/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const ThankYou = (props) => {
 * -----------------------------------------------------------------------------
 */

const ThankYou = () => {
	// logger.log('[ThankYou] data:%o', props.data);

	return (
		<div className="column-layout">
			<div className="four-col page-title">
				<div className="title-header">THANK YOU</div>
			</div>

			<div className="four-col clear-four clear-two">
				Thank you for your application for accreditation for The Championships 2024 which has been submitted to
				the Press Operations team. If you need to make any changes, please contact us at{' '}
				<a href="mailto:press.operations@aeltc.com">press.operations@aeltc.com</a>.
			</div>
			<div className="four-col clear-four clear-two">
				Should your application be successful, your applicant(s) will be contacted in early May with details of
				their accreditation.
			</div>
		</div>
	);
};

export default ThankYou;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import GenericCarousel from 'appdir/components/common-ui/GenericCarousel';
import { values } from 'appdir/main';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * React Component: ShopCarousel
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['ShopCarousel'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ShopCarousel.mount()),
});

const getShopItem = (item, n) => {
	return (
		<div className="shop-item" key={`item-${n}`}>
			<a href={item.url} target="_blank">
				<div className="shop-item-details">
					<div className="shop-item-img">
						<img src={item.image} alt={item.name} />
					</div>
					<span className="description">{item.name}</span>
					<hr className="shop-item-stripe" />
					{item.price && <span className="price">{item.price}</span>}
				</div>
			</a>
		</div>
	)
}

const sliderSettings = {
	infinite: true
}

class ShopCarousel extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.mount();
	}

	render() {
		const { type } = this.props;
		let group = values.apiBalance < 50 ? 0 : 1;

		const metricsData = {
			pageTitle: "Shop", // to add a value for Arrow and Swipe to indicate which carousel it is
		};

		// logger.log('[ShopCarousel] render - props:%o', this.props);
		if (this.props.groups && this.props.groups[0].items.length > 0 && this.props.groups[1].items.length > 0) {
			return (
				<div className={`shop-carousel-container ${type}`}>
					<span className="shop-header">Official Shop</span>
					<hr className="shop-header-stripe" />
					<GenericCarousel className={'shop-carousel'} sliderSettingsOverride = {sliderSettings} metricsData = {metricsData} slideWidth={250}>
						{this.props.groups[group].items.map((item, n) => (
							getShopItem(item, n)
						))}
					</GenericCarousel>
					<div className="view-more">
						<a href={this.props.groups[group].shopUrl} target="_blank">
							View More <i className="wim-icon-triangle-up" />
						</a>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopCarousel);

// 	<CarouselDisplay data={this.props.groups[group]} />
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import JSXParser from 'react-jsx-parser';
import { parseString, parseNumbers, parseBooleans } from 'xml2js';
import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';
import endsWith from 'lodash/endsWith';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Time from 'appdir/components/common-ui/Time';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import SiteMap from 'appdir/components/general/SiteMap';
import WimLink from 'appdir/components/general/WimLink';
import { getComponents, validateData } from 'appdir/components/general/Util';
import classNames from 'classnames';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { measureInAppContext } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['ContentPageWebview'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContentPageWebview.mount()),
	unmount: () => dispatch(deps.actions.ContentPageWebview.unmount()),
});

const getComponentList = list => {
	let comps = [];

	forIn(list, function(value, key) {
		if (value.hasOwnProperty('type')) {
			comps.push(value['type']);
		} else {
			logger.log('[ContentPageWebview] getComponentList - removing: %o:', key);
		}
	});

	//logger.log('[ContentPageWebview] getComponents - list:%o:', list);

	comps = uniq(comps);
	logger.log('[ContentPageWebview] getComponents - %o:', comps);
	return comps;
};

const getAttributes = list => {
	let attr = {};
	forIn(list, function(value, key) {
		if (value.hasOwnProperty('type')) {
			attr[key] = value;
		}
	});
	return attr;
};

const getComponentListArray = list => {
	let comps = [];

	if (validateData(list, 'array')) {
		Array.prototype.slice.call(list).forEach(item => {
			if (item.hasOwnProperty('type')) {
				comps.push(item['type']);
			} else {
				//logger.log('[ContentPageWebview] getComponentList - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	comps.push('WimLink');
	//logger.log('[ContentPageWebview] getComponents - %o:', comps);
	return comps;
};

const getAttributesArray = list => {
	let attr = {};

	if (validateData(list, 'array')) {
		Array.prototype.slice.call(list).forEach(item => {
			if (item.hasOwnProperty('type')) {
				attr[item['reference']] = item;
			} else {
				//logger.log('[ContentPageWebview]  getAttributesArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		attr[list['reference']] = list;
	}
	//logger.log('[ContentPageWebview]  getAttributesArray - attr: %o:', attr);

	return attr;
};

class ContentPageWebview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			favorite_articles: [],
			favorites: [],
			favorite_articleFilter: false,
			addedFavs: [],
			removedFavs: [],
		};
		this.onShareClick = this.onShareClick.bind(this);
		this.articleFavClicked = this.articleFavClicked.bind(this);

		this.lastDataPath = '';
		this.firstLoad = true;

		logger.log('[ContentPageWebview] constructor');
	}

	componentDidMount() {
		logger.log('[ContentPageWebview] componentDidMount');
	}

	componentWillUnmount() {
		logger.log('[ContentPageWebview] componentWillUnmount');
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		logger.log('[ContentPageWebview] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.dataUrl != undefined && endsWith(this.state.dataUrl, '.xml')) {
			logger.log('[ContentPageWebview] componentDidUpdate - fetch:%o', this.state.dataUrl);
			if (this.state.dataUrl != this.lastDataPath) {
				this.lastDataPath = this.state.dataUrl;
				deps.services.ContentPageWebview.fetchContent(this.state.dataUrl)
					.then(result => {
						//logger.log('[ContentPageWebview] componentDidUpdate result:%o', result);

						//set the xml parse options
						let options = {
							explicitArray: false,
							normalize: true,
							trim: true,
							mergeAttrs: true,
							valueProcessors: [],
						};

						//parse the xml
						parseString(result, options, function(err, json) {
							result = json.contentItem;
						});

						//throw a 404 error if recognize that a not found page
						if (result.contentId == 404) {
							let err = new Error('Nout found stub content');
							err.status = 404;
							throw err;
						}

						//clean the jsx block
						result.jsx = result.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');

						//try replacing relative links
						result.jsx = this.replaceLinks(result.jsx);

						/** fire pageView */
						if(this.props?.metricsData) {
							let {metricsData} = this.props;
							measureInAppContext({
								pageTitle: metricsData?.metricsPageTitle,
								action: metricsData?.measureAction,
								args: [],
								context:  [{ content_article: result?.shortTitle }, { section: metricsData?.metricsSection }],
								metricType: 'state'
							});
						}

						this.setState({
							data: result,
						});
					})
					.catch(error => {
						logger.error('[ContentPageWebview] componentDidUpdate error:%o', error);
						if (error.status == 404) {
							this.setState({
								data: 'notfound',
							});
						}
					});
			}

			if (this.state.location.search) {
				let favorites = this.getQSParams(this.state.location.search, 'favorite');

				if (
					prevState.favorite_articles !== this.state.favorite_articles &&
					this.state.favourites !== this.state.favorite_articles &&
					!this.firstLoad
				) {
					logger.log(
						'[ContentPageWebview] componentDidUpdate - prevState.favorite_articles:%o, this.state.favorite_articles:%o',
						prevState.favorite_articles,
						this.state.favorite_articles
					);

					let removeArrays = [prevState.favorite_articles, this.state.favorite_articles];
					let addedArrays = [this.state.favorite_articles, prevState.favorite_articles];
					let removedFavs = removeArrays.reduce((a, b) => a.filter(c => !b.includes(c)));
					let addedFavs = addedArrays.reduce((a, b) => a.filter(c => !b.includes(c)));

					logger.log('[ContentPageWebview] componentDidUpdate - removedFavs:%o', removedFavs);
					logger.log('[ContentPageWebview] componentDidUpdate - addedFavs:%o', addedFavs);

					for (var i = 0; i < removedFavs.length; i++) {
						const elem = `remove_${removedFavs[i]}`;

						this.setState({
							[elem]: false,
							favorites: [],
						});
					}

					for (var j = 0; j < addedFavs.length; j++) {
						const elem = `add_${addedFavs[j]}`;

						this.setState({
							[elem]: false,
							favorites: [],
						});
					}
				}
				/******** */
				if (favorites != this.state.favorite_articles && this.firstLoad) {
					this.firstLoad = false;
					this.setState(prevState => ({
						...prevState,
						favorite_articles: favorites == 'true' ? [this.state.id] : [],
						favorites: favorites == 'true' ? [this.state.id] : [],
						favorite_articleFilter: true,
					}));
				}
			} else {
				this.firstLoad = false;
			}
		} else {
			logger.warn('[ContentPageWebview] componentDidUpdate - improper data url: %o', this.state.dataUrl);
		}
	}

	getQSParams(search, which) {
		search = search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);
		let value = '';

		switch (which) {
			case 'favorite':
				if (parsedQs.favorite) {
					value = parsedQs.favorite;
				}
				break;
			default:
				break;
		}

		return value;
	}

	onShareClick() {
		logger.log('[ContentPageWebview] onShareClick');

		this.setState({
			sharePopup: 'hide',
		});
	}

	toggleShare() {
		logger.log('[ContentPageWebview] toggleShare - sharePopup:%o', this.state.sharePopup);
		this.setState({
			sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	replaceLinks(jsx) {
		//logger.log('[ContentPage] replaceLinks - jsx:%s', jsx);
		let result = jsx.match(/<a href="\/en_GB\/.*?a>/g);
		//logger.log('[ContentPage] replaceLinks - result:%s', result);
		if (result) {
			result.forEach(link => {
				let m = link.match(/href="(.*)">(.*)<\/a>/);
				jsx = jsx.replace(link, `<WimLink to="${m[1]}">${m[2]}</WimLink>`);
			});
		}

		//in webview force any link with http to open in external browser
		result = Array.from(jsx.matchAll(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g));
		//logger.log('[ContentPageWebview] replaceLinks - result:%o', result);
		if (result) {
			result.forEach(link => {
				//logger.log('[ContentPageWebview] replaceLinks - link:%o', link);
				let newUrl = link[2].includes('?') ? `${link[2]}&external=true` : `${link[2]}?external=true`;
				let newLink = link[0].replace(link[2], newUrl);
				jsx = jsx.replace(link[0],newLink);
			});
		}

		//logger.log('[ContentPageWebview] replaceLinks - jsx:%o', jsx);
		return jsx;
	}

	getByline(name, url, contentId, type) {
		logger.log('[ContentPageWebview] toggleShare - sharePopup:%o', this.state.sharePopup);
		// let articleFavClass = classNames({
		// 	'wim-icon-favStar':
		// 		(this.state.favorites.length == 0 && this.state.favorite_articles.length == 0) ||
		// 		(this.state.favorites.indexOf(this.state.id) == -1 &&
		// 			this.state.favorite_articles.indexOf(this.state.id) == -1),
		// 	'wim-icon-favStarSolid':
		// 		this.state[`add_${this.state.id}`] ||
		// 		this.state[`remove_${this.state.id}`] ||
		// 		this.state.favorites.indexOf(this.state.id) !== -1 ||
		// 		this.state.favorite_articles.indexOf(this.state.id) !== -1,
		// });

		let highlightClass = classNames({
			'byline--favorite': true,
			'highlight-added': this.state[`add_${this.state.id}`],
			'highlight-removed': this.state[`remove_${this.state.id}`],
		});
		return (
			<div className={`four-col byline ${this.state.data.margin == 'true' ? 'left' : ''}`}>
				<div className="byline--attribution">{name ? 'By ' + name : ''}</div>
				{/* {type == 'articles' ? (
					<div className={highlightClass} onClick={() => this.articleFavClicked(this.state.id)}>
						<WimLink
							className="name"
							to={
								this.state.favorite_articles.indexOf(this.state.id) != -1
									? `/addArticleFav/${this.state.id}.html`
									: `/removeArticleFav/${this.state.id}.html`
							}>
							<i className={articleFavClass} />
						</WimLink>
					</div>
				) : null} */}
			</div>
		);
	}

	getHeader() {
		let includeDate = true;
		if (this.state.category == 'amexverify') {
			includeDate = false
		}

		if (this.state.data.header.image && this.state.data.header.image.length > 25) {
			return (
				<div className="news--header-wrapper">
					<div className="image--header">
						<img src={this.state.data.header.image} />
					</div>
					<div className="news--header">
						<div className="news--header-date">
							{
								includeDate ? (
									<h3>
										<Time
											epoch_ms={this.state.data.date}
											format="ddd DD MMM YYYY kk:mm z"
											options="upper"
										/>
									</h3>
								): null
							}
						</div>
						<div className="news--header-title">
							<h1>{this.state.data.title}</h1>
						</div>
						<div className="news--header-shorttitle">
							<h1>{this.state.data.shortTitle}</h1>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	playFullVideo(id) {
		logger.log('[ContentPageWebview] playFullVideo - id:%o', id);
	}

	articleFavClicked(id) {
		let tempFavs = Object.assign([], this.state.favorite_articles.concat(this.state.favourites));

		tempFavs = Array.from(new Set(tempFavs));

		logger.log('[ContentPageWebview] articleFavClicked - tempFavs:%o', tempFavs);

		if (!tempFavs.includes(id)) {
			tempFavs.push(id);
		} else {
			var filtered = tempFavs.filter(function(value, index, arr) {
				return value !== id;
			});
			tempFavs = filtered;
		}

		logger.log('[ContentPageWebview] articleFavClicked after - tempFavs:%o', tempFavs);

		this.setState({
			favorite_articles: tempFavs,
		});
	}

	render() {
		logger.log('[ContentPageWebview] render - state:%o', this.state);

		if (this.state.data == 'notfound') {
			let header_propsData = {
				headerType: 'generic',
				title: 'Sitemap',
				metaTitle: 'Page not found',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};

			return (
				<Template className="webview">
					<div className="content-main webview">
						<SiteMap notFound={true} />
						<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
					</div>
				</Template>
				//<NotFoundPage attributes={header_propsData} />
			);
		} else if (this.state.data) {
			let attributes = getAttributesArray(this.state.data.dependencies.data);

			let components = getComponents(getComponentListArray(this.state.data.dependencies.data));
			//logger.log('[ContentPageWebview] render - attributes:%o components:%o:', attributes, components);
			//logger.log('[ContentPageWebview] render - data:%o:', this.state.data);

			let header_propsData = {
				headerType: this.state.category || this.state.landing,
				shortTitle: this.state.data.shortTitle,
				scrollElem: '.content-main',
				metaTitle: this.state.data.title,
				metaDescription: this.state.data.abstract,
				metaDate: this.state.data.date,
				metaPlayers: this.state.data.players,
				scroll: false,
			};

			if (this.state.data.header.image && this.state.data.header.image.length > 25) {
				header_propsData['imageSrc'] = this.state.data.header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (this.state.data.header.image && this.state.data.header.image.length > 25) {
				header_propsData['videoSrc'] = this.state.data.header.video;
			}

			//logger.log('[ContentPageWebview] render - jsx:%o', this.state.data.jsx);

			return (
				<Template className="webview">
					{this.getHeader()}
					<div className={`content-main column-layout news`}>
						{this.getByline(
							this.state.data.credit,
							this.state.data.url.share,
							this.state.data.contentId,
							this.state.category
						)}
						<JSXParser
							bindings={attributes}
							components={{ ...components }}
							jsx={this.state.data.jsx}
							renderInWrapper={false}
							showWarnings={true}
						/>
					</div>
				</Template>
			);
		} else {
			logger.warn('[ContentPageWebview] render - data not loaded, state:%o', this.state);

			let header_propsData = {
				headerType: 'generic',
				titleElem: '.news--header',
				scroll: false,
			};

			return (
				<Template className="webview">
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPageWebview);

import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		//logger.log('[ContentPage] action.mount - data:%o', data);

		// Mount dispatch
		//dispatch({type: deps.actionTypes.CONTENT_MOUNT, data: data});

		// Get articles
		if (data.hasOwnProperty('id')) {
			//dispatch({type: deps.actionTypes.CONTENT_LOADING});

			//logger.log('[ContentPage] action.mount - ensureConfig');
			return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
				let path = '';
				let prevNextPath = '';

				if (data.category) {
					data.landing = '';
					switch (data.category) {
						case 'jobs':
							path = Config.cmsData.general.replace('<section>', 'jobs');
							path = path.replace('<contentId>', data.id);
							break;
						case 'atoz':
							path = Config.cmsData.general.replace('<section>', 'atoz');
							path = path.replace('<contentId>', data.id);
							break;
						case 'aboutwimbledon':
						case 'about_wimbledon':
							path = Config.cmsData.general.replace('<section>', 'about_wimbledon');
							path = path.replace('<contentId>', data.id);
							break;
						case 'draws_archive':
							path = Config.cmsData.general.replace('<section>', 'draws_archive');
							path = path.replace('<contentId>', data.id);
							break;
						case 'foundation':
							path = Config.cmsData.general.replace('<section>', 'wimbledon_foundation');
							if (data.prefix == 'preview' && data.id == 'foundation') {
								path = path = path.replace('<contentId>', 'index');
							} else {
								path = path.replace('<contentId>', data.id);
							}
							break;
						case 'wimbledonfoundation':
							path = Config.cmsData.general.replace('<section>', 'wimbledon_foundation');
							if (data.prefix == 'preview' && data.id == 'foundation') {
								path = path = path.replace('<contentId>', 'index');
							} else {
								path = path.replace('<contentId>', data.id);
							}
							break;
						case 'tickets':
							path = Config.cmsData.general.replace('<section>', 'tickets');
							path = path.replace('<contentId>', data.id);
							break;
						case 'visitandtickets':
						case 'visit_and_tickets':
							path = Config.cmsData.general.replace('<section>', 'visit_and_tickets');
							path = path.replace('<contentId>', data.id);
							break;
						case 'aboutmywimbledon':
							path = Config.cmsData.general.replace('<section>', 'aboutmywimbledon');
							path = path.replace('<contentId>', data.id);
							break;
						case 'debentures':
							path = Config.cmsData.general.replace('<section>', 'debentures');
							//account for preview paths for landing pages in cms using urls like /preview/en_GB/debentures/deventures.html
							//  there is no way to identify this as a landing page with xml published as index.xml
							if (data.prefix == 'preview' && data.id == 'debentures') {
								path = path = path.replace('<contentId>', 'index');
							} else {
								path = path.replace('<contentId>', data.id);
							}
							break;
						case 'articles':
							let contentId = data.date ? data.date + '/' + data.id : data.id;
							path = Config.cmsData.general.replace('<section>', 'news');
							path = path.replace('<contentId>', contentId);
							prevNextPath = Config.relatedContent.nextPrevious
								.replace('<contentId>', data.id)
								.replace('<count>', 1)
								.replace('<true|false>', false)
								.replace('<type>', 'news');
							break;
						default:
							break;
					}
				} else if (data.landing) {
					data.category = '';
					//logger.log('[ContentPage] action.mount - landing - data: %o', data);
					switch (data.landing) {
						case 'jobs':
						case 'aboutmywimbledon':
						case 'about_wimbledon':
						case 'tickets':
						case 'foundation':
						case 'media':
						case 'learning':
						case 'your_visit':
						case 'museum_and_tours':
						case 'foodanddrink':
						case 'hospitality':
						case 'play_your_way_to_wimbledon':
						case 'privacy':
						case 'centre_court_100':

						case 'draws_archive':
						case 'wimbledon_foundation':
						case 'visit_and_tickets':
						case 'debentures':

						case 'inpursuitofgreatness':
							path = Config.cmsData.landing.replace('<section>', data.landing);
							break;
						case 'landing':
							path = Config.cmsData.landing.replace('<section>', data.id);
							break;
						default:
							break;
					}
				}

				/**
				 * Content
				 * Draws Archive Fast Facts: /en_GB/draws_archive/fast_facts.html - MOVE http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/draws_archive_fast_facts.xml
				 * Foundation: /en_GB/foundation/index.html - MOVE THE CONTENTS INTO "ABOUT" AND MAKE IT THE LANDING PAGE Andy can use this as a test
				 *
				 * Landing
				 * Jobs - /en_GB/jobs/index.html - MOVE - http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/jobs.xml
				 * About Wimbledon : /en_GB/about_wimbledon/index.html - MOVE  http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/about_wimbledon.xml
				 * Tickets: /en_GB/tickets/index.html - MOVE http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/tickets.xml
				 * Visit and Tickets : /en_GB/visit_and_tickets/index.html - DELETE IGNORED
				 * Media: /en_GB/media/index.html - MOVE http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/media.xml
				 * Learning: /en_GB/learning/index.html - MOVE http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/learning.xml
				 * Your Visit: /en_GB/your_visit/index.html - MOVE http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/your_visit.xml
				 * Museum and Tours : /en_GB/musuem_and_tours/index.html - MOVE http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/museum_and_tours.xml
				 * Food and Drink : /en_GB/foodanddrink/index.html - MOVE http://ix-events-dev.atl.dst.ibm.com:85/en_GB/cms/feeds/landing/foodanddrink.xml
				 *
				 * A to Z :/en_GB/atoz/index.html (used to create the banner image at the top) - PART OF SEARCH, NOT SURE IF IT EXISTS ON ONE. CAN WE MOVE FOR NOW. Now in .json file
				 */

				if (data.prefix !== 'preview') {
					path = path.replace('<preview>', '');
				} else {
					path = path.replace('<preview>', 'preview/');
				}

				data.dataUrl = path;
				data.prevNextUrl = prevNextPath;

				//logger.log('[ContentPage] action.mount - data: %o', data);
				dispatch({ type: deps.actionTypes.CONTENT_LOAD, data: data });
			});
		}
	},

	unmount: () => (dispatch, getState, store) => {
		let data = {
			category: '',
			landing: '',
			dataUrl: '',
		};
		dispatch({ type: deps.actionTypes.CONTENT_UNMOUNT, data: data });
	},
};

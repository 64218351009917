import React from 'react';
import findLastKey from 'lodash/findLastKey';
import { isNumeric } from 'appdir/components/general/Util';

const humanSort = require('alphanum-sort');

import StageItems from 'appdir/components/pages/Slamtracker/elements/stage/StageItems';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: StageGame
 * -----------------------------------------------------------------------------
 */

const StageGame = props => {
	let { data } = props;
	let { gameObj } = data;
	let { gameNo } = data;
	let { setNo } = data;
	let { statMatch } = data;
	let { pointsById } = data;
  let { pointHistory } = data;

  const gamePoint = (setNo, gameNo) =>
		findLastKey(gameObj, function (pointObj) {
			return pointObj.GameWinner !== '0';
		});

	// logger.log('[StageGame] props - props:%o', props);
	return (
		<div
			key={gameNo}
			id={`gameSection_set${setNo}_game${gameNo}`}
			className={`game ${
				pointsById[gamePoint(setNo, gameNo)] ? 'team' + pointsById[gamePoint(setNo, gameNo)].GameWinner : ''
			}`}
			data-set={`set${setNo}`}
			data-game={`game_${gameNo}`}>
			{humanSort(Object.keys(gameObj))
				.map((pointNumber, k) => {
					{/* logger.log('[StageGame] gameSummaryData map point:', pointNumber); */}
					const pointData = {
						pointObj: gameObj[pointNumber],
						pointHistoryArray: pointHistory,
						team1: {
							displayNameA: statMatch.team1.displayNameA,
							displayNameB: statMatch.team1.displayNameB,
						},
						team2: {
							displayNameA: statMatch.team2.displayNameA,
							displayNameB: statMatch.team2.displayNameB,
						},
						isMatchComplete: statMatch.winner == '1' || statMatch.winner == '2' ? true : false,
            pointsById,
            statMatch,
					};
					return isNumeric(pointNumber) ? (
            <div key={`${setNo}-${gameNo}-${pointNumber}`}>
              <StageItems data={{ ...pointData }} />
            </div>
          ) : null;
				})}
		</div>
	);
};

export default StageGame;

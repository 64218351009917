/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

/* page elements */
import MatchInsightsContent from 'components/pages/MatchInsights/MatchInsightsContent';
import PageHeader from 'appdir/components/general/PageHeader';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsightsWebview
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	unmount: () => dispatch(deps.actions.MatchInsights.unmount()),
});

class MatchInsightsWebview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//  ...props,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[MatchInsightsWebview] componentWillReceiveProps - state:%o', this.state);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidMount() {
		logger.log('[MatchInsightsWebview] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	componentDidUpdate() {
		logger.log('[MatchInsightsWebview] componentDidUpdate - state:%o', this.state);
	}

	render() {
		logger.log('[MatchInsightsWebview] render - state:%o', this.state);

		return (
			<>
				<PageHeader attributes={{ isWebview: true }} />
				<section className={`wrapper match-insights webview`} id="match-insights">
					<MatchInsightsContent matchId={this.props.match.params.matchId} isWebview={true} />
				</section>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsightsWebview);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { values } from 'appdir/main';
import React, { Component } from 'react';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import WimRedirect from 'appdir/components/general/WimRedirect';
import { getAppLink } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * CMS Component: Video
 * -----------------------------------------------------------------------------
 */

class Video extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);

		this.onVideoEvent = this.onVideoEvent.bind(this);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	onVideoEvent(event) {
		logger.info('[Video] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				videoId: '',
			});
		}
	}

	/**
	 * handle clicks from video thumb
	 */
	onClick() {
		logger.log('[Video] onClick - state:%o', this.state);

		if (!values.webview) {
			this.setState({
				videoId: this.state.data.video.videoId,
			});
		} else {
			// this.setState({
			// 	nav: `/en_GB/video/media/${this.state.data.video.videoId}.html`,
			// 	videoId: this.state.data.video.videoId
			// });

			let videoUrl = `/en_GB/video/media/${this.state.data.video.videoId}.html`;
			videoUrl = getAppLink(videoUrl, '');
			document.location.href = videoUrl.url;
		}
	}

	render() {
		logger.log('[Video] render - state:%o', this.state);

		let attributes = {
			playerId: 'main',
			cmsId: this.state.videoId,
			contentId: null,
			title: this.state.data.video.caption,
			thumb: this.state.data.video.thumbImage,
			style: 'video-wrapper',
			autoplay: true,
			aspect: 'wide',
		};

		// no videoId so video not clicked
		if (!this.state.videoId) {
			return (
				<div className={`${this.state.data.style} video-comp`}>
					<div className="video-wrapper" onClick={() => this.onClick()}>
						<img src={this.state.data.video.thumbImage} />
						<i className="wim-icon-video" />
					</div>
					<div className="video-info">
						<div className="video-title">{this.state.data.video.caption}</div>
						<div className="video-date" />
					</div>
				</div>
			);
		} else {
			return (
				<div className={`${this.state.data.style} video-comp`}>
					<div className={`video-comp-wrapper`}>
						<VideoPlayer attributes={attributes} onEvent={this.onVideoEvent} />
					</div>
					<div className="video-info">
						<div className="video-title">{this.state.data.video.caption}</div>
						<div className="video-date" />
					</div>
				</div>
			);
		}
	}
}

Video.defaultProps = {
	autoplay: false,
};

export default Video;

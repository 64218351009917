/**
 * -----------------------------------------------------------------------------
 * React Component: <RolexScheduleTime />
 * -----------------------------------------------------------------------------
 *
 * @author Cam Tullos <cam@tullos.ninja>
 *
 * @description Creates an overlay that displays the match start time.
 *
 * @param when Date|String The start time in valid new Date() format. Default: Date.now().
 * @param top Number|String Valid CSS position value for `top` property. Default: 50%.
 * @param left Number|String Valid CSS position value for `left` property. Default: 50%.
 * @param position String [fixed|absolute|relative] Valid CSS value for `position` property. Default: 'absolute'.
 * @param visible Boolean Determines whether to hide/show the component.
 * @param onClick Function On click handler for the component. Default: null.
 * @param format String Moment.js date format. Default: hh:mma. @see Moment.js date format: https://momentjs.com/docs/#/displaying/format
 * @param label Object Collection of labels used in the compnent.
 * @param label.event String The event time label
 * @param label.local String The local time label
 *
 * @example
    <RolexScheduleTime
        top={'50%'}
        left={'50%'}
        visible={true}
        format={'hh:mma'}
        position={'fixed'}
        when={'1978-04-22T16:00:00'}
        onClick={() => { alert('Hey guys'); }}
        label={{event: 'Wimbledon', local: 'Your Time'}} />
 */

/**
 * ----------------------------------------------------------------------------
 * Imports
 * ----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

class RolexScheduleTime extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	render() {
		let { when, top, left, position, onClick, format, visible, label } = this.state;
		// logger.log('[RolexScheduleTime] render - props:%o, state:%o',this.props, this.state);
		let display = this.props.visible === true ? 'block' : 'none';
		// logger.log('[RolexScheduleTime] render - display:%o', display);

		when = typeof when === 'string' ? new Date(when) : when;

		let cls = 'rolex-schedule-time';

		return (
			<div className={cls} style={{ top, left, position, display }} onClick={onClick}>
				<div className={`${cls}-wrapper`}>
					<div className={`${cls}-title`}>
						<h3>{label.event}</h3>
						<span>{`${moment(when)
							.tz('Europe/London')
							.format(format)}`}</span>
					</div>
					<div className={`${cls}-title`}>
						<h3>{label.local}</h3>
						<span>{`${moment(when).format(format)}`}</span>
					</div>
				</div>
			</div>
		);
	}
}

RolexScheduleTime.defaultProps = {
	visible: true,
	top: '50%',
	left: '50%',
	position: 'absolute',
	when: Date.now(),
	onClick: null,
	format: 'hh:mma',
	label: {
		event: 'Wimbledon',
		local: 'Your Time',
	},
};

export default RolexScheduleTime;

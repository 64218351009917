import React, { Component } from 'react';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';

class ResetPasswordSuccess extends Component {
	constructor(props) {
		super(props);

		//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
		this.classnames = 'gigya-screen v2 portrait';
	}

	render() {
		let location = '/index.html?login';
		if (op.get(this, 'context.location', '').indexOf('/hospitality') == 0) {
			location = op.get(this, 'context.location', '');
		}

		//logger.log('[Gigya] [ResetPasswordSuccess] render - location:%o', location);

		return (
			<div id="gigya-reset-password-success-screen" data-width="auto" className={this.classnames} data-caption="screenset.translations['GIGYA_RESET_PASSWORD_SUCCESS_SCREEN_CAPTION']">
				<div className="gigya-layout-row">
					<label className="gigya-composite-control gigya-composite-control-label gigya-message" data-translation-key="LABEL_RESET_SUCCESS_LABEL" />
				</div>
				<div className="gigya-layout-row">
					<a
						data-switch-screen="_finish"
						tabIndex="-1"
						className="gigya-composite-control gigya-composite-control-link gigya-button-link"
						style={{ textAlign: 'center' }}
						data-translation-key="LINK_BUTTON_PROFILE_LABEL"
					/>
					
				</div>
			</div>

	
		);
	}
}

ResetPasswordSuccess.contextType = AuthContext;
export default ResetPasswordSuccess;

/*
<div className="gigya-layout-row">
	<a
		className="gigya-composite-control gigya-composite-control-link gigya-button-link"
		style={{ textAlign: 'center' }}
		href={location}
		data-translation-key="LINK_122104312720648740_LABEL"
	/>
</div>
*/

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';
import { SAMLContext } from './context';
import isEmpty from 'lodash/isEmpty';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Script from 'react-load-script';
import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SiteMap from 'appdir/components/general/SiteMap';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import Login from './Login';
import Logout from './Logout';
import Error from './Error';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledonLoginSAML
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['MyWimbledonLoginSAML'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class MyWimbledonLoginSAML extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		logger.log('[MyWimbledonLoginSAML] componentDidUpdate - state:%o', this.state);
	}

	render() {
		logger.log('[MyWimbledonLoginSAML] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'mywimbledon',
			title: 'myWimbledon',
			shortTitle: 'myW',
			metaTitle: 'myWimbledon',
			metaDescription: 'myWimbledon - Wimbledon ' + values.tournamentYear,
			metaDate: '',
			metaPlayers: '',
		};

		let contextValues = {
			currentUser: this.state.currentUser,
			loggedIn: this.state.loggedIn,
			gigyaLoaded: this.state.gigyaLoaded,
		};

		//logger.log('[MyWimbledonLoginSAML] render - token:%o context:%o', op.get(contextValues, 'jwt.id_token', false), contextValues);

		// let gigyaParams = {
		// 	"screenSet": 'simple-screen-set',
		// 	"startScreen": 'edit-profile'
		// }

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main column-layout news">
					<SAMLContext.Provider value={contextValues}>
						{this.state.action == 'login' ? (
							<Login />
						) : this.state.action == 'logout' ? (
							<Logout />
						) : this.state.action == 'error' ? (
							<Error />
						) : null}
					</SAMLContext.Provider>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWimbledonLoginSAML);

/**
 *  Draws Analysis Bar Component
 *  Suppots three different cases
 *  1) Demo (for help overlay) with static valued markers
 *  2) dynamic positioned marker using drawLevel data in the drawAnalysis - for Draws and Round 1 for Full View Draws
 *  3) static positioned marker using drawPath data for Round by round on Full View Draws over Round 2
 */

import React, { useEffect, useState } from 'react';

const DrawAnalysisBar = props => {
	let { data = {} } = props;
	let drawLevel = data?.playerDrawAnalysisData?.drawLevel;
	let drawLabel = data?.playerDrawAnalysisData?.drawLabel;
	let roundByRoundData = data?.roundByRoundData;

	/** if the round by round data are passed, use it instead */
	if (roundByRoundData) {
		drawLevel = null; // no dynamic data for round by round
		drawLabel = roundByRoundData?.roundDrawLabel;
	}

	const getMarkerPos = () => {
		let markerPos;

		if (drawLevel) {
			markerPos = drawLevel * 100; // make it percentage
		} else if (drawLabel) {
			switch (drawLabel?.toLowerCase()) {
				case 'favourable':
					markerPos = 3;
					break;
				case 'neutral':
					markerPos = 50;
					break;
				case 'difficult':
					markerPos = 97;
					break;

				default:
					break;
			}
		}

		if (markerPos) {
			return <span className={drawLabel} style={{ left: markerPos + '%' }} />;
		}
	};

	logger.log('[DrawAnalysisBar] props:%o', props);

	return (
		<>
			{data?.demo && <h5 className="demo-header">Player's Indicator</h5>}

			<div className={`drawAnalysisBar ${data?.demo ? 'demo' : ''}`}>
				{
					data?.demo 
					? (
						<>
							<span className={'favourable'} />
							<span className={'neutral'} />
							<span className={'difficult'} />
						</>
					) 
					: data?.isWinner // don't show the marker in the winner box
					? null 
					: (
					getMarkerPos()
					)
				}

				<img src='/assets/images/misc/drawanalysis-bar.svg' alt="Draw Analysis Bar Image" />

				{data?.demo && (
					<div className="demo-labels">
						<label className={'favourable'}>Favourable</label>
						<label className={'neutral'}>Neutral</label>
						<label className={'difficult'}>Difficult</label>
					</div>
				)}
			</div>

		</>
	);
};

export default DrawAnalysisBar;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
//import EmbedContainer from 'react-oembed-container';
import JSXParser from 'react-jsx-parser';
import { loadTwitter, removeTwitter } from './TwitterEmbed';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: YouTube
 * -----------------------------------------------------------------------------
 */
class YouTube extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		//logger.log('[YouTube] - twitter:%o', loadTwitter);
		this.style = '';

		let regex = /<script.*?src="(.*?)"/gim;
		let url = regex.exec(this.state.data.youTubeVideoId);

		if (url && url.length > 0) {
			this.style = 'embedContent';
			let src = url[1];

			if (src.includes('platform.twitter.com/widgets.js')) {
				loadTwitter();
			}
		} else {
			this.style = 'youtubeEmbed';
		}
	}

	componentDidMount() {}

	componentWillUnmount() {
		removeTwitter();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	render() {
		logger.log('[YouTube] - attributes:%o', this.state.data);

		let html = this.state.data.youTubeVideoId;
		html = html.replace(/<br>/gi, '<br />');
		html = html.replace(/frameborder/gi, 'frameBorder');
		html = html.replace(/allowfullscreen/gi, 'allowFullScreen');

		return (
			<div className={`${this.state.data.style} embedComponent`}>
				<div className={this.style}>
					<JSXParser bindings={{}} components={{}} jsx={html} renderInWrapper={false} showWarnings={true} />
				</div>
			</div>
		);
	}
}

/*
    -- using TweetEmbedNew, sourced original from react-tweet-embed
                <TweetEmbedNew id='957213670475382784' options={{
                    //cards: 'hidden',
                    align: 'center',
                    conversation: 'none',
                    theme: 'dark'
                }} />

    -- using react-oembed-container
                <EmbedContainer markup={this.state.data.youTubeVideoId}>
                    <div dangerouslySetInnerHTML={{
                        __html: this.state.data.youTubeVideoId
                    }} />
                </EmbedContainer>


*/

export default YouTube;

/**
 *  Helpful notes
 * Status codes

A = In Progress
B = Future
D = Complete
E = Retired
F = Walkover
G = Default
K = Suspended
X = Arrive on Court
Y = Warming Up
 */

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DelayExecutor } from 'appdir/components/general/Util';
import Headshot from 'appdir/components/common-ui/Headshot';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Visualisation
 * -----------------------------------------------------------------------------
 */
const Visualisation = props => {

    /** the match */
    const statMatch = useSelector((state) => state?.['ScoreManager']?.statMatch ?? {});
    // const eventNames = useSelector((state) => state?.['Config']?.scoringConfig?.eventNames);
    // const st_event = useSelector((state) => state?.['Config']?.scoring?.messagePath);
    // const slamtrackerConfig = useSelector((state) => state?.['Config']?.slamtracker ?? {});
    // const tiebreakProps = { statMatch, eventNames, st_event, slamtrackerConfig};

    const currentPoint = statMatch?.pointHistory[statMatch?.pointHistory?.length - 1]; //most recent point data
    const currentSet = statMatch?.scores?.sets[statMatch?.scores?.sets?.length - 1]; //current set
    // const tiebreak = currentPoint?.P1GamesWon == '6' && currentPoint.P2GamesWon == '6';
    const tiebreak = currentSet?.[0]?.tiebreak || currentSet?.[1]?.tiebreak; //whether we're in a tiebreak or not 
    // logger.log('[Visualisation] currentSet: %o', currentSet);

    const inProgress = statMatch.statusCode == 'A' /*|| statMatch.statusCode == 'X' || statMatch.statusCode == 'Y'*/;
    const uom = useSelector((state) => state?.['Controller'].userPreferences['speed'] ?? 'M'); //user preference imperial or metric
    const team1ServingFB = statMatch.server == 'A' || statMatch.server == 'B'; //fallbacks for if we don't have point info
    const team2ServingFB = statMatch.server == 'C' || statMatch.server == 'D';//fallbacks for if we don't have point info
    const team1Serving = currentPoint?.ServeIndicator == "1" || ((!currentPoint || !currentPoint?.ServeIndicator || currentPoint?.ServeIndicator == "0" ) && team1ServingFB);
    const team2Serving = currentPoint?.ServeIndicator == "2" || ((!currentPoint || !currentPoint?.ServeIndicator || currentPoint?.ServeIndicator == "0" ) && team2ServingFB);
    const team1ShowBall = !displayRally && team1Serving && !statMatch.team1.won && !statMatch.team2.won && statMatch.statusCode === 'A';
    const team2ShowBall = !displayRally && team2Serving && !statMatch.team1.won && !statMatch.team2.won && statMatch.statusCode === 'A';
    const matchWon = currentPoint?.MatchWinner && currentPoint?.MatchWinner != "0";
    const duration = statMatch.scores.setDurations?.[parseInt(currentPoint?.SetNo) - 1];

    /** text options */
    const serveSpeedLabel = "IBM Serve Speed";
    const breakPointText = "Break Point Opportunity";
    const rallyText = "RALLY COUNT";
    const pointText = "POINT";
    const adText = "AD";
    const deuceText = "DEUCE";
    const changeoverText = "CHANGEOVER";
    const setBreakText = "SET BREAK";
    const setDurationText = "SET DURATION";
    const winnerText = "WINNER";

    /** variables */
    const second = 1000; //1000ms 
    let search = document.location.search.replace(/^\?/, '');
    let debug = search.indexOf('visdebug=true') > -1;
    const doubles = statMatch.team1.firstNameB ? true : false; //if the match is doubles or not
    
    /** these values should match (or be close to) the css anim values. Adjust for anim overlap */
    const changeoverSpeed = second; //changeover anim time
    const changeoverTime = second * 10; //changeover text box display time
    const fadeSpeed = second * 0.2;//how long to display something before fading
    const rallyDelayTime = second * 2; //how long before rally appears
    const rallyDisplayTime = second * 5; //how long before rally disappears

    /** state */
    const [init, setInit] = useState(true);
    const [reversed, setReversed] = useState(''); // court end
    const [adDeuce, setAdDeuce] = useState(''); // ad/deuce court    
    const [hideText, setHideText] = useState('');
    const [displayRally, setDisplayRally] = useState(false);
    const [hideServeSpeed, setHideServeSpeed] = useState(false);
    const [showSetDuration, setShoweSetDuration] = useState(false);
    const [matchState, setMatchState] = useState('warmup');//what state is the match in? (warmup, play, point, changeover)
    const [showBPO, setShowBPO] = useState(false);

	// logger.log('[Visualisation] props: %o', props);

    const debugSetDuration = () => {
        const executor = new DelayExecutor();
        executor.delay(() => setDisplayRally(true), rallyDelayTime)
                .delay(() => setDisplayRally(false), rallyDisplayTime)
                .delay(() => setShoweSetDuration(true), fadeSpeed)
                .delay(() => setShoweSetDuration(false), rallyDisplayTime)
                .delay(() => setMatchState('setbreak'), fadeSpeed)
                .delay(() => setMatchState('play'), rallyDisplayTime);
    }

    const getServeSpeed = (data) => {
		let textUnit = uom == 'M' ? ' KMH' : ' MPH';
		let serveSpeedArray = data ? data.split(',') : [null, null];
		let serveSpeed = uom == 'M' ? serveSpeedArray[0] : serveSpeedArray[1];

        return serveSpeed ? <>
            <span className='text caps'>{serveSpeedLabel}</span>
            <span className='text larger'>{serveSpeed + textUnit}</span>
        </> : null
	}

    const genTeamInfo = (team1) => {
        if(!currentPoint || matchWon){
            return null;
        }

        let serveSpeed = null;
        let showPoint = false;
        let displayText = null;
        let serving = false;
    
        /** BreakPointOpportunity       
         * String: If the result of this point is that the next point will be a break point, 
         * this value will represent the team that has the opportunity for a break (1|2). */

        /** if this team is serving */
        if((team1 && team1Serving) || (!team1 && team2Serving)){
            serving = true;
            serveSpeed = getServeSpeed(statMatch.last_serve_speed);
            displayText = !hideServeSpeed ? serveSpeed : null;
        }

        if(matchState == "point"){
            serveSpeed = null; //hide serve speed when a point is won
            /** if the team we're displaying won the point */
            if((team1 && currentPoint.PointWinner == '1') || (!team1 && currentPoint.PointWinner == '2')){
                showPoint = true;
                let pointDisplayText = pointText;
                if(team1 && currentPoint.P1Score == "AD" || !team1 && currentPoint.P2Score == "AD"){
                    pointDisplayText = adText;
                }else if(currentPoint.P1Score == '40' && currentPoint.P2Score == '40'){
                    pointDisplayText = deuceText;
                }
                displayText = <span className='text caps'>{pointDisplayText}</span>;                
            }            
        }

        if(!serving && showBPO && matchState == 'play'){
            /** override 'point' text with breakpoint if it applies, for receiver only */
            showPoint = true;
            displayText = <span className='text caps'>{breakPointText}</span>;
        }

        if(!displayText){
            return null;
        }

        const hidden = inProgress && matchState != 'warmup' && matchState != 'changeover' && (serveSpeed || showPoint)  ? '' : 'hidden'; //opacity 0 if nothing to display

        {/** positioning of info boxes either above or below player names depending on ad/deuce */}
        return <div className={`${team1 ? `team-1-info` : `team-2-info`} ${reversed} ${adDeuce} ${doubles ? 'doubles' : ''} ${hidden}`}>
            {displayText}
        </div>
    }

    const genWinner = winner => {
        logger.log('[Visualisation] winner:%o', winner);
        if(winner == "1"){
            return (
                <div className='winner-names'>
                    <span className='winnerName a'>{statMatch.team1.displayNameA}</span>
                    {doubles && <span className='winnerName b'>{statMatch.team1.displayNameB}</span>}
                </div>
            );            
        }else if(winner == "2"){
            return (
                <div className='winner-names'>
                    <span className='winnerName a'>{statMatch.team2.displayNameA}</span>
                    {doubles && <span className='winnerName b'>{statMatch.team2.displayNameB}</span>}
                </div>
            );  
        }else{ return null;}
    }

    const genNotices = () => {
        const dataInProgress = inProgress && currentPoint && !matchWon;//basic check for data and if the match is in progress

        const showMatchStatus = !inProgress && !matchWon ? 'visible' : '';
        const showRally = displayRally && dataInProgress ? 'visible' : '';
        const showMatchEnd = currentPoint && matchWon ? 'visible' : '';
        const showDuration = duration && dataInProgress && showSetDuration ? 'visible' : '';
        const showSetBreak = matchState == 'setbreak' && dataInProgress ? 'visible' : '';
        const showChangeover = matchState == 'changeover' && dataInProgress && !showSetDuration && !displayRally ? 'visible' : '';

        return <>
            {/** match has a status of something other than 'in progress' */}
            <div className={`vis-notice-container ${showMatchStatus}`}>
                <div className={`vis-notice`}>{statMatch.status}</div>
                {statMatch.statusCode == "X" || statMatch.statusCode == "Y" ? <img className='changeover-anim' src="/assets/images/scores/slamtracker/backgrounds/ChangeoverAnimation.gif" alt="changeover indicator" /> : null}
            </div>

            {/** show rally count after a point */}
            <div className={`vis-notice-container rally ${showRally}`}>
                <div className={`vis-notice`}>
                    <span className='text caps'>{rallyText}</span>
                    <span className='text larger'>{currentPoint?.RallyCount}</span>
                </div>
            </div>

            {/** match ended */}
            <div className={`vis-notice-container winner ${showMatchEnd}`}>
                <div className={`vis-notice winner`}>
                    <div className='winner-text'><span className='win-icon'><i className='wim-icon-wf-checkmark'/></span> <span className='winner-text'>{winnerText}</span></div>
                    {matchWon ? genWinner(currentPoint?.MatchWinner) : null}
                </div>                
            </div>

            {/** set duration */}
            <div className={`vis-notice-container duration ${showDuration}`}>
                <div className={`vis-notice`}>
                    <span className='text caps'>{setDurationText}</span>
                    <span className='text larger'>{`${currentPoint ? `${duration/60 >= 1 ? Math.floor(duration/60) : '0' }:${duration % 60 < 10 ? '0'+(duration % 60) : duration % 60}` : ''}`}</span>                                
                </div>
            </div>

            {/** set break */}
            <div className={`vis-notice-container ${showSetBreak}`}>
                <div className={`vis-notice`}>
                    {setBreakText}
                </div>
                <img className='changeover-anim' src="/assets/images/scores/slamtracker/backgrounds/ChangeoverAnimation.gif" alt="changeover indicator" />
            </div>

            {/** changeover */}
            <div className={`vis-notice-container ${showChangeover}`}>
                <div className={`vis-notice`}>
                    <span className='text caps'>{changeoverText}</span>                                
                </div>
                <img className='changeover-anim' src="/assets/images/scores/slamtracker/backgrounds/ChangeoverAnimation.gif" alt="changeover indicator" />
            </div>
        </>
    }
    
    /** when the serve speed value changes */
    useEffect(() => {
        setMatchState('play');
        const bpo = currentPoint?.BreakPointOpportunity && currentPoint?.BreakPointOpportunity != '0'; //break point opportunity
        setShowBPO(bpo);
        setHideServeSpeed(false);
    }, [statMatch.last_serve_speed]);

    /** When a new point comes in */
    useEffect(() => {
        logger.log('[Visualisation] a new point appears!');
        logger.log('[Visualisation] statMatch: %o', statMatch);
        logger.log('[Visualisation] currentPoint: %o', currentPoint);
        logger.log('[Visualisation] init: %o', init);

        const bpo = currentPoint?.BreakPointOpportunity && currentPoint?.BreakPointOpportunity != '0'; //break point opportunity

        if(init && currentPoint && currentPoint?.TeamOrientEnd){
            setInit(false);
            doPlayerPosition();
            setMatchState('play');
            setShowBPO(bpo);
            return;
        }else if(init || !currentPoint || !statMatch || !inProgress){
            return;
        }

        if(currentPoint.PointWinner == "0"){
            return; // for now do nothing, this would probably only happen as the match is initializing
        }

        setHideServeSpeed(true);
        setShowBPO(false);
        setMatchState('point');        
        
        let rallyLength = parseInt(currentPoint?.RallyCount);
        const executor = new DelayExecutor();
        if(rallyLength > 1){

            /** display set duration and set break after set end */
            if(currentPoint?.SetWinner != "0"){
                executor.delay(() => setDisplayRally(true), rallyDelayTime)
                .delay(() => setDisplayRally(false), rallyDisplayTime)
                .delay(() => setShoweSetDuration(true), fadeSpeed)          //show/hide set duration
                .delay(() => setShoweSetDuration(false), rallyDisplayTime)                
                .delay(() => doPlayerPosition(), fadeSpeed)
                .delay(() => setMatchState('setbreak'), changeoverSpeed + fadeSpeed);     //show set break

            /** game has ended, no changeover */
            // } else if(parseInt(currentPoint?.GameNo) % 2 == 0 && currentPoint?.GameWinner != "0" && !tiebreak){
            } else if(currentPoint?.TeamOrientStart == currentPoint?.TeamOrientEnd && currentPoint?.GameWinner != "0"){ 
                executor.delay(() => setDisplayRally(true), rallyDelayTime)
                .delay(() => setDisplayRally(false), rallyDisplayTime)
                .delay(() => setShoweSetDuration(true), fadeSpeed)          //show/hide set duration
                .delay(() => setShoweSetDuration(false), rallyDisplayTime)
                .delay(() => doPlayerPosition(), fadeSpeed)
                .delay(() => {setMatchState('play'); setShowBPO(bpo);}, changeoverSpeed + fadeSpeed);

            /** game has ended, changeover */
            // } else if(parseInt(currentPoint?.GameNo) % 2 != 0 && currentPoint?.GameWinner != "0"){
            } else if(currentPoint?.TeamOrientStart != currentPoint?.TeamOrientEnd && currentPoint?.GameWinner != "0"){     
                executor.delay(() => setDisplayRally(true), rallyDelayTime)
                .delay(() => setDisplayRally(false), rallyDisplayTime)
                .delay(() => doPlayerPosition(), fadeSpeed)
                .delay(() => setMatchState('changeover'), changeoverSpeed + fadeSpeed);

            /** else not end of set */
            }else{            
                executor.delay(() => setDisplayRally(true), rallyDelayTime)
                .delay(() => {setDisplayRally(false); }, rallyDisplayTime)
                .delay(() => doPlayerPosition(), fadeSpeed)
                .delay(() => {setMatchState('play'); setShowBPO(bpo);}, changeoverSpeed + fadeSpeed)
            }
        }else{ /** no rally stuff if length less than or equal to 1 */
            /** display set duration and set break after set end */
            if(currentPoint?.SetWinner != "0"){
                executor.delay(() => setShoweSetDuration(true), rallyDelayTime)          //show/hide set duration
                .delay(() => setShoweSetDuration(false), rallyDisplayTime)                
                .delay(() => doPlayerPosition(), fadeSpeed)
                .delay(() => setMatchState('setbreak'), changeoverSpeed + fadeSpeed);              //show set break

            /** game has ended, no changeover */
            } else if(currentPoint?.TeamOrientStart == currentPoint?.TeamOrientEnd && currentPoint?.GameWinner != "0"){ 
                executor.delay(() => setShoweSetDuration(true), fadeSpeed)          //show/hide set duration
                .delay(() => setShoweSetDuration(false), rallyDisplayTime)
                .delay(() => doPlayerPosition(), fadeSpeed)
                .delay(() => {setMatchState('play'); setShowBPO(bpo);}, changeoverSpeed + fadeSpeed);

            /** game has ended, changeover */
            } else if(currentPoint?.TeamOrientStart != currentPoint?.TeamOrientEnd && currentPoint?.GameWinner != "0"){
                executor.delay(() => doPlayerPosition(), rallyDelayTime).delay(() => setMatchState('changeover'), changeoverSpeed + fadeSpeed);

            /** else not end of set */
            }else{  
                executor.delay(() => doPlayerPosition(), rallyDelayTime).delay(() => {setMatchState('play'); setShowBPO(bpo);}, changeoverSpeed + fadeSpeed);
            }
        }
    }, [currentPoint?.ElapsedTime]);

    /** if swapping sides or ad/deuce court */

    const doPlayerPosition = () => {
        const rev = currentPoint?.TeamOrientEnd == "B" ? 'reversed' : '';
        let ad = currentPoint?.CourtIndicator == "A" ? 'adDeuce' : '';
        if(rev != ''){ //need to do the opposite if sides are reversed
            ad = ad != '' ? '' : 'adDeuce';
        }
        

        setHideText(true);
        const executor = new DelayExecutor();
        executor.delay(() => {setAdDeuce(ad); setReversed(rev)}, fadeSpeed).delay(() => setHideText(false), changeoverSpeed);
    }

    return(
        <>
        {debug && <div className='debug'>
            <div>{statMatch.matchId}</div>
            <div>{matchState}</div>
            <div>Set: {currentPoint?.SetNo} </div>
            <div>Game: {currentPoint?.GameNo} </div>
            <div>AD: {currentPoint?.CourtIndicator}</div>            
            <div>End: {currentPoint?.TeamOrientStart}{currentPoint?.TeamOrientEnd}</div>
            <div>BPO: {currentPoint?.BreakPointOpportunity && currentPoint?.BreakPointOpportunity != '0' ? 'T' : 'F'}</div>
            {/* <div><button onClick={() => debugSetDuration()}>Set Duration</button></div> */}
        </div>}
        
        <div className='visualisation-container' >            
            <div className={`visualisation`}>
                <div className='doubles-alley top'>
                    <div className={`tiebreak ${tiebreak && !matchWon && inProgress ? 'visible' : ''}`}>Tiebreak</div>
                </div>
                <div className={`court ${hideText ? 'hideText' : ''}`}>

                    {genTeamInfo(true)} {/* team 1 info box */}                    
                    {genTeamInfo(false)} {/* team 2 info box */}    

                    {/** player headshots and names */}
                    <div className={`team-1 ${reversed} ${adDeuce} ${doubles ? 'doubles' : ''} ${ matchWon || !inProgress ? 'noPlayers' : ''}`} >
                        <div className='player-imgs'>
                            {reversed && <img className={`ballbounce ${team1ShowBall && matchState == 'play' ? 'serving' : ''}`} src="/assets/images/scores/slamtracker/backgrounds/BallBounce.gif" alt="serve indicator" />}
                            <div className={`player-img A`}>
                                <Headshot
                                    className="player-headshot"
                                    id={statMatch.team1.idA}
                                    type="round"
                                    bg={true}
                                />
                            </div>
                            {doubles && <div className={`player-img B`}>
                                <Headshot
                                    className="player-headshot"
                                    id={statMatch.team1.idB}
                                    type="round"
                                    bg={true}
                                />
                            </div>}
                            {!reversed && <img className={`ballbounce ${team1ShowBall && matchState == 'play' ? 'serving' : ''}`} src="/assets/images/scores/slamtracker/backgrounds/BallBounce.gif" alt="serve indicator" />}
                        </div>
                        <div className='player-name A'>{statMatch.team1.firstNameA} {statMatch.team1.lastNameA}</div>
                        {doubles && <div className='player-name B'>{statMatch.team1.firstNameB} {statMatch.team1.lastNameB}</div>}
                    </div>
                    <div className={`team-2 ${reversed} ${adDeuce} ${doubles ? 'doubles' : ''} ${ matchWon || !inProgress ? 'noPlayers' : ''}`} >
                        <div className='player-imgs'>
                            {!reversed && <img className={`ballbounce ${team2ShowBall && matchState == 'play' ? 'serving' : ''}`} src="/assets/images/scores/slamtracker/backgrounds/BallBounce.gif" alt="serve indicator" />}
                            <div className={`player-img A`}>
                                <Headshot
                                    className="player-headshot"
                                    id={statMatch.team2.idA}
                                    type="round"
                                    bg={true}
                                />
                            </div>
                            {doubles && <div className={`player-img B`}>
                                <Headshot
                                    className="player-headshot"
                                    id={statMatch.team2.idB}
                                    type="round"
                                    bg={true}
                                />
                            </div>}
                            {reversed && <img className={`ballbounce ${team2ShowBall && matchState == 'play' ? 'serving' : ''}`} src="/assets/images/scores/slamtracker/backgrounds/BallBounce.gif" alt="serve indicator" />}
                        </div>
                        <div className='player-name A'>{statMatch.team2.firstNameA} {statMatch.team2.lastNameA}</div>
                        {doubles && <div className='player-name B'>{statMatch.team2.firstNameB} {statMatch.team2.lastNameB}</div>}
                    </div>

                    {/** center box containing 'changeover' etc. these are all hidden instead of removed from the dom so they can fade in/out */}
                    <div className='service-box'>
                        {genNotices()}
                    </div>
                </div>
                {/* <div className='doubles-alley bottom'></div> */}
            </div>
        </div>
        </>
    );
}

export default Visualisation;
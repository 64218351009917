import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapSateToProps = (state, props) => {
	return {
		...state['PlayerStatsPage'],
	};
};
const mapDispatchToProps = (dispatch, props) => ({});

class PlayerEventStats extends Component {
	constructor(props) {
		super(props);
		this.state = { filters: this.props.filters, ...this.props.data, selectedIndex: 0 };
		this.eventStats = [];
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	componentDidUpdate(prevProps, prevState) {}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	setDataIndex(currFilter) {
		let defaultIndex = 0;
		for (let i = 0; i < this.eventStats.length; i++) {
			if (this.eventStats[i].stat_label == currFilter) {
				return i;
			}
		}
		return defaultIndex;
	}

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	formatLabel(labelData) {
		return labelData
			.replace('_', ' ')
			.split(' ')
			.map(d => d.charAt(0).toUpperCase() + d.substring(1))
			.join(' ');
	}

	render() {
		let currFilterIndex;
		let selectedDataKey;
		let selectedData;
		let selectedDataTitle;
		let headerKey;
		let header;

		//filter out extra player id and tv name data
		this.eventStats = this.props.data.event_stats.map((d, i) => {
			if (i == 0) {
				return d;
			} else {
				return {
					...d,
					stat_lines: d.stat_lines.map(d1 => {
						let copyD = {
							...d1,
						};
						if (!copyD.players) {
							copyD['player'] = copyD.tv_name;
						}
						if (!copyD.score) {
							copyD[this.props.filters.playerStats] = copyD.stat;
						}
						if (copyD['nation']) {
							copyD['country'] = copyD['nation'];
						}
						delete copyD.nation;
						delete copyD.stat;
						delete copyD.id;
						delete copyD.tv_name;
						delete copyD.first_name;
						delete copyD.last_name;
						return copyD;
					}),
				};
			}
		});

		if (this.state.filters && this.eventStats) {
			currFilterIndex = this.setDataIndex(this.state.filters.playerStats);
			selectedDataKey = this.eventStats[currFilterIndex];
			selectedData = selectedDataKey.summary_stats ? selectedDataKey.summary_stats : selectedDataKey.stat_lines;
			selectedDataTitle = selectedDataKey.stat_title;
			headerKey = this.eventStats[currFilterIndex];
			header = headerKey.summary_stats
				? Object.keys(headerKey.summary_stats[0])
				: Object.keys(headerKey.stat_lines[0]);
			header = header.filter(d => d != 'stat_label').map(d => this.formatLabel(d));
		}
		return this.state.filters && this.eventStats ? (
			<div className="event-stats four-col page-one">
				<div className="table-title">
					<a
						onClick={() => {
							this.setSelected(0);
						}}>
						<i className="wim-icon-left-arrow left-icon" />
					</a>
					<div className={`left-nav-dot ${this.state['selectedIndex'] == 0 ? 'selected' : ''}`}>&#9679; </div>
					<div className={`right-nav-dot ${this.state['selectedIndex'] == 1 ? 'selected' : ''}`}>
						{' '}
						&#9679;
					</div>
					<a
						onClick={() => {
							this.setSelected(1);
						}}>
						<i className="wim-icon-right-arrow right-icon" />
					</a>
				</div>
				<div className="datatable">
					<div className="title">{selectedDataTitle}</div>
					<div className="headers">
						{currFilterIndex == 0 ? (
							<div className="cell first-col" />
						) : (
							<div className="cell first-col">{Object.keys(selectedData[0])[0]}</div>
						)}
						{header.map((d, i) => {
							return currFilterIndex == 0 ? (
								<div
									key={`header ${i}`}
									className={`cell ${i <= 2 ? 'early-col' : 'late-col'} ${
										i <= 2 && this.state['selectedIndex'] == 0
											? 'selected'
											: i > 2 && this.state['selectedIndex'] == 1
											? 'selected'
											: ''
									}`}>
									{d}
								</div>
							) : i !== 0 ? (
								<div
									key={`header ${i}`}
									className={`cell ${i <= 3 ? 'early-col' : 'late-col'} ${
										i <= 3 && this.state['selectedIndex'] == 0
											? 'selected'
											: i > 3 && this.state['selectedIndex'] == 1
											? 'selected'
											: ''
									}`}>
									{d}
								</div>
							) : null;
						})}
					</div>
					{selectedData.map((d, i) => {
						return (
							<div key={i} className="row">
								{Object.keys(d).map((d1, j) => {
									return (
										<div
											key={j}
											className={`cell ${
												j == 0 && currFilterIndex == 0
													? 'first-col'
													: j !== 0 && j <= 3
													? 'early-col'
													: 'late-col'
											} ${
												j !== 0 && j <= 3 && this.state['selectedIndex'] == 0
													? 'selected'
													: j > 3 && this.state['selectedIndex'] == 1
													? 'selected'
													: ''
											}`}>
											{d[d1]}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		) : null;
	}
}

export default connect(mapSateToProps, mapDispatchToProps)(PlayerEventStats);

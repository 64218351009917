import React, { Component } from 'react';
import { connect } from 'react-redux';

import deps from 'dependencies';
import { NavLink } from 'react-router-dom';
import Script from 'react-load-script';
import op from 'object-path';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { gigyaScreen } from 'appdir/components/general/Gigya/utils';
import { AuthProvider, AuthConsumer } from 'appdir/components/general/Gigya/context';
import { getQuerystringValues } from 'appdir/components/general/Util';

import Login from './screens/Login';
import Registration from './screens/Registration';
import EditProfile from './screens/EditProfile';
import ForgotPassword from './screens/ForgotPassword';
import ForgotPasswordSuccess from './screens/ForgotPasswordSuccess';
import ForgotPasswordSuccessLTA from './screens/ForgotPasswordSuccessLTA';
import ResetPassword from './screens/ResetPassword';
import ResetPasswordSuccess from './screens/ResetPasswordSuccess';
import ResetPasswordFail from './screens/ResetPasswordFail';
import ChangePassword from './screens/ChangePassword';
import RegistrationCompletion from './screens/RegistrationCompletion';
import RegistrationSuccess from './screens/RegistrationSuccess';
import RegistrationSuccessPostcode from './screens/RegistrationSuccessPostcode';
import LoginSuccess from './screens/LoginSuccess';
import Communication from './screens/Communication';
import LinkAccount from './screens/LinkAccount';
import VerificationPending from './screens/VerificationPending';
import VerificationSent from './screens/VerificationSent';
import VerificationCodeAuth from './screens/VerificationCodeAuth';
import { title } from './translations/title';
import isEmpty from 'lodash/isEmpty';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureAnonymous } from 'appdir/components/general/Gigya/Measure';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		favourites: state['Controller']['favourites']['players'],
		windowSize: state['PageHeader'].windowSize,
		configVal: state['Config'],
		promoFeature: state['Controller']['promoFeature'],
		Controller: state['Controller'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: data => dispatch(deps.actions.Gigya.mount(data)),
	onLogout: data => dispatch(deps.actions.Gigya.onLogout(data)),
	setGigyaLoaded: data => dispatch(deps.actions.Gigya.setGigyaLoaded(data)),
	transferSession: data => dispatch(deps.actions.Gigya.transferSession(data)),
	getAccountInfo: params => dispatch(deps.actions.Gigya.getAccountInfo(params)),
	togglePromoFeature: (value, isReg) => dispatch(deps.actions.PromoFeature.togglePromoFeature(value, isReg)),
	checkJWT: data => dispatch(deps.actions.Gigya.checkJWT(data)),
});

class Gigya extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.gtk = null;

		// if not a webview, start gigya
		// else, if a webview and has a gtk, start Gigya with gtk
		// else, if a webview and myW enabled, start Gigya
		window.onGigyaServiceReady = () => {
			this.GigyaLoadFunctions();
		};

		window.gigyaScreenError = err => {
			logger.error('[Gigya] gigyaScreenError - err:%o', err);
		};

		window.gigyaScreenSubmit = ev => {
			logger.log('[Gigya] gigyaScreenSubmit - ev:%o', ev);
		};

		this.clickNav = this.clickNav.bind(this);
	}

	componentDidMount() {
		//logger.log('[Gigya] componentDidMount - state:%o props:%o', this.state, this.props);

		this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[Gigya] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	getTranslation(obj) {
		return Function('"use strict";return (' + obj + ')')();
	}

	componentDidUpdate(prevState) {
		let prevUser = op.get(prevState, 'currentUser', {});
		let currentUser = op.get(this.state, 'currentUser', {});

		if (prevUser.UID != currentUser.UID) {
			//logger.log('[Gigya] componentDidUpdate - new UID:%o', currentUser.UID);
			//MeasurementUtils.dispatchMeasurementCall('myW_User', { UID: currentUser.UID });
		}

		//if (!isEmpty(this.state.currentUser)){
		logger.log('[Gigya] componentDidUpdate - state:%o', this.state);
		//}

		let set = document.getElementById('local-screen-set');

		if (set) {
			let matches = set.querySelectorAll('[data-translation-key]');
			Array.prototype.forEach.call(matches, match => {
				let text = screenset.translations[match.getAttribute('data-translation-key')];
				//logger.log('[Gigya] componentDidUpdate - match:%o text:%o', match, text);
				match.innerHTML = text;
			});

			matches = set.querySelectorAll('[data-gigya-placeholder]');
			//logger.log('[Gigya] componentDidUpdate - matches:%o ', matches);
			this.changeData(matches, 'data-gigya-placeholder');

			matches = set.querySelectorAll('[aria-label]');
			//logger.log('[Gigya] componentDidUpdate - matches:%o ', matches);
			this.changeData(matches, 'aria-label');

			matches = set.querySelectorAll('[data-caption]');
			//logger.log('[Gigya] componentDidUpdate - matches:%o ', matches);
			this.changeData(matches, 'data-caption');

			matches = set.querySelectorAll('input[value]');
			//logger.log('[Gigya] componentDidUpdate - matches:%o ', matches);
			this.changeData(matches, 'value');

			matches = set.querySelectorAll('[data-label]');
			//logger.log('[Gigya] componentDidUpdate - matches:%o ', matches);
			this.changeData(matches, 'data-label');
		}

		// change registration screenset classes if already rendered
		//   original class set in screenset render method
		let screen = document.querySelector('#gigya-register-screen');
		//logger.log('[Gigya] componentDidUpdate - screen:%o', screen);
		if (screen) {
			if (this.state.windowSize == 'tablet' || this.state.windowSize == 'mobile') {
				screen.classList.add('portrait');
				screen.classList.remove('landscape');
			} else {
				screen.classList.add('landscape');
				screen.classList.remove('portrait');
			}
		}
	}

	changeData(matches, label) {
		Array.prototype.forEach.call(matches, match => {
			let re = /\['(.*)'\]/g;
			//logger.log('[Gigya] componentDidUpdate - attr:%o ', match.getAttribute('data-gigya-placeholder'));
			let re_match = re.exec(match.getAttribute(label));
			//logger.log('[Gigya] componentDidUpdate - re_match:%o ', re_match);
			if (re_match && re_match.length >= 1) {
				match.setAttribute(label, screenset.translations[re_match[1]]);
			}
			//logger.log('[Gigya] componentDidUpdate - match:%o ', match);
		});
	}

	handleScriptCreate() {
		this.setState({
			scriptLoaded: false,
		});
		//window.include = 'id-token';
	}

	handleScriptError() {
		this.setState({
			scriptError: true,
		});
	}

	handleScriptLoad() {
		//logger.log('[Gigya] handleScriptLoad - state:%o props:%o', this.state, this.props);
		this.setState({
			scriptLoaded: true,
		});
	}

	showScreen(obj) {
		//logger.log('[Gigya] showScreen - obj:%o', obj);
	}

	GigyaLoadFunctions() {
		let cookieFound = false;
		let allCookies = document.cookie.split('; ');
		for (var i = 1; i <= allCookies.length; i++) {
			//logger.log('[Gigya] GigyaLoadFunctions - cookie:%o', allCookies[i-1]);
			if (allCookies[i - 1].indexOf(`glt_${this.state.gigyaApiKey}`) == 0) {
				cookieFound = true;
			}
		}

		if (cookieFound) {
			this.props
				.checkJWT()
				.then(token => {
					this.continueLoad(true);
				})
				.catch(error => {
					this.continueLoad(false);
				});
		} else {
			this.continueLoad(false);
		}
	}

	continueLoad(cookieFound) {
		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
		let gtk = op.get(parsedQs, 'gtk', null);
		let myw = op.get(parsedQs, 'enablemyw', null);
		let site = op.get(parsedQs, 'site', null);

		//logger.log('[Gigya] GigyaLoadFunctions - partner:%s', window.gigya.partnerSettings.globalConf);
		//window.gigya.partnerSettings.globalConf = "{\r\n\tinclude: \"profile, data, emails, subscriptions, preferences, address\",\r\n\textraProfileFields: \"address\",\r\n\r\n    // A comma-delimited list of provider names to enable.\r\n    enabledProviders: '*',\r\n\r\n    // Define the language of Gigya's user interface and error message.\r\n    lang: 'en',\r\n    \r\n    // 'remember me' selection keeps logged in for 21 days\r\n    gigyaPlugins: {\r\n    \trememberSessionExpiration: 1814400\r\n    },\r\n    \r\n    // List of data items for include param, \"e.g. account.login optional param\"\r\n   // include: \"data, profile, subscriptions, preferences, id_token\",\r\n    \r\n    // Bind globally to events.\r\n    // See: http://developers.gigya.com/display/GD/Events#Events-OverridingtheDefaultEventMap\r\n    customEventMap: {\r\n        eventMap: [\r\n        \t{\r\n\t\t\t\tevents: 'afterResponse',\r\n\t\t\t\targs: [function(e) {\r\n       \t\t\t\treturn e;\r\n   \t\t\t\t}],\r\n       \t\t\tmethod: function(e) {\r\n   \t\t\t\t\tif (e.methodName === 'accounts.resetPassword') {\r\n                  \t\te.response.errorCode = 0;\r\n         \t\t\t}\r\n         \t\t}\r\n    \t\t}\r\n         \t\r\n   \t\t]\r\n    }\r\n    \r\n}"

		//logger.log('[Gigya] GigyaLoadFunctions - partner:%s', window.gigya.partnerSettings.globalConf);

		//if believe user is logged in, then getAccountInfo
		//  else indicate Gigya is loaded (wait for login)
		//handle webviews and transfer sessions
		logger.log('[Gigya] continueLoad - loggedIn:%o myw:%o site:%o state:%o', cookieFound, myw, site, this.state);
		if (!window.webview) {
			if (!gtk) {
				if (cookieFound) {
					this.props.getAccountInfo({ src: 'gigyaLoad' });
				} else {
					this.props.setGigyaLoaded({ gigyaLoaded: true });
					// NOTE: not logged in?
					measureAnonymous(this.state.favourites);
				}
			} else {
				this.props.transferSession(gtk);
			}
		} else {
			if (site == 'mobile') {
				this.props.setGigyaLoaded({ gigyaLoaded: true });
			} else if (gtk) {
				this.props.transferSession(gtk);
			} else if (myw) {
				if (cookieFound) {
					this.props.getAccountInfo({ src: 'gigyaLoad' });
				} else {
					this.props.setGigyaLoaded({ gigyaLoaded: true });
				}
			}
		}

		gigya.accounts.addEventHandlers({
			// listen for login and register screen callbacks instead
			//   since they have common data response
			onLogin: this.onGigyaLogin.bind(this),
			onLogout: this.onGigyaLogout.bind(this),
		});

		// this.props.gigyaLoaded({
		// 	gigyaLoaded: true,
		// });
	}

	/**
	 * handles login event from Gigya
	 * commented out since looking for screen events in actions to handle login/join
	 * @param {*} eventObj
	 */
	onGigyaLogin(eventObj) {
		logger.log('[Gigya] onGigyaLogin - obj:%o', eventObj);
	}

	onGigyaLogout(eventObj) {
		logger.log('[Gigya] onGigyaLogout - obj:%o', eventObj);
		this.props.onLogout(null);
	}

	clickNav(loc) {
		logger.log('[Gigya] clickNav - loc:%o', loc);
	}

	render() {
		logger.log('[Gigya] render - state:%o', this.state);

		if (this.state.gigyaScriptUrl) {
			return (
				<noindex id="gigya">
					<AuthProvider
						value={{
							socialProviders: this.state.enabledProviders,
							currentUser: this.state.currentUser,
							profile: this.state.profile,
							user: this.state.user,
							location: this.state.path,
							myWimEnabled: this.state.myWim.active,
							screen: this.state.screen,
							windowSize: this.state.windowSize,
							email: this.state?.screen?.email,
						}}>
						<div
							id="local-screen-set"
							className="gigya-screen-set"
							data-width="500"
							data-height="600"
							data-on-pending-password-change-screen="gigya-password-change-required-screen"
							data-on-pending-registration-screen="gigya-complete-registration-screen"
							data-on-missing-loginid-screen="gigya-complete-registration-screen"
							data-on-existing-login-identifier-screen="gigya-link-account-screen"
							data-on-pending-verification-screen="gigya-verification-pending-screen"
							data-on-pending-email-verification-code="gigya-email-code-verification-screen">
							<Login />
							<Registration />
							<RegistrationCompletion />
							<RegistrationSuccess onClick={loc => this.clickNav()} />
							<RegistrationSuccessPostcode onClick={loc => this.clickNav()} />
							<EditProfile />
							<ForgotPassword />
							<ForgotPasswordSuccess onClick={loc => this.clickNav(loc)} />
							<ForgotPasswordSuccessLTA onClick={loc => this.clickNav(loc)} />
							<ResetPassword />
							<ResetPasswordSuccess onClick={loc => this.clickNav(loc)} />
							<ResetPasswordFail onClick={loc => this.clickNav(loc)} />
							<ChangePassword />
							<LoginSuccess />
							<Communication />
							<LinkAccount />
							<VerificationPending onClick={loc => this.clickNav(loc)} />
							<VerificationSent onClick={loc => this.clickNav(loc)} />
							<VerificationCodeAuth onClick={loc => this.clickNav(loc)} />

							<Script
								url={this.state.gigyaScriptUrl}
								onCreate={this.handleScriptCreate.bind(this)}
								onError={this.handleScriptError.bind(this)}
								onLoad={this.handleScriptLoad.bind(this)}
							/>
						</div>
					</AuthProvider>
				</noindex>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Gigya);

import VideoIndex from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	// {
	//     path: '/:lang/interactive/multimedia.html:type?',
	//     exact: true,
	//     component: VideoIndex,
	//     load: params => deps.actions.VideoIndex.mount(params),
	// },
	{
		path: '/:lang/video/:category.html',
		exact: true,
		component: VideoIndex,
		section: MENU_ITEMS.videoRadio,
		load: params => deps.actions.VideoIndex.mount(params),
	},
	{
		path: '/:lang/video/media/:videoId.html',
		exact: true,
		component: VideoIndex,
		section: MENU_ITEMS.videoRadio,
		load: params => deps.actions.VideoIndex.mount(params),
	},
];

import ContactThankYou from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: '/:lang/contact/ThankYou.html',
		exact: true,
		component: ContactThankYou,
		section: MENU_ITEMS.about,
		load: params => deps.actions.ContactThankYou.mount(params),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { createRef, useEffect, useState, Children } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { doMeasurement } from 'appdir/components/general/Analytics';
import Slider from 'react-slick';


/**
 * -----------------------------------------------------------------------------
 * React Component: GenericCarousel
 * @sliderSettings - array - settings for the carousel, see Slick docs for what's accepted & test page for sample
 * @sliderSettingsOverride - props - obj, optional
 * @className - string - props - css class, optional
 * @metricsData - Object - props - pass only the data that are necessary, doMeasurement() will apply the data accordingly for www and webviews
 * Example: 
 * 	metricsData = {
        pageTitle: "", // string, required for webview, don't need it for www but if passed, it's added as a metrics value for Arrow or Swipe
        arrowMetrics: {
            measureAction: "", 	// string, optional - single value, Arrow or Swipe is set by default. passed in value will be added to the default value
            measureArgs: [], 	// array, optional - for webview only
            contextData: [{data: "value"}],  // [{}], optional

        },
        swipeMetrics: {
            measureAction: "",
            measureArgs: [], 
            contextData: [{cmu_playerID: "<Player ID || summaryDay<#>"}, {cmu_player:"<Player Name || summaryDay<#>"}],
        }
	}
 * @slideWidth - Integer - each slide width, required
 * 
 * - You don't have to add the 'useEffect' setIntersecting function anymore, that's done by the GenericCarousel
 * - There are now default settings for the carousel, which may be overridden with <GenericCarousel sliderSettingsOverride={yourSettings}>...
 * - You no longer need to wrap your items with a <div> as the GenericCarousel component will do that for you, and add keys
 * - You should continue to pass className as that will be appended to the keys 
 * -----------------------------------------------------------------------------
 */
const GenericCarousel = props => {
    let {sliderSettingsOverride, className, children, metricsData, slideWidth} = props;
	let arrowMetrics = metricsData?.arrowMetrics;
	let swipeMetrics = metricsData?.swipeMetrics;
	let slideSize = slideWidth ?? 350; //set a default slide size if not sent in props

	/** metrics related vars
	 * 
	 *  for www, if pageTitle is passed, add it before Arrow or Swipe val
	 *  so it indicates which carousel it is 
	 *  
	 *  webview adds pageTitle in doMeasurement();
	 */
	let pageTitle = metricsData?.pageTitle || '';
	let arrowMetricsVal = pageTitle !== '' && pageTitle && !window.webview ? `${pageTitle}:Arrow` : 'Arrow';
	let swipeMetricsVal = pageTitle !== '' && pageTitle && !window.webview ? `${pageTitle}:Swipe` : 'Swipe';

	let initLoad = true;

	const [isIntersecting, setIntersecting] = useState(false);
	const [isFirstIntersecting, setFirstIntersecting] = useState(false);
	const [swipeCount, setSwipeCount] = useState(0);
	const [hasSetPosition, setHasSetPosition] = useState(false);

	const sliderRef = createRef();  // for the slider ourter wrapper
	const sliderRef2 = createRef(); // for the <Slider> -- used to workaroud the initialSlide > 1 and infinite: false bug
	const [toShow, setToShow] = useState(false);

	const lastRef = createRef();
	const firstRef = createRef();

	/**
	 * to address the react-slick bug 
	 * when infinite is false and initialSlide > 1
	 * https://github.com/akiran/react-slick/issues/1946
	 */
	useEffect(() => {
		if (sliderRef2?.current && !hasSetPosition && sliderSettingsOverride?.initialSlide) {
			sliderRef2?.current?.slickGoTo(sliderSettingsOverride?.initialSlide);
		  	setHasSetPosition(true);
		}
	  }, [sliderSettingsOverride?.initialSlide, hasSetPosition, sliderRef2]);

    /**
     * This is for showing a specific number of items depending on component width
     * Taken from Shop Carousel
     */
	useEffect(() => {
		let robserverRefValue = null;
		//logger.log('[GenericCarousel] resize entries:%o, width:%o');
		const robserver = new ResizeObserver(entries => {
			//logger.log('[GenericCarousel]  resize entries:%o, width:%o', entries, entries[0].contentRect.width);

			let observerWidth = entries[0].contentRect.width;
			let sliderTrackWidth = (observerWidth);
			let cnt = Math.floor(sliderTrackWidth / slideSize);
			logger.log('[GenericCarousel] resize toShow:%o sliderTrackWidth:%o slideSize:%o', cnt, sliderTrackWidth, slideSize);
			setToShow(cnt);
		});

		if (sliderRef.current) {
			robserverRefValue = sliderRef.current;
			robserver.observe(sliderRef.current);
		}

		return () => {
			if (robserverRefValue) robserver.unobserve(robserverRefValue);
		};
	}, [sliderRef]);


	/** This tests to see if the last item is visible and hides the "next" button if it is
	* Set the threshold to 1.0 to make sure it is entirely in view
	*/
	useEffect(() => {
		if(firstRef.current){
			// logger.log('[GenericCarousel] firstRef.current:%o', firstRef.current);
			let options = {
				threshold: 1.0,
			  };
			const observer = new IntersectionObserver(([entry]) =>
				setFirstIntersecting(entry.isIntersecting)
				,options);
		
			observer.observe(firstRef.current);
			return () => {
				observer.disconnect();
			};
		}
	}, [firstRef]);

	useEffect(() => {
		if(lastRef.current){
			// logger.log('[GenericCarousel] lastRef.current:%o', lastRef.current);
			let options = {
				threshold: 1.0,
			  };
			const observer = new IntersectionObserver(([entry]) =>
				setIntersecting(entry.isIntersecting)
				,options);
		
			observer.observe(lastRef.current);
			return () => {
				observer.disconnect();
			};
		}
	}, [lastRef]);

	/** initCallback  */
	useEffect(() => {
		if (initLoad && toShow && props?.onInitCallback) {
			initLoad = false;
			props?.onInitCallback(toShow);
		}
	}, [toShow, initLoad]);
	

	/**
	 * Find if it's the first slide or the last slide 
	 * to apply a certain class to hide 
	 * prev/next arrow accordingly
	 * @param {childProp} Array 
	 * @param {idx} Integer 
	 * @returns firstRef || lastRef || null
	 */
	const getRef = (childProp, idx) => {
		if(idx == 0) {
			return firstRef
		} else if (idx == (childProp.length - 1) && idx !== 0) {
			return lastRef
		} else {
			return null
		}
	}

	const addRef = childProp => {
		 let mappedChildren = Children.map(childProp, (child, idx) => 
			<div ref={getRef(childProp, idx)} key={`gen-carousel-${className}${idx}`}>
				{child}
			</div>
		);
		return mappedChildren;
	}
      
    /** use the same arrow that react-slick generates
    *  add onClick callback for metrics
    */
    function PrevArrow(data) {
        const { className, style, onClick } = data;
        return (
            <div className="slickPrevArrow" onClick={() => {
				let measureAction = arrowMetrics?.measureAction || arrowMetricsVal; // string and single value
                let measureArgs =  arrowMetrics?.measureArgs || []; // not used for www

				/** if there are custom measureArgs data, add ${direction} to the data */
                let contextData = arrowMetrics?.contextData 
					? [...arrowMetrics?.contextData, {direction: "Prev"}]
					: [{direction: "Prev"}];

                if (window.webview) {
					/** if there are custom measureArgs data, add 'Prev' to the data */
                    measureArgs = arrowMetrics?.measureArgs 
						? [...arrowMetrics?.measureArgs, 'Prev'] 
						: ['Prev'];
                }

                doMeasurement(pageTitle, measureAction, measureArgs, contextData);

            }}>
			
					<button
						role="button"
						className={className}
						//style={{ ...style}}
						onClick={onClick}
						aria-label="Arrow Prev"
						tabIndex={0}
					/>
			
            </div>
        );
    }

	 /** use the same arrow that react-slick generates
     *  add onClick callback for metrics
     */
	 function NextArrow(data) {
        const { className, style, onClick } = data;
        return (
            <div className="slickNextArrow" onClick={() => {
				let measureAction = arrowMetrics?.measureAction || arrowMetricsVal; // string and single value
                let measureArgs =  arrowMetrics?.measureArgs || []; // not used for www

				/** if there are custom measureArgs data, add ${direction} to the data */
                let contextData = arrowMetrics?.contextData 
					? [...arrowMetrics?.contextData, {direction: 'Next'}]
					: [{direction: 'Next'}];

                if (window.webview) {
					/** if there are custom measureArgs data, add 'Next' to the data */
                    measureArgs = arrowMetrics?.measureArgs 
						? [...arrowMetrics?.measureArgs, 'Next'] 
						: ['Next'];
                }

                doMeasurement(pageTitle, measureAction, measureArgs, contextData);
            }}>
			
					<button
						role="button"
						className={className}
						//style={{ ...style}}
						onClick={onClick}
						aria-label="Arrow Next"
						tabIndex={0}
					/>
				
         	</div>
        );
    }

	/** set up and send onSwipe measurement data */
	const sendSwipeMetrics = (direction, count) => {
		let measureAction = swipeMetrics?.measureAction || swipeMetricsVal; // string and single value
		let measureArgs =  swipeMetrics?.measureArgs || []; // this is for webview and it's not used for www

		/** if there are custom measureArgs data, add ${direction} and ${count} to the data */
		let contextData = swipeMetrics?.contextData 
							? [...swipeMetrics?.contextData, {direction: direction, count: count}] 
							: [{direction: direction, count: count}];

		if (window.webview) {
			/** if there are custom measureArgs data, add ${count} to the data */
			measureArgs = swipeMetrics?.measureArgs 
							? [...swipeMetrics?.measureArgs, count] 
							: [count];
		}

		doMeasurement(pageTitle, measureAction, measureArgs, contextData);
	}

	const getIntersectingClass = () => {
		let intersectingClass = '';

		if (isFirstIntersecting) {
			//intersectingClass = "hide-prev-arrow";
		} else if (isIntersecting) {
			intersectingClass = 'lastInView';
		}

		return intersectingClass;
	}

	let combinedSettings = {}

	let sliderSettings = {
		dots: false,
		arrows: true,
		infinite: false,
		speed: toShow <= 1 ? 300 : 500,
		slidesToShow: toShow == 0 ? 1 : toShow,
		slidesToScroll: toShow == 0 ? 1 : toShow,
		autoplay: false,
		variableWidth: false,
		draggable: false,
		swipeToSlide: false,
		swipe: isMobile ? true : false,
		// initialSlide: 0,
		// centerMode: false,
		
	};

	let sliderMetricsSettings = {
		nextArrow: <NextArrow />,
    	prevArrow: <PrevArrow />,

        onSwipe: (direction) => {
			let count = swipeCount+1;

			sendSwipeMetrics(direction, count);

			/** if there is a callback for onSwipe action, call it */
			if (props?.onSwipeCallback) {
				props.onSwipeCallback();
			}

			/** update swipe count state */
			setSwipeCount(count);
        },
        afterChange: (index) => {
			/** if there is a callback for afterChange action, call it
			 *  use case in AICatchUpHome/index.js:
			 *  update current slide number to send metrics data 
			 *  with specific data such as player ID
			*/
			if (props?.afterChangeCallback) {
				props.afterChangeCallback(index, toShow);
			}
        }
	}

	if(sliderSettingsOverride){
		//force initial slide to 0 since doing the "fix" for goto above
		combinedSettings = {...sliderSettings, ...sliderSettingsOverride, ...sliderMetricsSettings, ...{'initialSlide': 0}};
	}
	else{
		combinedSettings = {...sliderSettings, ...sliderMetricsSettings};
	}
	
	logger.log('[GenericCarousel] render combinedSettings:%o', combinedSettings);
	return (
		<div className={`generic-carousel ${className} ${getIntersectingClass()}`} ref={sliderRef}>
			<Slider {...combinedSettings} ref={sliderRef2}>
				{addRef(children)}		
			</Slider>
		</div>
	);
};

export default GenericCarousel;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { eventDef, getCorrectEventId, getRoute } from './DrawsUtils';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import { doMeasurement } from 'appdir/components/general/Analytics';

const mapStateToProps = (state, props) => {
	//return Object.assign({}, state['DrawsPage'], props);
	return {
		...state['DrawsPage'],
		tourn_events: state.Config?.scoringConfig?.eventNames,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	update: data => dispatch(deps.actions.DrawsPage.update(data)),
	changeView: data => dispatch(deps.actions.DrawsPage.changeView(data)),
});

class DrawsNavigator extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		logger.log('[DrawsNavigator] constructor this:%o', this);
	}

	componentDidMount() {
		//logger.log('[DrawsNavigator] componentDidMount state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// this.setState(prevState => {
		// 	return Object.assign({}, prevState, nextProps);
		// });
	}

	componentDidUpdate(prevState) {
		logger.log('[DrawsNavigator] componentDidUpdate this:%o', this);
		if (this.props.round == 'full') {
			this.onChangeView('fullView');
		} else {
			this.onChangeView('round');
		}
	}

	getRoundHalf(section) {
		logger.log('[DrawsNavigator] getRoundHalf section:%o props:%o', section, this.props);

		return (
			<div className="draws-nav-column half">
				<span className={`generic-button_wrapper ${this.props.section == '1' ? 'clicked' : ''}`}>
					<ButtonLinkText
						className={`generic-button btn center trans-20 compact border-r5`}
						ariaLabel="1ST HALF"
						onClick={() => {
							this.onHalfSelect('1');
						}}>
						<span className="content">1ST HALF</span>
					</ButtonLinkText>
				</span>
				<span className={`generic-button_wrapper ${this.props.section == '2' ? 'clicked' : ''}`}>
					<ButtonLinkText
						ariaLabel="2ND HALF"
						className={`generic-button btn center trans-20 compact border-r5`}
						onClick={() => {
							this.onHalfSelect('2');
						}}>
						<span className="content">2ND HALF</span>
					</ButtonLinkText>
				</span>
			</div>
		);
	}

	getRoundLabel(round, labelIndex) {
		//logger.log('[DrawsNavigator] getRoundLabel round:%o, labelIndex:%o', round, labelIndex);
		let labelArray = this.props.roundNames;
		let bracketName = round.toString();

		for (let i = 0; i < labelArray.length; i++) {
			if (labelArray[i].roundCode == round) {
				let roundNumber = labelIndex + 1;

				// logger.log(
				// 	'[DrawsNavigator] getRoundLabel round:%o roundNumber:%o, bracketName:%o',
				// 	round,
				// 	roundNumber,
				// 	bracketName,
				// 	this.props.round
				// );

				return (
					<span key={i} className={`round`}>
						<ButtonLinkText
							className={`item ${
								this.props.round == roundNumber && this.props.view == 'round' ? 'selected' : ''
							}`}
							ariaLabel={labelArray[i].roundName}
							onClick={() => {
								// this.onRoundSelect(roundNumber, 'round');
								this.onRoundSelect(roundNumber, 'round');
							}}>
							{labelArray[i].roundNameShort}
						</ButtonLinkText>
					</span>
				);
			}
		}
	}

	getBracketLabel(round) {
		//logger.log('[DrawsNavigator] function passed in value:%o', round);
		let labelArray = this.props.roundNames;

		for (var i = 0; i < labelArray.length; i++) {
			if (labelArray[i].roundCode == round) {
				return (
					<div key={i} className={`round${labelArray[i].roundCode}`}>
						<span className="long">{labelArray[i].roundName}</span>
						<span className="short">{labelArray[i].roundNameShort}</span>
					</div>
				);
			}
		}
	}

	onHalfSelect = section => {
		logger.log('[DrawsNavigator] onHalfSelect - section:%o', section);

		// MeasurementUtils.dispatchMeasurementCall('drawsNavigatorBracketSelector', {
		// 	round,
		// 	webview: window.webview ? true : false,
		// });

		//this.props.setEventUrl(round, section);

		let half = section == '1' ? '1st Half' : section == '2' ? '2nd Half' : '';

		let measureArg0 = 'Half Selector';
		let measureArgs = [`${half}`];
		let contextData = [{ toggle: `${half}` }];
		let metricType = '';

		if (values.webview) {
			measureArg0 = this.props.eventName;
			measureArgs = [`${half}`];
			contextData = [];
			metricType = 'action';
		}

		doMeasurement('Draws', measureArg0, measureArgs, contextData, metricType);

		this.props.onNavigatorSelect(this.props.round, section);
	};

	onRoundSelect = (round, view) => {
		// let correctedEvent = getCorrectEventId(this.props.event, this.totalRounds);
		// let eventData = eventDef[correctedEvent]?.roundNum;

		this.onChangeView(view);

		// logger.log('[DrawsNavigator] onRoundSelect - round:%o', round);
		logger.log('[DrawsNavigator] onRoundSelect - round:%o, view:%o', round, view);

		let measureArg0 = 'Round Selector';
		let measureArgs = [`${round}`];
		let contextData = [{ round: `${round}` }];
		let metricType = '';

		if (values.webview) {
			measureArg0 = this.props.eventName;
			measureArgs = [`${round}`];
			contextData = [];
			metricType = 'action';
		}

		if (view == 'fullView') {
			measureArgs = ['FD'];
		} 

		doMeasurement('Draws', measureArg0, measureArgs, contextData, metricType);

		this.props.onNavigatorSelect(round, null, view);
	};

	onFullDraw() {
		//logger.log('[DrawsNavigator] onFullDraw - round:%o', round);
	}

	onChangeView(view) {
		this.props.changeView({ view });
	}

	render() {
		//logger.log('[DrawsNavigator] render - this:%o', this);
		/**
		 * can't just check for this.props.round because the initial
		 * value is 0 which evaluates to false
		 */
		if (this.props.round !== '') {
			let correctedEvent = getCorrectEventId(this.props.event, this.props.totalRounds);

			let labelDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundNum : null;

			if (labelDisp) {
				return (
					<>
						<div className="column-layout fixedScroll">
							<div className="header">
								<div className="navItemFull rounds">
									<div className="draws-nav">
										{this?.props?.showFullView ? (
											<span className={`round full`}>
												<ButtonLinkText
													className={`item ${this.props.round == 'full' ? 'selected' : ''}`}
													onClick={() => this.onRoundSelect(1, 'fullView')}>
													Full
													<br />
													Draw
												</ButtonLinkText>
											</span>
										) : null}
										{Object.keys(labelDisp).map((round, i) => {
											return this.getRoundLabel(round, i);
										})}
									</div>
								</div>

								{this.props.view !== 'fullView' &&
								labelDisp[this.props.round] == 2 &&
								this.props.round < 3 ? (
									<>{this.getRoundHalf(this.props.section)}</>
								) : null}
								<div className="background"></div>
							</div>
						</div>
					</>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsNavigator);

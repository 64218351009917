/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { createRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: FullScreenVideo
 * component that will accept @videoId and @contentId and open the full screen video window
 * -----------------------------------------------------------------------------
 */

const FullScreenVideo = props => {
    const dispatch = useDispatch();
    const relatedVideoPath = useSelector(state => state['Config'].relatedContent.video);
    const contentId = useSelector(state => state['Config'].relatedContent.contentId);
    const [vidData, setVidData] = useState(null);
    const { videoId } = props.attributes;

    const onVideoEvent = (event) => {
		logger.info('[FullScreenVideo] onVideoEvent - event:%o', event);

		if (event == 'close') {
            props.closeVid();
            dispatch(deps.actions.VideoPlayer.fullwindow({ className: false, type: false }));
		}
	}

    const getVideo = () => {
        // videoId indicates direct video from url
        logger.log('[FullScreenVideo] - contentId:%o, videoId:%o', contentId, videoId);
        if (videoId && contentId && relatedVideoPath) {
            let vidContentId = contentId.replace('<id>', videoId);
            let relatedPath = relatedVideoPath.replace('<videoId>', vidContentId);
            logger.log('[FullScreenVideo] - relatedPath:%o', relatedPath);

            deps.services.VideoPlayer.fetchRelated(relatedPath)
                .then(result => {
                    //logger.log(`${this.name} checkVideo - related - data:%o`, result);
                    // this.setState({
                    //     directVideo: true,
                    //     relatedContentData: result,
                    // });
                    let thumbImage = op.get(result, 'images.0.xlarge', '');
                    let adPath = op.get(result, 'media.adTag', '');
                    let videoUrl = result.media.m3u8 ? result.media.m3u8 : result.media.mp4;

                    let attributes = {
                        playerId: 'main',
                        contentId: null,
                        cmsId: result.cmsId,
                        videoUrl: videoUrl,
                        adUrl: adPath,
                        shareUrl: result.shareUrl,
                        date: result.displayDate,
                        title: result.title,
                        description: result.description,
                        thumb: thumbImage,
                        autoplay: true,
                        fullscreenMode: '',
                        style: `video-wrapper modal`,
                        aspect: 'wide',
                        streamType: 'vod',
                        videoSrc: op.get(result, 'media.src', ''),
                    };

                    setVidData(attributes);
                    logger.log(`[FullScreenVideo] componentDidUpdate - related - data:%o`, result);
                })
                .catch(error => {
                    logger.warn('[FullScreenVideo] componentDidUpdate - related - %o', error);
                });
        }
    }

    useEffect(() => {
		getVideo();
	}, [contentId]);

    if(vidData){
        // logger.log(`[FullScreenVideo] vidData:%o`, vidData);
        return (
            <>
                <div className="fullscreen-video" id={videoId}>
                    <VideoPlayer attributes={vidData} onEvent={evt => onVideoEvent(evt)}/>
                </div>
            </>
        )
    }else{ return null; }
}

export default FullScreenVideo;




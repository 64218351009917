import React from 'react';
import WimLink from 'appdir/components/general/WimLink';

function CustomActionConfirmation(props) {
	const {
		fullTicketValue,
		closeModal,
		ticketData,
		headerTxt,
		detailsTxt,
		actionType,
		customActionUpdateTxt,
		error,
		recipientEmail,
		noRenderTicketStub,
		helpCenterUrl
	} = props;

	let ticketValue;
	let updatedData = [];
	let ticketStubToRender;
	if (!noRenderTicketStub) {
		ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];

		fullTicketValue.map(value => {
			let filteredArr = ticketData?.filter(d => {
				return d?.externalId === value?.externalId;
			});
			updatedData.push(filteredArr[0]);
		});

		ticketStubToRender =
			ticketValue.length > 1
				? props.onRenderMultipleTicketStubs(updatedData, error, recipientEmail)
				: props.onRenderSingleTicketStub(updatedData, error, recipientEmail);
	}

	const descriptiveText = (
		<div>
			<div className="ticket-action-modal-header" tabIndex={0} aria-label={headerTxt}>
				{headerTxt}
			</div>

			<div className={props.isWebView ? 'webview-ticket-transfer-emails-cntr' : 'ticket-transfer-emails-cntr'}>
				<div
					tabIndex={0}
					aria-label={detailsTxt}
					className={
						props.noRenderTicketStub
							? 'ticket-action-modal-bottom-margin'
							: 'ticket-action-modal-input-label ticket-action-modal-bottom-margin'
					}>
					{detailsTxt}
				</div>
			</div>
		</div>
	);

	return (
		<div className="ticket-action-modal-form">
			{((!fullTicketValue?.length || fullTicketValue?.length === 1) && !error) || fullTicketValue?.length > 1
				? descriptiveText
				: null}

			<div className="ticket-action-modal-section" style={{ display: noRenderTicketStub ? 'none' : 'block' }}>
				<div
					className="ticket-action-modal-general-label"
					style={{ justifyContent: ticketValue?.length > 1 ? 'left' : 'center' }}>
					{error ? (
						<div tabIndex={0} aria-label={`${actionType} Failure`}>
							{ticketValue?.length > 1 ? (
								''
							) : (
								<img
									className="tix-action-error-img"
									src="/assets/images/tickets/errorActionIcon.svg"
								/>
							)}
							{`${actionType} Failure`}
						</div>
					) : customActionUpdateTxt ? (
						`${customActionUpdateTxt}${ticketValue?.length > 1 ? 's:' : ':'}`
					) : (
						`Ticket${ticketValue?.length > 1 ? 's' : ''} ${
							actionType.toLowerCase() === 'transfer' ? 'Transferr' : actionType
						}ed:`
					)}
				</div>
			</div>

			{noRenderTicketStub ? null : (
				<div className="ticket-action-modal-ticket-stub ticket-action-modal-section">{ticketStubToRender}</div>
			)}

			{((!fullTicketValue?.length || fullTicketValue?.length === 1) && !error) || fullTicketValue?.length > 1
				? null
				: descriptiveText}

			<div
				style={{ display: noRenderTicketStub ? 'none' : 'block' }}
				className={
					ticketValue?.length > 1 ? 'ticket-action-modal-conf-help-wide' : 'ticket-action-modal-conf-help'
				}>
				If you have any problems with your tickets, please visit our&nbsp;
				<WimLink to={helpCenterUrl} title="wimbledon Help Centre" onClick={() => {
					closeModal('refresh')}
				}>
					Help Centre
				</WimLink>
			</div>
			<div
				className={props.isWebView ? 'webview-ticket-stub-actions-btns-cntr' : 'ticket-stub-actions-btns-cntr'}>
				<button
					role="button"
					aria-label="return to all tickets"
					alt="return to all tickets"
					className={
						props.isWebView
							? noRenderTicketStub
								? 'ticket-stub-swap-return'
								: 'webview-ticket-stub-actions-value ticket-stub-return-to-list-btn'
							: 'ticket-stub-actions-value ticket-stub-return-to-list-btn'
					}
					onClick={() => closeModal('refresh')}>
					RETURN TO ALL TICKETS
				</button>
			</div>
		</div>
	);
}
export default CustomActionConfirmation;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: ResultsPage
 * this is the completed matches page
 * -----------------------------------------------------------------------------
 */

class DaySelect extends Component {
	constructor(props) {
		super(props);

		let dayNavOptions = props.days.map(item => ({
			value: item.tournDay.toString(),
			type: item.quals ? 'quali' : 'tourn',
			dropdownName: item.messageShort,
			sort: item.tournDay,
			tournEvents: item.events,
			disabled: item.events.length == 0 ? true : false,
		}));

		this.state = {
			...props,
			dayNavOptions: dayNavOptions,
			mode: props.mode,
			selected: props.selected,
			open: false,
		};

		this.onOpen = this.onOpen.bind(this);
		this.onClose = this.onClose.bind(this);

		logger.log('[ResultsPage-DaySelect] constructor this:%o', this);
	}

	setDay = day => {
		logger.log('[ResultsPage-DaySelect] setDay:%o', day);

		this.props.onDaySelect(day);
		this.setState(prevState => {
			return {
				...prevState,
				selected: day,
				open: false,
			};
		});
	};

	onOpen(which) {
		logger.log('[ResultsPage-DaySelect] onOpen - which:%o', which);
		this.setState(prevState => {
			return {
				...prevState,
				open: which == 'days',
			};
		});
	}

	onClose(which) {
		logger.log('[ResultsPage-DaySelect] onClose - which:%o', which);
		this.setState(prevState => {
			return {
				...prevState,
				open: false,
			};
		});
	}

	setMode = mode => {
		logger.log('[ResultsPage-DaySelect] setMode - mode:%o', mode);

		let { dayNavOptions } = this.state;
		let foundDate = null;
		let foundDay = '';

		if (mode == 'tourn') {
			foundDate = dayNavOptions.find(dateOption => dateOption.disabled === false && dateOption.type == 'tourn');
			MeasurementUtils.dispatchMeasurementCall('resultsPageSetMode', 'Championships');
		} else if (mode == 'quali') {
			foundDate = dayNavOptions.find(dateOption => dateOption.disabled === false && dateOption.type == 'quali');
			MeasurementUtils.dispatchMeasurementCall('resultsPageSetMode', 'Qualifying');
		}

		if (foundDate !== undefined && foundDate !== null) {
			foundDay = foundDate.value;

			this.setDay(foundDay);
		}
	};

	render() {
		logger.log('[ResultsPage-DaySelect] render - state:%o', this.state);
		let selectedValue = this.state.selected;
		let daysData = {
			options: [],
		};

		// add available days to base archive menu options
		this.state.dayNavOptions.forEach(item => {
			if (!item.disabled) {
				daysData.options.push({
					name: item.dropdownName,
					value: item.value,
				});
			}
		});

		let found = daysData.options.filter(item => {
			return item.value == this.state.selected;
		});

		if (found.length == 0) {
			daysData.options.unshift({
				name: 'Select a Results Day',
				value: '0',
			});
			selectedValue = '0';
		}

		return (
			<div className="daynav scores-select-menu">
				<SelectMenu
					name="days"
					attributes={daysData}
					selected={selectedValue}
					show={this.state.open}
					onSelected={this.setDay}
					onOpen={this.onOpen}
					onClose={this.onClose}
					mode={this.state.mode}
				/>
			</div>
		);
	}
}

export default DaySelect;

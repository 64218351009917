import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	switch (action.type) {
		// case deps.actionTypes.PLAYERPAGE_LOADED: {
		// 	newState = Object.assign({}, state, { status: 'loaded' });
		// 	logger.log('PlayerPage loaded');
		// 	return newState;
		// 	break;
		// }
		case deps.actionTypes.PLAYERPAGE_LOADING: {
			newState = { ...state, PlayerPageConfig: { pathObj: action.data }, status: 'loading' };
			return newState;
			break;
		}
		/*case deps.actionTypes.CONTROLLER_MOUNT: {
            newState = {
                ...state,
                ...action.data.favorites.players
            }
            return newState;
            break;
        }*/
		/*case deps.actionTypes.CONTROLLER_UPDATE:
            newState = {
                ...state,
                ...action.data
            };
            return newState;*/
		default:
			return state;
	}
};

import op from 'object-path';

export const generateStatusText = data => {
	// let whichStatus = data.next_status !== null ? 'next' : 'last';
	let matchStatus = null;
	//determine status
	if (data.match_status != 'out') {
		//get match status
		if (data.match_status == 'in') {
			const matchTeam = determineTeam(data['match_details'], data['player_info']['playerid']);
			if (matchTeam['won']) {
				matchStatus = 'WON';
			} else {
				//check for match status
				switch (data.match_details.statusCode) {
					case 'B': {
						matchStatus = 'UPCOMING';
						break;
					}
					case null: {
						matchStatus = 'UPCOMING';
						break;
					}
					case 'K': {
						matchStatus = 'SUSPENDED';
						break;
					}
					case 'A': {
						matchStatus = 'LIVE';
						break;
					}
					case 'X': {
						matchStatus = 'LIVE';
						break;
					}
					case 'Y': {
						matchStatus = 'LIVE';
						break;
					}
					case 'D': {
						matchStatus = 'LOST';
						break;
					}
					case 'E': {
						matchStatus = 'LOST';
						break;
					}
					case 'F': {
						matchStatus = 'LOST';
						break;
					}
					case 'G': {
						matchStatus = 'LOST';
						break;
					}
				}
			}
		}
	} else {
		matchStatus = 'OUT';
	}

	return {
		matchStatus: matchStatus,
		playerOpponent: data?.player_info?.team?.opponent ? data['player_info']['team']['opponent'] : null,
		court: data?.match_details?.courtName ? data['match_details']['courtName'] : null,
		round: data?.match_details?.roundName ? data['match_details']['roundName'] : null,
		event: data?.match_details?.eventName ? data['match_details']['eventName'] : null,
		statusCode: data?.match_details?.statusCode,
		eventCode: data?.match_details?.eventCode,
	};
};

export const getCurrentMatch = data => {
	const { displayMatch } = data;
	let matchDetails = {};
	const { playerFirstName, playerID, playerLastName, playerTvName } = data;
	//find the match that matches the displayMatch
	matchDetails['player_info'] = {
		first_name: playerFirstName,
		playerid: playerID,
		last_name: playerLastName,
		tv_name: playerTvName,
	};

	for (let i = 0; i < data.matches.length; i++) {
		let currMatch = data.matches[i];
		if (currMatch['match_id'] === displayMatch) {
			matchDetails['match_details'] = { ...currMatch };
			matchDetails['match_status'] = data['inOut'];
			matchDetails['player_info']['team'] = determineTeam(currMatch, playerID);
		}
	}
	return matchDetails;
};

export const determineTeam = (currMatch, playerID) => {
	const { team1, team2 } = currMatch;
	//get team length
	let team1Length = team1.length;
	let team2Length = team2.length;

	//check if any team length is greater than one or equal to 0
	if (team1Length == 0 || team1Length > 1) {
		let teamInfo = team2[0];
		return {
			team: 'team2',
			won: op.get(teamInfo, 'won', ''),
			opponent: 'TBD',
		};
	}

	if (team2Length == 0 || team2Length > 1) {
		let teamInfo = team1[0];
		return {
			team: 'team1',
			won: op.get(teamInfo, 'won', ''),
			opponent: 'TBD',
		};
	}

	//find player info
	let playerInTeam1 = team1.filter(d => d.idA == playerID || d.idB == playerID).length > 0;
	let playerInTeam2 = team2.filter(d => d.idA == playerID || d.idB == playerID).length > 0;

	if (playerInTeam1) {
		let teamInfo = team1[0];
		let opponentInfo = team2[0];
		let opponentNameArry = [opponentInfo['displayNameA'], opponentInfo['displayNameB']];
		let opponentName = opponentNameArry.filter(d => d !== null).join('/');

		return {
			team: 'team1',
			won: op.get(teamInfo, 'won', ''),
			opponent: opponentName,
		};
	}

	if (playerInTeam2) {
		let teamInfo = team2[0];
		let opponentInfo = team1[0];
		let opponentNameArry = [opponentInfo['displayNameA'], opponentInfo['displayNameB']];
		let opponentName = opponentNameArry.filter(d => d !== null).join('/');

		return {
			team: 'team2',
			won: op.get(teamInfo, 'won', ''),
			opponent: opponentName,
		};
	}

	return null;
};

/** filter out other event's other than MS and LS
 */
export const getSinglesMatch = data => {
	const { displayMatchSingles } = data;
	let matchDetails = {};
	const { playerFirstName, playerID, playerLastName, playerTvName } = data;
	//find the match that matches the displayMatch
	matchDetails['player_info'] = {
		first_name: playerFirstName,
		playerid: playerID,
		last_name: playerLastName,
		tv_name: playerTvName,
	};

	for (let i = 0; i < data.matches.length; i++) {
		let currMatch = data.matches[i];
		if (currMatch['match_id'] === displayMatchSingles) {
			matchDetails['match_details'] = { ...currMatch };
			matchDetails['match_status'] = data['inOutSingles'];
			matchDetails['player_info']['team'] = determineTeam(currMatch, playerID);
		}
	}
	return matchDetails;
};

/** find out Winner or in Final - data is the return of getSingleMatch()*/
export const isWinnerStatus = (data, pid) => {
	if(data?.match_details?.roundCode == "F" && data?.player_info?.team?.won && data?.player_info?.playerid == pid) {
		return true;
	}
	return false;
}
import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const flagImagePathLarge = Config.otherData.flagImagePathLarge;
			const playerProfileImagePath = Config.otherData.playerProfileImagePath;
			dispatch({
				type: deps.actionTypes.PLAYERGENERALINFO_MOUNT,
				data: { flagImagePathLarge, playerProfileImagePath },
			});
		});
	},
};

import React, { Component } from 'react';

/**
 *  SkipContent Component
 *  This is called in <Header> component
 *  Any page that needs to have a skip content Accessibility feature,
 *  do the followings;
 *
 *  1) add skipContent: true in header_propsData
 *  2) add <id="main" tabIndex="0"> to the JSX tag wherever needs to jump to
 */

class SkipContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	render() {
		// logger.log('[SkipContent] render - props:%o', this.props);

		const { isSkipContent, genericSkipContent, genericSkipTxt } = this.props;
		const { skipToNav } = this.props ? this.props : false;

		if (isSkipContent && skipToNav) {
			return (
				<a className="skip-nav" href="#mainnav" aria-hidden="true" tabIndex={-1} role="link">
					Skip to main nav
				</a>
			);
		} else if (isSkipContent) {
			return (
				<a className="skip-main" href="#main">
					Skip to main content
				</a>
			);
		} else if (genericSkipContent) {
			return (
				<a className="skip-main" href={genericSkipContent}>
					{genericSkipTxt}
				</a>
			);
		} else {
			return null;
		}
	}
}

export default SkipContent;

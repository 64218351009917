import React, { Component } from 'react';
import Button from 'appdir/components/common-ui/Button';
import ExitQueueConfirmation from 'appdir/components/webviews/TicketResaleWebview/ExitQueueConfirmation.js'
import { handleOnMyWayEndpoint, handleGetQueueStatus } from 'appdir/components/webviews/TicketResaleWebview/ticketResaleEndpoints';
import WebviewResaleQRcode from 'appdir/components/webviews/TicketResaleWebview/WebviewResaleQRcode.js';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
// import { getInterfaceLink } from 'appdir/components/general/Util/index.js'
import { getErrorText } from 'appdir/components/general/Util/Error';
import RefreshResaleQueue from 'appdir/components/webviews/TicketResaleWebview/RefreshResaleQueue.js';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import { passAppData } from 'appdir/components/general/Util';

class WebviewResaleReady extends Component {
	constructor(props) {
		super(props);
		this.state = {
            showExitConfirmation: false,
			windowHeight: window.innerHeight,
            ...props
        }
		this.updateWindowHeight = this.updateWindowHeight.bind(this);
	}


	componentDidMount() {
		// const interfaceLink = getInterfaceLink('map');
		// this.setState({ viewMapOnClick: interfaceLink?.onclick });
		window.addEventListener('resize', this.updateWindowHeight);

		measureInAppContext({
			pageTitle:"Ticket Resale Queue",
			action: "Ticket Resale Ready",
			context: [{
				section: "Ticket Resale Ready",
			}],
			metricType: "state",
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowHeight);
	}

	updateWindowHeight() {
		this.setState({ windowHeight: window.innerHeight });
	}

	sortResult = (response) => {
		let res;
		// sort by creation time and get most recent booking
		if (response?.length > 0) {
			let sortedRes = response?.sort(function (a, b) {
				return moment.utc(b.creation_time).diff(moment.utc(a.creation_time))
			});
			res = sortedRes[0];
		} else {
			res = response;
		}
		
		return res;
	}


	render() {
        const { headerTxt, topTxt, expireTimeTxt, exitQueueTxt, queueObj, showRefreshBtn, configQueues } = this.props;
        const { showExitConfirmation, windowHeight } = this.state;

        if (showExitConfirmation) {
			return (
				<ExitQueueConfirmation
                    closeModal={() => this.setState({ showExitConfirmation: false})}
					getJWT={() => this.props.getJWT()}
					showModal={(data) => this.props.showModal(data)}
					queueAPIs={this.props.queueAPIs}
					queueObj={this.props.queueObj}
					exitQueueResponse={(status, resp) => this.props.onHandleResponse(status, resp)}
					handleExitExpiredQueue={() => { this.props.handleExitExpiredQueue() }}
					pageTitle="Resale Ready Page"
                />
			)
		}

		let court = configQueues.filter((queue) => queue?.id == queueObj?.queue_id)[0]?.name || '';

		return (
			<div className="webview-ticket-queue" style={{ paddingBottom: '16px' }}>
				<div className="webview-ticket-queue-court" tabIndex={0} aria-label={court}>{court}</div>
                <div className='ticket-resale-header-txt' tabIndex={0} aria-label={headerTxt}>{headerTxt}</div>
                <div className='ticket-resale-checkin-txt' style={{ maxWidth: '80%' }} tabIndex={0} aria-label={topTxt}>{topTxt}</div>
				<div className="webview-ticket-queue-position-number">{moment(this.props.queueObj?.expiration_time).tz('Europe/London').format("HH:mm")}</div>
                <div className='ticket-resale-checkin-txt' tabIndex={0} aria-label={expireTimeTxt}>{expireTimeTxt}</div>
				<div className="webview-ticket-queue-multiple-btn-cntr">
                    <div className='webview-ticket-queue-multiple-btn'>
                        {showRefreshBtn ? 
							<RefreshResaleQueue
								getResaleQueueConfig={(updateModal) => this.props.getResaleQueueConfig(updateModal) }
								getJWT={() => this.props.getJWT()}
								showModal={(data) => this.props.showModal(data)}
								queueAPIs={this.props.queueAPIs}
								updateQueueStatus={(res) => this.props.updateQueueStatus(res)}
							/>
						: <Button
							role="button"
							aria-label="I'M ON MY WAY"
                            onClick={() => 
								this.props.getJWT().then(token => {
									this.props.showModal({
										showModal: true,
										showCloseBtn: false,
										type: 'updating',
										title: 'Submitting',
										message: 'Please do not close the window while submitting.',
										useTimer: false,
									});

									measureInAppContext({
										pageTitle:"Ticket Resale Queue",
										action: "On My Way",
										context: [{
											section: "Ticket Resale Ready",
										}],
										metricType: "action",
									});

									let statusRespResult; // use to check status response below to see if expired

									// check status to see if it's expired
									let statusPath = this.props.queueAPIs?.resaleQueueStatus;
									handleGetQueueStatus(token?.jwt?.id_token, statusPath).then((response) => {
										logger.log('[TicketResaleCheckin] handleGetQueueStatus() response %o', response);
										this.props.updateQueueStatus(response);

										statusRespResult = this.sortResult(response?.data?.response?.result);
									}).catch(error => {
										logger.error('[TicketResaleCheckin] handleGetQueueStatus() - error:%o', error);
										this.props.showModal({
											showModal: true,
											showCloseBtn: true,
											type: 'error',
											title: 'Error Getting Status',
											message: getErrorText(error?.code || error?.data?.response?.error?.code, "Resale Ready Page"),
											useTimer: false,
										});
									}).then(() => {
										// don't call if queue has expired
                                   		if (statusRespResult?.booking_status !== 5) {
											// updateQueue
											let path = this.props.queueAPIs?.resaleUpdateQueue;
											handleOnMyWayEndpoint(token.jwt.id_token, path, queueObj?.booking_id).then((response) => {
												logger.log('[WebviewResaleReady] handleOnMyWayEndpoint() response %o', response);
												this.props.onHandleOnMyWayResponse(response?.status == 200 && response?.data?.response?.result?.booking_status ? 'success' : 'error', response)
												this.props.showModal({
													showModal: false,
													showCloseBtn: true,
													type: 'success',
													title: 'Success',
													message: "You have notified you are on your way",
													useTimer: false,
												});
											}).catch(error => {
												logger.error('[WebviewResaleReady] handleOnMyWayEndpoint() - error:%o', error);
												this.props.showModal({
													showModal: true,
													showCloseBtn: true,
													type: 'error',
													title: 'Error Updating Queue',
													message: getErrorText(error?.code || error?.data?.response?.error?.code),
													useTimer: false,
												});
											});
										} 
										else { // else just close modal 
											this.props.showModal({
												showModal: false,
												showCloseBtn: true,
												type: 'success',
												title: 'Success',
												message: "Success",
												useTimer: false,
											});
										}
									});
								}).catch(error => {
									logger.error('[WebviewResaleStatusView] handleGetQueueStatus() - error:%o', error);
									this.props.showModal({
										showModal: true,
										showCloseBtn: true,
										type: 'error',
										title: 'Error Refreshing',
										message: getErrorText(error?.code || error?.data?.response?.error?.code, "Resale Ready Page"),
										useTimer: false,
									});
								})
							}
                        >I'M ON MY WAY</Button>}
                    </div>
                    <div className='webview-ticket-queue-multiple-btn'>
                        <Button
							role="button"
							aria-label="VIEW MAP"
							onClick={() => {
								let dataObject = {
									type: "map",
									data: 
										{
											mapCategoryID: "400",
											mapPinID: "63"
										}
									};
								passAppData(dataObject);
							}}
							// onClick={() => this.state.viewMapOnClick()}
						>VIEW MAP</Button>
                    </div>
				</div>
				<div className="webview-ticket-queue-qr-code">
					<WebviewResaleQRcode booking_id={queueObj?.booking_id} />
				</div>
				<div className={windowHeight < 712 ? "webview-ticket-queue-exit-queue-cntr-relative" : "webview-ticket-queue-exit-queue-cntr"}>
					<div className="webview-ticket-queue-exit-queue-txt">{exitQueueTxt}</div>
					<Button 
						role="button"
						aria-label='EXIT QUEUE'
						onClick={() => {
							this.setState({ showExitConfirmation: true })
						}}
                    >EXIT QUEUE</Button>
				</div>

			</div>
		);
	}
}

export default WebviewResaleReady;

import findIndex from 'lodash/findIndex';

export const setUrlRound = (round, totalRounds, currentRound) => {
	let path;
	let urlRound;

	if (!round || round < 1 || round > totalRounds) {
		urlRound = currentRound; //change to latest round
	} else {
		urlRound = round;
	}
	return urlRound;
};

export const setUrlSection = (round, section) => {
	let urlSection = section;
	let urlRound = round;
	let sectionIndex;

	if (round > 2 || !urlSection || urlSection == '') {
		sectionIndex = round + '';
	} else if (urlSection == '1') {
		sectionIndex = urlRound + 'top';
	} else if (urlSection == '2') {
		sectionIndex = urlRound + 'bottom';
	}

	logger.log(
		'[DrawsPage] setUrlSection urlSection:%o, urlRound:%o. sectionIndex:%o',
		urlSection,
		urlRound,
		sectionIndex
	);

	return sectionIndex;
};

export const getRoundHalf = section => {
	let half = section;
	let selectedIndex;
	if (half?.includes('top')) {
		selectedIndex = 1;
	} else if (half?.includes('bottom')) {
		selectedIndex = 2;
	} else {
		selectedIndex = half;
	}

	logger.log(
		'[DrawsPage] getRoundHalf - section:%o, half:%o, selectedIndex:%o',
		section,
		half,
		selectedIndex
		// this.props?.eventRound?.selectedIndex
	);

	return selectedIndex;
};

const completeColumnData = (columnData, selectedRound, eventWon, totalRounds) => {
	logger.log(
		'[DrawsPage] completeColumnData columnData:%o, selectedRound:%o, eventWon:%o, totalRounds:%o',
		columnData,
		selectedRound,
		eventWon,
		totalRounds
	);

	if (eventWon && selectedRound == this.totalRounds - 1) {
		let columnWinData = columnData;

		// if (selectedEvent === 'SQ') {
		// 	columnWinData['columnC'] = JSON.parse(JSON.stringify(columnData['columnB']));
		// 	columnWinData['columnC'].matches.pop();
		// } else {
		columnWinData['columnC'] = JSON.parse(JSON.stringify(columnData['columnB']));
		// }
		columnWinData['columnC'].title = 'Winner';
		columnWinData['columnC'].matches[0].roundCode = 'W';

		// logger.log('[DrawsPage] completeColumnData adding winner header 1 columnWinData:%o', columnWinData);

		// this.setState({
		// 	drawsListData: columnWinData,
		// });
		return columnWinData;
	} else if (eventWon && selectedRound == this.totalRounds) {
		let columnWinData = JSON.parse(JSON.stringify(columnData));

		// if (selectedEvent === 'SQ') {
		// 	columnWinData['columnB'].matches.pop();
		// }

		logger.log('[DrawsPage] completeColumnData adding winner header 2 columnWinData:%o', columnWinData);

		columnWinData['columnB'].title = 'Winner';

		columnWinData['columnB'].matches[0].roundCode = 'W';

		logger.log('[DrawsPage] completeColumnData adding winner header 2 columnWinData:%o', columnWinData);

		// this.setState({
		// 	drawsListData: columnWinData,
		// });
		return columnWinData;
	} else {
		// logger.log('[DrawsPage] completeColumnData no winner header 3 columnData:%o', columnData);
		// this.setState({
		// 	drawsListData: columnData,
		// });
		return columnData;
	}
};

export const roundsToDisplay = (data, currentEvent, currentRound, view) => {
	logger.log(
		'[DrawsPage] roundsToDisplay data:%o, currentEvent:%o, currentRound:%o',
		data,
		currentEvent,
		currentRound,
		view
	);
	let startRound = 0;
	let endRound = 0;
	let displayRounds = [];
	let displayLabels = [];
	let matches = data.matches;
	let matchID = '';
	let totalRounds = data.totalRounds;
	let eventWon = false;

	/** First time displaying an event, get most current round for default display */
	if (currentRound == 0) {
		/** Get the round number from the match id */
		for (var i = 0; i < matches.length; i++) {
			let winnerStatus = matches[i].winner;

			/** if no matches have winners, get the current id and matchid from last match */
			if (winnerStatus == null) {
				currentRound = view == 'round' ? Number(matches[i].match_id.slice(-3, -2)) : 1;
				matchID = matches[i].match_id.slice(-3);
				break;
			} else if (matches[i].roundCode == 'F' && winnerStatus !== null) {
				/** for first match with a winner and a roundcode = f, get current id and match id from this match */
				currentRound = view == 'round' ? Number(matches[i].match_id.slice(-3, -2)) : 1;
				matchID = matches[i].match_id.slice(-3);
				eventWon = true;
				break;
			} else if (i == matches.length - 1 && winnerStatus !== null) {
				/** if match has a winner and is last match in list, get current id and match from this match */
				currentRound = view == 'round' ? Number(matches[i].match_id.slice(-3, -2)) : 1;
				matchID = matches[i].match_id.slice(-3);
				break;
			} else if (view == 'full') {
				/** if displaying full draw */
				currentRound = 1;
				matchID = matches[i].match_id.slice(-3);
				break;
			}
		}
	}

	logger.log('[DrawsPage] roundsToDisplay matchID:%o', matchID);

	/**
	 * Set the three rounds to be displayed based on currentRound
	 * Set the start and end indexes for loop based on currentRound and totalRounds
	 */
	if (totalRounds <= 3 || view == 'full') {
		startRound = currentRound;
		endRound = totalRounds;
	} else {
		startRound = currentRound;
		endRound = currentRound + 2;
		if (endRound > totalRounds) {
			endRound = totalRounds;
		}
	}

	var counter = 0;
	for (var i = startRound; i < endRound + 1; i++) {
		displayRounds.push((startRound + counter).toString());
		counter++;
	}

	/** Get round labels for the display round */
	var counter = 0;
	for (var i = 0; i < matches.length; i++) {
		if (matches[i].match_id.slice(-3, -2) == displayRounds[counter]) {
			displayLabels.push(matches[i].roundName);
			counter++;
		}
	}

	/**
	 * Check if event has been won and it is not a qualification event
	 * If so shift the displayRounds array to add a duplicate final round to show a winner column
	 */
	if (currentEvent !== 'QS' && currentEvent !== 'RS' && currentEvent !== 'QD' && currentEvent !== 'RD') {
		if (eventWon && currentRound == totalRounds) {
			// logger.log('[DrawsPage] adding winner column');

			let lastRound = displayRounds[0];
			displayRounds.push(lastRound);
		}
	}

	return {
		lastMatch: matchID,
		displayRounds: displayRounds,
		displayLabels: displayLabels,
		eventWon: eventWon,
		totalRounds: totalRounds,
		currentRound: currentRound,
	};
};

export const getRoundColumnsData = (data, selectedEvent, selectedRound, selectedSection, view) => {
	let selectedRoundOfEvent = selectedRound !== 'full' ? selectedRound : 1;
	let rounds = roundsToDisplay(data, selectedEvent, selectedRoundOfEvent, view);

	let columnList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
	let columnData = {};

	if (view == 'full') {
		for (let i = 0; i < data.totalRounds; i++) {
			columnData[`column${columnList[i]}`] = { title: '', matches: [] };
		}
	} else {
		columnData = {
			columnA: { title: '', matches: [] },
			columnB: { title: '', matches: [] },
			columnC: { title: '', matches: [] },
		};
	}

	let eventMatches = data.matches;
	let drawSize = data.drawSize;
	let matchNotPlayed = rounds.lastMatch;
	let bracketPosition = '';
	// let selectedIndex = op.get(this.props, 'eventRound.selectedIndex', '');
	// let selectedIndex = '';
	let roundTemp = 0;

	// logger.log('[DrawsPage] getRoundColumnsData displayRounds:%o', displayRounds);
	// logger.log('[DrawsPage] getRoundColumnsData matchNotPlayed:%o', matchNotPlayed);

	Object.keys(columnData).forEach(function(key) {
		let displayMatches = [];
		/* create only */

		/** If there are only one or two rounds in display matches, break out of loop **/
		if (
			(rounds.displayRounds.length == 2 && roundTemp == 2) ||
			(rounds.displayRounds.length == 1 && roundTemp == 1)
		) {
			return;
		}

		/** Set column title **/
		columnData[key].title = rounds.displayLabels[roundTemp];

		/** Get all events with matching round id **/
		if (rounds.displayRounds.length > 0) {
			displayMatches = eventMatches.filter(match => {
				if (match.match_id.slice(-3, -2) == rounds?.displayRounds[roundTemp]?.toString()) {
					//logger.log('[DrawsPage] getRoundColumnsData match:%o,', match.match_id);
					return match.match_id.slice(-3, -2) == rounds?.displayRounds[roundTemp]?.toString();
				}
			});
		}

		// logger.log(
		// 	'[DrawsPage] getRoundColumnsData key:%o displayMatches:%o, displayMatches.length:%o',
		// 	key,
		// 	displayMatches,
		// 	displayMatches.length
		// );

		let drawIndex = 0;
		let matchesSize = displayMatches.length;
		let breakPoint = matchesSize / 2;

		if (selectedSection === '') {
			/** Get index of match not played to set bracket position **/
			drawIndex = findIndex(displayMatches, function(match) {
				return match.match_id.slice(-3) === matchNotPlayed;
			});

			//logger.log('[DrawsPage] getRoundColumnsData drawIndex:%o', drawIndex);

			if (drawIndex < 0) {
				drawIndex = 0;
			}

			// if (displayMatches.length >= 32) {
			// 	if (!bracketPosition) {
			// 		if (drawIndex < breakPoint) {
			// 			bracketPosition = 'top';
			// 		} else {
			// 			bracketPosition = 'bottom';
			// 		}
			// 	}
			// }

			// logger.log(
			// 	'[DrawsPage] getRoundColumnsData displayMatches.length:%o, drawIndex:%o breakPoint:%o, bracketPosition:%o',
			// 	displayMatches.length,
			// 	drawIndex,
			// 	breakPoint,
			// 	bracketPosition
			// );
		}

		if (view !== 'full' && selectedSection !== '' && selectedRound <= 2) {
			if (drawSize == '128' || drawSize == '96' || drawSize == '64') {
				if (selectedSection?.indexOf('1') > -1) {
					displayMatches.splice(breakPoint, matchesSize);
				}
				if (selectedSection?.indexOf('2') > -1) {
					displayMatches.splice(0, breakPoint);
				}
			}
		}

		columnData[key].matches = displayMatches;
		roundTemp++;
	});

	// logger.log('[DrawsPage] getRoundColumnsData columnData:%o', columnData);

	if (selectedSection === '') {
		selectedSection = selectedRound.toString() + bracketPosition;

		// logger.log('[DrawsPage] getRoundColumnsData selectedSection:%o', selectedSection);
	}

	let returnData = {
		selectedSection: selectedSection,
		columnData: completeColumnData(columnData, selectedRound, rounds.eventWon, rounds.totalRounds),
	};

	return returnData;

	// return completeColumnData(columnData, selectedRound, rounds.eventWon, rounds.totalRounds);
};

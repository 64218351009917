import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const BirthDay = data => {
	let cutoffDate = moment()
		.subtract(13, 'years')
		.add(1, 'day')
		.date();
	//logger.log('[BirthDay] render - data:%o cutoff:%o ', data, cutoffDate);

	//can't restrict date unless check that not selected last month of cutoff
	cutoffDate = 32;

	return (
		<span className="input-date">
			<label className="gigya-label" data-binding="true" htmlFor={`${data.id}.gigya-dropdown-birthDay`}>
				<span className="gigya-label-text" data-translation-key="DROPDOWN_BIRTH_DAY_LABEL" />
				<label
					className="gigya-required-display"
					data-bound-to="profile.birthDay"
					style={{}}
					data-binding="true"
					htmlFor="gigya-dropdown-birthday">
					*
				</label>
			</label>

			<select
				id={`${data.id}.gigya-dropdown-birthDay`}
				name="profile.birthDay"
				tabIndex={data.tabIndex ? data.tabIndex : '0'}>
				<option value="" data-translation-key="DROPDOWN_103268785982798200_CHOICES_" />
				<option
					value="1"
					disabled={cutoffDate <= 1}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_C4CA4238A0B923820DCC509A6F75849B"
				/>
				<option
					value="2"
					disabled={cutoffDate <= 2}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_C81E728D9D4C2F636F067F89CC14862C"
				/>
				<option
					value="3"
					disabled={cutoffDate <= 3}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_ECCBC87E4B5CE2FE28308FD9F2A7BAF3"
				/>
				<option
					value="4"
					disabled={cutoffDate <= 4}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_A87FF679A2F3E71D9181A67B7542122C"
				/>
				<option
					value="5"
					disabled={cutoffDate <= 5}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_E4DA3B7FBBCE2345D7772B0674A318D5"
				/>
				<option
					value="6"
					disabled={cutoffDate <= 6}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_1679091C5A880FAF6FB5E6087EB1B2DC"
				/>
				<option
					value="7"
					disabled={cutoffDate <= 7}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_8F14E45FCEEA167A5A36DEDD4BEA2543"
				/>
				<option
					value="8"
					disabled={cutoffDate <= 8}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_C9F0F895FB98AB9159F51FD0297E236D"
				/>
				<option
					value="9"
					disabled={cutoffDate <= 9}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_45C48CCE2E2D7FBDEA1AFC51C7C6AD26"
				/>
				<option
					value="10"
					disabled={cutoffDate <= 10}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_D3D9446802A44259755D38E6D163E820"
				/>
				<option
					value="11"
					disabled={cutoffDate <= 11}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_6512BD43D9CAA6E02C990B0A82652DCA"
				/>
				<option
					value="12"
					disabled={cutoffDate <= 12}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_C20AD4D76FE97759AA27A0C99BFF6710"
				/>
				<option
					value="13"
					disabled={cutoffDate <= 13}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_C51CE410C124A10E0DB5E4B97FC2AF39"
				/>
				<option
					value="14"
					disabled={cutoffDate <= 14}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_AAB3238922BCC25A6F606EB525FFDC56"
				/>
				<option
					value="15"
					disabled={cutoffDate <= 15}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_9BF31C7FF062936A96D3C8BD1F8F2FF3"
				/>
				<option
					value="16"
					disabled={cutoffDate <= 16}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_C74D97B01EAE257E44AA9D5BADE97BAF"
				/>
				<option
					value="17"
					disabled={cutoffDate <= 17}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_70EFDF2EC9B086079795C442636B55FB"
				/>
				<option
					value="18"
					disabled={cutoffDate <= 18}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_6F4922F45568161A8CDF4AD2299F6D23"
				/>
				<option
					value="19"
					disabled={cutoffDate <= 19}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_1F0E3DAD99908345F7439F8FFABDFFC4"
				/>
				<option
					value="20"
					disabled={cutoffDate <= 20}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_98F13708210194C475687BE6106A3B84"
				/>
				<option
					value="21"
					disabled={cutoffDate <= 21}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_3C59DC048E8850243BE8079A5C74D079"
				/>
				<option
					value="22"
					disabled={cutoffDate <= 22}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_B6D767D2F8ED5D21A44B0E5886680CB9"
				/>
				<option
					value="23"
					disabled={cutoffDate <= 23}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_37693CFC748049E45D87B8C7D8B9AACD"
				/>
				<option
					value="24"
					disabled={cutoffDate <= 24}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_1FF1DE774005F8DA13F42943881C655F"
				/>
				<option
					value="25"
					disabled={cutoffDate <= 25}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_8E296A067A37563370DED05F5A3BF3EC"
				/>
				<option
					value="26"
					disabled={cutoffDate <= 26}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_4E732CED3463D06DE0CA9A15B6153677"
				/>
				<option
					value="27"
					disabled={cutoffDate <= 27}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_02E74F10E0327AD868D138F2B4FDD6F0"
				/>
				<option
					value="28"
					disabled={cutoffDate <= 28}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_33E75FF09DD601BBE69F351039152189"
				/>
				<option
					value="29"
					disabled={cutoffDate <= 29}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_6EA9AB1BAA0EFB9E19094440C317E21B"
				/>
				<option
					value="30"
					disabled={cutoffDate <= 30}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_34173CB38F07F89DDBEBC2AC9128303F"
				/>
				<option
					value="31"
					disabled={cutoffDate <= 31}
					data-translation-key="DROPDOWN_103268785982798200_CHOICES_C16A5320FA475530D9583C34FD356EF5"
				/>
			</select>
			<span className="gigya-error-msg" data-bound-to="profile.birthDay" />
		</span>
	);
};

export default BirthDay;

import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Mobile Apps',
		});

		//logger.log('[MobileApps] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[MobileApps] action.mount - dispatch - config:%o', Config);
			dispatch({ type: deps.actionTypes.MOBILEAPPS_MOUNT, data: params, config: Config });
		});
	},
};

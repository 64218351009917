import axios from 'axios';

import op from 'object-path';
import { values } from 'appdir/main';
import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isEmpty from 'lodash/isEmpty';
import { BALLOT_STATUS, BALLOT_PERIOD } from 'appdir/components/general/Util';
import { appendEncodedString } from 'appdir/components/general/Util';

export default {
	/**
	 * calls Gigya showScreenSet
	 * params {Object} - data about which screen and screen attributes
	 * cb {Function} - Gigya callback on screen action
	 */
	openScreen: (dispatch, store, params, cb) => {
		logger.log('[Gigya] services.openScreen - params:%o', params);
		logger.log('[Gigya] services.openScreen - cb:%o', cb);

		let gigya_data = {
			...gigyaScreens[params.screen],
		};

		if (params.cid) {
			gigya_data.cid = params.cid;
			gigya_data.context = { cid: params.cid };
		}

		if (params.email) {
			gigya_data.email = params.email;
		}

		if (!gigya_data.screenSet) {
			logger.info('[Gigya] openScreen - no screen set found for ":%o"', params.screen);
		}

		if (params.holder) {
			gigya_data.containerID = params.holder;
		}

		if (params.redirectUrl) {
			gigya_data.redirectURL = params.redirectUrl;
		}

		if (params.context) {
			gigya_data.context = params.context;
		}

		let customLangParams = {
			num_of_the_following_groups:
				'one of each of the following: An uppercase letter, a lowercase letter, a number, a special symbol',
			otp_code_expired: 'Sorry, your code has expired.',
			//network_error: 'network error test'
		};

		gigya_data = {
			...gigya_data,
			onError: cb,
			onBeforeScreenLoad: cb,
			onAfterScreenLoad: cb,
			onBeforeValidation: cb,
			onBeforeSubmit: cb,
			onAfterSubmit: cb,
			onSubmit: cb,
			onHide: cb,
			customLang: customLangParams,
		};

		//logger.log('[Gigya] openScreen - gigya_data:%o', gigya_data);

		if (window.gigya) {
			window.gigya.accounts.showScreenSet(gigya_data);
		} else {
			logger.error('[Gigya] gigya is undefined');
		}
	},

	closeScreen: id => {
		window.gigya.accounts.hideScreenSet({
			containerID: id,
		});
	},

	getAccountInfo: (dispatch, store, params) => {
		//window.gigya.accounts.getAccountInfo(params);
		const { Config } = store.getState();

		let callData = {
			method: 'post',
			url: op.get(Config, 'myWimbledon.api.getAccountInfo', null),
			data: {
				jwtToken: '',
				include: params.include,
				extraProfileFields: params.extraProfileFields,
			},
			headers: { 'Content-type': 'application/json' },
		};
		//logger.log('[Gigya] services.getAccountInfo callData:%o', callData);

		if (callData.url) {
			deps.services.Gigya.getJWT(dispatch, store)
				.then(token => {
					callData.data.jwtToken = token.jwt.id_token;
					let request = axios(callData)
						.then(res => {
							let respData = op.get(res, 'data', false);
							if (res.status == 200 && respData) {
								//logger.log('[Gigya] services.getAccountInfo: %o', respData);
								params.callback(respData);
							} else {
								params.callback({ error: 'response error' });
							}
						})
						.catch(error => {
							logger.error('[Gigya] services.getAccountInfo error: %o', error);
							params.callback({ error: 'service error' });
						});
				})
				.catch(error => {
					params.callback({ error: 'error getting token' });
				});
		} else {
			params.callback({ error: 'no service url defined' });
		}
	},

	setAccountInfo: (dispatch, store, params) => {
		//window.gigya.accounts.setAccountInfo(params);

		const { Config } = store.getState();

		let callData = {
			method: 'post',
			url: op.get(Config, 'myWimbledon.api.setAccountInfo', null),
			data: {
				jwtToken: '',
				...params.data,
			},
			headers: { 'Content-type': 'application/json' },
		};
		//logger.log('[Gigya] services.setAccountInfo callData:%o', callData);

		if (callData.url) {
			deps.services.Gigya.getJWT(dispatch, store)
				.then(token => {
					callData.data.jwtToken = token.jwt.id_token;
					let request = axios(callData)
						.then(res => {
							let respData = op.get(res, 'data', false);
							if (res.status == 200 && respData) {
								//logger.log('[Gigya] services.setAccountInfo - send %o', params.data);
								respData.requestParams = { ...params.data };
								//logger.log('[Gigya] services.setAccountInfo: %o', respData);
								params.callback(respData);
							} else {
								params.callback({ error: 'response error' });
							}
						})
						.catch(error => {
							logger.error('[Gigya] services.setAccountInfo error: %o', error);
							params.callback({ error: 'service error' });
						});
				})
				.catch(error => {
					params.callback({ error: 'error getting token' });
				});
		} else {
			params.callback({ error: 'no service url defined' });
		}
	},

	getJWT: (dispatch, store) => {
		return new Promise((resolve, reject) => {
			const { Gigya } = store.getState();

			let expiration = 600;
			let token_exp = op.get(Gigya, 'jwt.expiration', 0);

			let tokenExp = moment(token_exp);
			let tokenCompare = moment(tokenExp).subtract(20, 'seconds');
			let time = moment();
			// logger.log(
			// 	'[Gigya] services.getJWT  - tokenExp:%o tokenTime:%o time:%o',
			// 	tokenExp.toISOString(),
			// 	tokenCompare.toISOString(),
			// 	time.toISOString()
			// );
			//logger.log('[Gigya] services.getJWT  - expired:%o', time >= tokenCompare);

			if (Gigya.jwt && Gigya.jwt.status == 'error') {
				reject({ error: 'error getting jwt' });
			} else if (Gigya.jwt && Gigya.jwt.status == 'loading') {
				const unsubscribe = store.subscribe(() => {
					const { Gigya } = store.getState();
					//logger.log('[Gigya] services.getJWT subscribe - jwt:%o', Gigya.jwt);
					if (Gigya.jwt && Gigya.jwt.status == 'loaded') {
						unsubscribe();
						resolve({ jwt: Gigya.jwt });
					} else if (!Gigya || !Gigya.jwt) {
						reject({ error: 'error checking jwt status' });
					}
				});
			} else if (time >= tokenCompare) {
				let data = {
					expiration: expiration,
					callback: response => {
						//logger.log('[Gigya] services.getJWT - response:%o', response);
						if (response.errorCode != 0) {
							logger.error('[Gigya] services.getJWT - error response:%o', response);
							let update = {
								jwt: {
									id_token: null,
									expiration: moment(response.time).toISOString(),
									status: 'error',
								},
							};
							dispatch({ type: deps.actionTypes.GIGYA_UPDATE_JWT, data: update });
							reject({ error: 'error requesting token' });
						} else {
							let update = {
								jwt: {
									id_token: response.id_token,
									expiration: moment(response.time)
										.add(expiration, 'seconds')
										.toISOString(),
									status: 'loaded',
								},
							};
							//logger.log('[Gigya] services.getJWT - update:%o', update);
							dispatch({ type: deps.actionTypes.GIGYA_UPDATE_JWT, data: update });
							resolve(update);
						}
					},
				};

				let status = {
					jwt: {
						status: 'loading',
					},
				};
				dispatch({ type: deps.actionTypes.GIGYA_UPDATE_JWT, data: status });
				//logger.log('[Gigya] services.getJWT - pre-window Gigya:%o', Gigya);
				window.gigya.accounts.getJWT(data);
			} else {
				//logger.log('[Gigya] services.getJWT - Gigya:%o', Gigya);
				resolve({ jwt: Gigya.jwt });
			}
		});
	},

	/**
	 * get player contact details
	 */
	getPlayerContactsDetails: (dispatch, store, refId) => {
		const { Config } = store.getState();
		let api = op.get(Config, 'pde.api.contactDetails', null);

		refId = 1000;

		logger.info('[Gigya] services.getPlayerContactsDetails - refId:%o api:%o', refId, api);

		return new Promise((resolve, reject) => {
			if (!api) {
				reject({ error: 'no endpoint defined' });
			}

			deps.services.Gigya.getJWT(dispatch, store)
				.then(token => {
					logger.log('[Gigya] services.getPlayerContactsDetails - token:%o', token.jwt);
					const { Gigya } = store.getState();
					let UID = op.get(Gigya, 'currentUser.UID', false);
					return axios({
						method: 'get',
						url: appendEncodedString(api, UID),
						headers: {
							Authorization: `Bearer ` + token.jwt.id_token,
							'Content-type': 'application/json',
						},
					})
						.then(res => {
							logger.log('[Gigya] services.getPlayerContactsDetails - success fetching data: %o', res);
							//check for valid data
							if (res.status == 200 && res.data.message == 'OK') {
								resolve(res.data.response);
							} else {
								resolve({});
							}
						})
						.catch(error => {
							//logger.error('[Gigya] services.getPlayerContactsDetails');
							reject({ error: error });
						});
				})
				.catch(error => {
					logger.error(
						'[Gigya] services.getPlayerContactsDetails - error getting contact details: %o',
						error
					);
					reject({ error: error });
				});
		});
	},

	/**
	 * get detailed role status from API based on returned status from Gigya
	 * @param {*} tournStart
	 */
	getBallotDetailStatus: (dispatch, store, tournStart) => {
		const { Config } = store.getState();
		let api = op.get(Config, 'ballot.api.status', null);

		logger.info('[Gigya] services.getBallotDetailStatus - api:%o', api);

		return new Promise((resolve, reject) => {
			if (!api) {
				reject({ error: 'no endpoint defined' });
			}

			deps.services.Gigya.getJWT(dispatch, store)
				.then(token => {
					logger.log('[Gigya] services.getBallotDetailStatus - token:%o', token.jwt);
					return axios({
						method: 'post',
						url: api,
						data: {
							jwtToken: token.jwt.id_token,
						},
						headers: { 'Content-type': 'application/json' },
					})
						.then(res => {
							logger.log('[Gigya] services.getBallotDetailStatus - success fetching data: %o', res);
							if (res.data) {
								resolve(convertStatus(tournStart, res.data));
							} else {
								resolve({ status: 'default' });
							}
						})
						.catch(error => {
							logger.error('[Gigya] services.getBallotDetailStatus');
							resolve({ status: 'default' });
						});
				})
				.catch(error => {
					logger.error('[Gigya] services.getBallotDetailStatus - error getting contact details: %o', error);
					reject({ error: error });
				});
		});
	},

	sessionTransfer: (dispatch, store, gtk) => {
		const { Config } = store.getState();

		//logger.info('[Gigya] services.sessionTransfer - token:%o', gtk);

		let callData = {
			method: 'post',
			url: op.get(Config, 'myWimbledon.api.sessionTransfer', null),
			data: {
				jwtToken: gtk,
			},
			headers: { 'Content-type': 'application/json' },
		};
		//logger.log('[Gigya] services.sessionTransfer callData:%o', callData);

		if (callData.url) {
			let request = axios(callData)
				.then(res => {
					let respData = op.get(res, 'data', false);
					if (res.status == 200 && respData) {
						//logger.log('[Gigya] services.sessionTransfer: %o', respData);
						window.gigya.socialize.refreshUI({
							callback: function(resp) {
								logger.log('[Gigya] services.refreshResponse - %o', resp);
								if (resp.errorCode == 0) {
									dispatch(deps.actions.Gigya.getAccountInfo({ src: 'session_transfer' }));
								}
							},
						});
					}
				})
				.catch(error => {
					logger.error('[Gigya] services.sessionTransfer error: %o', error);
					dispatch({ type: deps.actionTypes.GIGYA_LOADED, data: { gigyaLoaded: true } });
				});
		}
	},
};

const refreshResponse = (resp, dispatch) => {};

const convertStatus = (tournStart, data) => {
	//calculate new status
	let { pinStatus } = data;
	let detail = {};
	//logger.log('[Gigya] services.convertStatus - start:%o pin:%o', tournStart, pinStatus);

	/*
	Priority/Levels for check order of statuses
		Lucky - Offer Expired - 1, BALLOT_STATUS.OFFER_EXPIRED
		Lucky - Offer Declined - 1, BALLOT_STATUS.DECLINED
		Withdrawn Entrant - 1, BALLOT_STATUS.WITHDRAWN_ENTR
		Withdrawn Ticketholder - 1, BALLOT_STATUS.WITHDRAWN_TICKET
		Offer Expired - 1, BALLOT_STATUS.WITHDRAWN_ENTR
		Lucky - Offer Voided - 2, BALLOT_STATUS.OFFER_VOID
		Ticketholder - 2, BALLOT_STATUS.TICKETHOLDER
		Ballot Lucky - 3, BALLOT_STATUS.LUCKY
		Ballot Unlucky - 3, BALLOT_STATUS.NOT_LUCKY
		Ballot Entrant - 4, BALLOT_STATUS.ENTRANT
		Void Applicant - 5, BALLOT_STATUS.VOID
	 */

	// for testing status
	// pinStatus.Expired = "Yes";
	// pinStatus.VoidCode = "No";
	// pinStatus.Declined = "No";
	// pinStatus.Paid = "Yes";
	//pinStatus.LuckyDay = '2';

	//Withdrawn Ticketholder - 1, BALLOT_STATUS.WITHDRAWN_TICKET
	if (pinStatus.Paid.toLowerCase() === 'refunded') {
		detail.status = BALLOT_STATUS.WITHDRAWN_TICKET;
	}
	//Withdrawn Entrant - 1, BALLOT_STATUS.WITHDRAWN_ENTR
	else if (pinStatus.Withdrawn.toLowerCase() === 'yes' && !isEmpty(pinStatus.WithdrawnReason)) {
		detail.status = BALLOT_STATUS.WITHDRAWN_ENTR;
	}
	//Lucky - Offer Declined - 1, BALLOT_STATUS.DECLINED
	else if (pinStatus.Lucky.toLowerCase() === 'yes' && pinStatus.Declined.toLowerCase() === 'yes') {
		detail.status = BALLOT_STATUS.DECLINED;
	}
	//Lucky - Offer Expired - 1, BALLOT_STATUS.OFFER_EXPIRED
	else if (
		pinStatus.Paid.toLowerCase() === 'no' &&
		pinStatus.Lucky.toLowerCase() === 'yes' &&
		pinStatus.Expired.toLowerCase() === 'yes'
	) {
		detail.status = BALLOT_STATUS.OFFER_EXPIRED;
		detail.ticket = convertTicketDetail(tournStart, pinStatus);
	}

	//Lucky - Offer Voided - 2, BALLOT_STATUS.OFFER_VOID
	else if (pinStatus.Lucky.toLowerCase() === 'yes' && pinStatus.VoidCode.toLowerCase() === 'yes') {
		detail.status = BALLOT_STATUS.OFFER_VOID;
	}
	//Ticketholder - 2, BALLOT_STATUS.TICKETHOLDER
	else if (pinStatus.Paid.toLowerCase() === 'yes') {
		detail.status = BALLOT_STATUS.TICKETHOLDER;
		detail.ticket = convertTicketDetail(tournStart, pinStatus);
	}

	//Ballot Lucky - 3, BALLOT_STATUS.LUCKY
	else if (pinStatus.Lucky.toLowerCase() === 'yes' && !isEmpty(pinStatus.DueDate)) {
		detail.status = BALLOT_STATUS.LUCKY;
		detail.ticket = convertTicketDetail(tournStart, pinStatus);
	}

	//Ballot Lucky - 3, BALLOT_STATUS.LUCKY
	else if (pinStatus.Unlucky.toLowerCase() === 'yes') {
		detail.status = BALLOT_STATUS.NOT_LUCKY;
	}

	//Ballot Entrant - 4, BALLOT_STATUS.ENTRANT
	else if (pinStatus.Success.toLowerCase() === 'yes') {
		detail.status = BALLOT_STATUS.ENTRANT;
	}

	//Void Applicant - 5, BALLOT_STATUS.VOID
	else if (pinStatus.Success.toLowerCase() === 'no') {
		detail.status = BALLOT_STATUS.VOID;
	} else {
		detail.status = 'not found';
	}

	logger.log('[Gigya] services.convertStatus - detail:%o', detail);

	return detail;
};

const convertTicketDetail = (tournStart, pinStatus) => {
	let detail = {};
	detail.dueDate = op.get(pinStatus, 'DueDate', null);
	detail.subCategory = op.get(pinStatus, 'LuckySubCategory', '');
	detail.day = parseInt(op.get(pinStatus, 'LuckyDay', '0'));
	detail.year = '';
	detail.court = op.get(pinStatus, 'LuckyCourt', '');
	detail.cost = op.get(pinStatus, 'LuckyCost', '');

	//logger.log('[Gigya] services.convertTicketDetail - detail: %o', detail);

	if (detail.dueDate) {
		detail.dueDate =
			detail.dueDate.length > 2 ? moment(detail.dueDate.substr(1), 'YYMMDD').format('D MMMM YYYY') : null;
	}
	if (detail.court) {
		switch (detail.court) {
			case 'CC':
				detail.court = 'Centre Court';
				break;
			case '01':
				detail.court = 'No.1 Court';
				break;
			case '02':
				detail.court = 'No.2 Court';
				break;
			case '03':
				detail.court = 'No.3 Court';
				break;
			default:
				break;
		}
	}
	detail.day = moment(tournStart)
		.add(detail.day < 7 ? detail.day - 1 : detail.day, 'days')
		.format('ddd D MMMM');
	detail.year = moment(tournStart).format('YYYY');

	switch (detail.subCategory) {
		case 'PAIR':
			detail.subCategory = 'A Pair of Tickets';
			break;
		case 'SING':
			detail.subCategory = 'One Single Ticket';
			break;
		case 'TWOS':
			detail.subCategory = 'Two Single Tickets (the seats will not be adjacent)';
			break;
		case 'OSEA':
			detail.subCategory = 'A Pair of Tickets';
			break;
		case 'WHCH':
			detail.subCategory = 'Wheelchair Space & Complimentary Guest Seat';
			break;
		case 'HSPE':
			detail.subCategory = 'A Pair of Tickets';
			break;
		case 'OSES':
			detail.subCategory = 'One Single Ticket';
			break;
		case 'OSPE':
			detail.subCategory = 'A Pair of Tickets';
			break;
		case 'HSPT':
			detail.subCategory = 'Two Single Tickets (the seats will not be adjacent)';
			break;
		default:
			detail.subCategory = '';
			break;
	}

	return detail;
};

const removeQuery2 = (...queryNames) => {
	logger.log('[Gigya] services.removeQuery2 - hist:%o', history);
	const location = Object.assign({}, history.location);
	logger.log('[Gigya] services.removeQuery2 - names:%o loc:%o', queryNames, location);
};

const removeQuery = (...queryNames) => {
	logger.log('[Gigya] services.removeQuery - names:%o', queryNames);
	const location = Object.assign({}, history.location);
	queryNames.forEach(q => delete location.query[q]);
	history.push(location);
};

const checkDate = date => {
	let before = moment(date, 'DD/MM/YYYY').isBefore();
	logger.info('[Gigya] services.checkDate - date:%o before:%o', date, before);
	return before;
};

const onScreenHide = obj => {
	logger.info('[Gigya] services.onScreenHide - obj:%o', obj);
};

//const screenSet = "Default-RegistrationLogin";
const screenSet = 'local-screen-set';

const gigyaScreens = {
	login_screen: {
		screenSet: screenSet,
		startScreen: 'gigya-login-screen',
		//authFlow: 'redirect',
		//redirectURL: '/en_US/mywimbledon/index.html',
	},

	forgot_password: {
		screenSet: 'local-screen-set', //RegistrationLogin
		startScreen: 'gigya-forgot-password-screen',
	},

	reset_password: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-reset-password-screen',
	},
	reset_password_fail: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-reset-password-fail-screen',
	},

	update_profile_screen: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-update-profile-screen',
	},

	register_screen: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-register-screen',
	},

	registration_completion: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-complete-registration-screen',
	},
	link_account: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-link-account-screen',
	},
	registration_success: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-registration-success-screen',
	},
	registration_success_postcode: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-registration-success-postcode-screen',
	},
	registration_address: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-address-registration-screen',
	},

	update_profile: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-update-profile-screen',
	},

	login_success: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-login-success-screen',
	},
	change_password: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-password-change-required-screen',
	},
	forgot_password_success: {
		screenSet: 'local-screen-set', //RegistrationLogin
		startScreen: 'gigya-forgot-password-success-screen',
	},
	reset_password_success: {
		screenSet: 'local-screen-set', //RegistrationLogin
		startScreen: 'gigya-reset-password-success-screen',
	},
	verification_link_pending: {
		screenSet: 'local-screen-set', //VerificationSent
		startScreen: 'gigya-verification-pending-screen',
	},
	verification_link_sent: {
		screenSet: 'local-screen-set', //VerificationSent
		startScreen: 'gigya-verification-sent-screen',
	},
	verification_email_code: {
		screenSet: 'local-screen-set', //VerificationCode
		startScreen: 'gigya-email-code-verification-screen',
	},
	communication: {
		screenSet: 'local-screen-set',
		startScreen: 'gigya-communication-screen',
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

const CloseButton = props => {
	let style = {
		marginTop: '5px',
	};

	return props.dismissible === false ? null : (
		<button type="button" className="close" data-dismiss="alert" aria-label="Close" style={style}>
			<span aria-hidden="true">&times;</span>
		</button>
	);
};

/**
 * -----------------------------------------------------------------------------
 * React Component: Alert
 * -----------------------------------------------------------------------------
 */

class Alert extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		return (
			<div className={this.state.className} role="alert">
				<CloseButton dismissible={this.state.dismissible} />
				{this.state.children}
			</div>
		);
	}
}

Alert.defaultProps = {
	className: 'alert',
	dismissible: false,
};

export default Alert;

import React, { Component, Fragment } from 'react';
import { RoleRegistrationInviteContext } from '../context';
// import op from 'object-path';
// import isEmpty from 'lodash/isEmpty';
// import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { getJson } from 'appdir/components/general/Util/Services';
import MeasurementUtils from 'appdir/lib/analytics';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormCheckBox } from 'appdir/components/common-ui/FormFields';
// import { FormInput, FormCheckBox } from 'appdir/components/common-ui/FormFields';
// import SectionDivider from 'appdir/components/cms/SectionDivider';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

class Player extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			confirmed: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.initialValues = {
			confirm: false,
		};

		logger.log('[RoleRegistrationInvite - Player] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log(
			'[RoleRegistrationInvite - Player] componentDidMount - state:%o context:%o',
			this.state,
			this.context
		);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Role Registration Invite Player',
		});

		getJson(this.context.playerLookup)
			.then(result => {
				logger.log('[RoleRegistrationInvite - Player] componentDidMount - getJson:%o', result);
				this.setState({
					players: result.players,
				});
			})
			.catch(error => {
				logger.error('[RoleRegistrationInvite - Player] componentDidMount - getJson:%o', error);
				this.setState({
					players: null,
				});
			});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[RoleRegistrationInvite - Player] componentDidUpdate - state:%o context:%o',
			this.state,
			this.context
		);
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {
			confirm: yup
				.boolean()
				.required('Please confirm.')
				.oneOf([true], 'Please confirm.'),
		};

		return yup.object(schema);
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[RoleRegistrationInvite - Player] handleSubmit - state:%o', values);
		this.addRole();
	}

	addRole() {
		if (this.context.addRole) {
			logger.log('[RoleRegistrationInvite - Player] addRole');
			this.context.addRole();
		}
	}

	findPlayerName(linkId) {
		let player = [];
		if (this.state.players && linkId) {
			player = this.state.players.filter(p => {
				return p.player_id == linkId;
			});
		}
		return player.length > 0 && player[0].first_name ? `${player[0].first_name} ${player[0].last_name}` : null;
	}

	onConfirm() {
		this.setState({ confirmed: true });
	}

	renderConfirmText(playerName) {
		if (playerName) {
			return (
				<Fragment>
					<div className="two-col margin question">
						<div>Would you like to add access as {playerName}</div>
						<div className="highlight">
							To <MyWimbledonTitle short="true" /> account:
						</div>
						<div className="email">
							<strong>{this.context.userEmail}</strong>
						</div>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<div className="two-col margin question">
						<div>Would you like to add access as a player</div>
						<div className="highlight">
							To <MyWimbledonTitle short="true" /> account:
						</div>
						<div className="email">
							<strong>{this.context.userEmail}</strong>
						</div>
					</div>
				</Fragment>
			);
		}
	}

	renderSubmitForm() {
		let existingName;
		if (this.context.hasRole) {
			let existingLinkId = this.context?.userRoles.filter(d => d.roleId == 1000050)[0]['linkId'];

			existingName = this.findPlayerName(existingLinkId);
		}

		//logger.log('[RoleRegistrationInvite - Player] renderSubmitForm - existing:%o', existingName);

		let validationSchema = this.getSchema();
		return (
			<Fragment>
				<Formik
					initialValues={this.initialValues}
					validationSchema={validationSchema}
					onSubmit={this.handleSubmit}
					enableReinitialize={true}>
					{formProps => {
						let allowSubmit = formProps.values.confirm;
						return (
							<Fragment>
								<div className="two-col margin">
									<FormCheckBox name="confirm" id="confirm">
										I confirm I want to add Player access to&nbsp;
										<MyWimbledonTitle short="true" /> account{' '}
										<strong>{this.context.userEmail}</strong>.
										{existingName ? (
											<span>
												{' '}
												This will replace your current Player access as {existingName}.
											</span>
										) : null}
									</FormCheckBox>
								</div>
								<div className="four-col body">
									<Button
										className={`btn solid ${!allowSubmit ? `disabled` : ``}`}
										onClick={formProps.submitForm}>
										CONFIRM ACCESS
									</Button>
								</div>
							</Fragment>
						);
					}}
				</Formik>
			</Fragment>
		);
	}

	render() {
		logger.log('[RoleRegistrationInvite - Player] render - state:%o status:%o', this.state, this.state.status);

		if (!this.state.players) {
			return null;
		} else {
			let playerName = this.findPlayerName(this.context.linkId);
			let hasDelegateRole = this.context?.userRoles.filter(d => d.roleId == 1000280).length > 0;

			return (
				<Fragment>
					{hasDelegateRole ? (
						<Fragment>
							<div className="two-col margin">
								<h4>REGISTRATION UNSUCCESSFUL</h4>
							</div>
							<div className="two-col body">
								Your account is already registered as a player delegate and can not be registered as a
								player.
							</div>
							<div className="two-col body">
								Please contact your AELTC representative for additional questions.
							</div>
						</Fragment>
					) : this.context.complete ? (
						<Fragment>
							<div className="two-col margin">
								<h4>REGISTRATION SUCCESSFUL</h4>
							</div>
							<div className="two-col">
								<p>
									We are delighted to welcome you to the Wimbledon Player Digital Experience, which
									will be available to you here on wimbledon.com and via the official mobile Apps for
									iOS and Android.
								</p>
								<p>The Player Digital Experience will provide you with access to:</p>
								<ul role="list">
									<li>Live scoring, draws and order of play</li>
									<li>Player services including racket stringing, transport and prize money</li>
									<li>Information, including guides and key updates from the AELTC</li>
									<li>Content, including photos and videos of your Championships journey</li>
									<li>
										Personalised communications during the Fortnight, updating you on the order of
										play and more
									</li>
								</ul>
							</div>
							<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
							<div className="two-col margin">
								Your profile has been updated. Please visit myWimbledon to explore and manage benefits.
							</div>
							<div className="two-col margin center">
								<Button className={`btn solid mywim`} to="/en_GB/mywimbledon/">
									<i className="wim-icon-myw my" aria-label="my wimbledon" />
									WIMBLEDON
								</Button>
							</div>
						</Fragment>
					) : this.context.status == 'completed' ? (
						<Fragment>
							<div className="two-col margin">
								<h4>REGISTRATION UNSUCCESSFUL</h4>
							</div>
							<div className="two-col body">Registration links may only be used once.</div>
							<div className="two-col body">
								Please <WimLink to="/en_GB/contact/index.html">contact us</WimLink> for additional
								questions.
							</div>
						</Fragment>
					) : !this.context.isValid ? (
						<Fragment>
							<div className="two-col margin">
								<h4>REGISTRATION UNSUCCESSFUL</h4>
							</div>
							<div className="two-col body">
								Unfortunately your registration code is invalid or has expired.
								<br />
								<br />
								Please try again or contact your AELTC representative to request a new link.
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className="two-col margin">
								<h4>PLAYER DIGITAL EXPERIENCE REGISTRATION</h4>
							</div>
							{this.context.status == 'active' ? (
								<Fragment>
									{this.renderConfirmText(playerName)}
									<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
									{this.renderSubmitForm()}
								</Fragment>
							) : null}
						</Fragment>
					)}
				</Fragment>
			);
		}
	}
}
Player.contextType = RoleRegistrationInviteContext;
export default Player;

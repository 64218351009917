import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[AtoZWebview] action.mount - data:%o', data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			data.contentPath = Config.cmsData.index.replace('<section>', 'atoz');
			dispatch({ type: deps.actionTypes.ATOZWEBVIEW_MOUNT, data: data });
		});
	},

	unmount: () => (dispatch, getState, store) => {
		logger.log('[AtoZWebview] action.unmount - data:%o', data);
		let data = {
			atozPath: '',
			contentPath: '',
		};
		dispatch({ type: deps.actionTypes.ATOZWEBVIEW_UNMOUNT, data: data });
	},
};

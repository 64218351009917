/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import RoundNav from './RoundNav';
import WimLink from 'appdir/components/general/WimLink';
import JSXParser from 'react-jsx-parser';
import { getRoundList, isWithinHours, sortMatchData } from './Utils';
import { CarouselArrow } from './CarouselArrow';
import Carousel from '@brainhubeu/react-carousel';
import Time from 'appdir/components/common-ui/Time';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchArchiveLanding
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchArchiveLanding'],
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

class MatchArchiveLanding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roundList: [],
			roundArrays: {
				matches_r1: [],
				matches_r2: [],
				matches_r3: [],
				matches_r4: [],
				matches_rQ: [],
				matches_rS: [],
				matches_rF: [],
			},
			selectedRound: '1',
			selectedSlide: null,
		};

		this.pageWrapper = null;
		this.firstLoad = true;
		this.matchesLoaded = false;

		this.setCurrentRound = this.setCurrentRound.bind(this);
		this.setCurrentCarouselRound = this.setCurrentCarouselRound.bind(this);

		// logger.log('[MatchArchiveLanding] constructor - state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[MatchArchiveLanding] componentDidUpdate - state:%o, this.matchesLoaded:%o', this.state, this.matchesLoaded);

		this.pageWrapper = document.querySelector('.page > .wrapper');

		if (this.state.roundList.length == 0 && this.props.roundNames.length > 0) {
			let tmpRoundList = getRoundList(this.props.roundNames);

			if(tmpRoundList.length > 7 ){
				tmpRoundList.length = 7;
			}
			
			this.setState(prevState => {
				return {
					...prevState,
					roundList: tmpRoundList,
				};
			});

			// logger.log('[MatchArchiveLanding] componentDidUpdate - this.state.roundList.length:%o', this.state.roundList.length);
		}

		//when data comes in sort on round
		if (this.props.matches.length > 0 && this.props.roundNames.length > 0 && !this.matchesLoaded) {
			this.matchesLoaded = true;

			let sortedMatches = sortMatchData(this.props.matches);

			this.setState(prevState => {
				return {
					...prevState,
					roundArrays: {
						matches_r1: sortedMatches.tmp_matches_r1,
						matches_r2: sortedMatches.tmp_matches_r2,
						matches_r3: sortedMatches.tmp_matches_r3,
						matches_r4: sortedMatches.tmp_matches_r4,
						matches_rQ: sortedMatches.tmp_matches_rQ,
						matches_rS: sortedMatches.tmp_matches_rS,
						matches_rF: sortedMatches.tmp_matches_rF,
					},
				};
			});
		}

		//set selected round to what comes through in the data on first load
		if (this.props.currentRound && this.firstLoad && this.state.roundList.length > 0) {
			this.firstLoad = false;

			this.setState(prevState => {
				return {
					...prevState,
					selectedRound: this.props.currentRound.roundCode,
					selectedSlide: this.getSelectedSlide(),
				};
			});
		}
	}

	getRoundClass(data) {
		let roundClass = 'none';

		if (Array.isArray(data)) {
			if (data.length < 7) {
				roundClass = 'three';
			}
			if (data.length >= 7) {
				roundClass = 'four';
			}
			if (data.length > 8 && this.props.windowSize !== 'mobile' && this.props.windowSize !== 'tablet') {
				roundClass = 'five';
			}
		}

		return roundClass;
	}

	getSelectedSlide() {
		// logger.log('[MatchArchiveLanding] getSelectedSlide');
		let slideNum;

		if (op.get(this.props, 'currentRound.roundCode', false) && this.state.roundList.length > 0) {
			slideNum = this.state.roundList.indexOf(this.props.currentRound.roundCode);
		}

		if (slideNum === -1) {
			slideNum = 0;
		}

		// logger.log('[MatchArchiveLanding] getSelectedSlide - slideNum:%o', slideNum);

		return slideNum;
	}

	getRoundText(round) {
		let { roundNames } = this.props;
		let roundText = '';

		for (var i = 0; i < roundNames.length; i++) {
			if (roundNames[i].roundCode === round) {
				roundText = roundNames[i].roundName;
			}
		}

		return roundText;
	}

	setCurrentCarouselRound(which) {
		// let measureDirection;
		// if(which > this.state.selectedSlide) {
		// 	measureDirection = "next"; //right
		// } else {
		// 	measureDirection = "previous"; //left
		// }
		// MeasurementUtils.dispatchMeasurementCall('Match Archive', {data: ['Index','Carousel Navigation', measureDirection]});

		let round;

		round = this.state.roundList[which];
		this.setCurrentRound(round);
	}

	setCurrentRound(which) {
		MeasurementUtils.dispatchMeasurementCall('Match Archive', { data: ['Index', 'Select Round', which] });

		this.setState(prevState => {
			return {
				...prevState,
				selectedRound: which,
				selectedSlide: this.state.roundList.indexOf(which),
			};
		});
	}

	renderMatchBox(m, r, i) {
		let p1Name = '';
		let p2Name = '';
		let flippable = false;
		let isNew = false;

		if (m.players.length > 0) {
			p1Name = `${m.players[0].firstName} ${m.players[0].lastName}`;
			p2Name = `${m.players[1].firstName} ${m.players[1].lastName}`;
		}

		if ((m.status === 'A' || m.status === 'P') && m.quotes.length > 0) {
			flippable = true;
		}

		if (m.status === 'A' && m.available) {
			isNew = isWithinHours(m.available, 12);
		}

		if (this.props.windowSize === 'mobile') {
			if (m.status !== 'F') {
				return (
					<WimLink
						to={m.status === 'A' ? `/en_GB/${this.props.directory}/${m.id}.html` : ''}
						style={`match-archive-box small${m.status === 'A' ? ' active' : ''}${
							m.status === 'P' ? ' available' : ''
						}${isNew === true ? ' new-match' : ''}`}
						key={`match-${r}-${i}`}>
						<div className="match-archive-box-content flip-card-inner">
							<div className="front flip-card-front">
								{m.status === 'A' && isNew === true ? <div className="ma-new-tag">New</div> : null}
								<div className="ma-team p1">
									<div className="photo">
										<img src={m.players[0].headshot} alt={p1Name} />
									</div>
									<div className="name">
										<JSXParser
											bindings={{}}
											components={{}}
											jsx={`<span className="first-name">${m.players[0].firstName}<br/></span><span className="last-name">${m.players[0].lastName}</span>`}
											renderInWrapper={false}
											showWarnings={true}
										/>
									</div>
								</div>
								<div className="info">
									<div className="year">{m.year}</div>
									{m.status === 'P' ? (
										<div className="date">
											Available on
											<br />
											<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
										</div>
									) : null}
								</div>
								<div className="ma-team p2">
									<div className="name">
										<JSXParser
											bindings={{}}
											components={{}}
											jsx={`<span className="first-name">${m.players[1].firstName}<br/></span><span className="last-name">${m.players[1].lastName}</span>`}
											renderInWrapper={false}
											showWarnings={true}
										/>
									</div>
									<div className="photo">
										<img src={m.players[1].headshot} alt={p2Name} />
									</div>
								</div>
							</div>
							{m.quotes.length > 0 ? (
								<div className="back flip-card-back">
									<div className="quote">
										<div className="quote-text">{m.quotes[0].text}</div>
										<div className="quote-credit">{m.quotes[0].credit}</div>
									</div>
									{m.status === 'A' ? (
										<div className="action">
											<i className="wim-icon-video"></i>
										</div>
									) : (
										<div className="date">
											Available on
											<br />
											<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
										</div>
									)}
								</div>
							) : null}
						</div>
					</WimLink>
				);
			} else {
				return (
					<div className="match-archive-box small" key={`match-${r}-${i}`}>
						<div className="match-archive-box-content flip-card-inner">
							<div className="front flip-card-front">
								<div className="ma-team p1">
									<div className="photo">
										<img src="/assets/images/misc/headshot.jpg" alt="Player headshot" />
									</div>
								</div>
								<div className="info">
									<div className="date">
										Available on
										<br />
										<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
									</div>
								</div>
								<div className="ma-team p2">
									<div className="photo">
										<img src="/assets/images/misc/headshot.jpg" alt="Player headshot" />
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		} else {
			if (m.status !== 'F') {
				return (
					<WimLink
						to={m.status === 'A' ? `/en_GB/${this.props.directory}/${m.id}.html` : ''}
						style={`match-archive-box large${m.status === 'A' ? ' active' : ''}${
							m.status === 'P' ? ' available' : ''
						}${flippable ? ' flip-card' : ''}${isNew === true ? ' new-match' : ''}`}
						key={`match-${r}-${i}`}>
						<div className="match-archive-box-content flip-card-inner">
							<div className="front flip-card-front">
								{m.status === 'A' && isNew === true ? <div className="ma-new-tag">New</div> : null}
								<div className="year">{m.year}</div>
								<div className="ma-teams">
									<div className="ma-team p1">
										<div className="photo">
											<img src={m.players[0].headshot} alt={p1Name} />
										</div>
										<div className="name">
											<JSXParser
												bindings={{}}
												components={{}}
												jsx={`<span className="first-name">${m.players[0].firstName}<br/></span><span className="last-name">${m.players[0].lastName}</span>`}
												renderInWrapper={false}
												showWarnings={true}
											/>
										</div>
									</div>
									<div className="ma-team p2">
										<div className="photo">
											<img src={m.players[1].headshot} alt={p2Name} />
										</div>
										<div className="name">
											<JSXParser
												bindings={{}}
												components={{}}
												jsx={`<span className="first-name">${m.players[1].firstName}<br/></span><span className="last-name">${m.players[1].lastName}</span>`}
												renderInWrapper={false}
												showWarnings={true}
											/>
										</div>
									</div>
								</div>
								{m.status === 'A' ? (
									<div className="action">
										<i className="wim-icon-video"></i>
									</div>
								) : (
									<div className="date">
										Available on
										<br />
										<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
									</div>
								)}
							</div>
							{m.quotes.length > 0 ? (
								<div className="back flip-card-back">
									<div className="quote">
										<div className="quote-text">{m.quotes[0].text}</div>
										<div className="quote-credit">{m.quotes[0].credit}</div>
									</div>
									{m.status === 'A' ? (
										<div className="action">
											<i className="wim-icon-video"></i>
										</div>
									) : (
										<div className="date">
											Available on
											<br />
											<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
										</div>
									)}
								</div>
							) : null}
						</div>
					</WimLink>
				);
			} else {
				return (
					<div className="match-archive-box large" key={`match-${r}-${i}`}>
						<div className="match-archive-box-content flip-card-inner">
							<div className="front flip-card-front">
								<div className="ma-teams">
									<div className="ma-team p1">
										<div className="photo">
											<img src="/assets/images/misc/headshot.jpg" alt="Player headshot" />
										</div>
									</div>
									<div className="ma-team p2">
										<div className="photo">
											<img src="/assets/images/misc/headshot.jpg" alt="Player headshot" />
										</div>
									</div>
								</div>
								<div className="date">
									Available on
									<br />
									<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}

	renderContent() {
		// logger.log('[MatchArchiveLanding] renderContent - this.state.roundList.length:%o', this.state.roundList.length);
		let { roundList } = this.state;
		let _this = this;

		if (roundList.length > 0) {
			return roundList.map((round, i) => {
				let arr = eval(`_this.state.roundArrays.matches_r${roundList[i]}`);
				let ma_class = this.getRoundClass(arr);
				let round_text = this.getRoundText(round);

				if (Array.isArray(arr)) {
					return (
						<div className="match-archive-round" key={`round-${roundList[i]}-matches`}>
							<div
								className={`ma-round-header ${this.props.windowSize === 'mobile' ? 'small' : 'large'}`}>
								{round_text}
							</div>
							<div className="ma-round-content">
								{arr.map((match, i) => {
									return this.renderMatchBox(match, round, i);
								})}
							</div>
							<div className="ma-round-overlay"></div>
						</div>
					);
				} else {
					return null;
				}
			});
		} else {
			return null;
		}
	}

	calculateWidths() {
		let elementWidths = [];
		/** adding comments for Brian B.  Some of these might not be right */
		let main = 80; // width of current slide (percentage of screen - maybe)
		let side = 0; // width of prev/next slides to be calculated later

		if (this.pageWrapper) {
			let windowWidth = this.pageWrapper.getBoundingClientRect().width;
			let mobileWidth = 0.9; // current slide 90% of the width
			let tabletWidth = 141 * 4 + 8 * 4 + 30; // current slide is the width of 4 cards (141 x 4), card margins (8 x 4) and slide margin (30)
			let desktopWidth = 141 * 5 + 8 * 5 + 30; // current slide is the width of 5 cards (141 x 5), card margins (8 x 5) and slide margin (30)

			// logger.log('calculateWidths - widths - windowSize:%o, window:%o, mobile:%o, tablet:%o, desktop:%o ', this.props.windowSize, windowWidth, mobileWidth, tabletWidth, desktopWidth);

			if (this.props.windowSize === 'mobile') {
				// 	percentage = mobileWidth/windowWidth;
				main = mobileWidth;
				side = (1 - main) / 2;
			}
			if (this.props.windowSize === 'tablet') {
				main = tabletWidth / windowWidth; // get the percentage of the main slide
				let tmp = (tabletWidth + 30) / windowWidth; // get the percentage of the main slide + the 15px margins from the sides
				side = (1 - tmp) / 2; // subtract the main slide percentage from 1 to get the percentage width of the sides
			}
			if (this.props.windowSize === 'desktop') {
				main = desktopWidth / windowWidth;
				let tmp = (desktopWidth + 30) / windowWidth;
				side = (1 - tmp) / 2;
			}

			// main = Math.round(main * 100);
			// side = Math.round(side * 100);
			main = Math.round(windowWidth * main);
			side = Math.round(windowWidth * side);

			// logger.log('calculateWidths:%o ', main);
		}

		elementWidths = [main, side];

		return elementWidths;
	}

	render() {
		logger.log('[MatchArchiveLanding] render - state:%o', this.state);
		logger.log('[MatchArchiveLanding] render - props:%o', this.props);

		let widths = this.calculateWidths();

		// logger.log('[MatchArchiveLanding] render - widths:%o', widths);

		if (op.get(this.props, 'windowSize', false)) {
			return (
				<Fragment>
					<div className="match-archive-landing">
						<div className="column-layout-six">
							{op.get(this.props, 'roundNames', false) ? (
								<RoundNav
									data={this.props.roundNames}
									currentRound={this.props.currentRound}
									selectedRound={this.state.selectedRound}
									onRoundChange={this.setCurrentRound}
								/>
							) : null}

							<div className="four-col match-archive-blurb">
								We have put together a selection of matches to create The Greatest Championships.
								<br />
								Select a match below and come back as they build round by round.
							</div>
						</div>

						<Carousel
							centered
							itemWidth={widths[0]}
							onChange={this.setCurrentCarouselRound}
							value={this.state.selectedSlide}
							arrowLeft={
								<CarouselArrow
									name="wim-icon-left-arrow"
									style={{ width: `${widths[1]}px` }}
									class="ma-nav-arrow prev-button"
								/>
							}
							arrowRight={
								<CarouselArrow
									name="wim-icon-right-arrow"
									style={{ width: `${widths[1]}px` }}
									class="ma-nav-arrow next-button"
								/>
							}
							addArrowClickHandler>
							{this.renderContent()}
						</Carousel>
					</div>
				</Fragment>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps)(MatchArchiveLanding);

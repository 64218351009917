import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsArchiveYear'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.DrawsArchiveYear.mount()),
	setYear: year => dispatch(deps.actions.DrawsArchiveYear.setYear(year)),
});

class Timeline extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
		logger.log('[DrawsArchiveTimeline] constructor - state: %o, props: %o', this.state, props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	onClickSetYear(event, selectedYear) {
		event.preventDefault();
		logger.log('[DrawsArchiveTimeline] render - state: %o, event: %o', this.state, event);
		event.target.className = 'selected';

		this.props.setYear({
			event: event,
			year: selectedYear.year,
		});

		MeasurementUtils.dispatchMeasurementCall('drawsArchiveYearSelector', {
			year: selectedYear.year,
		});
	}

	// make selected year transparent
	onMouseOverEvent(event) {
		let selectedLi = document.querySelector('li.selected');
		selectedLi.classList.add('dim');
	}

	// remove the transparency when mouse out
	onMouseOutEvent(event) {
		let selectedLi = document.querySelector('li.selected');
		selectedLi.classList.remove('dim');
	}

	renderYearBox(eventsDataByYear) {
		/*** eventsDataByYear data output example
            year: 1897, events: Array(4)}
            events: Array(4)
            0: {eventId: "MS", eventName: "Gentlemen's All-Comers' Singles"}
            1: {eventId: "MD", eventName: "Gentlemen's All-Comers' Doubles"}
            2: {eventId: "LS", eventName: "Ladies' All-Comers' Singles"}
            3: {eventId: "MP", eventName: "Gentlemen's Plate"}
        ***/
		const eventYear = eventsDataByYear.year;
		let stateYear = parseInt(this.state.year, 10);
		let klass = eventYear === stateYear ? 'selected' : '';

		return (
			<li
				key={eventYear}
				className={klass}
				data-content={eventYear}
				onClick={event => {
					this.onClickSetYear(event, { year: eventYear });
				}}
				onMouseOver={event => {
					this.onMouseOverEvent(event);
				}}
				onMouseOut={event => {
					this.onMouseOutEvent(event);
				}}>
				<time data-year={eventYear}>
					<a href="" />
				</time>
			</li>
		);
	}

	render() {
		logger.log('[DrawsArchiveTimeline] render - state: %o', this.state);
		if (this.state.eventsAll !== '') {
			const events = this.state.eventsAll;
			return (
				<div className="timelineWrapper">
					<div className="timeline" data-dna="timeline">
						<ol>{events.reverse().map(this.renderYearBox.bind(this))}</ol>
					</div>
				</div>
			);
		} else {
			return (
				<div className="timeline" data-dna="timeline">
					// render empty
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);

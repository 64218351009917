/**
 Sidepanel Initial State
*/

export default {
	showLiveChannel: true,
	//expanded: true,
	//sidepanelSize: false,
	geos: {
		set: false,
		uk: false,
		us: false,
		block: true,
		available: [],
	},
	stubs: {
		sidepanel: 'pre',
	},
	displayPanel: true,
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.HILLCHANNEL_MOUNT:
			break;

		case deps.actionTypes.HILLCHANNEL_PLAY:
			// let main = state.players.main;
			// let sidepanel = state.players.sidepanel;

			newState = {
				...state,
				...action.data,
				isPlaying: true,
			};
			logger.log('[VideoPlayer] deps.actionTypes.HILLCHANNEL_PLAY - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

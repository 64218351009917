import deps from 'dependencies';

export default (state = {}, action) => {
	let newState, stub;

	switch (action.type) {
		case deps.actionTypes.MOBILEAPPS_MOUNT:
		//logger.log('[MobileApps] MOBILEAPPS_MOUNT - config:%o', action.config);
		//stub = (action.config.stubPages.hasOwnProperty('mobileapps')) ? action.config.stubPages.mobileapps.stub : state.stub;
		//newState = Object.assign({}, state, {...action.data}, {stub: stub});
		//return newState;

		case deps.actionTypes.CONFIG_UPDATE:
		//stub = (action.data.stubPages.hasOwnProperty('mobileapps')) ? action.config.stubPages.mobileapps : state.stub;
		//newState = Object.assign({}, state, {stub: stub});
		//return newState;

		default:
			return state;
	}
};

import React, { Component } from 'react';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';

import InputBox from './elements/InputBox';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			pwd: true,
		};
		this.mobileClass = 'local-screen gigya-screen v2 portrait mobile';
		this.wideClass = 'gigya-screen v2 portrait';
		this.otherClass = '';

		this.width = '100%';
		// screenset.width < 430 ? 100%

		this.columns = '1';
		// screenset.width < 430 ? 2 :

		this.showSuccess = ''; //'gigya-login-success-screen'
	}

	render() {
		let enabledProviders = op.get(this, 'context.socialProviders', '');
		let screen = op.get(this, 'context.screen', '');
		//logger.log('[Gigya] [Login] render - enabledProviders:%o screen:%o', enabledProviders, screen);

		let socialDisplay = {};
		let registerDisplay = {};

		if (!screen.social) {
			socialDisplay = { display: 'none' };
		}
		if (!screen.register) {
			registerDisplay = { display: 'none' };
		}

		//logger.log('[Gigya] [Login] render - enabledProviders:%o social:%o register:%o', enabledProviders, socialDisplay, registerDisplay);
		logger.log('[Gigya] [Login] render state:%o', this.state);
		return (
			<div
				id="gigya-login-screen"
				data-width="auto"
				className={this.mobileClass}
				data-on-pending-verification-screen="gigya-verification-pending-screen"
				data-caption="screenset.translations['GIGYA_LOGIN_SCREEN_CAPTION']">
				<form className="gigya-login-form" id="gigya-login-form" data-on-success-screen={this.showSuccess}>
					<div className="gigya-layout-row">
						<div className="gigya-layout-cell responsive with-social-login" style={socialDisplay}>
							<h2
								className="gigya-composite-control gigya-composite-control-header"
								data-translation-key="HEADER_LOGIN_SOCIAL_LABEL"
							/>
							<div className="">
								<div className="gigya-composite-control gigya-composite-control-social-login">
									<div className="gigya-social-login">
										<param name="tabIndex" value="0" />
										<param name="version" value="2" />
										<param name="width" value={this.width} />
										<param name="height" value="50" />
										<param name="enabledProviders" value={enabledProviders} />
										<param name="loginMode" value="standard" />
										<param name="buttonsStyle" value="fullLogoColored" />
										<param name="pagingButtonStyle" value="newArrows" />
										<param name="buttonSize" value="40" />
										<param name="columns" value={this.columns} />
										<param name="showWhatsThis" value="false" />
										<param name="showTermsLink" value="false" />
										<param name="hideGigyaLink" value="true" />
										<param name="_pluginCenterFix" value="true" />
									</div>
								</div>
							</div>
							<div className="gigya-layout-cell responsive with-divider">
								<label
									className="gigya-divider-content gigya-composite-control gigya-composite-control-label"
									data-translation-key="LABEL_OR"
								/>
							</div>
						</div>
					</div>

					<div className="gigya-layout-row">
						<div className="gigya-layout-cell responsive with-site-login">
							<h2
								className="gigya-composite-control gigya-composite-control-header"
								data-translation-key="HEADER_LOGIN_PRIM_LABEL"
							/>
							<div className="gigya-composite-control gigya-composite-control-textbox">
								<InputBox
									data={{
										type: 'email',
										name: 'loginID',
										key: 'TEXTBOX_EMAIL',
										showValid: 'true',
										class: 'gigya-input-text',
										tabIndex: '0',
									}}
								/>
							</div>
							<div className="gigya-composite-control gigya-composite-control-password">
								<InputBox
									data={{
										type: this.state.pwd ? 'password' : 'text',
										name: 'password',
										key: 'TEXTBOX_PASSWORD',
										showValid: 'true',
										class: 'gigya-input-password',
										tabIndex: '0',
									}}
								/>
							</div>
						</div>
					</div>

					<div className="gigya-layout-row">
						<div className="gigya-layout-cell responsive">
							<div className="gigya-layout-row">
								<div className="gigya-layout-cell">
									<div className="gigya-composite-control gigya-composite-control-checkbox gigya-keep-me-logged-in form-checkbox">
										<input
											type="checkbox"
											name="remember"
											id="gigya-checkbox-remember"
											defaultChecked
											className="gigya-input-checkbox"
											tabIndex="0"
										/>
										<label className="gigya-label" htmlFor="gigya-checkbox-remember" tabIndex="0">
											<span
												className="gigya-label-text gigya-checkbox-text"
												data-translation-key="CHECKBOX_REMEMBER_LABEL"
											/>
											<label
												className="gigya-required-display"
												data-bound-to="remember"
												style={{ display: 'none' }}>
												*
											</label>
										</label>
									</div>
								</div>
								<div className="gigya-layout-cell">
									<a
										data-switch-screen="gigya-forgot-password-screen"
										className="gigya-forgotPassword gigya-composite-control gigya-composite-control-link"
										data-translation-key="LINK_FORGOTPASSWORD_LABEL"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '5px' }} />
					<div className="gigya-layout-row">
						<div className="gigya-composite-control gigya-composite-control-captcha-widget v1 gigya-unsupported gigya-v2">
							<div
								className="gigya-captcha-wrapper gigya-error-display"
								data-error-flags="captchaNeeded"
								data-bound-to="gigya-login-form">
								<div className="gigya-captcha">
									<param name="theme" value="light" />
									<param name="type" value="image" />
									<param name="size" value="normal" />
									<param name="badge" value="inline"/>
								</div>
								<span className="gigya-error-msg" data-bound-to="captchaText" />
							</div>
						</div>
					</div>
					<div className="gigya-layout-row">
						<div className="gigya-layout-cell responsive">
							<div className="gigya-composite-control gigya-composite-control-submit">
								<input
									type="submit"
									className="gigya-input-submit"
									tabIndex="0"
									value="screenset.translations['BUTTON_LOGIN_VALUE']"
								/>
							</div>
							<div
								className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
								data-bound-to="gigya-login-form">
								<div
									className="gigya-error-msg gigya-form-error-msg"
									data-bound-to="gigya-login-form"
								/>
							</div>
						</div>
					</div>

					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '15px' }} />
					<div className="gigya-layout-row">
						<div className="gigya-layout-cell responsive with-divider" style={registerDisplay}>
							<div className="gigya-composite-control gigya-button">
								<div className="button-border">
									<a
										data-switch-screen="gigya-register-screen"
										className="gigya-composite-control gigya-composite-control-link buttonBasic"
										data-translation-key="BUTTON_CREATEACCOUNT_LABEL"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="gigya-layout-row">
						<div className="gigya-layout-cell responsive under-site-login" />
					</div>
					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '0px' }} />
					<div className="gigya-layout-row google-terms-p">
						This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
					</div>
				</form>
			</div>
		);
	}
}

Login.contextType = AuthContext;
export default Login;

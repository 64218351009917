import React from 'react';
import Button from 'appdir/components/common-ui/Button';
import RegisterButton from 'appdir/components/cms/RegisterButton';

const Error = ({ data }) => {
	return (
		<div className="content-main content ">
			<div className="column-layout content">Testing error2 - {data.notreal.unknown}</div>
		</div>
	);
};

export default Error;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
/**
 * -----------------------------------------------------------------------------
 * React Component: Tournament
 * -----------------------------------------------------------------------------
 */

export default class Tournament extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		//logger.log('[Tournament] constructor - props:%o', props);
	}

	componentDidMount() {
		this.props.mount();
	}

	componentDidUpdate(prevState) {
		//logger.log('[Tournament] componentDidUpdate - state:%o', this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = null;

		//logger.log('[Tournament] getDerivedStateFromProps - nextProps:%o', nextProps);

		// newState = {
		// 	...prevState,
		// 	...nextProps
		// 	//matches: nextProps.matches,
		// 	//status: nextProps.status,
		// };

		return newState;
	}

	render() {
		return null;
	}
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';
import op from 'object-path';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

const mapStateToProps = (state, props) => {
	return {
		...state['HomePage'],
		...state['HomePageFilter'],
		props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.HomePage.filter(settings)),
});

class HomePageFilter extends Component {
	static getMenuData() {
		let menuData = {
			options: [
				{ name: 'All', value: 'all', query: 'all', selected: true },
				{ name: 'News', value: 'news', query: 'news' },
				{ name: 'Photos', value: 'gallery', query: 'photo' },
				{ name: 'Videos', value: 'video', query: 'video' },
				{ name: 'Updates', value: 'press release', query: 'press' },
				{ name: 'Your Players', value: 'favorite', query: 'favorite' },
				{ name: 'My Content', value: 'mycontent', query: 'mycontent' },
			],
		};
		return menuData;
	}

	constructor(props) {
		super(props);

		this.setFilter = this.setFilter.bind(this);
		this.onOpen = this.onOpen.bind(this);

		this.state = {
			...props,
		};

		this.useFavPlayers = true;
		this.useUpdates = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	setFilter(filter) {
		if (this.state.pathConfig) {
			let filterPath = this.state.pathConfig.replace('<type>', filter);
			this.props.filter({
				filterPath,
				type: filter,
				open: '',
			});
		}
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		logger.log('[HomePageFilter] render - state:%o', this.state);
		let roles = op.get(this.props, 'currentUser.roles', []);
		roles = roles.filter(role => {
			if (role.roleId == 1000050) {
				return true;
			} else {
				return false;
			}
		});

		if (!this.state.filters) {
			logger.warn('[HomePageFilter] no filters specified');
			return null;
		} else if (this.state.fullwindowVideo == 'fullwindow') {
			return null;
		}
		let isPlayer = isEmpty(roles) ? false : true;
		let menuData = {
			options: [
				{ name: 'All', value: 'all', query: 'all', selected: true },
				{ name: 'News', value: 'news', query: 'news' },
				{ name: 'Photos', value: 'gallery', query: 'gallery' },
				{ name: 'Videos', value: 'video', query: 'video' },
				{ name: 'Press Releases', value: 'press release', query: 'press release' },
				{ name: 'Interviews', value: 'interview', query: 'interview' },
				{ name: 'Your Players', value: 'favorite', query: 'favorite' },
				// { name: 'My Content', value: 'mycontent', query: 'mycontent' }
			],
		};

		if (isPlayer) {
			menuData.options.push({ name: 'My Content', value: 'mycontent', query: 'mycontent' });
		}

		return (
			<div className="news-landing-filters">
				<div
					className="filter-buttons"
					id="homepage-filter-buttons"
					tabIndex={0}
					aria-label="entering timeline section">
					{menuData.options.map((value, i) => {
						if (i < 4) {
							return (
								<button
									onClick={() => this.setFilter(value.value)}
									className={this.state.filters.type === value.value ? 'selected' : ''}
									role="button"
									aria-label={
										this.state.filters.type === value.value
											? `filter on "${value.name}", currently selected in news landing group`
											: `filter on "${value.name}", not currently selected in news landing group`
									}
									tabIndex={0}>
									<i className={`wim-icon-${value.value}`} aria-hidden={true} />
									<div className="filter-text">{value.name.toUpperCase()}</div>
								</button>
							);
						}
					})}
					{this.useUpdates ? (
						<button
							onClick={() => this.setFilter('press release')}
							className={this.state.filters.type === 'press release' ? 'selected' : ''}
							aria-label={`${
								this.state.filters.type === 'press release'
									? 'filter on "press release", currently selected in news landing group'
									: 'filter on "press release", not currently selected in news landing group'
							}`}
							role="button"
							tabIndex={0}>
							<i className="wim-icon-updates" aria-hidden={true} />
							<div className="filter-text">UPDATES</div>
						</button>
					) : null}
					{this.useFavPlayers ? (
						<button
							onClick={() => this.setFilter('favorite')}
							className={this.state.filters.type === 'favorite' ? 'selected' : ''}
							aria-label={`${
								this.state.filters.type === 'favorite'
									? 'filter on "favorite". currently selected in news landing group'
									: 'filter on "favorite", not currently selected in news landing group'
							}`}
							role="button"
							tabIndex={0}>
							<i className="wim-icon-favStar" aria-hidden={true} />
							<div className="filter-text">YOUR PLAYERS</div>
						</button>
					) : null}
					{!isEmpty(roles) ? (
						<button
							onClick={() => this.setFilter('mycontent')}
							className={this.state.filters.type === 'mycontent' ? 'selected' : ''}
							aria-label={`${
								this.state.filters.type === 'mycontent'
									? 'filter on "my content", currently selected in news landing group'
									: 'filter on "content button", not currently selected in news landing group'
							}`}
							role="button"
							tabIndex={0}>
							<i className="wim-icon-mycontent" aria-hidden={true} />
							<div className="filter-text">MY CONTENT</div>
						</button>
					) : null}
				</div>
				<div className="filter-dropdown">
					<SelectMenu
						name="filter"
						attributes={menuData}
						selected={this.state.filters.type}
						show={this.state.filters.open == 'filter'}
						onSelected={this.setFilter}
						onOpen={this.onOpen}
					/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageFilter);

import WimbledonWish from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/archive/en_GB/wishfromwimbledon.html'],
		exact: true,
		component: WimbledonWish,
		load: params => deps.actions.WimbledonWish.mount(params),
	},
];

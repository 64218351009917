/**
 VideoPlayer Initial State
*/

export default {
	players: {
		main: {
			id: '',
			state: 'stop',
			volume: 0.75,
		},
		sidepanel: {
			id: '',
			state: 'stop',
			volume: 0.75,
		},
		archive: {
			id: '',
			state: 'stop',
			volume: 0.75,
		},
	},
	modalStyle: '',
	user: {
		autoplay: true,
	},
};

import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Button from 'appdir/components/common-ui/Button';

const mapStateToProps = (state, props) => {
	return {
		...state['ConfirmationModal'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	toggleModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
	unUseTimer: () => dispatch(deps.actions.ConfirmationModal.unUseTimer()),
});
class ConfirmationModal extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
		this.modalTimer = null;
		this.closeModal = this.closeModal.bind(this);
	}

	closeModal() {
		this.props.toggleModal({ showModal: false, showLoader: false });
		if (this.props.reloadFunc) {
			this.props.reloadFunc();
		}
	}

	timeMessageDisplay() {
		setTimeout(() => {
			this.props.toggleModal({ showModal: false, showLoader: false });
			this.props.unUseTimer();
		}, 3000);
	}

	clickOk() {
		//logger.log('[ConfirmationModal] clickOk - state:%o', this.state);

		if (this.state.okCallback) {
			this.closeModal();
			this.state.okCallback();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.useTimer && !this.state.showCloseBtn) {
			if ((this.state.showModal && this.state.type == 'success') || this.state.type == 'fail') {
				this.modalTimer = this.timeMessageDisplay();
			}

			if (!this.state.showModal && this.modalTimer) {
				clearTimeout(this.modalTimer);
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[ConfirmationModal] componentWillReceiveProps - nextProps:%o', nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	render() {
		logger.log('[ConfirmationModal] render - state:%o', this.state);

		const promoOverlay = document.getElementsByClassName('promo-feature-overlay');


		return this.state.showModal ? (
			<div className={promoOverlay?.length ? "modal-overlay-promo-open" : "modal-overlay"}>
				<div className="modal-wrapper">
					<div
						className={`modal-container my-wimbledon ${
							this.state.className ? ` ${this.state.className}` : ''
						}`}>
						{this.state.showCloseBtn ? <CloseBtn closeFn={this.closeModal} /> : null}
						<div className={`modal-title ${this.state.type ? this.state.type : ''}`}>
							{this.state.title}
						</div>
						<div className="modal-content">{/*ReactHtmlParser*/ parse(this.state.message)}</div>
						<div className="modal-code">
							{this.state.code ? <span>Code: {this.state.code}</span> : null}
						</div>
						<div className="modal-button">
							{this.state.type === 'updating' ? <LoadingIndicator /> : null}
							{this.state.type === 'confirm' ? (
								<div className="confirm-btns">
									<Button className={`btn solid`} onClick={() => this.clickOk()}>
										OK
									</Button>
									<Button className={`btn solid`} onClick={() => this.closeModal()}>
										Cancel
									</Button>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);

import React from 'react';

const CloseBtn = props => {
	return (
		<div
			className={`close-btn-container ${props.className}`}
			style={{ cursor: 'pointer' }}
			tabIndex="0"
			role="button"
			aria-label="close button"
			onClick={e => props.closeFn()}
			onKeyPress={e => {
				if (e.key === 'Enter') {
					props.closeFn();
				}
			}}>
			<svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<defs></defs>
				<g id="myWimbledon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
					<g id="Home---Confirmation" transform="translate(-899.000000, -139.000000)" stroke="#00703C">
						<g id="Close-Copy" transform="translate(899.000000, 140.000000)">
							<path d="M0.833333333,0.833333333 L19.279953,19.279953" id="Line-2"></path>
							<path d="M20,0 L0,19 L20,0 Z" id="Line-2-Copy"></path>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default CloseBtn;

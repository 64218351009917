import deps from 'dependencies';

import axios from 'axios';

export default {
	mount:
		({ player }) =>
		(dispatch, getState, store) => {
			logger.log('[PlayerPage] mount - player:%o', player);
			// let controllerState = getState().Controller;
			return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
				const playerMatches = Config.scoringData.playerCompletedMatches;
				const playerInfo = Config.scoringData.playersDetail;
				const playerMatchStats = Config.scoringData.matchStatistics;
				const relatedContent = Config.relatedContent;
				const flagImagePathLarge = Config.otherData.flagImagePathLarge;
				const flagImagePathSmall = Config.otherData.flagImagePathSmall;
				const playerProfileImagePath = Config.otherData.playerProfileImagePath;
				const pathObj = {
					playerMatchup: playerMatches,
					playerDetails: playerInfo,
					...relatedContent,
					matchStats: playerMatchStats,
					flagImagePathLarge,
					flagImagePathSmall,
					playerProfileImagePath,
				};
				dispatch({
					type: deps.actionTypes.PLAYERPAGE_LOADING,
					data: pathObj,
				});
			});
		},
};

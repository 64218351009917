import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[RadioWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.RADIOWEBVIEW_LOAD:
			logger.log('[RadioWebview] reducers - action:%o',action);

			newState = {
				...state,
				status: 'load',
				...action.data
			};
			// logger.log('[RadioWebview] reducers RADIOWEBVIEW_LOAD - newState:%o',newState);
			return newState;
		break;
		case deps.actionTypes.RADIOWEBVIEW_SAVE_PLAYER_HANDLE:
			logger.log(
				'[RadioWebview] reducers RADIOWEBVIEW_SAVE_PLAYER_HANDLE before update state:%o, action.data:%o',
				state,
				action.data
			);

			// this reducer saves a handle to the player.
			newState = {
				...state,
				player: {
					...state.player,
					[action.data.player.props.videoConfig.id]: {
						...action.data,
					},
				},
			};

			logger.log('[RadioWebview] reducers RADIOWEBVIEW_SAVE_PLAYER_HANDLE newState:%o', newState);
				return newState;
				break;
		case deps.actionTypes.RADIOWEBVIEW_UPDATE_CHANNEL_STATUS:
			logger.log(
				'[RadioWebview] reducers RADIOWEBVIEW_UPDATE_CHANNEL_STATUS before update state:%o, action.data:%o',
				state,
				action.data
			);

			newState = {
				...state,
				...action.data,
			};
	
			logger.log('[RadioWebview] reducers RADIOWEBVIEW_UPDATE_CHANNEL_STATUS newState:%o', newState);
			return newState;
				break

		default:
			return state;
	}
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createBrowserHistory } from 'history';
import deps from 'dependencies';

import WimLink from 'appdir/components/general/WimLink';
import op from 'object-path';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
import { getQuerystringValues } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	logout: data => dispatch(deps.actions.Gigya.logout(data)),
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
	//gigyaState:(data) => dispatch(deps.actions.Auth.gigyaState(data))
});

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			showTip: false,
		};

		this.openLogin = false;

		logger.log('[Login] constructor - state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[Login] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidMount() {
		let search = document.location.search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);
		if (parsedQs['login'] == '') {
			this.openLogin = true;
		}
		logger.log('[Login] componentDidMount - parsedQs:%o',parsedQs);

		//remove querystring on session transfer
		removeQuery(parsedQs);

		// logger.log('[Login] componentDidMount - state:%o props:%o', this.state, this.props);
	}

	componentDidUpdate(prevState) {
		//logger.log('[Login] componentDidUpdate - state:%o prev:%o openLogin:%o', this.state, prevState, this.openLogin);

		if (this.state.gigyaLoaded && !op.get(this.props, 'currentUser.profile', false) && this.openLogin) {
			this.props.openScreen({ screen: 'login_screen', cid: 'login_querystring' });
			this.openLogin = false;
		}
	}

	showTip() {
		this.setState({
			showTip: true,
		});
	}

	hideTip() {
		this.setState({
			showTip: false,
		});
	}

	clickLogin(e) {
		//console.log(window.location);
		e.preventDefault();
		this.props.openScreen({ screen: 'login_screen', cid: 'login_header' });
	}

	clickLogout() {
		this.props.logout();
	}

	render() {
		// logger.log('[Login] render - state:%o props:%o', this.state, this.props);

		let showTip = this.state.showTip ? 'show' : 'hidden';

		let {home24} = this.props;

		if (this.props.loggedIn && op.get(this.props, 'currentUser.profile', false)) {
			return (
				<div className="login">
					<div className="name">
						<span className="text">
							<WimLink
								to="/en_GB/mywimbledon/index.html"
								style="white myWimbledonLink"
								title="my wimbledon">
								{home24 && <i className='wim-icon-userAvatar' /> }
								<MyWimbledonTitle short="true" />
							</WimLink>
						</span>
						<span className="vertical_divide" />
						<div className="tip">
							<div className="logoutWrapper">
								<div className="mywim-link">
									<WimLink title="my wimbledon" to="/en_GB/mywimbledon/index.html">
										<span
											tabIndex={0}
											title="link to my wimbledon"
											alt="link to my wimbledon"
											aria-label="link to my wimbledon"
											className="mywim-span"
											role="link">
											myWIMBLEDON
										</span>
									</WimLink>
								</div>
								<hr />
								<div className="logoutButton" onClick={() => this.clickLogout()} role="button">
									LOGOUT
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else if (this.props.myWim) {
			return (
				<div className="login">
					<div className="name">
						<span className="text">
							<a
								onClick={e => this.clickLogin(e)}
								tabIndex="0"
								role="button"
								title="login button"
								aria-label="login button"
								alt="login button">
								LOGIN
							</a>
						</span>
						<span className="vertical_divide" />
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}



export const removeQuery = (qs) => {
	const history = createBrowserHistory();
	const location = history.location;

	if (qs.gtk) {
		delete qs.gtk;

		//logger.log('[Login] removeQuery2 - location:%o', location);
		let newQs = '';
		Object.keys(qs).forEach(q => {
			//logger.log('[Login] removeQuery2 - q:%o qs:%o', q, qs[q]);
			newQs += `${q}=${qs[q]}&`
		});
		history.replace(location.pathname + '?' + newQs);
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

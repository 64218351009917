/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';
import { getMenus } from 'appdir/components/general/Util';
import { Redirect, withRouter } from 'react-router';
import classNames from 'classnames';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
import WimLink from 'appdir/components/general/WimLink';
import SubNav from './SubNav';
import SubNavMyWim from './SubNavMyWim';
import { matchPath } from 'react-router';
import pathToRegexp from 'path-to-regexp';

import MeasurementUtils from 'appdir/lib/analytics';
/**
 * -----------------------------------------------------------------------------
 * React Component: MainNav
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MainNav'],
		...state['Gigya'],
		mode: state['Config']['mode'],
		tournDates: state['Config']['dateConfig'],
		liveIndicators: state['ActiveData']['liveIndicators'],
		menus: state['Config']['menus'],
		foundation: state['Config']['foundation'],
		enableFoundationMenu: state.Config?.enabled?.foundationMenu,
		version: state.Config?.myWimbledon?.version,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MainNav.mount()),
	clearPath: () => dispatch(deps.actions.MainNav.clearPath()),
	unmount: data => dispatch(deps.actions.MainNav.unmount(data)),
});

class MainNav extends Component {
	constructor(props) {
		super(props);

		this.onShareClick = this.onShareClick.bind(this);
		this.onSubNav = this.onSubNav.bind(this);

		this.state = Object.assign({}, this.props);
		this.state.expanded = false;

		this.sidepanelRoute = false;
		this.linkTo;
		this.allowHover = true;
		logger.log('[MainNav] constructor ');

		this.currentPath = '';
	}

	componentDidMount() {
		this.props.mount();
	}

	componentWillUnmount() {
		//logger.log('[MainNav] componentWillUnmount');
		this.state.unmount({
			newPath: null,
			nav: null,
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let nav = '';

		if (nextProps.newPath && nextProps.newPath != this.state.location.pathname + this.state.location.search) {
			nav = nextProps.newPath;
			this.allowHover = true;
			this.props.clearPath();
		}

		this.setState(prevState => ({
			...prevState,
			...nextProps,
			nav: nav,
			newPath: null,
		}));
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[MainNav] componentDidUpdate - state:%o prev:%o', this.state, prevState.menus);
		//this.onRoute(this.props.location, prevState);
	}

	getSection(location) {
		//logger.log('[MainNav] this.props.enableFoundationMenu:%o', this.props.enableFoundationMenu);
		let { enableFoundationMenu } = this.props;
		let foundation = (location.pathname.includes('/foundation') || location.pathname.includes('/wimbledonfoundation')) && enableFoundationMenu;
		let menus = getMenus(this, foundation);

		if (menus.length > 0) {
			this.currentPath = location.pathname;
			let section = 'news';
			let [route] = deps.routes.filter(route => {
				let match = matchPath(this.currentPath, route);
				if (match) {
					section = route.section ? route.section : section;
				}
			});
			let configSection = this.findSection(menus, this.currentPath);
			section = configSection ? configSection : section;

			return section;
		}
		return '';
	}

	findSection(menus, pathname) {
		let section = null;
		//logger.log('[MainNav] findSection - state:%o', this.state.menus);

		if (menus) {
			for (let i = 0; i < menus.length; i++) {
				// if match value matches the pathname
				//   set the section
				if (Array.isArray(menus[i].match)) {
					for (let j = 0; j < menus[i].match.length; j++) {
						let keys = [];
						let re = pathToRegexp(menus[i].match[j], keys, { end: false });
						let result = re.exec(pathname);
						//logger.log('[MainNav] findSection - match:%o found:%o', this.state.menus[i].match, result);
						if (result) {
							section = menus[i].section;
						}
					}
				}
			}
		}

		return section;
	}

	close() {
		this.setState(prevState => ({
			...prevState,
			expanded: false,
			nav: '',
			openItem: '',
		}));
	}

	toggleOpen(e) {
		let section = '';
		if (!this.state.expanded) {
			section = this.getSection(this.props.location);
		}

		this.setState(prevState => ({
			...prevState,
			expanded: !prevState.expanded,
			nav: '',
			openItem: section,
		}));

		if (e && e.target) {
			e.target.blur();
		}

		MeasurementUtils.dispatchMeasurementCall('mainMenu', this.state.expanded ? 'Close' : 'Open');
	}

	lightboxClick() {
		this.allowHover = true;
		this.toggleOpen();
	}

	openSubmenu(event, menu) {
		event.preventDefault();
		let item = menu.section;
		// logger.log('[MainNav] openSubmenu - item:%o state:%o', item, this.state);

		if (item != this.state.openItem) {
			// this.state.submenu({
			// 	openItem: item,
			// });
			this.setState(prevState => ({
				...prevState,
				openItem: item,
			}));
		} else {
			//logger.log('[MainNav] openSubmenu - menu:%o', menu);
			if (menu) {
				//logger.log('[MainNav] openSubmenu - first:%o', this.state.menus[i]);
				this.allowHover = true;

				if (menu.default.external) {
					window.open(menu.default.link);
				} else {
					this.setState({
						expanded: false,
						nav: menu.default.link,
					});
				}
			}
		}
	}

	openMenu(event, menu) {
		event.preventDefault();
		if (menu) {
			//logger.log('[MainNav] openSubmenu - first:%o', this.state.menus[i]);
			this.allowHover = true;

			if (menu.external) {
				window.open(menu.link);
			} else {
				this.setState({
					expanded: false,
					nav: menu.link,
				});
			}
		}
	}

	/**
	 * find live status for certain main menu items from ActiveData
	 */

	getLive(menu) {
		let live = false;

		if (menu.liveStatus && this.state.liveIndicators != undefined) {
			menu.liveStatus.map(status => {
				//logger.log('[MainNav] render - liveStatus:%o indicators:%o', status, this.state.activeData.liveIndicators);
				if (this.state.liveIndicators[status]) {
					live = true;
				}
			});
		}

		if (live) {
			return <div className="live">LIVE</div>;
		} else {
			return null;
		}
	}

	/*
	 * handle clicks to share menu
	 */
	onShareClick() {
		// logger.log('[MainNav] onShareClick');
		this.setState({
			sharePopup: 'hide',
		});
	}

	toggleShare() {
		this.setState({
			sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	// subnav links handled by Link, this closes menu
	onSubNav(url) {
		this.setState({
			expanded: false,
			nav: '',
		});
	}

	/**
	 * format special menu titles
	 */
	formatTitle(title) {
		if (title === 'myWimbledon') {
			return <MyWimbledonTitle />;
		} else {
			return title;
		}
	}

	render() {
		let homepage = false;
		let [route] = deps.routes.filter(route => {
			if (matchPath(location.pathname, route) && route?.page == 'home') {
				//logger.log('[MainNav] render - route:%o ', route);
				homepage = true;
			}
		});

		logger.log(
			'[MainNav] render - expanded:%o open:%o state:%o',
			this.state.expanded,
			this.state.openItem,
			this.state
		);
		let { enableFoundationMenu } = this.props;
		let foundation = (location.pathname.includes('/foundation') || location.pathname.includes('/wimbledonfoundation')) && enableFoundationMenu;
		let home = location.pathname.includes('/');
		// for test
		//let shareUrl = 'http://www.wimbledon.com/en_GB/news/articles/2018-03-15/2018-03-15_serena_begins_the_comeback_trail.html';
		let shareUrl = '';
		let popup = 'hide';

		let menus = getMenus(this, foundation);

		let clicked;
		if (this.state.sharePopup == 'show') {
			clicked = 'clicked';
			popup = 'show';
		}

		let menuRoles =
			this.state.gigyaLoaded &&
			this.state?.currentUser?.roles?.filter(d => d.roleId == 1000050 || d.roleId === 1000040);

		// reduce padding if is a user with Player and Member role, and year round menu
		// check for length 2 to see if both member role AND player role
		let toReduceMenuItemPadding =
			this.state.gigyaLoaded && menuRoles?.length && menuRoles?.length === 2 && !this.state.mode.tournament;

		if (menus.length > 0) {
			//logger.log('[MainNav] render - menus');

			return (
				<nav className="mainnav" display-state={this.state.expanded === true ? 'visible' : 'hidden'}>
					{this.state.nav ? <Redirect push to={this.state.nav} /> : null}
					<button
						onClick={e => this.toggleOpen(e)}
						className="open"
						id="mainnav"
						type="button"
						data-target="#navbar-main"
						aria-controls="navbar-main"
						aria-expanded="false"
						tabIndex="0"
						aria-label="Open Menu">
						<i className="wim-icon-hamburger" aria-hidden />
						{homepage ? <div className='burger-menu-label'>Menu</div> : null}
					</button>

					<div
						className="mainnav--wrapper"
						display-state={this.state.expanded === true ? 'visible' : 'hidden'}>
						<div className="mainnav--header">
							<button
								onClick={e => this.toggleOpen(e)}
								className="close"
								type="button"
								data-target="#navbar-main"
								aria-controls="navbar-main"
								aria-expanded="true"
								tabIndex="0"
								aria-label="Close Menu">
								<i className="wim-icon-close" aria-hidden />
							</button>
							<div className="logotitle">
								<div
									className="logotitle-image"
									display-bg="dark"
									aria-hidden
									tabIndex={-1}
									role="none">
									<WimLink to="/index.html" ariaHiddenElement title="hideAria">
										<div
											title="The Championships, Wimbledon"
											className={`wim-logo ${foundation ? 'foundation' : ''}`}
											aria-hidden
											role="none"
											tabIndex={-1}
										/>
									</WimLink>
								</div>
								{foundation ? (
									<div className="logotitle-txt" display-bg="dark">
										<div className="logotitle-txt__foundation">Wimbledon Foundation</div>
									</div>
								) : (
									<div className="logotitle-txt" display-bg="dark">
										<div className="logotitle-txt__dates">
											{this.state.tournDates?.displayDates}
										</div>
									</div>
								)}
								<div className="logotitle-line" display-bg="dark" />
								{foundation ? (
									<>
										<div className="back-to-wim">
											<WimLink to="/">
												<i className="wim-icon-left-arrow left-icon" /> Back to Wimbledon.com
											</WimLink>
										</div>
										<div className="logotitle-line" display-bg="dark" />
									</>
								) : null}
							</div>
						</div>

						<div className={`mainnav--content-main ${foundation ? 'foundation' : ''}`}>
							<div className="mainnav--content-main-nav" id="navbar-main">
								<div className='menu-bg' />
								<ul className="navbar" role="navigation" aria-label="navigation bar main content">
									{menus.map(menu => {
										let classes =
											menu.section === this.state.openItem
												? classNames(
														'nav-item',
														'nav-item-open',
														toReduceMenuItemPadding ? 'compact-padding' : 'full-padding'
												  )
												: classNames(
														'nav-item',
														toReduceMenuItemPadding ? 'compact-padding' : 'full-padding'
												  );

										if (menu.title === 'myWimbledon') {
											if (op.get(this.state, 'myWim.active', false)) {
												return (
													<li className={classes} key={menu.title}>
														<a
															className="myWimbledonLink"
															href=""
															aria-label={`navigation item. ${menu.title}`}
															onClick={e => this.openSubmenu(e, menu)}>
															{this.formatTitle(menu.title)}
															{this.getLive(menu)}
														</a>
														<div className="bottom-highlight" />
														<SubNavMyWim
															open={menu.section === this.state.openItem}
															section={menu.section}
															items={menu.links}
															featured={menu.featured}
															onClick={this.onSubNav}
														/>
													</li>
												);
											} else {
												return null;
											}
										} else if ('link' in menu) {
											return (
												<li className={classes} key={menu.title}>
													<a
														href=""
														onClick={e => this.openMenu(e, menu)}
														aria-label={`navigation item. ${menu.title}`}>
														{this.formatTitle(menu.title)}
													</a>
													<div className="bottom-highlight" />
													<SubNav
														open={menu.section === this.state.openItem}
														section={menu.section}
														items={[]}
														featured={menu.featured}
														onClick={this.onSubNav}
													/>
												</li>
											);
										} else if ('links' in menu) {
											//logger.log('[MainNav] render - menus section:%o open:%o', menu.section, this.state.openItem);
											return (
												<li className={classes} key={menu.title}>
													<a
														href=""
														onClick={e => this.openSubmenu(e, menu)}
														aria-label={`navigation item. ${menu.title}`}>
														{this.formatTitle(menu.title)}
														{this.getLive(menu)}
													</a>
													<div className="bottom-highlight" />
													<SubNav
														open={menu.section === this.state.openItem}
														section={menu.section}
														items={menu.links}
														featured={menu.featured}
														onClick={this.onSubNav}
													/>
												</li>
											);
										}
									})}
								</ul>

								<ul className="navbar--footer" role="none">
									<li
										className="nav-item-footer"
										aria-hidden
										role="none"
										onClick={() => {
											this.close();
										}}>
										<WimLink to="/en_GB/contact/index.html" title="Contact">
											<span tabIndex={-1} aria-hidden role="none">
												Contact
											</span>
										</WimLink>
									</li>
									<li
										className="nav-item-footer"
										aria-hidden
										role="none"
										onClick={() => {
											this.close();
										}}>
										<WimLink to="/en_GB/jobs/index.html" title="Jobs">
											<span tabIndex={-1} aria-hidden role="none">
												Jobs
											</span>
										</WimLink>
									</li>
									<li
										className="nav-item-footer"
										aria-hidden
										role="none"
										onClick={() => {
											this.close();
										}}>
										<WimLink to="/en_GB/about_wimbledon/privacy_policy.html" title="Privacy">
											<span tabIndex={-1} aria-hidden role="none">
												Privacy
											</span>
										</WimLink>
									</li>
									<li
										className="nav-item-footer"
										aria-hidden
										role="none"
										onClick={() => {
											this.close();
										}}>
										<WimLink
											to="/en_GB/about_wimbledon/acceptable_use_policy.html"
											title="Terms of Use">
											<span tabIndex={-1} aria-hidden role="none">
												Terms of Use
											</span>
										</WimLink>
									</li>
									<li
										className="nav-item-footer"
										aria-hidden
										role="none"
										onClick={() => {
											this.close();
										}}>
										<WimLink to="/en_GB/search/index.html" title="Search">
											<span tabIndex={-1} aria-hidden role="none">
												Search
											</span>
										</WimLink>
									</li>
								</ul>

								<div className="navbar--social">
									<div className={`navbar--social-button`}>
										<ShareMenu
											type="popup"
											view={popup}
											orient="bottom"
											onClick={this.onShareClick}
											share={shareUrl}
											foundation={foundation}
										/>
										<div
											className={`navbar--social-button-wrapper ${clicked}`}
											onClick={() => {
												this.toggleShare();
											}}>
											<i className="Skip-share" />
											<div className="navbar--social-button-label">SOCIAL</div>
										</div>
									</div>
									<div className="navbar--social-bar">
										<ShareMenu
											type="horiz"
											color="black"
											share={shareUrl}
											foundation={foundation}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="background-lightbox" onClick={() => this.lightboxClick()} />
				</nav>
			);
		} else {
			logger.warn('[MainNav] render - no menus defined');
			return null;
		}
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNav));

/**
 DrawsPage Initial State
*/

export default {
	filters: {
		event: '',
	},
	eventRound: {
		clicked: false,
	},
	search: {
		timestamp: null,
		found: false,
	},
	view: 'round',
};

import ContentPageSecure from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

/*
Media Request Form (Media 2)
	- /en_GB/media/request.html
	- managed by MediaRequest component

Secure Media Form (Media Accreditation)
	- /s/form/media_registration.html
	- managed by MediaForm component
 
Secure Media Page
	- /s/media/index.html
	
Secure Media Segment pages
	- /s/media/rightsholder/index.html
	- etc...

Secure Hospitality Form
	- /s/hospitality/suites 
  
Secure Player Pages
	- /s/player/index.html

Secure Player Feature Pages
	- /s/player/info/:page.html
	- managed by PlayerDigitalExperience component

Secure Community Pages
	- /s/community/index.html

Secure Member Pages
	- /s/member/index.html

Secure Tournament Pages
	- /s/tournament/index.html
*/

export default [
	{
		path: '/:prefix(preview)?/s/:landing(hospitality)',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:landing(hospitality)/:id(index).html',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:category(hospitality)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},

	{
		path:
			'/:prefix(preview)?/s/:landing(debentures)/:segment(test|centre_court|no1_court|debentures|new_issue|redeeming)',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/s/:landing(debentures)/:segment(test|centre_court|no1_court|debentures|new_issue|redeeming)/:id(index).html',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/s/:category(debentures)/:segment(test|centre_court|no1_court|debentures|new_issue|redeeming)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},

	{
		path: '/s/:category(form)/:id(media_registration).html',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:landing(media)',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:landing(media)/:id(index).html',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/s/:landing(media)/:segment(rightsholder|radio_rightsholder|non_rightsholder|tournament|tennis_family|official_supplier|rightsholder_news_access)',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/s/:landing(media)/:segment(rightsholder|radio_rightsholder|non_rightsholder|tournament|tennis_family|official_supplier|rightsholder_news_access)/:id(index).html',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:category(media)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/s/:category(media)/:segment(rightsholder|radio_rightsholder|non_rightsholder|tournament|tennis_family|official_supplier|rightsholder_news_access)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},

	{
		path: '/:prefix(preview)?/s/:landing(tournament)',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:landing(tournament)/:id(index).html',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:landing(tournament)/:segment(permanent|temporary|contractors|board)',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/s/:landing(tournament)/:segment(permanent|temporary|contractors|board)/:id(index).html',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path: '/:prefix(preview)?/s/:category(tournament)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/s/:category(tournament)/:segment(permanent|temporary|contractors|board)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params),
	},

	{
		path: ['/:prefix(preview)?/s/:landing(player)/(index.html)?', '/:prefix(preview)?/s/:landing(player)'],
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params, 'p1'),
	},
	{
		path: '/:prefix(preview)?/s/:category(player)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params, 'p2'),
	},

	{
		path:
			'/:prefix(preview)?/s/:landing(community)/:segment(t1|t2|t3|t4|t5|t2_4|t3_4)?/(index.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params, 'c1'),
	},
	{
		path:
			'/:prefix(preview)?/s/:category(community)/:segment(t1|t2|t3|t4|t5|t2_4|t3_4)?/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params, 'c2'),
	},

	{
		path: '/:prefix(preview)?/s/:landing(member)/(index.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params, 'm1'),
	},
	{
		path: '/:prefix(preview)?/s/:category(member)/:id:suffix(.html)?',
		exact: true,
		component: ContentPageSecure,
		load: params => deps.actions.ContentPageSecure.mount(params, 'm2'),
	},
];

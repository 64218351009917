/***
 **  Main Drows and DrawsArchiveYear Draws Lists Component
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
const op = require('object-path');
import cn from 'classnames';

import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import DrawsMatchBox from 'appdir/components/scoring/MatchBox/draws';
import DrawsFullMatchBox from 'appdir/components/pages/DrawsPage/DrawsFullMatchBox';
import DrawsMenu from 'appdir/components/pages/DrawsPage/DrawsMenu';
import DrawAnalysis from 'appdir/components/common-ui/DrawAnalysis';
import WimLink from 'appdir/components/general/WimLink';

import { values } from 'appdir/main';
import { isWinnerIsCompleted } from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { doMeasurement } from 'appdir/components/general/Analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['DrawsLists'],
		scoring: state['Config'].scoring,
		view: state['DrawsPage'].view,
		drawAnalysisData: state['Tournament']?.data?.drawAnalysis,
		drawPathData: state['Tournament']?.data?.drawPath,
		Controller: state['Controller'].favourites,
		enabled: state['Config'].enabled,
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	renderList: timestamp => dispatch(deps.actions.DrawsPage.renderDraws(timestamp)),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
	getDrawAnalysis: (event, playerId) =>
		dispatch(deps.actions.Tournament.getDrawAnalysis({ eventId: event, playerId: playerId })),
	getDrawPath: playerId => dispatch(deps.actions.Tournament.getDrawPath({ playerId: playerId })),
	showFavorite: data => dispatch(deps.actions.Controller.toggleShowFavorites(data)),
	showPathToTheFinal: (playerId, event, top) =>
		dispatch(deps.actions.PathToTheFinal.showPathToTheFinal(playerId, event, top)),
});

class DrawsLists extends Component {
	constructor(props) {
		super(props);

		this.state = {
			view: 'fullView',
			aiDrawBg: false,
			debug: props.debug,
		};
		this.selectedRound = null;
		this.setOnHover = this.setOnHover.bind(this);
		this.profileBasePath = '/en_GB/players/overview/';
		this.finalRoundStatus;

		this.mobileMatch = window.matchMedia('(max-width: 767px)').matches;

		logger.log('[DrawsLists] constructor props:%o state:%o', props, this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		/** detect Favorites star change or view changes to round by round */
		if (
			(this.props.Controller?.show &&
				prevProps.Controller?.show !== this.props.Controller?.show &&
				this.state?.aiDrawBg &&
				this.state?.aiDrawPid) ||
			(this.props.view !== prevProps.view && this.props.view !== 'fullView')
		) {
			/** reset the state  */
			this.setState({
				aiDrawBg: false,
				aiDrawPid: null,
				aiDrawRoundName: null,
				aiDrawRoundNum: null,
				aiDrawMatchId: null,
				showL2P: false,
			});
		}
	}

	setOnHover(pid) {
		this.setState({
			hoveredPid: pid,
		});

		// logger.log('[DrawsLists] setOnHover - pid:%o, state:%o', pid, this.state);
	}

	isNextColumn() {
		let nextColumn = [];
		let data = this.props.attributes.data;

		Object.keys(data).map((column, index) => {
			//let column = 'column' + letter;

			if (data[column].matches.length === 0) {
				nextColumn.push(false);
			} else {
				nextColumn.push(true);
			}
		});

		// logger.log('[DrawsLists] isNextColumn nextColumn:%o', nextColumn);

		return nextColumn;
	}

	getColumn(column) {
		let drawsData = this.props.attributes.data;

		let columnKey = column;

		// if (this.props.view == 'fullView' && column == 'columnE' && Object.keys(drawsData).length == 6) {
		// 	columnKey = 'columnF';
		// }
		// if (this.props.view == 'fullView' && column == 'columnF' && Object.keys(drawsData).length == 6) {
		// 	columnKey = 'columnG';
		// }
		return columnKey;
	}

	renderRoundBrackets() {
		return (
			<>
				<div className={`connector-round`}>
					<div className={`left-side`}></div>
					<div className={`right-side`}></div>
				</div>
			</>
		);
	}

	/** for callback Fn from DrawsFullMatchBox
	 *   clicking on the player's name in the DrawsFullMatchBox
	 *   above mobile size calls this method
	 *   set selete pid and get necessary Tournament data
	 *
	 */
	getDrawAnalysis = (id, matchId, roundName, roundNum) => {
		switch (roundNum) {
			case 'F':
				roundNum = '7';
				break;

			case 'S':
				roundNum = '6';
				break;

			case 'Q':
				roundNum = '5';
				break;

			default:
				roundNum;
				break;
		}

		if (this.isAiDraws && id) {
			let isNewId = (this.state?.aiDrawPid !== null && this.state?.aiDrawPid !== id) || !this.state?.aiDrawPid;

			/** keep the this.state.showL2P value if not isNewId
			 *  so See IBM Likely to Play click is the only one to
			 *  reveal Likely to Play Opponent names in match box
			 */
			let showL2P = isNewId || !this.state?.showL2P ? false : true;

			this.setState(
				{
					aiDrawBg: true,
					aiDrawPid: id,
					aiDrawRoundName: roundName,
					aiDrawRoundNum: parseInt(roundNum), // for metrics
					aiDrawMatchId: matchId,
					collapse: false, // reset the popup menu to open
					showL2P,
				},
				() => {
					/** get necessary data for AI Draw
					 *  if the id is the same, the data already exist for the player
					 *  no need to get data
					 */
					if (isNewId) {
						this.props.getDrawAnalysis(this.event, id);
						this.props.getDrawPath(id);
					}
				}
			);
		}
	};

	/** show Likelyhood to play opponents names */
	setShowl2p = matchId => {
		this.setState({
			...this.state,
			showL2P: true,
		});

		let measureArgs = [];
		let contextData = [{ item: 'Show Opponents' }, { match_id: matchId }, { player_id: this.state?.aiDrawPid }];

		if (values.webview) {
			measureArgs = ['Show Opponents', { match_id: matchId }, { player_id: this.state?.aiDrawPid }];
			contextData = [];
		}
		doMeasurement('Draws', 'AI Draw Analysis', measureArgs, contextData);
	};

	/** get selefted player's AI Draws data
	 *  and pass selected player's id highlight players
	 */
	setAiDrawData = () => {
		let { drawPathData } = this.props;

		let data = {};

		/** if the data matches the selected player, get the draws label for each match */
		if (this.state?.aiDrawPid && drawPathData) {
			data = {
				aiDrawPid: this.state?.aiDrawPid,
				showAiDrawBg: this.state?.aiDrawBg,
				aiDrawMatchId: this.state?.aiDrawMatchId,
				showL2P: this.state?.showL2P,
			};
		}

		return data;
	};

	/** clear aiDraw state when outside of the draw box is clicked */
	clearAiDrawData = () => {
		this.setState({
			aiDrawBg: false,
			aiDrawPid: null,
			aiDrawRoundName: null,
			aiDrawRoundNum: null,
			aiDrawMatchId: null,
			collapse: false, // reset the popup menu to open
			showL2P: false,
		});
	};

	openPathToTheFinal = (id, event = this.props?.attributes?.event) => {
		if (id) {
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (document.documentElement || document.body.parentNode || document.body).scrollTop;

			let measureArgs = [];
			let contextData = [{ item: 'Open' }, { player_id: id }];

			if (values.webview) {
				measureArgs = ['Open'];
				contextData = [{ player_id: id }];
			}

			doMeasurement('Draws', 'Path To The Final', measureArgs, contextData);
			this.props.showPathToTheFinal(id, event, scrollTop);
		}
	};

	collapseMenu = collapse => {
		logger.log('[DrawsMatchBox] collapseMenu - collapseMenu:%o, e:%o');
		this.setState(
			{
				collapse,
			},
			() => {
				document.removeEventListener('click', this.collapseMenu);

				let contextData = [];
				if (!collapse) {
					contextData = [{ player_id: this.state?.aiDrawPid }];
				}

				let measureArgs = [collapse ? 'Close' : 'Open'];

				if (!values.webview) {
					measureArgs = [];
					contextData = [...contextData, { action: collapse ? 'Close' : 'Open' }];
				}

				doMeasurement('Draws', 'AI Draw Analysis Box', measureArgs, contextData);
			}
		);
	};

	handleResetAiDrawHighlight = e => {
		e.stopPropagation();

		/**
		 *  above tablet && aiDrawBg is true
		 *  only when outside of the full draw match box is clicked,
		 *  reset ai draw highlighht
		 */
		if (e.target === e.currentTarget && this.state?.aiDrawBg && !this.mobileMatch) {
			this.clearAiDrawData();

			// let contextData = [{ player_id: this.state?.aiDrawPid}];
			let contextData = [{ action: 'Remove' }];
			let measureArgs = [];

			if (values.webview) {
				measureArgs = ['Remove'];
				contextData = [];
			}

			doMeasurement('Draws', 'AI Draw Analysis', measureArgs, contextData);

			this.clearAiDrawData();
		}
	};

	/**
	 *  only for MS or LS with Config.enabled.drawAnalysis == true
	 * @returns
	 *  - if no player is selected and no Winner for LS or MS event, display default text, New in tournamentYear
	 *  - Player Name, icon to link to player bio
	 *  - if unscheduled Finals, display Final in place of Player Name and icon
	 *  - if Winner is selected, display tournamentYear Winner net to player name
	 */

	getDrawAnalysisBoxTitle = () => {
		if (this.props.view == 'fullView' && !this.state?.aiDrawBg) {
			/** show default text */
			return (
				<div className="name-container">
					<span className={`name intro`}>New in {values.tournamentYear}</span>
				</div>
			);
		} else {
			let isWinner = this.finalRoundStatus?.isWinner;
			let isUnscheduledFinals =
				this.state?.aiDrawRoundNum == 7 &&
				(!this.finalRoundStatus?.isTeam1Scheduled || !this.finalRoundStatus?.isTeam2Scheduled);

			return (
				<div className="name-container">
					<span className={`name`}>
						{isUnscheduledFinals ? (
							<b className="black">Final</b>
						) : (
							this.props?.drawAnalysisData?.data?.displayName
						)}
					</span>
					{/** display an icon to link to player bio when Finals title is't displayed */
					!isUnscheduledFinals && (
						<WimLink style={`icon`} role="link" to={`${this.profileBasePath}${this.state?.aiDrawPid}.html`}>
							<i className="wim-icon-link-out" aria-hidden />
						</WimLink>
					)}

					{isWinner && <span className="winner-title">{values.tournamentYear} Winner</span>}
				</div>
			);
		}
	};

	/** if AI Draws and player is selected, find the match data to check if it's a completed match */
	findMatchStatus = () => {
		let drawsData = this.props?.attributes?.data;
		let selectedMatch = [];

		if (drawsData && this.isAiDraws && this.state?.aiDrawMatchId) {
			let tmp = [];

			Object.keys(drawsData).map((column, index) => {
				if (drawsData[column].matches.length !== 0) {
					tmp = drawsData[column].matches.filter(match => match.match_id == this.state?.aiDrawMatchId);

					if (tmp.length > 0) {
						selectedMatch.push(tmp);
					}
				}
			});
		}

		return selectedMatch;
	};

	/** go through the check to see if the condition is met to display Draw Analysis pop up */
	shouldShowDrawAnalysisPopUp = () => {
		let showDrawAnalysisPopUp = false;

		let drawsData = this.props.attributes.data;

		if (this.isAiDraws) {
			this.finalRoundStatus = isWinnerIsCompleted(drawsData, this.state?.aiDrawPid);
		}

		/**
		 *  Conditions to display the Draw Analysis Pop Up
		 *
		 *  Config.enabled.drawAnalysis: true,
		 *  eventCode == "MS" || eventCode == "LS",
		 *  this.props.view == "fullView"                  <----- Route pathname match
		 *  this.props?.attributes?.round == "full"  <----- for preventing the flashing draw box from popping up on normal MS and LS draw view because there is a time delay to set this.type
		 *  this.finalRoundStatus?.isCompleted && this.state?.aiDrawBg   <--- if Final round is Completed but aiDrawBg highlight is on show draw analysis pop up
		 *  !this.finalRoundStatus?.isCompleted && (!this.state?.aiDrawBg || this.state?.aiDrawBg) <--- if Final round is not completed -- for displaing the default New for 2023 content
		 */
		return (showDrawAnalysisPopUp =
			this.isAiDraws &&
			this.props.view == 'fullView' &&
			this.props?.attributes?.round == 'full' &&
			((this.finalRoundStatus?.isCompleted && this.state?.aiDrawBg) ||
				(!this.finalRoundStatus?.isCompleted && (!this.state?.aiDrawBg || this.state?.aiDrawBg))));
	};

	render() {
		logger.log('[DrawsLists] render this:%o view:%o', this, this.props.view);

		let drawsData = this.props.attributes.data;

		// boolean
		let showSlamTrackerLinks = this.props.attributes.showSlamTrackerLinks;

		// add a class to the match-box <div> to adjust stles if necessary
		let matchBoxStyle = this.props.attributes.matchBoxStyle ? this.props.attributes.matchBoxStyle : '';

		// "main" || "archive" - set the proper link path based on the page
		let profileLinks = this.props.attributes.profileLinks;

		// eventId for Draws Archive. for main draws, leave it as blank
		let archiveYear = this.props.attributes.archiveYear ? this.props.attributes.archiveYear : '';

		let flagImagePathSmall = this.props.attributes.flagImagePathSmall;

		let playerProfileImagePath = this.props.attributes.playerProfileImagePath;

		let count = 0;

		const columnClassNames = (column, index) => {
			let columnKey = column;

			// if (this.props.view == 'fullView' && column == 'columnE' && Object.keys(drawsData).length == 6) {
			// 	columnKey = 'columnF';
			// }
			// if (this.props.view == 'fullView' && column == 'columnF' && Object.keys(drawsData).length == 6) {
			// 	columnKey = 'columnG';
			// }

			return cn({
				roundGroup: true,
				[this.getColumn(column, index)]: true,
				last: this.isNextColumn()[index + 1] ? false : true,
			});
		};

		if (this.selectedRound !== this.props?.attributes?.round) {
			let timeStamp = new Date().getTime();
			this.selectedRound = this.props.attributes.round;
			this.props.renderList(timeStamp);
		}

		/** get Ai Draw data and pass it to match box */
		let aiDrawData = this.setAiDrawData();

		this.event = this.props.attributes?.event;
		this.isAiDraws = this.props?.enabled?.drawAnalysis && (this.event == 'MS' || this.event == 'LS');

		/** check if drawAnalysis pop-up message box should be displayed */
		let showDrawAnalysisPopUp = this.shouldShowDrawAnalysisPopUp();

		return (
			<>
				{// loop through ABC array to assign columnA through columnC // ['A', 'B', 'C'].map((letter, index)
				Object.keys(drawsData).map((column, index) => {
					// logger.log(
					// 	'[DrawsLists] render drawsData.length:%o, column:%o, index:%o',
					// 	Object.keys(drawsData).length,
					// 	column,
					// 	index
					// );
					{
						/* let column = 'column' + letter;
					let drawsClass =
						this.props.attributes.event && this.props.attributes.round
							? ` draw-${this.props.attributes.event}-round${this.props.attributes.round}`
							: ''; */
					}
					// make sure data exists
					if (drawsData[column].matches.length !== 0) {
						return (
							<div
								key={column}
								className={columnClassNames(column, index)}
								onClick={e =>
									this.props.view == 'fullView' ? this.handleResetAiDrawHighlight(e) : null
								}>
								{this.props.view !== 'fullView' && (
									<span className="roundName" tabIndex={0} aria-label={drawsData[column].title}>
										{drawsData[column].title}
									</span>
								)}
								{// loop through drawsData.columnA-C.matches to get each MatchBox
								drawsData[column].matches.map((item, j) => {
									// for draws archve, set a special class for Winner and Challenged Match Boxes
									let winnerClass = item.roundCode && item.roundCode === 'W' ? 'winner' : '';
									let qualifierClass = item.roundCode && item.roundCode === 'WQ' ? 'qualifier' : '';
									let challengeClass = item.roundCode && item.roundCode === 'C' ? 'challenge' : '';
									let matchType = item?.eventName?.includes('Doubles') ? 'doubles' : 'singles';

									// showLinks    - receive true or false
									// profileLinks - receive "main" or "archive" and pass it to draws.js
									// onHovered    - callback function called in onHover(pid) in  /_scoring/MatchBox/draws.js
									// highlightPid - pass the state value to draws.js to check and render based on the state
									return (
										<div
											key={`${column} + ${item.roundCode} + ${
												item.match_id
											} + ${archiveYear} + ${count++}`}
											className={`drawGroup ${matchType} ${winnerClass} ${qualifierClass} ${challengeClass}`}>
											{this.props.view == 'fullView' ? (
												<DrawsFullMatchBox
													attributes={{
														data: item,
														style: matchBoxStyle,
														// showLinks: showSlamTrackerLinks,
														profileLinks: profileLinks,
														flagImagePathSmall: flagImagePathSmall,
														playerProfileImagePath: playerProfileImagePath,
														isAiDraws: this.isAiDraws,
														aiDrawData,
													}}
													onHovered={this.setOnHover}
													highlightPid={this.state.hoveredPid}
													key={item.match_id}
													matchNumber={j + 1}
													onAiDraws={id =>
														this.getDrawAnalysis(
															id,
															item.match_id,
															item.roundName,
															item.roundCode
														)
													}
													onShowl2pOpponents={matchId => this.setShowl2p(matchId)}
													webViewFavs={this.props.webViewFavs}
													debug={this.state.debug}
													tournamentYear={this.props.scoring.year}
												/>
											) : (
												<DrawsMatchBox
													attributes={{
														data: item,
														style: matchBoxStyle,
														showLinks: showSlamTrackerLinks,
														profileLinks: profileLinks,
														flagImagePathSmall: flagImagePathSmall,
														playerProfileImagePath: playerProfileImagePath,
													}}
													matchNumber={j + 1}
													onHovered={this.setOnHover}
													highlightPid={this.state.hoveredPid}
													key={item.match_id}
													webViewFavs={this.props.webViewFavs}
													showPlayerMenu={this.props.showPlayerMenu}
												/>
											)}
											{this.props.view !== 'fullView' && this.renderRoundBrackets()}
										</div>
									);
								})}
							</div>
						);
					}
				})}

				{/** display only above Tablet breakpoint
				 *  fullView has default New in 2023 message to display until there is a winner
				 *  then New in 2023 default mesasge will be replaed by the winner box
				 */
				showDrawAnalysisPopUp && (
					<ErrorBoundary message="">
						<DrawsMenu
							show={true}
							collapseMenu={this.collapseMenu}
							className={`draws-analysis-menu ${this.state.collapse ? 'collapse' : ''}`}
							collapsed={false}>
							<div className={`content`}>
								{this.getDrawAnalysisBoxTitle()}
								<div className="separator"></div>
								<DrawAnalysis
									attributes={{
										playerDrawAnalysisData:
											this.props?.drawAnalysisData?.data?.playerId == this.state?.aiDrawPid
												? this.props?.drawAnalysisData?.data
												: null,
										drawPathData:
											this.props?.drawPathData?.player?.idA == this.state?.aiDrawPid
												? this.props.drawPathData
												: null,
										hideButton: this.state.aiDrawBg ? false : true,
										style: '',
										selectedMatchId: this.state?.aiDrawMatchId,
										aiDrawData: {
											aiDrawPid: this.state.aiDrawPid,
											aiDrawBg: this.state.aiDrawBg,
											aiDrawRoundName: this.state?.aiDrawRoundName,
											aiDrawRoundNum: this.state?.aiDrawRoundNum,
											aiDrawFinalRoundStatus: this.finalRoundStatus,
											aiDrawSelectedMatch: this.findMatchStatus(),
										},
										view: 'aidraw',
										type: this.props.view,
										callbackFn: playerId => this.openPathToTheFinal(this.state?.aiDrawPid),
									}}
								/>
							</div>
						</DrawsMenu>
					</ErrorBoundary>
				)}
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsLists);

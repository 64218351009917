import React, { Component } from 'react';
import Carousel from 'appdir/components/common-ui/Carousel';

class BackgroundCarousel extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.attributes, currIndex: 0 };

		this.autoTimer;
		this.setAutoPlay();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	setAutoPlay() {
		if (this.state.autoPlay) {
			this.autoTimer = setTimeout(() => {
				let currIndex = this.state.currIndex;
				this.setIndex(++currIndex);
				this.setAutoPlay();
			}, this.state.interval);
		}
	}

	updateIndex(index) {
		this.setIndex(index);
		window.clearTimeout(this.autoTimer);
		this.setAutoPlay();
	}

	componentWillUnmount() {
		window.clearTimeout(this.autoTimer);
	}

	componentDidUpdate(prevProps, prevState) {}

	_onSlide(index) {
		this.setState(prevState => {
			return { ...prevState, currIndex: index };
		});
	}

	setIndex(index) {
		let min = 0,
			max = this.state.galleryImages.length - 1;
		let indexSet = index < 0 ? max : index > max ? 0 : index;

		this.setState(prevState => {
			return { ...prevState, currIndex: indexSet };
		});
	}

	render() {
		let currIndex = this.state.currIndex;
		return (
			<div className="carousel-wrapper">
				{this.state.galleryImages.map((d, i) => {
					return (
						<div className={`image-gallery-slide ${i == this.state.currIndex ? 'center' : ''}`} key={i}>
							<img src={d} />
						</div>
					);
				})}
				<div className="nav-wrapper">
					<div
						className="custom-slide prev-slide"
						onClick={() => {
							this.updateIndex(--currIndex);
						}}
					/>
					{this.state.galleryImages.map((d, i) => {
						return (
							<div
								onClick={() => {
									this.updateIndex(i);
								}}
								className={`nav-dot ${this.state.currIndex == i ? 'selected' : ''}`}
								key={i}>
								●
							</div>
						);
					})}
					<div
						className="custom-slide next-slide"
						onClick={() => {
							this.updateIndex(++currIndex);
						}}
					/>
				</div>
			</div>
		);
	}
}

export default BackgroundCarousel;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import Stripes from 'appdir/components/common-ui/Stripes';
import Button from 'appdir/components/common-ui/Button';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MyWPromo
 * -----------------------------------------------------------------------------
 */

const MyWPromo = () => {
    const loggedIn = useSelector(state => state['Gigya']['loggedIn']);

    if(!loggedIn){
        return (
            <>
                <div className='myWPromo'>
                    <div className='mywlogo'>
                        <MyWimbledonTitle />
                    </div>
                    <Stripes color="white" short={true} />
                    <div className='text'>
                        {`Sign up to myWimbledon for exclusive access to The Championships, including more Wimbledon Stories, updates about your favourite players and the chance to be the first to know about the opening of the Wimbledon Public Ballot.`}
                    </div>
                    <Button to="/en_GB/aboutmywimbledon/index.html" className="outlineWhite">
                        Join Now
                    </Button>
                </div>
            </>
        )
    }else{ return null; }
}

export default MyWPromo;
/**
 Article Initial State
*/

export default {
	// data: '',
	sharePopup: 'hide',
	// filters: {
	//     selected: 'champions',
	//     title: '2017 Champions',
	//     open: ''
	// }
};

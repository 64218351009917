import React, { Component, Fragment } from 'react';
import { PlayerDigitalExperienceContext } from '../context';
import { getContent } from 'appdir/components/general/Util/Services';
import { loggedIn, checkRole } from 'appdir/components/general/Util/Role';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import WimLink from 'appdir/components/general/WimLink';
import op from 'object-path';
import HeaderText from './HeaderText';
import Section from './Section';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getParams } from './Utils.js';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
	unmount: () => dispatch(deps.actions.PlayerDigitalExperience.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class PlayerStringing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			text:
				'Your racket stringing account details will be listed here. Payment for racket stringing and accessories can be settled directly at the racket stringing office or it can be deducted from Prize Money payments. If you have questions regarding the charges below please vist the racket stringing office at the rear of Aorangi Pavillion or at Roehampton (during Qualifying only)',
			gotData: false,
			data: null,
		};
		this.fetchData = true;
		this.handleSubmit = this.handleSubmit.bind(this);

		logger.log('[PDE - PlayerStringing] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log('[PDE - PlayerStringing] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'currentUser', {});

		let roleValidated = checkRole(op.get(currentUser, 'roles', []), op.get(this.state, 'roleIds', []));

		logger.log(
			'[PDE - PlayerStringing] componentDidUpdate - user:%o state:%o roleValidated:%o',
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			roleValidated
		);

		// logger.log('[PDE - PlayerCatering] componentDidUpdate - state:%o context:%o', this.state, this.context);
		if (loggedIn(currentUser) && roleValidated) {
			if (this.state.dataUrl && !this.state.gotData && this.fetchData) {
				this.fetchData = false;
				this.props
					.getJWT()
					.then(token => {
						//logger.log('[PDE - PlayerStringing] componentDidUpdate - token:%o', token.jwt);
						let nullError = false;
						let params = getParams('stringing', { ...this.state });
						getContent(this.state.dataUrl, 'get', token.jwt.id_token, nullError, params)
							.then(data => {
								logger.log('[PDE - PlayerStringing] componentDidUpdate - data:%o', data);
								this.setState({ data: data, gotData: true });
							})
							.catch(data => {
								this.setState({ data: 'error', gotData: true });
							});
					})
					.catch(error => {
						logger.error('[PDE - PlayerStringing] componentDidUpdate - error getting token');
					});
			}
		}
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[PDE - PlayerStringing] handleSubmit - state:%o', values);
	}

	noStringingData() {
		let stringItems = op.get(this.state.data, 'response', []);
		return stringItems.length == 0;
	}

	showStub() {
		const stubMsg = op.get(this.state, 'stubs.pde.stringing.text', null);
		return (
			<div className="four-col bank-info-container">
				<div className="section-container-no-data">
					<div className="row prize-money-explanation">{stubMsg}</div>
				</div>
			</div>
		);
	}

	renderStringing() {
		//check for no stringing items array, or an empty stringing items array
		let stringingItems = op.get(this.state, 'data.response', []);
		let message = op.get(this.state.data, 'message', null);
		return (
			<>
				{message !== 'OK' || this.state.data === 'error' ? (
					<div className="four-col bank-info-container">
						<div className="section-container-no-data">
							<div className="row prize-money-explanation">
								The information you are requesting is currently unavailable. Please try again later or
								contact us on{' '}
								<WimLink to="mailto:players@aeltc.com" external={true}>
									players@aeltc.com
								</WimLink>
								.
							</div>
						</div>
					</div>
				) : this.noStringingData() ? (
					<div className="four-col bank-info-container">
						<div className="section-container-no-data">
							<div className="row prize-money-explanation">No data available</div>
						</div>
					</div>
				) : (
					<>
						<HeaderText text={this.state.text} />
						{stringingItems.map((d, i) => {
							return (
								<div className="four-col">
									<Section data={d} key={i} />
								</div>
							);
						})}
					</>
				)}
			</>
		);
	}

	render() {
		logger.log('[PDE - PlayerStringing] render - state:%o status:%o', this.state, this.state.status);
		logger.log('[PDE - PlayerStringing] state %o', this.state);
		const showStub = op.get(this.state, 'stubs.pde.stringing.stub', false);

		if (!this.state.gotData) {
			return <LoadingIndicator />;
		} else {
			if (!showStub) {
				return this.renderStringing();
			} else {
				return this.showStub();
			}
		}
	}
}

PlayerStringing.contextType = PlayerDigitalExperienceContext;
export default connect(mapStateToProps, mapDispatchToProps)(PlayerStringing);

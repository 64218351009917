import { logger } from '../logger';
import CaptionConstants from './CaptionConstants';
import Cea608Row from './Cea608Row';

export default function Cea608CaptionScreen() {
	var NR_ROWS = CaptionConstants.NR_ROWS;

	var rows = [];
	for (var i = 0 ; i <  NR_ROWS; i++) {
		rows.push(Cea608Row({row:i})); // Note that we use zero-based numbering (0-14)
	}
	var currRowNum = NR_ROWS - 1;
	var nrRollUpRows = null;

	//logger.info('Cea608CaptionScreen - constants:%o', CaptionConstants);
	//logger.info('Cea608CaptionScreen - currRowNum:%o rows:%o', currRowNum, rows);

	return Object.freeze({
		getRow,
		rollUpRow,
		currRow,
		reset,
		equals,
		copy,
		isEmpty,
		backSpace,
		clearToEndOfRow,
		insertChar,
		setPen,
		moveCursor,
		setCursor,
		setPAC,
		setBkgData,
		setRollUpRows,
		rollUp,
		getDisplayText,
		getTextAndFormat
	})

	function reset() {
		//logger.info('reset');
		for (var i = 0 ; i < NR_ROWS ; i++) {
			rows[i].clear();
		}
		currRowNum = NR_ROWS - 1;
	};

	function equals(other) {
		var equal = true;
		for (var i = 0 ; i < NR_ROWS ; i++) {
			if (!rows[i].equals(other.getRow(i))) {
				equal = false;
				break;
			}
		}
		return equal;
	};

	function copy(other) {
		for (var i = 0 ; i < NR_ROWS ; i++) {
			rows[i].copy(other.getRow(i));
		}

		nrRollUpRows = other.rollUpRow;
		currRowNum = other.currRow;
	};

	function isEmpty() {
		var empty = true;
		for (var i = 0 ; i < NR_ROWS ; i++) {
			if (!rows[i].isEmpty()) {
				empty = false;
				break;
			}
		}
		return empty;
	};

	function backSpace() {
		var row = rows[currRowNum]; 
		row.backSpace();
	};

	function clearToEndOfRow() {
		//logger.info('clearToEndOfRow');
		var row = rows[currRowNum];
		row.clearToEndOfRow();
	};

	/**
	 * Insert a character (without styling) in the current row.
	 */
	function insertChar(char) {
		var row = rows[currRowNum];
		//logger.info('insertChar - row:%o char:%o-%o', row.id, char, row.getCharForByte(char));
		row.insertChar(char);
	};

	function setPen(styles) {
		var row = rows[currRowNum];
		row.setPenStyles(styles);
	};

	function moveCursor(relPos) {
		var row = rows[currRowNum];
		row.moveCursor(relPos); 
	};

	function setCursor(absPos) {
		//logger.info("setCursor: " + absPos);
		var row = rows[currRowNum];
		row.setCursor(absPos);
	};

	function setPAC(pacData) {
		//logger.info("pacData = " + JSON.stringify(pacData));
		var newRow = pacData.row - 1;
		//test forcing to diff rows
		//newRow = 10;
		//newRow -= 2;
		if (nrRollUpRows  && newRow < nrRollUpRows - 1) {
				newRow = nrRollUpRows-1;
		}
		currRowNum = newRow;
		var row = rows[currRowNum];
		if (pacData.indent !== null) {
			var indent = pacData.indent;
			var prevPos = Math.max(indent-1, 0);
			row.setCursor(pacData.indent);
			//logger.info("setPAC - row:%o row.getChars:%o", row, row.getChars(prevPos));
			pacData.color = row.getChars(prevPos).getPenState().foreground;
		}
		var styles = {foreground : pacData.color, underline : pacData.underline, italics : pacData.italics, background : 'black', flash : false};
		setPen(styles);
	};

	/**
	 * Set background/extra foreground, but first do back_space, and then insert space (backwards compatibility).
	 */
	function setBkgData(bkgData) {
		//logger.info("bkgData = " + JSON.stringify(bkgData));
		backSpace();
		setPen(bkgData);
		insertChar(0x20); //Space
	};

	function setRollUpRows(nrRows) {
		nrRollUpRows = nrRows;
	};

	function rollUp() {
		if (nrRollUpRows === null) {
			//logger.info('rollUp - roll_up but nrRollUpRows not set yet');
			return; //Not properly setup
		}
		//logger.info('rollUp - text:%o', var getDisplayText(true));

		var topRowIndex = currRowNum + 1 - nrRollUpRows;
		var topRow = rows.splice(topRowIndex, 1)[0];
		//logger.info('rollUp - curr:%o rows:%o', currRowNum, rows);
		topRow.clear();
		rows.splice(currRowNum, 0, topRow);
	};

   /**
	* Get all non-empty rows with as unicode text. 
	*/        
	function getDisplayText(asOneRow) {
		asOneRow = asOneRow || false;
		var displayText = [];
		var text = "";
		var rowNr = -1;
		for (var i = 0; i < NR_ROWS ; i++) {
			var rowText = rows[i].getTextString();
			//logger.info('getDisplayText - i:%o row:%o text:%o', i, rows[i].id, rowText);
			if (rowText) {
				rowNr = i+1;
				if (asOneRow) {
					displayText.push("\nRow " + rowNr + ': "' + rowText + '"');
				} else {
					displayText.push(rowText.trim());
				}
			}
		}
		if (displayText.length > 0) {
			if (asOneRow) {
				text = "[" + displayText.join(" | ") + "]";
			} else {
				text = displayText.join("\n");
			}
		}
		return text;
	};

	function getTextAndFormat() {
		return rows;
	};

	function getRow(i) {
		return rows[i];
	};
	
	function rollUpRow () {
		return nrRollUpRows;
	};

	function currRow() {
		return currRowNum;
	};
}
import DrawsPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: '/:lang(en_GB)/draws/:event/:round([0-9])?/:section([0-9])?/:full(full)?',
		exact: true,
		component: DrawsPage,
		section: MENU_ITEMS.draws,
		load: params => deps.actions.DrawsPage.mount(params),
	},
];

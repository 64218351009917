import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import Template from 'appdir/components/Template';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsArchiveLadiesNames'], props);
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.DrawsArchiveLadiesNames.mount()),
});

class DrawsArchiveLadiesNames extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
		};

		logger.log('[DrawsArchiveLadiesNames] props:%o', props);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log(
			'[DrawsArchiveLadiesNames] componentWillReceiveProps - state:%o, nextProps:%o',
			this.state,
			nextProps
		);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		logger.log('[DrawsArchiveLadiesNames] componentDidUpdate - state:%o', this.state);
	}

	render() {
		logger.log('[DrawsArchiveLadiesNames] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'drawsArchive',
			title: 'Draws Archive',
			imageSrc: '/assets/images/headers/drawsArchive_topstats.jpg',
			metaTitle: 'Draws Archive',
			metaDescription: 'Player profile archive information for The Championships, Wimbledon.',
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template className="drawsarchive">
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />
				<div className="drawsArchiveHdrContent">
					<h1>Ladies Names</h1>
					<ArchiveMenu location={this.state.location.pathname} />
				</div>

				<div className="content-main">
					<div className="col-4">
						<div className="draws-archive-stats-table nobreak">
							<div className="datatable">
								<div className="headers">
									<div className="cell">Maiden Name</div>
									<div className="cell">Married Name</div>
								</div>
								<div className="row">
									<div className="cell">Miss B.J. Baker</div>
									<div className="cell">Mrs. J.G. Fleitz</div>
								</div>
								<div className="row">
									<div className="cell">Miss E.V. Bennett</div>
									<div className="cell">Mrs. E.D.F. Whitingstall</div>
								</div>
								<div className="row">
									<div className="cell">Miss B. Bingley</div>
									<div className="cell">Mrs. G.W. Hillyard</div>
								</div>
								<div className="row">
									<div className="cell">Miss P.D.H. Boothby</div>
									<div className="cell">Mrs. A.C. Geen</div>
								</div>
								<div className="row">
									<div className="cell">Miss M. Broquedis</div>
									<div className="cell">Mrs. J. Billout</div>
								</div>
								<div className="row">
									<div className="cell">Miss C.R. Cooper</div>
									<div className="cell">Mrs. A. Sterry</div>
								</div>
								<div className="row">
									<div className="cell">Miss D.K. Douglas</div>
									<div className="cell">Mrs. R.L. Chambers</div>
								</div>
								<div className="row">
									<div className="cell">Miss C.M. Evert</div>
									<div className="cell">Mrs. J.M. Lloyd</div>
								</div>
								<div className="row">
									<div className="cell">Miss J.I. Gannon</div>
									<div className="cell">Mrs. A.J. Mottram</div>
								</div>
								<div className="row">
									<div className="cell">Miss E.F. Goolagong</div>
									<div className="cell">Mrs. R.A. Cawley</div>
								</div>
								<div className="row">
									<div className="cell">Miss H.F. Gourlay</div>
									<div className="cell">Mrs. R.L. Cawley</div>
								</div>
								<div className="row">
									<div className="cell">Miss K.J. Hantze</div>
									<div className="cell">Mrs. J.R. Susman</div>
								</div>
								<div className="row">
									<div className="cell">Miss A.S. Haydon</div>
									<div className="cell">Mrs. P.F. Jones</div>
								</div>
								<div className="row">
									<div className="cell">Miss E.A. Heine</div>
									<div className="cell">Mrs. J.H.K. Miller</div>
								</div>
								<div className="row">
									<div className="cell">Miss J. Henin</div>
									<div className="cell">Mrs. P-Y Hardenne</div>
								</div>
								<div className="row">
									<div className="cell">Miss L. Horn</div>
									<div className="cell">Mrs. A. Huber</div>
								</div>
								<div className="row">
									<div className="cell">Miss P.L. Hokins</div>
									<div className="cell">Mrs. B.C. Covell</div>
								</div>
								<div className="row">
									<div className="cell">Miss A.K. Kiyomura</div>
									<div className="cell">Mrs. D. Hayashi</div>
								</div>
								<div className="row">
									<div className="cell">Miss H. Krahwinkel</div>
									<div className="cell">Mrs. S. Sperling</div>
								</div>
								<div className="row">
									<div className="cell">Miss K. McKane</div>
									<div className="cell">Mrs. L.A. Godfree</div>
								</div>
								<div className="row">
									<div className="cell">Miss B.J. Moffitt</div>
									<div className="cell">Mrs. L.W. King</div>
								</div>
								<div className="row">
									<div className="cell">Miss P.E. Mudford</div>
									<div className="cell">Mrs. M.R. King</div>
								</div>
								<div className="row">
									<div className="cell">Miss J.A. Nicoll</div>
									<div className="cell">Mrs. E.W.A. Bostock</div>
								</div>
								<div className="row">
									<div className="cell">Miss M.E. Osborne</div>
									<div className="cell">Mrs. W. duPont</div>
								</div>
								<div className="row">
									<div className="cell">Miss S.H. Palfrey</div>
									<div className="cell">Mrs. M. Fabyan</div>
								</div>
								<div className="row">
									<div className="cell">Miss K.Y. Po </div>
									<div className="cell">Mrs. O.G.H-P Messerli</div>
								</div>
								<div className="row">
									<div className="cell">Miss V. Puzejova</div>
									<div className="cell">Mrs. C. Sukova</div>
								</div>
								<div className="row">
									<div className="cell">Miss S. Reynolds</div>
									<div className="cell">Mrs. L.E.G. Price</div>
								</div>
								<div className="row">
									<div className="cell">Miss B.R. Rosenquest</div>
									<div className="cell">Mrs. E.C.S. Pratt</div>
								</div>
								<div className="row">
									<div className="cell">Miss M.A. Saunders</div>
									<div className="cell">Mrs. L.R.C. Mitchell</div>
								</div>
								<div className="row">
									<div className="cell">Miss L.I. Savchenko</div>
									<div className="cell">Mrs. A. Neiland</div>
								</div>
								<div className="row">
									<div className="cell">Miss E.M. Sayers</div>
									<div className="cell">Mrs. P.D. Smylie</div>
								</div>
								<div className="row">
									<div className="cell">Miss R.A. Schallau</div>
									<div className="cell">Mrs. T.E. Guerrant</div>
								</div>
								<div className="row">
									<div className="cell">Miss B.V. Schofield</div>
									<div className="cell">Mrs. G.C. Davidson</div>
								</div>
								<div className="row">
									<div className="cell">Miss R. Schuurman</div>
									<div className="cell">Mrs. R. Haygarth</div>
								</div>
								<div className="row">
									<div className="cell">Miss D.C. Shepherd</div>
									<div className="cell">Mrs. W.P. Barron</div>
								</div>
								<div className="row">
									<div className="cell">Miss J. Sigart</div>
									<div className="cell">Mrs. J. de Meulemeester</div>
								</div>
								<div className="row">
									<div className="cell">Miss M. Smith</div>
									<div className="cell">Mrs. B.M. Court</div>
								</div>
								<div className="row">
									<div className="cell">Miss K.E. Stammers</div>
									<div className="cell">Mrs. M. Menzies</div>
								</div>
								<div className="row">
									<div className="cell">Miss J.A.M Tegart</div>
									<div className="cell">Mrs. D.E. Dalton</div>
								</div>
								<div className="row">
									<div className="cell">Miss E.W. Thomson</div>
									<div className="cell">Mrs. D.T.R. Larcombe</div>
								</div>
								<div className="row">
									<div className="cell">Miss L.R. Turner</div>
									<div className="cell">Mrs. W.W. Bowrey</div>
								</div>
								<div className="row">
									<div className="cell">Miss P.M. Walkden</div>
									<div className="cell">Mrs. Q.C. Pretorius</div>
								</div>
								<div className="row">
									<div className="cell">Miss H.N. Wills</div>
									<div className="cell">Mrs. F.S. Moody</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</Template>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveLadiesNames);

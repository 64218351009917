/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: BackToAllMI 
 *
 *  props = {
 *             parsedQs --- override=true to keep HaveYourSay voting open in the MI details & ST
 *           }
 *  if coming from SlamTracker, don't use bypassAppLink() so it can open in a new webview
 * -----------------------------------------------------------------------------
 */
const BackToAllMI = props => {
    return (
        <div className="mi-section no-border-bottom center">
            <WimLink to={`${window.webviewPlatform && props?.type != 'slamtracker' ? '/webview' : ''}/en_GB/matchinsights/index.html${props?.parsedQs?.override ? '?override=true' : ''}`} bypassAppLink={props?.type === 'slamtracker' ? false : true }>
                Back to All Matches 
            </WimLink>
        </div>
    );
};

export default BackToAllMI;

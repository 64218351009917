/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { values } from 'appdir/main';

import DividerHeader from 'appdir/components/common-ui/DividerHeader';
import ShotQualityItem from 'appdir/components/pages/Slamtracker/elements/preview/ShotQualityItem';

import { getRoundFromId } from 'appdir/components/data/Tournament/Util';

import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: ShotQuality
 * -----------------------------------------------------------------------------
 */
const ShotQuality = props => {
    const shotquality_data = useSelector((state) => state?.['Slamtracker']?.shotquality ?? null);
    const disclaimer = useSelector((state) =>  state?.['Config']?.slamtracker?.disclaimerConfig?.shot_quality ?? {});
    const tooltipConfig = useSelector((state) =>  state?.['Config']?.slamtracker?.tooltipConfig ?? {});
    let round;
    let hideDisplay = false;
    let hasData = false;

    if(shotquality_data) { 
        round = getRoundFromId(shotquality_data?.match_id); 

        logger.log('[ShotQuality] shotquality_data:%o, round:%o', shotquality_data, round);

        /** map through all the insights */
        Object.keys(shotquality_data?.results?.insights).map(insight => {
            /** map through the players of each insight */
            Object.keys(shotquality_data.results.insights[insight].player_average).map(player => {
                /** if at least oneof the players had data, set hasData to true */
                if(shotquality_data.results.insights[insight].player_average[player]) {
                    hasData = true;
                }
            })
        });

        // logger.log('[ShotQuality] hasData:%o', hasData);
    }

    const onClickAction = (val) => {
        let actionValue = val ? 'Show' : 'Hide';
        if (!values.webview) {
            measureInWeb({
                action: 'Preview',
                context: [{ pageTitle: 'Slamtracker' }, { tab: "Shot Quality" }, {action: `Tooltip ${actionValue}`}],
            });
        } else {
            measureInAppContext({
                pageTitle: 'Slamtracker',
                action: 'Preview',
                args: ['Shot Quality', `Tooltip ${actionValue}`],
                context: []
            });
        }
    }

    let playerInfo = {
        p1: shotquality_data?.results?.players?.p1?.first_name.charAt(0)+`. ${shotquality_data?.results?.players?.p1?.last_name}`,
        p2: shotquality_data?.results?.players?.p2?.first_name.charAt(0)+`. ${shotquality_data?.results?.players?.p2?.last_name}`,
    }

    return (shotquality_data && hasData) ? (
        <div className="shotquality">
            <DividerHeader 
                text='Shot Quality*' 
                icon="wim-icon-help"
                useTooltip={true} 
                onClickCallback = {(val) => onClickAction(val)}
                tooltipText={tooltipConfig?.shot_quality}
                tooltipStyle="shotquality"/>

            <div className="shotquality-content">
                {Object.keys(shotquality_data?.results?.insights).map((insight, i) => {
                    if(insight !== 'in_attack') {
                        return <ShotQualityItem players={playerInfo} shotType={insight} shotData={shotquality_data?.results?.insights?.[insight]} key={`sq-${insight}`} />
                    }
                })}
            </div>

            <div className="disclaimer">
                {round > 1 ? ( 
                    disclaimer?.tournament 
                ) : (
                    disclaimer?.pre_tournament
                )}
            </div>
        </div>
    ) : null;

};

export default ShotQuality;
import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MYWIMBLEDON_MOUNT:
			logger.info('[MyWimbledon] reducer.MYWIMBLEDON_MOUNT action:%o', action);
			newState = {
				...state,
				...action.data,
				roles: action.roles,
				api: action.api,
				img: action.img,
				url: action.url,
				favoriteTimer: action.favoriteTimer
			};
			return newState;
			break;

		case deps.actionTypes.MYWIMBLEDON_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;
		case deps.actionTypes.MYWIMBLEDON_TOGGLE_MODAL:
			newState = {
				...state,
				...action.data
			};
			return newState;
		default:
			return state;
	}
};

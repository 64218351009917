import React, { Component } from 'react';

import { isMobileSafari, isBrowser } from 'react-device-detect';
import SmartSearch from 'appdir/components/common-ui/SmartSearch';

class SearchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			props,
			value: '',
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	componentDidMount() {
		// document.getElementById("wimbledon-search-text").addEventListener('input', debounce(this.handleChange, 150,{'leading': true, 'trailing': false}), false);
		// window.addEventListener('resize', throttle(this.onResize.bind(this), 0), false);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	handleChange(evt) {
		logger.log('[SearchBox] handleChange - value:%o', this.state.value);
		this.setState({
			value: evt.target.value,
		});
	}

	getFormValue(evt) {
		if (evt) {
			evt.preventDefault();
		}
		this.props.onEnter(this.state.value);
		//logger.log('[SearchBox] handleChange - value:%o', this.state.value);
	}

	handleKeyPress(evt) {
		if (evt.key == 'Enter') {
			logger.log('[SearchBox] handleKeyPress - value:%o', this.state.value);
			this.props.onEnter(this.state.value);
		}
	}
	handleBlur(evt) {
		logger.log('[SearchBox] handleBlur - value:%o', this.state.value);
		if (isMobileSafari) {
			this.props.onEnter(this.state.value);
		}
	}
	render() {
		//logger.log('[SearchBox] render - props:%o', this.props);
		//let matchData = this.state.attributes;
		return (
			<div className="search-box">
				<div className="search-wrapper">
					<input
						id="wimbledon-search-text"
						type="text"
						value={this.state.value}
						onChange={this.handleChange}
						onKeyPress={this.handleKeyPress}
						onBlur={this.handleBlur}
						placeholder="Search Wimbledon.com"
						autocomplete="off"
					/>
					{/* <input id="wimbledon-search-text" type="text" value={this.state.value} onKeyPress={this.handleKeyPress} onBlur={this.handleBlur}  placeholder="Search Wimbledon.com"/> */}
					{isBrowser ? (
						<SmartSearch
							data={{
								queryPath: this.props.queryPath,
								value: this.state.value,
								submitFn: this.props.onEnter.bind(this),
							}}
						/>
					) : null}
					<i className="wim-icon-search" />
				</div>
			</div>
		);
	}
}
export default SearchBox;

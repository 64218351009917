import deps from 'dependencies';

export default {
	togglePromoFeature: (display, registration) => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.PROMOFEATURE_TOGGLE,
			data: { showPromoFeature: display, registrationComplete: registration },
		});
	},
};

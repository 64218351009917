/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import parse from 'html-react-parser';
import { createRoot } from 'react-dom/client';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Modal
 * -----------------------------------------------------------------------------
 */

class Modal extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data, showModal: false };
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
		this.modalTarget = document.createElement('div');
		document.body.appendChild(this.modalTarget);
		this.root = createRoot(this.modalTarget);
		this._render();
	}

	componentDidUpdate() {
		this._render();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentWillUnmount() {
		this.root.unmount(this.modalTarget);
		document.body.removeChild(this.modalTarget);
	}

	_render() {
		this.root.render(this.renderModalContent());
	}

	showModal(bool) {
		this.setState(prevState => {
			return { ...prevState, showModal: bool };
		});
	}

	renderModalContent() {
		let modalContent = this.state['modalContent'];
		let image = this.state['image'];
		return this.state.showModal ? (
			<span className="modal-content-overlay" style={{ display: this.state.showModal ? 'block' : 'none' }}>
				<div className={`modal-content ${this.state.style} ${this.state.showModal ? 'show' : ''}`}>
					<img src={image.url} alt={modalContent.subtitle} />
					<div className="scrollable">
						<div className="modal-content-text">
							<h2>{image.caption}</h2>
							<hr />
							<div className="subtitle">{modalContent.subtitle}</div>
							{/* <div className="title">{modalContent.subtitle}</div> */}
							<div className="description">{/*ReactHtmlParser*/ parse(modalContent.content)}</div>
						</div>
					</div>
					<i
						onClick={() => {
							this.showModal(false);
						}}
						className="wim-icon-close"
					/>
				</div>
			</span>
		) : null;
	}

	render() {
		let modalContent = this.state['modalContent'];
		let image = this.state['image'];
		return (
			<div
				className={`modal-image ${this.state.style}`}
				onClick={() => {
					this.showModal(true);
				}}>
				<img style={{ width: '100%' }} src={image.url} />
				<div className="title">{image.caption}</div>
			</div>
		);
	}
}

export default Modal;

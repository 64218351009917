/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ToolTip
 * 
 * currently used in slamtracker head to head section
 * styles found in _scss/commonui/divider-header
 * 
 * props:
 *   text: text for tooltip (required)
 *   style: className for tooltip (required)
 * -----------------------------------------------------------------------------
 */
const ToolTip = props => {    
    return (
        <div className={`tooltip ${props.style ? `tooltip-${props.style}` : null}`}>
            {props.text}
        </div>
    )
};

export default ToolTip;
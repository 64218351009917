import classNames from 'classnames';
import React, { Component } from 'react';
import { handleSwapTickets } from 'appdir/components/pages/Tickets/ReusableTicketActionFuncs';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
class SwapTicketAction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirmationModal: false,
			email: '',
		};
	}

	onHandleSwapResponse(which, error) {
		if (error) {
			let message1, message2;
			let message1Default = 'There was an error processing your request. Please try again.';
			let message2Default = '';

			const errCode = error?.data?.response?.failure[0] ? error.data.response.failure[0].errorCode : '';

			/** if there is no error code, use default messages */
			if (!errCode) {
				if (error?.msg) {
					message1 = error?.msg;
					message2 = message2Default;
				} else {
					message1 = message1Default;
					message2 = message2Default;
				}
			} else {
				message1 = message1Default;
				message2 = error?.data?.response?.failure[0]?.errorMessage
					? error?.data?.response?.failure[0]?.errorMessage
					: message2Default;
			}
			this.props.onHandleSwapError(message1, message2);
		} else {
			this.props.onSwapSuccessful();
		}
	}

	render() {
		const {
			fullTicketValue,
			callTicketActions,
			closeModal,
			ticketStubToRender,
			ticketStubSwapToRender,
			qrCodeScanned,
		} = this.props;

		return (
			<div className="swap-ticket-action-container">
				<div className="ticket-action-modal-close-btn">
					<CloseBtn closeFn={() => closeModal(this.state.successfulCall)} />
				</div>
				<div
					className="ticket-action-modal-title ticket-padding-top"
					tabIndex={0}
					alt="swap tickets"
					aria-label="swap tickets">
					SWAP TICKETS
				</div>
				<div className="ticket-action-modal-divider-container">
					<div className="ticket-action-modal-title-divider" />
				</div>
				<div className="ticket-action-modal-ticket-stub">{ticketStubToRender}</div>
				<img
					src="/assets/images/tickets/ticket_swap.png"
					className="tix-mgmt-sort-icon"
					aria-hidden
					tabIndex={-1}
				/>
				<div className="ticket-action-modal-ticket-stub">{ticketStubSwapToRender}</div>
				<div className="ticket-stub-actions-btns-cntr">
					<button role="button" className="ticket-stub-actions-value" onClick={() => closeModal()}>
						Cancel Swap
					</button>
					<button
						className={classNames('ticket-stub-actions-value', 'ticket-action-modal-button-green')}
						role="button"
						onClick={() => {
							this.props.setLoading(true);

							// call swap ticket action
							handleSwapTickets(fullTicketValue, qrCodeScanned, callTicketActions, (val, err) =>
								this.onHandleSwapResponse(val, err)
							);
						}}
						type="submit">
						Swap Tickets
					</button>
				</div>
			</div>
		);
	}
}
export default SwapTicketAction;

import * as yup from 'yup';

export function TransferSchema(spectatorEmail, email) {
	return yup.object({
		recipientEmail: yup
			.string()
			.lowercase()
			.notOneOf([spectatorEmail], 'Please enter a different myWimbledon account email')
			.email('Please enter a valid myWIMBLEDON account email')
			.required('Please enter a myWIMBLEDON account email.'),
		confirmRecipientEmail: yup
			.string()
			.required('Please re-enter the myWIMBLEDON account email.')
			.oneOf([email, null], 'myWimbledon account email addresses must match'),
	});
}

export function handleSubmitTransfer(
	email,
	spectatorId,
	data,
	selectedAction,
	callTicketActions,
	onSubmit,
	msg,
	msg2,
	errMsg
) {
	let ticketSourceId = data.map(a => a?.source?.id) || [];
	let transferShareData = {
		sid: spectatorId,
		target: email,
		tickets: ticketSourceId,
	};

	callTicketActions(selectedAction, spectatorId, transferShareData)
		.then(response => {
			logger.log('[ReusableFunctions - handleSubmitTransfer] - response:%o', response);

			// additional error handling check
			// if response gives status 200, check to make sure there is no failure (failure path= response.data.response.failure[0].errorMessage)
			if (response?.data?.response?.failure[0]?.errorMessage) {
				onSubmit(selectedAction, { msg: response.data.response.failure[0].errorMessage });
			} else {
				onSubmit(selectedAction, null, msg, msg2);
			}
		})
		.catch(error => {
			logger.error('[ReusableFunctions - handleSubmitTransfer] - error:%o', error);
			onSubmit(selectedAction, error, errMsg);
		});
}

export function handleCancelTicketTransfer(spectatorId, data, callTicketActions, onSubmit, msg) {
	// logger.log('[Tickets - RecallTickets] handleSubmit - values:%o', values);

	let ticketSourceId = data.map(a => a?.source?.id) || [];

	logger.log('[ReusableFunctions] handleCancelTicketTransfer - ticketSourceId:', ticketSourceId);
	logger.log('[ReusableFunctions] handleCancelTicketTransfer - spectatorId:', spectatorId);

	callTicketActions('recall', spectatorId, ticketSourceId) // NOTE: known as "recall" in last years code (recall = cancel transfer)
		.then(response => {
			logger.log('[ReusableFunctions] handleCancelTicketTransfer - response :%o', response);
			onSubmit('recall', null, msg);
		})
		.catch(error => {
			logger.error('[ReusableFunctions] handleCancelTicketTransfer - error:%o', error);
			onSubmit('recall', true);
		});
}

export function handleRequestTicketCancellation(spectatorId, data, callTicketActions, onSubmit, msg, reason) {
	// in 2022 only one ticket per action so get spectatorId from ticket

	let ticketObj = {
		ticketId: data.source.id,
		reasonCode: reason.value,
		reason: reason.label,
	};

	let cancelData = {
		sid: spectatorId,
		tickets: [ticketObj],
	};

	logger.log('[ReusableFunctions] handleRequestTicketCancellation - cancelData:%o', cancelData);

	callTicketActions('cancel', spectatorId, cancelData)
		.then(response => {
			// additional error handling check
			// if response gives status 200, check to make sure there is no failure (failure path= response.data.response.failure[0].errorMessage)
			if (response?.data?.failure[0]?.reason) {
				onSubmit('cancel', { msg: `${response.data.failure[0].reason}.` });
			} else {
				onSubmit('cancel', null, msg);
			}
		})
		.catch(error => {
			logger.error('[ReusableFunctions] handleRequestTicketCancellation - error:%o', error);
			onSubmit('cancel', true);
		});
}

export function handleReturnTicketToSender(
	spectatorId,
	transferredSourceEmail,
	data,
	callTicketActions,
	onSubmit,
	msg
) {
	let returnData = [];

	let ticketSourceId = data.map(a => a?.source?.id) || [];

	let ticketReceiverEmail = transferredSourceEmail || '';

	if (ticketSourceId === '' || ticketReceiverEmail === '') {
		onSubmit('return', true);
	} else {
		ticketSourceId.map(val => {
			return returnData.push({
				receiverEmail: ticketReceiverEmail,
				ticketId: val,
			});
		});

		logger.log('[ReusableFunctions] handleReturnTicketToSender - returnData:%o', returnData);

		callTicketActions('return', spectatorId, returnData)
			.then(response => {
				// note: response from tixngo is a 201 with empty string as data param
				logger.log('[ReusableFunctions] handleReturnTicketToSender - response :%o', response);
				onSubmit('return', null, msg);
			})
			.catch(error => {
				logger.error('[ReusableFunctions] handleReturnTicketToSender - error:%o', error);
				onSubmit('return', true);
			});
	}
}

export function handleSwapTickets(ticketA, ticketB, callTicketActions, onSubmit) {
	// grab ticketID and spectatorID from ticket A
	const ticketIdA = ticketA?.source?.id?.includes('www.wimbledon.com@')
		? ticketA?.source?.id?.split('www.wimbledon.com@')[1]
		: ticketA?.source?.id;
	const spectatorIdA = ticketA?.spectatorId;

	// grab ticketID and spectatorID  from QR code string (ticket B)
	let splitTxt = ticketB?.split('*');
	const ticketIdB = splitTxt[0]?.includes('www.wimbledon.com@')
		? splitTxt[0]?.split('www.wimbledon.com@')[1]
		: splitTxt[0];
	const spectatorIdB = splitTxt[1];

	let transferShareData = {
		sid1: spectatorIdA,
		ticketId1: ticketIdA,
		sid2: spectatorIdB,
		ticketId2: ticketIdB,
	};

	// onSubmit(true); // TODO: update based on response (true = success, false = error)

	callTicketActions('exchange', spectatorIdA, transferShareData)
		.then(response => {
			logger.log('[ReusableFunctions - handleSwapTickets exchange] - response:%o', response);
			onSubmit('swap', null);

			// additional error handling check
			// if response gives status 200, check to make sure there is no failure (failure path= response.data.response.failure[0].errorMessage)
			if (response?.data?.response?.failure[0]?.errorMessage) {
				onSubmit('swap', { msg: response?.data?.response?.failure[0]?.errorMessage });
			} else {
				onSubmit('swap');
			}
		})
		.catch(error => {
			logger.error('[ReusableFunctions - handleSwapTickets exchange] - error:%o', error);
			onSubmit('swap', error);
		});
}

export function checkTicketStatus(tickets, verifyTicketStatus, callback) {
	// grab ticket IDs
	let data = [];
	let spectatorId;
	if (tickets.length > 1) {
		tickets.map(ticket => {
			if (!spectatorId) spectatorId = ticket?.spectatorId;
			data.push({ ticketId: ticket?.source?.externalId, security: ticket?.source?.security });
		});
	} else {
		data = [
			{ ticketId: tickets[0]?.source?.externalId, security: tickets[0]?.source?.security },
			{ ticketId: tickets[0]?.source?.externalId, security: tickets[0]?.source?.security },
		];
	}

	verifyTicketStatus(data, spectatorId)
		.then(response => {
			logger.log('[ReusableFunctions - ticketSwapControl] - response:%o', response);
			callback(response, null);
		})
		.catch(error => {
			logger.error('[ReusableFunctions - ticketSwapControl] - error:%o', error);
			callback(null, error);
		});
}

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component:  MyWimbledonTitle
 * -----------------------------------------------------------------------------
 *
 * @param state
 * @param state.className {String} an addtl classname.
 * @param state.style {Object} CSS styles to apply to the component.
 * @param state.size Valid CSS font-size value.
 * @param state.color Valid CSS color value.
 *
 * @return {*}
 * @constructor
 */
const MyWimbledonTitle = props => {
	let cls = props.hasOwnProperty('className') ? ['myWimbledon', props.className] : ['myWimbledon'];

	let style = props.hasOwnProperty('style') ? props.style : {};

	if (props.hasOwnProperty('fontSize')) {
		style['fontSize'] = props.fontSize;
	}
	if (props.hasOwnProperty('color')) {
		style['color'] = props.color;
	}

	if (props.hasOwnProperty('link')) {
		return (
			<span className={`myWimbledon ${cls.join(' ')}`} style={style}>
				<WimLink to="/en_GB/mywimbledon/" title="my wimbledon">
					<i className="wim-icon-myw my" aria-hidden />
					<span aria-hidden className="wimbledon">
						{getText(props.hasOwnProperty('short'))}
					</span>
				</WimLink>
			</span>
		);
	} else {
		return (
			<span
				className={`myWimbledon ${cls.join(' ')}`}
				style={style}
				aria-label={`my ${getText(props.hasOwnProperty('short'))}`}>
				<i className="wim-icon-myw my" tabIndex={-1} />
				<span className="wimbledon">{getText(props.hasOwnProperty('short'))}</span>
			</span>
		);
	}
};

const getText = short => {
	if (!short) {
		return 'Wimbledon';
	} else {
		return 'W';
	}
};

/**
 * -----------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------
 */
export default MyWimbledonTitle;

import React, { Component, createRef } from 'react';
import Button from 'appdir/components/common-ui/Button';
import ExitVirtualQueueConfirmation from 'appdir/components/webviews/VirtualQueueWebview/ExitVirtualQueueConfirmation.js'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import axios from 'axios';
import parse from 'html-react-parser';
import GenericButton from 'appdir/components/cms/GenericButton';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import { getErrorText } from 'appdir/components/general/Util/Error';
import { handleGetVirtualQueueStatusEndpoint } from 'appdir/components/webviews/VirtualQueueWebview/virtualQueueEndpoints.js';


class VirtualQueueStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showExitConfirmation: false,
			lastUpdatedTime: moment(),
            ...props
        }

		this.cmsTextRef = createRef();
    	this.adjustStubHeight = this.adjustStubHeight.bind(this);
	}

	componentDidMount() {
		// for dynamic resizing of CMS stubs based on how much content they contain
		this.adjustStubHeight();
    	window.addEventListener('resize', this.adjustStubHeight);

		this.getQueueMessage();
		this.getQueueStatus();
		this.getQueueContent();
	}

	componentDidUpdate(prevProps, prevState) {
		// check if cms text has changed
		if (prevState.cmsTxt !== this.state.cmsTxt) {
		  this.adjustStubHeight();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.adjustStubHeight);
	}

	// dynamically resize CMS stubs based on how much content they contain
	adjustStubHeight() {
		if (this.cmsTextRef.current) {
		  const cmsTextHeight = this.cmsTextRef.current.clientHeight;
		  let contentHeight = 400 - cmsTextHeight;
		  document.getElementById('webview-ticket-queue-cms-stub')?.style?.setProperty('height', `calc(100vh - ${contentHeight}px)`);
		}
	}

	getQueueMessage() {
		let { cmsTxt } = this.state;
		axios.get(this.props.configData?.message)
			.then(res => {
				cmsTxt = parse(res?.data?.text);
				logger.log('[VirtualQueueStatus] getQueueMessage() - cmsTxt:%o', cmsTxt);
				this.setState({ cmsTxt })
			})
			.catch(err => { 
				logger.error('[VirtualQueueStatus] getQueueMessage() - error:%o', err);
				this.props.showModal({
					showModal: true,
					showCloseBtn: true,
					type: 'error',
					title: 'Error Getting CMS Message',
					message: getErrorText(err?.code || err?.data?.response?.error?.code),
					useTimer: false,
				});
			});
	}

	getQueueContent() {
		let { cmsQueueUpdates } = this.state;
		axios.get(this.props.configData?.updates)
			.then(res => {
				cmsQueueUpdates = res?.data?.updates;
				logger.log('[VirtualQueueStatus] getQueueContent() - cmsQueueUpdates:%o', cmsQueueUpdates);
				this.setState({ cmsQueueUpdates });
				// timeout otherwise modal never actually shows 
				setTimeout(() => {
					this.props.showModal({
						showModal: false,
						showCloseBtn: true,
						type: 'success',
						title: 'Success',
						message: 'You have refreshed queue status',
						useTimer: false,
					});
				}, 1000);
			})
			.catch(err => { 
				logger.error('[VirtualQueueStatus] getQueueContent() - error:%o', err);
				this.props.showModal({
					showModal: true,
					showCloseBtn: true,
					type: 'error',
					title: 'Error Getting CMS Content',
					message: getErrorText(err?.code || err?.data?.response?.error?.code, "Virtual Queue"),
					useTimer: false,
				});
			});
	}

	getQueueStatus() {
		let cmsQueueStatus = '';
		axios.get(this.props.configData?.status)
		.then(res => {
			cmsQueueStatus = parse(res?.data?.text);
			logger.log('[VirtualQueueStatus] getQueueStatus() - cmsQueueStatus:%o', cmsQueueStatus);
			this.setState({ cmsQueueStatus, lastUpdatedTime: moment(), });
		})
		.catch(err => { 
			logger.error('[VirtualQueueStatus] handleGetQueueStatus() - error:%o', err);
			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: 'Error Getting Status',
				message: getErrorText(err?.code || err?.data?.response?.error?.code, "Virtual Queue"),
				useTimer: false,
			});
		});
	}

	sendViewGuideMetrics = () => {
		measureInAppContext({
			pageTitle:"Virtual Queue",
			action: "View Queue Guide",
			args: [],
			context:  [{ section: "Virtual Queue" }]
		});
	}

	onCallQueueStatusEndpoint() {
		let queueStatus = '';
		this.props.getJWT().then(token => {
			// get queue status	
			let path = this.props.queueAPIs?.virtualQueueStatus;
			handleGetVirtualQueueStatusEndpoint(token.jwt.id_token, path).then((response) => {
				logger.log('[VirtualQueueStatus] handleGetQueueStatus() response %o', response);
				// logger.log('[VirtualQueueStatus] handleGetQueueStatus() response error %o', response?.data?.response?.error);

				// find virtual queue booking
				let respResult = response?.data?.response?.result;
				const filteredRes = respResult.filter(item =>
					this.props.queues.some(queue => queue.id === item.queue_id && !queue.ticketResale)
				);
				// sort by creation time, and get most recent booking
				let queueRes = filteredRes ? this.sortResult(filteredRes) : [];

				// if response is 200 but error inside, means user has not yet joined a queue 
				queueStatus = queueRes?.booking_status > -1 ? queueRes?.booking_status : 'notjoined';
				this.props.updateQueueStatus({ queueStatus: queueStatus, queueStatusObj: queueRes })

				// this.setState({ queueStatus: queueStatus, queueStatusObj: queueRes });

			}).catch(error => {
				logger.error('[VirtualQueueStatus] handleGetQueueStatus() - error:%o', error);
				queueStatus = 0;
				// this.setState({ queueStatus: queueStatus });
				this.props.updateQueueStatus({ queueStatus: queueStatus })
			});
		})
	}



	render() {
		const { showExitConfirmation, cmsTxt, cmsQueueStatus, cmsQueueUpdates, lastUpdatedTime } = this.state;

		if (showExitConfirmation) {
			return (
				<ExitVirtualQueueConfirmation
					closeModal={() => {
						this.setState({ showExitConfirmation: false});
					}}
					getJWT={() => this.props.getJWT()}
					showModal={(data) => this.props.showModal(data)}
					queueAPIs={this.props.queueAPIs}
					booking_id={this.props.booking_id}
					queue_id={this.props.queue_id}
					exitQueueResponse={(status, resp) => {
						this.props.exitQueueResponse(status, resp)
						if (status == 'success') this.setState({ showExitConfirmation: false });
					}}
				/>
			)
		}

		if (cmsQueueStatus < 0) {
			return <div>You are not in a queue</div>
		}
		return (
			<div className="webview-ticket-queue" >
                <div ref={this.cmsTextRef} className="webview-ticket-queue-cms-text">{cmsTxt}</div>
				<div className="webview-ticket-queue-position-header">Currently being served:</div>
				<div className="webview-virtual-queue-position-number">{cmsQueueStatus}</div>
				<div className="webview-ticket-queue-refresh-btn-grey">
					<Button
						role="button"
						aria-label="REFRESH"
						onClick={() => {
							measureInAppContext({
								pageTitle:"Virtual Queue",
								action: "Refresh",
								args: [],
								context:  []
							});

							this.props.showModal({
								showModal: true,
								showCloseBtn: false,
								type: 'updating',
								title: 'Refreshing',
								message: 'Please do not close the window while submitting.',
								useTimer: false,
							});
							this.onCallQueueStatusEndpoint();
							this.getQueueMessage();
							this.getQueueStatus();
							this.getQueueContent();
						}}
					>REFRESH</Button>
				</div>
				<div className="webview-ticket-queue-last-updated" tabIndex={0} aria-label={`Last Updated: ${moment(lastUpdatedTime).tz('Europe/London').format("HH:mm")}`}>Last Updated: {moment(lastUpdatedTime).format("HH:mm")} </div>
				<div className="webview-ticket-queue-cms-stub" id="webview-ticket-queue-cms-stub">
					{cmsQueueUpdates?.map((update, index) => {
						return (
							<div key={index} className="webview-ticket-queue-cms-stub-updates">
								{update.images && update.images[0]?.small && update.images[0]?.small?.length ? <div className='webview-ticket-queue-cms-stub-updates-img'><img src={update.images[0]?.small} /></div> : null}
								<div className='webview-ticket-queue-cms-stub-updates-txt'>
									<div className='webview-ticket-queue-cms-stub-updates-time'>{moment(update?.displayDate).format("hh:mm")}</div>
									<div className='webview-ticket-queue-cms-stub-updates-title'>{update?.title}</div>
									<div>{parse(update?.text)}</div>
								</div>
							</div>
						)
					})}
				</div>
				<div className="webview-ticket-view-map-cntr-virtual webview-queue-view-guide-cntr" onClick={() => {this.sendViewGuideMetrics()}}>
					<GenericButton
					 	data={{
							style: 'four-col',
							link: this.props.viewQueueGuideURL,
							title: 'view queue guide',
							text: (<span><i className='wim-icon-queue-guide' />VIEW QUEUE GUIDE</span>)
						
						}}
						>
					</GenericButton>
				</div>
				<div className="webview-ticket-queue-exit-queue-cntr-virtual">
					<Button
						role="button"
						aria-label="EXIT QUEUE"
						onClick={() => {
							measureInAppContext({
								pageTitle:"Virtual Queue",
								action: "Exit Queue",
								args: [],
								context:  [{ section: "Virtual Queue" }],
								metricType: 'state'
							});
							this.setState({ showExitConfirmation: true })
						}}
					>EXIT QUEUE</Button>
				</div>

			</div>
		);
	}
}

export default VirtualQueueStatus;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
// import { isMobileSafari, isBrowser } from 'react-device-detect';
import parse from 'html-react-parser';
import Autosuggest from 'react-autosuggest';

const mapStateToProps = (state, props) => {
	return {
		...state['SearchBox'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		playerListData: state['CommonData']['playerTourn'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

class SearchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			props,
			value: this.props.value ? this.props.value : '',
			// staticData: props.staticData,
			noSuggestions: false,
			filterData: [],
			found: false,
			showResults: false,
			noSuggFocus: true,
		};

		this.handleChange = this.handleChange.bind(this);
		//logger.log('[SearchBox] constructor - props:%o', this.props);
	}

	componentDidMount() {
		this.setState({
			status: 'loaded',
		});
	}

	handleChange(vals) {
		let value = vals;
		// use specified list if passed in (searchList) otherwise use playerListData
		let playerList = this.props.searchList || op.get(this.props, 'playerListData.result.players', []);
		logger.log('[SearchBox] handleChange - playerList:%o', playerList);
		let playerSearchData = [];

		playerList.map((p, i) => {
			playerSearchData.push({
				firstName: p.first_name,
				lastName: p.last_name,
				id: p.player_id,
				tour_id: p.id,
			});
		});

		let filterData = playerSearchData.filter(d => {
			return (
				d.firstName.toLowerCase().indexOf(value.value.toLowerCase()) !== -1 ||
				d.lastName.toLowerCase().indexOf(value.value.toLowerCase()) !== -1
			);
		});

		return filterData;
	}

	onChange(event, { newValue, method }) {
		//logger.log('[SearchBox] onChange - newValue:%o', newValue);

		this.setState({
			value: newValue,
			found: false,
		});
		if (newValue == '') {
			this.props.onEnter('');
		}
	}

	getFormValue(evt) {
		if (evt) {
			evt.preventDefault();
		}
		this.props.onEnter(this.state.value);
		logger.log('[SearchBox] onEnter - value:%o', this.state.value);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.reset !== this.props.reset && this.props.reset === true) {
			// logger.log('[SearchBox] componentDidUpdate - should reset:%o', this.props.reset);

			this.resetSearch();
		}

		if (this.props.sharedDataConfig && !this.props.playerListData && !this.props.searchList) {
			let commonDataConfig = {
				...this.props.sharedDataConfig['playerTourn'],
				path: this.props.sharedDataConfig['playerTourn']?.path,
			};
			this.props
				.checkExpired(commonDataConfig)
				.then(response => {
					logger.log('[SearchBox] componentDidUpdate playerTourn - response:%o', response);
					if (response.status == 'expired') {
						this.props.update(commonDataConfig);
					}
				})
				.catch(error => {
					logger.error('[SearchBox] componentDidUpdate playerTourn - :o', error);
				});
		}
	}

	onSuggestionsFetchRequested(value) {
		let filterData = this.handleChange(value);
		let noSuggestions = filterData.length == 0 ? true : false;
		let noSuggFocus = filterData.length == 0 ? true : false;
		this.setState({
			filterData: this.handleChange(value),
			noSuggestions,
			noSuggFocus,
		});
	}

	onSuggestionsClearRequested() {
		this.setState({
			filterData: [],
		});
	}

	onSuggestionSelected(event, { suggestion }) {
		this.setState({ noSuggestions: false, found: true });
		this.props.onEnter(suggestion);
	}

	renderAutoSuggest(data) {
		return (
			<div className={`result-article-title`}>
				{/*ReactHtmlParser*/ parse(this.highlightText(this.state.value, `${data.firstName} ${data.lastName}`))}
			</div>
		);
	}

	getSuggestionValue(data) {
		return `${data.firstName} ${data.lastName}`;
	}

	highlightText(searchTerm, text) {
		let searchTermLower = searchTerm;
		let searchRegex = new RegExp(searchTermLower, 'gi');
		if (text) {
			return `<span>${text.replace(searchRegex, str => `<span class='bold-text'>${str}</span>`)}</span>`;
		}
		return null;
	}

	resetSearch() {
		logger.log('[SearchBox] resetSearch - props:%o', this.props);

		this.setState(
			{
				value: '',
			},
			() => {
				this.props.onEnter('');
			}
		);
	}

	onBlur(event, { highlightedSuggestion }) {
		this.setState({
			noSuggFocus: false,
		});
	}

	render() {
		const { value } = this.state;
		const inputProps = {
			placeholder: 'Search for a Player',
			value,
			onChange: this.onChange.bind(this),
			onBlur: this.onBlur.bind(this),
			'aria-label': 'use arrow keys to navigate list',
		};
		logger.log('[SearchBox] render - state:%o', this.state);

		return (
			<div className="search-box search-box-container oop">
				<div className="search-wrapper">
					<i className="wim-icon-search" />
					{this.state.value && this.state.value.length > 0 && (
						<i className="wim-icon-close" onClick={this.resetSearch.bind(this)} />
					)}

					<Autosuggest
						suggestions={this.state.filterData}
						onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
						onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
						onSuggestionSelected={this.onSuggestionSelected.bind(this)}
						getSuggestionValue={this.getSuggestionValue.bind(this)}
						renderSuggestion={this.renderAutoSuggest.bind(this)}
						focusInputOnSuggestionClick={false}
						inputProps={inputProps}
					/>

					{this.state.value &&
						this.state.value.length > 0 &&
						this.state.noSuggestions &&
						this.state.noSuggFocus &&
						!this.state.found && <div className="autosuggest-not-found">Player not found</div>}
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SiteMap from 'appdir/components/general/SiteMap';

import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledonReset
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class MyWimbledonMigrate extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		let parsedQs = getQuerystringValues(false, true);
		this.email = parsedQs.email || null;
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {}

	render() {
		let currentUser = op.get(this.state, 'currentUser', {});

		logger.log('[MyWimbledonMigrate] render - props:%o email:%o', this.props, this.email);

		let header_propsData = {
			headerType: 'mywimbledon',
			title: 'myWimbledon',
			shortTitle: 'myW',
			metaTitle: 'myWimbledon',
			metaDescription: 'myWimbledon - Wimbledon ' + values.tournamentYear,
			metaDate: '',
			metaPlayers: '',
		};

		if (!this.state.gigyaLoaded) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout news">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		} else if (isEmpty(currentUser) && this.props?.location?.pathname.includes('migrate/lta')) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout news">
						<div className="four-col byline ">
							<div className="byline--attribution" />
						</div>
						<div className="two-col textWrapContent margin">
							<p>
								Please enter and submit your LTA Advantage account email address below, so we can match
								your new myWIMBLEDON account with your successful ballot entry. You will find the email
								address to use in the LTA email notifying you of your successful ballot entry.
							</p>
						</div>
						<div className="four-col textWrapContent margin">
							<GigyaScreenSet params={{ screen: 'forgot_password', email: this.email }} inline={true} />
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else if (isEmpty(currentUser)) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout news">
						<div className="four-col byline ">
							<div className="byline--attribution" />
						</div>
						<div className="four-col textWrapContent margin">
							<p>Welcome to myWIMBLEDON</p>
							<p>
								In order to complete your log in, you will need to create a password for your account.
								Please enter your email address below and you will receive an email which will contain
								instructions for setting your myWIMBLEDON password.
							</p>
							<p />
							<GigyaScreenSet params={{ screen: 'forgot_password' }} inline={true} />
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout news">
						<div className="four-col byline ">
							<div className="byline--attribution" />
						</div>
						<div className="four-col textWrapContent margin">
							<p className="migrate-text">Welcome to myWimbledon. You are logged in.</p>
						</div>
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWimbledonMigrate);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';

import classNames from 'classnames';
import PlayerImage from './PlayerImage';
import { getAppLink } from 'appdir/components/general/Util';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ScoreTable
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MatchBox'],
		uom: state['Controller'].userPreferences['speed'],
		windowSize: state['PageHeader'].windowSize,
		favourites: state['Controller'].favourites,
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	mount: () => dispatch(deps.actions.MatchBox.mount()),
	hide: () => dispatch(deps.actions.SlamtrackerPanel.hide()),
});

class MatchBox extends Component {
	constructor(props) {
		//logger.log("[MatchBox] constructor");
		super(props);
		this.state = { ...props, lastScore_t1: false, lastScore_t2: false };
		//this.t1_gameScore;
		//this.t2_gameScore;
		this.matchBoxRef = React.createRef();
		this.onLinkClick = this.onLinkClick.bind(this);
	}

	componentDidMount() {
		this.props.mount();
	}

	componentWillUnmount() {
		clearTimeout(this.t1_timer);
		clearTimeout(this.t2_timer);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.MatchBoxConfig) {
			this.props.mount();
		}
		const curAttributes = this.props.attributes;

		// logger.log(
		// 	'[MatchBox] componentDidUpdate match:%o team1 curScore:%o this.t1:%o',
		// 	curAttributes.data.match_id,
		// 	curAttributes.data.scores.gameScore[0],
		// 	this.t1_gameScore
		// );
		// logger.log(
		// 	'[MatchBox] componentDidUpdate match:%o team2 curScore:%o this.t2:%o',
		// 	curAttributes.data.match_id,
		// 	curAttributes.data.scores.gameScore[1],
		// 	this.t2_gameScore
		// );
		let scoreData = this.state.attributes.data;
		let searchedPlayer = op.get(this.props, 'attributes.searchedPlayer', false);
		let prevSearched = op.get(prevProps, 'attributes.searchedPlayer', false);

		let containsSearched =
			searchedPlayer &&
			(searchedPlayer.indexOf(scoreData.team1.idA) !== -1 ||
				searchedPlayer.indexOf(scoreData.team1.idB) !== -1 ||
				searchedPlayer.indexOf(scoreData.team2.idA) !== -1 ||
				searchedPlayer.indexOf(scoreData.team2.idB) !== -1);

		if (containsSearched && searchedPlayer != prevSearched) {
			if (this.matchBoxRef.current && this.matchBoxRef.current !== null) {
				let el = this.matchBoxRef.current;
				let elOffset = el.offsetTop;
				let offset = 106; //height of the header
				window.scroll({
					top: elOffset - offset,
					behavior: 'smooth',
				});
			}
		}

		if (!this.t1_gameScore) {
			this.t1_gameScore = curAttributes.data.scores.gameScore[0];
		}
		if (!this.t2_gameScore) {
			this.t2_gameScore = curAttributes.data.scores.gameScore[1];
		}

		if (
			curAttributes.data.scores.gameScore[0] != this.t1_gameScore &&
			!this.state.lastScore_t1 &&
			curAttributes.data.scores.gameScore[0] !== '' &&
			curAttributes.data.scores.gameScore[0]
		) {
			//logger.log('[MatchBox] componentDidUpdate match:%o team1 score changed:%o to %o', curAttributes.data.match_id, this.t1_gameScore, curAttributes.data.scores.gameScore[0] );
			this.setState({
				lastScore_t1: true,
				lastScore_t2: false,
			});
			if (curAttributes.data.scores.gameScore[0]) {
				this.t1_timer = setTimeout(() => this.setState({ lastScore_t1: false }), 5000);
			}

			this.t1_gameScore = curAttributes.data.scores.gameScore[0];
		}

		if (
			curAttributes.data.scores.gameScore[1] != this.t2_gameScore &&
			!this.state.lastScore_t2 &&
			curAttributes.data.scores.gameScore[1] !== '' &&
			curAttributes.data.scores.gameScore[1]
		) {
			//logger.log('[MatchBox] componentDidUpdate match:%o team2 score changed:%o to %o', curAttributes.data.match_id, this.t2_gameScore,curAttributes.data.scores.gameScore[1] );
			this.setState({
				lastScore_t2: true,
				lastScore_t1: false,
			});
			if (curAttributes.data.scores.gameScore[1]) {
				this.t2_timer = setTimeout(() => this.setState({ lastScore_t2: false }), 5000);
			}
			this.t2_gameScore = curAttributes.data.scores.gameScore[1];
		}
	}

	getServeSpeed(data) {
		let { uom } = this.props;
		let textUnit = uom == 'M' ? ' KMH' : ' MPH';
		let serveSpeedArray = data ? data.split(',') : [null, null];
		let serveSpeed = uom == 'M' ? serveSpeedArray[0] : serveSpeedArray[1];
		return serveSpeed ? 'Serve Speed: ' + serveSpeed + textUnit : null;
	}

	onLinkClick(e, path) {
		//logger.log('[MatchBox] e:%o, path:%o', e.target, path);
		// e.preventDefault();

		if (values.webview) {
			let link = getAppLink(path, '');
			location.href = link.url;
		} else if (path) {
			if (this.props.windowSize == 'mobile') {
				this.props.hide();
			}
			this.props.navigate({ path: path });
		}
	}

	render() {
		let scoreData = this.props.attributes.data;
		let boxStyle = this.props.attributes.style ? this.props.attributes.style : '';

		//logger.log('[MatchBox] render - scoreData:%o this:%o', scoreData, this);

		if (scoreData.scores.sets) {
			//logger.log('[MatchBox] state:%o',this.state);
			let isSidepanel = this.props.attributes.isSidepanel;
			let event = this.props.attributes.event;
			let tableHeader = this.props.attributes.tableHeader;
			let showLinks = this.props.attributes.showLinks;

			let round = this.props.attributes.round;
			let { usePlayerImages } = this.props.attributes;
			let { suppressProfileLink } = this.props.attributes;
			scoreData.team1.won = scoreData.team1.won && scoreData.team1.won !== 'false' ? true : false;
			scoreData.team2.won = scoreData.team2.won && scoreData.team2.won !== 'false' ? true : false;

			let iconClasses_t1 = classNames(
				'crticon',
				(scoreData.server == 'A' || scoreData.server == 'B') &&
					!scoreData.team1.won &&
					!scoreData.team2.won &&
					scoreData.statusCode === 'A'
					? 'currentServer'
					: '',
				scoreData.team1.won ? 'winner' : ''
			);
			let iconClasses_t2 = classNames(
				'crticon',
				(scoreData.server == 'C' || scoreData.server == 'D') &&
					!scoreData.team1.won &&
					!scoreData.team2.won &&
					scoreData.statusCode === 'A'
					? 'currentServer'
					: '',
				scoreData.team2.won ? 'winner' : ''
			);

			let emptySets = [];
			for (var i = 0; i < 5 - scoreData.scores.sets.length && scoreData.scores.sets.length < 5; i++) {
				emptySets[i] = 5 - i;
			}

			let serveSpeed = this.getServeSpeed(scoreData.last_serve_speed);

			//stats level only set after match complete, so won't allow active pre/during match
			let active = scoreData.team1.idA && scoreData.team2.idA;
			//let slamtrackerActive = (active && scoreData.statsLevel !== null && scoreData.statsLevel !== 'N');
			let slamtrackerActive = active && scoreData.courtId;

			emptySets.reverse();
			//logger.log('[MatchBox] render - match:%o  state:%o',scoreData.match_id, this.state);

			let searchedPlayer = this.props.attributes.searchedPlayer;
			let completedCodes = ['D', 'E', 'F', 'G'];

			let matchBoxAriaLabel = '';
			if (scoreData?.status === 'Completed') {
				matchBoxAriaLabel = `On ${scoreData?.courtName}, ${scoreData?.eventName} ${scoreData?.roundName}`;
				let setsWon, winningTeam, defeatedTeam;
				if (scoreData?.team1?.won) {
					winningTeam = `${scoreData?.team1?.displayNameA} ${
						scoreData?.team1?.displayNameB ? 'and ' + scoreData?.team1?.displayNameB : ''
					}`;
					defeatedTeam = `${scoreData?.team2?.displayNameA} ${
						scoreData?.team2?.displayNameB ? 'and ' + scoreData?.team2?.displayNameB : ''
					}`;
					setsWon = `${scoreData?.team1?.totalSetsWon} sets to ${scoreData?.team2?.totalSetsWon}`;
				} else {
					winningTeam = `${scoreData?.team2?.displayNameA} ${
						scoreData?.team2?.displayNameB ? 'and ' + scoreData?.team2?.displayNameB : ''
					}`;
					defeatedTeam = `${scoreData?.team1?.displayNameA} ${
						scoreData?.team1?.displayNameB ? 'and ' + scoreData?.team1?.displayNameB : ''
					}`;
					setsWon = `${scoreData?.team2?.totalSetsWon} sets to ${scoreData?.team1?.totalSetsWon}`;
				}

				matchBoxAriaLabel = `${matchBoxAriaLabel} ${winningTeam} defeated ${defeatedTeam} ${setsWon}`;

				// “On Centre Court, Gentlemen’s Singles Second Round, Djokovic defeated Kokkinakis 3 sets to 0. The duration was 2 hours and the match status is completed.”
			} else {
				// check if there are scores, otherwise just say names
				matchBoxAriaLabel = `${
					scoreData?.courtName ? `On ${scoreData?.courtName},` : ''
				} ${scoreData?.eventName || scoreData?.shortEventName || ''} ${scoreData?.roundName || ''}`;

				if (scoreData?.scores?.sets?.length) {
					// report scores if they exist
					let ariaScores = scoreData?.scores?.sets?.map(val => {
						let playerNameA = `${scoreData?.team1?.displayNameA} ${
							scoreData?.team1?.displayNameB ? 'and ' + scoreData?.team1?.displayNameB : ''
						}`;
						let playerNameB = `${scoreData?.team2?.displayNameA} ${
							scoreData?.team2?.displayNameB ? 'and ' + scoreData?.team2?.displayNameB : ''
						}`;

						return `${playerNameA} ${val[0]?.scoreDisplay} games to ${playerNameB} ${val[1]?.scoreDisplay} games`; // in the x set
					});
					matchBoxAriaLabel = ariaScores ? `${matchBoxAriaLabel} the score is` : matchBoxAriaLabel;
					if (ariaScores) {
						ariaScores.map((item, index) => {
							matchBoxAriaLabel = `${matchBoxAriaLabel} ${item} in set ${index + 1},`;
						});
					}
				} else {
					// otherwise just report team names if no scores to report
					// get team1 displayNameA and displayNameB
					matchBoxAriaLabel = `${matchBoxAriaLabel} ${scoreData?.team1?.displayNameA} ${
						scoreData?.team1?.displayNameB ? `and ${scoreData?.team1?.displayNameB}` : ''
					}`;
					// get team2 displayNameA and displayNameB
					matchBoxAriaLabel = `${matchBoxAriaLabel} versus ${scoreData?.team2?.displayNameA} ${
						scoreData?.team2?.displayNameB ? `and ${scoreData?.team2?.displayNameB}` : ''
					}`;
					matchBoxAriaLabel = `${matchBoxAriaLabel}. There are no scores yet to report`;
				}
			}

			if (scoreData?.duration !== -1) {
				let matchDuration = scoreData?.duration?.split(':');
				let hourVerbiage = matchDuration[0] === 1 || matchDuration[0] === '1' ? 'hour' : 'hours';
				let durationVerbiage = scoreData?.status === 'Completed' ? 'was' : 'is';
				matchDuration = matchDuration
					? `The match duration ${durationVerbiage} ${matchDuration[0]} ${hourVerbiage} ${matchDuration[1]} minutes`
					: '';
				matchBoxAriaLabel = `${matchBoxAriaLabel} ${matchDuration}`;
			}
			let matchStatus = scoreData?.status ? ` and the match status is ${scoreData?.status}` : '';
			matchBoxAriaLabel = `${matchBoxAriaLabel} ${matchStatus}`;

			return this.props.MatchBoxConfig ? (
				<div
					className={usePlayerImages ? 'match-box-container usePlayerImages' : 'match-box-container'}
					ref={this.matchBoxRef}>
					{usePlayerImages ? (
						<div className="team-img-container">
							<PlayerImage
								attributes={{
									'player-image': this.props.MatchBoxConfig.playerImagePath.replace(
										'<playerid>',
										scoreData.team1.idA
									),
									style: scoreData.team1.idB ? 'xsmall' : 'small',
								}}
							/>
							{scoreData.team1.idB ? (
								<PlayerImage
									attributes={{
										'player-image': this.props.MatchBoxConfig.playerImagePath.replace(
											'<playerid>',
											scoreData.team1.idB
										),
										style: 'xsmall',
									}}
								/>
							) : null}
						</div>
					) : null}
					<div
						className={`match-box ${boxStyle} ${isSidepanel ? 'sidepanel-box' : ''}`}
						data-match={scoreData.match_id}
						data-event={scoreData.eventCode}
						data-court={scoreData.courtId}>
						<div className="match-location" tabIndex={0} aria-label={matchBoxAriaLabel} role="presentation">
							{tableHeader}
						</div>
						{op.get(scoreData, 'flags.upset', false) && (
							<div className="upset">
								<i className="wim-icon-info" />
								{completedCodes.includes(scoreData.statusCode) ? 'Upset' : 'Potential Upset'}
							</div>
						)}
						<div className={`match-status ${boxStyle}`}>
							<div className="green-border" />
							<div className="event-info">
								<span className="event-type">{event}</span>
								<span className="event-round">{round ? round : scoreData.roundName}</span>
							</div>
							<div
								className={`team-info team-one ${
									scoreData.team1.displayNameB != null && scoreData.team1.displayNameB != 'null'
										? 'doubles'
										: 'singles'
								}`}>
								<div className="team-name">
									<div
										onClick={
											!suppressProfileLink
												? e => {
														this.onLinkClick(
															e,
															!this.props.attributes.isArchive
																? `/en_GB/players/overview/${scoreData.team1.idA}.html`
																: `/en_GB/draws_archive/player_profile/${scoreData.team1.idA}.html`
														);
												  }
												: null
										}
										onKeyPress={
											!suppressProfileLink
												? e => {
														e.key === 'Enter'
															? this.onLinkClick(
																	e,
																	!this.props.attributes.isArchive
																		? `/en_GB/players/overview/${scoreData.team1.idA}.html`
																		: `/en_GB/draws_archive/player_profile/${scoreData.team1.idA}.html`
															  )
															: null;
												  }
												: null
										}
										tabIndex={0}
										role="link"
										aria-label={`${scoreData.team1.displayNameA} bio`}
										className={`member-one ${
											this.props.favourites.show &&
											this.props.favourites.players.indexOf(scoreData.team1.idA) !== -1
												? 'favorite'
												: ''
										} ${!suppressProfileLink ? 'name-link' : ''} ${
											searchedPlayer && searchedPlayer.indexOf(scoreData.team1.idA) !== -1
												? 'searchedPlayer'
												: ''
										}`}>
										<div className="flag">
											<img
												alt={scoreData.team1.nationA}
												src={this.props.MatchBoxConfig.flagImagePathSmall.replace(
													'<code>',
													!Array.isArray(scoreData.team1.nationA)
														? scoreData.team1.nationA
														: scoreData.team1.nationA[0]
												)}
											/>
										</div>
										<div className="name">{scoreData.team1.displayNameA}</div>
										<div className={`seed ${scoreData.team1.seed ? '' : 'hide'}`}>
											{scoreData.team1.seed}
										</div>
									</div>
									<div
										onClick={
											!suppressProfileLink
												? e => {
														this.onLinkClick(
															e,
															`/en_GB/players/overview/${scoreData.team1.idB}.html`
														);
												  }
												: null
										}
										onKeyPress={
											!suppressProfileLink
												? e => {
														e.key === 'Enter'
															? this.onLinkClick(
																	e,
																	`/en_GB/players/overview/${scoreData.team1.idB}.html`
															  )
															: null;
												  }
												: null
										}
										className={`member-two ${
											this.props.favourites.show &&
											this.props.favourites.players.indexOf(scoreData.team1.idB) !== -1
												? 'favorite'
												: ''
										} ${!suppressProfileLink ? 'name-link' : ''} ${
											searchedPlayer && searchedPlayer.indexOf(scoreData.team1.idB) !== -1
												? 'searchedPlayer'
												: ''
										}`}
										aria-label={`${scoreData.team1.displayNameB} bio`}
										role="link"
										tabIndex={0}>
										<div className="flag">
											<img
												alt={scoreData.team1.nationB}
												src={this.props.MatchBoxConfig.flagImagePathSmall.replace(
													'<code>',
													!Array.isArray(scoreData.team1.nationB)
														? scoreData.team1.nationB
														: scoreData.team1.nationB[0]
												)}
											/>
										</div>
										<div className="name">{scoreData.team1.displayNameB}</div>
										<div className={`seed ${scoreData.team1.seed ? '' : 'hide'}`}>
											{scoreData.team1.seed}
										</div>
									</div>
								</div>
								<div className={iconClasses_t1}>
									<i className="wim-icon-check" />
									<i className="wim-icon-ballSolid" />
								</div>
								<div className="match-results">
									<div className="results-wrapper">
										{/* <div className={iconClasses_t1}>
											<i className="wim-icon-check" />
											<i className="wim-icon-ballSolid" />
										</div> */}
										<div
											className={`pts${
												this.state.lastScore_t1 && scoreData.statusCode === 'A' ? ' latest' : ''
											}`}>
											{scoreData.scores.gameScore[0]}
										</div>
										{scoreData.scores.sets.map((d1, i) => {
											let setno = parseInt(i, 10) + 1;
											let currentScore = Array.isArray(d1)
												? d1[0].scoreDisplay
													? d1[0].scoreDisplay
													: ''
												: d1.team1.scoreDisplay !== 'null'
												? d1.team1.scoreDisplay
												: '';
											return (
												<div
													key={`t1_${i}_${scoreData.match_id}_${currentScore}`}
													className={`set set${setno}`}>
													<span className="scores">
														{currentScore}
														<span className="tiebreak">
															{Array.isArray(d1)
																? d1[0].tiebreakDisplay
																	? d1[0].tiebreakDisplay
																	: ''
																: d1.team1.tiebreakDisplay !== 'null'
																? d1.team1.tiebreakDisplay
																: ''}
														</span>
													</span>
												</div>
											);
										})}
										{emptySets.map((set, i) => {
											return (
												<div key={i} className={`set set${set}`}>
													<span className="scores">
														<span className="tiebreak" />
													</span>
												</div>
											);
										})}
									</div>
								</div>
							</div>
							<div className="results-info">
								<div className="results-info-pts">PTS</div>
								<div className="results-info-set">1</div>
								<div className="results-info-set">2</div>
								<div className="results-info-set">3</div>
								<div className="results-info-set">4</div>
								<div className="results-info-set">5</div>
							</div>
							<div
								className={`team-info team-two ${
									scoreData.team2.displayNameB != null && scoreData.team2.displayNameB != 'null'
										? 'doubles'
										: 'singles'
								}`}>
								<div className="team-name">
									<div
										onClick={
											!suppressProfileLink
												? e => {
														this.onLinkClick(
															e,
															!this.props.attributes.isArchive
																? `/en_GB/players/overview/${scoreData.team2.idA}.html`
																: `/en_GB/draws_archive/player_profile/${scoreData.team2.idA}.html`
														);
												  }
												: null
										}
										onKeyPress={
											!suppressProfileLink
												? e => {
														e.key === 'Enter'
															? this.onLinkClick(
																	e,
																	!this.props.attributes.isArchive
																		? `/en_GB/players/overview/${scoreData.team2.idA}.html`
																		: `/en_GB/draws_archive/player_profile/${scoreData.team2.idA}.html`
															  )
															: null;
												  }
												: null
										}
										className={`member-one ${
											this.props.favourites.show &&
											this.props.favourites.players.indexOf(scoreData.team2.idA) !== -1
												? 'favorite'
												: ''
										} ${!suppressProfileLink ? 'name-link' : ''}  ${
											searchedPlayer && searchedPlayer.indexOf(scoreData.team2.idA) !== -1
												? 'searchedPlayer'
												: ''
										}`}
										tabIndex={0}
										aria-label={`${scoreData.team2.displayNameA} bio`}
										role="link">
										<div className="flag">
											<img
												alt={scoreData.team2.nationA}
												src={this.props.MatchBoxConfig.flagImagePathSmall.replace(
													'<code>',
													!Array.isArray(scoreData.team2.nationA)
														? scoreData.team2.nationA
														: scoreData.team2.nationA[0]
												)}
											/>
										</div>
										<div className="name">{scoreData.team2.displayNameA}</div>
										<div className={`seed ${scoreData.team2.seed ? '' : 'hide'}`}>
											{scoreData.team2.seed}
										</div>
									</div>
									<div
										onClick={
											!suppressProfileLink
												? e => {
														this.onLinkClick(
															e,
															!this.props.attributes.isArchive
																? `/en_GB/players/overview/${scoreData.team2.idB}.html`
																: `/en_GB/draws_archive/player_profile/${scoreData.team2.idB}.html`
														);
												  }
												: null
										}
										onKeyPress={
											!suppressProfileLink
												? e => {
														e.key === 'Enter'
															? this.onLinkClick(
																	e,
																	!this.props.attributes.isArchive
																		? `/en_GB/players/overview/${scoreData.team2.idB}.html`
																		: `/en_GB/draws_archive/player_profile/${scoreData.team2.idB}.html`
															  )
															: null;
												  }
												: null
										}
										className={`member-two ${
											this.props.favourites.show &&
											this.props.favourites.players.indexOf(scoreData.team2.idB) !== -1
												? 'favorite'
												: ''
										} ${!suppressProfileLink ? 'name-link' : ''}  ${
											searchedPlayer && searchedPlayer.indexOf(scoreData.team2.idB) !== -1
												? 'searchedPlayer'
												: ''
										}`}
										aria-label={`${scoreData.team2.displayNameB} bio`}
										tabIndex={0}
										role="link">
										<div className="flag">
											<img
												alt={scoreData.team2.nationB}
												src={this.props.MatchBoxConfig.flagImagePathSmall.replace(
													'<code>',
													!Array.isArray(scoreData.team2.nationB)
														? scoreData.team2.nationB
														: scoreData.team2.nationB[0]
												)}
											/>
										</div>
										<div className="name">{scoreData.team2.displayNameB}</div>
										<div className={`seed ${scoreData.team2.seed ? '' : 'hide'}`}>
											{scoreData.team2.seed}
										</div>
									</div>
								</div>
								<div className={iconClasses_t2}>
									<i className="wim-icon-check" />
									<i className="wim-icon-ballSolid" />
								</div>
								<div className="match-results">
									<div className="results-wrapper">
										<div
											className={`pts${
												this.state.lastScore_t2 && scoreData.statusCode === 'A' ? ' latest' : ''
											}`}>
											{scoreData.scores.gameScore[1]}
										</div>
										{scoreData.scores.sets.map((d1, i) => {
											let setno = parseInt(i, 10) + 1;
											let currentScore = Array.isArray(d1)
												? d1[0].scoreDisplay
													? d1[0].scoreDisplay
													: ''
												: d1.team1.scoreDisplay !== 'null'
												? d1.team1.scoreDisplay
												: '';
											return (
												<div
													key={`t2_${i}_${scoreData.match_id}_${currentScore}`}
													className={`set set${setno}`}>
													<span className="scores">
														{Array.isArray(d1)
															? d1[1].scoreDisplay
																? d1[1].scoreDisplay
																: ''
															: d1.team2.scoreDisplay !== 'null'
															? d1.team2.scoreDisplay
															: ''}
														<span className="tiebreak">
															{Array.isArray(d1)
																? d1[1].tiebreakDisplay
																	? d1[1].tiebreakDisplay
																	: ''
																: d1.team2.tiebreakDisplay !== 'null'
																? d1.team2.tiebreakDisplay
																: ''}
														</span>
													</span>
												</div>
											);
										})}
										{emptySets.map((set, i) => {
											return (
												<div key={i} className={`set set${set}`}>
													<span className="scores">
														<span className="tiebreak" />
													</span>
												</div>
											);
										})}
									</div>
								</div>
							</div>
							<div className="event-notes">
								<span className="duration">
									{scoreData.duration != null && scoreData.duration != -1
										? `Duration: ${scoreData.duration}`
										: ''}
								</span>
								<span className="update">
									{scoreData.statusCode == 'A' && serveSpeed
										? serveSpeed
										: scoreData.statusCode != 'A'
										? scoreData.status
										: ''}
								</span>
							</div>
						</div>
						{showLinks ? (
							<div className="match-box-buttons">
								<SlamtrackerButton
									match_id={scoreData.match_id}
									slamtrackerActive={slamtrackerActive}
									statusCode={scoreData.statusCode}
									defaultTab={this.props?.attributes?.defaultTab}
								/>
							</div>
						) : null}
					</div>

					{usePlayerImages ? (
						<div className="team-img-container" tabIndex={-1} aria-hidden>
							<PlayerImage
								attributes={{
									'player-image': this.props.MatchBoxConfig.playerImagePath.replace(
										'<playerid>',
										scoreData.team2.idA
									),
									style: scoreData.team2.idB ? 'xsmall' : 'small',
								}}
							/>
							{scoreData.team2.idB ? (
								<PlayerImage
									attributes={{
										'player-image': this.props.MatchBoxConfig.playerImagePath.replace(
											'<playerid>',
											scoreData.team2.idB
										),
										style: 'xsmall',
									}}
								/>
							) : null}
						</div>
					) : null}
				</div>
			) : null;
		} else {
			return <div>No Match Data</div>;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(MatchBox);

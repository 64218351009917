import React, { Component, Fragment } from 'react';
import { RoleRegistrationInviteContext } from '../context';
import op from 'object-path';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { getJson } from 'appdir/components/general/Util/Services';
import { getRole } from 'appdir/components/general/Util/Role';
import MeasurementUtils from 'appdir/lib/analytics';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormCheckBox } from 'appdir/components/common-ui/FormFields';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

class Coach extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			confirmed: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.initialValues = {
			confirm: false,
		};

		this.addingRole = false;

		logger.log('[RoleRegistrationInvite -  Coach] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log(
			'[RoleRegistrationInvite -  Coach] componentDidMount - state:%o context:%o',
			this.state,
			this.context
		);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Role Registration Invite  Coach',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[RoleRegistrationInvite -  Coach] componentDidUpdate - state:%o context:%o',
			this.state,
			this.context
		);
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {
			confirm: yup
				.boolean()
				.required('Please confirm.')
				.oneOf([true], 'Please confirm.'),
		};

		return yup.object(schema);
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[RoleRegistrationInvite -  Coach] handleSubmit - state:%o', values);
		this.addRole();
	}

	autoSubmit() {
		if (!this.addingRole) {
			this.addingRole = true;
			this.addRole();
		}
	}

	addRole() {
		if (this.context.addRole) {
			logger.log('[RoleRegistrationInvite -  Coach] addRole');
			this.context.addRole(null, null, false);
		}
	}

	onConfirm() {
		this.setState({ confirmed: true });
	}

	renderConfirmText() {
		return (
			<Fragment>
				<div className="two-col margin question">
					<div>Would you like to add Coach access</div>
					<div className="highlight">
						To <MyWimbledonTitle short="true" /> account:
					</div>
					<div className="email">
						<strong>{this.context.userEmail}</strong>
					</div>
				</div>
			</Fragment>
		);
	}

	renderSubmitForm() {
		//logger.log('[RoleRegistrationInvite -  Coach] renderSubmitForm - existing:%o', existingName);

		let validationSchema = this.getSchema();
		return (
			<Fragment>
				<Formik
					initialValues={this.initialValues}
					validationSchema={validationSchema}
					onSubmit={this.handleSubmit}
					enableReinitialize={true}>
					{formProps => {
						let allowSubmit = formProps.values.confirm;
						return (
							<Fragment>
								<div className="two-col margin center">
									<FormCheckBox name="confirm" id="confirm">
										I confirm I want to add Coach access to <MyWimbledonTitle short="true" />{' '}
										account <br />
										<strong>{this.context.userEmail}</strong>.
									</FormCheckBox>
								</div>
								<div className="four-col body">
									<Button
										className={`btn solid ${!allowSubmit ? `disabled` : ``}`}
										onClick={formProps.submitForm}>
										CONFIRM ACCESS
									</Button>
								</div>
							</Fragment>
						);
					}}
				</Formik>
			</Fragment>
		);
	}

	getInvite() {
		return (
			<Fragment>
				<div className="two-col margin">
					<h4>Coach REGISTRATION</h4>
				</div>
				{this.context.status == 'active' ? (
					<Fragment>
						{this.renderConfirmText()}
						<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
						{this.renderSubmitForm()}
					</Fragment>
				) : null}
			</Fragment>
		);
	}

	getComplete() {
		return (
			<Fragment>
				<div className="two-col margin">
					<h4>REGISTRATION SUCCESSFUL</h4>
				</div>
				<div className="two-col">
					<p>You have now successfully registered as a Coach.</p>
				</div>
				<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
				<div className="two-col margin">
					Please visit myWimbledon to access your services and benefits, including Coach.
				</div>
				<div className="two-col margin center">
					<Button className={`btn solid mywim`} to="/en_GB/mywimbledon/services.html">
						<i className="wim-icon-myw my" aria-label="my wimbledon" />
						WIMBLEDON
					</Button>
				</div>
			</Fragment>
		);
	}

	render() {
		logger.log('[RoleRegistrationInvite -  Coach] render - state:%o context:%o', this.state, this.context);

		return (
			<Fragment>
				{this.context.complete ? (
					<Fragment>{this.getComplete()}</Fragment>
				) : this.context.status == 'completed' ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">Registration links may only be used once.</div>
						<div className="two-col body">
							Please <WimLink to="/en_GB/contact/index.html">contact us</WimLink> for additional
							questions.
						</div>
					</Fragment>
				) : !this.context.isValid ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">
							Unfortunately your registration code is invalid or has expired.
							<br />
							<br />
							Please try again or contact your AELTC representative to request a new link.
						</div>
					</Fragment>
				) : (
					<Fragment>{this.getInvite()}</Fragment>
				)}
			</Fragment>
		);
	}
}
Coach.contextType = RoleRegistrationInviteContext;
export default Coach;

import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { FormCheckBox } from 'appdir/components/common-ui/FormFields';
import * as yup from 'yup';

import op from 'object-path';
import Button from 'appdir/components/common-ui/Button';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

const mapStateToProps = (state, props) => {
	return {
		gigya: state['Gigya'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	setAccountInfo: (source, data, modal) => dispatch(deps.actions.Gigya.setAccountInfoProm(source, data, modal)),
});

class USAHillRegistration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.handleSubmit = this.handleSubmit.bind(this);

		this.initialValues = {
			checkbox1: false,
			liveMusicConcert: false,
			ladiesSinglesFinalScreening: false,
			gentlemensSinglesFinalScreening: false,



		};
		//logger.log('[RegisterButton-USAHillRegistration] constructor - state:%o context:%o', this.state, this.context);
	}
	componentDidMount() {
		//logger.log('[RegisterButton-USAHillRegistration] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log("[RegisterButton-USAHillRegistration] componentDidUpdate - state:%o", this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[RegisterButton-USAHillRegistration] componentWillReceiveProps - nextProps:%o', nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps.state,
			};
		});
	}

	handleSubmit(formValues) {
		let update = {};
		op.set(update, 'subscriptions.usaHillRegistration.email.isSubscribed', true);
		// check if tags exist, push to array if exists
		let tags = this.props.gigya?.user?.subscriptions?.usaHillRegistration?.email?.tags || [];
		tags.push(`2024_Terms_and_Conditions:${formValues?.checkbox1}`);
		tags.push(`20240712:${formValues?.liveMusicConcert}`);
		tags.push(`20240713:${formValues?.ladiesSinglesFinalScreening}`);
		tags.push(`20240714:${formValues?.gentlemensSinglesFinalScreening}`);

		
		op.set(update, 'subscriptions.usaHillRegistration.email.tags', tags);

		this.props
			.setAccountInfo('usahill_register', update, true)
			.then(response => {
				MeasurementUtils.dispatchMeasurementCall('usaHillSubscribeSuccess', {});
			})
			.catch(error => {});
	}

	clickLogin(e) {
		e.preventDefault();
		this.props.openScreen({
			screen: 'login_screen',
			redirect: false,
			showSuccess: false,
			cid: 'debenture_prospect',
		});
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {
			checkbox1: yup
				.boolean()
				.required('Please read and accept the Terms and Conditions.')
				.oneOf([true], 'Please read and accept the Terms and Conditions.'),
		};

		return yup.object(schema);
	}

	getCompleteForm() {
		let validationSchema = this.getSchema();

		return (
			<Formik
				initialValues={this.initialValues}
				validationSchema={validationSchema}
				onSubmit={this.handleSubmit}
				enableReinitialize={true}>
				{formProps => {
					let allowSubmit = formProps.values.checkbox1;
					return (
						<Fragment>
							<div className='hill-option-container'>
								<FormCheckBox name="liveMusicConcert" id="liveMusicConcert">
								<div><strong>Friday 12 July</strong> - Live music concert - 6:00pm</div>

								</FormCheckBox>
							</div>
							<div className='hill-option-container'>
								<FormCheckBox name="ladiesSinglesFinalScreening" id="ladiesSinglesFinalScreening">
									<div><strong>Saturday 13 July</strong> - Ladies' Singles Final live screening - 8:00am</div>
								</FormCheckBox>
							</div>
							<div className='hill-option-container'>
								<FormCheckBox name="gentlemensSinglesFinalScreening" id="gentlemensSinglesFinalScreening">
									<div><strong>Sunday 14 July</strong> - Gentlemen's Singles Final live screening - 8:00am</div>
								</FormCheckBox>
							</div>
							<div style={{ marginTop: '16px' }}>
								<FormCheckBox name="checkbox1" id="checkbox1">
									I have read and agree to the{' '}
									<WimLink to={'/pdf/TheHillNYC_TsandCs2024.pdf'} external={true}>
										AELTC Terms and Conditions
									</WimLink>
									.
								</FormCheckBox>
							</div>
							<div>
								<Button
									className={`btn solid ${!allowSubmit ? `disabled` : ``}`}
									onClick={formProps.submitForm}>
									ENTER BALLOT
								</Button>
							</div>
						</Fragment>
					);
				}}
			</Formik>
		);
	}

	render() {
		logger.log('[RegisterButton-USAHillRegistration] render - state:%o', this.state);

		let subscribed = op.get(this.state, 'user.subscriptions.usaHillRegistration.email.isSubscribed', false);

		return (
			<div className={`${this.state.style} center`}>
				{!this.state.loggedIn && !this.state.gigyaLoaded ? (
					<LoadingIndicator />
				) : !this.state.loggedIn && this.state.gigyaLoaded ? (
					<h3>
						Please{' '}
						<a href="" onClick={e => this.clickLogin(e)}>
							Log in or Create a <MyWimbledonTitle /> account
						</a>{' '}
						to select your day preference and enter the ballot.
					</h3>
				) : !subscribed ? (
					this.getCompleteForm()
				) : (
					<Fragment>
						<h3 className="center">Thank you for your entry.</h3>
						{/** 
							<WimRedirect to={completePath} />
						*/}
					</Fragment>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(USAHillRegistration);

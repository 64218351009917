import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Skeleton from 'react-loading-skeleton';
import PlayerImage from 'appdir/components/content/PlayerImage';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';

const mapStateToProps = (state, props) => {
	return Object.assign(
		{},
		{ favourites: state['Controller'].favourites, PlayerGeneralInfo: state['PlayerGeneralInfo'] },
		props
	);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerGeneralInfo.mount()),
});

class PlayerGeneralInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	componentDidMount() {
		this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	render() {
		logger.info('[PlayerGeneralInfo] render - state:%o', this.state);

		if (this.state.player && this.state.player.playerObj && this.state.PlayerGeneralInfo.playerProfileImagePath) {
			let playerInfo = this.state.player.playerObj;

			let flagImagePathLarge = this.state.PlayerGeneralInfo.flagImagePathLarge;
			let playerProfileImagePath = this.state.PlayerGeneralInfo.playerProfileImagePath;

			return (
				<div className="two-col inline player-gen-info">
					<div className="general-info-item">
						<FavouriteStar
							id={playerInfo.id}
							type="players"
							unFavColor="green"
							favColor="yellow"
							alwaysShow={true}
						/>
						<PlayerImage
							attributes={{
								'player-image': playerProfileImagePath.replace('<playerid>', playerInfo.id),
								style: 'regular',
							}}
						/>
						<div className="mobile">
							{playerInfo.rank.current_singles ? (
								<div className="ranking">
									<div className="value">{playerInfo.rank.current_singles}</div>
									<div className="field">Singles Rank</div>
								</div>
							) : null}
							{playerInfo.rank.current_doubles ? (
								<div className="ranking">
									<div className="value">{playerInfo.rank.current_doubles}</div>
									<div className="field">Doubles Rank</div>
								</div>
							) : null}
						</div>
					</div>

					<div className="general-info-item">
						<div className="player-flag mobile-hide">
							<img
								src={
									'code' in playerInfo.nation && this.state.PlayerGeneralInfo.flagImagePathLarge ? (
										this.state.PlayerGeneralInfo.flagImagePathLarge.replace(
											'<code>',
											playerInfo.nation.code
										)
									) : (
										<Skeleton />
									)
								}
							/>
						</div>
						<div className="player-name mobile-hide">
							{playerInfo.first_name} {playerInfo.last_name}
						</div>
						<div className="general-info">
							<div className="general-info-line">
								<div className="general-info-field">Country:</div>{' '}
								<div className="general-info-value">{playerInfo.nation.country}</div>
							</div>
							<div className="general-info-line">
								<div className="general-info-field">Age:</div>{' '}
								<div className="general-info-value">
									{'date' in playerInfo.birth
										? playerInfo.birth.date
											? moment().diff(moment(playerInfo.birth.date, 'DD MMM YYYY'), 'years')
											: 'N/A'
										: 'N/A'}
								</div>
							</div>
							<div className="general-info-line">
								<div className="general-info-field">Birth Date:</div>{' '}
								<div className="general-info-value">{playerInfo.birth.date || 'N/A'}</div>
							</div>
							<div className="general-info-line">
								<div className="general-info-field">Birth Place:</div>{' '}
								<div className="general-info-value">{playerInfo.birth.place || 'N/A'}</div>
							</div>
							{/* <div className="general-info-line">
								<div className="general-info-field">Residence:</div> <div className="general-info-value">{playerInfo.residence || 'N/A'}</div>
							</div> */}
							<div className="general-info-line">
								<div className="general-info-field">Height:</div>{' '}
								<div className="general-info-value">
									{playerInfo.height.imperial
										? `${playerInfo.height.imperial} (${playerInfo.height.metric})`
										: 'N/A'}
								</div>
							</div>
							<div className="general-info-line">
								<div className="general-info-field">Weight:</div>{' '}
								<div className="general-info-value">
									{playerInfo.weight.imperial
										? `${playerInfo.weight.imperial} (${playerInfo.weight.metric})`
										: 'N/A'}
								</div>
							</div>
							<div className="general-info-line">
								<div className="general-info-field">Plays:</div>{' '}
								<div className="general-info-value">{playerInfo.plays || 'N/A'}</div>
							</div>
							<span className="large">
								{playerInfo.rank.current_singles ? (
									<div className="ranking">
										<div className="value">{playerInfo.rank.current_singles}</div>
										<div className="field">Singles Rank</div>
									</div>
								) : null}
								{playerInfo.rank.current_doubles ? (
									<div className="ranking">
										<div className="value">{playerInfo.rank.current_doubles}</div>
										<div className="field">Doubles Rank</div>
									</div>
								) : null}
							</span>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayerGeneralInfo);

import React from 'react';
import { Formik, Form } from 'formik';
import { FormInput, FormEmail, FormCheckBox, FormSelect } from 'appdir/components/common-ui/FormFields';

const SVG = ({ data }) => {
	return (
		<div className="content-main column-layout news article ">
			<div className="two-col margin">
				<svg width="80px" height="73px" viewBox="0 0 80 73" version="1.1" class="service-icon-test">
					<title>Player home icon</title>
					<g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g
							transform="translate(-487.000000, -159.000000)"
							id="Player-Information-Copy-5"
							stroke="#00703C"
							stroke-width="1.5">
							<g transform="translate(453.000000, 161.499972)">
								<g id="Player-home-icon" transform="translate(35.903846, 0.218108)">
									<g id="Ball" transform="translate(23.131543, 26.298451)">
										<circle id="Oval" cx="15.3585781" cy="15.3585781" r="15.3585781"></circle>
										<path
											d="M1.5627862,16.3846573 C10.2562865,13.721499 15.552154,8.8705945 17.4503889,1.83194385"
											id="Path-4"
											stroke-linecap="round"
											transform="translate(9.506588, 9.108301) rotate(-2.000000) translate(-9.506588, -9.108301) "></path>
										<path
											d="M13.8259041,29.2582382 C22.5194044,26.5950799 27.8152719,21.7441754 29.7135068,14.7055248"
											id="Path-4"
											stroke-linecap="round"
											transform="translate(21.769705, 21.981882) rotate(-180.000000) translate(-21.769705, -21.981882) "></path>
									</g>
									<g id="Home" transform="translate(0.500000, 0.000000)">
										<polyline
											id="Path-3"
											points="10.1372898 33.2180727 10.1372898 68.7498411 66.9414761 68.7498411 66.9414761 33.1715386"></polyline>
										<polyline
											id="Path-8"
											points="-2.84217094e-14 38.6294464 38.1895349 2.84217094e-14 76.233328 38.5280588"></polyline>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</div>
	);
};

export default SVG;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
/**
 * -----------------------------------------------------------------------------
 * React Component: SelectMenu
 * -----------------------------------------------------------------------------
 */

class SelectMenu extends Component {
	constructor(props) {
		super(props);

		logger.log('[SelectMenu] constructor - props:%o', props);
		let selected = props.selected;

		if (!selected) {
			console.log(props);
			// selected = props.attributes.options[0].value;
		}
		//logger.log('[SelectMenu] constructor selected:%o', selected);

		this.state = {
			...this.props,
			selected: selected,
		};
		this.handleClickOutside = this.handleClickOutside.bind(this);
		// logger.log('[SelectMenu] constructor state:%o', this.state);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.closeDropDown();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[SelectMenu] componentWillReceiveProps - nextProps:%o', nextProps);

		let selected = nextProps.selected;
		if (!selected) {
			selected = nextProps.attributes.options[0].value;
		}
		// logger.log('[SelectMenu] componentWillReceiveProps - selected:%o', selected);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				selected: selected,
			};
		});
	}

	getName(value) {
		let name = '';

		this.state.attributes.options.forEach(item => {
			if (item.value == value) {
				//logger.log('[SelectMenu] getName - item:%o value:%o name:%o', item, value, item.name);
				name = item.name;
			}
		});

		return name;
	}

	showMenu() {
		if (!this.state.disabled) {
			if (!this.state.show) {
				this.props.onOpen(this.props.filterName || this.state.name);

				MeasurementUtils.dispatchMeasurementCall('filterOpen');
			} else {
				// clear open value when show is false to avoid opening the dropdown on other state update
				this.props.onOpen('');

				MeasurementUtils.dispatchMeasurementCall('filterClose');
			}

			this.setState({
				show: !this.state.show,
			});
		}
	}

	setSelected(selected, name) {
		logger.log('[SelectMenu] setSelected:%o, name:%o', selected, name);
		this.setState({
			selected: selected,
			name: name,
			show: false,
		});
		this.props.onSelected(selected, name);
	}

	closeDropDown(length, value) {
		// logger.log('[SelectMenu] - closeDropDown options-length:%o,value:%o', length, value);
		if (value + 1 >= length || (!length && !value)) {
			this.setState({
				show: false,
			});
			if (this.props.onClose) {
				this.props.onClose();
			}
		}
	}

	render() {
		logger.log('[SelectMenu] render - state:%o', this.state);
		let arrow = ' ▾';
		let { attributes } = this.state;
		let title = this.getName(this.state.selected);
		let optionsLength = attributes.options.length;
		let pdfPath = this.props.pdfPath;

		return (
			<div className="select-menu" ref={node => (this.wrapperRef = node)}>
				<button
					className="title"
					onClick={(evt) => {evt.preventDefault(); this.showMenu()}}
					role="listbox"
					aria-label={`${title} is currently selected`}

					// onKeyPress={() => (event.key === 'Enter' ? this.showMenu() : null)}
				>
					<span className="menu-text" ref={this.props.optionRef}>
						{title}
					</span>
					<span className="menu-arrow">{arrow}</span>
				</button>
				<div className={`animation-container${this.state.show ? ' show' : ''}`}>
					<div className={`select-menu-dropdown ${this.state.show ? 'show' : ''}`}>
						{attributes.options.map(({ name, value, type }, index) => {
							//logger.log('[SelectMenu] display options map name:%o value:%o', name, value);
							if (value && value != 'section') {
								if (type && type == 'pdf') {
									return (
										<WimLink
											// to={pdfPath.replace('<eventId>', value)}
											// external={true}
											key={`${name}-${index}`}>
											<button
												className={'option pdf'}
												onClick={() => this.setSelected(value, name)}
												onBlur={() => this.closeDropDown(optionsLength, index)}>
												<span className="option-name">
													{name}
													<i className="wim-icon-pdf" />
												</span>
											</button>
										</WimLink>
									);
								} else {
									return (
										<button
											key={`${name}-${index}`}
											className={this.state.selected === value ? 'option clicked' : 'option'}
											onClick={() => this.setSelected(value, name)}
											type="button"
											onBlur={() => this.closeDropDown(optionsLength, index)}>
											<i className="wim-icon-check" />
											<span className="option-name">{name}</span>
										</button>
									);
								}
							} else {
								return (
									<div className="section" key={name}>
										{name}
									</div>
								);
							}
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default SelectMenu;

import React, { useState } from 'react';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: System Error
 * -----------------------------------------------------------------------------
 */

const SystemErrorContent = props => {
	const { data } = props;
	const [detailsOpen, setDetailsOpen] = useState(false);
	logger.log('[SystemErrorContent] - props:%o', props);

	return (
		<div className="four-col">
			<div className="system-error-wrapper">
				<section>
					<h2>Issue processing request</h2>
					<p>
						There was an issue processing your request. Please try again later.
						<br />
						If you require assistance please&nbsp;
						<WimLink to={'/en_GB/contact/index.html'} style="action-link">
							contact us
						</WimLink>
						.
					</p>
				</section>
				<section>
					<div>
						<h3 onClick={() => setDetailsOpen(!detailsOpen)}>
							Details <i className={`wim-icon-triangle-${detailsOpen ? 'up' : 'down'}`} />
						</h3>
						<section className={`error-section ${detailsOpen ? 'open' : ''}`}>
							<div className="label">
								Page: <span className="value">{data?.route}</span>
							</div>
							<div className="label">
								Name: <span className="value">{data?.error?.name}</span>
							</div>
							<div className="label">
								Message: <span className="value">{data?.error?.message}</span>
							</div>
							<div className="label">
								Component:{' '}
								<span className="value">{'' + data?.info?.componentStack.split('(')[0]}</span>
							</div>
						</section>
					</div>
				</section>
			</div>
		</div>
	);
};

export default SystemErrorContent;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

/* page elements */
import SlamtrackerContent from 'components/pages/Slamtracker/SlamtrackerContent';
import PageHeader from 'appdir/components/general/PageHeader';

/**
 * -----------------------------------------------------------------------------
 * React Component: SlamtrackerWebview
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Slamtracker'],
		windowSize: state['PageHeader'].windowSize,
		uom: state['Controller'].userPreferences['speed'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	unmount: () => dispatch(deps.actions.Slamtracker.unmount()),
	setScoreManagerStatus: data => dispatch(deps.actions.ScoreManager.setScoreManagerStatus(data)),
	clearStatMatch: () => dispatch(deps.actions.ScoreManager.clearStatMatch()),
});

class SlamtrackerWebview extends Component {
	constructor(props) {
		super(props);
		//logger.log('[SlamtrackerWebview] constructor props:%o', props);
	}

	componentWillUnmount() {
		// i don't want to do this if we are staying on slamtracker page
		this.props.setScoreManagerStatus({ slamtracker: false });
		//this.props.unmount();
		this.props.clearStatMatch();
	}

	render() {
		logger.log('[SlamtrackerWebview] render - this:%o', this);

		let uom = this.props['uom'] == 'M' ? 'metric' : 'imperial';

		return (
			<>
				<PageHeader attributes={{ isWebview: true }} />
				<section
					className={`wrapper scorespage slamtracker webview ${uom} ${this.props.windowSize == 'mobile'}`}>
					<div className="scores-background" />
					<SlamtrackerContent matchId={this.props.match.params.matchId} />
				</section>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamtrackerWebview);

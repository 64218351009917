import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: () => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Country Leaderboard',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const pathObj = {
				countryScoreBoard: Config.scoringData.countryScoreboard,
				flagImagePath: Config.otherData.flagImagePathLarge,
			};
			dispatch({
				type: deps.actionTypes.COUNTRYSTATISTICSPAGE_LOAD_COMPLETE,
				data: pathObj,
			});
			// dispatch({
			//     type: deps.actionTypes.COUNTRYSTATISTICSPAGE_LOAD_COMPLETE,
			//     data: {countryScoreBoard: Config.scoringData.countryScoreboard,flagImagePath = Config.otherData.flagImagePathLarge}
			// });
		});
	},
};

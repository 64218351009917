import { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';

// Can be passed a responses object that can contain query keys with successText as well as errorText
// The confirmation modal will trigger the correct error/success text by the invocation of the queryKey
// During invokeQuery or invokeAll
const useAxios = (responses = {}) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [response, setResponse] = useState(null);
	const [statusCode, setStatusCode] = useState(null);
	const [queryKey, setQueryKey] = useState('');
	const dispatch = useDispatch();

	// Just a string builder function to build custom urlParams
	const createUrlParameters = options => {
		let queryParams = '';
		options &&
			Object?.keys(options).map(key => {
				if (options[key]) queryParams += '&' + key + '=' + options[key];
			});
		return '?'.concat(queryParams.substring(1));
	};

	// Main method of making requests in members section. Most of the time headers wont be passed but I left
	// it as an option just in case there was ever a header needed other than the JWT Auth
	const invokeRequest = (key, method, headers, url, data, urlSlug = '', callback = data => data) => {
		setQueryKey(key);
		setError(null);

		if (key !== 'cancel-booking' && key !== 'cancel-initial-booking' && key !== 'reset-response') setLoading(true);

		dispatch(deps.actions.Gigya.getJWT()).then(res => {
			axios
				.request({
					method,
					url: url?.concat(urlSlug),
					headers: {
						Authorization: 'Bearer ' + res.jwt.id_token,
					},
					data,
				})
				.then(res => {
					callback();
					if (
						(res?.data?.response?.statusCode && res?.data?.response?.statusCode !== 200) ||
						(res?.data?.response?.code && res?.data?.response?.code !== 200) ||
						(res?.data?.response?.status && res?.data?.response?.status !== 200)
					) {
						// check if has 200 resp in parent response, but error status within the main response
						setResponse(res.data);
						let code =
							res?.data?.response?.statusCode || res?.data?.response?.code || res?.data?.response?.status;
						setStatusCode(code);
						dispatch(
							deps.actions.ConfirmationModal.toggleModal({
								showModal: true,
								showCloseBtn: true,
								type: 'error',
								title: responses[key]?.title || 'Error',
								message:
									res?.data?.response?.msg ||
									res?.data?.response?.message ||
									responses[key]?.errorText,
								useTimer: true,
							})
						);
					} else {
						if (res?.data) {
							setResponse(res?.data);
							setStatusCode(res?.status);
							responses?.[key]?.hasOwnProperty('successText') &&
								dispatch(
									deps.actions.ConfirmationModal.toggleModal({
										showModal: true,
										showCloseBtn: true,
										type: 'success',
										title: responses[key].title,
										message: responses[key].successText,
										useTimer: key !== 'cancel-booking',
									})
								);
						} else {
							responses?.[key]?.hasOwnProperty('errorText') &&
								dispatch(
									deps.actions.ConfirmationModal.toggleModal({
										showModal: true,
										showCloseBtn: true,
										type: 'error',
										title: responses[key].title,
										message: err || err?.message || err?.response?.msg || responses[key].errorText,
										useTimer: true,
									})
								);
						}
					}
				})
				.catch(err => {
					callback();
					if (key !== 'reset-response') {
						dispatch(
							deps.actions.ConfirmationModal.toggleModal({
								showModal: true,
								showCloseBtn: true,
								type: 'error',
								title: responses?.[key]?.hasOwnProperty('errorText') ? responses[key].title : 'Error',
								message: err || err?.message || err?.response?.msg || responses[key].errorText,
								useTimer: true,
							})
						);
					}
				})
				.finally(() => {
					if (key !== 'reset-response') setLoading(false);
				});
		});
	};

	// A couple of the functions calls multiple endpoints at same time, this just makes it slightly easier.
	// I only considered GET requests here, did not see any reason we would make multiple POSTS simultaneously
	const invokeAll = (endpoints, queryKey) => {
		setLoading(true);
		setQueryKey(queryKey);
		dispatch(deps.actions.Gigya.getJWT()).then(res => {
			axios
				.all(
					endpoints.map(endpoint =>
						axios.get(endpoint, {
							headers: {
								Authorization: 'Bearer ' + res.jwt.id_token,
							},
						})
					)
				)
				.then(setResponse)
				.catch(setError)
				.finally(() => setLoading(false));
		});
	};

	return {
		response,
		loading,
		error,
		invokeRequest,
		createUrlParameters,
		setLoading,
		setResponse,
		statusCode,
		invokeAll,
		queryKey,
	};
};

export default useAxios;

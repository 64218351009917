export const findCountry = (code => {
	let res = countryList.filter(c => {
		return c.alpha2code === code || c.alpha3code === code
	});
	
	return res.length > 0 ? res[0] : [];
})

export const findPlayerCountry = (code => {
	let found = []
	let res = countryList.filter(c => {
		return c.alpha2code === code || c.alpha3code === code
	});

	if (res.length > 1) {
		found = res.filter(cntry => {
			return cntry.source == 'player'
		})
	}
	else {
		found = res;
	}
	
	return found[0];
})


const countryCsv = `
alpha2code|alpha3code|number|wimName|isoName|source|outline
AD|AND|20|Andorra|Andorra|iso
AE|ARE|784|United Arab Emirates|United Arab Emirates (the)|iso
AF|AFG|4|Afghanistan|Afghanistan|iso
AG|ATG|28|Antigua And Barbuda|Antigua and Barbuda|iso
AI|AIA|660|Anguilla|Anguilla|iso
AL|ALB|8|Albania|Albania|iso
AM|ARM|51|Armenia|Armenia|iso
AN|AHO|530|Netherland Antilles|Netherland Antilles|player
AO|AGO|24|Angola|Angola|iso
AO|ANG|24|Angola|Angola|player
AQ|ATA|10|Antarctica|Antarctica|iso
AR|ARG|32|Argentina|Argentina|iso
AS|ASM|16|American Samoa|American Samoa|iso
AT|AUT|40|Austria|Austria|iso
AU|AUS|36|Australia|Australia|iso
AW|ABW|533|Aruba|Aruba|iso
AW|ARU|533|Aruba|Aruba|player
AX|ALA|248|Aland Islands|Åland Islands|iso
AZ|AZE|31|Azerbaijan|Azerbaijan|iso
BA|BIH|70|Bosnia And Herzegowina|Bosnia and Herzegovina|iso
BB|BRB|52|Barbados|Barbados|iso
BB|BAR|52|Barbados|Barbados|player
BD|BGD|50|Bangladesh|Bangladesh|iso
BE|BEL|56|Belgium|Belgium|iso
BF|BFA|854|Burkina Faso|Burkina Faso|iso
BG|BGR|100|Bulgaria|Bulgaria|iso
BG|BUL|100|Bulgaria|Bulgaria|player
BH|BHR|48|Bahrain|Bahrain|iso
BI|BDI|108|Burundi|Burundi|iso
BJ|BEN|204|Benin|Benin|iso
BL|BLM|652|Saint Barthélemy|Saint Barthélemy|iso
BM|BMU|60|Bermuda|Bermuda|iso
BN|BRN|96|Brunei Darussalam|Brunei Darussalam|iso
BO|BOL|68|Bolivia|Bolivia (Plurinational State of)|iso
BQ|BES|535|Bonaire, Sint Eustatius and Saba|Bonaire, Sint Eustatius and Saba|iso
BR|BRA|76|Brazil|Brazil|iso
BS|BHS|44|Bahamas|Bahamas (the)|iso
BS|BAH|44|Bahamas|Bahamas (the)|player
BT|BTN|64|Bhutan|Bhutan|iso
BV|BVT|74|Bouvet Island|Bouvet Island|iso
BW|BWA|72|Botswana|Botswana|iso
BW|BOT|72|Botswana|Botswana|player
BY|BLR|112|Belarus|Belarus|iso|false
BY|BLR|112||Belarus|player|false
BZ|BLZ|84|Belize|Belize|iso
CA|CAN|124|Canada|Canada|iso
CC|CCK|166|Cocos (Keeling) Islands|Cocos (Keeling) Islands (the)|iso
CD|COD|180|Congo, The Democratic Republic Of The|Congo (the Democratic Republic of the)|iso
CF|CAF|140|Central African Republic|Central African Republic (the)|iso
CG|COG|178|Congo|Congo (the)|iso
CH|CHE|756|Switzerland|Switzerland|iso
CH|SUI|756|Switzerland|Switzerland|player
CI|CIV|384|Ivory Coast|Côte d'Ivoire|iso
CK|COK|184|Cook Islands|Cook Islands (the)|iso
CL|CHL|152|Chile|Chile|iso
CL|CHI|152|Chile|Chile|player
CM|CMR|120|Cameroon|Cameroon|iso
CN|CHN|156|China|China|iso
CO|COL|170|Colombia|Colombia|iso
CR|CRI|188|Costa Rica|Costa Rica|iso
CR|CRC|188|Costa Rica|Costa Rica|player
CS|SCG|891|Serbia and Montenegro|Serbia and Montenegro|player
CU|CUB|192|Cuba|Cuba|iso
CV|CPV|132|Cape Verde|Cabo Verde|iso
CW|CUW|531|Curaçao|Curaçao|iso
CX|CXR|162|Christmas Island|Christmas Island|iso
CY|CYP|196|Cyprus|Cyprus|iso
CZ|CZE|203|Czech Republic|Czechia|iso
DE|DEU|276|Germany|Germany|iso
DE|GER|276|Germany|Germany|player
DJ|DJI|262|Djibouti|Djibouti|iso
DK|DNK|208|Denmark|Denmark|iso
DK|DEN|208|Denmark|Denmark|player
DM|DMA|212|Dominica|Dominica|iso
DO|DOM|214|Dominican Republic|Dominican Republic (the)|iso
DZ|DZA|12|Algeria|Algeria|iso
DZ|ALG|12|Algeria|Algeria|player
EC|ECU|218|Ecuador|Ecuador|iso
EE|EST|233|Estonia|Estonia|iso
EG|EGY|818|Egypt|Egypt|iso
EH|ESH|732|Western Sahara|Western Sahara*|iso
ER|ERI|232|Eritrea|Eritrea|iso
ES|ESP|724|Spain|Spain|iso
ET|ETH|231|Ethiopia|Ethiopia|iso
FI|FIN|246|Finland|Finland|iso
FJ|FJI|242|Fiji|Fiji|iso
FK|FLK|238|Falkland Islands (Malvinas)|Falkland Islands (the) [Malvinas]|iso
FM|FSM|583|Micronesia Federated States Of|Micronesia (Federated States of)|iso
FO|FRO|234|Faroe Islands|Faroe Islands (the)|iso
FR|FRA|250|France|France|iso
GA|GAB|266|Gabon|Gabon|iso
GB|GBR|826|United Kingdom|United Kingdom of Great Britain and Northern Ireland (the)|iso
GD|GRD|308|Grenada|Grenada|iso
GE|GEO|268|Georgia|Georgia|iso
GF|GUF|254|French Guiana|French Guiana|iso
GG|GGY|831|Guernsey|Guernsey|iso
GH|GHA|288|Ghana|Ghana|iso
GI|GIB|292|Gibraltar|Gibraltar|iso
GL|GRL|304|Greenland|Greenland|iso
GM|GMB|270|Gambia|Gambia (the)|iso
GN|GIN|324|Guinea|Guinea|iso
GP|GLP|312|Guadeloupe|Guadeloupe|iso
GQ|GNQ|226|Equatorial Guinea|Equatorial Guinea|iso
GR|GRC|300|Greece|Greece|iso
GR|GRE|300|Greece|Greece|player
GS|SGS|239|South Georgia South Sandwich Islands|South Georgia and the South Sandwich Islands|iso
GT|GTM|320|Guatemala|Guatemala|iso
GT|GUA|320|Guatemala|Guatemala|player
GU|GUM|316|Guam|Guam|iso
GW|GNB|624|Guinea-Bissau|Guinea-Bissau|iso
GY|GUY|328|Guyana|Guyana|iso
HK|HKG|344|Hong Kong SAR, China|Hong Kong|iso
HM|HMD|334|Heard And Mc Donald Islands|Heard Island and McDonald Islands|iso
HN|HND|340|Honduras|Honduras|iso
HN|HON|340|Honduras|Honduras|player
HR|CRO|191|Croatia|Croatia|player
HR|HRV|191|Croatia (Hrvatska)|Croatia|iso
HT|HTI|332|Haiti|Haiti|iso
HT|HAI|332|Haiti|Haiti|player
HU|HUN|348|Hungary|Hungary|iso
ID|IDN|360|Indonesia|Indonesia|iso
ID|INA|360|Indonesia|Indonesia|player
IE|IRL|372|Ireland|Ireland|iso
IL|ISR|376|Israel|Israel|iso
IM|IMN|833|Isle of Man|Isle of Man|iso
IN|IND|356|India|India|iso
IO|IOT|86|British Indian Ocean Territory|British Indian Ocean Territory (the)|iso
IQ|IRQ|368|Iraq|Iraq|iso
IR|IRI|364|Iran|Iran (Islamic Republic of)|player
IR|IRN|364|Iran (Islamic Republic Of)|Iran (Islamic Republic of)|iso
IS|ISL|352|Iceland|Iceland|iso
IT|ITA|380|Italy|Italy|iso
JE|JEY|832|Jersey|Jersey|iso
JM|JAM|388|Jamaica|Jamaica|iso
JO|JOR|400|Jordan|Jordan|iso
JP|JPN|392|Japan|Japan|iso
KE|KEN|404|Kenya|Kenya|iso
KG|KGZ|417|Kyrgyzstan|Kyrgyzstan|iso
KH|KHM|116|Cambodia|Cambodia|iso
KI|KIR|296|Kiribati|Kiribati|iso
KM|COM|174|Comoros|Comoros (the)|iso
KN|KNA|659|Saint Kitts And Nevis|Saint Kitts and Nevis|iso
KP|PRK|408|Korea Democratic People's Republic Of|Korea (the Democratic People's Republic of)|iso
KR|KOR|410|Korea|Korea (the Republic of)|player
KR|KOR|410|Korea Republic Of|Korea (the Republic of)|iso
KW|KWT|414|Kuwait|Kuwait|iso
KW|KUW|414|Kuwait|Kuwait|player
KY|CYM|136|Cayman Islands|Cayman Islands (the)|iso
KZ|KAZ|398|Kazakhstan|Kazakhstan|iso
LA|LAO|418|Lao People's Democratic Republic|Lao People's Democratic Republic (the)|iso
LB|LBN|422|Lebanon|Lebanon|iso
LB|LEB|422|Lebanon|Lebanon|player
LB|LIB|422|Lebanon|Lebanon|player
LC|LCA|662|Saint Lucia|Saint Lucia|iso
LI|LIE|438|Liechtenstein|Liechtenstein|iso
LK|LKA|144|Sri Lanka|Sri Lanka|iso
LR|LBR|430|Liberia|Liberia|iso
LS|LSO|426|Lesotho|Lesotho|iso
LT|LTU|440|Lithuania|Lithuania|iso
LU|LUX|442|Luxembourg|Luxembourg|iso
LV|LVA|428|Latvia|Latvia|iso
LV|LAT|428|Latvia|Latvia|player
LY|LBY|434|Libyan Arab Jamahiriya|Libya|iso
MA|MAR|504|Morocco|Morocco|iso
MC|MCO|492|Monaco|Monaco|iso
MC|MON|492|Monaco|Monaco|player
MD|MDA|498|Moldova Republic Of|Moldova (the Republic of)|iso
ME|MNE|499|Montenegro|Montenegro|iso
MF|MAF|663|Saint Martin|Saint Martin (French part)|iso
MG|MDG|450|Madagascar|Madagascar|iso
MG|MAD|450|Madagascar|Madagascar|player
MH|MHL|584|Marshall Islands|Marshall Islands (the)|iso
MK|MKD|807|Macedonia Former Yugoslav Republic Of|North Macedonia|iso
MK|MKD|574|North Macedonia|Norfolk Island|player
ML|MLI|466|Mali|Mali|iso
MM|MMR|104|Myanmar|Myanmar|iso
MN|MNG|496|Mongolia|Mongolia|iso
MO|MAC|446|Macao, China|Macao|iso
MP|MNP|580|Northern Mariana Islands|Northern Mariana Islands (the)|iso
MP|NMI|580|Northern Mariana Islands|Northern Mariana Islands (the)|player
MQ|MTQ|474|Martinique|Martinique|iso
MR|MRT|478|Mauritania|Mauritania|iso
MS|MSR|500|Montserrat|Montserrat|iso
MT|MLT|470|Malta|Malta|iso
MU|MUS|480|Mauritius|Mauritius|iso
MU|MRI|480|Mauritius|Mauritius|player
MV|MDV|462|Maldives|Maldives|iso
MW|MWI|454|Malawi|Malawi|iso
MX|MEX|484|Mexico|Mexico|iso
MY|MYS|458|Malaysia|Malaysia|iso
MY|MAS|458|Malaysia|Malaysia|player
MZ|MOZ|508|Mozambique|Mozambique|iso
NA|NAM|516|Namibia|Namibia|iso
NC|NCL|540|New Caledonia|New Caledonia|iso
NE|NER|562|Niger|Niger (the)|iso
NF|NFK|574|Norfolk Island|Norfolk Island|iso
NG|NGA|566|Nigeria|Nigeria|iso
NI|NIC|558|Nicaragua|Nicaragua|iso
NL|NLD|528|Netherlands|Netherlands (Kingdom of the)|iso
NL|NED|528|Netherlands|Netherlands (Kingdom of the)|player
NO|NOR|578|Norway|Norway|iso
NP|NPL|524|Nepal|Nepal|iso
NR|NRU|520|Nauru|Nauru|iso
NU|NIU|570|Niue|Niue|iso
NZ|NZL|554|New Zealand|New Zealand|iso
OM|OMN|512|Oman|Oman|iso
OM|OMA|512|Oman|Oman|player
OT|IOA|999|||player|false
OT|OTH|999|||iso - user|false
PA|PAN|591|Panama|Panama|iso
PE|PER|604|Peru|Peru|iso
PF|PYF|258|French Polynesia|French Polynesia|iso
PG|PNG|598|Papua New Guinea|Papua New Guinea|iso
PH|PHL|608|Philippines|Philippines (the)|iso
PH|PHI|608|Philippines|Philippines (the)|player
PK|PAK|586|Pakistan|Pakistan|iso
PL|POL|616|Poland|Poland|iso
PM|SPM|666|St. Pierre And Miquelon|Saint Pierre and Miquelon|iso
PN|PCN|612|Pitcairn|Pitcairn|iso
PR|PRI|630|Puerto Rico|Puerto Rico|iso
PR|PUR|630|Puerto Rico|Puerto Rico|player
PS|PSE|275|Palestine Territories|Palestine, State of|iso
PT|PRT|620|Portugal|Portugal|iso
PT|POR|620|Portugal|Portugal|player
PW|PLW|585|Palau|Palau|iso
PY|PRY|600|Paraguay|Paraguay|iso
PY|PAR|600|Paraguay|Paraguay|player
QA|QAT|634|Qatar|Qatar|iso
RE|REU|638|Reunion|Réunion|iso
RO|ROU|642|Romania|Romania|iso
RO|ROM|642|Romania|Romania|player
RS|SRB|688|Serbia|Serbia|iso
RU|RUS|643|Russian Federation|Russian Federation (the)|iso|false
RU|RUS|643||Russian Federation (the)|player|false
RW|RWA|646|Rwanda|Rwanda|iso
SA|SAU|682|Saudi Arabia|Saudi Arabia|iso
SB|SLB|90|Solomon Islands|Solomon Islands|iso
SC|SYC|690|Seychelles|Seychelles|iso
SD|SDN|729|Sudan|Sudan (the)|iso
SE|SWE|752|Sweden|Sweden|iso
SG|SGP|702|Singapore|Singapore|iso
SG|SIN|702|Singapore|Singapore|player
SH|SHN|654|St. Helena|Saint Helena, Ascension and Tristan da Cunha|iso
SI|SVN|705|Slovenia|Slovenia|iso
SI|SLO|705|Slovenia|Slovenia|player
SJ|SJM|744|Svalbard And Jan Mayen Islands|Svalbard and Jan Mayen|iso
SK|SVK|703|Slovakia (Slovak Republic)|Slovakia|iso
SL|SLE|694|Sierra Leone|Sierra Leone|iso
SM|SMR|674|San Marino|San Marino|iso
SN|SEN|686|Senegal|Senegal|iso
SO|SOM|706|Somalia|Somalia|iso
SR|SUR|740|Suriname|Suriname|iso
SS|SSD|728|South Sudan|South Sudan|iso
ST|STP|678|Sao Tome And Principe|Sao Tome and Principe|iso
SV|SLV|222|El Salvador|El Salvador|iso
SV|ESA|222|El Salvador|El Salvador|player
SX|SXM|534|Sint Maarten (Dutch part)|Sint Maarten (Dutch part)|iso
SX|SVK|703|Slovakia|Slovakia|player
SY|SYR|760|Syrian Arab Republic|Syrian Arab Republic (the)|iso
SZ|SWZ|748|Swaziland|Eswatini|iso
TC|TCA|796|Turks And Caicos Islands|Turks and Caicos Islands (the)|iso
TD|TCD|148|Chad|Chad|iso
TF|ATF|260|French Southern Territories|French Southern Territories (the)|iso
TG|TGO|768|Togo|Togo|iso
TH|THA|764|Thailand|Thailand|iso
TJ|TJK|762|Tajikistan|Tajikistan|iso
TK|TKL|772|Tokelau|Tokelau|iso
TL|TLS|626|Timor-Leste (East Timor)|Timor-Leste|iso
TM|TKM|795|Turkmenistan|Turkmenistan|iso
TN|TUN|788|Tunisia|Tunisia|iso
TO|TON|776|Tonga|Tonga|iso
TR|TUR|792|Turkey|Türkiye|iso
TT|TTO|780|Trinidad And Tobago|Trinidad and Tobago|iso
TT|TRI|780|Trinidad and Tobago|Trinidad and Tobago|player
TV|TUV|798|Tuvalu|Tuvalu|iso
TW|TPE|158|Chinese Taipei (Taiwan)|China|player
TW|TWN|158|Taiwan, China|Taiwan (Province of China)|iso
TZ|TZA|834|Tanzania United Republic Of|Tanzania, the United Republic of|iso
UA|UKR|804|Ukraine|Ukraine|iso
UG|UGA|800|Uganda|Uganda|iso
UM|UMI|581|United States Minor Outlying Islands|United States Minor Outlying Islands (the)|iso
US|USA|840|United States|United States of America (the)|iso
UY|URY|858|Uruguay|Uruguay|iso
UY|URU|858|Uruguay|Uruguay|player
UZ|UZB|860|Uzbekistan|Uzbekistan|iso
VA|VAT|336|Holy See (Vatican City State)|Holy See (the)|iso
VC|VCT|670|Saint Vincent And The Grenadines|Saint Vincent and the Grenadines|iso
VE|VEN|862|Venezuela|Venezuela (Bolivarian Republic of)|iso
VG|VGB|92|Virgin Islands (British)|Virgin Islands (British)|iso
VI|VIR|850|Virgin Islands (U.S.)|Virgin Islands (U.S.)|iso
VN|VNM|704|Viet Nam|Viet Nam|iso
VN|VIE|704|Vietnam|Viet Nam|player
VU|VUT|548|Vanuatu|Vanuatu|iso
WF|WLF|876|Wallis And Futuna Islands|Wallis and Futuna|iso
WS|WSM|882|Samoa|Samoa|iso
XK|XKX|999|Kosovo|Kosovo|iso - user
XK|KOS|999|Kosovo|Kosovo|player
YE|YEM|887|Yemen|Yemen|iso
YT|MYT|175|Mayotte|Mayotte|iso
ZA|ZAF|710|South Africa|South Africa|iso
ZA|RSA|710|South Africa|South Africa|player
ZM|ZMB|894|Zambia|Zambia|iso
ZW|ZWE|716|Zimbabwe|Zimbabwe|iso
ZW|ZIM|716|Zimbabwe|Zimbabwe|player
`;

const getCountryArray = () => {
	const [keys, ...rest] = countryCsv
		.trim()
		.split("\n")
		.map((item) => item.split('|'));

  	const formedArr = rest.map((item) => {
		const object = {};
		keys.forEach((key, index) => (object[key] = item.at(index)));
		return object;
  	});
  	return formedArr;
}

export const countryList = getCountryArray();
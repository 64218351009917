import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[SearchPage] action.mount - data:%o', data);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Search Page',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			data.solrSearchRole = Config.solrSearchRole;
			data.contentPath = Config.cmsData.search;
			data.faqPath = Config.cmsData.faq;
			logger.log('[SearchPage] action.mount - data:%o', data);
			dispatch({ type: deps.actionTypes.SEARCHPAGE_MOUNT, data: data });
		});
	},
	showSearch: flag => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SEARCHPAGE_SHOWSEARCH, data: flag });
	},
	unmount: () => (dispatch, getState, store) => {
		let data = {};
		data.solrSearch = '';
		data.contentPath = '';
		data.roleResults = null;
		data.searchResults = null;
		dispatch({ type: deps.actionTypes.SEARCHPAGE_UNMOUNT, data: data });
	},
	getRoleSearch: path => (dispatch, getState, store) => {
		deps.services.SearchPage.fetchRoleSearchResults(path, dispatch, store).then(data => {
			logger.log('[SearchPage] actions.getRoleSearch - data:%o', data);
			dispatch({ type: deps.actionTypes.SEARCHPAGE_ROLERESULTS, data: data?.response?.response });
		});
	},
};

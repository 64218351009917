import { useState, useEffect } from 'react';
import { AddressBook, CourtAvailability, DiningReservation, RemainingBookings } from '../elements';
import NotFound from '../NotFound';
import { useParams } from 'react-router-dom';
import { useMemberContext } from '../context';

const useMemberPage = () => {
	const [headerProps, setHeaderProps] = useState(null);

	const { currentUser, roleValidated, disableSection, gigyaLoaded } = useMemberContext();

	const { page } = useParams();
	// This is returning the React Element, which is then rendered using <Element /> inside index.
	const Element =
		page === 'court-availability'
			? CourtAvailability
			: page === 'book'
			? RemainingBookings
			: page === 'address-book'
			? AddressBook
			: page === 'make-reservation'
			? DiningReservation
			: NotFound;

	const getTitle = () =>
		disableSection
			? 'Not Found'
			: page === 'court-availability'
			? 'Court Status'
			: page === 'address-book'
			? 'Address Book'
			: page === 'book'
			? 'My Bookings'
			: page === 'make-reservation' && 'Book a Restaurant';

	useEffect(() => {
		const isMember = roleValidated && page;

		setHeaderProps(() => ({
			headerType: gigyaLoaded && isMember ? 's_member' : 'generic',
			title: gigyaLoaded && isMember ? getTitle() : !gigyaLoaded ? '' : 'SiteMap',
			shortTitle: gigyaLoaded && isMember ? getTitle() : !gigyaLoaded ? '' : 'SiteMap',
			metaTitle: gigyaLoaded && isMember ? getTitle() : !gigyaLoaded ? '' : 'Page Not Found',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		}));
	}, [roleValidated, currentUser, page]);

	return { Element, headerProps };
};

export default useMemberPage;

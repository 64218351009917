import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';

class PlayerList extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	render() {
		return (
			<ul role="list">
				{this.state.players
					.filter(player => {
						return (
							player.last_name.charAt(0).toUpperCase() === this.state.letter &&
							player.gender == this.state.gender
						);
					})
					.map(player => {
						return (
							<li key={player.id}>
								<FavouriteStar
									id={player.id}
									type="players"
									unFavColor="black"
									favColor="yellow"
									alwaysShow={true}
								/>
								<Link to={`/en_GB/players/overview/${player.id}.html`}>
									{player.last_name}, {player.first_name}
								</Link>
							</li>
						);
					})}
			</ul>
		);
	}
}
export default PlayerList;

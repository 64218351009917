import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import {} from 'appdir/components/data/Schema';

const v = new Validator();

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

export default {
	fetch,
};

import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const BirthMonth = data => {
	let cutoffMonth =
		moment()
			.subtract(13, 'years')
			.add(1, 'day')
			.month() + 1;
	//logger.log('[BirthMonth] render - data:%o cutoff.month:%o', data, cutoffMonth);
	//disable for now since day and month have to work together
	cutoffMonth = 13;

	return (
		<span className="input-date">
			<label className="gigya-label" data-binding="true" htmlFor={`${data.id}.gigya-dropdown-birthMonth`}>
				<span className="gigya-label-text" data-translation-key="DROPDOWN_BIRTH_MONTH_LABEL" />
				<label
					className="gigya-required-display"
					data-bound-to="profile.birthMonth"
					style={{}}
					data-binding="true"
					htmlFor="gigya-dropdown-birthmonth">
					*
				</label>
			</label>

			<select
				id={`${data.id}.gigya-dropdown-birthMonth`}
				name="profile.birthMonth"
				tabIndex={data.tabIndex ? data.tabIndex : '0'}>
				<option value="" data-translation-key="DROPDOWN_90567281827265380_CHOICES_" />
				<option
					value="1"
					disabled={cutoffMonth <= 1}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_C4CA4238A0B923820DCC509A6F75849B"
				/>
				<option
					value="2"
					disabled={cutoffMonth <= 2}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_C81E728D9D4C2F636F067F89CC14862C"
				/>
				<option
					value="3"
					disabled={cutoffMonth <= 3}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_ECCBC87E4B5CE2FE28308FD9F2A7BAF3"
				/>
				<option
					value="4"
					disabled={cutoffMonth <= 4}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_A87FF679A2F3E71D9181A67B7542122C"
				/>
				<option
					value="5"
					disabled={cutoffMonth <= 5}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_E4DA3B7FBBCE2345D7772B0674A318D5"
				/>
				<option
					value="6"
					disabled={cutoffMonth <= 6}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_1679091C5A880FAF6FB5E6087EB1B2DC"
				/>
				<option
					value="7"
					disabled={cutoffMonth <= 7}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_8F14E45FCEEA167A5A36DEDD4BEA2543"
				/>
				<option
					value="8"
					disabled={cutoffMonth <= 8}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_C9F0F895FB98AB9159F51FD0297E236D"
				/>
				<option
					value="9"
					disabled={cutoffMonth <= 9}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_45C48CCE2E2D7FBDEA1AFC51C7C6AD26"
				/>
				<option
					value="10"
					disabled={cutoffMonth <= 10}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_D3D9446802A44259755D38E6D163E820"
				/>
				<option
					value="11"
					disabled={cutoffMonth <= 11}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_6512BD43D9CAA6E02C990B0A82652DCA"
				/>
				<option
					value="12"
					disabled={cutoffMonth <= 12}
					data-translation-key="DROPDOWN_90567281827265380_CHOICES_C20AD4D76FE97759AA27A0C99BFF6710"
				/>
			</select>
			<span className="gigya-error-msg" data-bound-to="profile.birthMonth" />
		</span>
	);
};

export default BirthMonth;

import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useDiningContext } from './elements';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import Button from 'appdir/components/common-ui/Button';

const RetrieveAvailableTimes = props => {
	const {
		date,
		setDate,
		service,
		setService,
		size,
		setSize,
		searchVenues,
		permittedPartySize,
		permittedService,
		permittedDateMin,
		permittedDateMax,
		// services,
		// partySizes,
		resetSelectedTime,
		selectedTime,
	} = useDiningContext(props);

	let datePickerRef = useRef(null);

	return (
		<div className="dining-reservation-wrapper">
			<div className="entry-wrapper one-col  margin">
				<h5 className={'label purple select-header required'}>Date of Visit</h5>
				<div className="input-wrapper">
					<img
						src="/assets/images/icons/calendar-icon.png"
						alt="calendar"
						className="input-icon"
						onClick={() => datePickerRef.setOpen(true)}
					/>
					<DatePicker
						ref={c => (datePickerRef = c)}
						selected={date}
						onChange={date => {
							setDate(date);
							if (selectedTime) {
								resetSelectedTime(); // reset time selected if new value is set after search for available times has been done and time has been selected
							}
						}}
						minDate={permittedDateMin}
						maxDate={permittedDateMax}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
			</div>
			<div className="entry-wrapper one-col">
				<div className="input-wrapper">
					<div className="select-wrapper">
						<label htmlFor="membership-type">
							<h5 className={'label purple select-header required'}>Service</h5>
						</label>
						<div className="menu-wrapper">
							<div className="dining-reservation__select-menu">
								<div id="select-menu" style={{ width: '100%' }}>
									<div className="scores-select-menu" style={{ width: '100%' }}>
										<SelectMenu
											name="shiftType"
											attributes={permittedService}
											selected={service}
											onSelected={service => {
												setService(service);
												if (selectedTime) {
													resetSelectedTime(); // reset time selected if new value is set after search for available times has been done and time has been selected
												}
											}}
											filterName="type"
											onOpen={console.log}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="entry-wrapper one-col margin">
				<div className="input-wrapper">
					<div className="select-wrapper">
						<label htmlFor="membership-type">
							<h5 className={'label purple select-header required'}>Party Size</h5>
						</label>
						<div className="menu-wrapper">
							<div className="dining-reservation__select-menu">
								<div id="select-menu" style={{ width: '100%' }}>
									<div className="scores-select-menu" style={{ width: '100%' }}>
										<SelectMenu
											name="partySize"
											attributes={permittedPartySize}
											selected={size}
											onSelected={size => {
												setSize(size);
												if (selectedTime) {
													resetSelectedTime(); // reset time selected if new value is set after search for available times has been done and time has been selected
												}
											}}
											filterName="type"
											onOpen={console.log}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="entry-wrapper one-col"
				style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
				<Button
					className="green solid search-btn generic-button_wrapper"
					name="search venues"
					title="search venues"
					alt="search venues"
					// onClick={() => searchVenues('testmembersdiningroom')} // hardcode as Members' Dining Room key for now, change to dynamic once we have the multiple venues integrated
					onClick={() => searchVenues()}
					disabled={!date || !service || service === 'Select' || !size || size === 'Select'}>
					Search
				</Button>
			</div>
		</div>
	);
};

export default RetrieveAvailableTimes;

import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		//logger.log('[DrawsArchiveContentPage] action.mount - data:%o', data);

		// Mount dispatch
		//dispatch({type: deps.actionTypes.CONTENT_MOUNT, data: data});

		// Get articles
		if (data.hasOwnProperty('champion') || data.hasOwnProperty('fact') || data.hasOwnProperty('history')) {
			//dispatch({type: deps.actionTypes.CONTENT_LOADING});

			logger.log('[DrawsArchiveContentPage] action.mount - ensureConfig');
			return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
				let path = '';

				logger.log('[DrawsArchiveContentPage] action.mount data:%o', data);

				if (data.hasOwnProperty('champion')) {
					path = Config.cmsData.drawsArchive.replace('<contentId>', data.champion);
					data.category = 'champion';
					data.landing = '';
				} else if (data.hasOwnProperty('fact')) {
					path = Config.cmsData.drawsArchive.replace('<contentId>', data.fact);
					data.category = '';
					data.landing = 'fast_facts';
				} else if (data.hasOwnProperty('history')) {
					path = Config.cmsData.drawsArchiveHistoryYearData.replace('<year>', data.history);
					data.category = 'history';
					data.landing = '';
				}

				if (data.prefix === 'preview') {
					path = '/preview' + path;
				}

				data.dataUrl = path;

				data.drawYear = Config.drawsArchive.year;

				//logger.log('[DrawsArchiveContentPage] action.mount - data: %o', data);
				dispatch({ type: deps.actionTypes.CONTENT_LOAD, data: data });
			});
		}
	},

	unmount: () => (dispatch, getState, store) => {
		let data = {
			category: '',
			landing: '',
			dataUrl: '',
		};
		dispatch({ type: deps.actionTypes.CONTENT_UNMOUNT, data: data });
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_CONTENT_PAGE_FILTER, data: data });
	},
};

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const getHeadToHeadData = pathArry => {
	let hdr = restHeaders();
	return axios.all(pathArry);
};

export default {
	getHeadToHeadData,
};

import SlamtrackerWebview from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: '/webview/:lang/scores/stats/:matchId.html',
	exact: true,
	component: SlamtrackerWebview,
	section: MENU_ITEMS.scoresSchedule,
	load: params => deps.actions.Slamtracker.mount(params),
};

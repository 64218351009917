import React, { Component } from 'react';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';

import InputBox from 'appdir/components/general/Gigya/screens/elements/InputBox';

class VerificationCodeAuth extends Component {
	constructor(props) {
		super(props);

		//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
		this.classnames = 'gigya-screen v2 portrait';
	}

	render() {
		//logger.log('[VerificationCodeAuth] render - props:%o context:%o', this.props, this.context);

		//get user email from currentUser if known (during login) else from profile element (during registration)
		let userEmail = op.get(
			this,
			'context.currentUser.requestParams.loginID',
			op.get(this, 'context.profile.email', '')
		);

		return (
			<div
				data-width="auto"
				className="gigya-screen v2 portrait"
				className={this.classnames}
				data-caption="screenset.translations['GIGYA_EMAIL_CODE_VERIFICATION_SCREEN_CAPTION']"
				id="gigya-email-code-verification-screen"
				data-screenset-roles="template">
				<form
					className="gigya-otp-update-form"
					onSubmit={() => {
						return false;
					}}
					method="post"
					id="gigya-otp-update-form"
					data-screenset-roles="template">
					<div className="gigya-layout-row">
						<h2 className="gigya-composite-control gigya-composite-control-header">Email Verification</h2>
					</div>
					<div className="gigya-layout-row">
						<label
							className="gigya-message align-left gigya-composite-control gigya-composite-control-label"
							data-binding="true"
							data-screenset-element-id="__gig_template_element_386_1694020661445"
							data-screenset-element-id-publish="false"
							data-screenset-roles="template,template">
							For your security, myWIMBLEDON is now requiring all login emails to be verified.
							<br />
							<br />
							An email verification code has been sent to: {userEmail}
							<br />
							<br />
							Please enter the code sent to your email address below and click SUBMIT to complete the
							verification. This code will expire after 10 minutes.
						</label>
					</div>
					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '10px' }} />
					<div className="gigya-layout-row">
						<div className="gigya-composite-control gigya-composite-control-textbox">
							<input
								type="text"
								defaultValue=""
								name="code"
								data-required="true"
								autoComplete="off"
								className="gigya-input-text"
								formNoValidate="formnovalidate"
								tabIndex="0"
								data-screenset-element-id="gigya-textbox-code"
								data-screenset-element-id-publish="true"
								data-screenset-roles="template"
								data-gigya-name="code"
								data-original-value=""
							/>
							<span
								className="gigya-error-msg"
								data-bound-to="code"
								data-screenset-element-id="__gig_template_element_383_1694020661445"
								data-screenset-element-id-publish="false"
								data-screenset-roles="template"
								aria-atomic="true"></span>
						</div>
					</div>
					<div className="gigya-layout-row">
						<div className="gigya-composite-control gigya-composite-control-submit">
							<input
								type="submit"
								className="gigya-input-submit"
								tabIndex="0"
								data-screenset-element-id="__gig_template_element_385_1694020661445"
								data-screenset-element-id-publish="false"
								data-screenset-roles="template"
								gigya-default-value="null"
								value="SUBMIT"
								readOnly={true}
							/>
						</div>
						<div
							className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
							data-bound-to="gigya-otp-update-form"
							data-screenset-element-id="__gig_template_element_382_1694020661445"
							data-screenset-element-id-publish="false"
							data-screenset-roles="template"
							aria-atomic="true">
							<div
								className="gigya-error-msg gigya-form-error-msg"
								data-bound-to="gigya-otp-update-form"
								data-screenset-element-id="__gig_template_element_384_1694020661445"
								data-screenset-element-id-publish="false"
								data-screenset-roles="template"
								aria-atomic="true"></div>
						</div>
					</div>
					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '10px' }} />
					<div className="gigya-layout-row">
						<label
							className="gigya-composite-control gigya-composite-control-link"
							data-screenset-element-id="__gig_template_element_387_1694023500763"
							data-screenset-element-id-publish="false"
							data-screenset-roles="template">
							Resend new verification code.{' '}
							<a data-disable-on-render="true" data-gigya-api-link="accounts.otp.sendCode">
								Click Here
							</a>
						</label>
					</div>
					<div className="gigya-clear"></div>
				</form>
			</div>
		);
	}
}

VerificationCodeAuth.contextType = AuthContext;
export default VerificationCodeAuth;

/**
<div
				id="gigya-email-code-auth-method-screen"
				data-width="auto"
				className={this.classnames}
				data-caption="Email Code Auth">
				<form className="gigya-email-code-auth-method-form" id="gigya-email-code-auth-method-form">
					<div className="gigya-layout-row ui-droppable ui-sortable">
						<label
							for="gigya-textbox-email-otp-auth"
							className="gigya-label-text gigya-composite-control gigya-composite-control-label gigya-composite-control-hover"
							data-toolbox-item-type="label"
							data-wizard-base-translation-key="LABEL_110155625417325040"
							draggable="false"
							data-binding="true">
							Enter the code sent to your email
						</label>
					</div>
					<div className="gigya-layout-row ui-droppable ui-sortable">
						<div
							className="gigya-composite-control gigya-composite-control-textbox"
							data-toolbox-item-type="textbox"
							data-wizard-base-translation-key="TEXTBOX_104563282957373310"
							draggable="false">
							<input
								id="gigya-textbox-email-otp-auth"
								type="text"
								value=""
								name="code"
								autoComplete="off"
								className="gigya-input-text"
								formNoValidate="formnovalidate"
								tabIndex="0"
								data-array-root=""
								data-gigya-type="text"
							/>
							<span className="gigya-error-msg" data-bound-to="code"></span>
						</div>
					</div>

					<div className="gigya-layout-row ui-droppable ui-sortable">
						<div
							className="gigya-composite-control gigya-composite-control-submit"
							data-toolbox-item-type="submit"
							data-wizard-base-translation-key="SUBMIT_80310548192090850"
							draggable="false">
							<input
								type="submit"
								className="gigya-input-submit"
								value="Login"
								tabIndex="0"
								data-gigya-type="submit"
							/>
						</div>
						<div
							className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
							data-bound-to="gigya-email-code-auth-method-form"
							data-toolbox-item-type="formError">
							<div
								className="gigya-error-msg gigya-form-error-msg"
								data-bound-to="gigya-email-code-auth-method-form"></div>
						</div>
					</div>

					<div className="gigya-layout-row ui-droppable ui-sortable">
						<div className="gigya-layout-row ui-droppable ui-sortable gigya-email-otp-links">
							<div className="gigya-layout-cell ui-droppable ui-sortable gigya-email-otp-links-left">
								<a
									className="gigya-composite-control gigya-composite-control-link gigya-conditional gigya-email-otp-change-method-link"
									data-toolbox-item-type="link"
									data-binding="true"
									target="_blank"
									data-wizard-base-translation-key="LINK_114318864490895001"
									data-switch-screen="gigya-auth-methods-screen"
									draggable="false"
									data-condition="authMethods.length > 1"
									data-wizard-text-alignment="center">
									Change login method
								</a>
								<a
									className="gigya-composite-control gigya-composite-control-link gigya-conditional gigya-email-otp-change-method-link"
									data-toolbox-item-type="link"
									data-binding="true"
									target="_blank"
									data-wizard-base-translation-key="LINK_130096189626726381"
									data-switch-screen="gigya-passwordless-login-screen"
									draggable="false"
									data-condition="authMethods.length === 1"
									data-wizard-text-alignment="center">
									Back to login screen
								</a>
							</div>
							<div className="gigya-layout-cell gigya-email-otp-links-right ui-droppable ui-sortable">
								<a
									className="gigya-composite-control gigya-composite-control-link"
									data-toolbox-item-type="link"
									data-binding="true"
									data-wizard-base-translation-key="LINK_149410999765063800"
									data-disable-on-render="true"
									data-gigya-api-link="accounts.auth.otp.email.sendCode">
									Send a new code
								</a>
							</div>
						</div>
					</div>
				</form>
			</div>
			
 */

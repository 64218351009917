/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import React, { Component } from 'react';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';

import JSXParser from 'react-jsx-parser';
import { parseString, parseNumbers, parseBooleans } from 'xml2js';
import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';
import endsWith from 'lodash/endsWith';

import MeasurementUtils from 'appdir/lib/analytics';
import Helmet from 'react-helmet';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import SiteMap from 'appdir/components/general/SiteMap';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import EventSelect from './EventSelect';
import { getComponents, validateData } from 'appdir/components/general/Util';
import Template from 'appdir/components/Template';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['DrawsArchiveContentPage'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.DrawsArchiveContentPage.mount()),
	unmount: () => dispatch(deps.actions.DrawsArchiveContentPage.unmount()),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
	filter: settings => dispatch(deps.actions.DrawsArchiveContentPage.filter(settings)),
});

const getComponentList = list => {
	let comps = [];

	forIn(list, function(value, key) {
		if (value.hasOwnProperty('type')) {
			comps.push(value['type']);
		} else {
			logger.log('[DrawsArchiveContentPage] getComponentList - removing: %o:', key);
		}
	});

	//logger.log('[DrawsArchiveContentPage] getComponents - list:%o:', list);
	logger.log('[DrawsArchiveContentPage] values:%o:', values);

	comps = uniq(comps);
	logger.log('[DrawsArchiveContentPage] getComponents - %o:', comps);
	return comps;
};

const getAttributes = list => {
	let attr = {};
	forIn(list, function(value, key) {
		if (value.hasOwnProperty('type')) {
			attr[key] = value;
		}
	});
	return attr;
};

const getComponentListArray = list => {
	let comps = [];

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				comps.push(item['type']);
			} else {
				//logger.log('[DrawsArchiveContentPage] getComponentList - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	//logger.log('[DrawsArchiveContentPage] getComponents - %o:', comps);
	return comps;
};

const getAttributesArray = list => {
	let attr = {};

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				attr[item['reference']] = item;
			} else {
				//logger.log('[DrawsArchiveContentPage]  getAttributesArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		attr[list['reference']] = list;
	}
	//logger.log('[DrawsArchiveContentPage]  getAttributesArray - list: %o:', list);

	return attr;
};

let dropdownData = [];

class DrawsArchiveContentPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};

		this.onShareClick = this.onShareClick.bind(this);
		this.lastDataPath = '';

		logger.log('[DrawsArchiveContentPage] constructor');
	}

	componentDidMount() {
		logger.log('[DrawsArchiveContentPage] componentDidMount');
	}

	componentWillUnmount() {
		logger.log('[DrawsArchiveContentPage] componentWillUnmount');
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		dropdownData = [
			// { value: 'champions', name: `${nextProps.drawYear} Champions` }, //changed to be more generic
			{ value: 'champions', name: `Current Champions` },
			{ value: 'gentlemenssingles', name: "Gentlemen's Singles" },
			{ value: 'ladiessingles', name: "Ladies' Singles" },
			{ value: 'gentlemensdoubles', name: "Gentlemen's Doubles" },
			{ value: 'ladiesdoubles', name: "Ladies' Doubles" },
			{ value: 'mixeddoubles', name: 'Mixed Doubles' },
			{ value: 'boyssingles', name: "Boys' Singles" },
			{ value: 'girlssingles', name: "Girls' Singles" },
			{ value: 'boysdoubles', name: "Boys' Doubles" },
			{ value: 'girlsdoubles', name: "Girls' Doubles" },
			{ value: 'wheelchair_events', name: 'Wheelchair Events' },
		];

		logger.log('[DrawsArchiveContentPage] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	}

	setViewedContent(timestamp, contentId) {
		//logger.log('[DrawsArchiveContentPage] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	setFilter(contentId, title) {
		//logger.log('[DrawsArchiveContentPage] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.filter({
			selected: contentId,
			title: title,
			open: '',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.dataUrl != undefined && endsWith(this.state.dataUrl, '.xml')) {
			//logger.log('[DrawsArchiveContentPage] componentDidUpdate - fetch:%o', this.state.dataUrl);

			if (this.state.filters && prevState.filters) {
				logger.log(
					'[DrawsArchiveContentPage] componentDidUpdate - prevState: %o, state:%o',
					prevState,
					this.state
				);
				if (this.state.filters.selected !== prevState.filters.selected) {
					this.getRedirectURL();
				}
			}

			//if path samem but key diff (new nav to existing location?)
			//  reload the data
			if (
				this.state.location.pathname == prevState.location.pathname &&
				this.state.location.key != prevState.location.key
			) {
				this.lastDataPath = '';
			}

			if (this.state.dataUrl != this.lastDataPath) {
				this.lastDataPath = this.state.dataUrl;
				deps.services.DrawsArchiveContentPage.fetchContent(this.state.dataUrl)
					.then(result => {
						//logger.log('[DrawsArchiveContentPage] componentDidUpdate result:%o', result);

						//set the xml parse options
						let options = {
							explicitArray: false,
							normalize: true,
							trim: true,
							mergeAttrs: true,
							valueProcessors: [],
						};

						//parse the xml
						parseString(result, options, function(err, json) {
							result = json.contentItem;
						});

						//clean the jsx block
						result.jsx = result.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');
						//logger.log('[DrawsArchiveContentPage] - result:%o', result);

						//update viewed content if has a date
						if (result.date) {
							this.setViewedContent(parseInt(result.date), result.contentId);
							if (!result.contentId.includes('fast_fact')) {
								this.setFilter(result.contentId, result.title);
							}
						}

						this.setState({
							data: result,
						});

						MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
							content: result,
						});
					})
					.catch(error => {
						logger.error('[DrawsArchiveContentPage] componentDidUpdate error:%o', error);
						if (error.status == 404) {
							this.setState({
								data: 'notfound',
							});
						}
					});
			}
		} else {
			logger.warn('[DrawsArchiveContentPage] componentDidUpdate - improper data url: %o', this.state.dataUrl);
		}
	}

	getRedirectURL() {
		let urlLink = this.state.filters.selected + '.html';
		this.setState({
			menuLink: urlLink,
		});
	}

	onShareClick() {
		logger.log('[DrawsArchiveContentPage] onShareClick');

		this.setState({
			sharePopup: 'hide',
		});
	}

	getHeader() {
		if (this.state.category != 'articles' && !this.state.landing) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-title">
							<h1>{this.state.data.title}</h1>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
						</div>
					</div>
				</div>
			);
		} else if (this.state.landing) {
			<div className="news--header-wrapper">
				<div className="news--header" />
			</div>;
		} else if (this.state.data.header.videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-icon">
							<i className="wim-icon-news" aria-label="news icon" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h1>{this.state.data.title}</h1>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
					<div
						className="news--header-videoLink"
						onClick={() => {
							this.playFullVideo(this.state.data.header.videoId);
						}}>
						Play Full Video
						<i className="wim-icon-video" />
					</div>
				</div>
			);
		} else if (this.state.data.header.image && this.state.data.header.image.length > 25) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-icon">
							<i className="wim-icon-news" aria-hidden={true} />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h1>{this.state.data.title}</h1>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	playFullVideo(id) {
		logger.log('[DrawsArchiveContentPage] playFullVideo - id:%o', id);
	}

	champsCheck(dropdownData, filters) {
		//check for champions page select menu
		let isChampion = this.props.location.pathname.indexOf('/champions/') !== -1;

		// if it's abbreviation page or ladies name page, don't show dropdown
		let exclude =
			this.props.location.pathname.indexOf('/champions/country-abbreviations.html') !== -1 ||
			this.props.location.pathname.indexOf('/champions/ladies-names.html') !== -1;

		if (isChampion && !exclude) {
			return <EventSelect attributes={dropdownData} filters={filters} />;
		} else {
			return <div> </div>;
		}
	}

	render() {
		logger.log('[DrawsArchiveContentPage] render - state:%o', this.state);

		if (this.state.menuLink) {
			if (
				this.state.menuLink !==
				this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)
			) {
				return <Redirect push to={this.state.menuLink} />;
			}
		}

		if (this.state.data == 'notfound') {
			let header_propsData = {
				headerType: 'generic',
				title: 'Sitemap',
				metaTitle: 'Page not found',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};

			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="drawsArchiveHdrContent">
						<h1>Top Stats</h1>
						<ArchiveMenu location={this.state.location.pathname} />
					</div>

					<div className="content-main">
						<SiteMap notFound={true} />
						<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
					</div>
					<Footer />
				</Template>
			);
		} else if (this.state.data) {
			let attributes = getAttributesArray(this.state.data.dependencies.data);
			let components = getComponents(getComponentListArray(this.state.data.dependencies.data));
			//logger.log('[DrawsArchiveContentPage] render - attributes:%o components:%o:', attributes, components);
			logger.log('[DrawsArchiveContentPage] render - data:%o:', this.state.data);

			let flex = this.state.data.tiled && this.state.data.tiled == 'true' ? 'flex' : '';

			let isHistory = this.props.location.pathname.indexOf('/history/') !== -1;

			let players = op.get(this.state, 'data.players.player', []).map(function(player) {
				return player['name'];
			});

			let header_propsData = {
				headerType: 'drawsArchive',
				imageSrc: '/assets/images/headers/drawsArchive_topstats.jpg',
				shortTitle: 'Draws Archive, ' + this.state.data.shortTitle,
				metaTitle: 'Draws Archive, ' + this.state.data.title,
				metaDescription: this.state.data.abstract,
				metaDate: this.state.data.date,
				metaPlayers: players.join(' | '),
				scroll: false,
			};

			header_propsData = {
				...header_propsData,
				type: 'O',
				metaSeoTitle: this.state.data.seoTitle,
				metaSeoDescription: this.state.data.seoDescription,
				canonicalLink: values.canonicalHost + window.location.pathname,
				ampUrl: this.state.data.url.amp,
				shareImage:
					this.state.data.header.image ||
					window.location.origin + '/assets/images/headers/defaultContentHeader.jpg',
				author: this.state.data.credit,
				measureTitle: 'Detail',
				metaDate: this.state.data.date,
			};

			if (this.state.data.header.image && this.state.data.header.image.length > 25) {
				header_propsData['videoSrc'] = this.state.data.header.video;
			}

			logger.log('[DrawsArchiveContentPage] render - propsData:%o', header_propsData);

			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="drawsArchiveHdrContent">
						<h1>
							{this.state.data.title}
							{isHistory ? <span> History</span> : null}
						</h1>
						<ArchiveMenu location={this.state.location.pathname} />
					</div>

					{this.champsCheck(dropdownData, this.state.filters)}

					<div className={`content-main column-layout news ${flex}`}>
						<JSXParser
							bindings={attributes}
							components={{ ...components }}
							jsx={this.state.data.jsx}
							renderInWrapper={false}
							showWarnings={true}
						/>
					</div>

					<Footer />
				</Template>
			);
		} else {
			logger.warn('[DrawsArchiveContentPage] render - data not loaded, state:%o', this.state);

			let header_propsData = {
				headerType: 'drawsArchive',
				imageSrc: '/assets/images/headers/drawsArchive_topstats.jpg',
				titleElem: '.news--header',
				scroll: false,
			};

			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="drawsArchiveHdrContent">
						<h1>{this.state?.data?.title}</h1>
						<ArchiveMenu location={this.state.location.pathname} />
					</div>

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveContentPage);

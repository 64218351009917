import React from 'react';
import { values } from 'appdir/main';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const BirthYear = data => {
	let cutoff = moment()
		.subtract(13, 'years')
		.add(1, 'day');
	//logger.log('[BirthYear] render - data:%o cutoff:%o year:%o', data, cutoff.toString(), cutoff.year());

	let years = [];
	let startYear = cutoff.year();
	let lastYear = 1920;

	for (let i = startYear; i >= lastYear; i--) {
		years.push(i.toString());
	}

	//logger.log('[BirthYear] render - values:%o years:%o', values, years);

	return (
		<span className="input-date">
			<label className="gigya-label" htmlFor={`${data.id}.gigya-dropdown-birthYear`}>
				<span className="gigya-label-text" data-translation-key="DROPDOWN_BIRTH_YEAR_LABEL" />
				<label className="gigya-required-display" data-bound-to="profile.birthYear" style={{}}>
					*
				</label>
			</label>

			<select
				name="profile.birthYear"
				id={`${data.id}.gigya-dropdown-birthYear`}
				tabIndex={data.tabIndex ? data.tabIndex : '0'}>
				<option value="" data-translation-key="DROPDOWN_27897089514179760_CHOICES_" />
				{years.map((d, i) => {
					return (
						<option value={d} key={i}>
							{d}
						</option>
					);
				})}
			</select>
			<span className="gigya-error-msg" data-bound-to="profile.birthYear" />
		</span>
	);
};

export default BirthYear;

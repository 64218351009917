import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		logger.log('[ShopCarousel] action.mount');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const path = Config.shop.feedUrl;
			deps.services.ShopCarousel.fetch(path).then(data => {
				dispatch({ type: deps.actionTypes.SHOPCAROUSEL_MOUNT, data });
			});
		});
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';

/**
 * -----------------------------------------------------------------------------
 * React Component: Contact Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['ContactPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContactPage.mount()),
});

class ContactThankYou extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		logger.log('[ContactPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'contact',
			title: 'Contact Us',
			shortTitle: 'ContactUs',
			pageTitle: 'Contact Us',
			htsearch: 'Contact Us',
		};

		return (
			<Template className="contactpage">
				<div className="contact-background" />
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				{this.state ? (
					<div className="content-main column-layout thank-you-container">
						<div className="thank-you">
							<div className="heading">Thank you for contacting the AELTC</div>
							<div className="sub-heading">
								We receive a significant number of emails and we value your feedback. However, you will
								appreciate that due to this volume of correspondence, we are not able to respond
								directly to every enquiry.
							</div>
							<div className="urgent-contact">
								<div className="contact-message">
									If you have a significant issue you want to raise, we request that you contact us in
									writing to:
								</div>
								<div className="contact-details">
									The All England Lawn Tennis Club (Championships) Limited
									<br />
									Church Road, Wimbledon, LondonSW19 5A, EUK
								</div>
							</div>
							<div className="contact">
								Thank you for your interest in the AELTC and The Championships.
							</div>
						</div>
					</div>
				) : (
					<div className="content-main">
						<LoadingIndicator />
					</div>
				)}

				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactThankYou);

import React from 'react';
import InputBox from './elements/InputBox';

const ResetPassword = ({ data }) => {
	//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
	let reset_classname = 'gigya-screen v2 portrait';

	//gigya-conditional:show-password-strength="viewport.width < 900?up"
	let strengthInfo = 'up';

	return (
		<div
			id="gigya-reset-password-screen"
			data-width="auto"
			className="gigya-screen v2 portrait"
			data-caption="screenset.translations['GIGYA_RESET_PASSWORD_SCREEN_CAPTION']">
			<form className="gigya-reset-password-form" id="gigya-reset-password-form">
				<div className="gigya-layout-row">
					<label
						className="gigya-composite-control gigya-composite-control-label main-text letter-spacing-s"
						data-translation-key="LABEL_PASSWORD_NEW_LABEL"
					/>
					<div className="gigya-composite-control gigya-composite-control-password eyeDiv5">
						<InputBox
							data={{
								type: 'password',
								name: 'newPassword',
								key: 'TEXTBOX_PASSWORD_NEW_LABEL',
								showValid: 'true',
								showStrength: strengthInfo,
								class: 'gigya-input-password',
							}}
						/>
					</div>
					<div className="gigya-composite-control gigya-composite-control-password eyeDiv6">
						<InputBox
							data={{
								type: 'password',
								name: 'passwordRetype',
								key: 'TEXTBOX_PASSWORD_NEWCONFIRM_LABEL',
								showValid: 'true',
								showStrength: '',
								class: 'gigya-input-password',
								required: 'true',
							}}
						/>
					</div>
					<div
						className="gigya-composite-control gigya-composite-control-submit"
						style={{ display: 'block' }}>
						<input
							type="submit"
							className="gigya-input-submit"
							tabIndex="0"
							value="screenset.translations['BUTTON_SUBMIT_VALUE']"
						/>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell" />
					<div className="gigya-layout-cell" />
					<div className="gigya-clear" />
				</div>

				<div className="gigya-layout-row">
					<div
						className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
						data-bound-to="gigya-reset-password-form">
						<div
							className="gigya-error-msg gigya-form-error-msg"
							data-bound-to="gigya-reset-password-form"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ResetPassword;

import deps from 'dependencies';
import { MemberContextProvider } from './context';
export default [
	{
		path: ['/s/member/service/:page.html', '/s/member/service/:page', '/s/member/service/:page/:type'],
		exact: true,
		component: MemberContextProvider,
		load: params => deps.actions.Member.mount(params),
	},
];

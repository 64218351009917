/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import NewsTile from 'appdir/components/content/NewsTile';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ContentModal
 * -----------------------------------------------------------------------------
 */

class LatestContent extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		let latestContentData = this.state['LatestContent']['content'];
		return (
			<div className="latest-content">
				{latestContentData.map((d, i) => {
					return <NewsTile attributes={{ ...d, 'col-style': 'one-col', date: parseInt(d.date) }} />;
				})}
			</div>
		);
	}
}

export default LatestContent;

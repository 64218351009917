export const profileUpdate = {
	CHECKBOX_70498960732827160_LABEL: 'Subscribe to our newsletter',
	DROPDOWN_123378782009071870_LABEL: 'Country:',
	DROPDOWN_26444391219476100_LABEL: 'Year of birth:',
	LINK_132250333113315490_LABEL: 'Change password',
	LINK_38294035404464760_LABEL: 'Change password',
	LINK_8317452541627202_LABEL: 'Verification methods',
	GIGYA_UPDATE_PROFILE_SCREEN_CAPTION: 'Profile Details',
	SUBMIT_28989361435205550_VALUE: 'Save',
	TEXTBOX_103116099544972600_LABEL: 'First name:',
	TEXTBOX_120427572416211680_LABEL: 'Zip code:',
	TEXTBOX_122360196509920620_LABEL: 'Email:',
	TEXTBOX_37121616612960376_LABEL: 'Last name:',
	LINK_2367240488149685_LABEL: 'Back to profile details',
	GIGYA_TFA_EDIT_SCREEN_CAPTION: 'Verification Methods',
	LABEL_56210386758398230_LABEL: 'To change your password, please fill in the fields below:',
	LINK_119010215178770340_LABEL: 'Back to profile details',
	PASSWORD_44053075986500540_LABEL: 'New password:',
	PASSWORD_77569318128198620_LABEL: 'Current password:',
	PASSWORD_83392580376454140_LABEL: 'Confirm new password:',
	GIGYA_CHANGE_PASSWORD_SCREEN_CAPTION: 'Password Change',
	SUBMIT_73157439010313300_VALUE: 'Submit',
	CHECKBOX_30833646406628064_LABEL: 'Other consent statement',
	CONSENTWIDGET_135456827484122290_DATA_LABEL: 'Terms of Service:',
	CONSENTWIDGET_46211515338191930_DATA_LABEL: 'Privacy:',
	LABEL_32509117823221136_LABEL: '{{ profile.firstName }}',
	LABEL_87479578924694800_LABEL: '{{ profile.lastName }}',
	LABEL_93984751747520690_LABEL: '{{ profile.email }}',
	GIGYA_PRIVACY_SCREEN_CAPTION: 'Privacy',
	SUBMIT_68470082948063230_VALUE: 'Save',
	CHECKBOX_23747318366892016_LABEL: 'Weekly Newsletter',
	LABEL_47519884037620904_LABEL: '{{ profile.lastName }}',
	LABEL_78135148598284590_LABEL: '{{ profile.email }}',
	LABEL_81243869146910910_LABEL: '{{ profile.firstName }}',
	GIGYA_COMMUNICATION_SCREEN_CAPTION: 'Communication',
	SUBMIT_78091265303174380_VALUE: 'Save',
	CHECKBOX_36093561356460540_LABEL: 'Subscribe to our newsletter',
	DROPDOWN_10954929616950068_LABEL: 'Country:',
	DROPDOWN_27897089514179760_LABEL: 'Year of birth:',
	LINK_21074431753063724_LABEL: 'Verification methods',
	LINK_29009445734946590_LABEL: 'Change password',
	LINK_93937631890759840_LABEL: 'Change password',
	GIGYA_UPDATE_PROFILE_SCREEN_2_CAPTION: 'Profile Details',
	SUBMIT_139002756140568320_VALUE: 'Save',
	TEXTBOX_35887798661246190_LABEL: 'Last name:',
	TEXTBOX_67010566505855360_LABEL: 'Zip code:',
	TEXTBOX_7736986726932801_LABEL: 'First name:',
	TEXTBOX_97514499177413920_LABEL: 'Email:',
	LOGIN_IDENTIFIER_EXISTS: '',
	UNIQUE_IDENTIFIER_EXISTS: '',
	ACCOUNT_TEMPORARILY_LOCKED_OUT: '',
	WRONG_PASSWORD: '',
	OLD_PASSWORD_CANNOT_BE_THE_SAME_AS_NEW_PASSWORD: '',
	OLD_PASSWORD_USED: '',
	CHOOSE_FILE: '',
	NO_FILE_CHOSEN: '',
	'MAXIMUM_SIZE_OF_3MB.': '',
	THERE_ARE_ERRORS_IN_YOUR_FORM_PLEASE_TRY_AGAIN: '',
	SORRY_WE_ARE_NOT_ABLE_TO_PROCESS_YOUR_REGISTRATION: '',
	INVALID_LOGIN_OR_PASSWORD: '',
	ACCOUNT_IS_DISABLED: '',
	EMAIL_ALREADY_EXISTS: '',
	THERE_IS_NO_USER_WITH_THAT_USERNAME_OR_EMAIL: '',
	PASSWORD_MUST_CONTAIN_AT_LEAST: '',
	AND: '',
	NUM_OF_THE_FOLLOWING_GROUPS: '',
	NUM_CHARACTERS_TOTAL: '',
	TOO_WEAK: '',
	CHECKING: '',
	EMAIL_ADDRESS_IS_INVALID: '',
	PASSWORD_DOES_NOT_MEET_COMPLEXITY_REQUIREMENTS: '',
	"THE_CHARACTERS_YOU_ENTERED_DIDN'T_MATCH_THE_WORD_VERIFICATION._PLEASE_TRY_AGAIN": '',
	USERNAME_ALREADY_EXISTS: '',
	PASSWORDS_DO_NOT_MATCH: '',
	PLEASE_ENTER_FIELDNAME: '',
	THIS_FIELD_IS_REQUIRED: '',
	INVALID_FIELDNAME: '',
	VERY_STRONG: '',
	STRONG: '',
	FAIR: '',
	WEAK: '',
	PASSWORD_STRENGTH_COLON: '',
	NOT_AVAILABLE: '',
	AVAILABLE: '',
	NETWORK_ERROR: '',
	PROFILEPHOTO_FILESIZEERROR: '',
	SUBSCRIPTION_PENDING_CONFIRMATION: '',
	LOGIN_CAPTCHA_ERROR: '',
	REGISTER_CAPTCHA_ERROR: '',
	INVALID_PASSWORD_RESET_TOKEN: '',
	"DIDN'T_GET_THE_CODE_QM": '',
	EDIT: '',
	PLEASE_CHOOSE_A_NUMBER_TO_EDIT_COLON: '',
	SELECT_YOUR_COUNTRY_COLON: '',
	ENTER_YOUR_PHONE_NUMBER_COLON: '',
	GET_CODE_BY_COLON: '',
	TEXT_MESSAGE: '',
	VOICE_CALL: '',
	SUBMIT: '',
	GET_THE_CODE: '',
	PLEASE_ENTER_A_VALID_CODE: '',
	NEXT: '',
	ENTER_CODE_COLON: '',
	RESEND: '',
	CHANGE_RESEND: '',
	REMEMBER_THIS_DEVICE: '',
	YOUR_PHONE_NUMBER_COLON: '',
	EXAMPLE_COLON: '',
	A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_COLON: '',
	A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_PHONE_NUMBER_COLON: '',
	REMEMBER_THIS_COMPUTER: '',
	A_PHONE_CALL_WITH_YOUR_VERIFICATION_CODE_HAS_BEEN_PLACED_TO_COLON: '',
	CHOOSE_HOW_TO_RECEIVE_THE_VERIFICATION_CODE_COLON: '',
	TO_KEEP_YOUR_ACCOUNT_SECURE_VERIFICATION_CODES_WILL_BE_SENT_WHEN_YOU_LOGIN_FROM_UNTRUSTED_DEVICES_DOT: '',
	EDIT_YOUR_PHONE_NUMBER_WHERE_VERIFICATION_CODES_WILL_BE_RECEIVED_COLON: '',
	SELECT_PROVIDER: '',
	STEP: '',
	SCAN_QR_CODE: '',
	ENTER_TOTP_CODE: '',
	TOTP_SESSION_EXPIRED: '',
	AN_ERROR_HAS_OCCURRED_PLEASE_TRY_AGAIN_LATER: '',
	CANT_ACCESS_USE_BACKUP_CODES: '',
	TOTP_VERIFICATION_SETTINGS: '',
	TOTP_CHANGE_DEVICE: '',
	BACKUP_CODES: '',
	PRINT_BACKUP_CODES_INTRO: '',
	GENERATE_BACKUP_CODES: '',
	ALREADY_USED: '',
	ENTER_BACKUP_CODE: '',
	USE_BACKUP_CODE: '',
	RESET_CODES_QUESTION: '',
	TOTP_VERIFICATION_SETTINGS_INTRO: '',
	DOWNLOAD_BACKUP_CODES: '',
	PRINT: '',
	YES: '',
	CANCEL: '',
};

import React from 'react';

const Inline = props => {
	return (
		<div className={`inline-registration`} id="inline-champ-registration">
			{props.children}
		</div>
	);
};

export default Inline;

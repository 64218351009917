import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.ROLEREGISTRATIONINVITE_MOUNT:
			//logger.info('[RoleRegistrationInvite] reducer.MYWIMBLEDONROLE_MOUNT action:%o', action);
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		case deps.actionTypes.ROLEREGISTRATIONINVITE_UNMOUNT:
			logger.info('[RoleRegistrationInvite] reducer.MYWIMBLEDONROLE_UNMOUNT ');
			newState = {};
			return newState;
			break;

		default:
			return state;
	}
};

import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';
import { getRoleByName } from 'appdir/components/general/Util/Role';

const subItems = [
	'news',
	'favourites',
	'debentures',
	'events',
	'community',
	'viewpoint',
	'foundation',
	'merchandise',
	'museum',
	'ticketsAndBallots',
	'playingTennis',
	'transcripts',
	'courtChanges',
	'orderOfPlay',
	'mediaReleases',
	'dailyUpdates',
	'tournament_dailyUpdates',
	'tournament_courtChanges',
	'tournament_orderOfPlay',
];

/**
 * Checkboxes for displaying and update user's contact preferences
 *  - types of contact
 *  - subject matter of contact
 */

class FormContact extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			...FormContact.getStatus(props),
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		let hasData = op.get(props, 'subscriptions.newsletter.email.isSubscribed', null);

		logger.info('[FormContact] constructor - hasData:%o state:%o', hasData, this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[FormContact] getDerivedStateFromProps - next:%o prev:%o', nextProps, prevState);

		let newState = {};

		let noPreviousUser = prevState.user == null || isEmpty(prevState.user);

		//logger.info('[FormContact] getDerivedStateFromProps - noPrevUser:%o newUser:%o', noPreviousUser , newUser);

		if (noPreviousUser && nextProps.user) {
			//logger.info('[FormContact] getDerivedStateFromProps - next:%o prev:%o', nextProps.currentUser.UID, prevState.currentUser.UID);
			newState = {
				user: nextProps.user,
				...FormContact.getStatus(nextProps),
			};

			if (
				prevState.user &&
				(prevState.user.subscriptions == null || isEmpty(prevState.user.subscriptions)) &&
				nextProps.user.subscriptions
			) {
				//logger.info('[FormContact] getDerivedStateFromProps - subs');
				newState = {
					user: {
						subscriptions: nextProps.user.subscriptions,
					},
					...FormContact.getStatus(nextProps),
				};
			}
		}

		let mediaRole = getRoleByName(op.get(nextProps, 'currentUser.roles', null), 'media');
		if (!prevState.mediaRole && mediaRole) {
			newState.mediaRole = mediaRole;
		}

		let tournamentRole = getRoleByName(op.get(nextProps, 'currentUser.roles', null), 'tournament');
		if (!prevState.tournamentRole && tournamentRole) {
			newState.tournamentRole = tournamentRole;
		}

		if (isEmpty(newState)) {
			newState = null;
		}
		//logger.info('[FormContact] getDerivedStateFromProps - newState:%o', newState);
		return newState;
	}

	static getStatus(props) {
		let comms = op.get(props, 'user.preferences.CommunicationPreferences.entitlements', []);
		//comms = ['Email, SMS, Post'];
		if (comms.length) {
			if (comms[0].indexOf(',') > -1) {
				comms = comms[0].split(',').map(item => {
					return item.trim();
				});
			}
			logger.info('[FormContact] getStatus - comms:%o', comms);

			let commPrefs = comms.map(item => {
				return item.toLowerCase();
			});
			logger.info('[FormContact] getStatus - commPrefs:%o', commPrefs);
			comms = commPrefs;
		}

		let state = {
			phone: comms.indexOf('phone') >= 0 || comms.indexOf('call') >= 0,
			email: comms.indexOf('email') >= 0,
			sms: comms.indexOf('sms') >= 0,
			post: comms.indexOf('post') >= 0,
			news: op.get(props, 'user.subscriptions.newsletter.email.isSubscribed', false),
			favourites: op.get(props, 'user.subscriptions.favourites.email.isSubscribed', false),
			debentures: op.get(props, 'user.subscriptions.debentures.email.isSubscribed', false),
			events: op.get(props, 'user.subscriptions.events.email.isSubscribed', false),
			community: op.get(props, 'user.subscriptions.community.email.isSubscribed', false),
			viewpoint: op.get(props, 'user.subscriptions.viewpoint.email.isSubscribed', false),
			foundation: op.get(props, 'user.subscriptions.foundation.email.isSubscribed', false),
			merchandise: op.get(props, 'user.subscriptions.merchandise.email.isSubscribed', false),
			museum: op.get(props, 'user.subscriptions.museum.email.isSubscribed', false),
			ticketsAndBallots: op.get(props, 'user.subscriptions.ticketsAndBallots.email.isSubscribed', false),
			playingTennis: op.get(props, 'user.subscriptions.playingTennis.email.isSubscribed', false),
			transcripts: op.get(props, 'user.subscriptions.media.transcripts.email.isSubscribed', false),
			courtChanges: op.get(props, 'user.subscriptions.media.courtChanges.email.isSubscribed', false),
			orderOfPlay: op.get(props, 'user.subscriptions.media.orderOfPlay.email.isSubscribed', false),
			mediaReleases: op.get(props, 'user.subscriptions.media.mediaReleases.email.isSubscribed', false),
			dailyUpdates: op.get(props, 'user.subscriptions.media.dailyUpdates.email.isSubscribed', false),

			tournament_dailyUpdates: op.get(
				props,
				'user.subscriptions.tournament.dailyUpdates.email.isSubscribed',
				false
			),
			tournament_courtChanges: op.get(
				props,
				'user.subscriptions.tournament.courtChanges.email.isSubscribed',
				false
			),
			tournament_orderOfPlay: op.get(
				props,
				'user.subscriptions.tournament.orderOfPlay.email.isSubscribed',
				false
			),
		};

		state.all = FormContact.areAllChecked(state);

		return state;
	}

	static areAllChecked(state) {
		logger.info('[FormContact] areAllChecked - state:%o', state);
		if (state.news && state.favourites && state.ticketsAndBallots && state.debentures && state.foundation) {
			return true;
		} else {
			return false;
		}
	}

	static areAnyChecked(state) {
		let any = false;
		subItems.forEach(item => {
			//logger.info('[FormContact] areAnyChecked - %s:%o', item, state[item]);
			if (state[item]) {
				any = true;
			}
		});
		logger.info('[FormContact] areAnyChecked - val:%o', any);
		return any;
	}

	componentDidUpdate() {
		//logger.info('[FormContact] componentDidUpdate - state:%o', this.state);
	}

	handleChange(e) {
		logger.info('[FormContact] handleChange - e:%o', e.currentTarget.id);
		let id = e.currentTarget.id.split('-')[1];
		let state = {};
		if (id != 'all') {
			state[id] = !this.state[id];
			state.all = FormContact.areAllChecked({ ...this.state, ...state });
			state['errorMessage'] = '';
			//logger.info('[FormContact] handleChange - state:%o', state);
			this.setState(state);
		} else {
			this.setState({
				all: !this.state.all,
				news: !this.state.all,
				favourites: !this.state.all,
				ticketsAndBallots: !this.state.all,
				playingTennis: !this.state.all,
				debentures: !this.state.all,
				foundation: !this.state.all,
				errorMessage: '',
			});
		}
	}

	handleSubmit() {
		//logger.info('[FormContact] handleSubmit - onUpdate:%o', this.props.onUpdate);
		let entitlements = [];
		if (this.state.email) {
			entitlements.push('email');
		}
		if (this.state.phone) {
			entitlements.push('phone');
		}
		if (this.state.sms) {
			entitlements.push('sms');
		}
		if (this.state.post) {
			entitlements.push('post');
		}

		let anyCheck = FormContact.areAnyChecked({ ...this.state });
		//logger.info('[FormContact] handleSubmit - anyCheck:%o', anyCheck);
		if (entitlements.length == 0 && anyCheck) {
			this.showError();
			return;
		}

		let prefs = {
			CommunicationPreferences: {
				entitlements: entitlements,
				isConsentGranted: entitlements.length > 0,
			},
		};

		let subscriptions = {};

		op.set(subscriptions, 'newsletter.email.isSubscribed', this.state.news);
		op.set(subscriptions, 'favourites.email.isSubscribed', this.state.favourites);
		op.set(subscriptions, 'debentures.email.isSubscribed', this.state.debentures);
		op.set(subscriptions, 'events.email.isSubscribed', this.state.events);
		op.set(subscriptions, 'community.email.isSubscribed', this.state.community);
		op.set(subscriptions, 'viewpoint.email.isSubscribed', this.state.viewpoint);
		op.set(subscriptions, 'foundation.email.isSubscribed', this.state.foundation);
		op.set(subscriptions, 'merchandise.email.isSubscribed', this.state.merchandise);
		op.set(subscriptions, 'museum.email.isSubscribed', this.state.museum);
		op.set(subscriptions, 'ticketsAndBallots.email.isSubscribed', this.state.ticketsAndBallots);
		op.set(subscriptions, 'playingTennis.email.isSubscribed', this.state.playingTennis);

		if (op.get(this.state, 'mediaRole.approved', false)) {
			op.set(subscriptions, 'media.transcripts.email.isSubscribed', this.state.transcripts);
			op.set(subscriptions, 'media.courtChanges.email.isSubscribed', this.state.courtChanges);
			op.set(subscriptions, 'media.orderOfPlay.email.isSubscribed', this.state.orderOfPlay);
			op.set(subscriptions, 'media.mediaReleases.email.isSubscribed', this.state.mediaReleases);
			op.set(subscriptions, 'media.dailyUpdates.email.isSubscribed', this.state.dailyUpdates);
		}

		if (op.get(this.state, 'tournamentRole.approved', false)) {
			op.set(subscriptions, 'tournament.dailyUpdates.email.isSubscribed', this.state.tournament_dailyUpdates);
			op.set(subscriptions, 'tournament.courtChanges.email.isSubscribed', this.state.tournament_courtChanges);
			op.set(subscriptions, 'tournament.orderOfPlay.email.isSubscribed', this.state.tournament_orderOfPlay);
		}

		if (this.props.onUpdate) {
			logger.info('[FormContact] handleSubmit - prefs:%o subs:%o', prefs, subscriptions);
			this.props.onUpdate({ preferences: prefs, subscriptions: subscriptions });
		}
	}

	showError() {
		this.setState({
			errorMessage: 'Please select at least one of the Preferences above in order to update your Interests.',
		});
	}

	onRenderCheckboxes(stateVal, htmlForVal, innerTxt, containerClassname) {
		return (
			<div className={containerClassname}>
				<input
					type="checkbox"
					id={htmlForVal}
					tabIndex="0"
					checked={this.state[stateVal]}
					onChange={this.handleChange}
				/>
				<label className="consent-label-wrapper" htmlFor={htmlForVal}>
					<label className="consent-label" htmlFor={htmlForVal}>
						<div className="consent-label-text wrap">{innerTxt}</div>
					</label>
				</label>
			</div>
		);
	}

	render() {
		logger.info('[FormContact] render - state:%o', this.state);

		return (
			<div className="mywim_settings contact consent-pref-float">
				<div className="section">
					<div className="title">Preferences</div>

					<div className="inline-wrapper">
						{this.onRenderCheckboxes('phone', 'checkbox-phone', 'Call', 'consent-display compact inline')}

						{this.onRenderCheckboxes('email', 'checkbox-email', 'Email', 'consent-display compact inline')}
					</div>

					<div className="inline-wrapper">
						{this.onRenderCheckboxes('sms', 'checkbox-sms', 'SMS', 'consent-display compact inline')}

						{this.onRenderCheckboxes('post', 'checkbox-post', 'Post', 'consent-display compact inline')}
					</div>
				</div>

				<div className="section">
					<div className="title">Interests</div>
					<div className="title-description">
						Please help us guide your experience by selecting the topics that are of most interest to you:
					</div>
					{this.onRenderCheckboxes('all', 'checkbox-all', 'All', 'consent-display compact')}
					{this.onRenderCheckboxes(
						'news',
						'checkbox-news',
						'News & Information about the Club, the Grounds and The Championships',
						'consent-display compact indent'
					)}
					{this.onRenderCheckboxes(
						'favourites',
						'checkbox-favourites',
						'Favourites',
						'consent-display compact indent'
					)}

					{this.onRenderCheckboxes(
						'ticketsAndBallots',
						'checkbox-ticketsAndBallots',
						'Tickets & Ballots',
						'consent-display compact indent'
					)}
					{this.onRenderCheckboxes(
						'playingTennis',
						'checkbox-playingTennis',
						'Playing Tennis',
						'consent-display compact indent'
					)}
					{this.onRenderCheckboxes(
						'debentures',
						'checkbox-debentures',
						'Debentures',
						'consent-display compact indent'
					)}

					{this.onRenderCheckboxes(
						'foundation',
						'checkbox-foundation',
						'Wimbledon Foundation',
						'consent-display compact indent'
					)}

					{op.get(this.state, 'mediaRole.approved', false) ? (
						<Fragment>
							<div className="title">Media</div>
							<div className="title-description">
								Please select from the list below which tournament communications you wish to receive:
							</div>

							{this.onRenderCheckboxes(
								'transcripts',
								'checkbox-transcripts',
								'Transcripts',
								'consent-display compact'
							)}

							{this.onRenderCheckboxes(
								'courtChanges',
								'checkbox-courtChanges',
								'Court Changes',
								'consent-display compact'
							)}

							{this.onRenderCheckboxes(
								'orderOfPlay',
								'checkbox-orderOfPlay',
								'Order of Play',
								'consent-display compact'
							)}

							{this.onRenderCheckboxes(
								'mediaReleases',
								'checkbox-mediaReleases',
								'Media Releases',
								'consent-display compact'
							)}

							{this.onRenderCheckboxes(
								'dailyUpdates',
								'checkbox-dailyUpdates',
								'Daily Updates',
								'consent-display compact'
							)}
						</Fragment>
					) : null}

					{op.get(this.state, 'tournamentRole.approved', false) ? (
						<Fragment>
							<div className="title">Tournament</div>
							<div className="title-description">
								Please select from the list below which tournament communications you wish to receive:
							</div>

							{this.onRenderCheckboxes(
								'tournament_courtChanges',
								'checkbox-tournament_courtChanges',
								'Court Changes',
								'consent-display compact'
							)}

							{this.onRenderCheckboxes(
								'tournament_orderOfPlay',
								'checkbox-tournament_orderOfPlay',
								'Order of Play',
								'consent-display compact'
							)}

							{this.onRenderCheckboxes(
								'tournament_dailyUpdates',
								'checkbox-tournament_dailyUpdates',
								'Daily Updates',
								'consent-display compact'
							)}
						</Fragment>
					) : null}

					<div className="title">More</div>

					{this.onRenderCheckboxes(
						'merchandise',
						'checkbox-merchandise',
						'Official Wimbledon Merchandise',
						'consent-display compact'
					)}

					{this.onRenderCheckboxes(
						'museum',
						'checkbox-museum',
						'Wimbledon Museum & Tours',
						'consent-display compact'
					)}

					{this.onRenderCheckboxes(
						'events',
						'checkbox-events',
						'Wimbledon Events',
						'consent-display compact'
					)}

					{this.onRenderCheckboxes(
						'community',
						'checkbox-community',
						'Community News and Benefits',
						'consent-display compact'
					)}

					{this.onRenderCheckboxes(
						'viewpoint',
						'checkbox-viewpoint',
						<div>
							<span className="bold">VIEW</span>POINT – an exclusive community to have your say to help us
							shape the future
						</div>,
						'consent-display compact'
					)}
				</div>
				<div className="submit-section">
					<Button
						className={`btn solid four-col`}
						onClick={() => {
							this.handleSubmit();
						}}>
						UPDATE
					</Button>
					<div className="error">{this.state.errorMessage}</div>
				</div>
			</div>
		);
	}
}

export default FormContact;

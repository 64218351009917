/**
 HillVoteWebview Initial State
*/

export default {
    voted: [],
    // 172800000 = 2 days in milliseconds
	persist: {
		voted: 172800000,
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import { handleExitResaleQueueEndpoint, handleGetQueueStatus } from 'appdir/components/webviews/TicketResaleWebview/ticketResaleEndpoints';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getErrorText } from 'appdir/components/general/Util/Error';
import { measureInAppContext } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ExitQueueConfirmation
 * -----------------------------------------------------------------------------
 */

const ExitQueueConfirmation = props => {
    const sortResult = (response) => {
		let res;
		// sort by creation time and get most recent booking
		if (response?.length > 0) {
			let sortedRes = response?.sort(function (a, b) {
				return moment.utc(b.creation_time).diff(moment.utc(a.creation_time))
			});
			res = sortedRes[0];
		} else {
			res = response;
		}
		
		return res;
	}

	return (
        <div className="resale-modal-overlay">
            <div
                className='resale-modal-content'
            >
                <div className="resale-modal-close-btn">
                    <CloseBtn closeFn={props.closeModal} />
                </div>
                <div className="ticket-action-modal-title">EXIT QUEUE</div>
                <div className="ticket-action-modal-divider-container">
                    <div className="ticket-action-modal-title-divider" />
                </div>
                <div className="ticket-action-modal-information-text">
                    <span
                        tabIndex={0}
                        aria-label={'Are you sure you want to exit the queue? Doing so will mean losing your queue position.'}>
                        Are you sure you want to exit the queue? Doing so will mean losing your queue position.
                    </span>
                </div>
                <div className="ticket-stub-actions-btns-cntr">
                    <button
                        role="button"
                        aria-label='Yes exit the queue'
                        className='ticket-stub-actions-value ticket-action-modal-button-green'
                        onClick={() => {
                            props.closeModal();
                            props.getJWT().then(token => {
                                props.showModal({
                                    showModal: true,
                                    showCloseBtn: false,
                                    type: 'updating',
                                    title: 'Exiting Queue',
                                    message: 'Please do not close the window while submitting.',
                                    useTimer: false,
                                });

                                // first get status to see if user has been admitted 
                                let statusPath = props.queueAPIs?.resaleQueueStatus;
                                handleGetQueueStatus(token.jwt.id_token, statusPath).then((response) => {
                                    measureInAppContext({
                                        pageTitle:"Ticket Resale Queue",
                                        action: "Exit Queue",
                                        context: [{
                                            section: "Ticket Resale Ready",
                                        }],
                                        metricType: "state",
                                    });
                                    let statusResponse = sortResult(response?.data?.response?.result);
                                    if (statusResponse?.booking_status == 6 || statusResponse?.booking_status == 1 || statusResponse?.booking_status == 2 || statusResponse?.booking_status == 0) {
                                        // can call exit if "notify" or "on the way"
                                        let path = props.queueAPIs?.resaleUpdateQueue;
                                        handleExitResaleQueueEndpoint(token.jwt.id_token, path, props.queueObj?.booking_id).then((response) => {
                                            logger.log('[ExitQueueConfirmation] handleExitResaleQueueEndpoint response %o', response);
                                            props.exitQueueResponse('success', response)
                                            props.showModal({
                                                showModal: false,
                                                showCloseBtn: true,
                                                type: 'success',
                                                title: 'Success',
                                                message: 'You have exited the queue',
                                                useTimer: false,
                                            });
                                        }).catch(error => {
                                            logger.error('[ExitQueueConfirmation] handleExitResaleQueueEndpoint - error:%o', error);
                                            props.showModal({
                                                showModal: true,
                                                showCloseBtn: true,
                                                type: 'error',
                                                title: 'Error Exiting Queue',
                                                message: error?.message || 'An error has occured. Please try again',
                                                useTimer: false,
                                            });
                                            props.exitQueueResponse('error', error)
                                        });
                                    } else {
                                        // if status is 5 (i.e., expired), clear response locally and go back to join screen
                                        if (statusResponse?.booking_status == 5) {
                                            props.handleExitExpiredQueue();
                                        } else {
                                            // just go back to join screen 
                                            props.exitQueueResponse('success', response)
                                            props.showModal({
                                                showModal: false,
                                                showCloseBtn: true,
                                                type: 'success',
                                                title: 'Success',
                                                message: 'You have exited the queue',
                                                useTimer: false,
                                            });
                                        }
                                    }

                                }).catch(error => {
                                    logger.error('[ExitQueueConfirmation] handleExitResaleQueueEndpoint - error:%o', error);
                                    props.showModal({
                                        showModal: true,
                                        showCloseBtn: true,
                                        type: 'error',
                                        title: 'Error Exiting Queue',
                                        message: getErrorText(error?.code || error?.data?.response?.error?.code, `Exit Confirmation ${props.pageTitle}`),
                                        useTimer: false,
                                    });
                                    props.exitQueueResponse('error', error)
                                })
                            });
                        }}
                        type="submit">
                        Yes
                    </button>
                    <button aria-label="no do not exit the queue" role="button" className="ticket-stub-actions-value" onClick={props.closeModal}>
                        No
                    </button>
                </div>
            </div>
        </div>
    )
};

export default ExitQueueConfirmation;

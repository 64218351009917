/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: Interests
 * -----------------------------------------------------------------------------
 */

class Interests extends Component {
	constructor(props) {
		super(props);

		this.state = {
			PartnersAndSuppliers: '',
			merchandise: '',
			museum: '',
			ticketsAndBallots: '',
		};
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall('pageView', {
			pageTitle: 'onboardingInterests',
		});
	}

	handleInputChange = e => {
		const { name, value } = e.target;

		// update state with new values
		this.setState({ [name]: value === 'yes' });

		// check if all items have selections in order to enable next button in onboarding process
		const newState = { ...this.state, [name]: value === 'yes' };

		const stateValues = Object.values(newState);
		// Check if all items except the current one have values
		const hasAllValues = stateValues.every(val => val !== undefined && val !== '');

		// if has all values for all PartnersAndSuppliers, merchandise, museum, ticketsAndBallots items, update to enable next button
		if (hasAllValues) {
			this.props.onEnableNextBtn(newState);
			this.onSaveSelections(name, value === 'yes');
		}
	};

	onSaveSelections(name, value) {
		let entitlements = this.props.gigya?.user?.preferences?.CommunicationPreferences?.entitlements || [];

		// set new state to account for item that was updated
		const newState = { ...this.state, [name]: value };

		let subscriptions = {};
		let preferences = {};

		op.set(subscriptions, 'merchandise.email.isSubscribed', newState.merchandise);
		op.set(subscriptions, 'museum.email.isSubscribed', newState.museum);
		op.set(subscriptions, 'ticketsAndBallots.email.isSubscribed', newState.ticketsAndBallots);

		// get entitlements length to make sure at least one exists if any subscriptions are selected yes
		if (
			(newState.merchandise || newState.museum || newState.ticketsAndBallots) &&
			(!entitlements || !entitlements.length)
		) {
			// set default communication preferences if any subscriptions are selected yes
			entitlements.push('email', 'phone', 'sms');
			op.set(preferences, 'CommunicationPreferences.entitlements', entitlements);
			op.set(preferences, 'CommunicationPreferences.isConsentGranted', true);
		}

		op.set(preferences, 'PartnersAndSuppliers.isConsentGranted', newState.PartnersAndSuppliers);

		if (this.props.setAccountInfo) {
			logger.info('[Interests] handleSaveSelections - preferences:%o subs:%o', preferences, subscriptions);
			let data = { preferences: preferences, subscriptions: subscriptions };
			this.props.setAccountInfo('interests', data, false);
		}
	}

	render() {
		const { ticketsAndBallots, merchandise, museum, PartnersAndSuppliers } = this.state;

		return (
			<div className="two-col margin">
				<div className="interests-comp">
				Please confirm the areas of most interest to you by opting in to receive email communications below:
					<div className="question-container">
						<div className="question-item">
							<div className="toggle-container">
								<input
									type="radio"
									id="ticketsAndBallots-yes"
									className="question-yes-input"
									name="ticketsAndBallots"
									value="yes"
									onChange={this.handleInputChange}
									checked={ticketsAndBallots === true}
								/>
								<label
									htmlFor="ticketsAndBallots-yes"
									className={
										ticketsAndBallots === false
											? 'question-yes-input-white-border'
											: 'question-item question-yes'
									}>
									Yes
								</label>
								<input
									type="radio"
									id="ticketsAndBallots-no"
									className="question-no-input"
									name="ticketsAndBallots"
									value="no"
									onChange={this.handleInputChange}
									checked={ticketsAndBallots === false}
								/>
								<label htmlFor="ticketsAndBallots-no" className="question-item question-no">
									No
								</label>
							</div>
							<label htmlFor="ticketsAndBallots" className="question-item-text">
								Tickets and Ballot Information and attending The Championships
							</label>
						</div>

						<div className="question-item">
							<div className="toggle-container">
								<input
									type="radio"
									id="merchandise-yes"
									className="question-yes-input"
									name="merchandise"
									value="yes"
									onChange={this.handleInputChange}
									checked={merchandise === true}
								/>
								<label
									htmlFor="merchandise-yes"
									className={
										merchandise === false
											? 'question-yes-input-no-border'
											: 'question-item question-yes'
									}>
									Yes
								</label>
								<input
									type="radio"
									id="merchandise-no"
									className="question-no-input"
									name="merchandise"
									value="no"
									onChange={this.handleInputChange}
									checked={merchandise === false}
								/>
								<label htmlFor="merchandise-no" className="question-item question-no">
									No
								</label>
							</div>
							<label htmlFor="merchandise" className="question-item-text">
								The Wimbledon Shop including early access to exclusive products and discounts on
								Official Wimbledon Merchandise
							</label>
						</div>

						<div className="question-item">
							<div className="toggle-container">
								<input
									type="radio"
									id="museum-yes"
									className="question-yes-input"
									name="museum"
									value="yes"
									onChange={this.handleInputChange}
									checked={museum === true}
								/>
								<label
									htmlFor="museum-yes"
									className={
										museum === false ? 'question-yes-input-no-border' : 'question-item question-yes'
									}>
									Yes
								</label>
								<input
									type="radio"
									id="museum-no"
									className="question-no-input"
									name="museum"
									value="no"
									onChange={this.handleInputChange}
									checked={museum === false}
								/>
								<label htmlFor="museum-no" className="question-item question-no">
									No
								</label>
							</div>
							<label htmlFor="museum" className="question-item-text">
								Visiting year-round including Wimbledon Museum and Tours
							</label>
						</div>

						<div className="question-item">
							<div className="toggle-container">
								<input
									type="radio"
									id="PartnersAndSuppliers-yes"
									className="question-yes-input"
									name="PartnersAndSuppliers"
									value="yes"
									onChange={this.handleInputChange}
									checked={PartnersAndSuppliers === true}
								/>
								<label
									htmlFor="PartnersAndSuppliers-yes"
									className={
										PartnersAndSuppliers === false
											? 'question-yes-input-no-border'
											: 'question-item question-yes'
									}>
									Yes
								</label>
								<input
									type="radio"
									id="PartnersAndSuppliers-no"
									className="question-no-input"
									name="PartnersAndSuppliers"
									value="no"
									onChange={this.handleInputChange}
									checked={PartnersAndSuppliers === false}
								/>
								<label htmlFor="PartnersAndSuppliers-no" className="question-item question-no">
									No
								</label>
							</div>
							<label htmlFor="PartnersAndSuppliers" className="question-item-text">
								News and offers from Official Partners and Suppliers
							</label>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	gigya: state['Gigya'],
	configVal: state['Config'],
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	setAccountInfo: (source, data, modal) => dispatch(deps.actions.Gigya.setAccountInfo(source, data, modal)),
});

// export default Interests;
export default connect(mapStateToProps, mapDispatchToProps)(Interests);

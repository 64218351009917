import classNames from 'classnames';
import React, { Component } from 'react';
import { handleCancelTicketTransfer } from 'appdir/components/pages/Tickets/ReusableTicketActionFuncs';
import CustomActionConfirmation from 'appdir/components/pages/Tickets/elements/TicketActionViews/CustomActionConfirmation';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';

class RecallAction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirmationModal: false,
			email: '',
		};
		this.onHandleTicketAction = this.onHandleTicketAction.bind(this);
	}

	componentDidMount() {
		measureInAppContext({
			pageTitle: 'Tickets',
			action: 'Cancel Transfer',
			args: [],
			metricType: 'state',
		});
	}

	onHandleTicketAction(which, error, msg, msg2) {
		const { fullTicketValue } = this.props;
		let ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];
		// set state email because once the ticket is canceled, email in data status is empty (and we need to display it in the confirmation modal)
		this.setState({ email: ticketValue[0]?.source?.transfer?.toEmail || '' });
		// logger.log('[RecallAction] - onHandleTicketAction - which:%o, error:%o, msg:%o, msg2:%o', which, error, msg, msg2)
		if (error) {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Cancel Ticket Sumbit',
					args: 'Failed',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall('Recall Ticket Sumbit Failed');
			}

			let message1, message2;
			let message1Default = 'There was an error processing your request. Please try again.';
			let message2Default = '';

			const errCode = error?.data?.response?.failure[0] ? error.data.response.failure[0].errorCode : '';

			/** if there is no error code, use default messages */
			if (!errCode) {
				if (error?.msg) {
					message1 = error?.msg;
					message2 = message2Default;
				} else {
					message1 = message1Default;
					message2 = message2Default;
				}
			} else {
				message1 = message1Default;
				message2 = error?.data?.response?.failure[0]?.errorMessage
					? error?.data?.response?.failure[0]?.errorMessage
					: message2Default;
			}

			// logger.log('[RecallAction] - onHandleTicketAction - message1:%o, message2:%o', message1, message2);

			if (this.props.onSetResponse) this.props.onSetResponse(true);

			this.props.showModal({
				showModal: false,
				showCloseBtn: false,
				type: 'error',
				title: `${which} Error`,
				message: `${message1} ${message2}`,
				useTimer: false,
			});

			// show confirmation table
			this.setState({ showConfirmationModal: true, isError: `${message1} ${message2}` });
		} else {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Recall Ticket Sumbit',
					args: 'Successful',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall('Recall Ticket Sumbit Successful');
			}

			if (this.props.onSetResponse) this.props.onSetResponse(true);

			if (this.props.onSetActionSubmitted) this.props.onSetActionSubmitted();

			// // refresh the list
			// this.props.onRefreshTicketData();

			setTimeout(() => {
				this.props.showModal({
					showModal: false, // false because we have custom modal to show, but still need to update the status type to success otherwise the updating modal will continue showing
					showCloseBtn: false,
					type: 'success',
					title: `${which} Success`,
					message: `${msg || ''} ${msg2 || ''}`,
					useTimer: false,
				});

				// show confirmation table
				this.setState({ showConfirmationModal: true, isError: false });
			}, 1000);
		}
	}

	render() {
		const { fullTicketValue, callTicketActions, closeModal, ticketStubToRender } = this.props;

		let ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];
		const spectatorId = ticketValue[0]?.spectatorId || '';

		let transferredRecipientEmail = ticketValue[0]?.source?.transfer?.toEmail || '';

		return (
			<div>
				{!this.state.showConfirmationModal ? (
					<div>
						<div className="ticket-action-modal-information-text">
							<span
								tabIndex={0}
								aria-label={`Are you sure you want to cancel the transfer to ${transferredRecipientEmail}`}>
								Are you sure you want to cancel the transfer to <b>{transferredRecipientEmail}</b>?
							</span>
						</div>
						<div className="ticket-action-modal-ticket-stub ticket-action-modal-section">
							{ticketStubToRender}
						</div>
						<div className="ticket-stub-actions-btns-cntr">
							<button
								role="button"
								className={classNames('ticket-stub-actions-value', 'ticket-action-modal-button-green')}
								onClick={() => {
									this.props.showModal({
										showModal: true,
										showCloseBtn: false,
										type: 'updating',
										title: 'Cancel in progress',
										message: `Please wait while the ticket transfer is cancelled: ${transferredRecipientEmail.bold()}`,
										useTimer: false,
									});
									handleCancelTicketTransfer(
										spectatorId,
										ticketValue,
										callTicketActions,
										this.onHandleTicketAction,
										`The ticket transfer has been cancelled to ${transferredRecipientEmail.bold()}`
									);
								}}
								type="submit">
								Cancel Transfer
							</button>
							<button role="button" className="ticket-stub-actions-value" onClick={() => closeModal()}>
								Cancel
							</button>
						</div>
					</div>
				) : (
					<CustomActionConfirmation
						fullTicketValue={ticketValue}
						recipientEmail={transferredRecipientEmail}
						closeModal={closeModal}
						ticketData={this.props.ticketData}
						onRenderMultipleTicketStubs={(data, error, email) =>
							this.props.onRenderMultipleTicketStubs(data, error, email)
						}
						onRenderSingleTicketStub={(data, error, email) =>
							this.props.onRenderSingleTicketStub(data, error, email)
						}
						headerTxt={
							this.state.isError ? (
								<div>{fullTicketValue.length > 1 ? 'Your ticket(s) were not cancelled' : null}</div>
							) : (
								'The transfer was cancelled.'
							)
						}
						detailsTxt={
							this.state.isError && (!fullTicketValue.length || fullTicketValue.length === 1) ? (
								<div style={{ color: 'black', textAlign: 'center' }}>
									Your ticket to <b>{transferredRecipientEmail}</b> was not cancelled.<br></br>
									{this.state.isError}
								</div>
							) : (
								<div className="ticket-action-modal-email-label">
									Recipient email address:
									<span className="ticket-action-modal-email">{this.state.email}</span>
								</div>
							)
						}
						actionType={'Cancel'}
						customActionUpdateTxt="Transfer was cancelled for the following ticket"
						error={this.state.isError}
						isWebView={this.props.isWebView}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				)}
			</div>
		);
	}
}
export default RecallAction;

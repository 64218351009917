import React from 'react';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import Button from 'appdir/components/common-ui/Button';

const Modal = ({ children, toggleModal, otherCallback }) => {
	return (
		<div className="modal-overlay">
			<div className="modal-wrapper" style={{ maxWidth: '50vw' }}>
				<div className="modal-container my-wimbledon">
					<CloseBtn closeFn={toggleModal} />
					<div className="modal-content">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;

/**
 TopStats Initial State
*/

export default {
	filters: {
		selected: 'winners_no_lose_set',
		title: 'Winners without losing a set',
		open: '',
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import {
	FormInput,
	FormEmail,
	FormTextarea,
	FormCheckBox,
	FormRadioButton,
	FormSelectPlus,
} from 'appdir/components/common-ui/FormFields';
import Button from 'appdir/components/common-ui/Button';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const ApplicantAccreditationForm = (props) => {
 * -----------------------------------------------------------------------------
 */

const ApplicantAccreditationForm = props => {
	// logger.log('[ApplicantAccreditationForm] props:%o', props);

	let { values } = props.formProps;

	const displayITWA = () => {
		if (
			values.tennisWriter == true ||
			values.sportsReporter == true ||
			values.featureWriter == true ||
			values.onlineReporterSport == true
		) {
			return true;
		} else {
			return false;
		}
	};

	const displayITPA = () => {
		if (values.photographer == true) {
			return true;
		} else {
			return false;
		}
	};

	const displayOtherQuestions = () => {
		if (
			values.tennisWriter == true ||
			values.newsReporter == true ||
			values.sportsReporter == true ||
			values.featureWriter == true ||
			values.onlineReporterSport == true ||
			values.onlineReporterNews == true
		) {
			return true;
		} else {
			return false;
		}
	};

	let { type } = props.formProps.values;

	return (
		<div className="column-layout">
			<div className="four-col page-title">
				<div className="title-header">APPLICANT ACCREDITATION</div>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<span className="two-col no-margin left">
					<FormInput label="First Name" name="applicantFirstName" type="text" required showerror="true" />
				</span>
				<span className="two-col">
					<FormInput label="Last Name" name="applicantLastName" type="text" required showerror="true" />
				</span>
			</div>
			<div className="two-col margin-col">
				<FormSelectPlus
					label="Organisation Name"
					className="react-select-container"
					classNamePrefix="react-select"
					name="applicantOrganisationName"
					options={props.organisationList}
					multi={false}
					onChange={option => props.formProps.setFieldValue('applicantOrganisationName', option.value)}
				/>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<span className="two-col no-margin left">
					<FormEmail label="Email" name="applicantEmail" type="email" required showerror="true" />
				</span>
				<span className="two-col">
					<FormInput label="Mobile Phone" name="applicantMobilePhone" type="text" required showerror="true" />
				</span>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<div className="four-col clear-two clear-four media-form-header">Photos</div>
				<div className="four-col clear-two clear-four">
					<b>Unless one has been provided in the last two years</b>, please email us a recent colour,
					passport-style JPEG photograph (on plain cream or light grey background) to{' '}
					<a href="mailto:press.operations@aeltc.com?subject=Media photo" target="_blank">
						press.operations@aeltc.com
					</a>
					, CLEARLY STATING THE APPLICANT’S NAME AND ORGANISATION IN THE SUBJECT LINE OF THE EMAIL.
				</div>
				<div className="four-col clear-two clear-four media-form-header">Passports</div>
				<div className="four-col clear-two clear-four">
					<b>Unless the applicant has been accredited in the last six years</b>, please securely email us a
					copy of the photo page of passport or EU national ID document (or UK photo driving licence) to{' '}
					<a href="mailto:press.operations@aeltc.com?subject=Media passport" target="_blank">
						press.operations@aeltc.com
					</a>
					, CLEARLY STATING THE APPLICANT’S NAME AND ORGANISATION IN THE SUBJECT LINE OF THE EMAIL.
				</div>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<FormTextarea
					label="Accessibility Needs - The AELTC endeavours to provide a high standard of accessible facilities for guests with visible and hidden disabilities."
					name="accessibilityNeeds"
					type="textarea"
					placeholder="Please provide any specific accessibility requirements. The AELTC will make reasonable adjustments, so far as practicable, to accommodate individuals' specific needs."
					stylename="midheight"
				/>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<div className="four-col no-margin left">
					<span className="two-col no-margin left small-gray-text">APPLICANT ROLES</span>
					<span className="two-col small-gray-text">ACCREDITED EVENTS IN 2022/2023</span>
				</div>
				<div className="four-col no-margin left right top bottom">
					<div className="two-col no-margin left">
						{type != 'regionalNewspaper' && type != 'photoAgency' && type != 'photoAgency' ? (
							<div className="four-col no-margin left">
								<FormCheckBox name="tennisWriter" id="tennisWriter">
									Tennis Writer
								</FormCheckBox>
							</div>
						) : null}
						{type != 'magazineTennis' &&
						type != 'magazineOther' &&
						type != 'photoAgency' &&
						type != 'website' ? (
							<div className="four-col no-margin left">
								<FormCheckBox name="newsReporter" id="newsReporter">
									News Reporter
								</FormCheckBox>
							</div>
						) : null}
						{type != 'magazineTennis' && type != 'photoAgency' && type != 'website' ? (
							<div className="four-col no-margin left">
								<FormCheckBox name="sportsReporter" id="sportsReporter">
									Sports Reporter
								</FormCheckBox>
							</div>
						) : null}
						{type != 'photoAgency' && type != 'website' ? (
							<div className="four-col no-margin left">
								<FormCheckBox name="featureWriter" id="featureWriter">
									Feature Writer
								</FormCheckBox>
							</div>
						) : null}
						{type != 'magazineTennis' && type != 'magazineOther' && type != 'photoAgency' ? (
							<div className="four-col no-margin left">
								<FormCheckBox name="onlineReporterSport" id="onlineReporterSport">
									Online Reporter (Sport)
								</FormCheckBox>
							</div>
						) : null}
						{type != 'magazineTennis' && type != 'magazineOther' && type != 'photoAgency' ? (
							<div className="four-col no-margin left">
								<FormCheckBox name="onlineReporterNews" id="onlineReporterNews">
									Online Reporter (News)
								</FormCheckBox>
							</div>
						) : null}

						<div className="four-col no-margin left">
							<FormCheckBox name="photographer" id="photographer">
								Photographer
							</FormCheckBox>
						</div>

						{type != 'regionalNewspaper' &&
						type != 'magazineTennis' &&
						type != 'magazineOther' &&
						type != 'website' ? (
							<div className="four-col no-margin left">
								<FormCheckBox name="mediaSupport" id="mediaSupport">
									Media Support
								</FormCheckBox>
							</div>
						) : null}
						{typeof props.formProps.errors.roles === 'string' ? (
							<div className="error">{props.formProps.errors.roles}</div>
						) : null}
					</div>
					<div className="two-col">
						<div className="four-col no-margin left">
							<FormCheckBox name="australianOpen" id="australianOpen">
								Australian Open
							</FormCheckBox>
						</div>
						<div className="four-col no-margin left">
							<FormCheckBox name="rolandGarros" id="rolandGarros">
								Roland-Garros
							</FormCheckBox>
						</div>
						<div className="four-col no-margin left">
							<FormCheckBox name="usOpen" id="usOpen">
								US Open
							</FormCheckBox>
						</div>
						<div className="four-col no-margin left">
							<FormCheckBox name="wtaATP1000" id="wtaATP1000">
								WTA/ATP 1000
							</FormCheckBox>
						</div>
						<div className="four-col no-margin left">
							<FormCheckBox name="davisCup" id="davisCup">
								Davis Cup
							</FormCheckBox>
						</div>
						<div className="four-col no-margin left">
							<FormCheckBox name="billieJean" id="billieJean">
								Billie Jean King Cup
							</FormCheckBox>
						</div>
						{typeof props.formProps.errors.events === 'string' ? (
							<div className="error">{props.formProps.errors.events}</div>
						) : null}
					</div>
				</div>
			</div>
			{displayITWA() ? (
				<>
					<div className="two-col margin-col clear-two clear-four">Is this applicant a member of ITWA?</div>
					<div className="two-col margin-col clear-two clear-four">
						<span className="first">
							<FormRadioButton name="memberITWA" value="Yes">
								Yes
							</FormRadioButton>
						</span>
						<span>
							<FormRadioButton name="memberITWA" value="No">
								No
							</FormRadioButton>
						</span>
					</div>
				</>
			) : null}
			{displayITPA() ? (
				<>
					<div className="two-col margin-col clear-two clear-four">Is this applicant a member of ITPA?</div>
					<div className="two-col margin-col clear-two clear-four">
						<span className="first">
							<FormRadioButton name="memberITPA" value="Yes">
								Yes
							</FormRadioButton>
						</span>
						<span>
							<FormRadioButton name="memberITPA" value="No">
								No
							</FormRadioButton>
						</span>
					</div>
				</>
			) : null}
			{displayOtherQuestions() ? (
				<div className="two-col margin-col clear-two clear-four media-form-header">
					Additional Media Outlets
				</div>
			) : null}
			{displayOtherQuestions() ? (
				<>
					<div className="two-col margin-col clear-two clear-four">
						Does the applicant intend to do any radio broadcasting?
					</div>
					<div className="two-col margin-col clear-two clear-four">
						<span className="first">
							<FormRadioButton name="radioBroadcast" value="Yes">
								Yes
							</FormRadioButton>
						</span>
						<span>
							<FormRadioButton name="radioBroadcast" value="No">
								No
							</FormRadioButton>
						</span>
					</div>
				</>
			) : null}
			{displayOtherQuestions() && values.radioBroadcast == 'Yes' ? (
				<>
					<div className="two-col margin-col clear-two clear-four">
						<FormTextarea
							label=""
							name="radioDetails"
							type="textarea"
							placeholder="If yes, please provide details"
						/>
					</div>
				</>
			) : null}
			{displayOtherQuestions() ? (
				<>
					<div className="two-col margin-col clear-two clear-four">
						Does the applicant intend to do any podcasting?
					</div>
					<div className="two-col margin-col clear-two clear-four">
						<span className="first">
							<FormRadioButton name="podcast" value="Yes">
								Yes
							</FormRadioButton>
						</span>
						<span>
							<FormRadioButton name="podcast" value="No">
								No
							</FormRadioButton>
						</span>
					</div>
				</>
			) : null}
			{displayOtherQuestions() && values.podcast == 'Yes' ? (
				<>
					<div className="two-col margin-col clear-two clear-four">
						<FormTextarea
							label=""
							name="podcastDetails"
							type="textarea"
							placeholder="If yes, please provide details"
						/>
					</div>
				</>
			) : null}
			<div className="two-col margin-col clear-two clear-four">
				<span className="hospitality-btns">
					<Button className="media-btn" onClick={props.goBack}>
						&lt; Back
					</Button>
					<Button
						className={`media-btn long solid${props.formProps.isSubmitting ? ' disabled' : ''}`}
						onClick={props.formProps.submitForm}>
						Save &amp; Continue {'>'}
					</Button>
				</span>
			</div>
		</div>
	);
};

export default ApplicantAccreditationForm;

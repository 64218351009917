/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { createRef, useState, useEffect, useRef } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import { isArray } from 'underscore';
// import { useSelector } from 'react-redux';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const QuickLinks = (props) => {
 * -----------------------------------------------------------------------------
 */

const QuickLinks = (props) => {
	logger.log('[QuickLinks] props:%o', props);
	// const quickLinksRef = createRef();
	let items = props.data;
	if(!isArray(items)){
		items = [items];
	}


	return (
		<div className='quick-links column-layout'>
				{items.map((i, idx) => {
					return(
						<div key={`quickLink-${idx}`} className={`one-col clear-two clear-four ${idx%2 == 0 ? `left` : `right`}`}>
							<WimLink to={i.url}>
								{/* TODO - replace with actual icons */}
								<span className='link-icon'><i className={`wim-icon-${i.icon}`} /></span>								
								{/* <span className='link-icon'><i className="wim-icon-check" /></span> */}
								<span className='link-text'>{i.text}</span>
							</WimLink>
						</div>
					);
				})}
		</div>
	);
};

export default QuickLinks;

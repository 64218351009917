/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import TabNav from 'appdir/components/common-ui/TabNav';

const mapStateToProps = (state, props) => {
	return {
		...state['ScoresTabs'],
		liveIndicators: state['ActiveData']['liveIndicators'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class ScoresTabs extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let tabs = [
			{
				name: 'Scores',
				value: '/en_GB/scores/index.html',
				liveIndicator: true,
				isLive: op.get(this.props, 'liveIndicators.scores', false),
			},
			{
				name: 'Results',
				value: '/en_GB/scores/results/index.html',
				match: /\/en_GB\/scores\/results\/[A-Za-z0-9]+\.html/i,
			},
			{
				name: 'Upcoming',
				value: '/en_GB/scores/upcoming/index.html',
			},
		];

		return (
			<div className="navItemPad">
				<TabNav data={{ key: 'liveScores', default: 'Live', classes: 'white-text', tabs }} />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoresTabs);

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import { getCleanLink, getAppLink } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class WimRedirect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.link = {
			url: '',
			external: false,
		};

		if (this.state.to) {
			if (values.webview) {
				this.link = getAppLink(this.state.to, this.state.title ? this.state.title : '');

				if (!this.link.external) {
					document.location.href = this.link.url;
				}
			} else {
				this.link = {
					url: this.state.to,
					external: false,
				};
			}
		}

		logger.log('[WimRedirect] constructor - link:%o', this.link);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[Login] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidMount() {
		//logger.log('[WimRedirect] componentDidMount - state:%o props:%o', this.state, this.props);
	}

	render() {
		//logger.log('[WimRedirect] render - state:%o', this.state);

		// logger.log('[Router] updateRoute - router:%o pathname:%o', Router.pathname, location.pathname);
		// if (Router.pathname && Router.pathname.indexOf('/webview') == 0 && location.pathname.indexOf('/webview') != 0) {
		//  	location.pathname = '/webview'.concat(location.pathname);
		// }

		return <Redirect push to={this.link.url} />;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WimRedirect);

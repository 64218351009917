import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: day => (dispatch, getState, store) => {
		logger.log('[OrderOfPlay] mount day:%o', day);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Upcoming Matches',
		});
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				// dataPath: Config.scoringData.schedule,
				flagImagePath: Config.otherData.flagImagePathSmall,
				playerProfileImagePath: Config.otherData.playerProfileImagePath,
				allMatchesPlayed: 'init',
			};

			dispatch({ type: deps.actionTypes.UPCOMING_MOUNT, data: data });
		});
	},
	allMatchesPlayed: data => dispatch => {
		dispatch({ type: deps.actionTypes.UPCOMING_ALLMATCHES_PLAYED, data: { allMatchesPlayed: data } });
	},
	unmount: () => (dispatch, getState, store) => {
		// when unloading the mip page, reset Scoring data in the redux state
		// also reset the status of the page to null
		// I think this will change later.  May not need to reset scoring data in all cases.
		// Cannot reset Scoring data in Controller, because Controller puts everything in localstorage and
		// we don't want to do that.
		let status = null;
		dispatch({ type: deps.actionTypes.UPCOMING_UNMOUNT, data: { status: status } });
	},
};

import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class Extra extends Component {
	
	constructor(props) {
		super(props);
		
		this.styles = props.styles;
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('Extra: componentDidUpdate - state:%o', this.state);
		
	}

	onButtonClick(e) {
		//logger.log('Extra: onButtonClick - e:%o', e.currentTarget);

		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	
	render() {
		//logger.log('Extra: render - state:%o', this.props);
		let inline = {};
		
		if(this.props.first) {
			inline = {
				margin: "0 0 0 10px"
			}
		}
		
		return (
			<button 
				className={`${this.styles.icon} ${this.styles.extra}`} 
				style={inline} 
				onClick={(e) => this.onButtonClick(e)} 
				title="Extra">
			</button>
		)
	}
	
}

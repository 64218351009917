import React from 'react';
import WimLink from 'appdir/components/general/WimLink';

const DebentureItem = props => {
	return (
		<WimLink to={props.link}>
			<div className="service-container">
				<img className="service-img" src={props.src} alt="debenture service image" />
				<div className="service-title">{props.title}</div>
			</div>
		</WimLink>
	);
};
export default DebentureItem;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetchContent = (path, jwt_token) => {
	let hdr = restHeaders();
	//logger.log('[ContentPageSecure] fetchContent - path:%o uid:%o', path, uid, sig, timestamp);

	return axios
		.post(
			path,
			{
				jwtToken: jwt_token,
			},
			{ headers: hdr }
		)
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			//logger.log('[ContentPageSecure] fetchContent - error:%o', error);
			if (error.response) {
				//logger.log('[ContentPageSecure] fetchContent - error1:%o', error.response);
				throw error.response;
			} else {
				//logger.log('[ContentPageSecure] fetchContent - error2:%o resp:%o', error, error.response);
				throw error;
			}
		});
};

export default {
	fetchContent,
};

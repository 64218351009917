import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = path => {
	let hdr = restHeaders();
	// perform an ajax call
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

export default {
	fetch,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import concat from 'lodash/concat';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['NewsIndex'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.NewsIndex.filter(settings)),
});

class TimeSelect extends Component {
	constructor(props) {
		super(props);

		let days = props.days.map(item => ({ name: item.name, value: item.id }));
		days.sort(function(a, b) {
			return b.value - a.value;
		});

		this.state = {
			champDays: days,
			years: props.years,
			filters: props.filters,
		};

		this.setYear = this.setYear.bind(this);
		this.setChampDay = this.setChampDay.bind(this);
		this.onOpen = this.onOpen.bind(this);

		logger.log('[TimeSelect] props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[TimeSelect] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});

		//logger.log('[NewsIndex] componentWillReceiveProps - status:%o state:%o next:%o', nextProps.status, this.state.year, nextProps.year);
	}

	setYear(year) {
		//logger.log('[TimeSelect] setYear:%o', year);

		this.props.filter({
			year: year,
			open: '',
		});
	}

	setChampDay(day) {
		//logger.log('[TimeSelect] setChampDay:%o', day);
		let dayVal = day !== 'all' ? 'Day' + day : '';

		MeasurementUtils.dispatchMeasurementCall('dayPicker', {
			yearSelected: this.props.filters.year,
			selectedDay: day,
			category: this.props.filters.type,
		});

		this.props.filter({
			day: day,
			open: '',
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		logger.log('[TimeSelect] render - state:%o', this.state);

		// set data for select menus
		let archiveData = {
			options: [
				{ name: 'Latest', value: 'current' },
				{ name: 'ARCHIVES', value: 'section' },
			],
		};

		let champsData = {
			options: [
				{ name: 'All Days', value: 'all' },
				{ name: 'Championships', value: 'section' },
			],
		};

		// add available years to base archive menu options
		this.state.years.forEach(item => {
			archiveData.options.push({ name: item, value: item });
		});

		// add available days to base champ days menu options
		champsData.options = concat(champsData.options, this.state.champDays);

		return (
			<div className="news-landing-time-selector latest">
				<div className="archive-dropdown">
					<SelectMenu
						name="year"
						attributes={archiveData}
						selected={this.state.filters.year}
						show={this.state.filters.open == 'year'}
						onSelected={this.setYear}
						onOpen={this.onOpen}
					/>
				</div>
				<div className="separator" />
				<div className="champs-dropdown">
					<SelectMenu
						name="champs"
						attributes={champsData}
						selected={this.state.filters.day}
						show={this.state.filters.open == 'champs'}
						onSelected={this.setChampDay}
						onOpen={this.onOpen}
					/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSelect);

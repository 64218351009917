import React from 'react';
import WimLink from '../WimLink';

const UserRoles = ({ icons }) => {
	return (
		<div className="status-roles-wrapper">
			{icons.map(({ name, path, target, svg: Element }) => {
				return (
					<React.Fragment key={path}>
						<WimLink to={path} title={name} target={target} style="white">
							<div className="status-role-item" aria-hidden role="none">
								<Element strokeWidth={2} />
								<span aria-hidden role="none" className="elipsis">
									{name}
								</span>
							</div>
						</WimLink>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default UserRoles;

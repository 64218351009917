import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Time from 'appdir/components/common-ui/Time';
import SingleTicketStub from '../elements/TicketActionViews/SingleTicketStub';
import WimLink from 'appdir/components/general/WimLink';

class DesktopTicketStub extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ticketActionsDropdownOpen: false,
			ticketModalOpen: false,
			openModalTitle: '',
		};
		this.onHandleBodyClick = this.onHandleBodyClick.bind(this);
	}

	componentDidMount() {
		document.body.addEventListener('click', this.onHandleBodyClick);
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.onHandleBodyClick);
	}

	/* Needed in order to close dropdown actions menu if anywhere else on page is clicked */
	onHandleBodyClick(event) {
		const { id } = event.target;
		// only call if the dropdown is open and if it is not a action button that was clicked
		if (
			this.state.ticketActionsDropdownOpen &&
			!id.includes(`web-ticket-stub-actions-button${this.props.fullTicketValue.externalId}`) &&
			id !== 'show' &&
			id !== 'transfer' &&
			id !== 'recall' &&
			id !== 'return'
		) {
			this.onActionsClick();
		}
	}

	onTicketStubClick(id) {
		const { ticketActionsSelected } = this.state;
		const ticketSelectedVal = id === ticketActionsSelected ? '' : id;

		this.setState({ ticketActionsSelected: ticketSelectedVal });
	}

	onRenderTransferTicketStub(ticket, court, type, gangway, row, seat) {
		return (
			<SingleTicketStub
				middleContents={{
					court: court,
					type: type,
					gangway: gangway || '',
					row: row || '',
					seat: seat || '',
				}}
				imageToRender={this.props.imageToRender}
				id={ticket?.externalId}
				date={ticket?.day !== 'UGP' ? `${ticket?.date} Day ${ticket?.day}` : ''}
			/>
		);

		/* NOTE: keep old code just in case we have to switch back */
		// return (
		// 	<div className="web-ticket-stub-transfer-tix-cntr">
		// 		<div className="web-ticket-stub-transfer-tix-txt">{court}</div>
		// 		<div className="web-ticket-stub-transfer-tix-txt">
		// 			{fullTicketValue.date ? fullTicketValue.date : ''}
		// 		</div>
		// 		<div className="web-ticket-stub-transfer-tix-txt-group">
		// 			<span>{gangway}</span>
		// 			<span>{row}</span>
		// 			<span>{seat}</span>
		// 		</div>
		// 		<div className="web-ticket-stub-transfer-tix-txt msg">
		// 			{tixMsg ? (
		// 				<div>
		// 					<span className="web-ticket-stub-memb-guest">{type}</span>
		// 					<span className="web-ticket-stub-memb-guest msg">{tixMsg}</span>
		// 				</div>
		// 			) : (
		// 				type
		// 			)}
		// 		</div>
		// 	</div>
		// );
	}

	actionsDropdown(d) {
		const actionsArr = [
			{
				label: 'Show Full Ticket',
				value: 'show',
			},
			{
				label: 'Transfer Ticket',
				value: 'transfer',
			},
			{
				label: 'Cancel Transfer',
				value: 'recall',
			},
			{
				label: 'Return to Sender',
				value: 'return',
			},
		];

		return (
			<div className="web-ticket-stub-actions-dropdown-container">
				<div className="web-ticket-stub-actions-dropdown">
					{actionsArr.map((val, index) => {
						return (
							<button
								className="web-ticket-dropdown-button"
								id={val.value}
								key={index}
								disabled={
									(d?.status?.filtervalue?.toLowerCase() === 'normal' ||
										d?.status?.filtervalue?.toLowerCase() === 'received') &&
									d?.actions[0]?.toLowerCase() !== 'none-controlled'
										? !d?.actions?.concat('show').includes(val.value)
										: !d?.actions?.includes(val.value)
								}
								onClick={() => {
									if (event.target.id === 'show') {
										this.setState({
											ticketActionsDropdownOpen: false,
										});
										this.props.onDisplayPromoPage();
									} else {
										this.setState({
											ticketActionsDropdownOpen: false,
											ticketModalOpen: true,
											openModalTitle: val.value,
										});
									}
									this.props.onActionsModalDisplay(true);
								}}>
								{val.label}
							</button>
						);
					})}
				</div>
			</div>
		);
	}

	onActionsClick() {
		this.setState({ ticketActionsDropdownOpen: !this.state.ticketActionsDropdownOpen });
	}

	render() {
		const { id, fullTicketValue, index, middleContents, tixMsg, imageToRender } = this.props;

		const { ticketActionsSelected } = this.state;

		const { court, type, gangway, row, seat, status } = middleContents;

		let label = fullTicketValue.message
			? `${fullTicketValue.typeLabel} - ${fullTicketValue.message}`
			: fullTicketValue.typeLabel;

		logger.info('[DesktopTicketStub] render - id:%o status:%o', id, status);

		return (
			<div>
				<div
					className="web-ticket-stub-parent-container row row-content"
					key={`${id}${index}-ticket-stub-parent-container`}>
					<div className={'web-ticket-stub-container'}>
						<div className={classNames('ticket-stub-shape-img-border', 'web-ticket-stub-shape-container')}>
							<div className="web-ticket-stub-table-rows">
								<div className="web-ticket-stub-row-cell-image">
									<div className="web-ticket-stub-image-container">
										<img className="web-ticket-stub-image" src={imageToRender} />
									</div>
								</div>
								{fullTicketValue.testTicket ? (
									<div className="test-ticket-label">test ticket</div>
								) : null}
								<div className="web-ticket-stub-row-cell-header web-ticket-stub-row-cell-small">
									{court.toLowerCase()}
								</div>
								<div className="web-ticket-stub-row-cell-small">
									{fullTicketValue.date && fullTicketValue?.day !== 'UGP' ? fullTicketValue.date : ''}
								</div>
								<div
									className={
										!gangway && !row && !seat
											? 'web-ticket-stub-row-cell-med web-ticket-stub-cell-min-height'
											: 'web-ticket-stub-row-cell-med'
									}>
									{' '}
									{/*needs to be 1px when empty otherwise will mess up desktop css responsiveness*/}
									<div className="web-ticket-stub-row-cell-med-cntr">
										<span className="web-ticket-stub-row-cell-med-inner">{gangway}&nbsp;</span>
										<span className="web-ticket-stub-row-cell-med-inner">{row}&nbsp;</span>
										<span className="web-ticket-stub-row-cell-med-inner">{seat}&nbsp;</span>
									</div>
								</div>
								{/* label type */}
								<div className="web-ticket-stub-row-cell web-ticket-stub-row-cell-margin">
									<span className="web-ticket-stub-row-cell-val">
										{fullTicketValue.message ? (
											<div>
												<span className="web-ticket-stub-memb-guest">
													{label.split('-')[0]}
												</span>
												<span className="web-ticket-stub-memb-guest msg">
													{label.split('-')[1]}
												</span>
											</div>
										) : (
											<span className="web-ticket-stub-type">{label}</span>
										)}{' '}
									</span>
								</div>
								{/* status */}
								<div className="web-ticket-stub-row-cell-large">
									<span className="web-ticket-stub-row-cell-val">
										<span>{status?.value || ''}</span>{' '}
										<span style={{ fontWeight: 'normal' }}>{status?.detail || ''}</span>
									</span>
								</div>
								<div className="web-ticket-stub-row-cell-xs">
									{status?.filtervalue?.toLowerCase() !== 'normal' &&
									status?.date ? (
										<Time epoch_ms={moment(status.date).valueOf()} format="ddd DD MMM kk:mm z" />
									) : (
										''
									)}
								</div>
								<div className="web-ticket-stub-row-cell-actions">
									<img className="web-ticket-stub-img-style" />
									<button
										className="web-ticket-stub-actions-button"
										id={`web-ticket-stub-actions-button${id}`}
										onClick={() => {
											this.onActionsClick();
											this.onTicketStubClick(id);
										}}
									/>
								</div>
							</div>
						</div>
						{this.props.debugView ? (
							<div className="ticketview-ticketstub-details-id">
								<div>
									<strong>SpecId:</strong> {fullTicketValue.spectatorId}
								</div>
								<div>
									<strong>ID:</strong> {fullTicketValue.source.id}
								</div>
								<div>
									<strong>CATEGORY:</strong> {fullTicketValue.detailedInformation.category}{' '}
								</div>
								<div>
									<strong>ACTIONS:</strong> {fullTicketValue.actions.join(', ')}
								</div>
							</div>
						) : null}
					</div>
				</div>
				{this.state.ticketActionsDropdownOpen ? this.actionsDropdown(fullTicketValue) : null}
				{this.state.ticketModalOpen && ticketActionsSelected === id
					? this.props.openActionModal(
							fullTicketValue,
							this.state.openModalTitle,
							this.onRenderTransferTicketStub(fullTicketValue, court, type, gangway, row, seat, tixMsg),
							() => {
								this.setState({
									ticketActionsDropdownOpen: false,
									ticketActionsSelected: '',
									ticketModalOpen: false,
									openModalTitle: '',
								});
								this.onTicketStubClick(id);
							}
					  )
					: null}
			</div>
		);
	}
}

export default DesktopTicketStub;

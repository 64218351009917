/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values } from 'appdir/main';
import deps from 'dependencies';
import op from 'object-path';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Favorites from 'appdir/components/common-ui/Favorites';
import MatchBox from 'appdir/components/scoring/MatchBox';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import StubBox from 'appdir/components/common-ui/StubBox';
import LiveNav from 'appdir/components/general/LiveNav';
import SearchBox from 'appdir/components/common-ui/SearchBox';
import ScoresTabs from './ScoresTabs';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveScores
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveScores'],
		ScoreManager: state['ScoreManager'],
		controllerLoaded: state['Controller'].loaded,
		stubs: state['Config'].stubPages,
		text: state['Config'].text,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveScores.mount()),
	unmount: () => dispatch(deps.actions.LiveScores.unmount()),
	setScoreManagerStatus: data => dispatch(deps.actions.ScoreManager.setScoreManagerStatus(data)),
});

// renders navigation list - pass label, URL and specify the one that should be selected
const liveNavList = [
	{ name: 'Live Scores', url: '/en_GB/scores/index.html', selected: true },
	{ name: 'Live Commentary', url: '/en_GB/scores/live_commentary.html' },
];

class LiveScores extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
		this.init = true;
		this.onEnter = this.onEnter.bind(this);
	}

	componentDidUpdate(prevState) {
		if (
			(this.init &&
				this.props.status == 'load' &&
				this.props.controllerLoaded &&
				op.get(this.props, 'stubs.scores.stub', false) !== 'stub') ||
			!op.get(this.props, 'ScoreManager.status.mip', false)
		) {
			// logger.log('[LiveScores] componentDidUpdate init is true');
			this.init = false;
			this.props.setScoreManagerStatus({ mip: true });
		}
	}

	componentWillUnmount() {
		// sending mip:true tells controller that we are turning mip page off
		this.props.setScoreManagerStatus({ mip: false });
		this.props.unmount();
	}

	renderMatch(match) {
		// logger.log('[LiveScores] renderMatch - match:%o', match);
		if (match.scores) {
			return (
				<div key={match.match_id} className="one-col">
					<MatchBox
						key={`mb_${match.match_id}`}
						attributes={{
							data: match,
							style: 'live',
							event: match.shortEventName,
							tableHeader: match.courtName,
							showLinks: true,
							searchedPlayer: this.state.searchedPlayer,
						}}
					/>
				</div>
			);
		} else {
			return null;
		}
	}

	onEnter(data) {
		this.setState({ searchedPlayer: data.tour_id });
	}

	getHeaderNav(isStub) {
		return (
			<div className="column-layout matchexp no-padding">
				<div className="header">
					<ScoresTabs />
				</div>
				{!isStub ? (
					<div className="header narrow">
						<div className="navItem">
							<SearchBox onEnter={this.onEnter} />
						</div>
						<div className="navItemFixed">
							<Favorites />
						</div>
					</div>
				) : null}
			</div>
		);
	}

	render() {
		// logger.log('[LiveScores] render - state:%o', this.props);

		let pageDescription = this.props?.text?.descriptions?.scores || '';
		pageDescription = pageDescription.replace('<year>', values.tournamentYear);

		let header_propsData = {
			headerType: 'scores',
			title: 'Live Scores',
			shortTitle: 'Live Scores',
			metaTitle: 'Live Scores',
			metaDescription: pageDescription,
			metaDate: '',
			metaPlayers: '',
		};
		let { liveMatches } = this.props.ScoreManager;
		// logger.log('[LiveScores] render - liveMatches:%o', liveMatches);
		if (this.props.stubs && this.props.stubs.scores.stub === 'stub') {
			// logger.log('[LiveScores] render - stub');
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						{this.getHeaderNav(true)}
						<StubBox attributes={{ message: this.props.stubs.scores.text, style: 'centerV' }} />
					</div>
					<Footer />
				</Template>
			);
		} else if (this.state.hasError) {
			// logger.log('[LiveScores] render - hasError');
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						{this.getHeaderNav()}
						<div className="column-layout scores-nav no-padding">
							<GenericError message="Live scores are unavailable at this time." />
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else if (liveMatches && liveMatches.length > 0) {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<ErrorBoundary message="Live Scores are unavailable at this time.">
							{this.getHeaderNav()}
							<div className="column-layout scoresgrid">
								{liveMatches.map((item, index) => {
									return this.renderMatch(item, index);
								})}
							</div>
						</ErrorBoundary>
					</div>
					<Footer />
				</Template>
			);
		} else if (liveMatches && liveMatches.length == 0) {
			if (this.props.stubs) {
				// logger.log('[LiveScores] render - no live matches');
				return (
					<Template className="scorespage">
						<div className="scores-background" />
						<Header attributes={header_propsData} />

						<PageHeader attributes={header_propsData} />
						<div className="content-main">
							<ErrorBoundary message="Live Scores are unavailable at this time.">
								{this.getHeaderNav()}
								<StubBox
									attributes={{ message: this.props.stubs.scores.nomatches, style: 'centerV' }}
								/>
							</ErrorBoundary>
						</div>
						<Footer />
					</Template>
				);
			} else {
				return null;
			}
		} else {
			// logger.log('[LiveScores] render - loading');
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						{this.getHeaderNav()}
						<LoadingIndicator type="white" />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveScores);

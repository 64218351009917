/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import ChampionshipsRegistration from 'appdir/components/common-ui/ChampionshipsRegistration';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

/**
 * -----------------------------------------------------------------------------
 * React Component: Template
 * -----------------------------------------------------------------------------
 */

export default class Template extends Component {
	constructor(props) {
		super(props);

		this.mb = null;
		this.navbar = null;
		this.header = null;
		this.ival = null;
		this.state = {
			...this.props,
		};
		this.onLoad = this.onLoad.bind(this);
		this.onScroll = this.onScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('load', this.onLoad);
		//window.addEventListener("scroll", this.onScroll);

		// Update the body class when the mouse is being used
		document.body.addEventListener('mousedown', function() {
			document.body.classList.add('using-mouse');
		});

		// Re-enable focus styling when Tab is pressed
		// accessibility keyboard navigation
		document.body.addEventListener('keydown', function(event) {
			if (event.keyCode === 9) {
				document.body.classList.remove('using-mouse');
			}
		});
	}

	componentWillUnmount() {
		// clearInterval(this.ival);
		// this.ival = null;
		// window.removeEventListener("scroll", this.onScroll);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[Template] componentWillReceiveProps - nextProps:%o', nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	onLoad() {
		window.templateMounted = true;
	}

	onScroll(e) {}

	render() {
		//logger.log('[Template] render - state:%o', this.state);
		let { mounted, bodyClass, navbarFixed, headerMarginBottom, children, keywords, header = {} } = this.state;

		let className = this.state.className != undefined ? this.state.className : '';
		//logger.log('[Template] render - className:%o', className);

		//let headerStyle = { marginBottom: headerMarginBottom };

		// if (mounted !== true) {
		//     return (
		//         null
		//     );
		// }

		return (
			<Fragment>
				<div className="page" role="main" alt="template">
					<ErrorBoundary message="template" showMessage={true}>
						<ChampionshipsRegistration type={'popup'} />
						{this.state.id ? (
							<section id={`${this.state.id}`} className={`wrapper ${className}`}>
								{children}
							</section>
						) : (
							<section className={`wrapper ${className}`}>{children}</section>
						)}
					</ErrorBoundary>
				</div>
			</Fragment>
		);
	}
}

Template.defaultProps = {
	header: {},
	mounted: false,
	navbarFixed: false,
	headerMarginBottom: 0,
	bodyClass: '',
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */

import React from 'react';
import TennisScoringData from 'tennis-scoring-react/src';

/**
 * -----------------------------------------------------------------------------
 * React Component: Tennis Scoring Data Wrapper
 * -----------------------------------------------------------------------------
 */

const TennisScoringDataWrapper = props => {
	return (
		<TennisScoringData
			balance={props.balance}
			mqttBalance={props.mqttBalance}
			messageSightHostname={props.messageSightHostname}
			edgeConnectHostname={props.edgeConnectHostname}
			tokenCreator={props.tokenCreator}
			mqttPort={props.mqttPort}
			mqttEvent={props.mqttEvent}
			androidMode={props.androidMode}
			scoresPollingFile={props.scoresPollingFile}
			statsFile={props.statsFile}
			cFile={props.cFile}
			uFile={props.uFile}
			pollingFrequency={props.pollingFrequency}
			retryAttempts={props.retryAttempts}
			scoresUpdated={props.scoresUpdated}
			statsUpdated={props.statsUpdated}
			historyUpdated={props.historyUpdated}
			connectionReady={props.connectionReady}
			tournamentYear={props.tournamentYear}
			anonymous={true}
		/>
	);
};

export default TennisScoringDataWrapper;


import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

function updateTime(timeStamp) {
   let clockStr = moment().tz('Europe/London').format('HH:mm:ss');

   timeStamp.innerHTML = clockStr;
}

// pass in timeElement (e.g., timeElement = document.getElementById("clock");)
export default function updateTimeIncrement(timeStamp) {
    setInterval(() => updateTime(timeStamp), 1000); // Run updateTime() every second
 }
import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';
import {formatSecs} from './Utils';
import Measure from 'react-measure';

const clickAreaBuffer = 3;
const CLASSNAME_OFFSET = 0;

export default class ProgressBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			toolTipPreview: {}
		}

		this.scrubbing = false;
		this.touchMove = false;
		this.toolbarOffset = 0;
		this.toolbarWidth = 0;
		this.toolTipStart;
		this.toolTipEnd;
		this.toolTipPreviewStart;
		this.toolTipPreviewEnd; 

		this.onTimeUpdate = this.onTimeUpdate.bind(this);
		//this.onMarkerMouseMove = this.onMarkerMouseMove.bind(this);
		this.onMarkerMouseDown = this.onMarkerMouseDown.bind(this);
		this.onWindowMouseUp = this.onWindowMouseUp.bind(this);
		this.onWindowMouseMove = this.onWindowMouseMove.bind(this);

		this.amp = this.props.amp;
		this.addAmpListeners();

		this.toolSize = 0;
		
		//logger.log('ProgressBar: constructor - state:%o ', this.state);
	}

	componentDidMount(){
		this.previewTracks = this.amp.getPreviewTracks();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//logger.log('ProgressBar: componentDidUpdate - amp:%o', this.props.amp);
		if (this.props.amp.multitonKey != prevProps.amp.multitonKey){
			this.amp = this.props.amp;
			this.addAmpListeners();
		}
	}

	addAmpListeners() {
		logger.log('ProgressBar: addAmpListeners - amp:%o', this.amp);
	
		//logger.log('ProgressBar: addAmpListeners - amp:%o ', this.amp);
		this.amp.addEventListener('timeupdate', this.onTimeUpdate);
		
	}

	formatTime(curTime, durTime) {
		let data_state = '';
		let curhours = Math.floor(curTime / 3600);
		let curmins = Math.floor((curTime - (curhours * 3600)) / 60);
		let cursecs = Math.floor(curTime - (curhours * 3600) - (curmins * 60));

		let durhours = Math.floor(durTime / 60 / 60);
		let durmins = Math.floor((durTime - (durhours * 3600)) / 60);
		let dursecs = Math.floor(durTime - (durhours * 3600) - (durmins * 60));

		//logger.info('formatTime - dur:%o hrs:%o mins:%o secs:%o', durTime, durhours, durmins, dursecs);

		if (cursecs < 10) { cursecs = "0" + cursecs; }
		if (dursecs < 10) { dursecs = "0" + dursecs; }
		if (curmins < 10) { curmins = "0" + curmins; }
		if (durmins < 10) { durmins = "0" + durmins; }

		curTime = '';
		durTime = '';

		if (curhours > 0 || durhours > 0) {
			durTime += (durhours > 0 ? durhours + ':' : '');

			if (curhours > 0) {
				curTime += curhours + ':';
			} else if (durhours != '') {
				curTime += '0:';
			}

			data_state = 'hours';
		}

		if (!isNaN(curmins) || !isNaN(durmins)) {
			curTime += ((!isNaN(curmins)) ? curmins : '00') + ':';
			durTime += ((!isNaN(durmins)) ? durmins : '00') + ':';
		}

		if (!isNaN(cursecs) || !isNaN(dursecs)) {
			curTime += ((!isNaN(cursecs)) ? cursecs : '00');
			durTime += ((!isNaN(dursecs)) ? dursecs : '00');
		}

		this.setState({
			time: curTime,
			duration: durTime,
			data_state: data_state
		});
	}

	onTimeUpdate(data) {
		//logger.log('ProgressBar: onTimeUpdate - data:%o time:%o dur:%o size:%o scrubbing:%o', data, this.amp.getCurrentTime(), this.amp.getDuration(), this.props.dimensions, this.scrubbing);
		// this.formatTime(this.amp.getCurrentTime(), this.amp.getDuration());
		this.toolbarOffset = this.props.dimensions.left;

		let time = this.amp.getCurrentTime();
		let duration = this.amp.getDuration();
		this.toolbarWidth = this.props.dimensions.width;
		let progressWidth = (time / duration) * this.toolbarWidth;
		//logger.log('ProgressBar: onTimeUpdate - pw:%o time:%o, duration:%o tbWidth:%o', progressWidth, time, duration, this.toolbarWidth);

		if (progressWidth <= clickAreaBuffer) {
			progressWidth = 0;
		}

		if (!this.scrubbing && !this.touchMove) {
			this.setState({
				progress: progressWidth,
				time: time,
				duration: duration
			})
		}
	}

	/**
	 * calculate seek time based on scrub position
	 * - never let seek fully to 100% so finishes/ends naturally and stop, allowing user restart
	 */
	setScrubPosition(xPos, seek=true) {
		let newPercent = Math.max(0, Math.min(.999999, xPos / this.toolbarWidth));

		// logger.info('ProgressBar: setScrubPosition - xPos:%o width:%o perc:%o', xPos, this.toolbarWidth, newPercent);
		
		if (newPercent >= .999) {
			//liveState = true;
			//setLiveState(true);
		}

		if (seek){
			if (newPercent >= .999) {
				this.amp.goLive()
			}
			else {
				this.amp.setCurrentTime(newPercent * this.state.duration);
			}
			
			if (this.props.onClick) {
				this.props.onClick()
			}
		}
		else {
			if (xPos <= clickAreaBuffer) {
				xPos = 0;
			}
			this.setState({
				progress: xPos
			})
		}

		//playProgressBar.style.width = newPercent * (progressHolder.offsetWidth) + "px";
		//updateTimeDisplay();
	};

	onMouseUp(evt) {
		//logger.info('ProgressBar: onMouseUp - offsetX:%o', evt.nativeEvent.offsetX);
		//this.setScrubPosition(evt.nativeEvent.offsetX);
	}


	onMarkerMouseDown(evt) {
		this.scrubbing = true;
		logger.info('ProgressBar: onMarkerMouseDown - evt:%o ', evt);
		document.addEventListener("mousemove", this.onWindowMouseMove, false);
		document.addEventListener("mouseup", this.onWindowMouseUp, false);
		evt.stopPropagation();
	}

	onMarkerMouseUp(evt) {
		// evt.stopPropagation();
	}

	onWindowMouseMove(evt) {
		let offsetX = evt.clientX - this.toolbarOffset - clickAreaBuffer;

		// logger.info('ProgressBar: onWindowMouseMove - offsetX:%o evt:%o ', offsetX, evt);
		this.setScrubPosition(offsetX, false);
	}

	onWindowMouseUp(evt) {
		logger.info('ProgressBar: onWindowMouseUp - clientX:%o', evt.clientX);
		this.scrubbing = false;

		let offsetX = evt.clientX - this.toolbarOffset - clickAreaBuffer;
		
		logger.info('ProgressBar: onWindowMouseUp - offsetX:%o evt:%o ', offsetX, evt);
		document.removeEventListener("mousemove", this.onWindowMouseMove);
		document.removeEventListener("mouseup", this.onWindowMouseUp);
		this.setScrubPosition(offsetX);
	}


	onTouchStart(evt) {
		//logger.log('ProgressBar: onTouchStart - className:%o', evt.target.className);
	}

	onTouchMove(evt) {
		logger.log('ProgressBar: onTouchMove - evt:%o', evt.target.className);
		if (evt.target.className.indexOf('controls_progress_marker') == CLASSNAME_OFFSET) {
			
			this.touchMove = true;
			let xPos = evt.nativeEvent.pageX - this.toolbarOffset - clickAreaBuffer;

			//logger.log('ProgressBar: onTouchMove - evt:%o xPos:%o', evt.nativeEvent, xPos);

			let newPercent = Math.max(0, Math.min(.999999, xPos / this.toolbarWidth));
			//logger.log('ProgressBar: onTouchMove - className:%o xPos:%o perc:%o', evt.target.className, xPos, newPercent);
			let progressWidth = newPercent * this.toolbarWidth;
			
			if (progressWidth <= clickAreaBuffer) {
				progressWidth = 0;
			}

			this.setState({
				progress: progressWidth
			})
		}
	}

	onTouchEnd(evt) {
		logger.log('ProgressBar: onTouchEnd - evt:%o', evt.target.className);
		if (evt.target.className.indexOf('controls_progress_marker') == CLASSNAME_OFFSET) {
			this.touchMove = false;
			//let xPos = evt.nativeEvent.offsetX || evt.nativeEvent.layerX;
			let xPos = evt.nativeEvent.pageX - this.toolbarOffset - clickAreaBuffer;
			//logger.log('ProgressBar: onTouchEnd - className:%o pos;%o', evt.target.className, xPos);
			this.setScrubPosition(xPos);
		}
	}

	onMarkerMouseMove(evt) {
		logger.log('ProgressBar: onMarkerMouseMove - this.props.toolTipText:%o', this.props.toolTipText);
		if (evt.target.className.indexOf('controls_progress_bar') == CLASSNAME_OFFSET || evt.target.className.indexOf('controls_progress_marker') == CLASSNAME_OFFSET) {
			let xPos = evt.nativeEvent.pageX - this.toolbarOffset - clickAreaBuffer;
			let percent = Math.max(0, Math.min(.999999, xPos / this.toolbarWidth));
			let time = (percent * this.state.duration);
			let toolTime = formatSecs(time);
			logger.log("onMarkerMouseMove this.previewTracks:%o", this.previewTracks);
			logger.log("onMarkerMouseMove toolTime:%o", toolTime);
			
			let keys = Object.keys(this.props.toolTipText);
			if(keys.length > 0 && !this.toolTipStart || time < this.toolTipStart || time > this.toolTipEnd){
				for(const key of keys){	
					if(key < time){
						this.toolTipStart = key;
					}

					if(key > time){
						this.toolTipEnd = key;
						break;
					}
				}
			}

			let toolTipPreview = this.state.toolTipPreview;
			if(this.previewTracks.list.length > 0){
				let cues = this.previewTracks.list[0].cues;
				if(cues.length > 0  && (!this.toolTipPreviewStart || time < this.toolTipPreviewStart || time > this.toolTipPreviewEnd)){
					logger.log("[ProgressBar] cues:%o time", cues, time);
					let i = 0;
					for(i; i < cues.length; i++){
						if(time >= cues[i].startTime && time < cues[i].endTime){
							logger.log("[ProgressBar] cues[i]:%o", cues[i]);
							this.toolTipPreviewStart = cues[i].startTime;
							this.toolTipPreviewEnd = cues[i].endTime;
							toolTipPreview = cues[i].style;
							break;
						}
					}
				}
			}

			let toolTipVal = toolTime;
			// let toolWidth = op.get(this.toolSize, 'width', false);
			let toolWidth = this.toolSize;
			if(keys.length > 0){
				toolTipVal += this.props.toolTipText[this.toolTipStart] ? ' - ' + this.props.toolTipText[this.toolTipStart] : '';
			}


			if (xPos < toolWidth/2){
				xPos = toolWidth/2;
			}else if(xPos > this.toolbarWidth - (toolWidth/2)){
				xPos = this.toolbarWidth - (toolWidth/2);
			}
			logger.log("[ProgressBar] toolTipPreview:%o ", toolTipPreview);
			this.setState({mouseOverPos: xPos, toolTipVal, toolTipPreview});
			// logger.log('ProgressBar: onMouseMove - custom:%o', this.props.toolTipText);
			// logger.log('ProgressBar: onMouseMove - time:%o', (time));
			// logger.log('ProgressBar: onMouseMove - xPos:%o, measureRef:%o', xPos, this.toolbarWidth);
		}
	}

	/**
	 * if tap event happens on progress bar then seek
	 * prevent from trigger with taps on progress marker
	 * @param {} evt 
	 */
	handleTapEvent(evt) {
		//logger.log('ProgressBar: handleTapEvent - className:%o', evt.target.className);
		let offsetX = evt.clientX - this.toolbarOffset - clickAreaBuffer;
		logger.log('ProgressBar: handleTapEvent - offsetX:%o', offsetX);

		if (evt.target.className.indexOf('controls_progress_touch_area') == CLASSNAME_OFFSET || 
			evt.target.className.indexOf('controls_progress_bar') == CLASSNAME_OFFSET || 
			evt.target.className.indexOf('controls_play_progress') == CLASSNAME_OFFSET) {
			this.setScrubPosition(offsetX);
		}
	}
	
	render() {
		// logger.log('ProgressBar: render - state:%o dim:%o', this.state, this.props.dimensions);
		logger.log('ProgressBar: render - toolTipPreview:%o toolTipStart:%o', this.state.toolTipPreview, this.toolTipStart);
		// logger.log('ProgressBar: render - props:%o ', this.props);
		let styles = this.state.styles;
		return (
			
			<div className={styles.progress}>
					
				<span onClick={(evt) => {this.handleTapEvent(evt)}} >
					<div className={styles.progress_box}  >
						<div className={styles.progress_touch_area} 
							></div>
						<div className={styles.progress_cover}></div>
						<div className={styles.progress_bar} 
							onMouseMove={(evt) => this.onMarkerMouseMove(evt)}
							>
							{this.props.showTooltip && this.state.toolTipVal && this.state.toolTipVal != "" && (
							<Measure bounds onResize={contentRect =>{
								if(contentRect.bounds.width > this.toolSize){
									this.toolSize = contentRect.bounds.width;
								}
							}}>
								{({measureRef}) => (
									<div ref={measureRef} className={styles.tooltip} style={{left: this.state.mouseOverPos}}>
										{this.state.toolTipPreview && this.toolTipStart && (
											<div style={this.state.toolTipPreview} />
										)}
										{this.state.toolTipVal}
									</div>
								)}
							</Measure>
							)}
							<span className={styles.play_progress} style={{width: this.state.progress + 'px'}}>
								<span className={styles.progress_marker} 
									onMouseUp={(evt) => this.onMarkerMouseUp(evt)} 
									onMouseDown={(evt) => this.onMarkerMouseDown(evt)}
									onTouchStart={(evt) => this.onTouchStart(evt)}
									onTouchMove={(evt) => this.onTouchMove(evt)}
									onTouchEnd={(evt) => this.onTouchEnd(evt)} >
								</span>
							</span>
						</div>
					</div>
				</span>
			</div>
			  	
		)

		// return (
		// 	<div className={styles.progress}>
				
		// 		<div className={styles.progress_box}>
		// 			<div className={styles.progress_cover}></div>
		// 			<Tappable onTap={(evt) => {this.handleTapEvent(evt)}} stopPropagation={true}>
		// 				<div className={styles.progress_bar}>
		// 					<span className={styles.play_progress} style={{width: this.state.progress + 'px'}}>
		// 						<span className={styles.progress_marker}>
									
		// 						</span>
		// 					</span>
		// 				</div>
		// 			</Tappable>
		// 		</div>
				
		// 	</div>
		// )
	}
	
}

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { getRole } from 'appdir/components/general/Util/Role';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import CenterMarkTitle from 'appdir/components/common-ui/CenterMarkTitle';
import Script from 'react-load-script';
import { doMeasurement } from 'appdir/components/general/Analytics';


/**
 * -----------------------------------------------------------------------------
 * Component: StorytellerElem
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		gigya: state['Gigya'],
		favourites: state['Controller']['favourites'],
		config: state['Config'].storyteller,
		geoLocation: state['Config']['edge_country'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	// show: matchId => dispatch(deps.actions.SlamtrackerPanel.show(matchId)),
});

class StorytellerElem extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props, isStorytellerInitialized: false };

		this.rowTheme;
		this.id = this.props.id;
		this.rendered = false;
		//logger.info('[Storyteller] constructor state:%o', this.state);

		let unreadColor = "#FFFFFF";
		let readColor = "#999999";
		let unreadTextColor = "#FFFFFF";
		let readTextColor = "#FFFFFF";

		if (this.props.data?.type == 'fixed') {
			unreadColor = "#4E2683";
			readColor = "#999999";
			unreadTextColor = "#1a1a1a";
			readTextColor = "#1a1a1a";
		}

		this.theme = {
			light: {
				lists: {
					row: {
						startInset: 5,
						endInset: 5,
						scrollIndicatorFade: false
					}
				},
				storyTiles: {
					circularTile: {
						unreadIndicatorColor: unreadColor,
						readIndicatorColor: readColor,
						title: {
							show: true,
							unreadTextColor: unreadTextColor,
							readTextColor: readTextColor
						}
					},
					rectangularTile: {
						showGradient: false
					}
				}
			
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[Storyteller] getDerivedStateFromProps - prevState:%o nextProps:%o', prevState, nextProps);

		let newState = {...nextProps};
		newState = isEmpty(newState) ? null : newState;
		return newState;
	}
	
	componentWillUnmount() {

	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[Storyteller] componentDidUpdate state:%o', this.state);

		if (this.state.gigya.gigyaLoaded && this.state.scriptLoaded && this.state.isStorytellerInitialized && !this.rendered) {
			// logger.log('[Storyteller] componentDidUpdate currUserId:%o', window.Storyteller.sharedInstance.currentUserId);
			// logger.log('[Storyteller] componentDidUpdate getUserAttr:%o', window.Storyteller.User.getUserAttributes());

			this.setStorytellerAttributes(this.state?.gigya?.currentUser?.UID, this.state?.favourites?.players)

			this.renderStoryteller();
		}
	}

	handleScriptCreate() {
		//logger.log('[Storyteller] handleScriptCreate state:%o', this.state);
	}

	handleScriptError() {
		this.setState({
			scriptError: true,
		});
	}

	handleScriptLoad() {
		//logger.log('[Storyteller] handleScriptLoad: state:%o', this.state);
		this.setState({
			scriptLoaded: true
		});
		if (!window?.Storyteller?.sharedInstance?.isInitialized){
			this.initializeStoryteller();
		}
	}

	getId(length) {
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return result;
	}

	initializeStoryteller() {
		let initAttr = {};
		// if (this.state?.gigya?.currentUser?.UID) {
		// 	initAttr = { externalId: this.state?.gigya?.currentUser?.UID }
		// }

		window.Storyteller.sharedInstance
			.initialize(this.state?.config?.envId, initAttr)
			.then(() => {
				this.setState({isStorytellerInitialized: true});
			})
			.catch(e => {
				logger.error('[Storyteller] handleScriptLoad: error:%o', e);
				this.setState({
					scriptError: true,
				});
			});
	}

	renderStoryteller() {
		let categories = this.props?.data?.categories.split(',') || [];
		let maxCount = this.state?.config?.count ? this.state?.config?.count : 10;

		if (this.props.data.content == 'stories'){
			this.getStoryView(`storyteller-row-${this.id}`, maxCount, categories);
		}
		else if (this.props.data.content == 'clips'){
			this.getClipsView(`storyteller-row-${this.id}`, maxCount, categories);
		}
		else if (this.props.data.content == 'stories-grid'){
			this.getStoryGridView(`storyteller-row-${this.id}`, -1, categories);
		}
		else if (this.props.data.content == 'clips-grid'){
			this.getClipsGridView(`storyteller-row-${this.id}`, -1, categories);
		}

		this.rendered = true;
	}

	setStorytellerAttributes(uid, players) {
		//if (!window.Storyteller.sharedInstance.isInitialized)
		window.Storyteller.sharedInstance.initialize(this.state?.config?.envId, uid ? { externalId: uid } : {});

		window.Storyteller.sharedInstance.delegate ={
			onUserActivityOccurred: (type, data) => {
				logger.log('[Storyteller] Storyteller Activity Occurred', type, data);
				if(type == "openedStory"){
					logger.log('[Storyteller] Storyteller opened story');
					let measureArgs = [];
					let contextData = [{type: this.props.data.content, story_title: data.storyTitle }];
					let metricType = "pageView";

					doMeasurement('StoryTeller', 'pageView', measureArgs, contextData, metricType);
				}else if(type == "dismissedStory"){
					logger.log('[Storyteller] Storyteller dismissedStory');
					let measureArgs = [];
					let contextData = [{type: this.props.data.content, story_title: data.storyTitle }];
					let metricType = "closeStoryteller";

					doMeasurement('StoryTeller', 'closeStoryteller', measureArgs, contextData, metricType);
				}			
			}
		}
		window.Storyteller.User.setUserAttribute('myWUser', uid ? 'true' : 'false');
		window.Storyteller.User.setUserAttribute('countryCode', this.state?.geoLocation ? this.state?.geoLocation : 'UN');
		if (players && players.length > 0) {
			window.Storyteller.User.setUserAttribute('favouritePlayers', players.join(','));
		}
		//logger.log('[Storyteller] setStorytellerAttributes - currUserId:%o', window.Storyteller.sharedInstance.currentUserId);
		//logger.log('[Storyteller] setStorytellerAttributes - getUserAttr:%o', window.Storyteller.User.getUserAttributes());
	}

	getStoryView(id, count, categories) {
		//logger.log('[Storyteller] getStoryView: window.Storyteller:%o', window.Storyteller);
		logger.log('[Storyteller] getStoryView: id:%o count:%o categories:%o theme:%o', id, count, categories, this.rowTheme);

		let row = new window.Storyteller.StorytellerStoriesRowView(id, categories);
		let rowTheme = new window.Storyteller.UiTheme(this.theme);
		row.configuration = {
			basename: `baseStory-${this.id}`,
			cellType: window.Storyteller.CellType.round, // Stories only
			displayLimit: count,
			preload: true, // Stories only
			theme: rowTheme,
			uiStyle: 'light',
		};

		row.delegate = {
			onDataLoadComplete: (success, error, dataCount) => {
				logger.log('[Storyteller] onDataLoadComplete - success:%o dataCount:%o', success, dataCount);
				this.setState({
					dataLoaded: true,
				});
			}
		};
		//this.storyRow.reloadData();
	}

	getClipsView(id, count, categories) {
		logger.log('[Storyteller] getClipsView: id:%o count:%o categories:%o theme:%o', id, count, categories, this.rowTheme);

		let row = new window.Storyteller.StorytellerClipsRowView(id, categories[0]);
		let rowTheme = new window.Storyteller.UiTheme(this.theme);
		row.configuration = {
			basename: `baseClips-${this.id}`,
			cellType: window.Storyteller.CellType.round, // Stories only
			displayLimit: count,
			preload: true, // Stories only
			theme: rowTheme,
			uiStyle: 'light',
		};
		
		row.delegate = {
			onDataLoadComplete: (success, error, dataCount) => {
				//logger.log('[Storyteller] onDataLoadComplete - success:%o dataCount:%o', success, dataCount);
				this.setState({
					dataLoaded: true,
				});
			}
		};
	}

	getStoryGridView(id, count, categories) {
		//logger.log('[Storyteller] getClipsView: id:%o count:%o categories:%o', id, count, categories);

		let row = new window.Storyteller.StorytellerStoriesGridView(id, categories);
		let rowTheme = new window.Storyteller.UiTheme(this.theme);
		row.configuration = {
			basename: `baseClips-${this.id}`,
			cellType: window.Storyteller.CellType.square, // Stories only
			displayLimit: count,
			preload: true, // Stories only
			theme: rowTheme,
			uiStyle: 'light',
		};
		
		row.delegate = {
			onDataLoadComplete: (success, error, dataCount) => {
				//logger.log('[Storyteller] onDataLoadComplete - success:%o dataCount:%o', success, dataCount);
				this.setState({
					dataLoaded: true,
				});
			}
		};
	}

	getClipsGridView(id, count, categories) {
		//logger.log('[Storyteller] getClipsView: id:%o count:%o categories:%o', id, count, categories);

		let row = new window.Storyteller.StorytellerClipsGridView(id, categories[0]);
		let rowTheme = new window.Storyteller.UiTheme(this.theme);
		row.configuration = {
			basename: `baseClips-${this.id}`,
			cellType: window.Storyteller.CellType.square, // Stories only
			displayLimit: count,
			preload: true, // Stories only
			theme: rowTheme,
			uiStyle: 'light',
		};
		
		row.delegate = {
			onDataLoadComplete: (success, error, dataCount) => {
				//logger.log('[Storyteller] onDataLoadComplete - success:%o dataCount:%o', success, dataCount);
				this.setState({
					dataLoaded: true,
				});
			}
		};
	}


	/**
	 * render proper component for tag passed from CMS SimpleComponent
	 * - ChampionshipRegistration requires logged in player so check here not strictly necessary
	 * - PlayerStatusWidget does not necessarily require a logged in player role, so extra check added here
	 */
	render() {
		logger.log('[Storyteller] render2 - id:%o state:%o currentUserId:%o', this.id, this.state, window?.Storyteller?.sharedInstance.currentUserId);
		if (this.state?.config?.enabled && isMobile && !this.state.scriptLoaded && this.state?.config?.envId) {
			//logger.log('[Storyteller] render - load script');
			return (
				<Script
					url={`${this.state?.config?.path}`}
					onError={this.handleScriptError.bind(this)}
					onLoad={this.handleScriptLoad.bind(this)}
				/>
			);
		}

		let indColor = '';
		let styleType = this.props.data?.type == 'fixed' ? 'fixed' : 'inline';
		let showTitle = this.props.data.title ? true : false;
		let showViewAll = (this.props.data.content == 'clips')
		let viewAllPath = '/en_GB/storyteller/';
		if (this.props.data.content == 'clips'){
			viewAllPath += 'clips-grid'
		}
		else if (this.props.data.content == 'stories') {
			viewAllPath += 'stories-grid'
		}
		viewAllPath += `?title=${this.props.data.title}&categories=${this.props.data.categories}`

		let style = {};
		// if (this.props.data.content == 'clips' || this.props.data.content == 'stories') {
		// 	if (this.props.data?.type != 'fixed') {
		// 		style = {
		// 			...style,
		// 			"marginTop": "15px",
		// 			"height": '120px',
		// 			"backgroundColor": "rgba(0, 0, 0, 0)"
		// 		}
		// 	}
		// 	else {
		// 		style = {
		// 			...style,
		// 			"height": '120px'
		// 		};
		// 		indColor = 'white';
		// 	}
		// }

		if (styleType == 'inline') {
			if(this.props.data.content == 'clips-grid'){
				style = {
					...style,
					"backgroundColor": "rgba(0, 0, 0, 0)"
				}
			}else{
				style = {
					...style,
					"height": '120px',
					"backgroundColor": "rgba(0, 0, 0, 0)"
				}
			}
			indColor = 'white';
		}
		else {
			style = {
				...style,
				"height": '120px'
			};
			indColor = '';
		}

		if (!this.state.dataLoaded) {
			style = {
				...style,
				"display": "none"
			}
		}

		if (this.state?.config?.enabled && isMobile){
			//<div className="two-col center margin">
			return (
				
				<div className="full-col">
					{this.state.scriptLoaded && !this.state.scriptError ? (
						<div className={`storyteller ${styleType} ${this.props.data.content}`}>
							{
								(showTitle) ? 
									<div className='title'>
										{this.props.data.title}
										<hr class="purpleStripe-short" />
									</div> : null
							}
							{
								(!this.state.dataLoaded) ? 
									<div style={{"height": "120px"}}><LoadingIndicator type={`small relative ${indColor}`} /></div> : null
							}
							{
								(showViewAll) ? 
									<div className='view'><WimLink to={`${viewAllPath}`} >VIEW ALL</WimLink></div> : null
							}
							<div id={`storyteller-row-${this.id}`} style={style}></div>
						</div>
					) : this.state.scriptError ? (
						<div>Storyteller Error</div>
					) : null}
				</div>
			
			);
		}
		else {
			return null;
		}
		
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(StorytellerElem);

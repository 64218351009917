//default scores bounds, use config if we have
let topDrawScore = 0.46;
let bottomDrawScore = 0.282;

//default category bounds, use config if we have
let topCategory = 3;
let bottomCategory = 17;
let totalCategories = 20;

import { values } from 'appdir/main';

export const getDrawPath = (draw, playerId, drawAnalysis) => {
	//if no matches, exit
	if (!draw?.matches?.length) {
		return {};
	}

	const matches = new Map(); //list of matches in the draw
	const teams = new Map(); //list of "teams" found in the draw (from round 1)
	const totalRounds = draw.totalRounds; //number of rounds
	const selectedRounds = new Map(); //map for each round associated with the selected team. to store path data
	let selectedTeamId; //the "team" that calculating the draw path for

	//stub the selectedRounds maps for storing draw path results
	for (let i = 1; i <= totalRounds; i++) {
		selectedRounds.set(String(i), { round: '', opponents: [], result: null, match: null });
	}

	//create a map of all matches for easier lookup
	draw.matches.forEach(obj => {
		matches.set(obj.match_id, obj);
	});

	//loop through first rounds matches and find all teams in the draw
	// populate the teams map with the initial match/round1 for each team
	matches.forEach(match => {
		let roundId = getRoundFromId(match.match_id);
		if (roundId == 1) {
			let id1 = getTeamId(match, 1);
			let id2 = getTeamId(match, 2);

			//logger.log('[Tournament-Util] getDrawPath - playerId:%o id1:%o id2:%o', playerId, id1, id2);
			if (id1 && id1.includes(playerId)) {
				selectedTeamId = id1;
			} else if (id2 && id2.includes(playerId)) {
				selectedTeamId = id2;
			}

			teams.set(id1, getTeamDetails(match, 1));
			teams.set(id2, getTeamDetails(match, 2));
		}
	});
	logger.log('[Tournament-Util] getDrawPath - teams:%o', teams);

	//find selected team, if team not found in this draw return empty
	let selectedTeam = teams.get(selectedTeamId);
	selectedTeam.matches.push(...calculateMatchList(selectedTeam, matches, totalRounds));
	if (!selectedTeam) {
		return {};
	}

	//loop through all teams in the draw and find potential matches
	teams.forEach((value, key) => {
		//add potential match list to the team
		value.matches.push(...calculateMatchList(value, matches, totalRounds));
		// logger.log('[Tournament-Util] getDrawPath - start');
		// logger.log('[Tournament-Util] getDrawPath - key:%o player:%o', key, value.name);

		//after found potential matches, find intersection with selected team
		value.matches.some(matchPos => {
			let curRnd = String(getRoundFromId(matchPos.matchId));

			// if selected team idA matches list of team idAs (but not itself) push opponents
			// stop after adding name, because if name in one round, can't be in next
			let foundMatches = selectedTeam.matches.filter(teamMatch => {
				//logger.log('[Tournament-Util] getDrawPath - adding matchId:%o teamMatch:%o', matchPos.matchId, teamMatch);
				return teamMatch.matchId == matchPos.matchId;
			});

			// if (matchPos.matchId == '1152') {
			// 	logger.log('[Tournament-Util] getDrawPath - matchId:%o foundMatches:%o', matchPos.matchId, foundMatches);
			// }

			if (selectedTeam.idA != value.idA && foundMatches.length > 0) {
				logger.log('[Tournament-Util] getDrawPath - adding opp:%o', value);

				selectedRounds.get(curRnd).opponents.push({
					round: curRnd,
					playerIdA: value.idA,
					nationA: value.nationA,
					nameA: value.idA != 'll' ? value.nameA : 'Luck',
					playerIdB: value.idB,
					nationB: value.nationB,
					seed: value.seed,
					nameB: value.nameB,
				});
				return true;
			}

			// else if this is the selectedPlayer add match details
			else if (selectedTeam.idA == value.idA) {
				let match = matches.get(matchPos.matchId);
				selectedRounds.get(curRnd).round = curRnd;
				selectedRounds.get(curRnd).result = getMatchResult(match, selectedTeam.idA);
				selectedRounds.get(curRnd).match = matchPos.matchId;
				selectedRounds.get(curRnd).position = matchPos.position;
				//logger.log('[Tournament-Util] getDrawPath - select match:%o', match, result);
			}
		});
		//logger.log('[Tournament-Util] getDrawPath - team matches:%o', value);
	});

	//logger.log('[Tournament-Util] getDrawPath - selectedTeam:%o', selectedTeam);
	//logger.log('[Tournament-Util] getDrawPath - drawAnalysis:%o', drawAnalysis);

	//if draw analysis data exists, then sort the potential opponents by likley opponents ordered list
	selectedRounds.forEach((value, key) => {
		if (drawAnalysis) {
			//get the draw analysis match
			let match = drawAnalysis.matches.filter(match => {
				return match.matchId == value.match;
			});
			if (match[0]) {
				//get the match label
				value = getMatchLabel(value, match[0]);

				//sort the opponents
				let opponents = value.opponents;
				opponents.sort((a, b) => {
					let indxA = match[0].possibleOpponents.findIndex(elem => {
						return elem.opponentId == a.playerIdA;
					});
					let indxB = match[0].possibleOpponents.findIndex(elem => {
						return elem.opponentId == b.playerIdA;
					});
					return indxA - indxB;
				});

				//make sure the likely to place is placed at the top
				let index = opponents.findIndex(value => {
					return value.playerIdA == match[0].likelytoPlay; //atpn409
				});
				opponents.unshift(opponents.splice(index, 1)[0]);
			}
			//logger.log('[Tournament-Util] getDrawPath - round:%o match:%o daMatch:%o', key, value, match[0]);
		} else {
			// logger.log('[Tournament-Util] getDrawPath - round:%o match:%o ', key, value);
			
			/** if opponents array is more than 1, sort by the seed */
			let opponents = value.opponents;
			if(opponents?.length > 1) {
				//sort the opponents by seed
				opponents.sort((a, b) => {
					let seedA = !a.seed ? 500 : a.seed;
					let seedB = !b.seed ? 500 : b.seed;
					return seedA - seedB;
				});
			}
		}
	});

	return Array.from(selectedRounds, ([key, value]) => value);
};

/**
 * get player data from the draw
 * @param {*} draw
 * @param {*} playerId
 * @param {*} drawAnalysis
 * @returns
 */
export const getPlayerData = (draw, playerId, drawAnalysis) => {
	let team = {};
	draw.matches.every(obj => {
		if (obj.team1.idA == playerId || obj.team1.idB == playerId) {
			team.seed = obj.team1.seed;
			if (obj.team1.idA == playerId) {
				team.idA = obj.team1.idA;
				team.displayNameA = obj.team1.displayNameA;
				team.nationA = obj.team1.nationA;
				team.idB = obj.team1.idB;
				team.displayNameB = obj.team1.displayNameB;
				team.nationB = obj.team1.nationB;
			} else {
				team.idA = obj.team1.idB;
				team.displayNameA = obj.team1.displayNameB;
				team.nationA = obj.team1.nationB;
				team.idB = obj.team1.idA;
				team.displayNameB = obj.team1.displayNameA;
				team.nationB = obj.team1.nationA;
			}
			return false;
		} else if (obj.team2.idA == playerId || obj.team2.idB == playerId) {
			team.seed = obj.team2.seed;
			if (obj.team2.idA == playerId) {
				team.idA = obj.team2.idA;
				team.displayNameA = obj.team2.displayNameA;
				team.nationA = obj.team2.nationA;
				team.idB = obj.team2.idB;
				team.displayNameB = obj.team2.displayNameB;
				team.nationB = obj.team2.nationB;
			} else {
				team.idA = obj.team2.idB;
				team.displayNameA = obj.team2.displayNameB;
				team.nationA = obj.team2.nationB;
				team.idB = obj.team2.idA;
				team.displayNameB = obj.team2.displayNameA;
				team.nationB = obj.team2.nationA;
			}
			return false;
		}
		return true;
	});

	if (drawAnalysis) {
		team.drawRank = drawAnalysis.drawRank;
		team.beginDrawRank = drawAnalysis.beginDrawRank;
		team.drawScore = drawAnalysis.drawScore;
		team.drawLabel = getDrawAnalysisLabel(drawAnalysis.difficultyCategory);
		team.beginDrawLabel = getDrawAnalysisLabel(drawAnalysis.beginDifficultyCategory);
		team.drawLevel = getDrawAnalysisLevel(drawAnalysis.difficultyCategory);
		team.drawLevel = getDrawAnalysisLevel2(drawAnalysis.drawRank, drawAnalysis.currentRound);
		team.displayRank = getDrawAnalysisDisplayRank(
			drawAnalysis.drawRank,
			drawAnalysis.difficultyCategory,
			drawAnalysis.currentRound
		);

		//test using just the begin values
		// if (begin) {
		// 	team.drawRank = team.beginDrawRank;
		// 	team.drawLabel = getDrawAnalysisLabel(drawAnalysis.beginDifficultyCategory);
		// 	team.drawLevel = getDrawAnalysisLevel(drawAnalysis.beginDifficultyCategory);
		// 	team.displayRank = getDrawAnalysisDisplayRank(
		// 		drawAnalysis.beginDrawRank,
		// 		drawAnalysis.beginDifficultyCategory,
		// 		1
		// 	);
		// }
	}

	logger.log('[Tournament-Util] getPlayerData - team:%o drawAnalysis:%o', team, drawAnalysis);
	return team;
};

const getTeamId = (match, team) => {
	let entry = match[`team${team}`].entryStatus;

	const idA = match[`team${team}`].idA;
	const idB = match[`team${team}`].idB;
	return idB ? `${idA}/${idB}` : idA;

	// const idA = match[`team${team}`].entryStatus.toLowerCase() == 'll' ? match[`team${team}`].idA : '';
	// const idB = match[`team${team}`].entryStatus.toLowerCase() == 'll' ? match[`team${team}`].idB : '';
	// return idB ? `${idA}/${idB}` : idA;
};

const getTeamDetails = (match, team) => {
	const idA = match[`team${team}`].idA;
	const idB = match[`team${team}`].idB;

	let entryStatus = match[`team${team}`].entryStatus;

	if (idB) {
		return {
			idA: match[`team${team}`].idA,
			nationA: match[`team${team}`].nationA,
			nameA: match[`team${team}`].displayNameA,
			idB: match[`team${team}`].idB,
			nationB: match[`team${team}`].nationB,
			nameB: match[`team${team}`].displayNameB,
			seed: match[`team${team}`].seed,
			matches: [{ matchId: match.match_id, position: team == 1 ? 'top' : 'bottom' }],
		};
	} else {
		return {
			idA: match[`team${team}`].idA,
			nationA: match[`team${team}`].nationA,
			nameA: match[`team${team}`].displayNameA,
			seed: match[`team${team}`].seed,
			matches: [{ matchId: match.match_id, position: team == 1 ? 'top' : 'bottom' }],
		};
	}
};

const getMatchResult = (match, id) => {
	if (match.winner) {
		if (match['team' + match.winner].idA == id) {
			return 'won';
		} else {
			return 'lost';
		}
	} else {
		return 'vs';
	}
};

const getMatchFromId = id => {
	return parseInt(id.substr(id.length - 2, 2));
};

export const getRoundFromId = id => {
	return parseInt(id.substr(id.length - 3, 1));
};

const getEventFromId = id => {
	return parseInt(id.substring(0, id.length - 3));
};

const getNextMatch = (id, rounds) => {
	const matchEvt = getEventFromId(id);
	const matchCnt = getMatchFromId(id);
	let matchRnd = getRoundFromId(id);

	if (matchRnd == rounds) {
		return null;
	}

	let nextMatchCnt = String(matchCnt % 2 ? Math.ceil(matchCnt / 2) : matchCnt / 2);
	nextMatchCnt = nextMatchCnt < 10 ? '0' + nextMatchCnt : '' + nextMatchCnt;
	let nextMatchRnd = String(++matchRnd);
	return {
		matchId: matchEvt + nextMatchRnd + nextMatchCnt,
		position: matchCnt % 2 != 0 ? 'top' : 'bottom',
	};
	//return matchEvt + nextMatchRnd + nextMatchCnt;
};

const addPotentialPlayer = (rounds, matchId, player) => {
	let rnd = String(getRoundFromId(matchId));
	let round = rounds.get(rnd);
	round.push(player);
};

const canPlayerAdvance = (playerId, match) => {
	//logger.log('[Tournament-Util] canPlayerAdvance - match:%o', match);

	if (match.winner) {
		if (match['team' + match.winner].idA == playerId) {
			return true;
		}
	} else {
		return true;
	}

	return false;
};

const calculateMatchList = (team, matches, totalRounds) => {
	let match = team.matches[team.matches.length - 1]; //players first match
	let next;
	let potential = [];

	if (canPlayerAdvance(team.idA, matches.get(match.matchId))) {
		next = getNextMatch(match.matchId, totalRounds);
		while (next) {
			potential.push(next);

			if (canPlayerAdvance(team.idA, matches.get(next.matchId))) {
				next = getNextMatch(next.matchId, totalRounds);
			} else {
				next = null;
			}
		}
	}

	return potential;
};

const getMatchLabel = (match, drawAnalysisMatch) => {
	//logger.log('[Tournament-Util] getMatchLabel - match:%o data:%o', match, drawAnalysisMatch);
	match.label = getMatchScoreLabel(drawAnalysisMatch.drawScore);
	match.drawScore = drawAnalysisMatch.drawScore;
	return match;
};

const getMatchScoreLabel = score => {
	let top = values?.drawBounds?.topScore || topDrawScore;
	let bottom = values?.drawBounds?.bottomScore || bottomDrawScore;

	if (score >= top) {
		return 'favourable';
	} else if (score <= bottom) {
		return 'difficult';
	} else if (score) {
		return 'neutral';
	}
	return null;
};

export const getDrawAnalysisLabel = category => {
	let top = values?.drawBounds?.topCategory || topCategory;
	let bottom = values?.drawBounds?.bottomCategory || bottomCategory;

	if (category <= top) {
		return 'favourable';
	} else if (category >= bottom) {
		return 'difficult';
	} else if (category) {
		return 'neutral';
	}
	return null;
};

export const getDrawAnalysisLevel = category => {
	return category / totalCategories;
};

export const getDrawAnalysisLevel2 = (rank, round) => {
	let count = 128;
	let i = 1;
	while (i < round) {
		count = count / 2;
		i++;
	}
	return rank / (count + 1);
};

export const getDrawAnalysisDisplayRank = (rank, category, round) => {
	let top = values?.drawBounds?.topCategory || topCategory;
	let bottom = values?.drawBounds?.bottomCategory || bottomCategory;
	let count = 128;
	let i = 1;
	while (i < round) {
		count = count / 2;
		i++;
	}
	//logger.log('[Tournament-Util] getDrawAnalysisDisplayRank - round:%o count:%o', round, count);
	if (category <= top) {
		return rank;
	} else if (category >= bottom) {
		return count + 1 - rank;
	} else if (category) {
		return '';
	}
};



import React, { Component } from 'react';
import op from 'object-path';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import { TicketsContext } from '../context';

class TicketOptions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: null,
			open: false,
		};

		this.setAccount = this.setAccount.bind(this);
		this.onOpen = this.onOpen.bind(this);

		logger.log('[TicketOptions] constructor props:%o state:%o', props, this.state);
	}

	componentDidMount() {
		let accounts = op.get(this, 'context.spectatorAccounts', []);
		let selectedAccounts = accounts.filter(a => {
			return a.value == this.props.selected;
		});

		document.addEventListener('mousedown', this.handleClick, false);

		this.setState({
			selected:
				selectedAccounts.length > 0
					? selectedAccounts[0].value
					: op.get(this, 'context.spectatorAccounts[0].value', null),
			open: false,
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = e => {
		// logger.log('[TicketFilters] handleClick e.target:%o', e.target);
		// logger.log('[TicketFilters] handleClick this.actionsNode:%o', this.actionsNode);
		if (this.accountsNode) {
			if (!this.accountsNode.contains(e.target)) {
				this.setState({
					open: '',
				});
			}
		}
	};

	setAccount(which) {
		//logger.log('[TicketOptions] setAccount:%o', which);

		this.setState({
			selected: which,
			open: '',
		});

		if (this.props.onChange) {
			this.props.onChange(which);
		}
	}

	onOpen(which) {
		//logger.log('[TicketOptions] onOpen:%o', which);

		if (which == this.state.open) {
			this.setState({
				open: '',
			});
		} else {
			this.setState({
				open: which,
			});
		}
	}

	render() {
		logger.log('[TicketOptions] render - state:%o', this.state);

		let accountData = {
			options: [],
		};

		//if more than one spectator account, show the select menu
		if (this.context.spectatorAccounts.length > 1) {
			// add available options
			this.context.spectatorAccounts.forEach(item => {
				accountData.options.push({ name: item.value, value: item.value });
			});

			return (
				<div className="ticketview-header">
					ACCOUNT
					<div className="scores-select-menu">
						<span ref={node => (this.accountsNode = node)}>
							<SelectMenu
								name="accounts"
								attributes={accountData}
								selected={this.props.selected}
								show={this.state.open}
								onSelected={this.setAccount}
								onOpen={this.onOpen}
							/>
						</span>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

TicketOptions.contextType = TicketsContext;
export default TicketOptions;

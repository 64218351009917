import NewsIndex from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/:lang/news/:year.html:type?', '/:lang/news/'],
		exact: true,
		component: NewsIndex,
		section: MENU_ITEMS.news,
		onRouteChange: () => {},
		load: params => deps.actions.NewsIndex.mount(params),
	},
	// {
	// 	path: '/en_GB/news/',
	// 	exact: true,
	// 	component: NewsIndex,
	// 	section: MENU_ITEMS.news,
	// 	// onRouteChange: () => {},
	// 	load: params => deps.actions.NewsIndex.mount(params),
	// },
];

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import { doMeasurement } from 'appdir/components/general/Analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['DrawsPage'],
		tourn_events: state.Config?.scoringConfig?.eventNames,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//filter: settings => dispatch(deps.actions.DrawsPage.filter(settings)),
	mount: params => dispatch(deps.actions.DrawsPage.mount(params)),
});

class EventSelect extends Component {
	constructor(props) {
		super(props);

		/* Invitational match Codes: IX, BU, GU, RR, SL, */

		// Invitational matches should be CMS controlled.

		let excludeList = this.props.drawExclude ? this.props.drawExclude : '';
		let stubbedPdfEvents = this.props.stubbedPdfEvents;
		let pdfMatchList = this.props.drawPdfEvents || [];

		let eventsDropdownVals = props.events.map(item => ({
			name: item.long,
			value: item.code,
			type: pdfMatchList.includes(item.code) ? (item.type = 'pdf') : (item.type = ''),
		}));

		eventsDropdownVals = eventsDropdownVals.filter(event => {
			if (excludeList.indexOf(event.value) < 0) {
				return true;
			}
		});

		// check if pdf events on the list is stubbed. Set as stubbed
		// eventsDropdownVals.map(event => {
		// 	if (stubbedPdfEvents.includes(event.value)) {
		// 		return (event.isStubbed = true);
		// 	}
		// });

		this.state = {
			...props,
			eventsDropdownVals: eventsDropdownVals,
		};

		this.setEvent = this.setEvent.bind(this);
		this.onOpen = this.onOpen.bind(this);

		logger.log('[EventSelect] constructor props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[EventSelect] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});

		//logger.log('[EventSelect] componentWillReceiveProps - status:%o state:%o next:%o', nextProps.status, this.state.year, nextProps.year);
	}

	componentDidUpdate(prevProps) {}

	setEvent(event, name) {
		doMeasurement('Draws', 'Drop Down Selection', [name], [{ event: name }]);

		this.setState(() => {
			this.props.onChange(event);
		});
	}

	onOpen = which => {
		if (which) {
			doMeasurement('Draws', 'EventFilter', ['open'], [{ EventFilter: 'open' }]);
		} else {
			doMeasurement('Draws', 'EventFilter', ['close'], [{ EventFilter: 'close' }]);
		}
	};

	render() {
		logger.log('[EventSelect] render - this:%o', this);

		let eventsData = {
			options: this.state.eventsDropdownVals,
		};
		return (
			<div className="scores-select-menu">
				<SelectMenu
					name="events"
					attributes={eventsData}
					selected={this.props.selected}
					show={false}
					onSelected={this.setEvent}
					onOpen={this.onOpen}
					onClose={this.onClose}
					pdfPath={this.props.pdfPath}
					// measure={false}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSelect);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
const op = require('object-path');
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoTest
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['VideoTest'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({});

class VideoTest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};

		let parsedQs = getQuerystringValues(false, true);
		this.vidSrc = parsedQs.src || null;
	}

	componentDidMount() {}

	componentWillUnmount() {}

	componentDidUpdate(prevProps, prevState) {}

	onEvent(event) {
		logger.info('[VideoTest] onVideoEvent - event:%o', event);
	}

	render() {
		let attributes = {
			playerId: 'main',
			contentId: null,
			// videoUrl: 'https://wimbledonvod-vh.akamaihd.net/2023/highlights/test/playlist.m3u8',
			videoUrl: this.vidSrc,
			title: 'Test Vid',
			thumb: '',
			date: '',
			shareUrl: '',
			autoplay: false,
			fullscreenMode: '',
			streamType: 'vod',
			aspect: 'wide',
			videoSrc: '',
		};

		if (this.vidSrc) {
			return (
				<div className="content-main">
					<div className="video-test-page">
						<div className="video-wrapper">
							<VideoPlayer
								key={'test'}
								attributes={attributes}
								onEvent={this.onEvent}
								// onReady={this.onPlayerReady}
								// onVideoEvent={this.onVideoEvent}
								// onUserAction={this.onUserAction}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return <div className="error">No vid src</div>;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoTest);

import RoleRegistrationInvite from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/s/mywimbledon/assign/:role/:guid'],
		exact: true,
		component: RoleRegistrationInvite,
		load: params => deps.actions.RoleRegistrationInvite.mount(params),
	},
];

import React, { Component } from 'react';
import TableComponent from 'appdir/components/common-ui/TableComponent';

const createTableHeaders = () => ['Year', 'Singles', 'Doubles', 'Mixed'];

const formatTableData = roundsData =>
	Object.keys(roundsData).map((item, i) => {
		let roundsRow = roundsData[item].results;

		return {
			year: roundsData[item].year,
			singles: roundsRow.singles,
			doubles: roundsRow.doubles,
			mixed: roundsRow.mixed,
		};
	});

class PlayerRounds extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
	}

	render() {
		let tableHeader = createTableHeaders();
		let roundsData = this.state;
		let tableRowData = formatTableData(roundsData);
		let highestRoundsData = {
			headers: tableHeader,
			data: tableRowData,
			tableContainer: 'match-results',
			tableTitle: 'Highest Rounds',
		};

		logger.log('[PlayerRounds] render:%o', roundsData);

		return (
			<div className="col-4 inline">
				<TableComponent attributes={highestRoundsData} />
			</div>
		);
	}
}

export default PlayerRounds;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import PageHeader from 'appdir/components/general/PageHeader';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import IFrameComponent from 'appdir/components/common-ui/IFrameComponent';
import MeasurementUtils from 'appdir/lib/analytics';
import Helmet from 'react-helmet';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: FramePages
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.BreakpointPage.mount()),
});

class BreakpointPage extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		//logger.info('[FramePage] componentDidMount - state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {}

	render() {
		//logger.info('[FramePage] render - state:%o', this.state);
		const {} = this.state;

		let header_propsData = {
			headerType: 'generic',
			title: 'Wimbledon Smash',
			shortTitle: 'Wimbledon Smash',
			metaTitle: 'Wimbledon Smash',
			metaDescription: 'The Official Wimbledon game.',
			metaDate: '',
			metaPlayers: '',
			imageSrc: 'https://assets.wimbledon.com/images/breakpoint/smash-header.jpg',
			scroll: true,
		};

		//add data for Helmet
		header_propsData = {
			...header_propsData,
			type: 'O',
			canonicalLink: values.canonicalHost + window.location.pathname,
			metaDescription: 'The Official Wimbledon game.',
			shareImage: 'https://assets.wimbledon.com/images/breakpoint/smash-header.jpg',
		};

		return (
			<Template className="iframe-component">
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main content ">
					<div className="column-layout content">
						<div className="one-col center" style={{ 'line-height': '30px' }}>
							<WimLink to="https://apps.apple.com/us/app/breakpoint/id1628829047" title="app store">
								<img
									alt="app store icon"
									src="/assets/images/mobile/appStore.png"
									style={{ width: 'unset', margin: '10px' }}
								/>
							</WimLink>
							<WimLink
								to="https://play.google.com/store/apps/details?id=com.ibm.wimbledon.breakpoint"
								title="google play">
								<img
									alt="google play store icon"
									src="/assets/images/mobile/googlePlayStore.png"
									style={{ width: 'unset', margin: '10px' }}
								/>
							</WimLink>
						</div>
						<div className="one-col center">
							<img
								alt="breakpoint game 1"
								src="http://assets.wimbledon.com/images/breakpoint/breakpoint_screen-ingame_1.png"
								style={{ width: '80%' }}
							/>
						</div>
						<div className="one-col center">
							<img
								alt="breakpoint game 1"
								src="http://assets.wimbledon.com/images/breakpoint/breakpoint_screen-ingame_2.png"
								style={{ width: '80%' }}
							/>
						</div>
						<div className="one-col center">
							<img
								alt="breakpoint game 1"
								src="http://assets.wimbledon.com/images/breakpoint/breakpoint_screen-ingame_3.png"
								style={{ width: '80%' }}
							/>
						</div>
						<div className="four-col">
							<IFrameComponent
								src="https://production.aeltc.wimbledon-scoreboards.lostmechanics.cool/"
								height="fullview"
							/>
						</div>
					</div>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakpointPage);

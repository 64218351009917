import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import PlayerGeneralInfo from 'appdir/components/general/PlayerGeneralInfo';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['Player'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: param => dispatch(deps.actions.Player.mount(param)),
});

class Player extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.data,
		};

		this.playerDataPath = '';
		this.playerImagePath = '';
		this.fetchPlayer = true;
	}

	componentDidMount() {
		//logger.info('[Player] componentDidUpdate - state:%o', this.state);
		this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	// componentWillUpdate(nextProps, nextState) {
	//     if(nextState.urlPath && !nextState.playerObj) {
	//         //service call
	//         deps.services.Player.getPlayerInfo(nextProps.urlPath.path)
	//             .then(data => {
	//                 this.setState(prevState => {
	//                     return {
	// 						...prevState,
	// 						playerObj: data
	// 					};
	//                 });
	//             });
	//     }

	// }

	componentDidUpdate() {
		if (
			this.state.playerPath &&
			!this.playerDataPath &&
			this.state.playerPath &&
			this.state.playerImage &&
			this.fetchPlayer
		) {
			this.fetchPlayer = false;
			logger.info('[Player] componentDidUpdate - state:%o', this.state);
			this.playerDataPath = this.state.playerPath.replace('<id>', this.state.playerId);
			this.playerImagePath = this.state.playerImage.replace('<playerid>', this.state.playerId);

			//service call
			deps.services.Player.getPlayerInfo(this.playerDataPath).then(data => {
				this.setState(prevState => {
					return {
						...prevState,
						playerObj: data,
					};
				});
			});
		}
	}

	render() {
		if (this.state.playerObj) {
			logger.info('[Player] render - id:%o state:%o', this.state.playerId, this.state);
			return (
				<div className={`player-info ${this.state.style}`} key={this.state.playerId}>
					<PlayerGeneralInfo player={{ ...this.state, playerConfig: this.playerImagePath }} />
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Player);

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

const fetchSinglesPlayerData = pathObj => {
	// do all the ajax requests at one time.  pass in an arry of requests.  returns
	// and array of responses.  see the service call in index.js
	return axios.all([fetch(pathObj.p1APath), fetch(pathObj.p2APath)]);
};

const fetchDoublesPlayerData = doublesPathObj => {
	// do all the ajax requests at one time.  pass in an arry of requests.  returns
	// and array of responses.  see the service call in index.js
	return axios.all([fetch(doublesPathObj.p1BPath), fetch(doublesPathObj.p2BPath)]);
};

export default {
	fetch,
	fetchSinglesPlayerData,
	fetchDoublesPlayerData,
};

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	/* mount: (data) => (dispatch, getState) => {
        let state = getState().WeatherPage;
        logger.log('[WeatherPage] mount - state:', state);
        if (state.loaded === true) {
            dispatch({type: deps.actionTypes.WEATHERPAGE_MOUNT, data: params});
        } else {
            deps.services.WeatherPage.fetch().then((data) => {
                dispatch({type: deps.actionTypes.WEATHERPAGE_MOUNT, data: data});
            });
        }
    }, */

	// on mount, ensure latest config loaded then load weather data

	mount: data => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Weather',
		});
		logger.log('[WeatherPage] action.mount - data:%o', data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let weatherData = {
				cams: Config.otherData.weather.cams
			};

			dispatch({ type: deps.actionTypes.WEATHERPAGE_MOUNT, data: weatherData });
			dispatch(deps.actions.WeatherData.check());
		});
	},
};

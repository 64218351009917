import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import op from 'object-path';

import TransferAction from './TicketActionViews/TransferAction';
import RecallAction from './TicketActionViews/RecallAction';
// import CancelAction from './TicketActionViews/CancelAction';
import ReturnToSender from './TicketActionViews/ReturnToSender';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';

class TicketActionModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recipientEmail: '',
			confirmRecipientEmail: '',
			actionMsg: '',
			actionStatus: '',
			responseUpdated: false,
		};
	}

	onRenderActionType(actionType) {
		let display;
		const {
			closeModal,
			fullTicketValue,
			callTicketActions,
			ticketStubToRender,
			renderLargeTransferModal,
			showModal,
		} = this.props;

		switch (actionType.toLowerCase()) {
			case 'transfer':
				display = (
					<TransferAction
						closeModal={closeModal}
						fullTicketValue={fullTicketValue}
						ticketStubToRender={ticketStubToRender}
						renderLargeTransferModal={renderLargeTransferModal}
						showModal={showModal}
						callTicketActions={callTicketActions}
						onRefreshTicketData={() => this.props.onSoftRefreshData()}
						ticketData={this.props.ticketData}
						// onRenderMultipleTicketStubs={(value, err, email) =>
						// 	this.props.onRenderMultipleTicketStubs(value, err, email)
						// }
						onRenderSingleTicketStub={(value, err, email) =>
							this.props.onRenderSingleTicketStub(value, err, email)
						}
						isWebView
						onSetResponse={() => this.setState({ responseUpdated: true })}
						toAutoFocus
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				);
				break;
			case 'recall': // note: aka "cancel transfer"
				display = (
					<RecallAction
						closeModal={closeModal}
						fullTicketValue={fullTicketValue}
						ticketStubToRender={ticketStubToRender}
						callTicketActions={callTicketActions}
						showModal={showModal}
						onRefreshTicketData={() => this.props.onSoftRefreshData()}
						ticketData={this.props.ticketData}
						onRenderMultipleTicketStubs={value => this.props.onRenderMultipleTicketStubs(value)}
						onRenderSingleTicketStub={(value, err, email) =>
							this.props.onRenderSingleTicketStub(value, err, email)
						}
						isWebView
						onSetResponse={() => this.setState({ responseUpdated: true })}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				);
				break;
			// case 'cancel':
			// 	let optionsArr = [];
			// 	Object.entries(this.props.configTickets.cancelOptions).map(item => {
			// 		optionsArr.push({ value: item[0], label: item[1] });
			// 	});
			// 	display = (
			// 		<CancelAction
			// 			optionsArr={optionsArr}
			// 			closeModal={closeModal}
			// 			fullTicketValue={fullTicketValue}
			// 			showModal={showModal}
			// 			onRefreshTicketData={() => this.props.onSoftRefreshData()}
			// 			isWebView
			// 		/>
			// 	);
			// 	break;
			case 'return':
				display = (
					<ReturnToSender
						closeModal={closeModal}
						fullTicketValue={fullTicketValue}
						callTicketActions={callTicketActions}
						showModal={showModal}
						refreshData={this.props.refreshData}
						ticketData={this.props.ticketData}
						onRefreshTicketData={() => this.props.onSoftRefreshData()}
						onRenderMultipleTicketStubs={value => this.props.onRenderMultipleTicketStubs(value)}
						onRenderSingleTicketStub={value => this.props.onRenderSingleTicketStub(value)}
						isWebView
						ticketStubToRender={ticketStubToRender}
						onSetResponse={() => this.setState({ responseUpdated: true })}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				);
				break;
			// case 'swap':

			// 	// display = (
			// 		// <TicketSwapInitial
			// 		// 	closeModal={closeModal}
			// 		// 	fullTicketValue={fullTicketValue}
			// 		// 	callTicketActions={callTicketActions}
			// 		// 	showModal={showModal}
			// 		// 	refreshData={this.props.refreshData}
			// 		// 	ticketData={this.props.ticketData}
			// 		// 	onRefreshTicketData={() => this.props.onSoftRefreshData()}
			// 		// 	onRenderMultipleTicketStubs={value => this.props.onRenderMultipleTicketStubs(value)}
			// 		// 	onRenderSingleTicketStub={value => this.props.onRenderSingleTicketStub(value)}
			// 		// 	isWebView
			// 		// 	ticketStubToRender={ticketStubToRender}
			// 		// />
			// 	// );
			// 	break;
			default:
				display = <div>Please try again</div>;
				break;
		}
		return display;
	}

	render() {
		const { isOpen, closeModal, title, renderLargeTransferModal } = this.props;
		const { actionMsg, actionStatus } = this.state;

		let titleToUse;
		switch (title) {
			case 'recall':
				titleToUse = 'cancel ticket transfer';
				break;
			case 'return':
				titleToUse = 'return to sender';
				break;
			case 'swap':
				titleToUse = 'swap ticket';
				break;
			default:
				titleToUse = title;
		}

		let modalTitle = actionStatus
			? actionStatus === 'cancel'
				? actionStatus === titleToUse
					? titleToUse
					: `${actionStatus} ${titleToUse}`
				: actionStatus === titleToUse || actionStatus === 'recall' || actionStatus === 'return'
				? titleToUse
				: `${titleToUse} ${actionStatus}`
			: titleToUse;

		let modalHeight = title === 'cancel' && actionStatus === 'cancel' ? '400px' : 'auto';

		return (
			<div className="ticket-modal-outside-container">
				<div className="ticket-modal-overlay">
					<div
						className={
							isOpen
								? renderLargeTransferModal && title === 'transfer'
									? 'ticket-modal-window-open-large'
									: 'ticket-modal-window-open'
								: 'hide-ticket-modal'
						}
						style={{ height: modalHeight }}>
						{actionStatus === 'submitting' ? null : (
							<div className="ticket-action-modal-close-btn">
								<CloseBtn closeFn={() => closeModal(this.state.responseUpdated)} />
							</div>
						)}
						<div className="ticket-action-modal-title">{modalTitle}</div>
						<div className="ticket-action-modal-divider-container">
							<div className="ticket-action-modal-title-divider" />
						</div>
						{actionMsg === '' ? this.onRenderActionType(title) : actionMsg}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		// gigya: state['Gigya'],
		...state['Tickets'],
		// stubs: op.get(state['Config'].stubPages, 'pde', null),
		configTickets: op.get(state['Config'], 'tickets', null),
		helpCenterUrl: op.get(state['Config'], 'tickets.helpCenterUrl', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	callTicketActions: (service, id, data) => dispatch(deps.actions.Tickets.callTicketActions(service, id, data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TicketActionModal);

/**
 DrawsArchiveContentPage Dropdown
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.DrawsArchiveContentPage.filter(settings)),
});

class SelectEvent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.setEvent = this.setEvent.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.dropdownVals = { options: [] };
		this.dropdownVals.options = this.state.attributes;

		logger.log('[DrawsArchiveSelectEvent] constructor - state: %o, props: %o', this.state, props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	setEvent(selected, name) {
		logger.log('[EventSelect] setEvent:%o, name:%o', selected, name);
		this.props.filter({
			selected: selected,
			title: name,
			open: '',
		});

		MeasurementUtils.dispatchMeasurementCall('drawsArchiveContentPageEventSelect', {
			event: selected,
			name,
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		logger.log('[DrawsArchiveSelectEvent] render - state: %o', this.state);
		return (
			<div className="content-filters">
				<div className="news-landing-time-selector">
					<SelectMenu
						name="events"
						attributes={this.dropdownVals}
						selected={this.state.filters.selected}
						show={this.state.filters.open == 'events'}
						onSelected={this.setEvent}
						onOpen={this.onOpen}
					/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectEvent);

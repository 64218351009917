/**
 PLAYERSTATSPAGE Initial State
*/

export default {
	filters: {
		playerStats: 'stats',
		open: '',
	},
};

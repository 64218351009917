import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[SiteMap] mount - data:%o', Config);
			let menus = Config.menus;
			let myWim = Config.myWimbledon;
			dispatch({
				type: deps.actionTypes.SITEMAP_MOUNT,
				data: { menus, myWim },
			});
		});
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { callFunction } from 'appdir/components/general/Util/Services';
import op from 'object-path';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import MeasurementUtils from 'appdir/lib/analytics';

import Template from 'appdir/components/Template';
// import { Link } from 'react-router-dom';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import { WimbledonWishContext } from './context';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import SectionDivider from 'appdir/components/cms/SectionDivider';

import WimRedirect from 'appdir/components/general/WimRedirect';
import LoginWrapper from './LoginWrapper';
import RequestForm from './RequestForm';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledon
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		userPreferences: state['Controller']['userPreferences'],
		...state['Gigya'],
		...state['WimbledonWish'],
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Ballot.mount()),
	unmount: () => dispatch(deps.actions.Ballot.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class WimbledonWish extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = Object.assign({}, this.props, { complete: false });
	}

	componentDidMount() {
		logger.log('[WimbledonWish] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[WimbledonWish] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[WimbledonWish] componentDidUpdate - prev:%o state:%o', prevState, this.state);
	}

	handleSubmit(values) {
		logger.log('[WimbledonWish] handleSubmit - values:%o url:%o', values, this.state.submitFunction);

		this.submitModal('send');

		// let FD = [];
		// Object.keys(values).map((key, index) => {
		// 	let val = encodeURIComponent(values[key]).replace(/[!'()*]/g, escape);
		// 	FD.push(key + '=' + val);
		// });
		// let sendData = FD.join('&');
		// let headers = { 'Content-type': 'application/x-www-form-urlencoded' };

		let sendData = {
			id: '2000',
			store: {
				data: values,
			},
		};
		let headers = { 'Content-type': 'application/json' };

		callFunction(this.state.submitHosts, this.state.submitFunction, 'post', sendData, headers)
			.then(res => {
				logger.log('[WimbledonWish] success submitting request: %o', res);
				//check for valid data
				if (res.statusCode == 200) {
					this.submitModal('success');
					this.measure('Submitted');
					this.setState({
						complete: true,
					});
				} else {
					this.submitModal('error');
				}
			})
			.catch(error => {
				logger.error('[WimbledonWish] error submitting media request: %o', error);
				this.submitModal('error');
			});
	}

	measure(status) {
		let data = {};
		data.pageTitle = 'WimbledonWish';
		data.status = status;
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, data);
	}

	submitModal(state) {
		if (state == 'send') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'WISH FROM WIMBLEDON',
				message: 'Your wish is being sent. Please do not close your browser window while processing.',
				useTimer: false,
			});
		} else if (state == 'success') {
			this.props.showModal({
				showModal: false,
			});
		} else if (state == 'error') {
			//logger.log('[WimbledonWish] updateModal - state: %o', state);

			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: 'WISH FROM WIMBLEDON',
				message: 'There was an error with your request. Please try again.',
				useTimer: true,
			});
		}
	}

	render() {
		logger.log('[WimbledonWish] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'generic_register',
			title: 'A Wish From Wimbledon',
			shortTitle: 'A Wish From Wimbledon',
			metaTitle: 'A Wish From Wimbledon',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		};

		let style = {
			style: 'four-col',
		};

		let contextValues = {
			user: op.get(this.state, 'currentUser', null),
			submit: this.handleSubmit,
		};
		logger.log('[WimbledonWish] render - contextValues:%o', contextValues);

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main news media-form" id="main" tabIndex="0">
					<div className="column-layout general">
						<div className="four-col heading">
							<h3 className="green upper">SEND US YOUR WISH</h3>
						</div>
					</div>

					<div className="column-layout news">
						<WimbledonWishContext.Provider value={contextValues}>
							{!this.state.loggedIn && !this.state.gigyaLoaded ? (
								<LoadingIndicator type="relative" />
							) : this.state.complete ? (
								<Fragment>
									<SectionDivider data={{ style: 'four-col', dividerStyle: 'light gray nomargin' }} />
									<div className="two-col margin-col bottom-margin center">
										Thank you for your submission.
									</div>
								</Fragment>
							) : !this.state.loggedIn && this.state.gigyaLoaded ? (
								<Fragment>
									<SectionDivider data={{ style: 'four-col', dividerStyle: 'light gray nomargin' }} />
									<div className="two-col margin-col bottom-margin center">
										Please login or register with myWimbledon to continue.
									</div>
									<div className="two-col margin-col body">
										<LoginWrapper />
									</div>
								</Fragment>
							) : (
								<RequestForm />
							)}
						</WimbledonWishContext.Provider>
					</div>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WimbledonWish);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import HillChannel from 'appdir/components/general/HillChannel';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveVideo
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveVideoHill'],
		...props,
		stubs: state['Config'].stubPages,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveVideo.mount()),
	unmount: () => dispatch(deps.actions.LiveVideo.unmount()),
});

class LiveVideoHill extends Component {
	constructor(props) {
		super(props);

		logger.log('[LiveVideoHill] constructor - props:%o', props);

		this.onVideoEvent = this.onVideoEvent.bind(this);

		this.state = {
			...props,
		};
	}

	componentDidMount() {
		logger.log('[LiveVideoHill] componentDidMount - state:%o', this.state);
	}

	componentWillUpdate(nextProps, nextState) {}

	componentWillUnmount() {
		//logger.log('[LiveVideoHill] componentWillUnmount');
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[LiveVideoHill] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[LiveVideoHill] componentDidUpdate - state:%o loadedJson:%o', this.state);
	}

	onVideoEvent(event) {
		logger.info('[LiveVideoHill] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				directVideo: '',
			});
		}
	}

	getHillChan() {
		if (this.state.stubs.hillvideo.stub == 'live') {
			return <HillChannel position="main" />;
		} else {
			return (
				<div className="live-video-stub">
					<div className="live-video-stubtext">
						<div className="live-video-stubtext--wrap">
							<div className="live-video-stubtext--title">OFF AIR</div>
							<div className="live-video-stubtext--msg">{this.state.stubs.hillvideo.text}</div>
						</div>
					</div>
				</div>
			);
		}
	}

	render() {
		logger.log('[LiveVideoHill] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'liveVideo',
			title: 'THE BIG SCREEN',
			shortTitle: 'THE BIG SCREEN',
			scroll: false,
			metaTitle: 'The Big Screen',
			metaDescription: 'Watch The Hill Big Screen as you queue, following logon go to: hill.wimbledon.com',
			metaDate: '',
			metaPlayers: '',
		};

		//logger.log('[LiveVideoHill] render - header:%o', header_propsData);

		// if have selected video and geos are set
		if (this.state.selected) {
			return (
				<Template>
					<div className="content-main thehill">
						<div className="column-layout">
							{this.state.selected == 'hill' ? this.getHillChan() : null}
							<div className="hill-links">
								<a href="http://www.wimbledon.com/">www.wimbledon.com</a>
								<br />
								<a href="http://www.wimbledon.com/en_GB/video/index.html">On Demand Video</a>
							</div>
						</div>
					</div>
				</Template>
			);
		} else {
			return (
				<Template>
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideoHill);

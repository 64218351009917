import React from 'react';
import SiteMap from 'appdir/components/general/SiteMap';

const NotFound = () => {
	return (
		<div className="four-col">
			<SiteMap notFound={true} />
			<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
		</div>
	);
};

export default NotFound;

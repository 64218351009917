import deps from 'dependencies';

import axios from 'axios';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[SeatingViews] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {};

			// logger.log('[SeatingViews] action.mount - data:');

			dispatch({ type: deps.actionTypes.SEATINGVIEWS_MOUNT });
		});
	},
};

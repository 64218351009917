import MeasurementUtils from 'appdir/lib/analytics';

export const trackingMiddleware = ({ exclude = [] } = {}) => store => next => action => {
	next(action);

	switch (
		action.type
		// Place holder
	) {
	}
};

import deps from 'dependencies';

export default (state = {}, action) => {
	switch (action.type) {
		case deps.actionTypes.FRAMEPAGES_MOUNT:
			return {
				title: action.title,
				src: action.src,
				height: action.height,
				social: action.social,
			};
		default:
			return state;
	}
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.HOMEPAGE_MOUNT:
			newState = {
				...state,
				status: 'loaded',
				pageData: action.data,
				// ...action.data,
			};
			// logger.log('[Upcoming] deps.actionTypes.UPCOMING_MOUNT - newState:%o', newState);
			return newState;
			break;
		case deps.actionTypes.HOMEPAGE_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[Upcoming] deps.actionTypes.UPCOMING_UNMOUNT - newState:%o', newState);
			return newState;
			break;
		default:
			return state;
	}
};

import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[VisitWebview] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[VisitWebview] action.mount');

			dispatch({ type: deps.actionTypes.VISITWEBVIEW_LOAD });
		});
	},
};

import React, { Component } from 'react';
import TableComponent from 'appdir/components/common-ui/TableComponent';

const createTableHeaders = () => ['', 'Singles', 'Doubles', 'Mixed', 'Total'];

const tableContent = (data = []) =>
	data.map(({ display, singles, doubles, mixed, total }) => (
		<tr key={i}>
			<td>{display}</td>
			<td>{singles}</td>
			<td>{doubles}</td>
			<td>{mixed}</td>
			<td>{total}</td>
		</tr>
	));

const formatTableData = (selectedData, statsSummary) =>
	Object.keys(statsSummary).map((item, i) => {
		let dname = selectedData[statsSummary[item].statName];

		return {
			display: statsSummary[item].display,
			singles: dname.singles.toString(),
			doubles: dname.doubles.toString(),
			mixed: dname.mixed.toString(),
			total: dname.total.toString(),
		};
	});

class PlayerMatchSummary extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
	}

	render() {
		let tableHeader = createTableHeaders();
		let statsSummary = [
			{ statName: 'matches_played', display: 'Matches Played' },
			{ statName: 'matches_won', display: 'Matches Won' },
			{ statName: 'matches_lost', display: 'Matches Lost' },
			{ statName: 'sets_won', display: 'Sets Won' },
			{ statName: 'sets_lost', display: 'Sets Lost' },
			{ statName: 'games_won', display: 'Games Won' },
			{ statName: 'games_lost', display: 'Games Lost' },
			{ statName: 'tie_breaks_won', display: 'Tie-breakers Won' },
			{ statName: 'tie_breaks_lost', display: 'Tie-breakers Lost' },
		];

		let selectedData = this.state;
		let tableRowData = formatTableData(selectedData, statsSummary);
		let matchStatsData = {
			headers: tableHeader,
			data: tableRowData,
			tableContainer: 'match-results',
			tableTitle: 'Match Statistics',
		};
		logger.log('[PlayerMatchSummary] matchStatsData:%o', matchStatsData);
		return (
			<div className="col-4 inline">
				<TableComponent attributes={matchStatsData} />
			</div>
		);
	}
}
export default PlayerMatchSummary;

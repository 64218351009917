/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SiteMap from 'appdir/components/general/SiteMap';

/**
 * -----------------------------------------------------------------------------
 * React Component: SiteMapPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = (dispatch, props) => ({});

class SiteMapPage extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		let header_propsData = {
			headerType: 'generic',
			title: 'Site Map',
			shortTitle: 'Site Map',
			metaTitle: 'Site Map',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main">
					<SiteMap />
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMapPage);

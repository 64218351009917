/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

const CenterMarkTitle = props => {

	let style = {

	}

	return (
		<span
			className={`centerMarkTitle`}
			aria-label={props.text}>
			<span className="">{props?.text}</span>
			<hr className="purpleStripe-short" />
		</span>
	);
};


/**
 * -----------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------
 */
export default CenterMarkTitle;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	//logger.log('[TicketsWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.TICKETRESALEWEBVIEW_LOAD:
			// logger.log('[TicketsWebview] reducers - action:%o',action);

			newState = {
				...state,
				...action.data,
			};
			// logger.log('[TicketsWebview] reducers TicketWebVIEW_LOAD - newState:%o',newState);
			return newState;
		//break;

		default:
			return state;
	}
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MyWimContext } from './context';
import op from 'object-path';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import ServiceItem from 'appdir/components/common-ui/ServiceItem';
import RoleIcons from 'appdir/components/cms/RoleIcons';

const mapStateToProps = (state, props) => {
	return {
		shelfIcons: state['Config'].myWIcons,
		iconPath: op.get(state['Config'], 'myWimbledon.iconPath', ''),
	};
};

class MyWimServices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};
	}

	componentDidMount() {
		logger.log('[MyWimServices] componentDidMount this %o', this);

		this.setRoles();
	}

	extractUserRoles(userRoleData) {
		return userRoleData.map((d, i) => {
			return d.roleId;
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, nextProps };
		});
	}

	componentDidUpdate(prevProps, prevState) {
		this.setRoles();
	}

	setRoles() {
		if (!this.state.userRoles) {
			let userRoles = op.get(this.context, 'currentUser.roles', []);
			this.setState(prevState => {
				return {
					...prevState,
					userRoles,
				};
			});
		}
	}

	getMyWimMessage() {
		//logger.log('[MyWimServices] getMyW - state:%o', this.state);

		return (
			<div className="column-layout">
				<div className="four-col no-services-available">There are no services available at this time.</div>
			</div>
		);
	}

	getIcons(icons, segment) {
		return (
			<Fragment>
				{icons.map((d, i) => {
					logger.log('[MyWimServices] getIcons - key:%o icon:%o ', segment + i, d);
					if (this.includeIcon(d)) {
						return (
							<ServiceItem
								title={d.title}
								link={this.getSegmentLink(d.url, segment)}
								src={`${op.get(this.state, 'iconPath', '')}${d.icon}`}
								target={d.target}
								key={segment + i}
							/>
						);
					} else {
						return null;
					}
				})}
			</Fragment>
		);
	}

	includeIcon(d) {
		if (op.get(d, 'show', 'myw').includes('myw')) {
			return true;
		} else {
			return false;
		}
	}

	getSegmentLink(link, segment) {
		return link.replace('<segment>', segment);
	}

	render() {
		let userRoles = op.get(this.state, 'userRoles', []);
		let roles = [...userRoles];

		//add the all role if logged in and there is an icon to show
		let allIcons = op.get(this.state, `shelfIcons.all`, []);
		allIcons = allIcons.filter(icon => {
			//logger.log('[MyWimServices] render icon:%o show:%o ', icon, icon.show);
			if (icon.show == '') {
				return false;
			} else {
				return true;
			}
		});
		if (this.context.loggedIn && allIcons.length > 0) {
			roles.push({
				key: 'all',
			});
		}

		//filter roles that are not in config
		roles = roles.filter(role => {
			if (op.get(this.state, `shelfIcons.titles.${role.key}`, false)) {
				return true;
			} else {
				return false;
			}
		});

		//filter roles based on config consitions
		roles = roles.filter(role => {
			if (role.key == 'debenture' && this.context.version < 2) {
				return false;
			} else if (role.key == 'ballot' && this.context.ballotData.period == 'pre') {
				return false;
			} else {
				return true;
			}
		});

		//sort roles by order in config
		roles = roles.sort((a, b) => {
			if (this.state.shelfIcons.titles[a.key].sort > this.state.shelfIcons.titles[b.key].sort) {
				return 1;
			} else if (this.state.shelfIcons.titles[a.key].sort < this.state.shelfIcons.titles[b.key].sort) {
				return -1;
			} else {
				return 0;
			}
		});

		logger.log('[MyWimServices] render roles:%o state:%o ', roles, this.state);

		if (this.context.loggedIn && roles.length > 0) {
			return (
				<div className="my-wimbledon-page">
					<span className="manage-benefits">
						{roles.map((d, i) => {
							logger.log('[MyWimServices] render - data:%o ', d);

							return <RoleIcons role={d.key} type="myw" key={d.key} />;

							//Ballot role
							// if (d.roleId == 1000060) {
							// 	return this.getBallot(d, 'bal')
							// }
							// else {
							// 	return this.getGeneric(d, 'gen')
							// }
						})}
					</span>
				</div>
			);
		} else if (this.context.loggedIn) {
			return <div className="my-wimbledon-page">{this.getMyWimMessage()}</div>;
		} else {
			return <LoadingIndicator />;
		}
	}
}
MyWimServices.contextType = MyWimContext;
export default connect(mapStateToProps)(MyWimServices);

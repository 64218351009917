/**
 DrawsArchiveEvents Initial State
*/

export default {
	year: '',
	host: '',
	eventsAll: '',
	drawsAllByEvent: '',
	filters: {
		eventId: 'MS',
		open: '',
	},
};

import React, { Component } from 'react';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { RoleRegistrationInviteContext } from './context';
import axios from 'axios';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';

class LoginWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		logger.log('[LoginWrapper] componentDidMount - context:%o', this.context);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'RoleRegistrationInvite',
			loggedin: false,
		});
	}

	render() {
		return (
			<GigyaScreenSet params={{ screen: 'login_screen', redirect: false, showSuccess: false }} inline={true} />
		);
	}
}
LoginWrapper.contextType = RoleRegistrationInviteContext;
export default LoginWrapper;

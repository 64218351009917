import deps from 'dependencies';

export default {
	mount: playerId => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let playerPath = Config.scoringData.playersDetail;
			let playerImage = Config.otherData.playerProfileImagePath;
			dispatch({ type: deps.actionTypes.PLAYER_MOUNT, data: { playerPath, playerImage } });
		});
	},
};

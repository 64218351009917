/**
 *  Path To The Final Standalone - Renders just Draw Paths
 *
 *  Based on the eventCode and playerId, fetch drawsData and drawsPath
 *  For 'MS' and 'LS', get Draws Analysis data <--- not used in
 *
 *  Redux state
 *  @prop {playerId} ---- required
 * 	@prop {event} ------- required  eventCode, e.g.) 'MS', 'LS'
 * 	@prop {metricsPageName} ------ optional used for a measurement call
 */

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import { values } from 'appdir/main';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {
	getCurrentRound,
	isSlamTrackerActive,
	isWinnerIsCompleted,
} from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { doMeasurement } from 'appdir/components/general/Analytics';

import CountryFlagImage from 'appdir/components/common-ui/CountryFlagImage';
import PlayerLink from 'appdir/components/common-ui/PlayerLink';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';

const PathToTheFinalStandalone = props => {
	const enabled = useSelector(state => state['Config']?.enabled);

	const selectedEvent = props?.event?.toUpperCase(); // seleted event - passed from requested action
	const playerId = props?.playerId; // serlected player - passed from requested action

	const flagImagePath = useSelector(state => state['Config']?.otherData?.flagImagePathSmall);

	/** Tournament Data */
	const tournamentPaths = useSelector(state => state['Tournament']?.paths);
	const drawData = useSelector(state => state['Tournament']?.data?.draw, isEqual);
	const drawPath = useSelector(state => state['Tournament']?.data?.drawPath, isEqual);

	/** Common Data */
	// const playerStatusData = useSelector(state => state['CommonData']?.playerStatus, isEqual);

	const mobileMatch = window.matchMedia('(max-width: 767px)');
	const tabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
	const isMSLS = selectedEvent == 'MS' || selectedEvent == 'LS'; // in case drawAnalysis is not enabled, check MS and LS event separate from isAiDraws
	// const isAiDraws = isMSLS && enabled?.drawAnalysis;

	const [status, setStatus] = useState(null);

	// const [favs, setAppFavs] = useState(props?.appsData?.appFavs);
	const [loadMore, setLoadMore] = useState({});
	const dispatch = useDispatch();

	const page = props?.metricsPageName ? props?.metricsPageName : 'draws';

	const passedStyle = `${props?.styleClass} ` || '';

	/** mount the component and load Config */
	useEffect(() => {
		setStatus('loaded');
	}, [selectedEvent && playerId && tournamentPaths?.drawsEvent]);

	/** if there is no Tournament drawData or
	 *  Tournament drawsData's event ID is
	 *  different from requested event ID,
	 *  get draws info based on the DrawsPage selected event */
	useEffect(() => {
		// logger.log('[PathtotheFinalStandalone], isEmpty(drawData):%o, tournamentPaths?.drawsEvent:%o, status:%o', isEmpty(drawData), tournamentPaths?.drawsEvent, status)
		if (
			tournamentPaths?.drawsEvent &&
			(isEmpty(drawData) || !drawData || drawData?.data?.eventId !== selectedEvent) &&
			status == 'loaded'
		) {
			dispatch(deps.actions.Tournament.getDraws({ event: selectedEvent }));
		}
	}, [selectedEvent, status, tournamentPaths?.drawsEvent]);

	/** get the requested player's drawPath data
	 *  drawData is required to determin the event id
	 *  in the getDrawPath() action
	 *
	 *  MS and LS - get Draw Analysis and Player Status data
	 */
	useEffect(() => {
		if (
			drawData?.status == 'loaded' &&
			drawData?.data?.eventId &&
			drawData?.data?.eventId == selectedEvent &&
			status == 'loaded' &&
			playerId
		) {
			/** get necessary Draw Analysis data only for MS and LS and Config.enabled.drawAnalysis == true */
			// if (isAiDraws) {
			// 	/** if drawAnalysis data for the player exists already, don't get it again */
			// 	if (drawAnalysisData?.data?.playerId !== playerId) {
			// 		dispatch(
			// 			deps.actions.Tournament.getDrawAnalysis({
			// 				eventId: selectedEvent,
			// 				playerId: playerId,
			// 			})
			// 		);
			// 	}
			// }

			/** if there is already drawPath data for the selected player, don't get it again */
			if (isEmpty(drawPath) || !drawPath || drawPath?.player?.idA !== playerId) {
				dispatch(deps.actions.Tournament.getDrawPath({ playerId }));
			}
		}
	}, [drawData, playerId, status]);

	const drawsFinalsPaths = drawPath?.data;
	const drawPathDataLoaded = drawPath?.status == 'loaded';
	// const playerData = drawPath?.player;

	/** make sure drawAnalysisData in Tounament Data is for the selected player */
	// const playerDrawAnalysisData = drawAnalysisData?.data?.playerId == playerId ? drawAnalysisData?.data : null;

	let currentRound = null;
	let drawDataMatches = drawData?.data?.matches;
	let showFinalColumn = isMSLS ? false : true;

	if (drawDataMatches) {
		currentRound = getCurrentRound(drawDataMatches);
		let isFinalScheduled = isWinnerIsCompleted(drawDataMatches);

		/** for MS and LS, check if the Final is scheduled in draws data */
		if (isMSLS && isFinalScheduled) {
			showFinalColumn = isFinalScheduled?.isTeam1Scheduled && isFinalScheduled?.isTeam2Scheduled ? true : false;
		}
	}

	logger.log(
		'[PathToTheFinalStandalone] props:%o, drawData:%o, drawPath:%o, flagImagePath:%o, currentRound:%o, status:%o, playerId:%o',
		props,
		drawData,
		drawPath,
		flagImagePath,
		currentRound,
		status,
		playerId
	);

	// to always return type string event when s may be falsy other than empty-string
	const capitalize = s => {
		return (s && s[0].toUpperCase() + s.slice(1)) || '';
	};

	let pageName = capitalize(page);

	/**
	 *
	 * @param {Object} data
	 *
	 *  "result":  "won" or "lost" or "vs"
	 *  "opponents": [Array] --- if 1, "Scheduled" match, if more than 1,  not scheduled possible future matches
	 */
	const getPathToTheFinalLabel = (data, index) => {
		let prevMatchData = index !== 0 ? drawsFinalsPaths[index - 1] : null;
		let isPrevMatchScheduled = false;

		/** if prev match is already scheduled, don't display Scheduled
		 *  opponent is already scheduled but not the selected player is
		 *  scheduled in the draws data
		 */
		if (prevMatchData) {
			isPrevMatchScheduled = prevMatchData?.opponents?.length == 1 && prevMatchData?.result == 'vs';
		}

		if (data?.result) {
			switch (data.result) {
				case 'won':
					return 'Defeated';
				case 'lost':
					return 'Lost to';
				case 'vs':
					return data.opponents.length == 1 && !isPrevMatchScheduled ? 'Scheduled' : 'Projected';
				default:
					break;
			}
		}
	};

	const getDrawPathTitle = () => {
		let title = '';
		// if (isAiDraws && drawPathDataLoaded && drawsFinalsPaths?.length > 0) {
		// 	let isAiPredictedDraws = drawsFinalsPaths.filter(rd => rd.result == 'vs');

		// 	title = isAiPredictedDraws?.length > 0 ? 'IBM Likely to Play:' : 'Played:';
		// }

		return title;
	};

	const sendSTMetrics = match_id => {
		let measureArg = [];
		let contextData = [{ pageTitle: 'Path to the Final' }, { match_id }];

		if (values.webview) {
			measureArg = ['Path to the Final'];
			contextData = [{ match_id }];
		}

		doMeasurement(`${pageName}`, 'Slamtracker click', measureArg, contextData);
	};

	const getSlamtrackerButton = (match_id, matchData) => {
		/** for the button that doesn't know the actual live status of the natch,
		 *  just pass an empty string so the button label will be somethin generic
		 *  and avoid the mismatched label, for example, keeps showing View Preview
		 *  while the match is Live
		 */
		let matchStatsCode = props?.matchStatusUnknown ? '' : matchData?.statusCode;
		return (
			<div className="slamtracker-button">
				<SlamtrackerButton
					match_id={match_id}
					slamtrackerActive={isSlamTrackerActive(matchData)}
					statusCode={matchStatsCode}
					styleClass={`green`}
				/>
			</div>
		);
	};

	const handleLoadMoreClick = (name, index) => {
		// logger.log(
		// 	'[PathToTheFinalStandalone] - handleLoadMoreClick - name:%o, loadMore:%o, pageName:%o, index:%o',
		// 	name,
		// 	loadMore,
		// 	pageName,
		// 	index
		// );
		setLoadMore({
			[name]: !loadMore?.[name],
		});

		let metricsVal = [];
		let contextData = [
			{ item: 'Load More' },
			{ action: loadMore?.[name] ? 'Close' : 'Open' },
			{ player_id: playerId },
			{ round_number: index + 1 },
		];

		if (values.webview) {
			metricsVal = ['Load More', loadMore?.[name] ? 'Close' : 'Open'];
			// contextData = [{ player_id: playerId }, { round_number: index + 1 }];
			contextData = [{ player_id: playerId }];
		}

		doMeasurement(`${pageName}`, `Path To The Final`, metricsVal, contextData);
	};

	/**
	 *  .first ---- first draw-path data or lost at the first round, mobile order starts at top 50%
	 *  .last ----- the last draw-path data, don't draw a line to connect to the next element
	 *  .no-line -- if lost, don't draw a line to connect to the next element
	 *  .dashed --- for future draws
	 */
	const getBorderClass = (index, item, roundName) => {
		let borderClass = '';

		if (index == 0 && item?.result !== 'lost') {
			borderClass += 'first ';
		} else if (
			index == drawsFinalsPaths.length - 1 ||
			(showFinalColumn && roundName == 'Finals') ||
			(!showFinalColumn && roundName == 'Semi-Finals') ||
			item?.result === 'lost'
		) {
			borderClass += 'last ';
		} else if (item?.result === 'lost') {
			borderClass += 'line ';
		}

		if (item?.result && item?.result !== 'vs' && item?.result !== 'lost') {
			borderClass += '';
		} else {
			borderClass += 'dashed ';
		}

		return borderClass;
	};

	/**
	 *
	 * @returns renders draws paths
	 */
	const renderDrawsPaths = () => {
		if (drawPathDataLoaded && drawsFinalsPaths?.length > 0) {
			return drawsFinalsPaths.map((item, i) => {
				let label = getPathToTheFinalLabel(item, i);
				let matchData = drawData?.data?.matches.filter(game => game.match_id == item.match);
				let roundName = matchData?.[0]?.roundName ? matchData?.[0]?.roundName : '';

				// logger.log(
				// 	'[PathToTheFinalStandalone] label:%o, roundName:%o, matchData:%o',
				// 	label,
				// 	roundName,
				// 	matchData
				// );

				let borderClass = getBorderClass(i, item, roundName);

				/** don't display the Finals column for MS and LS events until Final is scheduled,
				 *  all the other events, display Finals colum from the first round
				 *  don't display the ST button if there is no opponent assigned
				 *
				 */
				if (item?.opponents?.length > 0 && (showFinalColumn || (!showFinalColumn && roundName !== 'Final'))) {
					/**
					 *  Condition to show ST Button:
					 *
					 *  showRecapButton
					 *  	- lost OR
					 *  	- "won" and next match in drawPath result is NOT "won" or "lost" AND next match in drawPath is projected or scheduled
					 *        "opponents": [Array] --- if 1, "Scheduled" match, if more than 1,  not scheduled possible future matches
					 *
					 *  showPreviewButton
					 * 		- first round OR last match in drawPath result is "won" AND
					 * 		- drawPath result is NOT "won" or "lost" AND
					 * 		- opponents is only 1 in drawPath meaning the match is scheduled
					 * */
					let showRecapButton =
						item?.result == 'lost' ||
						(item?.result == 'won' &&
							drawsFinalsPaths?.[i + 1]?.result !== 'won' &&
							drawsFinalsPaths?.[i + 1]?.result !== 'lost' &&
							(drawsFinalsPaths?.[i + 1]?.opponents?.length > 1 ||
								drawsFinalsPaths?.[i + 1]?.opponents?.length !== 1));
					let showPreviewButton =
						(drawsFinalsPaths?.[i - 1]?.result == 'won' || i == 0) &&
						item?.result !== 'won' &&
						item?.result !== 'lost' &&
						item?.opponents?.length == 1;
					let showStButton = (matchData?.length > 0 && showRecapButton) || showPreviewButton;

					return (
						<section className="draws-path" key={`${item.match} ${item.round} ${i}`}>
							<div className={`round-header ${borderClass}`}>
								<h5>
									{
										/** draw a top half solid border for mobile if the previous match won */
										borderClass.indexOf('dashed') > -1 &&
											i !== 0 &&
											drawsFinalsPaths?.[i - 1]?.result == 'won' && (
												<span className="half-solid"></span>
											)
									}
									{roundName}

									{
										/** only for mobile view (set in css)
										 *   and for future or current/scheduled match, show draw label for MS and LS
										 *
										 */
										// isAiDraws && item?.result == 'vs' && (
										// 	<div className={`draw-label ${item.label}`}>{item.label}</div>
										// )
									}
								</h5>
							</div>
							<div className={`round-block ${borderClass}`}>
								{item.opponents.map((player, index) => {
									let playerB = player.nameB;
									let isDoubles = playerB && playerB !== '';
									let seed = player?.seed ? player.seed : '';

									/** for mobile only (set in css), display only one opponent,
									 *  show the rest of players when Load More is clicked */
									let hideMoreOpponentClass =
										(index > 0 && loadMore?.[`load-more${i}`]) || index == 0
											? ''
											: 'hide-more-opponent ';
									if (index > 0 && !loadMore) {
										hideMoreOpponentClass = 'hide-more-opponent ';
									}

									if (player.playerIdA) {
										return (
											<div
												className={`${
													isDoubles ? 'opponent-doubles' : 'opponent'
												} name ${borderClass} ${hideMoreOpponentClass}`}
												key={`${item.match} opponent ${player.playerIdA} ${index}`}>
												{label && label !== '' && <label className={label}>{label}</label>}
												<div className="name">
													<div className="flag">
														<CountryFlagImage
															attributes={{
																country: player.nationA,
																flagImagePath,
															}}
														/>
													</div>
													<PlayerLink
														attributes={{
															playerId: player.playerIdA,
															name: player.nameA,
														}}
													/>{' '}
													<span>{seed}</span>
												</div>

												{isDoubles ? (
													<div className="name">
														<div className="flag">
															<CountryFlagImage
																attributes={{
																	country: player.nationB,
																	flagImagePath,
																}}
															/>
														</div>
														<PlayerLink
															attributes={{
																playerId: player.playerIdB,
																name: playerB,
															}}
														/>{' '}
														<span>{seed}</span>
													</div>
												) : null}
											</div>
										);
									} else {
										/** no opponent player - put an empty element so mobile has some space above the next round */
										return (
											<div className={`name`} key={`${item.match} opponent ${index}`}>
												<div className="name">&nbsp;</div>
											</div>
										);
									}
								})}

								{
									/** place the SlamTracker button for the last match of lost players
									 *   or current round's if the player is still in
									 */
									showStButton && getSlamtrackerButton(item.match, matchData?.[0])
								}

								{
									/** only for mobile (set with css), show Load More link */
									item?.opponents?.length > 1 && (
										<ButtonLinkText
											className="load-more"
											name={''}
											onClick={() => handleLoadMoreClick(`load-more${i}`, i)}>
											{loadMore?.[`load-more${i}`] ? 'See Less' : 'Load More'}
										</ButtonLinkText>
									)
								}
							</div>
						</section>
					);
				}
			});
		}
	};

	/** make sure there are data to display draw path */
	const areThereOpponents = () => {
		let found = false;
		if (drawsFinalsPaths?.length > 0) {
			found = drawsFinalsPaths?.filter(
				opponent => opponent.opponents?.length > 0 && opponent?.opponents?.[0] !== undefined
			);
		}

		return found?.length > 0 ? true : false;
	};

	// const aiDrawsClass = isAiDraws ? 'ai-draws ' : '';
	const aiDrawsClass = '';
	const found = drawsFinalsPaths ? areThereOpponents() : 'loading';

	let wrapperClassName = 'draws-path-wrapper ';
	wrapperClassName += passedStyle; // pass "full" to make sure full Path to the Final scrolls vertically when the list gets longer
	// wrapperClassName += isAiDraws ? 'with-draw-analysis ' : '';

	return (
		<div className={`path-to-the-final-wrapper standalone`}>
			<div className={`path-to-the-final standalone ${aiDrawsClass}`}>
				{drawPath?.status == 'error' ? (
					<div className="player-wrapper">
						Path to the Final for the selected player is not available at this time.{' '}
					</div>
				) : drawPath?.status == 'loading' || drawPath?.status !== 'loaded' ? (
					<section className="player-wrapper">
						<LoadingIndicator />
					</section>
				) : (
					<>
						{
							/** flex layout may break by adding react fragment by sharing the parent
							 *    element, section.draws-path-wrapper
							 *    as data are not available to test, I am putting the same structure for
							 *    both with data path and no data path to avoid layout issues
							 */
							found && found !== 'loading' ? (
								<section className={wrapperClassName}>
									{page == 'draws' && <h3 className="black">{getDrawPathTitle()}</h3>}

									<div className="draws-path-inner-wrapper">{renderDrawsPaths()}</div>
								</section>
							) : (
								<section className={wrapperClassName}>
									<p className="no-data">There is no data found for the player.</p>
								</section>
							)
						}
					</>
				)}
			</div>
		</div>
	);
};

export default PathToTheFinalStandalone;

import React from 'react';
import filter from 'lodash/filter';
import findLastKey from 'lodash/findLast';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInAppContext } from 'appdir/components/general/Analytics';

import op from 'object-path';
// import KeyTranslate from './Keys/KeyTranslate';

export const getNumFromString = str => {
	return str.replace(/\D/g, '');
};

export const isNumeric = data => {
	return !isNaN(parseFloat(data)) && isFinite(data);
};

export const getServeTeam = serveVal => {
	if (serveVal == '1' || serveVal == '3') {
		return '1';
	} else {
		return '2';
	}
};

export const isDoublesMatch = scoreData => {
	//logger.log('[Slamtracker Utilities] isDoublesMatch scoreData:%o, team1 type:%o, team2 type:%o', scoreData, typeof scoreData.team1, typeof scoreData.team2);
	// awt:  for some reason typeof array not working.  wierd.
	if(scoreData) {
		if (typeof scoreData.team1 === 'array' || scoreData?.team1?.[0]) {
			if (scoreData?.team1?.[0]?.idB || scoreData?.team2?.[0]?.idB) {
				return true;
			} else {
				return false;
			}
		} else {
			if (scoreData?.team1?.idB || scoreData?.team2?.idB) {
				return true;
			} else {
				return false;
			}
		}
	} else {
		return false;
	}
};

export const useMatchExtras = match => {
	/**
	 * check for A or MS for mens and B or LS for womens
	 * MS and MS are in the upcoming data
	 */
	if(match.eventCode == "A" || match.eventCode == "MS" || match.eventCode == "B" || match.eventCode == "LS") {
	 	return true;
	} else {
		return false;
	}
};

export const useAIContent = match => {
	/**
	 * check for A or MS for mens and B or LS for womens
	 * MS and MS are in the upcoming data
	 */
	if(match.eventCode == "A" || match.eventCode == "MS" || 
		match.eventCode == "B" || match.eventCode == "LS" || 
		match.eventCode == "F" || match.eventCode == "SW" || 
		match.eventCode == "G" || match.eventCode == "SM") {
	 	return true;
	} else {
		return false;
	}
};

export const arrayToObject = (array, keyField) =>
	array.reduce((obj, item) => {
		obj[item[keyField]] = item;
		return obj;
	}, {});

export const getLastPointData = pointsBySet => {
	let lastSet = Object.keys(pointsBySet).reverse()[0];
	let lastGame = Object.keys(pointsBySet[lastSet]).reverse()[0];
	let numPoints = Object.keys(pointsBySet[lastSet][lastGame]).length;
	let lastPoint = findLastKey(pointsBySet[lastSet][lastGame]);
	// so this lastPoint object is the wrong data.
	return { numPoints, lastSet, lastGame, lastPoint };
};

export const getStatTotal = (statName, setNo, pointNumber, pointHistory) => {
	try {
		let objArray;

		if (setNo != 0) {
			objArray = filter(pointHistory, function(e) {
				return parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) && e.SetNo == setNo;
			});
		} else {
			objArray = filter(pointHistory, function(e) {
				return parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10);
			});
		}

		let t1val = 0;
		let t2val = 0;

		if (statName !== 'NetPoint') {
			//ok so i think net points is the only one where a 3 will be counted for team 1 and 2
			// all other stats a 3 is for team 1 player b and a 4 is for team 2 player b
			t1val = filter(objArray, function(e) {
				return e[statName] == '1' || e[statName] == '3';
			}).length;
			t2val = filter(objArray, function(e) {
				return e[statName] == '2' || e[statName] == '4';
			}).length;
		} else {
			t1val = filter(objArray, function(e) {
				return e[statName] == '1' || e[statName] == '3';
			}).length;
			t2val = filter(objArray, function(e) {
				return e[statName] == '2' || e[statName] == '3';
			}).length;
		}

		return new Array(t1val, t2val);
	} catch (e) {
		logger.log('getStatTotal ERROR - ' + e);
	}
};

export const getDoubleDonutData = (set, point, numerator, denominator, pointHistory) => {
	let num = getStatTotal(numerator, set, point, pointHistory);
	let den = getStatTotal(denominator, set, point, pointHistory);

	let p1pct = 0;
	let p2pct = 0;

	if (den[0] != 0) {
		p1pct = Math.round((parseInt(num[0], 10) / parseInt(den[0], 10)) * 100);
		if (isNaN(p1pct)) {
			p1pct = 0;
		}
	}

	if (den[1] != 0) {
		p2pct = Math.round((parseInt(num[1], 10) / parseInt(den[1], 10)) * 100);
		if (isNaN(p2pct)) {
			p2pct = 0;
		}
	}

	// create data object
	let dd_data = {
		p1: {
			series: [p1pct, 100 - p1pct],
			chart_value: p1pct,
			num: num[0],
			den: den[0],
		},
		p2: {
			series: [p2pct, 100 - p2pct],
			chart_value: p2pct,
			num: num[1],
			den: den[1],
		},
	};

	return dd_data;
};

export const stage_items = ['dst', 'ret', 'trk', 'fsp', 'fsw', 'npw', 'rpw', 'ssw', 'ace', 'dbf', 'win', 'asp', 'kik'];

/**
 * This function takes the stage item value and matches it against a regular expression to see if its
 * a situational stage item
 */
export const isStageSituational = text => {
	var isMatch = false;
	if (
		text.match(/dg[1-6]/g) ||
		text.match(/d[0-2][0-2]s/g) ||
		text.match(/[ud][1-3]b[1-2]/g) ||
		text.match(/mb[1-2]/g)
	) {
		isMatch = true;
	}
	return isMatch;
};

export const isStageKeys = text => {
	let isMatch = false;
	if (text.match(/p[1-2]k[1-3]/g)) {
		isMatch = true;
	}
	return isMatch;
};

/** copied from USOpen to use on Match Insights */
/**
 * functions to render keys, putting them here so I can reuse them
 * for match insights overlay
 */
const getKeyClass = goal_reached => {
	let key_class = 'unmet';

	if (goal_reached == '1') {
		key_class = 'keymet';
	} else if (goal_reached == '0') {
		key_class = 'unmet';
	}

	return key_class;
};

// export const renderKey = (team, key, keyValue, index, mi) => {
// 	// logger.log('[SlamtrackerKeys] renderKey - keyValue:%o ', keyValue);
// 	let isMatchInsights = false;

// 	if (mi && mi === true) {
// 		isMatchInsights = true;
// 	}

// 	if (index < 3) {
// 		return (
// 			<div className={`${team}_${key} key col-xs-12`} key={key}>
// 				<div className="summary">
// 					<div className="text">
// 						<KeyTranslate keyId={keyValue.key_id} goal1={keyValue.goals[0]} goal2={keyValue.goals[1]} />
// 					</div>
// 					{!isMatchInsights ? (
// 						<div className="boxes">
// 							<div
// 								className={`set s1 ${
// 									keyValue.goal_reached['set_1'] ? getKeyClass(keyValue.goal_reached['set_1']) : ''
// 								}`}
// 							/>
// 							<div
// 								className={`set s2 ${
// 									keyValue.goal_reached['set_2'] ? getKeyClass(keyValue.goal_reached['set_2']) : ''
// 								}`}
// 							/>
// 							<div
// 								className={`set s3 ${
// 									keyValue.goal_reached['set_3'] ? getKeyClass(keyValue.goal_reached['set_3']) : ''
// 								}`}
// 							/>
// 							<div
// 								className={`set s4 ${
// 									keyValue.goal_reached['set_4'] ? getKeyClass(keyValue.goal_reached['set_4']) : ''
// 								}`}
// 							/>
// 							<div
// 								className={`set s5 ${
// 									keyValue.goal_reached['set_5'] ? getKeyClass(keyValue.goal_reached['set_5']) : ''
// 								}`}
// 							/>
// 						</div>
// 					) : null}
// 				</div>
// 			</div>
// 		);
// 	}
// };

export const doMeasurement = (measureAction, measure_args, statMatch) => {
	if (window.location.href.indexOf('suite') == -1) {
		let matchStatus;
		let measureArray;

		if (statMatch.status == 'Complete') {
			matchStatus = 'Complete';
		} else {
			matchStatus = 'Live';
		}

		if (typeof webview === 'undefined') {
			measureArray = ['Slamtracker', matchStatus, 'Match Stats'].concat(measureAction).concat(measure_args);

			MeasurementUtils.dispatchMeasurementCall(measureAction, {
				data: measureArray,
			});
		} else {
			measureArray = ['Metrics', 'Slamtracker', matchStatus].concat(measureAction).concat(measure_args);

			measureInAppContext({
				data: measureArray,
			});
		}
	}
};

export const getStatusConfig = (statusCode) => {
	const preMatch = ['B'];
	const liveMatch = ['A', 'K', 'X', 'Y'];
	const postMatch = ['D', 'E', 'F', 'G'];

	let statusConfig = {}
	
	if (preMatch.includes(statusCode)) {
		statusConfig.data = 'preview'; //preview
		statusConfig.text = ' Preview';
		statusConfig.status = 'pre';
	} else if (liveMatch.includes(statusCode)) {
		statusConfig.data = 'live';
		statusConfig.text = ' Live';
		statusConfig.status = 'live';
	} else if (postMatch.includes(statusCode)) {
		statusConfig.data = 'summary';
		statusConfig.text = ' Summary';
		statusConfig.status = 'post';
	}

	return statusConfig;
}

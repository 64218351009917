/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';
import { OIDCContext } from './context';
import isEmpty from 'lodash/isEmpty';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Script from 'react-load-script';
import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SiteMap from 'appdir/components/general/SiteMap';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import Login from './Login';
import Logout from './Logout';
import Error from './Error';
import Consent from './Consent';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledonLoginOIDC
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['MyWimbledonLoginOIDC'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

const clientIds = ['OIFt_AVHIDVmaiD74lPJlew6', 'l_7KxbnqYrHDu8-VvOp6Sk-h'];

class MyWimbledonLoginOIDC extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.clientId;
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.gigyaApiKey && this.clientId == null) {
			let cookie_value = sessionStorage['gigyaCache'];
			let oidc_clientId;
			if (cookie_value) {
				let cookie_obj = JSON.parse(cookie_value);
				let cookie_data = cookie_obj[`gig_oidcContext_${nextProps.gigyaApiKey}`]?.response;
				this.clientId = cookie_data ? cookie_data[0].clientID : undefined; // This will be the client_id of the RP that the user was referred from
			}
		}

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		logger.log('[MyWimbledonLoginOIDC] componentDidUpdate - state:%o', this.state);
	}

	render() {
		logger.log('[MyWimbledonLoginOIDC] render - test:%o state:%o', 'test', this.state);

		let header_propsData = {
			headerType: 'mywimbledon',
			title: 'myWimbledon',
			shortTitle: 'myW',
			metaTitle: 'myWimbledon',
			metaDescription: 'myWimbledon - Wimbledon ' + values.tournamentYear,
			metaDate: '',
			metaPlayers: '',
		};

		let contextValues = {
			currentUser: this.state.currentUser,
			loggedIn: this.state.loggedIn,
			gigyaLoaded: this.state.gigyaLoaded,
			api: this.state.api,
			proxy: this.state.proxy,
			social: true,
		};

		//logger.log('[MyWimbledonLoginOIDC] render - token:%o context:%o', op.get(contextValues, 'jwt.id_token', false), contextValues);

		// let gigyaParams = {
		// 	"screenSet": 'simple-screen-set',
		// 	"startScreen": 'edit-profile'
		// }

		if (clientIds.includes(this.clientId)) {
			contextValues.social = false;

			return (
				<Template>
					<div className="content-main column-layout general">
						<div className="four-col">
							<OIDCContext.Provider value={contextValues}>
								{this.state.action == 'login' ? (
									<Login />
								) : this.state.action == 'logout' ? (
									<Logout />
								) : this.state.action == 'consent' ? (
									<Consent />
								) : this.state.action == 'error' ? (
									<Error />
								) : null}
							</OIDCContext.Provider>
						</div>
					</div>
				</Template>
			);
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout general">
						<div className="four-col">
							<OIDCContext.Provider value={contextValues}>
								{this.state.action == 'login' ? (
									<Login />
								) : this.state.action == 'logout' ? (
									<Logout />
								) : this.state.action == 'consent' ? (
									<Consent />
								) : this.state.action == 'error' ? (
									<Error />
								) : null}
							</OIDCContext.Provider>
						</div>
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWimbledonLoginOIDC);

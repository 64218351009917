import React from 'react';
import InputBox from './elements/InputBox';

const ChangePassword = ({ data }) => {
	//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
	let classname = 'gigya-screen v2 portrait';

	//gigya-conditional:show-password-strength="viewport.width < 900?up"
	let strengthInfo = 'up';

	return (
		<div
			id="gigya-password-change-required-screen"
			data-width="auto"
			className={classname}
			data-caption="screenset.translations['GIGYA_PASSWORD_CHANGE_REQUIRED_SCREEN_CAPTION']">
			<form
				className="gigya-profile-form"
				id="gigya-profile-form"
				data-on-success-screen="gigya-reset-password-success-screen">
				<div className="gigya-layout-row">
					<label
						className="gigya-composite-control gigya-composite-control-label main-text letter-spacing-s"
						data-translation-key="LABEL_PASSWORD_CHANGE_LABEL"
					/>
					<div
						className="gigya-composite-control gigya-composite-control-password eyeDiv2"
						style={{ position: 'relative' }}>
						<InputBox
							data={{
								type: 'password',
								name: 'password',
								key: 'TEXTBOX_PASSWORD',
								showValid: 'true',
								showStrength: '',
								class: 'gigya-input-password',
							}}
						/>
					</div>
					<div className="gigya-composite-control gigya-composite-control-password eyeDiv3">
						<InputBox
							data={{
								type: 'password',
								name: 'newPassword',
								key: 'TEXTBOX_PASSWORD_NEW_LABEL',
								showValid: 'true',
								showStrength: strengthInfo,
								class: 'gigya-input-password',
							}}
						/>
					</div>
					<div
						className="gigya-composite-control gigya-composite-control-password eyeDiv4"
						style={{ display: 'block' }}>
						<InputBox
							data={{
								type: 'password',
								name: 'passwordRetype',
								key: 'TEXTBOX_PASSWORD_NEWCONFIRM_LABEL',
								showValid: 'true',
								class: 'gigya-input-password',
								required: 'true',
							}}
						/>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell" />
					<div className="gigya-layout-cell" />
					<div className="gigya-clear" />
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row">
							<div className="gigya-layout-cell" />
							<div className="gigya-layout-cell" />
							<div className="gigya-clear" />
						</div>
					</div>
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row">
							<div className="gigya-layout-cell" />
							<div className="gigya-layout-cell" />
							<div className="gigya-clear" />
						</div>
					</div>
					<div className="gigya-clear" />
				</div>
				<div className="gigya-layout-row" />
				<div className="gigya-layout-row">
					<div
						className="gigya-composite-control gigya-composite-control-submit"
						style={{ display: 'block' }}>
						<input
							type="submit"
							className="gigya-input-submit"
							tabIndex="0"
							value="screenset.translations['BUTTON_SUBMIT_VALUE']"
						/>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div
						className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
						data-bound-to="gigya-profile-form">
						<div className="gigya-error-msg gigya-form-error-msg" data-bound-to="gigya-profile-form" />
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row">
							<div className="gigya-layout-cell" />
							<div className="gigya-layout-cell" />
							<div className="gigya-clear" />
						</div>
					</div>
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row">
							<div className="gigya-layout-cell" />
							<div className="gigya-layout-cell" />
							<div className="gigya-clear" />
						</div>
					</div>
					<div className="gigya-clear" />
				</div>
				<div className="gigya-layout-row" />
				<div className="gigya-clear" />
			</form>
		</div>
	);
};

export default ChangePassword;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Info icon for Match Insights
 * onClick shows Likelihood Win overlay
 * -----------------------------------------------------------------------------
 */
const MIInfoIcon = props => {
	return (
		<i
			className="wim-icon-info"
			onClick={() => {
				props.onInfoClickFn();
			}}
		/>
	);
};

export default MIInfoIcon;

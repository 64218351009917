import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const PrivacyPolicy = data => {
	//logger.log('[Gigya] [PrivacyPolicy] data:%o', data);

	let version = '';
	let approved = '';

	if (data && data.granted) {
		version = moment(data.docDate)
			.tz('Europe/London')
			.format('DD/MM/YYYY')
			.toString();
		approved = moment(data.modifiedDate)
			.format('ll')
			.toString();
	}

	return (
		<span>
			<div
				className="gigya-container gigya-visible-when"
				data-condition="true || !preferences || !preferences.privacy || !preferences.privacy.PrivacyPolicy || !preferences.privacy.PrivacyPolicy.isConsentGranted"
				data-on-render="false">
				<div className="gigya-composite-control gigya-composite-control-checkbox">
					<input
						type="checkbox"
						id="gigya-checkbox-privacy"
						name="preferences.privacy.PrivacyPolicy.isConsentGranted"
						className="gigya-input-checkbox"
						data-display-name=""
						tabIndex={data.tabIndex ? data.tabIndex : '0'}
						disabled={data && data.granted}
					/>
					<label className="gigya-label" data-binding="true" htmlFor="gigya-checkbox-privacy">
						<label
							className="gigya-required-display"
							data-bound-to="preferences.privacy.PrivacyPolicy.isConsentGranted"
							style={{}}
							data-binding="true"
							htmlFor="gigya-checkbox-privacy">
							*
						</label>
						<span
							className="gigya-label-text gigya-checkbox-text"
							data-translation-key="CHECKBOX_PRIVACY_LABEL"
						/>

						{version != '' && approved != '' ? (
							<span className="gigya-consent-dates">
								<div>
									Version: {version} - Approved on {approved}
								</div>
							</span>
						) : (
							''
						)}
					</label>
				</div>
			</div>
		</span>
	);
};

export default PrivacyPolicy;

/*

<div className="gigya-composite-control gigya-composite-control-consent-widget">
	<div
		className="gigya-consent"
		name="preferences.privacy.PrivacyPolicy"
		data-array-root="preferences.privacy"
		data-label="Privacy Policy:"
	/>
</div>

*/

import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class SkipBack extends Component {
	
	constructor(props) {
		super(props);
		this.styles = props.styles;

		//logger.log('SkipBack: constructor - props:%o', this.props);
	}

	onClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}
	
	render() {
		//logger.log('SkipBack: render - state:%o', this.state);
		let inline = {};
		
		return (
			<button 
				className={`${this.styles.icon} ${this.styles.skipBack}`} 
				style={inline} 
				onClick={() => {this.onClick()}} 
				title="SkipBack">
			</button>
		)
	}
	
}

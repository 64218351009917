import React, { Component } from 'react';
import Script from 'react-load-script';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import updateTimeIncrement from 'appdir/components/common-ui/TimeIncrement/index.js';

/* NOTE: QR code generator came from: https://www.jsnow.io/p/javascript/creating-a-real-time-qr-code-scanner-with-vanilla-javascript-part-1 */

const smallMobileMatch = window.matchMedia('(max-width: 320px)').matches;

class WebviewResaleQRcode extends Component {
	constructor(props) {
		super(props);
		this.state = {
            ...props
        }
	}

    componentDidUpdate() {
        if (this.state.scriptLoaded && !this.state.qrCodeGenerated) {
            const { booking_id } = this.props;
            if (document.querySelector('.webview-resale-qr-code')) {
                this.generate({ booking_id })
            }
        }
    }

    handleScriptCreate() {
		this.setState({ scriptLoaded: false });
	}

	handleScriptError() {
		this.setState({ scriptError: true });
	}

	handleScriptLoad() {
		this.setState({ scriptLoaded: true });
	}

	generate(values) {
		document.querySelector('.webview-resale-qr-code').style = '';

		let { booking_id } = values;

		let txtStr = `${booking_id}`.trim(); // trim whitespace

		new QRCode(document.querySelector('.webview-resale-qr-code'), {
			text: txtStr,
			display: 'inline',
			width: 178, 
			height: 178, 
			colorDark: '#000000',
			colorLight: '#ffffff',
			correctLevel: QRCode.CorrectLevel.H,
		});

        this.setState({ qrCodeGenerated: true });
	}

	render() {
		if (document.getElementById("webview-resale-qr-code-timestamp")) {
			updateTimeIncrement(document.getElementById("webview-resale-qr-code-timestamp"));
		}

		return (
			<div className='webview-resale-qr-code'>
                <Script
                    url="/en_GB/static/qr/generateQRcode.js"
                    onCreate={this.handleScriptCreate.bind(this)}
                    onError={this.handleScriptError.bind(this)}
                    onLoad={this.handleScriptLoad.bind(this)}
                />
                <div className='webview-resale-qr-code-timestamp' id='webview-resale-qr-code-timestamp'>{moment().tz('Europe/London').format('HH:mm:ss')}</div>
				<div
					alt="qr code image"
					aria-label="qr code image"
					className="webview-resale-qr-code" // ticket-swap-qr-code in TicketSwapInitial for reference code
					style={{ display: 'none' }}
				/>
				<div className='webview-resale-qr-code-animation'>
					<img className="webview-resale-qr-code-animation-img"  src="https://assets.wimbledon.com/images/queue/BallBounce.gif" alt="ball animation"  width="250" />
				</div>
				
				<div className='webview-resale-qr-code-bookingID'>Booking ID: {this.props.booking_id}</div>
			</div>
		);
	}
}

export default WebviewResaleQRcode;

import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		//logger.log('[Auth]  deps.actions.mount data:%o',data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[MainNav] mount - data:%o', Config.menus);

			dispatch({ type: deps.actionTypes.LOGIN_MOUNT, data: {} });
		});
	},
	unmount: data => (dispatch, getState, store) => {
		//logger.log('[Login]  deps.actions.unmount data:%o',data);
		dispatch({ type: deps.actionTypes.LOGIN_UNMOUNT, data: data });
	},
	login: data => (dispatch, getState, store) => {
		//logger.log('[Login]  deps.actions.login data:%o',data);
		dispatch({ type: deps.actionTypes.LOGIN_LOGIN, data: data });
	},
	gigyaState: data => (dispatch, getState, store) => {
		//logger.log('[Login]  deps.actions.gigyaState data:%o',data);
		dispatch({ type: deps.actionTypes.LOGIN_GIGYA, data: data });
	},
};

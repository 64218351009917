import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: term => (dispatch, getState, store) => {
		logger.log('[DrawsArchiveTopStats] loadStatsConfig:%o', term);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Draws Archive Year',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				statsDataPath: Config.drawsArchive.statsData,
			};
			logger.log('[DrawsArchiveTopStats] services:%o', data);
			dispatch({ type: deps.actionTypes.DRAWSARCHIVE_TOP_STATS_LOAD, data: data });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_TOP_STATS_FILTER, data: data });
	},
};

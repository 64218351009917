import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.DRAW_MOUNTED: {
			let newState = { ...state, DrawConfig: action.data };
			return newState;
			break;
		}
		default:
			return state;
	}
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import { NavLink } from 'react-router-dom';
import JSXParser from 'react-jsx-parser';

const mapStateToProps = (state, props) => {
	return {
		weatherData: state['WeatherData'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.WeatherStatus.mount()),
	check: () => dispatch(deps.actions.WeatherStatus.check()),
});

class WeatherStatus extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		//logger.log('[WeatherStatus] state:%o', this.state);
	}

	componentDidMount() {
		//logger.log('[WeatherStatus] componentDidMount');
		this.props.mount();
	}

	componentWillUnmount() {
		//reset redux state, so on next load, get fresh data/weather data check
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[WeatherStatus] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		let loaded = false;
		if (nextProps.weatherData && nextProps.weatherData.status == 'loaded') {
			loaded = true;
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				hasWeatherData: loaded,
			};
		});
	}

	render() {
		//logger.log('[WeatherStatus] render - state:%o', this.state);

		let { weatherData } = this.state;

		if (!this.props.isTournament) {
			return null;
		} else if (this.state.hasWeatherData) {
			return (
				<NavLink to="/en_GB/weather/index.html">
					<div className="weather" alt="weather">
						<span className="icon">
							<img src={weatherData.current.icon_path} width="29" height="29" alt="weather" />
						</span>
						<span className="description h3">{weatherData.current.phrase_32char}</span>
					</div>
				</NavLink>
			);
		} else {
			return (
				<div className="weather">
					<div className="description">Loading...</div>
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherStatus);

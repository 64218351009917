import React, { Component } from 'react';
import MatchBox from 'appdir/components/scoring/MatchBox';
import RelatedContent from 'appdir/components/cms/RelatedContent';
import PlayerImage from 'appdir/components/content/PlayerImage';
import MatchStatsFull from 'appdir/components/cms/MatchStatsFull';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';
import SectionHeaderBar from '../../common-ui/SectionHeaderBar';

class PlayerMatchUpOverview extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	toggleDetails(index) {
		this.setState(prevState => {
			let newProps = this.state.attributes.map((d, i) => {
				return i == index ? { ...d, showDetail: !d.showDetail || d.showDetail == '0px' ? '720px' : '0px' } : d;
			});
			let updateDetailToggle = !this.state.attributes[index].showDetail;
			return { ...prevState, attributes: newProps };
		});
	}

	formatRelatedContentURL(matchID) {
		let relatedContentURL = this.state.playerConfiguration.pathObj.matchRelated;
		relatedContentURL = relatedContentURL.replace('<matchId>', matchID + '&count=4');

		return relatedContentURL;
	}

	render() {
		let matchData = this.state.attributes;
		logger.log('[PlayerMatchUp] - matchData: %o', matchData);
		return (
			matchData.length > 0 && (
				<section>
					<SectionHeaderBar data={{ title: 'Completed Matches' }} />
					<div className="completed-matches-wrapper">
						{matchData.map((d, i) => {
							let scoreTableHeader = `${d.round} • ${d.location}`;
							let relatedContentURL = this.formatRelatedContentURL(d.matchId);

							return (
								<div key={`${d.matchId}-${i}`} className="featuredtable">
									<div className="player-score-table">
										<MatchBox
											key={`${d.matchId}-${i}`}
											attributes={{
												data: d,
												event: d.event,
												tableHeader: d.location,
												showLinks: true,
												usePlayerImages: false,
											}}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</section>
			)
		);
	}
}
export default PlayerMatchUpOverview;

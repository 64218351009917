/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: PointNavFilters
 * -----------------------------------------------------------------------------
 */

export default class PointNavFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			//this.state.mount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => ({
			...prevState,
			...nextProps,
		}));
	}

	componentDidUpdate() {
		logger.log('[PointNavFilters] ComponentDidUpdate - state:%o', this.state);
	}

	filterClick(which) {
		logger.log('[PointNavFilters] filterClick - button:%o', which);
		if (this.state.onFilterClick) {
			this.state.onFilterClick(which);
		}
	}

	render() {
		logger.log('[PointNavFilters] render - state:%o', this.state);

		let filterStyle = this.state.show ? 'show' : '';
		let srv1On = '';
		let srv2On = '';
		let aceOn = '';
		let brkOn = '';
		let dfOn = '';
		let exOn = '';

		if (this.state.filters) {
			if (this.state.filters.includes('srv1')) {
				srv1On = 'on';
			}
			if (this.state.filters.includes('srv2')) {
				srv2On = 'on';
			}
			if (this.state.filters.includes('ace')) {
				aceOn = 'on';
			}
			if (this.state.filters.includes('brk')) {
				brkOn = 'on';
			}
			if (this.state.filters.includes('df')) {
				dfOn = 'on';
			}
			if (this.state.filters.includes('ex')) {
				exOn = 'on';
			}
		}
		if (op.has(this.state.match, 'players.0.firstName')) {
			return (
				<div className={`pointNavFilters ${filterStyle}`}>
					<div
						className={`filterButton ${srv1On}`}
						onClick={() => {
							this.filterClick('srv1');
						}}>
						Srv:{' '}
						{`${op.get(this.state.match, 'players.0.firstName')} ${op.get(
							this.state.match,
							'players.0.lastName'
						)}`}
						<div className={`divider`} />
						<div className={`indicator`} />
					</div>

					<div
						className={`filterButton ${srv2On}`}
						onClick={() => {
							this.filterClick('srv2');
						}}>
						Srv:{' '}
						{`${op.get(this.state.match, 'players.1.firstName')} ${op.get(
							this.state.match,
							'players.1.lastName'
						)}`}
						<div className={`divider`} />
						<div className={`indicator`} />
					</div>

					<div
						className={`filterButton ${aceOn}`}
						onClick={() => {
							this.filterClick('ace');
						}}>
						Aces
						<div className={`divider`} />
						<div className={`indicator`} />
					</div>

					<div
						className={`filterButton ${brkOn}`}
						onClick={() => {
							this.filterClick('brk');
						}}>
						Break Points
						<div className={`divider`} />
						<div className={`indicator`} />
					</div>

					<div
						className={`filterButton ${dfOn}`}
						onClick={() => {
							this.filterClick('df');
						}}>
						Double Faults
						<div className={`divider`} />
						<div className={`indicator`} />
					</div>

					<div
						className={`filterButton ${exOn}`}
						onClick={() => {
							this.filterClick('ex');
						}}>
						Most Exciting
						<div className={`divider`} />
						<div className={`indicator`} />
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

PointNavFilters.defaultProps = {};

import React, { Component, Fragment } from 'react';
import { PlayerDigitalExperienceContext } from '../context';
import { getContent } from 'appdir/components/general/Util/Services';
import { loggedIn, checkRole, getRoleByName } from 'appdir/components/general/Util/Role';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import WimLink from 'appdir/components/general/WimLink';
import op from 'object-path';
import HeaderText from './HeaderText';
import Section from './Section';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getParams } from './Utils.js';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
	unmount: () => dispatch(deps.actions.PlayerDigitalExperience.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	getAccountInfo: data => dispatch(deps.actions.Gigya.getAccountInfo(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
	removeShadowRole: role => dispatch(deps.actions.MyWimbledon.removeShadowRole(role)),
});

/**
 * DelegateSelect - Page for delegats to select a player to shadow
 */

class DelegateSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			text: 'Player Management',
			gotData: false,
			data: null,
		};
		this.fetchData = true;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.showFailModal = this.showFailModal.bind(this);

		logger.log('[PDE - DelegateSelect] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log('[PDE - DelegateSelect] componentDidMount - state:%o context:%o', this.state, this.context);
		this.setState({ fetch: true });
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.props, 'currentUser', {});

		// let roleValidated = checkRole(op.get(currentUser, 'roles', []), op.get(this.state, 'roleIds', []));
		let roleValidated = checkRole(op.get(currentUser, 'roles', []), [1000280]); //delegate role only

		logger.log(
			'[PDE - DelegateSelect] componentDidUpdate - user:%o state:%o roleValidated:%o',
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			roleValidated
		);

		logger.log('[PDE - DelegateSelect] componentDidUpdate - state:%o context:%o', this.state, this.context);
		if (loggedIn(currentUser) && roleValidated) {
			if (this.state.dataUrl && !this.state.gotData && this.fetchData) {
				this.fetchData = false;
				this.props
					.getJWT()
					.then(token => {
						logger.log('[PDE - DelegateSelect] componentDidUpdate - token:%o', token.jwt);
						let nullError = false;
						let params = getParams('delegateSelect', { ...this.state });
						getContent(this.state.dataUrl, 'get', token.jwt.id_token, nullError, params)
							.then(data => {
								logger.log('[PDE - DelegateSelect] componentDidUpdate - data:%o', data);
								this.setState({ data: data, gotData: true, reloading: false });
								this.props.getAccountInfo({ src: 'delegate_select' });
							})
							.catch(data => {
								this.setState({ data: 'error', gotData: true, reloading: false });
							});
					})
					.catch(error => {
						logger.error('[PDE - DelegateSelect] componentDidUpdate - error getting token');
						this.setState({ data: 'error' });
						this.showFailModal('E2310');
					});

				/**** test data *****/
				// let data = {
				// 	response: {
				// 		players: [
				// 			{
				// 				refsPlayerId: '123456',
				// 				firstName: 'Roger',
				// 				lastName: 'Federer',
				// 				delegateRelationshipStatus: 'Approved',
				// 			},
				// 			{
				// 				refsPlayerId: '123456',
				// 				firstName: 'Juan Pablo',
				// 				lastName: 'Del Potro',
				// 				delegateRelationshipStatus: 'Approved',
				// 			},
				// 			{
				// 				refsPlayerId: '123456',
				// 				firstName: 'Kei',
				// 				lastName: 'Nishikori',
				// 				delegateRelationshipStatus: 'Approved',
				// 			},
				// 			{
				// 				refsPlayerId: '123456',
				// 				firstName: 'Naomi',
				// 				lastName: 'Osaka',
				// 				delegateRelationshipStatus: 'Approved',
				// 			},
				// 		],
				// 	}
				// };
				// this.setState({ data, gotData: true });
			}
		}
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[PDE - DelegateSelect] handleSubmit - state:%o', values);
	}

	nodelegateSelectData() {
		let stringItems = op.get(this.state.data, 'response.players', []);
		return stringItems.length == 0;
	}

	showStub() {
		const stubMsg = op.get(this.state, 'stubs.pde.delegateSelect.text', null);
		return (
			<div className="four-col bank-info-container">
				<div className="section-container-no-data">
					<div className="row prize-money-explanation">{stubMsg}</div>
				</div>
			</div>
		);
	}

	showFailModal(passedCode = null) {
		this.setState({ reloading: false });
		this.props.showModal({
			showModal: true,
			showCloseBtn: true,
			type: 'fail',
			title: 'ERROR',
			message: 'There was an error with your request. Please try again later',
			code: passedCode,
			useTimer: false,
		});
	}

	getErrorCode(error) {
		let code = 'E2340';
		if (error.status == 403) code = 'E2320';
		if (error.status == 500) code = 'E2330';
		return code;
	}

	/**
	 * @param refsPlayerId - player refsPlayerId
	 */
	setDelegate(e, refsPlayerId, remove = false) {
		logger.log('[PDE - DelegateSelect] setDelegate refsPlayerId:%o', refsPlayerId);
		let playerRole = getRoleByName(op.get(this.props, 'currentUser.roles', null), 'player');
		this.setState({ reloading: true });

		if (remove) {
			this.props
				.removeShadowRole(playerRole)
				.then(data => {
					this.fetchData = true;
					this.setState({ gotData: false });
					if (data.statusReason == 'error') {
						this.showFailModal('E2310');
					}
				})
				.catch(data => {
					// this.setState({ data: 'error' });
					this.showFailModal('E2310');
				});
		} else {
			if (playerRole) {
				this.props
					.removeShadowRole(playerRole)
					.then(data => {
						let nullError = false;
						let url = this.props.paths.delegateAssign;
						// let params = getParams('delegateSelect', { ...this.state });
						let inData = { linkId: parseInt(refsPlayerId) };
						if (data.statusReason == 'error') {
							this.props.showModal({
								showModal: true,
								showCloseBtn: true,
								type: 'fail',
								title: 'ERROR',
								message: 'There was an error with your request. Please try again later',
								useTimer: false,
							});
						}
						this.props
							.getJWT()
							.then(token => {
								getContent(url, 'post', token.jwt.id_token, nullError, null, inData)
									.then(data => {
										this.fetchData = true;
										this.setState({ gotData: false });
										if (data.statusReason == 'error') {
											this.showFailModal('E2310');
										}
									})
									.catch(error => {
										let code = this.getErrorCode(error);
										// this.setState({ data: 'error' });
										this.showFailModal(code);
									});
							})
							.catch(error => {
								logger.error('[PDE - DelegateSelect] setDelegate - error getting token');
								// this.setState({ data: 'error' });
								this.showFailModal('E2010');
							});
					})
					.catch(error => {
						// this.setState({ data: 'error' });
						logger.log('[PDE - DelegateSelect] setDelegate - error:%o', error);
						this.showFailModal('E2010');
					});
			} else {
				this.props
					.getJWT()
					.then(token => {
						logger.log('[PDE - DelegateSelect] setDelegate - token:%o', token.jwt);
						let nullError = false;
						let url = this.props.paths.delegateAssign;
						// let params = getParams('delegateSelect', { ...this.state });
						let data = { linkId: parseInt(refsPlayerId) };
						logger.log('[PDE - DelegateSelect] setDelegate - data:%o, url:%o', data, url);
						getContent(url, 'post', token.jwt.id_token, nullError, null, data)
							.then(data => {
								logger.log('[PDE - DelegateSelect] setDelegate - data:%o', data);
								this.fetchData = true;
								this.setState({ gotData: false });
								if (data.statusReason == 'error') {
									this.showFailModal('E2310');
								}
							})
							.catch(error => {
								// this.setState({ data: 'error' });
								let code = this.getErrorCode(error);
								this.showFailModal(code);
							});
					})
					.catch(error => {
						logger.error('[PDE - DelegateSelect] setDelegate - error getting token');
						// this.setState({ data: 'error' });
						this.showFailModal('E2010');
					});
			}
		}
	}

	renderDelegateSelect() {
		//check for no delegateSelect items array, or an empty delegateSelect items array
		let delegateSelectItems = op.get(this.state, 'data.response.players', []);
		let message = op.get(this.state.data, 'message', null);
		let playerRole = op.get(this.props, 'currentUser.data.systemRole.player', []);
		logger.log('[PDE - DelegateSelect] renderDelegateSelect - this.props:%o', this.props);
		return (
			<>
				{message !== 'OK' || this.state.data === 'error' ? (
					<div className="four-col bank-info-container">
						<div className="section-container-no-data">
							<div className="row prize-money-explanation">
								The information you are requesting is currently unavailable. Please try again later or
								contact us on{' '}
								<WimLink to="mailto:players@aeltc.com" external={true}>
									players@aeltc.com
								</WimLink>
								.
							</div>
						</div>
					</div>
				) : this.nodelegateSelectData() ? (
					<div className="four-col bank-info-container">
						<div className="section-container-no-data">
							<div className="row prize-money-explanation">No players available</div>
						</div>
					</div>
				) : (
					<>
						<div className="four-col">
							<h4>{this.state.text}</h4>
						</div>
						<div className="four-col">
							<p>
								Once your access has been approved you can select a player to act as a delegate user on
								their account. This means you can add guest accreditation, view/download prize money
								statements or view/watch match statistics/videos as well as check all other information
								available to your player.
							</p>
						</div>
						<div className="four-col pde-content-dash delegate-selection">
							<table className="delegate-table">
								<thead>
									<tr>
										<th>Name</th>
										<th>Status</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{delegateSelectItems.length > 0 &&
										delegateSelectItems.map((d, i) => {
											if (
												d.delegateRelationshipStatus.toLowerCase() == 'approved' ||
												d.delegateRelationshipStatus.toLowerCase() == 'pending'
											) {
												return (
													<tr key={`delegateitem-${i}`}>
														<td>{`${d.firstName} ${d.lastName}`}</td>
														<td>{d.delegateRelationshipStatus}</td>
														<td>
															<div className="approval-buttons">
																{/* if refsplayerid is the one user is shadowing */
																d.delegateRelationshipStatus.toLowerCase() ==
																	'approved' &&
																	(playerRole &&
																	playerRole.linkId == d.refsPlayerId ? (
																		<div className="generic-button_wrapper">
																			<div
																				className="generic-button btn solid "
																				tabIndex={0}
																				role="button"
																				onClick={e =>
																					this.setDelegate(
																						e,
																						d.refsPlayerId,
																						true
																					)
																				}>
																				<div className="content">Remove</div>
																			</div>
																		</div>
																	) : (
																		<div className="generic-button_wrapper">
																			<div
																				className="generic-button btn solid "
																				tabIndex={0}
																				role="button"
																				onClick={e =>
																					this.setDelegate(e, d.refsPlayerId)
																				}>
																				<div className="content">Select</div>
																			</div>
																		</div>
																	))}
															</div>
														</td>
													</tr>
												);
											} else {
												return null;
											}
										})}
								</tbody>
							</table>
						</div>
					</>
				)}
			</>
		);
	}

	render() {
		logger.log('[PDE - DelegateSelect] render - state:%o status:%o', this.state, this.state.status);
		// logger.log('[PDE - DelegateSelect] state %o', this.state);
		const showStub = op.get(this.state, 'stubs.pde.delegateSelect.stub', false);

		if (!this.state.gotData || this.state.reloading) {
			return <LoadingIndicator />;
		} else {
			if (!showStub) {
				return this.renderDelegateSelect();
			} else {
				return this.showStub();
			}
		}
	}
}

DelegateSelect.contextType = PlayerDigitalExperienceContext;
export default connect(mapStateToProps, mapDispatchToProps)(DelegateSelect);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import indexOf from 'lodash/indexOf';
import { values } from 'appdir/main';
import op from 'object-path';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { getEventCode, getRoute, getEventName, getPlayersData } from '../..//pages/DrawsPage/DrawsUtils';
import { Redirect } from 'react-router';
import { getQuerystringParam } from 'appdir/components/general/Util';

// components
import EventSelect from '../../pages/DrawsPage/EventSelect';
import DrawsNavigator from '../../pages/DrawsPage/DrawsNavigator';
import DrawsLists from 'appdir/components/scoring/DrawsLists';
import StubBox from 'appdir/components/common-ui/StubBox';
import Favorites from 'appdir/components/common-ui/Favorites';
import SearchBox from 'appdir/components/common-ui/SearchBox';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import ScrollHandler from 'appdir/components/general/ScrollHandler';
import DrawsPage from '../../pages/DrawsPage';

/**
 * -----------------------------------------------------------------------------
 * React Component: DrawsWebview
 * -----------------------------------------------------------------------------
 */

const DrawsWebview = props => {
	return <DrawsPage {...props} />;
};

export default DrawsWebview;

// const mapStateToProps = (state, props) => {
// 	return {
// 		...state['DrawsWebview'],
// 		...props,
// 	};
// };

// const mapDispatchToProps = (dispatch, props) => ({
// 	mount: () => dispatch(actions.DrawsWebview.mount()),
//   filter: data => dispatch(deps.actions.DrawsPage.filter(data)),
// 	search: data => dispatch(deps.actions.DrawsPage.search(data)),
// 	update: data => dispatch(deps.actions.DrawsPage.update(data)),
// });

// class DrawsWebview extends Component {
//   constructor(props) {
//     super(props);

//     	logger.log('[DrawsWebview] constructor - props:%o', props);

//     this.state = {
// 			playersPlaying: [],
// 			playersSet: {},
// 			drawsEventData: null, // data loaded from draws event json file
// 			drawsListData: null, // data that should be displayed
// 		};

// 		this.firstLoad = true;
// 		this.eventMeasured = false;
// 		this.drawsDataLoaded = false;
// 		this.eventDataLoaded = false;
// 		this.playerDataLoaded = false;
// 		this.roundsRetrieved = false;
// 		this.columnsDataRetrieved = false;
// 		this.displayRounds = [];
// 		this.displayLabels = [];
// 		this.totalRounds = 0;
// 		this.matchID = '';
// 		this.eventWon = false;
// 		this.selectedEvent = '';
// 		this.year = values.tournamentYear;
// 		this.eventDataLoaded = false;
// 		// this.urlEvent = '';
// 		// this.urlSection = '';
//   }

//   componentDidMount() {
// 		logger.log('[DrawsWebview] componentDidMount - state:%o', this.state);

// 		this.debugView = getQuerystringParam(null, 'ticketDebug');
// 	}

//   componentWillUnmount() {
// 		this.eventMeasured = false;
// 		this.eventDataLoaded = false;
// 		this.playerDataLoaded = false;

// 		this.props.filter({
// 			event: '',
// 			open: '',
// 		});

// 		this.props.unmount({
// 			drawsPath: '',
// 			mountStatus: null,
// 		});

// 		//removing player highlight when unmounting component
// 		this.props.search({
// 			searchedPlayer: '',
// 		});
// 	}

// 	UNSAFE_componentWillReceiveProps(nextProps) {
// 		this.setState(prevState => {
// 			return {
// 				...prevState,
// 				...nextProps,
// 			};
// 		});
// 		//logger.log('[DrawsWebview] componentWillReceiveProps - state:%o', this.state);
// 	}

//   componentDidUpdate(prevProps, prevState) {
//     logger.log('[DrawsWebview] componentDidUpdate this:%o, prevState:%o, prevProps:%o', this, prevState, prevProps);

//     if (
// 			this.props.eventRound.selectedRound !== op.get(prevProps, 'eventRound.selectedRound', 0) ||
// 			this.props.view !== prevProps.view
// 		)
//     {
// 			this.roundsRetrieved = false;
// 			this.columnsDataRetrieved = false;
// 			logger.log('[DrawsWebview] componentDidUpdate selectedIndex OR selectedRound changed:%o', this);
// 			if (this.state.searchedPlayer) {
// 				this.setState(
// 					{
// 						searchedPlayer: null,
// 					},
// 					() => {
// 						this.setState({
// 							searchedPlayer: prevState.searchedPlayer,
// 						});
// 					}
// 				);
// 			}
// 		}
//     if (this.selectedEvent !== prevProps.filters.event && prevProps.filters.event !== '') {
// 			// logger.log(
// 			// 	'[DrawsWebview] componentDidUpdate event has changed from %o to %o',
// 			// 	this.state.filters.event,
// 			// 	this.selectedEvent
// 			// );
// 			this.onEventChange();
// 		}

// 		/** load draws data which includes urls for the draws feeds **/
// 		if (
// 			this.props.mountStatus == 'ready' &&
// 			!this.state.drawsData &&
// 			this.state.drawsPath &&
// 			!this.drawsDataLoaded
// 		)
//     {
// 			// logger.log(
// 			// 	'[DrawsWebview] componentDidUpdate loading draws data - this.state.drawsPath:%o',
// 			// 	this.state.drawsPath
// 			// );
// 			this.drawsDataLoaded = true;

// 			deps.services.DrawsPage.fetch(this.state.drawsPath)
// 				.then(result => {
// 					// logger.log('[DrawsWebview] componentDidUpdate draws data:%o', result);
// 					this.setState({
// 						drawsData: result,
// 					});
// 				})
// 				.catch(error => {
// 					logger.error('[DrawsWebview] componentDidUpdate error loading draws data:%o', error);
// 					this.setState({
// 						drawsData: 'notfound',
// 					});
// 				});
// 		}
//     /** if new event and not yet measured, make pageview measurement call **/
// 		if (this.state.drawsData && !this.eventMeasured) {
// 			this.eventMeasured = true;
// 			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
// 				pageTitle: getEventName(this.selectedEvent, this.state.tourn_events),
//         webview: window.webview ? true : false,
// 			});
// 		}

// 		/**
// 		 * draws data has been loaded, there is no selected event or event
// 		 * has changed - load the draws data for the new event
// 		 **/
// 		if (this.state.drawsData && !this.eventDataLoaded) {
// 			// logger.log('[DrawsWebview] componentDidUpdate loading event data');
// 			this.eventDataLoaded = true;
// 			let { draws } = this.state.drawsData;
// 			let drawToLoad = draws.filter(draw => {
// 				return draw.id == this.selectedEvent;
// 			})[0];
// 			let showStub = true;
// 			this.eventWon = false;
// 			let roundVal = this.props?.eventRound?.pageReturn === true ? this.props?.eventRound?.selectedRound : 0;

// 			logger.log(
// 				'[DrawsWebview] componentDidUpdate new event: event:%o, draws:%o, drawToLoad:%o',
// 				this.selectedEvent,
// 				draws,
// 				drawToLoad
// 			);

// 			let dataToUpdate = {
// 				eventRound: {
// 					selectedRound: roundVal,
// 					selectedIndex: '',
// 					pageReturn: false,
// 				},
// 				filters: {
// 					event: this.selectedEvent,
// 					open: '',
// 				},
// 			};

// 			if (drawToLoad) {
// 				showStub = false;

// 				deps.services.DrawsPage.fetch(drawToLoad.feed_url)
// 					.then(result => {
// 						logger.log('[DrawsWebview] componentDidUpdate result:%o', result);

// 						this.setState(
// 							{
// 								showStub: showStub,
// 								drawsEventData: result,
// 							},
// 							() => {
// 								this.updateInfo(dataToUpdate);
// 							}
// 						);
// 					})
// 					.catch(error => {
// 						logger.error('[DrawsWebview] componentDidUpdate error fetching draws data:%o', error);
// 						this.setState(
// 							{
// 								showStub: showStub,
// 								drawsEventData: 'notfound',
// 							},
// 							() => {
// 								this.updateInfo(dataToUpdate);
// 							}
// 						);
// 					});
// 			} else {
// 				this.setState(
// 					{
// 						showStub: showStub,
// 					},
// 					() => {
// 						this.updateInfo(dataToUpdate);
// 					}
// 				);
// 			}
// 		}

// 		/**
// 		 * if we have draws event data and have not gotten player data,
// 		 * call function to get player data
// 		 */
// 		if (this.state.drawsEventData && this.state.playersPlaying.length == 0 && !this.playerDataLoaded) {
// 			// logger.log('[DrawsWebview] componentDidUpdate loading player data');
// 			this.playerDataLoaded = true;
// 			let playerData = {};
// 			let { drawsEventData } = this.state;

// 			playerData = getPlayersData(drawsEventData, this.selectedEvent, drawsEventData.totalRounds);

// 			this.setState({
// 				playersPlaying: playerData.playersPlaying,
// 				playersSet: playerData.playersSet,
// 			});
// 		}

// 		/**
// 		 * only do these actions if we have already loaded
// 		 * playersPlaying and PlayersSet into state
// 		 */
// 		if (this.state.playersPlaying.length > 0) {
// 			// logger.log('[DrawsWebview] componentDidUpdate - data and players are in state - this:%o', this);

// 			/**
// 			 * Get current three rounds for display
// 			 *
// 			 * - drawsEventData has changed in state
// 			 * or
// 			 * - drawsEventData exists
// 			 * and
// 			 * - selectedRound is 0 and this is the first load
// 			 * or
// 			 * - the selectedRound is not 0 and has changed in state
// 			 * or
// 			 * - selectedIndex has changed in state and is not an empty string
// 			 * **/
// 			if (
// 				(!this.roundsRetrieved && this.state.drawsEventData !== op.get(prevState, 'drawsEventData', null)) ||
// 				(this.state.drawsEventData &&
// 					((this.state.eventRound.selectedRound == 0 && this.firstLoad) ||
// 						(this.state.eventRound.selectedRound !== op.get(prevState, 'eventRound.selectedRound', 0) &&
// 							op.get(prevState, 'eventRound.selectedRound', 0) !== '0') ||
// 						(this.state.eventRound.selectedIndex !== op.get(prevState, 'eventRound.selectedIndex', '') &&
// 							op.get(prevState, 'eventRound.selectedIndex', '') !== '')))
// 			) {
// 				// logger.log(
// 				// 	'[DrawsWebview] componentDidUpdate - calling roundsToDisplay prevState:%o, this:%o',
// 				// 	prevState,
// 				// 	this
// 				// );
// 				this.roundsRetrieved = true;
// 				this.firstLoad = false;
// 				this.roundsToDisplay(this.state.drawsEventData);
// 			}

// 			/**
// 			 * Get the rounds column data for display
// 			 *
// 			 * - drawsEventData exists in state
// 			 * and
// 			 * - display rounds data exists
// 			 * and
// 			 * - selectedRound is not 0
// 			 * and
// 			 * - selectedRound has changed in state
// 			 * or
// 			 * - selectedIndex in state and is not an empty string
// 			 */
// 			if (
// 				!this.columnsDataRetrieved &&
// 				this.state.drawsEventData && //this happens in componentDidUpdate
// 				this.displayRounds.length > 0 && //this happens in roundsToDisplay
// 				this.state.eventRound.selectedRound !== 0 && //this happens in roundsToDisplay
// 				(this.state.eventRound.selectedRound !== op.get(prevState, 'eventRound.selectedRound', 0) ||
// 					(this.state.eventRound.selectedIndex !== op.get(prevState, 'eventRound.selectedIndex', '') &&
// 						op.get(prevState, 'eventRound.selectedIndex', '') !== ''))
// 			) {
// 				// logger.log(
// 				// 	'[DrawsWebview] componentDidUpdate - calling getRoundColumnsData prevState:%o, this:%o',
// 				// 	prevState,
// 				// 	this
// 				// );
// 				this.columnsDataRetrieved = true;
// 				this.getRoundColumnsData(this.state.drawsEventData);
// 			}
// 		}

// 		/** if returning to the page, load data */
// 		if (this.state.eventRound.pageReturn == true && this.state.drawsData) {
// 			let drawsArray = this.state.drawsData.draws;

// 			if (drawsArray) {
// 				for (var i = 0; i < drawsArray.length; i++) {
// 					if (drawsArray[i].id == this.props.filters?.event) {
// 						deps.services.DrawsPage.fetch(drawsArray[i].feed_url)
// 							.then(result => {
// 								this.setState(
// 									{
// 										drawsEventData: result,
// 									},
// 									() => {
// 										this.getRoundColumnsData(this.state.drawsEventData);
// 									}
// 								);
// 							})
// 							.catch(error => {
// 								this.setState(
// 									{
// 										drawsEventData: 'notfound',
// 									},
// 									() => {
// 										this.getRoundColumnsData(this.state.drawsEventData);
// 									}
// 								);
// 							});
// 					}
// 				}
// 			}
// 		}
//   }
//   updateInfo(data) {
// 		this.props.update(data);
// 	}

// 	onEnter = data => {
// 		if (data !== '') {
// 			let playerRoundInfo = this.state['playersSet'][data.tour_id];
// 			const { index, rndNum } = playerRoundInfo;
// 			this.props.search({
// 				searchedPlayer: data.tour_id,
// 				selectedIndex: index,
// 				clicked: true,
// 				selectedRound: rndNum,
// 				searchRound: rndNum,
// 				found: false,
// 			});
// 		} else {
// 			this.props.search({
// 				searchedPlayer: '',
// 			});
// 		}
// 	};

// 	/**
// 	 * when event changes set local vars to false
// 	 * so data will be loaded for new event
// 	 **/
// 	onEventChange = () => {
// 		logger.log('[DrawsPage] onEventChange: this:%o', this);
// 		this.eventMeasured = false;
// 		this.eventDataLoaded = false;
// 		this.playerDataLoaded = false;
// 		this.roundsRetrieved = false;
// 		this.columnsDataRetrieved = false;
// 	};

// 	setEvent(event) {
// 		logger.log('[DrawsPage] setEvent event:%o', event);

// 		MeasurementUtils.dispatchMeasurementCall('eventSelect', {
// 			event,
// 		});

// 		this.props.filter({
// 			event,
// 			open: '',
// 		});
// 	}

// 	onSelectClosed = () => {
// 		this.props.update({
// 			filters: {
// 				open: '',
// 			},
// 		});
// 	};

// 	getStubMessage(event) {
// 		let drawsStub = this.state.stubPages.draws;
// 		let message = '';

// 		forEach(drawsStub, function(value, key) {
// 			if (key == event) {
// 				message = value;
// 			}
// 		});
// 		//logger.log('[DrawsPage] getStubMessage event:%o, message:%o', event, message);

// 		return message;
// 	}

// 	roundsToDisplay = data => {
// 		logger.log('[DrawsPage] roundsToDisplay this:%o', this);
// 		let startRound = 0;
// 		let endRound = 0;
// 		let displayRounds = [];
// 		let displayLabels = [];
// 		let currentRound = this.state.eventRound.selectedRound;
// 		let matches = data.matches;
// 		let matchID = '';
// 		this.totalRounds = data.totalRounds;

// 		/** First time displaying an event, get most current round for default display */
// 		if (currentRound == 0) {
// 			/** Get the round number from the match id */
// 			for (var i = 0; i < matches.length; i++) {
// 				let winnerStatus = matches[i].winner;

// 				/** if no matches have winners, get the current id and matchid from last match */
// 				if (winnerStatus == null) {
// 					currentRound = Number(matches[i].match_id.slice(-3, -2));
// 					matchID = matches[i].match_id.slice(-3);
// 					break;
// 				} else if (matches[i].roundCode == 'F' && winnerStatus !== null) {
// 					/** for first match with a winner and a roundcode = f, get current id and match id from this match */
// 					currentRound = Number(matches[i].match_id.slice(-3, -2));
// 					matchID = matches[i].match_id.slice(-3);
// 					this.eventWon = true;
// 					break;
// 				} else if (i == matches.length - 1 && winnerStatus !== null) {
// 					/** if match has a winner and is last match in list, get current id and match from this match */
// 					currentRound = Number(matches[i].match_id.slice(-3, -2));
// 					matchID = matches[i].match_id.slice(-3);
// 					break;
// 				}
// 			}
// 		}

// 		/**
// 		 * Set the three rounds to be displayed based on currentRound
// 		 * Set the start and end indexes for loop based on currentRound and totalRounds
// 		 */
// 		if (this.totalRounds <= 3) {
// 			startRound = currentRound;
// 			endRound = this.totalRounds;
// 		} else {
// 			startRound = currentRound;
// 			endRound = currentRound + 2;
// 			if (endRound > this.totalRounds) {
// 				endRound = this.totalRounds;
// 			}
// 		}

// 		var counter = 0;
// 		for (var i = startRound; i < endRound + 1; i++) {
// 			displayRounds.push((startRound + counter).toString());
// 			counter++;
// 		}

// 		/** Get round labels for the display round */
// 		var counter = 0;
// 		for (var i = 0; i < matches.length; i++) {
// 			if (matches[i].match_id.slice(-3, -2) == displayRounds[counter]) {
// 				displayLabels.push(matches[i].roundName);
// 				counter++;
// 			}
// 		}

// 		/**
// 		 * Check if event has been won and it is not a qualification event
// 		 * If so shift the displayRounds array to add a duplicate final round to show a winner column
// 		 */
// 		if (
// 			this.selectedEvent !== 'QS' &&
// 			this.selectedEvent !== 'RS' &&
// 			this.selectedEvent !== 'QD' &&
// 			this.selectedEvent !== 'RD'
// 		) {
// 			if (this.eventWon && currentRound == this.totalRounds) {
// 				// logger.log('[DrawsPage] adding winner column');

// 				let lastRound = displayRounds[0];
// 				displayRounds.push(lastRound);
// 			}
// 		}

// 		this.displayRounds = displayRounds;
// 		this.displayLabels = displayLabels;
// 		this.matchID = matchID;

// 		this.props.update({
// 			eventRound: {
// 				selectedRound: currentRound,
// 			},
// 		});
// 	};

// 	completeColumnData(columnData) {
// 		let selectedRound = this.state.eventRound.selectedRound;
// 		let selectedEvent = this.selectedEvent;

// 		logger.log(
// 			'[DrawsPage] completeColumnData columnData:%o, selectedRound:%o, selectedEvent:%o, this.eventWon:%o, this.totalRounds:%o',
// 			columnData,
// 			selectedRound,
// 			selectedEvent,
// 			this.eventWon,
// 			this.totalRounds
// 		);

// 		if (this.eventWon && selectedRound == this.totalRounds - 1) {
// 			let columnWinData = columnData;

// 			// if (selectedEvent === 'SQ') {
// 			// 	columnWinData['columnC'] = JSON.parse(JSON.stringify(columnData['columnB']));
// 			// 	columnWinData['columnC'].matches.pop();
// 			// } else {
// 			columnWinData['columnC'] = JSON.parse(JSON.stringify(columnData['columnB']));
// 			// }
// 			columnWinData['columnC'].title = 'Winner';
// 			columnWinData['columnC'].matches[0].roundCode = 'W';

// 			// logger.log('[DrawsPage] completeColumnData adding winner header 1 columnWinData:%o', columnWinData);

// 			this.setState({
// 				drawsListData: columnWinData,
// 			});
// 		} else if (this.eventWon && selectedRound == this.totalRounds) {
// 			let columnWinData = JSON.parse(JSON.stringify(columnData));

// 			// if (selectedEvent === 'SQ') {
// 			// 	columnWinData['columnB'].matches.pop();
// 			// }

// 			logger.log('[DrawsPage] completeColumnData adding winner header 2 columnWinData:%o', columnWinData);

// 			columnWinData['columnB'].title = 'Winner';

// 			columnWinData['columnB'].matches[0].roundCode = 'W';

// 			logger.log('[DrawsPage] completeColumnData adding winner header 2 columnWinData:%o', columnWinData);

// 			this.setState({
// 				drawsListData: columnWinData,
// 			});
// 		} else {
// 			// logger.log('[DrawsPage] completeColumnData no winner header 3 columnData:%o', columnData);
// 			this.setState({
// 				drawsListData: columnData,
// 			});
// 		}
// 	}

// 	getRoundColumnsData(data) {
// 		logger.log('[DrawsPage] getRoundColumnsData this:%o,', this);

// 		let columnData = {
// 			columnA: { title: '', matches: [] },
// 			columnB: { title: '', matches: [] },
// 			columnC: { title: '', matches: [] },
// 		};
// 		let selectedRound = this.state.eventRound.selectedRound;
// 		let displayRounds = this.displayRounds;
// 		let displayLabels = this.displayLabels;
// 		let eventMatches = data.matches;
// 		let drawSize = data.drawSize;
// 		let matchNotPlayed = this.matchID;
// 		let bracketPosition = '';
// 		let selectedIndex = op.get(this.state, 'eventRound.selectedIndex', '');
// 		let roundTemp = 0;

// 		// logger.log('[DrawsPage] getRoundColumnsData displayRounds:%o', displayRounds);
// 		// logger.log('[DrawsPage] getRoundColumnsData matchNotPlayed:%o', matchNotPlayed);

// 		Object.keys(columnData).forEach(function(key) {
// 			let displayMatches = [];
// 			/* create only */

// 			/** If there are only one or two rounds in display matches, break out of loop **/
// 			if ((displayRounds.length == 2 && roundTemp == 2) || (displayRounds.length == 1 && roundTemp == 1)) {
// 				return;
// 			}

// 			/** Set column title **/
// 			columnData[key].title = displayLabels[roundTemp];

// 			/** Get all events with matching round id **/
// 			if (displayRounds.length > 0) {
// 				displayMatches = eventMatches.filter(match => {
// 					if (match.match_id.slice(-3, -2) == displayRounds[roundTemp].toString()) {
// 						// logger.log('[DrawsPage] getRoundColumnsData match:%o,', match.match_id);
// 						return match.match_id.slice(-3, -2) == displayRounds[roundTemp].toString();
// 					}
// 				});
// 			}

// 			logger.log(
// 				'[DrawsPage] getRoundColumnsData key:%o displayMatches:%o, displayMatches.length:%o',
// 				key,
// 				displayMatches,
// 				displayMatches.length
// 			);

// 			let drawIndex = 0;
// 			let matchesSize = displayMatches.length;
// 			let breakPoint = matchesSize / 2;

// 			if (selectedIndex === '') {
// 				/** Get index of match not played to set bracket position **/
// 				drawIndex = findIndex(displayMatches, function(match) {
// 					return match.match_id.slice(-3) === matchNotPlayed;
// 				});

// 				if (drawIndex < 0) {
// 					drawIndex = 0;
// 				}

// 				if (displayMatches.length >= 32) {
// 					if (!bracketPosition) {
// 						if (drawIndex < breakPoint) {
// 							bracketPosition = 'top';
// 						} else {
// 							bracketPosition = 'bottom';
// 						}
// 					}
// 				}

// 				logger.log(
// 					'[DrawsPage] getRoundColumnsData displayMatches.length:%o, drawIndex:%o breakPoint:%o, bracketPosition:%o',
// 					displayMatches.length,
// 					drawIndex,
// 					breakPoint,
// 					bracketPosition
// 				);
// 			}

// 			if (selectedIndex !== '' && selectedRound < 3) {
// 				if (drawSize == '128' || drawSize == '96' || drawSize == '64') {
// 					if (selectedIndex?.indexOf('top') > -1 && matchesSize > 7) {
// 						displayMatches.splice(breakPoint, matchesSize);
// 					}
// 					if (selectedIndex?.indexOf('bottom') > -1 && matchesSize > 7) {
// 						displayMatches.splice(0, breakPoint);
// 					}
// 				}
// 			}

// 			columnData[key].matches = displayMatches;
// 			roundTemp++;
// 		});

// 		// logger.log('[DrawsPage] getRoundColumnsData columnData:%o', columnData);

// 		if (selectedIndex === '') {
// 			selectedIndex = selectedRound.toString() + bracketPosition;

// 			// logger.log('[DrawsPage] getRoundColumnsData selectedIndex:%o', selectedIndex);

// 			this.props.update({
// 				eventRound: {
// 					selectedIndex: selectedIndex,
// 				},
// 			});
// 		}

// 		this.completeColumnData(columnData);
// 	}

// 	getScrollHandler() {
// 		let elem = document.getElementsByClassName('roundGroup')[0];

// 		if (elem) {
// 			return <ScrollHandler hasData={true} filter={'none'} />;
// 		} else {
// 			return null;
// 		}
// 	}

// 	// setUrlRound = round => {
// 	// 	let urlRound;
// 	// 	if (round == '' || round == undefined || round < 1 || round > this.totalRounds) {
// 	// 		urlRound = this.state?.eventRound?.selectedRound; //change to latest round
// 	// 	} else {
// 	// 		urlRound = round;
// 	// 	}

// 	// 	logger.log('[DrawsPage] urlRound component setUrlRound, urlRound:%o', urlRound);

// 	// 	return urlRound;
// 	// };

// 	// setUrlSection = (round, section) => {
// 	// 	let urlSection = section;
// 	// 	let urlRound = round;
// 	// 	let sectionIndex;

// 	// 	if (urlSection == '' || urlSection == undefined || round > 2 || this.totalRounds < 3) {
// 	// 		sectionIndex = urlRound;
// 	// 	} else if (urlSection == '1') {
// 	// 		sectionIndex = urlRound + 'top';
// 	// 	} else if (urlSection == '2') {
// 	// 		sectionIndex = urlRound + 'bottom';
// 	// 	}

// 	// 	// else if ((urlSection == '1' || urlSection == '2' ) || (urlSection == urlRound)) {
// 	// 	// 	sectionIndex = urlRound + 'top';
// 	// 	// }
// 	// 	logger.log(
// 	// 		'[DrawsPage] setUrlSection - urlSection:%o, urlRound:%o, sectionIndex:%o',
// 	// 		urlSection,
// 	// 		urlRound,
// 	// 		sectionIndex
// 	// 	);

// 	// 	return sectionIndex;
// 	// };

// 	// getRoundHalf = section => {
// 	// 	let half = section;
// 	// 	let selectedIndex;
// 	// 	if (half?.indexOf('top') > -1) {
// 	// 		selectedIndex = 1;
// 	// 	} else if (half?.indexOf('bottom') > -1) {
// 	// 		selectedIndex = 2;
// 	// 	} else {
// 	// 		selectedIndex = half;
// 	// 	}

// 	// 	logger.log(
// 	// 		'[DrawsPage] setUrlSection getRoundHalf - section:%o, half:%o, selectedIndex:%o',
// 	// 		section,
// 	// 		half,
// 	// 		selectedIndex
// 	// 	);
// 	// 	return selectedIndex;
// 	// };

//   renderPageContents(path) {
//     let flagImagePathSmall = null;
// 		let playerProfileImagePath = null;

//     let newPath = path;
//     if (this.state.flagImagePathSmall) {
// 			flagImagePathSmall = this.state.flagImagePathSmall;
// 			playerProfileImagePath = this.state.playerProfileImagePath;
// 		}
//     if (
// 			op.get(this.state, 'drawsData', false) == 'notfound' ||
// 			op.get(this.state, 'drawsEventData', false) == 'notfound'
// 		) {
// 			return (
// 				<>
// 					<div className="scores-background" />
// 					{/* <Header attributes={header_propsData} /> */}

// 					{/* <PageHeader attributes={header_propsData} /> */}

// 					<div className="content-main draws">
// 						<EventSelect
// 							basePath={this.state.location.pathname}
// 							events={this.state.tourn_events}
// 							filters={this.state.filters}
// 							onChange={this.onEventChange}
// 							onClose={this.onSelectClosed}
// 							roundSection={this.urlSection}
// 							round={this.urlRound}
// 						/>
// 						<GenericError message="Draws data for this event is not available." />
// 					</div>

// 					{/* <Footer /> */}
// 				</>
// 			);
// 		} else if (this.state.stubPages && (this.state.showStub == true || this.state.drawsData == 'notfound')) {
// 			return (
// 				<>
// 					{/* <div className="scores-background" /> */}
// 					{/* <Header attributes={header_propsData} /> */}

// 					{/* <PageHeader attributes={header_propsData} /> */}

// 					<div className="content-main draws">
// 						<div className="column-layout no-padding">
// 							<div className="header">
// 								<div className="navItemFull">
// 									<EventSelect
// 										basePath={newPath}
// 										events={this.state.tourn_events}
// 										filters={this.state.filters}
// 										onChange={this.onEventChange}
// 										onClose={this.onSelectClosed}
// 										roundSection={this.urlSection}
// 										round={this.urlRound}
// 									/>
// 								</div>
// 							</div>
// 						</div>

// 						<StubBox attributes={{ message: this.getStubMessage(this.selectedEvent) }} />
// 					</div>

// 					{/* <Footer /> */}
// 				</>
// 			);
// 		} else if (
// 			this.state.drawsListData &&
// 			this.state.drawsListData !== '' &&
// 			this.state.playersPlaying.length > 0
// 		) {
// 			logger.log('[DrawsPage] render this.state.drawsListData:%o', this.state.drawsListData);
// 			//let pdfPath = this.state.pdfPath.replace('<eventId>', this.selectedEvent);
// 			let pdfPath = this.state.pdfPath;

// 			return (
// 				<>
// 					{/* <div className="scores-background" /> */}
// 					{/* <Header attributes={header_propsData} /> */}

// 					{/* <PageHeader attributes={header_propsData} /> */}

// 					<div className="content-main draws">
// 						<div className="column-layout no-padding">
// 							<div className="header">
// 								<div className="navItemFull">
// 									<EventSelect
// 										basePath={newPath}
// 										events={this.state.tourn_events}
// 										filters={this.state.filters}
// 										onChange={this.onEventChange}
// 										onClose={this.onSelectClosed}
// 										roundSection={this.urlSection}
// 										round={this.urlRound}
// 										pdfPath={pdfPath}
// 									/>
// 								</div>

// 								<div className="navItem">
// 									<SearchBox onEnter={this.onEnter} />
// 								</div>
// 								<div className="navItemFixed">
// 									<Favorites />
// 								</div>
// 								<div className="navItemFixed">
// 									<div className="print">
// 										<WimLink
// 											to={pdfPath.replace('<eventId>', this.selectedEvent)}
// 											external={true}
// 											style="white">
// 											<i className="wim-icon-pdf" />
// 										</WimLink>
// 									</div>
// 								</div>
// 							</div>
// 						</div>

// 						<DrawsNavigator
// 							event={this.state.filters.event}
// 							roundNames={this.state.round_names}
// 							totalRounds={this.totalRounds}
// 							basePath={newPath}
// 						/>
// 						<div className="column-layout draws-page">
// 							<div className="drawsList draws">
// 								<DrawsLists
// 									attributes={{
// 										data: this.state.drawsListData,
// 										matchBoxStyle: '',
// 										showSlamTrackerLinks: true,
// 										profileLinks: 'main',
// 										archiveYear: '',
// 										flagImagePathSmall,
// 										playerProfileImagePath,
// 										event: this.props?.filters?.event,
// 										round: this.props?.eventRound.selectedRound,
// 										//round: this.urlRound
// 									}}
// 								/>
// 							</div>
// 						</div>

// 						{this.getScrollHandler()}
// 					</div>

// 					{/* <Footer /> */}
// 				</>
// 			);
// 		} else if (this.state.drawsData && this.state.filters.event && this.totalRounds) {
// 			return (
// 				<>
// 					{/* <div className="scores-background" /> */}
// 					{/* <Header attributes={header_propsData} /> */}

// 					{/* <PageHeader attributes={header_propsData} /> */}

// 					<div className="content-main draws">
// 						<div className="column-layout no-padding">
// 							<div className="header">
// 								<div className="navItemFull">
// 									<EventSelect
// 										basePath={newPath}
// 										events={this.state.tourn_events}
// 										filters={this.state.filters}
// 										onChange={this.onEventChange}
// 										onClose={this.onSelectClosed}
// 										roundSection={this.urlSection}
// 										round={this.urlRound}
// 									/>
// 								</div>
// 							</div>
// 						</div>

// 						<DrawsNavigator
// 							event={this.state.filters.event}
// 							roundNames={this.state.round_names}
// 							totalRounds={this.totalRounds}
// 							basePath={newPath}
// 						/>

// 						<LoadingIndicator type={'white'} />
// 					</div>
// 					{/* <Footer /> */}
// 				</>
// 			);
// 		} else {
// 			return (
// 				<>
// 					{/* <div className="scores-background" /> */}
// 					{/* <Header attributes={header_propsData} /> */}

// 					{/* <PageHeader attributes={header_propsData} /> */}

// 					<div className="content-main">
// 						<LoadingIndicator type={'white'} />
// 					</div>
// 					{/* <Footer /> */}
// 				</>
// 			);
// 		}
//   }

// 	render() {
// 		logger.log('[DrawsWebview] render this:%o', this);

// 		let event = (this.props.match?.params?.webview && this.props.match?.params?.event)
//       ? getEventCode(this.props.match.params?.event, this.props?.tourn_events)
//       : this.props.match?.params?.event
// 			? getEventCode(this.props.match.params?.event, this.props?.tourn_events)
// 			: 'MS';

// 		this.selectedEvent = event;

// 		// let urlRound = parseInt(this.props.match?.params?.round)
// 		// 	? parseInt(this.props.match?.params?.round)
// 		// 	: this.state?.eventRound?.selectedRound;
// 		// let urlSection = this.props.match?.params?.section
// 		// 	? this.props.match?.params?.section
// 		// 	: this.props?.eventRound?.selectedIndex;

// 		// this.urlRound = this.setUrlRound(urlRound);
// 		// this.urlSection = this.setUrlSection(urlRound, urlSection);
// 		// let urlIndex;

// 		//let newPath;
// 		// if (urlRound > 2 || this.state?.eventRound?.selectedRound > 2 || this?.totalRounds < 4) {
// 		// 	newPath =
// 		// 		`${getRoute(this.state?.filters?.event, this.state?.tourn_events)}` + '/' + this.setUrlRound(urlRound);
// 		// } else if (urlRound <= 2 && urlSection) {
// 		// 	newPath =
// 		// 		`${getRoute(this.state?.filters?.event, this.state?.tourn_events)}` +
// 		// 		'/' +
// 		// 		urlRound +
// 		// 		'/' +
// 		// 		this.getRoundHalf(this.setUrlSection(urlSection));
// 		// } else {
// 		// 	newPath = `${getRoute(this.state?.filters?.event, this.state?.tourn_events)}`;
// 		// }

// 		//console.log("newPath: ", newPath, ':', urlRound, ":" , urlSection)
// 		// let urlIndex;

// 		// logger.log('[DrawsPage] render urlSection:%o, urlSection:%o', this.urlSection, urlSection);
// 		// logger.log('[DrawsPage] render urlSection:%o', newPath);

// 		let titleName = getEventName(this.selectedEvent, this.state.tourn_events);
// 		//let titleName = "test";

// 		let header_propsData = {
// 			headerType: 'scores',
// 			title: 'Draws',
// 			shortTitle: 'Draws',
// 			metaTitle: 'The ' + titleName + ' Draw',
// 			metaDescription: 'The Championships 2019 Draw.',
// 			metaDate: '',
// 			metaPlayers: '',
// 		};

// 		//let stubMessage = this.getStubMessage(this.selectedEvent);
// 		return (
//       <Template className="scorespage">
//       <>
//         <div className="scores-background" />
//         <Header attributes={header_propsData} />
// 				<PageHeader attributes={header_propsData} />
// 				{this.renderPageContents(this.state.basePath)}
//         <Footer />
//       </>
// 			</Template>
//     )
// 	}

// 	// return <DrawsPage {...props} />;
// };

// //export default DrawsWebview;
// export default connect(mapStateToProps, mapDispatchToProps)(DrawsWebview);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import classNames from 'classnames';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['CountryPlayersPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.CountryPlayersPage.filter(settings)),
});

class CountryPlayersSelect extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
		this.setCountry = this.setCountry.bind(this);
		this.onOpen = this.onOpen.bind(this);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	setCountry(stat) {
		this.props.filter({
			countryPlayers: stat,
			open: '',
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		let selectData = {
			options: [{ name: 'Select a Country', value: '' }],
		};
		selectData.options = selectData.options.concat(this.state.selectData);
		return (
			<div className="results-select-menu">
				<SelectMenu
					name="countryPlayers"
					attributes={selectData}
					selected={this.state.filters.countryPlayers}
					show={this.state.filters.open == 'countryPlayers'}
					onSelected={this.setCountry}
					onOpen={this.onOpen}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryPlayersSelect);

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: term => (dispatch, getState, store) => {
		logger.log('[DrawsArchiveCountryAbbreviations] loadStatsConfig:%o', term);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Country Abbreviations',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[DrawsArchiveCountryAbbreviations] services:%o', data);
			// dispatch({type: deps.actionTypes.DRAWSARCHIVE_COUNTRY_ABBREVIATIONS_LOAD, data: data});
		});
	},
};

import deps from 'dependencies';
import { parseString /*, parseNumbers, parseBooleans*/ } from 'xml2js';
import { replaceLinks } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: day => (dispatch, getState, store) => {
		// logger.log('[HomePage] mount day:%o', day);
		logger.log('[HomePage] mount');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Home',
		});
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[HomePage] Config cmsData:%o', Config.cmsData.home24);
			deps.services.HomePage.fetch(Config.cmsData.home24).then(data => {
				// logger.log('[HomePage] mount data:%o', data);
				//set the xml parse options
				let options = {
					explicitArray: false,
					normalize: true,
					trim: true,
					mergeAttrs: true,
					valueProcessors: [],
				};
				parseString(data, options, function(err, json) {
					data = json.contentItem;
				});

				//clean the jsx block
				data.jsx = data.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');

				//try replacing relative links
				data.jsx = replaceLinks(data.jsx);

				logger.log('[HomePage] mount data:%o', data);
				dispatch({ type: deps.actionTypes.HOMEPAGE_MOUNT, data: data });
			});
		});
	},
	unmount: () => (dispatch, getState, store) => {
		let status = null;
		dispatch({ type: deps.actionTypes.HOMEPAGE_UNMOUNT, data: { status: status } });
	},
};

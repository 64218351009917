import { useState, useEffect, useRef } from 'react';
import useAxios from '../../hooks/useAxios';
import { useSelector } from 'react-redux';

const useAddressBook = () => {
	const membershipTypeRef = useRef();
	const nameRef = useRef();

	const [addressBookData, setAddressBookData] = useState(null);
	const [membershipType, setMembershipType] = useState('All Memberships');
	const [searchName, setSearchName] = useState('');

	const state = useSelector(state => state);

	const { invokeAll, createUrlParameters, response, loading, setResponse, statusCode } = useAxios({});
	const searchAddressBook = isFromEnter => {
		// This is a bad work-around for now, getting the membership type directly from the fiber node from a ref passed to the SelectMenu
		let updatedMembershipType =
			membershipTypeRef.current &&
			membershipTypeRef.current[Object.keys(membershipTypeRef.current)[0]].memoizedProps.children;

		let updatedMemTypeToSubmit = updatedMembershipType === 'All Memberships' ? '' : updatedMembershipType;
		let memTypeToSubmit = membershipType === 'All Memberships' ? '' : membershipType;

		const data = {
			name: searchName || nameRef.current.value,
			membership: isFromEnter ? updatedMemTypeToSubmit : memTypeToSubmit || updatedMemTypeToSubmit,
			count: 1000,
			page: 1,
		};

		let endpoints = [
			`${state.Config.member.api.addressBook}${createUrlParameters(data)}`,
			`${state.Config.member.api.addressImages}${createUrlParameters(data)}`,
		];
		invokeAll(endpoints);
	};
	const resetValues = () => {
		setAddressBookData(null);
		setResponse(null);
		setMembershipType('All Memberships');
		setSearchName('');
	};

	useEffect(() => {
		if (response) {
			const memberData = response[0]?.data?.response?.members;
			const imageData = response[1]?.data?.response?.members;
			setAddressBookData(
				memberData?.map(item => ({
					...item,
					...imageData?.find(innerItem => item.individualId === innerItem.individualId),
				}))
			);
		}
	}, [JSON.stringify(response)]);

	useEffect(() => {
		const keypress = window.addEventListener('keypress', event => {
			event.key === 'Enter' && searchAddressBook(true);
		});

		return () => window.removeEventListener('keypress', keypress);
	}, []);
	const options = {
		options: [
			{ name: 'All Memberships', value: 'All Memberships' },
			{ name: 'Full Membership', value: 'Full Membership' },
			{ name: 'Honorary Membership', value: 'Honorary Membership' },
			{ name: 'Life Membership', value: 'Life Membership' },
			{ name: 'Temporary Membership', value: 'Temporary Membership' },
		],
	};

	return {
		addressBookData,
		membershipType,
		setMembershipType,
		resetValues,
		options,
		loading,
		statusCode,
		searchAddressBook,
		nameRef,
		membershipTypeRef,
		searchName,
		setSearchName,
	};
};

export default useAddressBook;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.DRAWSARCHIVE_SEARCH:
			newState = {
				...state,
				playerSearchPath: action.data.playerSearchPath,
				flagImagePath: action.data.flagImagePath
			};
			logger.log('[DrawsArchiveSearch] reducer - DRAWSARCHIVE_SEARCH: %o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

export default {
	CONFIG_MOUNT: 'CONFIG_MOUNT',
	CONFIG_UPDATE: 'CONFIG_UPDATE',
	CONFIG_ERROR: 'CONFIG_ERROR',
	CONFIG_LOADING: 'CONFIG_LOADING',
	CONFIG_EXPIRED: 'CONFIG_EXPIRED',
	CONFIG_LOADSTATIC: 'CONFIG_LOADSTATIC',
	CONFIG_STATIC_LOADING: 'CONFIG_STATIC_LOADING',
	CONFIG_STATIC_UPDATE: 'CONFIG_STATIC_UPDATE',
};

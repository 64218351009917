import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = path => {
	let hdr = restHeaders();
	// path = 'http://localhost:8080/scoring-status-files/wta317175-inprogress.json';
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

export default {
	fetch,
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import axios from 'axios';
import DebentureProspect from './elements/DebentureProspect';
import DebentureApplication from './elements/DebentureApplication';
import USAHillRegistration from './elements/USAHillRegistration';
import PicnicRegistration from './elements/PicnicRegistration';
import RoleRegistration from './elements/RoleRegistration';
import RetailCompetition from './elements/RetailCompetition';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Register Button
 *
 *   also referred to as myW button
 *   handles functionality of join button and for any button requiring myW logged in status
 * -----------------------------------------------------------------------------
 *
 * @param state
 * @param state.name {String} The icon name.
 * @param state.style {Object} CSS styles to apply to the component.
 * @param state.size Valid CSS font-size value. The font size of the icon. Default: 22. Note: if you set fontSize in state.style, it will be overwritten with this value.
 * @param state.color Valid CSS color value. Default: #FFFFFF. Note: if you set color in state.style, it will be overwritten with this value.
 *
 * @return {*}
 * @constructor
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['Config'],
		...props.data,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class RegisterButton extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };

		this.openScreen = this.openScreen.bind(this);
		this.register = this.register.bind(this);

		logger.log('[RegisterButton] constructor - state:%o: ', this.state);
	}

	componentDidMount() {
		//logger.log("[RegistrationButton] mounted %o: ", this.state);
	}

	componentDidUpdate() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	openScreen(data) {
		this.props.openScreen(data);
	}

	register(type, buttonParams) {
		if (type != 'transport') {
			this.registerModal('send');
		} else {
			this.registerModal('sendTransport');
		}

		let data = {
			jwtToken: null,
			...buttonParams,
		};
		let url = '';
		switch (type) {
			case 'deb_prospect':
				url = this.state.myWim.api.roleRegInit.replace('<type>', type);
				break;
			case 'transport':
				url = this.state.myWim.api.roleRegInit.replace('<type>', type);
				break;
			default:
				break;
		}

		this.props
			.getJWT()
			.then(token => {
				data.jwtToken = token.jwt.id_token;
				logger.log('[RegisterButton] register - data:%o', data);
				this.callRegister(url, data, type);
			})
			.catch(error => {
				logger.error('[RegisterButton] register - error');
				this.registerModal('error');
			});
	}

	callRegister(url, callData, type) {
		logger.log('[RegisterButton] callRegister - url:%o, data:%o', url, callData);

		let data = {
			method: 'post',
			url: url,
			data: callData,
			headers: { 'Content-type': 'application/json' },
		};

		let successTimout = type != 'transport' ? 3000 : 100;

		logger.log('[RegisterButton] callRegister - call:%o', data);

		let request = axios(data)
			.then(res => {
				let respData = op.get(res, 'data', false);
				if (res.status == 200 && respData) {
					setTimeout(() => {
						this.processSuccess(respData);
					}, successTimout);
				} else {
					//logger.error('[RegisterButton] error subscribing - res: %o', res);
					this.registerModal('error');
					this.setState({
						registerResponse: false,
					});
				}
			})
			.catch(error => {
				//logger.error('[RegisterButton] error subscribing: %o', error);
				this.registerModal('error');
				this.setState({
					registerResponse: false,
				});
			});
	}

	processSuccess(respData) {
		logger.log('[RegisterButton] success subscribing: %o', respData);
		this.registerModal('success');

		this.setState({
			registerResponse: respData,
		});
	}

	registerModal(state) {
		if (state == 'send') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'REGISTERING',
				message: 'Please do not close your browser window while registration is in process.',
				useTimer: false,
			});
		} else if (state == 'send') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'SUBMITTING',
				message: 'Please do not close your browser window while in process.',
				useTimer: false,
			});
		} else if (state == 'success') {
			this.props.showModal({
				showModal: false,
			});
		} else if (state == 'error') {
			logger.log('[RoleRegistration] updateModal - state: %o', state);

			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: 'REGISTERING...',
				message: 'There was an error processing your request. Please try again.',
				useTimer: true,
			});
		}
	}

	render() {
		logger.log('[RegisterButton] render - state:%o: ', this.state);

		let type = op.get(this.state, 'registrationType', null) || this.state.type;

		return (
			<Fragment>
				{type == 'mywimbledon' || type == null ? (
					<div className={this.state.style}>
						<div
							className="generic-button_wrapper"
							onClick={() => {
								this.openScreen({ screen: 'register_screen', cid: 'register_button' });
							}}>
							<div className={'generic-button btn solid myw center'}>
								<div className="content">
									JOIN{' '}
									<i
										className="wim-icon-myw"
										style={{ position: 'relative', top: '5px' }}
										aria-hidden
									/>
									WIMBLEDON
								</div>
								<div className="border"></div>
							</div>
						</div>
					</div>
				) : type == 'transport_register' ? (
					<RoleRegistration
						state={this.state}
						openScreen={this.openScreen}
						register={this.register}
						roleId={1000310}
						label={'ENABLE ACCESS TO TRANSPORT'}
						currentUser={this.state.currentUser}
					/>
				) : type == 'debenture_register' || type == 'debenture' ? (
					<DebentureProspect state={this.state} openScreen={this.openScreen} register={this.register} />
				) : type == 'debenture_application' ? (
					<DebentureApplication state={this.state} openScreen={this.openScreen} />
				) : type == 'usahill_registration' ? (
					<USAHillRegistration state={this.state} openScreen={this.openScreen} style="two-col margin" />
				) : type == 'picnic_registration' ? (
					<PicnicRegistration state={this.state} openScreen={this.openScreen} style="" />
				) : type == 'ballot_register' ? null : type == 'retail_competition' ? ( //<BallotRegistration state={this.state} openScreen={this.openScreen} style="" />
					<RetailCompetition state={this.state} openScreen={this.openScreen} style="two-col margin" />
				) : null}
			</Fragment>
		);
	}
}

/**
 * -----------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------
 */
export default connect(mapStateToProps, mapDispatchToProps)(RegisterButton);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values } from 'appdir/main';
import { getAppLink } from 'appdir/components/general/Util';
import deps from 'dependencies';
import op from 'object-path';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import classNames from 'classnames';
import Time from 'appdir/components/common-ui/Time';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import NewFlag from 'appdir/components/common-ui/NewFlag';
import ContentDate from 'appdir/components/cms/ContentDate/index.js';

const mapStateToProps = (state, props) => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	play: data => dispatch(deps.actions.VideoPlayer.play(data)),
});

/**
 * uses a prop of attributes for data
 * attributes: {
 *   col-style: "one-col",
 *   contentId: "5769779656001",
 *   date: 1523631046799,
 *   images:{
 *     small: "http://wimbledonprogressivedl.edgesuite.net/2014/t…76584001_5769779656001-vs.jpg?pubId=3506358525001", medium: "http://wimbledonprogressivedl.edgesuite.net/2014/t…76584001_5769779656001-vs.jpg?pubId=3506358525001", large: "http://wimbledonprogressivedl.edgesuite.net/2014/t…76584001_5769779656001-vs.jpg?pubId=3506358525001"
 *   }
 *   shortTitle: "Throwback Thursday: The luckiest Wimbledon shot ever?",
 *   title: "Throwback Thursday: The luckiest Wimbledon shot ever?"
 *   type: "video"
 *   url: "/en_GB/news/video/5769779656001.html"
 * }
 *
 * cmsId - used for video and for recording favorited content
 * contentId - used for the newFlag.  In this case contentId is not related content id, but misnoer for cmsId
 *    6/29/20, change uses of contentId for id and NewFlag to cmsId
 */

class NewsTileNoRoute extends Component {
	constructor(props) {
		super(props);

		this.onVideoEvent = this.onVideoEvent.bind(this);
		this.renderNonVid = this.renderNonVid.bind(this);

		let attributes = props['attributes'];
		attributes['type'] = attributes['type'].toLowerCase();

		this.state = {
			...props,
			favourites: this.props.favourites,
			onClick: props.onClick,
			update: props.update,
			...attributes,
		};

		if (attributes['type'] == 'video') {
			//logger.log('[NewsTileNoRoute] - attr:%o', attributes);
		}
		//logger.log('[NewsTileNoRoute] - attr:%o', attributes);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	onVideoEvent(event) {
		logger.info('[NewsTileNoRoute] onVideoEvent - event:%o', event);

		if (event == 'close') {
			if (this.props.onClose) {
				this.props.onClose();
			}
			this.setState({
				videoId: '',
			});
		}
	}

	/**
	 * return videoplayer if have current videoId
	 *   videoId set by click on news tile
	 */
	getVideo() {
		//logger.log('[NewsTileNoRoute] getVideo - state:%o', this.state);
		if (this.state.videoId) {
			let attributes = {
				playerId: 'main',
				contentId: null,
				cmsId: this.state.cmsId,
				videoUrl: this.state.videoUrl,
				title: this.state.title,
				thumb: this.state.thumb,
				date: this.state.date,
				shareUrl: this.state.shareUrl,
				adUrl: this.state.adUrl,
				nextUp: this.state.nextUp,
				autoplay: true,
				fullscreenMode: '',
				style: `video-wrapper modal ${this.state['col-style']}`,
				streamType: 'vod',
				aspect: this.state.hasOwnProperty('aspect') ? this.state.aspect : '',
				videoSrc: this.state.videoSrc,
			};
			//logger.log('[NewsTileNoRoute] getVideo - attr:%o', attributes);
			return <VideoPlayer key="news" attributes={attributes} onEvent={this.onVideoEvent} />;
		} else {
			return null;
		}
	}

	/**
	 * handle clicks from news tile
	 */
	onClick() {
		if (this.state.onClick) {
			this.state.onClick();
		} else if (this.state.type.toLowerCase() == 'video') {
			//this.props.play(this.state.contentId);
			logger.log('[NewsTileNoRoute] onClick - state:%o', this.state);
			if (this.state.cmsId) {
				if (values.webview) {
					let videoUrl = `/en_GB/video/media/${this.state.cmsId}.html`;
					videoUrl = getAppLink(videoUrl, '');
					document.location.href = videoUrl.url;
				} else {
					this.setState({
						videoId: this.state.cmsId,
					});
				}
			} else {
				logger.warn('[NewsTileNoRoute] onClick - no cmsId in state for this video');
			}
		}
		else {
			this.setState({
				nav: this.state.url,
			});
		}
	}

	isFavorited(data, type) {
		let contentType = type == 'video' ? 'videos' : 'articles';
		let updatedFav = {};
		let favArry = {
			...this.state.favourites,
		};
		// favArry[contentType] = this.state.favourites[contentType];
		favArry[contentType] = op.get(this.state, `favourites.${contentType}`, []);

		if (favArry[contentType].indexOf(data.cmsId) !== -1) {
			return true;
		} else {
			return false;
		}
	}

	renderNonVid(aspect, imageUrl, date_ms) {
		return (
			<span className="content" onClick={() => this.onClick()}
						onKeyDown={() => (e.key === 'Enter' ? this.onClick() : null)}>
				<div className={`image-container ${aspect}`}>
					<img src={imageUrl} role="none" tabIndex={-1} aria-hidden />
					{this.state.type === 'video' ? (
						<i className="wim-icon-video" tabIndex={-1} aria-hidden role="none" />
					) : null}
				</div>				
				{this.state.type === 'video' ? duration : null}
				<div className="type-tag" role="none" aria-hidden tabIndex={-1}>
					{this.state.type}
				</div>
				{/* format="ddd DD MMM YYYY kk:mm z" */}
				<div className="news-timestamp">
					<div>
						<h6 aria-hidden role="none" tabIndex={-1}>
							<ContentDate date={date_ms} />
						</h6>
					</div>
				</div>
				<h3 className="news-title purple" aria-hidden role="none" tabIndex={-1}>
					<div aria-hidden role="none" tabIndex={-1}>
						{this.state.title}
					</div>
				</h3>
			</span>
		);
	}

	formatDuration(duration){
		if(duration.indexOf(":") == -1){
			let sec_num = parseInt(duration, 10);
			if(sec_num > 1000){
				sec_num = Math.floor(sec_num/1000);
			}
			let hours   = Math.floor(sec_num / 3600);
			let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
			let seconds = sec_num - (hours * 3600) - (minutes * 60);

			if (hours   < 10) {hours   = "0"+hours;}
			if (minutes < 10) {minutes = "0"+minutes;}
			if (seconds < 10) {seconds = "0"+seconds;}
			if (hours != "00"){
				return hours+':'+minutes+':'+seconds;
			}else{
				return minutes+':'+seconds;
			}
			
		}else{
			return duration;
		}
	}

	render() {
		//logger.log('[NewsTileNoRoute] render - state:%o', this.state);

		let aspect = this.state.hasOwnProperty('aspect') ? this.state.aspect : '';

		let tileClasses = classNames('news-tile', this.state.new, this.state.type + '-tag', this.state['col-style'], this.state?.classes);

		let date_ms = this.state.date || this.state.displayDate;

		let imageUrl = this.state.images
			? this.state.images.medium
			: this.state.thumbURL || this.state.thumb || this.state.img;

		if (this.state.nav) {
			return <WimRedirect push to={this.state.nav} />;
		} else {
			let duration = '';
			if (this.state.duration && this.state.duration != '') {
				//logger.log('[NewsTileNoRoute] render - duration:%o', this.state.duration);
				duration = <div className="duration-tag">{this.formatDuration(this.state.duration)}</div>;
			}

			//logger.log('[NewsTileNoRoute] render - state:%o', this.state)

			if (this.state.type.toLowerCase() != 'video') {
				imageUrl = imageUrl;
				//logger.log('[NewsTileNoRoute] render - img:%o', imageUrl);

				return (
					<span className={tileClasses}>
						<NewFlag
							data={{
								url: this.state.url,
								contentId: this.state.cmsId,
								displayDate: date_ms,
							}}
						/>
						{this.state.isSidePanel && !values.webview ? (
							<a onClick={e => this.props.handleNav(e, this.state.url)}>
								{this.renderNonVid(aspect, imageUrl, date_ms)}
							</a>
						) : (
							<div>{this.renderNonVid(aspect, imageUrl, date_ms)}</div>
						)}
					</span>
				);
			} else {
				return (
					<span className={tileClasses}>
						<NewFlag
							data={{
								url: this.state.url,
								contentId: this.state.cmsId,
								displayDate: date_ms,
							}}
						/>
						<div
							className="news-tile-inner"
							id={this.state.cmsId}
							onClick={() => this.onClick()}
							aria-label={`video, ${this.state.title}`}
							tabIndex={0}
							onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? this.onClick() : null)}>
							<div className={`image-container ${aspect}`} tabIndex={-1} aria-hidden role="none">
								<img src={imageUrl} tabIndex={-1} aria-hidden role="none" />
								{this.state.type === 'video' ? (
									<i className="wim-icon-video" tabIndex={-1} aria-hidden role="none" />
								) : null}
							</div>							
							{this.state.type === 'video' ? duration : null}
							<div className="type-tag" aria-hidden role="none" tabIndex={-1}>
								{this.state.type}
							</div>
							{/* format="ddd DD MMM YYYY kk:mm z"  */}
							<div className="news-timestamp" aria-hidden role="none" tabIndex={-1}>
								<div>
									<h6 aria-hidden role="none" tabIndex={-1}>
										<ContentDate date={date_ms} />
									</h6>
								</div>
							</div>
							<h3 className="news-title purple" alt="" aria-hidden role="none" tabIndex={-1}>
								{this.state.title}
							</h3>
						</div>
						{this.getVideo()}
					</span>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsTileNoRoute);

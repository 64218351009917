import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getCleanLink } from 'appdir/components/general/Util';
import WimLink from 'appdir/components/general/WimLink';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['SubNav'], props);
};

const mapDispatchToProps = (dispatch, props) => ({});

class SubNav extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		//logger.log('[SubNav] props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[TimeSelect] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	// onSubnavClick(url) {
	// 	logger.log('[MainNav] onSubnavClick - prev:%o next:%o', url);
	// }

	render() {
		const { match, location, history } = this.props;

		let subnavClasses = this.state.open
			? classNames('nav-item-subnav', 'nav-item-subnav-open')
			: classNames('nav-item-subnav');
		let featured = this.state.featured ? this.state.featured : [];

		//logger.log('[SubNav] render - loc:%o', location);

		return (
			<ul className={subnavClasses} role="list">
				<div className="submenu">
					{this.state.items.length > 0 ? (
						this.state.items.map(item => {
							return (
								<li
									key={item.title}
									onClick={() => {
										this.state.onClick(item.link);
									}}>
									<WimLink to={item.link} external={item.external} title={item.title}>
										{item.title}
									</WimLink>
								</li>
							);
						})
					) : (
						<li key={'holder'}></li>
					)}
				</div>

				{(() => {
					return featured.length > 0 ? <div className="feature-label">Featured</div> : null;
				})()}

				{featured.map(item => {
					//logger.log('[SubNav] item:%o', item);
					let link = getCleanLink(item.link);
					return (
						<li
							className="feature-pic"
							key={item.title}
							tabIndex={-1}
							role="none"
							aria-hidden
							onClick={() => {
								this.state.onClick(link.url);
							}}>
							<WimLink to={link.url} measure={['Menu', 'Featured', item.title]} title={item.title}>
								<div className="feature-image" tabIndex={-1} role="none" aria-hidden>
									<img tabIndex={-1} role="none" aria-hidden src={item.image} />
								</div>
								<span tabIndex={-1} role="none" aria-hidden>
									{item.title}
								</span>
							</WimLink>
						</li>
					);
				})}
			</ul>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubNav);

import filter from 'lodash/filter';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapSateToProps = (state, props) => {
	return {
		...state['PlayerChallengePage'],
	};
};
const mapDispatchToProps = (dispatch, props) => ({});

class WSingles extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
		//this.state = {filters: this.props.filters, ...this.props.data, "selectedIndex": 0};
		//this.eventStats = [];
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	componentDidUpdate(prevProps, prevState) {}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	/*setDataIndex(currFilter) {
        let defaultIndex = 0;
        for(let i = 0; i < this.eventStats.length; i++) {
            if(this.eventStats[i].stat_label == currFilter) {
                return i;
            }
        }
        return defaultIndex;
    }

    setSelected(index) {
        this.setState(prevState => {
            return {...prevState, "selectedIndex": index};
        });
    }

    formatLabel(labelData) {
        return labelData.replace("_", " ").split(" ").map(d => d.charAt(0).toUpperCase() + d.substring(1)).join(" ");
    }*/

	render() {
		if (this.state.events) {
			let FSingles = filter(this.state.events, { event_name: "Ladies' Singles" })[0];
			const statRows = FSingles.players.map(player => {
				return (
					<div className="row" key={player.name}>
						<div className="cell early-col">{player.rank}</div>
						<div className="cell early-col">{player.name}</div>
						<div className="cell early-col">{player.challenges}</div>
						<div className="cell early-col">{player.overturned}</div>
						<div className="cell early-col">
							{Math.round((player.overturned / player.challenges) * 100)}%
						</div>
					</div>
				);
			});
			return (
				<div className="event-stats four-col page-one">
					<div className="datatable">
						<div className="title">Challenge % Leaders - Ladies' Singles</div>
						<div className="headers">
							<div className="cell early-col">Rank</div>
							<div className="cell early-col">Player</div>
							<div className="cell early-col">Challenges</div>
							<div className="cell early-col">Overturned</div>
							<div className="cell early-col">% Overturned</div>
						</div>
						{statRows}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapSateToProps, mapDispatchToProps)(WSingles);

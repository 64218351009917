import React, { Component, Fragment } from 'react';
import { logger } from '../logger';
import op from 'object-path';

const shareUrls = {
	"Mail": {
		"label": "Share via Email",
		"path": "mailto:?subject=<email_title>&body=<email_text><email_footer>"
	},
	"Facebook": {
		"label": "Share on Facebook",
		"path": "https://www.facebook.com/sharer/sharer.php?u=<url>"
	},
	"Twitter": {
		"label": "Tweet",
		"path": "https://twitter.com/share?url=<url>&text=<title>"
	},
	"Google": {
		"label": "Share on Google+",
		"path": "https://plus.google.com/share?url=<url>"
	},
	"Copy": {
		"label": "Share via Link",
		"path": "<share_url>"
	}
}


const line_break = '%0D%0A';

export default class Share extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			open: false
		}

		this.styles = props.styles;

		//logger.log('Share: constructor - state:%o footer:%o', this.state, this.share_footer);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('Share: componentDidUpdate - state:%o props.smallView:%o', this.state, this.props.smallView);
	}

	onButtonClick(e) {
		//logger.log('Share: onButtonClick - e:%o', e.currentTarget);

		if (this.props.onClick) {
			this.props.onClick();
		}

		// this.setState({
		// 	open: !this.state.open,
		// 	copyOpen: false
		// });
	}

	render() {
		//logger.log('Share: render - state:%o', this.props);
		let inline = {};
		let data_state = '';
		
		if(this.props.first) {
			inline = {
				margin: "0 0 0 10px"
			}
		}

		if (this.state.copyOpen) {
			data_state = 'open'
		}


		if (this.props.shareUrl == '') {
			return null
		}
		else {
			return (
				<button 
					className={`${this.styles.icon} ${this.styles.share}`} 
					style={inline} 
					onClick={(e) => this.onButtonClick(e)} 
					data-state={data_state}
					title="Share">
				</button>
			)
		}
	}
	
}

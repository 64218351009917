import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = path => {
	return axios.get(path).then(({ data }) => data);
};

export default {
	fetch,
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SITEMAP_MOUNT:
			//newState = Object.assign({}, state, {...action.data});
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { values } from 'appdir/main';
import includes from 'lodash/includes';
import ReactEcp from 'react-ecp';
import NextUp from './NextUp';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import axios from 'axios';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoPlayer
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['VideoPlayer'],
		...props,
		header: state['Header'],
		windowSize: state['PageHeader'].windowSize,
		audioPreferences: state['Controller'].userPreferences['aiAudio'],
		accountId: state['Config']?.videoPlayer?.brightCoveId
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: id => dispatch(deps.actions.VideoPlayer.mount(id)),
	play: data => dispatch(deps.actions.VideoPlayer.play(data)),
	fullscreen: data => dispatch(deps.actions.VideoPlayer.fullscreen(data)),
	fullwindow: data => dispatch(deps.actions.VideoPlayer.fullwindow(data)),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
	updateUser: settings => dispatch(deps.actions.VideoPlayer.updateUser(settings)),
	updateUserPreference: data => dispatch(deps.actions.Controller.updatePreference(data)),
});

class VideoPlayer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			videoData: null,
		};

		this.init = false;
		this.playing = false;
		this.player; // hold ecp player
		this.volume; // volume value
		this.userVolumeAction = false; //has user interacted with volume
		this.playObj; // ecp play object with video attributes
		this.positionId = this.props.attributes.positionId ? this.props.attributes.positionId + '_' : '';
		this.cmsId = this.props.attributes.cmsId;
		this.playerName = this.props.attributes.playerId; // for players data in state
		this.name = '[VideoPlayer_' + this.playerName + '_' + this.cmsId + ']'; // for logging
		this.playerId = 'player_' + this.playerName + '_' + this.cmsId; // for ecp id
		this.loadedId;
		this.isMP4only = false;
		this.lastCheckedId;
		this.measure_start = false;
		this.measure_50 = false;
		this.measure_95 = false;
		this.heartbeatTimer;
		this.heartbeatCounter = 0;
		this.bcmeasured = false; //brightcove measurement

		// todo:  this 300 should be in config somewhere as videoHeatbeat
		this.vodHeartbeatDuration = 300;
		this.timeStamp = 0;

		this.nextUpContent;
		this.nextUpIndex;

		this.modalStyle = '';
		if (this.props.attributes.style && includes(this.props.attributes.style, 'modal')) {
			this.modalStyle = 'fullwindow';

			if (includes(this.props.attributes.style, 'header')) {
				this.modalStyle = 'fullwindowheader';
			}
		}

		this.sendAnalyticsEvent = this.sendAnalyticsEvent.bind(this);
		this.sendData = this.sendData.bind(this);

		logger.log(`${this.name} **********************************`);
		logger.log(`${this.name} constructor - state:%o`, this.state);

		this.sendState('stop');

		// if not the sidepanel player and
		// the player state already indicates playing or has the new id, force close to reset
		//logger.log(`${this.name} constructor - name:%o playerState:%o`, this.playerName, (this.state.players[this.playerName]);
		if (
			this.playerName != 'sidepanel' &&
			(this.state.players[this.playerName].state == 'play' ||
				this.state.players[this.playerName].id != this.cmsId)
		) {
			this.sendState('close');
		}
	}

	heartbeat() {
		logger.log('[VideoPlayer] - heartbeat A heartbeatCounter:%o', this.heartbeatCounter);
		this.heartbeatCounter = this.heartbeatCounter + 1;
		logger.log('[VideoPlayer] - heartbeat B heartbeatCounter:%o', this.heartbeatCounter);
	}

	componentDidMount() {
		this.props.mount();

		logger.log(`${this.name} componentDidMount - player_state:%o`, this.state.players[this.playerName].state);

		if (this.state.players[this.playerName].state == 'stop') {
			// set volume and check for current play status
			this.setVolume();
			this.checkVideo();
		} else if (
			this.state.players[this.playerName].state == 'close' &&
			this.state.players[this.playerName].id != this.cmsId
		) {
			this.props.onEvent('close');
		}

		// let audioTrackData = localStorage?.getItem('wim_audioTrack');

		// if (audioTrackData) {
		// 	audioTrackData = JSON.parse(audioTrackData);
		// } else {
		// 	audioTrackData = null
		// }

		// if (audioTrackData) {
		// 	this.state.updateUserPreference({ userPreferences: { aiAudio: audioTrackData } });
		// }
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log(`${this.name} componentWillReceiveProps - state:%o next:%o`, this.state, nextProps);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(`${this.name} componentDidUpdate - state:%o `, this.state);
		//logger.log(`${this.name} componentDidUpdate - contentId:%o players:%o `, this.contentId, this.state.players);
		logger.log(
			`${this.name} AAC componentDidUpdate - cmsId:%o player:%s state:%s`,
			this.cmsId,
			this.state.players[this.playerName].id,
			this.state.players[this.playerName].state
		);
		let attr = this.state.attributes;
		//assign video related content id
		//  player expects cmsId and constructs contentId if null
		if (this.state.contentId && this.state.attributes.contentId == null) {
			attr.contentId = this.state.contentId.replace('<id>', attr.cmsId);

			this.setState({
				attributes: attr,
			});
		}

		if (!this.cmsId && attr.videoUrl) {
			this.isMP4only = true;
		}

		if (
			this.state.players[this.playerName].state == 'play' &&
			this.state.players[this.playerName].id != this.cmsId
		) {
			this.closeModal();
		}

		//if a new id was set, means main being used for diff player
		//  should close this one
		if (
			//this.state.players[this.playerName].state == 'close' &&
			this.state.players[this.playerName].id != this.cmsId &&
			!this.isMP4only
		) {
			// if(attr.)
			logger.log(`${this.name} AAC componentDidUpdate cmsId invalid`);
			this.cmsId = null;
			this.props.onEvent('close');
		}

		// if receive the close state and this is the new player
		//   reset state to stop (to initiate new playback)
		//   and reset init to make sure processes data
		else if (
			this.state.players[this.playerName].state == 'close' &&
			this.state.players[this.playerName].id == this.cmsId
		) {
			logger.log(`${this.name} init false - receive the close state and this is the new player`);
			this.init = false;
			this.sendState('stop');
		}

		// if receive update and state is 'stop'
		//   and not started playback yet
		//   initiate process
		else if (this.state.players[this.playerName].state == 'stop') {
			if (!this.init) {
				logger.log(`${this.name} AAC componentDidUpdate checkVideo`);
				// set volume and check for current play status
				this.setVolume();
				this.checkVideo();
			}
		}

		// set volume and check for current play status
		this.setVolume();
		this.checkPlayState();

		if (this.state.attributes.playNextUp == undefined || this.state.attributes.playNextUp) {
			this.getNextUp();
		}
	}

	componentWillUnmount() {
		//logger.log(`${this.name} componentWillUnmount - player:%o`, this.player);
		if (this.player) {
			//set stop state
			this.sendState('stop');
			this.props.fullwindow({ className: false, type: false });

			//destroy the ecp player when unmounting, unmuting first
			this.player.unmute();
		}

		clearInterval(this.heartbeatTimer);
		this.heartbeatCounter = 0;
	}

	onPause() {
		//logger.log(`${this.name} onPause `);
		let players = this.state.players;
		if (this.playerName == 'archive' && players[this.playerName].state == 'paused_main') {
			this.sendState('paused_main');
		} else {
			this.sendState('pause');
		}
		if (this.heartbeatTimer) {
			//logger.log(`${this.name} onPause stop heartbeat timer`);
			clearInterval(this.heartbeatTimer);
		}
	}

	/**
	 * handle modal closing (fullwindow or true modal)
	 *  - destroy ecp player
	 *  - send close event to parent callback
	 *  - exit fullwindow
	 *  - update players state
	 */
	closeModal() {
		// eventsPlayer.destroy('#' + this.playerId);
		logger.log(`${this.name} closeModal init false`);
		this.init = false;
		this.props.onEvent('close');
		this.props.fullwindow({ className: false, type: false });
		this.sendState('stop');
	}

	/**
	 * Analytics functions from Brightcove Docs: 
     * Injects API calls into the head of a document
     * as the src for a img tag
     * img is better than script tag for CORS
     * @param {string} requestURL The URL to call to send the data
     * @return true
     */
    sendData(requestURL) {
		var scriptElement = document.createElement("img");
		scriptElement.setAttribute("src", requestURL);
		document.getElementsByTagName("body")[0].appendChild(scriptElement);
		return true;
	}

	// send analytics event
    sendAnalyticsEvent(vidId, video_name, duration) {
		const accountID = this.props.accountId;
		//creating session id
		const now = new Date().toISOString();
		const rand = Math.random() * 1000000;
		const session = parseInt(rand).toString() + "_" + now;
		// location properties
		const destination = encodeURI(window.location.href);
		let thisPageProtocol = document.location.protocol;
      	// data-collection api
      	let baseURL = thisPageProtocol + "//metrics.brightcove.com/tracker/v2/?";
		let eventType = "video_view"; //for our purposes we will likely only have this 
		
		let urlStr = "";
		// let time = evt.timeStamp;
		// let dateTime = new Date(parseInt(evt.timeStamp));
		// add params for all requests
		urlStr =
		  "event=" +
		  eventType +
		  "&session=" +
		  session +
		  "&domain=videocloud&account=" +
		  accountID +
		//   "&time=" +
		//   time +
		  "&destination=" +
		  encodeURI(destination);
		// source will be empty for direct traffic
		// if (source !== "") {
		//   urlStr += "&source=" + encodeURI(source);
		// }
		// add params specific to video events
		if (
		  eventType === "video_impression" ||
		  eventType === "video_view" ||
		  eventType === "video_engagement"
		) {
		  urlStr +=
			"&video=" +
			vidId +
			"&video_name=" +
			encodeURI(video_name) + 
			"&video_duration=" + duration;
		}
		// add params specific to video_engagement events
		// if (eventType === "video_engagement") {
		//   urlStr +=
		// 	"&video_duration=" + duration ;
		// }
		// add the base URL
		urlStr = baseURL + urlStr;
		// make the request
		this.sendData(urlStr);
		// log that we did this
		// if (settings.showLog) {
		logger.log("Data Collection request: %o, %o", urlStr)
		//   logEvent(
		// 	"analytics-event",
		// 	eventType,
		// 	"Data Collection request: " + urlStr,
		// 	dateTime.toISOString()
		//   );
		// }
		return;
	  }

	/**
	 * check if video data based or need to load from related content
	 *   then load the video
	 */
	checkVideo() {
		//if not initted
		//  prevents calling twice from ComponentDidUpdate
		logger.info(
			`${this.name} - checkVideo  - init:%o state:%o shareBase:%o`,
			this.init,
			this.state,
			this.state.shareBase
		);
		if (!this.init) {
			//load video if has state and data
			if (
				this.state.shareBase &&
				(this.state.attributes.videoUrl ||
					op.get(this.state, 'attributes.videoSrc', '') == 'brightcoveVideoSync')
			) {
				this.init = true;
				logger.info(`${this.name} - checkVideo  - init:%o state:%o`, this.init, this.state);
				if (this.state.attributes.cmsId && this.state.attributes.cmsId == this.lastCheckedId) {
					return; //already loaded data for this id
				} else {
					this.lastCheckedId = this.state.attributes.cmsId;
				}
				logger.info(`${this.name} - checkVideo  - mapVideoData`);
				this.mapVideoData(this.state.attributes).then(data => {
					this.loadVideo(data);
				});
			}

			//else if no data, load from related content
			// content id replaced with relatedContentid in news index
			//  not replaced when coming from related content tile (uses related contentId already)
			else if (this.state.relatedVideoPath && this.state.attributes.contentId) {
				this.init = true;
				logger.log(`${this.name} checkVideo - src:%o`, op.get(this.state, 'attributes.videoSrc', ''));
				//if it's a brightcove vid no need to do this
				if (op.get(this.state, 'attributes.videoSrc', '') != 'brightcoveVideoSync') {
					//let contentId = op.get(this.state, 'contentId', '').replace('<id>', this.state.attributes.contentId);
					let relatedPath = this.state.relatedVideoPath.replace('<videoId>', this.state.attributes.contentId);
					logger.log(`${this.name} checkVideo - related:%o`, relatedPath);

					deps.services.VideoPlayer.fetchRelated(relatedPath)
						.then(result => {
							logger.log(`${this.name} checkVideo - related - data:%o`, result);
							this.mapVideoData(result).then(data => {
								this.loadVideo(data);
							});
						})
						.catch(error => {
							// logger.warn('[VideoIndex] componentDidUpdate - :%o', error);
							this.closeModal();
						});
				}
			}

			if ((this.modalStyle == 'fullwindow' || this.modalStyle == 'fullwindowheader') && !this.props.header.fullwindowVideo) {
				this.props.fullwindow({ type: 'video', className: this.modalStyle });
			}
		}
	}

	mapVideoData(data) {
		let videoData = data.video ? data.video : data;
		logger.log(`${this.name}  mapVideoData - state:%o videoData:%o`, this.state, videoData);
		let videoPath = '';

		// if (!videoData.videoUrl || (videoData.videoUrl && !videoData.videoUrl.includes('brightcove'))) {
		if (
			videoData.videoSrc != 'brightcoveVideoSync' &&
			op.get(videoData, 'media.src', '') != 'brightcoveVideoSync'
		) {
			logger.log(`${this.name} mapVideoData not brightcove - data:%o`, videoData);
			let type = videoData.streamType ? videoData.streamType : 'vod';
			if (videoData.media) {
				if (videoData.media.m3u8) {
					videoPath = videoData.media.m3u8;
				} else {
					videoPath = videoData.media.mp4;
				}
			} else {
				videoPath = videoData.videoUrl;
			}

			let returnData = {
				id: `player_${this.playerName}_${data.cmsId}`,
				title: videoData.title,
				poster: videoData.images ? videoData.images[0].small : videoData.thumb,
				shareUrl: this.state.shareBase + videoData.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: videoPath,
						mimeType: this.getMimeType(videoPath, type),
						streamType: type,
					},
				],
				tracks: [],
				startSeconds: 0,
				autoplay: true,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				enableWebVTT: true,
				displayDate: videoData.displayDate ? videoData.displayDate : '',
			};

			//data.adUrl = data.adUrl.replace('https://www.wimbledon.com', 'http://dev.wimbledon.com:3071');

			if (data.adUrl) {
				returnData['ads'] = [
					{
						type: 'vast',
						tag: data.adUrl,
					},
				];
			} else if (videoData.media && videoData.media.adTag) {
				returnData['ads'] = [
					{
						type: 'vast',
						tag: videoData.media.adTag,
					},
				];
			}

			return Promise.resolve(returnData);
		}

		return deps.services.VideoPlayer.fetchVidDetails(videoData.cmsId)
			.then(result => {
				logger.log(`${this.name} mapVideoData fetchVidDetails - data:%o`, result);

				let sources = op.get(result, 'sources', []);
				sources = sources.filter(d => {
					if (d.type == 'application/x-mpegURL' && d.src.includes('https://')) {
						return true;
					} else {
						return false;
					}
				});

				logger.log(`${this.name} mapVideoData fetchVidDetails - sources:%o`, sources);

				let type = videoData.streamType ? videoData.streamType : 'vod';

				videoPath = sources[0].src;

				let returnData = {
					id: `player_${this.playerName}_${data.cmsId}`,
					title: videoData.title,
					poster: videoData.images ? videoData.images[0].small : videoData.thumb,
					shareUrl: this.state.shareBase + videoData.shareUrl,
					streams: [
						{
							cdn: 'Akamai',
							url: videoPath,
							mimeType: this.getMimeType(videoPath, type),
							streamType: type,
						},
					],
					tracks: [],
					startSeconds: 0,
					autoplay: true,
					autoplayPolicy: 'mutedinline',
					playsinline: true,
					enableWebVTT: true,
					displayDate: videoData.displayDate ? videoData.displayDate : '',
				};

				//data.adUrl = data.adUrl.replace('https://www.wimbledon.com', 'http://dev.wimbledon.com:3071');

				if (data.adUrl) {
					returnData['ads'] = [
						{
							type: 'vast',
							tag: data.adUrl,
						},
					];
				} else if (videoData.media && videoData.media.adTag) {
					returnData['ads'] = [
						{
							type: 'vast',
							tag: videoData.media.adTag,
						},
					];
				}

				return returnData;
			})
			.catch(error => {
				logger.warn('[VideoPlayer] mapVideoData - :%o', error);
			});
	}

	getMimeType(url, type) {
		if (type == 'live' || type == 'dvr') return 'application/x-mpegURL';
		let extensionArry = url.split('.');
		let vidExtension = extensionArry[extensionArry.length - 1];
		//logger.log('${this.name}  getMimeType - ext:%o', vidExtension);
		if (vidExtension.indexOf('mp4') !== -1) {
			return 'video/mp4';
		} else {
			return 'application/x-mpegURL';
		}
		// return 'application/x-mpegURL';
	}

	/** Don't think this is being used? **/
	// getAttributes(result) {
	// 	let videoPath = result.media.m3u8 ? result.media.m3u8 : result.media.mp4;
	// 	let adPath = result.media.adTag ? result.media.adTag : '';

	// 	let attributes = {
	// 		playerId: this.playerName,
	// 		videoUrl: videoPath,
	// 		adUrl: adPath,
	// 		title: result.title,
	// 		thumb: result.images[0].small,
	// 		autoplay: this.state.attributes.autoplay,
	// 		fullscreenMode: this.state.attributes.fullscreenMode,
	// 		style: this.state.attributes.style,
	// 		aspect: this.state.attributes.aspect,
	// 		shareUrl: result.shareUrl,
	// 		contentId: result.cmsId,
	// 		date: result.displayDate,
	// 	};

	// 	return attributes;
	// }

	/**
	 * request nextup content list from related content
	 */
	getNextUp() {
		if (this.state.nextupEnable && !this.nextUpContent && this.state.attributes.contentId) {
			let nextUpPath = this.state.nextUpPath
				? this.state.nextUpPath.replace('<contentId>', this.state.attributes.contentId)
				: '';
			logger.log(`${this.name} getNextUp - path:%o`, nextUpPath);

			if (nextUpPath) {
				this.nextUpContent = 'loading';
				deps.services.VideoPlayer.fetchNextUp(nextUpPath)
					.then(result => {
						//logger.log(`${this.name} getNextUp - data:%o`, result);
						this.nextUpContent = result.content;
						this.nextUpIndex = 0;
					})
					.catch(error => {
						//logger.warn('[VideoPlayer] getNextUp - :%o', error);
					});
			}
		}
	}

	/**
	 * play video from nextup countdown or click
	 */
	nextUpPlay(auto) {
		//logger.log(`${this.name} nextUpPlay - nextUpContent:%o`, this.nextUpContent, this.nextUpIndex);
		let result = this.nextUpContent[this.nextUpIndex];
		this.nextUpIndex = this.nextUpIndex + 1;
		// this.loadVideo(this.mapVideoData(result), auto);
		this.mapVideoData(result).then(data => {
			this.loadVideo(data, auto);
		});

		this.setState({
			nextUpVideo: null,
		});
	}

	nextUpUserEnable(on) {
		logger.log(`${this.name} nextUpUserEnable - on:%o`, on);
		this.props.updateUser({
			autoplay: on,
		});
	}

	/**
	 * load video into player
	 *  - if style set as fullwindow, dispatch action for other components (header) to update styles
	 *  - check media type
	 *  - construct play object
	 *  - load video
	 * @param {object} attr - video attributes
	 * @param {boolean} auto - true if autoplaying (from nextUp)
	 */
	loadVideo(attr, auto = false) {
		logger.log(`${this.name} loadVideo - id:%o attr:%o`, attr.id, attr);

		if (attr.id == this.loadedId) {
			return;
		}
		this.loadedId = attr.id;

		if (attr.adUrl) {
			this.playObj.ads = [
				{
					type: 'vast',
					position: 'preroll',
					tag: attr.adUrl,
				},
			];
		}

		this.setState(prevState => {
			return {
				...prevState,
				videoData: attr,
			};
		});

		this.props.updateViewedContent(attr.date, attr.cmsId);

		MeasurementUtils.dispatchMeasurementCall('video/radioPlay', attr);
	}

	/**
	 * assign volume to local variable from players state
	 */
	setVolume() {
		this.volume = this.state.players[this.playerName].volume;
	}

	/**
	 * decide to mute/unmute or play/pause based on player states
	 */
	checkPlayState() {
		if (this.player) {
			//logger.log(`${this.name} checkPlayState - name:%o volumeAction:%o players:%o`, this.playerName, this.userVolumeAction, this.state.players);

			logger.log(
				`${this.name} checkPlayState - player:%o name:%s main:%o side:%o archive:%o`,
				this.player,
				this.playerName,
				this.state.players.main.state,
				this.state.players.sidepanel.state,
				this.state.players.archive.state
			);

			let players = this.state.players;
			if (this.playerName == 'sidepanel') {
				if (players.main.state == 'play') {
					this.player.mute();
				}
				if (players.main.state == 'stop' && this.userVolumeAction) {
					this.player.unmute();
				}
			}
			if (this.playerName == 'archive') {
				if (players.archive.state == 'play' && players.main.state == 'start') {
					this.player.pause();
					//this.sendState('paused_main');
				}
				// if (players.archive.state == 'paused_main' && players.main.state == 'stop') {
				// 	this.player.play();
				// }
			}
		}
	}

	/**
	 * update players state in redux store for VideoPlayer
	 * @param {String} status - current player status ('play', 'stop')
	 */
	sendState(status) {
		if (this.cmsId == null) {
			return;
		}
		logger.log(`${this.name} - AAC sendState %o`, status);
		let playerSetting = {};
		playerSetting[this.playerName] = {
			id: this.cmsId ? this.cmsId : '',
			state: status,
			volume: this.volume,
		};
		this.props.play(playerSetting);
	}

	//
	// Callback handlers from react-ecp
	//

	onReady(amp) {
		this.player = amp;
		if (this.state.onReady) {
			this.state.onReady(this.player);
		}
	}

	onStart() {
		logger.log(`${this.name} - onStartEvent, this:%`, this);
		this.player.onVolumeChange = this.onVolumeEvent;
		this.playing = true;
		this.sendState('start');
		this.checkPlayState();

		if (!this.measure_start) {
			this.measure_start = true;
			this.measure_50 = false;
			this.measure_95 = false;
			this.heartbeatCounter = 0; // reset the heartbeat counter
			this.timeStamp = op.get(this.props, 'timeStamp', this.props.data);

			MeasurementUtils.dispatchMeasurementCall('video/videoStart', {
				...this.state.videoData,
				...this.additionalMeasurement(),
			});
		}

		// clear the heartbeatTimer
		if (this.heartbeatTimer) {
			clearInterval(this.heartbeatTimer);
		}

		// start the heartbeatTimer
		if (this?.state?.videoData?.streams?.[0]?.streamType !== 'vod') {
			logger.log(`${this.name} onStart start heartbeat timer'`);

			this.heartbeatTimer = setInterval(() => {
				this.heartbeatCounter++;

				if (this.heartbeatCounter === this.vodHeartbeatDuration) {
					MeasurementUtils.dispatchMeasurementCall(`video/heartbeat`, {
						...this.state.videoData,
						vodHeartbeatDuration: this.vodHeartbeatDuration,
						vodSegmentDuration: 0,
						...this.additionalMeasurement(),
					});
					this.heartbeatCounter = 0;
				}
			}, 1000);
		}

		this.setState({
			nextUpVideo: null,
		});
	}

	// awt:  why does onPlay not get called??
	onPlay() {
		logger.log(`${this.name} - onPlayEvent`);
		this.sendState('play');

		// clear the heartbeatTimer
		if (this.heartbeatTimer) {
			clearInterval(this.heartbeatTimer);
		}

		// start the heartbeatTimer
		if (this?.state?.videoData?.streams?.[0]?.streamType !== 'vod') {
			logger.log(`${this.name} onPlay start heartbeat timer'`);

			this.heartbeatTimer = setInterval(() => {
				this.heartbeatCounter++;

				if (this.heartbeatCounter === this.vodHeartbeatDuration) {
					MeasurementUtils.dispatchMeasurementCall(`video/heartbeat`, {
						...this.state.videoData,
						vodHeartbeatDuration: this.vodHeartbeatDuration,
						vodSegmentDuration: 0,
						...this.additionalMeasurement(),
					});
					this.heartbeatCounter = 0;
				}
			}, 1000);
		}
	}

	onUserAction(data) {
		logger.info(`${this.name} - onUserAction data:%o`, data);

		let actionData = {
			...this.state.videoData,
			...this.additionalMeasurement(),
		};

		if (data.action == 'overlay_unmute') {
			this.userVolumeAction = true;
		}

		if (data.action == 'altAudio_open') {
			//if (data.action == 'altAudio_open' && metricsData.subType == 'video_shot_highlight') {
			let selectedAudio = this?.player?.amp?.tracks?.data?.audioTracks?.list?.find(track => {
				return track.enabled === true;
			})?.data;

			logger.log('[VideoPlayer] onUserAction - selectedAudio:%o', selectedAudio);
			this.props.updateUserPreference({
				userPreferences: { aiAudio: { id: parseInt(selectedAudio.id, 10), label: selectedAudio.label } },
			});
		}

		if (data.action == 'altAudio_close') {
			//if (data.action == 'altAudio_close' && metricsData.subType == 'video_shot_highlight') {
			let selectedAudio = this?.player?.amp?.tracks?.data?.audioTracks?.list?.find(track => {
				return track.enabled === true;
			})?.data;

			logger.log('[VideoPlayer] onUserAction - selectedAudio:%o', selectedAudio);

			try {
				if (this?.props?.audioPreferences?.label !== selectedAudio.label) {
					actionData.currentAudio = selectedAudio.label;
					actionData['preferences']['aiAudio'] = {
						id: parseInt(selectedAudio.id, 10),
						label: selectedAudio.label,
					};
				}
			} catch (e) {
				logger.error('[VideoPlayer] onUserAction - measurement selectAudio failed e:%o', e);
			}
			this.props.updateUserPreference({
				userPreferences: { aiAudio: { id: parseInt(selectedAudio.id, 10), label: selectedAudio.label } },
			});
		}

		MeasurementUtils.dispatchMeasurementCall(`video/${data.action}`, {
			...actionData,
		});

		if (this.state.onUserAction) {
			this.state.onUserAction(data);
		}
	}

	onComplete() {
		logger.log(`${this.name} - onComplete`, event);
		this.sendState('stop');
		if (this.nextUpContent && this.nextUpIndex < this.nextUpContent.length) {
			this.setState({
				nextUpVideo: this.nextUpContent[this.nextUpIndex],
			});
		}
	}

	onTimeUpdate(data) {
		//logger.log(`${this.name} - onTimeUpdate`, event);
		let percentage = (data.time / data.duration) * 100;
		let vodSegmentDuration = Math.round(data.duration / 2);
		if (op.get(this.state, 'attributes.videoSrc', '') == 'brightcoveVideoSync' && !this.bcmeasured) {
			this.bcmeasured = true;
			logger.log("[VideoPlayer] brightcove video:%o", this.state);
			let vidId = op.get(this.state, 'attributes.contentId', '');
			let title = op.get(this.state, 'attributes.title', '');
			this.sendAnalyticsEvent(vidId, title, data.duration);
		}

		if (this?.state?.videoData?.streams?.[0]?.streamType == 'vod') {
			if (percentage >= 95 && !this.measure_95) {
				logger.log(`${this.name} - onTimeUpdate 95`, data);
				this.measure_95 = true;
				MeasurementUtils.dispatchMeasurementCall(`video/percentage_95`, {
					...this.state.videoData,
					vodSegmentDuration,
					...this.additionalMeasurement(),
				});
			} else if (percentage >= 50 && !this.measure_50) {
				logger.log(`${this.name} - onTimeUpdate 50`, data);
				this.measure_50 = true;
				MeasurementUtils.dispatchMeasurementCall(`video/percentage_50`, {
					...this.state.videoData,
					vodSegmentDuration,
					...this.additionalMeasurement(),
				});
			}
		} else {
		}

		if (this.state.onVideoEvent) {
			this.state.onVideoEvent({
				type: 'onTimeUpdate',
				data: data,
			});
		}

		//if state was set to 'start', once playhead moves, set to 'play'
		if (this.state.players[this.playerName].state == 'start') {
			this.sendState('play');
		}
	}

	onLoadedMetadata(data) {
		logger.log(`${this.name} - onLoadedMetadata`, data);
		// why is this there?  because we need to wait for the tracks to actually download.  it is dumb!
		const audioInterval = setInterval(() => {
			if (data?.audioTracks?.length > 0 && this?.player?.amp?.appState?.playState == 'playing') {
				logger.log(`${this.name} -  onLoadedMetadata playState:%o`, this?.player?.amp?.appState?.playState);

				// data is loaded, so we can stop the interval
				clearInterval(audioInterval);

				let availableTracks = data?.audioTracks.map(track => {
					logger.log(`${this.name} -  onLoadedMetadata interval audioTracks.map track.data:%o`, track.data);
					return track.data;
				});

				logger.log(`${this.name} -  onLoadedMetadata interval availableTracks:%o`, availableTracks);

				let selectedAudio = availableTracks.find(track => {
					return track.enabled === true && track.language !== '';
				});

				let defaultAudio = { id: selectedAudio.id, label: selectedAudio.label };

				let audioTrackData = this?.props?.audioPreferences;

				logger.log(
					`${this.name} -  onLoadedMetadata interval selectedAudio:%o, userPrefAudio:%o`,
					selectedAudio,
					audioTrackData
				);

				let actionData = {
					...this.state.videoData,
					...this.additionalMeasurement(),
				};
				if (selectedAudio) {
					if (audioTrackData?.id >= 0) {
						// let's just make sure we are comparing apples to apples, so doing a parseInt here.
						if (parseInt(audioTrackData.id, 10) !== parseInt(selectedAudio.id, 10)) {
							logger.log(
								`${this.name} -  onLoadedMetadata interval switch to audioPref:%o`,
								audioTrackData
							);

							// parseInt because switch audio is looking for the index of the track
							this?.player?.switchAudio(parseInt(audioTrackData?.id, 10));

							logger.log(`${this.name} -  onLoadedMetadata interval highlight rewind`);
							this.player.seek(0);

							actionData.preferences['aiAudio'] = this?.props?.audioPreferences;
							MeasurementUtils.dispatchMeasurementCall(`video/multipleTracks`, {
								...actionData,
								availableTracks: data?.audioTracks.map(tracks => {
									return tracks.data;
								}),
							});
						} else {
							this.state.updateUserPreference({ userPreferences: { aiAudio: defaultAudio } });
							actionData.preferences['aiAudio'] = defaultAudio;

							MeasurementUtils.dispatchMeasurementCall(`video/multipleTracks`, {
								...actionData,
								availableTracks: data?.audioTracks.map(tracks => {
									return tracks.data;
								}),
							});
						}
					} else {
						logger.log(
							`${this.name} -  onLoadedMetadata interval set a default for users audioPref:%o`,
							defaultAudio
						);
						actionData.preferences['aiAudio'] = defaultAudio;

						MeasurementUtils.dispatchMeasurementCall(`video/multipleTracks`, {
							...actionData,
							availableTracks: data?.audioTracks.map(tracks => {
								return tracks.data;
							}),
						});
						this.state.updateUserPreference({ userPreferences: { aiAudio: defaultAudio } });
					}
				}
			}
		}, 100);
	}

	onFullscreenChange(isFullscreen) {
		this.props.fullscreen(event.state == 'enter');
	}

	additionalMeasurement() {
		logger.log(`${this.name} additionalMeasurement - amp.audioTracks:%o`, this?.player?.amp);

		let playerTracks = this?.player?.amp?.audioTracks?.list?.map(track => {
			logger.log(`${this.name} additionalMeasurement - track:%o`, track);

			return track.data;
		});
		logger.log(`${this.name} additionalMeasurement - playerTracks:%o`, playerTracks);
		let captions = localStorage?.getItem('ecp') ? JSON.parse(localStorage?.getItem('ecp'))?.captions : false;
		let additionalMeasurement = {
			preferences: {
				captions,
				...(playerTracks.length > 0 &&
					this?.props?.audioPreferences && { aiAudio: this?.props?.audioPreferences }),
			},
			...(playerTracks.length > 0 && { availableTracks: playerTracks }),
		};

		additionalMeasurement['preferences']['captions'] = captions;

		logger.log(`${this.name} additionalMeasurement - additionalMeasurement:%o`, additionalMeasurement);

		return additionalMeasurement;
	}

	render() {
		logger.log(`${this.name} render - playerId:%o state:%o`, this.playerId, this.state);

		let aspect = this.state.attributes.hasOwnProperty('aspect') ? this.state.attributes.aspect : '';
		let style = this.state.attributes.hasOwnProperty('style') ? this.state.attributes.style : '';
		style += this.state.fullscreenVideo ? ' fullscreen' : '';
		let colors = {
			highlightColor: '#00884E',
			toolbarBackground: 'rgba(73, 77, 95, .8)',
			progressBackground: '#FFFFFF',
		};

		let playerConfig = [];

		if (this.state.playerConfig && this.state.videoData !== null) {
			playerConfig = cloneDeep(this.state.playerConfig);
			if (this.props.controlType) {
				playerConfig.controls.type = this.props.controlType;
			}
		}

		if (style.indexOf('modal') > -1) {
			return (
				<div className={`${style}`}>
					<i
						onClick={() => {
							this.closeModal();
						}}
						onKeyDown={e => {
							e.key === 'Enter' || e.key === ' ' ? this.closeModal() : null;
						}}
						role="button"
						aria-label="close"
						tabIndex={0}
						className="wim-icon-close"
					/>
					<div className={`video-player ${aspect}`}>
						{this.state.playerConfig && this.state.videoData !== null ? (
							<ReactEcp
								id={this.playerId}
								reset={false}
								ampBasePath="/libs/"
								playerConfig={playerConfig}
								videoConfig={this.state.videoData}
								forcePsuedoFullScreen={false}
								isPseudoFullscreen={false}
								onReady={amp => this.onReady(amp)}
								onUserAction={data => this.onUserAction(data)}
								onFullscreenChange={data => this.onFullscreenChange(data)}
								onStart={() => this.onStart()}
								onPlay={() => this.onPlay()}
								onPause={() => this.onPause()}
								onComplete={() => this.onComplete()}
								onTimeUpdate={data => this.onTimeUpdate(data)}
								onLoadedMetadata={data => this.onLoadedMetadata(data)}
								colors={colors}
							/>
						) : null}

						{this.state.nextUpVideo && this.state.nextupEnable && this.state.windowSize !== 'mobile' ? (
							<NextUp
								content={this.state.nextUpVideo}
								seconds={this.state.nextupSec}
								auto={this.state.user.autoplay}
								onPlay={auto => this.nextUpPlay(auto)}
								onUserEnable={on => this.nextUpUserEnable(on)}
							/>
						) : null}
					</div>
				</div>
			);
		} else {
			return (
				<div className={`video-player ${aspect}`}>
					{this.state.playerConfig && this.state.videoData !== null ? (
						<ReactEcp
							id={this.playerId}
							reset={false}
							ampBasePath="/libs/"
							playerConfig={playerConfig}
							videoConfig={this.state.videoData}
							forcePsuedoFullScreen={false}
							isPseudoFullscreen={false}
							onReady={amp => this.onReady(amp)}
							onUserAction={data => this.onUserAction(data)}
							onFullscreenChange={data => this.onFullscreenChange(data)}
							onStart={() => this.onStart()}
							onPlay={() => this.onPlay()}
							onPause={() => this.onPause()}
							onComplete={() => this.onComplete()}
							onTimeUpdate={data => this.onTimeUpdate(data)}
							onLoadedMetadata={data => this.onLoadedMetadata(data)}
							colors={colors}
						/>
					) : null}
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);

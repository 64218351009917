/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Nav Components for Live Scores and Live Commentary
 * navigate to a page when clicked, selected is to highlight the text and disable href
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveNav'],
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	mount: () => dispatch(deps.actions.LiveNav.mount()),
});

class LiveNav extends Component {
	constructor(props) {
		logger.log('[LiveNav] constructor');
		super(props);
		this.state = {
			...props,
		};
	}

	componentDidMount() {
		this.state.mount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[LiveNav] componentDidUpdate state:%o',this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		// logger.log('[LiveNav] componentWillReceiveProps state:%o',this.state);
	}

	render() {
		let attributes = this.state.attributes;

		if (this.state.LiveNavConfig) {
			if (this.state.LiveNavConfig.commentaryShow) {
				return (
					<div data-dna="live-tabs" className="live-tabs">
						{attributes.map((item, index) => {
							return item.selected ? (
								<a key={item.name} className="selected">
									{item.name}
								</a>
							) : (
								<WimLink key={item.name} to={item.url}>
									{item.name}
								</WimLink>
							);
						})}
					</div>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveNav);

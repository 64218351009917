/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import WimRedirect from 'appdir/components/general/WimRedirect';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: OrderOfPlay
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['OrderOfPlay'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: param => dispatch(deps.actions.OrderOfPlay.mount(param)),
	unmount: () => dispatch(deps.actions.OrderOfPlay.unmount()),
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	allMatchesPlayed: data => dispatch(deps.actions.OrderOfPlay.allMatchesPlayed(data)),
});

class OrderOfPlay extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };

		this.init = true;
		this.onLinkClick = this.onLinkClick.bind(this);
	}

	componentDidMount() {
		this.state.mount();
	}
	componentWillUnmount() {
		//this.init = true;
		if (this.state.data.refresh) {
			clearTimeout(this.orderOfPlay_timeout);
		}
		this.state.unmount();
	}
	componentDidUpdate(prevProps, prevState) {
		//logger.log('[OrderOfPlay] componentDidUpdate state:%o',this.state);

		if (this.state.status === 'loaded' && this.init) {
			this.init = false;

			let day = this.state.data.dayId;
			let dataPath = this.state.dataPath.replace('<day>', day);
			let tempAllMatchesPlayed = true;

			deps.services.OrderOfPlay.fetch(dataPath)
				.then(result => {
					loop1: for (var i = 0; i < result.courts.length; i++) {
						let court = result.courts[i];
						loop2: for (var j = 0; j < court.matches.length; j++) {
							logger.log('[OrderOfPlay] status:%o', court.matches[j].status);
							if (!court.matches[j].status && court.matches[j].eventCode) {
								tempAllMatchesPlayed = false;
								break loop1;
							}
						}
					}

					logger.log('[OrderOfPlay] tempAllMatchesPlayed:%o', tempAllMatchesPlayed);
					this.state.allMatchesPlayed(tempAllMatchesPlayed);

					this.setState({
						orderOfPlayData: result,
						error: false,
					});

					if (this.state.data.refresh) {
						if (this.orderOfPlay_timeout) {
							clearTimeout(this.orderOfPlay_timeout);
						}

						this.orderOfPlay_timeout = setTimeout(() => {
							this.init = true;
						}, 30000);
					}
				})
				.catch(error => {
					logger.log('[OrderOfPlay] componentDidUpdate error:%o', error);
					this.setState({
						error: true,
						errorType: error,
					});
				});
		}
	}

	onLinkClick(e, path) {
		logger.log('[OrderOfPlay] e:%o', e.target);
		//logger.log('[MatchBox] target:', e.getCurrentTarget());
		e.preventDefault();

		if (this.state.data.isSidePanel) {
			this.state.navigate({ path: path });
		} else {
			this.setState({
				nav: path,
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}
	renderOrderOfPlay(d, i) {
		let nextMatch = this.state.data.nextMatch;
		let found = false;

		//logger.log('[OrderOfPlay] renderOrderOfPlay state:%o d:%o', this.state, d);

		return (
			<span key={i}>
				<div className="order-of-play-heading">
					<div className="match-date">{this.state.orderOfPlayData.displayDate}</div>
					<div className="match-revised">{this.state.orderOfPlayData.comments}</div>
				</div>
				<div className="court-title-divider">
					<div className="match-location">{d.courtName}</div>
					<div className="match-time">
						<div className="match-time-modifier">
							Not Before <span className="time-string">{d.time}</span>
						</div>
					</div>
				</div>
				<hr />

				{d.matches.map((d1, j) => {
					//check fot TBA match with no data
					if (!d1.status && !d1.eventCode) {
						return null;
					}


					let upcoming = !d1.status || d1.statusCode == 'K';
					if (
						!this.state.data.nextMatch ||
						(this.state.data.nextMatch && upcoming && !found && d1.eventCode)
					) {
						found = true;
						logger.log('[OrderOfPlay] renderOrderOfPlay d1:%o', d1);
						let team1PlayerImageA = this.state.playerProfileImagePath.replace(
							'<playerid>',
							d1.team1[0].idA
						);
						let team1FlagImageA = this.state.flagImagePath.replace('<code>', d1.team1[0].nationA);
						let team1PlayerImageB = d1.team1[0].idB
							? this.state.playerProfileImagePath.replace('<playerid>', d1.team1[0].idB)
							: null;
						let team1FlagImageB = d1.team1[0].idB
							? this.state.flagImagePath.replace('<code>', d1.team1[0].nationB)
							: null;

						let team2PlayerImageA = this.state.playerProfileImagePath.replace(
							'<playerid>',
							d1.team2[0].idA
						);
						let team2FlagImageA = this.state.flagImagePath.replace('<code>', d1.team2[0].nationA);
						let team2PlayerImageB = d1.team2[0].idB
							? this.state.playerProfileImagePath.replace('<playerid>', d1.team2[0].idB)
							: null;
						let team2FlagImageB = d1.team2[0].idB
							? this.state.flagImagePath.replace('<code>', d1.team2[0].nationB)
							: null;
						return (
							<div key={j} className="player-info">
								<div className={d1.team1[0].idB ? `player-one doubles` : `player-one`}>
									<span>
										<img
											className="player-image"
											alt="player image"
											src={team1PlayerImageA}
											onClick={e => {
												this.onLinkClick(e, `/en_GB/players/overview/${d1.team1[0].idA}.html`);
											}}
										/>
										{d1.team1[0].idB ? <br /> : null}
										{d1.team1[0].idB ? (
											<img
												className="player-image"
												alt="player image"
												src={team1PlayerImageB}
												onClick={e => {
													this.onLinkClick(
														e,
														`/en_GB/players/overview/${d1.team1[0].idB}.html`
													);
												}}
											/>
										) : null}
									</span>
									<div className="player-one-info">
										<div
											className="player-a"
											onClick={e => {
												this.onLinkClick(e, `/en_GB/players/overview/${d1.team1[0].idA}.html`);
											}}>
											<div className="player-flag">
												<img alt={d1.team1[0].nationA} src={team1FlagImageA} />
											</div>
											<div className="player-name">{d1.team1[0].displayNameA}</div>
											<div className="seed">{d1.team1[0].seed}</div>
										</div>
										{d1.team1[0].idB ? (
											<div
												className="player-b"
												onClick={e => {
													this.onLinkClick(
														e,
														`/en_GB/players/overview/${d1.team1[0].idB}.html`
													);
												}}>
												<div className="player-flag">
													<img alt={d1.team1[0].nationB} src={team1FlagImageB} />
												</div>
												<div className="player-name">{d1.team1[0].displayNameB}</div>
												<div className="seed">{d1.team1[0].seed}</div>
											</div>
										) : null}
									</div>
								</div>
								<div className="match-type-status">
									<div className="match-type">{d1.eventName}</div>
									<div className="match-status">{d1.roundName}</div>
								</div>
								<div className={d1.team2[0].idB ? `player-two doubles` : `player-two`}>
									<span>
										<img
											className="player-image"
											alt="player image"
											src={team2PlayerImageA}
											onClick={e => {
												this.onLinkClick(e, `/en_GB/players/overview/${d1.team2[0].idA}.html`);
											}}
										/>
										{d1.team2[0].idB ? <br /> : null}
										{d1.team2[0].idB ? (
											<img
												className="player-image"
												alt="player image"
												src={team2PlayerImageB}
												onClick={e => {
													this.onLinkClick(
														e,
														`/en_GB/players/overview/${d1.team2[0].idB}.html`
													);
												}}
											/>
										) : null}
									</span>
									<div className="player-two-info">
										<div
											className="player-a"
											onClick={e => {
												this.onLinkClick(e, `/en_GB/players/overview/${d1.team2[0].idA}.html`);
											}}>
											<div className="player-flag">
												<img alt={d1.team2[0].nationA} src={team2FlagImageA} />
											</div>
											<div className="player-name">{d1.team2[0].displayNameA}</div>
											<div className="seed">{d1.team2[0].seed}</div>
										</div>
										{d1.team2[0].idB ? (
											<div
												className="player-b"
												onClick={e => {
													this.onLinkClick(
														e,
														`/en_GB/players/overview/${d1.team2[0].idB}.html`
													);
												}}>
												<div className="player-flag">
													<img alt={d1.team2[0].nationB} src={team2FlagImageB} />
												</div>
												<div className="player-name">{d1.team2[0].displayNameB}</div>
												<div className="seed">{d1.team2[0].seed}</div>
											</div>
										) : null}
									</div>
								</div>
							</div>
						);
					} else {
						return null;
					}
				})}
			</span>
		);
	}
	render() {
		logger.log('[OrderOfPlay] render - state:%o', this.state);
		let { allCourts = false } = this.state.data;
		let courtId = this.state.data.courtId ? this.state.data.courtId : 'A';
		let { isSidepanel = false } = this.state.data;
		//logger.log('[OrderOfPlay] render - allCourts:%o', allCourts);

		if (this.state.nav) {
			return <WimRedirect to={this.state.nav} />;
		} else if (this.state.orderOfPlayData && !this.state.error) {
			return (
				<div className={`order-of-play ${this.state.style || 'two-col'}`}>
					<div className="match-information">
						{courtId && !allCourts
							? this.state.orderOfPlayData.courts
									.filter(d => d.courtId == courtId)
									.map((d, i) => {
										return this.renderOrderOfPlay(d, i);
									})
							: null}
						{!courtId || allCourts
							? this.state.orderOfPlayData.courts.map((d, i) => {
									// nextMatch = do we only show the next upcoming match?
									if (this.state.data.nextMatch) {
										// hasMatches = does this court have any matches that are not started?
										let hasMatches =
											d.matches.filter(
												match => match.statusCode == 'B' || match.statusCode == 'K'
											).length > 0;
										if (hasMatches) {
											return this.renderOrderOfPlay(d, i);
										} else {
											return null;
										}
									} else {
										return this.renderOrderOfPlay(d, i);
									}
							  })
							: null}
					</div>
				</div>
			);
		} else if (this.state.error) {
			<div>Order of play data is not available at this time.</div>;
		} else {
			return <div ref="oop" />;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderOfPlay);

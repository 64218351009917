import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';

const schema = require('appdir/components/data/Schema/index.js');
const v = new Validator();

/**
 *
 * @param {String} path
 */
const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

export default {
	fetch,
};

import HomePage from './index';
import deps from 'dependencies';
// import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/', '/index.html', '/en_GB/index.html', '/en_gb/index.html'],
		exact: true,
		page: 'home',
		component: HomePage,
		load: params => deps.actions.HomePage.mount(params),
	},
	{
		path: '/preview/index.html',
		exact: true,
		page: 'home',
		component: HomePage,
		load: params => deps.actions.HomePage.mount(params),
	},
];

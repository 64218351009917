import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { relatedContentVideo } from 'appdir/components/data/Schema';

const v = new Validator();

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

const fetchRelated = path => {
	let hdr = restHeaders();

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				//logger.log('[VideoPlayer] fetchRelated - path: %o', path);
				let validateResult = v.validate(response.data, relatedContentVideo);
				if (validateResult.valid) {
					return response.data;
				} else {
					// throw error and go to catch block
					throw new Error('failed schema validation');
				}
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			//logger.warn('[VideoPlayer] fetchRelated - %o', error.message);
			throw new Error(error.message);
		});
};

const fetchNextUp = path => {
	let hdr = restHeaders();

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				logger.log('[VideoPlayer] fetchNextUp - path:%o', path);
				return response.data;
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			logger.warn('[VideoPlayer] fetchNextUp - %o', error.message);
			throw new Error(error.message);
		});
};

const fetchVidDetails = id => {
	const path = `https://edge.api.brightcove.com/playback/v1/accounts/3506358525001/videos/${id}`;
	const policy_key =
		'BCpkADawqM1g4Ba9GSbjei3Ipu9ci-CN8bwnBX86Xo121yy1Bb5qJ0dUvZb5d4pvMmzpN80-Fv5aopcYYQIP6e3eCaBWeZi6RZpEUpOE-80i7-3wkR60NTG0McfE4GWisQTKrpET3X0npd4O';
	let hdr = { Accept: `application/json;pk=${policy_key}` };
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			logger.warn('[VideoPlayer] fetchVidDetails - %o', error.message);
			throw new Error(error.message);
		});
};

export default {
	fetch,
	fetchRelated,
	fetchNextUp,
	fetchVidDetails,
};

import deps from 'dependencies';

export default {
	mount: day => (dispatch, getState, store) => {
		logger.log('[OrderOfPlay] mount day:%o', day);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				dataPath: Config.scoringData.schedule,
				playerImagePath: Config.otherData.playerImagePath,
				flagImagePath: Config.otherData.flagImagePathSmall,
				playerProfileImagePath: Config.otherData.playerProfileImagePath,
				allMatchesPlayed: 'init',
			};

			dispatch({ type: deps.actionTypes.ORDEROFPLAY_MOUNT, data: data });
		});
	},
	allMatchesPlayed: data => dispatch => {
		dispatch({ type: deps.actionTypes.ORDEROFPLAY_ALLMATCHES_PLAYED, data: { allMatchesPlayed: data } });
	},
	unmount: () => dispatch => {
		logger.log('[OrderOfPlay] unmount');
		dispatch({ type: deps.actionTypes.ORDEROFPLAY_UNMOUNT, data: null });
	},
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MediaRequestContext } from './context';
import { Formik, Form } from 'formik';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import WimLink from 'appdir/components/general/WimLink';
import { countryLookup } from 'appdir/components/general/Util';
import deps from 'dependencies';
import op from 'object-path';
import * as yup from 'yup';
import Button from 'appdir/components/common-ui/Button';
import {
	FormInput,
	FormEmail,
	FormSelect,
	FormSelectPlus,
	FormTextarea,
	FormCheckBox,
} from 'appdir/components/common-ui/FormFields';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

const countries = countryLookup;

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => ({});

class RequestForm extends Component {
	constructor(props) {
		super(props);
		//this.state = {...props};

		this.initialValues = {};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(values) {
		if (this.context.submit) {
			this.context.submit(values);
		}
	}

	getForm(formProps) {
		//logger.log('[MediaRequest - RequestForm] getForm - errors:%o touched:%o', formProps.errors, formProps.touched);

		return (
			<Form className="media-form-content" onSubmit={formProps.handleSubmit}>
				<div className={`form-component visible`}>
					<ErrorBoundary message="Error in Media Form">
						<span className="one-col margin-col left">
							<FormInput
								label="First Name"
								name="firstName"
								type="text"
								placeholder=""
								showerror="true"
								required
							/>
						</span>
						<span className="one-col">
							<FormInput
								label="Last Name"
								name="lastName"
								type="text"
								placeholder=""
								showerror="true"
								required
							/>
						</span>
						<span className="one-col margin-col left">
							<FormEmail
								label="Email"
								name="email"
								type="email"
								placeholder=""
								showerror="true"
								required
							/>
						</span>
						<span className="one-col">
							<FormInput label="Phone Number" name="phone" type="text" placeholder="" showerror="false" />
						</span>
						<span className="one-col margin-col left">
							<FormInput
								label="Organisation"
								name="organisation"
								type="text"
								placeholder=""
								showerror="true"
								required
							/>
						</span>
						<span className="one-col">
							<FormInput label="Role" name="role" type="text" placeholder="" showerror="true" required />
						</span>
						<span className="one-col margin-col left">
							<FormInput
								label="Organisation Accreditation Contact"
								name="outletContact"
								type="text"
								placeholder=""
								showerror="true"
								required
							/>
						</span>
						<span className="one-col">
							<FormInput
								label="AELTC Contact"
								name="aeltcContact"
								type="text"
								placeholder=""
								showerror="true"
								required
							/>
						</span>
						<span className="two-col margin-col left">
							<FormTextarea
								label="Comments"
								name="comments"
								type="textarea"
								placeholder=""
								showerror="false"
							/>
						</span>

						<div className="two-col margin-col clear-two clear-four fullwidth">
							<FormCheckBox name="acceptDeclaration" id="acceptDeclaration">
								I agree to the processing of the personal data I am submitting via email in order to
								validate my request for access to the Wimbledon Media Website
							</FormCheckBox>
						</div>
						<div className="two-col margin">
							<Button className={`btn solid`} onClick={formProps.submitForm}>
								SUBMIT
							</Button>
						</div>
					</ErrorBoundary>
				</div>
			</Form>
		);
	}

	render() {
		logger.log('[MediaRequest - RequestForm] render - context:%o', this.context);
		this.initialValues = {
			firstName: op.get(this.context, 'user.profile.firstName', ''),
			lastName: op.get(this.context, 'user.profile.lastName', ''),
			email: op.get(this.context, 'user.profile.email', ''),
			phone: '',
			outletContact: '',
			role: '',
			organisation: '',
			aeltcContact: '',
			acceptDeclaration: false,
		};

		logger.log('[MediaRequest - RequestForm] render - initial:%o', this.initialValues);
		//logger.log('[MediaRequest - RequestForm] render - state:%o status:%o', this.state, status);

		let MediaFormSchema = yup.object().shape({
			firstName: yup.string().required('Please supply a First Name.'),
			lastName: yup.string().required('Please supply a Last Name.'),
			email: yup.string().required('Please supply an Email.'),
			// phone: yup.string().required('Please supply a Phone Number.'),
			outletContact: yup.string().required('Please supply an Organisation Accreditation Contact.'),
			aeltcContact: yup.string().required('Please supply an AELTC Contact.'),
			organisation: yup.string().required('Please supply an organisation.'),
			role: yup.string().required('Please supply a role'),
			acceptDeclaration: yup
				.boolean()
				.required('Please read and accept.')
				.oneOf([true], 'Please read and accept.'),
		});

		return (
			<Fragment>
				{
					<Fragment>
						<Formik
							initialValues={this.initialValues}
							validationSchema={MediaFormSchema}
							onSubmit={values => {
								this.handleSubmit(values);
							}}
							enableReinitialize={true}>
							{formProps => {
								return this.getForm({ ...formProps, context: this.context });
							}}
						</Formik>
					</Fragment>
				}
			</Fragment>
		);
	}
}

RequestForm.contextType = MediaRequestContext;

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);

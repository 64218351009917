/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import WimLink from 'appdir/components/general/WimLink';

class MobileWebFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}
	componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}
	render() {
		let { mobileMenu } = this.state;

		let liveIndicators = this.state.liveIndicators || {
			scores: false,
			video: false,
			radio: false,
		};

		return (
			<div id="web-footer" className="mobile-web-footer">
				{mobileMenu?.buttons?.map((value, index) => {
					if (index < 5) {
						return (
							<div className="web-footer-item" key={`web-footer-item${value.text}${index}`}>
								{/* on/live state use prim, off state use alt url if exists (otherwise use prim) */}
								<WimLink to={liveIndicators[value.text?.toLowerCase()] ? value.urls.prim : (value.urls.alt || value.urls.prim)} title={value.text}>
									<i className={value.icon} />
									<div>
										{liveIndicators[value.text?.toLowerCase()] ? (
											<span className="web-footer-item-live-indicator" />
										) : null}
										{value.text}
									</div>
								</WimLink>
							</div>
						);
					}
				})}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		liveIndicators: state['ActiveData']['liveIndicators'],
		mode: state['Config']['mode'],
		mobileMenu: state['Config']['mobileMenu'],
		...props,
	};
};

export default connect(mapStateToProps)(MobileWebFooter);

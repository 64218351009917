import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';
import { restHeaders } from 'appdir/components/general/Util';
import { getRole } from 'appdir/components/general/Util/Role';

/**
 * calls orch to remove role
 * @param {} currentUser
 * @param {} roleId
 * @param {*} dispatch
 * @param {*} store
 */
const removeShadowRole = (role, dispatch, store) => {
	logger.log('[MyWimbledon] services.removeRole - role:%o', role);

	if (role && role.isShadowingAccess) {
		let { Config } = store.getState();
		let axiosConfig = {};

		return new Promise((resolve, reject) => {
			deps.services.Gigya.getJWT(dispatch, store)
				.then(token => {
					if (role.isShadowingAccess == 'support') {
						axiosConfig = {
							method: 'post',
							url: Config?.myWimbledon?.api?.removeShadowRole,
							data: {
								jwtToken: token.jwt.id_token,
								shadowRole: { roleId: role.roleId },
							},
						};
					} else if (role.isShadowingAccess == 'delegate') {
						axiosConfig = {
							method: 'post',
							url: Config?.pde?.api?.delegateRemove,
							headers: {
								'Content-type': 'application/json',
								Authorization: 'Bearer ' + token.jwt.id_token,
							},
						};
					}

					axios(axiosConfig)
						//.get('/en_GB/json/gen/tix/transfertix_jeff.json', { headers })
						.then(response => {
							logger.log('[MyWimbledon] services.removeRole - response:%o', response);
							resolve(response);
						})
						.catch(error => {
							logger.error('[MyWimbledon] services.removeRole - error:%o', error);
							reject(error);
						});
				})
				.catch(error => {
					reject({
						error: 'error getting token',
						code: 2010,
					});
				});
		});
	} else {
		return new Promise((resolve, reject) => {
			reject({
				error: 'not a shadow role',
			});
		});
	}
};

export default {
	removeShadowRole,
};

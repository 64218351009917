/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import { values } from 'appdir/main';
import { OIDCContext } from './context';

import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import MeasurementUtils from 'appdir/lib/analytics';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: Consent page
 * -----------------------------------------------------------------------------
 */

class Consent extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.parsedQs;

		this.handleClick = this.handleClick.bind(this);

		this.autoConsent = true;
	}

	componentDidMount() {
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		let data = {};

		data.pageTitle = 'Consent';
		data.redirect = op.get(this.parsedQs, 'redirect', '');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, data);

		logger.log('[OIDC_Consent] componentDidMount - qs:%o', this.parsedQs);

		//call consent automatically
		if (this.autoConsent) {
			this.handleClick();
		}
	}

	componentWillUnmount() {
		//this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[OIDC_Consent] componentDidUpdate - prev:%o state:%o', prevState, this.state);
		logger.log('[OIDC_Consent] componentDidUpdate - context:%o', this.context);
	}

	callConsent() {
		let params = {};
		params.scope = this.parsedQs.scope ? this.parsedQs.scope : '';
		params.clientID = this.parsedQs.clientId ? this.parsedQs.clientId : '';
		params.context = this.parsedQs.context ? this.parsedQs.context : '';
		params.UID = this.parsedQs.UID ? this.parsedQs.UID : '';
		params.UIDSignature = this.parsedQs.UIDSignature ? this.parsedQs.UIDSignature : '';
		params.signatureTimestamp = this.parsedQs.signatureTimestamp ? this.parsedQs.signatureTimestamp : '';

		let scope = op.get(this, 'parsedQs.scope', '').replace(/[+]/g, ' ');
		let obj = {
			scope: scope,
			clientID: this.parsedQs.clientID,
			context: this.parsedQs.context,
			UID: this.parsedQs.UID,
			consent: true,
		};
		//let consentObj = encodeURI(JSON.stringify(obj));

		logger.log('[OIDC_Consent] callConsent - obj:%o', obj);

		let data = {
			method: 'post',
			url: this.context.api.consent,
			data: {
				...obj,
			},
			headers: { 'Content-type': 'application/json' },
		};

		let request = axios(data)
			.then(res => {
				if (res.status == 200 && res.data && res.data.signature) {
					logger.log('[OIDC_Consent] success consenting: %o', res);

					let qs =
						'?mode=afterConsent' +
						'&consent=' +
						encodeURIComponent(JSON.stringify(obj).replace(/(\r\n|\n|\r)/gm, '')) +
						'&sig=' +
						res.data.signature +
						'&userKey=' +
						encodeURIComponent(res.data.uk);

					logger.log('[OIDC_Consent] success consenting - qs:%o', qs);
					window.location.href = this.context.proxy + qs;
				} else {
				}
			})
			.catch(error => {
				logger.error('[OIDC_Consent] error consenting: %o', error);
				this.setState({
					redirect: '/en_GB/mywimbledon/error?mode=error&message=consent%2Bfailed',
				});
			});
	}

	handleClick() {
		logger.log('[OIDC_Consent] handleClick - qs:%o context:%o', this.parsedQs);

		let scope = this.parsedQs.scope ? this.parsedQs.scope : '';
		let clientID = this.parsedQs.clientId ? this.parsedQs.clientId : '';
		let context = this.parsedQs.context ? this.parsedQs.context : '';

		this.callConsent();
	}

	render() {
		logger.log('[OIDC_Consent] render - state:%o context:%o', this.state, this.context);

		return (
			<Fragment>
				{this.state.redirect ? (
					<WimRedirect to={`${this.state.redirect}`} />
				) : this.autoConsent ? (
					<div>Processing...</div>
				) : (
					<Fragment>
						<div>Consent page</div>
						<button onClick={this.handleClick}>Consent</button>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

Consent.contextType = OIDCContext;
export default Consent;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: PrevNextBack
 * receives below attributes
 *  - "prevNextData" -- optional. e.g.) News articles have this data to navigate to prev and next
 *  - "category" ------ used to set back link e.g.) news article goes back to news landing vs history go back
 *  - "history"  ------ used to set back link for history go back
 * -----------------------------------------------------------------------------
 */
const PrevNextBack = props => {
	let attributes = props.attributes;
	let category = attributes.category ? attributes.category : '';
	let newsLandingUrl = '/en_GB/news/index.html';
	let previousUrl = null;
	let previousTitle = null;
	let nextUrl = null;
	let nextTitle = null;

	if (attributes.prevNextData && attributes.prevNextData.previous) {
		if (attributes.prevNextData.previous.length > 0) {
			previousUrl = attributes.prevNextData.previous[0].url;
			previousTitle = attributes.prevNextData.previous[0].shortTitle;
		}

		if (attributes.prevNextData.next.length > 0) {
			nextUrl = attributes.prevNextData.next[0].url;
			nextTitle = attributes.prevNextData.next[0].shortTitle;
		}
	}

	let backToUrl = category === 'articles' ? newsLandingUrl : '';
	let backToText = category === 'articles' ? 'Back to news' : 'Back';

	let prevHideClass = previousUrl === null || previousUrl === '' ? 'hide' : '';
	let nextHideClass = nextUrl === null || nextUrl === '' ? 'hide' : '';

	const keyPressed = event => {
		if (event.key === 'Enter') {
			attributes.history.goBack();
		}
	};

	return (
		<div>
			<noindex id="prevNextBack">
				<div className="column-layout">
					<div data-dna="next-prev-back-bar" className="next-prev-back-bar">
						<div className={`previous ${prevHideClass}`}>
							<Link to={`${previousUrl}`}>
								<div className="left-arrow">&#x25B8;</div>
								<div className="previous-header">Previous Story</div>
								<div className="prev-page-name">{previousTitle}</div>
							</Link>
						</div>

						<div className="back">
							{backToUrl === '' ? (
								<span onClick={attributes.history.goBack} onKeyPress={keyPressed} tabIndex={0}>
									{backToText}
								</span>
							) : (
								<Link to={`${backToUrl}`}>{backToText}</Link>
							)}
						</div>

						<div className={`next ${nextHideClass}`}>
							<Link to={`${nextUrl}`}>
								<div className="next-header">Next Story</div>
								<div className="right-arrow">&#x25B8;</div>
								<div className="next-page-name">{nextTitle}</div>
							</Link>
						</div>
					</div>
				</div>
			</noindex>
		</div>
	);
};

export default PrevNextBack;

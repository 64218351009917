import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';

import WimLink from 'appdir/components/general/WimLink';

const mapStateToProps = (state, props) => {
	return {
		liveIndicators: state['ActiveData']['liveIndicators'],
		mode: state['Config']['mode'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class LivePanel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		// logger.log('[LivePanel] state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[LivePanel] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidMount() {
		//logger.log('[LivePanel] componentDidMount - state:%o props:%o', this.state, this.props);
	}

	render() {
		//logger.log('[LivePanel] render - state:%o', this.state);

		if (op.get(this.state, 'mode.tournament', false)) {
			let panelStyle = 'none';
			if (this.state.style) {
				panelStyle = this.state.style;
			}

			let { scores, video, radio } = this.state.liveIndicators || {
				scores: false,
				video: false,
				radio: false,
			};

			return (
				<div className={`live-status ${panelStyle}`}>
					{scores ? (
						<WimLink to="/en_GB/scores/index.html" title="scores">
							<div className="symbol scores live">
								<div className="circle">
									<i className="wim-icon-ballSolid" />
									<i className="wim-icon-scores" />
								</div>
								<div className="label">SCORES</div>
							</div>
						</WimLink>
					) : (
						<WimLink to="/en_GB/scores/results/index.html" title="results">
							<div className="symbol scores">
								<div className="circle">
									<i className="wim-icon-scores" />
								</div>
								<div className="label">SCORES</div>
							</div>
						</WimLink>
					)}

					<div className="line" />
					{video ? (
						<WimLink to="/en_GB/interactive/index.html" title="interactive video">
							<div className="symbol video live">
								<div className="circle">
									<i className="wim-icon-playArrow-blank" />
								</div>
								<div className="label">VIDEO</div>
							</div>
						</WimLink>
					) : (
						<WimLink to="/en_GB/video/index.html" title="video">
							<div className="symbol video">
								<div className="circle">
									<i className="wim-icon-playArrow-blank" />
								</div>
								<div className="label">VIDEO</div>
							</div>
						</WimLink>
					)}
					<div className="line" />
					{radio ? (
						<WimLink
							to="/webview/en_GB/radio/radio.html"
							style="radioStatus"
							title="radio"
							role="link"
							popup={true}
							measure={'RadioPopup'}
							options={'width=600, height=198, location=0, toolbar=0'}>
							<div className="symbol radio live">
								<div className="circle">
									<i className="wim-icon-microphone" />
								</div>
								<div className="label">RADIO</div>
							</div>
						</WimLink>
					) : (
						<div className="symbol radio">
							<div className="circle">
								<i className="wim-icon-microphone" />
							</div>
							<div className="label">RADIO</div>
						</div>
					)}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LivePanel);

import React, { Component } from 'react';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';
import PlayerImage from 'appdir/components/content/PlayerImage';
import { values } from 'appdir/main';
import { Link } from 'react-router-dom';

class FavourtingPlayersList extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	render() {
		const hiddenCountry = values?.countryHide;
		const filteredList = this.state.players.filter(player => {
			if (this.props.filterVal === 'gender') {
				return player.last_name.charAt(0).toUpperCase() === this.state.letter;
			} else if (this.props.filterVal === 'country') {
				return player.country_long === this.state.country;
			}
		});
		if (this.props.pageNav) {
			return (
				<ul
					className="favourting-players-list-ul"
					role="list"
					style={{ display: filteredList?.length ? 'block' : 'none' }}>
					<div className="favourting-letter-header letter-header">
						{this.state.country ? (
							<img
								alt={this.state.country}
								src={`${this.props.flagImagePathSmall?.replace(
									'<code>',
									this.props.countryObj[this.state.country]
								)}`}
							/>
						) : null}
						{this.state.letter || this.state.country}
					</div>
					{filteredList?.length &&
						filteredList.map((player, index) => {
							return (
								<li
									key={`${player.last_name || player.lastName}${player.first_name ||
										player.firstName}${index}`}
									className="favourting-list-item">
									<Link to={`/en_GB/players/overview/${player.id}.html`}>
										<PlayerImage
											attributes={{
												'player-image': this.props.playerProfileImagePath.replace(
													'<playerid>',
													player.id
												),
												style: 'regular',
											}}
										/>
										<div
											className="country-flag"
											style={{
												width:
													this.props.flagImagePathSmall &&
													!hiddenCountry.includes(player.country)
														? 'auto'
														: '45px',
												padding:
													this.props.flagImagePathSmall &&
													!hiddenCountry.includes(player.country)
														? 'auto'
														: '0px',
											}}>
											<img
												loading="lazy"
												alt={player?.country}
												src={`${this.props.flagImagePathSmall?.replace(
													'<code>',
													player?.country
												)}`}
											/>
										</div>
										<span>
											{player.last_name || player.lastName},{' '}
											{player.first_name || player.firstName}
											{this.props.activeTab === 'Seeds' && player.seed
												? `(${player?.seedValue})`
												: this.props.activeTab === 'Ranking'
												? `(${player.singles_rank})`
												: ''}
										</span>
									</Link>
									<FavouriteStar
										id={player.id}
										type="players"
										unFavColor="black"
										favColor="green"
										alwaysShow={true}
									/>
								</li>
							);
						})}
				</ul>
			);
		} else {
			return (
				<ul
					className="favourting-players-list-ul"
					role="list"
					style={{ display: filteredList?.length ? 'block' : 'none' }}>
					<div className="favourting-letter-header letter-header">
						{this.state.country ? (
							<img
								alt={this.state.country}
								src={`${this.props.flagImagePathSmall?.replace(
									'<code>',
									this.props.countryObj[this.state.country]
								)}`}
							/>
						) : null}
						{this.state.letter || this.state.country}
					</div>
					{filteredList?.length &&
						filteredList.map(player => {
							return (
								<li key={player.id} className="favourting-list-item">
									<PlayerImage
										attributes={{
											'player-image': this.props.playerProfileImagePath.replace(
												'<playerid>',
												player.id
											),
											style: 'regular',
										}}
									/>
									<div
										className="country-flag"
										style={{
											width:
												this.props.flagImagePathSmall && !hiddenCountry.includes(player.country)
													? 'auto'
													: '45px',
											padding:
												this.props.flagImagePathSmall && !hiddenCountry.includes(player.country)
													? 'auto'
													: '0px',
										}}>
										<img
											loading="lazy"
											alt={player?.country}
											src={`${this.props.flagImagePathSmall?.replace('<code>', player?.country)}`}
										/>
									</div>
									<span>
										{player.last_name || player.lastName}, {player.first_name || player.firstName}
										{this.props.activeTab === 'Seeds' && player.seed
											? `(${player?.seedValue})`
											: this.props.activeTab === 'Ranking'
											? `(${player.singles_rank})`
											: ''}
									</span>
									<FavouriteStar
										id={player.id}
										type="players"
										unFavColor="black"
										favColor="green"
										alwaysShow={true}
									/>
								</li>
							);
						})}
				</ul>
			);
		}
	}
}

export default FavourtingPlayersList;

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[SchedulePage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[SchedulePage] action.mount - data: %o', data);

			let day = params.tournDay ? `Day${params.tournDay}` : 'Current';
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'Schedule',
				day: day,
			});

			let data = {
				pdfPath: Config.scoringData.schedulePdf,
				tournDay: params.tournDay,
				event: params.event ? params.event : 'all',
				filters: {
					day: '',
					open: '',
				},
			};
			logger.log('[SchedulePage] action.mount - params:%o data:%o', params, data);
			//if (params.tournDay) {
			dispatch({ type: deps.actionTypes.SCHEDULEPAGE_LOAD, data: data });
			//}
		});
	},

	setDay: data => (dispatch, getState, store) => {
		logger.log('[SchedulePage] action.setDay - day:%o', data);
		dispatch({ type: deps.actionTypes.SCHEDULEPAGE_SETDAY, data: data });
	},
};

import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		/**
		 * controller mount
		 */
		case deps.actionTypes.CONTROLLER_MOUNT:
			newState = {
				...state,
				...action.data,
				scoring: {
					...state.scoring,
				},
			};
			logger.log('[Controller] deps.actionTypes.CONTROLLER_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.CONTROLLER_LOGOUT:
			newState = {
				...state,
				favourites: {
					...state['favourites'],
					players: [],
					articles: [],
					videos: [],
					matches: [],
					show: false,
				},
			};
			return newState;

		/**
		 * updating of controller state for preferences
		 *  - when user changes preferences
		 */
		case deps.actionTypes.CONTROLLER_UPDATE_PREF:
			newState = {
				...state,
				userPreferences: {
					...state.userPreferences,
					...action.data,
				},
			};
			logger.log('[Controller] deps.actionTypes.CONTROLLER_UPDATE_PREF - newState:%o', newState);
			return newState;

		/**
		 * generic updating of controller state
		 *  - when user changes sidepanel state
		 *  - ?
		 */
		case deps.actionTypes.CONTROLLER_UPDATE:
			//logger.log('[Controller] deps.actionTypes.CONTROLLER_UPDATE - state, action:%o', state, action);
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[Controller] deps.actionTypes.CONTROLLER_UPDATE - newState:%o', newState);
			return newState;

		/**
		 * what for?
		 */
		case deps.actionTypes.CONTROLLER_SETPLATFORM:
			newState = {
				...state,
				platform: action.data,
				updated: moment().toISOString(),
			};
			return newState;

		/**
		 * update the list of viewed content
		 */
		case deps.actionTypes.CONTROLLER_UPDATEVIEWEDCONTENT:
			newState = {
				...state,
				content: action.data,
			};
			logger.log('[Controller]  deps.actionTypes.CONTROLLER_UPDATEVIEWEDCONTENT - newState:%o', newState);
			return newState;

		/**
		 * what for?
		 */
		case deps.actionTypes.CONTROLLER_UPDATEDATE:
			newState = {
				...state,
				date: action.data,
			};
			return newState;

		case deps.actionTypes.CONTROLLER_GIGYA_UPDATE:
			//logger.log('[Controller] reducer.GIGYA_LOGIN data:%o', state);
			let userPrefs = state.userPreferences;
			userPrefs = {
				...userPrefs,
				// ...action.data.data.userPreferences
				...action.data.data.userPreferences,
			};

			newState = {
				...state,
				userPreferences: userPrefs,
			};
			logger.log('[Controller] reducer.CONTROLLER_GIGYA_UPDATE newState:%o', newState);
			return newState;

		case deps.actionTypes.CONTROLLER_CLEAR_PLAYERS:
			let favoriteData = state['favourites'];
			favoriteData['players'] = [];
			newState = {
				...state,
				favourites: favoriteData,
			};
			return newState;

		case deps.actionTypes.CONTROLLER_CLEAR_CONTENT:
			let favorites = state['favourites'];
			favorites['articles'] = [];
			favorites['videos'] = [];
			newState = {
				...state,
				favourites: favorites,
			};
			return newState;

		case deps.actionTypes.CONTROLLER_FORCEUPDATE:
			newState = {
				...state,
				forceUpdate: action.data,
			};
			return newState;

		case deps.actionTypes.CONTROLLER_RESET:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[Controller] reducer.CONTROLLER_RESET newState:%o', newState);
			return newState;

		/**
		 * called when Gigya data updated, from successful call to setAccountInfo
		 */
		// case deps.actionTypes.GIGYA_UPDATE:
		// 	//logger.log('[Gigya] reducer.GIGYA_UPDATE state:%o action.data:%o', state, action.data);
		// 	let newUserInfo = {};
		// 	logger.log("[Controller GIGYA] current user %o", action.data);
		// 	if (action.data.currentUser) {
		// 		newUserInfo = {
		// 			userPreferences: action.data.currentUser.data.userPreferences,
		// 		};
		// 	}

		// 	newState = {
		// 		...state,
		// 		...newUserInfo,
		// 	};
		// 	//logger.log('[Controller] reducer.GIGYA_UPDATE newState:%o', newState);
		// 	return newState;
		// 	break;
		/**
		 * called when user wishes to see or hide favourited players for following pages:
		 * Draws, Schedule of Play, Live Scores
		 */

		case deps.actionTypes.CONTROLLER_TOGGLE_SHOW_FAVORITES:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		case deps.actionTypes.CONTROLLER_DEBUG:
			logger.log('[Controller] reducer.GIGYA_DEBUG action:%o', action);
			newState = {
				...state,
				debugView: action.view,
			};
			return newState;
			break;

		case deps.actionTypes.CONTROLLER_SET_SCORING_STATUS:
			newState = {
				...state,
				scoring: {
					...state.scoring,
					...action.data,
				},
			};
			logger.log('[Controller] actionTypes.CONTROLLER_SET_SCORING_STATUS - newState:%o', newState);
			return newState;

		// case deps.actionTypes.CONTROLLER_DRAWS_MOUNT_PROMO_VERIFY:
		// 	newState = {
		// 		...state,
		// 		drawsPromoVerify: action.value,
		// 	};
		// 	return newState;
		default:
			return state;
	}
};

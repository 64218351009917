import React, { Fragment } from 'react';
import PurpleStripe from '../../../../common-ui/Stripes/PurpleStripe';
import { ReservationForm, DiningContextProvider } from './elements';
import { useSelector } from 'react-redux';
import TabNavigation from 'appdir/components/cms/TabNavigation';
import WimLink from 'appdir/components/general/WimLink';

const DiningReservation = () => {
	const pathname = window.location.pathname;
	const booking = decodeURIComponent(pathname.substring(pathname.lastIndexOf('/') + 1));
	const bookingName = booking.replace(/%/g, '');
	const groupVenues = useSelector(state => state.Config.member.diningReservation.group.venues);
	const venue = groupVenues.filter(item => item?.key === bookingName)[0];

	return (
		<Fragment>
			<div className="four-col ">
				<TabNavigation data={{ key: 'memberBookings' }} />
			</div>
			<DiningContextProvider>
				<div className="reservation-element">
					<p className="purple reservation-title">
						MAKE A RESERVATION
						<p>{venue?.name}</p>
					</p>
					<PurpleStripe width={35} />
					<ReservationForm />
					{/* <Menus />
					<DressCodeInformation /> */}
					<div className="dining-reservation-link">
						<WimLink to={'/s/member/dining'} title="Back To All Restaurants">
							Back To All Restaurants
						</WimLink>
					</div>
				</div>
			</DiningContextProvider>
		</Fragment>
	);
};

export default DiningReservation;

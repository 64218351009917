import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.PDECONTENTDASH_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[PDEContentDash] reducer.mount - newState:%o', newState);
			return newState;

		case deps.actionTypes.PDECONTENTDASH_UNMOUNT:
			newState = {};
			return newState;

		default:
			return state;
	}
};

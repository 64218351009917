/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PlayerGeneralInfo from './PlayerGeneralInfo';
import PlayerProfessionalInfo from './PlayerProfessionalInfo';
import PlayerHistory from './PlayerHistory';
import PlayerMatches from './PlayerMatches';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Divider from 'appdir/components/common-ui/Divider';
const op = require('object-path');
import axios from 'axios';

import PlayerMedia from './PlayerMedia';
import { measureInWeb } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerPage2024
 * TODO: Change page name to PlayerPage on golive
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => ({
	...state['PlayerPage'],
	playerDetail: state['Tournament']?.data?.playerDetail,
	playerStatus: state['CommonData']?.playerStatus,
	playerStats: state['Tournament']?.data?.playerStats,
	windowSize: state['PageHeader'].windowSize,
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	getPlayerDetail: playerId => dispatch(deps.actions.Tournament.getPlayerDetail({ playerId: playerId })),
	getPlayerStatus: playerId => dispatch(deps.actions.Tournament.getPlayerStatus({ playerId: playerId })),
	getPlayerStats: ids => dispatch(deps.actions.Tournament.getPlayerStats({ playerIds: ids })),
});

class PlayerPage extends Component {
	constructor(props) {
		super(props);

		this.state = { ...this.props, playerMatchesObj: null, playerObj: null, playerContentObj: null };
		this.initialLoad = true;
		this.fetchData = true;
		this.playerName = '';
		this.getPlayerStatus = true;
		this.getPlayerStats = true;
	}

	componentDidMount() {
		logger.log('[PlayerPage] PlayerPage mounted');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.initialLoad || nextProps.match.params.player !== this.state.match.params.player) {
			this.initialLoad = false;
			this.fetchData = true;
			this.getPlayerStatus = true;
			this.getPlayerStats = true;
			this.setState(prevState => {
				return { ...prevState, ...nextProps, playerObj: null, playerMatchesObj: null, playerContentObj: null };
			});
		} else {
			this.setState(prevState => {
				return { ...prevState, ...nextProps };
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.getPlayerStatus) {
			this.getPlayerStatus = false;
			this.props?.getPlayerStatus(this.state.match.params.player);
		}
		if (this.getPlayerStats) {
			this.getPlayerStats = false;
			this.props.getPlayerStats([`${this.state.match.params.player}`]);
			// logger.log('loading getPlayerStats', this.props.playerStats);
		}

		if (this.state.PlayerPageConfig && this.fetchData && this.props.playerStatus) {
			this.fetchPlayerData();
		}
		if (
			this.props.match.params.tabName !== prevProps.match.params.tabName &&
			typeof this.props.match.params.tabName !== 'undefined' &&
			this.state.playerObj?.id
		) {
			measureInWeb({
				action: `ppTabClick`,
				context: [{ tab: this.props.match.params.tabName, player_id: this.state.playerObj.id }],
			});
		}
	}

	componentWillUnmount() {
		logger.log('[PlayerPage] PlayerPage unmounted');
		this.state.playerObj = null;
	}

	fetchPlayerData() {
		this.fetchData = false;
		const { lang = 'en_GB', player = '' } = this.state.match.params;

		let pathObj = { ...this.state.PlayerPageConfig.pathObj };
		pathObj.playerMatchup = pathObj.playerMatchup.replace('<playerId>', player);
		pathObj.playerRelated = pathObj.playerRelated.replace('<playerId>', player);
		pathObj.playerDetails = pathObj.playerDetails.replace('<id>', player);

		// TODO: update page name to PlayerPage on golive
		deps.services.PlayerPage.getAllPlayerInfo(pathObj).then(
			axios.spread((playerMatchesObj, playerObj, playerContentObj) => {
				let isPlayerObj = Object.keys(playerObj).length !== 0;

				this.playerName = isPlayerObj ? playerObj.first_name + ' ' + playerObj.last_name : '';

				// MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				// 	pageTitle: 'Player Page',
				// 	player: playerObj,
				// });

				measureInWeb({
					pageTitle: 'Player Profile',
					action: 'pageView',
					context: [{ player_id: playerObj.id, section: 'Player Profiles' }],
				});

				this.setState(prevState => {
					logger.log('[PlayerPage] - Matches Data results %o', playerMatchesObj);
					logger.log('[PlayerPage] - playerContentObj Data results %o', playerContentObj);
					logger.log('[PlayerPage] - Player info Data results %o', playerObj['first_name']);
					return Object.assign({}, prevState, {
						playerObj,
						playerMatchesObj,
						playerContentObj,
						status: 'loaded',
					});
				});
			})
		);
	}

	getPageTitle(tab, name) {
		switch (tab) {
			case 'statistics':
				return `${name} Statistics`
				break;
			case 'profile':
				return `${name} Profile`
				break;
			case 'status':
				return `${name} Status`
				break;
			default:
				return `${name} Status`
				break;
		}
	}

	getPageDesc(tab, name) {
		switch (tab) {
			case 'statistics':
				return `View Championships Statistics for ${name}, including news, photos and videos.`
				break;
			case 'profile':
				return `View the Player Profile for ${name}, including news, photos and videos.`
				break;
			case 'status':
				return `View the full player profile and events entered for ${name}, including news, photos and videos.`
				break;
			default:
				return `View the full player profile and events entered for ${name}, including news, photos and videos.`
				break;
		}
	}

	render() {
		logger.log('[PlayerPage] render - state:%o, props: %o', this.state, this.props);

		let playerObj = {};

		let pageTitle = this.getPageTitle(this.props.match.params.tabName, this?.playerName || '');
		let pageDesc = this.getPageDesc(this.props.match.params.tabName, this?.playerName || '');

		let header_propsData = {
			headerType: 'player-profile',
			title: 'Players',
			shortTitle: 'Players',
			scrollElem: '.content-main',
			titleElem: '.player-profile--hide-point',
			// hideIndicatorElem: '.player-profile--hide-point',
			scroll: true,
			shortTitle: this.playerName,
			metaTitle: pageTitle,
			metaDescription: pageDesc,
			metaDate: '',
			metaPlayers: this.playerName !== undefined ? this.playerName : '',
			keywords: this.state?.playerObj?.nation?.country || null,
			canonicalLink: values.canonicalHost + window.location.pathname,
			noindex: this.state.playerObj && Object.keys(this.state.playerObj).length == 0
		};
		header_propsData['imageSrc'] = '/assets/images/player-profile/playerProfile_header.jpg';

		if (this.state.playerObj) {
			playerObj = { ...this.state.playerObj };
		}
		let backData = { category: '', history: this.props.history };
		return (
			<Template>
				<Header attributes={header_propsData} />
				{this.state.playerObj ? (
					Object.keys(this.state.playerObj).length > 0 ? (
						<>
							<PageHeader attributes={header_propsData} />
							<div className="content-main">
								<noindex>
									<div className="player-profile">
										<div className="component player-info">
											<PlayerGeneralInfo
												data={{ playerObj, playerConfig: this.state.PlayerPageConfig }}
											/>
											<PlayerProfessionalInfo
												data={{
													playerObj: playerObj,
													playerConfig: this.state.PlayerPageConfig,
													tabName: this.props.match.params.tabName,
													matchData: this.state.playerMatchesObj,
													playerStatus: op.get(
														this.props,
														`playerStatus.${this.props.match.params.player}.result`,
														false
													),
													playerStats: this.props?.playerStats?.data[0]?.player_stats,
													pathname: this.props.location.pathname,
												}}
											/>
											<Divider />
											<PlayerMedia
												relatedContent={this.state.playerContentObj}
												windowSize={this.state.windowSize}
											/>
											<Divider />
										</div>
										<div className="four-col player-matches-details">
											{this.state.playerMatchesObj ? (
												<PlayerMatches
													matchData={this.state.playerMatchesObj}
													playerConfiguration={this.state.PlayerPageConfig}
													playerID={this.state.match.params.player}
												/>
											) : null}
										</div>
									</div>
								</noindex>
								{/* <PrevNextBack attributes={backData} /> */}
							</div>
						</>
					) : (
						<div className="content-main">
							<noindex>
								<div className="no-player-data">
									<div className="no-data-text">No Player Data Available</div>
								</div>
							</noindex>
						</div>
					)
				) : (
					<div className="content-main">
						<LoadingIndicator />
					</div>
				)}
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPage);

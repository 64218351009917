import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SIDEPANEL_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		case deps.actionTypes.LIVEVIDEO_MOUNT:
			newState = {
				...state,
				showLiveChannel: false,
			};
			return newState;
			break;

		case deps.actionTypes.LIVEVIDEO_UNMOUNT:
			newState = {
				...state,
				showLiveChannel: true,
			};
			return newState;
			break;

		case deps.actionTypes.SIDEPANEL_GEO_UPDATE:
			newState = {
				...state,
				geos: action.data,
			};
			return newState;
			break;

		case deps.actionTypes.SIDEPANEL_HIDEPANEL:
			newState = {
				...state,
				displayPanel: action.data,
			};
			return newState;
			break;

		default:
			return state;
	}
};

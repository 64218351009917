import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let loggedIn;
	let updatedState;
	let currentUser;

	switch (action.type) {
		case deps.actionTypes.GIGYA_MOUNT:
		case deps.actionTypes.GIGYA_LOADED:
		case deps.actionTypes.GIGYA_UPDATE_JWT:
			//logger.log('[Gigya] reducer GIGYA_* action:%o', action);
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		/**
		 * called when Gigya login (user login)
		 */
		case deps.actionTypes.GIGYA_LOGIN:
			//logger.log('[Gigya] reducers.GIGYA_LOGIN action:%o', action);

			newState = {
				...state,
				...action.data,
				gigyaLoaded: true,
				loggedIn: true,
			};
			logger.log('[Gigya] reducer GIGYA_LOGIN newState:%o', newState);
			return newState;
			break;

		/**
		 * called when Gigya logout
		 */
		case deps.actionTypes.GIGYA_LOGOUT:
			//logger.log('[Gigya] reducer GIGYA_LOGOUT action:%o', action);
			newState = {
				...state,
				...action.data,
				loggedIn: false,
				jwt: null,
			};
			//logger.log('[Gigya] reducer GIGYA_LOGOUT newState:%o', newState);
			return newState;
			break;

		/**
		 * called when Gigya data updated, from successful call to setAccountInfo or getAccountInfo
		 */
		case deps.actionTypes.GIGYA_UPDATE:
			//logger.log('[Gigya] reducers.GIGYA_UPDATE state:%o action.data:%o', state, action.data);

			updatedState = {
				...state,
				...action.data,
				gigyaLoaded: true,
				loggedIn: true,
			};

			if (
				updatedState.currentUser.eventName != 'login' &&
				(!updatedState.currentUser || updatedState.currentUser.errorCode != 0)
			) {
				updatedState.loggedIn = false;
			}

			//logger.log('[Gigya] reducer.GIGYA_UPDATE newState:%o', updatedState);
			return updatedState;
			break;

		case deps.actionTypes.GIGYA_UPDATE_ROLE:
			//logger.log('[Gigya] reducers.GIGYA_UPDATE_ROLE state:%o action.data:%o', state, action.data);

			updatedState = {
				...state,
				currentUser: {
					...state.currentUser,
					roles: action.data,
				},
			};
			//logger.log('[Gigya] reducers.GIGYA_UPDATE_ROLE newState:%o', updatedState);

			return updatedState;
			break;

		/**
		 * called on screen callback
		 */
		case deps.actionTypes.GIGYA_SCREEN_CALLBACK:
			//logger.log('[Gigya] reducers.GIGYA_SCREEN_CALLBACK data:%o', action.data);
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[Auth] reducer GIGYA_UPDATE newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

import deps from 'dependencies';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[WIMBLEDONWISH] action.mount - params:%o store:%o', params, Config);

			let data = {
				submitHosts: op.get(Config, 'services.hosts', []),
				submitFunction: op.get(Config, 'services.formSend', ''),
			};
			logger.log('[WimbledonWish] action.mount - data:%o', data);

			dispatch({
				type: deps.actionTypes.WIMBLEDONWISH_MOUNT,
				data: data,
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.BALLOT_UNMOUNT });
	},
};

export default {
	SLAMTRACKER_MOUNT: 'SLAMTRACKER_MOUNT',
	SLAMTRACKER_UNMOUNT: 'SLAMTRACKER_UNMOUNT',
	SLAMTRACKER_LOAD: 'SLAMTRACKER_LOAD',
	SLAMTRACKER_CHANGEVIEW: 'SLAMTRACKER_CHANGEVIEW',
	SLAMTRACKER_UPDATE_MATCHHISTORY: 'SLAMTRACKER_UPDATE_MATCHHISTORY',
	SLAMTRACKER_UPDATE_MOMENTUM_ZOOM: 'SLAMTRACKER_UPDATE_MOMENTUM_ZOOM',
	SLAMTRACKER_UPDATE_MOMENTUM_SET: 'SLAMTRACKER_UPDATE_MOMENTUM_SET',
	SLAMTRACKER_UPDATE_MOMENTUM_GAME: 'SLAMTRACKER_UPDATE_MOMENTUM_GAME',
	SLAMTRACKER_UPDATE_MOMENTUM_SETTINGS: 'SLAMTRACKER_UPDATE_MOMENTUM_SETTINGS',
	SLAMTRACKER_ENABLE_MOMENTUM_SCROLL: 'SLAMTRACKER_ENABLE_MOMENTUM_SCROLL',
	SLAMTRACKER_UPDATE_MOMENTUM_SHOW_SETTINGS: 'SLAMTRACKER_UPDATE_MOMENTUM_SHOW_SETTINGS',
	SLAMTRACKER_FILTER: 'SLAMTRACKER_FILTER',
	SLAMTRACKER_MOMENTUM_MOUNT: 'SLAMTRACKER_MOMENTUM_MOUNT',
	SLAMTRACKER_MOMENTUM_TOGGLEPOINT: 'SLAMTRACKER_MOMENTUM_TOGGLEPOINT',
	SLAMTRACKER_RESET: 'SLAMTRACKER_RESET',
	SLAMTRACKER_UPDATE_HIGHLIGHTS: 'SLAMTRACKER_UPDATE_HIGHLIGHTS',
	SLAMTRACKER_UPDATE_DATA: 'SLAMTRACKER_UPDATE_DATA'
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	switch (action.type) {
		case deps.actionTypes.INSIGHTS_INDEX_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[InsightsIndex] reducers INSIGHTS_INDEX_MOUNT - newState:%o',newState);

			return newState;
			break;

		default:
			return state;
	}
};

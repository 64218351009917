/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { Formik, Form } from 'formik';
import axios from 'axios';
import op from 'object-path';
import * as yup from 'yup';

import ProfileForm from './ProfileForm';
import ProgramForm from './ProgramForm';
import CushionForm from './CushionForm';
import CarForm from './CarForm';
import TicketForm from './TicketForm';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { ContentPageSecureContext } from 'appdir/components/pages/ContentPageSecure/context.js';
import Button from 'appdir/components/common-ui/Button';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: HospitalityForm
 * -----------------------------------------------------------------------------
 */

const mapDispatchToProps = (dispatch, props) => ({
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class HospitalityForm extends Component {
	constructor(props) {
		super(props);

		let formData = {};
		if (props.data && props.data.formData) {
			formData = JSON.parse(props.data.formData);
		}

		this.initialValues = {};
		this.state = {
			...this.props,
			section: 'programme',
			showSpinner: false,
			showErrorPopup: false,
			showSuccessPopup: false,
			formData: formData,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.clearForm = this.clearForm.bind(this);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	clearForm(section, formProps) {
		let updatedInitialValues = { ...formProps.values };
		let clearObj = {};
		let clearedProps = updatedInitialValues.orders[0][section];
		if (section == 'programme') {
			let firstDay = clearedProps.days[0];
			firstDay['suite'] = null;
			firstDay['programmes'] = null;
			clearObj = {
				pplInvoice: null,
				days: [firstDay],
				changeAuthorisation: null,
				name: null,
				date: null,
				purchaseNo: null,
			};
		} else if (section == 'cushion') {
			let firstDay = clearedProps.days[0];
			firstDay['quantity'] = null;
			clearObj = {
				days: [firstDay],
				name: null,
				date: null,
			};
		} else if (section == 'parking') {
			let firstDay = clearedProps.days[0];
			firstDay['spaces'] = null;
			firstDay['electric'] = null;
			clearObj = {
				days: [firstDay],
				suite: null,
				name: null,
				date: null,
				invoice: null,
				purchaseNo: null,
				creditCard: false,
			};
		} else if (section == 'ticket') {
			clearObj = {
				guide: {
					number: null,
					pdf: null,
					comments: null,
				},
				wallets: {
					number: null,
					comments: null,
				},
				security: {
					number: null,
					comments: null,
				},
			};
		}
		updatedInitialValues.orders[0][section] = clearObj;
		this.initialValues = { ...updatedInitialValues };

		this.setState(prevState => {
			return {
				...prevState,
				clearedState: true,
			};
		});
	}

	timeMessageDisplay() {
		setTimeout(() => {
			this.setState(prevState => {
				return {
					...prevState,
					showErrorPopup: false,
					showSuccessPopup: false,
				};
			});
		}, 3000);
	}

	returnEmptyOrders() {
		return [
			{
				year: this.context.dateConfig.year,
				programme: {
					pplInvoice: '',
					weeks: [
						{
							suite: '',
							programmes: null,
							week: '1',
						},
						{
							suite: '',
							programmes: null,
							week: '2',
						},
					],
					days: [
						{
							suite: '',
							programmes: null,
							day: '',
							date: '',
						},
					],
					changeAuthorisation: 'yes',
					name: '',
					date: '',
				},
				cushion: {
					days: [
						{
							quantity: null,
							day: '',
							date: '',
						},
					],
					name: '',
					date: '',
				},
				parking: {
					days: [
						{
							spaces: null,
							electric: null,
							day: '',
							date: '',
						},
					],
					suite: '',
					name: '',
					date: '',
					invoice: '',
					purchaseNo: '',
					creditCard: null,
					delivery: ''
				},
				ticket: {
					guide: {
						number: null,
						pdf: true,
						comments: '',
					},
					wallets: {
						number: null,
						comments: '',
					},
					security: {
						number: null,
						comments: '',
					},
				},
			},
		];
	}

	handleSubmit(values, actions) {
		//handle scroll
		window.scroll({
			top: 0,
			behavior: 'smooth',
		});
		this.setState(prevState => {
			return {
				...prevState,
				showSpinner: true,
			};
		});
		let url = '';
		if (this.state.section == 'programme') {
			url = this.state.data.dataUrl.replace('<service>', 'programme');
		} else if (this.state.section == 'cushion') {
			url = this.state.data.dataUrl.replace('<service>', 'cushion');
		} else if (this.state.section == 'parking') {
			url = this.state.data.dataUrl.replace('<service>', 'parking');
		} else if (this.state.section == 'ticket') {
			url = this.state.data.dataUrl.replace('<service>', 'ticket');
		}

		/**
		 * TODO
		 * construct json object in confirmed format and submit
		 * include section submitting so orch layer can construct proper email
		 */
		let userData = this.buildJson(values);
		logger.log('[UserData] the user data: %o', userData);
		let user = this.state.data.currentUser;
		//logger.log('[HospitalityForm] handleSubmit - state:%o user:%o', this.state, user);

		url = url.replace('<type>', 'hospitality');
		url = url.concat('/' + this.state.section);
		logger.log('[HospitalityForm] handleSubmit - url:%o userData:%o', url, userData);

		//process form submission here
		//done submitting, set submitting to false
		actions.setSubmitting(false);

		this.props
			.getJWT()
			.then(token => {
				userData.jwtToken = token.jwt.id_token;

				let request = axios({
					method: 'put',
					url: url,
					data: userData,
					headers: { 'Content-type': 'application/json' },
				})
					.then(res => {
						logger.log('[HospitalityForm] handleSubmit response:%o', res);
						this.setState(prevState => {
							let formData = { ...prevState.formData };
							formData.store.oid = res.data.store.oid;
							return {
								...prevState,
								showSpinner: false,
								showErrorPopup: false,
								showSuccessPopup: true,
								formData,
							};
						});
						this.timeMessageDisplay();
					})
					.catch(error => {
						logger.error('[HospitalityForm] handleSubmit error:%o', error);
						this.setState(prevState => {
							return {
								...prevState,
								showSpinner: false,
								showErrorPopup: true,
								showSuccessPopup: false,
							};
						});
						this.timeMessageDisplay();
					});
			})
			.catch(error => {
				logger.error('[HospitalityForm] handleSubmit error:%o', error);
				this.setState(prevState => {
					return {
						...prevState,
						showSpinner: false,
						showErrorPopup: true,
						showRetryPopup: false,
						showSuccessPopup: false,
					};
				});
				this.timeMessageDisplay();
			});
	}

	getForm(formProps) {
		logger.log('[HospitalityForm] getForm - formProps:%o', formProps);
		return (
			<div style={{ position: 'relative' }}>
				<div className={`popup-container error ${this.state.showErrorPopup ? 'show' : ''}`}>
					<div className="msg-container">
						<div className="error-msg msg">There was an error submitting your form</div>
					</div>
				</div>
				<div className={`popup-container success ${this.state.showSuccessPopup ? 'show' : ''}`}>
					<div className="msg-container">
						<div className="success-msg msg">Success</div>
					</div>
				</div>
				<Form onSubmit={formProps.handleSubmit}>
					<ProfileForm formProps={formProps} />
					<span className="tabButtons style2 column-layout">
						<span className="four-col tab-btn-divider" />
						<span
							className={
								'one-col one-third outer ' + (this.state.section == 'programme' ? 'selected' : '')
							}
							onClick={this.changeSection.bind(this, 'programme')}>
							<span className="text">Programme Order Form</span>
						</span>
						<span
							className={'one-col one-third outer ' + (this.state.section == 'cushion' ? 'selected' : '')}
							onClick={this.changeSection.bind(this, 'cushion')}>
							<span className="inner">
								<span className="text">Cushion Order Form</span>
							</span>
						</span>
						<span
							className={'one-col one-third outer ' + (this.state.section == 'parking' ? 'selected' : '')}
							onClick={this.changeSection.bind(this, 'parking')}>
							<span className="inner">
								<span className="text">Car Parking Form</span>
							</span>
						</span>
						<span className="four-col tab-btn-divider green thick" />
					</span>
					{this.state.section == 'programme' ? (
						<div>
							<div className="column-layout">
								<div className="deadline two-col">
									Please complete and submit no later than {this.context.dateConfig.programme}.
									Programmes cost £12 each.
								</div>
								{/* <div className="two-col programmes-info">
									Programmes can be stored in the reception area storage rooms and the AELTC hosts can
									deliver them to your suite each day. Programmes are delivered between 7am and 9am.
								</div> */}
							</div>
							<ProgramForm formProps={formProps} />
						</div>
					) : null}
					{this.state.section == 'cushion' ? (
						<div>
							<div className="column-layout">
								<div className="deadline two-col">
									Please complete and submit no later than {this.context.dateConfig.cushion}.
								</div>
								<div className="two-col programmes-info">
									Souvenir Cushion £18.00 (inc VAT) Price excludes corporate discount
								</div>
							</div>
							<CushionForm formProps={formProps} />
						</div>
					) : null}
					{this.state.section == 'parking' ? (
						<div>
							<div className="column-layout">
								<div className="deadline two-col">
									Please complete and submit no later than {this.context.dateConfig.parking}.
								</div>
								<div className="two-col programmes-info">
									Car Park 5 at £30 or £20 for fully electric cars. Two-week passes £350 or £235 for fully electric cars
								</div>
							</div>
							<CarForm formProps={formProps} />
						</div>
					) : null}
					{this.state.section == 'ticket' ? (
						<div>
							<div className="column-layout">
								<div className="deadline four-col">
									If you would like to receive ticket wallets and the ‘Ticket Holders’ Guide’ please
									complete this form and submit no later than {this.context.dateConfig.ticket}.
								</div>
								<div className="four-col programmes-info">
									When sending out Ground Passes to your guests please enclose the ‘Important Security
									Information’ flyer which contains compulsory information for all ticket holders.
									Alternatively sent them an electronic version by email.
								</div>
							</div>
							<TicketForm formProps={formProps} />
						</div>
					) : null}
					<span className="column-layout">
						<span className="four-col hospitality-btns">
							<Button
								className={`hosp-btn solid${formProps.isSubmitting ? ' disabled' : ''}`}
								onClick={() => {
									formProps.submitForm();
								}}>
								Submit
							</Button>
							<Button
								className="hosp-btn hospClear"
								onClick={() => {
									this.clearForm(this.state.section, formProps);
								}}>
								Clear
							</Button>
						</span>
					</span>
				</Form>
			</div>
		);
	}

	validate(values) {
		let errors = {};

		if (!values.email) {
			errors.email = '*Invalid Email Address';
		}

		if (!values.programs) {
			errors.programs = '*Please Enter a Value';
		}

		//check if my values have errors
		logger.log('[HospitalityForm] validate - errors:%o', errors);
		return errors;
	}

	changeSection(which) {
		logger.log('[HospitalityForm] changeSection - which:%o', which);

		this.setState({
			section: which,
		});
	}

	buildJson(values) {
		logger.log('[HospitalityForm] buildJson - values:%o state:%o', values, this.state);
		//add oid when getting it back in response
		let { currentUser } = this.state.data;
		let userData = {
			store: {
				type: 'hospitality',
				data: {
					profile: {
						firstName: currentUser.profile.firstName,
						lastName: currentUser.profile.lastName,
						country: values.country,
						address: values.address,
						companyName: values.company,
						postcode: values.postcode,
						email: currentUser.profile.email,
					},
					orders: this.filterUndefined(values.orders),
					year: values.year,
				},
			},
		};

		if (op.get(this.state, 'formData.store.oid', false)) {
			userData.store.oid = op.get(this.state, 'formData.store.oid', '');
		}

		return userData;
	}

	calculateDate(startDate, year, index) {
		let currDate = new Date(startDate + ' ' + year);
		let firstSunday = false;
		let weekday = new Array(7);
		weekday[0] = 'Sunday';
		weekday[1] = 'Monday';
		weekday[2] = 'Tuesday';
		weekday[3] = 'Wednesday';
		weekday[4] = 'Thursday';
		weekday[5] = 'Friday';
		weekday[6] = 'Saturday';

		let month = new Array(12);
		month[0] = 'January';
		month[1] = 'February';
		month[2] = 'March';
		month[3] = 'April';
		month[4] = 'May';
		month[5] = 'June';
		month[6] = 'July';
		month[7] = 'August';
		month[8] = 'September';
		month[9] = 'October';
		month[10] = 'November';
		month[11] = 'December';
		if (index == 0) {
			return {
				day: weekday[currDate.getDay()],
				date: `${currDate.getDate()} ${month[currDate.getMonth()]}`,
			};
		}
		currDate.setDate(currDate.getDate() + index);
		//adding back middle sunday
		// if (index >= 6) {
		// 	currDate.setDate(currDate.getDate() + 1);
		// }

		return {
			day: weekday[currDate.getDay()],
			date: `${currDate.getDate()} ${month[currDate.getMonth()]}`,
		};
	}

	calculateWeeks(idx) {
		//logger.log('[ProgramForm] calculateDays - data:%o year:%o start:%o', data, year, startDate);

		return {
			week: (idx + 1).toString(),
		};
	}

	filterUndefined(orders) {
		let updatedOrders = { ...orders[0] };
		let programmeDays = updatedOrders.programme.days;
		let programmeWeeks = updatedOrders.programme.weeks;
		let parkingDays = updatedOrders.parking.days;
		let cushionDayDays = updatedOrders.cushion.days;
		let year = this.context.dateConfig.year,
			startDate = this.context.dateConfig.tournStartDate;
		for (let i = 0; i <= 13; i++) {
			let programmeObj = {},
				cushionObj = {},
				parkingObj = {};

			if (programmeDays[i] === undefined) {
				programmeObj = {
					suite: null,
					programmes: null,
					...this.calculateDate(startDate, year, i),
				};
				programmeDays[i] = programmeObj;
			} else {
				programmeDays[i].suite = programmeDays[i].suite == '' ? null : programmeDays[i].suite;
				programmeDays[i].programmes = programmeDays[i].programmes == '' ? null : programmeDays[i].programmes;
				programmeDays[i] = { ...programmeDays[i], ...this.calculateDate(startDate, year, i) };
			}

			if (parkingDays[i] === undefined) {
				parkingObj = {
					spaces: null,
					electric: null,
					...this.calculateDate(startDate, year, i),
				};
				parkingDays[i] = parkingObj;
			} else {
				parkingDays[i].spaces =
					parkingDays[i].spaces == '' ? (parkingDays[i].spaces = null) : parkingDays[i].spaces;
				parkingDays[i].electric =
					parkingDays[i].electric == '' ? (parkingDays[i].electric = null) : parkingDays[i].electric;
				parkingDays[i] = { ...parkingDays[i], ...this.calculateDate(startDate, year, i) };
			}

			if (cushionDayDays[i] === undefined) {
				cushionObj = {
					quantity: null,
					...this.calculateDate(startDate, year, i),
				};
				cushionDayDays[i] = cushionObj;
			} else {
				cushionDayDays[i].quantity = cushionDayDays[i].quantity == '' ? null : cushionDayDays[i].quantity;
				cushionDayDays[i] = { ...cushionDayDays[i], ...this.calculateDate(startDate, year, i) };
			}
		}

		for (i = 0; i <= 1; i++) {
			let programmeObj = {},
				cushionObj = {},
				parkingObj = {};

			if (programmeWeeks[i] === undefined) {
				programmeObj = {
					suite: null,
					programmes: null,
					...this.calculateWeeks(i),
				};
				programmeWeeks[i] = programmeObj;
			} else {
				programmeWeeks[i].suite = programmeWeeks[i].suite == '' ? null : programmeWeeks[i].suite;
				programmeWeeks[i].programmes = programmeWeeks[i].programmes == '' ? null : programmeWeeks[i].programmes;
				programmeWeeks[i] = { ...programmeWeeks[i], ...this.calculateWeeks(i) };
			}
		}

		updatedOrders['programme']['weeks'] = programmeWeeks;
		updatedOrders['programme']['weeks'].length = 2;
		updatedOrders['programme']['days'] = programmeDays;
		updatedOrders['programme']['days'].length = 14;
		updatedOrders['parking']['days'] = parkingDays;
		updatedOrders['parking']['days'].length = 14;
		updatedOrders['parking']['creditCard'] = updatedOrders.parking.creditCard == "true" ? true : false;
		updatedOrders['cushion']['days'] = cushionDayDays;
		updatedOrders['cushion']['days'].length = 14;
		return [updatedOrders];
	}
	render() {
		//logger.log('[HospitalityForm] render - context:%o state:%o', this.context, this.state);

		let { profile } = this.state.data.currentUser;

		//logger.log('[HospitalityForm] render - profile:%o', profile);

		/**
		 * TODO
		 * set initial values based on json block which is in content xml attributes
		 */

		let formYear = op.get(this.state, 'formData.store.data.year', '');
		// let orders = op.get(this.state, 'formData.store.data.orders', []);
		// if (orders.length > 0) {
		// 	formYear = orders[0].year;
		// }

		//if config year matches year in data, use existing data
		if (this.context.dateConfig.year == formYear) {
			this.initialValues = {
				firstName: profile.firstName,
				lastName: profile.lastName,
				email: profile.email,
				dob: { date: profile.birthDay, month: profile.birthMonth, year: profile.birthYear },
				country: op.get(this.state, 'formData.store.data.profile.country', ''),
				postcode: op.get(this.state, 'formData.store.data.profile.postcode', ''),
				company: op.get(this.state, 'formData.store.data.profile.companyName', ''),
				gender: op.get(this.state, 'formData.store.data.profile.gender', ''),
				idDocumentNumber: op.get(this.state, 'formData.store.data.profile.idDocumentNumber', ''),
				nationality: op.get(this.state, 'formData.store.data.profile.nationality', ''),
				address: op.get(this.state, 'formData.store.data.profile.address', ''),
				year: formYear,
				orders: op.get(this.state, 'formData.store.data.orders', this.returnEmptyOrders()),
			};
		}
		//else create empty data for new year
		else {
			this.initialValues = {
				firstName: profile.firstName,
				lastName: profile.lastName,
				email: profile.email,
				dob: { date: profile.birthDay, month: profile.birthMonth, year: profile.birthYear },
				country: '',
				postcode: '',
				company: '',
				gender: '',
				idDocumentNumber: '',
				nationality: '',
				address: '',
				year: this.context.dateConfig.year,
				orders: this.returnEmptyOrders(),
			};
		}

		logger.log('[HospitalityForm] render - initialValues:%o', this.initialValues);

		let HospitalitySchema = yup.object().shape({
			firstName: yup.string().required('First name required'),
			lastName: yup.string().required('Last name required'),
			email: yup
				.string()
				.email()
				.required('Email required'),
			// dob: yup.object().shape({
			// 	date: yup.string().required('Date required'),
			// 	month: yup.string().required('Month required'),
			// 	year: yup.string().required('Year required'),
			// }),
			country: yup.string().required('Country required'),
			postcode: yup.string().required('Zip code required'),
			// gender: yup.string().required('Gender required'),
			company: yup.string().required('Company name required'),
			// nationality: yup.string().required('Nationality required'),
			// idDocumentNumber: yup.string().required('ID Document number required'),
			address: yup.string().required('Address required'),
		});
		return (
			<div className="form-wrapper">
				<div className={`${this.state.data.style} hospitality-form`}>
					{this.state.showSpinner ? (
						<div className="loading-container">
							<div className="loader">
								<LoadingIndicator />
							</div>
						</div>
					) : null}
					<Formik
						initialValues={this.initialValues}
						validationSchema={HospitalitySchema}
						onSubmit={this.handleSubmit}
						enableReinitialize={true}>
						{formProps => {
							return this.getForm({ ...formProps, context: this.context });
						}}
					</Formik>
				</div>
			</div>
		);
	}
}
HospitalityForm.contextType = ContentPageSecureContext;
export default connect(null, mapDispatchToProps)(HospitalityForm);

import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[CountryPlayersPage] action.mount - data:%o', data);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let path = {
				countryPlayer: Config.scoringData.countryPlayers,
				countries: Config.scoringData.countries,
				flagImagePath: Config.otherData.flagImagePathLarge,
			};

			dispatch({ type: deps.actionTypes.COUNTRYPLAYERPAGE_MOUNT, data: { ...path } });
		});
	},
	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.COUNTRYPLAYERPAGE_FILTER, data: data });
	},
};

import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

class LoginWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		logger.log('[LoginWrapper] componentDidMount ');

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Member',
			loggedin: false,
		});
	}

	render() {
		let params = {
			screen: 'login_screen',
			register: false,
			social: false,
		};
		return (
			<div className="four-col">
				<br />
				Please login or register with <MyWimbledonTitle /> to continue.
				<br />
				<GigyaScreenSet params={params} inline={false} /> <div style={{ position: 'relative' }}></div>
			</div>
		);
	}
}

export default LoginWrapper;

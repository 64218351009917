/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import EventSelect from './EventSelect';
import EventsMatchBox from 'appdir/components/scoring/MatchBoxDrawsArchive/events';
import Template from 'appdir/components/Template';

/**
 * -----------------------------------------------------------------------------
 * React Component: CountryStatisticsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsArchiveEvents'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.DrawsArchiveEvents.mount()),
	loadAllEvents: result => dispatch(deps.actions.DrawsArchiveEvents.loadAllEvents(result)),
	setEvent: result => dispatch(deps.actions.DrawsArchiveEvents.setEvent(result)),
	unmount: () => dispatch(deps.actions.DrawsArchiveEvents.unmount()),
});

class DrawsArchiveEvents extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentWillUnmount() {
		// logger.log('[DrawsArchiveEvents] componentWillUnmount');
		this.props.unmount();
	}

	componentDidMount() {
		// logger.log('[DrawsArchiveContentPage] componentDidMount');
		this.firstLoad = true;
	}

	componentDidUpdate(prevState) {
		logger.log('[DrawsArchiveEvents] componentDidUpdate - state:%o, prevState%o', this.state, prevState);
		const pathName = 'v1/draws_archive';

		let dataPath = `${this.state.host}/${pathName}/years`;

		if (this.firstLoad && this.state.eventsAll === '') {
			deps.services.DrawsArchiveEvents.fetch(dataPath).then(result => {
				logger.log('[DrawsArchiveEvents] componentDidUpdate - result %o', result);
				this.props.loadAllEvents(result);
			});
		}
		let dataEventsDrawsPath = `${this.state.host}/${pathName}/get_last_event/${this.state.filters.eventId}`;

		// initially fetch onload to get drawsAllByEvent data
		// and fetch when eventId is changed
		if (
			this.firstLoad ||
			this.state.filters.eventId !== prevState.filters.eventId ||
			this.state.drawsAllByEvent === ''
		) {
			deps.services.DrawsArchiveEvents.fetch(dataEventsDrawsPath).then(result => {
				logger.log('[DrawsArchiveEvents] componentDidUpdate - result %o', result);
				this.props.setEvent(result);
				logger.log(
					'[DrawsArchiveEvents] componentDidUpdate - this.state.drawsAllByEvent %o',
					this.state.drawsAllByEvent
				);
			});
			this.firstLoad = false;
		}
	}

	renderMatches() {
		// logger.log("[DrawsArchiveEvents] renderMatches - state:%o",this.state);
		let count = 0;

		if (!this.state.drawsAllByEvent) {
			return <LoadingIndicator />;
		} else {
			return this.state.drawsAllByEvent.reverse().map((match, index) => {
				// logger.log("[DrawsArchiveEvents] renderMatches filter map match:%o",match);
				return (
					<div key={match.rounds.EVENT_YEAR} className="four-col">
						<EventsMatchBox
							key={match.MATCH_ID + count++}
							attributes={{
								data: match,
								tableHeader: match.year,
								layoutStyle: 'eventsPage',
								flagImagePathLarge: this.state.flagImagePathLarge,
								playerProfileImagePath: this.state.playerProfileImagePath,
							}}
						/>
					</div>
				);
			});
		}
	}

	render() {
		logger.log('[DrawsArchiveEvents] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'drawsArchive',
			title: 'Draws Archive',
			imageSrc: '/assets/images/headers/drawsArchive_events.jpg',
			metaTitle: 'Draws Archive',
			metaDescription: 'Draws Archive for The Championships, Wimbledon.',
			metaDate: '',
			metaPlayers: '',
		};

		if (this.state.eventsAll && this.state.drawsAllByEvent !== '') {
			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="drawsArchiveHdrContent dualDropdown">
						<h1>Events</h1>
						<ArchiveMenu location={this.state.location.pathname} />
					</div>

					<div className="content-main">
						<EventSelect
							year={this.state.year}
							eventsAll={this.state.eventsAll}
							filters={this.state.filters}
						/>

						<div className="column-layout">{this.renderMatches()}</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="drawsArchiveHdrContent dualDropdown">
						<h1>Events</h1>
						<ArchiveMenu location={this.state.location.pathname} />
					</div>

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveEvents);

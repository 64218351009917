/**
 NewsIndex Initial State
*/

export default {
	filters: {
		year: '',
		day: 'all',
		type: '',
		open: '',
	},
	status: 'none',
};

import CompetitorListPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: '/:lang/players/competitors/index.html',
	exact: true,
	component: CompetitorListPage,
	section: MENU_ITEMS.players,
	load: params => deps.actions.CompetitorListPage.mount(params),
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import AtoZ from 'appdir/components/common-ui/AtoZ';
import AtoZNav from 'appdir/components/common-ui/AtoZNav';

/**
 * -----------------------------------------------------------------------------
 * React Component: AtoZWebview
 * A to Z list of services available
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['AtoZWebview'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.AtoZWebview.mount()),
	unmount: () => dispatch(deps.actions.AtoZWebview.unmount()),
});

class AtoZWebview extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
		//logger.log('[AtoZWebview] constructor - state:%o', this.state);
	}

	componentDidMount() {
		//logger.log('[AtoZWebview] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.state.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[AtoZWebview] componentDidUpdate - state:%o', this.state);

		/*
		if (this.state.atozPath && !this.state.atozData) {
			deps.services.AtoZWebview.fetch(this.state.atozPath).then(result => {
				this.setState({
					atozData: result,
				});
			});
		}
		*/
		if (this.state.contentPath && !this.state.atozData) {
			deps.services.AtoZWebview.fetch(this.state.contentPath).then(result => {
				this.setState({
					atozData: result,
				});
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[AtoZWebview] componentWillReceiveProps - nextProps:%o', nextProps);
		//logger.log('[AtoZWebview] componentWillReceiveProps - state:%o', this.state);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		logger.log('[AtoZWebview] render - state:%o', this.state);

		if (this.state.atozData) {
			return (
				<section className="wrapper atoz">
					<div className="content-main atozcontent webview">
						<div className="title-image">
							<div className="image">
								<img
									src="https://photo-assets.wimbledon.com/images/images/pics/large/b_11_federerRaonic79_aeltc_tlovelock.jpg"
									alt="Your guide to Wimbledon"
								/>
							</div>
							<div className="text">
								<a className="page-title-text">Your guide to Wimbledon</a>
							</div>
						</div>
						<div className="content-filters">
							<AtoZNav settings={{ navTop: 0 }} />
						</div>
						<AtoZ data={this.state.atozData} webview={true} />
					</div>
				</section>
			);
		} else {
			return (
				<section className="wrapper">
					<div className="content-main webview">
						<LoadingIndicator />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AtoZWebview);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';

import Template from 'appdir/components/Template';
// import { Link } from 'react-router-dom';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import SiteMap from 'appdir/components/general/SiteMap';
import PageHeader from 'appdir/components/general/PageHeader';
import AccordionCollection from 'appdir/components/common-ui/AccordionCollection';
import Title from 'appdir/components/common-ui/AccordionCollection/Title';
// import SectionDivider from 'appdir/components/cms/SectionDivider';
// import StubBox from 'appdir/components/common-ui/StubBox';
// import WimLink from 'appdir/components/general/WimLink';
import PageNav from 'appdir/components/common-ui/PageNav';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import { MyWimContext } from './context';
import MyWimFavorites from './MyWimFavorites';
import MyWimServices from './MyWimServices';
import MyWimConsent from './MyWimConsent';
import MyWimProfile from './MyWimProfile';
import MyWimSupport from './MyWimSupport';
import Favouriting from '../../general/Favouriting';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledon
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		userPreferences: state['Controller']['userPreferences'],
		...state['Gigya'],
		...state['MyWimbledon'],
		windowSize: state['PageHeader'].windowSize,
		iconPath: op.get(state['Config'], 'myWimbledon.iconPath', ''),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MyWimbledon.mount()),
	unmount: () => dispatch(deps.actions.MyWimbledon.unmount()),
	setAccountInfo: (source, data, modal) => dispatch(deps.actions.Gigya.setAccountInfo(source, data, modal)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class MyWimbledon extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
		const search = this.props.location.search.replace(/^\?/, '');

		this.updateActiveMenuItem = this.updateActiveMenuItem.bind(this);
		//logger.log('[MyWimbledon] constructor - props:%o', this.props);

		//force to top when loading
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		//logger.log('[MyWimbledon] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[MyWimbledon] componentWillReceiveProps - props:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MyWimbledon] componentDidUpdate - prev:%o state:%o', prevState, this.state);

		// if (prevState.tab != this.state.tab) {
		// 	this.setState(prevState => {
		// 		return {
		// 			activeMenuItem: this.state.tab == 'index' ? 'profile' : this.state.tab
		// 		};
		// 	});
		// }
	}

	updateActiveMenuItem(item) {
		logger.log('[MyWimbledon] updateActiveMenuItem - state:%o item:%o', this.state, item);

		this.setState({
			tab: item,
		});

		this.props.history.push({
			pathname: `/en_GB/mywimbledon/${item}.html`,
		});
	}

	/**
	 * called from MyWimConsent for data update
	 * @param {*} update
	 */
	onAccountUpdate(update) {
		logger.log('[MyWimbledon] onAccountUpdate - update:%o', update);
		this.props.setAccountInfo('consent_update', update, true);
	}

	/**
	 * returns list of approved support roles in array
	 * @returns boolean
	 */
	getSupportRoles(roles) {
		let foundRoles = Object.entries(roles).filter(entry => {
			return entry[1].approved;
		});

		logger.log('[MyWimbledon] hasSupportRole - foundRoles:%o', foundRoles);

		return foundRoles;
	}

	render() {
		logger.log('[MyWimbledon] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'mywimbledon',
			title: 'myWimbledon',
			shortTitle: 'myW',
			metaTitle: 'myWimbledon',
			metaDescription: 'myWimbledon - Wimbledon ' + values.tournamentYear,
			metaDate: '',
			metaPlayers: '',
		};

		let style = {
			style: 'four-col',
		};

		let contextValues = {
			loggedIn: this.state.loggedIn,
			currentUser: this.state.currentUser,
			user: this.state.user,
			roles: this.state.roles,
			api: this.state.api,
			img: this.state.img,
			favoriteTimer: this.state.favoriteTimer,
			tab: this.state.tab,
			windowSize: this.state.windowSize,
			getJWT: this.props.getJWT,
			version: this.state.version,
			ballotData: this.state.ballotData,
			lockRoles: this.state.lockRoles
		};
		//check if user shadowing is turned on
		const supportRoles = this.getSupportRoles(op.get(this.state.currentUser, 'data.supportRole', {}));
		const showDetails = op.get(this.state.currentUser, 'data.supportRole.shadow', null);
		let pageNavItems = [];
		if (supportRoles.length) {
			pageNavItems = [
				{ name: 'PROFILE', value: 'profile' },
				{ name: 'FAVOURITES', value: 'favourites' },
				{ name: 'MANAGE BENEFITS', value: 'services' },
				{ name: 'CONSENT & PREFERENCES', value: 'consent' },
				{ name: 'SUPPORT SERVICES', value: 'support' },
			];
		} else {
			pageNavItems = [
				{ name: 'PROFILE', value: 'profile' },
				{ name: 'FAVOURITES', value: 'favourites' },
				{ name: 'MANAGE BENEFITS', value: 'services' },
				{ name: 'CONSENT & PREFERENCES', value: 'consent' },
			];
		}

		logger.log('[MyWimbledon] render - context:%o', contextValues);

		let renderValue = op.get(this.state, 'render', '');

		if (renderValue === true) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main my-wimbledon">
						<PageNav
							menuItems={pageNavItems}
							classes={supportRoles.length ? 'smaller-text' : ''}
							isActive={this.state.tab}
							clickFn={this.updateActiveMenuItem}
							pathname={`/en_GB/mywimbledon/<item>.html`}
						/>
						<div className="column-layout news no-padding top">
							<br />
							<MyWimContext.Provider value={contextValues}>
								{!this.state.loggedIn && !this.state.gigyaLoaded ? (
									<LoadingIndicator />
								) : !this.state.loggedIn && this.state.gigyaLoaded ? (
									<GigyaScreenSet params={{ screen: 'login_screen' }} inline={true} />
								) : this.state.tab == 'profile' ? (
									<MyWimProfile />
								) : this.state.tab == 'favourites' ? (
									<Favouriting pageNav />
								) : // <MyWimFavorites />
								this.state.tab == 'services' ? (
									<MyWimServices />
								) : this.state.tab == 'consent' ? (
									<MyWimConsent onUpdate={data => this.onAccountUpdate(data)} />
								) : this.state.tab == 'support' && showDetails ? (
									<MyWimSupport
										data={showDetails}
										key={'support'}
										roles={supportRoles}
										iconPath={this.props.iconPath}
									/>
								) : null}
							</MyWimContext.Provider>
						</div>
						{this.state.loggedIn && this.state.gigyaLoaded && this.state.tab == 'consent' ? (
							<div className="deactivate-message column-layout news no-padding top">
								<div className="text two-col margin">
									If you have any questions or queries, or wish to deactivate your myWIMBLEDON login,
									then please <WimLink to="/en_GB/contact/index.html">Contact Us</WimLink>.
								</div>
							</div>
						) : null}
					</div>
					<Footer />
				</Template>
			);
		} else if (renderValue === false) {
			header_propsData = {
				headerType: 'generic',
				title: 'Sitemap',
				metaTitle: 'Page not found',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<SiteMap notFound={true} />
						<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
					</div>
					<Footer />
				</Template>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWimbledon);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Slider from 'react-slick';
import Time from 'appdir/components/common-ui/Time';
import WimLink from 'appdir/components/general/WimLink';
import MISectionHeader from './MISectionHeader';

/**
 * -----------------------------------------------------------------------------
 * React Component: InTheMedia for Match Insights
 *
 *  props = {
 *             teams,  --------- Array of Match Insights data - [team1, team2]
 *             inTheMediaData ---- Media data that are stored from Match Insights data
 *             matchRoundCode ---- Match round code from scoring Match data, `publication_date_milliseconds`
 * 			   lastUpdate, ------- epoch time from Match Insights data  - not sure where this data is...
 *             onNextPrevClickFn- callback method for metrics call
 *             infoIcon, ------ boolean - to show (i) or not
 *             onInfoClickFn, -- callback method to open a modal
 *
 *           }
 *
 * -----------------------------------------------------------------------------
 */
const InTheMedia = props => {
	logger.log('[InTheMedia] props:%o', props);

	const renderInTheMedia = (team, which) => {
		let teamdata = eval('props.inTheMediaData[' + (which - 1) + ']');

		const compareMedia = function(a, b) {
			var newA = a['doc']['article']['publication_date_ms'];
			var newB = b['doc']['article']['publication_date_ms'];

			if (newA == newB) {
				var sourceA = a['doc']['article']['host'].toLowerCase();
				var sourceB = b['doc']['article']['host'].toLowerCase();
				return sourceA < sourceB ? -1 : 1;
			}

			return newA < newB ? 1 : -1;
		};

		/** show the latest article */
		teamdata.sort((a, b) => {
			return compareMedia(a, b);
		});

		/** this is a pre match insights, no round code as it's from completed match data */
		// let currentrounddata = teamdata.filter(data => {
		// 	return data.doc.insight.published_round == 'F'; // to test multiple items
		// 	return data.doc.insight.published_round == props.matchRoundCode;
		// });
		let maxcount = 3;

		let settings = {
			dots: true,
			arrows: props?.type !== 'slamtracker' && props?.windowSize !== 'desktop' ? true : false,
			infinite: false,
			speed: 500,
			className: `team${which}-media`,
			beforeChange: (oldIndex, newIndex) => {
				props.onNextPrevClickFn(oldIndex, newIndex, team);
			},
			// afterChange: newIndex => {
			// 	props.onNextPrevClickFn(newIndex, teamNum);
			// },
		};

		if (teamdata.length > 0) {
			return (
				<div className="col" key={`player${which}-media`}>
					<div className="player-name">{team['displayNameA']}</div>
					<Slider {...settings}>
						{teamdata.map((data, i) => {
							if (i < maxcount) {
								return (
									<div className="media-item mi-media-content" key={`player${which}-item-${i}`}>
										<div className="media-item-text">{`"${data.doc.insight.factoid.text}"`}</div>
										<div className="media-item-info">
											<span className="media-item-year">
												<Time epoch_ms={data.doc.article.publication_date_ms} format="YYYY" />
											</span>
											<span className="media-item-host">{` - ${data.doc.article.host}`}</span>
											<span className="media-item-link">
												<WimLink to={data.doc.article.url} external={true}>
													<i className="wim-icon-link-out"></i>
												</WimLink>
											</span>
										</div>
									</div>
								);
							}
						})}
					</Slider>
				</div>
			);
		} else {
			return (
				<div className="mi-media-content col" key={`player${which}-media`}>
					<div className="player-name">{team['displayNameA']}</div>
					There is no information for {team.firstNameA} {team.lastNameA} in the media.
				</div>
			);
		}
	};

	let klass = 'mi-section ';
	klass += props.hideTitle ? 'notitle ' : '';
	klass += props?.windowSize == 'mobile' || props?.windowSize == 'tablet' ? 'no-border-bottom ' : '';

	return (
		<div className={klass}>
			<MISectionHeader
				title="In The Media"
				infoIcon={props.infoIcon ? props.infoIcon : false}
				onInfoClickFn={props.onInfoClickFn}
				lastUpdate={props.lastUpdate ? props.lastUpdate : ''}
				lastUpdatePos="right"
				hideTitle={props.hideTitle ? props.hideTitle : false}
			/>

			<div className="column-layout mi-media">
				{props.teams.map((team, i) => {
					return renderInTheMedia(team, i + 1);
				})}
			</div>
		</div>
	);
};

export default InTheMedia;

import UpcomingWebview from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: ['/webview/:lang/scores/upcoming:tournDay'],
	exact: true,
	component: UpcomingWebview,
	section: MENU_ITEMS.scoresSchedule,
	load: params => deps.actions.UpcomingWebview.mount(params),
};

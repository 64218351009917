/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CountryPlayersSelect from './CountryPlayersSelect';

/**
 * -----------------------------------------------------------------------------
 * React Component: CountryPlayersPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['CountryPlayersPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.CountryPlayersPage.mount()),
});

class CountryPlayersPage extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let url = '';
		let countryPlayerData = this.state.countryPlayersData;
		let countries = this.state.countrySelectData;
		if (this.state.filters.countryPlayers != nextProps.filters.countryPlayers) {
			url = `/en_GB/players/nations/${nextProps.filters.countryPlayers}.html`;
			countryPlayerData = null;
			countries = null;
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				nav: url,
				countryPlayersData: countryPlayerData,
				countrySelectData: countries,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.path && !this.state.countryPlayersData) {
			//fetch country players
			deps.services.CountryPlayersPage.fetch(
				this.state.path.countryPlayer.replace('<country_code>', this.state.match.params.country)
			).then(data => {
				this.setState(prevState => {
					return { ...prevState, countryPlayersData: data };
				});
			});

			//fetch country list
			deps.services.CountryPlayersPage.fetch(this.state.path.countries).then(data => {
				let filterData = data.countries
					.filter(d => d.inDrawsCount > 0)
					.map(d => {
						return { name: d.display, value: d.code };
					});
				this.setState(prevState => {
					return { ...prevState, countrySelectData: filterData };
				});
			});
		}
	}

	render() {
		logger.log('[CountryPlayersPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'scores',
			title: 'Country Player Page',
			shortTitle: 'Country Players',
			pageTitle: 'Country Player Page',
			htsearch: 'Country Player Page',
		};
		if (this.state.nav && this.state.nav != this.state.location.pathname) {
			return <Redirect push to={this.state.nav} />;
		}
		if (this.state.countryPlayersData && this.state.countrySelectData) {
			return (
				<Template className="scorespage">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<div className="column-layout">
							<div className="two-col">
								<div style={{ float: 'left', paddingTop: '18px' }}>
									<img
										src={this.state.path.flagImagePath.replace(
											'<code>',
											this.state.countryPlayersData.country_code
										)}
									/>
								</div>
								<div className="one-col" style={{ float: 'left', fontWeight: 'bold' }}>
									<div>{this.state.countryPlayersData.message}</div>
									<div>{this.state.countryPlayersData.particpants}</div>
								</div>
							</div>
							<div className="two-col">
								<div className="content-filters country-page">
									<CountryPlayersSelect
										selectData={this.state.countrySelectData}
										filters={this.state.filters}
									/>
								</div>
							</div>
						</div>
						<div className="column-layout">
							<div className="two-col">
								{this.state.countryPlayersData.players
									.filter(d => d.sex == 'M')
									.map((d, i) => {
										return (
											<div>
												<Link to={`/en_GB/players/overview/${d.tourid}.html`}>
													<div>
														{d.first_name} {d.last_name}
													</div>
												</Link>
												{d.events_entered.split(',').map((d1, j) => {
													return <div>{d1}</div>;
												})}
											</div>
										);
									})}
							</div>
							<div className="two-col">
								{this.state.countryPlayersData.players
									.filter(d => d.sex == 'F')
									.map((d, i) => {
										return (
											<div>
												<Link to={`/en_GB/players/overview/${d.tourid}.html`}>
													<div>
														{d.first_name} {d.last_name}
													</div>
												</Link>
												{d.events_entered.split(',').map((d1, j) => {
													return <div>{d1}</div>;
												})}
											</div>
										);
									})}
							</div>
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator type={'white'} />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryPlayersPage);

import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, getState).then(Config => {
			logger.log('[AICatchUp] action.mount - params: %o', params);
			let data = {
				status: 'loaded',
			};
            dispatch({ type: deps.actionTypes.AICATCHUP_LOAD, data: data });
		});
    }
};
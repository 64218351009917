import React from 'react';
import { countryLookup } from 'appdir/components/general/Util';

/*
 Country select menu used in Gigya forms for Registration Completion and Edit Profile
*/
const countries = countryLookup;
const Country = data => {
	return (
		<span>
			<label className="gigya-label" data-binding="true" htmlFor={`${data.id}.gigya-dropdown-country`}>
				<span className="gigya-label-text" data-translation-key="DROPDOWN_COUNTRY_LABEL" />
				<label
					className="gigya-required-display"
					data-bound-to="profile.country"
					style={{}}
					data-binding="true"
					htmlFor={`${data.id}.gigya-dropdown-country`}>
					*
				</label>
			</label>

			<select
				name="profile.country"
				id={`${data.id}.gigya-dropdown-country`}
				tabIndex={data.tabIndex ? data.tabIndex : '0'}>
				<option key={'countryinit'} value="">
					-- Select a Country --
				</option>
				<option key={'uktop'} value="GB">
					{countries['GB']}
				</option>
				<option value="">-- All Countries --</option>
				{Object.keys(countries).map((d, i) => {
					return (
						<option key={i} value={d}>
							{countries[d]}
						</option>
					);
				})}
			</select>
			<span className="gigya-error-msg" data-bound-to="profile.country" />
		</span>
	);
};

export default Country;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerProfile StatisticsTab
 * -----------------------------------------------------------------------------
 */

const StatisticsTab = props => {
	let data = props.data;

	let tempStats = data?.playerStats ? data.playerStats.map(i => {
		return { [i.title]: i.stats.find(stat => stat.round === 'Total').display };
	}) : [];

	let totalStats = Object.assign({}, ...tempStats.map(data => data));

	logger.log('totalStats', totalStats);

	logger.log('[StatisticsTab] data: %o', data);

	if (totalStats) {
		return (
			<div className="statistics-page">
				<div className="player-stats" tabIndex={0}>
					<div className="stat-line">
						<span className="stat-field">Aces </span>
						<span className="stat-value">
							{totalStats['Total aces'] ? totalStats['Total aces'] : 'N/A'}
						</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Double Faults </span>
						<span className="stat-value">
							{totalStats['Total double faults'] ? totalStats['Total double faults'] : 'N/A'}
						</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">1st Serves In </span>
						<span className="stat-value">
							{totalStats['1st serves in'] && totalStats['% 1st serves in']
								? `${totalStats['1st serves in']} (${totalStats['% 1st serves in']}%)`
								: 'N/A'}{' '}
						</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">1st Serve Points Won </span>
						<span className="stat-value">
							{totalStats['1st serve points won'] && totalStats['% 1st serve points won']
								? `${totalStats['1st serve points won']} (${totalStats['% 1st serve points won']}%)`
								: 'N/A'}
						</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">2nd Serve Points Won </span>
						<span className="stat-value">
							{totalStats['% 2nd Serve points won'] ? `${totalStats['% 2nd Serve points won']}%` : 'N/A'}
						</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Return Games Won </span>
						<span className="stat-value">
							{totalStats['Return games won'] ? totalStats['Return games won'] : 'N/A'}
						</span>
					</div>
					<span className="tourn-stats-ast">*Tournament statistics are for adult singles only</span>
				</div>
			</div>
		);
	} else {
		return <div className="stats-tab-no-stats">Statistics are not available for this player</div>;
	}
};

export default StatisticsTab;

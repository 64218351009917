import PlayersIndex from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: '/:lang/players/index.html',
	//order: 2,
	exact: true,
	component: PlayersIndex,
	section: MENU_ITEMS.players,
	load: params => deps.actions.PlayersIndex.mount(params),
};

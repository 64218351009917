import { logger } from '../logger';
import CaptionConstants from './CaptionConstants';
import Cea608CaptionScreen from './Cea608CaptionScreen';

export default function Cea608Channel({channelNumber, outputFilter}) {

	var NR_COLS = CaptionConstants.NR_COLS;
	var NR_ROWS = CaptionConstants.NR_ROWS;

	var chNr = channelNumber;
	var outputFilter = outputFilter;
	var mode = null;
	var verbose = 0;
	var displayedMemory = Cea608CaptionScreen();
	var nonDisplayedMemory = Cea608CaptionScreen();
	var lastOutputScreen = Cea608CaptionScreen();
	var currRollUpRow = displayedMemory.getRow(NR_ROWS-1);
	var writeScreen = displayedMemory;
	var mode = null;
	var cueStartTime = null; // Keeps track of where a cue started.
	var msgTime = null;
	
	var modes = ["MODE_ROLL-UP", "MODE_POP-ON", "MODE_PAINT-ON", "MODE_TEXT"];
	
	return Object.freeze({
		reset,
		setTime,
		getHandler,
		setHandler,
		setPAC,
		setBkgData,
		insertChars,
		cc_RCL,
		cc_BS,
		cc_AOF,
		cc_AON,
		cc_DER,
		cc_RU,
		cc_FON,
		cc_RDC,
		cc_TR,
		cc_RTD,
		cc_EDM,
		cc_CR,
		cc_ENM,
		cc_EOC,
		cc_TO,
		cc_MIDROW
	})

	
	function reset() {
		mode = null;
		displayedMemory.reset();
		nonDisplayedMemory.reset();
		lastOutputScreen.reset();
		currRollUpRow = displayedMemory.getRow(NR_ROWS-1);
		writeScreen = displayedMemory;
		mode = null;
		cueStartTime = null;
	};

	function setTime(time) {
		msgTime = time;
	}

	function getHandler() {
		return outputFilter;
	};

	function setHandler(newHandler) {
		outputFilter = newHandler;
	};

	function setPAC(pacData) {
		writeScreen.setPAC(pacData);
	};

	function setBkgData(bkgData) {
		writeScreen.setBkgData(bkgData);
	};

	function setMode(newMode) {
		if (newMode === mode) {
			return;
		}
		mode = newMode;
		//logger.info('setMode - %o', newMode);
		if (mode == "MODE_POP-ON") {
			writeScreen = nonDisplayedMemory;
		} else {
			writeScreen = displayedMemory;
			writeScreen.reset();
		}
		if (mode !== "MODE_ROLL-UP") {
			displayedMemory.nrRollUpRows = null;
			nonDisplayedMemory.nrRollUpRows = null;
		}
		mode = newMode;

		outputDataUpdate({'cmd': 'mode', 'value': mode});
	};

	function insertChars(time, chars) {
		var added = '';
		msgTime = time;

		for (var i = 0 ; i < chars.length ; i++) {
			writeScreen.insertChar(chars[i]);
			added += currRollUpRow.getCharForByte(chars[i]);
		}
		var screen = writeScreen === displayedMemory ? "DISP" : "NON_DISP";
		//logger.info('insertChars - mode:%o chars:%o screen:%o chars:%o', mode, chars, screen, added);

		if (mode === "MODE_PAINT-ON" || mode === "MODE_ROLL-UP") {
			//logger.info('insertChars - time:%o dispText:%o', time, displayedMemory.getDisplayText(true));
			outputDataUpdate();
		}
	};

	function cc_RCL() { // Resume Caption Loading (switch mode to Pop On)
		//logger.info('command - cc_RCL - Resume Caption Loading');
		setMode("MODE_POP-ON");
	};
	function cc_BS() { // BackSpace
		//logger.info('command - cc_BS - BackSpace');
		if (mode === "MODE_TEXT") {
			return;
		}
		writeScreen.backSpace();
		if (writeScreen === displayedMemory) {
			outputDataUpdate();
		}
	}; 
	function cc_AOF() { // Reserved (formerly Alarm Off)
		return;
	};
	function cc_AON() { // Reserved (formerly Alarm On)
		return;
	};
	function cc_DER() { // Delete to End of Row
		//logger.info('command - cc_DER - Delete to End of Row');
		writeScreen.clearToEndOfRow();
		outputDataUpdate();
	};
	function cc_RU(nrRows) { //Roll-Up Captions-2,3,or 4 Rows
		//logger.info('command - cc_RU - roll up rows:%o', nrRows);
		writeScreen = displayedMemory;
		setMode("MODE_ROLL-UP");
		writeScreen.setRollUpRows(nrRows);
	};
	function cc_FON() { //Flash On
		//logger.info('command - cc_FON - Flash On');
		writeScreen.setPen({flash : true});
	};
	function cc_RDC() { // Resume Direct Captioning (switch mode to PaintOn)
		//logger.info('command - cc_RDC - Resume Direct Captioning');
		setMode("MODE_PAINT-ON");
	};
	function cc_TR() { // Text Restart in text mode (not supported, however)
		//logger.info('command - cc_TR - Text Restart');
		setMode("MODE_TEXT");
	};
	function cc_RTD() { // Resume Text Display in Text mode (not supported, however)
		//logger.info('command - cc-RTD - Resume Text Display');
		//setMode("MODE_TEXT");
	};
	function cc_EDM() { // Erase Displayed Memory
		//logger.info('command - cc_EDM - Erase Displayed Memory');
		displayedMemory.reset();
		outputDataUpdate({'cmd': 'erase'});
	};
	function cc_CR() { // Carriage Return
		//logger.info('command - cc_CR - Carriage Return');
		writeScreen.rollUp();
		outputDataUpdate({'cmd': 'rollup'});
	};
	function cc_ENM() { //Erase Non-Displayed Memory
		//logger.info('command - cc_ENM - Erase Non-displayed Memory');
		nonDisplayedMemory.reset();
	};
	function cc_EOC() { //End of Caption (Flip Memories)
		//logger.info('command - cc_EOC - End Of Caption');
		if (mode === "MODE_POP-ON") {
			var tmp = displayedMemory;
			displayedMemory = nonDisplayedMemory;
			nonDisplayedMemory = tmp;
			writeScreen = nonDisplayedMemory;
			//logger.info("cc_EOC - disp:%o", displayedMemory.getDisplayText(true));
		}
		outputDataUpdate();
	};
	function cc_TO(nrCols) { // Tab Offset 1,2, or 3 columns
		logger.info('command - cc_TO - Tab Offset:%o', nrCols);
		writeScreen.moveCursor(nrCols);
	};
	function cc_MIDROW(secondByte) { // Parse MIDROW command
		var styles = {flash : false};
		styles.underline = secondByte % 2 === 1;
		styles.italics = secondByte >= 0x2e;
		if (!styles.italics) {
			var colorIndex = Math.floor(secondByte/2) - 0x10;
			var colors = ["white", "green", "blue", "cyan", "red", "yellow", "magenta"];
			styles.foreground = colors[colorIndex];
		} else {
			styles.foreground = "white";
		}
		//logger.info('command - cc_MIDROW - %o', JSON.stringify(styles));
		writeScreen.setPen(styles);
	};

	function outputDataUpdate(cmd) {
		var time = msgTime;
		//logger.info('outputDataUpdate - time:%o cmd:%o last:%o', time, cmd, displayedMemory.getDisplayText(true));

		if (time === null) {
			return;
		}

		if (outputFilter) {
			if (cmd) {
				if (outputFilter.newCommand){
					outputFilter.newCommand(time, cmd);
				}
				return;
			}

			if (outputFilter.updateData) {
				outputFilter.updateData(time, displayedMemory);
			}

			if (cueStartTime === null && !displayedMemory.isEmpty()) { // Start of a new cue
				cueStartTime = time;
			} else {
				if (!displayedMemory.equals(lastOutputScreen)) { 
					if (outputFilter.newCue) {
						outputFilter.newCue(time, lastOutputScreen);
					}
					cueStartTime = displayedMemory.isEmpty() ? null : time;
				}
			}
			lastOutputScreen.copy(displayedMemory);
		}
	};

	function cueSplitAtTime(t) {
		if (outputFilter) {
			if (!displayedMemory.isEmpty()) {
				if (outputFilter.newCue) {
					outputFilter.newCue(cueStartTime, t, displayedMemory);
				}
				cueStartTime = t;
			}
		}
	};
}
import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEVIDEOHILL_MOUNT });

		switch (data.channel) {
			case 'hill':
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Hill Video',
				});
				break;
			default:
				break;
		}

		//logger.log('[LiveVideo] action.mount - params: %o', data);

		//logger.log('[VideoIndex] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let values = {
				selected: data.channel,
			};
			logger.log('[LiveVideoHill] action.mount - data%o', values);
			dispatch({ type: deps.actionTypes.LIVEVIDEOHILL_MOUNT, data: values });
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEVIDEOHILL_UNMOUNT });
	},
};

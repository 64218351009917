import React, { Component } from 'react';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isEmpty from 'lodash/isEmpty';

import Country from 'appdir/components/general/Gigya/screens/elements/Country';
import BirthYear from 'appdir/components/general/Gigya/screens/elements/BirthYear';
import BirthDay from 'appdir/components/general/Gigya/screens/elements/BirthDay';
import BirthMonth from 'appdir/components/general/Gigya/screens/elements/BirthMonth';
import Title from 'appdir/components/general/Gigya/screens/elements/Title';
import TermsOfService from 'appdir/components/general/Gigya/screens/elements/TermsOfService';
import PrivacyPolicy from 'appdir/components/general/Gigya/screens/elements/PrivacyPolicy';

import InputBox from 'appdir/components/general/Gigya/screens/elements/InputBox';
import { registrationLogin } from 'appdir/components/general/Gigya/translations/registrationLogin';

class RegistrationCompletion extends Component {
	constructor(props) {
		super(props);

		//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
		this.completion_classname = 'gigya-screen v2 portrait';

		this.showSuccess = ''; //'gigya-login-success-screen'

		this.cutoffDate = moment()
			.subtract(13, 'years')
			.add(1, 'day')
			.format('DD/MM/YYYY');
		//logger.log('[BirthDay] render - data:%o cutoff:%o ', data, cutoffDate);
	}

	render() {
		let currentUser = op.get(this, 'context.currentUser', '');
		let user = op.get(this, 'context.user', '');
		let profile = op.get(this, 'context.profile', '');
		let screen = op.get(this, 'context.screen', '');

		let readOnly = false;
		if (profile.email || (currentUser.profile && currentUser.profile.email)) {
			readOnly = true;
		}

		return (
			<div
				id="gigya-complete-registration-screen"
				className={this.completion_classname}
				data-width="auto"
				data-caption="">
				<form
					className="gigya-profile-form"
					data-on-success-screen={this.showSuccess}
					id="gigya-profile-form"
					_lpchecked="1">
					<div className="gigya-layout-row">
						<h2
							className="gigya-composite-control gigya-composite-control-header"
							data-translation-key="HEADER_COMPLETE_REG_LABEL"
						/>
					</div>

					<div className="gigya-layout-row">
						<div className="gigya-composite-control gigya-composite-control-textbox">
							<InputBox
								data={{
									type: 'text',
									name: 'completion.email',
									key: 'TEXTBOX_EMAIL',
									showValid: 'true',
									class: 'gigya-input-text',
									tabIndex: '1',
									readOnly: readOnly,
								}}
							/>
						</div>
					</div>

					<div className="gigya-layout-row with-two-inputs">
						<div className="gigya-layout-cell">
							<div className="gigya-composite-control gigya-composite-control-dropdown">
								<Title tabIndex="1" />
							</div>
						</div>
						<div className="gigya-layout-cell" />
					</div>

					<div className="gigya-layout-row with-two-inputs">
						<div className="gigya-layout-cell">
							<div
								className="gigya-composite-control gigya-composite-control-textbox"
								style={{ display: 'block' }}>
								<InputBox
									data={{
										type: 'text',
										name: 'profile.firstName',
										key: 'TEXTBOX_FIRST_NAME',
										showValid: 'true',
										class: 'gigya-input-text',
										tabIndex: '2',
									}}
								/>
							</div>
						</div>
						<div className="gigya-layout-cell">
							<div
								className="gigya-composite-control gigya-composite-control-textbox"
								style={{ display: 'block' }}>
								<InputBox
									data={{
										type: 'text',
										name: 'profile.lastName',
										key: 'TEXTBOX_LAST_NAME',
										showValid: 'true',
										class: 'gigya-input-text',
										tabIndex: '3',
									}}
								/>
							</div>
						</div>
					</div>

					<div className="gigya-layout-row with-two-inputs">
						<div className="gigya-layout-cell">
							<div className="gigya-composite-control gigya-composite-control-dropdown">
								<div className="gigya-composite-control gigya-composite-control-dropdown">
									<Country id="reg_completion" tabIndex="4" />
								</div>
							</div>
						</div>
						<div className="gigya-layout-cell">
							<div className="gigya-composite-control gigya-composite-control-textbox">
								<InputBox
									data={{
										type: 'text',
										name: 'profile.zip',
										key: 'TEXTBOX_POSTCODE_LABEL',
										showValid: 'true',
										class: 'gigya-input-text',
										tabIndex: '5',
									}}
								/>
							</div>
						</div>
					</div>

					<div className="gigya-layout-row">
						<div className="gigya-composite-control gigya-composite-control-dropdown birth-date">
							<BirthDay id="complete" tabIndex="6" />
							<BirthMonth id="complete" tabIndex="7" />
							<BirthYear id="complete" tabIndex="8" />
							<div className="age-note">
								You must have been born before {this.cutoffDate} to register.
							</div>
						</div>
					</div>

					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '15px' }} />

					<div className="gigya-layout-row">
						<div
							style={{ margin: '16px 0px' }}
							className="gigya-composite-control gigya-composite-control-subscription-widget">
							<i>{registrationLogin.SUBS_NEWSLETTER_LABEL}</i>
						</div>

						<PrivacyPolicy
							granted={op.get(user.preferences, 'privacy.PrivacyPolicy.isConsentGranted', false)}
							docDate={op.get(user.preferences, 'privacy.PrivacyPolicy.docDate', '')}
							modifiedDate={op.get(user.preferences, 'privacy.PrivacyPolicy.lastConsentModified', '')}
							custom={op.get(user.preferences, 'privacy.PrivacyPolicy.custom', '')}
							tabIndex="-1"
						/>

						<TermsOfService
							granted={op.get(user.preferences, 'terms.TermsOfService.isConsentGranted', false)}
							docDate={op.get(user.preferences, 'terms.TermsOfService.docDate', '')}
							modifiedDate={op.get(user.preferences, 'terms.TermsOfService.lastConsentModified', '')}
							custom={op.get(user.preferences, 'terms.TermsOfService.custom', '')}
							tabIndex="-1"
						/>
					</div>

					<div className="gigya-layout-row">
						<div
							className="gigya-composite-control gigya-composite-control-submit"
							style={{ display: 'block' }}>
							<input
								type="submit"
								className="gigya-input-submit"
								tabIndex="11"
								value="screenset.translations['BUTTON_SUBMIT_VALUE']"
							/>
						</div>
					</div>

					<div className="gigya-layout-row">
						<div
							className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
							data-bound-to="gigya-profile-form">
							<div className="gigya-error-msg gigya-form-error-msg" data-bound-to="gigya-profile-form" />
						</div>
					</div>
					<div className="gigya-layout-row" />
					<div className="gigya-clear" />
				</form>
			</div>
		);
	}
}

RegistrationCompletion.contextType = AuthContext;
export default RegistrationCompletion;

import React, { useEffect, useRef, useState, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import isEmpty from 'lodash/isEmpty';
import { values } from 'appdir/main';
import { getQuerystringValues, getAppLink, getInterfaceLink } from 'appdir/components/general/Util';
import {sourceLookup} from 'appdir/components/pages/AICatchUp/util';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';
import WimLink from 'appdir/components/general/WimLink';
import Headshot from 'appdir/components/common-ui/Headshot';
import Button from 'appdir/components/common-ui/Button';
import Time from 'appdir/components/common-ui/Time';
import NewFlagTime from 'appdir/components/common-ui/NewFlagTime';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';
import FullScreenVideo from 'appdir/components/common-ui/FullScreenVideo';
import { measureInAppContext, measureInWeb, doMeasurement } from 'appdir/components/general/Analytics';


const AIPlayerCard = props => {

	const dispatch = useDispatch();

	const [vidOpen, openVideo] = useState(false);

	const data = props?.data;
    const flagImagePathLarge = useSelector(state => state.Config.otherData.flagImagePathLarge);
	// logger.log('[AIPlayerCard] player data:%o, flagImagePathLarge:%o', data, flagImagePathLarge);

    /** Favorites  */
    const favourites = useSelector(state => state['Controller']?.favourites);

	/** viewed content */
    const viewedContent = useSelector(state => state['Controller']['content']['viewed']);

	let search = window.location.search.replace(/^\?/, '');
	let parsedQs = getQuerystringValues(search);
	const debug = parsedQs.debug;

    /** webview Favs 
	 *  store initial favorites players list that
	 *  apps send
	 */
    const [appFavs, setAppFavs] = useState(window.location.search ? parsedQs.filterPlayers?.split(',') : []);

	/** check if this card's player is a webview fav */
    const isWebviewFav = () => {
		if(appFavs?.length > 0) {
			logger.log(
				'[AIPlayerCard] - isWebviewFav - favs:%o, %o:%o',
				appFavs,
				appFavs.indexOf(data.id) !== -1
			);
			return appFavs.indexOf(data.id) !== -1;
		} else {
			return false;
		}
	}

	/** Add or Remove webview favorites players */
    const updateFavs = () => {
		let newFavs = Object.assign([], appFavs);
		let shouldAdd = true;
		let contextKey = 'favorite_add';

		if (isWebviewFav()) {
			// remove fav
			newFavs = appFavs.filter(fav => fav !== data.id);
			shouldAdd = false;
			contextKey = 'favorite_remove';
		} else {
			// add fav
			newFavs.push(data.id);
		}

		let pageTitle = props?.cardType?.includes("shortWeb") ? 'Home' : 'Catch Me Up';
		let measureArgs0 = props?.cardType?.includes("shortWeb") ? 'Catch Me Up' : '';
		let measureArgs = [];
		let contextData = [{item: 'Favorite'}, { action: shouldAdd ? 'Add' : 'Remove' }, { [contextKey]: data.id }];
		let sectionFavoritedval = pageTitle == 'Home' ? 'Home:Catch Me Up' : 'Catch Me Up';

		if (values.webview) {
			measureArgs = ['Favorite', shouldAdd ? 'Add' : 'Remove'];
			contextData = [{ [contextKey]: data.id }, {sectionFavorited: sectionFavoritedval}];
		}
		
		doMeasurement(pageTitle, measureArgs0, measureArgs, contextData);

		/** update favorites list for webview */
		logger.log('[AIPlayerCard] - updateFavs - favs:%o', appFavs);
		setAppFavs([...newFavs]);
	}

	/** favorite star */
	const getFavStar = () => {
		return (
			values.webview ?  (
				<WimLink
					to={
						!isWebviewFav()
							? `/addPlayerFav/${data.id}.html`
							: `/removePlayerFav/${data.id}.html`
					}
					external={true}>
					<span
						tabIndex="0"
						onClick={() => updateFavs()}
						className={`favorite ${
							isWebviewFav() ? 'favorite favorited purple' : 'favorite purple'
						}`}
						></span>
				</WimLink>
			) : (
				<FavouriteStar
					id={data.id}
					type="players"
					unFavColor="purple"
					favColor="purple"
					alwaysShow={true}
				/>
			)
		)
	}

    const getTypeAndStar = () => {
        return (
            <>
				<div className="fav-container">
					{getFavStar()}
				</div>
				<div className="ai-type">
					{
						data?.source && (
							sourceLookup(data.source)
						)
					}
				</div>
            </>
        )
    }

	const fireReadMoreMetrics = () => {
        /** Catch Me Up Home is native - only send metrics for web */
		measureInWeb({
			action: 'Catch Me Up:Read More',
			context: [{ playerId: data.id}],
		});
    }

	/** Read More click that links to Catch Me Up page */
	const onLinkClick = (path) => {
		//logger.log('[AIPlayerCard] path:%o', path);
		fireReadMoreMetrics();
		
		if (values.webview) {
			/** end player id (data.id) */
			const interfaceLink = getInterfaceLink('catchup', data);
			interfaceLink?.onclick();
			logger.log('[AIPlayercard] interfaceLink:%o, click:%o, data:%o ', interfaceLink, interfaceLink?.click, data);
			
		} else {
			dispatch(deps.actions.MainNav.navigate({path}));
			
		}
	}

	/** webview only - fav settings send app data - send player id (data.id) */
	const sendFavSettingsAppData = () => {
		if (window.webview) {
			measureInAppContext({
                pageTitle:"Catch Me Up",
                action: "Favorites",
                args: ['Filter'],
                context:  [{ section: "Catch Me Up" }]
            });

			const interfaceLink = getInterfaceLink('favouriteManage');
			interfaceLink?.onclick();
		}
	}
	
	const sendSTMetrics = (e) => {
		let matchStateText = e?.currentTarget?.firstChild?.innerText ?? '';
		let pageTitle = "Catch Me Up";
		let measureArg = '';
		let metricsVal = []
		let contextData = [{ pageTitle: pageTitle }, { label: matchStateText }];

		if (window.webview) {
			measureArg = [matchStateText];
			contextData = [{ section: pageTitle}];
		}

		doMeasurement(pageTitle, measureArg, metricsVal, contextData);
	};
	
	
	/** Watch Highlight click on the Catch Me Up page */
	const onWatchHighlightsClick = () => {

		if (window.webview) {
			measureInAppContext({
                pageTitle:"Catch Me Up",
                action: "Watch Highlights",
                args: [],
                context:  [{ section: "Catch Me Up" }]
            });

			/** send video ID to app */
			let videoUrl = `/en_GB/video/media/${data?.highlightsVidId}.html`;
			videoUrl = getAppLink(videoUrl, '');
			document.location.href = videoUrl.url;

		} else {
		
			measureInWeb({
				action: "Watch Highlights",
				context: [{ section: "Catch Me Up" }],
			});
		}

		vidOpen ? openVideo(false) : openVideo(true);
	}

	const closeVid = () => {
		openVideo(false);

		measureInWeb({
			action: "Close Watch Highlights",
			context: [{ section: "Catch Me Up" }],
		});
	}

	const shortLayout = () => {
		return (
			<div className="player-card">
				<div className="player-img">
					<Headshot id={data.id} type="full" bg={false} />
				</div>
				<div className="player-info">
					<div className="wrapper-player-info">
						<div className="ai-recommends">
							<NewFlagTime
								data={{
									displayDate: data?.updateTime, 
								}}
							/>
							{ getTypeAndStar()}
						</div>
					</div>
					<div className="ai-summary">
						<p>{data.content}</p>
					</div>
					<div className="updated">Updated:&nbsp;<Time epoch_ms={data?.updateTime} format="ddd HH:mm z" options="upper" /></div>
					{ debug && <div className="debugId">{data.matchId}</div> }
				</div>
				<div className="button-wrapper">
					<Button 
						aria-label="Read More"
						className="solid compact"
						style={{width: '100%'}}
						tabIndex={0}
						onClick={e => {
							onLinkClick(`/en_GB/catchup/index.html?id=${e, data.id}`);
						}}>
							Read More
					</Button>
					<div className="webview updated">Updated:&nbsp;<Time epoch_ms={data?.updateTime} format="ddd HH:mm z" options="upper" /></div>
				</div>
			</div>
		)
	}

	const fullLayout = () => {

		
		let contextData = [{
			section: "Catch Me Up"
		}];		
		let metricsArgs = {
			measureAction: "Catch Me Up:Player Profile",
			contextData
		}
		
		return (
			<div className="player-card" id={data.id}>
				<div className="player-info">
					<div className="wrapper-player-info non-scrollable">
						<div className="ai-recommends">
							
							<NewFlagTime
								data={{
									displayDate: data?.updateTime, 
								}}
							/>
							{ getTypeAndStar()}
						</div>
						<div className="player">
                            {data.country !== "IOA" ?(
                                <img src={flagImagePathLarge.replace('<code>', data.country)} className="flag" />
                            ): null}
							<WimLink to={`/en_GB/players/overview/${data.id}.html`} title={`${data.firstName} ${data.lastName}`} measure={metricsArgs}>
                            	{data.firstName}&nbsp;{data.lastName}
							</WimLink>
						</div>
						<div className="ranking">
							<div className="singles-rank">
								<div className="rank-no">{data.singlesRank ? data.singlesRank : '-'}</div>
								<div className="title">singles<br/>ranking</div>
							</div>
							<div className="player-img">
								<Headshot id={data.id} type="full" bg={false} />
							</div>
                            {parseInt(data?.doublesRank) === 0 ? (
								<div className="doubles-rank">&nbsp;</div>
							) : !isEmpty(data?.doublesRank) ? (
                                <div className="doubles-rank">
                                    <div className="rank-no">{data.doublesRank}</div>
                                    <div className="title">doubles<br/>ranking</div>
                                </div>
                            ) : <div className="doubles-rank">&nbsp;</div> }
						</div>
						{ debug && <div className="debugId">{data.matchId}</div> }
					</div>
					<div className="webview-wrapper-player-info  non-scrollable">
						<div className="ai-recommends">
							{
								window.webview && (
									<NewFlagTime
										data={{
											displayDate: data?.updateTime, 
										}}
									/>
								)
							}
							<div className="ai-type">
							{
								data?.source && (
									sourceLookup(data.source)
								)
							}
							</div>
						</div>
						<div className="player">
                            {data.country !== "IOA" ?(
                                <img src={flagImagePathLarge.replace('<code>', data.country)} className="flag" />
                            ): null}
                            <div className="details">
								<WimLink to={`/en_GB/players/overview/${data.id}.html`} title={`${data.firstName} ${data.lastName}`} measure={metricsArgs}>
									<span className="fname">{data.firstName}</span>
									<span className="lname">{data.lastName}</span>
								</WimLink>
							</div>
							<div className="fav-container">
								<div className="icon-settings" onClick={() => {sendFavSettingsAppData()}}>
									<i className="wim-icon-settings"/>
								</div>
								{getFavStar()}
							</div>
						</div>
						<div className="ranking">
							<div className="wrapper-ranking">
								<div className="singles-rank">
									<div className="rank-no">{data.singlesRank}</div>
									<div className="title">singles<br/>ranking</div>
								</div>
                                {parseInt(data?.doublesRank) === 0 ? (
									<div className="doubles-rank">&nbsp;</div>
								) : !isEmpty(data?.doublesRank) ? (
									<div className="doubles-rank">
										<div className="rank-no">{data.doublesRank}</div>
										<div className="title">doubles<br/>ranking</div>
									</div>
								) : <div className="doubles-rank">&nbsp;</div> }
							</div>
							<div className="player-img">
								<Headshot id={data.id} type="full" bg={false} />
							</div>
						</div>
					</div>
					<div className="ai-summary full scrollable">
						<p>{data.content}</p>
						<div className="updated full">UPDATED:&nbsp;<Time epoch_ms={data?.updateTime} format="ddd HH:mm z" options="upper" /></div>
					</div>
				</div>
				
					{
						data?.highlightsVidId ? (
							<div className="button-wrapper">
								<Button 
									aria-label="Watch Highlights"
									className="solid"
									style={{width: '100%'}}
									tabIndex={0}
									onClick={() => onWatchHighlightsClick()}
								>Watch Highlights</Button>
							</div>
						) : (
							<div className="button-wrapper" onClick={(e) => sendSTMetrics(e)}>
								<SlamtrackerButton
									match_id={data.matchId}
									statusCode=''
									slamtrackerActive={true}
									styleClass="solid"
									buttonSize="large"
								/>
							</div>

						)
					}
					{
						data?.highlightsVidId && vidOpen && !window.webview && <FullScreenVideo closeVid={() => closeVid()} attributes={{videoId: data?.highlightsVidId}} />
					}
			</div>
		)
	}

	logger.log('[AIPlayerCard] favourites:%o', favourites);

	/** select player card based on the card type */
	const selectCard = () => {
		let card = '';
		switch(props?.cardType) {
			case 'fullWeb':
			case 'fullWebview':
				card = fullLayout();
				break;
			case 'shortWeb':
			case 'shortWebview':
				card = shortLayout();
				break;
			default:
				card = fullLayout();
				break;
		}
		return card;
	}
	return	selectCard();
}

export default AIPlayerCard;
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import JSXParser from 'react-jsx-parser';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import Button from 'appdir/components/common-ui/Button';
import Translate from 'appdir/components/pages/Slamtracker/Translate';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: TableComponent
 * -----------------------------------------------------------------------------
 */
const DssStats = props => {
	// logger.log('[DssStats] props: %o', props);
	const [statTeam, changeTeam] = useState('t1');
	const [dss_serve, changeDssServe] = useState('first');
	const [dss_stat, changeDssStat] = useState('fastest_serve');
	const [selectOpen, setSelectOpen] = useState(false);

	const listData = {
		options: [
			{ value: 'fastest_serve', name: 'Fastest Serve' },
			{ value: 'average_serve_speed', name: 'Avg Serve Speed' },
			{ value: 'total_service_points_won', name: 'Total Service Pts Won' },
			{ value: 'total_serves_in', name: 'Total Serves In' },
			{ value: 'total_aces', name: 'Total Aces' },
		]
	}

	let names = props.data.names;
	let t1_stats = props.data.t1;
	let t2_stats = props.data.t2;
	let doubles = props.data.doubles;

	let t1_aw, t1_ab, t1_ac, t1_dc, t1_db, t1_dw;
	let t2_aw, t2_ab, t2_ac, t2_dc, t2_db, t2_dw;
	logger.log('[DssStats] statTeam:%o, dss_serve:%o, dss_stat: %o', statTeam, dss_serve, dss_stat);

	switch (dss_stat) {
		case 'total_aces':
			if (dss_serve == 'first') {
				//1st Serve
				t1_aw = t1_stats.f_ad_w_a != null ? t1_stats.f_ad_w_a : '-';
				t1_ab = t1_stats.f_ad_b_a != null ? t1_stats.f_ad_b_a : '-';
				t1_ac = t1_stats.f_ad_t_a != null ? t1_stats.f_ad_t_a : '-';
				t1_dc = t1_stats.f_du_t_a != null ? t1_stats.f_du_t_a : '-';
				t1_db = t1_stats.f_du_b_a != null ? t1_stats.f_du_b_a : '-';
				t1_dw = t1_stats.f_du_w_a != null ? t1_stats.f_du_w_a : '-';
				t2_aw = t2_stats.f_ad_w_a != null ? t2_stats.f_ad_w_a : '-';
				t2_ab = t2_stats.f_ad_b_a != null ? t2_stats.f_ad_b_a : '-';
				t2_ac = t2_stats.f_ad_t_a != null ? t2_stats.f_ad_t_a : '-';
				t2_dc = t2_stats.f_du_t_a != null ? t2_stats.f_du_t_a : '-';
				t2_db = t2_stats.f_du_b_a != null ? t2_stats.f_du_b_a : '-';
				t2_dw = t2_stats.f_du_w_a != null ? t2_stats.f_du_w_a : '-';
			} else if (dss_serve == 'second') {
				//2nd Serve
				t1_aw = t1_stats.s_ad_w_a != null ? t1_stats.s_ad_w_a : '-';
				t1_ab = t1_stats.s_ad_b_a != null ? t1_stats.s_ad_b_a : '-';
				t1_ac = t1_stats.s_ad_t_a != null ? t1_stats.s_ad_t_a : '-';
				t1_dc = t1_stats.s_du_t_a != null ? t1_stats.s_du_t_a : '-';
				t1_db = t1_stats.s_du_b_a != null ? t1_stats.s_du_b_a : '-';
				t1_dw = t1_stats.s_du_w_a != null ? t1_stats.s_du_w_a : '-';
				t2_aw = t2_stats.s_ad_w_a != null ? t2_stats.s_ad_w_a : '-';
				t2_ab = t2_stats.s_ad_b_a != null ? t2_stats.s_ad_b_a : '-';
				t2_ac = t2_stats.s_ad_t_a != null ? t2_stats.s_ad_t_a : '-';
				t2_dc = t2_stats.s_du_t_a != null ? t2_stats.s_du_t_a : '-';
				t2_db = t2_stats.s_du_b_a != null ? t2_stats.s_du_b_a : '-';
				t2_dw = t2_stats.s_du_w_a != null ? t2_stats.s_du_w_a : '-';
			}
			break;
		case 'fastest_serve':
			if (dss_serve == 'first') {
				//1st Serve
				t1_aw =
					t1_stats.f_ad_w_fs != null ? (
						<span>
							<span className="metric">{t1_stats.f_ad_w_fs[0]}</span>
							<span className="imperial">{t1_stats.f_ad_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ab =
					t1_stats.f_ad_b_fs != null ? (
						<span>
							<span className="metric">{t1_stats.f_ad_b_fs[0]}</span>
							<span className="imperial">{t1_stats.f_ad_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ac =
					t1_stats.f_ad_t_fs != null ? (
						<span>
							<span className="metric">{t1_stats.f_ad_t_fs[0]}</span>
							<span className="imperial">{t1_stats.f_ad_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dc =
					t1_stats.f_du_t_fs != null ? (
						<span>
							<span className="metric">{t1_stats.f_du_t_fs[0]}</span>
							<span className="imperial">{t1_stats.f_du_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_db =
					t1_stats.f_du_b_fs != null ? (
						<span>
							<span className="metric">{t1_stats.f_du_b_fs[0]}</span>
							<span className="imperial">{t1_stats.f_du_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dw =
					t1_stats.f_du_w_fs != null ? (
						<span>
							<span className="metric">{t1_stats.f_du_w_fs[0]}</span>
							<span className="imperial">{t1_stats.f_du_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_aw =
					t2_stats.f_ad_w_fs != null ? (
						<span>
							<span className="metric">{t2_stats.f_ad_w_fs[0]}</span>
							<span className="imperial">{t2_stats.f_ad_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ab =
					t2_stats.f_ad_b_fs != null ? (
						<span>
							<span className="metric">{t2_stats.f_ad_b_fs[0]}</span>
							<span className="imperial">{t2_stats.f_ad_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ac =
					t2_stats.f_ad_t_fs != null ? (
						<span>
							<span className="metric">{t2_stats.f_ad_t_fs[0]}</span>
							<span className="imperial">{t2_stats.f_ad_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dc =
					t2_stats.f_du_t_fs != null ? (
						<span>
							<span className="metric">{t2_stats.f_du_t_fs[0]}</span>
							<span className="imperial">{t2_stats.f_du_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_db =
					t2_stats.f_du_b_fs != null ? (
						<span>
							<span className="metric">{t2_stats.f_du_b_fs[0]}</span>
							<span className="imperial">{t2_stats.f_du_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dw =
					t2_stats.f_du_w_fs != null ? (
						<span>
							<span className="metric">{t2_stats.f_du_w_fs[0]}</span>
							<span className="imperial">{t2_stats.f_du_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
			} else if (dss_serve == 'second') {
				//2nd Serve
				t1_aw =
					t1_stats.s_ad_w_fs != null ? (
						<span>
							<span className="metric">{t1_stats.s_ad_w_fs[0]}</span>
							<span className="imperial">{t1_stats.s_ad_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ab =
					t1_stats.s_ad_b_fs != null ? (
						<span>
							<span className="metric">{t1_stats.s_ad_b_fs[0]}</span>
							<span className="imperial">{t1_stats.s_ad_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ac =
					t1_stats.s_ad_t_fs != null ? (
						<span>
							<span className="metric">{t1_stats.s_ad_t_fs[0]}</span>
							<span className="imperial">{t1_stats.s_ad_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dc =
					t1_stats.s_du_t_fs != null ? (
						<span>
							<span className="metric">{t1_stats.s_du_t_fs[0]}</span>
							<span className="imperial">{t1_stats.s_du_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_db =
					t1_stats.s_du_b_fs != null ? (
						<span>
							<span className="metric">{t1_stats.s_du_b_fs[0]}</span>
							<span className="imperial">{t1_stats.s_du_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dw =
					t1_stats.s_du_w_fs != null ? (
						<span>
							<span className="metric">{t1_stats.s_du_w_fs[0]}</span>
							<span className="imperial">{t1_stats.s_du_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_aw =
					t2_stats.s_ad_w_fs != null ? (
						<span>
							<span className="metric">{t2_stats.s_ad_w_fs[0]}</span>
							<span className="imperial">{t2_stats.s_ad_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ab =
					t2_stats.s_ad_b_fs != null ? (
						<span>
							<span className="metric">{t2_stats.s_ad_b_fs[0]}</span>
							<span className="imperial">{t2_stats.s_ad_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ac =
					t2_stats.s_ad_t_fs != null ? (
						<span>
							<span className="metric">{t2_stats.s_ad_t_fs[0]}</span>
							<span className="imperial">{t2_stats.s_ad_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dc =
					t2_stats.s_du_t_fs != null ? (
						<span>
							<span className="metric">{t2_stats.s_du_t_fs[0]}</span>
							<span className="imperial">{t2_stats.s_du_t_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_db =
					t2_stats.s_du_b_fs != null ? (
						<span>
							<span className="metric">{t2_stats.s_du_b_fs[0]}</span>
							<span className="imperial">{t2_stats.s_du_b_fs[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dw =
					t2_stats.s_du_w_fs != null ? (
						<span>
							<span className="metric">{t2_stats.s_du_w_fs[0]}</span>
							<span className="imperial">{t2_stats.s_du_w_fs[1]}</span>
						</span>
					) : (
						'-'
					);
			}
			break;
		case 'average_serve_speed':
			if (dss_serve == 'first') {
				//1st Serve
				t1_aw =
					t1_stats.f_ad_w_as != null ? (
						<span>
							<span className="metric">{t1_stats.f_ad_w_as[0]}</span>
							<span className="imperial">{t1_stats.f_ad_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ab =
					t1_stats.f_ad_b_as != null ? (
						<span>
							<span className="metric">{t1_stats.f_ad_b_as[0]}</span>
							<span className="imperial">{t1_stats.f_ad_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ac =
					t1_stats.f_ad_t_as != null ? (
						<span>
							<span className="metric">{t1_stats.f_ad_t_as[0]}</span>
							<span className="imperial">{t1_stats.f_ad_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dc =
					t1_stats.f_du_t_as != null ? (
						<span>
							<span className="metric">{t1_stats.f_du_t_as[0]}</span>
							<span className="imperial">{t1_stats.f_du_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_db =
					t1_stats.f_du_b_as != null ? (
						<span>
							<span className="metric">{t1_stats.f_du_b_as[0]}</span>
							<span className="imperial">{t1_stats.f_du_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dw =
					t1_stats.f_du_w_as != null ? (
						<span>
							<span className="metric">{t1_stats.f_du_w_as[0]}</span>
							<span className="imperial">{t1_stats.f_du_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_aw =
					t2_stats.f_ad_w_as != null ? (
						<span>
							<span className="metric">{t2_stats.f_ad_w_as[0]}</span>
							<span className="imperial">{t2_stats.f_ad_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ab =
					t2_stats.f_ad_b_as != null ? (
						<span>
							<span className="metric">{t2_stats.f_ad_b_as[0]}</span>
							<span className="imperial">{t2_stats.f_ad_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ac =
					t2_stats.f_ad_t_as != null ? (
						<span>
							<span className="metric">{t2_stats.f_ad_t_as[0]}</span>
							<span className="imperial">{t2_stats.f_ad_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dc =
					t2_stats.f_du_t_as != null ? (
						<span>
							<span className="metric">{t2_stats.f_du_t_as[0]}</span>
							<span className="imperial">{t2_stats.f_du_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_db =
					t2_stats.f_du_b_as != null ? (
						<span>
							<span className="metric">{t2_stats.f_du_b_as[0]}</span>
							<span className="imperial">{t2_stats.f_du_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dw =
					t2_stats.f_du_w_as != null ? (
						<span>
							<span className="metric">{t2_stats.f_du_w_as[0]}</span>
							<span className="imperial">{t2_stats.f_du_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
			} else if (dss_serve == 'second') {
				//2nd Serve
				t1_aw =
					t1_stats.s_ad_w_as != null ? (
						<span>
							<span className="metric">{t1_stats.s_ad_w_as[0]}</span>
							<span className="imperial">{t1_stats.s_ad_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ab =
					t1_stats.s_ad_b_as != null ? (
						<span>
							<span className="metric">{t1_stats.s_ad_b_as[0]}</span>
							<span className="imperial">{t1_stats.s_ad_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_ac =
					t1_stats.s_ad_t_as != null ? (
						<span>
							<span className="metric">{t1_stats.s_ad_t_as[0]}</span>
							<span className="imperial">{t1_stats.s_ad_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dc =
					t1_stats.s_du_t_as != null ? (
						<span>
							<span className="metric">{t1_stats.s_du_t_as[0]}</span>
							<span className="imperial">{t1_stats.s_du_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_db =
					t1_stats.s_du_b_as != null ? (
						<span>
							<span className="metric">{t1_stats.s_du_b_as[0]}</span>
							<span className="imperial">{t1_stats.s_du_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t1_dw =
					t1_stats.s_du_w_as != null ? (
						<span>
							<span className="metric">{t1_stats.s_du_w_as[0]}</span>
							<span className="imperial">{t1_stats.s_du_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_aw =
					t2_stats.s_ad_w_as != null ? (
						<span>
							<span className="metric">{t2_stats.s_ad_w_as[0]}</span>
							<span className="imperial">{t2_stats.s_ad_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ab =
					t2_stats.s_ad_b_as != null ? (
						<span>
							<span className="metric">{t2_stats.s_ad_b_as[0]}</span>
							<span className="imperial">{t2_stats.s_ad_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_ac =
					t2_stats.s_ad_t_as != null ? (
						<span>
							<span className="metric">{t2_stats.s_ad_t_as[0]}</span>
							<span className="imperial">{t2_stats.s_ad_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dc =
					t2_stats.s_du_t_as != null ? (
						<span>
							<span className="metric">{t2_stats.s_du_t_as[0]}</span>
							<span className="imperial">{t2_stats.s_du_t_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_db =
					t2_stats.s_du_b_as != null ? (
						<span>
							<span className="metric">{t2_stats.s_du_b_as[0]}</span>
							<span className="imperial">{t2_stats.s_du_b_as[1]}</span>
						</span>
					) : (
						'-'
					);
				t2_dw =
					t2_stats.s_du_w_as != null ? (
						<span>
							<span className="metric">{t2_stats.s_du_w_as[0]}</span>
							<span className="imperial">{t2_stats.s_du_w_as[1]}</span>
						</span>
					) : (
						'-'
					);
			}
			break;
		case 'total_service_points_won':
			if (dss_serve == 'first') {
				//1st Serve
				t1_aw = t1_stats.f_ad_w_pw != null ? t1_stats.f_ad_w_pw : '-';
				t1_ab = t1_stats.f_ad_b_pw != null ? t1_stats.f_ad_b_pw : '-';
				t1_ac = t1_stats.f_ad_t_pw != null ? t1_stats.f_ad_t_pw : '-';
				t1_dc = t1_stats.f_du_t_pw != null ? t1_stats.f_du_t_pw : '-';
				t1_db = t1_stats.f_du_b_pw != null ? t1_stats.f_du_b_pw : '-';
				t1_dw = t1_stats.f_du_w_pw != null ? t1_stats.f_du_w_pw : '-';
				t2_aw = t2_stats.f_ad_w_pw != null ? t2_stats.f_ad_w_pw : '-';
				t2_ab = t2_stats.f_ad_b_pw != null ? t2_stats.f_ad_b_pw : '-';
				t2_ac = t2_stats.f_ad_t_pw != null ? t2_stats.f_ad_t_pw : '-';
				t2_dc = t2_stats.f_du_t_pw != null ? t2_stats.f_du_t_pw : '-';
				t2_db = t2_stats.f_du_b_pw != null ? t2_stats.f_du_b_pw : '-';
				t2_dw = t2_stats.f_du_w_pw != null ? t2_stats.f_du_w_pw : '-';
			} else if (dss_serve == 'second') {
				//2nd Serve
				t1_aw = t1_stats.s_ad_w_pw != null ? t1_stats.s_ad_w_pw : '-';
				t1_ab = t1_stats.s_ad_b_pw != null ? t1_stats.s_ad_b_pw : '-';
				t1_ac = t1_stats.s_ad_t_pw != null ? t1_stats.s_ad_t_pw : '-';
				t1_dc = t1_stats.s_du_t_pw != null ? t1_stats.s_du_t_pw : '-';
				t1_db = t1_stats.s_du_b_pw != null ? t1_stats.s_du_b_pw : '-';
				t1_dw = t1_stats.s_du_w_pw != null ? t1_stats.s_du_w_pw : '-';
				t2_aw = t2_stats.s_ad_w_pw != null ? t2_stats.s_ad_w_pw : '-';
				t2_ab = t2_stats.s_ad_b_pw != null ? t2_stats.s_ad_b_pw : '-';
				t2_ac = t2_stats.s_ad_t_pw != null ? t2_stats.s_ad_t_pw : '-';
				t2_dc = t2_stats.s_du_t_pw != null ? t2_stats.s_du_t_pw : '-';
				t2_db = t2_stats.s_du_b_pw != null ? t2_stats.s_du_b_pw : '-';
				t2_dw = t2_stats.s_du_w_pw != null ? t2_stats.s_du_w_pw : '-';
			}
			break;
		case 'total_serves_in':
			if (dss_serve == 'first') {
				//1st Serve
				t1_aw = t1_stats.f_ad_w_si != null ? t1_stats.f_ad_w_si : '-';
				t1_ab = t1_stats.f_ad_b_si != null ? t1_stats.f_ad_b_si : '-';
				t1_ac = t1_stats.f_ad_t_si != null ? t1_stats.f_ad_t_si : '-';
				t1_dc = t1_stats.f_du_t_si != null ? t1_stats.f_du_t_si : '-';
				t1_db = t1_stats.f_du_b_si != null ? t1_stats.f_du_b_si : '-';
				t1_dw = t1_stats.f_du_w_si != null ? t1_stats.f_du_w_si : '-';
				t2_aw = t2_stats.f_ad_w_si != null ? t2_stats.f_ad_w_si : '-';
				t2_ab = t2_stats.f_ad_b_si != null ? t2_stats.f_ad_b_si : '-';
				t2_ac = t2_stats.f_ad_t_si != null ? t2_stats.f_ad_t_si : '-';
				t2_dc = t2_stats.f_du_t_si != null ? t2_stats.f_du_t_si : '-';
				t2_db = t2_stats.f_du_b_si != null ? t2_stats.f_du_b_si : '-';
				t2_dw = t2_stats.f_du_w_si != null ? t2_stats.f_du_w_si : '-';
			} else if (dss_serve == 'second') {
				//2nd Serve
				t1_aw = t1_stats.s_ad_w_si != null ? t1_stats.s_ad_w_si : '-';
				t1_ab = t1_stats.s_ad_b_si != null ? t1_stats.s_ad_b_si : '-';
				t1_ac = t1_stats.s_ad_t_si != null ? t1_stats.s_ad_t_si : '-';
				t1_dc = t1_stats.s_du_t_si != null ? t1_stats.s_du_t_si : '-';
				t1_db = t1_stats.s_du_b_si != null ? t1_stats.s_du_b_si : '-';
				t1_dw = t1_stats.s_du_w_si != null ? t1_stats.s_du_w_si : '-';
				t2_aw = t2_stats.s_ad_w_si != null ? t2_stats.s_ad_w_si : '-';
				t2_ab = t2_stats.s_ad_b_si != null ? t2_stats.s_ad_b_si : '-';
				t2_ac = t2_stats.s_ad_t_si != null ? t2_stats.s_ad_t_si : '-';
				t2_dc = t2_stats.s_du_t_si != null ? t2_stats.s_du_t_si : '-';
				t2_db = t2_stats.s_du_b_si != null ? t2_stats.s_du_b_si : '-';
				t2_dw = t2_stats.s_du_w_si != null ? t2_stats.s_du_w_si : '-';
			}
			break;
		default:
			break;
	}

	const onSelect = (val) => {
		changeDssStat(val);
		setSelectOpen(false);
	}

	const onOpen = () => {
        // logger.log('[MatchStats] onOpen - val:%o, selectOpen:%o', val, selectOpen);
        if (selectOpen) {
            setSelectOpen(false);
        } else {
            setSelectOpen(true);
        }
    }

	logger.log('[DssStats] t1_aw:%o, t1_ab:%o, t1_ac:%o, t1_dc:%o, t1_db:%o, t1_dw:%o, t2_aw:%o, t2_ab:%o, t2_ac:%o, t2_dc:%o, t2_db:%o, t2_dw:%o', t1_aw, t1_ab, t1_ac, t1_dc, t1_db, t1_dw, t2_aw, t2_ab, t2_ac, t2_dc, t2_db, t2_dw);

	return (
		<div className="directional-serve-stats">
			<div className="stats-header-container">
				<div className="stats-header-row">
					<Button
						className={`stats-header-cell t1 ${statTeam == 't1' ? 'selected' : ''}`}
						onClick={() => changeTeam('t1')}>
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[0]} />
						</div>
					</Button>
					<Button
						className={`stats-header-cell t2 ${statTeam == 't2' ? 'selected' : ''}`}
						onClick={() => changeTeam('t2')}>
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[1]} />
						</div>
					</Button>
				</div>
			</div>
			<div className="filter-container">
				<div className="serve-selection">
					<div className="radioBtn">
						<input
							type="radio"
							name="serve_num"
							defaultChecked={dss_serve == 'first' ? true : false}
							onClick={() => changeDssServe('first')}
						/>
						<span className="checkmark" />
						<label htmlFor="first_serve_num">
							<Translate originalText="first_serve_num" />
						</label>
					</div>
					<div className="radioBtn">
						<input
							type="radio"
							name="serve_num"
							defaultChecked={dss_serve == 'second' ? true : false}
							onClick={() => changeDssServe('second')}
						/>
						<span className="checkmark" />
						<label htmlFor="second_serve_num">
							<Translate originalText="second_serve_num" />
						</label>
					</div>
				</div>
				<div className="stat-selection">
					<div className="scores-select-menu gray">
						<SelectMenu
							name="dssStats"
							attributes={listData}
							selected={dss_stat}
							show={selectOpen}
							onSelected={onSelect}
							onOpen={onOpen}
						/>
					</div>
				</div>
			</div>
			<div className="serve-stats-container serve-stats">
				{statTeam == 't2' ? (
					<div className="p2container">
						<div
							className={`p2dw box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t2_dw}
						</div>
						<div
							className={`p2db box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t2_db}
						</div>
						<div
							className={`p2dc box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t2_dc}
						</div>

						<div
							className={`p2ac box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t2_ac}
						</div>
						<div
							className={`p2ab box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t2_ab}
						</div>
						<div
							className={`p2aw box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t2_aw}
						</div>
						<div className="deuce">
							<Translate originalText="deuce" />
						</div>
						<div className="advantage">
							<Translate originalText="advantage" />
						</div>
					</div>
				) : (
					<div className="p1container">
						<div
							className={`p1dw box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t1_dw}
						</div>
						<div
							className={`p1db box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t1_db}
						</div>
						<div
							className={`p1dc box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t1_dc}
						</div>

						<div
							className={`p1ac box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t1_ac}
						</div>
						<div
							className={`p1ab box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t1_ab}
						</div>
						<div
							className={`p1aw box ${
								dss_stat == 'average_serve_speed' || dss_stat == 'fastest_serve' ? 'multiline' : ''
							}`}>
							{t1_aw}
						</div>
						<div className="deuce">
							<Translate originalText="deuce" />
						</div>
						<div className="advantage">
							<Translate originalText="advantage" />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default DssStats;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { callFunction } from 'appdir/components/general/Util/Services';
import { checkRole } from 'appdir/components/general/Util/Role';
import op from 'object-path';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';

import Template from 'appdir/components/Template';
// import { Link } from 'react-router-dom';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import { MediaRequestContext } from './context';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import SectionDivider from 'appdir/components/cms/SectionDivider';

import WimRedirect from 'appdir/components/general/WimRedirect';
import RequestForm from './RequestForm';
import CompleteMessage from './CompleteMessage';
import LoginRequired from 'appdir/components/common-ui/LoginRequired';
import SiteMap from 'appdir/components/general/SiteMap';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledon
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		userPreferences: state['Controller']['userPreferences'],
		...state['Gigya'],
		...state['MediaRequest'],
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Ballot.mount()),
	unmount: () => dispatch(deps.actions.Ballot.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class MediaRequest extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = Object.assign({}, this.props, { complete: false });
	}

	componentDidMount() {
		logger.log('[MediaRequest] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[MediaRequest] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MediaRequest] componentDidUpdate - prev:%o state:%o', prevState, this.state);
	}

	handleSubmit(values) {
		logger.log('[MediaRequest] handleSubmit - values:%o', values);

		this.submitModal('send');

		let sendData = {
			id: '1000',
			store: {
				data: values,
			},
		};
		let headers = { 'Content-type': 'application/json' };

		callFunction(this.state.submitHosts, this.state.submitFunction, 'post', sendData, headers)
			.then(res => {
				logger.log('[MediaRequest] success submitting request: %o', res);
				//check for valid data
				if (res.status == 'success') {
					this.submitModal('success');
					this.setState({
						complete: true,
					});
				} else {
					this.submitModal('error');
				}
			})
			.catch(error => {
				logger.error('[MediaRequest] error submitting media request: %o', error);
				this.submitModal('error');
			});
	}

	submitModal(state) {
		if (state == 'send') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'REQUEST MEDIA ACCESS',
				message: 'Your request is being sent. Please do not close your browser window while processing.',
				useTimer: false,
			});
		} else if (state == 'success') {
			this.props.showModal({
				showModal: false,
			});
		} else if (state == 'error') {
			//logger.log('[MediaRequest] updateModal - state: %o', state);

			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: 'REQUEST MEDIA ACCESS',
				message: 'There was an error with your request. Please try again.',
				useTimer: true,
			});
		}
	}

	getMediaRedirect(user) {
		let roles = op.get(user, 'roles', []);
		let mediaRole = roles.filter(d => d.roleId == 1000250)[0];

		if (mediaRole.landingPage) {
			return <WimRedirect to={mediaRole.landingPage} />;
		} else {
			return null;
		}
	}

	render() {
		logger.log('[MediaRequest] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'generic_register',
			title: 'Media Request',
			shortTitle: 'Media Request',
			metaTitle: 'Media Request',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		};

		let style = {
			style: 'four-col',
		};

		let contextValues = {
			user: op.get(this.state, 'currentUser', null),
			submit: this.handleSubmit,
		};
		logger.log('[MediaRequest] render - contextValues:%o', contextValues);

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main news media-form" id="main" tabIndex="0">
					<div className="column-layout general">
						<div className="four-col heading">
							<h3 className="green upper">REQUEST ACCESS TO THE WIMBLEDON MEDIA WEBSITE</h3>
						</div>
						<SectionDivider data={{ style: 'four-col', dividerStyle: 'light gray' }} />
						<div className="two-col margin body left-align">
							The Wimbledon Media Website will provide you with information and resources to support your
							coverage of The Championships, tailored according to your role during the event.
							<br />
							<br />
							Please fill out the following form to request access. If your request is successful, you
							will receive an email inviting you to create a myWIMBLEDON account and you will need to
							accept our terms and conditions of accreditation, so please ensure you supply the correct
							email address.
							<br />
							<br />
							We will endeavour to process your requests as soon as possible, but it may take up to 48
							hours, so your patience is appreciated.
							<br />
							<br />
							If you have any questions, please speak to your contact at The All England Lawn Tennis Club
							or The Championships.
						</div>
					</div>

					<div className="column-layout news">
						<SectionDivider data={{ style: 'four-col', dividerStyle: 'light gray nomargin' }} />

						<MediaRequestContext.Provider value={contextValues}>
							<LoginRequired roles={[]}>
								{this.state.complete
									? (allowed, currentUser) => {
											return <CompleteMessage />;
									  }
									: (allowed, currentUser) => {
											let hasMediaRole = checkRole(
												op.get(currentUser, 'roles', []),
												[1000250],
												true
											);
											logger.log(
												'[MediaRequest] render - allowed:%o user:%o',
												allowed,
												currentUser
											);
											if (allowed && !hasMediaRole) {
												return <RequestForm />;
											} else if (hasMediaRole) {
												return this.getMediaRedirect(currentUser);
											} else {
												return <SiteMap notFound={true} class={'four-col'} />;
											}
									  }}
							</LoginRequired>
						</MediaRequestContext.Provider>
					</div>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaRequest);

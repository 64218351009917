import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	update: params => (dispatch, getState) => {
		dispatch({ type: deps.actionTypes.CONFIG_LOADING });

		//logger.log('[Config]  deps.actions.update - ');
		return deps.services.Config.fetch()
			.then(result => {
				//logger.log('[Config] loadConfigHelper - type:%o result:%o', typeof(result), result);
				if (typeof result === 'object') {
					dispatch({
						type: deps.actionTypes.CONFIG_UPDATE,
						updated: moment().toISOString(),
						status: 'loaded',
						data: result,
					});
				} else {
					dispatch({
						type: deps.actionTypes.CONFIG_ERROR,
						error: 'json parse',
					});
				}
			})
			.catch(err => {
				dispatch({
					type: deps.actionTypes.CONFIG_ERROR,
					error: err,
				});
			});
	},

	updateStatic: param => (dispatch, getState) => {
		let url = getState()['Config'].staticData[param].url;

		//allow global override of geo location country
		if (param == 'edge_country') {
			let parsedQs = getQuerystringValues();
			if (parsedQs.edgeCntry) {
				let data2 = {
					name: param,
					status: 'loaded',
					updated: moment().toISOString(),
					data: {'edge_country': parsedQs.edgeCntry}
				};
				dispatch({
					type: deps.actionTypes.CONFIG_STATIC_UPDATE,
					data: data2
				});
				return;
			}
		}
		//end override of geo location country

		dispatch({
			type: deps.actionTypes.CONFIG_STATIC_LOADING,
			data: {
				name: param,
				status: 'loading',
			},
		});

		return deps.services.Config.fetchStatic(url).then(result => {
			//logger.log('[Config] updateStatic - result:%o', typeof result);
			if (typeof result === 'object') {
				// logger.log('[Config]  deps.actions.updateStatic - loaded url:%o', url);
				let data = {
					name: param,
					status: 'loaded',
					updated: moment().toISOString(),
					data: result,
				};

				dispatch({
					type: deps.actionTypes.CONFIG_STATIC_UPDATE,
					data: data,
				});
			} else {
				// if there was an error, state stays loading, and will not load again
				logger.error('[Config] deps.actions.updateStatic - error url:%o', url);
			}
		});
	},
};

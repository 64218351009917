/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import SectionHeaderBar from '../../common-ui/SectionHeaderBar';
import PathToTheFinalStandalone from '../../common-ui/PathToTheFinalStandalone';
import Headshot from '../../common-ui/Headshot';
import Button from 'appdir/components/common-ui/Button';
import { isSlamTrackerActive } from 'appdir/components/pages/DrawsPage/DrawsUtils';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';
import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerProfile StatusTab
 * -----------------------------------------------------------------------------
 */

const StatusTab = props => {
	let data = props.data;
	logger.log('[StatusTab] data: %o', data);
	const [showExpandedMatch, setShowExpandedMatch] = useState(0);

	const toggleExpendedMatch = index => {
		setShowExpandedMatch(showExpandedMatch === index ? null : index);
		if (window.webview) {
			measureInAppContext({
				pageTitle: 'Player Profile',
				action: 'Event Accordion',
				args: showExpandedMatch === index ? 'Close' : 'Expand',
				context: [{ pp_playerID: data.playerObj.id }],
				metricType: 'action',
			});
		} else {
			measureInWeb({
				action: 'ppAccordion',
				context: [{ state: showExpandedMatch === index ? 'Closed' : 'Expand', player_id: data.playerObj.id }],
			});
		}
	};

	function getMatchCompletionStatus(statusCode) {
		// const preMatch = ['B'];
		// const liveMatch = ['A', 'K', 'X', 'Y'];
		// const postMatch = ['D', 'E', 'F', 'G'];
		const code = {
			A: 'In Progress',
			B: 'Scheduled', //'Future'
			C: 'Cancelled',
			D: 'Complete',
			E: 'Retired',
			F: 'Walkover',
			G: 'Default',
			K: 'Suspended',
			P: 'Postponed',
			X: 'Arrive on Court',
			Y: 'Warming Up',
		};
		return code[statusCode];
	}

	function hasMatchData() {
		let hasMatchData = false;
		if (data?.playerStatus?.matches?.length > 0 && data?.playerStatus?.matches) {
			return true;
		}
		else {
			return false;
		}
	}

	function getUnknownMatchDisplay(matchInfo, index) {
		logger.log('[getUnknownMatchDisplay] matchInfo: %o', matchInfo);

		return (
			<div className="event-box" tabIndex={0} key={index}>
				<div className="event-name" onClick={() => toggleExpendedMatch(index)}>
					{matchInfo.eventName}
				</div>
				<div className="round-name">{matchInfo.roundName}</div>
				<div className="match-status">{matchInfo.status}</div>
				{!window.webview ?
					<Button
						className={`solid`}
						to="/en_GB/scores/schedule/index.html">
						Order of Play
					</Button>
					: null
				}
			</div>
		)
	}

	function getMatchDisplay(matchInfo, index) {
		return (
			<div className="event-box" tabIndex={0} key={index}>
								<div className="event-name" onClick={() => toggleExpendedMatch(index)}>
									{matchInfo.eventName}
								</div>
								<div className="round-name">{matchInfo.roundName}</div>
								<div className="scheduled-match">
									<div className="match-completion-status">
										<span className="status">{getMatchCompletionStatus(matchInfo.statusCode)}</span>
										{'vs.'}
									</div>
									{/* if the player is on team1, they played against team2 */}
									{data.playerObj.id === matchInfo.team1[0]['idA'] ||
									data.playerObj.id === matchInfo.team1[0]['idB'] ? (
										<div className="opponent">
											<div>{matchInfo.team2[0]['displayNameA']}</div>
											{/* if there is a second player on the team, display it */}
											{matchInfo.team2[0]['displayNameB'] ? (
												<div>{matchInfo.team2[0]['displayNameB']}</div>
											) : null}
										</div>
									) : (
										<div className="opponent">
											<div>{matchInfo.team1[0]['displayNameA']}</div>
											{matchInfo.team1[0]['displayNameB'] ? (
												<div>{matchInfo.team1[0]['displayNameB']}</div>
											) : null}
										</div>
									)}
								</div>
								{/* only show the expanded match view if the user clicks */}
								<i
									className={showExpandedMatch === index ? 'expanded-open-icon' : 'wim-icon-close'}
									onClick={() => toggleExpendedMatch(index)}
								/>
								{showExpandedMatch === index && (
									<div className="expanded-match-view">
										<div className="matchup-box">
											<div className="match-court">
												{matchInfo.courtName ? matchInfo.courtName : 'Court TBD'}
											</div>
											<span className="divider-dash"></span>
											<div className="player-matchup">
												<div className="team1">
													<div className="player1">
														<div className="headshot-wrapper">
															<Headshot type="round" id={matchInfo.team1[0].idA} bg={true} />
														</div>
														<div className="name">
															<span className="first-name">
																{matchInfo.team1[0]['firstNameA']}
															</span>
															<span className="last-name">
																{matchInfo.team1[0]['lastNameA']}
															</span>
														</div>
													</div>
													{/* only display second player if it exists */}
													{matchInfo.team1[0].idB && (
														<div className="player2">
															<div className="headshot-wrapper">
																<Headshot
																	type="round"
																	id={matchInfo.team1[0].idB}
																	bg={true}
																/>
															</div>
															<div className="name">
																<span className="first-name">
																	{matchInfo.team1[0]['firstNameB']}
																</span>
																<span className="last-name">
																	{matchInfo.team1[0]['lastNameB']}
																</span>
															</div>
														</div>
													)}
													{matchInfo.team1[0].won === true && (
														<div className="match-winner">
															<i className="wim-icon-wf-checkmark" />
															winner
														</div>
													)}
												</div>
												<span className="versus">vs</span>
												<div className="team2">
													<div className="player1">
														<div className="headshot-wrapper">
															<Headshot type="round" id={matchInfo.team2[0].idA} bg={true} />
														</div>
														<div className="name">
															<span className="first-name">
																{matchInfo.team2[0]['firstNameA']}
															</span>
															<span className="last-name">
																{matchInfo.team2[0]['lastNameA']}
															</span>
														</div>
													</div>
													{matchInfo.team2[0].idB && (
														<div className="player2">
															<div className="headshot-wrapper">
																<Headshot
																	type="round"
																	id={matchInfo.team2[0].idB}
																	bg={true}
																/>
															</div>
															<div className="name">
																<span className="first-name">
																	{matchInfo.team2[0]['firstNameB']}
																</span>
																<span className="last-name">
																	{matchInfo.team2[0]['lastNameB']}
																</span>
															</div>
														</div>
													)}
													{matchInfo.team2[0].won === true && (
														<div className="match-winner">
															<i className="wim-icon-wf-checkmark" />
															winner
														</div>
													)}
												</div>
											</div>
											{/* {getSlamtrackerButton(matchInfo.match_id, matchInfo)} */}
											<div className="slamtracker-button">
												<SlamtrackerButton
													match_id={matchInfo.match_id}
													slamtrackerActive={isSlamTrackerActive(matchInfo)}
													statusCode={matchInfo?.statusCode}
													styleClass={`green`}
												/>
											</div>
										</div>
										<div className="ptf-wrapper">
											<div className="path-to-final-header">
												Path to final
												<span className="divider-dash"></span>
											</div>
											<PathToTheFinalStandalone
												playerId={data.playerObj.id}
												event={matchInfo.eventCode}
												metricsPageName="Player Page"
												matchStatusUnknown={true}
											/>
										</div>
									</div>
								)}
							</div>
		)
	}

	return (
		<div className="status-page">
			<div className="events-entered">
				<SectionHeaderBar data={{ title: 'Events Entered' }} />
				{
					data?.playerStatus?.matches?.length > 0 ? (
						data?.playerStatus?.matches.map((matchInfo, index) => {
							return (
								matchInfo.match_id != '40000' ? (
										<>{ getMatchDisplay(matchInfo, index) }</>
								) : ( 
										<>{ getUnknownMatchDisplay(matchInfo, index) }</>
									)
							)
						})
					) : (
						<div className="events-not-released">Events will be updated after the Draw is released.</div>
					)
				}
			</div>
		</div>
	);
};

export default StatusTab;

import React from 'react';
import { Logger } from 'appdir/logger';
//import staticData from '../data/json/man/static';
import { getQuerystringValues } from 'appdir/components/general/Util';

window.logger = Logger;

logger.log('version: %o', window.version);
logger.log('commit: %o', window.commit);
logger.log('env: %o', process.env.NODE_ENV);
logger.log('react version: %o', React.version);

// export static values loaded from json file at build
export let values = {
	pageTitle: 'The Championships, Wimbledon - Official Site by IBM',
	pageDescription: 'Official homepage of The Championships, Wimbledon 2024.',
	tournamentYear: '2024',
	lang: 'en_GB',
	xmlLang: 'en_GB',
	siteUrl: 'www.wimbledon.com',
	canonicalHost: 'https://www.wimbledon.com',
	eventId: 'wimbledon-odd',
	configPath: '/en_GB/json/gen/config_web.json',
	auth: null,
	apiBalance: Math.floor(Math.random() * 100),
	scoreApi: 'undefined',
	countryHide: ['IOA'],
	slamtrackerButtonText: 'View',
	powerIndexTitle: 'IBM Power Index',
	matchInsightsTitle: 'IBM Match Insights',
	aiDrawTitle: 'IBM AI Draw Analysis',
};

if (window.webview) {
	console.log('[App] webview:%o', window.webview);
	values.webview = true;
}

let parsedQs = getQuerystringValues();
logger.log('querystring: %o', parsedQs);

if (parsedQs.config) {
	values.configPath = values.configPath.replace('.json', '.' + parsedQs.config + '.json');
}

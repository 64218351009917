/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import op from 'object-path';
import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { values } from 'appdir/main';

/**
 * -----------------------------------------------------------------------------
 * React Component: Config
 * -----------------------------------------------------------------------------
 */

export default class Config extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);

		//logger.log('[Config] constructor - state:%o', this.state);
	}

	componentDidMount() {
		//logger.log('[Config] componentDidMount');
		//this.props.update();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[Config] componentWillReceiveProps - nextProps:%o', nextProps);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(nextProps) {
		logger.log('[Config] componentDidUpdate - state:%o values:%o', this.state, values);

		if (values.scoreApi == 'undefined' && op.get(this.state, 'scoring.scoreApiBalance', null)) {
			// logger.log('[Config] componentDidUpdate - values.apiBalance:%o this.state.scoreApiBalance:%o', values.apiBalance, this.state.scoreApiBalance, typeof values.apiBalance, typeof this.state.scoreApiBalance);
			values.scoreApi = values.apiBalance < parseInt(this.state.scoring.scoreApiBalance) ? 'true' : 'false';
		}

		if (this.state.auth) {
			values.config = {
				auth: this.state.auth,
			};
		}

		//logger.log('[Config] componentDidUpdate - state:%o values:%o', this.state, values);

		if (this.state.status === 'expired') {
			this.props.update();
		}

		if (op.get(this.state, 'staticData', false)) {
			logger.log('[Config] componentDidUpdate - loadStaticList:%o', this.state.loadStaticList);
			for (let item in this.state.staticData) {
				//logger.log('[Config] componentDidUpdate - staticData:%o', this.state.staticData[item]);

				let loadStatic = this.state.loadStaticList[0] == 'all' || this.state.loadStaticList.includes(item);
				if (loadStatic) {
					let updated = op.get(this.state.staticData[item], 'updated', 0);
					let loadDiff = moment().diff(moment(updated), 'seconds', true);
					//expired if diff greater then refresh and
					//  has valid refresh time with data loaded
					let data = this.state[item] ? true : false;

					let expired = loadDiff >= this.state.staticData[item].refresh;
					if (expired && data && this.state.staticData[item].refresh < 0) {
						expired = false;
					}

					//logger.log('[Config] componentDidUpdate - staticData- item:%o data:%o expired:%o', item, this.state.staticData[item], expired);
					

					// if expired and not still loading, then load again
					//   (if there was an error loading, state remains loading and does not load again)
					if (expired && op.get(this.state.staticData[item], 'status', '') != 'loading') {
						logger.log('[Config] componentDidUpdate - staticData - loading:%o expired:%o', item, expired);
						this.props.updateStatic(item);
					}
				}
				else {
					logger.log('[Config] componentDidUpdate - staticData - skipping:%o', item, this.state.staticData[item]);
				}

				
			}
		}

		// if (this.state.staticLoaded) {
		// 	deps.services.Config.fetchStatic(result.staticData.menu).then((result) => {
		// 		logger.log('[Config] load static - result:%o', result);
		// 	});
		// }
	}

	render() {
		//logger.log('[Config] render');
		return null;
	}
}

import React from 'react';
import SecurePDF from 'appdir/components/cms/SecurePDF';
import SecureFile from 'appdir/components/cms/SecureFile';
import PDFReader from 'appdir/components/cms/PDFReader';

const CMS = ({ currentUser }) => {
	logger.log('[PDFs] currentUser:%o', currentUser);

	return (
		<div className="content-main column-layout news article ">
			<div class="four-col">
				<h3>SecureFile component</h3>

				<br />
				<br />
				<SecureFile
					data={{
						id: '205',
						text: 'secure/media/rightsholder/OOP_10_July_copy.pdf',
						link: 'secure/media/rightsholder/OOP_10_July_copy.pdf',
						type: 'pdf',
						style: 'two-col',
					}}
				/>
				<br />
				<br />
				<SecureFile
					data={{
						id: '204',
						text: 'secure/member/2024BoardsandCommitteesMembershipChampCo.pdf',
						link: 'secure/member/2024BoardsandCommitteesMembershipChampCo.pdf',
						type: 'pdf',
						style: 'two-col',
					}}
				/>
				<br />
				<br />
				<SecureFile
					data={{
						id: '203',
						text: 'secure/member/1.2_Grounds_Ch_Members.pdf',
						link: 'secure/member/1.2_Grounds_Ch_Members.pdf',
						type: 'pdf',
						style: 'two-col',
					}}
				/>
				<br />
				<br />
				<SecureFile
					data={{
						id: '202',
						text: 'secure/member/New%20Club%20Room%20Menu%20-%20Guest%20prices.pdf',
						link: 'secure/member/New Club Room Menu - Guest prices.pdf',
						type: 'pdf',
						style: 'two-col',
					}}
				/>
				<br />
				<br />
				<SecureFile
					data={{
						id: '200',
						text: 'secure/media/pdfSample.pdf',
						link: 'secure/media/pdfSample.pdf',
						type: 'pdf',
						style: 'two-col',
					}}
				/>
				<br />
				<br />
				<SecureFile
					data={{
						id: '201',
						text: 'secure/member/smadacnairb@gmail.com.ics',
						link: 'secure/member/smadacnairb@gmail.com.ics',
						type: 'ics',
						style: 'two-col',
					}}
				/>
				<br />
				<br />

				<h3>SecurePDF CMS component</h3>

				<br />
				<SecurePDF
					data={{
						id: '100',
						text: 'secure/media/pdfSample.pdf',
						link: 'secure/media/pdfSample.pdf',
						style: 'two-col',
					}}
				/>
				<br />
				<br />
				<SecurePDF
					data={{
						id: '101',
						text: 'player match stats',
						link: 'match/stats/2019/2019Wimbledon_BS103_PlayerReport.pdf',
						style: 'two-col',
					}}
				/>
			</div>
			<div class="four-col">
				<h3>PDFReader component</h3>

				<br />
				<PDFReader
					key="wowbook-flip"
					data={{
						link: 'https://www.wimbledon.com/en_GB/scores/draws/2019_MS_draw.pdf',
						style: 'four-col',
						text: 'Open PDF',
					}}
				/>
			</div>

			<div class="four-col">
				<PDFReader
					data={{
						showPDF: true,
						text: 'Mens Singles Draw',
						link: 'https://www.wimbledon.com/en_GB/scores/draws/2019_MS_draw.pdf',
					}}
				/>
			</div>

			<div class="four-col" style={{ height: '100vh' }}>
				<iframe
					src={`/en_GB/scores/draws/2022_MS_draw_search.pdf`}
					allowFullScreen={true}
					marginWidth="0"
					marginHeight="0"
					frameBorder="0"
					className="embed-responsive-item"
					width="100%"
					height="100%"
				/>
			</div>
		</div>
	);
};

/*
			<br/>
				<SecurePDF text="sentries" link="secure/media/0618LSENTRIES_2016.pdf" />

				<br/>
				<SecurePDF text="media/test-secure.pdf" link="secure/media/test-secure.pdf" />

				<br/>
				<SecurePDF text="media/rightsholder/test-secure-rh.pdf" link="secure/media/rightsholder/test-secure-rh.pdf" />

				<br/>
				<SecurePDF text="media/restricted_rightsholder/test-secure-rrh.pdf" link="secure/media/restricted_rightsholder/test-secure-rrh.pdf" />
				
		*/

export default CMS;

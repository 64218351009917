import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[JobsPage] action.mount - data:%o', data);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Jobs',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			data.dataPath = Config.cmsData.index.replace('<section>', 'jobs');
			dispatch({ type: deps.actionTypes.JOBSPAGE_MOUNT, data: data });
		});
	},

	unmount: () => (dispatch, getState, store) => {
		let data = {};
		data.dataPath = '';
		dispatch({ type: deps.actionTypes.JOBSPAGE_UNMOUNT, data: data });
	},
};

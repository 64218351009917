/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Stripes
 * -----------------------------------------------------------------------------
 */

class Stripes extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		let classes = [];

		if (this.props.color == 'green') {
			classes.push('greenstripe-container');
			if (this.props.margin) {
				classes.push('margin');
			}
			return (
				<div className={classes.join(' ')}>
					<div className="greenStripe" />
				</div>
			);
		} else if (this.props.color == 'purple') {
			classes.push('purplestripe-container');
			if (this.props.margin) {
				classes.push('margin');
			}
			return (
				<div className="">
					<div className="purpleStripe-thin" />
				</div>
			);
		}else if(this.props.color == 'white'){
			classes.push('whiteStripe-container');
			if (this.props.margin) {
				classes.push('margin');
			}

			let stripeClass = "whiteStripe"
			if (this.props.thin) {
				stripeClass += "-thin";
			}
			if (this.props.short) {
				stripeClass += "-short"
			}
			return (
				<div className={classes.join(' ')}>
					<div className={stripeClass} />
				</div>
			);
		}
	}
}

export default Stripes;

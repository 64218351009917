import React from 'react';
import Modal from '../../../../common-ui/Modal';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { BookingConfirmation, RetrieveAvailableTimes, AvailableTimes, useDiningContext } from './elements';

const ReservationForm = () => {
	const {
		times,
		loading,
		showBookingConfirmation,
		setShowBookingConfirmation,
		formIsSubmitted,
		resetFields,
	} = useDiningContext();
	return (
		<>
			<RetrieveAvailableTimes />
			{loading ? (
				<div className="four-col" style={{ position: 'relative', height: 200 }}>
					<LoadingIndicator />
				</div>
			) : times && times.length > 0 ? (
				<AvailableTimes />
			) : times ? (
				<div className="unavailable">
					<p>No availability during this shift.</p>
				</div>
			) : null}

			{showBookingConfirmation && !loading && formIsSubmitted && (
				<Modal
					toggleModal={() => {
						resetFields();
						setShowBookingConfirmation(!showBookingConfirmation);
					}}>
					<BookingConfirmation onCloseModal={() => setShowBookingConfirmation(false)} />
				</Modal>
			)}
		</>
	);
};

export default ReservationForm;


import axios from 'axios';
import op from 'object-path';
import { callInterfaceLink } from 'appdir/components/general/Util/index.js';

export function handleGetVirtualQueueStatusEndpoint(jwtToken, path) {
	logger.log('[virtualQueueEndpoints] handleGetVirtualQueueStatusEndpoint called');

    // /tickets/rest/queue/user/status - sends CIAM JWT as header Bearer token
	/* Response
		The configuration response will have an array of queues
		Each should be identified by the queue_id
		For now, the only value we should need is the queue_status, 0 indicates queue is open, 1 indicates queue is closed
	*/

	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken}`,
			Accept: 'application/json',
		};

		let body = {};

		logger.log('[Tickets] handleGetVirtualQueueStatusEndpoint - path:%o headers:%o body:%o', path, { headers });


		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[virtualQueueEndpoints] handleGetVirtualQueueStatusEndpoint - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] handleGetVirtualQueueStatusEndpoint - error:%o', error);
				reject(error);
			});
	});
}


export function handleExitVirtualQueueEndpoint(jwtToken, path, booking_id, queue_id) {
	logger.log('[virtualQueueEndpoints] handleExitVirtualQueueEndpoint called');

	// /tickets/rest/queue/user/exit - sends CIAM JWT as header Bearer token


	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken}`,
			Accept: 'application/json',
		};

		let body = {
            "bookingId": booking_id ? booking_id : null
			// "queueStatus": 1
			// booking_id,
			// queue_id
		};

		logger.log('[virtualQueueEndpoints] handleExitVirtualQueueEndpoint - path:%o headers:%o body:%o', path, { headers }, body);

		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[virtualQueueEndpoints] handleExitVirtualQueueEndpoint - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0) {
					try {
						callInterfaceLink('refreshUser');
					} catch (error) {
						console.error('Error parsing URL:', error);
					}
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] handleExitVirtualQueueEndpoint - error:%o', error);
				reject(error);
		});
	});
}
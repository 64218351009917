/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { values } from 'appdir/main';
import Button from 'appdir/components/common-ui/Button';
import { getLikelihoodWinner, checkUpsetAlert } from '../MatchInsightsUtils';
import MISectionHeader from './MISectionHeader';
import MITeaser from './MITeaser';
import { getAppLink } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: PowerIndexNumbers for Match Insights
 * -----------------------------------------------------------------------------
 */
const PowerIndexNumbers = props => {
	logger.log('[PowerIndexNumbers] - props:%o', props);

	let { powerIndexMatchupData, teams } = props;
	const isSlamtracker = props?.type == 'slamtracker';
	// let isUpset = checkUpsetAlert(powerIndexMatchupData, teams[0]['idA'], teams[1]['idA']);
	// let direction = getLikelihoodWinner(powerIndexMatchupData) == teams[0]['idA'] ? 'left' : 'right';

	const webviewNav = path => {
		window.location = path;
	};

	const getPowerIndexLBBtn = () => {
		let selectTab = teams[0]?.idA.includes('wta') ? 'wta' : 'atp';
		if (props.type == 'slamtracker') {
			if (values.webview) {
				let path = getAppLink(`/en_GB/powerindex/index.html?selectTab=${selectTab}`, '', false);
				return (
					<Button className={`border center rounded-corner`} onClick={() => webviewNav(path.url)}>
						{values.powerIndexTitle} Leaderboard
					</Button>
				);
			} else {
				return (
					<Button
						className={`border center rounded-corner`}
						onClick={() => props.nav({ path: `/en_GB/powerindex/index.html?selectTab=${selectTab}` })}>
						{values.powerIndexTitle} Leaderboard
					</Button>
				);
			}
		} else {
			return (
				<Button
					className={`border center rounded-corner`}
					to={`/en_GB/powerindex/index.html?selectTab=${selectTab}`}>
					{values.powerIndexTitle} Leaderboard
				</Button>
			);
		}
	};

	let klass = 'mi-section ';
	klass += props?.windowSize == 'mobile' || props?.windowSize == 'tablet' || isSlamtracker ? 'no-border-bottom' : '';

	if (powerIndexMatchupData[teams[0]?.idA]?.wpi_rank && powerIndexMatchupData[teams[1]?.idA]?.wpi_rank) {
		return (
			<div className={klass}>
				{!props?.hideTitle ? (
					<div className="above-tablet">
						<MISectionHeader title={`${values.powerIndexTitle}`} />
					</div>
				) : null}

				<div className="mi-pr-ranks-wrapper">
					{/* <MITeaser isUpset={isUpset} direction={direction} /> */}

					<div className="mi-pr-ranks">
						<div className="ranking-row">
							<div className="ranking-cell player1-pr-rank">
								{powerIndexMatchupData[teams[0]?.idA]?.wpi_rank}
								<span className="player-name">{`${teams[0]?.firstNameA}  ${teams[0]?.lastNameA}`}</span>
							</div>

							{/** mobile or slamtracker */}
							<div className="mobile-only">
								<div className="ranking-cell rank-label">{values.powerIndexTitle}</div>
							</div>

							{/* <div className='above-tablet'>
								<div className="ranking-cell rank-label">{ getPowerIndexLBBtn() }</div>
							</div> */}

							<div className="ranking-cell player2-pr-rank">
								{powerIndexMatchupData[teams[1]?.idA]?.wpi_rank}
								<span className="player-name">{`${teams[1]?.firstNameA}  ${teams[1]?.lastNameA}`}</span>
							</div>
						</div>
					</div>

					{/** App needs this link to be open in their webview - no need to use bypassAppLink */}
					{/** mobile or slamtracker */}
					<div className="mobile-only">
						<div className="ranking-cell rank-label">{getPowerIndexLBBtn()}</div>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default PowerIndexNumbers;

import PlayerPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	//order: 2,
	path: ['/:lang/players/overview/:player.html', '/:lang/players/overview/:player/:tabName.html'],
	exact: true,
	component: PlayerPage,
	section: MENU_ITEMS.players,
	load: params => deps.actions.PlayerPage.mount(params),
};

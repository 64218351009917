import React from 'react';

const Accordion = (props, context) => {
	let keyPress = (e) => {
		if (e.key === 'Enter') {
			props.clickFn(props.iteration)
		}
	}

	let clickHandler = (e) => {
		//logger.info('[Accordion] clickHandler - e:%o', e.currentTarget);
		e.currentTarget.blur();
		props.clickFn(props.iteration);
	}

	if(props.children !== null) {
		//logger.info('[Accordion] props:%o', props);
		const childElements = props.children.props.children;
		return (
			<div className={`accordion-container`}>
				<div className="greenLine" />
				<div className="more-content">
					<div 
						className={`accordion-title-container${props.accordOpen ? ' open': ' close'}`} 
						tabIndex="0" 
						role="button"
						aria-controls={`accordionContent-${props.children.key}`}
						aria-expanded={props.accordOpen}
						onClick={(e) => clickHandler(e)}
						onKeyPress={(e) => keyPress(e)} >
						<span className="more-content-container">
							<span className="accordion-toggle-symbol" style={{ display: !props.accordOpen ? 'inline-block' : 'none' }}>+</span>
							<span className="accordion-toggle-symbol" style={{ display: !props.accordOpen ? 'none' : 'inline-block' }}>-</span>
						</span>
						<span>{React.cloneElement(childElements[0])}</span>
					</div>
				</div>
				<div className="accordion-content">
					<div id={`accordionContent-${props.children.key}`} className={`accordion-content-data${props.accordOpen ? ' open': ' close'}`}>
						{React.cloneElement(childElements[1])}
					</div>
				</div>
			</div>
		);	
	} else {
		return null;
	}
};
export default Accordion;

import PlayerDigitalExperience from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/s/player/info/:page.html', '/s/player/info/:page'],
		exact: true,
		component: PlayerDigitalExperience,
		load: params => deps.actions.PlayerDigitalExperience.mount(params),
	},
];

import MyWimbledonLoginOIDC from './index';
import Login from './Login';
import deps from 'dependencies';

export default [
	// {
	// 	path: ['/:lang/mywimbledon/login'],
	// 	exact: true,
	// 	component: Login
	// },
	{
		path: '/:lang/mywimbledon/:action(login|logout|error|consent)?',
		exact: true,
		component: MyWimbledonLoginOIDC,
		load: params => deps.actions.MyWimbledonLoginOIDC.mount(params),
	},
];

import deps from 'dependencies';

export default (state = {}, action) => {
	switch (action.type) {
		case deps.actionTypes.BREAKPOINTPAGE_MOUNT:
			return {};
		default:
			return state;
	}
};

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = path => {
	let hdr = restHeaders();
	// perform an ajax call
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

const fetchAllResultData = path => {
	let hdr = restHeaders();
	// do all the ajax requests at one time.  pass in an arry of requests.  returns
	// and array of responses.  see the service call in index.js
	return axios.all([fetch(path.matchPath), fetch(path.daysPath)]);
};

export default {
	fetch,
	fetchAllResultData,
};

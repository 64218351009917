import DrawsArchiveTopStats from './index';
import deps from 'dependencies';

export default {
	path: '/:lang/draws_archive/topstats.html',
	exact: true,
	component: DrawsArchiveTopStats,
	section: 'Draws',
	load: params => deps.actions.DrawsArchiveTopStats.mount(params),
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { loggedIn } from 'appdir/components/general/Util/Role';
import op from 'object-path';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';

import Template from 'appdir/components/Template';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import { RoleRegistrationInviteContext } from './context';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import parse from 'html-react-parser';
import WimRedirect from 'appdir/components/general/WimRedirect';
import LoginWrapper from './LoginWrapper';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
import DebProspect from './elements/DebProspect';
import Media from './elements/Media';
import LTABallot from './elements/LTABallot';
import PlayerDelegate from './elements/PlayerDelegate';
import Player from './elements/Player';
import Ticket from './elements/Ticket';
import Tournament from './elements/Tournament';
import Transport from './elements/Transport';
import Coach from './elements/Coach';
import Generic from './elements/Generic';
import { roleFeatures } from './RoleFeatures';

/**
 * -----------------------------------------------------------------------------
 * React Component: RoleRegistrationInvite
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		userPreferences: state['Controller']['userPreferences'],
		...state['Gigya'],
		...state['RoleRegistrationInvite'],
		windowSize: state['PageHeader'].windowSize,
		tickets: state['Config'].tickets,
		debenture: state['Config'].debenture,
		pde: state['Config'].pde,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//mount: () => dispatch(deps.actions.Ballot.mount()),
	unmount: () => dispatch(deps.actions.RoleRegistrationInvite.unmount()),
	getAccountInfoProm: data => dispatch(deps.actions.Gigya.getAccountInfoProm(data)),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

const maxRetries = 2;

class RoleRegistrationInvite extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props, { complete: false });

		this.addRole = this.addRole.bind(this);
		this.addRoleError = this.addRoleError.bind(this);
		this.getStatus = this.getStatus.bind(this);

		this.loading = false;
		this.retries = 0;

		//logger.log('[RoleRegistrationInvite] constructor - state:%o', this.state);
	}

	componentDidMount() {
		logger.log('[RoleRegistrationInvite] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		logger.log('[RoleRegistrationInvite] componentWillUnmount - state:%o', this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[RoleRegistrationInvite] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let lastLink = prevState.link;
		if (nextProps.currentUser.UID != prevState.currentUser.UID) {
			lastLink = {};
		}

		let newState = {
			...nextProps,
			registration: {
				...prevState.registration,
				...nextProps.registration,
			},
			link: {
				...lastLink,
				...nextProps.link,
			},
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[RoleRegistrationInvite] componentDidUpdate - prev:%o state:%o', prevState, this.state);

		//let hasUser = loggedIn(op.get(this.state, 'currentUser', {}));
		let newUser =
			op.get(this.state, 'currentUser.UID', null) != null &&
			op.get(prevState, 'currentUser.UID', null) != op.get(this.state, 'currentUser.UID', null);
		if (newUser) {
			this.loading = false;
		}
		//logger.log('[RoleRegistrationInvite] componentDidUpdate - loading:%o newUser:%o', this.loading, newUser);

		if (
			!this.loading &&
			op.get(this.state, 'registration.urlrole', false) &&
			op.get(this.state, 'registration.guid', false) &&
			op.get(this.state, 'loggedIn', false) &&
			op.get(this.state, 'currentUser.UID', null) != null
		) {
			//logger.log('[RoleRegistrationInvite] componentDidUpdate - getStatus');
			this.getStatus();
		}
	}

	/**
	 * request to check the status of the role reg guid
	 *   response values are added to state
	 *   response values are used to validate the proper role being requested
	 *   retries up to X times
	 *   "code" is for use with code verification
	 */
	getStatus(code) {
		this.loading = true;

		this.props
			.getJWT()
			.then(token => {
				let data = {
					data: {
						code: code,
					},
				};

				let axiosConfig = {
					method: 'post',
					url: `${op.get(this.state, 'myWim.api.roleRegStatus', '')}${this.state.registration.guid}`,
					//url: '/en_GB/orch/roleregcheck.json',
					headers: { 'Content-type': 'application/json' },
					data,
				};

				// change the axios config if trying to add a ticket role
				if (this.state.registration.urlrole == 'ticket') {
					if (code == undefined || code == 'new') {
						axiosConfig = {
							method: 'post',
							url: `${op.get(this.state, 'tickets.api.roleRegStatus', '')}${
								this.state.registration.guid
							}`,
							//url: '/en_GB/orch/roleregcheck.json',
							headers: {
								'Content-type': 'application/json',
								Authorization: 'Bearer ' + token.jwt.id_token,
							},
						};

						if (code == 'new') {
							this.codeModal('send');
						}
					} else {
						axiosConfig = {
							method: 'post',
							url: `${op.get(this.state, 'tickets.api.roleRegVerify', '')}${code}`,
							//url: '/en_GB/orch/roleregcheck.json',
							headers: {
								'Content-type': 'application/json',
								Authorization: 'Bearer ' + token.jwt.id_token,
							},
						};
					}
				}

				// change the axios config if trying to add a lta ballot role
				if (this.state.registration.urlrole == 'ltaballot') {
					let requestUrl = op.get(this.state, 'myWim.registration.roleRegStatus', '');
					requestUrl = requestUrl.replace('<role>', 'ltaballot/');

					axiosConfig = {
						method: 'post',
						url: `${requestUrl}${this.state.registration.guid}`,
						//url: '/en_GB/orch/roleregcheck_lta.json',
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer ' + token.jwt.id_token,
						},
					};
				}

				// change the axios config if trying to add a player delegate role
				if (this.state.registration.urlrole == 'delegate') {
					let requestUrl = op.get(this.state, 'pde.api.roleRegStatus', '');
					requestUrl = requestUrl.replace('<role>', 'delegate/');

					axiosConfig = {
						method: 'get',
						url: `${requestUrl}${this.state.registration.guid}`,
						//url: '/en_GB/orch/roleregcheck_lta.json',
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer ' + token.jwt.id_token,
						},
					};
				}

				let request = axios(axiosConfig)
					.then(res => {
						//logger.log('[RoleRegistrationInvite] getStatus - res:%o retries:%o', res, this.retries);

						let data = { ...res.data };
						if (
							this.state.registration.urlrole == 'ticket' ||
							this.state.registration.urlrole == 'ltaballot' ||
							this.state.registration.urlrole == 'delegate'
						) {
							data.requestStatus = data.status;
						}
						//check for valid data
						//  retry up to max times if requestStatus does not exist in response
						//  return requestStatus of 'notfound' if no status found from request
						if (data && data.requestStatus) {
							if (code == 'new') {
								this.codeModal('success');
							}

							let reg = {
								...this.state.link,
								...data,
								//test data
								//"roleSegmentId": 1000251
							};
							logger.log('[RoleRegistrationInvite] getStatus - reg:%o', reg);
							this.setState({
								link: reg,
							});
						} else if (this.retries < maxRetries) {
							this.retries++;
							this.getStatus_timeout = setTimeout(() => {
								this.getStatus();
							}, 2000);
						} else {
							let reg = {
								...this.state.registration,
								...{ requestStatus: 'notfound' },
							};
							this.setState({
								link: reg,
							});
						}
					})
					.catch(error => {
						logger.error('[RoleRegistrationInvite] error fetching status data: %o', error);
						this.setState({
							registration: {
								requestStatus: 'error',
							},
						});
					});
			})
			.catch(error => {
				logger.error('[RoleRegistrationInvite] getStatus - error getting token');
				logger.error('[RoleRegistrationInvite] getStatus - %o', error);
				this.submitModal('error', 'E2125');
			});
	}

	/**
	 * called if error starting add process from role specific section, like ticket spectator registration
	 * @param {*} tags
	 */
	addRoleError(type, code) {
		this.submitModal(type, code);
	}

	/**
	 * Make call to orchestration for completing role registration process
	 */
	addRole(tags, spectatorName, useModal = true) {
		this.submitModal('send');

		this.props
			.getJWT()
			.then(token => {
				let data = {
					jwtToken: token.jwt.id_token,
				};

				//create axios calls to complete role registration
				//  tickets requires jwt since goes through tickets app
				let axiosConfig = {
					method: 'post',
					url: `${op.get(this.state, 'myWim.api.roleRegComplete', '')}${this.state.registration.guid}`,
					//url: '/en_GB/orch/roleregcomplete.json',
					data: data,
					headers: { 'Content-type': 'application/json' },
				};

				if (
					this.state.registration.urlrole == 'ticket' ||
					this.state.registration.urlrole == 'ltaballot' ||
					this.state.registration.urlrole == 'delegate'
				) {
					let requestUrl =
						this.state.registration.urlrole == 'ticket'
							? op.get(this.state, 'tickets.api.roleRegComplete', '')
							: this.state.registration.urlrole == 'ltaballot'
							? op
									.get(this.state, 'myWim.registration.roleRegComplete', '')
									.replace('<role>', 'ltaballot/')
							: '';

					if (this.state.registration.urlrole == 'delegate') {
						requestUrl = op.get(this.state, 'pde.api.roleRegComplete', '');
					}

					axiosConfig = {
						method: 'post',
						url: `${requestUrl}${this.state.registration.guid}`,
						//url: '/en_GB/orch/roleregcheck.json',
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer ' + token.jwt.id_token,
						},
					};

					if (this.state.registration.urlrole == 'ticket') {
						axiosConfig.data = this.getSpectatorName();
					}
				}

				// update Gigya data/subscriptions to set during registration
				let updates = settings.filter(s => {
					//logger.log('[RoleRegistrationInvite] addRole - roleId:%o s:%o', this.state.link.roleId, s);
					return s.id == this.state.link.roleId;
				});
				let commPrefs = op.get(this.state, 'user.preferences.CommunicationPreferences.entitlements', []);

				logger.log('[RoleRegistrationInvite] addRole - updates:%o', updates);
				logger.log('[RoleRegistrationInvite] addRole - commPrefs:%o', commPrefs);

				if (updates.length > 0) {
					data.updates = {};
					if (op.get(updates[0], 'subscriptions', null)) {
						data.updates.subscriptions = op.get(updates[0], 'subscriptions');
					}
					if (op.get(updates[0], 'communications', null) && commPrefs.length == 0) {
						data.updates.communications = op.get(updates[0], 'communications');
					}

					//add tags to subscription
					if (tags != undefined && data.updates.subscriptions) {
						for (let sub in data.updates.subscriptions) {
							logger.log('[RoleRegistrationInvite] addRole - sub:%o tags:%o', sub, tags);
							if (sub == Object.keys(tags)[0]) {
								data.updates.subscriptions[sub].email.tags = tags[sub].email.tags;
							}
						}
					}
				}

				//logger.log('[RoleRegistrationInvite] addRole - data:%o', data);
				//logger.log('[RoleRegistrationInvite] addRole - state:%o', this.state);

				//make axios calls
				//  get response from complete call to continue registering spectatorId if need
				//  requires data.email from response
				let request = axios(axiosConfig)
					.then(res => {
						logger.log('[RoleRegistrationInvite] success adding role: %o', res);
						MeasurementUtils.dispatchMeasurementCall('addRole', { role: res });

						//check for valid data
						if (
							op.get(res, 'data.status', '') == 'complete' ||
							op.get(res, 'data.requestStatus', '') == 'complete'
						) {
							//call this with Promise so only complete when getAccountInfo is complete and we know have the role added
							this.props
								.getAccountInfoProm({ src: 'roleRegInvite' })
								.then(response => {
									logger.log('[RoleRegistrationInvite] addRole-register - data:%o', data);
									this.submitModal('success');
									this.setState({
										complete: true,
									});
								})
								.catch(error => {});
						} else if (op.get(res, 'data.status', '') == 'failure') {
							this.submitModal('error', 'E2400');
						} else if (op.get(res, 'data.status', '') == 'association_failure') {
							this.submitModal('error', 'E2300');
						} else if (op.get(res, 'data.status', '') == 'registration_failure') {
							this.submitModal('error', 'E2310');
						} else if (op.get(res, 'data.status', '') == 'failure_token') {
							this.submitModal('error', 'E2200');
						} else if (op.get(res, 'data.status', '') == 'failure_spectator') {
							this.submitModal('error', 'E2210');
						} else if (op.get(res, 'data.status', '') == 'invalid') {
							this.submitModal('error', 'E2120');
						} else if (op.get(res, 'data.status', '') == 'error') {
							this.submitModal('error', 'E2110');
						} else {
							this.submitModal('error', 'E2100');
						}
						/*
							complete = great success
							failure = Gigya failure
							association_failure = Aegis Patch Status Failed, role not assigned
							registration_failure = Aegis Delegate Registration failed, role not assigned
							invalid = Email doesn't match profile
							error = Link not valid, doesn't exist in DB
						*/
					})
					.catch(error => {
						logger.error('[RoleRegistrationInvite] error adding role data: %o', error);
						this.submitModal('error', 'E2110');
					});
			})
			.catch(error => {
				logger.error('[RoleRegistrationInvite] addRole - error getting token');
				logger.error('[RoleRegistrationInvite] addRole - %o', error);
				this.submitModal('error', 'E2120');
			});
	}

	/**
	 * Manage the modal for displaying submission status
	 * @param {Object} state
	 */
	submitModal(state, passedCode) {
		if (state == 'send') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'ADDING ROLE...',
				message: 'Your profile is updating. Please do not close your browser window while updating.',
				useTimer: false,
			});
		} else if (state == 'success') {
			this.props.showModal({
				showModal: false,
			});
		} else if (state == 'error') {
			//logger.log('[RoleRegistrationInvite] updateModal - state: %o', state);

			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: 'ADDING ROLE...',
				message: 'There was an error adding your role. Please try again.',
				code: passedCode ? passedCode : null,
				useTimer: true,
			});
		} else if (state == 'error_ticket') {
			//logger.log('[RoleRegistrationInvite] updateModal - state: %o', state);

			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: 'ADDING ROLE...',
				message: 'There was an error completing your registration.',
				code: passedCode ? passedCode : null,
				useTimer: true,
			});
		}
	}

	/**
	 * Manage the modal for requesting new code
	 * @param {Object} state
	 */
	codeModal(state) {
		if (state == 'send') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'SENDING NEW CODE',
				message: 'A new code is being generated. Check your email.',
				useTimer: false,
			});
		} else if (state == 'success') {
			this.props.showModal({
				showModal: false,
			});
		}
	}

	/**
	 * check if the requested role from the url matches the assigned from received from the status check
	 */
	isValid(allowedRoles, urlrole, assignedRole, status) {
		if (status)
			logger.log(
				'[RoleRegistrationInvite] isValid - allowedRoles:%o assignedRole:%o',
				allowedRoles[urlrole],
				assignedRole
			);
		if (
			status &&
			allowedRoles[urlrole] === assignedRole &&
			(status.toLowerCase() === 'active' || status.toLowerCase() === 'code')
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * check if user already has the assigned role for the request
	 */
	hasRole(assignedRole) {
		let userRole = op.get(this.state, 'currentUser.roles', []).filter(d => d.roleId == assignedRole);
		return userRole.length > 0;
	}

	/**
	 * get the role specific elements
	 * if link guid was not found, no assignedRole is available, display error with requestStatus set manually
	 * @param {*} assignedRole
	 */
	getRoleElement(assignedRole) {
		logger.log(
			'[RegistrationInvite] getRoleElement roles:%o, urlRole:%o',
			this.state.registration.roles,
			this.state.registration.urlrole
		);
		if (this.state.registration.urlrole == 'deb_prospect') {
			return <DebProspect issue={this.state?.debenture?.issue}></DebProspect>;
		} else if (this.state.registration.urlrole == 'media') {
			return <Media></Media>;
		} else if (this.state.registration.urlrole == 'player') {
			return <Player></Player>;
		} else if (this.state.registration.urlrole == 'ticket') {
			return <Ticket></Ticket>;
		} else if (this.state.registration.urlrole == 'ltaballot') {
			return <LTABallot></LTABallot>;
		} else if (this.state.registration.urlrole == 'delegate') {
			return <PlayerDelegate></PlayerDelegate>;
		} else if (this.state.registration.urlrole == 'tournament') {
			return <Tournament></Tournament>;
		} else if (this.state.registration.urlrole == 'transport') {
			return <Transport></Transport>;
		} else if (this.state.registration.urlrole == 'coach') {
			return <Coach></Coach>;
		} else if (this.state.registration.roles[this.state.registration.urlrole]) {
			return <Generic role={this.state.registration.urlrole}></Generic>;
		} else {
			return (
				<Fragment>
					<div className="two-col margin-col">
						Unfortunately your registration code is invalid or has expired. Please try again.
					</div>
					<div className="two-col margin-col">
						If the problem persists <WimLink to="/en_GB/contact/index.html">contact us</WimLink>.
					</div>
					{this.state?.link?.requestStatus != 'null' ? (
						<div className="two-col margin-col oidc_message">
							Message: {`${this.state.link.requestStatus}`}
						</div>
					) : null}
				</Fragment>
			);
		}
	}

	getSpectatorName() {
		let spectatorName =
			op.get(this, 'state.link.spectator.firstName', null) && op.get(this, 'state.link.spectator.lastName', null)
				? {
						firstName: op.get(this, 'state.link.spectator.firstName', null),
						lastName: op.get(this, 'state.link.spectator.lastName', null),
				  }
				: null;
		if (spectatorName == null) {
			spectatorName = {
				firstName: op.get(this, 'state.currentUser.profile.firstName', null),
				lastName: op.get(this, 'state.currentUser.profile.lastName', null),
			};
		}

		return spectatorName;
	}

	render() {
		logger.log('[RoleRegistrationInvite] render - state:%o', this.state);

		let style = {
			style: 'four-col',
		};

		let roleContent = roleFeatures.filter(r => {
			return r.ids.includes(op.get(this.state, 'link.roleId', null));
		});
		let segmentContent = roleFeatures.filter(r => {
			return r.id === op.get(this.state, 'link.roleSegmentId', null);
		});
		roleContent = roleContent.length >= 1 ? roleContent[0] : { name: '' };
		// fall back to check for role if matching role segment not found
		if (segmentContent.length == 0) {
			segmentContent = roleContent;
		} else {
			segmentContent = segmentContent.length >= 1 ? segmentContent[0] : null;
		}

		//use just titles based on urlrole if available
		//  never really used per segment tiles
		if (titles[this.state.registration.urlrole]) {
			segmentContent.pageTitle = titles[this.state.registration.urlrole];
		}

		let header_propsData = {
			headerType: 'generic_register',
			title: segmentContent.pageTitle ? segmentContent.pageTitle : 'Role Registration',
			shortTitle: segmentContent.pageTitle ? segmentContent.pageTitle : 'Role Registration',
			metaTitle: segmentContent.pageTitle ? `${segmentContent.pageTitle} - Registration` : 'Role Registration',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		};

		let contextValues = {
			userEmail: `${op.get(this.state, 'currentUser.profile.email', '')}`,
			spectatorId: this.state?.link?.email,
			isValid: this.isValid(
				this.state.registration.roles,
				this.state.registration.urlrole,
				this.state.link.roleId,
				this.state.link.requestStatus
			),
			status: op.get(this.state, 'link.requestStatus', ''),
			tickets: op.get(this.state, 'link.tickets', null),
			hasRole: this.hasRole(this.state.link.roleId),
			userRoles: op.get(this.state, 'currentUser.roles', []),
			segmentId: op.get(this.state, 'link.roleSegmentId', ''),
			addRole: this.addRole,
			addRoleError: this.addRoleError,
			getStatus: this.getStatus,
			complete: this.state.complete,
			linkId: this.state.link.linkId,
			playerLookup: op.get(this.state, 'pde.api.playerLookup', ''),
		};
		//logger.log('[RoleRegistrationInvite] render - contextValues:%o', contextValues);
		//logger.log('[RoleRegistrationInvite] render - propsData:%o', header_propsData);

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main content role-registration" id="main" tabIndex="0">
					<RoleRegistrationInviteContext.Provider value={contextValues}>
						<div className="column-layout content role-registration">
							{!this.state.gigyaLoaded ? (
								<LoadingIndicator />
							) : !this.state.loggedIn && this.state.gigyaLoaded ? (
								<Fragment>
									<div className="four-col body">
										Please login or register with <MyWimbledonTitle /> to continue.
										<br />
										<br />
									</div>
									<div className="four-col bottom-margin">
										<LoginWrapper />
									</div>
								</Fragment>
							) : op.get(this.state, 'registration.requestStatus', '') == 'error' ? (
								<Fragment>
									<div className="two-col margin-col">
										Unfortunately there was an error with your registration. Please try again.
									</div>
									<div className="two-col margin-col">
										If the problem persists{' '}
										<WimLink to="/en_GB/contact/index.html">contact us</WimLink>.
									</div>
									{this.state?.link?.requestStatus != 'null' ? (
										<div className="two-col margin-col oidc_message">Message: {`status error`}</div>
									) : null}
								</Fragment>
							) : !op.has(this.state, 'link.requestStatus') ? (
								<LoadingIndicator />
							) : (
								<Fragment>{this.getRoleElement(this.state.link.roleId)}</Fragment>
							)}
						</div>
					</RoleRegistrationInviteContext.Provider>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleRegistrationInvite);

const settings = [
	{
		id: 1000250,
		subscriptions: {
			'media.transcripts': {
				email: {
					isSubscribed: true,
				},
			},
			'media.courtChanges': {
				email: {
					isSubscribed: true,
				},
			},
			'media.orderOfPlay': {
				email: {
					isSubscribed: true,
				},
			},
			'media.mediaReleases': {
				email: {
					isSubscribed: true,
				},
			},
			'media.dailyUpdates': {
				email: {
					isSubscribed: true,
				},
			},
		},
	},

	{
		id: 1000260,
		subscriptions: {
			debentures: {
				email: {
					isSubscribed: true,
					tags: [],
				},
			},
		},
		communications: ['email', 'phone', 'sms'],
	},

	{
		id: 1000300,
		subscriptions: {
			'tournament.orderOfPlay': {
				email: {
					isSubscribed: true,
				},
			},
			'tournament.dailyUpdates': {
				email: {
					isSubscribed: true,
				},
			},
		},
		communications: ['email', 'phone', 'sms'],
	},
];

const titles = {
	media: 'Media',
	player: 'Player',
	ltaballot: 'LTA Ballot',
	ticket: 'Accept Tickets',
	delegate: 'Player Delegate',
	tournament: 'Tournament',
	transport: 'Transport',
	coach: 'Coach',
};

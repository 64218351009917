import BreakpointPage from './index';
import deps from 'dependencies';

export default [
	{
		path: '/en_GB/smash/index.html',
		exact: true,
		component: BreakpointPage,
		load: () => deps.actions.BreakpointPage.mount(),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import { connect } from 'react-redux';
import deps from 'dependencies';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { Formik, Form } from 'formik';
import { FormInput, FormSelect, FormTextarea, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import { countryLookup } from 'appdir/components/general/Util';
import { values } from 'appdir/main';
import * as yup from 'yup';
import Button from 'appdir/components/common-ui/Button';
import { osName, osVersion, browserName, fullBrowserVersion, getUA } from 'react-device-detect';
import { getQuerystringValues } from 'appdir/components/general/Util';
import op from 'object-path';
import ThankYouMessage from './ThankYouMessage';
import { callFunction } from 'appdir/components/general/Util/Services';
import isEmpty from 'lodash/isEmpty';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
import LoginWrapper from '../../pages/RoleRegistrationInvite/LoginWrapper';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledonDelete Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MyWimbledonDelete'],
		windowSize: state['PageHeader'].windowSize,
		services: state['Config'].services,
		...state['Gigya'],
		...props,
	};
};
const mobileMatch = window.matchMedia('(max-width: 767px)');

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MyWimbledonDelete.mount()),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class MyWimbledonDelete extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.countries = countryLookup;
		this.initialValues = {};
		this.handleSubmit = this.handleSubmit.bind(this);

		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
		this.gtk = op.get(parsedQs, 'gtk', null);
		this.myw = op.get(parsedQs, 'enablemyw', null);
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[MyWimbledonDelete] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let lastLink = prevState.link;
		if (nextProps.currentUser.UID != prevState.currentUser.UID) {
			lastLink = {};
		}

		let newState = {
			...nextProps,
			registration: {
				...prevState.registration,
				...nextProps.registration,
			},
			link: {
				...lastLink,
				...nextProps.link,
			},
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidMount() {
		const urlVars = this.getUrlVars();
		this.setState({ queryParams: urlVars });
		if (this.state.showThankYouMessage) this.setState({ showThankYouMessage: false });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	handleSubmit(form_values, actions) {
		actions.setSubmitting(false);

		//set additional form values
		form_values.eventid = values.eventId;
		form_values.os = osName + ' ' + osVersion;
		form_values.apptype = browserName + ' ' + fullBrowserVersion;
		form_values.userAgent = getUA;

		//add query param values as form values
		form_values = { ...form_values, ...this.state.queryParams };

		//remove gtk from form values
		delete form_values['gtk']; // pass all query params except for gtk

		let FD = [];
		let sendValues = Object.assign({}, form_values);
		delete sendValues.emailconfirm;
		Object.keys(sendValues).map((key, index) => {
			let val = encodeURIComponent(sendValues[key]).replace(/[!'()*]/g, escape);
			FD.push(key + '=' + val);
		});
		let headers = { 'Content-type': 'application/x-www-form-urlencoded' };
		let sendData = FD.join('&');

		callFunction(
			op.get(this.state, 'services.hosts', []),
			op.get(this.state, 'services.guestbook', ''),
			'post',
			sendData,
			headers
		)
			.then(res => {
				if (res?.status == 'success') {
					this.setState({ showThankYouMessage: true });
				} else {
					// show error message
					this.props.showModal({
						showModal: true,
						showCloseBtn: true,
						type: 'fail',
						title: 'ERROR',
						message: 'There was an error with your request. Please try again.',
						useTimer: false,
					});
					//actions.resetForm();
				}
			})
			.catch(error => {
				this.props.showModal({
					showModal: true,
					showCloseBtn: true,
					type: 'fail',
					title: 'ERROR',
					message: 'There was an error with your request. Please try again.',
					useTimer: false,
				});
			});
	}

	renderInputField(label, name, placeholder, type, showError, val, isRequired, isDisabled) {
		return (
			<FormInput
				label={label}
				name={name}
				placeholder={placeholder}
				type={type}
				showerror={showError}
				value={val}
				required={isRequired}
				disabled={isDisabled}
			/>
		);
	}

	renderForm(formProps) {
		logger.log('[MyWimbledonDelete] This is the formprops %o', formProps);
		logger.log('[MyWimbledonDelete] state:%o', this.state);

		let isChecked = formProps.values.agreement;

		if (!this.state.gigyaLoaded) {
			return <LoadingIndicator />;
		} else if (!this.state.loggedIn && this.state.gigyaLoaded) {
			return (
				<Fragment>
					<div className="four-col body" style={{ textAlign: 'center' }}>
						Please login or register with <MyWimbledonTitle /> to continue.
						<br />
						<br />
					</div>
					<div className="four-col bottom-margin">
						<LoginWrapper />
					</div>
				</Fragment>
			);
		}

		return (
			<Form onSubmit={formProps.handleSubmit}>
				<div className="name-bar column-layout">
					<div className="field two-col contact-us-input-field">
						{this.renderInputField(
							'First Name',
							'firstName',
							'First Name',
							'text',
							'true',
							formProps.values.firstName || '',
							true,
							true
						)}
					</div>
					<div className="field two-col contact-us-input-field">
						{this.renderInputField(
							'Surname',
							'surname',
							'Surname',
							'text',
							'true',
							formProps.values.surname || '',
							true,
							true
						)}
					</div>
				</div>

				<div className="email-bar">
					<div className="field two-col contact-us-input-field">
						{this.renderInputField(
							'Email',
							'email',
							'Email',
							'email',
							'true',
							formProps.values.email || '',
							true,
							true
						)}
					</div>
					<div className="fields two-col contact-us-input-field">
						{this.renderInputField(
							'Telephone Number',
							'telephoneNumber',
							'Telephone Number',
							'tel',
							'true',
							formProps.values.telephoneNumber || ''
						)}
					</div>
				</div>
				<div className="topic-subj-bar">
					<div className="field two-col contact-us-input-field">
						<FormSelect
							label="Topic"
							name="topic"
							value={'delete_account'}
							showerror={'true'}
							required
							disabled={true}>
							{
								<option key={'Delete myW Account Request'} value={'Delete myW Account Request'}>
									Delete myW Account Request
								</option>
							}
						</FormSelect>
					</div>
				</div>

				<div className="message-bar">
					<div className="field four-col contact-us-input-field">
						<FormTextarea
							label="Message"
							name="comment"
							type="textarea"
							rows="10"
							value={formProps.values.comment || ''}
							required
						/>
					</div>
				</div>

				<div className="form-opt-in">
					<FormCheckBox name="agreement" id="agreement" />
					<div className="opt-in-text">
						{' '}
						Please tick here to confirm you have read and understood how the All England Club will use your
						data: <WimLink to="/en_GB/about_wimbledon/privacy_policy.html">AELTC Privacy Policy</WimLink>
					</div>
				</div>

				<div className="contact-form-submit">
					<Button
						className={`wide button solid${
							formProps.isSubmitting ||
							(isChecked !== 'true' && !isChecked) ||
							formProps.submitCount > 0 ||
							!formProps.values.comment
								? ' disabled'
								: ''
						}`}
						onClick={() =>
							!formProps.isSubmitting && (isChecked == 'true' || isChecked) && formProps.submitCount === 0
								? formProps.submitForm()
								: null
						}>
						Submit
					</Button>
				</div>
			</Form>
		);
	}

	// Get the URL & Params, Break them Down
	getUrlVars() {
		let vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
			vars[key] = value;
		});
		return vars;
	}

	render() {
		logger.log('[MyWimbledonDelete] render - state:%o', this.state);

		let ready = true;
		if ((this.gtk || this.myw) && !this.state.loggedIn) {
			ready = false;
		}

		let header_propsData = {
			headerType: 'delete',
			title: 'Delete MyWimbledon Account',
			shortTitle: 'Delete MyWimbledon Account',
			metaTitle: 'Delete MyWimbledon Account',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		if (mobileMatch.matches == true) {
			header_propsData = {
				headerType: 'delete',
				title: 'Delete Account',
				shortTitle: 'Delete Account',
				metaTitle: 'Delete Account',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};
		}

		this.initialValues = {
			salutation: '',
			firstName: '',
			surname: '',
			email: '',
			dateOfBirth: '',
			postCode: '',
			country: '',
			topic: '',
			subject: '',
			comment: '',
			agreement: false,
		};

		//checking to see if user is logged in
		//to prepopulate contact form data
		if (this.state.loggedIn) {
			const { firstName, lastName, email } = this.state.currentUser.profile;
			const { title } = this.state.currentUser.data.profile;
			this.initialValues['salutation'] = title;
			this.initialValues['firstName'] = firstName;
			this.initialValues['surname'] = lastName;
			this.initialValues['email'] = email;
			this.initialValues['topic'] = 'delete_account';
		} else if (!this.gtk && !this.myw) {
			let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
			this.initialValues['firstName'] = op.get(parsedQs, 'fname', null);
			this.initialValues['surname'] = op.get(parsedQs, 'lname', null);
			this.initialValues['email'] = op.get(parsedQs, 'lname', null);
		}

		let MyWimbledonDeleteSchema = yup.object().shape({
			comment: yup.string().required('Please supply content for your Message.'),
			agreement: yup
				.bool()
				.required('Please comfirm you have read and understood how the All England Club will use your data.'),
		});

		let componentToRender = !ready ? (
			<div className="content-main">
				<LoadingIndicator />
			</div>
		) : this.state?.showThankYouMessage ? (
			<div className="content-main">
				<ThankYouMessage />
			</div>
		) : (
			<div className="content-main column-layout contact-us">
				<div className="contact-form">
					<Formik
						enableReinitialize
						initialValues={this.initialValues}
						onSubmit={this.handleSubmit}
						validationSchema={MyWimbledonDeleteSchema}>
						{formProps => {
							return this.renderForm(formProps);
						}}
					</Formik>
				</div>
			</div>
		);

		return (
			<Template className="contactpage">
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />
				{componentToRender}
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWimbledonDelete);

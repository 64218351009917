(function() {
	if (typeof window.CustomEvent === 'function') return false;

	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: null };
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;

	window.CustomEvent = CustomEvent;
})();

const ACTION_TYPES = {
	pageView: 'pageView',
	onPageClick: 'onPageClick',
};

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const WEEKDAY = ['Weekend', 'Weekday', 'Weekday', 'Weekday', 'Weekday', 'Weekday', 'Weekend'];

const getTimeSlot = (date = new Date()) => {
	let currentMinutes = date.getMinutes();
	let displayMinutes = '45';

	if (currentMinutes >= 0 && currentMinutes < 15) {
		displayMinutes = '00';
	} else if (currentMinutes >= 15 && currentMinutes < 30) {
		displayMinutes = '15';
	} else if (currentMinutes >= 30 && currentMinutes < 45) {
		displayMinutes = '30';
	}

	let currentHours = date.getHours();
	let amPm = ' AM';
	let displayHours = currentHours == 0 ? 12 : currentHours;
	if (currentHours > 12) {
		displayHours = currentHours - 12;
		amPm = ' PM';
	}

	return displayHours + ':' + displayMinutes + amPm;
};

const prepareDataLayerObject = () => {
	let date = new Date();

	let path = location.pathname.replace(/\/$/, '');

	let props = window.dataLayer ? window.dataLayer.props : {};

	let dataLayer = {
		props: { ...props },
		url: {
			path: path,
			queryString: location.search,
			fullUrl: location.href.replace(/\/$/, ''),
			fullPath: path + location.search,
		},
		dateProperties: {
			dayOfWeek: DAYS_OF_WEEK[date.getDay()],
			weekday: WEEKDAY[date.getDay()],
			timeSlot: getTimeSlot(date),
		},
		language: 'en_GB',
	};
	return dataLayer;
};

export default class MeasurementUtils {
	static dispatchMeasurementCall(actionType, actionData, visitData = null) {
		// Set up the standard values
		let dataLayer = prepareDataLayerObject();

		// Spread in the custom values
		dataLayer = {
			...dataLayer,
			actionData: actionData,
			actionType: actionType,
		};
		if (visitData) {
			dataLayer = {
				...dataLayer,
				visitData: visitData,
			};
		}

		// Place on window object so it can be pulled at by the Adobe Tag Manager
		window.dataLayer = dataLayer;
		logger.log('[Measurement] - Data Layer Properties: %o', window.dataLayer);

		let event = new CustomEvent('dispatch_measurement', { detail: { dataLayer } });
		window.dispatchEvent(event);
	}

	static dispatchMeasurementEventDetailsCall(eventDetails, props) {
		let dataLayer = {
			...eventDetails,
		};

		if (dataLayer?.actionType == 'pageView' && !dataLayer?.visitData) {
			let hasFavorites = props?.Controller?.favourites?.players || [];
			let loggedIn = props?.Gigya?.loggedIn;
			let UID_value = props?.Gigya?.currentUser?.UID;

			// logger.log('[dispatchMeasurementEventDetailsCall] props?.Gigya= %o',props?.Gigya)

			let visitDataValue = {
				hasFavorites: hasFavorites,
				loggedIn: loggedIn,
				UID_value: UID_value,
			};
			// logger.log('[dispatchMeasurementEventDetailsCall] visitDataValue= %o',visitDataValue)

			dataLayer = {
				...dataLayer,
				visitData: visitDataValue,
			};
		}

		logger.log('[dispatchMeasurementEventDetailsCall] dataLayer= %o', dataLayer);

		let event = new CustomEvent('measureEvent', dataLayer);
		document.dispatchEvent(event);
	}

	static get ACTION_TYPES() {
		return ACTION_TYPES;
	}
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import EventSelect from './EventSelect';
import WimLink from 'appdir/components/general/WimLink';
import Template from 'appdir/components/Template';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsArchiveTopStats'], props);
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.DrawsArchiveTopStats.mount()),
	filter: settings => dispatch(deps.actions.DrawsArchiveTopStats.filter(settings)),
});

const statsDropdownData = [
	{ value: 'winners_no_lose_set', name: 'Winners without losing a set' },
	{ value: 'matches_played', name: 'Matches played' },
	{ value: 'matches_in_main_events', name: 'Matches played in main events' },
	{ value: 'most_games_in_match', name: 'Most games in a match' },
	{ value: 'most_games_in_final', name: 'Most games in a final' },
	{ value: 'most_fifth_set_wins', name: 'Most fifth set wins' },
	{ value: 'best_tie_break_record', name: 'Best tie-break record' },
	{ value: 'comaback_from_two_sets', name: 'Comeback from two sets to love' },
	{ value: 'number_of_sets_and_games', name: 'Number of sets and games' },
];

const statsNames = [
	{
		ms: "Gentlemen's Singles",
		ls: "Ladies' Singles",
		md: "Gentlemen's Doubles",
		ld: "Ladies' Doubles",
		mx: 'Mixed Doubles',
		g: 'Gentlemen',
		l: 'Ladies',
	},
];

class DrawsArchiveTopStats extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
		};

		this.statsDropdownVals = { options: [] };

		logger.log('[DrawsArchiveTopStats] props:%o', props);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[DrawsArchiveTopStats] componentWillReceiveProps - state:%o, nextProps:%o', this.state, nextProps);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		logger.log('[DrawsArchiveTopStats] componentDidUpdate - state:%o', this.state);

		if (!this.state.statsData && this.state.statsDataPath) {
			let dataPath = this.state.statsDataPath;
			deps.services.DrawsArchiveTopStats.fetch(dataPath).then(result => {
				this.setState({
					statsData: result.historical,
				});
			});
		}
	}

	renderTableData(data) {
		let count = 0;
		return (
			<div className="datatable">
				<div className="title">{this.state.filters.title}</div>
				<div className="row">
					{Object.keys(data).map((keyName, keyIndex) => {
						let stat = data[keyName];
						return (
							<div key={keyIndex} className={'cell data' + (keyIndex + 1) + ' ' + keyName + '_wrapper'}>
								<div className="link">
									<WimLink to={`${stat.pdf}`} external={true} title="stats PDF">
										{statsNames[0][keyName]} <i className="wim-icon-pdf" />
									</WimLink>
								</div>
								{stat.data.map(item => {
									return (
										<div key={`${count++} + ${item}`} id={`${count++} + ${item}`}>
											{item.toString()}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	render() {
		logger.log('[DrawsArchiveTopStats] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'drawsArchive',
			title: 'Draws Archive, Top Stats',
			imageSrc: '/assets/images/headers/drawsArchive_topstats.jpg',
			metaTitle: 'Draws Archive, Top Stats',
			metaDescription: 'Player profile archive information for The Championships, Wimbledon.',
			metaDate: '',
			metaPlayers: '',
		};

		if (this.state.statsData) {
			var tableRowData = this.state.statsData[this.state.filters.selected];
			logger.log('[DrawsArchiveTopStats] render - tableRowData:%o', tableRowData);
		}

		return (
			<Template className="drawsarchive">
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />
				<div className="drawsArchiveHdrContent">
					<h1>Top Stats</h1>
					<ArchiveMenu location={this.state.location.pathname} />
				</div>

				{this.state.statsData ? (
					<div className="content-main">
						<EventSelect attributes={statsDropdownData} filters={this.state.filters} />

						<div className="col-4">
							<div className="draws-archive-stats-table">{this.renderTableData(tableRowData)}</div>
						</div>
					</div>
				) : (
					<div className="content-main">
						<LoadingIndicator />
					</div>
				)}
				<Footer />
			</Template>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveTopStats);

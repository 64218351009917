import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import { forceCheck } from 'react-lazyload';

/**
 * ------------------------------------------------
 * Functional Component: LazyHomeFeedPlaceHolder
 * ------------------------------------------------
 **/
class LazyHomeFeedPlaceHolder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		// forceCheck();
	}

	render() {
		return <div className="homepagefeed-placeholder" style={{ height: '500px' }} />;
	}
}

export default LazyHomeFeedPlaceHolder;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import { getQuerystringValues } from 'appdir/components/general/Util';
import PathToTheFinal from 'appdir/components/common-ui/PathToTheFinal';
// MS, N. Djokovic - atpd643
// MD, C. Taberner - atpte16 // out first round example
// MD, R. Rham - atpr548
// BS, P. Rodenas- atpr0h2
// LS, C. Garcia - wta315391
// LD, S. Zhang - wta311779

const init_data = {
	event: 'MS',
	id: 'atpd643',
};

const idLookUp = {
	MS: 'atpd643',
	MD: 'atpr548',
	LS: 'wta315391',
	LD: 'wta311779',
	MX: 'atpe690',
	SM: 'itf800231597', // wheelchair gentlemen's singles
	BS: 'atpr0h2',
};

const PathToTheFinalTest = props => {
	const DrawsPageData = useSelector(state => state['DrawsPage']);
	const pathToTheFinal = useSelector(state => state['PathToTheFinal']);

	const parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);

	const dispatch = useDispatch();

	/** pass eventCode and / or player id to test specfic event and/or player
	 *  the player ID needs to be defined above in idLookUp for default player ID
	 *  if player ID is not passed via query string
	 */
	let event = parsedQs?.event ? parsedQs.event : init_data.event;
	let id = parsedQs?.id ? parsedQs.id : idLookUp?.[event];

	const showPathToTheFinal = (playerId, event) => {
		logger.log('[PathToTheFinalTest] showPathToTheFinal playerId:%o, event:%o', playerId, event);
		dispatch(deps.actions.PathToTheFinal.showPathToTheFinal(playerId, event));
	};

	logger.log('[PathToTheFinalTest] DrawsPageData:%o', DrawsPageData);

	return (
		<div className="content-main draws draws-test-page">

			<blockquote>
                <h2>Path To The Final</h2>
                <p>Path to the final is displayed on an overlay. <br /><br />
					The main draw paths section is rendered in <b>&lt;PathToTheFinalStandalone &#47;&gt;</b> component.<br/><br />
					Tournament action, <b>PathToTheFinal.showPathToTheFinal(playerId, event)</b> sets PathToTheFinal Redux.
				</p> <br />
                <h4>PathToTheFinal Redux:</h4>
                <ul>
                    <li>playerId ------------- String, Required</li>
                    <li>event ---------------- String, Required eventCode</li>
                    <li>display -------------- Boolean, true to display overlay</li>
                    <li>defaultTop ----------- Integer, when a parent page, Draws page is scrolled down, store the position to prevent the page from scrolling up </li>
                </ul>

                <p>Click the links below to load a different Path to the Final.</p>
            </blockquote>

			<ul className="list">
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							showPathToTheFinal('atpd643', 'MS');
							return false;
						}}>
						MS - Djokovic - atpd643
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							showPathToTheFinal('wta323027', 'LS');
							return false;
						}}>
						LS - Marketa Vondrousova - wta323027
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							showPathToTheFinal('atppf00', 'MD');
							return false;
						}}>
						MD - Mate Pavic - atppf00
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							showPathToTheFinal('wta322052', 'LD');
							return false;
						}}>
						LD - Caroline Dolehide - wta322052
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							showPathToTheFinal('wta330364', 'GS');
							return false;
						}}>
						GS - Nikola Bartunkova - wta330364
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							showPathToTheFinal('atpwb08', 'QS');
							return false;
						}}>
						QS - Yosuke Watanuki - atpwb08
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							showPathToTheFinal(id, event);
							return false;
						}}>
						Path To The Final - from query string event and id
					</a><br/>
						Query string example: ?id=wta311779&event=LD
				</li>
			</ul>

			{/** load Path to the Final */
			pathToTheFinal?.display && (
				<PathToTheFinal
					appsData={{
						// appFavs: this.state.appFavs,
						// appFavCallback: () => this.updateAppFavs,
					}}
				/>
			)}
		</div>
	);
};

export default PathToTheFinalTest;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';
import NewsTile from 'appdir/components/content/NewsTile';
import VideoPlayer from 'appdir/components/general/VideoPlayer';

class VideoPanel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.onLinkClick = this.onLinkClick.bind(this);

		this.loadedJson = null;
		//logger.log('[VideoPanel] - attr:%o', attributes);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		let dataPath = this.state.path ? this.state.path.replace('<category>', 'video') : null;

		if (dataPath && dataPath != this.loadedJson) {
			this.loadedJson = dataPath;
			deps.services.VideoIndex.fetch(dataPath)
				.then(result => {
					//logger.log('[VideoIndex] componentDidUpdate - data:%o', result);
					this.setState({
						data: result.content[0],
					});
				})
				.catch(error => {
					//logger.error('[VideoIndex] componentDidUpdate - error:%o', error);
					if (error.status == 404) {
						this.setState({
							data: 'notfound',
						});
					}
				});
		}
	}

	/**
	 * return LazyLoaded NewsTile component for each video
	 * @param {object} video json node
	 * @param {*} index
	 */
	getVideo(video, index) {
		if (video.media) {
			this.count++;

			let adPath = video.media.adTag ? video.media.adTag : '';
			let attributes = {
				'col-style': 'four-col',
				news: 'new',
				cmsId: video.id,
				title: video.title,
				url: video.url,
				adUrl: adPath,
				shareUrl: video.shareUrl,
				date: video.epoch,
				type: 'VIDEO',
				duration: video.media.duration,
				thumb: video.images.thumb.xlarge,
				videoUrl: video.media.m3u8,
				aspect: 'wide',
				videoSrc: video.media.src,
			};

			return (
				<>
					<NewsTile attributes={attributes} key={attributes.contentId} />
					<div className="four-col news-tile-divider"></div>
				</>
			);
		} else {
			return null;
		}
	}

	/**
	 * get list of videos
	 */
	getVideos() {
		return (
			<div className="column-layout">
				{this.state.data.videos.map((item, index) => this.getVideo(item, index))}
			</div>
		);
	}

	onLinkClick(e, path) {
		if (this.state.onLink) {
			this.state.onLink(e, path);
		}
	}

	render() {
		//logger.log('[VideoPanel] render - state:%o', this.state);

		if (this.state.data && this.state.data != 'notfound') {
			return (
				<div className="sidepanel-video-content">
					{this.getVideos()}

					<a
						ref="button-allvideo"
						className="video-button"
						href={`/en_GB/video/index.html`}
						onClick={e => {
							this.onLinkClick(e, `/en_GB/video/index.html`);
						}}>
						<i className="wim-icon-arrow" />
						All Videos
					</a>
				</div>
			);
		} else if (this.state.data && this.state.data == 'notfound') {
			return (
				<div className="sidepanel-video-error">
					<div className="title">No Videos Found</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default VideoPanel;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values } from 'appdir/main';
import { getAppLink } from 'appdir/components/general/Util';
import deps from 'dependencies';
import get from 'lodash/get';
import isString from 'lodash/isString';
const op = require('object-path');

// import WimLink from 'appdir/components/general/WimLink';
import PlayerImage from 'appdir/components/content/PlayerImage';
import Button from 'appdir/components/common-ui/Button';
import LikelihoodWinBanner from 'appdir/components/pages/MatchInsights/elements/LikelihoodWinBanner';
import PowerIndexChart from 'appdir/components/pages/MatchInsights/elements/PowerIndexChart';
import { fetch, hasLikelihoodToWin } from 'appdir/components/general/Util';
import {
	getTeamsLikelihoodWinData,
	getLikelihoodWinner,
	getLikelyhoodWinData,
	checkUpsetAlert,
} from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import { isPostMatch } from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { getQuerystringValues } from 'appdir/components/general/Util';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';
import isEqual from 'lodash/isEqual';

/**
 * -----------------------------------------------------------------------------
 * React Component:  MatchInsight Landing MatchCard
 *
 *  props = {
 *             @param Object match ------------- required,
 *			 @param Integer total ------------ total of Match Cards data
 *             @param Object searchedPlayer ----
 *             @param bool noPowerOption ------- enable an override to allow display without power index data
 *           }
 *
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchCard'],
		otherData: state['Config'].otherData,
		windowSize: state['PageHeader'].windowSize,
		favourites: state['Controller'].favourites,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	hide: () => dispatch(deps.actions.SlamtrackerPanel.hide()),
});

class MatchCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			powerRankingData: null,
			status: null,
		};

		this.matchId = null;

		// logger.log('[MatchCard] constructor - this: %o', this);
	}

	componentDidMount() {
		/** ?override=true ignores the match status and always set vote to open in the MI details page
		 *  add the same param to the link to the details page
		 */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));

		this.setState({
			status: 'loaded',
		});
	}

	nav(path) {
		logger.log('[MatchCard] windowSize:%o', this.props.windowSize);
		if (values.webview) {
			// logger.log('[MatchCard] nav - link: %o', getAppLink(path, '', false));
			window.location = getAppLink(path, '', false).url;
		} else {
			if (this.props.windowSize == 'mobile') {
				this.props.hide();
			}
			this.props.navigate({ path });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MatchCard] componentDidUpdate id:%o props:%o',  this.props?.match?.match_id, this.props);
		this.matchId = this.props?.match?.match_id;

		if (
			!this.state.powerRankingData &&
			this.matchId &&
			this.matchId !== undefined &&
			this.state.status == 'loaded'
		) {
			let powerIndexMatchupPath = this.props?.otherData.innovations?.powerIndexMatchup?.replace(
				'<matchId>',
				this.matchId
			);

			//limit data requests to those matches with likelihood to win data
			if (hasLikelihoodToWin(this.props?.match?.eventCode)){
				fetch(powerIndexMatchupPath)
					.then(result => {
						// logger.log('[MatchCard] result:%o', result);
						this.setState({
							powerRankingData: result,
						});
					})
					.catch(error => {
						logger.error('[MatchCard] componentDidUpdate error loading powerIndexMatchup data:%o', error);
						this.setState({
							powerRankingData: 'error',
						});
					});
			}
			else {
				this.setState({
					powerRankingData: 'error',
				});
			}
		}

		/* favorites */
		let search = document.location.search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);
		let favPlayers = op.get(parsedQs, 'filterPlayers', null);
		if (favPlayers) {
			favPlayers = favPlayers.split(',');
			if (!isEqual(favPlayers, this.state.favorite_players)) {
				this.setState(prevState => ({
					...prevState,
					favorite_players: favPlayers,
					favorite_filter: true,
				}));
			}
		}
	}

	render() {
		// logger.log('[MatchCard] render state:%o', this);
		let playerImageSrc = this.props.otherData.playerProfileImagePath;
		let imageStyle = 'regular';
		let flagImagePath = this.props.otherData.flagImagePathSmall;

		let { match, total, noPowerOption } = this.props;
		let teams = [];
		if (Array.isArray(match.team1) && match.team1.length > 1) {
			teams.push({
				"displayNameA": null,
				"displayNameB": null,
				"firstNameA": null,
				"firstNameB": null,
				"lastNameA": null,
				"lastNameB": null,
				"idA": null,
				"idB": null,
				"nationA": null,
				"nationB": null,
				"seed": null,
				"won": false
			})
		}
		else {
			teams.push(match.team1[0] || match.team1)
		}
		if (Array.isArray(match.team2) && match.team2.length > 1) {
			teams.push({
				"displayNameA": null,
				"displayNameB": null,
				"firstNameA": null,
				"firstNameB": null,
				"lastNameA": null,
				"lastNameB": null,
				"idA": null,
				"idB": null,
				"nationA": null,
				"nationB": null,
				"seed": null,
				"won": false
			})
		}
		else {
			teams.push(match.team2[0] || match.team2)
		}

		if (match?.match_id == '24207') {
			logger.log('[MatchCard] JDA render teams:%o, match:%o', teams, match);
		}

		/** support both passing the powerRankings data as props and pass matchID and fetch powerRankings data */
		if ((this.state.powerRankingData && this.state.powerRankingData !== 'error') || noPowerOption) {
			let likelyhoodWinData = getTeamsLikelihoodWinData(this.state.powerRankingData, teams);
			logger.log('[MatchCard] render matchId:%o likelyhoodWinData:%o', match?.match_id, likelyhoodWinData);

			/** make sure players have the probability number  */
			if (likelyhoodWinData.filter(d => d['likelihoodWinNum']).length > 0 || noPowerOption) {
				let team1Data = likelyhoodWinData.filter(d => d['player_id'] === teams[0]['idA']);
				let team2Data = likelyhoodWinData.filter(d => d['player_id'] === teams[1]['idA']);
				logger.log('[MatchCard] render matchId:%o team1Data:%o team2Data:%o', match?.match_id, team1Data, team2Data);
				// logger.log('[MatchCard] render team1Data:%o, team2Data:%o', team1Data, team2Data);
				//  let direction = team1Data[0]['likelihoodWin'] ? 'left' : 'right';
				let direction = 'center';
				if (get(team1Data, '[0].likelihoodWin', false)) {
					direction = 'left';
				} else if (get(team2Data, '[0].likelihoodWin', false)) {
					direction = 'right';
				}
				let team1idA = teams[0].idA;
				let team1idB = teams[0].idB;
				let team2idA = teams[1].idA;
				let team2idB = teams[1].idB;
				let team1displayNameA = teams[0].displayNameA;
				let team1displayNameB = teams[0].displayNameB;
				let team2displayNameA = teams[1].displayNameA;
				let team2displayNameB = teams[1].displayNameB;
				let team1nationA = teams[0].nationA;
				let team1nationB = teams[0].nationB;
				let team2nationA = teams[1].nationA;
				let team2nationB = teams[1].nationB;
				let active = team1idA && team2idA;
				imageStyle = team2idB ? (imageStyle = ' doubles') : imageStyle;
				let slamtrackerActive = active && match.courtId;
				let linkTo = `${window.webviewPlatform ? '/webview' : ''}/en_GB/matchinsights/${match.match_id}.html${
					this.parsedQs?.override ? '?override=true' : ''
				}`;

				let isDoubles = (isString(team1idB) || isString(team2idB)) ;
				if (isDoubles) {
					direction = 'center';
				}

				if (true || match?.match_id == '24207') {
					logger.log('[MatchCard] render matchId:%o team1Data:%o team2Data:%o', match?.match_id, team1Data, team2Data);
					//logger.log('[MatchCard] render isDoubles:%o direction:%o', isDoubles, direction);
				}

				return (
					<div
						className={`match-card ${total < 2 ? `no-margin` : ``} ${isDoubles ? 'doubles' : ''}`}
						key={`match-card-${match.match_id}`}
						data-match={match.match_id}>
						<div className="card">
							<div className="card-head">
								{this.props.style != 'upcoming' && (
									<div className="courtName">{match.shortCourtName}</div>
								)}
								<div className="eventName">{`${match.eventName} - ${match.roundName}`}</div>
							</div>

							<LikelihoodWinBanner
								direction={direction}
								upcoming={this.props.style == 'upcoming' ? true : false}
								pos={this.props.pos}
							/>

							<div className={`teams ${isDoubles ? 'doubles' : ''}`}>
								<div className={`team1-wrapper`}>
									<div className="team-wrapper" id={team1idA}>
										<Button onClick={() => this.nav(`/en_GB/players/overview/${team1idA}.html`)}>
											<PlayerImage
												attributes={{
													'player-image': `${playerImageSrc.replace('<playerid>', team1idA)}`,
													style: `${imageStyle}`,
												}}
											/>
										</Button>
										{(!team1idB)&& (
											<div className="team-name t1">
												<div
													className={`team1 
                                            ${get(this.props, 'searchedPlayer.id', '') == team1idA ? `yellow` : ``} 
                                            ${
												(get(this.props, 'favourites.show', false) &&
													get(this.props, 'favourites.players', []).includes(team1idA)) ||
												(get(this.state, 'favorite_filter', false) &&
													get(this.state, 'favorite_players', []).includes(team1idA))
													? `purple`
													: ``
											} 
                                            `}>
													<Button
														onClick={() =>
															this.nav(`/en_GB/players/overview/${team1idA}.html`)
														}>
														<div className="playerName">
															<div className="name">
																<div className="flag">
																	<img
																		alt={team1nationA}
																		src={flagImagePath.replace(
																			'<code>',
																			team1nationA
																		)}
																	/>
																</div>
																{team1displayNameA || ''}
															</div>
														</div>
													</Button>
												</div>
											</div>
										)}
									</div>

									{isDoubles && (
										<div className="team-wrapper" id={team1idB}>
											<Button
												onClick={() => this.nav(`/en_GB/players/overview/${team1idB}.html`)}>
												<PlayerImage
													attributes={{
														'player-image': `${playerImageSrc.replace(
															'<playerid>',
															team1idB
														)}`,
														style: `${imageStyle}`,
													}}
												/>
											</Button>
										</div>
									)}
								</div>

								<div className={`v ${isDoubles ? 'doubles' : ''}`}>
									<PowerIndexChart
										teams={teams}
										matchId={match.match_id}
										powerRankingData={this.state.powerRankingData}
										type="matchcard"
										style={this.props.style}
									/>
								</div>
								<div className={`team2-wrapper`}>
									<div className="team-wrapper team2" id={team2idA}>
										<Button onClick={() => this.nav(`/en_GB/players/overview/${team2idA}.html`)}>
											<PlayerImage
												attributes={{
													'player-image': `${playerImageSrc.replace('<playerid>', team2idA)}`,
													style: `${imageStyle}`,
												}}
											/>
										</Button>
										{(!team2idB) && (
											<div className="team-name t2">
												<div
													className={`team2 
                                                ${get(this.props, 'searchedPlayer.id', '') == team2idA ? `yellow` : ``}
                                                ${
													(get(this.props, 'favourites.show', false) &&
														get(this.props, 'favourites.players', []).includes(team2idA)) ||
													(get(this.state, 'favorite_filter', false) &&
														get(this.state, 'favorite_players', []).includes(team2idA))
														? `purple`
														: ``
												} 
                                                `}>
													<Button
														onClick={() =>
															this.nav(`/en_GB/players/overview/${team2idA}.html`)
														}>
														<div className="playerName">
															<div className="name">
																<div className="flag">
																	<img
																		alt={team2nationA}
																		src={flagImagePath.replace(
																			'<code>',
																			team2nationA
																		)}
																	/>
																</div>
																{team2displayNameA || ''}
															</div>
														</div>
													</Button>
												</div>
											</div>
										)}
									</div>

									{team2idB && (
										<div className="team-wrapper team2" id={team2idB}>
											<Button
												onClick={() => this.nav(`/en_GB/players/overview/${team2idB}.html`)}>
												<PlayerImage
													attributes={{
														'player-image': `${playerImageSrc.replace(
															'<playerid>',
															team2idB
														)}`,
														style: `${imageStyle}`,
													}}
												/>
											</Button>
										</div>
									)}
								</div>
							</div>
							{team2idB && (
								<div className="doubles-team-names">
									<div className="team-name t1">
										<div className={`team1 doubles`}>
											<div className="playerName doubles">
												<Button
													onClick={() =>
														this.nav(`/en_GB/players/overview/${team1idA}.html`)
													}>
													<div
														className={`name ${
															get(this.props, 'searchedPlayer.id', '') == team1idA
																? `yellow`
																: ``
														}
															${
																(get(this.props, 'favourites.show', false) &&
																	get(this.props, 'favourites.players', []).includes(
																		team1idA
																	)) ||
																(get(this.state, 'favorite_filter', false) &&
																	get(this.state, 'favorite_players', []).includes(
																		team1idA
																	))
																	? `purple`
																	: ``
															} `}>
														<div className="flag">
															<img
																alt={team1nationA}
																src={flagImagePath.replace('<code>', team1nationA)}
															/>
														</div>
														{team1displayNameA}
													</div>
												</Button>
												<Button
													onClick={() =>
														this.nav(`/en_GB/players/overview/${team1idB}.html`)
													}>
													<div
														className={`name ${
															get(this.props, 'searchedPlayer.id', '') == team1idB
																? `yellow`
																: ``
														}
															${
																(get(this.props, 'favourites.show', false) &&
																	get(this.props, 'favourites.players', []).includes(
																		team1idB
																	)) ||
																(get(this.state, 'favorite_filter', false) &&
																	get(this.state, 'favorite_players', []).includes(
																		team1idB
																	))
																	? `purple`
																	: ``
															}`}>
														<div className="flag">
															<img
																alt={team1nationB}
																src={flagImagePath.replace('<code>', team1nationB)}
															/>
														</div>
														{team1displayNameB}
													</div>
												</Button>
											</div>
										</div>
									</div>
									<div className="team-name t2">
										<div className={`team2 doubles`}>
											<div className="playerName doubles">
												<Button
													onClick={() =>
														this.nav(`/en_GB/players/overview/${team2idA}.html`)
													}>
													<div
														className={`name ${
															get(this.props, 'searchedPlayer.id', '') == team2idA
																? `yellow`
																: ``
														}
															${
																(get(this.props, 'favourites.show', false) &&
																	get(this.props, 'favourites.players', []).includes(
																		team2idA
																	)) ||
																(get(this.state, 'favorite_filter', false) &&
																	get(this.state, 'favorite_players', []).includes(
																		team2idA
																	))
																	? `purple`
																	: ``
															} `}>
														<div className="flag">
															<img
																alt={team2nationA}
																src={flagImagePath.replace('<code>', team2nationA)}
															/>
														</div>
														{team2displayNameA}
													</div>
												</Button>
												<Button
													onClick={() =>
														this.nav(`/en_GB/players/overview/${team2idB}.html`)
													}>
													<div
														className={`name ${
															get(this.props, 'searchedPlayer.id', '') == team2idB
																? `yellow`
																: ``
														}
															${
																(get(this.props, 'favourites.show', false) &&
																	get(this.props, 'favourites.players', []).includes(
																		team2idB
																	)) ||
																(get(this.state, 'favorite_filter', false) &&
																	get(this.state, 'favorite_players', []).includes(
																		team2idB
																	))
																	? `purple`
																	: ``
															}`}>
														<div className="flag">
															<img
																alt={team2nationB}
																src={flagImagePath.replace('<code>', team2nationB)}
															/>
														</div>
														{team2displayNameB}
													</div>
												</Button>
											</div>
										</div>
									</div>
								</div>
							)}
							{this.props.style == 'upcoming' ? (
								<>
									{!this.props.hideSTbutton && (
										<div className="button-wrapper">
											<SlamtrackerButton
												match_id={match.match_id}
												slamtrackerActive={slamtrackerActive}
												statusCode={match.statusCode}
												styleClass={'white'}
												defaultTab={this.props.defaultTab}
											/>
										</div>
									)}
								</>
							) : (
								<SlamtrackerButton
									isSidepanel={false}
									match_id={match.match_id}
									slamtrackerActive={slamtrackerActive}
									statusCode={match.statusCode}
									styleClass={'white'}
									defaultTab={isPostMatch(match) ? 'watch' : 'overview'}
								/>
							)}
						</div>
					</div>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchCard);

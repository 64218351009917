import LiveRadio from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: '/:lang/interactive/radio.html',
		exact: true,
		component: LiveRadio,
		section: MENU_ITEMS.videoRadio,
		load: params => deps.actions.LiveRadio.mount(params),
	},
];

import deps from 'dependencies';
import { values } from 'appdir/main';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		let include = !window.webview ? ['all'] : ['scoringConfig'];
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, include).then(Config => {
			let scoringSection = 'scoringData';

			if (values.scoreApi == 'true') {
				logger.log('[DrawsPage] action.mount - values: %o', values);
				logger.log('[DrawsPage] action.mount - config: %o', Config);
				scoringSection = 'apiScoringData';
			}

			let pathtothefinal = {}
			
			if(values.webview) {
				let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
				let playerId  = parsedQs.playerId;

				logger.log('[DrawsPage] action.mount - pathtofinal:%o, playerId:%o', pathtothefinal, playerId);
			}

			let data = {
				mountStatus: 'ready',
				drawsPath: Config[scoringSection].draws,
				pdfPath: Config[scoringSection].drawPdf,
				flagImagePathSmall: Config.otherData.flagImagePathSmall,
				playerProfileImagePath: Config.otherData.playerProfileImagePath,
				drawExclude: Config?.scoring?.drawExclude,
				drawPdfEvents: Config?.scoring?.drawPdfEvents,
				showFullView: true, //Config?.enabled?.fullDrawView,
				pathtothefinal
			};

			dispatch({ type: deps.actionTypes.DRAWSPAGE_LOAD, data: data });
		});
	},

	unmount: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSPAGE_UNMOUNT, data: data });
	},

	/** RLL added 2/15/2022 to combine round and filter */
	update: data => (dispatch, getState, store) => {
		// logger.log('[DrawsPage] action.update - data: %o', data);
		dispatch({ type: deps.actionTypes.DRAWSPAGE_UPDATE, data: data });
	},

	// filter: data => (dispatch, getState, store) => {
	// 	dispatch({ type: deps.actionTypes.DRAWSPAGE_FILTER, data: data });
	// },

	round: data => (dispatch, getState, store) => {
		// logger.log('[DrawsPage] action.round - alldata: %o', data);
		dispatch({ type: deps.actionTypes.DRAWSPAGE_ROUND, data: data });
	},

	search: data => (dispatch, getState, store) => {
		// logger.log('[DrawsPage] action.search - alldata: %o', data);
		dispatch({ type: deps.actionTypes.DRAWS_SEARCH, data: data });
	},

	renderDraws: timestamp => (dispatch, getState, store) => {
		// logger.log('[DrawsPage] action.renderDraws');
		dispatch({ type: deps.actionTypes.DRAWS_RENDER, data: timestamp });
	},

	updateFound: flag => dispatch => {
		// logger.log('[DrawsPage] action.updateFound %o', flag);
		dispatch({ type: deps.actionTypes.DRAWS_FIND, data: flag });
	},

	changeView: data => dispatch => {
		dispatch({ type: deps.actionTypes.DRAWS_CHANGE_VIEW, data });
	},
};

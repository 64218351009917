import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => dispatch => {
		logger.log('[ContentMisc] action.mount - params: %o', params);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Breakpoint Game',
		});

		dispatch({
			type: deps.actionTypes.BREAKPOINTPAGE_MOUNT,
		});
	},
};

import React, { Component } from 'react';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isEmpty from 'lodash/isEmpty';

import InputBox from 'appdir/components/general/Gigya/screens/elements/InputBox';
import { registrationLogin } from 'appdir/components/general/Gigya/translations/registrationLogin';

class VerificationSent extends Component {
	constructor(props) {
		super(props);

		//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
		this.classnames = 'gigya-screen v2 portrait';
	}

	render() {
		return (
			<div
				id="gigya-verification-sent-screen"
				data-width="auto"
				className={this.classnames}
				data-caption="screenset.translations['GIGYA_VERIFICATION_SENT_SCREEN_CAPTION']">
				<div className="gigya-layout-row">
					<label
						className="gigya-composite-control gigya-composite-control-label gigya-message"
						data-translation-key="LABEL_EMAIL_VERIFICATION_SENT_LABEL"
					/>
				</div>
				<div className="gigya-layout-row">
					<a
						data-switch-screen="_finish"
						tabIndex="-1"
						className="gigya-composite-control gigya-composite-control-link gigya-button-link"
						style={{ textAlign: 'center' }}
						data-translation-key="LINK_VERIFICATION_SENT_LABEL"
					/>
				</div>
			</div>
		);
	}
}

VerificationSent.contextType = AuthContext;
export default VerificationSent;

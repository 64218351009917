import React, { Component } from 'react';
import { connect } from 'react-redux';
import RoleIcons from 'appdir/components/cms/RoleIcons';
const op = require('object-path');

const mapStateToProps = (state, props) => {
	return {
		loggedIn: state['Gigya']['loggedIn'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class DebenturePrivate extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	render() {
		logger.log('[DebenturePrivate] render state: %o', this.state);

		if (this.state.loggedIn) {
			return <RoleIcons role="debenture" type="private" />;
		} else {
			return null;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DebenturePrivate);

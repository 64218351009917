import { useSelector } from 'react-redux';

const useRoles = () => {
	const {
		Gigya: {
			currentUser: { roles },
		},
		//Config: { myWIcons },
	} = useSelector(state => state);

	let checkRoles = JSON.parse(JSON.stringify(roles));

	const rolesWithTitles = checkRoles.map(role => {
		return {
			...role,
		};
	});

	return { rolesWithTitles };
};

export default useRoles;

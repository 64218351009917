import React from 'react';
// import Button from 'appdir/components/common-ui/Button';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';
const mobileMatch = window.matchMedia('(max-width: 510px)');
const PlayerStatus = props => {
	const { statusText, matchId } = props;

	const getButtons = () => {
		if (props?.page == 'powerindex') {
			return (
				<SlamtrackerButton
					match_id={matchId}
					slamtrackerActive={props?.slamtrackerActive}
					statusCode={props?.statusCode}
					styleClass={`white ${mobileMatch.matches ? 'compact' : ''}`}
				/>
			);
		} else {
			return (
				/** for Picked for you
				 */
				<SlamtrackerButton
					match_id={matchId}
					slamtrackerActive={props?.slamtrackerActive}
					statusCode={props?.statusCode}
					styleClass={`white ${mobileMatch.matches ? 'compact' : ''}`}
				/>

				/** commenting out last year's set up for a reference */
				// preMatchInsight ?
				// 	<Button
				// 		className={`${mobileMatch.matches ? 'compact': ''}`}
				// 		to={`/en_GB/matchinsights/${matchId}.html`}>
				// 		Match Insights
				// 	</Button>
				// :
				// 	statusText.statusCode == 'B' && (statusText.eventCode == 'MS' || statusText.eventCode == 'LS') ?

				// 		<Button className="disabled">
				// 			Match Insights
				// 		</Button>
				// 	:
				// 		statusText.statusCode !== 'B' ?
				// 			<Button
				// 				className={`${mobileMatch.matches ? 'compact' : ''}`}
				// 				to={`/en_GB/scores/stats/${matchId}.html`}>
				// 				Stats By Slamtracker
				// 			</Button>
				// 		:
				// 			<Button
				// 				className={`disabled`}>
				// 				Stats By Slamtracker
				// 			</Button>
			);
		}
	};
	return (
		<>
			<div className="player-match-status">{statusText.matchStatus}</div>
			{statusText.playerOpponent ? (
				<div className="player-oponent">
					<span className="versus-text">vs</span> {statusText.playerOpponent}
				</div>
			) : null}
			{matchId !== null && getButtons()}
		</>
	);
};

export default PlayerStatus;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { RoleRegistrationInviteContext } from '../context';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormInput, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import { handleColumns } from 'appdir/components/general/Util';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => ({
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});
class Ticket extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			confirmed: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitCode = this.handleSubmitCode.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.initialValues = {
			checkbox1: false,
			checkbox2: false,
			confirm: false,
		};

		this.initialValuesCode = {
			verificationCode: '',
		};

		//logger.log('[RoleRegistrationInvite - Ticket] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log(
			'[RoleRegistrationInvite - Ticket] componentDidMount - state:%o context:%o',
			this.state,
			this.context
		);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log(
		// 	'[RoleRegistrationInvite - Ticket] componentDidUpdate - state:%o context:%o',
		// 	this.state,
		// 	this.context
		// );
		handleColumns();
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {
			checkbox1: yup
				.boolean()
				.required('Please read and accept the Ticket Terms and Conditions.')
				.oneOf([true], 'Please read and accept the Ticket Terms and Conditions.'),

			checkbox2: yup
				.boolean()
				.required('Please read and accept the Conditions of Entry.')
				.oneOf([true], 'Please read and accept the Conditions of Entry.'),

			confirm: yup
				.boolean()
				.required('Please confirm.')
				.oneOf([true], 'Please confirm.'),
		};

		return yup.object(schema);
	}

	getSchemaCode(content) {
		let schema = {
			verificationCode: yup.string().required('Please enter the verification code sent via email.'),
		};

		return yup.object(schema);
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[RoleRegistrationInvite - Ticket] handleSubmit - state:%o', values);
		this.addRole();
	}

	handleSubmitCode(values, actions) {
		logger.log('[RoleRegistrationInvite - Ticket] handleSubmitCode - state:%o', values);
		if (this.context.getStatus) {
			this.context.getStatus(values.verificationCode);
		}
	}

	/**
	 * Manage the modal for displaying submission status
	 * @param {Object} state
	 */
	submitModal(state, passedCode) {
		if (state == 'send') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'REGISTERING TICKETS...',
				message: 'Your profile is updating. Please do not close your browser window while updating.',
				useTimer: false,
			});
		}
	}

	addRole() {
		if (this.context.addRole) {
			//logger.log('[RoleRegistrationInvite - Ticket] addRole');

			//if have a spectatorId, meaning ticket response, then try to register with spectatorId
			if (this.context.spectatorId) {
				//this.submitModal('send');
				this.context.addRole();
			}
		}
	}

	viewTickets(e) {
		e.preventDefault();
		if (this.context.spectatorId) {
			//logger.log('[Tickets] viewTickets -');
			this.setState({
				//redirect: `/s/tickets/view.html?acct=${encodeURIComponent(this.context.spectatorId)}`,
				redirect: `/s/tickets/view.html`,
			});
		} else {
			logger.error('[Tickets] viewTickets - no spectatorId');
		}
	}

	requestCode(event) {
		event.preventDefault();
		logger.log('[RoleRegistrationInvite - Ticket] requestCode');
		if (this.context.getStatus) {
			this.context.getStatus('new');
		}
	}

	getCodeForm() {
		let validationSchema = this.getSchemaCode();

		return (
			<Formik
				initialValues={this.initialValuesCode}
				validationSchema={validationSchema}
				onSubmit={this.handleSubmitCode}
				enableReinitialize={true}>
				{formProps => {
					return (
						<Fragment>
							<div className="two-col margin">
								You have been sent a code via email to verify your email address. Please enter here.
							</div>
							<div className="one-col margin">
								<FormInput
									label="Verification Code"
									name="verificationCode"
									type="text"
									placeholder="Enter Code"
									required
								/>
								<a href="" className="link small" onClick={e => this.requestCode(e)}>
									Resend code via email >
								</a>
							</div>
							<div className="four-col body">
								<Button className={`btn solid`} onClick={formProps.submitForm}>
									SUBMIT
								</Button>
							</div>
						</Fragment>
					);
				}}
			</Formik>
		);
	}

	onConfirm() {
		this.setState({ confirmed: true });
	}

	getTicketDetails(ticket) {
		let ticketDetails = ticket.ticketDetails;
		let obj = {};
		obj.court =
			ticketDetails
				.filter((d, i) => {
					return d.key == 'Court';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
		obj.row =
			ticketDetails
				.filter((d, i) => {
					return d.key == 'Row';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
		obj.seat =
			ticketDetails
				.filter((d, i) => {
					return d.key == 'Seat Number';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';

		return obj;
	}

	getTicketDate(ticket) {
		return moment(ticket.startTime)
			.tz('Europe/London')
			.format('MMMM Do YYYY');
	}

	renderConfirmText() {
		return (
			<Fragment>
				<div className="two-col margin question">
					<div>You are adding tickets</div>
					<div className="highlight">
						To <MyWimbledonTitle short="true" /> account:
					</div>
					<div className="email">
						<strong>{this.context.userEmail}</strong>
					</div>
				</div>
			</Fragment>
		);
	}

	getCompleteForm() {
		let validationSchema = this.getSchema();

		return (
			<Formik
				initialValues={this.initialValues}
				validationSchema={validationSchema}
				onSubmit={this.handleSubmit}
				enableReinitialize={true}>
				{formProps => {
					let allowSubmit =
						formProps.values.checkbox1 && formProps.values.checkbox2 && formProps.values.confirm;
					return (
						<Fragment>
							<div className="two-col margin">
								<FormCheckBox name="checkbox1" id="checkbox1">
									I have read and agree to the{' '}
									<WimLink to={'/en_GB/tickets/ticket_terms_and_conditions.html'} external={true}>
										Ticket Terms and Conditions
									</WimLink>
									.
								</FormCheckBox>
								<div className="spacer" />
								<FormCheckBox name="checkbox2" id="checkbox2">
									I have read and agree to the{' '}
									<WimLink to={'/en_GB/atoz/conditions_of_entry.html'} external={true}>
										Conditions of Entry
									</WimLink>
									.
								</FormCheckBox>
								<div className="spacer" />
								<FormCheckBox name="confirm" id="confirm">
									I confirm I want to add Ticket access for <MyWimbledonTitle short="true" /> user{' '}
									<strong>{this.context.userEmail}</strong>.
								</FormCheckBox>
							</div>
							<div className="four-col body">
								<Button
									className={`btn solid ${!allowSubmit ? `disabled` : ``}`}
									onClick={formProps.submitForm}>
									ADD TICKETS
								</Button>
							</div>
						</Fragment>
					);
				}}
			</Formik>
		);
	}

	renderSubmitForm() {
		let validationSchema = this.getSchema();

		return (
			<Fragment>
				<div className="two-col margin">
					Before we provide you with access to your tickets, please acknowledge that you accept the Ticket Terms and Conditions and the Conditions of Entry below.{' '}
				</div>
				<div className="spacer" />

				{/*hide incompletetickets table*/}
				{false && this.context.tickets.length > 0 ? (
					<div className="two-col margin">
						<div className="table">
							{this.context.tickets.map(ticket => {
								return (
									<div className="row">
										<div className="text">{this.getTicketDate(ticket)}</div>
										<div className="text">{this.getTicketDetails(ticket).court}</div>
										<div className="text">Row: {this.getTicketDetails(ticket).row}</div>
										<div className="text">Seat: {this.getTicketDetails(ticket).seat}</div>
									</div>
								);
							})}
						</div>
						<div className="spacer" />
					</div>
				) : null}
				{this.getCompleteForm()}
			</Fragment>
		);
	}

	render() {
		logger.log('[RoleRegistrationInvite - Ticket] render - context:%o', this.context);

		return (
			<Fragment>
				{this.state.redirect ? (
					<WimRedirect to={this.state.redirect} />
				) : this.context.status == 'unmatched' ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col margin">
							Sorry – it looks like you are logged in to a different account. Please{' '}
							<WimLink to="/en_GB/mywimbledon/profile.html">update your account</WimLink>, or create a new
							one, with your ticket email address.
						</div>
					</Fragment>
				) : this.context.status == 'complete' ? (
					//links can only be used once, so direct to ticket view if completed
					<WimRedirect to={'/s/tickets/app'} />
				) : this.context.complete ? (
					<WimRedirect to={'/s/tickets/app'} />
				) : !this.context.isValid ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">
							Unfortunately your registration code is invalid or has expired.
							<br />
							<br />
							Please try again or contact your AELTC representative to request a new link.
						</div>
					</Fragment>
				) : (
					<Fragment>
						<div className="two-col margin">
							<h4>TICKET REGISTRATION</h4>
						</div>
						{this.context.status == 'active' && this.context.tickets ? (
							<Fragment>
								{this.renderConfirmText()}
								<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
								{this.renderSubmitForm()}
							</Fragment>
						) : null}

						{this.context.status == 'code' ? <Fragment>{this.getCodeForm()}</Fragment> : null}
					</Fragment>
				)}
			</Fragment>
		);
	}
}
Ticket.contextType = RoleRegistrationInviteContext;
export default connect(mapStateToProps, mapDispatchToProps)(Ticket);

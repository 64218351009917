import deps from 'dependencies';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[PDEContentDash] action.mount - params:%o config:%o', params, Config);

			let data = {
				...params,
			};

			logger.log('[PDEContentDash] action.mount - data: %o', data);
			dispatch({
				type: deps.actionTypes.PDECONTENTDASH_MOUNT,
				data: data,
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.PDECONTENTDASH_UNMOUNT });
	},
};

import React, { Component } from 'react';
import { logger } from '../logger';
// import op from 'object-path';


export default class Captions extends Component {
	
	constructor(props) {
		super(props);
		
		this.amp = props.amp;
		this.styles = props.styles;

		let visible = !this.amp.captioning.getHidden();

		this.state = {
			active: this.props.active
		}

		this.onVisibilityChange = this.onVisibilityChange.bind(this);
		//this.onTimeUpdate = this.onTimeUpdate.bind(this);

		this.addAmpListeners();

		if (!this.props.useCustom608) {
			this.amp.captioning.hidden = !this.state.active;
		}
		else {
			this.amp.captioning.hidden = true;
		}

		logger.log('CaptionsButton: constructor - state:%o custom608:%o ampHidden:%o', this.state, this.props.useCustom608, this.amp.captioning.getHidden());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//logger.log('CaptionsButton: componentDidUpdate - amp:%o', this.props.mediaId);

		if (this.props.amp.multitonKey != prevProps.amp.multitonKey) {
			this.amp = this.props.amp;
			this.addAmpListeners();
		}

		if ((this.amp && this.amp.captioning) && 
			(this.props.mediaId != prevProps.mediaId ||
			(prevProps.useCustom608 && !this.props.useCustom608) ||
			(!prevProps.useCustom608 && this.props.useCustom608))
		) {
			
			logger.log('CaptionsButton: componentDidUpdate - mediaId:%o custom608:%o ampHidden:%o', this.props.mediaId, this.props.useCustom608, this.amp.captioning.getHidden());

			if (!this.props.useCustom608) {
				this.amp.captioning.hidden = !this.state.active;
			}
			else {
				this.amp.captioning.hidden = true;
			}
		}
	}

	componentWillUnmount() {
		//logger.log('Controls: componentWillUnmount - amp:%o', this.amp);
		if (this.amp) {			
			if (this.amp.captioning){
				this.amp.captioning.removeEventListener('visibilitychange', this.onVisibilityChange);
			}
			this.amp = null;
		}
	}

	addAmpListeners() {
		if (this.amp){
			if (this.amp.captioning){
				this.amp.captioning.addEventListener('visibilitychange', this.onVisibilityChange);
				//this.amp.addEventListener('timeupdate', this.onTimeUpdate);
				//this.amp.captioning.addEventListener('trackselected', this.onTracksSelected);
				//this.amp.captioning.addEventListener('tracksloaded', this.onTracksLoaded);

				/*
				  	VISIBILITY_CHANGE: "captioningvisibilitychange",
					ENABLED: "captioningenabled",
					TRACKS_LOADED: "captioningtracksloaded",
					TRACK_SELECTED: "captioningtrackselected",
					CHANGE_CUE: "captioningchangecue",
					CUE_CHANGE: "captioningcuechange",
					SETTINGS_VISIBILITY_CHANGE: "captioningsettingsvisibility",
					SETTINGS_CHANGE: "captioningsettingschange"
					TTML_CUE_CHANGE: "captioningttmlcuechange"
				*/
			}
		}
	}

	onVisibilityChange(e) {
		logger.log('CaptionsButton: onVisibilityChange - state:%o custom608:%o ampHidden:%o', this.state, this.props.useCustom608, this.amp.captioning.getHidden());
		if (!this.props.useCustom608) {
			let visible = !this.amp.captioning.getHidden();
			logger.log('CaptionsButton: onVisibilityChange - state:%o ampVisible:%o parr:%o', this.state, visible, this.amp.captioning.config.useparallelrendering);

			// from Maverick w/modifications
			let state = e.data ? "showing" : "hidden";
			if(this.amp.captioning.config.useparallelrendering) {
				if (-1 < akamai.amp.Utils.getSafariVersion()) {
					this.amp.getMediaElement().textTracks[0].mode = state; 
				}
				else if (this.amp.getMediaElement().textTracks[0]){
					this.amp.getMediaElement().textTracks[0].mode = 'hidden'; 
				}
				//logger.log('[Captions] onVisibilityChange - state:%o track:%o', state, this.amp.getMediaElement().textTracks[0]);
			}
			else {
				logger.log('CaptionsButton: onVisibilityChange - track:%o', this.amp.getMediaElement().textTracks.length);
				//logger.log('CaptionsButton: onVisibilityChange - track:%o', document.getElementsByTagName('video')[0].textTracks.length);
			}
		}

	}

	//saw issue with captions displaying by default at start
	// this forces them off/on based on current state
	onTimeUpdate(e) {
		// if (this.amp.captioning.getHidden() != this.state.active) {
		// 	this.amp.captioning.hidden = !this.state.active;
		// 	this.amp.removeEventListener('timeupdate', this.onTimeUpdate);
		// }
	}

	onClick() {
		logger.log('CaptionsButton: onClick - useCustom608:%o', this.props.useCustom608);
		//this.amp.captioning.selectTrackByIndex(this.props.tracks[0]);
		
		if (!this.props.useCustom608) {
			//logger.log('CaptionsButton: onClick - state:%o', this.state);
			this.amp.captioning.hidden = this.state.active;
		}
		
		if (this.props.onClick) {
			this.props.onClick(this.state.active ? 'captions_hide' : 'captions_show');
		}

		this.setState ({
			active: !this.state.active
		})
	}

	/**
	 * controls captions state through API
	 * @param {Boolean} active 
	 */
	set(active) {

		if (!this.props.useCustom608) {
			//logger.log('CaptionsButton: onClick - state:%o', this.state);
			this.amp.captioning.hidden = !active;			
		}

		if (this.props.onClick) {
			this.props.onClick(!active ? 'captions_hide' : 'captions_show');
		}

		this.setState ({
			active: active
		})
	}
	
	render() {
		logger.log('CaptionsButton: this.amp.getMediaElement().textTracks[0]:%o', this.amp.getMediaElement().textTracks[0]);
		//let textTracks = this.amp.getMediaElement().textTracks[0];
		let textTracks = this.amp.getMediaElement().textTracks[0] || this.amp.captioning.tracks;
		let inline = {};
		let data_state = '';
		
		if(typeof textTracks !== 'undefined' && !this.state.active) {
			data_state = 'inactive';
		}
		else if(typeof textTracks !== 'undefined' && this.state.active) {
			data_state = 'active';
		}
		else {
			data_state = '';
		}
		logger.log('CaptionsButton: render - data_state:%o ', data_state);
		return (
			<button 
				className={`${this.styles.icon} ${this.styles.captions}`} 
				style={inline} 
				onClick={() => {this.onClick()}} 
				data-state={data_state}
				title="Closed Captions">
			</button>
		)
	}
	
}

import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MATCHSTATSFULL_LOADING:
			newState = {
				...state,
				matchStatsConfig: action.data,
				updated: moment().toISOString(),
			};
			return newState;
			break;

		default:
			return state;
	}
};

import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';

class PlayerProfilePromo extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

    handleBeforeUnload = () => {
        // dont show again if user views then refreshes page
		if (this.props.onUnmount) {
			this.props.onUnmount();
		}
	};

	render() {
		return (
            <div className="promos-image-container">
                <img className="player-profile-promo-img promo-image" alt="Player Profile Promo" />
            </div>
        );
	}
}

export default PlayerProfilePromo;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { SAMLContext } from './context';

import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import MeasurementUtils from 'appdir/lib/analytics';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: Error page
 * -----------------------------------------------------------------------------
 */

class Error extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		this.error = JSON.parse(this.parsedQs.error);
		logger.log('[SAML_Error] constructor - qs:%o error:%o', this.parsedQs, this.error);
	}

	componentDidMount() {
		let data = {};

		data.pageTitle = 'Error';
		data.redirect = op.get(this.parsedQs, 'redirect', '');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, data);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[SAML_Error] componentDidUpdate - prev:%o state:%o', prevState, this.state);
	}

	render() {
		//logger.log('[SAML_Error] render - state:%o context:%o', this.state, this.context);

		return (
			<Fragment>
				<div className="two-col margin-col">
					Sorry, we are having difficulty authenticating you. Please try again. If the problem persists please{' '}
					<WimLink to="/en_GB/contact/index.html">contact us</WimLink>, quoting the message below.
				</div>
				{this.error && this.error.errorDetails ? (
					<div className="two-col margin-col sso_message">{`${this.error.errorDetails} (${this.error.errorCode})`}</div>
				) : this.error ? (
					<div className="two-col margin-col sso_message">{`${this.error.errorMessage} (${this.error.errorCode})`}</div>
				) : null}
			</Fragment>
		);
	}
}

Error.contextType = SAMLContext;
export default Error;

import React, { Component } from 'react';

export default class BarChart extends React.Component {
	constructor(props) {
		super(props);
		this.chartRef = React.createRef();
		this.chart;
	}

	componentDidMount() {
		logger.log('[BarChart] componentDidMount - data:%o', this.props.data);
		this.chart = new Chart(this.chartRef.current, {
			type: 'bar',
			data: {
				labels: [''],
				datasets: [
					{
						label: 'Delay in seconds',
						data: [0],
						borderWidth: 1,
					},
				],
			},
			options: {
				plugins: {
					title: {
						display: true,
						text: '',
					},
					subtitle: {
						display: true,
						text: '',
					},
				},
				scales: {
					y: {
						beginAtZero: true,
					},
					x: {
						ticks: {
							display: false,
						},
					},
				},
			},
		});
	}

	componentDidUpdate() {
		if (this.chart) {
			logger.log('[BarChart] data:%o', this.props.data);
			this.chart.options.plugins.title.text = this.props.title;
			this.chart.options.plugins.subtitle.text = this.props.subtitle;
			this.chart.data.labels = this.props.data.map(d => d.label);
			this.chart.data.datasets[0].data = this.props.data.map(d => d.value);
			this.chart.data.datasets[0].backgroundColor = this.props.data.map(d => d.color);
			this.chart.update();
		}
	}

	render() {
		return (
			<>
				<div className="network-barchart">
					<canvas ref={this.chartRef} />
				</div>
			</>
		);
	}
}

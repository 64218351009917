import React, { Component } from 'react';
import JSXParser from 'react-jsx-parser';

class JSXWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		logger.info('[JSXWrapper] shouldComponentUpdate - id:%o state:%o', nextState.id, nextState);
		if (this.state.id !== nextState.id) {
			return true;
		}
		return false;
	}

	render() {
		return (
			<JSXParser
				bindings={this.state.bindings}
				components={{ ...this.state.components }}
				jsx={this.state.jsx}
				renderInWrapper={false}
				showWarnings={true}
			/>
		);
	}
}
export default JSXWrapper;

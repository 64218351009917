import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {};

			dispatch({ type: deps.actionTypes.SLAMTRACKERPANEL_MOUNT, data: data });
		});
	},

	hide: () => (dispatch, getState) => {
		dispatch({ type: deps.actionTypes.SLAMTRACKERPANEL_CLOSE, data: { slamtrackerPanel: { display: false } } });
	},

	show: (matchId = null, defaultTab = null) => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			dispatch({
				type: deps.actionTypes.SLAMTRACKERPANEL_OPEN,
				data: { slamtrackerPanel: { display: true, matchId, defaultTab } },
			});
		});
	},

	video: (vidOpen = false) => (dispatch) => {
		dispatch({ type: deps.actionTypes.SLAMTRACKERPANEL_VIDEO, data: {vidOpen} });
	},
};

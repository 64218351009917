import VisitWebview from './index';
import deps from 'dependencies';

// tournDay will be a field in the params obj.
// route is day<dayNum>.html
export default {
	path: '/webview/:lang/visit/index.html',
	exact: true,
	component: VisitWebview,
	load: params => deps.actions.VisitWebview.mount(params),
};

import React from 'react';
import { handleGetQueueStatus } from 'appdir/components/webviews/TicketResaleWebview/ticketResaleEndpoints';
import Button from 'appdir/components/common-ui/Button';
import { measureInAppContext } from 'appdir/components/general/Analytics';

const RefreshResaleQueue = (props) => {
    return (
        <Button
            role="button"
            aria-label="REFRESH"
            onClick={() => 
                props.getJWT().then(token => {
                    props.showModal({
                        showModal: true,
                        showCloseBtn: false,
                        type: 'updating',
                        title: 'Refreshing',
                        message: 'Please do not close the window while submitting.',
                        useTimer: false,
                    });

                    measureInAppContext({
                        pageTitle:"Ticket Resale Queue",
                        action: "Refresh",
                        context: [{
                            section: "Ticket Resale Queue",
                        }],
                        metricType: "action",
                    });
        
                    let path = props.queueAPIs?.resaleQueueStatus;
                    handleGetQueueStatus(token.jwt.id_token, path).then((response) => {
                        logger.log('[WebviewResaleStatusView] handleGetQueueStatus() response %o', response);
                        props.updateQueueStatus(response)
                    }).catch(error => {
                        logger.error('[WebviewResaleStatusView] handleGetQueueStatus() - error:%o', error);
                        props.showModal({
                            showModal: true,
                            showCloseBtn: true,
                            type: 'error',
                            title: 'Error Refreshing',
                            message: getErrorText(error?.code || error?.data?.response?.error?.code, "Refresh"),
                            useTimer: false,
                        });
                    }).then(() => {
                        props.getResaleQueueConfig(true);
                    });
                }).catch(error => {
                    logger.error('[WebviewResaleStatusView] handleGetQueueStatus() - error:%o', error);
                    props.showModal({
                        showModal: true,
                        showCloseBtn: true,
                        type: 'error',
                        title: 'Error Refreshing',
                        message: getErrorText(error?.code || error?.data?.response?.error?.code, "Refresh"),
                        useTimer: false,
                    });
                })
            }
        >REFRESH</Button>
    );
}

export default RefreshResaleQueue;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import RelatedContent from 'appdir/components/cms/RelatedContent';
import { getAppLink } from 'appdir/components/general/Util';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
/**
 * -----------------------------------------------------------------------------
 * Functional Component: Slamtracker Related Content
 * -----------------------------------------------------------------------------
 */

const SlamtrackerRelatedContent = props => {
    // logger.log('[SlamtrackerRelatedContent] props: %o', props);

    // const relatedContentData = useSelector((state) => op.get(state['Config'].relatedContent));
    const [showRelated, setShowRelated] = useState(null);
    const [displayVideo, setDisplayVideo] = useState(null);
    const [videoData, setVideoData] = useState(null);
    const dispatch = useDispatch();

    // hide or show fixed slamtracker elements if video is playing
    useEffect(() => {
      if(videoData && displayVideo) {
		dispatch(deps.actions.SlamtrackerPanel.video(true));
        // logger.log('[SlamtrackerRelatedContent] slamtrackerContent:%o', slamtrackerContent);
          return () => {
			dispatch(deps.actions.SlamtrackerPanel.video(false));
          };
      }
	}, [displayVideo]);

  /** get url for related content for match and players */
    const formatRelatedContentURL = (data) => {
      let rc_tags = '';
      let statMatch = data;

      if (statMatch.team1?.idA != null) {
        rc_tags += `&tags=${statMatch.team1.idA}`;
      }
      if (statMatch.team1?.idB != null) {
        rc_tags += `&tags=${statMatch.team1.idB}`;
      }
      if (statMatch.team2?.idA != null) {
        rc_tags += `&tags=${statMatch.team2.idA}`;
      }
      if (statMatch.team2?.idB != null) {
        rc_tags += `&tags=${statMatch.team2.idB}`;
      }

      let relatedContentURL = props?.relatedData.matchRelated.replace('<matchId>', statMatch.match_id);
      relatedContentURL = relatedContentURL + rc_tags;

      return relatedContentURL;
    }

    let rc_url = formatRelatedContentURL(props?.statMatch);
    // logger.log('[SlamtrackerRelatedContent] relatedContentData: %o', relatedContentData);

    const navigate = (path, data) => {
		// setUrlLink(path)
		if (data) {
			if (data.type == 'video') {
				// check for video links
				setVideoData(data);
				// logger.log('[SlamtrackerRelatedContent] setVideoData - setVideoData:%o');
				if (values.webview) {
					let videoUrl = `/en_GB/video/media/${data.cmsId}.html`;
					videoUrl = getAppLink(videoUrl, '');
					document.location.href = videoUrl.url;
				} else {
          // logger.log('[SlamtrackerRelatedContent] setDisplayVideo - setDisplayVideo:%o');
					setDisplayVideo(true);
				}
			} else if (values.webview) {
				// check for all other links
				let link = getAppLink(path, '');
				location.href = link.url;
			} else {
				if (props.windowSize == 'mobile') {
					dispatch(deps.actions.SlamtrackerPanel.hide());
				}
				dispatch(deps.actions.MainNav.navigate({ path: path }));
			}
		}
	};

  // handel video close
  const handleVideoClick = event => {
		if (event == 'close') {
      		setVideoData(null);
			setDisplayVideo(false);
		}
  };

  // function to get video and return video player
  const getVideo = data => {
		if (data) {
			let attributes = {
				playerId: 'main',
				cmsId: data.cmsId,
				fullscreenMode: '',
				style: `video-wrapper modal ${data.style}`,
				streamType: 'vod',
				autoplay: true,
				videoUrl: data.videoUrl,
				title: data.title,
				thumb: data.thumb,
				date: data.date,
			};
			return <VideoPlayer key="news" attributes={attributes} onEvent={handleVideoClick} />;
		} else {
			return null;
		}
	};

  return (
		<>
			<div className={`related-content-container ${showRelated ? 'show' : 'hide'}`}>
				<div className="title">Related Content</div>
				<div className="related-content">
					<RelatedContent
						data={{ url: rc_url, type: 'slamtracker', showCarousel: true }}
						goToUrl={navigate}
						slideWidth={275}
						showRelated={shouldShow => setShowRelated(shouldShow)}
						filterData={props.filterData}
					/>
				</div>
			</div>
			{displayVideo && videoData ? getVideo(videoData) : null }
		</>
	);
}

export default SlamtrackerRelatedContent;

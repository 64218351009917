import React from 'react';

const Overlay = (props) => {
    const {
        detectedEnvironment: {
            isMouseDetected = false,
            isTouchDetected = false
        } = {},
        elementDimensions: {
            width = 0,
            height = 0
        } = {},
        position: {
            x = 0,
            y = 0
        } = {},
        isActive = false,
        isPositionOutside = false
    } = props;

    return (
		
        <div className={props.styles.videoControlsTouch}>

		</div>
    );
};

Overlay.defaultProps = {
    shouldShowIsActive: true
};

export default Overlay;
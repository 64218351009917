import React from 'react';
import Button from 'appdir/components/common-ui/Button';
import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';

const Measure = ({ data }) => {
	let handleClick = () => {
		logger.log(`[Measure] handleClick`);
	};

	let callPageView = () => {
		measureInWeb({
			action: MeasurementUtils.ACTION_TYPES.pageView,
			context: [{ pageTitle: 'Test Page' }],
		});
	};

	let callAction = () => {
		measureInWeb({
			action: 'testAction',
			context: [{ testData1: 'Test Action Data1' }, { testData2: 'Test Action Data2' }],
		});

		measureInAppContext({
			pageTitle: 'Test Page',
			action: 'testAction',
			args: ['Test Action Data1', 'Test Action Data2'],
			context: [{ test: 'testData' }, { test2: 'testData2' }],
		});
	};

	return (
		<div className="content-main content ">
			<div className="column-layout content">
				<div className="two-col margin">
					<Button onClick={callPageView} className="solid" style={{ width: '225px' }}>
						Sample Page View
					</Button>
				</div>
				<div className="two-col margin">
					<Button onClick={callAction} className="solid" style={{ width: '225px' }}>
						Sample Action
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Measure;

import MyWimbledon from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/:lang/mywimbledon/:page(allowed_update).html'],
		exact: true,
		component: MyWimbledon,
		section: MENU_ITEMS.mywimbledon,
		onRouteChange: () => {},
		load: params => deps.actions.MyWimbledon.mount(params),
	},
	{
		path: ['/:lang/mywimbledon/:tab?.html', '/:lang/mywimbledon/'],
		exact: true,
		component: MyWimbledon,
		section: MENU_ITEMS.mywimbledon,
		onRouteChange: () => {},
		load: params => deps.actions.MyWimbledon.mount(params),
	}
];

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MyPlayersWrapper from './MyPlayersWrapper';
import WimLink from 'appdir/components/general/WimLink';
import PageNav from 'appdir/components/common-ui/PageNav';
import MeasurementUtils from 'appdir/lib/analytics';
import Stripes from 'appdir/components/common-ui/Stripes';

/**
 * 
 * @param {*} props 
 *    let commonDataConfig = {
		...this.props.sharedDataConfig['yourConfigId'], 
		key:'1101',
		path: this.props.sharedDataConfig['commonDataConfig'].path.replace('<matchId>','1101')
	}
	this.props
		.checkExpired(commonDataConfig)
		.then(response => {
			logger.log('[PowerIndexLeaderboard] componentDidUpdate yourConfigId - response:%o', response);
			if (response.status == 'expired') {
				this.props.update(commonDataConfig);
			}
		})
		.catch(error => {
			logger.error('[PowerIndexLeaderboard] componentDidUpdate yourConfigId - :o', error);
		});
 */
const MyPlayers = props => {
	const { sharedDataConfig, homepage, otherData, recommendedPlayersConfig, scoringData } = useSelector(
		state => state['Config']
	);
	const { playerProfileImagePath } = otherData;
	let HomepageFavorites = useSelector(state => state['Controller']['favourites']['players']);
	const EditorPicks = useSelector(state => state['Config']['recommendedPlayers']);
	const myPlayersTab = useSelector(state => state['Config']['homepage']['myPlayersTab']);
	const highlightPath = scoringData['aiHighlights']['path'];
	const [state, setState] = useState({
		tab: 'favourites',
	});
	let navClasses = '';
	const pageNavItems = [
		{ name: 'YOUR FAVOURITES', value: 'favourites' },
		{ name: 'PICKED FOR YOU', value: 'picked' },
		{ name: 'YOUR HIGHLIGHTS', value: 'highlights' },
	];

	//removing the tabs that have been disabled
	if (!myPlayersTab['showPicks']) {
		let removeTabIndex = pageNavItems.findIndex(ele => ele.value == 'picked');
		pageNavItems.splice(removeTabIndex, 1);
	}

	if (!myPlayersTab['showPlayerHighlights']) {
		let removeTabIndex = pageNavItems.findIndex(ele => ele.value == 'highlights');
		pageNavItems.splice(removeTabIndex, 1);
	}

	if (pageNavItems.length <= 1) {
		navClasses = 'center';
	}

	const updateActiveMenuItem = param => {
		MeasurementUtils.dispatchMeasurementCall('favouritesCarousel', param);
		setState({
			...state,
			tab: param,
		});
	};

	return (
		<section>
			<div className="homepage-favorites">
				<a className="skip-main" href="#content-filters">
					Skip to next section
				</a>
				<div className="homepage-favorites-text">
					<PageNav
						menuItems={pageNavItems}
						classes={navClasses}
						isActive={state.tab}
						clickFn={updateActiveMenuItem}
					/>
					<WimLink
						style="set-favorites"
						className="set-favorites"
						to="/en_GB/players/index.html"
						title="set favorites">
						<div className="set-favorites-container">
							<i className="wim-icon-gear" />
							<div
								className="set-favorites"
								tabIndex={0}
								alt="Set your Favourites"
								aria-label="Set your Favourites">
								Set your Favourites
							</div>
						</div>
					</WimLink>
				</div>
				<MyPlayersWrapper
					apiPath={sharedDataConfig['playerStatus']}
					matchInsightsMatchesPath={sharedDataConfig['matchInsightsMatches']}
					powerRankingsPath={sharedDataConfig['powerIndex']}
					homepageFavorites={[...HomepageFavorites]}
					editorsPicks={[...EditorPicks]}
					recommendedPlayersPath={recommendedPlayersConfig['recommended']['path']}
					recommendedPlayersRankingsPath={recommendedPlayersConfig['powerIndex']['path']}
					highlightPath={highlightPath}
					config={{ ...homepage, playerImg: playerProfileImagePath }}
					tab={state.tab}
				/>
				<Stripes color="green" margin={true} />
			</div>
		</section>
	);
};

export default MyPlayers;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageNav from 'appdir/components/common-ui/PageNav';
import WimRedirect from 'appdir/components/general/WimRedirect';
import deps from 'dependencies';
import op from 'object-path';
import isEqual from 'lodash/isEqual';

const TabNavigation = ({ data }) => {
	//logger.count('[TabNavigation] - render count');
	const [redirect, setRedirect] = useState();
	const [open, setOpen] = useState(undefined);
	const [tabs, setTabs] = useState([]);
	const CommonData = useSelector(state => state.CommonData, isEqual);
	const SharedDataConfig = useSelector(state => state.Config.sharedDataConfig, isEqual);
	const location = useLocation();

	const dispatch = useDispatch();

	const clickHandler = val => {
		logger.log('[TabNavigation] clickHandler - val:%0', val);

		if (val.indexOf('http') == 0) {
			let url = val.split('?qs=');
			url = url[0] + '?qs=' + encodeURIComponent(url[1]);
			document.location.href = url;
		} else {
			setRedirect(val);
		}
	};

	const checkExpired = key => {
		return dispatch(deps.actions.CommonData.checkExpired(key));
	};

	useEffect(() => {
		logger.log('[TabNavigation] useEffect - sharedData:%o', SharedDataConfig);
		if (SharedDataConfig) {
			checkExpired(SharedDataConfig['tabNavigation']).then(resp => {
				if (resp.status == 'expired') {
					//logger.log('[TabNavigation] CommonData - expired');
					dispatch(deps.actions.CommonData.update(SharedDataConfig['tabNavigation']));
				} else if (resp.status == 'loaded') {
					//logger.log('[TabNavigation] CommonData - result:%o', resp.result);
					setTabs(resp.result?.[data.key]);
				}
			});
		}
	}, [SharedDataConfig, CommonData['tabNavigation']]);

	useEffect(() => {
		logger.log('[TabNavigation] location:%o', location.pathname);
		setOpen(location.pathname);
	}, [data.key]);

	logger.log('[TabNavigation] data:%o open:%o tabs:%o', data, open, tabs);

	//logger.log('[TabNavigation] render redirect:%o open:%o', redirect, open);
	if (!tabs) {
		return null;
	} else if (redirect) {
		return <WimRedirect to={redirect} />;
	} else {
		return (
			<div className="tab-navigation">
				<PageNav
					menuItems={tabs}
					classes={tabs.length > 6 ? 'smaller-text' : ''}
					isActive={open}
					clickFn={clickHandler}
					default={data.default || 'Sections'}
					useLink={true}
				/>
			</div>
		);
	}
};

export default TabNavigation;

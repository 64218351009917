import React from 'react';
import Headshot from 'appdir/components/common-ui/Headshot';
import CenterMarkTitle from 'appdir/components/common-ui/CenterMarkTitle';
import Collapsible from 'appdir/components/cms/CollapsibleComponent';
import TabNavigation from 'appdir/components/cms/TabNavigation';
import Divider from 'appdir/components/common-ui/Divider';
import GenericCarousel from 'appdir/components/common-ui/GenericCarousel';
import NewFlag from 'appdir/components/common-ui/NewFlag';

const headshotHolderStyle1 = {
	"width": "390px", 
	"height":"200px",
	"border": "black",
    "border-block-width": "2px",
    "border-style": "solid"
}

const headshotHolderStyle2 = {
	"width": "100% ", 
	"height":"200px",
	"background-image": "url('https://www-cdt.wimbledon.com/assets/images/tickets/test_h_BlueAndWhiteFlowers_No1Court.png')", 
	"background-size": "cover"
}

const sliderSettings = {
	dots: false,
	arrows: true,
	infinite: true,
	speed: 500,
	slidesToScroll: 1,
	autoplay: false,
	variableWidth: false,
	className: 'shopSlider',
};

const CommonUI = ({ currentUser }) => {
	logger.log('[CommonUI] currentUser:%o', currentUser);

	return (
		<div className="content-main column-layout news article ">
			<div className="four-col ">
				<strong>Divider & CenterMarkTitle</strong><br/><br/>
				<Divider />
				<CenterMarkTitle text="Category Title" />
			</div>
			<div className='four-col' >
				<strong>NewFlag</strong>
				<div style={{position: "relative", margin: "20px 0", height: "5px"}}>
					<NewFlag
						data={{
							url: "/", //content url
							contentId: 1234, //content id
							displayDate: Date.now(), //content date
						}}
					/>
				</div>
			</div>
			<div className='four-col'>
				<strong>Generic Carousel</strong><br/><br/>
				<GenericCarousel sliderSettings={sliderSettings} className={"sample-carousel"} >
					<div key={"gen-carousel-1"}>1</div>
					<div key={"gen-carousel-2"}>2</div>
					<div key={"gen-carousel-3"}>3</div>
				</GenericCarousel>
			</div>
			<div className="four-col ">
				<strong>TabNavigation</strong><br/><br/>
				<TabNavigation data={{ key: 'memberBookings' }} />
			</div>
			<div className="four-col ">
				<strong>Collapsible</strong><br/><br/>
				<Collapsible
					data={{ style: 'test', type: null, title: 'Ballot Information', text: '<h1>Test part two</h1>' }}
				/>
			</div>
			<div className="four-col ">
				<strong>Headshot</strong><br/><br/>
				Style - Round, displays at 100% height and centered within parent, and maintains square aspect ratio<br/>
				<div style={headshotHolderStyle1}>
					<Headshot id="atpb001" type="round" bg={false} />
				</div>
				Style - Full, displays at 100% height and centered within parent, and maintains wide aspect ratio<br/>
				<div style={headshotHolderStyle2}>
					<Headshot id="atpb001" type="full" bg={false} />
				</div>
			</div>			
		</div>

	);
};

export default CommonUI;

/**
 * Imports
 */
import React, { Component } from 'react';
import WimLink from 'appdir/components/general/WimLink';
/**
 *
 * Class Component: Facts
 *
 */

class Facts extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	render() {
		//logger.log('[Facts] state:%o', this.state);

		let data = this.state.data;

		return (
			<div className={`facts ${data.style || 'four-col'}`}>
				{data.facts.fact.map((d, i) => {
					return (
						<div key={i} className="fact">
							<WimLink to={d.link}>
								<div className="fact-title">{d.title}</div>
							</WimLink>
							<WimLink to={d.link}>
								<div className="fact-description">{d.text}</div>
							</WimLink>
						</div>
					);
				})}
			</div>
		);
	}
}

export default Facts;

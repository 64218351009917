import React, { Component, Fragment } from 'react';

import { screenset } from 'appdir/components/general/Gigya/utils';
import axios from 'axios';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import Button from 'appdir/components/common-ui/Button';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
class DebentureProspect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
		};

		//logger.log('[RegisterButton-DebentureProspect] constructor - state:%o context:%o', this.state, this.context);
	}
	componentDidMount() {
		//logger.log('[RegisterButton-DebentureProspect] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log("[RegisterButton-DebentureProspect] componentDidUpdate - state:%o", this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[RegisterButton-DebentureProspect] componentWillReceiveProps - nextProps:%o', nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps.state,
			};
		});
	}

	register() {
		this.props.register('deb_prospect', {
			label: '',
			status: '',
			email: '',
		});
	}

	clickLogin(e) {
		e.preventDefault();
		this.props.openScreen({
			screen: 'login_screen',
			redirect: false,
			showSuccess: false,
			cid: 'debenture_prospect',
		});
	}

	render() {
		logger.log('[RegisterButton-DebentureProspect] render - state:%o', this.state);

		let responseLink = op.get(this.state, 'registerResponse.link', false);
		let completePath = responseLink ? responseLink.replace(/https?:\/\/[^\/]+/i, '') : false;
		let debRole = op.get(this.state, 'currentUser.roles', []).filter(d => d.roleId == 1000260);
		let debProspectRole = op.get(this.state, 'currentUser.roles', []).filter(d => d.roleId == 1000020);
		let country = op.get(this.state, 'currentUser.profile.country', '');

		//logger.log('[RegisterButton-DebentureProspect] render - debRole:%o', debRole);

		return (
			<div className={`${this.state.style} center`}>
				{!this.state.loggedIn && !this.state.gigyaLoaded ? null : !this.state.loggedIn &&
				  this.state.gigyaLoaded ? (
					<h3>
						Please{' '}
						<a href="" onClick={e => this.clickLogin(e)}>
							login to / join <MyWimbledonTitle />
						</a>{' '}
						to register your interest.
					</h3>
				) : !(country.toLowerCase() == 'uk' || country.toLowerCase() == 'gb') ? (
					<h3>
						Due to applicable securities laws and regulations, we are sorry to inform you that only new
						applicants who are UK residents may register their interest and apply for debentures.
					</h3>
				) : debRole.length >= 1 || debProspectRole.length >= 1 ? (
					<h3>You are already registered to receive future debenture information.</h3>
				) : !completePath ? (
					<div
						className="generic-button_wrapper"
						onClick={() => {
							this.register();
						}}>
						<div className={'generic-button btn solid center'}>
							<div className="content">REGISTER</div>
							<div className="border"></div>
						</div>
					</div>
				) : (
					<Fragment>
						<WimRedirect to={completePath} />
					</Fragment>
				)}
			</div>
		);
	}
}

export default DebentureProspect;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.CONTENT_LOAD:
			newState = {
				...state,
				...action.data,
				menuLink: null,
				// data: ''
			};
			//logger.log('[DrawsArchiveContentPage] CONTENT_LOAD - newState:%o',newState);
			return newState;
			break;

		case deps.actionTypes.CONTENT_UNMOUNT:
			newState = {
				...state,
				...action.data,
				// data: ''
			};
			//logger.log('[DrawsArchiveContentPage] CONTENT_UNMOUNT - newState:%o',newState);
			return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_CONTENT_PAGE_FILTER:
			logger.log('[DrawsArchiveContentPage] DRAWSARCHIVE_CONTENT_PAGE_FILTER - data:%o', action.data);

			let filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};

			logger.log('[DrawsArchiveContentPage] DRAWSARCHIVE_CONTENT_PAGE_FILTER - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

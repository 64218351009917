/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import shuffle from 'lodash/shuffle';

/**
 * -----------------------------------------------------------------------------
 * React Component: RecommendedPlayer
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['RecommendedPlayer'],
		recommendedPlayersConfig: state['Config'].recommendedPlayersConfig,
		editorsPicks: state['Config'].recommendedPlayers,
		favourites: state['Controller'].favourites.players,
		// sharedDataConfig: state['Config'].sharedDataConfig,
		// powerIndexData: state['CommonData']['powerIndex'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	// checkExpired: (dataConfig) => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	// update: (params) => dispatch(deps.actions.CommonData.update(params)),
});

class RecommendedPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
		logger.log('[RecommendedPlayer] constructor - props:%o', this.props);
		this.getRecommendedData = this.getRecommendedData.bind(this);
	}

	getRecommendedData() {
		const request1 = axios.get(this.props.recommendedPlayersConfig.powerIndex.path);
		const request2 = axios.get(this.props.recommendedPlayersConfig.recommended.path);
		axios.all([request1, request2]).then(
			axios.spread((res1, res2) => {
				// logger.log('[RecommendedPlayer] getRecommendedData - data1:%o, data2:%o', res1.data, res2.data);
				let powerIndexPlayers = res1.data;
				let relatedPlayers = res2.data;
				let { favourites } = this.props;

				//step 1 - filter power index players, remove favs from list and pick 1 of each type
				let idxNoFav = reject(powerIndexPlayers, v => includes(favourites, v.playerid)); //filter out favs
				let idxShuffle = shuffle(idxNoFav); //randomize array
				let idxUniq = uniqBy(idxShuffle, 'reason'); //select only 1 of each type
				let idxFin = idxUniq.map(item => {
					return { playerid: item.playerid, reason: 'power_index' };
				}); //make an array with only ids
				// logger.log('[RecommendedPlayer] getRecommendedData - idxFin', idxFin);

				//step 2 - filter editors picks and remove favs and power index players
				let picsNoFav = this.props.editorsPicks.filter(v => !favourites.includes(v));
				let filteredPicks = picsNoFav.filter(v => !idxFin.includes(v));
				filteredPicks = filteredPicks.map(i => {
					return { playerid: i, reason: 'editor_picks' };
				});

				//step 3 - get related favs
				favourites = shuffle(favourites); //shuffle the favs
				favourites = favourites.slice(0, 5); //only need 5 max
				let allRec = favourites.map(i => {
					return relatedPlayers[i].map(i => {
						return i.playerid;
					});
				}); //get all the related players
				allRec = [].concat.apply([], allRec); //flatten the array
				allRec = uniq(allRec); //uniques only
				allRec = allRec.filter(v => !favourites.includes(v)); //remove favourites
				allRec = allRec.filter(v => !idxFin.includes(v)); //remove anyone from step 1
				allRec = allRec.filter(v => !filteredPicks.includes(v)); //remove anyone from step 2
				allRec = shuffle(allRec); //shuffle it
				allRec = allRec.slice(0, 4); //only need 4
				allRec = allRec.map(i => {
					return { playerid: i, reason: 'recommended' };
				});
				// logger.log('[RecommendedPlayer] getRecommendedData - allRec', allRec);

				//step 4 - put all the id's into one array and shuffle again
				let recPlayersOut = allRec.concat(idxFin).concat(filteredPicks);
				recPlayersOut = shuffle(recPlayersOut);

				logger.log('[RecommendedPlayer] getRecommendedData - recPlayersOut', recPlayersOut);

				this.setState({ recommendedPlayers: recPlayersOut });
			})
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (isEmpty(prevProps.recommendedPlayersConfig) && !isEmpty(this.props.recommendedPlayersConfig)) {
			this.getRecommendedData();
		}
	}

	render() {
		logger.log('[RecommendedPlayer] render - props:%o', this.props);
		logger.log('[RecommendedPlayer] render - state:%o', this.state);
		if (isEmpty(this.state.recommendedPlayers)) {
			return <LoadingIndicator />;
		} else {
			return (
				<Fragment>
					{this.state.recommendedPlayers.map(i => {
						return <p>{`${i.playerid} - ${i.reason}`}</p>;
					})}
				</Fragment>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedPlayer);

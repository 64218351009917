import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class Live extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			active: true
		}
		this.styles = props.styles;

		this.onIsLive = this.onIsLive.bind(this);
		this.onPause = this.onPause.bind(this);

		this.amp = this.props.amp;
		this.addAmpListeners();

		//logger.log('Live: constructor - state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//logger.log('ProgressBar: componentDidUpdate - amp:%o', this.props.amp);
		if (this.props.amp.multitonKey != prevProps.amp.multitonKey){
			this.amp = this.props.amp;
			this.addAmpListeners();
		}
	}

	componentWillUnmount() {
		//logger.log('Live: componentWillUnmount - amp:%o', this.amp);
		if (this.amp) {
			//this.amp.removeEventListener('islive', this.onIsLive);
			this.amp = null;
		}
	}

	addAmpListeners() {
		//logger.log('Live: addAmpListeners - amp:%o', this.amp);
	
		//this.amp.addEventListener('timeupdate', this.onTimeUpdate);
		this.amp.addEventListener('islive', this.onIsLive);
		this.amp.addEventListener('pause', this.onPause);
	}

	onPause(data) {
		//logger.info('Controls: onPause - data:%o', data);
		this.setState({
			active: false
		});
	};

	setLiveState(live) {
		if (live)
			liveBtn.setAttribute('data-state', 'active');
		else
			liveBtn.setAttribute('data-state', '');

		this.setState({
			active: live
		});

		if ((!playingLive && live) || (playingLive && !live)) {
			var mode = 'live';
			if (!live) {
				mode = 'dvr';
			}
			$(state.container).trigger('player:VideoModeUpdate', { 'prop': null, 'mode': mode });
		}

		playingLive = live;
	};

	onIsLive(event) {
		logger.log('Live: onIsLive - event:%o', event);
		if (this.amp) {
			this.setState({
				active: event.data
			});
		}
	}


	clickHandler(event) {
		if (!this.state.active && this.amp) {
			this.amp.goLive();
		}
	}
	
	render() {
		//logger.log('Live: render - state:%o', this.state);
		
		let styles = this.state.styles;
		let inline = '';

		let data_state = '';
		
		//if the state is active, or type is 'live', then make button active (red highlight, shouldn't go inactive if not dvr) 
		if(this.state.active || this.amp.media.temporalType == 'live') {
			data_state = 'active';
		}
		else {
			data_state = '';
		}

		if(this.state.first) {
			inline = {
				margin: "0 0 0 10px"
			}
		}
		
		return (
			<button 
			className={`${this.styles.icon} ${this.styles.live}`} 
				style={{inline}} 
				data-state={data_state}
				title="Live"
				onClick={() => this.clickHandler()}>
			</button>
		)
	}
	
}

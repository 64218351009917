/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ShotQualityItem
 * 
 * currently used in slamtracker head to head section
 * 
 * props:
 *   players: header text (required)
 *   shot insight data: required
 * -----------------------------------------------------------------------------
 */
const ShotQualityItem = props => {
    // logger.log('[ShotQualityItem] props:%o', props);

    const getBarWidth = (w) => {
        /** 
         * max value of 10 (58% of the total area width) - this function
         * determines the percentage of the total space for the bar width
         * **/

        let percentage_val = parseInt((w/10) * 58);
        
        return percentage_val;
    }

    let { players, shotData, shotType } = props;
    const shotName = shotType.substr(0, shotType.lastIndexOf("_"));
    let p1_width = getBarWidth(shotData.player_average.p1);
    let p2_width = getBarWidth(shotData.player_average.p2);
    let avg_width = getBarWidth(shotData.championship_average);

    // logger.log('[ShotQualityItem] p1_width:%o, p2_width:%o', p1_width, p2_width);

    return (
        <div className='shotquality-item'>
            <div className="shotquality-item-row header">{shotName}</div>
            {p1_width > 0 && (
                <div className="shotquality-item-row p1">
                    <div className="label">{players.p1}</div>
                    <div className="bar" style={{width: `${p1_width}%`}}></div>
                    <div className="number">{shotData.player_average.p1}</div>
                </div>
            )}
            {p2_width > 0 && (
                <div className="shotquality-item-row p2">
                    <div className="label">{players.p2}</div>
                    <div className="bar" style={{width: `${p2_width}%`}}></div>
                    <div className="number">{shotData.player_average.p2}</div>
                </div>
            )}
            <div className="shotquality-item-row avg">
                <div className="label">Average</div>
                <div className="bar"  style={{width: `${avg_width}%`}}></div>
                <div className="number">{shotData.championship_average}</div>
            </div>
        </div>
    )
};

export default ShotQualityItem;
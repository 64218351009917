import React, { Component } from 'react';
import RadioPlayer from './RadioPlayer';
import isEqual from 'lodash/isEqual';
import MeasurementUtils from 'appdir/lib/analytics';

class RadioPlayersWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Radio Player',
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		logger.info(
			'[RadioPlayersWrapper] shouldComponentUpdate - isEqual:%o nextState:%o',
			isEqual(this.props.RadioPlayerProps.radioConfig, nextState.RadioPlayerProps.radioConfig),
			nextState
		);

		if (isEqual(this.props.RadioPlayerProps.radioConfig, nextState.RadioPlayerProps.radioConfig) == false) {
			return true;
		}

		return false;
	}

	onReady(player) {
		logger.log('[RadioPlayersWrapper] onReady - player:%o', player);

		// all parent page func to store all the player handles
		this.props.onReady(player);
	}

	onPlay(radioData) {
		logger.log('[RadioPlayersWrapper] onPlay radioData:%o', radioData);
	}

	onPause(radioData) {
		logger.log('[RadioPlayersWrapper] onPause radioData:%o', radioData);
	}

	render() {
		const { playerNumber, RadioPlayerProps } = this.props;
		const { radioConfig, playerConfig, colors } = RadioPlayerProps;
		const playerWrapper = [];

		logger.log('[RadioPlayersWrapper] render props:%o', this.props);

		for (let i = 0; i < parseInt(playerNumber); i++) {
			playerWrapper.push(
				<div key={radioConfig[i].id}>
					<div className="ecpRadio">
						<RadioPlayer
							id={`station${parseInt(i + 1)}`}
							playerConfig={playerConfig}
							videoConfig={radioConfig[i]}
							onReady={amp => this.onReady(amp)}
							onPause={data => this.onPause(data)}
							onPlay={data => this.onPlay(data)}
							metricsData={radioConfig[i]}
							colors={colors}
						/>
					</div>
				</div>
			);
		}

		return playerWrapper;
	}
}

export default RadioPlayersWrapper;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import Template from 'appdir/components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import ViewToggle from './ViewToggle';
import ReplaySelect from './ReplaySelect';
import SocialEmbed from 'appdir/components/content/SocialEmbed';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import Button from 'appdir/components/common-ui/Button';

import VideoPlayer from 'appdir/components/general/VideoPlayer';
import MainVideoChannel from 'appdir/components/general/MainVideoChannel';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveVideo
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveVideo'],
		...props,
		stubs: state['Config'].stubPages,
		geos: state['Sidepanel'].geos,
		video: state['ActiveData']['video'],
		windowSize: state['PageHeader'].windowSize,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveVideo.mount()),
	unmount: () => dispatch(deps.actions.LiveVideo.unmount()),
	select: selected => dispatch(deps.actions.LiveVideo.select(selected)),
});

class LiveVideo extends Component {
	constructor(props) {
		super(props);

		logger.log('[LiveVideo] constructor - props:%o', props);

		this.onVideoEvent = this.onVideoEvent.bind(this);

		this.state = {
			...props,
		};

		this.timeFormat = 'h:mm A z';
	}

	componentDidMount() {
		logger.log('[LiveVideo] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		//logger.log('[LiveVideo] componentWillUnmount');
		this.props.unmount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[LiveVideo] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);
		let videolist = [].concat(op.get(nextProps, 'video.live', []));
		videolist.push({
			title: 'ESPN',
			measurement_title: 'ESPN_1',
			code: 'espn_1',
			show: true,
			live: true,
			images: {
				slate: 'https://assets.wimbledon.com/images/apps/video/espn_1Bg.jpg',
				thumb: 'https://assets.wimbledon.com/images/apps/video/espn_1Bg_small.jpg',
			},
		});

		let availableVideo = videolist.filter(video => {
			if (!nextProps.geos.set) {
				return false;
			} else {
				if (video.show && nextProps.geos.available.includes(video.code)) {
					return true;
				} else {
					return false;
				}
			}
			return video.show;
		});

		let selectedVideo = null;

		if (availableVideo.length > 0) {
			selectedVideo = nextProps.selected == 'index' ? availableVideo[0].code : nextProps.selected;
		}

		logger.log('[LiveVideo] getDerivedStateFromProps - geoAvail:%o finalAvail:%o', nextProps.geos.available, availableVideo);

		let newState = {
			...nextProps,
			availableVideo: availableVideo,
			selectedVideo: selectedVideo,
		};

		if (isEmpty(newState)) {
			newState = null;
		}
		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[LiveVideo] componentDidUpdate - state:%o loadedJson:%o', this.state);

		if (
			op.get(this, 'state.geos.available', []).includes('espn_1') &&
			this.state.stubs.livevideo.stub == 'quals' &&
			this.state.selected == 'index'
		) {
			this.onNavClick('espn');
		}

		if (this.state.selectedVideo != prevState.selectedVideo) {
			let video = this.state.availableVideo.filter(video => {
				return video.code == this.state.selectedVideo;
			})[0];
			logger.log('[LiveVideo] componentDidUpdate - newChannel:%o', this.state.selected);
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: `Live Video - ${video.title}`,
			});
		}
	}

	onVideoEvent(event) {
		logger.info('[LiveVideo] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				directVideo: '',
			});
		}
	}

	getEspn() {
		if (this.state.windowSize !== 'mobile') {
			return (
				<div className="espn-holder">
					<div className="espn-frame">
						<iframe
							src={this.state.espnUrl}
							scrolling="no"
							height="800"
							width="100%"
							id="espn-player"
							frameBorder="0"
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div className="espn-holder">
					<div className="espn-frame-link">
						<Button
							to="https://www.espn.com/watch/catalog/6929e7a4-2c40-3f82-a710-42baae9472c6/wimbledon"
							className="solid"
							style={{ width: '225px' }}>
							Open ESPN Player
						</Button>
					</div>
				</div>
			);
		}
	}

	getChannel() {
		let video = this.state.availableVideo.filter(video => {
			return video.code == this.state.selectedVideo;
		})[0];

		//selected video is available
		if (video) {
			if (video.live && video.code != 'espn_1') {
				let attributes = {
					playerId: 'main',
					cmsId: video.code,
					videoUrl: video.media.desktop[0].url,
					title: video.title,
					thumb: video.images.slate,
					style: '',
					type: 'live',
					aspect: 'wide',
					autoplay: false,
				};

				logger.log('[LiveVideo] getChannel - video:%o', video);
				return <MainVideoChannel position="main" name="main" channel={video} />;
				/** 
					onEvent={this.onEvent}
					onReady={this.onPlayerReady}
					onVideoEvent={this.onVideoEvent}
					onUserAction={this.onUserAction}
				*/
			} else if (video.live && video.code == 'espn_1') {
				logger.log('[LiveVideo] getChannel - espn');
				return <>{this.getEspn()}</>;
			} else {
				logger.log('[LiveVideo] getChannel - video:%o', video);

				let startTime = moment(parseFloat(video.startTime))
					.tz('Europe/London')
					.format(this.timeFormat);
				let endTime = moment(parseFloat(video.endTime))
					.tz('Europe/London')
					.format(this.timeFormat);

				return (
					<div className="live-video-stub">
						<div className="live-video-stubtext">
							<div className="live-video-stubtext--wrap">
								<div className="live-video-stubtext--title">OFF AIR</div>
								<div className="live-video-stubtext--msg">
									{`${video.title} will be available`}
									<br />
									{`from ${startTime} to ${endTime}`}
								</div>
								<hr className="line" />
								<WimLink to="/en_GB/video/index.html" style={'white'}>
									<div className="live-video-stubtext--msg">
										Until then you may enjoy previous Championships through our video gallery.
									</div>
									<i className="wim-icon-video" />
								</WimLink>
							</div>
						</div>
					</div>
				);
			}
		}
		//if no video avaialble (geoblocked)
		else {
			return (
				<div className="live-video-stub">
					<div className="live-video-stubtext">
						<div className="live-video-stubtext--wrap">
							<div className="live-video-stubtext--msg">{`No video is available at the current time`}</div>
							<hr className="line" />
							<WimLink to="/en_GB/video/index.html" style={'white'}>
								<div className="live-video-stubtext--msg">
									Please enjoy previous Championships through our video gallery.
								</div>
								<i className="wim-icon-video" />
							</WimLink>
						</div>
					</div>
				</div>
			);
		}
	}

	renderReplayDropdown() {
		let replaysYouTube = this.state.replaysYouTube;
		let replays = this.state.replays;
		let dropdownVals;

		// if YouTube exists, then use YouTube
		if (replaysYouTube.length > 0) {
			dropdownVals = replaysYouTube.map(item => ({ name: item.title, value: item.url }));
			// logger.log('[LiveVideo] renderReplayDropdown - YouTube dropdownVals:%o', dropdownVals);
		} else if (replays.length > 0) {
			dropdownVals = replays.map(item => ({ name: item.title, value: item.url }));
			// logger.log('[LiveVideo] renderReplayDropdown - replays dropdownVals:%o', dropdownVals);
		}

		if (dropdownVals) {
			return (
				<div className="replay-selector">
					<ReplaySelect attributes={dropdownVals} filters={this.state.filters} />
				</div>
			);
		}
	}

	onVideoEvent(event) {
		//logger.log(`[LiveVideo] onVideoEvent - event:%o, this:%o`, event, this);
		// if (event == 'close' && this.state.videoId !== '') {
		// 	this.setState({
		// 		videoId: '',
		// 	});
		// }
	}

	render() {
		logger.log('[LiveVideo] render - state:%o', this.state);
		logger.log('[LiveVideo] render - available:%o', this.state.geos.available);

		let header_propsData = {
			headerType: 'liveVideo',
			title: 'LIVE VIDEO',
			shortTitle: 'LIVE VIDEO',
			scroll: false,
			metaTitle: 'Live Video',
			metaDescription:
				'Live video streamed directly from The Championships, Wimbledon.  Live look in on matches during The Championships.',
			metaDate: '',
			metaPlayers: '',
		};

		logger.log('[LiveVideo] render - state:%o', this.state);

		//if live video is stubbed
		if (this.state.stubs?.livevideo?.stub == 'stub') {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main landing">
						<div className="column-layout">
							<div className="live-video-stub">
								<div className="live-video-stubtext">
									<div className="live-video-stubtext--wrap">
										<div className="live-video-stubtext--title">OFF AIR</div>
										<div className="live-video-stubtext--msg">Live video is not yet available.</div>
										<hr className="line" />
										<WimLink to="/en_GB/video/index.html" style={'white'}>
											<div className="live-video-stubtext--msg">
												You may enjoy previous Championships through our video gallery.
											</div>
											<i className="wim-icon-video" />
										</WimLink>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</Template>
			);
		}
		// if have selected video and geos are set
		else if (this.state.selected && this.state.geos.set && this.state.geos.available) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main landing">
						<div className="column-layout">
							<div className="live-video-nav">
								<ViewToggle selected={this.state.selected} videos={this.state.availableVideo} />
							</div>

							{/* {this.state.selected == 'index' && (this.state.replaysYouTube || this.state.replays)
								? this.renderReplayDropdown()
								: null} */}

							{this.getChannel()}
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideo);

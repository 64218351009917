import deps from 'dependencies';

import axios from 'axios';

export default {
	mount: ({ player }) => (dispatch, getState, store) => {
		logger.log('[PlayerProfile] mount - player:%o', player);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const pathObjs = {
				playerInfoPath: Config.drawsArchive.player,
				playerTourInfoPath: Config.scoringData.playersDetail,
				relatedContentPath: Config.relatedContent.playerRelated,
				flagImagePathLarge: Config.otherData.flagImagePathLarge,
				flagImagePathSmall: Config.otherData.flagImagePathSmall,
				playerProfileImagePath: Config.otherData.playerProfileImagePath,
				archiveDrawsAssetsPath: Config.otherData.archiveDrawsAssetsPath,
				archiveDrawsPdfPath: Config.otherData.archiveDrawsPdfPath,
				playersListPath: Config.scoringData.players,
			};

			dispatch({ type: deps.actionTypes.DRAWSARCHIVE_PROFILE_MOUNT, data: pathObjs });
		});
	},

	unmount: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_PROFILE_UNMOUNT, data: data });
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import parse from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: TableComponent
 * -----------------------------------------------------------------------------
 */
const TableComponent = props => {
	let attributes = props.attributes;
	let headers = attributes.headers;
	let mobileHeaders = attributes.mobileHeaders;
	let tableData = attributes.data;
	let tableTitle = attributes.tableTitle ? <div className="title">{attributes.tableTitle}</div> : '';

	let headerClass = mobileHeaders === undefined ? '' : 'mobileHide';

	return (
		<div className={attributes.tableContainer}>
			{attributes.data ? (
				<div className="datatable">
					{tableTitle}
					<div className={`headers ${headerClass}`}>
						{headers.map((d, i) => {
							return (
								<div key={d} className={'cell data' + (i + 1) + ' ' + d}>
									{d}
								</div>
							);
						})}
					</div>
					{renderMobileHeader(mobileHeaders)}
					{tableData.map((d, i) => {
						return (
							<div key={i} className="row">
								{Object.keys(d).map((keyName, keyIndex) => {
									let objValue = d[keyName];
									return (
										<div
											key={keyName}
											className={'cell data' + (keyIndex + 1) + ' ' + keyName + '_wrapper'}>
											{/*ReactHtmlParser*/ objValue ? parse(String(objValue)) : ''}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			) : (
				<div className="datatable">
					{tableTitle}
					<div className="headers" style={{ height: '120px', lineHeight: '120px' }}>
						No Data Available
					</div>
				</div>
			)}
		</div>
	);
};

const renderMobileHeader = mHeaders => {
	if (mHeaders !== undefined) {
		return (
			<div className="headers mobileShow">
				{mHeaders.map((d, i) => {
					return (
						<div key={d} className={'cell data' + (i + 1) + ' ' + d}>
							{d}
						</div>
					);
				})}
			</div>
		);
	}
};

export default TableComponent;

import React, { Component } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { measureInAppContext } from 'appdir/components/general/Analytics';

class HorizontalMenuBar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		//reverting scroll pos for now
		//this.selectedButtonRef = React.createRef();
	}

	//reverting scroll pos for now
	// componentDidMount() {
	// 	if (this.selectedButtonRef.current) {
	// 		// Set focus to the selected day button when page loads - fix for: https://ix-gitlab.events.ibm.com/wimbledon/wimbledon-react2/-/issues/3703
	// 		this.selectedButtonRef.current.focus();
	// 	}
	// }

	componentWillReceiveProps(nextProps) {
		if (!this.props.currentlySelected && nextProps.currentlySelected) {
			measureInAppContext({
				pageTitle:"Tickets",
				context: [
					{"date": nextProps.selectedDateStr?.toString()},
					{"section": "Tickets"},
				],
				metricType: "state",
			});
		}

	}

	onButtonClick(value) {
		this.setState({ selected: value });
		this.props.onSelectDate(value);
		this.props.onTabIndexUpdate(false);

		// send mesaurement call
		let dateSplit = value?.split('-');
		let day = dateSplit[1];
		let ticketType = day === 'UGP' || day === 'QUALS' ? `${day} Ticket` : 'Main Draw Ticket';
		let date = moment(parseInt(dateSplit[0])).utc().format('dddd MMM DD');

		measureInAppContext({
			pageTitle: 'Tickets',
			action: ticketType, // measureAction,
			args: `dateSelected: ${date}`, // measure_args, // dateSelected
		});

	}

	renderMenuItems(index, value) {
		const classNameVal =
			value.props.children[2].props.children.toLowerCase() === 'pass' ? 'hmb-image-large' : 'hmb-image';
		const isSelected = value?.props?.id === this.props.currentlySelected;
		let valSplit = value?.props?.id?.split('-');
		let ariaLabel = `${moment(parseInt(valSplit[0]))
			.utc()
			.format('dddd MMM DD')}, day ${valSplit[1]}. ${
			isSelected ? 'is currently selected' : 'is not currently selected'
		}`;
		return (
			<button
				id={index}
				key={index}
				className={classNameVal}
				//ref={isSelected ? this.selectedButtonRef : null} // Assign the ref to the selected day button
				alt={ariaLabel}
				aria-label={ariaLabel}
				tabIndex={!isSelected && !this.props.tabIndexEnabled ? -1 : 0} // is not currently selected and tabIndexEnabled === false, then -1; otherwise 0
				onClick={() => {
					this.onButtonClick(value.props.id)
				}}
				onBlur={() => {
					// if !tabIndexEnabled set all tab indexs back to 0
					if (!this.props.tabIndexEnabled) this.props.onTabIndexUpdate(true);
				}}>
				{value}
			</button>
		);
	}

	render() {
		const { data } = this.props;

		return (
			<div className="hmb-main">
				<div className="hmb-image-container" id="hmb-image-container">
					{data.map((value, index) => {
						return this.renderMenuItems(index, value);
					})}
				</div>
			</div>
		);
	}
}

export default HorizontalMenuBar;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * ----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import Tournament from './Tournament';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: ControlBar
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.Tournament,
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.Tournament.mount()),
	// update: () => dispatch(deps.actions.Config.update()),
	// updateStatic: item => dispatch(deps.actions.Config.updateStatic(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);

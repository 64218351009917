import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	//logger.log('[SchedulePage] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.SCHEDULEPAGE_LOAD:
			logger.log('[SchedulePage] reducers - action:%o', action);

			filters = {
				...state.filters,
				day: action.data.tournDay,
			};

			newState = {
				...state,
				pdfPath: action.data.pdfPath,
				filters: filters,
			};
			logger.log('[SchedulePage] reducers SCHEDULEPAGE_LOAD - newState:%o', newState);
			return newState;

		case deps.actionTypes.SCHEDULEPAGE_SETDAY:
			logger.log('[SchedulePage] SCHEDULEPAGE_SETDAY - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
			};
			logger.log('[SchedulePage] SCHEDULEPAGE_SETDAY - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

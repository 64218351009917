/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
/**
 * -----------------------------------------------------------------------------
 * React Component: CommonData
 * -----------------------------------------------------------------------------

   This component holds any common data that is shared in various modules where we want to limit
   the amount of i/o.  The idea is if we've already loaded the data, don't load it again unless
   it has expired. The common data is stored in redux

   *** HOW TO USE THIS COMPONENT ***
   
   Step1:
   Add config to the sharedDataConfig object in config_web.json.
   
   sharedDataConfig : {
   		"yourConfigId": {
            "configId":"yourConfigId",
            "path":"your path",
            "refreshSec":3600
        },
   }
   
   Step2:
   In your component that needs CommonData, add the sharedDataConfig and any CommonData you need
   from redux
   
   const mapStateToProps = (state, props) => {
  	return {
  		...state['YOURCOMPONENT'],
  		sharedDataConfig: state['Config'].sharedDataConfig,
  		yourConfigIdData: state['CommonData']['yourConfigId'],
  		...props,
  	};
   };
   
   Step3:
   In your component that needs CommonData, add the following actions
   
   const mapDispatchToProps = (dispatch, props) => ({
  	checkExpired: (dataConfig) => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
  	update: (params) => dispatch(deps.actions.CommonData.update(params)),
   });
   
   Step4:
   In your component that needs CommonData, when you want to load your data call the following.  You only
   need a key if your data needs to be stored by a key (ex: matchId or playerId)
   
   let commonDataConfig = {
		...this.props.sharedDataConfig['yourConfigId'], 
		key:'1101',
		path: this.props.sharedDataConfig['commonDataConfig'].path.replace('<matchId>','1101')
	}
	this.props
		.checkExpired(commonDataConfig)
		.then(response => {
			logger.log('[PowerIndexLeaderboard] componentDidUpdate yourConfigId - response:%o', response);
			if (response.status == 'expired') {
				this.props.update(commonDataConfig);
			}
		})
		.catch(error => {
			logger.error('[PowerIndexLeaderboard] componentDidUpdate yourConfigId - :o', error);
		});
   
 * -----------------------------------------------------------------------------
 */

export default class CommonData extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return null;
	}
}

import React from 'react';
import LivePanel from 'appdir/components/general/LivePanel';
import HomePromos from 'appdir/components/general/HomePromos';
import WeatherStatus from 'appdir/components/general/WeatherStatus';
import WimLink from 'appdir/components/general/WimLink';
import UserRoles from './UserRoles';
import Rolex from './Rolex';
import { checkRole } from 'appdir/components/general/Util/Role';
import {
	MemberSVG,
	CommunitySVG,
	MediaSVG,
	TicketsSVG,
	DebentureSVG,
	PlayerSVG,
	CoachSVG,
	TransportSVG,
	BallotSVG,
} from 'appdir/components/common-ui/SVG';

const StatusPanel = ({ style, myWim, userName, loggedIn, shelfIcons, roles, mode, gigya, getJWT }) => {
	const roleIconNames = {
		debenture: DebentureSVG,
		player: PlayerSVG,
		coach: CoachSVG,
		member: MemberSVG,
		ticket: TicketsSVG,
		media: MediaSVG,
		community: CommunitySVG,
		transport: TransportSVG,
		ballot: BallotSVG,
	};

	//prevent coach landing icon if have player
	let hasPlayerRole = checkRole(roles, [1000050]);
	let hasCoachRole = checkRole(roles, [1000320]);
	if (hasPlayerRole && hasCoachRole) {
		roles = roles.filter(role => (role.key != 'coach' ? true : false));
	}

	const icons = roles
		.filter(role => (role.landingPage ? true : false))
		.sort((a, b) => shelfIcons.titles[a.key].sort - shelfIcons.titles[b.key].sort)
		.map(role => {
			return {
				svg: roleIconNames[role.key],
				name: shelfIcons[role.keySegment].find(role => role.show.includes('landing'))?.title.toUpperCase(),
				path: role.landingPage,
				target: shelfIcons[role.keySegment].find(role => role.show.includes('landing'))?.target,
			};
		});

	let hasMemberRole = checkRole(roles, [1000040]);

	return style == 'small' ? (
		<LivePanel style={style || 'none'} />
	) : (
		<div className={`status-panel ${style}`}>
			{myWim?.active && loggedIn && userName && (
				<div className="welcome">
					<WimLink
						to="/en_GB/mywimbledon/index.html"
						style="white"
						title="my wimbledon">{`Welcome ${userName}`}</WimLink>
					<div className="divider" />
					{icons.length > 0 && (
						<div className="user-role-icons">
							<UserRoles icons={icons} />
							<div className="divider" />
						</div>
					)}
				</div>
			)}

			<LivePanel style={style} />
			{mode?.tournament ? <div className="divider" /> : null}
			<WeatherStatus isTournament={hasMemberRole || mode?.weather} />
			<HomePromos roles={roles} gigya={gigya} getJWT={data => getJWT(data)} isTournament={mode?.tournament} />
			<Rolex />
		</div>
	);
};

export default StatusPanel;

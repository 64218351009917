export const sourceLookup = (source) => {
	let text = '';

	switch (source) {
		case 'favourite':
			text = "Based on Favourite";
			break;
		case 'topRecent':
			text = "Trending"
			break;
		case 'country':
			text = "Based on your location"
			break;
		default:
			break;
	}
	return text;
}
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import op from 'object-path';

import { getStatusConfig } from 'appdir/components/pages/Slamtracker/Utilities';
import Headshot from 'appdir/components/common-ui/Headshot';
import CountryFlagImage from 'appdir/components/common-ui/CountryFlagImage';

import deps from 'dependencies';
import { useDispatch } from 'react-redux';
import { values } from 'appdir/main';
import { getAppLink } from 'appdir/components/general/Util';
import SlamtrackerScoreboard from 'appdir/components/pages/Slamtracker/elements/SlamtrackerScoreboard';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Header
 * -----------------------------------------------------------------------------
 */

const Header = props => {
  // logger.log('[Slamtracker Header] props: %o', props);

  {/* Pre, live, post */}
  const matchStatus = getStatusConfig(props?.statMatch?.statusCode)['status'];
  const ScoreManager = useSelector((state) => op.get(state['ScoreManager']));

//   logger.log('[Slamtracker Header] matchStatus - matchStatus:%o, statuscode:%o', matchStatus, props.statMatch.statusCode );


  const dispatch = useDispatch();
  let { data } = props;
  let courtName;
  if (props.statMatch.statusCode) {
		if (
			props.statMatch.statusCode == 'B' ||
			props.statMatch.statusCode == 'D' ||
			props.statMatch.statusCode == 'E' ||
			props.statMatch.statusCode == 'F' ||
			props.statMatch.statusCode == 'G'
		) {
			courtName = `${props.statMatch.eventName} - ${props.statMatch.roundName}`;
		} else if (
			matchStatus == 'live' ||
			props.statMatch.statusCode == 'A' ||
			props.statMatch.statusCode == 'K' ||
			props.statMatch.statusCode == 'X' ||
			props.statMatch.statusCode == 'Y'
		) {
			courtName = `${props.statMatch.courtName} - ${props.statMatch.roundName}`;
		}
  }

  const mainProfilePath = '/en_GB/players/overview/';
	const archiveProfilePath = './player_profile/';

  const navigate = path => {
		if (values.webview) {
			let link = getAppLink(path, '');
			location.href = link.url;
		} else if (path) {
			if (props.windowSize == 'mobile') {
				dispatch(deps.actions.SlamtrackerPanel.hide());
			}
			dispatch(deps.actions.MainNav.navigate({ path: path }));
		}
  };

  let foundMatch = props?.liveMatches?.find(function(match) {
    return match.match_id == props.statMatch.match_id;
  });

  const scrollContent = document?.querySelector('.slamtrackerPanel-content');
  const [sticky, setSticky] = useState("");

  useEffect(() => {
		// logger.log('[Slamtracker Header] sticky useEffect: %o', scrollContent.scrollHeight);
		if (!window.webview) {
      if(scrollContent) {
        scrollContent.addEventListener('scroll', stickyHeader);
			  return () => {
				scrollContent.removeEventListener('scroll', stickyHeader);
			  };
      }
		}
  }, []);

  const stickyHeader = () => {
    const headerScrollContent = document?.querySelector('.slamtracker-content');
    const scrollContentTop = scrollContent.scrollTop;
    const headerHeight = document?.querySelector('.slamtracker-header').scrollHeight;
    const matchNameHeight = document?.querySelector('.slamtracker-match-name').scrollHeight;
    const channelHeight = document?.querySelector('.slamtrackerPanel-content-channel').scrollHeight;

    // header height + match name height
    let fixedHeader = scrollContentTop > 0 && scrollContentTop > headerHeight - (matchNameHeight + channelHeight) ? 'is-fixed' : '';
    setSticky(fixedHeader);
  };

  const renderCrtIcon = (match, team) => {
		// logger.log('[Slamtracker Header] team:%o, match:%o',team, match);
		let html;
		if (team == 'team1') {
			if (!match.team1.won &&
				!match.team2.won &&
				match.statusCode === 'A'
			) {
				html = (
					<div className="live-score-container">
						<div className="live-score">
							<span>
								{props.statMatch?.scores?.gameScore[0]}
							</span>
						</div>
						<div className="crtIcon currentServer team1">
							{(match.server == 'A' || match.server == 'B') ? <i className="wim-icon-ballSolid" /> : <div className='emptyIconContainer'></div>  }
						</div>
					</div>
				);
			} else {
				html = null;
			}
		}

		if (team == 'team2') {
			if (!match.team1.won &&
				!match.team2.won &&
				match.statusCode === 'A'
			) {
				html = (
					<div className="live-score-container">
						<div className="live-score">
							<span>
								{props.statMatch?.scores?.gameScore[1]}
							</span>
						</div>
						<div className="crtIcon currentServer team2">
							{(match.server == 'C' || match.server == 'D') ? <i className="wim-icon-ballSolid" /> : <div className='emptyIconContainer'></div> }
						</div>
					</div>
				);
			} else {
				html = null;
			}
		}

		return html;
	}

  const getScore = (data) => {
    let matchData = data;
    let setsData = data.scores.sets
    let setScores = [];
    let tiebreak = [];

    if(matchData) {
      let temp;
      setsData.map((sets, i) => {
        sets.map((set, j) => {
          setScores.push(set.scoreDisplay)
          if(j % 2 == 0) {
            setScores.push('-')
          }
          if(matchData.team1.won) {
            if(j % 2 == 1) {
              temp = set.tiebreakDisplay;
            }
          } 
          if(matchData.team2.won) {
              if(j % 2 == 0) {
                temp = set.tiebreakDisplay;
              }
          }
        })
        setScores.push(temp ? `(${temp})` : '')
        setScores.push(' ')
      })
    } 
   
    return(setScores.toString().split(",").join(""))
  } 

  const getMatchStat = (data, matchStatus) => {
    if(matchStatus == 'post'){
      return (
        <span className="match-stat">
          {getScore(data)}
        </span>
		  );
    } else if (matchStatus == 'live') {
      return (
        <span className="match-stat">In Progress</span>
		  );
    } else {
      return (
        null
		  );
    }
  }

  return (
		<div
			className={`slamtracker-header ${matchStatus == 'live' ? 'live' : ''} ${props.statMatch.team1.displayNameB ? 'doubles' : ''}`}>
			<div className="slamtracker-match-name">
				<span>{courtName}</span>
			</div>
			<div className={`slamtracker-match-info ${sticky}`}>
				<div className={`slamtracker-match-court-info`}>
					<span className="match-court-name">
						{matchStatus == 'live' ? 'live' : props.statMatch.courtName}
					</span>
					<span className="match-stat">{getMatchStat(props.statMatch, matchStatus)}</span>
				</div>
				<div className="slamtracker-match-teams">
					<div className="match-team1">
						<div className="match-score-box">
							<div
								className={`headshot-container ${props.statMatch.team1.displayNameB ? 'doubles' : ''}`}>
								<div
									className={`headshot ${!props.statMatch.team1.won && props.statMatch.statusCode == 'D' ? 'not-winner' : ''}`}
									onClick={() => navigate(`${mainProfilePath}${props.statMatch.team1.idA}.html`)}
									role="link">
									<Headshot
										className="link-to-player"
										id={props.statMatch.team1.idA}
										type="round"
										bg={true}
									/>
								</div>
								{props.statMatch.team1.displayNameB ? (
									<div
										className={`headshot team1 ${!props.statMatch.team1.won && props.statMatch.statusCode == 'D' ? 'not-winner' : ''}`}
										onClick={() => navigate(`${mainProfilePath}${props.statMatch.team1.idB}.html`)}
										role="link">
										<Headshot
											className="link-to-player"
											id={props.statMatch.team1.idB}
											type="round"
											bg={true}
										/>
									</div>
								) : null}
								{props.statMatch.team1.won ? (
									<div className="match-winner">
										<i className="wim-icon-check" />
									</div>
								) : null}
							</div>
							{props.statMatch.team1 ? (
								<div className="icon-container">{renderCrtIcon(props.statMatch, 'team1')}</div>
							) : null}
						</div>
						<div className="player-info">
							<div className={`member-one ${props.statMatch.team2.displayNameA ? 'doubles' : ''}`}>
								<div className="player-flag">
									<CountryFlagImage
										attributes={{
											country: props.statMatch.team1.nationA,
											flagImagePath: props.flagImagePath,
										}}
									/>
								</div>
								<div className="player-name">
									<span
										role="link"
										className="link-to-player"
										onClick={() => navigate(`${mainProfilePath}${props.statMatch.team1.idA}.html`)}>
										{props.statMatch.team1.displayNameA}
									</span>
								</div>
								<div className="player-seed">
									<span>{props.statMatch.team1.seed}</span>
								</div>
							</div>
							{props.statMatch.team1.displayNameB ? (
								<div className="member-two">
									<div className="player-flag">
										<CountryFlagImage
											attributes={{
												country: props.statMatch.team1.nationB,
												flagImagePath: props.flagImagePath,
											}}
										/>
									</div>
									<div className="player-name">
										<span
											role="link"
											className="link-to-player"
											onClick={() =>
												navigate(`${mainProfilePath}${props.statMatch.team1.idB}.html`)
											}>
											{props.statMatch.team1.displayNameB}
										</span>
									</div>
									<div className="player-seed">
										<span>{props.statMatch.team1.seed}</span>
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div className="match-team2">
						<div className="match-score-box">
							<div
								className={`headshot-container ${props.statMatch.team2.displayNameB ? 'doubles' : ''}`}>
								{props.statMatch.team2 ? (
									<div className="icon-container">{renderCrtIcon(props.statMatch, 'team2')}</div>
								) : null}
								{props.statMatch.team2.won ? (
									<div className="match-winner">
										<i className="wim-icon-check" />
									</div>
								) : null}

								<div
									className={`headshot ${!props.statMatch.team2.won && props.statMatch.statusCode == 'D' ? 'not-winner' : ''}`}
									onClick={() => navigate(`${mainProfilePath}${props.statMatch.team2.idA}.html`)}
									role="link">
									<Headshot
										className="link-to-player"
										id={props.statMatch.team2.idA}
										type="round"
										bg={true}
									/>
								</div>
								{props.statMatch.team2.displayNameB ? (
									<div
										className={`headshot team2 ${!props.statMatch.team2.won && props.statMatch.statusCode == 'D' ? 'not-winner' : ''}`}
										onClick={() => navigate(`${mainProfilePath}${props.statMatch.team2.idB}.html`)}
										role="link">
										<Headshot
											className="link-to-player"
											id={props.statMatch.team2.idB}
											type="round"
											bg={true}
										/>
									</div>
								) : null}
							</div>
						</div>
						<div className="player-info">
							<div className={`member-one ${props.statMatch.team2.displayNameB ? 'doubles' : ''}`}>
								<div className="player-flag">
									<CountryFlagImage
										attributes={{
											country: props.statMatch.team2.nationA,
											flagImagePath: props.flagImagePath,
										}}
									/>
								</div>
								<div className="player-name">
									<span
										role="link"
										className="link-to-player"
										onClick={() => navigate(`${mainProfilePath}${props.statMatch.team2.idA}.html`)}>
										{props.statMatch.team2.displayNameA}
									</span>
								</div>
								<div className="player-seed">
									<span>{props.statMatch.team2.seed}</span>
								</div>
							</div>
							{props.statMatch.team2.displayNameB ? (
								<div className="member-two">
									<div className="player-flag">
										<CountryFlagImage
											attributes={{
												country: props.statMatch.team2.nationB,
												flagImagePath: props.flagImagePath,
											}}
										/>
									</div>
									<div className="player-name">
										<span
											role="link"
											className="link-to-player"
											onClick={() =>
												navigate(`${mainProfilePath}${props.statMatch.team2.idB}.html`)
											}>
											{props.statMatch.team2.displayNameB}
										</span>
									</div>
									<div className="player-seed">
										<span>{props.statMatch.team2.seed}</span>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
			{matchStatus == 'live' ||
			props.statMatch.statusCode == 'A' ||
			props.statMatch.statusCode == 'K' ||
			props.statMatch.statusCode == 'X' ||
			props.statMatch.statusCode == 'Y' ? (
				<div className="featured-match">
					<ErrorBoundary message="Scores are not available for this match">
						<SlamtrackerScoreboard
							match={typeof foundMatch !== 'undefined' ? foundMatch : props.statMatch}
							matchid={props.statMatch.match_id}
							matchlist={props.liveMatches}
						/>
					</ErrorBoundary>
				</div>
			) : null}
		</div>
  );    
}

export default Header;
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import PointNavOptions from './PointNavOptions';
import PointNavFilters from './PointNavFilters';
import { MatchArchiveContext } from '../../context';
import Collapsible from 'react-collapsible';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import throttle from 'lodash/throttle';
import MatchOverview from './MatchOverview';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PointNav
 * -----------------------------------------------------------------------------
 */

export default class PointNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			//playAllOn: true,
			filterOpen: false,
			overviewOpen: false,
			filters: [],
			currentPointIndex: -1,
			openPosition: '',
			closeAll: true,
			videoStarted: false,
			pointInView: true,
			pointInViewport: true,
			needToScroll: false,
			scrollToCurrentClicked: false,
			autoAdvance: true,
		};

		this.pointsArray = [];
		this.currentPoint = null;

		this.handleTriggerClick = this.handleTriggerClick.bind(this);
		this.handleScrollToCurrent = this.handleScrollToCurrent.bind(this);
		this.afterGameOpened = this.afterGameOpened.bind(this);
		this.pointSelect = this.pointSelect.bind(this);
		this.setRef = this.setRef.bind(this);
		this.transitionTime = 150;
		this.firstAdvance = true;

		window.addEventListener('wheel', throttle(this.onWindowScroll.bind(this), 250), false);
		window.addEventListener('scroll', throttle(this.onWindowScroll.bind(this), 250), false);
		window.addEventListener('touchmove', throttle(this.onWindowScroll.bind(this), 250), false);
		// document.addEventListener('touchmove', function(e) {
		// 	e.preventDefault();
		// }, { passive: false });
	}

	onWindowScroll(e) {
		//logger.info('[PointNav] onWindowScroll, e.type:%o', e.type);

		if (e.type == 'wheel' || e.type == 'touchmove') {
			if (e.type == 'touchmove') {
				e.preventDefault();
			}
			// this.setState({
			// 	//autoAdvance: false,
			// });
		}
		this.checkInView(this.pointEl, this.setEl, false);
	}

	onSetScroll(e) {
		//logger.info('[PointNav] onSetScroll, e.type:%o', e.type);

		if (e.type == 'wheel' || e.type == 'touchmove') {
			// if (e.type == 'touchmove') {
			// 	e.preventDefault();
			// }
			this.setState({
				autoAdvance: false,
			});
		} else {
			logger.info('[PointNav] onSetScroll  - scroll autoAdvance', this.state.autoAdvance);
		}
		this.checkInView(this.pointEl, this.setEl, false);
	}

	componentWillUnmount() {
		window.removeEventListener('wheel', this.onWindowScroll.bind(this));
		window.removeEventListener('scroll', this.onWindowScroll.bind(this));

		if (this.setEl) {
			this.setEl.removeEventListener('wheel', this.onSetScroll.bind(this));
			this.setEl.removeEventListener('scroll', this.onSetScroll.bind(this));
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let prevPoints = op.get(prevState, 'pointsArray', []);
		let nextPoints = op.get(nextProps, 'pointsArray', []);
		//logger.info('[PointNav] getDerivedStateFromProps - next:%o', nextPoints.length);
		//logger.log('[PointNav] getDerivedStateFromProps - next:%o', nextProps);
		let newState = {};

		if (nextProps.matchId != prevState.matchId) {
			newState.matchId = nextProps.matchId;
			newState.pointsArray = nextProps.pointsArray;
			newState.mode = nextProps.mode;
		} else if (nextProps.pointsArray) {
			if (nextProps.mode != prevState.mode) {
				newState.mode = nextProps.mode;
			}

			if (nextProps.currentTime > 0) {
				newState.videoStarted = true;
			}

			if (nextProps.currentTime != prevState.currentTime) {
				newState.currentTime = nextProps.currentTime;
			}

			let p = nextProps.pointsArray;
			let start, end, prevEnd;
			let newIndex;

			for (var i = nextProps.pointsArray.length - 1; i >= 0; i--) {
				//choose current point in sync mode
				//  usually if past the end time of the point
				//  however if a serveline wan tto display when past the start time of the point
				if (nextProps.mode == 'sync') {
					start = p[i].clipStart;
					end = p[i].clipEnd;
					prevEnd = i > 0 ? p[i - 1].clipEnd : 0;

					//logger.info('[PointNav] getDerivedStateFromProps-sync i:%o prev:%o props:%o', i, prevState.currentPointIndex, nextProps);
					if (nextProps.currentTime >= end || (nextProps.currentTime >= start && p[i].serveline)) {
						newIndex = i;
						//logger.info('[PointNav] getDerivedStateFromProps - newState:%o', newState);
						//logger.info('[PointNav] getDerivedStateFromProps-sync new:%o prev:%o end:%o time:%o', newState.currentPointIndex, prevState.currentPointIndex, end, nextProps.currentTime);
						break;
					}
				}

				//choose current in result mode
				else {
					start = p[i].clipStart;
					end = i < p.length - 1 ? p[i + 1].clipStart : p[i].clipEnd;

					if (nextProps.currentTime >= start && nextProps.currentTime < end) {
						newIndex = i;
						//logger.info('[PointNav] getDerivedStateFromProps - newState:%o', newState);
						break;
					} else if (
						(nextProps.currentTime < p[0].clipStart || nextProps.currentTime >= p[p.length - 1].clipEnd) &&
						newState.currentPointIndex != -1
					) {
						newIndex = -1;
					}
				}
			} //end for loop

			if (newIndex != prevState.currentPointIndex) {
				newState.currentPointIndex = newIndex;
			}

			if (newState.currentPointIndex) {
				logger.info(
					'[PointNav] getDerivedStateFromProps new:%o prev:%o time:%o point:%o',
					newState.currentPointIndex,
					prevState.currentPointIndex,
					nextProps.currentTime,
					p[newIndex]
				);
			}

			//logger.log('[PointNav] getDerivedStateFromProps - newState:%o', newState);
		}

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	shouldComponentUpdate(nextProps, nextState) {
		//logger.info('[PointNav] shouldComponentUpdate - nextState:%o', nextState);
		if (
			nextState.matchId != this.state.matchId ||
			nextState.mode != this.state.mode ||
			nextState.openPosition != this.state.openPosition ||
			nextState.cloaseAll != this.state.closeAll
		) {
			return true;
		} else if (nextState.currentPointIndex == this.state.currentPointIndex) {
			return false;
		} else {
			//logger.info('[PointNav] shouldComponentUpdate - newState:%o', nextState);
			return true;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[PointNav] componentDidUpdate - state:%o', this.state);

		// check if point changed
		if (this.state.currentPointIndex >= 0 && this.state.currentPointIndex != prevState.currentPointIndex) {
			logger.log('[PointNav] componentDidUpdate - pointChanged state: %o', this.state);

			if (this.state.onPointChange) {
				let point = this.state.pointsArray[this.state.currentPointIndex - 1];
				if (this.state.mode == 'sync') {
					point = this.state.pointsArray[this.state.currentPointIndex];
				}

				//logger.log('[PointNav] componentDidUpdate - point:%o', JSON.stringify(point));
				this.state.onPointChange(point);
			}

			if (this.state.autoAdvance) {
				this.firstAdvance = false;
				logger.log(
					'[PointNav] componentDidUpdate - pointChanged, autoAdvance=true, now check if set/game open. state:%o, context:%o',
					this.state,
					this.context
				);
				if (
					this.state.currentPointIndex >= 0 &&
					this.state.openPosition !==
						`set_${this.state.pointsArray[this.state.currentPointIndex].setNo}-game_${
							this.state.pointsArray[this.state.currentPointIndex].gameNo
						}`
				) {
					logger.log(
						'[PointNav] componentDidUpdate - pointChanged,  point in different set/game call handleTriggerClick'
					);

					this.handleTriggerClick(
						`set_${this.state.pointsArray[this.state.currentPointIndex].setNo}-game_${
							this.state.pointsArray[this.state.currentPointIndex].gameNo
						}`
					);
				} else {
					logger.log('[PointNav] componentDidUpdate - pointChanged, autoAdvance=true, scrollIntoView');
					this.scrollPointIntoView(this.setEl, this.pointEl);
				}
			}
		}
		//if no current point, send null
		else if (
			!this.state.currentPointIndex &&
			this.state.currentPointIndex != prevState.currentPointIndex &&
			this.state.onPointChange
		) {
			this.state.onPointChange(null);
		}

		if (this.state.openPosition !== prevState.openPosition && this.state.currentPointIndex >= 0) {
			logger.log('[PointNav] componentDidUpdate - openPositionChanged checkInView state:%o', this.state);
			this.checkInView(this.pointEl, this.setEl, false);
		}

		// Result:  if the video started and set/game not opened then open set/game
		if (
			prevState.videoStarted !== this.state.videoStarted &&
			this.state.videoStarted &&
			this.state.openPosition == '' &&
			this.state.mode == 'result'
		) {
			if (this.state.currentPointIndex && this.state.currentPointIndex >= 0) {
				this.handleTriggerClick(
					`set_${this.state.pointsArray[this.state.currentPointIndex].setNo}_${
						this.state.pointsArray[this.state.currentPointIndex].gameNo
					}`
				);
			}
		}

		// if the UI needs to scroll to point, then scroll into view ... needToScroll is set AFTER a triggerClick finishes
		if (prevState.needToScroll !== this.state.needToScroll && this.state.needToScroll) {
			logger.log('[PointNav] componentDidUpdate - needToScroll is true, so scrollIntoView');

			this.setState(
				{
					needToScroll: false,
					pointInView: true,
					pointInViewport: true,
					autoAdvance: true,
				},
				() => {
					if (this.pointEl) {
						this.scrollPointIntoView(this.setEl, this.pointEl);
					}
				}
			);
		}

		if (prevState.mode !== this.state.mode && this.pointEl && this.setEl) {
			logger.log('[PointNav] componentDidUpdate - modeChanged, scrollIntoView -  mode:%o', this.state.mode);
			this.scrollPointIntoView(this.setEl, this.pointEl);
		}
	}

	// isNumeric(data) {
	// 	return !isNaN(parseFloat(data)) && isFinite(data);
	// }

	afterGameOpened() {
		logger.log('[PointNav] afterGameOpened');

		if (this.state.scrollToCurrentClicked) {
			this.setState({
				needToScroll: true,
				scrollToCurrentClicked: false,
			});
		} else if (this.state.autoAdvance) {
			this.setState({
				needToScroll: true,
			});
		} else {
			this.setState({
				needToScroll: false,
			});
		}
	}

	pointSelect(point, advanceToStart) {
		logger.log('[PointNav] pointSelect - point:%o startTime:%o', point, this.startTime);

		if (this.state.onSelect) {
			this.state.onSelect(point);
		}
		this.setState({
			autoAdvance: true,
		});

		if (advanceToStart) {
			MeasurementUtils.dispatchMeasurementCall('Match Archive', {
				data: ['Detail', 'Point Nav', 'Advance to start'],
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('Match Archive', {
				data: ['Detail', 'Point Nav', 'Select Point', point],
			});
		}
	}

	getPointArray(p, event, year) {
		let curSet = '0';
		let curGame = '0';
		let displayGame = curGame;
		let selected = false;
		let history = [];
		let filtered = true;
		let clipStart = p[0].clipStart; //start at first point

		for (var i = 0; i < p.length; i++) {
			// insert server line

			filtered = true;
			if (this.state.filters && this.state.filters.length > 0) {
				filtered = false;
				//logger.log('[PointNav] getPointsArray - point:%o', p[i], this.state.filters);
				if (
					((p[i].ace == '1' || p[i].ace == '2') && this.state.filters.includes('ace')) ||
					((p[i].breakPoint == '1' || p[i].breakPoint == '2') && this.state.filters.includes('brk')) ||
					((p[i].doubleFault == '1' || p[i].doubleFault == '2') && this.state.filters.includes('df')) ||
					(p[i].server == 'srv1' && this.state.filters.includes('srv1')) ||
					(p[i].server == 'srv2' && this.state.filters.includes('srv2')) ||
					(p[i].meta.excitement && this.state.filters.includes('ex'))
				) {
					filtered = true;
				}
			}

			p[i]['highlight'] = false;
			// let start = p[i].clipStart;
			// let end = i < p.length - 1 ? p[i + 1].clipStart : p[i].clipEnd;
			// selected = this.state.currentTime >= start && this.state.currentTime < end;
			if (this.state.currentPointIndex == i) {
				selected = true;
				//logger.log('[PointNav] getPointsArray - clipStart:%o p:%o curSet:%o', clipStart, p[i], curSet);
			} else {
				selected = false;
			}
			p[i]['highlight'] = selected;

			//stop adding points and remove highlighted point if in sync mode
			if (
				this.state.mode == 'sync' &&
				(i > this.state.currentPointIndex || this.state.currentPointIndex == undefined)
			) {
				break;
			}

			//if new set, populate history with new set structure
			if (p[i].setNo != curSet) {
				curSet = p[i].setNo;
				history[curSet] = {
					title: 'Set ' + curSet,
					expanded: false,
					games: [],
				};
			}

			//if new game, populate set with new game structure
			if (p[i].gameNo != curGame) {
				curGame = p[i].gameNo;
				if (curGame >= 13 && ((event == 'LS' && p[i].setNo !== '3') || (event == 'MS' && p[i].setNo !== '5'))) {
					displayGame = 'Tiebreak';
				} else if (
					parseInt(year, 10) > 2018 &&
					curGame >= 25 &&
					((event == 'LS' && p[i].setNo == '3') || (event == 'MS' && p[i].setNo == '5'))
				) {
					displayGame = 'Tiebreak';
				} else {
					displayGame = `Game ${curGame}`;
				}

				history[curSet]['games'][curGame] = {
					title: displayGame,
					expanded: false,
					points: [],
				};
			}

			// push current point into current game
			if (filtered) {
				history[curSet]['games'][curGame]['points'].push(p[i]);
			}

			//set expanded states
			if (selected && p[i].setNo === curSet) {
				history[curSet]['expanded'] = selected;
			}
			if (selected && p[i].gameNo === curGame) {
				history[curSet]['games'][curGame]['expanded'] = selected;
			}

			// update game title if there was a game winner
			if (this.state.mode == 'result') {
				//logger.log('[PointNav] getPointsArray - gameWinner p:%o', p[i]);
				//let pi = history[curSet]['games'][curGame]['points'].length - 1;
				if (
					this.state.currentPointIndex !== -1 &&
					p[i].gameNo == p[this.state.currentPointIndex].gameNo &&
					p[i].setNo == p[this.state.currentPointIndex].setNo &&
					p[i].gameWinner != '-' &&
					this.state.videoStarted
				) {
					history[curSet]['games'][curGame].title = displayGame + ' - In Progress';
				} else {
					history[curSet]['games'][curGame].title =
						displayGame + ' - ' + p[i].gameWinner + ' ' + p[i].setScore;
				}
			} else {
				if (
					this.state.currentPointIndex !== -1 &&
					p[i].gameNo == p[this.state.currentPointIndex].gameNo &&
					p[i].setNo == p[this.state.currentPointIndex].setNo &&
					p[this.state.currentPointIndex].gameWinner == '-' &&
					this.state.videoStarted
				) {
					history[curSet]['games'][curGame].title = displayGame + ' - In Progress';
				} else {
					history[curSet]['games'][curGame].title =
						displayGame + ' - ' + p[i].gameWinner + ' ' + p[i].setScore;
				}
			}
		}

		//logger.log('[PointNav] getPointsArray - history:%o', history);
		return history;
	}

	onFilter() {
		logger.log('[PointNav] onFilter - state:%o', this.state);

		this.setState(
			{
				filterOpen: !this.state.filterOpen,
				overviewOpen: false,
			},
			() => {
				logger.log('[PointNav] onFilter - after setState state:%o', this.state);
				MeasurementUtils.dispatchMeasurementCall('Match Archive', {
					data: ['Detail', 'Point Nav', 'Filter', this.state.filterOpen ? 'open' : 'close'],
				});
			}
		);
	}

	onOverView(show) {
		if (show && (!this.state.overviewOpen || this.state.filterOpen)) {
			MeasurementUtils.dispatchMeasurementCall('Match Archive', {
				data: ['Detail', 'Point Nav', 'Overview', 'open'],
			});
		} else if (!show && (this.state.overviewOpen || this.state.filterOpen)) {
			MeasurementUtils.dispatchMeasurementCall('Match Archive', {
				data: ['Detail', 'Point Nav', 'Point By Point', 'open'],
			});
		}
		this.setState(
			{
				overviewOpen: show,
				filterOpen: false,
			},
			() => {
				logger.log('[PointNav] onOverview  after setState - state:%o', this.state);
			}
		);
	}

	onFilterClick(which) {
		logger.log('[PointNav] onFilterClick - which:%o filters:%o', which, this.state.filters);

		let newFilters = this.state.filters || [];

		if (newFilters.includes(which)) {
			//newFilters = _.without(newFilters, which);
			newFilters = newFilters.filter(f => f != which);
		} else {
			//newFilters.push(which);
			newFilters = [which];
		}
		logger.log('[PointNav] onFilterClick - newFilters:%o', newFilters);
		// this.state.update({
		// 	filters: [...newFilters],
		// });
		this.setState({
			filters: [...newFilters],
		});

		MeasurementUtils.dispatchMeasurementCall('Match Archive', { data: ['Detail', 'Point Nav', 'Filter', which] });
	}

	onSetOpen(set) {
		logger.log('[PointNav] onSetOpen - which:%o', set);
		this.setState({
			openSet: set,
		});
	}

	handleScrollToCurrent() {
		MeasurementUtils.dispatchMeasurementCall('Match Archive', {
			data: ['Detail', 'Point Nav', 'Scroll to current'],
		});

		let newPosition = `set_${this.state.pointsArray[this.state.currentPointIndex].setNo}-game_${
			this.state.pointsArray[this.state.currentPointIndex].gameNo
		}`;

		if (this.state.openPosition !== newPosition) {
			logger.log('[PointNav] handleScrollToCurrent - handleTriggerClick:%o', newPosition);

			this.setState(
				{
					pointInView: true,
					pointInViewport: true,
					scrollToCurrentClicked: true,
					autoAdvance: true,
				},
				() => {
					logger.log('[PointNav] handleScrollToCurrent - handleTriggerClick');

					this.handleTriggerClick(
						`set_${this.state.pointsArray[this.state.currentPointIndex].setNo}-game_${
							this.state.pointsArray[this.state.currentPointIndex].gameNo
						}`,
						false
					);
				}
			);
		} else {
			this.setState(
				{
					autoAdvance: true,
					pointInView: true,
					pointInViewport: true,
				},
				() => {
					logger.log('[PointNav] handleScrollToCurrent - scrollIntoView');

					this.scrollPointIntoView(this.setEl, this.pointEl);
				}
			);
		}
	}

	handleTriggerClick(position, manual = false) {
		logger.log('[PointNav] handleTriggerClick - position:%o', position);

		let closeable = true;
		let closeAll = false;

		if (closeable && position) {
			//handle closing of game
			//  keep containg set open
			if (position.indexOf('game') > 0 && position === this.state.openPosition) {
				closeAll = false;
				position = position.split('-')[0];
			} else {
				closeAll = !this.state.closeAll && position === this.state.openPosition;
			}
		}
		logger.log('[PointNav] handleTriggerClick - closeAll:%o', closeAll);

		if (manual) {
			let currentSet = this.state.openPosition.split('-')[0];
			let currentGame =
				this.state.openPosition.indexOf('game') > 0 ? this.state.openPosition.split('-')[1] : null;
			let newSet = position.split('-')[0];
			let newGame = position.indexOf('game') > 0 ? position.split('-')[1] : null;
			if (currentSet !== newSet && null !== newSet) {
				MeasurementUtils.dispatchMeasurementCall('Match Archive', {
					data: ['Detail', 'Point Nav', 'Open Set', newSet],
				});
			} else if (currentGame !== newGame && null !== newGame) {
				MeasurementUtils.dispatchMeasurementCall('Match Archive', {
					data: ['Detail', 'Point Nav', 'Open Game', newGame],
				});
			}
		}

		this.setState({
			openPosition: position,
			closeAll: closeAll,
			autoAdvance: !manual,
		});

		//this.props.onTriggerClick(position);
	}

	checkInView(element, container, partial) {
		//Get container properties
		//container = this.accordionWrap;

		if (element && container && this.state.currentPointIndex >= 0) {
			let currentPoint = this.state.pointsArray[this.state.currentPointIndex];
			let currentPointPosition = `set_${currentPoint.setNo}-game_${currentPoint.gameNo}`;
			let isPositionOpen = currentPointPosition == this.state.openPosition;

			let windowTop = window.scrollTop + window.offsetTop;
			let windowBottom = windowTop + window.clientHeight;

			let cTop = container.offsetTop + container.scrollTop;
			let cBottom = cTop + container.clientHeight;

			//Get element properties
			let eTop = element.offsetTop;
			let eBottom = eTop + element.clientHeight;

			//Check if in view
			let isTotal = eTop >= cTop && eBottom <= cBottom;
			let isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));
			let pointInViewport = !this.isOutViewport(element).any;

			//logger.log('[PointNav] checkInView pointInViewport:%o', pointInViewport);

			//Return outcome
			this.setState(
				{
					pointInView: (isTotal || isPartial) && isPositionOpen,
					pointInViewport,
				},
				() => {
					// logger.log(
					// 	'[PointNav] checkInView pointInView:%o, pointInViewport:%o',
					// 	this.state.pointInView,
					// 	this.state.pointInViewport
					// );
				}
			);
		}
	}

	isOutViewport(elem) {
		// Get element's bounding
		let bounding = elem.getBoundingClientRect();

		// Check if it's out of the viewport on each side
		let out = {};
		out.top = bounding.top < 0;
		out.left = bounding.left < 0;
		out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
		out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
		out.any = out.top || out.left || out.bottom || out.right;
		out.all = out.top && out.left && out.bottom && out.right;

		return out;
	}

	scrollPointIntoView(parent, child) {
		//child.scrollIntoView(false);
		if (this.state.mode == 'result') {
			parent.scrollTop = child.offsetTop - 40;
		} else {
			parent.scrollTop = parent.scrollHeight;
		}
	}

	setRef(e, type, data) {
		if (type == 'point' && data.highlight) {
			//logger.log('[PointNav] setRef pointEl:%o', e);

			this.pointEl = e;
		} else if (type == 'set' && e && e.state && !e.state.isClosed) {
			//logger.log('[PointNav] setRef setEl:%o', e);

			this.setEl = e.innerRef;

			if (!this[e.props.accordionPosition]) {
				//logger.log('[PointNav] setRef add event listener');

				//this.setEl.addEventListener('scroll', throttle(this.onSetScroll.bind(this),250), false);
				this.setEl.addEventListener('wheel', throttle(this.onSetScroll.bind(this), 250), false);
				this.setEl.addEventListener('scroll', throttle(this.onSetScroll.bind(this), 250), false);
				this.setEl.addEventListener('touchmove', throttle(this.onSetScroll.bind(this), 250), false);

				this[e.props.accordionPosition] = true;
			}
		} else {
			this[`${type}_ref`] = e;
		}
		//this[`${id}_el`] = e;
		//logger.log('[PointNav] setRef - this:%o', this);
	}

	render() {
		//logger.log('[PointNav] render - state:%o', this.state);
		//logger.log('[PointNav] render - context:%o', this.context);
		//logger.log('[PointNav] render - props:%o', this.props);

		if (this.state.pointsArray) {
			//let points = this.state.pointsArray;
			let points = this.getPointArray(
				this.state.pointsArray,
				this.context.currentMatch.event,
				this.context.currentMatch.year
			);
			//logger.log('[PointNav] render - state:%o', this.state);
			// logger.log(
			// 	'[PointNav] render - time:%o currIndex:%o points:%o',
			// 	this.state.currentTime,
			// 	this.state.currentPointIndex,
			// 	points
			// );

			return (
				<div ref={e => this.setRef(e, 'pointNav')} className={`pointNav ${this.state.open ? 'show' : 'hide'}`}>
					<PointNavOptions
						// playAllOn={this.state.playAllOn}
						// onPlayAll={value => this.playAll(value)}
						onFilter={() => this.onFilter()}
						onOverview={show => this.onOverView(show)}
					/>
					<PointNavFilters
						match={this.context.currentMatch}
						show={this.state.filterOpen}
						filters={this.state.filters}
						onFilterClick={val => this.onFilterClick(val)}
					/>
					{this.state.videoStarted &&
					!this.state.pointInView &&
					!this.state.filterOpen &&
					!this.state.overviewOpen ? (
						<div className="instruction" onClick={() => this.handleScrollToCurrent()}>
							Click here to scroll to current point
						</div>
					) : null}

					{!this.state.filterOpen &&
					!this.state.overviewOpen &&
					((!this.state.currentPointIndex && this.state.currentPointIndex !== 0) ||
						(this.state.currentPointIndex == '-1' && this.state.mode == 'sync')) ? (
						<div className="instruction" onClick={() => this.pointSelect(this.state.pointsArray[0], true)}>
							Click here to advance to start of match
						</div>
					) : null}
					<MatchOverview
						show={this.state.overviewOpen}
						data={{ overview: this.context.overview, height: this.props.matchVideoHeight }}
					/>
					<div
						className={`accordionWrap ${this.state.filterOpen || this.state.overviewOpen ? 'hide' : ''} ${
							this.state.mode
						}`}>
						{points.map((d, i) => {
							//logger.log('[PointNav] render - set:%o i:%o', d, i);
							let setName = `set_${i}`;
							return (
								<Collapsible
									key={setName}
									trigger={d.title}
									transitionTime={this.transitionTime}
									open={!this.state.closeAll && this.state.openPosition.indexOf(setName) == 0}
									accordionPosition={setName}
									handleTriggerClick={p => this.handleTriggerClick(p, true)}
									overflowWhenOpen={'scroll'}
									openedClassName="opened_set"
									ref={e => this.setRef(e, 'set', setName)}>
									{d.games.map((g, i) => {
										//logger.log('[PointNav] render - game:%o', g);
										let gameName = `${setName}-game_${i}`;
										if (g.points.length > 0) {
											return (
												<Collapsible
													key={gameName}
													trigger={g.title}
													transitionTime={this.transitionTime}
													open={!this.state.closeAll && this.state.openPosition === gameName}
													accordionPosition={gameName}
													handleTriggerClick={p => this.handleTriggerClick(p, true)}
													overflowWhenOpen={'hidden'}
													onOpen={() => this.afterGameOpened()}
													openedClassName="opened_game"
													triggerClassName="Collapsible__trigger_game"
													triggerOpenedClassName="Collapsible__trigger_game">
													{g.points.map((p, i) => {
														return (
															<div
																className={`Collapsible__point ${
																	p.highlight ? 'selected' : ''
																}`}
																key={i}
																onClick={() => {
																	this.pointSelect(p);
																}}
																ref={e => this.setRef(e, 'point', p)}>
																{
																	<Fragment>
																		<span className="point">
																			{p.score == '0-0' && p.gameWinner != '-'
																				? 'Finish'
																				: !p.serveline
																				? p.score
																				: i == 0
																				? '0-0'
																				: ''}
																		</span>
																		<span className="desc">{p.description}</span>
																	</Fragment>
																}
															</div>
														);
													})}
												</Collapsible>
											);
										} else {
											return null;
										}
									})}
								</Collapsible>
							);
						})}
					</div>
				</div>
			);
		} else {
			return <LoadingIndicator type="small relative white" />;
		}
	}
}

PointNav.defaultProps = {};
PointNav.contextType = MatchArchiveContext;

/*
<Accordion preExpanded={['set_1']} allowZeroExpanded={true}>
							{points.map((d, i) => {
								logger.log('[PointNav] render - set:%o i:%o', d, i);
								return (
									<AccordionItem key={i} uuid={`set_${i}`}>
										<AccordionItemHeading>
											<AccordionItemButton>{d.title}</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<Accordion allowZeroExpanded={true}>
												{d.games.map((g, i) => {
													logger.log('[PointNav] render - game:%o', g);
													if (g.points.length > 0) {
														return (
															<AccordionItem key={i}>
																<AccordionItemHeading className="accordion__title-level2">
																	<AccordionItemButton>{g.title}</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	{g.points.map((p, i) => {
																		//logger.log('[PointNav] render - point:%o', p.ElapsedTime);

																		return (
																			<div
																				className={`accordion__point ${
																					p.highlight ? 'selected' : ''
																				}`}
																				key={i}
																				onClick={() => {
																					this.pointSelect(p);
																				}}>
																				{
																					<Fragment>
																						<span className="point">
																							{p.score == '0-0'
																								? 'Finish'
																								: p.score}
																						</span>
																						<span className="desc">
																							{p.description}
																						</span>
																					</Fragment>
																				}
																			</div>
																		);
																	})}
																</AccordionItemPanel>
															</AccordionItem>
														);
													} else {
														return null;
													}
												})}
											</Accordion>
										</AccordionItemPanel>
									</AccordionItem>
								);
							})}
						</Accordion>
*/

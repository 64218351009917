import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


class PlayPause extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		
		}
		this.styles = props.styles;

		let hui = document.styleSheets[0];
		//logger.log('PlayPause: constructor - hui:%o', hui);
	}

	onClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}
	
	render() {
		logger.log('PlayPause: render - styles:%o', this.styles);
		const {innerRef} = this.props;
		let inline = {};
		let data_state = '';

		
		if(this.props.first) {
			inline = {
				margin: "0 0 0 10px"
			}
		}

		if (this.props.status == 'play') {
			data_state = 'pause';
		}

		return (
			<button 
				className={`${this.styles.icon} ${this.styles.playPause}`} 
				style={inline} 
				onClick={() => {this.onClick()}} 
				data-state={data_state}
				ref={innerRef}
				title="Play"
				tabIndex={0}
				>
			</button>
		)
	}
	
}

export default React.forwardRef((props,ref) => <PlayPause innerRef={ref} {...props} />)
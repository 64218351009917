/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { MatchArchiveContext } from '../context';
import WimLink from 'appdir/components/general/WimLink';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchTitle
 * -----------------------------------------------------------------------------
 */

class MatchTitle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			sharePopup: 'hide',
		};

		this.onShareClick = this.onShareClick.bind(this);

		logger.log('[MatchTitle] constructor - context:%o', this.context);
	}

	componentDidMount() {
		let eventName = op.get(this.props, 'scoringConfig.eventNames', []).filter(event => {
			if (event.code == this.state.currentMatch.event) {
				return true;
			} else {
				return false;
			}
		})[0];
		eventName = eventName ? eventName.long : '';
		let roundName = op.get(this.props, 'scoringConfig.roundNames', []).filter(round => {
			if (round.roundCode == this.state.currentMatch.round) {
				return true;
			} else {
				return false;
			}
		})[0];
		roundName = roundName ? roundName.roundName : '';
		logger.log('[MatchTitle] this.props.scoringConfig:%o', this.props.scoringConfig);
		this.setState({ eventName, roundName });
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = null;

		if (nextProps.url != prevState.url) {
			newState = {
				mode: nextProps.mode,
			};
		}

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[MatchTitle] componentDidUpdate - context:%o state:%o', this.context, this.state);
	}

	onShareClick() {
		//logger.log('[ContentPage] onShareClick');
		this.setState({
			sharePopup: 'hide',
		});
	}

	toggleShare() {
		//logger.log('[ContentPage] toggleShare - sharePopup:%o', this.state.sharePopup);
		this.setState({
			sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	render() {
		logger.log('[MatchTitle] render - state:%o', this.state);
		//logger.log('[MatchTitle] render - props:%o points:%o', this.props, this.props.currentMatch.points);

		let details = op.get(this.state, 'currentMatch.cmsDetail', false);

		if (details) {
			let flagImagePath = this.state?.otherData?.flagImagePathSmall;

			return (
				<Fragment>
					<div className="match-title-container">
						<div className="spacer" />
						<div className="player1">
							<div className="image">
								<WimLink to={`/en_GB/draws_archive/player_profile/${details.players[0].id}.html`}>
									<img src={details.players[0].headshot} />
								</WimLink>
							</div>
							<div className="info">
								<div className="flag">
									<img src={flagImagePath?.replace('<code>', details.players[0].nation)} />
								</div>
								<WimLink to={`/en_GB/draws_archive/player_profile/${details.players[0].id}.html`}>
									<div className="name">
										<div className="first-name">{details.players[0].firstName}</div>
										<div className="last-name">
											{details.players[0].lastName}
											{details.players[0].seed && (
												<span className="seed">{details.players[0].seed}</span>
											)}
										</div>
									</div>
								</WimLink>
							</div>
						</div>
						<div className="match-title">
							<div className="match-year">
								<div className="year">{this.state.currentMatch.year}</div>
								<div
									className="byline--share-button"
									onClick={() => {
										this.toggleShare();
									}}>
									<i className="wim-icon-share" />
									<div className={`byline--share-wrapper threecol`}>
										<ShareMenu
											type="popup"
											view={this.state.sharePopup}
											orient="top"
											onClick={this.onShareClick}
											share={this.state.url}
										/>
									</div>
								</div>
							</div>
							<div className="match-heading">
								<p>{this.state.eventName}</p>
								<p>{this.state.roundName}</p>
							</div>
						</div>
						<div className="player2">
							<div className="info">
								<div className="flag">
									<img src={flagImagePath?.replace('<code>', details.players[1].nation)} />
								</div>
								<WimLink to={`/en_GB/draws_archive/player_profile/${details.players[1].id}.html`}>
									<div className="name">
										<div className="first-name">{details.players[1].firstName}</div>
										<div className="last-name">
											{details.players[1].lastName}
											{details.players[1].seed && (
												<span className="seed">{details.players[1].seed}</span>
											)}
										</div>
									</div>
								</WimLink>
							</div>
							<div className="image">
								<WimLink to={`/en_GB/draws_archive/player_profile/${details.players[1].id}.html`}>
									<img src={details.players[1].headshot} />
								</WimLink>
							</div>
						</div>
						<div className="scoring-mode"></div>
					</div>
				</Fragment>
			);
		} else {
			return null;
		}
	}
}

MatchTitle.contextType = MatchArchiveContext;
export default MatchTitle;

// import React from 'react';

// const MyWimConsent = props => {
// 	return (
// 		<MyWimContext.Consumer>
// 		{
// 			context => <div>Consent Tab {context.sampleKey}</div>
// 		}
// 		</MyWimContext.Consumer>
// 	);
// };
// export default MyWimConsent;

import React, { Component } from 'react';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { MyWimContext } from './context';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';

import FormConsent from './elements/FormConsent';
import FormContact from './elements/FormContact';
import FormSettings from './elements/FormSettings';

class MyWimConsent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		logger.log('[MyWimConsent] render - context:%o', this.context);
		//logger.log('[MyWimConsent] render - context:%o', JSON.stringify(this.context.currentUser.data.userPreferences) );

		return (
			<div className="preferences-container">
				<FormConsent
					user={{ ...this.context.user, ...this.context.currentUser.profile }}
					onUpdate={data => this.props.onUpdate(data)}
				/>
				<FormContact
					user={{ ...this.context.user, ...this.context.currentUser.profile }}
					currentUser={this.context.currentUser}
					subscriptions={this.context.subscriptions}
					onUpdate={data => this.props.onUpdate(data)}
				/>
				<FormSettings currentUser={this.context.currentUser} onUpdate={data => this.props.onUpdate(data)} />
			</div>
		);
	}
}

MyWimConsent.contextType = MyWimContext;
export default MyWimConsent;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Button from 'appdir/components/common-ui/Button';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const ApplicantAccreditationList = (props) => {
 * -----------------------------------------------------------------------------
 */

const ApplicantAccreditationList = props => {
	// console.log('[ApplicantAccreditationList] data:%o', props.data);

	return (
		<div className="column-layout">
			<div className="four-col page-title">
				<div className="title-header">APPLICANT ACCREDITATION</div>
			</div>

			<div className="two-col margin-col clear-four clear-two">
				<div className="media-form-box">
					<div className="title-header">Applicants Added</div>
					{props.applicants.length > 0
						? props.applicants.map((a, i) => {
								return (
									<div className="media-form-box-row" key={`applicant-${i}`}>
										<div className="name-col">
											{a.applicantFirstName} {a.applicantLastName}
										</div>
										<div className="org-col">{a.applicantOrganisationName}</div>
										<div
											className="edit-col"
											onClick={() => {
												props.editApplicant(i);
											}}>
											CONFIRM / EDIT PROFILE
										</div>
									</div>
								);
						  })
						: null}
				</div>
				<div className="clear-two clear-four no-margin right left">
					<span className="hospitality-btns no-margin right left">
						<Button className="media-btn" onClick={props.goBack}>
							&lt; Back
						</Button>
						<Button className="" onClick={props.addApplicant}>
							+ Add Applicant
						</Button>
					</span>
				</div>
			</div>
		</div>
	);
};

export default ApplicantAccreditationList;

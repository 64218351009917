import React from 'react';
import WimLink from 'appdir/components/general/WimLink';

const ServiceItem = props => {
	if (props.display === undefined || props.display) {
		return (
			<div className="myw-shelf service-item">
				<WimLink
					to={props.link}
					target={props.target}
					title={`${props.title} page. ${props.ariaLabel ? props.ariaLabel : ''}`}>
					<div className="service-container small" aria-hidden role="none">
						<img className="service-img" src={props.src} alt="service item" aria-hidden />
						<div aria-hidden role="none" className="service-title">
							{props.title}
						</div>
					</div>
				</WimLink>
			</div>
		);
	} else {
		return null;
	}
};
export default ServiceItem;

import React, { Component } from 'react';
import { Timeline, Tweet } from 'react-twitter-widgets';
import InstagramEmbed from 'react-instagram-embed';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';

const mobileMatch = window.matchMedia('(max-width: 510px)');

class SocialEmbed extends Component {
	constructor(props) {
		super(props);

		if (!props.data.height) {
			props.data.height = 800;
		}

		this.state = { ...this.props.data };
	}

	componentDidMount() {}
	render() {
		/**
		 * check type and render based off of that
		 * i.e.:
		 *  Facebook post, Instagram post, Twitter timeline, Twitter tweet (singular)
		 **/

		switch (this.state.type) {
			case 'timeline':
				return (
					<div className="timeline">
						<Timeline
							dataSource={{ sourceType: 'profile', screenName: this.state.screenName }}
							options={{ height: this.state.height }}
						/>
					</div>
				);
				//discuss with david
				// return <Timeline dataSource={{sourceType: 'list', id: this.state.listId}} />
				// return <Timeline dataSource={{sourceType: 'collection', screenName: this.state.collectionId}} />
				break;
			case 'facebook':
				return (
					<FacebookProvider appId={this.state.appId}>
						<EmbeddedPost href={this.state.postURL} />
					</FacebookProvider>
				);
				break;
			case 'tweet':
				let tweetWidth = mobileMatch.matches ? { width: 'auto' } : '';
				return <Tweet tweetId={this.state.tweetId} options={tweetWidth} />;
				break;
			case 'instagram':
				return (
					<InstagramEmbed
						url={`https://www.instagram.com/p/${this.state.instagramId}`}
						maxWidth={320}
						hideCaption={false}
						containerTagName="div"
						protocol=""
						onLoading={() => {}}
						onSuccess={() => {}}
						onAfterRender={() => {}}
						onFailure={() => {}}
					/>
				);
			default:
				return null;
		}
	}
}

export default SocialEmbed;

import SearchPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/:lang/search/index.html', '/:lang/search/'],
		exact: true,
		component: SearchPage,
		load: params => deps.actions.SearchPage.mount(params),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import { Link } from 'react-router-dom';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SectionDivider from 'appdir/components/cms/SectionDivider';

/**
 * -----------------------------------------------------------------------------
 * React Component: JobsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['JobsPage'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.JobsPage.mount()),
	unmount: () => dispatch(deps.actions.JobsPage.unmount()),
});

class JobsPage extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		logger.log('[JobsPage] constructor - props:%o', this.props);
	}

	componentDidMount() {
		//don't call if has a route
		//this.props.mount();
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[JobsPage] componentWillReceiveProps - props:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[JobsPage] componentDidUpdate - ', this.state);

		if (!this.state.jobsData) {
			deps.services.JobsPage.fetch(this.state.dataPath).then(result => {
				this.setState({
					jobsData: result,
				});
			});
		}
	}

	getContent() {
		logger.log('[JobsPage] render - state:%o', this.state);
		if (this.state.jobsData && this.state.jobsData.content) {
			this.state.jobsData.content.map((item, index) => {
				logger.log('[JobsPage] render - item:%o', item);
				let url = '/en_GB/jobs/' + item.contentId + '.html';
				return (
					<div className="jobs-item">
						<Link to={url}>{item.title}</Link>
					</div>
				);
			});
		} else {
			return null;
		}
	}

	render() {
		logger.log('[JobsPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'jobs',
			minHeight: 190,
			metaTitle: 'Jobs',
			metaDescription: 'Job Openings',
			metaDate: '',
			metaPlayers: '',
		};

		let style = {
			style: 'four-col',
		};

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main news">
					<div className="column-layout">
						<ul className="jobs-list" role="main">
							{this.state.jobsData && this.state.jobsData.content
								? this.state.jobsData.content.map((item, index) => {
										logger.log('[JobsPage] render - item:%o', item);
										let url = '/en_GB/jobs/' + item.contentId + '.html';
										return (
											<li className="jobs-item" key={item.contentId}>
												<Link to={url}>{item.title}</Link>
											</li>
										);
								  })
								: null}
						</ul>

						<br />
						<SectionDivider data={style} />
						<br />
						<div className="jobs-text">
							The All England Lawn Tennis Club (Championships) Limited is an equal opportunities employer
							and has developed policies and procedures that comply with current equal opportunities
							legislation. The Company is committed to promoting equal opportunity in the workplace,
							regardless of age, disability, gender reassignment, marital or civil partnership status,
							pregnancy and maternity, race, colour, nationality, ethnic or national origin, religion or
							belief, sex, or sexual orientation. The Company welcomes job applications from all sections
							of the community and we do not discriminate on any grounds other than the ability to carry
							out the required duties to a high standard.
						</div>
					</div>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsPage);

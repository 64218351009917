import React, { useRef, useEffect, useState } from 'react';
// import IntlTelInput from 'intl-tel-input/react';
import "intl-tel-input/build/css/intlTelInput.css";
import { countryLookupLowercase } from 'appdir/components/general/Util';
import intlTelInput from "intl-tel-input";

/* NOTE-- for reference, followed this code for validation:
    - https://codepen.io/jackocnr/pen/RwePbZg
    - https://intl-tel-input.com/examples/validation-practical.html
*/ 

const IntlTelInputView = (props) => {
    const inputRef = useRef(null);
    const itiRef = useRef(null); // useRef to store iti instance
    const [notice, setNotice] = useState(null);
    const countriesArr = Object.keys(countryLookupLowercase)

    const handleKeyUp = () => {
      const iti = itiRef.current; // get the current iti instance
      if (iti?.isValidNumberPrecise()) {
        setNotice(null);
        props.isValidNumber(iti.getNumber(), true);
      } else {
        setNotice("Invalid number");
        props.isValidNumber(iti.getNumber(), false);
      }
    }

    const handleKeyDown = (event) => {
        const char = event.key;
        const validChars = /^[0-9()+\s-\b]*$/; // allows numbers, parentheses, spaces, dashes, and plus sign
        
        if (!validChars.test(char) && char !== 'Backspace') {
            event.preventDefault();
        } 
      };
  
    useEffect(() => {
      itiRef.current = intlTelInput(inputRef.current, {
        utilsScript: "/en_GB/static/intl-tel-input/utils_19.1.1.js",
        initialCountry: "gb",
        i18n: countryLookupLowercase,
        onlyCountries: countriesArr,
        separateDialCode: false,
        showFlags: false,
        showSelectedDialCode: true,
        formatAsYouType: true,
        strictMode: false,
      });
    }, []);
  
    return (
      <form>
        <input id="resale-intl-input" type="phone" ref={inputRef} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} />
        {notice && <div className="intl-tel-notice">{notice}</div>}
      </form>
    );
  };

export default IntlTelInputView;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.TICKETS_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[Tickets] reducer.mount - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.TICKETS_UNMOUNT:
			newState = {
				idTokens: state.idTokens,
			};
			return newState;
			break;

		case deps.actionTypes.TICKETS_UPDATE:
			//

			newState = {
				...state,
				...action.data,
			};
			logger.log('[Tickets] reducer.update - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.TICKETS_UPDATE_IDTOKEN:
			logger.log('[Tickets] reducer - action:%o', action);
			let idTokens = state.idTokens.filter(entry => {
				return entry.spectatorId != action.data.idToken.spectatorId;
			});
			idTokens.push(action.data.idToken);

			newState = {
				...state,
				idTokens: idTokens,
			};
			logger.log('[Tickets] reducer.token - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

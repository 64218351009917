import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				contentId: Config.relatedContent.contentId,
				relatedVideoPath: Config.relatedContent.video,
				shareBase: Config.videoPlayer.shareBase,
				nextUpPath: Config.relatedContent.nextUp,
				nextupSec: Config.videoPlayer.videoAutoplayCountdown,
				nextupEnable: Config.videoPlayer.videoAutoplay,
				playerConfig: Config.playerConfig,
			};

			dispatch({ type: deps.actionTypes.VIDEOPLAYER_MOUNT, data: data });
		});
	},

	play: data => dispatch => {
		//logger.log('[VideoPlayer] action.play - data:%o', data);
		dispatch({ type: deps.actionTypes.VIDEOPLAYER_PLAY, data: data });
	},

	fullscreen: data => dispatch => {
		//logger.log('[VideoPlayer] action.fullscreen - data:%o', data);
		dispatch({ type: deps.actionTypes.VIDEOPLAYER_FULLSCREEN, data: data });
	},

	fullwindow: data => dispatch => {
		//logger.log('[VideoPlayer] action.fullwindow - data:%o', data);
		dispatch({ type: deps.actionTypes.VIDEOPLAYER_FULLWINDOW, data: data });
	},

	updateUser: settings => dispatch => {
		dispatch({ type: deps.actionTypes.VIDEOPLAYER_USER, data: settings });
	},
};

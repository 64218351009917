/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { FormInput, FormCheckBox } from 'appdir/components/common-ui/FormFields';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: CushionForm
 * -----------------------------------------------------------------------------
 */

function ticketDisplayName(name) {
	const displayMap = {
		guide: "Ticket Holder's Guide",
		security: 'Important Security Information flyer',
		wallets: 'Ticket Wallet',
	};
	return displayMap[name];
}
const TicketForm = ({ formProps }) => {
	logger.log('[ProgramForm] render - formProps:%o', formProps);
	let ticketFormData = formProps.values.orders[0].ticket;
	return (
		<span id="hosp-ticket-form" className="hospContent column-layout">
			<span className="four-col table-container">
				<table className="hospitality-table">
					<thead>
						<tr>
							<td>Document</td>
							<td>Number</td>
							<td>PDF Required</td>
							<td>Comments</td>
						</tr>
					</thead>
					<tbody>
						{Object.keys(ticketFormData).map((d, i) => {
							return (
								<tr key={i}>
									<td>{ticketDisplayName(d)}</td>
									<td>
										<FormInput
											label=""
											type="text"
											name={`orders[0].ticket[${d}].number`}
											value={ticketFormData[d].number || ''}
											className="quant"
										/>
									</td>
									<td>
										{ticketFormData[d].pdf ? (
											<FormCheckBox
												name={`orders[0].ticket[${d}].pdf`}
												id={`checkbox${i}`}></FormCheckBox>
										) : null}
									</td>
									<td>
										<FormInput
											label=""
											type="text"
											name={`orders[0].ticket[${d}].comments`}
											disabled={false}
											value={ticketFormData[d].comments || ''}
											className="quant"
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</span>
			<span className="one-col">
				<div className="hosp-img-container" id="hosp-guide">
					<img src="https://assets.wimbledon.com/images/hospitality/guide.jpg" />
					<div className="hosp-ticket-desc">
						Ticket Holders’ Guide
						<br />
						A5 (210 x 148.5) & 21 pages
						<br />
						(Available electronically if required)
					</div>
				</div>
			</span>
			<span className="three-col two-img-container">
				<span className="hosp-img-container" id="hosp-security">
					<img src="https://assets.wimbledon.com/images/hospitality/security.jpg" />
					<div className="hosp-ticket-desc">
						Important Security Information flyer
						<br />
						210mm x 100mm
						<br />
						(Available electronically if required)
					</div>
				</span>
				<span className="hosp-img-container" id="hosp-wallet">
					<img src="https://assets.wimbledon.com/images/hospitality/wallet.jpg" />
					<div className="hosp-ticket-desc">Ticket wallet –200mm x 105mm </div>
				</span>
			</span>
		</span>
	);
};

export default TicketForm;

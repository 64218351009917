import React, { Component } from 'react';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import IntlTelInputView from 'appdir/components/webviews/TicketResaleWebview/IntlTelInputView.js';
import { Formik, Form } from 'formik';
import { FormSelect } from 'appdir/components/common-ui/FormFields';
import { handleJoinQueue, handleGetQueueStatus } from 'appdir/components/webviews/TicketResaleWebview/ticketResaleEndpoints';
import axios from 'axios';
import parse from 'html-react-parser';
import { getErrorText } from 'appdir/components/general/Util/Error';
import { measureInAppContext } from 'appdir/components/general/Analytics';

class TicketResaleCheckin extends Component {
	constructor(props) {
		super(props);
		this.state = {
            selectedCourt: '',
            filterOpen: '',
            selectedTicketQuantity: props?.quantity?.min || 0,
            ...props
        }
        this.handleSubmit = this.handleSubmit.bind(this);
		this.updatePhone = this.updatePhone.bind(this);
        this.initialValues = {};
	}

    componentDidMount() {
        if (this.props.quantity?.min !== this.state.selectedTicketQuantity && this.state.selectedTicketQuantity === 0) {
            this.setState({ selectedTicketQuantity: this.props.quantity?.min });
        }

        this.props.getResaleQueueConfig();

        measureInAppContext({
            pageTitle:"Ticket Resale",
            action: [],
            context: [{
                "section": "Tickets",
            }],
            metricType: "state",
        });

        let cmsTxt = '';
		axios.get(this.props.configData?.message)
			.then(res => {
				cmsTxt = parse(res?.data?.text);
				this.setState({ cmsTxt })
			})
			.catch(err => { throw err });
    }

    handleSubmit(form_values) {
        let queueId = this.props.queueNames.filter(court => court.name == form_values.court)[0]?.id;
        let partySize = form_values?.quantity;
        this.props.showModal({
            showModal: true,
            showCloseBtn: false,
            type: 'updating',
            title: 'Entering Resale Queue',
            message: 'Please do not close the window while submitting.',
            useTimer: false,
        });

        let path = this.props.queueAPIs?.resaleJoinQueue;
        this.props.getJWT().then(token => {
            let jwtToken = token?.jwt?.id_token;
            handleJoinQueue(jwtToken, path, queueId, partySize, this.state.phoneNumber).then((response) => {
                logger.log('[TicketResaleCheckin] handleJoinQueue response %o', response);
                // If join form SUCCESS --> get user status, queue config, go through logic flow again
                this.props.updateQueueStatus(response);
            }).then(() => {
                // join response doesn't have queueId, so need to call get status 
                let statusPath = this.props.queueAPIs?.resaleQueueStatus;
                handleGetQueueStatus(jwtToken, statusPath).then((response) => {
                    logger.log('[TicketResaleCheckin] handleGetQueueStatus() response %o', response);
                    this.props.showModal({
                        showModal: false,
                        showCloseBtn: true,
                        type: 'success',
                        title: 'Success',
                        message: 'You have entered the resale queue',
                        useTimer: false,
                    });
                    measureInAppContext({
                        pageTitle:"Ticket Resale Queue",
                        action: "Entered Ticket Resale",
                        args: "Success",
                        metricType: "action",
                    });
                    this.props.updateQueueStatus(response)
                }).catch(error => {
                    logger.error('[TicketResaleCheckin] handleGetQueueStatus() - error:%o', error);
                    this.props.showModal({
                        showModal: true,
                        showCloseBtn: true,
                        type: 'error',
                        title: 'Error Getting Status',
                        message: getErrorText(error?.code || error?.data?.response?.error?.code, "Resale Join Page"),
                        useTimer: false,
                    });
                    measureInAppContext({
                        pageTitle:"Ticket Resale Queue",
                        action: "Entered Ticket Resale",
                        args: "Failed",
                        metricType: "action",
                    });
                });
            }).catch(error => {
                logger.error('[TicketResaleCheckin] handleJoinQueue - error:%o', error);
                // show metrics for join capacity error
                if (error?.data?.response?.error?.message == 'The queue is at capacity and cannot accept new bookings. Please try again later.') {
                    measureInAppContext({
                        pageTitle:"Ticket Resale Queue",
                        action: "Unknown Error",
                        context: [{
                            "Resale Join Page": code,
                        }],
                        metricType: "state",
                    });
                }
                this.props.showModal({
                    showModal: true,
                    showCloseBtn: true,
                    type: 'error',
                    title: 'Error Joining',
                    message: (error?.data?.response?.error?.message == 'The queue is at capacity and cannot accept new bookings. Please try again later.') ?
                        error?.data?.response?.error?.message
                        : getErrorText(error?.code || error?.data?.response?.error?.code, "Resale Join Page"),
                    useTimer: false,
                });
            })
        }).catch(error => {
            logger.error('[TicketResaleCheckin] handleGetQueueStatus() - error:%o', error);
            this.props.showModal({
                showModal: true,
                showCloseBtn: true,
                type: 'error',
                title: 'Error',
                message: getErrorText(error?.code || error?.data?.response?.error?.code, "Resale Join Page"),
                useTimer: false,
            });
        });
    }

	updatePhone(val) {
		this.setState({ phoneNumber: val })
        measureInAppContext({
            pageTitle:"Ticket Resale Queue",
            action: "Phone Number Entered",
            args: val,
            metricType: "action",
        });
	}

    handleFormChange(evt) {
        let type = evt.target.name;
        type = type === 'court' ? 'Court' : 'NumTix';
        let value = evt.target.value;
        measureInAppContext({
            pageTitle:"Ticket Resale Queue",
            action: `${type} Selection`,
            args: value,
            metricType: "action",
        });
    }

    renderForm(formProps) {
		logger.log('[TicketResaleCheckin] This is the formprops %o', formProps);
		logger.log('[TicketResaleCheckin] state:%o', this.state);

        let { quantity, queueConfig } = this.props;

        // get quantity min and max and create options off of those config values 
        let quantityArr = [];
        for (let i = quantity?.min; i <= quantity?.max; i++) {
            quantityArr.push({ name: i, value: i })
        }

		return (
			<Form onChange={(evt) => this.handleFormChange(evt)} onSubmit={formProps.handleSubmit} style={{ height: '100%', position: 'relative', margin: 'auto 16px'}}>
                <FormSelect
                    label="Select a court"
                    name="court"
                    value={formProps?.values?.court || ''}
                    showerror={'true'}
                    required
                    selectClassname={!formProps?.values?.court ? 'no-resale-selection' : 'resale-selection'}
                    disabled={false}>
                    <option key="courtKey" value="">
                        -- Select Court --
                    </option>
                    {queueConfig?.map(queue => {
                        // if hidden, display hidden. if closed, display closed
                        let statusStr = '';
                        if (queue.hidden) statusStr = ' - Full';
                        if (queue.status == 0) statusStr = ' - Closed';
                        return (
                            <option key={`${queue.name}`} value={`${queue.name}`} disabled={statusStr !== ''}>
                                {queue.name}{statusStr}
                            </option>
                        );
                    })}
                </FormSelect>

                <FormSelect
                    label="Select a number of tickets"
                    name="quantity"
                    value={formProps?.values?.quantity || ''}
                    showerror={'true'}
                    required
                    selectClassname={!formProps?.values?.quantity ? 'no-resale-selection' : 'resale-selection'}
                    disabled={false}>
                    <option key="quantityKey" value="">
                        -- Select tickets --
                    </option>
                    {quantityArr
                        ? quantityArr.map(quantity => {
                            return (
                                <option  key={`${quantity.name}`} value={`${quantity.name}`}>
                                    <span>{quantity.name}</span>
                                </option>
                            );
                        })
                        : null}
                </FormSelect>

                <div className="ticket-resale-checkin-phone-number">
                    <label htmlFor='ticket-resale-checkin-phone-number'>
                        <h5 className='label purple required' tabIndex={0} aria-label="Enter phone number:">Enter phone number:</h5>
                        <IntlTelInputView isValidNumber={(val, isValid) => {this.updatePhone(val); this.setState({ isValidPhoneNumber: isValid })}}/>
                    </label>
                </div>

                <div className='ticket-resale-terms-and-conditions'>
                    {this.props.configText?.checkin_terms_and_conditions}{' '}
                    <WimLink
                        to={this.props.configUrls?.terms_and_conditions_url}
                        external={true}
                    >
                        Ticket Resale Terms and Conditions{' '}
                    </WimLink>
                </div>

                <div className='ticket-resale-enter-queue-btn'>
                    <Button
                        disabled={!this.state.phoneNumber || !formProps?.values?.quantity || !formProps?.values?.court || !this.state.isValidPhoneNumber}
                        className={`btn solid`}
                        aria-label="ENTER TICKET RESALE QUEUE"
                        onClick={() => formProps.submitForm()}
                    >
                        ENTER TICKET RESALE QUEUE
                    </Button>
                </div>
			</Form>
		);
	}

	render() {
        this.initialValues = {
			court: '',
            quantity: '',
            phoneNumber: '',
		};

        let ticketResaleTxt = this.props.configText?.checkin_header;
		return (
			<div className="ticket-resale-checkin-container" >
                <div className="ticket-resale-checkin-cms">{this.state.cmsTxt}</div>
				<div className='ticket-resale-checkin-txt' aria-label={ticketResaleTxt}>{ticketResaleTxt}</div>

                <div className="ticket-resale-checkin-form">
                    <Formik
                        enableReinitialize
                        initialValues={this.initialValues}
                        onSubmit={this.handleSubmit}
                        // validationSchema={ContactPageSchema}
                    >
                        {formProps => {
                            return this.renderForm(formProps);
                        }}
                    </Formik>
                </div>
			</div>
		);
	}
}

export default TicketResaleCheckin;

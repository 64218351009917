import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMemberContext } from '../../context';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';
import deps from 'dependencies';
import { useDispatch } from 'react-redux';
export const DiningContext = createContext({});

const DiningContextProvider = ({ children }) => {
	const [times, setTimes] = useState(null);
	const [service, setService] = useState('Select');
	const [size, setSize] = useState('Select');
	const [selectedTime, setSelectedTime] = useState('');
	const [showBookingConfirmation, setShowBookingConfirmation] = useState(false);
	const [note, setNote] = useState('');
	const [formIsSubmitted, setFormIsSubmitted] = useState(false);
	const [bookingReservationId, setBookingReservationId] = useState('');
	const [bookingVenueId, setBookingVenueId] = useState('');
	const [venueTitle, setVenueTitle] = useState('');

	const availabilityURL = useSelector(store => store.Config.member.api.diningReservations);
	const cancelURL = useSelector(store => store.Config.member.api.cancelReservation);
	const bookURL = useSelector(store => store.Config.member.api.bookReservation);

	// const allBookingsURL =

	const groupVenues = useSelector(state => state.Config.member.diningReservation.group.venues);

	const dispatch = useDispatch();
	const {
		currentUser: {
			profile: { firstName, lastName },
		},
	} = useMemberContext();

	const { invokeRequest, response, loading, queryKey, setResponse } = useAxios({
		'new-booking': {
			title: 'New Booking',
			errorText: 'There was an error creating your booking. Please try again.',
		},
		'venue-availability': {
			title: 'Venue Availability',
			errorText: 'There was an error getting the venue availability times. Please verify inputs and try again.',
		},
		'update-booking-notes': {
			title: 'Update Booking Notes',
			errorText: 'There was an error updating the booking notes. Please try again.',
		},
		'cancel-initial-booking': {
			title: 'Cancel Booking',
			errorText: 'There was an error cancelling the booking. Please try again.',
			successText: 'You have successfully canceled the booking.',
		},
	});

	const pathname = window.location.pathname;
	const booking = decodeURIComponent(pathname.substring(pathname.lastIndexOf('/') + 1));
	const bookingName = booking.replace(/%/g, '');
	const venue = groupVenues.filter(item => item?.key === bookingName)[0];

	let permittedPartySize = { options: [] };
	let permittedService = { options: [] };
	let permittedDateMin, permittedDateMax;

	if (venue?.dateRange) {
		permittedDateMin = moment(venue?.dateRange[0]).toDate();
		permittedDateMax = moment(venue?.dateRange[1]).toDate();
	} else {
		permittedDateMin = moment(new Date())
			.add(1, 'days')
			.toDate(); // N/A
	}

	let currDate = moment(new Date())
		.add(1, 'days')
		.toDate();
	let dateVal;
	// check if the current date falls within date range, otherwise use the permittedDateMin
	// need to check 1. if is the same as one of the dates, or 2. is in between the range. (cant use isSameOrAfter or isSameOrBefore for this logic)
	if (
		moment(currDate).isSame(moment(permittedDateMin)) ||
		(permittedDateMax && moment(currDate).isSame(moment(permittedDateMax))) ||
		(permittedDateMax && moment(currDate).isBetween(moment(permittedDateMin), moment(permittedDateMax))) ||
		moment(currDate).isAfter(moment(permittedDateMin))
	) {
		dateVal = currDate;
	} else {
		dateVal = permittedDateMin;
	}
	const [date, setDate] = useState(permittedDateMin);

	if (venue) {
		let services = venue?.services;
		for (let i = 0; i < services.length; i++) {
			permittedService.options.push({ value: services[i]?.name, name: services[i]?.name });
		}

		for (let i = venue?.partySizeMin; i <= venue?.partySizeMax; i++) {
			permittedPartySize.options.push({ value: i, name: i });
		}
	} else {
		permittedService = {
			options: [
				{ value: 'Lunch', name: 'Lunch' },
				{ value: 'Dinner', name: 'Dinner' },
			],
		};
		permittedPartySize = {
			options: [
				{ value: 1, name: 1 },
				{ value: 2, name: 2 },
				{ value: 3, name: 3 },
				{ value: 4, name: 4 },
			],
		};
	}

	// const partySizes = {
	// 	options: [
	// 		{ value: 1, name: 1 },
	// 		{ value: 2, name: 2 },
	// 		{ value: 3, name: 3 },
	// 		{ value: 4, name: 4 },
	// 	],
	// };
	// const services = {
	// 	options: [
	// 		{ value: 'Lunch', name: 'Lunch' },
	// 		{ value: 'Dinner', name: 'Dinner' },
	// 	],
	// };
	const resetFields = () => {
		setTimes(() => null);
		setDate(dateVal);
		setSize(() => 'Select');
		setService(() => 'Select');
		setSelectedTime(() => '');
		setFormIsSubmitted(() => false);
		setNote(() => '');
	};

	const resetSelectedTime = () => {
		setSelectedTime(() => '');
	};

	const searchVenues = key => {
		const method = 'POST';
		const url = availabilityURL;

		//default to first venue for now
		let keyVal = key || venue?.key || groupVenues[0].key;

		let venueItemId = groupVenues.filter(venue => {
			return venue.key === keyVal;
		})?.[0]?.venueId;

		let serviceValue = venue?.services.filter(item => {
			return item.name === service;
		})[0];

		const timeRange = serviceValue?.time?.split(' - ');

		const data = {
			venueId: venueItemId,
			date: moment(date).format('yyyy-MM-DD'),
			startTime: timeRange
				? timeRange[0]
				: service === 'Lunch'
				? '12:00'
				: service === 'Dinner'
				? '19:00'
				: '0:00',
			endTime: timeRange ? timeRange[1] : service === 'Lunch' ? '15:00' : service === 'Dinner' ? '21:00' : '0:00',
			// startTime: service === 'Lunch' ? '12:00' : service === 'Dinner' ? '19:00' : '0:00',
			// endTime: service === 'Lunch' ? '15:00' : service === 'Dinner' ? '21:00' : '0:00',
			partySize: parseInt(size),
		};

		if (response) {
			// Reset the response state to null
			setResponse(null);
		}

		invokeRequest('venue-availability', method, {}, url, data, '');
	};

	const cancelBooking = (resId, venueId) => {
		const data = {
			venueId,
		};

		// invokeRequest('cancel-initial-booking','POST',{},cancelURL,data,`/${response?.response.data?.reservation_id}?venueId=${venueId}`);
		invokeRequest('cancel-initial-booking', 'POST', {}, cancelURL, data, `/${resId}?venueOrGroupId=${venueId}`);
		dispatch(
			deps.actions.ConfirmationModal.toggleModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'Submitting Request',
				message: 'Submitting...',
				useTimer: false,
			})
		);
	};

	const makeBooking = async key => {
		const method = 'POST';
		const url = bookURL;

		let keyVal = key || venue?.key || groupVenues[0].key;

		let venueItemId = groupVenues.filter(venue => {
			return venue.key === keyVal;
		})?.[0]?.venueId;

		setBookingVenueId(venueItemId);

		let venueItemTitle = groupVenues.filter(venue => {
			return venue.key === keyVal;
		})?.[0]?.name;
		setVenueTitle(venueItemTitle);

		const data = {
			venueId: venueItemId,
			date: moment(date).format('yyyy-MM-DD'),
			time: selectedTime,
			firstName,
			lastName,
			partySize: parseInt(size),
			phone: '+4402089712241',
			notes: note,
		};
		invokeRequest('new-booking', method, {}, url, data, '');
		setFormIsSubmitted(true);
	};

	const convertTime = time => {
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time.length > 1) {
			time = time.slice(1);
			time[0] = +time[0] % 12 || 12;
		}
		return time.join(''); // return adjusted time or original string
	};

	useEffect(() => {
		logger.info(`[Member - DiningReservations] effect response:%o`, response);
		if (response?.response?.data?.availability) {
			let slots = response?.response.data?.availability.filter(slot => {
				return slot?.name?.toUpperCase() == service?.toUpperCase();
			});
			let times = slots[0]?.times
				? slots[0]?.times.filter(time => {
						// return (time.type == 'book' && (time?.time >= timeRange[0] && time?.time <= timeRange[1]));
						return time.type == 'book';
				  })
				: [];
			logger.info(`[Member - DiningReservations] setTimes - service:%o times:%o`, service, times);
			setTimes(times);
		}

		if (response?.response?.data?.reservation_id) {
			setShowBookingConfirmation(true);
			setBookingReservationId(response?.response?.data?.reservation_id);
		}

		if (
			queryKey === 'new-booking' &&
			![200].includes(+response?.response.status) &&
			selectedTime &&
			formIsSubmitted
		) {
			dispatch(
				deps.actions.ConfirmationModal.toggleModal({
					showModal: true,
					showCloseBtn: true,
					type: 'error',
					title: response.message,
					message: response.response.msg,
					useTimer: true,
					okCallback: resetFields(),
				})
			);
		}
		if (queryKey === 'cancel-initial-booking' && response?.response.status === 200) {
			setShowBookingConfirmation(false);
			resetFields();
		}
	}, [JSON.stringify(response), queryKey]);

	useEffect(() => {
		setTimes(null);
	}, [size, service, date]);
	return (
		<DiningContext.Provider
			value={{
				// partySizes,
				// services,
				searchVenues,
				makeBooking,
				convertTime,
				times,
				date,
				setDate,
				setService,
				service,
				size,
				setSize,
				setSelectedTime,
				selectedTime,
				setShowBookingConfirmation,
				showBookingConfirmation,
				loading,
				note,
				setNote,
				cancelBooking,
				formIsSubmitted,
				resetFields,
				permittedPartySize,
				permittedService,
				permittedDateMin,
				permittedDateMax,
				resetSelectedTime,
				bookingReservationId,
				bookingVenueId,
				venueTitle,
			}}>
			{children}
		</DiningContext.Provider>
	);
};

const useDiningContext = () => {
	const context = useContext(DiningContext);
	return context;
};

export { useDiningContext, DiningContextProvider };

import deps from 'dependencies';

export default {
    mount: params => (dispatch, getState, store) => {
		
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, ['text','edge_country', 'stubPages']).then(Config => {
            logger.log('[AICatchUpFullWebview] action.mount - data: %o', params);
            dispatch({
                type: deps.actionTypes.AICATCHUPFULLWEBVIEW_LOAD,
                data: params,
            });
        });
    }
}
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
/**
 * -----------------------------------------------------------------------------
 * React Component: PromoFeature
 * -----------------------------------------------------------------------------
 */


const mobileMatch = window.matchMedia('(max-width: 767px)').matches;
const tabletMatch = window.matchMedia('(max-width: 1023px)').matches;

class PromoFeatureReusable extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.state = {
			...props,
		};
	}

	render() {
        let { toShowCloseBtn, titleTxt, contentStyle, componentToRender, bottomButtonStyle,
            bottomButtonTxt, bottomButtonDisabled, customTitle, subCustomTitleTxt,
            subCustomTxt } = this.props;
		return (
            <div className="promo-feature-overlay modal-content-overlay">
                {toShowCloseBtn ? 
                    <CloseBtn
                        closeFn={() => {
                            document.body.style.overflow = 'auto';
                            this.props.onCloseFunc(true)
                        }}
                        role="button"
                        alt="close button"
                        aria-label="close button"
                        className="promo-feature-close-btn"
                    />
                : null}
                <div className="promo-feature-container">
                    <div className="logotitle">
                        <div title="The Championships, Wimbledon" className="wim-logo" />
                    </div>
                    {customTitle ? 
                        <div className="promo-feature-mywimbledon">
                            {customTitle}
                            <div className="promo-feature-mywimbledon-divider" />
                        </div>
                        :
                        <div className="promo-feature-mywimbledon" aria-label={`my wimbledon ${titleTxt}`} alt={`my wimbledon ${titleTxt}`}>
                            <span>my</span>WIMBLEDON{' '}
                            {titleTxt}
                            <div className="promo-feature-mywimbledon-divider" />
                        </div>}
                        <div className="promo-feature-subCustomTitleTxt" aria-label={subCustomTitleTxt} alt={subCustomTitleTxt}> 
                            {subCustomTitleTxt}
                        </div>
                        <div className="promo-feature-subCustomTxt" aria-label={subCustomTxt} alt={subCustomTxt}>
                            {subCustomTxt}
                        </div>
                    <div
                        className={customTitle ? "onboard-image-content-container" : "onboard-content-container"}
                        style={contentStyle}>
                            {componentToRender}
                    </div>

                    <div className="promo-feature-next-btn-container fixed-bottom-mobile">
                        <div className="promo-feature-box-shadow">
                            {' '}
                            {/* needs double div container for box shadow */}
                            <button
                                onClick={() => {
                                    logger.log('[PromoFeature] bottom button clicked')
                                    this.props.onCloseFunc()
                                }}
                                aria-label={bottomButtonTxt}
                                alt={bottomButtonTxt}
                                role="button"
                                style={bottomButtonStyle}
                                className="promo-feature-next-btn"
                                disabled={bottomButtonDisabled}
                            >
                                {bottomButtonTxt}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}

export default PromoFeatureReusable;
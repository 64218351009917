import React, { Component } from 'react';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';

import TermsOfService from './elements/TermsOfService';
import PrivacyPolicy from './elements/PrivacyPolicy';
import InputBox from './elements/InputBox';

class LinkAccount extends Component {
	constructor(props) {
		super(props);

		//let register_classname = gigya-conditional:className="viewport.width < 550 ?gigya-screen v2 portrait mobile:viewport.width < 920 ?gigya-screen v2 portrait:"

		//gigya-conditional:value="screenset.width < 430?100%"
		this.width = '100%';

		//gigya-conditional:value="screenset.width < 430?auto:"
		this.register_paging_button = 'newArrows';

		//gigya-conditional:value="screenset.width < 430?2:"
		this.columns = '';

		//gigya-conditional:show-password-strength="viewport.width < 920?up"
		this.strengthInfo = 'left';

		//data-caption="screenset.translations['GIGYA_REGISTER_SCREEN_CAPTION']"
		//value="screenset.translations['SUBMIT_7667737432077126_VALUE']"
	}

	render() {
		let enabledProviders = op.get(this, 'context.socialProviders', '');
		//logger.log('[Gigya] [LinkAccount] render - context:%o', this.context);

		this.register_classname = 'gigya-screen v2 gigya-link-account-screen portrait';

		return (
			<div
				id="gigya-link-account-screen"
				data-width="auto"
				className={this.register_classname}
				data-screenset-element-id="gigya-link-account-screen"
				data-screenset-roles="instance"
				data-on-pending-verification-screen="gigya-verification-pending-screen"
				data-on-success-screen="gigya-complete-registration-screen"
				gigya-default-data-caption="null"
				data-caption="Account Linking">
				<form
					className="gigya-link-accounts-form"
					id="gigya-link-accounts-form"
					data-screenset-roles="instance"
					onSubmit={() => {
						return false;
					}}>
					<div className="gigya-layout-row with-divider">
						<div id="register-social-login" className="gigya-layout-cell responsive with-social-login">
							<div className="gigya-layout-row" style={{ height: '100%' }}>
								<div className="gigya-container gigya-visible-when" data-login-identities="site-only">
									<h2
										className="gigya-composite-control gigya-composite-control-header link-accounts-main-header"
										translation-key="HEADER_66766754253798720_LABEL">
										This is the first time you have logged in with a social network.
									</h2>
								</div>
								<div className="gigya-container gigya-visible-when" data-login-identities="social">
									<h2
										className="gigya-composite-control gigya-composite-control-header link-accounts-main-header"
										translation-key="HEADER_88485663979193280_LABEL">
										You have previously logged in with a different account. To link your accounts,
										please re-authenticate.
									</h2>
								</div>
								<div
									className="gigya-container gigya-visible-when"
									data-login-identities="site-and-social">
									<h2
										className="gigya-composite-control gigya-composite-control-header"
										translation-key="HEADER_52718613360185736_LABEL">
										Log in with an existing social network:
									</h2>
								</div>
								<div className="gigya-container gigya-visible-when" data-login-identities="social-only">
									<h2
										className="gigya-composite-control gigya-composite-control-header social-login-title"
										translation-key="HEADER_89162232959083470_LABEL">
										To connect with your existing account, click below:
									</h2>
								</div>

								<div className="gigya-container" data-login-identities="social">
									<div className="gigya-composite-control gigya-composite-control-social-login">
										<div className="gigya-social-login">
											<param name="tabIndex" value="0" />
											<param name="version" value="2" />
											<param name="width" value={this.width} />
											<param name="height" value="50" />
											<param name="enabledProviders" value={enabledProviders} />
											<param name="loginMode" value="link" />
											<param name="buttonsStyle" value="fullLogoColored" />
											<param name="pagingButtonStyle" value="newArrows" />
											<param name="buttonSize" value="40" />
											<param name="columns" value={this.columns} />
											<param name="showWhatsThis" value="false" />
											<param name="showTermsLink" value="false" />
											<param name="hideGigyaLink" value="true" />
											<param name="_pluginCenterFix" value="true" />
										</div>
									</div>
									<div className="gigya-layout-cell responsive with-divider">
										<label
											className="gigya-divider-content gigya-composite-control gigya-composite-control-label"
											data-translation-key="LABEL_OR"
										/>
									</div>
								</div>
							</div>
						</div>

						<div id="register-site-login" className="gigya-layout-cell responsive with-site-login">
							<div className="gigya-layout-row">
								<div
									className="gigya-container gigya-visible-when"
									data-login-identities="site-and-social">
									<h2
										className="gigya-composite-control gigya-composite-control-header"
										style={{ textAlign: 'center' }}
										translation-key="HEADER_119803489452460820_LABEL">
										Log in with an existing site account:
									</h2>
								</div>
								<div className="gigya-container gigya-visible-when" data-login-identities="site-only">
									<h2
										className="gigya-composite-control gigya-composite-control-header"
										style={{ textAlign: 'center' }}
										translation-key="HEADER_145260704159400830_LABEL">
										To connect with your existing account, please enter your password:
									</h2>
								</div>

								<div className="gigya-layout-row">
									<div className="gigya-composite-control gigya-composite-control-textbox">
										<InputBox
											data={{
												type: 'email',
												name: 'email',
												key: 'TEXTBOX_EMAIL',
												showValid: 'true',
												class: 'gigya-input-text',
											}}
										/>
									</div>
								</div>
							</div>
							<div id="password-row" className="gigya-layout-row with-two-inputs">
								<div className="gigya-layout-cell">
									<div
										className="gigya-composite-control gigya-composite-control-password eyeDiv9"
										style={{ display: 'block' }}>
										<InputBox
											data={{
												type: 'password',
												name: 'password',
												key: 'TEXTBOX_PASSWORD',
												showValid: 'true',
												showStrength: this.strengthInfo,
												class: 'gigya-input-password',
											}}
										/>
									</div>
								</div>
								<div className="gigya-layout-cell">
									<div className="gigya-composite-control gigya-composite-control-password eyeDiv10">
										<InputBox
											data={{
												type: 'password',
												name: 'passwordRetype',
												key: 'TEXTBOX_PASSWORD_CONFIRM',
												showValid: 'true',
												showStrength: '',
												class: 'gigya-input-password',
											}}
										/>
									</div>
								</div>
								<div className="gigya-clear" />
								<div className="gigya-clear" />
							</div>
							<div
								className="gigya-composite-control gigya-spacer"
								data-units="1"
								style={{ height: '10px' }}
							/>

							<div className="gigya-layout-row">
								<div className="gigya-composite-control gigya-composite-control-submit">
									<input
										type="submit"
										className="gigya-input-submit"
										tabIndex="0"
										value="screenset.translations['BUTTON_LOGIN_VALUE']"
									/>
								</div>
								<div
									className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
									data-bound-to="gigya-register-form">
									<div
										className="gigya-error-msg gigya-form-error-msg"
										data-bound-to="gigya-register-form"
									/>
								</div>
							</div>
							<div
								className="gigya-composite-control gigya-spacer"
								data-units="1"
								style={{ height: '10px' }}
							/>
							<div className="gigya-composite-control gigya-spacer-divider" data-units="1" />

							<div className="gigya-layout-row">
								<div className="gigya-layout-cell">
									<div className="gigya-container gigya-visible-when" data-login-identities="site">
										<a
											className="gigya-composite-control gigya-composite-control-link"
											data-switch-screen="gigya-forgot-password-screen"
											style={{ textAlign: 'left' }}>
											Forgot Password?
										</a>
									</div>
								</div>
								<div className="gigya-layout-cell">
									<div className="gigya-container gigya-visible-when" data-login-identities="site">
										<a
											className="gigya-composite-control gigya-composite-control-link"
											data-switch-screen="gigya-register-screen"
											style={{ textAlign: 'right' }}>
											Create New Account
										</a>
									</div>
								</div>
							</div>
							<div className="gigya-layout-row">
								<div className="gigya-container gigya-visible-when" data-login-identities="social-only">
									<a
										className="gigya-composite-control gigya-composite-control-link gigya-skip-link-account"
										data-switch-screen="gigya-register-screen"
										style={{ textAlign: 'center' }}>
										Create New Account
									</a>
								</div>
							</div>
							<div className="gigya-clear"></div>

							<div className="gigya-layout-row">
								<div
									className="gigya-container gigya-visible-when"
									data-condition="true"
									data-on-render="false">
									<div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-invisible">
										<div className="gigya-captcha-wrapper">
											<div className="gigya-captcha">
												<param name="theme" value="light" />
												<param name="badge" value="inline" />
												<param name="type" value="image" />
												<param name="size" value="normal" />
											</div>
											<span className="gigya-error-msg" data-bound-to="captchaText" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

LinkAccount.contextType = AuthContext;
export default LinkAccount;

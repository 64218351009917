import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router';
import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';
import op from 'object-path';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
// import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...props,
		...state['NewsIndex'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.NewsIndex.filter(settings)),
});

class NewsFilter extends Component {
	static getMenuData(isPlayer) {
		let menuData = {
			options: [
				{ name: 'All', value: 'all', query: 'all', selected: true },
				{ name: 'News', value: 'news', query: 'news' },
				{ name: 'Photos', value: 'gallery', query: 'gallery' },
				{ name: 'Videos', value: 'video', query: 'video' },
				{ name: 'Press Releases', value: 'press release', query: 'press release' },
				//{ name: 'Interviews', value: 'interview', query: 'interview' },
				{ name: 'Your Players', value: 'favorite', query: 'favorite' },
				{ name: 'My Content', value: 'mycontent', query: 'mycontent' },
			],
		};

		return menuData;
	}

	constructor(props) {
		super(props);

		this.setFilter = this.setFilter.bind(this);
		this.onOpen = this.onOpen.bind(this);

		this.state = {
			...props,
		};

		this.useFavPlayers = true;
		//logger.log('[NewsFilter] props:%o', props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[NewsFilter] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	setFilter(filter) {
		logger.log('[NewsFilter] setFilter:%o', filter);

		let nav = filter != 'all' ? this.state.basePath + '?filter=' + filter : this.state.basePath;
		this.setState({
			nav: nav,
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	redirect() {
		if (this.state.nav) {
			return <Redirect push to={this.state.nav} />;
		} else {
			return null;
		}
	}

	render() {
		logger.log('[NewsFilter] render - state:%o', this.state);
		let roles = op.get(this.props, 'currentUser.roles', []);
		roles = roles.filter(role => {
			if (role.roleId == 1000050) {
				return true;
			} else {
				return false;
			}
		});

		let isPlayer = isEmpty(roles) ? false : true;
		let menuData = {
			options: [
				{ name: 'All', value: 'all', query: 'all', selected: true },
				{ name: 'News', value: 'news', query: 'news' },
				{ name: 'Photos', value: 'gallery', query: 'gallery' },
				{ name: 'Videos', value: 'video', query: 'video' },
				{ name: 'Press Releases', value: 'press release', query: 'press release' },
				//{ name: 'Interviews', value: 'interview', query: 'interview' },
				{ name: 'Your Players', value: 'favorite', query: 'favorite' },
				// { name: 'My Content', value: 'mycontent', query: 'mycontent' }
			],
		};

		if (isPlayer) {
			menuData.options.push({ name: 'My Content', value: 'mycontent', query: 'mycontent' });
		}
		if (!this.state.filters) {
			logger.warn('[NewsFilter] no filters specified');
			return null;
		}

		logger.log('[NewsFilter] render - filter:%o', this.state.filters.type);
		if (this.state.basePath) {
			return (
				<div className="news-landing-filters">
					<div className="filter-buttons" tabIndex={0} aria-label="entering news timeline section">
						<NavLink
							to={this.state.basePath}
							className={this.state.filters.type === 'all' ? 'selected' : ''}
							aria-label={`filter on all, ${
								this.state.filters.type === 'all' ? 'currently' : 'not currently'
							} selected in news landing group`}>
							<i className="wim-icon-all" aria-hidden={true} />
							<div className="filter-text">ALL</div>
						</NavLink>
						<NavLink
							to={`${this.state.basePath}?filter=news`}
							aria-label={`filter on news, ${
								this.state.filters.type === 'news' ? 'currently' : 'not currently'
							} selected in news landing group`}
							className={this.state.filters.type === 'news' ? 'selected' : ''}>
							<i className="wim-icon-news" aria-hidden={true} />
							<div className="filter-text">NEWS</div>
						</NavLink>
						<NavLink
							tabIndex={-1}
							aria-hidden
							role="none"
							to={`${this.state.basePath}?filter=gallery`}
							aria-label={`filter on gallery, ${
								this.state.filters.type === 'gallery' ? 'currently' : 'not currently'
							} selected in news landing group`}
							className={this.state.filters.type === 'gallery' ? 'selected' : ''}>
							<i className="wim-icon-gallery" aria-hidden={true} />
							<div className="filter-text" tabIndex={-1} aria-hidden role="none">
								PHOTOS
							</div>
						</NavLink>
						<NavLink
							to={`${this.state.basePath}?filter=video`}
							aria-label={`filter on video, ${
								this.state.filters.type === 'video' ? 'currently' : 'not currently'
							} selected in news landing group`}
							className={this.state.filters.type === 'video' ? 'selected' : ''}>
							<i className="wim-icon-video" aria-hidden={true} />
							<div className="filter-text">VIDEOS</div>
						</NavLink>
						<NavLink
							to={`${this.state.basePath}?filter=press release`}
							aria-label={`filter on press release, ${
								this.state.filters.type === 'press release' ? 'currently' : 'not currently'
							} selected in news landing group`}
							className={this.state.filters.type === 'press release' ? 'selected' : ''}>
							<i className="wim-icon-press" aria-hidden={true} />
							<div className="filter-text">PRESS RELEASES</div>
						</NavLink>
						{/** removed in 2023 as were no interviews
						<NavLink
							to={`${this.state.basePath}?filter=interview`}
							aria-label={`filter on interview, ${
								this.state.filters.type === 'interview' ? 'currently' : 'not currently'
							} selected in news landing group`}
							className={this.state.filters.type === 'interview' ? 'selected' : ''}>
							<i className="wim-icon-person" aria-hidden={true} />
							<div className="filter-text">INTERVIEWS</div>
						</NavLink>
						 */}
						{this.useFavPlayers ? (
							<NavLink
								to={`${this.state.basePath}?filter=favorite`}
								aria-label={`filter on favorite, ${
									this.state.filters.type === 'favorite' ? 'currently' : 'not currently'
								} selected in news landing group`}
								className={this.state.filters.type === 'favorite' ? 'selected' : ''}>
								<i className="wim-icon-favStar" aria-hidden={true} />
								<div className="filter-text">YOUR PLAYERS</div>
							</NavLink>
						) : null}
						{isPlayer ? (
							<NavLink
								to={`${this.state.basePath}?filter=mycontent`}
								aria-label={`filter on my content, ${
									this.state.filters.type === 'mycontent' ? 'currently' : 'not currently'
								} selected in news landing group`}
								className={this.state.filters.type === 'mycontent' ? 'selected' : ''}>
								<i className="wim-icon-mycontent" aria-hidden={true} />
								<div className="filter-text">MY CONTENT</div>
							</NavLink>
						) : null}
					</div>
					<div className="filter-dropdown">
						<SelectMenu
							name="filter"
							attributes={menuData}
							selected={this.state.filters.type}
							show={this.state.filters.open == 'filter'}
							onSelected={this.setFilter}
							onOpen={this.onOpen}
						/>
					</div>
					{this.redirect()}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsFilter);

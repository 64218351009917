import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { playerFavorites } from 'appdir/components/data/Schema';

const v = new Validator();

const getFeedData = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

const getHomePageFavorites = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				logger.log('[HomePage] fetched - 200 validating response');
				let validateResult = v.validate(response.data, playerFavorites);
				// if (validateResult.valid) {
				if (true) {
					return response.data;
				} else {
					throw new Error('failed schema validation');
					return null;
				}
			} else {
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			logger.warn('[HomePage] getHomePageFavorites - %o', error.message);
			if (error.response && error.response.status === 404) {
				return null;
			}
			throw new Error(error.message);
		});
};

export default {
	fetch,
	getFeedData,
	getHomePageFavorites,
};

import TestPage from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/testwim/', '/testwim/:component'],
		exact: true,
		component: TestPage,
		load: params => deps.actions.TestPage.mount(params),
	},
	{
		path: '/testwim/:component/:playerid.html',
		exact: true,
		component: TestPage,
		load: params => deps.actions.TestPage.mount(params),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Fragment } from 'react';
import JSXParser from 'react-jsx-parser';
import Translate from 'appdir/components/pages/Slamtracker/Translate';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ReturnStats
 * -----------------------------------------------------------------------------
 */
const ReturnStats = props => {
	// logger.log('[ReturnStats] props: %o', props);

	const return_stat_rows = ['return_winners', 'return_unforced_errors', 'return_points', 'return_points_won'];
	
	let names = props.data.names;
	let t1_stats = props.data.t1;
	let t2_stats = props.data.t2;
	let doubles = props.data.doubles;

	let t1_first, t1_second, t1_total;
	let t2_first, t2_second, t2_total;

	const getStatData = (stat) => {
		switch (stat) {
			case 'return_winners':
				t1_first = t1_stats?.f_srv_rtn_wrs == null ? '-' : t1_stats?.f_srv_rtn_wrs;
				t1_second = t1_stats?.s_srv_rtn_wrs == null ? '-' : t1_stats?.s_srv_rtn_wrs;
				t1_total = t1_stats?.t_rtn_wrs == null ? '-' : t1_stats?.t_rtn_wrs;
				t2_first = t2_stats?.f_srv_rtn_wrs == null ? '-' : t2_stats?.f_srv_rtn_wrs;
				t2_second = t2_stats?.s_srv_rtn_wrs == null ? '-' : t2_stats?.s_srv_rtn_wrs;
				t2_total = t2_stats?.t_rtn_wrs == null ? '-' : t2_stats?.t_rtn_wrs;
				break;
			case 'return_unforced_errors':
				t1_first = t1_stats?.f_srv_rtn_ue == null ? '-' : t1_stats?.f_srv_rtn_ue;
				t1_second = t1_stats?.s_srv_rtn_ue == null ? '-' : t1_stats?.s_srv_rtn_ue;
				t1_total = t1_stats?.t_rtn_ue == null ? '-' : t1_stats?.t_rtn_ue;
				t2_first = t2_stats?.f_srv_rtn_ue == null ? '-' : t2_stats?.f_srv_rtn_ue;
				t2_second = t2_stats?.s_srv_rtn_ue == null ? '-' : t2_stats?.s_srv_rtn_ue;
				t2_total = t2_stats?.t_rtn_ue == null ? '-' : t2_stats?.t_rtn_ue;
				break;
			case 'return_points':
				t1_first = t1_stats?.f_srv_rtn_p == null ? '-' : t1_stats?.f_srv_rtn_p;
				t1_second = t1_stats?.s_srv_rtn_p == null ? '-' : t1_stats?.s_srv_rtn_p;
				t1_total = t1_stats?.t_rtn_p == null ? '-' : t1_stats?.t_rtn_p;
				t2_first = t2_stats?.f_srv_rtn_p == null ? '-' : t2_stats?.f_srv_rtn_p;
				t2_second = t2_stats?.s_srv_rtn_p == null ? '-' : t2_stats?.s_srv_rtn_p;
				t2_total = t2_stats?.t_rtn_p == null ? '-' : t2_stats?.t_rtn_p;
				break;
			case 'return_points_won':
				t1_first = t1_stats?.f_srv_rtn_p_w == null ? '-' : t1_stats?.f_srv_rtn_p_w;
				t1_second = t1_stats?.s_srv_rtn_p_w == null ? '-' : t1_stats?.s_srv_rtn_p_w;
				t1_total = t1_stats?.t_rtn_p_w == null ? '-' : t1_stats?.t_rtn_p_w;
				t2_first = t2_stats?.f_srv_rtn_p_w == null ? '-' : t2_stats?.f_srv_rtn_p_w;
				t2_second = t2_stats?.s_srv_rtn_p_w == null ? '-' : t2_stats?.s_srv_rtn_p_w;
				t2_total = t2_stats?.t_rtn_p_w == null ? '-' : t2_stats?.t_rtn_p_w;
				break;
			default:
				break;
		}

		return {t1_first, t1_second, t1_total, t2_first, t2_second, t2_total};
	}

	return (
		<div className="stats-table-wrapper return">
			<div className="stats-header-container">
				<div className="stats-caption-table">
					<div className="stats-header-caption t1">
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[0]} />
						</div>
					</div>
					<div className="stats-header-caption t2">
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[1]} />
						</div>
					</div>
				</div>
				<div className="stats-header-table">
					<div className="stats-header-cell first">
						<Translate originalText="first_serve_num" />
					</div>
					<div className="stats-header-cell second">
						<Translate originalText="second_serve_num" />
					</div>
					<div className="stats-header-cell">
						<Translate originalText="total" />
					</div>
					<div className="stats-header-spacer" />
					<div className="stats-header-cell first">
						<Translate originalText="first_serve_num" />
					</div>
					<div className="stats-header-cell second">
						<Translate originalText="second_serve_num" />
					</div>
					<div className="stats-header-cell">
						<Translate originalText="total" />
					</div>
				</div>
			</div>

			<div className="stats-table">
				{return_stat_rows.map((stat, index) => {
					let rowData = getStatData(stat);

					return (
						<Fragment key={`${stat}-row${i}`}>
							<div className="stats-row" key={`${stat}-label`}>
								<div className="stats-label">
									<Translate originalText={stat} />
								</div>
							</div>
							<div className={`stats-row ${index != return_stat_rows.length - 1 ? 'border' : ''}`} key={stat}>
								<div className="stats-data t1 first">{rowData.t1_first}</div>
								<div className="stats-data t1 second">{rowData.t1_second}</div>
								<div className="stats-data t1 total">{rowData.t1_total}</div>
								<div className="stats-data-spacer" />
								<div className="stats-data t2 first">{rowData.t2_first}</div>
								<div className="stats-data t2 second">{rowData.t2_second}</div>
								<div className="stats-data t2 total">{rowData.t2_total}</div>
							</div>
						</Fragment>
					)
				})}
			</div>
		</div>
	);
};

export default ReturnStats;
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: term => (dispatch, getState, store) => {
		//logger.log('[DrawsArchiveSearch] mount - term:%o', term);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Draws Archive Search',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let pathObjs = {
				playerSearchPath: Config.drawsArchive.search,
				flagImagePath: Config.otherData.flagImagePathSmall,
			};
			logger.log('[DrawsArchiveSearch] mount - paths:%o', pathObjs);
			dispatch({ type: deps.actionTypes.DRAWSARCHIVE_SEARCH, data: pathObjs });
		});
	},
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	let eventRound;

	switch (action.type) {
		case deps.actionTypes.DRAWSPAGE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[DrawsPage] reducers DRAWSPAGE_MOUNT - newState:%o',newState);

			return newState;

		case deps.actionTypes.DRAWSPAGE_UNMOUNT:
			logger.log('[DrawsPage] DRAWSPAGE_UNMOUNT - data:%o', action.data);
			// let filters = {
			// 	event: '',
			// 	open: ''
			// }
			filters = {
				...state.filters,
			};

			eventRound = {
				...state.eventRound,
				pageReturn: true,
				roundSection: '',
				selectedRound: 0,
			};

			newState = {
				...state,
				drawsPath: action.data.drawsPath,
				filters: filters,
				eventRound: eventRound,
				mountStatus: null,
			};

			logger.log('[DrawsPage] DRAWSPAGE_UNMOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWSPAGE_LOAD:
			//logger.log('[DrawsPage] reducers - action:%o', action);

			newState = {
				...state,
				...action.data,
			};
			//logger.log('[DrawsPage] reducers RESULTSPAGE_LOAD - newState:%o',newState);

			return newState;

		// case deps.actionTypes.DRAWSPAGE_FILTER:
		// 	//logger.log('[DrawsPage] RESULTSPAGE_FILTER - data:%o', action.data);

		// 	filters = {
		// 		...state.filters,
		// 		...action.data,
		// 	};

		// 	newState = {
		// 		...state,
		// 		filters: filters,
		// 		status: 'change',
		// 	};
		// 	//logger.log('[DrawsPage] DRAWSPAGE_FILTER - newState:%o', newState);
		// 	return newState;

		case deps.actionTypes.DRAWS_SEARCH:
			// eventRound = {
			// 	...state.eventRound,
			// 	roundSection: action.data.roundSection,
			// 	selectedRound: action.data.selectedRound,
			// 	clicked: action.data.clicked,
			// };
			newState = {
				...state,
				// eventRound,
				searchedPlayer: action.data.searchedPlayer,
				searchRound: action.data.searchRound,
				found: false,
			};
			return newState;

		case deps.actionTypes.DRAWSPAGE_ROUND:
			//logger.log('[DrawsPage] DRAWSPAGE_ROUND - data:%o', action.data

			eventRound = {
				...state.eventRound,
				...action.data,
			};

			newState = {
				...state,
				eventRound: eventRound,
			};
			//logger.log('[DrawsPage] DRAWSPAGE_ROUND - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWSPAGE_UPDATE:
			// logger.log('[DrawsPage] DRAWSPAGE_UPDATE - data:%o', action.data);

			eventRound = {
				...state.eventRound,
				...action.data.eventRound,
			};
			filters = {
				...state.filters,
				...action.data.filters,
			};

			newState = {
				...state,
				eventRound: eventRound,
				filters: filters,
			};
			logger.log('[DrawsPage] DRAWSPAGE_UPDATE - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWS_RENDER:
			newState = {
				...state,
				timestamp: action.data,
			};

			return newState;

		case deps.actionTypes.DRAWS_FIND:
			newState = {
				...state,
				found: action.data,
			};
			return newState;

		case deps.actionTypes.DRAWS_CHANGE_VIEW:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[DrawsPage] DRAWS_CHANGE_VIEW - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};

import React from 'react';

function SingleTicketStub(props) {
	const { id, middleContents, imageToRender, date, isWebview } = props;

	const { court, type, gangway, row, seat } = middleContents;

	return (
		<div className="single-ticket-stub">
			<div className="single-ticket-stub-display">
				<img
					src="/assets/images/tickets/desktopTicketBorderShape.jpg"
					aria-hidden={true}
					className="single-ticket-desktop-border-shape"
				/>
				<div className="row row-content single-ticket-stub-content" key={`${id}-ticket-stub-parent-container`}>
					<div
						className={isWebview ? 'single-ticket-stub-container-webview' : 'single-ticket-stub-container'}>
						<div className={isWebview ? 'single-ticket-img-cntr-webview' : 'single-ticket-img-cntr'}>
							<img className="web-ticket-stub-image" src={imageToRender} />
						</div>
						<div
							className={
								isWebview ? 'single-ticket-text-container-webview' : 'single-ticket-text-container'
							}
							tabIndex={0}
							aria-label={`court: ${court}, date: ${date}, gangway: ${gangway}, row: ${row}, seat: ${seat}, type: ${type}`}>
							<div style={{ display: court ? 'block' : 'none' }} className="single-tix-stub-court">
								{court}
							</div>
							<div style={{ display: date ? 'block' : 'none' }} className="single-tix-stub-txt">
								{date || ''}
							</div>
							<div
								style={{ display: gangway || row || seat ? 'block' : 'none' }}
								className="single-tix-stub-txt">
								<span>{gangway}&nbsp;&nbsp;</span>
								<span>{row}&nbsp;&nbsp;</span>
								<span>{seat}&nbsp;</span>
							</div>
							<div className="single-tix-stub-type">{type?.length && (type?.toString() !== 'undefined') ? type : ''}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SingleTicketStub;

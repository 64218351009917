/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import op from 'object-path';
import NewsTile from 'appdir/components/content/NewsTile';
import GenericCarousel from 'appdir/components/common-ui/GenericCarousel';
import PageNav from 'appdir/components/common-ui/PageNav';
import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerMedia
 * -----------------------------------------------------------------------------
 */

class PlayerMedia extends Component {
	constructor(props) {
		super(props);
		logger.log('[PlayerMedia] - props', this.props);

		this.updateTab = this.updateTab.bind(this);

		this.state = {
			tab: 'news',
			carouselItems: op.get(this, 'props.relatedContent.content', []).filter(item => item.type === 'news'),
			videoOpen: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PlayerMedia] this.state.tab', this.state.tab);
		// logger.log('[PlayerMedia] - this.state.videoOpen', this.state.videoOpen);
		if (this.state.videoOpen !== prevState.videoOpen) {
			this.handleSliderVideoOpen();
		}
		if (this.state.tab !== prevState.tab) {
			this.measureClick();
		}
	}

	componentWillUnmount() {
		if (document.querySelector('.video-open')) {
			const sliderWrapper = document.querySelector('.slick-list');
			sliderWrapper.classList.remove('video-open');
		}
	}

	measureClick() {
		const pageNavItems = [
			{ name: 'News', value: 'news' },
			{ name: 'Photos', value: 'photo' },
			{ name: 'Videos', value: 'video' },
		];

		if (window.webview) {
			measureInAppContext({
				pageTitle: 'Player Profile',
				action: 'Media',
				args: pageNavItems.find(tab => tab.value === this.state.tab).name,
				context: [{ pp_playerID: this.props.playerID }],
				metricType: 'action',
			});
		} else {
			measureInWeb({
				action: 'mediaTabClick',
				context: [
					{
						tab: pageNavItems.find(tab => tab.value === this.state.tab).name,
						player_id: this.props?.playerID,
					},
				],
			});
		}
	}

	formatNewsTileData(relatedContent) {
		if (relatedContent.type === 'video') {
			let adPath = relatedContent.media.adTag ? relatedContent.media.adTag : '';
			logger.log('[PlayerMedia] - relatedContent:%o', relatedContent);
			return {
				fullscreenMode: true,
				cmsId: relatedContent.cmsId,
				title: relatedContent.title,
				url: relatedContent.url,
				adUrl: adPath,
				shareUrl: relatedContent.shareUrl,
				date: relatedContent.date || relatedContent.displayDate,
				type: 'video',
				duration: relatedContent?.metadata?.formattedDuration || relatedContent?.metadata?.duration,
				thumb: relatedContent?.images[0]?.xlarge,
				videoUrl: relatedContent.media.mp4,
				aspect: 'wide',
				videoSrc: relatedContent.media.src,
			};
		}

		return {
			img: relatedContent?.images[0]?.xlarge,
			new: true,
			type: relatedContent.type,
			typeText: relatedContent.type,
			date: relatedContent.date || relatedContent.displayDate,
			title: relatedContent.title,
			style: 'two-col',
			description: relatedContent.description ? relatedContent.description : '',
			url: relatedContent?.url ? relatedContent.url : '',
		};
	}

	handleNewsTileClick = item => {
		if (item.type === 'photo') {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Player Profiles',
					action: 'Gallery',
					args: 'Detail',
					context: [
						{ content_gallery: item.title ? item.title : item.shortTitle },
						{ section: 'Player Profiles' },
					],
					metricType: 'state',
				});
			} else {
				measureInWeb({
					action: 'Gallery',
					context: [
						{ content_gallery: item.title ? item.title : item.shortTitle },
						{ section: 'Player Profiles' },
					],
				});
			}
		}
		if (item.type === 'news') {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Player Profiles',
					action: 'Article',
					args: 'Detail',
					context: [
						{ content_article: item.title ? item.title : item.shortTitle },
						{ section: 'Player Profiles' },
					],
					metricType: 'state',
				});
			} else {
				measureInWeb({
					action: 'Article',
					context: [
						{ content_article: item.title ? item.title : item.shortTitle },
						{ section: 'Player Profiles' },
					],
				});
			}
		}
		if (item.type === 'video') {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Video',
					action: 'Player Profiles',
					args: 'Play',
					context: [
						{ content_vod: item.title ? item.title : item.shortTitle },
						{ video_location: 'Player Profiles' },
						{ content_all: item.title ? item.title : item.shortTitle },
					],
					metricType: 'action',
				});
			} else {
				measureInWeb({
					action: 'selectVideo',
					context: [
						{ content_vod: item.title ? item.title : item.shortTitle },
						{ video_location: 'Player Profiles' },
						{ content_all: item.title ? item.title : item.shortTitle },
					],
				});
			}
		}
	};

	handleVideoClick = () => {
		this.setState(prevState => ({
			videoOpen: !prevState.videoOpen,
		}));
	};

	handleSliderVideoOpen() {
		const sliderWrapper = document.querySelector('.slick-list');
		if (this.state.videoOpen) {
			sliderWrapper.classList.add('video-open');
		}
	}

	closeVideo = () => {
		const sliderWrapper = document.querySelector('.slick-list');
		this.setState({ videoOpen: false });
		sliderWrapper.classList.remove('video-open');
	};

	updateTab(item) {
		this.setState({
			tab: item,
			carouselItems: op
				.get(this, 'props.relatedContent.content', [])
				.filter(contentItem => contentItem.type.toLowerCase() === item),
		});
	}

	render() {
		let slideWidth = this.props.mobile ? 275 : 300;
		logger.log('[PlayerMedia] - state:%o slideWidth:%o', this.state, slideWidth);

		const pageNavItems = [
			{ name: 'News', value: 'news' },
			{ name: 'Photos', value: 'photo' },
			{ name: 'Videos', value: 'video' },
		];
		const metricsData = { pageTitle: 'Player Profiles' };
		return (
			<div className="player-media">
				{/* <PageNav
					menuItems={pageNavItems}
					classes={'player-media-tabs'}
					isActive={this.state.tab}
					clickFn={this.updateTab}
				/> */}
				<ul className="player-media-tabs">
					{pageNavItems.map(item => (
						<>
							<li
								className={`player-media-tab ${this.state.tab === item.value ? 'active' : ''}`}
								onClick={() => this.updateTab(item.value)}>
								{item.name}
							</li>
							<span className="divider" />
						</>
					))}
				</ul>

				{this.state.carouselItems.length > 0 ? (
					<GenericCarousel
						className={'content-carousel'}
						slideWidth={slideWidth}
						key={this.state.tab}
						metricsData={metricsData}>
						{this.state.carouselItems.map(item => (
							<div className="content-wrap" onClick={() => this.handleNewsTileClick(item)}>
								<NewsTile
									classes={'carousel'}
									attributes={this.formatNewsTileData(item)}
									key={item.cmsId}
									onClick={this.handleVideoClick}
									onClose={this.closeVideo}
								/>
							</div>
						))}
					</GenericCarousel>
				) : (
					<div className="no-media-content">{`This player has no ${this.state.tab} content`}</div>
				)}
			</div>
		);
	}
}

export default PlayerMedia;

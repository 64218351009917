/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';

/**
 * -----------------------------------------------------------------------------
 * React Component: Alert
 * -----------------------------------------------------------------------------
 */

class Carousel extends Component {
	constructor(props) {
		super(props);
		this._imageGallery = null;
		this.state = Object.assign({}, this.props.attributes);
		this.state = { ...this.props.attributes, currIndex: 0 };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps.attributes ? nextProps.attributes : nextProps);
		});
	}

	slideTo(index) {
		this._imageGallery.slideToIndex(index);
	}

	onSlide() {
		if (this._imageGallery) {
			let currIndex = this._imageGallery.getCurrentIndex();
			this.setState(prevState => {
				return { ...prevState, currIndex: currIndex };
			});
			if (this.state.onSlide) {
				this.state.onSlide(currIndex);
			}
		}
	}

	toggleSlideNav(slideDirection) {
		let currIndex = this._imageGallery.getCurrentIndex();
		let newIndex = currIndex;
		if (slideDirection == 'next' || slideDirection == 'right') {
			newIndex = currIndex + 1 <= this.state.items.length - 1 && this.state.items.length > 0 ? currIndex + 1 : 0;
		} else {
			newIndex = currIndex - 1 > -1 && this.state.items.length > 0 ? currIndex - 1 : this.state.items.length - 1;
		}
		this.slideTo(newIndex);
	}

	pause() {
		this._imageGallery.pause();
	}

	play() {
		this._imageGallery.play();
	}

	render() {
		logger.info('[Carousel] render - state:%o', this.state);

		let imagesBullets = this.state.items.map((d, i) => i);
		return (
			<div className={`carousel-wrapper ${this.props.attributes.class ? this.props.attributes.class : ''}`}>
				<div className="nav-wrapper">
					{this.state.customNav ? (
						this.state.customNavHTML ? (
							this.state.customNavHTML.leftNav('left', this.toggleSlideNav.bind(this))
						) : (
							<div
								className="custom-slide prev-slide"
								onClick={() => {
									this.toggleSlideNav('prev');
								}}
							/>
						)
					) : null}

					{this.state.customBullets
						? imagesBullets.map((d, i) => {
								return (
									<div
										key={i}
										className={`nav-dot ${i == this.state.currIndex ? 'selected' : ''}`}
										onClick={() => {
											this.slideTo(i);
										}}>
										●
									</div>
								);
						  })
						: null}

					{this.state.customNav ? (
						this.state.customNavHTML ? (
							this.state.customNavHTML.rightNav('right', this.toggleSlideNav.bind(this))
						) : (
							<div
								className="custom-slide next-slide"
								onClick={() => {
									this.toggleSlideNav('next');
								}}
							/>
						)
					) : null}
				</div>
				<ImageGallery
					ref={i => (this._imageGallery = i)}
					items={this.state.items}
					showThumbnails={this.state.showThumbnails}
					showFullscreenButton={this.state.showFullscreenButton}
					showPlayButton={this.state.showPlayButton}
					showBullets={this.state.showBullets}
					onImageLoad={this.state.onImageLoad ? this.state.onImageLoad.bind(this) : null}
					showNav={this.state.showNav}
					autoPlay={this.state.autoPlay || false}
					onSlide={this.onSlide.bind(this)}
					slideInterval={this.state.slideInterval || 5000}
					startIndex={this.state.currIndex}
				/>
			</div>
		);
	}
}

export default Carousel;

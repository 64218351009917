import React, { Component } from 'react';
import TicketActionModal from '../../pages/Tickets/elements/TicketActionModal';
import { passAppData } from 'appdir/components/general/Util';
import { measureInAppContext } from 'appdir/components/general/Analytics';

class WebViewTicketStubActions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			openModalTitle: '',
			showSwap: false,
		};
	}

	onExecuteSelectedAction(action, fullTicketValue) {
		// open modal for ticket action
		this.props.onUpdateModalStatus(true);

		if (action === 'show full ticket') {
			passAppData({
				type: 'tickets_view',
				source: 'ibm_web',
				data: { ticketId: fullTicketValue.externalId },
			});
			this.setState({ isOpen: false });
			this.props.onActionClicked();
			this.props.onUpdateModalStatus(false);
		} else {
			this.setState({ isOpen: true, openModalTitle: action });
			document.querySelector('body').style.overflow = 'hidden';
		}
	}

	onActionButtonClick(value, fullTicketValue, ticketStubToRender) {
		{
			if (value === 'swap') {
				this.props.setShowTicketSwap(true);
				this.props.setTicketValueData(fullTicketValue, ticketStubToRender);
			} else {
				/* NOTE: commenting out rather than deleting in case we need to add it back in */ 
				// if (value === 'show full ticket') {
				// 	let { day } = fullTicketValue;
				// 	let ticketType = day === 'UGP' || day === 'QUALS' ? `${day} Ticket` : 'Main Draw Ticket';
				// 	measureInAppContext({
				// 		pageTitle: 'Tickets',
				// 		action: ticketType, // measureAction,
				// 		args: `Day ${day}`, // measure_args,
				// 	});
				// }
				this.onExecuteSelectedAction(value, fullTicketValue);
			}
		}
	}

	onCheckKeepOneInGroup(ticket, data) {
		let keepOneInGroup = ticket?.source?.transferRules?.keepOneInGroupId;
		let groupId = ticket?.source?.transferRules?.groupId;

		// get all those matching it's group id in the data (includes self)
		let matchingGroupIds = data.filter(d => {
			return d?.source?.transferRules?.groupId === groupId;
		});

		// compare to those already transferred
		let interesectionTransferred = matchingGroupIds?.filter(x => {
			return !x?.actions?.includes('transfer');
		});

		// if sum of both selected and transferred equal limit, cannot swap
		return interesectionTransferred?.length === matchingGroupIds.length - 1;
	}

	render() {
		const { actionsList, fullTicketValue, fullActionsList, onActionClicked, ticketData } = this.props;
		const { isOpen, openModalTitle } = this.state;

		let ticketStubToRender = this.props.onRenderTicketStub();

		const taxNumber = fullTicketValue?.purchase?.taxationNumber

		let disableSwap;

		return (
			<div className="ticket-stub-actions-container" style={{ backgroundColor: isOpen ? 'transparent' : 'auto' }}>
				{!isOpen ? (
					<div>
						{fullActionsList.map((value, index) => {
							let valText;
							if (value !== 'cancel') {
								switch (value) {
									case 'recall':
										valText = 'cancel transfer';
										break;
									case 'return':
										valText = 'return to sender';
										break;
									case 'transfer':
										valText = 'transfer ticket';
										break;
									case 'swap':
										// check for toDisableSwap, if not check keepOneInGroup, disable swap if true
										disableSwap = this.props.toDisableSwap
											? true
											: fullTicketValue?.source?.transferRules?.keepOneInGroupId
											? this.onCheckKeepOneInGroup(fullTicketValue, ticketData)
											: false;
										valText = 'swap ticket';
										break;
									default:
										valText = value;
										break;
								}

								// dont show swap action button if it's not available
								if (value === 'swap' && (disableSwap || !actionsList.includes('swap'))) return null;
								return (
									<button
										className={
											value === 'show full ticket'
												? 'webview-ticket-stub-actions-value-green'
												: 'webview-ticket-stub-actions-value'
										}
										id={index}
										alt={`${valText} action`}
										key={`${index}-ticket-actions-button`}
										disabled={!actionsList.includes(value)}
										tabIndex={!actionsList.includes(value) ? -1 : 0}
										aria-hidden={!actionsList.includes(value)}
										onClick={() =>
											this.onActionButtonClick(value, fullTicketValue, ticketStubToRender)
										}>
										{valText}
									</button>
								);
							}
						})}
						{taxNumber ? <div className="ticket-stub-actions-fileno">Id: {taxNumber}</div> : null}
					</div>
				) : (
					<TicketActionModal
						isOpen={isOpen}
						closeModal={isResponseChange => {
							this.setState({ isOpen: false });
							onActionClicked();
							this.props.onUpdateModalStatus(false);
							if (isResponseChange) {
								this.props.onRefreshData();
							}
							document.querySelector('body').style.overflow = 'scroll';
						}}
						fullTicketValue={fullTicketValue}
						title={openModalTitle}
						ticketStubToRender={ticketStubToRender}
						onRefreshTicketData={() => this.props.onRefreshData()}
						ticketData={this.props.ticketData}
						onRenderSingleTicketStub={(value, err, email) =>
							this.props.onRenderTicketStub(value, err, email)
						}
						onSoftRefreshData={() => this.props.onSoftRefreshData()}
					/>
				)}
			</div>
		);
	}
}

export default WebViewTicketStubActions;

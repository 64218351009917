/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import isEqual from 'lodash/isEqual';
import WimLink from 'appdir/components/general/WimLink';
import RadioPlayersWrapper from './RadioPlayersWrapper';
import MeasurementUtils from 'appdir/lib/analytics';
import Time from 'appdir/components/common-ui/Time';

// components
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: RadioWebview
 * this is the pop-up radio page that uses webview so side panel won't load
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['RadioWebview'],
		activeDataPath: op.get(state['Config'].otherData, 'activeData.path', null),
		radioPlayerConfig: op.get(state['Config'], 'radioPlayerConfig', null),
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.RadioWebview.mount()),
	savePlayerHandle: data => dispatch(deps.actions.RadioWebview.savePlayerHandle(data)),
	updateChannelStatus: data => dispatch(deps.actions.RadioWebview.updateChannelStatus(data)),
});

class RadioWebview extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};
		this.activeDataTimer = null;
		this.activeDataLoaded = false;

		this.togglePlay = this.togglePlay.bind(this);
		this.keyPress = this.keyPress.bind(this);

		this.endTime = 'Close of Play';
	}

	componentDidMount() {
		// logger.log('[RadioWebview] componentDidMount - state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[RadioWebview] componentDidUpdate - state:%o', this.state);

		if (!this.activeDataLoaded && this.state.activeDataPath) {
			this.activeDataLoaded = true;
			this.getActiveData(prevState);
		}

		let stationId = op.get(this.props, 'stationId', null);
		let muted = op.get(this.props, 'muted', false);
		let paused = op.get(this.props, 'paused', false);
		let player = op.get(this.props, 'player', {});
		// handle play/pause when a button clicked

		if (prevState && player[stationId]) {
			if (prevState.stationId !== stationId) {
				logger.log(
					'[RadioWebview] componentDidUpdate new channel clicked channel:%o, prevState:%o',
					stationId,
					prevState
				);
				try {
					if (player[prevState.stationId]) {
						player[prevState.stationId].player.pause();
					}
					player[stationId].player.play();
				} catch (e) {
					logger.error('[RadioWebview] componentDidUpdate new channel: stationId%o, e:%o', stationId, e);
				}
			}
		}

		if (prevState.paused !== paused && player[stationId] && player[stationId].player) {
			//logger.log('[RadioWebview] componentDidUpdate pause state changed: paused:%o', paused);
			if (paused) {
				logger.log('[RadioWebview] componentDidUpdate pause channel:%o', stationId);
				try {
					player[stationId].player.pause();
				} catch (e) {
					logger.error('[RadioWebview] componentDidUpdate pause: stationId%o, e:%o', stationId, e);
				}
			} else if (!paused) {
				logger.log('[RadioWebview] componentDidUpdate play channel:%o', stationId);
				try {
					player[stationId].player.play();
				} catch (e) {
					logger.error('[RadioWebview] componentDidUpdate play: stationId%o, e:%o', stationId, e);
				}
			}
		}

		//
		const activeDataRadio = op.get(this.state.radio, 'live', []);

		// when active channel goes off air, stop the stream
		if (
			activeDataRadio.length > 0 &&
			player[stationId] &&
			Object.keys(player).length == Object.keys(this.radioData).length
		) {
			let activeId = parseInt(stationId[stationId.length - 1]);
			logger.log('[RadioWebview] componentDidUpdate - activeId:%o, player:%o', activeId, player);

			// check if the playing station is live or offair, if offair stop the stream & update the icon to play icon
			if (activeDataRadio[activeId - 1].live == false) {
				player[stationId].player.pause();

				// reset the paused and stationId so when the channel comes back to live again it doesn't show mismatch play icon
				this.state.updateChannelStatus({ stationId: null, otherData: { paused: null } });
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[RadioWebview] componentWillReceiveProps - prev:%o', this.state);
		// logger.log('[RadioWebview] componentWillReceiveProps - next:%o', nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentWillUnmount() {
		if (this.activeDataTimer) {
			clearInterval(this.activeDataTimer);
		}
	}

	getActiveData(prevState) {
		deps.services.RadioWebview.fetch(this.state.activeDataPath)
			.then(result => {
				// logger.log('[RadioWebview] getActiveData - this.state:%o, prevState:%o', this.state, prevState);

				// first time fetch, set radio state
				if (!this.state.radio) {
					this.setState({
						radio: result.radio,
					});
				}

				// only when data change in liveRadio.ssi update state
				if (prevState) {
					if (prevState.radio) {
						// logger.log('[RadioWebview] getActiveData isEqual - isEqual:%o, prevState.radio.live:%o, result.radio.live:%o', isEqual(prevState.radio.live, result.radio.live), prevState.radio.live, result.radio.live);
						if (isEqual(prevState.radio.live, result.radio.live) == false) {
							this.setState({
								radio: result.radio,
							});
						}
					}
				}

				if (!this.activeDataTimer) {
					// logger.log('[RadioWebview] getActiveData - activeDataTimer:%o, this.state:%o, prevState:%o', this.activeDataTimer, this.state, prevState);
					this.activeDataTimer = setInterval(() => {
						this.getActiveData(this.state);
					}, this.state.refreshRate);
				}
			})
			.catch(error => {
				logger.error('[RadioWebview] componentDidUpdate error:%o', error);
				this.activeDataLoaded = false;
			});
	}

	isStub() {
		return this.state.stubs && this.state.stubs.liveradio.stub != 'live';
	}

	onReady(player) {
		logger.log('[RadioWebview] onReady - player:%o', player);

		this.state.savePlayerHandle({
			player,
		});
	}

	togglePlay(stationId, live, idx) {
		logger.log('[RadioWebview] togglePlay - state:%o, stationId:%o, live:%o', this.state, stationId, live);
		let player = op.get(this.props, 'player', {});
		//this.sendMeasure(stationId, player);

		if (live) {
			if (player) {
				// send metrics
				player[stationId].player.amp.playState === 'playing'
					? this.sendMeasure(stationId, 'pause', idx)
					: this.sendMeasure(stationId, 'play', idx);

				if (this.state.stationId !== stationId || !this.state.stationId) {
					this.state.updateChannelStatus({
						stationId: stationId,
						otherData: { paused: false, muted: false },
					});
				} else {
					if (this.state.stationId) {
						if (this.props.paused) {
							this.state.updateChannelStatus({
								stationId: stationId,
								otherData: { paused: false, muted: false },
							});
						} else {
							this.state.updateChannelStatus({ stationId: stationId, otherData: { paused: true } });
						}
					}
				}
			}
		} else {
			logger.log('[RadioWebview] togglePlay - live:%o', live);
		}
	}

	sendMeasure(id, action, idx) {
		//logger.log('[RadioWebview] sendMeasure - id:%o idx:%o playing:%o config:%o', id, idx, action, this.radioData[idx]);

		let metricsData = this.radioData[idx];
		MeasurementUtils.dispatchMeasurementCall('radioPlayer', {
			action: action,
			metricsData,
		});
	}

	keyPress(e, id, isLive, idx) {
		if (e.key === 'Enter' || e.keyCode === 13) {
			this.togglePlay(`station${id}`, isLive, idx);
		}
	}

	getChannelHtml() {
		let channelHtml = [];

		for (var i = 0; i < this.radioData.length; i++) {
			let id = parseInt(i + 1);
			let idx = i;
			let isLive = this.radioData[i].live;
			let iconClass =
				this.state.stationId && this.state.stationId == `station${id}` && !this.props.paused && isLive
					? 'wim-icon-pause'
					: 'wim-icon-videoPlay';

			//logger.log('[RadioWebview] getChannelHtml - radioData:%o, radioConfig:%o', this.radioData, radioConfig);
			//logger.log('[RadioWebview] getChannelHtml - radioData:%o',  this.radioData[i]);

			channelHtml.push(
				<li
					className={`channel ${this.radioData[i].live ? 'onair' : 'offair'}`}
					id={`station${id}`}
					key={`station${i}`}>
					<i
						className={`${iconClass} playbutton`}
						onClick={() => this.togglePlay(`station${id}`, isLive, idx)}
						onKeyDown={e => this.keyPress(e, id, isLive, idx)}
						tabIndex="0"
					/>
					<div className="station">
						<div className="status"></div>
						<div className="name">{this.radioData[i].title}</div>
						<div className="schedule">
							<Time epoch_ms={this.radioData[i].start_time} format="kk:mm z" options="upper tile" /> -{' '}
							{this.endTime}
						</div>
					</div>
				</li>
			);
		}

		return channelHtml;
	}

	render() {
		logger.log('[RadioWebview] render - state:%o', this.state);

		this.radioData = op.get(this.state.radio, 'live', []);
		let radioConfig = {};

		let colors = {
			highlightColor: '#00884E',
			toolbarBackground: 'rgba(73, 77, 95, .8)',
			progressBackground: '#D8D8D8',
		};

		for (var i = 0; i < 3; i++) {
			let id = parseInt(i + 1);
			let mimeType = 'audio/mpeg';
			let url = this.radioData.length > 0 ? this.radioData[i].media[0].url : '';
			//logger.log('[RadioWebview] render - url:%o, is hls:%o', url, url.includes('.m3u8'));
			if (url.includes('.m3u8')) {
				//logger.log('[RadioWebview] render - is hls url:%o', url);
				mimeType = 'application/x-mpegURL';
			}
			radioConfig[i] = {
				id: 'station' + id,
				index: i,
				title: this.radioData.length > 0 ? this.radioData[0].title : '',
				streams: [
					{
						url: this.radioData.length > 0 ? this.radioData[i].media[0].url : '',
						cdn: this.radioData.length > 0 ? this.radioData[i].media[0].cdn : '',
						mimeType,
						streamType: 'live',
					},
				],
				playsinline: true,
				autoplay: false,
			};
		}

		const RadioPlayerProps = {
			playerConfig: this.state.radioPlayerConfig,
			radioConfig,
			colors,
		};

		logger.log('[RadioWebview] render - radioConfig:%o', radioConfig);

		if (this.radioData && this.radioData.length !== 0 && op.get(this.state, 'radioPlayerConfig', false)) {
			return (
				<section id="schedule" className="wrapper webview radioPopup">
					<div className="content-main">
						<div id="radioModule">
							<div className="top">
								<div className="logo" title="Wimbledon" />
								<div className="title">Wimbledon Radio</div>
								<div className="ribbon">
									<a
										href="https://www.ibm.com/wimbledon"
										// popup={true}
										target="_blank"
										role="link">
										<img src="/assets/images/logos/ibm-logo-small.png" alt="ibm logo" />
									</a>
								</div>
							</div>

							<div className="bottom">
								<ul role="list" className="channels">
									{this.getChannelHtml()}
								</ul>
							</div>
						</div>

						<div className="playerWrapper">
							<RadioPlayersWrapper
								playerNumber={3}
								RadioPlayerProps={RadioPlayerProps}
								onToggle={(data, live) => this.togglePlay(data, live)}
								onReady={player => this.onReady(player)}
							/>
						</div>
					</div>
				</section>
			);
		} else {
			return (
				<section id="schedule" className="wrapper webview">
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioWebview);


/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import ToolTip from 'appdir/components/common-ui/ToolTip';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: DividerHeader
 * 
 * currently used in slamtracker head to head section
 * styles found in _scss/commonui/divider-header
 * 
 * props:
 *   text: header text (required)
 *   color:  background color for component - default is light gray (optional)
 *      if background color other than #F0F0F0 is desired, a class needs to
 *      be added to the stylesheet to override the background color
 *   icon: icon to be used next to text (optional)
 *   useTooltip: boolean - use tooltip or not - default is false (optional)
 *   tooltipText: text to be used in the tooltip (optional)
 *   tooltipStyle: classname for tooltip (optional)
 *   onClickCallback: callback function for metrics call, etc (optional)
 * -----------------------------------------------------------------------------
 */
const DividerHeader = props => {
    // logger.log('[DividerHeader] props:%o', props);

    const [tooltip, showToolTip] = useState(false);

    const onClickCallback = () => {

        if(props?.onClickCallback) {
            props.onClickCallback(!tooltip);
        }

        tooltip === true ? showToolTip(false) : showToolTip(true);
    }

	 return (
        <div className={`divider-header ${props.color ? props.color: ''} ${props.useTooltip === true ? 'with-tooltip' : ''}`}>
            {props.text}
            {props.icon ?
                props.useTooltip === true ?
                <i className={`${props.icon} ${props.useTooltip === true ? 'with-tooltip' : ''}`} onClick={() => (onClickCallback())}></i>
                : <i className={props.icon}></i>
            : null
            }
            {tooltip === true ? <ToolTip text={props.tooltipText} style={props.tooltipStyle} /> : null}
        </div>
    )
};

export default DividerHeader;
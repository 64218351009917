import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import op from 'object-path';
import deps from 'dependencies';
import Carousel from 'appdir/components/common-ui/Carousel';
import Button from 'appdir/components/common-ui/Button';
import { Link } from 'react-router-dom';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';
//add in window size
//use conjunction for player win status
//what uses carousel for regression testing
const PlayerStatusWidget = props => {
	const Config = useSelector(state => state['Config']);
	const windowSize = useSelector(state => state['PageHeader']['windowSize']);
	const [state, setState] = useState({
		playerData: null,
		carouselIndex: 0,
	});

	const constructItems = data => {
		let matches = op.get(data, 'matches', 0);
		if (matches.length > 0) {
			return matches.map(d => {
				return { renderItem: renderPlayerFavoriteSlide(d) };
			});
		} else {
			return null;
		}
	};

	const renderDisplayName = (playerInfo, eventType, id) => {
		let playerName = '';
		if (eventType === '') {
			playerName = `${playerInfo[`firstName${id}`]} ${playerInfo[`lastName${id}`]}`;
		} else {
			playerName = `${playerInfo[`displayName${id}`]}`;
		}

		if (windowSize === 'mobile') {
			playerName = `${playerInfo[`firstName${id}`].substring(0, 1)} ${playerInfo[`lastName${id}`].substring(
				0,
				1
			)}`;
		}

		return playerName;
	};

	const renderEventName = data => {
		return windowSize == 'mobile' ? data.shortEventName : data.eventName;
	};

	const renderEitherOrDoublesName = (playerInfo, eventType, id) => {
		let playerName = '';
		if (eventType === '') {
			playerName = `${playerInfo[`firstName${id}`]} ${playerInfo[`lastName${id}`]}`;
		} else {
			playerName = `${playerInfo[`firstName${id}`].substring(0, 1)} ${playerInfo[`lastName${id}`].substring(
				0,
				1
			)}`;
		}

		if (windowSize === 'mobile') {
			playerName = `${playerInfo[`firstName${id}`].substring(0, 1)} ${playerInfo[`lastName${id}`].substring(
				0,
				1
			)}`;
		}

		return playerName;
	};

	const renderMatchStatus = (teamTwoLen, status, day_num, match_id) => {
		let textStatus = null;
		if (status === null && day_num !== null && match_id != '40000') {
			textStatus = 'SCHEDULED';
		} else if (day_num === null && match_id != '40000') {
			if (teamTwoLen > 1) {
				textStatus = 'TBD';
			} else {
				textStatus = 'TO BE SCHEDULED';
			}
		} else {
			textStatus = status.toUpperCase();
		}

		// return status === null && day_num !== null ? 'SCHEDULED' : status.toUpperCase();
		return textStatus;
	};

	const renderMatchBtn = (teamTwoLen, status, match_id, day_num, statusCode) => {
		if (day_num !== null) {
			switch (status) {
				case null:
					return (
						<Button
							className={`solid ${windowSize === 'mobile' ? 'compact' : ''}`}
							to="/en_GB/scores/schedule/index.html">
							Order of Play
						</Button>
					);
					break;
				case 'In Progress':
					if (windowSize === 'mobile ') {
						return (
							<SlamtrackerButton
								match_id={match_id}
								slamtrackerActive={statusCode ? true : false}
								statusCode={statusCode}
							/>
						);
					} else {
						return (
							<SlamtrackerButton
								match_id={match_id}
								slamtrackerActive={statusCode ? true : false}
								statusCode={statusCode}
							/>
						);
					}
					break;
				case 'Completed':
					return (
						<SlamtrackerButton
							match_id={match_id}
							slamtrackerActive={statusCode ? true : false}
							statusCode={statusCode}
						/>
					);
					break;
				default:
					return null;
					break;
			}
		} else {
			if (teamTwoLen > 1 || status == 'See Schedule') {
				return (
					<Button
						className={`solid ${windowSize === 'mobile' ? 'compact' : ''}`}
						to="/en_GB/scores/schedule/index.html">
						Order of Play
					</Button>
				);
				// } else if (status == 'See Schedule') {
				// 	return (
				// 		<Button
				// 			className={`solid ${windowSize === 'mobile' ? 'compact' : ''}`}
				// 			to="/en_GB/scores/schedule/index.html">
				// 			See Schedule
				// 		</Button>
				// 	);
			} else {
				return null;
			}
		}
	};

	const showTime = data => {
		if (data.notBefore !== null) {
			return `NOT BEFORE ${data.notBefore}`;
		} else if (data.startTime !== null && data.order === 1) {
			return `${data.startTime}`;
		} else {
			return null;
		}
	};

	const showComment = data => {
		if (data.comment !== null) {
			return `${data.comment}`;
		} else {
			return null;
		}
	};

	const showMatchSequence = data => {
		let suffix = data.order === 1 ? 'ST' : data.order === 2 ? 'ND' : data.order === 3 ? 'RD' : 'TH';
		if ((data.status === null && data.dayNum !== null) || data.status === 'In Progress') {
			return ` - ${data.order}${suffix} MATCH`;
		} else {
			return null;
		}
	};

	const renderTeamTwo = data => {
		let eventType = data.eventName.toLowerCase().indexOf('double') !== -1 ? ' multiples' : '';
		if (data.team2.length < 1) return <div className={`current-match-player team-two alternate ${eventType}`} />;

		if (data.team2.length > 1) {
			return (
				<div className={`current-match-player team-two alternate ${eventType}`}>
					<div className="player-images">
						<div className="player">
							{data.eventName.toLowerCase().indexOf('double') !== -1 ? (
								<img
									className="player-image "
									alt="player image"
									src={'/assets/images/player-status/player-placeholder.png'}
								/>
							) : (
								<img
									className="player-image "
									alt="player image"
									src={Config.otherData.playerProfileImagePath.replace(
										'<playerid>',
										data.team2[1].idA
									)}
								/>
							)}
							<div className="player-info">
								{data.eventName.toLowerCase().indexOf('double') !== -1 ? (
									<div className="name">
										{data.eventName.toLowerCase().indexOf('double') !== -1
											? `${renderEitherOrDoublesName(
													data.team2[0],
													eventType,
													'A'
											  )}/${renderEitherOrDoublesName(data.team2[0], eventType, 'B')}`
											: renderDisplayName(data.team2[0], eventType, 'A')}
									</div>
								) : (
									<Link to={`/en_GB/players/overview/${data.team2[0].idA}.html`}>
										<div className="name">{renderDisplayName(data.team2[0], eventType, 'A')}</div>
									</Link>
								)}
							</div>
						</div>
						<div className="or-text">or</div>
						<div className="player-two">
							{data.eventName.toLowerCase().indexOf('double') !== -1 ? (
								<img
									className="player-image "
									alt="player image"
									src={'/assets/images/player-status/player-placeholder.png'}
								/>
							) : (
								<img
									className="player-image "
									alt="player image"
									src={Config.otherData.playerProfileImagePath.replace(
										'<playerid>',
										data.team2[1].idA
									)}
								/>
							)}
							<div className="player-info player-two">
								{data.eventName.toLowerCase().indexOf('double') !== -1 ? (
									<div className="name">
										{data.eventName.toLowerCase().indexOf('double') !== -1
											? `${renderEitherOrDoublesName(
													data.team2[1],
													eventType,
													'A'
											  )}/${renderEitherOrDoublesName(data.team2[1], eventType, 'B')}`
											: renderDisplayName(data.team2[1], eventType, 'A')}
									</div>
								) : (
									<Link to={`/en_GB/players/overview/${data.team2[1].idA}.html`}>
										<div className="name">{renderDisplayName(data.team2[1], eventType, 'A')}</div>
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className={`current-match-player team-two`}>
					<div className="player-images">
						<div className="player">
							<img
								className="player-image "
								src={Config.otherData.playerProfileImagePath.replace('<playerid>', data.team2[0].idA)}
							/>
							<div className="player-info">
								<Link to={`/en_GB/players/overview/${data.team2[0].idA}.html`}>
									<div className="name">{renderDisplayName(data.team2[0], eventType, 'A')}</div>
								</Link>
							</div>
						</div>
						{data.team2[0].idB !== null ? (
							<div className="player-two">
								<img
									className="player-image "
									src={Config.otherData.playerProfileImagePath.replace(
										'<playerid>',
										data.team2[0].idB
									)}
								/>
								<div className="player-info player-two">
									<div className="name">
										<Link to={`/en_GB/players/overview/${data.team2[0].idB}.html`}>
											{renderDisplayName(data.team2[0], eventType, 'B')}
										</Link>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			);
		}
	};

	const renderPlayerFavoriteSlide = data => () => {
		logger.log('[PlayerStatusWidget] data:%o, Config:%o', data, Config.status);
		const eitherOrMatch = data.team2.length > 1 ? 'alternate' : '';
		if (data && Config.status == 'loaded') {
			let eventType = data.eventName.toLowerCase().indexOf('double') !== -1 ? ' multiples' : '';
			return (
				<div className={`player-matches-widget${eventType}`}>
					<div className="current-match">
						{data.team1.length > 0 ? (
							<div className={`current-match-player team-one ${eitherOrMatch}`}>
								<div className="player-images">
									<div className="player">
										<img
											className="player-image "
											src={Config.otherData.playerProfileImagePath.replace(
												'<playerid>',
												data.team1[0].idA
											)}
										/>
									</div>
									<div className="player-info">
										<div className="name">
											<Link to={`/en_GB/players/overview/${data.team1[0].idA}.html`}>
												{renderDisplayName(data.team1[0], eventType, 'A')}
											</Link>
										</div>
									</div>
									{data.team1[0].idB !== null ? (
										<div className="player-two">
											<div className="player">
												<img
													className="player-image "
													src={Config.otherData.playerProfileImagePath.replace(
														'<playerid>',
														data.team1[0].idB
													)}
												/>
												<div className="player-info player-two">
													<div className="name">
														<Link to={`/en_GB/players/overview/${data.team1[0].idB}.html`}>
															{renderDisplayName(data.team1[0], eventType, 'B')}
														</Link>
													</div>
												</div>
											</div>
										</div>
									) : null}
								</div>
							</div>
						) : (
							<div className={`current-match-player team-one`} />
						)}
						<div className="current-match-info" style={{ color: 'black' }}>
							<div className="match match-type">{renderEventName(data)}</div>
							<div className="match match-round">{data.roundName}</div>
							<div className="match match-location">
								{data.courtName}
								{showMatchSequence({ ...data })}
							</div>
							<div className="match match-location">{showTime(data)}</div>
							<div className="match match-location">{showComment(data)}</div>
							<div className="match match-status">
								{renderMatchStatus(data.team2.length, data.status, data.dayNum, data.match_id)}
							</div>
							<div className="match match-day">
								{data.status !== 'In Progress' ? data.dayMessage : null}
							</div>
							<hr />
							{data.conjunction && (
								<div className="match match-player-status">
									{data.status === null ? '' : data.conjunction.toUpperCase()}
								</div>
							)}
							<div className="match match-scores">{data.shortScore}</div>
							<div className="match-btn">
								{renderMatchBtn(
									data.team2.length,
									data.status,
									data.match_id,
									data.dayNum,
									data.statusCode
								)}
							</div>
						</div>
						{renderTeamTwo(data)}
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	const renderMobileBullets = (clickFn, slideFn, state) => {
		let imagesBullets = state.items.map((d, i) => i);
		return (
			<React.Fragment>
				<div
					onClick={() => {
						clickFn('left');
					}}
					className={`arrow-wrapper-left mobile`}>
					<i className={`wim-icon-left-arrow`} />
				</div>
				{imagesBullets.map((d, i) => {
					return (
						<div
							key={i}
							className={`nav-dot ${i == state.currIndex ? 'selected' : ''}`}
							onClick={() => {
								slideFn(i);
							}}>
							●
						</div>
					);
				})}
				<div
					onClick={() => {
						clickFn('right');
					}}
					className={`arrow-wrapper-right mobile`}>
					<i className={`wim-icon-right-arrow`} />
				</div>
			</React.Fragment>
		);
	};

	const renderStubMessage = () => {
		return (
			<div className="player-matches-widget-container unavailable">
				<h1>Welcome to The Championships Your personal Order of Play will appear here when it is released.</h1>
				<hr />
			</div>
		);
	};

	const renderCarousel = data => {
		return (
			<>
				<div className={`player-matches-widget-container${data.matches.length === 1 ? ' no-nav' : ''}`}>
					<Carousel
						key={'player-matches'}
						attributes={{
							items: constructItems(data),
							showNav: true,
							showFullscreenButton: false,
							showThumbnails: false,
							showPlayButton: false,
							customNav: false,
							customBullets: true,
							customBulletsHTML: renderMobileBullets,
						}}
					/>
				</div>
				{op.get(props, 'data.showDivider', false) && <hr className="bottom-divide" />}
			</>
		);
	};

	useEffect(() => {
		// console.log({ props }, 'The props for the effect');
		if (state.playerData == null && Config.status === 'loaded') {
			let url = Config.homepage.favorites.replace('<playerId>', props.playerId);
			deps.services.PlayerStatusWidget.fetch(url).then(resp => {
				setState({ ...state, playerData: resp });
			});
		}
	}, [Config.status]);

	return (
		<React.Fragment>
			{state.playerData !== null
				? state.playerData.matches
					? state.playerData.matches.length > 0
						? renderCarousel({ ...state.playerData })
						: renderStubMessage()
					: renderStubMessage()
				: renderStubMessage()}
		</React.Fragment>
	);
};
export default PlayerStatusWidget;

import { values } from 'appdir/main';

export const MatchInsightsInfoText = {
	header: {
		title: values.matchInsightsTitle,
		body:
			`${values.matchInsightsTitle} are AI-generated previews that help fans get up to speed on every singles match. Combining the power of hybrid cloud and AI, Match Insights analyse data from thousands of sources -- from recent statistics to expert commentary -- producing projections of each player\'s Likelihood to Win, an explanation of their Win Factors, and a measurement of player momentum called the ${values.powerIndexTitle}. Match Insights also include relevant quotes from the media and a natural language summary of key performance metrics.`,
	},
	'power-ranking': {
		title: values.powerIndexTitle,
		body:
			`The ${values.powerIndexTitle} is an AI-powered analysis of player performance. They combine advanced statistical analysis, the natural language processing of IBM Watson, and the power of the IBM Cloud to measure player momentum, analyze the most recent match data and media commentary, and direct the attention of fans to the most compelling matchups.`,
	},
};

import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const TermsOfService = data => {
	//logger.log('[Gigya] [TermsOfService] data:%o', data);

	let version = '';
	let approved = '';

	if (data && data.granted) {
		version = moment(data.docDate)
			.tz('Europe/London')
			.format('DD/MM/YYYY')
			.toString();
		approved = moment(data.modifiedDate)
			.format('ll')
			.toString();
	}

	return (
		<span>
			<div
				className="gigya-container gigya-visible-when"
				data-condition="true || !preferences || !preferences.terms || !preferences.terms.TermsOfService || !preferences.terms.TermsOfService.isConsentGranted"
				data-on-render="false">
				<div className="gigya-composite-control gigya-composite-control-checkbox">
					<input
						type="checkbox"
						id="gigya-checkbox-terms"
						name="preferences.terms.TermsOfService.isConsentGranted"
						className="gigya-input-checkbox"
						data-display-name=""
						tabIndex={data.tabIndex ? data.tabIndex : '0'}
						disabled={data && data.granted}
					/>
					<label className="gigya-label" data-binding="true" htmlFor="gigya-checkbox-terms">
						<label
							className="gigya-required-display"
							data-bound-to="preferences.terms.TermsOfService.isConsentGranted"
							style={{}}
							data-binding="true"
							htmlFor="gigya-checkbox-terms">
							*
						</label>
						<span
							className="gigya-label-text gigya-checkbox-text"
							data-translation-key="CHECKBOX_TERMS_LABEL"
						/>

						{version != '' && approved != '' ? (
							<span className="gigya-consent-dates">
								<div>
									Version: {version} - Approved on {approved}
								</div>
							</span>
						) : (
							''
						)}
					</label>
				</div>
			</div>
		</span>
	);
};

export default TermsOfService;

/*

<div className="gigya-composite-control gigya-composite-control-consent-widget">
				<div
					className="gigya-consent"
					name="preferences.terms.TermsOfService"
					data-array-root="preferences.terms"
					data-label="screenset.translations['CONSENTWIDGET_93546374230298220_DATA_LABEL']"
				/>
			</div>

			*/

import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[ScoreboardTestPage] action.mount - data:%o', data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				completed_path: Config.scoringData.completedMatches,
				jsonServer: Config.scoring.jsonServer,
				status: 'mount',
			};
			dispatch({ type: deps.actionTypes.SCOREBOARDDEMO_MOUNT, data: data });
		});
	},

	unmount: () => (dispatch, getState, store) => {
		//dispatch({type: deps.actionTypes.SEARCHPAGE_UNMOUNT, data: data});
	},
};

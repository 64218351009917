import React from 'react';

const ForgotPasswordSuccessLTA = ({ data }) => {
	//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
	let classname = 'gigya-screen v2 portrait';

	return (
		<div
			id="gigya-forgot-password-success-screen-lta"
			data-width="auto"
			className={classname}
			data-caption="screenset.translations['GIGYA_FORGOT_PASSWORD_SUCCESS_SCREEN_CAPTION']">
			<div className="gigya-layout-row">
				<label
					className="gigya-composite-control gigya-composite-control-label gigya-message"
					data-translation-key="LABEL_MIGRATE_LTA"
				/>
			</div>
			<div className="gigya-layout-row">
				<a
					tabIndex="-1"
					className="gigya-composite-control gigya-composite-control-link gigya-button-link"
					data-switch-screen="gigya-login-screen"
					style={{ textAlign: 'center' }}
					data-translation-key="LINK_BUTTON_LOGIN_LABEL"
				/>
			</div>
		</div>
	);
};

export default ForgotPasswordSuccessLTA;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import shuffle from 'lodash/shuffle';

import { getDrawPath } from 'appdir/components/data/Tournament/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: Tournament
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Tournament'],
		commonData: state['CommonData'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	// checkExpired: (dataConfig) => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	getResults: day => dispatch(deps.actions.Tournament.getResults({ day: day })),
	getSchedule: day => dispatch(deps.actions.Tournament.getSchedule({ day: day })),
	getScheduleScores: day => dispatch(deps.actions.Tournament.getScheduleScores({ day: day })),
	getDraws: event => dispatch(deps.actions.Tournament.getDraws({ event: event })),
	getDrawPath: playerId => dispatch(deps.actions.Tournament.getDrawPath({ playerId: playerId })),
	getMatchInsightsAvailable: () => dispatch(deps.actions.Tournament.getMatchInsightsAvailable()),
	getMatchInsightsMatchup: match => dispatch(deps.actions.Tournament.getMatchInsightsMatchup({ matchId: match })),
	getPowerIndex: eventId => dispatch(deps.actions.Tournament.getPowerIndex({ eventId: eventId })),
	getPowerIndexTrends: () => dispatch(deps.actions.Tournament.getPowerIndexTrends()),
	getPowerIndexMatchup: match => dispatch(deps.actions.Tournament.getPowerIndexMatchup({ matchId: match })),
	getPlayerStatus: playerId => dispatch(deps.actions.Tournament.getPlayerStatus({ playerId: playerId })),
	getPlayerMatches: playerId => dispatch(deps.actions.Tournament.getPlayerMatches({ playerId: playerId })),
	getPlayerLookup: () => dispatch(deps.actions.Tournament.getPlayerLookup()),
	clearTournament: () => dispatch(deps.actions.Tournament.clearAll()),
	getDrawAnalysis: (event, playerId) =>
		dispatch(deps.actions.Tournament.getDrawAnalysis({ eventId: event, playerId: playerId })),
	getPlayerList: () => dispatch(deps.actions.Tournament.getPlayerList()),
	getPlayerDetail: playerId => dispatch(deps.actions.Tournament.getPlayerDetail({ playerId: playerId })),
	getExtraStats: (event, playerIds) => dispatch(deps.actions.Tournament.getExtraStats({ event: event, playerIds: playerIds })),
	getPlayerGen: (playerIds) => dispatch(deps.actions.Tournament.getPlayerGen({playerIds: playerIds})),
	getDaySummaryGen: () => dispatch(deps.actions.Tournament.getDaySummaryGen()),
	getPlayerStats: (ids) => dispatch(deps.actions.Tournament.getPlayerStats({ playerIds: ids })),
});

class Tournament extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };

		this.type = '';
		logger.log('[Tournament Test] constructor - state:%o', this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = null;

		//logger.log('[Tournament Test] getDerivedStateFromProps - nextProps:%o', nextProps);

		newState = {
			...prevState,
			...nextProps,
			//status: nextProps.status,
		};

		return newState;
	}

	componentDidMount() {}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[Tournament Test] componentDidUpdate - state.data:%o common:%o',
			this.state.data,
			this.state.commonData
		);
	}

	clickResults(e, day) {
		e.preventDefault();
		this.type = 'results';
		this.props.getResults(day);
		return false;
	}

	clickSchedule(e, day) {
		e.preventDefault();
		this.type = 'schedule';
		this.props.getSchedule(day);
		return false;
	}

	clickScheduleScores(e, day) {
		e.preventDefault();
		this.type = 'scheduleScores';
		this.props.getScheduleScores(day);
		return false;
	}

	clickDraw(e, event) {
		e.preventDefault();
		this.type = 'draws';
		this.props.getDraws(event);
		return false;
	}

	getMatchTable() {
		let matches = this.state?.data?.[this.type]?.matches || [];

		//adjust for schedule data
		if (this.type == 'schedule') {
			let schedMatches = [];
			matches = this.state?.data?.schedule?.data?.courts || [];
			matches.forEach(court => {
				court.matches.forEach(match => {
					schedMatches.push(match);
				});
			});
			matches = schedMatches;
		}

		if (this.type == 'draws') {
			//find subsequent matches
			//if (firstMatchId) {
			// matchNum = parseInt(firstMatchId.substr(firstMatchId.length-2, 2));
			// logger.log('[Tournament Test] drawPath - match:%o matchNum', firstMatchId, matchNum);
			//}
		}

		return (
			<table className="tournament-table">
				<thead>
					<tr>
						<td>Match Id</td>
						<td>Day</td>
						<td>Court</td>
						<td>Event</td>
						<td>Status</td>
						<td>Players</td>
						<td>Score</td>
					</tr>
				</thead>
				<tbody>
					{matches.map(elem => {
						//logger.log('[Tournament Test] getMatchTable - elem:%o', elem);
						return (
							<tr key={`match_${elem.match_id}`}>
								<td>{elem.match_id}</td>
								<td>{elem.eventDay}</td>
								<td>{elem.courtName}</td>
								<td>{elem.eventName}</td>
								<td>{elem.status}</td>
								<td>{elem.vsNames}</td>
								<td>{elem.shortScore}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	render() {
		//logger.log('[Tournament Test] render - props:%o', this.props);
		//logger.log('[Tournament Test] render - state:%o', this.state);

		return (
			<div className="content-main content ">
				<div className="column-layout content">
					<div className="four-col">
						Tournament Data Test Page -{' '}
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.clearTournament();
								return false;
							}}>
							Clear Data
						</a>
					</div>
					<div className="one-col">
						<a href="" onClick={e => this.clickResults(e, 1)}>
							Results Day 1
						</a>
						<br />
						<a href="" onClick={e => this.clickResults(e, 2)}>
							Results Day 2
						</a>
						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerList();
								return false;
							}}>
							Player List
						</a>
						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerDetail('atpfb98');
								return false;
							}}>
							Player Detail - Fritz
						</a>
						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getExtraStats('ms',['atpi186','atps0ag']);
								return false;
							}}>
							Extra Stats - MS - Isner,Sinner
						</a>
						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getExtraStats('ls');
								return false;
							}}>
							Extra Stats - LS 
						</a>
						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerStats(['atpi186','atps0ag']);
								return false;
							}}>
							Player Stats - Isner,Sinner
						</a>
					</div>
					<div className="one-col">
						<a href="" onClick={e => this.clickSchedule(e, 1)}>
							Schedule Day 1
						</a>
						<br />
						<a href="" onClick={e => this.clickSchedule(e, 11)}>
							Schedule Day 11 (Tourn Day 4)
						</a>
						<br />
						<a href="" onClick={e => this.clickScheduleScores(e, 10)}>
							Schedule Day 10 (Tourn Day 5) w/Scores
						</a>
					</div>
					<div className="one-col">
						<a href="" onClick={e => this.clickDraw(e, 'MS')}>
							Draw - Gentlemen's Singles
						</a>
						<br />
						<a href="" onClick={e => this.clickDraw(e, 'LS')}>
							Draw- Ladies' Singles
						</a>
						<br />
						<a href="" onClick={e => this.clickDraw(e, 'MD')}>
							Draw - Gentlemen's Doubles
						</a>
						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawPath('atpd643');
								return false;
							}}>
							Draw Path - Djokovic
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawPath('atpi186');
								return false;
							}}>
							Draw Path - Isner
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawPath('atpv942');
								return false;
							}}>
							Draw Path - Rijthoven
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawPath('atpr548');
								return false;
							}}>
							Draw Path - Ram
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawPath('wta315391');
								return false;
							}}>
							Draw Path - Garcia
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawPath('wta326408');
								return false;
							}}>
							Draw Path - Swiatek
						</a>

						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawAnalysis('MS', 'atpd643');
								return false;
							}}>
							Draw Analysis - MS - Djokovic
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawAnalysis('LS', 'wta318203');
								return false;
							}}>
							Draw Analysis - LS - wta318203
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDrawAnalysis('LS', 'wta316847');
								return false;
							}}>
							Draw Analysis - LS - wta316847
						</a>
					</div>
					<div className="one-col">
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getMatchInsightsAvailable();
								return false;
							}}>
							Match Insights - Available
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getMatchInsights('1101');
								return false;
							}}>
							Match Insights - 1101
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPowerIndex('MS');
								return false;
							}}>
							Power Index - MS
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPowerIndex('LS');
								return false;
							}}>
							Power Index - LS
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPowerIndexTrends();
								return false;
							}}>
							Power Index Trends
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPowerIndexMatchup('1101');
								return false;
							}}>
							Power Index Matchup - 1101
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerStatus('atpd643');
								return false;
							}}>
							Player Status - Djokovic
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerStatus('wta326408');
								return false;
							}}>
							Player Status - Swiatek
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerMatches('atpd643');
								return false;
							}}>
							Player Matches - Djokovoc
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerLookup();
								return false;
							}}>
							Player Lookup List
						</a>
						<br />
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerGen();
								return false;
							}}>
							Player Gen Content
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getPlayerGen(['wta318516', 'wta312001', 'wta312121', 'wta320760', 'wta322191']);
								return false;
							}}>
							Player Gen Content w/player ids
						</a>
						<br />
						<a
							href=""
							onClick={e => {
								e.preventDefault();
								this.props.getDaySummaryGen();
								return false;
							}}>
							Day Summary Gen
						</a>
						<br />
					</div>
				</div>

				{this.getMatchTable()}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);

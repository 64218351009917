import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let path = Config.scoringData.playersStats.replace('<id>', data);
			dispatch({ type: deps.actionTypes.EVENTSTATS_MOUNT, data: path });
		});
	},
};

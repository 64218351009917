import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.HEADTOHEAD_MOUNT:
			newState = {
				...state,
				headToHeadConfigData: action.data,
				status: 'load',
			};
			logger.info('[HeadToHead] (deps.actionTypes.HEADTOHEAD_MOUNT - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';

import PlayerMatchSummary from './PlayerMatchSummary';
import PlayerRounds from './PlayerRounds';
import DrawsLists from './DrawsLists';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['PlayerProfile'], props);
};

const mapDispatchToProps = (dispatch, props) => ({});

class Tabs extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props, selected: -1 };
	}

	handleClick(index, event) {
		event.preventDefault();
		this.setState({
			selected: index,
		});
		this.renderContent();
	}

	handleChange() {
		//console.log('this is changing: %o', this.state.selected);
	}

	renderContent() {
		let selectedIndex = this.state.selected;

		logger.log('[PlayerProfilePanes] renderActiveTabs: %o', this.state);

		if (selectedIndex === -1) {
			return (
				<div className="four-col" id="tabSummary" key="-1">
					<PlayerMatchSummary data={{ ...this.state.data.match_stats }} />
					<br />
					<PlayerRounds data={{ ...this.state.data.highest_rounds }} />
				</div>
			);
		} else {
			return (
				<div key={selectedIndex} className="four-col">
					<DrawsLists
						attributes={{
							data: this.state.data.events_archive[selectedIndex].archive,
							flagImagePathSmall: this.state.data.flagImagePathSmall,
							playerProfileImagePath: this.state.data.playerProfileImagePath,
						}}
					/>
				</div>
			);
		}
	}

	render() {
		let summarySelected = -1;
		let tabsInfo = this.state.data.events_archive;
		let activeClass = this.state.selected === summarySelected ? 'current' : 'display';

		logger.log('[PlayerTabs] render: %o', this.state);

		return (
			<div className="playerMatches">
				<ul className="tabs" role="list">
					<li className={activeClass} key={summarySelected}>
						<a href="#" onClick={this.handleClick.bind(this, summarySelected)}>
							Summary
						</a>
					</li>
					{Object.keys(tabsInfo).map((item, i) => {
						activeClass = this.state.selected === item ? 'current' : 'display';

						return (
							<li key={i} className={activeClass}>
								<a href="#" onClick={this.handleClick.bind(this, item)}>
									{tabsInfo[i].eventName}
								</a>
							</li>
						);
					})}
				</ul>
				{this.renderContent()}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.PATHTOTHEFINAL_MOUNT:
			logger.log('[PathToTheFinal] reducers - PATHTOTHEFINAL_MOUNT action:%o', action);

			newState = {
				...state,
				...action.data,
			};
			logger.log('[PathToTheFinal] reducersPATHTOTHEFINAL_MOUNT - newState:%o', newState);
			return newState;
		case deps.actionTypes.PATHTOTHEFINAL_UNMOUNT:
			logger.log('[PathToTheFinal] reducers - PATHTOTHEFINAL_UNMOUNT action:%o', action);

			newState = {
				...state,
				...action.data,
			};
			logger.log('[PathToTheFinal] reducers PATHTOTHEFINAL_UNMOUNT - newState:%o', newState);
			return newState;
		
		case deps.actionTypes.PATHTOTHEFINAL_UPDATE:
			newState = {
				...state,
				...action.data,
			};

			logger.log('[PathToTheFinal] PATHTOTHEFINAL_UPDATE - newState:%o', newState);
			return newState;
		
		case deps.actionTypes.PATHTOTHEFINAL_CLOSE:
			newState = {
				...state,
				...action.data,
				display: false
			};

			logger.log('[PathToTheFinal] PATHTOTHEFINAL_CLOSE - newState:%o', newState);
			return newState;

		case deps.actionTypes.PATHTOTHEFINAL_OPEN:
			newState = {
				...state,
				...action.data,
			};

			logger.log('[PathToTheFinal] PATHTOTHEFINAL_OPEN - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};

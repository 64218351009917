import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	switch (action.type) {
		case deps.actionTypes.DRAWSARCHIVE_EVENTS_MOUNT:
			//newState = Object.assign({}, state);
			//return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_EVENTS_LOAD:
			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_EVENTS_LOAD - data:%o', action.data);
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_EVENTS_ALL_EVENTS_LOAD:
			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_EVENTS_ALL_EVENTS_LOAD - data:%o', action.data);
			newState = {
				...state,
				eventsAll: action.data,
			};
			return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_EVENTS_FILTER:
			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_EVENTS_FILTER - data:%o', action.data);

			let filters = {
				...state.filters,
				...action.data,
			};

			let drawsAllByEvent = state.drawsAllByEvent;

			if (action.data.eventId) {
				drawsAllByEvent = '';
			}

			newState = {
				...state,
				filters: filters,
				status: 'change',
				drawsAllByEvent: drawsAllByEvent,
			};

			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_EVENTS_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWSARCHIVE_EVENTS_SET_EVENT:
			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_EVENTS_SET_EVENT - data:%o', action.data);

			newState = {
				...state,
				drawsAllByEvent: action.data,
			};
			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_EVENTS_SET_EVENT - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWSARCHIVE_UNMOUNT:
			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_UNMOUNT - data:%o', action.data);

			newState = {
				year: '',
				host: '',
				eventsAll: '',
				drawsAllByEvent: '',
				filters: {
					eventId: 'MS',
					open: '',
				},
			};
			logger.log('[DrawsArchiveEvents] DRAWSARCHIVE_UNMOUNT - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};

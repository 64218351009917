/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { values } from 'appdir/main';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: Power Index Find More content for Match Insights
 *
 *  props = {
 *             type, ---- if 'slamtracker' is passed, display shorter discription
 *           }
 * -----------------------------------------------------------------------------
 */
const MIFooter = props => {
	return (
		<div className="mi-section center no-bottom-border mi-footer">
			{props.type && props.type == 'slamtracker' ? (
				<p>
					Click here to{' '}
					<WimLink to={'/en_GB/matchinsights/index.html'} external={false}>
						read more {values.matchInsightsTitle}
					</WimLink>
					.
				</p>
			) : (
				<p>
					Did you enjoy this article? If so, click here to{' '}
					<WimLink to={'/en_GB/matchinsights/index.html'} external={false}>
						read more {values.matchInsightsTitle}
					</WimLink>
					.
				</p>
			)}
		</div>
	);
};

export default MIFooter;

export const registrationLogin = {
	GIGYA_LOGIN_SCREEN_CAPTION: 'Login',
	GIGYA_REGISTER_SCREEN_CAPTION: 'Registration',
	GIGYA_FORGOT_PASSWORD_SCREEN_CAPTION: 'Forgot Password',
	GIGYA_FORGOT_PASSWORD_SUCCESS_SCREEN_CAPTION: 'Forgot Password',
	GIGYA_RESET_PASSWORD_SCREEN_CAPTION: 'Reset Password',
	GIGYA_RESET_PASSWORD_SUCCESS_SCREEN_CAPTION: 'Reset Password',
	GIGYA_REGISTRATION_SUCCESS_SCREEN_CAPTION: 'Registration',

	HEADER_LOGIN_SOCIAL_LABEL: 'Log In For MyWimbledon With Social',
	HEADER_LOGIN_PRIM_LABEL: 'Log In For MyWimbledon',
	HEADER_COMPLETE_REG_LABEL: 'Complete Registration',
	HEADER_COMMUNITY_REG_LABEL: 'Complete Community Registration',
	HEADER_REGISTER_SOCIAL_LABEL: 'Join MyWimbledon With Social',
	HEADER_REGISTER_LABEL: 'Join MyWimbledon',
	TEXTBOX_EMAIL: 'Email',
	TEXTBOX_PASSWORD: 'Password',
	TEXTBOX_FIRST_NAME: 'First Name',
	TEXTBOX_LAST_NAME: 'Last Name',
	TEXTBOX_PASSWORD: 'Password',
	TEXTBOX_PASSWORD_CONFIRM: 'Confirm Password',
	TEXTBOX_PASSWORD_NEW_LABEL: 'New Password',
	TEXTBOX_PASSWORD_NEWCONFIRM_LABEL: 'Confirm New Password',
	TEXTBOX_POSTCODE_LABEL: 'Postcode',
	TEXTBOX_ADDRESS: 'Address',
	CHECKBOX_REMEMBER_LABEL: 'Keep me logged-in',
	SUBS_NEWSLETTER_LABEL:
		'By joining myWimbledon you are confirming you are happy to receive news and information from The All England Lawn Tennis Club regarding The Club, The Championships and The Grounds via email',
	CHECKBOX_PRIVACY_LABEL:
		'I have read and understood the <a class="gigya-terms-of-use inline-link" target="_blank" href="/en_GB/about_wimbledon/privacy_policy.html">Privacy Policy</a>',
	CHECKBOX_TERMS_LABEL:
		'I have read and understood the <a class="gigya-terms-of-use inline-link" target="_blank" href="/en_GB/about_wimbledon/acceptable_use_policy.html">Terms of Use</a>',
	CHECKBOX_NEIGHBORS_LABEL:
		'In ticking this box I agree to be part of the AELTC Neighbours and Community scheme and receive information from the AELTC on tickets and benefits, news and updates, delivered via email and post regarding the Club, the grounds and The Championships. Terms and Conditions​',

	BUTTON_SUBMIT_VALUE: 'SUBMIT',
	BUTTON_LOGIN_VALUE: 'LOGIN',
	BUTTON_CREATEACCOUNT_LABEL: 'JOIN',
	BUTTON_VERIFY_VALUE: 'VERIFY',

	LINK_FORGOTPASSWORD_LABEL: 'Forgot password?',
	LINK_HAVE_ACCOUNT_LABEL: 'I already have an account',
	LINK_LOGIN_LABEL: 'Back to Login',
	LINK_BUTTON_LOGIN_LABEL: '<input tabndex="0" type="button" value="BACK TO LOGIN">',
	LABEL_FORGOT_PASSWORD: 'Please enter your email address to update your password:',
	LABEL_FORGOT_EMAIL: 'We have sent details on how to update your password to the email address you provided.',
	LABEL_MIGRATE_LTA: 'Thank you for adding your email address. You will shortly be sent a password set-up email.',
	LABEL_OR: 'or',
	LABEL_PASSWORD_CHANGE_LABEL: 'Please change your password:',
	LABEL_PASSWORD_NEW_LABEL: 'Please enter a new password:',
	LINK_BUTTON_PROFILE_LABEL: '<input tabindex="0" type="button" value="myWIMBLEDON PROFILE">',
	LINK_BUTTON_ACTIVATE_PROFILE_LABEL: '<input tabindex="0" type="button" value="ACTIVATE YOUR COMMUNITY PROFILE">',
	LABEL_RESET_SUCCESS_LABEL:
		'Your password has been updated successfully.<br><br>Visit your myWimbledon profile to manage your preferences, set your favourite players and access key services including your tickets.',
	LABEL_REGISTRATION_SUCCESS:
		'Your account has been created / updated.<br><br>Visit your myWimbledon profile to see your account details and manage your preferences.',
	LABEL_REGISTRATION_SUCCESS_POSTCODE:
		'Your account has been created / updated.<br><br>Visit your myWimbledon profile to see your account details and manage your preferences. <br><br><strong>You have been identified as living within the catchment area of our AELTC community.</strong> <br><br>To provide you with updates, news and benefits as part of the Community, please provide us with your address so we can confirm the AELTC benefits you are eligible for.',

	LABEL_RESET_PASSWORD_FAIL:
		'There was an error updating your password.<br><br>Please click "Forgot password?" from a login screen to try again.',

	DROPDOWN_COUNTRY_LABEL: 'Country:',

	DROPDOWN_TITLE_LABEL: 'Title',
	DROPDOWN_TITLE_CHOICE_0: '--Select Title--',
	DROPDOWN_TITLE_CHOICE_1: 'Mr',
	DROPDOWN_TITLE_CHOICE_2: 'Mrs',

	DROPDOWN_BIRTH_LABEL: 'Date Of Birth',
	DROPDOWN_BIRTH_DAY_LABEL: 'Birth Day',
	DROPDOWN_BIRTH_YEAR_LABEL: 'Birth Year',
	DROPDOWN_BIRTH_MONTH_LABEL: 'Birth Month',

	DROPDOWN_90567281827265380_CHOICES_: '',
	DROPDOWN_90567281827265380_CHOICES_1679091C5A880FAF6FB5E6087EB1B2DC: 'June',
	DROPDOWN_90567281827265380_CHOICES_45C48CCE2E2D7FBDEA1AFC51C7C6AD26: 'September',
	DROPDOWN_90567281827265380_CHOICES_6512BD43D9CAA6E02C990B0A82652DCA: 'November',
	DROPDOWN_90567281827265380_CHOICES_8F14E45FCEEA167A5A36DEDD4BEA2543: 'July',
	DROPDOWN_90567281827265380_CHOICES_A87FF679A2F3E71D9181A67B7542122C: 'April',
	DROPDOWN_90567281827265380_CHOICES_C20AD4D76FE97759AA27A0C99BFF6710: 'December',
	DROPDOWN_90567281827265380_CHOICES_C4CA4238A0B923820DCC509A6F75849B: 'January',
	DROPDOWN_90567281827265380_CHOICES_C81E728D9D4C2F636F067F89CC14862C: 'February',
	DROPDOWN_90567281827265380_CHOICES_C9F0F895FB98AB9159F51FD0297E236D: 'August',
	DROPDOWN_90567281827265380_CHOICES_D3D9446802A44259755D38E6D163E820: 'October',
	DROPDOWN_90567281827265380_CHOICES_E4DA3B7FBBCE2345D7772B0674A318D5: 'May',
	DROPDOWN_90567281827265380_CHOICES_ECCBC87E4B5CE2FE28308FD9F2A7BAF3: 'March',

	LABEL_82751524717670350_LABEL: 'We still need a few more details:',
	GIGYA_COMPLETE_REGISTRATION_SCREEN_CAPTION: 'Profile Completion',
	SUBSCRIPTIONWIDGET_48538574007401360_DATA_LABEL: 'Subscribe to the WImbledon newsletter',
	TEXTBOX_43478426937750630_LABEL: 'Email:',

	LOGINID_1762354922073180_LABEL: 'Email:',

	LINK_122104312720648740_LABEL: '<input tabindex="0" type="button" value="Back to Login">',
	PASSWORD_101426512808638880_LABEL: 'Current password:',
	GIGYA_PASSWORD_CHANGE_REQUIRED_SCREEN_CAPTION: 'Password Change',

	LABEL_CODE_VERIFICATION_LABEL: '',
	LABEL_RESEND_CODE_LABEL: '',

	GIGYA_VERIFICATION_PENDING_SCREEN_CAPTION: 'Email Verification',
	LABEL_LINK_VERIFICATION_PENDING_LABEL: '',
	LABEL_LINK_ACCOUNT_LABEL: '<a data-switch-screen="gigya-login-screen">Log in with a different account</a>',
	TEXTBOX_53553186815043370_LABEL: 'Email:',

	GIGYA_VERIFICATION_SENT_SCREEN_CAPTION: 'Email Verification',
	LABEL_EMAIL_VERIFICATION_SENT_LABEL:
		'A verification email with a link to verify your account has been sent to you.',
	LINK_VERIFICATION_SENT_LABEL: '<input type="button" class="gigya-button" value="OK" tabindex="0">',

	GIGYA_TFA_REGISTRATION_SCREEN_CAPTION: 'Securing Your Account',
	GIGYA_TFA_VERIFICATION_SCREEN_CAPTION: 'Verifying Your Account',

	CHECKBOX_78285840404140770_LABEL:
		'I have read and understood the <a class="gigya-terms-of-use" href="#">Terms of Use</a>',
	HEADER_51697213698355980_LABEL: 'Provide a password:',
	PASSWORD_107891164873405600_PLACEHOLDER: 'Password',
	PASSWORD_76732333762278900_PLACEHOLDER: 'Confirm password',
	GIGYA_LITE_ACCOUNT_PROGRESSION_SCREEN_CAPTION: 'Complete Your Account',
	LOGIN_IDENTIFIER_EXISTS: '',
	UNIQUE_IDENTIFIER_EXISTS: '',
	ACCOUNT_TEMPORARILY_LOCKED_OUT: '',
	WRONG_PASSWORD: '',
	OLD_PASSWORD_CANNOT_BE_THE_SAME_AS_NEW_PASSWORD: '',
	OLD_PASSWORD_USED: '',
	CHOOSE_FILE: '',
	NO_FILE_CHOSEN: '',
	'MAXIMUM_SIZE_OF_3MB.': '',
	THERE_ARE_ERRORS_IN_YOUR_FORM_PLEASE_TRY_AGAIN: '',
	SORRY_WE_ARE_NOT_ABLE_TO_PROCESS_YOUR_REGISTRATION: '',
	INVALID_LOGIN_OR_PASSWORD: '',
	ACCOUNT_IS_DISABLED: '',
	EMAIL_ALREADY_EXISTS: '',
	YOUR_AGE_DOES_NOT_MEET_THE_MINIMAL_AGE_REQUIREMENT: '',
	THERE_IS_NO_USER_WITH_THAT_USERNAME_OR_EMAIL: '',
	PASSWORD_MUST_CONTAIN_AT_LEAST: '',
	AND: '',
	NUM_OF_THE_FOLLOWING_GROUPS: '',
	NUM_CHARACTERS_TOTAL: '',
	TOO_WEAK: '',
	CHECKING: '',
	EMAIL_ADDRESS_IS_INVALID: '',
	PASSWORD_DOES_NOT_MEET_COMPLEXITY_REQUIREMENTS: '',
	"THE_CHARACTERS_YOU_ENTERED_DIDN'T_MATCH_THE_WORD_VERIFICATION._PLEASE_TRY_AGAIN": '',
	INVALID_USERNAME_OR_PASSWORD: '',
	USERNAME_ALREADY_EXISTS: '',
	PASSWORDS_DO_NOT_MATCH: '',
	PLEASE_ENTER_FIELDNAME: '',
	THESE_PASSWORDS_DO_NOT_MATCH: '',
	THIS_FIELD_IS_REQUIRED: '',
	INVALID_FIELDNAME: '',
	VERY_STRONG: '',
	STRONG: '',
	FAIR: '',
	WEAK: '',
	PASSWORD_STRENGTH_COLON: '',
	NOT_AVAILABLE: '',
	AVAILABLE: '',
	NETWORK_ERROR: '',
	PLEASE_ENTER_A_VALID_FIELDNAME: '',
	PROFILEPHOTO_FILESIZEERROR: '',
	SUBSCRIPTION_PENDING_CONFIRMATION: '',
	LOGIN_CAPTCHA_ERROR: '',
	REGISTER_CAPTCHA_ERROR: '',
	INVALID_PASSWORD_RESET_TOKEN: '',
	"DIDN'T_GET_THE_CODE_QM": '',
	EDIT: '',
	PLEASE_CHOOSE_A_NUMBER_TO_EDIT_COLON: '',
	SELECT_YOUR_COUNTRY_COLON: '',
	ENTER_YOUR_PHONE_NUMBER_COLON: '',
	GET_CODE_BY_COLON: '',
	TEXT_MESSAGE: '',
	VOICE_CALL: '',
	SUBMIT: '',
	GET_THE_CODE: '',
	PLEASE_ENTER_A_VALID_CODE: '',
	NEXT: '',
	ENTER_CODE_COLON: '',
	RESEND: '',
	CHANGE_RESEND: '',
	REMEMBER_THIS_DEVICE: '',
	YOUR_PHONE_NUMBER_COLON: '',
	EXAMPLE_COLON: '',
	A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_COLON: '',
	A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_PHONE_NUMBER_COLON: '',
	REMEMBER_THIS_COMPUTER: '',
	A_PHONE_CALL_WITH_YOUR_VERIFICATION_CODE_HAS_BEEN_PLACED_TO_COLON: '',
	CHOOSE_HOW_TO_RECEIVE_THE_VERIFICATION_CODE_COLON: '',
	TO_KEEP_YOUR_ACCOUNT_SECURE_VERIFICATION_CODES_WILL_BE_SENT_WHEN_YOU_LOGIN_FROM_UNTRUSTED_DEVICES_DOT: '',
	EDIT_YOUR_PHONE_NUMBER_WHERE_VERIFICATION_CODES_WILL_BE_RECEIVED_COLON: '',
	SELECT_PROVIDER: '',
	STEP: '',
	SCAN_QR_CODE: '',
	ENTER_TOTP_CODE: '',
	SESSION_EXPIRED: '',
	AN_ERROR_HAS_OCCURRED_PLEASE_TRY_AGAIN_LATER: '',
	CANT_ACCESS_USE_BACKUP_CODES: '',
	TOTP_VERIFICATION_SETTINGS: '',
	TOTP_CHANGE_DEVICE: '',
	BACKUP_CODES: '',
	PRINT_BACKUP_CODES_INTRO: '',
	GENERATE_BACKUP_CODES: '',
	ALREADY_USED: '',
	ENTER_BACKUP_CODE: '',
	USE_BACKUP_CODE: '',
	RESET_CODES_QUESTION: '',
	TOTP_VERIFICATION_SETTINGS_INTRO: '',
	DOWNLOAD_BACKUP_CODES: '',
	PRINT: '',
	YES: '',
	CANCEL: '',
};

import filter from 'lodash/filter';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapSateToProps = (state, props) => {
	return {
		...state['PlayerChallengePage'],
	};
};
const mapDispatchToProps = (dispatch, props) => ({});

class SinglesChallengeSummary extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
		//this.state = {filters: this.props.filters, ...this.props.data, "selectedIndex": 0};
		//this.eventStats = [];
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	componentDidUpdate(prevProps, prevState) {}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	/*setDataIndex(currFilter) {
        let defaultIndex = 0;
        for(let i = 0; i < this.eventStats.length; i++) {
            if(this.eventStats[i].stat_label == currFilter) {
                return i;
            }
        }
        return defaultIndex;
    }

    setSelected(index) {
        this.setState(prevState => {
            return {...prevState, "selectedIndex": index};
        });
    }

    formatLabel(labelData) {
        return labelData.replace("_", " ").split(" ").map(d => d.charAt(0).toUpperCase() + d.substring(1)).join(" ");
    }*/

	render() {
		if (this.state.events) {
			let MSingles = filter(this.state.events, { event_name: "Gentlemen's Singles" })[0];
			let FSingles = filter(this.state.events, { event_name: "Ladies' Singles" })[0];
			return (
				<div className="event-stats four-col">
					<div className="datatable">
						<div className="title">Singles Challenge Summary</div>
						<div className="headers">
							<div className="cell first-col">Category</div>
							<div className="cell early-col">Gentlemen's</div>
							<div className="cell early-col">Ladies'</div>
						</div>
						<div className="row">
							<div className="cell first-col">Total Number of Challenges</div>
							<div className="cell early-col">{MSingles ? MSingles.total : '--'}</div>
							<div className="cell early-col">{FSingles ? FSingles.total : '--'}</div>
						</div>
						<div className="row">
							<div className="cell first-col">Total Number of Correct Challenges</div>
							<div className="cell early-col">{MSingles ? MSingles.correct : '--'}</div>
							<div className="cell early-col">{FSingles ? FSingles.correct : '--'}</div>
						</div>
						<div className="row">
							<div className="cell first-col">Total Number of Incorrect Challenges</div>
							<div className="cell early-col">{MSingles ? MSingles.incorrect : '--'}</div>
							<div className="cell early-col">{FSingles ? FSingles.incorrect : '--'}</div>
						</div>
						<div className="row">
							<div className="cell first-col">Percentage Overturned</div>
							<div className="cell early-col">
								{MSingles ? Math.round((MSingles.correct / MSingles.total) * 100) + '%' : '--'}
							</div>
							<div className="cell early-col">
								{FSingles ? Math.round((FSingles.correct / FSingles.total) * 100) + '%' : '--'}
							</div>
						</div>
						<div className="row">
							<div className="cell first-col">Avg. Challenges per Match</div>
							<div className="cell early-col">{MSingles ? MSingles.average : '--'}</div>
							<div className="cell early-col">{FSingles ? FSingles.average : '--'}</div>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapSateToProps, mapDispatchToProps)(SinglesChallengeSummary);

import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		let archive = false;
		let year = 'current';
		//logger.log('[NewsIndex] action.mount - params: %o', params);

		if (params.year && params.year != 'index') {
			archive = true;
			year = params.year;
		}

		logger.log('[NewsIndex] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[NewsIndex] action.mount - ensureConfig: %', Config);
			let data = {
				path: Config.cmsData.index,
				relatedContentPath: Config.relatedContent.newsFavPlayers,
				archive: archive,
				year: year,
			};
			//logger.log('[NewsIndex] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.NEWSINDEX_LOAD, data: data });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.NEWSINDEX_FILTER, data: data });
	},
};

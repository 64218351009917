/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { PlayerDigitalExperienceContext } from '../context';
import op from 'object-path';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import Button from 'appdir/components/common-ui/Button';
import { appendEncodedString } from 'appdir/components/general/Util';
import WimLink from 'appdir/components/general/WimLink';
import { checkRole } from 'appdir/components/general/Util/Role';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import SecurePDF from 'appdir/components/cms/SecurePDF';

import Collapsible from 'react-collapsible';

import SearchBox from 'appdir/components/common-ui/SearchBox';

/**
 * -----------------------------------------------------------------------------
 * React Component: PDE Player Match Stats and Video
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		msvPlayer: op.get(state['PlayerDigitalExperience'], 'msvPlayer'),
		configPde: state['Config'].pde,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	setPlayer: data => dispatch(deps.actions.PlayerDigitalExperience.setPlayer(data)),
});

class PlayerMatchStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.onEnter = this.onEnter.bind(this);
		this.getVideo = this.getVideo.bind(this);
		this.onVideoEvent = this.onVideoEvent.bind(this);
		this.openVideo = this.openVideo.bind(this);
		this.openMSVpage = this.openMSVpage.bind(this);
		this.yearCount = null;

		let search = new URLSearchParams(window.location.search);
		this.playerFromURL = search.get('p');
		this.jwt = null;

		logger.log('[PDE - PlayerMatchStats] constructor - state:%o context:%o', this.state, this.context);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[[PDE - PlayerMatchStats] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidMount() {
		logger.log('[PDE - PlayerMatchStats] componentDidMount - state:%o context:%o', this.state, this.context);
		this.loadPlayerSearchData();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PDE - PlayerMatchStats] componentDidUpdate - context:%o', this.context);
		// logger.log('[PDE - PlayerMatchStats] componentDidUpdate - prevState:%o', prevState);
		// logger.log('[PDE - PlayerMatchStats] componentDidUpdate - state:%o', this.state);
		// logger.log('[PDE - PlayerMatchStats] componentDidUpdate - props:%o', this.props);
		logger.log('[PDE - PlayerMatchStats] componentDidUpdate - playerFromURL:%o', this.playerFromURL);

		// first load, validate user role, get id
		if (
			!op.get(this.props, 'msvPlayer', false) &&
			op.get(this.props, 'roleIds', false) &&
			this.context.currentUser
		) {
			let roleValidated = checkRole(
				op.get(this.context, 'currentUser.roles', []),
				op.get(this.state, 'roleIds', [])
			);

			if (op.get(this.state, 'roleIds', false) && roleValidated) {
				let roles = op
					.get(this.context, 'currentUser.roles', [])
					.filter(d => d.roleId == this.state.roleIds[0]);

				if (roles.length > 0 && !this.playerFromURL) {
					this.props.setPlayer(roles[0].linkId);
					logger.log('[PDE - PlayerMatchStats] componentDidUpdate - setPlayer:%o', roles[0].linkId);
				} else if (this.playerFromURL) {
					this.props.setPlayer(this.playerFromURL);
					this.playerFromURL = null;
				}
			}
		}

		// new player, update data
		if (
			prevState.msvPlayer !== this.state.msvPlayer ||
			(this.state.msvPlayer && isEmpty(this.state.matchlist) && !this.state.error)
		) {
			// logger.log('[PDE - PlayerMatchStats] componentDidUpdate - loadPlayerData');
			this.loadPlayerData();
		}
	}

	loadPlayerSearchData() {
		logger.log('[PDE - PlayerMatchStats] loadPlayerSearchData - state:%o', this.state);

		axios({
			method: 'get',
			url: this.state.paths.playerLookup,
		})
			.then(result => {
				logger.log('[PDE - PlayerMatchStats] loadPlayerSearchData result:%o', result);

				let playerSearchData = [];
				let playerIDmap = [];

				result.data.players.map((p, i) => {
					playerIDmap[p.player_id] = p.tour_id;
					playerSearchData.push({
						first_name: p.first_name,
						last_name: p.last_name,
						player_id: p.player_id,
						id: p.tour_id,
					});
				});

				this.setState({
					playerSearchList: playerSearchData,
					playerIDmap,
				});
			})
			.catch(error => {
				logger.error('[PDE - PlayerMatchStats] loadPlayerSearchData login error:%o', error);
				this.setState({
					playerSearchList: playerSearchData,
				});
			});
	}

	loadPlayerData() {
		logger.log('[PDE - PlayerMatchStats] loadPlayerData - state:%o context:%o', this.state, this.context);

		if (op.get(this.state, 'paths.matchStatsHistory', false) && op.get(this.state, 'msvPlayer', false)) {
			let ms_history_url = this.state.paths.matchStatsHistory.replace('<refs_id>', this.state.msvPlayer);
			this.yearCount = 0;

			axios({
				method: 'get',
				url: ms_history_url,
			})
				.then(history_result => {
					// logger.log('[PDE - PlayerMatchStats] loadPlayerData result:%o', history_result.status);
					if (history_result.status == 200) {
						this.props
							.getJWT()
							.then(token => {
								logger.log('[PDE - PlayerMatchStats] loadPlayerData - token:%o', token.jwt);
								this.jwt = token.jwt.id_token;
								axios({
									method: 'get',
									url: appendEncodedString(
										`${this.state.paths.matchStatsVideo}?playerId=${this.state.msvPlayer}`,
										op.get(this.context, 'currentUser.UID', '')
									),
									headers: {
										Authorization: `Bearer ` + token.jwt.id_token,
										'Content-type': 'application/json',
									},
								})
									.then(msv_result => {
										logger.log(
											'[PDE - PlayerMatchStats] loadPlayerData - msv_result:%o',
											msv_result
										);

										this.setState({
											matchlist: history_result.data.years,
											playerName: history_result.data.playerName,
											msvlist: msv_result.data.years,
											error: null,
										});
									})
									.catch(data => {
										this.setState({ data: null });
									});
							})
							.catch(error => {
								logger.error('[PDE - PlayerMatchStats] loadPlayerData match stats video error');
								this.setState({
									error: 'playerDataError',
								});
							});
					} else {
						logger.error('[PDE - PlayerMatchStats] loadPlayerData history results status error');
						this.setState({
							error: 'playerDataError',
						});
					}
				})
				.catch(error => {
					logger.error('[PDE - PlayerMatchStats] loadPlayerData error:%o', error);
					this.setState({
						error: 'playerDataError',
					});
				});
		}
	}

	getVideo() {
		let { year, vidMatchID } = this.state;
		logger.log('[PlayerMatchStats] getVideo - year:%o, vidMatchID:%o', year, vidMatchID);
		if (year && vidMatchID) {
			let attributes = {
				playerId: 'main',
				contentId: null,
				cmsId: this.state.cmsId,
				videoUrl: `https://wim-a.akamaihd.net/playerportal/${year}/${vidMatchID}.mp4`,
				// videoUrl: `https://wim-a.akamaihd.net/playerportal/2019/MS701.mp4`, //test
				title: '',
				thumb: this.state.thumb,
				date: this.state.date,
				shareUrl: this.state.shareUrl,
				adUrl: '',
				nextUp: false,
				autoplay: true,
				fullscreenMode: '',
				style: `video-wrapper modal one-col`,
				streamType: 'vod',
				aspect: this.state.hasOwnProperty('aspect') ? this.state.aspect : '',
			};
			logger.log('[PlayerMatchStats] getVideo - attr:%o', attributes);
			return (
				<VideoPlayer key="news" attributes={attributes} onEvent={this.onVideoEvent} controlType={'native'} />
			);
		} else {
			return null;
		}
	}

	onVideoEvent(event) {
		logger.info('[PlayerMatchStats] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				year: '',
				vidMatchID: '',
			});
		}
	}

	openVideo(e, year, vidMatchID) {
		logger.log('[PlayerMatchStats] openVideo - year:%o, vidMatchID:%o', year, vidMatchID);
		e.preventDefault();
		this.setState({ year, vidMatchID });
	}

	openMSVpage(e, year, matchID, p1A, p1B, p2A, p2B) {
		this.props.getJWT().then(token => {
			// logger.log('[PDE - PlayerMatchStats] loadPlayerData - token:%o', token.jwt);
			let jwt = token.jwt.id_token;
			window.open(
				`/en_GB/static/matchvideo/index.html?year=${year}&matchId=${matchID}&token=${jwt}&p1a=${p1A}&p1b=${p1B}&p2a=${p2A}&p2b=${p2B}`,
				'_blank'
			);
		});
	}

	getMatchData(data, year, msv_data) {
		let description = `${data.roundName}, `;
		let winners;
		let losers;
		let msv_match = [];
		let msv_pdf = [];
		let msv_video = [];
		let msv_video_stats = [];

		// logger.log('[PDE - PlayerMatchStats] getMatchData - data:%o', data);
		// logger.log('[PDE - PlayerMatchStats] getMatchData - year:%o', year);
		// logger.log('[PDE - PlayerMatchStats] getMatchData - msv_data:%o', msv_data);

		//get msv data for match
		if (msv_data.length > 0) {
			msv_match = msv_data[0].matches.filter(m => m.match == data.matchIdSMT);
		}

		// logger.log('[PDE - PlayerMatchStats] getMatchData - msv_match:%o', msv_match);

		if (msv_match.length > 0) {
			msv_pdf = msv_match[0].data.filter(d => d.type == 'statsPDF');
			// logger.log('[PDE - PlayerMatchStats] getMatchData - msv_pdf:%o', msv_pdf);
			msv_video_stats = msv_match[0].data.filter(d => d.type == 'videoStats');
			// logger.log('[PDE - PlayerMatchStats] getMatchData - msv_video_stats:%o', op.get(msv_video_stats, '0.key', false));
			msv_video = msv_match[0].data.filter(d => d.type == 'video');
			// logger.log('[PDE - PlayerMatchStats] getMatchData - msv_video:%o', msv_video);
		}

		if (data.team1[0].won === true) {
			winners = data.team1[0];
		} else if (data.team1[0].won === false) {
			losers = data.team1[0];
		}

		if (data.team2[0].won === true) {
			winners = data.team2[0];
		} else if (data.team2[0].won === false) {
			losers = data.team2[0];
		}

		description += winners.displayNameA;
		if (winners.displayNameB) {
			description += ` / ${winners.displayNameB}`;
		}

		description += ` ${data.conjunction} `;

		description += losers.displayNameA;
		if (losers.displayNameB) {
			description += ` / ${losers.displayNameB}`;
		}

		switch (data.statusLong) {
			case 'Walkover':
				description += ` W/O`;
				break;
			case 'Bye':
				description += ` Bye`;
				break;
			case 'Default':
				description += ` Def.`;
				break;
			case 'Retired':
				description += ` Ret.`;
				break;
			default:
				description += ` ${data.score}`;
				break;
		}

		return (
			<div className="match-item" key={`match-${data.matchID}`}>
				<div className="match-item-description">{description}</div>
				<div className="match-links">
					{data.statusLong !== 'Walkover' && data.statusLong !== 'Bye' ? (
						<div className="match-link-item">
							<WimLink to={`/s/player/info/match-stats/${year}/${data.matchID}.html`}>
								View summary match statistics
							</WimLink>
						</div>
					) : null}
					{data.statusLong !== 'Walkover' && data.statusLong !== 'Bye' && op.get(msv_pdf, '0.key', false) ? (
						<div className="match-link-item">
							<SecurePDF
								data={{
									text: 'View full match statistics (PDF)',
									link: msv_pdf[0].key,
									id: `secpdf-${data.matchID}`,
									// style: 'four-col'
								}}
							/>
							{/* <WimLink to={msv_pdf[0].key} external={true}>
								 View full match statistics (PDF)
							 </WimLink> */}
						</div>
					) : null}
					{/* Link to fancy video player here */}
					{op.get(msv_video, '0.key', false) && op.get(msv_video_stats, '0.key', false) ? (
						<div className="match-link-item">
							<button
								className="pdeVideo"
								onClick={e =>
									this.openMSVpage(
										e,
										year,
										data.matchIdSMT,
										this.state.playerIDmap[data.team1[0].pidA],
										this.state.playerIDmap[data.team1[0].pidB],
										this.state.playerIDmap[data.team2[0].pidA],
										this.state.playerIDmap[data.team2[0].pidB]
									)
								}>
								View match video
							</button>
						</div>
					) : null}
					{/* Link to boring video player here */}
					{op.get(msv_video, '0.key', false) && !op.get(msv_video_stats, '0.key', false) ? (
						<div className="match-link-item">
							<button className="pdeVideo" onClick={e => this.openVideo(e, year, data.matchIdSMT)}>
								View match video
							</button>
						</div>
					) : null}
				</div>
			</div>
		);
	}

	getEventData(data, year) {
		let _this = this;

		//get msv data from state for year
		let msv = op.get(this.state, 'msvlist', []).filter(y => y.year == year);

		// logger.log('[PDE - PlayerMatchStats] getEventData - data:%o', data);

		return (
			<div className="match-list-events two-col margin-col" key={`event-${data.eventName}`}>
				<div className="event-header">{data.eventName}</div>
				{data.matches.map((match, i) => {
					return _this.getMatchData(match, year, msv);
				})}
			</div>
		);
	}

	getYearData(data) {
		let _this = this;
		let transitionTime = 150;
		this.yearCount++;

		// logger.log('[PDE - PlayerMatchStats] getYearData - this.yearCount:%o', this.yearCount);

		return (
			<div className="match-list-year" key={`year-${data.year}`}>
				<Collapsible
					trigger={`${data.year}`}
					transitionTime={transitionTime}
					key={`${data.year}`}
					open={this.yearCount === 1 ? true : false}>
					{data.events.map((event, i) => {
						return _this.getEventData(event, data.year);
					})}
				</Collapsible>
			</div>
		);
	}

	onEnter(value) {
		// logger.log('[PDE - PlayerMatchStats] onEnter - value:%o', value);
		let current_url = window.location.protocol + '//' + window.location.host + window.location.pathname;
		// logger.log('[PDE - PlayerMatchStats] onEnter - current_url:%o', current_url + '?p=' + value.id);
		window.history.pushState({}, '', current_url + '?p=' + value.id);
		this.props.setPlayer(value.id);
	}

	getTourId() {
		let roles = op.get(this.context, 'currentUser.roles', []).filter(d => d.roleId == this.state.roleIds[0]);

		if (roles.length > 0 && this.state.playerIDmap) {
			return this.state.playerIDmap[roles[0].linkId];
		} else {
			return '';
		}
	}

	render() {
		logger.log('[PDE - PlayerMatchStats] render - state:%o', this.state);
		let _this = this;
		this.yearCount = 0;

		if (op.get(this.state, 'playerSearchList', false)) {
			return (
				<Fragment>
					<div className="pde-match-stats-video column-layout video-wrapper-header">
						<div className="intro-text center">
							{/* Your match statistics and video will be available below after every completed match at The
							Championships.
							<br />
							<br />
							However, there are no match statistics or videos for any match where a walkover or bye was
							given.
							<br />
							<br />
							Your match video will be available 30 minutes after the conclusion of your match.
							<br />
							<br />
							You may also view match statistics and videos of any other active player by typing in their
							name into the search box below. */}
							Please click on the links below to view your match videos and match statistics. You can also
							use the search box to see statistics and videos from any other match from 2015 to the
							current Championships. This year’s match videos will be available 30 mins after your match
							finishes.
							<br />
							<br />
							For main draw singles matches, click on PERFORMANCE ANALYTICS for advanced insights to help you prepare, scout and review your matches – now including next-generation video analysis.
							<br />
							<br />
							<Button
								className={`btn solid`}
								to={`${this.state?.configPde?.performanceAnalytics}?tour_id=${this.getTourId()}`}
								target="_self">
								Performance Analytics
							</Button>
						</div>

						<div className="player-search four-col">
							<SearchBox searchList={this.state.playerSearchList} onEnter={this.onEnter} />
						</div>

						{op.get(this.state, 'error', false) === 'playerDataError' ? (
							<div className="match-list four-col">
								<div className="match-list-content no-content">
									The information you are requesting is currently unavailable. Please try again later
									or contact us on players@aeltc.com.
								</div>
							</div>
						) : null}

						{op.get(this.state, 'matchlist', false) && !op.get(this.state, 'error', false) ? (
							<div className="match-list four-col">
								<div className="match-list-header center">
									Currently Viewing matches for: <span className="name">{this.state.playerName}</span>
								</div>
								{this.state.matchlist.length === 0 ? (
									<div className="match-list-content no-content">No data available</div>
								) : (
									<div className="match-list-content">
										{this.state.matchlist.map((year, i) => {
											if (year.events.length > 0) {
												return _this.getYearData(year);
											}
										})}
									</div>
								)}
							</div>
						) : null}

						{this.getVideo()}
					</div>
				</Fragment>
			);
		} else {
			return null;
		}
	}
}

PlayerMatchStats.contextType = PlayerDigitalExperienceContext;
export default connect(mapStateToProps, mapDispatchToProps)(PlayerMatchStats);

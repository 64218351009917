/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import TableComponent from 'appdir/components/common-ui/TableComponent';
import WeatherCams from './WeatherCams';

/**
 * -----------------------------------------------------------------------------
 * React Component: WeatherPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['WeatherPage'],
		weatherData: state['WeatherData'],
		userPreferences: state['Controller'].userPreferences,
		pageHeaders: state['Config'].pageHeaders,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.WeatherPage.mount()),
});

class WeatherPage extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		logger.log('[WeatherPage] constructor - props:%o', this.props);
	}

	componentDidMount() {
		//don't call if has a route
		//this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[WeatherPage] componentWillReceiveProps - props:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	createHourByHourData(hours) {
		let newData = [];
		hours.map((data, index) =>
			newData.push({
				time: `<span class="time">${data.time}</span>`,
				forecast: `<span class="icon"><img src=${data.icon} alt=${
					data.phrase_32char
				} /></span><span class="temp">${data.temp[this.state.userPreferences.temperature]}</span>`,
				wind: `<span class="icon"><img src='https://assets.wimbledon.com/images/weather/wc_wind_green/wind${
					data.wdir
				}.png' alt=${data.wdir}  /></span><span class="speed">${
					data.wind_speed[this.state.userPreferences.speed]
				}</span>`,
			})
		);
		//logger.log('[WeatherPage] createHourByHourData %o', newData);
		return newData;
	}

	createFiveDayHeaders(days) {
		//add empty header for first column
		let newData = [''];
		let newMobileData = [''];
		days.map((data, index) => newData.push(data.dow));

		// for mobile header, get abbr header values
		newData.map((data, i) => {
			if (i > 0) {
				newMobileData.push(data.substring(0, 3));
			}
		});

		// logger.log('[WeatherPage] createFiveDayHeaders:%o, newMobileData%o', newData, newMobileData);
		return {
			headers: newData,
			mobileHeaders: newMobileData,
		};
	}

	createFiveDayData(days) {
		let newData = [];
		let dataKeys = ['icon', 'max', 'min'];
		let dataKey = '';

		for (let i = 0; i < 3; i++) {
			newData.push({});

			dataKey = dataKeys[i];
			//logger.log('[WeatherPage] createFiveDayData - dataKey%o', dataKey);

			for (let j = 0; j < 6; j++) {
				let keyVal = 'day_' + j;
				let dataIndex = j - 1;

				//logger.log('[WeatherPage] createFiveDayData - index:%o day:%o dataKey%o keyVal:%o', dataIndex, days[dataIndex], dataKey, keyVal);

				switch (dataKey) {
					case 'icon':
						j > 0
							? (newData[i][
									keyVal
							  ] = `<span class=${dataKey}><img src=${days[dataIndex][dataKey]} width="44" height="44" /></span>`)
							: (newData[i][keyVal] = `<span class=${dataKey}></span>`);
						break;
					case 'max':
						j > 0
							? (newData[i][keyVal] = `<span class=${dataKey}>${days[dataIndex][dataKey]}</span>`)
							: (newData[i][keyVal] = `<span class=${dataKey}>High</span>`);
						break;
					case 'min':
						j > 0
							? (newData[i][keyVal] = `<span class=${dataKey}>${days[dataIndex][dataKey]}</span>`)
							: (newData[i][keyVal] = `<span class=${dataKey}>Low</span>`);
						break;
					default:
						break;
				}
			}
		}
		//logger.log('[WeatherPage] createFiveDayData - newData:%o', newData);
		return newData;
	}

	renderFiveDayText(days) {
		return days.map((data, index) => (
			<div key={data.dow} className="forecast">
				<div className="title">{data.dow}:</div>
				<div className="text">{data.narrative}</div>
			</div>
		));
	}

	getFiveDayData(days) {
		let data = [];
		let day = {};
		let count = 0;

		for (let i = days.length - 1; i >= 0; i--) {
			day = {};
			day.dow = days[i].dow;
			day.icon = days[i].icon;
			day.max = days[i].max_temp ? days[i].max_temp[this.state.userPreferences.temperature] : '-';
			day.min = days[i].min_temp ? days[i].min_temp[this.state.userPreferences.temperature] : '-';
			day.narrative = days[i].narrative[`${this.state.userPreferences.temperature}_I`];
			data.unshift(day);
			count++;
			if (count == 5) {
				return data;
			}
		}
		return data;
	}

	renderCurrentWeather() {
		return (
			<div className="current-conditions">
				<div className="title">Current Conditions</div>
				<div className="leftside">
					<div className="title2">Current Temperature</div>
					<div className="curTemp">
						{this.state.weatherData.current.temp[this.state.userPreferences.temperature]}
					</div>
				</div>
				<div className="rightside">
					<div className="curdata">
						<div className="row observation">
							<div className="text">Observation:</div>
							<div className="value">{this.state.weatherData.current.phrase_32char}</div>
						</div>
						<div className="row wind">
							<div className="text">Wind:</div>
							<div className="value">
								<span>
									{this.state.weatherData.current.wind_speed[this.state.userPreferences.speed]}
								</span>
								<img
									src={`https://assets.wimbledon.com/images/weather/wc_wind/wind${this.state.weatherData.current.wind_dir}.png`}
								/>{' '}
							</div>
						</div>
						<div className="row feelslike">
							<div className="text">Feels Like:</div>
							<div className="value">
								{this.state.weatherData.current.feels_like[this.state.userPreferences.temperature]}
							</div>
						</div>
						<div className="row precip">
							<div className="text">Precip 1Hr:</div>
							<div className="value">{this.state.weatherData.current.precip_1hour.M}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		//let hbh_data = this.state.weatherData.hour;
		let headerImagePath = '/assets/images/headers/weather_28.jpg';
		let available = op.get(this.state, 'pageHeaders.weather.available', '');
		let imagePath = op.get(this.state, 'pageHeaders.weather.imagePath', '');

		if (available && imagePath) {
			if (this.state.weatherData) {
				if (this.state.weatherData.current) {
					if (available.indexOf(this.state.weatherData.current.icon_code) !== -1) {
						headerImagePath = imagePath.replace('<code>', this.state.weatherData.current.icon_code);
					}
				}
			}
		}
		// }
		let header_propsData = {
			headerType: 'weather',
			titleElem: '.weather--header',
			metaTitle: 'Weather',
			metaDescription: 'Weather forecast information for The Championships, Wimbledon updated hourly.',
			metaDate: '',
			metaPlayers: '',
			imageSrc: headerImagePath,
		};

		//logger.log('[WeatherPage] render - status:%o', this.state.weatherData.status);
		//logger.log('[WeatherPage] render - data:%o', this.state.weatherData);

		let hasData = op.get(this.state, 'weatherData.status', 'no') == 'loaded';
		let hbh_headers = ['Time', 'Forecast', 'Wind'];
		let hbh_propsData, fiveDay_propsData, fiveDayData;

		if (hasData) {
			let hbh_data = this.createHourByHourData(this.state.weatherData.hour.hours);
			hbh_propsData = {
				headers: hbh_headers,
				data: hbh_data,
				tableContainer: 'hbh-table',
				tableTitle: 'Hour By Hour Forecast',
			};
			logger.log('[WeatherPage] render - hbh_propsData:%o', hbh_propsData);

			fiveDayData = this.getFiveDayData(this.state.weatherData.day.days);
			//logger.log('[WeatherPage] render - fiveDayData:%o', fiveDayData);

			let fiveDay_headers = this.createFiveDayHeaders(fiveDayData).headers;
			let fiveDay_mobile_headers = this.createFiveDayHeaders(fiveDayData).mobileHeaders;

			let fiveDay_data = this.createFiveDayData(fiveDayData);
			fiveDay_propsData = {
				headers: fiveDay_headers,
				mobileHeaders: fiveDay_mobile_headers,
				data: fiveDay_data,
				tableContainer: 'fiveday-table',
				tableTitle: 'Five Day Forecast',
			};
		}
		//logger.log('[WeatherPage] render - fiveDay_data:%o', fiveDay_data);

		logger.log('[WeatherPage] render - state:%o hasData:%o', this.state, hasData);

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				{!hasData ? (
					<div className="content-main">
						<LoadingIndicator />
					</div>
				) : (
					<div className="content-main weather">
						<div className="weather--header-wrapper">
							<div className="weather--header">{this.renderCurrentWeather()}</div>
						</div>
						<div className="column-layout weather">
							<WeatherCams cams={this.state.cams} />
							<div className="two-col component">
								<TableComponent attributes={hbh_propsData} />
							</div>
							<div className="two-col component">
								<TableComponent attributes={fiveDay_propsData} />
								<div className="fiveday-forecast">{this.renderFiveDayText(fiveDayData)}</div>
							</div>
						</div>
					</div>
				)}

				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherPage);

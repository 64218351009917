import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class Live extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			...this.props
		}

		this.onTimeUpdate = this.onTimeUpdate.bind(this);

		this.amp = this.props.amp;
		this.addAmpListeners();

		//logger.log('Time: constructor - state:%o ', this.state);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.amp.multitonKey != prevProps.amp.multitonKey){
			this.amp = this.props.amp;
			this.addAmpListeners();
		}
	}

	addAmpListeners() {
		this.amp.addEventListener('timeupdate', this.onTimeUpdate);
	}

	formatTime(curTime, durTime) {
		let data_state = '';
		let curhours = Math.floor(curTime / 3600);
		let curmins = Math.floor((curTime - (curhours * 3600)) / 60);
		let cursecs = Math.floor(curTime - (curhours * 3600) - (curmins * 60));

		let durhours = Math.floor(durTime / 60 / 60);
		let durmins = Math.floor((durTime - (durhours * 3600)) / 60);
		let dursecs = Math.floor(durTime - (durhours * 3600) - (durmins * 60));

		//logger.info('formatTime - dur:%o hrs:%o mins:%o secs:%o', durTime, durhours, durmins, dursecs);

		if (cursecs < 10) { cursecs = "0" + cursecs; }
		if (dursecs < 10) { dursecs = "0" + dursecs; }
		if (curmins < 10) { curmins = "0" + curmins; }
		if (durmins < 10) { durmins = "0" + durmins; }

		curTime = '';
		durTime = '';

		if (curhours > 0 || durhours > 0) {
			durTime += (durhours > 0 ? durhours + ':' : '');

			if (curhours > 0) {
				curTime += curhours + ':';
			} else if (durhours != '') {
				curTime += '0:';
			}

			data_state = 'hours';
		}

		if (!isNaN(curmins) || !isNaN(durmins)) {
			curTime += ((!isNaN(curmins)) ? curmins : '00') + ':';
			durTime += ((!isNaN(durmins)) ? durmins : '00') + ':';
		}

		if (!isNaN(cursecs) || !isNaN(dursecs)) {
			curTime += ((!isNaN(cursecs)) ? cursecs : '00');
			durTime += ((!isNaN(dursecs)) ? dursecs : '00');
		}

		this.setState({
			time: curTime,
			duration: durTime,
			data_state: data_state
		});
	}

	onTimeUpdate(data) {
		//logger.log('Time: onTimeUpdate - data:%o time:%o dur:%o', data, this.amp.getCurrentTime(), this.amp.getDuration());
		this.formatTime(this.amp.getCurrentTime(), this.amp.getDuration());
	}
	
	render() {
		//logger.log('Time: render - state:%o', this.state);
		let styles = this.state.styles;
		
		return (
			<div className={styles.timeText} data-state={this.state.data_state}>
				<span className={styles.curTimeText}>{this.state.time}</span>
				<span className={styles.durTimeText}>{this.state.duration}</span>
			</div>
		)
	}
	
}

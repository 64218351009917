/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import op from 'object-path';
import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { values } from 'appdir/main';

/**
 * -----------------------------------------------------------------------------
 * React Component: Debug
 * -----------------------------------------------------------------------------
 */

export default class Debug extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(nextProps) {}

	render() {
		//logger.info('[Debug] render - state: %o', this.state);

		let roles = op.get(this.state, 'currentUser.roles', []);
		let cnt = roles.length;
		roles = roles.map((role, i) => {
			let comma = i < cnt - 1 ? ', ' : '';
			let name = role.name + comma;
			if (role.segment) {
				name = role.name + '-' + role.segment + comma;
			}
			return name;
		});

		if (this.state.debugView && this.state.info) {
			return (
				<div className="debug-info-display">
					<noindex>
						<div className="data-column">
							<div className="info-section">Release:</div>
							<table className="info-table">
								<tbody>
									<tr>
										<td>Version:</td>
										<td>{window.version}</td>
									</tr>
									<tr>
										<td>Commit:</td>
										<td>{window.commit}</td>
									</tr>
								</tbody>
							</table>
							<div className="info-section">Environments:</div>
							<table className="info-table">
								<tbody>
									<tr>
										<td>Scoring:</td>
										<td>{this.state.info.scoreEnv}</td>
									</tr>
									<tr>
										<td>Related Content:</td>
										<td>{this.state.info.relatedEnv}</td>
									</tr>
									<tr>
										<td>Orchestration:</td>
										<td>{this.state.info.orchEnv}</td>
									</tr>
									<tr>
										<td>Gigya:</td>
										<td>{this.state.info.authEnv}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="data-column">
							<div className="info-section">Auth:</div>
							<table className="info-table">
								<tbody>
									<tr>
										<td>User:</td>
										<td>
											{op.get(this.state, 'currentUser.profile.firstName', '') +
												' ' +
												op.get(this.state, 'currentUser.profile.lastName', '')}
										</td>
									</tr>
									<tr>
										<td>UID:</td>
										<td>{op.get(this.state, 'currentUser.UID', '')}</td>
									</tr>
									<tr>
										<td>Roles:</td>
										<td>{roles}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</noindex>
				</div>
			);
		} else {
			return null;
		}
	}
}

import React, { Component } from 'react';

import { isMobileSafari, isBrowser } from 'react-device-detect';
import parse from 'html-react-parser';
import Autosuggest from 'react-autosuggest';
class SearchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			props,
			value: '',
			staticData: props.staticData,
			noSuggestions: false,
			filterData: [],
			found: false,
			showResults: false,
			noSuggFocus: true,
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		// document.getElementById("wimbledon-search-text").addEventListener('input', debounce(this.handleChange, 150,{'leading': true, 'trailing': false}), false);
		// window.addEventListener('resize', throttle(this.onResize.bind(this), 0), false);
	}

	// UNSAFE_componentWillReceiveProps(nextProps) {
	// 	this.setState(prevState => {
	// 		return Object.assign({}, prevState, nextProps);
	// 	});
	// }

	handleChange(vals) {
		let value = vals;
		let filterData = this.props.staticData.filter(d => {
			return (
				d.firstName.toLowerCase().indexOf(value.value.toLowerCase()) !== -1 ||
				d.lastName.toLowerCase().indexOf(value.value.toLowerCase()) !== -1
			);
		});

		return filterData;
	}

	onChange(event, { newValue, method }) {
		this.setState({
			value: newValue,
			found: false,
		});
	}

	getFormValue(evt) {
		if (evt) {
			evt.preventDefault();
		}
		this.props.onEnter(this.state.value);
		logger.log('[SearchBox] handleChange - value:%o', this.state.value);
	}

	onSuggestionsFetchRequested(value) {
		let filterData = this.handleChange(value);
		let noSuggestions = filterData.length == 0 ? true : false;
		let noSuggFocus = filterData.length == 0 ? true : false;
		this.setState({
			filterData: this.handleChange(value),
			noSuggestions,
			noSuggFocus,
		});
	}

	onSuggestionsClearRequested() {
		this.setState({
			filterData: [],
		});
	}

	onSuggestionSelected(event, { suggestion }) {
		this.setState({ noSuggestions: false, found: true });
		this.props.onEnter(suggestion);
	}

	renderAutoSuggest(data) {
		return (
			<div className={`result-article-title`}>
				{/*ReactHtmlParser*/ parse(this.highlightText(this.state.value, `${data.firstName} ${data.lastName}`))}
			</div>
		);
	}

	getSuggestionValue(data) {
		return `${data.firstName} ${data.lastName}`;
	}

	highlightText(searchTerm, text) {
		let searchTermLower = searchTerm;
		let searchRegex = new RegExp(searchTermLower, 'gi');
		if (text) {
			return `<span>${text.replace(searchRegex, str => `<span class='bold-text'>${str}</span>`)}</span>`;
		}
		return null;
	}

	resetSearch() {
		this.setState({
			value: '',
		});

		this.props.onEnter('');
	}

	onBlur(event, { highlightedSuggestion }) {
		this.setState({
			noSuggFocus: false,
		});
	}

	render() {
		const { value } = this.state;
		const inputProps = {
			placeholder: 'Search',
			value,
			onChange: this.onChange.bind(this),
			onBlur: this.onBlur.bind(this),
		};
		return (
			isBrowser && (
				<div className="search-box search-box-container ">
					<div className="search-wrapper">
						<i className="wim-icon-search" />
						{this.state.value.length > 0 && (
							<i className="wim-icon-close" onClick={this.resetSearch.bind(this)} />
						)}
						<Autosuggest
							suggestions={this.state.filterData}
							onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
							onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
							onSuggestionSelected={this.onSuggestionSelected.bind(this)}
							getSuggestionValue={this.getSuggestionValue.bind(this)}
							renderSuggestion={this.renderAutoSuggest.bind(this)}
							focusInputOnSuggestionClick={false}
							inputProps={inputProps}
						/>
						{this.state.value.length > 0 &&
							this.state.noSuggestions &&
							this.state.noSuggFocus &&
							!this.state.found && <div className="autosuggest-not-found">Player not found</div>}
					</div>
				</div>
			)
		);
	}
}
export default SearchBox;

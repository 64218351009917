import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.COUNTRYSTATISTICSPAGE_LOADING:
			newState = Object.assign({}, state, { status: 'loading' });
			return newState;
			break;

		case deps.actionTypes.COUNTRYSTATISTICSPAGE_LOAD_COMPLETE:
			logger.log('[COUNTRYSTATISTICSPAGE] COUNTRYSTATISTICSPAGE_LOAD_COMPLETE - result:%o', action.data);
			newState = Object.assign({}, state, { countryStatsPath: action.data });
			return newState;
			break;

		case deps.actionTypes.COUNTRYSTATISTICSPAGE_LOAD_ERROR:
			newState = Object.assign({}, state, { error: action.err, status: 'error' });
			return newState;
			break;

		default:
			return state;
	}
};

import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import { getRoleByName } from 'appdir/components/general/Util/Role';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { getQuerystringValues } from 'appdir/components/general/Util';

import op from 'object-path';
import Button from 'appdir/components/common-ui/Button';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import RegisterButton from 'appdir/components/cms/RegisterButton';
import JSXParser from 'react-jsx-parser';
import { getComponents } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['Config'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class BallotStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.ballotApplicationClick = this.ballotApplicationClick.bind(this);
		this.ballotHistoryClick = this.ballotHistoryClick.bind(this);

		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));

		logger.log('[BallotStatus] constructor - state:%o context:%o', this.state, this.context);
	}
	componentDidMount() {
		//logger.log('[BallotStatus] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log("[BallotStatus] componentDidUpdate - state:%o", this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	ballotApplicationClick() {
		logger.log('[BallotStatus] redirect to %s', this.state.ballot.applicationUrl);
		document.location.href = this.state.ballot.applicationUrl;
	}

	ballotHistoryClick() {
		logger.log('[BallotStatus] redirect to %s', this.state.ballot.applicationHistory);
		document.location.href = this.state.ballot.applicationHistory;
	}

	clickLogin() {
		this.props.openScreen({
			screen: 'login_screen',
			redirect: false,
			showSuccess: false,
			cid: 'ballot_register',
		});
	}

	/**
	 * Return Ballot content in "pre" period
	 * @returns
	 */
	getContentPre() {
		return null;
	}

	getTimeline(period, status) {
		let checked = false;
		//let values = getTimelineValues(period, status);
		let values = op.get(this.state, `text.ballotStatus`, []);
		let statusValue = values.filter(values => {
			if (values.period.includes(period) && values.status == status.toLowerCase()) {
				return true;
			}
		});
		if (statusValue.length == 0) {
			statusValue = values.filter(values => {
				if (values.period.includes(period) && values.status == 'default') {
					return true;
				}
			});
		}
		logger.log('[BallotStatus] getTimeline - statusValue:%o', statusValue);

		return (
			<div className={`ballot-status ${this.props.style} ${statusValue[0].steps.length == 1 ? 'single' : ''}`}>
				<div className="status-line">
					<div className="ball"></div>
					<div className="base"></div>
				</div>
				<div className="status-container">
					{statusValue[0].steps.map((step, i) => {
						return (
							<Fragment key={`bullet${i}`}>
								<div className={`status-detail ${step.check ? 'checked' : ''}`}>
									<i className="wim-icon-check" />
									{step.title}
								</div>
								<div className="status-message">
									<div className="status-text">
										<JSXParser
											bindings={[]}
											components={getComponents(['WimLink', 'RegisterButton'])}
											jsx={step.msg}
										/>
									</div>
								</div>
							</Fragment>
						);
					})}
				</div>
			</div>
		);
	}

	render() {
		let ballotRole = getRoleByName(op.get(this.state, 'currentUser.roles', null), 'ballot');
		//let period = op.get(this.state, 'ballot.period', false);
		//let status = op.get(ballotRole, 'status', null);

		let period = op.get(this.parsedQs, 'period', op.get(this.state, 'ballot.period', false));
		let status = op.get(this.parsedQs, 'status', op.get(ballotRole, 'status', ''));
		logger.log(
			'[BallotStatus] render - period:%o status:%o hasBallot:%o state:%o',
			period,
			status,
			ballotRole,
			this.state
		);

		return (
			<>
				{!period ? (
					<LoadingIndicator type="small relative" />
				) : period == 'pre' ? (
					this.getContentPre()
				) : !this.state.loggedIn && !this.state.gigyaLoaded ? (
					<div className={`ballot-status ${this.props.style} }`}>
						<LoadingIndicator type="small relative" />
					</div>
				) : !this.state.loggedIn && this.state.gigyaLoaded ? (
					this.getTimeline(period, 'No_Role')
				) : !ballotRole ? (
					this.getTimeline(period, 'Default')
				) : (
					this.getTimeline(period, status)
				)}
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BallotStatus);

/*
<Button className={`btn solid`} to={`${this.state.ballot.applicationHistory}`} target="_self">
							View My Ballot Entry
						</Button>
						*/

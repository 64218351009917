/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import PlayerImage from 'appdir/components/content/PlayerImage';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';
import WimLink from 'appdir/components/general/WimLink';
import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';

const op = require('object-path');
/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerBox for Match Insights
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['PlayerBox'],
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	hide: () => dispatch(deps.actions.SlamtrackerPanel.hide()),
});
class PlayerBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			favs: window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : [],
			render: false,
		};
		logger.log('[PlayerBox] - props:%o', this.props);

		this.imgSrc = props.imgPath.replace('<playerid>', props.data.idA);
		this.donut_options = {
			donut: true,
			donutWidth: 8,
			startAngle: 210,
			total: 120,
			showLabel: false,
			resize: true,
			chartPadding: 0,
		};

		this.donut_data = {
			series: [{ value: props.likelihoodWinData['likelihoodWinNum'], className: 'stroke-color' }],
			// series: [53]//test data
		};

		this.updateFavs = this.updateFavs.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
	}

	onLinkClick(e, path) {
		e.preventDefault();
		if (this.props.windowSize == 'mobile') {
			this.props.hide();
		}
		logger.log('[PlayerBox] - onLinkClick');
		this.props.navigate({ path: path });
	}

	componentDidMount() {
		setTimeout(
			function() {
				this.setState({ render: true });
			}.bind(this),
			500
		);
	}

	componentDidUpdate(prevProps) {
		// if (prevProps) {
		// 	let chartEl = document.querySelector('.mi-pr-chart');
		// 	if (chartEl && op.get(this.props, 'type', null) == 'slamtracker') {
		// 		// force redraw the chart when tabs are switched back
		// 		window.dispatchEvent(new Event('resize'));
		// 	}
		// }
	}

	getPlayerFlag(nation) {
		let flagPath;

		flagPath = this.props.flagPath.replace('<code>', nation);

		return flagPath;
	}

	updateFavs() {
		let newFavs = Object.assign([], this.state.favs);
		if (this.isWebviewFav()) {
			newFavs = this.state.favs.filter(fav => fav !== this.props.data.idA);
			this.setState({
				favs: newFavs,
			});
		} else {
			newFavs.push(this.props.data.idA);
			this.setState({
				favs: newFavs,
			});
		}
	}

	isWebviewFav() {
		logger.log(
			'[PlayerBox] - isWebviewFav - favs:%o, %o:%o',
			this.state.favs,
			this.props.data.idA,
			this.state.favs.indexOf(this.props.data.idA) !== -1
		);

		return this.state.favs.indexOf(this.props.data.idA) !== -1;
	}

	render() {
		let { teamnum, data, likelihoodWinData, direction } = this.props;
		this.isSlamTracker = this.props?.type == 'slamtracker';
		return (
			<div className={`mi-pr-player-info player${teamnum}`}>
				{window.webviewPlatform && this.props?.type !== 'news' && this.props?.type != 'slamtracker' ? (
					<WimLink
						to={
							!this.isWebviewFav()
								? `/addPlayerFav/${data.idA}.html`
								: `/removePlayerFav/${data.idA}.html`
						}
						external={true}>
						<span
							onClick={() => this.updateFavs()}
							className={`favorite ${
								this.isWebviewFav() ? 'favorite favorited yellow' : 'favorite green'
							}`}></span>
					</WimLink>
				) : this.props?.type !== 'news' && this.props?.type != 'slamtracker' ? (
					<FavouriteStar
						id={data.idA}
						type="players"
						unFavColor="green"
						favColor="yellow"
						alwaysShow={true}
					/>
				) : null}
				{values.webview ? (
					<WimLink to={`/en_GB/players/overview/${data.idA}.html`}>
						<PlayerImage
							attributes={{
								'player-image': this.imgSrc,
							}}
						/>
					</WimLink>
				) : (
					<a
						href={`/en_GB/players/overview/${data.idA}.html`}
						onClick={e => {
							this.onLinkClick(e, `/en_GB/players/overview/${data.idA}.html`);
						}}>
						<PlayerImage
							attributes={{
								'player-image': this.imgSrc,
							}}
						/>
					</a>
				)}

				<div className="mi-pr-player-country-name">
					{/** display for desktop or tablet */}
					<div className="above-tablet">
						<div className="mi-player-flag">
							{data.nationA ? <img src={this.getPlayerFlag(data.nationA)} alt={data.nationA} /> : null}
						</div>
					</div>

					{values.webview ? (
						<WimLink to={`/en_GB/players/overview/${data.idA}.html`}>
							<div className="player-name">
								{/** display for desktop or tablet and NOT in the news article */}
								<div className="above-tablet">
									{data.firstNameA} {data.lastNameA}
								</div>

								{/** display for mobile size or in the news article */}
								<div className="mobile-only">{data.displayNameA}</div>
							</div>
						</WimLink>
					) : (
						<a
							// ref="button-livescores"
							// className="scores-button"
							// tabIndex={-1}
							// aria-hidden={true}
							href={`/en_GB/players/overview/${data.idA}.html`}
							onClick={e => {
								this.onLinkClick(e, `/en_GB/players/overview/${data.idA}.html`);
							}}>
							<div className="player-name">
								{/** display for desktop or tablet and NOT in the news article */}
								<div className="above-tablet">
									{data.firstNameA} {data.lastNameA}
								</div>

								{/** display for mobile size or in the news article */}
								<div className="mobile-only">{data.displayNameA}</div>
							</div>
						</a>
					)}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerBox);

import React from 'react';
import {
	createTableHeaders,
	formatTableData,
	formatMatchStatsData,
} from 'appdir/components/cms/MatchStatsFull/elements/Utils';
import TableComponent from 'appdir/components/common-ui/TableComponent';

const MatchStats = props => {
	logger.log('[MatchStats] props:%o', props);
	let propsData = {};

	if (props.data !== null) {
		let matchData = {
			team1: props.data.matches[0].team1,
			team2: props.data.matches[0].team2,
			base_stats: props.data.matches[0].base_stats.match,
			serve_stats: props.data.matches[0].serve_stats.match,
			distance_run: props.data.matches[0].distance_run.match,
		};

		let tableHeader = createTableHeaders({
			player1: matchData.team1,
			player2: matchData.team2,
			matchArchive: true,
		});
		let formattedData = formatMatchStatsData(matchData);
		let tableData = formatTableData(formattedData);

		propsData = {
			headers: tableHeader,
			data: tableData,
			tableTitle: '',
			tableContainer: 'match-stats',
		};
	}

	if (props.data !== null) {
		return (
			<div className="column-layout-six">
				<div className="six-col match-stats-container">
					<TableComponent attributes={propsData} />
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default MatchStats;

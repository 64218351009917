import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import Results from './Results';
import Template from 'appdir/components/Template';
import { getQuerystringValues } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...state['DrawsArchiveSearch'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.DrawsArchiveSearch.mount()),
});

class DrawsArchiveSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
		};
		//logger.log('[DrawsArchiveSearch] props:%o', props);
	}

	componentDidMount() {
		//logger.log('[DrawsArchiveSearch] componentDidMount - state:%o', this.state);
		this.readyNewSearch();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[DrawsArchiveSearch] componentWillReceiveProps - state:%o, nextProps:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[DrawsArchiveSearch] componentDidUpdate - state:%o', this.state);

		let prevSearch = this.getTerm(prevState.location.search);
		let newSearch = this.getTerm(this.props.location.search);
		//logger.log('[DrawsArchiveSearch] componentDidUpdate - prev:%o new:%o results:%o', prevSearch, newSearch, this.state.playerSearchResults);

		if (prevSearch !== newSearch && this.state.playerSearchResults) {
			this.readyNewSearch();
		} else if (newSearch && this.state.playerSearchResults === undefined) {
			this.fetchSearchResults();
		}
	}

	readyNewSearch() {
		//logger.log("set state for ready new search");
		const items = this.state;
		items.playerSearchResults = undefined;

		this.setState({
			items,
		});
	}

	fetchSearchResults() {
		if (this.state.playerSearchPath) {
			let searchTerm = this.getTerm(this.props.location.search);
			//logger.log('[DrawsArchiveSearch] fetchSearchResults - searchTerm:%o', searchTerm);

			let dataPath = this.state.playerSearchPath.replace('<searchTerm>', encodeURI(searchTerm));
			deps.services.DrawsArchiveSearch.fetch(dataPath).then(result => {
				logger.log('[DrawsArchiveSearch] getResults - data:%o', result);
				this.setState({
					playerSearchResults: result,
				});
				window.scrollTo(0, 0);
			});
		}
	}

	getTerm(search) {
		let parsedQs = getQuerystringValues(search.replace(/^\?/, ''));
		//logger.log('[DrawsArchiveSearch] getTerm - search:%o parsed:%o', search, parsedQs);
		let searchTerm = parsedQs.term;
		return searchTerm;
	}

	render() {
		logger.log('[DrawsArchiveSearch] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'drawsArchive',
			title: 'Draws Archive',
			imageSrc: '/assets/images/headers/drawsArchive_year.jpg',
			metaTitle: 'Draws Archive',
			metaDescription: 'Player profile archive information for The Championships, Wimbledon.',
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template className="drawsarchive">
				<Header attributes={header_propsData} />
				<MainNav />
				<PageHeader attributes={header_propsData} />
				<div className="drawsArchiveHdrContent">
					<ArchiveMenu location={this.state.location.pathname} />
				</div>

				{this.state.playerSearchResults ? (
					<div className="content-main">
						<div className="searchResultsList">
							<Results data={this.state} />
						</div>
					</div>
				) : (
					<div className="content-main">
						<LoadingIndicator />
					</div>
				)}

				<Footer />
			</Template>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveSearch);

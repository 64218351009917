import React from 'react';

const PageLayout = ({ data }) => {
	let bg1 = {
		backgroundColor: 'Bisque',
	};
	let bg2 = {
		backgroundColor: 'LightSteelBlue',
	};
	let bg3 = {
		backgroundColor: 'Gainsboro',
	};
	let bg4 = {
		backgroundColor: 'AliceBlue',
	};

	return (
		<div className="content-main content ">
			<div className="column-layout content">
				<div className="four-col ">Four-Col</div>
				<div className="four-col center">Four-Col Center</div>
				<div className="two-col margin">Two-Col Margin</div>
				<div className="four-col bottom-margin">Four-Col Bottom Margin</div>
				<div className="two-col margin">
					<h4>H4 Title Example</h4>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent elementum velit tellus. Nullam
						sapien dui, aliquet id porta sed, placerat non lectus. Fusce sodales enim sed volutpat
						ullamcorper.
					</p>
					<p>
						Quisque sit amet metus ac metus interdum ultricies. Vivamus at blandit purus, eu vulputate
						felis. Vivamus eget risus accumsan, venenatis est nec, fermentum lorem. Pellentesque congue
						consequat ornare. Curabitur sodales viverra nunc, sit amet fermentum sem lacinia sit amet.
					</p>
				</div>
			</div>
		</div>
	);
};

export default PageLayout;

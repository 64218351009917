import React, { Component } from 'react';
import ContentLink from 'appdir/components/cms/ContentLink';

const allLetters = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

class AtoZ extends Component {
	constructor(props) {
		super(props);

		logger.log('[AtoZ] constructor - props:%o', props);

		this.state = {
			...props,
			data: props.data,
		};
	}

	componentDidMount() {
		logger.log('[AtoZ] componentDidMount');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		logger.log('[AtoZ] componentDidUpdate');
	}

	shouldComponentUpdate() {
		if (this.state.data) {
			return true;
		}
		return false;
	}

	groupItems(data) {
		if (data) {
			let letter = '';
			let grouped = [];
			let completeGrouped = [];
			for (let i = 0; i < data.length; i++) {
				if (data[i].title.substring(0, 1).toUpperCase() != letter) {
					letter = data[i].title.substring(0, 1).toUpperCase();

					grouped.push({
						id: letter,
						items: [],
					});
				}
				grouped[grouped.length - 1].items.push(data[i]);
			}

			completeGrouped = this.getMissingLetters(grouped);

			return completeGrouped;
		}

		return [];
	}

	getMissingLetters(grouped) {
		if (grouped.length != allLetters.length) {
			for (let i = 0; i < allLetters.length; i++) {
				if (!grouped[i] || grouped[i].id != allLetters[i]) {
					grouped.splice(i, 0, { id: allLetters[i], items: [] });
				}
			}
		}

		return grouped;
	}

	getContent(group) {
		if (group.items.length > 0) {
			return (
				<section id={group.id} className="column-layout" key={group.id}>
					<a name={group.id} />
					<div className="letter-section-header section-header four-col">
						<div className="header-title">{group.id}</div>
					</div>
					{group.items.map((item, index) => {
						//logger.log('[AtoZ] getContent - %o', this.getDataObject(item));
						return <ContentLink data={this.getDataObject(item)} key={index} />;
					})}
				</section>
			);
		} else {
			return (
				<section id={group.id} className="column-layout" key={group.id}>
					<a name={group.id} />
				</section>
			);
		}
	}

	getDataObject(item) {
		let data = {
			style: 'one-col',
			title: item.title,
			text: item.title,
			images: {
				image: {
					url: item.images.medium,
				},
			},
			url: item.url,
		};

		return data;
	}

	render() {
		logger.log('[AtoZ] render - state:%o', this.state);

		let content = this.groupItems(this.state.data.content);

		return (
			<div className="content-list">
				{content.map((group, index) => {
					//logger.log('[SearchPage] render - item:%o', item);
					return this.getContent(group);
				})}
			</div>
		);
	}
}

export default AtoZ;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageNav from 'appdir/components/common-ui/PageNav';
import WimRedirect from 'appdir/components/general/WimRedirect';
// import deps from 'dependencies';
// import op from 'object-path';
// import isEqual from 'lodash/isEqual';

const TabNav = ({ data, bypassAppLink }) => {
	//logger.count('[TabNav] - render count');
	const [redirect, setRedirect] = useState();
	const [open, setOpen] = useState(undefined);
	let tabs = data.tabs;
	const location = useLocation();

	const dispatch = useDispatch();

	const clickHandler = val => {
		logger.log('[TabNav] clickHandler - val:%0', val);

		if (val.indexOf('http') == 0) {
			let url = val.split('?qs=');
			url = url[0] + '?qs=' + encodeURIComponent(url[1]);
			document.location.href = url;
		} else {
			setRedirect(val);
		}
	};

	useEffect(() => {
		logger.log('[TabNav] location:%o', location.pathname);
		setOpen(location.pathname);
	}, [data.key]);

	logger.log('[TabNav] data:%o open:%o tabs:%o', data, open, tabs);

	//logger.log('[TabNav] render redirect:%o open:%o', redirect, open);
	if (!tabs) {
		return null;
	} else if (redirect) {
		return <WimRedirect to={redirect} />;
	} else {
		return (
			<div className="tab-navigation">
				<PageNav
					menuItems={tabs}
					classes={tabs.length > 6 ? `${data.classes} smaller-text` : `${data.classes}`}
					isActive={open}
					clickFn={clickHandler}
					default={data.default || 'Sections'}
					useLink={true}
					noDD
					bypassAppLink={bypassAppLink}
				/>
			</div>
		);
	}
};

export default TabNav;

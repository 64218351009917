import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[MatchInsights] action.mount');
			let path = Config.scoringData.completedMatch;
			let data = {
				status: 'loaded',
				path
			};
			dispatch({ type: deps.actionTypes.MATCHINSIGHTS_CARD_MOUNT, data: data });
		});
	},
	unmount: params => (dispatch, getState, store) => {
		//logger.log('[MatchInsights] action.unmount');
		let data = {
			status: null,
		};
		dispatch({ type: deps.actionTypes.MATCHINSIGHTS_CARD_UNMOUNT, data: data });
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Carousel from 'appdir/components/common-ui/Carousel';
import StubBox from 'appdir/components/common-ui/StubBox';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: MobileAppsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MobileAppsPage'],
		stubs: state['Config'].stubPages,
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({});

class MobileAppsPage extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
		this.state = Object.assign({}, this.props);
		this.state['sliderOffset'] = 0;
		this.state['currImageIndex'] = 0;
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	scrollTo(i) {
		if (i >= 0 && i <= this.state['images']['image'].length - 1) {
			let newOffset = i == 0 ? 0 : -(i * 150);
			this.setState(prevState => {
				return { ...prevState, currImageIndex: i, sliderOffset: newOffset };
			});
		}
	}

	slideTo(newIndex) {
		if (newIndex >= 0 && newIndex <= this.state['images']['image'].length - 1) {
			let index = newIndex;
			let newOffset = index == 0 ? 0 : -(index * 100);
			this.setState(prevState => {
				return { ...prevState, currImageIndex: index, sliderOffset: newOffset };
			});
		}
	}

	renderSlide1(data) {
		return (
			<div className="slide">
				<div className="slideItem">
					<div className="slideTitle">Tickets</div>
					<div className="slideImage">
						<img src="http://assets.wimbledon.com/images/mobile/tickets.png" />
					</div>
				</div>
				<div className="slideItem">
					<div className="slideTitle">Live Scores</div>
					<div className="slideImage">
						<img src="http://assets.wimbledon.com/images/mobile/live_scores.png" />
					</div>
				</div>
			</div>
		);
	}

	renderSlide2(data) {
		return (
			<div className="slide">
				<div className="slideItem">
					<div className="slideTitle">Live Video</div>
					<div className="slideImage">
						<img src="http://assets.wimbledon.com/images/mobile/live_video.png" />
					</div>
				</div>
				<div className="slideItem">
					<div className="slideTitle">Draws</div>
					<div className="slideImage">
						<img src="http://assets.wimbledon.com/images/mobile/draws.png" />
					</div>
				</div>
			</div>
		);
	}

	renderNav(direction, clickFn) {
		return (
			<div
				onClick={() => {
					clickFn(direction);
				}}
				className={`arrow-wrapper-${direction}`}>
				<i className={`wim-icon-${direction}-arrow`} />
			</div>
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[MobileApps] componentWillReceiveProps - next:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		logger.log('[MobileApps] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'generic',
			title: 'Mobile Apps',
			shortTitle: 'Mobile Apps',
			scroll: false,
			metaTitle: 'Mobile Apps',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		if (!this.state.stubs) {
			return 'Loading..';
		}
		if (this.state.stubs.mobileapps.stub != 'stub') {
			let sliderContent = [
				{ renderItem: this.renderSlide1.bind(this) },
				{ renderItem: this.renderSlide2.bind(this) },
			];
			let currIndex = this.state['currImageIndex'];
			let navJSON = {
				leftNav: this.renderNav.bind(this),
				rightNav: this.renderNav.bind(this),
			};
			let showThumbnails = false;
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="column-layout no-padding left right">
						<div className="mobile-top-container">
							<div className="mobile iPhone">
								<div className="device-name">iPhone</div>
								<div className="device-image">
									<img alt="iPhone" src="http://assets.wimbledon.com/images/mobile/iphone.png" />
								</div>
								<div className="download-info">
									<WimLink to="https://itunes.apple.com/us/app/wimbledon-2018/id319284643">
										<img
											alt="iPhone"
											src="/assets/images/mobile/appStore.png"
											style={{ width: 'unset' }}
										/>
									</WimLink>
								</div>
							</div>
							<div className="mobile appleTV">
								<div className="device-name">Tablet</div>
								<div className="device-image">
									<img alt="appleTV" src="http://assets.wimbledon.com/images/mobile/ipad.png" />
								</div>
								{/* <div className="download-info">
									Please visit the Apple Store on your Apple TV and search for the Wimbledon TV app
								</div> */}
							</div>
							<div className="mobile android">
								<div className="device-name">Android</div>
								<div className="device-image">
									<img alt="android" src="http://assets.wimbledon.com/images/mobile/android.png" />
								</div>
								<div className="download-info">
									<WimLink to="https://play.google.com/store/apps/details?id=com.ibm.events.android.wimbledon">
										<img
											alt="google play store icon"
											src="/assets/images/mobile/googlePlayStore.png"
											style={{ width: 'unset' }}
										/>
									</WimLink>
								</div>
							</div>
						</div>
						<div className="mobile-bottom">
							<div className="featuresInclude">Features Include:</div>
							<div className="desktopView">
								<div className="slideItem">
									<div className="slideTitle">Tickets</div>
									<div className="slideImage">
										<img src="http://assets.wimbledon.com/images/mobile/tickets.png" />
									</div>
								</div>
								<div className="slideItem">
									<div className="slideTitle">Live Scores</div>
									<div className="slideImage">
										<img src="http://assets.wimbledon.com/images/mobile/live_scores.png" />
									</div>
								</div>

								<div className="slideItem">
									<div className="slideTitle">Live Video</div>
									<div className="slideImage">
										<img src="http://assets.wimbledon.com/images/mobile/live_video.png" />
									</div>
								</div>
								<div className="slideItem">
									<div className="slideTitle">Draws</div>
									<div className="slideImage">
										<img src="http://assets.wimbledon.com/images/mobile/draws.png" />
									</div>
								</div>
							</div>
							<div className="carousel">
								<Carousel
									attributes={{
										items: sliderContent,
										showThumbnails: showThumbnails ? true : false,
										showFullscreenButton: false,
										showPlayButton: false,
										showBullets: !showThumbnails ? true : false,
										showNav: !showThumbnails ? true : false,
										customNav: showThumbnails ? true : false,
										customNavHTML: navJSON,
										customBullets: false,
									}}
								/>
							</div>
							<div className="column-layout mobile-also">
								<div className="mobile-also-header">Also:</div>
								<div className="mobile-also-features-container">
									<div className="mobile-also-features">
										<ul role="list">
											<li role="listitem">Results</li>
											<li role="listitem">News</li>
											<li role="listitem">Photos</li>
											<li role="listitem">A-Z Guide</li>
										</ul>
										<ul role="list">
											<li role="listitem">Order of Play</li>
											<li role="listitem">Map</li>
											<li role="listitem">Player Info & Alerts</li>
											<li role="listitem">{values.matchInsightsTitle}</li>
										</ul>
										<ul>
											<li role="listitem">Tickets</li>
											<li role="listitem">The Wimbledon Channel</li>
											<li role="listitem">On Demand Video</li>
											<li role="listitem">Shop</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			header_propsData = {
				headerType: 'scores',
				title: 'Mobile Apps',
				shortTitle: 'Mobile Apps',
				scroll: false,
				metaTitle: 'Mobile Apps',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};

			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<StubBox attributes={{ message: this.state.stubs.mobileapps.text, style: 'centerV' }} />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileAppsPage);

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.ORDEROFPLAY_MOUNT:
			newState = {
				...state,
				...action.data,
				status: 'loaded',
			};

			logger.log('[OrderOfPlay] ORDEROFPLAY_MOUNT newState:%o', newState);
			return newState;
			break;
		case deps.actionTypes.ORDEROFPLAY_UNMOUNT:
			newState = {
				...state,
				...action.data,
				status: null,
			};

			logger.log('[OrderOfPlay] ORDEROFPLAY_MOUNT newState:%o', newState);
			return newState;
			break;
		case deps.actionTypes.ORDEROFPLAY_ALLMATCHES_PLAYED:
			newState = {
				...state,
				...action.data,
			};

			logger.log('[OrderOfPlay] ORDEROFPLAY_ALLMATCHES_PLAYED newState:%o', newState);
			return newState;
			break;
		default:
			return state;
	}
};

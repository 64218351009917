/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import op from 'object-path';
import Time from 'appdir/components/common-ui/Time';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import MIInfoIcon from './MIInfoIcon';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MIHeaderContents
 *
 * This is the components that shows text on top of the header image
 * on Match Insights page and IBM Power Index LB
 *
 * pass power-rankings as type in props for Power Index. default is MI
 * props.data.type == power-rankings
 *
 *  props = {
 *             data.type,
 *             data.headerTitle,
 *             data.headerSubTitle,
 *             data.headerDate,
 *           }
 *  type
 * -----------------------------------------------------------------------------
 */
const MIHeaderContents = props => {
	logger.log('[MIHeaderContents] constructor - props: %o', props);

	let { data } = props;
	let type = op.get(data, 'type', '');

	return (
		<div className={`contents-in-header-wrapper ${type}`}>
			<div className="contents-in-header">
				{type == 'power-rankings' ? getPowerRankingsContents(data) : getMIContents(data)}
				<hr className="short-line"></hr>

				{data.headerSubTitle ? <h4 className="sub-title">{data.headerSubTitle} </h4> : null}
				{props.children}
			</div>
		</div>
	);
};

const getMIContents = data => {
	return (
		<>
			{data.headerDate ? (
				<div className="contents-in-header-date">
					<h3>
						<Time epoch_ms={data.headerDate} format="ddd D MMM YYYY HH:mm z" options="upper" />
					</h3>
				</div>
			) : null}
			{data.headerTitle ? (
				<div className="contents-in-header-title">
					<h1>
						{data.headerTitle}
						{data.infoIcon ? <MIInfoIcon onInfoClickFn={data.onInfoClickFn} /> : null}
					</h1>
				</div>
			) : null}
		</>
	);
};

const getPowerRankingsContents = data => {
	return (
		<>
			{data.headerTitle ? (
				<div className="contents-in-header-title">
					<h1>{data.headerTitle}</h1>
				</div>
			) : null}
			{data.headerDate ? (
				<div className="contents-in-header-date">
					<h3>
						Updated <Time epoch_ms={data.headerDate} format="ddd D MMM YYYY HH:mm z" options="upper" />
						{/* {moment(data.headerDate)
							.tz(moment.tz.guess(true))
							.format('llll z')} */}
					</h3>
				</div>
			) : null}
		</>
	);
};

export default MIHeaderContents;

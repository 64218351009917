/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
// import { NavLink } from 'react-router-dom';
import isArray from 'lodash/isArray';
// import classNames from 'classnames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { isIE, deviceDetect } from 'react-device-detect';
import throttle from 'lodash/throttle';
import VideoBackground from './VideoBackground';
import WimLink from 'appdir/components/general/WimLink';
import BackgroundCarousel from 'appdir/components/common-ui/BackgroundCarousel';

/**
 * -----------------------------------------------------------------------------
 * React Component: PageHeader
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['PageHeader'],
		// controller: state['Controller'],
		userOpen: state['Controller']['sidepanel']['userOpen'],
		slamtrackerPanel: state['SlamtrackerPanel']['slamtrackerPanel'],
		pageHeaders: state['Config'].pageHeaders,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => {
		dispatch(deps.actions.PageHeader.mount());
	},
	setWindowSize: windowsize => dispatch(deps.actions.PageHeader.setWindowSize(windowsize)),
	sidepanelExpanded: expanded => dispatch(deps.actions.PageHeader.sidepanelExpanded(expanded)),
});

const b_image_ratio = 1.5;
const h_image_ratio = 1.55;
const j_image_ratio = 1.78;
const video_image_ratio = 1.78;

const mobileMatch = window.matchMedia('(max-width: 767px)');
const tabletMatch = window.matchMedia('(max-width: 1023px)');
const desktopMatch = window.matchMedia('(max-width: 1364px)');
const midDesktopMatch = window.matchMedia('(min-width: 1365px) and (max-width: 1600px)');
const largeDesktopMatch = window.matchMedia('(min-width: 1601px)');
const slamtrackerPanelMatch = window.matchMedia('(min-width: 767px) and (max-width: 1600px)');

const stMobileMatch = window.matchMedia('(max-width: 1141px)');
const stTabletMatch = window.matchMedia('(max-width: 1397px)');

const fixedHeaderTypes = [
	'match-archive',
	'match-archive-landing',
	'scores',
	'schedule',
	'live-comment',
	'powerindex',
	'match-insights',
	'player-profile',
];

// const desktopSidePanelMatch = window.matchMedia('(max-width: 1365px)');
// const desktopLarge = window.matchMedia('(max-width: 1601px)');
// const desktopXlarge = window.matchMedia('(max-width: 1964px)');

class PageHeader extends Component {
	constructor(props) {
		super(props);

		//logger.log('[PageHeader] constructor - props:%o, state:%o', props, this.state);

		this.pageWrapper = null; // the page wrapper
		this.pageHeader = null; // header of a page, includes bg image and title
		this.contentMain = null; // main content area of page
		this.contentFiltersScroll = null; // indicates top position of filters
		this.bgImage = null; // bg image div of the page header
		this.bgSpace = null; // bg space div
		this.titleElem = null; // title elem within the page header
		this.scrollElem = null; // element to check for header collapse during scroll, generally content-main
		this.hideIndicatorElem = null;
		this.pageTxt = null; // page title text (section title)
		this.contentTxt = null; // specific title related to particular page content
		this.headerGlobal = null; //reference to the global header
		this.video = null; //reference to bg video tag

		this.imageRatio = b_image_ratio;
		this.videoRatio = video_image_ratio;

		this.lastWindowSize = '';
		this.lastStWindowSize = '';
		this.fixedHeader = false;
		this.fixedBeforeRender = false;

		this.refreshImage = this.refreshImage.bind(this);
		this.checkWindowSizes = this.checkWindowSizes.bind(this);

		this.ieCheck = isIE || deviceDetect().browserName == 'IE';

		this.state = {
			...props,
		};

		this.positionChange = this.positionChange.bind(this);
		this.throttledPosChange = throttle(this.positionChange, 100, { trailing: true });
		this.onResize = this.onResize.bind(this);
		this.throttledResize = throttle(this.onResize, 100, { trailing: true });

		// logger.log('[PageHeader] constructor - state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[PageHeader] componentWillReceiveProps - nextProps: %o', nextProps);
		let imageSrc = this.state.imageSrc;
		let videoSrc = this.state.videoSrc;
		let type = this.state.headerType;
		let scroll = this.state.scroll;
		let pageHeaders = {};

		// check 2 values from config page_headers (imageSrc, scroll)
		if (nextProps['attributes'] && nextProps['attributes']['headerType']) {
			type = nextProps['attributes']['headerType'];

			if (nextProps['attributes'].hasOwnProperty('imageSrc') && nextProps['attributes']['imageSrc']) {
				imageSrc = nextProps['attributes']['imageSrc'];
			} else if (nextProps['pageHeaders']) {
				pageHeaders = nextProps['pageHeaders'][type];
				if (pageHeaders && pageHeaders['imageSrc']) {
					imageSrc = pageHeaders['imageSrc'];
				}
			}

			if (op.get(nextProps, 'attributes.videoSrc')) {
				videoSrc = nextProps['attributes']['videoSrc'];
			} else {
				videoSrc = null;
			}

			//logger.log('[PageHeader] componentWillReceiveProps - nextProps: %o scroll:%o', nextProps, nextProps['attributes']['scroll']);
			if (nextProps['attributes'].hasOwnProperty('scroll')) {
				scroll = nextProps['attributes']['scroll'];
			} else if (nextProps['pageHeaders']) {
				pageHeaders = nextProps['pageHeaders'][type];
				if (pageHeaders && pageHeaders['scroll']) {
					scroll = pageHeaders['scroll'];
				}
			}
		}

		if (!pageHeaders) {
			// logger.log('[PageHeader] no page headers in config, using props: %o', nextProps);
		}

		//logger.log('[PageHeader] componentWillReceiveProps - fixedBeforeRender:%o fixedHeader:%o', this.fixedBeforeRender, this.fixedHeader);
		// logger.log('[PageHeader] componentWillReceiveProps - slamtrackerPanel:%o', nextProps.slamtrackerPanel?.display);
		this.setState(prevState => ({
			...prevState,
			...pageHeaders,
			...nextProps['attributes'],
			userOpen: nextProps.userOpen,
			// slamtrackerOpen: nextProps.slamtrackerPanel?.display || false,
			imageSrc: imageSrc,
			videoSrc: videoSrc,
			scroll: scroll,
			status: 'loaded',
			windowSize: nextProps.windowSize,
			timelapseRefresh:
				nextProps.attributes.timelapseRefresh >= 2 ? nextProps.attributes.timelapseRefresh * 1000 : -1,
			slideInterval: nextProps.attributes.slideInterval >= 2 ? nextProps.attributes.slideInterval * 1000 : 6000,
		}));

		//logger.log('[PageHeader] componentWillReceiveProps - scroll:%o', scroll);
	}

	componentDidMount() {
		this.props.mount();
		window.addEventListener('scroll', this.throttledPosChange, false);
		window.addEventListener('resize', this.throttledResize, false);
		// logger.log('[PageHeader] componentDidMount - state:%o pageElem:%o', this.state, this.pageHeader);
		//logger.log('[PageHeader] componentDidMount');
	}

	componentWillUnmount() {
		// logger.log('[PageHeader] componentWillUnmount - state.scroll:%o', this.state.scroll);
		window.removeEventListener('scroll', this.throttledPosChange, false);
		window.removeEventListener('resize', this.throttledResize, false);

		this.pageWrapper = null;
		this.scrollElem = null;
		this.titleElem = null;
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PageHeader] componentDidUpdate - fixedHeader:%o state:%o', this.fixedHeader, this.state);

		//return;
		this.onSidepanel();

		if (this.state.timelapse && !this.timer && this.state.timelapseRefresh > 2000) {
			this.timer = setInterval(this.refreshImage, this.state.timelapseRefresh);
			this.refreshImage();
		}

		//logger.log('[PageHeader] componentDidUpdate - shortTitle:%o prevShortTitle:%o', this.state.shortTitle, prevState.shortTitle);
		//force position change if found new title
		if (this.state.shortTitle != prevState.shortTitle) {
			this.positionChange({ type: 'newpage' });
		}

		//try to recognize content changes that don't rerender header
		// like in draws page when change events
		if (this.state.metaTitle != prevState.metaTitle) {
			this.contentFiltersScroll = null;
		}
	}

	assignElements() {
		this.pageWrapper = document.querySelector('.page > .wrapper');
		this.scrollElem = document.querySelector(this.state.scrollElem);
		this.titleElem = document.querySelector(this.state.titleElem);
		this.hideIndicatorElem = document.querySelector(this.state.hideIndicatorElem);
		this.contentMain = document.querySelector('.content-main');
		this.contentFiltersScroll = document.querySelector('.content-filters-scroll');
		this.pageHeader = document.querySelector('.page-header');
		this.bgImage = document.querySelector('.backgroundImage');
		this.bgSpace = document.querySelector('.backgroundSpace');
		this.pageTxt = document.querySelector('.title-txt-page');
		this.contentTxt = document.querySelector('.title-txt-content');
		this.headerGlobal = document.querySelector('.header-global');
	}

	initElements() {
		this.assignElements();

		// logger.log('[PageHeader] initElements scrollElem:%o titleElem:%o', this.scrollElem, this.titleElem);

		this.onResize();
	}

	positionChange(event) {
		if (!event) {
			this.fixedBeforeRender = true;
			//logger.log('[PageHeader] positionChange - no event');
			return;
		}
		this.fixedBeforeRender = false;
		// logger.log(
		// 	'[PageHeader] positionChange - event:%o state:%o filters:%o',
		// 	event,
		// 	this.state,
		// 	this.contentFiltersScroll
		// );

		let checkHeight = this.state.attributes.minHeight ? this.state.attributes.minHeight : 73;
		let panelCheck = true;

		if (!this.titleElem || !this.scrollElem) {
			//this.assignElements();
		}

		if (!this.contentFiltersScroll) {
			this.contentFiltersScroll = document.querySelector('.content-filters-scroll');
		}

		//logger.log('[PageHeader] positionChange - tabletMatch.matches:%o', tabletMatch.matches);

		if (!mobileMatch.matches) {
			checkHeight = this.state.attributes.minHeight ? this.state.attributes.minHeight : 106;
			//panelCheck = false;
		}

		//decides if title element (header title) needs to be hidden based on position
		if (this.titleElem) {
			//logger.log('[PageHeader] scroll - titleElem - checkHeight:%o top:%o', checkHeight, this.titleElem.getBoundingClientRect().top);
			if (this.titleElem.getBoundingClientRect().top <= checkHeight && event.type != 'newpage') {
				this.titleElem.classList.add('hide');
				this.titleElem.classList.remove('show');
				if (this.contentTxt) {
					if (this.state.windowSize !== 'mobile') {
						this.pageTxt.classList.remove('show');
						this.contentTxt.classList.add('show');
					} else {
						this.pageTxt.classList.add('show');
						this.contentTxt.classList.remove('show');
					}
				}
			} else {
				this.titleElem.classList.add('show');
				this.titleElem.classList.remove('hide');
				if (this.contentTxt) {
					this.pageTxt.classList.add('show');
					this.contentTxt.classList.remove('show');
				}
			}
		}

		// sets fixed state when the scroll elem hits the check height (min height)
		if (this.state.scroll && this.scrollElem) {
			// logger.log(
			// 	'[PageHeader] scroll - scrollElem:%o top:%o checkHeight:%o',
			// 	this.scrollElem,
			// 	this.scrollElem.getBoundingClientRect().top,
			// 	checkHeight
			// );
			if (this.scrollElem.getBoundingClientRect().top <= checkHeight && event.type != 'newpage') {
				if (!this.pageWrapper.classList.contains('fixed')) {
					this.pageHeader.classList.add('fixed');
					this.headerGlobal.classList.add('fixed');
					this.pageWrapper.classList.add('fixed');
					this.fixedHeader = true;
					this.checkVideo();
					this.checkGallery();
				}
			} else {
				if (this.pageWrapper.classList.contains('fixed')) {
					this.pageHeader.classList.remove('fixed');
					this.headerGlobal.classList.remove('fixed');
					this.pageWrapper.classList.remove('fixed');
					this.fixedHeader = false;
					this.checkVideo();
					this.checkGallery();
				}
			}
		}

		if (this.state.scroll && this.contentFiltersScroll && this.contentMain) {
			//logger.log('[PageHeader] scroll - contentFiltersScroll - top:%o checkHeight:%o ', this.contentFiltersScroll.getBoundingClientRect(), checkHeight);
			if (this.contentFiltersScroll.getBoundingClientRect().top <= checkHeight) {
				if (!this.contentMain?.classList.contains('fixed')) {
					this.contentMain?.classList.add('fixed');
					//this.checkVideo();
					//this.checkGallery();
				}
			} else if (this.contentFiltersScroll.getBoundingClientRect().top > checkHeight) {
				if (this.contentMain?.classList.contains('fixed')) {
					this.contentMain?.classList.remove('fixed');
					//this.checkVideo();
					//this.checkGallery();
				}
			}
		} else if (!this.state.scroll && this.contentFiltersScroll && this.contentMain) {
			// logger.log(
			// 	'[PageHeader] scroll - contentFiltersScroll - top:%o checkHeight:%o ',
			// 	this.contentFiltersScroll.getBoundingClientRect(),
			// 	checkHeight
			// );
			if (this.contentFiltersScroll.getBoundingClientRect().top <= checkHeight) {
				if (!this.contentMain?.classList.contains('fixed')) {
					this.contentMain?.classList.add('fixed');
				}
			} else if (this.contentFiltersScroll.getBoundingClientRect().top > checkHeight) {
				if (this.contentMain?.classList.contains('fixed')) {
					this.contentMain?.classList.remove('fixed');
				}
			}
		}

		// hide/show variable list of elements based on position of specified hideIndicatorElem or titleElem
		if (this.hideIndicatorElem && this.state.hideElems) {
			this.state.hideElems.forEach(elem => {
				let domElem = document.querySelector(elem);
				if (domElem) {
					logger.log(
						'[PageHeader] scroll - elem:%o titleElem.top:%o hideElem.bottom:%o',
						elem,
						this.titleElem.getBoundingClientRect().top,
						domElem.getBoundingClientRect().bottom
					);
					if (
						panelCheck &&
						this.hideIndicatorElem.getBoundingClientRect().top < domElem.getBoundingClientRect().bottom
					) {
						domElem.classList.add('hide');
						domElem.classList.remove('show');
					} else {
						domElem.classList.add('show');
						domElem.classList.remove('hide');
					}
				}
			});
		} else if (this.titleElem && this.state.hideElems) {
			this.state.hideElems.forEach(elem => {
				let domElem = document.querySelector(elem);
				if (domElem) {
					// logger.log(
					// 	'[PageHeader] scroll - elem:%o titleElem.top:%o hideElem.bottom:%o',
					// 	elem,
					// 	this.titleElem.getBoundingClientRect().top,
					// 	domElem.getBoundingClientRect().bottom
					// );
					if (
						panelCheck &&
						this.titleElem.getBoundingClientRect().top < domElem.getBoundingClientRect().bottom
					) {
						domElem.classList.add('hide');
						domElem.classList.remove('show');
					} else {
						domElem.classList.add('show');
						domElem.classList.remove('hide');
					}
				}
			});
		}

		//if no title elem (like searchpage)
		else if (this.state.hideElems) {
			this.state.hideElems.forEach(elem => {
				let domElem = document.querySelector(elem);

				if (domElem && this.contentMain) {
					// logger.log(
					// 	'[PageHeader] scroll - domElem:%o hide:%o',
					// 	domElem,
					// 	this.contentMain.getBoundingClientRect().top < domElem.getBoundingClientRect().bottom
					// );
					if (this.contentMain.getBoundingClientRect().top < domElem.getBoundingClientRect().bottom) {
						domElem.classList.add('hide');
						domElem.classList.remove('show');
					} else {
						domElem.classList.add('show');
						domElem.classList.remove('hide');
					}
				}
			});
		}

		// check if page scrolled at all
		if (this.bgSpace) {
			if (this.bgSpace.getBoundingClientRect().top < 0) {
				this.bgImage.classList.add('scrolled');
			} else {
				this.bgImage.classList.remove('scrolled');
			}
		}
	}

	onResize(event) {
		//logger.log('[PageHeader] resize - event:%o', event);

		this.checkWindowSizes();

		if (this.pageHeader && this.pageWrapper) {
			let checkWidth = this.pageWrapper.getBoundingClientRect().width;
			let checkHeight = this.pageHeader.getBoundingClientRect().height;

			let videoBgElem;
			let imageBgElem;
			let galleryBgElems;

			// if using a video bg
			if (document.querySelector('.backgroundImage.video')) {
				videoBgElem = document.querySelector('.backgroundImage > video');
				imageBgElem = document.querySelector('.backgroundImage > img');
				//logger.log('[PageHeader] resize - using video bg');
			}
			// if has gallery
			if (document.querySelector('.image-gallery-image > img')) {
				galleryBgElems = document.querySelectorAll('.image-gallery-image > img');
				// logger.log('[PageHeader] resize - using video bg');
			}

			//check aspect ratio of holding space (pageWrapper)
			// logger.log('[PageHeader] resize - width:%o height:%o ratio:%o', checkWidth, checkHeight, (checkWidth / checkHeight));

			if (this.pageWrapper && checkWidth / checkHeight < this.imageRatio) {
				this.pageHeader.classList.add('fit-height');
				this.pageHeader.classList.remove('fit-width');
			} else {
				this.pageHeader.classList.add('fit-width');
				this.pageHeader.classList.remove('fit-height');
			}

			if (videoBgElem) {
				//logger.log('[PageHeader] resize - videobg %o', (this.pageWrapper && checkWidth / checkHeight < this.videoRatio));
				if (this.pageWrapper && checkWidth / checkHeight < this.videoRatio) {
					videoBgElem.classList.add('fit-height');
					videoBgElem.classList.remove('fit-width');
				} else {
					videoBgElem.classList.add('fit-width');
					videoBgElem.classList.remove('fit-height');
				}
			}

			if (imageBgElem) {
				if (this.pageWrapper && checkWidth / checkHeight < this.imageRatio) {
					imageBgElem.classList.add('fit-height');
					imageBgElem.classList.remove('fit-width');
				} else {
					imageBgElem.classList.add('fit-width');
					imageBgElem.classList.remove('fit-height');
				}
			}

			if (galleryBgElems && isIE) {
				if (this.pageWrapper && checkWidth / checkHeight < this.imageRatio) {
					forEach(galleryBgElems, function (value) {
						value.classList.add('fit-height');
						value.classList.remove('fit-width');
					});
				} else {
					forEach(galleryBgElems, function (value) {
						value.classList.add('fit-width');
						value.classList.remove('fit-height');
					});
				}
			}
		}

		if (event && event.detail != 99) {
			this.positionChange(event);
		} else {
			this.positionChange(null);
		}

		this.checkSidepanel();
		this.checkVideo();
	}

	onSidepanel() {
		this.checkSidepanel();
		this.onResize();
	}

	checkWindowSizes() {
		let size = '';
		let stSize = '';
		
		let stPanelOpen = this.props?.slamtrackerPanel?.display;
		if (mobileMatch.matches) {
			size = 'mobile';
		} else if (tabletMatch.matches) {
			size = 'tablet';
		} else if (desktopMatch.matches || largeDesktopMatch.matches || midDesktopMatch.matches) {
			size = 'desktop';
		}

		// logger.log('[PageHeader] checkWindowSizes - slamtrackerPanel:%o, stMobileMatch.matches:%o', stPanelOpen, stMobileMatch.matches);
		if ((!stPanelOpen && mobileMatch.matches) || (stPanelOpen && stMobileMatch.matches)) {
			stSize = 'mobile';
		} else if ((!stPanelOpen && tabletMatch.matches) || (stPanelOpen && stTabletMatch.matches)) {
			stSize = 'tablet';
		} else {
			stSize = 'desktop';
		}

		if (size !== this.lastWindowSize || stSize !== this.lastStWindowSize) {
			this.lastWindowSize = size;
			this.lastStWindowSize = stSize;
			this.props.setWindowSize({
				windowSize: size,
				stWindowSize: stSize //windowsize but adjusted for slamtracker panel open or not
			});
			// logger.log('[PageHeader] checkWindowSizes - size: ', size);
		}
	}

	checkSidepanel() {
		let wrapElem = document.querySelector('.site-wrap');
		let pageElem = document.querySelector('.page');

		//logger.log('[PageHeader] checkSidepanel - page:%o mid:%o large:%o', pageElem, midDesktopMatch.matches, largeDesktopMatch.matches);
		// logger.log(
		// 	'[PageHeader] checkSidepanel - slamtrackerOpen:%o slamtrackerPanelMatch:%o',
		// 	this.props.slamtrackerPanel,
		// 	// this.state.slamtrackerOpen,
		// 	slamtrackerPanelMatch.matches
		// );

		if (pageElem && !window.webviewPlatform) {
			if (
				(this.state.userOpen && midDesktopMatch.matches) ||
				// this.state.slamtrackerOpen
				(this.props.slamtrackerPanel?.display === true && midDesktopMatch.matches) ||
				largeDesktopMatch.matches
			) {
				wrapElem.classList.add('expanded');
				pageElem.classList.add('expanded');
				document.body.classList.add('expanded');
				this.props.sidepanelExpanded({ sidepanelExpanded: true });
			} else {
				wrapElem.classList.remove('expanded');
				pageElem.classList.remove('expanded');
				document.body.classList.remove('expanded');
				this.props.sidepanelExpanded({ sidepanelExpanded: false });
			}

			if (
				// this.state.slamtrackerOpen
				this.props.slamtrackerPanel?.display === true &&
				slamtrackerPanelMatch.matches &&
				!midDesktopMatch.matches
			) {
				wrapElem.classList.add('compressed');
				pageElem.classList.add('compressed');
				wrapElem.classList.remove('default');
				pageElem.classList.remove('default');
			} else {
				wrapElem.classList.remove('compressed');
				pageElem.classList.remove('compressed');
				wrapElem.classList.add('default');
				pageElem.classList.add('default');
			}

			if (isIE) {
				pageElem.classList.add('ie');
			} else {
				pageElem.classList.remove('ie');
			}
		}
	}

	checkVideo() {
		//if has a video bg, check size to start or stop
		if (this.state.videoSrc && !this.fixedBeforeRender) {
			this.video = document.querySelector('#videobg');
			if (this.video) {
				// logger.log(
				// 	'[PageHeader] checkVideo - fixedHeader:%o mobileMatch:%o tabletMatch:%o desktopMatch:%o',
				// 	this.fixedHeader,
				// 	mobileMatch.matches,
				// 	tabletMatch.matches,
				// 	desktopMatch.matches
				// );

				if (mobileMatch.matches || tabletMatch.matches) {
					if (this.video.src === this.video.dataset.src) {
						// logger.log('[PageHeader] checkVideo - stop video');
						this.video.src = '';
					}
				} else if (this.isDesktopSize() && !this.fixedHeader) {
					if (this.video.src !== this.video.dataset.src) {
						// logger.log('[PageHeader] checkVideo - start video');
						this.video.src = this.video.dataset.src;
					}
				} else if (this.fixedHeader) {
					if (this.video.src === this.video.dataset.src.trim()) {
						// logger.log('[PageHeader] checkVideo - stop video');
						this.video.src = '';
					}
				}
			}
		}
	}

	checkGallery() {
		if (this.refs.carousel) {
			// logger.log('[PageHeader] checkGallery - refs:%o tablet:%o desktop:%o', this.refs, tabletMatch.matches, desktopMatch.matches);
			if (tabletMatch.matches) {
				// logger.log('[PageHeader] checkGallery - tablet');
				this.refs.carousel.pause();
			} else if (this.isDesktopSize() && !this.fixedHeader) {
				this.refs.carousel.play();
			} else if (this.fixedHeader) {
				this.refs.carousel.pause();
			}
		}
	}

	isDesktopSize() {
		return desktopMatch.matches || largeDesktopMatch.matches || midDesktopMatch.matches;
	}

	refreshImage() {
		let now = moment().valueOf();
		let coeff = 1000 * 60 * 1;
		let rounded = Math.ceil(now / coeff) * coeff;
		let imageSrc = !isArray(this.state.imageSrc) ? [this.state.imageSrc] : this.state.imageSrc;

		let images = document.querySelectorAll('.backgroundImage > span > img');
		// logger.log('[PageHeader] refreshImage - images:%o', images);

		if (images[0]) {
			images[0].setAttribute('src', imageSrc[0] + '?' + rounded);
		}
	}

	render() {
		//logger.log('[PageHeader] render - windowSize:%o desktop:%o state:%o', this.state.windowSize, this.state.windowSize === 'desktop', this.state);
		//logger.log('[PageHeader] render - isIE:%o device:%o', isIE, JSON.stringify(deviceDetect()));
		//let size = this.state.stickyOn ? 'min' : '';

		if (this.props.attributes && this.props.attributes.isWebview) {
			return null;
		} else {
			let headerType = this.state.headerType;
			let fixedType = !this.state.scroll ? 'noscroll' : '';
			let tgcClass = this.props.tgc === true ? 'tgc' : '';
			let images = !isArray(this.state.imageSrc) ? [this.state.imageSrc] : this.state.imageSrc;
			let browserStyle = '';

			if (images.length > 1 && this.state.windowSize == 'mobile') {
				images = [images[0]];
			}

			if (this.ieCheck) {
				browserStyle = 'ie';
			}

			//logger.log('[PageHeader] render - headerType:%o fixedType:%o browserStyle:%o', headerType, fixedType, browserStyle);

			//header has not been set yet, return nothing
			if (headerType === 'unset') {
				return (
					<div className={`page-header ${headerType} `}>
						<div className="backgroundSpace" />
						<div className="backgroundImage" />
					</div>
				);
			}

			//video bg
			else if (this.state.videoSrc && !this.state.poster) {
				this.imageRatio = j_image_ratio;
				this.videoRatio = video_image_ratio;
				//logger.log('[PageHeader] render - videoBg');

				return (
					<div className={`page-header ${headerType} ${fixedType} ${tgcClass}`}>
						<div className="backgroundSpace">
							<img
								src={`${images[0]}`}
								onLoad={() => {
									this.initElements();
								}}
								alt="video page header background space"
							/>
						</div>
						<div className={`backgroundImage video`}>
							<VideoBackground
								display={this.state.windowSize === 'desktop'}
								videoSrc={this.state.videoSrc}
								imageSrc={images[0]}
							/>
							<img className="cur" src={`${images[0]}`} alt="video page header background" />
							<div className="border" />
						</div>
					</div>
				);
			}

			//timelapse bg
			else if (this.state.attributes.timelapse) {
				this.imageRatio = b_image_ratio;
				this.videoRatio = null;

				return (
					<div className={`page-header ${headerType} ${fixedType} ${tgcClass}`}>
						<div className={`backgroundSpace ${browserStyle}`}>
							<img
								src={`${images[0]}`}
								onLoad={() => {
									this.initElements();
								}}
								alt="timelapse page header background space"
							/>
						</div>
						<div className={`backgroundImage ${this.state.windowSize} ${browserStyle}`}>
							<img src={`${images[0]}`} alt="timelapse page header background" />
							<div className="border" />
						</div>
					</div>
				);
			}

			//image bg
			else if (images.length === 1) {
				this.imageRatio = b_image_ratio;
				this.videoRatio = null;

				let usesFixedType = fixedHeaderTypes.includes(headerType);

				if (fixedType && !usesFixedType) {
					return (
						<div className={`page-header ${headerType} ${fixedType} ${tgcClass}`}>
							<div className="backgroundSpace">
								<WimLink to={this.state.link} style={'white'} ariaHiddenElement title="hideAria">
									<img
										src={`${images[0]}`}
										onLoad={() => {
											this.initElements();
										}}
										tabIndex={-1}
										aria-hidden
										role="none"
									/>
								</WimLink>
							</div>
							<div className={`backgroundImage`} style={{ backgroundImage: `url(${images[0]})` }} />
						</div>
					);
				} else if (fixedType && usesFixedType) {
					return (
						<div className={`page-header ${headerType} scoresStyle`}>
							<div className="backgroundSpace">
								<WimLink to={this.state.link} style={'white'} ariaHiddenElement title="hideAria">
									<img
										tabIndex={-1}
										aria-hidden
										role="none"
										src={`${images[0]}`}
										onLoad={() => {
											this.initElements();
										}}
									/>
								</WimLink>
							</div>
							<div className={`backgroundImage`}>
								<div className="bg-holder" />
							</div>
						</div>
					);
				} else {
					return (
						<div className={`page-header ${headerType} ${fixedType} ${tgcClass}`}>
							<div className={`backgroundSpace ${browserStyle}`}>
								<WimLink to={this.state.link} style={'white'} ariaHiddenElement title="hideAria">
									<img
										tabIndex={-1}
										aria-hidden
										role="none"
										src={`${images[0]}`}
										onLoad={() => {
											this.initElements();
										}}
									/>
								</WimLink>
							</div>
							<div className={`backgroundImage ${this.state.windowSize} ${browserStyle}`}>
								<WimLink to={this.state.link} style={'white'} ariaHiddenElement title="hideAria">
									<img src={`${images[0]}`} tabIndex={-1} aria-hidden role="none" />
								</WimLink>
								<div className="border" />
							</div>
						</div>
					);
				}

				return image;
			}

			//gallery bg
			else if (images.length > 1) {
				// gallery
				this.imageRatio = b_image_ratio;
				return (
					<div className={`page-header ${headerType} ${fixedType} ${tgcClass}`}>
						<div className="backgroundSpace">
							<img
								alt="gallery page header background space"
								src={`${images[0]}`}
								onLoad={() => {
									this.initElements();
								}}
							/>
						</div>
						<div className={`backgroundImage gallery`}>
							<BackgroundCarousel
								attributes={{
									galleryImages: images,
									autoPlay: this.state.rotate,
									interval: this.state.slideInterval,
								}}
							/>
						</div>
					</div>
				);
			} else {
				//logger.log('[PageHeader] render - null');
				return null;
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const ContactThankYouWebview = (props) => {
 * -----------------------------------------------------------------------------
 */

const ContactThankYouWebview = () => {
	return (
		<div className="content-main column-layout thank-you-container">
			<div className="thank-you">
				<div className="heading">Thank you for contacting the AELTC</div>
				<div className="sub-heading">
					We receive a significant number of emails and we value your feedback. However, you will appreciate
					that due to this volume of correspondence, we are not able to respond directly to every enquiry.
				</div>
				<div className="urgent-contact">
					<div className="contact-message">
						If you have a significant issue you want to raise, we request that you contact us in writing to:
					</div>
					<div className="contact-details">
						The All England Lawn Tennis Club (Championships) Limited
						<br />
						Church Road, Wimbledon, LondonSW19 5A, EUK
					</div>
				</div>
				<div className="contact">Thank you for your interest in the AELTC and The Championships.</div>
			</div>
		</div>
	);
};

export default ContactThankYouWebview;

import deps from 'dependencies';

export default {
	selectImage: data => dispatch => {
		dispatch({ type: deps.actionTypes.GALLERY_SELECTIMAGE, data: data });
	},
	updateSliderOffSet: data => dispacth => {
		dispacth({ type: deps.actionTypes.GALLERY_UPDATESLIDEROFFSET, data: data });
	},
};

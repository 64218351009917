import React, { useEffect } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';

const AppPromo = props => {
	logger.log('[Tickets - AppPromo] props:%o', props);

	useEffect(() => {
		logger.log('[Tickets - AppPromo] useEffect - props:%o', props);
	}, []);

	return (
		<React.Fragment>
			<div className={props.isPopup ? 'app-promo-outer-cntr' : 'app-promo-outer-cntr two-col margin'}>
				<div className={props.isPopup ? 'app-promo-container' : 'app-promo-container-border'}>
					{props.isPopup ? (
						<div className="ticket-action-modal-close-btn">
							<CloseBtn closeFn={() => props.onClose()} />
						</div>
					) : null}

					<div className="ticket-action-modal-title">VIEW TICKETS IN APP</div>
					<div className="ticket-action-modal-divider-container">
						<div className="ticket-action-modal-title-divider" />
					</div>
					<div className={props.isPopup ? 'app-promo-txt-popup' : 'app-promo-txt'}>
						Please view your tickets in the app.
					</div>

					<div className="app-promo-background-shade" />
					<img src="/assets/images/tickets/AppDownload_Tix.png" className="app-promo-img" />
					<div className={props.isPopup ? 'app-promo-apple-download-popup' : 'app-promo-apple-download'}>
						<WimLink to="https://itunes.apple.com/us/app/wimbledon-2018/id319284643">
							<img alt="iPhone" src="/assets/images/mobile/appStore.png" style={{ width: 'unset' }} />
						</WimLink>
					</div>
					<div className={props.isPopup ? 'app-promo-android-download-popup' : 'app-promo-android-download'}>
						<WimLink to="https://play.google.com/store/apps/details?id=com.ibm.events.android.wimbledon">
							<img
								alt="google play store icon"
								src="/assets/images/mobile/googlePlayStore.png"
								style={{ width: 'unset' }}
							/>
						</WimLink>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default AppPromo;

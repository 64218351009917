import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import op from 'object-path';

const Headshot = props => {
	const Config = useSelector(state => state['Config']);
	const [state, setState] = useState({
		id: props.id,
		type: props.type
	});

	const renderRound = (id, bg) => {
		let url = !bg ? `${state.host}square_nobg/${id}.png` : `${state.host}square/${id}.jpg`
		let roundStyle = {
			"backgroundImage": `url(${url})`, 
			"backgroundSize": "cover",
			"aspectRatio": "1 / 1",
			"height": "100%",
			"borderRadius": "50%",
			"margin": "auto"
		}

		return (
			<div style={roundStyle}></div>
		)
	}

	const renderFull = (id, bg) => {
		let url = !bg ? `${state.host}wide_nobg/${id}.png` : `${state.host}ipad/${id}.jpg`
		let fullStyle = {
			"backgroundImage": `url(${url})`, 
			"backgroundSize": "cover",
			"aspectRatio": "1.4205 / 1",
			"height": "100%",
			"margin": "auto"
		}

		return (
			<div style={fullStyle}></div>
		)
	}

	useEffect(() => {
		const host = op.get(Config, 'otherData.playerImageHost', false);
		logger.log('[Headshot] useEffect host:%o', host);

		if (host != state.host) {
			setState({
				...state,
				host: host,
			});
		}
	}, [Config]);

	return (
		

		(
			props.type === 'round' && state.host ? (
				<>{renderRound(props.id, props.bg)}</>
			) : (
				props.type === 'full' && state.host ? (
					<>{renderFull(props.id, props.bg)}</>
				) : null
			)
		)
	);
};

export default Headshot;

import deps from 'dependencies';
import op from 'object-path';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[Tickets] action.mount - params:%o Tickets:%o', params, Tickets);

			let data = {
				tickets: {
					...params,
				},
			};

			logger.log('[Tickets] action.mount - data:%o', data);

			let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
			let account = op.get(parsedQs, 'acct', null);


			//logger.log('[Tickets] action.mount - data: %o', data);
			data.qualStart = Config.dateConfig.qualIsoStart;
			data.tournStart = Config.dateConfig.tournIsoStart;
			data.category = 's_tickets';
			data.selectAcct = account;

			logger.log('[Tickets] action.mount - data: %o', data);

			dispatch({
				type: deps.actionTypes.TICKETS_MOUNT,
				data: data,
			});
		});
	},

	callSpectator: (service, id) => (dispatch, getState, store) => {
		return deps.services.Tickets.callSpectator(service, id, dispatch, store);
	},

	callSpectatorAll: (service, accounts) => (dispatch, getState, store) => {
		return deps.services.Tickets.callSpectatorAll(service, accounts, dispatch, store);
	},

	callTicketActions: (service, id, data) => (dispatch, getState, store) => {
		return deps.services.Tickets.callTicketActions(service, id, data, dispatch, store);
	},

	update: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.TICKETS_UPDATE, data: data });
	},

	verifyTicketStatus: (data, id) => (dispatch, getState, store) => {
		return deps.services.Tickets.verifyTicketStatus(data, id, dispatch, store);
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.TICKETS_UNMOUNT });
	},
};

import MyWimbledonRedirect from './index';
import deps from 'dependencies';

export default {
	path: ['/:lang/mywimbledon/redirect'],
	exact: true,
	component: MyWimbledonRedirect,
	onRouteChange: () => {},
	load: params => deps.actions.MyWimbledonRedirect.mount(params),
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getQuerystringValues } from 'appdir/components/general/Util';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

import Template from 'appdir/components/Template';
// import { Link } from 'react-router-dom';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import SiteMap from 'appdir/components/general/SiteMap';
import PageHeader from 'appdir/components/general/PageHeader';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
	};
};

class MyWimbledonRedirect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};
		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		this.url = op.get(parsedQs, 'url', '')
		this.gtk = op.get(parsedQs, 'gtk', '')
	}

	componentDidMount() {
		//logger.log('[MyWimbledonRedirect] componentDidMount this %o', this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[Storyteller] getDerivedStateFromProps - prevState:%o nextProps:%o', prevState, nextProps);
		let newState = {...nextProps};
		newState = isEmpty(newState) ? null : newState;
		return newState;
	}
	

	render() {
		logger.log('[MyWimbledonRedirect] render url:%o state:%o ', this.url, this.state);

		if (this.state.loggedIn && this.url) {
			document.location.href = this.url;
		} 
	
		let header_propsData = {
			headerType: 'mywimbledon',
			title: 'myWimbledon',
			shortTitle: 'myW',
			metaTitle: 'myWimbledon',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main my-wimbledon">

					<div className="column-layout news no-padding top">
						
						<LoadingIndicator />
						
					</div>
				</div>
				<Footer />
			</Template>
		);
		
	}
}
export default connect(mapStateToProps)(MyWimbledonRedirect);

/**
<div>url:{this.url}</div>
<br/>
<div>gtk:{this.gtk}</div>
 */

import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';

class LoginWraper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		logger.log('[LoginWrapper] componentDidMount ');

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Tickets',
			loggedin: false,
		});
	}

	render() {
		let params = {
			screen: 'login_screen',
			register: false,
			social: false,
		};
		return <GigyaScreenSet params={params} inline={true} />;
	}
}

export default LoginWraper;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'appdir/components/common-ui/Button';
import { reconsentRole } from 'appdir/components/general/Util/Role';
import deps from 'dependencies';
import WimRedirect from 'appdir/components/general/WimRedirect';

const mapStateToProps = state => {
	return {
		myWimbledon: state['Config']['myWimbledon'],
	};
};

const mapDispatchToProps = dispatch => ({
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class MediaReconsent extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props, redirectLink: false };
		logger.log('[MediaReconsent] constructor - state:%o ', this.state);
	}

	showError() {
		this.props.showModal({
			showModal: true,
			showCloseBtn: true,
			type: 'error',
			title: 'Reconsent Error',
			message: 'There was an error processing your request. Please try again.',
			useTimer: true,
		});
	}

	onRoleReconsent() {
		let path = this.state.myWimbledon?.api?.roleRegReconsent;

		this.props.showModal({
			showModal: true,
			showCloseBtn: false,
			type: 'updating',
			title: 'SUBMITTING',
			message: 'Please do not close your browser window while reconsent request is in process.',
			useTimer: true,
		});

		this.props
			.getJWT()
			.then(token => {
				let jwt = token.jwt.id_token;
				reconsentRole(path, 'media', jwt)
					.then(resp => {
						logger.log('[MediaReconsent] onRoleReconsent success, response:', resp);
						let link = resp?.data?.link;
						if (link) {
							setTimeout(() => {
								this.openRoleRegistration(link);
							}, 3000);
						} else {
							this.showError();
						}
					})
					.catch(error => {
						logger.error('[MediaReconsent] onRoleReconsent error:', error);
						this.showError();
					});
			})
			.catch(error => {
				logger.error('[MediaReconsent] onRoleReconsent error:', error);
				this.showError();
			});
	}

	openRoleRegistration(link) {
		this.props.showModal({
			showModal: false,
			showCloseBtn: false,
			type: 'success',
			title: 'Success',
			message: '',
			useTimer: false,
		});
		this.setState({ redirectLink: link.replace(/https?:\/\/[^\/]+/i, '') });
	}

	render() {
		if (this.state.redirectLink) return <WimRedirect to={this.state.redirectLink} />;
		return (
			<div style={{ width: '100%', marginTop: '24px', textAlign: 'center', display: 'inline-grid' }}>
				Please register to re-enable your Media access.
				<Button
					role="button"
					alt="Media Registration, click here to re-enable your Media access"
					title="Media Registration, click here to re-enable your Media access"
					style={{ margin: '24px' }}
					onClick={() => this.onRoleReconsent()}>
					Media Registration
				</Button>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaReconsent);

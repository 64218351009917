/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import deps from 'dependencies';
import { countryLookup, countryLookupLowercase } from 'appdir/components/general/Util';
import { countryList, findCountry, findPlayerCountry} from 'appdir/components/general/Util/Convert';
import Flag from 'appdir/components/common-ui/Flag';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Countries
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
});

class Countries extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props
		};


		logger.log('[Countries] constructor - countries: %o', countryList);
		
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
		this.getList();
	}

	getTable() {
		return (
			<table className="tournament-table">
				<thead>
					<tr>
						<td>2 Letter</td>
						<td>3 Letter</td>
						<td>Number</td>
						<td>Wim Name</td>
						<td>ISO Name</td>
						<td>Flag Sm</td>
						<td>Flag Lg</td>
						<td>Flag SVG</td>
						<td>source</td>
					</tr>
				</thead>
				<tbody>
					{countryList.map((elem, i) => {
						//logger.log(`[Countries] find - ${elem.alpha2code}:%o ${elem.alpha3code}:%o`, findCountry(elem.alpha2code).alpha3code, border(elem.alpha3code).alpha2code);
						return (
							<tr key={`match_${elem.alpha2code}_${elem.source}_${i}`}>
								<td>{elem.alpha2code}</td>
								<td>{elem.alpha3code}</td>
								<td>{elem.number}</td>
								<td>{elem.wimName}</td>
								<td>{elem.isoName}</td>
								<td><img alt={elem.alpha3code} src={`https://assets.wimbledon.com/images/flags/${elem.alpha3code}_sm.gif`} /></td>
								<td><img alt={elem.alpha3code} src={`https://assets.wimbledon.com/images/flags/${elem.alpha3code}_h.gif`} /></td>
								<td><Flag code={elem.alpha3code} width="60px" border="true" /></td>
								<td>{elem.source}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	getList() {
		return (
			<div>
				{countryList.map(elem => {
						//logger.log('[Tournament Test] getMatchTable - elem:%o', elem);
						let playerCntry = findPlayerCountry(elem.alpha2code)
						logger.log('[Countries] getList - elem.alpha2code:%o player:%o source:%o', elem.alpha2code, playerCntry.alpha3code, playerCntry.source);
					})}
				
			</div>
		);
	}

	render() {

		return (
	

			<div className="content-main content ">
				
				<div className='column-layout content'>
					Countries
					{this.getTable()}
	
				</div>
				
			</div>
	
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Countries);



import React from 'react';
import WimLink from 'appdir/components/general/WimLink';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import CollapseBtn from 'appdir/components/common-ui/CollapseBtn';

import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';

const DrawsMenu = ({
	openMenu,
	className = '',
	closeMenu,
	collapseMenu,
	collapsed,
	show,
	children,
	playerInfo,
	profileLinks,
	isSlamtrackerActive,
	openSlamtracker,
	eventCode,
	match_id,
	showPathToTheFinal,
}) => {
	logger.log('[DrawsMenu] event:%o', eventCode);
	let showHide = show ? 'menu-wrapper' : 'menu-wrapper hide';
	let showLikelihood = (eventCode == 'LS' || eventCode == 'MS') && !window.webview;
	return (
		<div className={`${className} ${show ? 'menu-wrapper' : 'menu-wrapper hide'}`}>
			<div className="menu-container">
				<section className="menu-content">
					{closeMenu && <CloseBtn closeFn={() => closeMenu(playerInfo?.id)} />}
					{collapseMenu && (
						<CollapseBtn
							className=""
							collapseFn={collapseMenu}
							defaultDirection={collapsed ? 'up' : 'down'}
						/>
					)}
					{children ? (
						children
					) : (
						<div className={`content ${showLikelihood ? 'with-likelihood' : ''}`}>
							<div className="name-container">
								{/* <span className={`name`}>{playerInfo?.name}</span> */}
								<WimLink style={`name`} role="link" to={`${profileLinks}${playerInfo?.id}.html`}>
									{playerInfo?.name}
								</WimLink>
								<WimLink style={`icon`} role="link" to={`${profileLinks}${playerInfo?.id}.html`}>
									<i className="wim-icon-link-out" aria-hidden />
								</WimLink>
							</div>

							<div className="separator"></div>
							{
								showPathToTheFinal && (
									<ButtonLinkText
									className=""
									name="Path to the Final"
									onClick={() => showPathToTheFinal(playerInfo?.id)}>
									Path to the Final
								</ButtonLinkText>
								)
							}
							<WimLink style={`item`} role="link" to={`${profileLinks}${playerInfo?.id}.html`}>
								Player Profile
							</WimLink>
							{isSlamtrackerActive && showLikelihood && (
								<ButtonLinkText
									aria-label="Likelihood to Win"
									className={`item`}
									onClick={() => openSlamtracker(playerInfo?.id, match_id)}>
									Likelihood to Win
								</ButtonLinkText>
							)}
						</div>
					)}
				</section>
			</div>
		</div>
	);
};

export default DrawsMenu;

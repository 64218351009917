import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const loadPlayer = path => {
	let hdr = restHeaders();
	return (
		axios
			.get(path, { headers: hdr })
			.then(response => {
				if (response.status === 200) {
					return response.data;
				} else {
					throw new Error('invalid response code');
				}

				log('loadPlayer: response:%o', response);
				return response;
			})
			// .then(({data}) => data)
			.catch(error => {
				logger.warn('[PlayerPage] loadPlayer - %o', error.message);
				if (error) {
					return {};
				}
			})
	);
};

const getPlayerMatches = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			logger.warn('[PlayerPage] getPlayerMatches - %o', error.message);
			if (error) {
				return {};
			}
		});
};

const getRelatedContent = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			logger.warn('[PlayerPage] getRelatedContent - %o', error.message);
			if (error) {
				return {};
			}
		});
};

const getAllPlayerInfo = pathObj => {
	let hdr = restHeaders();
	let playerMatches = pathObj.playerMatchup;
	let playerInfo = pathObj.playerDetails;
	let playerRelated = pathObj.playerRelated;

	return axios.all([getPlayerMatches(playerMatches), loadPlayer(playerInfo), getRelatedContent(playerRelated)]);
};

const getMatchStatistics = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			log('getMatchStats: response:%o', response);
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error('invalid response code');
			}
			return response;
		})
		.catch(error => {
			logger.warn('[PlayerPage] getPlayerMatchStats - %o', error.message);
			if (error) {
				return {};
			}
		});
};

export default {
	loadPlayer,
	getPlayerMatches,
	getAllPlayerInfo,
	getMatchStatistics,
};

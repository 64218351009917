import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';

const useBookings = () => {
	const [bookingData, setBookingData] = useState(null);
	const courtBookings = useSelector(state => state.Config.member.api.courtBookings);
	const restaurantBookings = useSelector(state => state.Config.member.api.restaurantBookings);
	const groupVenueId = useSelector(state => state.Config.member.diningReservation.group.id);

	const cancelURL = useSelector(state => state.Config.member.api.cancelReservation);

	const updateBookingURL = useSelector(state => state.Config.member.api.updateBooking);

	const limitDate = moment(new Date()).format('yyyy-MM-DD');

	const venues = useSelector(state => state.Config.member.diningReservation.group.venues);

	const endpoints = [
		courtBookings,
		`${restaurantBookings}/${groupVenueId}?from_date=${limitDate}&limit=150&status=NOT_RECONCILED`,
	];
	// 	const endpoints = [courtBookings, `${restaurantBookings}/${venueId}?from_date=${limitDate}&limit=50&status=Booked`];

	const [showEmptyMsg, setShowEmptyMsg] = useState(false);

	const [showErrorMsg, setShowErrorMsg] = useState(false);

	const { loading, response, queryKey, invokeAll, invokeRequest, setLoading } = useAxios();

	const dispatch = useDispatch();

	const mergeAndSortByDate = (courtBookings, restaurantBookings) => {
		const courtData = courtBookings?.map(item => ({
			venueName: item.venueName,
			name:
				item?.players && item?.players.length
					? item?.players?.map((itemVal, index) => {
							let lastChar = index === item?.players.length - 1 ? '' : ', ';
							return `${itemVal.title}. ${itemVal.firstName} ${itemVal.lastName}${lastChar}`;
					  })
					: `Booked Tickets on ${moment(item?.dateCreated)?.format('ddd D MMMM YYYY')}`,
			date: `${moment(item?.dateStart).format('ddd D MMMM YYYY h:mma')} - ${moment(item?.dateEnd).format(
				'h:mma'
			)}`,
			location: item?.venueGroupName || item?.eventName,
			sortDate: moment(item?.dateStart),
			type: item?.presentationType?.toLowerCase(),
			size: item?.numPlayers,
			isMainGuest: item?.isMainGuest,
			url: item?.editLink || '',
		}));

		const restaurantData = restaurantBookings
			?.filter(item => {
				return (
					item.status.toUpperCase() != 'CANCELED' &&
					venues.filter(venue => {
						// make sure groupVenues ids contain item id
						return venue.venueId === item.venue_id;
					}).length
				);
			})
			.map(item => {
				let venueNames = null;
				let loc = '';
				let key = '';
				let services = '';
				if (Array.isArray(venues)) {
					//logger.log('[Booking] restaurantData - item:%o venues:%o ', item, venues);
					venueNames = venues.filter(v => {
						logger.log('[Booking] restaurantData - v:%o ', v);
						return v.venueId == item.venue_id;
					});
					loc = venueNames.length == 1 ? venueNames[0].name : '';
					key = venueNames.length == 1 ? venueNames[0].key : '';
					services = venueNames.length == 1 ? venueNames[0].services : '';
				}

				// match the shifts
				let shift_cat = item?.shift_category?.charAt(0) + item?.shift_category?.slice(1).toLowerCase();
				let venue_name = services?.filter(service => {
					return service?.shift?.toLowerCase() === shift_cat?.toLowerCase();
				})[0]?.name;
				return {
					venueName: venue_name,
					name: `${item.first_name} ${item.last_name}`,
					date: `${moment(item.real_datetime_of_slot, 'YYYY-MM-DD HH:mm:ss').format(
						'ddd D MMMM YYYY h:mma'
					)}`,
					location: loc,
					sortDate: moment(item.real_datetime_of_slot, 'YYYY-MM-DD HH:mm:ss'),
					type: 'restaurant',
					size: item.max_guests,
					note: item.notes,
					resId: item.id,
					venueId: item?.venue_id,
					key: key,
				};
			});

		let sortedArray = []; // initialize to empty array in case there is an error, setBookingData will return [] instead of nothing

		// add empty handling check
		if (!restaurantData || !courtData) {
			// show empty msg
			setShowEmptyMsg(true);
		} else {
			const mergedArray = [...restaurantData, ...courtData];
			sortedArray = mergedArray.sort((a, b) => moment(a.sortDate).diff(b.sortDate));
		}

		setBookingData(sortedArray);
	};

	const cancelBooking = (resId, venueId) => {
		const data = {
			venueId,
		};

		invokeRequest('cancel-booking', 'POST', {}, cancelURL, data, `/${resId}?venueOrGroupId=${venueId}`);
		dispatch(
			deps.actions.ConfirmationModal.toggleModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'Submitting Request',
				message: 'Submitting...',
				useTimer: false,
			})
		);
	};

	const updateBookingNotes = (venueId, notes, reservationId) => {
		const data = {
			venueId,
			notes,
			reservationId,
		};

		invokeRequest('update-booking-notes', 'POST', {}, updateBookingURL, data);
		dispatch(
			deps.actions.ConfirmationModal.toggleModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'Submitting Request',
				message: 'Submitting...',
				useTimer: false,
			})
		);
	};

	const invokeAllEndpoints = () => {
		setLoading(true);
		setTimeout(() => {
			invokeAll(endpoints, 'booking-page-data');
		}, 1000); // set timeout to give backend time to load per Andys comment in https://ix-gitlab.events.ibm.com/wimbledon/wimbledon-react2/-/issues/2798
	};

	useEffect(() => {
		invokeAllEndpoints();
	}, []);

	useEffect(() => {
		if (response && queryKey === 'booking-page-data') {
			const courtBookings = response?.[0]?.data?.response?.events;
			const restaurantBookings = response?.[1]?.data?.response?.data?.results;
			// add error handling check
			if (
				!response?.[0]?.data?.response ||
				(response?.[0]?.data?.response?.status && response?.[0]?.data?.response?.status !== 200)
			) {
				// show empty msg
				setShowErrorMsg(true);
			}
			// add empty handling check
			else if (!response?.[0]?.data?.response?.events || !response?.[1]?.data?.response?.data?.results) {
				// show empty msg
				setShowEmptyMsg(true);
			}
			mergeAndSortByDate(courtBookings, restaurantBookings);
		}

		if (response && queryKey === 'cancel-booking') {
			if (response && [400, 404, 409].includes(+response?.response.status)) {
				dispatch(
					deps.actions.ConfirmationModal.toggleModal({
						showModal: true,
						showCloseBtn: true,
						type: 'error',
						title: response.message,
						message: response.response.msg,
						useTimer: true,
						reloadFunc: () => invokeAllEndpoints(),
						okCallback: () => invokeAllEndpoints(), // reset booking data on callback once updated
					})
				);
			}
			if (+response?.response?.status === 200) {
				setTimeout(() => {
					// set timeout to give backend data a chance to update list before showing success message
					dispatch(
						deps.actions.ConfirmationModal.toggleModal({
							showModal: true,
							showCloseBtn: true,
							type: 'success',
							title: 'Booking Canceled',
							message: 'You have successfully canceled the booking.',
							useTimer: true,
							reloadFunc: () => invokeAllEndpoints(),
							okCallback: () => invokeAllEndpoints(), // reset booking data on callback once updated
						})
					);
				}, 3000);
			}
		} else if (response && queryKey === 'update-booking-notes') {
			if (response && +response?.response?.status !== 200) {
				dispatch(
					deps.actions.ConfirmationModal.toggleModal({
						showModal: true,
						showCloseBtn: true,
						type: 'error',
						title: response.message,
						message: response.response.msg,
						useTimer: true,
						reloadFunc: () => invokeAllEndpoints(),
						okCallback: () => invokeAllEndpoints(), // reset booking data on callback once updated
					})
				);
			}
			if (+response?.response?.status === 200) {
				setTimeout(() => {
					// set timeout to give backend data a chance to update list before showing success message
					dispatch(
						deps.actions.ConfirmationModal.toggleModal({
							showModal: true,
							showCloseBtn: true,
							type: 'success',
							title: 'Updated Booking Notes',
							message: 'You have successfully updated the booking notes.',
							useTimer: true,
							reloadFunc: () => invokeAllEndpoints(),
							okCallback: () => invokeAllEndpoints(), // reset booking data on callback once updated
						})
					);
				}, 3000);
			}
		}
	}, [JSON.stringify(response)]);

	return { bookingData, loading, cancelBooking, showEmptyMsg, invokeAllEndpoints, showErrorMsg, updateBookingNotes };
};

export default useBookings;

import React from 'react';
import { values } from 'appdir/main';

const PowerRanking = (props = {}) => {
	const { data = {} } = props;
	return (
		<div className="powerrank-container">
			<div className="value">{data.value}</div>
			<div className="label">{values.powerIndexTitle}</div>
		</div>
	);
};

export default PowerRanking;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

/**
 *
 * @param {String} path
 */
const fetch = path => {
	let hdr = restHeaders();
	logger.log('[ShopCarousel] services.fetch - path: %o', path);
	return axios
		.get(path, { headers: hdr })
		.then(({ data }) => data)
		.catch(error => {
			logger.log('[ShopCarousel] fetch - error:%o', error);
		});
};

export default { fetch };

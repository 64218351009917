/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import IBMwatsonx from 'appdir/components/pages/AICatchUp/elements/IBMwatsonx';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import { getAppLink } from 'appdir/components/general/Util';
import { getInterfaceLink } from 'appdir/components/general/Util/index.js';
import { values } from 'appdir/main';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: AICatchUpBox
 * 
 * currently used in slamtracker preview and summary tabs
 * 
 * props:
 *   players: header text (required)
 *   shot insight data: required
 *   stRelatedData: associated article data
 * -----------------------------------------------------------------------------
 */
const AICatchUpBox = props => {
    // logger.log('[AICatchUpBox] props:%o', props);
    const {stRelatedData, data, type} = props;
    const maxCount = useSelector((state) => state?.['Config']?.slamtracker?.matchInsightsMaxCount ?? 3);
    const dispatch = useDispatch();
    let headerText = type == 'preview' ? 'Preview' : 'Recap';
    let showArticle = type == 'recap' ? false : true;

	const goToArticle = (path, id) => {
        if (values.webview) {		
            // let cmsId = { id: id};
            // if(window.webviewPlatform == 'ios'){
            //     const interfaceLink = getInterfaceLink('news', cmsId);
            //     interfaceLink?.onclick();
            // }else{
                let link = getAppLink(path, '');
                location.href = link.url;
            // }
		} else if (path) {
            logger.log('[AICatchUpBox] goToArticle path:%o', path);
			if (props.windowSize == 'mobile') {
				dispatch(deps.actions.SlamtrackerPanel.hide());
			}
            dispatch(deps.actions.MainNav.navigate({path: path}));
		}
    }

    if(!data && (!stRelatedData || !showArticle))
        return null;

    const relatedDataType = type == 'preview' ? "match preview" : "match recap"; 
    let stArticleData = null;
    if(stRelatedData){
        stArticleData = stRelatedData.filter(item => {
            return item.subType == relatedDataType;
        })?.[0];
    }

    logger.log('[AICatchUpBox] stArticleData:%o', stArticleData);

    return (
        <>
            <div className={`catch-up-article ${!stArticleData || !showArticle ? 'noArticle' : ''} ${!data ? 'noBox' : ''}`}>
                {(stArticleData && (type == 'preview' || type == 'summary')) &&
                    <ButtonLinkText className="article-link" onClick={() => goToArticle(stArticleData?.url, stArticleData?.contentId) }>
                        <div className='article-image'>
                            <img src={stArticleData?.images?.[0]?.medium} alt={stArticleData?.description} />
                        </div>
                        <div className='article-title'>
                            <div className='text'>{stArticleData?.shortTitle}</div>
                        </div>
                    </ButtonLinkText>
                }
                {data &&
                    <div className="ai-catch-up-box green-border">
                        <div className="ai-catch-up-header">
                            <div className="title">{`Match ${headerText}`}</div>
                            <IBMwatsonx styleClasses='subtext'/>
                        </div>
                        <div className="ai-catch-up-content">
                            <ul>
                                {props?.data?.map((c, i) => {
                                    if (i < maxCount) {
                                        return (
                                            <li key={`sentence-${i}`}>{c.sentence}</li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </>
    )
};

export default AICatchUpBox;
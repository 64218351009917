/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Time from 'appdir/components/common-ui/Time';
import classNames from 'classnames';
// import { forceCheck } from 'react-lazyload';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ImageComponent
 * -----------------------------------------------------------------------------
 */
class LazyPlaceholder extends Component {
	constructor(props) {
		super(props);

		let attributes = props['attributes'];
		attributes['type'] = attributes['type'].toLowerCase();

		this.state = {
			...attributes,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		// forceCheck();
	}

	render() {
		//background image style
		// <div className="image-container" style={{ backgroundImage: `url(${attributes["images"]["small"]})` }} >

		//logger.log('[LazyPlaceholder] render - state:%o', this.state);

		let tileClasses = classNames(
			'lazy-placeholder',
			'news-tile',
			this.state.new,
			this.state.type + '-tag',
			this.state['col-style']
		);

		return (
			<div className={tileClasses} id={this.state.contentId}>
				<div className="image-container" />
				<div className="type-tag">&nbsp;</div>
				<div className="news-timestamp">&nbsp;</div>
				<div className="news-title">{this.state.title}</div>
			</div>
		);
	}
}

export default LazyPlaceholder;

import deps from 'dependencies';

export default {
    mount: params => (dispatch, getState, store) => {
		
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, ['text', 'edge_country']).then(Config => {
            logger.log('[AICatchUpHomeWebview] action.mount - params: %o', params);
            dispatch({
                type: deps.actionTypes.AICATCHUPHOMEWEBVIEW_LOAD,
                data: params,
            });
        });
    }
}
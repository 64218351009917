import deps from 'dependencies';

export default {
    toggleModal: data => (dispatch, getState, store) => {
        dispatch({ type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE, data: data });
    },
    togglerLoader: data => (dispatch, getState, store) => {
        dispatch({ type: deps.actionTypes.CONFIRMATION_MODAL_LOADING, data: data});
    },
    unUseTimer: () => dispatch => {
        dispatch({type: deps.actionTypes.CONFIRMATION_MODAL_UNUSE_TIMER});
    }
}
import React, { Component } from 'react';
import GenericError from './GenericError';
import { Redirect, withRouter } from 'react-router';

import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import SystemErrorContent from 'appdir/components/general/SystemErrorContent';

class ErrorBoundary extends Component {
	constructor(props) {
		logger.log('[ErrorBoundary] props:%o', props);

		super(props);
		this.state = {
			//...props,
			hasError: false,
		};
	}
	componentDidCatch(error, info) {
		logger.log('[ErrorBoundary] componentDidCatch - error:%o info:%o props:%o', error, info, this.props);

		if (this.props.showMessage) {
			this.setState({
				showMessage: true,
				error,
				info,
			});
		} else {
			this.setState({ hasError: true }, () => {
				this.props.callback ? this.props.callback(error, info) : null;
			});
		}
	}

	render() {
		if (this.state.showMessage) {
			//logger.log('[ErrorBoundary] props:%o', this.props);
			logger.log('[ErrorBoundary] render - props:%o state:%o', this.props, this.state);
			let header_propsData = {
				headerType: 'generic',
				title: 'Error',
				shortTitle: 'Error',
				titleElem: '.landing--header',
				pageTitle: 'Error',
			};

			let data = {
				error: this.state?.error,
				info: this.state?.info,
				route: this.props?.location?.pathname,
			};

			return (
				<section className={`wrapper`}>
					<Header attributes={header_propsData} />
					<MainNav />
					<PageHeader attributes={header_propsData} />
					<div className="content-main content ">
						<div className="column-layout content">
							<SystemErrorContent data={data} message={'test'} />
						</div>
					</div>
					<Footer />
				</section>
			);
		} else if (this.state.hasError) {
			return <GenericError message={this.props.message} klass={this.props.klass ? this.props.klass : ''} />;
		} else {
			return this.props.children;
		}
	}
}
export default ErrorBoundary;

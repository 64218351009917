/**
 *  Path To The Final Overlay Component
 *
 *  Based on the eventCode and playerId, fetch drawsData and drawsPath
 *  For 'MS' and 'LS', get Draws Analysis data -- not enabled for 2024
 *
 *  Redux state
 *  @prop {playerId}
 * 	@prop {event} ------  eventCode, e.g.) 'MS', 'LS'
 * 	@prop {display} ----- bool, true to display overlay
 * 	@prop {defaultTop} -- optional, when Draws page or Power Index page is scrolled down, store the position so the page doesnt jump to top
 */

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import { values } from 'appdir/main';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {
	getCurrentRound,
	isWinnerIsCompleted,
} from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { doMeasurement } from 'appdir/components/general/Analytics';

import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import CountryFlagImage from 'appdir/components/common-ui/CountryFlagImage';
import PlayerLink from 'appdir/components/common-ui/PlayerLink';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';
import PlayerImage from 'appdir/components/content/PlayerImage';
import DrawAnalysis from 'appdir/components/common-ui/DrawAnalysis';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PathToTheFinalStandalone from 'appdir/components/common-ui/PathToTheFinalStandalone';

const PathToTheFinal = props => {
	const Router = useSelector(state => state['Router']);
	const enabled = useSelector(state => state['Config']?.enabled);

	const pathToTheFinal = useSelector(state => state['PathToTheFinal']);
	const selectedEvent = pathToTheFinal?.event?.toUpperCase(); // seleted event - passed from requested action
	const playerId = pathToTheFinal?.playerId; // serlected player - passed from requested action

	const flagImagePath = useSelector(state => state['Config']?.otherData?.flagImagePathSmall);
	const playerProfileImagePath = useSelector(state => state['Config']?.otherData?.playerProfileImagePath);

	/** Tournament Data */
	const tournamentPaths = useSelector(state => state['Tournament']?.paths);
	const drawData = useSelector(state => state['Tournament']?.data?.draw, isEqual);
	const drawPath = useSelector(state => state['Tournament']?.data?.drawPath, isEqual);
	const drawAnalysisData = useSelector(state => state['Tournament']?.data?.drawAnalysis, isEqual);

	/** Common Data */
	// const playerStatusData = useSelector(state => state['CommonData']?.playerStatus, isEqual);

	const mobileMatch = window.matchMedia('(max-width: 767px)');
	const tabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
	const isMSLS = selectedEvent == 'MS' || selectedEvent == 'LS'; // in case drawAnalysis is not enabled, check MS and LS event separate from isAiDraws
	// const isAiDraws = isMSLS && enabled?.drawAnalysis;

	const [favs, setAppFavs] = useState(props?.appsData?.appFavs);

	const [open, setOpen] = useState(false);

	const dispatch = useDispatch();

	const eventIdLookUp = {
		MS: 'atp',
		LS: 'wta',
	};

	const page = pathToTheFinal?.metricsPageName ? pathToTheFinal?.metricsPageName : 'draws';

	/** mount the component and load Config */
	useEffect(() => {
		dispatch(deps.actions.PathToTheFinal.mount());

		return function cleanup() {
			document.body.classList.remove('modal-on-noscroll');
			document?.querySelector('.footer-global')?.classList.remove('hide');
			dispatch(deps.actions.PathToTheFinal.hidePathToTheFinal());
		};
	}, []);

	/** if there is no Tournament drawData or
	 *  Tournament drawsData's event ID is
	 *  different from requested event ID,
	 *  get draws info based on the DrawsPage selected event */
	useEffect(() => {
		if (
			tournamentPaths &&
			(isEmpty(drawData) || !drawData || drawData?.data?.eventId !== selectedEvent) &&
			pathToTheFinal?.status == 'loaded'
		) {
			dispatch(deps.actions.Tournament.getDraws({ event: selectedEvent }));
		}
	}, [selectedEvent, pathToTheFinal]);

	/** get the requested player's drawPath data
	 *  drawData is required to determin the event id
	 *  in the getDrawPath() action
	 *
	 *  MS and LS - get Draw Analysis and Player Status data
	 */
	useEffect(() => {
		if (
			drawData?.status == 'loaded' &&
			drawData?.data?.eventId &&
			drawData?.data?.eventId == selectedEvent &&
			pathToTheFinal?.status == 'loaded'
		) {

			/** get necessary Draw Analysis data only for MS and LS and Config.enabled.drawAnalysis == true */
			// if (isAiDraws) {
			// 	/** if drawAnalysis data for the player exists already, don't get it again */
			// 	if (drawAnalysisData?.data?.playerId !== playerId) {
			// 		dispatch(
			// 			deps.actions.Tournament.getDrawAnalysis({
			// 				eventId: selectedEvent,
			// 				playerId: playerId,
			// 			})
			// 		);
			// 	}
			// }

			/** if there is already drawPath data for the selected player, don't get it again */
			if (isEmpty(drawPath) || !drawPath || drawPath?.player?.idA !== playerId) {
				dispatch(deps.actions.Tournament.getDrawPath({ playerId }));
			}
		}
	}, [drawData, playerId, pathToTheFinal]);

	useEffect(() => {
		/** when pathtothefinal is set to display, add a class to the body to prevent
		 *  the main content to scroll
		 *  hide footer as footer's z-index is higher
		 */

		if (pathToTheFinal?.display && pathToTheFinal?.status == 'loaded') {
			document.body.classList.add('modal-on-noscroll');
			document?.querySelector('.footer-global')?.classList.add('hide');
			setOpen(true);
		} else {
			document.body.classList.remove('modal-on-noscroll');
			document?.querySelector('.footer-global')?.classList.remove('hide');
		}
	}, [pathToTheFinal?.display, pathToTheFinal?.status == 'loaded']);

	/** check for nav */
	useEffect(() => {
		logger.log('[PathToTheFinal] useEffect - open:%o Router:%o', open, Router);
		if (open) {
			logger.log('[PathToTheFinal] useEffect - Router:%o', Router);
			document.body.classList.remove('modal-on-noscroll');
			document?.querySelector('.footer-global')?.classList.remove('hide');
			dispatch(deps.actions.PathToTheFinal.hidePathToTheFinal());
		}
	}, [Router]);

	const closePathToTheFinal = () => {
		document.body.classList.remove('modal-on-noscroll');
		document?.querySelector('.footer-global')?.classList.remove('hide');

		let measureArg = [];
		let contextData = [{ action: 'Close' }];

		if (values.webview) {
			measureArg = ['Close'];
			contextData = [];
		}

		doMeasurement(`${pageName}`, 'Path To The Final', measureArg, contextData);

		dispatch(deps.actions.PathToTheFinal.hidePathToTheFinal());
	};

	const drawsFinalsPaths = drawPath?.data;
	const drawPathDataLoaded = drawPath?.status == 'loaded';
	const playerData = drawPath?.player;

	/** make sure drawAnalysisData in Tounament Data is for the selected player */
	const playerDrawAnalysisData = drawAnalysisData?.data?.playerId == playerId ? drawAnalysisData?.data : null;

	let currentRound = null;
	let drawDataMatches = drawData?.data?.matches;
	let showFinalColumn = isMSLS ? false : true;

	if (drawDataMatches) {
		currentRound = getCurrentRound(drawDataMatches);
		let isFinalScheduled = isWinnerIsCompleted(drawDataMatches);

		/** for MS and LS, check if the Final is scheduled in draws data */
		if (isMSLS && isFinalScheduled) {
			showFinalColumn = isFinalScheduled?.isTeam1Scheduled && isFinalScheduled?.isTeam2Scheduled ? true : false;
		}
	}

	logger.log(
		'[PathToTheFinal] pathToTheFinal:%o, props:%o, drawData:%o, drawPath:%o, flagImagePath:%o, currentRound:%o',
		pathToTheFinal,
		props,
		drawData,
		drawPath,
		flagImagePath,
		currentRound
	);

	// to always return type string event when s may be falsy other than empty-string
	const capitalize = s => {
		return (s && s[0].toUpperCase() + s.slice(1)) || '';
	};

	let pageName = capitalize(page);

	const isWebviewFav = () => {
		logger.log('[PathToTheFinal] - isWebviewFav - favs:%o, %o:%o', favs, playerId, favs?.indexOf(playerId) !== -1);
		return favs?.indexOf(playerId) !== -1;
	};

	const updateFavs = () => {
		let newFavs = Object.assign([], favs);
		let shouldAdd = true;
		let contextKey = 'favorite_add';

		logger.log('[PathToTheFinal] - updateFavs - favs:%o', favs);
		if (isWebviewFav()) {
			// remove fav
			newFavs = favs.filter(fav => fav !== playerId);
			shouldAdd = false;
			contextKey = 'favorite_remove';
		} else {
			// add fav
			newFavs.push(playerId);
		}
		// logger.log('[PathToTheFinal] - updateFavs - newFavs:%o',newFavs);
		let measureArg = [];
		let contextData = [{ item: 'Favorite' }, { action: shouldAdd ? 'Add' : 'Remove' }, { [contextKey]: playerId }];

		if (values.webview) {
			measureArg = ['Favorite', shouldAdd ? 'Add' : 'Remove'];
			contextData = [{ [contextKey]: playerId }];
		}
		doMeasurement(`${pageName}`, 'Path To The Final', measureArg, contextData);

		setAppFavs(newFavs);

		if (values.webview) {
			props?.appsData?.appFavCallback(playerId, shouldAdd);
		}
	};

	/**
	 *
	 * @returns Player's country flag, name, and rank or Power Index
	 */
	const renderPlayerInfo = () => {
		if (drawPathDataLoaded && playerData) {
			const playerB = playerData.displayNameB;
			const isDoubles = playerB && playerB !== '';
			const seed = playerData?.seed ? playerData.seed : '';

			return (
				<section className="player-wrapper">
					<div className="col">
						{values.webview ? null : (
							// <WimLink
							// 	to={
							// 		!isWebviewFav()
							// 			? `/addPlayerFav/${playerId}.html`
							// 			: `/removePlayerFav/${playerId}.html`
							// 	}
							// 	external={true}>
							// 	<span
							// 		tabIndex="0"
							// 		onClick={() => updateFavs()}
							// 		className={`favorite ${
							// 			isWebviewFav() ? 'favorite favorited purple' : 'favorite purple'
							// 		}`}></span>
							// </WimLink>
							<FavouriteStar
								id={playerId}
								type="players"
								unFavColor="purple"
								favColor="purple"
								alwaysShow={true}
							/>
						)}
						<PlayerImage
							attributes={{
								'player-image': playerProfileImagePath?.replace('<playerid>', playerId),
								style: 'small',
							}}
						/>
					</div>
					<div className="col">
						<div className="player-info">
							<div className="name">
								<CountryFlagImage
									attributes={{
										country: playerData.nationA,
										flagImagePath,
									}}
								/>
								<PlayerLink
									attributes={{
										playerId: playerData.idA,
										name: playerData.displayNameA,
									}}
								/>{' '}
								<span>{seed}</span>
							</div>

							{isDoubles && (
								<div className="name with">
									with &nbsp;
									<PlayerLink
										attributes={{
											playerId: playerData.idB,
											name: playerData.displayNameB,
										}}
									/>{' '}
									<span>{seed}</span>
								</div>
							)}
							{/** display Power Index or Rank - not both at the same time */
								/** if there is rank, display it */
								((playerData.singlesRank !== '') || 
								(isDoubles && playerData.doublesRank !== '')) && (
									<div className="rank">
										<span className="num">{ isDoubles  && playerData.doublesRank !== '' ? playerData.doublesRank : playerData.singlesRank}</span>{' '}
										{isDoubles && playerData.doublesRank !== ''  ? 'Doubles' : 'Singles'} Rank
									</div>
								)
							}
						</div>

						{/** display only on Tablet breakpoint */
						playerDrawAnalysisData && tabletMatch.match == true && (
							<ErrorBoundary message="">
								<DrawAnalysis
									attributes={{
										playerDrawAnalysisData,
										drawPathData: drawPath?.player?.idA == playerId ? drawPath : null,
										hideButton: true,
										style: '',
									}}
								/>
							</ErrorBoundary>
						)}
					</div>

					{/** display on all the breakpoints but Tablet */
					playerDrawAnalysisData && !tabletMatch.match && (
						<ErrorBoundary message="">
							<DrawAnalysis
								attributes={{
									playerDrawAnalysisData,
									drawPathData: drawPath?.player?.idA == playerId ? drawPath : null,
									hideButton: true,
									style: '',
									metricsPage: 'Draws',
								}}
							/>
						</ErrorBoundary>
					)}
				</section>
			);
		}
	};

	// const aiDrawsClass = isAiDraws ? 'ai-draws ' : '';
	const aiDrawsClass = "";

	return (
		<div className={`path-to-the-final-wrapper`}>
			<div className={`path-to-the-final ${aiDrawsClass}`}>
				<CloseBtn closeFn={closePathToTheFinal} className={''} />
				<h3>Path to the Final</h3>

				{drawPath?.status == 'error' ? (
					<div className="player-wrapper">
						Path to the Final for the selected player is not available at this time.{' '}
					</div>
				) : drawPath?.status == 'loading' || drawPath?.status !== 'loaded' ? (
					<section className="player-wrapper">
						<LoadingIndicator />
					</section>
				) : (
					<>
						{renderPlayerInfo()}

						{
							selectedEvent && playerId && (
								<PathToTheFinalStandalone event={selectedEvent} playerId={playerId} styleClass="full " />
							)
						}
					</>
				)}
			</div>
		</div>
	);
};

export default PathToTheFinal;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SLAMTRACKERPANEL_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;
		
		case deps.actionTypes.SLAMTRACKERPANEL_OPEN:
		case deps.actionTypes.SLAMTRACKERPANEL_CLOSE:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		case deps.actionTypes.SLAMTRACKERPANEL_VIDEO:
			logger.log('[SlamtrackerPanel] SLAMTRACKERPANEL_VIDEO:%o', action.data);
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		default:
			return state;
	}
};

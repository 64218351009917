import React, { useEffect } from 'react';
import Button from 'appdir/components/common-ui/Button';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';

const AppLink = props => {
	logger.log('[Tickets - AppLink] props:%o', props);

	useEffect(() => {
		logger.log('[Tickets - AppLink] useEffect - props:%o', props);
	}, []);

	return (
		<React.Fragment>
			<div className={props.isPopup ? 'app-promo-outer-cntr' : 'app-promo-outer-cntr two-col margin'}>
				<div className={props.isPopup ? 'app-promo-container' : 'app-promo-container-border'}>
					{props.isPopup ? (
						<div className="ticket-action-modal-close-btn">
							<CloseBtn closeFn={() => props.onClose()} />
						</div>
					) : null}

					<div className="ticket-action-modal-title">VIEW TICKETS IN APP</div>
					<div className="ticket-action-modal-divider-container">
						<div className="ticket-action-modal-title-divider" />
					</div>
					<div className={props.isPopup ? 'app-promo-txt-popup' : 'app-promo-txt'}>
						Please view your tickets in the app.
					</div>

					<div className="app-promo-background-shade" />
					<img src="/assets/images/tickets/AppDownload_Tix.png" className="app-promo-img" />
					<div className="app-link">
						<Button to={`/s/tickets/app`} className="solidWhite radius" style={{ width: '175px' }}>
							OPEN APP
						</Button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default AppLink;

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import React from 'react';
import op from 'object-path';
import axios from 'axios';
import { getRole } from 'appdir/components/general/Util/Role';

export const MENU_ITEMS = {
	mywimbledon: 'mywimbledon',
	news: 'news',
	mywimbledon: 'mywimbledon',
	scoresSchedule: 'scores',
	draws: 'draws',
	players: 'players',
	videoRadio: 'media',
	visitTickets: 'visit',
	about: 'about',
	foundation: 'foundation',
	shop: 'shop',
	home: 'home',
};
import uniq from 'lodash/uniq';
export const externalLinkRegex = /^(http|https|www)/;
export const startWWW = /^(www)/;
//add config for year
export const wimbledonLinkRegex = /^(?:(http|https)?:\/\/(2018|2019|2020|2021|www|grass|secure|www-cdt)\.?wimbledon.com)?/i;
// after 2019 can switch to year match to "\d{4}""
export const webviewRegex = /^\/webview\//;
export const playerRegex = /players\/overview\/([a-z0-9]*)\.html/;
export const newsRegex = /\/en_GB\/news\/articles\/.*.html/;
// export const drawsRegex = /\/en_GB\/draws\/.*/;
export const contentRegex = /\/en_GB\/(about_wimbledon|jobs|foundation|tickets|visit_and_tickets|media|learning|your_visit|foodanddrink|museum_and_tours|atoz|debentures|hospitality|aboutmywimbledon|draws_archive).*/;
export const galleryRegex = /\/en_GB\/news\/galleries\/.*.html/;
export const statsRegex = /\/en_GB\/scores\/stats\/(\d*)\.html/;
export const insightsRegex = /\/en_GB\/matchinsights\/(\d*)\.html/;
export const insightsIndexRegex = /\/en_GB\/matchinsights\/(index)\.html/;
export const powerrankRegex = /\/en_GB\/powerindex\/.*.html/;
export const videoRegex = /\/en_GB\/video\/media\/(.*).html/;
export const addMatchFavRegex = /\/removeMatchFav\/(\d*)\.html/;
export const removeMatchFavRegex = /\/addMatchFav\/(\d*)\.html/;
export const addArticleFavRegex = /\/removeArticleFav\/(.+).html/;
export const removeArticleFavRegex = /\/addArticleFav\/(.+).html/;
export const addPlayerFavRegex = /\/addPlayerFav\/(.+).html/;
export const removePlayerFavRegex = /\/removePlayerFav\/(.+).html/;
export const pdfRegex = /.*\.pdf/;

export const getCleanLink = url => {
	// make wimbledon links relative
	let newUrl = url.replace(wimbledonLinkRegex, '');
	//logger.log('[Utils] getCleanLink - url:%o newUrl:%o', url, newUrl);
	url = newUrl;

	// check if link is external
	let external = url.match(externalLinkRegex) ? url.match(externalLinkRegex).length > 0 : false;
	// make any pdf link external
	external = url.match(pdfRegex) ? url.match(pdfRegex).length > 0 : external;

	//logger.log('[Utils] getCleanLink - to:%o external:%o pdfext:%o', url, external, (url.match(pdfRegex)) );
	//fix links which only start with www
	url = url.match(startWWW) ? `//${url}` : url;

	//replace &amp; from cms generated content links for external urls
	if (external) {
		url = url.replace(/&amp;/g, '&');
	}

	return {
		url: url,
		external: external,
	};
};

export const getAppLink = (url, title = '', bypassAppLink = false) => {
	let playerMatch = url.match(playerRegex);
	let articleMatch = url.match(newsRegex);
	let contentMatch = url.match(contentRegex);
	let galleryMatch = url.match(galleryRegex);
	let slamtrackerMatch = url.match(statsRegex);
	let insightsMatch = url.match(insightsRegex);
	let insightsIndexMatch = url.match(insightsIndexRegex);
	let powerrankMatch = url.match(powerrankRegex);
	// let drawsMatch = url.match(drawsRegex);
	let videoMatch = url.match(videoRegex);
	let addMatchFav = url.match(addMatchFavRegex);
	let removeMatchFav = url.match(removeMatchFavRegex);
	let addArticleFav = url.match(addArticleFavRegex);
	let removeArticleFav = url.match(removeArticleFavRegex);
	let addPlayerFav = url.match(addPlayerFavRegex);
	let removePlayerFav = url.match(removePlayerFavRegex);

	//? `javascript:window.webkit.messageHandlers.player.postMessage('${playerMatch[1]}');`

	if (bypassAppLink) {
		/** to avoid multiple webview windows open up in app for deeper links,
		 * provide a way to bypass webview link and use simple to link navigating b/w webviews
		 */

		return {
			url: url,
			external: false,
			appLink: false
		};
	}
	// match favorite url
	else if (addMatchFav) {
		let link = {
			url: '',
			external: true,
			appLink: true,
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] addMatchFav - window.webkit.messageHandlers.addMatch.postMessage(%o)',
								addMatchFav[1]
							);
							window.webkit.messageHandlers.addMatch.postMessage(addMatchFav[1]);
					  }
					: () => {
							logger.info('[Util] addMatchFav - window.JSInterface.addMatch(%o)', addMatchFav[1]);
							window.JSInterface.addMatch(addMatchFav[1]);
					  },
		};
		//logger.info('[Util] addMatchFavRegex - match:%o, link:%o', addMatchFav, link);

		return link;
	} else if (removeMatchFav) {
		let link = {
			url: '',
			// url:
			// 	window.webviewPlatform == 'ios'
			// 		? `javascript:window.webkit.messageHandlers.removeMatch.postMessage('${removeMatchFav[1]}');`
			// 		: `javascript:window.JSInterface.removeMatch('${removeMatchFav[1]}');`,
			external: true,
			appLink: true,
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] removeMatchFav - window.webkit.messageHandlers.removeMatch.postMessage(%o)',
								removeMatchFav[1]
							);
							window.webkit.messageHandlers.removeMatch.postMessage(removeMatchFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] removeMatchFav - window.JSInterface.removeMatch(%o)',
								removeMatchFav[1]
							);
							window.JSInterface.removeMatch(removeMatchFav[1]);
					  },
		};
		//logger.info('[Util] removeMatchFavRegex - match:%o, link:%o', removeMatchFav, link);

		return link;
	}

	// Article favorite url
	else if (addArticleFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] addArticleFav - window.webkit.messageHandlers.addArticle.postMessage(%o)',
								addArticleFav[1]
							);
							window.webkit.messageHandlers.addArticle.postMessage(addArticleFav[1]);
					  }
					: () => {
							logger.info('[Util] addArticleFav - window.JSInterface.addArticle(%o)', addArticleFav[1]);
							window.JSInterface.addArticle(addArticleFav[1]);
					  },
			external: true,
			appLink: true
		};
		//logger.info('[Util] addArticleFavRegex - article:%o, link:%o', addArticleFav, link);

		return link;
	} else if (removeArticleFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] removeArticleFav - window.webkit.messageHandlers.removeArticle.postMessage(%o)',
								removeArticleFav[1]
							);
							window.webkit.messageHandlers.removeArticle.postMessage(removeArticleFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] removeArticleFav - window.JSInterface.removeArticle(%o)',
								removeArticleFav[1]
							);
							window.JSInterface.removeArticle(removeArticleFav[1]);
					  },
			external: true,
			appLink: true
		};
		//logger.info('[Util] removeArticleFavRegex - article:%o, link:%o', removeArticleFav, link);

		return link;
	}

	// Player favorite url
	else if (addPlayerFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] addPlayerFav - window.webkit.messageHandlers.addPlayerFavorite.postMessage(%o)',
								addPlayerFav[1]
							);
							window.webkit.messageHandlers.addPlayerFavorite.postMessage(addPlayerFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] addPlayerFav - window.JSInterface.addPlayerFavorite(%o)',
								addPlayerFav[1]
							);
							window.JSInterface.addPlayerFavorite(addPlayerFav[1]);
					  },
			external: true,
			appLink: true
		};
		//logger.info('[Util] addPlayerFavRegex - player:%o, link:%o', addPlayerFav, link);

		return link;
	} else if (removePlayerFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] removePlayerFav - window.webkit.messageHandlers.removePlayerFavorite.postMessage(%o)',
								removePlayerFav[1]
							);
							window.webkit.messageHandlers.removePlayerFavorite.postMessage(removePlayerFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] removePlayerFav - window.JSInterface.removePlayerFavorite(%o)',
								removePlayerFav[1]
							);
							window.JSInterface.removePlayerFavorite(removePlayerFav[1]);
					  },
			external: true,
			appLink: true
		};
		//logger.info('[Util] removePlayerFavRegex - player:%o, link:%o', removePlayerFav, link);

		return link;
	}

	// draws match
	/** the playerId query string enables Path to the Final overlay on the draws page */
	// else if (drawsMatch) {
	// 	//"/en_GB/draws/gentlemens-singles?playerId=atpte51"

	// 	let matchedVal = drawsMatch[0]?.split('?');
	// 	let path = matchedVal[0]; // /en_GB/draws/gentlemens-singles/full
	// 	let querystring = matchedVal[1]; // "playerId=wta1383"
	// 	let playerId = querystring?.split("=")?.[1];
	// 	let data = {
	// 		"type": "openDraw",
	// 		"data": {
	// 		   event: playerId.startsWith('atp') ? 'MS' : 'LS',
	// 		   path: '/webview' + path,
	// 		   querystring
	// 		}
	// 	 }

	// 	let link = {
	// 		url: '',
	// 		onclick: () => {
	// 						logger.info(
	// 							'[Util] draws  - passAppData(%o)',
	// 							data
	// 						);

	// 						passAppData(data);
	// 				  },
	// 		external: true,
	// 	};
	// 	logger.info('[Util] drawsMatchRegex - player:%o, link:%o', playerId, link);

	// 	return link;
	// }

	// match player url
	else if (playerMatch) {
		//logger.info('[Util] playerRegex - match:%o', playerMatch);
		return {
			url: `http://wimbledon/player?id=${playerMatch[1]}`,
			external: false,
			appLink: true
		};
	}

	// article match
	else if (articleMatch) {
		return {
			url: `${document.location.protocol}//${document.location.hostname}/webview${articleMatch[0]}?external=true`,
			external: true,
			appLink: false
		};
	}

	// slamtracker match
	else if (slamtrackerMatch) {
		return {
			url: `http://wimbledon/match?id=${slamtrackerMatch[1]}`,
			external: false,
			appLink: true
		};
	}

	// insights match
	else if (insightsMatch) {
		return {
			url: `http://wimbledon/matchinsights?matchid=${insightsMatch[1]}`,
			external: false,
			appLink: true
		};
	}

	// insights index
	else if (insightsIndexMatch) {
		let path = '';

		if (window.webviewPlatform == 'ios') {
			path = 'http://wimbledon/matchinsightsLanding';
		} else {
			path = 'http://wimbledon/matchinsights';
		}

		return {
			url: path,
			external: false,
			appLink: true
		};
	}

	// powerrank match
	else if (powerrankMatch) {
		return {
			url: `http://wimbledon/powerranking`,
			external: false,
			appLink: true
		};
	}

	// photo match
	else if (false) {
		return {
			url: url,
			external: false,
			appLink: false
		};
	}

	// gallery match
	else if (false) {
		return {
			url: url,
			external: false,
			appLink: false
		};
	}

	// video match
	else if (videoMatch) {
		return {
			url: `http://wimbledon/video?id=${videoMatch[1]}`,
			external: false,
			appLink: true
		};
	}

	// if a content link which can be handled by webview
	else if (contentMatch) {
		return {
			url: '/webview'.concat(url),
			external: false,
			appLink: false
		};
	}

	// if not handled by app or content webview, link externally
	else {
		if (url.indexOf('?') > 0) {
			url = url + '&external=true';
		} else {
			url = url + '?external=true';
		}

		return {
			url: url,
			external: true,
			appLink: false
		};
		// if (!url.match(webviewRegex) && !url.match(externalLinkRegex)) {
		// 	return {
		// 		url: '/webview'.concat(url),
		// 		external: false
		// 	};
		// }
		// else {
		// 	return {
		// 		url: url,
		// 		external: false
		// 	};
		// }
	}
};

export class DelayExecutor {
	constructor() {
		this.promise = Promise.resolve();
	}

	delay(fn, ms) {
		this.promise = this.promise.then(() => 
		new Promise(resolve => setTimeout(() => {
			fn();
			resolve();
		}, ms))
		);
		return this;
	}
}

/** 
JS Interface

Handled by apps (string is function we call)
	case player = "player" //Pass a player ID
    case news = "news" //Pass news ID or nil
    case map = "map" //No Value accepted, will just open map section
    case ticketsData = "tickets_data" //Message with tickets data
    case ticketsView = "tickets_view" //Message with ticket selection data
    case matchInsights = "matchInsights" //Gives a match ID or nil
    case matchInsightsLanding = "matchInsightsLanding" //Gives a match ID or nil
    case powerRanking = "powerRanking" //Gives a player ID or nil
    case video = "video" //Pass video ID
    case match = "match" //Pass a match ID, for use in scores/slamtracker
    case photo = "photo" //Pass photo ID
    case gallery = "gallery" //Pass gallery ID
    case share = "share" //Pass share URL
    case metrics = "metrics" //Pass metrics state text

    case addMatchFavorite = "addMatch" //Pass a match ID
    case removeMatchFavorite = "removeMatch" //Pass a match ID
    case removeArticleFavorite = "removeArticle" //Pass an article ID
    case addArticleFavorite = "addArticle" //Pass an article ID
    case addPlayerFavorite = "addPlayerFavorite" //Pass a player ID
    case removePlayerFavorite = "removePlayerFavorite" //Pass a player ID
    case webviewMessage = "webviewMessage" //Generic JS Message
   
    case wimbledonAppExit = "wimbledonappexit"
    case fontSize = "fontSize"
    case external = "external"
    case pdf = "pdf"
    case tel = "tel:"
    case mail = "mailto:"

	DATA EXAMPLE
	message modal:
		{
			title: 'Modal Title',
			text: 'modal text'
		}
*/


export const getInterfaceLink = (type, data) => {
	switch (type) {
		case 'map':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.map.postMessage('map') }
						: () => { window.JSInterface.map() }
			};
			break;

		case 'favouriteManage':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.favouriteManage.postMessage('manage') }
						: () => { window.JSInterface.favouriteManage() }
			};
			break;

		case 'video':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.video.postMessage(data.id) }
						: () => { window.JSInterface.map(data.id) }
			};
			break;

		case 'news':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.news.postMessage(data.id) }
						: () => { window.JSInterface.map(data.id) }
			};
			break;

		case 'catchup':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.catchup.postMessage(data.id) }
						: () => { window.JSInterface.catchup(data.id) }
			};
			break;

		case 'liveScores':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.liveScores.postMessage('scores') }
						: () => { window.JSInterface.liveScores('scores') }
			};
			break;

		case 'refreshUser':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.refreshUser.postMessage() }
						: () => { window.JSInterface.refreshUser() }
			};
			break;

		default:
			break;
	}
}

export const callInterfaceLink = (type, data) => {
	window.webkit.messageHandlers.refreshUser.postMessage()

	switch (type) {
		case 'refreshUser':
			return window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.refreshUser.postMessage() }
						: () => { window.JSInterface.refreshUser() }
			break;

		default:
			break;
	}
}

/** get local bst time and determine location of day summary
 *  move day summary card to bottom when play started for the day. The rule is:
	11pm BST - 10am BST - top / first
	10am BST - 11pm BST - bottom / last
**/
export const showSummaryFirst = () => {
	let bstHour = parseInt(moment().tz("Europe/London").format("HH"));
	let showSummaryFirst = bstHour <= 10 || bstHour >= 23; //between the hours of 11pm - 11am
	//showSummaryFirst = false;  //force testing
	logger.log('[Util showSummaryFirst - bstHour:%o, showSummaryFirst', bstHour, showSummaryFirst);
	return showSummaryFirst;
};


export const sortTicketData = (tickets, orderedCourts) => {
	// logger.log('[TicketsWebview] sortTicketData - tickets:%o', tickets);

	let sorted;
	let ordered_courts = orderedCourts.sort((a, b) => {
		return a.prestige - b.prestige;
	});

	sorted = tickets.sort((a, b) => {
		let c1 = ordered_courts.indexOf(op.get(a, 'detailedInformation.court', ''));
		let c2 = ordered_courts.indexOf(op.get(b, 'detailedInformation.court', ''));
		let d1 = a.source.event.startTime;
		let d2 = b.source.event.startTime;
		let r1 = op.get(a, 'detailedInformation.row', '');
		let r2 = op.get(b, 'detailedInformation.row', '');
		let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
		let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

		// sort by date
		if (d1 < d2) return -1;
		if (d1 > d2) return 1;
		// sort by court
		if (c1 < c2) return -1;
		if (c1 > c2) return 1;
		// sort by row
		if (r1 < r2) return -1;
		if (r1 > r2) return 1;
		// sort by seat
		if (s1 < s2) return -1;
		if (s1 > s2) return 1;

		return 0;
	});

	return sorted;
};



/**
 * take server time and return tournament day
 */
export const getTournamentDay = (time, tournStart) => {
	//logger.log('[Util] getTournamentDay - time:%o', time);
	//logger.log('[Util] getTournamentDay - tournStart:%o', tournStart);

	let checkTime = moment(time)
		.tz('Europe/London')
		.startOf('day');
	let start = moment(tournStart)
		.tz('Europe/London')
		.startOf('day');
	//logger.log('[Util] getTournamentDay - checkTime:%o', checkTime.format('ddd, DD MMM z - HH:mm'));
	//logger.log('[Util] getTournamentDay - startTime:%o', start.format('ddd, DD MMM z - HH:mm'));

	let difference = checkTime.diff(start, 'days') + 1;

	return difference;
};

/**
 * common function to determine if match has likelihood to win data
 * @param {*} data 
 */
export const hasLikelihoodToWin = (eventCode) => {
	return eventCode.toLowerCase() == 'ms' || eventCode.toLowerCase() == 'ls';
}

export const passAppData = data => {
	let stringData = JSON.stringify(data);
	logger.info('[Util] passAppData - data:%s', stringData);
	if (window.webviewPlatform == 'ios' && window.webkit) {
		window.webkit.messageHandlers.webviewMessage.postMessage(stringData);
	} else if (window.JSInterface) {
		window.JSInterface.webviewMessage(stringData);
	} else {
		logger.error('[Util] passAppData - could not pass data');
	}
};

export const getScrollPos = () => {
	let supportPageOffset = window.pageXOffset !== undefined;
	let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
	let scroll = {
		x: supportPageOffset
			? window.pageXOffset
			: isCSS1Compat
			? document.documentElement.scrollLeft
			: document.body.scrollLeft,
		y: supportPageOffset
			? window.pageYOffset
			: isCSS1Compat
			? document.documentElement.scrollTop
			: document.body.scrollTop,
	};

	return scroll;
};

export const restHeaders = () => {
	return {};
};

//determine image size to render based on device application is running on
export const getPlatformImageSize = platform => {
	switch (platform) {
		case 'mobile':
			return 'medium';
			break;
		case 'tablet':
			return 'large';
			break;
		case 'browser':
			return 'xlarge';
			break;
		default:
			return 'large';
	}
};

export const getComponents = types => {
	let cmps = {};

	types = typeof types === 'string' ? [types] : types;
	types.forEach(cname => {
		let req, cpath;
		
		let paths = [
			cname,
			`${cname}/index`,
			`components/${cname}`,
			`components/${cname}/index`,
			`components/cms/${cname}`,
			`components/cms/${cname}/index`,
			`components/common-ui/${cname}`,
			`components/common-ui/${cname}/index`,
			`components/data/${cname}`,
			`components/data/${cname}/index`,
			`components/general/${cname}`,
			`components/pages/PlayerDigitalExperience/elements/${cname}`,
			`components/pages/PlayerDigitalExperience/elements/${cname}/index`,
		];

		while (!req && paths.length > 0) {
			cpath = paths.shift();

			try {
				req = require('compdir/' + cpath);
			} catch (err) {
				//console.error('[App] getComponents err1 - %o:', err);
			}

			if (!req) {
				try {
					req = require(cpath + '');
					console.error('[App] getComponents found - path:%o', cpath);
				} catch (err) {
					//console.error('[App] getComponents err2 - path:%o err:%o:', cpath, err);
				}
			}

			if (req) {
				if (req.hasOwnProperty('default')) {
					req = req.default;
				}
				break;
			}
		}

		if (req) {
			cmps[cname] = req;
		}
	});

	return cmps;
};

//resusable data validation function for checking if right data type is returned
export const validateData = (data, dataType) => {
	//dataType map for returning true or false if first function parameter (data) matches second parameter (data type)
	let validDataMap = {
		array: {
			validate: d => {
				return Array.isArray(d);
			},
		},
		json: {
			validate: d => {
				let jsonData = typeof d !== 'string' ? JSON.stringify(d) : d;
				try {
					JSON.parse(jsonData);
					return true;
				} catch (e) {
					return false;
				}
			},
		},
		string: {
			validate: d => {
				return typeof d === 'string';
			},
		},
		boolean: {
			validate: d => {
				return typeof d === 'boolean';
			},
		},
		int: {
			validate: d => {
				return typeof d == 'number' && d % 1 === 0;
			},
		},
		float: {
			validate: d => {
				return typeof d == 'number' && d % 1 !== 0;
			},
		},
	};
	//return validation boolean of data and its assumed data type
	return validDataMap[dataType].validate(data);
};

export const isNumeric = data => {
	return !isNaN(parseFloat(data)) && isFinite(data);
};

/**
 * get unique list of component names from passed array
 * @param {array} list
 */
export const getComponentListArray = list => {
	let comps = [];
	logger.log('[Util] list:%o', list);
	if (validateData(list, 'array')) {
		Array.prototype.slice.call(list).forEach(item => {
			if (item.hasOwnProperty('type')) {
				comps.push(item['type']);
			} else {
				//logger.log('[Util] getComponentListArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	comps.push('WimLink');
	logger.log('[Util] getComponentListArray - %o:', comps);
	return comps;
};

/**
 * get list of attributes from passed array
 * @param {array} list
 */
export const getAttributesArray = list => {
	let attr = {};

	if (validateData(list, 'array')) {
		Array.prototype.slice.call(list).forEach(item => {
			if (item.hasOwnProperty('type')) {
				attr[item['reference']] = item;
			} else {
				//logger.log('[Util]  getAttributesArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		attr[list['reference']] = list;
	}
	//logger.log('[Util]  getAttributesArray - list: %o:', list);

	return attr;
};

/**
 * get parsed querystring object
 */
export const getQuerystringValues = (loc, allowPlus = false) => {
	let search = loc ? loc : location.search.replace(/^\?/, '');
	//logger.log('[Util] getQuerystringValues - loc: %o: search:%o', loc, '{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}');

	let params = {};
	search.split('&').forEach(function(pair) {
		pair = pair.split('=');
		if (pair[1] !== undefined) {
			let key = decodeURIComponent(pair[0]);
			let val = decodeURIComponent(pair[1]);
			if (!allowPlus) {
				val = val ? val.replace(/\++/g, ' ').trim() : '';
			}

			if (key.length === 0) {
				return;
			}
			if (params[key] === undefined) {
				params[key] = val;
			} else {
				if ('function' !== typeof params[key].push) {
					params[key] = [params[key]];
				}
				params[key].push(val);
			}
		}
	});

	return params;
};

/**
 * 
 * @param {*} jsx 
 * @returns jsx with links replaced
 */
export const replaceLinks = (jsx) => {
	logger.log('[Util] replaceLinks - jsx:%s', jsx);
	let result = jsx.match(/<a href="\/en_GB\/.*?a>/g);
	logger.log('[Util] replaceLinks - result:%s', result);
	if (result) {
		result.forEach(link => {
			let m = link.match(/href="(.*)">(.*)<\/a>/);
			jsx = jsx.replace(link, `<WimLink to="${m[1]}">${m[2]}</WimLink>`);
		});
	}
	//logger.log('[ContentPage] replaceLinks - jsx:%o', jsx);
	return jsx;
}

/**
 * appendEncodedString
 * @param {*} url - string or array of url's
 * @param {*} id - currentUser.UID
 * @returns url or array of urls with cachebusting
 */

export const appendEncodedString = (url, id) => {
	id = id.substring(0, 7);
	let encoded;
	let timeStamp = new Date().getTime();
	if (Array.isArray(url)) {
		encoded = url.map(i => {
			let query = i.includes('?') ? '&u=' : '?u=';
			let encoded =
				i +
				query +
				btoa(id + timeStamp)
					.replace(/\+/g, '-')
					.replace(/\//g, '_')
					.replace(/\=+$/, '');
			return encoded;
		});
	} else {
		let query = url.includes('?') ? '&u=' : '?u=';
		encoded =
			url +
			query +
			btoa(id + timeStamp)
				.replace(/\+/g, '-')
				.replace(/\//g, '_')
				.replace(/\=+$/, '');
	}
	return encoded;
};

export const getMenus = (parentThis, foundation = false) => {
	let menus = [];
	let roles = [];
	let menuData = foundation ? parentThis.state.foundation : parentThis.state.menus;

	if (parentThis.state.currentUser && parentThis.state.loggedIn && menuData) {
		let playerRole = getRole(op.get(parentThis.state.currentUser, 'roles', []), 1000050);
		let memberRole =
			parentThis.state.version > 2 && getRole(op.get(parentThis.state.currentUser, 'roles', []), 1000040);
		let mediaRole = getRole(op.get(parentThis.state.currentUser, 'roles', []), 1000250);
		roles.push(playerRole ? playerRole.roleId : null);
		roles.push(mediaRole ? mediaRole.roleId : null);
		parentThis.props.version >= 3 && roles.push(memberRole ? memberRole.roleId : null);
		menus = menuData.filter(menu => {
			if (menu.roleInclude && !roles.includes(menu.roleInclude)) {
				return null;
			} else if (menu.roleInclude && roles.includes(menu.roleInclude)) {
				return menu;
			} else if (menu.roleExclude && roles.includes(menu.roleExclude)) {
				return null;
			} else {
				return menu;
			}
		});
	} else if (menuData) {
		menus = menuData.filter(menu => {
			if (menu.roleInclude) {
				return null;
			} else {
				return menu;
			}
		});
	}

	return menus;
};
/**
 * get querystring param exists
 */
export const getQuerystringParam = (loc, val) => {
	let search = loc ? loc : location.search.replace(/^\?/, '');
	//logger.log('[Util] getQuerystringValues - loc: %o: search:%o', loc, '{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}');

	let found = false;
	search.split('&').forEach(function(pair) {
		pair = pair.split('=');
		//logger.log('[Util] getQuerystringParam - pair:%o val:%o', pair[0].toLowerCase(), val.toLowerCase());
		if (pair[0].toLowerCase() == val.toLowerCase()) {
			found = true;
		}
	});
	return found;
};

export const handleColumns = () => {
	//logger.log('[ContentPage] handleColumns - result: %o',);

	let elems = document.querySelectorAll('.content-main.column-layout.news > div');
	let pos = 0;
	let lastOpen = 0;

	if (elems && elems.length) {
		//logger.log('[ContentPage] handleColumns - elems: %o', elems);
		Array.prototype.slice.call(elems).forEach(function(elem, index) {
			if (
				elem.classList.contains('one-col') ||
				elem.classList.contains('two-col') ||
				elem.classList.contains('three-col') ||
				elem.classList.contains('four-col')
			) {
				// if a two col lands in postion more than 3rd, pad with one for next elem
				if (elem.classList.contains('two-col')) {
					if (lastOpen % 4 > 2) {
						//logger.info('[ContentPage] handleColumns - open:%o rem:%o', lastOpen, (lastOpen % 4));
						pos += 4 - (lastOpen % 4);
					}
				}
				// if a three col lands in postion more than 1st or 2nd col, pad with one for next elem
				if (elem.classList.contains('three-col')) {
					if (lastOpen % 4 > 1) {
						//logger.info('[ContentPage] handleColumns - open:%o rem:%o', lastOpen, (lastOpen % 4));
						pos += 4 - (lastOpen % 4);
					}
				}
				// if a four col lands in postion more than 1st, pad with one for next elem
				if (elem.classList.contains('four-col')) {
					if (lastOpen % 4 >= 1) {
						//logger.info('[ContentPage] handleColumns - open:%o rem:%o', lastOpen, (lastOpen % 4));
						pos += 4 - (lastOpen % 4);
					}
				}

				//logger.info('[ContentPage] handleColumns - pos:%o elem:%o', pos, elem);

				if (pos % 2 === 0) {
					elem.classList.add('clear-two');
				}
				if (pos % 4 === 0) {
					elem.classList.add('clear-four');
				}

				if (elem.classList.contains('one-col')) {
					pos += 1;
				} else if (elem.classList.contains('two-col')) {
					pos += 2;
				} else if (elem.classList.contains('three-col')) {
					pos += 3;
				} else if (elem.classList.contains('four-col')) {
					pos += 4;
				}

				//adjustments
				if (elem.classList.contains('margin') && !elem.classList.contains('four-col')) {
					pos += 1;
					lastOpen += 1;
				}

				lastOpen = pos;

				//logger.info('[ContentPage] handleColumns - elem:%o content:%o', elem.classList.value, elem.innerHTML);
			}
		});
	} else {
		//logger.info('[ContentPage] handleCoumns - elems:%o', elems);
	}
};

// Timer wrapper allowing timer pausing
export class Timer {
	constructor(id, cb, delay) {
		this.timerId = null;
		this.start = null;
		this.remaining = delay;
		this.callback = cb;
		this.name = id;

		//logger.info('**Timer - construct name:%o delay:%o remain:%o', this.name, delay, this.remaining);

		this.starttimer();
	}

	starttimer = () => {
		logger.info('*** Timer - start name:%o id:%o remain:%o', this.name, this.timerId, this.remaining);
		this.start = Date.now();
		window.clearTimeout(this.timerId);
		this.timerId = window.setTimeout(this.callback, this.remaining);
	};

	resume = add => {
		if (add) {
			this.remaining = this.remaining + add * 1000;
		}
		logger.info('*** Timer - resume name:%o id:%o remain:%o', this.name, this.timerId, this.remaining);
		this.start = Date.now();
		window.clearTimeout(this.timerId);
		this.timerId = window.setTimeout(this.callback, this.remaining);
	};

	pause = () => {
		if (this.timerId) {
			window.clearTimeout(this.timerId);
			this.remaining -= Date.now() - this.start;
			logger.info('*** Timer - pause name:%o id:%o remain:%o', this.name, this.timerId, this.remaining);
			this.timerId = null;
		}
	};

	clear = () => {
		window.clearTimeout(this.timerId);
		this.remaining -= Date.now() - this.start;
		//logger.info('**Timer - clear name:%o id:%o', this.name, this.timerId);
		window.clearTimeout(this.timerId);
	};
}

export const countryLookup = {
	AF: 'Afghanistan',
	AX: 'Aland Islands',
	AL: 'Albania',
	DZ: 'Algeria',
	AS: 'American Samoa',
	AD: 'Andorra',
	AO: 'Angola',
	AI: 'Anguilla',
	AQ: 'Antarctica',
	AG: 'Antigua And Barbuda',
	AR: 'Argentina',
	AM: 'Armenia',
	AW: 'Aruba',
	AU: 'Australia',
	AT: 'Austria',
	AZ: 'Azerbaijan',
	BS: 'Bahamas',
	BH: 'Bahrain',
	BD: 'Bangladesh',
	BB: 'Barbados',
	BY: 'Belarus',
	BE: 'Belgium',
	BZ: 'Belize',
	BJ: 'Benin',
	BM: 'Bermuda',
	BT: 'Bhutan',
	BO: 'Bolivia',
	BQ: 'Bonaire, Sint Eustatius and Saba',
	BA: 'Bosnia And Herzegowina',
	BW: 'Botswana',
	BV: 'Bouvet Island',
	BR: 'Brazil',
	IO: 'British Indian Ocean Territory',
	BN: 'Brunei Darussalam',
	BG: 'Bulgaria',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	KH: 'Cambodia',
	CM: 'Cameroon',
	CA: 'Canada',
	CV: 'Cape Verde',
	KY: 'Cayman Islands',
	CF: 'Central African Republic',
	TD: 'Chad',
	CL: 'Chile',
	CN: 'China',
	CX: 'Christmas Island',
	CC: 'Cocos (Keeling) Islands',
	CO: 'Colombia',
	KM: 'Comoros',
	CG: 'Congo',
	CD: 'Congo, The Democratic Republic Of The',
	CK: 'Cook Islands',
	CR: 'Costa Rica',
	CI: "Cote D'Ivoire",
	HR: 'Croatia (Hrvatska)',
	CU: 'Cuba',
	CW: 'Curaçao',
	CY: 'Cyprus',
	CZ: 'Czech Republic',
	DK: 'Denmark',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic',
	EC: 'Ecuador',
	EG: 'Egypt',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea',
	ER: 'Eritrea',
	EE: 'Estonia',
	ET: 'Ethiopia',
	FK: 'Falkland Islands (Malvinas)',
	FO: 'Faroe Islands',
	FJ: 'Fiji',
	FI: 'Finland',
	FR: 'France',
	GF: 'French Guiana',
	PF: 'French Polynesia',
	TF: 'French Southern Territories',
	GA: 'Gabon',
	GM: 'Gambia',
	GE: 'Georgia',
	DE: 'Germany',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GR: 'Greece',
	GL: 'Greenland',
	GD: 'Grenada',
	GP: 'Guadeloupe',
	GU: 'Guam',
	GT: 'Guatemala',
	GG: 'Guernsey',
	GN: 'Guinea',
	GW: 'Guinea-Bissau',
	GY: 'Guyana',
	HT: 'Haiti',
	HM: 'Heard And Mc Donald Islands',
	VA: 'Holy See (Vatican City State)',
	HN: 'Honduras',
	HK: 'Hong Kong SAR, China',
	HU: 'Hungary',
	IS: 'Iceland',
	IN: 'India',
	ID: 'Indonesia',
	IR: 'Iran (Islamic Republic Of)',
	IQ: 'Iraq',
	IE: 'Ireland',
	IM: 'Isle of Man',
	IL: 'Israel',
	IT: 'Italy',
	JM: 'Jamaica',
	JP: 'Japan',
	JE: 'Jersey',
	JO: 'Jordan',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KI: 'Kiribati',
	KP: "Korea Democratic People's Republic Of",
	KR: 'Korea Republic Of',
	KW: 'Kuwait',
	KG: 'Kyrgyzstan',
	LA: "Lao People's Democratic Republic",
	LV: 'Latvia',
	LB: 'Lebanon',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libyan Arab Jamahiriya',
	LI: 'Liechtenstein',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MO: 'Macao, China',
	MK: 'Macedonia Former Yugoslav Republic Of',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands',
	MQ: 'Martinique',
	MR: 'Mauritania',
	MU: 'Mauritius',
	YT: 'Mayotte',
	MX: 'Mexico',
	FM: 'Micronesia Federated States Of',
	MD: 'Moldova Republic Of',
	MC: 'Monaco',
	MN: 'Mongolia',
	ME: 'Montenegro',
	MS: 'Montserrat',
	MA: 'Morocco',
	MZ: 'Mozambique',
	MM: 'Myanmar',
	NA: 'Namibia',
	NR: 'Nauru',
	NP: 'Nepal',
	NL: 'Netherlands',
	NC: 'New Caledonia',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigeria',
	NU: 'Niue',
	NF: 'Norfolk Island',
	MP: 'Northern Mariana Islands',
	NO: 'Norway',
	OM: 'Oman',
	PK: 'Pakistan',
	PW: 'Palau',
	PS: 'Palestine Territories',
	PA: 'Panama',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru',
	PH: 'Philippines',
	PN: 'Pitcairn',
	PL: 'Poland',
	PT: 'Portugal',
	PR: 'Puerto Rico',
	QA: 'Qatar',
	RE: 'Reunion',
	RO: 'Romania',
	RU: 'Russian Federation',
	RW: 'Rwanda',
	BL: 'Saint Barthélemy',
	KN: 'Saint Kitts And Nevis',
	LC: 'Saint Lucia',
	MF: 'Saint Martin',
	VC: 'Saint Vincent And The Grenadines',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'Sao Tome And Principe',
	SA: 'Saudi Arabia',
	SN: 'Senegal',
	RS: 'Serbia',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SX: 'Sint Maarten (Dutch part)',
	SK: 'Slovakia (Slovak Republic)',
	SI: 'Slovenia',
	SB: 'Solomon Islands',
	SO: 'Somalia',
	ZA: 'South Africa',
	GS: 'South Georgia South Sandwich Islands',
	SS: 'South Sudan',
	ES: 'Spain',
	LK: 'Sri Lanka',
	SH: 'St. Helena',
	PM: 'St. Pierre And Miquelon',
	SD: 'Sudan',
	SR: 'Suriname',
	SJ: 'Svalbard And Jan Mayen Islands',
	SZ: 'Swaziland',
	SE: 'Sweden',
	CH: 'Switzerland',
	SY: 'Syrian Arab Republic',
	TW: 'Taiwan, China',
	TJ: 'Tajikistan',
	TZ: 'Tanzania United Republic Of',
	TH: 'Thailand',
	TL: 'Timor-Leste (East Timor)',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad And Tobago',
	TN: 'Tunisia',
	TR: 'Turkey',
	TM: 'Turkmenistan',
	TC: 'Turks And Caicos Islands',
	TV: 'Tuvalu',
	UG: 'Uganda',
	UA: 'Ukraine',
	AE: 'United Arab Emirates',
	GB: 'United Kingdom',
	US: 'United States',
	UM: 'United States Minor Outlying Islands',
	UY: 'Uruguay',
	UZ: 'Uzbekistan',
	VU: 'Vanuatu',
	VE: 'Venezuela',
	VN: 'Viet Nam',
	VG: 'Virgin Islands (British)',
	VI: 'Virgin Islands (U.S.)',
	WF: 'Wallis And Futuna Islands',
	EH: 'Western Sahara',
	YE: 'Yemen',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
	OT: 'Other',
	// return countryCodeMap[countryCode];
};

export const countryLookupLowercase = {
	af: 'Afghanistan',
	ax: 'Aland Islands',
	al: 'Albania',
	dz: 'Algeria',
	as: 'American Samoa',
	ad: 'Andorra',
	ao: 'Angola',
	ai: 'Anguilla',
	aq: 'Antarctica',
	ag: 'Antigua and Barbuda',
	ar: 'Argentina',
	am: 'Armenia',
	aw: 'Aruba',
	au: 'Australia',
	at: 'Austria',
	az: 'Azerbaijan',
	bs: 'Bahamas',
	bh: 'Bahrain',
	bd: 'Bangladesh',
	bb: 'Barbados',
	by: 'Belarus',
	be: 'Belgium',
	bz: 'Belize',
	bj: 'Benin',
	bm: 'Bermuda',
	bt: 'Bhutan',
	bo: 'Bolivia',
	bq: 'Bonaire, Sint Eustatius and Saba',
	ba: 'Bosnia and Herzegowina',
	bw: 'Botswana',
	bv: 'Bouvet Island',
	br: 'Brazil',
	io: 'British Indian Ocean Territory',
	bn: 'Brunei Darussalam',
	bg: 'Bulgaria',
	bf: 'Burkina Faso',
	bi: 'Burundi',
	kh: 'Cambodia',
	cm: 'Cameroon',
	ca: 'Canada',
	cv: 'Cape Verde',
	ky: 'Cayman Islands',
	cf: 'Central African Republic',
	td: 'Chad',
	cl: 'Chile',
	cn: 'China',
	cx: 'Christmas Island',
	cc: 'Cocos (keeling) Islands',
	co: 'Colombia',
	km: 'Comoros',
	cg: 'Congo',
	cd: 'Congo, The Democratic Republic Of The',
	ck: 'Cook Islands',
	cr: 'Costa Rica',
	ci: "cote D'Ivoire",
	hr: 'Croatia (Hrvatska)',
	cu: 'Cuba',
	cw: 'Curaçao',
	cy: 'Cyprus',
	cz: 'Czech Republic',
	dk: 'Denmark',
	dj: 'Djibouti',
	dm: 'Dominica',
	do: 'Dominican Republic',
	ec: 'Ecuador',
	eg: 'Egypt',
	sv: 'El salvador',
	gq: 'Equatorial guinea',
	er: 'Eritrea',
	ee: 'Estonia',
	et: 'Ethiopia',
	fk: 'Falkland Islands (malvinas)',
	fo: 'Faroe Islands',
	fj: 'Fiji',
	fi: 'Finland',
	fr: 'France',
	gf: 'French guiana',
	pf: 'French polynesia',
	tf: 'French southern territories',
	ga: 'Gabon',
	gm: 'Gambia',
	ge: 'Georgia',
	de: 'Germany',
	gh: 'Ghana',
	gi: 'Gibraltar',
	gr: 'Greece',
	gl: 'Greenland',
	gd: 'Grenada',
	gp: 'Guadeloupe',
	gu: 'Guam',
	gt: 'Guatemala',
	gg: 'Guernsey',
	gn: 'Guinea',
	gw: 'Guinea-bissau',
	gy: 'Guyana',
	ht: 'Haiti',
	hm: 'Heard and mc donald Islands',
	va: 'Holy see (vatican city state)',
	hn: 'Honduras',
	hk: 'Hong kong sar, china',
	hu: 'Hungary',
	is: 'Iceland',
	in: 'India',
	id: 'Indonesia',
	ir: 'Iran (islamic Republic of)',
	iq: 'Iraq',
	ie: 'Ireland',
	im: 'Isle of man',
	il: 'Israel',
	it: 'Italy',
	jm: 'Jamaica',
	jp: 'Japan',
	je: 'Jersey',
	jo: 'Jordan',
	kz: 'Kazakhstan',
	ke: 'Kenya',
	ki: 'Kiribati',
	kp: "Korea Democratic People's Republic Of",
	kr: 'Korea Republic of',
	kw: 'Kuwait',
	kg: 'Kyrgyzstan',
	la: "Lao People's Democratic Republic",
	lv: 'Latvia',
	lb: 'Lebanon',
	ls: 'Lesotho',
	lr: 'Liberia',
	ly: 'Libyan Arab Jamahiriya',
	li: 'Liechtenstein',
	lt: 'Lithuania',
	lu: 'Luxembourg',
	mo: 'Macao, China',
	mk: 'Macedonia Former Yugoslav Republic Of',
	mg: 'Madagascar',
	mw: 'Malawi',
	my: 'Malaysia',
	mv: 'Maldives',
	ml: 'Mali',
	mt: 'Malta',
	mh: 'Marshall Islands',
	mq: 'Martinique',
	mr: 'Mauritania',
	mu: 'Mauritius',
	yt: 'Mayotte',
	mx: 'Mexico',
	fm: 'Micronesia Federated States Of',
	md: 'Moldova Republic Of',
	mc: 'Monaco',
	mn: 'Mongolia',
	me: 'Montenegro',
	ms: 'Montserrat',
	ma: 'Morocco',
	mz: 'Mozambique',
	mm: 'Myanmar',
	na: 'Namibia',
	nr: 'Nauru',
	np: 'Nepal',
	nl: 'Netherlands',
	nc: 'New Caledonia',
	nz: 'New Zealand',
	ni: 'Nicaragua',
	ne: 'Niger',
	ng: 'Nigeria',
	nu: 'Niue',
	nf: 'Norfolk Island',
	mp: 'Northern Mariana Islands',
	no: 'Norway',
	om: 'Oman',
	pk: 'Pakistan',
	pw: 'Palau',
	ps: 'Palestine Territories',
	pa: 'Panama',
	pg: 'Papua New Guinea',
	py: 'Paraguay',
	pe: 'Peru',
	ph: 'Philippines',
	pn: 'Pitcairn',
	pl: 'Poland',
	pt: 'Portugal',
	pr: 'Puerto Rico',
	qa: 'Qatar',
	re: 'Reunion',
	ro: 'Romania',
	ru: 'Russian Federation',
	rw: 'Rwanda',
	bl: 'Saint Barthélemy',
	kn: 'Saint Kitts and Nevis',
	lc: 'Saint Lucia',
	mf: 'Saint Martin',
	vc: 'Saint Vincent and the Grenadines',
	ws: 'Samoa',
	sm: 'San Marino',
	st: 'Sao Tome and Principe',
	sa: 'Saudi Arabia',
	sn: 'Senegal',
	rs: 'Serbia',
	sc: 'Seychelles',
	sl: 'Sierra leone',
	sg: 'Singapore',
	sx: 'Sint Maarten (Dutch Part)',
	sk: 'Slovakia (Slovak Republic)',
	si: 'Slovenia',
	sb: 'Solomon Islands',
	so: 'Somalia',
	za: 'South Africa',
	gs: 'South Georgia South Sandwich Islands',
	ss: 'South Sudan',
	es: 'Spain',
	lk: 'Sri Lanka',
	sh: 'St. Helena',
	pm: 'St. Pierre and Miquelon',
	sd: 'Sudan',
	sr: 'Suriname',
	sj: 'Svalbard and Jan Mayen Islands',
	sz: 'Swaziland',
	se: 'Sweden',
	ch: 'Switzerland',
	sy: 'Syrian Arab Republic',
	tw: 'Taiwan, China',
	tj: 'Tajikistan',
	tz: 'Tanzania United Republic Of',
	th: 'Thailand',
	tl: 'Timor-Leste (East Timor)',
	tg: 'Togo',
	tk: 'Tokelau',
	to: 'Tonga',
	tt: 'Trinidad and Tobago',
	tn: 'Tunisia',
	tr: 'Turkey',
	tm: 'Turkmenistan',
	tc: 'Turks and Caicos Islands',
	tv: 'Tuvalu',
	ug: 'Uganda',
	ua: 'Ukraine',
	ae: 'United Arab Emirates',
	gb: 'United Kingdom',
	us: 'United States',
	um: 'United States Minor Outlying Islands',
	uy: 'Uruguay',
	uz: 'Uzbekistan',
	vu: 'Vanuatu',
	ve: 'Venezuela',
	vn: 'Viet nam',
	vg: 'Virgin Islands (British)',
	vi: 'Virgin Islands (U.S.)',
	wf: 'Wallis and Futuna Islands',
	eh: 'Western Sahara',
	ye: 'Yemen',
	zm: 'Zambia',
	zw: 'Zimbabwe',
	ot: 'Other',
	// return countrycodemap[countrycode];
};

export const BALLOT_STATUS = {
	DEFAULT: 'Default',
	REGISTERED: 'Ballot Registered',
	APPLICANT: 'Ballot Applicant',
	WITHDRAWN_APP: 'Withdrawn Applicant',
	IN_BALLOT: 'In Ballot',
	ENTRANT: 'Ballot Entrant',
	VOID: 'Void Applicant',
	LUCKY: 'Ballot Lucky',
	DECLINED: 'Lucky - Offer Declined',
	OFFER_VOID: 'Lucky - Offer Voided',
	OFFER_EXPIRED: 'Lucky - Offer Expired',
	WITHDRAWN_ENTR: 'Withdrawn Entrant',
	TICKETHOLDER: 'Ticketholder',
	WITHDRAWN_TICKET: 'Withdrawn Ticketholder',
	NOT_LUCKY: 'Ballot Unlucky',
	PENDING: 'Pending',
	TRANSFERRED: 'Transferred',
};

export const BALLOT_PERIOD = {
	PRE: 'pre',
	REGISTER: 'register',
	GAP: 'gap',
	APPLY: 'apply',
	SELECT: 'select',
	CLOSED: 'closed',
};

export const fetch = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			if (error.response && error.response.status == 404) {
				throw error.response;
			} else {
				throw error;
			}
		});
};

export const fetchAll = paths => {
	let hdr = restHeaders();
	let pathList = [];
	const pathsType = typeof paths;
	logger.log('[Shared Util] fetch - paths:%o', paths);

	if (Array.isArray(paths)) {
		pathList = paths;
	} else {
		return new Error('Invalid type for paths');
	}
	// logger.log('[Shared Util] fetchAll - pathList:%o', pathList);

	return axios
		.all(pathList.map(path => fetch(path, { headers: hdr })))
		.then(function(results) {
			// logger.log('[Shared Util] fetchAll - results:%o', results);

			const result = results.map(response => {
				return response;
			});

			let data = Array.isArray(paths) ? result : Object.assign({}, ...result);

			return data;
		})
		.catch(error => {
			throw error;
		});
};

/** create a new promise to use in fetchPromiseAll() */
export const fetchNewPromise = path => {
	logger.log('[Shared Util] fetchPromise path:%o', path);
	return new Promise((resolve, reject) => {
		try {
			fetch(path)
				.then(result => {
					return resolve(result);
				})
				.catch(error => {
					return resolve({ status: 'error', error });
				});
		} catch (error) {
			return resolve({ status: 'error', error });
		}
	});
};

/** gets an array of paths and create a new Promise for each and perform Promise.all */
export const fetchPromiseAll = paths => {
	let pathList = [];
	if (Array.isArray(paths)) {
		pathList = paths;
	} else {
		return new Error('Invalid type for paths');
	}

	return Promise.all(pathList.map(path => fetchNewPromise(path)));
};

/** add st, nd, rd, and th to a number
 *  A. find s[v%10] if >= 20 (20th...99th),
 *  B. if not found, try s[v] (0th..3rd),
 *  C. if still not found, use s[0] (4th..19th)
 */
export const getNumberWithOrdinal = n => {
	var s = ['th', 'st', 'nd', 'rd'],
		v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/*
Share link
http://wimbledon/share?url=<shareurl>
*** no native in apps

Player Profile
http://wimbledon/player?id=<playerid>
*** done

News Article
http://wimbledon/article?url=<shareurl>
http://wimbledon/news?url=<shareurl>
*** done

Single Photo
http://wimbledon/photo?url=<photourl>
*** not doing

Gallery​​​​​​​
http://wimbledon/gallery?id=<gallery content id>
*** not doing

Video​​​​​​​​​​​​​​
http://wimbledon/video?id=<video content id>

Slamtracker
http://wimbledon/match?id=<match id>

Map
http://wimbledon/map

Reload (for font size change)
http://wimbledon/fontSizeChange

Metrics
http://wimbledon/track?text=<metrics text to send>
http://wimbledon/metrics?text=<metrics text to send>
*/

import React, {useState} from 'react';
import WimLink from 'appdir/components/general/WimLink';
import NewFlag from 'appdir/components/common-ui/NewFlag';
import FullScreenVideo from 'appdir/components/common-ui/FullScreenVideo';
import ContentDate from 'appdir/components/cms/ContentDate/index.js';

const ContentListTile = props => {
	let { attributes, style } = props;
	const [vidOpen, openVideo] = useState(false);
	logger.log('[ContentListTile] - props:%o', props);
	const styleGen = style => {
		switch (style) {
			case "four-col":
				return "two-col";
				break;
			case "one-col":
				return "four-col";
				break;
			default:
				break;
		}
	}

	const onClick = () => {
		vidOpen ? openVideo(false) : openVideo(true);
	}

	const closeVid = () => {
		openVideo(false);
	}

	let dateArr = attributes['date'].split(" ");
	
	return (
		<div className={`content-tile ${styleGen(style)} `}>
			<NewFlag
				data={{
					url: attributes['url'], 
					contentId: attributes['contentId'], 
					displayDate: attributes['date'], 
				}}
			/>
      		{attributes['type'] == 'video' ? (
				<div
					className="content-link"
					id={attributes['contentId']}
					onClick={() => onClick()}
					aria-label={`video, ${attributes['title']}`}
					tabIndex={0}
					onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? onClick() : null)}>
					<div className="image-container">
						{attributes['type'] == 'video' && <i className="wim-icon-video" />}
						<img src={`${attributes['thumb']}`} alt={attributes['title']} />
					</div>
					<div className="content-text">
						<div className="type-tag">
							<div>{attributes['type']}</div>
						</div>						
						<div className='content-date'>
							<h6 aria-hidden role="none" tabIndex={-1}>
								<ContentDate date={dateArr[0]} tz={dateArr[1]} />
							</h6>
						</div>
						<div className="content-title">{attributes['title']}</div>
						<div className="content-description">{attributes['description']}</div>
						<hr className="purpleStripe-short left" />
					</div>
				</div>
			) : (
				<WimLink to={attributes.url} style="content-link">
					<div className="image-container">
						<img src={`${attributes['thumb']}`} alt={attributes['title']} />
					</div>
					<div className="content-text">
						<div className="type-tag">
							<div>{attributes['type']}</div>
						</div>						
						<div className='content-date'>
							<h6 aria-hidden role="none" tabIndex={-1}>
								<ContentDate date={dateArr[0]} tz={dateArr[1]} />
							</h6>
						</div>
						<div className="content-title">{attributes['title']}</div>
						<div className="content-description">{attributes['description']}</div>
						<hr className="purpleStripe-short left" />
					</div>
				</WimLink>
			)}
			{attributes['type'] == 'video' && vidOpen && <FullScreenVideo closeVid={() => closeVid()} attributes={{videoId: attributes['contentId']}} />}
		</div>
	);
};

export default ContentListTile;

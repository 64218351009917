/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ScoreTable
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['EventsMatchBox'],
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({});

class EventsMatchBox extends Component {
	constructor(props) {
		// logger.log('[EventsMatchBox] constructor props:%o', props);
		super(props);
		this.state = { ...props, lastScore_t1: false, lastScore_t2: false };

		this.scoreData = this.state.attributes.data;
		this.tableHeader = this.state.attributes.tableHeader;
		this.flagImagePathLarge = this.state.attributes.flagImagePathLarge;
		this.playerProfileImagePath = this.state.attributes.playerProfileImagePath;
		this.boxStyle = this.state.attributes.style ? this.state.attributes.style : '';
		this.layoutStyle = this.state.attributes.layoutStyle ? this.state.attributes.layoutStyle : '';
		this.iconClasses_t1 = classNames('crticon', this.scoreData.rounds[0].WINNER === 1 ? 'winner' : '');
		this.iconClasses_t2 = classNames('crticon', this.scoreData.rounds[0].WINNER === 2 ? 'winner' : '');

		this.renderFinalMatchBox = this.renderFinalMatchBox.bind(this);
		this.renderWinnerMatchBox = this.renderWinnerMatchBox.bind(this);
	}
	componentDidMount() {}
	componentWillUnmount() {
		clearTimeout(this.t1_timer);
		clearTimeout(this.t2_timer);
	}
	componentDidUpdate(prevProps, prevState) {
		const curAttributes = this.state.attributes;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[EventsMatchBox] componentWillReceiveProps nextProps:%o',nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		//logger.log('[EventsMatchBox] componentWillReceiveProps state:%o',this.state);
	}

	renderFinalMatchBox(match, index) {
		let topClass = index !== 0 ? 'extraSpace' : '';
		return (
			<div
				className={`match-box match-box-draws ${this.boxStyle} ${topClass}`}
				data-match={match.MATCH_ID}
				data-dna="match-box-draws-page"
				key={`matchbox ${match.DRAW_LINE_NUM} ${match.EVENT_YEAR}`}>
				<div className="match-status">
					<div className="green-border" />
					<div className={`team-info team-one ${match.TV_NAME_1B === '' ? 'singles' : 'doubles'}`}>
						<div className="team-name">
							<div className="member-one">
								<div className="flag">
									<img
										alt={match.CNTRY_1A}
										src={this.flagImagePathLarge.replace('<code>', match.CNTRY_1A)}
									/>
								</div>
								<div className="name">
									<WimLink
										to={`/en_GB/draws_archive/player_profile/${match.PLAYER_1A_ID}.html`}
										target="_self">
										{match.TV_NAME_1A}
									</WimLink>
								</div>
								<div className="seed">{match.SEED_1}</div>
							</div>
							<div className="member-two">
								<div className="flag">
									<img
										alt={match.CNTRY_1B}
										src={this.flagImagePathLarge.replace('<code>', match.CNTRY_1B)}
									/>
								</div>
								<div className="name">
									<WimLink
										to={`/en_GB/draws_archive/player_profile/${match.PLAYER_1B_ID}.html`}
										target="_self">
										{match.TV_NAME_1B}
									</WimLink>
								</div>
								<div className="seed">{match.SEED_1}</div>
							</div>
						</div>
						<div className={this.iconClasses_t1}>
							<i className="wim-icon-check" />
						</div>
						<div className="match-results">
							<div className="results-wrapper">
								<div key="set1" className="set set1">
									<span className="scores">
										{match.SET_1_1}
										<span className="tiebreak">{match.TIEBREAKER_1_1}</span>
									</span>
								</div>
								<div key="set2" className="set set2">
									<span className="scores">
										{match.SET_2_1}
										<span className="tiebreak">{match.TIEBREAKER_2_1}</span>
									</span>
								</div>
								<div key="set3" className="set set3">
									<span className="scores">
										{match.SET_3_1}
										<span className="tiebreak">{match.TIEBREAKER_3_1}</span>
									</span>
								</div>
								<div key="set4" className="set set4">
									<span className="scores">
										{match.SET_4_1}
										<span className="tiebreak">{match.TIEBREAKER_4_1}</span>
									</span>
								</div>
								<div key="set5" className="set set5">
									<span className="scores">
										{match.SET_5_1}
										<span className="tiebreak">{match.TIEBREAKER_5_1}</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="results-info">
						<div className="results-info-pts">PTS</div>
						<div className="results-info-set">1</div>
						<div className="results-info-set">2</div>
						<div className="results-info-set">3</div>
						<div className="results-info-set">4</div>
						<div className="results-info-set">5</div>
					</div>
					<div className={`team-info team-two ${match.TV_NAME_2B !== '' ? 'doubles' : 'singles'}`}>
						<div className="team-name">
							<div className="member-one">
								<div className="flag">
									<img
										alt={match.CNTRY_2A}
										src={this.flagImagePathLarge.replace('<code>', match.CNTRY_2A)}
									/>
								</div>
								<div className="name">
									<WimLink
										to={`/en_GB/draws_archive/player_profile/${match.PLAYER_2A_ID}.html`}
										target="_self">
										{match.TV_NAME_2A}
									</WimLink>
								</div>
								<div className="seed">{match.SEED_2}</div>
							</div>
							<div className="member-two">
								<div className="flag">
									<img
										alt={match.CNTRY_2B}
										src={this.flagImagePathLarge.replace('<code>', match.CNTRY_2B)}
									/>
								</div>
								<div className="name">
									<WimLink
										to={`/en_GB/draws_archive/player_profile/${match.PLAYER_2B_ID}.html`}
										target="_self">
										{match.TV_NAME_2B}
									</WimLink>
								</div>
								<div className="seed">{match.SEED_2}</div>
							</div>
						</div>
						<div className={this.iconClasses_t2}>
							<i className="wim-icon-check" />
						</div>
						<div className="match-results">
							<div className="results-wrapper">
								<div key="set1" className="set set1">
									<span className="scores">
										{match.SET_1_2}
										<span className="tiebreak">{match.TIEBREAKER_1_2}</span>
									</span>
								</div>
								<div key="set2" className="set set2">
									<span className="scores">
										{match.SET_2_2}
										<span className="tiebreak">{match.TIEBREAKER_2_2}</span>
									</span>
								</div>
								<div key="set3" className="set set3">
									<span className="scores">
										{match.SET_3_2}
										<span className="tiebreak">{match.TIEBREAKER_3_2}</span>
									</span>
								</div>
								<div key="set4" className="set set4">
									<span className="scores">
										{match.SET_4_2}
										<span className="tiebreak">{match.TIEBREAKER_4_2}</span>
									</span>
								</div>
								<div key="set5" className="set set5">
									<span className="scores">
										{match.SET_5_2}
										<span className="tiebreak">{match.TIEBREAKER_5_2}</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderWinnerMatchBox(match, index) {
		let topClass = index !== 0 ? 'extraSpace' : '';
		let winnerTeam = match.WINNER === 1 ? 1 : 2;

		let winnerA = 'TV_NAME_' + winnerTeam + 'A';
		let winnerB = 'TV_NAME_' + winnerTeam + 'B';

		let countryA = 'CNTRY_' + winnerTeam + 'A';
		let countryB = 'CNTRY_' + winnerTeam + 'B';

		let IDA = 'PLAYER_' + winnerTeam + 'A_ID';
		let IDB = 'PLAYER_' + winnerTeam + 'B_ID';

		let seed = 'SEED_' + winnerTeam;

		let winnerName_A = match[winnerA];
		let winnerName_B = match[winnerB];

		return (
			<div className="drawGroupInner" key={`winnerMatchBox ${match.DRAW_LINE_NUM} ${match.EVENT_YEAR}`}>
				<div
					className={`match-box match-box-draws events winner ${this.boxStyle} ${topClass}`}
					data-match={match.MATCH_ID}
					data-dna="match-box-draws-winner">
					<div className="match-status">
						<div className="green-border" />
						<div className={`team-info team-one ${winnerName_B === '' ? 'singles' : 'doubles'}`}>
							<div className="team-name">
								<div className="member-one">
									<div className="flag">
										<img
											alt={match[countryA]}
											src={this.flagImagePathLarge.replace('<code>', match[countryA])}
										/>
									</div>
									<div className="name">
										<WimLink
											to={`/en_GB/draws_archive/player_profile/${match[IDA]}.html`}
											target="_self">
											{winnerName_A}
										</WimLink>
									</div>
									<div className="seed">{match[seed]}</div>
								</div>
								<div className="member-two">
									<div className="flag">
										<img
											alt={match[countryB]}
											src={this.flagImagePathLarge.replace('<code>', match[countryB])}
										/>
									</div>
									<div className="name">
										<WimLink
											to={`/en_GB/draws_archive/player_profile/${match[IDB]}.html`}
											target="_self">
											{winnerName_B}
										</WimLink>
									</div>
									<div className="seed">{match[seed]}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		// logger.log('[EventsMatchBox] render - this.scoreData:%o  state:%o',this.scoreData, this.state);
		return (
			<div className="winnerGroup">
				<div className={`roundGroup ${this.layoutStyle}`}>
					<div className="yearWrapper">
						<h4>{this.tableHeader}</h4>
						<div className="drawGroup">
							{this.scoreData.rounds.map((match, index) => {
								return this.renderFinalMatchBox(match, index);
							})}
						</div>
					</div>
				</div>
				<div className={`roundGroup ${this.layoutStyle}`}>
					<div className="drawGroup winner">
						{this.scoreData.rounds.map((match, index) => {
							return this.renderWinnerMatchBox(match, index);
						})}
					</div>
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(EventsMatchBox);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import VideoPlayer from 'appdir/components/general/VideoPlayer';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Button from 'appdir/components/common-ui/Button';

/**
 * -----------------------------------------------------------------------------
 * React Component: MainVideoChannel
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MainVideoChannel'],
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//mount: () => dispatch(deps.actions.VideoPlayer.mount()),
	//videoPlay: (data) => dispatch(deps.actions.Controller.videoPlay(data))
	videoPlay: data => dispatch(deps.actions.MainVideoChannel.play(data)),
});

class MainVideoChannel extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.playerId = 'livePlayer';
		this.name = `[MainVideoChannel_${props.position}]`;

		this.timeFormat = 'h:mm A z';
		this.localFormat = 'h:mm A';
		this.bstTime = '';
		this.localTime = '';
		this.onVideoEvent = this.onVideoEvent.bind(this);
	}

	componentDidMount() {
		//logger.log(`${this.name} componentDidMount - logEnabled:%o', global.logEnabled);
		logger.log(`${this.name} componentDidMount - state:%o`, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log(`${this.name} componentWillReceiveProps - next:%o`, nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(`${this.name} componentDidUpdate - status:%o `, this.state);
	}

	componentWillUnmount() {
		logger.log(`${this.name} componentWillUnmount - player:%o`, this.player);
	}

	onPlayClick() {
		this.props.videoPlay({ position: this.state.position });
	}

	onVideoEvent(event) {
		logger.log(`${this.name} onVideoEvent - event:%o, this:%o`, event, this);

		if (event == 'close' && this.state.videoId !== '') {
			this.setState({
				videoId: '',
			});
		}
	}

	render() {
		logger.log(`${this.name} render - state:%o`, this.state);

		//let aspect = (this.state.attributes.hasOwnProperty('aspect')) ? this.state.attributes.aspect : '';
		//let col = (this.state.attributes.hasOwnProperty('style')) ? this.state.attributes.style : '';

		let aspect = 'wide';
		let style = this.state.position;

		if (this.props.channel.live) {
			let attributes = {
				playerId: this.state.name,
				cmsId: this.props.channel.code,
				videoUrl: this.props.channel.media.desktop[0].url,
				title: this.props.channel.title,
				thumb: this.props.channel.images.slate,
				shareUrl: '/en_GB/interactive/index.html',
				style: '',
				streamType: 'live',
				aspect: 'wide',
				autoplay: true,
			};

			//autoplay if in main
			if (this.state.position == 'main' || this.state.isPlaying) {
				//this.props.videoPlay({ position: this.state.position });
				return (
					<div className={`wimchan-wrapper ${style}`}>
						<div className="wimchan-onair">
							<VideoPlayer attributes={attributes} onEvent={this.onVideoEvent} key={attributes.cmsId} />
						</div>
					</div>
				);
			}

			// if can't play or isn't playing display image and play icon
			//   (when either stopped or sidepanel isn't open)
			else {
				return (
					<div className={`wimchan-wrapper ${style}`}>
						<div
							className="wimchan-onair"
							onClick={() => {
								this.onPlayClick();
							}}>
							<img src={this.props.channel.images.slate} />
							<i className="wim-icon-video" />
						</div>
					</div>
				);
			}
		}

		//if video is not live
		// else if (this.props.channel && !this.props.channel.live) {
		// 	this.localTime = moment(parseFloat(this.state.video.wimchan.startTime))
		// 		.local()
		// 		.format(this.localFormat);
		// 	this.bstTime = moment(parseFloat(this.state.video.wimchan.startTime))
		// 		.tz('Europe/London')
		// 		.format(this.timeFormat);
		// 	let wim3d = this.state.geoBlock.available.wimChan3 ? 'wimchan3d-offair' : null;

		// 	return (
		// 		<div className={`wimchan-wrapper ${style}`}>
		// 			<div className={`wimchan-offair ${wim3d}`}>
		// 				<div className="offair-text">
		// 					<div className="offair-title">
		// 						{wim3d !== null
		// 							? `WIMBLEDON UNCOVERED IN 360 IS OFF AIR`
		// 							: `WIMBLEDON UNCOVERED IS OFF AIR`}
		// 					</div>
		// 					<div className="offair-times">
		// 						Join us at {this.bstTime} and {this.localTime} local time.
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	);
		// }
		else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainVideoChannel);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

/**
 * -----------------------------------------------------------------------------
 * React Component: HillChannel
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['HillChannel'],
		video: state['ActiveData']['video'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//mount: () => dispatch(deps.actions.VideoPlayer.mount()),
	//videoPlay: (data) => dispatch(deps.actions.Controller.videoPlay(data))
	videoPlay: data => dispatch(deps.actions.HillChannel.play(data)),
});

class HillChannel extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.playerId = 'hillPlayer';
		this.name = '[HillChannel_]';

		this.timeFormat = 'h:mm A z';
		this.localFormat = 'h:mm A';
		this.bstTime = '';
		this.localTime = '';
	}

	componentDidMount() {
		//logger.log(`${this.name} componentDidMount - logEnabled:%o', global.logEnabled);
		logger.log(`[HillChannel] componentDidMount - state:%o`, this.state);

		if (global.logEnabled) {
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log(`${this.name} componentWillReceiveProps - next:%o`, nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(`${this.name} componentDidUpdate - status:%o `, this.state);
	}

	componentWillUnmount() {
		logger.log(`${this.name} componentWillUnmount - player:%o`, this.player);
		//eventsPlayer.destroy(`#${this.playerId}`);
	}

	onPlayClick() {
		this.props.videoPlay({ position: this.state.position });
	}

	render() {
		logger.log(`${this.name} render - state:%o`, this.state);

		//let aspect = (this.state.attributes.hasOwnProperty('aspect')) ? this.state.attributes.aspect : '';
		//let col = (this.state.attributes.hasOwnProperty('style')) ? this.state.attributes.style : '';

		let aspect = 'wide';
		let style = this.state.position;

		// style indicates player display
		//  - "none" (do not display)
		//  - "sidepanel"
		//  - "main"
		if (!this.state.position) {
			return (
				<div className={`centrecourt-wrapper sidepanel`}>
					<div className="centrecourt-offair">
						<div className="offair-text">
							<div className="offair-title" />
						</div>
					</div>
				</div>
			);
		}

		//if video is live
		if (this.state.video && this.state.video.hill.live) {
			let attributes = {
				playerId: 'main',
				contentId: 'thehill',
				videoUrl: this.state.video.hill.media.desktop[0].url,
				title: 'The Hill',
				thumb: this.state.video.hill.slate,
				shareUrl: '/index.html',
				style: '',
				type: 'live',
				aspect: 'wide',
				autoplay: true,
			};

			//if isPlaying is true, render and autoplay video
			if (this.state.isPlaying) {
				return (
					<div className={`centrecourt-wrapper ${style}`}>
						<div className="centrecourt-onair">
							<VideoPlayer attributes={attributes} onEvent={this.onVideoEvent} />
						</div>
					</div>
				);
			}

			// if can't play or isn't playing display image and play icon
			//   (when either stopped or sidepanel isn't open)
			else {
				return (
					<div className={`centrecourt-wrapper ${style}`}>
						<div
							className="centrecourt-onair"
							onClick={() => {
								this.onPlayClick();
							}}>
							<img src={this.state.video.hill.images.slate} />
							<i className="wim-icon-video" />
						</div>
					</div>
				);
			}
		}

		//if video is not live
		else if (this.state.video && !this.state.video.hill.live) {
			this.bstTime = moment(parseFloat(this.state.video.hill.startTime))
				.tz('Europe/London')
				.format(this.timeFormat);

			return (
				<div className={`centrecourt-wrapper ${style}`}>
					<div className="centrecourt-offair">
						<div className="offair-text">
							<div className="offair-title">THE BIG SCREEN IS OFF AIR</div>
							<div className="offair-times">Join us at {this.bstTime}.</div>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HillChannel);

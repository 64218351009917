import React from 'react';

const RankingMovement = props => {
	const { data = {} } = props;

	return (
		<div className="powerrank-movement">
			<div className="rank">{data.rank}</div>
			{data.movement !== 0 ? (
				<div className={`movement ${data.movement > 0 ? 'up' : 'down'}`}>
					<i className={`wim-icon-triangle-${data.movement > 0 ? 'up' : 'down'}`}></i>
					<div className="movement-value">{data.movement > 0 ? `+${data.movement}` : data.movement}</div>
				</div>
			) : null}
		</div>
	);
};
export default RankingMovement;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * React Component: PointNavOptions
 * -----------------------------------------------------------------------------
 */

export default class PointNavOptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			//selected: 'overview',
			selected: 'pointByPoint',
		};
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			//this.state.mount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => ({
			...prevState,
			...nextProps,
		}));
	}

	componentDidUpdate() {
		logger.log('[PointNavOptions] ComponentDidUpdate - state:%o', this.state);
	}

	isNumeric(data) {
		return !isNaN(parseFloat(data)) && isFinite(data);
	}

	pointSelect(point) {
		logger.log('[PointNavOptions] pointSelect - point:%o startTime:%o', point, this.startTime);

		if (this.state.onSelect) {
			this.state.onSelect(point);
		}
	}

	filterClick() {
		logger.log('[PointNavOptions] filterClick - state:%o', this.state);
		if (this.state.onFilter) {
			this.state.onFilter();
		}
	}
	optionClick(selected) {
		logger.log('[PointNavOptions] pointByPointClick - state:%o', this.state);

		if (selected == 'overview' && this.props.onOverview) {
			this.props.onOverview(true);
		} else {
			this.props.onOverview(false);
		}

		this.setState({
			selected,
		});
	}

	render() {
		logger.log('[PointNavOptions] render - state:%o', this.state);

		let filterStyle = this.state.filterOpen ? 'active' : '';

		return (
			<div className={`pointNavOptions`}>
				<div
					className={`option ${this.state.selected == 'overview' ? 'selected' : ''}`}
					onClick={() => this.optionClick('overview')}>
					Overview
				</div>
				<div className="separator">|</div>
				<div
					className={`option ${this.state.selected == 'pointByPoint' ? 'selected' : ''}`}
					onClick={() => this.optionClick('pointByPoint')}>
					Point By Point
				</div>
				<div
					className={`filter ${filterStyle}`}
					onClick={() => {
						this.filterClick();
					}}
				/>
			</div>
		);
	}
}

PointNavOptions.defaultProps = {};

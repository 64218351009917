import ContentPage from './index';
import SearchPage from 'appdir/components/pages/SearchPage';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

/*
	Oct2020 - changed debentures to not have landing page route, landing page (index.html) pulls index.xml
	hospitality could also
	June 2023 - changed debentures to not have landing page route, landing page (index.html) pulls index.xml
*/

export default [
	{
		path: '/:lang/atoz/index.html',
		exact: true,
		component: SearchPage,
		section: MENU_ITEMS.visitTickets,
		load: params => deps.actions.SearchPage.mount(params),
	},

	{
		path: '/:prefix(preview)?/en_GB/:landing(aboutmywimbledon)/:id(index).html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.mywimbledon,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:landing(about_wimbledon|jobs|media)/:id(index).html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.about,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:landing(learning)/:id(index).html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.foundation,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/en_GB/:landing(tickets|foodanddrink|your_visit|museum_and_tours|learning)/:id(index).html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.visitTickets,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/en_GB/:landing(inpursuitofgreatness|hospitality|play_your_way_to_wimbledon|privacy|centre_court_100)/:id(index).html',
		exact: true,
		component: ContentPage,
		load: params => deps.actions.ContentPage.mount(params),
	},

	{
		path:
			'/:prefix(preview)?/en_GB/:landing(landing)/:id(centre_court_100|foundation|tickets|foodanddrink|your_visit|museum_and_tours|learning|about_wimbledon|jobs|media|hospitality|debentures|aboutmywimbledon).html',
		exact: true,
		component: ContentPage,
		load: params => deps.actions.ContentPage.mount(params),
	},

	{
		path: '/:prefix(preview)?/en_GB/:category(aboutmywimbledon)/:id.html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.mywimbledon,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:category(aboutmywimbledon)/:id.html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.mywimbledon,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:category(atoz)/:id(schedule|tv_schedules).html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.scoresSchedule,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:category(atoz)/:id(official_suppliers).html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.about,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:category(atoz)/:id(shopping).html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.shop,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:category(foundation|wimbledonfoundation)/:id.html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.foundation,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:category(aboutwimbledon|about_wimbledon|jobs)/:id.html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.about,
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/en_GB/:category(tickets|visit_and_tickets|visitandtickets|atoz|debentures)/:id.html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.visitTickets,
		load: params => deps.actions.ContentPage.mount(params),
	},

	{
		path: '/:prefix(preview)?/en_GB/news/:category(articles)/:date?/:id.html',
		exact: true,
		component: ContentPage,
		section: MENU_ITEMS.news,
		load: params => deps.actions.ContentPage.mount(params),
	},
];

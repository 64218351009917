/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * React Component: NextUp
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['NextUp'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class NextUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.countDown = this.countDown.bind(this);
	}

	componentDidMount() {
		this.startCountdown();
	}

	componentWillUnmount() {
		//logger.log('[NextUp] componentWillUnmount - ');
		this.stopCountdown();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[NextUp] componentWillReceiveProps - state:%o', nextProps);

		if (!nextProps.content) {
			this.stopCountdown();
		}

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		//logger.log('[NextUp] componentDidUpdate- time:%o state:%o', this.timer, this.state);
		//this.startCountdown();
	}

	startCountdown() {
		if (!this.timer && this.state.content && this.state.auto) {
			this.timer = setInterval(this.countDown, 1000);
		}
	}

	stopCountdown() {
		clearInterval(this.timer);
		this.timer = null;
	}

	countDown() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.state.seconds - 1;

		if (this.timer) {
			this.setState({
				seconds: seconds,
			});
		}

		// Check if we're at zero.
		if (seconds == 0) {
			this.nextUpPlay(true);
		}
	}

	nextUpPlay(auto) {
		this.stopCountdown();

		if (this.state.onPlay) {
			this.state.onPlay(auto);
		}
	}

	enableToggle() {
		let enabled = !this.state.auto;

		if (!enabled) {
			this.stopCountdown();
		} else {
			this.timer = setInterval(this.countDown, 1000);
		}

		if (this.state.onUserEnable) {
			this.state.onUserEnable(enabled);
		}

		this.setState({
			auto: enabled,
		});
	}

	render() {
		//logger.log('[NextUp] render - state:%o', this.state);

		if (this.state.content) {
			return (
				<div className="nextup">
					<div className="next_banner">
						<div className="photo">
							<div className="photo-holder">
								<img src={this.state.content.images[0].small} />
							</div>
						</div>
						<div className="next-content">
							<div className="prompt">Up Next...</div>
							<div className="title">{this.state.content.title}</div>
						</div>
						{this.state.auto ? (
							<div className="countdown">
								<div className="label">Starts in...</div>
								<div className="count">{this.state.seconds}</div>
								<div className="allow">
									<span onClick={() => this.enableToggle()}>disable autoplay</span>
								</div>
							</div>
						) : (
							<div className="countdown">
								<div className="allow">
									<span onClick={() => this.enableToggle()}>enable autoplay</span>
								</div>
							</div>
						)}
						<div className="next" onClick={() => this.nextUpPlay(false)}>
							<span className="play-button" />
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(NextUp);

import React from 'react';

const IFrameComponent = ({ src, height }) => {
	let style = '';

	if (height) {
		style = 'fullview';
		height = '';
	} else {
		height = '100%';
	}

	return (
		<div className={`embed-responsive embed-responsive-1by1 ${style}`}>
			<iframe
				src={src}
				allowFullScreen={true}
				width="100%"
				height={height}
				frameBorder="0"
				className={`embed-responsive-item`}
				style={{ padding: '10px' }}
			/>
		</div>
	);
};

export default IFrameComponent;

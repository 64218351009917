import DrawsArchiveLadiesNames from './index';
import deps from 'dependencies';

export default {
	path: '/:lang/draws_archive/ladies-names.html',
	exact: true,
	component: DrawsArchiveLadiesNames,
	section: 'Draws',
	load: params => deps.actions.DrawsArchiveLadiesNames.mount(params),
};

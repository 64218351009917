import React from 'react';
import { useDiningContext } from './elements';
import moment from 'moment';
import PurpleStripe from '../../../../common-ui/Stripes/PurpleStripe';
import Button from 'appdir/components/common-ui/Button';

const AvailableTimes = () => {
	const {
		setSelectedTime,
		selectedTime,
		makeBooking,
		size,
		date,
		note,
		setNote,
		times,
		service,
	} = useDiningContext();

	return (
		<>
			<p className="purple reservation-title">SELECT TIME</p>
			<PurpleStripe width={35} />
			<p className="reservation-description">
				You are making a reservation for {size} {size === '1' ? 'person' : 'people'} on{' '}
				{moment(date).format('dddd DD-MM-yyyy')} <br /> Please select a time from one of the following available
				options
			</p>
			<div className="times-wrapper two-col margin">
				{times.map(obj => (
					<div
						className={`${obj.time === selectedTime ? 'inverted-time-box' : 'time-box'}`}
						key={obj.time}
						onClick={() => setSelectedTime(obj.time)}>
						<p className={`${obj.time === selectedTime ? 'inverted-time' : 'time'}`}>{obj.time}</p>
					</div>
				))}
				{times === undefined && <p>No availability during this time</p>}
			</div>
			<div className="entry-wrapper two-col margin">
				<div className="input-wrapper">
					<div className="select-wrapper">
						<label htmlFor="membership-type">
							<h5 className={'label purple select-header'}>Notes</h5>
						</label>
						<textarea
							className="notes"
							value={note}
							onChange={e => setNote(e.target.value)}
							placeholder="Please enter notes of any Dietary Requirements, Table Preference or Special Occasion"></textarea>
					</div>
				</div>
			</div>
			<div
				className="entry-wrapper two-col margin"
				style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
				<Button
					className="solid green reservation-submit-btn"
					// onClick={() => makeBooking('testmembersdiningroom')} // hardcode as Members' Dining Room key for now, change to dynamic once we have the multiple venues integrated
					onClick={() => makeBooking()}
					disabled={!date || !service || service === 'Select' || !size || size === 'Select' || !selectedTime}>
					Submit
				</Button>
			</div>
		</>
	);
};

export default AvailableTimes;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import WimLink from 'appdir/components/general/WimLink';
const op = require('object-path');

const mapStateToProps = (state, props) => {
	return {
		currentUser: state['Gigya']['currentUser'],
		loggedIn: state['Gigya']['loggedIn'],
		shelfIcons: state['Config']['myWIcons'],
		version: op.get(state['Config'], 'myWimbledon.version', 0),
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

/**
 * This is the public Debenture component
 */
class Debenture extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	render() {
		logger.log('[Debenture] render state: %o', this.state);

		let debenture = [];
		let infoUrl = '';

		if (this.state.loggedIn && this.state.shelfIcons) {
			debenture = this.state.currentUser.roles.filter(d => d.roleId == 1000020);

			if (debenture.length > 0) {
				let shelfKey = `debenture:${debenture[0].segment}`;
				let icons = op.get(this.state, `shelfIcons.${shelfKey}`, []);
				let info = icons.filter(icons => {
					return icons.title == 'Debenture Info';
				});

				if (info.length > 0) {
					infoUrl = info[0].url.replace('<segment>', debenture[0].segment);
					logger.log('[Debenture] render segment:%o info:%o', debenture[0].segment, infoUrl);
				}
			}
		}

		return infoUrl != '' && this.state.version >= 2 ? (
			<React.Fragment>
				<div className="column-layout debenture-cms debenture-content">
					<div className="item title full-width">
						<div className="debenture-row-title">
							<span className="welcome-text">{`Welcome `}</span>
							{this.state.currentUser.profile.firstName}
						</div>
					</div>
					<div className="full-width">
						For specific debenture information to you including tickets
						<br />
						and to book restaurants and parking, visit{' '}
						<WimLink to={infoUrl} style="bold-text">
							Debenture Information
						</WimLink>
						.
					</div>
				</div>
				<div className="gray-line four-col clear-four" />
			</React.Fragment>
		) : null;
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Debenture);

import PDEContentDash from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/s/player/info/match-stats/pdedash.html'],
		exact: true,
		component: PDEContentDash,
		// load: params => deps.actions.PDEContentDash.mount(params),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import PlayerHistory from './PlayerHistory';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerProfile ProfileTab
 * -----------------------------------------------------------------------------
 */

const ProfileTab = props => {
	let data = props.data;
	let playerInfo = data.playerObj;

	const { num_played = '', best_singles = '', best_doubles = '' } = data.playerObj.event;

	const { matches_won = '', matches_lost = '', prize_money = '' } = data.playerObj.results.career;

	const { high_singles = '', high_doubles = '' } = data.playerObj.rank;

	let events = '';
	if (typeof data.playerObj.event.events_entered === 'string') {
		events = data.playerObj.event.events_entered;
	} else {
		events = data.playerObj.event.events_entered
			.map(obj => {
				return obj.event_name;
			})
			.join(', ');
	}

	logger.log('[ProfileTab] data: %o', data);

	return (
		<div className="profile-tab">
			<div className="column-layout player-profile-info" tabIndex={0}>
				<div className="profile-col1">
					<div className="stat-line">
						<span className="stat-field">Country: </span>
						<span className="stat-value">{playerInfo.nation.country || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Age: </span>
						<span className="stat-value">
							{'date' in playerInfo.birth
								? playerInfo.birth.date
									? moment().diff(moment(playerInfo.birth.date, 'DD MMM YYYY'), 'years')
									: 'N/A'
								: 'N/A'}
						</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Birth date: </span>
						<span className="stat-value">{playerInfo.birth.date || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Birth Place: </span>
						<span className="stat-value">{playerInfo.birth.place || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Residence: </span>
						<span className="stat-value">{playerInfo.residence || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Height: </span>
						<span className="stat-value">
							{playerInfo.height.imperial
								? `${playerInfo.height.imperial} (${playerInfo.height.metric})`
								: 'N/A'}
						</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Plays: </span>
						<span className="stat-value">{playerInfo.plays || 'N/A'}</span>
					</div>
				</div>
				<div className="profile-col2">
					<div className="stat-line">
						<span className="stat-field">Championships Played: </span>
						<span className="stat-value">{num_played || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Events Entered: </span>{' '}
						<span className="stat-value">{events || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Best Singles: </span>
						<span className="stat-value">{best_singles || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Career Matches Won: </span>
						<span className="stat-value">{matches_won || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Career Matches Lost: </span>
						<span className="stat-value">{matches_lost || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">High Rank for Singles: </span>
						<span className="stat-value">{high_singles || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">High Rank for Doubles: </span>
						<span className="stat-value">{high_doubles || 'N/A'}</span>
					</div>
					<div className="stat-line">
						<span className="stat-field">Career Prize Money: </span>
						<span className="stat-value">{prize_money || 'N/A'}</span>
					</div>
				</div>
			</div>
			<div className="player-results">
				<PlayerHistory singles={playerInfo.singles_history} doubles={playerInfo.doubles_history} />
			</div>
		</div>
	);
};

export default ProfileTab;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.VIDEOPLAYER_FULLWINDOW:
			let type = action.data;

			newState = {
				...state,
				fullwindowVideo: type,
			};
			//logger.log('[HomePageFilter] deps.actionTypes.VIDEOPLAYER_FULLWINDOW - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

import React from 'react';
import Button from 'appdir/components/common-ui/Button';
import RegisterButton from 'appdir/components/cms/RegisterButton';
import SimpleComponent from 'appdir/components/cms/SimpleComponent';

const Buttons = ({ data }) => {
	let handleClick = () => {
		logger.log(`[TestPage] handleClick`);
	};

	return (
		<div className="content-main content ">
			<div className="column-layout content">
				<div className="two-col margin">2023 Ballot Status Component</div>
				<SimpleComponent tag="ballotStatus" style="two-col margin collapsible"></SimpleComponent>
				<div className="four-col">
					<br />
					<br />
					<br />
				</div>
				<div className="two-col margin">
					Picnic Registration
					<br />
					<RegisterButton type="picnic_registration"></RegisterButton>
				</div>
				<div className="two-col margin">
					Debenture Prospect
					<br />
					<RegisterButton type="debenture_register"></RegisterButton>
				</div>
				<div className="two-col margin">
					Transport
					<br />
					<RegisterButton data={{ style: 'four-col', type: 'transport_register' }} />
				</div>
				<div className="two-col margin">
					USA Hill
					<br />
					<RegisterButton data={{ style: 'four-col', type: 'usahill_registration' }} />
				</div>

				<div className="two-col margin">
					Retail Competition
					<br />
					<RegisterButton data={{ style: 'four-col', type: 'retail_competition' }} />
				</div>

				<div className="two-col margin">
					<Button to="/index.html" style={{ width: '225px' }}>
						View More
					</Button>
				</div>
				<div className="two-col margin">
					<Button onClick={handleClick} className="solid" style={{ width: '225px' }}>
						Submit
					</Button>
				</div>
				<div className="two-col margin">
					<Button to="/en_GB/scores/stats/3601.html" className="compact" style={{ width: '100%' }}>
						Stats By Slamtracker
					</Button>
				</div>
				<div className="two-col margin">
					<Button to="" className="outline">
						Match Insights by IBM
					</Button>
				</div>
				<div className="two-col margin"></div>
				<div className="two-col margin">
					<Button to="/s/tickets/app" className="outline">
						Tickets - App
					</Button>
					<a href="intent:#Intent;action=com.ibm.events.android.wimbledon;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.msg_from_browser=Launched%20from%20Browser;end">
						Intent Link to Tickets
					</a>
				</div>
				<div className="two-col margin"></div>
				<div className="two-col margin center">
					Please register to re-enable your Media access
					<br />
					<br />
					<Button to="/s/tickets/app" className="solid">
						Media registration
					</Button>
				</div>
				<div className="two-col margin"></div>
				<div className="two-col margin center">
					window.location redirect
					<br />
					<br />
					<Button onClick={() => window.location.href = "http://www-cdt.wimbledon.com/en_GB/scores/index.html"}>
						open /en_GB/scores/index.html
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Buttons;

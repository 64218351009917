/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { values } from 'appdir/main';

import LoginWrapper from './LoginWrapper';
import SiteMap from 'appdir/components/general/SiteMap';
import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';

// import MatchBox from 'appdir/components/scoring/MatchBox';
// import Translate from 'appdir/components/pages/Slamtracker/Translate';
// import OverallStats from 'appdir/components/pages/Slamtracker/Stats/OverallStats';
// import JSXParser from 'react-jsx-parser';
// import PrevNextBack from 'appdir/components/common-ui/PrevNextBack';

/**
 * -----------------------------------------------------------------------------
 * React Component: PDEContentDash
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['PDEContentDash'],
		...state['Gigya'],
		daysPath: op.get(state['Config'], 'scoringData.scheduleDays', null),
		activeDataPath: op.get(state['Config'].otherData, 'activeData.path', null),
		schedulePath: op.get(state['Config'].scoringData, 'schedule', null),
		eventNames: op.get(state['Config'].scoringConfig, 'eventNames', null),
		pdePaths: op.get(state['Config'].pde, 'api', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	unmount: () => dispatch(deps.actions.PDEContentDash.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class PDEContentDash extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			statsSet: 'match',
		};

		this.activeDataLoaded = false;
		this.dataLoaded = false;
		this.eventNamesArr = null;
		this.idGen = false;
		this.eventNames = new Object();
		this.isShadow = this.isShadow.bind(this);

		// logger.log('[PDEContentDash] constructor - state:%o', this.state);
		// logger.log('[PDEContentDash] constructor - props:%o', this.props);
	}

	componentDidMount() {
		// logger.log('[PDEContentDash] componentDidMount');
	}

	componentWillUnmount() {
		// logger.log('[PDEContentDash] componentWillUnmount');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[PDEContentDash] componentDidUpdate - state:%o', this.state);
		logger.log('[PDEContentDash] componentDidUpdate - props:%o', this.props);

		if (!this.activeDataLoaded) {
			this.activeDataLoaded = true;

			// logger.log('[PDEContentDash] componentDidUpdate - url:%o', url);
			const request1 = axios.get(this.props.activeDataPath);
			const request2 = axios.get(this.props.daysPath);
			axios
				.all([request1, request2])
				.then(
					axios.spread((res1, res2) => {
						logger.log('[PDEContentDash] componentDidUpdate - res1:%o, res2:%o', res1, res2);

						this.setState({
							activeData: res1.data,
							scheduleDaysData: res2.data,
						});
					})
				)
				.catch(error => {
					logger.error('[PDE - PlayerMatchStats] componentDidUpdate - error loading match data:%o', error);
					this.setState({
						error:
							'The information you are requesting is currently unavailable. Please try again later or contact us on players@aeltc.com',
					});
				});
		}

		if (
			/* data load checking here */
			this.state.activeData &&
			this.state.scheduleDaysData &&
			this.props.gigyaLoaded &&
			this.props.loggedIn &&
			this.isShadow() &&
			!this.dataLoaded
		) {
			this.dataLoaded = true;
			let { scheduleDaysData, activeData } = this.state;
			let currentDay = activeData.currentDay.schedule;
			currentDay = parseInt(currentDay);
			logger.log('[PDEContentDash] componentDidUpdate - currentDay:%o', currentDay);
			let promiseArray = [];
			for (let x = 0; x < scheduleDaysData.eventDays.length; x++) {
				if (scheduleDaysData.eventDays[x].feedUrl) {
					// promiseArray.push(axios.get(this.props.schedulePath.replace('<day>', x)));
					promiseArray.push(axios.get(scheduleDaysData.eventDays[x].feedUrl));
					if (scheduleDaysData.eventDays[x].currentDay) {
						break;
					}
				}
			}

			axios
				.all(promiseArray)
				.then(results => {
					logger.log('[PDEContentDash] componentDidUpdate - results:%o', results[0]);
					let temp = results.map(r => r.data);
					temp = temp.reverse();
					if (!isEmpty(temp)) {
						this.setState({
							scheduleData: temp,
						});
					} else {
						this.setState({
							error: 'No Matches Scheduled',
						});
					}
				})
				.catch(error => {
					logger.error('[PDE - PlayerMatchStats] componentDidUpdate - error loading match data:%o', error);
					this.setState({
						error:
							'The information you are requesting is currently unavailable. Please try again later or contact us on players@aeltc.com',
					});
				});

			let url = this.props.pdePaths.dashboard;
			// let url = 'https://www-cdt.wimbledon.com/pde/rest/player/2019/matches';
			this.props.getJWT().then(token => {
				logger.log('[PDEContentDash] componentDidUpdate - token.jwt.id_token:%o', token.jwt.id_token);
				axios({
					method: 'get',
					url: url,
					headers: {
						Authorization: `Bearer ` + token.jwt.id_token,
						'Content-type': 'application/json',
					},
				})
					.then(result => {
						logger.log('[PDEContentDash] componentDidUpdate - result:%o', result);

						this.setState({
							pdeData: result.data,
						});
					})
					.catch(error => {
						logger.error(
							'[PDE - PlayerMatchStats] componentDidUpdate - error loading match data:%o',
							error
						);
						this.setState({
							error:
								'The information you are requesting is currently unavailable. Please try again later or contact us on players@aeltc.com',
						});
					});
			});
		}

		if (this.props.eventNames && isEmpty(this.eventNames)) {
			this.props.eventNames.map(i => {
				this.eventNames[i.code] = i.event_prefix;
			});
		}
		if (!isEmpty(this.eventNames) && this.state.pdeData && !this.idGen) {
			this.idGen = true;
			let tempPde = new Object();

			this.state.pdeData.map(i => {
				i.years[0].matches.map(j => {
					let tempData = j.data;
					tempData['matchId'] = j.match;
					let matchCode = this.eventNames[j.match.substring(0, 2)];
					let smtId = matchCode + j.match.slice(2);
					// logger.log('[PDEContentDash] componentDidUpdate - smtId:%o', smtId);
					tempPde[smtId] = j.data;
				});
			});
			// logger.log('[PDEContentDash] componentDidUpdate - tempPde:%o', tempPde);
			this.setState({ pdeDataRemap: tempPde });
		}
	}

	changeStatsSet(which, hasData) {
		if (hasData) {
			this.setState(prevState => {
				return { ...prevState, statsSet: which };
			});

			this.measureStats(['Select Set', which]);
		}
	}

	isShadow() {
		let shadowAccess = op.get(this.props, 'currentUser.data.systemRole.player.isShadowingAccess', false);
		if (!shadowAccess) {
			shadowAccess = op.get(this.props, 'currentUser.data.supportRole.shadow.access', []);
			shadowAccess = shadowAccess.filter(role => {
				if (role.roleId == 1000050) {
					return true;
				} else {
					return false;
				}
			});
		}
		return isEmpty(shadowAccess) ? false : true;
	}

	render() {
		logger.log('[PDEContentDash] render - state:%o', this.state);
		logger.log('[PDEContentDash] render - props:%o', this.props);
		logger.log('[PDEContentDash] render - this.error:%o', this.error);

		let header_propsData = {
			headerType: 'generic_register',
			title: 'PDE Content Dashboard',
			shortTitle: 'PDE Content Dashboard',
			metaTitle: 'PDE Content Dashboard',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		};

		if (!this.props.loggedIn && this.props.gigyaLoaded) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout news">
						<div className="four-col byline clear-two clear-four" />
						<div className="four-col bottom-margin">
							<LoginWrapper />
						</div>
					</div>

					<Footer />
				</Template>
			);
		}
		if (!this.isShadow() && this.props.gigyaLoaded) {
			header_propsData = {
				headerType: 'generic_register',
				title: 'Sitemap',
				shortTitle: 'Sitemap',
				metaTitle: 'Sitemap',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
				scrollElem: '.content-main',
				titleElem: '.news--header',
				skipContent: true,
			};
			logger.log('[PDEContentDash] render - no shadow access, redirecting here');
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<SiteMap notFound={true} />
						<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
					</div>

					<Footer />
				</Template>
			);
		}
		// let backData = { category: '', history: this.props.history };
		let { eventNames } = this.props;
		let { pdeDataRemap, scheduleData } = this.state;
		if (this.state.error) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main news pde-match-stats" id="main" tabIndex="0">
						<div className="column-layout">
							<div className="four-col multi">
								<div className="loading-indicator">{this.state.error}</div>
							</div>
						</div>
					</div>

					<Footer />
				</Template>
			);
		}

		logger.log(
			'[PDEContentDash] render - pdeData:%o, scheduleData:%o, eventNames:%o, this.idGen:%o',
			pdeDataRemap,
			scheduleData,
			eventNames,
			this.idGen
		);
		if (pdeDataRemap && scheduleData && eventNames) {
			//do data check here
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className={`content-main pde-content-dash`} id="main" tabIndex="0">
						<div className="column-layout">
							<div className="four-col">
								{scheduleData.map((dayData, idx) => {
									let allMatches = [];
									for (let x = 0; x < dayData.courts.length; x++) {
										allMatches.push(...dayData.courts[x].matches);
									}
									allMatches = sortBy(allMatches, ['match_id']);
									return (
										<Fragment key={`daytable-${idx}`}>
											<h4>{dayData.displayDate}</h4>
											<table>
												<tbody>
													<tr>
														<th>MatchId</th>
														<th>SMT MatchId</th>
														<th>Player 1</th>
														<th>Player 2</th>
														<th>Stats PDF</th>
														<th>Video</th>
														<th>Point History</th>
													</tr>
													{allMatches.map((i, idx) => {
														logger.log('[PDEContentDash] render - matches:%o', i);
														// let sortedMatches = sortBy(c.matches, ['match_id']);
														let statsPdf = op.get(
															filter(pdeDataRemap[i.match_id], {
																type: 'statsPDF',
															}),
															'0.key',
															false
														);
														let video = op.get(
															filter(pdeDataRemap[i.match_id], {
																type: 'video',
															}),
															'0.key',
															false
														);
														let videoStats = op.get(
															filter(pdeDataRemap[i.match_id], {
																type: 'videoStats',
															}),
															'0.key',
															false
														);
														return (
															<tr key={`match-${i.match_id}-${idx}`}>
																<td>{i.match_id}</td>
																<td>
																	{op.get(pdeDataRemap, i.match_id + '.matchId', '')}
																</td>
																<td className="match-player">
																	{`${op.get(i, 'team1.0.firstNameA', '') ||
																		''} ${op.get(i, 'team1.0.lastNameA', '') ||
																		''}`}
																	<br />
																	{`${op.get(i, 'team1.0.firstNameB', '') ||
																		''} ${op.get(i, 'team1.0.lastNameB', '') ||
																		''}`}
																</td>
																<td className="match-player">
																	{`${op.get(i, 'team2.0.firstNameA', '') ||
																		''} ${op.get(i, 'team2.0.lastNameA', '') ||
																		''}`}
																	<br />
																	{`${op.get(i, 'team2.0.firstNameB', '') ||
																		''} ${op.get(i, 'team2.0.lastNameB', '') ||
																		''}`}
																</td>
																<td>{statsPdf ? 'Yes' : ''}</td>
																<td>
																	{video && (
																		<a
																			href={`https://wim-a.akamaihd.net/playerportal/${values.tournamentYear}/${video}`}
																			target="_blank">
																			{video}
																		</a>
																	)}
																</td>
																<td>{videoStats ? 'Yes' : ''}</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</Fragment>
									);
								})}
							</div>
						</div>
					</div>

					<Footer />
				</Template>
			);
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main news pde-match-stats" id="main" tabIndex="0">
						<div className="column-layout">
							<div className="four-col multi">
								<LoadingIndicator />
							</div>
						</div>
					</div>

					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PDEContentDash);

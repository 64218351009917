import ResultsPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

// tournDay will be a field in the params obj.
// route is day<dayNum>.html
export default {
	//path:"/:lang/scores/results/(index|day):tournDay(|\\d|\\d\\d).html",
	path: ['/:lang/scores/results/index.html', '/:lang/scores/results/day:tournDay.html'],
	exact: true,
	component: ResultsPage,
	section: MENU_ITEMS.scoresSchedule,
	load: params => deps.actions.ResultsPage.mount(params),
};

import React from 'react';
import isEqual from 'lodash/isEqual';

const StatusDevice = ({ court, type = '', courtKey }) => {
	const isSummaryBox = type === 'summary-box';

	const innerContent = (
		<div className="status-device__circle">
			<div
				className={`status-device__circle--overlapping-outer`}
				style={{
					borderColor:
						court?.courtsAvailable === 0 && isSummaryBox
							? 'rgb(201, 32, 52)'
							: isSummaryBox
							? 'rgb(28, 68, 28)'
							: '#fff',
				}}>
				<p
					className={`status-device__circle--overlapping-outer-number${
						type === 'summary-box' ? '--inverted' : ''
					}`}>
					{court?.courtsAvailable}
				</p>
			</div>
			<div className={`status-device__circle--overlapping-inner`}>
				<div className={`status-device__circle--overlapping-inner-number`}>{court?.courtsUnavailable}</div>
			</div>
		</div>
	);

	// only render as link if is type summer-box
	if (isSummaryBox) {
		return (
			<a className="status-device" href={'#'.concat(courtKey)}>
				{innerContent}
				{isSummaryBox && <p>{court?.name}</p>}
			</a>
		);
	}
	return (
		<div className="status-device__not-clickable">
			{innerContent}
			{isSummaryBox && <p>{court?.name}</p>}
		</div>
	);
};

export default StatusDevice;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const getMatchStats = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			return response;
		})
		.then(({ data }) => {
			return {
				team1: data.matches[0].team1,
				team2: data.matches[0].team2,
				base_stats: data.matches[0].base_stats.match,
				serve_stats: data.matches[0].serve_stats.match,
				distance_run: data.matches[0].distance_run.match,
			};
		});
};

export default {
	getMatchStats,
};

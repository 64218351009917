import React from 'react';
import InputBox from './elements/InputBox';

const Communication = ({ data }) => {
	//gigya-conditional:className="viewport.width < 500?gigya-screen v2 portrait mobile: gigya-screen v2 portrait"
	let viewClass = 'gigya-screen v2 portrait mobile';

	//gigya-conditional:show-password-strength="viewport.width < 900?up"
	let strengthInfo = 'up';

	return (
		<div id="gigya-communication-screen" data-width="auto" className={viewClass} data-caption="screenset.translations['GIGYA_COMMUNICATION_SCREEN_CAPTION']">
			<form className="gigya-profile-form" data-on-success-screen="_finish" id="gigya-profile-form">
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row" />
						<div className="gigya-layout-row" />
					</div>
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row" />
						<div className="gigya-layout-row" />
						<div className="gigya-layout-row" />
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.newsletter.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_112469301310216200_DATA_LABEL']"
						/>
					</div>
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.favourites.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_143290718280911120_DATA_LABEL']"
						/>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.museum.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_18874949537483910_DATA_LABEL']"
						/>
					</div>
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.foundation.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_144548340434404450_DATA_LABEL']"
						/>
					</div>
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.ticketsAndBallots.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_43117642821038280_DATA_LABEL']"
						/>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.debentures.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_61680133850206340_DATA_LABEL']"
						/>
					</div>
				</div>
				<div className="gigya-layout-ro ui-sortablew">
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.merchandise.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_123265448607210610_DATA_LABEL']"
						/>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell" />
					<div className="gigya-layout-cell" />
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell" />
					<div className="gigya-layout-cell" />
				</div>
				<div className="gigya-layout-row subscribe-wrapper">
					<div className="gigya-composite-control gigya-composite-control-subscription-widget">
						<div
							className="gigya-subscription"
							name="subscriptions.events.email"
							data-label="screenset.translations['SUBSCRIPTIONWIDGET_65264857200153600_DATA_LABEL']"
						/>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-submit">
						<input type="submit" className="gigya-input-submit" tabIndex="0" value="screenset.translations['SUBMIT_78091265303174380_VALUE']" />
					</div>
					<div className="gigya-clear" />
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell" />
					<div className="gigya-layout-cell" />
				</div>
				<div className="gigya-layout-row" />
				<div className="gigya-clear" />
			</form>
		</div>
	);
};

export default Communication;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import orderBy from 'lodash/orderBy';

class MenuSelect extends Component {
	constructor(props) {
		super(props);

		//logger.log('[MenuSelect] constructor - props:%o', props);
		let selected = props.selected;
		if (!selected) {
			selected = props.attributes.options[0].value;
		}
		this.state = {
			...this.props,
			selected: selected,
			textoptions: orderBy(props.attributes.options, ['sort'], ['asc']),
			dropdownoptions: orderBy(props.attributes.options, ['sort'], ['desc']),
		};
		logger.log('[MenuSelect] constructor props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[MenuSelect] componentWillReceiveProps - nextProps:%o state:%o', nextProps,this.state);

		let selected = nextProps.selected;
		if (!selected) {
			selected = nextProps.attributes.options[0].value;
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				selected: selected,
			};
		});
		//logger.log('[MenuSelect] componentWillReceiveProps: state:%o', this.state);
	}

	getName(value) {
		let name = '';

		this.state.attributes.options.forEach(item => {
			if (item.value == value) {
				//logger.log('[MenuSelect] getName - item:%o value:%o name:%o', item, value, item.name);
				name = item.title;
			}
		});

		return name;
	}

	showMenu() {
		if (!this.state.show) {
			this.props.onOpen(this.state.name);
		}

		this.setState({
			show: !this.state.show,
		});
	}

	setSelected(selected, name) {
		logger.log('[MenuSelect] setSelected:%o', selected);
		this.setState({
			selected: selected,
			name: name,
			show: false,
		});
		this.props.onSelected(selected);
	}

	render() {
		logger.log('[MenuSelect] render - state:%o', this.state);
		let arrow = ' ▾';
		//let {attributes} = this.state;
		let title = this.getName(this.state.selected);

		//logger.log("OPTIONS: show:%o, options:%o",this.state.show, options);
		return (
			<div className="select-menu" data-type={this.state.mode}>
				<button className="title" onClick={() => this.showMenu()}>
					<span className="menu-text">{title}</span>
					<span className="menu-arrow">{arrow}</span>
				</button>
				<div className={`select-menu-dropdown mobile  ${this.state.show ? 'show' : ''}`}>
					{this.state.dropdownoptions.map(({ dropdownName, value, type, title, disabled }) => {
						//logger.log('[MenuSelect] name:%o value:%o type:%o title:%o', name, value, type, title);

						if (value != 'section' && !disabled) {
							return (
								<button
									key={value + type}
									className={this.state.selected === value ? 'option selected clicked' : 'option'}
									onClick={
										title == 'Middle Sunday' || disabled
											? null
											: () => this.setSelected(value, dropdownName)
									}
									data-type={type}
									data-day={value}>
									<i className="wim-icon-check" />
									{dropdownName}
								</button>
							);
						} else {
							return null;
						}
					})}
				</div>
				<div className={`select-menu-dropdown tablet`}>
					<button data-type="label">Day</button>
					{this.state.textoptions.map(({ textName, value, type, title, disabled }) => {
						//logger.log('[MenuSelect] name:%o value:%o type:%o title:%o', name, value, type, title);

						if (value != 'section') {
							let buttonClasses = classNames({
								option: true,
								selected: this.state.selected === value,
								clicked: this.state.selected === value,
								disabled: disabled,
							});
							return (
								<button
									key={value + type}
									//className={this.state.selected === value ? 'option selected clicked' : 'option'}
									className={buttonClasses}
									onClick={
										title == 'Middle Sunday' || disabled
											? null
											: () => this.setSelected(value, textName)
									}
									data-type={type}
									data-day={value}>
									<i className="wim-icon-check" />
									{textName}
								</button>
							);
						} else {
							return (
								<div className="section" key={textName}>
									{textName}
								</div>
							);
						}
					})}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DaySelect);

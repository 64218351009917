/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import deps from 'dependencies';
import { getRole } from 'appdir/components/general/Util/Role';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

import PlayerStatusWidget from 'appdir/components/cms/PlayerStatusWidget';
import EventNavigator from 'appdir/components/cms/EventNavigator';
import ChampionshipsRegistration from 'appdir/components/common-ui/ChampionshipsRegistration';
import TabNavigation from 'appdir/components/cms/TabNavigation';
import ShopCarousel from 'appdir/components/cms/ShopCarousel';
import BallotStatus from 'appdir/components/cms/BallotStatus';
import MyWPromo from 'appdir/components/cms/MyWPromo';
import RoleNavigation from 'appdir/components/general/Util/ServiceIcons/RoleNavigation';
import CenterMarkTitle from 'appdir/components/common-ui/CenterMarkTitle';
import Divider from 'appdir/components/common-ui/Divider';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: SimpleComponent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		currentUser: state['Gigya']['currentUser'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	// show: matchId => dispatch(deps.actions.SlamtrackerPanel.show(matchId)),
});

class SimpleComponent extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	/**
	 * render proper component for tag passed from CMS SimpleComponent
	 * - ChampionshipRegistration requires logged in player so check here not strictly necessary
	 * - PlayerStatusWidget does not necessarily require a logged in player role, so extra check added here
	 */
	render() {
		let tag = op.get(this.state, 'data.tag', null) || op.get(this.state, 'tag', null);
		let playerRole = getRole(op.get(this.state.currentUser, 'roles', []), 1000050);
		let playerId = op.get(this.state, 'playerId', null);
		if (!playerId) {
			playerId = op.get(playerRole, 'contactDetails.ibmTourId', null);
		}

		logger.log('[SimpleComponent] render state:%o playerRole:%o', this.state, playerRole);

		switch (tag) {
			case 'PdeEventNavigator':
				return <EventNavigator />;
				break;

			case 'PlayerStatusComponent':
				if (playerId)
					return (
						<ErrorBoundary message="Error in PlayerStatusWidget">
							<PlayerStatusWidget key={'player-widget'} playerId={playerId} />
						</ErrorBoundary>
					);
				else return null;
				break;

			case 'ChampionshipRegistration':
				if (playerId)
					return (
						<ErrorBoundary message="Error in ChampionshipsRegistration">
							<ChampionshipsRegistration type="inline" class="pl" />
						</ErrorBoundary>
					);
				else return null;
				break;

			case 'shopCarousel':
				return (
					<ErrorBoundary message="Error in Shop Carousel">
						<ShopCarousel />
					</ErrorBoundary>
				);
				break;

			case 'myWPromo':
				return (
					<ErrorBoundary message="Error in MyWPromo">
						<MyWPromo />
					</ErrorBoundary>
				);
				break;

			case 'ballotStatus':
				return <BallotStatus style={'two-col margin'} />;
				break;

			case 'tabNav_memberBookings':
				return (
					<div className="four-col ">
						<ErrorBoundary message="Error in Member Navigation">
							<TabNavigation data={{ key: 'memberBookings', default: 'Members' }} />
						</ErrorBoundary>
					</div>
				);
				break;

			case 'tabNav_memberMembership':
				return (
					<div className="four-col ">
						<ErrorBoundary message="Error in Member Navigation">
							<TabNavigation data={{ key: 'memberMembership', default: 'Members' }} />
						</ErrorBoundary>
					</div>
				);
				break;

			case 'tabNav_memberFixtures':
				return (
					<div className="four-col ">
						<ErrorBoundary message="Error in Member Navigation">
							<TabNavigation data={{ key: 'memberFixtures', default: 'Members' }} />
						</ErrorBoundary>
					</div>
				);
				break;

			case 'tabNav_memberFinance':
				return (
					<div className="four-col ">
						<ErrorBoundary message="Error in Member Navigation">
							<TabNavigation data={{ key: 'memberFinance', default: 'Members' }} />
						</ErrorBoundary>
					</div>
				);
				break;

			case 'tabNav_playerLanding':
				return (
					<ErrorBoundary message="Error in Player Landing Nav">
						<TabNavigation data={{ key: 'playerLanding', default: 'Players' }} />
					</ErrorBoundary>
				);
				break;
			
			case 'iconShelf':
				let roles = op.get(this.state.currentUser, 'roles', []);
				logger.log('[SimpleComponent] render roles:%o',roles);
				return(					
					<ErrorBoundary message="Error in Role Navigation">
						{roles.length > 0 && <RoleNavigation />}
					</ErrorBoundary>
				)
				break;
				
			case 'category':
				return (
					<ErrorBoundary message="Error in Category">
						<div className='category-heading'>
							<Divider />
							<CenterMarkTitle text={this.state.data?.title} />
						</div>
					</ErrorBoundary>
				)
				break;
			default:
				return null;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SimpleComponent);

import React from 'react';

const ResetPasswordFail = ({ data }) => {
	//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
	let classname = 'gigya-screen v2 portrait';

	return (
		<div
			id="gigya-reset-password-fail-screen"
			data-width="auto"
			className={classname}
			data-caption="screenset.translations['GIGYA_RESET_PASSWORD_SCREEN_CAPTION']">
			<div className="gigya-layout-row">
				<label
					className="gigya-composite-control gigya-composite-control-label gigya-message"
					data-translation-key="LABEL_RESET_PASSWORD_FAIL"
				/>
			</div>
			<div className="gigya-layout-row">
				<a
					data-switch-screen="_finish"
					tabIndex="-1"
					className="gigya-composite-control gigya-composite-control-link gigya-button-link"
					style={{ textAlign: 'center' }}
					data-translation-key="LINK_BUTTON_LOGIN_LABEL"
				/>
			</div>
		</div>
	);
};

export default ResetPasswordFail;

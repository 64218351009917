/**
 ScoreManager Initial State
*/

export default {
	// liveMatches: [],
	status: {
		started: false,
		loaded: false,
		mip: false,
		sidepanel: false,
		slamtracker: false,
	},
	statMatch: {
		pointHistory: [],
		stats: {},
	},
};

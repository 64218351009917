/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, PureComponent } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoBackground
 * -----------------------------------------------------------------------------
 */
class VideoBackground extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		logger.log('[VideoBackground] render - state:%o', this.state);
		if (this.state.display) {
			return (
				<video
					id="videobg"
					data-src={`${this.state.videoSrc}`}
					src={`${this.state.videoSrc}`}
					poster={`${this.state.imageSrc}`}
					loop={true}
					autoPlay
					muted
					playsInline
				/>
			);
		} else {
			return null;
		}
	}
}

export default VideoBackground;

import Tickets from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/s/tickets/:page(app|view|return).html', '/s/tickets/:page(app|view|return)'],
		exact: true,
		component: Tickets,
		load: params => deps.actions.Tickets.mount(params),
	},
];

import DrawsArchiveContentPage from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:prefix(preview)?/:lang/draws_archive/champions/:champion.html',
		//order: 11,
		exact: true,
		component: DrawsArchiveContentPage,
		section: 'Players',
		load: params => deps.actions.DrawsArchiveContentPage.mount(params),
	},

	{
		path: '/:lang/draws_archive/:fact(fast_facts).html',
		//order: 12,
		exact: true,
		component: DrawsArchiveContentPage,
		section: 'Draws',
		load: params => deps.actions.DrawsArchiveContentPage.mount(params),
	},

	{
		path: '/:prefix(preview)?/:lang/draws_archive/history/:history.html',
		//order: 11,
		exact: true,
		component: DrawsArchiveContentPage,
		section: 'Draws',
		load: params => deps.actions.DrawsArchiveContentPage.mount(params),
	},
];

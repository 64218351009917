import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.DRAWSARCHIVE_PROFILE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[PlayerProfile] reducer - DRAWSARCHIVE_PROFILE_MOUNT: %o', newState);
			return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_PROFILE_UNMOUNT:
			newState = {
				...state,
			};

			logger.log('[PlayerProfile] reducer - DRAWSARCHIVE_PROFILE_UNMOUNT: %o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

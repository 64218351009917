/**
 Header Initial State
*/

export default {
	imageSrc: '/assets/images/headers/generic_default.jpg',
	scrollElem: '.content-main',
	scroll: false,
	headerTitle: '',
	title: '',
	shortTitle: '',
	stickyOn: false,
	statusPanel: 'small',
	htsearch: '',
	type: null,
	fullwindowVideo: null,
};

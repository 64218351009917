import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const FeaturedPlayers = ({ players, featuredPlayer, config }) => {
	if (!players || !featuredPlayer) {
		return <div />;
	}

	const renderPlayers = players
		.filter(player => player.id == featuredPlayer)
		.map(player => (
			<div key={player.id}>
				<Link to={`/en_GB/players/overview/${player.id}.html`}>
					<img className="fphoto" src={player.id ? config.playerProfileImagePath.replace('<playerid>', player.id) : ''} />
					<div className="ftitle">
						<img className="flag" src={player.country ? config.flagImagePathLarge.replace('<code>', player.country) : <Skeleton />} />
						<div className="fname">
							{player.first_name} {player.last_name}
						</div>
					</div>
				</Link>
			</div>
		));

	return <div className="fplayer">{renderPlayers}</div>;
};
export default FeaturedPlayers;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import { values } from 'appdir/main';
import { doMeasurement } from 'appdir/components/general/Analytics';
import { getSelectedMatchFromDrawsPath } from 'appdir/components/pages/DrawsPage/DrawsUtils';

import classNames from 'classnames';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import DrawsMenu from 'appdir/components/pages/DrawsPage/DrawsMenu';
import PlayerImage from 'appdir/components/content/PlayerImage';
import CountryFlagImage from 'appdir/components/common-ui/CountryFlagImage';
import PlayerLink from 'appdir/components/common-ui/PlayerLink';

/*
 * -----------------------------------------------------------------------------
 * Functional Component: DrawsFullMatchBox
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['DrawsFullMatchBox'],
		favourites: state['Controller'].favourites,
		searchedPlayer: state['DrawsPage'].searchedPlayer,
		searchRound: state['DrawsPage'].searchRound,
		selectedRound: state['DrawsPage']['eventRound'].selectedRound,
		timestamp: state['DrawsPage']['timestamp'],
		found: state['DrawsPage']['found'],
		drawAnalysisData: state['Tournament']?.data?.drawAnalysis,
		drawPathData: state['Tournament']?.data?.drawPath,
		drawData: state['Tournament']?.data?.draw,
		playerStatusData: state['Tounament']?.data?.playerStatus,
		...props,
	};

	this.onHover = this.onHover.bind(this);
};
const mapDispatchToProps = (dispatch, props) => ({
	updateFound: flag => dispatch(deps.actions.DrawsPage.updateFound(flag)),
	showPathToTheFinal: (playerId, event, top) =>
		dispatch(deps.actions.PathToTheFinal.showPathToTheFinal(playerId, event, top)),
	showSlamtracker: (matchId, defaultTab) => dispatch(deps.actions.SlamtrackerPanel.show(matchId, defaultTab)),
});

class DrawsFullMatchBox extends Component {
	constructor(props) {
		// logger.log("[DrawsFullMatchBox] constructor");
		super(props);
		this.state = { ...props };

		this.scoreData = this.state.attributes.data;
		this.showLinks = this.state.attributes.showLinks;

		this.winnerTeam = this.scoreData.team1.won ? 'team1' : this.scoreData.team2.won ? 'team2' : '';

		const mainProfilePath = '/en_GB/players/overview/';
		const archiveProfilePath = './player_profile/';

		this.profileLinks = this.state.attributes.profileLinks === 'main' ? mainProfilePath : archiveProfilePath;

		this.boxStyle = this.state.attributes.style ? this.state.attributes.style : '';

		this.iconClasses_t1 = classNames('crticon', this.scoreData.team1.won ? 'winner' : '');
		this.iconClasses_t2 = classNames('crticon', this.scoreData.team2.won ? 'winner' : '');

		this.flagImagePathSmall = this.state.attributes.flagImagePathSmall;

		this.playerProfileImagePath = this.state.attributes.playerProfileImagePath;

		this.emptySets = [];
		this.scrollPlayer = false;
		for (var i = 0; i < 5 - this.scoreData.scores.sets.length && this.scoreData.scores.sets.length < 5; i++) {
			this.emptySets[i] = 5 - i;
		}
		this.drawRef = React.createRef();

		this.mobileMatch = window.matchMedia('(max-width: 767px)').matches;
		this.desktopMatch = window.matchMedia('(min-width: 1024px)').matches;

		/** MS or LS AND is Config.enabled.drawAnalysis */
		this.eventCode = this.props?.attributes?.data?.eventCode;
		this.isAiDraws = this.props?.attributes?.isAiDraws;
		this.isFinalRound = this.scoreData?.roundCode == 'F';

		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.setPlayerInfo = this.setPlayerInfo.bind(this);
	}
	componentDidMount() {}
	componentWillUnmount() {}
	componentDidUpdate(prevProps, prevState) {
		if (!this.state.found) {
			this.scrollPlayer = true;
		}

		if (this.drawRef.current && this.scrollPlayer && this.state.searchedPlayer !== undefined) {
			let playerArry = [
				this.state.attributes.data.team1.idA,
				this.state.attributes.data.team1.idB,
				this.state.attributes.data.team2.idA,
				this.state.attributes.data.team2.idB,
			].filter(d => d !== null);
			let foundPlayer = playerArry.indexOf(this.state.searchedPlayer) !== -1 ? true : false;
			let round = parseInt(this.drawRef.current.dataset.round);
			if (foundPlayer && round === parseInt(this.state.searchRound)) {
				this.scrollPlayer = false;
				this.determinePlayerScroll();
			}
		}
	}

	determinePlayerScroll() {
		if (this.drawRef.current && this.drawRef.current !== null) {
			let el = this.drawRef.current;
			let elOffset = el.offsetTop;
			let offset = 106; //height of the header
			window.scroll({
				top: elOffset - offset,
				behavior: 'smooth',
			});
		}
		this.state.updateFound(true);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[DrawsFullMatchBox] componentWillReceiveProps nextProps:%o',nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		// logger.log('[DrawsFullMatchBox] componentWillReceiveProps state:%o',this.state);
	}

	onHover(pid) {
		if (!pid) {
			pid = '';
		}
		this.props.onHovered(pid);
	}

	getSlamTrackerLinkBox(active) {
		/**
		 * if the match has not begun and this match has match insights, show pre-match analysis button
		 */
		if (this.scoreData.statusCode == 'B' && this.hasMatchInsights) {
			return (
				<Button
					to={`/en_GB/matchinsights/${this.scoreData.match_id}.html`}
					className="compact slamtracker-stats"
					style={{ width: '100%' }}>
					Match Insights
				</Button>
			);
		} else if (
			/**
			 * if the match is boys or girls quals
			 */
			this.scoreData.eventCode == 'BQ' ||
			this.scoreData.eventCode == 'GQ'
		) {
			return (
				<Button className="compact slamtracker-stats disabled" style={{ width: '100%' }}>
					Stats by Slamtracker
				</Button>
			);
		} else if (
			/**
			 * if the match has not begun and this match will have match insights (LS or MS), show disabled pre-match analysis button
			 */
			this.scoreData.statusCode == 'B' &&
			(this.scoreData.eventCode == 'MS' || this.scoreData.eventCode == 'LS') &&
			!this.hasMatchInsights
		) {
			return (
				<Button className="compact slamtracker-stats disabled" style={{ width: '100%' }}>
					Match Insights
				</Button>
			);
		} else if (
			/**
			 * if the match has not begun and this match will not have match insights (not LS and not MS) show disabled slamtracker button
			 */
			this.scoreData.statusCode == 'B' &&
			(this.scoreData.eventCode != 'MS' || this.scoreData.eventCode != 'LS') &&
			!this.hasMatchInsights
		) {
			return (
				<Button className="compact slamtracker-stats disabled" style={{ width: '100%' }}>
					Stats by Slamtracker
				</Button>
			);
		} else if (this.scoreData.statusCode != 'B') {
			/**
			 * if the match has begun, show the enabled slamtracker button
			 */
			return (
				<Button
					to={`/en_GB/scores/stats/${this.scoreData.match_id}.html`}
					className="compact slamtracker-stats"
					style={{ width: '100%' }}>
					Stats by Slamtracker
				</Button>
			);
		}
	}

	getPlayerLink(id, name) {
		if (id) {
			return <WimLink to={`${this.profileLinks}${id}.html`}>{name}</WimLink>;
		} else {
			return name;
		}
	}

	getPlayerClasses = (base, playerId) => {
		let classes = base;
		if (playerId) {
			if (window.webview) {
				// logger.log('[DrawsMatchBox] getPlayerClass - playerId:%o,  favs:%o', playerId, this.props?.webViewFavs);
				if (this.props?.webViewFavs) {
					classes += this.props?.webViewFavs.indexOf(playerId) !== -1 ? ' favorite' : '';
				}
			} else {
				classes +=
					this.state.favourites.show && this.state.favourites.players.indexOf(playerId) !== -1
						? ' favorite'
						: '';
			}

			classes += this.state.searchedPlayer === playerId ? ' searchedPlayer' : '';
		}

		return classes;
	};

	/**
	 *  this.isAiDraws == true --- the name click doesn't do anything
	 *                             the click is added to the match box itself
	 *                             to change colors
	 *  normal events (www) ------ show the context menu
	 *  mobile size -------------- straight open Path to the Final
	 *  webview ------------------ straight open Path to the Final
	 */
	playerNameClick = (id, name) => {
		if (!this.isAiDraws && id) {
			if (!this.mobileMatch && !values.webview) {
				this.showMenu(id, name);
			} else {
				this.showPathToTheFinal(id, this.eventCode);
			}
		}
	};

	showDrawAnalysis = (id, position) => {
		let aiDrawPid = this?.props?.attributes?.aiDrawData?.aiDrawPid;
		let drawPathData = this.props?.drawPathData;
		let matchId = this.scoreData.match_id;

		/** if no id is passed and there is highlight,
		 *  this is the selected player to display AI Draws
		 */
		let isAiHighlighted = this.getHighlightClass(id, position);

		if (!this.mobileMatch) {
			/** tablet apps directly open Path to the Final */
			if (window?.webview) {
				this.showPathToTheFinal(id, this.eventCode);
			} else if (this.isAiDraws) {
				/** only for MS or LS, callback to pull AI Draw related data and set highlight
				 *  if id doesn't exist, that means it's a future unscheduled match,
				 *  check if drawPath data is there and get player ID from it
				 */
				/** unscheduled future match */
				if ((id == '' || !id) && aiDrawPid && matchId && drawPathData) {
					/**
					 * find the match from drawPath for the same matchId */
					let matchedMatch = getSelectedMatchFromDrawsPath(drawPathData, matchId);

					/**
					 *  make clickable
					 *  - when the selected player's name is displayed
					 *  - Final round is clicked and final round match id exists in drawPathData (lost players are not clickable on Final)
					 *
					 */
					if ((matchedMatch && isAiHighlighted) || (this.isFinalRound && matchedMatch)) {
						/**
						 *   for Final box
						 *   parseInt(matchedMatch?.round) == 7
						 */
						this.props?.onAiDraws(aiDrawPid);

						/** no apps for this */
						doMeasurement(
							'Draws',
							'Show Draw Analysis',
							[],
							[{ player_id: aiDrawPid }, { match_id: this.scoreData.match_id }]
						);
					}
				} else if (id) {
					this.props?.onAiDraws(id);

					/** no apps for this */
					doMeasurement(
						'Draws',
						'Show Draw Analysis',
						[],
						[{ player_id: id }, { match_id: this.scoreData.match_id }]
					);
				}
			}
		} else if (id) {
			/** for mobile view, directly open Path to the Final */
			this.showPathToTheFinal(id, this.eventCode);
		}
	};


	onOpenSlamtracker = (id, match_id, defaultTab = 'overview') => {
		if (match_id) {
			logger.log(
				'[DrawsFullMatchBox] onOpenSlamtracker - id:%o, match_id:%o, defaultTab:%o',
				match_id,
				match_id,
				defaultTab
			);

			doMeasurement(
				'Draws',
				'playerMenuItem',
				[],
				[{ player_id: id }, { match_id: match_id }, { item: 'Likelihood to Win' }]
			);

			this.props.showSlamtracker(match_id, defaultTab);
		}
	};

	/** call action to activate the Path to the Final overlay */
	showPathToTheFinal = (id, event = this.eventCode, metricsAction = null) => {
		if (id) {
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (document.documentElement || document.body.parentNode || document.body).scrollTop;

			let metricsVal = [];
			let data = metricsAction ? { item: metricsAction } : null;
			let contextData = [{ player_id: id }, { match_id: this.scoreData.match_id }];

			if(data) {
				contextData.push(data);
			}

			if (values.webview) {
				metricsVal = [metricsAction ? metricsAction : ''];
			}

			doMeasurement('Draws', 'Path To The Final', metricsVal, contextData);
			this.props.showPathToTheFinal(id, event, scrollTop);
		}
	};

	/** applies to the name div to switch among ai draw color, favorite and search */
	getHighlightClass = (id, position = null) => {
		// logger.log(
		// 	'[DrawsFullMatchBox] getHighlightClass - id:%o, matchId:%o',
		// 	id,
		// 	matchId
		// );

		let { aiDrawData } = this?.props?.attributes;
		let drawPathData = this.props?.drawPathData;
		let matchId = this.scoreData.match_id;

		/** because not scheduled match doesn't have players assigned in the data,
		 *  we can't check using --> aiDrawData?.aiDrawPid == id
		 */
		if (aiDrawData?.showAiDrawBg && drawPathData?.status == 'loaded') {
			let drawLabel = '';

			/** make sure the drawPath data is for this player */
			if (drawPathData?.player?.idA == id && id !== '') {
				let selectPath = drawPathData?.data.filter(path => path.match == matchId)?.[0];

				/** for past matches, highlight to be gray */
				selectPath?.result == 'vs'
					? (drawLabel = selectPath.label)
					: selectPath?.result == 'won' || selectPath?.result == 'lost'
					? (drawLabel = 'post-match-color')
					: (drawLabel = '');
			} else if (matchId && (id == '' || !id) && drawPathData) {
				/** for future match that's not scheduled - no player id available
				 *
				 *  get the last match info from Player Status and use the team number
				 *  to highlight all the way
				 *
				 *  Finals match box
				 *  Final is scheduled ----- clicing on it should highlight and show the selected player's draw analysis
				 *  Final is not schedled -- No highlight but if the player is still In show Finals text with Power Index CTA button in the draw analysis box
				 *
				 */
				/** find the match from drawPath for the same matchId */
				let matchedMatch = getSelectedMatchFromDrawsPath(drawPathData, matchId);

				if (matchedMatch) {
					drawLabel =
						matchedMatch.position == position && parseInt(matchedMatch?.round) !== 7
							? matchedMatch.label
							: '';
				}
			}

			return drawLabel;
		}
	};

	/**
	 * 
	 * @param {*} player_name 
	 * @returns bool to print out Qualifier or Lucky Loser
	 */
	isSpecialEntry = (player_name) => {

		let isSpecial = false;

		if (player_name) {
			isSpecial = (player_name?.toLowerCase() == "qualifier") || (player_name?.toLowerCase()?.includes("lucky"))
		}

		return isSpecial;
	}

	/**
	 *
	 * @param {*} playerId
	 * @param {*} playerName
	 * @param {*} countryCode
	 * @param {*} seed
	 * @param {*} position
	 * @returns
	 *
	 *  get player's name, flag from draws data or draws path data
	 */
	getFlagAndPlayerNameValues = (playerId, playerName, countryCode, seed, entryStatus, position) => {
		let drawPathData = this.props?.drawPathData;
		let showL2POpponents = this.props?.attributes?.aiDrawData?.showL2P;

		/** make sure the aiDrawPid is the same as the drawPath data pid */
		const isTheSamePlayer = drawPathData?.player?.idA == this.props?.attributes?.aiDrawData?.aiDrawPid;

		/** if aiHighlight has a value, it means this is the AI Draw selected player */
		let aiHighlight = this.getHighlightClass(playerId, position);
		/** find the same match in draw data */

		let matchedMatch;
		if (drawPathData?.data) {
			matchedMatch = getSelectedMatchFromDrawsPath(drawPathData, this.scoreData.match_id);
		}
		let isTheSameMatch = this.scoreData.match_id && matchedMatch;

		/** checks Qualifier or Special Loser */
		let isSpecialEntry = this.isSpecialEntry(playerName);

		let flagCode, pid;
		let pName,
			pseed,
			pStatus,
			opponentClass = '';

		/** if the playerName is for place holder qualifier, use data from draws feed  */
		if ((playerId, playerName, countryCode) || isSpecialEntry) {
			/** for normal case - data from draws feed  */
			pName = playerName;
			pid = playerId;
			flagCode = countryCode;
			pseed = seed;
			pStatus = entryStatus;
		} else if ((!playerName || playerName == '') && isTheSamePlayer && aiHighlight && !this.isFinalRound) {
			/** for future unscheduled match - get player info from drawsPath data
			 *  if highlighted, this is the selected player
			 *  don't show player name in Final match unless it's scheduled
			 */
			pName = drawPathData?.player?.displayNameA;
			pid = drawPathData?.player?.idA;
			flagCode = drawPathData?.player?.nationA;
			pseed = drawPathData?.player?.seed ? drawPathData?.player?.seed : '';
		} else if (
			/** for future unscheduled match -
			 *  get most likley to play opponent player info from drawsPath
			 *  don't show most likely to play player in Final match */
			(!playerName || playerName == '') &&
			isTheSamePlayer &&
			isTheSameMatch &&
			!aiHighlight &&
			!this.isFinalRound
		) {
			// let drawPathData = this.props?.drawPathData;
			let opponentsData = matchedMatch?.opponents?.[0];
			opponentClass = 'ai-opponent-name';

			if (showL2POpponents) {
				pName = opponentsData?.nameA;
				pid = opponentsData?.playerIdA;
				flagCode = opponentsData?.nationA;
				pseed = opponentsData?.seed ? opponentsData?.seed : '';
				opponentClass = 'ai-opponent-name';
			}
		}

		return {
			pName,
			pid,
			flagCode,
			pseed,
			pStatus,
			opponentClass,
		};
	};

	/**
	 *  scheduled match ------------------------ display the player name from the draws feed
	 *  unscheduled and highlighhted ----------- display the aiDrawPid name
	 *  unscheduled and not highlighted,
	 *  but the same match id as highlighted,
	 *  AND aiDrawData.showL2P == true --------- display most likley to play opponent
	 */
	getFlagAndPlayerName = (playerId, playerName, countryCode, seed, entryStatus, position, readScore) => {
		let playerNameData = this.getFlagAndPlayerNameValues(
			playerId,
			playerName,
			countryCode,
			seed,
			entryStatus,
			position
		);
		let isOverThirdRound =
			(this.eventCode == 'MS' && parseInt(this.scoreData?.match_id) > 1300) ||
			(this.eventCode == 'LS' && parseInt(this.scoreData?.match_id) > 2300);

		let pName = playerNameData?.pName;
		let pid = playerNameData?.pid;
		let flagCode = playerNameData?.flagCode;
		let pseed = playerNameData?.pseed;
		let pStatus = playerNameData?.pStatus;
		let opponentClass = playerNameData?.opponentClass;

		/** checks Qualifier or Special Loser */
		let isSpecialEntry = this.isSpecialEntry(pName);

		/** find the first unscheduled future match */
		let firstFutureMatch;

		if (this.props?.drawPathData?.data?.length > 0) {
			firstFutureMatch = this.props?.drawPathData?.data?.find(d => d.opponents.length > 1);
		}

		/** a flag to reveal the most likely to play opponent name in the match box  */
		let showL2POpponents = this.props?.attributes?.aiDrawData?.showL2P;

		/** show CTA text for the first future match to display most likely to play opponent names */
		if (
			firstFutureMatch?.match == this.scoreData.match_id &&
			opponentClass !== '' &&
			opponentClass &&
			!showL2POpponents
		) {
			return (
				<div className="view-more-opponents-cta">
					<ButtonLinkText onClick={() => this.props?.onShowl2pOpponents(this.scoreData.match_id)}>
						See IBM Likely to Play
					</ButtonLinkText>
				</div>
			);
		} else {
			return (
				<>
					<div className={`${this.getPlayerClasses('member-one', pid)}`}>
						<div className="flag">
							{this.flagImagePathSmall && flagCode ? (
								<img src={this.flagImagePathSmall.replace('<code>', flagCode)} />
							) : null}
						</div>
						<div className={`name ${opponentClass}`} id={`${pid}`}>
							{(opponentClass && opponentClass !== '') || isSpecialEntry ? (
								pName
							) : (
								<ButtonLinkText
									ariaLabel={`${pName} bio`}
									className=""
									name={pName}
									onClick={() => this.playerNameClick(pid, pName)}>
									{pName}
								</ButtonLinkText>
							)}
						</div>
						<div className={`seed ${pStatus ? '' : 'hide'}`}>{pStatus}</div>
						<div className={`seed ${pseed ? '' : 'hide'}`}>{pseed}</div>
					</div>

					{/**
					 * display View More above 3rd round
					 * CTA to open the selected player's Path to the Final
					 *
					 */
					opponentClass !== '' && opponentClass && isOverThirdRound && showL2POpponents && (
						<div className="view-more-opponents">
							<ButtonLinkText
								onClick={() =>
									this.showPathToTheFinal(
										this.props?.attributes?.aiDrawData?.aiDrawPid,
										this.eventCode,
										'View More'
									)
								}>
								View More
							</ButtonLinkText>
						</div>
					)}
				</>
			);
		}
	};

	closeMenu = () => {
		logger.log('[DrawsFullMatchBox] closeMenu - closeMenu:%o, e:%o', this);
		this.setState(
			{
				show: false,
			},
			() => {
				document.removeEventListener('click', this.closeMenu);
				let measureArgs = [];
				let contextData = [{ item: 'Menu' }, { action: 'Close' }];

				if (values.webview) {
					measureArgs = ['Menu', 'Close'];
					contextData = [];
				}
				doMeasurement('Draws', 'Player', measureArgs, contextData);
			}
		);
	};

	showMenu = (id, name) => {
		this.setPlayerInfo(id, name);
		this.setState(
			{
				show: true,
			},
			() => {
				document.addEventListener('click', this.closeMenu);

				let measureArgs = [];
				let contextData = [{ item: 'Menu' }, { action: 'Open' }, { player_id: id }];

				if (values.webview) {
					measureArgs = ['Menu', 'Open'];
					contextData = [{ player_id: id }];
				}

				doMeasurement('Draws', 'Player', measureArgs, contextData);
			}
		);

		logger.log('[DrawsFullMatchBox] showMenu - showMenu:%o,', this);
	};

	setPlayerInfo = (id, name) => {
		logger.log('[DrawsFullMatchBox] setPlayerInfo - id:%o, name:%o', id, name);

		let playerInfo = {};

		if (id) {
			playerInfo.id = id;
		} else {
			playerInfo.id = '';
		}
		if (name) {
			playerInfo.name = name;
		} else {
			playerInfo.name = '';
		}

		logger.log('[DrawsFullMatchBox] setPlayerInfo - playerInfo:%o, this:%o', playerInfo, this);

		this.setState(prevState => {
			return {
				...prevState,
				playerInfo: playerInfo,
			};
		});
	};

	renderRegularMatchBox() {
		let t1_higlight_condition =
			this.state.highlightPid !== '' &&
			(this.state.highlightPid === this.scoreData.team1.idA ||
				this.state.highlightPid === this.scoreData.team1.idB) &&
			(this.scoreData.team1.idA !== '' ||
				this.scoreData.team1.idB !== '' ||
				this.scoreData.team1.idA !== null ||
				this.scoreData.team1.idB !== null);

		let t2_higlight_condition =
			this.state.highlightPid !== '' &&
			(this.state.highlightPid === this.scoreData.team2.idA ||
				this.state.highlightPid === this.scoreData.team2.idB) &&
			(this.scoreData.team2.idA !== '' ||
				this.scoreData.team2.idB !== '' ||
				this.scoreData.team2.idA !== null ||
				this.scoreData.team2.idB !== null);

		let hilightClass_t1 = t1_higlight_condition ? 'highlight' : '';
		let hilightClass_t2 = t2_higlight_condition ? 'highlight' : '';

		let allowSlamtrackerLink = this.scoreData.courtId && this.scoreData.team1.idA && this.scoreData.team2.idA;
		let matchStatusClass = 'match-status ';
		matchStatusClass += this.scoreData.eventName?.includes('Doubles') ? 'doubles ' : 'singles ';
		matchStatusClass +=
			this.scoreData.match_id == this.props?.attributes?.aiDrawData?.aiDrawMatchId ? 'selected ' : '';

		return (
			<div
				className={`match-box match-box-draws full ${this.boxStyle}`}
				data-dna="match-box-draws-page"
				data-match={this.scoreData.match_id}>
				<div className={matchStatusClass} tabIndex={0} aria-label={`match ${this.props.matchNumber}`}>
					{/* <div className="green-border" /> */}
					<div
						className={`team-info team-one ${
							this.scoreData.eventName?.includes('Doubles') ? 'doubles' : 'singles'
						} ${hilightClass_t1}  ${this.getHighlightClass(this.scoreData.team1.idA, 'top')}`}
						onClick={e => (this.isAiDraws ? this.showDrawAnalysis(this.scoreData.team1.idA, 'top') : null)}
						onMouseEnter={() => this.onHover(this.scoreData.team1.idA)}
						onMouseLeave={() => this.onHover('')}>
						<div className="team-name">
							<div className="members">
								{this.getFlagAndPlayerName(
									this.scoreData.team1.idA,
									this.scoreData.team1.displayNameA,
									this.scoreData.team1.nationA,
									this.scoreData.team1.seed,
									this.scoreData.team1.entryStatus,
									'top',
									true
								)}

								<div className={`${this.getPlayerClasses('member-two', this.scoreData.team1.idB)}`}>
									<div className="flag">
										{this.flagImagePathSmall && this.scoreData.team1.nationB ? (
											<img
												alt={this.scoreData.team1.nationB}
												src={this.flagImagePathSmall.replace(
													'<code>',
													this.scoreData.team1.nationB
												)}
											/>
										) : null}
									</div>
									<div className={`name`} id={`${this.scoreData.team1.idB}`}>
										<ButtonLinkText
											className=""
											name={this.scoreData.team1.displayNameB}
											onClick={() =>
												this.playerNameClick(
													this.scoreData.team1.idB,
													this.scoreData.team1.displayNameB
												)
											}>
											{this.scoreData.team1.displayNameB}
										</ButtonLinkText>
									</div>
									<div className={`seed ${this.scoreData.team1.entryStatus ? '' : 'hide'}`}>
										{this.scoreData.team2.entryStatus}
									</div>
									<div className={`seed ${this.scoreData.team1.seed ? '' : 'hide'}`}>
										{this.scoreData.team1.seed}
									</div>
								</div>
							</div>
						</div>
						{this.state.debug ? <div className="debugId">{this.scoreData.match_id}</div> : null}
					</div>
					<div className={`${hilightClass_t1 || hilightClass_t2 ? 'separator-hl' : 'separator'}`}></div>
					<div
						className={`team-info team-two ${
							this.scoreData.team2.displayNameB != null ? 'doubles' : 'singles'
						} ${hilightClass_t2} ${this.getHighlightClass(this.scoreData.team2.idA, 'bottom')}`}
						onClick={() =>
							this.isAiDraws ? this.showDrawAnalysis(this.scoreData.team2.idA, 'bottom') : null
						}
						onMouseEnter={() => this.onHover(this.scoreData.team2.idA)}
						onMouseLeave={() => this.onHover('')}>
						<div className="team-name">
							<div className="members">
								{this.getFlagAndPlayerName(
									this.scoreData.team2.idA,
									this.scoreData.team2.displayNameA,
									this.scoreData.team2.nationA,
									this.scoreData.team2.seed,
									this.scoreData.team2.entryStatus,
									'bottom'
								)}
								<div className={`${this.getPlayerClasses('member-two', this.scoreData.team2.idB)}`}>
									<div className="flag">
										{this.flagImagePathSmall && this.scoreData.team2.nationB ? (
											<img
												alt={this.scoreData.team2.nationB}
												src={this.flagImagePathSmall.replace(
													'<code>',
													this.scoreData.team2.nationB
												)}
											/>
										) : null}
									</div>
									<div className={`name`} id={`${this.scoreData.team2.idB}`}>
										<ButtonLinkText
											className=""
											name={this.scoreData.team2.displayNameB}
											onClick={() =>
												this.playerNameClick(
													this.scoreData.team2.idB,
													this.scoreData.team2.displayNameB
												)
											}>
											{this.scoreData.team2.displayNameB}
										</ButtonLinkText>
									</div>
									<div className={`seed ${this.scoreData.team2.entryStatus ? '' : 'hide'}`}>
										{this.scoreData.team2.entryStatus}
									</div>
									<div className={`seed ${this.scoreData.team2.seed ? '' : 'hide'}`}>
										{this.scoreData.team2.seed}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.showLinks ? (
					<div className="match-box-buttons">
						<Button className="compact watch-and-read" style={{ width: '100%' }}>
							Watch &amp; Read
						</Button>
						{/* <a className="watch-and-read">Watch &amp; Read</a> */}
						{this.getSlamTrackerLinkBox(allowSlamtrackerLink)}
					</div>
				) : null}
				{this.state?.show && (
					<div className="without-ai-menu">
						<DrawsMenu
							openMenu={this.showMenu}
							closeMenu={this.closeMenu}
							playerInfo={this.state?.playerInfo}
							show={this.state.show}
							profileLinks={this.profileLinks}
							eventCode={this.scoreData.eventCode}
							match_id={this.scoreData.match_id}
							openSlamtracker={this.onOpenSlamtracker}
							isSlamtrackerActive={allowSlamtrackerLink}
							showPathToTheFinal={playerId => {
								this.showPathToTheFinal(playerId, this.props?.attributes?.data?.eventCode);
							}}
						/>
					</div>
				)}
			</div>
		);
	}

	renderWinnerBox(winnerBoxRightAlign) {
		const alignRight = this.desktopMatch ? 'right-align' : '';
		return (
			<div className={`winner-info ${this.boxStyle} ${alignRight}`} data-dna="match-box-draws-winner">
				<span className="year">{this.props.tournamentYear} Winner</span>
				<span className="photo">
					<PlayerImage
						attributes={{
							'player-image': this.playerProfileImagePath
								? this.playerProfileImagePath.replace('<playerid>', this.scoreData[this.winnerTeam].idA)
								: null,
							style: 'xsmall',
						}}
					/>
					{this.scoreData[this.winnerTeam].idB && this.scoreData[this.winnerTeam].displayNameB != null ? (
						<PlayerImage
							attributes={{
								'player-image': this.playerProfileImagePath
									? this.playerProfileImagePath.replace(
											'<playerid>',
											this.scoreData[this.winnerTeam].idB
									  )
									: null,
								style: 'xsmall',
							}}
						/>
					) : null}
				</span>
				<div className={`winner-name`}>
					<div className="flag">
						<img
							alt={this.scoreData[this.winnerTeam].nationA}
							src={
								this.flagImagePathSmall
									? this.flagImagePathSmall.replace('<code>', this.scoreData[this.winnerTeam].nationA)
									: null
							}
						/>
					</div>
					<div className="name">
						<WimLink to={`${this.profileLinks}${this.scoreData[this.winnerTeam].idA}.html`}>
							{this.scoreData[this.winnerTeam].displayNameA}
						</WimLink>
					</div>
				</div>
				{this.scoreData[this.winnerTeam].idB && this.scoreData[this.winnerTeam].displayNameB != null ? (
					<div className={`winner-name`}>
						<div className="flag">
							<img
								alt={this.scoreData[this.winnerTeam].nationB}
								src={
									this.flagImagePathSmall
										? this.flagImagePathSmall.replace(
												'<code>',
												this.scoreData[this.winnerTeam].nationB
										  )
										: null
								}
							/>
						</div>
						<div className="name">
							<WimLink to={`${this.profileLinks}${this.scoreData[this.winnerTeam].idB}.html`}>
								{this.scoreData[this.winnerTeam].displayNameB}
							</WimLink>
						</div>
					</div>
				) : null}
			</div>
		);
	}

	render() {
		// logger.log('[DrawsFullMatchBox] render this:%o', this, this.winnerTeam);
		this.emptySets.reverse();
		this.hasMatchInsights = this.props.attributes.hasMatchInsights;

		/** Final and there is winnerTeam AND AI Draw highlight isn't on, display Winner box
		 *
		 *  if AI Draw highlight is on, the Draw Analysis message box is displayed instead
		 */
		let drawsListEl = document.querySelector('.drawsList.fullView');
		let showWinnerBox =
			this.scoreData?.roundCode === 'F' && this.winnerTeam && !this.props?.attributes?.aiDrawData?.showAiDrawBg;

			/** if the draws size is small and window size is less than desktop size, 
			 *  add a special class to push down drawsList
			 *  so the winners box doesn't overlap anything
			 */
			if(drawsListEl && this.props.drawData?.data?.drawSize < 12 && showWinnerBox) {
				if(!this.desktopMatch) {
					let doublesClass = this.scoreData?.eventName?.includes('Doubles') ? '-doubles' : '';
					drawsListEl.classList.add(`winnerBoxShown${doublesClass}`);
				} else {
					/** prevent the winner box cut at the bottom */
					drawsListEl.classList.add(`winnerBoxShown-smallTopPadding`);
				}
			}

		{
			return (
				<>
					<div ref={this.drawRef} data-round={this.state.selectedRound}>
						{showWinnerBox ? this.renderWinnerBox() : null}
						{this.renderRegularMatchBox()}
					</div>
				</>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawsFullMatchBox);

import React from 'react';

const DressCodeInformation = () => {
	return (
		<>
			<div className="menus two-col margin">
				<p className="menu-text">DRESS CODE INFORMATION</p>
				<br />
				<p className="menu-text">Members' Dining Room</p>
				<p className="text">
					The Dining Room dress code is smart and will remain unchanged: jacket and tie after 6pm, tie
					optional before 6pm. Women should wear smart clothing with similar formality
				</p>

				<p className="menu-text">Landing</p>
				<p className="text">
					The landing dress code is smart/casual dress, however tennis whites will be permitted prior to 6pm
					but no gym kit
				</p>

				<p className="menu-text">Club Room</p>
				<p className="text">
					There is no change to the existing dress code: tennis whites and gym kit are permitted (no jeans,
					blue denim, or flip flops).
				</p>
			</div>
			<p className="text two-col margin">
				Please note if you would like to book for today or have a question, please call the Members' Dining Room
				at 0208 971 2241 or email: <br />
				<span className="menu-text" style={{ marginTop: 0 }}>
					<a href="mailto: MemberDiningReservations@aeltc.com">MemberDiningReservations@aeltc.com</a>
				</span>
			</p>
		</>
	);
};

export default DressCodeInformation;

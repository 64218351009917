const getServeTeam = serveVal => {
	if (serveVal == '1' || serveVal == '3') {
		return '1';
	} else {
		return '2';
	}
};

export const updatePointHistory = (oldPointHistory, newPointHistory) => {
	return newPointHistory.map((point, i) => {
		// skip points we already know about.
		let foundMatch = oldPointHistory.find(function(p) {
			return p.PointNumber == point.PointNumer;
		});

		if (typeof foundMatch !== 'undefined') {
			return point;
		}

		// for all other points
		/* RLL we have to set these additional attributes on each point to handle some of the stat calculations for state items */

		// set ForehandWinner
		if (point.WinnerShotType == 'F') {
			point.ForehandWinner = point.PointWinner;
		} else {
			point.ForehandWinner = '0';
		}

		// set BackhandWinner
		if (point.WinnerShotType == 'B') {
			point.BackhandWinner = point.PointWinner;
		} else {
			point.BackhandWinner = '0';
		}

		// set ServiceWinner
		if (point.WinnerType == 'S') {
			point.ServiceWinner = point.PointWinner;
		} else {
			point.ServiceWinner = '0';
		}

		// set ReturnWinner
		if (point.WinnerType == 'R') {
			point.ReturnWinner = point.PointWinner;
		} else {
			point.ReturnWinner = '0';
		}

		// set FirstServeIn
		if (point.ServeNumber == '1') {
			point.FirstServeIn = getServeTeam(point.PointServer);
		} else {
			point.FirstServeIn = '0';
		}

		// set SecondServeIn
		if (point.ServeNumber == '2') {
			point.SecondServeIn = getServeTeam(point.PointServer);
		} else {
			point.SecondServeIn = '0';
		}

		if (point.ServeNumber == '2' || point.DoubleFault == getServeTeam(point.PointServer)) {
			point.SecondServeIn2 = getServeTeam(point.PointServer);
		} else {
			point.SecondServeIn2 = '0';
		}

		// set AdCourt
		if (point.Court == 'A') {
			point.AdCourt = getServeTeam(point.PointServer);
		} else {
			point.AdCourt = '0';
		}

		// set DuCourt
		if (point.Court == 'D') {
			point.DuCourt = getServeTeam(point.PointServer);
		} else {
			point.DuCourt = '0';
		}

		// set AdCourtWin
		if (point.AdCourt == point.PointWinner) {
			point.AdCourtWin = point.PointWinner;
		} else {
			point.AdCourtWin = '0';
		}

		// set DuCourtWin
		if (point.DuCourt == point.PointWinner) {
			point.DuCourtWin = point.PointWinner;
		} else {
			point.DuCourtWin = '0';
		}

		// set Forced Error
		var x = point.History.split('', 5);

		if (x[3] == '3' && point.PointWinner == '1') {
			point.ForcedError = '2';
		} else if (x[3] == '3' && point.PointWinner == '2') {
			point.ForcedError = '1';
		} else {
			point.ForcedError = "0'";
		}

		// set FirstServeWin
		if (point.FirstServeIn == point.PointWinner) {
			point.FirstServeWin = point.PointWinner;
		} else {
			point.FirstServeWin = '0';
		}

		// set SecondServeWin
		if (point.SecondServeIn == point.PointWinner) {
			point.SecondServeWin = point.PointWinner;
		} else {
			point.SecondServeWin = '0';
		}

		// set net points won
		if (point.NetPoint == point.PointWinner || point.NetPoint == '3') {
			point.NetPointWon = point.PointWinner;
		} else {
			point.NetPointWon = '0';
		}

		// set receiving point
		if (getServeTeam(point.PointServer) == '1') {
			point.ReceivingPoint = '2';
		} else if (getServeTeam(point.PointServer) == '2') {
			point.ReceivingPoint = '1';
		} else {
			point.ReceivingPoint = '0';
		}

		// set receiving point won
		if (getServeTeam(point.PointServer) != point.PointWinner && getServeTeam(point.PointServer) != 0) {
			point.ReceivingPointWon = point.PointWinner;
		} else {
			point.ReceivingPointWon = '0';
		}
		

		/* end RLL */

		return point;
	});
};

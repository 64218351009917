/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { isMobile } from 'react-device-detect';
import JSXWrapper from 'appdir/components/general/JSXWrapper';
import { getComponents, validateData, getAttributesArray, getComponentListArray } from 'appdir/components/general/Util';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Storyteller from 'appdir/components/cms/Storyteller';
import Rolex from 'appdir/components/general/StatusPanel/Rolex';
import FeaturedNews from './components/featured-news';
import AICatchUpHome from 'appdir/components/common-ui/AICatchUpHome';
import QuickLinks from './components/quick-links';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

/**
 * -----------------------------------------------------------------------------
 * React Component: HomePage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['HomePage'],
		windowSize: state['PageHeader']['stWindowSize'],
		enabled: state['Config']?.enabled,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.HomePage.mount()),
	unmount: () => dispatch(deps.actions.HomePage.unmount()),
});

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			
		};
		this.init = true;
		// logger.log('[HomePage] constructor this:%o', this);
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	render() {
		logger.log('[HomePage] render props:%o, state:%o', this.props, this.state);
		let header_propsData = {
			headerType: 'home24',
			statusPanel: 'none',
			title: 'Home',
			shortTitle: 'Home',
			metaTitle: 'Home',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		let { pageData, windowSize } = this.props;
		let newsItems = filter(pageData?.contentElements?.element, {type: "FeaturedNews"})[0]?.data;
		let links = filter(pageData?.contentElements?.element, {type: "QuickLink"})[0]?.data;
		let storytellerData = filter(pageData?.contentElements?.element, {type: "Storyteller"})[0]?.data;
		
		if(pageData?.jsx){
			let attributes = getAttributesArray(pageData.dependencies.data);
			let components = getComponents(getComponentListArray(pageData.dependencies.data));
			logger.log('[HomePage] render attributes:%o, components:%o', attributes, components);
			return (
				<Template className="home">
					<Header attributes={header_propsData}  />
	
					<PageHeader attributes={header_propsData} />

					{/* TODO - move this style */}
					<div className="home24 content-main" id="main" tabIndex={-1} aria-hidden role="none" style={{"paddingTop": "0px"}}>
						{windowSize != "mobile" && <QuickLinks data={links} />}
						<FeaturedNews data={newsItems} />
											
						{!isEmpty(storytellerData) && isMobile && <div className='column-layout content'>
							<Storyteller data={{
								type: "fixed",
								title: storytellerData.title,
								content: storytellerData.content,
								categories: storytellerData.categories,
								count: 10
							}} />
						</div>}
						{windowSize == "mobile" &&
							<>
								<QuickLinks data={links} />
								<div className='home-rolex mobile'>
									<Rolex width={"315px"} />
								</div>
							</>
						}

						{
							this.props?.enabled?.aiCatchUp && (
								<AICatchUpHome />
							)
						}
						<div className='column-layout content home24-main'>
							<JSXWrapper
								id={'home'}
								bindings={attributes}
								components={components}
								jsx={pageData.jsx}
							/>
						</div>
					</div>
					<Footer />
				</Template>
			);
		}else{
			return (
				<Template className="home">
					<Header attributes={header_propsData} />
	
					<PageHeader attributes={header_propsData} />
	
					<div className="content-main">
						<LoadingIndicator type="green" />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

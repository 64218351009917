import ContactPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: '/:lang/contact/index.html',
		exact: true,
		component: ContactPage,
		section: MENU_ITEMS.about,
		load: params => deps.actions.ContactPage.mount(params),
	},
];

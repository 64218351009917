import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

const Collapsible = ({ data }) => {
	const [open, setOpen] = useState();

	useEffect(() => {
		setOpen(data.status);
	}, [data.status]);

	return (
		<div className={data.style}>
			<div className="collapsible">
				<div
					className="collapsible__header"
					tabIndex={0}
					onClick={() => (open === 'open' ? setOpen('closed') : setOpen('open'))}
					onKeyPress={e => {
						e.preventDefault();
						e.key === 'Enter' || e.key === ' '
							? open === 'open'
								? setOpen('closed')
								: setOpen('open')
							: null;
					}}
					aria-controls={`collapsible`}
					aria-expanded={open === 'open' ? true : false}>
					<div className="collapsible__header-plus">
						<div className="collapsible__header-plus--horizontal"></div>
						{open !== 'open' && <div className="collapsible__header-plus--vertical"></div>}
					</div>
					<p className="collapsible__header--title">{data.title}</p>
				</div>
				<div className={`collapsible__content-${open == 'open' ? 'open' : 'closed'}`}>
					{/*ReactHtmlParser*/ parse(data.text)}
				</div>
			</div>
		</div>
	);
};

export default Collapsible;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import classNames from 'classnames';

import VideoPlayer from 'appdir/components/general/VideoPlayer';
import JSXParser from 'react-jsx-parser';
import ErrorBoundary2 from 'appdir/components/general/ErrorBoundary2';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';

import MatchBox from 'appdir/components/scoring/MatchBox';
import OrderOfPlay from 'appdir/components/cms/OrderOfPlay';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import VideoPanel from './VideoPanel';
import VideoTournament from './VideoTournament';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import SkipContent from 'appdir/components/general/SkipContent';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';

import MeasurementUtils from 'appdir/lib/analytics';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: Sidepanel
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Sidepanel'],
		sidepanel: state['Controller'].sidepanel,
		scoring: state['ScoreManager'].status,
		stubs: state['Config'].stubPages,
		ScoreManager: state['ScoreManager'],
		controllerLoaded: state['Controller'].loaded,
		liveIndicators: state['ActiveData']['liveIndicators'],
		video: state['ActiveData']['video'],
		//liveIndicators:{scores: false, video: true, radio: true},
		scoredays: state['ActiveData']['currentDay'],
		allMatchesPlayed: state['OrderOfPlay'].allMatchesPlayed,
		sidepanelExpanded: state['PageHeader'].sidepanelExpanded,
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: elm => dispatch(deps.actions.Sidepanel.mount(elm)),
	show: () => dispatch(deps.actions.Sidepanel.show()),
	hide: () => dispatch(deps.actions.Sidepanel.hide()),
	geoUpdate: geos => dispatch(deps.actions.Sidepanel.geoUpdate(geos)),
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	setScoreManagerStatus: data => dispatch(deps.actions.ScoreManager.setScoreManagerStatus(data)),
});

class Sidepanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			scorestabs: null,
		};

		this.onTabClick = this.onTabClick.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);

		this.init = true;
		this.results_init = true;
		this.scores_init = true;

		// logger.log('[Sidepanel] constructor - state:%o', this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[SidePanel] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);
		let videolist = [].concat(op.get(nextProps, 'video.live', []));

		let availableVideo = videolist.filter(video => {
			if (!nextProps.geos.set) {
				return false;
			} else {
				if (video.show && nextProps.geos.available.includes(video.code)) {
					return true;
				} else {
					return false;
				}
			}
			return video.show;
		});

		let selectedVideo = null;
		let selectedVod = null;

		if (availableVideo.length > 0) {
			selectedVideo = availableVideo[0];
		}

		if (videolist.length > 0) {
			//all vods are the same, just get first
			selectedVod = videolist[0]?.media?.vod;
		}

		let newState = {
			...nextProps,
			selectedVideo: selectedVideo,
			selectedVod: selectedVod,
		};

		if (isEmpty(newState)) {
			newState = null;
		}
		return newState;
	}

	componentDidMount() {
		this.state.mount(this.refs.Sidepanel);
		// logger.log('[Sidepanel] componentDidMount');

		if (this.state?.sidepanel?.userOpen) {
			this.state.show();
		}
	}

	componentWillUnmount() {
		// window.removeEventListener('resize', this.onResize.bind(this), false);
		clearTimeout(this.results_timeout);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[Sidepanel] componentDidUpdate prevState:%o', prevState);
		// logger.log('[Sidepanel] componentDidUpdate init:%o state:%o', this.init, this.state);

		if (this.init && this.state.paths) {
			this.init = false;

			let us, uk, liveBlock;

			// do geo blocking stuff
			deps.services.Sidepanel.fetchGeoJson(this.state.paths.usGeoCheck)
				.then(result => {
					//logger.log('[Sidepanel] componentDidUpdate - us fetchGeoJsonp:%o', result);
					us = result.match;

					deps.services.Sidepanel.fetchGeoJson(this.state.paths.ukGeoCheck).then(result => {
						//logger.log('[Sidepanel] componentDidUpdate - uk fetchGeoJson:%o', result);
						uk = result.match;

						deps.services.Sidepanel.fetchGeoJson(this.state.paths.otherGeoCheck).then(result => {
							//logger.log('[Sidepanel] componentDidUpdate - other fetchGeoJson:%o', result);
							liveBlock = result.match;

							this.props.geoUpdate({
								set: true,
								us: us,
								uk: uk,
								block: liveBlock,
								available: this.getAvailable({ us: us, uk: uk, block: liveBlock }),
							});
						});
					});
				})
				.catch(e => {
					us = false;

					deps.services.Sidepanel.fetchGeoJson(this.state.paths.ukGeoCheck).then(result => {
						//logger.log('[Sidepanel] componentDidUpdate - fetchGeoJson:%o', result);
						uk = result.match;

						deps.services.Sidepanel.fetchGeoJson(this.state.paths.otherGeoCheck).then(result => {
							//logger.log('[Sidepanel] componentDidUpdate - fetchGeoJson:%o', result);
							liveBlock = result.match;

							this.props.geoUpdate({
								set: true,
								us: us,
								uk: uk,
								block: liveBlock,
								available: this.getAvailable({ us: us, uk: uk, block: liveBlock }),
							});
						});
					});
				});
		}

		this.getCompletedMatches();
		this.setSidepanelState(prevState);
		this.getDefaultTab(prevState);
	}

	/**
	 * Deteremine the available video channels based on geoblocking rules
	 * @param {*} geos
	 * @returns
	 */
	getAvailable(geos) {
		let avail = [];

		let search = document.location.search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues();

		let isUK = geos.uk;
		let isUS = geos.us;
		let isBlocked = geos.block;
		let isOther = null;

		if (parsedQs.geoblock) {
			//if we're overriding from URL
			isUK = parsedQs.geoblock == 'uk' ? true : false;
			isUS = parsedQs.geoblock == 'us' ? true : false;
			isBlocked = parsedQs.geoblock == 'blocked' ? true : false;
			isOther = parsedQs.geoblock == 'other' ? true : false;
		}

		//let isQuals = this.state.stubs.livevideo.stub == 'quals';

		if (this.state.stubs) {
			if (isUK) {
				avail = ['mainQuals', 'mainDraw', 'mainAccess', 'mainAorangi', 'centreCourt'];
			} else if (isUS) {
				avail = ['mainDraw', 'mainAccess', 'mainAorangi', 'espn_1'];
			} else if (isBlocked) {
				avail = [];
			} else {
				avail = ['mainQuals', 'mainDraw', 'mainAccess', 'mainAorangi'];
			}

			logger.log('[Sidepanel] getAvailable - livevideo:%o available:%o', this.state.stubs.livevideo.stub, avail);
		}

		return avail;
	}

	toggleOpen() {
		this.state.show();
		MeasurementUtils.dispatchMeasurementCall('sidePanel', 'Open');
	}

	toggleClose() {
		this.state.hide();
		MeasurementUtils.dispatchMeasurementCall('sidePanel', 'Close');
	}

	setSidepanelState(prevState) {
		// send sidepanel state to controller
		if (this.state.sidepanelExpanded && this.scores_init) {
			// logger.log('[Sidepanel] setSidepanelState ... state:%o',this.state);
			if (this.state.stubs && this.state.stubs.sidepanel.stub == 'live') {
				this.scores_init = false;
				// logger.log('[Sidepanel] setSidepanelState ... sidepanel is open, not stubbed and we havent init_scores.  start scores.');
				this.state.setScoreManagerStatus({ sidepanel: true });
			}
		} else {
			if (prevState.sidepanelExpanded && !this.state.sidepanelExpanded) {
				// logger.log('[Sidepanel] setSidepanelState ... sidepanel is closed');
				this.scores_init = true;
				this.state.setScoreManagerStatus({ sidepanel: false });
			}
		}
	}

	getCompletedMatches() {
		// get scores results
		if (
			this.results_init &&
			this.state.paths &&
			this.state.scoredays &&
			this.state.stubs &&
			this.state.stubs.sidepanel.stub !== 'pre'
		) {
			if (this.state.paths.results) {
				// logger.log('[Sidepanel] getCompletedMatches');
				let dataPath = this.state.paths.results.replace('<day>', this.state.scoredays.completed);
				this.results_init = false;
				deps.services.Sidepanel.fetch(dataPath)
					.then(result => {
						this.setState({
							resultsMatches: result,
							results_loaded: true,
							results_error: false,
						});

						if (this.results_timeout) {
							clearTimeout(this.results_timeout);
						}

						this.results_timeout = setTimeout(() => {
							this.results_init = true;
						}, 30000);
					})
					.catch(error => {
						//logger.log('[Sidepanel] componentDidUpdate error:%o',error);
						this.setState({
							status: 'error',
							errorType: error,
							results_error: true,
						});
					});
			}
		}
	}

	getDefaultTab(prevState) {
		// set the default scoring tab
		if (
			(!this.state.scorestabs && this.state.stubs && this.state.liveIndicators) ||
			(this.state.scorestabs &&
				this.state.stubs &&
				this.state.liveIndicators &&
				prevState.scoring &&
				(this.state.scoring.mip != prevState.scoring.mip ||
					this.state.scoring.slamtracker != prevState.scoring.slamtracker)) ||
			(this.state.scorestabs &&
				prevState.liveIndicators &&
				prevState.liveIndicators.scores != this.state.liveIndicators.scores)
		) {
			if (this.state.stubs.sidepanel.stub === 'live') {
				if (
					this.state.liveIndicators.scores == true &&
					!this.state.scoring.mip &&
					!this.state.scoring.slamtracker &&
					!this.state.scoringErrors
				) {
					// logger.log('[Sidepanel] scores are live.  setting default scores tab to "live".');
					this.setState({
						scorestabs: 'live',
					});
				} else if (
					this.state.liveIndicators.scores == true &&
					(this.state.scoring.mip || this.state.scoring.slamtracker || this.state.scoringErrors)
				) {
					// logger.log('[Sidepanel] scores are live, but mip page.  setting default scores tab to "results".');
					this.setState({
						scorestabs: 'results',
					});
				} else if (!this.state.liveIndicators.scores) {
					// logger.log('[Sidepanel] scores are not live. setting default scores tab to "results"');
					this.setState({
						scorestabs: 'results',
					});
				}
			} else if (this.state.stubs.sidepanel.stub === 'pre') {
			} else if (this.state.stubs.sidepanel.stub === 'post') {
			}
		}
	}

	renderMatch(match) {
		//logger.log('[SidePanel] renderMatch - match_id:%o index:%o',match_id, this.state.Scoring.liveMatches.byId[match_id]);
		// let match = this.state.liveMatches.byId[match_id];
		if (match.scores) {
			return (
				<MatchBox
					key={match.match_id}
					attributes={{
						data: match,
						style: 'live',
						event: match.shortEventName,
						tableHeader: match.courtName,
						showLinks: true,
						isSidepanel: true,
					}}
				/>
			);
		} else {
			return null;
		}
	}

	renderCompletedMatch(match, index) {
		return (
			<MatchBox
				key={index}
				attributes={{
					data: match,
					event: match.shortEventName,
					tableHeader: match.courtName,
					showLinks: true,
					isSidepanel: true,
				}}
			/>
		);
	}

	onTabClick(tabname) {
		this.setState({
			scorestabs: tabname,
		});

		switch (tabname) {
			case 'live':
				tabname = 'Scores';
				break;
			case 'upcoming':
				tabname = 'Upcoming';
				break;
			case 'results':
				tabname = 'Results';
				break;
			case 'schedule':
				tabname = 'Schedule';
				break;
			default:
				tabname = 'Tab';
				break;
		}

		MeasurementUtils.dispatchMeasurementCall('sidePanelTabsClick', { tabname });
	}

	renderScoreTabs() {
		/***
		 * Live scores && Upcoming tab: scores active data is true  (controlled by scoring) and not on mip or slamtracker page (set in Controller component)
		 * Results && Upcoming tab:  scores active data is true (controlled by scoring) and on mip or slamtracker page (set in Controller) OR there's some scoring error
		 * Results and Order of Play:  scores active data is false (controlled by scoring)
		 */
		if (
			this.state.liveIndicators.scores &&
			!this.state.scoring.mip &&
			!this.state.scoring.slamtracker &&
			!this.state.scoringErrors
		) {
			return (
				<div className="sidepanel-tabs" alt="side panel tabs">
					<span
						onClick={() => {
							this.onTabClick('live');
						}}
						className={this.state.scorestabs == 'live' ? 'selected' : ''}>
						<span className="live-indicator">●</span>Scores
					</span>
					&nbsp;|&nbsp;
					<span
						onClick={() => {
							this.onTabClick('upcoming');
						}}
						className={this.state.scorestabs == 'upcoming' ? 'selected' : ''}>
						Upcoming
					</span>
				</div>
			);
		} else if (
			this.state.liveIndicators.scores &&
			(this.state.scoring.mip || this.state.scoring.slamtracker || this.state.scoringErrors)
		) {
			return (
				<div className="sidepanel-tabs">
					<span
						onClick={() => {
							this.onTabClick('results');
						}}
						className={this.state.scorestabs == 'results' ? 'selected' : ''}>
						Results
					</span>
					&nbsp;|&nbsp;
					<span
						onClick={() => {
							this.onTabClick('upcoming');
						}}
						className={this.state.scorestabs == 'upcoming' ? 'selected' : ''}>
						Upcoming
					</span>
				</div>
			);
		} else {
			return (
				<div className="sidepanel-tabs">
					<span
						onClick={() => {
							this.onTabClick('results');
						}}
						className={this.state.scorestabs == 'results' ? 'selected' : ''}>
						Results
					</span>
					&nbsp;|&nbsp;
					<span
						onClick={() => {
							this.onTabClick('schedule');
						}}
						className={this.state.scorestabs == 'schedule' ? 'selected' : ''}>
						Order of Play
					</span>
				</div>
			);
		}
	}

	renderContentButton() {
		if (this.state.scorestabs == 'live')
			return (
				<div className="scores-button-container">
					<a
						ref="button-livescores"
						className="scores-button"
						tabIndex={-1}
						aria-hidden={true}
						href={`/en_GB/scores/index.html`}
						onClick={e => {
							this.onLinkClick(e, `/en_GB/scores/index.html`);
						}}>
						<i className="wim-icon-arrow" />
						All Live Scores
					</a>
				</div>
			);
		else if (this.state.scorestabs == 'results')
			return (
				<div className="scores-button-container">
					<a
						ref="button-results"
						className="scores-button"
						href={`/en_GB/scores/results/day${this.state.scoredays.completed}.html`}
						onClick={e => {
							this.onLinkClick(e, `/en_GB/scores/results/day${this.state.scoredays.completed}.html`);
						}}>
						<i className="wim-icon-arrow" />
						All Results
					</a>
				</div>
			);
		else if (this.state.scorestabs == 'upcoming' || this.state.scorestabs == 'schedule')
			return (
				<div className="scores-button-container">
					<a
						ref="button-schedule"
						className="scores-button"
						href={`/en_GB/scores/schedule/schedule${this.state.scoredays.schedule}.html`}
						onClick={e => {
							this.onLinkClick(e, `/en_GB/scores/schedule/schedule${this.state.scoredays.schedule}.html`);
						}}>
						<i className="wim-icon-arrow" />
						Order of Play
					</a>
				</div>
			);
		else return null;
	}

	onLinkClick(e, path) {
		//logger.log('[Sidepanel] e:%o',e.target);
		e.preventDefault();
		this.state.navigate({ path: path });
	}

	renderStateContent() {
		//if sidepanel not open, don't render
		if (!this.state.sidepanelExpanded) {
			return (
				<div className="sidepanel-content-channel">
					<div className="sidepanel-content-video" />
					<div className="sidepanel-content" />
				</div>
			);
		}

		// if sidepanel not stubbed
		else if (this.state.stubs.sidepanel.stub === 'live') {
			let { liveMatches } = this.state.ScoreManager;

			return (
				<>
					<div className="sidepanel-content-channel">
						<VideoTournament
							windowSize={this.state.windowSize}
							video={this.state.selectedVideo}
							vod={this.state.selectedVod}
							expanded={this.state.sidepanelExpanded}
							showLive={this.state.showLiveChannel}
						/>
					</div>
					{this.state.liveIndicators && this.state.scoredays ? (
						<div className="sidepanel-content-scores">
							{this.renderScoreTabs()}
							{this.renderContentButton()}
							{!this.state.scoring.mip &&
							!this.state.scoring.slamtracker &&
							this.state.scorestabs == 'live' ? (
								<div className="sidepanel-column">
									{liveMatches && liveMatches.length > 0 ? (
										liveMatches.map((item, index) => this.renderMatch(item, index))
									) : (
										<div className="message show">
											<LoadingIndicator />
										</div>
									)}
								</div>
							) : null}
							{this.state.scorestabs == 'upcoming' ? (
								<div className="sidepanel-column upcoming">
									<div
										className={
											this.state.allMatchesPlayed !== 'init' &&
											this.state.allMatchesPlayed == true
												? 'hide'
												: 'show'
										}>
										<ErrorBoundary2 message="Unable to display schedule data.">
											<OrderOfPlay
												data={{
													dayId: this.state.scoredays.schedule,
													nextMatch: true,
													allCourts: true,
													refresh: true,
													isSidePanel: true,
												}}
											/>
										</ErrorBoundary2>
									</div>
									<div className="message">There are no more matches scheduled for today.</div>
								</div>
							) : null}
							{this.state.scorestabs == 'results' ? (
								<div className="sidepanel-column results">
									{this.state.results_loaded ? (
										this.state.resultsMatches && this.state.resultsMatches.matches.length > 0 ? (
											this.state.resultsMatches.matches.map((item, index) =>
												this.renderCompletedMatch(item, index)
											)
										) : (
											<div className="message show">There are no results at this time.</div>
										)
									) : !this.state.results_error ? (
										<div className="message show">
											<LoadingIndicator />
										</div>
									) : (
										<GenericError message="Results data is unavailable." />
									)}
								</div>
							) : null}
							{this.state.scorestabs == 'schedule' ? (
								<div className="sidepanel-column schedule">
									<ErrorBoundary2 message="Unable to display schedule data.">
										<OrderOfPlay
											data={{
												dayId: this.state.scoredays.schedule,
												nextMatch: false,
												allCourts: true,
												refresh: true,
												isSidePanel: true,
											}}
										/>
									</ErrorBoundary2>
								</div>
							) : null}
						</div>
					) : null}
				</>
			);
		}

		// else if in pre/stub state
		else if (this.state.stubs.sidepanel.stub === 'pre') {
			//create attributes for stub video defined in stubs_web
			let attributes_preVideo = {
				playerId: 'sidepanel',
				cmsId: this.state.stubs.sidepanel.video.cmsId,
				title: this.state.stubs.sidepanel.video.title,
				thumb: this.state.stubs.sidepanel.video.thumb,
				autoplay: true,
				playNextUp: false,
				style: 'video-wrapper one-col',
				aspect: 'wide',
			};

			return (
				<div className="sidepanel-content-channel">
					<div className="title">
						{attributes_preVideo.title}
						<hr />
					</div>
					<div className="sidepanel-content-video-pre">
						<ErrorBoundary2 message="Error in Pre Video">
							<VideoWrapper attributes={attributes_preVideo} />
						</ErrorBoundary2>
					</div>
					<div className="sidepanel-content-pre">
						<div className="title">{this.state.stubs.sidepanel.text[0]}</div>
						{this.state.stubs.sidepanel.text.map((val, ind) => {
							if (ind > 0) {
								return (
									<p key={ind}>
										<JSXParser jsx={val} renderInWrapper={false} showWarnings={true} />
									</p>
								);
							}
						})}
					</div>
				</div>
			);
		}

		//else if in post championships state
		else if (this.state.stubs.sidepanel.stub === 'post') {
			return (
				<div className="sidepanel-video-panel">
					{this.state.paths && this.state.paths.latestVideoPath ? (
						<VideoPanel
							path={this.state.paths.latestVideoPath}
							onLink={this.onLinkClick}
							expanded={this.state.sidepanelExpanded}
						/>
					) : null}
				</div>
			);
		}
	}

	render() {
		let sidepanelClasses = this.state?.sidepanel?.userOpen ? classNames('sidepanel show') : classNames('sidepanel');
		let buttonClasses = this.state?.sidepanel?.userOpen
			? classNames('sidepanel-button hide')
			: classNames('sidepanel-button');

		//logger.log('[SidePanel] render - state:%o sidepanel:%o', this.state);

		if (!this.state.stubs || !this.state.displayPanel) {
			return null;
		}

		// logger.log('[SidePanel] render - state:%o', this.state);

		return (
			<section className={sidepanelClasses}>
				<noindex>
					<div className="sidepanel-content stub">
						<SkipContent isSkipContent={true} skipToNav={true} />
						<div className={buttonClasses} onClick={() => this.toggleOpen()} />
						<div className="sidepanel-close" onClick={() => this.toggleClose()}>
							<i className="wim-icon-close" />
						</div>

						{this.renderStateContent()}
					</div>
				</noindex>
			</section>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidepanel);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Favouriting from 'appdir/components/general/Favouriting';
import Interests from 'appdir/components/general/Onboarding/Interests.js';
import MeasurementUtils from 'appdir/lib/analytics';
import CatchMeUpPromo from 'appdir/components/general/Onboarding/CatchMeUpPromo.js';
import PromoFeatureReusable from 'appdir/components/general/PromoFeature/PromoFeatureReusable.js';
import PlayerProfilePromo from 'appdir/components/general/Onboarding/PlayerProfilePromo.js'
// import StorytellerPromo from 'appdir/components/general/Onboarding/StorytellerPromo.js';
/**
 * -----------------------------------------------------------------------------
 * React Component: PromoFeature
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PromoFeature'],
		...state['Gigya'],
		Controller: state['Controller'],
		loggedIn: state['Gigya']['loggedIn'],
		configVal: state['Config'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: elm => dispatch(deps.actions.PromoFeature.mount(elm)),
	updatePromoFeature: (key, newValue) => dispatch(deps.actions.Controller.updatePromoFeature(key, newValue)),
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	togglePromoFeature: value => dispatch(deps.actions.PromoFeature.togglePromoFeature(value)),
});

const mobileMatch = window.matchMedia('(max-width: 767px)').matches;
const tabletMatch = window.matchMedia('(max-width: 1023px)').matches;

class PromoFeature extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.state = {
			...props,
			interestsViewed: false,
			catchMeUpMobileIndex: 0,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.showPromoFeature !== this.state.showPromoFeature) {
			this.setState({ showPromoFeature: nextProps.showPromoFeature, closeBtnClicked: false });
		}
	}

	onCloseInterests() {
		this.props.updatePromoFeature('interestsViewed', true);
		let newShowPromo = this.state.showPromoFeature;
		newShowPromo.interestsPromo = false;
		this.setState({ showPromoFeature: newShowPromo });
		if (!this.doOtherPromosExist('interestsPromo')) {
			this.onCloseOnboardingPromos();
		}
	}

	onCloseCurrentPromo(controllerName, promptsVal, promoName, isCloseBtn) {
		this.props.updatePromoFeature(controllerName, promptsVal);
		let newShowPromo = this.state.showPromoFeature;
		newShowPromo[promoName] = false;
		this.setState({ showPromoFeature: newShowPromo });
		if (!this.doOtherPromosExist(promoName) || isCloseBtn) {
			this.onCloseOnboardingPromos();
		}
		if (isCloseBtn) {
			this.setState({ closeBtnClicked: true });
		}
		document.body.style.overflow = 'auto';
	}

	// onCloseStoryteller() {
	// 	const { prompts } = this.props.configVal;
	// 	this.props.updatePromoFeature('storytellerVersionViewed', prompts?.storyteller);
	// 	if (!this.doOtherPromosExist('storytellerPromo')) {
	// 		this.onCloseOnboardingPromos();
	// 	}
	// }

	onCloseOnboardingPromos() {
		document.body.style.overflow = 'auto';
		this.props.togglePromoFeature(false);
	}

	doOtherPromosExist(promoCurrentlyDisplayed) {
		let { showPromoFeature } = this.state;
		let hasPromoToShow = Object.keys(showPromoFeature)?.some(key => {
			return showPromoFeature[key] === true && key !== promoCurrentlyDisplayed
		});
		return hasPromoToShow;
	}

	render() {
		const { showPromoFeature, catchMeUpMobileIndex, closeBtnClicked } = this.state;
		let toShowInterests = showPromoFeature?.interestsPromo;
		let toShowFavortingPromo = showPromoFeature?.favortingPromo;
		let toShowCatchMeUpPromo = showPromoFeature?.catchMeUpPromo;
		let toShowPlayerProfilePromo = showPromoFeature?.playerProfilePromo;
		// let toShowStoryteller = showPromoFeature?.storytellerPromo;

		let hasPromoToShow = Object.keys(showPromoFeature)?.some(key => showPromoFeature[key] === true);
		if (hasPromoToShow && !closeBtnClicked) {
			const { prompts } = this.props.configVal;

			const enableNextBtn = !this.state.nextBtnEnabled;

			if (toShowInterests) {
				document.body.style.overflow = 'hidden';
				return (
					<PromoFeatureReusable
						titleTxt="INTERESTS"
						contentStyle={{
							display: 'inherit',
							background: '',
						}}
						componentToRender={
							<Interests
								onEnableNextBtn={data =>
									this.setState({ nextBtnEnabled: true, selectedInterests: data })
								}
							/>
						}
						bottomButtonStyle={{ width: '200px' }}
						bottomButtonTxt={!this.doOtherPromosExist('interestsPromo') ? 'Finish' : 'Next'}
						onCloseFunc={() => this.onCloseInterests()}
						bottomButtonDisabled={enableNextBtn}
					/>
				);
			} else if (toShowFavortingPromo) {
				document.body.style.overflow = 'hidden';
				return (
					<PromoFeatureReusable
						toShowCloseBtn
						titleTxt="FAVOURITES"
						contentStyle={{
							display: 'inherit',
							background: 'white',
						}}
						componentToRender={
							<Favouriting
								isPromo
								onUnmount={() => {
									this.props.updatePromoFeature('favortingViewed', true);
									// mark as closed so next promo doesn't immediately show on refresh and user doesn't have a chance to see
									this.setState({ closeBtnClicked: true });
								}}
							/>
						}
						bottomButtonDisabled={false}
						bottomButtonStyle={{ width: '200px' }}
						bottomButtonTxt={!this.doOtherPromosExist('favortingPromo') ? 'Finish' : 'Next'}
						onCloseFunc={(isCloseBtn) => this.onCloseCurrentPromo('favortingViewed', true, 'favortingPromo', isCloseBtn)}
					/>
				);
			} else if (toShowCatchMeUpPromo) {
				document.body.style.overflow = 'hidden';

				return (
					<PromoFeatureReusable
						toShowCloseBtn
						titleTxt="CATCH ME UP"
						customTitle="NEW IN 2024"
						subCustomTitleTxt="CATCH ME UP"
						subCustomTxt={(<div className="catch-up-promo-watsonX_AI">
							<div>
								Written by AI with IBM
							</div>
							<img src="https://assets.wimbledon.com/images/logo/watsonx_logotype_white.png"/>
						</div>)}
						contentStyle={{
							display: 'flex',
							background: '',
							height: 'calc(100% - 250px)'
						}}
						componentToRender={
							<CatchMeUpPromo
								imageCount={catchMeUpMobileIndex}
								onUnmount={() => {
									this.props.updatePromoFeature('catchUpPromoVersionViewed', prompts?.catchMeUp);
									// mark as closed so next promo doesn't immediately show on refresh and user doesn't have a chance to see
									this.setState({ closeBtnClicked: true });
								}}
							/>
						}
						bottomButtonDisabled={false}
						bottomButtonStyle={{ width: '200px' }}
						bottomButtonTxt={(mobileMatch && catchMeUpMobileIndex == 0 || this.doOtherPromosExist('catchMeUpPromo')) ? 'Next' : 'Finish'}
						onCloseFunc={(isCloseBtn) => {
							if (mobileMatch && catchMeUpMobileIndex == 0) {
								this.setState({ catchMeUpMobileIndex: 1 })
							} else {
								this.onCloseCurrentPromo('catchUpPromoVersionViewed', prompts?.catchMeUp, 'catchMeUpPromo', isCloseBtn);
							}
						}}
					/>
				);
			// } else if (toShowStoryteller) {
			// 	return (
			// 		<PromoFeatureReusable
			// 			toShowCloseBtn
			// 			titleTxt="STORYTELLER"
			// 			contentStyle={{
			// 				display: 'flex',
			// 				background: 'white',
			// 			}}
			// 			componentToRender={
			// 				<StorytellerPromo
			// 					onUnmount={() => {
			// 						this.props.updatePromoFeature('storytellerVersionViewed', prompts?.storyteller);
									// // mark as closed so next promo doesn't immediately show on refresh and user doesn't have a chance to see
									// this.setState({ closeBtnClicked: true });
			// 					}}
			// 				/>
			// 			}
			// 			bottomButtonDisabled={false}
			// 			bottomButtonStyle={{ width: '200px' }}
			// 			bottomButtonTxt={!this.doOtherPromosExist('storytellerPromo') ? 'Finish' : 'Next'}
			// 			onCloseFunc={() => this.onCloseStoryteller()}
			// 		/>
			// 	);
			} else if (toShowPlayerProfilePromo) {
				document.body.style.overflow = 'hidden';
				return (
					<PromoFeatureReusable
						toShowCloseBtn
						titleTxt="PLAYER PROFILE"
						customTitle="NEW IN 2024"
						subCustomTitleTxt="PLAYER PROFILE"
						contentStyle={{
							display: 'flex',
							background: '',
							height: 'calc(100% - 200px)'
						}}
						componentToRender={
							<PlayerProfilePromo
								onUnmount={() => {
									this.props.updatePromoFeature('playerProfileVersionViewed', prompts?.playerProfile);
									// mark as closed so next promo doesn't immediately show on refresh and user doesn't have a chance to see
									this.setState({ closeBtnClicked: true });
								}}
							/>
						}
						bottomButtonDisabled={false}
						bottomButtonStyle={{ width: '200px' }}
						bottomButtonTxt={!this.doOtherPromosExist('playerProfilePromo') ? 'Finish' : 'Next'}
						onCloseFunc={(isCloseBtn) => this.onCloseCurrentPromo('playerProfileVersionViewed', prompts?.playerProfile, 'playerProfilePromo', isCloseBtn)}
					/>
				);
			} 
		}

		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoFeature);

import React, { Component, Fragment } from 'react';
import { PlayerDigitalExperienceContext } from '../context';
import op from 'object-path';
import deps from 'dependencies';
import { connect } from 'react-redux';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import { Formik, Form } from 'formik';
import { FormCheckBox } from 'appdir/components/common-ui/FormFields';
import { getContent } from 'appdir/components/general/Util/Services';
import { getParams } from './Utils.js';
import { loggedIn, checkRole } from 'appdir/components/general/Util/Role';
import SecurePDF from 'appdir/components/cms/SecurePDF';
import axios from 'axios';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
	unmount: () => dispatch(deps.actions.PlayerDigitalExperience.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

const zoomLink = '/s/player/contact_us_prize_money.html';
class PlayerPrizeMoney extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			gotData: false,
			data: null,
			showForm: true,
		};
		this.fetchData = true;
		this.submittedData = false;
		this.initialValues = {
			FlagVerified: false,
			FlagWTA: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);

		this.permittedEvents = [
			'QS',
			'RS',
			'MS',
			'LS',
			'MD',
			'LD',
			'MX',
			'SM',
			'SW',
			'DM',
			'DW',
			'SQ',
			'DQ',
			'OD',
			'SL',
			'SD',
			'IX',
			'RR',
			'SL',
			'OD',
		];
		this.invitationals = ['DM', 'SW', 'SM', 'DW', 'SQ', 'DQ', 'OD', 'SL', 'SD', 'IX', 'RR', 'SL', 'OD']; //includes wheelchair events

		logger.log('[PDE - PlayerPrizeMoney] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log('[PDE - PlayerPrizeMoney] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'currentUser', {});

		let roleValidated = checkRole(op.get(currentUser, 'roles', []), op.get(this.state, 'roleIds', []));

		logger.log(
			'[PDE - PlayerPrizeMoney] componentDidUpdate - user:%o state:%o roleValidated:%o',
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			roleValidated
		);

		// logger.log('[PDE - PlayerCatering] componentDidUpdate - state:%o context:%o', this.state, this.context);
		if (loggedIn(currentUser) && roleValidated) {
			if (this.state.stub) {
				if (this.fetchData) {
					const prizeURLs = ['/en_GB/pde/feeds/prizeMoney.json', '/en_GB/pde/feeds/bank_info.json'];
					let prizeCalls = prizeURLs.map(d => axios.get(d));
					this.fetchData = false;
					axios.all(prizeCalls).then(
						axios.spread((prizeMoney, bankInfo) => {
							let data = [];
							data[0] = prizeMoney['data'];
							data[1] = bankInfo['data'];
							// data[1] = {
							// 	message: 'OK',
							// 	response: {
							// 		AccountNumber: '1095',
							// 		AddressLine1: '20 Oxford St',
							// 		Email: 'skat@aeltc.com',
							// 		FlagVerified: true,
							// 		FlagWTA: 1,
							// 		IBANNumber: '6252',
							// 		PlayerID: '100167618',
							// 	},
							// };
							logger.log('[PDE - PlayerPrizeMoney] componentDidUpdate - data:%o', data);
							let flagVerified = op.get(data[1], 'response.FlagVerified', false);
							let eventCode = op.get(data[1], 'response.EventCode', false);
							let flagWTA = op.get(data[1], 'response.FlagWTA', false);
							if (flagWTA == 0 || flagWTA == 1) {
								flagWTA = Boolean(flagWTA);
							}
							this.initialValues['FlagVerified'] = flagVerified;
							this.initialValues['EventCode'] = eventCode;
							this.initialValues['FlagWTA'] = flagWTA;

							let showForm = true;
							if (this.initialValues['FlagVerified']) {
								showForm = false;
							}
							this.setState({ data: data, gotData: true, showForm });
						})
					);
				}
			} else {
				if (this.state.dataUrl && !this.state.gotData && this.fetchData) {
					this.fetchData = false;
					this.props
						.getJWT()
						.then(token => {
							//logger.log('[PDE] componentDidUpdate - token:%o', token.jwt);
							let nullError = false;
							let params = getParams('prize-money', { ...this.state });
							getContent(this.state.dataUrl, 'get', token.jwt.id_token, nullError, params)
								.then(data => {
									/*** test data ***/
									// data[1] = {
									// 	message: 'OK',
									// 	response: {
									// 		AccountNumber: '1095',
									// 		AddressLine1: '20 Oxford St',
									// 		Email: 'skat@aeltc.com',
									// 		FlagVerified: false,
									// 		FlagWTA: 0,
									// 		IBANNumber: '6252',
									// 		PlayerID: '100167618',
									// 		EventCode: 'LS'
									// 	},
									// };

									logger.log('[PDE - PlayerPrizeMoney] componentDidUpdate - data:%o', data);
									let flagVerified = Boolean(op.get(data[1], 'response.FlagVerified', false));
									let flagWTA = op.get(data[1], 'response.FlagWTA', false);
									let eventCode = op.get(data[1], 'response.EventCode', false);
									if (flagWTA == 0 || flagWTA == 1) {
										flagWTA = Boolean(flagWTA);
									}

									this.initialValues['FlagVerified'] = flagVerified;
									this.initialValues['FlagWTA'] = flagWTA;
									this.initialValues['EventCode'] = eventCode;

									let showForm = true;
									if (this.initialValues['FlagVerified']) {
										showForm = false;
									}
									this.setState({ data: data, gotData: true, showForm });
								})
								.catch(data => {
									this.setState({ data: 'error', gotData: true });
								});
						})
						.catch(error => {
							this.setState({ data: 'error', gotData: true });
							logger.error('[PDE - PlayerPrizeMoney] componentDidUpdate - error getting token');
						});
				}
			}
		}
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[PDE - PlayerPrizeMoney] handleSubmit - state:%o', values);
		//send data to endpoint not defined yet
		//format the payload to send to PDE prize money endpoint
		// '{ "isDetailsVerified": "false", "isWTAPayment": "true" }'
		let wtaFlag = values.FlagWTA === 0 ? false : values.FlagWTA === 1 ? true : values.FlagWTA;
		let payload = {
			isDetailsVerified: values.FlagVerified,
			isWTAPayment: wtaFlag,
		};
		let url = this.props.submitURL;
		// this.setState({ gotData: false });
		this.props.showModal({
			showModal: true,
			showCloseBtn: false,
			type: 'updating',
			title: 'UPDATING',
			message: 'Your bank information is updating. Please do not close your browser window while updating.',
		});
		this.props
			.getJWT()
			.then(token => {
				//logger.log('[PDE] componentDidUpdate - token:%o', token.jwt);
				let nullError = false;
				getContent(url, 'post', token.jwt.id_token, nullError, null, payload)
					.then(data => {
						let responseData = null;
						if (typeof data === 'string') {
							responseData = JSON.parse(data);
						} else {
							responseData = data;
						}
						if (responseData['message'] === 'OK') {
							logger.log('[PDE - PlayerPrizeMoney] componentDidUpdate - data:%o', data);
							let showForm = payload['isDetailsVerified'];
							actions.setSubmitting(false);
							this.initialValues['FlagVerified'] = values.FlagVerified;
							this.initialValues['FlagWTA'] = values.FlagWTA;
							this.setState({ gotData: true, showForm });
							this.submittedData = true;
							this.props.showModal({
								showModal: true,
								showCloseBtn: true,
								type: 'success',
								title: 'UPDATE SUCCESS',
								message: 'Update Successful. Your banking information has been updated.',
								useTimer: true,
							});
						} else {
							this.setState({ gotData: true, showForm: true });
							actions.setSubmitting(false);
							this.props.showModal({
								showModal: true,
								showCloseBtn: true,
								type: 'fail',
								title: 'UPDATE FAILURE',
								message: 'There was an error with your request. Please try again.',
								useTimer: true,
							});
						}
					})
					.catch(data => {
						// this.setState({ gotData: true, data: 'error', showForm: false });
						actions.setSubmitting(false);
						this.props.showModal({
							showModal: true,
							showCloseBtn: true,
							type: 'fail',
							title: 'UPDATE FAILURE',
							message: 'There was an error with your request. Please try again.',
							useTimer: true,
						});
					});
			})
			.catch(error => {
				logger.error('[PDE - PlayerPrizeMoney] componentDidUpdate - error getting token');
			});
	}

	submitForm(url, payload) {}

	noBankingData() {
		let bankingData = op.get(this.state.data[1], 'response', {});
		return Object.keys(bankingData).length == 0;
	}

	bankingErrorMsg() {
		let message = op.get(this.state.data[1], 'message', null);
		return message !== 'OK' || this.state.data[1] === null;
	}

	accountEnding(data) {
		if (data !== null && data !== '') {
			return 'Ending in';
		}
		return null;
	}

	prizeMoneyText(){
		return <>
			<p>We require your bank details and postal address so that we are able to transfer your prize money following your final match at The Championships.</p>

			<p>To update your details contact the prize money team via email: {' '}
				<a href="mailto:prize.money@aeltc.com" target="_blank">
					prize.money@aeltc.com
				</a>{' '} or call using the numbers below.</p>

			<p>Please note the Prize Money Office opening hours are:<br/>
			Pre-Championships: Monday-Friday 09:00-17:00 (excluding Monday 27 May) – Tel: + 44 (0)20 8939 6368<br/>
			Qualifying: 24-27 June from 10:00–19:00 – Tel: +44 (0)20 8247 3326<br/>
			Main Draw: 28-30 June 10:00-17:00, 1-14 July 11:00-21:00 – Tel: +44 (0)20 8971 2259</p>
		</>
	}

	getErrorMessage() {
		return (
			<Fragment>
				{this.prizeMoneyText()}
			</Fragment>
		);
	}

	renderBankInfo() {
		// if(this.state.data.bankInfo)
		let bankInfo = this.state.data[1];
		if (this.bankingErrorMsg()) {
			return (
				<div className="four-col bank-info-container">
					<div className="section-container">
						<div className="row prize-money-explanation">{this.getErrorMessage()}</div>
					</div>
				</div>
			);
		} else if (this.noBankingData()) {
			<div className="four-col bank-info-container">
				<div className="section-container">
					<div className="row prize-money-explanation">No data available</div>
				</div>
			</div>;
		} else {
			let bankInformation = bankInfo['response'];

			return (
				<div className="four-col bank-info-container">
					<div className="section-container">
						<div className="row prize-money-explanation">
							<p>Your prize money statements, and tax documents are listed below for any Championships that you have competed in.</p>

							{this.prizeMoneyText()}
						</div>
					</div>
					<div className="section-container">
						<div className="prize-money-header">Prize Money Details</div>
						<div className="row">
							<div className="row-header">Account Number:</div>
							<div className="row-value">
								<span className="unbold">{this.accountEnding(bankInformation.AccountNumber)}</span>{' '}
								{bankInformation.AccountNumber}
							</div>
						</div>
						<div className="row">
							<div className="row-header">IBAN:</div>
							<div className="row-value">
								<span className="unbold">{this.accountEnding(bankInformation.IBANNumber)}</span>{' '}
								{bankInformation.IBANNumber}
							</div>
						</div>
						<div className="row">
							<div className="row-header">Email Address</div>
							<div className="row-value">{bankInformation.Email}</div>
						</div>
						<div className="row">
							<div className="row-header">First Line of Address</div>
							<div className="row-value">{bankInformation.AddressLine1}</div>
						</div>
					</div>
				</div>
			);
		}
	}

	renderPDFLinks(linkData, index, year) {
		switch (linkData.type) {
			case 'PrizeMoney':
				return (
					<SecurePDF
						data={{
							text: 'View Final Statement (PDF)',
							id: `prizepdf-${linkData.key}`,
							link: linkData.key,
							style: 'four-col',
						}}
					/>
				);
			case 'TaxStatement':
				return (
					<SecurePDF
						data={{
							text: 'View Witholding Tax Form (PDF)',
							id: `prizepdf-${linkData.key}`,
							link: linkData.key,
							style: 'four-col',
						}}
					/>
				);
		}
	}

	renderPDFs() {
		let prize_money_pdfs = op.get(this.state, 'data.0', null);

		if (prize_money_pdfs !== null) {
			if (prize_money_pdfs['years'].length > 0) {
				return (
					<div className="four-col pdf-container">
						<div className="section-container">
							<div className="row prize-money-explanation">
								Your Prize Money Final Statement and Witholding Tax Form will appear here following your
								final match at each Championships.
							</div>
						</div>
						<div className="pdf-links">
							{prize_money_pdfs.years
								.sort((a, b) => b.year - a.year)
								.map((d, i) => {
									return (
										<div className="section-container pdf">
											<div className="pdf-header">{d.year}</div>
											{d.data.map((d1, j) => {
												return (
													<div className="rendered-link">
														{this.renderPDFLinks(d1, j, d.year)}
													</div>
												);
											})}
										</div>
									);
								})}
						</div>
					</div>
				);
			} else {
				return (
					<div className="four-col pdf-container">
						<div className="section-container">
							<div className="row prize-money-explanation">No data available</div>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div className="four-col pdf-container">
					<div className="section-container">
						<div className="row prize-money-explanation">
							The information you are requesting is currently unavailable. Please try again later or
							contact us on players@aeltc.com.
						</div>
					</div>
				</div>
			);
		}
	}

	haveAllAccountInfo() {
		const { AccountNumber, IBANNumber } = this.state.data[1]['response'];

		if ((AccountNumber == '' || AccountNumber == null) && (IBANNumber == '' || IBANNumber == null)) {
			return false;
		}

		return true;
	}

	haveAllContactInfo() {
		const { Email, AddressLine1 } = this.state.data[1]['response'];
		if (Email == '' || Email == null || AddressLine1 == '' || AddressLine1 == null) {
			return false;
		}

		return true;
	}

	haveNoData() {
		const { AccountNumber, IBANNumber, Email, AddressLine1 } = this.state.data[1]['response'];
		if (
			(AccountNumber == '' || AccountNumber == null) &&
			(IBANNumber == '' || IBANNumber == null) &&
			(Email == '' || Email == null) &&
			(AddressLine1 == '' || AddressLine1 == null)
		) {
			return true;
		}

		return false;
	}

	renderFormik() {
		return (
			!this.bankingErrorMsg() &&
			!this.noBankingData() && (
				<div className="prize-money-form-container">
					<Formik initialValues={this.initialValues} onSubmit={this.handleSubmit} enableReinitialize={false}>
						{formProps => {
							return this.renderUpdateForm({ ...formProps });
						}}
					</Formik>
				</div>
			)
		);
	}

	requiredChecks(showWTA, inEventList, formProps) {
		if (!inEventList) {
			return false;
		}

		if (showWTA) {
			if (this.haveNoData() && formProps.values.FlagWTA) {
				return true;
			}

			if (!this.haveAllAccountInfo() && this.haveAllContactInfo()) {
				if (formProps.values.FlagVerified && formProps.values.FlagWTA) {
					return true;
				}
			}

			if (this.haveAllAccountInfo() && !this.haveAllContactInfo()) {
				if (formProps.values.FlagWTA) {
					return true;
				}
			}

			if (this.haveAllAccountInfo() && this.haveAllContactInfo()) {
				if (formProps.values.FlagVerified) {
					return true;
				}
			}

			if (!this.haveAllAccountInfo() && !this.haveAllContactInfo()) {
				if (formProps.values.FlagWTA) {
					return true;
				}
			}
		} else if (formProps.values.FlagVerified == true) {
			return true;
		}

		return false;
	}

	disableWTACheckBox(inEventList) {
		const { FlagVerified, FlagWTA } = this.initialValues;
		//user is not in championship
		if (!inEventList) {
			return true;
		}

		if (FlagVerified) {
			return true;
		}

		if (this.haveNoData()) {
			return true;
		}

		if (!this.haveAllContactInfo()) {
			return true;
		}

		return false;
	}

	disableVerifyCheckBox(inEventList, wtaFlag) {
		const { FlagVerified } = this.initialValues;

		if (!inEventList) {
			logger.log('[PDE - PlayerPrizeMoney] disableVerify not inEventList');
			return true;
		}

		if (this.haveNoData()) {
			logger.log('[PDE - PlayerPrizeMoney] disableVerify haveNoData');
			return true;
		}

		if (!this.haveAllContactInfo()) {
			logger.log('[PDE - PlayerPrizeMoney] disableVerify haveAllContactInfo');
			return true;
		}

		if (FlagVerified) {
			logger.log('[PDE - PlayerPrizeMoney] disableVerify FlagVerified');
			return true;
		}

		if (!wtaFlag && !this.haveAllAccountInfo()) {
			logger.log('[PDE - PlayerPrizeMoney] disableVerify wtaFlag');
			return true;
		}

		return false;
	}

	displayUpdateBtn(initalValues, showWTA) {
		const { FlagVerified, FlagWTA } = initalValues;
		const { AccountNumber, IBANNumber } = this.state.data[1]['response'];

		if (FlagVerified == true && FlagWTA == true) {
			return false;
		}

		if (showWTA) {
			if ((AccountNumber == '' || AccountNumber == null) && (IBANNumber == null || IBANNumber == '')) {
				if (FlagVerified == false || FlagWTA == false) {
					return true;
				}
			} else {
				if (FlagVerified == false) {
					return true;
				}
			}
		} else if (FlagVerified == false) {
			return true;
		}

		return false;
	}

	renderUpdateForm(formProps) {
		let showWta = false;
		let showButton = true;
		let inEventList = false;
		let readOnlyAccess = false;
		let playerCreds = this.state.currentUser.roles.find(ele => ele.key === 'player');
		if (playerCreds) {
			let playerContactDetails = op.get(playerCreds, 'contactDetails', {});
			const { EventCode } = this.initialValues;
			let playerEventCodes = [];
			if (Array.isArray(EventCode)) {
				playerEventCodes = EventCode;
			} else if (EventCode) {
				playerEventCodes = EventCode.split('|');
			}

			const playerShadowAccess = op.get(
				this.state.currentUser,
				'data.systemRole.player.isShadowingAccess',
				false
			);
			if (playerShadowAccess && (playerShadowAccess === 'read' || playerShadowAccess === 'support')) {
				readOnlyAccess = true;
			}

			logger.log(
				'[PDE - PlayerPrizeMoney] playerEventCodes:%o, permitted:%o',
				playerEventCodes,
				this.permittedEvents
			);
			if (playerEventCodes && this.permittedEvents.some(item => playerEventCodes.includes(item))) {
				logger.log('[PDE - PlayerPrizeMoney] in permitted events');
				inEventList = true;
			}

			if (Object.keys(playerContactDetails).length > 0) {
				showWta = playerContactDetails['gender'] == 'female';
				if (this.invitationals.some(item => playerEventCodes.includes(item)) || !inEventList) {
					showWta = false;
				}
			}

			if (!this.displayUpdateBtn({ ...this.initialValues }, showWta)) {
				showButton = false;
			}

			// let mainRegistration = playerContactDetails?.championshipsRegistration?.mainRegistration;
			// let wheelchairRegistration = playerContactDetails?.championshipsRegistration?.wheelchairRegistration;
			// if (
			// 	(mainRegistration !== null || wheelchairRegistration !== null) &&
			// 	(mainRegistration !== undefined || wheelchairRegistration !== undefined)
			// ) {
		}

		return (
			<>
				<Form onSubmit={formProps.handleSubmit}>
					<div className="four-col no-margin left prize-money-checkbox">
						<FormCheckBox
							name="FlagVerified"
							id="FlagVerified"
							disabled={this.disableVerifyCheckBox(inEventList, showWta) || readOnlyAccess}>
							I confirm my bank account and address details are correct
						</FormCheckBox>
					</div>
					{showWta ? (
						<div className="four-col no-margin left prize-money-checkbox">
							<FormCheckBox
								name="FlagWTA"
								id="FlagWTA"
								disabled={this.disableWTACheckBox(inEventList) || readOnlyAccess}>
								I confirm my prize money should be paid via the WTA
							</FormCheckBox>
						</div>
					) : null}
					{showButton && (
						<div className="four-col">
							<span className="prizemoney-btn">
								{formProps.isSubmitting ||
								!this.requiredChecks(showWta, inEventList, formProps) ||
								readOnlyAccess ? (
									<Button className={`media-btn long solid disabled`}>Update</Button>
								) : (
									<Button className={`media-btn long solid`} onClick={formProps.submitForm}>
										Update
									</Button>
								)}
							</span>
						</div>
					)}
					<div className="form-update-text">
						Once the player has validated their Prize Money details, the player can not remove the
						validation
					</div>
				</Form>
			</>
		);
	}

	renderPrizeMoney() {
		return (
			<Fragment>
				{this.renderBankInfo()}
				{this.renderFormik()}
				<hr className="prize-money-form-divider" />
				{this.renderPDFs()}
			</Fragment>
		);
	}

	render() {
		logger.log('[PDE - PlayerPrizeMoney] render - state:%o status:%o', this.state, this.state.status);
		if (!this.state.gotData) {
			return <LoadingIndicator />;
		} else {
			return this.renderPrizeMoney();
		}
	}
}

PlayerPrizeMoney.contextType = PlayerDigitalExperienceContext;
export default connect(mapStateToProps, mapDispatchToProps)(PlayerPrizeMoney);

import SeatingViews from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:lang/seating/:court.html',
		exact: true,
		component: SeatingViews,
		load: params => deps.actions.SeatingViews.mount(params),
	},
];

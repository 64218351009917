import React, { Component, Fragment } from 'react';
import { PlayerDigitalExperienceContext } from '../context';
import op from 'object-path';
import Time from 'appdir/components/common-ui/Time';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getContent } from 'appdir/components/general/Util/Services';
import { loggedIn, checkRole, getRole } from 'appdir/components/general/Util/Role';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getParams } from './Utils.js';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import WimLink from 'appdir/components/general/WimLink';
import RegisterButton from 'appdir/components/cms/RegisterButton';
import Button from 'appdir/components/common-ui/Button';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		stubs: state['Config'].stubPages,
		pde: state['Config'].pde,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
	unmount: () => dispatch(deps.actions.PlayerDigitalExperience.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class PlayerTransport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		logger.log('[PDE - PlayerTransport] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log('[PDE - PlayerTransport] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	componentDidUpdate(prevProps, prevState) {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	showStub() {
		const stubMsg = op.get(this.state, 'stubs.pde.transport.text', null);
		return (
			<div className="four-col bank-info-container">
				<div className="section-container-no-data">
					<div className="row prize-money-explanation">{stubMsg}</div>
				</div>
			</div>
		);
	}

	renderUnavailable() {
		return (
			<Fragment>
				<div className="four-col">
					<div className="transport-header">
						Transport is unavailable. For any questions, please contact us at{' '}
						<WimLink to="mailto:players@aeltc.com" external={true}>
							players@aeltc.com
						</WimLink>
					</div>
				</div>
			</Fragment>
		);
	}

	renderTransportReg() {
		return (
			<Fragment>
				<div className="four-col">
					<div className="transport-header">Transport info....</div>
					<RegisterButton data={{ style: 'four-col', type: 'transport_register' }} />
				</div>
			</Fragment>
		);
	}

	renderTransport() {
		return (
			<Fragment>
				<div className="four-col center">
					<div className="transport-header">Transport info....</div>
					<Button className={`solid`} to={`${this.state.pde.transportLink}`}>
						Open Transport
					</Button>
				</div>
			</Fragment>
		);
	}

	render() {
		logger.log('[PDE - PlayerTransport] render - state:%o status:%o', this.state, this.state.status);
		const showStub = op.get(this.state, 'stubs.pde.transport.stub', false);
		let currentUser = op.get(this.state, 'currentUser', {});
		let playerRole = getRole(op.get(currentUser, 'roles', []), 'player');
		//let transportRole = getRole(op.get(currentUser, 'roles', []), 'transport');
		let isMain =
			op.get(playerRole, 'contactDetails.championshipsRegistration.mainRegistration', false) ||
			op.get(playerRole, 'contactDetails.championshipsRegistration.wheelchairRegistration', false);

		if (!loggedIn) {
			return <LoadingIndicator />;
		} else {
			//force for now since this page probably not used
			if (true) {
				return this.renderUnavailable();
			} else if (!showStub && transportRole) {
				return this.renderTransport();
			} else if (!showStub && !transportRole) {
				return this.renderTransportReg();
			} else {
				return this.showStub();
			}
		}
	}
}

PlayerTransport.contextType = PlayerDigitalExperienceContext;
export default connect(mapStateToProps, mapDispatchToProps)(PlayerTransport);

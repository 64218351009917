/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values } from 'appdir/main';
import deps from 'dependencies';
import indexOf from 'lodash/indexOf';
import WimLink from 'appdir/components/general/WimLink';

// components
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import axios from 'axios';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getQuerystringValues } from 'appdir/components/general/Util';
import classNames from 'classnames';
import { measureInAppContext } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: ScheduleWebview
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ScheduleWebview'],
		scheduleData: state['Tournament']['data']['scheduleScores'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(actions.ScheduleWebview.mount()),
	getSchedule: day => dispatch(deps.actions.Tournament.getScheduleScores({ day: day })),
	stopSchedule: day => dispatch(deps.actions.Tournament.stopSchedule()),
});

class ScheduleWebview extends Component {
	constructor(props) {
		super(props);

		// logger.log('[ScheduleWebview] constructor - props:%o', props);
		let filters = props.filters;

		// now add all the props and filters to state
		this.state = {
			...props,
			favorite_filter: false,
			user_tz_display: false,
			filters: filters,
			favorite_matches: [],
			favorite_matchFilter: false,
			// favMatches: [],
			addedFavs: [],
			removedFavs: [],
		};
		this.firstLoad = true;
		//window.addEventListener("matchFavorite", receiveMatchFavorite, false);
		this.matchFavClicked = this.matchFavClicked.bind(this);

		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
		this.debug = parsedQs.debug;
	}

	componentDidMount() {
		// logger.log('[ScheduleWebview] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.props.stopSchedule();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[ScheduleWebview] componentDidUpdate - state:%o loadedJson:%o', this.state, this.loadedJson);
		logger.log('[ScheduleWebview] componentDidUpdate - prevState:%o, this.state:%o', prevState, this.state);
		logger.log('[ScheduleWebview] componentDidUpdate - this:%o', this);

		if (this.state.location.search) {
			/* timezone offset */
			let timezoneOffset = this.getQSParams(this.state.location.search, 'tzOffset');
			if (timezoneOffset != this.state.timezone_offset) {
				this.setState(prevState => ({
					...prevState,
					timezone_offset: timezoneOffset,
					user_tz_display: true,
				}));
			}

			/* favorites */
			let favPlayers = this.getQSParams(this.state.location.search, 'filterPlayers');

			// let favMatches = this.getQSParams(this.state.location.search, 'filterMatches').split(',');

			if (favPlayers != this.state.favorite_players) {
				this.setState(prevState => ({
					...prevState,
					favorite_players: favPlayers,
					favorite_filter: true,
				}));
			}
		} else {
			this.firstLoad = false;
		}

		if (this.state.filters.day != prevProps.filters.day) {
			this.props.getSchedule(this.state.filters.day);
		}

		if (
			prevState.favorite_matches !== this.state.favorite_matches &&
			// this.state.favMatches !== this.state.favorite_matches &&
			!this.firstLoad
		) {
			logger.log(
				'[ScheduleWebview] componentDidUpdate - prevState.favorite_matches:%o, this.state.favorite_matches:%o',
				prevState.favorite_matches,
				this.state.favorite_matches
			);

			let removeArrays = [prevState.favorite_matches, this.state.favorite_matches];
			let addedArrays = [this.state.favorite_matches, prevState.favorite_matches];
			let removedFavs = removeArrays.reduce((a, b) => a.filter(c => !b.includes(c)));
			let addedFavs = addedArrays.reduce((a, b) => a.filter(c => !b.includes(c)));

			// let favMatches = this.getQSParams(this.state.location.search, 'filterMatches').split(',');

			logger.log('[ScheduleWebview] componentDidUpdate - removedFavs:%o', removedFavs);
			logger.log('[ScheduleWebview] componentDidUpdate - addedFavs:%o', addedFavs);

			for (var i = 0; i < removedFavs.length; i++) {
				const elem = `remove_${removedFavs[i]}`;

				this.setState({
					[elem]: true,
					// favMatches: [],
				});

				window.setTimeout(() => {
					this.setState({
						[elem]: false,
					});
				}, 5000);
			}

			for (var j = 0; j < addedFavs.length; j++) {
				const elem = `add_${addedFavs[j]}`;

				this.setState({
					[elem]: true,
					// favMatches: [],
				});
				window.setTimeout(() => {
					this.setState({
						[elem]: false,
					});
				}, 5000);
			}
		}

		/** fire pageView */
		if(!this.firePageView && this.props.scheduleData?.status == 'loaded') {
			this.firePageView = true;
			measureInAppContext({
				pageTitle:"Order of Play",
				action: this.props.scheduleData?.data?.displayDate,
				args: [],
				context:  [{ date: this.props.scheduleData?.data?.displayDate }, { section: "Order of Play" }],
				metricType: 'state'
			});
		}
	}

	getQSParams(search, which) {
		search = search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);
		let value = '';

		switch (which) {
			case 'filterPlayers':
				if (parsedQs.filterPlayers) {
					value = parsedQs.filterPlayers;
				}
				break;
			case 'filterMatches':
				if (parsedQs.filterMatches) {
					value = parsedQs.filterMatches;
				}
				break;
			case 'tzOffset':
				if (parsedQs.tzOffset) {
					value = parsedQs.tzOffset;
				}
				break;
			default:
				break;
		}

		return value;
	}

	checkTeamsInMatch(match) {
		if (match.team1.length > 0 && match.team2.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	checkPlayersInMatch(match) {
		let matchFavsLocated = false;

		if (this.state.favorite_players) {
			let favsTemp = this.state.favorite_players.split(',');

			if (this.checkTeamsInMatch(match)) {
				if (
					indexOf(favsTemp, match.team1[0].idA) > -1 ||
					indexOf(favsTemp, match.team1[0].idB) > -1 ||
					indexOf(favsTemp, match.team2[0].idA) > -1 ||
					indexOf(favsTemp, match.team2[0].idB) > -1
				) {
					matchFavsLocated = true;
				}
			}
		}

		return matchFavsLocated;
	}

	checkPlayersOnCourt(courtId) {
		let courtToCheck = this.state.scheduleData.data.courts.filter(court => court.courtId == courtId);
		let matchesOnCourt = courtToCheck[0].matches;
		let courtFavsLocated = false;

		if (this.state.favorite_players) {
			matchesOnCourt.map((match, index) => {
				courtFavsLocated = this.checkPlayersInMatch(match);
			});
		}

		return courtFavsLocated;
	}

	formatCourtTime(courtEpoch) {
		courtEpoch = courtEpoch * 1000; //have to make it milliseconds

		let wimTime = moment(courtEpoch).tz('Europe/London');
		let wimOffset = wimTime.utcOffset();

		// logger.log('[ScheduleWebview] convertCourtTime - courtEpoch:%o ', courtEpoch);
		// logger.log('[ScheduleWebview] convertCourtTime - wimTime:%o ', wimTime);

		if (this.state.timezone_offset && this.state.timezone_offset !== wimOffset && this.state.user_tz_display) {
			let localTime = moment(courtEpoch).zone(this.state.timezone_offset);
			let timeZone = moment.tz.guess();
			let localTimeZone = moment.tz(timeZone).format('z');

			return `${wimTime.format('h:mm')} ${wimTime.format('a')} BST ${localTime.format('h:mm')} ${localTime.format(
				'a'
			)} ${localTimeZone}`;
		} else {
			return `${wimTime.format('h:mm')} ${wimTime.format('a')} BST`;
		}
	}

	convertCourtTime(courtEpoch) {
		if (courtEpoch) {
			courtEpoch = courtEpoch * 1000; //have to make it milliseconds

			let wimTime = moment(courtEpoch).tz('Europe/London');
			let wimOffset = wimTime.utcOffset();

			// logger.log('[ScheduleWebview] convertCourtTime - courtEpoch:%o ', courtEpoch);
			// logger.log('[ScheduleWebview] convertCourtTime - wimTime:%o ', wimTime);

			if (this.state.timezone_offset && this.state.timezone_offset !== wimOffset && this.state.user_tz_display) {
				let localTime = moment(courtEpoch).zone(this.state.timezone_offset);
				let timeZone = moment.tz.guess();
				let localTimeZone = moment.tz(timeZone).format('z');

				// logger.log('[ScheduleWebview] convertCourtTime - localTime:%o ', localTime);
				// logger.log('[ScheduleWebview] convertCourtTime - localTimeZone:%o ', localTimeZone);

				return (
					<div>
						<span className="wimTime time-display">
							<span className="time">{wimTime.format('h:mm')}</span>
							<span className="suffix">
								<span className="meridiem">{wimTime.format('a')}</span>
								<span className="timezone">BST</span>
							</span>
						</span>
						<span className="localTime time-display">
							<span className="time">{localTime.format('h:mm')}</span>
							<span className="suffix">
								<span className="meridiem">{localTime.format('a')}</span>
								<span className="timezone">{localTimeZone}</span>
							</span>
						</span>
					</div>
				);
			} else {
				return (
					<span className="wimTime time-display">
						<span className="time">{wimTime.format('h:mm')}</span>
						<span className="suffix">
							<span className="meridiem">{wimTime.format('a')}</span>
							<span className="timezone">BST</span>
						</span>
					</span>
				);
			}
		} else {
			return null;
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[ScheduleWebview] componentWillReceiveProps - prev:%o', this.state);
		// logger.log('[ScheduleWebview] componentWillReceiveProps - next:%o', nextProps);

		let url = '';

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				nav: url,
			};
		});

		// logger.log('[ScheduleWebview] componentWillReceiveProps - state:%o', this.state);
	}
	matchFavClicked(match_id) {
		// let tempFavs = Object.assign([], this.state.favorite_matches.concat(this.state.favMatches));
		// tempFavs = Array.from(new Set(tempFavs));
		// logger.log('[ScheduleWebview] matchFavClicked - tempFavs:%o', tempFavs);
		// if (!tempFavs.includes(match_id)) {
		// 	tempFavs.push(match_id);
		// } else {
		// 	var filtered = tempFavs.filter(function(value, index, arr) {
		// 		return value !== match_id;
		// 	});
		// 	tempFavs = filtered;
		// }
		// logger.log('[ScheduleWebview] matchFavClicked after - tempFavs:%o', tempFavs);
		// this.setState({
		// 	favorite_matches: tempFavs,
		// });
	}

	renderMatches(match) {
		{
			if (this.checkTeamsInMatch(match)) {
				// let matchFavClass = classNames({
				// 	'wim-icon-favStar':
				// 		(this.state.favMatches.length == 0 && this.state.favorite_matches.length == 0) ||
				// 		(this.state.favMatches.indexOf(match.match_id) == -1 &&
				// 			this.state.favorite_matches.indexOf(match.match_id) == -1),
				// 	'wim-icon-favStarSolid':
				// 		this.state[`add_${match.match_id}`] ||
				// 		this.state[`remove_${match.match_id}`] ||
				// 		this.state.favMatches.indexOf(match.match_id) !== -1 ||
				// 		this.state.favorite_matches.indexOf(match.match_id) !== -1,
				// });

				let highlightClass = classNames({
					'match-favorite': true,
					'highlight-added': this.state[`add_${match.match_id}`],
					'highlight-removed': this.state[`remove_${match.match_id}`],
				});
				//if ((this.state.favorite_filter && this.checkPlayersInMatch(match)) || !this.state.favorite_filter) {
				return (
					<div
						key={match.match_id}
						className="match"
						data-players={`${match.team1[0].idA},${match.team1[0].idB},${match.team2[0].idA},${match.team2[0].idB}`}
						data-match={match.id}>
						<div className="row">
							<div className="match-info header" colSpan="3">
								{match.notBefore ? (
									<div className="match-info-notBefore">NOT BEFORE: {match.notBefore}</div>
								) : null}
								<span className="event">{match.eventName}</span>
								<span className="dash">{` - `}</span>
								<span className="round">{match.roundName}</span>
							</div>
							<div className="scores header">{match.shortScore}</div>
						</div>
						<div className="row teams">
							<div className="schedule-team content">{this.getTeamNames(match.team1)}</div>
							<div className="versus content">{match.conjunction}</div>
							<div className="schedule-team content">{this.getTeamNames(match.team2)}</div>
							<div className="status content">
								{/* {
									<div
										className={highlightClass}
										onClick={() =>
											this.matchFavClicked(match.match_id, this.state.favorite_matches)
										}>
										<WimLink
											className="name"
											to={
												this.state.favorite_matches.indexOf(match.match_id) != -1
													? `/addMatchFav/${match.match_id}.html`
													: `/removeMatchFav/${match.match_id}.html`
											}>
											<i className={matchFavClass} />
										</WimLink>
									</div>
								} */}
								<div className="status-text">{match.status}</div>
							</div>
							{/* {
								<div
									className={highlightClass}
									onClick={() => this.matchFavClicked(match.match_id, this.state.favorite_matches)}>
									<WimLink
										className="name"
										to={
											this.state.favorite_matches.indexOf(match.match_id) != -1
												? `/addMatchFav/${match.match_id}.html`
												: `/removeMatchFav/${match.match_id}.html`
										}>
										<i className={matchFavClass} />
									</WimLink>
								</div>
							} */}
						</div>
						<div className="row mobile">
							<div className="scores">{match.shortScore}</div>
							<div className="status">{match.status}</div>
						</div>
					</div>
				);
				//}
			}
		}
	}

	renderCourts(court) {
		//if((this.state.favorite_filter && this.checkPlayersOnCourt(court.courtId)) || !this.state.favorite_filter) {

		let arialabel = `${court.courtName}, ${this.formatCourtTime(court.startEpoch)}`;

		court.matches.map(match => {
			arialabel = `${arialabel}
			${match.eventName} ${match.roundName} ${this.getTeamNamesString(match.team1)} ${
				match.conjunction
			} ${this.getTeamNamesString(match.team2)}.`;
			if (match.status)
				arialabel = `${arialabel}
			The match status is ${match.status}.`;
		});

		return (
			<div key={court.courtName} className="schedule-court">
				<div className="courtName" tabIndex={0} aria-label={arialabel}>
					{court.courtName}
					<br />
					{this.convertCourtTime(court.startEpoch)}
				</div>
				<div className="schedule-content">
					{court.matches.map((match, index) => {
						return this.renderMatches(match);
					})}
				</div>
			</div>
		);
		//}
	}

	getCountry(nation) {
		if (nation && !values.countryHide.includes(nation)) {
			return `(${nation})`;
		} else {
			return '';
		}
	}

	getPlayerNamesString(team) {
		let favsTemp = '';

		if (this.state.favorite_players) {
			favsTemp = this.state.favorite_players.split(',');
		}

		let stringValue = `${team.displayNameA}`;

		if (team.idB) {
			stringValue = `${stringValue} ${team.displayNameB}`;
		}

		return stringValue;
	}

	getPlayerNames(team) {
		let favsTemp = '';

		if (this.state.favorite_players) {
			favsTemp = this.state.favorite_players.split(',');
		}

		return (
			<span>
				<div>
					<span
						className={`schedule-player player1 ${indexOf(favsTemp, team.idA) > -1 ? 'favorite' : ''}`}
						data-player={team.idA}>
						<WimLink
							className="name"
							to={`/en_GB/players/overview/${team.idA}.html`}
							title={`${team.displayNameA} player profile`}>
							{team.displayNameA}
						</WimLink>
						<span className="nation"> {this.getCountry(team.nationA)} </span>
						<span className="seed">{team.seed}</span>
					</span>
				</div>
				{team.idB ? (
					<div>
						<span
							className={`schedule-player player2 ${indexOf(favsTemp, team.idB) > -1 ? 'favorite' : ''}`}
							data-player={team.idB}>
							<WimLink
								className="name"
								to={`/en_GB/players/overview/${team.idB}.html`}
								title={`${team.displayNameB} player profile`}>
								{team.displayNameB}
							</WimLink>
							<span className="nation"> {this.getCountry(team.nationB)} </span>
							<span className="seed">{team.seed}</span>
						</span>
					</div>
				) : null}
			</span>
		);
	}

	getTeamNamesString(team) {
		if (team.length == 1) {
			return this.getPlayerNamesString(team[0]);
		} else if (team.length == 2) {
			return `winner of ${this.getPlayerNamesString(team[0])} versus ${this.getPlayerNamesString(team[1])}`;
		}
	}

	getTeamNames(team) {
		if (team.length == 1) {
			return <span>{this.getPlayerNames(team[0])}</span>;
		} else if (team.length == 2) {
			return (
				<span className="winner-of-holder">
					<div className="winner-of">winner of</div>
					<div className="winner-of1">{this.getPlayerNames(team[0])}</div>
					<div className="winner-versus">versus</div>
					<div className="winner-of2">{this.getPlayerNames(team[1])}</div>
				</span>
			);
		}
	}

	render() {
		logger.log('[ScheduleWebview] render - state:%o', this.state);

		// if theres no tournDay, then redirect to current day that is in the activeData.
		if (this.state.scheduleData?.data?.status == 'error') {
			return (
				<section className="wrapper scorespage">
					<div className="content-main">
						<GenericError message="No schedule found" />
					</div>
				</section>
			);
		} else if (this.state.scheduleData?.status == 'loaded') {
			return (
				<section id="schedule" className={`wrapper scorespage webview ${this.debug ? 'debug' : ''}`}>
					<div className="content-main">
						<div className="column-layout scorespage">
							<div className="schedule-info">
								<div className="day">{this.state.scheduleData?.data?.displayDate}</div>
								{this.state.scheduleData?.data?.comments != null ? (
									<div className="revised">{this.state.scheduleData?.data?.comments}</div>
								) : (
									''
								)}
							</div>
							{this.state.scheduleData?.data?.courts.map((court, index) => {
								return this.renderCourts(court);
							})}
						</div>
					</div>
				</section>
			);
		} else {
			return (
				<section className="wrapper scorespage">
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleWebview);

export const getEventName = eventCode => {
	if (eventCode) {
		switch (eventCode.toLowerCase()) {
			case 'ms':
				return 'gentlemens-singles';

			case 'ls':
				return 'ladies-singles';
		}
	}
};

export const getEventCode = eventName => {
	if (eventName) {
		switch (eventName.toLowerCase()) {
			case 'gentlemens-singles':
				return 'ms';

			case 'ladies-singles':
				return 'ls';
		}
	}
};

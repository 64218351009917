/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { OIDCContext } from './context';

import op from 'object-path';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import MeasurementUtils from 'appdir/lib/analytics';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component:Logout
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	logout: data => dispatch(deps.actions.Gigya.logout(data)),
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
});

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.loggingOut = false;
		this.showLogin = false;

		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		this.redirect = op.get(parsedQs, 'redirect', '');

		//logger.log('[OIDC_Logout] constructor - redirect:%o', this.redirect);
	}

	componentDidMount() {
		let data = {};
		data.pageTitle = 'Logout-OIDC';
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, data);
	}

	componentWillUnmount() {
		//this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[OIDC_Logout] componentDidUpdate - prev:%o state:%o', prevState, this.state);
		logger.log('[OIDC_Logout] componentDidUpdate - state:%o context:%o', this.state, this.context);

		if (this.context.loggedIn && this.context.gigyaLoaded && !this.loggingOut) {
			logger.log('[OIDC_Logout] componentDidUpdate - call logout');
			this.loggingOut = true;
			this.props.logout();
		} else if (this.loggingOut && !this.state.redirect && this.context.loggedIn && this.context.gigyaLoaded) {
			this.setState({
				redirect: this.redirect || '/en_GB/mywimbledon/index.html',
			});
		}

		// let url = '';
		// if (!this.context.loggedIn && this.context.gigyaLoaded) {
		// 	this.showLogin = true;
		// } else if (this.context.loggedIn && this.context.gigyaLoaded && this.showLogin) {
		// 	url = gigya.utils.URL.addParamsToURL(this.context.proxy, {
		// 		mode: 'afterLogin',
		// 	});
		// 	window.location.href = url;
		// } else if (this.context.loggedIn && this.context.gigyaLoaded && !this.showLogin) {
		// 	// this.setState({
		// 	// 	redirect: '/en_GB/myWimbledon/index.html',
		// 	// });
		// }
	}

	render() {
		logger.log(
			'[OIDC_Logout] render - state:%o context:%o starts:%o',
			this.state,
			this.context,
			String(this.state.redirect).startsWith('http')
		);

		if (this.state.redirect && String(this.state.redirect).startsWith('http')) {
			document.location.href = this.state.redirect;
		}

		return (
			<Fragment>
				{this.state.redirect ? (
					<WimRedirect to={`${this.state.redirect}`} />
				) : !this.context.loggedIn && this.context.gigyaLoaded ? (
					<GigyaScreenSet params={{ screen: 'login_screen', social: this.context.social }} inline={true} />
				) : (
					<LoadingIndicator />
				)}
			</Fragment>
		);
	}
}

Login.contextType = OIDCContext;
export default connect(mapStateToProps, mapDispatchToProps)(Login);

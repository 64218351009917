import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	//logger.log('[ScheduleWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.SCHEDULEWEBVIEW_LOAD:
			// logger.log('[ScheduleWebview] reducers - action:%o',action);

			filters = {
				...state.filters,
				day: action.data.tournDay,
			};

			newState = {
				...state,
				path: action.data.path,
				daysPath: action.data.daysPath,
				status: 'load',
				filters: filters,
			};
			// logger.log('[ScheduleWebview] reducers SCHEDULEWEBVIEW_LOAD - newState:%o',newState);
			return newState;
		//break;

		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentListTile from './ContentListTile';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: RelatedContent
 * -----------------------------------------------------------------------------
 */
class RelatedContent extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}
	formatImage(data) {
		return data.thumb
			? data.thumb.charAt(0) == '/'
				? 'http://www.wimbledon.com/' + data.thumb
				: data.thumb
			: data.images[0].xlarge;
	}
	formatNewsTileData(relatedContent) {
		let _this = this;
		let newsTile = { ...relatedContent };
		return {
			img: _this.formatImage(relatedContent),
			new: true, //need to discuss with Jeff on giving news the new tag
			type: newsTile.type,
			typeText: newsTile.type,
			date: newsTile.date || newsTile.displayDate,
			title: newsTile.title,
			style: 'two-col',
			description: newsTile.description ? newsTile.description : '',
		};
	}

	render() {
		let relatedContent = {};
		if (this.state.data) {
			relatedContent = this.formatNewsTileData(this.state.data);
		}
		return (
			<div className={`related-cnt`}>
				{<ContentListTile attributes={{ ...relatedContent, 'col-style': relatedContent.style }} />}
			</div>
		);
	}
}

export default RelatedContent;

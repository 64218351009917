/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { values } from 'appdir/main';

import findIndex from 'lodash/findIndex';
import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import NewsTile from 'appdir/components/content/NewsTile';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import LazyPlaceholder from 'appdir/components/content/LazyPlaceholder';
import LazyLoad from 'react-lazy-load';
import MeasurementUtils from 'appdir/lib/analytics';
import { getQuerystringValues } from 'appdir/components/general/Util';
import Helmet from 'react-helmet';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoIndex
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['VideoIndex'],
		prevLocation: state['Router']['prevLocation'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.VideoIndex.mount()),
	fullwindow: data => dispatch(deps.actions.VideoPlayer.fullwindow(data)),
});

class VideoIndex extends Component {
	constructor(props) {
		super(props);

		//set default filter based on querystring
		let filters = props.filters;
		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);
		let menu = [];
		let index = -1;

		if (parsedQs.filter) {
			index = findIndex(menu, function(o) {
				return o.query == parsedQs.filter.toLowerCase();
			});
		}

		if (index > -1) {
			filters['type'] = menu[index].value;
		}

		logger.log('[VideoIndex] constructor - props:%o filters:%o', props, filters);

		this.onVideoEvent = this.onVideoEvent.bind(this);

		this.state = {
			...props,
			filters: filters,
		};

		this.backUrl = '/en_GB/video/index.html';
		this.count = 0;
		this.max = 100;
		this.loadedJson = '';
		this.loadedRelatedVideo = '';
	}

	componentDidMount() {
		//logger.log('[VideoIndex] componentDidMount - state:%o', this.state);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	componentWillUpdate(nextProps, nextState) {}

	componentWillUnmount() {
		//logger.log('[VideoIndex] componentWillUnmount');
		this.setState({
			...this.state,
			data: null,
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[VideoIndex] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		//let url = '';
		//let data = this.state.data;

		// if (this.state.filters.type != nextProps.filters.type) {
		//     logger.log('[VideoIndex] componentWillReceiveProps - nav - stateType:%o nextType:%o', this.state.filters.type, nextProps.filters.type);
		//     url = '/en_GB/video/index.html?filter=' + type;
		//     data = null;
		// }

		let data = this.state.data;
		if (this.state.location.pathname != nextProps.location.pathname) {
			data = null;
		}

		//set default category of index if none (single video url)
		let category = nextProps.category;
		if (!category) {
			category = 'index';
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				category: category,
				data: data,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[VideoIndex] componentDidUpdate - state:%o loadedJson:%o', this.state, this.loadedJson);

		// calculate new data path from path in state and current filters
		let fileName = this.state.category && this.state.category != 'index' ? this.state.category : 'video';
		let dataPath = this.state.path ? this.state.path.replace('<category>', fileName) : null;

		// if the data path is not what was last loaded, load now
		if (dataPath && dataPath != this.loadedJson) {
			this.loadedJson = dataPath;
			logger.log('[VideoIndex] componentDidUpdate - path:%o', dataPath);
			deps.services.VideoIndex.fetch(dataPath)
				.then(result => {
					this.setState({
						data: result,
					});
					logger.log('[VideoIndex] componentDidUpdate - result:%o', result);

					MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
						pageTitle: 'Video Gallery',
						category: this.state.category == 'index' ? 'all' : this.state.category,
					});
				})
				.catch(error => {
					logger.error('[VideoIndex] componentDidUpdate - error:%o', error);
					if (error.status == 404) {
						this.setState({
							data: 'notfound',
						});
					}
				});
		}

		// videoId indicates direct video from url
		if (this.state.videoId && this.state.contentId && !this.loadedRelatedVideo) {
			let contentId = this.state.contentId.replace('<id>', this.state.videoId);
			let relatedPath = this.state.relatedVideoPath.replace('<videoId>', contentId);
			this.loadedRelatedVideo = relatedPath;
			//logger.log('[VideoIndex] componentDidUpdate - relatedPath:%o', relatedPath);

			deps.services.VideoPlayer.fetchRelated(relatedPath)
				.then(result => {
					//logger.log(`${this.name} checkVideo - related - data:%o`, result);
					this.setState({
						directVideo: true,
						relatedContentData: result,
					});
					//logger.log(`[VideoIndex] componentDidUpdate - related - data:%o`, result);
				})
				.catch(error => {
					logger.warn('[VideoIndex] componentDidUpdate - related - %o', error);
				});
		}
	}

	setFilter(filter) {
		this.setState({
			filter: filter.toLowerCase(),
		});
	}

	/**
	 * get jsx of all videos with category headers
	 * @param {} item
	 * @param {*} index
	 */
	getCategory(item, index) {
		logger.log('[VideoIndex] getCategory - item:%o', item);

		let linkUrl = '/en_GB/video/<filter>.html';
		linkUrl = linkUrl.replace('<filter>', item.category);

		if (item.videos) {
			return (
				<div className="column-layout" key={item.category}>
					<div className="video-page-section-header section-header">
						<div className="header-title">{item.title}</div>
						<div className="header-select">
							<Link to={linkUrl}> All {item.title}</Link>
						</div>
					</div>

					{item.videos.map((video, index) => {
						//logger.log('[VideoIndex] getNewsItem - video:%o', video);
						this.count++;

						let adPath = video.media.adTag ? video.media.adTag : '';

						let attributes = {
							'col-style': 'one-col',
							news: 'new',
							contentId: null,
							cmsId: video.cmsId,
							title: video.title,
							url: video.url,
							adUrl: adPath,
							shareUrl: video.shareUrl,
							date: video.epoch,
							type: 'VIDEO',
							duration: video.media.duration,
							thumb: video.images.thumb.xlarge,
							videoUrl: video.media.m3u8,
							aspect: 'wide',
							videoSrc: video.media.src,
						};
						logger.log('[VideoIndex] getCategory - attr:%o', attributes);
						return (
							<LazyLoad
								once
								throttle={300}
								offset={1000}
								scroll={true}
								resize={true}
								key={attributes.cmsId}
								className={'one-col'}
								placeholder={<LazyPlaceholder attributes={attributes} />}>
								<NewsTile attributes={attributes} key={attributes.cmsId} />
							</LazyLoad>
						);
					})}
				</div>
			);
		} else {
			return null;
		}
	}

	/**
	 * return LazyLoaded NewsTile component for each video
	 * @param {object} video json node
	 * @param {*} index
	 */
	getVideo(video, index) {
		if (video.media) {
			this.count++;

			let adPath = video.media.adTag ? video.media.adTag : '';
			let attributes = {
				'col-style': 'one-col',
				news: 'new',
				contentId: null,
				cmsId: video.cmsId,
				title: video.title,
				url: video.url,
				adUrl: adPath,
				shareUrl: video.shareUrl,
				date: video.epoch,
				type: 'VIDEO',
				duration: video.media.duration,
				thumb: video.images.thumb.xlarge,
				videoUrl: video.media.m3u8,
				aspect: 'wide',
				videoSrc: video.media.src,
			};

			logger.log('[VideoIndex] getVideo - atrr:%o', attributes);
			return (
				<LazyLoad
					once
					throttle={300}
					offset={1000}
					scroll={true}
					resize={true}
					key={attributes.cmsId}
					className={'one-col'}
					placeholder={<LazyPlaceholder attributes={attributes} />}>
					<NewsTile attributes={attributes} key={attributes.cmsId} />
				</LazyLoad>
			);
		} else {
			return null;
		}
	}

	/**
	 * get category list of index or list of videos
	 */
	getVideos() {
		//logger.log('[VideoIndex] getVideos - state:%o', this.state);
		if (this.state.category == 'index') {
			return this.state.data.content.map((item, index) => this.getCategory(item, index));
		} else {
			return (
				<div className="column-layout">
					<div className="video-page-section-header section-header">
						<div
							className="header-title"
							role="heading"
							tabIndex={0}
							aria-label={`${this.state.data.title} page`}>
							{this.state.data.title}
						</div>
						<div className="header-select">
							<Link to={this.backUrl}> BACK TO ALL VIDEOS</Link>
						</div>
					</div>
					{this.state.data.content.map((item, index) => this.getVideo(item, index))}
				</div>
			);
		}
	}

	/**
	 * return jsx if direct video id from route
	 * *** data format to change for related content ***
	 */
	getDirectVideo() {
		if (this.state.directVideo && this.state.relatedContentData) {
			let { relatedContentData } = this.state;
			let thumbImage = op.get(relatedContentData, 'images.0.xlarge', '');
			let adPath = op.get(relatedContentData, 'media.adTag', '');
			let videoUrl = relatedContentData.media.m3u8 ? relatedContentData.media.m3u8 : relatedContentData.media.mp4;

			let attributes = {
				playerId: 'main',
				contentId: null,
				cmsId: relatedContentData.cmsId,
				videoUrl: videoUrl,
				adUrl: adPath,
				shareUrl: relatedContentData.shareUrl,
				date: relatedContentData.displayDate,
				title: relatedContentData.title,
				description: relatedContentData.description,
				thumb: thumbImage,
				autoplay: true,
				fullscreenMode: '',
				style: `video-wrapper modal`,
				aspect: 'wide',
				streamType: 'vod',
				videoSrc: op.get(relatedContentData, 'media.src', ''),
			};

			logger.log('[VideoIndex] getDirectVideo - attributes:%o', attributes);

			return (
				<div className="news-tile video-tag one-col" id={this.state.videoId}>
					<VideoPlayer attributes={attributes} onEvent={this.onVideoEvent} />
				</div>
			);
		} else {
			return null;
		}
	}

	/**
	 * check if no videos display
	 */
	checkError() {
		//check if need to display error
		let error = '';
		if (this.count == 0) {
			error = 'show';
		}
		return (
			<div className={`errorMessage ${error}`} tabIndex={0}>
				There is no content for your selection.
			</div>
		);
	}

	onVideoEvent(event) {
		logger.info('[VideoIndex] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.props.fullwindow({ className: false, type: false });
			this.setState({
				directVideo: '',
			});

			if (this.state.prevLocation) {
				logger.info('[VideoIndex] close:%o', this.state.prevLocation);
				this.props.history.goBack();
			}
		}
	}

	render() {
		logger.log('[VideoIndex] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'videos',
			title: 'VIDEOS',
			shortTitle: 'VIDEOS',
			scroll: false,
			metaTitle: 'Videos',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		if (this.state.directVideo && this.state.relatedContentData) {
			header_propsData = {
				...header_propsData,
				type: 'V',
				canonicalLink: values.canonicalHost + window.location.pathname,
				metaTitle: 'Video - ' + op.get(this.state, 'relatedContentData.title', ''),
				metaDate: op.get(this.state, 'relatedContentData.displayDate', ''),
				metaDescription: op.get(this.state, 'relatedContentData.description', ''),
				shareImage: op.get(this.state.relatedContentData, 'images.0.xlarge', ''),
				media: this.state.relatedContentData.media.m3u8
					? this.state.relatedContentData.media.m3u8
					: this.state.relatedContentData.media.mp4,
			};
		}

		//logger.log('[VideoIndex] render - header:%o', header_propsData);

		if (this.state.nav && this.state.nav != this.state.location.pathname) {
			return <Redirect push to={this.state.nav} />;
		} else if (this.state.data && this.state.data.content) {
			let content = this.state.data.content;
			this.count = 0;

			//logger.log('[VideoIndex] render - promos:%o', this.state.data.promos[0]);

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main landing">
						<noindex>
							{this.getDirectVideo()}
							{this.getVideos()}
							{this.checkError()}
						</noindex>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoIndex);

import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import op from 'object-path';
import TransferAction from './TicketActionViews/TransferAction';
import RecallAction from './TicketActionViews/RecallAction';
import CancelAction from './TicketActionViews/CancelAction';
import ReturnToSender from './TicketActionViews/ReturnToSender';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';

class DesktopTicketActionModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actionMsg: '',
			actionSubmitted: false,
		};
	}

	onRenderActionType(actionType) {
		let display;
		const {
			closeModal,
			fullTicketValue,
			callTicketActions,
			ticketStubToRender,
			renderLargeTransferModal,
			showModal,
		} = this.props;

		switch (actionType.toLowerCase()) {
			case 'transfer':
				display = (
					<TransferAction
						closeModal={closeModal}
						fullTicketValue={fullTicketValue}
						ticketStubToRender={ticketStubToRender}
						renderLargeTransferModal={renderLargeTransferModal}
						showModal={showModal}
						callTicketActions={callTicketActions}
						onRefreshTicketData={() => this.props.onRefreshTicketData()}
						ticketData={this.props.ticketData}
						onRenderMultipleTicketStubs={(value, err, email) =>
							this.props.onRenderMultipleTicketStubs(value, err, email)
						}
						onRenderSingleTicketStub={(value, err, email) =>
							this.props.onRenderSingleTicketStub(value, err, email)
						}
						onSetActionSubmitted={() => this.setState({ actionSubmitted: true })}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				);
				break;
			case 'recall': // note: aka "cancel transfer"
				display = (
					<RecallAction
						closeModal={closeModal}
						fullTicketValue={fullTicketValue}
						callTicketActions={callTicketActions}
						showModal={showModal}
						onRefreshTicketData={() => this.props.onRefreshTicketData()}
						ticketData={this.props.ticketData}
						onRenderMultipleTicketStubs={(value, err, email) =>
							this.props.onRenderMultipleTicketStubs(value, err, email)
						}
						onRenderSingleTicketStub={(value, err, email) =>
							this.props.onRenderSingleTicketStub(value, err, email)
						}
						ticketStubToRender={ticketStubToRender}
						onSetActionSubmitted={() => this.setState({ actionSubmitted: true })}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				);
				break;
			case 'cancel':
				let optionsArr = [];
				Object.entries(this.props.configTickets.cancelOptions).map(item => {
					optionsArr.push({ value: item[0], label: item[1] });
				});
				display = (
					<CancelAction
						optionsArr={optionsArr}
						closeModal={closeModal}
						fullTicketValue={fullTicketValue}
						showModal={showModal}
						onRefreshTicketData={() => this.props.onRefreshTicketData()}
						onRenderMultipleTicketStubs={(value, err, email) =>
							this.props.onRenderMultipleTicketStubs(value, err, email)
						}
						onRenderSingleTicketStub={(value, err, email) =>
							this.props.onRenderSingleTicketStub(value, err, email)
						}
						ticketStubToRender={ticketStubToRender}
						onSetActionSubmitted={() => this.setState({ actionSubmitted: true })}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				);
				break;
			case 'return':
			case 'returntosender':
				display = (
					<ReturnToSender
						closeModal={closeModal}
						fullTicketValue={fullTicketValue}
						callTicketActions={callTicketActions}
						showModal={showModal}
						refreshData={this.props.refreshData}
						ticketData={this.props.ticketData}
						onRefreshTicketData={() => this.props.onRefreshTicketData()}
						onRenderMultipleTicketStubs={(value, err, email) =>
							this.props.onRenderMultipleTicketStubs(value, err, email)
						}
						onRenderSingleTicketStub={(value, err, email) =>
							this.props.onRenderSingleTicketStub(value, err, email)
						}
						ticketStubToRender={ticketStubToRender}
						onSetActionSubmitted={() => this.setState({ actionSubmitted: true })}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				);
				break;
			default:
				display = (
					<div className="ticket-action-modal-info-txt-container" style={{ textAlign: 'center' }}>
						Please try again
					</div>
				);
				break;
		}
		return display;
	}

	render() {
		const { title, closeModal } = this.props;
		const { actionMsg } = this.state;

		let titleValue =
			title.toLowerCase() === 'returntosender'
				? 'return to sender'
				: title.toLowerCase() === 'recall'
				? 'cancel transfer'
				: title;

		return (
			<div className="ticket-actions-view-overlay">
				<div className="ticket-actions-view-wrapper">
					<div
						className="ticket-actions-view-container my-wimbledon"
						tabIndex={0}
						alt={`${titleValue} action modal open`}
						aria-label={`${titleValue} action modal open`}>
						<CloseBtn
							closeFn={() => {
								if (this.state.actionSubmitted) {
									closeModal('refresh');
									// reset state
									this.setState({ actionSubmitted: false });
								} else {
									closeModal();
								}
							}}
						/>
						<div
							className="ticket-actions-view-title"
							tabIndex={0}
							alt={titleValue}
							aria-label={titleValue}>
							{titleValue}
						</div>
						<div>{actionMsg === '' ? this.onRenderActionType(title) : actionMsg}</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		...state['Tickets'],
		configTickets: op.get(state['Config'], 'tickets', null),
		helpCenterUrl: op.get(state['Config'], 'tickets.helpCenterUrl', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	callTicketActions: (service, id, data) => dispatch(deps.actions.Tickets.callTicketActions(service, id, data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DesktopTicketActionModal);

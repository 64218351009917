/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

import DayNav from 'appdir/components/common-ui/DayNav';
import Favorites from 'appdir/components/common-ui/Favorites';
import SearchBox from './SearchBox';
import { values } from 'appdir/main';
import { fetch } from 'appdir/components/general/Util';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import MeasurementUtils from 'appdir/lib/analytics';
import { getEventName, getEventCode } from 'appdir/components/pages/InsightsIndex/Utils';
import { doMeasurement } from 'appdir/components/general/Analytics';

import MIHeaderContents from 'appdir/components/pages/MatchInsights/elements/MIHeaderContents';
import MIInfoIcon from '../MatchInsights/elements/MIInfoIcon';
import MIModal from '../MatchInsights/elements/MIModal';
import MatchCard from 'appdir/components/pages/InsightsIndex/MatchCard';

import StubBox from 'appdir/components/common-ui/StubBox';

/**
 * -----------------------------------------------------------------------------
 * React Component: InsightsIndex
 * -----------------------------------------------------------------------------
 */

export default class InsightsIndex extends Component {
	constructor(props) {
		super(props);

		this.state = {
			matchType: 'ms',
			openModal: false,
		};
		this.courtSort = [];

		this.dataLoaded = false;
		this.initLoad = true;

		this.basePath = '/en_GB/matchinsights';
		if (window.webviewPlatform) {
			this.basePath = '/webview/en_GB/matchinsights';
		}
		this.defaultTournDay = '8';

		this.setMatchType = this.setMatchType.bind(this);
		this.setDay = this.setDay.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.onEnter = this.onEnter.bind(this);
		this.infoClick = this.infoClick.bind(this);
		logger.log('[InsightsIndex] constructor - this:%o', this);
	}

	componentDidMount() {
		/**
		 * due to bypassAppLink, ios is no longer firing the pageView metrics call
		 * that's navigated to through bypassAppLink={true} b/w Insights Index and Insights Details
		 * Detect the previous page path and if it's from MI details page, send messageHandlers to iOS
		 */
		const insightsRegex = /\/en_GB\/matchinsights\/(\d*)\.html/;
		let insightsMatch = document.referrer.match(insightsRegex);

		if (window.webviewPlatform == 'ios' && insightsMatch) {
			let metricsVal = `${values.matchInsightsTitle}:Index`;

			// logger.log('[MatchInsightsIndex] componentDidMount - metricsVal:%o', metricsVal);
			window.webkit.messageHandlers.metrics.postMessage(metricsVal);
		}

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: values.matchInsightsTitle,
		});
	}

	componentWillUnmount() {
		logger.log('[InsightsIndex] componentWillUnmount - this:%o');

		this.props.clearTournament();
	}

	componentDidUpdate(prevProps, prevState) {
		let tournamentCurrentDay = this.props.currentDay?.schedule;
		let availableMatches = this.props.commonData?.matchInsightsMatches?.result?.matches;

		let curMatchTypeProps = this.props.match?.params?.matchType;
		let curTournDayProps = this.props.match?.params?.tournDay;
		let prevMatchTypeProps = prevProps?.match?.params?.matchType;
		let prevTournDayProps = prevProps?.match?.params?.tournDay;

		if (window.webviewPlatform) {
			/** prevent insightsIndex to override the currentDay value as undefined,
			 *  use a different property name
			 */
			let path = this.props.otherData?.activeData?.path;

			if (!this.state?.activeDataCurrentDay && path) {
				fetch(path)
					.then(result => {
						// logger.log('[MatchInsightsIndex] componentDidUpdate result:%o', result);
						this.setState({
							activeDataCurrentDay: result?.currentDay,
							status: 'loaded',
						});
					})
					.catch(error => {
						this.setState({
							status: 'error',
						});
					});
			}

			if (this.state.activeDataCurrentDay) {
				tournamentCurrentDay = this.state?.activeDataCurrentDay?.schedule;
			}
		}

		/** Initial load - calculate new data path from path in props and state - store drawsData so
		 *  we don't have to fetch the same event again
		 *
		 *  make sure paths obj exists before accessing
		 *  */

		if (
			(this.initLoad &&
				this.props.tournament?.paths &&
				!isEmpty(this.props.tournament?.paths) &&
				tournamentCurrentDay &&
				!isNaN(parseInt(tournamentCurrentDay))) ||
			(!this.initLoad && curTournDayProps !== prevTournDayProps)
		) {
			this.initLoad = false;

			this.setState(
				{
					matchType: curMatchTypeProps ? getEventCode(curMatchTypeProps) : 'ms',
					day: curTournDayProps
						? curTournDayProps.toString()
						: tournamentCurrentDay
						? tournamentCurrentDay.toString()
						: this.defaultTournDay, // "8"
				},
				() => {
					this.props.getMatchInsightsAvailable(); // load commonData
					this.props.getSchedule(parseInt(this.state.day, 10)); // load schedule data
				}
			);
		}

		/** redux schedule data are available
		 *  filter by selected matchType and store only available matches
		 *
		 *  detect matchType and/or day change from props.match
		 */
		let curScheduleData = this.scheduleData;
		let prevScheduleData = prevProps.tournament?.data?.schedule?.data;

		if (
			(availableMatches && !this.dataLoaded) ||
			(((this.dataLoaded && curScheduleData !== prevScheduleData) || curMatchTypeProps !== prevMatchTypeProps) &&
				curScheduleData?.day?.toString() == this.state.day)
		) {
			this.dataLoaded = true;

			/** set court for sorting */
			if (this.props.scoringConfig?.courtNames) {
				this.props.scoringConfig.courtNames.map(i => {
					this.courtSort[i.long] = i.sb_code;
				});
			}

			this.setMatchCard(curScheduleData, availableMatches);
		}

		logger.log(
			'[InsightsIndex] componentDidUpdate - this:%o, state:%o, prevProps:%o, prevState:%o',
			this,
			this.state,
			prevProps,
			prevState
		);
	}

	/**
	 *
	 * @param {*} curScheduleData - scheduled match data per day
	 * @param {*} availableMatches - array of availale match insights match id
	 *
	 *  Find available match to display for the selected day and event
	 */
	setMatchCard = (curScheduleData, availableMatches) => {
		let matchCards = [];
		let searchData = [];
		let oppCards = [];

		if (curScheduleData?.courts?.length > 0) {
			curScheduleData.courts.forEach(court => {
				let _this = this;

				court.matches.forEach((match, i) => {
					let eventCode = match.eventCode.toLowerCase();
					let oppMatchType = _this.state.matchType == 'ms' ? 'ls' : 'ms';

					if (eventCode == _this.state.matchType) {
						matchCards.push(match);
					} else if (eventCode == oppMatchType) {
						oppCards.push(match);
					}
				});
			});
		}

		if (matchCards.length > 0) {
			matchCards = reject(matchCards, v => !includes(availableMatches, v.match_id));

			if (matchCards.length > 0) {
				/** add a primary sorting to push completed matches down and put upcoming matches on top */
				matchCards = matchCards.map(i => {
					return {
						...i,
						sort: this.courtSort[i.courtName],
						sortPrimary: i?.status?.toLowerCase() == 'completed' ? 1 : -1,
					};
				});
				matchCards = sortBy(matchCards, ['sortPrimary', 'sort']);
				// logger.log('[InsightsIndex] cards:%o', matchCards);

				matchCards.forEach(i => {
					if(i.team1?.[0].idA && i.team1?.[0].lastNameA !== "Loser") {
						searchData.push({
							firstName: i.team1?.[0].firstNameA,
							lastName: i.team1?.[0].lastNameA,
							id: i.team1?.[0].idA,
						});
					}
					if(i.team2?.[0].idA && i.team2?.[0].lastNameA !== "Loser") {
						searchData.push({
							firstName: i.team2?.[0].firstNameA,
							lastName: i.team2?.[0].lastNameA,
							id: i.team2?.[0].idA,
						});
					}
				});
			} else {
				let oppMatchType = this.state.matchType == 'ms' ? 'ls' : 'ms';
				oppCards = filter(this.state[oppMatchType], o => {
					return o.eventDay == intDay;
				});
			}
		}

		this.setState({
			matchCards,
			searchData,
			oppCards,
		});
	};

	setMatchType(matchType) {
		let measureArgs = [];
		let contextData = [{ item: matchType == 'ls' ? 'wta' : 'atp' }];

		if (values.webview) {
			measureArgs = [matchType == 'ls' ? 'wta' : 'atp'];
			contextData = [];
		}
		doMeasurement(`${values.matchInsightsTitle} Index`, 'Tab Selection', measureArgs, contextData);

		this.setState({ matchType, matchCards: null });

		const routeName = getEventName(matchType);

		/** update route */
		this.props.history.push(`${this.basePath}/${routeName}/day${this.state.day}.html`);
	}

	setDay(day) {
		// logger.log('[InsightsIndex] setDay:%o', day);
		let measureArgs = [];
		let contextData = [{ day: `Day ${day}` }];

		if (values.webview) {
			measureArgs = [`Day ${day}`];
			contextData = [];
		}

		doMeasurement(`${values.matchInsightsTitle} Index`, `Drop Down Selection`, measureArgs, contextData);

		this.setState({ open: '', day: null, matchCards: null }); // reset day to get the value from URL and re-render with new day state
		const routeName = getEventName(this.state.matchType);

		/** update route */
		this.props.history.push(`${this.basePath}/${routeName}/day${day}.html`);
	}

	onOpen(which) {
		this.setState({
			open: which,
		});
	}

	onEnter(value) {
		// logger.log('[SearchPage] onEnter - value:%o', value);
		let searchedPlayer = value;
		this.setState({ searchedPlayer });
		//get element page offset
		let el = document.getElementById(searchedPlayer.id);
		if (el !== null) {
			let coord = el.getBoundingClientRect();
			let offset = 106; //minus the height of the header
			window.scroll({
				top: coord.top - offset,
				behavior: 'smooth',
			});
		}
	}

	infoClick() {
		let { openModal } = this.state;
		openModal = openModal ? false : true;

		//  MeasurementUtils.dispatchMeasurementCall('infoClick', { open: openModal });
		let measureArgs = [];
		let contextData = [{ action: openModal ? 'Open' : 'Close' }, { item: 'Header' }];

		if (values.webview) {
			measureArgs = [openModal ? 'Open' : 'Close', 'Header'];
			contextData = [];
		}

		doMeasurement(`${values.matchInsightsTitle} Index`, 'openinfoClickModal', measureArgs, contextData);

		this.setState({ openModal });
	}

	/**
	 * renderPageContent:  renders the overall page structure.  calls other render type functions.
	 * @returns
	 */
	renderPageContent() {
		if (this.stubs || (this.state?.ms == 'error' || this.state?.ls == 'error')) {
			// logger.log('[InsightsIndex] render - stub');
			let message =
				this.state?.ms == 'error'
					? `${values.matchInsightsTitle} are not available at this time.`
					: this.props.stubs?.insightsindex?.text;
			return (
				<div className="content-main">
					<StubBox attributes={{ message: message, style: 'centerV' }} />
				</div>
			);
		} else {
			let modalHeaderText = values.matchInsightsTitle;
			let modalBodyText = `${values.matchInsightsTitle} are AI-generated previews that help fans get up to speed on every singles match at Wimbledon. Combining the power of hybrid cloud and AI, Match Insights analyse data from thousands of sources -- from recent statistics to expert commentary -- producing projections of each player's Likelihood to Win, an explanation of their Win Factors, and a measurement of player momentum called the ${values.powerIndexTitle}. Match Insights also include relevant quotes from the media and a natural language summary of key performance metrics.`;

			return (
				<div className="content-main insights-index">
					<div className="idx-header column-layout">
						{this.state.openModal && (
							<MIModal header={modalHeaderText} body={modalBodyText} closeModal={this.infoClick} />
						)}
						{this.renderHeaderTitle()}
						{this.getHeaderNav()}
					</div>
					<div className="column-layout insights-cards-container">
						<div className="insights-cards column-layout four-col">
							{!this.state.matchCards ? (
								<LoadingIndicator type={'white'} />
							) : this.state.matchCards.length > 0 ? (
								<>
									{this.state.matchCards.map(i => {
										/** when both players are assigned, render Match Card
										 *  this takes care of Lucky Loser
										 */
										if (i.team1?.[0]?.idA && i.team2?.[0]?.idA) {
											logger.log("matchinsights - match id:%o", i.match_id);
											return (
												<MatchCard
													match={i}
													total={this.state.matchCards.length}
													searchedPlayer={this.state.searchedPlayer}
													key={`-match-card-${i.match_id}`}
												/>
											);
										}
									})}
								</>
							) : (
								<div className="no-matches">
									{`${this.state.matchType == 'ms' ? "GENTLEMEN'S" : "LADIES'"} ${
										values.matchInsightsTitle
									} ARE NOT AVAILABLE FOR THIS DAY. ${
										this.state?.oppCards?.length > 0
											? `CHECK OUT THE ${
													this.state.matchType == 'ms' ? "LADIES'" : "GENTLEMEN'S"
											  } ${values.matchInsightsTitle} FOR ${this.scheduleData?.displayDate ? this.scheduleData?.displayDate : 'DAY ' + parseInt(this.state?.day) - parseInt(this.defaultTournDay)}.`
											: ``
									}`}
								</div>
							)}
						</div>
					</div>
				</div>
			);
		}
	}

	renderHeaderTitle = () => {
		return (
			<div className="idx-header-inner">
				<div className="headtext">
					{values.matchInsightsTitle}
					<div className="info">
						<MIInfoIcon onInfoClickFn={this.infoClick} />
					</div>
				</div>
				<hr className="spacerLine" />
				<div className="subtext">
					Select a match below to view the AI-generated preview for every singles match
				</div>
			</div>
		);
	};

	renderEventsSelect() {
		let { matchType } = this.state;

		if (matchType ? matchType : 'ms') {
			return (
				<>
					<button
						className={`button gentlemen ${matchType == 'ms' ? `selected` : ``}`}
						onClick={() => {
							matchType !== 'ms' ? this.setMatchType('ms') : null;
						}}>
						Gentlemen's
					</button>
					<span className="separator">|</span>
					<button
						className={`button ladies ${matchType == 'ls' ? `selected` : ``}`}
						onClick={() => {
							matchType !== 'ls' ? this.setMatchType('ls') : null;
						}}>
						Ladies'
					</button>
				</>
			);
		}
	}

	renderDaysSelect() {
		if (this.props.scheduleDays?.status == 'loaded' && !isEmpty(this.props.scheduleDays?.data?.eventDays)) {
			let eventDays = this.props.scheduleDays.data.eventDays;

			eventDays = filter(eventDays, o => {
				return o.tournDay >= this.defaultTournDay; // "8"
			});

			let dayNavOptions = eventDays.map(item => ({
				value: item.tournDay.toString(),
				type: item.quals ? 'quali' : 'tourn',
				textName: item.displayDay,
				dropdownName: item.message,
				title: item.message,
				sort: item.tournDay,
				tournEvents: item.events || '',
				disabled: !item.released ? true : false,
			}));

			let daysData = {
				options: [{ dropdownName: 'Select Day', textName: '', value: '', type: '', title: '', disabled: true }],
			};

			// add available days to base archive menu options
			dayNavOptions.forEach(item => {
				daysData.options.push({
					textName: item.textName,
					dropdownName: item.dropdownName,
					value: item.value,
					type: item.type,
					title: item.title,
					sort: item.sort,
					disabled: item.disabled,
				});
			});

			return (
				<DayNav
					name="days"
					attributes={daysData}
					selected={this.state.day}
					show={this.state.open == 'days'}
					onSelected={this.setDay}
					onOpen={this.onOpen}
					mode={`tourn`}
				/>
			);
		} else {
			return null;
		}
	}

	getHeaderNav() {
		return (
			<>
				<div className="match-type-select">{this.renderEventsSelect()}</div>
				<div className="daynav-wrapper">
					<div className="daynav scores-select-menu">{this.renderDaysSelect()}</div>
				</div>
				<div className="filters">
					<div className="players-container">
						<SearchBox staticData={this.state.searchData} onEnter={this.onEnter} />
					</div>
					<div className="favourites">{window.webviewPlatform ? null : <Favorites />}</div>
				</div>
			</>
		);
	}

	render() {
		let header_propsData = {
			headerType: 'scores',
			title: values.matchInsightsTitle,
			shortTitle: values.matchInsightsTitle,
			metaTitle: values.matchInsightsTitle,
			metaDescription: values.matchInsightsTitle,
			helper: 'Select a match below to view the AI-generated, head-to-head fact sheet',
			metaDate: '',
			metaPlayers: '',
		};

		/** schedule day specific data */
		this.scheduleData = this.props.tournament?.data?.schedule?.data;

		/** pre qualifying - the scheduleDays data are empty, check either scheduleDays or stubs */
		this.stubs = (this.props.stubs && this.props.stubs?.insightsindex?.stub === 'stub' || isEmpty(this.props.scheduleDays));

		logger.log('[InsightsIndex] render - window.webviewPlatform:%o', window.webviewPlatform);
		logger.log('[InsightsIndex] render - this:%o', this);
		
		if (this.props.status == 'loaded' && (this.props.scheduleDays?.data?.eventDays || this.stubs) && !window.webviewPlatform) {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					{this.renderPageContent(header_propsData)}
					<Footer />
				</Template>
			);
		} else if (
			this.props.status == 'loaded' &&
			(this.props.scheduleDays?.data?.eventDays || this.stubs) &&
			window.webviewPlatform
		) {
			return (
				<>
					<PageHeader attributes={{ isWebview: true }} />
					{this.renderPageContent(header_propsData)}
				</>
			);
		} else if (this.props.status !== 'loaded' || !this.props.scheduleDays?.data?.eventDays) {
			if (!window.webviewPlatform) {
				return (
					<>
						<Template className="scorespage">
							<Header attributes={header_propsData} />

							<PageHeader attributes={header_propsData} />

							<div className="content-main">
								<LoadingIndicator />
							</div>
							<Footer />
						</Template>
					</>
				);
			} else {
				return (
					<>
						<PageHeader attributes={{ isWebview: true }} />
						<div className="content-main">
							<LoadingIndicator />
						</div>
					</>
				);
			}
		} else {
			return null;
		}
	}
}

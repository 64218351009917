/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import cn from 'classnames';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ButtonLinkText
 *
 * For accessibility, this is created to handle onClick action without
 * the usual Wim button style
 *
 * For usual text link, use the WimLink component
 * -----------------------------------------------------------------------------
 *
 * @param state
 * @param state.style {Object} CSS styles to apply to the component.
 * @param className - available preset classNames --> disabled, bold, center
 *
 * @return {*}
 * @constructor
 */
const ButtonLinkText = ({
	onClick,
	className,
	style,
	children,
	disabled,
	ariaHidden,
	type,
	name,
	to,
	external,
	bypassAppLink,
	target,
	ariaLabel,
}) => {
	let classes = cn({
		'button-text-link': true,
		disabled: disabled,
	});

	let keyPress = e => {
		if (e.key === 'Enter') {
			onClick();
		}
	};

	if (onClick) {
		return (
			<div className={`${classes} ${className}`} disabled={disabled}>
				<button
					style={style}
					onClick={() => onClick()}
					onKeyPress={e => keyPress(e)}
					aria-hidden={ariaHidden}
					name={name}
					type={type ? type : 'button'}
					aria-label={ariaLabel || children}
					role={ariaHidden ? 'none' : 'button'}>
					{children}
				</button>
			</div>
		);
	} else if (to) {
		return (
			<div className={`${classes} ${className}`} disabled={disabled} aria-hidden={ariaHidden}>
				<WimLink to={to} external={external} target={target} bypassAppLink={bypassAppLink}>
					<div className={classes} style={style}>
						<div className="content">{children}</div>
						<div className="border"></div>
					</div>
				</WimLink>
			</div>
		);
	} else {
		return (
			<div className={`${classes} ${className}`} aria-hidden={ariaHidden} disabled={disabled}>
				<div aria-label={ariaLabel || children} style={style}>
					{children}
				</div>
			</div>
		);
	}
};

export default ButtonLinkText;

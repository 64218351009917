import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const mapStateToProps = (state, props) => {
	return {
		viewedContent: state['Controller']['content']['viewed'],
		contentNewExpireSec: state['Controller']['contentNewExpireSec'],
		...props.data,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	updateViewedContent: dataArray => dispatch(deps.actions.Controller.updateViewedContent(dataArray)),
});

class NewFlag extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newContent: false,
		};
	}

	componentDidMount() {}

	detectNewTimestamp(dataTimestamp) {
		let dateNow = moment().valueOf();
		logger.log('[NewFlag] detectNewTimestamp - dateNow:%o, expireSec:%o', (dateNow - dataTimestamp) * 1000, this.props.contentNewExpireSec);
		if ((dateNow - dataTimestamp) / 1000 <= this.props.contentNewExpireSec) {
			return true;
		}
		return false;
	}

	componentDidUpdate(prevProps, prevState) {
		//let newContent = this.checkState();
		//logger.log('[NewFlag] componentDidUpdate - dateNow:%o date:%o inList:%o', dateNow, this.props.data.displayDate, inViewedList);
		// if(prevState.newContent !== newContent) {
		//     this.setState(nextState => {
		//         return {...nextState, newContent};
		//     });
		// }
	}

	checkState() {
		//determine if id should be added to viewed content list
		//need id of content
		logger.log('[NewFlag] checkState - this.props.viewedContent:%o', this.props);
		let contentId = this.props?.data?.contentId;
		let viewedContentIdArry = this.props.viewedContent;
		let inViewedList = viewedContentIdArry.filter(d => d.contentId == contentId).length;
		let newTimestamp = this.detectNewTimestamp(this.props?.data?.displayDate);
		let newContent;
		if (inViewedList > 0 || !newTimestamp) {
			newContent = false;
		} else {
			newContent = true;
		}

		return newContent;
	}

	render() {
		let newContent = this.checkState();
		//logger.log('[NewFlag] render - newContent:%o', newContent);

		return (
			<span aria-hidden role="none" tabIndex={-1}>
				{newContent ? (
					<div className="new-tag" aria-hidden role="none" tabIndex={-1}>
						New
					</div>
				) : null}
			</span>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFlag);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import { Formik, Form } from 'formik';
import axios from 'axios';
import op from 'object-path';
import * as yup from 'yup';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import OrganisationInformationForm from './OrganisationInformationForm';
import ApplicantAccreditationList from './ApplicantAccreditationList';
import ApplicantAccreditationForm from './ApplicantAccreditationForm';
import ApplicantAccreditationFormContd from './ApplicantAccreditationFormContd';
import Declaration from './Declaration';
import Confirmation from './Confirmation';
import ThankYou from './ThankYou';
import union from 'lodash/union';
import indexOf from 'lodash/indexOf';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import { ContentPageSecureContext } from '../../pages/ContentPageSecure/context';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MediaForm
 * -----------------------------------------------------------------------------
 */

const mapDispatchToProps = (dispatch, props) => ({
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

const mapStateToProps = (state, props) => {
	return {
		DateConfig: state['Config']['dateConfig'],
		...props,
	};
};

const roleData = [
	{
		label: 'Tennis Writer',
		name: 'tennisWriter',
	},
	{
		label: 'News Reporter',
		name: 'newsReporter',
	},
	{
		label: 'Sports Reporter',
		name: 'sportsReporter',
	},
	{
		label: 'Feature Writer',
		name: 'featureWriter',
	},
	{
		label: 'Online Reporter (Sport)',
		name: 'onlineReporterSport',
	},
	{
		label: 'Online Reporter (News)',
		name: 'onlineReporterNews',
	},
	{
		label: 'Photographer',
		name: 'photographer',
	},
	{
		label: 'Media Support',
		name: 'mediaSupport',
	},
];

const eventData = [
	{
		label: 'Australian Open',
		name: 'australianOpen',
	},
	{
		label: 'Roland-Garros',
		name: 'rolandGarros',
	},
	{
		label: 'US Open',
		name: 'usOpen',
	},
	{
		label: 'WTA/ATP 1000',
		name: 'wtaATP1000',
	},
	{
		label: 'Davis Cup',
		name: 'davisCup',
	},
	{
		label: 'Billie Jean King Cup',
		name: 'billieJean',
	},
];

class MediaForm extends Component {
	constructor(props, context) {
		super(props);

		let formData = {
			store: {},
		};

		if (props.data && props.data.formData) {
			let parsedData = JSON.parse(props.data.formData);
			if (!this.checkYear(parsedData, context)) {
				formData = parsedData;
			}
		}

		this.initialValues = {};
		this.state = {
			...this.props,
			section: 'organisation', //organisation
			selectedApplicant: '',
			applicantList: op.get(formData, 'store.data.organisation.applicants', []),
			organisationList: [],
			organisationCount: 1,
			showSpinner: false,
			showErrorPopup: false,
			showRetryPopup: false,
			showSuccessPopup: false,
			formData: formData,
			updatedFormData: formData,
		};

		this.hasSelectedApplicant = false;

		this.handleSubmit = this.handleSubmit.bind(this);
		this.nextSection = this.nextSection.bind(this);
		this.prevSection = this.prevSection.bind(this);
		this.addApplicant = this.addApplicant.bind(this);
		this.editApplicant = this.editApplicant.bind(this);
		this.changeOrganisationCount = this.changeOrganisationCount.bind(this);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	checkYear(data, context) {
		let new_year = false;

		if (context.mediaForm == data.store.data.year) {
			new_year = false;
		} else {
			new_year = true;
		}

		return new_year;
	}

	showRadioButtons(values) {
		if (
			values.tennisWriter ||
			values.newsReporter ||
			values.sportsReporter ||
			values.featureWriter ||
			values.onlineReporterSport ||
			values.onlineReporterNews
		) {
			return true;
		}
		return false;
	}

	timeMessageDisplay() {
		setTimeout(() => {
			this.setState(prevState => {
				return {
					...prevState,
					showErrorPopup: false,
					showRetryPopup: false,
					showSuccessPopup: false,
				};
			});
		}, 3000);
	}

	handleSubmit(values, actions) {
		// logger.log('[MediaForm] handleSubmit - state:%o', this.state);
		//handle scroll
		// window.scroll({
		// 	top: 0,
		// 	behavior: 'smooth',
		// });

		window.scrollTo(0, 0);

		this.setState(prevState => {
			return {
				...prevState,
				showSpinner: true,
			};
		});

		let url = '';
		let displaySuccessMessage = false;
		if (this.state.section == 'declaration') {
			url = this.state.data.dataUrl + '/submit/';
			displaySuccessMessage = true;
		} else {
			url = this.state.data.dataUrl + '/save/';
		}

		/**
		 * construct json object in confirmed format and submit
		 * include section submitting so orch layer can construct proper email
		 */
		// logger.log('[MediaForm] handleSubmit - the previous data: %o', this.state.formData.store.data);
		let mediaFormData = this.buildJson(values);
		logger.log('[MediaForm] handleSubmit - the updated data: %o', mediaFormData);

		let newSelectedApplicant = '';

		if (mediaFormData.organisation.applicants.length > 0) {
			newSelectedApplicant =
				this.state.selectedApplicant !== ''
					? this.state.selectedApplicant
					: mediaFormData.organisation.applicants.length - 1;
		}

		if (newSelectedApplicant < 0) {
			newSelectedApplicant = '';
		}

		let _this = this;

		//process form submission here
		//done submitting, set submitting to false
		actions.setSubmitting(false);

		this.props
			.getJWT()
			.then(token => {
				// logger.log('[MediaForm] handleSubmit - token:%o', token.jwt.id_token);
				let oid = op.get(this.state, 'formData.store.oid', false) ? this.state.formData.store.oid : 'auto';
				// logger.log('[MediaForm] handleSubmit - oid:%o', oid);

				let request = axios({
					method: 'put',
					url: url,
					data: {
						jwtToken: token.jwt.id_token,
						store: {
							oid: oid,
							type: 'media',
							data: mediaFormData,
						},
					},
					headers: { 'Content-type': 'application/json' },
				})
					.then(res => {
						logger.log('[MediaForm] handleSubmit response:%o', res);
						if (res.status == 200 && res.data.store) {
							this.setState(prevState => {
								let formData = { ...prevState.formData };
								formData.store.oid = res.data.store.oid;
								return {
									...prevState,
									showSpinner: false,
									showErrorPopup: false,
									showRetryPopup: false,
									showSuccessPopup: displaySuccessMessage,
									selectedApplicant: newSelectedApplicant,
									applicantList: mediaFormData.organisation.applicants,
									updatedFormData: {
										store: {
											...this.state.updatedFormData.store,
											data: mediaFormData,
										},
									},
									formData,
								};
							});
							_this.hasSelectedApplicant = newSelectedApplicant !== '' ? true : false;
							this.timeMessageDisplay();

							if (this.state.section !== 'thankyou') {
								this.nextSection();
							}
						} else {
							logger.error('[MediaForm] handleSubmit error - status is not 200');
							this.setState(prevState => {
								return {
									...prevState,
									showSpinner: false,
									showErrorPopup: false,
									showRetryPopup: true,
									showSuccessPopup: false,
								};
							});
							this.timeMessageDisplay();
						}
					})
					.catch(error => {
						logger.error('[MediaForm] handleSubmit error:%o', error);
						this.setState(prevState => {
							return {
								...prevState,
								showSpinner: false,
								showErrorPopup: true,
								showRetryPopup: false,
								showSuccessPopup: false,
							};
						});
						this.timeMessageDisplay();
					});
			})
			.catch(error => {
				logger.error('[MediaForm] handleSubmit error:%o', error);
				this.setState(prevState => {
					return {
						...prevState,
						showSpinner: false,
						showErrorPopup: true,
						showRetryPopup: false,
						showSuccessPopup: false,
					};
				});
				this.timeMessageDisplay();
			});

		// if(submitSuccess && this.state.section !== 'thankyou') {
		// 	this.nextSection();
		// }
	}

	getOrganisationList() {
		let request = axios({
			method: 'get',
			url: '/en_GB/json/man/media_form/companies.json',
			headers: { 'Content-type': 'application/json' },
		})
			.then(res => {
				// logger.log('[MediaForm] getOrganisationList res:%o', res);

				this.setState({
					organisationList: res.data.companies,
				});
			})
			.catch(error => {
				logger.error('[MediaForm] getOrganisationList error:%o', error);
			});
	}

	/**
	 * set the applicant to edit and set the section
	 * to go to the accrediation form for editing an
	 * applicant
	 *
	 * @param {*} which
	 */
	editApplicant(which) {
		// logger.log('[MediaForm] editApplicant - which:%o', which);
		let updatedOrgCount = '';
		this.hasSelectedApplicant = true;

		if (this.state.organisationCount !== this.state.applicantList[which].otherOrganisations.length) {
			updatedOrgCount = this.state.applicantList[which].otherOrganisations.length;
		}

		if (updatedOrgCount === 0) {
			updatedOrgCount = 1;
		}

		this.setState({
			selectedApplicant: which,
			section: 'accreditationform',
			organisationCount: updatedOrgCount !== '' ? updatedOrgCount : this.state.organisationCount,
		});
	}

	/**
	 * set the section to go to the accreditation
	 * form to add a new applicant
	 */
	addApplicant() {
		this.hasSelectedApplicant = false;

		this.setState({
			section: 'accreditationform',
			selectedApplicant: '',
			organisationCount: 1,
		});
	}

	/**
	 * go to the next section
	 */
	nextSection() {
		let nextsection = '';

		if (this.state.section == 'organisation') {
			nextsection = 'accreditationlist';
		} else if (this.state.section == 'accreditationlist') {
			nextsection = 'accreditationform';
		} else if (this.state.section == 'accreditationform') {
			nextsection = 'accreditationformcontd';
		} else if (this.state.section == 'accreditationformcontd') {
			nextsection = 'confirmation';
		} else if (this.state.section == 'confirmation') {
			nextsection = 'declaration';
		} else if (this.state.section == 'declaration') {
			nextsection = 'thankyou';
		}

		this.setState({
			section: nextsection,
		});
	}

	/**
	 * go to the previous section
	 */
	prevSection() {
		let prevsection = '';

		if (this.state.section == 'accreditationlist') {
			prevsection = 'organisation';
		} else if (this.state.section == 'accreditationform') {
			prevsection = 'accreditationlist';
		} else if (this.state.section == 'accreditationformcontd') {
			prevsection = 'accreditationform';
		} else if (this.state.section == 'confirmation') {
			prevsection = 'accreditationformcontd';
		} else if (this.state.section == 'declaration') {
			prevsection = 'confirmation';
		}

		this.setState({
			section: prevsection,
		});
	}

	checkRoleForOrgType(which, orgType) {
		let doesRoleDisplayForOrgType = false;

		// logger.log('[MediaForm] checkRoleForOrgType - which:%o, orgType:%o', which, orgType);

		switch (which) {
			case 'tennisWriter':
				if (indexOf(['regionalNewspaper', 'photoAgency', 'photoAgency'], orgType) === -1) {
					doesRoleDisplayForOrgType = true;
				}
				break;
			case 'newsReporter':
				if (indexOf(['magazineTennis', 'magazineOther', 'photoAgency', 'website'], orgType) === -1) {
					doesRoleDisplayForOrgType = true;
				}
				break;
			case 'sportsReporter':
				if (indexOf(['magazineTennis', 'photoAgency', 'website'], orgType) === -1) {
					doesRoleDisplayForOrgType = true;
				}
				break;
			case 'featureWriter':
				if (indexOf(['photoAgency', 'website'], orgType) === -1) {
					doesRoleDisplayForOrgType = true;
				}
				break;
			case 'onlineReporterSport':
				if (indexOf(['magazineTennis', 'magazineOther', 'photoAgency'], orgType) === -1) {
					doesRoleDisplayForOrgType = true;
				}
				break;
			case 'onlineReporterNews':
				if (indexOf(['magazineTennis', 'magazineOther', 'photoAgency'], orgType) === -1) {
					doesRoleDisplayForOrgType = true;
				}
				break;
			case 'photographer':
				// if (orgType !== 'magazineOther') {
				doesRoleDisplayForOrgType = true;
				// }
				break;
			case 'mediaSupport':
				if (indexOf(['regionalNewspaper', 'magazineTennis', 'magazineOther', 'website'], orgType) === -1) {
					doesRoleDisplayForOrgType = true;
				}
				break;
			default:
				break;
		}

		// logger.log('[MediaForm] checkRoleForOrgType - doesRoleDisplayForOrgType:%o', doesRoleDisplayForOrgType);

		return doesRoleDisplayForOrgType;
	}

	changeOrganisationCount(count) {
		// logger.log('[MediaForm] changeOrganisationCount to ',count);
		this.setState({
			organisationCount: count,
		});
	}

	/****************************************
	 * functions used to build json object
	 ***************************************/

	/**
	 * get the name of an organisation from
	 * the organisation list based on the value
	 *
	 * @param {*} value
	 */

	getOrganisationSelectName(value) {
		let name;

		this.state.organisationList.map((o, i) => {
			if (o.value == value) {
				name = o.label;
			}
		});

		return name;
	}

	/**
	 * create the array for the desk values
	 * for the json data
	 *
	 * @param {*} values
	 */
	getDeskArray(values) {
		let deskArray = [];

		if (values.writingRoomDesk == true) {
			deskArray.push('Press Writing Room');
		}
		if (values.photoWorkroomDesk == true) {
			deskArray.push('Photo Workroom');
		}

		return deskArray;
	}

	getChampionshipDays(applicant, key) {
		return op.get(applicant, 'attendChampionships', false)
			? applicant.attendChampionships.indexOf(key) !== -1
				? true
				: false
			: false;
	}

	formatChampionshipDays(values) {
		let attendanceDays = [];
		if (values.attendChampionshipsQualifying) {
			attendanceDays.push('Qualifying Tournament');
		}
		if (values.attendChampionshipsEntire) {
			attendanceDays.push('Entire Championships');
		}
		if (values.attendChampionshipsWeek1) {
			attendanceDays.push('Full Week 1');
		}
		if (values.attendChampionshipsSingleDays) {
			attendanceDays.push('Single day or days');
		}
		if (values.attendChampionshipsRemote) {
			attendanceDays.push('Remote coverage only');
		}
		return attendanceDays;
	}
	/**
	 * Loop through values from form, find role values
	 * based on roleData const and create array
	 *
	 * @param {*} values
	 */
	getRolesJsonValue(values) {
		let rolesArray = [];

		Object.keys(values).map((value, i) => {
			roleData.map((role, j) => {
				if (value == role.name && values[value] == true) {
					if (this.checkRoleForOrgType(role.name, values.type)) {
						rolesArray.push(role.label);
					}
				}
			});
		});

		// logger.log('[MediaForm] getRolesData - rolesArray:%o ', rolesArray);
		return rolesArray;
	}

	/**
	 * Loop through values from form, find event values
	 * based on eventData const and create array
	 *
	 * @param {*} values
	 */
	getEventsJsonValue(values) {
		let eventsArray = [];

		Object.keys(values).map((value, i) => {
			eventData.map((event, j) => {
				if (value == event.name && values[value] == true) {
					eventsArray.push(event.label);
				}
			});
		});

		// logger.log('[MediaForm] getEventsData - eventsArray:%o ', eventsArray);
		return eventsArray;
	}

	getOrganisationJson(values) {
		logger.log('[MediaForm] getOrganisationData values:%o', values);
		let organisation;
		let organisationArray = [];
		let orgNum;

		if (this.state.section == 'accreditationform') {
			if (this.state.selectedApplicant !== '') {
				if (
					this.state.updatedFormData.store.data.organisation.applicants[this.state.selectedApplicant]
						.otherOrganisations
				) {
					organisationArray = this.state.updatedFormData.store.data.organisation.applicants[
						this.state.selectedApplicant
					].otherOrganisations;
				}
			}
		} else if (this.state.section == 'accreditationformcontd') {
			Object.keys(values).map((value, i) => {
				if (value.indexOf('organisationName') > -1) {
					orgNum = value.substring(16);

					if (values[value] !== '' || values[`organisationURL${orgNum}`] !== '') {
						organisation = {
							name: values[value],
							url: values[`organisationURL${orgNum}`],
						};

						organisationArray.push(organisation);
					}
				}
			});
		}

		return organisationArray;
	}

	getApplicantsJson(values) {
		let applicant;
		let updatedApplicants = union([], this.state.applicantList);
		//get current applicant

		if (this.state.section == 'accreditationform' || this.state.section == 'accreditationformcontd') {
			if (this.hasSelectedApplicant) {
				updatedApplicants.splice(this.state.selectedApplicant, 1);
			}

			applicant = {
				applicantOrganisationName: op.get(values, 'applicantOrganisationName', false)
					? this.getOrganisationSelectName(values.applicantOrganisationName)
					: op.get(this.state, 'updatedFormData.store.data.organisation.name', ''),
				applicantFirstName: op.get(values, 'applicantFirstName', ''),
				applicantLastName: op.get(values, 'applicantLastName', ''),
				applicantEmail: op.get(values, 'applicantEmail', ''),
				applicantMobilePhone: op.get(values, 'applicantMobilePhone', ''),
				attendanceOptions: op.get(values, 'attendanceOptions', null),
				accessibilityNeeds: op.get(values, 'accessibilityNeeds', ''),
				roles: this.getRolesJsonValue(values), //["Tennis Writer", "News Reporter", "Photographer"],
				events: this.getEventsJsonValue(values), //["Australian Open", "US Open"],
				memberITWA:
					values.tennisWriter || values.sportsReporter || values.featureWriter || values.onlineReporterSport
						? op.get(values, 'memberITWA', 'No')
						: 'No',
				memberITPA: values.photographer ? op.get(values, 'memberITPA', 'No') : 'No',
				radioBroadcast: this.showRadioButtons(values) ? op.get(values, 'radioBroadcast', 'No') : 'No',
				radioDetails:
					this.showRadioButtons(values) && values.radioBroadcast === 'Yes'
						? op.get(values, 'radioDetails', '')
						: '',
				podcast: this.showRadioButtons(values) ? op.get(values, 'podcast', 'No') : 'No',
				podcastDetails:
					this.showRadioButtons(values) && values.podcast === 'Yes'
						? op.get(values, 'podcastDetails', '')
						: '',
				otherOrganisations: this.getOrganisationJson(values),
				pictureCopyright: values.photographer ? op.get(values, 'pictureCopyright', '') : '',
				playerInterest: values.type === 'regionalNewspaper' ? op.get(values, 'playerInterest', '') : '',
				attendChampionships: this.formatChampionshipDays(values),
				attendDays: op.get(values, 'attendDays', ''),
			};

			// logger.log('[MediaForm] getApplicantsJson state:%o', this.state);

			if (this.state.applicantList.length > 1 && this.hasSelectedApplicant) {
				// logger.log('[MediaForm] getApplicantsJson replacing applicant in array');
				updatedApplicants.splice(this.state.selectedApplicant, 0, applicant);
			} else {
				// logger.log('[MediaForm] getApplicantsJson adding applicant to end of array');
				updatedApplicants.push(applicant);
			}

			// logger.log('[MediaForm] getApplicantsJson updatedApplicants:%o', updatedApplicants);

			return updatedApplicants;
		} else {
			return this.state.applicantList;
		}
	}

	getJsonValue(which, values) {
		let value = '';

		switch (which) {
			case 'circulation':
				if (
					indexOf(
						['nationalNewspaper', 'regionalNewspaper', 'magazineTennis', 'magazineOther'],
						values.type
					) > -1
				) {
					value = op.get(values, 'circulation', false)
						? values.circulation
						: op.get(this.state, 'updatedFormData.store.data.organisation.circulation', '');
				} else {
					value = '';
				}
				break;
			case 'frequency':
				if (
					indexOf(
						['nationalNewspaper', 'regionalNewspaper', 'magazineTennis', 'magazineOther'],
						values.type
					) > -1
				) {
					value = op.get(values, 'frequency', false)
						? values.frequency
						: op.get(this.state, 'updatedFormData.store.data.organisation.frequency', '');
				} else {
					value = '';
				}
				break;
			case 'photoAgency':
				if (values.type === 'photoAgency') {
					value = op.get(values, 'photoAgency', false)
						? values.photoAgency
						: op.get(this.state, 'updatedFormData.store.data.organisation.photoAgency', '');
				} else {
					value = '';
				}
				break;
			case 'newsAgency':
				if (values.type === 'newsAgency') {
					value = op.get(values, 'newsAgency', false)
						? values.newsAgency
						: op.get(this.state, 'updatedFormData.store.data.organisation.newsAgency', '');
				} else {
					value = '';
				}
				break;
			case 'website':
				if (values.type === 'website') {
					value = op.get(values, 'website', false)
						? values.website
						: op.get(this.state, 'updatedFormData.store.data.organisation.website', '');
				} else {
					value = '';
				}
				break;
			case 'officialSupplier':
				if (values.type === 'officialSupplier') {
					value = op.get(values, 'officialSupplier', false)
						? values.officialSupplier
						: op.get(this.state, 'updatedFormData.store.data.organisation.officialSupplier', '');
				} else {
					value = '';
				}
				break;
			case 'tennisFamily':
				if (values.type === 'tennisFamily') {
					value = op.get(values, 'tennisFamily', false)
						? values.tennisFamily
						: op.get(this.state, 'updatedFormData.store.data.organisation.tennisFamily', '');
				} else {
					value = '';
				}
				break;
			default:
				break;
		}

		return value;
	}

	buildJson(values) {
		logger.log('[MediaForm] buildJson - values:%o', values);
		// logger.log('[MediaForm] buildJson - props:%o', this.props);

		let mediaFormData = {
			year: op.get(this.props, 'DateConfig.media.year', ''),
			firstName: op.get(values, 'firstName', false)
				? values.firstName
				: op.get(this.state, 'updatedFormData.store.data.firstName', ''),
			lastName: op.get(values, 'lastName', false)
				? values.lastName
				: op.get(this.state, 'updatedFormData.store.data.lastName', ''),
			email: op.get(values, 'email', false)
				? values.email
				: op.get(this.state, 'updatedFormData.store.data.email', ''),
			position: op.get(values, 'position', false)
				? values.position
				: op.get(this.state, 'updatedFormData.store.data.position', ''),
			address1: op.get(values, 'address1', false)
				? values.address1
				: op.get(this.state, 'updatedFormData.store.data.address1', ''),
			address2: op.get(values, 'address2', false)
				? values.address2
				: op.get(this.state, 'updatedFormData.store.data.address2', ''),
			city: op.get(values, 'city', false)
				? values.city
				: op.get(this.state, 'updatedFormData.store.data.city', ''),
			state: op.get(values, 'state', false)
				? values.state
				: op.get(this.state, 'updatedFormData.store.data.state', ''),
			postcode: op.get(values, 'postcode', false)
				? values.postcode
				: op.get(this.state, 'updatedFormData.store.data.postcode', ''),
			country: op.get(values, 'country', false)
				? values.country
				: op.get(this.state, 'updatedFormData.store.data.country', ''),
			organisation: {
				name: op.get(values, 'name', false)
					? this.getOrganisationSelectName(values.name)
					: op.get(this.state, 'updatedFormData.store.data.organisation.name', ''),
				type: op.get(values, 'type', false)
					? values.type
					: op.get(this.state, 'updatedFormData.store.data.organisation.type', ''),
				circulation: this.getJsonValue('circulation', values),
				frequency: this.getJsonValue('frequency', values),
				photoAgency: this.getJsonValue('photoAgency', values),
				newsAgency: this.getJsonValue('newsAgency', values),
				website: this.getJsonValue('website', values),
				officialSupplier: this.getJsonValue('officialSupplier', values),
				tennisFamily: this.getJsonValue('tennisFamily', values),
				applicants: this.getApplicantsJson(values),
				acceptDeclaration: op.get(values, 'acceptDeclaration', false)
					? values.acceptDeclaration
					: op.get(this.state, 'updatedFormData.store.data.organisation.acceptDeclaration', false),
			},
		};

		// logger.log('[MediaForm] buildJson - mediaFormData:%o', mediaFormData);

		return mediaFormData;
	}
	/** end functions to build json object **/

	/****************************************
	 * functions used to set initial values
	 ***************************************/

	/**
	 *	get the value of an organisation from
	 * the organisation list based on the name
	 *
	 * @param {*} name
	 */
	getOrganisationSelectValue(name) {
		let orgValue;

		if (name == '') {
			orgValue = '';
		} else {
			this.state.organisationList.map((o, i) => {
				if (o.label == name) {
					orgValue = o.value;
				}
			});
		}

		return orgValue;
	}

	getApplicantOrganisationValue() {
		let appOrgValue;
		let currentSelectedApplicant;

		if (this.hasSelectedApplicant) {
			currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];

			if (op.get(currentSelectedApplicant, 'applicantOrganisationName', false)) {
				appOrgValue = this.getOrganisationSelectValue(currentSelectedApplicant.applicantOrganisationName);
			} else {
				appOrgValue = this.getOrganisationSelectValue(
					op.get(this.state, 'updatedFormData.store.data.organisation.name', '')
				);
			}
		} else {
			appOrgValue = this.getOrganisationSelectValue(
				op.get(this.state, 'updatedFormData.store.data.organisation.name', '')
			);
		}

		return appOrgValue;
	}

	getITWAValue() {
		let initialValue = 'No';

		if (this.hasSelectedApplicant) {
			let currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];

			if (currentSelectedApplicant.roles) {
				if (
					currentSelectedApplicant.roles.indexOf('Tennis Writer') > -1 ||
					currentSelectedApplicant.roles.indexOf('Sports Reporter') > -1 ||
					currentSelectedApplicant.roles.indexOf('Feature Writer') > -1 ||
					currentSelectedApplicant.roles.indexOf('Online Reporter (Sport)') > -1
				) {
					initialValue = currentSelectedApplicant.memberITWA;
				}
			}
		}

		return initialValue;
	}

	getITPAValue() {
		let initialValue = 'No';

		if (this.hasSelectedApplicant) {
			let currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];

			if (currentSelectedApplicant.roles) {
				if (currentSelectedApplicant.roles.indexOf('Photographer') > -1) {
					initialValue = currentSelectedApplicant.memberITPA;
				}
			}
		}

		return initialValue;
	}

	/**
	 * get initial value for role
	 *
	 * @param {*} which
	 */
	getRoleValue(which) {
		let roleValue = false;

		if (this.hasSelectedApplicant) {
			let currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];

			if (currentSelectedApplicant.roles) {
				if (currentSelectedApplicant.roles.indexOf(which) > -1) {
					let whichValue;

					for (var i = 0; i < roleData.length; i++) {
						if (roleData[i].label === which) {
							whichValue = roleData[i].name;
						}
					}

					if (this.checkRoleForOrgType(whichValue, this.state.updatedFormData.store.data.organisation.type)) {
						roleValue = true;
					}
				}
			}
		}

		return roleValue;
	}

	/**
	 * get initial value for event
	 *
	 * @param {*} which
	 */
	getEventValue(which) {
		let eventValue = false;

		if (this.hasSelectedApplicant) {
			let currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];

			if (currentSelectedApplicant.events) {
				if (currentSelectedApplicant.events.indexOf(which) > -1) {
					eventValue = true;
				}
			}
		}

		return eventValue;
	}

	allowedRoles(roles) {
		let allowedRoles = [
			'Tennis Writer',
			'News Reporter',
			'Sports Reporter',
			'Feature Writer',
			'Online Reporter (Sport)',
			'Online Reporter (News)',
		];
		for (let i = 0; i < allowedRoles.length; i++) {
			let currentRole = allowedRoles[i];
			if (roles.indexOf(currentRole) !== -1) {
				return true;
			}
		}
		return false;
	}

	getInitialValue(which) {
		let initialValue = 'No';

		if (this.hasSelectedApplicant) {
			let currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];

			if (
				(currentSelectedApplicant.roles.indexOf('Photographer') === -1 &&
					currentSelectedApplicant.roles.indexOf('Media Support') === -1) ||
				this.allowedRoles(currentSelectedApplicant.roles)
			) {
				initialValue = currentSelectedApplicant[which];
			}
		}

		return initialValue;
	}

	getInitialDetailsValue(which) {
		let initialValue = '';

		if (this.hasSelectedApplicant) {
			let currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];

			if (
				(currentSelectedApplicant.roles.indexOf('Photographer') === -1 &&
					currentSelectedApplicant.roles.indexOf('Media Support') === -1) ||
				this.allowedRoles(currentSelectedApplicant.roles)
			) {
				switch (which) {
					case 'radioDetails':
						if (currentSelectedApplicant.radioBroadcast === 'Yes') {
							initialValue = currentSelectedApplicant[which];
						}
						break;
					case 'podcastDetails':
						if (currentSelectedApplicant.podcast === 'Yes') {
							initialValue = currentSelectedApplicant[which];
						}
						break;
					default:
				}
			}
		}

		return initialValue;
	}

	buildInitialValues(currentSelectedApplicant) {
		let initialFormValues;

		initialFormValues = {
			name: this.getOrganisationSelectValue(
				op.get(this.state, 'updatedFormData.store.data.organisation.name', '')
			),
			firstName: op.get(this.state, 'updatedFormData.store.data.firstName', ''),
			lastName: op.get(this.state, 'updatedFormData.store.data.lastName', ''),
			email: op.get(this.state, 'updatedFormData.store.data.email', ''),
			position: op.get(this.state, 'updatedFormData.store.data.position', ''),
			address1: op.get(this.state, 'updatedFormData.store.data.address1', ''),
			address2: op.get(this.state, 'updatedFormData.store.data.address2', ''),
			city: op.get(this.state, 'updatedFormData.store.data.city', ''),
			state: op.get(this.state, 'updatedFormData.store.data.state', ''),
			postcode: op.get(this.state, 'updatedFormData.store.data.postcode', ''),
			country: op.get(this.state, 'updatedFormData.store.data.country', ''),
			type: op.get(this.state, 'updatedFormData.store.data.organisation.type', ''),
			circulation: op.get(this.state, 'updatedFormData.store.data.organisation.circulation', ''),
			frequency: op.get(this.state, 'updatedFormData.store.data.organisation.frequency', ''),
			photoAgency: op.get(this.state, 'updatedFormData.store.data.organisation.photoAgency', ''),
			newsAgency: op.get(this.state, 'updatedFormData.store.data.organisation.newsAgency', ''),
			website: op.get(this.state, 'updatedFormData.store.data.organisation.website', ''),
			officialSupplier: op.get(this.state, 'updatedFormData.store.data.organisation.officialSupplier', ''),
			tennisFamily: op.get(this.state, 'updatedFormData.store.data.organisation.tennisFamily', ''),
			writingRoomDesk:
				op.get(this.state, 'updatedFormData.store.data.organisation.desks') &&
				this.state.updatedFormData.store.data.organisation.desks.indexOf('Press Writing Room') > -1
					? true
					: false,
			photoWorkroomDesk:
				op.get(this.state, 'updatedFormData.store.data.organisation.desks') &&
				this.state.updatedFormData.store.data.organisation.desks.indexOf('Photo Workroom') > -1
					? true
					: false,

			//applicant accreditation form
			applicantOrganisationName: this.getApplicantOrganisationValue(),
			applicantFirstName: this.hasSelectedApplicant ? currentSelectedApplicant.applicantFirstName : '',
			applicantLastName: this.hasSelectedApplicant ? currentSelectedApplicant.applicantLastName : '',
			applicantEmail: this.hasSelectedApplicant ? currentSelectedApplicant.applicantEmail : '',
			applicantMobilePhone: this.hasSelectedApplicant ? currentSelectedApplicant.applicantMobilePhone : '',
			accessibilityNeeds: this.hasSelectedApplicant ? currentSelectedApplicant.accessibilityNeeds : '',
			attendanceOptions: this.hasSelectedApplicant ? currentSelectedApplicant.attendanceOptions : null,
			tennisWriter: this.getRoleValue('Tennis Writer'),
			newsReporter: this.getRoleValue('News Reporter'),
			sportsReporter: this.getRoleValue('Sports Reporter'),
			featureWriter: this.getRoleValue('Feature Writer'),
			onlineReporterSport: this.getRoleValue('Online Reporter (Sport)'),
			onlineReporterNews: this.getRoleValue('Online Reporter (News)'),
			photographer: this.getRoleValue('Photographer'),
			mediaSupport: this.getRoleValue('Media Support'),
			australianOpen: this.getEventValue('Australian Open'),
			rolandGarros: this.getEventValue('Roland-Garros'),
			usOpen: this.getEventValue('US Open'),
			wtaATP1000: this.getEventValue('WTA/ATP 1000'),
			davisCup: this.getEventValue('Davis Cup'),
			billieJean: this.getEventValue('Billie Jean King Cup'),
			memberITWA: this.getITWAValue(),
			memberITPA: this.getITPAValue(),
			radioBroadcast: this.getInitialValue('radioBroadcast'),
			radioDetails: this.getInitialDetailsValue('radioDetails'),
			podcast: this.getInitialValue('podcast'),
			podcastDetails: this.getInitialDetailsValue('podcastDetails'),

			// applicant accreditation form contd
			organisationName0: '',
			organisationURL0: '',
			pictureCopyright: this.hasSelectedApplicant
				? currentSelectedApplicant.roles.indexOf('Photographer') > -1
					? currentSelectedApplicant.pictureCopyright
					: ''
				: '',
			playerInterest:
				this.hasSelectedApplicant &&
				op.get(this.state, 'updatedFormData.store.data.organisation.type', '') === 'regionalNewspaper'
					? currentSelectedApplicant.playerInterest
					: '',
			// attendChampionships: this.hasSelectedApplicant ? currentSelectedApplicant.attendChampionships : 'entire',
			// attendChampionships: this.getChampionshipDays(currentSelectedApplicant),
			attendChampionshipsQualifying: this.hasSelectedApplicant
				? this.getChampionshipDays(currentSelectedApplicant, 'Qualifying Tournament')
				: false,
			attendChampionshipsEntire: this.hasSelectedApplicant
				? this.getChampionshipDays(currentSelectedApplicant, 'Entire Championships')
				: false,
			attendChampionshipsWeek1: this.hasSelectedApplicant
				? this.getChampionshipDays(currentSelectedApplicant, 'Full Week 1')
				: false,
			attendChampionshipsSingleDays: this.hasSelectedApplicant
				? this.getChampionshipDays(currentSelectedApplicant, 'Single day or days')
				: false,
			attendChampionshipsRemote: this.hasSelectedApplicant
				? this.getChampionshipDays(currentSelectedApplicant, 'Remote coverage only')
				: false,
			attendDays:
				this.hasSelectedApplicant && this.getChampionshipDays(currentSelectedApplicant, 'Single day or days')
					? currentSelectedApplicant.attendDays
					: '',

			// accept declaration
			acceptDeclaration: false,
		};

		if (this.hasSelectedApplicant) {
			if (currentSelectedApplicant.otherOrganisations) {
				currentSelectedApplicant.otherOrganisations.map((org, i) => {
					let fieldName = `organisationName${i}`;
					let fieldURL = `organisationURL${i}`;

					initialFormValues[fieldName] = org.name ? org.name : '';
					initialFormValues[fieldURL] = org.url ? org.url : '';
				});
			}
		}

		// logger.log('[MediaForm] buildInitialValues - initialFormValues:%o', initialFormValues);

		return initialFormValues;
	}

	/** end functions to set initial values **/

	getForm(formProps) {
		return (
			<div style={{ position: 'relative' }}>
				<div className={`popup-container error ${this.state.showRetryPopup ? 'show' : ''}`}>
					<div className="msg-container">
						<div className="error-msg msg">There was an error submitting your form. Please try again.</div>
					</div>
				</div>
				<div className={`popup-container error ${this.state.showErrorPopup ? 'show' : ''}`}>
					<div className="msg-container">
						<div className="error-msg msg">There was an error submitting your form</div>
					</div>
				</div>
				<div className={`popup-container success ${this.state.showSuccessPopup ? 'show' : ''}`}>
					<div className="msg-container">
						<div className="success-msg msg">Success</div>
					</div>
				</div>
				<Form className="media-form-content" onSubmit={formProps.handleSubmit}>
					<div className={`form-component${this.state.section == 'organisation' ? ' visible' : ' hidden'}`}>
						<ErrorBoundary message="Error in OrganisationInformationForm">
							<OrganisationInformationForm
								formProps={formProps}
								organisationList={this.state.organisationList}
							/>
						</ErrorBoundary>
					</div>
					<div
						className={`form-component${
							this.state.section == 'accreditationlist' ? ' visible' : ' hidden'
						}`}>
						<ErrorBoundary message="Error in ApplicantAccreditationList">
							<ApplicantAccreditationList
								applicants={this.state.applicantList}
								editApplicant={this.editApplicant}
								goBack={this.prevSection}
								addApplicant={this.addApplicant}
							/>
						</ErrorBoundary>
					</div>
					<div
						className={`form-component${
							this.state.section == 'accreditationform' ? ' visible' : ' hidden'
						}`}>
						<ErrorBoundary message="Error in ApplicantAccreditationForm">
							<ApplicantAccreditationForm
								formProps={formProps}
								orgType={
									op.get(this.state, 'updatedFormData.store.data.organisation.type', false)
										? this.state.updatedFormData.store.data.organisation.type
										: ''
								}
								goBack={this.prevSection}
								organisationList={this.state.organisationList}
							/>
						</ErrorBoundary>
					</div>
					<div
						className={`form-component${
							this.state.section == 'accreditationformcontd' ? ' visible' : ' hidden'
						}`}>
						<ErrorBoundary message="Error in ApplicantAccreditationFormContd">
							<ApplicantAccreditationFormContd
								formProps={formProps}
								data={
									op.get(this.state, 'updatedFormData.store.data', false)
										? this.state.updatedFormData.store.data
										: ''
								}
								orgCount={this.state.organisationCount}
								selectedApplicant={this.state.selectedApplicant}
								goBack={this.prevSection}
								updateOrgCount={this.changeOrganisationCount}
							/>
						</ErrorBoundary>
					</div>
					<div className={`form-component${this.state.section == 'confirmation' ? ' visible' : ' hidden'}`}>
						<ErrorBoundary message="Error in Confirmation">
							<Confirmation
								formProps={formProps}
								applicants={this.state.applicantList}
								goBack={this.prevSection}
								editApplicant={this.editApplicant}
								addApplicant={this.addApplicant}
							/>
						</ErrorBoundary>
					</div>
					<div className={`form-component${this.state.section == 'declaration' ? ' visible' : ' hidden'}`}>
						<ErrorBoundary message="Error in Declaration">
							<Declaration formProps={formProps} goBack={this.prevSection} />
						</ErrorBoundary>
					</div>
					<div className={`form-component${this.state.section == 'thankyou' ? ' visible' : ' hidden'}`}>
						<ErrorBoundary message="Error in ThankYou">
							<ThankYou />
						</ErrorBoundary>
					</div>
				</Form>
			</div>
		);
	}

	render() {
		logger.log('[MediaForm] render - state:%o', this.state);

		let MediaFormSchema = '';

		if (this.state.organisationList.length == 0) {
			this.getOrganisationList();
		}

		let currentSelectedApplicant = '';

		if (this.hasSelectedApplicant) {
			currentSelectedApplicant = this.state.updatedFormData.store.data.organisation.applicants[
				this.state.selectedApplicant
			];
		}

		this.initialValues = this.buildInitialValues(currentSelectedApplicant);

		if (this.state.section == 'organisation') {
			// organisation form
			MediaFormSchema = yup.object({
				// organisation form
				firstName: yup.string().required('Please supply a First Name.'),
				lastName: yup.string().required('Please supply a Last Name.'),
				email: yup
					.string()
					.email('Please supply a valid Email Address')
					.required('Email Address is required'),
				position: yup.string().required('Please supply a Position.'),
				address1: yup.string().required('Please supply an Address.'),
				city: yup.string().required('Please supply a City'),
				state: yup.string().required('Please supply a State/Province/Region'),
				postcode: yup.string().required('Please supply a Zip/Postal code'),
				country: yup.string().required('Please select a Country'),
				type: yup.string().required('Please select an Organisation Type'),
			});
		} else if (this.state.section == 'accreditationform') {
			// applicant accreditation form
			MediaFormSchema = yup
				.object({
					// applicant accreditation form
					applicantFirstName: yup.string().required('Please supply a First Name.'),
					applicantLastName: yup.string().required('Please supply a Last Name.'),
					applicantEmail: yup
						.string()
						.email('Please supply a valid Email Address')
						.required('Email Address is required'),
					applicantMobilePhone: yup.string().required('Mobile Phone is required'),
				})
				.test('at-least-one-role', null, obj => {
					if (
						obj.tennisWriter ||
						obj.newsReporter ||
						obj.sportsReporter ||
						obj.featureWriter ||
						obj.onlineReporterSport ||
						obj.onlineReporterNews ||
						obj.photographer ||
						obj.mediaSupport
					) {
						return true; // everything is fine
					}

					return new yup.ValidationError('Please select at least one role', null, 'roles');
				});
		} else if (this.state.section == 'accreditationformcontd') {
			// accred continued
			if (currentSelectedApplicant.roles.indexOf('Photographer') > -1) {
				MediaFormSchema = yup.object({
					pictureCopyright: yup.string().required('Copyright holder is required.'),
				});
			}
		} else if (this.state.section == 'declaration') {
			// declaration
			MediaFormSchema = yup.object({
				acceptDeclaration: yup
					.boolean()
					.required('Please read and accept the Declaration.')
					.oneOf([true], 'Please read and accept the Declaration.'),
			});
		}

		return (
			<div className="form-wrapper media-wrapper">
				<div className="accreditation-form-title">Application for Non-Rights Media Accreditation 2024</div>
				<div className={`${this.state.data.style} media-form`}>
					<div className="progress-indicator column-layout">
						<div className="no-margin left-right progress-item organisation active">
							<div className="circle"></div>
							<div className="line"></div>
							<span>
								Organisation
								<br />
								Information
							</span>
						</div>
						<div
							className={
								'no-margin left-right progress-item applicant ' +
								(this.state.section == 'accreditationlist' ||
								this.state.section == 'accreditationform' ||
								this.state.section == 'accreditationformcontd' ||
								this.state.section == 'confirmation' ||
								this.state.section == 'declaration' ||
								this.state.section == 'thankyou'
									? 'active'
									: '')
							}>
							<div className="circle"></div>
							<div className="line"></div>
							<span>
								Applicant(s)
								<br />
								Added
							</span>
						</div>
						<div
							className={
								'no-margin left-right progress-item accreditation  ' +
								(this.state.section == 'accreditationform' ||
								this.state.section == 'accreditationformcontd' ||
								this.state.section == 'confirmation' ||
								this.state.section == 'declaration' ||
								this.state.section == 'thankyou'
									? 'active'
									: '')
							}>
							<div className="circle"></div>
							<div className="line"></div>
							<span>
								Applicant
								<br />
								Accreditation
							</span>
						</div>
						<div
							className={
								'no-margin left-right progress-item confirmation ' +
								(this.state.section == 'confirmation' ||
								this.state.section == 'declaration' ||
								this.state.section == 'thankyou'
									? 'active'
									: '')
							}>
							<div className="circle"></div>
							<div className="line"></div>
							<span>Confirmation</span>
						</div>
						<div
							className={
								'no-margin left-right progress-item declaration ' +
								(this.state.section == 'declaration' || this.state.section == 'thankyou'
									? 'active'
									: '')
							}>
							<div className="circle"></div>
							<div className="line"></div>
							<span>Declaration</span>
						</div>
						<div
							className={
								'no-margin left-right progress-item finish ' +
								(this.state.section == 'thankyou' ? 'active' : '')
							}>
							<div className="circle"></div>
							<div className="line"></div>
							<span>Finish</span>
						</div>
					</div>
					{this.state.showSpinner ? (
						<div className="loading-container">
							<div className="loader">
								<LoadingIndicator />
							</div>
						</div>
					) : null}

					<Formik
						initialValues={this.initialValues}
						validationSchema={MediaFormSchema}
						onSubmit={this.handleSubmit}
						enableReinitialize={true}>
						{formProps => {
							return this.getForm({ ...formProps, context: this.context });
						}}
					</Formik>
				</div>
			</div>
		);
	}
}
MediaForm.contextType = ContentPageSecureContext;
export default connect(mapStateToProps, mapDispatchToProps)(MediaForm);

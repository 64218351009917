/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { isBrowser, isMobile, isTablet, isMobileSafari } from 'react-device-detect';
import { getQuerystringParam } from 'appdir/components/general/Util';
import showPromoLogic from 'appdir/components/general/PromoFeature/logicFunctions.js';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: Header
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign(
		{},
		{ Controller: state['Controller'] },
		{ Stubs: state['Config']['stubs'] },
		{ syncFavourites: op.get(state, 'Config.myWimbledon.favorites.syncFavourites', null) },
		{ Gigya: state['Gigya'] },
		{ ScoreManager: state['ScoreManager'] },
		{ Config: state['Config'] },
		props
	);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Controller.mount()),
	setPlatform: data => dispatch(deps.actions.Controller.setPlatform(data)),
	updateDate: data => dispatch(deps.actions.Controller.updateDate(data)),
	forceUpdateFn: data => dispatch(deps.actions.Controller.forceUpdateFn(data)),
	getAccountInfo: params => dispatch(deps.actions.Gigya.getAccountInfo(params)),
	cancel: () => dispatch(deps.actions.Controller.cancel()),
	debug: () => dispatch(deps.actions.Controller.debug()),
	slamtracker: id => dispatch(deps.actions.Controller.slamtracker(id)),
	setScoreManagerStatus: data => dispatch(deps.actions.ScoreManager.setScoreManagerStatus(data)),
	togglePromoFeature: (value, isReg) => dispatch(deps.actions.PromoFeature.togglePromoFeature(value, isReg)),
});

class Controller extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
		this.initialLoad = true;
		this.initialUpdate = true;

		this.handleDebug = this.handleDebug.bind(this);
		this.openSlamtracker = this.openSlamtracker.bind(this);
		this.dispatchMeasurementEvent = this.dispatchMeasurementEvent.bind(this);

		window.controller = {};
		window.controller.toggleDebug = function() {
			let event = new Event('toggle_debug');
			window.dispatchEvent(event);
		};
		window.controller.openSlamtracker = function(id) {
			let event = new CustomEvent('open_slamtracker', {
				detail: { id: id },
			});
			window.dispatchEvent(event);
		};

		logger.log('[Controller] constructor state:%o', this.state);
	}

	componentDidMount() {
		//current datetime
		let currTime = Date.now();

		// logger.log('[Controller] componentdidMount state:%o',this.state )

		//detect device
		let platform = '';
		if (isMobile && !isTablet) {
			platform = 'mobile';
		} else if (isBrowser) {
			platform = 'browser';
		} else if (isTablet) {
			platform = 'tablet';
		}
		this.state.mount();
		this.state.setPlatform(platform);
		this.state.updateDate(currTime);

		let smartbannerView = new Event('smartbanner.init');
		document.dispatchEvent(smartbannerView);

		window.addEventListener('toggle_debug', this.handleDebug);
		window.addEventListener('open_slamtracker', this.openSlamtracker);
		window.addEventListener('dispatch_measurement', this.dispatchMeasurementEvent);

		let debugView = getQuerystringParam(null, 'debugView');
		//logger.log('[Controller] componentdidMount debugView:%o', debugView )
		if (debugView) {
			this.handleDebug();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[Controller] componentWillReceiveProps - next:%o', nextProps);
		logger.log('[Controller] componentWillReceiveProps - nextProps.Config?.prompts:%o', nextProps.Config?.prompts);

		// if (nextProps?.Controller?.favortingViewed)
		if (nextProps.Config?.prompts !== this.props.Config?.prompts && nextProps.Config?.prompts) { // make sure config prompts have loaded
			const screenToShow = showPromoLogic(
				nextProps.Config?.prompts,
				nextProps?.Gigya?.loggedIn,
				nextProps?.Controller?.promoFeature,
				[],
			);
	
			let hasScreenToShow = Object.keys(screenToShow)?.some(key => screenToShow[key] === true);
			if (hasScreenToShow) {
				this.props.togglePromoFeature(screenToShow, false);
			}
		}


		// if (nextProps?.Controller?.drawsPromoVerify && !this.props?.Controller?.drawsPromoVerify) {
		// 	// check promo logic
		// 	const screenToShow = showPromoLogic(
		// 		nextProps.Config?.prompts,
		// 		false,
		// 		nextProps?.Controller?.promoFeature,
		// 		[],
		// 	);
			// if (screenToShow.onboard || screenToShow.draw) {
			// 	this.props.togglePromoFeature(screenToShow, false);
			// }
		// }

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevState) {
		//logger.log('[Controller] componentDidUpdate - prevState%o, state:%o', prevState, this.state);
		this.handleScores();

		if (prevState.syncFavourites !== null) {
			if (prevState.syncFavourites && !this.state.syncFavourites) {
				this.props.cancel();
			}

			if (!prevState.syncFavourites && this.state.syncFavourites) {
				this.props.getAccountInfo({ src: 'sync_on' });
			}
		}
	}

	handleScores() {
		let { status } = this.props.ScoreManager;

		// logger.log('[Controller] handleScores - state:%o', this.state);
		if (status && status.loaded && (status.mip || status.sidepanel || status.slamtracker)) {
			if (!status.started && window.currentConnection) {
				logger.log('[Controller] handleScores - dispatch start scores');
				this.props.setScoreManagerStatus({ started: true });
				let startEvent = new CustomEvent('ixEventsScoring', {
					detail: { type: 'start' },
				});
				window.dispatchEvent(startEvent);
			}
		} else if (status && !status.mip && !status.sidepanel && !status.slamtracker) {
			if (status.started) {
				logger.log('[Controller] handleScores - dispatch stop scores');
				this.props.setScoreManagerStatus({ started: false });
				let stopEvent = new CustomEvent('ixEventsScoring', {
					detail: { type: 'stop' },
				});
				window.dispatchEvent(stopEvent);
			}
		}
	}

	handleDebug() {
		//logger.log('[Controller] handleDebug - this:%o', this);
		this.props.debug();
	}

	openSlamtracker(e) {
		//logger.log('[Controller] handleDebug - this:%o', this);
		this.props.slamtracker(e.detail.id);
	}

	dispatchMeasurementEvent(eventDetails) {
		// call a new analytics static function which takes the combined data and makes the actual adobe call
		logger.log('[dispatchMeasurementEvent] eventDetails= %o', eventDetails);

		MeasurementUtils.dispatchMeasurementEventDetailsCall(eventDetails?.detail?.dataLayer, this.props);
	}

	render() {
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';

class WeatherCams extends Component {
	constructor(props) {
		super(props);

		let sec =
			op.has(props, 'cams.refreshSec') && op.get(props, 'cams.refreshSec') >= 30
				? op.get(props, 'cams.refreshSec')
				: -1;

		this.state = {
			...props,
			refreshSec: sec,
		};

		this.refresh = this.refresh.bind(this);

		//logger.log('[WeatherCams] - attr:%o', attributes);
	}

	componentDidMount() {
		if (this.state.refreshSec >= 30) {
			this.timer = setInterval(this.refresh, this.state.refreshSec * 1000);
			this.refresh();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	refresh() {
		let now = moment().valueOf();
		let coeff = 1000 * 60 * 1;
		let rounded = Math.ceil(now / coeff) * coeff;

		let images = document.querySelectorAll('.cam-wrapper .cam > .image-holder > img');
		logger.log('[WeatherCams] refresh - images:%o', images);

		if (images[0] && images[1]) {
			images[0].setAttribute('src', this.state.cams.one + '?' + rounded);
			images[1].setAttribute('src', this.state.cams.two + '?' + rounded);
		}
	}

	render() {
		logger.log('[WeatherCams] render - state:%o', this.state);

		if (this.state.refreshSec > 0) {
			return (
				<span className="cam-wrapper" key={this.state.updated}>
					<div className="two-col component cam">
						<div className="title">Centre Court Weather Camera</div>
						<span className="image-holder">
							<img src={this.state.cams.one} alt="Centre Court Weather Camera" />
						</span>
					</div>
					<div className="two-col component cam">
						<div className="title">Outer Courts Weather Camera</div>
						<span className="image-holder">
							<img src={this.state.cams.two} alt="Outer Courts Weather Camera" />
						</span>
					</div>
				</span>
			);
		} else {
			return null;
		}
	}
}

export default WeatherCams;

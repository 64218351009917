import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[PowerIndexLeaderboard] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[PathToTheFinal] action.mount - Config: %o', Config);
			let data = {
				status: 'loaded',
			};
			dispatch({ type: deps.actionTypes.PATHTOTHEFINAL_MOUNT, data: data });
		});
	},

	unmount: params => (dispatch, getState, store) => {
		let data = {
			status: 'init',
			display: false,
			playerId: null,
			event: null,
			defaultTop: 0,
		};
		dispatch({ type: deps.actionTypes.PATHTOTHEFINAL_UNMOUNT, data: data });
	},
	
	update: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.PATHTOTHEFINAL_UPDATE, data });
	},

	hidePathToTheFinal: () => (dispatch, getState) => {
		dispatch({
			type: deps.actionTypes.PATHTOTHEFINAL_CLOSE,
			data: {
				display: false,
			},
		});
	},

	showPathToTheFinal: (playerId = null, event = null, defaultTop = null) => dispatch => {
		dispatch({
			type: deps.actionTypes.PATHTOTHEFINAL_OPEN,
			data: {
				display: true,
				playerId,
				event,
				defaultTop,
			},
		});
	},
};

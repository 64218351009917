import MeasurementUtils from 'appdir/lib/analytics';
import op from 'object-path';

//need to get anon and join acct favorites
//reg success will also fire on updates, like consents

export const measureRegistrationCompletion = obj => {
	logger.log('[Gigya] measureRegistrationCompletion - obj:%o', obj);

	if (obj?.response?.newUser) {
		MeasurementUtils.dispatchMeasurementCall(
			'Registration Success',
			{
				origin: obj?.response?.requestParams?.regSource,
				newsletterOpt: obj?.subscriptions?.newsletter.email.isSubscribed,
			},
			{
				UID: obj?.response?.UID,
				loggedIn: true,
				hasFavorites: '',
			}
		);
	} else {
		MeasurementUtils.dispatchMeasurementCall(
			'registrationCompleteUpdate',
			{
				origin: obj?.response?.requestParams?.regSource,
				newsletterOpt: obj?.subscriptions?.newsletter.email.isSubscribed,
			},
			{
				UID: obj?.response?.UID,
				loggedIn: true,
				hasFavorites: '',
			}
		);
	}
};

export const measureLoginSuccess = obj => {
	//logger.log('[Gigya] measureLoginSuccess - obj:%o', obj);
	let favPlayers = obj?.response?.data?.userPreferences?.favourites?.players || [];

	MeasurementUtils.dispatchMeasurementCall(
		'loginSuccess',
		{
			response: 'success',
			origin: obj?.response?.requestParams?.regSource,
			loginType: obj?.response?.loginProvider,
		},
		{
			UID: obj?.response?.UID,
			loggedIn: true,
			hasFavorites: favPlayers.length > 0,
		}
	);
};

export const measureLoginFailure = obj => {
	//logger.log('[Gigya] measureLoginFailure - obj:%o', obj);

	MeasurementUtils.dispatchMeasurementCall(
		'loginSuccess',
		{
			response: 'failed',
			origin: obj?.response?.params?.regSource,
			loginType: '',
		},
		{
			UID: '',
			loggedIn: false,
			hasFavorites: '',
		}
	);
};

export const measureLogout = () => {
	//logger.log('[Gigya] measureLoginFailure - obj:%o', obj);

	MeasurementUtils.dispatchMeasurementCall(
		'logout',
		{
			response: 'success',
			origin: '',
			loginType: '',
		},
		{
			UID: '',
			loggedIn: false,
			hasFavorites: '',
		}
	);
};

export const measureLoggedin = data => {
	logger.log('[Gigya] measureLogedin - obj:%o');
	let favPlayers = data?.currentUser?.data?.userPreferences?.favourites?.players || [];

	MeasurementUtils.dispatchMeasurementCall(
		'myW_User',
		{},
		{
			UID: data?.currentUser?.UID,
			loggedIn: true,
			hasFavorites: favPlayers.length > 0,
		}
	);
};

export const measureAnonymous = favourites => {
	logger.log('[Gigya] measureAnonymous - obj:%o');
	let favPlayers = favourites || [];

	MeasurementUtils.dispatchMeasurementCall(
		'myW_User',
		{},
		{
			UID: 'Anonymous',
			loggedIn: false,
			hasFavorites: favPlayers.length > 0,
		}
	);
};

export const measureCall = data => {
	logger.log('[Gigya] measureCall - data:%o', data);

	let type = '';
	let m_data = {};

	if (data.context) {
		m_data.content = data.context;
		m_data.provider = op.get(data, 'response.response.loginProvider', '');
	}

	if (data.eventName == 'afterScreenLoad') {
		switch (data.currentScreen) {
			case 'gigya-login-screen':
				type = 'Login Screen';
				break;
			case 'gigya-login-success-screen':
				type = 'Login Success Screen';
				break;
			case 'gigya-register-screen':
				type = 'Registration Screen';
				break;
			case 'gigya-complete-registration-screen':
				type = 'Registration Complete Screen';
				break;
			case 'gigya-registration-success-screen':
				type = 'Registration Success Screen';
				break;
			case 'gigya-registration-success-postcode-screen':
				type = 'Registration Success Postcode Screen';
				break;
			case 'gigya-reset-password-screen':
				type = 'Reset Password Screen';
				break;
			case 'gigya-reset-password-success-screen':
				type = 'Reset Password Success Screen';
				break;
			case 'gigya-password-change-required-screen':
				type = 'Change Password Screen';
				break;
			case ' gigya-forgot-password-screen':
				type = 'Forgot Password Screen';
				break;
			case 'gigya-forgot-password-success-screen':
				type = 'Forgot Password Success Screen';
				break;
			case 'gigya-link-account-screen':
				type = 'Linking Screen';
				break;
			default:
				break;
		}
	} else if (data.eventName == 'hide') {
		type = 'Close Screen';
	}

	if (type) {
		MeasurementUtils.dispatchMeasurementCall(type, m_data);
	}
};

import React from 'react';
// import op from 'object-path';
import NewsTile from 'appdir/components/content/NewsTile';

const MatchContent = props => {
	logger.log('[MatchContent] props:%o', props);

	return (
		<div className="column-layout match-content-container">
			{props.relatedContent.map(item => {
				let attributes = {};
				attributes = { ...item };
				attributes.cmsId = item.cmsId ? item.cmsId : item.contentId;
				attributes.contentId = item.contentId;
				attributes['col-style'] = 'one-col';
				return <NewsTile attributes={attributes} key={attributes.cmsId} />;
			})}
		</div>
	);
};

export default MatchContent;

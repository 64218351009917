import DrawsArchiveYear from './index';
import deps from 'dependencies';

export default {
	path: '/:lang/draws_archive/index.html',
	exact: true,
	component: DrawsArchiveYear,
	section: 'Draws',
	load: params => deps.actions.DrawsArchiveYear.mount(params),
};

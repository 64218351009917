import classNames from 'classnames';
import React, { Component } from 'react';
import { TransferSchema, handleSubmitTransfer } from 'appdir/components/pages/Tickets/ReusableTicketActionFuncs';
import { Formik, Form } from 'formik';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';
import CustomActionConfirmation from 'appdir/components/pages/Tickets/elements/TicketActionViews/CustomActionConfirmation';
class TransferAction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recipientEmail: '',
			confirmRecipientEmail: '',
			actionMsg: '',
			actionStatus: '',
			showConfirmationModal: false,
		};
		this.onAttemptTicketTransfer = this.onAttemptTicketTransfer.bind(this);
		this.onHandleTicketAction = this.onHandleTicketAction.bind(this);
	}

	componentDidMount() {
		measureInAppContext({
			pageTitle: 'Tickets',
			action: 'Transfer Tickets',
			args: [],
			metricType: 'state',
		});
	}
 
	onHandleTicketAction(which, error, msg, msg2) {
		// logger.log('[TransferAction] - onHandleTicketAction - which:%o, error:%o, msg:%o, msg2:%o', which, error, msg, msg2)
		if (error) {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Transfer Ticket Sumbit',
					args: 'Failure',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall(`Transfer Ticket Submit Failure`);
			}

			let message1, message2;
			let message1Default = 'There was an error processing your request. Please try again.';
			let message2Default = '';

			const errCode = error?.data?.response?.failure[0] ? error.data.response.failure[0].errorCode : '';

			/** if there is no error code, use default messages */
			if (!errCode) {
				if (error?.msg) {
					message1 = error?.msg;
					message2 = message2Default;
				} else {
					message1 = message1Default;
					message2 = message2Default;
				}
			} else {
				/**
				 * if error code is TRANSFER_RULE_VIOLATION, use errorMessage
				 * value for 1st line and message2Default for 2nd line
				 */
				if (errCode === 'TRANSFER_RULE_VIOLATION') {
					message1 = error.data.response.failure[0].errorMessage
						? error.data.response.failure[0].errorMessage
						: message1Default;
					message2 = message2Default;
				} else if (errCode !== 'TRANSFER_RULE_VIOLATION') {
					/**
					 * if error code is not TRANSFER_RULE_VIOLATION, use message1Default
					 * for 1st line and errorMessage value for 2nd line
					 */
					message1 = message1Default;
					message2 = error?.data?.response?.failure[0]?.errorMessage
						? error?.data?.response?.failure[0]?.errorMessage
						: message2Default;
				}
			}

			// logger.log('[TransferAction] - onHandleTicketAction - message1:%o, message2:%o', message1, message2);

			if (this.props.onSetResponse) this.props.onSetResponse(true);

			if (this.props.onSetActionSubmitted) this.props.onSetActionSubmitted();

			// // refresh the list
			// this.props.onRefreshTicketData();

			// settimeout to give the ticket data time to refresh in order to show updated status
			setTimeout(() => {
				this.props.showModal({
					showModal: false, // false because we have custom modal to show, but still need to update the status type to success otherwise the updating modal will continue showing
					showCloseBtn: true,
					type: 'error',
					title: `${which} Error`,
					message: `${message1} ${message2}`,
					useTimer: false,
				});

				// show confirmation table
				this.setState({ showConfirmationModal: true, isError: `${message1} ${message2}`, fullError: error });
			}, 100);
		} else {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Transfer Ticket Sumbit',
					args: 'Successful',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall(`Transfer Ticket Submit Successful`);
			}

			if (this.props.onSetResponse) this.props.onSetResponse(true);

			// refresh the list
			this.props.onRefreshTicketData();

			// settimeout to give the ticket data time to refresh in order to show updated status
			setTimeout(() => {
				this.props.showModal({
					showModal: false, // false because we have custom modal to show, but still need to update the status type to success otherwise the updating modal will continue showing
					showCloseBtn: true,
					type: 'success',
					title: `${which} Success`,
					message: `${msg} ${msg2}`,
					useTimer: false,
				});

				// show confirmation table
				this.setState({ showConfirmationModal: true, isError: false });
			}, 100);
			if (this.props.onSetActionSubmitted) this.props.onSetActionSubmitted();
		}
	}

	onAttemptTicketTransfer(msg, msg2) {
		const { fullTicketValue, callTicketActions } = this.props;
		const { recipientEmail } = this.state;
		let ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];
		const spectatorId = ticketValue[0]?.spectatorId || '';

		this.props.showModal({
			showModal: true,
			showCloseBtn: true,
			type: 'updating',
			title: 'TRANSFER IN PROGRESS',
			message: `Please wait while the ticket(s) are being transferred to: ${recipientEmail.bold()}`,
			useTimer: true,
		});

		handleSubmitTransfer(
			recipientEmail,
			spectatorId,
			ticketValue,
			'transfer',
			callTicketActions,
			this.onHandleTicketAction,
			msg,
			msg2,
			fullTicketValue.length > 0 ? '' : 'Your ticket was not transferred to:'
		);
	}

	render() {
		const { closeModal, fullTicketValue, toAutoFocus } = this.props;

		let ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];
		const spectatorId = ticketValue[0]?.spectatorId || '';

		const { ticketStubToRender, renderLargeTransferModal } = this.props;
		const { recipientEmail, confirmRecipientEmail, showConfirmationModal } = this.state;

		let transferSchema = TransferSchema(spectatorId, recipientEmail);

		return (
			<div>
				{!showConfirmationModal ? (
					<Formik
						validationSchema={transferSchema}
						initialValues={{ recipientEmail: recipientEmail, confirmRecipientEmail: confirmRecipientEmail }}
						onSubmit={() => {
							this.onAttemptTicketTransfer(
								`The ticket(s) have been transferred to: ${recipientEmail.bold()}`,
								'Please ask the recipient to check their email for their arrival.'
							);
						}}
						enableReinitialize>
						{formProps => {
							const { errors } = formProps;
							return (
								<Form className="ticket-action-modal-form">
									<div
										tabIndex={0}
										aria-label="Please enter the email address of the person you would like to send your ticket
										to:"
										className={
											this.props.isWebView
												? 'webview-ticket-action-modal-header'
												: 'ticket-action-modal-header'
										}>
										Please enter the email address of the person you would like to send your ticket
										to:
									</div>
									<div
										className={
											this.props.isWebView
												? 'webview-ticket-transfer-emails-cntr'
												: 'ticket-transfer-emails-cntr'
										}>
										<div>
											<label
												className={
													renderLargeTransferModal
														? 'ticket-action-modal-input-label-left'
														: 'ticket-action-modal-input-label'
												}>
												Recipient email address
												<input
													type="email"
													alt="Please enter the email address of the person you would like to send your ticket
													to"
													aria-label="Please enter the email address of the person you would like to send your ticket
													to"
													className="ticket-action-modal-input"
													name="recipientEmail"
													value={recipientEmail}
													autoFocus={toAutoFocus}
													onChange={event =>
														this.setState({ recipientEmail: event.target.value })
													}
												/>
											</label>
											<div
												tabIndex={0}
												aria-label={errors.recipientEmail}
												className={
													this.props.isWebView
														? 'webview-ticket-action-errorMsg'
														: 'ticket-action-errorMsg'
												}>
												{errors.recipientEmail}
											</div>
										</div>
										<div>
											<label
												className={
													renderLargeTransferModal
														? 'ticket-action-modal-input-label-right'
														: 'ticket-action-modal-input-label'
												}>
												Re-enter recipient email address
												<input
													alt="Re-enter recipient email address"
													aria-label="Re-enter recipient email address"
													type="email"
													className="ticket-action-modal-input"
													name="confirmRecipientEmail"
													value={this.state.confirmRecipientEmail}
													onChange={event =>
														this.setState({ confirmRecipientEmail: event.target.value })
													}
												/>
											</label>
											<div
												tabIndex={0}
												aria-label={errors.confirmRecipientEmail}
												className={
													this.props.isWebView
														? 'webview-ticket-action-errorMsg'
														: 'ticket-action-errorMsg'
												}>
												{errors.confirmRecipientEmail}
											</div>
										</div>
									</div>

									<div className="ticket-action-modal-section">
										<div
											className={
												ticketValue.length > 1
													? 'ticket-action-modal-general-label'
													: 'ticket-action-modal-general-label-single-tix'
											}
											style={{
												marginTop: ticketValue.length > 1 ? '0px' : '24px',
											}}>
											Ticket{ticketValue.length > 1 ? 's' : ''} to be transferred:
										</div>
										<div className="ticket-action-modal-ticket-stub">{ticketStubToRender}</div>
									</div>
									<div
										className={
											this.props.isWebView
												? 'webview-ticket-stub-actions-btns-cntr'
												: 'ticket-stub-actions-btns-cntr'
										}>
										<button
											role="button"
											className={classNames(
												this.props.isWebView
													? 'webview-ticket-stub-actions-value webview-ticket-stub-tablet-width'
													: 'ticket-stub-actions-value',
												this.props.isWebView
													? 'webview-ticket-action-modal-button-green webview-ticket-stub-tablet-width'
													: 'ticket-action-modal-button-green'
											)}
											onClick={() => formProps.submitForm}
											type="submit">
											Transfer
										</button>
										<button
											className={
												this.props.isWebView
													? 'webview-ticket-stub-actions-value webview-ticket-stub-tablet-width'
													: 'ticket-stub-actions-value'
											}
											role="button"
											onClick={() => closeModal(null)}>
											Cancel
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				) : (
					<CustomActionConfirmation
						fullTicketValue={ticketValue}
						recipientEmail={recipientEmail}
						closeModal={closeModal}
						onRenderMultipleTicketStubs={(data, error, email) =>
							this.props.onRenderMultipleTicketStubs(data, error, email)
						}
						onRenderSingleTicketStub={(data, error, email) =>
							this.props.onRenderSingleTicketStub(data, error, email)
						}
						headerTxt={
							this.state.fullError && (!fullTicketValue.length || fullTicketValue.length === 1)
								? null
								: 'Please ask the recipient to check their email and accept the transferred ticket. The ticket status may take a few minutes to update.'
						}
						detailsTxt={
							this.state.fullError && (!fullTicketValue.length || fullTicketValue.length === 1) ? (
								<div style={{ color: 'black', textAlign: 'center' }}>
									Your ticket was not transferred to: <b>{recipientEmail}</b>.<br></br>
									{this.state.isError}
								</div>
							) : (
								<div className="ticket-action-modal-email-label">
									Recipient email address:
									<span className="ticket-action-modal-email">{recipientEmail}</span>
								</div>
							)
						}
						actionType={'Transfer'}
						error={this.state.fullError}
						isWebView={this.props.isWebView}
						ticketData={this.props.ticketData}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				)}
			</div>
		);
	}
}
export default TransferAction;

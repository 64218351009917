/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import MatchBox from 'appdir/components/scoring/MatchBox';
/**
 * -----------------------------------------------------------------------------
 * Functional Component: MatchResult
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['MatchResult'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchResult.mount()),
});

class MatchResult extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};
		this.fetchMatchResult = true;
		this.loadedPath = '';
	}

	componentDidMount() {
		//logger.log('[MatchResult] -  componentDidMount');
		this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevState) {
		//logger.log('[MatchResult] componentDidUpdate - state:%o', this.state);

		if (!this.state.resultBoxData && !this.loadedPath && this.fetchMatchResult) {
			this.fetchMatchResult = false;
			let path = this.state.dataPath.replace('<matchId>', this.state.data.matchId);
			this.loadedPath = path;

			deps.services.MatchResult.getResultBox(path).then(data => {
				this.setState({
					resultBoxData: data,
				});
			});
		}
	}

	render() {
		logger.log('[MatchResult] render - state:%o', this.state);
		let scoreTableHeader = '';

		if (this.state.resultBoxData) {
			scoreTableHeader = this.state.resultBoxData.courtName;
		}

		return scoreTableHeader ? (
			<div className={`result-box ${this.state.data.style}`}>
				<MatchBox
					attributes={{
						data: this.state.resultBoxData,
						event: this.state.resultBoxData.eventName,
						tableHeader: scoreTableHeader,
						showLinks: false,
						style: '',
					}}
				/>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchResult);

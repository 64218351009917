import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.WATCHREAD_LOAD:
			newState = {
				...state,
				path: action.data,
			};
			logger.log('[WatchReadPage] WATCHREAD_LOAD - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.ARTICLE_LOADING:
			newState = {
				...state,
				status: 'loading',
			};
			logger.log('[WatchReadPage] WATCHREAD_LOADING - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

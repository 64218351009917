import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		//logger.log('[MainNav]  deps.actions.mount data:%o',data);
		//logger.log('[MainNav]  deps.actions.mount state:%o', getState() );
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[MainNav] mount - data:%o', Config);
			//let menus = Config.menus;
			let myWim = Config.myWimbledon;
			let flagImagePathSmall = Config.otherData.flagImagePathSmall;
			dispatch({
				type: deps.actionTypes.MAINNAV_MOUNT,
				data: { flagImagePathSmall, myWim },
			});
		});
	},
	unmount: data => (dispatch, getState, store) => {
		//logger.log('[MainNav]  deps.actions.unmount data:%o',data);
		dispatch({ type: deps.actionTypes.MAINNAV_UNMOUNT, data: data });
	},

	navigate: data => (dispatch, getState, store) => {
		logger.log('[MainNav]  deps.actions.navigate data:%o', data);
		let newData = {
			newPath: data.path,
		};
		dispatch({ type: deps.actionTypes.MAINNAV_NAVIGATE, data: newData });
	},

	clearPath: () => (dispatch, getState, store) => {
		//logger.log('[MainNav]  deps.actions.clearPath data:%o',data);
		let newData = {
			newPath: null,
		};
		dispatch({ type: deps.actionTypes.MAINNAV_CLEAR_PATH, data: newData });
	},
};

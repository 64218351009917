import React, { useState, useEffect } from 'react';
import Button from 'appdir/components/common-ui/Button';
import SearchResults from '../SearchResults';

const RoleDisplay = props => {
	useEffect(() => {
		logger.log('[SearchPage_RoleDisplay] useEffect - props:%o', props);
	}, [props]);

	const renderRoleSection = (arr, key) => {
		const { loadMore } = props;
		return (
			<div>
				<SearchResults
					data={{ response: { docs: arr } }}
					loadAllResults={loadMore.includes(key) || arr.length < 3 ? 'true' : 'false'} // make string to not break code in existing SearchResults
					isRoleData
				/>
				{arr.length > 2 && !loadMore.includes(key) ? (
					<Button
						className="btn solid role-display-btn"
						onClick={() => props.setLoadMore(loadMore.concat(key))}>
						Load More
					</Button>
				) : null}
			</div>
		);
	};

	const getRoleContent = () => {
		logger.log('[SearchPage_RoleDisplay] getRoleContent - props:%o', props);
		const { docs } = props?.results;
		let roleObj = {};
		docs.map(val => {
			let title = val['metatag.resulttype_str']?.length ? val['metatag.resulttype_str'].split('_')[0] : null;
			if (title) {
				if (roleObj[title]?.length) {
					roleObj[title].push(val);
				} else {
					roleObj[title] = [val];
				}
			}
			//logger.log('[SearchPage_RoleDisplay] getRoleContent - roleObj:%o', roleObj);
		});

		return (
			<div>
				{Object.keys(roleObj).map((key, index) => {
					let title = key.split('_')[0];
					return (
						<div key={index}>
							<div className="section-title">{title}</div>
							{renderRoleSection(roleObj[key], key)}
						</div>
					);
				})}
			</div>
		);
	};

	return <div className="role-display">{getRoleContent()}</div>;
};

export default RoleDisplay;

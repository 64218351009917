import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
// import throttle from 'lodash/throttle';
import { getScrollPos } from 'appdir/components/general/Util';

import 'scroll-behavior-polyfill';

let popstateEvent = false;

const mapStateToProps = (state, props) => {
	return {
		...state['ScrollHandler'],
		...props,
		windowSize: state['PageHeader'].windowSize,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ScrollHandler.mount()),
	unmount: data => dispatch(deps.actions.ScrollHandler.unmount(data)),
});

const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

class ScrollHandler extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		//logger.log('[ScrollHandler] constructor - state:%o', this.state);

		this.scrolled = false;
		this.checkScroll();
	}

	componentDidMount() {
		this.props.mount();

		this.contentFilter = document.querySelector('.content-filters-scroll');

		let self = this;

		window.onpopstate = event => {
			logger.log('[ScrollHandler] onpopstate - state:%o this:%o', event, self);
			popstateEvent = true;
		};
	}

	componentWillUnmount() {
		let scroll = getScrollPos();
		logger.log('[ScrollHandler] componentWillUnmount - scroll:%o ', scroll);
		this.props.unmount({
			scrollPos: scroll,
		});

		popstateEvent = false;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[ScrollHandler] componentDidUpdate - state:%o', this.state);

		this.contentFilter = document.querySelector('.content-filters-scroll');

		let headerHeight = 106;
		if (this.props.windowSize == 'mobile') {
			headerHeight = 73;
		}

		if (this.contentFilter) {
			let posTop = Math.ceil(this.getCoords(this.contentFilter).top - headerHeight);

			//if filter has changed and scrollPos.y is 0, scroll to top
			if (this.state.filter != prevState.filter && window.scrollY != posTop) {
				if (supportsNativeSmoothScroll) {
					window.scrollTo({ top: posTop, behavior: 'smooth' });
				} else {
					window.scroll({ top: posTop, behavior: 'smooth' });
				}
			}
		}

		this.checkScroll();
	}

	checkScroll() {
		logger.log(
			'[ScrollHandler] checkScroll - popstateEv:%o scrolled:%o props:%o',
			popstateEvent,
			this.scrolled,
			this.props
		);

		// scroll to last position if browser back/history nav used
		let scroll = getScrollPos();
		if (
			popstateEvent &&
			!this.scrolled &&
			this.props.hasData &&
			this.props.scrollPos &&
			this.props.scrollPos.y != scroll.y
		) {
			logger.log('[ScrollHandler] checkScroll - scroll:%o', this.props.scrollPos.y);
			this.scrolled = true;
			window.scrollTo(0, this.props.scrollPos.y);
		}
	}

	// get document coordinates of the element
	getCoords(elem) {
		let box = elem.getBoundingClientRect();

		return {
			top: box.top + window.pageYOffset,
			left: box.left + window.pageXOffset,
		};
	}

	render() {
		return <span />;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollHandler);

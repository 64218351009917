import JobsPage from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:lang/jobs/jobs.html',
		exact: true,
		component: JobsPage,
		load: params => deps.actions.JobsPage.mount(params),
	},
];

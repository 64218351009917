import deps from 'dependencies';

export default (state = {}, action) => {
	switch (action.type) {
		case deps.actionTypes.PLAYERINDEX_LOADED: {
			return { ...action.data };
		}
		default:
			return state;
	}
};

import React, { Component, Fragment } from 'react';
import { PlayerDigitalExperienceContext } from '../context';
import { getContent } from 'appdir/components/general/Util/Services';
import { loggedIn, checkRole } from 'appdir/components/general/Util/Role';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import WimLink from 'appdir/components/general/WimLink';
import op from 'object-path';
import HeaderText from './HeaderText';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getParams } from './Utils.js';
import TableComponent from 'appdir/components/common-ui/TableComponent';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
	unmount: () => dispatch(deps.actions.PlayerDigitalExperience.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

/**
 * PlayerDelegate - Page for players to manage their delegates or send a request to a new one
 */

class PlayerDelegate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			text: 'Delegate Management',
			gotData: false,
			data: null,
			emailSent: false,
		};
		this.fetchData = true;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.approveReject = this.approveReject.bind(this);
		this.email1;
		this.email2;

		this.email1ref = React.createRef();
		this.email2ref = React.createRef();
		logger.log('[PDE - PlayerDelegate] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log('[PDE - PlayerDelegate] componentDidMount - state:%o context:%o', this.state, this.context);
		this.setState({ fetch: true });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'currentUser', {});

		let roleValidated = checkRole(op.get(currentUser, 'roles', []), op.get(this.state, 'roleIds', []));
		let isDelegate = checkRole(op.get(currentUser, 'roles', []), [1000280]);

		logger.log(
			'[PDE - PlayerDelegate] componentDidUpdate - user:%o state:%o roleValidated:%o',
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			roleValidated
		);

		// logger.log('[PDE - PlayerCatering] componentDidUpdate - state:%o context:%o', this.state, this.context);
		if (loggedIn(currentUser) && roleValidated && !isDelegate) {
			if (this.state.dataUrl && !this.state.gotData && this.fetchData) {
				this.fetchData = false;
				/** TODO put this back */
				this.props
					.getJWT()
					.then(token => {
						//logger.log('[PDE - PlayerDelegate] componentDidUpdate - token:%o', token.jwt);
						let nullError = false;
						let params = getParams('delegate', { ...this.state });
						getContent(this.state.dataUrl, 'get', token.jwt.id_token, nullError, params)
							.then(data => {
								logger.log('[PDE - PlayerDelegate] componentDidUpdate - data:%o', data);
								this.setState({ data: data, gotData: true, reloading: false });
							})
							.catch(data => {
								this.setState({ data: 'error', gotData: true, reloading: false });
							});
					})
					.catch(error => {
						logger.error('[PDE - PlayerDelegate] componentDidUpdate - error getting token');
					});

				// let data = {
				// 	response: [
				// 		{
				// 			UID: 'd795828c7323477c9251c9eb03777ad2',
				// 			firstName: 'Santosh',
				// 			lastName: 'Nair',
				// 			delegateRelationshipStatus: 'Approved',
				// 		},
				// 		{
				// 			UID: '377ab7f958584d54863b66dc93e22035',
				// 			firstName: 'Severin',
				// 			lastName: 'Luthi',
				// 			delegateRelationshipStatus: 'Rejected',
				// 		},
				// 		{
				// 			UID: '49141a69d1204d8782a257c4d5a2494f',
				// 			firstName: 'Joe',
				// 			lastName: 'Bloggs',
				// 			delegateRelationshipStatus: 'Pending',
				// 		},
				// 	],
				// };
				// this.setState({ data, gotData: true });
			}
		}
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[PDE - PlayerDelegate] handleSubmit - state:%o', values);
	}

	noDelegateData() {
		let delegateItems = op.get(this.state.data, 'response.delegateUsers', []);
		return delegateItems.length == 0;
	}

	setEmail1(e) {
		logger.log('[PDE - PlayerDelegate] email1:%o', e.target.value);
		this.email1 = e.target.value;
		this.checkEmail();
	}

	setEmail2(e) {
		this.email2 = e.target.value;
		this.checkEmail();
	}

	checkEmail() {
		let emailMatch = this.email1.toLowerCase() == this.email2.toLowerCase();
		let validEmail = String(this.email1)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);

		this.setState({ emailMatch, validEmail, emailSent: false });
	}

	showStub() {
		const stubMsg = op.get(this.state, 'stubs.pde.delegates.text', null);
		return (
			<div className="four-col bank-info-container">
				<div className="section-container-no-data">
					<div className="row prize-money-explanation">{stubMsg}</div>
				</div>
			</div>
		);
	}

	getErrorCode(error) {
		let code = 'E2340';
		if (error.status == 403) code = 'E2320';
		if (error.status == 500) code = 'E2330';
		return code;
	}

	approveReject(e, id, reject = false) {
		logger.log('[PDE - PlayerDelegate] approveReject - this.props:%o', this.props);
		this.setState({ reloading: true });
		this.props
			.getJWT()
			.then(token => {
				logger.log('[PDE - PlayerDelegate] approveReject - token:%o', token.jwt);
				let nullError = false;
				// let params = getParams('delegate', { ...this.state });
				let url = '';
				if (reject) {
					url = this.props.paths.delegateReject.replace('<uid>', id);
				} else {
					url = this.props.paths.delegateApprove.replace('<uid>', id);
				}
				logger.log('[PDE - PlayerDelegate] approveReject - url:%o', url);
				getContent(url, 'post', token.jwt.id_token, nullError, null)
					.then(data => {
						logger.log('[PDE - PlayerDelegate] approveReject - data:%o', data);
						this.fetchData = true;
						this.setState({ gotData: false });
						if (data.statusReason == 'error') {
							this.props.showModal({
								showModal: true,
								showCloseBtn: true,
								type: 'fail',
								title: 'ERROR',
								message: 'There was an error with your request. Please try again later',
								code: 'E2310',
								useTimer: false,
							});
						}
					})
					.catch(error => {
						console.log(error.status);
						let code = this.getErrorCode(error);
						this.fetchData = false;
						this.setState({ gotData: true, reloading: false });
						this.props.showModal({
							showModal: true,
							showCloseBtn: true,
							type: 'fail',
							title: 'ERROR',
							code,
							message: 'There was an error with your request. Please try again later',
							useTimer: false,
						});
					});
			})
			.catch(error => {
				this.fetchData = false;
				this.setState({ gotData: true, reloading: false });
				this.props.showModal({
					showModal: true,
					showCloseBtn: true,
					type: 'fail',
					title: 'ERROR',
					message: 'There was an error with your request. Please try again later',
					code: 'E2010',
					useTimer: false,
				});
			});
	}

	requestDelegate(disabled) {
		if (disabled) return;

		this.setState({ sending: true });
		this.props.showModal({
			showModal: true,
			showCloseBtn: false,
			type: 'updating',
			title: 'SENDING REQUEST',
			message: 'Your request is being sent',
		});
		this.props
			.getJWT()
			.then(token => {
				let nullError = false;
				// let params = getParams('delegate', { ...this.state });
				let url = this.props.paths.delegateRequest;
				let data = { email: this.email1 };
				getContent(url, 'post', token.jwt.id_token, nullError, null, data)
					.then(data => {
						logger.log('[PDE - PlayerDelegate] requestDelegate - data:%o', data);
						this.email1ref.current.value = '';
						this.email2ref.current.value = '';
						// this.setState({ emailSent: true });
						this.setState({ sending: false });
						if (data.statusReason == 'error') {
							this.props.showModal({
								showModal: true,
								showCloseBtn: true,
								type: 'fail',
								title: 'ERROR',
								message: 'There was an error with your request. Please try again later',
								code: 'E2310',
								useTimer: false,
							});
						} else {
							this.props.showModal({
								showModal: true,
								showCloseBtn: true,
								type: 'success',
								title: 'EMAIL SENT',
								message: 'Email was sent successfully',
								useTimer: false,
							});
						}
					})
					.catch(error => {
						let code = this.getErrorCode(error);
						this.setState({ sending: false });
						this.props.showModal({
							showModal: true,
							showCloseBtn: true,
							type: 'fail',
							title: 'EMAIL ERROR',
							code,
							message: 'There was an error with your request. Please try again later',
							useTimer: false,
						});
					});
			})
			.catch(error => {
				logger.error('[PDE - PlayerDelegate] approveReject - error getting token');
				this.props.showModal({
					showModal: true,
					showCloseBtn: true,
					type: 'fail',
					title: 'ERROR',
					message: 'There was an error with your request. Please try again later',
					code: 'E2010',
					useTimer: false,
				});
			});
	}

	renderDelegate() {
		//check for no delegate items array, or an empty delegate items array
		// logger.log("[PlayerDelegate] this.state.emailMatch:%o, this.email1:%o, this.email2:%o", this.state.emailMatch, this.email1, this.email2)
		let delegateItems = op.get(this.state, 'data.response.delegateUsers', []);
		let message = op.get(this.state.data, 'message', null);
		let emailError = !this.state.emailMatch && this.email1 && this.email2;
		let { validEmail } = this.state;
		return (
			<>
				{message !== 'OK' || this.state.data === 'error' ? (
					<div className="four-col bank-info-container">
						<div className="section-container-no-data">
							<div className="row prize-money-explanation">
								The information you are requesting is currently unavailable. Please try again later or
								contact us on{' '}
								<WimLink to="mailto:players@aeltc.com" external={true}>
									players@aeltc.com
								</WimLink>
								.
							</div>
						</div>
					</div>
				) : (
					<>
						<div className="four-col">
							<h4>{this.state.text}</h4>
						</div>
						<div className="four-col margin">
							<p>
								Delegate management is designed to provide your player support team with access to your
								players.wimbledon.com account. This will enable them to add guest accreditation,
								view/download your prize money statements, view/watch match statistics/videos, and see
								all the information available to you on The Championships.
							</p>
							<p>
								Please provide their email address below. They will be sent a link to set up their
								account, once this is completed you will need to return to this page to approve their
								access.
							</p>
							<p>From this page you can add, remove and approve access to any person.</p>
						</div>
						<div className="four-col pde-content-dash">
							{this.noDelegateData() ? (
								<div className="four-col bank-info-container">
									<div className="section-container-no-data">
										<div className="row prize-money-explanation">You do not have any delegates</div>
									</div>
								</div>
							) : (
								<table className="delegate-table">
									<thead>
										<tr>
											<th>Name</th>
											<th>Status</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{delegateItems.length > 0 &&
											delegateItems.map((d, i) => {
												if (
													d.delegateRelationshipStatus.toLowerCase() == 'approved' ||
													d.delegateRelationshipStatus.toLowerCase() == 'pending'
												) {
													return (
														<tr key={`delegate-${i}`}>
															<td>{`${d.firstName} ${d.lastName}`}</td>
															<td>{d.delegateRelationshipStatus}</td>
															<td>
																<div className="approval-buttons">
																	{d.delegateRelationshipStatus.toLowerCase() ==
																	'approved' ? (
																		<div className="generic-button_wrapper">
																			<div
																				className="generic-button btn solid "
																				tabIndex={0}
																				role="button"
																				onClick={e =>
																					this.approveReject(e, d.uid, true)
																				}>
																				<div className="content">Remove</div>
																			</div>
																		</div>
																	) : (
																		<>
																			<div className="generic-button_wrapper">
																				<div
																					className="generic-button btn solid "
																					tabIndex={0}
																					role="button"
																					onClick={e =>
																						this.approveReject(
																							e,
																							d.uid,
																							true
																						)
																					}>
																					<div className="content">
																						Remove
																					</div>
																				</div>
																			</div>
																			<div className="generic-button_wrapper">
																				<div
																					className="generic-button btn solid "
																					tabIndex={0}
																					onClick={e =>
																						this.approveReject(
																							e,
																							d.uid,
																							false
																						)
																					}
																					role="button">
																					<div className="content">
																						Approve
																					</div>
																				</div>
																			</div>
																		</>
																	)}
																</div>
															</td>
														</tr>
													);
												} else {
													return null;
												}
											})}
									</tbody>
								</table>
							)}

							<hr />
							<div className="four-col">
								<h3>Request a Delegate</h3>
							</div>
							<div className="four-col">
								<div className="delegate-request">
									<input
										type="text"
										id="email"
										placeholder={'Email'}
										onChange={e => this.setEmail1(e)}
										ref={this.email1ref}
									/>
									<input
										type="text"
										id="email"
										placeholder={'Confirm Email'}
										onChange={e => this.setEmail2(e)}
										ref={this.email2ref}
									/>
									{emailError && validEmail && <div className="error">Emails do not match</div>}
									{!validEmail && this.email1 && <div className="error">Email is not valid</div>}
									{this.state.emailSent && (
										<div>
											<p>Email sent</p>
										</div>
									)}
									<div className="generic-button_wrapper submit">
										<div
											className={`generic-button btn solid ${
												emailError || !this.email1 || !this.email2 || this.state.sending
													? 'disabled'
													: ''
											}`}
											tabIndex={0}
											role="button">
											<div
												className="content"
												onClick={() =>
													this.requestDelegate(
														emailError || !this.email1 || !this.email2 || this.state.sending
													)
												}>
												Submit
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
	}

	render() {
		logger.log('[PDE - PlayerDelegate] render - state:%o status:%o', this.state, this.state.status);
		logger.log('[PDE - PlayerDelegate] state %o', this.state);
		const showStub = op.get(this.state, 'stubs.pde.delegates.stub', false);
		const isDelegate = checkRole(op.get(this.state, 'currentUser.roles', []), [1000280]);
		const shadowingAccess = op.get(this.state.currentUser, 'data.systemRole.player.isShadowingAccess', false);
		if (isDelegate && shadowingAccess) {
			return (
				<div className="column-layout">
					<div className="two-col margin-col center">
						Unfortunately you are unable to manage player delegates while shadowing a player.
					</div>
				</div>
			);
		} else if (!this.state.gotData || this.state.reloading) {
			return <LoadingIndicator />;
		} else {
			if (!showStub) {
				return this.renderDelegate();
			} else {
				return this.showStub();
			}
		}
	}
}

PlayerDelegate.contextType = PlayerDigitalExperienceContext;
export default connect(mapStateToProps, mapDispatchToProps)(PlayerDelegate);

import { getJson } from 'appdir/components/general/Util/Services';

/**
 * fetch video json file
 *   - 2019, trying new pattern to use utility Services for axios requests
 * @param {String} path
 */
const fetch = path => {
	return new Promise((resolve, reject) => {
		getJson(path)
			.then(result => {
				resolve(result);
			})
			.catch(error => {
				reject(error);
			});
	});
};


export default {
	fetch,
};

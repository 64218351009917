import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	openScreen: (data, cb) => dispatch(deps.actions.Gigya.openScreen(data, cb)),
	closeScreen: data => dispatch(deps.actions.Gigya.closeScreen(data)),
});

class GigyaScreenSet extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.load = false;

		this.gigyaCb = this.gigyaCb.bind(this);
		logger.log('[GigyaScreenSet] constructor - state:%o', this.state);
	}

	componentWillUnmount() {
		logger.log('[GigyaScreenSet] componentWillUnmount');
		let selector = this.state.holder ? this.state.holder : 'screen-holder';
		this.props.closeScreen({ containerId: selector });
	}

	componentDidMount() {
		let selector = this.state.holder ? this.state.holder : 'screen-holder';
		let holder = document.querySelector('#' + selector);

		//logger.log('[GigyaScreenSet] componentDidMount - load:%o state:%o holder:%o', this.load, this.state, holder);

		if (!this.load && this.state.gigyaLoaded && this.state.params && holder) {
			this.load = true;
			let params = this.state.params;
			if (this.state.inline) {
				params.holder = selector;
			}

			logger.log('[GigyaScreenSet] componentDidMount - state:%o params:%o holder:%o', this.state, params);
			this.props.openScreen(params, this.gigyaCb);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	gigyaCb(evt) {
		logger.log('[GigyaScreenSet] gigyaCb evt:%evt', evt);
	}

	componentDidUpdate() {
		let selector = this.state.holder ? this.state.holder : 'screen-holder';
		let holder = document.querySelector('#' + selector);

		//logger.log('[GigyaScreenSet] componentDidUpdate');

		if (!this.load && this.state.gigyaLoaded && this.state.params && holder) {
			this.load = true;
			let params = this.state.params;
			if (this.state.inline) {
				params.holder = selector;
			}

			logger.log('[GigyaScreenSet] componentDidUpdate - state:%o params:%o holder:%o', this.state, params);
			this.props.openScreen(params, this.gigyaCb);
		}
	}

	render() {
		let selector = this.props.holder ? this.props.holder : 'screen-holder';
		//logger.log('[GigyaScreenSet] render');
		return <div id={selector} className="screen-holder" />;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GigyaScreenSet);

/**
 DrawsArchiveEvents Events Dropdown
 From all the events data, list out only one event per event
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import indexOf from 'lodash/indexOf';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.DrawsArchiveEvents.filter(settings)),
});

class SelectEvent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.setEvent = this.setEvent.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.eventsDropdownVals = { options: [] };

		// create a list of events to populate in the dropdown
		this.setDropdownVal();

		logger.log('[DrawsArchiveSelectEvent] constructor - state: %o, props: %o', this.state, props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	setEvent(event, name) {
		logger.log('[EventSelect] setEvent:%o', event);

		MeasurementUtils.dispatchMeasurementCall('drawsArchiveEventsPageEventSelect', {
			event,
			name,
		});

		this.props.filter({
			eventId: event,
			open: '',
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	setDropdownVal() {
		if (this.state.eventsAll !== '') {
			const events = this.state.eventsAll;

			// excludes qualifying events
			// const excludeList = ['QD', 'QS', 'RD', 'RS', 'NL', 'NM', 'NE', 'ND', 'NX'];
			// doesn't exclude qualifying events
			const excludeList = ['NL', 'NM', 'NE', 'ND', 'NX'];

			let eventsList = [];
			let event_id;
			let event_name;

			// using ES5 here as .map or foreach didn't return anything for some reason
			// add each unique event to eventsDropdownVals
			for (var j = 0; j < events.length; j++) {
				for (var l = 0; l < events[j].events.length; l++) {
					event_id = events[j].events[l].eventId;
					event_name = events[j].events[l].eventName;

					// if eventId is not in eventsDropdownVals yet, add it to the dropdown
					if (indexOf(eventsList, event_id) === -1 && indexOf(excludeList, event_id) === -1) {
						eventsList.push(event_id);
						this.eventsDropdownVals.options.push({ value: event_id, name: event_name });
					}
				}
			}
		}
	}

	render() {
		logger.log('[DrawsArchiveSelectEvent] render - state: %o', this.state);
		if (this.state.eventsAll !== '') {
			return (
				<div className="content-filters">
					<div className="news-landing-time-selector">
						<SelectMenu
							name="events"
							attributes={this.eventsDropdownVals}
							selected={this.state.filters.eventId}
							show={this.state.filters.open == 'events'}
							onSelected={this.setEvent}
							onOpen={this.onOpen}
						/>
					</div>
				</div>
			);
		} else {
			return <div />;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectEvent);

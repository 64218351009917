import ContentPageWebview from './index';
import deps from 'dependencies';

export default [
	{
		path: '/webview/:lang/:landing(about_wimbledon|jobs|foundation|tickets|media|learning|your_visit|foodanddrink|museum_and_tours|debentures|hospitality|aboutmywimbledon)/:id(index).html',
		//order: 10,
		exact: true,
		component: ContentPageWebview,
		load: params => deps.actions.ContentPageWebview.mount(params),
	},
	{
		path: '/webview/:lang/:category(amexverify|about_wimbledon|draws_archive|jobs|foundation|tickets|visit_and_tickets|wimbledon_foundation|atoz|debentures|hospitality|aboutmywimbledon)/:id.html',
		//order: 11,
		exact: true,
		component: ContentPageWebview,
		load: params => deps.actions.ContentPageWebview.mount(params),
	},
	{
		path: '/webview/:lang/news/:category(articles)/:date?/:id.html',
		exact: true,
		component: ContentPageWebview,
		load: params => deps.actions.ContentPageWebview.mount(params),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import op from 'object-path';
import JSXParser from 'react-jsx-parser';
import parse from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchOverview
 * -----------------------------------------------------------------------------
 */

export default class MatchOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			//this.state.mount(this);
		}
	}

	componentDidUpdate() {
		//logger.log('[MatchOverview] ComponentDidUpdate - state:%o', this.state);
	}

	render() {
		// logger.log('[MatchOverview] render - state:%o', this.state);
		//logger.log('[MatchOverview] render - props:%o', this.props);
		//let html = /*ReactHtmlParser*/parse(this.props.data.overview);
		//logger.log('[MatchOverview] render - overview html:%o', html);
		let overviewHeight =
			null !== this.props.data.height && this.props.data.height > 0
				? `${this.props.data.height - 38 - 22}px`
				: '';
		//logger.log('[MatchOverview] render - overview height:%o', overviewHeight);

		return (
			<div className={`matchOverview ${this.props.show ? 'show' : ''}`}>
				<div className="inner" style={{ height: overviewHeight }}>
					<JSXParser jsx={/*ReactHtmlParser*/ parse(this.props.data.overview)[0]} />
				</div>
			</div>
		);
	}
}

MatchOverview.defaultProps = {
	data: {
		overview: '',
	},
};

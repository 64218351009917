/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Fragment } from 'react';
import JSXParser from 'react-jsx-parser';
import Translate from 'appdir/components/pages/Slamtracker/Translate';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ServeStats
 * -----------------------------------------------------------------------------
 */
const ServeStats = props => {
	// logger.log('[ServeStats] props: %o', props);

	const serve_stat_rows = [
        'aces',
        'service_winners',
        'double_faults',
        'total_points',
        'total_points_won',
        'service_games',
        'average_serve_speed',
        'fastest_serve_speed',
    ];

	let names = props.data.names;
	let t1_stats = props.data.t1;
	let t2_stats = props.data.t2;
	let doubles = props.data.doubles;

	let t1_first, t1_second, t1_total;
	let t2_first, t2_second, t2_total;

	const getStatData = (stat) => {
		switch (stat) {
			case 'aces':
				t1_first = t1_stats?.f_srv_ace == null ? '-' : t1_stats?.f_srv_ace;
				t1_second = t1_stats?.s_srv_ace == null ? '-' : t1_stats?.s_srv_ace;
				t1_total = t1_stats?.t_ace == null ? '-' : t1_stats?.t_ace;
				t2_first = t2_stats?.f_srv_ace == null ? '-' : t2_stats?.f_srv_ace;
				t2_second = t2_stats?.s_srv_ace == null ? '-' : t2_stats?.s_srv_ace;
				t2_total = t2_stats?.t_ace == null ? '-' : t2_stats?.t_ace;
				break;
			case 'service_winners':
				t1_first = t1_stats?.f_srv_wrs == null ? '-' : t1_stats?.f_srv_wrs;
				t1_second = t1_stats?.s_srv_wrs == null ? '-' : t1_stats?.s_srv_wrs;
				t1_total = t1_stats?.t_wrs == null ? '-' : t1_stats?.t_wrs;
				t2_first = t2_stats?.f_srv_wrs == null ? '-' : t2_stats?.f_srv_wrs;
				t2_second = t2_stats?.s_srv_wrs == null ? '-' : t2_stats?.s_srv_wrs;
				t2_total = t2_stats?.t_wrs == null ? '-' : t2_stats?.t_wrs;
				break;
			case 'double_faults':
				t1_first = t1_stats?.f_srv_df == null ? '-' : t1_stats?.f_srv_df;
				t1_second = t1_stats?.s_srv_df == null ? '-' : t1_stats?.s_srv_df;
				t1_total = t1_stats?.t_df == null ? '-' : t1_stats?.t_df;
				t2_first = t2_stats?.f_srv_df == null ? '-' : t2_stats?.f_srv_df;
				t2_second = t2_stats?.s_srv_df == null ? '-' : t2_stats?.s_srv_df;
				t2_total = t2_stats?.t_df == null ? '-' : t2_stats?.t_df;
				break;
			case 'total_points':
				t1_first = t1_stats?.f_srv_p == null ? '-' : t1_stats?.f_srv_p;
				t1_second = t1_stats?.s_srv_p == null ? '-' : t1_stats?.s_srv_p;
				t1_total = t1_stats?.t_p == null ? '-' : t1_stats?.t_p;
				t2_first = t2_stats?.f_srv_p == null ? '-' : t2_stats?.f_srv_p;
				t2_second = t2_stats?.s_srv_p == null ? '-' : t2_stats?.s_srv_p;
				t2_total = t2_stats?.t_p == null ? '-' : t2_stats?.t_p;
				break;
			case 'total_points_won':
				t1_first = t1_stats?.f_srv_p_w == null ? '-' : t1_stats?.f_srv_p_w;
				t1_second = t1_stats?.s_srv_p_w == null ? '-' : t1_stats?.s_srv_p_w;
				t1_total = t1_stats?.t_p_w == null ? '-' : t1_stats?.t_p_w;
				t2_first = t2_stats?.f_srv_p_w == null ? '-' : t2_stats?.f_srv_p_w;
				t2_second = t2_stats?.s_srv_p_w == null ? '-' : t2_stats?.s_srv_p_w;
				t2_total = t2_stats?.t_p_w == null ? '-' : t2_stats?.t_p_w;
				break;
			case 'service_games':
				t1_first = t1_stats?.f_srv_gms == null ? '-' : t1_stats?.f_srv_gms;
				t1_second = t1_stats?.s_srv_gms == null ? '-' : t1_stats?.s_srv_gms;
				t1_total = t1_stats?.t_gms == null ? '-' : t1_stats?.t_gms;
				t2_first = t2_stats?.f_srv_gms == null ? '-' : t2_stats?.f_srv_gms;
				t2_second = t2_stats?.s_srv_gms == null ? '-' : t2_stats?.s_srv_gms;
				t2_total = t2_stats?.t_gms == null ? '-' : t2_stats?.t_gms;
				break;
			case 'average_serve_speed':
				t1_first =
					t1_stats?.f_srv_a_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t1_stats?.f_srv_a_spd[0]}</span>{' '}
							<span className="imperial">{t1_stats?.f_srv_a_spd[1]}</span>
						</span>
					);
				t1_second =
					t1_stats?.s_srv_a_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t1_stats?.s_srv_a_spd[0]}</span>{' '}
							<span className="imperial">{t1_stats?.s_srv_a_spd[1]}</span>
						</span>
					);
				t1_total =
					t1_stats?.t_a_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t1_stats?.t_a_spd[0]}</span>{' '}
							<span className="imperial">{t1_stats?.t_a_spd[1]}</span>
						</span>
					);
				t2_first =
					t2_stats?.f_srv_a_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t2_stats?.f_srv_a_spd[0]}</span>{' '}
							<span className="imperial">{t2_stats?.f_srv_a_spd[1]}</span>
						</span>
					);
				t2_second =
					t2_stats?.s_srv_a_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t2_stats?.s_srv_a_spd[0]}</span>{' '}
							<span className="imperial">{t2_stats?.s_srv_a_spd[1]}</span>
						</span>
					);
				t2_total =
					t2_stats?.t_a_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t2_stats?.t_a_spd[0]}</span>{' '}
							<span className="imperial">{t2_stats?.t_a_spd[1]}</span>
						</span>
					);
				break;
			case 'fastest_serve_speed':
				t1_first =
					t1_stats?.f_srv_f_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t1_stats?.f_srv_f_spd[0]}</span>{' '}
							<span className="imperial">{t1_stats?.f_srv_f_spd[1]}</span>
						</span>
					);
				t1_second =
					t1_stats?.s_srv_f_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t1_stats?.s_srv_f_spd[0]}</span>{' '}
							<span className="imperial">{t1_stats?.s_srv_f_spd[1]}</span>
						</span>
					);
				t1_total =
					t1_stats?.t_f_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t1_stats?.t_f_spd[0]}</span>{' '}
							<span className="imperial">{t1_stats?.t_f_spd[1]}</span>
						</span>
					);
				t2_first =
					t2_stats?.f_srv_f_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t2_stats?.f_srv_f_spd[0]}</span>{' '}
							<span className="imperial">{t2_stats?.f_srv_f_spd[1]}</span>
						</span>
					);
				t2_second =
					t2_stats?.s_srv_f_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t2_stats?.s_srv_f_spd[0]}</span>{' '}
							<span className="imperial">{t2_stats?.s_srv_f_spd[1]}</span>
						</span>
					);
				t2_total =
					t2_stats?.t_f_spd == null ? (
						'-'
					) : (
						<span>
							<span className="metric">{t2_stats?.t_f_spd[0]}</span>{' '}
							<span className="imperial">{t2_stats?.t_f_spd[1]}</span>
						</span>
					);
				break;
			default:
				break;
		}

		return {t1_first, t1_second, t1_total, t2_first, t2_second, t2_total};
	}

	return (
		<div className="stats-table-wrapper serve">
            <div className="stats-header-container">
				<div className="stats-caption-table">
					<div className="stats-header-caption t1">
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[0]} />
						</div>
					</div>
					<div className="stats-header-caption t2">
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[1]} />
						</div>
					</div>
				</div>
				<div className="stats-header-table">
					<div className="stats-header-cell first">
						<Translate originalText="first_serve_num" />
					</div>
					<div className="stats-header-cell second">
						<Translate originalText="second_serve_num" />
					</div>
					<div className="stats-header-cell">
						<Translate originalText="total" />
					</div>
					<div className="stats-header-spacer" />
					<div className="stats-header-cell first">
						<Translate originalText="first_serve_num" />
					</div>
					<div className="stats-header-cell second">
						<Translate originalText="second_serve_num" />
					</div>
					<div className="stats-header-cell">
						<Translate originalText="total" />
					</div>
				</div>
			</div>
        
            <div className="stats-table">
                {serve_stat_rows.map((stat, index) => {
					let rowData = getStatData(stat);
                    
					return (
						<Fragment key={`${stat}-row${i}`}>
							<div className="stats-row" key={`${stat}-label`}>
								<div className="stats-label">
									<Translate originalText={stat} />
								</div>
							</div>
							<div className={`stats-row ${index != serve_stat_rows.length - 1 ? 'border' : ''}`} key={stat}>
								<div className="stats-data t1 first">{rowData?.t1_first}</div>
								<div className="stats-data t1 second">{rowData?.t1_second}</div>
								<div className="stats-data t1 total">{rowData?.t1_total}</div>
								<div className="stats-data-spacer" />
								<div className="stats-data t2 first">{rowData?.t2_first}</div>
								<div className="stats-data t2 second">{rowData?.t2_second}</div>
								<div className="stats-data t2 total">{rowData?.t2_total}</div>
							</div>
						</Fragment>
					)
                })}
            </div>
        </div>
		
		
	);
};

export default ServeStats;
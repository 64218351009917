import React from 'react';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';

const handleChange = date => {
	//logger.log('change - ', date);
};

const GigyaScreens = ({ data }) => {
	//logger.log('[Test- GigyaScreen] data:%o', data);

	return (
		<span className="my-wimbledon">
			{/* RegistrationCommunity
			<br /> <GigyaScreenSet params={{ screen: 'registration_address' }} holder="reg-addr" inline={true} /> <br /> */}
			Login
			<br />
			<GigyaScreenSet params={{ screen: 'login_screen' }} holder="login-comp" inline={true} />
			<br />
			Verification Code
			<br /> <GigyaScreenSet
				params={{ screen: 'verification_email_code' }}
				holder="code-verif"
				inline={true}
			/>{' '}
			<br />
			RegistrationCompletion
			<br /> <GigyaScreenSet
				params={{ screen: 'registration_completion' }}
				holder="regcom-comp"
				inline={true}
			/>{' '}
			<br />
			RegistrationSuccessPostcode
			<br />{' '}
			<GigyaScreenSet
				params={{ screen: 'registration_success_postcode' }}
				holder="reg-success-postcode"
				inline={true}
			/>{' '}
			<br />
		</span>
	);

	/* return (
		<span className="my-wimbledon">
			ForgotPasswordSuccess
			<br /> <GigyaScreenSet params={{ screen: 'forgot_password_success' }} holder="forgot-password-success" inline={true} /> <br />


			ResetPasswordSuccess
			<br /> <GigyaScreenSet params={{ screen: 'reset_password_success' }} holder="reset-password-success" inline={true} /> <br />
		
			RegistrationCompletion
			<br /> <GigyaScreenSet params={{ screen: 'registration_completion' }} holder="reg-comp" inline={true} /> <br />
			
			RegistrationCommunity
			<br /> <GigyaScreenSet params={{ screen: 'registration_community' }} holder="reg-community" inline={true} /> <br />

			LinkAccount
			<br /> <GigyaScreenSet params={{ screen: 'link_account' }} holder="link-account" inline={true} /> <br />
			
		</span>
	); */
};

export default GigyaScreens;

/*
responsive test

Login
<br /> <GigyaScreenSet params={{ screen: 'login_screen' }} holder="login-comp" inline={true} /> <br />

Registration
<br /> <GigyaScreenSet params={{ screen: 'register_screen' }} holder="reg-comp"  inline={true} /> <br />

RegistrationCompletion
<br /> <GigyaScreenSet params={{ screen: 'registration_completion' }} holder="regcom-comp" inline={true}/> <br />

*/

/*

			ChangePassword
			<br /> <GigyaScreenSet params={{ screen: 'change_password' }} holder="change-password" /> <br />
			ForgotPassword
			<br /> <GigyaScreenSet params={{ screen: 'forgot_password' }} holder="forgot-password" /> <br />
			ForgotPasswordSuccess
			<br /> <GigyaScreenSet params={{ screen: 'forgot_password_success' }} holder="forgot-password-success" /> <br />
			ResetPassword
			<br /> <GigyaScreenSet params={{ screen: 'reset_password' }} holder="reset-password" /> <br />
			ResetPasswordSuccess
			<br /> <GigyaScreenSet params={{ screen: 'reset_password_success' }} holder="reset-password-success" /> <br />
			RegistrationCompletion
			<br /> <GigyaScreenSet params={{ screen: 'registration_completion' }} holder="reg-comp" /> <br />
			Login
			<br /> <GigyaScreenSet params={{ screen: 'login_screen' }} holder="login-comp" /> <br />
			Registration
			<br /> <GigyaScreenSet params={{ screen: 'register_screen' }} holder="registration" /> <br />

			LinkAccount
			<br /> <GigyaScreenSet params={{ screen: 'link_account' }} holder="link-account" /> <br />

*/

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';
import findIndex from 'lodash/findIndex';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import MeasurementUtils from 'appdir/lib/analytics';
import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import WimLink from 'appdir/components/general/WimLink';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import isEmpty from 'lodash/isEmpty';
import Footer from 'appdir/components/general/Footer';
import ScrollHandler from 'appdir/components/general/ScrollHandler';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import NewsTile from 'appdir/components/content/NewsTile';
import NewsFilter from './NewsFilter';
import TimeSelect from './TimeSelect';
import LazyPlaceholder from 'appdir/components/content/LazyPlaceholder';
import LazyLoad from 'react-lazy-load';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: NewsIndex
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['NewsIndex'],
		...props,
		Gigya: state['Gigya'],
		favourites: state['Controller'].favourites.players,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.NewsIndex.mount()),
	filter: settings => dispatch(deps.actions.NewsIndex.filter(settings)),
});

class NewsIndex extends Component {
	constructor(props) {
		super(props);

		logger.log('[NewsIndex] constructor - props:%o ', props);

		this.state = {
			...props,
			timeSelectParams: {},
		};

		this.count = 0;
		this.max = 2000;
		this.loadedJson = ''; //keep track of last loaded json url
		this.initialLoad = true;
		this.fetchingData = false;
		this.filterType = '';
		// this.state.timeSelectParams = {};
		this.getTimeSelectParams = true;
		// this.getContent= true;
		this.promo;
	}

	componentDidMount() {
		//this.props.mount();
		logger.log('[NewsIndex] componentDidMount - state:%o', this.state);

		//let self = this;
		// window.onpopstate = (event) => {
		// 	//logger.log('[NewsIndex] onpopstate - state:%o this:%o', event.state, self);
		// 	this.popstate = true;
		// 	popstateAction = true;
		// };
	}

	componentWillUnmount() {
		this.props.filter({
			type: '',
			open: '',
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	componentWillUpdate(nextProps, nextState) {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[NewsIndex] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		let url = ''; // redirect url
		let data = this.state.data; // json data
		//logger.log('[NewsIndex] componentWillReceiveProps - status:%o state:%o next:%o', nextProps.status, this.state.year, nextProps.year);
		//logger.log('[NewsIndex] componentWillReceiveProps - status:%o state:%o next:%o', nextProps.status, this.state.filters.year, nextProps.filters.year);

		// check if filter change requires a redirect to a new url

		if (this.state.filters.year != nextProps.filters.year) {
			url = '/en_GB/news/index.html';
			if (nextProps.filters.year != 'current') {
				url = '/en_GB/news/' + nextProps.filters.year + '.html';
			}
			data = null;
		}

		// if the filter value has change, push new url to history
		if (this.state.filters.type != nextProps.filters.type) {
			//this.state.history.push('/en_GB/news/index.html?filter=' + nextProps.filters.type);
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				nav: url,
				data: data,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[NewsIndex] componentDidUpdate - 1 - state:%o loadedJson:%o', this.state, this.loadedJson);

		if (this.state.status == 'none') {
			return;
		}

		// set default data path based on currently filtered yet
		//   data files are year based or we use related content
		let dataPath = this.state.path.replace('<section>', () => {
			return !this.state.archive ? 'news_current' : 'news_' + this.state.filters.year;
		});

		let type = this.getFilter(this.state.location.search);
		let year = op.get(this.props, 'filters.year', false);

		//measure if filter type different
		logger.log('[NewsIndex] componentDidUpdate - type:%o, filters:%o', type, this.state.filters);

		if (type != this.state.filters.type) {
			MeasurementUtils.dispatchMeasurementCall('selectContent', {
				yearSelected: year,
				selectedDay: this.state.filters.day,
				category: type,
			});
		}

		let prevYear = op.get(prevProps, 'filters.year', false);
		if (year && prevYear && year != prevYear) {
			MeasurementUtils.dispatchMeasurementCall('selectYear', {
				yearSelected: year,
				selectedDay: this.state.filters.day,
				category: type,
			});
		}

		//logger.log('[NewsIndex] componentDidUpdate - time:%o', this.state.timeSelectParams);

		// if have the time select days, meaning news_* file has loaded,
		//   and favorites filter, set related content path for favorites
		if (this.state.timeSelectParams['days']) {
			if (type == 'favorite' || type == 'mycontent') {
				let startDay, endDay, year;
				let favPlayersParam = this.state.favourites.join('&tags=');
				if (type == 'mycontent') {
					let roles = op.get(this.state, 'Gigya.currentUser.roles', []);
					roles = roles.filter(role => {
						if (role.roleId == 1000050) {
							return true;
						} else {
							return false;
						}
					});

					if (!isEmpty(roles)) {
						let ibmTourId = op.get(roles, '0.contactDetails.ibmTourId', '');
						logger.log('[NewsIndex] componentDidUpdate - roles:%o', roles);
						favPlayersParam = ibmTourId;
					}
				}
				dataPath = this.state.relatedContentPath.replace('<tags>', favPlayersParam);
				logger.log('[NewsIndex] componentDidUpdate - dataPath:%o', dataPath);
				if (this.state.filters.day !== 'all') {
					startDay = this.state.timeSelectParams.days
						.filter(d => d.id == this.state.filters.day)[0]
						.date.trim();
					endDay = startDay;
				}
				if (this.state.filters.year !== 'current' && this.state.filters.day === 'all') {
					startDay = `${this.state.filters.year}-01-01`;
					endDay = `${this.state.filters.year}-12-31`;
				}
				if (startDay && endDay) {
					dataPath = `${dataPath}&startDate=${startDay}&endDate=${endDay}`;
				}
			}
		}

		logger.log(
			'[NewsIndex] componentDidUpdate - archive:%o diff:%o path:%o',
			this.state.archive,
			dataPath != this.loadedJson,
			dataPath
		);

		// if the data path is not what was last loaded, load now
		if (dataPath && dataPath != this.loadedJson) {
			this.loadedJson = dataPath;

			deps.services.NewsIndex.fetch(dataPath).then(result => {
				//logger.log('[VideoIndex] componentDidUpdate - data:%o', result);
				if (type === 'favorite' || type === 'mycontent') {
					if (result.content.length > 0) {
						result.content = result.content.map(d => {
							return { ...d, date: d.displayDate, images: d.images[0] };
						});
					}
				}
				let promo = this.state.promo;
				if (result && result.promos && result.promos.length > 0) {
					promo = result.promos[0];
				}
				this.setState({
					timeSelectParams: {
						days: result.days || this.state.timeSelectParams.days,
						years: result.years || this.state.timeSelectParams.years,
					},
					data: result,
					promo: promo,
				});

				this.logItem('first', result.content[0]);
				this.logItem('last', result.content[result.content.length - 1]);

				if (result && result.promos && result.promos.length > 0) {
					this.promo = result.promos[0];
				}
			});
		}

		// check current filter from qs against state and update redux store if needed
		//logger.log('[NewsIndex] componentDidUpdate - type:%o', type);
		if (type != this.state.filters.type) {
			this.props.filter({
				type: type,
				open: '',
			});
		}

		let evt = window.document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 99);
		window.dispatchEvent(evt);
	}

	getFilter(search) {
		search = search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);
		let menu = NewsFilter.getMenuData().options;
		let index = -1;
		let value = 'all';

		if (parsedQs.filter) {
			index = findIndex(menu, function(o) {
				return o.query == parsedQs.filter.toLowerCase();
			});
			value = index > -1 ? menu[index].value : '';
		}

		return value;
	}

	getNewsItem(item, index) {
		//logger.log('[NewsIndex] getNewsItem - filter:%o type:%o day:%o', this.state.filters, item.type, item.dayId);

		let attributes = {};
		//let match = this.filterValues
		let checkType = item.type.toLowerCase();
		if (checkType == 'feature') {
			checkType = 'news';
		}

		if (
			//this.count < this.max &&
			((checkType === this.state.filters.type || this.state.filters.type === 'all') &&
				(item.dayId === this.state.filters.day || this.state.filters.day === 'all')) ||
			this.state.filters.type === 'favorite' ||
			this.state.filters.type === 'mycontent'
		) {
			attributes = { ...item };

			//convert cmsId in contentId to real contentId
			//attributes.cmsId = item.contentId;
			//attributes.contentId = null;

			// 2019 change to use cmsId
			attributes.cmsId = item.cmsId ? item.cmsId : item.contentId;
			attributes.contentId = item.contentId;

			//logger.log('[NewsIndex] getNewsItem - item:%o attributes:%o', item, attributes);

			attributes['col-style'] = 'one-col';
			attributes['filter'] = this.state.filters.type;

			//2021 add videoUrl
			if (attributes['type'] == 'video') {
				if (attributes.media) {
					let videoPath = '';
					if (attributes.media.m3u8) {
						videoPath = attributes.media.m3u8;
					} else {
						videoPath = attributes.media.mp4;
					}
					attributes['videoUrl'] = videoPath;
				}
				logger.log('[NewsIndex] getNewsItem - attributes:%o', attributes);
			}

			this.count++;

			return (
				// <div key={item.contentId}>
				//     {index + " - " + item.title + " - " + item.type}
				// </div>
				<LazyLoad
					once
					throttle={300}
					offset={1000}
					scroll={true}
					resize={true}
					key={attributes.cmsId}
					className={'one-col'}
					placeholder={<LazyPlaceholder attributes={attributes} />}>
					<NewsTile attributes={attributes} key={attributes.cmsId} />
				</LazyLoad>
			);
		} else {
			return null;
		}
	}

	getPromo(promo) {
		logger.log('[NewsIndex] getPromo - archive:%o promo:%o', this.state.archive, promo);
		if (!this.state.archive && promo) {
			return (
				<div className="landing--header-wrapper" aria-hidden tabIndex={-1} role="none">
					<div className="landing--header" aria-hidden tabIndex={-1} role="none">
						<WimLink to={promo.url} style={'white'} title="hideAria">
							<div className="landing--header-icon" aria-hidden tabIndex={-1} role="none">
								<i className="wim-icon-news" role="none" tabIndex={-1} aria-hidden="true" />
							</div>
							<div className="landing--header-date" aria-hidden tabIndex={-1} role="none">
								<h3 aria-hidden tabIndex={-1} role="none">
									<Time epoch_ms={promo.date} format="ddd DD MMM YYYY kk:mm z" options="upper" />
								</h3>
							</div>
							<div className="landing--header-title" aria-hidden tabIndex={-1} role="none">
								<h1 aria-hidden tabIndex={-1} role="none">
									{promo.title}
								</h1>
							</div>
						</WimLink>
					</div>
				</div>
			);
		} else if (this.state.archive) {
			return (
				<div className="landing--header-wrapper archive">
					<div className="landing--header">
						<div className="landing--header-title">
							<h1>{this.state.filters.year}</h1>
						</div>
					</div>
				</div>
			);
		} else {
			return '';
		}
	}

	checkError() {
		//check if need to display error
		let error = '';
		if (this.count == 0) {
			error = 'show';
		}
		return (
			<div className={`errorMessage ${error}`} tabIndex={0}>
				There is no content for your selection.
			</div>
		);
	}

	logItem(label, item) {
		logger.log('[NewsIndex] logItem - label:%o date:%o item:%o', label, moment(item.date).toISOString(), item);
	}

	render() {
		logger.log('[NewsIndex] render - state:%o', this.state);
		let currentUser = op.get(this.state, 'Gigya.currentUser', []);
		let promo = this.state.promo;
		let header_propsData = {};

		header_propsData = {
			headerType: !this.state.archive ? 'landing' : 'newsArchive',
			imageSrc: promo ? promo.images['large'] : null,
			title: !this.state.archive ? 'NEWS' : 'NEWS ARCHIVE',
			shortTitle: '',
			titleElem: '.landing--header',
			metaTitle: !this.state.archive ? 'News' : 'News Archive',
			metaDescription: '',
			link: promo ? promo.url : null,
			metaDate: '',
			metaPlayers: '',
		};

		//logger.log('[NewsIndex] render - header:%o', header_propsData);
		logger.log('[NewsIndex] render - content:%o', op.get(this.state, 'data.content', []));

		if (this.state.nav && this.state.nav != this.state.location.pathname) {
			return <Redirect push to={this.state.nav} />;
		} else if (op.get(this.state, 'data.content', [404])[0] != 404) {
			let content = this.state.data.content;
			this.count = 0;

			//logger.log('[NewsIndex] render - promos:%o', this.state.data.promos[0]);

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<ScrollHandler hasData={true} filter={this.state.filters.type} />
					<div className="content-filters-scroll home" />
					<div className="content-main landing news">
						<noindex>
							{this.getPromo(promo)}
							<div className="content-filters-scroll" />
							<div className="content-filters news">
								<TimeSelect
									years={this.state.timeSelectParams.years}
									days={this.state.timeSelectParams.days}
									filters={this.state.filters}
								/>
								<NewsFilter
									filters={this.state.filters}
									basePath={this.state.location.pathname}
									currentUser={currentUser}
								/>
							</div>
							<div className="content-filters-spacer" />
							<div className="column-layout">
								<ErrorBoundary message="Error getting news items">
									{content.map((item, index) => this.getNewsItem(item, index))}
								</ErrorBoundary>
							</div>

							{this.checkError()}
						</noindex>
					</div>
					<Footer />
				</Template>
			);
		} else {
			header_propsData = {
				headerType: 'generic',
				title: 'NEWS',
				shortTitle: '',
				titleElem: '.landing--header',
				metaTitle: 'News',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<ScrollHandler hasData={false} filter={this.state.filters.type} />
					<div className="content-filters-scroll home" />
					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsIndex);

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, _, store) => {
		let measurementUtilsPageTitle =
			params?.page === 'court-availability'
				? 'Court Status'
				: params?.page === 'address-book'
				? 'Address Book'
				: params?.page === 'book'
				? 'My Bookings'
				: params?.page === 'make-reservation' && 'Book a Restaurant';
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: measurementUtilsPageTitle,
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[Member] action.mount - params:%o store:%o', params, Config);
			let data = {
				member: {
					...params,
				},
			};
			data.roleIds = [1000040];
			data.screen = screen = {
				register: false,
				social: false,
			};
			data.category = 's_member';

			dispatch({
				type: deps.actionTypes.MEMBER_MOUNT,
				data: data,
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MEMBER_UNMOUNT });
	},
};

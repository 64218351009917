import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getDrawPath, getDrawAnalysisLabel, getDrawAnalysisLevel2 } from 'appdir/components/data/Tournament/Util';

const topCategory = 10;
const bottomCategory = 2;

export default (state = {}, action) => {
	let newState;

	//logger.log("[Tournament] reducer - action:%o state:%o", action, state);

	switch (action.type) {
		case deps.actionTypes.TOURNAMENT_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[Tournament] deps.actionTypes.TOURNAMENT_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.TOURNAMENT_LOADING:
			newState = {
				...state,
				data: getDataObj(action, state),
			};
			logger.log('[Tournament] reducer.TOURNAMENT_LOADING - newState:%o:', newState);
			return newState;

		case deps.actionTypes.TOURNAMENT_UPDATE:
			//logger.log('[Tournament] reducer.TOURNAMENT_UPDATE - action:%o:', action);
			newState = {
				...state,
				data: getDataObj(action, state),
			};
			//logger.log('[Tournament] reducer.TOURNAMENT_UPDATE - newState:%o:', newState);
			return newState;

		case deps.actionTypes.TOURNAMENT_CLEAR:
			newState = {
				...state,
				data: getDataObj({ key: 'clear' }, state),
			};
			logger.log('[Tournament] reducer.TOURNAMENT_CLEAR - newState:%o:', newState);
			return newState;

		case deps.actionTypes.TOURNAMENT_CLEAR_SCHEDULE:
			newState = {
				...state,
				data: getDataObj({ key: 'clear_schedule' }, state),
			};
			logger.log('[Tournament] reducer.TOURNAMENT_CLEAR_SCHEDULE - newState:%o:', newState);
			return newState;
	
		case deps.actionTypes.TOURNAMENT_CLEAR_RESULTS:
			newState = {
				...state,
				data: getDataObj({ key: 'clear_results' }, state),
			};
			logger.log('[Tournament] reducer.TOURNAMENT_CLEAR_RESULTS - newState:%o:', newState);
			return newState;
		
		case deps.actionTypes.TOURNAMENT_CLEAR_DRAWS:
			newState = {
				...state,
				data: getDataObj({ key: 'clear_draws' }, state),
			};
			logger.log('[Tournament] reducer.TOURNAMENT_CLEAR_DRAWS - newState:%o:', newState);
			return newState;	

		case deps.actionTypes.TOURNAMENT_ERROR:
			//logger.log('[Tournament] reducer.TOURNAMENT_ERROR - action:%o', action);

			newState = {
				...state,
				data: getDataObj(action, state),
			};
			logger.log('[Tournament] reducer.TOURNAMENT_ERROR - newState:%o:', newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_UPDATE_SCORES_DATA:
			// logger.log(
			// 	'[Tournament] reducer.UPDATE_SCORES_DATA - schedule:%o live:%o',
			// 	state.data.schedule,
			// 	action.data
			// );

			let scheduleData = updateScheduleMatches(state.data.scheduleScores.data, action.data);

			newState = {
				...state,
				data: updateDataObj('live', state, {
					live: {
						matches: action.data,
						status: 'loaded',
						updated: moment().toISOString(),
					},
					scheduleScores: {
						...state.data.scheduleScores,
						data: scheduleData,
					},
				}),
			};

			logger.log('[Tournament] reducer.UPDATE_SCORES_DATA - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};

/**
 * Construct new state objects, maintaining existing state data
 * @param {*} state
 * @param {*} data
 * @returns
 */
const updateDataObj = (key, state, data) => {
	let newData = {
		...state.data,
		...data,
	};
	return newData;
};

const getDataObj = (action, state) => {
	let updateData, updateAnalysis;
	let len = action.key.indexOf('_') > 0 ? action.key.indexOf('_') : action.key.length;
	let key = action.key.substr(0, len);
	let type = action.key.substr(len + 1, action.key.length);

	switch (key) {
		case 'scheduleDays':
			return updateDataObj(key, state, {
				scheduleDays: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'resultDays':
			return updateDataObj(key, state, {
				resultDays: {
					data: action.data?.eventDays || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'schedule':
			return updateDataObj(key, state, {
				schedule: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'scheduleScores':
			let scheduleData = updateScheduleMatches(action.data, state?.data?.live?.matches);
			return updateDataObj(key, state, {
				scheduleScores: {
					data: scheduleData || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'results':
			let matches = updateResultMatches([], action.data?.matches || []);
			return updateDataObj(key, state, {
				results: {
					matches: matches || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'drawEvents':
			return updateDataObj(key, state, {
				drawEvents: {
					data: action.data?.draws || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'draws':
			return updateDataObj(key, state, {
				draw: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
				drawPath: {},
			});
			break;
		case 'drawPath':
			updateData =
				action.status == 'loaded'
					? updateDrawPath(action.data, action.data?.players, action.data?.player)
					: false;
			return updateDataObj(key, state, {
				drawPath: {
					data: updateData?.data || [],
					player: action.data?.player,
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'drawAnalysis':
			updateData =
				action.status == 'loaded' ? updateDrawAnalysis(action.data?.data, action.data?.players) : false;
			return updateDataObj(key, state, {
				drawAnalysis: {
					data: updateData || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'powerIndex':
			updateData =
				action.status == 'loaded' ? updatePowerIndex(action.data?.data, action.data?.drawAnalysis) : false;
			return updateDataObj(key, state, {
				powerIndex: {
					data: updateData.players || [],
					publicationTimestamp: updateData.publicationTimestamp,
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'players':
			//updateData = action.status == 'loaded' ? updateDrawPath(state.data?.drawPath, action.data?.players) : state.data?.drawPath;
			//updateAnalysis = action.status == 'loaded' ? updateDrawAnalysis(state.data?.drawAnalysis, action.data?.players) : state.data?.drawAnalysis;
			return updateDataObj(key, state, {
				players: {
					data: action.data?.players || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'playerDetail':
			return updateDataObj(key, state, {
				playerDetail: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'playerGen':
			return updateDataObj(key, state, {
				playerGen: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'playerStats':
			return updateDataObj(key, state, {
				playerStats: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'extraStats':
			return updateDataObj(key, state, {
				extraStats: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'daySummaryGen':
			return updateDataObj(key, state, {
				daySummaryGen: {
					data: action.data || [],
					status: action.status,
					updated: action.updated,
				},
			});
			break;
		case 'clear':
			switch(type) {
				case 'schedule':
					return updateDataObj(key, state, {
						schedule: {},
						scheduleDays: {},
						scheduleScores: {},
					});
				case 'results':
					return updateDataObj(key, state, {
						results: {},
						resultDays: {},
						});
				case 'draws':
					return updateDataObj(key, state, {
						draw: {},
						drawEvents: {},
						drawPath: {},
						drawAnalysis: {},
					});
				default:
					return {
						live: {
							matches: [],
						},
						results: {},
						resultDays: {},
						schedule: {},
						scheduleDays: {},
						scheduleScores: {},
						daySummaryGen: {},
						draw: {},
						drawEvents: {},
						drawPath: {},
						drawAnalysis: {},
						extraStats: {},
						playerStats: {},
						players: {},
						playerDetail: {},
						playerGen: {},
						powerIndex: {},
					};
			}
			break;
		default:
			break;
	}
};

/**
 * update power index list with draw analysis data
 * @param {*} data
 * @param {*} state
 * @returns
 */
const updatePowerIndex = (data, draw) => {
	if (data && draw) {
		data.players.forEach((player, i, a) => {
			draw.forEach(drawPlayer => {
				if (player.playerid == drawPlayer.playerId) {
					player.drawAnalysis = {
						drawLabel: getDrawAnalysisLabel(drawPlayer.difficultyCategory),
						drawLevel: getDrawAnalysisLevel2(drawPlayer.drawRank, drawPlayer.currentRound),
					};
				}
			});
		});
	}
	return data;
};

/**
 * path data with bio info
 * @param {*} data
 * @param {*} state
 * @returns
 */
const updateDrawPath = (data, players, drawAnalysisPlayer) => {
	if (data && players) {
		let player = players.filter(player => {
			return player.id == data.player.idA;
		});
		if (player.length > 0) {
			data.player.singlesRank = player[0].singles_rank;
			data.player.doublesRank = player[0].doubles_rank;
		}
	}

	logger.log('[Tournament] updateDrawPath - data:%o drawAnalysisPlayer:%o', data, drawAnalysisPlayer);

	return data;
};

/**
 * clean draw analysis data
 * used for overal draw score data so don't need matches
 * @param {*}
 * @returns
 */
const updateDrawAnalysis = (data, players) => {
	//logger.log('[Tournament] updateDrawAnalysis - data:%o players:%o', data, players);

	if (players) {
		let player = players.filter(player => {
			return player.id == data.playerId;
		});
		if (player.length > 0) {
			data.displayName = player[0].tv_name;
		}
	}

	return data;
};

/**
 * update schedule matches with additional data
 *   calculate new and clean data values
 * @param {*} scheduleCourts
 * @param {*} liveMatches
 * @returns
 */
const updateScheduleMatches = (scheduleCourts, liveMatches) => {
	// const matchesMap = new Map(matches.map(obj => [obj.match_id, obj]));
	// const map = new Map();
	//logger.log('[Tournament] updateScheduleMatches - scheduleCourts:%o liveMatches:%o', scheduleCourts, liveMatches);

	let courts = scheduleCourts?.courts || [];

	courts.forEach(court => {
		court.matches.forEach((match, i, a) => {
			let score_match = {};
			//force for testing
			//if (true || match.shortScore == null) {

			score_match = updateScheduleScore([match], liveMatches);

			a[i] = {
				...match,
				...score_match[0],
			};
		});
	});

	return scheduleCourts;
};

/**
 * update schedule matches with live scoring data
 * @param {*} matches
 * @param {*} liveMatches
 * @returns
 */
const updateScheduleScore = (matches, updateMatches = []) => {
	//logger.log('[Tournament] reducers.updateScheduleScore - matches:%o updateMatches:%o', matches, updateMatches);

	updateMatches = updateMatches == null ? [] : updateMatches;

	//loop through base matches
	//  and assign to data from matching updateMatches
	matches.forEach((match, i, a) => {
		//find matching update match
		let found = updateMatches.filter(upd => upd.match_id == match.match_id);
		if (found.length > 0) {
			let winningTeam = findWinningTeam(found[0]);

			//update the score and names if match is in Progress
			if (found[0].statusCode == 'A') {
				a[i] = {
					...match,
					//shortScore: getShortScore(found[0], winningTeam),
					//...getScheduleNames(match, winningTeam),
					//keep in team1/team2 order for now, need to decide if flip
					shortScore: getShortScore(found[0], 1),
					status: found[0].status,
					statusCode: found[0].statusCode,
					...getScheduleNames(match, 1),
				};
			} else {
				a[i] = {
					...match,
					...getScheduleNames(match, 1),
				};
			}
		}
	});

	//convert map back to array and return
	//let returned = [...matchesMap.values()];
	return matches;
};

/**
 * update result matches
 *   add vs names and short score
 * @param {*} matches
 * @param {*} data
 * @returns
 */
const updateResultMatches = (matches, data) => {
	// logger.log('[Tournament] updateResultMatches - matches:%o, data:%o', matches, data);
	const arrays = [matches, data];
	const map = new Map();

	// iterate the arrays we pass to the function
	arrays.forEach(array => {
		// iterate the objects in each array
		array.forEach(object => {
			// set a new key/value pair for each object
			//logger.log('[Tournament] updateMatches - object:%o', object);

			object.vsNames = getVersusNames(object);
			object.shortScore = getShortScore(object);

			map.set(object['match_id'], object);
		});
	});
	// logger.log('[Tournament] updateMatches - map:%o', map.values());
	// return a new array from our map
	// let returned = [...map.values()];
	let returned = Array.from(map.values());
	// logger.log('[Tournament] updateResultMatches - returned:%o', returned);
	return returned;
};

/**
 * get string value for standard "vs" names format of team player names
 * @param {*} object
 * @returns
 */
const getVersusNames = object => {
	if (!object.team1.displayNameB) {
		return `${object.team1.displayNameA} vs. ${object.team2.displayNameA}`;
	} else {
		return `${object.team1.displayNameA}/${object.team1.displayNameB} vs. ${object.team2.displayNameA}/${object.team2.displayNameB}`;
	}
};

/**
 * Reverse teams in schedule data if the winning team is team2
 * @param {*} object
 * @returns
 */
const getScheduleNames = (object, winningTeam) => {
	let scoreNames = {};
	if (winningTeam == 1) {
		scoreNames.team1 = object.team1;
		scoreNames.team2 = object.team2;
	} else {
		scoreNames.team1 = object.team2;
		scoreNames.team2 = object.team1;
	}
	return scoreNames;
};

/**
 * generate a String value for the short score
 * if winningTeam is specified then the winning team values are first
 *    (this is how the schedule displays when match is complete so handling to maintain consistency)
 * @param {*} object
 * @param {*} winningTeam
 * @returns
 */
const getShortScore = (object, winningTeam = 1) => {
	//logger.log('[Tournament] getShortScore - object:%o', object);
	let sets = object?.scores?.sets ? object?.scores?.sets : [];

	let score = sets.reduce((prev, cur, idx, arr) => {
		//logger.log('[Tournament] getShortScore1 - prev:%o cur:%o ', prev, cur );
		let tb = '';
		let tempScore = '';
		if (parseInt(cur[0].tiebreakDisplay) && parseInt(cur[0].tiebreakDisplay) < parseInt(cur[1].tiebreakDisplay)) {
			tb = `(${cur[0].tiebreakDisplay})`;
		} else if (
			parseInt(cur[1].tiebreakDisplay) &&
			parseInt(cur[1].tiebreakDisplay) < parseInt(cur[0].tiebreakDisplay)
		) {
			tb = `(${cur[1].tiebreakDisplay})`;
		}
		//logger.log('[Tournament] getShortScore2 - normal:%o ', `${prev} ${cur[0].score}-${cur[1].score}${tb}`);
		if (winningTeam == 1) {
			return `${prev} ${cur[0].score}-${cur[1].score}${tb}`;
		} else {
			return `${prev} ${cur[1].score}-${cur[0].score}${tb}`;
		}
	}, '');

	//logger.log('[Tournament] getShortScore2 - winning:%o ', score);
	return score;
};

const findWinningTeam = object => {
	let team1 = 0;
	let team2 = 0;

	//increment team count by # of sets won
	object?.scores?.setsWon.forEach((set, i, a) => {
		if (i > 0 && set > 0) {
			set == 1 ? team1++ : team2++;
		}
	});

	//increment by game score if no winner yet
	if (object?.scores?.setsWon[0] == 0) {
		let sets = object.scores.sets;
		if (parseInt(sets[sets.length - 1][0].score) >= parseInt(sets[sets.length - 1][1].score)) {
			team1++;
		} else {
			team2++;
		}
	}

	logger.log('[Tournament] findWinningTeam - object:%o team1:%o, team2:%o', object, team1, team2);
	return team1 >= team2 ? 1 : 2;
};

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsArchiveMenu'], props);
};

const mapDispatchToProps = (dispatch, props) => ({});

const menuPaths = {
	year: '/en_GB/draws_archive/index.html',
	facts: '/en_GB/draws_archive/fast_facts.html',
	stats: '/en_GB/draws_archive/topstats.html',
	events: '/en_GB/draws_archive/events.html',
	champions: '/en_GB/draws_archive/champions/champions.html',
};

class DrawsArchiveMenu extends Component {
	constructor(props) {
		super(props);

		let selectedMenu = '';
		for (let prop in menuPaths) {
			if (props.location == menuPaths[prop]) {
				selectedMenu = prop;
			}
		}

		this.state = {
			...this.props,
			value: '',
			isHidden: '',
			loadResults: false,
			mobileMenu: selectedMenu,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.mobileNav = this.mobileNav.bind(this);

		logger.log('[DrawsArchiveMenu] props:%o', this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[DrawsArchiveMenu] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		//logger.log('[DrawsArchiveMenu] componentDidUpdate');
		let elem = document.getElementsByClassName('player-search')[0];
		if (elem) {
			let temp_value = elem.value;
			elem.value = '';
			elem.value = temp_value;
			document.getElementsByClassName('player-search')[0].focus();
		}
	}

	showSearch(searchView) {
		//logger.log('[DrawsArchiveMenu] showSearch - searchView:%o', searchView);

		if (this.state.value != '') {
			this.loadResults();
		} else {
			this.setState({
				isHidden: searchView.searchView,
			});
			if (searchView.searchView === 'hidden') {
				MeasurementUtils.dispatchMeasurementCall('drawsArchiveSearchOpen', {});
			} else {
				MeasurementUtils.dispatchMeasurementCall('drawsArchiveSearchClose', {});
			}
		}
	}

	handleChange(evt) {
		this.setState({
			value: evt.target.value,
			loadResults: false,
		});
		//document.getElementsByClassName('player-search')[0].focus();
	}

	handleKeyPress(evt) {
		if (evt.key == 'Enter') {
			logger.log('[DrawsArchiveMenu] handleKeyPress - value:%o', this.state.value);
			MeasurementUtils.dispatchMeasurementCall('drawsArchiveSearch', { searchTerm: this.state.value });
			this.loadResults();
		}
	}

	loadResults() {
		this.setState({
			loadResults: true,
		});
	}

	mobileNav(evt) {
		let urlLink;

		if (this.state.mobileMenu != evt.target.value) {
			this.setState({
				mobileMenu: evt.target.value,
				menuLink: menuPaths[evt.target.value],
			});
		}
	}

	renderMenu() {
		let searchView = this.state.isHidden === 'hidden' ? '' : 'hidden';
		let searchClass = classNames('searchInputContainer', searchView);
		let menuView = searchView === 'hidden' ? '' : 'hidden';
		let menuClass = classNames('menuContainer', menuView);

		return (
			<div data-dna="draws_archive_menu" className="drawsArchiveHdrMenu">
				<div className="centerMenu">
					<div className={menuClass}>
						<div className="view-by-mobile">
							<select
								className="view-by-mobile-menu"
								onChange={this.mobileNav}
								value={this.state.mobileMenu}>
								<option value="year">Year</option>
								<option value="facts">Fast Facts</option>
								<option value="stats">Top Stats</option>
								<option value="events">Events</option>
								<option value="champions">Champions</option>
							</select>
						</div>

						<div className="view-by">
							<div className="view-by-label">View By:</div>
							<div className="view-by-item">
								<NavLink to="/en_GB/draws_archive/index.html" className="link">
									Year
								</NavLink>
							</div>
							<div className="view-by-item">
								<NavLink to="/en_GB/draws_archive/fast_facts.html" className="link">
									Fast Facts
								</NavLink>
							</div>
							<div className="view-by-item">
								<NavLink to="/en_GB/draws_archive/topstats.html" className="link">
									Top Stats
								</NavLink>
							</div>
							<div className="view-by-item">
								<NavLink to="/en_GB/draws_archive/events.html" className="link">
									Events
								</NavLink>
							</div>
							<div className="view-by-item">
								<NavLink to="/en_GB/draws_archive/champions/champions.html" className="link">
									Champions
								</NavLink>
							</div>
						</div>

						<div className="searchIconContainer">
							<div className="view-by-item searchIcon" onClick={() => this.showSearch({ searchView })}>
								<span className="mobileHide">Player</span>
								<div className="ar-icon inline">
									<i className="wim-icon-search" />
								</div>
							</div>
						</div>
					</div>
					<div className={searchClass}>
						<input
							type="text"
							className="player-search"
							value={this.state.value}
							onChange={this.handleChange}
							onKeyPress={this.handleKeyPress}
							placeholder="Enter Surname"
						/>
						<div className="searchIcon" onClick={() => this.showSearch({ searchView })}>
							<div className="ar-icon">
								<div>
									<i className="wim-icon-search" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		logger.log('[DrawsArchiveMenu] render - state:%o', this.state);

		if (this.state.loadResults) {
			let url = '/en_GB/draws_archive/search.html?term=' + this.state.value;

			return (
				<div>
					<div>{this.renderMenu()}</div>
					<Redirect push to={url} />
				</div>
			);
		}

		if (this.state.menuLink) {
			return <Redirect push to={this.state.menuLink} />;
		}

		return <div>{this.renderMenu()}</div>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveMenu);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { FormInput, FormRadioButton, FormFieldGroup } from 'appdir/components/common-ui/FormFields';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ProgramForm
 *
 *
 * by day disabled on 2021 in favor of by week
 * -----------------------------------------------------------------------------
 */
function calculateDays(data, year, startDate) {
	//logger.log('[ProgramForm] calculateDays - data:%o year:%o start:%o', data, year, startDate);

	let programDayArry = [];
	let firstSunday = false;
	let weekday = new Array(7);
	weekday[0] = 'Sunday';
	weekday[1] = 'Monday';
	weekday[2] = 'Tuesday';
	weekday[3] = 'Wednesday';
	weekday[4] = 'Thursday';
	weekday[5] = 'Friday';
	weekday[6] = 'Saturday';

	let month = new Array(12);
	month[0] = 'January';
	month[1] = 'February';
	month[2] = 'March';
	month[3] = 'April';
	month[4] = 'May';
	month[5] = 'June';
	month[6] = 'July';
	month[7] = 'August';
	month[8] = 'September';
	month[9] = 'October';
	month[10] = 'November';
	month[11] = 'December';

	let currDate = new Date(startDate + ' ' + year);
	for (let i = 0; i <= 13; i++) {
		let currData = {};
		if (i != 0) {
			currDate.setDate(currDate.getDate() + 1);
			// add back middle sunday
			// if (weekday[currDate.getDay()] == 'Sunday' && !firstSunday) {
			// 	currDate.setDate(currDate.getDate() + 1);
			// 	firstSunday = true;
			// }
		}

		currData = {
			...data[i],
			day: weekday[currDate.getDay()],
			date: `${currDate.getDate()} ${month[currDate.getMonth()]}`,
		};
		programDayArry[i] = currData;
	}
	return programDayArry;
}

function calculateWeeks(data, year, startDate) {
	//logger.log('[ProgramForm] calculateDays - data:%o year:%o start:%o', data, year, startDate);

	return [
		{
			...data[0],
			week: '1',
		},
		{
			...data[1],
			week: '2',
		},
	];
}

const ProgramForm = ({ formProps }) => {
	logger.log('[ProgramForm] render - formProps:%o', formProps);
	let programFormData = formProps.values.orders[0].programme;
	let year = formProps.values.orders[0].year;
	let programOrders = calculateDays(programFormData.days, year, formProps.context.dateConfig.tournStartDate);
	let programOrdersWeeks = calculateWeeks(programFormData.weeks, year, formProps.context.dateConfig.tournStartDate);

	return (
		<span className="hospContent">
			<span className="column-layout">
				<span className="field two-col">
					<FormFieldGroup id="radioGroup" label="Does the PPL invoice have to be signed off by the client?">
						<FormRadioButton name="orders[0].programme.pplInvoice" id="yes" value="yes" checked={true}>
							Yes
						</FormRadioButton>
						<FormRadioButton name="orders[0].programme.pplInvoice" id="no" value="no">
							No
						</FormRadioButton>
					</FormFieldGroup>
				</span>
				<span className="four-col table-container">
					<table className="hospitality-table hide">
						<thead>
							<tr>
								<td>Week</td>
								<td>Suite No.</td>
								<td>No. Of Programmes</td>
							</tr>
						</thead>
						<tbody>
							{programOrdersWeeks.map((d, i) => {
								return (
									<tr key={i}>
										<td>{d.week}</td>
										<td>
											<FormInput
												label=""
												name={`orders[0].programme.weeks[${i}].suite`}
												type="text"
												value={d.suite || ''}
												className="quant"
											/>
										</td>
										<td>
											<FormInput
												label=""
												name={`orders[0].programme.weeks[${i}].programmes`}
												type="text"
												value={d.programmes || ''}
												className="quant"
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<table className="hospitality-table">
						<thead>
							<tr>
								<td>Day</td>
								<td>Date</td>
								<td>Suite No.</td>
								<td>No. Of Programmes</td>
							</tr>
						</thead>
						<tbody>
							{programOrders.map((d, i) => {
								return (
									<tr key={i}>
										<td>{d.day}</td>
										<td>{d.date}</td>
										<td>
											<FormInput
												label=""
												name={`orders[0].programme.days[${i}].suite`}
												type="text"
												value={d.suite || ''}
												className="quant"
											/>
										</td>
										<td>
											<FormInput
												label=""
												name={`orders[0].programme.days[${i}].programmes`}
												type="text"
												value={d.programmes || ''}
												className="quant"
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</span>
			</span>
			<span className="column-layout no-padding">
				<span className="two-col">
					<span className="field">
						<FormFieldGroup
							id="radioGroup2"
							label="I authorize catering staff to change our programme order:">
							<FormRadioButton
								name="orders[0].programme.changeAuthorisation"
								id="yes"
								value="yes"
								checked={true}>
								Yes
							</FormRadioButton>
							<FormRadioButton name="orders[0].programme.changeAuthorisation" id="no" value="no">
								No
							</FormRadioButton>
						</FormFieldGroup>
					</span>
				</span>
				<span className="four-col ppl-info">
					If NO please note that PPL will not supply additional programmes without the consent of named
					contacts –send details by email to hospitality@thepplgroup.com
					<br />
					<br />
					If YES catering staff will sign for programmes including any additional copies ordered.
				</span>
			</span>
			<span className="column-layout no-padding">
				<span className="three-col signdate">
					<FormInput
						label="Purchase Order No."
						name="orders[0].programme.purchaseNo"
						id="programmePurchasNo"
						type="text"
						value={programFormData.purchaseNo || ''}
					/>
				</span>
				<span className="three-col signdate">
					<FormInput
						label="Name & Phone Number"
						name="orders[0].programme.name"
						id="programmeName"
						type="text"
						value={programFormData.name || ''}
					/>
				</span>
				<span className="one-col signdate">
					<FormInput
						label="Date"
						name="orders[0].programme.date"
						id="programmeDate"
						type="text"
						value={programFormData.date || ''}
					/>
				</span>
			</span>
		</span>
	);
};
export default ProgramForm;

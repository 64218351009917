import React from 'react';
import { NavLink } from 'react-router-dom';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SiteMap from 'appdir/components/general/SiteMap';

const NotFound = props => {
	let header_propsData = {
		headerType: 'generic',
		title: 'Sitemap',
		metaTitle: 'Page not found',
		metaDescription: '',
		metaDate: '',
		metaPlayers: '',
	};

	return (
		<Template>
			<Header attributes={header_propsData} />

			<PageHeader attributes={header_propsData} />

			<div className="content-main">
				<SiteMap notFound={true} />
				<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
			</div>
			<Footer />
		</Template>
	);
};

export default NotFound;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { values } from 'appdir/main';
import PageNav from 'appdir/components/common-ui/PageNav';
import DividerHeader from 'appdir/components/common-ui/DividerHeader';

import HeadtoHead from 'appdir/components/pages/Slamtracker/elements/preview/HeadtoHead';
import HeadtoHeadStats from 'appdir/components/pages/Slamtracker/elements/preview/HeadtoHeadStats';
import PastMatchUps from 'appdir/components/pages/Slamtracker/elements/preview/PastMatchUps';
import ShotQuality from 'appdir/components/pages/Slamtracker/elements/preview/ShotQuality';

import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';

import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: PreviewHeadToHead
 * -----------------------------------------------------------------------------
 */
const PreviewHeadToHead = props => {
    // logger.log('[PreviewHeadToHead] props:%o', props);

    const slamtrackerConfig = useSelector((state) => op.get(state['Config'], 'slamtracker', {}),);

    const [tab, updateTab] = useState('headtohead');

    const pageNavItems = [
		{ name: 'HEAD TO HEAD', value: 'headtohead', measure: 'Head to Head' },
		{ name: 'STATS', value: 'stats', measure: 'Stats' }
	];

    const onSelect = (val) => {
        /** find selected tab and apply measurement value */
        let metricsVal = val;
        let selectedTab = pageNavItems.find((item) => { return item.value == val});

        if(selectedTab) {
            metricsVal = selectedTab.measure
        }
        
        if (!values.webview) {
            measureInWeb({
                action: 'Preview',
                context: [{ pageTitle: 'Slamtracker' }, { tab: metricsVal }],
            });
        } else {
            measureInAppContext({
                pageTitle: 'Slamtracker',
                action: 'Preview',
                args: [metricsVal],
                context: []
            });
        }

        updateTab(val);
    }

    const { playersHaveStats } = props;

    return (
        <div className="slamtracker-headtohead-content">
            <div className="headtohead-stats-content">
                {playersHaveStats === true && slamtrackerConfig?.useHeadtoHeadStats === true ?
                    <PageNav
                        menuItems={pageNavItems}
                        classes='slamtracker-headtohead-tabs'
                        isActive={tab}
                        clickFn={onSelect}
                        noDD
                    />
                    : <DividerHeader text='Head to Head' />                    
                }
                {tab == 'headtohead' ?
                    <div>
                        <ErrorBoundary message="Error loading Head to Head">
                            <HeadtoHead doubles={props.doubles} playersHaveStats={playersHaveStats} />
                        </ErrorBoundary>
                        {slamtrackerConfig?.useHeadtoHeadStats === true && playersHaveStats === true
                            ? (
                                <ErrorBoundary message="Error loading Past Match Ups">
                                    <PastMatchUps min={slamtrackerConfig?.pastMatchupsMinCount || 2} max={slamtrackerConfig?.pastMatchupsMaxCount || 10} /> 
                                </ErrorBoundary>
                            ) : null }
                        {slamtrackerConfig?.useShotQuality === true && playersHaveStats === true ? (
                            <ErrorBoundary message="Error loading Shot Quality">
                                <ShotQuality />
                            </ErrorBoundary>
                        ) : null }
                    </div>
                    : slamtrackerConfig.useHeadtoHeadStats === true ? (
                        <ErrorBoundary message="Error loading Head to Head Stats">
                            <HeadtoHeadStats /> 
                        </ErrorBoundary>
                        ) : null 
                }
            </div> 
        </div>
    )
};

export default PreviewHeadToHead;
import deps from 'dependencies';

export default (state = {}, action) => {
	let filters;
	let newState;

	switch (action.type) {
		case deps.actionTypes.DRAWSARCHIVE_YEAR_MOUNT:
			//newState = Object.assign({}, state);
			//return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_YEAR_LOAD:
			filters = {
				...state.filters,
				year: action.data.year,
			};

			newState = {
				...state,
				...action.data,
				filters: filters,
			};
			return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_YEAR_FILTER:
			logger.log('[DrawsArchiveYear] DRAWSARCHIVE_YEAR_FILTER - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			// let drawsAllByEvent = state.drawsAllByEvent;

			// if(action.data.eventId) {
			//     drawsAllByEvent = ''
			// }

			newState = {
				...state,
				filters: filters,
				status: 'change',
				// drawsAllByEvent: drawsAllByEvent
			};

			logger.log('[DrawsArchiveYear] DRAWSARCHIVE_YEAR_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWSARCHIVE_YEAR_SET_YEAR:
			logger.log('[DrawsArchiveYear] DRAWSARCHIVE_YEAR_SET_YEAR - data:%o', action.data);
			filters = {
				...state.filters,
				...action.data,
				year: action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};
			return newState;
			break;

		default:
			return state;
	}
};

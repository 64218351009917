import React, { useState } from 'react';
import Button from 'appdir/components/common-ui/Button';
import moment from 'moment';
import WimLink from 'appdir/components/general/WimLink';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';

const BookingConfirmationDetails = props => {
	const {
		firstName,
		lastName,
		date,
		size,
		selectedTime,
		note,
		cancelBooking,
		isSimpleConfirmation,
		resId,
		service,
		onCloseModal,
		venueId,
		venueKey,
		venueTitle,
		setEditableBooking,
		editableBooking,
		updateBookingNotes,
	} = props;

	let savedNote = note?.startsWith('Client notes:') ? note?.split('Client notes:')[1] : note;

	const [updatedNote, setUpdatedNote] = useState('');

	const dispatch = useDispatch();

	let venueDetails;
	switch (venueKey) {
		case 'memberswimbledon':
			venueDetails = (
				<div className="booking-confirmation__further-info">
					<p>
						The Members’ Restaurant is open to Full Members and LTA Councillors.<br></br>
						<br></br>
						Table reservations can be made for the entire day with arrivals for lunch between 11am - 2pm. We
						kindly ask Members’ to meet their guests and enter the Members’ Enclosure as one party.<br></br>
						<br></br>A Full Member or LTA Councillor must be present at lunch to host the table.<br></br>
						<br></br>
						Table sizes of up to eight people including the Member.<br></br>
						<br></br>
						Full Members may make a maximum of four bookings per week in the Members’ Restaurant.<br></br>
						<br></br>
						If you no longer need your reservation, we request that you please cancel it.
					</p>
				</div>
			);
			break;
		case 'wimbledonmembersbrasserie':
			venueDetails = (
				<div className="booking-confirmation__further-info">
					<p>
						The Members’ Brasserie is open to Full Members, Temporary Members and LTA Councillors.<br></br>
						<br></br>
						Advance bookings can only be made for tables hosted by a Member or LTA Councillor. We kindly ask
						Members’ to meet their guests and enter the Members’ Enclosure as one party.<br></br>
						<br></br>
						Temporary Members may only pre-book tables after 1.30pm.<br></br>
						<br></br>
						Table sizes of up to six people including the Member.<br></br>
						<br></br>
						If you no longer need your reservation, we request that you please cancel it.
					</p>
				</div>
			);
			break;
		case 'testmembersdiningroom':
		case 'memberswimbledontest':
		case 'wimbledonmembersbrasserietest':
		case 'wimbledoncourtsiderestaurant':
			venueDetails = (
				<div className="booking-confirmation__further-info">
					{/* <p>
						TEST TEST TEST TEST
						<br></br>
						The ***** is open to Full Members and LTA Councillors.<br></br>
						<br></br>
						Table reservations can be made for the entire day with arrivals for lunch between 11am - 2pm. We
						kindly ask Members’ to meet their guests and enter the Members’ Enclosure as one party.<br></br>
						<br></br>A Full Member or LTA Councillor must be present at lunch to host the table.<br></br>
						<br></br>
						Table sizes of up to eight people including the Member.<br></br>
						<br></br>
						Full Members may make a maximum of four bookings per week in the Members’ Restaurant.<br></br>
						<br></br>
						If you no longer need your reservation, we request that you please cancel it.
					</p> */}
				</div>
			);
			break;
		case 'membersdiningroomuk':
			venueDetails = (
				<div className="booking-confirmation__further-info">
					<p>
						If guests are joining you for lunch or dinner, please let visitors know by registering your
						guests. After closing this confirmation, use the GUESTS tab to access Guests Requests.
					</p>
					<p>
						If you are experiencing any problems or would like to book for a larger party, please contact
						the Members' Restaurant at 0208 971 2241 or email:{' '}
						<span>
							<a href="mailto: MemberDiningReservations@aeltc.com">MemberDiningReservations@aeltc.com</a>
						</span>
					</p>
				</div>
			);
			break;
		default:
			break;
	}

	return (
		<div className="booking-confirmation">
			<div className="booking-confirmation__header">
				<p className="booking-confirmation__header--title">BOOKING CONFIRMATION</p>
				<div className="booking-confirmation__header--purple-line"></div>
				<p className="booking-confirmation__header--sub-text">
					The booking listed below is confirmed
					<p>{venueTitle}</p>
				</p>
			</div>

			<div className="booking-confirmation__details">
				<p className="booking-confirmation__details--shift">
					{service} for {size}
				</p>
				<p>
					{firstName} {lastName}
				</p>
				<p>
					{isSimpleConfirmation
						? date
						: `${moment(date).format('ddd D MMMM YYYY')} ${moment(selectedTime, 'HH:mm').format('h:mma')}`}
				</p>
				<p>
					{size} {size > 1 ? 'People' : 'Person'}
				</p>
			</div>

			<div className="booking-confirmation__notes">
				<h5 className="label purple">Notes</h5>
				<textarea
					readOnly={!editableBooking}
					value={editableBooking && updatedNote !== '' ? updatedNote : savedNote?.trimStart()} // remove any extra spaces/empty whitespace from beginning
					placeholder="None listed"
					style={{ border: isSimpleConfirmation && editableBooking ? '2px solid #00703C' : 'auto' }}
					onChange={event => {
						let newVal = event.target.value || ' ';
						setUpdatedNote(newVal);
					}}
					id="booking-confirmation__notes"
				/>
			</div>

			{venueDetails}

			<div
				className={
					isSimpleConfirmation
						? 'booking-confirmation__buttons'
						: 'booking-confirmation__buttons booking-confirmation__buttons__initial-confirmation-buttons'
				}>
				<Button
					className="btn white"
					onClick={() => {
						dispatch(
							deps.actions.ConfirmationModal.toggleModal({
								showModal: true,
								showCloseBtn: false,
								type: 'updating',
								title: 'Submitting Request',
								message: 'Submitting...',
								useTimer: false,
							})
						);
						onCloseModal();
						if (editableBooking) setEditableBooking(false);
						cancelBooking(resId, venueId);
					}}>
					Cancel Booking
				</Button>
				{isSimpleConfirmation ? (
					!editableBooking ? (
						<Button
							className="btn white"
							onClick={() => {
								setEditableBooking(true);
								const input = document.getElementById('booking-confirmation__notes');
								if (input) {
									input.selectionStart = input.selectionEnd = input.value?.length; // sets cursor at the end of the text
									input?.focus(); // focus the notes field
								}
							}}>
							Edit Notes
						</Button>
					) : (
						<Button
							className="btn white"
							onClick={() => {
								onCloseModal();
								setEditableBooking(false);
								updateBookingNotes(venueId, updatedNote || savedNote, resId);
							}}>
							Save Notes
						</Button>
					)
				) : null}
				{isSimpleConfirmation ? null : (
					<WimLink
						to="/s/member/service/book"
						style="booking-confirmation__viewAll"
						title="view all bookings">
						View All Bookings
					</WimLink>
				)}
			</div>
		</div>
	);
};

export default BookingConfirmationDetails;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { getAppLink } from 'appdir/components/general/Util';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import { getInterfaceLink } from 'appdir/components/general/Util/index.js';

/**
 * -----------------------------------------------------------------------------
 * Component: ViewMoreMatches
 * -----------------------------------------------------------------------------
 */
const ViewMoreMatches = props => {
    const dispatch = useDispatch();

	const goToMatches = path => {
        if (values.webview) {
			// let link = getAppLink(path, '');
            let interfaceLink = getInterfaceLink('liveScores');
			interfaceLink?.onclick();
		} else if (path) {
			if (props.windowSize == 'mobile') {
				dispatch(deps.actions.SlamtrackerPanel.hide());
			}
            dispatch(deps.actions.MainNav.navigate({path: path}));
		}
    }

    return (
		<div className="view-more-matches-container">
			<ButtonLinkText
				className="view-more-matches"
				onClick={() => {
					props.measureIndex(`${props.currentTab}`, 'View More Matches');
					goToMatches('/en_GB/scores/index.html');
				}}>
				View More Matches
			</ButtonLinkText>
		</div>
	);
}

export default ViewMoreMatches;

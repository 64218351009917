/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import Button from 'appdir/components/common-ui/Button';
import { useSelector } from 'react-redux';
import ContentListTile from './ContentListTile';
import isArray from 'lodash/isArray';
import clone from 'lodash/clone';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ContentList
 * -----------------------------------------------------------------------------
 */

const ContentList = props => {
	let { data } = props;
	const [showAll, setShowAll] = useState(false);
	const windowSize = useSelector(state => state['PageHeader']['windowSize']);
	// logger.log('[ContentList] - props:%o', props);
	
	const updateImagePath = (d) => {
		let { picBase } = props;
		if (d.thumb.indexOf('/') == 0 && picBase) {
			d.thumb = picBase + d.thumb;
		}

		// logger.log('[ContentList] updateImagePath - d:%o', d);
		return d;
	}

	const styleGen = style => {
		switch (style) {
			case "four-col":
				return "four-col";
				break;
			case "one-col":
				return "one-col clear-two clear-four";
				break;
			default:
				break;
		}
	}

	if(!data?.items?.content){
		return null;
	}

	let items = isArray(data?.items?.content)
		? clone(data?.items?.content)
		: [data?.items?.content];

	//make sure items element has required elements
	items = items.filter(d => {
		return d?.thumb && d?.url;
	})
	
	// logger.log('[ContentList] - items:%o', items);
	return (
		<>
			<div className={`content-list ${styleGen(data.style)}`}>
				{items.slice(0, (!showAll && windowSize == "mobile") ? 4 : items.length).map((d, i) => {
					d = updateImagePath(d);
					return <ContentListTile key={i} attributes={d} style={data.style} />;
				})}
			
				{windowSize == "mobile" && !showAll && items.length > 4 ? 
					<div className="showMore">
						<Button						
							onClick={() => 
								setShowAll(true)
							}>
							Show More
						</Button>
					</div>
				: null}
			</div>
		</>
	);
}

export default ContentList;

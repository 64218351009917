import SchedulePrintPage from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/:lang/scores/schedule/print.html'],
		exact: true,
		component: SchedulePrintPage,
		// load: params => deps.actions.SchedulePrintPage.mount(params),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import { Formik, Form } from 'formik';
import { FormInput } from 'appdir/components/common-ui/FormFields';
import Button from 'appdir/components/common-ui/Button';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: SeatingViews Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['SeatingViews'],
		imageHost: state['Config']['imageHost'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SeatingViews.mount()),
});

class SeatingViews extends Component {
	constructor(props) {
		super(props);
		if (this.props.match.params.court == 'centre') {
			this.state = {
				...props,
				currentSeat: '101',
			};
		}
		if (this.props.match.params.court == 'no1court') {
			this.state = {
				...props,
				currentSeat: '1',
			};
		}
		this.initialValues = {};
		this.onSubmit = this.onSubmit.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	activeSection(currentSeat) {
		this.setState({ currentSeat });
	}

	renderForm(formProps) {
		return (
			<Form className="gangway-input" onSubmit={formProps.handleSubmit}>
				<FormInput
					label="Gangway Number"
					name="text"
					type="text"
					placeholder="Enter Gangway Number"
					value={formProps.values ? formProps.values.text : ''}
				/>
				<Button className="solid" onClick={formProps.handleSubmit}>
					Go
				</Button>
				{/* <input type="submit" data-dna="submit" value="Go" /> */}
			</Form>
		);
	}

	renderCourtHeader(courtName) {
		if (courtName == 'centre') {
			return (
				<div className="seat-map-heading">
					Centre Court was built in 1922 and is arguably the most famous tennis court in the world. Click the
					map below to see the view of Centre Court from your seat.
				</div>
			);
		}
		if (courtName == 'no1court') {
			return (
				<div className="seat-map-heading">
					Home to many famous games, No.1 Court has a prized place in tennis history. Click the map below to
					see the view of No.1 court from your seat.
				</div>
			);
		}
	}

	renderEntranceArrow(cx, cy, rx, ry, d, transform = null) {
		return (
			<g>
				<ellipse transform={transform} className="st5" cx={cx} cy={cy} rx={rx} ry={ry} />
				<path className="st6" d={d} />
			</g>
		);
	}

	renderRectSection(x, y, w, h, transform, name) {
		let shapeClass = 'st2';
		let textClass = 'st3 st7 st8';
		if (name == this.state.currentSeat) {
			shapeClass = 'st3';
			textClass = 'st9 st7 st8';
		}
		return (
			<g id={name} onClick={this.activeSection.bind(this, name)}>
				<rect x={x} y={y} className={shapeClass} width={w} height={h} />
				<text transform={transform} className={textClass}>
					{name}
				</text>
			</g>
		);
	}

	renderPolySection(points, transform, name) {
		let shapeClass = 'st2';
		let textClass = 'st3 st7 st8';
		if (name == this.state.currentSeat) {
			shapeClass = 'st3';
			textClass = 'st9 st7 st8';
		}
		return (
			<g id={name} onClick={this.activeSection.bind(this, name)}>
				<polygon className={shapeClass} points={points} />
				<text transform={transform} className={textClass}>
					{name}
				</text>
			</g>
		);
	}

	renderPathSection(d, transform, name) {
		let shapeClass = 'st2';
		let textClass = 'st3 st7 st8';
		if (name == this.state.currentSeat) {
			shapeClass = 'st3';
			textClass = 'st9 st7 st8';
		}
		return (
			<g id={name} onClick={this.activeSection.bind(this, name)}>
				<path className={shapeClass} d={d} />
				<text transform={transform} className={textClass}>
					{name}
				</text>
			</g>
		);
	}

	renderCourt(courtName) {
		if (courtName == 'centre') {
			let shapeClass = 'st2';
			let textClass = 'st3 st7 st8';
			if (this.state.currentSeat == 'ROYAL' || this.state.currentSeat == 'ROYAL BOX') {
				shapeClass = 'st3';
				textClass = 'st9 st7 st8';
			}
			return (
				<div className="seat-map-centre seat-map two-col">
					<div className="seat-view-map">
						<svg
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 338 333"
							xmlSpace="preserve">
							<g id="entrance-arrows">
								{this.renderEntranceArrow(
									'165.4',
									'9.4',
									'7.8',
									'7.8',
									'M170.2,6.2c0,0-4.9,6.8-4.9,8.6c0-1.8-4.9-8.6-4.9-8.6s3,2.5,4.9,2.5C167,8.7,170.2,6.2,170.2,6.2z'
								)}
								{this.renderEntranceArrow(
									'258',
									'25.6',
									'7.8',
									'7.8',
									'M263.8,25.6c0,0-7.9,3-8.8,4.5c1-1.5,0.7-9.9,0.7-9.9s1.2,3.8,2.7,4.8C259.8,25.9,263.8,25.6,263.8,25.6z',
									'matrix(0.5495 -0.8355 0.8355 0.5495 94.8866 227.1012)'
								)}
								{this.renderEntranceArrow(
									'329',
									'122',
									'7.8',
									'7.8',
									'M332.2,126.9c0,0-6.8-4.9-8.6-4.9c1.8,0,8.6-4.9,8.6-4.9s-2.5,3-2.5,4.9C329.7,123.7,332.2,126.9,332.2,126.9 z'
								)}
								{this.renderEntranceArrow(
									'329',
									'201',
									'7.8',
									'7.8',
									'M332.2,205.9c0,0-6.8-4.9-8.6-4.9c1.8,0,8.6-4.9,8.6-4.9s-2.5,3-2.5,4.9C329.7,202.7,332.2,205.9,332.2,205.9 z'
								)}
								{this.renderEntranceArrow(
									'63.2',
									'25.6',
									'7.8',
									'7.8',
									'M65.1,20.1c0,0,0.3,8.4,1.4,9.8c-1.1-1.4-9.1-3.8-9.1-3.8s3.9,0.1,5.4-1C64.1,24,65.1,20.1,65.1,20.1z',
									'matrix(0.7906 -0.6123 0.6123 0.7906 -2.4317 44.0242)'
								)}
								{this.renderEntranceArrow(
									'9.6',
									'85.7',
									'7.8',
									'7.8',
									'M9.4,79.8c0,0,3.3,7.7,4.8,8.7c-1.5-0.9-9.9-0.3-9.9-0.3s3.7-1.3,4.7-2.8C9.9,83.9,9.4,79.8,9.4,79.8z',
									'matrix(0.5198 -0.8543 0.8543 0.5198 -68.5816 49.3304)'
								)}
								{this.renderEntranceArrow(
									'27.2',
									'276.8',
									'7.8',
									'7.8',
									'M21.5,275.6c0,0,8.3-1.4,9.6-2.6c-1.3,1.3-2.7,9.5-2.7,9.5s-0.4-3.9-1.7-5.2 C25.5,276.1,21.5,275.6,21.5,275.6z',
									'matrix(0.7071 -0.7071 0.7071 0.7071 -187.7693 100.275)'
								)}
								{this.renderEntranceArrow(
									'53.1',
									'313.2',
									'7.8',
									'7.8',
									'M47.4,312c0,0,8.3-1.4,9.6-2.6c-1.3,1.3-2.7,9.5-2.7,9.5s-0.4-3.9-1.7-5.2C51.5,312.5,47.4,312,47.4,312z',
									'matrix(0.7071 -0.7071 0.7071 0.7071 -205.8875 129.2944)'
								)}
								{this.renderEntranceArrow(
									'276.3',
									'310.5',
									'7.8',
									'7.8',
									'M274.2,315.9c0,0,0-8.4-1-9.9c1,1.4,9,4.2,9,4.2s-3.9-0.3-5.4,0.8C275.3,312,274.2,315.9,274.2,315.9z',
									'matrix(0.8128 -0.5826 0.5826 0.8128 -129.1448 219.0566)'
								)}
							</g>
							<g className="sections">
								{this.renderPolySection(
									'94.6,254.4 99.9,262.2 113.6,269.8 117.6,269.8 117.6,240.3 94.6,240.3 	',
									'matrix(1 0 0 1 99.9093 254.7498)',
									'101'
								)}
								{this.renderRectSection(
									'94.5',
									'216.2',
									'23.1',
									'20.5',
									'matrix(1 0 0 1 99.1055 229.3332)',
									'102'
								)}
								{this.renderRectSection(
									'94.5',
									'191.1',
									'23.1',
									'22.4',
									'matrix(1 0 0 1 99.113 205.1665)',
									'103'
								)}
								{this.renderRectSection(
									'94.5',
									'167.1',
									'23.1',
									'20.5',
									'matrix(1 0 0 1 98.8412 180.3332)',
									'104'
								)}
								{this.renderRectSection(
									'94.5',
									'143.5',
									'23.1',
									'20.5',
									'matrix(1 0 0 1 99.0773 155.8332)',
									'105'
								)}
								{this.renderPolySection(
									'147.7,125.8 121.9,125.8 116.5,102.4 147.7,102.4 	',
									'matrix(1 0 0 1 126.6668 116.611)',
									'106'
								)}
								{this.renderPolySection(
									'94.6,140.6 117.6,140.6 118,126.2 112.9,103.1 102.1,110.9 94.6,119.8 	',
									'matrix(1 0 0 1 99.1214 128.4998)',
									'107'
								)}
								{this.renderRectSection(
									'151.3',
									'102.4',
									'27.5',
									'23.4',
									'matrix(1 0 0 1 158.3066 116.611)',
									'108'
								)}
								{this.renderPolySection(
									'236.9,139.7 213.9,139.7 213.9,124.5 215.4,102.6 229.8,109.6 236.9,119.4 	',
									'matrix(1 0 0 1 217.6505 126.3332)',
									'109'
								)}
								{this.renderPolySection(
									'209.9,125.8 182.4,125.8 182.4,102.4 211.6,102.4 	',
									'matrix(1 0 0 1 190.6505 116.6108)',
									'110'
								)}
								{this.renderRectSection(
									'213.8',
									'143.4',
									'23',
									'20.3',
									'matrix(1 0 0 1 219.8426 155.6665)',
									'111'
								)}
								{this.renderRectSection(
									'213.8',
									'167.4',
									'23',
									'20.3',
									'matrix(1 0 0 1 219.0389 180.1665)',
									'112'
								)}
								{this.renderRectSection(
									'213.8',
									'191.4',
									'23',
									'20.3',
									'matrix(1 0 0 1 219.0468 203.9998)',
									'113'
								)}
								{this.renderRectSection(
									'213.8',
									'215.3',
									'23',
									'22.9',
									'matrix(1 0 0 1 218.7744 229.4998)',
									'114'
								)}
								{this.renderPolySection(
									'237,253.7 229.8,263.6 219,271.3 214,271.3 214,241.3 237,241.3 	',
									'matrix(1 0 0 1 219.0105 254.7498)',
									'115'
								)}
								{this.renderPolySection(
									'110.9,304.8 140.5,304.8 140.5,275.1 116.9,275.1 	',
									'matrix(1 0 0 1 120.0106 292.7498)',
									'201'
								)}
								{this.renderPolySection(
									'79.6,287 106.9,302.9 113.2,274 96.9,265.5 91.1,257.2 66.4,262.8 	',
									'matrix(1 0 0 1 84.0106 281.4164)',
									'202'
								)}
								{this.renderPolySection(
									'90.6,253.8 65.8,258.9 65.8,189.4 90.6,189.4 	',
									'matrix(1 0 0 1 70.0106 223.7497)',
									'203'
								)}
								{this.renderPolySection(
									'65.8,117.6 90.8,119.9 90.6,186.6 65.8,186.6 	',
									'matrix(1 0 0 1 70.0455 156.4996)',
									'204'
								)}
								{this.renderPolySection(
									'84.2,90 66.6,113.9 91.6,116.8 100.6,106.6 111.7,98.7 106.1,74.8 	',
									'matrix(1 0 0 1 83.5455 101.833)',
									'205'
								)}
								{this.renderPolySection(
									'164,98.8 115.6,98.8 109.8,74.4 164,74.4 	',
									'matrix(1 0 0 1 132.0002 88.611)',
									'206'
								)}
								{this.renderPolySection(
									'167.3,98.8 212.3,98.8 214,74.4 167.3,74.4 	',
									'matrix(1 0 0 1 180.64 88.611)',
									'207'
								)}
								{this.renderPolySection(
									'250.5,90.7 218.6,74.9 216.7,99 232.3,106 240.4,117.6 265.5,111.8 	',
									'matrix(1 0 0 1 230.8066 101.611)',
									'208'
								)}
								{this.renderPolySection(
									'266.2,115.8 241.2,121.3 241.4,185.5 266.2,185.5 	',
									'matrix(1 0 0 1 244.64 155.7777)',
									'209'
								)}
								{this.renderPolySection(
									'241.3,253.9 234.5,264.8 252.8,282.8 266,259 266,189.5 241.3,189.5 	',
									'matrix(1 0 0 1 246.14 229.7777)',
									'210'
								)}
								{this.renderPolySection(
									'212.9,274.9 220.5,275.2 231.7,267.2 249.5,285.3 219,304.7 212.8,304.7 	',
									'matrix(1 0 0 1 221.8439 287.8332)',
									'211'
								)}
								{this.renderRectSection(
									'191.5',
									'275.1',
									'18.4',
									'29.6',
									'matrix(1 0 0 1 194.1773 292.7499)',
									'212'
								)}
								{this.renderPolySection(
									'109.9,308 164.1,308 164.1,330.5 105.5,330.5 	',
									'matrix(1 0 0 1 127.5106 322.7499)',
									'301'
								)}
								{this.renderPolySection(
									'63.4,305 78,290.5 105.8,306.6 101.4,329.4 	',
									'matrix(1 0 0 1 78.0106 309.7498)',
									'302'
								)}
								{this.renderPolySection(
									'37.4,268.4 62.1,263.6 75.5,287.5 60,302 	',
									'matrix(1 0 0 1 51.1773 283.5831)',
									'303'
								)}
								{this.renderPolySection(
									'61.4,259.9 37,264.2 37,229 61.8,229 	',
									'matrix(1 0 0 1 40.8091 248.9164)',
									'304'
								)}
								{this.renderRectSection(
									'37',
									'189.5',
									'24.8',
									'35.9',
									'matrix(1 0 0 1 41.0455 210.7497)',
									'305'
								)}
								{this.renderPolySection(
									'36.9,114.4 62,116.8 61.7,186.5 36.9,186.5 	',
									'matrix(1 0 0 1 41.8788 156.4998)',
									'306'
								)}
								{this.renderPolySection(
									'80.2,88 62,113.5 38.4,110.4 63.4,73.1 	',
									'matrix(1 0 0 1 52.3788 99.3332)',
									'307'
								)}
								{this.renderPolySection(
									'66.2,70.1 83.2,85.9 104.7,70.6 98.6,46.3 	',
									'matrix(1 0 0 1 80.2121 70.8332)',
									'308'
								)}
								{this.renderPolySection(
									'164,70.7 108.8,70.7 103,46.3 164,46.3 	',
									'matrix(1 0 0 1 127.6668 60.5277)',
									'309'
								)}
								{this.renderPolySection(
									'167.5,70.7 214.7,70.7 216.4,46.3 167.5,46.3 	',
									'matrix(1 0 0 1 184.4733 60.5277)',
									'310'
								)}
								{this.renderPolySection(
									'252.3,86.7 218.8,69.5 220.6,45.7 267.1,69.6 	',
									'matrix(1 0 0 1 232.8066 70.611)',
									'311'
								)}
								{this.renderPolySection(
									'269.8,111.1 254.8,89.2 270.9,71.3 290.3,105.6 	',
									'matrix(1 0 0 1 265.3066 98.111)',
									'312'
								)}
								{this.renderPolySection(
									'292,149.5 292,109.3 269.9,115 269.9,149.5 	',
									'matrix(1 0 0 1 274.3066 134.4443)',
									'313'
								)}
								{this.renderPolySection(
									'292,152.7 291.9,185.7 270,185.7 269.9,152.8 	',
									'matrix(1 0 0 1 274.14 172.611)',
									'314'
								)}
								{this.renderPolySection(
									'291.9,258 269.9,258 269.9,189.5 291.9,189.5 	',
									'matrix(1 0 0 1 274.14 225.611)',
									'315'
								)}
								{this.renderPolySection(
									'255.2,286 269.2,261.5 291.6,261.5 277,294.5 268.6,300 	',
									'matrix(1 0 0 1 266.1079 279.9443)',
									'316'
								)}
								{this.renderPolySection(
									'220.8,308 225.9,329.4 265.5,302.5 252,288.2 	',
									'matrix(1 0 0 1 235.3439 309.8332)',
									'317'
								)}
								{this.renderPolySection(
									'217.1,308 167.4,308 167.4,330.5 221.7,330.5 	',
									'matrix(1 0 0 1 187.1773 322.7498)',
									'318'
								)}
								{this.renderRectSection(
									'8.2',
									'237',
									'25.6',
									'27.4',
									'matrix(1 0 0 1 13.6774 253.8331)',
									'501'
								)}
								{this.renderRectSection(
									'8.2',
									'206.3',
									'25.6',
									'27.4',
									'matrix(1 0 0 1 12.6774 222.6665)',
									'502'
								)}
								{this.renderRectSection(
									'8.2',
									'175.7',
									'25.6',
									'27.4',
									'matrix(1 0 0 1 12.9135 192.8331)',
									'503'
								)}
								{this.renderRectSection(
									'8.2',
									'145',
									'25.6',
									'27.5',
									'matrix(1 0 0 1 12.5108 162.4998)',
									'504'
								)}
								{this.renderRectSection(
									'8.2',
									'114.7',
									'25.6',
									'27.2',
									'matrix(1 0 0 1 12.7469 131.4998)',
									'505'
								)}
								{this.renderPolySection(
									'23.1,85.9 41.9,97.6 34,110.2 8.7,110.2 	',
									'matrix(1 0 0 1 18.5455 104.1665)',
									'506'
								)}
								{this.renderPolySection(
									'25.1,82.4 37.3,61.9 55.5,76.7 44.2,94.4 	',
									'matrix(1 0 0 1 32.7121 82.3332)',
									'507'
								)}
								{this.renderPolySection(
									'40.1,58.5 56.9,45.3 71.6,60.2 61.6,68.9 57.8,73.7 	',
									'matrix(1 0 0 1 49.2121 63.4998)',
									'508'
								)}
								{this.renderPolySection(
									'92.1,21.5 86.9,21.5 60.2,42.9 75.2,58.2 97.8,41.5 	',
									'matrix(1 0 0 1 72.7121 43.4998)',
									'509'
								)}
								{this.renderPolySection(
									'164,41.9 102.2,41.9 96.7,21.4 164,21.4 	',
									'matrix(1 0 0 1 125.5002 34.6942)',
									'510'
								)}
								{this.renderPolySection(
									'167.5,41.9 217.3,41.9 219,21.4 167.5,21.4 	',
									'matrix(1 0 0 1 187.3066 34.6945)',
									'511'
								)}
								{this.renderPolySection(
									'222.8,21.6 227.6,21.6 262.1,41.8 252.1,57 221.6,41.8 	',
									'matrix(1 0 0 1 232.8066 44.611)',
									'512'
								)}
								{this.renderPolySection(
									'266,43.5 283,53.5 270.5,67.2 255.4,58.9 	',
									'matrix(1 0 0 1 262.3066 59.611)',
									'513'
								)}
								{this.renderPolySection(
									'274.4,69 283.4,85.4 300.8,75.4 292.6,58.2 286.8,55.5 	',
									'matrix(1 0 0 1 279.64 74.611)',
									'514'
								)}
								{this.renderPolySection(
									'285,88.2 294.4,105.2 315.5,100 303.1,78 	',
									'matrix(1 0 0 1 292.64 96.7777)',
									'515'
								)}
								{this.renderPolySection(
									'316.3,120.7 295.5,120.7 295.5,108.7 316.3,103.9 	',
									'matrix(1 0 0 1 298.9982 117.611)',
									'516'
								)}
								{this.renderRectSection(
									'295.5',
									'124',
									'20.8',
									'13',
									'matrix(1 0 0 1 299.1707 133.4443)',
									'517'
								)}
								{this.renderRectSection(
									'295.5',
									'140.1',
									'20.8',
									'28.4',
									'matrix(1 0 0 1 299.1266 157.611)',
									'518'
								)}
								{this.renderRectSection(
									'295.5',
									'171.8',
									'20.8',
									'28.4',
									'matrix(1 0 0 1 298.999 189.4443)',
									'519'
								)}
								{this.renderRectSection(
									'295.5',
									'203.3',
									'20.8',
									'12.7',
									'matrix(1 0 0 1 297.9227 212.4443)',
									'520'
								)}
								{this.renderRectSection(
									'295.5',
									'218.8',
									'20.8',
									'12.4',
									'matrix(1 0 0 1 299.1544 227.7777)',
									'521'
								)}
								{this.renderRectSection(
									'295.5',
									'233.9',
									'20.8',
									'24',
									'matrix(1 0 0 1 298.3507 247.7777)',
									'522'
								)}
								{this.renderPolySection(
									'295.1,260.9 316,260.9 316,267.6 282.9,290.4 	',
									'matrix(1 0 0 1 296.2745 269.5277)',
									'523'
								)}
								<g id="ROYAL" onClick={this.activeSection.bind(this, 'ROYAL')}>
									<rect x="143.6" y="270" className={shapeClass} width="44.6" height="34.8" />
									<text transform="matrix(1 0 0 1 151.0781 286.9166)">
										<tspan x="0" y="0" className={textClass}>
											ROYAL
										</tspan>
										<tspan x="5.6" y="9.6" className={textClass}>
											BOX
										</tspan>
									</text>
								</g>
							</g>
							<g id="court">
								<rect x="131.2" y="133.9" className="st1" width="69.5" height="127" />
								<g>
									<line className="st4" x1="152.8" y1="159.5" x2="152.8" y2="235.1" />
									<line className="st4" x1="179.9" y1="159.7" x2="179.9" y2="235.3" />
									<rect x="147" y="159.6" className="st4" width="37.9" height="75.8" />
									<g>
										<line className="st4" x1="152.7" y1="177.7" x2="179.7" y2="177.7" />
										<line className="st4" x1="147" y1="197.5" x2="185" y2="197.5" />
										<line className="st4" x1="152.3" y1="217.2" x2="179.3" y2="217.2" />
									</g>
								</g>
							</g>
						</svg>
					</div>
				</div>
			);
		}
		if (courtName == 'no1court') {
			return (
				<div className="seat-map-no1court seat-map two-col">
					<div className="seat-view-map">
						<svg version="1.1" viewBox="0 0 338 333">
							<g className="court">
								<rect x="139.2" y="110.7" className="st1" width="62.5" height="114.2" />
								<g>
									<line className="st4" x1="157.3" y1="131" x2="157.3" y2="206.6" />
									<line className="st4" x1="184.4" y1="131.2" x2="184.4" y2="206.8" />
									<rect x="151.5" y="131.1" className="st4" width="37.9" height="75.8" />
									<g>
										<line className="st4" x1="157.2" y1="149.2" x2="184.2" y2="149.2" />
										<line className="st4" x1="151.5" y1="169" x2="189.5" y2="169" />
										<line className="st4" x1="156.8" y1="188.7" x2="183.8" y2="188.7" />
									</g>
								</g>
							</g>
							<g className="entrance-arrows">
								{this.renderEntranceArrow(
									'288.5',
									'50.1',
									'7.8',
									'7.8',
									'M294.2,51.3c0,0-8.3,1.4-9.6,2.6c1.3-1.3,2.7-9.5,2.7-9.5s0.4,3.9,1.7,5.2C290.2,50.8,294.2,51.3,294.2,51.3z',
									'matrix(0.7071 -0.7071 0.7071 0.7071 49.0733 218.706)'
								)}
								{this.renderEntranceArrow(
									'51.8',
									'50.1',
									'7.8',
									'7.8',
									'M53,44.4c0,0,1.4,8.3,2.6,9.6c-1.3-1.3-9.5-2.7-9.5-2.7s3.9-0.4,5.2-1.7C52.5,48.5,53,44.4,53,44.4z',
									'matrix(0.7071 -0.7071 0.7071 0.7071 -20.2691 51.2985)'
								)}
								{this.renderEntranceArrow(
									'51.8',
									'286.5',
									'7.8',
									'7.8',
									'M46.1,285.3c0,0,8.3-1.4,9.6-2.6c-1.3,1.3-2.7,9.5-2.7,9.5s-0.4-3.9-1.7-5.2C50.1,285.8,46.1,285.3,46.1,285.3z',
									'matrix(0.7071 -0.7071 0.7071 0.7071 -187.4115 120.5311)'
								)}
								{this.renderEntranceArrow(
									'288.5',
									'286.5',
									'7.8',
									'7.8',
									'M287.3,292.2c0,0-1.4-8.3-2.6-9.6c1.3,1.3,9.5,2.7,9.5,2.7s-3.9,0.4-5.2,1.7C287.8,288.1,287.3,292.2,287.3,292.2z',
									'matrix(0.7071 -0.7071 0.7071 0.7071 -118.069 287.9387)'
								)}
							</g>
							<g className="sections">
								{this.renderPolySection(
									'238.2,45.4 227.2,63.9 202.7,48.9 170.5,48.9 170.5,27.9 207.5,27.9',
									'matrix(1 0 0 1 200.2933 43.111)',
									'1'
								)}
								{this.renderPolySection(
									'190,91.4 170.3,91.4 170.3,53.4 201.3,53.4 207.3,57.7 196.4,80.3 204.3,84.7 198.2,95.5',
									'matrix(1 0 0 1 181.7933 73.9443)',
									'2'
								)}
								{this.renderPolySection(
									'237.2,87.9 243.3,77.7 211.8,59 205.5,70.7 		',
									'matrix(1 0 0 1 224.1266 77.9443)',
									'3'
								)}
								{this.renderPolySection(
									'203.3,74.3 200.7,79 209.5,84.2 200.5,97.7 223.8,111.2 240,95.3 		',
									'matrix(1 0 0 1 216.4599 99.4443)',
									'4'
								)}
								{this.renderPolySection(
									'241.8,46.8 272,64.1 289,93.3 271.9,103.9 262.4,87.4 247.8,96.9 245,91.8 240.1,89.6 248.6,75.1 230.9,65.3 		',
									'matrix(1 0 0 1 258.7933 77.9443)',
									'5'
								)}
								{this.renderPolySection(
									'226.8,113.7 243.5,98.5 266.5,137.3 243.8,144.5 		',
									'matrix(1 0 0 1 242.4599 126.111)',
									'6'
								)}
								{this.renderPolySection(
									'279,124.3 268.3,130.5 250.2,100.2 260.7,93.8 		',
									'matrix(1 0 0 1 262.1266 115.4443)',
									'7'
								)}
								{this.renderPolySection(
									'222,117.2 209,109.7 213.3,132.2 213.3,164.3 238.8,164.3 238.8,145.8 		',
									'matrix(1 0 0 1 221.7901 153.9443)',
									'8'
								)}
								{this.renderPathSection(
									'M310.8,163.8h-21.1v-21.4h-17V138l-2.8-3.6l13.8-8.8l-10.1-17.2l18-11.5l19.2,33C310.8,129.9,311,163.6,310.8,163.8z',
									'matrix(1 0 0 1 294.6266 137.2777)',
									'9'
								)}
								{this.renderPolySection(
									'267.9,192 245.9,186.6 245.9,147.6 267.4,140.8 		',
									'matrix(1 0 0 1 251.6266 171.111)',
									'10'
								)}
								{this.renderRectSection(
									'272.2',
									'146.5',
									'13.5',
									'40.1',
									'matrix(1 0 0 1 274.9599 171.111)',
									'11'
								)}
								{this.renderPolySection(
									'222,217.8 209,225.3 213.3,202.8 213.3,170.7 238.8,170.7 238.8,189.2 		',
									'matrix(1 0 0 1 220.1298 186.2777)',
									'12'
								)}
								{this.renderPolySection(
									'293.2,235.2 275,224.4 284.4,208.3 269.9,199.3 272.8,196.5 272.8,191 289.5,191 289.5,168.4 310.7,168.4 310.7,205.5 		',
									'matrix(1 0 0 1 292.4631 206.111)',
									'13'
								)}
								{this.renderPolySection(
									'244.8,235.5 267.2,196.7 244.5,190.9 227.3,221.2 		',
									'matrix(1 0 0 1 239.7964 215.111)',
									'14'
								)}
								{this.renderPolySection(
									'260.4,241 249.9,235 268.2,203.3 279.3,209.8 		',
									'matrix(1 0 0 1 259.4631 226.4443)',
									'15'
								)}
								{this.renderPolySection(
									'241.2,239.8 204.4,261.2 201.9,257 210.4,251.7 201.3,238.2 224.9,224 		',
									'matrix(1 0 0 1 215.7964 241.111)',
									'16'
								)}
								{this.renderPolySection(
									'290.8,239.8 273.7,269.6 244.5,286.2 234.8,269.2 250.7,260.1 242.2,245.1 246.3,242.9 248.5,238.1 262.8,246.4 272.5,228.9 		',
									'matrix(1 0 0 1 257.9631 264.111)',
									'17'
								)}
								{this.renderPolySection(
									'208.5,278.4 195.9,256.7 205.4,251.3 197.9,239.8 190.2,244.5 172.9,244.5 172.9,281.9 201.9,281.9 ',
									'matrix(1 0 0 1 181.2964 266.111)',
									'18'
								)}
								{this.renderPolySection(
									'205.5,265.4 239.2,247.1 245.4,258.5 211.7,276.8 		',
									'matrix(1 0 0 1 220.9631 265.111)',
									'19'
								)}
								{this.renderPolySection(
									'150.1,241.8 169.9,241.8 169.9,281.8 138.9,281.8 132.9,277.5 		',
									'matrix(1 0 0 1 151.6298 266.111)',
									'20'
								)}
								{this.renderPolySection(
									'241.2,288.2 231.3,271.1 205.2,285.6 173.2,285.6 173.2,307.2 208.2,307.2 		',
									'matrix(1 0 0 1 199.1298 297.1111)',
									'21'
								)}
								{this.renderPolySection(
									'133.8,233.2 146.9,240.2 136,261.4 101.1,241.8 104.4,236.2 125.9,247.9 		',
									'matrix(1 0 0 1 130.2964 249.9443)',
									'22'
								)}
								{this.renderPolySection(
									'102,289.8 113,271.2 137.5,286.2 169.7,286.2 169.7,307.2 132.7,307.2 		',
									'matrix(1 0 0 1 130.4631 297.1109)',
									'23'
								)}
								{this.renderPolySection(
									'74,198 95.2,190.6 103.1,203.8 89.1,212.6 101.6,233.8 98.1,238.4 		',
									'matrix(1 0 0 1 85.2964 204.9443)',
									'24'
								)}
								{this.renderPolySection(
									'103,247.2 96.9,257.5 128.4,276.1 134.6,264.5 		',
									'matrix(1 0 0 1 111.2964 264.9443)',
									'25'
								)}
								{this.renderPolySection(
									'118.1,218 131.1,225.5 126.9,203 126.9,170.9 101.4,170.9 101.4,189.4 		',
									'matrix(1 0 0 1 108.9631 186.111)',
									'26'
								)}
								{this.renderPolySection(
									'98.3,288.4 68.2,271 51.1,241.9 68.2,231.2 77.8,247.8 92.4,238.2 95.1,243.4 100,245.6 91.6,260.1 109.3,269.8 		',
									'matrix(1 0 0 1 71.9631 263.2776)',
									'27'
								)}
								{this.renderPolySection(
									'72.2,143.1 94.2,148.6 94.2,187.6 72.8,194.4 		',
									'matrix(1 0 0 1 79.2964 171.111)',
									'28'
								)}
								{this.renderPolySection(
									'61.1,210.9 71.9,204.6 90,235 79.5,241.4 		',
									'matrix(1 0 0 1 70.2964 226.111)',
									'29'
								)}
								{this.renderPolySection(
									'118.1,117.4 131.1,109.9 126.9,132.4 126.9,164.5 101.4,164.5 101.4,146 		',
									'matrix(1 0 0 1 109.1298 154.111)',
									'30'
								)}
								{this.renderPathSection(
									'M29.4,171.4h21.1v21.4h17v4.4l2.8,3.6l-13.8,8.8l10.1,17.2l-18,11.5l-19.2-33C29.4,205.2,29.2,171.6,29.4,171.4z',
									'matrix(1 0 0 1 39.9631 205.7776)',
									'31'
								)}
								{this.renderPolySection(
									'95.5,99.4 73,138.5 95.6,144.2 113,113.8 		',
									'matrix(1 0 0 1 88.7964 126.7777)',
									'32'
								)}
								{this.renderRectSection(
									'54.5',
									'148.5',
									'13.5',
									'40.1',
									'matrix(1 0 0 1 56.1298 172.9443)',
									'33'
								)}
								{this.renderPolySection(
									'98.5,95.5 135.6,73.7 138.2,78.1 129.8,83.5 138.9,97 115.2,111.1 		',
									'matrix(1 0 0 1 114.9631 98.611)',
									'34'
								)}
								{this.renderPolySection(
									'47,100 65.1,110.8 55.8,126.9 70.2,135.9 67.4,138.6 67.4,144.1 50.6,144.1 50.6,166.8 29.5,166.8 29.5,129.6 		',
									'matrix(1 0 0 1 39.1298 135.9443)',
									'35'
								)}
								{this.renderPolySection(
									'131.6,56.8 144.2,78.5 134.8,83.9 142.2,95.4 150,90.6 167.2,90.6 167.2,53.2 138.2,53.2 		',
									'matrix(1 0 0 1 149.1266 73.9443)',
									'36'
								)}
								{this.renderPolySection(
									'79.8,94.1 90.2,100.1 72,131.9 60.9,125.4 		',
									'matrix(1 0 0 1 69.7964 117.111)',
									'37'
								)}
								{this.renderPolySection(
									'49.3,95.4 66.5,65.6 95.7,48.9 105.3,65.9 89.5,75.1 98,90.1 93.8,92.2 91.7,97.1 77.3,88.8 67.7,106.2 		',
									'matrix(1 0 0 1 71.2964 78.7776)',
									'39'
								)}
								{this.renderPolySection(
									'134.6,69.8 101.1,88.5 94.8,76.7 128.4,58.4 		',
									'matrix(1 0 0 1 109.0339 77.1255)',
									'41'
								)}
								{this.renderPolySection(
									'99,46.9 108.8,64.1 135,49.6 167,49.6 167,27.9 132,27.9 		',
									'matrix(1 0 0 1 128.8131 42.9079)',
									'43'
								)}
								{this.renderPolySection(
									'94.1,214.1 105.5,207.2 113.9,221.9 130.1,231.4 123.9,242.6 104.8,232.2 		',
									'matrix(1 0 0 1 107.1298 229.9443)',
									'A'
								)}
							</g>
						</svg>
					</div>
				</div>
			);
		}
	}

	onSubmit(values, court) {
		if (values.text.toString().toUpperCase() == 'ROYAL BOX') {
			this.setState({ currentSeat: 'ROYAL' });
		} else {
			this.setState({ currentSeat: values.text.toString().toUpperCase() });
		}
	}

	renderSeatView(courtName) {
		let _this = this;
		const { handleSubmit } = this.props;
		const SEATINGVIEWSCHEMA = yup
			.object({
				text: yup.string().required('This is not a valid gangway'),
			})
			.test('valid-gangway', null, obj => {
				let validationErrors = validate(obj, this.props);

				if (isEmpty(validationErrors)) {
					return true;
				}

				return new yup.ValidationError(validationErrors.text, null, 'text');
			});

		this.initialValues = {
			text: '',
		};

		if (courtName == 'centre') {
			return (
				<div data-dna="view-from-your-seat" className="view-from-your-seat two-col">
					<div className="image-wrapper">
						<img
							aria-label="center court image"
							role="img"
							src={`${this.state.imageHost}/images/seating/CentreCourt/cc_${this.state.currentSeat}.jpg`}
						/>
					</div>
					<div className="view-from-your-seat-text">
						<div className="title">View from your seat</div>
						<div className="description">
							Type in your Gangway number (printed at the top of your ticket) to see a view of Centre
							Court from your seating area.
						</div>
					</div>
					<Formik
						onSubmit={this.onSubmit}
						initialValues={this.initialValues}
						validationSchema={SEATINGVIEWSCHEMA}>
						{formProps => {
							return this.renderForm({ ...formProps, context: this.context });
						}}
					</Formik>
				</div>
			);
		}
		if (courtName == 'no1court') {
			return (
				<div data-dna="view-from-your-seat" className="view-from-your-seat two-col">
					<div className="image-wrapper">
						<img
							role="img"
							aria-label="number 1 court seat"
							src={`${this.state.imageHost}/images/seating/No1Court/No1_${this.state.currentSeat}.jpg`}
						/>
					</div>
					<div className="view-from-your-seat-text">
						<div className="title">View from your seat</div>
						<div className="description">
							Type in your Gangway number (printed at the top of your ticket) to see a view of No.1 Court
							from your seating area.
						</div>
					</div>
					<Formik
						onSubmit={this.onSubmit}
						initialValues={this.initialValues}
						validationSchema={SEATINGVIEWSCHEMA}>
						{formProps => {
							return this.renderForm({ ...formProps, context: this.context });
						}}
					</Formik>
				</div>
			);
		}
	}

	render() {
		logger.log('[SeatingViews] render - state:%o', this.state);
		let courtName = this.props.match.params.court;
		let header_propsData = {
			headerType: 'Seating View',
			title: 'Seating Plan',
			shortTitle: 'Seating Plan',
			metaTitle: 'Seating Plan',
			metaDescription: 'Seating Plan',
			metaDate: '',
			metaPlayers: '',
		};

		if (this.state) {
			return (
				<Template className="seatingviews">
					<div className="seatingviews-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout newsletter">
						{this.renderCourtHeader(courtName)}
						{this.renderCourt(courtName)}
						{this.renderSeatView(courtName)}
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="newsletterpage">
					<div className="newsletter-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

function validate(values, props, state) {
	const errors = {};
	let centreSeats = [
		'101',
		'102',
		'103',
		'104',
		'105',
		'106',
		'107',
		'108',
		'109',
		'110',
		'111',
		'112',
		'113',
		'114',
		'115',
		'201',
		'202',
		'203',
		'204',
		'205',
		'206',
		'207',
		'208',
		'209',
		'210',
		'211',
		'212',
		'301',
		'302',
		'303',
		'304',
		'305',
		'306',
		'307',
		'308',
		'309',
		'310',
		'311',
		'312',
		'313',
		'314',
		'315',
		'316',
		'317',
		'318',
		'501',
		'502',
		'503',
		'504',
		'505',
		'506',
		'507',
		'508',
		'509',
		'510',
		'511',
		'512',
		'513',
		'514',
		'515',
		'516',
		'517',
		'518',
		'519',
		'520',
		'521',
		'522',
		'523',
		'ROYAL',
		'ROYAL BOX',
	];

	let no1Seats = [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
		'17',
		'18',
		'19',
		'20',
		'21',
		'22',
		'23',
		'24',
		'25',
		'26',
		'27',
		'28',
		'29',
		'30',
		'31',
		'32',
		'33',
		'34',
		'35',
		'36',
		'37',
		'39',
		'41',
		'43',
		'A',
	];

	if (values.text) {
		if (props.match.params.court === 'centre') {
			if (centreSeats.indexOf(values.text.toString().toUpperCase()) == -1) {
				errors.text = 'This is not a valid gangway';
			}
		}
		if (props.match.params.court === 'no1court') {
			if (no1Seats.indexOf(values.text.toString().toUpperCase()) == -1) {
				errors.text = 'This is not a valid gangway';
			}
		}
	}

	return errors;
}

export default connect(mapStateToProps, mapDispatchToProps)(SeatingViews);

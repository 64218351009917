import PlayerStatsPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: '/en_GB/scores/extrastats/index.html',
	exact: true,
	component: PlayerStatsPage,
	section: MENU_ITEMS.players,
	load: params => deps.actions.PlayerStatsPage.mount(params),
};

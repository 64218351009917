import PlayerPageWebview from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: [
		'/webview/:lang(en_GB)/players/overview/:player.html',
		'/webview/:lang(en_GB)/players/overview/:player/:tabName.html',
	],
	exact: true,
	section: MENU_ITEMS.players,
	component: PlayerPageWebview,
	load: params => deps.actions.PlayerPageWebview.mount(params),
};

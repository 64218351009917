import React, { Component, useEffect, useState, Fragment } from 'react';
import Accordion from './Accordion';

const AccordionCollection = props => {
	const [isOpen, setIsOpen] = useState(0);
	let children;
	if (Array.isArray(props.children) && props.children.length > 0) {
		children = props.children.filter(x => !!x);
	}
	else {
		children = props.children;
	}

	const updateisOpen = index => {
		//console.log('Accordion - index:%o isOpen:%o', index, isOpen);
		if (index != isOpen) {
			setIsOpen(index);
			if (children[index] && props.onOpen) {
				props.onOpen(children[index].key);
			}
		} 
		else {
			setIsOpen(null);
		}
	};
	
	return (
		<div className={`accordion-collection-container ${props.class ? props.class : ''}`}>
			<div>
				{React.Children.map(children, (child, i) => {
					//console.log('Accordion - i:%o child:%o', i, child.key);
					return React.cloneElement(
						<Accordion accordOpen={i == isOpen ? true : false} clickFn={updateisOpen} iteration={i}>
							{child}
						</Accordion>
					);
				})}
			</div>
		</div>
	);
};

export default AccordionCollection;

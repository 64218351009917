import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				relatedVideoPath: Config.relatedContent.video,
			};

			dispatch({ type: deps.actionTypes.VIDEOWRAPPER_MOUNT, data: data });
		});
	},
};

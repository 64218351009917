import { measureInWeb, measureInAppContext } from 'appdir/components/general/Analytics';
import { getQuerystringValues } from 'appdir/components/general/Util';

/** doMeasurement is used on multiple webviews */
export const doMeasurement = (pageTitle, measureAction, measure_args) => {
	measureInAppContext({
		pageTitle: pageTitle,
		action: measureAction,
		args: measure_args,
	});
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, useEffect, useState } from 'react';
import { MatchArchiveContext } from '../context';
import op from 'object-path';
import WimLink from 'appdir/components/general/WimLink';
import Time from 'appdir/components/common-ui/Time';
import RoundNav from './RoundNav';
import { getRoundList, isWithinHours, sortMatchData } from './Utils';
import { CarouselArrow } from './CarouselArrow';
import Carousel from '@brainhubeu/react-carousel';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchNav
 * -----------------------------------------------------------------------------
 */
const getSelectedSlide = (slideList, current) => {
	// logger.log('[MatchNav] getSelectedSlide - slideList:%o, current:%o', slideList, current);
	let slideNum;

	if (slideList.length > 0 && current) {
		slideNum = slideList.findIndex(x => x.id === current);
		// logger.log('[MatchNav] getSelectedSlide - slideNum:%o', slideNum);
	}

	if (slideNum === -1) {
		slideNum = 0;
	}

	return slideNum;
};

const MatchNavCarousel = props => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const handleChange = value => {
		setCurrentSlide(value);
	};
	useEffect(() => {
		setCurrentSlide(getSelectedSlide(props.dataArray, props.currentMatchId));
		return () => {
			null;
		};
	}, [props.round, props.dataArray]);

	return (
		<>
			<Carousel
				itemWidth={240}
				value={currentSlide}
				onChange={handleChange}
				arrowLeft={<CarouselArrow name="wim-icon-left-arrow" class="matchnav-arrow prev-button" />}
				arrowRight={<CarouselArrow name="wim-icon-right-arrow" class="matchnav-arrow next-button" />}
				arrowLeftDisabled={
					<CarouselArrow name="wim-icon-left-arrow" class="matchnav-arrow prev-button disabled" />
				}
				arrowRightDisabled={
					<CarouselArrow name="wim-icon-right-arrow" class="matchnav-arrow next-button disabled" />
				}
				addArrowClickHandler>
				{props.content}
			</Carousel>
		</>
	);
};

class MatchNav extends Component {
	// const MatchNav = props => {
	constructor(props) {
		super(props);
		this.state = {
			roundList: [],
			roundArrays: {
				matches_r1: [],
				matches_r2: [],
				matches_r3: [],
				matches_r4: [],
				matches_rQ: [],
				matches_rS: [],
				matches_rF: [],
			},
			selectedRound: '1',
			currentSlide: 0,
		};

		this.firstLoad = true;
		this.matchesLoaded = false;
		this.setSelectedRound = this.setSelectedRound.bind(this);

		logger.log('[MatchNav] props: %o', this.props);
	}

	componentDidUpdate() {
		if (this.state.roundList.length == 0 && this.context.roundNames.length > 0) {
			let tmpRoundList = getRoundList(this.context.roundNames);

			this.setState(prevState => {
				return {
					...prevState,
					roundList: tmpRoundList,
				};
			});

			// logger.log('[MatchNav] componentDidUpdate - this.state.roundList.length:%o', this.state.roundList.length);
		}

		if (this.context.matches.length > 0 && this.context.roundNames.length > 0 && !this.matchesLoaded) {
			this.matchesLoaded = true;

			let sortedMatches = sortMatchData(this.context.matches);

			this.setState(prevState => {
				return {
					...prevState,
					roundArrays: {
						matches_r1: sortedMatches.tmp_matches_r1,
						matches_r2: sortedMatches.tmp_matches_r2,
						matches_r3: sortedMatches.tmp_matches_r3,
						matches_r4: sortedMatches.tmp_matches_r4,
						matches_rQ: sortedMatches.tmp_matches_rQ,
						matches_rS: sortedMatches.tmp_matches_rS,
						matches_rF: sortedMatches.tmp_matches_rF,
					},
				};
			});
		}

		//set selected round to what comes through in the data on first load
		if (this.props.currentMatchRound && this.firstLoad && this.state.roundList.length > 0) {
			this.firstLoad = false;

			this.setState(prevState => {
				return {
					...prevState,
					selectedRound: this.props.currentMatchRound,
				};
			});
		}
	}

	setSelectedRound(which) {
		MeasurementUtils.dispatchMeasurementCall('Match Archive', { data: ['Detail', 'Select Round', which] });

		this.setState(prevState => {
			return {
				...prevState,
				selectedRound: which,
			};
		});
	}

	renderMatch(m) {
		let hasquote = false;
		let p1initial = '';
		let p2initial = '';
		let p1Name = '';
		let p2Name = '';
		let isNew = false;

		if (m.players.length > 0) {
			p1initial = m.players[0].firstName.substr(0, 1);
			p2initial = m.players[1].firstName.substr(0, 1);
			p1Name = `${p1initial}. ${m.players[0].lastName}`;
			p2Name = `${p2initial}. ${m.players[1].lastName}`;
		}

		if ((m.status === 'A' || m.status === 'P') && m.quotes.length > 0) {
			hasquote = true;
		}

		if (m.status === 'A' && m.available) {
			isNew = isWithinHours(m.available, 12);
		}

		if (m.status !== 'F') {
			return (
				<WimLink
					to={m.status === 'A' ? `/en_GB/${this.props.directory}/${m.id}.html` : ''}
					style={`match-archive-box small${m.status === 'A' ? ' active' : ''}${
						m.status === 'P' ? ' available' : ''
					}${hasquote ? ' quote-card' : ''}${this.props.currentMatchId === m.id ? ' current' : ''}${
						isNew === true ? ' new-match' : ''
					}`}
					key={`match-${m.contentId}`}>
					<div className="match-archive-box-content quote-card-inner">
						<div className="front quote-card-front">
							{m.status === 'A' && isNew === true ? <div className="ma-new-tag">New</div> : null}
							<div className="ma-team p1">
								<div className="photo">
									<img src={m.players[0].headshot} alt={p1Name} />
								</div>
								<div className="name">{p1Name}</div>
							</div>
							{m.status === 'A' ? (
								<div className="info">
									<div className="year">{m.year}</div>
									<div className="action">
										<i className="wim-icon-video"></i>
									</div>
								</div>
							) : (
								<div className="info">
									<div className="date">
										Available on
										<br />
										<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
									</div>
								</div>
							)}
							{/* <div className="info">
									<div className="year">{m.year}</div>
									{m.status === 'P' ? (
										<div className="date">
											Available on
											<br />
											<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
										</div>
									) : null}
								</div> */}
							<div className="ma-team p2">
								<div className="photo">
									<img src={m.players[1].headshot} alt={p2Name} />
								</div>
								<div className="name">{p2Name}</div>
							</div>
						</div>
						{m.quotes.length > 0 ? (
							<div className="back quote-card-back">
								<div className="quote">
									<div className="quote-text">{m.quotes[0].text}</div>
									<div className="quote-credit">{m.quotes[0].credit}</div>
								</div>
							</div>
						) : null}
					</div>
				</WimLink>
			);
		} else {
			return (
				<div className="match-archive-box small">
					<div className="match-archive-box-content quote-card-inner">
						<div className="front quote-card-front">
							<div className="ma-team p1">
								<div className="photo">
									<img src="/assets/images/misc/headshot.jpg" alt="Player headshot" />
								</div>
							</div>
							<div className="info">
								<div className="date">
									Available on
									<br />
									<Time epoch_ms={m.available} format="MMMM D h:mm z" options="tile" />
								</div>
							</div>
							<div className="ma-team p2">
								<div className="photo">
									<img src="/assets/images/misc/headshot.jpg" alt="Player headshot" />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	renderContent() {
		let arr = eval(`this.state.roundArrays.matches_r${this.state.selectedRound}`);
		let _this = this;

		// logger.log('[MatchNav] render - arr: %o', arr);

		return arr.map((match, i) => {
			return _this.renderMatch(match);
		});
	}

	render() {
		logger.log('[MatchNav] render - context: %o', this.context);
		let dataArray = [];

		if (op.get(this.state, 'selectedRound', false)) {
			dataArray = eval(`this.state.roundArrays.matches_r${this.state.selectedRound}`);
		}

		return (
			<noindex>
				{op.get(this.context, 'roundNames', false) ? (
					<RoundNav
						data={this.context.roundNames}
						currentRound={this.context.currentRound}
						selectedRound={this.state.selectedRound}
						onRoundChange={this.setSelectedRound}
					/>
				) : null}
				<div className="matchnav">
					<div className="matchnav-container">
						<MatchNavCarousel
							content={this.renderContent()}
							round={this.state.selectedRound}
							dataArray={dataArray}
							currentMatchId={this.props.currentMatchId}
						/>
					</div>
				</div>
			</noindex>
		);
	}
}

MatchNav.contextType = MatchArchiveContext;

export default MatchNav;

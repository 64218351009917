/**
 SchedulePage Initial State
*/

export default {
	mode: '',
	filters: {
		day: '',
		open: '',
	},
};

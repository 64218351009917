/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Divider
 * -----------------------------------------------------------------------------
 */
const Divider = props => {
    const {className} = props;
	return <div className={`content-divider ${className || ""}`} />
};

/**
 * -----------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------
 */
export default Divider;

//import services from './services';
import { logger } from '../logger';
import { WebSocketConnection } from './connections/websocketConnection';
import { PollingConnection } from './connections/pollingConnection';
import { AndroidConnection } from './connections/androidWebsocketConnection';
import axios from 'axios';

let isPolling = true;
// window.currentConnection = undefined;
let pollingInterval = undefined;
let isActive = false;
let messageSightRetryMax = 30; // configurable via component
let retryCount = 0;
let isAndroid = false;

if (navigator.userAgent.indexOf('IBM_Events_Android_APK') != -1) {
	isAndroid = true;
}

const TOKEN_EXPIRE = 2592000000; //30 days
const TIME_BUFFER = 300000; //5 minutes

const setDefaults = options => {
	//logger.log('setDefaults, options:%o', options);

	if (options.balance === 0) {
		return options;
	}
	options.balance = options.balance && !isNaN(options.balance) ? options.balance : 100;
	options.mqttBalance = !isNaN(options.mqttBalance) ? options.mqttBalance : 100;
	if (options.retryAttempts >= 0) {
		messageSightRetryMax = options.retryAttempts;
	}
	return options;
};

const startPoll = frequency => {
	runPoll();
	pollingInterval = setInterval(() => {
		runPoll();
	}, frequency);
};

const stopPolling = () => {
	clearInterval(pollingInterval);
};

const runPoll = () => {
	if (isPolling && isActive) {
		window.currentConnection.runPoll(isActive);
	}
};

const connectToWebSocket = options => {
	if (isAndroid) {
		window.currentConnection = new AndroidConnection();
	} else {
		window.currentConnection = new WebSocketConnection();
	}
	logger.log('connectToWebSocket - host:%o options:%o', options.hostname, options);
	window.currentConnection.createNew({
		hostname: options.hostname,
		port: options.port,
		path: options.path,
		event: options.event,
		year: options.year,
		cPath: options.cFile,
		clientId: options.clientId,
		connectionReady: options.connectionReady,
		onDataUpdate: scoringData => {
			options.scoresUpdated(scoringData);
		},
		onStatsUpdate: statsData => {
			options.statsUpdated(statsData);
		},
		onHistoryUpdate: historyData => {
			options.historyUpdated(historyData);
		},
		onError: err => {
			logger.log('- error: %o', err);

			if (retryCount < messageSightRetryMax) {
				logger.log('- error received, will reconnect.');
				window.currentConnection.stop();
				if (options.type == 'MS') {
					logger.log('- previous client id:%o ', options.clientId);
					options.clientId = 'WWSMMS_' + createClientId();
					logger.log('- new client id:%o ', options.clientId);
					connectToWebSocket(options);
				} else if (options.type == 'EC') {
					logger.log('- previous client id:%o ', options.clientId);
					// options.clientId = 'EDGE_' + createClientId();
					/** RLL added 5-25-2022 to try to get new token on reconnect */
					getClient(options);
					// logger.log('- new client id:%o ', options.clientId);
				}
				// connectToWebSocket(options);
				window.currentConnection.start();
				retryCount++;
			} else {
				logger.log('retries exceeded - revert to Scoring mode = POLLING');
				window.currentConnection.stop();
				window.currentConnection = new PollingConnection();
				window.currentConnection.createNew({
					scoresPath: options.scoresPollingFile,
					statsPath: options.statsFile,
					cPath: options.cFile,
					uPath: options.uFile,
					event: options.event,
					year: options.year,
					connectionReady: options.connectionReady,
					onDataUpdate: scoringData => {
						options.scoresUpdated(scoringData);
					},
					onStatsUpdate: statsData => {
						options.statsUpdated(statsData);
					},
					onHistoryUpdate: historyData => {
						options.historyUpdated(historyData);
					},
				});
				isPolling = true;
				startPoll(options.pollingFrequency);
			}
		},
	});

	logger.log('connectToWebSocket - after createNew window.currentConnection:%o', window.currentConnection);
};

const createClientId = () => {
	try {
		let randomClientId = String(new Date().getTime());
		const randomNumber = Math.floor(Math.random() * 1000 + 1);
		randomClientId += String(randomNumber);
		return randomClientId;
	} catch (e) {
		logger.error('createClientId - ' + e);
	}
};

/**
 * create new client data, id, token, expire time
 *   and store locally if possible
 *  *** NOTE:  Used for edge connect ***
 */
const createClient = options => {
	logger.log('createClient - options: %o', options);
	let now = new Date();
	let clientData = {
		// clientId: `EDGE_${createClientId()}`,
		clientId: '',
		expireTime: now.getTime() + TOKEN_EXPIRE,
		token: '',
	};

	return new Promise((resolve, reject) => {
		axios({
			method: 'get',
			url: `${options.tokenCreator}${clientData.clientId}`,
			//url: `https://0990f523.us-east.apigw.appdomain.cloud/edgeconnect/token?clientId=${clientData.clientId}`,
			headers: { 'Content-type': 'application/json' },
		})
			.then(res => {
				logger.log('createClient - token response: %o', res);
				clientData.token = res.data.token;
				/**
				 * RLL commenting out code so that client data is not stored/retrieved from
				 * localStorage.  Each instance of edge connect needs a new client id and token.
				 */
				// if (window.localStorage) {
				// 	window.localStorage.setItem('ixMessageClient', JSON.stringify(clientData));
				// }

				resolve(clientData);
			})
			.catch(error => {
				logger.error('createClient - error:%o', error);
				reject(clientData);
			});
	});
};

/** called for edge connect */
const getClient = options => {
	logger.info('getClient - options:%o', options);
	/**
	 * RLL changed value to force new client data on every connection
	 */
	let clientData = null;

	/**
	 * RLL commenting out code so that client data is not stored/retrieved from
	 * localStorage.  Each instance of edge connect needs a new client id and token.
	 */
	// if (window.localStorage) {
	// 	let data = window.localStorage.getItem('ixMessageClient');
	// 	if (typeof data == 'string' && data.length > 0) {
	// 		clientData = JSON.parse(data);
	// 		let now = new Date();
	// 		logger.info('getClient - now:%o expire:%o', now.getTime(), clientData.expireTime);
	// 		if (now.getTime() > clientData.expireTime - TIME_BUFFER) {
	// 			//clear old data so gets new
	// 			clientData = null;
	// 		}
	// 	}
	// }

	//if anonymous
	if (options.anonymous) {
		logger.info('getClient - anonymous edgeconnect');
		//options.path = '/mqtt?accessToken=' + clientData.token;
		options.path = '/mqtt';
		options.hostname = options.edgeConnectHostname;
		options.clientId = '';
		options.type = 'EC';
		startPubSub(options);
	}

	//get new clientData and start pubsub
	else if (!clientData) {
		logger.info('getClient - edge -- no clientData so create one');
		createClient(options)
			.then(data => {
				logger.info('getClient - resolved data:%o', data);

				options.path = '/mqtt?accessToken=' + data.token;
				options.hostname = options.edgeConnectHostname;
				options.clientId = data.clientId;
				options.type = 'EC';
				startPubSub(options);
			})
			.catch(e => {
				logger.info('getClient - error creating data');
			});
	}

	//update options from saved clientData and start pubsub
	else {
		logger.info('clientData:%o', clientData);
		options.path = '/mqtt?accessToken=' + clientData.token;
		options.hostname = options.edgeConnectHostname;
		options.clientId = clientData.clientId;
		options.type = 'EC';
		startPubSub(options);
	}
};

const startPubSub = options => {
	try {
		// Create our pub sub connection!
		connectToWebSocket(options);
		isPolling = false;
		stopPolling();
	} catch (error) {
		logger.info('- Error connecting to WebSocket, reverting to polling: %o', error);
		window.currentConnection = new PollingConnection();
		window.currentConnection.createNew({
			scoresPath: options.scoresPollingFile,
			statsPath: options.statsFile,
			cPath: options.cFile,
			uPath: options.uFile,
			event: options.event,
			year: options.year,
			connectionReady: options.connectionReady,
			onDataUpdate: scoringData => {
				options.scoresUpdated(scoringData);
			},
			onStatsUpdate: statsData => {
				options.statsUpdated(statsData);
			},
			onHistoryUpdate: historyData => {
				options.historyUpdated(historyData);
			},
		});
		isPolling = true;
		startPoll(options.pollingFrequency);
	}
};

export default {
	init: (options = {}) => {
		options = setDefaults(options);
		let balance = Math.floor(Math.random() * 100);
		let mqttBalance = Math.floor(Math.random() * 100);
		// logger.log('init, messageSightRetryMax:%o', messageSightRetryMax);
		let scoringMode = 'ec';

		if (isAndroid && options.androidMode == 'poll') {
			scoringMode = 'poll';
		}

		if (balance < options.balance && scoringMode != 'poll') {
			if (mqttBalance < options.mqttBalance) {
				logger.log(
					'- Scoring mode: PUBSUB - mqtt - genBal:%o balanceSetting:%o',
					mqttBalance,
					options.mqttBalance
				);

				options.path = '/mqtt2';
				options.hostname = options.messageSightHostname;
				options.clientId = 'WWSMMS_' + createClientId();
				options.type = 'MS';
				startPubSub(options);
			} else {
				logger.log(
					'- Scoring mode: PUBSUB - edge - genBal:%o balanceSetting:%o',
					mqttBalance,
					options.mqttBalance
				);
				getClient(options);
			}
		} else {
			logger.log('- Scoring mode: POLLING');
			window.currentConnection = new PollingConnection();
			window.currentConnection.createNew({
				scoresPath: options.scoresPollingFile,
				statsPath: options.statsFile,
				cPath: options.cFile,
				uPath: options.uFile,
				event: options.event,
				year: options.year,
				connectionReady: options.connectionReady,
				onDataUpdate: scoringData => {
					options.scoresUpdated(scoringData);
				},
				onStatsUpdate: statsData => {
					options.statsUpdated(statsData);
				},
				onHistoryUpdate: historyData => {
					options.historyUpdated(historyData);
				},
			});
			isPolling = true;
			startPoll(options.pollingFrequency);
		}
	},
	initListener: () => {
		/**
		 * TODO: Amy/Robin, FYI this is the event listener code below to add stats etc for a match..
		 */
		window.addEventListener('ixEventsScoring', e => {
			//logger.log('initListener, e:%o', e);
			if (e.detail) {
				logger.log(
					'initListener, type:%o, window.currentConnection:%o',
					e.detail.type,
					window.currentConnection
				);

				if (e.detail.type == 'addMatchData') {
					window.currentConnection.addSlamtrackerForMatch(e.detail);
				} else if (e.detail.type == 'removeMatchData') {
					window.currentConnection.removeSlamtrackerForMatch(e.detail);
					isActive = true;
				} else if (e.detail.type == 'start') {
					window.currentConnection.start();
					isActive = true;
				} else if (e.detail.type == 'stop') {
					window.currentConnection.stop();
					isActive = false;
				}
			}
		});
		window.addEvents;
	},
};

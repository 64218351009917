/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { getQuerystringParam } from 'appdir/components/general/Util';
import VirtualQueueStatus from 'appdir/components/webviews/VirtualQueueWebview/VirtualQueueStatus.js';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { handleGetVirtualQueueStatusEndpoint } from 'appdir/components/webviews/VirtualQueueWebview/virtualQueueEndpoints.js';
import { loggedIn } from 'appdir/components/general/Util/Role';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import { getErrorText } from 'appdir/components/general/Util/Error';

/**
 * -----------------------------------------------------------------------------
 * React Component: VirtualQueueWebview
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		gigya: state['Gigya'],
		queueAPIs: op.get(state['Config'], 'queue.api.queue', null),
		configStatus: op.get(state['Config'], 'status', null),
		configData: op.get(state['Config'], 'queue.data.queue', null),
		viewQueueGuideURL:  op.get(state['Config'], 'queue.url.queue.view_queue_guide_pdf_url', null),
		queues: op.get(state['Config'], 'queue.queues', null),
		...state['VirtualQueueWebview'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(actions.VirtualQueueWebview.mount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class VirtualQueueWebview extends Component {
	constructor(props) {
		super(props);
		// now add all the props and filters to state
		this.state = {
			...props,
		};
	}

	componentDidMount() {
		// logger.log('[VirtualQueueWebview] componentDidMount - state:%o', this.state);
		this.debugView = getQuerystringParam(null, 'ticketDebug');

		measureInAppContext({
			pageTitle:"Virtual Queue",
			action: "",
			args: [],
			context:  [{ section: "Virtual Queue" }],
			metricType: 'state'
		});
	}

	
	static getDerivedStateFromProps(nextProps, prevState) {
		let newUser = op.get(nextProps, 'gigya.currentUser', {});
		let prevUser = op.get(prevState, 'gigya.currentUser', {});

		// logger.log('[VirtualQueueWebview] getDerivedStateFromProps - nextProps:%o', nextProps);

		return newUser.UID != prevUser.UID ||
			(op.get(nextProps, 'gigya.gigyaLoaded', false) && !op.get(prevState, 'gigya.gigyaLoaded', false))
			? {
					...prevState,
					...nextProps,
			  }
			: null;
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'gigya.currentUser', {});
		logger.log(
			'[VirtualQueueWebview] componentDidUpdate - dataLoad:%o, user:%o state:%o prev:%o',
			this.dataLoad,
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			prevState
		);

		if (loggedIn(currentUser)) {
			logger.log('[VirtualQueueWebview] componentDidUpdate load data - state:%o', this.state);

			if ((!this.state.queueStatus && this.state.queueStatus !== 0)) {
				let queueStatus = '';
				this.props.getJWT().then(token => {
					// get queue status	
					let path = this.props.queueAPIs?.virtualQueueStatus;
					handleGetVirtualQueueStatusEndpoint(token.jwt.id_token, path).then((response) => {
						logger.log('[VirtualQueueWebview] handleGetQueueStatus() response %o', response);
						// logger.log('[VirtualQueueWebview] handleGetQueueStatus() response error %o', response?.data?.response?.error);

						// find virtual queue booking
						let respResult = response?.data?.response?.result;
						const filteredRes = respResult.filter(item =>
							this.props.queues.some(queue => queue.id === item.queue_id && !queue.ticketResale)
						);
						logger.log('[VirtualQueueWebview] handleGetQueueStatus() filteredRes', filteredRes);
						// sort by creation time, and get most recent booking
						let queueRes = filteredRes ? this.sortResult(filteredRes) : [];
						logger.log('[VirtualQueueWebview] handleGetQueueStatus() queueStatus', queueRes);

						// if response is 200 but error inside, means user has not yet joined a queue 
						queueStatus = queueRes?.booking_status > -1 ? queueRes?.booking_status : 'notjoined';

						this.setState({ queueStatus: queueStatus, queueStatusObj: queueRes });
	
					}).catch(error => {
						logger.error('[VirtualQueueWebview] handleGetQueueStatus() - error:%o', error);
						queueStatus = 0;
						this.setState({ queueStatus: queueStatus });
					});
				})
			}
		}
	}

	sortResult(response) {
		// sort by creation time and get most recent booking
		if (response?.length > 0) {
			let sortedRes = response?.sort(function (a, b) {
				return moment.utc(b.creation_time).diff(moment.utc(a.creation_time))
			});
			return sortedRes[0];
		} else {
			return response;
		}
	}

	render() {
		let gigyaLoaded = op.get(this.state, 'gigya.gigyaLoaded', {});
		let configStatus = op.get(this.state, 'configStatus', {});
		let currentUser = op.get(this.state, 'gigya.currentUser', {});

        // queue role segmentId: 1000341
		let ticketQueueRole = currentUser?.roles?.filter(d => d.segmentId == 1000341);


		//logger.log('[VirtualQueueWebview] render - this:%o', this);
		//logger.log('[VirtualQueueWebview] render - state:%o', this.state);

		if (configStatus !== 'loaded' || !gigyaLoaded || (gigyaLoaded && (this.state.queueStatus == undefined || this.state.queueStatus < 0))) {
			return (
				<div className={
					this.debugView
						? 'ticketDebugWebViewBackground virtual-queue-webview' : 'virtual-queue-webview'
				}>
					<LoadingIndicator type={'white'} />
				</div>);
		}

		if (this.state.exitedQueue) {
			return (
				<div className={
					this.debugView ? 'ticketDebugWebViewBackground queue-stub-general-message' : 'queue-stub-general-message'}
				>
					<div className='queue-general-message'>
						<div className='queue-general-message-title'>THANK YOU</div>
						<div className='queue-general-message-msg'>Thank you for participating in The Queue.</div>
					</div>
				</div>);
		} else if (!ticketQueueRole || !ticketQueueRole?.length) {
			return (<div className={
				this.debugView ? 'ticketDebugWebViewBackground queue-error-msg' : 'queue-error-msg'}
			><div>{getErrorText('invalid_role', "Virtual Queue")}</div></div>);
		}

		return (
            <div className={
                this.debugView
                    ? 'ticketDebugWebViewBackground virtual-queue-webview' : 'virtual-queue-webview'
            }>
				<VirtualQueueStatus
					getJWT={() => this.props.getJWT()}
					showModal={(data) => this.props.showModal(data)}
					queueAPIs={this.props.queueAPIs}
					booking_id={this.state.queueStatusObj?.booking_id}
					queue_id={this.state.queueStatusObj?.queue_id}
					configData={this.props.configData}
					exitQueueResponse={(status, response) => {
						// reset state values, has exited queue
						let newObj = this.sortResult(response?.data?.response?.result);
						if (status == 'success') {
							this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj, exitedQueue: true });
						}
					}}
					viewQueueGuideURL={this.props.viewQueueGuideURL}
					updateQueueStatus={(queueStatus, queueStatusObj) => {
						if (queueStatusObj) {
							this.setState({ queueStatus: queueStatus, queueStatusObj: queueRes });
						} else {
							this.setState({ queueStatus: queueStatus });
						}
					}}
				/>
            </div>
        )
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualQueueWebview);

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MAINVIDEOCHANNEL_MOUNT:
			break;

		case deps.actionTypes.MAINVIDEOCHANNEL_PLAY:
			// let main = state.players.main;
			// let sidepanel = state.players.sidepanel;

			newState = {
				...state,
				...action.data,
				isPlaying: true,
			};
			logger.log('[VideoPlayer]  deps.actionTypes.MAINVIDEOCHANNEL_PLAY - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.CONTROLLER_UPDATE:
			let isPlaying = state.isPlaying;
			if (action.data.sidepanel) {
				if (!action.data.sidepanel.userOpen) {
					isPlaying = false;
				}
			}

			newState = {
				...state,
				isPlaying: isPlaying,
			};
			//logger.log('[VideoPlayer]  deps.actionTypes.MAINVIDEOCHANNEL_PLAY - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

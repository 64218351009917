import React, { Component, Fragment } from 'react';

import SectionDivider from 'appdir/components/cms/SectionDivider';

class CompleteMessage extends Component {
	constructor(props) {
		super(props);
		//this.state = {...props};
	}

	render() {
		logger.log('[CompleteMEssage] render - context:%o', this.context);

		return (
			<Fragment>
				<div className="two-col margin-col bottom-margin center">Thank you for your request.</div>
			</Fragment>
		);
	}
}

export default CompleteMessage;

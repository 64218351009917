import PlayerProfile from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:lang/draws_archive/player_profile/:player.html',
		exact: true,
		component: PlayerProfile,
		section: 'Draws',
		load: params => deps.actions.PlayerProfile.mount(params),
	},
];

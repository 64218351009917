import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';

import { values } from 'appdir/main';
import { getQuerystringValues, showSummaryFirst } from 'appdir/components/general/Util';
import { passAppData } from 'appdir/components/general/Util';
import { measureInWeb } from 'appdir/components/general/Analytics';
import { isMobile } from 'react-device-detect';
import {sourceLookup} from 'appdir/components/pages/AICatchUp/util';  

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import GenericCarousel from 'appdir/components/common-ui/GenericCarousel';
import AIDaySummary from 'appdir/components/pages/AICatchUp/elements/AIDaySummary';
import AIPlayerCard from 'appdir/components/pages/AICatchUp/elements/AIPlayerCard';
import AICatchUpHeader from 'appdir/components/pages/AICatchUp/elements/AICatchUpHeader';

/**
 * AI Catch Up Home component
 * PlayerCard and Header are shared with AI Catch Up page
 * 
 * @param {*} props 
 * @returns 
 */
const AICatchUpHome = props => {
	const dispatch = useDispatch();
	const configText = useSelector(state => state['Config']?.text?.aiCatchUp);
	const contentNewTimeExpireSec = useSelector(state => state['Config']?.contentNewTimeExpireSec);
    const sidePanel = useSelector(state => state['Controller']['sidepanel']['userOpen']);

    /** Show/Hide AI Catch Up component */
    const enabled = useSelector(state => state['Config']?.enabled);
    
    /** Tournament playerGen & daySummaryGen Data */
	const playerGen = useSelector(state => state['Tournament']?.data?.playerGen);
	const daySummaryGen = useSelector(state => state['Tournament']?.data?.daySummaryGen);

    const [activeSlides, setActiveSlides] = useState({active: 0, totalVisibleSlides: 1}); // this is to keep track of first visible slide, used for viewedContent
    const [allContent, setAllContent] = useState([]); // consolidated contents
    const [showSummaryFirstLoc, setShowSummaryFirstLoc] = useState();

	const mobileMatch = window.matchMedia('(max-width: 390px)');
	const windowSize = useSelector(state => state['PageHeader']['windowSize']);
    const sendPageView = useRef(true);

    //player data
    const playerGenData = playerGen?.data?.data;
    const daySummaryGenData = daySummaryGen?.data?.data;

    /** webview Favs 
	 *  store initial favorites players list that
	 *  apps send
	 */
    let search = window.location.search.replace(/^\?/, '');
	let parsedQs = getQuerystringValues(search);
    const [appFavs, setAppFavs] = useState(window.location.search ? parsedQs.filterPlayers?.split(',') : []);

    /** wait until Config is loaded and AI Catch Up is enabled */
	useEffect(() => {
		if(!isEmpty(enabled) && enabled) {

            if(values.webview && appFavs?.length > 0) {
                dispatch(deps.actions.Tournament.getPlayerGen({playerIds: appFavs}));
            } else {
                dispatch(deps.actions.Tournament.getPlayerGen());
            }

            dispatch(deps.actions.Tournament.getDaySummaryGen());
        }
        setShowSummaryFirstLoc(showSummaryFirst());
	}, [enabled, showSummaryFirstLoc]);

    /**
     *  
     *  For some reason, react-slick treates AIDaySummary and AIPlayerCard
     *  as two different children and cause isseus with intersecting in GenericCarousel
     *  Consolidate the content here into one child
     *  
     *  this also allows easier access to set viewedContent, metrics data setup, etc
     */
    useEffect(() => {
        let arry = [];

        if((playerGen?.status == "loaded" && playerGenData) || 
            (daySummaryGen?.status == "loaded" && daySummaryGenData)) {
            if (playerGenData?.length > 0) {
                arry.push(...playerGen.data.data)
            }

			if (daySummaryGen?.data?.data?.content) {
				daySummaryGen.data.data.content = replaceLinks(daySummaryGen?.data?.data?.content)
			}

            /** add day summary data as the first obj in arry or add to end of array if not showing between 11pm - 10am */
            if(daySummaryGenData && Object.keys(daySummaryGenData).length > 0 && showSummaryFirstLoc) {
                arry.unshift(daySummaryGenData);
            } else if(daySummaryGenData && Object.keys(daySummaryGenData).length > 0 && !showSummaryFirstLoc){
                arry.push(daySummaryGenData);
            };

			//pass data to apps
			if (configText && window.webview && playerGen?.status == "loaded" && (daySummaryGen?.status == "loaded" || daySummaryGen?.status == "error")) {
                sendAppData(arry)
            }

            setAllContent(arry);
        }
    }, [playerGen, daySummaryGen, configText]);

    /** once all the content is loaded, send pageview once */
    useEffect(() => {
        if(allContent?.length > 0 && sendPageView.current) {
            sendPageView.current = false;
            /** for metrics data */
            let playerIds = [];
            let playerNames = [];

            allContent.map((item, index) => {
                let type = item?.day ? 'summary' : 'player';
                let id = '';
                let name = '';

                if(type == 'summary') {
                    id = `summaryDay${item?.day}`;
                    name = `summaryDay${item?.day}`;
                } else {
                    id = item?.id;
                    name = `${item?.firstName} ${item?.lastName}`;
                }
                playerIds.push(id);
                playerNames.push(name);
            });

            measureInWeb({
                action: 'pageView',
                context: [{ playerIds: playerIds, playerNames: playerNames, section: 'Catch Me Up' }],
            });
        }
    }, [allContent]);

    /** set viewed content for www */
    useEffect(() => {
        let currentSlideNum = activeSlides?.active;
        let visibleSlidesNum = activeSlides?.totalVisibleSlides;

        if(allContent?.length > 0 && visibleSlidesNum && !window.webview) {

            for (let i=currentSlideNum; i < (currentSlideNum + visibleSlidesNum); i++) {
                let activeData = allContent[i];

                /** data property name is different b/w daySummary and playerGen */
                let time = activeData?.updated_epoch ? activeData.updated_epoch : activeData?.updateTime;

                /** select correct one for daySummary or playerGen */
                let id = activeData?.day == 0 || activeData?.day ? activeData.day + "summary" : activeData?.id;

                // logger.log('[AICatchUpHome] activeData:%o, day:%o, id:%o', activeData, activeData?.day, activeData?.id);
                
                // dispatch(deps.actions.Controller.updateViewedContent(time, id));
            }
        }
    }, [activeSlides]);

	/** remove links in webview */
	const replaceLinks = (html) => {
		//logger.log('[AICatchUpHome] replaceLinks - html:%s', html);
		let result = html.match(/<a href='\/en_GB\/players.*?a>/g);
		if (result) {
			result.forEach(link => {
				let m = link.match(/<a href='(.*)'>(.*)<\/a>/);
				html = html.replace(link, `${m[2]}`);
			});
		}
		return html;
	}

    /** set the data to pass to the header */
    const setHeaderData = () => {
        let pageName = configText?.title;

        //align headertext class
        let classSP = sidePanel ? '' : 'sideoff';

        let data = {
            pageName,
            showRightLink: true,
            hideInfoIcon: false,
            headerStyle: classSP
        }
        return data;
    }
    // logger.log('[AICatchUpHome] playerGen:%o, props:%o, appFavs:%o, daySummaryGen:%o, allContent:%o', playerGen, props, appFavs, daySummaryGen, allContent);

    /** based on the first active slide, find the player ID
     *  It was used to find the player's info to send to metrics
     *  it's simplified to not to send the player info this year
     *   - it could be for future enhanceements
    */
    const getActiveSlidePlayerID = () => {
        // logger.log('[AICatchUpHome] getActiveSlidePlayerID selectedCard:%o', allContent?.[activeSlides?.active]);
        let selectedSlide = allContent?.[activeSlides?.active];
        let type = selectedSlide?.day == 0 || selectedSlide?.day ? 'summary' : 'player';
        let id = '';
        let name = '';
        if(type == 'summary') {
            id = selectedSlide?.day;
            name = `summaryDay${selectedSlide?.day}`;
        } else {
            id = selectedSlide?.id;
            name = `${selectedSlide?.firstName} ${selectedSlide?.lastName}`;
        }
       return {id, name};
    }

    const metricsData = {
        pageTitle: "Catch Me Up", // required for webview, don't need it for www
        arrowMetrics: {
        },
        swipeMetrics: {
            // contextData: [{cmu_playerID: getActiveSlidePlayerID()?.id }, {cmu_player:getActiveSlidePlayerID()?.name}],
        }
    };

	/**
	 * padd player gen data to mobile apps
	 * @param {*} data 
	 */
	const sendAppData = (data) => {
		let appList = []
		let entry;
		let time = null;
		let newFlag = null;
		let timeDifference = null;
		let currentTime = moment();

		//logger.log('[AICatchUpHome] sendAppData data:%o', data);
		data.forEach(element => {
			time = element.updateTime || element.updated_epoch;
			timeDifference = currentTime.diff(time, 'seconds');
			newFlag = timeDifference <= contentNewTimeExpireSec;
			//logger.log('[AICatchUpHome] sendAppData time:%o element:%o', time, newFlag, element);
			entry = {
				content: element.content || element.paragraph,
				source: sourceLookup(element.source) || 'summary',
				updateTime: moment(time).tz('Europe/London').format('ddd HH:mm z'),
				newFlag: newFlag,
				playerId: element.id || String(element.day),
				matchId: String(element.matchId) || ''
	
			}

			appList.push(entry)
		});

		let dataObject = {
			type: 'catchup',
			data: {
				title: configText.title + ' ' + configText.extend_title,
				text: configText.helpText,
				cards: appList
			},
		};
		logger.log('[AICatchUpHome] sendAppData dataObject:%o', dataObject);
		passAppData(dataObject);
		
	}

    const settings={
        arrows: false,
		centerMode: true,
		variableWidth: true
	}

	//settings for peek on left/right instead of arrows
	const settingsWebview = {
        arrows: false,
		centerMode: true,
		variableWidth: true
	}
	
	let overrrideSettings = !window.webview ? settings : settingsWebview;

    if (allContent?.length > 0) {
		let mobileLayout = ((isMobile && mobileMatch.matches) || window.webview);
		let carouselClasses = `ai-catchup ${mobileLayout ? 'usePeek' : ''}`
		//logger.log('[AICatchUpHome] mobileLayout:%o mobileMatch:%o', mobileLayout, mobileMatch.matches);

        return (
            <div className='column-layout content ai-catchup home'>
    
                <AICatchUpHeader data={setHeaderData()} />

                <div className="ai-catchup-cards-container home">
                    <div className='ai-catchup-cards'>
                        <GenericCarousel 
                            className={carouselClasses}
                            slideWidth={ mobileLayout ? 330 : 350 }
                            sliderSettingsOverride={mobileLayout ? overrrideSettings : null}
                            metricsData={metricsData}
                            afterChangeCallback={(index, visibleSlides) => {
                                setActiveSlides(
                                    {
                                        active: index, 
                                        totalVisibleSlides: visibleSlides
                                    }
                                )
                            }}
                            onInitCallback = {(visibleSlides) => {
                                setActiveSlides(
                                    {
                                        active: 0, 
                                        totalVisibleSlides: visibleSlides
                                    }
                                )
                            }}
                        >
                            {
                                allContent.map((item, index) => {
                                   return item?.day || item?.day == 0 
                                        ? (
											<div className="carousel-wrap" key={`aiPlayer${item?.day}summary`}>
                                            <AIDaySummary 
                                                data={item} 
                                                cardType="shortWeb"
                                            />
											</div>
                                        ) : (
                                            <div className="carousel-wrap" key={`aiPlayer${index}${item.id}`}>
                                                <AIPlayerCard 
                                                    data={item} 
                                                    cardType="shortWeb" 
                                                />
                                            </div>
                                        )
                                })
                            }
                        </GenericCarousel>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default AICatchUpHome;
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
const op = require('object-path');
import BarChart from './BarChart';
import Script from 'react-load-script';
import MatchBox from 'appdir/components/scoring/MatchBox';
import filter from 'lodash/filter';
// import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';

/**
 * -----------------------------------------------------------------------------
 * React Component: NetworkTest
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['NetworkTest'],
		ScoreManager: state['ScoreManager'],
		controllerLoaded: state['Controller'].loaded,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	setScoreManagerStatus: data => dispatch(deps.actions.ScoreManager.setScoreManagerStatus(data)),
});

class NetworkTest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};
		this.chart;
		this.init = false;
		this.chartRef = React.createRef();
		this.courtDataEllapsed = [];
		this.prevCourtDataEllapsed = [];
	}

	componentDidMount() {
		if (!this.init) {
			this.init = true;
			this.props.setScoreManagerStatus({ mip: true });
		}
	}

	componentWillUnmount() {
		this.props.setScoreManagerStatus({ mip: false });
	}

	componentDidUpdate(prevProps, prevState) {}

	handleScriptCreate() {
		this.setState({
			scriptLoaded: false,
		});
	}

	handleScriptError() {
		this.setState({
			scriptError: true,
		});
	}

	handleScriptLoad() {
		this.setState({
			scriptLoaded: true,
		});
	}

	renderMatch(match) {
		// logger.log('[NetworkTest] renderMatch - match:%o', match);
		if (match?.scores) {
			return (
				<div key={match.match_id} className="four-col">
					<MatchBox
						key={`mb_${match.match_id}`}
						attributes={{
							data: match,
							style: 'live',
							event: match.shortEventName,
							tableHeader: match.courtName,
							showLinks: true,
							searchedPlayer: this.state.searchedPlayer,
						}}
					/>
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		let { liveMatches } = this.props.ScoreManager;
		logger.log('[NetworkTest] liveMatches:%o', liveMatches);
		// logger.log('[NetworkTest] this.courtDataEllapsed:%o', this.courtDataEllapsed);
		if (!isEmpty(liveMatches)) {
			liveMatches.map((i, idx) => {
				// logger.log('[NetworkTest] i:%o', i);
				if (!i.create_epoch) return;
				let matchIndex = this.courtDataEllapsed.findIndex(item => item.match_id == i.match_id);
				if (this.courtDataEllapsed[matchIndex]?.data) {
					if (i.create_epoch > this.courtDataEllapsed[matchIndex]?.data.at(-1).label) {
						this.courtDataEllapsed[matchIndex].index = idx;
						let difference = Math.round(Date.now() / 1000) - i.create_epoch;
						difference = difference > 0 ? difference : 0.9;
						this.courtDataEllapsed[matchIndex].data.push({
							label: i.create_epoch,
							value: difference,
							color: difference >= 1 ? '#b5cef5' : '#e3e3e3',
						});
						if (difference < 0) {
							this.courtDataEllapsed[matchIndex].errors.push(
								'Error at ' + Math.round(Date.now() / 1000) + ' value was ' + difference
							);
						}
						if (this.courtDataEllapsed[matchIndex].data.length > 50) {
							this.courtDataEllapsed[matchIndex].data.shift();
						}
					} else if (i.create_epoch < this.courtDataEllapsed[matchIndex]?.data.at(-1).label) {
						this.courtDataEllapsed[matchIndex].errors.push(
							'Error at ' + Math.round(Date.now() / 1000) + ' score with old epoch received'
						);
					}
				} else {
					this.courtDataEllapsed.push({
						data: [
							{
								label: i.create_epoch,
								value: 0,
							},
						],
						court: i.shortCourtName,
						index: idx,
						match_id: i.match_id,
						info: `match_id: ${i.match_id}`,
						errors: [],
					});
					// this.courtDataEllapsed[matchIndex].data.push();
				}
			});
			logger.log('[NetworkTest] this.courtDataEllapsed:%o', this.courtDataEllapsed.length);
			remove(this.courtDataEllapsed, match => {
				return liveMatches.findIndex(item => item.match_id == match.match_id) == -1;
			});
			this.courtDataEllapsed = sortBy(this.courtDataEllapsed, ['index']);
			// this.prevCourtDataEllapsed = cloneDeep(this.courtDataEllapsed);
			logger.log('[NetworkTest] this.courtDataEllapsed:%o', this.courtDataEllapsed);
		}

		return (
			<div className="content-main">
				<Script
					url="https://cdn.jsdelivr.net/npm/chart.js"
					onCreate={this.handleScriptCreate.bind(this)}
					onError={this.handleScriptError.bind(this)}
					onLoad={this.handleScriptLoad.bind(this)}
				/>
				{this.state.scriptLoaded && (
					<div className="charts">
						<h4>{`Your time: ${Math.round(Date.now() / 1000)}`}</h4>
						<div className="column-layout scoresgrid">
							{this.courtDataEllapsed?.length > 0
								? this.courtDataEllapsed.map((i, idx) => {
										logger.log('[NetworkTest] filter i:%o', i);
										let match = filter(liveMatches, { match_id: i.match_id })[0];
										logger.log('[NetworkTest] filter match:%o', match);
										return (
											<div className="one-col" key={`chart-container-${idx}`}>
												<BarChart data={i.data} title={i.court} subtitle={i.info} />
												{this.renderMatch(match, idx)}
												{i.errors?.length > 0 && (
													<ul>
														{i.errors.map(error => {
															return <li>{error}</li>;
														})}
													</ul>
												)}
											</div>
										);
								  })
								: null}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkTest);

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import classNames from 'classnames';
import WimRedirect from 'appdir/components/general/WimRedirect';
import VideoPlayer from 'appdir/components/general/VideoPlayer';
import NewFlag from 'appdir/components/common-ui/NewFlag';
import ContentDate from 'appdir/components/cms/ContentDate/index.js';

class NewsListTile extends Component {
	constructor(props) {
		super(props);
		logger.log('[PullQuote] - props:%o', props);

		this.onVideoEvent = this.onVideoEvent.bind(this);

		let attributes = props['attributes'];
		attributes['type'] = attributes['type'].toLowerCase();

		this.state = {
			onClick: props.onClick,
			...attributes,
		};
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	getDateTitle() {
		if (this.state.date) {
			return (
				<span>
					<div className="content-timestamp">
						<ContentDate date={this.state.date}/>
					</div>
					<div className="content-title">{this.state.title}</div>
				</span>
			);
		} else {
			return (
				<span>
					<div className="content-timestamp" />
					<div className="content-title">{this.state.title}</div>
				</span>
			);
		}
	}

	onVideoEvent(event) {
		// logger.info('[NewsListTile] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				videoId: '',
			});
		}
	}

	/**
	 * return videoplayer if have current videoId
	 *   videoId set by click on news tile
	 */
	getVideo() {
		logger.log('[NewsListTile] getVideo - state:%o, props:%o', this.state, this.props);

		if (this.state.videoId) {
			let attributes = {
				playerId: 'main',
				contentId: null,
				cmsId: this.state.cmsId,
				videoUrl: this.state.videoUrl,
				title: this.state.title,
				thumb: this.state.thumb,
				autoplay: true,
				fullscreenMode: '',
				style: 'video-wrapper modal one-col',
				aspect: this.state.hasOwnProperty('aspect') ? this.state.aspect : '',
			};

			return <VideoPlayer attributes={attributes} onEvent={this.onVideoEvent} />;
		} else {
			return null;
		}
	}

	/**
	 * handle clicks from news tile
	 */
	onClick() {
		logger.log('[NewsListTile] onClick - type:%o', this.state.type);

		if (this.state.onClick) {
			this.state.onClick();
		} else if (this.state.type.toLowerCase() == 'video') {
			//this.props.play(this.state.contentId);
			this.setState({
				videoId: this.state.cmsId,
			});
		} else {
			this.setState({
				nav: this.state.url,
			});
		}
	}

	render() {
		logger.log('[NewsListTile] render - state:%o, props:%o', this.state, this.props);
		if (this.state.nav) {
			return <WimRedirect push to={this.state.nav} title={this.state.title} />;
		} else {
			let tyeptag = this.state.subType ? this.state.subType : this.state.type;

			let duration = '';
			// if (this.state.duration && this.state.duration != ''){
			//     //logger.log('[NewsTile] render - duration:%o', this.state.duration);
			//     duration = (<div className="duration-tag">{this.state.duration}</div>);
			// }

			let aspect = this.state.hasOwnProperty('aspect') ? this.state.aspect : '';

			let tileClasses = classNames('related-content-tile', this.state.type + '-tag');

			return (
				<div className={`${tileClasses} ${this.state.style}`} id={this.state.cmsId}>
					<span
						className="click-wrapper"
						onClick={() => this.onClick()}
						onKeyDown={() => (e.key === 'Enter' ? this.onClick() : null)}
						tabIndex={0}>
						<div className={`image-container ${aspect}`}>
							<img src={this.state.img} alt={this.state.title} />
							{this.state.type === 'video' ? <i className="wim-icon-video" /> : null}
						</div>
						<NewFlag
							data={{
								url: this.state.url,
								contentId: this.state.contentId,
								displayDate: this.state.date,
							}}
						/>
						{this.state.type === 'video' ? duration : null}

						<div className="content-text">
							<div className="type-tag">
								<div>{tyeptag}</div>
							</div>
							{this.getDateTitle()}
							<div className="content-description">{this.state.description}</div>
						</div>
					</span>
					{this.getVideo()}
				</div>
			);
		}
	}
}

export default NewsListTile;

/**
 Controller Initial State
*/

export default {
	userPreferences: {
		localTime: 'GMT',
		speed: 'M',
		temperature: 'C',
		aiAudio: {},
	},
	favourites: {
		show: false,
		players: [],
		articles: [],
		matches: [],
		videos: [],
	},
	platform: '',
	sidepanel: {
		userOpen: true,
	},
	slamtrackerPanel: {
		display: false,
	},
	scoring: {
		loaded: false,
	},
	scoringPreferences: {
		view: 'page',
	},
	content: {
		viewed: [], //list of content ids
	},
	privacy: {
		viewed: false,
	},
	championshipsRegistration: {
		viewed: false,
	},
	video: {
		main: {
			id: '',
			status: '',
			volume: '',
		},
		sidepanel: {
			id: '',
			status: '',
			volume: '',
		},
	},
	loaded: false,
	persist: true,
	forceUpdate: false,
	debugView: false,
	promoFeature: {
		// onboardVersionViewed: false,
		interestsViewed: false,
		favortingViewed: false,
		catchUpPromoVersionViewed: false,
		playerProfileVersionViewed: false
	},
	// drawsPromoVerify: false,
};

import { connect } from 'react-redux';
import { callFunction } from 'appdir/components/general/Util/Services';
import deps from 'dependencies';
import op from 'object-path';
import axios from 'axios';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Contact Us',
		});

		//logger.log('[ContactPage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				subjects: Config.contact.subjects,
				topics: Config.contact.topics,
			};

			logger.log('[ContactPage] action.mount - data:', data);

			dispatch({ type: deps.actionTypes.CONTACTPAGE_MOUNT, data: data });
		});
	},

	contactPageSubmit: (values, callback) => (dispatch, getState, store) => {
		let config = store.getState()['Config'];
		let api = op.get(config, 'services.guestbook', '');
		let FD = [];
		let sendValues = Object.assign({}, values);
		delete sendValues.emailconfirm;
		Object.keys(sendValues).map((key, index) => {
			let val = encodeURIComponent(sendValues[key]).replace(/[!'()*]/g, escape);
			FD.push(key + '=' + val);
		});
		let headers = { 'Content-type': 'application/x-www-form-urlencoded' };
		let sendData = FD.join('&');

		callFunction(
			op.get(config, 'services.hosts', []),
			op.get(config, 'services.guestbook', ''),
			'post',
			sendData,
			headers
		)
			.then(result => {
				logger.log('[ContactPage] contactPageSubmit result:%o', result);
				callback(result);
			})
			.catch(error => {
				logger.error('[ContactPage] contactPageSubmit error:%o', error);
				callback(error);
			});

		// const request = axios({
		// 	method: 'post',
		// 	url: api,
		// 	data: sendData,
		// 	headers: { 'Content-type': 'application/x-www-form-urlencoded' },
		// }).then(() => callback());
		// dispatch({ type: deps.actionTypes.CONTACTPAGE_SUBMIT, data: request });
	},

	newsletterSignUpSubmit: values => (dispatch, getState, store) => {
		let sendValues = Object.assign({}, values);
		delete sendValues.email;
		delete sendValues.eventid;
		delete sendValues.comment;
		delete sendValues.subject;
		delete sendValues.topic;
		delete sendValues.os;
		delete sendValues.apptype;
		delete sendValues.emailconfirm;
		sendValues.foundation = false;
		const request = axios.post('/relatedcontent/rest/wifi/signup', sendValues);
		dispatch({ type: deps.actionTypes.CONTACTPAGE_NEWSLETTERSIGNUP, data: request });
	},
};

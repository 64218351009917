import React from 'react';

import WimLink from 'appdir/components/general/WimLink';

/**
 * Return just the flag image
 * @param {*} props.attributes
 * @param {String} playerId - player ID
 * @param {String} name - player name
 * @param {String} seed - optional
 *
 * @returns
 */
const PlayerLink = props => {
	let { attributes = {} } = props;

	if (attributes?.playerId && attributes?.name) {
		return (
			<WimLink to={`/en_GB/players/overview/${attributes.playerId}.html`}>
				{attributes.name}
				{attributes?.seed && <span>{attributes?.seed}</span>}
			</WimLink>
		);
	} else {
		return attributes.name;
	}
};

export default PlayerLink;

import React from 'react';

const LoginSuccess = ({ data }) => {
	//gigya-conditional:className="viewport.width < 500? gigya-screen v2 portrait mobile"
	let success_classname = 'gigya-screen v2 portrait';

	return (
		<div id="gigya-login-success-screen" data-width="auto" className={success_classname} data-caption="Success">
			<div className="gigya-layout-row">
				<div className="gigya-composite-control gigya-message" style={{ display: 'block' }}>
					You Have Successully Logged In!
				</div>
			</div>
			<div className="gigya-layout-row" />
			<div className="gigya-clear" />
		</div>
	);
};

export default LoginSuccess;

import React from 'react';

export const BallotSVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg width={width} height={height} viewBox="0 -15 57 65" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd" strokeWidth={strokeWidth}>
			<path stroke="#fff" strokeLinejoin="round" stroke-dasharray="4.17" d="M23.377 34.051v4.766" />
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M59.183 6.817V33l-52.366.309v5.508H65v-32z"
			/>
			<path stroke="#fff" strokeLinecap="square" strokeLinejoin="round" d="M17.16 1v1.909" />
			<path
				stroke="#fff"
				strokeLinecap="square"
				strokeLinejoin="round"
				stroke-dasharray="3.52"
				d="M17.16 6.931V29.08"
			/>
			<path stroke="#fff" strokeLinecapp="square" strokeLinejoin="round" d="M17.16 31.091V33" />
			<path d="M1 1v8a8 8 0 1 1 0 16v8h58.183V1H1z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M37.24 17.846c0-.812-.16-1.36-1.406-1.692-.251 0-.274-.137-.274-.24v-.297c0-.114 0-.171.194-.217.377-.171 3.063-1.143 3.532-1.314.207-.09.427-.143.651-.16.172 0 .252.114.217.331v9.714c0 .332.172.709.435.709h1.142c.218 0 .263 0 .263.183v.548c0 .16 0 .275-.217.275s-1.463-.08-3.291-.08c-1.577 0-2.606.08-2.937.08-.138 0-.218-.057-.218-.275v-.514c0-.171.115-.217.275-.217h1.143c.262 0 .4-.114.434-.549v-2.445l.057-3.84zm.926-6.663a1.714 1.714 0 0 1-1.737-1.634 1.84 1.84 0 0 1 1.908-1.692 1.623 1.623 0 0 1 1.68 1.577 1.726 1.726 0 0 1-1.851 1.749z"
				fill="#fff"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

export const TicketsSVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 57 65">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth={strokeWidth}>
			<g stroke="#fff" strokeWidth={strokeWidth} transform="translate(0 1)">
				<path
					strokeLinecap="round"
					d="M45.754 45.283H56.14V17.594H.752v6.917a6.928 6.928 0 010 13.855v6.917h28.284"></path>
				<path strokeLinecap="square" strokeLinejoin="round" d="M14.596 17.594L14.596 19.458"></path>
				<path
					strokeDasharray="3.05"
					strokeLinecap="square"
					strokeLinejoin="round"
					d="M14.596 22.883L14.596 41.701"></path>
				<path strokeLinecap="square" strokeLinejoin="round" d="M14.596 43.419L14.596 45.283"></path>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M14.125 10.667h.932a2.998 2.998 0 012.998 2.998v3.93h-6.917v-3.93a2.998 2.998 0 012.998-2.998h-.011zM21.053 3.739h.932a2.998 2.998 0 012.997 2.998v10.857h-6.927V6.737a2.998 2.998 0 012.998-2.998z"></path>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M27.98.28h.932A2.998 2.998 0 0131.91 3.28v14.315h-6.928V3.28A2.998 2.998 0 0127.98.28z"></path>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M34.897 3.739h.932a2.998 2.998 0 012.998 2.998v10.857H31.91V6.737a2.998 2.998 0 012.987-2.998zM28.44 48.752c0-5.736 4.65-10.386 10.387-10.386V31.44a6.928 6.928 0 016.927-6.928v20.772a17.314 17.314 0 11-34.616 0"></path>
			</g>
		</g>
	</svg>
);

export const MediaSVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 61">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g stroke="#fff" strokeWidth={strokeWidth} transform="translate(-1041 -1802)">
				<g transform="translate(-8 230)">
					<g transform="translate(0 1342.3)">
						<g transform="translate(1050 230.436)">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M14.642 48.743l7.131 10.154 7.595-10.154h23.293c9.255 0 16.876-3.86 16.828-16.33V17.548C69.489 7.896 61.916 0 52.661 0H16.98C7.725 0 .153 7.896.153 17.547v14.867C.3 45.037 6.97 48.134 14.643 48.743z"></path>
							<path d="M33.403 16.685H17.52v12.607c0 3.47 2.96 4.727 4.774 4.727h16.79V21.6c0-3.733-2.735-4.914-5.68-4.914z"></path>
							<path
								strokeLinejoin="round"
								d="M42.4163867 22.2099461L51.5526032 16.6849666 51.5526032 33.0012843 42.4163867 27.8394183z"></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const PlayerSVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 73">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth={2.2}>
			<g stroke="#fff" strokeWidth={2.2} transform="translate(-487 -159) translate(453 161.5)">
				<g transform="translate(35.904 .218)">
					<g transform="translate(23.132 26.298)">
						<circle cx="15.359" cy="15.359" r="15.359"></circle>
						<path
							strokeLinecap="round"
							d="M1.563 16.385C10.256 13.72 15.553 8.87 17.45 1.832"
							transform="rotate(-2 9.507 9.108)"></path>
						<path
							strokeLinecap="round"
							d="M13.826 29.258c8.693-2.663 13.99-7.514 15.888-14.552"
							transform="rotate(-180 21.77 21.982)"></path>
					</g>
					<g transform="translate(.5)">
						<path d="M10.1372898 33.2180727L10.1372898 68.7498411 66.9414761 68.7498411 66.9414761 33.1715386"></path>
						<path d="M-2.84217094e-14 38.6294464L38.1895349 2.84217094e-14 76.233328 38.5280588"></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const CoachSVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 73">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth={2.2}>
			<g stroke="#fff" strokeWidth={2.2} transform="translate(-487 -159) translate(453 161.5)">
				<g transform="translate(35.904 .218)">
					<g transform="translate(23.132 26.298)">
						<circle cx="15.359" cy="15.359" r="15.359"></circle>
						<path
							strokeLinecap="round"
							d="M1.563 16.385C10.256 13.72 15.553 8.87 17.45 1.832"
							transform="rotate(-2 9.507 9.108)"></path>
						<path
							strokeLinecap="round"
							d="M13.826 29.258c8.693-2.663 13.99-7.514 15.888-14.552"
							transform="rotate(-180 21.77 21.982)"></path>
					</g>
					<g transform="translate(.5)">
						<path d="M10.1372898 33.2180727L10.1372898 68.7498411 66.9414761 68.7498411 66.9414761 33.1715386"></path>
						<path d="M-2.84217094e-14 38.6294464L38.1895349 2.84217094e-14 76.233328 38.5280588"></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const DebentureSVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 66 63">
		<g
			fill="none"
			fillRule="evenodd"
			stroke="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={strokeWidth}>
			<g stroke="#fff" strokeWidth={strokeWidth} transform="translate(1)">
				<path d="M25.143 34.514l-3.589 3.589A6.343 6.343 0 0117.017 40h-3.714C6.316 40 .647 45.654.629 52.64v9.497H64V52.64C63.981 45.654 58.312 40 51.326 40H47.61a6.343 6.343 0 01-4.48-1.863l-3.588-3.623"></path>
				<ellipse cx="32.32" cy="18.309" rx="15.051" ry="17.429"></ellipse>
			</g>
		</g>
	</svg>
);

export const MemberSVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 75 65">
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth={strokeWidth}>
			<g stroke="#fff" strokeWidth={strokeWidth} transform="translate(-189 -254)">
				<g transform="translate(44 178.59)">
					<g>
						<g>
							<g transform="translate(131 75)">
								<g transform="translate(14 .41)">
									<g transform="translate(11 .248)">
										<path d="M4.2581 62.881L0.8701 59.493 25.6701 34.694 29.0571 38.081z"></path>
										<path d="M57.485 27.732c-6.804 6.803-17.156 7.483-23.123 1.517-5.966-5.967-5.287-16.318 1.518-23.122C42.683-.677 53.035-1.356 59.002 4.61c5.965 5.967 5.287 16.32-1.517 23.123z"></path>
										<path d="M44.821 1.068L62.683 18.931"></path>
										<path d="M38.492 3.893L59.718 25.118"></path>
										<path d="M33.846 8.4L55.141 29.695"></path>
										<path d="M30.88 14.588L49.024 32.73"></path>
										<path d="M49.093 0.578L30.39 19.281"></path>
										<path d="M56.309 2.609L32.299 26.618"></path>
										<path d="M60.932 7.232L36.869 31.294"></path>
										<path d="M62.963 14.447L44.041 33.369"></path>
										<path d="M30.405 20.92s.677 9.701-3.927 14.304"></path>
										<path d="M42.779 33.293s-9.932-.906-14.534 3.697"></path>
									</g>
									<g transform="translate(0 .248)">
										<path d="M40.5052 36.8009L63.0282 59.3239 59.6422 62.7109 37.1182 40.1879"></path>
										<path d="M27.98 6.197c6.804 6.804 7.482 17.156 1.517 23.122-5.966 5.966-16.32 5.287-23.122-1.517C-.43 20.998-1.11 10.646 4.857 4.68 10.824-1.286 21.176-.607 27.98 6.197z"></path>
										<path d="M1.316 18.86L19.178 0.998"></path>
										<path d="M4.141 25.188L25.366 3.963"></path>
										<path d="M8.648 29.835L29.943 8.54"></path>
										<path d="M14.835 32.8L32.978 14.657"></path>
										<path d="M0.826 14.588L19.529 33.291"></path>
										<path d="M2.857 7.373L26.867 31.382"></path>
										<path d="M7.48 2.749L31.542 26.811"></path>
										<path d="M14.695 0.718L33.617 19.64"></path>
										<path d="M21.166 33.276s9.313-1.295 13.916 3.31"></path>
										<path d="M33.54 20.902s-1.294 9.313 3.31 13.916"></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const CommunitySVG = ({ strokeWidth = 2, width = 50, height = 50 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width={width}
		height={height}
		viewBox="0 0 36 35">
		<defs>
			<path id="path-1" d="M0 33L33.5021862 33 33.5021862 0 0 0z"></path>
		</defs>
		<g fill="none" fillRule="evenodd" stroke="none" strokeWidth={1}>
			<g transform="translate(-1098 -215)">
				<g transform="translate(1074.5 146)">
					<g transform="translate(25 70)">
						<path
							stroke="#FFF"
							d="M31.5899719 12.3571525L31.5899719 32.8425076 16.2216237 32.8425076 16.2216237 12.3571525"></path>
						<path stroke="#FFF" d="M14.592 4.321L20.482 4.321"></path>
						<path stroke="#FFF" d="M9.053 4.321L12.042 4.321"></path>
						<g>
							<path
								stroke="#FFF"
								d="M16.0766387 12.409608L0.25115613 12.409608 4.16121475 4.32107002 6.54707196 4.32107002"></path>
							<path
								stroke="#FFF"
								d="M33.1790732 12.409608L14.6325225 12.409608 23.6851068 0.259473393z"></path>
							<path
								stroke="#FFF"
								d="M2.01917305 12.3571525L2.01917305 32.8425076 16.0863737 32.8425076"></path>
							<path
								stroke="#FFF"
								d="M7.308 29.397v-3.53c0-.996.816-1.812 1.813-1.812a1.82 1.82 0 011.814 1.813v3.53H7.308z"></path>
							<path
								stroke="#FFF"
								d="M7.308 20.396v-3.53c0-.997.816-1.813 1.813-1.813a1.82 1.82 0 011.814 1.814v3.529H7.308z"></path>
							<path
								stroke="#FFF"
								d="M21.3853837 32.8131136L21.3853837 24.9003723 26.426149 24.9003723 26.426149 32.8131136"></path>
							<mask id="mask-2" fill="#fff">
								<use xlinkHref="#path-1"></use>
							</mask>
							<path
								stroke="#FFF"
								d="M12.0902755 6.2870945L14.6065625 6.2870945 14.6065625 1.71512039 12.0902755 1.71512039z"
								mask="url(#mask-2)"></path>
							<path
								stroke="#FFF"
								d="M6.58607489 6.2870945L9.1023619 6.2870945 9.1023619 1.71512039 6.58607489 1.71512039z"
								mask="url(#mask-2)"></path>
							<path stroke="#FFF" d="M16.286 22.336L31.526 22.336" mask="url(#mask-2)"></path>
							<path
								stroke="#FFF"
								d="M22.092 20.01v-3.528c0-.998.816-1.814 1.814-1.814a1.82 1.82 0 011.814 1.814v3.528h-3.628z"
								mask="url(#mask-2)"></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const TransportSVG = ({ strokeWidth = 2, width = 71, height = 50 }) => (
	<svg width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
		<g id="Icons" stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
			<path
				d="M78.582 0c4.087.027 6.877 1.94 8.314 5.12l.135.312 5.332 13.263.162.036c3.02.716 5.896 3.689 6.216 7.56l.021.334.01.312v18.378h-5.46l.001 4.663c.009 3.47-2.365 5.777-5.456 6.004l-.267.016-.27.004c-3.25-.013-5.855-2.262-5.998-5.73l-.008-.291v-4.667H46.62v4.664c.005 3.437-2.371 5.75-5.456 6l-.266.017-.269.006c-3.26.013-5.864-2.227-6.005-5.727l-.007-.294v-4.667l-5.387.001V26.938c-.009-4.066 2.843-7.276 5.93-8.141l.25-.065.164-.037L40.971 5.44C42.205 2.296 44.79.287 48.588.028l.383-.02.38-.008h29.232zm-29.22 1.846c-3.322.022-5.476 1.498-6.565 4.006l-5.913 14.523-.542.063c-2.472.287-5.117 2.847-5.26 6.2l-.005.298v16.531h5.388v6.51c.002 2.598 1.787 4.186 4.157 4.177 2.288-.01 4.035-1.505 4.146-3.922l.005-.253v-6.512H83.16l-.001 6.51c.002 2.565 1.796 4.166 4.164 4.176 2.29.01 4.028-1.473 4.136-3.919l.005-.256v-6.512h5.457l.001-16.525c-.027-3.3-2.462-5.89-4.878-6.428l-.226-.045-.227-.032-.546-.063L85.321 6.13c-1.074-2.6-3.148-4.151-6.392-4.276l-.352-.008H49.362zM84.544 23.52c3.118.03 5.63 2.512 5.613 5.566.017 3.064-2.495 5.548-5.632 5.58-3.071-.032-5.577-2.51-5.61-5.584.032-2.967 2.398-5.39 5.349-5.554l.28-.009zm-41.138-.003c3.09.033 5.598 2.512 5.609 5.575-.011 3.06-2.518 5.542-5.628 5.574-3.1-.032-5.608-2.514-5.612-5.579.004-2.97 2.37-5.395 5.347-5.561l.284-.009zm41.123 1.848-.207.009c-1.968.131-3.54 1.755-3.562 3.708.023 2.045 1.713 3.716 3.765 3.738 2.113-.022 3.797-1.687 3.786-3.734.012-1.963-1.554-3.58-3.56-3.712l-.22-.01zm-41.14-.003-.207.01c-1.992.133-3.56 1.752-3.562 3.715.004 2.044 1.688 3.711 3.767 3.733 2.088-.022 3.774-1.691 3.782-3.728-.008-1.966-1.577-3.587-3.561-3.72l-.22-.01zM76.154 7.62c1.708-.01 2.736.83 3.342 2.35l.088.233 4.042 9.424h-39.25l4.024-9.38c.604-1.643 1.581-2.549 3.242-2.622l.255-.005h24.256zm.011 1.846H51.891c-.89-.005-1.314.317-1.7 1.266l-.076.199-2.939 6.85h33.65l-2.96-6.903c-.348-.976-.735-1.364-1.525-1.407l-.176-.004z"
				transform="translate(-17, 4) scale(.8)"
				fill="#ffffff"
				fillRule="nonzero"
				id="Transport"
			/>
		</g>
	</svg>
);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PlayerGeneralInfoWebview from './PlayerGeneralInfoWebview';
import PlayerProfessionalInfoWebview from './PlayerProfessionalInfoWebview';
import PlayerMatches from 'appdir/components/pages/PlayerPage/PlayerMatches';
import PlayerMedia from 'appdir/components/pages/PlayerPage/PlayerMedia';

import Footer from 'appdir/components/general/Footer';
import Divider from 'appdir/components/common-ui/Divider';
const op = require('object-path');
import axios from 'axios';

import { measureInAppContext } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerPageWebview
 * TODO: Change page name to PlayerPage on golive
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => ({
	...state['PlayerPageWebview'],
	playerDetail: state['Tournament']?.data?.playerDetail,
	playerStatus: state['CommonData']?.playerStatus,
	playerStats: state['Tournament']?.data?.playerStats,
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	getPlayerDetail: playerId => dispatch(deps.actions.Tournament.getPlayerDetail({ playerId: playerId })),
	getPlayerStatus: playerId => dispatch(deps.actions.Tournament.getPlayerStatus({ playerId: playerId })),
	getPlayerStats: ids => dispatch(deps.actions.Tournament.getPlayerStats({ playerIds: ids })),
});

const mobileMatch = window.matchMedia('(max-width: 400px)');

class PlayerPageWebview extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
			playerMatchesObj: null,
			playerObj: null,
			playerContentObj: null,
			favorite_players: [],
		};
		this.initialLoad = true;
		this.fetchData = true;
		this.playerName = '';
		this.getPlayerStatus = true;
		this.getPlayerStats = true;
	}

	componentDidMount() {
		logger.log('[PlayerPageWebview] PlayerPageWebview mounted');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.initialLoad || nextProps.match.params.player !== this.state.match.params.player) {
			this.initialLoad = false;
			this.fetchData = true;
			this.getPlayerStatus = true;
			this.getPlayerStats = true;
			this.setState(prevState => {
				return { ...prevState, ...nextProps, playerObj: null, playerMatchesObj: null, playerContentObj: null };
			});
		} else {
			this.setState(prevState => {
				return { ...prevState, ...nextProps };
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.getPlayerStatus) {
			this.getPlayerStatus = false;
			this.props?.getPlayerStatus(this.state.match.params.player);
		}
		if (this.getPlayerStats) {
			this.getPlayerStats = false;
			this.props.getPlayerStats([`${this.state.match.params.player}`]);
			// logger.log('loading getPlayerStats', this.props.playerStats);
		}

		if (this.state.PlayerPageConfig && this.fetchData && this.props.playerStatus) {
			this.fetchPlayerData();
		}
		// add metrics call for PlayerProfessionalInfoWebview tabs here to keep track of route history
		if (prevProps.match.params.tabName !== this.props.match.params.tabName) {
			measureInAppContext({
				pageTitle: 'Player Profile',
				action: 'Tab Selection',
				args: [this.props.match.params.tabName],
				context: [{ pp_playerID: this.state.playerObj.id }],
				metricType: 'action',
			});
		}
	}

	componentWillUnmount() {
		logger.log('[PlayerPageWebview] PlayerPageWebview unmounted');
		this.state.playerObj = null;
	}

	fetchPlayerData() {
		this.fetchData = false;
		const { lang = 'en_GB', player = '' } = this.state.match.params;

		let pathObj = { ...this.state.PlayerPageConfig.pathObj };
		pathObj.playerMatchup = pathObj.playerMatchup.replace('<playerId>', player);
		pathObj.playerRelated = pathObj.playerRelated.replace('<playerId>', player);
		pathObj.playerDetails = pathObj.playerDetails.replace('<id>', player);

		// TODO: update page name to PlayerPage on golive
		deps.services.PlayerPage.getAllPlayerInfo(pathObj).then(
			axios.spread((playerMatchesObj, playerObj, playerContentObj) => {
				logger.log('fetchPlayerData');
				let isPlayerObj = Object.keys(playerObj).length !== 0;

				this.playerName = isPlayerObj ? playerObj.first_name + ' ' + playerObj.last_name : '';

				// MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				// 	pageTitle: 'Player Page Webview',
				// 	player: playerObj,
				// });
				measureInAppContext({
					pageTitle: 'Player Profile',
					action: playerObj.id,
					args: [],
					context: [
						{ pp_playerID: playerObj.id },
						{ section: 'Player Profiles' },
						{ player_name: `${playerObj.first_name} ${playerObj.last_name}` },
					],
					metricType: 'state',
				});

				this.setState(prevState => {
					logger.log('[PlayerPageWebview] - Matches Data results %o', playerMatchesObj);
					logger.log('[PlayerPageWebview] - playerContentObj Data results %o', playerContentObj);
					logger.log('[PlayerPageWebview] - Player info Data results %o', playerObj['first_name']);
					return Object.assign({}, prevState, {
						playerObj,
						playerMatchesObj,
						playerContentObj,
						status: 'loaded',
					});
				});
			})
		);
	}

	render() {
		logger.log('[PlayerPageWebview] render - state:%o, props: %o', this.state, this.props);

		let playerObj = {};

		if (this.state.playerObj) {
			playerObj = { ...this.state.playerObj };
		}
		let backData = { category: '', history: this.props.history };
		return (
			<Template>
				{this.state.playerObj ? (
					Object.keys(this.state.playerObj).length > 0 ? (
						<>
							<div className="content-main">
								<noindex>
									<div className="player-profile">
										<div className="component player-info">
											<PlayerGeneralInfoWebview
												data={{ playerObj, playerConfig: this.state.PlayerPageConfig }}
											/>
											<PlayerProfessionalInfoWebview
												data={{
													playerObj: playerObj,
													playerConfig: this.state.PlayerPageConfig,
													tabName: this.props.match.params.tabName,
													matchData: this.state.playerMatchesObj,
													playerStatus: op.get(
														this.props,
														`playerStatus.${this.props.match.params.player}.result`,
														false
													),
													playerStats: this.props?.playerStats?.data[0]?.player_stats,
												}}
											/>
											<Divider />
											<PlayerMedia
												relatedContent={this.state.playerContentObj}
												playerID={playerObj.id}
												mobile={mobileMatch.matches}
											/>
											<Divider />
										</div>
										<div className="four-col player-matches-details">
											{this.state.playerMatchesObj ? (
												<PlayerMatches
													matchData={this.state.playerMatchesObj}
													playerConfiguration={this.state.PlayerPageConfig}
													playerID={this.state.match.params.player}
												/>
											) : null}
										</div>
									</div>
								</noindex>
								{/* <PrevNextBack attributes={backData} /> */}
							</div>
						</>
					) : (
						<div className="content-main">
							<noindex>
								<div className="no-player-data">
									<div className="no-data-text">No Player Data Available</div>
								</div>
							</noindex>
						</div>
					)
				) : (
					<div className="content-main">
						<LoadingIndicator />
					</div>
				)}
				{/* <Footer /> */}
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPageWebview);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import uniqBy from 'lodash/uniqBy';
import TableComponent from 'appdir/components/common-ui/TableComponent';
import Lookup from 'appdir/components/general/Util/Lookup';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MatchStats
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['MatchStats'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchStats.mount()),
});

const createTableHeaders = playersInfo => {
	if (playersInfo.player1.firstNameB) {
		return [
			`${playersInfo.player1.firstNameA.substring(0, 1)}${playersInfo.player1.lastNameA.substring(0, 1)}` +
				'/' +
				`${playersInfo.player1.firstNameB.substring(0, 1)}${playersInfo.player1.lastNameB.substring(0, 1)}`,
			'',
			`${playersInfo.player2.firstNameA.substring(0, 1)}${playersInfo.player2.lastNameA.substring(0, 1)}` +
				'/' +
				`${playersInfo.player2.firstNameB.substring(0, 1)}${playersInfo.player2.lastNameB.substring(0, 1)}`,
		];
	} else {
		return [
			`${playersInfo.player1.firstNameA.substring(0, 1)}${playersInfo.player1.lastNameA.substring(0, 1)}`,
			'',
			`${playersInfo.player2.firstNameA.substring(0, 1)}${playersInfo.player2.lastNameA.substring(0, 1)}`,
		];
	}
};

const formatTableData = data => {
	let formattedData = [];
	return data.map((d, i) => {
		// logger.log('[MatchStats] d:%o', d);
		let playerWonObj = null;

		/** RLL 7/1/2022 for values that are equal, don't highligh either value */
		if (d.player1.value == d.player2.value) {
			playerWonObj = 'neither';
		} else if (d.metric.toLowerCase() == 'double faults' || d.metric.toLowerCase() == 'unforced errors') {
			/** RLL 7/1/2022 for unforced errors and double faults, the lower number is better */
			playerWonObj = d.player1.value > d.player2.value ? 'player_two' : 'player_one';
		} else if (d.metric.toLowerCase().indexOf('serve speed') > -1) {
			/** RLL 7/1/2022 for serve speeds need to parse out the mph or kmh */
			playerWonObj = parseInt(d.player1.value) > parseInt(d.player2.value) ? 'player_one' : 'player_two';
		} else {
			playerWonObj = d.player1.value > d.player2.value ? 'player_one' : 'player_two';
		}

		let winObj = {};
		winObj['player1'] =
			playerWonObj !== 'player_one' || playerWonObj == 'neither'
				? `<span class="not-better">${d.player1.display}</span>`
				: `<span>${d.player1.display}</span>`;
		winObj['player2'] =
			playerWonObj !== 'player_two' || playerWonObj == 'neither'
				? `<span class="not-better">${d.player2.display}</span>`
				: `<span>${d.player2.display}</span>`;
		return {
			player1: winObj.player1,
			metric: d.metric,
			player2: winObj.player2,
		};
	});
};

const formatMatchStatsData = (matchStatsData, headerConfig) => {
	let baseStats = matchStatsData.base_stats;
	let serveStats = matchStatsData.serve_stats;
	let distanceStats = matchStatsData.distance_run;
	let player1BaseStats = baseStats ? baseStats.team_1 : null;
	let player2BaseStats = baseStats ? baseStats.team_2 : null;
	let player1ServeStats = serveStats ? serveStats.team_1 : null;
	let player2ServeStats = serveStats ? serveStats.team_2 : null;
	let player1Distance = distanceStats ? distanceStats.team_1 : [null];
	let player2Distance = distanceStats ? distanceStats.team_2 : [null];

	let formatted_data = [];
	let tableHeader = Lookup.lookup;
	let objKeys = [];

	if (baseStats) {
		if (baseStats.team_1) {
			objKeys = uniqBy(
				objKeys.concat(
					Object.keys({ ...baseStats.team_1 }).filter(d => {
						return Object.keys(tableHeader).indexOf(d) !== -1;
					})
				),
				x => {
					return x;
				}
			);
		}
	}

	/* Commenting out for 2019 ENH - simplifies stats  */
	// if (serveStats) {
	// 	if (serveStats.team_1) {
	// 		objKeys = uniqBy(
	// 			objKeys.concat(
	// 				Object.keys({ ...serveStats.team_1 }).filter(d => {
	// 					return Object.keys(tableHeader).indexOf(d) !== -1;
	// 				})
	// 			),
	// 			x => {
	// 				return x;
	// 			}
	// 		);
	// 	}
	// }

	// objKeys = Object.keys({...baseStats.team_1, ...serveStats.team_1}).filter(d => {
	//     return Object.keys(tableHeader).indexOf(d) !== -1;
	// });
	if (objKeys.length > 0) {
		formatted_data = objKeys.map(d => {
			let player1Data, player1Display, player2Data, player2Display;

			if (
				(baseStats.team_1[d] || baseStats.team_1[d] == 0) &&
				(baseStats.team_2[d] || baseStats.team_2[d] == 0)
			) {
				player1Data = baseStats.team_1[d];
				player1Display = baseStats.team_1[d];
				player2Data = baseStats.team_2[d];
				player2Display = baseStats.team_2[d];
			} else if (
				(serveStats.team_1[d] || Array.isArray(serveStats.team_1[d])) &&
				(serveStats.team_2[d] || Array.isArray(serveStats.team_2[d]))
			) {
				player1Data = serveStats.team_1[d][1];
				player1Display = serveStats.team_1[d][1];
				player2Data = serveStats.team_2[d][1];
				player2Display = serveStats.team_2[d][1];
			} else {
				player1Data = null;
				player1Display = null;
				player2Data = null;
				player2Display = null;
			}

			if (d == 't_p_w_opp_srv') {
				player1Data =
					baseStats.team_1['t_f_srv_w'] + baseStats.team_1['t_s_srv_w'] + baseStats.team_1['t_p_w_opp_srv'];
				player1Display = player1Data;
				player2Data =
					baseStats.team_2['t_f_srv_w'] + baseStats.team_2['t_s_srv_w'] + baseStats.team_2['t_p_w_opp_srv'];
				player2Display = player2Data;
				//t_f_srv_w + t_s_srv_w + t_p_w_opp_srv
			}

			return {
				player1: {
					display: player1Display || player1Display == 0 ? player1Display : '',
					value: player1Data,
				},
				metric: tableHeader[d].toUpperCase(),
				player2: {
					display: player2Display || player2Display == 0 ? player2Display : '',
					value: player2Data,
				},
			};
		});
	}

	/* Commenting out some of the data mapping for 2019 ENH - simplifies stats  */
	formatted_data = formatted_data.concat([
		{
			player1: {
				display: player1BaseStats
					? `${player1BaseStats.t_f_srv_in}/${player1BaseStats.t_f_srv} (${player1BaseStats.f_srv_pct}%)`
					: '',
				value: player1BaseStats ? player1BaseStats.f_srv_pct : null,
			},
			metric: '1ST SERVE IN',
			player2: {
				display: player2BaseStats
					? `${player2BaseStats.t_f_srv_in}/${player2BaseStats.t_f_srv} (${player2BaseStats.f_srv_pct}%)`
					: '',
				value: player2BaseStats ? player2BaseStats.f_srv_pct : null,
			},
		},
		// {
		// 	player1: {
		// 		display: player1BaseStats ? `${player1BaseStats.t_f_srv_w}/${player1BaseStats.t_f_srv_in} (${player1BaseStats.w_pct_f_srv}%)` : '',
		// 		value: player1BaseStats ? player1BaseStats.w_pct_f_srv : null,
		// 	},
		// 	metric: '1ST SERVE POINTS WON',
		// 	player2: {
		// 		display: player2BaseStats ? `${player2BaseStats.t_f_srv_w}/${player2BaseStats.t_f_srv_in} (${player2BaseStats.w_pct_f_srv}%)` : '',
		// 		value: player2BaseStats ? player2BaseStats.w_pct_f_srv : null,
		// 	},
		// },
		// {
		// 	player1: {
		// 		display: player1BaseStats ? `${player1BaseStats.t_s_srv_w}/${player1BaseStats.t_s_srv} (${player1BaseStats.w_pct_s_srv}%)` : '',
		// 		value: player1BaseStats ? player1BaseStats.w_pct_s_srv : null,
		// 	},
		// 	metric: '2ND SERVE POINTS WON',
		// 	player2: {
		// 		display: player2BaseStats ? `${player2BaseStats.t_s_srv_w}/${player2BaseStats.t_s_srv} (${player2BaseStats.w_pct_s_srv}%)` : '',
		// 		value: player2BaseStats ? player2BaseStats.w_pct_s_srv : null,
		// 	},
		// },
		{
			player1: {
				display: player1BaseStats
					? `${player1BaseStats.t_bp_w}/${player1BaseStats.t_bp} (${player1BaseStats.bp_con_pct}%)`
					: '',
				value: player1BaseStats ? parseInt(player1BaseStats.bp_con_pct) : null,
			},
			metric: 'BREAK POINTS WON',
			player2: {
				display: player2BaseStats
					? `${player2BaseStats.t_bp_w}/${player2BaseStats.t_bp} (${player2BaseStats.bp_con_pct}%)`
					: '',
				value: player2BaseStats ? parseInt(player2BaseStats.bp_con_pct) : null,
			},
		},
		// {
		// 	player1: {
		// 		display: player1BaseStats ? `${player1BaseStats.t_np_w}/${player1BaseStats.t_na} (${player1BaseStats.na_pct}%)` : '',
		// 		value: player1BaseStats ? parseInt(player1BaseStats.na_pct) : null,
		// 	},
		// 	metric: 'NET POINTS WON',
		// 	player2: {
		// 		display: player2BaseStats ? `${player2BaseStats.t_np_w}/${player2BaseStats.t_na} (${player2BaseStats.na_pct}%)` : '',
		// 		value: player2BaseStats ? parseInt(player2BaseStats.na_pct) : null,
		// 	},
		// },
		// {
		// 	player1: {
		// 		display: `${player1Distance[0] ? player1Distance[0] : ''}`,
		// 		value: parseInt(player1Distance[0]),
		// 	},
		// 	metric: 'DISTANCE COVERED (M)',
		// 	player2: {
		// 		display: `${player2Distance[0] ? player2Distance[0] : ''}`,
		// 		value: parseInt(player2Distance[0]),
		// 	},
		// },
		// {
		// 	player1: {
		// 		display: `${player1Distance[0] || player1Distance[0] === 0 ? parseFloat((parseFloat(player1Distance[0].split(' ')[0]) / player1BaseStats.t_p_w_opp_srv).toFixed(2)) : ''}`,
		// 		value: player1Distance[0] || player1Distance[0] === 0 ? parseFloat((parseFloat(player1Distance[0].split(' ')[0]) / player1BaseStats.t_p_w_opp_srv).toFixed(2)) : null,
		// 	},
		// 	metric: 'DIST. COVERED/PT. (M)',
		// 	player2: {
		// 		display: `${player2Distance[0] || player2Distance[0] === 0 ? parseFloat((parseFloat(player2Distance[0].split(' ')[0]) / player2BaseStats.t_p_w_opp_srv).toFixed(2)) : ''}`,
		// 		value: player2Distance[0] || player2Distance[0] === 0 ? parseFloat((parseFloat(player2Distance[0].split(' ')[0]) / player2BaseStats.t_p_w_opp_srv).toFixed(2)) : null,
		// 	},
		// },
	]);

	// reorder data - 1st Serve In and Break Points Won to move above Winner
	let ordered_formatted_data = formatted_data.concat(formatted_data.splice(2, 4));

	// remove Net Points Won data
	let remove = ordered_formatted_data.splice(6, 1);

	// logger.log('[MatchStats] formatMatchStatsData formatted_data:%o, ordered_formatted_data:%o', formatted_data, ordered_formatted_data);

	return ordered_formatted_data;
};

class MatchStats extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
		this.fetchMatchData = true;
	}

	componentDidMount() {
		logger.log('[MatchStats] componentDidMount id:%o', this.state.matchId);
		// if (!this.state.matchStatsConfig) {
		this.props.mount();
		// }
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.matchStatsConfig && !this.state.matchData && this.fetchMatchData) {
			this.fetchMatchData = false;
			let matchId = this.state.matchId;
			let matchStatsPath = this.state.matchStatsConfig.matchStatsPath.replace('<matchId>', matchId);
			logger.log(`[MatchStats] componentDidUpdate - path:%o`, matchStatsPath);

			deps.services.MatchStats.getMatchStats(matchStatsPath).then(data => {
				this.setState(prevState => {
					return { ...prevState, matchData: data };
				});
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		let propsData = {};
		let style = this.state.style ? this.state.style : 'two-col inline';

		if (this.state.matchData) {
			let headerConfigData = this.state.matchStatsConfig.tableHeaders.headers;
			let tableHeader = createTableHeaders({
				player1: this.state.matchData.team1,
				player2: this.state.matchData.team2,
			});
			let formattedData = formatMatchStatsData(this.state.matchData, headerConfigData);
			let tableData = formatTableData(formattedData);

			propsData = {
				headers: tableHeader,
				data: tableData,
				tableTitle: 'Match Statistics',
				tableContainer: 'match-stats',
			};
		}
		return this.state.matchData ? (
			<div className={`match-stats ${style}`}>
				<span>
					<TableComponent attributes={propsData} />
				</span>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchStats);

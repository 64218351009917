import React, { Component } from 'react';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';

class SocialShare extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
			sharePopup: 'hide',
		};
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.wrapperRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		//logger.log('[SocialShare] handleClickOutside - event:%o', event);
		if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
			this.setState({
				sharePopup: 'hide',
			});
		}
	}

	toggleShare() {
		//logger.log('[ContentPage] toggleShare - sharePopup:%o', this.state.sharePopup);
		this.setState({
			sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	onShareClick() {
		//logger.log('[ContentPage] onShareClick');
		this.setState({
			sharePopup: 'hide',
		});
	}

	render() {
		logger.log('[SocialShare] render - state:%o', this.state);

		return (
			<div className="byline--share" ref={this.wrapperRef}>
				<noindex>
					<div
						className="byline--share-button"
						onClick={() => {
							this.toggleShare();
						}}>
						<i className="wim-icon-share" />
						SOCIAL
					</div>
					<div className={`byline--share-wrapper ${this.state.url ? 'threecol' : ''}`}>
						<ShareMenu
							type="popup"
							view={this.state.sharePopup}
							orient="top"
							onClick={this.onShareClick}
							share={this.state.url}
						/>
					</div>
				</noindex>
			</div>
		);
	}
}

export default SocialShare;

import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[MatchInsights] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[MatchInsights] action.mount - Config: %o', Config);
			let data = {
				status: 'loaded',
			};
			dispatch({ type: deps.actionTypes.MATCH_INSIGHTS_MOUNT, data: data });
		});
	},
	unmount: params => (dispatch, getState, store) => {
		//logger.log('[MatchInsights] action.mount - ensureConfig');
		let data = {
			status: 'init',
		};
		dispatch({ type: deps.actionTypes.MATCH_INSIGHTS_UNMOUNT, data: data });
	},
};

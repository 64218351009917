import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.CONTENT_LOAD_SECURE:
			newState = {
				...state,
				...action.data,
				data: '',
			};
			//logger.log('[ContentPageSecure] CONTENT_LOAD_SECURE - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.CONTENT_UNMOUNT_SECURE:
			newState = {
				...state,
				...action.data,
				data: '',
			};
			//logger.log('[ContentPageSecure] CONTENT_UNMOUNT_SECURE - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

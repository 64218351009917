import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import op from 'object-path';
import { findCountry} from 'appdir/components/general/Util/Convert';

const Flag = props => {
	const Config = useSelector(state => state['Config']);

	const [state, setState] = useState({
		code: props.code,
		width: props.width,
		border: props.border || false
	});

	const renderFlag = (code, width, height) => {
		let country = findCountry(code);
		let outline = op.get(country, 'outline', 'true') == 'true';
		let code2 = country.alpha2code.toLowerCase();
		let url = `${state.host}${code}.svg`;
		let flagStyle = {
			"width": width,
			"height": "auto",
			"outline": props.border && outline ? "1px solid lightgrey" : "1px none lightgrey",
    		"outlineOffset": "-1px"
		}

		return (
			<img style={flagStyle} alt={code} src={`${state.host}${code2}.svg`} />
		)
	}

	useEffect(() => {
		const host = op.get(Config, 'flagImagePathLarge', false);
		logger.log('[Flag] useEffect host:%o', host);

		if (host != state.host) {
			setState({
				...state,
				//host: host,
				host: `https://assets.wimbledon.com/images/flags2/`
			});
		}
	}, [Config]);

	return (
		

		(
			state.host ? (
				<>{renderFlag(props.code, props.width, props.height)}</>
			) : null
		)
	);
};

export default Flag;

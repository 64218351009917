import React from 'react';
import CourtBoxHeader from './CourtBoxHeader';

const CourtBox = ({ court, courtKey }) => {
	const courts = court?.courts;

	return (
		<>
			{courts && (
				<div className="court-availability__court-box">
					<CourtBoxHeader court={court} courtKey={courtKey} />
					{courts?.map((court, index) => {
						return (
							<React.Fragment key={`${court.name}-${index}`}>
								<div className="court-availability__court-box-row">
									<div>
										<p
											className={`${court.status.replace(
												/\s/g,
												''
											)} court-availability__court-box-row--name`}>
											{court.name}
										</p>
										{court.status !== 'Open' && (
											<p
												className={`${court.status.replace(
													/\s/g,
													''
												)} court-availability__court-box-row--status`}>
												{court.status}
											</p>
										)}
									</div>
									<div>
										<p className={court.status.replace(/\s/g, '')}>{court.status}</p>
									</div>
								</div>
							</React.Fragment>
						);
					})}
				</div>
			)}
		</>
	);
};

export default CourtBox;

import deps from 'dependencies';

const Router = (state = {}, action) => {
	switch (action.type) {
		case deps.actionTypes.UPDATE_ROUTE: {
			const { location, params } = action;
			let historyArray = state.historyArray ? state.historyArray : [];
			if (location.search !== '') {
				historyArray.push(location.pathname + location.search);
			} else {
				historyArray.push(location.pathname);
			}
			return {
				...location,
				params,
				historyArray,
			};
		}
		default: {
			return state;
		}
	}
};

export default Router;

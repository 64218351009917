import React from 'react';
import { useMemberContext } from '../../context';
import { useDiningContext } from './elements';
import MeasurementUtils from 'appdir/lib/analytics';
import BookingConfirmationDetails from './BookingConfirmationDetails';

const BookingConfirmation = props => {
	const {
		currentUser: {
			profile: { firstName, lastName },
		},
	} = useMemberContext();
	const {
		date,
		size,
		selectedTime,
		note,
		service,
		bookingReservationId,
		bookingVenueId,
		cancelBooking,
		venueTitle,
	} = useDiningContext();

	MeasurementUtils.dispatchMeasurementCall('Submit Booking Reservation Success');

	return (
		<BookingConfirmationDetails
			firstName={firstName}
			lastName={lastName}
			selectedTime={selectedTime}
			date={date}
			size={size}
			note={note}
			cancelBooking={cancelBooking}
			service={service}
			onCloseModal={() => props.onCloseModal()}
			resId={bookingReservationId}
			venueId={bookingVenueId}
			venueTitle={venueTitle}
		/>
	);
};

export default BookingConfirmation;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import DividerHeader from 'appdir/components/common-ui/DividerHeader';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: YTDStats
 * -----------------------------------------------------------------------------
 */
const HeadtoHeadYTDStats = props => {

    const ytd = useSelector((state) => state?.['Slamtracker']?.ytd_stats ?? {});
    const disclaimer = useSelector((state) =>  state?.['Config']?.slamtracker?.disclaimerConfig?.ytd_stats ?? {});
    const tournamentStatsConfig = useSelector((state) => state?.['Config']?.slamtracker?.tournamentStatsConfig ?? []);
    const p1 = ytd?.team1;
    const p2 = ytd?.team2;

    const getStatRow = (statArray, i) => {
        let title = statArray[1] || "";
        let p1_stat_val = "";
        let p2_stat_val = "";
        let winner = "";
        let pct_val = false;

        if (title == "") {
            return null;
        }

        p1_stat_val = p1?.player_stats?.[title] || 'N/A';
        p2_stat_val = p2?.player_stats?.[title] || 'N/A';
        
        // logger.log('[HeadtoHeadYTDStats] p1_stat_val:%o, p2_stat_val:%o,', p1_stat_val, p2_stat_val);

        if(p1_stat_val == 'N/A') { 
            winner = 't2'; 
        } else if (p2_stat_val == 'N/A') { 
            winner = 't1'; 
        } else if(statArray[2] == "high") {
            if(p1_stat_val > p2_stat_val) { winner = 't1'; }
            else if (p2_stat_val > p1_stat_val) { winner = 't2'; }
        } else {
            if(p1_stat_val < p2_stat_val) { winner = 't1'; }
            else if (p2_stat_val < p1_stat_val) { winner = 't2'; }
        }

        if(title.indexOf('pct') > 0) {
            pct_val = true;
        }

        return (
            <div className="stats-content-row" key={`tournament-stat-row-${i}`}>
                <div className={`t1${winner == 't1' ? ' winner' : ''}`}>{`${p1_stat_val}${pct_val === true && p1_stat_val !== 'N/A' ? '%' : ''}`}</div>
                <div className="label">{statArray[0]}</div>
                <div className={`t2${winner == 't2' ? ' winner' : ''}`}>{`${p2_stat_val}${pct_val === true && p2_stat_val !== 'N/A' ? '%' : ''}`}</div>
            </div>
        )
    }

    return p1 && p2 ? (
        <>
            <DividerHeader text='2024 Stats*' />

            <div className="stats-content">
                <div className="stats-content-row header">
                    <div className="t1">{p1.name}</div>
                    <div className="t2">{p2.name}</div>
                </div>
                <div className="stats-content-row">
                    <div className="t1 winner">{`${p1?.player_stats?.wins}-${p1?.player_stats?.losses}`}</div>
                    <div className="label">Win/Loss</div>
                    <div className="t2 winner">{`${p2?.player_stats?.wins}-${p2?.player_stats?.losses}`}</div>
                </div>
                {tournamentStatsConfig.ytdStatRows.length > 0 &&
                    tournamentStatsConfig?.ytdStatRows.map((stat, i) => {
                        return getStatRow(stat, i);
                    }) 
                }
            </div>

            <div className="stats-content">
                <div className="stats-content-row disclaimer">{disclaimer}</div>
            </div>
        </>
    ) : null;
};

export default HeadtoHeadYTDStats;
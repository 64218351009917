import React from 'react';

const ContentListTile = props => {
	let attributes = props.attributes;

	return (
		<div className={`related-content-tile`}>
			<a className="content-link">
				<div className="image-container">
					<img src={`${attributes['thumb']}`} alt={attributes['title']} />
				</div>
				<div className="content-text">
					<div className="type-tag">
						<div>{attributes['type']}</div>
					</div>
					<div className="content-title">{attributes['title']}</div>
					<div className="content-description">{attributes['description']}</div>
				</div>
			</a>
		</div>
	);
};

export default ContentListTile;

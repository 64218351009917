/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import CompetitorListSelect from './CompetitorListSelect';
import axios from 'axios';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: CompetitorListPage
 * -----------------------------------------------------------------------------
 */
const listFilters = [
	{ value: 'seeds', text: 'Seeds' },
	{ value: 'singles', text: 'Singles' },
	{ value: 'doubles', text: 'Doubles' },
];

const mapStateToProps = (state, props) => {
	return {
		...state['CompetitorListPage'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.CompetitorListPage.mount()),
});

class CompetitorListPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			showData: 'men',
			selectedIndex: 0,
		};
	}

	componentDidMount() {
		//logger.log('[CompetitorListPage] componentDidMount');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[CompetitorListPage] componentWillReceiveProps nextProps:%o ', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[CompetitorListPage] componentDidUpdate - state:%o', this.state);

		// calculate new data path from path in state and current fitlers
		if (
			this.state.stubs &&
			this.state.stubs.competitors.stub != 'stub' &&
			this.state.seedsPath &&
			this.state.competitorPath &&
			(!this.state.competitorList || !this.state.seedsList)
		) {
			let pathObj = {
				seedsPath: this.state.seedsPath,
				competitorPath: this.state.competitorPath,
			};

			// call the service fetch and pass in the object containing multiple request urls
			deps.services.CompetitorListPage.fetchAllCompetitorListData(pathObj).then(
				axios.spread((seedsResults, competitorResults) => {
					this.setState(prevState => {
						return {
							...prevState,
							seedsList: seedsResults,
							competitorList: competitorResults,
						};
					});
				})
			);
		}
	}

	createTableHeaders() {
		if (this.state.filters.listView == 'seeds') {
			return ['Seed', 'Name', '', 'Opponent', 'Round'];
		} else {
			return ['Rank', 'Name'];
		}
	}

	formatTableData(data) {
		if (this.state.filters.listView == 'seeds') {
			let seedData = data.seeds;
			return seedData.map((d, i) => {
				return {
					seed: d['rank'],
					name: d['long-name'],
					text: d['result'],
					opponent: d['opp-long-name'],
					round: d['round'],
				};
			});
		} else {
			let competitorData = data.players;
			return competitorData.map((d, i) => {
				return {
					rank: d['rank'],
					name: `${d['lastName']}, ${d['firstName']}`,
				};
			});
		}
	}

	getPageData() {
		let tableTitle;
		let headerData;
		let tableRowData;
		let pageClass;

		if (this.state.showData == 'men') {
			switch (this.state.filters.listView) {
				case 'seeds':
					tableTitle = "Gentlemen's Seeds";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.seedsList.seed_results[0]);
					break;
				case 'singles':
					tableTitle = "Gentlemen's Singles";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.competitorList.competitors[0]);
					break;
				case 'doubles':
					tableTitle = "Gentlemen's Doubles";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.competitorList.competitors[2]);
					break;
				default:
					tableTitle = "Gentlemen's Seeds";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.seedsList.seed_results[0]);
					break;
			}
		} else if (this.state.showData == 'women') {
			switch (this.state.filters.listView) {
				case 'seeds':
					tableTitle = "Ladies' Seeds";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.seedsList.seed_results[1]);
					break;
				case 'singles':
					tableTitle = "Ladies' Singles";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.competitorList.competitors[1]);
					break;
				case 'doubles':
					tableTitle = "Ladies' Doubles";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.competitorList.competitors[3]);
					break;
				default:
					tableTitle = "Ladies' Seeds";
					headerData = this.createTableHeaders();
					tableRowData = this.formatTableData(this.state.seedsList.seed_results[1]);
					break;
			}
		}

		switch (this.state.selectedIndex) {
			case 0:
				pageClass = 'page-one';
				break;
			case 1:
				pageClass = 'page-two';
				break;
		}

		return {
			tableTitle: tableTitle,
			headers: headerData,
			data: tableRowData,
			tableContainer: 'competitorList',
			pageClass: pageClass,
		};
	}

	showData(type) {
		this.setState(prevState => {
			return { ...prevState, showData: type };
		});

		let measure_type;
		type == 'men' ? (measure_type = 'Gentlemen') : (measure_type = 'Ladies');

		MeasurementUtils.dispatchMeasurementCall('competitorListTabsSelector', { tab: measure_type });
	}

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	render() {
		logger.log('[CompetitorListPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'scores',
			title: 'Competitor List',
			shortTitle: 'Competitor List',
			metaTitle: 'Competitor List',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		if (!this.state.stubs) {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator type={'white'} />
					</div>
					<Footer />
				</Template>
			);
		}

		if (this.state.stubs.competitors.stub == 'stub') {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<div className="stub_box centerV">
							<div className="text">{this.state.stubs.competitors.text}</div>
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			if (this.state.competitorList && this.state.seedsList) {
				let propsData = this.getPageData();

				return (
					<Template className="scorespage">
						<div className="scores-background" />
						<Header attributes={header_propsData} />

						<PageHeader attributes={header_propsData} />

						<div className="content-main">
							<div className="column-layout">
								<div
									className={`player-competitor-list four-col ${propsData.pageClass} ${this.state.filters.listView}`}>
									<div className="player-data-toggle">
										<span
											onClick={() => this.showData('men')}
											className={this.state.showData == 'men' ? 'show' : ''}>
											Gentlemen's
										</span>{' '}
										|{' '}
										<span
											onClick={() => this.showData('women')}
											className={this.state.showData == 'women' ? 'show' : ''}>
											Ladies'
										</span>
									</div>
									<div className="header">
										<div className="navItemFixed">
											<CompetitorListSelect filters={listFilters} />
										</div>
									</div>

									<div className="table-title">
										<a
											onClick={() => {
												this.setSelected(0);
											}}>
											<i className="wim-icon-left-arrow left-icon" />
										</a>
										<div
											className={`left-nav-dot ${
												this.state['selectedIndex'] == 0 ? 'selected' : ''
											}`}>
											&#9679;{' '}
										</div>
										<div
											className={`right-nav-dot ${
												this.state['selectedIndex'] == 1 ? 'selected' : ''
											}`}>
											{' '}
											&#9679;
										</div>
										<a
											onClick={() => {
												this.setSelected(1);
											}}>
											<i className="wim-icon-right-arrow right-icon" />
										</a>
									</div>

									<div className="datatable">
										<div className="title">{propsData.tableTitle}</div>
										<div className="headers">
											{propsData.headers.map((d, i) => {
												return (
													<div
														key={i}
														className={`cell ${i <= 2 ? 'early-col' : 'late-col'} ${
															i <= 2 && this.state['selectedIndex'] == 0
																? 'selected'
																: i > 3 && this.state['selectedIndex'] == 1
																? 'selected'
																: ''
														}`}>
														{d}
													</div>
												);
											})}
										</div>
										{propsData.data.map((d, i) => {
											return (
												<div key={i} className="row">
													{Object.keys(d).map((d1, j) => {
														return (
															<div
																key={j}
																className={`cell ${j <= 2 ? 'early-col' : 'late-col'} ${
																	j <= 2 && this.state['selectedIndex'] == 0
																		? 'selected'
																		: j > 2 && this.state['selectedIndex'] == 1
																		? 'selected'
																		: ''
																}`}>
																{d[d1]}
															</div>
														);
													})}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<Footer />
					</Template>
				);
			} else {
				return (
					<Template className="scorespage">
						<div className="scores-background" />
						<Header attributes={header_propsData} />

						<PageHeader attributes={header_propsData} />

						<div className="content-main">
							<LoadingIndicator type={'white'} />
						</div>
						<Footer />
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorListPage);

import deps from 'dependencies';
import op from 'object-path';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[TicketsWebview] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, ['ticketConfig','ticketLabels']).then(Config => {
			logger.log('[TicketsWebview] action.mount - config: %o', Config);
			// logger.log('[TicketsWebview] action.mount - data: %o', data);

			let data = {
				tickets: {
					...params,
				},
			};

			logger.log('[TicketsWebview] action.mount - data:%o', data);

			let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
			let account = op.get(parsedQs, 'acct', null);

	
				//logger.log('[TicketsWebview] action.mount - result:%o', result);

				//logger.log('[Tickets] action.mount - data: %o', data);
				data.qualStart = Config.dateConfig.qualIsoStart;
				data.tournStart = Config.dateConfig.tournIsoStart;
				data.tokenUrl = Config.tickets.api.token;
				data.category = 's_tickets';
	

				logger.log('[TicketsWebview] action.mount - data: %o', data);

				dispatch({
					type: deps.actionTypes.TICKETSWEBVIEW_LOAD,
					data: data,
				});
		
		});
	},

	callSpectatorAll: (service, accounts) => (dispatch, getState, store) => {
		return deps.services.Tickets.callSpectatorAll(service, accounts, dispatch, store);
	},
};

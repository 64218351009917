import deps from 'dependencies';
import findIndex from 'lodash/findIndex';
import op from 'object-path';
import uniq from 'lodash/uniq';
import { Timer } from 'appdir/components/general/Util';
import isEqual from 'lodash/isEqual';

let updateSetGigyaTimer = null;
let updateGetGigyaTimer = null;
let initialLoad = true;

export default {
	cancel: () => (dispatch, getState) => {
		logger.log('[Controller] actions.cancel');

		if (updateGetGigyaTimer) {
			updateGetGigyaTimer.clear();
		}
	},

	toggleShowFavorites: data => (dispatch, getState) => {
		dispatch({ type: deps.actionTypes.CONTROLLER_TOGGLE_SHOW_FAVORITES, data: data });
	},

	logout: () => (dispatch, getState) => {
		dispatch({ type: deps.actionTypes.CONTROLLER_LOGOUT });
	},
	debug: () => (dispatch, getState) => {
		let view = !getState().Controller.debugView;
		//logger.log('[Controller] actions.debug - view:%o',
		dispatch({ type: deps.actionTypes.CONTROLLER_DEBUG, view: view });
	},
	slamtracker: id => (dispatch, getState) => {
		//dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: { slamtrackerPanel: { display: true } } });
		dispatch(deps.actions.SlamtrackerPanel.show(id));
	},
	updatePromoFeature: (key, newValue) => (dispatch, getState) => {
		let currentPromo = getState().Controller?.promoFeature;
		dispatch({
			type: deps.actionTypes.CONTROLLER_UPDATE,
			data: {
				promoFeature: {
					...currentPromo,
					[key]: newValue,
				},
			},
		});
	},
	drawsPageMountCheck: value => (dispatch, getState) => {
		//dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: { slamtrackerPanel: { display: true } } });
		dispatch({ type: deps.actionTypes.CONTROLLER_DRAWS_MOUNT_PROMO_VERIFY, value: value });
	},
	update: (data, initial) => (dispatch, getState, store) => {
		let myWimConfig = getState().Config['myWimbledon'];
		let Controller = getState().Controller;
		let gigyaState = getState().Gigya;
		let secs = myWimConfig['favorites']['syncPollSeconds'] * 1000;
		let syncFavorites = myWimConfig['favorites']['syncFavourites'] && !window.webview;
		initialLoad = initial != undefined ? initial : initialLoad;

		logger.log(
			'[Controller] actions.update - initial:%o initialLoad:%o enabled:%o webview:%o data:%o loggedIn:%o',
			initial,
			initialLoad,
			myWimConfig['favorites']['syncFavourites'],
			window.webview,
			data,
			gigyaState.loggedIn
		);

		if (updateGetGigyaTimer) {
			updateGetGigyaTimer.clear();
		}

		if (initialLoad && gigyaState.loggedIn) {
			initialLoad = false;
			mergeFavorites(getState, dispatch, data);

			if (syncFavorites) {
				updateGetGigyaTimer = new Timer(
					'get',
					() => dispatch(deps.actions.Gigya.getAccountInfo({ src: 'syncTimer1' })),
					secs
				);
			}
		} else if (gigyaState.loggedIn) {
			if (syncFavorites) {
				updateGetGigyaTimer = new Timer(
					'get',
					() => dispatch(deps.actions.Gigya.getAccountInfo({ src: 'syncTimer2' })),
					secs
				);
			}

			if (data) {
				logger.log('[Controller] actions.update - data:%o', data);
				let players = cleanArray(op.get(data, 'currentUser.data.userPreferences.favourites.players', []));
				let articles = cleanArray(op.get(data, 'currentUser.data.userPreferences.savedContent.articles', []));
				let videos = cleanArray(op.get(data, 'currentUser.data.userPreferences.savedContent.videos', []));
				//logger.log('[Controller] actions.update - initialLoad:%o enabled:%o data:%o', players);

				if (!Array.isArray(players)) {
					players = players.split(',');
				}
				if (!Array.isArray(articles)) {
					articles = articles.split(',');
				}
				if (!Array.isArray(videos)) {
					videos = videos.split(',');
				}

				let updateData = {
					favourites: {
						...Controller.favourites,
						players: players.filter(d => d !== null || d !== ''),
						articles: articles.filter(d => d !== null || d !== ''),
						videos: videos.filter(d => d !== null || d !== ''),
					},
				};
				logger.log('[Controller] actions.update - favs:%o', updateData);

				dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: updateData });
			}
		}
	},

	updateImmediate: data => (dispatch, getState, store) => {
		let myWimConfig = getState().Config['myWimbledon'];
		let ControllerState = getState().Controller;

		let updateTime = myWimConfig['favorites']['syncPushSeconds'] * 1000;

		let playerMax = myWimConfig['favorites']['maxPlayers'];
		let articleMax = myWimConfig['favorites']['maxArticles'];
		let videoMax = myWimConfig['favorites']['maxVideos'];
		let maxFav = { player: playerMax, article: articleMax, video: videoMax };

		let articleLastCount = op.get(ControllerState, 'favourites.articles', []).length;
		let videoLastCount = op.get(ControllerState, 'favourites.videos', []).length;
		let playerLastCount = op.get(ControllerState, 'favourites.players', []).length;
		let lastShow = op.get(ControllerState, 'favourites.show', null);

		let articleCount = op.get(data, 'favourites.articles', []).length;
		let videoCount = op.get(data, 'favourites.videos', []).length;
		let playerCount = op.get(data, 'favourites.players', []).length;
		let currShow = op.get(data, 'favourites.show', null);

		let favLastCount = {
			player: playerLastCount,
			video: videoLastCount,
			article: articleLastCount,
			show: lastShow,
		};
		let favCount = { player: playerCount, video: videoCount, article: articleCount, show: currShow };
		let changedFavCount = whichTypeChanged(favCount, favLastCount);

		if (changedFavCount) {
			//detect if show favorites flag is set to true and do controller update only
			if (changedFavCount == 'show') {
				dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: data });
			} else {
				/*
				 * otherwise check other favoriting changes make sure they are within the configurable limits
				 * and update both Gigya and controller redux state
				 */
				if (maxFav[changedFavCount] >= favCount[changedFavCount]) {
					dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: data });

					//clearTimeout(updateSetGigyaTimer);
					if (updateSetGigyaTimer) {
						updateSetGigyaTimer.clear();
					}

					let gigyaState = store.getState().Gigya;

					//logger.log('[Controller] actions.updateImmediate - data:%o', data);
					//logger.log('[Controller] actions.updateImmediate - changedFavCount:%o', changedFavCount);
					//logger.log('[Controller] actions.updateImmediate -  gigyaState:%o', gigyaState);
					if (gigyaState.loggedIn) {
						let updatedState = {
							data: {
								userPreferences: {},
							},
						};

						if (changedFavCount == 'player') {
							let updatedPlayers = cleanArray(
								op.get(
									data,
									'favourites.players',
									op.get(gigyaState, 'currentUser.data.userPreferences.favourites.players', {})
								)
							);
							op.set(updatedState, 'data.userPreferences.favourites.players', updatedPlayers);
						} else if (changedFavCount == 'article') {
							let updatedArticles = cleanArray(
								op.get(
									data,
									'favourites.articles',
									op.get(gigyaState, 'currentUser.data.userPreferences.savedContent.articles', {})
								)
							);
							op.set(updatedState, 'data.userPreferences.savedContent.articles', updatedArticles);
						} else if (changedFavCount == 'video') {
							let updatedVideo = cleanArray(
								op.get(
									data,
									'favourites.videos',
									op.get(gigyaState, 'currentUser.data.userPreferences.savedContent.videos', {})
								)
							);
							op.set(updatedState, 'data.userPreferences.savedContent.videos', updatedVideo);
						}

						if (myWimConfig['favorites']['syncFavourites']) {
							logger.log(
								'[Controller] actions.updateImmediate - updateTime:%o updatedState:%o',
								updateTime,
								updatedState
							);

							if (updateGetGigyaTimer) {
								updateGetGigyaTimer.pause();
							}

							updateSetGigyaTimer = new Timer(
								'set',
								() => {
									dispatch(
										deps.actions.Gigya.setAccountInfo(
											'timer',
											updatedState,
											false,
											updateGetGigyaTimer
										)
									);
								},
								updateTime
							);
						}
					}
				} else {
					dispatch({
						type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE,
						data: {
							showModal: true,
							showCloseBtn: true,
							type: 'fail',
							title: `UNABLE TO FAVOURITE ${changedFavCount.toUpperCase()}`,
							message: 'Reached maximum amount of allowed favourite content',
							useTimer: false,
						},
					});
				}
			}
		}
	},

	clearPlayers: data => (dispatch, getState, store) => {
		let myWimConfig = getState().Config['myWimbledon'];

		let updateTime = myWimConfig['favorites']['syncPushSeconds'] * 1;

		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: data });

		//clearTimeout(updateSetGigyaTimer);
		if (updateSetGigyaTimer) {
			updateSetGigyaTimer.clear();
		}

		let updatedState = {
			data: {
				userPreferences: {},
			},
		};

		op.set(updatedState, 'data.userPreferences.favourites.players', []);

		if (myWimConfig['favorites']['syncFavourites']) {
			logger.log(
				'[Controller] actions.updateImmediate - updateTime:%o updatedState:%o',
				updateTime,
				updatedState
			);

			if (updateGetGigyaTimer) {
				updateGetGigyaTimer.pause();
			}

			updateSetGigyaTimer = new Timer(
				'set',
				() => {
					dispatch(deps.actions.Gigya.setAccountInfo('timer', updatedState, false, updateGetGigyaTimer));
				},
				updateTime
			);
		}
	},

	clearContent: () => (dispatch, getState) => {
		clearFavorites(getState, dispatch);
	},

	mount: () => (dispatch, getState, store) => {
		//logger.log('[Controller] mount - webview:%o', window.webview);
		let include = !window.webview ? ['all'] : [''];
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, include).then(Config => {
			let contentNewExpireSec = parseInt(Config.contentNewExpireSec);
			let data = {
				contentNewExpireSec,
				loaded: true,
				myWimTimer: {
					enableUpdate: Config.myWimbledon.enableUpdate,
					update: Config.myWimbledon.updateTime,
				},
				// slamtrackerPanel: {
				// 	display: false,
				// },
			};
			dispatch({ type: deps.actionTypes.CONTROLLER_MOUNT, data: data });
		});
	},

	updatePreference: (data, modal) => (dispatch, getState, store) => {
		let gigyaState = store.getState().Gigya;
		let controllerPrefs = store.getState().Controller.userPreferences;
		let updatedState = {};

		// logger.log('[Controller] updatePreference - state:%o gigya:%o', controllerPrefs, gigyaState);

		//adding update of controller state, and gigya store for user preferences
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE_PREF, data: data.userPreferences });

		//only called from slamtracker, don't update pref from that local toggle
		/* if (gigyaState.loggedIn) {
			updatedState = {
				data: {
					userPreferences: {
						...gigyaState.currentUser.data.userPreferences,
						...data.userPreferences,
					},
					profile: {
						...gigyaState['currentUser']['data']['profile'],
					},
				},
			};
			dispatch(deps.actions.Gigya.setAccountInfo(updatedState, modal, 0));
		} */
	},

	forceUpdateFn: data => dispatch => {
		dispatch({ type: deps.actionTypes.CONTROLLER_FORCEUPDATE, data: data });
	},

	updateDate: data => (dispatch, getState, store) => {
		let controllerDate = data;
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATEDATE, data: controllerDate });
	},

	setPlatform: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.CONTROLLER_SETPLATFORM, data: data });
	},

	setScoringStatus: data => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.CONTROLLER_SET_SCORING_STATUS,
			data: data,
		});
	},

	updateViewedContent: (time, id) => (dispatch, getState, store) => {
		let contentNewExpire = getState().Controller['contentNewExpireSec'];
		let dateNow = Date.now() / 1000;
		let contentNew = false;

		if (dateNow - time / 1000 <= contentNewExpire) {
			contentNew = true;
		}
		//logger.log('[Controller] action.updateViewedContent - time:%o new:%o', time, contentNew);

		if (contentNew) {
			let contentArray = getState().Controller['content']['viewed'];

			//logger.log('[Controller] action.updateViewedContent - time:%o contentNewExpire:%o', time, contentNewExpire);

			contentArray.filter((d, i) => {
				return d.date >= dateNow - contentNewExpire;
			});
			let index = findIndex(contentArray, o => {
				return o.contentId == id;
			});
			if (index == -1) {
				contentArray.push({ contentId: id, date: time });
			}

			dispatch({ type: deps.actionTypes.CONTROLLER_UPDATEVIEWEDCONTENT, data: { viewed: contentArray } });
		}
	},

	//reset local storage for favourites
	resetLocal: () => (dispatch, getState, store) => {
		logger.log('[Controller] action.resetLocal');
		let controllerUpdatedData = {
			favourites: {
				players: [],
				articles: [],
				videos: [],
			},
		};

		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: controllerUpdatedData });
	},
};

function clearFavorites(getState, dispatch) {
	let Controller = getState().Controller;
	let Gigya = getState().Gigya;

	if (op.get(Gigya, 'currentUser.data', false)) {
		let articles = [],
			videos = [];

		let gigyaSettings = {};
		let localTime = Controller['userPreferences']['localTime'];
		let speed = Controller['userPreferences']['speed'];
		let temperature = Controller['userPreferences']['temperature'];
		let localFavoritePlayers = op.get(Controller, 'favourites.players', []);

		gigyaSettings.localTime = op.get(Gigya, 'currentUser.data.userPreferences.localTime', localTime);
		gigyaSettings.speed = op.get(Gigya, 'currentUser.data.userPreferences.speed', speed);
		gigyaSettings.temperature = op.get(Gigya, 'currentUser.data.userPreferences.temperature', temperature);

		let updatedData = {
			data: {
				userPreferences: {
					...gigyaSettings,
					savedContent: {
						articles: articles,
						videos: videos,
					},
					favourites: {
						players: localFavoritePlayers,
					},
				},
			},
		};

		let controllerUpdatedData = {
			favourites: {
				players: localFavoritePlayers,
				articles: articles,
				videos: videos,
			},
			userPreferences: {
				localTime,
				speed,
				temperature,
			},
		};

		dispatch(deps.actions.Gigya.setAccountInfo('clearFavorites', updatedData));
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: controllerUpdatedData });
	}
}

function mergeFavorites(getState, dispatch, data) {
	//take gigya favorites
	let myWimConfig = getState().Config['myWimbledon'];
	let Controller = getState().Controller;
	// let Gigya = getState().Gigya;
	let Gigya = data;

	let playerMax = myWimConfig['favorites']['maxPlayers'];
	let articleMax = myWimConfig['favorites']['maxArticles'];
	let videoMax = myWimConfig['favorites']['maxVideos'];

	logger.log('[Controller] mergeFavorites - gigya:%o', Gigya);

	if (op.get(Gigya, 'currentUser.data', false)) {
		//logger.log('[Controller] mergeFavorites - user:%o', op.get(Gigya, 'currentUser.data', false));

		let gigyaProfileInfo = { ...Gigya.currentUser.profile };
		delete gigyaProfileInfo['age'];

		//assign content loaded from remote
		let gigyaFavoritePlayers = op.get(Gigya, 'currentUser.data.userPreferences.favourites.players', []);
		let gigyaSavedArticles = op.get(Gigya, 'currentUser.data.userPreferences.savedContent.articles', []);
		let gigyaSavedVideo = op.get(Gigya, 'currentUser.data.userPreferences.savedContent.videos', []);

		//assign content stored locally
		let localFavoritePlayers = op.get(Controller, 'favourites.players', []);
		let localSavedArticles = op.get(Controller, 'favourites.articles', []);
		let localSavedVideo = op.get(Controller, 'favourites.videos', []);

		//check for differences in local vs remote content
		let newFavoritePlayers = uniq(localFavoritePlayers.concat(gigyaFavoritePlayers));
		let newSavedArticles = uniq(
			localSavedArticles.concat(
				gigyaSavedArticles.length > 0
					? !Array.isArray(gigyaSavedArticles)
						? gigyaSavedArticles.split(',')
						: gigyaSavedArticles
					: []
			)
		);
		let newSavedVideos = uniq(
			localSavedVideo.concat(
				gigyaSavedVideo.length > 0
					? !Array.isArray(gigyaSavedVideo)
						? gigyaSavedVideo.split(',')
						: gigyaSavedVideo
					: []
			)
		);

		if (newFavoritePlayers.length > playerMax) {
			let removalAmount = newFavoritePlayers.length - playerMax;
			newFavoritePlayers.splice(0, removalAmount);
		}

		if (newSavedArticles.length > articleMax) {
			let removalAmount = newSavedArticles.length - articleMax;
			newSavedArticles.splice(0, removalAmount);
		}

		if (newSavedVideos.length > videoMax) {
			let removalAmount = newSavedVideos.length - videoMax;
			newSavedVideos.splice(0, removalAmount);
		}

		//get controller values
		let localTime = Controller['userPreferences']['localTime'];
		let speed = Controller['userPreferences']['speed'];
		let temperature = Controller['userPreferences']['temperature'];

		let settings = {};
		//use profile values if exist, otherwise local values
		op.set(
			settings,
			'data.userPreferences.localTime',
			op.get(Gigya, 'currentUser.data.userPreferences.localTime', localTime)
		);
		op.set(settings, 'data.userPreferences.speed', op.get(Gigya, 'currentUser.data.userPreferences.speed', speed));
		op.set(
			settings,
			'data.userPreferences.temperature',
			op.get(Gigya, 'currentUser.data.userPreferences.temperature', temperature)
		);
		op.set(settings, 'data.profile.title', op.get(Gigya, 'currentUser.data.profile.title', null));

		//update with merged/calculated new values
		op.set(settings, 'data.userPreferences.savedContent.articles', newSavedArticles);
		op.set(settings, 'data.userPreferences.savedContent.videos', newSavedVideos);
		op.set(settings, 'data.userPreferences.favourites.players', newFavoritePlayers);

		//set data that might be in profile, but is not used on web
		if (op.get(Gigya, 'currentUser.data.userPreferences.favourites.countries', false)) {
			op.set(
				settings,
				'data.userPreferences.favourites.countries',
				op.get(Gigya, 'currentUser.data.userPreferences.favourites.countries', null)
			);
		}
		if (op.get(Gigya, 'currentUser.data.userPreferences.savedContent.matches', false)) {
			op.set(
				settings,
				'data.userPreferences.savedContent.matches',
				op.get(Gigya, 'currentUser.data.userPreferences.savedContent.matches', null)
			);
		}

		let updatedData = {
			data: {
				profile: {
					title: '',
				},
				userPreferences: {
					localTime: '',
					speed: '',
					temperature: '',
					savedContent: {
						articles: [],
						videos: [],
						matches: [],
					},
					favourites: {
						players: [],
						countries: [],
					},
				},
			},
			...settings,
		};

		const currentUserData = {
			data: {
				profile: { ...Gigya.currentUser.data.profile },
				userPreferences: { ...Gigya.currentUser.data.userPreferences },
			},
		};
		logger.log('[Controller] mergeFavorites - currentUserData:%o', currentUserData);
		logger.log('[Controller] mergeFavorites - update:%o', updatedData);
		logger.log('[Controller] mergeFavorites - equal:%o', isEqual(currentUserData, updatedData));
		if (!isEqual(currentUserData, updatedData)) {
			dispatch(deps.actions.Gigya.setAccountInfo('mergeFavorites', updatedData));
		}

		//get updated data needed for Controller and dispatch
		let controllerUpdatedData = {
			favourites: {
				...Controller.favourites,
				players: newFavoritePlayers.filter(d => d !== null || d !== ''),
				articles: newSavedArticles.filter(d => d !== null || d !== ''),
				videos: newSavedVideos.filter(d => d !== null || d !== ''),
			},
			userPreferences: {
				localTime,
				speed,
				temperature,
			},
		};
		logger.log('[Controller] mergeFavorites - controllerUpdatedData:%o', controllerUpdatedData);
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: controllerUpdatedData });
	}
}

function whichTypeChanged(newCurrCount, prevCurrCount) {
	let favKeys = Object.keys(prevCurrCount);
	for (let i = 0; i < favKeys.length; i++) {
		let checkedKey = favKeys[i];
		if (newCurrCount[checkedKey] !== prevCurrCount[checkedKey]) return checkedKey;
	}
	return false;
}

function cleanArray(arr) {
	let filtered = arr.filter(function(el) {
		return el != null;
	});
	return filtered;
}

import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import { any } from 'appdir/components/general/Util/Functions';
import op from 'object-path';
import React from 'react';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import { fetchContent } from 'appdir/components/general/Util/Services';
import { parseString, parseNumbers, parseBooleans } from 'xml2js';
import MeasurementUtils from 'appdir/lib/analytics';
import SiteMap from 'appdir/components/general/SiteMap';

export const callContent = (url, method, jwt_token, isXml = true) => {
	return new Promise((resolve, reject) => {
		fetchContent(url, method, jwt_token)
			.then(result => {
				logger.log('[ContentPage] callContent result:%o', result);

				//set the xml parse options
				let options = {
					explicitArray: false,
					normalize: true,
					trim: true,
					mergeAttrs: true,
					valueProcessors: [],
				};

				if (isXml) {
					//parse the xml
					parseString(result, options, function(err, json) {
						result = json.contentItem;
					});

					logger.log('[ContentPage] callContent result:%o', result);

					//clean the jsx block
					result.jsx = op.get(result, 'jsx._', result.jsx);
					result.jsx = result.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');
					//logger.log('[PlayerDigitalExperience] callContent - result:%o', result);
				}

				resolve({
					data: result,
					dataLoad: 'loaded',
					authorized: true,
				});

				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					content: result,
				});
			})
			.catch(error => {
				logger.error('[ContentPage] callContent error:%o', error);
				if (error.status == 404) {
					reject({
						data: 'notfound',
						dataLoad: 'loaded',
					});
				} else if (error.status == 401) {
					reject({
						authorized: false,
						dataLoad: 'loaded',
					});
				} else {
					reject({
						data: 'notfound',
						authorized: false,
						dataLoad: 'loaded',
					});
				}
			});
	});
};

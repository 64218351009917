import deps from 'dependencies';

export default {
    mount: data => (dispatch, getState) => {
        let config = getState().Config;
        dispatch({
            type: deps.actionTypes.TESTPAGE_MOUNT,
            data: data,
            config: config,
        });
    },
};

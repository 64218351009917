import React, { Fragment } from 'react';
import Button from 'appdir/components/common-ui/Button';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import useAddressBook from './useAddressBook';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import TabNavigation from 'appdir/components/cms/TabNavigation';

const AddressBook = () => {
	const {
		addressBookData,
		membershipType,
		setMembershipType,
		resetValues,
		options,
		nameRef,
		membershipTypeRef,
		loading,
		statusCode,
		setSearchName,
		searchName,
		searchAddressBook,
	} = useAddressBook();

	return (
		<Fragment>
			<div className="four-col ">
				<TabNavigation data={{ key: 'memberMembership' }} />
			</div>
			<div className="address-book">
				<div className="address-book-form">
					<div className="input-container">
						<label htmlFor="address-book-name" className="address-book-form__name-input">
							<h5 className="purple">Search</h5>
						</label>
						<input
							type="text"
							className="address-book-form__name-input"
							id="address-book-name"
							ref={nameRef}
							value={searchName}
							onChange={event => setSearchName(event.target.value)}
							placeholder="Name"
						/>
					</div>
					<div className="address-book-form__membership-type">
						<div className="input-container">
							<label htmlFor="membership-type">
								<h5 className="purple">Membership Type</h5>
							</label>

							<div className="address-book__select-menu">
								<div id="membership-type-select-menu">
									<div className="address-book-select-menu">
										<SelectMenu
											name="membershiptype"
											id="membership-type"
											attributes={options}
											selected={membershipType}
											onSelected={setMembershipType}
											filterName="type"
											onOpen={console.log}
											optionRef={membershipTypeRef}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="address-book-form__buttons">
						<Button className="outline" onClick={searchAddressBook}>
							Search
						</Button>
						<Button className="outline" onClick={resetValues}>
							Clear
						</Button>
					</div>
				</div>
				{loading ? (
					<LoadingIndicator />
				) : addressBookData && addressBookData.length > 0 ? (
					<ul className="address-book-entries">
						{addressBookData?.map(entry => {
							return (
								<li className="address-book-entry" key={entry.individualId}>
									<div className="address-book-entry__left">
										<div className="address-book-entry__left--picture">
											<img src={entry.memberImage} />
										</div>
										<div className="address-book-entry__left--info-container">
											<div className="address-book-entry__left--info-text">
												<p className="address-book-entry__left--name">{entry.name}</p>
												<div className="address-book-entry__left--purple-line"></div>
												<p className="address-book-entry__left--address">
													{entry.defaultAddress}
												</p>
												<p className="address-book-entry__left--email">{entry.defaultEmail}</p>
											</div>

											<div className="address-book-entry__right">
												<p className="address-book-entry__right--membership-type">
													{entry.membershipType}
												</p>
												<div>
													<p className="address-book-entry__right--phone-number">
														{entry.mobilePhone}
													</p>
													<p className="address-book-entry__right--phone-number">
														{entry.workPhone}
													</p>
												</div>
											</div>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				) : addressBookData ? (
					<p className="not-found">No address book data found for the given criteria</p>
				) : statusCode === 500 ? (
					<p className="not-found">There was an error fetching the data. Please try again later</p>
				) : (
					<p className="not-found">Please enter a name or membership type above to search for contacts</p>
				)}
			</div>
		</Fragment>
	);
};

export default AddressBook;

import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import SectionDivider from 'appdir/components/cms/SectionDivider';
import Time from 'appdir/components/common-ui/Time';

const MAXDATE = 9999999999999;

class SearchResults extends Component {
	constructor(props) {
		super(props);

		let docs = this.getDocs(props.data.response.docs);

		logger.log('[SearchResults] constructor - props:%o', props);
		logger.log('[SearchResults] constructor - docs:%o', docs);

		//logger.log('[SearchResults] constructor - docs:%o', filteredDocs);
		this.state = {
			docs: docs,
		};
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let docs = this.getDocs(nextProps.data.response.docs);

		this.setState(prevState => {
			return {
				docs: docs,
			};
		});
	}

	getDocs(docs) {
		docs = docs.filter(result => result.score > 0.000001);
		//logger.log('[SearchResults] getDocs - score filter - props:%o', docs);
		docs = docs.filter(result => result.url_str.indexOf('en_GB/cms/feeds/search/') < 0);
		docs = docs.filter(result => {
			//logger.log('[SearchResults] constructor - filter: %o', result['metatag.resulttitle_str']);
			return result['metatag.resulttitle_str'] != undefined;
		});
		return docs;
	}

	getResult(item, key) {
		let regex = /^(https?:\/\/).*wimbledon.com\//;
		let linkUrl = String(item['url_str']).replace(regex, '/');

		logger.log('[SearchResults] getResult - item:%o', item);

		if (this.props.isRoleData) {
			if (item?.url_str != '') {
				linkUrl = String(item['url_str']).replace(regex, '/');
			} else {
				let roleType = item ? item['metatag.resulttype'] : '';
				let id = String(item['id']).replace(roleType, '');
				let role = roleType.replace(new RegExp('_'), '/');
				linkUrl = `/s/${role}${id}.html`;
			}
		}

		return (
			<div className="search--result-item four-col" key={key}>
				<Link to={linkUrl}>
					<div className="search--result-item-title">{item['metatag.resulttitle_str']}</div>
					{item['metatag.description_str'] != 'null' ? (
						<div className="search--result-item-description">{item['metatag.description_str']}</div>
					) : null}
					{item['metatag.resultdate_str'] > 0 && item['metatag.resultdate_str'] < MAXDATE ? (
						<div className="search--result-item-date">
							{
								<Time
									epoch_ms={item['metatag.resultdate_str']}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							}
						</div>
					) : null}
				</Link>
			</div>
		);
	}

	render() {
		logger.log('[SearchResults] render - state:%o', this.state);
		let count = 0;

		if (this.state.docs) {
			return (
				<div className="column-layout">
					{this.props.isRoleData ? (
						this.props.allRoleDataTile ? (
							<div className="search-results-count-role">{this.props.allRoleDataTile}</div>
						) : null
					) : (
						<div className="search-results-count">{this.state.docs.length} Results</div>
					)}
					{this.props.isRoleData ? null : <SectionDivider data={{ style: 'four-col' }} />}
					{this.state.docs.map((item, index) => {
						{
							/* only display ALL data if:
							A. loadAllResults prop does NOT exist at all
							B. loadAllResults prop DOES exist and it is set to 'true'
							C. loadAllResults prop DOES exist, but it is set to false AND the index of the item is less than 2 (i.e., only show first two items in list if loadAllResults is false)
						*/
						}
						if (
							!this.props.loadAllResults ||
							(this.props.loadAllResults &&
								(this.props.loadAllResults === 'true' ||
									(this.props.loadAllResults === 'false' && index < 2)))
						) {
							//logger.log('[SearchResults] render - item:%o', item);
							return this.getResult(item, count++);
						}
					})}
				</div>
			);
		} else {
			return null;
		}
	}
}
export default SearchResults;

import deps from 'dependencies';
import { getQuerystringValues } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, getState).then(Config => {
			logger.log('[ResultsPage] action.mount - params: %o,getState:%o', params, getState().Router);
			let search = getState().Router.search.replace(/^\?/, '');
			let parsedQs = getQuerystringValues(search);

			let data = {
				tournDay: params.tournDay,
				event: parsedQs.event ? parsedQs.event : 'all',
			};
			logger.log('[ResultsPage] action.mount - data: %o', data);

			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'Results',
				day: params.tournDay ? `Day${params.tournDay}` : 'Current',
				event: parsedQs.event ? parsedQs.event : 'all',
			});

			dispatch({ type: deps.actionTypes.RESULTSPAGE_LOAD, data: data });
		});
	},

	setDay: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.RESULTSPAGE_SETDAY, data: data });
	},
};

/**
 CountryStatistics Initial State
*/

export default {
	localize: false,
	status: null,
	data: {
		title: 'Loading',
	},
	filters: {
		countryPlayers: '',
		open: '',
	},
};

import React from 'react';

const InputBox = ({ data }) => {
	//logger.log('InputBox - data:%o', data);
	return (
		<span>
			<label className="gigya-label" htmlFor={data.name}>
				<span className="gigya-label-text" data-translation-key={data.key} />
				<label className="gigya-required-display" data-bound-to={data.name} style={{}}>
					*
				</label>
			</label>
			<input
				type={data.type}
				className={data.class}
				name={data.name}
				show-valid-checkmark={data.showValid}
				data-display-name=""
				formNoValidate="formNoValidate"
				tabIndex={data.tabIndex ? data.tabIndex : '0'}
				autoComplete="off"
				data-gigya-placeholder=""
				aria-label={`screenset.translations['${data.key}']`}
				show-password-strength={data.showStrength ? data.showStrength : ''}
				data-required={data.required ? data.required : ''}
				readOnly={data.readOnly ? true : false}
				id={data.name}
				defaultValue={data.value || ''}
			/>
			<span className="gigya-error-msg" data-bound-to={data.name} />
		</span>
	);
};

export default InputBox;

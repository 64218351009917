import React, { useEffect } from 'react';
import CourtBox from './CourtBox';
import SummaryBox from './SummaryBox';
import useCourts from './useCourts';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import TabNavigation from 'appdir/components/cms/TabNavigation';

const CourtAvailability = () => {
	const {
		courts: { other, courts },
		getCourtData,
		loading,
	} = useCourts();
	useEffect(() => {
		getCourtData();
	}, []);

	return (
		<>
			{loading ? (
				<LoadingIndicator />
			) : (
				courts && (
					<>
						<div className="four-col ">
							<TabNavigation data={{ key: 'memberBookings' }} />
						</div>
						<SummaryBox courts={courts} other={other} />
						<div className="court-availability">
							{courts &&
								Object.keys(courts)?.map(courtKey => {
									return (
										courts[courtKey] && (
											<React.Fragment key={courtKey}>
												<CourtBox court={courts[courtKey]} courtKey={courtKey} />
											</React.Fragment>
										)
									);
								})}
						</div>
					</>
				)
			)}
		</>
	);
};

export default CourtAvailability;

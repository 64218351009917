import LiveVideo from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: '/:lang/interactive/:channel.html',
		exact: true,
		component: LiveVideo,
		section: MENU_ITEMS.videoRadio,
		load: params => deps.actions.LiveVideo.mount(params),
	},
];

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import axios from 'axios';

const mapStateToProps = (state, props) => {
	return {
		auth: state['Config'].auth,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class SecureFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			id: `sfile${Math.ceil(Math.random() * 10000)}`,
		};

		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	handleClick(e) {
		e.preventDefault();

		this.props
			.getJWT()
			.then(token => {
				logger.log('[SecureFile] handleClick - token:%o', token.jwt);
				document.getElementById(this.state.data.id).children[0].value = token.jwt.id_token;
				document.getElementById(this.state.data.id).submit();
			})
			.catch(error => {
				logger.error('[SecureFile] handleClick - error getting token');
				this.setState({
					error: true,
				});
			});
	}

	render() {
		//logger.info('[SecureFile] render - state:%o props:%o ', this.state, this.props);
		let filePath = op.get(this.state, 'data.link', '');
		let url = op.get(this.state, 'auth.orch.file', '');
		url = url.replace('<type>', this.props?.data?.type);

		if (url != '' && filePath) {
			url = url.replace('<path>', filePath);
			logger.info('[SecureFile] render - url:%o ', url);

			return (
				<div className={`${this.state.data.style}`}>
					<form id={`${this.state.data.id}`} action={`${url}`} target="_blank" method="POST">
						<input type="hidden" name="jwtToken" />
						<a href={`${url}`} className="pdf-link" onClick={this.handleClick}>
							{this.state.data.text}
						</a>
					</form>
				</div>
			);
		} else {
			return null;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SecureFile);

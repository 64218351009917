import axios from 'axios';
import deps from 'dependencies';
import { restHeaders } from 'appdir/components/general/Util';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';

axios.interceptors.request.use(
	function(config) {
		//logger.log('[Tournament] interceptor config:% - ', config);
		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

export default {
	fetch: path => {
		let hdr = restHeaders();
		return axios
			.get(path, { headers: hdr })
			.then(response => {
				if (response.status === 200) {
					return response.data;
				} else {
					throw new Error('invalid response code');
				}
			})
			.catch(error => {
				//logger.log('[Tournament] fetchContent - error:%o', error);
				if (error.response && error.response.status == 404) {
					//logger.log('[Tournament] fetchContent - error1:%o', error.response);
					throw error.response;
				} else {
					//logger.log('[Tournament] fetchContent - error2:%o', error);
					throw error;
				}
			});
	},

	checkExpired: (dispatch, store, configData) => {
		return new Promise((resolve, reject) => {
			logger.log('[Tournament] checkExpired - call configData:%o', configData);
			const unsubscribe = configData =>
				store.subscribe(() => {
					logger.log('[Tournament] checkExpired - unsubscribe - store change');

					const { Tournament } = store.getState();
					const which = configData.key
						? op.get(Tournament, `${configData.configId}.${configData.key}`)
						: op.get(Tournament, configData.configId);

					//logger.log('[Tournament] checkExpired unsubscribe - configData:%o', configData);

					let loadDiff = moment().diff(moment(Tournament.updated), 'seconds', true);
					let expired = true;

					if (which) {
						loadDiff = moment().diff(moment(which.updated), 'seconds', true);
					}

					// logger.log('[Tournament] checkExpired unsubscribe - which:%o', which);
					// logger.log('[Tournament] checkExpired unsubscribe - loadDiff:%o', loadDiff);

					if (which) {
						expired = loadDiff >= which.refreshSec;
					} else {
						expired = loadDiff >= Tournament.refreshSec;
					}

					if (which && which.status === 'loaded' && !expired) {
						//logger.log('[Tournament] checkExpired unsubscribe - loaded');
						unsubscribe(configData);
						resolve(which);
					}

					if (which && which.state === 'error') {
						logger.log('[Tournament] checkExpired unsubscribe - error');
						reject({ error: which.error });
					}
				});

			//logger.log('[Tournament] checkExpired - store:%o',store.getState());

			let Conf = store.getState().Tournament;
			//logger.log('[Tournament] checkExpired - Conf:%o',Conf);

			let expired = true;
			let which = configData.key
				? op.get(Conf, `${configData.configId}.${configData.key}`)
				: op.get(Conf, configData.configId);
			let loadDiff = moment().diff(moment(Conf.updated), 'seconds', true);
			//logger.log('[Tournament] checkExpired - which:%o',which);

			if (which) {
				loadDiff = moment().diff(moment(which.updated), 'seconds', true);
			}

			if (which) {
				expired = loadDiff >= which.refreshSec;
			} else {
				expired = loadDiff >= Conf.refreshSec;
			}

			//logger.log('[Tournament] checkExpired - expired:%o loadDiff:%o state:%o', expired, loadDiff, Conf);

			if ((!which || (which && (which.status == null || which.status == 'loaded'))) && expired) {
				logger.log('[Tournament] checkExpired - initial expired - which:%o', which);
				dispatch({
					type: deps.actionTypes.COMMONDATA_EXPIRED,
					refreshSec: configData.refreshSec,
					configId: configData.configId,
					key: configData.key,
				});
				//resolve({status:"expired"})

				if (which) {
					resolve({ ...which, status: 'expired' });
				} else {
					resolve({ status: 'expired' });
				}
			}

			if (which && which.status === 'loaded' && !expired) {
				//logger.log('[Tournament] checkExpired - initial loaded');
				//unsubscribe(configData);
				resolve(which);
			}

			if (which && which.state === 'error') {
				unsubscribe(configData);
				reject({ error: which.error });
			}
		});
	},
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.DRAWSARCHIVE_MENU:
			return newState;
			break;

		default:
			return state;
	}
};

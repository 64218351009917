/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: SchedulePage
 * this is the completed matches page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['SchedulePage'], props);
	//return Object.assign({}, state['SchedulePage'], props);
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({});

class DaySelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			mode: props.mode,
			selected: props.selected,
		};

		this.setDay = this.setDay.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.setMode = this.setMode.bind(this);
		this.nav = null;

		logger.log('[DaySelect] constructor props:%o state:%o', props, this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[DaySelect] getDerivedStateFromProps - prevState:%o', prevState);
		logger.info('[DaySelect] getDerivedStateFromProps - nextProps:%o', nextProps);

		let op;

		let newState = {
			...prevState,
			...nextProps,
		};
		return newState;
	}

	setDay(day) {
		logger.log('[DaySelect] setDay - props:%o setDay:%o', this.props, day);

		if (this.state.selected !== day) {
			this.props.setScheduleDay(day);
		}
	}

	onOpen(which) {}

	onClose(which) {}

	setMode(mode) {
		logger.log('[DaySelect] setMode - this:%o', this);
		let testDates = this.state.dayNavOptions;
		let foundDate = null;
		let foundDay = '';

		if (mode == 'tourn') {
			foundDate = testDates.find(dateOption => dateOption.disabled === false && dateOption.type == 'tourn');
			MeasurementUtils.dispatchMeasurementCall('schedulePageSetMode', 'Championships');
		} else if (mode == 'quali') {
			foundDate = testDates.find(dateOption => dateOption.disabled === false && dateOption.type == 'quali');
			MeasurementUtils.dispatchMeasurementCall('schedulePageSetMode', 'Qualifying');
		}

		if (foundDate !== undefined && foundDate !== null) {
			foundDay = foundDate.value;

			this.setDay(foundDay);
		}

		// this.setState({
		// 	mode: mode,
		// 	filters: {
		// 		day: foundDay,
		// 	},
		// });
	}

	render() {
		let dayNavOptions =
			this.state?.days.map(item => ({
				value: item.tournDay.toString(),
				type: item.quals ? 'quali' : 'tourn',
				textName: item.displayDay,
				dropdownName: item.message,
				title: item.message,
				sort: item.tournDay,
				disabled: !item.released ? true : false,
			})) || [];
		dayNavOptions = dayNavOptions.reverse();

		let selectedValue = this.state.selected;
		let daysData = {
			options: [],
		};

		// add available days to base archive menu options
		dayNavOptions.forEach(item => {
			if (!item.disabled) {
				daysData.options.push({
					name: item.title,
					value: item.value,
				});
			}
		});

		let found = daysData.options.filter(item => {
			return item.value == this.state.selected;
		});

		if (found.length == 0) {
			daysData.options.unshift({
				name: 'Select a Results Day',
				value: '0',
			});
			selectedValue = '0';
		}

		//logger.log('[DaySelect] render - state:%o daysData:%o', this.state, daysData);

		return (
			<div className="daynav scores-select-menu">
				<div className="category">
					<button
						onClick={() => this.setMode('quali')}
						className={this.state.mode == 'quali' ? 'selected' : ''}
						data-type="quali"
						tabIndex={0}>
						Qualifying
					</button>
					|
					<button
						onClick={() => this.setMode('tourn')}
						className={this.state.mode == 'tourn' ? 'selected' : ''}
						data-type="tourn"
						tabIndex={0}>
						Championships
					</button>
				</div>
				<SelectMenu
					name="scheduleDay"
					attributes={daysData}
					selected={selectedValue}
					show={this.state.filters.open == 'days'}
					onSelected={this.setDay}
					onOpen={this.onOpen}
					onClose={this.onClose}
					mode={this.state.mode}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DaySelect);

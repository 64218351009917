import deps from 'dependencies';
import axios from 'axios';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Login Webview',
		});

		//logger.log('[LoginPage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {};

			logger.log('[LoginWebview] action.mount - data:', data);

			dispatch({ type: deps.actionTypes.LOGINPAGE_MOUNT, data: data });
		});
	},
};

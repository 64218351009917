/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import { Link } from 'react-router-dom';
import { Icon } from 'appdir/components/common-ui/Icon';
import WimLink from 'appdir/components/general/WimLink';
import Stripes from 'appdir/components/common-ui/Stripes';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import MobileWebFooter from 'appdir/components/cms/MobileWebFooter';
import { isMobile } from 'react-device-detect';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Footer
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Footer'],
		...props,
		mobileMenu: state['Config']['mobileMenu'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Footer.mount()),
});

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	componentDidMount() {
		this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	render() {
		let bg = this.state['background'] || 'green';
		let year = moment().format('YYYY');
		let icons = this.state['icons'] || ['googleplus', 'twitter', 'facebook', 'youtube', 'instagram'];
		let links =
			this.state['links'] || this.state.flagImagePathLarge
				? [
						{
							url: '#',
							label: [
								<img
									key={`image-1`}
									src={this.state.flagImagePathLarge.replace('<code>', 'CHN')}
									className="flag"
									alt="China Site"
								/>,
							],
						},
						{ url: '#', label: 'PRIVACY' },
						{ url: '#', label: 'MEDIA' },
						{ url: '#', label: 'JOBS' },
						{ url: '#', label: 'CONTACT' },
						{ url: '#', label: 'SITE MAP' },
				  ]
				: null;

		const showMobileMenu = this.props.mobileMenu?.enabled && isMobile;

		return (
			<>
				{showMobileMenu ? <MobileWebFooter /> : null}
				<footer>
				<noindex>
					<div
						className={
							showMobileMenu ? 'footer-global footer-global-mobile-web' : 'footer-global'
						}>
						<div className="footer-flex">
							<div className="footer-global-social">
								<div className="footer-global-social-wrapper">
									<ShareMenu type="horiz" color="black" />
								</div>
							</div>
							<div className="footer-global-links">
								<Link to="/en_GB/contact/index.html" className="h6 purple">
									Contact
								</Link>
								<Link to="/en_GB/jobs/index.html" className="h6 purple">
									Jobs
								</Link>
								<Link to="/en_GB/media/index.html" className="h6 purple">
									Media
								</Link>
								<Link to="/en_GB/about_wimbledon/privacy_policy.html" className="h6 purple">
									Privacy
								</Link>
								<Link to="/en_GB/about_wimbledon/acceptable_use_policy.html" className="h6 purple">
									Terms of Use
								</Link>
							</div>
							<div className="footer-global-copyright">
								&copy; Copyright IBM Corp., AELTC {values.tournamentYear}. All Rights reserved.
							</div>
						</div>
					</div>
				</noindex>
			</footer>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

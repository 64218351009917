export const formatSecs = curTime => {
	//return 'secs:' + secs

	let curhours = Math.floor(curTime / 3600);
	let curmins = Math.floor((curTime - (curhours * 3600)) / 60);
	let cursecs = Math.floor(curTime - (curhours * 3600) - (curmins * 60));

	//logger.info('formatTime - dur:%o hrs:%o mins:%o secs:%o', durTime, durhours, durmins, dursecs);

	if (cursecs < 10) { cursecs = "0" + cursecs; }
	if (curmins < 10) { curmins = "0" + curmins; }

	curTime = '';
	
	if (curhours > 0) {
		curTime += curhours + ':';
	}

	if (!isNaN(curmins)) {
		curTime += ((!isNaN(curmins)) ? curmins : '00') + ':';
	}

	if (!isNaN(cursecs)) {
		curTime += ((!isNaN(cursecs)) ? cursecs : '00');
	}

	return curTime;
};

export const unformatSecs = curtime => {
	let a = new Array;
	a = curtime.split(':');
	let seconds = 0;
	if(a.length > 2){
		seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
	}else{
		seconds = (+a[0]) * 60 + (+a[1]);
	}
	
	return seconds;
}
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
// import uniqBy from 'lodash/uniqBy';
import { createTableHeaders, formatTableData, formatMatchStatsData } from './elements/Utils';
import TableComponent from 'appdir/components/common-ui/TableComponent';
// import Lookup from './elements/node_modules/appdir/components/general/Util/Lookup';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MatchStatsFull
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['MatchStatsFull'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchStatsFull.mount()),
});

class MatchStatsFull extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
	}

	componentDidMount() {
		logger.log('[MatchStatsFull] componentDidMount id:%o', this.state.matchId);
		if (!this.state.matchStatsConfig) {
			this.props.mount();
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.state.matchStatsConfig && !this.state.matchData) {
			let matchId = this.state.matchId;
			let matchStatsPath = this.state.matchStatsConfig.matchStatsPath.replace('<matchId>', matchId);
			logger.log(`[MatchStatsFull] componentDidUpdate - path:%o`, matchStatsPath);

			deps.services.MatchStatsFull.getMatchStats(matchStatsPath).then(data => {
				this.setState(prevState => {
					return { ...prevState, matchData: data };
				});
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		let propsData = {};
		let style = this.state.style ? this.state.style : 'two-col inline';

		if (this.state.matchData) {
			let headerConfigData = this.state.matchStatsConfig.tableHeaders.headers;
			let tableHeader = createTableHeaders({
				player1: this.state.matchData.team1,
				player2: this.state.matchData.team2,
			});
			let formattedData = formatMatchStatsData(this.state.matchData, headerConfigData);
			let tableData = formatTableData(formattedData);

			propsData = {
				headers: tableHeader,
				data: tableData,
				tableTitle: 'Match Statistics',
				tableContainer: 'match-stats',
			};
		}
		return this.state.matchData ? (
			<div className={`match-stats ${style}`}>
				<span>
					<TableComponent attributes={propsData} />
				</span>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchStatsFull);

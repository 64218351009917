import { logger } from '../logger';

export class ScoringConnection {
	start() {
		//logger.warn('This is the default start method, it needs to be over-ridden by the subclass');
	}
	stop() {
		//logger.warn('This is the default stop method, it needs to be over-ridden by the subclass');
	}
	createNew(connectionProperties = {}) {
		//logger.warn('This is the default createNew method, it needs to be over-ridden by the subclass');
	}
	addSlamtrackerForMatch(event = '', matchId = '') {
		//logger.warn('This is the default addSlamtrackerForMatch method, it needs to be over-ridden by the subclass');
	}
	removeSlamtrackerForMatch(event = '', matchId = '') {
		//logger.warn('This is the default removeSlamtrackerForMatch method, it needs to be over-ridden by the subclass');
	}
}

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Contact Us:Thank You',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[ContactThankYou] action.mount - dispatch - config:%o', Config);
			dispatch({ type: deps.actionTypes.CONTACT_THANK_YOU_MOUNT, data: params, config: Config });
		});
	},
};

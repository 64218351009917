import React from 'react';
import Time from 'appdir/components/common-ui/Time';

const Section = props => {
	let hourAndMinIndex = props.data.TransactionDate.indexOf('T');
	let hourAndMin = props.data.TransactionDate.substr(hourAndMinIndex + 1, 5);
	return (
		<div className="section-container">
			<div className="row">
				<div className="row-header">Transaction Date & Time:</div>
				<div className="row-value">
					<Time
						epoch_string={props.data.TransactionDate}
						format="DD/MM/YYYY"
						options="upper"
						addendum={`at ${hourAndMin}`}
					/>
				</div>
			</div>
			<div className="row">
				<div className="row-header">Item:</div>
				<div className="row-value">{props.data.Description}</div>
			</div>
			<div className="row">
				<div className="row-header">Amount per Item:</div>
				<div className="row-value">£{props.data.UnitCost}</div>
			</div>
			<div className="row">
				<div className="row-header">Quantity:</div>
				<div className="row-value">{props.data.Quantity}</div>
			</div>
			<div className="row">
				<div className="row-header">Total Amount:</div>
				<div className="row-value">£{props.data.TotalCost}</div>
			</div>
			<div className="row">
				<div className="row-header">Payment Status:</div>
				<div className="row-value">{props.data.PaymentStatus}</div>
			</div>
			{props.data.PaymentType && (
				<div className="row">
					<div className="row-header">Payment Type:</div>
					<div className="row-value">{props.data.PaymentType}</div>
				</div>
			)}
		</div>
	);
};

export default Section;

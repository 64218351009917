/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import WimLink from 'appdir/components/general/WimLink';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Favorites from 'appdir/components/common-ui/Favorites';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import StubBox from 'appdir/components/common-ui/StubBox';
import isEmpty from 'lodash/isEmpty';
import MatchCard from 'appdir/components/pages/InsightsIndex/MatchCard';
import ScoresTabs from 'appdir/components/pages/LiveScores/ScoresTabs';

/**
 * -----------------------------------------------------------------------------
 * React Component: Upcoming
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Upcoming'],
		liveIndicators: state['ActiveData']['liveIndicators'],
		controllerLoaded: state['Controller'].loaded,
		stubs: state['Config'].stubPages,
		scheduleData: state['Tournament']['data']['schedule'],
		scoredays: state['ActiveData']['currentDay'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Upcoming.mount()),
	unmount: () => dispatch(deps.actions.Upcoming.unmount()),
	allMatchesPlayed: data => dispatch(deps.actions.Upcoming.allMatchesPlayed(data)),
	getSchedule: day => dispatch(deps.actions.Tournament.getSchedule({ day: day })),
	clearSchedule: () => dispatch(deps.actions.Tournament.clearSchedule()),
});

class Upcoming extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
		this.init = true;
		this.renderUpcoming = this.renderUpcoming.bind(this);
		// logger.log('[Upcoming] constructor this:%o', this);
	}

	componentDidUpdate(prevState) {
		logger.log('[Upcoming] props:%o', this.props);
		if (this.props.status === 'loaded' && this.props.scoredays && this.init) {
			this.init = false;
			let day = this.props.scoredays.schedule;
			this.props.getSchedule(day);
		}
	}

	componentWillUnmount() {
		this.props.unmount();
		this.props.clearSchedule();
	}

	renderUpcoming(d, i) {
		// logger.log('[Upcoming] renderUpcoming state:%o d:%o', this.state, d);
		let count = -1;
		return (
			<div key={i} className="upcoming-court">
				<div className="court-title-divider">
					<div className="match-location">{d.courtName}</div>
					<div className="match-time">
						<div className="match-time-modifier">
							<span className="time-string">{d.time}</span>
						</div>
					</div>
				</div>

				{d.matches.map((d1, j) => {
					let upcoming = !d1.status || d1.statusCode == 'B' || d1.statusCode == 'K'; //|| d1.statusCode == 'D'; //TODO remove D
					// let imageStyle = 'regular';
					if (upcoming && d1.eventCode && d1.team1[0].idA && d1.team2[0].idA) { //if one or both teams do not have players assigned, hide
						count++;
						return (
							<MatchCard
								match={d1}
								total={d.length}
								noPowerOption={true}
								key={`-match-card-${d1.match_id}`}
								style={'upcoming'}
								defaultTab={'overview'}
								pos={count}
							/>
						);
					} else {
						return null;
					}
				})}
			</div>
		);
	}

	getHeaderNav(isStub) {
		return (
			<div className="column-layout matchexp no-padding">
				<div className="header">
					<ScoresTabs />
				</div>
				{!isStub ? (
					<div className="four-col order-of-play-link">
						<WimLink to="/en_GB/scores/schedule/index.html">Full Order of Play</WimLink>
						<Favorites />
					</div>
				) : null}
			</div>
		);
	}

	render() {
		// let nextMatch = true;
		logger.log('[Upcoming] render - this:%o', this);
		let UpcomingData = op.get(this.props, 'scheduleData.data', null);
		//logger.log('[Upcoming] render - UpcomingData:%o', UpcomingData);
		let header_propsData = {
			headerType: 'scores',
			title: 'Upcoming',
			shortTitle: 'Upcoming',
			metaTitle: 'Upcoming',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		if (this.props.stubs && this.props.stubs.upcoming.stub === 'stub') {
			// logger.log('[Upcoming] render - stub');
			return (
				<Template className="scorespage upcoming">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main insights-style">
						{this.getHeaderNav(true)}
						<StubBox attributes={{ message: this.props.stubs.upcoming.text, style: 'centerV' }} />
					</div>
					<Footer />
				</Template>
			);
		} else if (this.state.hasError) {
			// logger.log('[Upcoming] render - hasError');
			return (
				<Template className="scorespage upcoming">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main insights-style">
						{this.getHeaderNav()}
						<GenericError message="Upcoming matches are unavailable at this time." />
					</div>
					<Footer />
				</Template>
			);
		} else if (UpcomingData && UpcomingData.courts && UpcomingData.courts.length > 0) {
			let courtData = UpcomingData.courts.filter(d => {
				return (
					d.matches.filter(
						match => match.statusCode == 'B' || match.statusCode == 'K' //|| match.statusCode == 'D' //TODO D is not valid, for testing only
					).length > 0
				);
			});
			logger.log('[Upcoming] render - courtData:%o', courtData);
			return (
				<Template className="scorespage upcoming">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main insights-style">
						<ErrorBoundary message="Upcoming matches are unavailable at this time.">
							{this.getHeaderNav()}
							<div className="column-layout matchexp no-padding">
								<div className="displayDate">{UpcomingData.displayDate}</div>
							</div>
							{!isEmpty(courtData) ? (
								<div className="insights-cards column-layout four-col">
									{courtData.map((d, i) => {
										return this.renderUpcoming(d, i);
									})}
								</div>
							) : (
								<StubBox
									attributes={{ message: this.props.stubs.upcoming.nomatches, style: 'centerV' }}
								/>
							)}
						</ErrorBoundary>
					</div>
					<Footer />
				</Template>
			);
		} else if (UpcomingData && UpcomingData.courts && UpcomingData.courts.length == 0) {
			if (this.props.stubs) {
				// logger.log('[Upcoming] render - no live matches');
				return (
					<Template className="scorespage upcoming">
						<div className="scores-background" />
						<Header attributes={header_propsData} />

						<PageHeader attributes={header_propsData} />
						<div className="content-main insights-style">
							{this.getHeaderNav()}
							<StubBox attributes={{ message: this.props.stubs.upcoming.nomatches, style: 'centerV' }} />
						</div>
						<Footer />
					</Template>
				);
			} else {
				return null;
			}
		} else {
			// logger.log('[Upcoming] render - loading');
			return (
				<Template className="scorespage upcoming">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main insights-style">
						{this.getHeaderNav()}
						<LoadingIndicator type="white" />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Upcoming);

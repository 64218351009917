import React, { Component } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import Button from 'appdir/components/common-ui/Button';

/**
 * Radio buttons for displaying and updating user's settings
 *  - types of contact
 *  - subject matter of contact
 */

class FormSettings extends Component {
	constructor(props) {
		super(props);

		//logger.log('[FormSettings] constructor - props:%o', JSON.stringify(props.currentUser.data.userPreferences) );

		this.state = {
			...props,
			...FormSettings.getNewState(props.currentUser.data),
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		logger.info('[FormSettings] constructor - props:%o state:%o', props, this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[FormSettings] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let newState = {};
		let prefs = op.get(prevState, 'userPreferences', {
			localTime: null,
			temperature: null,
			speed: null,
		});
		let nextPrefs = op.get(nextProps, 'currentUser.data.userPreferences');

		logger.info('[FormSettings]  getDerivedStateFromProps - prefs:%o nextPrefs:%o', prefs, nextPrefs);

		if (prefs && nextPrefs != undefined) {
			if (
				prefs.localTime != nextPrefs.localTime ||
				prefs.temperature != nextPrefs.temperature ||
				prefs.speed != nextPrefs.speed
			) {
				newState.userPreferences = nextPrefs;
				newState = {
					...newState,
					...FormSettings.getNewState(nextProps.currentUser.data),
				};
			}
		}

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	static getNewState(data) {
		let newState = {};

		let time = op.get(data, 'userPreferences.localTime', 'GMT');
		if (time == 'GMT') {
			newState.time = { local: false, gmt: true };
		} else {
			newState.time = { local: true, gmt: false };
		}

		let stats = op.get(data, 'userPreferences.speed', 'I');
		if (stats == 'I') {
			newState.statistics = { imperial: true, metric: false };
		} else {
			newState.statistics = { imperial: false, metric: true };
		}

		let temp = op.get(data, 'userPreferences.temperature', 'C');
		if (temp == 'C') {
			newState.temp = { celsius: true, fahrenheit: false };
		} else {
			newState.temp = { celsius: false, fahrenheit: true };
		}

		logger.info('[FormSettings] data:%o getNewState :%o', data, newState);

		return newState;
	}

	handleChange(e) {
		//logger.info('[FormSettings] handleChange - e:%o', e.currentTarget.id);

		switch (e.currentTarget.id) {
			case 'checkbox-local':
				this.setState({
					time: { local: true, gmt: false },
				});
				break;
			case 'checkbox-gmt':
				this.setState({
					time: { local: false, gmt: true },
				});
				break;
			case 'checkbox-metric':
				this.setState({
					statistics: { imperial: false, metric: true },
				});
				break;
			case 'checkbox-imperial':
				this.setState({
					statistics: { imperial: true, metric: false },
				});
				break;
			case 'checkbox-fahrenheit':
				this.setState({
					temp: { celsius: false, fahrenheit: true },
				});
				break;
			case 'checkbox-celsius':
				this.setState({
					temp: { celsius: true, fahrenheit: false },
				});
				break;
			default:
				break;
		}
	}

	handleSubmit() {
		//logger.info('[FormSettings] handleSubmit - onUpdate:%o', this.props.onUpdate);
		let data = {
			data: {
				userPreferences: {
					localTime: this.state.time.local ? 'local' : 'GMT',
					speed: this.state.statistics.metric ? 'M' : 'I',
					temperature: this.state.temp.celsius ? 'C' : 'F',
				},
			},
		};

		if (this.props.onUpdate) {
			this.props.onUpdate(data);
		}
	}

	render() {
		logger.info('[FormSettings] render - state:%o', this.state);

		return (
			<div className="mywim_settings settings consent-pref-float-left">
				<div className="title">Settings</div>

				<div className="settings-display">
					<div className="subtitle">Time</div>

					<div className="radio1">
						<input
							type="checkbox"
							id="checkbox-local"
							tabIndex="1"
							checked={this.state.time.local}
							onChange={this.handleChange}
						/>
						<label className="settings-label-wrapper" htmlFor={'checkbox-local'}>
							<label className="settings-label" htmlFor={'checkbox-local'}>
								<div className="settings-label-text">Local Time</div>
							</label>
						</label>
					</div>

					<div className="radio2">
						<input
							type="checkbox"
							id="checkbox-gmt"
							tabIndex="1"
							checked={this.state.time.gmt}
							onChange={this.handleChange}
						/>
						<label className="settings-label-wrapper" htmlFor={'checkbox-gmt'}>
							<label className="settings-label" htmlFor={'checkbox-gmt'}>
								<div className="settings-label-text">GMT/BST</div>
							</label>
						</label>
					</div>
				</div>

				<div className="settings-display">
					<div className="subtitle">Statistics</div>

					<div className="radio1">
						<input
							type="checkbox"
							id="checkbox-imperial"
							tabIndex="1"
							checked={this.state.statistics.imperial}
							onChange={this.handleChange}
						/>
						<label className="settings-label-wrapper" htmlFor={'checkbox-imperial'}>
							<label className="settings-label" htmlFor={'checkbox-imperial'}>
								<div className="settings-label-text">Imperial</div>
							</label>
						</label>
					</div>

					<div className="radio2">
						<input
							type="checkbox"
							id="checkbox-metric"
							tabIndex="1"
							checked={this.state.statistics.metric}
							onChange={this.handleChange}
						/>
						<label className="settings-label-wrapper" htmlFor={'checkbox-metric'}>
							<label className="settings-label" htmlFor={'checkbox-metric'}>
								<div className="settings-label-text">Metric</div>
							</label>
						</label>
					</div>
				</div>

				<div className="settings-display">
					<div className="subtitle">Temperature</div>

					<div className="radio1">
						<input
							type="checkbox"
							id="checkbox-celsius"
							tabIndex="1"
							checked={this.state.temp.celsius}
							onChange={this.handleChange}
						/>
						<label className="settings-label-wrapper" htmlFor={'checkbox-celsius'}>
							<label className="settings-label" htmlFor={'checkbox-celsius'}>
								<div className="settings-label-text">Celsius</div>
							</label>
						</label>
					</div>

					<div className="radio2">
						<input
							type="checkbox"
							id="checkbox-fahrenheit"
							tabIndex="1"
							checked={this.state.temp.fahrenheit}
							onChange={this.handleChange}
						/>
						<label className="settings-label-wrapper" htmlFor={'checkbox-fahrenheit'}>
							<label className="settings-label" htmlFor={'checkbox-fahrenheit'}>
								<div className="settings-label-text">Fahrenheit</div>
							</label>
						</label>
					</div>
				</div>
				<Button
					className={`btn solid`}
					onClick={() => {
						this.handleSubmit();
					}}>
					UPDATE
				</Button>
			</div>
		);
	}
}

export default FormSettings;

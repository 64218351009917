import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let pathObj = {
				flagImagePathSmall: Config.otherData.flagImagePathSmall,
			};
			dispatch({ type: deps.actionTypes.FOOTER_MOUNT, data: pathObj });
		});
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import classNames from 'classnames';
import SkipContent from 'appdir/components/general/SkipContent';
import SlamtrackerContent from 'appdir/components/pages/Slamtracker/SlamtrackerContent';

import MeasurementUtils from 'appdir/lib/analytics';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: SlamtrackerPanel
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['SlamtrackerPanel'],
		panel: state['SlamtrackerPanel'].slamtrackerPanel,
		commonData: state['CommonData'],
		uom: state['Controller'].userPreferences['speed'],
		stubs: state['Config'].stubPages,
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: elm => dispatch(deps.actions.SlamtrackerPanel.mount(elm)),
	hide: () => dispatch(deps.actions.SlamtrackerPanel.hide()),
	show: matchId => dispatch(deps.actions.SlamtrackerPanel.show(matchId)),
});

class SlamtrackerPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: 'preview',
		};
		this.init = true;
		// logger.log('[SlamtrackerPanel] constructor - this:%o', this);
	}

	componentDidMount() {
		logger.log('[SlamtrackerPanel] componentDidMount document.location:%o', document.location);
		this.props.hide();
		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		let stmatch = parsedQs.match;
		if (stmatch) {
			this.props.show(stmatch);
		}
	}

	componentWillUnmount() {
		// window.removeEventListener('resize', this.onResize.bind(this), false);
		clearTimeout(this.results_timeout);
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	logger.log('[SlamtrackerPanel] componentDidUpdate prevState:%o', prevState);
	// 	// logger.log('[SlamtrackerPanel] componentDidUpdate init:%o state:%o', this.init, this.props);

	// 	// if (this.init && this.props.resultsData) {
	// 	// 	this.init = false;
	// 	// 	logger.log('[SlamtrackerPanel] componentDidUpdate geocheck:%o', this.props.paths);
	// 	// }

	// 	//this.setSidepanelState(prevState);
	// }

	toggleClose() {
		this.props.hide();
		MeasurementUtils.dispatchMeasurementCall('slamtrackerPanel', 'Close');
	}

	onLinkClick(e, path) {
		//logger.log('[SlamtrackerPanel] e:%o',e.target);
		//logger.log('[MatchBox] target:', e.getCurrentTarget());
		e.preventDefault();
		this.props.navigate({ path: path });
	}

	render() {
		const { uom, vidOpen } = this.props;
		let matchId = this.props?.panel?.matchId ? this.props.panel.matchId : 0;
		let defaultTab = this.props?.panel?.defaultTab ? this.props.panel.defaultTab : null;
		let slamtrackerPanelClasses = this.props.panel?.display
			? classNames('slamtracker slamtrackerPanel show')
			: classNames('slamtracker slamtrackerPanel');

		// logger.log('[SlamtrackerPanel] render - match:%o', match);
		if (matchId != 0) {
			return (
				<section className={slamtrackerPanelClasses} aria-hidden="true">
					<noindex>
						<div className={`slamtrackerPanel-content insights-style ${uom} ${vidOpen ? "vid-open" : ""}`}>
							<SkipContent isSkipContent={true} skipToNav={true} />
							<div className="slamtrackerPanel-close" onClick={() => this.toggleClose()}>
								<i className="wim-icon-close" />
							</div>
							<div className="slamtrackerPanel-content-channel">IBM Slamtracker</div>
							<SlamtrackerContent matchId={matchId} tab={defaultTab} />
						</div>
					</noindex>
				</section>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamtrackerPanel);

import React, { Component } from 'react';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isEmpty from 'lodash/isEmpty';

import InputBox from 'appdir/components/general/Gigya/screens/elements/InputBox';
import { registrationLogin } from 'appdir/components/general/Gigya/translations/registrationLogin';

class VerificationPending extends Component {
	constructor(props) {
		super(props);

		//gigya-conditional:className="viewport.width < 500 ?gigya-screen v2 portrait mobile:"
		this.classnames = 'gigya-screen v2 portrait';
	}

	render() {
		logger.log('[VerificationPending] render - props:%o context:%o', this.props, this.context);

		return (
			<div
				id="gigya-verification-pending-screen"
				data-width="auto"
				className={this.classnames}
				data-caption="screenset.translations['GIGYA_VERIFICATION_SENT_SCREEN_CAPTION']">
				<form
					className="gigya-resend-verification-code-form"
					data-on-success-screen="gigya-login-screen"
					id="gigya-resend-verification-code-form">
					<div className="gigya-layout-row ui-droppable ui-sortable">
						<label
							className="gigya-label-text gigya-composite-control gigya-composite-control-label"
							data-toolbox-item-type="label">
							For your security, myWimbledon is now requiring all login emails to be verified.
							<br />
							<br />
							After clicking CONTINUE below you will receive a verification email. Please follow the
							included instructions to complete your account verification.
						</label>
						<div
							className="gigya-composite-control gigya-spacer"
							data-toolbox-item-type="spacer"
							data-units="1"
							style={{ height: '10px' }}></div>
						<div
							className="gigya-composite-control gigya-composite-control-textbox"
							data-toolbox-item-type="textbox"
							data-wizard-base-translation-key="TEXTBOX_53553186815043370">
							<label className="gigya-label" htmlFor="gigya-textbox-email">
								<span className="gigya-label-text">Email:</span>
								<label className="gigya-required-display" data-bound-to="email">
									*
								</label>
							</label>
							<input
								type="text"
								name="email"
								id="gigya-textbox-email"
								className="gigya-input-text"
								formNoValidate="formnovalidate"
								tabIndex="0"
								data-array-root=""
								value={op.get(this, 'context.currentUser.requestParams.loginID', '')}
								disabled={true}></input>
							<span className="gigya-error-msg" data-bound-to="email"></span>
						</div>
					</div>
					<div className="gigya-layout-row ui-droppable ui-sortable">
						<div
							className="gigya-composite-control gigya-composite-control-submit"
							data-toolbox-item-type="submit"
							data-wizard-base-translation-key="SUBMIT_59040383411520670">
							<input type="submit" className="gigya-input-submit" value="CONTINUE" tabIndex="0"></input>
						</div>
					</div>

					<div className="gigya-layout-row ui-droppable ui-sortable">
						<div
							className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
							data-bound-to="gigya-resend-verification-code-form"
							data-toolbox-item-type="formError">
							<div
								className="gigya-error-msg gigya-form-error-msg"
								data-bound-to="gigya-resend-verification-code-form"></div>
						</div>
					</div>
					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '10px' }} />
					<div className="gigya-layout-row ui-droppable ui-sortable">
						<label
							className="gigya-composite-control gigya-composite-control-label gigya-message"
							data-toolbox-item-type="label"
							data-wizard-text-alignment="left"
							data-wizard-base-translation-key="LABEL_135099439990555600">
							<a data-switch-screen="gigya-login-screen">Log in with a different account</a>
						</label>
					</div>
				</form>
			</div>
		);
	}
}

VerificationPending.contextType = AuthContext;
export default VerificationPending;

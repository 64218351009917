import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getRole, checkRole } from 'appdir/components/general/Util/Role';
import ServiceItem from 'appdir/components/common-ui/ServiceItem';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import MediaReconsent from 'appdir/components/pages/MediaRequest/MediaReconsent.js';

const mapStateToProps = (state, props) => {
	return {
		currentUser: state['Gigya']['currentUser'],
		loggedIn: state['Gigya']['loggedIn'],
		shelfIcons: state['Config'].myWIcons,
		period: op.get(state['Config'], 'ballot.period', 'pre'),
		version: op.get(state['Config'], 'myWimbledon.version', 0),
		iconPath: op.get(state['Config'], 'myWimbledon.iconPath', ''),
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class RoleIcons extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };

		logger.info('[RoleIcons] constructor - state:%o ', this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[RoleIcons] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);
		let newState = {};

		//check for unique situation of user with Coach role or Coach AND Player role
		//  change icon shelf to Coach role if so
		//  icons shelf in cms component are identified by id in data.roles
		let hasPlayerRole = checkRole(nextProps?.currentUser?.roles, [1000050]);
		let hasCoachRole = checkRole(nextProps?.currentUser?.roles, [1000320]);
		if (nextProps?.data?.roles == '1000050' && !hasPlayerRole && hasCoachRole) {
			newState = {
				data: {
					...prevState.data,
					roles: '1000320',
				},
			};
		}

		if (isEmpty(newState)) {
			newState = null;
		}
		//logger.info('[RoleIcons] getDerivedStateFromProps - newState:%o', newState);
		return newState;
	}

	getSegmentLink(link, segment) {
		return link.replace('<segment>', segment);
	}

	/**
	 *  Return the ServiceItem
	 *   check if user has the Transport role and if so remove the check indicator
	 *   if player does not have Transport role, then link to self role reg (if not a junior)
	 */
	getServiceItem(data, role) {
		if (role == 'player' && data.title == 'Transport') {
			let playerRole = getRole(op.get(this.state, 'currentUser.roles', []), 1000050);
			let isMain =
				op.get(playerRole, 'contactDetails.championshipsRegistration.mainRegistration', false) ||
				op.get(playerRole, 'contactDetails.championshipsRegistration.wheelchairRegistration', false);

			if (playerRole && !isMain) {
				data = null;
			}
		}

		if (data != null) {
			return (
				<ServiceItem
					title={data.title}
					link={this.getSegmentLink(data.url, data.segment)}
					src={`${op.get(this.state, 'iconPath', '')}${data.icon}`}
					target={data.target}
					key={data.key}
					ariaLabel={`link ${data.key + 1} out of ${data.length} links in ${this.props.role} sub group.`}
				/>
			);
		} else {
			return null;
		}
	}

	render() {
		let shelfType = this.state.tag || this.state.type || op.get(this.state, 'data.tag', null);
		logger.info('[RoleIcons] render - state:%o role:%o shelfType:%o', this.state, this.props.role, shelfType);

		let transitionTime = 150;
		let titles = op.get(this.state, `shelfIcons.titles`, {});
		let userRoles = op.get(this.state, 'currentUser.roles', []);
		//let playerRole = getRole(op.get(this.state, 'currentUser.roles', []), 1000050);
		let roles = [...userRoles];

		if (this.state.loggedIn) {
			roles.push({
				key: 'all',
			});
		}

		roles = roles.filter(role => {
			if (role.key == this.props.role || role.roleId == op.get(this.state, 'data.roles', null)) {
				//check for addtl paramters for specific roles
				if (role.key == 'debenture' && this.state.version < 2) {
					return false;
				} else if (role.key == 'ballot' && this.state.period == 'pre') {
					return false;
				}
				return true;
			} else {
				return false;
			}
		});

		if (this.state.loggedIn && roles.length > 0) {
			let role = roles[0].key;
			let shelfKey = role;
			let segment = '';
			let icons = op.get(this.state, `shelfIcons.${role}`, []);

			/**
			 * find the appropriate icons based on role/roleSegmentId
			 */
			if (roles[0].segment) {
				segment = roles[0].segment;
				shelfKey = `${role}:${segment}`;
				if (op.get(this.state, `shelfIcons.${shelfKey}`, false)) {
					icons = op.get(this.state, `shelfIcons.${shelfKey}`, []);
				}
			}

			/**
			 * remove icons not designated for the specified display
			 */
			icons = icons.filter(icons => {
				if (op.get(icons, 'show', shelfType).includes(shelfType)) {
					return true;
				} else {
					return false;
				}
			});

			//logger.info('[RoleIcons] render - role:%o icons:%o', role, icons);

			//special manipulations for player icons
			// - remove transport shelf if is a player
			// if (role == 'transport' && playerRole) {
			// 	icons = [];
			// }

			/**
			 * return icon list based on shelfType
			 * - private includes welcom text
			 * - myw includes the section title
			 * - home is in a Collapsible element
			 */

			const needsReconsent = roles.find(val => val.key === 'media' && val.reconsent);

			return icons.length > 0 && this.state.version >= 2 ? (
				shelfType == 'private' ? (
					<div className="column-layout myw-icons-shelf landing-shelf">
						<div className="item title full-width landing-item">
							<div className="row-title">
								<span className="welcome-text">{`Welcome `}</span>{' '}
								{this.state.currentUser.profile.firstName}
							</div>
						</div>
						{icons.map((d, i) => {
							return this.getServiceItem(
								{
									title: d.title,
									url: d.url,
									segment: segment,
									icon: d.icon,
									target: d.target,
									key: i,
								},
								role
							);
						})}
					</div>
				) : shelfType == 'myw' ? (
					<Fragment>
						<div className="column-layout no-padding left right myw-icons">
							<div className="four-col service-section-title">{titles[role]['title']}</div>
							{!needsReconsent ? (
								icons.map((d, i) => {
									return this.getServiceItem(
										{
											title: d.title,
											url: d.url,
											segment: segment,
											icon: d.icon,
											target: d.target,
											key: i,
										},
										role
									);
								})
							) : (
								<MediaReconsent />
							) // show reconsent page if user needs to reconsent
							}
						</div>
						<div className="greenstripe-container service">
							<div className="greenStripe"></div>
						</div>
					</Fragment>
				) : shelfType == 'home' && this.props.role === this.props.activeRole ? (
					<div className="home-collapse">
						{!needsReconsent ? (
							<div className="column-layout myw-icons-shelf home-shelf">
								{icons.map((d, i) => {
									return (
										<div
											key={`role-icon-${i}`}
											onBlur={() => {
												if (i === icons.length - 1) {
													this.props.onRoleFocused(true);
												}
											}}>
											{this.getServiceItem(
												{
													title: d.title,
													url: d.url,
													segment: segment,
													icon: d.icon,
													target: d.target,
													key: i,
													length: icons.length,
												},
												role
											)}
										</div>
									);
								})}
							</div>
						) : (
							<MediaReconsent /> // show reconsent page if user needs to reconsent
						)}
					</div>
				) : null
			) : null;
		} else {
			return null;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleIcons);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: EventNavigator
 *
 * Used for naviagting to PDE Event landing pages
 * -----------------------------------------------------------------------------
 */

class EventNavigator extends Component {
	constructor(props) {
		super(props);

		// set data for select menus
		this.eventData = {
			options: [
				{ name: 'MAIN DRAW', value: '/s/player/main_draw.html' },
				{ name: 'JUNIORS', value: '/s/player/juniors.html' },
				{ name: 'QUALIFYING', value: '/s/player/qualifying.html' },
				{ name: 'WHEELCHAIR', value: '/s/player/wheelchairs.html' },
			],
		};

		let openItem = this.eventData.options.filter(item => {
			return item.value == props.location.pathname;
		});
		if (openItem.length > 0) {
			this.state = {
				open: false,
				selected: openItem[0].value,
			};
		} else {
			this.state = {
				open: false,
			};
		}

		this.onSelect = this.onSelect.bind(this);
		this.onOpen = this.onOpen.bind(this);

		logger.log('[EventNavigator] props:%o, state:%o', props, this.state);
	}

	onSelect(route) {
		logger.log('[EventNavigator] onSelect:%o', route);
		if (this.state.selected !== route) {
			this.setState({
				redirect: route,
			});
		}
	}

	onOpen(which) {
		logger.log('[EventNavigator] onOpen:%o', which);
		// this.props.filter({
		// 	open: which,
		// });
	}

	render() {
		logger.log('[EventNavigator] render - state:%o', this.state);

		if (this.state.redirect) {
			return <WimRedirect to={this.state.redirect} />;
		} else {
			return (
				<div className="pde-event-wrapper">
					<div className="pde-event-selector">
						<SelectMenu
							name="event"
							attributes={this.eventData}
							selected={this.state.selected}
							show={this.state.open}
							onSelected={this.onSelect}
							onOpen={this.onOpen}
						/>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(EventNavigator);

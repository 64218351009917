/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: SectionHeaderBar
 * -----------------------------------------------------------------------------
 */

const SectionHeaderBar = props => {
	logger.log('[SectionHeaderBar] props', props);
	let data = props.data;
	return (
		<div className="section-header-bar-wrapper">
			<div>{data.title}</div>
		</div>
	);
};

export default SectionHeaderBar;

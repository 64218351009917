/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import filter from 'lodash/filter';
import { getServeTeam } from '../../Utilities';
import Translate from '../Translate';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: DepthOfReturn
 * -----------------------------------------------------------------------------
 */

const DepthOfReturn = props => {
	const getDepthPct = params => {
		//player 1  Server = 2 && ReturnDepth = D / (Server = 2  && ReturnDepth = D + ReturnDepth = ND)
		//player 2  Server = 1 && ReturnDepth = D / (Server = 1 && ReturnDepth = D + ReturnDepth = ND)

		let den = filter(props.data.pointHistory, function (e) {
			return (
				parseInt(e.PointNumber, 10) <= parseInt(params.point, 10) &&
				e.ReturnDepth != '' &&
				e.PointServer != params.player
			);
		});

		let num = filter(props.data.pointHistory, function (e) {
			return (
				parseInt(e.PointNumber, 10) <= parseInt(params.point, 10) &&
				e.ReturnDepth == params.type &&
				e.PointServer != params.player
			);
		});

		let pct = den.length != 0 ? Math.round((num.length / den.length) * 100) : 0;

		// logger.log('[StageItems | DepthOfReturn] getDepthPct - pct: %o', pct);

		return pct;
	};

	const getDepthPctWinning = params => {
		//player 1  Server = 2 && ReturnDepth = D / (Server = 2  && ReturnDepth = D + ReturnDepth = ND)
		//player 2  Server = 1 && ReturnDepth = D / (Server = 1 && ReturnDepth = D + ReturnDepth = ND)

		//Server=2  Winner=1 ReturnDepth=D
		var num = filter(props.data.pointHistory, function (e) {
			return (
				parseInt(e.PointNumber, 10) <= parseInt(params.point, 10) &&
				e.ReturnDepth == params.type &&
				getServeTeam(e.PointServer) != params.player &&
				e.PointWinner == params.player
			);
		});

		var den = filter(props.data.pointHistory, function (e) {
			return (
				parseInt(e.PointNumber, 10) <= parseInt(params.point, 10) &&
				e.ReturnDepth == params.type &&
				getServeTeam(e.PointServer) != params.player
			);
		});

		var pct = den.length != 0 ? Math.round((num.length / den.length) * 100) : 0;

		// logger.log('[StageItems | DepthOfReturn] getDepthPctWinning - pct: %o', pct);

		return pct;
	};

	const getDepthObj = () => {
		var depthOb = {
			pct_deep: getDepthPct({
				point: props.data.point,
				player: props.data.team.number,
				type: 'D',
			}),
			pct_shallow: getDepthPct({
				point: props.data.point,
				player: props.data.team.number,
				type: 'ND',
			}),
			pct_deep_win: getDepthPctWinning({
				point: props.data.point,
				player: props.data.team.number,
				type: 'D',
			}),
			pct_shallow_win: getDepthPctWinning({
				point: props.data.point,
				player: props.data.team.number,
				type: 'ND',
			}),
		};

		return depthOb;
	};

	// logger.log('[StageItems | DepthOfReturn] render - state: %o', this.state);
	let depthData = getDepthObj();

	if (depthData.pct_shallow + depthData.pct_shallow_win + depthData.pct_deep + depthData.pct_deep_win != 0) {
		if (props.stage_location == 'slamtracker') {
			return (
				<div className="stage-item depth-of-return si-wrapper">
					<div className="si-wrapper-inner">
						<div className="stage-container">
							<div className="court-wrapper">
								<div className="court-position">
									<div className="depth-wrapper shallow">
										<div className="depth-items shallow">
											<div className="pct-returns-container">
												<div className="pct-returns">
													<div className="pct-returns inner">
														<div className="label-title label-returns">
															<Translate originalText="pct_returns" />
														</div>
														<div className="perc-container">
															<div className="returns-perc">{depthData.pct_shallow}%</div>
														</div>
													</div>
												</div>
											</div>
											<div className="pct-returns-container">
												<div className="pct_winning inner">
													<div className="label-title label-winning">
														<Translate originalText="pct_winning" />
													</div>
													<div className="perc-container">
														<div className="winning-perc">{depthData.pct_shallow_win}%</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="depth-wrapper deep">
										<div className="depth-items deep">
											<div className="returns">
												<div className="returns-perc">{depthData.pct_deep}%</div>
												<div className="winning-perc">{depthData.pct_deep_win}%</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="heading-wrapper">
						<div className="heading">
							<div className="heading-title">Depth of Return</div>
							<div className="subheading-title">
								<Translate
									data={{
										text: 'depth_title',
										replace: [props.data.team.name, props.data.set],
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}
	} else {
		// logger.log('[StageItems | DepthOfReturn] No data to display stage item');
		return null;
	}
};

export default DepthOfReturn;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

// const fetch = () => {
//     let hdr = restHeaders();
//     return axios.get(`/assets/data/en_GB/json/gen/weather.json`, {headers: hdr}).then(({data}) => data);
// };

export default {
	//fetch,
};

import React, { Component } from 'react';
import { logger } from './logger';
import op from 'object-path';


export default class Amp extends Component {
	
	constructor(props) {
		super(props);
		
	
		//logger.log('[Amp] constructor - props:%o', this.props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		
	}
	
	render() {
		//logger.log('[Amp] render - props:%o', this.props);
		return (
			<div id={this.props.id}></div>
		)
	}
	
}

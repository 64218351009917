import React from 'react';

const Title = data => {
	return (
		<span className="select-title">
			<label className="gigya-label" data-binding="true" htmlFor="gigya-dropdown-title">
				<span className="gigya-label-text" data-translation-key="DROPDOWN_TITLE_LABEL" />
				<label
					className="gigya-required-display"
					data-bound-to="data.profile.title"
					style={{}}
					data-binding="true"
					htmlFor="gigya-dropdown-title">
					{' '}
					*
				</label>
			</label>
			<select
				id="gigya-dropdown-title"
				name="data.profile.title"
				defaultValue="default"
				data-required="true"
				tabIndex={data.tabIndex ? data.tabIndex : '0'}>
				<option value="" data-translation-key="DROPDOWN_TITLE_CHOICE_0" />
				<option value="Mr" data-translation-key="DROPDOWN_TITLE_CHOICE_1" />
				<option value="Mrs" data-translation-key="DROPDOWN_TITLE_CHOICE_2" />
				<option value="Miss">Miss</option>
				<option value="Ms">Ms</option>
				<option value="Mx">Mx</option>
				<option value="Dr">Dr</option>
				<option value="Prof">Prof</option>
				<option value="Rev">Rev</option>
				<option value="Sir">Sir</option>
				<option value="Dame">Dame</option>
				<option value="Baroness">Baroness</option>
				<option value="Lady">Lady</option>
				<option value="Lord">Lord</option>
				<option value="Rt Hon">Rt Hon</option>
			</select>
			<span className="gigya-error-msg" data-bound-to="data.profile.title" />
		</span>
	);
};

export default Title;

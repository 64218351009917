/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { createRef, useState, useRef, useEffect} from 'react';
import Slider from 'react-slick';
import Rolex from 'appdir/components/general/StatusPanel/Rolex';
import WimLink from 'appdir/components/general/WimLink';
import { useSelector } from 'react-redux';
import { doMeasurement } from 'appdir/components/general/Analytics';
import FullScreenVideo from 'appdir/components/common-ui/FullScreenVideo';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const FeaturedNews = (props) => {
 * -----------------------------------------------------------------------------
 */

const FeaturedNews = (props) => {
	// logger.log('[FeaturedNews] props:%o', props);
	const featuredNewsRef = createRef();
	let sliderRef = useRef(null);
	const items = props.data;
	const [activeItem, setActiveItem] = useState(0);
	const [vidId, openVideo] = useState(false);
	const [metricsTimeout, setMetricsTimeout] = useState(null);
	const windowSize = useSelector(state => state['PageHeader']['stWindowSize']);

	useEffect(() => {
		return () => {//component will unmount
			logger.log("[FeaturedNews] unmounted");
			clearTimeout(metricsTimeout);
		}
	}, []);

	const onClick = (contentId, type, title) => {
		if(type == 'video'){
			vidId ? openVideo(false) : openVideo(contentId);
		}
		let measureAction = 'click';
		let measureArgs = [];
		let metricType = "";
		let contextData = [{
			moduleTitle: 'featured news',
			contentType: type,
			contentTitle: title
		}];	
		doMeasurement('Home', measureAction, measureArgs, contextData, metricType);
	}

	const closeVid = () => {
		openVideo(false);
	}

	const itemMouseOver = (idx, pause, type, title) => {
		// logger.log("[FeaturedNews] n:%o",idx);
		if(pause){
			sliderRef.slickPause();

			let measureAction = 'hover';
            let measureArgs = [];
            let contextData = [{
				moduleTitle: 'featured news',
				contentPosition: idx,
                contentType: type,
                contentTitle: title
			}];
            let metricType = "";
			if(metricsTimeout){
				clearTimeout(metricsTimeout);
			}
			setMetricsTimeout(setTimeout(() => {
				doMeasurement('Home', measureAction, measureArgs, contextData, metricType);
			}, 2000));            
		}else{
			sliderRef.slickPlay();
			clearTimeout(metricsTimeout);
		}
		sliderRef.slickGoTo(idx);
		setActiveItem(idx);
	}

	const linkType = type => {
		let out = "";
		switch (type) {
			case "news":
				out = "Read";
				break;
		
			default:
				out = "Watch";
				break;
		}
		return out;
	}

	let sliderSettings = {
		
		// pauseOnHover: false, //default true
		dots: windowSize == "desktop" ? false : true,
		arrows: false,
		fade: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		waitForAnimate: false,
		autoplay: true,
    	autoplaySpeed: 6000,
		adaptiveHeight: true,
		beforeChange: (current, next) => setActiveItem(next),
		className: 'featured-news-carousel-desktop',
	};

	return (
		<>
			<div className="featured-news" ref={featuredNewsRef}>
				<Slider {...sliderSettings} ref={slider => {sliderRef = slider;}}>
					{items.map((i, idx) => {
						let contextData = [{
							moduleTitle: 'featured news',
							contentType: i.type,
							contentTitle: i.title
						}];		
						let metricsArgs = {
							page: "Home",
							contextData
						}

						return (
							<div className="news-item" key={`item-${idx}`}>
								<picture>
									{i.image?.large && <source 
										srcSet={i.image.large}
										media="(min-width: 1024px)"
									/>}
									{i.image?.medium && <source 
										srcSet={i.image.medium}
										media="(min-width: 768px)"
									/>}
									{i.image?.small && <source 
										srcSet={i.image.small}
									/>}
									<img 
										src={i.image?.large}
										alt={i.title} 
									/>
								</picture>
								{windowSize != "desktop" && 
									<div className={`featured-items ${windowSize}`}>
										<div className={`featured-item active`}>
											{i.type == "video" ? (
												<div
													className="featured-item-vid"
													id={i.contentId}
													onClick={() => onClick(i.contentId, i.type, i.title)}
													aria-label={`video, ${i.title}`}
													tabIndex={0}
													onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? onClick(i.contentId, i.type, i.title) : null)}>
													<div className='featured-item-inner'>
														<div className='type-tag'>{i.type}</div>
														<div className='featured-title'>{i.title}</div>
															<div className='featured-item-link'>
																{i.buttonText && i.buttonText != "" ? i.buttonText : linkType(i.type)}
															</div>
													</div>
												</div>
											) : (
												<WimLink to={i.url} title={i.title} measure={metricsArgs}>
													<div className='featured-item-inner'>
														<div className='type-tag'>{i.type}</div>
														<div className='featured-title'>{i.title}</div>
															<div className='featured-item-link'>
																{i.buttonText && i.buttonText != "" ? i.buttonText : linkType(i.type)}
															</div>
													</div>
												</WimLink>
											)}
										</div>
									</div>
								}
							</div>
						);
					})}
				</Slider>
				{windowSize == "desktop" && 
				<div className={`featured-items ${windowSize}`}>
					{items.map((i, idx) => {
						let contextData = [{
							moduleTitle: 'featured news',
							contentType: i.type,
							contentTitle: i.title
						}];		
						let metricsArgs = {
							page: "Home",
							contextData
						}

						return (
							<div key={`featured-item-${idx}`} className={`featured-item ${activeItem == idx ? `active` : ``}`} >
								{i.type == "video" ? (
									<div
										className="featured-item-vid"
										id={i.contentId}
										onClick={() => onClick(i.contentId, i.type, i.title)}
										aria-label={`video, ${i.title}`}
										tabIndex={0}
										onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? onClick(i.contentId, i.type, i.title) : null)}>
											<div className='featured-item-inner'
												tabIndex={0}
												aria-labelledby={`featured-title-${idx}`}
												onFocus={() => itemMouseOver(idx, true, i.type, i.title)} 
												onMouseEnter={() => itemMouseOver(idx, true, i.type, i.title)} 
												onMouseLeave={() => itemMouseOver(idx, false)}
												>
													<div className='type-tag'>{i.type}</div>
													<label id={`featured-title-${idx}`} className='featured-title'>{i.title}</label>
													{activeItem == idx && 
														<div className='featured-item-link'>
															{i.buttonText && i.buttonText != "" ? i.buttonText : linkType(i.type)}
														</div>
													}
											</div>
									</div>
								) : (
								<WimLink to={i.url} title={i.title} measure={metricsArgs}>
									<div className='featured-item-inner'
									tabIndex={0}
									aria-labelledby={`featured-title-${idx}`}
									onFocus={() => itemMouseOver(idx, true, i.type, i.title)} 
									onMouseEnter={() => itemMouseOver(idx, true, i.type, i.title)} 
									onMouseLeave={() => itemMouseOver(idx, false)}									
									>
										<div className='type-tag'>{i.type}</div>
										<label id={`featured-title-${idx}`} className='featured-title'>{i.title}</label>
										{activeItem == idx && 
											<div className='featured-item-link'>
												{i.buttonText && i.buttonText != "" ? i.buttonText : linkType(i.type)}
											</div>
										}
									</div>
								</WimLink>
								)}
							</div>)
					})}
					<div className='featured-rolex'>
						<Rolex />
					</div>
				</div>}
				{windowSize == "tablet" && 
					<div className='featured-rolex tablet'>
						<Rolex />
					</div>
				}
			</div>
			{vidId !== false && <FullScreenVideo closeVid={() => closeVid()} attributes={{videoId: vidId}} />}
		</>
	);
};

export default FeaturedNews;

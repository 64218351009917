import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { doMeasurement } from 'appdir/components/general/Analytics';

const mapStateToProps = (state, props) => {
	return {
		...props,
		Controller: state['Controller'].favourites,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	showFavorite: data => dispatch(deps.actions.Controller.toggleShowFavorites(data)),
});

class Favorites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	componentDidMount() {}

	toggleFavorite() {
		let newFavoriteState = {};
		let favoriteFlag = this.props.Controller.show;
		let toggleFavoriteFlag = !favoriteFlag;

		newFavoriteState['favourites'] = { ...this.props.Controller, show: toggleFavoriteFlag };

		doMeasurement('Favorites', 'Favorites Only', [], [{ status: toggleFavoriteFlag ? 'On' : 'Off' }]);
		this.props.showFavorite(newFavoriteState);
	}

	componentDidUpdate(prevState) {}

	render() {
		logger.log('[Favorites] render - this:%o', this);
		return (
			<button
				onClick={() => {
					this.toggleFavorite();
				}}
				tabIndex={0}
				// onKeyPress={e => (e.key === 'Enter' ? this.toggleFavorite() : null)}
				id="favorites-toggle"
				aria-label="favorites toggle"
				role="button"
				type="button"
				className={`favorites-toggle ${this.props.Controller.show ? 'on' : ''}`}></button>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);

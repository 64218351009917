/**
 * -----------------------------------------------------------------------------
 * Imports
 * ----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import Config from './Config';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: ControlBar
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.Config,
	...props,
});

const mapDispatchToProps = dispatch => ({
	update: () => dispatch(deps.actions.Config.update()),
	updateStatic: item => dispatch(deps.actions.Config.updateStatic(item)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Config);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { getComponents, getAttributesArray, validateData } from 'appdir/components/general/Util';
import React, { Component } from 'react';
import JSXWrapper from 'appdir/components/general/JSXWrapper';
import { parseString } from 'xml2js';
import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';
import endsWith from 'lodash/endsWith';
import isEmpty from 'lodash/isEmpty';
import { any } from 'appdir/components/general/Util/Functions';
import MeasurementUtils from 'appdir/lib/analytics';
import { handleColumns } from 'appdir/components/general/Util';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import SiteMap from 'appdir/components/general/SiteMap';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import { ContentPageSecureContext } from './context';
import MediaReconsent from '../MediaRequest/MediaReconsent';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['ContentPageSecure'],
		...state['Gigya'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContentPageSecure.mount()),
	unmount: () => dispatch(deps.actions.ContentPageSecure.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

const getComponentList = list => {
	let comps = [];

	forIn(list, function(value, key) {
		if (value.hasOwnProperty('type')) {
			comps.push(value['type']);
		} else {
			logger.log('[ContentPageSecure] getComponentList - removing: %o:', key);
		}
	});

	//logger.log('[ContentPageSecure] getComponents - list:%o:', list);

	comps = uniq(comps);
	//logger.log('[ContentPageSecure] getComponents - %o:', comps);
	return comps;
};

const getComponentListArray = list => {
	let comps = [];

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				comps.push(item['type']);
			} else {
				//logger.log('[ContentPage] getComponentList - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	comps.push('WimLink');
	//logger.log('[ContentPage] getComponents - %o:', comps);
	return comps;
};

class ContentPageSecure extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			authorized: false,
			dataLoad: 'loading',
			needsReconsent: false,
		};

		this.lastDataPath = '';

		logger.log('[ContentPageSecure] constructor - state:%o', this.state);
	}

	componentDidMount() {
		logger.log('[ContentPageSecure] componentDidMount');
	}

	componentWillUnmount() {
		logger.log('[ContentPageSecure] componentWillUnmount');
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[ContentPageSecure] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);

		let data = this.state.data;
		let authorized = this.state.authorized;
		let currentUser = op.get(this.state, 'currentUser', {});
		let dataLoad = this.state.dataLoad;

		if (!this.loggedIn(currentUser)) {
			data = '';
			authorized = false;
			this.lastUser = '';
		}

		if (nextProps.location.pathname != this.state.location.pathname) {
			this.props.unmount();
			data = null;
			dataLoad = 'loading';
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				data: data,
				authorized: authorized,
				dataLoad: dataLoad,
			};
		});

		//logger.log('[ContentPageSecure] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'currentUser', {});
		let loggedIn = this.loggedIn(currentUser);
		let roleValidated = this.checkRole(currentUser);

		if (!loggedIn || !roleValidated) {
			this.lastDataPath = '';
		}

		// logger.log(
		// 	'[ContentPageSecure] componentDidUpdate - user:%o state:%o roleValidated:%o loggedIn:%o',
		// 	op.get(currentUser, 'profile.lastName', ''),
		// 	this.state,
		// 	roleValidated,
		// 	loggedIn
		// );

		//logger.log('[ContentPageSecure] componentDidUpdate - loggedIn:%o roleValidated:%o state:%o', loggedIn, roleValidated, this.state);
		if (this.state.dataUrl != '' && this.state.dataUrl != undefined && loggedIn && roleValidated) {
			//logger.log('[ContentPageSecure] componentDidUpdate - lastUser:%o current:%o lastPath:%o path:%o', this.lastUser, currentUser.UID, this.lastDataPath, this.state.dataUrl);

			if (this.state.dataUrl != this.lastDataPath || currentUser.UID != this.lastUser) {
				this.lastDataPath = this.state.dataUrl;
				this.lastUser = currentUser.UID;
				logger.log('[ContentPageSecure] componentDidUpdate - load path:%o', this.state.dataUrl);

				this.props
					.getJWT()
					.then(token => {
						logger.log('[ContentPageSecure] componentDidUpdate - token:%o', token.jwt);
						this.callContent(token.jwt.id_token);
					})
					.catch(error => {
						logger.error('[ContentPageSecure] componentDidUpdate - error getting token');
						logger.error('[ContentPageSecure] componentDidUpdate - :o', error);
					});
			}
		} else if (!loggedIn && this.state.dataLoad != 'nouser') {
			//logger.warn('[ContentPageSecure] componentDidUpdate - improper data url: %o', this.state.dataUrl);
			this.setState({
				dataLoad: 'nouser',
			});
		} else if (loggedIn && !roleValidated && this.state.dataLoad != 'loaded') {
			this.setState({
				dataLoad: 'loaded',
			});
		}

		handleColumns();
	}

	replaceLinks(jsx) {
		logger.log('[ContentPageSecure] replaceLinks - jsx:%s', jsx);
		let secureLinks = jsx.match(/<a href="\/s\/.*?a>/g);
		let stdLinks = jsx.match(/<a href="\/en_GB\/.*?a>/g);
		let result = secureLinks ? secureLinks : [];
		result = result.concat(stdLinks ? stdLinks : []);
		logger.log('[ContentPageSecure] replaceLinks - result:%o', result);
		if (result.length) {
			result.forEach(link => {
				let m = link.match(/href="(.*)">(.*)<\/a>/);
				jsx = jsx.replace(link, `<WimLink to="${m[1]}">${m[2]}</WimLink>`);
			});
		}
		logger.log('[ContentPageSecure] replaceLinks - jsx:%o', jsx);
		return jsx;
	}

	callContent(jwt_token) {
		deps.services.ContentPageSecure.fetchContent(this.state.dataUrl, jwt_token)
			.then(result => {
				//logger.log('[ContentPageSecure] callContent result:%o', result);

				//set the xml parse options
				let options = {
					explicitArray: false,
					normalize: true,
					trim: true,
					mergeAttrs: true,
					valueProcessors: [],
				};

				//parse the xml
				parseString(result, options, function(err, json) {
					result = json.contentItem;
				});
				logger.log('[ContentPageSecure] callContent result:%o', result);

				//clean the jsx block
				result.jsx = op.get(result, 'jsx._', result.jsx);
				result.jsx = result.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');
				//logger.log('[ContentPageSecure] callContent - result:%o', result);

				//try replacing relative links
				result.jsx = this.replaceLinks(result.jsx);

				this.setState({
					data: result,
					dataLoad: 'loaded',
					authorized: true,
				});

				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					content: result,
				});
			})
			.catch(error => {
				logger.error('[ContentPageSecure] callContent error:%o', error);
				if (error.status == 404) {
					this.setState({
						data: 'notfound',
						dataLoad: 'loaded',
					});
				} else if (error.status == 401) {
					this.setState({
						authorized: false,
						dataLoad: 'loaded',
					});
				} else {
					this.setState({
						data: 'notfound',
						authorized: false,
						dataLoad: 'loaded',
					});
				}
			});
	}

	loggedIn(user) {
		logger.log(
			'[ContentPageSecure] loggedIn - empty:%o error:%o',
			isEmpty(user),
			op.get(this.state, 'currentUser.errorCode')
		);

		if (isEmpty(user)) {
			return false;
		} else if (op.get(this.state, 'currentUser.errorCode') != 0) {
			return false;
		} else {
			return true;
		}
	}

	playFullVideo(id) {
		logger.log('[ContentPageSecure] playFullVideo - id:%o', id);
	}

	getHeader() {
		logger.log('[ContentPageSecure] getHeader - state:%o', this.state);

		let videoId =
			this.state.data.header && this.state.data.header.videoId && this.state.data.header.video
				? this.state.data.header.videoId
				: null;

		let video_attributes = {
			playerId: 'main',
			cmsId: videoId,
			contentId: null,
			videoUrl: null,
			title: null,
			thumb: null,
			autoplay: true,
			fullscreenMode: '',
			style: `video-wrapper one-col modal header`,
			aspect: 'wide',
			url: null,
		};

		//content page, (category)
		if (this.state.category != 'articles' && !this.state.landing) {
			let videoclass = videoId ? 'video' : '';

			return (
				<div className="news--header-wrapper">
					<div className={`news--header ${videoclass}`}>
						<div className="news--header-title">
							<h1>{this.state.data.title}</h1>
						</div>
						<div className="news--header-shorttitle">
							<h1>{this.state.data.shortTitle}</h1>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
						</div>
					</div>
					{videoId ? (
						<div className="news--header-videoLink">
							<VideoWrapper attributes={video_attributes} type="header" />
						</div>
					) : null}
				</div>
			);
		} else if (this.state.landing && videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video" />
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.state.landing) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header" />
				</div>
			);
		}

		//article pages
		else if (videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video">
						<div className="news--header-icon">
							<i className="wim-icon-news" aria-label="news icon" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h1>{this.state.data.title}</h1>
						</div>
						<div className="news--header-shorttitle">
							<h1>{this.state.data.shortTitle}</h1>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.state.data.header.image && this.state.data.header.image.length > 25) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-icon">
							<i className="wim-icon-news" aria-hidden tabIndex={-1} role="none" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h1>{this.state.data.title}</h1>
						</div>
						<div className="news--header-shorttitle">
							<h1>{this.state.data.shortTitle}</h1>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	getContent() {
		logger.log('[ContentPageSecure] getContent - state:%o', this.state);

		let components = null;
		let attributes = null;

		if (this.state.data) {
			let attr = this.state.data.dependencies.data || {};
			attr.currentUser = this.state.currentUser;
			attr.dataUrl = this.state.dataUrl;

			components = getComponents(getComponentListArray(this.state.data.dependencies.data));
			attributes = getAttributesArray(attr);
			logger.log('[ContentPageSecure] getContent - components:%o', components);
			logger.log('[ContentPageSecure] getContent - attributes:%o', attributes);
		}

		if (this.state.data) {
			return (
				<ContentPageSecureContext.Provider
					value={{
						dateConfig: this.state.hospDates,
						mediaForm: this.state.mediaDate.year,
					}}>
					{this.getHeader()}
					<JSXWrapper
						id={this.state.data.contentId}
						bindings={attributes}
						components={components}
						jsx={this.state.data.jsx}
					/>
				</ContentPageSecureContext.Provider>
			);
		} else {
			return <LoadingIndicator />;
		}
	}

	checkLogin() {
		let user = op.get(this.state, 'currentUser.profile', {});

		logger.log(
			'[ContentPageSecure] checkLogin - state.dataLoad:%o auth:%o user:%o',
			this.state.dataLoad,
			this.state.authorized,
			user.lastName
		);

		if (!this.loggedIn(user)) {
			logger.log('[ContentPageSecure] render empty User - state:%o', this.state);
			return (
				<Fragment>
					<div className="four-col byline ">
						<div className="byline--attribution" />
					</div>
					<div className="four-col textWrapContent ">
						<p />
						<GigyaScreenSet
							params={{
								screen: 'login_screen',
								register: this.state.screen.register,
								social: this.state.screen.social,
								redirect: false,
								showSuccess: false,
							}}
							inline={true}
						/>
					</div>
				</Fragment>
			);
		} else {
			// data hasn't loaded yet
			if (this.state.dataLoad == 'nouser') {
				logger.log('[ContentPageSecure] render loading - state:%o', this.state);
				return <LoadingIndicator />;
			} else if (this.state.authorized) {
				logger.log('[ContentPageSecure] render authorized - state:%o', this.state);
				return this.getContent();
			} else {
				logger.log('[ContentPageSecure] render not authorized - state:%o', this.state);
				return (
					<div>
						<div className="name">
							<SiteMap notFound={true} />
						</div>
					</div>
				);
			}
		}
	}

	checkRole(user) {
		let roles = op.get(user, 'roles', []);
		logger.log('[ContentPageSecure] checkRole - roles:%o', roles);
		let roleids = op.get(this.state, 'roleIds', []);
		logger.log('[ContentPageSecure] checkRole - roleids:%o', roleids);

		// check if reconsent == true if content page is media
		const contentType = this.state.category || this.state.landing;
		let needsReconsent = false;
		if (contentType === 's_media') {
			needsReconsent = roles.find(val => val.key === 'media' && val.reconsent);
			if (this.state.needsReconsent !== needsReconsent) this.setState({ needsReconsent: needsReconsent });
		} else if (this.state.needsReconsent) {
			// set back to false when changing pages
			this.setState({ needsReconsent: false });
		}

		let found =
			roleids.length == 0 ||
			any(roles, role => {
				return any(roleids, id => id == role.roleId) && role.approved;
			});
		logger.log('[ContentPageSecure] checkRole - user:%o found:%o', user, found);
		return found;
	}

	render() {
		logger.log('[ContentPageSecure] render - state:%o', this.state);

		if (this.state.data == 'notfound') {
			logger.log('[ContentPageSecure] render not found - state:%o', this.state);
			let header_propsData = {
				headerType: 'generic',
				title: 'Sitemap',
				shortTitle: 'Sitemap',
				metaTitle: 'Page not found',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<SiteMap notFound={true} />
					</div>
					<Footer />
				</Template>
			);
		}

		// if gigya loaded
		// and not loading content
		// and content loaded from API, or no user (not logged in)
		else if (this.state.gigyaLoaded && this.state.dataLoad != 'loading') {
			logger.log('[ContentPageSecure] render gigyaLoaded - state:%o', this.state);
			let flex = this.state.data.tiled && this.state.data.tiled == 'true' ? 'flex' : '';

			let header_propsData = {
				headerType: this.state.category || this.state.landing,
				shortTitle: this.state.data.shortTitle,
				scrollElem: '.content-main',
				metaTitle: this.state.data.title,
				metaDescription: this.state.data.abstract,
				metaSeoTitle: this.state.data.seoTitle,
				metaSeoDescription: this.state.data.seoDescription,
				metaDate: this.state.data.date,
				metaPlayers: this.state.data.players,
				scroll: false,
			};

			//initially was checking for seemingly valid url to image or video due to invalid test data
			let image = op.get(this.state, 'data.header.image', false);
			let video = op.get(this.state, 'data.header.video', false);

			if (image && image.length > 25) {
				header_propsData['imageSrc'] = image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (video && video.length > 25) {
				header_propsData['videoSrc'] = video;
			}

			logger.log('[ContentPageSecure] render - propsData:%o', header_propsData);

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className={`content-main column-layout news ${flex}`}>
						{!this.state.needsReconsent ? this.checkLogin() : <MediaReconsent />}
					</div>
					<Footer />
				</Template>
			);
		} else {
			logger.log('[ContentPageSecure] render loading - state:%o', this.state);
			//logger.warn('[ContentPageSecure] render - data not loaded, state:%o', this.state);

			let header_propsData = {
				headerType: 'generic',
				titleElem: '.news--header',
				scroll: false,
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPageSecure);

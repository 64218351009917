import classNames from 'classnames';
import React, { Component } from 'react';
import { handleRequestTicketCancellation } from 'appdir/components/pages/Tickets/ReusableTicketActionFuncs';
import Select from 'react-select';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';

class CancelAction extends Component {
	constructor(props) {
		super(props);

		this.onHandleTicketAction = this.onHandleTicketAction.bind(this);
		this.onConfirmDropdownInput = this.onConfirmDropdownInput.bind(this);
	}

	componentDidMount() {
		measureInAppContext({
			pageTitle: 'Tickets',
			action: 'Cancel Transfer',
			args: [],
			metricType: 'state',
		});
	}

	onHandleTicketAction(which, error, msg, msg2) {
		// logger.log('[CancelAction - onHandleTicketAction - which:%o, error:%o, msg:%o, msg2:%o', which, error, msg, msg2)
		if (error) {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Cancel Ticket Sumbit',
					args: 'Failed',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall('Cancel Ticket Sumbit Failed');
			}

			let message1, message2;
			let message1Default = 'There was an error processing your request. Please try again.';
			let message2Default = '';

			const errCode = error?.data?.response?.failure[0] ? error.data.response.failure[0].errorCode : '';

			/** if there is no error code, use default messages */
			if (!errCode) {
				if (error?.msg) {
					message1 = error?.msg;
					message2 = message2Default;
				} else {
					message1 = message1Default;
					message2 = message2Default;
				}
			} else {
				message1 = message1Default;
				message2 = error?.data?.response?.failure[0]?.errorMessage
					? error?.data?.response?.failure[0]?.errorMessage
					: message2Default;
			}

			// logger.log('[CancelAction - onHandleTicketAction - message1:%o, message2:%o', message1, message2);

			// set actionMsg as '' to return to ticket transfer modal, rather than returning to ticket list
			// this.onRenderConfirmAction(
			// 	'failure',
			// 	message1,
			// 	message2,
			// 	which === 'recall' ? `Return to cancel ticket transfer` : `Return to ${which} ticket`,
			// 	() => this.setState({ actionMsg: '' })
			// );
			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: `${which} Error`,
				message: `${message1} ${message2}`,
				useTimer: false,
			});
		} else {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Cancel Ticket Sumbit',
					args: 'Successful',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall('Cancel Ticket Sumbit Successful');
			}
			if (this.props.onSetActionSubmitted) this.props.onSetActionSubmitted();
			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'success',
				title: `${which} Success`,
				message: `${msg} ${msg2}`,
				useTimer: false,
			});
			// this.onRenderConfirmAction('successful', msg, msg2, 'Return to all tickets', () => closeModal(true));
		}
	}

	onConfirmDropdownInput(spectatorId) {
		if (this.state.cancelReason) {
			// this.onRenderConfirmAction('submitting', 'submitting');
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'updating',
				title: 'Submitting cancel',
				message: 'Please do not close your browser window while submitting.',
				useTimer: false,
			});
			handleRequestTicketCancellation(
				spectatorId,
				this.props.fullTicketValue,
				this.props.callTicketActions,
				this.onHandleTicketAction,
				'The ticket has been cancelled.',
				this.state.cancelReason
			);
		} else {
			this.setState({ dropdownError: true });
		}
	}

	render() {
		const { optionsArr, fullTicketValue, closeModal } = this.props;
		const spectatorId = fullTicketValue?.spectatorId || '';

		return (
			<div>
				<div className="ticket-action-modal-information-text">Are you sure you want to cancel this ticket?</div>
				<label className="ticket-action-dropdown-label">
					{'Select a cancellation reason:'}
					<Select
						isSearchable={false}
						options={optionsArr}
						onChange={value => this.setState({ cancelReason: value, dropdownError: false })}
						required
					/>
				</label>
				{!this.state.cancelReason ? (
					<div className={this.props.isWebView ? 'webview-ticket-action-errorMsg' : 'ticket-action-errorMsg'}>
						Please make a selection before continuing
					</div>
				) : null}

				<button
					role="button"
					className={classNames(
						'ticket-stub-actions-value',
						this.props.isWebView
							? 'webview-ticket-action-modal-button-green webview-ticket-stub-tablet-width'
							: 'ticket-action-modal-button-green'
					)}
					onClick={() => this.onConfirmDropdownInput(spectatorId)}>
					Submit
				</button>

				<button
					role="button"
					className={
						this.props.isWebView
							? 'webview-ticket-stub-actions-value webview-ticket-stub-tablet-width'
							: 'ticket-stub-actions-value'
					}
					onClick={() => closeModal()}>
					Cancel
				</button>
			</div>
		);
	}
}

export default CancelAction;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { relatedContentVideo } from 'appdir/components/data/Schema';

import { getJson } from 'appdir/components/general/Util/Services';

//const schema = require('appdir/components/data/Schema/index.js');
const v = new Validator();

/**
 * fetch video json file
 *   - 2019, trying new pattern to use utility Services for axios requests
 * @param {String} path
 */
const fetch = path => {
	return new Promise((resolve, reject) => {
		getJson(path)
			.then(result => {
				resolve(result);
			})
			.catch(error => {
				reject(error);
			});
	});
};

const fetchRelated = path => {
	let hdr = restHeaders();

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				//logger.log('[VideoPlayer] fetchRelated - path: %o', path);
				let validateResult = v.validate(response.data, relatedContentVideo);
				if (validateResult.valid) {
					return response.data;
				} else {
					// throw error and go to catch block
					throw new Error('failed schema validation');
				}
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			//logger.warn('[VideoPlayer] fetchRelated - %o', error.message);
			throw new Error(error.message);
		});
};

export default {
	fetch,
	fetchRelated,
};

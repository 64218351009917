import findIndex from 'lodash/findIndex';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { passAppData } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * handle app measurement calls
 * used for actions
 * @param {Array} args - array of string for tracking call
 */
// export const measureApp = (...args) => {
// 	let idx = findIndex(args, function(o) {
// 		return !o;
// 	});
// 	if (idx >= 0) {
// 		args[idx] = '';
// 	}
// 	logger.log('[Analytics] measureApp - args:%o', args);

// 	//adobeMeasureApp(args);
// };

/**
 * call measureApp and set optional properties
 * @param {Object} props
 * @param {Array} args
 */
// export const measureAppProps = (args, props) => {
// 	let idx = findIndex(args, function(o) {
// 		return !o;
// 	});
// 	if (idx >= 0) {
// 		args[idx] = '';
// 	}
// 	logger.log('[Analytics] measureAppProps - args:%o', args);

// 	//adobeMeasureAppProps(args, props);
// };

/**
 * handle external link tracking
 * @param {array} option - list of measurement options
 */
export const measureInApp = (...mArgs) => {
	let search = document.location.search.replace(/^\?/, '');
	let parsedQs = getQuerystringValues(search);
	let queryString = '?text=';
	queryString += encodeURIComponent(mArgs.join(':'));

	logger.log('[Analytics] measureInApp - parsedQs:%o', parsedQs);
	logger.log('[Analytics] measureInApp - measureString:%o', 'http://wimbledon/metrics' + queryString);

	if (!parsedQs['ignoreClicks']) {
		document.location.href = 'http://wimbledon/metrics' + queryString;
	}
};

/**
 * handle metrics through apps with context var
 */
export const measureInAppContext = props => {
	let search = document.location.search.replace(/^\?/, '');
	let parsedQs = getQuerystringValues(search);
	let metricsString = []
		.concat(props.pageTitle || [])
		.concat(props.action || [])
		.concat(props.args || [])
		.join(':');

	let context = props.context
		? props.context.map(item => {
				return {
					name: Object.keys(item)[0],
					value: Object.values(item)[0],
				};
		  })
		: [];
	
	/** 
	 * introduced in 2024 for webviews
	 * 'state' or 'action'
	 *  default is 'action' */
	let metricType = props?.metricType ? props?.metricType : 'action';

	// props.pageTitle ? metricsArray.concat(props.pageTitle) : null;
	// props.action ? metricsArray.concat(props.action) : null;
	// props.args ? metricsArray.concat(props.args) : null;
	// let metricsString = metricsArray.join(':');

	if (!parsedQs['ignoreClicks']) {
		let metricsObject = {
			type: 'metrics',
			data: {
				metricsString: metricsString,
				metricsContext: context || [],
				metricType: metricType
			},
		};
		logger.log('[Analytics] measureInAppContext - metricsObject:%o', metricsObject);
		passAppData(metricsObject);
	}
};

/**
 * handle metrics through apps with context var
 * accepts object
 */
export const measureInWeb = props => {
	let data = {};
	if (Array.isArray(props.context)) {
		props.context.map(item => {
			data = {
				...data,
				...item,
			};
		});
	}

	MeasurementUtils.dispatchMeasurementCall(props.action, {
		...data,
	});
};

/**
 *
 * handle metrics calls for webview and web
 * web doesn't use pageTitle because it is known by Adobe
 * web doesn't use measure_args, just pass an empty array [] if this is just for web
 *
 */
/** Example
	let measureArgs = [];
	let contextData = [{item: "Menu"}, {action: "Open"}, { player_id: id }];
	if (values.webview) {
		measureArgs = ['Menu', 'Open'];
		contextData = [{ player_id: id }]
	}
	doMeasurement('Draws', 'Player', measureArgs, contextData);
**/

/** doMeasurement 
 *  metricType is used for webviews - introduced in 2024
 *  if nothing is passed, default value is 'action', which is for click measurements
 *  the other value is 'state', which is used for a pageView measurement
 * **/
export const doMeasurement = (pageTitle, measureAction, measure_args, context = [], metricType) => {
	if (window.webview) {
		measureInAppContext({
			pageTitle: pageTitle,
			action: measureAction,
			args: measure_args,
			context: context,
			metricType: metricType
		});
	} else {
		measureInWeb({
			action: measureAction,
			context: context,
		});
	}
};

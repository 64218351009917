/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import MatchBox from 'appdir/components/scoring/MatchBox';

import SlamtrackerChallenges from 'appdir/components/pages/Slamtracker/elements/SlamtrackerChallenges';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: Slamtracker Live Panel
 * -----------------------------------------------------------------------------
 */
const SlamtrackerScoreboard = props => {
	//render() {
	// logger.log("[SlamtrackerScoreboard] props:%o", props);

	let { match } = props;
	let { matchid } = props;
	let { matchlist } = props;
	let { suppressProfileLink } = props;

	let liveVideo = true;

	let foundMatch = matchlist?.find(function(match) {
		return match.match_id == matchid;
	});

	if (typeof foundMatch !== 'undefined') {
		liveVideo = true;
	} else {
		liveVideo = false;
	}

	return (
		<section className="slamtracker-scoreboard">
			<div className="content">
				{match.scores && matchid == match.match_id ? (
					<MatchBox
						attributes={{
							data: match,
							style: 'live',
							event: match.shortEventName,
							// tableHeader: match.courtName,
							showLinks: false,
							usePlayerImages: true,
							isFeaturedMatch: true,
							liveVideo: liveVideo,
							useRadio: true,
							suppressProfileLink: suppressProfileLink,
							isSidepanel: true,
						}}
						key={`slamtrackerScoreboard-${match.match_id}`}
					/>
				) : (
					<LoadingIndicator type="white" />
				)}
			</div>
			<div className="more">
				<div className={`challenges team-one ${match.team1.idB != null ? 'doubles' : ''}`}>
					<SlamtrackerChallenges
						data={{
							available: match.team1.challengesAvailable,
							used: match.team1.challengesUsed,
						}}
					/>
				</div>
				<div className="radio" />
				<div className={`challenges team-two ${match.team2.idB != null ? 'doubles' : ''}`}>
					<SlamtrackerChallenges
						data={{
							available: match.team2.challengesAvailable,
							used: match.team2.challengesUsed,
						}}
					/>
				</div>
			</div>
		</section>
	);
};

export default SlamtrackerScoreboard;

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';
import { getQuerystringValues } from 'appdir/components/general/Util';

export const getParams = (pdeType, stateObj) => {
	let date = moment()
		.tz('Europe/London')
		.format('YYYY-MM-DD');
	let search = document.location.search.replace(/^\?/, '');
	let parsedQs = getQuerystringValues(search);
	if (parsedQs['cateringDate']) {
		date = parsedQs['cateringDate'];
	}

	let params = {};
	switch (pdeType) {
		case 'stringing':
			break;
		case 'transport':
			let playerCred = stateObj.currentUser.roles.find(ele => ele.key === 'player');
			if (playerCred) {
				let accreditorId = playerCred['contactDetails']['wacsURN'];
				params['accreditorId'] = accreditorId;
			}
			break;
		case 'catering':
			let playerCreds = stateObj.currentUser.roles.find(ele => ele.key === 'player');
			if (playerCreds) {
				let playerContactDetails = op.get(playerCreds, 'contactDetails', {});
				if (Object.keys(playerContactDetails).length > 0) {
					let barcode = playerContactDetails['barcode'];
					params['barcode'] = barcode;
					// let date = '2018-07-10';
					params['date'] = date;
				}
			}
			break;
		case 'prize-money':
			break;
		default:
			break;
	}

	return params;
};

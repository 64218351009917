import React from 'react';
import Button from 'appdir/components/common-ui/Button';



const RTW = ({ data }) => {


	return (
		<div class="content-main column-layout news article ">
			
			
			<div class="four-col">
				<iframe src='https://portlet.wingfield.app/' height='700' width='100%' allowFullScreen style={{'border': 0}}>
					<p>Your browser does not support iframes.</p>
				</iframe>
			</div>
		</div>
	);
};

export default RTW;

import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.GALLERYPAGE_MOUNT:
			newState = {
				...state,
				status: moment().toISOString(),
				galleryAPIPath: action.data,
			};
			return newState;
			break;
		case deps.actionTypes.GALLERYPAGE_UNMOUNT:
			newState = {
				...state,
				galleryAPIPath: null,
			};
			return newState;
			break;
		default:
			return state;
	}
};

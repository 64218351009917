import deps from 'dependencies';

export default (state = {}, action) => {
    let newState;

    switch (action.type) {
        case deps.actionTypes.FOOTER_MOUNT:
            newState = {
                ...state,
                ...action.data,
            };
            return newState;
            break;
        default:
            return state;
    }
};

import deps from 'dependencies';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[PlayerDigitalExperience] action.mount - params:%o store:%o', params, Config);

			let data = {
				pde: {
					...params,
				},
			};
			// logger.log('[PlayerDigitalExperience] action.mount - data:%o', data);

			let path = '';
			let roles = [];
			switch (data.pde.page) {
				case 'prize-money':
					path = [Config.pde.api.prizemoney, Config.pde.api.bankdetail];
					roles = [1000050];
					break;
				case 'stringing':
					path = Config.pde.api.stringTransaction;
					roles = [1000050];
					break;
				case 'delegates':
					path = Config.pde.api.delegates;
					roles = [1000050];
					break;
				case 'catering':
					path = Config.pde.api.foodStatement;
					// path = '/en_GB/json/man/pdedash/cateringTest.json';
					roles = [1000050];
					break;
				case 'transport':
					path = Config.pde.api.transport;
					roles = [1000050];
					break;
				case 'delegate-select':
					path = Config.pde.api.delegateSelect;
					roles = [1000050];
					break;
				case 'match-stats':
					roles = [1000050, 1000320];
					break;
				default:
					break;
			}

			logger.log('[PlayerDigitalExperience] action.mount - data: %o', data);

			data.dataUrl = path;
			data.dataMethod = 'get';
			data.roleIds = roles;
			data.screen = screen = {
				register: false,
				social: false,
			};
			data.category = 's_player';
			logger.log('[PlayerDigitalExperience] action.mount - data: %o', data);
			// dispatch({ type: deps.actionTypes.CONTENT_LOAD_SECURE, data: data });

			dispatch({
				type: deps.actionTypes.PLAYERDIGITALEXPERIENCE_MOUNT,
				data: data,
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.PLAYERDIGITALEXPERIENCE_UNMOUNT });
	},

	setPlayer: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.PLAYERDIGITALEXPERIENCE_SET_PLAYER, data: data });
	},
};

import React from 'react';
import StatusDevice from './StatusDevice';

const CourtBoxHeader = ({ court, courtKey }) => {
	console.log(court);
	const tempUnavailable =
		court?.courts?.findIndex(court => court?.status === 'Temporarily Unavailable') === -1 ? false : true;

	return (
		<div
			className={`court-availability__court-box-header${
				court?.courtsAvailable === 0 ? '--active' : ''
			} ${tempUnavailable && 'temp-unavailable'}`}
			id={courtKey}>
			<div>
				<p className="court-availability__court-box-header--name">{court?.name}</p>
				<p className="court-availability__court-box-header--count">
					{court?.courtsAvailable === 0 ? 'All courts closed' : `${court?.courtsAvailable} courts available`}
				</p>
			</div>
			<div>
				<StatusDevice court={court} />
			</div>
		</div>
	);
};

export default CourtBoxHeader;

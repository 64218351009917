import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import Template from 'appdir/components/Template';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsArchiveCountryAbbreviations'], props);
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.DrawsArchiveCountryAbbreviations.mount()),
});

class DrawsArchiveCountryAbbreviations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
		};

		logger.log('[DrawsArchiveCountryAbbreviations] props:%o', props);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log(
			'[DrawsArchiveCountryAbbreviations] componentWillReceiveProps - state:%o, nextProps:%o',
			this.state,
			nextProps
		);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		logger.log('[DrawsArchiveCountryAbbreviations] componentDidUpdate - state:%o', this.state);

		if (!this.state.statsData && this.state.statsDataPath) {
			let dataPath = this.state.statsDataPath;
			deps.services.DrawsArchiveCountryAbbreviations.fetch(dataPath).then(result => {
				this.setState({
					statsData: result.historical,
				});
			});
		}
	}

	render() {
		logger.log('[DrawsArchiveCountryAbbreviations] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'drawsArchive',
			title: 'Draws Archive',
			imageSrc: '/assets/images/headers/drawsArchive_topstats.jpg',
			metaTitle: 'Draws Archive',
			metaDescription: 'Player profile archive information for The Championships, Wimbledon.',
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template className="drawsarchive">
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />
				<div className="drawsArchiveHdrContent">
					<h1>Country Abbreviations</h1>
					<ArchiveMenu location={this.state.location.pathname} />
				</div>

				<div className="content-main">
					<div className="col-4">
						<div className="draws-archive-stats-table">
							<div className="datatable">
								<div className="row">
									<div className="cell">ANH Netherlands Antilles</div>
									<div className="cell">ARG Argentina</div>
									<div className="cell">AUS Australia</div>
								</div>
								<div className="row">
									<div className="cell">AUT Austria</div>
									<div className="cell">BAH Bahamas</div>
									<div className="cell">BAR Barbados</div>
								</div>
								<div className="row">
									<div className="cell">BEL Belgium</div>
									<div className="cell">BIH BosniaHerzegovina</div>
									<div className="cell">BLR Belarus</div>
								</div>
								<div className="row">
									<div className="cell">BOL Bolivia</div>
									<div className="cell">BOM Bohemia Moravia</div>
									<div className="cell">BRA Brazil</div>
								</div>
								<div className="row">
									<div className="cell">BRI British Isles</div>
									<div className="cell">BUL Bulgaria</div>
									<div className="cell">CAN Canada</div>
								</div>
								<div className="row">
									<div className="cell">CEY Ceylon</div>
									<div className="cell">CHI Chile</div>
									<div className="cell">CHN China, People's Republic of</div>
								</div>
								<div className="row">
									<div className="cell">CRO Croatia</div>
									<div className="cell">CUB Cuba</div>
									<div className="cell">CYP Cyprus</div>
								</div>
								<div className="row">
									<div className="cell">CZE Czech Republic</div>
									<div className="cell">DEN Denmark</div>
									<div className="cell">ECU Ecuador</div>
								</div>
								<div className="row">
									<div className="cell">ELA El Salvador</div>
									<div className="cell">EGY Egypt</div>
									<div className="cell">ESP Spain</div>
								</div>
								<div className="row">
									<div className="cell">EST Estonia</div>
									<div className="cell">FIN Finland</div>
									<div className="cell">FRA France</div>
								</div>
								<div className="row">
									<div className="cell">GEO Georgia</div>
									<div className="cell">GBR Great Britain</div>
									<div className="cell">GER Germany (incl. Federal Republic 1946-1990)</div>
								</div>
								<div className="row">
									<div className="cell">GRE Greece</div>
									<div className="cell">HAI Haiti</div>
									<div className="cell">HKG Hong Kong China</div>
								</div>
								<div className="row">
									<div className="cell">HUN Hungary</div>
									<div className="cell">IND India</div>
									<div className="cell">INA Indonesia</div>
								</div>
								<div className="row">
									<div className="cell">IRI Iran</div>
									<div className="cell">IRL Ireland</div>
									<div className="cell">ISR Israel </div>
								</div>
								<div className="row">
									<div className="cell">ITA Italy</div>
									<div className="cell">JAM Jamaica</div>
									<div className="cell">JAP Japan</div>
								</div>

								<div className="row">
									<div className="cell">KAZ Kazakhstan</div>
									<div className="cell">KEN Kenya</div>
									<div className="cell">KZG Kyrgystan</div>
								</div>
								<div className="row">
									<div className="cell">KOR Republic of Korea, South</div>
									<div className="cell">LAT Latvia</div>
									<div className="cell">LEB Lebanon</div>
								</div>
								<div className="row">
									<div className="cell">LIT Lithuania</div>
									<div className="cell">LUX Luxembourg</div>
									<div className="cell">MAD Madagascar</div>
								</div>
								<div className="row">
									<div className="cell">MEX Mexico</div>
									<div className="cell">MKD Macedonia</div>
									<div className="cell">MNE Montenegro</div>
								</div>
								<div className="row">
									<div className="cell">NED Netherlands</div>
									<div className="cell">NZL New Zealand</div>
									<div className="cell">NOR Norway</div>
								</div>
								<div className="row">
									<div className="cell">PAK Pakistan</div>
									<div className="cell">PHI Philippines</div>
									<div className="cell">POL Poland</div>
								</div>
								<div className="row">
									<div className="cell">POR Portugal</div>
									<div className="cell">PUR Puerto Rico</div>
									<div className="cell">RHO Rhodesia</div>
								</div>
								<div className="row">
									<div className="cell">ROM Romania</div>
									<div className="cell">CIS Commonwealth of Independent States</div>
									<div className="cell">CMR Cameroon</div>
								</div>
								<div className="row">
									<div className="cell">COL Colombia</div>
									<div className="cell">RSA Republic of South Africa</div>
									<div className="cell">RUS Russia</div>
								</div>
								<div className="row">
									<div className="cell">SER Serbia</div>
									<div className="cell">SLO Slovenia</div>
									<div className="cell">SMR San Marino</div>
								</div>
								<div className="row">
									<div className="cell">SRH Southern Rhodesia</div>
									<div className="cell">STA Stateless</div>
									<div className="cell">SUI Switzerland</div>
								</div>
								<div className="row">
									<div className="cell">SVK Slovak Republic</div>
									<div className="cell">SWE Sweden</div>
									<div className="cell">TCH Czechoslovakia</div>
								</div>
								<div className="row">
									<div className="cell">THA Thailand</div>
									<div className="cell">TPE Chinese Taipei</div>
									<div className="cell">TUN Tunisia</div>
								</div>
								<div className="row">
									<div className="cell">TUR Turkey</div>
									<div className="cell">UKR Ukraine</div>
									<div className="cell">URS Union of Soviet Socialist Republics</div>
								</div>
								<div className="row">
									<div className="cell">USA United States of America</div>
									<div className="cell">URU Uruguay</div>
									<div className="cell">UZB Uzbekistan</div>
								</div>
								<div className="row">
									<div className="cell">VEN Venezuela</div>
									<div className="cell">YUG Yugoslavia</div>
									<div className="cell">ZIM Zimbabwe</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</Template>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveCountryAbbreviations);

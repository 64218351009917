/* NOTE: code for table comes from: https://react-table-v7.tanstack.com/docs/examples/kitchen-sink */

import React from 'react';
import { useTable, useSortBy, useFilters, useGroupBy, useRowSelect } from 'react-table';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

// Create cell renderer
const FormattedCell = ({ value: value, row: row, column: column, error: error, email: email }) => {
	// format last updated date
	if (column?.Header === 'Last Updated' && row?.original?.status?.date) {
		let formatDateVal = moment(row?.original?.status?.date);
		return moment(formatDateVal).format('ddd DD MMM YYYY');
	}

	let tixFailed = [];
	// filter error
	if (error?.data?.response?.failure?.length) {
		tixFailed = error?.data?.response?.failure.filter(errVal => {
			return errVal.order?.ticketId === row?.original?.source?.id;
		});
	}

	if (column?.Header === 'Status' && tixFailed?.length > 0) {
		return (
			<div className="tix-action-err-cntr">
				<img className="tix-action-error-img" src="/assets/images/tickets/errorActionIcon.svg" />
				<b>Error transferring to </b>
				<br></br>
				{email}
			</div>
		);
	}

	// format ticket date and day
	if (column?.Header === 'Date') {
		if (row?.original?.day !== 'QUALS' && row?.original?.day !== 'UGP') {
			return (
				<span style={{ padding: '0px' }}>
					{row?.original?.date} <br></br>
					Day {row?.original?.day}
				</span>
			);
		} else {
			return null;
		}
	}

	return value || null;
};

function Table({ columns, data, error, email }) {
	const defaultColumn = React.useMemo(
		() => ({
			// set up default cell
			Cell: FormattedCell,
		}),
		[]
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		// state: { selectedRowIds, filters },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			disableMultiSort: true,
			error,
			email,
		},
		useFilters,
		useGroupBy,
		useSortBy,
		useRowSelect,
		// Here we will use a plugin to add our selection column
		hooks => {
			hooks.visibleColumns.push(columns => {
				return [
					{
						id: 'selection',
					},
					...columns,
				];
			});
		}
	);

	// Render the UI for your table
	return (
		<div className="tix-mgmt-list-table">
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>
									<div>
										<span {...column.getSortByToggleProps()}>{column.render('Header')}</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, index) => {
						prepareRow(row);
						const ariaLabel = `ticket row ${index + 1} out of ${rows.length} for: court ${
							row?.values?.['detailedInformation.court']
						}, gangway ${row?.values['detailedInformation.gangway']}, row ${
							row?.values['detailedInformation.row']
						}, seat ${row?.values['detailedInformation.seat']}, status ${
							row?.values['status.filtervalue']
						}, date ${row?.values?.date}`;

						return (
							<tr {...row.getRowProps()} tabIndex={0} aria-label={ariaLabel}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

// NOTE: COMPONENT STARTS HERE
function TicketManagementMultipleStubs(props) {
	const columns = React.useMemo(
		() => [
			{
				Header: 'Court',
				accessor: 'detailedInformation.court',
			},
			{
				Header: 'Date',
				accessor: 'date',
			},
			{
				Header: 'Gangway',
				accessor: 'detailedInformation.gangway',
			},
			{
				Header: 'Row',
				accessor: 'detailedInformation.row',
			},
			{
				Header: 'Seat',
				accessor: 'detailedInformation.seat',
			},
			{
				Header: 'Status',
				accessor: 'status.filtervalue',
			},
			{
				Header: 'Type',
				accessor: 'typeLabel',
			},
			{
				Header: 'Last Updated',
				accessor: 'status.date',
				aggregate: 'count',
			},
		],
		[]
	);

	return <Table columns={columns} data={props.data} error={props.error} email={props.email} />;
}

export default TicketManagementMultipleStubs;

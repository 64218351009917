/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
// import { connect } from 'react-redux';
// import deps from 'dependencies';
import op from 'object-path';
import { MatchArchiveContext } from '../context';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import PointNav from './PointNav';
import ScoreDisplay from './ScoreDisplay';
// import MatchTitle from './MatchTitle';
// import Carousel from 'appdir/components/common-ui/Carousel';
import { CarouselArrow } from './CarouselArrow';
import Carousel from '@brainhubeu/react-carousel';
import ReplayType from './ReplayType';
import MeasurementUtils from 'appdir/lib/analytics';
import Measure from 'react-measure';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchVideo
 * -----------------------------------------------------------------------------
 */

class MatchVideo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 0,
			loadVideo: false,
			seek: 0,
			shouldSeek: false,
			videoDimensions: {
				width: -1,
				height: -1,
			},
			videoBottomDimensions: {
				width: -1,
				height: -1,
			},
			...props,
		};

		this.player = null;
		this.selectedSlide = 0;
		this.heartbeatInterval;

		this.onPointChange = this.onPointChange.bind(this);
		this.carouselItem = this.carouselItem.bind(this);
		this.carouselChange = this.carouselChange.bind(this);
		this.onResizeDiv = this.onResizeDiv.bind(this);
		this.heartbeat = this.heartbeat.bind(this);

		logger.log('[MatchVideo] constructor - context:%o', this.context);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = null;

		if (nextProps.mode != prevState.mode) {
			newState = {
				mode: nextProps.mode,
			};
		}

		return newState;
	}

	componentDidMount() {
		//minimum of 10 seconds for interval
		if (this.state.heartbeatInterval >= 10) {
			logger.log('[MatchVideo] componentDidMount - heartbeatSec:%o', this.state.heartbeatInterval);
			this.heartbeatInterval = setInterval(this.heartbeat, this.state.heartbeatInterval * 1000);
		}
	}

	componentWillUnmount() {
		clearInterval(this.heartbeatInterval);
		this.heartbeatInterval = null;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MatchVideo] componentDidUpdate - context:%o state:%o', this.context, this.state);
		if (op.get(this.props, 'currentMatch.id', null) != op.get(prevProps, 'currentMatch.id', null)) {
			this.setState({
				time: 0,
				totalPlayed: 0,
			});
		}

		if (prevState.shouldSeek !== this.state.shouldSeek && this.state.shouldSeek) {
			logger.log('[MatchVideo] componentDidUpdate - start playing at seek:%o', this.state.seek);

			this.player.seek(this.state.seek);
			this.setState({
				shouldSeek: false,
				loadVideo: false,
				seek: 0,
			});
		}
	}

	heartbeat() {
		let time = this.state.totalPlayed ? this.state.totalPlayed : 0;
		//logger.info('[MatchVideo] heartbeat - totalTime:%o', time);

		MeasurementUtils.dispatchMeasurementCall('Match Archive Detail', {
			heartbeat_seconds: this.state.heartbeatInterval,
			cumulative_seconds: time,
		});
	}

	onResizeDiv(contentRect, id) {
		this.setState({
			[id]: contentRect.bounds,
		});
	}

	onPlayerReady(player) {
		logger.info('[MatchVideo] onPlayerReady - player:%o', player);
		this.player = player;
	}

	onVideoEvent(event) {
		logger.info('[MatchVideo] onVideoEvent - event:%o', event);
		if (event.type == 'onTimeUpdate') {
			//logger.info('[MatchVideo] onVideoEvent - setting shouldSeek:%o', this.state.loadVideo && this.state.seek);
			this.setState({
				time: event.data.time,
				shouldSeek: this.state.loadVideo && this.state.seek,
				totalPlayed: event.data.played,
			});
		}
	}

	onUserAction(data) {
		//logger.info('[MatchVideo] onUserAction - data:%o', data);
		MeasurementUtils.dispatchMeasurementCall('Match Archive Detail', {
			data: ['Play VOD', data.action],
		});
	}

	/**
	 * When selecting a point, set seek time at start of point
	 *   unless the point is a serveline, then seek to 5 sec before the end
	 *   helps insure see start of serve and not a lot of "switch" time between games/sets/servers
	 */
	pointSelect(point) {
		logger.log('[MatchVideo] pointSelect: %o', point);
		if (!this.player) {
			logger.log('[MatchVideo] pointSelect pointSelected before video loaded');

			this.setState({
				loadVideo: true,
				seek: !point.serveline ? point.clipStart : point.clipEnd - 5,
			});
		} else {
			this.player.seek(!point.serveline ? point.clipStart : point.clipEnd - 5);
		}
	}

	onPointChange(point) {
		logger.log('[MatchVideo] onPointChange: %o', point);
		this.setState({
			currentPoint: point,
		});
	}

	carouselItem(index, credit, quote) {
		return (
			<div className="quote-wrapper" key={`carousel-item-${index}`} id={`carousel-item-${index}`}>
				<div className="credit">{credit}</div>
				<div className="quote">{quote}</div>
			</div>
		);
	}

	renderNav(direction, clickFn) {
		return (
			<div
				onClick={() => {
					clickFn(direction);
				}}
				className={`arrow-wrapper-${direction}`}>
				<i className={`wim-icon-${direction}-arrow`} />
			</div>
		);
	}

	carouselChange(value) {
		let measureDirection;
		if (value > this.selectedSlide) {
			measureDirection = 'next'; //right
		} else {
			measureDirection = 'previous'; //left
		}
		this.selectedSlide = value;
		MeasurementUtils.dispatchMeasurementCall('Match Archive Detail', {
			data: ['Detail', 'Quote Carousel Navigation', measureDirection],
		});
	}

	render() {
		logger.log('[MatchVideo] render - state:%o', this.state);
		logger.log('[MatchVideo] render - props:%o', this.props);

		//logger.log('[MatchVideo] render - match:%o', this.props.currentMatch);
		logger.log('[MatchVideo] render - currentPoint:%o', this.state.currentPoint);
		//logger.log('[MatchVideo] render - props:%o points:%o', this.props, this.props.currentMatch.points);

		//make sure have points to display in PointNav

		let contextValues = {
			overview: op.get(this.props.currentMatch, 'cmsDetail.overview'),
			currentMatch: {
				event: this.props.currentMatch.event,
				players: this.props.currentMatch.players,
				year: this.props.currentMatch.year,
			},
			windowSize: this.props.windowSize,
		};

		let currId = null;
		if (this.props.currentMatch.points) {
			currId = this.props.currentMatch.id;
		}

		let navJSON = {
			leftNav: this.renderNav.bind(this),
			rightNav: this.renderNav.bind(this),
		};

		//set display point
		let displayPoint = null;
		if (this.state.mode == 'sync') {
			displayPoint = this.state.currentPoint;
		}
		if (this.state.mode == 'result') {
			[displayPoint] = op.get(this.state, 'currentMatch.points', []).slice(-1);
		}

		if (this.props.currentMatch && this.props.currentMatch.cmsDetail) {
			let attributes_video = {
				playerId: 'archive',
				cmsId: 'archive_' + this.props.currentMatch.id,
				contentId: null,
				//videoUrl: this.state.video.wimchan.media.vod.url,
				videoUrl: this.props.currentMatch.cmsDetail.video.media.m3u8,
				title: this.props.currentMatch.cmsDetail.title,
				thumb: this.props.currentMatch.cmsDetail.video.images.thumb.large,
				autoplay: false,
				style: 'video-wrapper one-col archive',
				aspect: 'wide',
				shareUrl: this.props.shareUrl,
				streamType: 'vod',
				loadVideo: this.state.loadVideo,
			};

			let contentItems = this.props.currentMatch.cmsDetail.quotes.map((item, index) => {
				return this.carouselItem(index, item.credit, item.text);
			});

			logger.log('[MatchVideo] render - contentItems:%o', contentItems);

			return (
				<Fragment>
					{/* <div className="six-col">
						<MatchTitle
							mode={this.state.mode}
							currentMatch={this.props.currentMatch}
							scoringConfig={this.state.scoringConfig}
						/>
					</div> */}
					<Measure
						bounds
						onResize={contentRect => {
							this.onResizeDiv(contentRect, 'videoDimensions');
						}}>
						{({ measureRef }) => (
							<div ref={measureRef} className="four-col video-wrapper-archive">
								{this.props.currentMatch.cmsDetail.video.media.m3u8 ? (
									<VideoWrapper
										attributes={attributes_video}
										onReady={player => {
											this.onPlayerReady(player);
										}}
										onVideoEvent={event => {
											this.onVideoEvent(event);
										}}
										onUserAction={data => {
											this.onUserAction(data);
										}}
									/>
								) : (
									<div className="novideo-message">No video defined</div>
								)}
								<ReplayType mode={this.state.mode} />
								<Measure
									bounds
									onResize={contentRect => {
										this.onResizeDiv(contentRect, 'videoBottomDimensions');
									}}>
									{({ measureRef }) => (
										<div ref={measureRef} id="videoBottom" className="video-bottom">
											<div className="three-col">
												<ScoreDisplay
													currentMatch={this.state.currentMatch}
													currentPoint={displayPoint}
													scoringConfig={this.state.scoringConfig}
													isArchive={true}
												/>
											</div>

											<div className="three-col quote-carousel">
												<Carousel
													// itemWidth={224}
													infinite
													dots
													onChange={this.carouselChange}
													arrowLeft={
														<CarouselArrow
															name="wim-icon-left-arrow"
															class="matchnav-arrow prev-button"
														/>
													}
													arrowRight={
														<CarouselArrow
															name="wim-icon-right-arrow"
															class="matchnav-arrow next-button"
														/>
													}
													arrowLeftDisabled={
														<CarouselArrow
															name="wim-icon-left-arrow"
															class="matchnav-arrow prev-button disabled"
														/>
													}
													arrowRightDisabled={
														<CarouselArrow
															name="wim-icon-right-arrow"
															class="matchnav-arrow prev-button disabled"
														/>
													}
													addArrowClickHandler>
													{contentItems}
												</Carousel>
											</div>
										</div>
									)}
								</Measure>
								{this.props.windowSize != 'desktop' && (
									<div className="two-col pointnav-wrapper">
										<MatchArchiveContext.Provider value={contextValues}>
											<PointNav
												open={true}
												currentTime={this.state.time}
												matchId={currId}
												pointsArray={this.props.currentMatch.points}
												mode={this.state.mode}
												onSelect={point => {
													this.pointSelect(point);
												}}
												onPointChange={point => {
													this.onPointChange(point);
												}}
												matchVideoHeight={null}
											/>
										</MatchArchiveContext.Provider>
									</div>
								)}
							</div>
						)}
					</Measure>
					{this.props.windowSize == 'desktop' && (
						<div className="two-col pointnav-wrapper">
							<MatchArchiveContext.Provider value={contextValues}>
								<PointNav
									open={true}
									currentTime={this.state.time}
									matchId={currId}
									pointsArray={this.props.currentMatch.points}
									mode={this.state.mode}
									onSelect={point => {
										this.pointSelect(point);
									}}
									onPointChange={point => {
										this.onPointChange(point);
									}}
									matchVideoHeight={
										this.state.videoBottomDimensions.bottom - this.state.videoDimensions.top
									}
								/>
							</MatchArchiveContext.Provider>
						</div>
					)}
				</Fragment>
			);
		} else {
			return (
				<div className="column-layout">
					<div className="four-col video-wrapper-archive load">
						<div className="video-wrapper loading-bg"></div>
						<LoadingIndicator type={'white'} />
					</div>
				</div>
			);
		}
	}
}

MatchVideo.contextType = MatchArchiveContext;
export default MatchVideo;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import uniq from 'lodash/uniq';
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...props,
		favourites: state['Controller']['favourites'],
		enableFavourite: op.get(state, 'Config.myWimbledon.favorites.enableContentFavoriting', false),
		loggedIn: state['Gigya']['loggedIn'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	update: data => dispatch(deps.actions.Controller.updateImmediate(data)),
});

class FavouriteStar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};
		this.updateFavourites = this.updateFavourites.bind(this);
		this.isFavourited = this.isFavourited.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	isFavourited(id, type) {
		let contentType = type;
		let favArry = {
			...this.state.favourites,
		};

		favArry[contentType] = op.get(this.state, `favourites.${contentType}`, []);

		if (favArry[contentType].indexOf(id) !== -1) {
			return true;
		} else {
			return false;
		}
	}

	updateFavourites(id, type) {
		let contentType = type;
		let updatedFav = {};
		let favArry = {
			...this.state.favourites,
		};

		favArry[contentType] = op.get(JSON.parse(JSON.stringify(this.state)), `favourites.${contentType}`, []);

		if (favArry[contentType].indexOf(id) !== -1) {
			const contentIndex = favArry[contentType].indexOf(id);
			favArry[contentType].splice(contentIndex, 1);
			this.measureCall('Favorite Remove', id);
		} else {
			favArry[contentType].push(id);
			this.measureCall('Favorite Add', id);
		}
		favArry[contentType] = favArry[contentType].filter(d => d !== null || d !== '');
		favArry[contentType] = uniq(favArry[contentType]);
		updatedFav = favArry;
		this.state.update({ favourites: { ...updatedFav } });
	}

	measureCall(action, id) {
		MeasurementUtils.dispatchMeasurementCall(action, { val: id });
	}

	render() {
		return (this.state.loggedIn && this.state.enableFavourite) || this.state.alwaysShow ? (
			<span
				onClick={() => {
					this.updateFavourites(this.state.id, this.state.type);
				}}
				onKeyPress={() =>
					event.key === 'Enter' ? this.updateFavourites(this.state.id, this.state.type) : null
				}
				tabIndex={0}
				className={`favorite ${
					this.isFavourited(this.state.id, this.state.type)
						? 'favorited ' + this.state.favColor
						: this.state.unFavColor
				}`}></span>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteStar);

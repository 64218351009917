import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.DRAWSARCHIVE_TOP_STATS_LOAD:
			filters = {
				...state.filters,
			};

			newState = {
				...state,
				statsDataPath: action.data.statsDataPath,
				...action.data,
				filters: filters,
			};
			logger.log('[DrawsArchiveTopStats] reducer - DRAWSARCHIVE_TOP_STATS_LOAD: %o', newState);
			return newState;
			break;

		case deps.actionTypes.DRAWSARCHIVE_TOP_STATS_FILTER:
			logger.log('[DrawsArchiveTopStats] DRAWSARCHIVE_TOP_STATS_FILTER - data:%o', action.data);

			let filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};

			logger.log('[DrawsArchiveTopStats] DRAWSARCHIVE_TOP_STATS_FILTER - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getQuerystringValues } from 'appdir/components/general/Util';

//initialize logger as not enabled
global.logEnabled = false;
global.startTime = moment();

let search = document.location.search.replace(/^\?/, '');
let logging = search.indexOf('logs=true') > -1;
let logOnly = search.indexOf('logOnly=') > -1;
let logQuery = "";
if(logOnly){
	let parsedQs = getQuerystringValues();
	logQuery = parsedQs.logOnly;
}

//console.log('logging qs: ' + logging);

if (process.env.NODE_ENV == 'development' || logging || window.level == '1') {
	global.logEnabled = true;
}

if(search.indexOf('logs=false') > -1)
	global.logEnabled = false;

console.log('logging node_env: ' + process.env.NODE_ENV);

export const Logger = {
	table: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			//let time = moment().diff(startTime, 'seconds', true);
			args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
			console.log(args[0]);
			console.table(args[1], args[2]);
		}
	},

	log: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			//let time = moment().diff(startTime, 'seconds', true);
			if(logOnly && args[0].toLowerCase().indexOf(logQuery) > -1){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.log(...args);
			}else if(!logOnly){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.log(...args);
			}
		}
	},

	info: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			//let time = moment().diff(startTime, 'seconds', true);
			if(logOnly && args[0].toLowerCase().indexOf(logQuery) > -1){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.info(...args);
			}else if(!logOnly){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.info(...args);
			}
		}
	},

	warn: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			if(logOnly && args[0].toLowerCase().indexOf(logQuery) > -1){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.warn(...args);
			}else if(!logOnly){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.warn(...args);
			}
		}
	},

	error: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			if(logOnly && args[0].toLowerCase().indexOf(logQuery) > -1){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.error(...args);
			}else if(!logOnly){
				args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
				console.error(...args);
			}
		}
	},

	count: label => {
		if (logEnabled === true || logEnabled === 'true') {
			console.count(label);
		}
	},
};

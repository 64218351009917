import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['Results'], props);
};

const mapDispatchToProps = (dispatch, props) => ({});

class Results extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}

	render() {
		logger.log('[Results] :', this.state.data.playerSearchResults);
		let flagImage;
		let flagNat;

		return (
			<ul className="results" role="contentinfo">
				{this.state.data.playerSearchResults.length > 0 ? (
					this.state.data.playerSearchResults.map((item, index) => {
						flagNat = item.nat.trim();
						flagImage = this.state.data.flagImagePath.replace('<code>', flagNat);
						//hide flag for now, Oct 2022
						flagNat = null;

						// if no country do not display - amb
						// why not? - jda
						return (
							<li key={item.id}>
								<Link to={`/en_GB/draws_archive/player_profile/${item.id}.html`}>
									{flagNat ? (
										<span className="flags">
											<img src={flagImage} />
										</span>
									) : null}
									{item.name}
								</Link>
							</li>
						);
					})
				) : (
					<div>No Results</div>
				)}
			</ul>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Results);

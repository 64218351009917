import React from 'react';
import BirthYear from 'appdir/components/general/Gigya/screens/elements/BirthYear';
import Country from 'appdir/components/general/Gigya/screens/elements/Country';

const EditProfile = ({ data }) => {
	let mobileStyle = 'gigya-screen v2 gigya-update-profile-screen portrait mobile';
	let wideStyle = 'gigya-screen v2 gigya-update-profile-screen portrait';

	return (
		<div id="gigya-update-profile-screen" className={wideStyle} data-width="auto" data-caption="screenset.translations['GIGYA_UPDATE_PROFILE_SCREEN_2_CAPTION']">
			<form className="gigya-profile-form" data-on-success-screen="_finish" id="gigya-profile-form" _lpchecked="1">
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row" />
						<div className="gigya-layout-row" />
					</div>
					<div className="gigya-layout-cell">
						<div className="gigya-layout-row" />
						<div className="gigya-layout-row" />
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-textbox">
						<label className="gigya-label" htmlFor="gigya-textbox-lastName">
							<span className="gigya-label-text" data-translation-key="TEXTBOX_35887798661246190_LABEL" />
							<label className="gigya-required-display" data-bound-to="profile.lastName" style={{}}>
								*
							</label>
						</label>
						<input type="text" name="profile.lastName" id="gigya-textbox-lastName" className="gigya-input-text" tabIndex="0" data-gigya-type="text" />
						<span className="gigya-error-msg" data-bound-to="profile.lastName" />
					</div>
					<div className="gigya-composite-control gigya-composite-control-textbox">
						<label className="gigya-label" htmlFor="gigya-textbox-email">
							<span className="gigya-label-text" data-translation-key="TEXTBOX_97514499177413920_LABEL" />
							<label className="gigya-required-display" data-bound-to="email" style={{}}>
								*
							</label>
						</label>
						<input
							type="text"
							name="profile.email"
							id="gigya-textbox-email"
							className="gigya-input-text"
							formNoValidate="formnovalidate"
							tabIndex="0"
							data-gigya-type="text"
						/>
						<span className="gigya-error-msg" data-bound-to="email" />
					</div>
					<div className="gigya-composite-control gigya-composite-control-textbox">
						<label className="gigya-label" htmlFor="gigya-textbox-firstName">
							<span className="gigya-label-text" data-translation-key="TEXTBOX_7736986726932801_LABEL" />
							<label className="gigya-required-display" data-bound-to="profile.firstName" style={{}}>
								*
							</label>
						</label>
						<input type="text" name="profile.firstName" id="gigya-textbox-firstName" className="gigya-input-text" tabIndex="0" data-gigya-type="text" />
						<span className="gigya-error-msg" data-bound-to="profile.firstName" />
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-dropdown">
						<label className="gigya-label" htmlFor="edit.gigya-dropdown-birthYear">
							<span className="gigya-label-text" data-translation-key="DROPDOWN_27897089514179760_LABEL" />
							<label className="gigya-required-display" data-bound-to="birthYear" style={{}}>
								*
							</label>
						</label>
						<BirthYear id="edit" tabIndex="6" />
						<span className="gigya-error-msg" data-bound-to="profile.birthYear" />
					</div>
					<div className="gigya-composite-control gigya-composite-control-dropdown">
						<label className="gigya-label" htmlFor="edit.gigya-textbox-country">
							<span className="gigya-label-text" data-translation-key="DROPDOWN_10954929616950068_LABEL" />
							<label className="gigya-required-display" data-bound-to="profile.country" style={{}}>
								*
							</label>
						</label>
						<Country id="edit" tabIndex="7" />
						<span className="gigya-error-msg" data-bound-to="profile.country" />
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-textbox">
						<label className="gigya-label" htmlFor="edit.gigya-textbox-zip">
							<span className="gigya-label-text" data-translation-key="TEXTBOX_67010566505855360_LABEL" />
							<label className="gigya-required-display" data-bound-to="profile.zip" style={{}}>
								*
							</label>
						</label>
						<input type="text" name="profile.zip" id="edit.gigya-textbox-zip" className="gigya-input-text" tabIndex="0" />
						<span className="gigya-error-msg" data-bound-to="profile.zip" />
					</div>
				</div>
				<div className="gigya-layout-ro ui-sortablew" />
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell" />
					<div className="gigya-layout-cell" />
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell" />
					<div className="gigya-layout-cell" />
				</div>
				<div className="gigya-layout-row subscribe-wrapper">
					<div className="gigya-composite-control gigya-composite-control-checkbox">
						<input type="checkbox" className="gigya-input-checkbox" name="data.subscribe" id="edit.gigya-checkbox-subscribe" tabIndex="0" />
						<label className="gigya-label" htmlFor="edit.gigya-checkbox-subscribe">
							<span className="gigya-label-text gigya-checkbox-text" data-translation-key="CHECKBOX_36093561356460540_LABEL" />
							<label className="gigya-required-display" data-bound-to="data.subscribe" style={{ display: 'none' }}>
								*
							</label>
						</label>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-composite-control gigya-composite-control-submit">
						<input type="submit" className="gigya-input-submit" tabIndex="0" value="screenset.translations['SUBMIT_139002756140568320_VALUE']" />
					</div>
					<div className="gigya-error-display gigya-composite-control gigya-composite-control-form-error" data-bound-to="gigya-profile-form">
						<div className="gigya-error-msg gigya-form-error-msg" data-bound-to="gigya-profile-form" />
					</div>
					<div className="gigya-clear" />
				</div>
				<div className="gigya-layout-row">
					<div className="gigya-layout-cell">
						<div
							className="gigya-container gigya-visible-when"
							data-condition="accountInfo.socialProviders.indexOf('site') !== -1 &amp;&amp; tfaProviders.activeProviders.length > 0"
						>
							<a
								className="gigya-composite-control gigya-composite-control-link"
								data-switch-screen="gigya-change-password-screen"
								style={{ textAlign: 'left' }}
								data-translation-key="LINK_29009445734946590_LABEL"
							/>
						</div>
					</div>
					<div className="gigya-layout-cell">
						<div className="gigya-container gigya-visible-when" data-condition="tfaProviders.activeProviders.length > 0">
							<a
								className="gigya-composite-control gigya-composite-control-link"
								data-switch-screen="gigya-tfa-edit-screen"
								style={{ textAlign: 'right' }}
								data-translation-key="LINK_21074431753063724_LABEL"
							/>
						</div>
					</div>
				</div>
				<div className="gigya-layout-row">
					<div
						className="gigya-container gigya-visible-when"
						data-condition="accountInfo.socialProviders.indexOf('site') !== -1 &amp;&amp; tfaProviders.activeProviders.length == 0"
					>
						<a
							className="gigya-composite-control gigya-composite-control-link"
							data-switch-screen="gigya-change-password-screen"
							style={{ textAlign: 'center' }}
							data-translation-key="LINK_93937631890759840_LABEL"
						/>
					</div>
				</div>
				<div className="gigya-clear" />
			</form>
		</div>
	);
};

export default EditProfile;

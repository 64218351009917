import axios from 'axios';
import { logger } from '../../logger';
import { ScoringConnection } from '../scoringConnection';

let pollingProperties;
let firstPoll = true;
let filesToPoll = {};
let currentStatsMatches = [];

const validateProperites = connectionProperties => {
	if (connectionProperties.scoresPath == undefined) {
		logger.error('[pollingConnection] - Error, no scoresPath supplied.');
		throw 'scoresPath for connection is required';
	}
	return connectionProperties;
};

const executeScoresPoll = connectionProperties => {
	return axios
		.get(connectionProperties.scoresPath)
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			logger.log('[pollingConnection] fetch - error:%o', error);
			if (error.response && error.response.status == 404) {
				logger.log('[pollingConnection] fetch scores - error, file not found:%o', error.response);
				throw error.response;
			} else {
				logger.log('[pollingConnection] fetch scores - error, general http erorr:%o', error);
				throw error;
			}
		});
};

/**
 * loop through files we are polling e.g. stats and momentum files.
 * for momentum, the first time we loop through, get the c file, all other
 * polls will get the u file
 */
const executeMatchDataPoll = connectionProperties => {
	logger.log('[pollingConnection] filesToPoll:%o', filesToPoll);
	if (currentStatsMatches.length > 0) {
		if (filesToPoll.hasOwnProperty('statsPath')) {
			axios
				.get(filesToPoll['statsPath'])
				.then(response => {
					if (response.status === 200) {
						// logger.log('stats response: %o', response);
						connectionProperties.onStatsUpdate(response.data.matches[0]);
					}
				})
				.catch(error => {
					if (error.response && error.response.status == 404) {
						logger.log('[pollingConnection] fetch stats - error, file not found:%o', error.response);
						throw error.response;
					} else {
						logger.log('[pollingConnection] fetch stats - error, general http error:%o', error);
						throw error;
					}
				});
		}

		if (filesToPoll.hasOwnProperty('cPath') && filesToPoll.hasOwnProperty('uPath')) {
			if (firstPoll) {
				firstPoll = false;

				axios
					.get(filesToPoll['cPath'])
					.then(response => {
						if (response.status === 200) {
							// logger.log('cData response: %o', response);
							connectionProperties.onHistoryUpdate(response.data);
						}
					})
					.catch(error => {
						if (error.response && error.response.status == 404) {
							logger.log('[pollingConnection] fetch cPath - error, file not found:%o', error.response);
							throw error.response;
						} else {
							logger.log('[pollingConnection] fetch cPath - error, general http error:%o', error);
							throw error;
						}
					});
			} else {
				axios
					.get(filesToPoll['uPath'])
					.then(response => {
						if (response.status === 200) {
							// logger.log('uData response: %o', response);
							connectionProperties.onHistoryUpdate(response.data);
						}
					})
					.catch(error => {
						if (error.response && error.response.status == 404) {
							logger.log('[pollingConnection] fetch uPath - error, file not found:%o', error.response);
							throw error.response;
						} else {
							logger.log('[pollingConnection] fetch uPath - error, general http error:%o', error);
							throw error;
						}
					});
			}
		}
	}
};

export class PollingConnection extends ScoringConnection {
	constructor(connectionProperties) {
		super();
		pollingProperties = connectionProperties;
	}

	createNew(connectionProperties = {}) {
		pollingProperties = validateProperites(connectionProperties);

		logger.log('[PollingConnection] - calling connectionReady');
		pollingProperties.connectionReady(true);
	}

	start() {
		this.runPoll(true);
	}

	stop() {}

	addSlamtrackerForMatch(data) {
		logger.log('[pollingConnection] addSlamtrackerForMatch - data:%o', data);

		/**
		 * This module is called when we need to get stats for a match
		 *
		 * 1. Check that this.currentStatsMatches doesn't already have an active subscription for this match id
		 * 2. If not add the right file into the polling list
		 * 3. If it does, do nothing
		 */
		if (currentStatsMatches.indexOf(data.matchId) == -1) {
			currentStatsMatches.push(data);
			filesToPoll['statsPath'] = pollingProperties.statsPath.replace('<matchId>', data.matchId);

			if (data.statsLevel == 'K' || data.statsLevel == 'H') {
				filesToPoll['cPath'] = pollingProperties.cPath.replace('<matchId>', data.matchId);
				filesToPoll['uPath'] = pollingProperties.uPath.replace('<matchId>', data.matchId);
			}

			executeMatchDataPoll(pollingProperties);
		}
	}

	removeSlamtrackerForMatch(data) {
		logger.log('[pollingConnection] removeSlamtrackerForMatch - data:%o', data);

		/**
		 * This module is called when we need to stop calling stats data for a given match
		 *
		 * 1. Check that this.currentStatsMatches has an active subscription for this match id
		 * 2. If it does, remove the file from the polling list
		 * 3. If it doesn't, do nothing
		 */
		let foundMatches = currentStatsMatches.filter(match => {
			return match.matchId == data.matchId;
		});

		if (foundMatches.length == 1) {
			currentStatsMatches = currentStatsMatches.filter(match => {
				return match.matchId != data.matchId;
			});
			firstPoll = true;
		}
	}

	runPoll(isActive) {
		if (isActive) {
			executeScoresPoll(pollingProperties).then(data => {
				pollingProperties.onDataUpdate(data.matches);
			});
			executeMatchDataPoll(pollingProperties);
		}
	}
}

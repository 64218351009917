import React, { Component } from 'react';
import { values } from 'appdir/main';

import { Link } from 'react-router-dom';
import { getCleanLink, getAppLink } from 'appdir/components/general/Util';

import MeasurementUtils from 'appdir/lib/analytics';
import { doMeasurement } from 'appdir/components/general/Analytics';

class WimLink extends Component {
	constructor(props) {
		super(props);

		let link = {
			url: '',
			external: false,
		};

		this.keyPressPopup = this.keyPressPopup.bind(this);

		if (props.to) {
			if (values.webview) {
				/** pass bypassAppLink: true to go to webpage directly without forming appLink */
				let cleanLink = getCleanLink(props.to);
				if (!cleanLink.external) {
					link = getAppLink(
						cleanLink.url,
						props.title ? props.title : '',
						props.bypassAppLink ? props.bypassAppLink : false
					);
				} else {
					link = cleanLink;
				}
			} else if (!props.external) {
				link = getCleanLink(props.to);
				//logger.log('[WimLink] constructor - getCleanLink:%o', link);
			} else {
				link = {
					url: props.to,
					external: true,
				};
			}
		}

		if (link.external) {
			link.target = props.target ? props.target : '_blank';
		}

		this.state = {
			...props,
			link: link,
		};

		//logger.log('[WimLink] constructor - state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[WimLink] componentWillReceiveProps - name:%o next:%o', nextProps.name, nextProps);

		let link = {
			url: '',
			external: false,
		};

		if (nextProps.to) {
			if (values.webview) {
				link = getAppLink(
					nextProps.to,
					nextProps.title ? nextProps.title : '',
					nextProps.bypassAppLink ? nextProps.bypassAppLink : false
				);
			} else if (!nextProps.external) {
				link = getCleanLink(nextProps.to);
			} else {
				link = {
					url: nextProps.to,
					external: true,
				};
			}
		}

		if (link.external) {
			link.target = nextProps.target ? nextProps.target : '_blank';
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				link: link,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[WimLink] componentDidUpdate - name:%o state:%o', this.state.name, this.state);
	}

	componentDidMount() {
		//logger.log('[WimLink] componentDidMount - state:%o props:%o', this.state, this.props);
	}

	openWindow(url, measure) {
		if (this.props.popupWindow) {
			window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
			MeasurementUtils.dispatchMeasurementCall(this.state.measureType, {
				network: measure,
				content: {
					title: 'Official Wimbledon Social Account',
				},
			});
		} else {
			window.open(url, '', this.state.options);
			if (measure) {
				MeasurementUtils.dispatchMeasurementCall(measure);
			}
		}
	}

	onClick(args) {		
		if(args?.contextData){
			doMeasurement(args?.page, args?.measureAction || "click", [], args?.contextData, args?.metricType || "");
		}else{
			MeasurementUtils.dispatchMeasurementCall('wimLink', { val: args });
		}
	}

	keyPressPopup(e) {
		if (e.key === 'Enter' || e.keyCode === 13) {
			this.openWindow(this.state.link.url, this.state.measure);
		}
	}

	render() {
		// logger.log('[WimLink] render - this:%o', this.state);
		//logger.log('[WimLink] render - rend:%o', ( this.state.link && this.state.link.url && this.state.children));

		if (this.state.link && (this.state.link.url || this.state.link.onclick) && this.state.children) {

			//handle popups
			if (this.state.popup) {
				let openStyle = {
					cursor: 'pointer',
				};

				return (
					<span
						role="link"
						className={this.state.style ? this.state.style : ''}
						tabIndex={this.props.ariaHiddenElement ? -1 : 0}
						alt={this.props.title || this.state.link.url}
						aria-label={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						style={openStyle}
						onClick={() => this.openWindow(this.state.link.url, this.state.measure)}
						onKeyDown={e => this.keyPressPopup(e)}>
						{this.state.children}
					</span>
				);
			} 
			
			//if webview AND not external and not an appLink
			else if (values.webview && !this.state.link.external && !this.state.link.appLink) {
				return (
					<Link
						to={this.state.link.url}
						role={this.props.ariaHiddenElement ? 'none' : 'link'}
						tabIndex={this.props.ariaHiddenElement ? -1 : 0}
						title={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						alt={this.props.title || this.state.link.url}
						aria-label={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						className={this.state.style ? this.state.style : ''}
						target="_self">
						{this.state.children}
					</Link>
					// <a
					// 	title={
					// 		this.props.title && this.props.title === 'hideAria'
					// 			? ''
					// 			: `link to ${this.props.title || this.state.link.url}`
					// 	}
					// 	role={this.props.ariaHiddenElement ? 'none' : 'link'}
					// 	tabIndex={this.props.ariaHiddenElement ? -1 : 0}
					// 	alt={this.props.title || this.state.link.url}
					// 	aria-label={
					// 		this.props.title && this.props.title === 'hideAria'
					// 			? ''
					// 			: `link to ${this.props.title || this.state.link.url}`
					// 	}
					// 	href={this.state.link.url}
					// 	className={this.state.style ? this.state.style : ''}
					// 	target="_self">
					// 	{this.state.children}
					// </a>
				);
			} 

			//if webview AND not external and is an appLink
			else if (values.webview && !this.state.link.external && this.state.link.appLink) {
				return (
					<a
						title={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						role={this.props.ariaHiddenElement ? 'none' : 'link'}
						tabIndex={this.props.ariaHiddenElement ? -1 : 0}
						alt={this.props.title || this.state.link.url}
						aria-label={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						className={this.state.style ? this.state.style : ''}
						href={this.state.link?.url}
						onClick={() => this.props?.measure ? this.onClick(this.props.measure) : null }
						target="_self">
						{this.state.children}
					</a>
				);
			}

			//if external
			else if (this.state.link.external) {
				let url = this.state.link?.url;
				let className = this.state.style ? this.state.style : '';
				
				if (this.state.link.url.indexOf('.pdf') > -1) {
					className = '' ? (className = 'download') : className + ' download';
				} else {
					className = '' ? (className = 'external') : className + ' external';
				}

				//add ?exernal if external in a webview
				if (values.webview && !url.includes('external=true')) {
					url = url.includes('?') ? `${url}&external=true` : `${url}?external=true`;
				}

				if (this.state.link?.url !== '') {
					if (this.props.labelledBy) {
						return (
							<a
								role="link"
								tabIndex={0}
								className={className}
								href={this.state.link?.url}
								target={this.state.link.target}
								aria-labelledby={this.props.labelledBy}
							>
								{this.state.children}
							</a>
						);
					}
					return (
						<a
							title={
								this.props.title && this.props.title === 'hideAria'
									? ''
									: `link to ${this.props.title || this.state.link.url}`
							}
							role={this.props.ariaHiddenElement ? 'none' : 'link'}
							tabIndex={this.props.ariaHiddenElement ? -1 : 0}
							alt={this.props.title || this.state.link.url}
							aria-label={
								this.props.title && this.props.title === 'hideAria'
									? ''
									: `link to ${this.props.title || this.state.link.url}`
							}
							className={className}
							href={url}
							onClick={
								this.state.onClick ? this.state.onClick : null
							}
							target={this.state.link.target}>
							{this.state.children}
						</a>
					);
				} else if (this.state.link.onclick) {
					//logger.log('[WimLink] render - link.onclick:%o', this.state.link.onclick);
					return (
						<a
							title={
								this.props.title && this.props.title === 'hideAria'
									? ''
									: `link to ${this.props.title || this.state.link.url}`
							}
							role={this.props.ariaHiddenElement ? 'none' : 'link'}
							tabIndex={this.props.ariaHiddenElement ? -1 : 0}
							alt={this.props.title || this.state.link.url}
							aria-label={
								this.props.title && this.props.title === 'hideAria'
									? ''
									: `link to ${this.props.title || this.state.link.url}`
							}
							className={className}
							target={this.state.link.target}
							onClick={this.state.link.onclick}>
							{this.state.children}
						</a>
					);
				} else {
					return null;
				}
			}

			//not sure this is used anywhere?
			// included in SubNav but don't see onClick firing
			else if (this.state.measure) {
				return (
					<Link
						to={this.state.link.url}
						role={this.props.ariaHiddenElement ? 'none' : 'link'}
						tabIndex={this.props.ariaHiddenElement ? -1 : 0}
						title={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						alt={this.props.title || this.state.link.url}
						aria-label={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						className={this.state.style ? this.state.style : ''}
						onClick={() => this.onClick(this.state.measure)}>
						{this.state.children}
					</Link>
				);
			} else {
				return (
					<Link
						alt={this.props.title || this.state.link.url}
						aria-label={
							this.props.title && this.props.title === 'hideAria'
								? ''
								: `link to ${this.props.title || this.state.link.url}`
						}
						to={this.state.link.url}
						className={this.state.style ? this.state.style : ''}>
						{this.state.children}
					</Link>
				);
			}
		} else if (this.state.children) {
			return (
				<span
					role={`${
						this.props.role !== null ? (this.props.title === 'hideAria' ? '' : this.props.role) : `img`
					}`}
					aria-label={`${
						this.props.ariaLabel !== null
							? this.props.title === 'hideAria'
								? ''
								: this.props.title
							: `link image`
					}`}
					alt={this.props.title || this.state.link.url}
					title={`${
						this.props.ariaLabel !== null
							? this.props.title === 'hideAria'
								? ''
								: this.props.title
							: `link image`
					}`}
					className={this.state.style ? this.state.style : ''}
					tabIndex={this.props.title === 'hideAria' ? -1 : 0}>
					{this.state.children}
				</span>
			);
		} else {
			return null;
		}
	}
}

export default WimLink;

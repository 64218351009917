import React from 'react';

const Checkbox = ({ data }) => {
	logger.log('Checkbox - data:%o', data);
	return (
		<span>
			<input type="checkbox" name={data.name} id={data.id} className="gigya-input-checkbox" tabIndex="0" />
			<label htmlFor={data.name} />
			<span className="gigya-label-text gigya-checkbox-text" data-translation-key={data.key} />
		</span>
	);
};

export default Checkbox;

import FramePages from './index';
import deps from 'dependencies';

export default [
	{
		path: '/en_GB/wrapper/lost_property.html',
		exact: true,
		component: FramePages,
		load: () =>
			deps.actions.FramePages.mount({
				title: 'Lost Property',
				src: 'https://lostform.notlost.co/#/lost/2fb82135-25d4-4907-8097-5e50cf89e5a6/personal',
				height: '',
				social: {},
			}),
	},
	{
		path: '/en_GB/wrapper/lost_property_pre.html',
		exact: true,
		component: FramePages,
		load: () =>
			deps.actions.FramePages.mount({
				title: 'Lost Property',
				src: 'https://lostform.notlost.co/#/lost/19726dad-9286-420b-a857-1f0424d558a0/personal',
				height: '',
				social: {},
			}),
	},
	{
		path: '/en_GB/wrapper/museum.html',
		exact: true,
		component: FramePages,
		load: () =>
			deps.actions.FramePages.mount({
				title: 'Museum',
				src: 'https://collections.wimbledon.com/',
				height: '',
				social: {},
			}),
	},
	{
		path: '/en_GB/roadtowimbledon/video.html',
		exact: true,
		component: FramePages,
		load: () =>
			deps.actions.FramePages.mount({
				title: 'Road to Wimbledon Video',
				src: 'https://portlet.wingfield.app/',
				height: '',
				social: {},
			}),
	},

	{
		path: '/en_GB/virtualExhibitRoom/index.html',
		exact: true,
		component: FramePages,
		load: () =>
			deps.actions.FramePages.mount({
				title: 'Virtual Consultation',
				src: 'https://assets.wimbledon.com/VirtualConsultRoom3/index.html',
				height: 'fullview',
				social: {},
			}),
	},
	{
		path: '/en_GB/wrapper/contacttracing.html',
		exact: true,
		component: FramePages,
		load: () =>
			deps.actions.FramePages.mount({
				title: 'Contact Tracing',
				src: 'https://sres.pmy.cloud/dashboardfull',
				height: '',
				social: {},
			}),
	},
	{
		path: '/en_GB/wrapper/eventsresearchprogram.html',
		exact: true,
		component: FramePages,
		load: () =>
			deps.actions.FramePages.mount({
				title: 'The Events Research Programme',
				src: 'https://forms.wimbledon.com/ERPJuly2021/',
				height: '',
				social: {},
			}),
	},
];

/**
 DrawsArchiveYear Initial State
*/

export default {
	filters: {
		eventId: 'MS',
		open: '',
	},
};

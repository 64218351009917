import deps from 'dependencies';

import axios from 'axios';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let matchStatsPath = Config.scoringData.matchScore.path;
			let matchStatsHeaders = Config.components.cms.MatchStats;
			dispatch({
				type: deps.actionTypes.MATCHSTATSFULL_LOADING,
				data: { matchStatsPath: matchStatsPath, tableHeaders: matchStatsHeaders },
			});
		});
	},
};

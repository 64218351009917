import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[RadioWebview] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[RadioWebview] action.mount - data: %o', data);

			let data = {
				refreshRate: Config.otherData.activeData.rateSec > 5 ? Config.otherData.activeData.rateSec * 1000 : 5000,
			};
			logger.log('[RadioWebview] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.RADIOWEBVIEW_LOAD, data: data });
		});
	},

	savePlayerHandle: data => dispatch => {
		logger.log('[RadioWebview] actions savePlayerHandle data:%o', data);
		return dispatch({
			type: deps.actionTypes.RADIOWEBVIEW_SAVE_PLAYER_HANDLE,
			data,
		});
	},


	updateChannelStatus: data => dispatch => {
		logger.log('[RadioWebview] actions updateChannelStatus data:%o', data);
		let newData = {
			stationId: data.stationId,
			...data.otherData,
		};
		return dispatch({
			type: deps.actionTypes.RADIOWEBVIEW_UPDATE_CHANNEL_STATUS,
			data: newData,
		});
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import { values } from 'appdir/main';
import LoadingIndicator from 'components/common-ui/LoadingIndicator';
import isUndefined from 'lodash/isUndefined';

/* page elements */
import Header from 'appdir/components/pages/Slamtracker/elements/Header';
import Tabs from 'appdir/components/pages/Slamtracker/elements/Tabs';

/* preview tab */
import LikelihoodToWin from 'appdir/components/pages/Slamtracker/elements/preview/LikelihoodToWin';
import PreviewHeadToHead from 'appdir/components/pages/Slamtracker/elements/preview/PreviewHeadToHead';
import MatchStats from 'appdir/components/pages/Slamtracker/elements/MatchStats';
import Visualisation from 'appdir/components/pages/Slamtracker/elements/visualisation/Visualisation';
import ViewMoreMatches from 'appdir/components/pages/Slamtracker/elements/ViewMoreMatches';

/* summary tab */
import SlamtrackerRelatedContent from 'appdir/components/pages/Slamtracker/elements/SlamtrackerRelatedContent';

/* live & recap tab */
import Stage from 'appdir/components/pages/Slamtracker/elements/stage';

/* preview & summary tab */
import AICatchUpBox from 'appdir/components/pages/Slamtracker/elements/AICatchUpBox';

import StubBox from 'appdir/components/common-ui/StubBox';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import { fetch, fetchAll } from 'appdir/components/general/Util';
import { arrayToObject, getStatusConfig, isDoublesMatch, isNumeric, useMatchExtras, useAIContent } from 'appdir/components/pages/Slamtracker/Utilities';
import { hasInsights } from 'appdir/components/common-ui/PowerRanking/Utils';
import { updatePointHistory } from 'appdir/components/general/ScoreManager/PointHistory';

// import MeasurementUtils from 'appdir/lib/analytics';
import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';

import op from 'object-path';
import cn from 'classnames';

/**
 * -----------------------------------------------------------------------------
 * React Component: SlamtrackerContent
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Slamtracker'],
		statMatch: state?.['ScoreManager']?.statMatch ?? {},
		liveMatches: state?.['ScoreManager']?.liveMatches,
		smStatus: state?.['ScoreManager']?.status,
		smCurrentMatch: state?.['ScoreManager']?.currentMatch ?? {},
		controllerLoaded: state?.['Controller']?.loaded,
		uom: state?.['Controller'].userPreferences['speed'],
		windowSize: state?.['PageHeader']?.windowSize,
		configOtherData: state?.['Config']?.otherData ?? {},
		related: state?.['Config']?.relatedContent ?? {},
		showInsights: state?.['Config']?.scoring?.showInsights ?? false,
		st_event: state?.['Config']?.scoring?.messagePath ?? '',
		eventNames: state?.['Config']?.scoringConfig?.eventNames ?? [],
		configScoringData: state?.['Config']?.scoringData ?? {},
		sharedDataConfig: state?.['Config']?.sharedDataConfig ?? {},
		slamtrackerConfig: state?.['Config']?.slamtracker ?? {},
		// adConfig: state['Config'].adConfig,
		stubs: state?.['Config']?.stubPages,
		currentDay: state['ActiveData']['currentDay'],
		matchInsightsMatches: state['CommonData']['matchInsightsMatches'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.Slamtracker.mount()),
	unmount: () => dispatch(deps.actions.Slamtracker.unmount()),
	setScoreManagerStatus: data => dispatch(deps.actions.ScoreManager.setScoreManagerStatus(data)),
	updateMatchHistory: data => dispatch(deps.actions.Slamtracker.updateMatchHistory(data)),
	updateData: (data) => dispatch(deps.actions.Slamtracker.updateData(data)),
	setCompletedStatMatch: matchData => dispatch(deps.actions.ScoreManager.setCompletedStatMatch(matchData)),
	setCurrentMatch: matchData => dispatch(deps.actions.ScoreManager.setCurrentMatch(matchData)),
	setLiveStatMatch: matchData => dispatch(deps.actions.ScoreManager.setLiveStatMatch(matchData)),
	setStatMatchHistory: historyData => dispatch(deps.actions.ScoreManager.setStatMatchHistory(historyData)),
	clearStatMatch: () => dispatch(deps.actions.ScoreManager.clearStatMatch()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
	reset: () => dispatch(deps.actions.Slamtracker.reset()),
	clearNavPath: data => dispatch(deps.actions.MainNav.unmount(data)),
	hide: () => dispatch(deps.actions.SlamtrackerPanel.hide()),

	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),

	getPlayerDetail: (ids) => dispatch(deps.actions.Tournament.getPlayerDetail({ playerIds: ids })),
	getPlayerStats: (ids) => dispatch(deps.actions.Tournament.getPlayerStats({ playerIds: ids }))
});

class SlamtrackerContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: null
		};
		this.t1_loading = false;
		this.t2_loading = false;
		this.stLoaded = false;
		this.liveLoaded = false;
		this.unsubscribed = true;
		this.scoresInit = false;
		this.statsLoaded = false;
		this.historyLoaded = false;
		this.pageMeasured = false;
		this.currentMatch = null;
		this.shotQualityTimer = null;
		this.matchEndTimer = null;

		this.myScrollArea = React.createRef();
	}

	componentDidMount() {
		// logger.log('[SlamtrackerContent] componentDidMount - this:%o', this);

		this.isPanel = true;

		if (!this.props?.configStatus) {
			// logger.log('[STContent] mounting Slamtracker');
			this.props?.mount(this.isPanel);
		}

		this.setState({
			mounted: true,
		});
	}

	componentWillUnmount() {
		logger.log('[SlamtrackerContent] componentWillUnmount');
		// i don't want to do this if we are staying on slamtracker page
		this.props?.setScoreManagerStatus({ slamtracker: false });
		//this.props.unmount();
		this.unsubscribeMatch();
		this.props?.reset();
		this.props?.clearStatMatch();
		this.clearShotQualityTimer();
		this.clearMatchEndTimer();
	}

	navigate = (path) => {
		if (this.props?.windowSize == 'mobile') {
			this.props?.hide();
		}
		this.props?.navigate(path);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[SlamtrackerContent] componentDidUpdate - prevState:%o', prevState);
		// logger.log('[SlamtrackerContent] componentDidUpdate - this:%o', this);

		if (
			!this.scoresInit &&
			this.props?.smStatus &&
			!this.props?.smStatus?.slamtracker &&
			this.props?.controllerLoaded
		) {
			// logger.log('[SlamtrackerContent] componentDidUpdate set status of slamtracker in controller');
			this.scoresInit = true;
			this.props?.setScoreManagerStatus({ slamtracker: true });
		}

		/** This is needed to check for innovations data */
		if (
			prevProps?.status !== this.props?.status &&
			this.props?.status == 'loaded' &&
			this.props?.slamtrackerConfig?.usePowerIndex === true
		) {
			// load all the available MI matches list to check if the selected MatchId exists
			this.props
				.checkExpired(this.props?.sharedDataConfig['matchInsightsMatches'])
				.then(response => {
					this.sharedDataLoaded = true;
					logger.log('[MatchInsights] componentDidUpdate matchInsightsMatches - response:%o', response);
					if (response.status == 'expired') {
						this.props?.update(this.props?.sharedDataConfig['matchInsightsMatches']);
					}
				})
				.catch(error => {
					//this.sharedDataLoaded = false;
					logger.error('[MatchInsights] componentDidUpdate matchInsightsMatches - :o', error);
				});
		}

		/**
		 * currentMatchId will only be the match id string
		 * this.currentMatch will only be a live match object retrieved
		 * from ScoreManager.liveMatches
		 */

		let currentMatchId = this.props?.smCurrentMatch?.matchId ?? 'undefined';

		/**
		 * a previous statMatch exists and the previous statMatch
		 * status is not completed and the match id param from the route
		 * matches the match id for our current statMatch, but the current
		 * statMatch status is complete.
		 * unsubscribe from the match in the scoring module, but do not
		 * clear the statMatch or set stLoaded to false because we are
		 * still sitting on the page
		 */
		if (
			prevProps?.statMatch?.status !== 'Completed' &&
			this.props?.statMatch?.match_id === this.props?.matchId &&
			this.props?.statMatch?.status == 'Completed' &&
			this.stLoaded &&
			!this.unsubscribed
		) {
			// logger.log('[SlamtrackerContent] componentDidUpdate: match has ended, but still on the page');

			this.matchEndTimer = setTimeout(() => {
				this.unsubscribeMatch();
				this.unsubscribed = true;
			}, 30 * 1000); //give some time for any last point history to come in, then unsubscribe	
		}

		/**
		 * the match id param from the route does not match the match id
		 * for our current statMatch. Unsubscribe from the match in the
		 * scoring module, clear the statMatch data, and set stLoaded to false
		 * set pageMeasured to false - new page, new measurement call
		 */
		if (
			prevProps.matchId !== this.props?.matchId &&
			this.props?.statMatch?.match_id !== this.props?.matchId &&
			this.stLoaded
		) {
			// logger.log(
			// 	'[SlamtrackerContent] componentDidUpdate: new match!  old:%o, new:%o',
			// 	this.props.statMatch.match_id,
			// 	this.props.matchId
			// );

			currentMatchId = 'undefined';
			this.resetMatch();
		}

		/**
		 * we do not have a current match so we are going to search
		 * for one in our live matches based on the match id param from
		 * the route
		 */
		if (currentMatchId == 'undefined' && this.props?.matchId && this.props?.liveMatches) {
			// logger.log(
			// 	'[SlamtrackerContent] componentDidUpdate: trying to get a current live match - this.props.matchId:%o, this.props.liveMatches:%o',
			// 	this.props.matchId,
			// 	this.props.liveMatches
			// );

			let _this = this;
			this.currentMatch = this.props?.liveMatches.find(function(match) {
				return match.match_id == _this.props?.matchId;
			});

			/**
			 * we didn't find a live match, so setting current match to the string "undefined".
			 * we are working with a completed match
			 */
			if (typeof this.currentMatch === 'undefined') {
				// logger.log("[SlamtrackerContent] componentDidUpdate: unable to get a current live match, must be a completed match:%o");
				currentMatchId = 'undefined';
				this.currentMatch = null;
			}
		}
		// logger.log('[SlamtrackerContent] componentDidUpdate: this.props.liveMatches:%o', this.props.liveMatches);
		/**
		 * we have a matchId, slamtracker is not loaded,
		 * ScoreManager and ScoreManager.liveMatches are
		 * available, we are using match insights and they are loaded,
		 * or we are not using match insights.  Load a match.
		 */
		if (
			this.props?.matchId &&
			this.props?.liveMatches
			/** RLL 6/13/24 I don't think this is needed */
			//  &&
			// ((op.get(this.props, 'matchInsightsMatches.status', false) == 'loaded' &&
			// 	this.props?.showInsights === true) ||
			// 	this.props?.showInsights === false)
		) {
			// logger.log('[SlamtrackerContent] componentDidUpdate: loading match this:%o', this);


			if (!this.pageMeasured) {
				this.pageMeasured = true;
				if (!values.webview) {
					this.measureIndex('IBM Slamtracker', '');
				} 
			}

			/* completed or upcoming match */
			if (
				!this.currentMatch ||
				(this.props?.currentDay &&
					this.props?.currentDay.tournament != 'post' &&
					this.currentMatch &&
					this.currentMatch.status === 'Completed')
			) {
				if (this.props?.matchId) {
					// logger.log('[SlamtrackerContent] componentDidUpdate upcoming match:%o ', this.props?.matchId);

					// logger.log(
					// 	'[SlamtrackerContent] componentDidUpdate init completed matchID:%o this.currentMatch',
					// 	this.props.matchId,
					// 	this.currentMatch
					// );

					this.unsubscribed = true;

					let path = this.props?.configScoringData?.matchScore?.path.replace('<matchId>', this.props?.matchId);

					/* fetch the complete match data */
					if (!this.statsLoaded) {
						this.statsLoaded = true;
						fetch(path)
							.then(result => {
								logger.log('[SlamtrackerContent] completed match data for completed or upcoming match:%o', result);
								this.props?.setCompletedStatMatch(result.matches[0]);
							})
							.catch(error => {
								logger.log('[SlamtrackerContent] componentDidUpdate error:%o', error);
								this.setState({
									hasError: true,
									errorType: error,
								});
							});
					}

					let history_path = this.props?.configScoringData?.matchHistory?.path.replace('<matchId>', this.props?.matchId);

					/* fetch the completed point history data but not if upcoming */

					if (
						!this.historyLoaded &&
						this.props?.statMatch?.match_id == this.props?.matchId &&
						this.props?.statMatch?.statusCode &&
						this.props?.statMatch?.statusCode != 'B' && //future
						this.props?.statMatch?.statusCode != 'F' //&& //walkover
						// this.props.statMatch.statusCode != 'K' //suspended
					) {
						this.historyLoaded = true;
						fetch(history_path)
							.then(result => {
								// logger.log( "[SlamtrackerContent] completed match history data:%o", result);
								let updatedResult = updatePointHistory(this.props?.statMatch?.pointHistory, result);
								this.props?.setStatMatchHistory(result);
							})
							.catch(error => {
								logger.log(
									'[SlamtrackerContent] componentDidUpdate error fetching match history:%o',
									error
								);
								this.setState({
									hasError: true,
									errorType: error,
								});
							});
					}

					if (!this.stLoaded) {
						this.stLoaded = true;
						this.t1_loading = false;
						this.t2_loading = false;

						this.setState({
							overviewData: {
								p1ADetail: false,
								p2ADetail: false,
								p1BDetail: false,
								p2BDetail: false,
								playerOverviewError: false
							},
						});
					}
				}
			} else if (
				/**
				 * live match: 
				 * 
				 * we have a currentMatch object and
				 * this.stLoaded is false OR
				 * this.stLoaded is true and this.liveLoaded is false and
				 * the previous statLevel was S and now it's H
				 * indicating the statLevel for the match was updated and 
				 * we have a new statsLevel that needs data
				 * 
				 * RLL 6/20/24 added the extra conditions because match data was not
				 * being loaded because statsLevel was not correct for an extended period into
				 * the match
				 **/
				this.currentMatch &&
				(
					!this.stLoaded 
					// || 
					// (
					// 	this.stLoaded && !this.liveLoaded &&
					// 	(
					// 		prevProps?.statMatch?.statsLevel == 'S' && this.props?.statMatch?.statsLevel == 'H'
					// 	)

					// )
				) &&
				this.currentMatch.statsLevel
			) {
				logger.log(
					'[SlamtrackerContent] componentDidUpdate init live this.stLoaded:%o, this.liveLoaded:%o, prevProps.statMatch.statsLevel:%o, this.props?.statMatch?.statsLevel:%o',
					this.stLoaded,
					this.liveLoaded,
					prevProps?.statMatch?.statsLevel,
					this.props?.statMatch?.statsLevel
				);
				logger.log('[SlamtrackerContent] subscribe to match:%o', this.props.matchId);
				let upcoming = false;
				let statLevelToUse = !this.stLoaded ? this.currentMatch?.statsLevel : this.props?.statMatch?.statsLevel;

				if(this.stLoaded) {
					upcoming = true;
					this.liveLoaded = true;
					/** 
					 * we do need to unsubscribe from the match data 
					 * tennis-scoring-data is set up not to re-subscribe to the data
					 * for any of the currentStatMatches
					 **/
					this.unsubscribeMatch()
				}

				this.stLoaded = true;
				this.unsubscribed = false;

				/* create event to call stats and point history */
				let addEvent = new CustomEvent('ixEventsScoring', {
					detail: {
						type: 'addMatchData',
						statsLevel: 'H', //statLevelToUse,
						matchId: this.props?.matchId,
					},
				});

				window.dispatchEvent(addEvent);

				// logger.log("[SlamtrackerContent] componentDidUpdate set current overview data to null");
				if(!upcoming) {
					this.t1_loading = false;
					this.t2_loading = false;

					this.setState({
						overviewData: {
							p1ADetail: false,
							p2ADetail: false,
							p1BDetail: false,
							p2BDetail: false,
							playerOverviewError: false
						},
					});
				}

				this.props?.setLiveStatMatch(this.currentMatch);
			}
		}

		// NOTE:  i don't think i should be checking the length but rather if the list of match ids have changed
		if (
			prevProps.statMatch &&
			this.props?.statMatch.match_id == this.props?.matchId &&
			this.props?.statMatch.pointHistory.length !== prevProps.statMatch.pointHistory.length
		) {
			// logger.log('[SlamtrackerContent] componentDidUpdate pointHistory length changed - statMatch:%o', this.props.statMatch);
			this.updateMatchHistory();
		}

		// awt 6/2/2021: if statMatch.match_id changes then setPageViews passing nothing so that default tabs can be chosen
		if (
			this?.props?.statMatch?.match_id !== prevProps?.statMatch?.match_id &&
			this?.props?.statMatch?.match_id
		) {
			// if (this.state.tab) {
				let matchStateText = getStatusConfig(this.props?.statMatch.statusCode)['text'];
				
				// this.measureIndex('Default Tab', '', [
				// 	{ slamtrackerTab: this.state.tab },
				// 	{ st_cta_label: `${values.slamtrackerButtonText}${matchStateText}` },
				// ]);
			
				this.setPageViews();
				if(this.props?.slamtrackerConfig?.usePowerIndex === true) {
					this.loadInnovationsData();
				}
		}

		if (
			this.props?.statMatch &&
			this.props?.statMatch?.team1?.idA &&
			this.props?.statMatch?.match_id == this.props?.matchId &&
			!this.t1_loading &&
			!this.t2_loading
		) {
			logger.log('[SlamtrackerContent] componentDidUpdate calling getMatchData this:%o', this);
			this.getMatchData();

			// load data for innovations overview and insights tabs
			// this.loadInnovationsData();
		}
	}

	loadInnovationsData() {
		// logger.log('[SlamtrackerContent] loadInnovationsData this:%o', this);

		// fetch nlg match insights data file
		let miList = this.props?.matchInsightsMatches?.result?.matches;
		let match = this.props?.statMatch;
		let { configOtherData } = this.props;

		if (useMatchExtras(match)) {
			let powerIndexMatchupPath = configOtherData.innovations.powerIndexMatchup.replace(
				'<matchId>',
				match.match_id
			);
			fetch(powerIndexMatchupPath)
				.then(pim_result => {
					logger.log('[SlamtrackerContent] loadInnovationsData pim_result:%o', pim_result);

					this.setState({
						innovationsData: {
							match_id: match.match_id,
							powerIndexMatchup: pim_result,
						},
					});
				})
				.catch(error => {
					logger.error(
						'[SlamtrackerContent] loadInnovationsData error loading powerIndexMatchup data:%o',
						error
					);
					this.setState({
						innovationsData: null,
					});
				})
		}
	}

	/**
	 * actions to take when switching matches in slamtracker
	 */
	resetMatch() {
		// logger.log('[SlamtrackerContent] resetMatch');
		this.props?.reset(); //Slamtracker

		this.unsubscribeMatch();

		/* call action to clear out stat match data */
		this.props?.clearStatMatch(); //ScoreManager

		/* this needs to be done whether currentMatchId is undefined or not */
		this.stLoaded = false;
		this.statsLoaded = false;
		this.historyLoaded = false;
		this.clearShotQualityTimer();
		this.clearMatchEndTimer();

		/* set to false so new match will get a page view measurement call */
		this.pageMeasured = false;

		// clear the innovationsMatch
		this.setState({
			innovationsData: null,
			powerrankError: false,
			stRelatedData: null,
			catchMeUpRecap: null,
			catchMeUpPreview: null,
			hasError: false,
			overviewData: {
				p1ADetail: false,
				p2ADetail: false,
				p1BDetail: false,
				p2BDetail: false,
				playerOverviewError: false
			}
		});

		this.props?.clearNavPath({
			newPath: null,
			nav: null,
		});
	}

	/**
	 * unsubscribe match from the scoring module
	 */
	unsubscribeMatch() {
		// logger.log('[SlamtrackerContent] unsubscribeMatch:%o', this.props.statMatch.match_id);
		this.clearShotQualityTimer();
		
		let removeEvent = new CustomEvent('ixEventsScoring', {
			detail: {
				type: 'removeMatchData',
				statsLevel: this.props?.statMatch.statsLevel,
				matchId: this.props?.statMatch.match_id,
			},
		});

		window.dispatchEvent(removeEvent);
	}

	clearShotQualityTimer = () => {
		if(this.shotQualityTimer) {
			clearTimeout(this.shotQualityTimer);
		}
	}

	clearMatchEndTimer = () => {
		if(this.matchEndTimer){
			clearTimeout(this.matchEndTimer);
		}
	}

	/**
	 * load shot quality data from tennis viz
	 * and set timer to continuously reload
	 * data at an interval.
	 * 
	 * currently this is only used for Wimbledon
	 */
	loadShotQualityData = () => {
		let { statMatch } = this.props;
		let { configOtherData } = this.props;
		let sqPath;

		/** use data we know exists if using test data */
		if(this.props?.slamtrackerConfig?.useSQTestData === true) {
			sqPath = configOtherData?.shotQuality?.url.replace('<matchId>', statMatch?.match_id).replace('2024', '2023');
		} else {
			sqPath = configOtherData?.shotQuality?.url.replace('<matchId>', statMatch?.match_id);
		}
		let sqError = false;

		fetch(sqPath)
			.then(results => {
				if(results?.players && results?.insights) {
					// logger.log('[SlamtrackerContent] loadShotQualityData results:%o', results);

					let shotquality_data = {
						shotquality: {
							match_id: statMatch?.match_id,
							results
						}
					}

					this.props?.updateData(shotquality_data);
				} else {
					sqError = true;
					// logger.log('[SlamtrackerContent] loadShotQualityData data loaded incorrectly');
				}

				if(sqError === false) {
					// logger.log('[SlamtrackerContent] loadShotQualityData (then) should be false setting timeout. sqError:%o', sqError);
					this.shotQualityTimer = setTimeout(this.loadShotQualityData, configOtherData?.shotQuality?.refresh * 1000);
				} else {
					// logger.log('[SlamtrackerContent] loadShotQualityData (then) should be true clearing timeout if it exists. sqError:%o', sqError);
					this.clearShotQualityTimer();
				}
			})
			.catch(error => {
				sqError = true;
				logger.error('[SlamtrackerContent] loadShotQualityData error loading shot quality data:%o', error);

				this.clearShotQualityTimer();
			});
	}

	/***
	 * function getMatchData
	 *
	 * This function will fetch the player overview data, 
	 * player stats data, and head to head data. The data 
	 * retreived will be saved in Slamtracker redux state
	 */
	getMatchData = () => {
		let { statMatch } = this.props;

		if (!this.t1_loading && !this.t2_loading) {
			let idObj = [
				statMatch.team1.idA,
				statMatch.team2.idA,
			];

			if (statMatch.team1.idB != null && statMatch.team2.idB != null) {
				idObj.push(statMatch.team1.idB),
				idObj.push(statMatch.team2.idB)
			}

			this.t1_loading = true;
			this.t2_loading = true;

			this.props?.getPlayerDetail(idObj);

			if(useMatchExtras(statMatch) === true){
				/**
				 * Head to head tournament stats are 
				 * only available for round 2 and later
				 */
				if(statMatch?.roundCode !== "1") {
					/** used in HeadtoHeadTournamentStats */
					let tournStatsObj;
					
					tournStatsObj = [
						this.props?.configScoringData?.playerTournStats.replace('<id>', statMatch.team1.idA),
						this.props?.configScoringData?.playerTournStats.replace('<id>', statMatch.team2.idA),
					]

					fetchAll(tournStatsObj)
						.then(data => {
							let tourndata= {
								tourn_stats: {
									team1: {name: statMatch.team1.displayNameA, player_stats: {...data[0]}},
									team2: {name: statMatch.team2.displayNameA, player_stats: {...data[1]}}
								}
							}
							this.props?.updateData(tourndata);
						})
						.catch(error => {
							logger.error('[SlamtrackerContent] error loading tourn stats data:%o', error);
						})
				}
				
				/** used in ShotQuality */
				this.loadShotQualityData();

				/** used in PastMatchUps */
				let head2headPath;

				/** use file we know exists if using test data */
				if(this.props?.slamtrackerConfig?.useH2HTestData === true) {
					head2headPath = "https://www-cdt.wimbledon.com/en_GB/scores/feeds/2024/stats/head2head/1701.json";
				} else {
					head2headPath = this?.props?.configScoringData?.headToHead.replace('<matchId>', statMatch?.match_id);
				}

				fetch(head2headPath)
					.then(data => {
						if(data?.player) {
							/** sort results from most recent to oldest */
							let sorted_results = data?.player[0]?.results;
							
							if(sorted_results) {
								/* sort by year/month/day most recent to least recent  */
								sorted_results.sort((a,b) => {
									return parseInt(b.year) - parseInt(a.year) || parseInt(b.month) - parseInt(a.month) || parseInt(b.day) - parseInt(a.day);
								});
							}

							data.player[0].results = sorted_results;

							let h2hdata = {
								headtohead: data.player[0]
							}

							/** use names from statMatch if using test data */
							if(this.props?.slamtrackerConfig?.useH2HTestData === true && statMatch?.match_id !== '1701') {
								h2hdata.headtohead.player1Name = `${statMatch.team1.firstNameA} ${statMatch.team1.lastNameA}`;
								h2hdata.headtohead.player2Name = `${statMatch.team2.firstNameA} ${statMatch.team2.lastNameA}`;
							}
							
							this.props?.updateData(h2hdata);
						}
					})
					.catch(error => {
						logger.error('[SlamtrackerContent] error loading head to head data:%o', error);
					})
				
				/** used in HeadtoHeadYTDStats */
				let ytdStatsObj;

				/** use file we know exists (for both players) if using test data */
				if(this.props?.slamtrackerConfig?.useYTDTestData === true) {
					ytdStatsObj = [
						"https://www-cdt.wimbledon.com/en_GB/scores/feeds/2024/players/stats_ytd/atpa0e2_ytd.json",
						"https://www-cdt.wimbledon.com/en_GB/scores/feeds/2024/players/stats_ytd/atpa0e2_ytd.json",
					]
				} else {
					ytdStatsObj = [
						this.props?.configScoringData?.playerYTDStats.replace('<id>', statMatch.team1.idA),
						this.props?.configScoringData?.playerYTDStats.replace('<id>', statMatch.team2.idA),
					]
				}

				fetchAll(ytdStatsObj)
					.then(data => {
						let ytddata= {
							ytd_stats: {
								team1: {name: statMatch.team1.displayNameA, player_stats: {...data[0]}},
								team2: {name: statMatch.team2.displayNameA, player_stats: {...data[1]}}
							}
						}
						this.props?.updateData(ytddata);
					})
					.catch(error => {
						logger.error('[SlamtrackerContent] error loading ytd stats data:%o', error);
					})
			}
		}
	}

	/***
	 * function updateMatchHistory
	 *
	 * This function gets called everytime a new point comes in.  What it does is loops through the point history
	 * and separates it by set and game adding in point objects for point server changes, tiebreaks and other status
	 * types that need to be displayed (players arrive on court, players warming up etc.)
	 *
	 * TODO:  To improve efficiency, might want to rewrite this to only need to handle the next point and not process all
	 * 		points every time a point comes in.  I don't know .... just been kinda bothering me.  No time to do that now!
	 */
	updateMatchHistory() {
		let { pointHistory } = this.props?.statMatch;

		let pointsBySet = {};

		if (pointHistory && pointHistory.length > 0) {
			let pointsById = arrayToObject(pointHistory, 'PointNumber');

			for (let i = 1; i <= 5; i++) {
				let setNo = i.toString();

				if (!pointsBySet[setNo]) {
					pointsBySet[setNo] = {};
				}

				let setHistory = {};

				pointHistory
					.filter(point => point.SetNo == setNo)
					// .filter(pointNumber => pointHistory.byId[pointNumber].SetNo == setNo)
					.slice(0)
					.reverse()
					.map((point, i) => {
						//logger.log('[SlamtrackerContent] updateMatchHistory pointNumber:%o, setHistory:%o, point, setHistory);
						// let pointObj = pointHistory.byId[point];
						let pointObj = point;

						// if there's no game obj, add an empty game obj to set

						let isTbGame = this.isTiebreakGame(pointObj);
						let gameKey = isTbGame ? 'tiebreak' : pointObj.GameNo;

						if (!setHistory[gameKey]) {
							setHistory[gameKey] = {};
						}

						// add the point obj to game
						setHistory[gameKey][point.PointNumber] = pointObj;

						// do the tiebreak next server stuff
						// set the initial point of the game to 0Z.  it will be used for next server
						if (isNumeric(point.PointNumber) && !setHistory[gameKey]['0Z']) {
							//logger.log('[SlamtrackerContent] updateMatchHistory add 0Z setHistory point:%o, gameKey:%o', point, gameKey);
							//if (!setHistory[pointObj.GameNo]["0Z"]){
							setHistory[gameKey]['0Z'] = pointObj;
							//}
						} else if (
							isNumeric(point.PointNumber) &&
							setHistory[gameKey]['0Z'] &&
							parseInt(setHistory[gameKey]['0Z'].PointNumber, 10) > parseInt(pointObj.PointNumber, 10)
						) {
							//logger.log('[SlamtrackerContent] updateMatchHistory add 0Z setHistory 2 point:%o, gameKey:%o, pointObj.PointNumber:%o', point, gameKey, pointObj.PointNumber);
							setHistory[gameKey]['0Z'] = pointObj;
							//logger.log('[SlamtrackerContent] updateMatchHistory add 0Z setHistory 2 setHistory:%o', setHistory);
						}

						//logger.log('[SlamtrackerContent] updateMatchHistory isTbGame:%o, pointObj.SetNo:%o, pointObj.MatchWinner:%o, pointObj.SetWinner:%o, pointObj.GameWinner:%o', isTbGame, pointObj.SetNo, pointObj.MatchWinner, pointObj.SetWinner, pointObj.GameWinner);

						if (
							isTbGame &&
							pointObj.MatchWinner == '0' &&
							pointObj.SetWinner == '0' &&
							pointObj.GameWinner == '0'
						) {
							let tempServer = pointObj.PointServer == '1' || pointObj.PointServer == '3' ? '1' : '2';
							let tempServeIndicator =
								pointObj.ServeIndicator == '1' || pointObj.ServeIndicator == '3' ? '1' : '2';
							//logger.log('[SlamtrackerContent] updateMatchHistory server -- serveIndicator:%o, tempServer:%o', tempServeIndicator, tempServer);

							if (tempServer !== tempServeIndicator) {
								// logger.log('[SlamtrackerContent] updateMatchHistory server change - gameKey:%o, pointKey:%o',gameKey, `${point.PointNumber}Z`);
								// logger.log('[SlamtrackerContent] updateMatchHistory server change - isAdded: %o',setHistory[gameKey][`${point.PointNumber}Z`]);

								if (!setHistory[gameKey][`${point.PointNumber}Z`]) {
									//logger.log('[SlamtrackerContent] updateMatchHistory server change - adding!');
									setHistory[gameKey][`${point.PointNumber}Z`] = pointObj;
									//logger.log('[SlamtrackerContent] updateMatchHistory server change - setHistory:%o',setHistory);
								}
							}
						}
					});

				// logger.log('[SlamtrackerContent] updateMatchHistory server change - setHistory2:%o', setHistory);
				// create set history
				if (Object.keys(setHistory).length > 0) {
					// add set obj to match
					pointsBySet[setNo] = setHistory;
				} else {
					// delete sets that are not needed from matchHistory
					delete pointsBySet[setNo];
				}
			}

			// set state with new match history
			// logger.log("[SlamtrackerContent] updateMatchHistory pointsBySet:%o", pointsBySet);
			this.props?.updateMatchHistory({
				pointsBySet,
				pointsById,
			});
		}
	}

	/**
	 * this function determines if this is a tiebreak game
	 */
	isTiebreakGame(item) {
		let { statMatch } = this.props;

		var tiebreak = false;
		var set = parseInt(item.SetNo, 10);
		//var game = parseInt(item.GameNo,10) == 12?13:parseInt(item.GameNo);
		var game = parseInt(item.GameNo);
		var setWinner = item.SetWinner == '0' ? false : true;
		//logger.log('[SlamtrackerContent] this:%o',this);
		const isQuals = () => {
			let quals = false;
			this.props?.eventNames.map((event, i) => {
				if (event.quals == true && event.sb_code == statMatch.eventCode) {
					quals = true;
				}
			});
			//logger.log('[SlamtrackerContent] is quals');

			return quals;
		};

		//if (this.props.st_event == 'wim' && game >= 13 /*&&!setWinner*/) {
		if (this.props?.st_event == 'wim' && game >= 13) {
			if (isQuals()) {
				//logger.log('[SlamtrackerContent] is quals: %o', item);
				// quals match
				if (
					this.props?.st_event == 'wim' &&
					statMatch.eventCode == 'S' &&
					statMatch.roundCode == '3' &&
					set != 5
				) {
					// qual mens round 3 but not set 5
					tiebreak = true;
				} else if (set != 3) {
					// all others not set 3
					tiebreak = true;
				}
			} else {
				// main draw
				//logger.log('[SlamtrackerContent] is maindraw: %o', item);
				if ((statMatch.eventCode == 'A' || statMatch.eventCode == 'C') && set != 5) {
					// mens singles and doubles and not the last set
					tiebreak = true;
				} else if (statMatch.eventCode != 'A' && statMatch.eventCode != 'C' && set != 3) {
					// all others not the last set
					tiebreak = true;
				}
			}
		}
		//logger.log('[SlamtrackerContent] isTiebreakGame SetNo:%o, GameNo:%o, PointNumber:%o, SetWinner:%o, tiebreakEvent:%o', item.SetNo, item.GameNo, item.PointNumber, item.SetWinner, this.props.slamtrackerConfig?.superTiebreakEvents.indexOf(statMatch.eventCode));
		if (
			this.props?.st_event == 'uso' &&
			(game >= 13 || (item.SetNo == '3' && this.props?.slamtrackerConfig?.superTiebreakEvents.indexOf(statMatch.eventCode) !== -1))
		) {
			tiebreak = true;
		}

		if (tiebreak) {
			//logger.log('[SlamtrackerContent] isTiebreakGame - isTiebreakGame game:%o', game);
		}
		return tiebreak;
	}

	/** set view of slamatracker either based on the tab passed in or the match status */
	setPageViews = (tab) => {
		// logger.log('[SlamtrackerContent] setPageViews - this:%o', this);
		// logger.log('[SlamtrackerContent] setPageViews - tab:%o', tab);

		/** set tab based on data passed to function **/ 
		if (tab) {
			this.setState(prevState => {
				return {
					...prevState,
					tab: tab,
					prevTab: prevState.tab
				}
			}, () => {
				if(values.webview) {
					this.myScrollArea.current.scrollTo(0,0);
				}
			});
		} 
		/** set tab based on current match status **/ 
		else {
			let { statMatch } = this.props;

			let matchStatusConfig = getStatusConfig(statMatch.statusCode);

			// logger.log('[SlamtrackerContent] setPageViews default - matchStatusConfig:%o', matchStatusConfig['data']);

			this.measureIndex('Default Tab', '', [
				{ slamtrackerTab: matchStatusConfig['data'] },
				{ st_cta_label: `${values.slamtrackerButtonText}${matchStatusConfig['text']}` },
				],
			 "state");

			this.setState({
				tab: matchStatusConfig['data']
			});
		}
	}

	renderPreviewContent() {
		// logger.log('[SlamtrackerContent] renderPreviewContent - this:%o', this);
		let doubles = isDoublesMatch(this.props?.statMatch);
		let extras = useMatchExtras(this.props?.statMatch);
		let hasAIContent = useAIContent(this.props?.statMatch);
		let miList = this.props?.matchInsightsMatches?.result?.matches;
		let showPowerIndex = hasInsights(miList, this.props?.statMatch?.match_id) && this.props?.slamtrackerConfig?.usePowerIndex === true ? true : false;

		// logger.log('[SlamtrackerContent] renderPreviewContent - this:%o', this);

		return (
			<div className="preview-content panel-content">
				<ErrorBoundary message="Error loading Match Preview">
					{(hasAIContent === true && this.props?.slamtrackerConfig?.useAIBox === true) && this.renderAIBox('preview')}
				</ErrorBoundary>
				{showPowerIndex === true && this.renderLikelihoodToWin()}
				<PreviewHeadToHead doubles={doubles} playersHaveStats={extras} />
				<ViewMoreMatches currentTab={'Live'} measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />
			</div>
		)
	}

	renderLiveContent(status) {
		return (
			<div className="live-content panel-content">
				{this.props?.slamtrackerConfig?.showVisualisation && status && status == 'live' && <ErrorBoundary message="Error loading Match Visualisation"><Visualisation /></ErrorBoundary>}
				{this.props?.slamtrackerConfig?.usePointByPoint === true &&
					<ErrorBoundary message="Error loading Point by Point Data">
						<Stage 
							statMatch={this.props?.statMatch}
							measureIndex={this.measureIndex}
							currentTab={this.state?.tab}
						/>
					</ErrorBoundary>
				}
				{status && status == 'live' && <ErrorBoundary message="Error loading Match Stats"><MatchStats /></ErrorBoundary>}
				<ViewMoreMatches currentTab={'Live'} measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />
			</div>
		)
	}

	renderRecapContent() {
		let hasAIContent = useAIContent(this.props?.statMatch);

		return (
			<div className="recap-content panel-content">
				<ErrorBoundary message="Error loading Match Recap">
					{(hasAIContent && this.props?.slamtrackerConfig?.useAIBox === true) && this.renderAIBox('recap')}
				</ErrorBoundary>
				{this.props?.slamtrackerConfig?.showVisualisation && <ErrorBoundary message="Error loading Match Visualisation"><Visualisation /></ErrorBoundary>}
				{this.props?.slamtrackerConfig?.usePointByPoint === true &&
					<ErrorBoundary message="Error loading Point by Point Data">
						<Stage statMatch={this.props?.statMatch} 
							measureIndex={this.measureIndex}
							currentTab={this.state?.tab}
						/>
					</ErrorBoundary>
				}
				<ViewMoreMatches currentTab={'Live'} measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />
			</div>
		)
	}

	renderSummaryContent(status) {
		let hasAIContent = useAIContent(this.props?.statMatch);
		
		return (
			<div className="summary-content panel-content">
				<ErrorBoundary message="Error loading Match Recap">
					{(hasAIContent && this.props?.slamtrackerConfig?.useAIBox === true) && this.renderAIBox('summary') }
				</ErrorBoundary>
				<ErrorBoundary message="Error loading Related Content">
					<SlamtrackerRelatedContent
						filterData={this?.state?.stRelatedData}
						relatedData={this.props?.related}
						statMatch={this.props?.statMatch}
            windowSize={this.props?.windowSize}
          			/>
				</ErrorBoundary>
				{status && status == 'post' ? (
					<ErrorBoundary message="Error loading Match Stats">
						<MatchStats />
					</ErrorBoundary>
					) : null
				}
				<ViewMoreMatches currentTab={'Live'} measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />
			</div>
		);
	}

	renderContentArea() {
		// logger.log('[SlamtrackerContent] renderContentArea - this:%o', this);
		// logger.log('[SlamtrackerContent] renderContentArea - overviewProps:%o', overviewProps);
		let miList = this.props?.matchInsightsMatches?.result?.matches;
		let { statMatch } = this.props;
		let { tab } = this.state;
		let status = getStatusConfig(statMatch.statusCode)['status'];

		const overviewClassnames = () => {
			//logger.log('[SlamtrackerContent] renderContentArea - overviewClassnames - this:%o', this);

			return cn({
				panel: true,
				'overview-panel ': true,
				pr: hasInsights(miList, statMatch.match_id),
				selected:
					this.props?.windowSize == 'mobile' || this.isPanel
						? this.state.tab == 'preview'
						: this.state.tab == 'preview',
			});
		};

		return (
			<div id="st-content-area" className="content-area" ref={this.myScrollArea}>
				{tab == 'preview' ? (
					<div className={overviewClassnames()}>
						{this.renderPreviewContent()}
					</div>
				) : null}
						
				{tab == 'live' && status == 'live' ? (
					<div
						className={`panel live-panel ${
							tab == 'live' ? 'selected' : ''
						}`}>
						{this.renderLiveContent(status)}
					</div>
				) : null}
						
				{tab == 'live' && status == 'post' ? (
					<div
						className={`panel live-panel ${
							tab == 'live' ? 'selected' : ''
						}`}>
						{this.renderRecapContent()}
					</div>
				) : null}
						
				{tab == 'summary' ? (
					<div className={`panel stats-panel ${tab == 'summary' ? 'selected' : ''}`}>
						{this.renderSummaryContent(status)}
					</div>
				) : null}
			</div>
		);
	}

	likelihoodToWinErrorCallback = (error, info) => {
		// logger.log(
		// 	'[SlamtrackerContent] likelihoodToWinErrorCallback - this:%o, error:%o, info:%o',
		// 	this,
		// 	error,
		// 	info
		// );

		this.setState(
			{
				powerrankError: true,
			},
			() => {
				logger.log('[SlamtrackerContent] likelihoodToWinErrorCallback - state:%o', this.state);
			}
		);
	}

	renderLikelihoodToWin() {
		let { statMatch } = this.props;

		return (
			<ErrorBoundary
				message={`${values.powerIndexTitle} is not currently available for this match`}
				klass="mi-section"
				callback={() => this.likelihoodToWinErrorCallback()}>
					{/* Likelihood To Win */}
				<LikelihoodToWin
					callback={() => this.likelihoodToWinErrorCallback()}
					nav={this.navigate}
					data={{
						...this.state.innovationsData,
						imgPath: this.props?.configOtherData?.playerProfileImagePath || '',
						flagPath: this.props?.configOtherData?.flagImagePathSmall || '',
						windowSize: this.props?.windowSize,
						infoIcon: false,
						statMatch,
					}}
				/>
			</ErrorBoundary>
		);
	}

	renderAIBox(which) {
		// logger.log('[SlamtrackerContent] renderAIBox which:%o, this:%o', which, this);
		let { statMatch } = this.props;
		let status = which == 'preview' ? 'pre' : 'post';
		let aiCatchUpPreviewPath, aiCatchUpRecapPath, data;

		// logger.log('[SlamtrackerContent] renderAIBox status:%o', status);
		/**
		 * RLL: TODO
		 * figure out the data and how it loads from the file,
		 * pass it to the component correctly, once that happens,
		 * the "if" statement below is unnecessary and only the "else"
		 * will be needed
		 */
		if(this.props?.slamtrackerConfig?.useAITestData === true) {
			if(status == 'pre') {
				data = {
					"last_name": "Sabalenka",
					"first_name": "Aryna",
					"tour_id": "wta320760",
					"watson_power_index_rank": 1,
					"likelihood_to_win":43.4,
					"opponent_tour_id":"wta000",
					"opponent_last_name":"opponent last name",
					"opponent_first_name":"opponent first name",
					"update_epoch": 1713982440000,
					"update_datetime":"2024/04/24 14:14",
					"match_id": "00000",
					"content":[  
					{"sentence":"Preseason rank: Zverev (63.18%) > Sinner (61.82%)","status":"approved"},
					{"sentence":"Sinner won 3 sets while Zverev won 2 sets in the US Open Men Singles 2023.","status":"automated"},
					{"sentence":"Zverev had more grass match wins than Sinner.","status":"automated"}
					],
					"content_status":"published",
					"event_series":"mens_singles"
				}
			} else {
				data = {
					"last_name": "Sabalenka",
					"first_name": "Aryna",
					"tour_id": "wta320760",
					"watson_power_index_rank": 1,
					"likelihood_to_win":43.4,
					"opponent_tour_id":"wta000",
					"opponent_last_name":"opponent last name",
					"opponent_first_name":"opponent first name",
					"update_epoch": 1713982440000,
					"update_datetime":"2024/04/24 14:14",
					"match_id": "00000",
					"content":[  
					  {"sentence":"Zverev from Germany is number 5 and Sinner from Italy is number 2.","status":"approved"},
					  {"sentence":"Zverev won against Goffin 19-8, with 8 aces.","status":"automated"},
					  {"sentence":"Zverev played Sinner in the round of 16 at the US Open in 2023.","status":"automated"}
					],
					"content_status":"published",
					"event_series":"mens_singles"
				}
			}
		} else {
			if(which == "preview") {
				aiCatchUpPreviewPath = this?.props?.configOtherData?.innovations?.aiCatchUpPath.replace('<status>', status).replace('<matchId>', statMatch?.match_id);
			} else {
				aiCatchUpRecapPath = this?.props?.configOtherData?.innovations?.aiCatchUpPath.replace('<status>', status).replace('<matchId>', statMatch?.match_id);
			}
		}

		
		const stResultRelatedPath = this.props?.related?.stResultRelated.replace('<matchId>', statMatch?.match_id);
		if(stResultRelatedPath && !this.state.stRelatedData && this.state.stRelatedData !== false){
			fetch(stResultRelatedPath)
				.then(result => {
					this.setState({stRelatedData: result?.content || false});
				})
				.catch(error => {
					logger.error('[SlamtrackerContent] error loading related content:%o', error);
				})
		}

		if(aiCatchUpPreviewPath && !this.state?.catchMeUpPreview && this.state.catchMeUpPreview !== false) {
			fetch(aiCatchUpPreviewPath)
				.then(result => {
					this.setState({catchMeUpPreview: result?.content.length > 0 ? result?.content : false});
				})
				.catch(error => {
					logger.error('[SlamtrackerContent] error loading AI Preview Data:%o', error);
					this.setState({catchMeUpPreview: false});
				})
		}

		if(aiCatchUpRecapPath && !this.state?.catchMeUpRecap && this.state.catchMeUpRecap !== false) {
			fetch(aiCatchUpRecapPath)
				.then(result => {
					this.setState({catchMeUpRecap: result?.content.length > 0 ? result?.content : false});
				})
				.catch(error => {
					logger.error('[SlamtrackerContent] error loading AI Recap Data:%o', error);
					this.setState({catchMeUpRecap: false});
				})
		}

		if(!aiCatchUpPreviewPath && !aiCatchUpRecapPath) {
			return <AICatchUpBox data={data} stRelatedData={this.state.stRelatedData} type={which} />
		} else {
			if(aiCatchUpPreviewPath) {
				return <AICatchUpBox data={this.state.catchMeUpPreview} stRelatedData={this.state.stRelatedData} type={which} />
			} else if(aiCatchUpRecapPath) {
				return <AICatchUpBox data={this.state.catchMeUpRecap} stRelatedData={this.state.stRelatedData} type={which} />
			}
		}
	}

	/**
	 * 
	 * @param {String} measureAction 
	 * @param {String} measure_args 
	 * @param {Array} context 
	 * @param {Strig} metricType -  "action"- default value and this is for clicks, "state" for page view
	 */
	measureIndex = (measureAction, measure_args, context = [], metricType = "action") => {
		if (window.location.href.indexOf('suite') == -1) {
			context.push({ slamtrackerMatchID: this.props?.matchId });

			if (!values.webview) {
				context.push({ pageTitle: 'Slamtracker' });
				measureInWeb({
					action: measureAction,
					context: context,
				});
			} else {
				/** webview page doesn't want Default Tab value sent. Clear the data here */
				if(measureAction == "Default Tab") {
					measureAction = ""
				}
				measureInAppContext({
					pageTitle: 'Slamtracker',
					action: measureAction,
					args: measure_args,
					context: context,
					metricType
				});
			}
		}
	}

	render() {
	 	// logger.log('[SlamtrackerContent] render - this:%o', this);

		//this.props.windowSize == 'mobile' ? subheader_attributes = null : null;

		let { statMatch } = this.props;
		let flagImagePath = this.props?.configOtherData?.flagImagePathSmall;
		
		// logger.log('[SlamtrackerContent] render - statMatch:%o', statMatch);
    
		if (this.props?.stubs && this.props?.stubs.slamtracker.stub === 'stub') {
			return (
				<div className="content-main">
					<StubBox attributes={{ message: this.props?.stubs.slamtracker.text }} />
				</div>
			);
		} else if (
			/**
			 * should hit this condition for matches that are not live matches and
			 * are missing completed match data or point history data
			 **/
			this.props?.stubs &&
			this.props?.stubs.slamtracker.stub !== 'stub' &&
			!this.currentMatch &&
			this.state.hasError
		) {
			return (
				<div className="content-main">
					<StubBox attributes={{ message: this.props?.stubs.slamtracker.nodata }} />
				</div>
			);
		} else if (statMatch && statMatch.match_id && !isUndefined(this.props?.windowSize)) {
			let _this = this;

			let foundMatch = op.get(this.props, 'liveMatches', []).find(function(match) {
				return match.match_id == _this.props?.matchId;
			});

			return (
				<div className="content-main">
					{this.props?.slamtrackerConfig?.showMatchList && this.props?.windowSize != 'mobile' && this.props?.windowSize != 'tablet' && !this.isPanel ? (
						<div className="content-allmatches">
							{/* <ErrorBoundary message="slamtracker">
								<SlamtrackerMatches />
							</ErrorBoundary> */}
							<div>Slamtracker Matches</div>
						</div>
					) : null}
					<div className="slamtracker-content">
						{this.props?.windowSize == 'mobile' || this.isPanel ? (
							<>
								<div className="content-filters-scroll" />
								<ErrorBoundary message="Error in Header">
									<Header
										statMatch={statMatch}
										currentTab={this.state?.tab}
										flagImagePath={flagImagePath}
										liveMatches={this.props?.liveMatches}
										windowSize={this.props?.windowSize}
									/>
								</ErrorBoundary>
							</>
						) : null}

						<ErrorBoundary message="Error in Tabs">
							<Tabs 
								tab={this.state?.tab}
								setPageViews={this.setPageViews}
								measureIndex={this.measureIndex}
							/>
						</ErrorBoundary>
						{this.props?.windowSize == 'mobile' ? <div className="content-filters-spacer" /> : null}
						{statMatch.match_id == this.props?.matchId ? (
							this.renderContentArea()
						) : (
							<div className="loading-indicator">
								<div className="large progress">
									<div>Loading…</div>
								</div>
							</div>
						)}
					</div>
					{this.state.scoringMode ? (
						<div className="connection-type">{this.state.scoringMode.polling ? '..' : '.'}</div>
					) : null}
				</div>
			);
		} else {
			return (
				<div className="content-main">
					<LoadingIndicator type="white" />
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamtrackerContent);

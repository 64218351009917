/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import HeadtoHeadTournamentStats from 'appdir/components/pages/Slamtracker/elements/preview/HeadtoHeadTournamentStats';
import HeadtoHeadYTDStats from 'appdir/components/pages/Slamtracker/elements/preview/HeadtoHeadYTDStats';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: YTDStats
 * -----------------------------------------------------------------------------
 */
const HeadtoHeadStats = props => {

    return (
        <div className="stats">
            <HeadtoHeadTournamentStats />
            <HeadtoHeadYTDStats />
        </div>
    )
};

export default HeadtoHeadStats;
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SiteMap from 'appdir/components/general/SiteMap';

import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';

/**
 * -----------------------------------------------------------------------------
 * React Component: MyWimbledonReset
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class MyWimbledonReset extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {}

	render() {
		logger.log('[MyWimbledonReset] render');

		let header_propsData = {
			headerType: 'mywimbledon',
			title: 'myWimbledon',
			shortTitle: 'myW',
			metaTitle: 'myWimbledon',
			metaDescription: 'myWimbledon - Wimbledon ' + values.tournamentYear,
			metaDate: '',
			metaPlayers: '',
		};

		// let gigyaParams = {
		// 	"screenSet": 'simple-screen-set',
		// 	"startScreen": 'edit-profile'
		// }

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main column-layout news">
					<div className="four-col byline ">
						<div className="byline--attribution" />
					</div>
					<div className="two-col textWrapContent margin">
						<p />
						<GigyaScreenSet params={{ screen: 'reset_password' }} inline={true} />
					</div>
				</div>

				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWimbledonReset);

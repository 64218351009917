/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getComponents, getAttributesArray, getComponentListArray, validateData } from 'appdir/components/general/Util';
import { loggedIn, checkRole } from 'appdir/components/general/Util/Role';
import { appendEncodedString } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SiteMap from 'appdir/components/general/SiteMap';
import LoginWrapper from './LoginWrapper';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

import { PlayerDigitalExperienceContext } from './context';
import PlayerPrizeMoney from './elements/PlayerPrizeMoney';
import PlayerMatchStats from './elements/PlayerMatchStats';
import PlayerCatering from './elements/PlayerCatering';
import PlayerStringing from './elements/PlayerStringing';
import DelegateSelect from './elements/DelegateSelect';
import PlayerTransport from './elements/PlayerTransport';
import PlayerDelegate from './elements/PlayerDelegate';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['PlayerDigitalExperience'],
		stubs: op.get(state['Config'].stubPages, 'pde', null),
		pdePaths: op.get(state['Config'].pde, 'api', null),
		pdeConfig: op.get(state['Config'], 'pde', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
	unmount: () => dispatch(deps.actions.PlayerDigitalExperience.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	setPlayer: data => dispatch(deps.actions.PlayerDigitalExperience.setPlayer(data)),
});

class PlayerDigitalExperience extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
		this.fetchData = true;
		this.lastDataPath = '';
		this.pageViewed = false;
		logger.log('[PDE] constructor - state:%o', this.state);
	}

	componentDidMount() {
		logger.log('[PDE] componentDidMount');
	}

	componentWillUnmount() {
		logger.log('[PDE] componentWillUnmount');
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[PDE] componentDidUpdate - state:%o', this.state);
		if (this.state.loggedIn != prevState.loggedIn && op.get(this.state, 'pde.page', '') == 'match-stats') {
			let current_url = window.location.protocol + '//' + window.location.host + window.location.pathname;
			window.history.pushState({}, '', current_url);
			let roles = op.get(this.state, 'currentUser.roles', []).filter(d => d.roleId == this.state.roleIds[0]);
			this.props.setPlayer(op.get(roles[0], 'linkId', null));
		}
	}

	/**
	 * get the component for the requested page
	 */
	getPageElement(page) {
		switch (page) {
			case 'test':
				return 'test page';
			case 'prize-money':
				return (
					<PlayerPrizeMoney
						submitURL={appendEncodedString(
							this.state.dataUrl[1],
							op.get(this.state, 'currentUser.UID', '')
						)}
						dataUrl={appendEncodedString(this.state.dataUrl, op.get(this.state, 'currentUser.UID', ''))}
						//stub={this.props.location.search !== '' ? true : false}
						stub={false}
					/>
				);
				break;
			case 'match-stats':
				return <PlayerMatchStats paths={this.state.pdePaths} roleIds={op.get(this.state, 'roleIds', [])} />;
			case 'catering':
				return (
					<PlayerCatering
						dataUrl={appendEncodedString(this.state.dataUrl, op.get(this.state, 'currentUser.UID', ''))}
						key={'pde-catering'}
					/>
				);
				break;
			case 'stringing':
				return (
					<PlayerStringing
						dataUrl={appendEncodedString(this.state.dataUrl, op.get(this.state, 'currentUser.UID', ''))}
						key={'pde-stringing'}
					/>
				);
				break;
			case 'delegate-select':
				return (
					<DelegateSelect
						paths={this.state.pdePaths}
						dataUrl={this.state.dataUrl}
						key={'pde-delegate-select'}
					/>
				);
				break;
			case 'delegates':
				return <PlayerDelegate paths={this.state.pdePaths} dataUrl={this.state.dataUrl} key={'pde-delegate'} />;
				break;
			case 'transport':
				return (
					<PlayerTransport
						dataUrl={appendEncodedString(this.state.dataUrl, op.get(this.state, 'currentUser.UID', ''))}
						key={'pde-transport'}
					/>
				);
				break;
			default:
				return (
					<div className="four-col">
						<SiteMap notFound={true} />
						<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
					</div>
				);
				break;
		}
		return null;
	}

	getTitle() {
		let title = 'PDE';
		let pdeType = op.get(this.state, 'pde.page', '');
		switch (pdeType) {
			case 'stringing':
				title = 'Racket Stringing';
				break;
			case 'transport':
				title = 'Transport';
				break;
			case 'catering':
				title = 'Food Allowance';
				break;
			case 'prize-money':
				title = 'Prize Money';
				break;
			case 'match-stats':
				title = 'Match Statistics and Video';
				break;
			case 'delegate-select':
				title = 'Player Management';
				break;
			case 'delegates':
				title = 'Delegate Management';
				break;
			default:
				title = 'Players';
				break;
		}
		if (pdeType && !this.pageViewed) {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: `PDE ${title}`,
			});
			this.pageViewed = true;
		}
		return title;
	}

	render() {
		// logger.log('[PDE] render - state:%o', this.state);

		let page = op.get(this.state, 'pde.page', false);
		let currentUser = op.get(this.state, 'currentUser', null);
		let roleValidated = checkRole(op.get(currentUser, 'roles', []), op.get(this.state, 'roleIds', []), true);
		let isDelegate = checkRole(op.get(currentUser, 'roles', []), [1000280], true); //check if the user is a delegate
		const shadowingAccess = op.get(currentUser, 'data.systemRole.player.isShadowingAccess', false);
		let style = {
			style: 'four-col',
		};

		let header_propsData = {
			headerType: 'generic_register',
			title: 'Players',
			shortTitle: 'Players',
			metaTitle: 'Players',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		};

		let contextValues = {
			config: op.get(this.state, 'pde.config', null),
			stubs: op.get(this.state, 'stubs', null),
			currentUser: op.get(this.state, 'currentUser', null),
			loggedIn: op.get(this.state, 'loggedIn', null),
			pdeConfig: op.get(this.state, 'pdeConfig', null),
		};

		logger.log('[PDE] render - contextValues:%o', contextValues);
		logger.log('[PDE] render - roleValidated:%o, isDelegate:%o', roleValidated, isDelegate);

		if (
			((currentUser.UID && roleValidated && page != 'delegate-select') ||
				(currentUser.UID && isDelegate && page == 'delegate-select') ||
				(currentUser.UID && isDelegate && shadowingAccess && page == 'delegates')) &&
			page
		) {
			header_propsData = {
				headerType: 'generic_register',
				title: this.getTitle(),
				shortTitle: this.getTitle(),
				metaTitle: this.getTitle(),
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
				scrollElem: '.content-main',
				titleElem: '.news--header',
				skipContent: true,
			};
		} else {
			header_propsData = {
				headerType: 'generic_register',
				title: 'Sitemap',
				shortTitle: 'Sitemap',
				metaTitle: 'Page not found',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
				scrollElem: '.content-main',
				titleElem: '.news--header',
				skipContent: true,
			};
		}

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main content player-digital-experience" id="main" tabIndex="0">
					<PlayerDigitalExperienceContext.Provider value={contextValues}>
						{/* businss feedback doesn't like this UI <TabNavigation data={{ key: 'playerLanding' }} /> */}
						<div className="column-layout content">
							{!this.state.loggedIn && this.state.gigyaLoaded ? (
								<Fragment>
									<div className="four-col body">
										Please login or register with <MyWimbledonTitle /> to continue.
										<br />
										<br />
									</div>
									<div className="four-col bottom-margin">
										<LoginWrapper />
									</div>
								</Fragment>
							) : (currentUser.UID && !roleValidated && !isDelegate && roleValidated != undefined) ||
							  (currentUser.UID && !isDelegate && page == 'delegate-select') ||
							  (isDelegate && !shadowingAccess && page == 'delegates') ? (
								<div className="four-col">
									<SiteMap notFound={true} />
									<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
								</div>
							) : (currentUser.UID && roleValidated && page) ||
							  (currentUser.UID && isDelegate && page == 'delegate-select') ? (
								<Fragment>{this.getPageElement(page)}</Fragment>
							) : (
								<Fragment>
									<LoadingIndicator />
								</Fragment>
							)}
						</div>
					</PlayerDigitalExperienceContext.Provider>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerDigitalExperience);

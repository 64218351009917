import LiveVideoHill from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: '/:lang/interactive/:channel(hill).html',
		exact: true,
		component: LiveVideoHill,
		load: params => deps.actions.LiveVideoHill.mount(params),
	},
];

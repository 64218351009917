import React, { useState, useEffect } from 'react';
import useRoles from './useRoles';
import RoleIcons from 'appdir/components/cms/RoleIcons';
import { useSelector } from 'react-redux';
import SkipContent from 'appdir/components/general/SkipContent';
import MediaReconsent from 'appdir/components/pages/MediaRequest/MediaReconsent';

const RoleNavigation = () => {
	const { rolesWithTitles: roles } = useRoles();
	const state = useSelector(state => state);
	const homeShelfRoles = roles
		.sort((a, b) => state.Config.myWIcons.titles[a.key]?.sort - state.Config.myWIcons.titles[b.key]?.sort)
		?.filter(role => {
			let validHomeShelfRole = false;
			state.Config.myWIcons[role.keySegment]?.map(roleService => {
				if (roleService.show.includes('home')) {
					validHomeShelfRole = true;
				}
			});
			return validHomeShelfRole;
		});

	const [activeRole, setActiveRole] = useState();
	const [roleIconsFocused, setRoleIconsFocused] = useState(false);

	useEffect(() => {
		setActiveRole(roles[0]);
	}, [roles.length]);

	let indexRole = homeShelfRoles?.findIndex(object => {
		return object.name === activeRole?.name;
	});
	let focusRoleId = indexRole !== homeShelfRoles.length - 1 ? homeShelfRoles[indexRole + 1]?.name : false;

	return (
		<div className="iconShelf tabs" tabIndex={0} aria-label="entering role icons section">
			<SkipContent
				genericSkipContent="#homepage-filter-buttons"
				genericSkipTxt="skip to filter timeline section"
			/>

			{!activeRole?.reconsent ? (
				<ul className="tab-list" role="list">
					{homeShelfRoles?.map((role, index) => {
						return (
							<button
								type="button"
								key={role?.name}
								id={role?.name}
								className={`service-icon-label ${role?.name === activeRole?.name && 'active-tab'}`}
								aria-label={`${
									role?.name
								} shortcut in homepage role navigation tab list, button ${index + 1} out of ${
									homeShelfRoles?.length
								} buttons in group`}
								tabIndex={role?.name === activeRole?.name && !roleIconsFocused ? 0 : -1}
								onClick={() => {
									setActiveRole(role);
									setRoleIconsFocused(false);
								}}>
								{role?.name?.toUpperCase()}
							</button>
						);
					})}
				</ul>
			) : (
				<MediaReconsent /> // show reconsent page if user needs to reconsent
			)}
			<div className="services-list-wrapper" style={{ display: !activeRole?.reconsent ? 'flex' : 'none' }}>
				{homeShelfRoles.map(d => {
					//logger.info('[RoleNavigation] activeRole:%o d:%o', activeRole, d);
					return (
						<RoleIcons
							role={d.key}
							type="home"
							key={d.key}
							activeRole={activeRole?.key?.toLowerCase()}
							onRoleFocused={val => {
								setRoleIconsFocused(val);
								if (val && focusRoleId) {
									// focus next main tab after running through all sub tabs
									document.getElementById(focusRoleId).focus();
								}
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default RoleNavigation;

/**
 * Imports
 */
import React, { Component } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';

/**
 * Class Component: PhotoPromo
 **/

const externalLinkRegex = /^(http|https)/;

const PhotoPromo = props => {

	logger.log('[PhotoPromo] props:%o', props);
	let {data} = props;
	return (
		<div
			style={{
				backgroundImage: `url(${data.image?.url})`,
				backgroundPosition: 'center center',
				backgroundSize: 'cover',
			}}
			className={`photo-promo ${data.padded == 'true' ? 'padded' : 'full-width'}`}>
			<WimLink to={data.link} style="white" title={data.image?.caption}>
				<img style={{ opacity: 0 }} src={`${data.image?.url}`} tabIndex={-1} />
			</WimLink>
			{
				<div className="photo-promo-text">
					<WimLink to={data.link} style="white" title={data.title}>
						<div className="photo-promo-text-title">{data.title}</div>
					</WimLink>
					<div className="photo-promo-text-description">
						<WimLink to={data.link} style="white" title={data.description}>
							{data.description}
						</WimLink>
					</div>
					{
						data.button == "true" && <div className='photo-promo-button' tabIndex={-1}>
							<Button to={data.link} className="outlineWhite">
								{data.buttonText}
							</Button>
						</div>
					}
				</div>
			}
		</div>
	);
}

export default PhotoPromo;

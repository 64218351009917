import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[WatchReadPage] action.mount - data:%o', data);

		// Mount dispatch
		//dispatch({type: deps.actionTypes.ARTICLE_MOUNT, data: data});
		if (data.hasOwnProperty('id')) {
			logger.log('[WatchReadPage] action.mount - ensureConfig');
			return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
				let matchBoxPath = Config.scoringData.matchScore.path;
				let relatedContentPath = Config.relatedContent.matchRelated;
				let pathObj = { matchBoxPath: matchBoxPath, relatedContentPath: relatedContentPath };
				logger.log('[WatchReadPage] action.mount - fetch: %o', JSON.stringify(pathObj));
				dispatch({ type: deps.actionTypes.WATCHREAD_LOAD, data: pathObj });
			});
		}
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import StorytellerElem from './StorytellerElem';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Storyteller (wrapper to prevent rerenders)
 * -----------------------------------------------------------------------------
 */

 const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) ||
  		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)


class Storyteller extends Component {
	constructor(props) {
		super(props);
		this.id = this.props.id || this.getId(5);
		logger.info('[Storyteller] constructor id:%o props:%o', this.id, this.props);
	}

	getId(length) {
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return result;
	}

	
	render() {
		logger.log('[Storyteller] render1 - id:%o isMobileOnly:%o isIOS:%o props:%o', this.id, isMobileOnly, isIOS, this.props);

		if (isMobileOnly){
			return (
				<StorytellerElem key={this.id} id={this.id} {...this.props}/>
			);
		}
		else {
			return null;
		}
		
	}
}
export default Storyteller;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Icon
 * -----------------------------------------------------------------------------
 *
 * @param state
 * @param state.name {String} The icon name.
 * @param state.style {Object} CSS styles to apply to the component.
 * @param state.size Valid CSS font-size value. The font size of the icon. Default: 22. Note: if you set fontSize in state.style, it will be overwritten with this value.
 * @param state.color Valid CSS color value. Default: #FFFFFF. Note: if you set color in state.style, it will be overwritten with this value.
 *
 * @return {*}
 * @constructor
 */
const Icon = state => {
	let cls = state.hasOwnProperty('name') ? [`icon-${state.name}`] : [];

	let style = state.hasOwnProperty('style') ? state.style : {};
	style['fontSize'] = state.hasOwnProperty('size') ? state.size : '14px';
	style['color'] = state.hasOwnProperty('color') ? state.color : '#FFFFFF';

	return <i className={cls.join(' ')} style={style} />;
};

/**
 * -----------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------
 */
export default Icon;

import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: data => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Player Statistics',
		});

		logger.log('[PlayerStatsPage] action.mount - data:%o', data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let path = Config.scoringData.extraStats;
			dispatch({ type: deps.actionTypes.PLAYERSTATSPAGE_LOAD, data: path });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.PLAYERSTATSPAGE_FILTER, data: data });
	},
};

import GalleryPage from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:lang/:type/galleries/:date?/:id.html',
		exact: true,
		component: GalleryPage,
		load: params => deps.actions.GalleryPage.mount(params),
	},
	{
		path: '/:lang/news/photos/:photoid.html',
		exact: true,
		component: GalleryPage,
		load: params => deps.actions.GalleryPage.mount(params),
	},
	{
		path: '/:lang/player/photos/:playerid.html',
		exact: true,
		component: GalleryPage,
		load: params => deps.actions.GalleryPage.mount(params),
	},
];

import React, { Component, Fragment } from 'react';
import { RoleRegistrationInviteContext } from '../context';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormInput, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';

class Generic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.handleSubmit = this.handleSubmit.bind(this);

		this.initialValues = {
			PlayerPolicy: false,
			accreditTerms: false,
		};

		logger.log('[RoleRegistrationInvite - Generic] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log(
			'[RoleRegistrationInvite - Generic] componentDidMount - state:%o context:%o',
			this.state,
			this.context
		);
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[RoleRegistrationInvite - Generic] componentDidUpdate - state:%o context:%o',
			this.state,
			this.context
		);
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {};

		return yup.object(schema);
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[RoleRegistrationInvite - Generic] handleSubmit - state:%o', values);
		this.addRole();
	}

	addRole() {
		if (this.context.addRole) {
			logger.log('[RoleRegistrationInvite - Generic] addRole');
			this.context.addRole();
		}
	}

	render() {
		logger.log('[RoleRegistrationInvite - Generic] render - state:%o status:%o', this.state, this.state.status);

		let validationSchema = this.getSchema();

		return (
			<Fragment>
				{this.context.complete ? (
					<div className="four-col body">
						Your profile has been updated. Please visit{' '}
						<WimLink to="/en_GB/mywimbledon/">myWimbledon</WimLink> to explore and manage benefits.
					</div>
				) : this.context.hasRole ? (
					<div className="four-col body">
						Our records indicate your myWimbledon account is already assigned this role.
					</div>
				) : !this.context.isValid ? (
					<div className="four-col body">
						Unfortunately your registration code is invalid or has expired.
						<br />
						<br />
						Please try again or contact your AELTC representative to request a new link.
					</div>
				) : (
					<Formik
						initialValues={this.initialValues}
						validationSchema={validationSchema}
						onSubmit={this.handleSubmit}
						enableReinitialize={true}>
						{formProps => {
							return (
								<Fragment>
									<div className="four-col body">You are adding a {this.props.role} role.</div>
									<div className="four-col body">
										<Button className={`btn solid`} onClick={formProps.submitForm}>
											SUBMIT
										</Button>
									</div>
								</Fragment>
							);
						}}
					</Formik>
				)}
			</Fragment>
		);
	}
}
Generic.contextType = RoleRegistrationInviteContext;
export default Generic;

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

/**
  get the final list of generated player data to display
  based on ordered data, favourites, etc..
 */
 export const getPlayerGenList = (config, data, favourites, country = '') => {
	const TIME_MULT = 10;
	const RANK_MULT = 2;
	const RANK_LOG_BASE = 10;
	const COUNTRY_MULT = 3;
	
	let displayList = [];

	//trim the list based on cutoff time
	data = data.filter(card => {
		let cutoff = moment().subtract(config.cutoff || 0, 'seconds');
		//logger.log('[Tournament-Util] getPlayerGenList - card:%o cutoff:%o', moment(card.updateTime).format('DD MMM hh:mm:ss'), cutoff.format('DD MMM HH:mm:ss'));
		//logger.log('[Tournament-Util] getPlayerGenList - less:%o', card.updateTime < cutoff.valueOf());
		return card.updateTime > cutoff.valueOf();
	});
	//logger.log('[Tournament-Util] getPlayerGenList - length:%s', data.length);

	//sort by time to get first and last updated time
	data.sort((a,b) => {
		return (a.updateTime < b.updateTime) ? 1 : (a.updateTime > b.updateTime) ? -1 : 0;
	});
	logger.log('[Tournament-Util] getPlayerGenList - config:%o data:%o favs:%o', config, data, favourites);

	const LAST_TIME = data[0].updateTime;
	const FIRST_TIME = data[data.length - 1].updateTime;
	const TIME_DIFF = LAST_TIME - FIRST_TIME;

	logger.log('[Tournament-Util] getPlayerGenList - first:%o last:%o', FIRST_TIME, LAST_TIME);

	//calculate "time" for each player
	//  1. get adjusted time
	//  2. get adjusted singles rank (or use other value for rank?)
	//  3. get country or editorial adjustements?
	//  4. add values for a "timeRank"
	data.forEach((value, idx) => {
		//let rank = value.singlesRank;
		let rank = value.powerRank;

		// formatted time for debugging
		value.formatTime = moment(value.updateTime).format('DD MMM hh:mm:ss')

		//get adjusted time
		//let adjustedTime = (value.updateTime - offsetTime) / (timeGroup * 3600);
		let adjustedTime = ((value.updateTime - FIRST_TIME) / TIME_DIFF) * TIME_MULT;

		//get adjusted rank
		let adjustedRank = (Math.log(1000/rank) / Math.log(RANK_LOG_BASE)) * RANK_MULT;
		
		//get adjusted country
		let adjustedCountry = (value.country.toUpperCase() === country.toUpperCase()) ? COUNTRY_MULT : 0;
		if (adjustedCountry > 0) value.source = 'country';
		
		//logger.log('[Tournament-Util] getPlayerGenList - time rank: %s ', value.lastName + ' ' + adjustedTime + ' ' + adjustedRank);

		value.timeRank = (adjustedTime + adjustedRank + adjustedCountry);
	});

	//sort list by timeRank
	data.sort((a,b) => {
		return (a.timeRank < b.timeRank) ? 1 : (a.timeRank > b.timeRank) ? -1 : 0;
	});

	//move favourites, up to 20, to displayList
	let totalFavs = 0;
	let data2 = []
	data.forEach((value, idx) => {
		if (favourites == 'all') {
			totalFavs++;
			let item = {...data[idx]};
			item.source = 'favourite';
			displayList.push(item);
			value.source = 'favourite'; //for debugging
		}
		else if (totalFavs < 20 && favourites.includes(value.id)) {
			totalFavs++;
			let item = {...data[idx]};
			item.source = 'favourite';
			displayList.push(item);
			value.source = 'favourite'; //for debugging
		}
		else {
			data2.push(data[idx])
		}
	});

	//get next highest ranked 8 players
	data2.forEach((value, idx) => {
		if (idx < 8){
			let item = {...value};

			//check reason, country or topRecent
			// if player was in top ~12 because of countryBoost, assume there because of country
			if (value.source == 'country') {
				let oldRank = value.timeRank - COUNTRY_MULT;
				if (oldRank > data[12].timeRank) {
					item.source = 'topRecent';
				}
			}
			else {
				item.source = 'topRecent';
			}

			displayList.push(item)
			value.source = item.source; //for debugging
		}
	});

	logger.table('[Tournament-Util] getPlayerGenList:', data, ['tvName', 'id', 'country', 'singlesRank', 'powerRank', 'formatTime', 'timeRank', 'source']);

	//sort displayList by timeRank
	displayList.sort((a,b) => {
		return (a.timeRank < b.timeRank) ? 1 : (a.timeRank > b.timeRank) ? -1 : 0;
	})

	logger.table('[Tournament-Util] getPlayerGenList:', displayList, ['tvName', 'id', 'country', 'singlesRank', 'powerRank', 'formatTime', 'timeRank', 'source']);

	//logger.log('[Tournament-Util] getPlayerGenList - displayList:%o data:%o', displayList, data);
	return displayList;
 }
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import LazyLoad from 'react-lazy-load';
import LazyPlaceholder from 'appdir/components/content/LazyPlaceholder';

/**
 * -----------------------------------------------------------------------------
 * React Component: Headshots
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		tourn: state['Tournament'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	getPlayerLookup: () => dispatch(deps.actions.Tournament.getPlayerLookup()),
	getPlayerList: () => dispatch(deps.actions.Tournament.getPlayerList())
});

class Headshots extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...props,
			loadData: ''
		};

		this.type = '';
		this.lazyOffset = 0;
		logger.log('[Headshots] constructor - state:%o', this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// let newState = null;

		// logger.log('[Headshots] getDerivedStateFromProps - nextProps:%o', nextProps);
		// if (prevState.loadData == '' && nextProps.tourn?.paths?.players) {
		// 	newState = {
		// 		loadData: 'load'
		// 	}
		// }
		logger.log('[Headshots] getDerivedStateFromProps - prevState:%o', prevState);
		logger.log('[Headshots] getDerivedStateFromProps - nextProps:%o', nextProps);

		if (nextProps.tourn.paths && prevState.loadData == '') {
			nextProps.getPlayerList();
			//nextProps.getPlayerLookup();
			return {
				loadData: 'load'
			};
		}
		else if (nextProps?.tourn?.data?.players) {
			let newState = {
				...prevState,
				...nextProps
			}
			return newState
		}

		// Return null to indicate no change to state.
		return null;
	}

	componentDidMount() {
		
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[Headshots] componentDidUpdate - state:%o', this.state);

		// if (this.state.loadData == 'load') {
		// 	this.props.getPlayerList();
		// 	this.props.getPlayerLookup();

		// 	this.setState(prevState => {
		// 		return { 
		// 			...prevState, 
		// 			loadData: "loading"
		// 		};
		// 	});
		// }
	}

	

	getHeadshotsTable() {
		let players = this.state?.tourn?.data?.players?.data || [];

		let imgBgStyle = {
			"background-image":"url('https://www-cdt.wimbledon.com/assets/images/tickets/test_h_BlueAndWhiteFlowers_No1Court.png')", 
			"background-size": "cover",
			"margin-left":"20px"
		}
		let bgStyle = {
			"margin-left":"20px"
		}
		let imgSize = 250;


		return (
			<table className="tournament-table">
				<thead>
					<tr>
						<td>Id</td>
						<td>Last Name</td>
						<td>First Name</td>
						<td>Country</td>
					</tr>
				</thead>
				 <tbody>
					{players.map(elem => {
						//logger.log('[Headshots]getMatchTable - elem:%o', elem);
						return (
							<>
								<tr key={`player_${elem.id}`}>
									<td>{elem.id}</td>
									<td>{elem.last_name}</td>
									<td>{elem.first_name}</td>
									<td>{elem.country}</td>
								</tr>
								<tr key={`player_${elem.id}_images`}>
									<td colSpan="4">
										<LazyLoad
											once
											offset={this.lazyOffset}
											scroll={true}
											resize={true}
											key={`player_${elem.id}_images`}
											placeholder={<LazyPlaceholder />}>
												<>
												<img src={`https://images.wimbledon.com/square/${elem.id}.jpg`} width={imgSize} height={imgSize}/>
												<img src={`https://images.wimbledon.com/square_nobg/${elem.id}.png`} width={imgSize} height={imgSize} style={bgStyle}/>
												<img src={`https://images.wimbledon.com/wide_nobg/${elem.id}.png`} height={imgSize} style={imgBgStyle}/>
												</>
										</LazyLoad>
									</td>
								</tr>
							</>
						);
					})}
				</tbody>
			</table>
		);
	}

	render() {
		//logger.log('[Headshots]render - props:%o', this.props);
		//logger.log('[Headshots]render - state:%o', this.state);

		return (
			<div className="content-main content ">
				<div className="column-layout content">
					<div className="four-col">
						{this.getHeadshotsTable()}
					
					</div>
					
				</div>				
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Headshots);

import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[TicketResaleWebview] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[TicketResaleWebview] action.mount - data: %o', data);

			let data = {
				resale: {
					...params,
				},
			};

			logger.log('[TicketResaleWebview] action.mount - data:%o', data);
			dispatch({
				type: deps.actionTypes.TICKETRESALEWEBVIEW_MOUNT,
				data: data,
			});
		});
	},
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import classNames from 'classnames';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['PlayerStatsPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.PlayerStatsPage.filter(settings)),
});

class PlayerStatsSelect extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
		this.setStats = this.setStats.bind(this);
		this.onOpen = this.onOpen.bind(this);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	setStats(stat) {
		MeasurementUtils.dispatchMeasurementCall('statsDropdown', {
			stat,
		});
		this.props.filter({
			playerStats: stat,
			open: '',
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		let selectData = {
			options: [{ name: 'Stats', value: 'stats' }],
		};
		selectData.options = selectData.options.concat(this.state.selectData);
		return (
			<div className="scores-select-menu">
				<SelectMenu
					name="playerStats"
					attributes={selectData}
					selected={this.state.filters.playerStats}
					show={this.state.filters.open == 'playerStats'}
					onSelected={this.setStats}
					onOpen={this.onOpen}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatsSelect);

/**
 Slamtracker Initial State
*/

export default {
	slamtrackerStatsLayout: 'expanded',
	filters: {
		open: '',
	},
	momentumLoaded: false,
	momentumPreferences: {
		zoomLevel: 'match',
		set: '1',
		game: '1',
		showSettings: false,
		settings: {
			All: false,
			Ace: true,
			Winner: true,
			BreakPointWon: true,
			DoubleFault: false,
			UnforcedError: false,
			Expand: false,
		},
	},
	expandedPoints: {},
	highlights: {},
};

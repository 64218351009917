import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Draws Archive Events',
		});

		//logger.log('[DrawsArchiveEvents] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				year: Config.drawsArchive.year,
				host: Config.drawsArchive.host,
				playerProfileImagePath: Config.otherData.playerProfileImagePath,
				flagImagePathLarge: Config.otherData.flagImagePathLarge,
			};
			logger.log('[DrawsArchiveEvents] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.DRAWSARCHIVE_EVENTS_LOAD, data: data });
		});
	},

	loadAllEvents: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_EVENTS_ALL_EVENTS_LOAD, data: data });
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_EVENTS_FILTER, data: data });
	},

	setEvent: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_EVENTS_SET_EVENT, data: data });
	},

	unmount: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_UNMOUNT, data: data });
	},
};

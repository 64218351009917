import React from 'react';
import op from 'object-path';

const RoundNav = props => {
	// logger.log('[RoundNav] props:%o', props);

	const changeRound = which => {
		// logger.log('[RoundNav] changeRound - which:%o', which);
		props.onRoundChange(which);
	};

	return (
		<div className="four-col round-nav">
			{props.data.map(d => {
				if (d.roundNameShort != 'RR') {
					return (
						<div
							className={`round-nav-link${
								op.get(props, 'currentRound.roundCode', false) &&
								props.currentRound.roundCode === d.roundCode
									? ' current'
									: ''
							}${
								op.get(props, 'selectedRound', false) && props.selectedRound === d.roundCode
									? ' selected'
									: ''
							}`}
							onClick={() => {
								changeRound(d.roundCode);
							}}
							key={`round-link-${d.roundNameShort}`}>
							{d.roundNameShort}
						</div>
					);
				} else {
					return null;
				}
			})}
		</div>
	);
};

export default RoundNav;

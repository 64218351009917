import MatchInsightsIndexWebview from './index';
import deps from 'dependencies';

export default [
	{
		path: [
			'/webview/:lang(en_GB)/matchinsights/index.html',
			'/webview/:lang(en_GB)/matchinsights/:matchType/day:tournDay.html',
		],
		exact: true,
		component: MatchInsightsIndexWebview,
		load: params => deps.actions.InsightsIndex.mount(params),
	},
];

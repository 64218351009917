import { logger } from '../logger';
import CaptionConstants from './CaptionConstants';
import Cea608Channel from './Cea608Channel';

export default function Cea608Parser({field, out1, out2}) {
	//logger.log('Cea608Parser - field:%o', field);
	
	var rowsLowCh1 = CaptionConstants.rowsLowCh1;
	var rowsLowCh2 = CaptionConstants.rowsLowCh2;
	var rowsHighCh1 = CaptionConstants.rowsHighCh1;
	var rowsHighCh2 = CaptionConstants.rowsHighCh2;

	var dataCounters = {'padding' : 0, 'char' : 0, 'cmd' : 0, 'other' : 0};
	var field = field || 1;
	var currChNr = -1; // Will be 1 or 2
	var lastCmdA = null; // First byte of last command
	var lastCmdB = null; // Second byte of last command
	var bufferedData = [];
	var startTime = null;
	var lastTime = null;
	var channels = [Cea608Channel({channelNumber:1, outputFilter:out1}), Cea608Channel({channelNumber:2, outputFilter:out2})];

	return Object.freeze({
		addData, 
		reset
	})


	function addData (t, byteList) {
		//logger.log('Cea608Parser - addData t:%o bytelist:%o', t, byteList);

		let cmdFound, a, b;
		let charsFound = false;
		
		lastTime = t;
		//logger.info('addData - time:%o bytes:%o', t, byteList);

		for (let i = 0 ; i < byteList.length ; i+=2) {
			// remove the parity bits
			a = byteList[i] & 0x7f;
			b = byteList[i+1] & 0x7f;

			if (a === 0 && b === 0) {
				dataCounters.padding += 2;
				continue;
			} 

			cmdFound = parseCmd(t, a, b);
			if (!cmdFound) {
				cmdFound = parseMidrow(a, b);
			}
			if (!cmdFound) {
				cmdFound = parsePAC(a, b);
			}
			if (!cmdFound) {
				cmdFound = parseBackgroundAttributes(a, b);
			}

			if (!cmdFound) {
				charsFound = parseChars(a, b);

				//continue;

				if (charsFound) {
					if (currChNr && currChNr >=0) {
						var channel = channels[currChNr-1];
						channel.insertChars(lastTime, charsFound);
					} else {
						logger.warn("No channel found yet. TEXT-MODE?");
					}
				}
			}
			if (cmdFound) {
				dataCounters.cmd += 2;
			} else if (charsFound) {
				dataCounters.char += 2;
			} else {
				dataCounters.other += 2;
				logger.warn("Couldn't parse cleaned data " + numArrayToHexArray([a, b]) +
							" orig: " + numArrayToHexArray([byteList[i], byteList[i+1]]));
			}
		}
	}
	
	/**
	 * Parse Command.
	 * @returns {Boolean} Tells if a command was found
	 */
	function parseCmd(t, a, b) {
		//logger.info('parseCmd - a:%o b:%o 14:%o 1c:%o 17:%o 1F:%o', a, b, (a === 0x14), (a === 0x1C), (a === 0x17), (a === 0x1F));

		let chNr = null;

		let cond1 = (a === 0x14 || a === 0x1C) && (0x20 <= b && b <= 0x2F);
		let cond2 = (a === 0x17 || a === 0x1F) && (0x21 <= b && b <= 0x23);
		if (!(cond1 || cond2)) {
			return false;
		}
				
		if (a === lastCmdA && b === lastCmdB) {
			lastCmdA = null;
			lastCmdB = null; // Repeated commands are dropped (once)
			logger.info("Repeated command (" + numArrayToHexArray([a, b]) + ") is dropped");
			return true;
		}

		if (a === 0x14 || a === 0x17) {
			chNr = 1;
		} else {
			chNr = 2; // (a === 0x1C || a=== 0x1f)
		}

		var channel = channels[chNr - 1];
		channel.setTime(t);

		if (a === 0x14 || a === 0x1C) {
			if (b === 0x20) {
				channel.cc_RCL();
			} else if (b === 0x21) {
				channel.cc_BS();
			} else if (b === 0x22) {
				channel.cc_AOF();
			} else if (b === 0x23) {
				channel.cc_AON();
			} else if (b === 0x24) {
				channel.cc_DER();
			} else if (b === 0x25) {
				channel.cc_RU(2);
			} else if (b === 0x26) {
				channel.cc_RU(3);
			} else if (b === 0x27) {
				channel.cc_RU(4);
			} else if (b === 0x28) {
				channel.cc_FON();
			} else if (b === 0x29) {
				channel.cc_RDC();
			} else if (b === 0x2A) {
				channel.cc_TR();
			} else if (b === 0x2B) {
				channel.cc_RTD();
			} else if (b === 0x2C) {
				channel.cc_EDM();
			} else if (b === 0x2D) {
				channel.cc_CR(lastTime);
			} else if (b === 0x2E) {
				channel.cc_ENM();
			} else if (b === 0x2F) {
				channel.cc_EOC();
			}
		} else { //a == 0x17 || a == 0x1F
			channel.cc_TO(b - 0x20);
		}
		lastCmdA = a;
		lastCmdB = b;
		currChNr = chNr;
		return true;
	};

	/**
	 * Parse midrow styling command
	 * @returns {Boolean}
	 */
	function parseMidrow(a, b) {
		var chNr = null;
			
		if ( ((a === 0x11) || (a === 0x19)) && 0x20 <= b && b <= 0x2f) {
			if (a === 0x11) {
				chNr = 1;
			} else  {
				chNr = 2;
			}
			if (chNr !== currChNr) {
				logger.log("ERROR", "Mismatch channel in midrow parsing");
				return false;
			}
			var channel = channels[chNr-1];
			channel.cc_MIDROW(b);
			logger.info("MIDROW (" + numArrayToHexArray([a, b]) + ")");
			return true;
		}
		return false;
	};

	/**
	 * Parse Preable Access Codes (Table 53).
	 * @returns {Boolean} Tells if PAC found
	 */
	function parsePAC(a, b) {

		var chNr = null;
		var row = null;
		
		var case1 = ((0x11 <= a  && a <= 0x17) || (0x19 <= a && a <= 0x1F)) && (0x40 <= b && b <= 0x7F);
		var case2 = (a === 0x10 || a === 0x18) && (0x40 <= b && b <= 0x5F);
		if (! (case1 || case2)) {
			return false;
		}

		if (a === lastCmdA && b === lastCmdB) {
			lastCmdA = null;
			lastCmdB = null;
			return true; // Repeated commands are dropped (once)
		}

		chNr = (a <= 0x17) ? 1 : 2;

		if (0x40 <= b && b <= 0x5F) {
			row = (chNr === 1) ? rowsLowCh1[a] : rowsLowCh2[a];
		} else { // 0x60 <= b <= 0x7F
			row = (chNr === 1) ? rowsHighCh1[a] : rowsHighCh2[a];
		}
		var pacData = interpretPAC(row, b);
		var channel = channels[chNr-1];
		channel.setPAC(pacData);
		lastCmdA = a;
		lastCmdB = b;
		currChNr = chNr;
		return true;
	};

	/**
	 * Interpret the second byte of the pac, and return the information.
	 * @returns {Object} pacData with style parameters.
	 */
    function interpretPAC(row, byte) {
		var pacIndex = byte;
		var pacData = {color : null, italics : false, indent : null, underline : false, row : row};
		
		if (byte > 0x5F) {
			pacIndex = byte - 0x60;
		} else {
			pacIndex = byte - 0x40;
		}
		pacData.underline = (pacIndex & 1) === 1;
		if (pacIndex <= 0xd) {
			pacData.color = ['white', 'green', 'blue', 'cyan', 'red', 'yellow', 'magenta', 'white'][Math.floor(pacIndex/2)];
		} else if (pacIndex <= 0xf) {
			pacData.italics = true;
			pacData.color = 'white';
		} else {
			pacData.indent = (Math.floor((pacIndex-0x10)/2))*4;
		}
		return pacData; // Note that row has zero offset. The spec uses 1.
	};

	/**
	 * Parse characters.
	 * @returns An array with 1 to 2 codes corresponding to chars, if found. null otherwise.
	 */
	function parseChars(a, b) {

		var  channelNr = null,
			charCodes = null,
			charCode1 = null,
			charCode2 = null;

		if (a >= 0x19) {
			channelNr = 2;
			charCode1 = a - 8;
		} else {
			channelNr = 1;
			charCode1 = a;
		}
		if (0x11 <= charCode1 && charCode1 <= 0x13) {
			// Special character
			var oneCode = b;
			if (charCode1 === 0x11) {
				oneCode = b + 0x50;
			} else if (charCode1 === 0x12) {
				oneCode = b + 0x70;
			} else {
				oneCode = b + 0x90;
			}
			//logger.info("parseChars - Special char '" + getCharForByte(oneCode) + "' in channel " + channelNr);
			charCodes = [oneCode];
		} else if (0x20 <= a && a <= 0x7f) {
			charCodes = (b === 0) ? [a] : [a, b];
		}
		if (charCodes) {
			var hexCodes = numArrayToHexArray(charCodes);
			//logger.info("parseChars - Char codes =  " + hexCodes.join(","));
			lastCmdA = null;
			lastCmdB = null;
		}
		return charCodes;
	};

	/**
	* Parse extended background attributes as well as new foreground color black.
	* @returns{Boolean} Tells if background attributes are found
	*/
	function parseBackgroundAttributes(a, b) {
		var bkgData,
			index,
			chNr,
			channel;

		var case1 = (a === 0x10 || a === 0x18) && (0x20 <= b && b <= 0x2f);
		var case2 = (a === 0x17 || a === 0x1f) && (0x2d <=b && b <= 0x2f);
		if (!(case1 || case2)) {
			return false;
		}
		bkgData = {};
		if (a  === 0x10 || a === 0x18) {
			index = Math.floor((b-0x20)/2);
			bkgData.background = backgroundColors[index];
			if (b % 2 === 1) {
				bkgData.background = bkgData.background + "_semi";
			}
		} else if (b === 0x2d) {
			bkgData.background = "transparent";
		} else {
			bkgData.foreground = "black";
			if (b === 0x2f) {
				bkgData.underline = true;
			}
		}
		chNr = (a < 0x18) ? 1 : 2;
		channel = channels[chNr-1];
		channel.setBkgData(bkgData);
		lastCmdA = null;
		lastCmdB = null;
		return true;
	};

	function getHandler(index) {
		return channels[index].getHandler();
	};
	
	function setHandler(index, newHandler) {
		channels[index].setHandler(newHandler);
	};

	function numArrayToHexArray(numArray) {
		var hexArray = [];
		for (var j = 0; j < numArray.length; j++) {
			hexArray.push(numArray[j].toString(16));
		}
		return hexArray;
	};

	function reset() {
		for (var i=0 ; i < channels.length ; i++) {
			if (channels[i]) {
				channels[i].reset();
			}
		}
		lastCmdA = null;
		lastCmdB = null;
	};
}

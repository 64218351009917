import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.PLAYERDIGITALEXPERIENCE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[PlayerDigitalExperience] reducer.mount - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.PLAYERDIGITALEXPERIENCE_UNMOUNT:
			newState = {};
			logger.log('[PlayerDigitalExperience] reducer.unmount - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.PLAYERDIGITALEXPERIENCE_SET_PLAYER:
			newState = {
				...state,
				msvPlayer: action.data,
			};
			logger.log('[PlayerDigitalExperience] reducer.setPlayer - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import { connect } from 'react-redux';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Button from 'appdir/components/common-ui/Button';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import { osName, osVersion, browserName, fullBrowserVersion, getUA } from 'react-device-detect';
import { getQuerystringValues } from 'appdir/components/general/Util';
import op from 'object-path';
import { callFunction } from 'appdir/components/general/Util/Services';
import { loggedIn } from 'appdir/components/general/Util/Role';
import { passAppData } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: Login Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LoginWebview'],
		windowSize: state['PageHeader'].windowSize,
		services: state['Config'].services,
		gigya: state['Gigya'],
		...props,
	};
};
const mobileMatch = window.matchMedia('(max-width: 767px)');

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Login.mount()),
	logout: data => dispatch(deps.actions.Gigya.logout(data)),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class LoginWebview extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevState) {
		logger.log('[LoginWebview] componentDidUpdate - state:%o', this.state);
		if (
			this.state.match.params.action == 'logout' &&
			!prevState.gigya.gigyaLoaded &&
			this.state.gigya.gigyaLoaded
		) {
			this.props.logout();
		}
		if (this.state.match.params.action == 'login' && !prevState.gigya.loggedIn && this.state.gigya.loggedIn) {
			this.props.getJWT();
		}
		if (prevState.gigya?.jwt?.status != 'loaded' && this.state.gigya?.jwt?.status == 'loaded') {
			const appData = {
				type: 'myw_login',
				source: 'ibm_web',
				data: {
					jwt: this.state.gigya.jwt,
				},
			};
			logger.log('[LoginWebview] passStatus - appData:%o', appData);
			passAppData(appData);
		}
	}

	getLoginContent() {
		if (this.state.gigya.gigyaLoaded) {
			if (this.state.match.params.action == 'login') {
				if (this.state.gigya.jwt) {
					return <div>Logged In - jwt</div>;
				} else {
					return <GigyaScreenSet params={{ screen: 'login_screen' }} holder="login-comp" inline={true} />;
				}
			} else if (this.state.match.params.action == 'logout') {
				return <div>Logging Out</div>;
			} else {
				return null;
			}
		} else {
			return <LoadingIndicator type={'green'} showTxt="One moment please..." />;
		}
	}

	render() {
		logger.log('[LoginWebview] render - state:%o', this.state);

		let currentUser = op.get(this.state, 'gigya.currentUser', {});
		//logger.log('[TicketsWebview] render - this:%o', this);
		//logger.log('[TicketsWebview] render - state:%o', this.state);

		return (
			<div class="local-screens site-wrap">
				<section className={'wrapper webview'}>
					<div className="content-main">{this.getLoginContent()}</div>
				</section>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginWebview);

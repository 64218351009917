/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: VisitWebview
 * this is the landing page for the visit section
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['VisitWebview'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(actions.VisitWebview.mount()),
});

class VisitWebview extends Component {
	constructor(props) {
		super(props);
		logger.log('[VisitWebview] constructor - props:%o', props);
	}

	componentDidMount() {
		measureInAppContext({
			pageTitle:"Visit",
			action: "A-Z Guide",
			args: [],
			context:  [{ section: "Visit" }],
			metricType: 'state'
		});
	}

	render() {
		logger.log('[VisitWebview] render - state:%o', this.state);

		return (
			<section className="wrapper visitwebview">
				<div className="content-main webview">
					<div className="visitContainer">
						<ul role="list">
							<li>
								<div
									className="timelineNews"
									onClick={() => {
										measureInAppContext({
											pageTitle: 'Plan Your Day',
											action: 'Visitor',
										});
									}}>
									<Link to="/webview/en_GB/atoz/explore_the_grounds.html">
										<img
											className="scale imageGrad"
											src="https://assets.wimbledon.com/images/webview/visit/u_Explorethegrounds.jpg"
											alt="Explore the Grounds"
										/>
									</Link>
									<div className="text">
										<span className="title">
											<Link to="/webview/en_GB/atoz/explore_the_grounds.html">
												Explore the Grounds
											</Link>
										</span>
									</div>
								</div>
							</li>
							<li>
								<div className="timelineNews">
									<Link to="/webview/en_GB/atoz/getting_here.html">
										<img
											className="scale imageGrad"
											src="https://assets.wimbledon.com/images/webview/visit/u_Tube.jpg"
											alt="Transport"
										/>
									</Link>
									<div className="text">
										<span className="title">
											<Link to="/webview/en_GB/atoz/getting_here.html">Transport</Link>
										</span>
									</div>
								</div>
							</li>
							<li>
								<div className="timelineNews">
									<Link to="/webview/en_GB/foodanddrink/index.html">
										<img
											className="scale imageGrad"
											src="https://assets.wimbledon.com/images/webview/visit/u_Foodanddrink.jpg"
											alt="Food and Drink"
										/>
									</Link>
									<div className="text">
										<span className="title">
											<Link to="/webview/en_GB/foodanddrink/index.html">Food and Drink</Link>
										</span>
									</div>
								</div>
							</li>
							<li>
								<div className="timelineNews">
									<Link to="/webview/en_GB/atoz/accessibility.html">
										<img
											className="scale imageGrad"
											src="https://assets.wimbledon.com/images/webview/visit/u_Accessibility.jpg"
											alt="Accessibility"
										/>
									</Link>
									<div className="text">
										<span className="title">
											<Link to="/webview/en_GB/atoz/accessibility.html">Accessibility</Link>
										</span>
									</div>
								</div>
							</li>
							<li>
								<div className="timelineNews">
									<Link to="/webview/en_GB/atoz/general_information.html">
										<img
											className="scale imageGrad"
											src="https://assets.wimbledon.com/images/webview/visit/u_GeneralInfo.jpg"
											alt="General Information"
										/>
									</Link>
									<div className="text">
										<span className="title">
											<Link to="/webview/en_GB/atoz/general_information.html">
												General Information
											</Link>
										</span>
									</div>
								</div>
							</li>
							<li>
								<div className="timelineNews">
									<WimLink to="https://help.wimbledon.com/hc/en-gb">
										<img
											className="scale imageGrad"
											src="https://assets.wimbledon.com/images/webview/visit/u_HelpCentre.jpg"
											alt="Help Centre"
										/>
									</WimLink>
									<div className="text">
										<span className="title">
											<WimLink to="https://help.wimbledon.com/hc/en-gb">Help Centre</WimLink>
										</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitWebview);

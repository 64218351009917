/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
// import { values } from 'appdir/main';
// import op from 'object-path';

import classNames from 'classnames';
import NewsTile from 'appdir/components/content/NewsTile';
import NewsTileNoRoute from 'appdir/components/content/NewsTileNoRoute';
import NewsListTile from 'appdir/components/content/NewsListTile';
// import Time from 'appdir/components/common-ui/Time';
import GenericCarousel from 'appdir/components/common-ui/GenericCarousel';
import differenceBy from 'lodash/differenceBy';
import cloneDeep from 'lodash/cloneDeep';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: RelatedContent
 * -----------------------------------------------------------------------------
 */
class RelatedContent extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
		this.fetchRelated = true;
		this.showOrHideCallback = this.showOrHideCallback.bind(this);
	}

	componentDidMount() {
		// logger.log('[RelatedContent] componentDidMount - state:%o', this.state);
		if (this.state.url && this.fetchRelated) {
			this.fetchRelated = false;
			let path = this.state.url;
			// logger.log('[RelatedContent] componentDidMount - url:%o', path);

			deps.services.RelatedContent.fetchRelatedContentComp(path).then(data => {				
				// logger.log('[RelatedContent] componentDidMount - data:%o, filterData:%o', data, this.props.filterData);
				if(this.props.filterData){
					let newRelatedList = differenceBy(data.content, this.props.filterData, 'cmsId');
					data.content = newRelatedList;
				}	

				this.showOrHideCallback(data);

				this.setState({
					relatedContentData: data,
					style: 'four-col',
				});
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[RelatedContent] componentWillReceiveProps- state:%o', this.state);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	showOrHideCallback(data){
		if (this.props.hasOwnProperty('showRelated') && data?.content?.length > 0) {	
			this.props.showRelated(true);
		}else if(data?.content?.length == 0){
			this.props.showRelated(false);
		}
	}

	componentDidUpdate(prevProps) {
		// logger.log('[RelatedContent] componentDidUpdate- state:%o', this.state);
		if (this.state.url && this.fetchRelated) {
			this.fetchRelated = false;
			let path = this.state.url;
			// logger.log('[RelatedContent] componentDidMount - url:%o', path);

			if(this.props.filterData){
				let newRelatedList = differenceBy(data.content, this.props.filterData, 'cmsId');
				data.content = newRelatedList;
			}	

			this.showOrHideCallback(data);

			deps.services.RelatedContent.fetchRelatedContentComp(path).then(data => {
				this.setState({
					relatedContentData: data,
					style: 'four-col',
				});
			});
		}

		if(this.props.filterData && !prevProps.filterData && this.state.relatedContentData){
			let data = cloneDeep(this.state.relatedContentData);
			let newRelatedList = differenceBy(data.content, this.props.filterData, 'cmsId');			
			data.content = newRelatedList;

			this.showOrHideCallback(data);

			this.setState({
				relatedContentData: data,
				style: 'four-col',
			});
		}
	}

	formatNewsTileData(relatedContentArry, col) {
		//let max = 4;
		//relatedContentArry = relatedContentArry.slice(relatedContentArry.length - max - 1, relatedContentArry.length - 1);

		return relatedContentArry.map((d, i) => {
			return {
				img: d.images[0].medium,
				type: d.type,
				subType: d.subType,
				typeText: d.type,
				// date: (this.state.includeDates == 'true') ? d.displayDate : '', //why is this line like this --removed amb 062618
				date: d.displayDate,
				title: d.shortTitle ? d.shortTitle : d.title,
				style: col ? col : 'two-col',
				description: d.description ? d.description : '',
				cmsId: d.cmsId,
				url: d.url,
			};
			// logger.log('[RelatedContent] - formatNewsTileData:%o', d);
		});
	}

	formatNewsTileDataPlayer(relatedContentArry, col) {
		//let max = 4;
		//relatedContentArry = relatedContentArry.slice(relatedContentArry.length - max - 1, relatedContentArry.length - 1);

		return relatedContentArry.map((d, i) => {
			return {
				img:
					d.images[0].medium.charAt(0) == '/'
						? '//www.wimbledon.com/' + d.images[0].medium
						: d.images[0].medium,
				type: d.type,
				subType: d.subType,
				typeText: d.type,
				date: d.displayDate,
				title: d.title,
				style: col ? col : 'two-col',
				description: d.description ? d.description : '',
				cmsId: d.cmsId,
				url: d.url,
			};
		});
	}

	displayRelatedContent(type, data, index){
		logger.log('[RelatedContent] displayRelatedContent - type:%o', type);

		let displayType = type;
		let displayData = data;

		if(displayType == 'player') {
			return(
				<NewsTile key={`${displayData.cmsId}-${index}`} attributes={{ ...displayData, 'col-style': displayData.style }} />
			);
		} else if(displayType == 'slamtracker') {
			return(
				<NewsTileNoRoute key={`${displayData.cmsId}-${index}`} attributes={{ ...displayData, 'col-style': displayData.style }} onClick={() => this.props?.goToUrl(`${displayData.url}`, ...displayData)} classes={'carousel'} />
			);
		} else {
			return (
				<NewsListTile attributes={{ ...displayData, 'col-style': displayData.style }} key={displayData.cmsId} />
			);
		}
	}
  
	render() {
		logger.log('[RelatedContent] render - state:%o', this.state);

		let classes = classNames(this.state.style);

		let relatedContent = [];
		if (
			this.state.relatedContentData &&
			this.state.relatedContentData.content &&
			this.state.relatedContentData.content.length > 0
		) {
			let col = this.state.listView == 'true' ? this.state.style : '';
			if (this.state.type == 'player') {
				relatedContent = this.formatNewsTileDataPlayer(this.state.relatedContentData.content, col);
			} else {
				relatedContent = this.formatNewsTileData(this.state.relatedContentData.content, col);
			}
		} else if (this.state.relatedContent) {
			//player profile loads
			relatedContent = this.formatNewsTileData(this.state.relatedContent.content);
		}

		// if (relatedContent.length > 0 && (!values.webview || (this.state.listView == 'true' && values.webview))) {
    if (relatedContent.length > 0) {
			return relatedContent.length > 0 ? (
				<div className={`${this.state.showCarousel ? '' : classes} related-cnt`}>
					{this.state.showCarousel ? (
						<GenericCarousel 
							className = {`content-carousel ${this.state.type == 'slamtracker' ? 'sidepanel' : ''}`} 
					
							slideWidth = {this.state?.slideWidth} >
							{relatedContent.map((d, i) => {
								return (
									<div key={i} className="content-wrap">
										{this.displayRelatedContent(this.state.type, d, i)}
									{/* <div className="test">content</div> */}
									</div>
								)
							})}
						</GenericCarousel>
					) : (
						<>
							{relatedContent.map((d, i) => {
								return this.displayRelatedContent(this.state.type, d, i);
							})}
						</>
					)}
				</div>
			) : null;
		} else {
			return null;
		}
	}
}

export default RelatedContent;

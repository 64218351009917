import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				paths: {
					usGeoCheck: Config.otherData.usGeoCheck,
					ukGeoCheck: Config.otherData.ukGeoCheck,
					otherGeoCheck: Config.otherData.otherGeoCheck,
					schedule: Config.scoringData.schedule,
					results: Config.scoringData.completedMatches,
					playerImagePath: Config.otherData.playerProfileImagePath,
					flagImagePath: Config.otherData.flagImagePathSmall,
					latestVideoPath: Config.cmsData.video,
				},
			};

			dispatch({ type: deps.actionTypes.SIDEPANEL_MOUNT, data: data });
		});
	},

	hide: () => dispatch => {
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: { sidepanel: { userOpen: false } } });
	},

	show: () => dispatch => {
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: { sidepanel: { userOpen: true } } });
	},

	geoUpdate: data => dispatch => {
		dispatch({ type: deps.actionTypes.SIDEPANEL_GEO_UPDATE, data: data });
	},

	hidePanel: data => dispatch => {
		dispatch({ type: deps.actionTypes.SIDEPANEL_HIDEPANEL, data: data });
	},
};

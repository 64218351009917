/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { FormInput } from 'appdir/components/common-ui/FormFields';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: CushionForm
 * -----------------------------------------------------------------------------
 */

function calculateDays(data, year, startDate) {
	let programDayArry = [];
	let firstSunday = false;
	let weekday = new Array(7);
	weekday[0] = 'Sunday';
	weekday[1] = 'Monday';
	weekday[2] = 'Tuesday';
	weekday[3] = 'Wednesday';
	weekday[4] = 'Thursday';
	weekday[5] = 'Friday';
	weekday[6] = 'Saturday';

	let month = new Array(12);
	month[0] = 'January';
	month[1] = 'February';
	month[2] = 'March';
	month[3] = 'April';
	month[4] = 'May';
	month[5] = 'June';
	month[6] = 'July';
	month[7] = 'August';
	month[8] = 'September';
	month[9] = 'October';
	month[10] = 'November';
	month[11] = 'December';

	let currDate = new Date(startDate + ' ' + year);
	for (let i = 0; i <= 13; i++) {
		let currData = {};
		if (i != 0) {
			currDate.setDate(currDate.getDate() + 1);
			// add back middle sunday
			// if (weekday[currDate.getDay()] == 'Sunday' && !firstSunday) {
			// 	currDate.setDate(currDate.getDate() + 1);
			// 	firstSunday = true;
			// }
		}

		currData = {
			...data[i],
			day: weekday[currDate.getDay()],
			date: `${currDate.getDate()} ${month[currDate.getMonth()]}`,
		};
		programDayArry[i] = currData;
	}
	return programDayArry;
}

const CushionForm = ({ formProps }) => {
	logger.log('[ProgramForm] render - formProps:%o', formProps);
	let cushionFormData = formProps.values.orders[0].cushion;
	let year = formProps.values.orders[0].year;
	let cushionOrderData = calculateDays(cushionFormData.days, year, formProps.context.dateConfig.tournStartDate);
	return (
		<span className="hospContent">
			{/* <span className="field one-col">
				<div>CUSHIONS</div>
				<Field
					component="input"
					type="text"
					name="programs"
					onChange={formProps.handleChange}
					onBlur={formProps.handleBlur}
					value={formProps.values.cushions}
				/>
				<ErrorMessage name="programs" render={msg => <span className="error">{msg}</span>} />
			</span> */}
			<span className="column-layout">
				<span className="four-col table-container">
					<table className="hospitality-table">
						<thead>
							<tr>
								<td>Day</td>
								<td>Date</td>
								<td>Quantity</td>
							</tr>
						</thead>
						<tbody>
							{cushionOrderData.map((d, i) => {
								return (
									<tr key={i}>
										<td>
											{d.day}
											<FormInput
												label=""
												name={`orders[0].cushion.days[${i}].day`}
												type="hidden"
												value={d.day}
												className="quant"
											/>
										</td>
										<td>
											{d.date}
											<FormInput
												label=""
												name={`orders[0].cushion.days[${i}].date`}
												type="hidden"
												value={d.date}
												className="quant"
											/>
										</td>
										<td>
											<FormInput
												label=""
												name={`orders[0].cushion.days[${i}].quantity`}
												type="text"
												value={d.quantity || ''}
												className="quant"
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</span>
			</span>
			<span className="column-layout no-padding">
				<span className="one-col signdate">
					<FormInput
						label="Suite Number"
						name="orders[0].cushion.suite"
						id="cushionSuite"
						type="text"
						value={cushionFormData.suite || ''}
					/>
				</span>
			</span>
			<span className="column-layout no-padding">
				<span className="three-col signdate">
					<FormInput
						label="Name & Phone Number"
						name="orders[0].cushion.name"
						id="cushionName"
						type="text"
						value={cushionFormData.name || ''}
					/>
				</span>
				<span className="one-col signdate">
					<FormInput
						label="Date"
						name="orders[0].cushion.date"
						id="cushionDate"
						type="text"
						value={cushionFormData.date || ''}
					/>
				</span>
			</span>
		</span>
	);
};

export default CushionForm;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import classNames from 'classnames';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ScoreTable
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MatchBox'],
		favourites: state['Controller'].favourites,
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({});

class ArchiveMatchBox extends Component {
	constructor(props) {
		logger.log('[ArchiveMatchBox] constructor');
		super(props);
		this.state = { ...props, lastScore_t1: false, lastScore_t2: false };

		this.scoreData = this.state.attributes.data;
		this.tableHeader = this.state.attributes.tableHeader;
		this.flagConfig = this.state.attributes.flagConfig;
		this.playerProfileImagePath = this.state.attributes.playerProfileImagePath;
		this.winnerTeam = this.scoreData.team1.won ? 'team1' : 'team2';
		this.boxStyle = this.state.attributes.style ? this.state.attributes.style : '';
		this.layoutStyle = this.state.attributes.layoutStyle ? this.state.attributes.layoutStyle : '';
		this.iconClasses_t1 = classNames('crticon', this.scoreData.team1.won ? 'winner' : '');
		this.iconClasses_t2 = classNames('crticon', this.scoreData.team2.won ? 'winner' : '');

		this.renderFinalMatchBox = this.renderFinalMatchBox.bind(this);
		this.renderWinnerMatchBox = this.renderWinnerMatchBox.bind(this);
	}
	componentDidMount() {}
	componentWillUnmount() {
		clearTimeout(this.t1_timer);
		clearTimeout(this.t2_timer);
	}
	componentDidUpdate(prevProps, prevState) {
		const curAttributes = this.state.attributes;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[ArchiveMatchBox] componentWillReceiveProps nextProps:%o',nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		//logger.log('[ArchiveMatchBox] componentWillReceiveProps state:%o',this.state);
	}

	renderFinalMatchBox() {
		return (
			<div
				className={`match-box match-box-draws ${this.boxStyle}`}
				data-match={this.scoreData.id}
				data-dna="match-box-draws-page">
				<div className="match-status">
					<div className="green-border" />
					<div
						className={`team-info team-one ${
							this.scoreData.team1.playerB_tv_name !== '' ? 'doubles' : 'singles'
						}`}>
						<div className="team-name">
							<div
								className={`member-one ${
									this.state.favourites.show &&
									this.state.favourites.players.indexOf(scoreData.team1.playerA_id) !== -1
										? 'favorite'
										: ''
								}`}>
								<div className="flag">
									<img
										alt={this.scoreData.team1.playerA_nat}
										src={this.flagConfig.replace('<code>', this.scoreData.team1.playerA_nat)}
									/>
								</div>
								<div className="name">
									<WimLink
										style={'white'}
										to={`/en_GB/draws_archive/player_profile/${this.scoreData.team1.playerA_id}.html`}
										target="_self">
										{this.scoreData.team1.playerA_tv_name}
									</WimLink>
								</div>
								<div className="seed">{this.scoreData.team1.seed}</div>
							</div>
							<div
								className={`member-two ${
									this.state.favourites.show &&
									this.state.favourites.players.indexOf(scoreData.team1.playerB_id) !== -1
										? 'favorite'
										: ''
								}`}>
								<div className="flag">
									<img
										alt={this.scoreData.team1.playerB_nat}
										src={this.flagConfig.replace('<code>', this.scoreData.team1.playerB_nat)}
									/>
								</div>
								<div className="name">
									<WimLink
										style={'white'}
										to={`/en_GB/draws_archive/player_profile/${this.scoreData.team1.playerB_id}.html`}
										target="_self">
										{this.scoreData.team1.playerB_tv_name}
									</WimLink>
								</div>
								<div className="seed">{this.scoreData.team1.seed}</div>
							</div>
						</div>
						<div className={this.iconClasses_t1}>
							<i className="wim-icon-check" />
						</div>
						<div className="match-results">
							<div className="results-wrapper">
								<div key="set1" className="set set1">
									<span className="scores">
										{this.scoreData.team1.s1}
										<span className="tiebreak">{this.scoreData.team1.t1}</span>
									</span>
								</div>
								<div key="set2" className="set set2">
									<span className="scores">
										{this.scoreData.team1.s2}
										<span className="tiebreak">{this.scoreData.team1.t2}</span>
									</span>
								</div>
								<div key="set3" className="set set3">
									<span className="scores">
										{this.scoreData.team1.s3}
										<span className="tiebreak">{this.scoreData.team1.t3}</span>
									</span>
								</div>
								<div key="set4" className="set set4">
									<span className="scores">
										{this.scoreData.team1.s4}
										<span className="tiebreak">{this.scoreData.team1.t4}</span>
									</span>
								</div>
								<div key="set5" className="set set5">
									<span className="scores">
										{this.scoreData.team1.s5}
										<span className="tiebreak">{this.scoreData.team1.t5}</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="results-info">
						<div className="results-info-pts">PTS</div>
						<div className="results-info-set">1</div>
						<div className="results-info-set">2</div>
						<div className="results-info-set">3</div>
						<div className="results-info-set">4</div>
						<div className="results-info-set">5</div>
					</div>
					<div
						className={`team-info team-two ${
							this.scoreData.team2.playerB_tv_name !== '' ? 'doubles' : 'singles'
						}`}>
						<div className="team-name">
							<div
								className={`member-one ${
									this.state.favourites.show &&
									this.state.favourites.players.indexOf(scoreData.team2.playerA_id) !== -1
										? 'favorite'
										: ''
								}`}>
								<div className="flag">
									<img
										alt={this.scoreData.team2.playerA_nat}
										src={this.flagConfig.replace('<code>', this.scoreData.team2.playerA_nat)}
									/>
								</div>
								<div className="name">
									<WimLink
										style={'white'}
										to={`/en_GB/draws_archive/player_profile/${this.scoreData.team2.playerA_id}.html`}
										target="_self">
										{this.scoreData.team2.playerA_tv_name}
									</WimLink>
								</div>
								<div className="seed">{this.scoreData.team2.seed}</div>
							</div>
							<div
								className={`member-two ${
									this.state.favourites.show &&
									this.state.favourites.players.indexOf(scoreData.team2.playerB_id) !== -1
										? 'favorite'
										: ''
								}`}>
								<div className="flag">
									<img
										alt={this.scoreData.team2.playerB_nat}
										src={this.flagConfig.replace('<code>', this.scoreData.team2.playerB_nat)}
									/>
								</div>
								<div className="name">
									<WimLink
										style={'white'}
										to={`/en_GB/draws_archive/player_profile/${this.scoreData.team2.playerB_id}.html`}
										target="_self">
										{this.scoreData.team2.playerB_tv_name}
									</WimLink>
								</div>
								<div className="seed">{this.scoreData.team2.seed}</div>
							</div>
						</div>
						<div className={this.iconClasses_t2}>
							<i className="wim-icon-check" />
						</div>
						<div className="match-results">
							<div className="results-wrapper">
								<div key="set1" className="set set1">
									<span className="scores">
										{this.scoreData.team2.s1}
										<span className="tiebreak">{this.scoreData.team2.t1}</span>
									</span>
								</div>
								<div key="set2" className="set set2">
									<span className="scores">
										{this.scoreData.team2.s2}
										<span className="tiebreak">{this.scoreData.team2.t2}</span>
									</span>
								</div>
								<div key="set3" className="set set3">
									<span className="scores">
										{this.scoreData.team2.s3}
										<span className="tiebreak">{this.scoreData.team2.t3}</span>
									</span>
								</div>
								<div key="set4" className="set set4">
									<span className="scores">
										{this.scoreData.team2.s4}
										<span className="tiebreak">{this.scoreData.team2.t4}</span>
									</span>
								</div>
								<div key="set5" className="set set5">
									<span className="scores">
										{this.scoreData.team2.s5}
										<span className="tiebreak">{this.scoreData.team2.t5}</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderWinnerMatchBox() {
		return (
			<div
				className={`match-box match-box-draws winner ${this.boxStyle}`}
				data-match={this.scoreData.id}
				data-dna="match-box-draws-winner">
				<div className="match-status">
					<div className="green-border" />
					<div
						className={`team-info team-one ${
							this.scoreData[this.winnerTeam].playerB_tv_name !== '' ? 'doubles' : 'singles'
						}`}>
						<div className="team-name">
							<div className="member-one">
								<div className="flag">
									<img
										alt={this.scoreData[this.winnerTeam].playerA_nat}
										src={this.flagConfig.replace(
											'<code>',
											this.scoreData[this.winnerTeam].playerA_nat
										)}
									/>
								</div>
								<div className="name">
									<WimLink
										to={`/en_GB/draws_archive/player_profile/${
											this.scoreData[this.winnerTeam].playerA_id
										}.html`}
										target="_self">
										{this.scoreData[this.winnerTeam].playerA_tv_name}
									</WimLink>
								</div>
								<div className="seed">{this.scoreData[this.winnerTeam].seed}</div>
							</div>
							<div className="member-two">
								<div className="flag">
									<img
										alt={this.scoreData[this.winnerTeam].playerB_nat}
										src={this.flagConfig.replace(
											'<code>',
											this.scoreData[this.winnerTeam].playerB_nat
										)}
									/>
								</div>
								<div className="name">
									<WimLink
										to={`/en_GB/draws_archive/player_profile/${
											this.scoreData[this.winnerTeam].playerB_id
										}.html`}
										target="_self">
										{this.scoreData[this.winnerTeam].playerB_tv_name}
									</WimLink>
								</div>
								<div className="seed">{this.scoreData[this.winnerTeam].seed}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		//logger.log('[ArchiveMatchBox] render - match:%o  state:%o',this.scoreData.match_id, this.state);
		return (
			<div className="winnerGroup">
				<div className={`roundGroup ${this.layoutStyle}`}>
					<div className="yearWrapper">
						<h4>{this.tableHeader}</h4>
						<div className="drawGroup">{this.renderFinalMatchBox()}</div>
					</div>
				</div>
				<div className={`roundGroup ${this.layoutStyle}`}>
					<div className="drawGroup winner">{this.renderWinnerMatchBox()}</div>
				</div>
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveMatchBox);

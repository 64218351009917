import MediaRequest from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: ['/en_GB/media/request.html'],
		exact: true,
		component: MediaRequest,
		load: params => deps.actions.MediaRequest.mount(params),
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values } from 'appdir/main';
import { getAppLink } from 'appdir/components/general/Util';
import Button from 'appdir/components/common-ui/Button';
import WimRedirect from 'appdir/components/general/WimRedirect';
import deps from 'dependencies';
import cn from 'classnames';

const mapStateToProps = (state, props) => {
	return {
		...state['SlamtrackerButton'],
		panel: state['SlamtrackerPanel'].slamtrackerPanel,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	// hide: () => dispatch(deps.actions.SlamtrackerPanel.hide()),
	show: (matchId, defaultTab) => dispatch(deps.actions.SlamtrackerPanel.show(matchId, defaultTab)),
});

class SlamtrackerButton extends Component {
	constructor(props) {
		super(props);
		this.state = { };
		this.onLinkClick = this.onLinkClick.bind(this);
	}

	onLinkClick(e, path) {
		//logger.log('[MatchBox] e:%o, path:%o', e.target, path);
		// e.preventDefault();
		const { slamtrackerActive } = this.props;
		if (!slamtrackerActive) return;

		if (values.webview) {
			let link = getAppLink(path, '');
			location.href = link.url;
		} else {
			this.props.show(this.props.match_id, this.props.defaultTab);
		}
	}

	render() {
		//logger.log('[SlamtrackerButton] this.state:%o', this.state);
		let preMatch = ['B'];
		let liveMatch = ['A', 'K', 'X', 'Y'];
		let postMatch = ['D', 'E', 'F', 'G'];
		let noMatchStatus = ['']; // this is to handle the button label without knowing the live matcb status
		const { statusCode, slamtrackerActive } = this.props;
		let matchStateText = '';
		if (preMatch.includes(statusCode)) {
			matchStateText = `${values.slamtrackerButtonText} Preview`;
		} else if (liveMatch.includes(statusCode)) {
			matchStateText = `Follow Live`;
		} else if (postMatch.includes(statusCode)) {
			matchStateText = `${values.slamtrackerButtonText} Recap`;
		} else if (noMatchStatus.includes(statusCode)) { // added to handle the button without live match status code updates
			matchStateText = `Follow Match`; 
		}

		let classes = cn({
			'compact': this.props?.buttonSize !== 'large',
			'slamtracker-stats': true,
			'disabled': !slamtrackerActive,
		});

		if (this.props.nav) {
			return <WimRedirect to={this.props.nav} />;
		} else {
			return (
				<Button
					genLabel={`match ${this.props.match_id}`}
					ariaLabel={
						values.slamtrackerButtonText && matchStateText
							? `${matchStateText}`
							: `${values.slamtrackerButtonText} Recap`
					}
					// ariaHidden
					onClick={e => {
						this.onLinkClick(e, `/en_GB/scores/stats/${this.props.match_id}.html`);
					}}
					tabIndex={0}
					className={`${classes} ${this.props.styleClass}`}
					name={`slamtracker stats`}
					style={{ width: '100%' }}
					disabled={!slamtrackerActive}>
					{`${matchStateText}`}
				</Button>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamtrackerButton);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { values } from 'appdir/main';
import deps from 'dependencies';
import op from 'object-path';
import Template from 'appdir/components/Template';
import WimLink from 'appdir/components/general/WimLink';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import DaySelect from './DaySelect';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import StubBox from 'appdir/components/common-ui/StubBox';
import Favorites from 'appdir/components/common-ui/Favorites';
import SearchBox from 'appdir/components/common-ui/SearchBox';

import RolexScheduleTime from 'appdir/components/common-ui/RolexScheduleTime';
import findIndex from 'lodash/findIndex';

import axios from 'axios';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: SchedulePage
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SchedulePage'],
		scheduleData: state['Tournament']['data']['scheduleScores'],
		scheduleDaysData: state['Tournament']['data']['scheduleDays'],
		currentDay: state['ActiveData']['currentDay'],
		stubs: state['Config'].stubPages,
		text: state['Config'].text,
		favourites: state['Controller'].favourites,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SchedulePage.mount()),
	setDefaultDay: settings => dispatch(deps.actions.SchedulePage.setDay(settings)),
	getSchedule: day => dispatch(deps.actions.Tournament.getScheduleScores({ day: day })),
	stopSchedule: day => dispatch(deps.actions.Tournament.stopSchedule()),
	clearSchedule: data => dispatch(deps.actions.Tournament.clearSchedule(data)),
});

class SchedulePage extends Component {
	constructor(props) {
		super(props);

		this.setScheduleDay = this.setScheduleDay.bind(this);

		//set default filter based on querystring
		let filters = props.filters;

		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);

		if (parsedQs.filter) {
			index = findIndex(menu, function(o) {
				return o.query == parsedQs.filter.toLowerCase();
			});
		}

		//bind enter for player search
		this.onEnter = this.onEnter.bind(this);
		// now add all the props and filters to state
		this.state = {
			...this.props,
			filters: filters,
			showSelected: 0,
			playersPlaying: null,
			searchedPlayer: null,
		};
		this.defaultSet = false;
		this.showRolex = this.showRolex.bind(this);
		this.hideRolex = this.hideRolex.bind(this);
		logger.log('[SchedulePage] constructor - state:%o, props: %o', this.state, this.props);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[SchedulePage] getDerivedStateFromProps - nextProps:%o', nextProps);

		let updates = {};
		if (nextProps?.scheduleData?.status == 'loaded') {
			updates = {
				playersPlaying: SchedulePage.getPlayers(nextProps.scheduleData.data),
				error: null,
			};
		} else if (nextProps?.scheduleData?.status == 'error') {
			updates = {
				error: true,
			};
		}

		let newState = {
			...prevState,
			...nextProps,
			...updates,
		};

		return newState;
	}

	static getPlayers(data) {
		//get a list of players playing that day
		let playerArry = [];
		if (data.status == 'loaded') {
			data?.courts.forEach(d => {
				d.matches.forEach(d1 => {
					d1.team1.forEach(d2 => {
						let player1a = null,
							player1b = null;
						player1a = {
							firstName: d2.firstNameA,
							lastName: d2.lastNameA,
							id: d2.idA,
						};
						player1b = {
							firstName: d2.firstNameB,
							lastName: d2.lastNameB,
							id: d2.idB,
						};
						playerArry.push(player1a, player1b);
					});

					d1.team2.forEach(d2 => {
						let player1a = null,
							player1b = null;
						player1a = {
							firstName: d2.firstNameA,
							lastName: d2.lastNameA,
							id: d2.idA,
						};
						player1b = {
							firstName: d2.firstNameB,
							lastName: d2.lastNameB,
							id: d2.idB,
						};
						playerArry.push(player1a, player1b);
					});
				});
			});
		}

		let playersPlaying = playerArry.filter(d => d.id !== null);
		return playersPlaying;
	}

	componentDidMount() {
		//logger.log('[SchedulePage] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.props.stopSchedule();
		this.props.clearSchedule();
		this.props.setDefaultDay({ day: null });
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[SchedulePage] componentDidUpdate  - state:%o', this.state);

		if (!this.state.match.params.tournDay && !this.state.filters.day && this.state?.currentDay?.schedule) {
			this.state.setDefaultDay({ day: this.state.currentDay.schedule });
		} else if (op.get(this.state, 'filters.day', '') != '') {
			if (this.state.filters.day != prevProps.filters.day) {
				this.props.getSchedule(this.state.filters.day);
			}
		}
	}

	getScheduleDay() {
		if (this.state.match.params.tournDay) {
			return this.state.match.params.tournDay;
		} else if (this.state?.currentDay?.schedule) {
			return this.state.currentDay.schedule;
		} else {
			return '';
		}
	}

	setScheduleDay(day) {
		logger.log('[SchedulePage] setScheduleDay - day:%o', day);
		this.props.history.push(`schedule${day}.html`);
	}

	onEnter(value) {
		logger.log('[SearchPage] onEnter - value:%o', value);
		this.setState({ searchedPlayer: value.tour_id });
		//get element page offset
		let el = document.getElementById(value.tour_id);
		if (el !== null) {
			let coord = el.getBoundingClientRect();
			let offset = 126; //minus the height of the header plus 20 buffer
			window.scroll({
				top: coord.top - offset,
				behavior: 'smooth',
			});
		}
	}

	getMode() {
		let day = this.getScheduleDay();
		let mode = '';
		let days = this.state?.scheduleData?.data?.eventDays || [];
		days.forEach(item => {
			if (item.tournDay == day) {
				mode = item.quals ? 'quali' : 'tourn';
			}
		});
		return mode;
	}

	hideRolex(evt) {
		logger.log('[SchedulePage] hideTime - evt:%o', evt);
		this.setState({
			showSelected: 0,
		});
	}

	showRolex(evt) {
		logger.log('[SchedulePage] showTime - evt:%o', evt);
		this.setState({
			showSelected: evt.target.id,
		});
	}

	renderRolex(courtTime, activeClass) {
		if (courtTime) {
			courtTime = new Date(courtTime);
			//logger.log('[SchedulePage] courtTime - :%o',courtTime);

			return (
				<RolexScheduleTime
					top={'0px'}
					left={'85%'}
					visible={activeClass}
					format={'hh:mma'}
					position={'relative'}
					when={courtTime}
					label={{ event: 'Wimbledon', local: 'Your Time' }}
				/>
			);
		} else {
			return null;
		}
	}

	determinePlayerHighlightClass(id) {
		let playerClasses = [];
		let searchedPlayer = op.get(this.state, 'searchedPlayer', '');
		if (this.state.favourites.show && this.state.favourites.players.indexOf(id) !== -1) {
			playerClasses.push('favorite');
		}
		if (typeof id === 'string') {
			if (searchedPlayer === id.toString()) {
				playerClasses.push('search');
			}
		}

		return playerClasses.join(' ');
	}

	getPlayerNameA(team) {
		if (team.length == 1) {
			team = team[0];
			return (
				<div
					className={`schedule-player player1 ${
						this.state.favourites.show && this.state.favourites.players.indexOf(team.idA) !== -1
							? 'favorite'
							: ''
					}`}
					data-player={team.idA}>
					<NavLink className="name" to={`/en_GB/players/overview/${team.idA}.html`}>
						{team.displayNameA}
					</NavLink>
					<span className="nation"> {this.getCountry(team.nationA)} </span>
					<span className="seed">{team.seed}</span>
				</div>
			);
		} 
	}

	getCountry(nation) {
		if (nation && !values.countryHide.includes(nation)) {
			return `(${nation})`;
		} else {
			return '';
		}
	}

	getPlayerNames(team) {
		return (
			<span>
				{/* <div className={`schedule-player player1 ${this.state.favourites.show && this.state.favourites.players.indexOf(team.idA) !== -1 ? 'favorite' : ''}`} data-player={team.idA}> */}
				<div
					className={`schedule-player player1 ${this.determinePlayerHighlightClass(team.idA)}`}
					data-player={team.idA}
					id={team.idA}>
					<NavLink
						className="name"
						aria-label={`${team.displayNameA} player profile`}
						alt={`${team.displayNameA} player profile`}
						to={`/en_GB/players/overview/${team.idA}.html`}>
						{`${team.displayNameA}`}
					</NavLink>
					<span className="nation"> {this.getCountry(team.nationA)} </span>
					<span className="seed">{team.seed}</span>
				</div>
				{team.idB ? (
					<div
						className={`schedule-player player2 ${this.determinePlayerHighlightClass(team.idB)}`}
						data-player={team.idB}
						id={team.idB}>
						<NavLink
							aria-label={`${team.displayNameB} player profile`}
							alt={`${team.displayNameB} player profile`}
							className="name"
							to={`/en_GB/players/overview/${team.idB}.html`}>
							{`${team.displayNameB}`}
						</NavLink>
						<span className="nation"> {this.getCountry(team.nationB)} </span>
						<span className="seed">{team.seed}</span>
					</div>
				) : null}
			</span>
		);
	}

	getTeamNames(team) {
		if (team.length == 1) {
			return <span>{this.getPlayerNames(team[0])}</span>;
		} else if (team.length == 2) {
			return (
				<span className="winner-of-holder">
					<div className="winner-of">winner of</div>
					<div className="winner-of1">{this.getPlayerNames(team[0])}</div>
					<div className="winner-versus">v</div>
					<div className="winner-of2">{this.getPlayerNames(team[1])}</div>
				</span>
			);
		}
	}

	getMatch(match) {
		//logger.log('[SchedulePage] getMatch - match:%o',  match);

		return (
			<div
				key={match.match_id}
				data-match={match.match_id}
				className="match"
				data-players={`${match.team1[0].idA},${match.team1[0].idB},${match.team2[0].idA},${match.team2[0].idB}`}>
				<div className="row">
					<div className="match-info header" colSpan="3">
						{match.notBefore ? (
							<div className="match-info-notBefore">NOT BEFORE: {match.notBefore}</div>
						) : null}
						{match.comment ? <div className="match-info-comment">{match.comment}</div> : null}
						<span className="event">{match.eventName}</span> -{' '}
						<span className="round">{match.roundName}</span>
					</div>
					<div className="scores header">{match.shortScore}</div>
				</div>
				<div className="row teams">
					<div>{}</div>
					<div className="schedule-team content">{this.getTeamNames(match.team1)}</div>
					<div className="versus content">{match.conjunction}</div>
					<div className="schedule-team content">{this.getTeamNames(match.team2)}</div>
					<div className="status content">{match.status}</div>
				</div>
				<div className="row mobile">
					<div className="scores">{match.shortScore}</div>
					<div className="status">{match.status}</div>
				</div>
			</div>
		);
	}

	getEmptyMatch(match) {
		//logger.log('[SchedulePage] getEmptyMatch match:%o',  match);

		return (
			<div key={match.match_id} className="match">
				<div className="row">
					<div className="match-info header" colSpan="3">
						{match.notBefore ? (
							<div className="match-info-notBefore">NOT BEFORE: {match.notBefore}</div>
						) : null}
						{match.comment ? <div className="match-info-comment">{match.comment}</div> : null}
						<span className="event" />
					</div>
				</div>
				<div className="row teams" />
				<div className="row mobile" />
			</div>
		);
	}

	getPDF() {
		let filename = `schedulePDF${this.getScheduleDay()}`;
		let pdfPath = this.state.pdfPath ? this.state.pdfPath.replace('<file>', filename) : '';

		if (this.state.scheduleData.status == 'loaded') {
			return (
				<div className="print">
					<WimLink to={pdfPath} external={true} style="white">
						<i className="wim-icon-pdf" />
					</WimLink>
				</div>
			);
		} else {
			return null;
		}
	}

	getHeader() {
		// iframe style for rolex clock
		let iframeStyle = {};
		iframeStyle['width'] = '250px';
		iframeStyle['height'] = '70px';
		iframeStyle['border'] = '0';
		iframeStyle['margin'] = '0';
		iframeStyle['padding'] = '0';
		iframeStyle['overflow'] = 'hidden';
		iframeStyle['scroll'] = 'none';

		return (
			<div className="four-col">
				<div className="schedule-info">
					<div className="day">
						{this.state?.scheduleData?.data?.displayDate ? this.state.scheduleData.data.displayDate : ''}
					</div>
					<div className="revised">
						{this.state?.scheduleData?.data?.comments ? this.state.scheduleData.data.comments : ''}
					</div>
				</div>
				<div className="schedule-clock">
					<div className="clock">
						<iframe
							src="https://assets.wimbledon.com/static/rolex/desktop/rolex.html"
							style={iframeStyle}
							scrolling="NO"
							frameBorder="NO"
							title="schedule clock"
						/>
					</div>
				</div>
			</div>
		);
	}

	getDayDescription() {
		let days = this.state?.scheduleDaysData?.data?.eventDays || [];
		let day = days.filter(day => {
			return day.tournDay == this.state?.filters?.day
		})

		//logger.log('[SchedulePage] getDayDescription - day:%o', day);
		return day[0]?.message;
	}

	getDayNavigator() {
		let days = this.state?.scheduleDaysData?.data?.eventDays || [];
		return (
			<DaySelect
				key={`daySelect${this.state.filters.day}`}
				days={days}
				filters={this.state.filters}
				selected={this.state.filters.day}
				setScheduleDay={this.setScheduleDay}
				mode={this.getMode()}
			/>
		);
	}

	/*
	//start trying to use common SelectMenu
	getDayNavigator() {
		if (this.state?.scheduleData?.days?.eventDays) {
			let daysData = {
				options: [
					
				],
			};
			this.state?.scheduleData?.days?.eventDays.forEach(item => {
				daysData.options.push({
					name: item.message,
					value: item.tournDay,
					
				});
			});

			return (
				<SelectMenu
					name="scheduleDay"
					attributes={daysData}
					selected={null}
					onSelected={this.setScheduleDay}
					onOpen={() => {}}
				/>
			)
		} else {
			return null;
		}
	}
	*/

	getContents() {
		if (this.state.stubs && this.state.stubs.schedule.stub === 'stub') {
			return <StubBox attributes={{ message: this.state.stubs.schedule.text, style: 'centerV' }} />;
		} else if (this.state.error) {
			return (
				<>
					<div className="column-layout no-padding">
						<div className="header">
							<div className="navItemFull">{this.getDayNavigator()}</div>
							<div className="navItem">
								<SearchBox staticData={this.state.playersPlaying} onEnter={this.onEnter} />
							</div>
							<div className="navItemFixed">
								<Favorites />
							</div>
							<div className="navItemFixed">{this.getPDF()}</div>
						</div>
					</div>
					<GenericError message="Unable to display order of play data." />;
				</>
			);
		} else if (this.state.scheduleData.status == 'loaded' && this.state.playersPlaying !== null) {
			return (
				<>
					<div className="column-layout no-padding">
						<div className="header">
							<div className="navItemFull">{this.getDayNavigator()}</div>
							<div className="navItem">
								<SearchBox staticData={this.state.playersPlaying} onEnter={this.onEnter} />
							</div>
							<div className="navItemFixed">
								<Favorites />
							</div>
							<div className="navItemFixed">{this.getPDF()}</div>
						</div>
					</div>
					<div className="column-layout scorespage">
						{this.getHeader()}
						{this.getCourtDisplay()}
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="column-layout no-padding">
						<div className="header">
							<div className="navItemFull">{this.getDayNavigator()}</div>
							<div className="navItem">
								<SearchBox staticData={this.state.playersPlaying} onEnter={this.onEnter} />
							</div>
							<div className="navItemFixed">
								<Favorites />
							</div>
							<div className="navItemFixed">{this.getPDF()}</div>
						</div>
					</div>
					<LoadingIndicator type="white" />;
				</>
			);
		}
	}

	getCourtDisplay() {
		return (
			<Fragment>
				{this.state.scheduleData.data.courts.map(court => {
					let courtTime = court.startEpoch * 1000;
					let ariaLabel = `${court.courtName} ${court.time} `;

					court.matches.map(match => {
						ariaLabel = match.notBefore ? `${ariaLabel}. Not before ${match?.notBefore}.` : ariaLabel;
						ariaLabel = `${ariaLabel} ${match.eventName} ${match.roundName}`;
						ariaLabel = `${ariaLabel} ${match.team1[0]?.displayNameA} ${
							match.team1[0]?.displayNameB ? `and ${match.team1[0]?.displayNameB}` : ''
						} ${match.conjunction === 'v' ? 'versus' : match.conjunction} ${match.team2[0]?.displayNameA} ${
							match.team2[0]?.displayNameB ? `and ${match.team2[0]?.displayNameB}` : ''
						}`;
						if (match?.shortScore) {
							ariaLabel = `${ariaLabel} ${match.conjunction === 'v' ? 'with' : 'by'} a score of ${
								match?.shortScore
							}`;
						}
						ariaLabel = match?.status ? `${ariaLabel}. The match status is ${match?.status}.` : ariaLabel;
					});

					//logger.log('[SchedulePage] render - court:%o', court);

					return (
						<div key={court.courtId} data-court={court.courtId} className="schedule-court">
							<div
								className="courtName"
								data="1300"
								id={court.courtId}
								onMouseEnter={this.showRolex}
								onMouseLeave={this.hideRolex}
								tabIndex={0}
								alt={ariaLabel}
								aria-label={ariaLabel}>
								{court.courtName}
								<br />
								{court.time}
								<div className="rolexContainer">
									{this.renderRolex(
										courtTime,
										this.state.showSelected === court.courtId ? true : false
									)}
								</div>
							</div>

							<div className="schedule-content">
								{court.matches.map(match =>
									match.team1.length > 0 && match.team2.length > 0
										? this.getMatch(match)
										: this.getEmptyMatch(match)
								)}
							</div>
						</div>
					);
				})}
			</Fragment>
		);
	}

	render() {
		logger.log('[SchedulePage] render - state:%o', this.state);
		let header_propsData = {};
		let _this = this;

		let pageDescription = this.props?.text?.descriptions?.schedule || '';
		pageDescription = pageDescription.replace('<schedule_day>', this.getDayDescription());

		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		header_propsData = {
			headerType: 'schedule',
			metaTitle: 'Order of Play',
			metaDescription: pageDescription,
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template id="schedule" className="scorespage">
				<div className="schedule-background" />
				<Header attributes={header_propsData} />
				<PageHeader attributes={header_propsData} />
				<div className="content-main">{this.getContents()}</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage);

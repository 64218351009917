import axios from 'axios';
import op from 'object-path';


export function handleSubmitTransfer(
	email,
	spectatorId,
	data,
	selectedAction,
	callTicketActions,
	onSubmit,
	msg,
	msg2,
	errMsg
) {
	let ticketSourceId = data.map(a => a?.source?.id) || [];
	let transferShareData = {
		sid: spectatorId,
		target: email,
		tickets: ticketSourceId,
	};

	callTicketActions(selectedAction, spectatorId, transferShareData)
		.then(response => {
			logger.log('[ticketResaleEndpoints - handleSubmitTransfer] - response:%o', response);

			// additional error handling check
			// if response gives status 200, check to make sure there is no failure (failure path= response.data.response.failure[0].errorMessage)
			if (response?.data?.response?.failure[0]?.errorMessage) {
				onSubmit(selectedAction, { msg: response.data.response.failure[0].errorMessage });
			} else {
				onSubmit(selectedAction, null, msg, msg2);
			}
		})
		.catch(error => {
			logger.error('[ticketResaleEndpoints - handleSubmitTransfer] - error:%o', error);
			onSubmit(selectedAction, error, errMsg);
		});
}

export function handleJoinQueue(jwtToken, path, queueId, partySize, phoneNumber) {
	logger.log('[ticketResaleEndpoints] handleJoinQueue, queueId:%o, partySize:%o, phoneNumber:%o', queueId, partySize, phoneNumber);


    // /tickets/rest/queue/user/join - sends CIAM JWT as header Bearer token
	/* payload: {
		"queueId": {from queue config},
		"partySize": {integer},
		"bookingType": 2, // booking_type is a fixed value of 2 for now
		"phone: {user entered phone}
	} */

	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken}`,
			Accept: 'application/json',
		};

		let body = {
			queueId: queueId,
			partySize: partySize,
			bookingType: 2, // booking_type is a fixed value of 2 for now
			phone: phoneNumber,
		};

		logger.log('[ticketResaleEndpoints] handleJoinQueue - path:%o headers:%o body:%o', path, { headers }, body);


		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[ticketResaleEndpoints] handleJoinQueue - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0 && op.get(response, 'data.response.error', []).length == 0) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] handleJoinQueue - error:%o', error);
				reject(error);
			});
	});
}

export function handleGetQueueStatus(jwtToken, path) {
	logger.log('[ticketResaleEndpoints] handleGetQueueStatus called');

    // /tickets/rest/queue/user/status - sends CIAM JWT as header Bearer token
	/* Response
		The configuration response will have an array of queues
		Each should be identified by the queue_id
		For now, the only value we should need is the queue_status, 0 indicates queue is open, 1 indicates queue is closed
	*/

	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken}`,
			Accept: 'application/json',
		};

		let body = {};

		logger.log('[Tickets] handleGetQueueStatus - path:%o headers:%o body:%o', path, { headers });


		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[ticketResaleEndpoints] handleGetQueueStatus - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0 && op.get(response, 'data.response.error', []).length == 0) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] handleGetQueueStatus - error:%o', error);
				reject(error);
			});
	});
}

export function handleOnMyWayEndpoint(jwtToken, path, booking_id) {
	logger.log('[ticketResaleEndpoints] handleOnMyWayEndpoint called');

    // sends CIAM JWT as header Bearer token
	/* JSON Payload:
		{
			"queueStatus": 1
		}
	*/

	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken}`,
			Accept: 'application/json',
		};

		let body = {
			"queueStatus": 1,
			"bookingId": booking_id
		};

		logger.log('[ticketResaleEndpoints] handleOnMyWayEndpoint - path:%o headers:%o body:%o', path, { headers }, body);


		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[ticketResaleEndpoints] handleOnMyWayEndpoint - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0 && op.get(response, 'data.response.error', []).length == 0) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] handleOnMyWayEndpoint - error:%o', error);
				reject(error);
			});
	});
}

export function handleExitResaleQueueEndpoint(jwtToken, path, booking_id) {
	logger.log('[ticketResaleEndpoints] handleExitResaleQueueEndpoint called');

	// /tickets/rest/queue/user/update - sends CIAM JWT as header Bearer token


	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken}`,
			Accept: 'application/json',
		};

		let body = {
			"queueStatus": 4,
			"bookingId": booking_id,
			// queue_id
		};

		logger.log('[ticketResaleEndpoints] handleExitResaleQueueEndpoint - path:%o headers:%o body:%o', path, { headers }, body);


		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[ticketResaleEndpoints] handleExitResaleQueueEndpoint - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0 && op.get(response, 'data.response.error', []).length == 0) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] handleExitResaleQueueEndpoint - error:%o', error);
				reject(error);
			});
	});
}
 

export function handleGetResaleQueueConfig(jwtToken, path) {
	logger.log('[ticketResaleEndpoints] handleGetQueueStatus called');

    // /tickets/rest/queue/user/status - sends CIAM JWT as header Bearer token
	/* Response
		The configuration response will have an array of queues
		Each should be identified by the queue_id
		For now, the only value we should need is the queue_status, 0 indicates queue is open, 1 indicates queue is closed
	*/

	return new Promise((resolve, reject) => {
		/* Response:
			The configuration response will have an array of queues
			Each should be identified by the queue_id
			There is no queue name in the config, so we should have a configuration to match queue_id to displayed name
			To determine if queue is open or closed, we use queue_status, 0 indicates queue is open, 1 indicates queue is closed
			to determine if queue is hidden, we use the boolean queue_is_hidden
			if the queue is hidden, it should be disabled in the join select menu, e.g. "Centre Court - Full"
			if the queue is closed, it should be disabled in the join select menu, e.g. "Centre Court - Closed"
		*/
		let headers = {
			Authorization: `Bearer ${jwtToken}`,
			Accept: 'application/json',
		};

		let body = {};

		logger.log('[Tickets] handleGetResaleQueueConfig - path:%o headers:%o body:%o', path, { headers });


		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[ticketResaleEndpoints] handleGetResaleQueueConfig - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0 && op.get(response, 'data.response.error', []).length == 0) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] handleGetResaleQueueConfig - error:%o', error);
				reject(error);
			});
	});
}
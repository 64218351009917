import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Draws Archive Top Stats',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const year = Config.drawsArchive.year;
			const host = Config.drawsArchive.host;
			const allEventsCode = Config.drawsArchive.allEventsCode;
			const yearData = Config.drawsArchive.yearData;
			const flagImagePathLarge = Config.otherData.flagImagePathLarge;
			const flagImagePathSmall = Config.otherData.flagImagePathSmall;
			const pathObj = {
				year,
				host,
				allEventsCode,
				yearData,
				archiveDrawsAssetsPath: Config.otherData.archiveDrawsAssetsPath,
				MatchBoxConfig: { flagImagePathLarge, flagImagePathSmall },
			};
			logger.log('[DrawsArchiveYear] action.mount - data: %o', pathObj);

			dispatch({ type: deps.actionTypes.DRAWSARCHIVE_YEAR_LOAD, data: pathObj });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_YEAR_FILTER, data: data });
	},

	setYear: data => (dispatch, getState, store) => {
		logger.log('[DrawsArchiveYear] action.selectYear -data.year: %o', data.year);
		dispatch({ type: deps.actionTypes.DRAWSARCHIVE_YEAR_SET_YEAR, data: data.year });
	},
};

import SchedulePage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

// tournDay will be a field in the params obj.
// route is day<dayNum>.html
export default {
	//path:"/:lang/scores/schedule/(index|schedule):tournDay(|\\d|\\d\\d).html",
	path: ['/:lang/scores/schedule/index.html', '/:lang/scores/schedule/schedule:tournDay.html'],
	exact: true,
	component: SchedulePage,
	section: MENU_ITEMS.scoresSchedule,
	load: params => deps.actions.SchedulePage.mount(params),
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';
import { connect } from 'react-redux';
import deps from 'dependencies';
/**
 * -----------------------------------------------------------------------------
 * React Component: PDFReader
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		fullWindowType: state['Header']['type'],
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	fullwindow: data => dispatch(deps.actions.VideoPlayer.fullwindow(data)),
});
class PDFReader extends Component {
	constructor(props) {
		super(props);
		logger.log('[PDFReader] - props:%o', props);

		this.state = {
			...this.props.data,
			showPDF: false,
		};

		this.displayPDF = this.displayPDF.bind(this);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	displayPDF(e) {
		e.preventDefault();

		//add analytics to track when a pdf is opened
		MeasurementUtils.dispatchMeasurementCall('pdfReader', this.state.link);
		this.props.fullwindow({ className: 'fullwindow', type: 'pdf' });
		this.setState(prevState => {
			return {
				...prevState,
				showPDF: true,
			};
		});
	}

	closeReader() {
		this.props.fullwindow({ className: false, type: false });
		this.setState(prevState => {
			return {
				...prevState,
				showPDF: false,
			};
		});
	}

	render() {
		logger.log('[PDFReader] render - state:%o', this.state);
		let libPath = this.state.lib || '/en_GB/static/pdfreader/web/wow_book_plugin/index.html';
		return (
			<div className={`pdfreader ${this.state.style}`}>
				<a href={this.state.link} onClick={this.displayPDF}>
					{this.state.text}
				</a>
				{this.state.showPDF && this.state.fullWindowType === 'pdf' ? (
					<div className="pdf-reader-container">
						<i
							className="wim-icon-close"
							onClick={() => {
								this.closeReader();
							}}></i>
						<iframe
							src={`${libPath}?file=${this.state.link}`}
							allowFullScreen={true}
							marginWidth="0"
							marginHeight="0"
							frameBorder="0"
							className="embed-responsive-item"
						/>
					</div>
				) : null}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PDFReader);

export const birthDay = {
	DROPDOWN_103268785982798200_CHOICES_: '',
	DROPDOWN_103268785982798200_CHOICES_02E74F10E0327AD868D138F2B4FDD6F0: '27',
	DROPDOWN_103268785982798200_CHOICES_1679091C5A880FAF6FB5E6087EB1B2DC: '6',
	DROPDOWN_103268785982798200_CHOICES_1F0E3DAD99908345F7439F8FFABDFFC4: '19',
	DROPDOWN_103268785982798200_CHOICES_1FF1DE774005F8DA13F42943881C655F: '24',
	DROPDOWN_103268785982798200_CHOICES_33E75FF09DD601BBE69F351039152189: '28',
	DROPDOWN_103268785982798200_CHOICES_34173CB38F07F89DDBEBC2AC9128303F: '30',
	DROPDOWN_103268785982798200_CHOICES_37693CFC748049E45D87B8C7D8B9AACD: '23',
	DROPDOWN_103268785982798200_CHOICES_3C59DC048E8850243BE8079A5C74D079: '21',
	DROPDOWN_103268785982798200_CHOICES_45C48CCE2E2D7FBDEA1AFC51C7C6AD26: '9',
	DROPDOWN_103268785982798200_CHOICES_4E732CED3463D06DE0CA9A15B6153677: '26',
	DROPDOWN_103268785982798200_CHOICES_6512BD43D9CAA6E02C990B0A82652DCA: '11',
	DROPDOWN_103268785982798200_CHOICES_6EA9AB1BAA0EFB9E19094440C317E21B: '29',
	DROPDOWN_103268785982798200_CHOICES_6F4922F45568161A8CDF4AD2299F6D23: '18',
	DROPDOWN_103268785982798200_CHOICES_70EFDF2EC9B086079795C442636B55FB: '17',
	DROPDOWN_103268785982798200_CHOICES_8E296A067A37563370DED05F5A3BF3EC: '25',
	DROPDOWN_103268785982798200_CHOICES_8F14E45FCEEA167A5A36DEDD4BEA2543: '7',
	DROPDOWN_103268785982798200_CHOICES_98F13708210194C475687BE6106A3B84: '20',
	DROPDOWN_103268785982798200_CHOICES_9BF31C7FF062936A96D3C8BD1F8F2FF3: '15',
	DROPDOWN_103268785982798200_CHOICES_A87FF679A2F3E71D9181A67B7542122C: '4',
	DROPDOWN_103268785982798200_CHOICES_AAB3238922BCC25A6F606EB525FFDC56: '14',
	DROPDOWN_103268785982798200_CHOICES_B6D767D2F8ED5D21A44B0E5886680CB9: '22',
	DROPDOWN_103268785982798200_CHOICES_C16A5320FA475530D9583C34FD356EF5: '31',
	DROPDOWN_103268785982798200_CHOICES_C20AD4D76FE97759AA27A0C99BFF6710: '12',
	DROPDOWN_103268785982798200_CHOICES_C4CA4238A0B923820DCC509A6F75849B: '1',
	DROPDOWN_103268785982798200_CHOICES_C51CE410C124A10E0DB5E4B97FC2AF39: '13',
	DROPDOWN_103268785982798200_CHOICES_C74D97B01EAE257E44AA9D5BADE97BAF: '16',
	DROPDOWN_103268785982798200_CHOICES_C81E728D9D4C2F636F067F89CC14862C: '2',
	DROPDOWN_103268785982798200_CHOICES_C9F0F895FB98AB9159F51FD0297E236D: '8',
	DROPDOWN_103268785982798200_CHOICES_D3D9446802A44259755D38E6D163E820: '10',
	DROPDOWN_103268785982798200_CHOICES_E4DA3B7FBBCE2345D7772B0674A318D5: '5',
	DROPDOWN_103268785982798200_CHOICES_ECCBC87E4B5CE2FE28308FD9F2A7BAF3: '3',
};

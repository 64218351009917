import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MyWimContext } from './context';
import PlayerImage from 'appdir/components/content/PlayerImage';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';
import Carousel from 'appdir/components/common-ui/Carousel';
import NewsTile from 'appdir/components/content/NewsTile';
import axios from 'axios';
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';
import op from 'object-path';
import xor from 'lodash/xor';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';

const mapStateToProps = (state, props) => {
	return {
		playerFavorites: state['Controller']['favourites'],
		picBase: state['Config'].otherData.basePicPath,
		enableFavourite: op.get(state, 'Config.myWimbledon.favorites.enableContentFavoriting', false),
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	clearPlayers: () => dispatch(deps.actions.Controller.clearPlayers()),
	clearContent: () => dispatch(deps.actions.Controller.clearContent()),
});

class MyWimFavorites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			favouritesData: [],
			fetchedPlayerData: false,
			playersList: null,
			filteredPlayerList: [],
			relatedContent: [],
			fetchedRelatedData: false,
		};

		this.fetchFavorites = true;
		this.initialLoad = true;
		this.fetchPlayers = true;
		this.fetchRelated = true;
		this.handleChange = this.handleChange.bind(this);
		this.clearPlayerFavorites = this.clearPlayerFavorites.bind(this);
		this.renderIndividualFavoritePlayer = this.renderIndividualFavoritePlayer.bind(this);
		this.returnNotFoundPlayer = this.returnNotFoundPlayer.bind(this);

		logger.log('[MyWimFavorites] constructor - props %o', this.props);
	}

	componentDidMount() {
		logger.log('[MyWimFavorites] componentDidMount - state:%o context:%o', this.state, this.context);

		if (this.state.playerFavorites.players.length > 0) {
			this.fetchFavorites = true;
		} else if (this.state.playerFavorites.players.length == 0) {
			this.setState(prevState => {
				return {
					...prevState,
					favouritesData: [],
				};
			});
		}

		this.setPlayers({});
	}

	/**
	 * return players ids from favorites that exist in player list (players.json)
	 */
	getActivePlayerIds(playersListMap, playerFavoriteArry) {
		let activePlayerIds = [];
		for (let i = 0; i < playerFavoriteArry.length; i++) {
			let currId = playerFavoriteArry[i];
			if (playersListMap[currId]) {
				activePlayerIds.push(currId);
				logger.info('[MyWimFavorites] getActivePlayerIds found - currId:%o ', currId);
			} else {
				logger.info('[MyWimFavorites] getActivePlayerIds not found - currId:%o ', currId);
			}
		}
		return activePlayerIds;
	}

	returnNotFoundPlayer(data) {
		let updatedData = { ...data };
		if (data.first_name == null || data.last_name == null) {
			let playerInfo = this.state.playersList.filter(d => d.id == data.id);
			if (playerInfo.length > 0) {
				updatedData['first_name'] = playerInfo[0]['first_name'];
				updatedData['last_name'] = playerInfo[0]['last_name'];
				updatedData = {
					...updatedData,
					id: data.id,
					events_entered: null,
					last_status: null,
					last_opp: null,
					last_court: null,
					last_event: null,
					last_round: null,
					last_match_id: null,
					next_status: null,
					next_opp: null,
					next_court: null,
					next_event: null,
					next_round: null,
					next_match_id: null,
				};
			} else {
				return null;
			}
		}
		return updatedData;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[MyWimFavorites] componentDidUpdate - prevPlayers:%o',
			op.get(prevState, 'playerFavorites.players', 0)
		);
		logger.log('[MyWimFavorites] componentDidUpdate - prev:%o state:%o', prevState, this.state);

		let favPlayers = op.get(this.state, 'playerFavorites.players', []);
		let favArticles = op.get(this.state, 'playerFavorites.articles', []);
		let favVideos = op.get(this.state, 'playerFavorites.videos', []);

		if (favPlayers.length == 0 && this.fetchFavorites) {
			this.fetchFavorites = false;
			this.setState(prevState => {
				return {
					...prevState,
					fetchedPlayerData: true,
				};
			});
		}

		if (favArticles.length == 0 && favVideos.length == 0 && this.fetchRelated) {
			this.fetchRelated = false;
			this.setState(prevState => {
				return {
					...prevState,
					fetchedRelatedData: true,
				};
			});
		}

		if (prevState.playerFavorites.players.length == 0 && this.state.playerFavorites.players.length > 0)
			this.fetchFavorites = true;

		this.setPlayers(prevState);

		// if have player list (players.json) and flagged to fetch
		//   then get list of favorites that are in player list
		//	 compare that list to existing favorites list or if initial load
		//   and request data for diff
		if (this.state.playersList && this.fetchFavorites) {
			let playerIds = this.getActivePlayerIds(this.state.playerListMap, this.state.playerFavorites.players);
			if (xor(playerIds, prevState.playerFavorites.players).length > 0 || this.initialLoad) {
				this.fetchFavorites = false;
				this.initialLoad = false;

				let favoritePlayerId = this.state.favouritesData.map(d => d.playerID);
				let newPlayerIds = playerIds.filter(d => favoritePlayerId.indexOf(d) == -1);

				this.getFavoriteData(newPlayerIds)
					.then(() => {
						this.fetchFavorites = true;
					})
					.catch(() => {
						this.fetchFavorites = true;
					});
			}
		}

		// get data from related content for articles and videos
		// if (
		// 	this.state.relatedContent &&
		// 	this.state.relatedContent.length == 0 &&
		// 	this.fetchRelated &&
		// 	this.context.currentUser.UID
		// ) {
		// 	this.fetchRelated = false;
		// 	let articles = op.get(this.state, 'playerFavorites.articles', []);
		// 	let videos = op.get(this.state, 'playerFavorites.videos', []);
		// 	let favoriteArry = articles.concat(videos).map(d => `${this.context.api.contentId.replace('<id>', d)}`);
		// 	if (favoriteArry.length > 0) {
		// 		let url = `${this.context.api.favoriteRelated}?ids=${favoriteArry.join('&ids=')}`;
		// 		axios({
		// 			method: 'get',
		// 			url: url,
		// 			headers: { 'Content-type': 'application/json' },
		// 		}).then(res => {
		// 			this.setState(prevState => {
		// 				return {
		// 					...prevState,
		// 					relatedContent: res.data.content,
		// 					fetchedRelatedData: true,
		// 				};
		// 			});
		// 		});
		// 	}
		// }
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let currPlayers = op.get(nextProps, 'playerFavorites.players', []);
		let currArticles = op.get(nextProps, 'playerFavorites.articles', []);
		let currVideos = op.get(nextProps, 'playerFavorites.videos', []);
		let currContent = currArticles.concat(currVideos).concat(currPlayers);

		if (currContent.length == 0) {
			this.setState(prevState => {
				return {
					...prevState,
					...nextProps,
					relatedContent: [],
					favouritesData: [],
				};
			});
		} else {
			this.setState(prevState => {
				return {
					...prevState,
					...nextProps,
				};
			});
		}
	}

	clearPlayerFavorites() {
		this.state.clearPlayers();
	}

	/**
	 * request data for list of new favorite ids
	 */
	getFavoriteData(playerIds) {
		return axios
			.all(
				playerIds.map((d, i) => {
					return axios({
						method: 'get',
						url: this.context.api.favorites.replace('<playerId>', d),
					}).catch(() => {
						return null;
					});
				})
			)
			.then(result => {
				result = result
					.filter(d => d !== null)
					.map(d => {
						return d.data;
					});
				let favouritesData = JSON.parse(JSON.stringify(this.state.favouritesData));
				favouritesData = favouritesData.concat(result);
				this.setState(prevState => {
					return {
						...prevState,
						favouritesData: favouritesData,
						fetchedPlayerData: true,
					};
				});
			});
	}

	clearContentFavorites() {
		this.state.clearContent();
	}

	handleChange(e) {
		let searchQuery = e.target.value;
		let filteredResult = this.state.playersList.filter(d => {
			const playerFullName = `${d.first_name.toLowerCase()} ${d.last_name.toLowerCase()}`.toLowerCase();
			return playerFullName.indexOf(searchQuery.toLowerCase()) !== -1;
		});

		logger.log('[MyWimFavorites] handleChange');

		this.setState(prevState => {
			return {
				...prevState,
				filteredPlayerList: searchQuery.length > 0 ? filteredResult : [],
			};
		});
	}

	/**
	 * create player list maps from players loaded in players.json
	 */
	setPlayers() {
		if (!this.state.playersList && this.fetchPlayers) {
			this.fetchPlayers = false;
			let playerListMap = {};
			axios({
				method: 'get',
				url: this.context.api.players,
			}).then(result => {
				let playerList = op.get(result, 'data.players', []);
				logger.log('[MyWimFavorites] Players list %o', playerList);

				this.setState(prevState => {
					for (let i = 0; i < playerList.length; i++) {
						let currPlayer = playerList[i];
						playerListMap[currPlayer['id']] = currPlayer;
					}
					return {
						...prevState,
						playersList: playerList,
						playerListMap,
					};
				});
			});
		}
	}

	getCurrentMatch(data) {
		const { displayMatch } = data;
		let matchDetails = {};
		const { playerFirstName, playerID, playerLastName, playerTvName } = data;
		//find the match that matches the displayMatch
		matchDetails['player_info'] = {
			first_name: playerFirstName,
			playerid: playerID,
			last_name: playerLastName,
			tv_name: playerTvName,
		};

		for (let i = 0; i < data.matches.length; i++) {
			let currMatch = data.matches[i];
			if (currMatch['match_id'] === displayMatch) {
				matchDetails['match_details'] = { ...currMatch };
				matchDetails['match_status'] = data['inOut'];
				matchDetails['player_info']['team'] = this.determineTeam(currMatch, playerID);
			}
		}
		return matchDetails;
	}

	generateStatusText(data) {
		// let whichStatus = data.next_status !== null ? 'next' : 'last';
		let matchStatus = null;
		//determine status
		if (data.match_status != 'out') {
			//get match status
			if (data.match_status == 'in') {
				const matchTeam = this.determineTeam(data['match_details'], data['player_info']['playerid']);
				if (matchTeam['won']) {
					matchStatus = 'WON';
				} else {
					//check for match status
					switch (data.match_details.statusCode) {
						case 'B': {
							matchStatus = 'UPCOMING';
							break;
						}
						case null: {
							matchStatus = 'UPCOMING';
							break;
						}
						case 'K': {
							matchStatus = 'SUSPENDED';
							break;
						}
						case 'A': {
							matchStatus = 'LIVE';
							break;
						}
						case 'X': {
							matchStatus = 'LIVE';
							break;
						}
						case 'Y': {
							matchStatus = 'LIVE';
							break;
						}
						case 'D': {
							matchStatus = 'LOST';
							break;
						}
						case 'E': {
							matchStatus = 'LOST';
							break;
						}
						case 'F': {
							matchStatus = 'LOST';
							break;
						}
						case 'G': {
							matchStatus = 'LOST';
							break;
						}
					}
				}
			}
		} else {
			matchStatus = 'OUT';
		}

		return {
			matchStatus: matchStatus,
			playerOpponent: data?.player_info?.team?.opponent ? data['player_info']['team']['opponent'] : null,
			court: data?.match_details?.courtName ? data['match_details']['courtName'] : null,
			round: data?.match_details?.roundName ? data['match_details']['roundName'] : null,
			event: data?.match_details?.eventName ? data['match_details']['eventName'] : null,
		};
	}

	constructItems(data) {
		if (this.state.playersList) {
			let dataArray = this.constructFavoritesArry(data);
			return dataArray.map(d => {
				return { renderItem: this.renderPlayerFavoriteSlide.bind(this, d) };
			});
		}
	}

	constructFavoritesArry(data) {
		// let updatedData = data.map(d => this.returnNotFoundPlayer(d)).filter(d => d !== null);
		// let playerFavoriteArry = updatedData.slice();
		let playerFavoriteArry = data.slice();
		let restructuredPlayerFavArry = [];
		if (this.context.windowSize == 'mobile') {
			restructuredPlayerFavArry = playerFavoriteArry.map(d => [d]);
		} else if (this.context.windowSize == 'tablet') {
			while (playerFavoriteArry.length !== 0) {
				restructuredPlayerFavArry.push(playerFavoriteArry.splice(0, 3));
			}
		} else {
			while (playerFavoriteArry.length !== 0) {
				restructuredPlayerFavArry.push(playerFavoriteArry.splice(0, 6));
			}
		}
		return restructuredPlayerFavArry;
	}

	slideDirection(direction) {
		let directionMeasured = direction == 'left' ? 'Previous' : 'Next';
		MeasurementUtils.dispatchMeasurementCall('myWimFavoritesSlide', { direction: directionMeasured });
	}

	determineTeam(currMatch, playerID) {
		const { team1, team2 } = currMatch;
		//get team length
		let team1Length = team1.length;
		let team2Length = team2.length;

		//check if any team length is greater than one or equal to 0
		if (team1Length == 0 || team1Length > 1) {
			let teamInfo = team2[0];
			return {
				team: 'team2',
				won: teamInfo['won'],
				opponent: 'TBD',
			};
		}

		if (team2Length == 0 || team2Length > 1) {
			let teamInfo = team1[0];
			return {
				team: 'team1',
				won: teamInfo['won'],
				opponent: 'TBD',
			};
		}

		//find player info
		let playerInTeam1 = team1.filter(d => d.idA == playerID || d.idB == playerID).length > 0;
		let playerInTeam2 = team2.filter(d => d.idA == playerID || d.idB == playerID).length > 0;

		if (playerInTeam1) {
			let teamInfo = team1[0];
			let opponentInfo = team2[0];
			let opponentNameArry = [opponentInfo['displayNameA'], opponentInfo['displayNameB']];
			let opponentName = opponentNameArry.filter(d => d !== null).join('/');

			return {
				team: 'team1',
				won: teamInfo['won'],
				opponent: opponentName,
			};
		}

		if (playerInTeam2) {
			let teamInfo = team2[0];
			let opponentInfo = team1[0];
			let opponentNameArry = [opponentInfo['displayNameA'], opponentInfo['displayNameB']];
			let opponentName = opponentNameArry.filter(d => d !== null).join('/');

			return {
				team: 'team2',
				won: teamInfo['won'],
				opponent: opponentName,
			};
		}

		return null;
	}

	renderIndividualFavoritePlayer(d) {
		if (d !== null) {
			const displayMatchDetails = this.getCurrentMatch(d);
			let statusText = this.generateStatusText(displayMatchDetails);
			const { playerid, first_name, last_name } = displayMatchDetails['player_info'];
			return (
				<div key={playerid} className="one-third player-fav-container">
					<div className="player-info">
						<div className="player-imgs">
							<FavouriteStar
								id={playerid}
								type="players"
								unFavColor="green"
								favColor="green"
								alwaysShow={false}
							/>
							<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
								<PlayerImage
									attributes={{
										'player-image': `//images.wimbledon.com/square/${playerid}.jpg`,
										style: `small${statusText.matchStatus == 'OUT' ? ' out' : ''}`,
									}}
								/>
							</WimLink>
						</div>
						<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
							<div className="player-name">
								{statusText.matchstatus ? (
									statusText.matchStatus.indexOf('WON') !== -1 ? (
										<i className="wim-icon-check" />
									) : null
								) : null}
								{first_name} {last_name}
							</div>
						</WimLink>
						<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
							<div className="player-status">{statusText.matchStatus}</div>
						</WimLink>
						{statusText.playerOpponent ? (
							<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
								<div className="opp-name">Against {statusText.playerOpponent}</div>
							</WimLink>
						) : null}
						<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
							<div className="event-name">{statusText.event}</div>
						</WimLink>
						<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
							<div className="round-name">{statusText.round}</div>
						</WimLink>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	renderPlayerFavoriteSlide(data) {
		if (data) {
			return (
				<div className="column-layout">
					{data.map((d, i) => {
						return d ? this.renderIndividualFavoritePlayer(d) : null;
					})}
				</div>
			);
		}
	}

	renderNav(direction, clickFn) {
		return (
			<div
				onClick={() => {
					clickFn(direction);
					this.slideDirection(direction);
				}}
				className={`arrow-wrapper-${direction}`}>
				<i className={`wim-icon-${direction}-arrow`} />
			</div>
		);
	}

	render() {
		let contentItems = [];
		let navJSON = {};
		let readList = [],
			watchList = [];
		if (this.state.favouritesData.length > 0) {
			contentItems = this.constructItems(this.state.favouritesData);
			navJSON = {
				leftNav: this.renderNav.bind(this),
				rightNav: this.renderNav.bind(this),
			};
		}

		// if (this.state.relatedContent) {
		// 	readList =
		// 		this.state.relatedContent.length > 0 ? this.state.relatedContent.filter(d => d.type == 'news') : [];
		// 	watchList =
		// 		this.state.relatedContent.length > 0 ? this.state.relatedContent.filter(d => d.type != 'news') : [];
		// }

		logger.log('[MyWimFavorites] render - state:%o', this.state);
		//logger.log("[MyWimFavorites] render readList:%o", readList);

		return (
			<div className="favorites-container">
				<span>
					<div className="column-layout no-padding left">
						<div className="one-col search">
							<div className="search-container">
								<div className="search-header">Your Favourites</div>
								<div className="search-input">
									<img className="search-icon" src="/assets/images/misc/magnifying-glass.svg" />
									<input type="text" placeholder="Search for players" onChange={this.handleChange} />
								</div>
								<div className="search-results">
									{this.state.filteredPlayerList.length > 0
										? this.state.filteredPlayerList.map((d, i) => {
												return (
													<div key={i} className="player-result">
														<FavouriteStar
															id={d.id}
															type="players"
															unFavColor="green"
															favColor="green"
															alwaysShow={false}
														/>
														{`${d.first_name} ${d.last_name}`}
													</div>
												);
										  })
										: null}
								</div>
							</div>
						</div>

						{contentItems.length > 0 ? (
							contentItems.length > 1 ? (
								<div className="three-col fav-players">
									<Carousel
										key={'myWimbledon-favorites'}
										attributes={{
											items: contentItems,
											showNav: false,
											showFullscreenButton: false,
											showThumbnails: false,
											showPlayButton: false,
											customNav: true,
											customNavHTML: navJSON,
										}}
									/>
								</div>
							) : (
								<div className="three-col fav-players no-carousel">
									<div className="column-layout">
										{this.state.favouritesData.map((d, i) => {
											return d ? this.renderIndividualFavoritePlayer(d) : null;
										})}
									</div>
								</div>
							)
						) : this.state.fetchedPlayerData && this.state.favouritesData.length == 0 ? (
							<div className="three-col no-favorite-players">
								<div className="no-players-text">No Favourite Players</div>
							</div>
						) : null}
					</div>

					{/* {this.state.enableFavourite ? (
						<div className="column-layout favorite-content">
							<div className="related-content-title four-col">
								<div className="read-title">Read These</div>
								{this.state.relatedContent && this.state.relatedContent.length > 0 ? (
									<Button
										className={`btn light-btn`}
										onClick={() => {
											this.clearContentFavorites();
										}}>
										Clear All
									</Button>
								) : null}
							</div>
							{readList.length > 0 ? (
								readList.map((d, i) => {
									d.images = d.images[0] ? d.images[0] : d.images;
									return <NewsTile attributes={{ ...d, 'col-style': 'one-col' }} key={d.cmsId} />;
								})
							) : readList.length == 0 && this.state.fetchedRelatedData ? (
								<div className="four-col no-favorite-content">
									<div className="no-content-text">No Favourite Content Available</div>
								</div>
							) : null}
							<div className="related-content-title four-col">
								<div className="read-title">View These</div>
							</div>
							{watchList.length > 0 ? (
								watchList.map((d, i) => {
									d.images = d.images[0] ? d.images[0] : d.images;
									return <NewsTile attributes={{ ...d, 'col-style': 'one-col' }} key={d.cmsId} />;
								})
							) : watchList.length == 0 && this.state.fetchedRelatedData ? (
								<div className="four-col no-favorite-content">
									<div className="no-content-text">No Favourite Content Available</div>
								</div>
							) : null}
						</div>
					) : null} */}
				</span>
			</div>
		);
	}
}

MyWimFavorites.contextType = MyWimContext;
export default connect(mapStateToProps, mapDispatchToProps)(MyWimFavorites);

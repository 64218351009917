import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.WEATHERDATA_CHECK:
			newState = {
				...state,
			};
			return newState;

		case deps.actionTypes.WEATHERDATA_EXPIRED:
			newState = {
				...state,
				status: 'expired',
			};
			return newState;

		case deps.actionTypes.WEATHERDATA_LOADED:
			newState = {
				...state,
				...action.data,
				refreshSec: action.seconds,
				status: 'loaded',
				updated: action.updated,
			};
			if (newState.hasOwnProperty('error')) {
				delete newState.error;
			}
			return newState;

		case deps.actionTypes.WEATHERDATA_LOADING:
			newState = {
				...state,
				status: 'loading',
			};
			if (newState.hasOwnProperty('error')) {
				delete newState.error;
			}
			return newState;

		case deps.actionTypes.WEATHERDATA_ERROR:
			newState = {
				...state,
				status: 'error',
				error: action.error,
			};
			return newState;

		default:
			return state;
	}
};

import axios from 'axios';
import deps from 'dependencies';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { searchResults } from 'appdir/components/data/Schema';
import op from 'object-path';

const v = new Validator();

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

/**
 *
 * @param {String} path
 */
const fetchSearchResults = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				//logger.log('[SearchPage] fetchRelated - 200 valid response');
				let validateResult = v.validate(response.data, searchResults);
				if (validateResult.valid) {
					return response.data;
				} else {
					// throw error and go to catch block
					throw new Error('failed schema validation');
				}
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			logger.warn('[SearchPage] fetchRelated - %o', error.message);
			throw new Error(error.message);
		});
};

/**
 *
 * @param {String} path
 */
const fetchRoleSearchResults = (path, dispatch, store) => {
	return new Promise((resolve, reject) => {
		deps.services.Gigya.getJWT(dispatch, store)
			.then(token => {
				let axiosConfig = {
					method: 'get',
					url: path,
					headers: {
						'Content-type': 'application/json',
						Authorization: 'Bearer ' + token.jwt.id_token,
					},
				};

				let request = axios(axiosConfig)
					.then(res => {
						let respData = op.get(res, 'data', false);
						if (res.status == 200 && respData) {
							resolve(respData);
						} else {
							//params.callback({ error: 'response error' });
							logger.error('[SearchPage] services.fetchRoleSearchResults error: %o', error);
							reject(error);
						}
					})
					.catch(error => {
						logger.error('[SearchPage] services.fetchRoleSearchResults error: %o', error);
						reject(error);
					});
			})
			.catch(error => {
				logger.error('[SearchPage] services.fetchRoleSearchResults error: %o', error);
				reject(error);
			});
	});
};

export default {
	fetch,
	fetchSearchResults,
	fetchRoleSearchResults,
};

import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class PopOpen extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		
		}
		this.amp = props.amp;
		this.styles = props.styles;

		//logger.log('PopOpen: constructor - state:%o', this.state);
	}

	onClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}
	
	render() {
		//logger.log('PopOpen: render - state:%o', this.props);
		let inline = {};

		if(this.props.first) {
			inline = {
				margin: "0 0 0 10px"
			}
		}

		return (
			<button 
				className={`${this.styles.icon} ${this.styles.popOpen}`} 
				style={inline} 
				onClick={() => {this.onClick()}} 
				title="PopOpen">
			</button>
		)
	}
	
}

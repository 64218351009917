import React, { Component } from 'react';
import CustomActionConfirmation from 'appdir/components/pages/Tickets/elements/TicketActionViews/CustomActionConfirmation';
import { getQuerystringParam } from 'appdir/components/general/Util';
import ScanQRCode from './ScanQRCode';
import SwapTicketAction from './SwapTicketAction';
import SingleTicketStub from '../SingleTicketStub';
import Script from 'react-load-script';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';
import { courtImageObj } from 'appdir/components/pages/Tickets/elements/consts.js'


const courtCodesObj = {
	CCOURT: 'Centre Court',
	N1COURT: 'No.1 Court',
	N2COURT: 'No.2 Court',
	N3COURT: 'No.3 Court',
	COURT12: 'Court 12',
	COURT18: 'Court 18',
	GROUNDS: 'Grounds Pass',
	GA: 'General Admission',
};

const smallMobileMatch = window.matchMedia('(max-width: 320px)').matches;

/* NOTE: QR code generator came from: https://www.jsnow.io/p/javascript/creating-a-real-time-qr-code-scanner-with-vanilla-javascript-part-1 */

class TicketSwapInitial extends Component {
	constructor(props) {
		super(props);

		const debugView = getQuerystringParam(null, 'ticketDebug');

		this.state = {
			debugView: debugView,
			showQRcode: false,
			scanQRcode: false,
			scannedSuccessful: false,
			loading: false,
			qrCodeScanned: '',
		};
	}

	onRenderSingleTicketStub(err, stringToParse) {
		logger.log('[TicketSwapInitial] onRenderSingleTicketStub', stringToParse);

		let id, court, type, gangway, row, seat, courtDetails, date;

		let ticket = this.props.fullTicketValue;

		if (stringToParse) {
			let splitTxt = stringToParse?.split('*');

			id = splitTxt[0];
			gangway = `Gangway ${splitTxt[3]}`;
			row = `Row ${splitTxt[4]}`;
			seat = `Seat ${splitTxt[5]}`;
			type = splitTxt[6] || '';
			courtDetails = splitTxt[7] || '';
			date = splitTxt[8] || '';
			court = courtCodesObj[courtDetails];
		} else {
			id = ticket.externalId;
			court = ticket?.detailedInformation?.court;
			gangway = ticket?.detailedInformation?.gangway ? `Gangway ${ticket?.detailedInformation?.gangway},` : '';
			row = ticket?.detailedInformation?.row ? `Row ${ticket?.detailedInformation?.row},` : '';
			seat = ticket?.detailedInformation?.seat ? `Seat ${ticket?.detailedInformation?.seat}` : '';
			courtDetails = ticket.source.details.main.find(({ key }) => key.toLowerCase() === 'court')?.value;
			type = ticket?.typeLabel;
			date = ticket?.day !== 'UGP' ? ticket.date : '';
		}

		return (
			<SingleTicketStub
				middleContents={{
					court: court,
					type: type,
					gangway: gangway,
					row: row,
					seat: seat,
				}}
				imageToRender={
					courtImageObj[courtDetails?.toLowerCase() || ''] || '/assets/images/tickets/Tickets_GroundsPass.png'
				} // have a default image in case no match
				id={id}
				error={err}
				date={date}
				isWebview
			/>
		);
	}

	handleScriptCreate() {
		this.setState({ scriptLoaded: false });
	}

	handleScriptError() {
		this.setState({ scriptError: true });
	}

	handleScriptLoad() {
		this.setState({ scriptLoaded: true });
	}

	generate(values) {
		document.querySelector('.ticket-swap-qr-code').style = '';

		let { ticketId, spectatorId } = values;
		const { fullTicketValue } = this.props;

		// add 5 mins
		const epoch5mins = moment()
			.add(5, 'minutes')
			.valueOf();

		// use * as seporators to parse values needed for swap
		let txtStr = `${ticketId}*${spectatorId}*${epoch5mins}*${fullTicketValue?.detailedInformation?.gangway}*${
			fullTicketValue?.detailedInformation?.row
		}*${fullTicketValue?.detailedInformation?.seat}*${fullTicketValue?.typeLabel}*${
			fullTicketValue?.source?.details?.main?.find(({ key }) => key.toLowerCase() === 'court')?.value
		}*${fullTicketValue?.day !== 'UGP' ? fullTicketValue?.date : ''}`.trim(); // trim whitespace
		// let base64encoded = btoa(txtStr);

		new QRCode(document.querySelector('.ticket-swap-qr-code'), {
			text: txtStr,
			width: !smallMobileMatch ? 312 : 280, // check for mobile width of 320px
			height: !smallMobileMatch ? 312 : 280, // check for mobile width of 320px
			colorDark: '#000000',
			colorLight: '#ffffff',
			correctLevel: QRCode.CorrectLevel.H,
		});
		this.setState({ showQRcode: true });
	}

	onHandleQRcode(QRcodeStr) {
		/* NOTE: keeping commented in case we want to switch it back to base64 encoding */
		// check if string is base64 encoded, otherwise errors out if wrong qr code type
		// let isBase64;
		// try {
		// 	isBase64 = btoa(atob(val)) == val;
		// } catch (err) {
		// 	isBase64 = false;
		// }

		// let decodedVal, epochLimit, isWimTicket;
		// if (isBase64) {
		// 	decodedVal = atob(val);
		// 	epochLimit = epochLimit = decodedVal?.split('*')[2];
		// 	isWimTicket = decodedVal.substring(0, 24) === 'www.wimbledon.com@AELTC-';
		// }

		let decodedStr = QRcodeStr;
		let epochLimit = (epochLimit = decodedStr?.split('*')[2]);
		let isWimTicket = decodedStr.substring(0, 24) === 'www.wimbledon.com@AELTC-';

		// set state for QR code scanned
		// compare date to epoch to make sure it comes before
		if (this.state.scanQRcode) {
			this.setState({
				qrCodeScanned: decodedStr,
				scanQRcode: false,
				scannedSuccessful: isWimTicket && epochLimit && moment().valueOf() < parseInt(epochLimit),
				ticketScanned: decodedStr,
				isTimeoutError: epochLimit && moment().valueOf() > parseInt(epochLimit),
				isInvalidQRError: !isWimTicket,
			});
		}
	}

	onSwapSuccessful() {
		if (window.webview) {
			measureInAppContext({
				pageTitle: 'Tickets',
				action: 'Swap Ticket Sumbit',
				args: 'Successful',
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('Swap Ticket Sumbit Successful');
		}
		this.setState({ showConfirmationModal: true, loading: false });
	}

	onHandleSwapError(msg1, msg2) {
		if (window.webview) {
			measureInAppContext({
				pageTitle: 'Tickets',
				action: 'Swap Ticket Sumbit',
				args: 'Failure',
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('Swap Ticket Sumbit Failed');
		}
		this.setState({
			showConfirmationModal: true,
			loading: false,
			isError: true,
			errorMsg1: msg1,
			errorMsg2: msg2,
		});
	}

	render() {
		const { fullTicketValue, callTicketActions, ticketStubToRender } = this.props;

		// let ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];
		// const spectatorId = ticketValue[0]?.spectatorId || '';

		// let swappedRecipientEmail = ticketValue[0]?.source?.transfer?.toEmail || '';

		const { showQRcode, scanQRcode, scannedSuccessful } = this.state;

		logger.log('[TicketSwapInitial] ticketScanned', this.state.ticketScanned);

		let descriptionTxt =
			'To complete a ticket swap, one party must scan the QR code and the other party must show the QR code using the buttons below. It will not work to scan the actual ticket.';

		let bodyToRender;

		if (this.state.showConfirmationModal) {
			bodyToRender = (
				<div className="ticket-swap-confirmation">
					<CloseBtn
						closeFn={() => {
							this.props.onClose('refresh');
						}}
					/>
					<div
						className="ticket-action-modal-title"
						tabIndex={0}
						aria-label="swap tickets view. choose to scan QR code or to show QR code">
						SWAP TICKETS
					</div>
					<div className="ticket-action-modal-divider-container">
						<div className="ticket-action-modal-title-divider" />
					</div>
					<CustomActionConfirmation
						// fullTicketValue={ticketValue}
						// recipientEmail={swappedRecipientEmail}
						closeModal={() => {
							this.props.onClose('refresh');
							this.setState({
								showConfirmationModal: false,
								scanQRcode: false,
								isError: false,
								showQRcode: false,
								scannedSuccessful: false,
							});
						}}
						ticketData={this.props.ticketData}
						noRenderTicketStub
						headerTxt={
							this.state.isError
								? 'The tickets were not swapped.'
								: 'The tickets have been swapped successfully.'
						}
						detailsTxt={
							this.state.isError
								? `${this.state.errorMsg1} ${this.state.errorMsg2}`
								: 'The ticket list may take a few minutes for the ticket status to change'
						}
						actionType={'Swap'}
						error={this.state.isError}
						isWebView
						showCloseBtn
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				</div>
			);
		} else if (this.state.loading) {
			bodyToRender = (
				<section
					className={this.debugView ? 'ticketDebugWebViewBackground wrapper webview' : 'wrapper webview'}>
					<div className="content-main">
						<LoadingIndicator type={'white'} showTxt="The tickets may take a moment to swap." />
					</div>
				</section>
			);
		} else {
			bodyToRender = (
				<div>
					<div style={{ display: !showQRcode && !scanQRcode ? 'inline-block' : 'none' }}>
						<div
							className="ticket-swap-error-msg"
							alt="QR code has expired. Please generate a new QR code to swap with."
							aria-label="QR code has expired. Please generate a new QR code to swap with."
							tabIndex={0}
							style={{
								display:
									this.state.isError || this.state.isInvalidQRError || this.state.isTimeoutError
										? 'block'
										: 'none',
							}}>
							<img className="tix-action-error-img" src="/assets/images/tickets/errorActionIcon.svg" />
							<div style={{ fontWeight: 'bold', marginBottom: '16px', display: 'inline-block' }}>
								Swap Error
							</div>
							<br></br>
							<span>
								{this.state.isTimeoutError
									? 'The scanned QR code has expired. Please try again with a new QR code.'
									: 'QR code not recognized. Please try again with a new QR code.'}
							</span>
						</div>
						<div
							className="ticket-swap-informative-txt"
							aria-label={descriptionTxt}
							tabIndex={0}
							style={{ visibility: !scannedSuccessful ? 'visible' : 'hidden' }}>
							{descriptionTxt}
						</div>
						<div className="ticket-swap-action-btns-cntr">
							<button
								role="button"
								alt="scan QR code"
								aria-label="scan QR code"
								className="ticket-stub-actions-value ticket-swap-action-btns"
								onClick={() => this.setState({ scanQRcode: true, isError: false })}>
								Scan QR Code
							</button>
							<b>OR</b>
							<button
								role="button"
								alt="show qr code"
								aria-label="show qr code"
								className="ticket-stub-actions-value ticket-swap-action-btns"
								onClick={() => {
									const ticketId = this.props.fullTicketValue?.source?.id;
									const { spectatorId } = this.props.fullTicketValue;
									if (ticketId && spectatorId) {
										if (document.querySelector('.ticket-swap-qr-code').childElementCount == 0) {
											this.generate({ ticketId: ticketId, spectatorId: spectatorId });
										} else {
											document.querySelector('.ticket-swap-qr-code').innerHTML = '';
											this.generate({ ticketId: ticketId, spectatorId: spectatorId });
										}
									} else {
										document.querySelector('.ticket-swap-qr-code').style = 'display: none'; // not valid input
									}
									this.setState({ isError: false });
								}}
								id="generateQRcodeBtn"
								type="submit">
								Show QR Code
							</button>
						</div>
						<button
							role="button"
							alt="back to ticket list"
							aria-label="back to ticket list"
							className="ticket-swap-back-btn"
							style={{ visibility: !scannedSuccessful ? 'visible' : 'hidden' }}
							onClick={() => this.props.onClose(this.state.scannedSuccessful)}>
							<img aria-label="back arrow icon" role="image" src="/assets/images/misc/back_arrow.png" />
							Back
						</button>
					</div>
					{this.state.scanQRcode ? (
						<ScanQRCode
							onBackClicked={() => this.setState({ scanQRcode: false })}
							onScannedQRCode={val => this.onHandleQRcode(val)}
						/>
					) : null}
					{scannedSuccessful ? (
						<SwapTicketAction
							closeModal={() => this.props.onClose()}
							fullTicketValue={fullTicketValue}
							callTicketActions={callTicketActions}
							refreshData={this.props.refreshData}
							ticketData={this.props.ticketData}
							onRefreshTicketData={() => this.props.onSoftRefreshData()}
							onRenderSingleTicketStub={(err, value) => this.onRenderSingleTicketStub(err, value)}
							isWebView
							ticketStubToRender={ticketStubToRender}
							ticketStubSwapToRender={this.onRenderSingleTicketStub(null, this.state.ticketScanned)}
							setLoading={val => this.setState({ loading: val })}
							qrCodeScanned={this.state.qrCodeScanned}
							onSwapSuccessful={() => this.onSwapSuccessful()}
							onHandleSwapError={(msg1, msg2) => this.onHandleSwapError(msg1, msg2)}
						/>
					) : null}

					<Script
						url="/en_GB/static/qr/generateQRcode.js"
						onCreate={this.handleScriptCreate.bind(this)}
						onError={this.handleScriptError.bind(this)}
						onLoad={this.handleScriptLoad.bind(this)}
					/>

					<b
						style={{ display: this.state.showQRcode ? 'block' : 'none', paddingTop: '16px' }}
						tabIndex={0}
						alt="hold up QR code to be scaned to swap. QR code is displayed and waiting to be scanned">
						QR CODE:
					</b>
					<div
						alt="qr code image"
						aria-label="qr code image"
						className="ticket-swap-qr-code"
						style={{ display: 'none' }}></div>
					<div
						className="ticket-swap-informative-txt"
						style={{ display: this.state.showQRcode ? 'inline-block' : 'none' }}>
						Present this QR code to the person you are swapping with, so that they may scan to start the
						ticket swap.
					</div>
					<button
						role="button"
						alt="back to previous page"
						aria-label="back to previous page"
						className="ticket-swap-back-btn"
						style={{ display: this.state.showQRcode ? 'block' : 'none' }}
						onClick={() => {
							document.querySelector('.ticket-swap-qr-code').innerHTML = '';
							document.querySelector('.ticket-swap-qr-code').style = 'display: none';
							this.setState({ showQRcode: false });
						}}>
						{`< Back`}
					</button>
				</div>
			);
		}

		return (
			<div
				className={
					this.state.debugView
						? 'ticketDebugWebViewBackground ticket-swap-container'
						: 'ticket-swap-container'
				}>
				{bodyToRender}
			</div>
		);
	}
}

export default TicketSwapInitial;

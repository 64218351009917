/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PrivacyBanner
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['PrivacyBanner'],
		//privacybanner: state['Controller'].privacy,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({

});

class PrivacyBanner extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		//this.state.expanded = false;
		//logger.log('[PrivacyBanner] constructor - state:%o', this.state);

		//this.init = true;
	}



	render() {

		return null;
		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyBanner);

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { convertPoints } from 'appdir/components/general/Util/MatchPoints';

/**
 *
 * @param {String} path
 */
const fetch = path => {
	let hdr = restHeaders();
	logger.log('[MatchArchive] services.fetch - path: %o', path);
	return axios
		.get(path, { headers: hdr })
		.then(({ data }) => data)
		.catch(error => {
			logger.log('[MatchArchive] fetch - error:%o', error);
		});
};

const fetchPoints = (path, match, matchOffset) => {
	let hdr = restHeaders();

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				logger.log('[MatchArchive] fetchPoints - match:%o offset:%o', match, matchOffset);
				logger.log('[MatchArchive] fetchPoints - response.data:%o', response.data);

				let start = response.data.points[0].epochTimeStart;
				if (matchOffset.epochVideoOffset > 0) {
					start = response.data.points[0].epochTimeStart - matchOffset.epochVideoOffset;
				} else if (response.data.epochVideoOffset) {
					start = response.data.points[0].epochTimeStart - response.data.epochVideoOffset;
				}
				let data = convertPoints(match, response.data.points, start, matchOffset);

				return data;
			}
		})
		.catch(error => {
			logger.log('[MatchArchive] fetchPoints - error:%o', error);
			if (error.response && error.response.status == 404) {
				logger.log('[MatchArchive] fetchPoints - error1:%o', error.response);
				throw error.response;
			} else {
				logger.log('[[MatchArchive] fetchPoints - error2:%o', error);
				throw error;
			}
		});
};

export default {
	fetch,
	fetchPoints,
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */

import React from 'react';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PowerRanking from 'appdir/components/pages/MatchInsights/elements/PowerRanking';
import { getTeamsLikelihoodWinData } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';

import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: Slamtracker Likelihood To Win
 * -----------------------------------------------------------------------------
 */

const LikelihoodToWin = props => {
	logger.log('[LikelihoodToWin] props:%o', props);
	
    const teams = [props.data.statMatch.team1, props.data.statMatch.team2];

	return (
		<section className="slamtracker-powerranking content">
			{op.get(props, 'data.powerIndexMatchup') && op.get(props, 'data.statMatch') ? (
				<>
					{/** make sure players have the probablility number  */
					getTeamsLikelihoodWinData(props.data.powerIndexMatchup, teams).filter(d => d['likelihoodWinNum'])
						.length > 0 && (
						<PowerRanking
							matchData={props.data.statMatch}
							teams={teams}
							powerIndexMatchupData={props.data.powerIndexMatchup}
							imgPath={props.data.imgPath}
							flagPath={props.data.flagPath}
							infoIcon={false}
							type="slamtracker"
						/>
					)}
				</>
			) : (
				<LoadingIndicator type="small" />
			)}
		</section>
	);
};

export default LikelihoodToWin;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
const op = require('object-path');
import Helmet from 'react-helmet';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import { values } from 'appdir/main';

/** MI Specific components */
import MISectionHeader from './elements/MISectionHeader';
import MIHeaderContents from './elements/MIHeaderContents';
import PowerRanking from './elements/PowerRanking';
import PowerIndexNumbers from './elements/PowerIndexNumbers';
import ByTheNumbers from './elements/ByTheNumbers';
import InTheMedia from './elements/InTheMedia';
import WinFactors from './elements/WinFactors';
import HaveYourSay from 'appdir/components/common-ui/HaveYourSay';
import BackToAllMI from './elements/BackToAllMI';
import MIFooter from './elements/MIFooter';
import MIModal from './elements/MIModal';
import { MatchInsightsInfoText } from './MIInfoContent';
import { getLikelihoodWinner } from './MatchInsightsUtils';
import { fetch } from 'appdir/components/general/Util';

import ErrorBoundary from 'components/general/ErrorBoundary';
import StubBox from 'appdir/components/common-ui/StubBox';
import MeasurementUtils from 'appdir/lib/analytics';
import { doMeasurement } from 'appdir/components/general/Analytics';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsight
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		matchInsightsMatches: state['CommonData']['matchInsightsMatches'],
		configScoringData: state['Config'].scoringData,
		configOtherData: state['Config'].otherData,
		windowSize: state['PageHeader'].windowSize,
		stubs: state['Config'].stubPages,
		uom: state['Controller'].userPreferences['speed'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	unmount: () => dispatch(deps.actions.MatchInsights.unmount()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

class MatchInsightsContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stub: false,
			expired: false,
			matchInsightsData: null,
			atAGlance: null,
			inTheMedia: null,
			sharePopup: 'hide',
			openModal: false,
			modalContent: null,
			selectedTab: 'winfactors',
		};

		this.matchId = null;
		this.sharedDataLoaded = false;
		this.checkMatchExisits = false;

		this.statsDataTimer = null;
		this.statsDataLoaded = false;
		this.parsedQs = null;

		// logger.log('[MatchInsights] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidMount() {
		/** ?override=true ignores the match status and always set vote to open */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));

		/**
		 * due to bypassAppLink, ios is no longer firing the pageView metrics call
		 * that's navigated to through bypassAppLink={true} b/w Insights Index and Insights Details
		 * Detect the previous page path and if it's from MI Index page, send messageHandlers to iOS
		 */
		const insightsRegex = /\/en_GB\/matchinsights\/(index)\.html/;
		let insightsMatch = document.referrer.match(insightsRegex);

		if (window.webviewPlatform == 'ios' && insightsMatch) {
			let metricsVal = `${values.matchInsightsTitle}:${this.props.matchId}`;

			// logger.log('[MatchInsights] componentDidMount - metricsVal:%o', metricsVal);
			window.webkit.messageHandlers.metrics.postMessage(metricsVal);
		}

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: values.matchInsightsTitle,
			match: this.props.matchId,
		});
	}

	componentWillUnmount() {
		this.setState({
			matchInsightsData: null,
			atAGlance: null,
		});

		if (this.statsDataTimer) {
			clearInterval(this.statsDataTimer);
		}

		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		this.matchId = this.props.matchId;

		/** if match ids are different, reset the flag */
		if (prevProps.matchId !== this.matchId) {
			this.setState({
				matchExists: false,
			});

			this.sharedDataLoaded = false;
			this.checkMatchExisits = false;
		}

		/** Pull shared matches json file to get all the available mathces ids */

		if (prevProps.status !== this.props.status && this.props.status == 'loaded' && !this.sharedDataLoaded) {
			// logger.log('[MatchInsights] componentDidUpdate  - this.props:%o', this.props);

			// load all the available MI matches list to check if the selected MatchId exists
			this.props
				.checkExpired(this.props.sharedDataConfig['matchInsightsMatches'])
				.then(response => {
					this.sharedDataLoaded = true;
					// logger.log('[MatchInsights] componentDidUpdate matchInsightsMatches - response:%o', response);
					if (response.status == 'expired') {
						this.props.update(this.props.sharedDataConfig['matchInsightsMatches']);
					}
				})
				.catch(error => {
					this.sharedDataLoaded = false;
					logger.error('[MatchInsights] componentDidUpdate matchInsightsMatches - :o', error);
				});
		}

		/** Check if cloud Match Insight data exists in the master matches ids list file */
		if (
			this.props.status == 'loaded' &&
			this.sharedDataLoaded &&
			this.props.matchInsightsMatches &&
			op.get(this.props.matchInsightsMatches, 'status', '') == 'loaded' &&
			!this.checkMatchExisits
		) {
			let matchesList = op.get(this.props, 'matchInsightsMatches.result.matches', []);
			this.checkMatchExisits = true;

			if (op.get(this, 'parsedQs.allow', 'false') == 'true') {
				this.setState({
					matchExists: true,
				});
			} else if (matchesList.length > 0) {
				this.setState({
					matchExists: matchesList.includes(this.matchId),
				});
			} else {
				this.setState({
					matchExists: false,
				});
			}
		}

		if (
			(prevProps.status !== this.props.status &&
				this.props.status == 'loaded' &&
				this.matchId &&
				this.state.matchExists) ||
			(prevProps.matchId !== this.matchId && this.state.matchExists) ||
			(prevState.matchExists !== this.state.matchExists &&
				this.state.matchExists &&
				this.props.configScoringData?.matchStat)
		) {
			// logger.log('[MatchInsights] componentDidUpdate -loading data files');
			let { configScoringData, configOtherData } = this.props;

			let matchInsightsDataPath = configScoringData.matchInsights.replace('<matchId>', this.matchId);

			/** start pulling match stats to monitor if the match is started or over
			 *  The stat feed doesn't exist until players show up on the court
			 *  status: "Players Arrive On Court" <-- this is the first status in the stat file
			 */
			this.getStatsData();

			// fetch scores match insights data file - used to get event name, court name, etc
			deps.services.MatchInsights.fetch(matchInsightsDataPath).then(mi_result => {
				logger.log('[MatchInsights] mi_result:%o', mi_result);

				let matchFactsPath = configOtherData.innovations.matchInsightsFacts.replace(
					'<matchId>',
					this.matchId
				);
				let powerIndexMatchupPath = configOtherData.innovations.powerIndexMatchup.replace(
					'<matchId>',
					this.matchId
				);

				// fetch nlg match insights data file
				deps.services.MatchInsights.fetch(matchFactsPath)
					.then(mf_result => {
						logger.log('[MatchInsights] mf_result:%o', mf_result);

						// fetch nlg match insights data file
						deps.services.MatchInsights.fetch(powerIndexMatchupPath)
							.then(pim_result => {
								logger.log('[MatchInsights] pim_result:%o', pim_result);

								if (op.get(mi_result, 'matches', []).length > 0) {
									this.setState({
										powerIndexMatchup: pim_result,
										matchInsightsData: mi_result.matches[0],
										atAGlance: mf_result.nlg[0].doc,
										inTheMedia: [mf_result.p1_insights, mf_result.p2_insights],
										matchInsightsLastUpdate: mf_result['published_date_milliseconds'],
										matchInsightsStatus: pim_result['publication_date_milliseconds']
											? 'loaded'
											: 'error',
									});
								} else {
									this.setState({
										matchInsightsStatus: 'error',
									});
								}
							})
							.catch(error => {
								logger.log(
									'[MatchInsights] componentDidUpdate error loading powerIndexMatchup data:%o',
									error
								);
								this.setState({
									powerIndexMatchup: null,
									matchInsightsData: mi_result.matches[0],
									atAGlance: mf_result.nlg[0].doc,
									inTheMedia: [mf_result.p1_insights, mf_result.p2_insights],
									matchInsightsLastUpdate: mf_result['published_date_milliseconds'],
									matchInsightsStatus: 'error',
								});
							})
							.catch(error => {
								logger.log(
									'[MatchInsights] componentDidUpdate error loading nlg match insights data:%o',
									error
								);
								this.setState({
									powerIndexMatchup: null,
									matchInsightsData: mi_result.matches[0],
									atAGlance: null,
									inTheMedia: null,
									matchInsightsLastUpdate: null,
									matchInsightsStatus: 'error',
								});
							});
					})
					.catch(error => {
						logger.log(
							'[MatchInsights] componentDidUpdate error loading scores match insights data:%o',
							error
						);
						this.setState({
							powerIndexMatchup: null,
							matchInsightsData: null,
							atAGlance: null,
							inTheMedia: null,
							matchInsightsLastUpdate: null,
							matchInsightsStatus: 'error',
						});
					});
			});
		}
		logger.log('[MatchInsights] componentDidUpdate  - this.state:%o', this.state);
	}

	getStatsData = () => {
		let path = this.props.configScoringData.matchStat.path.replace('<matchId>', this.matchId);

		fetch(path)
			.then(result => {
				let results = result.matches;
				let isThereStat = results.length > 0;
				let isCompleted = false;

				if (isThereStat) {
					isCompleted = results[0].team1.won || results[0].team2.won ? true : false;
				}

				// logger.log('[MatchInsights] getStatsData - this:%o, result:%o', this, result);

				this.setState({
					statsData: isThereStat ? results[0] : [],
					statsStatus: 'loaded',
				});

				/** If the match is already over, don't keep pulling the data */
				if (!this.statsDataTimer && !isCompleted) {
					// logger.log('[MatchInsights] getScoresData - this:%o', this);
					this.statsDataTimer = setInterval(() => {
						this.getStatsData();
					}, this.props.configScoringData.matchStat.rateSec * 3000);
				}
			})
			.catch(error => {
				logger.error('[MatchInsights] componentDidUpdate error:%o', error);

				this.setState({
					statsStatus: 'error',
				});
			});
	};

	closeInfoOverlay = () => {
		//MeasurementUtils.dispatchMeasurementCall('closeMatchInsightsModal', {});
		doMeasurement(`${values.matchInsightsTitle}`, 'closeMatchInsightsModal', []);
		document.querySelector('body').classList.remove('modal-on-noscroll');

		this.setState({
			showInfoOverlay: false,
		});
	};

	openInfoWindow = contentType => {
		// logger.log('[MatchInsightsContent] openInfoWindow contentType:%o', contentType);
		//MeasurementUtils.dispatchMeasurementCall('infoWindow', { action: 'open', contentType });
		doMeasurement(`${values.matchInsightsTitle}`, 'infoWindow', ['open', contentType]);
		document.querySelector('body').classList.add('modal-on-noscroll');

		this.setState({
			openModal: true,
			modalContent: contentType,
		});
	};

	closeInfoWindow = () => {
		//MeasurementUtils.dispatchMeasurementCall('infoWindow', { action: 'close' });
		doMeasurement(`${values.matchInsightsTitle}`, 'infoWindow', ['close']);
		document.querySelector('body').classList.remove('modal-on-noscroll');

		this.setState({
			openModal: false,
			modalContent: null,
		});
	};

	measureNextPrev = (oldIndex, newIndex, playerData) => {
		// logger.log('[MatchInsightsContent] measureNexPrev oldIndex:%o, newIndex:%o, playerData:%o', oldIndex, newIndex, playerData);

		if (oldIndex < newIndex) {
			// MeasurementUtils.dispatchMeasurementCall('InTheMedia', {
			// 	action: 'next',
			// 	curSlide: newIndex,
			// 	playerData,
			// });
			doMeasurement(`${values.matchInsightsTitle}`, 'In The Media', [
				'next',
				newIndex,
				playerData.firstNameA,
				playerData.lastNameA,
			]);
		} else {
			// MeasurementUtils.dispatchMeasurementCall('InTheMedia', {
			// 	action: 'prev',
			// 	curSlide: newIndex,
			// 	playerData,
			// });
			doMeasurement(`${values.matchInsightsTitle}`, 'In The Media', [
				'prev',
				newIndex,
				playerData.firstNameA,
				playerData.lastNameA,
			]);
		}
	};

	onShareClick = () => {
		doMeasurement(`${values.matchInsightsTitle}`, 'toggleShare', ['hide']);

		this.setState({
			sharePopup: 'hide',
		});
	};

	toggleShare = () => {
		if (window.webview) {
			let url = window.location.href;

			/** clean up the URL so it sharers the correct www URL and not webview URL */
			if (url.indexOf('/webview') > -1) {
				url = url.replace('/webview', '');
			}

			window.webviewPlatform == 'ios'
				? window.webkit.messageHandlers.share.postMessage(url)
				: window.JSInterface.share(url);

			// doMeasurement(`${values.matchInsightsTitle}`, 'toggleShare', [this.state.sharePopup == 'hide' ? 'show' : 'hide']);
		} else {
			doMeasurement(`${values.matchInsightsTitle}`, 'toggleShare', [
				this.state.sharePopup == 'hide' ? 'show' : 'hide',
			]);

			this.setState({
				sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
			});
		}
	};

	getStubContent = () => {
		return (
			<div className="column-layout news">
				<StubBox attributes={{ message: op.get(this.props, 'stubs.matchinsights.text', '') }} />
			</div>
		);
	};

	setTab = (val, metricsVal = '') => {
		if (val) {
			this.setState({
				selectedTab: val,
			});

			doMeasurement(`${values.matchInsightsTitle}`, metricsVal, []);
		}
	};

	getWinFactorsComponent = () => {
		return <WinFactors matchId={this.matchId} hideTitle={this.showTabs ? true : false} />;
	};

	getHaveYourSayComponent = () => {
		return (
			<HaveYourSay
				powerIndexMatchupData={this.state?.powerIndexMatchup}
				matchStatsData={this.state.statsData}
				teams={this.teams}
				matchId={this.matchId}
			/>
		);
	};

	renderDesktopFactors = prematchWinnerPos => {
		if (this.props.configOtherData?.innovations?.disableFanPrediction) {
			return <div className="mi-section row no-border-bottom">{this.getWinFactorsComponent()}</div>;
		} else {
			return (
				<div className="mi-section factors no-border-bottom">
					<div className="silo">
						{prematchWinnerPos == 'left' ? this.getWinFactorsComponent() : this.getHaveYourSayComponent()}
					</div>

					<div className="silo">
						{prematchWinnerPos == 'right' ? this.getWinFactorsComponent() : this.getHaveYourSayComponent()}
					</div>
				</div>
			);
		}
	};

	render() {
		logger.log('[MatchInsightsContent] render - this:%o', this);

		let isStub = op.get(this.props, 'stubs.matchinsights.stub', '') == 'stub';

		/**  make the default true to matchExists so it doesn't flash stub content
		 *  while setting the correct state
		 */
		let isError = op.get(this.state, 'matchInsightsStatus', false) == 'error';
		let isExists = op.get(this.state, 'matchExists', true);
		this.showTabs = this.props?.windowSize === 'tablet' || this.props?.windowSize === 'mobile';

		let isWebview = window.webviewPlatform;

		if (
			this.props.status == 'loaded' &&
			op.get(this.state, 'matchInsightsData', false) &&
			op.get(this.props, 'windowSize', false) &&
			isExists &&
			!isError
		) {
			let { matchInsightsData, powerIndexMatchup, statsData } = this.state;
			this.teams = [matchInsightsData.team1, matchInsightsData.team2];

			/** reset the teams data if stats data exist as it has won status */
			if (statsData && statsData !== 'error' && statsData !== []) {
				this.teams = [this.state.statsData.team1, this.state.statsData.team2];
			}

			let pageTitle = `${this.teams[0]['lastNameA']} vs ${this.teams[1]['lastNameA']}: ${values.matchInsightsTitle}`;

			let prematchWinner = getLikelihoodWinner(powerIndexMatchup);
			let prematchWinnerPos = this.teams[0]['idA'] === prematchWinner ? 'left' : 'right';

			return (
				<div className="content-main match-insights">
					{!isWebview ? (
						<Helmet>
							<meta property="og:title" content={pageTitle} />
							<meta property="og:description" content={pageTitle} />
							<meta
								property="og:image"
								content="https://photo-assets.wimbledon.com/images/pics/thumbs/w_grounds_4751_29062019_iw.jpg"
							/>
							<meta property="og:url" content={window.location.href} />
							<meta property="og:type" content="article" />
							<meta property="fb:app_id" content="1462414547319687" />

							<meta name="twitter:card" content="summary_large_image" />
							<meta
								property="twitter:image"
								content="https://photo-assets.wimbledon.com/images/pics/thumbs/w_grounds_4751_29062019_iw.jpg"
							/>
							<meta name="twitter:site" content="@Wimbledon" />
							<meta name="twitter:creator" content="@Wimbledon" />
						</Helmet>
					) : null}

					<div className="columm-layout">
						{this.state.openModal && this.state.modalContent && (
							<MIModal
								header={MatchInsightsInfoText[this.state.modalContent]['title']}
								body={MatchInsightsInfoText[this.state.modalContent]['body']}
								closeModal={this.closeInfoWindow}
							/>
						)}
						<MIHeaderContents
							data={{
								type: '',
								headerTitle: `${this.teams[0]['lastNameA']} vs ${this.teams[1]['lastNameA']}: ${values.matchInsightsTitle}`,
								infoIcon: true,
								onInfoClickFn: () => this.openInfoWindow('header'),
							}}
						/>
					</div>
					<div className="columm-layout match-insights-content">
						<div className="byline">
							<div className="byline--share">
								<div
									className="byline--share-button"
									onClick={() => {
										this.toggleShare();
									}}>
									<i className="wim-icon-share" />
									SOCIAL
								</div>
								<div className={`byline--share-wrapper`}>
									<ShareMenu
										type="popup"
										view={this.state.sharePopup}
										orient="top"
										onClick={this.onShareClick}
										share={window.location.href}
									/>
								</div>
							</div>
						</div>

						{powerIndexMatchup !== null && this.state.atAGlance !== null ? (
							<ErrorBoundary
								message={`${values.powerIndexTitle} is not currently available for this match`}
								klass="mi-section">
								<PowerRanking
									matchData={matchInsightsData}
									teams={this.teams}
									powerIndexMatchupData={powerIndexMatchup}
									imgPath={this.props.configOtherData.playerProfileImagePath}
									flagPath={this.props.configOtherData.flagImagePathSmall}
									infoIcon={false}
									onInfoClickFn={() => this.openInfoWindow('power-ranking')}
								/>
							</ErrorBoundary>
						) : null}

						{/** render side by side WinFactors and Have Your Say components
						 *    WinFactors component should in line with the winner headshot image above
						 */}
						<div className="desktop-only">{this.renderDesktopFactors(prematchWinnerPos)}</div>

						{/** Win Factors, In the Media, At a Glance show in tabs format on mobile & tablet windowSize */}
						<ul className="mi-tags" role="list">
							<li
								onClick={() => this.setTab('winfactors', 'Win Factors')}
								className={this.state.selectedTab === 'winfactors' ? 'selected' : ''}>
								Win Factors
							</li>
							<li
								onClick={() => this.setTab('inthemedia', 'In The Media')}
								className={this.state.selectedTab === 'inthemedia' ? 'selected' : ''}>
								In The Media
							</li>
							<li
								onClick={() => this.setTab('ataglance', 'At a Glance')}
								className={this.state.selectedTab === 'ataglance' ? 'selected' : ''}>
								At a Glance
							</li>
						</ul>

						{this.state.selectedTab === 'winfactors' ? (
							<div className="below-desktop">{this.getWinFactorsComponent()}</div>
						) : null}

						{this.state.inTheMedia !== null &&
						((this.state.selectedTab === 'inthemedia' && this.showTabs) || !this.showTabs) ? (
							<ErrorBoundary
								message="In The Media is not currently available for this match"
								klass="mi-section">
								<InTheMedia
									teams={this.teams}
									inTheMediaData={this.state.inTheMedia}
									lastUpdate={op.get(this.state, 'matchInsightsLastUpdate', null)}
									infoIcon={false}
									onNextPrevClickFn={this.measureNextPrev}
									hideTitle={this.showTabs ? true : false}
									windowSize={this.props.windowSize}
								/>
							</ErrorBoundary>
						) : null}

						{this.state.atAGlance !== null &&
						((this.state.selectedTab === 'ataglance' && this.showTabs) || !this.showTabs) ? (
							<ErrorBoundary
								message="By The Numbers is not currently available for this match"
								klass="mi-section">
								<ByTheNumbers
									teams={this.teams}
									byTheNumbersData={this.state.atAGlance}
									userPreference={this.props.uom}
									infoIcon={false}
									hideTitle={this.showTabs ? true : false}
									windowSize={this.props.windowSize}
								/>
							</ErrorBoundary>
						) : null}

						{powerIndexMatchup !== null ? (
							<ErrorBoundary message="powerIndex">
								<PowerIndexNumbers
									powerIndexMatchupData={powerIndexMatchup}
									teams={this.teams}
									windowSize={this.props.windowSize}
								/>
							</ErrorBoundary>
						) : null}

						<div className="below-desktop">{this.getHaveYourSayComponent()}</div>

						{/* pass 'slamtracker' as type for shorter description */}
						{/* {!isWebview ? <MIFooter type="" /> : null} */}

						<BackToAllMI parsedQs={this.parsedQs} />
					</div>
				</div>
			);
		} else if (isStub || !isExists || isError) {
			return <div className="content-main">{this.getStubContent()}</div>;
		} else {
			return (
				<div className="content-main  match-insights">
					<div className="columm-layout">
						<MIHeaderContents
							data={{
								type: '',
								headerTitle: values.matchInsightsTitle,
								headerSubTitle: '',
								headerDate: '',
								infoIcon: false,
							}}
						/>
					</div>
					<div className="columm-layout match-insights-content">
						{op.get(this.state, 'matchExists', null) == false ? (
							this.getStubContent()
						) : (
							<LoadingIndicator type={isWebview ? 'white' : ''} />
						)}
					</div>
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsightsContent);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: EventStats
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['EventStats'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: param => dispatch(deps.actions.EventStats.mount(param)),
});

class EventStats extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data, selectedIndex: 0 };
		this.fetchEvents = true;
	}

	componentDidMount() {
		let playerId = this.state.playerId;
		this.props.mount(playerId);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevState) {
		if (this.fetchEvents && !this.state.eventsData) {
			this.fetchEvents = false;
			deps.services.EventStats.getEventStats(this.state.urlPath).then(data => {
				this.setState(prevState => {
					return { ...prevState, eventStatsData: data };
				});
			});
		}
	}

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	render() {
		logger.log('[EventStats] render - state:%o', this.state);
		let header = ['1R', '2R', '3R', '4R', 'QF', 'SF', 'F'];

		return this.state.eventStatsData ? (
			<div className="event-stats four-col">
				<div className="table-title">
					<a
						onClick={() => {
							this.setSelected(0);
						}}>
						<i className="wim-icon-left-arrow left-icon" />
					</a>
					<div className={`left-nav-dot ${this.state['selectedIndex'] == 0 ? 'selected' : ''}`}>&#9679; </div>
					<div className={`right-nav-dot ${this.state['selectedIndex'] == 1 ? 'selected' : ''}`}>
						{' '}
						&#9679;
					</div>
					<a
						onClick={() => {
							this.setSelected(1);
						}}>
						<i className="wim-icon-right-arrow right-icon" />
					</a>
				</div>
				<div className="datatable">
					<div className="title">
						Event Stats for {this.state.eventStatsData.first_name} {this.state.eventStatsData.last_name}
					</div>
					<div className="headers">
						<div className="cell first-col" />
						{header.map((d, i) => {
							return (
								<div
									key={i}
									className={`cell ${i <= 3 ? 'early-col' : 'late-col'} ${
										i <= 3 && this.state['selectedIndex'] == 0
											? 'selected'
											: i > 3 && this.state['selectedIndex'] == 1
											? 'selected'
											: ''
									}`}>
									{d}
								</div>
							);
						})}
					</div>
					{this.state.eventStatsData.player_stats.map((d, i) => {
						return (
							<div key={i} className="row">
								<div className={`cell first-col`}>{d.title}</div>
								{d.stats.map((d1, j) => {
									return (
										<div
											key={j}
											className={`cell ${j <= 3 ? 'early-col' : 'late-col'} ${
												j <= 3 && this.state['selectedIndex'] == 0
													? 'selected'
													: j > 3 && this.state['selectedIndex'] == 1
													? 'selected'
													: ''
											}`}>
											{d1.display}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventStats);

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	switch (action.type) {
		case deps.actionTypes.CONTACT_THANK_YOU_MOUNT:
			//logger.log('[ContactThankYouPage] deps.actionTypes.CONTACT_THANK_YOU_MOUNT - newState:%o', newState);
			return state;
			break;
		default:
			return state;
	}
};


import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetch } from 'appdir/components/general/Util';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isEmpty from 'lodash/isEmpty';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import PageHeader from 'appdir/components/general/PageHeader';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import SiteMap from 'appdir/components/general/SiteMap';


/**
 * -----------------------------------------------------------------------------
 * React Component: SchedulePrintPage
 * -----------------------------------------------------------------------------
 */

const SchedulePrintPage = props => {
	const dispatch = useDispatch();
	const daysPath = useSelector(state => state['Config']?.scoringData?.scheduleDays);
	const startDay = useSelector(state => state['Config']?.dateConfig?.qualIsoStart);
	const [dayData, setDayData] = useState([]);

	//logger.log('[SchedulePrintPage] scheduleDays:%o', scheduleDays); 

	useEffect(() => {
		logger.log('[SchedulePrintPage] daysPath:%o', daysPath);
		fetch(daysPath)
			.then(result => {
				logger.log('[SchedulePrintPage] result:%o', result);
				let days = result.eventDays.reverse();
				setDayData(days)
				
			})
			.catch(error => {
				
			});
	}, [daysPath])
    
    const getDayContent = (day) => {
       if (!day.quals && day.released) {
			let date = moment(startDay).tz('Europe/London').add(day.tournDay - 1, 'd')
			logger.log('[SchedulePrintPage] scheduleDays:%o', day); 
			let dayNum = date.tz('Europe/London').format('DD');
			let moNum = date.tz('Europe/London').format('MM');
			return (
				<>
					<div className="title">{day.messageShort}</div>
					<div className="item"><a href={`https://www.wimbledon.com/pdf/update/referees/2024/${day.tournDay}/OOP_${moNum}${dayNum}_Grid.pdf`} target="_blank" rel="noopener noreferrer">Grid Layout</a></div>
					<div className="item"><a href={`https://www.wimbledon.com/pdf/update/referees/2024/${day.tournDay}/OOP_${moNum}${dayNum}_A4.pdf`} target="_blank" rel="noopener noreferrer">A4 Layout</a></div>
					<div className="item"><a href={`https://www.wimbledon.com/pdf/update/referees/2024/${day.tournDay}/OOP_${moNum}${dayNum}_3Col.pdf`} target="_blank" rel="noopener noreferrer">3 Column Layout</a></div>
					<div className="item"><a href={`https://www.wimbledon.com/pdf/update/referees/2024/${day.tournDay}/OOP_${moNum}${dayNum}_Alpha.pdf`} target="_blank" rel="noopener noreferrer">Alpha Layout</a></div>
					
				</>
			)
	   	}
		else {
			return null;
		}
        
    }
        
    let header_propsData = {};

    if(true){
        header_propsData = {
            headerType: 'scores',
            title: 'Printable Order of Play',
            shortTitle: 'Printable Order of Play',
            metaTitle: 'Printable Order of Play',
            metaDescription: '',
            metaDate: '',
            metaPlayers: '',
        };
    }else{
        header_propsData = {
            headerType: 'generic',
            title: 'Sitemap',
            metaTitle: 'Page not found',
            metaDescription: '',
            metaDate: '',
            metaPlayers: '',
        };
    }


    return (
        <Template >
            <div className="scores-background" />
            <Header attributes={header_propsData} />

            <PageHeader attributes={header_propsData} />
            {true ? (
                <div className="content-main">
                    
                    {dayData.length > 0 ? (
                        <div className="column-layout general">
							<div className="four-col heading">The Order of Play PDFs will be available by the end of the day.</div>
							<div className="oop_pdfs">
						
								{
									dayData.map(day => {
										return (getDayContent(day))
									})
								}
							
							</div>
                        </div>
                    ) : (
                        <div className="column-layout">
                            <LoadingIndicator  />
                        </div>

                    )}

                </div>
            ) : (
                <div className="column-layout">
                    <LoadingIndicator type="white" />
                </div>
            )}
            <Footer />
        </Template>
    );
}

export default SchedulePrintPage;

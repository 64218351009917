import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	switch (action.type) {
		case deps.actionTypes.MATCHARCHIVE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[MatchArchive] MATCHARCHIVE_MOUNT - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.MATCHARCHIVE_LOAD_MATCHES:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[MatchArchive] MATCHARCHIVE_LOAD_MATCHES - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.MATCHARCHIVE_UPDATE:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[MatchArchive] MATCHARCHIVE_UPDATE - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

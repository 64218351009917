import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class Panel extends Component {
	
	constructor(props) {
		super(props);
		this.styles = props.styles;

		//logger.log('Panel: constructor - state:%o', this.state);
	}

	onClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}
	
	render() {
		//logger.log('Panel: render - state:%o', this.props);
		let inline = {};
		let data_state = '';

		if(this.props.first) {
			inline = {
				margin: "0 0 0 10px"
			}
		}

		if (this.props.active) {
			data_state = 'active';
		}

		return (
			<button 
				className={`${this.styles.icon} ${this.styles.panel}`} 
				style={inline} 
				onClick={() => {this.onClick()}} 
				title="Panel"
				data-state={data_state} >
			</button>
		)
	}
	
}

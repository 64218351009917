import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getCleanLink } from 'appdir/components/general/Util';
import WimLink from 'appdir/components/general/WimLink';
import op from 'object-path';
import { gigyaScreen } from 'appdir/components/general/Gigya/utils';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

const mapStateToProps = (state, props) => {
	return {
		shelfIcons: state['Config'].myWIcons,

		...state['Config'],
		...state['SubNav'],
		...state['Gigya'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
	logout: data => dispatch(deps.actions.Gigya.logout(data)),
});

class SubNavMyWim extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.items = [
			{
				title: 'Profile & Preferences',
				link: '/en_GB/mywimbledon/index.html',
			},
		];

		//logger.log('[SubNav] props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[TimeSelect] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	handleClick(link) {
		if (link == 'logout') {
			this.props.logout();
		} else if (link == 'login') {
			this.props.openScreen({ screen: 'login_screen', cid: 'login_subnav' });
		} else if (link == 'register') {
			this.props.openScreen({ screen: 'register_screen', cid: 'register_subnav' });
		}

		this.state.onClick(link);
	}

	getDebentureLink(segment, which) {
		switch (which) {
			case 'Debenture Information':
				return `/s/debentures/${segment}`;

			case 'Debenture FAQs':
				return `/en_GB/debentures/faqs.html`;

			case 'Debenture Contacts':
				return `/en_GB/debentures/contacts.html`;

			default:
				break;
		}
	}

	render() {
		let version = this.state.myWim.version || 0;

		let subnavClasses = this.state.open
			? classNames('nav-item-subnav', 'nav-item-subnav-open')
			: classNames('nav-item-subnav');
		let featured = this.state.featured ? this.state.featured : [];

		let roles = op.get(this.state, 'currentUser.roles', []);
		let debentureRole = [];
		let playerRole = [];
		if (roles.length > 0) {
			debentureRole = roles.filter(d => d.roleId == 1000020);

			//logger.log('[SubNavMyWim] render - debentureRole:%o', debentureRole);
		}
		roles = roles
			.filter(role => {
				if (role.landingPage) {
					return true;
				} else {
					return false;
				}
			})
			.map(role => {
				return {
					...role,
					name: this.props.shelfIcons[role.keySegment]?.find(role => role.show.includes('landing')).title,
				};
			})
			.sort((a, b) => this.props.shelfIcons?.titles[a.key].sort - this.props.shelfIcons?.titles[b.key].sort);
		//logger.log('[SubNavMyWim] render - state:%o', this.state);
		return (
			<ul className={subnavClasses} role="navigation" aria-label="sub navigation my wimbledon">
				<div className="submenu">
					{this.state.gigyaLoaded && this.state.loggedIn ? (
						<React.Fragment>
							<div className="myWimSubnav">
								<span
									className="name"
									alt="first name"
									tabIndex="0">{`${this.state.currentUser.profile.firstName}`}</span>
								<span className="button" tabIndex="0" onClick={() => this.handleClick('logout')}>
									LOGOUT
								</span>
								<div className="line"></div>
							</div>

							{this.items.map(item => {
								return (
									<li
										key={item.title}
										onClick={() => {
											this.state.onClick(item.link);
										}}>
										<WimLink to={item.link} external={item.external}>
											{item.title}
										</WimLink>
									</li>
								);
							})}
							{roles?.map(role => (
								<React.Fragment key={role.key}>
									{role.landingPage && (
										<li
											className="myWimbledonItem show"
											onClick={() => this.state.onClick(role.landingPage)}>
											<WimLink style="myWimbledonLink" to={role.landingPage || ''}>
												{role.name}
											</WimLink>
										</li>
									)}
								</React.Fragment>
							))}
							<li className="myWimbledonItem show">
								<WimLink style="myWimbledonLink" to="/en_GB/aboutmywimbledon/index.html">
									About <MyWimbledonTitle />
								</WimLink>
							</li>

							{playerRole.length > 0 && version >= 2 && (
								<li
									key={`Player Home`}
									onClick={() => {
										this.state.onClick();
									}}>
									<WimLink to={'/s/player/main_draw.html'}>{`Players Home`}</WimLink>
								</li>
							)}
						</React.Fragment>
					) : (
						<div className="myWimSubnav">
							<span tabIndex="0" onClick={() => this.handleClick('login')}>
								Login
							</span>{' '}
							/{' '}
							<span tabIndex="0" onClick={() => this.handleClick('register')}>
								Join
							</span>
							<div className="line"></div>
							<div className="myWimbledonItem show" onClick={() => this.handleClick()}>
								<WimLink style="myWimbledonLink" to="/en_GB/aboutmywimbledon/index.html">
									About <MyWimbledonTitle />
								</WimLink>
							</div>
						</div>
					)}
				</div>

				{(() => {
					return featured.length > 0 && (debentureRole.length == 0 || version < 2) ? (
						<div className="feature-label">Featured</div>
					) : null;
				})()}

				{debentureRole.length == 0 || version < 2
					? featured.map(item => {
							//logger.log('[SubNav] item:%o', item);
							let link = getCleanLink(item.link);
							return (
								<li
									className="feature-pic"
									tabIndex={-1}
									role="none"
									aria-hidden
									key={item.title}
									onClick={() => {
										this.state.onClick(link.url);
									}}>
									<WimLink
										to={link.url}
										measure={['Menu', 'Featured', item.title]}
										title={item.title}>
										<div className="feature-image" tabIndex={-1} role="none" aria-hidden>
											<img tabIndex={-1} role="none" aria-hidden src={item.image} />
										</div>
										<span tabIndex={-1} role="none" aria-hidden>
											{item.title}
										</span>
									</WimLink>
								</li>
							);
					  })
					: null}
			</ul>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubNavMyWim);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Time from 'appdir/components/common-ui/Time';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Image
 * -----------------------------------------------------------------------------
 */
const Image = props => {
	logger.log('[Image] - props:%o', props);

	let attributes = props.data;
	let type = 'image';

	let getTitleLine = image => {
		if (image['caption'] && image['credit']) {
			return (
				<div className="photo-title">
					{image['caption']}
					<div className="photo-credit">{image['credit']}</div>
				</div>
			);
		} else if (image['caption']) {
			return <div className="photo-title">{image['caption']}</div>;
		} else if (image['credit']) {
			return <div className="photo-credit">{image['credit']}</div>;
		} else {
			return null;
		}
	};

	//look for error
	if (!attributes) {
		return null;
	}

	if (attributes['images']?.length > 1) {
		type = 'multiple';
	}

	if (type == 'image') {
		logger.log('[Image] attributes:%o', attributes);

		// let image = attributes['images']['image'];
		let image = attributes.images?.image;
		//logger.log('[Image] link:%o', attributes['link']);

		if(!image)
			return null;

		if (attributes['link']) {
			return (
				<div className={`photo ${attributes['style']}`} aria-hidden tabIndex={-1} role="none">
					<WimLink to={attributes['link']} title="hideAria" ariaHiddenElement>
						<img src={image['url']} aria-hidden tabIndex={-1} role="none" />
						<div className="photo-info" aria-hidden tabIndex={-1} role="none">
							{getTitleLine(image)}
						</div>
					</WimLink>
				</div>
			);
		} else {
			return (
				<div className={`photo ${attributes['style']}`} aria-hidden tabIndex={-1} role="none">
					<img src={image['url']} aria-hidden tabIndex={-1} role="none" />
					<div className="photo-info" aria-hidden tabIndex={-1} role="none">
						{getTitleLine(image)}
					</div>
				</div>
			);
		}
	} else if (type == 'multiple') {
		return null;
	}
};

export default Image;

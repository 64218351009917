import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				ibmLink: Config.ibmLink,
				myWim: Config.myWimbledon,
				displayDates: Config.dateConfig.displayDates,
			};

			dispatch({ type: deps.actionTypes.HEADER_MOUNT, data: data });
		});
	},
};

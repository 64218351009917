/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import PointNav from './PointNav';
import deps from 'dependencies';
//import { MatchArchiveContext } from '../../context';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: VideoPlayer
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.PointNav,
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.PointNav.mount()),
	update: data => dispatch(deps.actions.MatchArchive.controlUpdate(data)),
});

//PointNav.contextType = MatchArchiveContext;
export default connect(mapStateToProps, mapDispatchToProps)(PointNav);

import React, { Component } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';

/**
 * Checkboxes for managing consents
 *  - consent is actually stored ina  Gigya attribute called 'preferences'
 *  - Gigya 'preferences' are different from user preferences as lebelled on the site
 *  - The subscription is "playingTennis" and the consent is "LTA".
 *  - Logic around handling these data objects has changed slightly with the updates to proxy requests to Gigya through orchestration.
 *  - Please review and insure what updates are needed to add a new subscription and consent.
 */

class FormConsent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			...FormConsent.getStatus(props),
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		//logger.info('[FormConsent] constructor - props:%o state:%o', props, this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[FormConsent] getDerivedStateFromProps - next:%o prev:%o', nextProps, prevState);

		let newState = {};

		if ((prevState.user == null || isEmpty(prevState.user)) && nextProps.user) {
			newState = {
				user: nextProps.user,
				...FormConsent.getStatus(nextProps),
			};
		}

		if (isEmpty(newState)) {
			newState = null;
		}
		return newState;
	}

	static getStatus(props) {
		let state = {
			privacy: op.get(props, 'user.preferences.privacy.PrivacyPolicy.isConsentGranted', false),
			terms: op.get(props, 'user.preferences.terms.TermsOfService.isConsentGranted', false),
			location: op.get(props, 'user.preferences.LocationServices.isConsentGranted', false),
			insights: op.get(props, 'user.preferences.Insights.isConsentGranted', false),
			partners: op.get(props, 'user.preferences.PartnersAndSuppliers.isConsentGranted', false),
		};

		if (props.user.country === 'GB' || props.user.country === 'UK') {
			state['lta'] = op.get(props, 'user.preferences.LTA.isConsentGranted', false);
		}
		return state;
	}

	componentDidUpdate() {
		//logger.info('[FormConsent] componentDidUpdate - state:%o', this.state);
	}

	handleChange(e) {
		//logger.info('[FormConsent] handleChange - e:%o', e.currentTarget.id);

		switch (e.currentTarget.id) {
			case 'checkbox-location':
				this.setState({ location: !this.state.location });
				break;
			case 'checkbox-insights':
				this.setState({ insights: !this.state.insights });
				break;
			case 'checkbox-partners':
				this.setState({ partners: !this.state.partners });
				break;
			case 'checkbox-lta':
				this.setState({ lta: !this.state.lta });
				break;
			default:
				break;
		}
	}

	handleSubmit() {
		//logger.info('[FormConsent] handleSubmit - onUpdate:%o', this.props.onUpdate);
		let prefs = { ...this.props.preferences };
		op.set(prefs, 'LocationServices.isConsentGranted', this.state.location);
		op.set(prefs, 'Insights.isConsentGranted', this.state.insights);
		op.set(prefs, 'PartnersAndSuppliers.isConsentGranted', this.state.partners);
		if (this.state.user.country === 'GB' || this.state.user.country === 'UK') {
			op.set(prefs, 'LTA.isConsentGranted', this.state.lta);
		}

		op.del(prefs, 'terms');
		op.del(prefs, 'privacy');
		op.del(prefs, 'CommunicationPreferences');
		op.del(prefs, 'LocationServices.docDate');
		op.del(prefs, 'LocationServices.lastConsentModified');
		op.del(prefs, 'LocationServices.custom');
		op.del(prefs, 'Insights.docDate');
		op.del(prefs, 'Insights.lastConsentModified');
		op.del(prefs, 'Insights.custom');
		op.del(prefs, 'PartnersAndSuppliers.docDate');
		op.del(prefs, 'PartnersAndSuppliers.lastConsentModified');
		op.del(prefs, 'PartnersAndSuppliers.custom');

		if (this.props.onUpdate) {
			this.props.onUpdate({ preferences: prefs });
		}
	}

	render() {
		logger.info('[FormConsent] render - state:%o', this.state);

		let privacy_version = '';
		let privacy_approved = '';
		let terms_version = '';
		let terms_approved = '';

		if (this.state.privacy) {
			privacy_version = moment(this.state.user.preferences.privacy.PrivacyPolicy.docDate)
				.tz('Europe/London')
				.format('DD/MM/YYYY')
				.toString();
			privacy_approved = moment(this.state.user.preferences.privacy.PrivacyPolicy.lastConsentModified)
				.format('ll')
				.toString();
		}

		if (this.state.terms) {
			terms_version = moment(this.state.user.preferences.terms.TermsOfService.docDate)
				.tz('Europe/London')
				.format('DD/MM/YYYY')
				.toString();
			terms_approved = moment(this.state.user.preferences.terms.TermsOfService.lastConsentModified)
				.format('ll')
				.toString();
		}

		//logger.info('[FormConsent] render - privacy:%o version:%o', this.state.privacy, privacy_version);

		return (
			<div className="mywim_settings consent consent-pref-float-left">
				<div className="title">CONSENTS</div>

				<div className="consent-display">
					<input
						type="checkbox"
						id="checkbox-privacy"
						data-display-name=""
						tabIndex="0"
						disabled={this.state.privacy}
						checked={this.state.privacy}
						onChange={this.handleChange}
					/>
					<label className="consent-label-wrapper" htmlFor="checkbox-privacy">
						<label className="consent-label">
							<div className="consent-label-text">
								<WimLink to="/en_GB/about_wimbledon/privacy_policy.html">Privacy Policy</WimLink>
							</div>
							<div className="consent-dates">
								Version: {privacy_version} - Approved on {privacy_approved}
							</div>
						</label>
					</label>
				</div>

				<div className="consent-display">
					<input
						type="checkbox"
						id="checkbox-terms"
						data-display-name=""
						tabIndex="0"
						disabled={this.state.terms}
						checked={this.state.terms}
						onChange={this.handleChange}
					/>
					<label className="consent-label-wrapper" htmlFor="checkbox-terms">
						<label className="consent-label">
							<div className="consent-label-text">
								<WimLink to="/en_GB/about_wimbledon/acceptable_use_policy.html">
									Terms of Service
								</WimLink>
							</div>
							<div className="consent-dates">
								Version: {terms_version} - Approved on {terms_approved}
							</div>
						</label>
					</label>
				</div>

				<div className="consent-display">
					<input
						type="checkbox"
						id="checkbox-location"
						data-display-name=""
						tabIndex="0"
						checked={this.state.location}
						onChange={this.handleChange}
					/>
					<label className="consent-label-wrapper" htmlFor={'checkbox-location'}>
						<label className="consent-label" htmlFor={'checkbox-location'}>
							<div className="consent-label-text">Location Services</div>
							<div className="consent-desc">
								We wish to contact you with relevant information based on your location. It means we can
								make sure you are getting what you need when you come to Wimbledon, or equally, when you
								are not here. You can also manage these settings through your device.
							</div>
						</label>
					</label>
				</div>

				<div className="consent-display">
					<input
						type="checkbox"
						id="checkbox-insights"
						data-display-name=""
						tabIndex="0"
						checked={this.state.insights}
						onChange={this.handleChange}
					/>
					<label className="consent-label-wrapper" htmlFor={'checkbox-insights'}>
						<label className="consent-label" htmlFor={'checkbox-insights'}>
							<div className="consent-label-text">Insights</div>
							<div className="consent-desc">
								We profile our guests’ data to learn more about your relation- ship with us. Whether
								you’re attending The Championships, or tweeting about your favourite player or are
								interested in attending our Museum, we use insights to analyse how you might want to
								interact with us, and can make sure we are only communicating with you about what is
								relevant to you.
							</div>
						</label>
					</label>
				</div>
				{(this.state.user.country === 'GB' || this.state.user.country === 'UK') && (
					<div className="consent-display">
						<input
							type="checkbox"
							id="checkbox-lta"
							data-display-name=""
							tabIndex="0"
							checked={this.state.lta}
							onChange={this.handleChange}
						/>
						<label className="consent-label-wrapper" htmlFor={'checkbox-lta'}>
							<label className="consent-label" htmlFor={'checkbox-lta'}>
								<div className="consent-label-text">LTA</div>
								<div className="consent-desc">
									Whether you’re a fan, player, or both, the LTA’s membership scheme, Advantage, has
									excellent options for everyone in the UK. By ticking this box, you give consent for
									the AELTC to provide your name and email address (“personal data”) to the LTA, and
									for the LTA to contact you by email about joining Advantage. The LTA will use your
									personal data in accordance with the{' '}
									<WimLink
										to={'http://www.lta.org.uk/Footer/Privacy-Policy/'}
										external={true}
										target="_blank">
										LTA's Privacy Policy
									</WimLink>
									.
								</div>
							</label>
						</label>
					</div>
				)}
				<div className="consent-display">
					<input
						type="checkbox"
						id="checkbox-partners"
						data-display-name=""
						tabIndex="0"
						checked={this.state.partners}
						onChange={this.handleChange}
					/>
					<label className="consent-label-wrapper" htmlFor={'checkbox-partners'}>
						<label className="consent-label" htmlFor={'checkbox-partners'}>
							<div className="consent-label-text">Partners & Suppliers</div>
							<div className="consent-desc">
								We work very closely with our carefully selected partners, and occasionally we would
								like to share information with you about their relationship with Wimbledon. We will not
								share your personal data with them, we will only communicate with you about them.
							</div>
						</label>
					</label>
				</div>
				<Button
					className={`btn solid`}
					onClick={() => {
						this.handleSubmit();
					}}>
					UPDATE
				</Button>
			</div>
		);
	}
}

export default FormConsent;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, createRef } from 'react';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import { Scrollchor } from 'react-scrollchor';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * Class Component: AtoZNav
 * -----------------------------------------------------------------------------
 */

const letterList = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];
const mobileMatch = window.matchMedia('(max-width: 767px)').matches;
const tabletMatch = window.matchMedia('(max-width: 1023px)').matches;
const desktopMatch = window.matchMedia('(min-width: 1024px)').matches;

class AtoZNav extends Component {
	//logger.log('[AtoZNav] - props:%o', props);

	//let attributes = props['attributes'][props.id];
	//logger.log('[AtoZNav] - attr:%o', attributes);

	static getDropdownData() {
		let options = [];

		options.push({ name: 'Select', value: 'select', query: 'select', selected: true });

		letterList.map((letter, index) => options.push({ name: letter, value: `#${letter}`, query: `#${letter}` }));

		//logger.log('[AtoZNav] getDropdownData - options:%o', options);
		let menuData = { options };
		return menuData;
	}

	constructor(props) {
		logger.log('[AtoZNav] constructor - props:%o', props);
		super(props);
		this.state = {
			...props,
			value: 'select',
		};

		this.clickLetter = this.clickLetter.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);

		// this.letterRefs = {};
		// letterList.forEach(letter => {
		// 	this.letterRefs[`hash${letter}`] = createRef();
		// });
	}

	componentDidMount() {
		//logger.log('[AtoZNav] componentDidMount - state:%o', this.state);
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	handleChange(letterHash) {
		// you have to handle change this way because it asynchronous.
		// So, this will set the state with a callback to then set the location
		// logger.log('[AtoZNav] letterhash: %o, name: %o', letterHash);
		if (this.state.value !== letterHash) {
			this.setState({ value: letterHash });
			// window.history.pushState({}, '', `${letterHash}`);
			let temp = letterHash.charAt(1);
			this.scrollToId(temp);
			this.clickLetter(letterHash.slice(-1));
		}

		// let hashLink = `hash${temp}`;
		// logger.log('[AtoZNav] hashLink: %o, this.letterRefs[hashLink]: %o', hashLink, this.letterRefs[hashLink]);
		// if (this.letterRefs[hashLink] && this.letterRefs[hashLink].current) {
		// this.letterRefs[hashLink].current.click();
		// } else {
		// 	logger.log('AtoZNav hashlink errored: %o', this.letterRefs[hashLink].current);
		// }

		// this.refs[hashLink].simulateClick();
	}

	scrollToId(letter) {
		let offset2 = this.state?.settings?.offset ? this.state.settings.offset : 0;
		logger.log('[AtoZNav] scrollToId - letter:%o offset:%o', letter, offset2);
		const el = document.getElementById(letter);
		if (el) {
			let elPosition = el.getBoundingClientRect().top;
			// el.scrollIntoView({ behavior: 'smooth' });
			window.scrollTo({
				behavior: 'smooth',
				top: elPosition - document?.body?.getBoundingClientRect().top + offset2,
			});
			// if (!this.state.offset) {
			// 	this.getAnimationSettings();
			// }
			logger.log('[AtoZNav] - scrolled to letter', letter);
		} else {
			logger.log('[AtoZNav] - could not scroll to letter', letter);
		}
	}

	onOpen(which) {}

	getAnimationSettings() {
		let animationOffset;
		let animationDuration = 600;

		let atozRect = document.querySelector('.content-filters').getBoundingClientRect();
		//logger.log('[AtoZNav] getAnimationSettings - atozRect:%o', atozRect);

		// + 70 is from height of "Ladies/Gents" and letter header
		animationOffset = (atozRect.height + this.state.settings.navTop) * -1;
		//logger.log('[AtoZNav] getAnimationSettings - animationOffset:%o', animationOffset);

		this.setState({
			offset: animationOffset,
		});
	}
	clickLetter(letter) {
		MeasurementUtils.dispatchMeasurementCall('select', letter);
	}

	render() {
		logger.log('[AtoZNav] render - state:%o', this.state);
		//logger.log('[AtoZNav] constructor - duration:%o', this.state.settings.duration);

		return (
			<div id="atoznav">
				<div className="dropdown_nav filter-dropdown">
					<SelectMenu
						name="filter"
						attributes={AtoZNav.getDropdownData()}
						selected={this.state.value}
						onSelected={this.handleChange}
						onOpen={this.onOpen}
					/>
				</div>
				<div className="text_nav">
					<ul className="azAlphaInner" role="list">
						{letterList.map((letter, index) => (
							<li key={letter} onClick={() => this.clickLetter(letter)}>
								<Scrollchor
									id={`hash${letter}`}
									// ref={`hash${letter}`}
									to={letter}
									animate={{ offset: this.state?.settings?.offset || 0 }}>
									{letter}
								</Scrollchor>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
}

export default AtoZNav;

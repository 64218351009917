import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.CONTENT_WEBVIEW_LOAD:
			newState = {
				...state,
				...action.data,
				data: '',
			};
			logger.log('[ContentPageWebview] CONTENT_WEBVIEW_LOAD - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.CONTENT_WEBVIEW_UNMOUNT:
			newState = {
				...state,
				...action.data,
				data: '',
			};
			logger.log('[ContentPageWebview] CONTENT_WEBVIEW_UNMOUNT - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

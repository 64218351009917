import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['CompetitorListPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.CompetitorListPage.filter(settings)),
});

class CompetitorListSelect extends Component {
	constructor(props) {
		super(props);

		let listDropdownVals = props.filters.map(item => ({ name: item.text, value: item.value }));

		this.state = {
			...props,
			selected: listDropdownVals[0].value,
			listDropdownVals: listDropdownVals,
		};

		this.setList = this.setList.bind(this);
		this.onOpen = this.onOpen.bind(this);

		logger.log('[ViewSelect] constructor props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	setList(viewType) {
		this.props.filter({
			listView: viewType,
			open: '',
		});

		MeasurementUtils.dispatchMeasurementCall('competitorListSetList', { viewType });
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		let listData = {
			options: this.state.listDropdownVals,
		};

		return (
			<div className="scores-select-menu">
				<SelectMenu
					name="events"
					attributes={listData}
					selected={this.state.selected}
					show={this.state.filters.open == 'view'}
					onSelected={this.setList}
					onOpen={this.onOpen}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorListSelect);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import forEach from 'lodash/forEach';
import { getQuerystringValues } from 'appdir/components/general/Util';
import {
	getEventCode,
	getRoute,
	getEventName,
	getPlayersData,
	getCurrentRound,
	getSinglesEventCodeByPlayerId,
	eventDef,
	getCorrectEventId,
	isWinnerIsCompleted,
} from './DrawsUtils';

// components
import EventSelect from './EventSelect';
import DrawsNavigator from './DrawsNavigator';
import DrawsLists from 'appdir/components/scoring/DrawsLists';
import StubBox from 'appdir/components/common-ui/StubBox';
import SearchBox from 'appdir/components/common-ui/SearchBox';
import Favorites from 'appdir/components/common-ui/Favorites';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import ScrollHandler from 'appdir/components/general/ScrollHandler';
import WimLink from 'appdir/components/general/WimLink';
// import WimRedirect from 'appdir/components/general/WimRedirect';
import PathToTheFinal from 'appdir/components/common-ui/PathToTheFinal';
import Button from 'appdir/components/common-ui/Button';

import { Redirect } from 'react-router';
import cn from 'classnames';

import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import { getRoundColumnsData, getRoundHalf, roundsToDisplay, setUrlRound, setUrlSection } from './DrawsFunctions';
import { doMeasurement } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: DrawsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['DrawsPage'],
		stubPages: state['Config'].stubPages,
		text: state['Config'].text,
		tourn_events: state.Config?.scoringConfig?.eventNames,
		round_names: state.Config?.scoringConfig?.roundNames,
		drawData: op.get(state['Tournament'], 'data.draw', null),
		drawEventsData: op.get(state['Tournament'], 'data.drawEvents', null),
		pathToTheFinal: op.get(state['PathToTheFinal'], null),
		Gigya: state.Gigya,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	unmount: data => dispatch(deps.actions.DrawsPage.unmount(data)),
	pathToTheFinalUnmount: data => dispatch(deps.actions.PathToTheFinal.unmount(data)),
	search: data => dispatch(deps.actions.DrawsPage.search(data)),
	getDraws: event => dispatch(deps.actions.Tournament.getDraws({ event: event })),
	getDrawsEvents: () => dispatch(deps.actions.Tournament.getDrawsEvents()),
	drawsPageMountCheck: value => dispatch(deps.actions.Controller.drawsPageMountCheck(value)),
});
class DrawsPage extends Component {
	constructor(props) {
		super(props);

		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
		this.debug = parsedQs.debug;

		this.state = {
			playersPlaying: [],
			playersSet: {},
			drawListData: null, // data that should be displayed
			eventLoading: false,
			// selectedRound: 0,
			// selectedSection: '',
			defaultPlayer: parsedQs?.playerId ? parsedQs.playerId : false,
			appFavs: window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : [],
			debug: parsedQs.debug == 'true',
		};

		this.onEnter = this.onEnter.bind(this);
		this.firstLoad = true;
		this.eventMeasured = false;
		this.year = values.tournamentYear;
		// this.setEventUrl = this.setEventUrl.bind(this);

		logger.log('[DrawsPage] constructor - this:%o', this);

		this.event = '';

		this.initLoad = true;

		logger.log('[DrawsPage] constructor - debug:%o', this.debug);
	}

	componentDidMount() {
		// fire controller action for promo check -- only do so for anonymous user
		this.props.drawsPageMountCheck(true);
	}

	componentWillUnmount() {
		this.eventMeasured = false;

		document.body?.classList?.remove('modal-on-noscroll');
		document?.querySelector('.footer-global')?.classList.remove('hide');

		this.props.unmount({
			drawsPath: '',
			mountStatus: null,
		});

		this.props.pathToTheFinalUnmount();

		//removing player highlight when unmounting component
		this.props.search({
			searchedPlayer: '',
		});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		//logger.info('[DrawsPage] getDerivedStateFromProps - prevState:%o', prevState.drawData);
		//logger.info('[DrawsPage] getDerivedStateFromProps - nextProps:%o', nextProps.drawData);

		let newEvent = false;

		if (
			nextProps.drawData?.status == 'loaded' &&
			nextProps?.drawData?.data?.eventName != prevState?.drawData?.data?.eventName
		) {
			newEvent = true;
		}

		let newState = nextProps
			? {
					...nextProps,
					isNewEvent: newEvent,
					playerData: newEvent ? null : prevState.playerData,
			  }
			: null;

		if (newEvent) {
			newState = {
				...newState,
				isPdfEvent: false,
				showStub: false,
			};
			// logger.info(
			// 	'[DrawsPage] getDerivedStateFromProps - prevState:%s',
			// 	prevState?.drawData?.data?.eventName
			// );
			// logger.info(
			// 	'[DrawsPage] getDerivedStateFromProps - nextProps:%s',
			// 	nextProps?.drawData?.data?.eventName
			// );
			logger.info('[DrawsPage] getDerivedStateFromProps - newState:%o', newState);
		}

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[DrawsPage] componentDidUpdate this:%o, prevState:%o, prevProps:%o', this, prevState, prevProps);
		// logger.log('[DrawsPage] componentDidUpdate - this:%o', this);

		//let isNewEvent = this.isNewEvent();
		//logger.log('[DrawsPage] componentDidUpdate - isNewEvent:%o', isNewEvent);

		/**
		 * if there is no drawEventsData or the event changes and the
		 * status is not "loading", load draw events data - list of
		 * currently available draws
		 */
		if (
			(!this.state?.drawEventsData?.data || this.props?.match?.params?.event != this.event) &&
			this.state?.drawEventsData?.status != 'loading' &&
			this.state?.drawEventsData?.status != 'error'
		) {
			this.props.getDrawsEvents();
		}

		/**
		 * get the event from the url.  If the event from the url is
		 * not the same as the current event on the page, reset the page
		 * event, set the selected event and get the draws data from
		 * tournament data if the event is in the drawEventsData, if not, then
		 * set showStub to true
		 */
		if (
			this.props?.match?.params?.event != this.event &&
			this.state?.tourn_events &&
			this.state?.drawEventsData.status == 'loaded'
		) {
			this.event = this.props.match.params.event;
			// logger.log('[DrawsPage] componentDidUpdate - new event: %s', this.event);

			let drawsList = this.props?.tourn_events;
			let excludeList = this.props?.drawExclude;
			this.includedDraws = drawsList.filter(event => {
				if (excludeList?.indexOf(event?.code) < 0) {
					return true;
				}
			});

			logger.log(
				'[DrawsPage] fixedIncludedDraws - includedDraws:%o, drawsList:%o',
				this.includedDraws.length,
				drawsList.length
			);

			this.selectedEvent = this.props.match.params.event
				? getEventCode(this.props.match.params.event, this.includedDraws)
				: 'MS';

			let found = this.state.drawEventsData.data.find(item => item.id == this.selectedEvent);
			let isPdfEvent = this.state?.drawPdfEvents?.includes(this.selectedEvent);
			let isPdfEventStubbed = this.state?.stubPages?.drawsPdfEvents?.includes(this.selectedEvent);

			if (found) {
				this.props.getDraws(this.selectedEvent);
			} else if (!found && isPdfEvent) {
				this.setState({
					showStub: null,
					isPdfEvent: true,
					isPdfEventStubbed: isPdfEventStubbed,
				});
			} else {
				this.setState({
					showStub: true,
				});
			}

			/** reset Path to the final */
			this.props.pathToTheFinalUnmount();
		}

		if (this.state.isNewEvent) {
			logger.log('[DrawsPage] componentDidUpdate - event change: %s', this.state?.drawData.data.eventName);
		}

		/** if new event and not yet measured, make pageview measurement call **/
		if (this.state?.drawData && !this.eventMeasured) {
			this.eventMeasured = true;
			// MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			// 	pageTitle: getEventName(this.selectedEvent, this.includedDraws),
			// 	webview: window.webview ? true : false,
			// });

			// simulate dropdown selected event in webview and measure 'new event' as selected event

			let measureArg0 = 'pageView';
			let measureArgs = [];
			let contextData = [{ pageTitle: 'Draws' }, { event: this.props.match.params.event }];
			let metricType = '';

			if (values.webview) {
				measureArg0 = this.props.match.params.event;
				measureArgs = [];
				contextData = [{ drawsEvent: this.props.match.params.event }, { section: "Draws" }];
				metricType = 'state';
			}

			doMeasurement('Draws', measureArg0, measureArgs, contextData, metricType);
		}

		/**
		 * if a new event should have data and can get player data
		 */
		if (this.state.isNewEvent) {
			// fire controller action for promo check -- only do so for anonymous user
			this.props.drawsPageMountCheck(true);

			// logger.log('[DrawsPage] componentDidUpdate loading player data this:%o', this);
			let playerData = {};
			let { data } = this.state.drawData;

			playerData = getPlayersData(data, this.selectedEvent, data.totalRounds);

			this.setState({
				playerData: playerData,
				playersPlaying: playerData.playersPlaying,
				playersSet: playerData.playersSet,
			});
		}

		/**
		 * if an event round or section is changed
		 */
		if (
			this.state?.drawData?.data?.matches &&
			(this.props?.match?.params?.round !== prevState.match?.params?.round ||
				this.props?.match?.params?.section !== prevState.match?.params?.section)
		) {
			logger.log(
				'[DrawsPage] round changed - newRound:%o, prevRound:%o, view:%o',
				this.props?.match?.params?.round,
				prevState.match?.params?.round,
				this.props.view
			);

			this.setState({
				renderData: this.getRenderData(),
			});

			/** make sure to clean the scroll position */
			if (this.props?.pathToTheFinal?.defaultTop > 0) {
				this.props.pathToTheFinalUnmount();
			}
		}

		/** when the Path To The Final overlay is closed, scroll to the previously stayed position */
		if (
			this.props.pathToTheFinal?.defaultTop &&
			this.props.pathToTheFinal?.display !== prevProps.pathtothefinal?.display &&
			!this.props.pathToTheFinal?.display
		) {
			window.scrollBy(0, this.props.pathToTheFinal.defaultTop);
		}

		/** default open Pass to the Final when coming from Power Index */
		// if (op.get(this.state, 'drawData.status', false) == 'loaded' && this.initLoad && this.state.defaultPlayer) {
		// 	this.initLoad = false;

		// 	// call action to open Path to the Final overlay
		// 	this.props.showPathToTheFinal(
		// 		this.state.defaultPlayer,
		// 		getSinglesEventCodeByPlayerId(this.state.defaultPlayer)
		// 	);
		// }

		/** get app favs and save to state */
		if (this.state.location.search && getQSParams(this.state.location.search, 'filterPlayers')) {
			let favPlayers = this?.state?.appFavs;
			if (favPlayers !== this.state?.webViewFavs) {
				this.setState(prevState => ({
					...prevState,
					webViewFavs: favPlayers,
				}));
			}
		}

		/** check if a searched player is in current section */
		if (this.renderData?.selectedSection != prevState?.renderData?.selectedSection) {
			let playerId = this.state?.searchedPlayer;
			let el = document.getElementById(playerId);

			if (this.state?.searchedPlayer && el !== null) {
				el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			}
		}
	}

	isWebviewFav = playerId => {
		logger.log(
			'[DrawsPage] - isWebviewFav - appFavs:%o, %o:%o',
			this.state.appFavs,
			playerId,
			this.state.appFavs.indexOf(playerId) !== -1
		);
		return this.state.appFavs.indexOf(playerId) !== -1;
	};

	updateAppFavs = (playerId, shouldAdd) => {
		logger.log('[DrawsPage] - updateAppFavs - playerId:%o', playerId, shouldAdd);
		let newFavs = Object.assign([], this.state.appFavs);
		//logger.log('[DrawsPage] - updateAppFavs - current state.appFavs:%o, newAppFavs:%o',this.state.appFavs, newFavs);

		if (shouldAdd) {
			newFavs.push(playerId);
		} else {
			newFavs = this.state.appFavs.filter(fav => fav !== playerId);
		}

		//logger.log('[DrawsPage] - updateAppFavs before state change - newFavs:%o',newFavs);

		this.setState(
			{
				appFavs: newFavs,
			},
			() => {
				logger.log('[DrawsPage] - updateAppFavs - new appFavs:%o', this.state.appFavs);
			}
		);
	};

	/**
	 * when event changes set local vars to false
	 * so data will be loaded for new event
	 **/
	onEventChange = event => {
		logger.log('[DrawsPage] onEventChange - event:%o', event);
		this.eventMeasured = false;

		if (this.state?.drawPdfEvents?.includes(event)) {
			this.setState({
				isPdfEvent: true,
			});
		} else {
			this.setState({
				showStub: false,
				isPdfEvent: false,
				isPdfEventStubbed: false,
			});
		}

		let path = `${getRoute(event, this.includedDraws)}`;
		this.props.history.push(path);
	};

	onNavigatorChange = (round, section, view) => {
		logger.log('[DrawsPage] onNavigatorChange - round:%o section:%o', round, section, view);

		let path;

		if (view == 'fullView') {
			path = `${getRoute(this.selectedEvent, this.includedDraws)}/full`;
		} else if (round && section) {
			path = `${getRoute(this.selectedEvent, this.includedDraws)}/${round}/${section}`;
		} else if (round && !section) {
			path = `${getRoute(this.selectedEvent, this.includedDraws)}/${round}`;
		} else {
			path = `${getRoute(this.selectedEvent, this.includedDraws)}`;
		}

		this.props.history.push(path);
	};

	onRoundChange = (round, correctedRound, section, correctedSection, currentEventRound, isEventWithHalf) => {
		let path = '';
		let selectedRound;
		let selectedSection = section;

		if (round && round == 'full') {
			selectedRound = 'full';
		} else {
			selectedRound = parseInt(round);
		}

		// logger.log('[DrawsPage] onRoundChange - isEventWithHalf:%o, selectedRound:%o, correctedRound:%o, selectedSection:%o, correctedSection:%o', isEventWithHalf, selectedRound, correctedRound, selectedSection, correctedSection);

		if (selectedRound != 'full') {
			if (selectedRound !== correctedRound && !isEventWithHalf) {
				path = `${getRoute(this.selectedEvent, this.includedDraws)}/${correctedRound}`;
				this.props.history.push(path);
			} else if (selectedRound > 2 && selectedSection) {
				path = `${getRoute(this.selectedEvent, this.includedDraws)}/${correctedRound}`;
				this.props.history.push(path);
			} else if (selectedRound <= 2 && isEventWithHalf && correctedSection) {
				if (parseInt(selectedSection) > parseInt(correctedSection)) {
					path = `${getRoute(this.selectedEvent, this.includedDraws)}/${selectedRound}/${correctedSection}`;
					this.props.history.push(path);
				}
			}
		}
	};

	onEnter = data => {
		let selectedRound = this.getRenderData().selectedRound;
		let selectedSection = this.getRenderData().selectedSection;

		let playerId = data.tour_id;
		let playersPlaying = this.state?.playersPlaying;
		let playerChecker = obj => obj.id === playerId;
		let isPlayerInEvent = playersPlaying.some(playerChecker);

		if (isPlayerInEvent) {
			if (data !== '') {
				this.props.search({
					searchedPlayer: data.tour_id,
				});

				let el = document.getElementById(data.tour_id);

				if (!el || el == null) {
					if (selectedSection == '1' && el == null) {
						this.onNavigatorChange(`${selectedRound}`, '2');
					}
					if (selectedSection == '2' && el == null) {
						this.onNavigatorChange(`${selectedRound}`, '1');
					}
				}

				if (el !== null) {
					el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
				}
			}
		} else {
			this.props.search({
				searchedPlayer: '',
			});
		}

		/** if the X icon is clicked, set the value to 'Clear Search' */
		let playerName = data?.firstName && data?.lastName 
						? data.firstName + ' ' + data.lastName 
						: data == ''
						? 'Clear Search'
						: '';


		let measureArg0 = 'Player Search';
		let measureArgs = [playerName];
		let contextData = [];
		let metricType = '';

		if (values.webview) {
			contextData = [{drawsPlayerSearch: playerName}];
			metricType = 'action';
		}

		doMeasurement('Draws', measureArg0, measureArgs, contextData, metricType);
	};

	getStubMessage(event) {
		let drawsStub = this.props.stubPages.draws;
		let message = '';

		forEach(drawsStub, function(value, key) {
			if (key == event) {
				message = value;
			}
		});
		//logger.log('[DrawsPage] getStubMessage event:%o, message:%o', event, message);

		return message;
	}

	getScrollHandler() {
		let elem = document.getElementsByClassName('roundGroup')[0];

		if (elem) {
			return <ScrollHandler hasData={true} filter={'none'} />;
		} else {
			return null;
		}
	}

	renderEventSelect(newPath) {
		if (!window.webview) {
			return (
				<EventSelect
					basePath={newPath}
					selected={this.selectedEvent}
					events={this.includedDraws}
					filters={null}
					onChange={this.onEventChange}
					onClose={null}
				/>
			);
		} else {
			return null;
		}
	}

	renderDrawsNavigator(data, newPath) {
		return (
			<DrawsNavigator
				event={this.selectedEvent}
				round={data.selectedRound}
				section={data.selectedSection}
				roundNames={this.state.round_names}
				totalRounds={this.state?.drawData?.data?.totalRounds}
				basePath={newPath}
				setEventUrl={this.setEventUrl}
				onNavigatorSelect={this.onNavigatorChange}
				eventName={this.props?.match?.params?.event}
			/>
		);
	}

	renderDrawsList(data, flagImagePathSmall, playerProfileImagePath) {
		return (
			<DrawsLists
				attributes={{
					data: data.columnData,
					matchBoxStyle: '',
					showSlamTrackerLinks: true,
					profileLinks: 'main',
					archiveYear: '',
					flagImagePathSmall,
					playerProfileImagePath,
					event: this.selectedEvent,
					round: data.selectedRound,
				}}
				webViewFavs={this.state.webViewFavs}
				debug={this.state.debug}
			/>
		);
	}

	renderPageContents() {
		let renderData = null;
		if (this.state?.playerData && this.state?.drawData?.status == 'loaded') {
			renderData = this.getRenderData();
		}

		let roundName = [];

		if (renderData) {
			roundName = Object.keys(renderData?.columnData).map(column => {
				return (
					<span key={column} tabIndex={0}>
						{renderData.columnData[column].title}
					</span>
				);
			});
		}

		let flagImagePathSmall = this.state.flagImagePathSmall;

		let playerProfileImagePath = this.state.playerProfileImagePath;
		let eventName = getEventName(this.selectedEvent, this.includedDraws);

		//let newPath = path;
		let newPath = this.state?.location?.pathname;
		let pdfPath = this.props.pdfPath;
		// let pdfEventLink = this.state?.pdfPath?.replace('<eventId>', this.selectedEvent);
		let pdfEventLink = `/pdf/update/referees/${values.tournamentYear}/${this.selectedEvent}_Draw.pdf`;

		const pageClassnames = () => {
			return cn({
				// wrapper: true,
				// scorespage: true,
				'content-main': true,
				draws: true,
				fullView: this.props.view == 'fullView',
				[`rounds_${this.state?.drawData?.data?.totalRounds}`]: this.props.view == 'fullView',
			});
		};

		// convert players playing list to match searchbox - searchlist variables
		let playerSearchData = [];

		if (this.state.playersPlaying) {
			this.state.playersPlaying.map((p, i) => {
				if (p.id && p.lastName !== 'Loser') {
					playerSearchData.push({
						first_name: p.firstName,
						last_name: p.lastName,
						player_id: p.id,
						id: p.id,
					});
				}
			});
		}

		// logger.log('[DrawsPage] renderPageContents - playerData%o', this.state?.playerData);

		//TODO: better way to check for errors, unavialable data
		if (
			this.includedDraws &&
			(op.get(this.state, 'drawData.status', '') == 'error' ||
				!op.get(this.state, 'drawEventsData.status', '') == 'error')
		) {
			return (
				<div className={pageClassnames()}>
					{this.renderEventSelect(newPath)}
					<GenericError message="Draws data for this event is not available." />
				</div>
			);
		} else if (
			this.includedDraws &&
			this.state.stubPages &&
			(!this.state.drawData ||
				this.state?.showStub == true ||
				this.state?.drawData?.status == 'error' ||
				(this.state.isPdfEvent && this.state.isPdfEventStubbed))
		) {
			return (
				<div className={pageClassnames()}>
					<div className="column-layout no-padding">
						<div className="header">
							<div className="navItemFull">{this.renderEventSelect(newPath)}</div>
						</div>
					</div>
					<StubBox attributes={{ message: this.getStubMessage(this.selectedEvent), style: 'centerV' }} />
				</div>
			);
		} else if (
			this.includedDraws &&
			op.get(this.state, 'drawData.status', false) == 'loaded' &&
			op.get(this.state, 'drawEventsData.status', false) == 'loaded' &&
			renderData &&
			!this.state.isPdfEvent
		) {
			return (
				<div className={pageClassnames()}>
					<div className="column-layout no-padding">
						<div className="header">
							<div className="navItemFull">{this.renderEventSelect(newPath)}</div>
						</div>
					</div>
					<div className="column-layout no-padding">
						<header className="header">
							<div className="navItem">
								<SearchBox searchList={playerSearchData} onEnter={this.onEnter} />
							</div>
							{!window.webview ? (
								<>
									<div className="navItemFixed">
										<Favorites />
									</div>
									<div className="navItemFixed" aria-label="Draws PDF" alt="Draws PDF" title="Draws PDF">
										<div className="print">
											<WimLink
												to={pdfPath.replace('<eventId>', this.selectedEvent)}
												external={true}
												title="Draws PDF"
												labelledBy="draws-pdf-icon-label"
												style="white">
												<label className="draws-pdf-icon-label" id="draws-pdf-icon-label" for="draws-pdf-icon">Draws PDF</label>
												<i id="draws-pdf-icon" className="wim-icon-pdf" aria-label="Draws PDF" alt="Draws PDF" title="Draws PDF" />
											</WimLink>
										</div>
									</div>
								</>
							) : null}
						</header>
					</div>
					<div className="content-filters-scroll"></div>
					{this.renderDrawsNavigator(renderData, newPath)}
					<div className={`column-layout draws-page ${this.props.view == 'fullView' ? 'no-padding' : ''}`}>
						{this.props.view == 'fullView' && <div className="roundName">{roundName}</div>}
						<div className={`drawsList draws ${this.props.view == 'fullView' ? 'fullView' : ''}`}>
							{this.renderDrawsList(renderData, flagImagePathSmall, playerProfileImagePath)}
						</div>
					</div>
					{this.getScrollHandler()}

					{/** load Path to the Final */
					this.props?.pathToTheFinal?.display && (
						<PathToTheFinal
							appsData={{
								appFavs: this.state.appFavs,
								appFavCallback: () => this.updateAppFavs,
							}}
						/>
					)}
				</div>
			);
		} else if (this.includedDraws && this.state.isPdfEvent && !this.state.isPdfEventStubbed) {
			return (
				<div className={pageClassnames()}>
					<div className="column-layout no-padding">
						<div className="header">
							<div className="navItemFull">{this.renderEventSelect(newPath)}</div>
						</div>
					</div>

					<div className={`four-col fullHeight`}>
						<Button
							to={`${pdfEventLink}`}
							external={true}
							className={`btn solid compact`}
							style={{ textTransform: 'uppercase', color: 'white' }}>
							Open draw PDF
						</Button>
						<iframe
							src={pdfEventLink}
							allowFullScreen={true}
							marginWidth="0"
							marginHeight="0"
							frameBorder="0"
							className={`embed-responsive-item`}
							// width="100%"
							// height="100vh"
							style={{ padding: '10px 20px', height: '100%', width: '100%' }}
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div className={pageClassnames()}>
					<LoadingIndicator type={'white'} />
				</div>
			);
		}
	}

	getRenderData = () => {
		let selectedSection = this.props.match?.params?.section;
		let showFullView = this.props.match?.params?.full;
		let currentEventRound =
			this.props.view !== 'fullView' ? getCurrentRound(this.state?.drawData?.data?.matches) : 1;

		let selectedRound = parseInt(this.props.match?.params?.round)
			? parseInt(this.props.match?.params?.round)
			: this.props.match?.params?.full
			? 'full'
			: currentEventRound;

		logger.log(
			'[DrawsPage] getRenderData selectedRound:%o, selectedSection:%o, showFullView:%o',
			selectedRound,
			selectedSection,
			showFullView
		);

		let correctedEvent = getCorrectEventId(this.selectedEvent, this.state?.drawData?.data?.totalRounds);
		let labelDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundNum : null;
		let isEventWithHalf;
		let correctedRound;
		let correctedSection;

		if (labelDisp) {
			isEventWithHalf = labelDisp[selectedRound] == 2;
		}

		if (selectedRound == 'full') {
			correctedRound = 'full';
		} else if (!selectedRound || selectedRound > this.state?.drawData?.data?.totalRounds || selectedRound < 1) {
			correctedRound = currentEventRound;
		} else {
			correctedRound = selectedRound;
		}

		if (isEventWithHalf) {
			if (!selectedSection || parseInt(selectedSection) < 1 || parseInt(selectedSection) > 2) {
				correctedSection = '1';
			} else {
				correctedSection = selectedSection;
			}
		} else {
			correctedSection = null;
		}

		let roundColumnData = getRoundColumnsData(
			this.state.drawData.data,
			this.selectedEvent,
			correctedRound,
			correctedSection,
			showFullView
		);

		roundColumnData.selectedRound = selectedRound;
		roundColumnData.selectedSection = correctedSection;

		this.onRoundChange(
			selectedRound,
			correctedRound,
			selectedSection,
			correctedSection,
			currentEventRound,
			isEventWithHalf
		);

		logger.log('[DrawsPage] getRenderData -  getRenderData roundColumnData:%o', roundColumnData);
		return roundColumnData;
	};

	render() {
		logger.log('[DrawsPage] render this:%o', this);

		//let newPath = this.getNewPath();

		let titleName = getEventName(this.selectedEvent, this.includedDraws);
		let pageDescription = this.props?.text?.descriptions?.draws || '';
		pageDescription = pageDescription.replace('<event>', titleName);
		pageDescription = pageDescription.replace('<year>', this.year);

		let header_propsData = {
			headerType: 'scores',
			title: 'Draws',
			shortTitle: 'Draws',
			metaTitle: 'The ' + titleName + ' Draw',
			metaDescription: pageDescription,
			metaDate: '',
			metaPlayers: '',
		};

		//let stubMessage = this.getStubMessage(this.selectedEvent);
		return !window.webview ? (
			<Template className="scorespage">
				<>
					<div className="scores-background" />
					<Header attributes={header_propsData} />
					<PageHeader attributes={header_propsData} />
					{this.renderPageContents()}
					<Footer />
				</>
			</Template>
		) : (
			<Template className={`scorespage webview default ${this.debug ? 'debug' : ''}`}>
				{this.renderPageContents()}
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsPage);

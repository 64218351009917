/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Likelihood to Win bnnner
 *
 *  props = {
 *			   direction --- "left" or "right" or center or "none" for upcoming matches
 *           }
 *
 * -----------------------------------------------------------------------------
 */
const LikelihoodWinBanner = props => {
	// logger.log('[MITLikelihoodWinBannereaser] props:%o', props);

	let { direction, upcoming, pos } = props;
	let poweredByText = true;
	direction = direction == 'left' || direction == 'right' || direction == 'center' ? direction : 'right'; //testing purposes
	if (upcoming === true && direction == 'center') {
		direction = 'none';
		poweredByText = false;
	}
	// logger.log('[MITLikelihoodWinBannereaser] upcoming:%o', upcoming);
	return (
		<div className="LikelihoodToWinBanner">
			<div className={`likelihood-win-btn ${direction} ${poweredByText === true ? 'powered-by' : ''}`}>
				{upcoming === true && direction == 'none' ? (
					`${pos == 0 ? `Up Next` : `Later Today`}`
				) : (
					<>
						Likelihood to Win
					</>
				)}
			</div>
		</div>
	);
};

export default LikelihoodWinBanner;

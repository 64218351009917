/**
 * Generated by createManifest.js
 * DO NOT directly edit this file !!!!!!
 */

module.exports = {
    get: () => {
        return {
            allActions: {
                ContentList: require('components/cms/ContentList/actions')
                    .default,
                Draw: require('components/cms/Draw/actions').default,
                EventStats: require('components/cms/EventStats/actions')
                    .default,
                Gallery: require('components/cms/Gallery/actions').default,
                HeadToHead: require('components/cms/HeadToHead/actions')
                    .default,
                MatchInsightsCard:
                    require('components/cms/MatchInsightsCard/actions').default,
                MatchResult: require('components/cms/MatchResult/actions')
                    .default,
                MatchStats: require('components/cms/MatchStats/actions')
                    .default,
                MatchStatsFull: require('components/cms/MatchStatsFull/actions')
                    .default,
                OrderOfPlay: require('components/cms/OrderOfPlay/actions')
                    .default,
                Player: require('components/cms/Player/actions').default,
                ShopCarousel: require('components/cms/ShopCarousel/actions')
                    .default,
                HaveYourSay: require('components/common-ui/HaveYourSay/actions')
                    .default,
                PathToTheFinal:
                    require('components/common-ui/PathToTheFinal/actions')
                        .default,
                SmartSearch: require('components/common-ui/SmartSearch/actions')
                    .default,
                ActiveData: require('components/data/ActiveData/actions')
                    .default,
                CommonData: require('components/data/CommonData/actions')
                    .default,
                Config: require('components/data/Config/actions').default,
                Tournament: require('components/data/Tournament/actions')
                    .default,
                WeatherData: require('components/data/WeatherData/actions')
                    .default,
                DrawsArchiveContentPage:
                    require('components/drawsArchive/DrawsArchiveContentPage/actions')
                        .default,
                DrawsArchiveCountryAbbreviations:
                    require('components/drawsArchive/DrawsArchiveCountryAbbreviations/actions')
                        .default,
                DrawsArchiveEvents:
                    require('components/drawsArchive/DrawsArchiveEvents/actions')
                        .default,
                DrawsArchiveLadiesNames:
                    require('components/drawsArchive/DrawsArchiveLadiesNames/actions')
                        .default,
                DrawsArchiveMenu:
                    require('components/drawsArchive/DrawsArchiveMenu/actions')
                        .default,
                DrawsArchiveSearch:
                    require('components/drawsArchive/DrawsArchiveSearch/actions')
                        .default,
                DrawsArchiveTopStats:
                    require('components/drawsArchive/DrawsArchiveTopStats/actions')
                        .default,
                DrawsArchiveYear:
                    require('components/drawsArchive/DrawsArchiveYear/actions')
                        .default,
                PlayerProfile:
                    require('components/drawsArchive/PlayerProfile/actions')
                        .default,
                ConfirmationModal:
                    require('components/general/ConfirmationModal/actions')
                        .default,
                Controller: require('components/general/Controller/actions')
                    .default,
                Footer: require('components/general/Footer/actions').default,
                Gigya: require('components/general/Gigya/actions').default,
                Header: require('components/general/Header/actions').default,
                HillChannel: require('components/general/HillChannel/actions')
                    .default,
                HomePromos: require('components/general/HomePromos/actions')
                    .default,
                LiveNav: require('components/general/LiveNav/actions').default,
                Login: require('components/general/Login/actions').default,
                MainNav: require('components/general/MainNav/actions').default,
                MainVideoChannel:
                    require('components/general/MainVideoChannel/actions')
                        .default,
                PageHeader: require('components/general/PageHeader/actions')
                    .default,
                PlayerGeneralInfo:
                    require('components/general/PlayerGeneralInfo/actions')
                        .default,
                PrivacyBanner:
                    require('components/general/PrivacyBanner/actions').default,
                PromoFeature: require('components/general/PromoFeature/actions')
                    .default,
                ScoreManager: require('components/general/ScoreManager/actions')
                    .default,
                ScrollHandler:
                    require('components/general/ScrollHandler/actions').default,
                Sidepanel: require('components/general/Sidepanel/actions')
                    .default,
                SiteMap: require('components/general/SiteMap/actions').default,
                SlamtrackerPanel:
                    require('components/general/SlamtrackerPanel/actions')
                        .default,
                VideoPlayer: require('components/general/VideoPlayer/actions')
                    .default,
                VideoWrapper: require('components/general/VideoWrapper/actions')
                    .default,
                WeatherStatus:
                    require('components/general/WeatherStatus/actions').default,
                AICatchUp: require('components/pages/AICatchUp/actions')
                    .default,
                BreakpointPage:
                    require('components/pages/BreakpointPage/actions').default,
                CompetitorListPage:
                    require('components/pages/CompetitorListPage/actions')
                        .default,
                ContactPage: require('components/pages/ContactPage/actions')
                    .default,
                ContactThankYou:
                    require('components/pages/ContactThankYou/actions').default,
                ContentPage: require('components/pages/ContentPage/actions')
                    .default,
                ContentPageSecure:
                    require('components/pages/ContentPageSecure/actions')
                        .default,
                CountryPlayersPage:
                    require('components/pages/CountryPlayersPage/actions')
                        .default,
                CountryStatisticsPage:
                    require('components/pages/CountryStatisticsPage/actions')
                        .default,
                DrawsPage: require('components/pages/DrawsPage/actions')
                    .default,
                FramePages: require('components/pages/FramePages/actions')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/actions')
                    .default,
                HomePage: require('components/pages/HomePage/actions').default,
                InsightsIndex: require('components/pages/InsightsIndex/actions')
                    .default,
                JobsPage: require('components/pages/JobsPage/actions').default,
                LiveRadio: require('components/pages/LiveRadio/actions')
                    .default,
                LiveScores: require('components/pages/LiveScores/actions')
                    .default,
                LiveVideo: require('components/pages/LiveVideo/actions')
                    .default,
                LiveVideoHill: require('components/pages/LiveVideoHill/actions')
                    .default,
                MatchArchive: require('components/pages/MatchArchive/actions')
                    .default,
                MatchInsights: require('components/pages/MatchInsights/actions')
                    .default,
                MediaRequest: require('components/pages/MediaRequest/actions')
                    .default,
                Member: require('components/pages/Member/actions').default,
                MobileAppsPage:
                    require('components/pages/MobileAppsPage/actions').default,
                MyWimbledon: require('components/pages/MyWimbledon/actions')
                    .default,
                MyWimbledonDelete:
                    require('components/pages/MyWimbledonDelete/actions')
                        .default,
                MyWimbledonLoginOIDC:
                    require('components/pages/MyWimbledonLoginOIDC/actions')
                        .default,
                MyWimbledonLoginSAML:
                    require('components/pages/MyWimbledonLoginSAML/actions')
                        .default,
                MyWimbledonRedirect:
                    require('components/pages/MyWimbledonRedirect/actions')
                        .default,
                NewsIndex: require('components/pages/NewsIndex/actions')
                    .default,
                PDEContentDash:
                    require('components/pages/PDEContentDash/actions').default,
                PDEMatchStats: require('components/pages/PDEMatchStats/actions')
                    .default,
                PlayerChallengePage:
                    require('components/pages/PlayerChallengePage/actions')
                        .default,
                PlayerDigitalExperience:
                    require('components/pages/PlayerDigitalExperience/actions')
                        .default,
                PlayerPage: require('components/pages/PlayerPage/actions')
                    .default,
                PlayerStatsPage:
                    require('components/pages/PlayerStatsPage/actions').default,
                PlayersIndex: require('components/pages/PlayersIndex/actions')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/actions')
                    .default,
                RoleRegistrationInvite:
                    require('components/pages/RoleRegistrationInvite/actions')
                        .default,
                SchedulePage: require('components/pages/SchedulePage/actions')
                    .default,
                ScoreboardDemo:
                    require('components/pages/ScoreboardDemo/actions').default,
                SearchPage: require('components/pages/SearchPage/actions')
                    .default,
                SeatingViews: require('components/pages/SeatingViews/actions')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/actions')
                    .default,
                TestPage: require('components/pages/TestPage/actions').default,
                Tickets: require('components/pages/Tickets/actions').default,
                Upcoming: require('components/pages/Upcoming/actions').default,
                VideoIndex: require('components/pages/VideoIndex/actions')
                    .default,
                WatchReadPage: require('components/pages/WatchReadPage/actions')
                    .default,
                WeatherPage: require('components/pages/WeatherPage/actions')
                    .default,
                WimbledonWish: require('components/pages/WimbledonWish/actions')
                    .default,
                WinFactorsFinder:
                    require('components/pages/WinFactorsFinder/actions')
                        .default,
                MatchBox: require('components/scoring/MatchBox/actions')
                    .default,
                AICatchUpFullWebview:
                    require('components/webviews/AICatchUpFullWebview/actions')
                        .default,
                AICatchUpHomeWebview:
                    require('components/webviews/AICatchUpHomeWebview/actions')
                        .default,
                AtoZWebview: require('components/webviews/AtoZWebview/actions')
                    .default,
                ContactUsPage:
                    require('components/webviews/ContactUsPage/actions')
                        .default,
                ContentPageWebview:
                    require('components/webviews/ContentPageWebview/actions')
                        .default,
                HillVoteWebview:
                    require('components/webviews/HillVoteWebview/actions')
                        .default,
                LoginWebview:
                    require('components/webviews/LoginWebview/actions').default,
                PlayerPageWebview:
                    require('components/webviews/PlayerPageWebview/actions')
                        .default,
                RadioWebview:
                    require('components/webviews/RadioWebview/actions').default,
                ScheduleWebview:
                    require('components/webviews/ScheduleWebview/actions')
                        .default,
                SlamtrackerWebview:
                    require('components/webviews/SlamtrackerWebview/actions')
                        .default,
                TicketResaleWebview:
                    require('components/webviews/TicketResaleWebview/actions')
                        .default,
                TicketsWebview:
                    require('components/webviews/TicketsWebview/actions')
                        .default,
                UpcomingWebview:
                    require('components/webviews/UpcomingWebview/actions')
                        .default,
                VirtualQueueWebview:
                    require('components/webviews/VirtualQueueWebview/actions')
                        .default,
                VisitWebview:
                    require('components/webviews/VisitWebview/actions').default,
                Plugable: require('dotix/components/Plugable/actions').default,
                Router: require('dotix/components/Router/actions').default,
            },
            allActionTypes: {
                ContentList: require('components/cms/ContentList/actionTypes')
                    .default,
                Draw: require('components/cms/Draw/actionTypes').default,
                EventStats: require('components/cms/EventStats/actionTypes')
                    .default,
                Gallery: require('components/cms/Gallery/actionTypes').default,
                HeadToHead: require('components/cms/HeadToHead/actionTypes')
                    .default,
                MatchInsightsCard:
                    require('components/cms/MatchInsightsCard/actionTypes')
                        .default,
                MatchResult: require('components/cms/MatchResult/actionTypes')
                    .default,
                MatchStats: require('components/cms/MatchStats/actionTypes')
                    .default,
                MatchStatsFull:
                    require('components/cms/MatchStatsFull/actionTypes')
                        .default,
                OrderOfPlay: require('components/cms/OrderOfPlay/actionTypes')
                    .default,
                Player: require('components/cms/Player/actionTypes').default,
                ShopCarousel: require('components/cms/ShopCarousel/actionTypes')
                    .default,
                HaveYourSay:
                    require('components/common-ui/HaveYourSay/actionTypes')
                        .default,
                PathToTheFinal:
                    require('components/common-ui/PathToTheFinal/actionTypes')
                        .default,
                SmartSearch:
                    require('components/common-ui/SmartSearch/actionTypes')
                        .default,
                ActiveData: require('components/data/ActiveData/actionTypes')
                    .default,
                CommonData: require('components/data/CommonData/actionTypes')
                    .default,
                Config: require('components/data/Config/actionTypes').default,
                Tournament: require('components/data/Tournament/actionTypes')
                    .default,
                WeatherData: require('components/data/WeatherData/actionTypes')
                    .default,
                DrawsArchiveContentPage:
                    require('components/drawsArchive/DrawsArchiveContentPage/actionTypes')
                        .default,
                DrawsArchiveCountryAbbreviations:
                    require('components/drawsArchive/DrawsArchiveCountryAbbreviations/actionTypes')
                        .default,
                DrawsArchiveEvents:
                    require('components/drawsArchive/DrawsArchiveEvents/actionTypes')
                        .default,
                DrawsArchiveLadiesNames:
                    require('components/drawsArchive/DrawsArchiveLadiesNames/actionTypes')
                        .default,
                DrawsArchiveMenu:
                    require('components/drawsArchive/DrawsArchiveMenu/actionTypes')
                        .default,
                DrawsArchiveSearch:
                    require('components/drawsArchive/DrawsArchiveSearch/actionTypes')
                        .default,
                DrawsArchiveTopStats:
                    require('components/drawsArchive/DrawsArchiveTopStats/actionTypes')
                        .default,
                DrawsArchiveYear:
                    require('components/drawsArchive/DrawsArchiveYear/actionTypes')
                        .default,
                PlayerProfile:
                    require('components/drawsArchive/PlayerProfile/actionTypes')
                        .default,
                ConfirmationModal:
                    require('components/general/ConfirmationModal/actionTypes')
                        .default,
                Controller: require('components/general/Controller/actionTypes')
                    .default,
                Footer: require('components/general/Footer/actionTypes')
                    .default,
                Gigya: require('components/general/Gigya/actionTypes').default,
                Header: require('components/general/Header/actionTypes')
                    .default,
                HillChannel:
                    require('components/general/HillChannel/actionTypes')
                        .default,
                LiveNav: require('components/general/LiveNav/actionTypes')
                    .default,
                Login: require('components/general/Login/actionTypes').default,
                MainNav: require('components/general/MainNav/actionTypes')
                    .default,
                MainVideoChannel:
                    require('components/general/MainVideoChannel/actionTypes')
                        .default,
                PageHeader: require('components/general/PageHeader/actionTypes')
                    .default,
                PlayerGeneralInfo:
                    require('components/general/PlayerGeneralInfo/actionTypes')
                        .default,
                PrivacyBanner:
                    require('components/general/PrivacyBanner/actionTypes')
                        .default,
                PromoFeature:
                    require('components/general/PromoFeature/actionTypes')
                        .default,
                ScoreManager:
                    require('components/general/ScoreManager/actionTypes')
                        .default,
                ScrollHandler:
                    require('components/general/ScrollHandler/actionTypes')
                        .default,
                Sidepanel: require('components/general/Sidepanel/actionTypes')
                    .default,
                SiteMap: require('components/general/SiteMap/actionTypes')
                    .default,
                SlamtrackerPanel:
                    require('components/general/SlamtrackerPanel/actionTypes')
                        .default,
                VideoPlayer:
                    require('components/general/VideoPlayer/actionTypes')
                        .default,
                VideoWrapper:
                    require('components/general/VideoWrapper/actionTypes')
                        .default,
                WeatherStatus:
                    require('components/general/WeatherStatus/actionTypes')
                        .default,
                AICatchUp: require('components/pages/AICatchUp/actionTypes')
                    .default,
                BreakpointPage:
                    require('components/pages/BreakpointPage/actionTypes')
                        .default,
                CompetitorListPage:
                    require('components/pages/CompetitorListPage/actionTypes')
                        .default,
                ContactPage: require('components/pages/ContactPage/actionTypes')
                    .default,
                ContactThankYou:
                    require('components/pages/ContactThankYou/actionTypes')
                        .default,
                ContentPage: require('components/pages/ContentPage/actionTypes')
                    .default,
                ContentPageSecure:
                    require('components/pages/ContentPageSecure/actionTypes')
                        .default,
                CountryPlayersPage:
                    require('components/pages/CountryPlayersPage/actionTypes')
                        .default,
                CountryStatisticsPage:
                    require('components/pages/CountryStatisticsPage/actionTypes')
                        .default,
                DrawsPage: require('components/pages/DrawsPage/actionTypes')
                    .default,
                FramePages: require('components/pages/FramePages/actionTypes')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/actionTypes')
                    .default,
                HomePage: require('components/pages/HomePage/actionTypes')
                    .default,
                InsightsIndex:
                    require('components/pages/InsightsIndex/actionTypes')
                        .default,
                JobsPage: require('components/pages/JobsPage/actionTypes')
                    .default,
                LiveRadio: require('components/pages/LiveRadio/actionTypes')
                    .default,
                LiveScores: require('components/pages/LiveScores/actionTypes')
                    .default,
                LiveVideo: require('components/pages/LiveVideo/actionTypes')
                    .default,
                LiveVideoHill:
                    require('components/pages/LiveVideoHill/actionTypes')
                        .default,
                MatchArchive:
                    require('components/pages/MatchArchive/actionTypes')
                        .default,
                MatchInsights:
                    require('components/pages/MatchInsights/actionTypes')
                        .default,
                MediaRequest:
                    require('components/pages/MediaRequest/actionTypes')
                        .default,
                Member: require('components/pages/Member/actionTypes').default,
                MobileAppsPage:
                    require('components/pages/MobileAppsPage/actionTypes')
                        .default,
                MyWimbledon: require('components/pages/MyWimbledon/actionTypes')
                    .default,
                MyWimbledonDelete:
                    require('components/pages/MyWimbledonDelete/actionTypes')
                        .default,
                MyWimbledonLoginOIDC:
                    require('components/pages/MyWimbledonLoginOIDC/actionTypes')
                        .default,
                MyWimbledonLoginSAML:
                    require('components/pages/MyWimbledonLoginSAML/actionTypes')
                        .default,
                MyWimbledonRedirect:
                    require('components/pages/MyWimbledonRedirect/actionTypes')
                        .default,
                NewsIndex: require('components/pages/NewsIndex/actionTypes')
                    .default,
                PDEContentDash:
                    require('components/pages/PDEContentDash/actionTypes')
                        .default,
                PDEMatchStats:
                    require('components/pages/PDEMatchStats/actionTypes')
                        .default,
                PlayerChallengePage:
                    require('components/pages/PlayerChallengePage/actionTypes')
                        .default,
                PlayerDigitalExperience:
                    require('components/pages/PlayerDigitalExperience/actionTypes')
                        .default,
                PlayerPage: require('components/pages/PlayerPage/actionTypes')
                    .default,
                PlayerStatsPage:
                    require('components/pages/PlayerStatsPage/actionTypes')
                        .default,
                PlayersIndex:
                    require('components/pages/PlayersIndex/actionTypes')
                        .default,
                ResultsPage: require('components/pages/ResultsPage/actionTypes')
                    .default,
                RoleRegistrationInvite:
                    require('components/pages/RoleRegistrationInvite/actionTypes')
                        .default,
                SchedulePage:
                    require('components/pages/SchedulePage/actionTypes')
                        .default,
                ScoreboardDemo:
                    require('components/pages/ScoreboardDemo/actionTypes')
                        .default,
                SearchPage: require('components/pages/SearchPage/actionTypes')
                    .default,
                SeatingViews:
                    require('components/pages/SeatingViews/actionTypes')
                        .default,
                Slamtracker: require('components/pages/Slamtracker/actionTypes')
                    .default,
                TestPage: require('components/pages/TestPage/actionTypes')
                    .default,
                Tickets: require('components/pages/Tickets/actionTypes')
                    .default,
                Upcoming: require('components/pages/Upcoming/actionTypes')
                    .default,
                VideoIndex: require('components/pages/VideoIndex/actionTypes')
                    .default,
                WatchReadPage:
                    require('components/pages/WatchReadPage/actionTypes')
                        .default,
                WeatherPage: require('components/pages/WeatherPage/actionTypes')
                    .default,
                WimbledonWish:
                    require('components/pages/WimbledonWish/actionTypes')
                        .default,
                WinFactorsFinder:
                    require('components/pages/WinFactorsFinder/actionTypes')
                        .default,
                MatchBox: require('components/scoring/MatchBox/actionTypes')
                    .default,
                AICatchUpFullWebview:
                    require('components/webviews/AICatchUpFullWebview/actionTypes')
                        .default,
                AICatchUpHomeWebview:
                    require('components/webviews/AICatchUpHomeWebview/actionTypes')
                        .default,
                AtoZWebview:
                    require('components/webviews/AtoZWebview/actionTypes')
                        .default,
                ContactUsPage:
                    require('components/webviews/ContactUsPage/actionTypes')
                        .default,
                ContentPageWebview:
                    require('components/webviews/ContentPageWebview/actionTypes')
                        .default,
                HillVoteWebview:
                    require('components/webviews/HillVoteWebview/actionTypes')
                        .default,
                LoginWebview:
                    require('components/webviews/LoginWebview/actionTypes')
                        .default,
                PlayerPageWebview:
                    require('components/webviews/PlayerPageWebview/actionTypes')
                        .default,
                RadioWebview:
                    require('components/webviews/RadioWebview/actionTypes')
                        .default,
                ScheduleWebview:
                    require('components/webviews/ScheduleWebview/actionTypes')
                        .default,
                SlamtrackerWebview:
                    require('components/webviews/SlamtrackerWebview/actionTypes')
                        .default,
                TicketResaleWebview:
                    require('components/webviews/TicketResaleWebview/actionTypes')
                        .default,
                TicketsWebview:
                    require('components/webviews/TicketsWebview/actionTypes')
                        .default,
                UpcomingWebview:
                    require('components/webviews/UpcomingWebview/actionTypes')
                        .default,
                VirtualQueueWebview:
                    require('components/webviews/VirtualQueueWebview/actionTypes')
                        .default,
                VisitWebview:
                    require('components/webviews/VisitWebview/actionTypes')
                        .default,
                Plugable: require('dotix/components/Plugable/actionTypes')
                    .default,
                Router: require('dotix/components/Router/actionTypes').default,
            },
            allReducers: {
                Draw: require('components/cms/Draw/reducers').default,
                EventStats: require('components/cms/EventStats/reducers')
                    .default,
                Gallery: require('components/cms/Gallery/reducers').default,
                HeadToHead: require('components/cms/HeadToHead/reducers')
                    .default,
                MatchInsightsCard:
                    require('components/cms/MatchInsightsCard/reducers')
                        .default,
                MatchResult: require('components/cms/MatchResult/reducers')
                    .default,
                MatchStats: require('components/cms/MatchStats/reducers')
                    .default,
                MatchStatsFull:
                    require('components/cms/MatchStatsFull/reducers').default,
                OrderOfPlay: require('components/cms/OrderOfPlay/reducers')
                    .default,
                Player: require('components/cms/Player/reducers').default,
                ShopCarousel: require('components/cms/ShopCarousel/reducers')
                    .default,
                HaveYourSay:
                    require('components/common-ui/HaveYourSay/reducers')
                        .default,
                HomePageFilter:
                    require('components/common-ui/HomePageFilter/reducers')
                        .default,
                PathToTheFinal:
                    require('components/common-ui/PathToTheFinal/reducers')
                        .default,
                SmartSearch:
                    require('components/common-ui/SmartSearch/reducers')
                        .default,
                ActiveData: require('components/data/ActiveData/reducers')
                    .default,
                CommonData: require('components/data/CommonData/reducers')
                    .default,
                Config: require('components/data/Config/reducers').default,
                Tournament: require('components/data/Tournament/reducers')
                    .default,
                WeatherData: require('components/data/WeatherData/reducers')
                    .default,
                DrawsArchiveContentPage:
                    require('components/drawsArchive/DrawsArchiveContentPage/reducers')
                        .default,
                DrawsArchiveCountryAbbreviations:
                    require('components/drawsArchive/DrawsArchiveCountryAbbreviations/reducers')
                        .default,
                DrawsArchiveEvents:
                    require('components/drawsArchive/DrawsArchiveEvents/reducers')
                        .default,
                DrawsArchiveLadiesNames:
                    require('components/drawsArchive/DrawsArchiveLadiesNames/reducers')
                        .default,
                DrawsArchiveMenu:
                    require('components/drawsArchive/DrawsArchiveMenu/reducers')
                        .default,
                DrawsArchiveSearch:
                    require('components/drawsArchive/DrawsArchiveSearch/reducers')
                        .default,
                DrawsArchiveTopStats:
                    require('components/drawsArchive/DrawsArchiveTopStats/reducers')
                        .default,
                DrawsArchiveYear:
                    require('components/drawsArchive/DrawsArchiveYear/reducers')
                        .default,
                PlayerProfile:
                    require('components/drawsArchive/PlayerProfile/reducers')
                        .default,
                ConfirmationModal:
                    require('components/general/ConfirmationModal/reducers')
                        .default,
                Controller: require('components/general/Controller/reducers')
                    .default,
                Footer: require('components/general/Footer/reducers').default,
                Gigya: require('components/general/Gigya/reducers').default,
                Header: require('components/general/Header/reducers').default,
                HillChannel: require('components/general/HillChannel/reducers')
                    .default,
                LiveNav: require('components/general/LiveNav/reducers').default,
                Login: require('components/general/Login/reducers').default,
                MainNav: require('components/general/MainNav/reducers').default,
                MainVideoChannel:
                    require('components/general/MainVideoChannel/reducers')
                        .default,
                PageHeader: require('components/general/PageHeader/reducers')
                    .default,
                PlayerGeneralInfo:
                    require('components/general/PlayerGeneralInfo/reducers')
                        .default,
                PrivacyBanner:
                    require('components/general/PrivacyBanner/reducers')
                        .default,
                PromoFeature:
                    require('components/general/PromoFeature/reducers').default,
                ScoreManager:
                    require('components/general/ScoreManager/reducers').default,
                ScrollHandler:
                    require('components/general/ScrollHandler/reducers')
                        .default,
                Sidepanel: require('components/general/Sidepanel/reducers')
                    .default,
                SiteMap: require('components/general/SiteMap/reducers').default,
                SlamtrackerPanel:
                    require('components/general/SlamtrackerPanel/reducers')
                        .default,
                VideoPlayer: require('components/general/VideoPlayer/reducers')
                    .default,
                VideoWrapper:
                    require('components/general/VideoWrapper/reducers').default,
                WeatherStatus:
                    require('components/general/WeatherStatus/reducers')
                        .default,
                AICatchUp: require('components/pages/AICatchUp/reducers')
                    .default,
                BreakpointPage:
                    require('components/pages/BreakpointPage/reducers').default,
                CompetitorListPage:
                    require('components/pages/CompetitorListPage/reducers')
                        .default,
                ContactPage: require('components/pages/ContactPage/reducers')
                    .default,
                ContactThankYou:
                    require('components/pages/ContactThankYou/reducers')
                        .default,
                ContentPage: require('components/pages/ContentPage/reducers')
                    .default,
                ContentPageSecure:
                    require('components/pages/ContentPageSecure/reducers')
                        .default,
                CountryPlayersPage:
                    require('components/pages/CountryPlayersPage/reducers')
                        .default,
                CountryStatisticsPage:
                    require('components/pages/CountryStatisticsPage/reducers')
                        .default,
                DrawsPage: require('components/pages/DrawsPage/reducers')
                    .default,
                FramePages: require('components/pages/FramePages/reducers')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/reducers')
                    .default,
                HomePage: require('components/pages/HomePage/reducers').default,
                InsightsIndex:
                    require('components/pages/InsightsIndex/reducers').default,
                JobsPage: require('components/pages/JobsPage/reducers').default,
                LiveRadio: require('components/pages/LiveRadio/reducers')
                    .default,
                LiveScores: require('components/pages/LiveScores/reducers')
                    .default,
                LiveVideo: require('components/pages/LiveVideo/reducers')
                    .default,
                LiveVideoHill:
                    require('components/pages/LiveVideoHill/reducers').default,
                MatchArchive: require('components/pages/MatchArchive/reducers')
                    .default,
                MatchInsights:
                    require('components/pages/MatchInsights/reducers').default,
                MediaRequest: require('components/pages/MediaRequest/reducers')
                    .default,
                MobileAppsPage:
                    require('components/pages/MobileAppsPage/reducers').default,
                MyWimbledon: require('components/pages/MyWimbledon/reducers')
                    .default,
                MyWimbledonLoginOIDC:
                    require('components/pages/MyWimbledonLoginOIDC/reducers')
                        .default,
                MyWimbledonLoginSAML:
                    require('components/pages/MyWimbledonLoginSAML/reducers')
                        .default,
                NewsIndex: require('components/pages/NewsIndex/reducers')
                    .default,
                PDEContentDash:
                    require('components/pages/PDEContentDash/reducers').default,
                PDEMatchStats:
                    require('components/pages/PDEMatchStats/reducers').default,
                PlayerChallengePage:
                    require('components/pages/PlayerChallengePage/reducers')
                        .default,
                PlayerDigitalExperience:
                    require('components/pages/PlayerDigitalExperience/reducers')
                        .default,
                PlayerPage: require('components/pages/PlayerPage/reducers')
                    .default,
                PlayerStatsPage:
                    require('components/pages/PlayerStatsPage/reducers')
                        .default,
                PlayersIndex: require('components/pages/PlayersIndex/reducers')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/reducers')
                    .default,
                RoleRegistrationInvite:
                    require('components/pages/RoleRegistrationInvite/reducers')
                        .default,
                SchedulePage: require('components/pages/SchedulePage/reducers')
                    .default,
                ScoreboardDemo:
                    require('components/pages/ScoreboardDemo/reducers').default,
                SearchPage: require('components/pages/SearchPage/reducers')
                    .default,
                SeatingViews: require('components/pages/SeatingViews/reducers')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/reducers')
                    .default,
                TestPage: require('components/pages/TestPage/reducers').default,
                Tickets: require('components/pages/Tickets/reducers').default,
                Upcoming: require('components/pages/Upcoming/reducers').default,
                VideoIndex: require('components/pages/VideoIndex/reducers')
                    .default,
                WatchReadPage:
                    require('components/pages/WatchReadPage/reducers').default,
                WeatherPage: require('components/pages/WeatherPage/reducers')
                    .default,
                WimbledonWish:
                    require('components/pages/WimbledonWish/reducers').default,
                WinFactorsFinder:
                    require('components/pages/WinFactorsFinder/reducers')
                        .default,
                MatchBox: require('components/scoring/MatchBox/reducers')
                    .default,
                AICatchUpFullWebview:
                    require('components/webviews/AICatchUpFullWebview/reducers')
                        .default,
                AICatchUpHomeWebview:
                    require('components/webviews/AICatchUpHomeWebview/reducers')
                        .default,
                AtoZWebview: require('components/webviews/AtoZWebview/reducers')
                    .default,
                ContentPageWebview:
                    require('components/webviews/ContentPageWebview/reducers')
                        .default,
                HillVoteWebview:
                    require('components/webviews/HillVoteWebview/reducers')
                        .default,
                PlayerPageWebview:
                    require('components/webviews/PlayerPageWebview/reducers')
                        .default,
                RadioWebview:
                    require('components/webviews/RadioWebview/reducers')
                        .default,
                ScheduleWebview:
                    require('components/webviews/ScheduleWebview/reducers')
                        .default,
                SlamtrackerWebview:
                    require('components/webviews/SlamtrackerWebview/reducers')
                        .default,
                TicketResaleWebview:
                    require('components/webviews/TicketResaleWebview/reducers')
                        .default,
                TicketsWebview:
                    require('components/webviews/TicketsWebview/reducers')
                        .default,
                UpcomingWebview:
                    require('components/webviews/UpcomingWebview/reducers')
                        .default,
                VirtualQueueWebview:
                    require('components/webviews/VirtualQueueWebview/reducers')
                        .default,
                VisitWebview:
                    require('components/webviews/VisitWebview/reducers')
                        .default,
                Plugable: require('dotix/components/Plugable/reducers').default,
                Router: require('dotix/components/Router/reducers').default,
            },
            allInitialStates: {
                EventStats: require('components/cms/EventStats/state').default,
                HeadToHead: require('components/cms/HeadToHead/state').default,
                MatchInsightsCard:
                    require('components/cms/MatchInsightsCard/state').default,
                MatchResult: require('components/cms/MatchResult/state')
                    .default,
                Player: require('components/cms/Player/state').default,
                HaveYourSay: require('components/common-ui/HaveYourSay/state')
                    .default,
                SmartSearch: require('components/common-ui/SmartSearch/state')
                    .default,
                NewsTile: require('components/content/NewsTile/state').default,
                ActiveData: require('components/data/ActiveData/state').default,
                CommonData: require('components/data/CommonData/state').default,
                Config: require('components/data/Config/state').default,
                Tournament: require('components/data/Tournament/state').default,
                WeatherData: require('components/data/WeatherData/state')
                    .default,
                DrawsArchiveContentPage:
                    require('components/drawsArchive/DrawsArchiveContentPage/state')
                        .default,
                DrawsArchiveCountryAbbreviations:
                    require('components/drawsArchive/DrawsArchiveCountryAbbreviations/state')
                        .default,
                DrawsArchiveEvents:
                    require('components/drawsArchive/DrawsArchiveEvents/state')
                        .default,
                DrawsArchiveLadiesNames:
                    require('components/drawsArchive/DrawsArchiveLadiesNames/state')
                        .default,
                DrawsArchiveMenu:
                    require('components/drawsArchive/DrawsArchiveMenu/state')
                        .default,
                DrawsArchiveSearch:
                    require('components/drawsArchive/DrawsArchiveSearch/state')
                        .default,
                DrawsArchiveTopStats:
                    require('components/drawsArchive/DrawsArchiveTopStats/state')
                        .default,
                DrawsArchiveYear:
                    require('components/drawsArchive/DrawsArchiveYear/state')
                        .default,
                PlayerProfile:
                    require('components/drawsArchive/PlayerProfile/state')
                        .default,
                ConfirmationModal:
                    require('components/general/ConfirmationModal/state')
                        .default,
                Controller: require('components/general/Controller/state')
                    .default,
                Gigya: require('components/general/Gigya/state').default,
                Header: require('components/general/Header/state').default,
                HillChannel: require('components/general/HillChannel/state')
                    .default,
                Login: require('components/general/Login/state').default,
                MainNav: require('components/general/MainNav/state').default,
                MainVideoChannel:
                    require('components/general/MainVideoChannel/state')
                        .default,
                PageHeader: require('components/general/PageHeader/state')
                    .default,
                PrivacyBanner: require('components/general/PrivacyBanner/state')
                    .default,
                PromoFeature: require('components/general/PromoFeature/state')
                    .default,
                ScoreManager: require('components/general/ScoreManager/state')
                    .default,
                ScrollHandler: require('components/general/ScrollHandler/state')
                    .default,
                Sidepanel: require('components/general/Sidepanel/state')
                    .default,
                SiteMap: require('components/general/SiteMap/state').default,
                SlamtrackerPanel:
                    require('components/general/SlamtrackerPanel/state')
                        .default,
                VideoPlayer: require('components/general/VideoPlayer/state')
                    .default,
                VideoWrapper: require('components/general/VideoWrapper/state')
                    .default,
                WeatherStatus: require('components/general/WeatherStatus/state')
                    .default,
                BreakpointPage: require('components/pages/BreakpointPage/state')
                    .default,
                CompetitorListPage:
                    require('components/pages/CompetitorListPage/state')
                        .default,
                ContactPage: require('components/pages/ContactPage/state')
                    .default,
                ContactThankYou:
                    require('components/pages/ContactThankYou/state').default,
                ContentPage: require('components/pages/ContentPage/state')
                    .default,
                ContentPageSecure:
                    require('components/pages/ContentPageSecure/state').default,
                CountryPlayersPage:
                    require('components/pages/CountryPlayersPage/state')
                        .default,
                CountryStatisticsPage:
                    require('components/pages/CountryStatisticsPage/state')
                        .default,
                DrawsPage: require('components/pages/DrawsPage/state').default,
                FramePages: require('components/pages/FramePages/state')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/state')
                    .default,
                HomePage: require('components/pages/HomePage/state').default,
                InsightsIndex: require('components/pages/InsightsIndex/state')
                    .default,
                JobsPage: require('components/pages/JobsPage/state').default,
                LiveRadio: require('components/pages/LiveRadio/state').default,
                LiveScores: require('components/pages/LiveScores/state')
                    .default,
                LiveVideo: require('components/pages/LiveVideo/state').default,
                LiveVideoHill: require('components/pages/LiveVideoHill/state')
                    .default,
                PointNav:
                    require('components/pages/MatchArchive/elements/PointNav/state')
                        .default,
                MatchArchive: require('components/pages/MatchArchive/state')
                    .default,
                MatchInsights: require('components/pages/MatchInsights/state')
                    .default,
                MediaRequest: require('components/pages/MediaRequest/state')
                    .default,
                MobileAppsPage: require('components/pages/MobileAppsPage/state')
                    .default,
                MyWimbledon: require('components/pages/MyWimbledon/state')
                    .default,
                NewsIndex: require('components/pages/NewsIndex/state').default,
                PlayerChallengePage:
                    require('components/pages/PlayerChallengePage/state')
                        .default,
                PlayerDigitalExperience:
                    require('components/pages/PlayerDigitalExperience/state')
                        .default,
                PlayerPage: require('components/pages/PlayerPage/state')
                    .default,
                PlayerStatsPage:
                    require('components/pages/PlayerStatsPage/state').default,
                PlayersIndex: require('components/pages/PlayersIndex/state')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/state')
                    .default,
                RoleRegistrationInvite:
                    require('components/pages/RoleRegistrationInvite/state')
                        .default,
                SchedulePage: require('components/pages/SchedulePage/state')
                    .default,
                SearchPage: require('components/pages/SearchPage/state')
                    .default,
                SeatingViews: require('components/pages/SeatingViews/state')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/state')
                    .default,
                TestPage: require('components/pages/TestPage/state').default,
                Tickets: require('components/pages/Tickets/state').default,
                Upcoming: require('components/pages/Upcoming/state').default,
                VideoIndex: require('components/pages/VideoIndex/state')
                    .default,
                WatchReadPage: require('components/pages/WatchReadPage/state')
                    .default,
                WeatherPage: require('components/pages/WeatherPage/state')
                    .default,
                WimbledonWish: require('components/pages/WimbledonWish/state')
                    .default,
                WinFactorsFinder:
                    require('components/pages/WinFactorsFinder/state').default,
                AtoZWebview: require('components/webviews/AtoZWebview/state')
                    .default,
                ContentPageWebview:
                    require('components/webviews/ContentPageWebview/state')
                        .default,
                HillVoteWebview:
                    require('components/webviews/HillVoteWebview/state')
                        .default,
                RadioWebview: require('components/webviews/RadioWebview/state')
                    .default,
                ScheduleWebview:
                    require('components/webviews/ScheduleWebview/state')
                        .default,
                SlamtrackerWebview:
                    require('components/webviews/SlamtrackerWebview/state')
                        .default,
                TicketsWebview:
                    require('components/webviews/TicketsWebview/state').default,
                UpcomingWebview:
                    require('components/webviews/UpcomingWebview/state')
                        .default,
                VisitWebview: require('components/webviews/VisitWebview/state')
                    .default,
                Plugable: require('dotix/components/Plugable/state').default,
            },
            allRoutes: {
                DrawsArchiveContentPage:
                    require('components/drawsArchive/DrawsArchiveContentPage/route')
                        .default,
                DrawsArchiveCountryAbbreviations:
                    require('components/drawsArchive/DrawsArchiveCountryAbbreviations/route')
                        .default,
                DrawsArchiveEvents:
                    require('components/drawsArchive/DrawsArchiveEvents/route')
                        .default,
                DrawsArchiveLadiesNames:
                    require('components/drawsArchive/DrawsArchiveLadiesNames/route')
                        .default,
                DrawsArchiveSearch:
                    require('components/drawsArchive/DrawsArchiveSearch/route')
                        .default,
                DrawsArchiveTopStats:
                    require('components/drawsArchive/DrawsArchiveTopStats/route')
                        .default,
                DrawsArchiveYear:
                    require('components/drawsArchive/DrawsArchiveYear/route')
                        .default,
                PlayerProfile:
                    require('components/drawsArchive/PlayerProfile/route')
                        .default,
                AICatchUp: require('components/pages/AICatchUp/route').default,
                BreakpointPage: require('components/pages/BreakpointPage/route')
                    .default,
                CompetitorListPage:
                    require('components/pages/CompetitorListPage/route')
                        .default,
                ContactPage: require('components/pages/ContactPage/route')
                    .default,
                ContactThankYou:
                    require('components/pages/ContactThankYou/route').default,
                ContentPage: require('components/pages/ContentPage/route')
                    .default,
                ContentPageSecure:
                    require('components/pages/ContentPageSecure/route').default,
                CountryPlayersPage:
                    require('components/pages/CountryPlayersPage/route')
                        .default,
                CountryStatisticsPage:
                    require('components/pages/CountryStatisticsPage/route')
                        .default,
                DrawsPage: require('components/pages/DrawsPage/route').default,
                FramePages: require('components/pages/FramePages/route')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/route')
                    .default,
                HomePage: require('components/pages/HomePage/route').default,
                InsightsIndex: require('components/pages/InsightsIndex/route')
                    .default,
                JobsPage: require('components/pages/JobsPage/route').default,
                LiveRadio: require('components/pages/LiveRadio/route').default,
                LiveScores: require('components/pages/LiveScores/route')
                    .default,
                LiveVideo: require('components/pages/LiveVideo/route').default,
                LiveVideoHill: require('components/pages/LiveVideoHill/route')
                    .default,
                MatchArchive: require('components/pages/MatchArchive/route')
                    .default,
                MatchInsights: require('components/pages/MatchInsights/route')
                    .default,
                MediaRequest: require('components/pages/MediaRequest/route')
                    .default,
                Member: require('components/pages/Member/route').default,
                MobileAppsPage: require('components/pages/MobileAppsPage/route')
                    .default,
                MyWimbledon: require('components/pages/MyWimbledon/route')
                    .default,
                MyWimbledonDelete:
                    require('components/pages/MyWimbledonDelete/route').default,
                MyWimbledonLoginOIDC:
                    require('components/pages/MyWimbledonLoginOIDC/route')
                        .default,
                MyWimbledonLoginSAML:
                    require('components/pages/MyWimbledonLoginSAML/route')
                        .default,
                MyWimbledonMigrate:
                    require('components/pages/MyWimbledonMigrate/route')
                        .default,
                MyWimbledonRedirect:
                    require('components/pages/MyWimbledonRedirect/route')
                        .default,
                MyWimbledonReset:
                    require('components/pages/MyWimbledonReset/route').default,
                MyWimbledonUnsubscribe:
                    require('components/pages/MyWimbledonUnsubscribe/route')
                        .default,
                NewsIndex: require('components/pages/NewsIndex/route').default,
                PDEContentDash: require('components/pages/PDEContentDash/route')
                    .default,
                PDEMatchStats: require('components/pages/PDEMatchStats/route')
                    .default,
                PlayerChallengePage:
                    require('components/pages/PlayerChallengePage/route')
                        .default,
                PlayerDigitalExperience:
                    require('components/pages/PlayerDigitalExperience/route')
                        .default,
                PlayerPage: require('components/pages/PlayerPage/route')
                    .default,
                PlayerStatsPage:
                    require('components/pages/PlayerStatsPage/route').default,
                PlayersIndex: require('components/pages/PlayersIndex/route')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/route')
                    .default,
                RoleRegistrationInvite:
                    require('components/pages/RoleRegistrationInvite/route')
                        .default,
                SchedulePage: require('components/pages/SchedulePage/route')
                    .default,
                SchedulePrintPage:
                    require('components/pages/SchedulePrintPage/route').default,
                ScoreboardDemo: require('components/pages/ScoreboardDemo/route')
                    .default,
                SearchPage: require('components/pages/SearchPage/route')
                    .default,
                SeatingViews: require('components/pages/SeatingViews/route')
                    .default,
                SiteMapPage: require('components/pages/SiteMapPage/route')
                    .default,
                Storyteller: require('components/pages/Storyteller/route')
                    .default,
                TestPage: require('components/pages/TestPage/route').default,
                Tickets: require('components/pages/Tickets/route').default,
                Upcoming: require('components/pages/Upcoming/route').default,
                VideoIndex: require('components/pages/VideoIndex/route')
                    .default,
                WatchReadPage: require('components/pages/WatchReadPage/route')
                    .default,
                WeatherPage: require('components/pages/WeatherPage/route')
                    .default,
                WimbledonWish: require('components/pages/WimbledonWish/route')
                    .default,
                WinFactorsFinder:
                    require('components/pages/WinFactorsFinder/route').default,
                AICatchUpFullWebview:
                    require('components/webviews/AICatchUpFullWebview/route')
                        .default,
                AICatchUpHomeWebview:
                    require('components/webviews/AICatchUpHomeWebview/route')
                        .default,
                AtoZWebview: require('components/webviews/AtoZWebview/route')
                    .default,
                ContactUsPage:
                    require('components/webviews/ContactUsPage/route').default,
                ContentPageWebview:
                    require('components/webviews/ContentPageWebview/route')
                        .default,
                DrawsWebview: require('components/webviews/DrawsWebview/route')
                    .default,
                HillVoteWebview:
                    require('components/webviews/HillVoteWebview/route')
                        .default,
                LoginWebview: require('components/webviews/LoginWebview/route')
                    .default,
                MatchInsightsIndexWebview:
                    require('components/webviews/MatchInsightsIndexWebview/route')
                        .default,
                MatchInsightsWebview:
                    require('components/webviews/MatchInsightsWebview/route')
                        .default,
                PlayerPageWebview:
                    require('components/webviews/PlayerPageWebview/route')
                        .default,
                RadioWebview: require('components/webviews/RadioWebview/route')
                    .default,
                ScheduleWebview:
                    require('components/webviews/ScheduleWebview/route')
                        .default,
                SlamtrackerWebview:
                    require('components/webviews/SlamtrackerWebview/route')
                        .default,
                TicketResaleWebview:
                    require('components/webviews/TicketResaleWebview/route')
                        .default,
                TicketsWebview:
                    require('components/webviews/TicketsWebview/route').default,
                UpcomingWebview:
                    require('components/webviews/UpcomingWebview/route')
                        .default,
                VirtualQueueWebview:
                    require('components/webviews/VirtualQueueWebview/route')
                        .default,
                VisitWebview: require('components/webviews/VisitWebview/route')
                    .default,
            },
            allServices: {
                EventStats: require('components/cms/EventStats/services')
                    .default,
                HeadToHead: require('components/cms/HeadToHead/services')
                    .default,
                MatchResult: require('components/cms/MatchResult/services')
                    .default,
                MatchStats: require('components/cms/MatchStats/services')
                    .default,
                MatchStatsFull:
                    require('components/cms/MatchStatsFull/services').default,
                OrderOfPlay: require('components/cms/OrderOfPlay/services')
                    .default,
                Player: require('components/cms/Player/services').default,
                PlayerStatusWidget:
                    require('components/cms/PlayerStatusWidget/services')
                        .default,
                RelatedContent:
                    require('components/cms/RelatedContent/services').default,
                ShopCarousel: require('components/cms/ShopCarousel/services')
                    .default,
                ActiveData: require('components/data/ActiveData/services')
                    .default,
                CommonData: require('components/data/CommonData/services')
                    .default,
                Config: require('components/data/Config/services').default,
                Tournament: require('components/data/Tournament/services')
                    .default,
                WeatherData: require('components/data/WeatherData/services')
                    .default,
                DrawsArchiveContentPage:
                    require('components/drawsArchive/DrawsArchiveContentPage/services')
                        .default,
                DrawsArchiveCountryAbbreviations:
                    require('components/drawsArchive/DrawsArchiveCountryAbbreviations/services')
                        .default,
                DrawsArchiveEvents:
                    require('components/drawsArchive/DrawsArchiveEvents/services')
                        .default,
                DrawsArchiveLadiesNames:
                    require('components/drawsArchive/DrawsArchiveLadiesNames/services')
                        .default,
                DrawsArchiveMenu:
                    require('components/drawsArchive/DrawsArchiveMenu/services')
                        .default,
                DrawsArchiveSearch:
                    require('components/drawsArchive/DrawsArchiveSearch/services')
                        .default,
                DrawsArchiveTopStats:
                    require('components/drawsArchive/DrawsArchiveTopStats/services')
                        .default,
                DrawsArchiveYear:
                    require('components/drawsArchive/DrawsArchiveYear/services')
                        .default,
                PlayerProfile:
                    require('components/drawsArchive/PlayerProfile/services')
                        .default,
                Gigya: require('components/general/Gigya/services').default,
                Header: require('components/general/Header/services').default,
                HillChannel: require('components/general/HillChannel/services')
                    .default,
                MainNav: require('components/general/MainNav/services').default,
                MainVideoChannel:
                    require('components/general/MainVideoChannel/services')
                        .default,
                PageHeader: require('components/general/PageHeader/services')
                    .default,
                Sidepanel: require('components/general/Sidepanel/services')
                    .default,
                SlamtrackerPanel:
                    require('components/general/SlamtrackerPanel/services')
                        .default,
                VideoPlayer: require('components/general/VideoPlayer/services')
                    .default,
                VideoWrapper:
                    require('components/general/VideoWrapper/services').default,
                BreakpointPage:
                    require('components/pages/BreakpointPage/services').default,
                CompetitorListPage:
                    require('components/pages/CompetitorListPage/services')
                        .default,
                ContentPage: require('components/pages/ContentPage/services')
                    .default,
                ContentPageSecure:
                    require('components/pages/ContentPageSecure/services')
                        .default,
                CountryPlayersPage:
                    require('components/pages/CountryPlayersPage/services')
                        .default,
                CountryStatisticsPage:
                    require('components/pages/CountryStatisticsPage/services')
                        .default,
                DrawsPage: require('components/pages/DrawsPage/services')
                    .default,
                FramePages: require('components/pages/FramePages/services')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/services')
                    .default,
                HomePage: require('components/pages/HomePage/services').default,
                JobsPage: require('components/pages/JobsPage/services').default,
                LiveRadio: require('components/pages/LiveRadio/services')
                    .default,
                LiveScores: require('components/pages/LiveScores/services')
                    .default,
                LiveVideo: require('components/pages/LiveVideo/services')
                    .default,
                LiveVideoHill:
                    require('components/pages/LiveVideoHill/services').default,
                MatchArchive: require('components/pages/MatchArchive/services')
                    .default,
                MatchInsights:
                    require('components/pages/MatchInsights/services').default,
                MyWimbledon: require('components/pages/MyWimbledon/services')
                    .default,
                NewsIndex: require('components/pages/NewsIndex/services')
                    .default,
                PlayerChallengePage:
                    require('components/pages/PlayerChallengePage/services')
                        .default,
                PlayerDigitalExperience:
                    require('components/pages/PlayerDigitalExperience/services')
                        .default,
                PlayerPage: require('components/pages/PlayerPage/services')
                    .default,
                PlayerStatsPage:
                    require('components/pages/PlayerStatsPage/services')
                        .default,
                PlayersIndex: require('components/pages/PlayersIndex/services')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/services')
                    .default,
                SchedulePage: require('components/pages/SchedulePage/services')
                    .default,
                ScoreboardDemo:
                    require('components/pages/ScoreboardDemo/services').default,
                SearchPage: require('components/pages/SearchPage/services')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/services')
                    .default,
                TestPage: require('components/pages/TestPage/services').default,
                Tickets: require('components/pages/Tickets/services').default,
                Upcoming: require('components/pages/Upcoming/services').default,
                VideoIndex: require('components/pages/VideoIndex/services')
                    .default,
                WatchReadPage:
                    require('components/pages/WatchReadPage/services').default,
                WeatherPage: require('components/pages/WeatherPage/services')
                    .default,
                MatchBox: require('components/scoring/MatchBox/services')
                    .default,
                AtoZWebview: require('components/webviews/AtoZWebview/services')
                    .default,
                ContentPageWebview:
                    require('components/webviews/ContentPageWebview/services')
                        .default,
                HillVoteWebview:
                    require('components/webviews/HillVoteWebview/services')
                        .default,
                RadioWebview:
                    require('components/webviews/RadioWebview/services')
                        .default,
                ScheduleWebview:
                    require('components/webviews/ScheduleWebview/services')
                        .default,
                SlamtrackerWebview:
                    require('components/webviews/SlamtrackerWebview/services')
                        .default,
                TicketsWebview:
                    require('components/webviews/TicketsWebview/services')
                        .default,
                UpcomingWebview:
                    require('components/webviews/UpcomingWebview/services')
                        .default,
                VisitWebview:
                    require('components/webviews/VisitWebview/services')
                        .default,
            },
            allMiddleware: {
                redux: require('dotix/redux/middleware').default,
            },
            allEnhancers: {
                redux: require('dotix/redux/enhancer').default,
            },
            allPlugins: {},
        };
    },
    contexts: {
        components:
            typeof window !== 'undefined' &&
            require.context('components', true, /.jsx?$/),
        dotix:
            typeof window !== 'undefined' &&
            require.context('dotix/components', true, /.jsx?$/),
    },
    listContexts: () => {
        return;
    },
    list: () => {
        return {
            allActions: {
                type: 'actions',
                imports: [
                    'components/cms/ContentList/actions',
                    'components/cms/Draw/actions',
                    'components/cms/EventStats/actions',
                    'components/cms/Gallery/actions',
                    'components/cms/HeadToHead/actions',
                    'components/cms/MatchInsightsCard/actions',
                    'components/cms/MatchResult/actions',
                    'components/cms/MatchStats/actions',
                    'components/cms/MatchStatsFull/actions',
                    'components/cms/OrderOfPlay/actions',
                    'components/cms/Player/actions',
                    'components/cms/ShopCarousel/actions',
                    'components/common-ui/HaveYourSay/actions',
                    'components/common-ui/PathToTheFinal/actions',
                    'components/common-ui/SmartSearch/actions',
                    'components/data/ActiveData/actions',
                    'components/data/CommonData/actions',
                    'components/data/Config/actions',
                    'components/data/Tournament/actions',
                    'components/data/WeatherData/actions',
                    'components/drawsArchive/DrawsArchiveContentPage/actions',
                    'components/drawsArchive/DrawsArchiveCountryAbbreviations/actions',
                    'components/drawsArchive/DrawsArchiveEvents/actions',
                    'components/drawsArchive/DrawsArchiveLadiesNames/actions',
                    'components/drawsArchive/DrawsArchiveMenu/actions',
                    'components/drawsArchive/DrawsArchiveSearch/actions',
                    'components/drawsArchive/DrawsArchiveTopStats/actions',
                    'components/drawsArchive/DrawsArchiveYear/actions',
                    'components/drawsArchive/PlayerProfile/actions',
                    'components/general/ConfirmationModal/actions',
                    'components/general/Controller/actions',
                    'components/general/Footer/actions',
                    'components/general/Gigya/actions',
                    'components/general/Header/actions',
                    'components/general/HillChannel/actions',
                    'components/general/HomePromos/actions',
                    'components/general/LiveNav/actions',
                    'components/general/Login/actions',
                    'components/general/MainNav/actions',
                    'components/general/MainVideoChannel/actions',
                    'components/general/PageHeader/actions',
                    'components/general/PlayerGeneralInfo/actions',
                    'components/general/PrivacyBanner/actions',
                    'components/general/PromoFeature/actions',
                    'components/general/ScoreManager/actions',
                    'components/general/ScrollHandler/actions',
                    'components/general/Sidepanel/actions',
                    'components/general/SiteMap/actions',
                    'components/general/SlamtrackerPanel/actions',
                    'components/general/VideoPlayer/actions',
                    'components/general/VideoWrapper/actions',
                    'components/general/WeatherStatus/actions',
                    'components/pages/AICatchUp/actions',
                    'components/pages/BreakpointPage/actions',
                    'components/pages/CompetitorListPage/actions',
                    'components/pages/ContactPage/actions',
                    'components/pages/ContactThankYou/actions',
                    'components/pages/ContentPage/actions',
                    'components/pages/ContentPageSecure/actions',
                    'components/pages/CountryPlayersPage/actions',
                    'components/pages/CountryStatisticsPage/actions',
                    'components/pages/DrawsPage/actions',
                    'components/pages/FramePages/actions',
                    'components/pages/GalleryPage/actions',
                    'components/pages/HomePage/actions',
                    'components/pages/InsightsIndex/actions',
                    'components/pages/JobsPage/actions',
                    'components/pages/LiveRadio/actions',
                    'components/pages/LiveScores/actions',
                    'components/pages/LiveVideo/actions',
                    'components/pages/LiveVideoHill/actions',
                    'components/pages/MatchArchive/actions',
                    'components/pages/MatchInsights/actions',
                    'components/pages/MediaRequest/actions',
                    'components/pages/Member/actions',
                    'components/pages/MobileAppsPage/actions',
                    'components/pages/MyWimbledon/actions',
                    'components/pages/MyWimbledonDelete/actions',
                    'components/pages/MyWimbledonLoginOIDC/actions',
                    'components/pages/MyWimbledonLoginSAML/actions',
                    'components/pages/MyWimbledonRedirect/actions',
                    'components/pages/NewsIndex/actions',
                    'components/pages/PDEContentDash/actions',
                    'components/pages/PDEMatchStats/actions',
                    'components/pages/PlayerChallengePage/actions',
                    'components/pages/PlayerDigitalExperience/actions',
                    'components/pages/PlayerPage/actions',
                    'components/pages/PlayerStatsPage/actions',
                    'components/pages/PlayersIndex/actions',
                    'components/pages/ResultsPage/actions',
                    'components/pages/RoleRegistrationInvite/actions',
                    'components/pages/SchedulePage/actions',
                    'components/pages/ScoreboardDemo/actions',
                    'components/pages/SearchPage/actions',
                    'components/pages/SeatingViews/actions',
                    'components/pages/Slamtracker/actions',
                    'components/pages/TestPage/actions',
                    'components/pages/Tickets/actions',
                    'components/pages/Upcoming/actions',
                    'components/pages/VideoIndex/actions',
                    'components/pages/WatchReadPage/actions',
                    'components/pages/WeatherPage/actions',
                    'components/pages/WimbledonWish/actions',
                    'components/pages/WinFactorsFinder/actions',
                    'components/scoring/MatchBox/actions',
                    'components/webviews/AICatchUpFullWebview/actions',
                    'components/webviews/AICatchUpHomeWebview/actions',
                    'components/webviews/AtoZWebview/actions',
                    'components/webviews/ContactUsPage/actions',
                    'components/webviews/ContentPageWebview/actions',
                    'components/webviews/HillVoteWebview/actions',
                    'components/webviews/LoginWebview/actions',
                    'components/webviews/PlayerPageWebview/actions',
                    'components/webviews/RadioWebview/actions',
                    'components/webviews/ScheduleWebview/actions',
                    'components/webviews/SlamtrackerWebview/actions',
                    'components/webviews/TicketResaleWebview/actions',
                    'components/webviews/TicketsWebview/actions',
                    'components/webviews/UpcomingWebview/actions',
                    'components/webviews/VirtualQueueWebview/actions',
                    'components/webviews/VisitWebview/actions',
                    'dotix/components/Plugable/actions',
                    'dotix/components/Router/actions',
                ],
            },
            allActionTypes: {
                type: 'actionTypes',
                imports: [
                    'components/cms/ContentList/actionTypes',
                    'components/cms/Draw/actionTypes',
                    'components/cms/EventStats/actionTypes',
                    'components/cms/Gallery/actionTypes',
                    'components/cms/HeadToHead/actionTypes',
                    'components/cms/MatchInsightsCard/actionTypes',
                    'components/cms/MatchResult/actionTypes',
                    'components/cms/MatchStats/actionTypes',
                    'components/cms/MatchStatsFull/actionTypes',
                    'components/cms/OrderOfPlay/actionTypes',
                    'components/cms/Player/actionTypes',
                    'components/cms/ShopCarousel/actionTypes',
                    'components/common-ui/HaveYourSay/actionTypes',
                    'components/common-ui/PathToTheFinal/actionTypes',
                    'components/common-ui/SmartSearch/actionTypes',
                    'components/data/ActiveData/actionTypes',
                    'components/data/CommonData/actionTypes',
                    'components/data/Config/actionTypes',
                    'components/data/Tournament/actionTypes',
                    'components/data/WeatherData/actionTypes',
                    'components/drawsArchive/DrawsArchiveContentPage/actionTypes',
                    'components/drawsArchive/DrawsArchiveCountryAbbreviations/actionTypes',
                    'components/drawsArchive/DrawsArchiveEvents/actionTypes',
                    'components/drawsArchive/DrawsArchiveLadiesNames/actionTypes',
                    'components/drawsArchive/DrawsArchiveMenu/actionTypes',
                    'components/drawsArchive/DrawsArchiveSearch/actionTypes',
                    'components/drawsArchive/DrawsArchiveTopStats/actionTypes',
                    'components/drawsArchive/DrawsArchiveYear/actionTypes',
                    'components/drawsArchive/PlayerProfile/actionTypes',
                    'components/general/ConfirmationModal/actionTypes',
                    'components/general/Controller/actionTypes',
                    'components/general/Footer/actionTypes',
                    'components/general/Gigya/actionTypes',
                    'components/general/Header/actionTypes',
                    'components/general/HillChannel/actionTypes',
                    'components/general/LiveNav/actionTypes',
                    'components/general/Login/actionTypes',
                    'components/general/MainNav/actionTypes',
                    'components/general/MainVideoChannel/actionTypes',
                    'components/general/PageHeader/actionTypes',
                    'components/general/PlayerGeneralInfo/actionTypes',
                    'components/general/PrivacyBanner/actionTypes',
                    'components/general/PromoFeature/actionTypes',
                    'components/general/ScoreManager/actionTypes',
                    'components/general/ScrollHandler/actionTypes',
                    'components/general/Sidepanel/actionTypes',
                    'components/general/SiteMap/actionTypes',
                    'components/general/SlamtrackerPanel/actionTypes',
                    'components/general/VideoPlayer/actionTypes',
                    'components/general/VideoWrapper/actionTypes',
                    'components/general/WeatherStatus/actionTypes',
                    'components/pages/AICatchUp/actionTypes',
                    'components/pages/BreakpointPage/actionTypes',
                    'components/pages/CompetitorListPage/actionTypes',
                    'components/pages/ContactPage/actionTypes',
                    'components/pages/ContactThankYou/actionTypes',
                    'components/pages/ContentPage/actionTypes',
                    'components/pages/ContentPageSecure/actionTypes',
                    'components/pages/CountryPlayersPage/actionTypes',
                    'components/pages/CountryStatisticsPage/actionTypes',
                    'components/pages/DrawsPage/actionTypes',
                    'components/pages/FramePages/actionTypes',
                    'components/pages/GalleryPage/actionTypes',
                    'components/pages/HomePage/actionTypes',
                    'components/pages/InsightsIndex/actionTypes',
                    'components/pages/JobsPage/actionTypes',
                    'components/pages/LiveRadio/actionTypes',
                    'components/pages/LiveScores/actionTypes',
                    'components/pages/LiveVideo/actionTypes',
                    'components/pages/LiveVideoHill/actionTypes',
                    'components/pages/MatchArchive/actionTypes',
                    'components/pages/MatchInsights/actionTypes',
                    'components/pages/MediaRequest/actionTypes',
                    'components/pages/Member/actionTypes',
                    'components/pages/MobileAppsPage/actionTypes',
                    'components/pages/MyWimbledon/actionTypes',
                    'components/pages/MyWimbledonDelete/actionTypes',
                    'components/pages/MyWimbledonLoginOIDC/actionTypes',
                    'components/pages/MyWimbledonLoginSAML/actionTypes',
                    'components/pages/MyWimbledonRedirect/actionTypes',
                    'components/pages/NewsIndex/actionTypes',
                    'components/pages/PDEContentDash/actionTypes',
                    'components/pages/PDEMatchStats/actionTypes',
                    'components/pages/PlayerChallengePage/actionTypes',
                    'components/pages/PlayerDigitalExperience/actionTypes',
                    'components/pages/PlayerPage/actionTypes',
                    'components/pages/PlayerStatsPage/actionTypes',
                    'components/pages/PlayersIndex/actionTypes',
                    'components/pages/ResultsPage/actionTypes',
                    'components/pages/RoleRegistrationInvite/actionTypes',
                    'components/pages/SchedulePage/actionTypes',
                    'components/pages/ScoreboardDemo/actionTypes',
                    'components/pages/SearchPage/actionTypes',
                    'components/pages/SeatingViews/actionTypes',
                    'components/pages/Slamtracker/actionTypes',
                    'components/pages/TestPage/actionTypes',
                    'components/pages/Tickets/actionTypes',
                    'components/pages/Upcoming/actionTypes',
                    'components/pages/VideoIndex/actionTypes',
                    'components/pages/WatchReadPage/actionTypes',
                    'components/pages/WeatherPage/actionTypes',
                    'components/pages/WimbledonWish/actionTypes',
                    'components/pages/WinFactorsFinder/actionTypes',
                    'components/scoring/MatchBox/actionTypes',
                    'components/webviews/AICatchUpFullWebview/actionTypes',
                    'components/webviews/AICatchUpHomeWebview/actionTypes',
                    'components/webviews/AtoZWebview/actionTypes',
                    'components/webviews/ContactUsPage/actionTypes',
                    'components/webviews/ContentPageWebview/actionTypes',
                    'components/webviews/HillVoteWebview/actionTypes',
                    'components/webviews/LoginWebview/actionTypes',
                    'components/webviews/PlayerPageWebview/actionTypes',
                    'components/webviews/RadioWebview/actionTypes',
                    'components/webviews/ScheduleWebview/actionTypes',
                    'components/webviews/SlamtrackerWebview/actionTypes',
                    'components/webviews/TicketResaleWebview/actionTypes',
                    'components/webviews/TicketsWebview/actionTypes',
                    'components/webviews/UpcomingWebview/actionTypes',
                    'components/webviews/VirtualQueueWebview/actionTypes',
                    'components/webviews/VisitWebview/actionTypes',
                    'dotix/components/Plugable/actionTypes',
                    'dotix/components/Router/actionTypes',
                ],
            },
            allReducers: {
                type: 'reducers',
                imports: [
                    'components/cms/Draw/reducers',
                    'components/cms/EventStats/reducers',
                    'components/cms/Gallery/reducers',
                    'components/cms/HeadToHead/reducers',
                    'components/cms/MatchInsightsCard/reducers',
                    'components/cms/MatchResult/reducers',
                    'components/cms/MatchStats/reducers',
                    'components/cms/MatchStatsFull/reducers',
                    'components/cms/OrderOfPlay/reducers',
                    'components/cms/Player/reducers',
                    'components/cms/ShopCarousel/reducers',
                    'components/common-ui/HaveYourSay/reducers',
                    'components/common-ui/HomePageFilter/reducers',
                    'components/common-ui/PathToTheFinal/reducers',
                    'components/common-ui/SmartSearch/reducers',
                    'components/data/ActiveData/reducers',
                    'components/data/CommonData/reducers',
                    'components/data/Config/reducers',
                    'components/data/Tournament/reducers',
                    'components/data/WeatherData/reducers',
                    'components/drawsArchive/DrawsArchiveContentPage/reducers',
                    'components/drawsArchive/DrawsArchiveCountryAbbreviations/reducers',
                    'components/drawsArchive/DrawsArchiveEvents/reducers',
                    'components/drawsArchive/DrawsArchiveLadiesNames/reducers',
                    'components/drawsArchive/DrawsArchiveMenu/reducers',
                    'components/drawsArchive/DrawsArchiveSearch/reducers',
                    'components/drawsArchive/DrawsArchiveTopStats/reducers',
                    'components/drawsArchive/DrawsArchiveYear/reducers',
                    'components/drawsArchive/PlayerProfile/reducers',
                    'components/general/ConfirmationModal/reducers',
                    'components/general/Controller/reducers',
                    'components/general/Footer/reducers',
                    'components/general/Gigya/reducers',
                    'components/general/Header/reducers',
                    'components/general/HillChannel/reducers',
                    'components/general/LiveNav/reducers',
                    'components/general/Login/reducers',
                    'components/general/MainNav/reducers',
                    'components/general/MainVideoChannel/reducers',
                    'components/general/PageHeader/reducers',
                    'components/general/PlayerGeneralInfo/reducers',
                    'components/general/PrivacyBanner/reducers',
                    'components/general/PromoFeature/reducers',
                    'components/general/ScoreManager/reducers',
                    'components/general/ScrollHandler/reducers',
                    'components/general/Sidepanel/reducers',
                    'components/general/SiteMap/reducers',
                    'components/general/SlamtrackerPanel/reducers',
                    'components/general/VideoPlayer/reducers',
                    'components/general/VideoWrapper/reducers',
                    'components/general/WeatherStatus/reducers',
                    'components/pages/AICatchUp/reducers',
                    'components/pages/BreakpointPage/reducers',
                    'components/pages/CompetitorListPage/reducers',
                    'components/pages/ContactPage/reducers',
                    'components/pages/ContactThankYou/reducers',
                    'components/pages/ContentPage/reducers',
                    'components/pages/ContentPageSecure/reducers',
                    'components/pages/CountryPlayersPage/reducers',
                    'components/pages/CountryStatisticsPage/reducers',
                    'components/pages/DrawsPage/reducers',
                    'components/pages/FramePages/reducers',
                    'components/pages/GalleryPage/reducers',
                    'components/pages/HomePage/reducers',
                    'components/pages/InsightsIndex/reducers',
                    'components/pages/JobsPage/reducers',
                    'components/pages/LiveRadio/reducers',
                    'components/pages/LiveScores/reducers',
                    'components/pages/LiveVideo/reducers',
                    'components/pages/LiveVideoHill/reducers',
                    'components/pages/MatchArchive/reducers',
                    'components/pages/MatchInsights/reducers',
                    'components/pages/MediaRequest/reducers',
                    'components/pages/MobileAppsPage/reducers',
                    'components/pages/MyWimbledon/reducers',
                    'components/pages/MyWimbledonLoginOIDC/reducers',
                    'components/pages/MyWimbledonLoginSAML/reducers',
                    'components/pages/NewsIndex/reducers',
                    'components/pages/PDEContentDash/reducers',
                    'components/pages/PDEMatchStats/reducers',
                    'components/pages/PlayerChallengePage/reducers',
                    'components/pages/PlayerDigitalExperience/reducers',
                    'components/pages/PlayerPage/reducers',
                    'components/pages/PlayerStatsPage/reducers',
                    'components/pages/PlayersIndex/reducers',
                    'components/pages/ResultsPage/reducers',
                    'components/pages/RoleRegistrationInvite/reducers',
                    'components/pages/SchedulePage/reducers',
                    'components/pages/ScoreboardDemo/reducers',
                    'components/pages/SearchPage/reducers',
                    'components/pages/SeatingViews/reducers',
                    'components/pages/Slamtracker/reducers',
                    'components/pages/TestPage/reducers',
                    'components/pages/Tickets/reducers',
                    'components/pages/Upcoming/reducers',
                    'components/pages/VideoIndex/reducers',
                    'components/pages/WatchReadPage/reducers',
                    'components/pages/WeatherPage/reducers',
                    'components/pages/WimbledonWish/reducers',
                    'components/pages/WinFactorsFinder/reducers',
                    'components/scoring/MatchBox/reducers',
                    'components/webviews/AICatchUpFullWebview/reducers',
                    'components/webviews/AICatchUpHomeWebview/reducers',
                    'components/webviews/AtoZWebview/reducers',
                    'components/webviews/ContentPageWebview/reducers',
                    'components/webviews/HillVoteWebview/reducers',
                    'components/webviews/PlayerPageWebview/reducers',
                    'components/webviews/RadioWebview/reducers',
                    'components/webviews/ScheduleWebview/reducers',
                    'components/webviews/SlamtrackerWebview/reducers',
                    'components/webviews/TicketResaleWebview/reducers',
                    'components/webviews/TicketsWebview/reducers',
                    'components/webviews/UpcomingWebview/reducers',
                    'components/webviews/VirtualQueueWebview/reducers',
                    'components/webviews/VisitWebview/reducers',
                    'dotix/components/Plugable/reducers',
                    'dotix/components/Router/reducers',
                ],
            },
            allInitialStates: {
                type: 'state',
                imports: [
                    'components/cms/EventStats/state',
                    'components/cms/HeadToHead/state',
                    'components/cms/MatchInsightsCard/state',
                    'components/cms/MatchResult/state',
                    'components/cms/Player/state',
                    'components/common-ui/HaveYourSay/state',
                    'components/common-ui/SmartSearch/state',
                    'components/content/NewsTile/state',
                    'components/data/ActiveData/state',
                    'components/data/CommonData/state',
                    'components/data/Config/state',
                    'components/data/Tournament/state',
                    'components/data/WeatherData/state',
                    'components/drawsArchive/DrawsArchiveContentPage/state',
                    'components/drawsArchive/DrawsArchiveCountryAbbreviations/state',
                    'components/drawsArchive/DrawsArchiveEvents/state',
                    'components/drawsArchive/DrawsArchiveLadiesNames/state',
                    'components/drawsArchive/DrawsArchiveMenu/state',
                    'components/drawsArchive/DrawsArchiveSearch/state',
                    'components/drawsArchive/DrawsArchiveTopStats/state',
                    'components/drawsArchive/DrawsArchiveYear/state',
                    'components/drawsArchive/PlayerProfile/state',
                    'components/general/ConfirmationModal/state',
                    'components/general/Controller/state',
                    'components/general/Gigya/state',
                    'components/general/Header/state',
                    'components/general/HillChannel/state',
                    'components/general/Login/state',
                    'components/general/MainNav/state',
                    'components/general/MainVideoChannel/state',
                    'components/general/PageHeader/state',
                    'components/general/PrivacyBanner/state',
                    'components/general/PromoFeature/state',
                    'components/general/ScoreManager/state',
                    'components/general/ScrollHandler/state',
                    'components/general/Sidepanel/state',
                    'components/general/SiteMap/state',
                    'components/general/SlamtrackerPanel/state',
                    'components/general/VideoPlayer/state',
                    'components/general/VideoWrapper/state',
                    'components/general/WeatherStatus/state',
                    'components/pages/BreakpointPage/state',
                    'components/pages/CompetitorListPage/state',
                    'components/pages/ContactPage/state',
                    'components/pages/ContactThankYou/state',
                    'components/pages/ContentPage/state',
                    'components/pages/ContentPageSecure/state',
                    'components/pages/CountryPlayersPage/state',
                    'components/pages/CountryStatisticsPage/state',
                    'components/pages/DrawsPage/state',
                    'components/pages/FramePages/state',
                    'components/pages/GalleryPage/state',
                    'components/pages/HomePage/state',
                    'components/pages/InsightsIndex/state',
                    'components/pages/JobsPage/state',
                    'components/pages/LiveRadio/state',
                    'components/pages/LiveScores/state',
                    'components/pages/LiveVideo/state',
                    'components/pages/LiveVideoHill/state',
                    'components/pages/MatchArchive/elements/PointNav/state',
                    'components/pages/MatchArchive/state',
                    'components/pages/MatchInsights/state',
                    'components/pages/MediaRequest/state',
                    'components/pages/MobileAppsPage/state',
                    'components/pages/MyWimbledon/state',
                    'components/pages/NewsIndex/state',
                    'components/pages/PlayerChallengePage/state',
                    'components/pages/PlayerDigitalExperience/state',
                    'components/pages/PlayerPage/state',
                    'components/pages/PlayerStatsPage/state',
                    'components/pages/PlayersIndex/state',
                    'components/pages/ResultsPage/state',
                    'components/pages/RoleRegistrationInvite/state',
                    'components/pages/SchedulePage/state',
                    'components/pages/SearchPage/state',
                    'components/pages/SeatingViews/state',
                    'components/pages/Slamtracker/state',
                    'components/pages/TestPage/state',
                    'components/pages/Tickets/state',
                    'components/pages/Upcoming/state',
                    'components/pages/VideoIndex/state',
                    'components/pages/WatchReadPage/state',
                    'components/pages/WeatherPage/state',
                    'components/pages/WimbledonWish/state',
                    'components/pages/WinFactorsFinder/state',
                    'components/webviews/AtoZWebview/state',
                    'components/webviews/ContentPageWebview/state',
                    'components/webviews/HillVoteWebview/state',
                    'components/webviews/RadioWebview/state',
                    'components/webviews/ScheduleWebview/state',
                    'components/webviews/SlamtrackerWebview/state',
                    'components/webviews/TicketsWebview/state',
                    'components/webviews/UpcomingWebview/state',
                    'components/webviews/VisitWebview/state',
                    'dotix/components/Plugable/state',
                ],
            },
            allRoutes: {
                type: 'route',
                imports: [
                    'components/drawsArchive/DrawsArchiveContentPage/route',
                    'components/drawsArchive/DrawsArchiveCountryAbbreviations/route',
                    'components/drawsArchive/DrawsArchiveEvents/route',
                    'components/drawsArchive/DrawsArchiveLadiesNames/route',
                    'components/drawsArchive/DrawsArchiveSearch/route',
                    'components/drawsArchive/DrawsArchiveTopStats/route',
                    'components/drawsArchive/DrawsArchiveYear/route',
                    'components/drawsArchive/PlayerProfile/route',
                    'components/pages/AICatchUp/route',
                    'components/pages/BreakpointPage/route',
                    'components/pages/CompetitorListPage/route',
                    'components/pages/ContactPage/route',
                    'components/pages/ContactThankYou/route',
                    'components/pages/ContentPage/route',
                    'components/pages/ContentPageSecure/route',
                    'components/pages/CountryPlayersPage/route',
                    'components/pages/CountryStatisticsPage/route',
                    'components/pages/DrawsPage/route',
                    'components/pages/FramePages/route',
                    'components/pages/GalleryPage/route',
                    'components/pages/HomePage/route',
                    'components/pages/InsightsIndex/route',
                    'components/pages/JobsPage/route',
                    'components/pages/LiveRadio/route',
                    'components/pages/LiveScores/route',
                    'components/pages/LiveVideo/route',
                    'components/pages/LiveVideoHill/route',
                    'components/pages/MatchArchive/route',
                    'components/pages/MatchInsights/route',
                    'components/pages/MediaRequest/route',
                    'components/pages/Member/route',
                    'components/pages/MobileAppsPage/route',
                    'components/pages/MyWimbledon/route',
                    'components/pages/MyWimbledonDelete/route',
                    'components/pages/MyWimbledonLoginOIDC/route',
                    'components/pages/MyWimbledonLoginSAML/route',
                    'components/pages/MyWimbledonMigrate/route',
                    'components/pages/MyWimbledonRedirect/route',
                    'components/pages/MyWimbledonReset/route',
                    'components/pages/MyWimbledonUnsubscribe/route',
                    'components/pages/NewsIndex/route',
                    'components/pages/PDEContentDash/route',
                    'components/pages/PDEMatchStats/route',
                    'components/pages/PlayerChallengePage/route',
                    'components/pages/PlayerDigitalExperience/route',
                    'components/pages/PlayerPage/route',
                    'components/pages/PlayerStatsPage/route',
                    'components/pages/PlayersIndex/route',
                    'components/pages/ResultsPage/route',
                    'components/pages/RoleRegistrationInvite/route',
                    'components/pages/SchedulePage/route',
                    'components/pages/SchedulePrintPage/route',
                    'components/pages/ScoreboardDemo/route',
                    'components/pages/SearchPage/route',
                    'components/pages/SeatingViews/route',
                    'components/pages/SiteMapPage/route',
                    'components/pages/Storyteller/route',
                    'components/pages/TestPage/route',
                    'components/pages/Tickets/route',
                    'components/pages/Upcoming/route',
                    'components/pages/VideoIndex/route',
                    'components/pages/WatchReadPage/route',
                    'components/pages/WeatherPage/route',
                    'components/pages/WimbledonWish/route',
                    'components/pages/WinFactorsFinder/route',
                    'components/webviews/AICatchUpFullWebview/route',
                    'components/webviews/AICatchUpHomeWebview/route',
                    'components/webviews/AtoZWebview/route',
                    'components/webviews/ContactUsPage/route',
                    'components/webviews/ContentPageWebview/route',
                    'components/webviews/DrawsWebview/route',
                    'components/webviews/HillVoteWebview/route',
                    'components/webviews/LoginWebview/route',
                    'components/webviews/MatchInsightsIndexWebview/route',
                    'components/webviews/MatchInsightsWebview/route',
                    'components/webviews/PlayerPageWebview/route',
                    'components/webviews/RadioWebview/route',
                    'components/webviews/ScheduleWebview/route',
                    'components/webviews/SlamtrackerWebview/route',
                    'components/webviews/TicketResaleWebview/route',
                    'components/webviews/TicketsWebview/route',
                    'components/webviews/UpcomingWebview/route',
                    'components/webviews/VirtualQueueWebview/route',
                    'components/webviews/VisitWebview/route',
                ],
            },
            allServices: {
                type: 'services',
                imports: [
                    'components/cms/EventStats/services',
                    'components/cms/HeadToHead/services',
                    'components/cms/MatchResult/services',
                    'components/cms/MatchStats/services',
                    'components/cms/MatchStatsFull/services',
                    'components/cms/OrderOfPlay/services',
                    'components/cms/Player/services',
                    'components/cms/PlayerStatusWidget/services',
                    'components/cms/RelatedContent/services',
                    'components/cms/ShopCarousel/services',
                    'components/data/ActiveData/services',
                    'components/data/CommonData/services',
                    'components/data/Config/services',
                    'components/data/Tournament/services',
                    'components/data/WeatherData/services',
                    'components/drawsArchive/DrawsArchiveContentPage/services',
                    'components/drawsArchive/DrawsArchiveCountryAbbreviations/services',
                    'components/drawsArchive/DrawsArchiveEvents/services',
                    'components/drawsArchive/DrawsArchiveLadiesNames/services',
                    'components/drawsArchive/DrawsArchiveMenu/services',
                    'components/drawsArchive/DrawsArchiveSearch/services',
                    'components/drawsArchive/DrawsArchiveTopStats/services',
                    'components/drawsArchive/DrawsArchiveYear/services',
                    'components/drawsArchive/PlayerProfile/services',
                    'components/general/Gigya/services',
                    'components/general/Header/services',
                    'components/general/HillChannel/services',
                    'components/general/MainNav/services',
                    'components/general/MainVideoChannel/services',
                    'components/general/PageHeader/services',
                    'components/general/Sidepanel/services',
                    'components/general/SlamtrackerPanel/services',
                    'components/general/VideoPlayer/services',
                    'components/general/VideoWrapper/services',
                    'components/pages/BreakpointPage/services',
                    'components/pages/CompetitorListPage/services',
                    'components/pages/ContentPage/services',
                    'components/pages/ContentPageSecure/services',
                    'components/pages/CountryPlayersPage/services',
                    'components/pages/CountryStatisticsPage/services',
                    'components/pages/DrawsPage/services',
                    'components/pages/FramePages/services',
                    'components/pages/GalleryPage/services',
                    'components/pages/HomePage/services',
                    'components/pages/JobsPage/services',
                    'components/pages/LiveRadio/services',
                    'components/pages/LiveScores/services',
                    'components/pages/LiveVideo/services',
                    'components/pages/LiveVideoHill/services',
                    'components/pages/MatchArchive/services',
                    'components/pages/MatchInsights/services',
                    'components/pages/MyWimbledon/services',
                    'components/pages/NewsIndex/services',
                    'components/pages/PlayerChallengePage/services',
                    'components/pages/PlayerDigitalExperience/services',
                    'components/pages/PlayerPage/services',
                    'components/pages/PlayerStatsPage/services',
                    'components/pages/PlayersIndex/services',
                    'components/pages/ResultsPage/services',
                    'components/pages/SchedulePage/services',
                    'components/pages/ScoreboardDemo/services',
                    'components/pages/SearchPage/services',
                    'components/pages/Slamtracker/services',
                    'components/pages/TestPage/services',
                    'components/pages/Tickets/services',
                    'components/pages/Upcoming/services',
                    'components/pages/VideoIndex/services',
                    'components/pages/WatchReadPage/services',
                    'components/pages/WeatherPage/services',
                    'components/scoring/MatchBox/services',
                    'components/webviews/AtoZWebview/services',
                    'components/webviews/ContentPageWebview/services',
                    'components/webviews/HillVoteWebview/services',
                    'components/webviews/RadioWebview/services',
                    'components/webviews/ScheduleWebview/services',
                    'components/webviews/SlamtrackerWebview/services',
                    'components/webviews/TicketsWebview/services',
                    'components/webviews/UpcomingWebview/services',
                    'components/webviews/VisitWebview/services',
                ],
            },
            allMiddleware: {
                type: 'middleware',
                imports: ['dotix/redux/middleware'],
            },
            allEnhancers: {
                type: 'enhancer',
                imports: ['dotix/redux/enhancer'],
            },
            allPlugins: {
                type: 'plugin',
                imports: [],
            },
        };
    },
};

import deps from 'dependencies';
import op from 'object-path';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[VirtualQueueWebview] action.mount - config: %o', Config);
			let data = {
				queue: {
					...params,
				},
			};

			logger.log('[VirtualQueueWebview] action.mount - data:%o', data);
			dispatch({
				type: deps.actionTypes.VIRTUALQUEUEWEBVIEW_MOUNT,
				data: data,
			});
		});
	},
};

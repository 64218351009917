import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.VIDEOINDEX_LOADING });
		logger.log('[VideoIndex] action.mount - params: %o', data);

		let params = data;

		//logger.log('[VideoIndex] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				contentId: Config.relatedContent.contentId,
				shareBase: Config.videoPlayer.shareBase,
				path: Config.cmsData.video,
				relatedVideoPath: Config.relatedContent.video,
				videoId: params.videoId ? params.videoId : '',
				category: params.category ? params.category : '',
			};

			dispatch({ type: deps.actionTypes.VIDEOINDEX_LOAD, data: data });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.VIDEOINDEX_FILTER, data: data });
	},
};

import React, { Component } from 'react';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import InputBox from './elements/InputBox';
import op from 'object-path';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.forgot_classname = 'gigya-screen v2 portrait';

		//logger.log('[ForgotPassword] render - props:%o  ', props);
	}

	render() {
		//logger.log('[ForgotPassword] render - context:%o  ', op.get(this, 'context', ''));

		return (
			<div
				id="gigya-forgot-password-screen"
				data-width="auto"
				className={this.forgot_classname}
				data-caption="screenset.translations['GIGYA_FORGOT_PASSWORD_SCREEN_CAPTION']">
				<form
					className="gigya-reset-password-form"
					data-on-success-screen="gigya-forgot-password-success-screen"
					id="gigya-reset-password-form">
					<label
						className="gigya-composite-control gigya-composite-control-label main-text letter-spacing-s"
						data-translation-key="LABEL_FORGOT_PASSWORD"
					/>
					<div className="gigya-layout-row">
						<div
							className="gigya-composite-control gigya-composite-control-textbox email-wrapper"
							style={{ display: 'block' }}>
							<InputBox
								data={{
									type: 'text',
									name: 'loginID',
									key: 'TEXTBOX_EMAIL',
									showValid: 'true',
									class: 'gigya-input-text',
									value: op.get(this, 'context.email', ''),
								}}
							/>
						</div>
					</div>
					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '15px' }} />
					<div className="gigya-layout-row">
						<div className="gigya-composite-control gigya-composite-control-submit submit-wrapper">
							<input
								type="submit"
								className="gigya-input-submit"
								tabIndex="0"
								value="screenset.translations['BUTTON_SUBMIT_VALUE']"
							/>
						</div>
						<div
							className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
							data-bound-to="gigya-reset-password-form">
							<div
								className="gigya-error-msg gigya-form-error-msg"
								data-bound-to="gigya-reset-password-form"
							/>
						</div>
						<div className="gigya-clear" />
					</div>
					<div className="gigya-composite-control gigya-spacer" data-units="1" style={{ height: '15px' }} />
					<div className="gigya-layout-row">
						<a
							className="gigya-composite-control gigya-composite-control-link"
							data-switch-screen="gigya-login-screen"
							data-translation-key="LINK_LOGIN_LABEL"
						/>
						<div className="gigya-clear" />
					</div>
				</form>
			</div>
		);
	}
}

ForgotPassword.contextType = AuthContext;
export default ForgotPassword;

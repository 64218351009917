import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import Fullscreenable from 'react-fullscreenable';
// import moment from 'moment';
import op from 'object-path';
import { logger } from './logger';
import Amp from './Amp';
import { AmpConfig } from './ampConfig';
import Controls from './controls';
import Captions from './captions';

import styles from './sass/styles.scss'


require('create-react-class');

// const ampVersion = process.env.AMP_VERSION;

class ReactEcp extends Component {
	
	constructor(props) {
		super(props);

		this.controls = React.createRef();
		this.ampBasePath = './libs/';
		this.ampUrl = ``;
		this.amp = null;
		this.start = false;
		this.useFlash = false;
		this.lastFullscreenAction;
		this.lastTimeSent = 0;
		this.timeUpdateInterval = .25;
		this.totalTime = 0;
		this.seeking = false;

		//force import amp and set base path for loading dependent files
		//  these files must exist in proper path in project using react-amp
		//let js = require(`./libs/amp/${ampVersion}/amp.premier/amp.premier.js`);
		
		if (props.ampBasePath) {
			this.ampBasePath = props.ampBasePath;
		}
		//this.ampUrl = `${this.ampBasePath}amp/amp.premier/amp.premier.js`;
		this.ampUrl = `${this.ampBasePath}amp/akamai/amp/core/amp.min.js`;
		
		//logger.log('loading amp version %o from base path %o', ampVersion, this.ampBasePath);

		this.state = {
			...props,
			settings: this.getLocalStorage(),
			initializing: false
		};

		this.clearAmpCaptions();

		this.ampConfig = AmpConfig(this.props.playerConfig, this.props.videoConfig, this.ampBasePath);
		this.reload = false;
		this.setMedia = false;

		this.previewTracks;
		this.playerRef = React.createRef();

		this.onPlay = this.onPlay.bind(this);
		this.onPaused = this.onPaused.bind(this);
		this.onPlaying = this.onPlaying.bind(this);
		this.onSeeking = this.onSeeking.bind(this);
		this.onSeeked = this.onSeeked.bind(this);
		this.onEnded = this.onEnded.bind(this);
		this.onVolumeChange = this.onVolumeChange.bind(this);
		this.onTimeUpdate = this.onTimeUpdate.bind(this);
		this.onLoadedMetadata = this.onLoadedMetadata.bind(this);
		//this.fullscreenchangeHandler = this.fullscreenchangeHandler.bind(this);
		this.onAdClicked = this.onAdClicked.bind(this);
		this.play = this.play.bind(this);
		
		logger.log('version: %o', '<@VERSION@>');
		//logger.log('constructor - state:%o', this.state);
	}
	
	componentDidMount() {
		//logger.log('componentDidMount');

		let isLoaded = Script.loadedScripts[this.ampUrl];

		this.setState({
			status: 'mount',
			scriptLoaded: isLoaded
		});
	}

	componentWillUnmount() {
		logger.log('componentWillUnmount - amp:%o', this.amp);

		this.amp.destroy();
	}
	
	componentWillReceiveProps(nextProps) {
		//logger.log('componentWillReceiveProps - nextProps:%o', nextProps);
		if (this.props.isFullscreen !== nextProps.isFullscreen) {
			// Fullscreen status has changed.
			this.setState({
				isFullscreen: nextProps.isFullscreen
			})
        }
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		logger.log('Main: componentDidUpdate - prev:%o props:%o reset:%o', prevProps.videoConfig.id, this.props.videoConfig.id, this.props.reset);
		logger.log('componentDidUpdate - currId:%o stateId:%o', this.currentId, this.state.videoConfig.id);

		let reset = this.props.reset == undefined ? true : this.props.reset;
		let self = this;
		let id;
		this.reload = false;
		this.setMedia = false;
		this.start = false;

		if (this.amp == null || (reset && prevProps.videoConfig.id != this.props.videoConfig.id)) {
			id = this.props.videoConfig.id;
			this.reload = true;
			this.lastTimeSent = 0;
			this.totalTime = 0;
			this.ampConfig = AmpConfig(this.props.playerConfig, this.props.videoConfig, this.ampBasePath);
			logger.log('Main: componentDidUpdate - reload:%o', this.props.videoConfig.id);
			this.cleanAmpConfig();

			if (this.amp){
				this.amp.destroy();
				this.amp = null;
			}
			
		}
		else if (prevProps.videoConfig.id != this.props.videoConfig.id) {
			id = this.state.videoConfig.id;
			this.setMedia = true;
			this.lastTimeSent = 0;
			this.totalTime = 0;
			this.ampConfig = AmpConfig(this.props.playerConfig, this.props.videoConfig, this.ampBasePath);
			logger.log('Main: componentDidUpdate - setMedia:%o', this.props.videoConfig.id);
			this.cleanAmpConfig();

			// set autoplay based on config
			this.amp.autoplay = this.ampConfig.autoplay;
			this.amp.loop = this.ampConfig.loop;
		}

		

		
		logger.log('Main: componentDidUpdate - reload:%o setMedia:%o', this.reload, this.setMedia);
		
		if (this.reload) {
			logger.log('Main: componentDidUpdate - amp:%o script:%o', this.amp, this.state.scriptLoaded);
			if (!this.amp && !this.state.initializing && this.state.scriptLoaded) {
				logger.log('Main: componentDidUpdate - load amp');
				this.currentId = id;
				// akamai.amp.AutoplayThreshold.init()
				// 	.then(function (threshold) {
				// 		let htmlElem = document.getElementById('amp');
				// 		logger.log("componentDidUpdate - The browser's autoplay threshold is: ", threshold);
				// 		logger.log('componentDidUpdate - reload - Hls:%0 htmlElem:% ampConfig:%o', window.Hls, htmlElem, self.ampConfig);
				// 		self.amp = akamai.amp.AMP.create("amp", self.ampConfig, function(event) {
				// 			logger.log("componentDidUpdate - Player Ready");
				// 			self.readyHandler();
				// 		});

				// 		self.reload = false;
				// 	});
	
				let htmlElem = document.getElementById(this.props.id);
				//logger.log("componentDidUpdate - The browser's autoplay threshold is: ", threshold);
				if (htmlElem) {
					logger.log('componentDidUpdate - reload - Hls:%o ampConfig:%o', window.Hls, self.ampConfig);
					// self.amp = akamai.amp.AMP.create(this.props.id, self.ampConfig, function(event) {
					// 	logger.log("Main: componentDidUpdate - player:%o", akamai.amp.AMP.players.html);
					// 	logger.log("Main: componentDidUpdate - adplayer:%o", document.getElementsByClassName('akamai-html5')[0].amp.ads.player);
					// 	logger.log("Main: componentDidUpdate - Player Ready");
					// 	self.readyHandler();
					// });

					this.setState({
						initializing: true
					})

					akamai.amp.AMP.create(this.props.id, self.ampConfig)
						.then(function (player) {
							//console.log(player);
							self.amp = player;
							logger.log("Main: componentDidUpdate - player:%o", player);
							//logger.log("Main: componentDidUpdate - adplayer:%o", document.getElementsByClassName('akamai-html5')[0].amp.ads.player);
							logger.log("Main: componentDidUpdate - Player Ready");
							self.readyHandler();
						})
						.catch(function (err){
							logger.error("Main: error initializing player:%o", err);
						});

					self.reload = false;
				}
				else {
					logger.error('Main: componentDidUpdate - reload - no html elem found for id: %o', this.props.id);
				}
			}
		}

		//  to load new video into AMP, use load API function
		else if (this.setMedia) {
			if (this.amp){
				logger.log('Main: componentDidUpdate - setMedia - ampConfig:%o', this.ampConfig);
				this.currentId = id;
				this.amp.setMedia(this.ampConfig.media);

				this.setState({
					ampConfig: this.ampConfig,
					shareUrl: op.get(this.props, 'videoConfig.shareUrl', ''),
					shareTitle: op.get(this.props, 'videoConfig.title', '')
				})
			}
		}
	}

	/**
	 * remove empty track array if using flash
	 *  - causes error in flash player
	 */
	cleanAmpConfig() {
		//logger.log('cleanAmpConfig - ampConfig:%o', this.ampConfig);
		if (this.useFlash) {
			let track = op.get(this.ampConfig, 'media.track', [])
			if (track.length == 0) {
				delete this.ampConfig.media.track;
			}
		}
		//logger.log('cleanAmpConfig - ampConfig:%o', this.ampConfig);
	}
	
	handleScriptCreate() {
		this.setState({
			scriptLoaded: false
		})
	}
	
	handleScriptError() {
		this.setState({
			scriptError: true
		})
	}
	
	handleScriptLoad() {
		//let version = Utils.getIEVersion();
		//logger.log('handleScriptLoad: ie:%o html:%o', akamai.amp.Utils.getIEVersion(), akamai.amp.Utils.isHTMLFirst());
		//this.useFlash = !akamai.amp.Utils.isHTMLFirst();
		this.useFlash = false;

		this.setState({
			scriptLoaded: true
		})
	}

	userAction(type, data){
		logger.info('userAction - type:%o data:%o', type, data);

		if(type == 'playPause'){			
			this.playerRef.current.focus();
			logger.info('userAction - this.playerRef:%o ele:%o', this.playerRef, document.act);
		}

		if (type == 'fullscreen') {
			this.props.toggleFullscreen();

			this.lastFullscreenAction = data.action;
		}

		if (type == 'captions') {
			if (data.action == 'captions_show') {
				this.updateLocalStorage('captions', true);
			}
			else {
				this.updateLocalStorage('captions', false);
			}
			this.setState({
				settings: this.getLocalStorage('')
			})
			
		}

		if (this.props.onUserAction) {
			this.props.onUserAction(data);
		}
	}

	/**
	 * if a track exists which has 608 data
	 * and the player config is to use custom 608 display
	 * and the browser uses hls.js (therefore can usecustom608 because has data events)
	 * then return true
	 * @param {Object} config 
	 */
	checkCustom608(config) {
		if (this.state.scriptLoaded && akamai) {
			let safariVersion = akamai.amp.Utils.getSafariVersion();
			logger.info('checkCustom608 - safariVersion:%o', safariVersion);

			// if know using hls.js, and not Safari
			let browserSupport = (safariVersion == -1);
			if (browserSupport && this.amp && this.amp.hls && this.amp.hls.instance) {
				logger.info('checkCustom608 - browserSupport:%o', browserSupport);
			}

			let useCustom608 = op.get(config, 'media.track', false);
			logger.info('checkCustom608 - configs:%o tracksArray:%o', config, useCustom608);
			if (Array.isArray(useCustom608)) {
				useCustom608 = op.get(useCustom608[0], 'type', false) == "text/cea-608";
			}
			let val = useCustom608 && this.props.playerConfig.useCustom608 && browserSupport;
			logger.info('checkCustom608 - useCustom608 val:%o', val);
			return val;
		}
		else {
			return false;
		}
	}

	updateLocalStorage(item, value) {
		let obj = JSON.parse(localStorage.getItem('ecp')) || {};
		
		if (!value && typeof value !== "boolean") {
			op.del(obj, item);
			localStorage.setItem('ecp', JSON.stringify(obj));
		}
		else {
			op.set(obj, item, value);
			localStorage.setItem('ecp', JSON.stringify(obj));
		}
	}

	getLocalStorage(item) {
		let obj = JSON.parse(localStorage.getItem('ecp'));
		return op.get(obj, item, null);
	}

	clearAmpCaptions() {
		let obj = JSON.parse(localStorage.getItem('akamai_amp'));
		// if (!obj) {
		// 	obj = {};
		// }
		// let visible = op.get(obj, 'captions.visible', false);
		// logger.info('clearAmpCaptions - visible:%o', visible);

		op.empty(obj, 'captions');
		//op.set(obj, 'captions.visible', visible)
		localStorage.setItem('akamai_amp', JSON.stringify(obj));
	}

	//*******************
	//**** AMP Scripts
	//*******************
	readyHandler() {
		logger.info('readyHandler - AMP version: %o', this.amp.getVersion());

		this.setState({
			ampConfig: this.ampConfig,
			videoConfig: this.props.videoConfig,
			shareUrl: op.get(this.props, 'videoConfig.shareUrl', ''),
			shareTitle: op.get(this.props, 'videoConfig.title', '')
		});

		
		// override the initial ad tag with the current ad tag
		//   used if not resetting amp, since amp will not update subsequent ad tags through config
		let self = this;
		this.amp.addTransform(akamai.amp.TransformType.AD_REQUEST, function (ad) {
			let adTag = op.get(self.ampConfig, 'adTagUrl', null);
			logger.info('readyHandler - AD_REQUEST - url:%o', adTag);
			return new Promise(function (resolve, reject) {
				ad.request.adTagUrl = adTag;
				resolve(ad)
			});
		});

		this.amp.addEventListener('ready', this.onReady);
		this.amp.addEventListener('loadedmetadata', this.onLoadedMetadata);
		this.amp.addEventListener("autoplayblocked", this.onAutoplayBlocked);

		this.amp.addEventListener('play', this.onPlay);
		this.amp.addEventListener('playing', this.onPlaying);
		this.amp.addEventListener('error', this.onError);
		this.amp.addEventListener('pause', this.onPaused);
		this.amp.addEventListener('ended', this.onEnded);
		this.amp.addEventListener('waiting', this.onWaiting);
		this.amp.addEventListener('seeking', this.onSeeking);
		this.amp.addEventListener('seeked', this.onSeeked);

		this.amp.addEventListener('durationchange', this.onDurationChange);
		this.amp.addEventListener('timeupdate', this.onTimeUpdate);

		this.amp.addEventListener('volumechange', this.onVolumeChange);
		//this.amp.addEventListener("fullscreenchange", this.fullscreenchangeHandler);

		this.amp.addEventListener('canplay', this.handleVideoEvent);
		this.amp.addEventListener('canplaythrough', this.handleVideoEvent);
		this.amp.addEventListener('loadeddata', this.handleVideoEvent);

		this.amp.addEventListener('qualitychange', this.onQualityChange);

		if (this.amp.hls && this.amp.hls.instance){
			
			this.amp.hls.addEventListener('hlsLevelSwitching', this.hlsEvent);


			
		}

		logger.info('readyHandler - ads: %o', this.amp.ads);

		if (this.amp.ads != null){
			this.amp.ads.addEventListener('playing', this.onAdPlaying);
			this.amp.ads.addEventListener('ended', this.onAdEnded);
			this.amp.ads.addEventListener('adclicked', this.onAdClicked);
		}

		if (this.props.onReady) {
			//logger.info('readyHandler - amp:%o', this.amp);
			//pass 'this' for ReactEcp API, or 'this.amp' for AMP api
			this.props.onReady(this);
		}

		// set autoplay based on config
		this.amp.autoplay = this.ampConfig.autoplay;

		this.amp.loop = this.ampConfig.loop;

		this.setState({
			ready: true
		})


		logger.info('readyHandler - media:%o', this.amp.mediaElement.textTracks);

		// this.amp.mediaElement.textTracks.addEventListener("change", (event) => {
		// 	console.log(`Track event fired - %o`, event);
		// });

		// const tracks = this.amp.mediaElement.textTracks;

		// for (const track of tracks) {
		// 	logger.info('readyHandler - media track:%o', track);
		// }

	}

	getElem = () => {
		return this.playerRef;
	}

	onReady() {
		logger.info('onReady -');
	}

	hlsEvent(e) {
		logger.info('hlsEvent - %o', e);
	}

	onQualityChange(e) {
		logger.info('onQualityChange - level:%o bitrate:%o res:%o data:%o', e.data.level, e.data.bitrate, e.data.attrs.RESOLUTION, e.data);
	}

	onPlay() {
		logger.info('onPlay');
		if (this.start){
			if (this.props.onPlay) {
				this.props.onPlay();
			}
		}

		if (this.amp.hls && this.amp.hls.instance){
			//logger.info('readyHandler - hls:%o', this.amp.hls.instance);
			//logger.info('readyHandler - safari:%o edge:%o', akamai.amp.Utils.getSafariVersion(), akamai.amp.Utils.getEdgeVersion());

			//logger.info('readyHandler - HLS isSupported:%o', window.Hls.isSupported());
			//logger.info('readyHandler - HLS version:%o', this.amp.hls.version);
			logger.info('readyHandler - HLS config:%o', this.amp.hls.config);
			//logger.info('readyHandler - HLS autoLevelEnabled:%o', this.amp.hls.autoLevelEnabled);
			//logger.info('readyHandler - HLS firstLevel:%o', this.amp.hls.firstLevel);
			//logger.info('readyHandler - HLS startLevel:%o', this.amp.hls.startLevel);
		}
	}

	onPlaying() {
		//logger.info('onPlaying');
		if (!this.start) {
			this.start = true;
			if (this.props.onStart) {
				this.props.onStart();
			}
		}
		else {
			if (this.props.onStart) {
				this.props.onPlay();
			}
		}
	};

	onPaused(data) {
		logger.info('onPaused - ');
		if (this.props.onPause) {
			this.props.onPause();
		}
	};

	onAutoplayBlocked(event) {
		let mode = event.detail.policy;
		let threshold = event.detail.threshold;
		logger.info("onAutoplayBlocked - autoplay '" + mode + "', but the browser's threshold is '" + threshold + "'");
		//logger.info('onAutoPlayblocked - event: %o ', event);

		//autoplayPrevented();
	};

	onDurationChange(evt) {
		if (evt.target.duration - lastDuration <= 0.5) {
			// some browsers reports several duration change events with almost the same value ... avoid spamming video events
			return;
		}
		let lastDuration = evt.target.duration;
		let data = Math.round(evt.target.duration * 1000);

		logger.info('onDurationChange - data:%o', data);
	}

	onTimeUpdate(data) {
		//logger.log('onTimeUpdate - data:%o time:%o dur:%o', data, this.amp.getCurrentTime(), this.amp.getDuration());

		let time = this.amp.getCurrentTime();
		let duration = this.amp.getDuration();
		let timeDiff = time - this.lastTimeSent;
		
		if (this.props.onTimeUpdate) {
			//logger.log('onTimeUpdate - time:%o last:%o', time, this.lastTimeSent);

			// if past timeinterval playing forward or seeking back
			if ((time - this.lastTimeSent) >= this.timeUpdateInterval ||
				(this.lastTimeSent - time) >= this.timeUpdateInterval
			){

				//calculate amt of video watched
				if (timeDiff > 0 && timeDiff < this.timeUpdateInterval + 2) {
					this.totalTime += (time - this.lastTimeSent);
					this.totalTime = Math.round(this.totalTime * 1000)/1000;
				}
				//logger.log('onTimeUpdate - totalTime:%o', this.totalTime, timeDiff);

				this.lastTimeSent = time;
				this.props.onTimeUpdate({time: this.lastTimeSent, duration: duration, played: this.totalTime});
			}
		}
	}

	onLoadedMetadata(data) {
		//logger.info('onLoadedMetadata - %o', data);
		//logger.info('onLoadedMetadata - levels:%o', this.amp.getQualityLevels());

		this.checkTextTracks();
		//return list of audio tracks
		if (this.props.onLoadedMetadata) {
			let data = {audioTracks: this.amp.tracks.data.audioTracks.list, textTracks: this.amp.getMediaElement().textTracks};
			this.props.onLoadedMetadata(data);
		}
	}

	onWaiting = function(data) {
		//logger.info('onWaiting - data:%o', data);
		//var playerEvent = new eventsPlayer.commons.BufferingEvent();

		//sendMessage(playerEvent, localorigin);
		//playerEvent = null;
	};

	onEnded = function(data) {
		//var playerEvent = new eventsPlayer.commons.CompleteEvent();
		logger.info('onEnded - data:%o', data);
		//sendMessage(playerEvent, localorigin);
		//playerEvent = null;

		//allow play event if restart after end
		let firstPlay = false;

		if (this.props.onComplete) {
			this.props.onComplete();
		}
	};

	onSeeking(data) {
		let previousCurrentTime = 0;
		//logger.info('onSeeking - data.%o cur:%o', data, this.amp.getCurrentTime());
	};

	onSeeked(data) {
		//logger.info('onSeeked - data.%o cur:%o', data, this.amp.getCurrentTime());
	};

	onVolumeChange(data) {
		let muted = this.amp.getMuted();
		let curVolume = this.amp.getVolume();
		//logger.info('onVolumeChange - muted:%o vol:%o', muted, curVolume);
	};

	/**
	 * listen for AMP fullscreen events
	 * not using because want custom control in fullscreen
	 * using Fullscreenable instead
	 */
	// fullscreenchangeHandler(event) {
	// 	let isFullScreen = event.detail;
	// 	let displayState = this.amp.getDisplayState();
				
	// 	logger.info('fullscreenchangeHandler - dispState:%o isFullScreen:%o', displayState, isFullScreen);
	// 	//logger.info('fullscreenchangeHandler - styles:%o', styles);

	// 	this.setState({
	// 		isFullScreen: isFullscreen
	// 	})
	// }

	onAdPlaying() {
		//logger.info('onAdPlaying');
	};

	onAdEnded() {
		//logger.info('onAdEnd');
	}

	onAdClicked() {
		logger.info('onAdClicked - ads:%o paused:%o', this.amp.ads, this.amp.ads.getPaused());
		if (this.amp.ads.getPaused()) {
			this.pause();
		}
	}

	onError(data) {
		let errorType = data.type;
		let errorDetails = data.details;
		let errorFatal = data.fatal;

		logger.info('onError - data:%o type:%o detail:%o', data, errorType, errorDetails);
	};

	/**
	 * generic amp video event handler
	 */
	handleVideoEvent(event) {
		//logger.info('handleVideoEvent - type:%o event:%o', event.type, event);
	};

	/**
	 * disable all inline text tracks
	 */
	checkTextTracks() {
		let tracks = this.amp.getMediaElement().textTracks;
		logger.info('checkTextTracks - there are %s tracks', tracks.length);
		
		if (tracks.length > 0) {
			for (let i = 0; i < tracks.length; i++) {
				logger.info('checkTextTracks - track:%o', tracks[i]);
				// Find the English captions track and mark it as "showing".
				if (tracks[i].kind === 'captions' || tracks[i].kind === 'subtitles') {
					tracks[i].mode = 'disabled';
				}
			}
		}
	}

	//*******************
	//  API functions
	//*******************

	play() {
		//logger.info('API - play - paused:%o start:%o', this.amp.getPaused(), this.start);
		if (this.amp.getPaused() || !this.start || 
			(this.amp.ads && this.amp.ads.enabled && this.amp.ads.getPaused())
		){
			this.amp.play();
		}
	}

	pause() {
		if (!this.amp.getPaused() || 
			(this.amp.ads && this.amp.ads.enabled && !this.amp.ads.getPaused())
		){
			this.amp.pause();
		}
	}

	mute() {
		if (!this.amp.getMuted()){
			this.amp.setMuted(true);
		}
	}

	unmute() {
		if (this.amp.getMuted()){
			this.amp.setMuted(false);
		}
	}

	captionsOn() {
		this.controls.current.captions(true);
	}

	captionsOff() {
		this.controls.current.captions(false);
	}
	
	openPanel() {
		this.controls.current.setPanel(true);
	}

	closePanel() {
		this.controls.current.setPanel(false);
	}

	openAudioOverlay(){
		this.controls.current.audioOverlayVisible(true);
	}

	closeAudioOverlay(){
		this.controls.current.audioOverlayVisible(false);
	}

	enableToolbar() {
		this.controls.current.allowToolbar(true);
	}

	disableToolbar() {
		this.controls.current.allowToolbar(false);
	}

	seek(time) {
		this.controls.current.seek(time);
	}

	switchAudio(trackId){		
		this.controls.current.switchAudio(trackId);
	}

	switchCaption(trackId){
		this.controls.current.switchCaption(trackId);
	}
	
	render() {		
		const stateConfig = Object.assign({},this.state.ampConfig);
		let useCustom608 = this.checkCustom608(stateConfig);
		let autoplay = op.get(this.state.ampConfig, 'autoplay', false);
		let autoplayPoster = op.get(this.props.videoConfig, 'autoplayPoster', false);
		let streams = op.get(this.props.videoConfig, 'streams', false);
		let toolTipText = op.get(this.props.videoConfig, 'toolTipText', false);

		logger.log('Main: render - state:%o useCustom608:%o autoplayPoster:%o', this.state, useCustom608, autoplayPoster);
		logger.log('Main: render - this.amp:%o', this.amp);
		logger.log('Main: render - styles:%o', styles);
		logger.log('Main: render - toolTipText:%o', toolTipText);
		if (this.state.status == 'mount') {
			return (
				<div className={`react-ecp ${styles.amp_wrapper}`} ref={this.playerRef} tabIndex={-1}>
					<Amp id={this.props.id} />
					{
						(this.state.ready && this.amp) ?
							<Controls
								mediaId={this.props.videoConfig.id}
								ref={this.controls}
								config={this.props.playerConfig} 
								autoplay={autoplay}
								autoplayPoster={autoplayPoster}
								poster={this.props.videoConfig.poster}
								shareUrl={this.state.shareUrl}
								streams={streams}
								shareTitle={this.state.shareTitle}
								amp={this.amp}
								isFullscreen={this.state.isFullscreen}
								onUserAction={(type, data) => {this.userAction(type, data)}}
								colors={this.props.colors}
								useCustom608={useCustom608}
								settings={this.state.settings}
								mode={this.ampConfig.customControls.mode}
								toolTipText={toolTipText}
								parentElem={this.getElem}
								preventKeyboard={this.props.preventKeyboard || false}
							/>
						: null
					}

					{
						(this.state.ready && useCustom608) ?
							<Captions
								mediaId={this.props.videoConfig.id}
								active={op.get(this.state, 'settings.captions', false)}
								amp={this.amp}
							/>
						: null
					}
					<Script
						url={this.ampUrl}
						onCreate={this.handleScriptCreate.bind(this)}
						onError={this.handleScriptError.bind(this)}
						onLoad={this.handleScriptLoad.bind(this)}
					/>
				</div>
			)
		} else {
			return null;
		}
	}
	
}

export default Fullscreenable()(ReactEcp);

ReactEcp.propTypes = {
    isFullscreen: PropTypes.bool,
	toggleFullscreen: PropTypes.func,
	viewportDimension: PropTypes.object
};
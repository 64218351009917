/**
 * -----------------------------------------------------------------------------
 * Imports
 * ----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import CommonData from './CommonData';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: ControlBar
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.CommonData,
	...props,
});

const mapDispatchToProps = dispatch => ({
	// update: () => dispatch(deps.actions.Config.update()),
	// updateStatic: item => dispatch(deps.actions.Config.updateStatic(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonData);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import op from 'object-path';
import VideoPlayer from 'appdir/components/general/VideoPlayer';

/**
 * -----------------------------------------------------------------------------
 * React Component: VidModal
 * -----------------------------------------------------------------------------
 */

class VidModal extends Component {
	constructor(props) {
		super(props);

		this.getVideo = this.getVideo.bind(this);
	}

	getVideo() {
		if (this.state.videoId) {
			let attributes = {
				playerId: 'main',
				contentId: null,
				cmsId: this.state.cmsId,
				videoUrl: this.state.videoUrl,
				title: this.state.title,
				thumb: this.state.thumb,
				date: this.state.date,
				shareUrl: this.state.shareUrl,
				adUrl: this.state.adUrl,
				nextUp: this.state.nextUp,
				autoplay: true,
				fullscreenMode: '',
				style: `video-wrapper modal ${this.state['col-style']}`,
				streamType: 'vod',
				aspect: this.state.hasOwnProperty('aspect') ? this.state.aspect : '',
			};
			//logger.log('[NewsTile] getVideo - attr:%o', attributes);
			return <VideoPlayer key="news" attributes={attributes} onEvent={this.onVideoEvent} />;
		} else {
			return null;
		}
	}

	render() {
		return <Fragment></Fragment>;
	}
}

export default VidModal;

export default {
	GIGYA_MOUNT: 'GIGYA_MOUNT',
	GIGYA_LOGIN: 'GIGYA_LOGIN',
	GIGYA_LOGOUT: 'GIGYA_LOGOUT',
	GIGYA_UPDATE: 'GIGYA_UPDATE',
	GIGYA_UPDATE_ROLE: 'GIGYA_UPDATE_ROLE',
	GIGYA_UPDATE_JWT: 'GIGYA_UPDATE_JWT',
	GIGYA_LOADED: 'GIGYA_LOADED',
	GIGYA_SCREEN_CALLBACK: 'GIGYA_SCREEN_CALLBACK',
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

class EventSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			eventsDropdownVals: props.events.map(item => ({ name: item.long, value: item.code })),
			selected: props.selected,
			open: false,
		};

		this.setEvent = this.setEvent.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.onClose = this.onClose.bind(this);

		//logger.log('[ResultsPage-EventSelect] constructor props:%o state:%o', props, this.state);
	}

	setEvent(event, name) {
		//logger.log('[ResultsPage-EventSelect] setEvent:%o', event);

		this.props.onEventSelect(event);
		this.setState(prevState => {
			return {
				...prevState,
				selected: event,
				open: false,
			};
		});
	}

	onOpen(which) {
		logger.log('[ResultsPage-EventSelect] onOpen - which:%o', which);
		this.setState(prevState => {
			return {
				...prevState,
				open: which == 'events',
			};
		});
	}

	onClose(which) {
		logger.log('[ResultsPage-EventSelect] onClose');
		this.setState(prevState => {
			return {
				...prevState,
				open: false,
			};
		});
	}

	render() {
		logger.log('[ResultsPage-EventSelect] render - state:%o', this.state);

		let eventsData = {
			options: [{ name: 'All Events', value: 'all' }],
		};

		// add available events to base archive menu options
		this.state.eventsDropdownVals.forEach(item => {
			eventsData.options.push({ name: item.name, value: item.value });
		});

		return (
			<div className="scores-select-menu">
				<SelectMenu
					name="events"
					attributes={eventsData}
					selected={this.state.selected}
					show={this.state.open}
					onSelected={this.setEvent}
					onOpen={this.onOpen}
					onClose={this.onClose}
				/>
			</div>
		);
	}
}

export default EventSelect;

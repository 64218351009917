/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import op from 'object-path';

import MainNav from 'appdir/components/general/MainNav';
import { Link, NavLink } from 'react-router-dom';
import EventsHelmet from 'appdir/components/general/EventsHelmet';
import classNames from 'classnames';
import { isNullOrUndefined } from 'util';
import StatusPanel from 'appdir/components/general/StatusPanel';
import Login from 'appdir/components/general/Login';
import WimLink from 'appdir/components/general/WimLink';
import JSXParser from 'react-jsx-parser';
import SkipContent from 'appdir/components/general/SkipContent';
import { getRole } from 'appdir/components/general/Util/Role';
import SmartbannerHelmet from 'appdir/components/general/SmartbannerHelmet/index.js';
import RoleNav from './components/RoleNav';
import throttle from 'lodash/throttle';

/**
 * -----------------------------------------------------------------------------
 * React Component: Header
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Header'],
		...props,
		currentUser: state['Gigya'].currentUser,
		loggedIn: state['Gigya'].loggedIn,
		gigyaLoaded: state['Gigya'].gigyaLoaded,
		windowSize: state['PageHeader'].windowSize,
		pageHeaders: state['Config'].pageHeaders,
		shelfIcons: state['Config'].myWIcons,
		mode: state['Config']['mode'],
		gigya: state['Gigya'],
		period: op.get(state['Config'], 'ballot.period', 'pre'),
		version: op.get(state['Config'], 'myWimbledon.version', 0),
		smartbanner: state['Config']['smartbanner'],
		config: state['Config'],

		//activeData: state['ActiveData']['homepage'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Header.mount()),
	refreshHomePage: () => dispatch(deps.actions.HomePage.refreshHomePage()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
			titles: {},
		};
		this.scrolled = this.scrolled.bind(this);
		//logger.log('[Header] constructor - props:%o, state:%o', props.attributes, this.state);
	}

	componentDidMount() {
		window.addEventListener('scroll', throttle(this.scrolled, 100, { trailing: true }), false);
		this.props.mount();
		//logger.log('[Header] componentDidMount - state:%o', this.state);
		let type = 'generic';
		let title = 'default';
		if (this.state.pageHeaders && this.state.pageHeaders[type]['title']) {
			title = this.state.pageHeaders[type]['title'];
		}

		let statusPanel = 'small';
		if (this.state.attributes && this.state.attributes.statusPanel) {
			statusPanel = this.state.attributes.statusPanel;
		}

		logger.log('[Header] componentDidMount - title:%o state:%o', title, this.state);

		this.setState({
			title: title,
			statusPanel: statusPanel,
			titles: {
				stdTitle: title,
			},
		});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', throttle(this.scrolled, 100, { trailing: true }), false);
		// logger.log('[Header] componentWillUnmount - state.scroll:%o', this.state.scroll);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[Header] componentWillReceiveProps link:%s', nextProps.attributes.canonicalLink);
		let type = this.state.headerType;
		let pageHeaders = {};
		let configPageHeader = {};

		//get the content type defined in the attributes
		if (nextProps['attributes'] && nextProps['attributes']['headerType']) {
			type = nextProps['attributes']['headerType'];
		}

		//get the configured pageHeader data for the found type
		if (nextProps['pageHeaders']) {
			pageHeaders = nextProps['pageHeaders'][type];
		}

		let resize = true;
		if (nextProps['fullwindowVideo'] || (!nextProps['fullwindowVideo'] && this.state.fullwindowVideo)) {
			resize = false;
		}

		//logger.log('[Header] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
		// logger.log('[Header] componentWillReceiveProps - title:%o', title);
		// logger.log('[Header] componentWillReceiveProps - type:%o', type);
		// logger.log('[Header] componentWillReceiveProps - attr:%o', nextProps['attributes']);
		// logger.log('[Header] componentWillReceiveProps - pageHeaders:%o', pageHeaders);

		/*
			 title and shortTitle set from attributes
			 if has pageHeaders config, this overrides attributes
			 types with titles coming from content, should not have title in pageHeaders config (will override)
 
			 ContentPage for articles, landing pages, etc... with a type
			   - title comes from type/pageHeaders config
			   - shortTitle comes from content xml loaded into page
 
			 Landing for NewsIndex, specific components
			   - set type of 'landing' or 'newsArchive'
			   - title comes from type/pageHeaders config
			   - shortTitle comes from type/pageHeaders config
 
			 stdTitle - the standard long title in config or overridden by page attributes
			 shortTitle - the standard short title in config or overridden by page attributes, used in mobile views
			 altTitle - the title from data which display in desktop view when scroll header or config short title
		 */

		let titles = {};
		titles.stdTitle = op.get(pageHeaders, 'title', null) || nextProps['attributes']['title'];
		titles.shortTitle = op.get(pageHeaders, 'shortTitle', null) || nextProps['attributes']['shortTitle'];
		titles.altTitle = nextProps['attributes']['shortTitle'] || op.get(pageHeaders, 'shortTitle', null);
		titles.subTitle = op.get(pageHeaders, 'subTitle', null) || nextProps['attributes']['subTitle'];

		// overwrites pageHeader attributes from component with config values from pageHeaders
		this.setState(prevState => ({
			...prevState,
			...nextProps['attributes'],
			...pageHeaders,
			currentUser: nextProps['currentUser'],
			gigyaLoaded: nextProps['gigyaLoaded'],
			loggedIn: nextProps['loggedIn'],
			fullwindowVideo: nextProps['fullwindowVideo'],
			resize: resize,
			ibmLink: nextProps['ibmLink'],
			myWim: nextProps['myWim'],
			displayDates: nextProps['displayDates'],
			windowSize: nextProps['windowSize'],
			titles,
			shelfIcons: nextProps['shelfIcons'],
		}));
	}

	componentDidUpdate() {
		//logger.log('[Header] componentDidUpdate - state:%o', this.state);
		// logger.log('[Header] componentDidUpdate - link:%s', String(this.state.canonicalLink));

		let pageWrapper = document.querySelector('.page > .wrapper');

		if (pageWrapper && this.state.fullwindowVideo) {
			pageWrapper.classList.add(this.state.fullwindowVideo);
		} else if (pageWrapper && !this.state.fullwindowVideo) {
			pageWrapper.classList.remove('fullwindow');
			pageWrapper.classList.remove('fullwindowheader');
		}

		if (this.state.resize) {
			let evt = window.document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 99);
			window.dispatchEvent(evt);
		}
	}

	getTitles() {
		logger.log('[Header] getTitles - state:%o titles:%o', this.state, this.state.titles);

		//let titleText = '';
		let dates = '';
		let { stdTitle, shortTitle, altTitle, subTitle } = this.state.titles;

		//add dates to header if hompage
		if (this.state.headerType == 'home' || this.state.headerType == 'home24') {
			dates = this.state.displayDates;
		}

		// return std or short title based on window size
		const getTitleText = () => {
			let title = stdTitle;
			if (this.state.windowSize == 'mobile' && shortTitle && shortTitle !== '') {
				title = shortTitle;
			}
			if (subTitle) {
				title += '<div class="subtitle">' + subTitle.toUpperCase() + '</div>';
			}
			// logger.log('[Header] getTitleText window:%o title:%o', this.state.windowSize, title);
			return title;
		};

		// if title is default
		if (stdTitle == 'default' || stdTitle == 'Home') {
			return (
				<div className={`logotitle-txt show`} display-bg="dark">
					<div className="logotitle-txt__name">WIMBLEDON</div>
					<div className="logotitle-txt__dates h5 white" id="header-dates">
						{dates}
					</div>
				</div>
			);
		}

		// if have stdTitle and shortTitle and scrollable, render alt and getTitleText, use css to hide/show as scroll
		//   in mobile, only shows primary short title from getTitleText
		else if (stdTitle && shortTitle != '' && this.state.scroll) {
			return (
				<div className="logotitle-titles">
					<div className={`title-txt title-txt-page show sel1`} display-bg="dark">
						<h3>
							<JSXParser jsx={getTitleText()} />
						</h3>
					</div>
					<div className={`title-txt title-txt-content sel2`} display-bg="dark">
						<h3>{altTitle}</h3>
					</div>
				</div>
			);
		}

		// if have title and shortTitle and not scrollable, use title based on window size
		else if (stdTitle && shortTitle != '' && !this.state.scroll) {
			return (
				<div className="logotitle-titles">
					<div className={`title-txt title-txt-page show sel3`} display-bg="dark">
						<div>
							<h3>
								<JSXParser jsx={getTitleText()} />
							</h3>
						</div>
					</div>
				</div>
			);
		}

		// if only have std title, use that
		else if (stdTitle) {
			return (
				<div className="logotitle-titles">
					<div className={`title-txt title-txt-page show sel4`} display-bg="dark">
						<div className="title-txt-page__name">
							<h3>
								<JSXParser jsx={getTitleText()} />
							</h3>
						</div>
					</div>
				</div>
			);
		}
	}

	getShadowRoles(data) {
		const roles = op.get(data, 'roles', []);
		let rolesDisplay = [];
		if (roles.length == 0) {
			return false;
		}
		//get list of roles and check if 'isShadowingAccess' is set
		for (let i = 0; i < roles.length; i++) {
			let currRole = roles[i];
			const shadowingAccess = op.get(this.state.currentUser, 'data.systemRole.player.isShadowingAccess', false);
			if (shadowingAccess) {
				rolesDisplay.push(currRole);
			}
		}
		logger.log('[Header] rolesDisplay:%o', rolesDisplay);
		return rolesDisplay;
	}

	renderShadow(roleData) {
		// const shadowingAccess = op.get(this.state.currentUser, 'data.systemRole.player.isShadowingAccess', false);
		if (roleData['key'] === 'player') {
			//check of the contact details is available
			const playerContactDetails = op.get(roleData, 'contactDetails', false);
			if (Object.keys(playerContactDetails).length > 0) {
				return (
					<div>
						Player -{' '}
						{`${roleData['contactDetails']['firstName']} ${roleData['contactDetails']['lastName']}`}
					</div>
				);
			} else {
				let linkId = op.get(this.state, 'currentUser.data.systemRole.player.linkId', false);
				if (linkId) {
					return <div>Player - {`${linkId}`}</div>;
				}
			}
		}
	}

	renderAllShadowRoles(data) {
		let playerCreds = getRole(op.get(this.state, 'currentUser.roles', []), 1000050);

		if (playerCreds) {
			//check to see if shadowing is set
			let shadowAccess = op.get(playerCreds, 'isShadowingAccess', false);

			if (shadowAccess) {
				const playerContactDetails = op.get(playerCreds, 'contactDetails', false);
				logger.log('[Header] playerContactDetails:%o', playerContactDetails);
				if (Object.keys(playerContactDetails).length > 0) {
					return (
						<WimLink to={'/en_GB/mywimbledon/support.html'} title="support">
							<div className="shadow-header">
								<div className="shadow-title">
									{shadowAccess == 'delegate' ? `Viewing:` : `Shadow:`}
								</div>
								{data.map((d, i) => {
									return this.renderShadow(d);
								})}
							</div>
						</WimLink>
					);
				}
			}
		}
	}

	scrolled(){
		if (this.props.windowSize && window.scrollY > 0 && !this.state.scrolled) {
			this.setState({scrolled: true});
		} else if(this.props.windowSize && window.scrollY <= 0 && this.state.scrolled){
			this.setState({scrolled: false});
		}
	}

	render() {
		logger.log('[Header] render - this:%o', this);

		let ibmLink = this.state.ibmLink ? this.state.ibmLink : '';
		let user = op.get(this.state, 'currentUser.profile', { firstName: null });
		//get shadow roles
		let shadowRoles = this.getShadowRoles({ ...this.state.currentUser });
		let logoClass = 'wim-logo';
		if (this.state.headerType == 'foundation' || this.state.headerType == 'wimbledonfoundation') {
			logoClass = 'wim-logo foundation';
		} else if (this.state.headerType == 's_member') {
			logoClass = 'wim-logo members';
		}

		//define the different titles used
		//  seoTitle takes precedence
		//  boilerplate appended, except for site search
		let searchTitle = this.state.metaSeoTitle || this.state.metaTitle || '';
		let siteTitle = searchTitle + ' - ' + values.pageTitle;

		let description = this.state.metaSeoDescription || this.state.metaDescription || '';

		let evHelmetAttr = {
			pageTitle: siteTitle,
			description: description,
			keywords: this.state.keywords || '',
			canonical: this.state.canonicalLink || undefined,
			amp: this.state.ampUrl || undefined,
			type: this.state.type || undefined,
			contentTitle: searchTitle,
			contentDate: this.state.metaDate ? this.state.metaDate : undefined,
			contentPlayers: this.state.metaPlayers ? this.state.metaPlayers : undefined,
			measureTitle: this.state.measureTitle ? this.state.measureTitle : undefined,
			shareImage: this.state.shareImage ? this.state.shareImage : undefined,
			author: this.state.author ? this.state.author : undefined,
			noindex: this.state.noindex || false
		};

		let iconsArray = [];
		let roleNavShown = this.state.headerType == 'home24' && this.props.loggedIn;
		if(this.state.headerType == 'home24'){
			/** figure out roles */
			let userRoles = op.get(this.state, 'currentUser.roles', []);
			
			let roles = [...userRoles];
			logger.log('[Header] roles:%o', roles);
			roles = roles.filter(role => {
				// if (role.key == this.props.role || role.roleId == op.get(this.props, 'data.roles', null)) { 
					//check for addtl paramters for specific roles
					if (role.key == 'debenture' && this.props.version < 2) { //what's this for?? 
						return false;
					} else if (role.key == 'ballot' && this.props.period == 'pre') {
						return false;
					}else if(role.key == 'media' && role.approved == false){
						return false;
					}
					return true;
				// } else {
				// 	return false;
				// }
			});

			let roleNavRoles = [];
			/** multiple roles, so show each role with a link to their landing pages */
			if(roles.length > 1){
				
				let {shelfIcons} = this.props;
				logger.log('[Header] multirole - roles:%o', roles);
				if(shelfIcons){
					roleNavRoles = roles
					.sort((a, b) => shelfIcons.titles[a.key]?.sort - shelfIcons.titles[b.key]?.sort)
					?.filter(role => {
						let validHomeShelfRole = false;
						if(!role.landingPage){
							return validHomeShelfRole;
						}
						shelfIcons[role.keySegment]?.map(roleService => {
							if (roleService.show.includes('home')) {
								validHomeShelfRole = true;
							}
						});
						return validHomeShelfRole;
					});

					logger.log('[Header] render - roleNavRoles:%o, roles:%o', roleNavRoles, roles);

					// remap data to match format
					iconsArray = roleNavRoles.map(i => {
						return { url: i.landingPage.replace('<segment>', i.segment), title: i.name};
					});

					logger.log('[Header] multirole render - iconsArray:%o', iconsArray);
				}
			}

			/** no roles, so don't show the role nav */
			if(roles.length < 1){
				roleNavShown = false;
			}

			/** if the user only has one role, or only one role that will display, show all sub-links */
			if(roles.length == 1 || roleNavRoles.length == 1 || iconsArray.length == 1){
				// logger.log('[Header] 1role iconsArray:%o, roleNavRoles:%o', iconsArray, roleNavRoles);
				iconsArray = [];
				/** figure out links from icon data */
				let role = roleNavRoles?.[0]?.key || roles?.[0]?.key;
				let shelfKey = role;
				let segment = '';
				let icons = op.get(this.state, `shelfIcons.${role}`, []);
				// logger.log('[Header] shelfIcons:%o', this.state.shelfIcons);
				/**
				 * find the appropriate icons based on role/roleSegmentId
				 */
				if (roleNavRoles?.[0]?.segment || roles?.[0]?.segment) {
					segment = roleNavRoles?.[0]?.segment || roles?.[0]?.segment;
					shelfKey = `${role}:${segment}`;
					if (op.get(this.state, `shelfIcons.${shelfKey}`, false)) {
						icons = op.get(this.state, `shelfIcons.${shelfKey}`, []);
					}
				}

				/**
				 * remove icons not designated for the specified display
				 */
				icons = icons.filter(icons => {
					if (op.get(icons, 'show', 'home').includes('home')) {
						return true;
					} else {
						return false;
					}
				});
				icons = icons.map(i => {
					return { url: i.url.replace('<segment>', segment), title: i.title};
				});
				iconsArray.push(...icons);
				logger.log('[Header] 1role icons:%o, roleNavRoles:%o', icons, roleNavRoles);
			}

			if(iconsArray.length < 1){
				roleNavShown = false;
			}

			
		}

		

		//add dates to header if hompage, otherwise say link to homepage
		let headerAriaLabel = this.state.headerType == 'home' || this.state.headerType == 'home24' ? this.state.displayDates || '' : 'link to homepage';

		let toShowSmartbanner = this.props.config?.enabled?.showSmartbanner;
		let toRenderSmartbanner = this.props.smartbanner?.includes(window.location.pathname) && toShowSmartbanner && (document.getElementsByClassName('.promo-feature-overlay')?.length == 0);

		return (
			<>
				{roleNavShown && <RoleNav scrolled={this.state.scrolled} links={iconsArray} />}
				<div
					id="header-global"
					className={`header-global${this.props.tgc === true ? ' tgc' : ''} ${this.state.headerType == 'player-profile' ? 'player-profile' : ''} ${this.state.headerType == 'home24' ? 'home24' : ''} ${roleNavShown ? 'roleNavShown' : ''} ${this.state.scrolled ? 'scrolled' : ''}`}
					role="navigation">
					<noindex>
						{/* Adding User Shadow Bar Here */}
						{shadowRoles && user && this.renderAllShadowRoles(shadowRoles)}

						{toRenderSmartbanner ? <SmartbannerHelmet /> : null}

						<EventsHelmet attributes={evHelmetAttr} shareHost={window.location.origin} />

						<SkipContent isSkipContent={this.state.skipContent} />

						<MainNav />

						<div className="logotitle">
							<div className="logotitle-image">
								<NavLink to="/index.html" role="link" tabIndex={-1}>
									{/* <a href="/index.html"> */}
									<div
										onClick={() => {
											this.state.refreshHomePage();
										}}
										role="link"
										aria-label={`The Championships, Wimbledon. ${headerAriaLabel}`}
										tabIndex={0}
										title={`The Championships, Wimbledon. ${headerAriaLabel}`}
										className={logoClass}
									/>
									{/* </a> */}
								</NavLink>
							</div>
							{this.getTitles()}
						</div>
						<div className="logosearch">
							{this.state.gigyaLoaded ? (
								<Login
									currentUser={this.state.currentUser}
									myWim={op.get(this.state, 'myWim.active', false)}
									loggedIn={this.state.loggedIn}
									home24={this.state.headerType == 'home24' ? true : false}
								/>
							) : null}
							<WimLink to="/en_GB/search/index.html" title="search" style="white">
								<i className="wim-icon-search" aria-hidden tabIndex={-1} />
							</WimLink>
							<WimLink to={ibmLink} external={true} title="IBM wimbledon" role="link">
								<span className="logo-small">
									<img
										className="ibm-logo"
										src={`/assets/images/logos/ibm-logo-small${this.state.headerType == 'home24' ? '-blk' : ''}.png`}
										alt="IBM logo"
									/>
								</span>
								<span className="logo-standard">
									<img className="ibm-logo" src={`/assets/images/logos/ibm-logo${this.state.headerType == 'home24' ? '-blk' : ''}.png`} alt="IBM logo" />
								</span>
							</WimLink>
						</div>
						
						{this.state.statusPanel != 'none' &&
							<StatusPanel
								style={this.state.statusPanel}
								myWim={this.state.myWim}
								userName={user.firstName}
								loggedIn={this.state.loggedIn}
								shelfIcons={this.state.shelfIcons}
								roles={op.get(this.state, 'currentUser.roles', [])}
								mode={op.get(this.props, 'mode', {})}
								gigya={this.props?.gigya}
								getJWT={() => this.props?.getJWT()}
							/>
						}
					</noindex>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

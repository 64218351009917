/* NOTE: code for table comes from: https://react-table-v7.tanstack.com/docs/examples/kitchen-sink */

import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, useFilters, useGroupBy, useRowSelect } from 'react-table';
import { matchSorter } from 'match-sorter';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

// Create cell renderer
const FormattedCell = ({ value: initialValue, row: row, column: column, debugView: debugView }) => {
	// format ticket date and day
	if (column?.Header === 'Date') {
		if (row?.original?.day !== 'QUALS' && row?.original?.day !== 'UGP') {
			return (
				<span style={{ padding: '0px' }}>
					{row?.original?.date} <br></br>
					Day {row?.original?.day}
				</span>
			);
		} else {
			return null;
		}
	}

	// add bold css to email address of status
	if (column?.Header === 'Status' && row?.original?.status?.fullStatusDetails !== 'Normal') {
		return (
			<span className="web-ticketview-no-padding">
				<b className="web-ticketview-no-padding">{row?.original?.status?.value}</b>
				<br></br>
				{row?.original?.status?.detail}
			</span>
		);
	}

	if (debugView && column?.Header.toLowerCase() === 'court') {
		return (
			<div className="web-ticketview-no-padding">
				{row?.original?.testTicket ? (
					<span className="web-ticketview-test-ticket web-ticketview-no-padding">TEST TICKET</span>
				) : null}
				{initialValue}
				<span className="web-ticketview-debug web-ticketview-no-padding">
					SPEC: {row?.original?.spectatorId} &emsp;&emsp;&emsp; ID: {row?.original?.externalId}{' '}
					&emsp;&emsp;&emsp; GRP: {row?.original?.source?.groupId?.split('@')[1]} &emsp;&emsp;&emsp; CAT:{' '}
					{row?.original?.detailedInformation?.category} &emsp;&emsp;&emsp; ACTIONS:{' '}
					{row?.original?.actions?.join(', ')}
				</span>
			</div>
		);
	}

	return initialValue;
};

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter, id } }) {
	// const count = preFilteredRows.length;
	let label;
	switch (id) {
		case 'status.date':
			label = 'last updated';
			break;
		case 'status.filtervalue':
			label = 'status';
			break;
		default:
			if (id.includes('detailedInformation.')) {
				label = id.split('.')[1];
			} else {
				label = id;
			}
			break;
	}

	return (
		<input
			value={filterValue || ''}
			alt={`${label} filter. ${filterValue ? 'currently filtered on' + filterValue : ''}`}
			title={`${label} filter. ${filterValue ? 'currently filtered on' + filterValue : ''}`}
			onChange={e => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			//   placeholder={`Search ${count} records...`}
			placeholder="Search"
		/>
	);
}

// This is a custom filter UI for selecting a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	let prefilteredRowsIds = [];
	let options = [];
	preFilteredRows.map((row) => {
		// if prefilteredRows doesnt include filterValue, set value to "All" (done down below)
		prefilteredRowsIds.push(row.values[id]);
		if (id === 'status.date') {
			let value = moment(row.values[id]).format('ddd DD MMM YYYY');
			if (row.values[id] && !options.includes(value)) options.push(value);
		  } else {
			if (row.values[id] && !options.includes(row.values[id])) options.push(row.values[id]);
		  }
	})
	
	// let label = id.includes('.') ? id.split('.')[1] : id;

	let label;
	let sortedOptions;
	switch (id) {
		case 'typeLabel':
			label = 'type';
			break;
		case 'status.date':
			label = 'last updated';
			sortedOptions = options.sort((a, b) => {
				return moment(a) - moment(b);
			});
			break;
		default:
			if (id.includes('detailedInformation.')) {
				label = id.split('.')[1];
			} else {
				label = id;
			}
			break;
	}

	if (['detailedInformation.gangway', 'date'].includes(id)) {
		sortedOptions = options.sort((a, b) => {
			return a - b;
		});
	} else if (id !== 'status.date') {
		// already sorted status.date above
		sortedOptions = options.sort();
	}

	const labelValue = label === 'status.dateFormatted' ? 'last updated' : label;

	let filterVal = filterValue;

	if (filterValue && prefilteredRowsIds?.length && !prefilteredRowsIds?.includes(filterValue)) {
		// if the other succeeding tab filters are no longer included in new filter applied, reset the succeeding tab filter to '' so it will be reset to "All" and update the items in the table properly
		filterVal = '';
		setFilter(filterVal)
	}	

	// Render a multi-select box
	return (
		<select
			tabIndex={0}
			alt={`${label} filter. ${filterValue ? 'currently filtered on' + filterValue : ''}`}
			aria-label={`${label} filter. ${filterValue ? 'currently filtered on' + filterValue : ''}`}
			value={filterVal}
			onChange={e => {
				e.preventDefault();
				MeasurementUtils.dispatchMeasurementCall('Dropdown Selected', {
					pageTitle: 'Tickets',
					view: 'Ticket Management',
					dropdown: labelValue,
					selected: event?.target?.value,
				});
				setFilter(e.target.value);
			}}>
			<option value="">All</option>
			{sortedOptions.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	);
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

function Table({ columns, data, ticketActionTabSelected, onActionBtnClick, debugView, resetClicked, onSetReset }) {
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter(row => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// set up default Filter UI
			Filter: DefaultColumnFilter,
			// set up default cell
			Cell: FormattedCell,
		}),
		[]
	);

	const startTicketAction = (selectedFlatRows, onCallAction) => {
		let selectedRowsDetails = [];
		selectedFlatRows.map(val => {
			selectedRowsDetails.push(val?.original);
		});
		// call action button function
		onCallAction(ticketActionTabSelected, selectedRowsDetails);
	};

	const deselectCheckboxesOnTabChange = () => {
		selectedFlatRows.forEach(id => {
			toggleRowSelected(id.id, false);
		});
	};

	const [tabSelected, setTabSelected] = useState('');

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		// if tab has changed, deselect previously selected checkboxes
		if (ticketActionTabSelected !== tabSelected) {
			deselectCheckboxesOnTabChange();
			setTabSelected(ticketActionTabSelected);
		}
	});

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		setAllFilters,
		selectedFlatRows,
		toggleRowSelected,
		// state: { selectedRowIds, filters },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			disableMultiSort: true,
			debugView,
			onSetReset,
			// autoResetSelectedRows: true,
		},
		useFilters,
		useGroupBy,
		useSortBy,
		useRowSelect,
		useState,
		// Here we will use a plugin to add our selection column
		hooks => {
			hooks.visibleColumns.push(columns => {
				return [
					{
						id: 'selection',
						// Make this column a groupByBoundary. This ensures that groupBy columns
						// are placed after it
						groupByBoundary: true,
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox to select all values
						// Header: ({ getToggleAllRowsSelectedProps }) => (
						// 	<div>{/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}</div>
						// ),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} row={row} data={data} />
							</div>
						),
					},
					...columns,
				];
			});
		}
	);

	const [selectedRows, setSelectedRows] = useState([]);
	const [keepOneInGroupObj, setKeepOneInGroupObj] = useState({});

	// reset dropdown filters, otherwise will keep their previously selected values when "reset filters" button is clicked
	const resetAllDropdownFilters = () => {
		const dropdownFilters = document.getElementsByTagName('select');
		for (let i = 0; i < dropdownFilters.length; i++) {
			dropdownFilters[i].selectedIndex = 0;
		}
	};

	const onCheckboxClick = (row, data) => {
		if (!selectedRows?.includes(row)) {
			selectedRows?.push(row);
		} else {
			// handle un-check of checkbox
			const index = selectedRows?.indexOf(row);
			if (index > -1) {
				// only splice array when item is found
				selectedRows?.splice(index, 1); // 2nd parameter means remove one item only
			}
			// remove from keepOneInGroupObj group if exists in obj
			if (keepOneInGroupObj[row?.original?.source?.transferRules?.groupId]) {
				let newObj = keepOneInGroupObj;
				newObj[row?.original?.source?.transferRules?.groupId] = false;
				setKeepOneInGroupObj(newObj);
			}
		}
		setSelectedRows(selectedRows);

		// get transfer tab to check if its selected
		const transferTab = document.getElementById('ticket-mgmt-tab-transfer');

		// check for keepOneInGroupId rule in transfer tab
		if (
			row?.original?.source?.transferRules?.keepOneInGroupId &&
			transferTab?.className?.includes('ticket-mgmt-tab-selected')
		) {
			// get groupId
			let groupId = row?.original?.source?.transferRules?.groupId;

			// get all those matching it's group id in the data (includes self)
			let matchingGroupIds = data.filter(d => {
				return d?.source?.transferRules?.groupId === groupId;
			});

			// compare to those already selected
			let intersectionSelected = selectedRows?.filter(x => {
				return matchingGroupIds?.includes(x?.original);
			});

			// compare to those already transferred
			let interesectionTransferred = matchingGroupIds?.filter(x => {
				return x?.status?.value?.toLowerCase().includes('transfer');
			});

			// if sum of both selected and transferred equal limit, set state
			if (intersectionSelected?.length + interesectionTransferred?.length === matchingGroupIds.length - 1) {
				let newObj = keepOneInGroupObj;
				newObj[groupId] = true;
				setKeepOneInGroupObj(newObj);
			}
		}
	};

	const IndeterminateCheckbox = React.forwardRef(({ indeterminate, row, data, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input
					ref={resolvedRef}
					{...rest}
					className="tix-mgmt-styled-checkbox"
					id={`tix-mgmt-styled-checkbox-${row?.id}`}
					type="checkbox"
					tabIndex={0}
					row={row}
					onClick={() => onCheckboxClick(row, data)}
				/>
				<label htmlFor={`tix-mgmt-styled-checkbox-${row?.id}`} />
			</>
		);
	});

	let noRowsToDisplayMsg;
	switch (tabSelected) {
		case 'transfer':
			noRowsToDisplayMsg = 'There are no tickets available to transfer.';
			break;
		case 'recall':
			noRowsToDisplayMsg = 'There are no pending transfers to cancel.';
			break;
		case 'returnToSender':
			noRowsToDisplayMsg = 'There are no transferred tickets which can be returned to the sender.';
			break;
		default:
			noRowsToDisplayMsg = 'There are no tickets available.';
			break;
	}

	if (resetClicked === true) {
		onSetReset();
		setTimeout(() => {
			setAllFilters([]);
			resetAllDropdownFilters();
		}, 100); // needs timeout otherwise will go into infinate loop (reset needs to be set to false with onSetReset first)
	}

	// Render the UI for your table
	return (
		<div className="tix-mgmt-list-table">
			<div className="tix-viewing-count-txt" alt={`Viewing ${rows.length} of ${data.length} tickets`}>
				Viewing{' '}
				<b>
					{rows.length} of {data.length}
				</b>{' '}
				tickets
			</div>
			<button
				className="ticket-reset-button tix-viewing-count-reset-btn"
				role="button"
				alt="reset ticket filters in the ticket management list table"
				title="reset ticket filters in the ticket management list table"
				onClick={() => {
					setAllFilters([]);
					resetAllDropdownFilters();
				}}>
				Reset Filters
			</button>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => {
								return (
									<th {...column.getHeaderProps()}>
										<div>
											<span {...column.getSortByToggleProps()}>
												{column.render('Header')}
												{/* Add a sort direction indicator */}
												{column.canGroupBy ? (
													!column.isSorted ? (
														<div className="tix-mgmt-sort-icon-cntr">
															<img
																src="/assets/images/tickets/sortArrowIcon.svg"
																className="tix-mgmt-sort-icon"
																alt="sort arrow icon"
															/>
															<img
																src="/assets/images/tickets/sortArrowIcon.svg"
																alt="sort arrow icon"
																className="web-ticketview-sortIcon-rotate-no-padding tix-mgmt-sort-icon"
															/>
														</div>
													) : column.isSortedDesc ? (
														<img
															src="/assets/images/tickets/sortArrowIcon.svg"
															className="web-ticketview-sortIcon tix-mgmt-sort-icon"
															alt="sort arrow icon"
														/>
													) : (
														<img
															src="/assets/images/tickets/sortArrowIcon.svg"
															alt="sort arrow icon"
															className="web-ticketview-sortIcon-rotate tix-mgmt-sort-icon"
														/>
													)
												) : null}
											</span>
										</div>
										{/* Render the columns filter UI */}
										<div alt={column.canFilter ? 'filter on column' : ''}>
											{column.canFilter ? column.render('Filter') : null}
										</div>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} id="desktop-ticket-list-body">
					{rows.map((row, index) => {
						prepareRow(row);

						// NOTE: commented out. keeping in case we need to revert code back to disabling rows rather than filtering them out based on action tab selected
						// disable row if action is selected in tab list and the ticket does not have that action
						// let toHideRow =
						// 	ticketActionTabSelected !== 'viewAll' &&
						// 	(!row?.original?.actionable ||
						// 		//!row?.original?.actions.includes(ticketActionTabSelected) ||
						// 		(ticketActionTabSelected === 'returnToSender'
						// 			? !row?.original?.actions.includes('return')
						// 			: !row?.original?.actions.includes(ticketActionTabSelected)));

						// check if it is the last one in the group if keepOneInGroup applies and that it's not selected
						let toDisableKeepOneInGroup = false;
						if (
							row?.original?.source?.transferRules?.keepOneInGroupId &&
							ticketActionTabSelected !== 'viewAll' &&
							ticketActionTabSelected === 'transfer'
						) {
							toDisableKeepOneInGroup =
								keepOneInGroupObj[row?.original?.source?.transferRules?.groupId] &&
								!selectedRows?.includes(row);

							const groupId = row?.original?.source?.transferRules?.groupId;
							let isLastInGroup = false;
							let remainingInGroup = data.filter(d => {
								return (
									d?.source?.transferRules?.groupId === groupId &&
									d?.externalId !== row?.original?.source?.externalId &&
									(!d?.actions.includes('transfer disallowed') || d?.actions.includes('transfer'))
								);
							});
							if (remainingInGroup.length === 0) isLastInGroup = true;

							// check in actual row one as well in case there are only 2 and the other one has been transferered, second cant be selected either
							let interesectionTransferred = [];
							if (!isLastInGroup) {
								// let groupId = row?.original?.source?.transferRules?.groupId;
								let matchingGroupIds = data.filter(d => {
									return d?.source?.transferRules?.groupId === groupId;
								});
								if (matchingGroupIds?.length === 2) {
									interesectionTransferred = matchingGroupIds?.filter(x => {
										return x?.status?.value?.toLowerCase().includes('transfer');
									});
								}
							}
							toDisableKeepOneInGroup =
								ticketActionTabSelected !== 'viewAll' &&
								(isLastInGroup || toDisableKeepOneInGroup || interesectionTransferred?.length > 0);
						}

						const ariaLabel = `ticket row ${index + 1} out of ${rows.length} for: court ${
							row?.values?.['detailedInformation.court']
						}, gangway ${row?.values['detailedInformation.gangway']}, row ${
							row?.values['detailedInformation.row']
						}, seat ${row?.values['detailedInformation.seat']}, status ${
							row?.values['status.filtervalue']
						}, date ${row?.values?.date}`;

						// NOTE: commented out. keeping in case we need to revert code back to disabling rows rather than filtering them out based on action tab selected
						// don't display row at all rather than disabling the row
						// if (toHideRow) {
						// 	return null;
						// }

						return (
							<tr
								{...row.getRowProps()}
								tabIndex={0}
								aria-label={`${
									ticketActionTabSelected === 'viewAll'
										? 'row is unable to be selected for action'
										: 'row is selectable for action'
								} ${ariaLabel}`}
								disabled={toDisableKeepOneInGroup}
								alt={ariaLabel}
								title={ariaLabel}
								className={
									ticketActionTabSelected === 'viewAll' || toDisableKeepOneInGroup
										? 'ticket-list-table-row-viewAll'
										: 'ticket-list-table-row'
								}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			{rows.length === 0 ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '24px' }}>{noRowsToDisplayMsg}</div>
			) : null}
			{/* show action button when tickets are selected in list  */}
			{Object.keys(selectedFlatRows).length > 0 ? (
				<div className="tix-mgmt-action-btn-cntr">
					<button
						className="tix-mgmt-action-btns"
						onClick={() => startTicketAction(selectedFlatRows, onActionBtnClick)}>
						start{' '}
						{ticketActionTabSelected === 'recall'
							? 'cancel transfer'
							: ticketActionTabSelected.toLowerCase() === 'returntosender'
							? 'return to sender'
							: ticketActionTabSelected}{' '}
						<i className="wim-icon-right-arrow right-icon" />
					</button>
				</div>
			) : null}
		</div>
	);
}

// NOTE: COMPONENT STARTS HERE
function DesktopTicketListTable(props) {
	const ordered_courts = props.orderedCourts;
	const columns = React.useMemo(
		() => [
			{
				Header: 'Court',
				accessor: 'detailedInformation.court',
				Filter: SelectColumnFilter,
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();

					// desc
					if (desc) {
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (d2 < d1) return -1;
						if (d1 < d2) return 1;
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						if (s2 < s1) return -1;
						if (s1 < s2) return 1;
						return 0;
					}
					// else asc
					if (c2 < c1) return 1;
					if (c1 < c2) return -1;
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					return 0;
				},
			},
			{
				Header: 'Date',
				accessor: 'date',
				Filter: SelectColumnFilter,
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					// asc
					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();

					// desc
					if (desc) {
						if (d2 < d1) return 1;
						if (d1 < d2) return -1;
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						if (s2 < s1) return -1;
						if (s1 < s2) return 1;
						return 0;
					}
					// else asc
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (c2 < c1) return 1;
					if (c1 < c2) return -1;
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					return 0;
				},
			},
			{
				Header: 'Gangway',
				accessor: 'detailedInformation.gangway',
				Filter: SelectColumnFilter,
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					// asc
					let g1 = parseInt(op.get(a, 'detailedInformation.gangway', ''));
					let g2 = parseInt(op.get(b, 'detailedInformation.gangway', ''));
					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();

					// desc
					if (desc) {
						if (g1 < g2) return -1;
						if (g2 < g1) return 1;
						if (d2 < d1) return -1;
						if (d1 < d2) return 1;
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						if (s2 < s1) return -1;
						if (s1 < s2) return 1;
						return 0;
					}
					// else asc
					if (g2 < g1) return 1;
					if (g1 < g2) return -1;
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (c2 < c1) return 1;
					if (c1 < c2) return -1;
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					return 0;
				},
			},
			{
				Header: 'Row',
				accessor: 'detailedInformation.row',
				Filter: SelectColumnFilter,
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					// asc
					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();

					// desc
					if (desc) {
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						if (d2 < d1) return -1;
						if (d1 < d2) return 1;
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (s2 < s1) return -1;
						if (s1 < s2) return 1;
						return 0;
					}
					// else asc
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (c1 < c2) return 1;
					if (c2 < c1) return -1;
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					return 0;
				},
			},
			{
				Header: 'Seat',
				accessor: 'detailedInformation.seat',
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					// asc
					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();

					// desc
					if (desc) {
						if (s2 < s1) return 1;
						if (s1 < s2) return -1;
						if (d2 < d1) return -1;
						if (d1 < d2) return 1;
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						return 0;
					}
					// else asc
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (c2 < c1) return 1;
					if (c1 < c2) return -1;
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					return 0;
				},
			},
			{
				Header: 'Status',
				accessor: 'status.fullStatusDetails',
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					// asc
					let status1 = op.get(a, 'status.filtervalue', '');
					let status2 = op.get(b, 'status.filtervalue', '');
					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();

					// desc
					if (desc) {
						if (status2 < status1) return 1;
						if (status1 < status2) return -1;
						if (d1 < d2) return 1;
						if (d2 < d1) return -1;
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						if (s2 < s1) return -1;
						if (s1 < s2) return 1;
						return 0;
					}
					// else asc
					if (status1 < status2) return 1;
					if (status2 < status1) return -1;
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (c2 < c1) return 1;
					if (c1 < c2) return -1;
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					return 0;
				},
			},
			{
				Header: 'Type',
				accessor: 'typeLabel',
				Filter: SelectColumnFilter,
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					// asc
					let t1 = op.get(a, 'typeLabel', '');
					let t2 = op.get(b, 'typeLabel', '');
					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();

					// desc
					if (desc) {
						if (t2 < t1) return 1;
						if (t1 < t2) return -1;
						if (d1 < d2) return -1;
						if (d2 < d1) return 1;
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						if (s2 < s1) return -1;
						if (s1 < s2) return 1;
						return 0;
					}
					// else asc
					if (t1 < t2) return 1;
					if (t2 < t1) return -1;
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (c2 < c1) return 1;
					if (c1 < c2) return -1;
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					return 0;
				},
			},
			{
				Header: 'Last Updated',
				accessor: 'status.dateFormatted',
				Filter: SelectColumnFilter,
				aggregate: 'count',
				sortType: (valueA, valueB, id, desc) => {
					let a = valueA?.original;
					let b = valueB?.original;

					// asc
					let c1 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(a, 'detailedInformation.courtCode', '')
					);
					let c2 = ordered_courts?.findIndex(
						crt => crt?.code === op.get(b, 'detailedInformation.courtCode', '')
					);
					let r1 = op.get(a, 'detailedInformation.row', '');
					let r2 = op.get(b, 'detailedInformation.row', '');
					let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
					let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

					let d1 = a?.source?.event?.startTime
						? moment(a?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let d2 = b?.source?.event?.startTime
						? moment(b?.source?.event?.startTime)
								.tz('Europe/London')
								.startOf('day')
								.valueOf()
						: moment(0)
								.add(900, 'years')
								.valueOf();
					let l1 =
						moment(a?.status?.date)?.valueOf() ||
						moment(0)
							.add(900, 'years')
							.valueOf();
					let l2 =
						moment(b?.status?.date)?.valueOf() ||
						moment(0)
							.add(900, 'years')
							.valueOf();

					// desc
					if (desc) {
						if (l2 < l1) return -1;
						if (l1 < l2) return 1;
						if (d2 < d1) return -1;
						if (d1 < d2) return 1;
						if (c2 < c1) return 1;
						if (c1 < c2) return -1;
						if (r2 < r1) return 1;
						if (r1 < r2) return -1;
						if (s2 < s1) return -1;
						if (s1 < s2) return 1;
						return 0;
					}
					// else asc
					if (l2 < l1) return -1;
					if (l1 < l2) return 1;
					if (d2 < d1) return 1;
					if (d1 < d2) return -1;
					if (c2 < c1) return 1;
					if (c1 < c2) return -1;
					if (r2 < r1) return 1;
					if (r1 < r2) return -1;
					if (s2 < s1) return 1;
					if (s1 < s2) return -1;
					return 0;
				},
			},
		],
		[]
	);

	// filter out data based on tab selected
	let data;
	if (props.ticketActionTabSelected !== 'viewAll') {
		// show all data if tab is View All
		let tab = props.ticketActionTabSelected === 'returnToSender' ? 'return' : props.ticketActionTabSelected;
		data = props.data.filter(item => {
			return item?.actions?.includes(tab);
		});
	} else {
		data = props.data;
	}

	return (
		<Table
			columns={columns}
			data={data}
			ticketActionTabSelected={props.ticketActionTabSelected}
			onActionBtnClick={props.onActionBtnClick}
			debugView={props.debugView}
			resetClicked={props.resetClicked}
			onSetReset={() => props.onSetReset()}
		/>
	);
}

export default DesktopTicketListTable;

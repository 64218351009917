/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { MatchArchiveContext } from '../context';
import WimLink from 'appdir/components/general/WimLink';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: ReplayType
 * -----------------------------------------------------------------------------
 */

class ReplayType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.onClick = this.onClick.bind(this);

		logger.log('[ReplayType] constructor - context:%o', this.context);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = null;

		if (nextProps.mode != prevState.mode) {
			newState = {
				mode: nextProps.mode,
			};
		}

		return newState;
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.mode != this.state.mode) {
			return true;
		} else {
			return false;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[ReplayType] componentDidUpdate - context:%o state:%o', this.context, this.state);
	}

	onClick() {
		logger.log('[ReplayType] onClick - state:%o', this.state);

		let newMode = '';
		if (this.state.mode == 'sync') {
			newMode = 'result';
		} else if (this.state.mode == 'result') {
			newMode = 'sync';
		}

		MeasurementUtils.dispatchMeasurementCall('Match Archive', {
			data: ['Detail', 'Point Nav', 'Scoring Mode', newMode == 'sync' ? 'video sync' : 'result'],
		});

		this.setState({
			mode: newMode,
		});

		if (this.context.onReplayType) {
			this.context.onReplayType(newMode);
		}
	}

	render() {
		logger.log('[ReplayType] render - state:%o', this.state);
		//logger.log('[ReplayType] render - props:%o points:%o', this.props, this.props.currentMatch.points);

		return (
			<div className="replay-type">
				<div className="title">Scoring Mode:</div>
				<button data-type={this.state.mode == 'sync' ? 'active' : ''} onClick={this.onClick}>
					Video Sync
				</button>
				<div className="separator">|</div>
				<button data-type={this.state.mode == 'result' ? 'active' : ''} onClick={this.onClick}>
					Result
				</button>
			</div>
		);
	}
}

ReplayType.contextType = MatchArchiveContext;
export default ReplayType;

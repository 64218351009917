import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getJson } from 'appdir/components/general/Util/Services';
import { NavLink } from 'react-router-dom';
import WimLink from 'appdir/components/general/WimLink';
import JSXParser from 'react-jsx-parser';
import op from 'object-path';
// import { CarouselArrow } from './CarouselArrow';
// import Carousel, { Dots } from '@brainhubeu/react-carousel';
import Slider from 'react-slick';
import axios from 'axios';

const mapStateToProps = (state, props) => {
	return {
		// playStatus: state['ActiveData']['homepage'],
		playStatus: state['ActiveData'],
		promoPath: state['Config']['homepage'],
		memberConfig: state['Config']['member'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class HomePromos extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			error: false,
			selectedSlide: null,
			ariaSliderSet: false,
			courtAvailibilitySet: false,
		};
		this.pageWrapper = null;
		this.gotData = false;
		this.loading = false;
		this.sliderHover = this.sliderHover.bind(this);
		// logger.log('[HomePromos] constructor state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		if (document.getElementById('home-slider-cntr') && !this.state.ariaSliderSet) {
			let el = document.getElementById('home-slider-cntr');
			let listItems = el?.getElementsByTagName('li');
			for (let index = 0; index < listItems.length; index++) {
				// get button inside slider to set tab index of -1 so aria accessibility ignores it. have to do it in componentDidUpdate because the slider has not yet loaded in componentDidMount and therefore is null
				let btn = listItems[index]?.getElementsByTagName('button');
				btn[0]?.setAttribute('tabindex', '-1');
			}
			this.setState({ ariaSliderSet: true }); // set state so it doesnt re-set it ever single time the component updates
		}
		this.pageWrapper = document.querySelector('.page > .wrapper');
		//logger.log('[HomePromos] componentDidUpdate - promoPath:%o', this.props.promoPath);
		logger.log('[HomePromos] componentDidUpdate - playStatus:%o', this.props.playStatus);

		let isMemberRole = this.props.gigya.currentUser?.data?.systemRole?.member?.roleId === 1000040;

		if (
			this.props.gigya.gigyaLoaded &&
			!this.state.courtAvailibilitySet &&
			!this.props.isTournament &&
			isMemberRole
		) {
			this.props.getJWT().then(token => {
				this.getCourtAvailibility(token?.jwt?.id_token);
			});
		}
		if (this.props.playStatus && this.props.playStatus.updated != prevProps.playStatus.updated) {
			this.setState({ promoData: null });
		}
		if (op.get(this.props, 'promoPath.promoUrl', false) && this.loading == false && !this.state.promoData) {
			let path = this.props.promoPath.promoUrl;
			this.loading = true;
			getJson(path)
				.then(data => {
					logger.log('[HomePromos] componentDidUpdate - data:%o', data);
					this.setState({ promoData: data });
					this.loading = false;
				})
				.catch(error => {
					logger.error('[HomePromos] componentDidUpdate - error getting data');
					this.setState({ promoData: 'error' });
					this.loading = false;
				});
		}
	}

	async getCourtAvailibility(token) {
		const method = 'GET';
		const baseURL = this.props?.memberConfig?.api.courtAvailability;
		const data = null;

		const courtAvailabilityData = await axios
			.request({
				method,
				url: baseURL,
				headers: {
					Authorization: 'Bearer ' + token,
				},
				data,
			})
			.then(res => {
				return res;
			})
			.catch(err => {
				logger.log('***test [HomePromos] getCourtAvailibility - err:%o', err);
			});

		if (courtAvailabilityData) {
			let {
				championshipCourts,
				clayAndAcrylicCourts,
				practiceCourts,
				courtAvailabilityMemberNotes,
			} = courtAvailabilityData?.data?.response;
			let totalAvailableCourts =
				championshipCourts?.courtsAvailable +
				clayAndAcrylicCourts?.courtsAvailable +
				practiceCourts?.courtsAvailable;
			let totalCourts =
				championshipCourts?.totalCourts + clayAndAcrylicCourts?.totalCourts + practiceCourts?.totalCourts;
			this.setState({
				totalAvailableCourts,
				totalCourts,
				courtAvailibilitySet: true,
				courtAvailabilityMemberNotes,
			});
		}
	}

	sliderHover(showArrows) {
		this.setState({ showArrows });
	}

	render() {
		//logger.log('[HomePromos] render - status1 status2 play:%o ticket:%o', play, ticket);
		let { promoData } = this.state;
		//logger.log('[HomePromos] render - state:%o', this.state);
		//logger.log('[HomePromos] render - playStatus:%o', this.props.playStatus);
		//logger.log('[HomePromos] render - promoData:%o', promoData);

		let settings = {
			dots: true,
			arrows: this.state.showArrows,
			infinite: true,
			speed: 500,
			className: `home-media`,
			autoplay: true,
		};

		if (promoData && promoData != 'error') {
			let text = promoData.text.slice(0, 3);
			let image = promoData.image.slice(0, 5);

			return (
				<>
					{text && text.length > 0 && (
						<div className="court-status">
							{text.map((i, x) => {
								return (
									<div className={`status-${x} desc h5 white`} key={`status-${x}`}>
										<WimLink name="playStatus" to={i.url} style="white" key={i.url} title={i.text}>
											<span aria-hidden>{i.text}</span>
										</WimLink>
									</div>
								);
							})}
						</div>
					)}
					{image && image.length > 0 ? (
						<div
							className="home-slider"
							id="home-slider-cntr"
							onMouseEnter={() => this.sliderHover(true)}
							tabIndex={-1}
							aria-hidden={true}
							onMouseLeave={() => this.sliderHover(false)}>
							<Slider {...settings} aria-hidden={true} tabIndex={-1}>
								{image.map((i, x) => {
									return (
										<div
											className={`imgPromo`}
											key={`imgPromo-${x}`}
											alt={i.txt}
											tabIndex={-1}
											aria-hidden={true}>
											<div className={`promoInner`}>
												<div className={`promoImg`}>
													<img
														src={i.thumbUrl}
														alt="promo image"
														tabIndex={-1}
														aria-hidden={true}
													/>
												</div>
												<div className={`promoText`} tabIndex={-1} aria-hidden={true}>
													<WimLink
														name="imgPromoLink"
														to={i.url}
														key={i.url}
														ariaHiddenElement>
														{i.text.length > 60 ? i.text.substring(0, 60) + '...' : i.text}
													</WimLink>
													{/* <hr /> */}
												</div>
											</div>
										</div>
									);
								})}
							</Slider>
						</div>
					) : (
						<div className="divider" />
					)}
				</>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePromos);

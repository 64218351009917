import deps from 'dependencies';

export default (state = {}, action) => {
    let newState;

    switch (action.type) {
        case deps.actionTypes.LOGIN_MOUNT:
            //newState = Object.assign({}, state, {...action.data});
            newState = {
                ...state,
                ...action.data,
            };
            return newState;
            break;

        case deps.actionTypes.LOGIN_UNMOUNT:
            //newState = Object.assign({}, state, {...action.data});
            newState = {
                ...state,
                ...action.data,
            };
            //logger.log('[Auth] reducer MAINNAV_UNMOUNT newState:%o', newState);
            return newState;
            break;

        default:
            return state;
    }
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Button from 'appdir/components/common-ui/Button';

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => ({
	openModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class Amex extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}

	componentDidMount() {}

	componentDidUpdate() {}

	render() {
		logger.log('[Amex] render - version:%o', this.state.version);
		return (
			<span className="amex-container">
				<div className="column-layout no-padding left right">
					<div className="two-col margin-col">
						You have indicated to us that you are an American Express Cardmember – click here to discover
						more about the benefits available to you at The Championships.
					</div>
					<div className="two-col margin-col" style={{ 'text-align': 'center' }}>
						<Button
							className={`btn solid auto center`}
							to="/en_GB/atoz/american_express.html"
							external={false}>
							AMERICAN EXPRESS AT WIMBLEDON
						</Button>
					</div>
				</div>
			</span>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Amex);

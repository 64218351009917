import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.PAGEHEADER_MOUNT:
			//logger.log('[PageHeader] PAGEHEADER_MOUNT - state:%o');
			newState = {
				...state,
				status: moment().toISOString(),
				pageHeaders: action.data,
			};
			//logger.log('[PageHeader] PAGEHEADER_MOUNT - state:%o', newState);
			return newState;
			break;

		case deps.actionTypes.PAGEHEADER_WINDOW_SIZE_SET:
			//logger.log('[PageHeader] PAGEHEADER_WINDOW_SIZE_SET - state:%o');
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[PageHeader] PAGEHEADER_WINDOW_SIZE_SET - windowSize:%o', newState.windowSize);
			return newState;
			break;

		case deps.actionTypes.PAGEHEADER_SIDEPANEL_EXPANDED:
			//logger.log('[PageHeader] PAGEHEADER_SIDEPANEL_EXPANDED - action:%o', action.data);
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;
		default:
			return state;
	}
};

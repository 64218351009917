import React from 'react';
import { useSelector } from 'react-redux';

import { measureInWeb } from 'appdir/components/general/Analytics';
import IBMwatsonx from 'appdir/components/pages/AICatchUp/elements/IBMwatsonx';
import WimLink from 'appdir/components/general/WimLink';

/** 
 * shared component b/w AI Catch Up page and AI Catch Up Home
 * 
 * @param {data.headerStyle} String ----  Optional
 * @param {data.styleClasses} String ----  Optional 
 * @param {data.pageName}      String ---- Required to pass to a measurement call
 * @param {data.hideInfoIcon} Boolean ---  Optional default is false to display the icon
 * @param {data.showRightLink} Boolean --- Optional used to show or hide the View All link, CSS also hides the element 
 * @returns 
 */
const AICatchUpHeader = props => {
	const configText = useSelector(state => state['Config']?.text?.aiCatchUp);

    const sendMetrics = () => {
        /** Catch Me Up Home is native - only send metrics for web */
		measureInWeb({
			action: 'Catch Me Up:View Alll',
			context: [],
		});
    }

    return (
        <div className="idx-header column-layout">
            <div className="idx-header-inner">
                <div className={`headtext ${props?.data.headerStyle}`}>
                    {configText?.title}
                </div>

                <IBMwatsonx styleClasses={props?.styleClasses ?? 'subtext'} />

                {
                    props?.data?.showRightLink && (
                        <div className='right-link' 
                            onClick={() => sendMetrics()}
						    aria-label={`View All`}
						    tabIndex={0}
						    onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? sendMetrics() : null)}
                        >
                            <WimLink to="/en_GB/catchup" title={configText?.title}  style="white">
                                View All
                                <i className="wim-icon-arrow-right" />
                            </WimLink>
                        </div>
                    )
                }
                
            </div>

        </div>
    )
}

export default AICatchUpHeader;
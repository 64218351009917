/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import deps from 'dependencies';
import { connect } from 'react-redux';
import { values } from 'appdir/main';

import { validateData } from 'appdir/components/general/Util';
import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import MatchBox from 'appdir/components/scoring/MatchBox';
import NewsTile from 'appdir/components/content/NewsTile';
import PlayerImage from 'appdir/components/content/PlayerImage';

/**
 * -----------------------------------------------------------------------------
 * React Component: WatchReadPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['WatchReadPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.WatchReadPage.mount()),
});

class WatchReadPage extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props, status: 'loading' };
		this.fetchData = true;
		this.initialLoad = true;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.match.params.id !== this.state.match.params.id) {
			this.setState(prevState => {
				this.fetchData = true;
				return { ...prevState, ...nextProps, relatedContentData: null, matchData: null };
			});
		} else if (this.initialLoad) {
			this.initialLoad = false;
			this.setState(prevState => {
				this.fetchData = true;
				return { ...prevState, ...nextProps };
			});
		}

		logger.log('[WatchReadPage] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	}

	componentDidUpdate(prevState) {
		let matchId = this.state.match.params.id;
		if (!this.state.matchData && !this.state.relatedContentData && this.state.path && matchId && this.fetchData) {
			this.fetchData = false;
			let matchBoxPath = this.state.path.matchBoxPath.replace('<matchId>', matchId);
			let relatedContentPath = this.state.path.relatedContentPath.replace('<matchId>', matchId);
			let path = { matchBoxPath, relatedContentPath };
			deps.services.WatchReadPage.fetchWatchReadData(path)
				.then(
					axios.spread((matchData, relatedContentData) => {
						this.setState(prevState => {
							return { ...prevState, matchData: matchData, relatedContentData: relatedContentData };
						});
					})
				)
				.catch(error => {
					logger.error('[WatchReadPage] componentWillReceiveProps - error:%o', error);
				});
		}
	}

	render() {
		logger.log('[WatchReadPage] render - status:%o state:%o', this.state.status, this.state);

		let descData = '';
		let names = '';
		let date = '';
		let playerList = undefined;

		if (this.state.matchData) {
			date = this.state.matchData.epoch * 1000;

			names =
				this.state.matchData.matches[0].team1.displayNameA +
				' vs. ' +
				this.state.matchData.matches[0].team2.displayNameA;
			if (this.state.matchData.matches[0].team1.displayNameB) {
				names =
					this.state.matchData.matches[0].team1.displayNameA +
					'/' +
					this.state.matchData.matches[0].team1.displayNameB;
				names += ' vs. ';
				names +=
					this.state.matchData.matches[0].team2.displayNameA +
					'/' +
					this.state.matchData.matches[0].team2.displayNameB;
			}

			descData = [
				this.state.matchData.matches[0].eventName,
				this.state.matchData.matches[0].roundName,
				this.state.matchData.matches[0].courtName,
			];
			descData = descData.join(', ');

			let playerArray = [];
			playerArray.push(
				this.state.matchData.matches[0].team1.firstNameA + ' ' + this.state.matchData.matches[0].team1.lastNameA
			);
			playerArray.push(
				this.state.matchData.matches[0].team2.firstNameA + ' ' + this.state.matchData.matches[0].team2.lastNameA
			);
			if (this.state.matchData.matches[0].team1.lastNameB) {
				playerArray.push(
					this.state.matchData.matches[0].team1.firstNameB +
						' ' +
						this.state.matchData.matches[0].team1.lastNameB
				);
				playerArray.push(
					this.state.matchData.matches[0].team2.firstNameB +
						' ' +
						this.state.matchData.matches[0].team2.lastNameB
				);
			}
			playerList = playerArray.join('|');
		}

		//TODO Fix Header title
		let header_propsData = {
			headerType: 'watchRead',
			titleElem: '.weather--header',
			metaTitle: `Watch and Read - ${names}`,
			metaDescription: names + ' - ' + descData,
			metaDate: date,
			metaPlayers: playerList,
		};

		if (this.state.relatedContentData) {
			if (this.state.relatedContentData.content.length > 0) {
				logger.log('[WatchReadPage] render - data:%o:', this.state.data);
				let videoContent = this.state.relatedContentData.content
					.filter(d => validateData(d, 'json') && (d.type == 'video' || d.type == 'photo'))
					.map(d => {
						return { ...d, images: d.images[0] };
					});
				let otherContent = this.state.relatedContentData.content
					.filter(d => validateData(d, 'json') && d.type != 'video' && d.type != 'photo')
					.map(d => {
						return { ...d, images: d.images[0] };
					});
				return (
					<Template>
						<Header attributes={header_propsData} />

						<PageHeader attributes={header_propsData} />
						<div className="content-main column-layout watchread">
							{this.state.matchData.matches.map((d, i) => {
								let tableHeader = `${d.roundName} ${d.courtName}`;
								// let scoreTableHeader = `${d.round} • ${d.location}`;
								return (
									<noindex>
										<MatchBox
											key={`${d.matchId}-${i}`}
											attributes={{
												data: d,
												event: d.eventName,
												tableHeader: tableHeader,
												showLinks: false,
												usePlayerImages: true,
											}}
										/>
										<div className="autoupdate-message">This scoreboard does not auto update.</div>
									</noindex>
								);
							})}
							{videoContent.length > 0 ? (
								<div className="watch-these-section-header section-header">
									<div className="header-title">View These</div>
								</div>
							) : null}
							<div className="column-layout" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
								{videoContent.map((d, i) => {
									return <NewsTile attributes={{ ...d, 'col-style': 'one-col' }} key={i} />;
								})}
							</div>
							{otherContent.length > 0 ? (
								<div className="watch-these-section-header section-header">
									<div className="header-title">Read These</div>
								</div>
							) : null}
							<div className="column-layout" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
								{otherContent.map((d, i) => {
									return <NewsTile attributes={{ ...d, 'col-style': 'one-col' }} key={i} />;
								})}
							</div>
						</div>
						<Footer />
					</Template>
				);
			} else {
				return (
					<Template>
						<Header attributes={header_propsData} />

						<PageHeader attributes={header_propsData} />
						<div className="content-main column-layout watchread">
							{this.state.matchData.matches.map((d, i) => {
								let tableHeader = `${d.roundName} ${d.courtName}`;
								return (
									<noindex>
										<MatchBox
											key={`${d.matchId}-${i}`}
											attributes={{
												data: d,
												event: d.eventName,
												tableHeader: tableHeader,
												showLinks: false,
												usePlayerImages: true,
											}}
										/>
										<div className="autoupdate-message">This scoreboard does not auto update.</div>
									</noindex>
								);
							})}
							<div
								style={{
									top: '50%',
									transform: 'translateX(-50%)',
									position: 'absolute',
									textAlign: 'center',
									width: '100%',
								}}
								className={`errorMessage show`}>
								No Content Available
							</div>
						</div>
						<Footer />
					</Template>
				);
			}
		} else {
			logger.warn('[WatchReadPage] render - watchread data not loaded');
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchReadPage);

import React, { useState } from 'react';
import { getQuerystringValues } from 'appdir/components/general/Util';
import PathToTheFinalStandalone from 'appdir/components/common-ui/PathToTheFinalStandalone';

// MS, N. Djokovic - atpd643
// MD, C. Taberner - atpte16 // out first round example
// MD, R. Rham - atpr548
// BS, P. Rodenas- atpr0h2
// LS, C. Garcia - wta315391
// LD, S. Zhang - wta311779

const init_data = {
	event: 'MS',
	id: 'atpd643',
};

const idLookUp = {
	MS: 'atpd643',
	MD: 'atpr548',
	LS: 'wta315391',
	LD: 'wta311779',
	MX: 'atpe690',
	SM: 'itf800231597', // wheelchair gentlemen's singles
	BS: 'atpr0h2',
};

/**
 * 
 * @param {*} props 
 * @returns 
 * 
 * Path to the Finals Standalone test component
 * 
 * Usage:
 * 
 * <PathToTheFianlsStandalone playerId="xxxxxx" event="XX" />
 */

const PathToTheFinalStandaloneTest = props => {

	const parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
    
    /** pass eventCode and player id to test specfic event and player
    *  the player ID needs to be defined above in idLookUp for default player ID
    *  if player ID is not passed via query string
    */
    let event = parsedQs?.event ? parsedQs.event : init_data.event;
    let id = parsedQs?.id ? parsedQs.id : idLookUp?.[event];

    const [data, setState] = useState({
                                            event,
                                            playerId: id,
                                        });
    
    const getClassName = (pid, eventCode) => {
        let classVal = '';

        if (pid == data.playerId && eventCode == data.event) {
            classVal = 'selected'
        }

        return classVal;
    }

	return (
		<div className="content-main draws draws-test-page">
            <blockquote>
                <h2>Path To The Final Standalone</h2>
                <p>Path to the final main draw paths section is rendered in the <b>&lt;PathToTheFinalStandalone &#47;&gt;</b> component.</p> <br />
                <h4>Props to pass:</h4>
                <ul>
                    <li>playerId ------------- String, Required</li>
                    <li>event ---------------- String, Required eventCode</li>
                    <li>metricsPageName --- String , Optional - passed to a measurement call, default value is 'draws'</li>
                </ul>

                <p>Click the links below to load a different Path to the Final</p>
            </blockquote>

            <ul className="list">
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'atpd643', 
                                event: 'MS'
                            });
							return false;
						}}
                        className={getClassName('atpd643', 'MS')}>
						MS - Djokovic - atpd643
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'wta323027', 
                                event: 'LS'
                            });
							return false;
						}}
                        className={getClassName('wta323027', 'LS')}>
						LS - Marketa Vondrousova - wta323027
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'atppf00', 
                                event: 'MD'
                            });
							return false;
						}}
                        className={getClassName('atppf00', 'MD')}>
						MD - Mate Pavic - atppf00
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'wta322052', 
                                event: 'LD'
                            });
							return false;
						}}
                        className={getClassName('wta322052', 'LD')}>
						LD - Caroline Dolehide - wta322052
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'atpv895', 
                                event: 'MX'
                            });
							return false;
						}}
                        className={getClassName('atpv895', 'MX')}>
						MX - Joran Vliegen - atpv895
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'wta330364', 
                                event: 'GS'
                            });
							return false;
						}}
                        className={getClassName('wta330364', 'GS')}>
						GS - Nikola Bartunkova - wta330364
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'atpwb08', 
                                event: 'QS'
                            });
							return false;
						}}
                        className={getClassName('atpwb08', 'QS')}>
						QS - Yosuke Watanuki - atpwb08
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: 'wta320238', 
                                event: 'RS'
                            });
							return false;
						}}
                        className={getClassName('wta320238', 'RS')}>
						RS - Nao Hibino - wta320238
					</a>
				</li>
				<li>
					<a
						href=""
						onClick={e => {
							e.preventDefault();
							setState({ 
                                playerId: parsedQs?.id ? parsedQs.id : idLookUp?.[event], 
                                event: parsedQs?.event ? parsedQs.event : init_data.event
                            });
							return false;
						}}
                        className={getClassName(parsedQs?.id , parsedQs?.event)}>
						from query string event and id
					</a><br/>
						Query string example: ?id=atpw0dd&event=BS
				</li>
			</ul>
            
            <PathToTheFinalStandalone {...data} />
		</div>
	);
};

export default PathToTheFinalStandaloneTest;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.LIVENAV_MOUNTED: {
			let newState = {
				...state,
				LiveNavConfig: action.data,
				status: 'loaded',
				updated: action.updated,
			};
			return newState;
			break;
		}
		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Storyteller from 'appdir/components/cms/Storyteller';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: Storyteller
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = (dispatch, props) => ({});

class SiteMapPage extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.data = {...this.props.match.params};

		let parsedQs = getQuerystringValues();
		this.data.categories = parsedQs.categories ? parsedQs.categories : '';
		this.data.title = parsedQs.title ? parsedQs.title : '';

		logger.log('[Storyteller - Page] constructor - data:%o ', this.data);
	}

	render() {
		logger.log('[Storyteller - Page] render - state:%o ', this.state);

		let header_propsData = {
			headerType: 'generic',
			title: this.data.title,
			shortTitle: this.data.title,
			metaTitle: '',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main content ">
				
					<div className="column-layout content">
						<Storyteller data={{
							title: this.data.title,
							content: this.data.type,
							categories: this.data.categories
						}} />
					</div>
				</div>

				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMapPage);

export const courtImageObj = {
    groundPass: 'https://assets.wimbledon.com/images/ticket/Grounds_328x440.jpg',
	grounds: 'https://assets.wimbledon.com/images/ticket/Grounds_328x440.jpg',
	quals: 'https://assets.wimbledon.com/images/ticket/Qualifying_328x440.jpg',
	ga: 'https://assets.wimbledon.com/images/ticket/Qualifying_328x440.jpg',
	centerCourt: 'https://assets.wimbledon.com/images/ticket/Centre-Court_328x440.jpg',
	ccourt: 'https://assets.wimbledon.com/images/ticket/Centre-Court_328x440.jpg',
	n1court: 'https://assets.wimbledon.com/images/ticket/No.1-Court_328x440.jpg',
	n2court: 'https://assets.wimbledon.com/images/ticket/No.2-Court_328x440.jpg',
	n3court: 'https://assets.wimbledon.com/images/ticket/No.3-Court_328x440.jpg',
}
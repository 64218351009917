import DrawsArchiveCountryAbbreviations from './index';
import deps from 'dependencies';

export default {
	path: '/:lang/draws_archive/country-abbreviations.html',
	exact: true,
	component: DrawsArchiveCountryAbbreviations,
	section: 'Draws',
	load: params => deps.actions.DrawsArchiveCountryAbbreviations.mount(params),
};

import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

/**
 * New Flag Time component
 * 
 * @param {*} props 
 * @returns 
 *  
 *  Check the expiry time from Config and add  flag
 *  if the updated time is newer than the expiry time
 */
const NewFlagTime = props => {
    /** expiry in seconds  */
    const contentNewTimeExpireSec = useSelector(state => state['Config']?.contentNewTimeExpireSec);
    
    // Convert epoch time from milliseconds to a moment object
    const updatedTime = moment(props?.data?.displayDate);

    // Get the current time as a moment object
    const currentTime = moment();

   const detectNewTimestamp = () => {

        if(!updatedTime) {
            return false;
        } else {
            // Calculate the difference in seconds
            const timeDifference = currentTime.diff(updatedTime, 'seconds');

            // logger.log('[NewFlagWebview] timeDifference:%o', timeDifference);
           
            // Check if the difference is within the specified interval
            return timeDifference <= contentNewTimeExpireSec;
        }
	}

    let newContent = detectNewTimestamp();

    logger.log('[NewFlagWebview] render - newContent:%o, contentNewTimeExpireSec:%o', newContent, contentNewTimeExpireSec);

    return (
        <span aria-hidden role="none" tabIndex={-1}>
            {newContent ? (
                <div className="new-tag" aria-hidden role="none" tabIndex={-1}>
                    New
                </div>
            ) : null}
        </span>
    );
}

export default NewFlagTime;
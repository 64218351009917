import React from 'react';

let callbacks = [];

const addScript = (src, cb) => {
	//logger.log('[TweetEmbedNew] - src:%o cb:%o', src, callbacks);

	if (callbacks.length === 0) {
		callbacks.push(cb);
		let s = document.createElement('script');
		s.setAttribute('src', src);
		s.setAttribute('id', 'twitterWidget');
		//s.async = true;
		s.onload = () => callbacks.forEach(cb => cb());

		//logger.log('[TwitterEmbed] - body:%o', document.body);

		document.body.appendChild(s);
	} else {
		callbacks.push(cb);
	}
};

const removeScript = cb => {
	let s = document.getElementById('twitterWidget');
	//logger.log('[TwitterEmbed] removeScript - script:%o', s);
	document.body.removeChild(s);
	callbacks = [];
	cb();
};

const addWidgetJs = () => {
	const isLocal = window.location.protocol.indexOf('file') >= 0;
	const protocol = isLocal ? this.props.protocol : '';

	logger.log('[TwitterEmbed] addWidgetJs');

	addScript(`${protocol}//platform.twitter.com/widgets.js`, renderTweet);
};

const renderTweet = () => {
	//logger.log('[TwittertEmbed] - renderTweet');
};

export function loadTwitter() {
	// logger.log('[TwitterEmbed] loadTwitter - twttr:%o ready:%o', window.twttr, window.twttr.ready);

	try {
		removeScript();
	} catch (e) {}

	addWidgetJs();
}

export function removeTwitter() {
	logger.log('[TwitterEmbed] removeTwitter - twttr:%o', window.twttr);

	try {
		removeScript();
	} catch (e) {}
}

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, {useState} from 'react';
import WimLink from 'appdir/components/general/WimLink';
import NewFlag from 'appdir/components/common-ui/NewFlag';
import FullScreenVideo from 'appdir/components/common-ui/FullScreenVideo';
import Stripes from 'appdir/components/common-ui/Stripes';
import ContentDate from 'appdir/components/cms/ContentDate/index.js';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ContentLink
 * -----------------------------------------------------------------------------
 */
// const externalLinkRegex = /^(http|https)/;

const ContentLink = props => {
	//logger.log('[ContentLink] - props:%o', props);
	// let attributes = props['attributes'][props.id];
	const [vidOpen, openVideo] = useState(false);
	let attributes = props.data;
	logger.log('[ContentLink] - attr:%o', attributes);

	const onClick = () => {
		vidOpen ? openVideo(false) : openVideo(true);
	}

	const closeVid = () => {
		openVideo(false);
	}

	const genLayout = (attributes, image) => {
		switch (attributes.layout) {	
			case "date":
				/** "With Date" display */
				let dateArr = attributes['date'].split(" ");
				return(
					<>
						<div className="content-link-image">
							{attributes['contentType'] == 'video' && <i className="wim-icon-video" />}
							<img src={image['url']} aria-hidden tabIndex={-1} role="none" />
						</div>
						<div className='content-date center'>
							<h6 aria-hidden role="none" tabIndex={-1}>
								<ContentDate date={dateArr[0]} tz={dateArr[1]} />
							</h6>
						</div>
						<div className="content-link-title center" aria-hidden tabIndex={-1} role="none">
							{attributes['text']}
						</div>
						<hr className="purpleStripe-short" />
						<div className='content-link-description'>
							{attributes['description']}
						</div>
					</>
				)
				break;
			case "full": 
					
				return (
					<div className='content-link-full'>
						<div className="content-link-image">
							{attributes['contentType'] == 'video' && <i className="wim-icon-video" />}
							<img src={image['url']} aria-hidden tabIndex={-1} role="none" />
							<div className="content-link-title" aria-hidden tabIndex={-1} role="none">
								<span className='text'>{attributes['text']}</span>
								<Stripes color="white" short={true} />
								<span className='description'>{attributes['description']}</span>
							</div>
						</div>						
					</div>
				);
				break;
			default: 
				/** "Title only" display */
				return(
					<>
						<div className="content-link-image">
							{attributes['contentType'] == 'video' && <i className="wim-icon-video" />}
							<img src={image['url']} aria-hidden tabIndex={-1} role="none" />
						</div>
						<div className="content-link-title" aria-hidden tabIndex={-1} role="none">
							{attributes['text']}
						</div>
					</>
				)
				break;
		}
	}

	if (attributes && attributes.images) {
		let image = attributes['images']['image'];

		return (
			<>
			<div className={`content-link ${attributes['style']} ${attributes['layout']}`}>
				<NewFlag
					data={{
						url: attributes['url'], 
						contentId: attributes['contentId'], 
						displayDate: attributes['date'], 
					}}
				/>
				{attributes['contentType'] == 'video' ? (
					<div
						// className="news-tile-inner"
						id={attributes['contentId']}
						onClick={() => onClick()}
						aria-label={`video, ${attributes['text']}`}
						tabIndex={0}
						onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? onClick() : null)}>
						{genLayout(attributes, image)}
					</div>
				) : (
					<WimLink to={`${attributes['url']}`} title={attributes['text']}>
						{genLayout(attributes, image)}
					</WimLink>
				)}
				
			</div>
			{attributes['contentType'] == 'video' && vidOpen && <FullScreenVideo closeVid={() => closeVid()} attributes={{videoId: attributes['contentId']}} />}
			</>
		);
	} else {
		return null;
	}
};

export default ContentLink;

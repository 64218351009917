import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetchRelatedContentComp = path => {
	let hdr = restHeaders();
	//path = "http://www.wimbledon.com/relatedcontent/rest/wim_2017/en/tags/1403?total=4&startdate=2017-01-01&";
	//path = 'http://www-cdt.wimbledon.com' + path;

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			logger.log('[RelatedContent] loadRelatedContent: response:%o', response);
			return response;
		})
		.then(({ data }) => data);
	// return axios.get(path, {headers: hdr}).then(({data}) => data);
};

export default {
	fetchRelatedContentComp,
};

import React, { Component } from 'react';
import { PlayerDigitalExperienceContext } from '../context';
import op from 'object-path';
import { getContent } from 'appdir/components/general/Util/Services';
import { loggedIn, checkRole, getRole } from 'appdir/components/general/Util/Role';
import Time from 'appdir/components/common-ui/Time';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import WimLink from 'appdir/components/general/WimLink';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getParams } from './Utils.js';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
	unmount: () => dispatch(deps.actions.PlayerDigitalExperience.unmount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
});

class PlayerCatering extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			gotData: false,
		};
		this.fetchData = true;

		this.handleSubmit = this.handleSubmit.bind(this);

		logger.log('[PDE - PlayerCatering] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log('[PDE - PlayerCatering] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'currentUser', {});
		let roleValidated = checkRole(op.get(currentUser, 'roles', []), op.get(this.state, 'roleIds', []));
		let playerRole = getRole(op.get(currentUser, 'roles', []), 'player');
		let contactDetails = op.get(playerRole, 'contactDetails', null);

		logger.log(
			'[PDE - PlayerCatering] componentDidUpdate - user:%o state:%o roleValidated:%o',
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			roleValidated
		);

		// logger.log('[PDE - PlayerCatering] componentDidUpdate - state:%o context:%o', this.state, this.context);
		if (loggedIn(currentUser) && roleValidated && contactDetails) {
			if (this.state.dataUrl && !this.state.gotData && this.fetchData) {
				this.fetchData = false;
				let playerCreds = this.state.currentUser.roles.find(ele => ele.key === 'player');
				let contactDetails = op.get(playerCreds, 'contactDetails', null);
				if (Object.keys(contactDetails).length > 0) {
					this.props
						.getJWT()
						.then(token => {
							logger.log(
								'[PDE - PlayerCatering] componentDidUpdate - token:%o, dataUrl:%o',
								token.jwt,
								this.state.dataUrl
							);
							let nullError = false;
							let params = getParams('catering', { ...this.state });
							getContent(this.state.dataUrl, 'get', token.jwt.id_token, nullError, params)
								.then(data => {
									logger.log(
										'[PDE - PlayerCatering] componentDidUpdate - data:%o params:%o',
										data,
										params
									);
									this.setState({ data: data, gotData: true, todaysDate: params.date });
								})
								.catch(data => {
									this.setState({ data: 'error', gotData: true });
								});
						})
						.catch(error => {
							this.setState({ data: 'error', gotData: true });
							logger.error('[PDE - PlayerCatering] componentDidUpdate - error: %o', error);
						});
				} else {
					this.setState({
						data: {},
						gotData: true,
					});
				}
			}
		}
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		//logger.log('[PDE - PlayerCatering] handleSubmit - state:%o', values);
	}

	getDateSubstring(dtString) {
		return moment(dtString.substr(0, 10)).valueOf();
	}

	getTodaysTransactions(transactions, todaysDate) {
		let currentDateString = todaysDate;
		let currentDate = moment(currentDateString).valueOf();
		let sortedTransactions = transactions
			.map(d => {
				let dateString = d.TransactionDate.substr(0, 10);
				return {
					...d,
					timestamp: moment(d.TransactionDate).valueOf(),
					date: this.getDateSubstring(dateString),
				};
			})
			.sort((a, b) => {
				return b.timestamp - a.timestamp;
			})
			.filter(d => {
				let dateTimestamp = d.date;
				return dateTimestamp === currentDate;
			});

		sortedTransactions = Object.values(groupBy(sortedTransactions, 'RowNumber'));
		if (!isEmpty(sortedTransactions)) {
			sortedTransactions = sortedTransactions.map(item => {
				let helper = {};
				return item.reduce(function(r, o) {
					let key = o.ItemDescription.replace(/ /g, '') + '_' + o.AmountOut;
					if (!helper[key]) {
						helper[key] = Object.assign({}, o);
						helper[key].count = 1;
						r.push(helper[key]);
					} else {
						helper[key].count += 1;
					}

					return r;
				}, []);
			});
		}
		return sortedTransactions;
	}

	noCateringData() {
		if (op.get(this.state, 'data.response.StatementItems', []).length === 0) {
			return true;
		} else {
			return false;
		}
	}

	cateringErrorData() {
		let statementItems = op.get(this.state, 'data.response.StatementItems', null);
		let result = op.get(this.state, 'data.response.Result', false);
		return statementItems == null || result == false;
	}

	showStub() {
		const caterStubMsg = op.get(this.state, 'stubs.pde.catering.text', null);
		return (
			<div className="four-col bank-info-container">
				<div className="section-container-no-data">
					<div className="row prize-money-explanation">{caterStubMsg}</div>
				</div>
			</div>
		);
	}

	renderCatering() {
		let date = op.get(this.state, 'todaysDate', null);

		if (this.state.data['message'] !== 'OK' || this.state.data === 'error' || this.cateringErrorData()) {
			return (
				<div className="four-col bank-info-container">
					<div className="section-container-no-data">
						<div className="row prize-money-explanation">
							The information you are requesting is currently unavailable. Please try again later or
							contact us on{' '}
							<WimLink to="mailto:players@aeltc.com" external={true}>
								players@aeltc.com
							</WimLink>
							.
						</div>
					</div>
				</div>
			);
		} else if (date) {
			let statementItems = op.get(this.state, 'data.response.StatementItems', null);
			let latestTrans = this.getTodaysTransactions([...statementItems], date);
			logger.log('[PDE - PlayerCatering] render - latestTrans:%o', latestTrans);
			return (
				<>
					<div className="catering-header four-col">
						<div className="green-text top-header header-row">
							Food Allowance Statement :{' '}
							<span className="bold">
								<Time epoch_string={this.state.todaysDate} format="DD/MM/YYYY" options="upper" />
							</span>
						</div>
						<div className="black-text bold header-row">
							Your daily allowance is £{this.state.data.response.FreeSpendTotal.toFixed(2)}
						</div>
						<div className="green-text bold header-row">
							Current balance: £{this.state.data.response.FreeSpendBalance.toFixed(2)}
						</div>
						<div className="green-text bold header-row">
							Today's spend: £
							{(
								this.state.data.response.FreeSpendTotal - this.state.data.response.FreeSpendBalance
							).toFixed(2)}
						</div>
					</div>
					<div className="transactions">
						{latestTrans.length > 0 ? (
							latestTrans.map((transaction, i) => {
								let hourAndMinIndex = this.state.data.response.LastSpend.indexOf('T');
								let hourAndMin = transaction[0].TransactionDate.substr(hourAndMinIndex + 1, 5);
								return (
									<div className="transaction" key={`${transaction[0].PosDescription}-${i}`}>
										<div className="row column-layout time-amount">
											<div className="row-header catering">
												{`Location/Time: `}
												<b>{`${transaction[0].PosDescription} at ${hourAndMin}`}</b>
											</div>
											<div className="transaction-total">
												Amount: <b>{`£${transaction[0].AmountOut.toFixed(2)}`}</b>
											</div>
										</div>
										<div className="row items">
											<span>Purchase:</span>
											<ul role="list">
												{transaction.map((d, i) => {
													let quanIndex = d.ItemDescription.indexOf('x');
													let costIndex = d.ItemDescription.indexOf('@');
													let quantity = d.ItemDescription.substr(0, quanIndex).trim();
													let itemDescription = d.ItemDescription.substr(
														quanIndex + 1,
														costIndex - quanIndex - 1
													).trim();
													let cost = d.ItemDescription.substr(costIndex + 1).trim();
													return <li className="listed-item">{`${d.ItemDescription}`}</li>;
												})}
											</ul>
										</div>
									</div>
								);
							})
						) : (
							<div className="four-col bank-info-container">
								<div className="section-container-no-data">
									<div className="row prize-money-explanation">No transactions available today</div>
								</div>
							</div>
						)}
					</div>
				</>
			);
		} else {
			return null;
		}
	}

	/** */
	render() {
		logger.log('[PDE - PlayerCatering] render - state:%o status:%o', this.state, this.state.status);
		const showStub = op.get(this.state, 'stubs.pde.catering.stub', false);

		if (!this.state.gotData) {
			return <LoadingIndicator />;
		} else {
			if (!showStub) {
				return this.renderCatering();
			} else {
				return this.showStub();
			}
		}
	}
}

PlayerCatering.contextType = PlayerDigitalExperienceContext;
export default connect(mapStateToProps, mapDispatchToProps)(PlayerCatering);

import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.PLAYER_MOUNT:
			newState = {
				...state,
				playerPath: action.data.playerPath,
				playerImage: action.data.playerImage,
				status: moment().toISOString(),
			};
			return newState;
			break;
		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactEcp from 'react-ecp';

/**
 * -----------------------------------------------------------------------------
 * React Component: RadioPlayer
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['RadioPlayer'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class RadioPlayer extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		logger.log('[RadioPlayer] componentDidMount this.props:%o', this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[RadioPlayer] componentDidUpdate state:%o', this.state);
	}

	onReady(player) {
		logger.log('[RadioPlayer] onReady - player:%o', player);
		this.player = player;

		if (this.props.onReady) {
			this.props.onReady(player, this.props.videoConfig);
		}
	}

	onStart() {
		logger.log('[RadioPlayer] onStart props:%o player:%o', this.props, this.player);

		if (this.props.onStart) {
			this.props.onStart(this.props.videoConfig);
		}
	}

	onPlay() {
		logger.log('[RadioPlayer] onPlay props:%o player:%o amp:%o', this.props, this.player, this.player.amp);

		if (this.props.onPlay) {
			this.props.onPlay(this.props.videoConfig);
		}

		if (this.player.amp.hls) {
			this.player.amp.hls.instance.startLoad(-1);
		}
	}

	onPause() {
		logger.log('[RadioPlayer] onPause props:%o', this.props);

		if (this.props.onPause) {
			this.props.onPause(this.props.videoConfig);
		}

		//logger.log('[RadioPlayer] onause props:%o hls:%o', this.props, this.player.amp.hls);

		if (this.player.amp.hls) {
			this.player.amp.hls.instance.stopLoad();
		}
	}

	render() {
		logger.log('[RadioPlayer] render props:%o', this.props);

		let { id, playerConfig, videoConfig, colors } = this.props;

		return playerConfig && videoConfig && colors && id ? (
			<ReactEcp
				id={id}
				ampBasePath="/libs/"
				playerConfig={playerConfig}
				videoConfig={videoConfig}
				onReady={amp => this.onReady(amp)}
				onStart={() => this.onStart()}
				onPlay={() => this.onPlay()}
				onPause={() => this.onPause()}
				colors={colors}
			/>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioPlayer);

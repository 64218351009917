import axios from 'axios';
//import Messaging from '../../lib/mqttws31';
import Paho from 'paho-mqtt';
import { logger } from '../../logger';
import { ScoringConnection } from '../scoringConnection';
var pako = require('pako');

/**
 * Expose our createNew method to connect with!
 */
export class WebSocketConnection extends ScoringConnection {
	constructor(connectionProperties) {
		super();
		this.connectionProperties = connectionProperties;
		this.mqttClient = null;
		this.currentMatches = [];
		this.currentScoreSubscriptions = [];
		this.currentStatsMatches = [];
		this.currentTopics = [];
		//logger.log('[WebSocketConnection] - constructor connectionProperties:%o', this.connectionProperties);
	}

	createNew(connectionProperties = {}) {
		this.connectionProperties = this.validateProperites(connectionProperties);

		// logger.log('[WebSocketConnection] - calling connectionReady');
		this.connectionProperties.connectionReady(true);
	}

	start() {
		logger.log(
			'[WebSocketConnection] start, this.currentStatsMatches:%o',
			window.localStorage.getItem('ixScoringMatches')
		);
		this.initMessageSightConnectivity(this.connectionProperties);
	}

	stop() {
		logger.log('[WebSocketConnection] stop');
		this.currentTopics.forEach(currentTopic => {
			this.removeTopicRegistration(currentTopic);
		});
		this.currentTopics = [];
		this.currentMatches = [];
		this.currentStatsMatches = [];
		this.currentScoreSubscriptions = [];
		if (window.localStorage) {
			window.localStorage.removeItem('ixScoringMatches');
		}
		this.connectionProperties.connectionReady(false);
	}

	/**
	 * This module is called when we need to get stats for a match
	 *
	 * 1. Check that this.currentStatsMatches doesn't already have an active subscription for this match id
	 * 2. If not, add the topic subscriptions
	 * 3. If it does, do nothing
	 */
	addSlamtrackerForMatch(data) {
		logger.info(
			'[WebSocketConnection] addSlamtrackerForMatch - data:%o, currentStatsMatches:%o',
			data,
			this.currentStatsMatches
		);

		let foundMatches = this.currentStatsMatches.filter(match => {
			return match.matchId == data.matchId;
		});
		if (foundMatches.length == 0) {
			logger.info(
				'[WebSocketConnection] addSlamtrackerForMatch - adding stats subscription. data:%o, match:%o, connectionProperties:%o',
				data,
				data.matchId,
				this.connectionProperties
			);
			this.currentStatsMatches.push(data);
			if (window.localStorage) {
				window.localStorage.setItem('ixScoringMatches', JSON.stringify(this.currentStatsMatches));
			}

			this.addTopicRegistration(
				`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/stat/${data.matchId}`
			);

			if (data.statsLevel == 'K' || data.statsLevel == 'H') {
				logger.info(
					'[WebSocketConnection] addSlamtrackerForMatch - currentStatsMatches:%o',
					this.currentStatsMatches
				);

				return axios
					.get(this.connectionProperties.cPath.replace('<matchId>', data.matchId))
					.then(response => {
						if (response.status === 200) {
							// logger.log('cData response: %o', response);
							this.connectionProperties.onHistoryUpdate(response.data);
						}
					})
					.then(() => {
						logger.info(
							'[WebSocketConnection] addSlamtrackerForMatch - adding slamtracker subscription for match %o',
							data.matchId
						);
						this.addTopicRegistration(
							`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/slamtracker/${data.matchId}`
						);
					})
					.catch(error => {
						logger.log('[WebSocketConnection] fetch C file - error:%o', error);
						if (error.response && error.response.status == 404) {
							logger.log('[WebSocketConnection] fetch C file - error, file not found:%o', error.response);
							throw error.response;
						} else {
							logger.log('[WebSocketConnection] fetch C file - error, general http erorr:%o', error);
							throw error;
						}
					});
			}
		}
		logger.info('[WebSocketConnection] addSlamtrackerForMatch - currentStatsMatches:%o', this.currentStatsMatches);
	}

	/**
	 *
	 * This module is called when we need to stop calling stats data for a given match
	 *
	 * 1. Check that this.currentStatsMatches has an active subscription for this match id
	 * 2. If it does, add remove topic subscription
	 * 3. It it doesn't, do nothing
	 */
	removeSlamtrackerForMatch(data) {
		logger.info(
			'[WebSocketConnection] removeStatsForMatch  - currentStatsMatches:%o, matchId:%o',
			this.currentStatsMatches,
			data.matchId
		);
		let foundMatches = this.currentStatsMatches.filter(match => {
			return match.matchId == data.matchId;
		});
		if (foundMatches.length == 1) {
			logger.info(
				'[WebSocketConnection] removeStatsForMatch  - removing stats subscription for match %o',
				data.matchId
			);
			this.currentStatsMatches = this.currentStatsMatches.filter(match => {
				return match.matchId != data.matchId;
			});

			if (window.localStorage) {
				window.localStorage.setItem('ixScoringMatches', JSON.stringify(this.currentStatsMatches));
			}
			this.removeTopicRegistration(
				`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/stat/${data.matchId}`
			);
			this.removeTopicRegistration(
				`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/slamtracker/${data.matchId}`
			);
		}
		logger.info('[WebSocketConnection] removeStatsForMatch  - currentStatsMatches:%o', this.currentStatsMatches);
	}

	initMessageSightConnectivity(connectionProperties) {
		logger.info('[WebSocketConnection] initMessageSightConnectivity - props:%o', connectionProperties);

		// Create an MQTT WebSocket Client
		// this.mqttClient = new Messaging.Client(
		// 	connectionProperties.hostname,
		// 	connectionProperties.port,
		// 	connectionProperties.path,
		// 	connectionProperties.clientId
		// );
		//this.mqttClient = new Paho.MQTT.Client(connectionProperties.hostname, Number(connectionProperties.hostname), connectionProperties.clientId);
		this.mqttClient = new Paho.Client(
			connectionProperties.hostname,
			connectionProperties.port,
			connectionProperties.clientId
		);

		logger.info('[WebSocketConnection] initMessageSightConnectivity - mqttClient:%o', this.mqttClient);

		// Set-up the handler for when we receive data
		this.mqttClient.onMessageArrived = message => {
			let messageData = JSON.parse(pako.inflate(message.payloadBytes, { to: 'string' }));
			// logger.info('[WebSocketConnection] - Received data %o', messageData);

			// Pick the right processing method based on the topic (destinationName)
			if (
				message.destinationName.indexOf(`${connectionProperties.year}/${connectionProperties.event}/mip`) > -1
			) {
				this.processMipMessage(connectionProperties.year, connectionProperties.event, messageData);

				// Tell the controller we have a data update!
				connectionProperties.onDataUpdate(this.currentMatches);
			} else if (
				message.destinationName.indexOf(`${connectionProperties.year}/${connectionProperties.event}/score/`) >
				-1
			) {
				// logger.info('[WebSocketConnection] - Need to process a score');
				this.processScore(messageData);

				// Tell the controller we have a data update!
				connectionProperties.onDataUpdate(this.currentMatches);
			} else if (
				message.destinationName.indexOf(`${connectionProperties.year}/${connectionProperties.event}/stat/`) > -1
			) {
				// logger.info('[WebSocketConnection] - Need to process a stat update');

				// Tell the controller we have a stats update!
				connectionProperties.onStatsUpdate(messageData);
			} else if (
				message.destinationName.indexOf(
					`${connectionProperties.year}/${connectionProperties.event}/slamtracker/`
				) > -1
			) {
				// logger.info('[WebSocketConnection] - Need to process a point history update');

				// Tell the controller we have a point history update!
				connectionProperties.onHistoryUpdate(messageData);
			}
		};

		// Alert when we lose connectivity
		this.mqttClient.onConnectionLost = error => {
			logger.error('[WebSocketConnection] Error received from WebSocket %o', error);
			// connection has been lost.  re-initialize vars
			this.currentTopics = [];
			this.currentMatches = [];
			this.currentScoreSubscriptions = [];
			connectionProperties.onError(error);
		};

		// Run our connect call
		this.mqttClient.connect({
			cleanSession: true,
			useSSL: true,
			keepAliveInterval: 60,
			onSuccess: () => {
				logger.info('[WebSocketConnection] - Connected, creating mip subscription');
				logger.info(
					'[WebSocketConnection] - topic - ',
					`events/tennis/${connectionProperties.year}/${connectionProperties.event}/mip`
				);
				this.addTopicRegistration(
					`events/tennis/${connectionProperties.year}/${connectionProperties.event}/mip`
				);
				if (window.localStorage) {
					let data = window.localStorage.getItem('ixScoringMatches');
					if (typeof data == 'string' && data.length > 0) {
						let storedMatches = JSON.parse(data);

						if (storedMatches.length > 0) {
							storedMatches.forEach(statsMatch => {
								this.addSlamtrackerForMatch(statsMatch);
							});
						}
					}
				}

				// logger.info('[WebSocketConnection] - Connected, created mip subscription');
			},
			onFailure: ({ errorMessage }) => {
				logger.info('[WebSocketConnection] - Connection failed');
				// connection has failed. re-initialize vars to empty array
				this.currentTopics = [];
				this.currentMatches = [];
				this.currentScoreSubscriptions = [];
				connectionProperties.onError(errorMessage);
			},
		});
	}

	validateProperites(connectionProperties) {
		if (connectionProperties.clientId == undefined) {
			logger.error('[WebSocketConnection] - Error, no clientId supplied.');
			throw 'ClientId for connection is required';
		}
		if (connectionProperties.hostname == undefined) {
			logger.error('[WebSocketConnection] - Error, no hostname supplied.');
			throw 'Hostname for connection is required';
		}
		if (connectionProperties.port == undefined || isNaN(connectionProperties.port)) {
			logger.info('[WebSocketConnection] - No port supplied, defaulting to 443.');
			connectionProperties.port = 443;
		}

		if (connectionProperties.onDataUpdate == undefined) {
			logger.info('[WebSocketConnection] - No onDataUpdate supplied, defaulting to no-op function.');
			connectionProperties.onDataUpdate = data => {};
		}

		if (connectionProperties.onStatsUpdate == undefined) {
			logger.info('[WebSocketConnection] - No onStatsUpdate supplied, defaulting to no-op function.');
			connectionProperties.onStatsUpdate = data => {};
		}

		if (connectionProperties.onHistoryUpdate == undefined) {
			logger.info('[WebSocketConnection] - No onHistoryUpdate supplied, defaulting to no-op function.');
			connectionProperties.onHistoryUpdate = data => {};
		}

		if (connectionProperties.connectionReady == undefined) {
			logger.info('[WebSocketConnection] - No connectionReady supplied, defaulting to no-op function.');
			throw 'connectionReady is required';
		}

		return connectionProperties;
	}

	processMipMessage(year, event, mipData) {
		let matchesOnCourt = [];
		// Add matches we don't already have!

		mipData.matches.forEach(mipMatch => {
			let match = this.currentMatches.filter(currentMatch => {
				return currentMatch.match_id == mipMatch.match_id;
			});
			if (match.length == 0) {
				// new match so add it in and update this to have a score subscription..
				matchesOnCourt.push(mipMatch);
			} else {
				// We already have this match, cover over as is..
				matchesOnCourt.push(match[0]);
			}
		});

		// Set back into the global variable asap to avoid race conditions...
		this.currentMatches = matchesOnCourt;

		// Let's loop through and add any new subscriptions we should add
		this.currentMatches.forEach(currentMatch => {
			if (this.currentScoreSubscriptions.indexOf(currentMatch.match_id) == -1) {
				// We need to add this one!
				this.currentScoreSubscriptions.push(currentMatch.match_id);
				this.addTopicRegistration(`events/tennis/${year}/${event}/score/${currentMatch.match_id}`);
			}
		});

		// Now lets work out if we need to take matches off court
		let matchIdsToRemove = [];
		this.currentScoreSubscriptions.forEach(currentSubscription => {
			let foundMatch = this.currentMatches.filter(currentMatch => {
				return currentMatch.match_id == currentSubscription;
			});
			if (foundMatch.length == 0) {
				matchIdsToRemove.push(currentSubscription);
			}
		});

		// If we found some to remove then remove them...
		if (matchIdsToRemove.length > 0) {
			// We need to remove some matches...
			matchIdsToRemove.forEach(id => {
				this.currentScoreSubscriptions = this.currentScoreSubscriptions.filter(subscription => {
					return subscription != id;
				});
				this.removeTopicRegistration(`events/tennis/${year}/${event}/score/${id}`);
			});
		}

		// Let's log our our current state for helpfulness
		logger.log('[WebSocketConnection] - MIP update, match ids on court are %o', this.currentMatches);
	}

	processScore(scoreData) {
		//logger.info('[WebSocketConnection] - processScore %o', scoreData);
		for (var i = 0; i < this.currentMatches.length; i++) {
			if (this.currentMatches[i].match_id == scoreData.match_id) {
				this.currentMatches[i] = scoreData;
			}
		}
	}

	processStats(statsData) {
		/**
		 * // TODO Amy/Robin
		 *
		 * 1. Decide if we need to locally store stats in this object or just return them, we stored the MIP/Score data so we could return all courts, do we need that here?
		 * 2. Once decided on 1. this method should call connectionProperties.onStatsUpdate(statsData); to pass the data backup
		 */
		// logger.info('[WebSocketConnection] - processStats %o', statsData);
	}

	addTopicRegistration(topic) {
		// logger.info('[WebSocketConnection] - Adding topic %o', topic);
		this.mqttClient.subscribe(topic);
		this.currentTopics.push(topic);
		// logger.info('[WebSocketConnection] - Added topic %o', topic);
	}

	removeTopicRegistration(topic) {
		this.mqttClient.unsubscribe(topic);
		logger.info('[WebSocketConnection] - Removing topic %o', topic);
	}
}

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.CONFIRMATION_MODAL_TOGGLE:
			if (!action?.data?.code) {
				action.data.code = null;
			}
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[ConfirmationModal] CONFIRMATION_MODAL_TOGGLE - newState:%o', JSON.stringify(newState));
			return newState;
			break;
		case deps.actionTypes.CONFIRMATION_MODAL_LOADING:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;
		case deps.actionTypes.CONFIRMATION_MODAL_UNUSE_TIMER:
			newState = {
				...state,
				useTimer: false,
			};
			return newState;
			break;
		default:
			return state;
	}
};

import LiveScores from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: ['/:lang/scores/index.html', '/:lang/scores/', '/:lang/scores'],
	exact: true,
	component: LiveScores,
	section: MENU_ITEMS.scoresSchedule,
	load: params => deps.actions.LiveScores.mount(params),
};

import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';

const shareUrls = {
	"Mail": {
		"label": "Share via Email",
		"path": "mailto:?subject=<email_title>&body=<email_text><email_footer>"
	},
	"Facebook": {
		"label": "Share on Facebook",
		"path": "https://www.facebook.com/sharer/sharer.php?u=<url>"
	},
	"Twitter": {
		"label": "Tweet",
		"path": "https://twitter.com/share?url=<url>&text=<title>"
	},
	"Google": {
		"label": "Share on Google+",
		"path": "https://plus.google.com/share?url=<url>"
	},
	"Copy": {
		"label": "Share via Link",
		"path": "<share_url>"
	}
}

const line_break = '%0D%0A';

export default class ShareOverlay extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			open: this.props.open
		}
		
		this.styles = props.styles;
		this.shareTime = React.createRef();

		let app_strings = '';
        for (let i = 0; i < this.props.appStrings.length; i++) {
            app_strings += this.props.appStrings[i][0] + encodeURIComponent(this.props.appStrings[i][1]) + line_break;
        }
		this.share_footer = app_strings;
		this.showShareAtTime = this.showShareAtTime.bind(this);

		//logger.log('ShareOverlay: constructor - state:%o footer:%o', this.state, this.share_footer);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			open: nextProps.open,
			copyOpen: (!nextProps.open) ? false : this.state.copyOpen
		})
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('ShareOverlay: componentDidUpdate - state:%o props.smallView:%o', this.state, this.props.smallView);
	}

	onCloseClick(e) {
		logger.log('ShareOverlay: onCloseClick - e:%o', e.currentTarget);

		if (this.props.onCloseClick) {
			this.props.onCloseClick();
		}

		this.setState({
			copyOpen: false
		});
	}

	onLinkClick(e, url) {
		//logger.log('ShareOverlay: onLinkClick - e:%o', e.currentTarget.id);

		if (e.currentTarget.id != 'link_Copy') {
			e.preventDefault();
			e.stopPropagation();
			
			this.openSocialWindow(url);
			if (this.props.onShareLinkClick) {
				this.props.onShareLinkClick( e.currentTarget.dataset.track);
			}

			this.setState({
				open: false,
				copyOpen: false
			});
		}
		else if (e.currentTarget.id == 'link_Copy') {
			e.preventDefault();
			e.stopPropagation();
			this.setState({
				copyOpen: !this.state.copyOpen
			});
		}
	}

	setSelection(target) {
		target.setSelectionRange(0, 9999);
	}

	onCopyLink(e) {
		logger.log('ShareOverlay: onCopyLink - e:%o', e.currentTarget);
		e.currentTarget.focus();
		this.setSelection(e.currentTarget);
		document.execCommand('copy');

		e.currentTarget.value = 'link copied';

		// do not close shar eoverlay based on Masters 19 request
		if (this.props.onShareLinkClick) {
			this.props.onShareLinkClick('Share:Copy');
		}
		// this.setState({
		// 	open: false,
		// 	copyOpen: false
		// });
	}
	

	openSocialWindow(href) {
		//logger.info('openSocialWindow - url:%o', href);
		let win = window.open(href, '_blank', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');

		let checkBlank = (openWin) => {
			try {
				if (openWin.location.hostname !== undefined && openWin.location.hostname === '') {
					// means empty frame, can close
					openWin.close();
				}
			} catch (e) {
				// do nothing
			}
		};

		window.setTimeout(checkBlank.bind(this, win), 4000);
	};

	showShareAtTime(e){
		if(!e.target.checked){
			this.shareTime.current.value = '';
		}
		this.props.showShareAtTime(e.target.checked);
	}

	getUrl(type) {
		//logger.log('ShareOverlay: getUrl - type:%o', type);

		var enc_url = window.encodeURIComponent(this.props.shareUrl);
		var enc_title = window.encodeURIComponent(this.props.shareTitle);
		var enc_email_title = window.encodeURIComponent(this.props.shareTitle);
		var email_text = enc_email_title + line_break + line_break + enc_url + line_break + line_break;

		let item = shareUrls[type];
		let href = item.path.replace('<url>', enc_url);
		href = href.replace('<title>', enc_title);
		href = href.replace('<email_title>', enc_email_title);
		href = href.replace('<email_text>', email_text);
		href = href.replace('<email_footer>', this.share_footer);
		href = href.replace('<share_url>', this.props.shareUrl);

		//logger.log('ShareOverlay: getUrl - href:%o', href);
		return href;
	}

	
	render() {
		//logger.log('ShareOverlay: render - state:%o props:%o', this.state, this.props);
		let inline = {};
		let offset = {};
		let show_state = '';
		let copy_state = '';

		let shareClasses = [this.styles.shareOverlayWrap];
		  
		if (this.props.smallView) { 
			shareClasses.push(this.styles.small); 
		}
		
		if(this.props.first) {
			inline = {
				margin: "0 0 0 10px"
			}
		}
		if (this.props.offsetRight > -1 && !this.props.smallView) {
			offset = {
				right: `${(40 * this.props.offsetRight)}px`
			}
		}

		if (this.state.copyOpen) {
			copy_state = 'open'
		}
		if (this.props.open) {
			show_state = 'show'
		}

		if (this.props.shareUrl == '') {
			return null
		}
		else {
			return (
				<div className={shareClasses.join(' ')} style={offset} data-state={show_state}>
					<div className={`${this.styles.shareOverlay}`} data-state={show_state}>
						{this.props.enableShareAtTime && <div className={this.styles.shareAtTime}>
							<input type="checkbox" className={this.styles.shareCheck} onClick={(e) => this.showShareAtTime(e)} />
							<span className={this.styles.startAt}>Start at:</span>
							<input className={this.styles.shareTime} type="text" placeholder={this.props.convertedTime} onChange={(e) => this.props.setShareTime(e)} ref={this.shareTime} />
							<hr className={this.styles.hr} />
						</div>}
						<span className={`${this.styles.mail} ${this.styles.shareItem}`}>
							<span id="link_Mail" data-track="Share:Mail" onClick={(e) => this.onLinkClick(e, this.getUrl('Mail'))}></span>
						</span>
						<span className={`${this.styles.facebook} ${this.styles.shareItem}`}>
							<span id="link_Facebook" data-track="Share:Facebook" onClick={(e) => this.onLinkClick(e, this.getUrl('Facebook'))}></span>
						</span>
						<span className={`${this.styles.twitter} ${this.styles.shareItem}`}>
							<span id="link_Twitter" data-track="Share:Twitter" onClick={(e) => this.onLinkClick(e, this.getUrl('Twitter'))}></span>
						</span>
						
						<span className={`${this.styles.copy} ${this.styles.shareItem}`} onClick={(e) => e.stopPropagation()}>
							<span id="link_Copy" onClick={(e) => this.onLinkClick(e)}></span>
							<span className={`${this.styles.link_url}`} data-state={copy_state} >
								<input type="text" readOnly="readonly" value={this.getUrl('Copy')} onClick={(e) => this.onCopyLink(e)} />								
							</span>
						</span>
					</div>
					<span className={`${this.styles.shareClose}`} onClick={(e) => this.onCloseClick(e)}></span>
				</div>
			)
		}
	}
	
}

/*

Google:
<span className={`${this.styles.google} ${this.styles.shareItem}`}>
	<a id="link_Google" data-track="Share:Google" onClick={(e) => this.onLinkClick(e)} href={this.getUrl('Google')}></a>
</span>

*/
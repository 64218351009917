export default {
	CONTROLLER_UPDATE: 'CONTROLLER_UPDATE',
	CONTROLLER_MOUNT: 'CONTROLLER_MOUNT',
	CONTROLLER_VIDEO_PLAY: 'CONTROLLER_VIDEO_PLAY',
	CONTROLLER_VIDEO_STOP: 'CONTROLLER_VIDEO_STOP',
	CONTROLLER_VIDEO_MUTE: 'CONTROLLER_VIDEO_UNMUTE',
	CONTROLLER_SET_SCORING_STATUS: 'CONTROLLER_SET_SCORING_STATUS',
	CONTROLLER_SETPLATFORM: 'CONTROLLER_SETPLATFORM',
	CONTROLLER_UPDATEVIEWEDCONTENT: 'CONTROLLER_UPDATEVIEWEDCONTENT',
	CONTROLLER_UPDATEDATE: 'CONTROLLER_UPDATEDATE',
	CONTROLLER_UPDATE_PREF: 'CONTROLLER_UPDATE_PREF',
	CONTROLLER_GIGYA_UPDATE: 'CONTROLLER_GIGYA_UPDATE',
	CONTROLLER_RESET: 'CONTROLLER_RESET',
	CONTROLLER_CLEAR_PLAYERS: 'CONTROLLER_CLEAR_PLAYERS',
	CONTROLLER_CLEAR_CONTENT: 'CONTROLLER_CLEAR_CONTENT',
	CONTROLLER_FORCEUPDATE: 'CONTROLLER_FORCEUPDATE',
	CONTROLLER_LOGOUT: 'CONTROLLER_LOGOUT',
	CONTROLLER_TOGGLE_SHOW_FAVORITES: 'CONTROLLER_TOGGLE_SHOW_FAVORITES',
	CONTROLLER_DEBUG: 'CONTROLLER_DEBUG',
	CONTROLLER_DRAWS_MOUNT_PROMO_VERIFY: 'CONTROLLER_DRAWS_MOUNT_PROMO_VERIFY',
};

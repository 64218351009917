import React, { Component } from 'react';
import deps from 'dependencies';
import WimLink from 'appdir/components/general/WimLink';

class ViewToggle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		//logger.log('[ViewToggle] props:%o', props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[ViewToggle] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	onClick(value) {
		//logger.log('[ViewToggle] onClick - value:%o', value);
		this.props.onChange(value);
	}

	getTabButton(index, video) {
		//logger.log('[ViewToggle] getTabButton - video:%o', video);

		let openClass = 'toggle';
		if (this.props.selected == 'index' && index == 0) {
			openClass = 'toggle-selected';
		} else if (this.props.selected == video.code) {
			openClass = 'toggle-selected';
		}

		return (
			<>
				<WimLink to={`/en_GB/interactive/${video.code}.html`}>
					<span className={openClass}>{video.title}</span>
				</WimLink>
				<span className="line">|</span>
			</>
		);
	}

	render() {
		logger.log('[ViewToggle] state:%o', this.state);
		logger.log('[ViewToggle] props:%o', this.props);

		if (this.props.videos) {
			return (
				<div className="channel-data-toggle">
					{this.props.videos.map((d, i) => {
						//logger.log('[ViewToggle] render - map:%o', i, this.props.videos.length);
						return this.getTabButton(i, d);
					})}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default ViewToggle;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import InsightsIndex from './InsightsIndex';

/**
 * -----------------------------------------------------------------------------
 * React Component: InsightsIndex
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['InsightsIndex'],
		scoringPaths: state['Config'].scoringData,
		sharedDataConfig: state['Config'].sharedDataConfig,
		otherData: state['Config'].otherData,
		favourites: state['Controller'].favourites,
		stubs: state['Config'].stubPages,
		scoringConfig: state['Config'].scoringConfig,
		currentDay: state['ActiveData']['currentDay'],
		commonData: state['CommonData'],
		tournament: state['Tournament'],
		drawData: state['Tournament']?.data?.draw,
		scheduleDays: state['Tournament']?.data?.scheduleDays,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	getDraws: event => dispatch(deps.actions.Tournament.getDraws({ event: event })),
	getSchedule: day => dispatch(deps.actions.Tournament.getSchedule({ day })),
	getMatchInsightsAvailable: () => dispatch(deps.actions.Tournament.getMatchInsightsAvailable()),
	clearTournament: () => dispatch(deps.actions.Tournament.clearAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsIndex);

import React, { Component } from 'react';
import Script from 'react-load-script';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/* NOTE- demo for qr scanner comes from: https://blog.minhazav.dev/research/html5-qrcode */
/* NOTE- Refernce docs: https://scanapp.org/html5-qrcode-docs/docs/intro */
/* NOTE- Demo: https://blog.minhazav.dev/research/html5-qrcode.html */

class ScanQRCode extends Component {
	constructor(props) {
		super(props);

		this.state = {
			scriptLoaded: false,
			cameraLoaded: false,
		};
		// this.onScanSuccessAuto = this.onScanSuccessAuto.bind(this);
		this.qrboxFunction = this.qrboxFunction.bind(this);

		this.html5QrcodeScanner = null;
	}

	componentDidMount() {}

	componentWillUnmount() {
		document.getElementById('scanQr-back-btn').removeEventListener('click', e => e);
	}

	handleScriptCreate() {
		this.setState({ scriptLoaded: false });
	}

	handleScriptError() {
		this.setState({ scriptError: true });
	}

	//handle script load and start scanning with "auto" method
	//  this includes some loading icons and buttons
	// handleScriptLoad2() {
	// 	this.setState({ scriptLoaded: true });

	// 	var lastMessage;
	// 	var codeId = 0;

	// 	this.html5QrcodeScanner = new Html5QrcodeScanner('reader', {
	// 		fps: 10,
	// 		qrbox: this.qrboxFunction,
	// 	});
	// 	this.html5QrcodeScanner.render(this.onScanSuccessAuto);
	// }

	// onScanSuccessAuto(decodedText, decodedResult) {
	// 	console.log(`Scan result:%o`, decodedText);
	// 	this.html5QrcodeScanner.clear();
	// 	this.props.onScannedQRCode(decodedText);
	// }

	//handle script load and scanning with manual method
	//  this removes a lot of the decoration and buttons
	//  but requires a camera id
	handleScriptLoad() {
		this.setState({ scriptLoaded: true });
		this.startScanManual();
	}

	startScanManual() {
		logger.log('[ScanQRCode] startScanManual');
		this.setState({ cameraLoaded: true });

		const qrCodeSuccessCallback = (decodedText, decodedResult) => {
			logger.log('[ScanQRCode] ticketScanned - ', decodedText);
			html5QrCode.stop();
			this.props.onScannedQRCode(decodedText);
		};
		// define QR scanner size
		const config = { fps: 10, qrbox: this.qrboxFunction };

		const html5QrCode = new Html5Qrcode('reader');
		// allows use of back camera
		html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback);

		if (document.getElementById('scanQr-back-btn')) {
			document.getElementById('scanQr-back-btn').addEventListener('click', function(e) {
				html5QrCode.stop();
			});
		}
	}

	qrboxFunction(viewfinderWidth, viewfinderHeight) {
		// Square QR Box, with size = 80% of the min edge.
		var minEdgeSizeThreshold = 250;
		var edgeSizePercentage = 0.75;
		var minEdgeSize = viewfinderWidth > viewfinderHeight ? viewfinderHeight : viewfinderWidth;
		var qrboxEdgeSize = Math.floor(minEdgeSize * edgeSizePercentage);
		if (qrboxEdgeSize < minEdgeSizeThreshold) {
			if (minEdgeSize < minEdgeSizeThreshold) {
				return { width: minEdgeSize, height: minEdgeSize };
			} else {
				return {
					width: minEdgeSizeThreshold,
					height: minEdgeSizeThreshold,
				};
			}
		}
		return { width: qrboxEdgeSize, height: qrboxEdgeSize };
	}

	render() {
		return (
			<div
				tabIndex={0}
				style={{ paddingTop: '16px' }}
				alt="hold up camera to scan QR code to swap. camera is waiting to scan"
				aria-label="hold up camera to scan QR code to swap. camera is waiting to scan">
				<Script
					url="/en_GB/static/qr/html5-qrcode.js"
					onCreate={this.handleScriptCreate.bind(this)}
					onError={this.handleScriptError.bind(this)}
					onLoad={this.handleScriptLoad.bind(this)}
				/>
				Scan the QR code you want to swap with:
				<div id="reader" className="qr-code-reader" />
				<div style={{ display: this.state.cameraLoaded ? 'none' : 'inline-block' }}>
					<LoadingIndicator type={'white'} showTxt="Camera is loading" />
				</div>
				<button
					role="button"
					alt="back to previous page"
					aria-label="back to previous page"
					id="scanQr-back-btn"
					className="ticket-swap-back-btn"
					onClick={() => this.props.onBackClicked()}>
					{`< Back`}
				</button>
			</div>
		);
	}
}
export default ScanQRCode;

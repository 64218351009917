/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import { connect } from 'react-redux';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { Formik, Form } from 'formik';
import { FormInput, FormSelect, FormTextarea, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import { countryLookup } from 'appdir/components/general/Util';
import { values } from 'appdir/main';
import * as yup from 'yup';
import Button from 'appdir/components/common-ui/Button';
import { osName, osVersion, browserName, fullBrowserVersion, getUA } from 'react-device-detect';
import { getQuerystringValues } from 'appdir/components/general/Util';
import op from 'object-path';
import ContactThankYouWebview from './ContactThankYouWebview';
import { callFunction } from 'appdir/components/general/Util/Services';
import { measureInAppContext } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: Contact Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['ContactPage'],
		windowSize: state['PageHeader'].windowSize,
		services: state['Config'].services,
		...state['Gigya'],
		...props,
	};
	//return Object.assign({}, state['ContactPage'], props);
};
const mobileMatch = window.matchMedia('(max-width: 767px)');

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContactPage.mount()),
	newsletterSignUpSubmit: v => dispatch(deps.actions.ContactPage.newsletterSignUpSubmit(v)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class ContactUsPage extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.countries = countryLookup;
		this.initialValues = {};
		this.handleSubmit = this.handleSubmit.bind(this);

		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
		this.gtk = op.get(parsedQs, 'gtk', null);
		this.myw = op.get(parsedQs, 'enablemyw', null);
	}

	componentDidMount() {
		const urlVars = this.getUrlVars();
		this.setState({ queryParams: urlVars });
		if (this.state.showThankYouMessage) this.setState({ showThankYouMessage: false });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	getTopic(val) {
		let value = null;
		if (this.state.topics) {
			this.state.topics.forEach(topic => {
				if (topic.value == val) {
					value = topic.label;
				}
			});
		}
		return value;
	}

	handleSubmit(form_values, actions) {

		measureInAppContext({
			pageTitle:"Settings",
			action: "Feedback",
			args: ['Submit'],
			context:  []
		})


		actions.setSubmitting(false);

		//set additional form values
		form_values.eventid = values.eventId;
		form_values.os = osName + ' ' + osVersion;
		form_values.apptype = browserName + ' ' + fullBrowserVersion;
		form_values.userAgent = getUA;
		form_values.topic_label = this.getTopic(form_values.topic);

		//add query param values as form values
		form_values = { ...form_values, ...this.state.queryParams };

		//remove gtk from form values
		delete form_values['gtk']; // pass all query params except for gtk

		let FD = [];
		let sendValues = Object.assign({}, form_values);
		delete sendValues.emailconfirm;
		Object.keys(sendValues).map((key, index) => {
			let val = encodeURIComponent(sendValues[key]).replace(/[!'()*]/g, escape);
			FD.push(key + '=' + val);
		});
		let headers = { 'Content-type': 'application/x-www-form-urlencoded' };
		let sendData = FD.join('&');

		// this.props.contactPageSubmit(form_values, res => {
		// 	// this.props.history.push('/' + this.props.match.params.lang + '/contact/ThankYou.html');

		// 	// handle error
		// 	if (res?.statusCode && res.statusCode === 200) {
		// 		this.setState({ showThankYouMessage: true });
		// 	} else {
		// 		// show error message
		// 		this.props.showModal({
		// 			showModal: true,
		// 			showCloseBtn: true,
		// 			type: 'fail',
		// 			title: 'ERROR',
		// 			message: 'There was an error with your request. Please try again.',
		// 			useTimer: false,
		// 		});
		// 		actions.resetForm();
		// 	}
		// });

		callFunction(
			op.get(this.state, 'services.hosts', []),
			op.get(this.state, 'services.guestbook', ''),
			'post',
			sendData,
			headers
		)
			.then(res => {
				if (res?.status === 'success') {
					this.setState({ showThankYouMessage: true });
				} else {
					// show error message
					this.props.showModal({
						showModal: true,
						showCloseBtn: true,
						type: 'fail',
						title: 'ERROR',
						message: 'There was an error with your request. Please try again.',
						useTimer: false,
					});
					//actions.resetForm();
				}
			})
			.catch(error => {
				this.props.showModal({
					showModal: true,
					showCloseBtn: true,
					type: 'fail',
					title: 'ERROR',
					message: 'There was an error with your request. Please try again.',
					useTimer: false,
				});
			});
	}

	renderInputField(label, name, placeholder, type, showError, val, isRequired) {
		return (
			<FormInput
				label={label}
				name={name}
				placeholder={placeholder}
				type={type}
				showerror={showError}
				value={val}
				required={isRequired}
			/>
		);
	}

	renderForm(formProps) {
		logger.log('[ContactUsPage] This is the formprops %o', formProps);
		logger.log('[ContactUsPage] state:%o', this.state);

		let isChecked = formProps.values.agreement;

		return (
			<Form onSubmit={formProps.handleSubmit}>
				<div className="name-bar column-layout">
					<div className="field two-col contact-us-input-field">
						{this.renderInputField(
							'First Name',
							'firstName',
							'First Name',
							'text',
							'true',
							formProps.values.firstName || '',
							true
						)}
					</div>
					<div className="field two-col contact-us-input-field">
						{this.renderInputField(
							'Surname',
							'surname',
							'Surname',
							'text',
							'true',
							formProps.values.surname || '',
							true
						)}
					</div>
				</div>

				<div className="email-bar">
					<div className="field two-col contact-us-input-field">
						{this.renderInputField(
							'Email',
							'email',
							'Email',
							'email',
							'true',
							formProps.values.email || '',
							true
						)}
					</div>
					<div className="fields two-col contact-us-input-field">
						{this.renderInputField(
							'Telephone Number',
							'telephoneNumber',
							'Telephone Number',
							'tel',
							'true',
							formProps.values.telephoneNumber || ''
						)}
					</div>
				</div>
				<div className="topic-subj-bar">
					<div className="field two-col contact-us-input-field">
						<FormSelect
							label="Topic"
							name="topic"
							value={formProps.values.topic || ''}
							showerror={'true'}
							required
							disabled={false}>
							<option key="topicKey" value="">
								-- Please Select A Topic --
							</option>
							{this.state.topics
								? this.state.topics.map(topic => {
										return (
											<option key={`${topic.value}`} value={`${topic.value}`}>
												{topic.label}
											</option>
										);
								  })
								: null}
						</FormSelect>
					</div>
				</div>

				<div className="date-of-visit-bar">
					<div className="field two-col contact-us-input-field">
						{this.renderInputField(
							'Date of Visit',
							'dateOfVisit',
							'',
							'date',
							null,
							formProps.values.dateOfVisit || ''
						)}
					</div>
				</div>

				<div className="message-bar">
					<div className="field four-col contact-us-input-field">
						<FormTextarea
							label="Message"
							name="comment"
							type="textarea"
							rows="10"
							value={formProps.values.comment || ''}
							required
						/>
					</div>
				</div>

				<div className="form-opt-in">
					<FormCheckBox name="agreement" id="agreement" />
					<div className="opt-in-text">
						{' '}
						Please tick here to confirm you have read and understood how the All England Club will use your
						data: <WimLink to="/en_GB/about_wimbledon/privacy_policy.html">AELTC Privacy Policy</WimLink>
					</div>
				</div>

				<div className="contact-form-submit">
					<Button
						className={`wide button solid${
							formProps.isSubmitting ||
							(isChecked !== 'true' && !isChecked) ||
							formProps.submitCount > 0 ||
							!formProps.values.firstName ||
							!formProps.values.surname ||
							!formProps.values.email ||
							!formProps.values.comment ||
							!formProps.values.topic
								? ' disabled'
								: ''
						}`}
						onClick={() =>
							!formProps.isSubmitting && (isChecked == 'true' || isChecked) && formProps.submitCount === 0
								? formProps.submitForm()
								: null
						}>
						Submit
					</Button>
				</div>
			</Form>
		);
	}

	// Get the URL & Params, Break them Down
	getUrlVars() {
		let vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
			vars[key] = value;
		});
		return vars;
	}

	render() {
		logger.log('[ContactUsPage] render - state:%o', this.state);

		let ready = true;
		if ((this.gtk || this.myw) && !this.state.loggedIn) {
			ready = false;
		}

		let header_propsData = {
			headerType: 'contact',
			title: 'Contact The AELTC',
			shortTitle: 'Contact The AELTC',
			metaTitle: 'Contact The AELTC',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		if (mobileMatch.matches == true) {
			header_propsData = {
				headerType: 'contact',
				title: 'Contact',
				shortTitle: 'Contact',
				metaTitle: 'Contact',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};
		}

		this.initialValues = {
			salutation: '',
			firstName: '',
			surname: '',
			email: '',
			dateOfBirth: '',
			postCode: '',
			country: '',
			topic: '',
			subject: '',
			comment: '',
			agreement: false,
		};

		//checking to see if user is logged in
		//to prepopulate contact form data
		if (this.state.loggedIn) {
			const { firstName, lastName, email } = this.state.currentUser.profile;
			const { title } = this.state.currentUser.data.profile;
			this.initialValues['salutation'] = title;
			this.initialValues['firstName'] = firstName;
			this.initialValues['surname'] = lastName;
			this.initialValues['email'] = email;
		} else if (!this.gtk && !this.myw) {
			let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''), true);
			this.initialValues['firstName'] = op.get(parsedQs, 'fname', null);
			this.initialValues['surname'] = op.get(parsedQs, 'lname', null);
			this.initialValues['email'] = op.get(parsedQs, 'email', null);
		}

		let ContactUsPageSchema = yup.object().shape({
			firstName: yup.string().required('Please supply a First Name.'),
			surname: yup.string().required('Please supply a Surname.'),
			email: yup
				.string()
				.email('Please supply a valid Email Address.')
				.required('Email Address is required.'),
			topic: yup.string().required('Please select a Topic.'),
			comment: yup.string().required('Please supply content for your Message.'),
			agreement: yup
				.bool()
				.required('Please comfirm you have read and understood how the All England Club will use your data.'),
		});

		if (!ready) {
			return (
				<Template className="contactpage">
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</Template>
			);
		} else if (this.state?.showThankYouMessage) {
			return (
				<Template className="contactpage">
					<div>
						<ContactThankYouWebview />
					</div>
				</Template>
			);
		} else {
			return (
				<Template className="contactpage">
					<div className="content-main column-layout contact-us">
						<div className="contact-form">
							<Formik
								enableReinitialize
								initialValues={this.initialValues}
								onSubmit={this.handleSubmit}
								validationSchema={ContactUsPageSchema}>
								{formProps => {
									return this.renderForm(formProps);
								}}
							</Formik>
						</div>
					</div>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);

import React, { Component } from 'react';
import parse from 'html-react-parser';

export default class PlayerExtraInfo extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}

	render() {
		logger.log('[PlayerExtraInfo] render - state:%o, props:%o', this.state, this.props);

		return (
			<div className="two-col inline player-extra-info">
				<div className="extra-info">{/*ReactHtmlParser*/ parse(this.props.data.content)}</div>
			</div>
		);
	}
}

import deps from 'dependencies';

export default (state = {}, action) => {
    let newState, status;

    switch (action.type) {
        case deps.actionTypes.WINFACTORS_FINDER_MOUNT:

			newState = {
				...state,
				...action.data,
			};
			logger.log('[WinFactorsFinder] reducers WINFACTORS_FINDER_MOUNT - newState:%o', newState);
            return newState;

        default:
            return state;
    }
};

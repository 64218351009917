/**
 WatchReadPage Initial State
*/

export default {
	localize: false,
	status: null,
	data: {
		title: 'Loading',
	},
};

import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const playerProfileImagePath = Config.otherData.playerProfileImagePath;
			const flagImagePathLarge = Config.otherData.flagImagePathLarge;
			const flagImagePathSmall = Config.otherData.flagImagePathSmall;
			let pathObj = {
				eventStats: Config.scoringData.playersStats,
				players:Config.scoringData.players,
				playerInfo: Config.scoringData.playersDetail,
				flagImagePathLarge,
				flagImagePathSmall,
				playerProfileImagePath,
			};

			//let headToHeadHeaders = Config.components.cms.HeadToHead.headers;
			let rounds = Config.scoringConfig.roundNames;

			dispatch({ type: deps.actionTypes.HEADTOHEAD_MOUNT, data: { pathObj, rounds } });
		});
	},
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MyWimContext } from '../context';
import WimLink from 'appdir/components/general/WimLink';
import deps from 'dependencies';
import op from 'object-path';
import Button from 'appdir/components/common-ui/Button';
import { BALLOT_PERIOD } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => ({});

class BallotRegister extends Component {
	constructor(props) {
		super(props);
		//this.state = {...props};
	}
	componentDidMount() {}
	componentDidUpdate() {}

	render() {
		logger.log('[MyWimProfile - BallotRegister] render - props:%o', this.props);
		logger.log('[MyWimProfile - BallotRegister] render - context:%o', this.context);
		//logger.log('[MyWimProfile - BallotRegister] render - state:%o status:%o', this.state, status);

		return (
			<div className="ballotreg-container">
				<div className="profile-title title-header">Ballot Information</div>
				<div className="column-layout">
					<div className="two-col margin-col">{this.context.ballotData.message}</div>
					<div className="two-col margin-col">
						<Button className={`btn solid`} to={this.context.ballotData.ballotPage}>
							Enter The Ballot
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

BallotRegister.contextType = MyWimContext;

export default connect(mapStateToProps, mapDispatchToProps)(BallotRegister);

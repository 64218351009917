/**
 Live Video Replay Dropdown
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.LiveVideo.filter(settings)),
});

class ReplaySelect extends Component {
	constructor(props) {
		super(props);

		let eventsDropdownVals = this.props.attributes;

		this.state = {
			...props,
			eventsDropdownVals: eventsDropdownVals,
			filters: props.filters,
		};

		this.setReplay = this.setReplay.bind(this);
		this.onOpen = this.onOpen.bind(this);

		// logger.log('[LiveVideo] constructor - state: %o, props: %o', this.state, props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	setReplay(selected, name) {
		logger.log('[ReplaySelect] setEvent:%o, name:%o', selected, name);

		MeasurementUtils.dispatchMeasurementCall('replaySelect', { selected, name });

		// if URL is YouTube, open in a new window
		if (selected !== 'Default' && selected.indexOf('youtu') > -1) {
			this.openYouTube(selected);
		} else if (selected !== 'Default' && selected.indexOf('youtu') === -1) {
			this.openReplay(selected);
		}

		this.props.filter({
			selected: selected,
			open: '',
		});
	}

	openYouTube(url) {
		return window.open(url, '_blank');
	}

	openReplay(url) {
		// place holder for what to do with replay's normal URL
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		logger.log('[ReplaySelect] render - state: %o', this.state);

		let eventsData = {
			options: [{ name: 'SELECT A DAY', value: 'Default' }],
		};

		// add available events to base archive menu options
		this.state.eventsDropdownVals.forEach(item => {
			//eventsData.options.push({name:item.long, value:item.code})
			eventsData.options.push({ name: item.name, value: item.value });
		});

		return (
			<div className="content-filters">
				<div className="news-landing-time-selector">
					<SelectMenu
						name="events"
						attributes={eventsData}
						selected={this.state.filters.selected}
						show={this.state.filters.open == 'events'}
						onSelected={this.setReplay}
						onOpen={this.onOpen}
					/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplaySelect);

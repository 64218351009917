import { logger } from '../logger';
import CaptionConstants from './CaptionConstants';
import PenState from './PenState';

export default function StyledCharacter({uchar, foreground, underline, italics, background, flash}) {
	
	var uchar = uchar || ' '; // unicode character
	var penState = PenState({foreground:foreground, underline:underline, italics:italics, background:background, flash:flash});

	return Object.freeze({
		reset,
		setChar,
		setPenState,
		equals,
		copy,
		isEmpty,
		getChar,
		getPenState
	})

	function reset() {
		uchar = ' ';
		penState.reset();
	};
	
	function setChar(_uchar, newPenState) {
		uchar = _uchar;
		penState.copy(newPenState);
		//logger.info('setChar - uchar:%o', uchar);
	};
	
	function setPenState(newPenState) {
		penState.copy(newPenState);
	};
	
	function equals(other) {
		return uchar === other.getChar() && penState.equals(other.getPenState());
	};
	
	function copy(newChar) {
		//logger.info('StyledCharacter.copy - newChar:%o', newChar);
		uchar = newChar.getChar();
		penState.copy(newChar.getPenState());
	};
	
	function isEmpty() {
		return uchar === ' ' && penState.isDefault();
	};

	function getChar() {
		return uchar;
	};

	function getPenState() {
		return penState;
	}

}
import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
//import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const Moment = ({ data }) => {
	let testTime1 = 1657202400000; // Thursday, July 7, 2022 2:00:00 PM GMT
	let testTime2 = 1572621072000; //Nov 2019
	let testTime3 = 1982721072000; //
	let testTime4 = 1688762269000; //July 7, 2023

	return (
		<div className="content-main content ">
			<div className="column-layout content">
				<div class="one-col margin">
					<strong>Moment</strong>
				</div>
				<div class="one-col">
					<strong>Date Service</strong>
				</div>
				<div class="one-col margin">
					<strong>format (now)</strong>
					<br />
					local - {moment(new Date()).format('ddd, DD MMM - HH:mm z')}
					<br />
					london -{' '}
					{moment(new Date())
						.tz('Europe/London')
						.format('ddd, DD MMM - HH:mm z')}
					<br />
					berlin -{' '}
					{moment(new Date())
						.tz('Europe/Berlin')
						.format('ddd, DD MMM - HH:mm z')}
					<br />
				</div>
				<div class="one-col"></div>

				<div class="one-col margin">
					<strong>format (2022)</strong>
					<br />
					local - {moment(testTime1).format('ddd, DD MMM - HH:mm z')}
					<br />
					london -{' '}
					{moment(testTime1)
						.tz('Europe/London')
						.format('ddd, DD MMM - HH:mm z')}
					<br />
					berlin -{' '}
					{moment(testTime1)
						.tz('Europe/Berlin')
						.format('ddd, DD MMM - HH:mm z')}
					<br />
				</div>
				<div class="one-col"></div>

				<div class="one-col margin">
					<strong>format (2023)</strong>
					<br />
					local - {moment(testTime4).format('ddd, DD MMM - HH:mm z')}
					<br />
					london -{' '}
					{moment(testTime4)
						.tz('Europe/London')
						.format('ddd, DD MMM - HH:mm z')}
					<br />
					berlin -{' '}
					{moment(testTime4)
						.tz('Europe/Berlin')
						.format('ddd, DD MMM - HH:mm z')}
					<br />
				</div>
				<div class="one-col"></div>
			</div>
		</div>
	);
};

export default Moment;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { SAMLContext } from './context';

import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import MeasurementUtils from 'appdir/lib/analytics';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component:Logout
 * -----------------------------------------------------------------------------
 */

class Logout extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.parsedQs;
	}

	componentDidMount() {
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		let data = {};

		data.pageTitle = 'Logout-SAML';
		data.redirect = op.get(this.parsedQs, 'redirect', '');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, data);
	}

	componentWillUnmount() {
		//this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[SAML_Logout] componentDidUpdate - prev:%o state:%o', prevState, this.state);
	}

	render() {
		logger.log('[SAML_Logout] render - state:%o context:%o', this.state, this.context);

		return (
			<Fragment>
				<div className="two-col margin-col">Logged out of myWimbledon.</div>
			</Fragment>
		);
	}
}

Logout.contextType = SAMLContext;
export default Logout;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FormInput, FormEmail, FormSelect } from 'appdir/components/common-ui/FormFields';
import { countryLookup } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ProfileForm
 * -----------------------------------------------------------------------------
 */

const ProfileForm = ({ formProps }) => {
	logger.log('[ProfileForm] render - formProps:%o', formProps);
	function formatDate(monthData) {
		let monthNum = parseInt(monthData);
		let monthStrLookup = {
			1: 'JAN',
			2: 'FEB',
			3: 'MAR',
			4: 'APR',
			5: 'MAY',
			6: 'JUN',
			7: 'JUL',
			8: 'AUG',
			9: 'SEP',
			10: 'OCT',
			11: 'NOV',
			12: 'DEC',
		};
		return monthStrLookup[monthNum];
	}

	const countries = countryLookup;

	const renderDateField = () => {
		return (
			<div className="dob-container">
				<span>
					<Field
						component="input"
						type="text"
						name="dob.day"
						disabled={true}
						value={formProps.values.dob.month}
					/>
					<ErrorMessage name="dob.day" render={msg => <span className="error">{msg}</span>} />
				</span>
				<span>
					<Field
						component="input"
						type="text"
						name="dob.month"
						disabled={true}
						value={formatDate(formProps.values.dob.date)}
					/>
					<ErrorMessage name="dob.month" render={msg => <span className="error">{msg}</span>} />
				</span>
				<span>
					<Field
						component="input"
						type="text"
						name="dob.year"
						disabled={true}
						value={formProps.values.dob.year}
					/>
					<ErrorMessage name="dob.year" render={msg => <span className="error">{msg}</span>} />
				</span>
			</div>
		);
	};
	return (
		<span>
			{/* <span className="column-layout profile-title">PROFILE INFORMATION</span> */}
			<span className="column-layout profile-info">
				<span className="two-col profile-title">PROFILE INFORMATION</span>
				<span className="two-col edit-profile">{/* <span className={'disabled'}>EDIT PROFILE</span> */}</span>
			</span>
			<span className="profile">
				<span className="column-layout">
					<span className="field one-col one-third">
						{/* <div className="profile-info">FIRST NAME</div> */}
						{/* <Field component="input" type="text" name="firstName" disabled={true} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.firstName || ''} />
						<ErrorMessage name="firstName" render={msg => <span className="error">{msg}</span>} /> */}

						<FormInput
							label="First Name"
							name="firstName"
							type="text"
							disabled={true}
							value={formProps.values.firstName || ''}
							required
							showerror="true"
						/>
					</span>

					<span className="field one-col one-third">
						{/* <div className="profile-info">LAST NAME</div>
						<Field component="input" type="text" name="lastName" disabled={true} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.lastName || ''} />
						{/* {errors.lastName ? <div className="error-space">{errors.lastName}</div> : null} */}
						{/* <ErrorMessage name="lastName" render={msg => <span className="error">{msg}</span>} /> */}

						<FormInput
							label="Last Name"
							name="lastName"
							type="text"
							disabled={true}
							value={formProps.values.lastName || ''}
							required
							showerror="true"
						/>
					</span>

					<span className="field one-col one-third">
						{/* <div className="profile-info">EMAIL ADDRESS</div>
						<Field component="input" type="email" name="email" disabled={true} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.email || ''} />
						{/* {errors.email ? <div className="error-space">{errors.email}</div> : null} */}
						{/* <ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} /> */}

						<FormEmail
							label="Email Address"
							name="email"
							type="email"
							disabled={true}
							value={formProps.values.email || ''}
							required
							showerror="true"
						/>
					</span>
				</span>
				<span className="column-layout">
					<span className="field two-col">
						{/* <div className="profile-info">COMPANY NAME</div>
						<Field component="input" type="text" name="company" onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.company} />
						<ErrorMessage name="company" render={msg => <span className="error">{msg}</span>} /> */}

						<FormInput
							label="Company Name"
							name="company"
							type="text"
							disabled={false}
							value={formProps.values.company}
							required
							showerror="true"
						/>
					</span>
					<span className="field two-col">
						{/* <div className="profile-info">COMPANY / INVOICE ADDRESS</div>
						<Field component="input" type="text" name="address" disabled={false} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.address} />
						<ErrorMessage name="address" render={msg => <span className="error">{msg}</span>} /> */}

						<FormInput
							label="Company/Invoice Address"
							name="address"
							type="text"
							disabled={false}
							value={formProps.values.address}
							required
							showerror="true"
						/>
					</span>
				</span>
				<span className="column-layout">
					<span className="field two-col">
						{/* <div className="profile-info">ZIP/POSTAL CODE</div>
						<Field component="input" type="text" name="postcode" disabled={false} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.postcode} />
						<ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} /> */}

						<FormInput
							label="Zip/Postal Code"
							name="postcode"
							type="text"
							disabled={false}
							value={formProps.values.postcode}
							required
							showerror="true"
						/>
					</span>
					<span className="field two-col">
						{/* <div className="profile-info">COUNTRY</div>
						<div className="select-dropdown">
							<Field component="select" type="text" name="country" disabled={false} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={formProps.values.country}>
								<option key={'orig'} value="" />
								{Object.keys(countries).map((d, i) => {
									return (
										<option key={i} value={d}>
											{countries[d]}
										</option>
									);
								})}
							</Field>
						</div>
						<ErrorMessage name="country" render={msg => <span className="error">{msg}</span>} /> */}

						<FormSelect
							label="Country"
							name="country"
							disabled={false}
							value={formProps.values.country}
							showerror="true"
							required>
							<option key={'countryinit'} value="">
								-- Select a Country --
							</option>
							<option key={'uktop'} value="GB">
								{countries['GB']}
							</option>
							<option key={'orig'} value="">
								-- All Countries --
							</option>
							{Object.keys(countries).map((d, i) => {
								return (
									<option key={i} value={d}>
										{countries[d]}
									</option>
								);
							})}
						</FormSelect>
					</span>
					{/* <span className="field one-col">
						<div className="profile-info">COUNTRY</div>
						<Field component="input" type="text" name="country" disabled={true} onChange={formProps.handleChange} onBlur={formProps.handleBlur} value={countries[formProps.values.country]} />
						<ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} />
					</span> */}
				</span>
			</span>
		</span>
	);
};

export default ProfileForm;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';

import MatchBox from 'appdir/components/scoring/MatchBox';
import Translate from 'appdir/components/pages/Slamtracker/Translate';
import OverallStats from 'appdir/components/pages/Slamtracker/elements/stats/OverallStats';
import JSXParser from 'react-jsx-parser';
import PrevNextBack from 'appdir/components/common-ui/PrevNextBack';

/**
 * -----------------------------------------------------------------------------
 * React Component: PDEMatchStats
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['PDEMatchStats'],
		msvPlayer: op.get(state['PlayerDigitalExperience'], 'msvPlayer', null),
		uom: state['Controller'].userPreferences['speed'],
		pdePaths: op.get(state['Config'].pde, 'api', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	unmount: () => dispatch(deps.actions.PDEMatchStats.unmount()),
});

class PDEMatchStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			statsSet: 'match',
		};

		this.getNames = this.getNames.bind(this);

		this.dataLoaded = false;

		// logger.log('[PDEMatchStats] constructor - state:%o', this.state);
		// logger.log('[PDEMatchStats] constructor - props:%o', this.props);
	}

	componentDidMount() {
		// logger.log('[PDEMatchStats] componentDidMount');
	}

	componentWillUnmount() {
		// logger.log('[PDEMatchStats] componentWillUnmount');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PDEMatchStats] componentDidUpdate - state:%o', this.state);
		logger.log('[PDEMatchStats] componentDidUpdate - props:%o', this.props);

		if (
			op.get(this.props, 'match.params.yearId', false) &&
			op.get(this.props, 'match.params.matchId', false) &&
			op.get(this.props, 'pdePaths.matchStatsPath', false) &&
			!this.dataLoaded
		) {
			this.dataLoaded = true;

			let url = this.props.pdePaths.matchStatsPath
				.replace('<match_year>', this.props.match.params.yearId)
				.replace('<match_id>', this.props.match.params.matchId);

			logger.log('[PDEMatchStats] componentDidUpdate - url:%o', url);

			axios({
				method: 'get',
				url: url,
			})
				.then(result => {
					logger.log('[PDEMatchStats] componentDidUpdate - result:%o', result);

					this.setState({
						statMatch: result.data.matches[0],
					});
				})
				.catch(error => {
					logger.error('[PDE - PlayerMatchStats] componentDidUpdate - error loading match data:%o', error);
					this.setState({
						error:
							'The information you are requesting is currently unavailable. Please try again later or contact us on players@aeltc.com',
					});
				});
		}
	}

	changeStatsSet(which, hasData) {
		if (hasData) {
			this.setState(prevState => {
				return { ...prevState, statsSet: which };
			});

			this.measureStats(['Select Set', which]);
		}
	}

	isDoublesMatch() {
		try {
			if (this.state.statMatch.eventName.toLowerCase().indexOf('doubles') > -1) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			logger.error('[PDEMatchStats] isDoublesMatch - error:%o', error);
			return false;
		}
	}

	getHeaderRow() {
		let isDoubles = this.isDoublesMatch();
		let team1Names, team1Initials;
		let team2Names, team2Intitials;

		team1Initials =
			this.state.statMatch.team1.firstNameA.charAt(0) + this.state.statMatch.team1.lastNameA.charAt(0);
		team2Intitials =
			this.state.statMatch.team2.firstNameA.charAt(0) + this.state.statMatch.team2.lastNameA.charAt(0);
		if (isDoubles) {
			team1Initials += `<br/>${this.state.statMatch.team1.firstNameB.charAt(
				0
			)}${this.state.statMatch.team1.lastNameB.charAt(0)}`;
			team2Intitials += `<br/>${this.state.statMatch.team2.firstNameB.charAt(
				0
			)}${this.state.statMatch.team2.lastNameB.charAt(0)}`;
		}

		team1Names = this.state.statMatch.team1.displayNameA;
		team2Names = this.state.statMatch.team2.displayNameA;
		if (isDoubles) {
			team1Names += `<br/>${this.state.statMatch.team1.displayNameB}`;
			team2Names += `<br/>${this.state.statMatch.team2.displayNameB}`;
		}

		return (
			<div className="stats-header-container">
				<div className="stats-header-row">
					<div className="stats-header-cell t1">
						<div className={`stats-player ${isDoubles ? 'doubles' : ''}`}>
							<span className="short">
								<JSXParser jsx={team1Initials} />
							</span>
							<span className="long">
								<JSXParser jsx={team1Names} />
							</span>
						</div>
					</div>
					<div className="stats-header-cell t2">
						<div className={`stats-player ${isDoubles ? 'doubles' : ''}`}>
							<span className="short">
								<JSXParser jsx={team2Intitials} />
							</span>
							<span className="long">
								<JSXParser jsx={team2Names} />
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	getNames() {
        let team1Names;
		let team2Names;
		let statMatch = op.get(this.state, 'statMatch', false);
		let isDoubles = this.isDoublesMatch();
		team1Names = statMatch.team1.displayNameA;
		team2Names = statMatch.team2.displayNameA;
		if (isDoubles) {
			team1Names += `<br/>${statMatch.team1.displayNameB}`;
			team2Names += `<br/>${statMatch.team2.displayNameB}`;
		}

        return [team1Names, team2Names]
    }

	render() {
		// logger.log('[PDEMatchStats] render - state:%o', this.state);
		// logger.log('[PDEMatchStats] render - props:%o', this.props);
		logger.log('[PDEMatchStats] render - this.error:%o', this.error);

		let header_propsData = {
			headerType: 'generic_register',
			title: 'Match Statistics',
			shortTitle: 'Match Statistics',
			metaTitle: 'Match Statistics',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		};

		let backData = { category: '', history: this.props.history };

		if (this.state.error) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main news pde-match-stats" id="main" tabIndex="0">
						<div className="column-layout">
							<div className="four-col multi">
								<div className="loading-indicator">{this.state.error}</div>
							</div>
						</div>
					</div>

					<Footer />
				</Template>
			);
		}

		if (op.get(this.state, 'statMatch', false) && op.get(this.state, 'uom', false)) {
			let unitOfMeasure = this.props.uom == 'M' ? 'metric' : 'imperial';

			let stats_data = {
				t1: !isEmpty(this.state.statMatch.base_stats)
					? this.state.statMatch.base_stats[this.state.statsSet].team_1
					: null,
				t2: !isEmpty(this.state.statMatch.base_stats)
					? this.state.statMatch.base_stats[this.state.statsSet].team_2
					: null,
				d1: isEmpty(this.state.statMatch.distance_run)
					? 'none'
					: this.state.statMatch.distance_run[this.state.statsSet].team_1,
				d2: isEmpty(this.state.statMatch.distance_run)
					? 'none'
					: this.state.statMatch.distance_run[this.state.statsSet].team_2,
				level: this.state.statMatch.statsLevel,
				unit: unitOfMeasure,
				names: this.getNames(),
				set: this.state.statsSet
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className={`content-main pde-match-stats ${unitOfMeasure}`} id="main" tabIndex="0">
						<div className="column-layout">
							<div className="four-col">
								<div className="match-stats-header four-col">{this.props.match.params.yearId}</div>

								<div className="match-stats-scoreboard three-col">
									<MatchBox
										attributes={{
											data: this.state.statMatch,
											style: 'live',
											event: this.state.statMatch.shortEventName,
											tableHeader: this.state.statMatch.courtName,
											showLinks: false,
											usePlayerImages: true,
										}}
									/>
								</div>

								<section className="slamtracker-stats content expanded match-stats-table three-col">
									{isEmpty(this.state.statMatch.base_stats) ? (
										<div className="no-content">No stats data available</div>
									) : (
										<div className='page-nav-container'>
											<div className="nav-container">
												<ul className="page-nav-list" role="list">
													<li
														className={`page-nav-items ${
															this.state.statMatch.base_stats['match']
																? ''
																: 'disabled'
														} ${this.state.statsSet == 'match' ? 'active' : ''}`}>
														{' '}
														<a
															data-ref="Match Summary"
															onClick={() =>
																this.changeStatsSet(
																	'match',
																	this.state.statMatch.base_stats['match']
																)
															}>
															<Translate originalText="match_summary" />
														</a>
													</li>
													<li className='divider' />
													<li
														className={`page-nav-items ${
															this.state.statMatch.base_stats['set_1']
																? ''
																: 'disabled'
														} ${this.state.statsSet == 'set_1' ? 'active' : ''}`}>
														<a
															data-ref="Set 1"
															onClick={() =>
																this.changeStatsSet(
																	'set_1',
																	this.state.statMatch.base_stats['set_1']
																)
															}>
															<Translate originalText="set1" />
														</a>
													</li>
													<li className='divider' />
													<li
														className={`page-nav-items ${
															this.state.statMatch.base_stats['set_2']
																? ''
																: 'disabled'
														} ${this.state.statsSet == 'set_2' ? 'active' : ''}`}>
														<a
															data-ref="Set 2"
															onClick={() =>
																this.changeStatsSet(
																	'set_2',
																	this.state.statMatch.base_stats['set_2']
																)
															}>
															<Translate originalText="set2" />
														</a>
													</li>
													<li className='divider' />
													<li
														className={`page-nav-items ${
															this.state.statMatch.base_stats['set_3']
																? ''
																: 'disabled'
														} ${this.state.statsSet == 'set_3' ? 'active' : ''}`}>
														<a
															data-ref="Set 3"
															onClick={() =>
																this.changeStatsSet(
																	'set_3',
																	this.state.statMatch.base_stats['set_3']
																)
															}>
															<Translate originalText="set3" />
														</a>
													</li>
													<li className='divider' />
													<li
														className={`page-nav-items ${
															this.state.statMatch.base_stats['set_4']
																? ''
																: 'disabled'
														} ${this.state.statsSet == 'set_4' ? 'active' : ''}`}>
														<a
															data-ref="Set 4"
															onClick={() =>
																this.changeStatsSet(
																	'set_4',
																	this.state.statMatch.base_stats['set_4']
																)
															}>
															<Translate originalText="set4" />
														</a>
													</li>
													<li className='divider' />
													<li
														className={`page-nav-items ${
															this.state.statMatch.base_stats['set_5']
																? ''
																: 'disabled'
														}  ${this.state.statsSet == 'set_5' ? 'active' : ''}`}>
														<a
															data-ref="Set 5"
															onClick={() =>
																this.changeStatsSet(
																	'set_5',
																	this.state.statMatch.base_stats['set_5']
																)
															}>
															<Translate originalText="set5" />
														</a>
													</li>
												</ul>
											</div>

											<div className="slamtracker-matchstats-content">
												{/* {this.getHeaderRow()} */}

												<div className="stats-table">
													<OverallStats data={stats_data} />
												</div>
											</div>
										</div>
									)}
								</section>

								<PrevNextBack attributes={backData} />
							</div>
						</div>
					</div>

					<Footer />
				</Template>
			);
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main news pde-match-stats" id="main" tabIndex="0">
						<div className="column-layout">
							<div className="four-col multi">
								<LoadingIndicator />
							</div>
						</div>
					</div>

					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PDEMatchStats);

import MatchInsights from './index';
import deps from 'dependencies';

export default {
	//path: '/:lang(en_GB)/matchinsights/:matchId(\\d+).html',
	path: '/:lang(en_GB)/matchinsights23/:matchId(\\d+).html',
	exact: true,
	component: MatchInsights,
	load: params => deps.actions.MatchInsights.mount(params),
};

import React from 'react';
import { values } from 'appdir/main';
import { generateStatusText, getCurrentMatch } from 'appdir/components/general/Util/MatchUtil';
import WimLink from 'appdir/components/general/WimLink';
import PlayerImage from 'appdir/components/content/PlayerImage';
import PlayerStatus from './PlayerStatus';
import RankingMovement from 'appdir/components/common-ui/PowerRanking/RankingMovement';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';

const PlayerInnovation = props => {
	const d = { ...props.data };
	const { imgConfig, showStar, tab } = props;
	const displayMatchDetails = getCurrentMatch(d);
	const statusText = generateStatusText(displayMatchDetails);
	const matchId = displayMatchDetails?.match_details?.match_id || null;
	const { playerid, first_name, last_name } = displayMatchDetails['player_info'];
	const { statusCode } = statusText;
	const reasonMap = reason => {
		let reasonMap = {
			editor_picks: "EDITOR'S PICKS",
			power_index: 'BASED ON POWER INDEX',
			recommended: 'BASED ON FAVOURITES',
		};
		return reasonMap[reason];
	};

	const isSlamTrackerActive2 = matchData => {
		//stats level only set after match complete, so won't allow active pre/during match
		let active =
			(matchData?.team1?.idA && matchData?.team2?.idA) ||
			(matchData?.team1?.[0]?.idA && matchData?.team2?.[0]?.idA);
		let slamtrackerActive = active && matchData?.courtId;
		return slamtrackerActive;
	};

	return (
		<div className={`fav-player-container ${statusCode == undefined ? 'no-status' : ''}`}>
			<div className="player-img-link">
				{showStar && tab == 'picked' && (
					<FavouriteStar
						id={playerid}
						type="players"
						unFavColor="purple"
						favColor="purple"
						alwaysShow={true}
					/>
				)}
				<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
					<PlayerImage
						attributes={{
							'player-image': imgConfig.replace('<playerid>', playerid),
							style: `small${statusText.matchStatus == 'OUT' ? ' out' : ''}`,
						}}
					/>
				</WimLink>
			</div>
			<div className="player-info-container">
				{d.reason && tab == 'picked' && <div className="reason">{reasonMap(d.reason)}</div>}
				<div className="player-info">
					<WimLink to={`/en_GB/players/overview/${playerid}.html`}>
						<div className="player-name">
							{first_name} {last_name}
						</div>
					</WimLink>
					{d.powerRanking ? (
						<WimLink to={`/en_GB/powerindex/index.html?playerId=${playerid}`}>
							<div className="player-ranking">
								{values.powerIndexTitle}:{' '}
								<RankingMovement
									data={{ rank: d.powerRanking.wpi_rank, movement: d.powerRanking.wpi_rank_change }}
								/>
							</div>
						</WimLink>
					) : (
						<div className="power-ranking-placeholder">No Index Available</div>
					)}
					{/* {d.powerRanking !== null && d.powerRanking !== undefined ? ( */}
					{matchId ? <hr className="power-ranking-stripe" /> : <hr className="power-ranking-stripe hidden" />}
				</div>
				<PlayerStatus
					preMatchInsight={d.preMatchInsight}
					statusText={{ ...statusText }}
					matchId={matchId}
					slamtrackerActive={isSlamTrackerActive2(displayMatchDetails?.match_details)}
					statusCode={displayMatchDetails?.match_details?.statusCode}
				/>
			</div>
		</div>
	);
};

export default PlayerInnovation;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import { loggedIn, checkRole } from 'appdir/components/general/Util/Role';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//mount: () => dispatch(deps.actions.PlayerDigitalExperience.mount()),
});

class LoginRequired extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[LoginRequired] getDerivedStateFromProps - nextProps:%o', nextProps);
		let newState = nextProps ? { ...nextProps } : null;
		return newState;
	}

	componentDidUpdate() {
		logger.log('[LoginRequired] componentDidUpdate - state:%o', this.state);
	}

	render() {
		let currentUser = op.get(this.state, 'currentUser', null);
		let allowedRoles = op.get(this.state, 'roles', []);
		let roleValidated = checkRole(op.get(currentUser, 'roles', []), allowedRoles, false);

		logger.log('[LoginRequired] render - roleValidated:%o', roleValidated);
		logger.log('[LoginRequired] render - state:%o', this.state);
		logger.log('[LoginRequired] render - props:%o', this.props);

		return (
			<Fragment>
				{!this.state.loggedIn && !this.state.gigyaLoaded ? (
					<div className="loading-wrapper">
						<LoadingIndicator />
					</div>
				) : !this.state.loggedIn && this.state.gigyaLoaded ? (
					<Fragment>
						<div className="two-col margin-col bottom-margin center">
							Please login with <MyWimbledonTitle /> to continue.
						</div>
						<div className="four-col">
							<GigyaScreenSet
								params={{
									screen: 'login_screen',
									redirect: false,
									showSuccess: false,
									register: false,
								}}
								inline={true}
							/>
						</div>
					</Fragment>
				) : (
					<Fragment>{this.props.children(allowedRoles.length == 0 || roleValidated, currentUser)}</Fragment>
				)}
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRequired);

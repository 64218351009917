import React from 'react';
import PropTypes from 'prop-types';

const callbacks = [];

function addScript(src, cb) {
	logger.log('[TweetEmbedNew] - src:%o cb:%o', src, callbacks);

	if (callbacks.length === 0) {
		callbacks.push(cb);
		var s = document.createElement('script');
		s.setAttribute('src', src);
		//s.async = true;
		s.onload = () => callbacks.forEach(cb => cb());

		logger.log('[TweetEmbedNew] - body:%o', document.body);

		document.body.appendChild(s);
	} else {
		callbacks.push(cb);
	}
}

class TweetEmbedNew extends React.Component {
	loadTweetForProps(props) {
		const renderTweet = () => {
			window.twttr.ready().then(({ widgets }) => {
				// Clear previously rendered tweet before rendering the updated tweet id
				if (this._div) {
					this._div.innerHTML = '';
				}

				const { options, onTweetLoadSuccess, onTweetLoadError } = props;
				// widgets
				//     .createTweet(this.props.id, this._div, options)
				//     .then(onTweetLoadSuccess)
				//     .catch(onTweetLoadError)
				widgets
					.createVideo(this.props.id, this._div, options)
					.then(onTweetLoadSuccess)
					.catch(onTweetLoadError);
			});
		};

		if (!(window.twttr && window.twttr.ready)) {
			const isLocal = window.location.protocol.indexOf('file') >= 0;
			const protocol = isLocal ? this.props.protocol : '';

			logger.log('[TweetEmbedNew] - protocol:%o', protocol);

			//addScript(`${protocol}//platform.twitter.com/widgets.js`, renderTweet)
		} else {
			renderTweet();
		}
	}

	componentDidMount() {
		this.loadTweetForProps(this.props);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.id !== nextProps.id;
	}

	componentWillUpdate(nextProps, nextState) {
		this.loadTweetForProps(nextProps);
	}

	render() {
		return (
			<div
				className={this.props.className}
				ref={c => {
					this._div = c;
				}}
			/>
		);
	}
}

TweetEmbedNew.propTypes = {
	id: PropTypes.string,
	options: PropTypes.object,
	protocol: PropTypes.string,
	onTweetLoadSuccess: PropTypes.func,
	onTweetLoadError: PropTypes.func,
	className: PropTypes.string,
};

TweetEmbedNew.defaultProps = {
	protocol: 'https:',
	options: {},
	className: null,
};

export default TweetEmbedNew;

import React, { useEffect } from 'react';
import TabNav from 'appdir/components/common-ui/TabNav';
import StatusTab from 'appdir/components/pages/PlayerPage/StatusTab';
import ProfileTab from 'appdir/components/pages/PlayerPage/ProfileTab';
import StatisticsTab from 'appdir/components/pages/PlayerPage/StatisticsTab';
import { measureInAppContext } from 'appdir/components/general/Analytics';

const PlayerProfessionalInfoWebview = ({ data }) => {
	logger.log('[PlayerProfessionalInfoWebview] - data', data);

	// useEffect(() => {
	// 	if (){
	// 		measureClick();
	// 	}
	// }, [props.location.pathname])
	// if url is different from last one and first load, call measureClick

	const measureClick = () => {
		measureInAppContext({
			pageTitle: 'Player Profile',
			action: 'Tab Selection',
			args: [data.tabName],
			context: [{ pp_playerID: data.playerObj.id }],
			metricType: 'action',
		});
	};
	return (
		<div className="professional-info-tab-wrapper" style={{ marginTop: '25px', marginBottom: '15px' }}>
			<TabNav
				data={{
					key: `profileTabs-${data?.tabName}`,
					default: 'Status',
					tabs: [
						{
							name: 'Status',
							value: `/webview/en_GB/players/overview/${data.playerObj.id}/status.html`,
							match: [
								/\/webview\/en_GB\/players\/overview\/[A-Za-z0-9]+\/status.html/i,
								/\/webview\/en_GB\/players\/overview\/[A-Za-z0-9]+\.html/i,
							],
						},
						{
							name: 'Profile',
							value: `/webview/en_GB/players/overview/${data.playerObj.id}/profile.html`,
							match: /\/webview\/en_GB\/players\/overview\/[A-Za-z0-9]+\/profile.html/i,
						},
						{
							name: 'Statistics',
							value: `/webview/en_GB/players/overview/${data.playerObj.id}/statistics.html`,
							match: /\/webview\/en_GB\/players\/overview\/[A-Za-z0-9]+\/statistics.html/i,
						},
					],
				}}
				bypassAppLink={true}
			/>
			{data?.tabName === 'status' || !data?.tabName ? (
				<StatusTab data={data} />
			) : data?.tabName === 'profile' ? (
				<ProfileTab data={data} />
			) : data?.tabName === 'statistics' ? (
				<StatisticsTab data={data} />
			) : null}
		</div>
	);
};

export default PlayerProfessionalInfoWebview;

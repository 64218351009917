/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { OIDCContext } from './context';

import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import MeasurementUtils from 'appdir/lib/analytics';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: Error page
 * -----------------------------------------------------------------------------
 */

class Error extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		logger.log('[OIDC_Error] constructor - qs:%o', this.parsedQs);
	}

	componentDidMount() {
		let data = {};

		data.pageTitle = 'Error';
		data.redirect = op.get(this.parsedQs, 'redirect', '');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, data);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[OIDC_Error] componentDidUpdate - prev:%o state:%o', prevState, this.state);
	}

	render() {
		logger.log('[OIDC_Error] render - state:%o context:%o', this.state, this.context);

		return (
			<Fragment>
				<div className="two-col margin-col">
					Sorry, we are having difficulty authenticating you. Please try again. If the problem persists please{' '}
					<WimLink to="/en_GB/contact/index.html">contact us</WimLink>, quoting the message below.
				</div>
				{this.parsedQs && this.parsedQs.message ? (
					<div className="two-col margin-col oidc_message">Message: {`${this.parsedQs.message}`}</div>
				) : null}
			</Fragment>
		);
	}
}

Error.contextType = OIDCContext;
export default Error;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Carousel from 'appdir/components/common-ui/Carousel';
/**
 * -----------------------------------------------------------------------------
 * Functional Component: Gallery
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['Gallery'], props.data);
};

const mapDispatchToProps = (dispatch, props) => ({
	selectImage: d => dispatch(deps.actions.Gallery.selectImage(d)),
	updateSliderOffSet: d => dispatch(deps.actions.Gallery.updateSliderOffSet(d)),
});

class Gallery extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data };
		this.state['sliderOffset'] = 0;
		this.state['currImageIndex'] = 0;
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	scrollTo(i) {
		if (i >= 0 && i <= this.state['images']['image'].length - 1) {
			let newOffset = i == 0 ? 0 : -(i * 150);
			this.setState(prevState => {
				return { ...prevState, currImageIndex: i, sliderOffset: newOffset };
			});
		}
	}

	slideTo(newIndex) {
		if (newIndex >= 0 && newIndex <= this.state['images']['image'].length - 1) {
			let index = newIndex;
			let newOffset = index == 0 ? 0 : -(index * 100);
			this.setState(prevState => {
				return { ...prevState, currImageIndex: index, sliderOffset: newOffset };
			});
		}
	}

	renderSlideContent(data) {
		return (
			<span>
				<div className="photo-container">
					<img src={data.url} />
				</div>
				<div className="photo-info">
					<div className="photo-title">{data.title}</div>
					<div className="photo-credit">{data.credit}</div>
				</div>
			</span>
		);
	}

	renderNav(direction, clickFn) {
		return (
			<div
				onClick={() => {
					clickFn(direction);
				}}
				className={`arrow-wrapper-${direction}`}>
				<i className={`wim-icon-${direction}-arrow`} />
			</div>
		);
	}

	render() {
		logger.log('[Gallery] - props:%o', this.state);
		let images = this.state['images']['image'];
		let sliderContent = images.map(d => {
			return {
				thumbnail: d.url,
				renderItem: this.renderSlideContent.bind(this),
				title: d.title || d.caption,
				credit: d.credit,
				date: d.date,
				url: d.url,
			};
		});
		let currIndex = this.state['currImageIndex'];
		let navJSON = {
			leftNav: this.renderNav.bind(this),
			rightNav: this.renderNav.bind(this),
		};
		let showThumbnails = this.state.showThumbnails == 'true' ? true : false;
		return (
			<div
				className={`photo-gallery ${showThumbnails ? 'thumbnail-gallery' : 'dot-gallery'} photo ${
					this.state['style']
				}`}>
				<Carousel
					attributes={{
						items: sliderContent,
						showThumbnails: showThumbnails ? true : false,
						showFullscreenButton: false,
						showPlayButton: false,
						showBullets: !showThumbnails ? true : false,
						showNav: !showThumbnails ? true : false,
						customNav: showThumbnails ? true : false,
						customNavHTML: navJSON,
						customBullets: false,
					}}
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);

import deps from 'dependencies';

export default {
    mount: data => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[WinFactorsFinder] action.mount - Config: %o', Config);
			let data = {
				status: 'loaded',
			};
			dispatch({ type: deps.actionTypes.WINFACTORS_FINDER_MOUNT, data: data });
		});
    },
};

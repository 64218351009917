import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import MeasurementUtils from 'appdir/lib/analytics';

const TCG_START = 1593388800000;
const ROUNDS = {
	'1': '1',
	'2': '1',
	'3': '2',
	'4': '2',
	'5': '3',
	'6': '3',
	'7': '3',
	'8': '4',
	'9': 'Q',
	'10': 'Q',
	'11': 'S',
	'12': 'S',
	'13': 'F',
	'14': 'F',
	'15': 'F',
};

export default {
	mount: params => (dispatch, getState, store) => {
		logger.log('[MatchArchive] action.mount - params: %o', params);
		let landingPage = params.id == 'index' || params.id == 'greatestchampionships';

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[MatchArchive] action.mount - Config.otherData: %o', Config.otherData);
			let data = {
				matchArchive: [],
				currentMatch: undefined,
				landing: landingPage,
			};
			//logger.log('[MatchArchive] action.mount - data: %o', data);
			if (data.landing) {
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Match Archives - Landing',
				});
			}
			dispatch({ type: deps.actionTypes.MATCHARCHIVE_MOUNT, data: data });

			//load data based on the current path
			let summaryDataPath = Config.cmsData.matchArchiveSummary;
			if (params.dir == 'preview_matcharchive_7') {
				//summaryDataPath = summaryDataPath.replace('.json', '.preview.json');
			}

			deps.services.MatchArchive.fetch(summaryDataPath).then(matches => {
				//logger.log('[MatchArchive] action.mount - matches: %o', matches.archives);
				//dispatch({ type: deps.actionTypes.MATCHARCHIVE_LOAD_MATCHES, data: matches });

				//add readable date
				// matches.archives = matches.archives.map(match => {
				// 	match.availableRead = moment(match.available).format('MMMM Do YYYY, h:mm:ss a');
				// 	return match;
				// });
				logger.log('[MatchArchive] action.mount - matches.archives: %o', matches.archives);

				deps.services.MatchArchive.fetch(Config.otherData.matchArchiveOffsets).then(offsets => {
					//logger.log('[MatchArchive] action.mount - offsets: %o', offsets);

					// find the current day based on the latest active match
					let day = '0';
					matches.archives.sort((a, b) => {
						if (a.available < b.available) {
							return 1;
						} else if (a.available > b.available) {
							return -1;
						} else {
							return 0;
						}
					});
					let activeMatches = matches.archives.filter(m => m.status == 'A');
					if (activeMatches.length > 0) {
						let a = moment(TCG_START);
						let b = moment(activeMatches[0].available);
						day = String(b.diff(a, 'days') + 1);
						logger.log(
							'[MatchArchive] action.mount - active:%o day:%o',
							moment(activeMatches[0].available).format('MMMM Do YYYY, h:mm:ss a'),
							day
						);
					}

					let current = matches.archives.filter(m => m.id == params.id);
					current = current.length == 0 ? 'notfound' : current[0];

					logger.log('[MatchArchive] action.mount - current: %o', current);
					let statsURL = Config.otherData.matchArchiveStats.replace('<matchId>', current.id);
					let data;

					let detailPath = Config.cmsData.matchArchive;

					if (params.preview) {
						current = {
							id: '0000W0000',
							contentId: params.id,
							status: 'A',
							available: 1593424800000,
							year: '',
							round: '',
							event: '',
							players: [
								{
									firstName: '',
									lastName: '',
									id: '',
									nation: '',
									seed: null,
									headshot: '',
								},
								{
									firstName: '',
									lastName: '',
									id: '',
									nation: '',
									seed: null,
									headshot: '',
								},
							],
							quotes: [],
						};

						detailPath = detailPath.replace('/en_GB', '/preview/en_GB');
					}

					if (current === 'notfound') {
						data = {
							matchArchive: matches.archives,
							matchOffsets: offsets.archives,
							matchHistoryPath: Config.otherData.matchArchiveHistory,
							matchArchivePath: detailPath,
							currentMatchStats: null,
							currentMatch: current,
							currentDay: day,
							currentRound: ROUNDS[day],
							directory: params.dir,
							pageUrl: window.location.origin + window.location.pathname,
							pagePath: window.location.pathname,
							heartbeatInterval: Config.videoPlayer.heartbeatInterval,
						};

						dispatch({ type: deps.actionTypes.MATCHARCHIVE_LOAD_MATCHES, data: data });
					} else {
						deps.services.MatchArchive.fetch(statsURL).then(stats => {
							// logger.log('[MatchArchive] action.mount - stats: %o', stats);

							data = {
								matchArchive: matches.archives,
								matchOffsets: offsets.archives,
								matchHistoryPath: Config.otherData.matchArchiveHistory,
								matchExcitePath: Config.otherData.matchArchiveOther,
								matchArchivePath: detailPath,
								currentMatchStats: stats ? stats : null,
								currentMatch: current,
								currentDay: day,
								currentRound: ROUNDS[day],
								directory: params.dir,
								pageUrl: window.location.origin + window.location.pathname,
								pagePath: window.location.pathname,
								heartbeatInterval: Config.videoPlayer.heartbeatInterval,
							};
							//logger.log('[MatchArchive] action.mount - data: %o', data);
							dispatch({ type: deps.actionTypes.MATCHARCHIVE_LOAD_MATCHES, data: data });
						});
					}
				});
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MATCHARCHIVE_UNMOUNT });
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MATCHARCHIVE_FILTER, data: data });
	},

	updateMode: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MATCHARCHIVE_UPDATE, data: { mode: data } });
	},
};

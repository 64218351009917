/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useField, ErrorMessage } from 'formik';
import Select from 'react-select';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Formik Generic Form Fields
 * -----------------------------------------------------------------------------
 */

export const FormEmail = ({ label, ...props }) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input> and also replace ErrorMessage entirely.
	const [field, meta] = useField(props);
	return (
		<>
			<label htmlFor={props.id || props.name}>
				<h5 className={`label purple${props.required ? ' required' : ''}`}>{label}</h5>
				<input className="text-input" {...field} {...props} />
			</label>
			{meta.touched && meta.error ? (
				<ErrorMessage name={props.name} render={() => <span className="error">{meta.error}</span>} />
			) : null}
		</>
	);
};

export const FormInput = ({ label, ...props }) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input> and also replace ErrorMessage entirely.
	const [field, meta] = useField(props);
	logger.log('FormInput - label:%o meta:%o props:%o', label, meta, props);
	return (
		<>
			<label htmlFor={props.id || props.name}>
				<h5 className={`label purple${props.required ? ' required' : ''}`}>{label}</h5>
				<input className="text-input" {...field} {...props} />
			</label>
			{meta.touched && meta.error && props.showerror && props.showerror === 'true' ? (
				<ErrorMessage name={props.name} render={() => <span className="error">{meta.error}</span>} />
			) : null}
		</>
	);
};

export const FormTextarea = ({ label, ...props }) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input> and also replace ErrorMessage entirely.
	const [field, meta] = useField(props);
	return (
		<>
			<label htmlFor={props.id || props.name}>
				<h5 className={`label purple${props.required ? ' required' : ''}`}>{label}</h5>
				<textarea className={`${props.stylename ? props.stylename : null}`} {...field} {...props} />
			</label>
			{meta.touched && meta.error ? (
				<ErrorMessage name={props.name} render={() => <span className="error">{meta.error}</span>} />
			) : null}
		</>
	);
};

export const FormCheckBox = ({ children, ...props }) => {
	// We need to tell useField what type of input this is
	// since React treats radios and checkboxes differently
	// than inputs/select/textarea.
	const [field, meta] = useField({ ...props, type: 'checkbox' });
	return (
		<>
			<div className={`checkbox-option${props.required ? ' required' : ''}`}>
				<input type="checkbox" {...field} {...props} />
				<label htmlFor={props.id} className={props.disabled === true ? 'disabled' : ''}>
					{children}
				</label>
				{meta.touched && meta.error ? (
					<ErrorMessage name={props.name} render={() => <div className="error">{meta.error}</div>} />
				) : null}
			</div>
		</>
	);
};

export const FormRadioButton = ({ children, ...props }) => {
	// We need to tell useField what type of input this is
	// since React treats radios and checkboxes differently
	// than inputs/select/textarea.
	const [field, meta] = useField({ ...props, type: 'radio' });
	return (
		<>
			<div className="radioBtn">
				<input type="radio" {...field} {...props} />
				<span className="checkmark" />
				<label htmlFor={props.id}>{children}</label>
			</div>
		</>
	);
};

export const FormSelect = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<>
			<label htmlFor={props.id || props.name}>
				<h5 className={`label purple${props.required ? ' required' : ''}`}>{label}</h5>
				<select {...field} {...props} className={props.selectClassname || ''}/>
				<i className="wim-icon-down-arrow"></i>
			</label>
			{meta.touched && meta.error && props.showerror && props.showerror === 'true' ? (
				<ErrorMessage name={props.name} render={() => <span className="error">{meta.error}</span>} />
			) : null}
		</>
	);
};

export const FormSelectPlus = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<>
			<label htmlFor={props.id || props.name}>
				<h5 className={`label purple${props.required ? ' required' : ''}`}>{label}</h5>
				<Select
					{...field}
					{...props}
					multi={false}
					value={props.options ? props.options.find(option => option.value === field.value) : ''}
				/>
				<i className="wim-icon-down-arrow"></i>
			</label>
		</>
	);
};

export const FormFieldGroup = ({ value, error, touched, id, label, className, children }) => {
	return (
		<div>
			<fieldset>
				<legend>{label}</legend>
				{children}
				{/* {touched && <InputFeedback error={error} />} */}
			</fieldset>
		</div>
	);
};

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';
import op from 'object-path';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[MyWimbledon] action.mount - params:%o store:%o', params, store.getState('Gigya'));
			let roles = op.get(Config, 'myWimbledon.rolesComplete', {});
			let lockRoles = {};
			roles.forEach(role => {
				if (role.lock) {
					lockRoles[`${role.key}`] = role.id;
				}
			});
			//logger.log('[MyWimbledon] action.mount - lockRoles:%o', lockRoles);

			let data = {
				tab: !params.tab || params.tab == 'index' ? 'profile' : params.tab,
				version: op.get(Config, 'myWimbledon.version', 0),
				ballotData: op.get(Config, 'ballot', {}),
				lockRoles: lockRoles,
				render: true,
			};

			if (params.page == 'allowed_update') {
				if (op.get(Config, 'myWimbledon.emailChangeAllowed', false)) {
					data.period = 'pre';
				} else {
					data.render = false;
				}
			}

			//allow for qs override for dev
			//  can be removed for production release
			let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
			data.ballotData.period = op.get(parsedQs, 'period', data.ballotData.period);

			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				content: {
					tab: data.tab,
					//loggedIn: store.getState('Gigya').loggedIn
				},
			});

			dispatch({
				type: deps.actionTypes.MYWIMBLEDON_MOUNT,
				data: data,
				config: Config,
				roles: Config.myWimbledon.roles,
				api: {
					debenture: Config.myWimbledon.api,
					favorites: Config.homepage.favorites,
					players: Config.scoringData.players,
					favoriteRelated: Config.relatedContent.favoriteContent,
					contentId: Config.relatedContent.contentId,
				},
				img: {
					default: Config.homepage.playerDefaultImg,
					basePicPath: Config.otherData.basePicPath,
				},
				favoriteTimer: {
					enableUpdate: Config.myWimbledon.enableUpdate,
					update: Config.myWimbledon.updateTime,
				},
			});
		});
	},

	toggleModal: data => (dispatch, getState, store) => {
		return dispatch({ type: deps.actionTypes.MYWIMBLEDON_TOGGLE_MODAL, data: { showModal: data } });
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MYWIMBLEDON_UNMOUNT });
	},

	removeShadowRole: role => (dispatch, getState, store) => {
		return deps.services.MyWimbledon.removeShadowRole(role, dispatch, store);
	},
};

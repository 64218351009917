import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsArchiveYear'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.DrawsArchiveYear.mount()),
	setYear: year => dispatch(deps.actions.DrawsArchiveYear.setYear(year)),
});

class TimelineMobile extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
		logger.log('[DrawsArchiveTimelineMobile] constructor - state: %o, props: %o', this.state, props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	onChangeSetYear(event) {
		event.preventDefault();
		logger.log('[DrawsArchiveTimelineMobile] render - state: %o, event: %o', this.state, event);

		this.props.setYear({
			event: event,
			year: event.target.value,
		});

		MeasurementUtils.dispatchMeasurementCall('drawsArchiveYearSelectorMobile', {
			year: event.target.value,
		});
	}

	renderYearOption(eventsDataByYear) {
		/*** eventsDataByYear data output example
            year: 1897, events: Array(4)}
            events: Array(4)
            0: {eventId: "MS", eventName: "Gentlemen's All-Comers' Singles"}
            1: {eventId: "MD", eventName: "Gentlemen's All-Comers' Doubles"}
            2: {eventId: "LS", eventName: "Ladies' All-Comers' Singles"}
            3: {eventId: "MP", eventName: "Gentlemen's Plate"}
        ***/
		const eventYear = eventsDataByYear.year;
		let stateYear = parseInt(this.state.year, 10);
		let klass = eventYear === stateYear ? 'selected' : '';

		return (
			<option key={eventYear} value={eventYear}>
				{eventYear}
			</option>
		);
	}

	render() {
		logger.log('[DrawsArchiveTimelineMobile] render - state: %o', this.state);
		if (this.state.eventsAll !== '') {
			const events = this.state.eventsAll;
			return (
				<div className="centerMenu">
					<select
						className="select-white tablet-show"
						name="select"
						data-dna="select-white"
						onChange={event => {
							this.onChangeSetYear(event);
						}}
						value={this.state.year}>
						{events.reverse().map(this.renderYearOption.bind(this))}
					</select>
				</div>
			);
		} else {
			return <div />;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineMobile);

import React, { createContext, useContext } from 'react';
import { checkRole } from 'appdir/components/general/Util/Role';
import { useSelector } from 'react-redux';
import op from 'object-path';
import Member from './index';
export const MemberContext = createContext({});

const MemberContextProvider = () => {
	const {
		Gigya: { currentUser, loggedIn, gigyaLoaded },
		Config: { member, myWimbledon },
	} = useSelector(state => state);
	let roleIds = op.get(currentUser, 'roles', []).map(role => role.roleId);
	const isMember = currentUser?.roles?.find(role => role.roleId === 1000040);

	let roleValidated = checkRole(op.get(currentUser, 'roles', []), roleIds, true) && isMember;

	return (
		<MemberContext.Provider
			value={{
				roleValidated,
				currentUser,
				loggedIn,
				gigyaLoaded,
				memberConfig: member,
				disableSection: myWimbledon?.version < 3,
			}}>
			<Member />
		</MemberContext.Provider>
	);
};

const useMemberContext = () => {
	const context = useContext(MemberContext);
	return context;
};

export { useMemberContext, MemberContextProvider };

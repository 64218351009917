import React from 'react';
import { useState } from 'react';

const CollapseBtn = props => {
	logger.log('[CollapseBtn] props:%o', props);

	const [direction, setDirection] = useState(props.defaultDirection ? props.defaultDirection : 'up');
	logger.log('[CollapseBtn] direction:%o', direction);

	const changeDirection = curDirection => {
		props.collapseFn(curDirection == 'up' ? false : true);
		setDirection(curDirection == 'up' ? 'down' : 'up');
	};

	return (
		<div
			className={`collapse-btn-container ${props.className || ''}`}
			style={{ cursor: 'pointer' }}
			tabIndex="0"
			role="button"
			aria-label="collapse button"
			onClick={e => changeDirection(direction)}
			onKeyPress={e => {
				if (e.key === 'Enter') {
					props.collapseFn();
				}
			}}>
			<i className={`wim-icon-${direction}-arrow`} />
		</div>
	);
};

export default CollapseBtn;

import axios from 'axios';
import { logger } from '../../logger';
import { ScoringConnection } from '../scoringConnection';
var pako = require('pako');

/**
 * Expose our createNew method to connect with!
 */
export class AndroidConnection extends ScoringConnection {
	constructor(connectionProperties) {
		super();
		this.connectionProperties = connectionProperties;
		this.currentMatches = [];
		this.currentScoreSubscriptions = [];
		this.currentStatsMatches = [];
		this.currentTopics = [];
	}

	createNew(connectionProperties = {}) {
		this.connectionProperties = this.validateProperites(connectionProperties);
		//logger.log('[AndroidConnection] - calling connectionReady');
		this.connectionProperties.connectionReady(true);
	}

	start() {
		this.initMessageSightConnectivity(this.connectionProperties);
	}

	stop() {
		this.currentTopics.forEach(currentTopic => {
			this.removeTopicRegistration(currentTopic);
		});
		this.currentTopics = [];
		this.currentMatches = [];
		this.currentStatsMatches = [];
		this.currentScoreSubscriptions = [];
		if (window.localStorage) {
			window.localStorage.removeItem('ixScoringMatches');
		}
	}

	addSlamtrackerForMatch(data) {
		// logger.info('[AndroidConnection] addSlamtrackerForMatch: %o', data);

		/**
		 * This module is called when we need to get stats for a match
		 *
		 * 1. Check that this.currentStatsMatches doesn't already have an active subscription for this match id
		 * 2. If not, add the topic subscriptions
		 * 3. If it does, do nothing
		 */
		let foundMatches = this.currentStatsMatches.filter(match => {
			return match.matchId == data.matchId;
		});
		if (foundMatches.length == 0) {
			logger.info(
				'[AndroidConnection] addSlamtrackerForMatch - adding stats subscription for match' + data.matchId
			);
			this.currentStatsMatches.push(data);
			if (window.localStorage) {
				window.localStorage.setItem('ixScoringMatches', JSON.stringify(this.currentStatsMatches));
			}
			this.addTopicRegistration(
				`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/stat/${data.matchId}`
			);

			if (data.statsLevel == 'K' || data.statsLevel == 'H') {
				return axios
					.get(this.connectionProperties.cPath.replace('<matchId>', data.matchId))
					.then(response => {
						if (response.status === 200) {
							// logger.log('cData response: %o', response);
							this.connectionProperties.onHistoryUpdate(response.data);
						}
					})
					.then(() => {
						this.addTopicRegistration(
							`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/slamtracker/${data.matchId}`
						);
					})
					.catch(error => {
						logger.log('[AndroidConnection] fetch C file - error: ' + JSON.stringify(error));
						if (error.response && error.response.status == 404) {
							logger.log('[AndroidConnection]fetch C file - error, file not found:' + error.response);
							throw error.response;
						} else {
							logger.log(
								'[AndroidConnection] fetch C file - error, general http erorr: ' + JSON.stringify(error)
							);
							throw error;
						}
					});
			}
		}
	}

	removeSlamtrackerForMatch(data) {
		/**
		 *
		 * // TODO: Amy/Robin
		 * This module is called when we need to stop calling stats data for a given match
		 *
		 * 1. Check that this.currentStatsMatches has an active subscription for this match id
		 * 2. If it does, add remove topic subscription
		 * 3. It it doesn't, do nothing
		 */
		let foundMatches = this.currentStatsMatches.filter(match => {
			return match.matchId == data.matchId;
		});
		if (foundMatches.length == 1) {
			logger.info(
				'[AndroidConnection] removeStatsForMatch  - removing stats subscription for match ' + data.matchId
			);
			this.currentStatsMatches = this.currentStatsMatches.filter(matches => {
				return matches != data.matchId;
			});
			if (window.localStorage) {
				window.localStorage.setItem('ixScoringMatches', JSON.stringify(this.currentStatsMatches));
			}
			this.removeTopicRegistration(
				`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/stat/${data.matchId}`
			);
			this.removeTopicRegistration(
				`events/tennis/${this.connectionProperties.year}/${this.connectionProperties.event}/slamtracker/${data.matchId}`
			);
		}
	}

	initMessageSightConnectivity(connectionProperties) {
		window.JSInterface.connect(connectionProperties.hostname);

		window.messageSightAndroidConnected = () => {
			this.addTopicRegistration(`events/tennis/${connectionProperties.year}/${connectionProperties.event}/mip`);
			logger.info('[AndroidConnection] - Connected, created mip subscription');

			if (window.localStorage) {
				let data = window.localStorage.getItem('ixScoringMatches');
				if (typeof data == 'string' && data.length > 0) {
					let storedMatches = JSON.parse(data);

					if (storedMatches.length > 0) {
						storedMatches.forEach(statsMatch => {
							this.addSlamtrackerForMatch(statsMatch);
						});
					}
				}
			}
		};

		window.messsageSightAndroidReceived = (topic, payload) => {
			let messageData = payload;
			if (typeof payload == 'string') {
				messageData = JSON.parse(payload);
			}

			logger.info('[AndroidConnection] - Received data: ' + JSON.stringify(messageData));

			// Pick the right processing method based on the topic (destinationName)
			if (topic.indexOf(`${connectionProperties.year}/${connectionProperties.event}/mip`) > -1) {
				this.processMipMessage(connectionProperties.year, connectionProperties.event, messageData);

				// Tell the controller we have a data update!
				connectionProperties.onDataUpdate(this.currentMatches);
			} else if (topic.indexOf(`${connectionProperties.year}/${connectionProperties.event}/score/`) > -1) {
				// logger.info('[AndroidConnection] - Need to process a score');
				this.processScore(messageData);

				// Tell the controller we have a data update!
				connectionProperties.onDataUpdate(this.currentMatches);
			} else if (topic.indexOf(`${connectionProperties.year}/${connectionProperties.event}/stat/`) > -1) {
				// logger.info('[AndroidConnection] - Need to process a stat update');

				// Tell the controller we have a stats update!
				connectionProperties.onStatsUpdate(messageData);
			} else if (topic.indexOf(`${connectionProperties.year}/${connectionProperties.event}/slamtracker/`) > -1) {
				// logger.info('[AndroidConnection] - Need to process a point history update');

				// Tell the controller we have a point history update!
				connectionProperties.onHistoryUpdate(messageData);
			}
		};

		window.messageSightAndroidConnectionError = () => {
			logger.info(
				'[AndroidConnection] - messageSightAndroidConnectionError - Connection failed.  Need to reconnect'
			);
			this.currentTopics = [];
			this.currentMatches = [];
			this.currentScoreSubscriptions = [];
			connectionProperties.onError();
		};
	}

	validateProperites(connectionProperties) {
		if (connectionProperties.hostname == undefined) {
			logger.error('[AndroidConnection] - Error, no hostname supplied.');
			throw 'Hostname for connection is required';
		}
		if (connectionProperties.port == undefined || isNaN(connectionProperties.port)) {
			logger.info('[AndroidConnection] - No port supplied, defaulting to 443.');
			connectionProperties.port = 443;
		}

		if (connectionProperties.onDataUpdate == undefined) {
			logger.info('[AndroidConnection] - No onDataUpdate supplied, defaulting to no-op function.');
			connectionProperties.onDataUpdate = data => {};
		}

		if (connectionProperties.onStatsUpdate == undefined) {
			logger.info('[AndroidConnection] - No onStatsUpdate supplied, defaulting to no-op function.');
			connectionProperties.onStatsUpdate = data => {};
		}

		if (connectionProperties.onHistoryUpdate == undefined) {
			logger.info('[AndroidConnection] - No onHistoryUpdate supplied, defaulting to no-op function.');
			connectionProperties.onHistoryUpdate = data => {};
		}

		return connectionProperties;
	}

	processMipMessage(year, event, mipData) {
		logger.log('[AndroidConnection] - processMipMessage mipData: ' + JSON.stringify(mipData));

		let matchesOnCourt = [];
		// Add matches we don't already have!

		mipData.matches.forEach(mipMatch => {
			let match = this.currentMatches.filter(currentMatch => {
				return currentMatch.match_id == mipMatch.match_id;
			});
			if (match.length == 0) {
				// new match so add it in and update this to have a score subscription..
				matchesOnCourt.push(mipMatch);
			} else {
				// We already have this match, cover over as is..
				matchesOnCourt.push(match[0]);
			}
		});

		// Set back into the global variable asap to avoid race conditions...
		this.currentMatches = matchesOnCourt;

		// LEt's loop through and add any new subscriptions we should add
		this.currentMatches.forEach(currentMatch => {
			if (this.currentScoreSubscriptions.indexOf(currentMatch.match_id) == -1) {
				// We need to add this one!
				this.currentScoreSubscriptions.push(currentMatch.match_id);
				this.addTopicRegistration(`events/tennis/${year}/${event}/score/${currentMatch.match_id}`);
			}
		});

		// Now lets work out if we need to take matches off court
		let matchIdsToRemove = [];
		this.currentScoreSubscriptions.forEach(currentSubscription => {
			let foundMatch = this.currentMatches.filter(currentMatch => {
				return currentMatch.match_id == currentSubscription;
			});
			if (foundMatch.length == 0) {
				matchIdsToRemove.push(currentSubscription);
			}
		});

		// If we found some to remove then remove them...
		if (matchIdsToRemove.length > 0) {
			// We need to remove some matches...
			matchIdsToRemove.forEach(id => {
				this.currentScoreSubscriptions = this.currentScoreSubscriptions.filter(subscription => {
					return subscription != id;
				});
				this.removeTopicRegistration(`events/tennis/${year}/${event}/score/${id}`);
			});
		}

		// Let's log our our current state for helpfulness
		logger.log('[AndroidConnection] - MIP update, match ids on court are ' + JSON.stringify(this.currentMatches));
	}

	processScore(scoreData) {
		logger.info('[AndroidConnection] - processScore ' + JSON.stringify(scoreData));
		for (var i = 0; i < this.currentMatches.length; i++) {
			if (this.currentMatches[i].match_id == scoreData.match_id) {
				this.currentMatches[i] = scoreData;
			}
		}
	}

	processStats(statsData) {
		/**
		 * // TODO Amy/Robin
		 *
		 * 1. Decide if we need to locally store stats in this object or just return them, we stored the MIP/Score data so we could return all courts, do we need that here?
		 * 2. Once decided on 1. this method should call connectionProperties.onStatsUpdate(statsData); to pass the data backup
		 */
		logger.info('[AndroidConnection] - processStats ' + JSON.stringify(statsData));
	}

	addTopicRegistration(topic) {
		// logger.info('[AndroidConnection] - Adding topic %o', topic);
		window.JSInterface.subscribe(topic);
		this.currentTopics.push(topic);
		logger.info('[AndroidConnection] - Added topic ' + topic);
	}

	removeTopicRegistration(topic) {
		window.JSInterface.unsubscribe(topic);
	}
}

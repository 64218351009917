import PlayerChallengePage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: '/:lang/scores/challenge/index.html',
	exact: true,
	component: PlayerChallengePage,
	section: MENU_ITEMS.players,
	load: params => deps.actions.PlayerChallengePage.mount(params),
};

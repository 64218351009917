import React, { useEffect } from 'react';
import useAxios from '../../pages/Member/hooks/useAxios';
import moment from 'moment';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { useSelector } from 'react-redux';
const RemainingBooking = props => {
	const bookingLimits = useSelector(state => state.Config.member.api.bookingLimits);

	const { response, loading, invokeRequest, createUrlParameters } = useAxios();

	if (loading !== props.remainingBookingLoaded) props.setRemainingBookingLoaded(loading);

	useEffect(() => {
		const data = { date: moment(new Date()).format('yyyy-MM-DD') };
		invokeRequest('booking-limits', 'GET', {}, bookingLimits, data, createUrlParameters(data));
	}, []);
	return (
		<>
			{loading ? (
				<LoadingIndicator />
			) : (
				<div className="cms-bookings-wrapper one-col">
					<p className="cms-bookings-header">Remaining Court Bookings</p>
					<div className="booking-entry-wrapper">
						<div className="purple-box">
							<p className="booking-entry">{response?.response?.today}</p>
						</div>
						<p>Today</p>
					</div>
					<div className="booking-entry-wrapper">
						<div className="purple-box">
							<p className="booking-entry">{response?.response?.thisWeekend}</p>
						</div>
						<p className="booking-entry">This weekend</p>
					</div>
					<div className="booking-entry-wrapper">
						<div className="purple-box">
							<p className="booking-entry">{response?.response?.thisWeek}</p>
						</div>
						<p>This week</p>
					</div>
				</div>
			)}
		</>
	);
};

export default RemainingBooking;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MATCHRESULT_MOUNT:
			newState = {
				...state,
				dataPath: action.data,
			};
			return newState;
			break;

		default:
			return state;
	}
};

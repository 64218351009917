import deps from 'dependencies';

export default {
	// mount: params => (dispatch, getState, store) => {
	// 	return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
	// 		let data = {};

	// 		dispatch({ type: deps.actionTypes.PRIVACYBANNER_MOUNT, data: data });
	// 	});
	// },

	// hide: () => dispatch => {
	// 	dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: { privacy: { viewed: true } } });
	// },
};

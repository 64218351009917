import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SCROLLHANDLER_MOUNT, data: null });
	},

	unmount: data => (dispatch, getState, store) => {
		//logger.log('[PageHeader] mount: %o');
		dispatch({ type: deps.actionTypes.SCROLLHANDLER_UNMOUNT, data: data });
	},
	setAnimationFlag: flag => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SCROLLHANDLER_ANIMATEDFLAG, data: flag });
	},
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'appdir/main';
import HelpModal from 'appdir/components/common-ui/HelpModal';
import WimLink from 'appdir/components/general/WimLink';

import { getInterfaceLink } from 'appdir/components/general/Util/index.js';
import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';
import { passAppData } from 'appdir/components/general/Util';

const IBMwatsonx = props => {

    let styleClasses = props?.styleClasses ?? '';

	const configText = useSelector(state => state['Config']?.text?.aiCatchUp);

    const [openModal, setState] = useState(false);

    /** clean up on unmount */
	useEffect(() => {
		return function cleanup() {
			document.body.classList.remove('modal-on-noscroll-nofixed');
		};
	}, []);

    useEffect(() => {
		/** when HelpModal is set to display, add a class to the body to prevent
		 *  the main content to scroll
		 *  lower header-global z-index as it is higher
		 */

		if (openModal) {
			document.body.classList.add('modal-on-noscroll-nofixed');
		} else {
			document.body.classList.remove('modal-on-noscroll-nofixed');
		}
	}, [openModal]);

    const infoClick = () => {

        if(!window.webview) {

            /** close icon is clicked 
             *  close call on webview will be done by native
             *  native owns the modal
            */
            if(openModal) {
                measureInWeb({
                    action: "Modal Info",
                    context: [{ section: 'Catch Me Up' }, {action: 'Close'}],
                });
            } else {
                measureInWeb({
                    action: 'pageView',
                    context: [{item: 'watsonx Info'}, { section: 'Catch Me Up' }],
                });
            }

            setState(openModal ? false : true);

        } else {
            measureInAppContext({
				pageTitle: 'Catch Me Up',
				action: 'watsonx Info',
				args: [],
				context: [{ section: 'Catch Me Up' }, {action: 'Open'}],
				metricType: 'state',
			});

            //for app modal (?) icon
            const appData = {
                type: "info_modal",
                data: {
                  title: configText?.title + ' with IBM watsonx',
                  text: configText?.helpText
                }
            };
            passAppData(appData);
            logger.log('[IBMwatsonx] appData:%o ', appData);
        }
    }
    
    let modalHeaderText = configText?.title?.toUpperCase() + ' ' + configText?.extend_title;
    let modalBodyText = configText?.helpText;
    // logger.log('[IBMwatsonx] configText:%o, modalHeaderText:%o', configText, modalHeaderText);

    return (
        <div className={`${styleClasses}`}>
            {configText?.subText} <i className="wim-icon-watsonx" />
            {
                !props?.data?.hideInfoIcon && (
                    <div className="question">
                        <i 
                            className="wim-icon-help"
                            onClick={() => {infoClick()}}
                        />
                    </div>
                )
            }
            { 
                openModal && modalBodyText && !window.webview && (
                        <HelpModal 
                            header={modalHeaderText} 
                            body={modalBodyText} 
                            headerTextClass="narrow"
                            closeModal={infoClick} 
                        />
                    )
            }
        </div>
    )

}

export default IBMwatsonx;
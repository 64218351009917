/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { OIDCContext } from './context';

import op from 'object-path';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import MeasurementUtils from 'appdir/lib/analytics';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import GigyaScreenSet from 'appdir/components/general/Gigya/GigyaScreenSet';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component:Login
 * -----------------------------------------------------------------------------
 */

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		this.showLogin = false;
		this.parsedQs;
	}

	componentDidMount() {
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		let data = {};

		data.pageTitle = 'Login-OIDC';
		data.redirect = op.get(this.parsedQs, 'redirect', '');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, data);
	}

	componentWillUnmount() {
		//this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[OIDC_Login] componentDidUpdate - prev:%o state:%o', prevState, this.state);
		logger.log('[OIDC_Login] componentDidUpdate - context:%o', this.context);

		let url = '';
		if (!this.context.loggedIn && this.context.gigyaLoaded) {
			this.showLogin = true;
		} else if (this.context.loggedIn && this.context.gigyaLoaded && this.showLogin) {
			url = gigya.utils.URL.addParamsToURL(this.context.proxy, {
				mode: 'afterLogin',
			});
			window.location.href = url;
		} else if (this.context.loggedIn && this.context.gigyaLoaded && !this.showLogin) {
			this.setState({
				redirect: '/en_GB/myWimbledon/index.html',
			});
		}
	}

	render() {
		logger.log('[OIDC_Login] render - state:%o context:%o', this.state, this.context);

		return (
			<Fragment>
				{!this.context.loggedIn && this.context.gigyaLoaded ? (
					<GigyaScreenSet params={{ screen: 'login_screen', social: this.context.social }} inline={true} />
				) : this.state.redirect ? (
					<WimRedirect to={`${this.state.redirect}`} />
				) : (
					<LoadingIndicator />
				)}
			</Fragment>
		);
	}
}

Login.contextType = OIDCContext;
export default Login;

import deps from 'dependencies';
import axios from 'axios';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'MyWimbledon Delete',
		});

		//logger.log('[ContactPage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				subjects: Config.contact.subjects,
				topics: Config.contact.topics,
			};

			logger.log('[MyWimbledonDelete] action.mount - data:', data);

			dispatch({ type: deps.actionTypes.MYWIMBLEDONDELETE_MOUNT, data: data });
		});
	},
};

import isEmpty from 'lodash/isEmpty';
import { any } from 'appdir/components/general/Util/Functions';
import op from 'object-path';
import axios from 'axios';

/**
 * determine if in a logged in state
 * @param {*} user
 * @returns
 */
export const loggedIn = user => {
	logger.log('[ContentPage] loggedIn - user:%o', user);

	if (isEmpty(user)) {
		return false;
	} else if (op.get(user, 'errorCode', -1) != 0) {
		return false;
	} else {
		return true;
	}
};

/**
 * return role by role id
 * @param {*} roles
 * @param {*} roleid
 * @returns
 */
export const getRole = (roles, roleid) => {
	//logger.log('[ContentPage] checkRole - roles:%o roleids:%o', roles, roleids);

	let role = roles.filter(r => {
		return r.roleId == roleid || r.key == roleid;
	});

	return role.length > 0 ? role[0] : null;
};

/**
 * return role by key value (same as getRoleByName)
 * @param {*} roles
 * @param {*} key
 * @returns
 */
export const getRoleByKey = (roles, key) => {
	//logger.log('[ContentPage] checkRole - roles:%o roleids:%o', roles, roleids);

	let role = roles.filter(r => {
		return r.key == key;
	});

	return role.length > 0 ? role[0] : null;
};

/**
 * return role by name (same as getRoleByKey)
 * @param {*} roles
 * @param {*} name
 * @returns
 */
export const getRoleByName = (roles, name) => {
	//logger.log('[ContentPage] checkRole - roles:%o roleids:%o', roles, roleids);
	let role = [];
	if (roles) {
		role = roles.filter(r => {
			return r.key == name;
		});
	}

	return role.length > 0 ? role[0] : null;
};

/**
 * Checks if a required role id is found in list of roles
 *  returns boolean or undefined if required
 * @param {*} roles
 * @param {*} roleids
 * @param {*} required
 * @returns
 */
export const checkRole = (roles, roleids, required = false) => {
	//logger.log('[ContentPage] checkRole - roles:%o roleids:%o', roles, roleids);

	let found =
		roleids.length == 0 ||
		any(roles, role => {
			return any(roleids, id => id == role.roleId) && role.approved;
		});

	if (required && roleids.length == 0) {
		found = undefined;
	}

	return found;
};

/**
 * check if postcode is in the list
 * @param postCodes - array of available post codes from config
 * @returns boolean
 */
export const postCodeAvailable = (postCodes, zip) => {
	let available = false;
	let zipToCheck = zip.replace(/ /g, '');
	postCodes = postCodes.map(i => {
		if (i.replace(/ /g, '') === zipToCheck) {
			available = true;
		}
	});

	return available;

	// let regex = new RegExp(postCodes.join('|'), 'i');
	// console.log(regex, zip);
	// return regex.test(zip.replace(/ /g, ''));
};

export const reconsentRole = (path, roleType, id_token) => {
	logger.log('[Role] reconsentRole - path:%o ', path);

	return new Promise((resolve, reject) => {
		//prepare API call
		let headers = {
			Authorization: `Bearer ${id_token}`,
			Accept: 'application/json',
		};

		axios
			.post(`${path.replace('<roleName>', roleType)}`, {}, { headers })
			.then(response => {
				logger.log('[Role] reconsentRole - success', response);
				resolve(response);
			})
			.catch(error => {
				logger.error('[Role] reconsentRole - error:%o', error);
				reject(error);
			});
	});
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Modal to dislay simple text info
 * @props - content: header, body
 *          closeModal: callback for X button
 * -----------------------------------------------------------------------------
 */
const HelpModal = props => {
	return (
		<div className="help-modal">
			<div className="help-modal-inner">
			<div className="modal-container">
				<div className="modal-close" onClick={() => props.closeModal()}>
					<i className="wim-icon-close" />
				</div>
				<div className={`modal-header ${props?.headerTextClass ?? ''}`}>{props.header}</div>
				<hr className="spacerLine" />
				<div className="modal-body">{props.body}</div>
			</div>
			</div>
		</div>
	);
};

export default HelpModal;

export const roleFeatures = [
	{
		ids: [1000260],
		pageTitle: 'Debentures',
	},
	{
		ids: [1000250],
		pageTitle: 'Media',
	},
	{
		ids: [1000050],
		pageTitle: 'Player',
	},
	{
		ids: [1000090],
		pageTitle: 'Ticket',
	},
	{
		ids: [1000290],
		pageTitle: 'LTA Ballot',
	},
	{
		ids: [1000320],
		pageTitle: 'Coach',
	},
];

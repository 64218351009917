import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		logger.log('[SmartSearch] action.mount');
		//do we need analytics for the smartsearch
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			dispatch({ type: deps.actionTypes.SMARTSEARCH_MOUNT });
		});
	},
};

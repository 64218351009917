import React, { Fragment, useState } from 'react';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import useBookings from './useBookings';
import WimLink from 'appdir/components/general/WimLink';
import Modal from '../../../../common-ui/Modal';
import BookingConfirmationDetails from '../DiningReservation/BookingConfirmationDetails';
import TabNavigation from 'appdir/components/cms/TabNavigation';

const RemainingBookings = () => {
	const { bookingData, loading, cancelBooking, showEmptyMsg, showErrorMsg, updateBookingNotes } = useBookings();

	const [showBookingConfirmation, setShowBookingConfirmation] = useState(false);
	const [bookingSelected, setBookingSelected] = useState();
	const [editableBooking, setEditableBooking] = useState(false);

	return (
		<Fragment>
			<div className="four-col ">
				<TabNavigation data={{ key: 'memberBookings' }} />
			</div>
			<div className="remaining-bookings">
				{loading ? <LoadingIndicator /> : null}
				<div style={{ visibility: loading ? 'hidden' : 'visible' }}>
					<div className="remaining-bookings__header">
						<p className="remaining-bookings__header--title">CURRENT BOOKINGS</p>
						<div className="remaining-bookings__header--purple-line"></div>
						<p className="remaining-bookings__header--description">
							In order for access to be granted, players who are not Members must be entered on the guest
							request booking system.
							<br />
							Please check the weather and{' '}
							<WimLink to="/s/member/service/court-availability">court status</WimLink> before traveling
						</p>
						<div className="remaining-bookings__booking-list four-col">
							{bookingData && bookingData?.length && !showEmptyMsg ? (
								bookingData?.map((booking, index) => {
									return (
										<li key={index * 100} className="remaining-bookings__booking-list-item">
											<div className="remaining-bookings__circle-wrapper">
												<div
													className={`remaining-bookings__booking-circle`}
													style={{
														backgroundColor:
															(booking?.isMainGuest && booking.type === 'court') ||
															booking.type === 'restaurant'
																? 'rgb(165, 139, 39)' // gold dot
																: booking?.isMainGuest === false &&
																  booking.type === 'court'
																? 'rgb(156, 156, 156)' // grey dot
																: 'rgb(0, 97, 42)', // green dot
													}}
												/>
												<p
													className="green"
													style={{
														cursor:
															booking?.type === 'restaurant' || booking?.url
																? 'pointer'
																: 'default',
													}}>
													{booking.type === 'restaurant' || !booking.url ? (
														<button
															onClick={() => {
																if (booking.type === 'restaurant') {
																	setShowBookingConfirmation(true);
																	setBookingSelected(booking);
																}
															}}
															className={
																booking?.type === 'restaurant'
																	? 'remaining-bookings__booking-list-item__actionable_item'
																	: ''
															} // only restaurants because otherwise it is an unactionable booking since there is no URL
														>
															{booking?.type === 'restaurant' || booking?.type === 'court'
																? booking.venueName
																: booking.location}
														</button>
													) : (
														<a href={booking.url} target="_self">
															{booking?.type === 'court'
																? booking.venueName
																: booking.location}
														</a>
													)}
												</p>
											</div>
											<p>{booking.name}</p>
											<p>{booking.date}</p>
											<p>
												{booking?.type === 'restaurant' || booking?.type === 'court'
													? booking.location
													: booking.venueName}
											</p>
										</li>
									);
								})
							) : showErrorMsg ? (
								<div style={{ color: 'red', textAlign: 'center', margin: '8px' }}>
									Error loading bookings. Please try again.
								</div>
							) : (
								<div style={{ textAlign: 'center', margin: '8px' }}>No Current Bookings Found.</div>
							)}
						</div>
						{showBookingConfirmation ? (
							<Modal
								toggleModal={() => {
									setShowBookingConfirmation(!showBookingConfirmation);
									if (editableBooking) setEditableBooking(false);
								}}>
								<BookingConfirmationDetails
									firstName={bookingSelected?.name}
									date={bookingSelected?.date}
									size={bookingSelected?.size}
									note={bookingSelected?.note}
									cancelBooking={cancelBooking}
									resId={bookingSelected?.resId}
									isSimpleConfirmation
									onCloseModal={() => setShowBookingConfirmation(false)}
									service={bookingSelected?.venueName}
									venueKey={bookingSelected?.key}
									venueId={bookingSelected?.venueId}
									venueTitle={bookingSelected?.location}
									editableBooking={editableBooking}
									setEditableBooking={setEditableBooking}
									updateBookingNotes={updateBookingNotes}
								/>
							</Modal>
						) : null}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RemainingBookings;

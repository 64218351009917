import React from 'react';
import { useSelector } from 'react-redux';

/**
 * Return just the flag image
 * @param {*} props.attributes
 * @param {String} country
 * @param {String} flagImagePath
 * @returns
 */
const CountryFlagImage = props => {
	let { attributes = {} } = props;

	if (attributes?.flagImagePath && attributes?.country) {
		return <img src={attributes.flagImagePath?.replace('<code>', attributes.country)} alt={attributes.country} />;
	}
};

export default CountryFlagImage;

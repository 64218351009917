import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { playerProfileJson } from 'appdir/components/data/Schema';

const v = new Validator();

const fetch = path => {
	let hdr = restHeaders();

	logger.log('[PlayerProfile] fetch - path:%o', path);

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			//logger.log('[PlayerProfile] fetch - %o', response);
			if (response.status === 200) {
				let validateResult = v.validate(response.data, playerProfileJson);
				if (validateResult.valid) {
					return response.data;
				} else {
					// throw error and go to catch block
					throw new Error('failed schema validation');
				}
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			logger.warn('[PlayerProfile] fetch error - %o', error);
			throw new Error();
		});
};

export default {
	fetch,
};

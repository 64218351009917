import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	switch (action.type) {
		case deps.actionTypes.LIVEVIDEOHILL_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[LiveVideo] LIVEVIDEO_MOUNT - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

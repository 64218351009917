import deps from 'dependencies';
import axios from 'axios';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

/**
 generic load data function
 allows processing of data in promise
 */
export const getData = url => {
	return new Promise((resolve, reject) => {
		return axios
			.get(url)
			.then(result => {
				if (result?.statusText != 'error') {
					resolve(result.data);
				} else {
					reject(new Error('parse error'));
				}
			})
			.catch(err => {
				reject(err);
			});
	});
};


export const isLoaded = (state, key) => {
	return state['Tournament']['data'][key];
};


/**
 generic load data function
 loads and dispatches update for the given key
 */
export const loadData = (files, dispatch, loadNew, other) => {
	if (loadNew) {
		let requests = files.map(file => {
			dispatch({ type: deps.actionTypes.TOURNAMENT_LOADING, key: file.key, status: 'loading' });
			return axios.get(file.url);
		});

		return axios
			.all(requests)
			.then(data => {
				data.forEach((result, i, a) => {
					if (result?.statusText != 'error') {
						dispatch(updateObject(files[i]?.key, result.data, 'loaded', null));
					} else {
						dispatch(updateObject(files[i]?.key, [], 'error', 'error - json parse'));
					}
				});
			})
			.catch(err => {
				logger.error('[Tournament] loadData - err:%o', err);
				dispatch(updateObject(files[i]?.key, [], 'error', err));
			});
	}
};

/**
 * load data through CommonData component
 *  - used sharedData config in config web
 */
export const loadCommonData = (config, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(deps.actions.CommonData.checkExpired(config))
			.then(response => {
				//logger.log('[Tournament] loadCommonData - response:%o', response);
				if (response.status == 'expired') {
					dispatch(deps.actions.CommonData.update(config)).then(resp => {
						logger.log('[Tournament] loadCommonData - response:%o', resp);
						if (resp == 'loaded') {
							resolve(true);
						} else {
							reject({ });
						}
					});
				} else {
					logger.log('[Tournament] loadCommonData - notexpired:%o', response);
					resolve(true);
				}
			})
			.catch(error => {
				//logger.error('[Tournament] deps.actions.getMatchInsightsAvailable - error:o', error);
				dispatch(deps.actions.CommonData.error(config));
				reject({ error: 'error loading' });
			});
	});
};

/**
 * form an update object to dispatch
 */
export const updateObject = (key, data, status, errMessage) => {
	let dispatchObject = {
		type: deps.actionTypes.TOURNAMENT_UPDATE,
		key: key,
		data: data,
		status: status,
		updated: moment().toISOString(),
	};
	if (errMessage) {
		dispatchObject.type = deps.actionTypes.TOURNAMENT_ERROR;
		dispatchObject.errorMessage = errMessage;
	}

	return dispatchObject;
};
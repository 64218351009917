/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import { validateData } from 'appdir/components/general/Util';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Helmet from 'react-helmet';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import PlayerEventStats from './PlayerEventStats';
import PlayerStatsSelect from './PlayerStatsSelect';
import StubBox from 'appdir/components/common-ui/StubBox';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerStatsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['PlayerStatsPage'], { stubs: state['Config'].stubPages }, props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerStatsPage.mount()),
});

class PlayerStatsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			genderType: 'ms',
		};

		logger.log('[PlayerStatsPage] constructor - state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[PlayerStatsPage] componentWillReceiveProps');

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[PlayerStatsPage] componentDidUpdate');
		if (
			this.state.stubs &&
			this.state.stubs.playerstats.stub !== 'stub' &&
			this.state.path &&
			(!this.state.playerStats || this.state.genderType !== prevState.genderType)
		) {
			deps.services.PlayerStatsPage.fetch(this.state.path.replace('<gender>', this.state.genderType)).then(
				result => {
					this.setState(prevState => {
						return {
							...prevState,
							playerStats: result,
						};
					});
				}
			);
		}
	}

	componentDidMount() {
		logger.log('[PlayerStatsPage] componentDidMount');
	}

	componentWillUnmount() {
		logger.log('[PlayerStatsPage] componentWillUnmount');
	}

	showData(type) {
		MeasurementUtils.dispatchMeasurementCall('statsTabClick', {
			tab: type == 'ms' ? "Gentlemen's" : "Ladies'",
		});
		this.setState(prevState => {
			return { ...prevState, genderType: type };
		});
	}

	render() {
		logger.log('[PlayerStatsPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'scores',
			title: 'Player Statistics',
			shortTitle: 'Player Statistics',
			metaTitle: 'Player Statistics',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		if (this.state.stubs && this.state.stubs.playerstats.stub === 'stub') {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<StubBox attributes={{ message: this.state.stubs.playerstats.text, style: 'centerV' }} />
					</div>
					<Footer />
				</Template>
			);
		} else if (this.state.playerStats && validateData(this.state.playerStats.event_stats, 'array')) {
			//format the dropdown data
			let selectData = this.state.playerStats.event_stats
				.filter((d, i) => i !== 0)
				.map((d, i) => {
					return { name: d.stat_label, value: d.stat_label };
				});

			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main player-stats">
						<div className="column-layout">
							<div className="four-col">
								<div className="player-data-toggle">
									<span
										onClick={() => this.showData('ms')}
										className={this.state.genderType == 'ms' ? 'show' : ''}>
										Gentlemen's
									</span>{' '}
									|{' '}
									<span
										onClick={() => this.showData('ls')}
										className={this.state.genderType == 'ls' ? 'show' : ''}>
										Ladies'
									</span>
								</div>
								<div className="header">
									<div className="navItemFixed">
										<PlayerStatsSelect selectData={selectData} filters={this.state.filters} />
									</div>
								</div>
								<PlayerEventStats data={{ ...this.state.playerStats }} />
							</div>
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator type={'white'} />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatsPage);

import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import AIDaySummary from './elements/AIDaySummary';
import AIPlayerCard from './elements/AIPlayerCard';
import SiteMap from 'appdir/components/general/SiteMap';
import AICatchUpHeader from 'appdir/components/pages/AICatchUp/elements/AICatchUpHeader';
import { measureInWeb } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import { getQuerystringValues, showSummaryFirst, fetch } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: AICatchUp
 * page component for displaying all the current player cards loaded by the Tournament Data component
 * -----------------------------------------------------------------------------
 */

const AICatchUp = props => {
    const configStatus = useSelector(state => state['Config']?.status);
    const enabled = useSelector(state => state['Config']?.enabled);
	const configText = useSelector(state => state['Config']?.text?.aiCatchUp);
    const playerGen = useSelector(state => state['Tournament']?.data?.playerGen);
	const daySummaryGen = useSelector(state => state['Tournament']?.data?.daySummaryGen);
    const stubPages = useSelector(state => state['Config']?.stubPages?.aiCatchUp);
    const sidePanel = useSelector(state => state['Controller']['sidepanel']['userOpen']);
	const matchHighlights = useSelector(state => state['Config']?.relatedContent?.matchHighlights);
    const dispatch = useDispatch();
    const sendPageView = useRef(true);

    let search = window.location.search.replace(/^\?/, '');
    let parsedQs = getQuerystringValues(search);
    const [selectedPlayer, setSelectedPlayer] = useState(window.location.search ? parsedQs.id?.split(',') : []);
    const [showSummaryTop, setShowSummaryTop] = useState('');
    const [highlightsIds, setHighlightsIds] = useState([]); // store post match ids if there is AI Highlights for the match

    //player data
    const playerGenData = playerGen?.data?.data;
    const daySummaryGenData = daySummaryGen?.data?.data;
    
    useEffect(() => {
        if(!isEmpty(enabled) && enabled) {
            // dispatch(deps.actions.Tournament.getPlayerGen({playerIds: ['wta328560','atptd51','wta312001']})); //specific players
			let debug = null;
            if (parsedQs?.debugcards == 'all') {
				debug = {'playerIds':'all'}
			}
			dispatch(deps.actions.Tournament.getPlayerGen(debug));
        }
        dispatch(deps.actions.Tournament.getDaySummaryGen());
        setShowSummaryTop(showSummaryFirst());
    }, [enabled, showSummaryTop]);

    /** fire page views when all the data are ready and send all the ids and names */
    useEffect(() => {
        if((playerGen?.status == "loaded" && playerGenData) || (daySummaryGen?.status == "loaded" && daySummaryGenData)) {

            let playerIds = [];
            let playerNames = [];
            let matchIdsArry = [];
            let foundMatchIds = [];

            if(daySummaryGenData && Object.keys(daySummaryGenData).length > 0) {
                playerIds.push(`summaryDay${daySummaryGenData?.day}`);
                playerNames.push(`summaryDay${daySummaryGenData?.day}`);
            }

            if (playerGenData?.length > 0) {
                {playerGenData.map((player, index) => {
                        playerIds.push(player.id);
                        playerNames.push(`${player.firstName} ${player.lastName}`);

                        /** store all the post match IDs to check if any of them have a higlight video */
                        if(player.type == "post") {
                            matchIdsArry.push(player.matchId);
                        }
                    });
                }

                /** 
                 * pass all the match ids to fetch 
                 * related content all once 
                 * and save the cmsId with the match id
                 * so the button click plays the highlight video
                 */
                if(matchIdsArry?.length > 0) {
                    let tags = '';
                    matchIdsArry.map((id, index) => {
                        index == 0 ? (
                            tags += `tags=${id}`
                        )
                        : (
                            tags += `&tags=${id}`
                        )
                    })
                    let url = matchHighlights.replace('<matchTags>', tags);
                    logger.log('[AICatchUp] matchIds useEffect - url:%o', url); 
        
                    /** fetch related content and sore match id and video id */
                    fetch(url)
                    .then(result => {
                        logger.log('[AICatchUp] fetch rc result:%o', result);

                        result?.content?.map((item, index) => {
                            foundMatchIds.push({
                                    matchId: item?.metadata?.matchId,
                                    vidId: item?.cmsId
                                });
                        })

                        setHighlightsIds(foundMatchIds);
                    })
                    .catch(error => {
                        logger.error('[AICatchUp] fetch rc error:%o', error);
        
                        setHighlightsIds([]);
                    });
                }

            }

            if(playerGen?.status && daySummaryGen?.status && sendPageView.current) {
                sendPageView.current = false;
                /** 
                 *  avoid send double calls, wait for both feeds are fetched
                */
                measureInWeb({
                    action: 'pageView',
                    context: [{ playerIds: playerIds, playerNames: playerNames, section: 'Catch Me Up' }],
                });

            }
        }
    }, [playerGen, daySummaryGen, sendPageView]);


    useLayoutEffect(() => {
        if(selectedPlayer){
            const summaryFirst = showSummaryFirst(); // because this happens before setting showSummaryTop state
            const card = selectedPlayer[0];
            const element = document.getElementById(card);
            const headerOffset = 120;
            const elementPosition = element?.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - headerOffset;

            if(card !== "summary" || (!summaryFirst && card == "summary")) {
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'instant'
                });
                // logger.log('[AICatchUpHome element: %o', element); 
                setTimeout(() => {
                    //hack to add header background after load
                    window.scrollTo({
                        top: offsetPosition + 1
                    });
                },1000);    

            }
        }
        
    }, []);

    const setHeaderData = () => {
        let pageName = configText?.title;

        let data = {
            pageName,
            showRightLink: true,
            hideInfoIcon: false,
        }

        return data;
    }

    const displaySummary = () =>{
        logger.log('[AICatchUpHome displaySummary - daySummaryGenData: %o, daySummaryGen:%o', daySummaryGenData, daySummaryGen); 
        if(daySummaryGenData && Object.keys(daySummaryGenData).length > 0){
            return(
                <div className="ai-catchup-summary column-layout four-col">
                    <AIDaySummary 
                        data={daySummaryGen.data.data} 
                        cardType="fullWeb"
                    />
                </div>
            );
        }else{ 
            return null;
        }
        
    }

    /**
     * 
     * @param {*} matchId 
     * @returns cmsId from related content
     * 
     *  find the same match id obj from highlightsIds
     *  and get the video id
     */
    const getHighlightVidId = (matchId) => {
        let vidId;

        let match = highlightsIds.filter(item => item.matchId == matchId);
        if(match?.length > 0) {
           vidId = match[0].vidId;
        }

        return vidId;

    }
        
    let header_propsData = {};

    if(enabled?.aiCatchUp){
        header_propsData = {
            headerType: 'scores',
            title: configText?.nav_title,
            shortTitle: configText?.nav_title,
            metaTitle: configText?.nav_title,
            metaDescription: '',
            metaDate: '',
            metaPlayers: '',
        };
    }else{
        header_propsData = {
            headerType: 'generic',
            title: 'Sitemap',
            metaTitle: 'Page not found',
            metaDescription: '',
            metaDate: '',
            metaPlayers: '',
        };
    }

    let classSP = sidePanel ? '' : 'sideoff';
    logger.log('[AICatchUpHome] playerGen:%o, highlightsIds:%o, configStatus:%o', playerGen, highlightsIds, configStatus);
    return (
        <Template className={enabled?.aiCatchUp ? 'scorespage' : ''}>
            <div className="scores-background" />
            <Header attributes={header_propsData} />

            <PageHeader attributes={header_propsData} />
            {stubPages?.stub === "pre" ? (
                <div className="content-main ai-catchup">
                    <StubBox attributes={{ message: stubPages?.text, style: 'centerV' }} />
                </div>
            ) : enabled?.aiCatchUp && configStatus ? (
                <div className="content-main ai-catchup">
                    <AICatchUpHeader data={setHeaderData()} />
                    {playerGen?.status == "loaded" && playerGenData?.length > 0 ?(
                        <div className="column-layout ai-catchup-cards-container">
                            { showSummaryTop ? displaySummary() : ''}
                            <div className={`ai-catchup-cards column-layout four-col ${classSP}`}>
                                {playerGenData.map((player, index) =>{
                                    return(
                                        <AIPlayerCard  data={{...player, highlightsVidId: getHighlightVidId(player.matchId) }} key={`aiPC${index}${player.id}`}/>
                                    );
                                })}
                            </div>
                            { !showSummaryTop ? displaySummary() : ''}
                        </div>
                    ): playerGenData?.length == 0 ? (
                        <div className="column-layout">
                            <div className="column-layout ai-catchup-cards-container">
                                { displaySummary()}
                                { daySummaryGenData && Object.keys(daySummaryGenData).length == 0 && (

                                    <p className="nodata">
                                        {configText?.nodata}
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="column-layout">
                            <LoadingIndicator type="white" />
                        </div>

                    )}
                </div>
            ): configStatus == "loaded" && enabled?.aiCatchUp ? (
                <div className="content-main ai-catchup notfound">
                    <SiteMap notFound={true} />
                    <img style={{ display: 'none' }} src={`/notfound`} />
                </div>
            ) : (
                <div className="column-layout">
                    <LoadingIndicator type="white" />
                </div>
            )}
            <Footer />
        </Template>
    );
}

export default AICatchUp;
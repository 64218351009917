import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		// logger.log('[PageHeader] mount: %o');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[PageHeader] mount - headers: %o', Config.pageHeaders);
			dispatch({
				type: deps.actionTypes.PAGEHEADER_MOUNT
			});
		});
	},

	setWindowSize: data => (dispatch, getState, store) => {
		//logger.log('[PageHeader] action.setWindowSize -data.windowSize: %o', data);
		dispatch({
			type: deps.actionTypes.PAGEHEADER_WINDOW_SIZE_SET,
			data: data,
		});
	},

	sidepanelExpanded: data => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.PAGEHEADER_SIDEPANEL_EXPANDED,
			data: data,
		});
	},
};

import deps from 'dependencies';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import MeasurementUtils from 'appdir/lib/analytics';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { BALLOT_STATUS, validateData } from 'appdir/components/general/Util';
import { getRoleByKey, getRoleByName, postCodeAvailable } from 'appdir/components/general/Util/Role';
import {
	measureCall,
	measureLoginSuccess,
	measureLoginFailure,
	measureLogout,
	measureLoggedin,
	measureRegistrationCompletion,
} from 'appdir/components/general/Gigya/Measure';
import showPromoLogic from 'appdir/components/general/PromoFeature/logicFunctions.js';

let updateTimer = null;

let currentScreen = '';
let lastUpdate = 0;

export default {
	mount: data => (dispatch, getState, store) => {
		//logger.log('[Gigya] actions.mount data:%o',data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[Gigya] mount - data:%o', Config.auth);
			let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
			let site = op.get(parsedQs, 'site', null);

			let apiKey = op.get(Config, 'auth.apiKey', null);
			if (site == 'mobile') {
				apiKey = op.get(Config, 'auth.apiKeyMobile', null);
			}

			let jsPath = op.get(Config, 'auth.jsPath', null);
			jsPath = apiKey && jsPath ? jsPath + '?apikey=' + apiKey : null;
			let data = {
				gigyaScriptUrl: jsPath,
				gigyaApiKey: apiKey,
				gigyaApiPath: op.get(Config, 'auth.apiPath', null),
				enabledProviders: op.get(Config, 'auth.socialProviders', ''),
				myWim: Config.myWimbledon,
			};

			dispatch({ type: deps.actionTypes.GIGYA_MOUNT, data: data });
		});
	},

	logout: data => (dispatch, getState, store) => {
		let { Gigya } = store.getState();
		let playerRole = getRoleByName(op.get(Gigya, 'currentUser.roles', null), 'player');
		let ticketRole = getRoleByName(op.get(Gigya, 'currentUser.roles', null), 'ticket');

		dispatch(deps.actions.MyWimbledon.removeShadowRole(playerRole));
		deps.services.Tickets.logoutTickets(dispatch, store, ticketRole)
			.then(resp => {
				window.gigya.accounts.logout();
				dispatch(deps.actions.Controller.logout());
				lastUpdate = 0;
			})		
	},

	onLogout: data => (dispatch, getState, store) => {
		data = {
			currentUser: {},
			user: {},
		};

		dispatch({ type: deps.actionTypes.GIGYA_LOGOUT, data: data });
		dispatch(deps.actions.Controller.resetLocal());

		measureLogout();
	},

	setGigyaLoaded: data => (dispatch, getState, store) => {
		logger.log('[Gigya] actions.gigyaLoaded data:%o', data);
		dispatch({ type: deps.actionTypes.GIGYA_LOADED, data: data });
	},

	/**
	 * action to open Gigya screen
	 * callback functions set additional state data from profile for certain actions
	 */
	openScreen: params => (dispatch, getState, store) => {
		//logger.log('[Gigya] actions.openScreen state:%o', getState().Gigya);
		let openScreen = getState().Gigya.screen?.current || 'none';
		logger.log('[Gigya] actions.openScreen params:%o openScreen:%o', params, openScreen);

		// do not open if openning login screen from LOGIN button and another screen is already open
		//if ((params.screen == 'login_screen') && openScreen == 'gigya-login-screen') {
		if (openScreen != 'none' && params.cid == 'login_header') {
			return false;
		}

		//call dispatch to trigger render of Gigya and screen sets, before services.openScreen which calls top level Gigya open function
		//  3/24 - saw that screens were not being updated before displayed, so weren't getting screen params set before Gigya copied to display
		let screen = {
			register: typeof params.register == 'boolean' ? params.register : true,
			social: typeof params.social == 'boolean' ? params.social : true,
			email: typeof params.email == 'string' ? params.email : null,
		};
		dispatch({ type: deps.actionTypes.GIGYA_SCREEN_CALLBACK, data: {screen:screen} });

		deps.services.Gigya.openScreen(dispatch, store, params, eventObj => {
			let screen = {
				register: typeof params.register == 'boolean' ? params.register : true,
				social: typeof params.social == 'boolean' ? params.social : true,
				email: typeof params.email == 'string' ? params.email : null,
			};
			let redirect = typeof params.redirect == 'boolean' ? params.redirect : true;
			let showSuccess = typeof params.showSuccess == 'boolean' ? params.showSuccess : true;
			let data = {};
			let obj;

			logger.log('[Gigya] actions.screenCallback - eventObj:%o', eventObj);

			// get preferences and subscriptions before screen
			//  handle verification procedure
			if (eventObj.eventName == 'beforeScreenLoad') {
				//logger.log('[Gigya] actions.screenCallback - openScreen:%o params.screen:%o', openScreen, params.screen);

				if (eventObj.nextScreen == 'gigya-verification-pending-screen') {
					logger.log('[Gigya] actions.beforeScreenLoad - screen:%o', eventObj.nextScreen); //gigya.accounts.showScreenset(confirmation pending screen)
					//gigya.accounts.sendVerificationCode(email)
					let verificationParams = {
						regToken: eventObj.response.regToken,
						email: eventObj.response.requestParams.loginID,
					};
					window.gigya.accounts.resendVerificationCode(verificationParams);
					openScreenSet(dispatch, 'verification_link_sent', 'screen-holder', true);
				}

				if (eventObj.nextScreen == 'gigya-complete-registration-screen') {
					data = {
						user: {
							preferences: op.get(eventObj, 'preferences', null),
							subscriptions: op.get(eventObj, 'subscriptions', false),
						},
						path: getState('Router').Router.pathname,
						profile: op.get(eventObj, 'profile', null),
					};
					dispatch({ type: deps.actionTypes.GIGYA_UPDATE, data: data });
				}
			}

			// for measurement
			if (eventObj.eventName == 'afterScreenLoad') {
				measureCall(eventObj);
				//screen.current = eventObj.currentScreen;
				logger.log('[Gigya] actions.screenCallback afterScreenLoad');

				let gigyaLoginPasswordField = document.querySelector('#password');
				let gigyaPwdContainer = document.querySelector('#gigya-login-form .gigya-composite-control-password');
				let gigyaLoginPasswordField2 = document.querySelector('#password');
				let gigyaPwdContainer2 = document.querySelector('#gigya-profile-form .eyeDiv2');

				let gigyaLoginPasswordField3 = document.querySelector('#newPassword');
				let gigyaPwdContainer3 = document.querySelector('#gigya-profile-form .eyeDiv3');

				let gigyaLoginPasswordField4 = document.querySelector('#passwordRetype');
				let gigyaPwdContainer4 = document.querySelector('#gigya-profile-form .eyeDiv4');

				let gigyaLoginPasswordField5 = document.querySelector('#newPassword');
				let gigyaPwdContainer5 = document.querySelector('#gigya-reset-password-form .eyeDiv5');

				let gigyaLoginPasswordField6 = document.querySelector('#passwordRetype');
				let gigyaPwdContainer6 = document.querySelector('#gigya-reset-password-form .eyeDiv6');

				let gigyaLoginPasswordField7 = document.querySelector('#password');
				let gigyaPwdContainer7 = document.querySelector('#gigya-register-form .eyeDiv7');

				let gigyaLoginPasswordField8 = document.querySelector('#passwordRetype');
				let gigyaPwdContainer8 = document.querySelector('#gigya-register-form .eyeDiv8');

				let gigyaLoginPasswordField9 = document.querySelector('#password');
				let gigyaPwdContainer9 = document.querySelector('#gigya-link-accounts-form .eyeDiv9');

				let gigyaLoginPasswordField10 = document.querySelector('#passwordRetype');
				let gigyaPwdContainer10 = document.querySelector('#gigya-link-accounts-form .eyeDiv10');

				const addChildrenEyes = (gigyaPwdContainer, gigyaLoginPasswordField) => {
					const passwordEyeDiv = document.createElement('button');
					passwordEyeDiv.type = 'button';
					gigyaPwdContainer.appendChild(passwordEyeDiv);
					passwordEyeDiv.classList.add('show');
					passwordEyeDiv.classList.remove('hide');
					passwordEyeDiv.classList.add('passwordEyeDivClass');
					passwordEyeDiv.addEventListener('click', function(e) {
						// e.preventDefault();
						if (e.pointerType !== '') {
							logger.log('[Gigya] actions.screenCallback e:%o', e);
							if (gigyaLoginPasswordField.type === 'password') {
								gigyaLoginPasswordField.type = 'text';
								this.classList.add('hide');
								this.classList.remove('show');
							} else {
								gigyaLoginPasswordField.type = 'password';
								this.classList.add('show');
								this.classList.remove('hide');
							}
						}
					});
				};
				// Login
				gigyaPwdContainer && addChildrenEyes(gigyaPwdContainer, gigyaLoginPasswordField);
				// Change Password
				gigyaPwdContainer2 && addChildrenEyes(gigyaPwdContainer2, gigyaLoginPasswordField2);
				gigyaPwdContainer3 && addChildrenEyes(gigyaPwdContainer3, gigyaLoginPasswordField3);
				gigyaPwdContainer4 && addChildrenEyes(gigyaPwdContainer4, gigyaLoginPasswordField4);
				// Reset Password
				gigyaPwdContainer5 && addChildrenEyes(gigyaPwdContainer5, gigyaLoginPasswordField5);
				gigyaPwdContainer6 && addChildrenEyes(gigyaPwdContainer6, gigyaLoginPasswordField6);
				// Registration
				gigyaPwdContainer7 && addChildrenEyes(gigyaPwdContainer7, gigyaLoginPasswordField7);
				gigyaPwdContainer8 && addChildrenEyes(gigyaPwdContainer8, gigyaLoginPasswordField8);
				// Link Accounts
				gigyaPwdContainer9 && addChildrenEyes(gigyaPwdContainer9, gigyaLoginPasswordField9);
				gigyaPwdContainer10 && addChildrenEyes(gigyaPwdContainer10, gigyaLoginPasswordField10);

				let gigyaAddressField = document.querySelector('.completion-address-container');

				gigyaAddressField.addEventListener('click', function(e) {
					// e.preventDefault();
					logger.log('[Gigya] actions gigyaAddressField clicked');
				});
				//logger.log('[Gigya] actions.screenCallback gigyaAddressField:%o', gigyaAddressField);
			} else if (eventObj.eventName == 'beforeValidation') {
				logger.log('[Gigya] actions.screenCallback - beforeValidation eventObj:%o', eventObj);
				if (eventObj.form == 'gigya-profile-form') {
					const formdata = eventObj.formData;
					const birthdate = moment({
						year: formdata['profile.birthYear'],
						month: formdata['profile.birthMonth'] - 1,
						day: formdata['profile.birthDay'],
					}).toDate();
					let dateThirteen = new Date();
					dateThirteen.setFullYear(dateThirteen.getFullYear() - 13);
					logger.log(
						'[Gigya] actions.screenCallback - beforeValidation birthdate:%o, dateThirteen:%o',
						birthdate,
						dateThirteen
					);
					if (birthdate > dateThirteen) {
						return { form: 'Birthday is invalid' };
					}
				}
			}

			// update user data from screen submit response
			// if after a login/complete reg screen submit
			// get basic user data and update Gigya state to close login box and display basic info
			else if (eventObj.eventName == 'afterSubmit') {
				let loggedIn = op.get(getState().Gigya, 'loggedIn', null);
				let prompts = op.get(getState().Config, 'prompts', null);
				let roles = op.get(getState().Gigya, 'currentUser.roles', null);

				if (eventObj.eventName == 'error' && eventObj?.response?.info?.screen == 'gigya-login-screen') {
					measureLoginFailure(eventObj);
				}

				if (eventObj.screen == 'gigya-email-code-verification-screen') {
					logger.log('[Gigya] actions.afterSubmit - eventObj:%o', eventObj);
					if (eventObj.response.errorCode == 0) {
						dispatch(deps.actions.Gigya.getAccountInfo({ src: 'code_verification' }));
					}
				}

				if (
					eventObj.screen == 'gigya-login-screen' ||
					eventObj.screen == 'gigya-profile-form' ||
					eventObj.screen == 'gigya-complete-registration-screen'
				) {
					let data = formatUserData(eventObj.response, true, true, store, dispatch);
					dispatch({ type: deps.actionTypes.GIGYA_UPDATE, data: data });
					dispatch(deps.actions.Controller.update(data, true));
				}

				//handle response from reset password success or failure
				if (eventObj.screen === 'gigya-reset-password-screen') {
					if (eventObj.response.errorDetails) {
						openScreenSet(dispatch, 'reset_password_fail', 'screen-holder', true);
					} else {
						openScreenSet(dispatch, 'reset_password_success', 'screen-holder', true);
					}
				}

				//handle login submits
				if (eventObj.screen == 'gigya-login-screen') {
					measureLoginSuccess(eventObj);
					// promo check
					checkPromoFeatureLogic(true, roles, getState().Controller, prompts, dispatch);
				}

				//show message if complete reg screen
				//check for postcode here and open community screen if match
				if (
					eventObj.screen == 'gigya-complete-registration-screen' ||
					eventObj.screen == 'gigya-email-code-verification-screen'
				) {
					if (eventObj.response.errorCode == 0) {
						measureRegistrationCompletion(eventObj);

						deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
							// function to check config, onboarding version, controller state and determine
							// if we should render PromoFeature or show standard success screen
							// let suppressSuccessScreen = false;
							let configLoaded = op.get(getState().Config, 'status', null);
							if (configLoaded === 'loaded') {
								checkPromoFeatureLogic(
									loggedIn,
									roles,
									getState().Controller,
									prompts,
									dispatch,
									true
								);
							}

							//logger.log('[Gigya] actions.screenCallback - Config:%o', Config);
							//logger.log('[Gigya] actions.screenCallback - eventObj:%o', eventObj);
							let zip = eventObj.profile.zip;
							let postCodes = Config.postCodes;
							let matchPostcode = postCodeAvailable(postCodes, zip);
							//logger.log('[Gigya] actions.screenCallback - matchPostcode:%o', matchPostcode);
							if (!matchPostcode) {
								let respData = eventObj.response;
								if (respData.errorCode == 0) {
									//logger.log('[Gigya] actions.screenCallback - registration eventObj:%o', eventObj);
									//logger.log('[Gigya] actions.screenCallback - registration completion');
									if (showSuccess && !suppressSuccessScreen) {
										openScreenSet(dispatch, 'registration_success');
									}
								}
							} else {
								if (showSuccess && !suppressSuccessScreen) {
									openScreenSet(dispatch, 'registration_success_postcode');
								}
								// openScreenSet(dispatch, 'registration_address');
							}
						});
					}
				}
			}

			// checks and updates before submit forms
			else if (
				eventObj.eventName == 'submit' &&
				eventObj.screen == 'gigya-complete-registration-screen' &&
				!eventObj.isRegistered
			) {
				let entitlements = op.get(eventObj, 'accountInfo.preferences.CommunicationPreferences.entitlements', [
					'email',
					'phone',
					'sms',
				]);
				logger.log(
					'[Gigya] actions.screenCallback - Submit1 eventObj:%o entitlements:%o',
					eventObj,
					entitlements
				);

				op.set(eventObj, 'formModel.subscriptions.newsletter.email.isSubscribed', true);
				let prefs = {
					...op.get(eventObj, 'formModel.preferences', {}),
					CommunicationPreferences: {
						entitlements: entitlements,
						isConsentGranted: true,
					},
				};

				op.set(eventObj, 'formModel.preferences', prefs);
				logger.log('[Gigya] actions.screenCallback - Submit1 full eventObj:%o', eventObj);
			}

			//look for navigation screen
			if (eventObj.eventName == 'beforeScreenLoad') {
				currentScreen = eventObj.nextScreen;
				screen.current = eventObj.nextScreen;
			}

			// checks if screen closing
			//   redirect to mywimbledon page if password reset or registration success screen and redirect param
			else if (eventObj.eventName == 'hide') {
				if (eventObj.reason == 'finished') {
					logger.log(
						'[Gigya] actions.screenCallback - hide currentScreen:%o eventObj:%o',
						currentScreen,
						eventObj
					);

					if (
						(currentScreen == 'gigya-reset-password-success-screen' ||
							currentScreen == 'gigya-registration-success-screen' ||
							currentScreen == 'gigya-registration-success-postcode-screen' ||
							currentScreen == 'gigya-reset-password-fail-screen') &&
						redirect
					) {
						logger.log(
							'[Gigya] actions.screenCallback - hide currentScreen:%o eventObj:%o',
							currentScreen,
							eventObj
						);

						let newData = {
							newPath: '/en_GB/mywimbledon/index.html',
							screen: {
								...screen,
								current: '',
							},
						};
						dispatch({ type: deps.actionTypes.MAINNAV_NAVIGATE, data: newData });
					}
				} else if (eventObj.reason == 'canceled') {
					measureCall(eventObj);
				}
			}

			data.screen = screen;
			logger.log('[Gigya] actions.screenCallback last - data:%o eventObj:%o', data, eventObj);
			dispatch({ type: deps.actionTypes.GIGYA_SCREEN_CALLBACK, data: data });
		});
	},

	/**
	 * action to close Gigya screen
	 */
	closeScreen: params => (dispatch, getState, store) => {
		//logger.log('[Gigya] actions.closeScreen data:%o path:%o', params, getState('Router').Router.pathname);
		let containerId = params.containerId;
		let openScreen = op.get(getState().Gigya, 'screen.current', null);
		let data = {
			screen: null,
		};

		dispatch({ type: deps.actionTypes.GIGYA_SCREEN_CALLBACK, data: data });
		deps.services.Gigya.closeScreen(containerId);
	},

	//requests a JWT to confirm session is valid
	checkJWT: params => (dispatch, getState, store) => {
		logger.log('[Gigya] actions.checkJWT - source:%o', params?.src);

		return new Promise((resolve, reject) => {
			deps.services.Gigya.getJWT(dispatch, store)
				.then(response => {
					resolve();
				})
				.catch(error => {
					reject();
				});
		});
	},

	getAccountInfo: params => (dispatch, getState, store) => {
		logger.log('[Gigya] actions.getAccountInfo - source:%o', params?.src);

		deps.services.Gigya.getAccountInfo(dispatch, store, {
			//include: 'data, profile, subscriptions, preferences',
			include: ['data', 'profile', 'subscriptions', 'preferences'],
			extraProfileFields: ['address'],
			callback: response => {
				if (response.lastUpdatedTimestamp && response.lastUpdatedTimestamp !== lastUpdate) {
					lastUpdate = response.lastUpdatedTimestamp;

					if (response.errorCode === 0) {
						let data = formatUserData(response, true, false, store, dispatch);
						//logger.log('[Gigya] actions.getAccountInfo - formatUserData:%o', data);
						dispatch({ type: deps.actionTypes.GIGYA_UPDATE, data: data });
						dispatch(deps.actions.Controller.update(data));

						if (params.src == 'gigyaLoad') {
							measureLoggedin(data);
							let roles = op.get(getState().Gigya, 'currentUser.roles', null);
							let loggedIn = op.get(getState().Gigya, 'loggedIn', null);
							let prompts = op.get(getState().Config, 'prompts', null);
							// promo check
							checkPromoFeatureLogic(loggedIn, roles, getState().Controller, prompts, dispatch);
						}
					} else {
						//dispatch(deps.actions.Gigya.setGigyaLoaded({ gigyaLoaded: true }));
						dispatch(deps.actions.Controller.update(null));
					}
				} else if (response.error) {
					logger.log('[Gigya] actions.getAccountInfo - error:%o', response.error);
					//dispatch(deps.actions.Gigya.setGigyaLoaded({ gigyaLoaded: true }));
					dispatch(deps.actions.Controller.update(null));
				} else {
					// dispatch update to controller with no data in order to reset timer
					logger.log('[Gigya] actions.getAccountInfo - no update');
					//dispatch(deps.actions.Gigya.setGigyaLoaded({ gigyaLoaded: true }));
					dispatch(deps.actions.Controller.update(null));
				}
			},
		});
	},

	//returns a Promise from sgetAccountInfo so callers can take specific actions if success or failure
	//  believe we can replace all calls with this one, just needs testing
	getAccountInfoProm: params => (dispatch, getState, store) => {
		logger.log('[Gigya] actions.getAccountInfoProp - source:%o', params?.src);

		return new Promise((resolve, reject) => {
			deps.services.Gigya.getAccountInfo(dispatch, store, {
				//include: 'data, profile, subscriptions, preferences',
				include: ['data', 'profile', 'subscriptions', 'preferences'],
				extraProfileFields: ['address'],
				callback: response => {
					if (response.lastUpdatedTimestamp && response.lastUpdatedTimestamp !== lastUpdate) {
						lastUpdate = response.lastUpdatedTimestamp;

						if (response.errorCode === 0) {
							let data = formatUserData(response, true, false, store, dispatch);
							//logger.log('[Gigya] actions.getAccountInfo - formatUserData:%o', data);
							dispatch({ type: deps.actionTypes.GIGYA_UPDATE, data: data });
							dispatch(deps.actions.Controller.update(data));
						} else {
							//dispatch(deps.actions.Gigya.setGigyaLoaded({ gigyaLoaded: true }));
							dispatch(deps.actions.Controller.update(null));
						}
						resolve();
					} else if (response.error) {
						logger.log('[Gigya] actions.getAccountInfoProm - error:%o', response.error);
						//dispatch(deps.actions.Gigya.setGigyaLoaded({ gigyaLoaded: true }));
						dispatch(deps.actions.Controller.update(null));
						reject();
					} else {
						// dispatch update to controller with no data in order to reset timer
						logger.log('[Gigya] actions.getAccountInfoProm - no update');
						//dispatch(deps.actions.Gigya.setGigyaLoaded({ gigyaLoaded: true }));
						dispatch(deps.actions.Controller.update(null));
						resolve();
					}
				},
			});
		});
	},

	setAccountInfo: (source, data, modal, timer) => (dispatch, getState, store) => {
		logger.log('[Gigya] actions.setAccountInfo - source:%o data:%o modal:%o', source, data, modal);

		if (modal) {
			dispatch({
				type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE,
				data: {
					showModal: true,
					showCloseBtn: false,
					type: 'updating',
					title: 'UPDATING',
					message: 'Your profile is updating. Please do not close your browser window while updating.',
				},
			});
		}

		deps.services.Gigya.setAccountInfo(dispatch, store, {
			data,
			callback: response => {
				logger.log('[Gigya] actions.setAccountInfo - response:%o', response);

				// check statusCode which is in Gigya response

				if (response.status == 'OK' || response.statusCode == 200) {
					let update = {};
					let state = getState().Gigya;
					//logger.log('[Gigya] actions.setAccountCallback - state:%o response:%o', state, response);
					update = updateUserReduxState(dispatch, response, state);
					//logger.log('[Gigya] actions.setAccountInfo - state:%o', state);

					logger.log('[Gigya] actions.setAccountInfo - update:%o', update);
					dispatch({ type: deps.actionTypes.GIGYA_UPDATE, data: update });

					if (modal) {
						dispatch({
							type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE,
							data: {
								showModal: true,
								showCloseBtn: true,
								type: 'success',
								title: 'UPDATE SUCCESS',
								message: 'Update Successful. Your profile has been updated.',
								useTimer: true,
							},
						});
					}

					if (timer) {
						timer.resume();
					}
				}

				if (modal && (response.status == 'FAIL' || response.error || response.statusCode != 200)) {
					dispatch({
						type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE,
						data: {
							showModal: true,
							showCloseBtn: true,
							type: 'fail',
							title: 'UPDATE FAILURE',
							message: 'Profile information update failed please try again!',
							useTimer: false,
						},
					});
				}
			},
		});
	},

	//returns a Promise from setAccountInfo so callers can take specific actions if success or failuer
	//  believe we can replace all calls with this one, just needs tetsing
	setAccountInfoProm: (source, data, modal, timer) => (dispatch, getState, store) => {
		logger.log('[Gigya] actions.setAccountInfoProm - source:%o data:%o modal:%o', source, data, modal);

		if (modal) {
			dispatch({
				type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE,
				data: {
					showModal: true,
					showCloseBtn: false,
					type: 'updating',
					title: 'UPDATING',
					message: 'Your profile is updating. Please do not close your browser window while updating.',
				},
			});
		}

		return new Promise((resolve, reject) => {
			deps.services.Gigya.setAccountInfo(dispatch, store, {
				data,
				callback: response => {
					logger.log('[Gigya] actions.setAccountInfoProm - response:%o', response);

					// check statusCode which is in Gigya response

					if (response.status == 'OK' || response.statusCode == 200) {
						let update = {};
						let state = getState().Gigya;
						//logger.log('[Gigya] actions.setAccountCallback - state:%o response:%o', state, response);
						update = updateUserReduxState(dispatch, response, state);
						//logger.log('[Gigya] actions.setAccountInfo - state:%o', state);

						logger.log('[Gigya] actions.setAccountInfoProm - update:%o', update);
						dispatch({ type: deps.actionTypes.GIGYA_UPDATE, data: update });

						if (modal) {
							dispatch({
								type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE,
								data: {
									showModal: true,
									showCloseBtn: true,
									type: 'success',
									title: 'UPDATE SUCCESS',
									message: 'Update Successful. Your profile has been updated.',
									useTimer: true,
								},
							});
						}

						if (timer) {
							timer.resume();
						}

						resolve();
					}

					if (modal && (response.status == 'FAIL' || response.error || response.statusCode != 200)) {
						dispatch({
							type: deps.actionTypes.CONFIRMATION_MODAL_TOGGLE,
							data: {
								showModal: true,
								showCloseBtn: true,
								type: 'fail',
								title: 'UPDATE FAILURE',
								message: 'Profile information update failed please try again!',
								useTimer: false,
							},
						});
						reject({ error: 'error updating subscription' });
					}
				},
			});
		});
	},

	getJWT: data => (dispatch, getState, store) => {
		return deps.services.Gigya.getJWT(dispatch, store);
	},

	updateBallotStatus: data => (dispatch, getState, store) => {
		let state = getState().Gigya;
		let roles = op.get(state, 'currentUser.roles', []);
		let ballotRole = roles.find(role => role.key == 'ballot');
		logger.info('[Gigya] updateBallotStatus - ballotRole:%o', ballotRole);
		if (ballotRole) {
			if (data == 'decline') {
				ballotRole.status = BALLOT_STATUS.DECLINED;
			}

			dispatch({ type: deps.actionTypes.GIGYA_UPDATE_ROLE, data: roles });
		}
	},

	transferSession: data => (dispatch, getState, store) => {
		//logger.log('[Gigya] actions.transferSession data:%o', data);
		deps.services.Gigya.sessionTransfer(dispatch, store, data);
	},
};

const checkPromoFeatureLogic = (loggedIn, roles, controller, prompts, dispatch, isReg) => {
	const screenToShow = showPromoLogic(
		prompts,
		loggedIn,
		controller?.promoFeature,
		roles,
		isReg
	);
	let hasScreenToShow = Object.keys(screenToShow)?.some(key => screenToShow[key] === true);
	if (hasScreenToShow) {
		dispatch(deps.actions.PromoFeature.togglePromoFeature(screenToShow, isReg));
	}

	// return screenToShow.favortingPromo || screenToShow.interestsPromo;
	return hasScreenToShow;
};

const formatUserData = (dataObj, getRoles, initial, store, dispatch) => {
	logger.log('[Gigya] formatUserData - dataObj:%o initial:%o', dataObj, initial);

	//account for "privacy.PrivacyPolicy" attribute name in returned data from accounts.login
	//  which is different than structure in getAccountInfo, "privacy"."PrivacyPolicy"
	let prefs = op.get(dataObj, 'preferences', null);
	let keys = Object.keys(prefs);
	let fixedPrefs = {};

	keys.forEach((key, index) => {
		if (key.indexOf('.') > -1) {
			let names = key.split('.');
			fixedPrefs[names[0]] = {};
			fixedPrefs[names[0]][names[1]] = prefs[key];
		} else {
			fixedPrefs[key] = prefs[key];
		}
	});

	//if initial user data from login included a shadow player role, remove it
	if (initial && op.get(dataObj, 'data.systemRole.player.isShadowingAccess', null)) {
		dispatch(deps.actions.MyWimbledon.removeShadowRole(op.get(dataObj, 'data.systemRole.player')));
		op.del(dataObj, 'data.systemRole.player');
	}

	let user = getUserObject(dataObj, getRoles, store, dispatch);
	let data = {
		...user,
		user: {
			preferences: fixedPrefs,
			subscriptions: op.get(dataObj, 'subscriptions', null),
		},
	};
	delete data.currentUser.preferences;
	delete data.currentUser.subscriptions;
	//logger.log('[Gigya] formatUserData - data:%o ', data);

	return data;
};

/**
 * returns an updated currentUser object after modifying the roles array
 * @param {*} data
 * @param {*} store
 * @param {*} dispatch
 */
const getUserObject = (data, getRoles, store, dispatch) => {
	data.roles = getRolesArray(data, getRoles, store, dispatch);
	let obj = {
		currentUser: data,
	};
	//logger.info('[Gigya] getUserObject - obj:%o', data);
	return obj;
};

/**
 * returns roles array with approved roles and roles which are in configuration
 * @param {*} data
 * @param {*} config
 */
const getRolesArray = (data, getRoleDetail, store, dispatch) => {
	let config = store.getState()['Config'];
	let roles = [];
	let system = data.data.systemRole;
	let configRoles = op.get(config, 'myWimbledon.rolesComplete', {});
	let myWIcons = op.get(config, 'myWIcons', {});
	let configSegments = op.get(config, 'myWimbledon.segments', {});
	let tournStart = op.get(config, 'dateConfig.tournStart', '');
	logger.log('[Gigya] getRolesArray - data:%o', data);

	if (system) {
		Object.keys(system).map(e => {
			//adjust id values if they were entered as strings
			if (typeof system[e].roleId === 'string') {
				system[e].roleId = parseInt(system[e].roleId);
			}
			if (typeof system[e].roleSegmentId === 'string') {
				system[e].roleSegmentId = parseInt(system[e].roleSegmentId);
			}

			//logger.warn('[Gigya] getRolesArray - "%s" system[e]:%o', e, system[e]);

			let approved = false;
			if (validateData(system[e].approved, 'boolean')) {
				approved = system[e].approved;
			} else if (
				validateData(system[e].approved, 'string') &&
				(system[e].approved.toLowerCase() == 'true' || system[e].approved == '1')
			) {
				approved = true;
			} else if (validateData(system[e].approved, 'int') && system[e].approved == 1) {
				approved = true;
			}
			//logger.log('[Gigya] getRolesArray - system[e]:%o approved:%o', system[e], approved);

			// do not add role if approved not a truthy value, unless media (need to reconsent)
			if ((approved && system[e].roleId) || system[e]?.roleId === 1000250) {
				// if role id matches role in config, add to local roles
				if (getRoleByKey(configRoles, e)?.id == system[e].roleId) {
					let status = system[e].status;
					let updatedRole = {
						key: e,
						//name: system[e].name,
						// don't use name from ciam data anymore, it isn't consistent, use names defined in config
						name: getRoleByKey(configRoles, e)?.name,
						roleId: system[e].roleId,
						segmentId: system[e].roleSegmentId,
						linkId: system[e].linkId,
						otherId: system[e].otherId,
						approved: system[e].approved,
						status: system[e].status,
					};

					if (getRoleByKey(configRoles, e)?.id === 1000250) {
						updatedRole['reconsent'] = !system[e].approved;
					}

					if (system[e].isShadowingAccess) {
						updatedRole.isShadowingAccess = system[e].isShadowingAccess;
					}

					// check for qs with ballot override for testing
					if (e == 'ballot') {
						let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
						let statusOverride = op.get(parsedQs, 'status', null);
						if (statusOverride) {
							updatedRole.status = statusOverride;
							logger.info(
								'[Gigya] getRolesArray - updatedRole:%o statusOverride:%o',
								updatedRole,
								statusOverride
							);
						}
					}

					roles.push(updatedRole);
				} else {
					logger.warn('[Gigya] getRolesArray - "%s" excluded by config', e);
				}
			} else {
				logger.warn('[Gigya] getRolesArray - "%s" not approved', e);
			}
		});
	}

	// set segment details if have id and config segments
	roles.forEach(role => {
		let roleId = role.segmentId ? role.segmentId : role.roleId;
		if (roleId) {
			let seg = configSegments.find(obj => obj.id === roleId);

			role.segment = seg?.segment || '';
			const key = `${role.key}${role.segment && ':'.concat(role.segment)}`;
			role.keySegment = key;
			role.landingPage = myWIcons[key]?.find(role => role.show.includes('landing'))?.url;
			logger.log('[Gigya] getRolesArray - role:%o seg:%o', role, seg);
		}
	});

	//don't request role details if in a webview
	//  not needed for any current webviews
	if (getRoleDetail && !window.webview) {
		// if has player role, get player contact details and ids
		let playerRole = roles.find(role => role.key == 'player');
		if (playerRole) {
			logger.log('[Gigya] getRolesArray - user is a player:%o', playerRole.linkId);
			let playerIndex = roles.findIndex(role => role.key == 'player');
			deps.services.Gigya.getPlayerContactsDetails(dispatch, store, playerRole.linkId)
				.then(data => {
					logger.log('[Gigya] getRolesArray - player:%o data:%o', playerRole, data);
					if (data) {
						playerRole.contactDetails = data;
						roles[playerIndex] = playerRole;
						dispatch({ type: deps.actionTypes.GIGYA_UPDATE_ROLE, data: roles });
					}
					logger.log('[Gigya] getRolesArray - playerRole:%o', playerRole);
				})
				.catch(err => {
					logger.error('[Gigya] getRolesArray - error:%o', err);
				});
		}

		return roles;
	} else {
		return roles;
	}
};

const openScreenSet = (dispatch, which, holder, redirect, context = null) => {
	//logger.log('[Gigya] openSuccessMessage ');
	let params = { screen: which };
	if (holder) {
		params.holder = holder;
	}
	if (redirect) {
		params.redirect = redirect;
	}
	if (context) {
		params.context = context;
	}
	dispatch(deps.actions.Gigya.openScreen(params));
};

const updateUserReduxState = (dispatch, response, state) => {
	let update = {};
	let updateData = {
		...state.currentUser.data,
		...response.requestParams.data,
	};
	logger.log('[Gigya] updateUserReduxState - resp:%o state:%o', response, state);
	logger.log('[Gigya] updateUserReduxState - updateData:%o', updateData);

	if (op.get(response, 'requestParams.data.userPreferences', false)) {
		update.currentUser = {
			...state.currentUser,
			data: updateData,
		};

		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE_PREF, data: updateData.userPreferences });
	}

	if (op.get(response, 'requestParams.profile', false)) {
		update.currentUser = {
			...state.currentUser,
			profile: response.requestParams.profile,
			data: updateData,
		};
	}

	// Form submit includes prefs or subs
	if (
		op.get(response, 'requestParams.subscriptions', false) ||
		op.get(response, 'requestParams.preferences', false)
	) {
		let entitlements = op.get(state, 'user.preferences.CommunicationPreferences.entitlements', []);
		if (op.get(response, 'requestParams.subscriptions', false) && entitlements.length == 0) {
			op.set(response, 'requestParams.preferences', {
				CommunicationPreferences: {
					entitlements: ['email', 'phone', 'sms'],
					isConsentGranted: true,
				},
			});
		}
		logger.log('[Gigya] updateUserReduxState - updatedResp:%o', response);
		update.user = {
			preferences: {
				...state.user.preferences,
				...op.get(response, 'requestParams.preferences', {}),
			},
			subscriptions: {
				...state.user.subscriptions,
				...op.get(response, 'requestParams.subscriptions', {}),
			},
		};
	}

	//ballot Reg
	else if (op.get(response, 'requestParams.subscriptions.ballotRegistration.email.isSubscribed', false)) {
		update.user = {
			subscriptions: {
				ballotRegistration: {
					email: {
						...response.requestParams.subscriptions.ballotRegistration.email,
					},
				},
			},
		};
	}

	logger.log('[Gigya] updateUserReduxState - update:%o', update);
	return update;
};

// const measureCall = data => {
// 	//logger.log('[Gigya] measureCall - data:%o', data);

// 	let type = '';
// 	let m_data = {};

// 	if (data.context) {
// 		m_data.content = data.context;
// 		m_data.provider = op.get(data, 'response.response.loginProvider', '');
// 	}

// 	if (data.eventName == 'afterScreenLoad') {
// 		switch (data.currentScreen) {
// 			case 'gigya-login-screen':
// 				type = 'Login Screen';
// 				break;
// 			case 'gigya-login-success-screen':
// 				type = 'Login Success Screen';
// 				break;
// 			case 'gigya-register-screen':
// 				type = 'Registration Screen';
// 				break;
// 			case 'gigya-complete-registration-screen':
// 				type = 'Registration Complete Screen';
// 				break;
// 			case 'gigya-registration-success-screen':
// 				type = 'Registration Success Screen';
// 				break;
// 			case 'gigya-registration-success-postcode-screen':
// 				type = 'Registration Success Postcode Screen';
// 				break;
// 			case 'gigya-reset-password-screen':
// 				type = 'Reset Password Screen';
// 				break;
// 			case 'gigya-reset-password-success-screen':
// 				type = 'Reset Password Success Screen';
// 				break;
// 			case 'gigya-password-change-required-screen':
// 				type = 'Change Password Screen';
// 				break;
// 			case ' gigya-forgot-password-screen':
// 				type = 'Forgot Password Screen';
// 				break;
// 			case 'gigya-forgot-password-success-screen':
// 				type = 'Forgot Password Success Screen';
// 				break;
// 			case 'gigya-link-account-screen':
// 				type = 'Linking Screen';
// 				break;
// 			default:
// 				break;
// 		}
// 	} else if (data.eventName == 'hide') {
// 		type = 'Close Screen';
// 	}

// 	if (type) {
// 		MeasurementUtils.dispatchMeasurementCall(type, m_data);
// 	}
// };

export const courtAvailability = {
	championshipCourts: {
		name: 'Championship Courts',
		totalCourts: 16,
		courtsUnavailable: 16,
		courtsAvailable: 0,
		courts: [
			{
				name: 'No.2 Court',
				unavailabilityStart: '2020-07-10T10:21:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 14,
			},
			{
				name: 'No.3 Court',
				unavailabilityStart: '2020-09-03T07:26:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 15,
			},
			{
				name: 'Court A',
				unavailabilityStart: '2021-10-01T07:00:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 16,
			},
			{
				name: 'Court B',
				unavailabilityStart: '2021-10-01T07:00:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 17,
			},
			{
				name: 'Court C',
				unavailabilityStart: '2021-10-01T08:19:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 18,
			},
			{
				name: 'Court D',
				unavailabilityStart: '2021-09-20T08:01:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 19,
			},
			{
				name: 'Court E',
				unavailabilityStart: '2021-09-20T08:01:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 20,
			},
			{
				name: 'Court F',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 21,
			},
			{
				name: 'Court G',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 22,
			},
			{
				name: 'Court H',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 23,
			},
			{
				name: 'Court I',
				unavailabilityStart: '2020-09-21T08:42:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 24,
			},
			{
				name: 'Court 14',
				unavailabilityStart: '2020-05-24T08:56:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 25,
			},
			{
				name: 'Court 15',
				unavailabilityStart: '2020-05-24T08:56:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 26,
			},
			{
				name: 'Court 16',
				unavailabilityStart: '2020-05-24T08:56:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 27,
			},
			{
				name: 'Court 17',
				unavailabilityStart: '2020-05-24T08:56:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 28,
			},
			{
				name: 'Court 18',
				unavailabilityStart: '2020-05-24T08:56:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CHAMPCOURTS',
				venueId: 29,
			},
		],
	},
	practiceCourts: {
		name: 'Aorangi Courts',
		totalCourts: 20,
		courtsUnavailable: 20,
		courtsAvailable: 0,
		courts: [
			{
				name: 'Aorangi A',
				unavailabilityStart: '2021-09-29T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 30,
			},
			{
				name: 'Aorangi B',
				unavailabilityStart: '2021-09-29T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 31,
			},
			{
				name: 'Aorangi C',
				unavailabilityStart: '2021-09-29T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 32,
			},
			{
				name: 'Aorangi D',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 33,
			},
			{
				name: 'Aorangi E',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 34,
			},
			{
				name: 'Aorangi F',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 35,
			},
			{
				name: 'Aorangi G',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 36,
			},
			{
				name: 'Aorangi H',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 37,
			},
			{
				name: 'Aorangi I',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 38,
			},
			{
				name: 'Aorangi J',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 39,
			},
			{
				name: 'Aorangi 11',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 40,
			},
			{
				name: 'Aorangi 12',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 41,
			},
			{
				name: 'Aorangi 13',
				unavailabilityStart: '2021-09-13T08:07:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 42,
			},
			{
				name: 'Aorangi 14',
				unavailabilityStart: '2021-09-13T08:06:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 43,
			},
			{
				name: 'Aorangi 15',
				unavailabilityStart: '2021-07-16T08:37:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 44,
			},
			{
				name: 'Aorangi 16',
				unavailabilityStart: '2021-06-23T07:57:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 45,
			},
			{
				name: 'Aorangi 17',
				unavailabilityStart: '2021-06-23T07:57:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 46,
			},
			{
				name: 'Aorangi 18',
				unavailabilityStart: '2021-06-19T09:47:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 47,
			},
			{
				name: 'Aorangi 19',
				unavailabilityStart: '2021-06-19T09:47:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 48,
			},
			{
				name: 'Aorangi 20',
				unavailabilityStart: '2021-06-19T09:47:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'PRACTICECOURTS',
				venueId: 49,
			},
		],
	},
	clayAndAcrylicCourts: {
		name: 'Clay & Acrylic Courts',
		totalCourts: 11,
		courtsUnavailable: 3,
		courtsAvailable: 8,
		courts: [
			{
				name: 'Clay Court 3',
				unavailabilityStart: '2021-12-07T08:26:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1784,
			},
			{
				name: 'Clay Court 5',
				unavailabilityStart: '2021-12-07T08:26:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1785,
			},
			{
				name: 'Clay Court 6',
				unavailabilityStart: '2021-12-07T08:26:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1786,
			},
			{
				name: 'Court 1 (Indoor Clay)',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1145,
			},
			{
				name: 'Court 2 (Indoor Clay)',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1146,
			},
			{
				name: 'Court 3 (Indoor Clay)',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1147,
			},
			{
				name: 'Clay Court 4',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 3,
			},
			{
				name: 'Court 5 (Indoor Clay)',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1148,
			},
			{
				name: 'Court 6 (Indoor Clay)',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 1149,
			},
			{
				name: 'Court 7 (Acrylic)',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 4,
			},
			{
				name: 'Court 8 (Acrylic)',
				unavailabilityStart: '0001-01-01T00:00:00',
				unavailabilityEnd: '0001-01-01T00:00:00',
				status: 'Open',
				comments: null,
				downtimeType: null,
				venueType: 'TENNISCOURT',
				venueCategory: 'CLAYANDACRYLICCOURTS',
				venueId: 5,
			},
		],
	},
	croquetLawns: {
		name: 'Croquet Lawns',
		totalCourts: 2,
		courtsUnavailable: 2,
		courtsAvailable: 0,
		courts: [
			{
				name: 'Croquet Lawn 1',
				unavailabilityStart: '2022-02-18T09:04:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'CROQUETLAWN',
				venueCategory: 'CROQUETLAWNS',
				venueId: 176,
			},
			{
				name: 'Croquet Lawn 2',
				unavailabilityStart: '2022-02-18T09:04:00',
				unavailabilityEnd: '9999-12-31T23:59:00',
				status: 'Unavailable',
				comments: '',
				downtimeType: 'Closed',
				venueType: 'CROQUETLAWN',
				venueCategory: 'CROQUETLAWNS',
				venueId: 177,
			},
		],
	},
	courtAvailabilityMemberNotes:
		'Air Domes 1, 2, 3, 5 & 6 will are open. Acrylic courts 7 & 8 are open. Clay court 4 is closed due to rain.\r\nAll grass courts are now closed for the season. ',
	lastupdate: '2022-03-02 07:36:33',
};

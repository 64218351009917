import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';
import op from 'object-path';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[MyWimbledon] action.mount - params:%o store:%o', params, store.getState('Gigya'));

			let apiKey = op.get(Config, 'auth.apiKey', null);

			let data = {
				myWim: Config.myWimbledon,
				action: params.action,
				proxy: Config.auth.proxyPage,
				api: {
					consent: Config.myWimbledon.api.consent,
				},
			};

			dispatch({
				type: deps.actionTypes.MYWIMBLEDONLOGIN_MOUNT,
				data: data,
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MYWIMBLEDONLOGIN_UNMOUNT });
	},
};

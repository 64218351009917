/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: PastMatchUpsItem
 * 
 * currently used in slamtracker head to head section
 * 
 * -----------------------------------------------------------------------------
 */
const PastMatchUpsItem = props => {
    // logger.log('[PastMatchUpsItem] props:%o', props);

    const roundNames = useSelector((state) => state?.['Config']?.scoringConfig?.roundNames ?? []);

    const getFinalStatusText = () => {
        return props?.match?.winner == '1' ? (
            <div className="status">{props?.player1} Won vs {props?.player2}</div>
        ) : (
            <div className="status">{props?.player2} Won vs {props?.player1}</div>
        )

    }

    const getRoundName = () => {
        let found = roundNames.filter(round => {
            return round.roundNameShort == props.match.round;
        });

        if(found.length > 0) {
            return found[0].roundName;
        } else {
            return null;
        }
    }

    return (
        <div className='pastmatchups-item'>
            <div className="pastmatchups-item-row">
                <div className="tournament">{props.match.year} {props.match.tournamentName}</div>
                <div className="round-opponent"><span className="round">{ getRoundName() }</span></div> 
                 {/* - {props.match.opponent} */}
                { getFinalStatusText() }
                {/* <div className="status">{props.match.status}</div> */}
                <div className="score">{props.match.scores}</div>
            </div>
        </div>
    )
};

export default PastMatchUpsItem;
import React, { useEffect } from 'react';
import TabNav from 'appdir/components/common-ui/TabNav';
import StatusTab from './StatusTab';
import ProfileTab from './ProfileTab';
import StatisticsTab from './StatisticsTab';
import { measureInWeb } from 'appdir/components/general/Analytics';

const PlayerProfessionalInfo = ({ data }) => {
	logger.log('[PlayerProfessionalInfo] - data', data);

	return (
		<div className="professional-info-tab-wrapper" style={{ marginTop: '25px', marginBottom: '15px' }}>
			<TabNav
				data={{
					key: `profileTabs-${data?.tabName}`,
					default: 'Status',
					tabs: [
						{
							name: 'Status',
							value: `/en_GB/players/overview/${data.playerObj.id}.html`,
							match: [
								/\/en_GB\/players\/overview\/[A-Za-z0-9]+\/status.html/i,
								/\/en_GB\/players\/overview\/[A-Za-z0-9]+\.html/i,
							],
						},
						{
							name: 'Profile',
							value: `/en_GB/players/overview/${data.playerObj.id}/profile.html`,
							match: /\/en_GB\/players\/overview\/[A-Za-z0-9]+\/profile.html/i,
						},
						{
							name: 'Statistics',
							value: `/en_GB/players/overview/${data.playerObj.id}/statistics.html`,
							match: /\/en_GB\/players\/overview\/[A-Za-z0-9]+\/statistics.html/i,
						},
					],
				}}
			/>
			{data?.tabName === 'status' || !data?.tabName ? (
				<StatusTab data={data} />
			) : data?.tabName === 'profile' ? (
				<ProfileTab data={data} />
			) : data?.tabName === 'statistics' ? (
				<StatisticsTab data={data} />
			) : null}
		</div>
	);
};

export default PlayerProfessionalInfo;

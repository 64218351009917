import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Time from 'appdir/components/common-ui/Time';

const ContentDate = props => {
	logger.log('[ContentDate] - props:%o', props);

	let contentDate = "";
	if (props.date) {
        //allow timezone to be passed as prop
        const now = moment().tz(props.tz || "Europe/London");
        const parsedDate = moment(parseInt(props.date)).tz(props.tz || "Europe/London");

        /*  Logic:
                Get the difference in calendar days between NOW and content date
                If difference is <= 7
                    display "{x} DAYS/HOURS/MINUTES AGO"
                Else display full date/time format in user's selection of Local or London timezones, e.g.
                    MON 17 JUL 2023 12:45 BST or THU 22 FEB 2024 10:00 GMT (depends on date)
                    MON 17 JUL 2023 12:45 EDT or THU 22 FEB 2024 10:00 EST (depends on date)
		*/

        // calendar days between NOW and content date
		if(now.diff(parsedDate, "days") <= 7){
            let fromDate = parsedDate.fromNow(true);
            let tempArr = fromDate.split(" ");
            let first = tempArr[0].toLowerCase();
            if(first == "a" || first == "an"){
                tempArr[0] = "1";
            }
            fromDate = tempArr.join(" ");
			contentDate = `${fromDate} ago`;
        } else { // existing date
            return (
                <div className='contentDate'>
                    <span className="timestamp-date medium">
                        {
                            <Time
                                ariaHidden
                                epoch_ms={props.date}
                                format="ddd DD MMM YYYY"
                                options="upper tile"
                            />
                        }
                    </span>
                    <span className="timestamp-time">
                        {
                            <Time
                                ariaHidden
                                epoch_ms={props.date}
                                format="kk:mm z"
                                options="upper tile"
                            />
                        }
                    </span>
                </div>
            );
		}
	}

    return (
        <div className='content-date' style={{ textTransform: 'uppercase' }}><span className='date-part medium'>{contentDate}</span></div>
    )
};

export default ContentDate;

import { getQuerystringParam } from 'appdir/components/general/Util';

export default function showPromoLogic(prompts, loggedIn, controllerPromoFeature, roles, isReg) {
	// let isReg = true; // TODO: change this when finished testing 
	// show "onboard" if
	// 1) this.props.loggedIn is true and !(prompts?.onboard < 1 || prompts?.onboard === onboardVersionViewed):


	/* 2024 logic
		Interests page:
			- Do not show on login 
			- Show on Register
			- Do not show if not logged in or not after reg
		Favoriting page:
			- Show on login if user has not seen (doesn't matter if it's a new version)
			- Show on register if user has not seen (doesn't matter if it's a new version)
			- Do not show if not logged in or not after reg
		Catch me up page:
			- Show if logged in && user has not seen current version 
		Player Profile page:
			- Show if logged in && user has not seen current version 
		Storyteller page:
			- Show if logged in && user has not seen current version 
	*/

	let debugMode = getQuerystringParam(null, 'onboardDebug');

	let promoToShow;
	let catchUpPromoVersionViewed_controller = controllerPromoFeature?.catchUpPromoVersionViewed?.toString() || false;
	let playerProfileVersionViewed_controller = controllerPromoFeature?.playerProfileVersionViewed?.toString() || false;

	if (!debugMode && prompts?.onboard > 0) {
		// check to see if the user has any roles that are included in the forbiddenRolesId list
		let matchingForbiddenRoles = roles?.filter(d => prompts?.forbiddenRoleIds?.includes(d?.roleId)) || [];

		// let onboardVersionViewed = controllerPromoFeature?.onboardVersionViewed || false;
		
		let toShowCatchMeUpPromo = matchingForbiddenRoles?.length == 0 &&
			window.location.pathname == '/en_GB/catchup/index.html' &&
			!(prompts?.catchMeUp < 1) &&
			(catchUpPromoVersionViewed_controller?.toString() !== prompts?.catchMeUp?.toString());

		let toShowPlayerProfilePromo = matchingForbiddenRoles?.length == 0 &&
			window.location.pathname == '/en_GB/players/index.html' &&
			!(prompts?.playerProfile < 1) &&
			(playerProfileVersionViewed_controller?.toString() !== prompts?.playerProfile?.toString());


		// interests logic (need to check controller because will be set to true after user clicks next and saves values)
		let toShowInterests = isReg == true && matchingForbiddenRoles?.length == 0 && !controllerPromoFeature?.interestsViewed && !(prompts?.interests < 1);

		// favoriting logic
		let favortingViewed_controller = controllerPromoFeature?.favortingViewed || false;
		
		let toShowFavorting = (isReg == true || loggedIn) && !favortingViewed_controller && matchingForbiddenRoles?.length == 0 && !(prompts?.favorting < 1);

		/* storyteller code
		let storytellerVersionViewed_controller = controllerPromoFeature?.storytellerVersionViewed?.toString() || false;
		let toShowStoryteller = loggedIn && 
			matchingForbiddenRoles?.length == 0 &&
			!(prompts?.draw < 1) &&
			(storytellerVersionViewed_controller?.toString() !== prompts?.storyteller?.toString());
		*/

		promoToShow = {
			interestsPromo: toShowInterests,
			favortingPromo: toShowFavorting,
			catchMeUpPromo: toShowCatchMeUpPromo,
			playerProfilePromo: toShowPlayerProfilePromo,
			// storytellerPromo: toShowStoryteller,
		};
	} else if (prompts?.onboard == 0 && !debugMode) {
		promoToShow = {
			interestsPromo: false,
			favortingPromo: false,
			catchMeUpPromo: false,
			playerProfilePromo: false,
			// storytellerPromo: toShowStoryteller,
		};
	} else {
		promoToShow = {
			interestsPromo: !controllerPromoFeature?.interestsViewed,
			favortingPromo: !(controllerPromoFeature?.favortingViewed || false),
			catchMeUpPromo: catchUpPromoVersionViewed_controller?.toString() !== prompts?.catchMeUp?.toString(),
			playerProfilePromo: playerProfileVersionViewed_controller?.toString() !== prompts?.playerProfile?.toString(),
			// storytellerPromo: storytellerVersionViewed_controller?.toString() !== prompts?.storyteller?.toString(),
		};
	}

	return promoToShow;
}

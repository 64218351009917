/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { FormInput, FormRadioButton } from 'appdir/components/common-ui/FormFields';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: CushionForm
 * -----------------------------------------------------------------------------
 */

function calculateDays(data, year, startDate) {
	let programDayArry = [];
	let firstSunday = false;
	let weekday = new Array(7);
	weekday[0] = 'Sunday';
	weekday[1] = 'Monday';
	weekday[2] = 'Tuesday';
	weekday[3] = 'Wednesday';
	weekday[4] = 'Thursday';
	weekday[5] = 'Friday';
	weekday[6] = 'Saturday';

	let month = new Array(12);
	month[0] = 'January';
	month[1] = 'February';
	month[2] = 'March';
	month[3] = 'April';
	month[4] = 'May';
	month[5] = 'June';
	month[6] = 'July';
	month[7] = 'August';
	month[8] = 'September';
	month[9] = 'October';
	month[10] = 'November';
	month[11] = 'December';

	let currDate = new Date(startDate + ' ' + year);
	for (let i = 0; i <= 13; i++) {
		let currData = {};
		if (i != 0) {
			currDate.setDate(currDate.getDate() + 1);
			// adding back middle sunday
			// if (weekday[currDate.getDay()] == 'Sunday' && !firstSunday) {
			// 	currDate.setDate(currDate.getDate() + 1);
			// 	firstSunday = true;
			// }
		}

		currData = {
			...data[i],
			day: weekday[currDate.getDay()],
			date: `${currDate.getDate()} ${month[currDate.getMonth()]}`,
		};
		programDayArry[i] = currData;
	}
	return programDayArry;
}

const CarForm = ({ formProps }) => {
	logger.log('[ProgramForm] render - formProps:%o', formProps);
	let parkingFormData = formProps.values.orders[0].parking;
	logger.log('[ProgramForm] render - parkingFormData:%o', parkingFormData);
	let year = formProps.values.orders[0].year;
	let creditCard = creditCard = parkingFormData.creditCard == "true" || parkingFormData.creditCard == true ? true : false;
	let parkingOrders = calculateDays(parkingFormData.days, year, formProps.context.dateConfig.tournStartDate);
	return (
		<span className="hospContent">
			{/* <span className="field one-col"> */}
			{/* <div>CUSHIONS</div> */}
			{/* <Field
					component="input"
					type="text"
					name="programs"
					onChange={formProps.handleChange}
					onBlur={formProps.handleBlur}
					value={formProps.values.cushions}
				/> */}
			{/* <ErrorMessage name="programs" render={msg => <span className="error">{msg}</span>} /> */}
			{/* </span> */}
			<span className="column-layout">
				<span className="four-col table-container">
					<table className="hospitality-table">
						<thead>
							<tr>
								<td>Day</td>
								<td>Date</td>
								<td>PETROL/DIESEL CARS</td>
								<td>FULLY ELECTRIC CARS</td>
							</tr>
						</thead>
						<tbody>
							{parkingOrders.map((d, i) => {
								return (
									<tr key={i}>
										<td>{d.day}</td>
										<td>{d.date}</td>
										<td>
											<FormInput
												label=""
												name={`orders[0].parking.days[${i}].spaces`}
												type="text"
												value={d.spaces || ''}
												className="quant"
											/>
										</td>
										<td className="checkbox-electric">
											<FormInput
												label=""
												name={`orders[0].parking.days[${i}].electric`}
												type="text"
												value={d.electric || ''}
												className="quant"
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</span>
			</span>
			<span className="column-layout no-padding">
				<span className="one-col signdate one-third">
					<FormInput
						label="Suite Number"
						type="text"
						name="orders[0].parking.suite"
						id="parkingSuite"
						value={parkingFormData.suite || ''}
					/>
				</span>
				<span className="one-col signdate one-third">
					<FormInput
						label="Name & Phone Number"
						type="text"
						name="orders[0].parking.name"
						id="parkingName"
						value={parkingFormData.name || ''}
					/>
				</span>
				<span className="one-col signdate one-third">
					<FormInput
						label="Date"
						type="text"
						name="orders[0].parking.date"
						id="parkingDate"
						value={parkingFormData.date || ''}
					/>
				</span>
			</span>
			<span className="column-layout no-padding">
				<span className="four-col payment-options">PAYMENT OPTIONS</span>
					<span className="four-col check-cc">
					<FormRadioButton name="orders[0].parking.creditCard" id="creditCardNo" value="false" checked={creditCard == true ? false : true}>
						Pay by invoice (Please complete invoice details below. Note: parking tickets will not be sent until invoice has been paid)
					</FormRadioButton>
				</span>
				<span className="two-col invoice-container signdate">
					<FormInput
						label="Invoice Address"
						type="text"
						name="orders[0].parking.invoice"
						id="parkingInvoice"
						value={parkingFormData.invoice || ''}
					/>
				</span>
				<span className="two-col invoice-container signdate">
					<FormInput
						label="Delivery Address for Parking Tickets (if different)"
						type="text"
						name="orders[0].parking.delivery"
						id="parkingDelivery"
						value={parkingFormData.delivery || ''}
					/>
				</span>
				<span className="two-col purhase-order-container signdate">
					<FormInput
						label="Purchase Order No."
						type="text"
						name="orders[0].parking.purchaseNo"
						id="parkingPurchaseNo"
						value={parkingFormData.purchaseNo || ''}
					/>
				</span>
				<span className="four-col check-cc">
					<FormRadioButton name="orders[0].parking.creditCard" id="creditCardYes" value="true" checked={creditCard == true ? true : false}>
						Credit/Debit Card
					</FormRadioButton>
					<span className="credit-card-contact">We will contact you to arrange payment</span>
				</span>
			</span>
		</span>
	);
};

export default CarForm;

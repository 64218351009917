/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Button
 * -----------------------------------------------------------------------------
 *
 * @param state
 * @param state.name {String} The icon name.
 * @param state.style {Object} CSS styles to apply to the component.
 * @param state.size Valid CSS font-size value. The font size of the icon. Default: 22. Note: if you set fontSize in state.style, it will be overwritten with this value.
 * @param state.color Valid CSS color value. Default: #FFFFFF. Note: if you set color in state.style, it will be overwritten with this value.
 *
 * @return {*}
 * @constructor
 */

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => ({
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
});

class GenericButton extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props.data };
	}

	componentDidMount() {
		//logger.log("[RegistrationButton] mounted %o: ", this.state);
	}

	componentDidUpdate() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	render() {
		logger.log('[GenericButton] render - state:%o: ', this.state);

		return (
			<div className={this.state.style}>
				<WimLink to={this.state.link} title={this.state.title || this.state.link}>
					<div className="generic-button_wrapper cms">
						<div className={'generic-button btn solid'}>
							<div className="content">{this.state.text}</div>
							<div className="border"></div>
						</div>
					</div>
				</WimLink>
			</div>
		);
	}
}

/**
 * -----------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------
 */
export default connect(mapStateToProps, mapDispatchToProps)(GenericButton);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import JSXParser from 'react-jsx-parser';
import Translate from 'appdir/components/pages/Slamtracker/Translate';
import ToolTip from 'appdir/components/common-ui/ToolTip';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: OverallStats
 *
 *  props:
 *  names: display names for both players/teams
 *  t1_stats: team 1 base stats for the currently selected set
 *  t2_stats: team 2 base stats for the currently selected set
 *  t1_distance: team 1 distance run for the currently selected set
 *  t2_distance: team 2 distance run for the currently selected set
 *  unit: unit of measurement found in the Controller
 *
 *  example of implementing this component can be found in Slamtracker/elements/MatchStats.js
 * -----------------------------------------------------------------------------
 */
const OverallStats = props => {
	// logger.log('[OverallStats] props:%o',props);

	const { data } = props;
	let shotquality_data;

	if(data?.matchExtras === true) {
		shotquality_data = useSelector((state) => state?.['Slamtracker']?.shotquality ?? {});
		// logger.log('[OverallStats] shotquality_data: %o',shotquality_data);
	} else {
		shotquality_data = {};
		// logger.log('[OverallStats] shotquality_data: %o',shotquality_data);
	}
	const tooltip_data = useSelector((state) => state?.['Config']?.slamtracker?.tooltipConfig ?? {});

	const names = data?.names;
	const t1_stats = data?.t1;
	const t2_stats = data?.t2;
	const t1_distance = data?.d1;
	const t2_distance = data?.d2;
	const unit = data?.unit;
	const doubles = data?.doubles;
	const set = data?.set == "match" ? 'total' : data?.set.replace('_', '');

	const [tooltip, showToolTip] = useState(false);

    const onClickCallback = () => {
        tooltip === true ? showToolTip(false) : showToolTip(true);
    }

	const percArray = [
		'first_srv_pct_in',
		'win_pct_first_srv',
		'win_pct_second_srv',
		'net_points_won',
		'break_points_won',
		'rec_pts_won',
	];

	const getDistancePtData = (data, data2, distance, unit) => {
		if (distance !== 'none') {
			let totalpoints_t1 = getOverallRowData('total_points', data);
			let totalpoints_t2 = getOverallRowData('total_points', data2);
			let totalpoints = totalpoints_t1 + totalpoints_t2;
			let dnum;
			let dpt;

			if (unit == 'M') {
				dnum = distance[0].substring(0, distance[0].indexOf(' '));
			} else if (unit == 'I') {
				dnum = distance[1].substring(0, distance[1].indexOf(' '));
			}

			dpt = parseFloat(dnum) / totalpoints;

			if (unit == 'M') {
				dpt = dpt.toFixed(1) + ' m';
			} else if (unit == 'I') {
				dpt = dpt.toFixed(1) + ' ft';
			}

			return dpt;
		}
	};

	const getDistancePtLeader = (data, distance, data2, distance2) => {
		if (distance !== 'none' && distance2 !== 'none') {
			let totalpoints_t1 = getOverallRowData('total_points', data);
			let totalpoints_t2 = getOverallRowData('total_points', data2);
			let totalpoints = totalpoints_t1 + totalpoints_t2;
			let dnum;
			let dnum2;
			let dpt;
			let dpt2;

			if (unit == 'M') {
				dnum = distance[0].substring(0, distance[0].indexOf(' '));
				dnum2 = distance2[0].substring(0, distance2[0].indexOf(' '));
			} else if (unit == 'I') {
				dnum = distance[1].substring(0, distance[1].indexOf(' '));
				dnum2 = distance2[1].substring(0, distance2[1].indexOf(' '));
			}

			dpt = parseFloat(dnum) / totalpoints;
			dpt2 = parseFloat(dnum2) / totalpoints;

			if (dpt > dpt2) {
				return true;
			} else {
				return false;
			}
		}
	};

	//     Total First Serves In - 0
	//     Total First Serves Won - 1
	//     Total First Serves - 2
	//     First Serve Percentage - 3
	//     Win % on First Serve - 4
	//     Total Second Serves Won - 5
	//     Total Second Serves - 6
	//     Second Serve Percentage - 7
	//     Win % on Second Serve - 8
	//     Total Aces - 9
	//     Double Faults - 10
	//     Total Winners - 11
	//     Total Unforced Errors - 12
	//     Total Break Points Won - 13
	//     Total Break Points - 14
	//     Break Point Conversion % - 15
	//     Total Net Points Won - 16
	//     Total Net Approaches - 17
	//     Net Approaches % - 18
	//     Total Points Won on Opponent's Serve - 19

	const getOverallRowData = (which, data, data2) => {
		// logger.log('[OverallStats] getOverallRowData');

		let stat_info;
		let t_f_srv_in, t_s_srv, f_srv_pct;
		let t_f_srv_w, w_pct_f_srv;
		let w_pct_s_srv, t_s_srv_w;
		let t_np_w, t_na, na_pct;
		let t_bp_w, t_bp, bp_con_pct;
		let t_p_w_opp_srv;

		switch (which) {
			case 'f_srv_pct': // 0
				t_f_srv_in = data?.t_f_srv_in == null ? 0 : data?.t_f_srv_in;
				t_s_srv = data?.t_s_srv == null ? 0 : data?.t_s_srv;
				f_srv_pct = data?.f_srv_pct == null ? 0 : data?.f_srv_pct;

				stat_info = t_f_srv_in + '/' + (t_f_srv_in + t_s_srv) + ' (' + f_srv_pct + '%)';
				return stat_info;

			case 'w_pct_f_srv': // 1
				t_f_srv_w = data?.t_f_srv_w == null ? 0 : data?.t_f_srv_w;
				t_f_srv_in = data?.t_f_srv_in == null ? 0 : data?.t_f_srv_in;
				w_pct_f_srv = data?.w_pct_f_srv == null ? 0 : data?.w_pct_f_srv;

				stat_info = t_f_srv_w + '/' + t_f_srv_in + ' (' + w_pct_f_srv + '%)';
				return stat_info;

			case 'w_pct_s_srv': //5
				t_s_srv_w = data?.t_s_srv_w == null ? 0 : data?.t_s_srv_w;
				t_s_srv = data?.t_s_srv == null ? 0 : data?.t_s_srv;
				w_pct_s_srv = data?.w_pct_s_srv == null ? 0 : data?.w_pct_s_srv;
				stat_info = t_s_srv_w + '/' + t_s_srv + ' (' + w_pct_s_srv + '%)';
				return stat_info;

			case 'na_pct': //16
				t_np_w = data?.t_np_w == null ? 0 : data?.t_np_w;
				t_na = data?.t_na == null ? 0 : data?.t_na;
				na_pct = data?.na_pct == null ? 0 : data?.na_pct;

				stat_info = t_np_w + '/' + t_na + ' (' + na_pct + '%)';
				return stat_info;

			case 'bp_con_pct': //13
				t_bp_w = data?.t_bp_w == null ? 0 : data?.t_bp_w;
				t_bp = data?.t_bp == null ? 0 : data?.t_bp;
				bp_con_pct = data?.bp_con_pct == null ? 0 : data?.bp_con_pct;

				stat_info = t_bp_w + '/' + t_bp + ' (' + bp_con_pct + '%)';
				return stat_info;

			case 'receiving_points_won': //19
				let stat_info_pct, rec_pt_data;

				t_p_w_opp_srv = data?.t_p_w_opp_srv == null ? 0 : data?.t_p_w_opp_srv;
				t_f_srv_in = data2?.t_f_srv_in == null ? 0 : parseInt(data2?.t_f_srv_in);
				t_s_srv = data2?.t_s_srv == null ? 0 : parseInt(data2?.t_s_srv);

				stat_info = t_p_w_opp_srv + '/' + (t_f_srv_in + t_s_srv);

				if (stat_info != '0/0') {
					stat_info_pct = Math.round((t_p_w_opp_srv / (t_f_srv_in + t_s_srv)) * 100);
					stat_info = stat_info + ' (' + stat_info_pct + '%)';
				} else {
					stat_info_pct = 0;
					stat_info = stat_info + ' (0%)';
				}

				rec_pt_data = [stat_info, stat_info_pct];

				return rec_pt_data;

			case 'total_points':
				t_f_srv_w = data?.t_f_srv_w == null ? 0 : parseInt(data?.t_f_srv_w);
				t_s_srv_w = data?.t_s_srv_w == null ? 0 : parseInt(data?.t_s_srv_w);
				t_p_w_opp_srv = data?.t_p_w_opp_srv == null ? 0 : parseInt(data?.t_p_w_opp_srv);

				stat_info = t_f_srv_w + t_s_srv_w + t_p_w_opp_srv;
				return stat_info;

			default:
				break;
		}
	};

	const renderInAttack = () => {
		const in_attack_data = shotquality_data?.results?.insights?.in_attack;
		let show_attack_data = in_attack_data?.match?.[set]?.p1 && in_attack_data?.match?.[set]?.p2 && in_attack_data?.match?.[set]?.neutral ? true : false;

		// logger.log('[OverallStats] in_attack_data: %o, set: %o',in_attack_data, set);

		return show_attack_data ? (
			<div className="stats-row in-attack" data-ref="in-attack">
				<div className="in-attack-content">
					<div className="in-attack-header">
						In Attack<i className="wim-icon-help" onClick={() => (onClickCallback())}></i>						
						{tooltip === true ? <ToolTip text={tooltip_data?.in_attack} style="inattack" /> : null}
					</div>
					<div className="in-attack-values">
						<div className="p1" style={{width: `${in_attack_data?.match?.[set]?.p1}%`}}>
							{`${in_attack_data?.match?.[set]?.p1 || ''}${in_attack_data?.match?.[set]?.p1 ? '%' : ''}`}
						</div>
						<div className="neutral" style={{width: `${in_attack_data?.match?.[set]?.neutral}%`}}>
							{`${in_attack_data?.match?.[set]?.neutral || ''}${in_attack_data?.match?.[set]?.neutral ? '%' : ''}`}
						</div>
						<div className="p2" style={{width: `${in_attack_data?.match?.[set]?.p2}%`}}>
							{`${in_attack_data?.match?.[set]?.p2 || ''}${in_attack_data?.match?.[set]?.p2 ? '%' : ''}`}
						</div>
					</div>
					<div className="in-attack-labels">
						<div className="p1">Attacking</div>
						<div className="neutral">Neutral</div>
						<div className="p2">Attacking</div>
					</div>
				</div>
			</div>
		) : null
	}
	
	const renderCalculationRow = stat => {
		let t1_val, t2_val;
		let t1_comp, t2_comp;

		switch (stat) {
			case 'first_srv_pct_in':
				t1_val = getOverallRowData('f_srv_pct', t1_stats);
				t2_val = getOverallRowData('f_srv_pct', t2_stats);
				t1_comp = t1_stats?.f_srv_pct == null ? 0 : t1_stats?.f_srv_pct;
				t2_comp = t2_stats?.f_srv_pct == null ? 0 : t2_stats?.f_srv_pct;
				break;

			case 'win_pct_first_srv':
				t1_val = getOverallRowData('w_pct_f_srv', t1_stats);
				t2_val = getOverallRowData('w_pct_f_srv', t2_stats);
				t1_comp = t1_stats?.w_pct_f_srv == null ? 0 : t1_stats?.w_pct_f_srv;
				t2_comp = t2_stats?.w_pct_f_srv == null ? 0 : t2_stats?.w_pct_f_srv;
				break;

			case 'win_pct_second_srv':
				t1_val = getOverallRowData('w_pct_s_srv', t1_stats);
				t2_val = getOverallRowData('w_pct_s_srv', t2_stats);
				t1_comp = t1_stats?.w_pct_s_srv == null ? 0 : t1_stats?.w_pct_s_srv;
				t2_comp = t2_stats?.w_pct_s_srv == null ? 0 : t2_stats?.w_pct_s_srv;
				break;

			case 'net_points_won':
				t1_val = getOverallRowData('na_pct', t1_stats);
				t2_val = getOverallRowData('na_pct', t2_stats);
				t1_comp = t1_stats?.na_pct == null ? 0 : t1_stats?.na_pct;
				t2_comp = t2_stats?.na_pct == null ? 0 : t2_stats?.na_pct;
				break;

			case 'break_points_won':
				t1_val = getOverallRowData('bp_con_pct', t1_stats);
				t2_val = getOverallRowData('bp_con_pct', t2_stats);
				t1_comp = t1_stats?.bp_con_pct == null ? 0 : t1_stats?.bp_con_pct;
				t2_comp = t2_stats?.bp_con_pct == null ? 0 : t2_stats?.bp_con_pct;
				break;

			case 'total_points_won':
				t1_val = getOverallRowData('total_points', t1_stats);
				t2_val = getOverallRowData('total_points', t2_stats);
				t1_comp = t1_val;
				t2_comp = t2_val;
				break;

			default:
				break;
		}

		return (
			<div className={`stats-row ${stat}`} data-ref={stat}>
				<div className={`stats-data t1 ${t1_comp > t2_comp ? 'leading' : ''}`} id={`${stat}_p1`}>
					{t1_val}
				</div>
				<div className="stats-label">
					<Translate originalText={stat} />
				</div>
				<div className={`stats-data t2 ${t2_comp > t1_comp ? 'leading' : ''}`} id={`${stat}_p2`}>
					{t2_val}
				</div>
			</div>
		);
	};

	const renderValueRow = stat => {
		let t1_val, t1_comp;
		let t2_val, t2_comp;

		switch (stat) {
			case 'aces':
				t1_val = t1_stats?.t_ace == null ? 0 : t1_stats?.t_ace;
				t2_val = t2_stats?.t_ace == null ? 0 : t2_stats?.t_ace;
				t1_comp = t1_stats?.t_ace == null ? 0 : t1_stats?.t_ace;
				t2_comp = t2_stats?.t_ace == null ? 0 : t2_stats?.t_ace;
				break;

			case 'double_faults':
				t1_val = t1_stats?.df == null ? 0 : t1_stats?.df;
				t2_val = t2_stats?.df == null ? 0 : t2_stats?.df;
				t1_comp = t1_stats?.df == null ? 0 : t1_stats?.df * -1; // looking for lowest value to be leader
				t2_comp = t2_stats?.df == null ? 0 : t2_stats?.df * -1; // looking for lowest value to be leader
				break;

			case 'winners':
				t1_val = t1_stats?.t_w == null ? 0 : t1_stats?.t_w;
				t2_val = t2_stats?.t_w == null ? 0 : t2_stats?.t_w;
				t1_comp = t1_stats?.t_w == null ? 0 : t1_stats?.t_w;
				t2_comp = t2_stats?.t_w == null ? 0 : t2_stats?.t_w;
				break;

			case 'unforced_errors':
				t1_val = t1_stats?.t_ue == null ? 0 : t1_stats?.t_ue;
				t2_val = t2_stats?.t_ue == null ? 0 : t2_stats?.t_ue;
				t1_comp = t1_stats?.t_ue == null ? 0 : t1_stats?.t_ue * -1; // looking for lowest value to be leader
				t2_comp = t2_stats?.t_ue == null ? 0 : t2_stats?.t_ue * -1; // looking for lowest value to be leader
				break;

			default:
				break;
		}

		return (
			<div className={`stats-row ${stat}`} data-ref={stat}>
				<div className={`stats-data t1 ${t1_comp > t2_comp ? 'leading' : ''}`} id={`${stat}_p1`}>
					{t1_val}
				</div>
				<div className="stats-label">
					<Translate originalText={stat} />
				</div>
				<div className={`stats-data t2 ${t2_comp > t1_comp ? 'leading' : ''}`} id={`${stat}_p2`}>
					{t2_val}
				</div>
			</div>
		);
	};

	const renderReceivingPointsWonRow = stat => {
		let t1_data = getOverallRowData('receiving_points_won', t1_stats, t2_stats);
		let t1_val = t1_data[0];
		let t1_comp = t1_data[1];
		let t2_data = getOverallRowData('receiving_points_won', t2_stats, t1_stats);
		let t2_val = t2_data[0];
		let t2_comp = t2_data[1];
		
		return (
			<div className={`stats-row ${stat}`} data-ref={stat}>
				<div className={`stats-data t1 ${t1_comp > t2_comp ? 'leading' : ''}`} id={`${stat}_p1`}>
					{t1_val}
				</div>
				<div className="stats-label">
					<Translate originalText={stat} />
				</div>
				<div className={`stats-data t2 ${t2_comp > t1_comp ? 'leading' : ''}`} id={`${stat}_p2`}>
					{t2_val}
				</div>
			</div>
		);
	};

	const renderDistanceRow = stat => {
		if (t1_distance == 'none' || t2_distance == 'none') {
			return null;
		} else {
			return (
				<div className={`stats-row ${stat}`} data-ref={stat}>
					<div
						className={`stats-data t1 ${t1_distance[0] > t2_distance[0] ? 'leading' : ''}`}
						id={`${stat}_p1`}>
						<span className="metric">{t1_distance[0] == null ? 0 : t1_distance[0]}</span>
						<span className="imperial">{t1_distance[1] == null ? 0 : t1_distance[1]}</span>
					</div>
					<div className="stats-label">
						<Translate originalText={stat} /> <span className="unit" />
					</div>
					<div
						className={`stats-data t2 ${t2_distance[0] > t1_distance[0] ? 'leading' : ''}`}
						id={`${stat}_p2`}>
						<span className="metric">{t2_distance[0] == null ? 0 : t2_distance[0]}</span>
						<span className="imperial">{t2_distance[1] == null ? 0 : t2_distance[1]}</span>
					</div>
				</div>
			);
		}
	};

	const renderDistancePtRow = stat => {
		if (t1_distance == 'none' || t2_distance == 'none') {
			return null;
		} else {
			let t1_distance_pt_metric = getDistancePtData(t1_stats, t2_stats, t1_distance, 'M');
			let t1_distance_pt_imperial = getDistancePtData(t1_stats, t2_stats, t1_distance, 'I');
			let t2_distance_pt_metric = getDistancePtData(t2_stats, t1_stats, t2_distance, 'M');
			let t2_distance_pt_imperial = getDistancePtData(t2_stats, t1_stats, t2_distance, 'I');

			return (
				<div className={`stats-row ${stat}`} data-ref={stat}>
					<div
						className={`stats-data t1 ${
							getDistancePtLeader(t1_stats, t1_distance, t2_stats, t2_distance) ? 'leading' : ''
						}`}
						id={`${stat}_p1`}>
						<span className="metric">{t1_distance_pt_metric}</span>
						<span className="imperial">{t1_distance_pt_imperial}</span>
					</div>
					<div className="stats-label">
						<Translate originalText={stat} /> <span className="unit" />
					</div>
					<div
						className={`stats-data t2 ${
							getDistancePtLeader(t2_stats, t2_distance, t1_stats, t1_distance) ? 'leading' : ''
						}`}
						id={`${stat}_p2`}>
						<span className="metric">{t2_distance_pt_metric}</span>
						<span className="imperial">{t2_distance_pt_imperial}</span>
					</div>
				</div>
			);
		}
	};

	return (
		<div className="stats-table-wrapper overall matchstats">
			<div className="stats-header-container">
				<div className="stats-header-row">
					<div className="stats-header-cell t1">
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[0]} />
						</div>
					</div>
					<div className="stats-header-cell t2">
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[1]} />
						</div>
					</div>
				</div>
			</div>

			<div className="stats-content">
				{(data?.matchExtras === true && data?.useInAttack === true) && renderInAttack() }
				{renderValueRow('aces')}
				{renderValueRow('double_faults')}
				{renderCalculationRow('first_srv_pct_in')}
				{renderCalculationRow('win_pct_first_srv')}
				{renderCalculationRow('win_pct_second_srv')}
				{renderCalculationRow('net_points_won')}
				{renderCalculationRow('break_points_won')}
				{renderReceivingPointsWonRow('rec_pts_won')}
				{renderValueRow('winners')}
				{renderValueRow('unforced_errors')}
				{renderCalculationRow('total_points_won')}
				{renderDistanceRow('distance_run')}
				{renderDistancePtRow('distance_per_pt')}
			</div>
		</div>
	);
};

export default OverallStats;
import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SEATINGVIEWS_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		case deps.actionTypes.SEATINGVIEWS_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		default:
			return state;
	}
};

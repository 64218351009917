export default {
	DRAWSPAGE_MOUNT: 'DRAWSPAGE_MOUNT',
	DRAWSPAGE_UNMOUNT: 'DRAWSPAGE_UNMOUNT',
	DRAWSPAGE_LOAD: 'DRAWSPAGE_LOAD',
	DRAWSPAGE_FILTER: 'DRAWSPAGE_FILTER',
	DRAWSPAGE_ROUND: 'DRAWSPAGE_ROUND',
	DRAWS_SEARCH: 'DRAWS_SEARCH',
	DRAWS_RENDER: 'DRAWS_RENDER',
	DRAWS_FIND: 'DRAWS_FIND',
	DRAWSPAGE_UPDATE: 'DRAWSPAGE_UPDATE',
	DRAWS_CHANGE_VIEW: 'DRAWS_CHANGE_VIEW',
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
// import WimLink from 'appdir/components/general/WimLink';
import { connect } from 'react-redux';
import deps from 'dependencies';
// import { values } from 'appdir/main';
import op from 'object-path';
import { MatchArchiveContext } from './context';

import Helmet from 'react-helmet';
import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
// import VideoWrapper from 'appdir/components/general/VideoWrapper';
import SiteMap from 'appdir/components/general/SiteMap';
import MeasurementUtils from 'appdir/lib/analytics';

import MatchNav from './elements/MatchNav';
import MatchVideo from './elements/MatchVideo';
import MatchTitle from './elements/MatchTitle';
import MatchContent from './elements/MatchContent';
import MatchStats from './elements/MatchStats';
import MatchArchiveLanding from './elements/MatchArchiveLanding';
import ReplayType from './elements/ReplayType';

import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchArchive
 * -----------------------------------------------------------------------------
 */
const dayLabels = {
	'1': '1',
	'2': '2',
	'3': '3',
	'4': '4',
	'5': '5',
	'6': '6',
	'7': 'Middle Sunday',
	'8': '7',
	'9': '8',
	'10': '9',
	'11': '10',
	'12': '11',
	'13': '12',
	'14': '13',
	'15': "People's Monday",
};

const mapStateToProps = (state, props) => {
	return {
		...state['MatchArchive'],
		...props,
		windowSize: state['PageHeader'].windowSize,
		scoringConfig: op.get(state['Config'], 'scoringConfig'),
		otherData: state['Config']['otherData'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchArchive.mount()),
	unmount: () => dispatch(deps.actions.MatchArchive.unmount()),
	updateMode: mode => dispatch(deps.actions.MatchArchive.updateMode(mode)),
});

class MatchArchive extends Component {
	constructor(props) {
		super(props);

		logger.log('[MatchArchive] constructor - props:%o', props);

		this.onReplayType = this.onReplayType.bind(this);
		this.pickTab = this.pickTab.bind(this);

		this.state = {
			...props,
			matchTab: 'match',
		};
		this.loadingNewMatch = false;
	}

	componentDidMount() {
		logger.log('[MatchArchive] componentDidMount - state:%o', this.state);
	}

	componentWillUpdate(nextProps, nextState) {}

	componentWillUnmount() {
		//logger.log('[MatchArchive] componentWillUnmount');
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[MatchArchive] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MatchArchive] componentDidUpdate - state:%o ', this.state);
		let current = op.get(this.state, 'currentMatch', null);
		let prev = op.get(prevState, 'currentMatch', { id: '' });

		if (current && current != 'notfound' && this.state.matchArchive && current.id != prev.id) {
			logger.log('[MatchArchive] componentDidUpdate - curr:%o prev:%o', current.id, prev.id);

			if (!this.loadingNewMatch) {
				this.loadingNewMatch = true;

				// get match archive details
				logger.log('[MatchArchive] componentDidUpdate - curr:%o', current);
				let archivePath = this.state.matchArchivePath.replace('<contentId>', current.contentId);

				deps.services.MatchArchive.fetch(archivePath)
					.then(response => {
						logger.log('[MatchArchive] componentDidUpdate - response: %o', response);

						current.cmsDetail = { ...response.archive };

						//send pageview call
						MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
							pageTitle: 'Match Archives',
							match: {
								id: current.id,
								title: current.cmsDetail.title,
							},
						});

						this.setState(prevState => {
							return {
								...prevState,
								currentMatch: current,
							};
						});

						this.loadingNewMatch = false;
					})
					.catch(error => {
						logger.error('[MatchArchive] componentDidUpdate - error:%o', error);
					});

				//check for questring addendum to point history for testing alternates
				let historyId = current.id;
				let search = document.location.search.replace(/^\?/, '');
				let parsedQs = getQuerystringValues(search);
				if (parsedQs['history']) {
					historyId += parsedQs['history'];
				}

				let pointsPath = this.state.matchHistoryPath.replace('<matchId>', historyId);
				let excitePath = this.state.matchExcitePath.replace('<matchId>', current.id);
				let matchData = {
					team1: current.players[0].firstName.substr(0, 1) + '. ' + current.players[0].lastName,
					team2: current.players[1].firstName.substr(0, 1) + '. ' + current.players[1].lastName,
					year: current.year,
					event: current.event,
					excitmentPoints: null,
				};

				//find offset for the match
				let matchOffset = this.state.matchOffsets.filter(match => {
					return match.id == current.id;
				});
				//logger.log('[MatchArchive] componentDidUpdate - offset: %o', matchOffset);
				if (matchOffset.length == 0) {
					matchOffset = [{}];
				}

				//get excitement data
				deps.services.MatchArchive.fetch(excitePath)
					.then(response => {
						logger.log('[MatchArchive] componentDidUpdate - response: %o', response);

						matchData.excitementPoints = response;
					})
					.catch(error => {
						logger.error('[MatchArchive] componentDidUpdate - error:%o', error);
					})
					.finally(info => {
						//get point history after try to load excite data
						deps.services.MatchArchive.fetchPoints(pointsPath, matchData, matchOffset[0])
							.then(response => {
								//logger.log('[MatchArchive] componentDidUpdate - response: %o', response);

								current.points = response;

								this.setState(prevState => {
									return {
										...prevState,
										currentMatch: current,
									};
								});

								this.loadingNewMatch = false;
							})
							.catch(error => {
								logger.error('[MatchArchive] componentDidUpdate - error:%o', error);
							});
					});
			}
		}
	}

	onReplayType(mode) {
		//logger.log('[MatchArchive] onReplayType - mode:%o', mode);
		this.props.updateMode(mode);
	}

	pickTab(matchTab) {
		MeasurementUtils.dispatchMeasurementCall('Match Archive', { data: ['Detail', 'Select Tab', matchTab] });
		this.setState({ matchTab });
	}

	getSubTitle(round) {
		let subtitle = '';

		if (op.get(round, 'roundName', false)) {
			if (this.state.currentDay !== '7' && this.state.currentDay !== '15') {
				subtitle = `DAY ${dayLabels[this.state.currentDay]} - ${round.roundName}`;
			} else {
				subtitle = dayLabels[this.state.currentDay];
			}
		}

		return subtitle;
	}

	getEventName() {
		let eventName = op.get(this.state, 'scoringConfig.eventNames', []).filter(event => {
			if (event.code == this.state?.currentMatch?.event) {
				return true;
			} else {
				return false;
			}
		})[0];
		eventName = eventName ? eventName.long : '';
		return eventName;
	}

	getRoundName() {
		let roundName = op.get(this.state, 'scoringConfig.roundNames', []).filter(round => {
			if (round.roundCode == this.state?.currentMatch?.round) {
				return true;
			} else {
				return false;
			}
		})[0];
		roundName = roundName ? roundName.roundName : '';
		return roundName;
	}

	render() {
		logger.log('[MatchArchive] render - state:%o', this.state);

		let round = op.get(this.state, 'scoringConfig.roundNames', []).filter(round => {
			if (this.state.currentRound == round.roundCode) {
				return true;
			} else {
				return false;
			}
		})[0];

		//logger.log('[MatchArchive] render - subtitle:%o', this.getSubTitle(round));
		//logger.log('[MatchArchive] render - round:%o', this.getRoundName());
		//logger.log('[MatchArchive] render - event:%o', this.getEventName());

		let pageTitle = `The Greatest Championships`;
		let pageDescription = ``;
		let pageImage = ``;
		let playerList = undefined;

		if (op.get(this.props, 'currentMatch.year', false)) {
			let { year, players } = this.props.currentMatch;
			pageTitle = `${pageTitle}, ${year} - ${players[0].firstName} ${players[0].lastName} vs ${players[1].firstName} ${players[1].lastName}`;
			pageDescription = `${pageTitle}, ${this.getEventName()}, ${this.getRoundName()}`;
			pageImage = op.get(this.props, 'currentMatch.cmsDetail.video.images.thumb.large', '');
			playerList = `${players[0].firstName} ${players[0].lastName} | ${players[1].firstName} ${players[1].lastName}`;
		}

		let header_propsData = {
			headerType: 'match-archive',
			title: `GREATEST CHAMPIONSHIPS`,
			shortTitle: 'GREATEST CHAMPIONSHIPS',
			// subTitle: op.get(round, 'roundName', false) ? `DAY ${this.state.currentDay} - ${round.roundName}` : '',
			subTitle: this.getSubTitle(round),
			scroll: false,
			metaTitle: pageTitle,
			metaDescription: pageDescription,
			metaDate: op.get(this.state, 'currentMatch.cmsDetail.date', 0),
		};

		let contextValues = {
			matches: this.state.matchArchive,
			currentRound: round,
			roundNames: op.get(this.state, 'scoringConfig.roundNames', []),
			onReplayType: this.onReplayType,
		};

		//logger.log('[MatchArchive] render - state:%o', this.state);
		//logger.log('[MatchArchive] render - id:%o', op.get(this.state, 'currentMatch.id', ''));

		//if the landing page
		if (this.state.landing) {
			header_propsData.headerType = 'match-archive-landing';
			logger.log('[MatchArchive] render - landing - contextValues:%o', contextValues);
			return (
				<Template className="scorespage">
					<div className="match-archive-landing-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main landing match-archive">
						<MatchArchiveLanding
							directory={this.state.directory}
							matches={this.state.matchArchive}
							currentRound={round}
							roundNames={op.get(this.state, 'scoringConfig.roundNames', [])}
						/>
					</div>
					<Footer />
				</Template>
			);
		}

		//if not found
		else if (this.state.currentMatch === 'notfound') {
			header_propsData = {
				headerType: 'generic',
				title: 'Sitemap',
				shortTitle: 'Sitemap',
				metaTitle: 'Page not found',
				metaDescription: '',
				metaDate: '',
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<SiteMap notFound={true} />
						<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
					</div>
					<Footer />
				</Template>
			);
		}

		// if match was found
		else if (this.state.currentMatch) {
			//add data for Helmet
			header_propsData = {
				...header_propsData,
				type: 'V',
				canonicalLink: this.props.pageUrl,
				shareImage: pageImage,
				metaPlayers: playerList,
			};

			return (
				<Template className="scorespage">
					<div className="match-archive-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<MatchArchiveContext.Provider value={contextValues}>
						<div className="content-main landing match-archive">
							<div className="column-layout-six">
								<div className="six-col match-nav-parent">
									<div className="match-nav-wrapper">
										<MatchNav
											directory={this.state.directory}
											currentMatchId={this.state.currentMatch.id}
											currentMatchRound={this.state.currentMatch.round}
										/>
									</div>
								</div>
							</div>
							<div className="column-layout-six match-header">
								<MatchTitle
									mode={this.state.mode}
									currentMatch={this.props.currentMatch}
									scoringConfig={this.state.scoringConfig}
									url={this.props.pageUrl}
									otherData={this.state.otherData}
								/>
								<ReplayType mode={this.state.mode} />
							</div>
							<div className="column-layout-six">
								<MatchVideo
									currentMatch={this.state.currentMatch}
									scoringConfig={this.state.scoringConfig}
									mode={this.state.mode}
									shareUrl={this.props.pagePath}
									windowSize={this.state.windowSize}
									heartbeatInterval={this.state.heartbeatInterval}
								/>
							</div>
							<div className="column-layout-six match-tabs">
								<div
									className={`match-content ${this.state.matchTab == 'match' ? `active` : ``}`}
									onClick={() => this.pickTab('match')}>
									<div className="icon">
										<i className="wim-icon-tennis-rackets" />
									</div>
									<div className="label">Match Content</div>
								</div>
								<div
									className={`statistics ${this.state.matchTab == 'stats' ? `active` : ``}`}
									onClick={() => this.pickTab('stats')}>
									<div className="icon">
										<i className="wim-icon-stats" />
									</div>
									<div className="label">
										End of Match
										<br />
										Statistics
									</div>
								</div>
							</div>
							{/* <div className="column-layout-six statContent"> */}
							{this.state.matchTab == 'match' && (
								<MatchContent
									relatedContent={op.get(this.state, 'currentMatch.cmsDetail.relatedContent', [])}
								/>
							)}
							{this.state.matchTab == 'stats' && <MatchStats data={this.state.currentMatchStats} />}
							{/* </div> */}
						</div>
					</MatchArchiveContext.Provider>
					<Footer />
				</Template>
			);
		}

		//loading screen
		else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<LoadingIndicator type={'white'} />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchArchive);

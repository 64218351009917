
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import JSXParser from 'react-jsx-parser';
import Button from 'appdir/components/common-ui/Button';
import Time from 'appdir/components/common-ui/Time';
import NewFlagTime from 'appdir/components/common-ui/NewFlagTime';
import { getInterfaceLink } from 'appdir/components/general/Util/index.js';
import { measureInWeb } from 'appdir/components/general/Analytics';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getComponents } from 'appdir/components/general/Util';

const AIDaySummary = props => {
	const dispatch = useDispatch();
	const data = props?.data;
	const windowSize = useSelector(state => state['PageHeader']?.windowSize);
	const addCols = (windowSize == "desktop" ? "two-col" : "");
	const tournIsoStart = useSelector(state => state['Config']?.dateConfig?.tournIsoStart);
	// logger.log('[AIDaySummary] windowSize:%o, addCols:%o', windowSize, addCols);

	const replaceLinks = (txtContent) => {
		let result = txtContent.match(/<a href='\/en_GB\/.*?a>/g);
		// logger.log('[AIDaySummary] replaceLinks -  txtContent:%o, result:%o',txtContent, result);
		if (result) { 
			result.forEach(link => {
				let m = link.match(/href='(.*)'>(.*)<\/a>/);
				let modified = m[1].slice(0, -5); //remove html ending from string
				// logger.log('[AIDaySummary] replaceLinks - m:%o, modifed: %o', m, modified);
				//replace links with webview profile
				//txtContent = txtContent.replace(link, `<a href="/webview${modified}/profile.html">${m[2]}</a>`);
				txtContent = txtContent.replace(link, `<WimLink to="${m[1]}">${m[2]}</WimLink>`);
			});
		}
		// logger.log('[AIDaySummary] replaceLinks - txtContent:%o', txtContent);
		return txtContent;
	}

	const fireReadMoreMetrics = () => {
        /** Catch Me Up Home is native - only send metrics for web */
		measureInWeb({
			action: 'Catch Me Up:Read More',
			context: [{day_summary: 'day_summary_' + data?.day}],
		});
    }

	/** Read More click that links to Catch Me Up page */
	const onLinkClick = (path) => {
		//logger.log('[AIDaySummary] path:%o', path);
		fireReadMoreMetrics();

		if (window.webview) {
			let summary = { id: "summary"};
			const interfaceLink = getInterfaceLink('catchup', summary);
			interfaceLink?.onclick();
			// logger.log('[AIDaySummary] interfaceLink:%o, click:%o, data:%o ', interfaceLink, interfaceLink?.click, summary);

		} else {
			dispatch(deps.actions.MainNav.navigate({path}))
		}
	}

	const getDayStringSummary = () => {
		let eod = data.day - 8; // minus 7 days for quals
		let addDay = moment(tournIsoStart).add(eod, 'days').format("dddd DD MMMM");
		let dayString = addDay + ' Summary';
		dayString = 'Daily Summary'; //remove day because it isn't consistently in the file
		// logger.log('[AIDaySummary] getDayStringSummary - dayString:%o, data.day:%o, tournIsoStart:%o, addDay:%o, eod:%o ', dayString, data.day, tournIsoStart, addDay, eod);
		
		return dayString;
	}

	const shortLayout = (jsxContent) => {
		return (
			<div className="summary-card" id="summary">
				<div className="summary-wrapper">
					<div className="ai-recommends">
						<NewFlagTime
							data={{
								displayDate: data?.updateTime, 
							}}
						/>
					</div>
					<div className="title">Daily Summary</div>
					<div className="text">
						{/* <p>{parse(replaceLinks(data?.content))}</p> */}
						<p>
							<JSXParser
								//bindings={attributes}
								components={{ ...getComponents(['WimLink']) }}
								jsx={jsxContent}
								renderInWrapper={false}
								showWarnings={true}
							/>
						</p>
					</div>
					<div className="updated">Updated: <Time epoch_ms={data?.updated_epoch} format="ddd HH:mm z" options="upper" /></div>
				</div>
				<div className="button-wrapper">
					<Button 
						aria-label="Read More"
						className="solid compact"
						style={{width: '100%'}}
						tabIndex={0}
						onClick={e => {
							onLinkClick(`/en_GB/catchup/index.html?id=summary`);
						}}>
							Read More
					</Button>
				</div>
			</div>
		);
	};
	
	const fullLayout = (jsxContent) => {
		// logger.log('[AIDaySummary] fullLayout data:%o',data);
		return (
			<div className="summary-card" id="summary">
				{ windowSize !== "desktop" ? (
					<div className="title">{getDayStringSummary()}</div>
				) : null }
				<div className={`image-container ${addCols}`}>
					<img src="https://photo-assets.wimbledon.com/images/pics/large/b_20230712_rainbow_EK1_8911-313635_2447.jpg" />
				</div>
				<div className={`summary-info`}>
				{windowSize == "desktop" ? (
					<div className="title">{getDayStringSummary()}</div>
				) : null }
					<div className="text">
						{/* <p>{parse(replaceLinks(data?.content))}</p> */}
						<p>
						<JSXParser
							//bindings={attributes}
							components={{ ...getComponents(['WimLink']) }}
							jsx={jsxContent}
							renderInWrapper={false}
							showWarnings={true}
						/>
						</p>
					</div>
				</div>
				{/* <div className="webview signup"> */}
				{/* <div className="webview signup hide"> */}
					{/* <Button 
						to="/index.html"
						className="solid"
						style={{width: '100%'}}>
							Sign Up for <sub><i className="wim-icon-myw my" aria-label="my wimbledon" /></sub>Wimbledon
					</Button>
				</div> */}
				{/* <IBMwatsonx styleClasses={props?.styleClasses ?? 'webview ai-subtext'} /> */}
			</div>
		);
	}

	const selectCard = () => {
		let card = '';
		let jsxContent = replaceLinks(data?.content);
		switch(props?.cardType) {
			case 'fullWeb':
			case 'fullWebview':
				card = fullLayout(jsxContent);
				break;
			case 'shortWeb':
			case 'shortWebview':
				card = shortLayout(jsxContent);
				break;
			default:
				card = fullLayout(jsxContent);
				break;
		}
		return card;
	}

	//display corresponding layout
	return	selectCard();
}

export default AIDaySummary;

import React from 'react';

const Columns = ({ data }) => {
	let bg1 = {
		backgroundColor: 'Bisque',
	};
	let bg2 = {
		backgroundColor: 'LightSteelBlue',
	};
	let bg3 = {
		backgroundColor: 'Gainsboro',
	};
	let bg4 = {
		backgroundColor: 'AliceBlue',
	};

	return (
		<div class="content-main column-layout news article ">
			<div class="news--header-wrapper">
				<div class="news--header show">
					<div class="news--header-icon">
						<i class="wim-icon-news" aria-label="news icon"></i>
					</div>
					<div class="news--header-date">
						<h3>
							<div>TUE 07 MAY 2019 18:50 BST</div>
						</h3>
					</div>
					<div class="news--header-title">
						<h1>Components Test Page</h1>
					</div>
					<div class="news--header-shorttitle">
						<h1>Components Test Page</h1>
					</div>
					<div class="news--header-abstract">
						<h4>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc neque orci, rutrum ut lectus
							et, iaculis suscipit leo. Nunc blandit commodo nisl id tincidunt. In quis feugiat ipsu.
						</h4>
						<h3> READ MORE</h3>
					</div>
				</div>
			</div>
			<div class="four-col byline ">
				<div class="byline--attribution">By Andy Burns</div>
				<div class="byline--share">
					<div class="byline--share-button">
						<i class="wim-icon-share"></i>SOCIAL
					</div>
					<div class="byline--share-wrapper threecol">
						<div class="share-popup hide">
							<div class="share-box-arrow top"></div>
							<div class="share-icons">
								<i role="button" class="wim-icon-facebook" title="Facebook"></i>
								<i role="button" class="wim-icon-twitter" title="Twitter"></i>
								<i role="button" class="wim-icon-weibo" title="Weibo"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="four-col" style={bg4}>
				Four Column
			</div>
			<div class="one-col" style={bg1}>
				One Column 1<br />
				line
			</div>
			<div class="one-col" style={bg1}>
				One Column 2
			</div>
			<div class="one-col" style={bg1}>
				One Column 3<br />
				line
			</div>
			<div class="one-col" style={bg1}>
				One Column 4
			</div>
			<div class="one-col" style={bg1}>
				One Column 5
			</div>
			<div class="two-col" style={bg2}>
				Two Column 1
			</div>
			<div class="three-col" style={bg3}>
				Three Column 1
			</div>
			<div class="one-col" style={bg1}>
				One Column 6<br />
				line
			</div>
			<div class="one-col" style={bg1}>
				One Column 7
			</div>
			<div class="one-col" style={bg1}>
				One Column 8
			</div>
			<div class="one-col" style={bg1}>
				One Column 9<br />
				line
			</div>
			<div class="one-col" style={bg1}>
				One Column 10
			</div>
			<div class="one-col" style={bg1}>
				One Column 11
			</div>
			<div class="four-col" style={bg4}>
				Four Column
			</div>
			<div class="one-col margin" style={bg1}>
				One Column 8 Margin
			</div>
			<div class="one-col" style={bg1}>
				One Column 9<br />
				line
			</div>
			<div class="one-col" style={bg1}>
				One Column 10
			</div>
			<div class="one-col" style={bg1}>
				One Column 11
			</div>
			<div class="four-col" style={bg4}>
				Four Column
			</div>
			<div class="three-col margin" style={bg3}>
				Three Column 1 Margin
			</div>
			<div class="two-col" style={bg2}>
				Two Column 1
			</div>
		</div>
	);
};

export default Columns;

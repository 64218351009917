/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

import { loggedIn } from 'appdir/components/general/Util/Role';
import { getQuerystringParam } from 'appdir/components/general/Util';

import TicketResaleCheckin from 'appdir/components/webviews/TicketResaleWebview/TicketResaleCheckin.js';
import WebviewResaleStatusView from 'appdir/components/webviews/TicketResaleWebview/WebviewResaleStatusView.js';
import WebviewResaleReady from 'appdir/components/webviews/TicketResaleWebview/WebviewResaleReady.js';
import ExpiredQueue from 'appdir/components/webviews/TicketResaleWebview/ExpiredQueue';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { handleGetQueueStatus, handleGetResaleQueueConfig } from 'appdir/components/webviews/TicketResaleWebview/ticketResaleEndpoints';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getErrorText } from 'appdir/components/general/Util/Error';
import { measureInAppContext } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: TicketResaleWebview
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		gigya: state['Gigya'],
		configStatus: op.get(state['Config'], 'status', null),
		config: op.get(state['Config'], null),
		resale_queue: op.get(state['Config'], 'queue.resale_queue', null),
		queues: op.get(state['Config'], 'queue.queues', null),
		queueAPIs: op.get(state['Config'], 'queue.api.resale', null),
		configText: op.get(state['Config'], 'queue.text.resale', null),
		configUrls: op.get(state['Config'], 'queue.url.resale', null),
		configData: op.get(state['Config'], 'queue.data.resale', null),
		authEnv: op.get(state['Config'], 'info.authEnv', null),
		...state['TicketResaleWebview'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(actions.TicketResaleWebview.mount()),
	getJWT: data => dispatch(deps.actions.Gigya.getJWT(data)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class TicketResaleWebview extends Component {
	constructor(props) {
		super(props);

		// logger.log('[TicketResaleWebview] constructor - props:%o', props);

		// now add all the props and filters to state
		this.state = {
			...props,
			data: '',
		};
		//this.dataLoad = '';
	}

	componentDidMount() {
		// logger.log('[TicketResaleWebview] componentDidMount - state:%o', this.state);

		this.debugView = getQuerystringParam(null, 'ticketDebug');

      
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newUser = op.get(nextProps, 'gigya.currentUser', {});
		let prevUser = op.get(prevState, 'gigya.currentUser', {});

		// logger.log('[TicketResaleWebview] getDerivedStateFromProps - nextProps:%o', nextProps);

		return newUser.UID != prevUser.UID ||
			(op.get(nextProps, 'gigya.gigyaLoaded', false) && !op.get(prevState, 'gigya.gigyaLoaded', false))
			? {
					...prevState,
					...nextProps,
			  }
			: null;
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'gigya.currentUser', {});
		logger.log(
			'[TicketResaleWebview] componentDidUpdate - dataLoad:%o, user:%o state:%o prev:%o',
			this.dataLoad,
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			prevState
		);

		let ticketResaleRole = currentUser?.roles?.filter(d => d.roleId == 1000350);
		if (loggedIn(currentUser)) {
			logger.log('[TicketResaleWebview] componentDidUpdate load data - state:%o', this.state);
			this.props.getJWT().then(token => {
				let updateStatus = (this.state.queueStatus == undefined && !this.state.queueStatus && this.state.queueStatus !== 0) && token && ticketResaleRole?.length > 0;
				if (updateStatus) {
					let queueStatus = '';
					// get queue status
					let path = this.props.queueAPIs?.resaleQueueStatus;
					handleGetQueueStatus(token.jwt.id_token, path).then((response) => {
						logger.log('[TicketResaleWebview] handleGetQueueStatus() response %o', response);
						// logger.log('[TicketResaleWebview] handleGetQueueStatus() response error %o', response?.data?.response?.error);

						// filter out queue to exclude virtual queueID
						let respResult = response?.data?.response?.result;
						const filteredRes = respResult.filter(item =>
							this.props.queues.some(queue => queue.id === item.queue_id && queue.ticketResale)
						);

						// sort by creation time and get most recent booking
						let res = this.sortResult(filteredRes);
						logger.log('[TicketResaleWebview] handleGetQueueStatus() queueStatus', res);

						// if response is 200 but error inside, means user has not yet joined a queue 
						queueStatus = res?.booking_status > -1 ? res?.booking_status : 'notjoined';

						// filter queues to match queue_id
						this.setState({ queueStatus: queueStatus, queueStatusObj: res });

						// reassign updateStatus because items have changed while endpoints are being called 
						updateStatus = (this.state.queueStatus == undefined && !this.state.queueStatus && this.state.queueStatus !== 0) && token && ticketResaleRole?.length > 0;

					}).catch(error => {
						logger.error('[WebviewResaleStatusView] handleGetQueueStatus() - error:%o', error);
						if (error?.data?.response?.error?.code?.toString() != "-41001") {
							this.props.showModal({
								showModal: true,
								showCloseBtn: true,
								type: 'error',
								title: 'Error',
								message: getErrorText(error?.code || error?.data?.response?.error?.code, "Ticket Resale Queue"),
								useTimer: false,
							});
						} else { // show join screen with empty data if -41001 error
							this.setState({ queueStatus: 'notjoined', queueStatusObj: {} });
						}
					})
				}
			}).catch(error => {
				logger.error('[WebviewResaleStatusView] handleGetQueueStatus() - error:%o', error);
				if (error?.data?.response?.error?.code?.toString() != "-41001") {
					this.props.showModal({
						showModal: true,
						showCloseBtn: true,
						type: 'error',
						title: 'Error',
						message: getErrorText(error?.code || error?.data?.response?.error?.code, "Ticket Resale Queue"),
						useTimer: false,
					});
				} else { // show join screen with empty data if -41001 error
					this.setState({ queueStatus: 'notjoined', queueStatusObj: {} });
				}
			})



			// get queue config
			/* 
				match queue_id to displayed name
				To determine if queue is open or closed, we use queue_status, 0 indicates queue is open, 1 indicates queue is closed
				to determine if queue is hidden, we use the boolean queue_is_hidden
				if the queue is hidden, it should be disabled in the join select menu, e.g. "Centre Court - Full"
				if the queue is closed, it should be disabled in the join select menu, e.g. "Centre Court - Closed"
			*/
			if (!this.state.queueConfig && ticketResaleRole?.length) {
				this.getResaleQueueConfig();
			}
		}
	}

	getResaleQueueConfig(updateModal) {
		// get queue config
		let path = this.props.queueAPIs?.resaleQueueConfig;
		this.props.getJWT().then(token => {
			handleGetResaleQueueConfig(token.jwt.id_token, path).then((response) => {
				logger.log('[TicketResaleWebview] handleGetResaleQueueConfig() response %o', response);
				// logger.log('[TicketResaleWebview] handleGetResaleQueueConfig() response error %o', response?.data?.response?.error);
	
				// returns array of queues, iterate through to get status, etc. 
				let res = response?.data?.response?.result;
				let queueDetails = [];
				res?.map((queueItem) => {
					logger.log('[TicketResaleWebview] handleGetResaleQueueConfig() queueItem', queueItem);
					let queueObj = {};
					let filteredQueue = this.props.queues?.filter(item => item.id == queueItem.queue_id)[0];
					let itemName = filteredQueue?.name;
					let isResale = filteredQueue?.ticketResale;

					// only add those with matching names, some do not have matches 
					if (itemName && isResale){
						queueObj.name = itemName;
						queueObj.queue_id = queueItem.queue_id;
						queueObj.status = queueItem.queue_status; // 1 indicates queue is open, 0 indicates queue is closed (display 'closed' if 1)
						queueObj.hidden = queueItem.queue_is_hidden; // boolean (display 'full' if true)
						queueDetails.push(queueObj);
					}
				})
				logger.log('[TicketResaleWebview] handleGetResaleQueueConfig() queueDetails', queueDetails);

				this.setState({ queueConfig: queueDetails });

				if (updateModal) {
					this.props.showModal({
						showModal: false,
						showCloseBtn: true,
						type: 'success',
						title: 'Success',
						message: 'You have refreshed queue status',
						useTimer: false,
					});
				}
			}).catch(error => {
				logger.error('[WebviewResaleStatusView] handleGetQueueStatus() - error:%o', error);
				this.props.showModal({
					showModal: true,
					showCloseBtn: true,
					type: 'error',
					title: 'Error',
					message: getErrorText(error?.code || error?.data?.response?.error?.code, "Ticket Resale Queue"),
					useTimer: false,
				});
			})
        }).catch(error => {
            logger.error('[WebviewResaleStatusView] handleGetQueueStatus() - error:%o', error);
			this.props.showModal({
				showModal: true,
				showCloseBtn: true,
				type: 'error',
				title: 'Error',
				message: getErrorText(error?.code || error?.data?.response?.error?.code, "Ticket Resale Queue"),
				useTimer: false,
			});
        });
	}

	sortResult(response) {
		let res;
		// sort by creation time and get most recent booking
		if (response?.length > 0) {
			let sortedRes = response?.sort(function (a, b) {
				return moment.utc(b.creation_time).diff(moment.utc(a.creation_time))
			});
			res = sortedRes[0];
		} else {
			res = response;
		}
		
		return res;
	}

	render() {
		let gigyaLoaded = op.get(this.state, 'gigya.gigyaLoaded', {});
		let currentUser = op.get(this.state, 'gigya.currentUser', {});
		let queueStatusObj = op.get(this.state, 'queueStatusObj', {});
		let queueConfig = op.get(this.state, 'queueConfig', null);
		let debug_environment = op.get(this.state, 'authEnv', null) == 'cdt';

		// check for if config loaded 
		let configStatus = op.get(this.state, 'configStatus', {});
        // get user roles - ticket resale role: 1000350
        let ticketResaleRole = currentUser?.roles?.filter(d => d.roleId == 1000350);

		logger.log('[TicketResaleWebview] render - currentUser:%o', currentUser);
		logger.log('[TicketResaleWebview] render - ticketResaleRole:%o', ticketResaleRole);

		let status = this.state.queueStatus;
		let isQueueClosed;
		// let isQueueHidden;

		//logger.log('[TicketResaleWebview] render - this:%o', this);
		//logger.log('[TicketResaleWebview] render - state:%o', this.state);



		if (configStatus === 'loaded' && gigyaLoaded && (!ticketResaleRole || !ticketResaleRole.length)) {
			return (<div className={
				this.debugView || debug_environment ? 'ticketDebugWebViewBackground queue-error-msg' : 'queue-error-msg'}
			><div>{getErrorText('invalid_role', "Ticket Resale Queue")}</div></div>);
		}

		if (configStatus !== 'loaded' || !gigyaLoaded || (gigyaLoaded && (this.state.queueStatus == undefined || this.state.queueStatus < 0))) {
			return (
				<div className={
					this.debugView
						? 'ticketDebugWebViewBackground' : ''
				}>
					<LoadingIndicator type={'white'} />
				</div>);
		} else if (configStatus == 'loaded') {
			// map court name to queueConfig to get status (hidden, closed, etc.)
			let queueOpenStatus = Array.isArray(queueConfig) ? queueConfig?.filter((val) => val?.queue_id == queueStatusObj?.queue_id) : [];
			isQueueClosed = queueOpenStatus[0]?.status == 0;

			if (isQueueClosed) { // metrics call
				measureInAppContext({
					pageTitle:"Ticket Resale Queue",
					action: "Closed",
					context: [{
						section: "Ticket Resale Queue",
					}],
					metricType: "state",
				});
			}
			// isQueueHidden = queueOpenStatus[0]?.hidden;
		}
		
		logger.log('[TicketResaleWebview] render - status:%o', status);

		logger.log('[TicketResaleWebview] render - queueConfig:%o', queueConfig);
		logger.log('[TicketResaleWebview] render - queueStatusObj:%o', queueStatusObj);

		return (
			<div className={
				this.debugView
					? 'ticketDebugWebViewBackground ticket-resale-webview' : 'ticket-resale-webview'
			}>
				{this.debugView || debug_environment ? 
					<div style={{ fontWeight: 'bold', fontSize: '12px', background: 'rgba(230, 230, 230, 0.8)', position: 'absolute', padding: '4px' }}>
						<div>booking id: {queueStatusObj?.booking_id}</div>
						<div>booking status: {queueStatusObj?.booking_status}</div>
						<div>court name: {this.props.queues?.filter((queue) => queue?.id == queueStatusObj?.queue_id)[0]?.name || ''}</div>
					</div>
					: null
				}
				{/* 4 is when user exited queue */}
				{!status == undefined || (status !== 0 && status !== 2 && status !== 1 && status !== 6 && status !== 5) || status == 4 ?
					<TicketResaleCheckin
						queueNames={this.props.queues?.filter((val => val.ticketResale == true))}
						quantity={this.props.resale_queue?.ticket_quantity}
						getJWT={() => this.props.getJWT()}
						showModal={(data) => this.props.showModal(data)}
						queueAPIs={this.props.queueAPIs}
						updateQueueStatus={(response) => {
							let newObj = this.sortResult(response?.data?.response?.result);
							this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj });
						}}
						configText={this.props.configText}
						configData={this.props.configData}
						queueConfig={this.state.queueConfig}
						configUrls={this.props.configUrls}
						getResaleQueueConfig={() => this.getResaleQueueConfig()}
					/>
				: null}
				
				{(status == 0 || status == 2) ? // "booked" status (joined)
					<WebviewResaleStatusView 
						topTxt={this.props.configText?.status_header}
						exitQueueTxt="I no longer want tickets:"
						getJWT={() => this.props.getJWT()}
						showModal={(data) => this.props.showModal(data)}
						queueAPIs={this.props.queueAPIs}
						updateQueueStatus={(response) => {
							let newObj = this.sortResult(response?.data?.response?.result);
							this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj });
						}}
						queueStatus={isQueueClosed ? 'closed' : ''}
						queueObj={this.state.queueStatusObj}
						configQueues={this.props.queues}
						exitQueueResponse={(status, response) => {
							// reset state values, has exited queue
							let newObj = this.sortResult(response?.data?.response?.result);
							if (status == 'success') this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj });
						}}
						getResaleQueueConfig={(updateModal) => this.getResaleQueueConfig(updateModal) }
						configText={this.props.configText}
					/>
				: null}

				{(status == 6 || status == 1) ? // "notified" status
					<WebviewResaleReady 
						headerTxt="IT'S YOUR TURN NOW"
						topTxt={`Please proceed to the Ticket Resale kiosk by:`}
						expireTimeTxt="Your ticket collection window will expire at this time."
						exitQueueTxt="I no longer want tickets:"
						getJWT={() => this.props.getJWT()}
						showModal={(data) => this.props.showModal(data)}
						queueAPIs={this.props.queueAPIs}
						queueObj={this.state.queueStatusObj}
						showRefreshBtn={status == 1}
						onHandleResponse={(status, response) => {
							// reset state values, has exited queue
							let newObj = this.sortResult(response?.data?.response?.result);
							if (status == 'success') this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj });
						}}
						onHandleOnMyWayResponse={(status, response) => {
							// check for empty response if expired
							if (response) {
								let newValues = response?.data?.response?.result;
								/* 
									NOTE: only update values in object that are new. onMyWay response returns a different object
									that only contains booking_id, ___ and ___. if we reset object, we will lose all other values
								*/
								let newObj = this.state.queueStatusObj;
								for (const key in newValues) {
									if (newValues.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
										newObj[key] = newValues[key];
									}
								}
								if (status == 'success') this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj });
							} else {
								this.setState({ queueStatus: 'rejoin', queueStatusObj: {} })
							}
							
						}}
						getResaleQueueConfig={(updateModal) => this.getResaleQueueConfig(updateModal) }
						updateQueueStatus={(response) => {
							let newObj = this.sortResult(response?.data?.response?.result);
							this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj });
						}}
						configQueues={this.props.queues}
						handleExitExpiredQueue={() => {
							this.setState({ queueStatus: 'rejoin', queueStatusObj: {} })
							this.props.showModal({
								showModal: false,
								showCloseBtn: true,
								type: 'success',
								title: 'Success',
								message: 'You have exited the queue',
								useTimer: false,
							});
						}}
					/>
				: null}
				
				{(status == 5) ? // "expired" status
					<ExpiredQueue 
						headerTxt="EXPIRED"
						topTxt={this.props.configText?.expired_text}
						expireTimeTxt="Your ticket collection window will expire at this time."
						exitQueueTxt="I no longer want tickets:"
						// getJWT={() => this.props.getJWT()}
						// showModal={(data) => this.props.showModal(data)}
						queueAPIs={this.props.queueAPIs}
						queueObj={this.state.queueStatusObj}
						// exitQueueResponse={(status, response) => {
						// 	// reset state values, has exited queue
						// 	let newObj = this.sortResult(response?.data?.response?.result);
						// 	if (status == 'success') this.setState({ queueStatus: newObj?.booking_status, queueStatusObj: newObj });
						// }}
						configQueues={this.props.queues}
						handleExitExpiredQueue={() => {
							this.setState({ queueStatus: 'rejoin', queueStatusObj: {} })
						}}
					/>
				: null}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketResaleWebview);

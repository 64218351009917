/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { values } from 'appdir/main';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import isArray from 'lodash/isArray';

import { getComponents, validateData } from 'appdir/components/general/Util';
import React, { Component } from 'react';
import JSXWrapper from 'appdir/components/general/JSXWrapper';
import { parseString } from 'xml2js';
import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';
import endsWith from 'lodash/endsWith';
import MeasurementUtils from 'appdir/lib/analytics';
import { handleColumns } from 'appdir/components/general/Util';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import SiteMap from 'appdir/components/general/SiteMap';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import PrevNextBack from 'appdir/components/common-ui/PrevNextBack';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import SocialShare from 'appdir/components/common-ui/SocialShare';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['ContentPage'],
		// favourites: state['Controller']['favourites'],
		gigyaLoaded: state['Gigya']['gigyaLoaded'],
		loggedIn: state['Gigya']['loggedIn'],
		// enableFavourite: op.get(state, 'Config.myWimbledon.favorites.enableContentFavoriting', false),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContentPage.mount()),
	unmount: () => dispatch(deps.actions.ContentPage.unmount()),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
});

const getComponentList = list => {
	let comps = [];

	forIn(list, function(value, key) {
		if (value.hasOwnProperty('type')) {
			comps.push(value['type']);
		} else {
			//logger.log('[ContentPage] getComponentList - removing: %o:', key);
		}
	});

	//logger.log('[ContentPage] getComponents - list:%o:', list);

	comps = uniq(comps);
	//logger.log('[ContentPage] getComponents - %o:', comps);
	return comps;
};

const getAttributes = list => {
	let attr = {};
	forIn(list, function(value, key) {
		if (value.hasOwnProperty('type')) {
			attr[key] = value;
		}
	});
	return attr;
};

const getComponentListArray = list => {
	let comps = [];

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				comps.push(item['type']);
			} else {
				//logger.log('[ContentPage] getComponentList - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	comps.push('WimLink');
	//logger.log('[ContentPage] getComponents - %o:', comps);
	return comps;
};

const getAttributesArray = list => {
	let attr = {};

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				attr[item['reference']] = item;
			} else {
				//logger.log('[ContentPage]  getAttributesArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		attr[list['reference']] = list;
	}
	//logger.log('[ContentPage]  getAttributesArray - list: %o:', list);

	return attr;
};

class ContentPage extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);

		this.lastDataPath = '';

		logger.log('[ContentPage] constructor');
	}

	componentWillUnmount() {
		logger.log('[ContentPage] componentWillUnmount');
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let data = this.state.data;
		let prevNextData = this.state.prevNextData;

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				data: data,
				prevNextData,
			};
		});

		logger.log('[ContentPage] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	}

	setViewedContent(timestamp, contentId) {
		//logger.log('[ContentPage] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.dataUrl != undefined && endsWith(this.state.dataUrl, '.xml')) {
			//logger.log('[ContentPage] componentDidUpdate - fetch:%o', this.state.dataUrl);

			//if path samem but key diff (new nav to existing location?)
			//  reload the data
			if (
				this.state.location.pathname == prevState.location.pathname &&
				this.state.location.key != prevState.location.key
			) {
				this.lastDataPath = '';
			}

			if (this.state.dataUrl != this.lastDataPath) {
				this.lastDataPath = this.state.dataUrl;
				deps.services.ContentPage.fetchContent(this.state.dataUrl)
					.then(result => {
						logger.log('[ContentPage] componentDidUpdate result:%o', result.substring(0, 500));

						//set the xml parse options
						let options = {
							explicitArray: false,
							normalize: true,
							trim: true,
							mergeAttrs: true,
							valueProcessors: [],
						};

						//parse the xml
						parseString(result, options, function(err, json) {
							result = json.contentItem;
						});

						//clean the jsx block
						result.jsx = result.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');

						//try replacing relative links
						result.jsx = this.replaceLinks(result.jsx);

						//logger.log('[ContentPage] - result:%o', result);

						//update viewed content if has a date
						if (result.date) {
							this.setViewedContent(parseInt(result.date), result.contentId);
						}

						//throw a 404 error if recognize that a not found page
						if (result.contentId == 404) {
							let err = new Error('Not found stub content');
							err.status = 404;
							throw err;
						}

						this.setState({
							data: result,
						});

						MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
							content: result,
						});
					})
					.catch(error => {
						logger.error('[ContentPage] componentDidUpdate error:%o', error.status);
						if (error.status == 404) {
							this.setState({
								data: 'notfound',
							});
						}
					});

				if (this.state.prevNextUrl != '' && this.state.prevNextUrl !== undefined) {
					deps.services.ContentPage.fetchContent(this.state.prevNextUrl)
						.then(result => {
							//logger.log('[ContentPage] componentDidUpdate preNextUrl result:%o', result);

							this.setState({
								prevNextData: result,
							});
						})
						.catch(error => {
							logger.error('[ContentPage] componentDidUpdate error:%o', error);
							if (error.status == 404) {
								this.setState({
									prevNextData: 'notfound',
								});
							}
						});
				}
			}
		} else {
			logger.warn('[ContentPage] componentDidUpdate - improper data url: %o', this.state.dataUrl);
		}

		handleColumns();
	}

	replaceLinks(jsx) {
		logger.log('[ContentPage] replaceLinks - jsx:%s', jsx);
		let result = jsx.match(/<a href="\/en_GB\/.*?a>/g);
		logger.log('[ContentPage] replaceLinks - result:%s', result);
		if (result) {
			result.forEach(link => {
				let m = link.match(/href="(.*)">(.*)<\/a>/);
				jsx = jsx.replace(link, `<WimLink to="${m[1]}">${m[2]}</WimLink>`);
			});
		}
		//logger.log('[ContentPage] replaceLinks - jsx:%o', jsx);
		return jsx;
	}

	getByline(name, url) {
		return (
			<div className={`four-col byline ${this.state.data.margin == 'true' ? 'left' : ''}`}>
				<div className="byline--attribution" tabIndex={0} aria-label={name ? 'By ' + name : ''}>
					{name ? 'By ' + name : ''}
				</div>
				<SocialShare url={url} />
			</div>
		);
	}

	getHeader() {
		logger.log('[ContentPage] getHeader - state:%o', this.state);

		let videoId =
			this.state.data.header && this.state.data.header.videoId && this.state.data.header.video
				? this.state.data.header.videoId
				: null;
		let videoclass = videoId ? 'video' : '';
		let video_attributes = {
			playerId: 'main',
			cmsId: videoId,
			contentId: null,
			videoUrl: null,
			title: null,
			thumb: null,
			autoplay: true,
			fullscreenMode: '',
			style: `video-wrapper one-col modal header`,
			aspect: 'wide',
			url: null,
		};

		// let hasImageHeader = this.state.data.header.image && this.state.data.header.image.length > 25;
		let hasImageHeader = true;

		//content page, (category)
		if (this.state.category != 'articles' && !this.state.landing) {
			return (
				<div className="news--header-wrapper" aria-hidden tabIndex={-1} role="none">
					<div className={`news--header ${videoclass}`} aria-hidden tabIndex={-1} role="none">
						<div className="news--header-title" aria-hidden tabIndex={-1} role="none">
							<h1 tabIndex={0}>{this.state.data.title}</h1>
						</div>
						<div className="news--header-shorttitle" aria-hidden tabIndex={-1} role="none">
							<h1 aria-hidden tabIndex={-1} role="none">
								{this.state.data.shortTitle}
							</h1>
						</div>
						<div className="news--header-abstract" aria-hidden tabIndex={-1} role="none">
							<h4 aria-hidden tabIndex={-1} role="none">
								{this.state.data.abstract}
							</h4>
						</div>
					</div>
					{videoId ? (
						<div className="news--header-videoLink" tabIndex={0}>
							<VideoWrapper attributes={video_attributes} type="header" />
						</div>
					) : null}
				</div>
			);
		} else if (this.state.landing && videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video" />
					<div className="news--header-videoLink" tabIndex={0}>
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.state.landing) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header" />
				</div>
			);
		}

		//article pages
		else if (videoId) {
			return (
				<div className="news--header-wrapper" aria-hidden tabIndex={-1} role="none">
					<div className="news--header video" tabIndex={0}>
						<div className="news--header-icon" aria-hidden tabIndex={-1} role="none">
							<i className="wim-icon-news" aria-hidden tabIndex={-1} role="none" />
						</div>
						<div className="news--header-date" aria-hidden tabIndex={-1} role="none">
							<h3 aria-hidden tabIndex={-1} role="none">
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title" aria-hidden tabIndex={-1} role="none">
							<h1 aria-hidden tabIndex={-1} role="none">
								{this.state.data.title}
							</h1>
						</div>
						<div className="news--header-shorttitle" aria-hidden tabIndex={-1} role="none">
							<h1 aria-hidden tabIndex={-1} role="none">
								{this.state.data.shortTitle}
							</h1>
						</div>
						<div className="news--header-abstract" aria-hidden tabIndex={-1} role="none">
							<h4 aria-hidden tabIndex={-1} role="none">
								{this.state.data.abstract}
							</h4>
							<h3 aria-hidden tabIndex={-1} role="none">
								{' '}
								READ MORE
							</h3>
						</div>
					</div>
					<div className="news--header-videoLink" tabIndex={0}>
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (hasImageHeader) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header" tabIndex={0}>
						<div className="news--header-icon" aria-hidden tabIndex={-1} role="none">
							<i className="wim-icon-news" aria-hidden tabIndex={-1} role="none" />
						</div>
						<div className="news--header-date" aria-hidden tabIndex={-1} role="none">
							<h3 aria-hidden tabIndex={-1} role="none">
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title" aria-hidden tabIndex={-1} role="none">
							<h1 aria-hidden tabIndex={-1} role="none">
								{this.state.data.title}
							</h1>
						</div>
						<div className="news--header-shorttitle" aria-hidden tabIndex={-1} role="none">
							<h1 aria-hidden tabIndex={-1} role="none">
								{this.state.data.shortTitle}
							</h1>
						</div>
						<div className="news--header-abstract" aria-hidden tabIndex={-1} role="none">
							<h4 aria-hidden tabIndex={-1} role="none">
								{this.state.data.abstract}
							</h4>
							<h3 aria-hidden tabIndex={-1} role="none">
								{' '}
								READ MORE
							</h3>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	playFullVideo(id) {
		logger.log('[ContentPage] playFullVideo - id:%o', id);
	}

	render() {
		logger.log('[ContentPage] render - state:%o', this.state);

		if (this.state.data == 'notfound') {
			let header_propsData = {
				headerType: 'generic',
				title: 'Sitemap',
				shortTitle: 'Sitemap',
				metaTitle: 'Page not found',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<SiteMap notFound={true} />
						<img style={{ display: 'none' }} src={`/notfound${document.location.pathname}`} />
					</div>
					<Footer />
				</Template>
			);
		} else if (this.state.data) {
			let attributes = getAttributesArray(this.state.data.dependencies.data);
			let components = getComponents(getComponentListArray(this.state.data.dependencies.data));
			logger.log('[ContentPage] render - attributes:%o components:%o:', attributes, components);
			//logger.log('[ContentPage] render - data:%o:', this.state.data);

			let flex = this.state.data.tiled && this.state.data.tiled == 'true' ? 'flex' : '';

			let players = op.get(this.state, 'data.players.player', []);
			if (isArray(players)) {
				players = players.map(function(player) {
					return player['name'];
				});
			} else {
				players = new Array(players.name);
			}

			let header_propsData = {
				headerType: this.state.category || this.state.landing,
				shortTitle: this.state.data.shortTitle,
				scrollElem: '.content-main',
				metaTitle: this.state.data.title,
				metaDescription: this.state.data.abstract,
				scroll: false,
				skipContent: true,
			};

			//add data for Helmet
			header_propsData = {
				...header_propsData,
				type: 'A',
				metaSeoTitle: this.state.data.seoTitle,
				metaSeoDescription: this.state.data.seoDescription,
				canonicalLink: values.canonicalHost + window.location.pathname,
				ampUrl: this.state.data.url.amp,
				shareImage:
					this.state.data.header.image ||
					window.location.origin + '/assets/images/headers/defaultContentHeader.jpg',
				author: this.state.data.credit,
				measureTitle: 'Detail',
				metaDate: this.state.data.date,
				metaPlayers: players.join(' | '),
			};

			// add width and height when get form content metadata update from CMS
			//let ogImageHeight;
			//let ogImageWidth;

			//initially was checking for seemingly valid url to image or video due to invalid test data
			if (this.state.data.header.image && this.state.data.header.image.length > 25) {
				header_propsData['imageSrc'] = this.state.data.header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			} else {
				//grass.wimbledon.com/images/pics/misc/header_aerialview.jpg
				header_propsData['imageSrc'] = '/assets/images/headers/defaultContentHeader.jpg';
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (this.state.data.header.video && this.state.data.header.video.length > 25) {
				header_propsData['videoSrc'] = this.state.data.header.video;
			}

			let prevNextBackData = {
				prevNextData: this.state.prevNextData,
				category: this.state.category,
				history: this.props.history,
			};

			logger.log('[ContentPage] render - propsData:%o', header_propsData);

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className={`content-main column-layout news article ${flex}`} id="main">
						<noindex tabIndex={-1} role="none">
							{this.getHeader()}
						</noindex>
						{this.getByline(this.state.data.credit, this.state.data.url.share)}

						<JSXWrapper
							id={this.state.data.contentId}
							bindings={attributes}
							components={components}
							jsx={this.state.data.jsx}
						/>
					</div>
					{this.state.category && this.state.prevNextData ? (
						<PrevNextBack attributes={prevNextBackData} />
					) : null}

					<Footer />
				</Template>
			);
		} else {
			logger.warn('[ContentPage] render - data not loaded, state:%o', this.state);

			let header_propsData = {
				headerType: 'generic',
				titleElem: '.news--header',
				scroll: false,
			};

			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: ({ title, src, height, social }) => dispatch => {
		logger.log('[FramePages] action.mount - title: %o', title);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: title,
		});

		dispatch({
			type: deps.actionTypes.FRAMEPAGES_MOUNT,
			title,
			src,
			height,
			social,
		});
	},
};

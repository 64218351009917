// import deps from "dependencies";
//
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import deps from 'dependencies';
let activeDataTimer = null;

const updateActive = (dispatch, getState) => {
	let config = getState().Config;
	let refreshRate = config.otherData.activeData.rateSec > 5 ? config.otherData.activeData.rateSec * 1000 : 5000;
	//logger.log('[ActiveData] updateActive - rate:%o', refreshRate);

	deps.services.ActiveData.fetch(`${config.otherData.activeData.path}`).then(data => {
		dispatch({
			type: deps.actionTypes.ACTIVEDATA_LOADED,
			updated: moment().toISOString(),
			status: 'loaded',
			data: data,
		});

		if (activeDataTimer) {
			clearTimeout(activeDataTimer);
		}

		activeDataTimer = setTimeout(updateActive.bind(null, dispatch, getState), refreshRate);
	});
};

export default {
	mount: () => (dispatch, getState, store) => {
		logger.log('[ActiveData] mount - ');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(() => {
			updateActive(dispatch, getState);
		});
	},
};

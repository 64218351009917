import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let path = Config.scoringData.completedMatch;
			dispatch({ type: deps.actionTypes.MATCHRESULT_MOUNT, data: path });
		});
	},
};

import { logger } from '../logger';
import CaptionConstants from './CaptionConstants';
import PenState from './PenState';
import StyledCharacter from './StyledCharacter';

export default function Cea608Row({row}) {
	
	var id = row;

	var NR_COLS = CaptionConstants.NR_COLS;
	var specialCea608CharsCodes = CaptionConstants.specialCea608CharsCodes;

	var chars = [];

	var currPenState = PenState({foreground:null, underline:null, italics:null, background:null, flash:null});
	
	for (var i = 0 ; i < NR_COLS ; i++) {
		chars.push(StyledCharacter({uchar:null, foreground:null, underline:null, italics:null, background:null, flash:null}));
	}
	var pos = 0;

	return Object.freeze({
		getCharForByte,
		equals,
		copy, 
		isEmpty,
		setCursor,
		moveCursor,
		backSpace,
		insertChar,
		clearFromPos,
		clear,
		clearToEndOfRow,
		getTextString,
		setPenStyles,
		getChars
	})

	function getCharForByte(byte) {
		var charCode = byte;
		if (specialCea608CharsCodes.hasOwnProperty(byte)) {
			charCode = specialCea608CharsCodes[byte];
		}
		return String.fromCharCode(charCode);
	};
	
	function equals(other) {
		var equal = true;
		for (var i = 0 ; i < NR_COLS; i ++) {
			if (!chars[i].equals(other.getChars(i))) {
				equal = false;
				break;
			}
		}
		return equal;
	};
	
	function copy(other) {
		//logger.log('Row - copy chars:%o other:%o', chars, other);
		for (var i = 0 ; i < NR_COLS; i ++) {
			chars[i].copy(other.getChars(i));
		}
	};
	
	function isEmpty() {
		var empty = true;
		for (var i = 0 ; i < NR_COLS; i ++) {
			if (!chars[i].isEmpty()) {
				empty = false;
				break;
			}
		}
		return empty;
	};

	/**
	 *  Set the cursor to a valid column.
	 */
	function setCursor(absPos) {
		//logger.info('setCursor - pos:%o', absPos);
		if (pos !== absPos) {
			pos = absPos;
		}
		if (pos < 0) {
			logger.info("ERROR", "Negative cursor position " + pos);
			pos = 0;
		} else if (pos > NR_COLS) {
			logger.info("ERROR", "Too large cursor position " + pos);
			pos = NR_COLS;
		}
	};

	/** 
	 * Move the cursor relative to current position.
	 */
	function moveCursor(relPos) {
		var newPos = pos + relPos;
		if (relPos > 1) {
			for (var i = pos+1; i < newPos+1 ; i++) {
				chars[i].setPenState(currPenState);
			}
		}
		setCursor(newPos);
	};

	/**
	 * Backspace, move one step back and clear character.
	 */
	function backSpace() {
		moveCursor(-1);
		chars[pos].setChar(' ', currPenState);
	};

	function insertChar(byte) {
		if (byte >= 0x90) { //Extended char
			backSpace();
		}
		var char = getCharForByte(byte);
		//logger.info('insertChar1 - byte:%o char:%o', byte, char);

		if (pos >= NR_COLS) {
			//logger.error('insertChar - error inserting ' + byte.toString(16) + " (" + char + ") at position " + pos + ". Skipping it!");
			return;
		}
		chars[pos].setChar(char, currPenState);
		//logger.info('insertChar - pos:%o byte:%o', pos, chars[pos].uchar);
		moveCursor(1);
	};

	function clearFromPos(startPos) {
		var i;
		for (i = startPos ; i < NR_COLS ; i++) {
			chars[i].reset();
		}
	};

	function clear() {
		clearFromPos(0);
		pos = 0;
		currPenState.reset();
	};

	function clearToEndOfRow () {
		clearFromPos(pos);
	};

	function getTextString() {
		var _chars = [];
		var empty = true;
		for (var i = 0 ; i < NR_COLS ; i++) {
			
			var char = chars[i].getChar();
			if (char !== " ") {
				empty = false;
			}
			_chars.push(char);
		}
		if (empty) {
			return "";
		} else {
			return _chars.join("");
		}
	};

	function setPenStyles(styles) {
		currPenState.setStyles(styles);
		var currChar = chars[pos];
		currChar.setPenState(currPenState);
	};

	function getChars(pos) {
		return chars[pos];
	};

	function getId() {
		return id;
	}
}
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import MatchBox from 'appdir/components/scoring/MatchBox';
import EventSelect from './EventSelect';
import DaySelect from './DaySelect';
import StubBox from 'appdir/components/common-ui/StubBox';
import SearchBox from 'appdir/components/common-ui/SearchBox';
import ScoresTabs from 'appdir/components/pages/LiveScores/ScoresTabs';

import Favorites from 'appdir/components/common-ui/Favorites';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: ResultsPage
 * this is the completed matches page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ResultsPage'],
		currentDay: state['ActiveData']['currentDay'],
		liveIndicators: state['ActiveData']['liveIndicators'],
		stubs: state['Config'].stubPages,
		tourn_events: op.get(state, 'Config.scoringConfig.eventNames', null),
		resultsData: state['Tournament']['data']['results'],
		resultsDaysData: state['Tournament']['data']['resultDays'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	setDefaultDay: settings => dispatch(deps.actions.ResultsPage.setDay(settings)),
	getResults: day => dispatch(deps.actions.Tournament.getResults({ day: day })),
	clearResults: data => dispatch(deps.actions.Tournament.clearResults(data)),
});

class ResultsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.onEnter = this.onEnter.bind(this);

		this.onDaySelect = this.onDaySelect.bind(this);
		this.onEventSelect = this.onEventSelect.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.info('[ResultsPage] getDerivedStateFromProps - prevState:%o', prevState);
		logger.info('[ResultsPage] getDerivedStateFromProps - nextProps:%o', nextProps);

		let updates = {};
		if (nextProps?.resultsData?.status == 'loaded') {
			updates = {
				error: null,
			};
		} else if (nextProps?.resultsData?.status == 'error') {
			updates = {
				error: true,
			};
		}

		let newState = {
			...prevState,
			...nextProps,
			...updates,
		};

		return newState;
	}

	componentDidMount() {
		//logger.log('[ResultsPage] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.props.clearResults();
		this.props.setDefaultDay({ day: null });
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[ResultsPage] componentDidUpdate - state:%o props:%o', this.state, this.props);

		// calculate new data path from path in state and current fitlers
		if (!this.props.match.params.tournDay && this.props.currentDay?.completed && !this.props.filters.day) {
			this.props.setDefaultDay({ day: this.props.currentDay.completed });
		} else if (op.get(this.state, 'filters.day', '') != '') {
			if (this.state.filters.day != prevProps.filters.day) {
				this.props.getResults(this.state.filters.day);
			}
		}
	}

	getMode(day) {
		let mode = '';
		if (this.state.resultsDaysData?.status == 'loaded') {
			this.state.resultsDaysData.data.forEach(item => {
				if (item.tournDay == day) {
					console.info('got a mode');
					mode = item.quals ? 'quali' : 'tourn';
				}
			});
		}
		return mode;
	}

	redirectDay(eventCode, curDay) {
		let nextDay = false;
		let currentDay = false;

		if (eventCode != '' && eventCode != 'all') {
			currentDay = this.state.resultsDaysData.data.find(
				x => x.tournDay == curDay && x.events.includes(eventCode)
			);
			nextDay = this.state.resultsDaysData.data.find(x => x.events.includes(eventCode));
		}
		//logger.log('[ResultsPage] redirectDay eventCode:%o,  curDay:%o, nextDay:%o, currentDay:%o',eventCode, curDay, nextDay, currentDay);
		return currentDay ? false : nextDay;
	}

	getPlayedEvents() {
		let playedEvents = new Array();
		if (this.state.resultsDaysData?.status == 'loaded') {
			this.state?.tourn_events?.map((event, i) => {
				let played = this.state.resultsDaysData.data.find(x => x.events.includes(event.code));
				if (played) {
					playedEvents.push(event);
				}
			});
		}
		return playedEvents;
		//logger.log('[ResultsPage] getPlayedEvents playedEvents:%o', playedEvents);
	}

	onEnter(data) {
		this.setState({ searchedPlayer: data.tour_id });
	}

	onEventSelect(event) {
		//logger.log('[ResultsPage] onEventSelect - event:%o', event);
		let search = event == 'all' ? '' : `?event=${event}`;
		this.props.history.push(`day${this.state.filters.day}.html${search}`);
	}

	onDaySelect(day) {
		//logger.log('[ResultsPage] onDaySelect - day:%o', day);
		//logger.log('[ResultsPage] onDaySelect - state:%o', this.state);
		let search = this.state.location.search || '';
		this.props.history.push(`day${day}.html${search}`);
	}

	renderEventsSelect() {
		if (this.state.resultsDaysData?.status == 'loaded') {
			return (
				<EventSelect
					onEventSelect={this.onEventSelect}
					events={this.getPlayedEvents()}
					selected={this.state.filters.event}
				/>
			);
		} else {
			return null;
		}
	}

	renderDaysSelect() {
		if (this.state.resultsDaysData?.status == 'loaded') {
			return (
				<DaySelect
					onDaySelect={this.onDaySelect}
					days={this.state.resultsDaysData.data}
					selected={this.state.filters.day}
					mode={this.getMode(this.state.filters.day)}
				/>
			);
		} else {
			return null;
		}
	}

	renderMatches() {
		//logger.log("[ResultsPage] renderMatches - filters:%o",this.props.filters);
		//logger.log('[ResultsPage] renderMatches - state:%o', this.state);

		let filtered_matches = this.state.resultsData.matches.filter(match => {
			if (!this.props.filters || this.props.filters.event == 'all' || this.props.filters.event == '') {
				return match;
			} else {
				return match.eventCode === this.props.filters.event;
			}
		});

		logger.log('[ResultsPage] renderMatches - filtered_matches:%o', filtered_matches);

		if (filtered_matches.length > 0) {
			return filtered_matches.map((match, i) => {
				//logger.log("[ResultsPage] renderMatches filter map match:%o",match);
				return (
					<div key={i} className="one-col">
						<MatchBox
							attributes={{
								data: match,
								event: match.shortEventName,
								tableHeader: match.courtName,
								showLinks: true,
								searchedPlayer: this.state.searchedPlayer,
							}}
						/>
					</div>
				);
			});
		} else {
			let current_event = this.props.tourn_events.filter(tourn_event => {
				return tourn_event.code == this.props.filters.event;
			})[0];
			let event = current_event?.long || '';

			return <GenericError message={`No ${event} matches on this day`} />;
		}
	}

	getContent() {
		if (this.state.resultsData?.status == 'loaded') {
			return <div className="column-layout scoresgrid">{this.renderMatches()}</div>;
		} else if (this.state.resultsData?.status == 'error') {
			return <GenericError message="No results found" />;
		} else {
			return null;
		}
	}

	getHeaderNav(stub = false) {
		if (!stub) {
			return (
				<div className="column-layout matchexp no-padding">
					<div className="header">
						<ScoresTabs />
					</div>
					<div className="header">
						<div className="navItem navWrapper">{this.renderEventsSelect()}</div>
						<div className="navItem navWrapper">{this.renderDaysSelect()}</div>
						<div className="navItemBreak"></div>
						<div className="navItem">
							<SearchBox
								// staticData={this.state.playersPlaying}
								onEnter={this.onEnter}
							/>
						</div>
						<div className="navItemFixed">
							<Favorites />
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="column-layout matchexp no-padding">
					<div className="header">
						<ScoresTabs />
					</div>
					<div className="header">
						<div className="navItem navWrapper"></div>
						<div className="navItem navWrapper"></div>
						<div className="navItemBreak"></div>
						<div className="navItem"></div>
						<div className="navItemFixed"></div>
					</div>
				</div>
			);
		}
	}

	render() {
		logger.log('[ResultsPage] render - state:%o', this.state);
		let header_propsData = {};
		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = getQuerystringValues(search);
		let eventName = op.get(parsedQs, 'event', 'all');

		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		header_propsData = {
			headerType: 'scores',
			title: 'Results',
			shortTitle: 'Results',
			metaTitle: 'Results',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		// if theres no tournDay, then redirect to current day that is in the activeData.

		// Stub State
		if (this.state.stubs && this.state.stubs.results.stub === 'stub') {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						{this.getHeaderNav(true)}
						<StubBox attributes={{ message: this.props.stubs.results.text, style: 'centerV' }} />
					</div>
					<Footer />
				</Template>
			);
		}

		// Results Data State
		else if (
			(this.state.resultsData?.status == 'error' || this.state.resultsData?.status == 'loaded') &&
			this.props.tourn_events
		) {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						{this.getHeaderNav()}
						{this.getContent()}
					</div>

					<Footer />
				</Template>
			);
		}

		// Loading State
		else {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<ErrorBoundary message="Results are unavailable at this time.">
							{this.getHeaderNav(true)}
							<LoadingIndicator type="white" />
						</ErrorBoundary>
					</div>

					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: SchedulePage
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ScoreboardDemo'],
		ScoreManager: state['ScoreManager'],
		currentDay: state['ActiveData']['currentDay'],
		controllerLoaded: state['Controller'].loaded,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ScoreboardDemo.mount()),
	setScoreManagerStatus: data => dispatch(deps.actions.ScoreManager.setScoreManagerStatus(data)),
});

class ScoreboardDemo extends Component {
	constructor(props) {
		super(props);

		// now add all the props and filters to state
		this.state = {
			scoreboardSyndicator: 'None',
			videoSyndicator: 'None',
			...props,
		};
		this.submitSyndicator = this.submitSyndicator.bind(this);
		this.updateSyndicator = this.updateSyndicator.bind(this);

		this.hasError = false;
		this.init = true;
	}

	submitSyndicator(syndicator) {
		if (syndicator) {
			this.setState({
				syndicator,
			});
		}
	}

	updateSyndicator(evt) {
		if (evt.target.value) {
			this.setState({
				syndicatorValue: evt.target.value,
			});
		}
	}

	componentDidMount() {
		//logger.log('[ScoreboardDemo] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		this.state.setScoreManagerStatus({ mip: false });
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[ScoreboardDemo] componentDidUpdate state:%o', this.state);

		if (
			this.state.controllerLoaded &&
			op.get(this.props, 'ScoreManager.status', false) &&
			!this.props.ScoreManager.status.mip
		) {
			this.state.setScoreManagerStatus({ mip: true });
		}

		if (this.init && this.state.currentDay && this.state.status == 'mount') {
			this.init = false;

			let completed_path = this.state.completed_path.replace('<day>', this.state.currentDay.completed);

			deps.services.ScoreboardDemo.fetch(completed_path)
				.then(result => {
					this.setState({
						completed_matches: result,
					});

					if (this.results_timeout) {
						clearTimeout(this.results_timeout);
					}

					this.results_timeout = setTimeout(() => {
						this.results_init = true;
					}, 30000);
				})
				.catch(error => {
					//logger.log('[ScoreboardDemo] componentDidUpdate error:%o',error);
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[ScoreboardDemo] componentWillReceiveProps - prev:%o', this.state);
		//logger.log('[ScoreboardDemo] componentWillReceiveProps - next:%o', nextProps);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		logger.log('[ScoreboardDemo] render - this:%o', this);
		let header_propsData = {};
		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		header_propsData = {
			headerType: 'generic',
			title: 'Scoreboard Test',
			shortTitle: 'Scoreboard Test',
			metaTitle: 'Scoreboard Test',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		let synIframeStyle = {};
		synIframeStyle['width'] = '100%';
		synIframeStyle['height'] = '210px';
		synIframeStyle['border'] = '0';
		synIframeStyle['margin'] = '0';
		synIframeStyle['padding'] = '0';
		synIframeStyle['overflow'] = 'hidden';
		synIframeStyle['scroll'] = 'none';
		synIframeStyle['zIndex'] = '1';

		let synTabIframeStyle = {};
		synTabIframeStyle['width'] = '100%';
		synTabIframeStyle['height'] = '245px';
		synTabIframeStyle['border'] = '0';
		synTabIframeStyle['margin'] = '0';
		synTabIframeStyle['padding'] = '0';
		synTabIframeStyle['overflow'] = 'hidden';
		synTabIframeStyle['scroll'] = 'none';
		synTabIframeStyle['zIndex'] = '1';

		// if theres no tournDay, then redirect to current day that is in the activeData.
		if (op.get(this.state, 'ScoreManager.liveMatches', []).length > 0 || this.state.completed_matches) {
			let { liveMatches } = this.state.ScoreManager;
			let { completed_matches } = this.state;
			return (
				<Template className="scorespage scoreboard-demo">
					<div className="schedule-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main column-layout">
						{/* <div
							style={{
								fontWeight: 'bold',
								fontSize: '1.2em',
								color: '#FFFFFF',
							}}>
							<span
								style={{
									textDecoration: 'underline',
								}}>
								Launch Syndicated Scoreboard:
							</span>
							<span
								style={{
									marginLeft: '10px',
									textDecoration: 'none',
								}}>
								{this.state.scoreboardSyndicator}
							</span>
						</div> */}
						<br />
						{/* {liveMatches && liveMatches.length > 0 ? (
							<div>
								<input
									style={{ height: '25px', width: '200px', fontSize: '20px' }}
									type="text"
									id="syndicated-value"
									placeholder="Syndicator value"
									value={this.state.scoreboardSyndicatorValue}
									onChange={this.updateSyndicator}
								/>
								<button
									style={{ height: '26px', fontSize: '20px', marginLeft: '10px' }}
									onClick={() => {
										this.submitSyndicator(this.state.scoreboardSyndicatorValue);
									}}>
									Change Syndicator
								</button>
							</div>
						) : null}
						<br /> */}

						<br />
						{this.state.scoreboardSyndicator ? (
							<iframe
								src={`${this.state.jsonServer}/en_GB/syndicated/scoreboard.html`}
								style={synIframeStyle}
								scrolling="NO"
								frameBorder="NO"
								title="scoreboard"
							/>
						) : null}

						<br />
						<br />

						<div
							style={{
								fontWeight: 'bold',
								fontSize: '1.2em',
								color: '#FFFFFF',
							}}>
							<span
								style={{
									textDecoration: 'underline',
								}}>
								Launch Syndicated Scoreboard with Video:
							</span>
							<span
								style={{
									marginLeft: '10px',
									textDecoration: 'none',
								}}>
								{this.state.videoSyndicator}
							</span>
						</div>
						<br />
						{liveMatches && liveMatches.length > 0 ? (
							<div>
								<input
									style={{ height: '25px', width: '200px', fontSize: '20px' }}
									type="text"
									id="syndicated-value"
									placeholder="Syndicator value"
									value={this.state.videoSyndicatorValue}
									onChange={this.updateSyndicator}
								/>
								<button
									style={{ height: '26px', fontSize: '20px', marginLeft: '10px' }}
									onClick={() => {
										this.submitSyndicator(this.state.videoSyndicatorValue);
									}}>
									Change Syndicator
								</button>
							</div>
						) : null}
						<br />

						<br />
						{this.state.videoSyndicator ? (
							<iframe
								src={`${this.state.jsonServer}/en_GB/syndicated/${this.state.videoSyndicator}/index.html`}
								style={synTabIframeStyle}
								scrolling="NO"
								frameBorder="NO"
								title="video syndicator"
							/>
						) : null}
					</div>

					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="scorespage">
					<div className="schedule-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<LoadingIndicator type="white" />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreboardDemo);

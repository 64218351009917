import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SCROLLHANDLER_MOUNT:
			newState = {
				...state,
			};
			//logger.log('[PageHeader] PAGEHEADER_MOUNT - state:%o', newState);
			return newState;
			break;

		case deps.actionTypes.SCROLLHANDLER_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[PageHeader] PAGEHEADER_MOUNT - state:%o', newState);
			return newState;
			break;
		case deps.actionTypes.SCROLLHANDLER_ANIMATEDFLAG:
			//logger.log('[ScrollHandler] SCROLLHANDLER_ANIMATEDFLAG - action:%o', action);
			newState = {
				...state,
				animatedScroll: action.data,
			};
			return newState;
		default:
			return state;
	}
};

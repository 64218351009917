import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';
import op from 'object-path';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[MyWimbledon] action.mount - params:%o store:%o', params, store.getState('Gigya'));
			let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
			let currentSP = op.get(parsedQs, 'currentSP', null);

			let data = {
				myWim: Config.myWimbledon,
				action: params.action,
				sp: currentSP,
			};

			dispatch({
				type: deps.actionTypes.MYWIMBLEDON_SAMLLOGIN_MOUNT,
				data: data,
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MYWIMBLEDON_SAMLLOGIN_UNMOUNT });
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Time from 'appdir/components/common-ui/Time';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Video
 * -----------------------------------------------------------------------------
 */
class Video extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props, displayThumbnail: false };
	}

	componentDidMount() {}

	togglePlay() {
		//toggle the video player to play/pause
	}

	render() {
		return (
			<div className={`video-comp ${this.state['col-style']}`}>
				<div
					onClick={() => {
						this.togglePlay();
					}}
					className="video-wrapper">
					<img
						src={this.state['vid-thumbnail']}
						style={{ display: this.state.displayThumbnail ? 'block' : 'none' }}
					/>
					<i className="wim-icon-video" style={{ display: this.state.displayThumbnail ? 'block' : 'none' }} />
				</div>
				<div className="video-info">
					<div className="video-title">{this.state['video-title']}</div>
					<div className="video-date">
						{<Time epoch_ms={this.state['video-date']} format="ddd DD MMM YYYY kk:mm z" options="upper" />}
					</div>
				</div>
			</div>
		);
	}
}

export default Video;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import parse from 'html-react-parser';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: StubMessage
 * -----------------------------------------------------------------------------
 */
const StubBox = props => {
	let attributes = props.attributes;
	let classes = '';
	if (attributes.dark) {
		classes += 'dark ';
	}
	if (attributes.style) {
		classes += attributes.style;
	}

	return attributes.message ? (
		<div className={`stub_box ${classes}`}>
			{Array.isArray(attributes.message) ? (
				attributes.message.map((text, i) => {
					return (
						<div key={i} className="text">
							{text}
						</div>
					);
				})
			) : (
				<div className="text">{attributes.message}</div>
			)}
		</div>
	) : null;
};

export default StubBox;

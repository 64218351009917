import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetchImages = path => {
	let hdr = restHeaders();
	return axios.get(path).then(({ data }) => data);
};

export default {
	fetchImages,
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import op from 'object-path';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import NewsTile from 'appdir/components/content/NewsTile';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import MainVideoChannel from 'appdir/components/general/MainVideoChannel';

class VideoTournament extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		//logger.log('[VideoTournament] - attr:%o', attributes);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {}

	getVideoSelection() {
		//if have a live main stream
		if (this.props.showLive && this.props?.video?.live) {
			if (!this.props.expanded) {
				return null;
			}

			logger.log('[Sidepanel - VideoTournament] getVideoSelection - props:%o', this.props);

			return (
				<span>
					<div className="title">
						{this.props?.video.title}
						<hr />
					</div>
					<MainVideoChannel position="sidepanel" name="sidepanel" channel={this.props?.video} />
				</span>
			);
		}

		// else if not live, show vod
		else {
			let attributes_video = {
				playerId: 'sidepanel',
				cmsId: this.props?.vod?.cmsId,
				contentId: null,
				videoUrl: null,
				title: this.props?.vod?.title,
				thumb: this.props?.vod?.thumbnail,
				autoplay: false,
				style: 'video-wrapper sidepanel',
				aspect: 'wide',
				shareUrl: '/en_GB/video/index.html',
				streamType: 'vod',
				videoSrc: '',
			};

			return (
				<span className="sidepanel-vod">
					<div className="title">
						{attributes_video.title}
						<hr />
					</div>
					<div className="sidepanel-content-video-pre">
						<ErrorBoundary message="Error in VideoPlayer">
							<VideoWrapper attributes={attributes_video} />
						</ErrorBoundary>
					</div>
				</span>
			);
		}
	}

	render() {
		logger.log('[Sidepanel - VideoTournament] render - props:%o', this.props);

		return <>{this.getVideoSelection()}</>;
	}
}

export default VideoTournament;

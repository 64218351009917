/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import { FormInput, FormEmail, FormSelect, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import { countryLookup } from 'appdir/components/general/Util';
import * as yup from 'yup';
import axios from 'axios';
import Button from 'appdir/components/common-ui/Button';
import op from 'object-path';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { BALLOT_STATUS } from 'appdir/components/general/Util';
import { postCodeAvailable } from 'appdir/components/general/Util/Role';
import WimLink from 'appdir/components/general/WimLink';
import moment from 'moment';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: FormProfile
 * -----------------------------------------------------------------------------
 */

const countries = countryLookup;
const PROFILESCHEMA = yup.object({
	title: yup.string().required('Please supply a Title.'),
	firstName: yup.string().required('Please supply a First Name.'),
	lastName: yup.string().required('Please supply a Last Name.'),
	emailProfile: yup
		.string()
		.email('Please supply a valid Email Address')
		.required('Email Address is required'),
	birthMonth: yup.string().test('is-valid-month', 'Please supply valid month', function(value) {
		return Number.isInteger(parseInt(value)) && parseInt(value) >= 1 && parseInt(value) <= 12;
	}),
	birthDay: yup.string().test('is-valid-day', 'Please supply valid day', function(value) {
		return Number.isInteger(parseInt(value)) && parseInt(value) >= 1 && parseInt(value) <= 31;
	}),
	birthYear: yup.string().test('is-valid-year', 'Please supply valid year', function(value) {
		return Number.isInteger(parseInt(value));
	}),
	zip: yup.string().required('Please supply a Zip code'),
	country: yup.string().required('Please select a Country'),
	agreement: yup.bool().required('Please read and accept the AELTC Neighbours and Community policy.'),
	// .oneOf([true], 'Please read and accept the AELTC Neighbours and Community policy.'),
});

const LOCK_PERIODS = ['on'];

const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['MyWimbledon'],
		postCodes: state['Config'].postCodes,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
	logout: data => dispatch(deps.actions.Gigya.logout(data)),
	getAccountInfoProm: params => dispatch(deps.actions.Gigya.getAccountInfoProm(params)),
	setAccountInfo: (source, data) => dispatch(deps.actions.Gigya.setAccountInfo(source, data, true)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class FormProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			fullProfile: props.profile.address ? true : false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.loqateAddress = this.loqateAddress.bind(this);
		this.addressVal = this.addressVal.bind(this);
		this.clickOffAddress = this.clickOffAddress.bind(this);
		this.fullAddressData;
		this.addressHolder;
		this.loqateClicked = false;
		this.addressWrapperRef = React.createRef();
		logger.log('[FormProfile] constructor - state:%o context:%o', this.state, this.context);

		this.months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		if (!this.state.fullProfile) {
			let postCodeCheck = postCodeAvailable(props.postCodes, props.profile.zip);
			logger.log('[FormProfile] constructor - postCodeCheck:%o', postCodeCheck);
			this.props.getAccountInfoProm({ src: 'myWload' }).then(response => {
				//logger.log('[FormProfile] constructor - profile:%o', response);
				this.setState({
					fullProfile: true,
				});
			});
		}
	}

	addressVal(e, formProps) {
		this.address = e.target.value;
		let addressDataFiltered = this.fullAddressData.filter(item =>
			item.Text.concat(' ')
				.concat(item.Description)
				.toLowerCase()
				.includes(e.target.value.toLowerCase())
		);

		this.setState({ addressData: addressDataFiltered });
		formProps.setFieldValue('address', this.address);
		if (this.address == '' || this.address == ' ') {
			this.addressHolder = false;
			this.setState({ addressDD: false });
		} else {
			this.setState({ addressDD: true });
		}

		// this.setState({ selectedAddress: e.target.value });
	}

	addressClick(e, address, id, formProps) {
		logger.log('[FormProfile] addressClick - address:%o', address);
		this.setState({ selectedAddress: address, selected: id, addressDD: false });
		formProps.setFieldValue('address', address);
		this.addressBlur(formProps);
	}

	changeZip(e, formProps) {
		formProps.setFieldValue('address', '');
		formProps.setFieldValue('zip', e.target.value);
	}

	addressBlur(e, formProps) {
		logger.log('[UpdateForm] addressBlur this.addressHolder:%o', this.addressHolder);
		if (this.addressHolder) {
			formProps?.setFieldValue('address', this.addressHolder);
		}
		// this.setState({ addressDD: false });
	}

	validateAddress = value => {
		if (!value || !this.state.addressData) {
			return null;
		}
		let addressData = this.state?.addressData;
		let error = 'Please select an address from the list.';
		addressData?.map(o => {
			if (
				o.Text.concat(' ')
					.concat(o.Description)
					.toLowerCase() === value.toLowerCase()
			) {
				error = null;
			}
		});

		return error;
	};

	clickOffAddress(event) {
		// logger.log('[UpdateForm] clickOffAddress event:%o, this.addressWrapperRef.current:%o, target:%o', event, this.addressWrapperRef.current, this.addressWrapperRef.current.contains(event.target));
		if (
			this.addressWrapperRef &&
			this.addressWrapperRef.current &&
			!this.addressWrapperRef.current.contains(event.target)
		) {
			this.setState({ addressDD: false });
		}
	}

	loqateAddress(e, formProps) {
		this.addressHolder = e.target.value;
		logger.log('[UpdateForm] loqateAddress this.addressHolder:%o', this.addressHolder);
		this.setState({ addressDD: true });

		const Key = 'WC18-MC28-BT96-NF19';
		// logger.log('[UpdateForm] loqateAddress:%o', text);
		let params = '';
		params += '?Key=' + encodeURIComponent(Key);
		params += '&Text=' + encodeURIComponent(formProps.values.zip);
		params += '&IsMiddleware=' + encodeURIComponent('True');
		// params += "&Container=" + encodeURIComponent("GB|RM|ENG|3DA-WR5");
		// params += "&Origin=" + encodeURIComponent("52.182,-2.222");
		params += '&Countries=' + encodeURIComponent('GB,US,CA');
		params += '&Limit=' + encodeURIComponent('100');
		// params += "&Language=" + encodeURIComponent(Language);
		axios
			.get('https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws' + params)
			.then(result => {
				let resultData = result.data.Items;
				logger.log('[UpdateForm] loqateAddress - resultData:%o', resultData);
				let addressData = filter(resultData, { Type: 'Address' });
				if (!isEmpty(addressData)) {
					this.setState({
						addressData: resultData,
					});
					this.fullAddressData = resultData;
				} else {
					let id = resultData[0].Id;
					params += '&Container=' + encodeURIComponent(id);
					axios
						.get('https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws' + params)
						.then(result => {
							let resultData = result.data.Items;
							logger.log('[UpdateForm] loqateAddress - 2nd resultData:%o', resultData);
							let addressData = filter(resultData, { Type: 'Address' });
							this.setState({
								addressData,
							});
							this.fullAddressData = addressData;
						})
						.catch(error => {
							logger.error('[UpdateForm] loqateAddress - 2nd error:%o', error);
							if (error.status == 404) {
								this.setState({
									addressData: 'notfound',
								});
							}
						});
				}

				// logger.log('[UpdateForm] loqateAddress - addressData:%o', addressData);
			})
			.catch(error => {
				logger.error('[UpdateForm] loqateAddress - error:%o', error);
				if (error.status == 404) {
					this.setState({
						addressData: 'notfound',
					});
				}
			});
	}

	renderDateField({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) {
		return (
			<label className="dt-field">
				<legend htmlFor={id}>
					<h5 className="label purple required">{label}</h5>
				</legend>
				<input id={id} name={name} type="text" maxLength="100" {...props} onChange={onChange} onBlur={onBlur} />
			</label>
		);
	}

	renderDOB(formProps) {
		const verifyAtleastThirteen = value => {
			const { birthDay, birthMonth, birthYear } = formProps.values;
			const birthDayEntered = moment(`${birthYear}-${birthMonth}-${birthDay}`, 'YYYY-MM-DD');
			const today = moment(new Date());
			const difference = birthDayEntered.diff(today, 'years', true);
			logger.log('[FormProfile] birthDayEntered:%o, today:%o, difference:%o', birthDayEntered, today, difference);
			return Math.abs(difference) < 13
				? { type: 'displayOnce', message: 'User must be at least 13 years of age' }
				: null;
			// return 'error!';
		};
		let { ticketLock, ballotLock } = this.state;
		let cutoff = moment()
			.subtract(13, 'years')
			.add(1, 'day');
		let days = [];
		for (let x = 1; x <= 31; x++) {
			days.push(
				<option value={x} key={`date-${x}`}>
					{x}
				</option>
			);
		}

		let years = [];
		let startYear = cutoff.year();
		let lastYear = 1920;

		for (let i = startYear; i >= lastYear; i--) {
			years.push(i.toString());
		}

		return (
			<fieldset className="DOB label">
				<legend htmlFor="dob">
					<h5 className="label purple required">Birth Day/Month/Year</h5>
				</legend>
				<span className="dt-container">
					<FormSelect
						aria-label="Date"
						label=""
						name="birthDay"
						disabled={ticketLock || ballotLock}
						value={formProps.values.birthDay || ''}
						validate={verifyAtleastThirteen}>
						<option value="">-- Please Select a Day --</option>
						{days}
					</FormSelect>
					<FormSelect
						aria-label="Month"
						label=""
						name="birthMonth"
						disabled={ticketLock || ballotLock}
						id="birthMonth"
						value={formProps.values.birthMonth || ''}
						validate={verifyAtleastThirteen}>
						<option value="">-- Please Select a Month --</option>
						{this.months.map((i, index) => {
							return (
								<option value={index + 1} key={`month-${i}`}>
									{this.state.windowSize === 'desktop' ? i.substring(0, 3) : i}
								</option>
							);
						})}
					</FormSelect>
					<FormSelect
						aria-label="Year"
						label=""
						name="birthYear"
						id="birthYear"
						disabled={ticketLock || ballotLock}
						value={formProps.values.birthYear || ''}
						validate={verifyAtleastThirteen}>
						<option value="">-- Please Select a Year --</option>
						{years.map((d, i) => {
							return (
								<option value={d} key={i}>
									{d}
								</option>
							);
						})}
					</FormSelect>
				</span>

				<ErrorMessage
					name="birthYear"
					render={msg =>
						msg.type === 'displayOnce' ? (
							<div className="error">{msg.message}</div>
						) : (
							<div className="error">{msg}</div>
						)
					}
				/>
				<ErrorMessage
					name="birthDay"
					render={msg => msg.type !== 'displayOnce' && <div className="error">{msg}</div>}
				/>
				<ErrorMessage
					name="birthMonth"
					render={msg => msg.type !== 'displayOnce' && <div className="error">{msg}</div>}
				/>
			</fieldset>
		);
	}

	renderForm(formProps) {
		let { addressData, addressDD, ticketLock, ballotLock, emailLock } = this.state;
		let { postCodes } = this.props;
		let postCodeCheck = postCodeAvailable(postCodes, formProps.values.zip);
		let isChecked = formProps.values.agreement;

		logger.log('[FormProfile] isChecked:%o', formProps.values.agreement);

		logger.log('[FormProfile] formProps:%o', formProps);
		logger.log(
			'[FormProfile] isChecked:%o, postCodeCheck:%o, formProps.values.address:%o, formProps.initialValues.address:%o',
			isChecked,
			postCodeCheck,
			formProps.values.address,
			formProps.initialValues.address
		);
		return (
			<Fragment>
				<div className="column-layout no-padding left right top">
					{this.state.windowSize === 'desktop' ? (
						<div className="one-col">
							<div className="column-layout no-padding bottom left right">
								<div className="four-col">
									{this.state.profile.loginProvider === 'site' ? (
										<Button
											className={`btn light-btn`}
											onClick={() => {
												this.props.openScreen({ screen: 'change_password' });
											}}>
											CHANGE PASSWORD
										</Button>
									) : null}
								</div>
								<div className="four-col">
									<Button
										className={`btn light-btn`}
										onClick={() => {
											this.props.logout();
										}}>
										LOGOUT
									</Button>
								</div>
							</div>
						</div>
					) : null}
					<div className="two-col">
						<div className="column-layout no-padding top bottom left right">
							<div className="two-col">
								<FormSelect
									label="Title"
									name="title"
									disabled={ticketLock || ballotLock}
									value={formProps.values.title || ''}
									required>
									<option value="">-- Please Select a Title --</option>
									<option value="Mr">Mr</option>
									<option value="Mrs">Mrs</option>
									<option value="Miss">Miss</option>
									<option value="Ms">Ms</option>
									<option value="Mx">Mx</option>
									<option value="Dr">Dr</option>
									<option value="Prof">Prof</option>
									<option value="Rev">Rev</option>
									<option value="Sir">Sir</option>
									<option value="Dame">Dame</option>
									<option value="Baroness">Baroness</option>
									<option value="Lady">Lady</option>
									<option value="Lord">Lord</option>
									<option value="Rt Hon">Rt Hon</option>
								</FormSelect>
							</div>
							{this.state.windowSize === 'mobile' || this.state.windowSize === 'tablet' ? null : (
								<div className="two-col">&nbsp;</div>
							)}

							<div className="two-col">
								<FormInput
									label="First Name"
									name="firstName"
									type="text"
									placeholder="First Name"
									value={formProps.values.firstName || ''}
									disabled={ticketLock || ballotLock}
									required
								/>
							</div>
							<div className="two-col">
								<FormInput
									label="Last Name"
									name="lastName"
									type="text"
									placeholder="Last Name"
									value={formProps.values.lastName || ''}
									disabled={ticketLock || ballotLock}
									required
								/>
							</div>
							<div className="two-col">
								<FormEmail
									label="Email"
									name="emailProfile"
									type="email"
									placeholder="Email"
									value={formProps.values.emailProfile || ''}
									disabled={emailLock || ticketLock || ballotLock}
									required
								/>
							</div>
							<div className="two-col">{this.renderDOB(formProps)}</div>

							<div className="two-col">
								<FormInput
									label="Zip/Postal Code"
									name="zip"
									type="text"
									placeholder="Postal Code"
									onChange={e => this.changeZip(e, formProps)}
									value={formProps.values.zip || ''}
									disabled={ticketLock || ballotLock}
									required
								/>
							</div>
							<div className="two-col">
								<FormSelect
									label="Country"
									name="country"
									value={formProps.values.country || ''}
									disabled={ticketLock || ballotLock}
									required>
									<option key={'countryinit'} value="">
										-- Select a Country --
									</option>
									<option key={'uktop'} value="GB">
										{countries['GB']}
									</option>
									<option value="">-- All Countries --</option>

									{Object.keys(countries).map((d, i) => {
										return (
											<option key={i} value={d}>
												{countries[d]}
											</option>
										);
									})}
								</FormSelect>
							</div>

							{postCodeCheck && !this.state.fullProfile && (
								<Fragment>
									<div className="four-col">
										<LoadingIndicator type="small relative" />
									</div>
								</Fragment>
							)}

							{postCodeCheck && this.state.fullProfile && (
								<Fragment>
									<div className="four-col margin address-field">
										<p>
											<span className="bold">
												You have been identified as living within the catchment area of our
												AELTC community.
											</span>{' '}
											As part of the Community, we will provide you with updates, news and
											benefits from the AELTC, simply provide us with your address below so we can
											confirm the benefits you are eligible for.
										</p>
										{formProps.errors.address && (
											<p className="error-text">{formProps.errors.address}</p>
										)}
										<Field
											label="Address"
											type="text"
											name="address"
											id="address"
											onChange={e => this.addressVal(e, formProps)}
											onFocus={e => this.loqateAddress(e, formProps)}
											onBlur={e => this.addressBlur(e, formProps)}
											value={formProps.values.address || ''}
											autoComplete="off"
											validate={this.validateAddress}
										/>
										{addressDD && (
											<div className="addressSelectContainer" ref={this.addressWrapperRef}>
												{addressData && (
													<div
														className="addresses"
														onClick={() => this.setState({ addressDD: false })}>
														{addressData.map((item, i) => {
															return (
																<div
																	key={`address-${i}`}
																	className={`address-${
																		i % 2 == 0 ? `even` : `odd`
																	} ${
																		this.state.selected == item.Id ? `selected` : ``
																	}`}
																	onClick={(e, address, id) =>
																		this.addressClick(
																			e,
																			`${item.Text} ${item.Description}`,
																			item.Id,
																			formProps
																		)
																	}>
																	{`${item.Text} ${item.Description}`}
																</div>
															);
														})}
														{isEmpty(addressData) && (
															<div className="address-even">No addresses found</div>
														)}
													</div>
												)}
											</div>
										)}
									</div>
									{(!formProps.initialValues.address || formProps.initialValues.address == '') && (
										<div className="four-col margin addressConsent-field">
											<FormCheckBox name="agreement" id="agreement">
												In ticking this box I agree to be part of the AELTC Neighbours and
												Community scheme and receive information from the AELTC on tickets and
												benefits, news and updates, delivered via email and post regarding the
												Club, the grounds and The Championships.{' '}
												<a href="/en_GB/about_wimbledon/privacy.html" target="_blank">
													Terms and Conditions​
												</a>
											</FormCheckBox>
											<ErrorMessage
												name="agreement"
												render={msg => <div className="error">{msg}</div>}
											/>
										</div>
									)}
								</Fragment>
							)}

							{this.state.windowSize === 'mobile' || this.state.windowSize === 'tablet' ? (
								<div className="four-col">
									<div className="column-layout three-column no-padding top left right">
										<div className="four-col ballot-msg">{this.getLockMessage()}</div>
										<div className="one-col no-margin left right full-width">
											<Button
												className={`btn solid ${
													Object.keys(formProps.errors).length > 0 ||
													formProps.isSubmitting ||
													(postCodeCheck &&
														isChecked !== 'true' &&
														isChecked !== true &&
														formProps.values.address &&
														(!formProps.initialValues.address ||
															formProps.initialValues.address == ''))
														? 'disabled'
														: ''
												}`}
												onClick={() =>
													(postCodeCheck &&
														formProps.values.address &&
														(!formProps.initialValues.address ||
															formProps.initialValues.address == '') &&
														(isChecked === 'true' || isChecked === true)) ||
													formProps.initialValues.address ||
													!postCodeCheck
														? !formProps.errors.address && formProps.submitForm()
														: null
												}>
												UPDATE
											</Button>
										</div>
									</div>
									<div className="column-layout three-column no-padding left right top">
										<div className="one-col no-margin left right full-width">
											{this.state.profile.loginProvider === 'site' ? (
												<Button
													className={`btn light-btn`}
													onClick={() => {
														this.props.openScreen({ screen: 'change_password' });
													}}>
													CHANGE PASSWORD
												</Button>
											) : null}
										</div>
										{/* <div className="one-third">
                       <button className="btn light-btn" type="submit">
                         DELETE ACCOUNT
                       </button>
                     </div> */}
										<div className="one-col no-margin left right full-width">
											<Button
												className={`btn light-btn`}
												onClick={() => {
													this.props.logout();
												}}>
												LOGOUT
											</Button>
										</div>
									</div>
								</div>
							) : (
								<Fragment>
									<div className="four-col ballot-msg">{this.getLockMessage()}</div>
									<div className="two-col">
										<Button
											type="submit"
											className={`btn solid ${
												Object.keys(formProps.errors).length > 0 ||
												formProps.isSubmitting ||
												(postCodeCheck &&
													isChecked !== 'true' &&
													isChecked !== true &&
													formProps.values.address &&
													(!formProps.initialValues.address ||
														formProps.initialValues.address == ''))
													? 'disabled'
													: ''
											}`}
											onClick={() =>
												(postCodeCheck &&
													formProps.values.address &&
													(!formProps.initialValues.address ||
														formProps.initialValues.address == '') &&
													(isChecked === 'true' || isChecked === true)) ||
												formProps.initialValues.address ||
												!postCodeCheck
													? !formProps.errors.address && formProps.submitForm()
													: null
											}>
											UPDATE
										</Button>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	handleSubmit(values, actions) {
		logger.log('[FormProfile] submission - values %o', values);

		let addSubscription =
			!op.get(this.state, 'profile.address', false) && op.get(values, 'address', false) ? true : false;
		//logger.log('[FormProfile] submission - addSubscription: %o', addSubscription);

		const profileUpdateInfo = this.constructProfileJSON(values, addSubscription);

		if (!this.state.ballotLock && !this.state.ticketLock) {
			this.props.setAccountInfo('profileUpdate', profileUpdateInfo);
		} else {
			this.profileModal('message_select', profileUpdateInfo);
		}

		actions.setSubmitting(false);
	}

	constructProfileJSON(data, addSubscription) {
		let submissionData = { ...data };
		let title = submissionData['title'];
		submissionData['email'] = submissionData['emailProfile'];
		delete submissionData['title'];
		delete submissionData['emailProfile'];
		// delete submissionData['emailconfirm'];
		delete submissionData['age'];
		delete submissionData['loginProvider'];
		delete submissionData['agreement'];

		let returnData = {
			data: {
				profile: {
					title: title,
				},
			},
			profile: {
				...submissionData,
			},
		};

		if (addSubscription) {
			op.set(returnData, 'subscriptions.community.email.isSubscribed', true);
		}

		return returnData;
	}

	componentDidMount() {
		logger.log('[FormProfile] componentDidMount - state:%o', this.state);
		document.addEventListener('mousedown', this.clickOffAddress);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.clickOffAddress);
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[FormProfile] componentDidUpdate - state:%o', this.state);

		let ballotLock = false;
		let ticketLock = false;
		let emailLock = true;
		let ballotRole = op.get(this.state, 'currentUser.roles', []).filter(d => d.roleId == 1000060)[0];
		let ticketRole = op.get(this.state, 'currentUser.roles', []).filter(d => d.roleId == 1000090)[0];

		// check that user has a ballot role and that ballot roles are configrued to be locked
		if (ballotRole && this.state.lockRoles['ballot']) {
			ballotLock = true;
			if (ballotLock != this.state.ballotLock) {
				this.setState({
					ballotLock,
				});
			}
		}

		// check that user has a ticket role and that ticket roles are configrued to be locked
		if (ticketRole && this.state.lockRoles['ticket']) {
			ticketLock = true;
			if (ticketLock != this.state.ticketLock) {
				this.setState({
					ticketLock,
				});
			}
		}

		if (emailLock != this.state.emailLock) {
			this.setState({
				emailLock,
			});
		}
	}

	/**
	 * show a modal with specific messages when in ballot apply or select periods and attempting to update profile data
	 * @param {*} state
	 * @param {*} profileUpdateInfo
	 */
	profileModal(state, profileUpdateInfo) {
		//PROD change request from AELTC to remove this pop-up message
		if (true) {
			this.finishSubmit(profileUpdateInfo);
		} else if (state == 'message_apply') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'confirm',
				title: '',
				message: `Please ensure that you also update and confirm your details on your Ballot application. You can do this by clicking Edit Application in the pane below. If you wish to amend your email address or if you have any questions, please contact the Ticket Office on 020 8971 2473 or <a href="mailto:ticketing@aeltc.com" target="_blank">ticketing@aeltc.com</a>.`,
				useTimer: false,
				okCallback: () => {
					this.finishSubmit(profileUpdateInfo);
				},
			});
		} else if (state == 'message_select') {
			this.props.showModal({
				showModal: true,
				showCloseBtn: false,
				type: 'confirm',
				title: '',
				message: `In order to update your Ballot application details, please contact the Ticket Office on 020 8971 2473 or <a href="mailto:ticketing@aeltc.com" target="_blank">ticketing@aeltc.com</a>.`,
				useTimer: false,
				okCallback: () => {
					this.finishSubmit(profileUpdateInfo);
				},
			});
		}
	}

	/**
	 * get message to display in profile form if ticket lock
	 */
	getLockMessage() {
		if (!this.state.ticketLock && !this.state.ballotLock) {
			return null;
		} else {
			return (
				<div>
					{`It is important that we are able to provide you with essential information. We recommend retaining the same email address used in your reservation. If you need to update your personal details, please `}
					<WimLink to="/en_GB/contact/index.html">contact us</WimLink>
					{`.`}
				</div>
			);
		}
	}

	finishSubmit(profileUpdateInfo) {
		this.props.setAccountInfo('profileUpdate', profileUpdateInfo);
	}

	render() {
		logger.log('[FormProfile] render - state:%o context:%o', this.state, this.context);
		//logger.log('[FormProfile] render - props:%o', this.props);

		let initialValues = {};
		if (this.state.profile) {
			initialValues = {
				birthDay: this.state.profile.birthDay,
				birthMonth: this.state.profile.birthMonth,
				birthYear: this.state.profile.birthYear,
				country: this.state.profile.country,
				emailProfile: this.state.profile.email,
				firstName: this.state.profile.firstName,
				lastName: this.state.profile.lastName,
				title: this.state.profile.title,
				zip: this.state.profile.zip,
				address: this.state.profile.address,
				agreement: false,
			};
		}
		return this.state.profile ? (
			<span className="profile-form">
				<Formik
					onSubmit={this.handleSubmit}
					validationSchema={PROFILESCHEMA}
					initialValues={initialValues}
					enableReinitialize={true}>
					{formProps => {
						return this.renderForm(formProps);
					}}
				</Formik>
			</span>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FormProfile);

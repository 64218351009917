// import deps from "dependencies";
import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.ACTIVEDATA_LOADED:
			newState = {
				...state,
				...action.data,
				status: 'loaded',
				updated: action.updated,
			};

			if (newState.hasOwnProperty('error')) {
				delete newState.error;
			}
			//logger.log('[ActiveData] reducer.ACTIVEDATA_LOADED');
			//logger.log('[ActiveData] reducer.ACTIVEDATA_LOADED - updated:%o: newState:%o', action.updated, action);
			return newState;

		case deps.actionTypes.ACTIVEDATA_LOADING:
			newState = Object.assign({}, state, { status: 'loading' });
			if (newState.hasOwnProperty('error')) {
				delete newState.error;
			}
			return newState;

		case deps.actionTypes.ACTIVEDATA_ERROR:
			//logger.log('[ActiveData] reducer.ACTIVEDATA_ERROR - action:%o', action);
			newState = Object.assign({}, state, {
				status: 'error',
				error: action.error,
			});
			return newState;

		case deps.actionTypes.ACTIVEDATA_EXPIRED:
			//logger.log('[ActiveData] reducer.ACTIVEDATA_EXPIRED');
			newState = Object.assign({}, state, { status: 'expired' });
			return newState;

		default:
			return state;
	}
};

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: EventsHelmet
 * -----------------------------------------------------------------------------
 */
const EventsHelmet = props => {
	// logger.log('[EventsHelmet] props:%o', props);
	let { attributes = {}, shareHost, scripts, bodyClass } = props;
	attributes.link = attributes.link
		? attributes.link
		: window.location.href.replace(window.location.origin, shareHost);
	attributes.type = attributes.type ? attributes.type : 'O';
	attributes.isoDate = moment(attributes.contentDate).toISOString();
	//attributes.date = attributes.date ? attributes.date : Date.now();

	let isHp =
		window.location.pathname == '/en_GB/index.html' ||
		window.location.pathname == '/' ||
		window.location.pathname == '/index.html'
			? true
			: false;

	// logger.log('[EventsHelmet]  - attributes: %o, isHp:%o', attributes, isHp);

	/*
		attributes.contentTitle
			Used for resulttitle and social titles, the title minus boilerplate  
			Can be overridden by metaSeoTitle or metaTitle
		attributes.description
			Used for description and social description
			Can be overridden by metaSeoDescription or metaDescription
			metaDescription is from CMS abstract for content pages
	*/

	return (
		<Helmet>
			{/** SEO TAGS */}
			<title>{attributes.pageTitle}</title>
			<meta name="description" content={attributes.description} />
			<meta name="keywords" content={attributes.keywords} />
			{attributes.canonical ? <link rel="canonical" href={attributes.canonical} /> : null}
			{attributes.amp ? <link rel="amphtml" href={attributes.amp} /> : null}
			{isHp ? (
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'Organization',
						url: 'https://www.wimbledon.com/en_GB/index.html',
						logo: 'http://www.wimbledon.com/assets/images/logos/Wimbledon_logo.png',
					})}
				</script>
			) : null}
			{attributes.measureTitle ? <meta name="measureTitle" content={attributes.measureTitle} /> : null}

			{/** Not Found/Noindex */}
			{attributes.noindex == true ? <meta name="robots" content="noindex" /> : null}

			{/** UNKNOWN? */}
			{attributes.type == 'A' || attributes.type == 'O' || !attributes.type ? (
				<link rel="origin" href={attributes.link ? attributes.link : ''} />
			) : null}

			{/** SITESEARCH TAGS */}
			{/* site search */}
			{attributes.contentTitle ? <meta name="resulttitle" content={attributes.contentTitle} /> : null}
			{attributes.contentDate ? <meta name="resultdate" content={attributes.contentDate} /> : null}
			{attributes.contentPlayers ? <meta name="resultplayers" content={attributes.contentPlayers} /> : null}

			{/** FACEBOOK TAGS */}
			{/** Facebook Common */}
			<meta property="fb:app_id" content="1462414547319687" />
			{attributes.contentTitle ? (
				<meta property="og:title" content={attributes.contentTitle ? attributes.contentTitle : ''} />
			) : null}
			{attributes.description ? (
				<meta property="og:description" content={attributes.description ? attributes.description : ''} />
			) : null}
			{attributes.link ? <meta property="og:url" content={attributes.link ? attributes.link : ''} /> : null}
			{attributes.shareImage ? (
				<meta property="og:image" content={attributes.shareImage ? attributes.shareImage : ''} />
			) : null}
			{attributes.shareImage ? <meta property="og:image:secure_url" content={attributes.shareImage} /> : null}
			{attributes.width ? <meta property="og:image:width" content={attributes.width} /> : null}
			{attributes.height ? <meta property="og:image:height" content={attributes.height} /> : null}

			{/** Facebook Article/Page */}
			{attributes.type == 'A' ? <meta property="og:type" content="article" /> : null}
			{attributes.type == 'O' ? <meta property="og:type" content="page" /> : null}

			{/* Facebook Photo Galleries */}
			{attributes.type == 'P' ? <meta name="og:type" content="photo galleries" /> : null}

			{/** Facebook Video */}
			{attributes.type == 'V' ? <meta property="og:type" content="video.other" /> : null}

			{/* figure out if need to share video itself
			{attributes.type == 'V' ? <meta property="og:video:type" content="video/mp4" /> : null}
			{attributes.type == 'V' ? <meta property="og:video:width" content="512" /> : null}
			{attributes.type == 'V' ? <meta property="og:video:height" content="288" /> : null}
			{attributes.type == 'V' ? <meta property="og:video:url" content={attributes.media} /> : null}
			{attributes.type == 'V' ? <meta property="og:video:secure_url" content={attributes.media} /> : null}
			*/}

			{/** TWITTER TAGS */}
			{/** Twitter Common */}
			<meta name="twitter:site" content="@Wimbledon" />
			<meta name="twitter:creator" content="@Wimbledon" />
			<meta name="twitter:card" content="summary_large_image" />

			{attributes.contentTitle ? <meta name="twitter:title" content={attributes.contentTitle} /> : null}
			{attributes.description ? <meta name="twitter:description" content={attributes.description} /> : null}
			{attributes.shareImage ? <meta name="twitter:image" content={attributes.shareImage} /> : null}
			{attributes.shareImage ? (
				<meta
					name="twitter:image:alt"
					content={attributes.imageAlt ? attributes.imageAlt : attributes.description}
				/>
			) : null}

			{/** Twitter Article and Page */}

			{/** Twitter Video */}
			{/* figure out if need to share video itself
			{attributes.type == 'V' ? <meta name="twitter:card" content="player" /> : null}
			{attributes.type == 'V' ? <meta name="twitter:player:width" content="512" /> : null}
			{attributes.type == 'V' ? <meta name="twitter:player:height" content="288" /> : null}
			{attributes.type == 'V' ? <meta name="twitter:player:stream:content_type" content="video/mp4" /> : null}
			{attributes.type == 'V' ? (
				<meta name="twitter:player" content={`${shareHost}/social/player${attributes.link}`} />
			) : null}
			{attributes.type == 'V' ? <meta name="twitter:player:stream" content={attributes.media} /> : null}
			*/}

			{/* Twitter Photo Galleries */}

			{/** SCHEMA.org microdata */}
			{attributes.type == 'A' ? (
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'NewsArticle',
						headline: attributes.contentTitle ? attributes.contentTitle : '',
						abstract: attributes.description ? attributes.description : '',
						image: attributes.shareImage ? attributes.shareImage : '',
						datePublished: attributes.contentDate ? attributes.contentDate : Date.now(),
						author: [
							{
								'@type': 'Person',
								name: attributes.author ? attributes.author : '',
							},
						],
					})}
				</script>
			) : null}

			{attributes.type == 'V' ? (
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'VideoObject',
						mainEntityOfPage: attributes.link ? attributes.link : '',
						name: attributes.contentTitle ? attributes.contentTitle : '',
						description: attributes.description ? attributes.description : 'Wimbledon video',
						thumbnailUrl: attributes.shareImage ? attributes.shareImage : '',
						duration: attributes.duration ? attributes.duration : '',
						uploadDate: attributes.isoDate ? attributes.isoDate : '',
						contentUrl: attributes.media ? attributes.media : null,
						publisher: {
							'@type': 'Organization',
							name: 'The Championships, Wimbledon',
							logo: 'http://www.wimbledon.com/assets/images/logos/Wimbledon_logo.png',
						},
					})}
				</script>
			) : null}

			{scripts
				? scripts.map((script, i) => {
						return <script key={i} src={script} async />;
				  })
				: null}
			{bodyClass ? <body tabIndex="-1" className={bodyClass} /> : null}
		</Helmet>
	);
};

export default EventsHelmet;

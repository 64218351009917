import { format } from 'util';
import op from 'object-path';

const convertPoints = (match, result, videoStartTime, matchOffset) => {
	let newResult = [];
	let skipOffset = 0;
	let server = '0';
	let setNo = '0';
	let gameNo = '0';

	// subtract any skip seconds from points
	if (matchOffset.skipPoints && matchOffset.skipPoints.length > 0) {
		for (let j = 0; j < result.length; j++) {
			if (isNumeric(result[j].pointNumber)) {
				let skipPoint = matchOffset.skipPoints.filter(p => {
					return p.point == result[j].pointNumber;
				});

				if (skipPoint.length > 0) {
					skipOffset += skipPoint[0].epochPointSkip - skipPoint[0].epochVideoOffset;
				}

				result[j].epochTimeStart = result[j].epochTimeStart - skipOffset;
				result[j].epochTimeEnd = result[j].epochTimeEnd - skipOffset;
			}
		}
	}

	logger.log('[MatchPoints] convertPoints - match:%o matchOffset:%o', match, matchOffset);
	// add extra points for servelines
	//  add metadata
	for (let i = 0; i < result.length; i++) {
		let prev = i > 0 ? i - 1 : 0;
		let newGame = false;

		//parse epochs from string values to numeric
		result[i].epochTimeEnd = parseInt(result[i].epochTimeEnd);
		result[i].epochTimeStart = parseInt(result[i].epochTimeStart);

		//add metadata values
		result[i].meta = {
			excitement: false,
			missing: false,
		};

		if (match.excitementPoints) {
			for (let r = 0; r < match.excitementPoints.length; r++) {
				if (match.excitementPoints[r].point == result[i].pointNumber) {
					result[i].meta.excitement = true;
				}
			}
		}
		if (matchOffset.points) {
			for (let m = 0; m < matchOffset.points.length; m++) {
				if (matchOffset.points[m].point == result[i].pointNumber) {
					result[i].meta.missing = matchOffset.points[m].missing;
				}
			}
		}

		//logger.log('[MatchPoints] convertPoints - point:%o', result[i]);
		//logger.log('[MatchPoints] convertPoints - start:%o end:%o', result[i].epochTimeStart, result[i].epochTimeEnd);
		if (isNumeric(result[i].pointNumber)) {
			if (result[i].setNo != setNo) {
				setNo = result[i].setNo;
				server = '0';
			}
			if (result[i].gameNo != gameNo) {
				gameNo = result[i].gameNo;
				newGame = true;
			}
			if (result[i].pointServer != server && !result[i].meta.missing) {
				server = result[i].pointServer;
				result.splice(i, 0, { ...result[i] });
				result[i].pointNumber = result[i].pointNumber + 's';
				result[i].p1ScoreAfter = newGame ? '0' : result[prev].p1ScoreAfter;
				result[i].p2ScoreAfter = newGame ? '0' : result[prev].p2ScoreAfter;
				result[i].sentence = getTeam(match, result[i].pointServer) + ' serving';
				result[i].serveline = true;
				result[i].epochTimeEnd = result[i].epochTimeStart;
				result[i].epochTimeStart = prev > 0 ? result[prev].epochTimeEnd + 5 : result[i].epochTimeStart - 1;
				result[i].setWinner = '0';
				result[i].matchWinner = '0';
			}
			newResult.push(transformPoint(match, result[i], i, videoStartTime, newResult[newResult.length - 1]));
		}
	}

	let history = '';
	for (var j = 0; j < newResult.length; j++) {
		let p = newResult[j];
		history += '\n';
		history += `pt:${padRight(String(p.num), 5)}`;
		history += `${padRight(String(p.meta.excitement ? 'E' : ''), 3)}`;
		history += `set:${padRight(p.setNo, 3)} gm:${padRight(p.gameNo, 3)} `;
		history += `srv:${padRight(p.server.replace('srv', ''), 3)} `;
		//history += `win: ${p.winner} `;
		history += `sets: `;
		for (var k = 0; k < p.displayScore.sets.length; k++) {
			history += `${p.displayScore.sets[k][0].score}-${p.displayScore.sets[k][1].score} `;
		}
		history += `\t${p.displayScore.gameScore[0]}-${p.displayScore.gameScore[1]}  `;
		history += `\t${p.clipStartTime} - ${p.clipEndTime}`;
		//history += `\tdur: ${p.duration}`;
		history += `\t  ${p.description}`;
	}
	logger.log('[MatchPoints] convertPoints - POINT HISTORY: %s', history);

	return newResult;
};

const transformPoint = (m, p, index, videoStartTime = 0, prevPoint = null) => {
	//logger.log(`[MatchPoints] transformPoint - prevPoint:%o`, prevPoint);
	//logger.log(`[MatchPoints] transformPoint - videoStartTime:%o`, videoStartTime);

	let point = {
		num: '',
		clipStart: '-',
		clipDuration: 0,
		clipEnd: '-',
		setNo: '-',
		gameNo: '-',
		score: '-',
		server: '-',
		gameWinner: '-',
		setWinner: '-',
		pointWinner: '-',
		matchWinner: '-',
		type: '-',
		displayScore: {
			sets: [[{ score: 0 }, { score: 0 }]],
		},
		meta: {},
	};

	//logger.log('[MatchPoints] transformPoint - num:%o point:%o ', p.pointNumber, p);

	// 1 - copy in the previous points set scores so have previous sets in array
	if (prevPoint) {
		//point.displayScore.sets = [...prevPoint.displayScore.sets];
		for (let i = 0; i < prevPoint.displayScore.sets.length; i++) {
			point.displayScore.sets[i] = [
				{ ...prevPoint.displayScore.sets[i][0] },
				{ ...prevPoint.displayScore.sets[i][1] },
			];
		}
	}

	point.num = p.pointNumber;
	point.clipStart = Math.max(0, p.epochTimeStart - videoStartTime);
	point.clipEnd = p.epochTimeEnd - videoStartTime;

	point.clipStartTime = convertTime(point.clipStart);
	point.clipEndTime = convertTime(point.clipEnd);
	point.duration = p.elapsedTime;
	//logger.log('[MatchPoints] transformPoint - times:', p.epochTimeStart, p.epochTimeEnd, point.clipStart, point.clipEnd);

	point.score = formatScore(p.p1ScoreAfter, p.p2ScoreAfter, p.pointServer);
	point.setScore = p.p1GamesWonAfter + '-' + p.p2GamesWonAfter;
	point.setNo = p.setNo;
	point.gameNo = p.gameNo;
	//if past first point set a server and In Progress status

	point.status = 'B';
	if (p.matchWinner && p.matchWinner != 0) {
		point.matchWinner = p.matchWinner;
	}
	if (p.setWinner && p.setWinner != 0) {
		point.setWinner = p.setWinner;
	}
	if (p.gameWinner != 0) {
		point.gameWinner = getTeam(m, p.gameWinner);
		//logger.log('[MatchPoints] transformPoint - end point:%o', point);
	}
	point.pointWinner = getTeam(m, p.pointWinner);
	point.type = '-';
	point.description = p.sentence;
	point.ace = p.ace;
	point.breakPoint = p.breakPoint;
	point.doubleFault = p.doubleFault;

	point.serveline = p.serveline;
	point.server = 'srv' + p.pointServer;
	point.nextServer = !p.serveline && p.serveIndicator ? 'srv' + p.serveIndicator : '';

	point.meta = p.meta;
	point.index = index;
	//logger.log('[MatchPoints] transformPoint - end point:%o', point);

	// adjust display score
	let prevSets = op.get(prevPoint, 'displayScore.sets', []);
	let setNo = parseInt(p.setNo);
	let finalSet = false;
	let displayTiebreak = true;

	//logger.log('[MatchPoints] transformPoint - match:%o', m);
	if ((m.event == 'LS' && setNo == 3) || (m.event == 'MS' && setNo == 5)) {
		if (parseInt(m.year) < 2019) {
			displayTiebreak = false;
		} else if (parseInt(point.gameNo) == 25) {
			displayTiebreak = true;
		} else {
			displayTiebreak = false;
		}
	}

	// 2 - update current set set score
	if (!point.serveline && setNo == point.displayScore.sets.length) {
		point.displayScore.sets[setNo - 1][0].score = p.p1GamesWonAfter;
		point.displayScore.sets[setNo - 1][1].score = p.p2GamesWonAfter;
		point.displayScore.sets[setNo - 1][0].scoreDisplay = String(p.p1GamesWonAfter);
		point.displayScore.sets[setNo - 1][1].scoreDisplay = String(p.p2GamesWonAfter);

		//logger.log('[MatchPoints] transformPoint - tiebreakScore point:%o p:%o', point, p);
		if (
			(parseInt(point.gameNo) == 13 && (point.setWinner != '-' || point.matchWinner != '-') && displayTiebreak) ||
			(parseInt(point.gameNo) == 25 && point.matchWinner != '-' && displayTiebreak)
		) {
			let score1 = parseInt(p.p1ScoreBefore);
			let score2 = parseInt(p.p2ScoreBefore);
			if (point.setWinner == '1' || point.matchWinner == '1') {
				score1 += 1;
			} else {
				score2 += 1;
			}

			point.displayScore.sets[setNo - 1][0].tiebreak = p.p1GamesWonAfter;
			point.displayScore.sets[setNo - 1][1].tiebreak = p.p2GamesWonAfter;
			point.displayScore.sets[setNo - 1][0].tiebreakDisplay = String(score1);
			point.displayScore.sets[setNo - 1][1].tiebreakDisplay = String(score2);
			//point.displayScore.sets.push([{ score: 0 }, { score: 0 }]);
		} else {
			point.displayScore.sets[setNo - 1][0].tiebreak = null;
			point.displayScore.sets[setNo - 1][1].tiebreak = null;
			point.displayScore.sets[setNo - 1][0].tiebreakDisplay = '';
			point.displayScore.sets[setNo - 1][1].tiebreakDisplay = '';
		}
	} else if (setNo > point.displayScore.sets.length) {
		// if (prevPoint && parseInt(prevPoint.gameNo) > 12) {
		// 	point.displayScore.sets[setNo - 2][0].tiebreak = prevPoint.displayScore.gameScore[0];
		// 	point.displayScore.sets[setNo - 2][1].tiebreak = prevPoint.displayScore.gameScore[1];
		// 	point.displayScore.sets[setNo - 2][0].tiebreakDisplay = String(prevPoint.displayScore.gameScore[0]);
		// 	point.displayScore.sets[setNo - 2][1].tiebreakDisplay = String(prevPoint.displayScore.gameScore[1]);
		// }

		point.displayScore.sets.push([{ score: 0 }, { score: 0 }]);
	}

	// 3 - update the points
	op.set(point, 'displayScore.gameScore.0', p.p1ScoreAfter);
	op.set(point, 'displayScore.gameScore.1', p.p2ScoreAfter);
	op.set(point, 'displayScore.server', p.serveIndicator);

	// if set or match winner, clear game scores for matchbox and pointnav
	if (point.matchWinner != '-' || point.setWinner != '-') {
		point.displayScore.gameScore[0] = '';
		point.displayScore.gameScore[1] = '';
		point.score = '0-0';
	}

	//if missing, override other display
	if (p.meta.missing) {
		point.displayScore.gameScore[0] = '';
		point.displayScore.gameScore[1] = '';
		point.score = '-';
		point.description = '(video unavailable)';
	}

	//logger.log('[MatchPoints] transformPoint - num:%o p:%o point:%o', p.pointNumber, p, point);
	//logger.log('[MatchPoints] transformPoint - end winner:%o point:%o', p.matchWinner, point);

	return point;
};

const getTeam = (m, num) => {
	return m['team' + num];
};

const convertTime = time => {
	if (isNaN(time)) {
		return '-';
	}

	let hours = Math.floor(time / 3600);
	let minutes = Math.floor((time - hours * 3600) / 60);
	let seconds = time - minutes * 60;

	//time = time - hours * 3600;

	let str_pad_left = (string, pad, length) => {
		return (new Array(length + 1).join(pad) + string).slice(-length);
	};

	let finalTime =
		str_pad_left(hours, '0', 2) + ':' + str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);

	return finalTime;
};

const formatScore = (p1, p2, server) => {
	//logger.log('[MatchPoints] formatScore - p1:%o, p2:%o, server:%o', p1, p2, server);

	if (!p1 || !p2) {
		return '-';
	} else {
		return server == '1' ? `${p1}-${p2}` : `${p2}-${p1}`;
	}
};

const isNumeric = data => {
	return !isNaN(parseFloat(data)) && isFinite(data);
};

const score = {
	setsWon: [2, 2, 2, 0, 0, 0],
	setDurations: ['34', '34'],
	sets: [
		[
			{
				score: 3,
				scoreDisplay: '3',
				tiebreak: null,
				tiebreakDisplay: null,
				challenge_total: 1,
				challenge_won: 0,
				challenge_lost: 1,
				challenge_remaining: 2,
			},
			{
				score: 6,
				scoreDisplay: '6',
				tiebreak: null,
				tiebreakDisplay: null,
				challenge_total: 0,
				challenge_won: 0,
				challenge_lost: 0,
				challenge_remaining: 3,
			},
		],
		[
			{
				score: 3,
				scoreDisplay: '3',
				tiebreak: null,
				tiebreakDisplay: null,
				challenge_total: 2,
				challenge_won: 0,
				challenge_lost: 2,
				challenge_remaining: 1,
			},
			{
				score: 6,
				scoreDisplay: '6',
				tiebreak: null,
				tiebreakDisplay: null,
				challenge_total: 0,
				challenge_won: 0,
				challenge_lost: 0,
				challenge_remaining: 3,
			},
		],
	],
	gameScore: [null, null],
};

const padRight = (str, pad) => {
	return str + new Array(pad - str.length).join(' ');
};

export { convertPoints, transformPoint };

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import deps from 'dependencies';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Storyteller from 'appdir/components/cms/Storyteller';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Storyteller
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
});

class StorytellerPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			scriptLoaded: false,
			scriptError: false,
		};


		//logger.log('[StorytellerPage] init - state: %o', this.state);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	

	render() {
		//logger.log('[StorytellerPage] render - state:%o', this.state);

		return (
	

			<div className="content-main content ">
				
				<div className='column-layout content'>
					<Storyteller data={{
						title: 'Test Story All',
						content: 'stories',
						categories: 'wimbledon-2024',
						categories2: 'wimbledon-editorial',
						count: 10
					}} />
				</div>
				<div className='column-layout content'>
					<Storyteller data={{
						title: 'Test Clips',
						content: 'clips',
						categories: 'wimbledon-2024',
						categories2: 'wimbledon-moments',
						count: 10
					}} />
				</div>
			</div>
	
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StorytellerPage);


/**

<div className="column-layout content">
					<Storyteller title="Test Story Top - QA" type="stories" categories="wimbledon-2024" />
				</div>
				<br/><br/><br/>
				<div className="column-layout content">
					<Storyteller title="Test Story Top" type="stories" categories="wimbledon-top-row" />
				</div>
				<div className="column-layout content">
					<Storyteller title="Test Story All" type="stories" categories="wimbledon-editorial" />
				</div>
				<div className="column-layout content">
					<Storyteller title="Test Clips" type="clips" categories="wimbledon-moments" />
				</div>

 */
import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';

const mobileMatch = window.matchMedia('(max-width: 390px)');

class CatchMeUpPromo extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

    handleBeforeUnload = () => {
        // dont show again if user views then refreshes page
		if (this.props.onUnmount) {
			this.props.onUnmount();
		}
	};

	render() {
        let { imageCount } = this.props;
        return (
            <div className="catch-me-up-image-container">
                {(!mobileMatch || (mobileMatch && imageCount == 0)) ?
                    <img alt="Catch Me Up Promo" className="catch-me-up-promo-img" />
                : null}
                {mobileMatch && imageCount == 1 ?
                    <img alt="Catch Me Up Promo" className="catch-me-up-promo-img2" />
                : null}

            </div>
        );
	}
}

export default CatchMeUpPromo;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { getJson } from 'appdir/components/general/Util/Services';

const fetchBack = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

/**
 * fetch video json file
 *   - 2019, trying new pattern to use utility Services for axios requests
 * @param {String} path
 */
const fetch = path => {
	return new Promise((resolve, reject) => {
		getJson(path)
			.then(result => {
				resolve(result);
			})
			.catch(error => {
				reject(error);
			});
	});
};

const fetchWatchReadData = pathObj => {
	return axios.all([fetch(pathObj.matchBoxPath), fetch(pathObj.relatedContentPath)]);
};

export default {
	fetch,
	fetchWatchReadData,
};

import deps from 'dependencies';

export default (state = {}, action) => {
    let newState, status;

    switch (action.type) {
        case deps.actionTypes.TESTPAGE_MOUNT:
            status = action.config.hasOwnProperty('TestPage')
                ? deps.action.config.TestPage.status
                : state.status;
            status = action.config.status === 'loading' ? 'loading' : status;
            newState = Object.assign(
                {},
                state,
                { ...action.data },
                { status: status },
            );
            return newState;

        case deps.actionTypes.CONFIG_UPDATE:
            status = action.data.hasOwnProperty('TestPage')
                ? deps.action.data.TestPage.status
                : state.status;
            newState = Object.assign({}, state, { status: status });
            return newState;

        default:
            return state;
    }
};

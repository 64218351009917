import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import PageNav from 'appdir/components/common-ui/PageNav';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import { values } from 'appdir/main';
import { isDoublesMatch, useMatchExtras } from 'appdir/components/pages/Slamtracker/Utilities';

import SlamtrackerToggle from 'appdir/components/pages/Slamtracker/elements/SlamtrackerToggle';
import OverallStats from 'appdir/components/pages/Slamtracker/elements/stats/OverallStats';
import ServeStats from 'appdir/components/pages/Slamtracker/elements/stats/ServeStats';
import ReturnStats from 'appdir/components/pages/Slamtracker/elements/stats/ReturnStats';
import RallyStats from 'appdir/components/pages/Slamtracker/elements/stats/RallyStats';
import DssStats from 'appdir/components/pages/Slamtracker/elements/stats/DssStats';

import isEmpty from 'lodash/isEmpty';

import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MatchStats
 * -----------------------------------------------------------------------------
 */
const MatchStats = props => {
    const [statsType, setStatsType] = useState('overall');
    const [statsSet, updateStatsSet] = useState('match');
    const [selectOpen, setSelectOpen] = useState();
    const useInAttack = useSelector((state) => state?.['Config']?.slamtracker?.useInAttack ?? false);
    const statMatch = useSelector((state) => state?.['ScoreManager']?.statMatch ?? {});
    const uom = useSelector((state) => state?.['Controller']?.userPreferences?.speed ?? null);
    const statsTabItems = [
		{ name: 'MATCH SUMMARY', value: 'match', classes: `${!statMatch?.stats?.base_stats?.['match'] || statsType == 'dss' ? 'disabled' : ''}`, measure: 'Match Summary' },
		{ name: 'S1', value: 'set_1', classes: `${!statMatch?.stats?.base_stats?.['set_1'] || statsType == 'dss' ? 'disabled' : ''}`, measure: 'S1' },
        { name: 'S2', value: 'set_2', classes: `${!statMatch?.stats?.base_stats?.['set_2'] || statsType == 'dss' ? 'disabled' : ''}`, measure: 'S2' },
        { name: 'S3', value: 'set_3', classes: `${!statMatch?.stats?.base_stats?.['set_3'] || statsType == 'dss' ? 'disabled' : ''}`, measure: 'S3' },
        { name: 'S4', value: 'set_4', classes: `${!statMatch?.stats?.base_stats?.['set_4'] || statsType == 'dss' ? 'disabled' : ''}`, measure: 'S4' },
        { name: 'S5', value: 'set_5', classes: `${!statMatch?.stats?.base_stats?.['set_5'] || statsType == 'dss' ? 'disabled' : ''}`, measure: 'S5' },
	];
    const statsDropdownItems = {
        options: [
            { name: 'Overall Stats', value: 'overall' },
		    { name: 'Serve Stats', value: 'serve' },
            { name: 'Return Stats', value: 'return' },
            { name: 'Rally Stats', value: 'rally' },
            { name: 'Dir Serve Stats', value: 'dss' }
        ]
    };

    let matchExtras = useMatchExtras(statMatch);
    let isDoubles = isDoublesMatch(statMatch);

    const getNames = () => {
        let team1Names;
		let team2Names;

		team1Names = statMatch.team1.displayNameA;
		team2Names = statMatch.team2.displayNameA;
		if (isDoubles) {
			team1Names += `<br/>${statMatch.team1.displayNameB}`;
			team2Names += `<br/>${statMatch.team2.displayNameB}`;
		}

        return [team1Names, team2Names]
    }

	const getStatsContent = () => {
        switch (statsType) {
            case 'overall':
                let overall_stats_data = {
                    names: getNames(),
                    t1: statMatch?.stats?.base_stats?.[statsSet]?.team_1,
                    t2: statMatch?.stats?.base_stats?.[statsSet]?.team_2,
                    d1: isEmpty(statMatch?.stats?.distance_run)
                        ? 'none'
                        : statMatch?.stats?.distance_run?.[statsSet]?.team_1,
                    d2: isEmpty(statMatch?.stats?.distance_run)
                        ? 'none'
                        : statMatch?.stats?.distance_run?.[statsSet]?.team_2,
                    unit: uom,
                    doubles: isDoubles,
                    set: statsSet,
                    matchExtras: matchExtras,
                    useInAttack: useInAttack
                };
        
                return <OverallStats data={overall_stats_data} />;
    
            case 'serve':
                let serve_stats_data = {
                    names: getNames(),
                    t1: statMatch?.stats?.serve_stats?.[statsSet]?.team_1,
                    t2: statMatch?.stats?.serve_stats?.[statsSet]?.team_2,
                    doubles: isDoubles
                };
        
                return <ServeStats data={serve_stats_data} />;
    
            case 'return':
                let return_stats_data = {
                    names: getNames(),
                    t1: statMatch?.stats?.return_stats?.[statsSet]?.team_1,
                    t2: statMatch?.stats?.return_stats?.[statsSet]?.team_2,
                    doubles: isDoubles
                };
        
                return <ReturnStats data={return_stats_data} />;
    
            case 'rally':
                let rally_stats_data = {
                    names: getNames(),
                    t1: statMatch?.stats?.rally_stats?.[statsSet]?.team_1,
                    t2: statMatch?.stats?.rally_stats?.[statsSet]?.team_2,
                    doubles: isDoubles
                };
        
                return <RallyStats data={rally_stats_data} />;
    
            case 'dss':
                let dss_stats_data = {
                    names: getNames(),
                    t1: statMatch?.stats?.dss?.['match']?.team_1,
                    t2: statMatch?.stats?.dss?.['match']?.team_2,
                    doubles: isDoubles
                };

                return <DssStats data={dss_stats_data} />;
    
            default:
                let default_stats_data = {
                    names: getNames(),
                    t1: statMatch?.stats?.base_stats?.[statsSet]?.team_1,
                    t2: statMatch?.stats?.base_stats?.[statsSet]?.team_2,
                    d1: isEmpty(statMatch?.stats?.distance_run)
                        ? 'none'
                        : statMatch?.stats?.distance_run?.[statsSet]?.team_1,
                    d2: isEmpty(statMatch?.stats?.distance_run)
                        ? 'none'
                        : statMatch?.stats?.distance_run?.[statsSet]?.team_2,
                    unit: uom,
                    doubles: isDoubles
                };
        
                return <OverallStats data={default_stats_data} />;
        }
    }

    const onPageNavSelect = (val) => {

        /** find selected tab and apply measurement value */
        let metricsVal = val;
        let selectedTab = statsTabItems.find((item) => { return item.value == val});

        if(selectedTab) {
            metricsVal = selectedTab.measure
        }
        
        if (!values.webview) {
            measureInWeb({
                action: 'Summary',
                context: [{ pageTitle: 'Slamtracker' }, { tab: metricsVal }],
            });
        } else {
            measureInAppContext({
                pageTitle: 'Slamtracker',
                action: 'Sumary',
                args: [metricsVal],
                context: []
            });
        }

        updateStatsSet(val);
    }

    const onSelect = (val) => {
        // logger.log('[MatchStats] onSelect - val:%o', val);

        /** find selected tab and apply measurement value */
        let metricsVal = val;
        let selectedTab = statsDropdownItems?.options.find((item) => { return item.value == val});

        if(selectedTab) {
            metricsVal = selectedTab.name
        }
        
        if (!values.webview) {
            measureInWeb({
                action: 'Summary',
                context: [{ pageTitle: 'Slamtracker' }, { tab: metricsVal }],
            });
        } else {
            measureInAppContext({
                pageTitle: 'Slamtracker',
                action: 'Summary',
                args: ['Dropdown Selection', metricsVal],
                context: []
            });
        }

        setStatsType(val);
        setSelectOpen(false);
    }

    const onOpen = () => {
        // logger.log('[MatchStats] onOpen - val:%o, selectOpen:%o', val, selectOpen);
        if (selectOpen) {
            setSelectOpen(false);
        } else {
            measureInAppContext({
                pageTitle: 'Slamtracker',
                action: 'Summary',
                args: ['Dropdown Open'],
                context: []
            });
            setSelectOpen(true);
        }
    }

    return (
        <div className="slamtracker-matchstats-content">
            <PageNav
                menuItems={statsTabItems}
                classes={`slamtracker-matchstats-tabs ${statsType == 'dss' ? 'disabled' : ''}`}
                isActive={statsSet}
                clickFn={onPageNavSelect}
                noDD
            />
            <div className="scores-select-menu gray">
				<SelectMenu
					name="statsList"
					attributes={statsDropdownItems}
					selected={statsType}
					show={selectOpen}
				    onSelected={onSelect}
                    onOpen={onOpen}
				/>
			</div>
            <SlamtrackerToggle />
            
            { getStatsContent() }
        </div>
    )
};

export default MatchStats;
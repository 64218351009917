import deps from 'dependencies';
import op from 'object-path';

export default {
	mount: (data, routeId) => (dispatch, getState, store) => {
		logger.log('[ContentPageSecure] action.mount - routeId:%o data:%o', routeId, data);

		// Mount dispatch
		//dispatch({type: deps.actionTypes.CONTENT_MOUNT, data: data});

		// Get articles
		if (data.hasOwnProperty('landing') || data.hasOwnProperty('category')) {
			//dispatch({type: deps.actionTypes.CONTENT_LOADING});

			return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
				let path = '';
				let ids;
				let screen = {
					register: true,
					social: true,
				};

				//logger.log('[ContentPageSecure] action.mount 1 - data:%o', data);
				data.hospDates = op.get(Config, 'dateConfig.hospitality', '');
				data.mediaDate = op.get(Config, 'dateConfig.media', '');

				/*
				EXAMPLE DATA
				"content" is for generic/stub content like hospitality forms
				"page" is for CMS page content

				*/

				if (data.category != undefined) {
					data.landing = '';
					let categoryName = data.segment ? data.category + '_' + data.segment : data.category;
					//let dataId = (data.segment) ? data.id + '_' + data.segment : data.id;
					let dataId = data.id;
					let pageUrl = Config.auth.orch.page.replace('<section>', categoryName);
					let preview = '';
					if (data.prefix === 'preview') {
						preview = 'preview_';
					}

					logger.log(
						'[ContentPageSecure] action.mount - category:%o categoryName:%o dataId:%o',
						data.category,
						categoryName,
						dataId
					);
					//logger.log('[ContentPageSecure] action.mount - pageUrl:%o', pageUrl);

					switch (data.category) {
						case 'form':
							if (data.id == 'media_registration') {
								path = Config.auth.orch.content.replace('<type>', 'media');
							}
							ids = []; //andy said open to any myW user
							screen = {
								register: true,
								social: true,
							};
							break;
						case 'hospitality':
							path = pageUrl.replace('<type_id>', preview + data.category + '_' + data.id);
							if (data.id == 'suites') {
								path = Config.auth.orch.content.replace('<type>', data.category);
							} else {
								path = pageUrl.replace('<type_id>', preview + data.category + '_' + data.id);
							}
							ids = [1000030];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'media':
						case 'media_rightsholder':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_' + data.id);
							ids = [1000250];
							screen = {
								register: true,
								social: true,
							};
							break;
						case 'debentures':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_' + dataId);
							ids = [1000020];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'player':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_' + dataId);
							ids = [1000050, 1000320];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'community':
							categoryName = categoryName.replace('_t', '_tier')
							pageUrl = Config.auth.orch.page.replace('<section>', categoryName);
							path = pageUrl.replace('<type_id>', preview + categoryName + '_' + dataId);
							ids = [1000180];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'member':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_' + dataId);
							ids = [1000040];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'tournament':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_' + dataId);
							ids = [1000300];
							screen = {
								register: false,
								social: false,
							};
							break;
						default:
							break;
					}
					data.category = 's_' + data.category;
				} else if (data.landing != undefined) {
					data.category = '';
					let categoryName = data.segment ? data.landing + '_' + data.segment : data.landing;
					let pageUrl = Config.auth.orch.page.replace('<section>', categoryName);
					let preview = '';
					if (data.prefix === 'preview') {
						preview = 'preview_';
					}

					logger.log('[ContentPageSecure] action.mount - landing - data: %o', data);
					//logger.log('[ContentPageSecure] action.mount - pageUrl:%o', pageUrl);

					switch (data.landing) {
						case 'hospitality':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_landing');
							ids = [1000030];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'debentures':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_landing');
							ids = [1000020];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'community':
							categoryName = categoryName.replace('_t', '_tier')
							pageUrl = Config.auth.orch.page.replace('<section>', categoryName);
							path = pageUrl.replace('<type_id>', preview + categoryName + '_landing');
							ids = [1000180];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'media':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_landing');
							ids = [1000250];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'tournament':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_landing');
							ids = [1000300];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'player':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_landing');
							ids = [1000050];
							screen = {
								register: false,
								social: false,
							};
							break;
						case 'member':
							path = pageUrl.replace('<type_id>', preview + categoryName + '_landing');
							ids = [1000040];
							screen = {
								register: false,
								social: false,
							};
							break;
						default:
							break;
					}
					data.landing = 's_' + data.landing;
				}
				///relatedcontent/rest/gigya/hospitality/wim_2019_hospitality_landing_en

				data.dataUrl = path;
				data.roleIds = ids;
				data.screen = screen;

				logger.log('[ContentPageSecure] action.mount load - data: %o', data);
				dispatch({ type: deps.actionTypes.CONTENT_LOAD_SECURE, data: data });
			});
		} else {
			data.dataUrl = '/relatedcontent/gigya';

			logger.log('[ContentPageSecure] action.mount load related - data: %o', data);
			dispatch({ type: deps.actionTypes.CONTENT_LOAD_SECURE, data: data });
		}
	},

	unmount: () => (dispatch, getState, store) => {
		let data = {
			category: '',
			landing: '',
			dataUrl: '',
		};
		dispatch({ type: deps.actionTypes.CONTENT_UNMOUNT_SECURE, data: data });
	},
};

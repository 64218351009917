import DrawsArchiveSearch from './index';
import deps from 'dependencies';

export default {
	path: '/:lang/draws_archive/search.html:type?',
	exact: true,
	component: DrawsArchiveSearch,
	section: 'Draws',
	load: params => deps.actions.DrawsArchiveSearch.mount(params),
};

import { Component } from 'react';
import { logger } from './logger';
import scoringController from './scoring';
import queryString from 'querystring-browser';

// import { runInThisContext } from 'vm';

/*
TennisScoringData props

mqttBalance - balances between MessageSight (< value) and EdgeConnect (> value)

*/

export default class TennisScoringData extends Component {
	componentDidMount() {
		// logger.log('componentDidMount, props = %o', this.props);
		const search = document.location.search.replace(/^\?/, '');
		const parsedQs = queryString.parse(search);
		let mode = parsedQs.mode;
		let android_mode = 'ec';

		if (this.props.mode && this.props.mode == 'poll') {
			logger.log('componentDidMount, props.mode is poll so setting mode to poll');
			mode = 'poll';
		}

		if (this.props.androidMode) {
			android_mode = this.props.androidMode;
		}

		// logger.log('componentDidMount, mode:%o', mode);

		let configuration = {
			balance: mode == 'poll' ? 0 : this.props.balance,
			mqttBalance: mode == 'ms' ? 100 : mode == 'ec' ? 0 : this.props.mqttBalance,
			messageSightHostname: this.props.messageSightHostname,
			edgeConnectHostname: this.props.edgeConnectHostname,
			tokenCreator: this.props.tokenCreator,
			anonymous: this.props.anonymous,
			androidMode: android_mode,
			port: this.props.mqttPort,
			event: this.props.mqttEvent,
			year: this.props.tournamentYear,
			scoresPollingFile: this.props.scoresPollingFile,
			statsFile: this.props.statsFile,
			cFile: this.props.cFile,
			uFile: this.props.uFile,
			pollingFrequency: this.props.pollingFrequency,
			scoresUpdated: this.props.scoresUpdated,
			statsUpdated: this.props.statsUpdated,
			historyUpdated: this.props.historyUpdated,
			retryAttempts: this.props.retryAttempts,
			connectionReady: this.props.connectionReady,
		};

		logger.log('componentDidMount, init configuration:%o', configuration);
		scoringController.init(configuration);

		// logger.log('componentDidMount, init listener');
		scoringController.initListener();
	}

	render() {
		return null;
	}
}

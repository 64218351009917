export default {
	TOURNAMENT_MOUNT: 'TOURNAMENT_MOUNT',
	TOURNAMENT_UPDATE: 'TOURNAMENT_UPDATE',
	TOURNAMENT_ERROR: 'TOURNAMENT_ERROR',
	TOURNAMENT_LOADING: 'TOURNAMENT_LOADING',
	TOURNAMENT_CLEAR: 'TOURNAMENT_CLEAR',
	TOURNAMENT_CLEAR_SCHEDULE: 'TOURNAMENT_CLEAR_SCHEDULE',
	TOURNAMENT_CLEAR_RESULTS: 'TOURNAMENT_CLEAR_RESULTS',
	TOURNAMENT_CLEAR_DRAWS: 'TOURNAMENT_CLEAR_DRAWS'
};

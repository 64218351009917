import deps from 'dependencies';

export default {
	mount: term => (dispatch, getState, store) => {
		logger.log('[DrawsArchiveMenu] mount - term:%o', term);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const pathObjs = {
				playerSearchPath: Config.drawsArchive.search,
			};

			dispatch({ type: deps.actionTypes.DRAWSARCHIVE_MENU, data: pathObjs });
		});
	},
};

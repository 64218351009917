/**
 Config Initial State
*/

export default {
	data: {
		schedule: {
			days: [],
			courts: [],
		},
		scheduleScores: {
			days: [],
			courts: [],
		},
		live: {
			matches: [],
		},
		results: {
			matches: [],
		},
		resultDays: {},
		scheduleDays: {},
		draw: {},
		drawEvents: {},
		drawPath: {},
	},
};

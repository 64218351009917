/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

// components
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { passAppData } from 'appdir/components/general/Util';
import {
	updateCourtLabels,
	updateTicketLabels,
	// getVisitStatus,
	updateTicketDay,
	updateTicketHeader,
	updateTicketSort,
} from 'appdir/components/pages/Tickets/Data';

import isEmpty from 'lodash/isEmpty';
import { loggedIn } from 'appdir/components/general/Util/Role';
import Tickets from 'appdir/components/pages/Tickets';
import DayNavigator from 'appdir/components/pages/Tickets/elementsFunc/DayNavigator';
import TicketList from 'appdir/components/pages/Tickets/elementsFunc/TicketList';
import { getQuerystringParam } from 'appdir/components/general/Util';
import { getErrorMessage } from 'appdir/components/general/Util/Error';
import { checkTicketStatus } from 'appdir/components/pages/Tickets/ReusableTicketActionFuncs';
import { measureInAppContext } from 'appdir/components/general/Analytics';

import {
	FILTER_TRANSFERRED,
	FILTER_RECEIVED,
	FILTER_NORMAL,
	FILTER_RETURNED,
} from 'appdir/components/pages/Tickets/Data';

/**
 * -----------------------------------------------------------------------------
 * React Component: TicketsWebview
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		gigya: state['Gigya'],
		courts: op.get(state['Config'], 'ticketConfig.ticketCourts', null),
		ticketLabels: op.get(state['Config'], 'ticketLabels', null),
		types: op.get(state['Config'], 'ticketConfig.ticketTypes', null),
		text: op.get(state['Config'], 'ticketConfig.ticketText', null),
		terms: op.get(state['Config'], 'ticketConfig.ticketTerms', null),
		enableHistory: op.get(state['Config'], 'tickets.enableAppHistory', true),
		...state['TicketsWebview'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(actions.TicketsWebview.mount()),
	callSpectatorAll: (service, accounts) => dispatch(deps.actions.Tickets.callSpectatorAll(service, accounts)),
	callTicketActions: (service, id, data) => dispatch(deps.actions.Tickets.callTicketActions(service, id, data)),
	verifyTicketStatus: (service, id, data) => dispatch(deps.actions.Tickets.verifyTicketStatus(service, id, data)),
});

class TicketsWebview extends Component {
	constructor(props) {
		super(props);

		// logger.log('[TicketsWebview] constructor - props:%o', props);

		// now add all the props and filters to state
		this.state = {
			...props,
			selected: '',
			data: '',
			ticketDisplayOrg: '',
			hideDayNavigator: false,
		};
		this.firstLoad = true;
		//this.dataLoad = '';

		this.onSelectDate = this.onSelectDate.bind(this);
	}

	componentDidMount() {
		// logger.log('[TicketsWebview] componentDidMount - state:%o', this.state);

		this.debugView = getQuerystringParam(null, 'ticketDebug');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newUser = op.get(nextProps, 'gigya.currentUser', {});
		let prevUser = op.get(prevState, 'gigya.currentUser', {});
		let refreshData = false;

		logger.log('[TicketsWebview] getDerivedStateFromProps - nextProps:%o', nextProps);

		// if (!nextProps.ticketLabels) {
		// 	return null;
		// }

		return newUser.UID != prevUser.UID ||
			(op.get(nextProps, 'gigya.gigyaLoaded', false) && !op.get(prevState, 'gigya.gigyaLoaded', false))
			? {
					...prevState,
					...nextProps,
					lastUser: '',
					ticketData: [],
					ticketComplete: null,
					refreshData: refreshData,
			  }
			: null;
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'gigya.currentUser', {});

		logger.log(
			'[TicketsWebview] componentDidUpdate - dataLoad:%o, user:%o state:%o prev:%o',
			this.dataLoad,
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			prevState
		);

		if (loggedIn(currentUser) && this.firstLoad && this.state.ticketLabels) {
			logger.log('[Tickets] componentDidUpdate load data - state:%o', this.state);
			this.firstLoad = false;
			this.callTickets();
		}
	}

	/**
	 * format data for mobile app and pass
	 * only pass tickets with security info
	 */
	passTickets(tickets, serverTime, systemTime, error, ticketDataObj_logTickets) {
		// *add serverTime
		// #add dayLabel
		// #add dayNumber
		// *add ticketId
		// #figure out if user has valid tickets on today's day
		//   three states - pre-visit, visit, post-visit
		//     if server time is less than first ticket date = pre-visit
		//     if server time is > last ticket expire time = post-visit
		//     else = visit
		//   add fourth attribute to days passed
		// #typeLabel
		//   either name or Complimentary label
		// #price
		//   value or '' based on ticket label config
		// #send tickets in display order
		//   date, then court prestige order
		//   sort before returned from Data.js (services.js)

		// when tap Show Ticket
		//   pass ticket id and ticket date

		if (tickets.length == 0) {
			//return;
		}

		let displayTickets = [];
		// only send ticket data to app for tickets which need to be displayed, normal and receieved
		let validTickets = tickets.filter(ticket => {
			let found = [FILTER_NORMAL, FILTER_RECEIVED].indexOf(op.get(ticket, 'status.filtervalue', null)) > -1;
			return found;
		});

		let headerDesign = '';
		let court = '';
		let typeLabel = '';
		let gangway = '';
		//let visitStatus = getVisitStatus(validTickets, serverTime);

		validTickets.map(ticket => {
			headerDesign = ticket.headerDesign.toLowerCase();
			court = ticket.detailedInformation.court;
			gangway = ticket.detailedInformation.gangway.replace('Gangway ', '');

			//make specific adjustments for quals displays
			if (headerDesign == 'quals') {
				gangway = op.get(this, 'state.text.qualsLocation', '');
			}

			displayTickets.push({
				eventName: ticket.source.event.name,
				displayDay: `Day ${ticket.day}`,
				dayNumber: `${ticket.day}`,
				activationTime: op.get(ticket, 'source.activationParams.time', null),
				startTime: ticket.source.event.startTime,
				expireTime: ticket.source.event.expirationDate,
				court: court,
				stand: ticket.detailedInformation.stand,
				gangway: gangway,
				row: ticket.detailedInformation.row,
				seat: ticket.detailedInformation.seat,
				price: ticket.price.includes('GBP') ? `£${ticket.price.split(' ')[0]}` : ticket.price,
				type: ticket.label,
				typeLabel: ticket.headerLabel,
				typeColorBar: ticket.colorBar,
				typeColorTxt: ticket.colorTxt,
				typeColorBg: ticket.colorBg,
				typeMessage: ticket.message,
				ticketholder: ticket.spectatorName,
				security: ticket.source.security,
				ticketId: ticket.externalId,
				testTicket: ticket.testTicket,
				header: headerDesign,
				disableSwap: ticket.disableSwap,
				salesTerms:
					headerDesign.toLowerCase() != 'quals'
						? `https://${document.location.hostname}${this.state.terms.salesTerms}`
						: `https://${document.location.hostname}${this.state.terms.salesTermsQuals}`,
				entryTerms:
					headerDesign.toLowerCase() != 'quals'
						? `https://${document.location.hostname}${this.state.terms.entryTerms}`
						: `https://${document.location.hostname}${this.state.terms.entryTermsQuals}`,
			});
		});

		const appData = {
			type: 'tickets_data',
			source: 'ibm_web',
			data: {
				serverTime: serverTime,
				tickets: displayTickets,
				error: error,
			},
		};
		logger.log('[TicketsWebview] passTickets - appData:%o', appData);
		passAppData(appData);

		// get default day (default day should be decided by the serverTime value returned in callTickets)
		let filteredDay;
		let ticketDisplayOrg = this.state.ticketDisplayOrg || ticketDataObj_logTickets;
		if (serverTime && ticketDisplayOrg) {
			filteredDay = Object.keys(ticketDisplayOrg).find(key => {
				let ttime = key.split('-')[0];
				let stime = moment
					.unix(serverTime)
					.utc()
					.startOf('day')
					.valueOf()
					.toString();
				let eq = ttime === stime;
				//logger.log('[TicketsWebview] passTickets - ttime:%o stime:%o eq:%o', ttime, stime, eq);
				return eq;
			});
			// check to make sure selected isnt already set, if it is, dont update
			if (filteredDay && !this.state.selected) {
				this.setState({ selected: filteredDay, filteredDay });
			}
		}

		this.setState({ data: displayTickets });
	}

	/**
	 * create a debug view of the tickets
	 */
	logTickets(tickets) {
		let displayTickets = [];

		let ticketDatesObj = {};

		tickets.map(ticket => {
			let activationTime = op.get(ticket, 'source.activationParams.time', 0);
			if (activationTime != 0) {
				activationTime = moment(activationTime)
					.tz('Europe/London')
					.format('MM/DD/YYYY h:mm:ss');
			}
			let expireTime = moment(ticket.source.event.expirationDate)
				.tz('Europe/London')
				.format('MM/DD/YYYY h:mm:ss');

			displayTickets.push({
				test: ticket.testTicket ? 'true' : '',
				spectatorId: ticket.spectatorId,
				day: `Day ${ticket.day} - ${moment(ticket.source.event.startTime)
					.tz('Europe/London')
					.format('MM/DD/YYYY')}`,
				activationTime: activationTime,
				expireTime: expireTime,
				court: ticket.detailedInformation.court,
				stand: ticket.detailedInformation.stand,
				gangway: ticket.detailedInformation.gangway.replace('Gangway ', ''),
				row: ticket.detailedInformation.row,
				seat: ticket.detailedInformation.seat,
				price: ticket.price.includes('GBP') ? `£${ticket.price.split(' ')[0]}` : ticket.price,
				typeLabel: ticket.typeLabel,
				filter: op.get(ticket, 'status.filtervalue', null),
				ticketholder: ticket.spectatorName,
				ticketId: ticket.externalId,
				disableSwap: ticket.disableSwap,
			});

			// organize tickets to display in view - set date as ugp for Members Ground Passes (no date)
			const startEpoch = moment(ticket.source.event.startTime)
				.utc()
				//.tz('Europe/London')
				//.tz('America/New_York')
				.startOf('day')
				.valueOf();
			const currTicketDate = ticket.day === 'UGP' ? 'ugp-UGP' : `${startEpoch}-${ticket.day}`;
			const currTicketCourt = ticket.detailedInformation.court;

			// logger.log(
			// 	'[TicketsWebview] logTickets start:%o currTicketDate:%o',
			// 	ticket.source.event.startTime,
			// 	currTicketDate
			// );

			if (ticketDatesObj[currTicketDate] && ticketDatesObj[currTicketDate][currTicketCourt]) {
				const currObjVal = ticketDatesObj[currTicketDate][currTicketCourt];
				ticketDatesObj[currTicketDate] = {
					...ticketDatesObj[currTicketDate],
					[currTicketCourt]: [...currObjVal, ticket],
				};
			} else {
				ticketDatesObj[currTicketDate] = { ...ticketDatesObj[currTicketDate], [currTicketCourt]: [ticket] };
			}
		});

		logger.table('[TicketsWebview] logTickets:', displayTickets);

		this.setState({ ticketDisplayOrg: ticketDatesObj });
		return ticketDatesObj;
	}

	/**
	 * call ticket API
	 */
	callTickets() {
		logger.log('[TicketsWebview] callTickets - state:%o', this.state);
		this.setState({
			refreshData: true,
			errorCode: null,
		});

		this.callTicketsAPI();
	}

	callTicketsAPI() {
		let service = this.state.enableHistory ? 'view' : 'app';
		this.props
			.callSpectatorAll(service, Tickets.getSpectatorAccounts(this.state))
			.then(data => {
				//filter tickets that don't need in webview
				logger.log('[TicketsWebview] callTickets - data.tickets:%o', data.tickets);
				logger.log('[TicketsWebview] callTickets - data:%o', data);

				let webviewTickets = data.tickets;
				// let webviewTickets = data.tickets.filter(ticket => {
				// 	let found =
				// 		[FILTER_TRANSFERRED, FILTER_RETURNED].indexOf(op.get(ticket, 'status.filtervalue', null)) < 0;
				// 	return found;
				// });
				// logger.log('[TicketsWebview] callTickets - data:%o', webviewTickets);

				webviewTickets.map(value => {
					let prestigeType;
					this.props.courts.map(courtVal => {
						prestigeType =
							courtVal.code === value.detailedInformation.courtCode ? courtVal.prestige : prestigeType;
					});
					value.prestige = prestigeType;
				});

				data.tickets = updateTicketLabels(webviewTickets, this.state.ticketLabels);
				data.tickets = updateCourtLabels(webviewTickets, this.state.courts);
				data.tickets = updateTicketDay(webviewTickets, this.state.tournStart, this.state.qualStart);
				data.tickets = updateTicketHeader(webviewTickets, this.state.ticketLabels, this.state.types);
				webviewTickets = updateTicketSort(webviewTickets);

				let error = data?.error?.code
					? data.error.code
					: data.error[0] && data.error[0].reason.code
					? data.error[0].reason.code
					: null;

				// filter swappable tickets
				const swappableTickets = data?.tickets?.filter(item => {
					return item?.actions?.includes('swap');
				});
				if (swappableTickets.length) {
					checkTicketStatus(swappableTickets, this.props.verifyTicketStatus, (resp, err) => {
						let exitedTix = this.onVerifySwap(resp, err);
						if (exitedTix) {
							webviewTickets = webviewTickets.map(webviewTicket => {
								return { ...webviewTicket, disableSwap: exitedTix.includes(webviewTicket.externalId) };
							});
						}
						let ticketDataObj = this.logTickets(webviewTickets);
						this.passTickets(webviewTickets, data.serverTime, data.systemTime, error, ticketDataObj);
						this.setState({
							ticketData: webviewTickets,
							ticketComplete: data.complete,
							time: {
								serverTime: data.serverTime,
								systemTime: data.systemTime,
							},
							refreshData: false,
							errorCode: error,
						});
					});
				} else {
					let ticketDataObj = this.logTickets(webviewTickets);
					this.passTickets(webviewTickets, data.serverTime, data.systemTime, error, ticketDataObj);
					this.setState({
						ticketData: webviewTickets,
						ticketComplete: data.complete,
						time: {
							serverTime: data.serverTime,
							systemTime: data.systemTime,
						},
						refreshData: false,
						errorCode: error,
					});
				}
			})
			.catch(error => {
				logger.error('[Tickets] callTickets - error:%o', error);
				this.setState({
					ticketData: [],
					ticketComplete: null,
					time: {
						serverTime: null,
						systemTime: null,
					},
					refreshData: false,
					errorCode: error?.code,
					error: error,
				});
			});
	}

	onVerifySwap = (response, error) => {
		if (error) {
			// display error
			// TODO: find out how errors should be handled
		}

		let filteredIds = [];
		response?.data?.response?.filter(value => {
			if (op.get(value, 'scanType', null)?.includes('Exit')) {
				filteredIds.push(value.ticketId);
			}
			return filteredIds;
		});
		return filteredIds;
	};

	onSelectDate = value => {
		this.setState({ selected: value });
	};

	render() {
		let currentUser = op.get(this.state, 'gigya.currentUser', {});
		//logger.log('[TicketsWebview] render - this:%o', this);
		logger.log('[TicketsWebview] render - state:%o', this.state);

		if (this.state.gigya.gigyaLoaded && !loggedIn(currentUser)) {
			return (
				<section
					className={this.debugView ? 'ticketDebugWebViewBackground wrapper webview' : 'wrapper webview'}>
					<div className="ticketsWebview-message">
						<div className="ticketsWebview-message-txt">
							We are unable to locate your user information, please go back and try again.
						</div>
					</div>
				</section>
			);
		} else if (!this.state.refreshData) {
			// if not loading data and there is an errorCode
			if (this.state.errorCode) {
				//logger.log('[TicketsWebview] render - error:%o', this.state.error);
				logger.log('[TicketsWebview] render - errorCode:%o', this.state.errorCode);
				return (
					<div
						className={
							this.debugView
								? 'ticketDebugWebViewBackground four-col h3 bottom-margin ticketsWebview-message '
								: 'content-main four-col four-col h3 bottom-margin ticketsWebview-message'
						}>
						{getErrorMessage(
							this.state.errorCode,
							() => {
								this.callTickets();
							},
							'ticketsWebview-err-msg'
						)}
					</div>
				);
			}

			// if not loading data and have ticketData
			else if (op.get(this, 'state.ticketData', []).length > 0) {
				return (
					<section
						className={this.debugView ? 'ticketDebugWebViewBackground wrapper webview' : 'wrapper webview'}>
						<DayNavigator
							tickets={this.state.ticketData}
							time={this.state.time}
							currentSelected={this.state.selected}
							onSelectDate={this.onSelectDate}
							tournStart={this.state.tournStart}
							serverTime={
								this.state.time && this.state.time.serverTime
									? moment.unix(this.state.time.serverTime).valueOf()
									: ''
							}
							filteredDay={this.state.filteredDay}
							onTabIndexUpdate={value => this.setState({ tabIndexEnabled: value })}
							tabIndexEnabled={this.state.tabIndexEnabled}
							hideDisplay={this.state.hideDayNavigator}
						/>
						<TicketList
							tickets={this.state.ticketData}
							selected={this.state.selected}
							ticketDisplayOrg={this.state.ticketDisplayOrg}
							onRefreshData={() => {
								this.callTickets();
							}}
							onSoftRefreshData={() => this.callTicketsAPI()}
							debugView={this.debugView}
							callTicketActions={(service, id, data) => this.props.callTicketActions(service, id, data)}
							onHideDayNavigator={val => this.setState({ hideDayNavigator: val })}
						/>
					</section>
				);
			}

			// if not ticketData and ticket status is complete
			else if (isEmpty(this.state.ticketData) && this.state.ticketComplete) {
				// if no ticket data, but data load is complete and status.complete is true, show new message about no tickets found
				return (
					<section
						className={this.debugView ? 'ticketDebugWebViewBackground wrapper webview' : 'wrapper webview'}>
						<div className="ticketsWebview-message">
							<div className="ticketsWebview-message-txt">
								There are no tickets associated with this account.
								<br />
								You may&nbsp;
								<strong>
									<button
										className="ticketsWebview-message-btn"
										onClick={event => {
											event.preventDefault();
											this.callTickets();
										}}>
										click here
									</button>
								</strong>{' '}
								to refresh your view.
							</div>
						</div>
					</section>
				);
			}

			// if no ticket data and ticket status is not complete
			else if (
				// if no ticket data, but data load is complete and status.complete is false, show message
				isEmpty(this.state.ticketData) &&
				!this.state.ticketComplete &&
				typeof this.state.ticketComplete === 'boolean'
			) {
				return (
					<section
						className={this.debugView ? 'ticketDebugWebViewBackground wrapper webview' : 'wrapper webview'}>
						<div className="ticketsWebview-message">
							<div className="ticketsWebview-message-txt">
								It can take a few minutes for newly assigned tickets to be available.
								<br />
								You may&nbsp;
								<strong>
									<button
										className="ticketsWebview-message-btn"
										onClick={event => {
											event.preventDefault();
											this.callTickets();
										}}>
										click here
									</button>
								</strong>{' '}
								to refresh your view.
							</div>
						</div>
					</section>
				);
			} else {
				return (
					<section
						className={this.debugView ? 'ticketDebugWebViewBackground wrapper webview' : 'wrapper webview'}>
						<div className="content-main">
							<LoadingIndicator type={'white'} showTxt="One moment please, loading your tickets" />
						</div>
					</section>
				);
			}

			// refreshData is true (loading) or anything else
		} else {
			return (
				<section
					className={this.debugView ? 'ticketDebugWebViewBackground wrapper webview' : 'wrapper webview'}>
					<div className="content-main">
						<LoadingIndicator type={'white'} showTxt="One moment please, loading your tickets" />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketsWebview);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Inline from './Inline';
import Popup from './Popup';
import deps from 'dependencies';
import op from 'object-path';
import WimLink from 'appdir/components/general/WimLink';
import { withRouter } from 'react-router';

const ChampionshipsRegistration = props => {
	const Gigya = useSelector(state => state['Gigya']);
	const Controller = useSelector(state => state['Controller']);
	const Config = useSelector(state => state['Config']);
	const dispatch = useDispatch();
	const [state, setState] = useState({
		playerData: null,
		type: props.type,
		hidePopup: op.get(Controller, 'championshipsRegistration.viewed', false),
	});

	const toggleClose = () => {
		const pdeYear = Config.pde.pdeYear;
		dispatch({
			type: deps.actionTypes.CONTROLLER_UPDATE,
			data: { championshipsRegistration: { viewed: pdeYear } },
		});
	};

	const renderMainDraws = () => {
		let mainRegistration = op.get(
			state,
			'playerData.contactDetails.championshipsRegistration.mainRegistration',
			null
		);
		//const { mainRegistration } = state['playerData']['contactDetails']['championshipsRegistration'];
		if (mainRegistration != null) {
			return renderSection(mainRegistration, 'Main Draw & Qualifying');
		} else {
			return null;
		}
	};

	const renderJuniors = props => {
		let juniorRegistration = op.get(
			state,
			'playerData.contactDetails.championshipsRegistration.juniorRegistration',
			null
		);
		if (juniorRegistration != null) {
			return renderSection(juniorRegistration, 'Juniors');
		} else {
			return null;
		}
	};

	const renderWheelchairs = props => {
		let wheelchairRegistration = op.get(
			state,
			'playerData.contactDetails.championshipsRegistration.wheelchairRegistration',
			null
		);
		if (wheelchairRegistration != null) {
			return renderSection(wheelchairRegistration, 'Wheelchairs');
		} else {
			return null;
		}
	};

	const getLinkType = type => {
		let linkType = '';
		switch (type) {
			case 'Main Draw & Qualifying':
				linkType = 'main';
				break;
			case 'Juniors':
				linkType = 'junior';
				break;
			case 'Wheelchairs':
				linkType = 'wheelchair';
				break;
			default:
				break;
		}

		return linkType;
	};

	const renderSection = (status, type) => {
		let renderHTML = null;
		logger.log('[ChampionshipRegistration] Config:%o', Config);
		if (op.get(Config, 'pde.registrationLinks', null) !== null) {
			const { completeRegistration } = Config.pde.registrationLinks;
			let linkType = getLinkType(type);
			switch (status) {
				case 'register':
					renderHTML = (
						<div className="registration-section">
							<div className="registration-type-header">{capitalize(type)}</div>
							<div className="registration-type-message">
								Please{' '}
								<WimLink to={`${completeRegistration}/${linkType}`} external={true} target="_self">
									click here
								</WimLink>{' '}
								to complete your Championships Registration - this is a{' '}
								<span className="bold">mandatory requirement</span> before you arrive at The
								Championships.
							</div>
						</div>
					);
					break;
				case null:
					break;
				case 'guardianRequired':
					renderHTML = (
						<div className="registration-section">
							<div className="registration-type-header">{capitalize(type)}</div>
							<div className="registration-type-message">
								As you are under 18 years old, it is a legal requirement that your guardian, as well
								yourself, complete the Championships Registration. Please{' '}
								<WimLink to={`${completeRegistration}/${linkType}`} external={true} target="_self">
									click here
								</WimLink>{' '}
								to nominate your guardian and provide their contact details.
							</div>
						</div>
					);
					break;
				case 'guardianSelected':
					renderHTML = (
						<div className="registration-section">
							<div className="registration-type-header">{capitalize(type)}</div>
							<div className="registration-type-message">
								As soon as your guardian completes their part in the Championships Registration you will
								be able to{' '}
								<WimLink to={`${completeRegistration}/${linkType}`} external={true} target="_self">
									click here
								</WimLink>{' '}
								to finish this process.
								<span className="bold">Your guardian has not yet started this.</span>
							</div>
						</div>
					);
					break;
				case 'complete':
					break;
				default:
					break;
			}
		}
		return renderHTML;
	};

	const capitalize = s => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	const renderRegistrationSections = () => {
		return (
			<>
				{renderMainDraws()}
				{renderJuniors()}
				{renderWheelchairs()}
			</>
		);
	};

	const playerPage = () => {
		let playerLandingPageURLS = [
			'/s/player/main_draw.html',
			'/s/player/juniors.html',
			'/s/player/qualifying.html',
			'/s/player/wheelchairs.html',
		];
		let pageURL = props.location.pathname;
		for (let i = 0; i < playerLandingPageURLS.length; i++) {
			let currURL = playerLandingPageURLS[i];
			if (pageURL.indexOf(currURL) !== -1) {
				return true;
			}
		}
		return false;
	};

	const hideRegistration = () => {
		const mainRegistration = op.get(
			state,
			'playerData.contactDetails.championshipsRegistration.mainRegistration',
			null
		);
		const juniorRegistration = op.get(
			state,
			'playerData.contactDetails.championshipsRegistration.juniorRegistration',
			null
		);
		const wheelchairRegistration = op.get(
			state,
			'playerData.contactDetails.championshipsRegistration.wheelchairRegistration',
			null
		);
		const stubPromptConfig = op.get(Config, 'stubPages.pde.registration.stub', false);
		return (
			((mainRegistration === 'complete' || mainRegistration === null) &&
				(juniorRegistration === 'complete' || juniorRegistration === null) &&
				(wheelchairRegistration === 'complete' || wheelchairRegistration === null)) ||
			stubPromptConfig
		);
	};

	useEffect(() => {
		let playerRoles = op.get(Gigya, 'currentUser.roles', null);
		if (playerRoles !== null) {
			let playerCred = playerRoles.find(ele => ele.key === 'player');
			if (playerCred) {
				setState({
					...state,
					playerData: playerCred,
				});
			}
		}
	}, [Gigya['currentUser']]);

	useEffect(() => {
		const pdeYear = op.get(Config, 'pde.pdeYear', false);
		let popUpFlag = op.get(Controller, 'championshipsRegistration.viewed', false);
		if (pdeYear != popUpFlag) popUpFlag = false;

		if (popUpFlag != state.hidePopup) {
			setState({
				...state,
				hidePopup: popUpFlag,
			});
		}
	}, [Controller, Config]);

	return (
		op.get(state, 'playerData.contactDetails.championshipsRegistration', null) !== null &&
		(props.type === 'inline' && !hideRegistration() ? (
			<>
				<Inline>
					<div className="registration-banner">Please Complete Your Registration</div>
					<div className="registration-content">
						<i className="wim-icon-updates" tabIndex={0}></i>
						<div className="registration-notifcation">Attention</div>
						<div className="registration-header">Championships Registration</div>
						<hr className="registration-header-underline" />
						{renderRegistrationSections()}
					</div>
				</Inline>
				<div className="registration-border"></div>
			</>
		) : (
			!state.hidePopup &&
			!playerPage() &&
			!hideRegistration() && (
				<Popup>
					<div className="registration-banner">Please Complete Your Registration</div>
					<div className="registration-content">
						<i
							className="wim-icon-close"
							onClick={() => toggleClose()}
							onKeyPress={() => (event.key === 'Enter' ? toggleClose() : null)}
							tabIndex={0}></i>
						<i className="wim-icon-updates" tabIndex={0}></i>
						<div className="registration-notifcation">Attention</div>
						<div className="registration-header">Championships Registration</div>
						<hr className="registration-header-underline" />
						{renderRegistrationSections()}
					</div>
				</Popup>
			)
		))
	);
};

export default withRouter(ChampionshipsRegistration);

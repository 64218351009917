import React from 'react';
import { measureInAppContext } from 'appdir/components/general/Analytics';

export const getErrorMessage = (code, onClickFunc, classname) => {
	switch (code) {
		case 'E2010':
			return (
				<div className="two-col margin">
					<div>We could not validate your myWimbledon account, please logout, then login again to retry.</div>
					<div className="error-code">{`Code: ${code}`}</div>
				</div>
			);
			break;
		case 'E3020':
		case 'E3050':
			return (
				<>
					<div className={classname}>
						There has been an issue loading your tickets, please{' '}
						<a href="#" onClick={() => onClickFunc()}>
							try again
						</a>
						.
					</div>
					<div className="error-code">{`Code: ${code}`}</div>
				</>
			);
			break;
		case 'E3201':
			return (
				<>
					<div className={classname}>
						There has been an issue loading your tickets, please{' '}
						<a href="#" onClick={() => onClickFunc()}>
							try again
						</a>{' '}
						in a few minutes.
					</div>
					<div className="error-code">{`Code: ${code}`}</div>
				</>
			);
			break;
		default:
			return (
				<div className="two-col margin">
					<div>There has been an error. Please try again.</div>
					<div className="error-code">{`Code: ${code}`}</div>
				</div>
			)
			;
	}
};


export const getErrorText = (code, errorPage) => {
	if (errorPage) {
		measureInAppContext({
			pageTitle:"Ticket Resale Queue",
			action: "Unknown Error",
			context: [{
				errorPage:code,
			}],
			metricType: "state",
		});
	}
	switch (code) {
		case 'E2010':
			return `We could not validate your myWimbledon account, please logout, then login again to retry. Code: ${code}`;
		case 'E3020':
		case 'E3050':
			return `There has been an issue loading your tickets, please try again. Code: ${code}`;
		case 'E3201':
			return `There has been an issue loading your tickets, please try again in a few minutes. Code: ${code}`;
		case 'invalid_role':
			return "We are unable to locate your user information, please go back and try again."
		case 'ERR_NETWORK':
			return "There has been an error processing your request. Please check your connection and try again."
		case '-32002':
		case -32002:
			return `There has been an error processing your request, please refresh and try again`;
		default:
			return code ? `There has been an error. Please try again. Code: ${code}` : `There has been an error. Please try again`;
	}
};

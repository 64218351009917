import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Button from 'appdir/components/common-ui/Button';
import TicketTable from './TicketTable';

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => ({
	openModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class Debentures extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}

	componentDidMount() {}

	componentDidUpdate() {}

	getLocation(abrev) {
		const courtLookup = {
			CC: 'CENTRE COURT',
			N1: 'No 1 COURT',
		};
		return courtLookup[abrev.toUpperCase()];
	}

	extractYears(data) {
		return data
			.split('-')
			.map((d, i) => {
				return `20${d.match(/\d+/g)}`;
			})
			.join('-');
	}

	debentureDetail() {
		return (
			<Fragment>
				<div className="line four-col clear-four" />

				<div className="three-col margin-col">
					<div className="info-container">
						<div className="header">DEBENTURE TICKET INFORMATION</div>
						{this.state.data.officialMarketMessages.length > 0
							? this.state.data.officialMarketMessages.map((m, i) => {
									return (
										<div key={`message${i}`} className="info-flex">
											{m}
										</div>
									);
							  })
							: null}

						{this.state.data.officialMarketButtons.length > 0
							? this.state.data.officialMarketButtons.map((b, i) => {
									return (
										<Button
											className={`btn solid auto`}
											to={b.url}
											external={true}
											target={'_self'}>
											{b.text}
										</Button>
									);
							  })
							: null}
					</div>
				</div>

				{this.state.data.officialMarketMessages.length == 0 ? (
					this.state.data.tickets ? (
						this.state.data.allocatedSeats ? (
							<TicketTable
								ticketData={this.state.data.tickets}
								seats={true}
								seatData={this.state.data.allocatedSeats}
							/>
						) : (
							<TicketTable ticketData={this.state.data.tickets} seats={false} />
						)
					) : null
				) : null}
			</Fragment>
		);
	}

	render() {
		logger.log('[Debentures] render - version:%o', this.state.version);
		// logger.log('[Debentures] render - state:%o', this.state);
		return (
			<div className="debenture-container">
				<div className="three-col margin-col">
					<div className="info-container">
						<div className="header">INDEXNAME</div>
						<div className="info-detail">
							<div>{this.state.data.index}</div>
						</div>
					</div>
				</div>
				<div className="one-col margin-col">
					<div className="info-container">
						<div className="header">REGISTERED ADDRESS</div>
						<div className="info-detail">
							<div>{this.state.data.registeredHolderName}</div>
							{this.state.data.registeredHolderAddressLine1 ? (
								<div>{this.state.data.registeredHolderAddressLine1}</div>
							) : null}
							{this.state.data.registeredHolderAddressLine2 ? (
								<div>{this.state.data.registeredHolderAddressLine2}</div>
							) : null}
							{this.state.data.registeredHolderAddressLine3 ? (
								<div>{this.state.data.registeredHolderAddressLine3}</div>
							) : null}
							{this.state.data.registeredHolderAddressLine4 ? (
								<div>{this.state.data.registeredHolderAddressLine4}</div>
							) : null}
							{this.state.data.registeredHolderAddressLine5 ? (
								<div>{this.state.data.registeredHolderAddressLine5}</div>
							) : null}
							{this.state.data.registeredHolderCountry} {this.state.data.registeredHolderPostcode}
						</div>
					</div>
				</div>
				<div className="one-col">
					<div className="header">CONTACT DETAILS</div>
					{this.state.data.telephone && this.state.data.telephone !== '' ? (
						<span>
							<div className="sub-header">TELEPHONE</div>
							<div className="sub-detail">{this.state.data.telephone}</div>
						</span>
					) : null}
					{this.state.data.mobile && this.state.data.mobile !== '' ? (
						<span>
							<div className="sub-header">MOBILE PHONE</div>
							<div className="sub-detail">{this.state.data.mobile}</div>
						</span>
					) : null}
					{this.state.data.primaryEmailAddress && this.state.data.primaryEmailAddress !== '' ? (
						<span>
							<div className="sub-header">PRIMARY EMAIL</div>
							<div className="sub-detail">{this.state.data.primaryEmailAddress}</div>
						</span>
					) : null}
					{this.state.data.secondaryEmailAddress && this.state.data.secondaryEmailAddres !== '' ? (
						<span>
							<div className="sub-header">SECONDARY EMAIL</div>
							<div className="sub-detail">{this.state.data.secondaryEmailAddress}</div>
						</span>
					) : null}
				</div>

				<div className="one-col margin-col clear-two clear-four">
					<div className="info-container">
						<div className="header">POSTAL ADDRESS</div>
						<div className="info-detail">
							<div>{this.state.data.payeeName}</div>
							{this.state.data.payeeAddressLine1 ? <div>{this.state.data.payeeAddressLine1}</div> : null}
							{this.state.data.payeeAddressLine2 ? <div>{this.state.data.payeeAddressLine2}</div> : null}
							{this.state.data.payeeAddressLine3 ? <div>{this.state.data.payeeAddressLine3}</div> : null}
							{this.state.data.payeeAddressLine4 ? <div>{this.state.data.payeeAddressLine4}</div> : null}
							{this.state.data.payeeAddressLine5 ? <div>{this.state.data.payeeAddressLine5}</div> : null}
							<div>
								{this.state.data.payeeCountry} {this.state.data.payeePostcode}
							</div>
						</div>
					</div>
				</div>
				<div className="one-col">
					<div className="info-container">
						<div className="header">TICKETING ADDRESS</div>
						<div className="info-detail">
							<div>{this.state.data.ticketAddressName}</div>
							{this.state.data.ticketAddressLine1 ? (
								<div>{this.state.data.ticketAddressLine1}</div>
							) : null}
							{this.state.data.ticketAddressLine2 ? (
								<div>{this.state.data.ticketAddressLine2}</div>
							) : null}
							{this.state.data.ticketAddressLine3 ? (
								<div>{this.state.data.ticketAddressLine3}</div>
							) : null}
							{this.state.data.ticketAddressLine4 ? (
								<div>{this.state.data.ticketAddressLine4}</div>
							) : null}
							{this.state.data.ticketAddressLine5 ? (
								<div>{this.state.data.ticketAddressLine5}</div>
							) : null}
							{this.state.data.ticketAddressCountry} {this.state.data.ticketAddressPostcode}
						</div>
					</div>
				</div>

				<div className="line four-col clear-four" />
				<div className="three-col margin-col">
					<div className="info-container">
						<div className="header">CURRENT DEBENTURES HELD</div>
						{this.state.data.debentureHoldings.map((d, i) => {
							let count = d.debentures.length;
							let debStr = '';

							for (let i = 0; i < d.debentures.length; i++) {
								debStr += d.debentures[i];
								if (i < d.debentures.length - 1) {
									debStr += ', ';
								}
							}

							return (
								<div key={i} className="info-flex">
									<span className="court-location">{this.getLocation(d.type)}</span>
									<span className="years">{this.extractYears(d.series)}</span>
									<span className="issued">{`${count} ISSUED:`}</span>
									<span className="list">{debStr}</span>
								</div>
							);
						})}
					</div>
				</div>

				{this.state.version >= 2 ? this.debentureDetail() : null}
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Debentures);

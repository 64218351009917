/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import { Redirect } from 'react-router-dom';
import VideoPlayer from 'appdir/components/general/VideoPlayer';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoWrapper
 *
 * Wraps video player for vod video tiles and video headers
 * provides thumb and play buttons
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['VideoPlayer'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.VideoPlayer.mount()),
});

class VideoWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.onEvent = this.onEvent.bind(this);
		this.onVideoEvent = this.onVideoEvent.bind(this);
		this.onPlayerReady = this.onPlayerReady.bind(this);
		this.onUserAction = this.onUserAction.bind(this);

		this.name = 'VideoWrapper';
		this.playInline = true;
	}

	componentDidMount() {
		this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log(`${this.name} componentWillReceiveProps - state:%o next:%o`, this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log(`${this.name} componentDidUpdate - state:%o`, this.state);

		//force reset when video attributes change
		//  by setting videoId to '', thumb and play button render
		//  click on thumb sets videoId and video autoplays
		//  if set new videoId here, VideoPlayer rerenders and autoplays
		if (prevState.videoId && this.state.attributes.cmsId != prevState.videoId && prevState.videoId != '') {
			this.setState({
				videoId: '',
			});
		}
		if (this.state.attributes.loadVideo !== prevState.attributes.loadVideo && this.state.attributes.loadVideo) {
			this.onClick();
		}
	}

	componentWillUnmount() {}

	/**
	 * handle clicks from news tile
	 */
	onClick() {
		logger.log('[VideoWrapper] onClick - playInline:%o state:%o', this.playInline, this.state);

		if (this.playInline) {
			//this.props.play(this.state.attributes.cmsId);
			this.setState({
				videoId: this.state.attributes.cmsId ? this.state.attributes.cmsId : this.state.attributes.contentId,
			});
		} else {
			this.setState({
				nav: this.state.attributes.url,
			});
		}
	}

	onEvent(event) {
		//logger.info('[VideoWrapper] onEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				videoId: '',
			});
		}
	}

	onUserAction(event) {
		//logger.info('[VideoWrapper] onVideoEvent - event:%o', event);
		if (this.props.onUserAction) {
			this.props.onUserAction(event);
		}
	}

	onVideoEvent(event) {
		//logger.info('[VideoWrapper] onVideoEvent - event:%o', event);
		if (this.props.onVideoEvent) {
			this.props.onVideoEvent(event);
		}
	}

	onPlayerReady(player) {
		//logger.info('[VideoWrapper] onPlayerReady - props:%o', this.props);
		//logger.info('[VideoWrapper] onPlayerReady - player:%o', player);
		if (this.props.onReady) {
			this.props.onReady(player);
		}
	}

	render() {
		//logger.log(`${this.name} render - title:% state:%o`, this.state.attributes.title, this.state);
		//logger.log(`${this.name} render - videoId:%o`, this.state.videoId);
		logger.log(`${this.name} videoWrapper render - state:%o`, this.state);
		let thumbImg = this.state.attributes.thumb;

		if (this.state.nav) {
			return <Redirect to={this.state.nav} />;
		} else if (this.state.type == 'header' && this.state.videoId) {
			return (
				<div className="video-wrapper-header">
					<div className="video-wrapper">
						<img className="video-thumb" src={thumbImg} alt="video thumb" />
						<VideoPlayer
							key={this.state.videoId}
							attributes={this.state.attributes}
							onEvent={this.onEvent}
						/>
					</div>
				</div>
			);
		} else if (this.state.videoId) {
			return (
				<div className="video-wrapper">
					<img className="video-thumb" src={thumbImg} alt="video thumb" />
					<VideoPlayer
						key={this.state.videoId}
						attributes={this.state.attributes}
						onEvent={this.onEvent}
						onReady={this.onPlayerReady}
						onVideoEvent={this.onVideoEvent}
						onUserAction={this.onUserAction}
					/>
				</div>
			);
		} else if (this.state.type == 'header') {
			return (
				<span
					className="news--header-videoLinkButton"
					onClick={() => {
						this.onClick();
					}}>
					Play Full Video
					<i className="wim-icon-video" />
				</span>
			);
		} else {
			return (
				<div
					className="video-wrapper"
					onClick={() => {
						this.onClick();
					}}>
					<img className="video-thumb" src={thumbImg} alt="video thumb" />
					<i className="wim-icon-video" />
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoWrapper);

import React, { Component } from 'react';
import { values } from 'appdir/main';
import PlayerImage from 'appdir/components/content/PlayerImage';
import deps from 'dependencies';
import { connect } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import WimLink from 'appdir/components/general/WimLink';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['PlayerProfile'], props);
};

const mapDispatchToProps = (dispatch, props) => ({});

class PlayerArchiveInfo extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
		this.player_match = false;
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[PlayerArchiveInfo] componentWillReceiveProps - state:%o', this.state);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	renderPDF(playerInfo) {
		let playerId = playerInfo.id;
		const pdfPath = `${this.state.player.archiveDrawsAssetsPath}pdfs/players/`;

		return (
			<div className="pdfLinks">
				{playerInfo.events_archive.map((item, i) => {
					switch (item.eventId) {
						case 'MS':
							return (
								<WimLink
									to={`${pdfPath}${playerId}_MS.pdf`}
									external={true}
									measure={'Archive Record PDF'}
									key={`${i}+'1'`}>
									View complete Singles record (PDF)
									<br />
								</WimLink>
							);

						case 'LS':
							return (
								<WimLink
									to={`${pdfPath}${playerId}_LS.pdf`}
									external={true}
									measure={'Archive Record PDF'}
									key={`${i}+'2'`}>
									View complete Singles record (PDF)
									<br />
								</WimLink>
							);

						case 'MD':
							return (
								<WimLink
									to={`${pdfPath}${playerId}_MD.pdf`}
									external={true}
									measure={'Archive Record PDF'}
									key={`${i}+'3'`}>
									View complete Doubles record (PDF)
									<br />
								</WimLink>
							);

						case 'LD':
							return (
								<WimLink
									to={`${pdfPath}${playerId}_LD.pdf`}
									external={true}
									measure={'Archive Record PDF'}
									key={`${i}+'4'`}>
									View complete Doubles record (PDF)
									<br />
								</WimLink>
							);

						case 'MX':
							return (
								<WimLink
									to={`${pdfPath}${playerId}_MX.pdf`}
									external={true}
									measure={'Archive Record PDF'}
									key={`${i}+'5'`}>
									View complete Mixed Doubles record (PDF)
									<br />
								</WimLink>
							);

						default:
							return <br />;
					}
				})}
			</div>
		);
	}
	renderProfileLink(playerInfo) {
		let playerId = playerInfo.tourid;
		let profilePath = '/en_GB/players/overview/' + playerId + '.html';

		for (var i = 0; i < this.state.player.playersList.length; i++) {
			if (this.state.player.playersList[i].id === playerId) {
				this.player_match = true;
				break;
			}
		}

		if (this.player_match) {
			return (
				<div className="pdfLinks">
					<WimLink to={profilePath}>{`View ${values.tournamentYear} Profile`}</WimLink>
					<br />
				</div>
			);
		} else {
			return <div> </div>;
		}
	}

	render() {
		let playerInfo = this.state.player.playerDetailData;
		let flagNat = playerInfo.country.trim();
		let flagImage = this.state.flagImagePathLarge.replace('<code>', flagNat);
		let playerImage = '';
		let playerImageSrc = '';
		let imageStyle = '';

		//use the tourid image first
		if (playerInfo.tourid) {
			playerImageSrc = this.state.playerProfileImagePath.replace('<playerid>', playerInfo.tourid).trim();
			imageStyle = 'regular';
		}
		//then use draws archive image
		else if (playerInfo.portrait_img) {
			playerImageSrc = this.state.archiveDrawsAssetsPath + playerInfo.portrait_img;
			imageStyle = 'archive';
		}
		//else use generic headshot
		else {
			playerImageSrc = '/assets/images/misc/headshot.jpg';
			imageStyle = 'regular';
		}

		return (
			<div className="two-col inline player-gen-info-da draws_archive">
				<div className="general-info-item">
					<PlayerImage attributes={{ 'player-image': `${playerImageSrc}`, style: `${imageStyle}` }} />
					<span className="mobile-display">
						<div className="player-flag"></div>
						<div className="player-name">
							{playerInfo.firstname} {playerInfo.lastname}
						</div>
						<div>
							<span className="professional-info-field">First Year:</span>{' '}
							<span className="professional-info-value">{playerInfo.overview.first_pro}</span>
						</div>
						<div>
							<span className="professional-info-field">Last Year:</span>{' '}
							<span className="professional-info-value">{playerInfo.overview.last_pro}</span>
						</div>
						<div>
							<span className="professional-info-field">Tournaments:</span>{' '}
							<span className="professional-info-value">{playerInfo.championships.played}</span>
						</div>
					</span>
				</div>
				<div className="general-info-item">
					<div className="player-flag mobile-hide"></div>
					<div className="player-name mobile-hide">
						{playerInfo.firstname} {playerInfo.lastname}
					</div>
					<div className="general-info mobile-hide">
						<div>
							<span className="general-info-field">First Year:</span>{' '}
							<span className="general-info-value">{playerInfo.overview.first_pro}</span>
						</div>
						<div>
							<span className="general-info-field">Last Year:</span>{' '}
							<span className="general-info-value">{playerInfo.overview.last_pro}</span>
						</div>
						<div>
							<span className="general-info-field">Tournaments:</span>{' '}
							<span className="general-info-value">{playerInfo.championships.played}</span>
						</div>
					</div>
					{this.state.player.playersList ? this.renderProfileLink(playerInfo) : <div> </div>}
					{this.renderPDF(playerInfo)}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerArchiveInfo);

import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: () => (dispatch, getState, store) => {
		logger.log('[PlayersIndex] mount - players');

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Player Profiles',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let flagImagePathLarge = Config.otherData.flagImagePathLarge;
			let playerProfileImagePath = Config.otherData.playerProfileImagePath;
			let profilePath = Config.scoringData.players;

			dispatch({
				type: deps.actionTypes.PLAYERINDEX_LOADED,
				data: { profilePath, flagImagePathLarge, playerProfileImagePath, status: 'loaded' },
			});
		});
	},
};

import deps from 'dependencies';

export default {
	mount: data => dispatch => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				//path: Config.relatedContent.video
			};

			dispatch({ type: deps.actionTypes.actionTypes.MAINVIDEOCHANNEL_MOUNT, data: data });
		});
	},

	play: data => dispatch => {
		logger.log('[MainVideoChannel] action.play - data:%o', data);
		dispatch({ type: deps.actionTypes.MAINVIDEOCHANNEL_PLAY, data: data });
	},
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MAINNAV_MOUNT:
			//newState = Object.assign({}, state, {...action.data});
			newState = {
				...state,
				...action.data,
			};
			return newState;
			break;

		case deps.actionTypes.MAINNAV_UNMOUNT:
			//newState = Object.assign({}, state, {...action.data});
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[MainNav] reducer MAINNAV_UNMOUNT newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.MAINNAV_NAVIGATE:
			//newState = Object.assign({}, state, {...action.data});
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[MainNav] reducer MAINNAV_NAVIGATE newState:%o', newState);
			return newState;
			break;
		case deps.actionTypes.MAINNAV_CLEAR_PATH:
			//newState = Object.assign({}, state, {...action.data});
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[MainNav] reducer MAINNAV_CLEAR_PATH newState:%o', newState);
			return newState;
		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: LoqateAddress
 * -----------------------------------------------------------------------------
 */
class LoqateAddress extends Component {
	constructor(props) {
		super(props);

		this.loqateAddress = this.loqateAddress.bind(this);
		this.addressVal = this.addressVal.bind(this);
		this.fullAddressData;
		this.addressHolder;
	}

	addressVal(e, formProps) {
		this.address = e.target.value;
		let addressDataFiltered = filter(this.fullAddressData, function(o) {
			return o.Text.toLowerCase().includes(e.target.value.toLowerCase());
		});
		this.setState({ addressData: addressDataFiltered });
		formProps.setFieldValue('address', e.target.value);
		this.addressHolder = false;
		// this.setState({ selectedAddress: e.target.value });
	}

	addressClick(e, address, id, formProps) {
		logger.log('[LoqateAddress] addressClick - address:%o', address);
		this.setState({ selectedAddress: address, selected: id });
		formProps.setFieldValue('address', address);
	}

	addressBlur(formProps) {
		logger.log('[LoqateAddress] addressBlur this.addressHolder:%o', this.addressHolder);
		if (this.addressHolder) {
			formProps.setFieldValue('address', this.addressHolder);
		}
		this.setState({ addressDD: false });
	}

	loqateAddress(e, formProps) {
		this.addressHolder = e.target.value;
		logger.log('[LoqateAddress] loqateAddress this.addressHolder:%o', this.addressHolder);
		formProps.setFieldValue('address', '');
		this.setState({ addressDD: true });

		const Key = 'WC18-MC28-BT96-NF19';
		// logger.log('[LoqateAddress] loqateAddress:%o', text);
		let params = '';
		params += '?Key=' + encodeURIComponent(Key);
		params += '&Text=' + encodeURIComponent(formProps.values.zip);
		params += '&IsMiddleware=' + encodeURIComponent('True');
		// params += "&Container=" + encodeURIComponent("GB|RM|ENG|3DA-WR5");
		// params += "&Origin=" + encodeURIComponent("52.182,-2.222");
		params += '&Countries=' + encodeURIComponent('GB,US,CA');
		params += '&Limit=' + encodeURIComponent('100');
		// params += "&Language=" + encodeURIComponent(Language);
		axios
			.get('https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws' + params)
			.then(result => {
				let resultData = result.data.Items;
				logger.log('[LoqateAddress] loqateAddress - resultData:%o', resultData);
				let addressData = filter(resultData, { Type: 'Address' });
				if (!isEmpty(addressData)) {
					this.setState({
						addressData: resultData,
					});
					this.fullAddressData = resultData;
				} else {
					let id = resultData[0].Id;
					params += '&Container=' + encodeURIComponent(id);
					axios
						.get('https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws' + params)
						.then(result => {
							let resultData = result.data.Items;
							logger.log('[LoqateAddress] loqateAddress - 2nd resultData:%o', resultData);
							let addressData = filter(resultData, { Type: 'Address' });
							this.setState({
								addressData,
							});
							this.fullAddressData = addressData;
						})
						.catch(error => {
							logger.error('[LoqateAddress] loqateAddress - 2nd error:%o', error);
							if (error.status == 404) {
								this.setState({
									addressData: 'notfound',
								});
							}
						});
				}

				// logger.log('[LoqateAddress] loqateAddress - addressData:%o', addressData);
			})
			.catch(error => {
				logger.error('[LoqateAddress] loqateAddress - error:%o', error);
				if (error.status == 404) {
					this.setState({
						addressData: 'notfound',
					});
				}
			});
	}

	render() {
		// let { formProps } = this.props;
		// let { addressData, addressDD } = this.state;
		return (
			<Fragment>
				<p>test</p>
				{/* <FormInput
                    label="Address"
                    type="text"
                    name="address"
                    id="address"
                    onChange={e => this.addressVal(e, formProps)}
                    onFocus={e => this.loqateAddress(e, formProps)}
                    onBlur={() => this.addressBlur(formProps)}
                    value={formProps.values.address || ''}
                />
                {addressDD && (
                    <div className="addressSelectContainer">
                        {addressData && (
                            <div className="addresses">
                                {addressData.map((item, i) => {
                                    return (
                                        <div
                                            key={`address-${i}`}
                                            className={`address-${
                                                i % 2 == 0 ? `even` : `odd`
                                            } ${
                                                this.state.selected == item.Id ? `selected` : ``
                                            }`}
                                            onClick={(e, address, id) =>
                                                this.addressClick(
                                                    e,
                                                    `${item.Text} ${item.Description}`,
                                                    item.Id,
                                                    formProps
                                                )
                                            }>
                                            {`${item.Text} ${item.Description}`}
                                        </div>
                                    );
                                })}
                                {isEmpty(addressData) && (
                                    <div className="address-even">No addresses found</div>
                                )}
                            </div>
                        )}
                    </div>
                )} */}
			</Fragment>
		);
	}
}

export default LoqateAddress;

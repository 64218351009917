/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * React Component: WeatherData
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['WeatherData'], props);
};

const mapDispatchToProps = dispatch => ({
	//mount: (data) => dispatch(deps.actions.WeatherData.mount(data)),
	//update: (data) => dispatch(deps.actions.WeatherData.update(data)),
});

class WeatherData extends Component {
	constructor(props) {
		super(props);
		//this.state = Object.assign({}, this.props);
		this.state = { ...this.props };
	}

	componentDidMount() {
		//this.props.mount();
		//log(`${this.constructor.name}.mount()`);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			//return Object.assign({}, prevState, nextProps);
			return {
				...prevState,
				...nextProps,
			};
		});

		//log(`${this.constructor.name}.componentWillReceiveProps() - state:%o`, this.state);
	}

	render() {
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherData);

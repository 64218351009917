import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[CompetitorListPage] action.mount - ensureConfig');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Competitor List',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[CompetitorListPage] action.mount - dispatch - param:%o', params);
			logger.log('Config: ', Config);

			let data = {
				competitorPath: Config.scoringData.competitorList,
				seedsPath: Config.scoringData.seedsList,
			};

			dispatch({ type: deps.actionTypes.COMPETITORLISTPAGE_LOAD, data: data });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.COMPETITORLISTPAGE_FILTER, data: data });
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import TableComponent from 'appdir/components/common-ui/TableComponent';
import StubBox from 'appdir/components/common-ui/StubBox';

/**
 * -----------------------------------------------------------------------------
 * React Component: CountryStatisticsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['CountryStatisticsPage'], { stubs: state['Config'].stubPages }, props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.CountryStatisticsPage.mount()),
});

const getComponentList = list => {
	let comps = [];

	for (let key in list) {
		comps.push(list[key]['type']);
	}

	return comps;
};

const createTableHeaders = () => {
	return ['Country', 'Players Entered', 'Players Remaining', 'Wins/Losses'];
};

const formatTableData = data => {
	let countryData = data['countries'];
	let imagePath = this.state.countryStatsPath.flagImagePath;
	return countryData.map((d, i) => {
		imagePath = imagePath.replace('<code>', d.country_code);
		return {
			img: `<img src=${imagePath} alt=${d.country_message} border="0" width="43" height="26" /><a href='/en_GB/players/nations/${d.country_code}.html'>${d.country_message}</a>`,
			entered: d.entered,
			remain: d.remain,
			winsandlosses: `${d.wins}/${d.losses}`,
		};
	});
};

const getAttributes = list => {
	return list;
};

class CountryStatisticsPage extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.fetchData = true;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.stubs &&
			this.state.stubs.countrystats.stub !== 'stub' &&
			this.state.countryStatsPath &&
			!this.state.data &&
			this.fetchData
		) {
			let countryStatsPath = this.state.countryStatsPath.countryScoreBoard;
			this.fetchData = false;
			deps.services.CountryStatisticsPage.fetchCountryLeaderboard(countryStatsPath).then(data => {
				this.setState(prevState => {
					return { ...prevState, data: data };
				});
			});
		}
	}

	formatTableData(data) {
		let countryData = data['countries'];
		let imagePath = this.state.countryStatsPath.flagImagePath;
		return countryData.map((d, i) => {
			return {
				img: `<img src=${imagePath.replace('<code>', d.country_code)} alt=${
					d.country_message
				} border="0" width="43" height="26" /><a href='/en_GB/players/nations/${d.country_code}.html'>${
					d.country_message
				}</a>`,
				entered: d.entered,
				remain: d.remain,
				winsandlosses: `${d.wins}/${d.losses}`,
			};
		});
	}

	render() {
		logger.log('[CountryStatisticsPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'scores',
			title: 'Country Statistics',
			shortTitle: 'Country Statistics',
			metaTitle: 'Country Statistics',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		if (this.state.stubs && this.state.stubs.countrystats.stub === 'stub') {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<StubBox attributes={{ message: this.state.stubs.countrystats.text, style: 'centerV' }} />
					</div>
					<Footer />
				</Template>
			);
		} else if (this.state.data) {
			let headerData = createTableHeaders();
			let tableRowData = this.formatTableData(this.state.data);
			let propsData = { headers: headerData, data: tableRowData, tableContainer: 'countryScoreBoard' };
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<div className="country-stats-info">
							The Country Leaderboard contains participant information for Gentlemen's Singles and
							Doubles, Ladies' Singles and Doubles, Boys' Singles and Doubles, and Girls' Singles and
							Doubles Matches. Click on each country name to view a list of participants from the country
							for all events.
						</div>
						<TableComponent attributes={propsData} />
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator type={'white'} />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryStatisticsPage);

import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';
import Headshot from '../../common-ui/Headshot';

// stylesheet: _player-page-general-info.scss
class PlayerGeneralInfo extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	componentDidUpdate(prevState) {
		logger.log('[PlayerGeneralInfo] - state: %o, props: %o', this.state, this.props);
	}

	render() {
		let playerInfo = this.props.data.playerObj;
		let { flagImagePathLarge = '' } = this.props.data.playerConfig.pathObj;

		return (
			<div className="player-profile--header-wrapper" aria-hidden tabIndex={-1} role="none">
				<div className="player-profile-info">
					<div className="general-info-item" tabIndex={0}>
						<div className="player-profile-header">
							<div className="player-profile-left">
								<div className="flag-player-name">
									{playerInfo.nation.code !== 'IOA' && (
										<div className="player-flag ">
											<img
												src={
													'code' in playerInfo.nation ? (
														flagImagePathLarge.replace('<code>', playerInfo.nation.code)
													) : (
														<Skeleton />
													)
												}
											/>
										</div>
									)}
									<div className="player-name ">
										<span className="first-name">{playerInfo.first_name}</span>
										<span className="last-name">{playerInfo.last_name}</span>
									</div>
								</div>
								<div className="singles-ranking">
									{playerInfo.rank.current_singles ? (
										<div className="ranking">
											<div className="value">{playerInfo.rank.current_singles}</div>
											<div className="field">
												Singles
												<br />
												Ranking
											</div>
										</div>
									) : null}
								</div>
							</div>

							<div className="player-headshot">
								<Headshot type="full" id={playerInfo.id} />
								<span className="player-profile--hide-point"></span>
							</div>
							<div className="player-profile-right">
								<div className="fav-star-player-profile">
									<FavouriteStar
										id={playerInfo.id}
										type="players"
										unFavColor="green"
										favColor="yellow"
										alwaysShow={true}
									/>
								</div>

								<div className="doubles-ranking">
									{playerInfo.rank.current_doubles ? (
										<div className="ranking">
											<div className="value">{playerInfo.rank.current_doubles}</div>
											<div className="field">
												Doubles
												<br />
												Ranking
											</div>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default PlayerGeneralInfo;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import WimLink from 'appdir/components/general/WimLink';
import SocialEmbed from 'appdir/components/content/SocialEmbed';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveRadio
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	if (state['ActiveData']) {
		if (state['ActiveData']['radio']) {
			return {
				...state['LiveRadio'],
				radio: state['ActiveData']['radio']['live'],
				stubs: state['Config'].stubPages,
				...props,
			};
		}
	}
	return {
		...state['LiveRadio'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveRadio.mount()),
	unmount: () => dispatch(deps.actions.LiveRadio.unmount()),
});

class LiveRadio extends Component {
	constructor(props) {
		super(props);

		logger.log('[LiveRadio] constructor - props:%o', props);

		this.onRadioEvent = this.onRadioEvent.bind(this);

		this.state = {
			...props,
		};

		this.endTime = 'CLOSE OF PLAY';

		this.stub = false;
	}

	componentDidMount() {
		//logger.log('[LiveRadio] componentDidMount - state:%o', this.state);
	}

	componentWillUpdate(nextProps, nextState) {}

	/*componentWillUnmount() {
        //logger.log('[LiveRadio] componentWillUnmount');
        this.props.unmount();
    }*/

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[LiveRadio] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[LiveRadio] componentDidUpdate - stub:%o state%o', this.stub, this.state);
	}

	isStub() {
		return this.state.stubs && this.state.stubs.liveradio.stub != 'live';
	}

	onRadioEvent(event) {
		logger.info('[LiveRadio] onRadioEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				directRadio: '',
			});
		}
	}

	renderRadioChannel(index) {
		let channelStyle = '';
		let channelLink = '';

		switch (index) {
			case 0:
				channelStyle = 'wimbledon';
				channelLink = 'wimradio';
				break;
			case 1:
				channelStyle = 'centre';
				channelLink = 'centre';
				break;
			case 2:
				channelStyle = 'one';
				channelLink = 'no1';
				break;
			default:
				break;
		}

		if (this.state.radio[index].live && !this.isStub()) {
			return (
				<div className={`radio-station ${channelStyle} on-air`}>
					<WimLink
						to={`/webview/en_GB/radio/radio.html?chan=${channelLink}`}
						style="live-station"
						popup={true}
						options={'width=620, height=176, location=0, toolbar=0'}>
						<div className="station-text">
							<div className="station-title">{this.state.radio[index].title} IS ON AIR</div>
							<div className="station-description">
								JOIN US FROM{' '}
								<Time
									epoch_ms={this.state.radio[index].start_time}
									format="kk:mm z"
									options="upper tile"
								/>{' '}
								UNTIL {this.endTime}
							</div>
							<hr />
							<i className="wim-icon-video" />
						</div>
					</WimLink>
				</div>
			);
		}
		return (
			<div className={`radio-station ${channelStyle} off-air`}>
				<div className="not-live-station">
					<div className="station-text">
						<div className="station-title">{this.state.radio[index].title} IS OFF AIR</div>
						<div className="station-description">
							JOIN US FROM{' '}
							<Time epoch_ms={this.state.radio[index].start_time} format="kk:mm z" options="upper tile" />{' '}
							UNTIL {this.endTime}
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		logger.log('[LiveRadio] render - props:%o', this.props);
		logger.log('[LiveRadio] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'liveRadio',
			title: 'LIVE RADIO',
			shortTitle: 'LIVE RADIO',
			scroll: false,
			pageTitle: 'LIVE RADIO',
			htsearch: 'LIVE RADIO',
		};

		this.radioSchedule = op.get(this.state, 'radioSchedule', []);

		//logger.log('[LiveRadio] render - header:%o', header_propsData);

		//if (this.state.data) {
		if (this.state.radio && this.state.radio.length !== 0) {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="column-layout content-main live-radio">
						<div className="radio-stations">
							{this.renderRadioChannel(0)}
							{this.renderRadioChannel(1)}
							{this.renderRadioChannel(2)}
						</div>
						<hr />
						{/*
							<div className={'twitter-embed'}>
								<SocialEmbed data={{ type: 'timeline', screenName: 'WimbledonChnl' }} />
							</div>
						*/}
					</div>
					<Footer />
				</Template>
			);
		} else if (this.props.radio && this.radioSchedule && this.radioSchedule.length !== 0) {
			if (this.props.radio[0]) {
				return (
					<Template>
						<Header attributes={header_propsData} />

						<PageHeader attributes={header_propsData} />

						<div className="column-layout content-main live-radio">
							<div className="radio-stations">
								{this.renderWimbledonRadio()}
								{this.renderCentreRadio()}
								{this.renderNo1Radio()}
							</div>
							<hr />
							<div className={'twitter-embed'}>
								<SocialEmbed data={{ type: 'timeline', screenName: 'WimbledonChnl' }} />
							</div>
						</div>
						<Footer />
					</Template>
				);
			}
		} else {
			return (
				<Template>
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveRadio);

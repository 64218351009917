import { logger } from '../logger';
import CaptionConstants from './CaptionConstants';

export default function PenState({foreground, underline, italics, background, flash}) {
	
	var styles = {
		foreground: foreground || "white",
		underline: underline || false,
		italics: italics || false,
		background: background || "black",
		flash: flash || false
	};

	return Object.freeze({
		reset,
		setStyles,
		isDefault,
		equals,
		copy,
		toString
	})

	
	function reset() {
		styles.foreground = "white";
		styles.underline = false;
		styles.italics = false;
		styles.background = "black";
		styles.flash = false;
	};
	
	function setStyles(newStyles) {
		var attribs = ["foreground", "underline", "italics", "background", "flash"];
		for (var i = 0 ; i < attribs.length; i++) {
			var style = attribs[i];
			if (newStyles.hasOwnProperty(style)) {
				styles[style] = newStyles[style];
			}
		}
	};
	
	function isDefault() {
		return (styles.foreground === "white" && !styles.underline && !styles.italics && 
			styles.background === "black" && !styles.flash);
	};

	function equals(other) {
		return ( (styles.foreground === other.foreground) && 
					(styles.underline === other.underline) &&
					(styles.italics === other.italics) &&
					(styles.background === other.background) &&
					(styles.flash === other.flash) );
	};

	function copy(newPenState) {
		styles.foreground = newPenState.foreground;
		styles.underline = newPenState.underline;
		styles.italics = newPenState.italics;
		styles.background = newPenState.background;
		styles.flash = newPenState.flash;
	};
	
	function toString() {
		return ("color=" + styles.foreground + ", underline=" + styles.underline + ", italics=" + styles.italics +
			", background=" + styles.background + ", flash=" + styles.flash);
	};
}
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { countryLookup } from 'appdir/components/general/Util';
import {
	FormInput,
	FormEmail,
	FormSelect,
	FormSelectPlus,
	FormTextarea,
	FormCheckBox,
} from 'appdir/components/common-ui/FormFields';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: OrganisationInformationForm = (props) => {
 * -----------------------------------------------------------------------------
 */

const countries = countryLookup;

// class OrganisationInformationForm extends Component {
const OrganisationInformationForm = props => {
	let { values } = props.formProps;

	return (
		<div className="column-layout">
			<div className="four-col page-title">
				<div className="title-header">ORGANISATION INFORMATION</div>
			</div>
			<div className="two-col margin-col">
				<FormSelectPlus
					label="Organisation Name"
					className="react-select-container"
					classNamePrefix="react-select"
					name="name"
					options={props.organisationList}
					multi={false}
					onChange={option => props.formProps.setFieldValue('name', option.value)}
				/>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<div className="media-form-header">
					Accreditation Contact (the person responsible for submitting the application/s on behalf of the
					Organisation)
				</div>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<span className="two-col no-margin left">
					<FormInput
						label="First Name"
						name="firstName"
						type="text"
						placeholder=""
						required
						showerror="true"
					/>
				</span>
				<span className="two-col">
					<FormInput label="Last Name" name="lastName" type="text" placeholder="" required showerror="true" />
				</span>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<span className="two-col no-margin left">
					<FormEmail label="Email" name="email" type="email" placeholder="" required showerror="true" />
				</span>
				<span className="two-col">
					<FormInput label="Position" name="position" type="text" placeholder="" required showerror="true" />
				</span>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<span className="two-col no-margin left">
					<FormInput label="Address 1" name="address1" type="text" placeholder="" required showerror="true" />
				</span>
				<span className="two-col">
					<FormInput label="Address 2" name="address2" type="text" placeholder="" />
				</span>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<span className="two-col no-margin left">
					<FormInput label="City" name="city" type="text" placeholder="" required showerror="true" />
				</span>
				<span className="two-col">
					<FormInput
						label="State/Province/Region"
						name="state"
						type="text"
						placeholder=""
						required
						showerror="true"
					/>
				</span>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<span className="two-col no-margin left">
					<FormInput
						label="Zip/Postal Code"
						name="postcode"
						type="text"
						placeholder=""
						required
						showerror="true"
					/>
				</span>
				<span className="two-col">
					<FormSelect label="Country" name="country" required showerror="true">
						<option key={'countryinit'} value="">
							-- Select a Country --
						</option>
						<option key={'uktop'} value="GB">
							{countries['GB']}
						</option>
						<option key={'orig'} value="">
							-- All Countries --
						</option>
						{Object.keys(countries).map((d, i) => {
							return (
								<option key={i} value={d}>
									{countries[d]}
								</option>
							);
						})}
					</FormSelect>
				</span>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<FormSelect label="Organisation Type" name="type" required showerror="true">
					<option value="">-- Please Select an Organisation Type --</option>
					<option value="nationalNewspaper">National Newspaper</option>
					<option value="regionalNewspaper">Regional Newspaper</option>
					<option value="magazineTennis">Magazine - Tennis</option>
					<option value="magazineOther">Magazine - Other</option>
					<option value="photoAgency">Photo Agency</option>
					<option value="newsAgency">News Agency</option>
					<option value="website">Website</option>
					<option value="officialSupplier">Official Supplier</option>
					<option value="tennisFamily">Tennis Family</option>
					{/* <option value="photographer">Photographer</option> */}
				</FormSelect>
			</div>
			{values.type == 'nationalNewspaper' ||
			values.type == 'regionalNewspaper' ||
			values.type == 'magazineTennis' ||
			values.type == 'magazineOther' ? (
				<div className="two-col margin-col clear-two clear-four">
					<span className="two-col no-margin left">
						<FormInput label="Circulation" name="circulation" type="text" placeholder="" />
					</span>
					<span className="two-col">
						<FormSelect label="Frequency" name="frequency">
							<option value="">-- Please Select a Frequency --</option>
							{values.type == 'nationalNewspaper' || values.type == 'regionalNewspaper' ? (
								<option value="daily">Daily</option>
							) : null}
							<option value="weekly">Weekly</option>
							{values.type == 'magazineTennis' || values.type == 'magazineOther' ? (
								<option value="forgnightly">Fortnightly</option>
							) : null}
							{values.type == 'magazineTennis' || values.type == 'magazineOther' ? (
								<option value="monthly">Monthly</option>
							) : null}
							{values.type == 'magazineTennis' || values.type == 'magazineOther' ? (
								<option value="quarterly">Quarterly</option>
							) : null}
							{values.type == 'magazineTennis' || values.type == 'magazineOther' ? (
								<option value="other">Other</option>
							) : null}
							{values.type == 'magazineOther' ? <option value="online">Online only</option> : null}
						</FormSelect>
					</span>
				</div>
			) : null}
			{values.type == 'photoAgency' ? (
				<div className="two-col margin-col clear-two clear-four">
					<FormTextarea
						label="Photo Agency"
						name="photoAgency"
						type="textarea"
						placeholder="Please enter details of clients, distribution, publications represented, etc."
						stylename="midheight"
					/>
				</div>
			) : null}
			{values.type == 'newsAgency' ? (
				<div className="two-col margin-col clear-two clear-four">
					<FormTextarea
						label="News Agency"
						name="newsAgency"
						type="textarea"
						placeholder="Please enter details of clients, distribution, publications represented, etc."
						stylename="midheight"
					/>
				</div>
			) : null}
			{values.type == 'website' ? (
				<div className="two-col margin-col clear-two clear-four">
					<FormInput
						label="Website (Organisation exclusively online)"
						name="website"
						type="text"
						placeholder="Please enter unique visitor numbers"
					/>
				</div>
			) : null}
			{values.type == 'officialSupplier' ? (
				<div className="two-col margin-col clear-two clear-four">
					<FormInput
						label="Official Supplier"
						name="officialSupplier"
						type="textarea"
						placeholder="Please enter channel overview and size of audience"
					/>
				</div>
			) : null}
			{values.type == 'tennisFamily' ? (
				<div className="two-col margin-col clear-two clear-four">
					<FormInput
						label="Tennis Family"
						name="tennisFamily"
						type="textarea"
						placeholder="Please enter channel overview and size of audience"
					/>
				</div>
			) : null}
			{/* <div className="two-col margin-col clear-two clear-four fullwidth">
                <FormCheckBox name="writingRoomDesk" id="writingRoomDesk">
                    Request a desk in Press Writing Room
                </FormCheckBox>
            </div>
            <div className="two-col margin-col clear-two clear-four fullwidth">
                <FormCheckBox name="photoWorkroomDesk" id="photoWorkroomDesk">
                    Request a desk in Photo Workroom
                </FormCheckBox>
            </div> */}
			{/* <div className="two-col margin-col clear-two clear-four">
                <div className="four-col clear-two clear-four media-form-header">Purchase Communications Services</div>
                <div className="four-col clear-two clear-four smaller">
                    Please note: The Championships provides Wi-Fi, suitable for emails and web browsing
                    only, free of charge. This is provided on a reasonable endeavours basis and therefore if 
                    anything more is required showerror="true", such as uncontended bandwidth, or should you wish to
                    purchase VLAN, phones lines, etc. these should be ordered by <WimLink to="https://www.wimbledon.com/pdf/Application_for_Communication_and_Audio_Services_2020.pdf" external={true}>clicking HERE to view and download the order form</WimLink>.
                </div>
            </div>  */}
			<div className="two-col margin-col clear-two clear-four no-margin right">
				<span className="hospitality-btns no-margin right-align right left">
					<Button
						className={`media-btn long solid${props.formProps.isSubmitting ? ' disabled' : ''}`}
						onClick={props.formProps.submitForm}>
						Save &amp; Continue {'>'}
					</Button>
				</span>
			</div>
		</div>
	);
};

export default OrganisationInformationForm;

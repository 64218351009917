import WeatherPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: ['/en_GB/weather/', '/en_GB/weather/index.html'],
	exact: true,
	component: WeatherPage,
	section: MENU_ITEMS.news,
	load: params => deps.actions.WeatherPage.mount(params),
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Script from 'react-load-script';
import { connect } from 'react-redux';
import op from 'object-path';
import deps from 'dependencies';
import { any } from 'appdir/components/general/Util/Functions';
import Button from 'appdir/components/common-ui/Button';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
/**
 * -----------------------------------------------------------------------------
 * Functional Component: RestaurantBooking
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['Config']['dateConfig'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
});

class RestaurantBooking extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);

		logger.log('[RestaurantBooking] init - state: %o', this.state);

		this.roleWildcard = '*';
		this.buttonId = '';
		this.defaultDate = '';
		this.text = 'Reservations';
		this.type = 'reservations';
		this.calendar = true;
		this.link = false;
		this.tagName = this.state.data.tag;

		let defDate = '2023-07-03';

		/*
		SevenroomsWidget.init({
			venueId: "wimbledonpicnic",
			triggerId: "sr-res-root", // id of the dom element that will trigger this widget
			type: "reservations", // either 'reservations' or 'waitlist' or 'events'
			styleButton: true // true if you are using the SevenRooms button
		})
		*/

		switch (this.tagName) {
			case 'memberswimbledon':
				this.buttonId = 'sr-res-root-21';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				this.tagName = 'memberswimbledon';
				break;

			case 'wimbledonpicnic':
				this.buttonId = 'sr-res-root-1';
				this.text = 'Book a Picnic';
				this.defaultDate = defDate;
				this.type = 'events';
				this.calendar = false;
				this.link = `https://eu.sevenrooms.com/${this.type}/${this.state.data.tag}?hide-calendar=true`;
				break;

			case 'wimbledonpicnictest':
				this.buttonId = 'sr-res-root-2';
				this.text = 'Book a Picnic';
				this.defaultDate = defDate;
				this.type = 'events';
				this.calendar = false;
				this.link = `https://eu.sevenrooms.com/${this.type}/${this.state.data.tag}?hide-calendar=true`;
				break;

			case 'wingfieldwimbledon':
				this.buttonId = 'sr-res-root-3';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'wimbledonmembersbrasserie':
				this.buttonId = 'sr-res-root-4';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				this.tagName = 'wimbledonmembersbrasserie';
				break;

			case 'wimbledonmembersbrasserietest':
				this.buttonId = 'sr-res-root-5';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'championsroomsuk':
				this.buttonId = 'sr-res-root-6';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'championsroom':
				this.buttonId = 'sr-res-root-7';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'renshawwimbledon':
				this.buttonId = 'sr-res-root-8';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'courtsiderestaurantuk':
				this.buttonId = 'sr-res-root-9';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'wimbledoncourtsiderestaurant':
				this.buttonId = 'sr-res-root-10';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'courtsidebrasseriuk':
			case 'courtsidebrasserieuk':
				this.buttonId = 'sr-res-root-11';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				this.tagName = 'courtsidebrasserieuk';
				break;

			case 'memberslawn':
				this.buttonId = 'sr-res-root-12';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'thebaselineuk':
				this.buttonId = 'sr-res-root-13';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'thepergola':
				this.buttonId = 'sr-res-root-14';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'theterrace':
				this.buttonId = 'sr-res-root-15';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'thegallerywimbledon':
				this.buttonId = 'sr-res-root-16';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'rooftopbar':
				this.buttonId = 'sr-res-root-17';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'number1debentureslounge':
				this.buttonId = 'sr-res-root-18';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'number1debenturesbar':
				this.buttonId = 'sr-res-root-19';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'theverandauk':
				this.buttonId = 'sr-res-root-20';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'centenaryseafoodbar':
				this.buttonId = 'sr-res-root-22';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				break;

			case 'centenarybrasserie':
				this.buttonId = 'sr-res-root-23';
				this.text = 'Book a Table';
				this.defaultDate = defDate;
				this.tagName = 'centenarybrasserie';
				break;

			default:
				break;
		}
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		if (this.tagName && window.SevenroomsWidget && this.state.tournStart) {
			//override all dates with tourn start date
			this.defaultDate = moment(this.state.tournStart).format('YYYY-MM-DD');
			let config = {
				venueId: this.tagName,
				triggerId: this.buttonId,
				type: this.type,
				env: 'EU',
				defaultDate: this.defaultDate,
			};

			logger.log('[RestaurantBooking] init - date:%o config: %o', config.defaultDate, config);
			//window.SEVENROOMS_VENUE_ID = this.tagName;
			try {
				window.SevenroomsWidget.init(config);
			} catch (e) {
				//logger.error('[RestaurantBooking] init - config error: %o', e);
			}
		}
	}

	handleScriptCreate() {
		this.setState({
			scriptLoaded: false,
		});
	}

	handleScriptError() {
		this.setState({
			scriptError: true,
		});
	}

	handleScriptLoad() {
		//let version = Utils.getIEVersion();
		//logger.log('handleScriptLoad: ie:%o html:%o', akamai.amp.Utils.getIEVersion(), akamai.amp.Utils.isHTMLFirst());

		logger.log('[RestaurantBooking] handleScriptLoad: state:%o', this.state);

		this.setState({
			scriptLoaded: true,
		});
	}

	checkRole(ids) {
		let roles = op.get(this.state, 'currentUser.roles', []);

		//logger.log('[RestaurantBooking] checkRole - ds:%o roles:%o', ids, roles);
		//if specified id is any user return true
		if (ids.includes(this.roleWildcard)) {
			return true;
		}

		//check for matching roles
		roles = roles.filter(role => {
			//logger.log('[RestaurantBooking] checkRole - role:%o', role);
			return any(ids, id => id == role.roleId || role.segmentId == id);
		});

		return roles.length > 0;
	}

	clickLogin(e, screen) {
		e.preventDefault();
		this.props.openScreen({ screen: screen, cid: 'restaurant_button' });
	}

	getButton(id, style, text, link) {
		if (!link) {
			return (
				<div id={id} className={`generic-button_wrapper cms ${style}`}>
					<div className={'generic-button btn solid'}>
						<div className="content">{text}</div>
						<div className="border"></div>
					</div>
				</div>
			);
		} else {
			return (
				<div className={`generic-button_wrapper cms ${style}`}>
					<Button className={`btn solid`} to={`${this.link}`} external={true}>
						{text}
					</Button>
				</div>
			);
		}
	}

	render() {
		let ids = this.state.data.roles || this.state.data.id || [];
		if (ids && typeof ids == 'string') {
			ids = ids.split(',');
		}

		if (!this.state.scriptLoaded) {
			return (
				<Script
					url="https://eu.sevenrooms.com/eu/embed.js"
					onCreate={this.handleScriptCreate.bind(this)}
					onError={this.handleScriptError.bind(this)}
					onLoad={this.handleScriptLoad.bind(this)}
				/>
			);
		}

		logger.log('[RestaurantBooking] render - ids:%o state:%o', ids, this.state);

		if (!this.state.gigyaLoaded) {
			return null;
		} else {
			// if no id specified open to anyone without login
			if (ids.length == 0) {
				return this.getButton(this.buttonId, this.state.data.style, this.text, this.link);
			}

			//check against roleId, roleSegment, or any user
			else if (this.state.loggedIn && ids.length > 0) {
				if (this.checkRole(ids)) {
					return this.getButton(this.buttonId, this.state.data.style, this.text, this.link);
				} else {
					return null;
				}
			}

			//display message if not logged in and requires any logged in user
			else if (!this.state.loggedIn && ids.includes(this.roleWildcard)) {
				return (
					<div className={`${this.state.data.style} center`}>
						<span className="p">
							Please{' '}
							<a href="" onClick={e => this.clickLogin(e, 'login_screen')}>
								login
							</a>{' '}
							or{' '}
							<a href="" onClick={e => this.clickLogin(e, 'register_screen')}>
								register
							</a>{' '}
							to book.
						</span>
					</div>
				);
			}

			//do not display if not logged in an id reuired that isn't wildcard
			else if (!this.state.loggedIn && ids.length > 0) {
				return null;
			} else {
				return null;
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantBooking);

import deps from 'dependencies';
import axios from 'axios';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: params => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Contact Us Webview',
		});

		//logger.log('[ContactPage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				subjects: Config.contact.subjects,
				topics: Config.contact.topics,
			};

			logger.log('[ContactUsPage] action.mount - data:', data);

			dispatch({ type: deps.actionTypes.CONTACTUSPAGE_MOUNT, data: data });
		});
	},

	newsletterSignUpSubmit: values => (dispatch, getState, store) => {
		let sendValues = Object.assign({}, values);
		delete sendValues.email;
		delete sendValues.eventid;
		delete sendValues.comment;
		delete sendValues.subject;
		delete sendValues.topic;
		delete sendValues.os;
		delete sendValues.apptype;
		delete sendValues.emailconfirm;
		sendValues.foundation = false;
		const request = axios.post('/relatedcontent/rest/wifi/signup', sendValues);
		dispatch({ type: deps.actionTypes.CONTACTUSPAGE_NEWSLETTERSIGNUP, data: request });
	},
};

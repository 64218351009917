import { useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import { useMemberContext } from '../../context';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';

const useCourts = () => {
	const [courts, setCourts] = useState({});
	const { invokeRequest, response, loading, setLoading, statusCode } = useAxios();
	const { memberConfig } = useMemberContext();

	const dispatch = useDispatch();
	const getCourtData = () => {
		const key = 'court-availability';
		const method = 'GET';
		const baseURL = memberConfig?.api.courtAvailability;
		const data = null;
		const urlSlug = '';
		setLoading(true);
		dispatch(deps.actions.Gigya.getJWT()).then(res => {
			invokeRequest(
				key,
				method,
				{
					Authorization: 'Bearer ' + res.jwt.id_token,
				},
				baseURL,
				data,
				urlSlug
			);
		});
	};
	useEffect(() => {
		if (
			response &&
			((response?.response?.status &&
				(![200].includes(response?.response?.status) || ![200].includes(response?.status))) ||
				(response?.response?.statusCode && ![200].includes(response?.response?.statusCode)))
		) {
			if (
				!(
					response &&
					([404].includes(response?.response?.status) ||
						[404].includes(response?.status) ||
						[404].includes(response?.response?.statusCode))
				)
			) {
				dispatch(
					deps.actions.ConfirmationModal.toggleModal({
						showModal: true,
						showCloseBtn: true,
						type: 'error',
						title: response?.message,
						message: `Please try again. ${response?.response?.msg || response?.response?.message}`,
						useTimer: true,
					})
				);
			}
		}
		if (response?.response) {
			setCourts({
				courts: {
					championshipCourts: response?.response.championshipCourts,
					practiceCourts: response?.response.practiceCourts,
					clayAndAcrylicCourts: response?.response.clayAndAcrylicCourts,
					croquetLawns: response?.response.croquetLawns,
				},
				other: {
					lastupdate: response?.response.lastupdate,
					courtAvailabilityMemberNotes: response?.response.courtAvailabilityMemberNotes,
				},
			});
		}
	}, [response]);

	return {
		getCourtData,
		courts,
		loading,
	};
};

export default useCourts;

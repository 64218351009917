import React, { Component } from 'react';
import Button from 'appdir/components/common-ui/Button';
import WebviewResaleQRcode from 'appdir/components/webviews/TicketResaleWebview/WebviewResaleQRcode.js';
// import { handleExitResaleQueueEndpoint } from 'appdir/components/webviews/TicketResaleWebview/ticketResaleEndpoints';
// import { getErrorText } from 'appdir/components/general/Util/Error';
import { measureInAppContext } from 'appdir/components/general/Analytics';

class ExpiredQueue extends Component {
	constructor(props) {
		super(props);
		this.state = {
            ...props
        }
	}

	componentDidMount(){
		measureInAppContext({
			pageTitle:"Ticket Resale Queue",
			action: "Collection Window Expired",
			context: [{
				section: "Ticket Resale Ready",
			}],
			metricType: "state",
		});
	}



	render() {
        const { headerTxt, topTxt, queueObj, configQueues } = this.props;
		let court = configQueues.filter((queue) => queue?.id == queueObj?.queue_id)[0]?.name || '';

		return (
			<div className="webview-ticket-queue" >
                <div className='ticket-resale-header-txt ticket-resale-header-txt-expired' aria-label={headerTxt}>{headerTxt}</div>
				<div className="webview-ticket-queue-court" tabIndex={0} aria-label={court}>{court}</div>
				<div className='ticket-resale-checkin-txt' aria-label={topTxt}>{topTxt}</div>
				<div className="webview-ticket-queue-qr-code">
					<WebviewResaleQRcode booking_id={queueObj?.booking_id} />
				</div>
				<div className="webview-ticket-queue-exit-queue-cntr">
					<Button 
						onClick={() => {
							// go back to join screen if expired 
							this.props.handleExitExpiredQueue();

							// NOTE: for time being, don't need to call exit because will return an error, keeping code in case need it again...
							// this.props.getJWT().then(token => {
							// 	this.props.showModal({
							// 		showModal: true,
							// 		showCloseBtn: false,
							// 		type: 'updating',
							// 		title: 'Exiting Queue',
							// 		message: 'Please do not close the window while submitting.',
							// 		useTimer: false,
							// 	});
					
							// 	let path = this.props.queueAPIs?.resaleUpdateQueue;
							// 	handleExitResaleQueueEndpoint(token.jwt.id_token, path, queueObj?.booking_id).then((response) => {
							// 		logger.log('[ExpiredQueue] handleExitResaleQueueEndpoint response %o', response);
							// 		this.props.showModal({
							// 		    showModal: false,
							// 		    showCloseBtn: true,
							// 		    type: 'success',
							// 		    title: 'Success',
							// 		    message: 'You have exited the queue',
							// 		    useTimer: false,
							// 		});
							// 		// add update status 
							// 		this.props.exitQueueResponse('success', response)

							// 	}).catch(error => {
							// 		logger.error('[ExpiredQueue] handleExitResaleQueueEndpoint - error:%o', error);
							// 		this.props.showModal({
							// 			showModal: true,
							// 			showCloseBtn: true,
							// 			type: 'error',
							// 			title: 'Error Exiting Queue',
							// 			message: getErrorText(error?.code || error?.data?.response?.error?.code),
							// 			useTimer: false,
							// 		});
							// 	});
							// });
						}}
						aria-label="REJOIN ANOTHER QUEUE"
                    >REJOIN ANOTHER QUEUE</Button>
				</div>

			</div>
		);
	}
}

export default ExpiredQueue;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import { createRoot } from 'react-dom/client';

import MeasurementUtils from 'appdir/lib/analytics';
class ShareMenu extends Component {
	constructor(props) {
		super(props);

		// this.state = Object.assign({}, this.props);
		this.state = { ...this.props, showModalFlag: false };

		const { foundation } = props;
		this.twitterUrl = foundation ? 'http://twitter.com/#!/wimbledonFdn' : 'http://twitter.com/#!/wimbledon';
		this.instagramUrl = foundation ? 'https://www.instagram.com/WimbledonFdn/' : 'https://instagram.com/Wimbledon/';
		this.youtubeUrl = foundation
			? 'https://www.youtube.com/watch?v=J-fHjTKCSbs&list=PLwx9gNibGUz6ErdNQGxs2W5zHyOqy3hkr'
			: 'https://www.youtube.com/wimbledon';
	}

	componentDidMount() {
		this.modalTarget = document.createElement('div');
		document.body.appendChild(this.modalTarget);
		this.root = createRoot(this.modalTarget);
		this._render();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentWillUnmount() {
		this.root.unmount(this.modalTarget);
		document.body.removeChild(this.modalTarget);
	}

	componentDidUpdate() {
		this._render();
	}

	_render() {
		this.root.render(this.renderModalContent());
	}

	showModal(bool) {
		this.setState(prevState => {
			return { ...prevState, showModalFlag: bool };
		});
	}

	renderModalContent() {
		// let modalContent = this.state['showModalFlag'];
		// let image = this.state['image'];
		return this.state.showModalFlag ? (
			<span
				className="modal-content-overlay"
				style={{
					display: this.state.showModalFlag ? 'block' : 'none',
				}}>
				<div className={`modal-content wechat ${this.state.showModalFlag ? 'show' : ''}`}>
					<img role="image" mg src="/assets/images/misc/WeChatQR.jpg" alt="share WeChat" />
					<div className="scrollable">
						<div className="modal-content-text">
							<h2>WeChat</h2>
							<div className="description">
								<p>Follow Wimbledon on WeChat by scanning the QR code.</p>
							</div>
						</div>
					</div>
					<i
						role="button"
						onClick={() => {
							this.showModal(false);
						}}
						onKeyDown={() => (e.key === 'Enter' ? this.showModal(false) : null)}
						tabIndex={0}
						aria-label="modal button"
						className="wim-icon-close"
					/>
				</div>
			</span>
		) : null;
	}

	openSocialWindow(href) {
		logger.log('[ShareMenu] openSocialWindow - href: ', href);
		let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
		let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if (isSafari && iOS && href.indexOf('mailto') > -1) {
			window.location.href = href;
		} else {
			var win = window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');

			var checkBlank = function(openWin) {
				try {
					if (openWin.location.hostname !== undefined && openWin.location.hostname === '') {
						// means empty frame, can close
						openWin.close();
					}
				} catch (e) {
					// do nothing
					logger.log('[ShareMenu] openSocialWindow - error:%o share:%o', e);
				}
			};
		}

		setTimeout(checkBlank.bind(this, win), 7000);
	}

	openShare(which, url) {
		logger.log('[ShareMenu] openShare - which:%o share:%o', which, url);
		const { foundation } = this.props;
		logger.log('[ShareMenu] openShare - foundation:%o', foundation);
		if (url) {
			url =
				url.indexOf('http') !== -1 || url.indexOf('www.wimbledon.com') !== -1
					? url
					: `${window.location.protocol}//${window.location.hostname}${url}`;
		}

		MeasurementUtils.dispatchMeasurementCall('socialOutlet', {
			network: which,
			content: { title: document.title },
		});
		switch (which) {
			// case 'googleplus':
			//     if (url) {
			//         window.open('http://plus.google.com/share?url=' + url, '_blank')
			//     } else {
			//         window.open('http://plus.google.com/+Wimbledon', '_blank')
			//     }
			//     break;

			case 'facebook':
				if (url) {
					this.openSocialWindow('https://www.facebook.com/sharer/sharer.php?u=' + url);
				} else {
					this.openSocialWindow('http://www.facebook.com/wimbledon');
				}
				break;
			case 'twitter':
				if (url) {
					this.openSocialWindow('https://twitter.com/share?url=' + url + '&via=Wimbledon&text=Detail');
				} else {
					this.openSocialWindow(this.twitterUrl);
				}
				break;
			case 'instagram':
				if (url) {
					//window.open('' + url, '_blank')
				} else {
					this.openSocialWindow(this.instagramUrl);
				}
				break;
			case 'youtube':
				if (url) {
					//window.open('' + url, '_blank')
				} else {
					this.openSocialWindow(this.youtubeUrl);
				}
				break;
			case 'wechat':
				this.showModal(!this.state.showModalFlag);
				//window.open('', '_blank');
				break;
			case 'weibo':
				if (url) {
					this.openSocialWindow('http://service.weibo.com/share/share.php?url=' + url);
				} else {
					this.openSocialWindow('http://weibo.com/wimbledonofficial');
				}
				break;
		}

		//https:// plus.google.com / share ? url = http % 3A % 2F % 2Fwww.wimbledon.com % 2Fen_GB % 2Fnews % 2Farticles % 2F2018 - 03 - 15 % 2F2018 - 03 -15_serena_begins_the_comeback_trail.html
		//https://twitter.com/share?url=http%3A%2F%2Fwww.wimbledon.com%2Fen_GB%2Fnews%2Farticles%2F2018-03-15%2F2018-03-15_serena_begins_the_comeback_trail.html&via=Wimbledon&text=Detail

		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	getGalleryBar(color) {
		return (
			<div className={`gallery share-bar ${color}`}>
				<div className="share-bar-icons">
					<i
						role="button"
						className="wim-icon-x external"
						aria-label="X social media"
						onClick={() => {
							this.openShare('twitter', this.state.url);
						}}
						onKeyDown={() => (e.key === 'Enter' ? this.openShare('twitter', this.state.url) : null)}
						tabIndex={0}
					/>
					<i
						role="button"
						className="wim-icon-facebook external"
						aria-label="Facebook social media"
						onClick={() => {
							this.openShare('facebook', this.state.url);
						}}
						onKeyDown={() => (e.key === 'Enter' ? this.openShare('facebook', this.state.url) : null)}
						tabIndex={0}
					/>
					<i
						role="button"
						className="wim-icon-weibo external"
						aria-label="Weibo social media"
						onClick={() => {
							this.openShare('weibo', this.state.url);
						}}
						onKeyDown={() => (e.key === 'Enter' ? this.openShare('weibo', this.state.url) : null)}
						tabIndex={0}
					/>
				</div>
				<div className="share-bar-icons">
					{/* <i
						role="button" className="wim-icon-googleplus" title="Google+" onClick={() => {this.openShare('googleplus', this.state.url)}}></i> */}
					{/* <i
						role="button" className="wim-icon-weibo" title="Weibo" onClick={() => { this.openShare('weibo', this.state.url) }}></i> */}
				</div>
			</div>
		);
	}

	getHorizBar(color) {
		return (
			<div className={`share-bar ${color}`}>
				<div className="share-bar-icons-wimlink">
					<WimLink
						to="http://www.facebook.com/wimbledon"
						measure="Facebook"
						measureType="socialOutlet"
						popupWindow
						popup
						role="link"
						title="Facebook social media"
						external={true}>
						<i className="wim-icon-facebook external" />
					</WimLink>
					<WimLink
						to={this.twitterUrl}
						measure="Twitter"
						measureType="socialOutlet"
						popupWindow
						popup
						role="link"
						title="X social media"
						external={true}>
						<i className="wim-icon-x external" />
					</WimLink>
					<WimLink
						to={this.instagramUrl}
						measure="Instagram"
						measureType="socialOutlet"
						popupWindow
						popup
						role="link"
						title="Instagram social media"
						external={true}>
						<i className="wim-icon-instagram external" />
					</WimLink>
					<WimLink
						to={this.youtubeUrl}
						measure="YouTube"
						measureType="socialOutlet"
						popupWindow
						popup
						role="link"
						title="YouTube social media"
						external={true}>
						<i className="wim-icon-youtube external" />
					</WimLink>
					<span
						tabIndex={0}
						role="link"
						onClick={() => {
							this.openShare('wechat');
						}}
						onKeyPress={() => (event.key === 'Enter' ? this.openShare('wechat', this.state.url) : null)}
						aria-label="WeChat social media">
						<i className="wim-icon-wechat external" />
					</span>
					<WimLink
						to="http://weibo.com/wimbledonofficial"
						measure="Weibo"
						measureType="socialOutlet"
						popupWindow
						popup
						role="link"
						title="Weibo social media"
						external={true}>
						<i className="wim-icon-weibo external" />
					</WimLink>
					{/* <i
						role="button" className="wim-icon-googleplus" title="Google+" onClick={() => {this.openShare('googleplus')}}></i> */}
				</div>
			</div>
			// <div className="share-bar">
			//     <div className="share-bar-icons">
			//         <NavLink to="http://www.facebook.com/wimbledon" target="_blank"><i className="wim-icon-facebook"></i></NavLink>
			//         <NavLink to="http://twitter.com/#!/wimbledon" target="_blank"><i className="wim-icon-x"></i></NavLink>
			//         <NavLink to="https://instagram.com/Wimbledon/" target="_blank"><i className="wim-icon-instagram"></i></NavLink>
			//         <NavLink to="http://plus.google.com/+Wimbledon" target="_blank"><i className="wim-icon-youtube"></i></NavLink>
			//         <NavLink to="http://www.youtube.com/wimbledon" target="_blank"><i className="wim-icon-googleplus"></i></NavLink>
			//         <NavLink to="" target="_blank"><i className="wim-icon-wechat"></i></NavLink>
			//         <NavLink to="" target="_blank"><i className="wim-icon-weibo"></i></NavLink>
			//     </div>
			// </div>
		);
	}

	/*
	 * if has specific url for sharing, only display social networks
	 *   which used for sharing specific page
	 *
	 * <i
	 * 	role="button" className="wim-icon-wechat" title="WeChat" onClick={() => { this.openShare('wechat', url) }}></i>
	 *
	 */
	getPopup(view, arrow, url) {
		if (url) {
			return (
				<div className={`share-popup ${view}`}>
					<div className={`share-box-arrow ${arrow}`} />
					<div className="share-icons">
						<i
							role="link"
							className="wim-icon-facebook"
							aria-label="Facebook social media"
							onClick={() => {
								this.openShare('facebook', url);
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('facebook', this.state.url) : null)}
							tabIndex={0}
						/>
						<i
							role="link"
							className="wim-icon-x"
							aria-label="X social media"
							onClick={() => {
								this.openShare('twitter', url);
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('twitter', this.state.url) : null)}
							tabIndex={0}
						/>
						<i
							role="link"
							className="wim-icon-weibo"
							aria-label="Weibo social media"
							onClick={() => {
								this.openShare('weibo', url);
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('weibo', this.state.url) : null)}
							tabIndex={0}
						/>
						{/* <i
							role="link" className="wim-icon-googleplus" title="Google+" onClick={() => { this.openShare('googleplus', url) }}></i> */}
					</div>
				</div>
			);
		} else {
			return (
				<div className={`share-popup ${view}`}>
					<div className={`share-box-arrow ${arrow}`} />
					<div className="share-icons">
						<i
							role="link"
							className="wim-icon-facebook"
							aria-label="Facebook social media"
							onClick={() => {
								this.openShare('facebook');
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('facebook', this.state.url) : null)}
							tabIndex={0}
						/>
						<i
							role="link"
							className="wim-icon-x"
							aria-label="X social media"
							onClick={() => {
								this.openShare('twitter');
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('twitter', this.state.url) : null)}
							tabIndex={0}
						/>
						<i
							role="link"
							className="wim-icon-instagram"
							aria-label="Instagram social media"
							onClick={() => {
								this.openShare('instagram');
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('instagram', this.state.url) : null)}
							tabIndex={0}
						/>
						<i
							role="link"
							className="wim-icon-youtube"
							aria-label="YouTube social media"
							onClick={() => {
								this.openShare('youtube');
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('youtube', this.state.url) : null)}
							tabIndex={0}
						/>
						<i
							role="link"
							className="wim-icon-wechat"
							aria-label="WeChat social media"
							onClick={() => {
								this.openShare('wechat');
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('wechat', this.state.url) : null)}
							tabIndex={0}
						/>
						<i
							role="link"
							className="wim-icon-weibo"
							aria-label="Weibo social media"
							onClick={() => {
								this.openShare('weibo');
							}}
							onKeyDown={() => (e.key === 'Enter' ? this.openShare('weibo', this.state.url) : null)}
							tabIndex={0}
						/>
						{/* <i
							role="button" className="wim-icon-googleplus" title="Google+" onClick={() => { this.openShare('googleplus') }}></i> */}
					</div>
				</div>
			);
		}
	}

	render() {
		if (this.state.type == 'gallery') {
			return this.getGalleryBar(this.state.color);
		}
		if (this.state.type == 'horiz') {
			return this.getHorizBar(this.state.color);
		} else if (this.state.type == 'popup') {
			return this.getPopup(this.state.view, this.state.orient, this.state.share);
		} else if (this.state.type == 'webview') {
			return null;
		}
	}
}

/*
 *  default props
 */
ShareMenu.defaultProps = {};

export default ShareMenu;

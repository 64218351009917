import React, { useContext } from 'react';
import { MatchArchiveContext } from '../context';
import WimLink from 'appdir/components/general/WimLink';
import op from 'object-path';

import MatchBox from 'appdir/components/scoring/MatchBox';

const ScoreDisplay = props => {
	const matchContext = useContext(MatchArchiveContext);

	//let match = op.get(props, 'currentPoint.displayScore', {});
	let match = props.currentMatch;
	//test data
	//match.eventCode = 'MS';
	//match.courtId = 'A';

	let point = op.get(props, 'currentPoint', {});
	let score = op.get(props, 'currentPoint.displayScore', {});
	let winner = op.get(props, 'currentPoint.matchWinner', '');
	let duration = op.get(props, 'currentPoint.duration', '');
	let status = op.get(props, 'currentPoint.status', '');
	let event = op.get(props, 'scoringConfig.eventNames', []).filter(event => {
		if (event.code == match.event) {
			return true;
		} else {
			return false;
		}
	})[0];
	let round = op.get(props, 'scoringConfig.roundNames', []).filter(round => {
		if (round.roundCode == match.round) {
			return true;
		} else {
			return false;
		}
	})[0];
	let court = match.courtId == 'A' ? 'Centre Court' : match.courtId == 'B' ? 'No.1 Court' : '';
	let server = op.get(props, 'currentPoint.server', '');
	let nextServer = op.get(props, 'currentPoint.nextServer', '');
	if (nextServer && nextServer != server) {
		server = nextServer;
	}

	//logger.log('[ScoreDisplay] match: %o', match);
	//logger.log('[ScoreDisplay] point: %o', JSON.stringify(point));

	//logger.log('[ScoreDisplay] scoringConfig: %o', props.scoringConfig);
	//logger.log('[ScoreDisplay] event: %o', event);

	let matchData = {
		match_id: match.id,
		eventName: event ? event.long : '',
		shortEventName: event ? event.short : '',
		eventCode: event ? event.code : '',
		courtName: court,
		shortCourtName: court,
		courtId: match.courtId,
		roundCode: match.round,
		roundName: round ? round.roundName : '',
		roundNameShort: round ? round.roundNameShort : '',
		eventDay: 18,
		//duration: duration,
		duration: -1, //hide until can validate data
		statsLevel: 'H',
		status: status == 'A' ? 'In Progress' : '',
		statusCode: status,
		winner: winner,
		server: server == 'srv1' ? 'A' : server == 'srv2' ? 'C' : '',
		epoch: 1562692721000,
		team1: {
			firstNameA: match.players[0].firstName,
			lastNameA: match.players[0].lastName,
			displayNameA: match.players[0].firstName.substr(0, 1) + '. ' + match.players[0].lastName,
			idA: match.players[0].id,
			nationA: match.players[0].nation,
			firstNameB: null,
			lastNameB: null,
			displayNameB: null,
			idB: null,
			nationB: null,
			seed: match.players[0].seed,
			totalSetsWon: 0,
			won: winner == '1' ? true : false,
			serve: server == 'srv1' ? true : false,
		},
		team2: {
			firstNameA: match.players[1].firstName,
			lastNameA: match.players[1].lastName,
			displayNameA: match.players[1].firstName.substr(0, 1) + '. ' + match.players[1].lastName,
			idA: match.players[1].id,
			nationA: match.players[1].nation,
			firstNameB: null,
			lastNameB: null,
			displayNameB: null,
			idB: null,
			nationB: null,
			seed: match.players[1].seed,
			totalSetsWon: 2,
			won: winner == '2' ? true : false,
			serve: server == 'srv2' ? true : false,
		},
		scores: {
			setsWon: [],
			setDurations: [],
			sets: op.get(score, 'sets', []),
			gameScore: op.get(score, 'gameScore', [null, null]),
		},
	};
	logger.log('[ScoreDisplay] matchData: %o', matchData);

	return (
		<MatchBox
			attributes={{
				data: matchData,
				event: matchData.shortEventName,
				tableHeader: matchData.courtName,
				showLinks: false,
				style: 'live',
				isArchive: props.isArchive,
			}}
		/>
	);
};

export default ScoreDisplay;

/**
 * Example data
 * 
{
	"data": {
		"match_id": "2601",
		"eventName": "Ladies' Singles",
		"shortEventName": "Ladies' Singles",
		"eventCode": "LS",
		"courtName": "Centre Court",
		"shortCourtName": "Centre Court",
		"courtId": "A",
		"roundCode": "S",
		"roundName": "Semi-Finals",
		"roundNameShort": "SF",
		"eventDay": 18,
		"duration": "1:08",
		"statsLevel": "H",
		"status": "Completed",
		"statusCode": "D",
		"winner": "2",
		"epoch": 1562692721000,
		"team1": {
			"firstNameA": "Jelena",
			"lastNameA": "Ostapenko",
			"displayNameA": "J. Ostapenko",
			"idA": "wta319939",
			"nationA": "LAT",
			"firstNameB": null,
			"lastNameB": null,
			"displayNameB": null,
			"idB": null,
			"nationB": null,
			"seed": 12,
			"totalSetsWon": 0,
			"won": false,
			"serve": false
		},
		"team2": {
			"firstNameA": "Angelique",
			"lastNameA": "Kerber",
			"displayNameA": "A. Kerber",
			"idA": "wta311470",
			"nationA": "GER",
			"firstNameB": null,
			"lastNameB": null,
			"displayNameB": null,
			"idB": null,
			"nationB": null,
			"seed": 11,
			"totalSetsWon": 2,
			"won": true,
			"serve": false
		},
		"scores": {
			"setsWon": [
				2,
				2,
				2,
				0,
				0,
				0
			],
			"setDurations": [
				"34",
				"34"
			],
			"sets": [
				[
					{
						"score": 3,
						"scoreDisplay": "3",
						"tiebreak": null,
						"tiebreakDisplay": null,
						"challenge_total": 1,
						"challenge_won": 0,
						"challenge_lost": 1,
						"challenge_remaining": 2
					},
					{
						"score": 6,
						"scoreDisplay": "6",
						"tiebreak": null,
						"tiebreakDisplay": null,
						"challenge_total": 0,
						"challenge_won": 0,
						"challenge_lost": 0,
						"challenge_remaining": 3
					}
				],
				[
					{
						"score": 3,
						"scoreDisplay": "3",
						"tiebreak": null,
						"tiebreakDisplay": null,
						"challenge_total": 2,
						"challenge_won": 0,
						"challenge_lost": 2,
						"challenge_remaining": 1
					},
					{
						"score": 6,
						"scoreDisplay": "6",
						"tiebreak": null,
						"tiebreakDisplay": null,
						"challenge_total": 0,
						"challenge_won": 0,
						"challenge_lost": 0,
						"challenge_remaining": 3
					}
				]
			],
			"gameScore": [
				null,
				null
			]
		}
	},
	"event": "Ladies' Singles",
	"tableHeader": "Centre Court",
	"showLinks": true
}
 */

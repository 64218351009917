import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const commentaryShow = Config.commentary.show;
			const pathObj = { commentaryShow };
			dispatch({
				type: deps.actionTypes.LIVENAV_MOUNTED,
				data: pathObj,
			});
		});
	},
};

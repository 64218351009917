import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const loadWeatherDataHelper = (dispatch, getState) => {
	let config = getState().Config;
	let state = getState().WeatherData;
	let last = moment(state.updated);

	//log('[WeatherData] loadWeatherDataHelper - status:%o config:%o', state.status, config);

	dispatch({ type: deps.actionTypes.WEATHERDATA_LOADING });

	return deps.services.WeatherData.fetch(`${config.otherData.weather.path}`)
		.then(result => {
			logger.log('[WeatherData] loadWeatherDataHelper - data:%o', result);

			dispatch({
				type: deps.actionTypes.WEATHERDATA_LOADED,
				updated: moment().toISOString(),
				status: 'loaded',
				data: result,
				seconds: config.otherData.weather.rateSec,
			});
		})
		.catch(err => {
			dispatch({
				type: deps.actionTypes.WEATHERDATA_ERROR,
				error: err,
			});
		});
};

const acts = {
	// if data is older than refresh rate, or data hasn't been loaded in longer than refresh rate, or no status
	//   then clear the data to prep for new load
	check: () => (dispatch, getState) => {
		let state = getState().WeatherData;
		let loadDiff = moment().diff(moment(state.updated), 'seconds', true); //difference from update date set in reducer
		let dataDiff = moment().diff(moment(state.update), 'seconds', true); //difference from data date in file

		if (loadDiff >= state.refreshSec || dataDiff >= state.refreshSec || state.status === null) {
			logger.log('[WeatherData] action.check - loadDiff:%o dataDiff:%o status:%o', loadDiff, dataDiff, 'expired');
			dispatch({
				type: deps.actionTypes.WEATHERDATA_EXPIRED,
			});

			logger.log('[WeatherData] action.check - expired');

			let prom = loadWeatherDataHelper(dispatch, getState);
			//log('[WeatherData] deps.actions.check - load promise:%o:', prom);
			return new Promise((resolve, reject) => {
				//log('[WeatherData] deps.actions.check - load promise:%o:', prom);
				prom.then(() => {
					//log('[WeatherData] deps.actions.check - load promise then:%o:', prom);
					resolve();
				}).catch(err => {
					reject({ error: 'config check error' });
				});
			});
		} else {
			logger.log('[WeatherData] action.check - loadDiff:%o dataDiff:%o status:%o', loadDiff, dataDiff, 'valid');
			//logger.log('[WeatherData] deps.actions.check - valid');

			dispatch({
				type: deps.actionTypes.WEATHERDATA_CHECK,
			});

			return new Promise((resolve, reject) => {
				resolve();
			});
		}
	},
};

export default {
	...acts,
};

import React, { Component } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';

/**
 * Component for diplaying table with user debenture tickets
 */

class TicketTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			showOverlay: false,
			overlaySeatData: null,
			overlaySeatDate: null,
		};

		this.hasSeats = false;
		if (this.state.seats && this.state.seatData) {
			this.hasSeats = true;
		}

		this.closeModal = this.closeModal.bind(this);

		// logger.log('[TicketTable] constructor - state:%o', this.state);
	}

	componentDidMount() {}

	componentDidUpdate() {}

	getTableSeatData(day, year) {
		// logger.log('[TicketTable] getTableSeatData - day:%o, year:%o', day, year);
		if (this.hasSeats) {
			let foundSeats = this.state.seatData.filter(seat => {
				return seat.day == day && seat.year == year;
			});

			if (foundSeats.length > 0) {
				return foundSeats;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	launchModal(day, date, year) {
		let seats = this.getTableSeatData(day, year);

		// logger.log('[TicketTable] launchModal - seats:%o', seats);

		if (seats.length > 0) {
			this.setState({
				showOverlay: true,
				overlaySeatData: seats,
				overlaySeatDate: date,
			});
		}
	}

	closeModal() {
		this.setState({
			showOverlay: false,
			overlaySeatData: null,
			overlaySeatDate: null,
		});
	}

	render() {
		let _this = this;

		return (
			<>
				<div className="four-col ticket-info">
					<div className="row headers gray">
						<div className="cell day">Day</div>
						<div className="cell date">Date</div>
						<div className="cell centrecourt">
							Centre<span className="no-show-mobile"> Court</span>
						</div>
						<div className="cell no1court">
							No.1<span className="no-show-mobile"> Court</span>
						</div>
						<div className="cell seats"></div>
					</div>
					{this.state.ticketData.map((t, i) => {
						let seatInfo = _this.getTableSeatData(t.day, t.year);
						let rowclass = '';
						if (i % 2 != 0) {
							rowclass = 'gray';
						}

						return (
							<div className={`row ${rowclass}`} key={`ticket-row-${i}`}>
								<div className="row-header">
									<div className="cell day">{t.day}</div>
									<div className="cell date">{moment(t.date).format('dddd Do MMMM')}</div>
								</div>
								<div className="cell centrecourt">{t.amountCentreCourtTickets}</div>
								<div className="cell no1court">{t.amountNo1Tickets}</div>
								<div className="cell seats">
									{seatInfo && seatInfo.length > 0 ? (
										<a
											onClick={() => {
												_this.launchModal(t.day, t.date, t.year);
											}}>
											View Tickets
										</a>
									) : null}
								</div>
							</div>
						);
					})}
				</div>

				{this.state.showOverlay ? (
					<div className="seats-overlay modal-overlay">
						<div className="seats-overlay-content">
							<div className="close">
								<CloseBtn closeFn={this.closeModal} />
							</div>
							<div className="seats-title">
								{moment(this.state.overlaySeatDate).format('dddd Do MMMM')}
							</div>
							{this.state.overlaySeatData.map(seat => {
								return (
									<div className="seats-row-content">
										<div className="seats-court">
											{seat.court == 'CC' ? 'Centre Court' : 'No.1 Court'}
										</div>
										<div className="seats-data-container">
											<div className="seats-gangway">
												<div className="seats-header">Gangway</div>
												{seat.staircase}
											</div>
											<div className="seats-row">
												<div className="seats-header">Row</div>
												{seat.row}
											</div>
											<div className="seats-seatno">
												<div className="seats-header">Seat No.</div>
												{seat.seat}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
			</>
		);
	}
}

export default TicketTable;

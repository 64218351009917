import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Skeleton from 'react-loading-skeleton';

const PlayerProfessionalInfo = ({ player }) => {
	let playerInfo = player.playerTourData;

	logger.log('[PlayerProfessionalInfo] :', playerInfo);

	let events = '';
	if (typeof playerInfo.event.events_entered === 'string') {
		events = playerInfo.event.events_entered;
		//logger.log('[PlayerProfessionalInfo] - 1 events:%o', events);
	} else {
		events = playerInfo.event.events_entered
			.map(obj => {
				return obj.event_name;
			})
			.join(', ');
		//logger.log('[PlayerProfessionalInfo] - 2 events:%o', events);
	}

	return (
		<span>
			<div className="two-col inline player-professional-info">
				<div className="professional-info-item text">
					<div className="professional-info">
						<div>
							<span className="professional-info-field">Championships Played:</span>{' '}
							<span className="professional-info-value">{playerInfo.event.num_played}</span>
						</div>
						<div>
							<span className="professional-info-field">Events Entered:</span>{' '}
							<span className="professional-info-value">{events}</span>
						</div>
						<div>
							<span className="professional-info-field">Best Singles:</span>{' '}
							<span className="professional-info-value">{playerInfo.event.best_singles}</span>
						</div>
						<div>
							<span className="professional-info-field">Best Doubles:</span>{' '}
							<span className="professional-info-value">{playerInfo.event.best_doubles}</span>
						</div>
					</div>
				</div>
				<div className="professional-info-item image">
					<img src="/assets/images/player-profile/trophy.png" />
				</div>
			</div>
			<div className="two-col inline player-professional-info">
				<div className="professional-info-item text">
					<div className="professional-info">
						<div>
							<span className="professional-info-field">Years Turned Pro:</span>{' '}
							<span className="professional-info-value">{playerInfo.turned_pro}</span>
						</div>
						<div>
							<span className="professional-info-field">Career Matches Won:</span>{' '}
							<span className="professional-info-value">{playerInfo.results.career.matches_won}</span>
						</div>
						<div>
							<span className="professional-info-field">Career Matches Lost:</span>{' '}
							<span className="professional-info-value">{playerInfo.results.career.matches_lost}</span>
						</div>
						<div>
							<span className="professional-info-field">High Rank for Singles:</span>{' '}
							<span className="professional-info-value">{playerInfo.rank.high_singles}</span>
						</div>
						<div>
							<span className="professional-info-field">High Rank for Doubles:</span>{' '}
							<span className="professional-info-value">{playerInfo.rank.high_doubles}</span>
						</div>
						<div>
							<span className="professional-info-field">Career Prize Money:</span>{' '}
							<span className="professional-info-value">{playerInfo.results.career.prize_money}</span>
						</div>
					</div>
				</div>
				<div className="professional-info-item image">
					<img src="/assets/images/player-profile/tennis-racquet.png" />
				</div>
			</div>
		</span>
	);
};

export default PlayerProfessionalInfo;

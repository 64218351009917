/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
// import { stageOptions } from 'appdir/components/pages/Slamtracker/Stage/StageResponsiveOptions';
// import DonutChart from 'appdir/components/pages/Slamtracker/Stage/DonutChart';
import MISectionHeader from './MISectionHeader';
const op = require('object-path');

/**
 * -----------------------------------------------------------------------------
 * React Component: By The Numbers for Match Insights
 * -----------------------------------------------------------------------------
 */
const ByTheNumbers = props => {
	logger.log('[ByTheNumbers] - props:%o', props);

	let { teams, infoIcon, showInfoTip } = props;

	let klass = 'mi-section ';
	klass += props.hideTitle ? 'notitle ' : '';
	klass += props?.windowSize == 'mobile' || props?.windowSize == 'tablet' ? 'no-border-bottom ' : '';

	const getByTheNumbersData = which => {
		let { p1_bullets, p2_bullets, p1_display_stat, p2_display_stat } = props.byTheNumbersData;
		let p1_filtered = [];
		let p2_filtered = [];
		let p1_maxcharcount;
		let p2_maxcharcount;
		let data;
		let statsdata;

		which = which.toString();

		if (p1_bullets.length >= 5) {
			p1_maxcharcount = 110;
		} else {
			p1_maxcharcount = 165;
		}

		if (p2_bullets.length >= 5) {
			p2_maxcharcount = 110;
		} else {
			p2_maxcharcount = 165;
		}

		p1_filtered = p1_bullets.filter(bullet => bullet.character_count <= p1_maxcharcount);

		p2_filtered = p2_bullets.filter(bullet => bullet.character_count <= p2_maxcharcount);

		if (which === '1') {
			data = p1_filtered;
			statsdata = p1_display_stat;
		}
		if (which === '2') {
			data = p2_filtered;
			statsdata = p2_display_stat;
		}

		return { data, statsdata };
	};

	const getByTheNumbersStatsSection = (datastat, team_num) => {
		let type = datastat['value'].includes('%') ? 'chart' : '';

		if (type == 'chart') {
			return (
				<div className="mi-stats-wrapper">
					<div className="mi-stats-label">{datastat['text']}</div>
					<div className={`chart-content team${team_num}`}>
						{/* <DonutChart options={getChartData(datastat, team_num)} /> */}
					</div>
				</div>
			);
		} else {
			let speedUnit = op.get(props, 'userPreference', 'M') == 'M' ? 'MPH' : 'KMH';
			let speedDataSplit = datastat['value'].split('|');
			let speedData = speedDataSplit.find(el => el.includes(speedUnit));

			return (
				<div className="mi-stats-wrapper">
					<div className="mi-stats-label">{datastat['text']}</div>
					<div className={`chart-content circle team${team_num}`}>
						<div className="circle-labels">
							<div className="value">{parseInt(speedData.substring(0, speedData.length - 4))}</div>
							<div className="unit">{speedUnit}</div>
						</div>
					</div>
				</div>
			);
		}
	};

	// const getChartData = (data, team_num) => {
	// 	let chart_data1 = parseInt(data['value'].slice(0, -1));
	// 	let chart_data = [chart_data1, 100 - chart_data1];
	// 	let chart_id = `select-chart_${team_num}`;
	// 	let chart_marks = [];
	// 	let chart_responsive_options;
	// 	let chart_label;

	// 	chart_responsive_options = stageOptions('embedded', 'matchinsight-single');
	// 	chart_label = '<div class="chart-value">' + chart_data1 + '<sup>%</sup></div>';

	// 	return {
	// 		id: chart_id,
	// 		data: chart_data,
	// 		marks: chart_marks,
	// 		sizes: chart_responsive_options,
	// 		label: chart_label,
	// 	};
	// };

	const renderByTheNumbers = (team, which) => {
		let dataset = getByTheNumbersData(which);
		let data = dataset['data'];
		let statsdata = dataset['statsdata'];

		// logger.log(
		// 	'[MatchInsights] renderByTheNumbers - which:%o, data:%o, statsdata:%o, props:%o',
		// 	which,
		// 	data,
		// 	statsdata,
		// 	props
		// );

		if (data.length > 0) {
			return (
				<div className="col" key={`player${which}-glance`}>
					{getByTheNumbersStatsSection(statsdata, which)}

					<ul className="spacious" role="list">
						{data.map((d, i) => {
							return <li key={`p${which}-b${i}`}>{d.text}</li>;
						})}
					</ul>
				</div>
			);
		} else {
			return (
				<div className="col" key={`player${which}-glance`}>
					{team.firstNameA} {team.lastNameA} did not compete in the Wimbledon Championships last year.
				</div>
			);
		}
	};

	return (
		<div className={klass}>
			<MISectionHeader
				title="By the Numbers"
				infoIcon={infoIcon}
				onInfoClickFn={props.onInfoClickFn}
				lastUpdate={props.byTheNumbersData['creation_date_milliseconds']}
				lastUpdatePos="right"
				hideTitle={props.hideTitle ? props.hideTitle : false}
			/>

			<div className="mi-player-glance column-layout">
				{teams.map((team, i) => {
					return renderByTheNumbers(team, i + 1);
				})}
			</div>

			{/* {showInfoTip ? (
				<LikelihoodWinOverlay
					teams={teams}
					powerIndexMatchupData={powerIndexMatchupData}
					imgPath={props.imgPath}
					showInfoTip={showInfoTip}
					onCloseClickFn={props.onCloseClickFn}
				/>
			) : null} */}
		</div>
	);
};

export default ByTheNumbers;

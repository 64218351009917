/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import GenericCarousel from 'appdir/components/common-ui/GenericCarousel';
import WimLink from 'appdir/components/general/WimLink';
import remove from 'lodash/remove';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: RoleNav
 * @props 
 * -----------------------------------------------------------------------------
 */

const RoleNav = (props) => {
	let { links } = props;
	remove(links, n => { return !n.url}); //filter out any that don't have a url

	/** Test data function */
	// const gentest = () => {
	// 	let outs = [];
	// 	let x = 0;
	// 	for(x; x < 10; x++){
	// 	outs.push(
	// 		// <div ref={x == 9 ? lastRef : null}>
	// 		<div>
	// 			<WimLink to={"/en_GB/home24.html"} key={`role-nav-${x}`}>
	// 				{`ref ${x*798900}`}
	// 			</WimLink>
	// 		</div>
	// 	)
	// 	}
	// 	return outs;
	// }

	const settings={

		variableWidth: true,
	}

	const metricsData = {
		pageTitle: "Role Nav", // to add a value for Arrow and Swipe to indicate which carousel it is
	};
	
	return (
		<div className={`role-nav ${props.scrolled ? 'scrolled' : ''} `}>
				<GenericCarousel sliderSettingsOverride={settings}  metricsData={metricsData} className={`roleNav-carousel`} >
					{
						links.map((link, idx) => {
							return (
								<div className="roleWrap" key={`role-${idx}`}>
									<WimLink to={link.url} target={link.target}>
										{link.shortTitle || link.title}
									</WimLink>
								</div>
							)
						})
					}
					
					{/* for test data */}
					{/* {gentest()} */}
					
				</GenericCarousel>
		</div>
	);
};

export default RoleNav;

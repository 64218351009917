import React from 'react';

const Rolex = (props) => {
	let iframeStyle = {};
	iframeStyle['width'] = props.width || '250px';
	iframeStyle['height'] = '70px';
	iframeStyle['border'] = '0';
	iframeStyle['margin'] = '0';
	iframeStyle['padding'] = '0';
	iframeStyle['overflow'] = 'hidden';
	iframeStyle['scroll'] = 'none';
	iframeStyle['display'] = 'block';
	return (
		<div className="rolex-clock" tabIndex={-1} aria-hidden>
			<div className="clock">
				<iframe
					src="https://assets.wimbledon.com/static/rolex/desktop/rolex.html"
					style={iframeStyle}
					scrolling="NO"
					frameBorder="NO"
					title="rolex"
					tabIndex={-1}
					aria-hidden
					role="none"
				/>
			</div>
		</div>
	);
};

export default Rolex;

import MobileAppsPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default {
	path: '/:lang/news/mobile.html',
	exact: true,
	component: MobileAppsPage,
	section: MENU_ITEMS.news,
	load: params => deps.actions.MobileAppsPage.mount(params),
};

import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class Download extends Component {
	
	constructor(props) {
		super(props);

		this.styles = props.styles;

		this.state = {
			...this.props
		}

		this.amp = props.amp;
		
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		let downloadUrl = op.get(this.props, 'streams.0.url', false);
		if(downloadUrl){
			var a = document.createElement('a');
			a.href = downloadUrl;
			a.download = this.props.title;
			a.style.display = 'none';
			a.target = "_blank";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			// window.open(downloadUrl);
		}
	}
	
	render() {
		logger.log('Download: render - props:%o', this.props);
		let inline = {};
		let data_state = '';
		

		// if (this.state.isDownload) {
		// 	data_state = 'fullscreen';
		// }
		// else {
		// 	data_state = 'normal';
		// }

		return (
			<button 
				className={`${this.styles.icon} ${this.styles.download}`} 
				style={inline} 
				onClick={() => {this.onClick()}} 
				data-state={data_state}
				title="Download">
			</button>
		)
	}
	
}

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';
import DrawsMenu from 'appdir/components/pages/DrawsPage/DrawsMenu';
import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import { doMeasurement } from 'appdir/components/general/Analytics';
import { isSlamTrackerActive, isPostMatch, isPreMatch } from 'appdir/components/pages/DrawsPage/DrawsUtils';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ScoreTable
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['DrawsMatchBox'],
		favourites: state['Controller'].favourites,
		searchedPlayer: state['DrawsPage'].searchedPlayer,
		searchRound: state['DrawsPage'].searchRound,
		selectedRound: state['DrawsPage']['eventRound'].selectedRound,
		timestamp: state['DrawsPage']['timestamp'],
		found: state['DrawsPage']['found'],
		...props,
	};

	this.onHover = this.onHover.bind(this);
};
const mapDispatchToProps = (dispatch, props) => ({
	updateFound: flag => dispatch(deps.actions.DrawsPage.updateFound(flag)),
	showPathToTheFinal: (playerId, event, top) =>
		dispatch(deps.actions.PathToTheFinal.showPathToTheFinal(playerId, event, top)),
	showSlamtracker: (matchId, defaultTab) => dispatch(deps.actions.SlamtrackerPanel.show(matchId, defaultTab)),
});

class DrawsMatchBox extends Component {
	constructor(props) {
		logger.log('[DrawsMatchBox] constructor - props:%o,', props);
		super(props);
		this.state = { ...props };

		this.scoreData = this.state.attributes.data;
		this.showLinks = this.state.attributes.showLinks;

		this.winnerTeam = this.scoreData.team1.won ? 'team1' : 'team2';

		const mainProfilePath = '/en_GB/players/overview/';
		const archiveProfilePath = './player_profile/';

		this.profileLinks = this.state.attributes.profileLinks === 'main' ? mainProfilePath : archiveProfilePath;

		this.boxStyle = this.state.attributes.style ? this.state.attributes.style : '';

		this.iconClasses_t1 = classNames('crticon', this.scoreData.team1.won ? 'winner' : '');
		this.iconClasses_t2 = classNames('crticon', this.scoreData.team2.won ? 'winner' : '');

		this.flagImagePathSmall = this.state.attributes.flagImagePathSmall;

		this.playerProfileImagePath = this.state.attributes.playerProfileImagePath;

		this.emptySets = [];
		this.scrollPlayer = false;
		for (var i = 0; i < 5 - this.scoreData.scores.sets.length && this.scoreData.scores.sets.length < 5; i++) {
			this.emptySets[i] = 5 - i;
		}
		this.drawRef = React.createRef();

		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.onNameClickHandler = this.onNameClickHandler.bind(this);
		this.setPlayerInfo = this.setPlayerInfo.bind(this);
		// this.openSlamtracker = this.openSlamtracker(this);

		this.mobileMatch = window.matchMedia('(max-width: 767px)').matches;

		//logger.log('[DrawsMatchBox] constructor - state:%o', this.props);
	}
	componentDidMount() {}
	componentWillUnmount() {}
	componentDidUpdate(prevProps, prevState) {
		if (!this.state.found) {
			this.scrollPlayer = true;
		}

		if (this.drawRef.current && this.scrollPlayer && this.state.searchedPlayer !== undefined) {
			let playerArry = [
				this.state.attributes.data.team1.idA,
				this.state.attributes.data.team1.idB,
				this.state.attributes.data.team2.idA,
				this.state.attributes.data.team2.idB,
			].filter(d => d !== null);
			let foundPlayer = playerArry.indexOf(this.state.searchedPlayer) !== -1 ? true : false;
			let round = parseInt(this.drawRef.current.dataset.round);
			if (foundPlayer && round === parseInt(this.state.searchRound)) {
				this.scrollPlayer = false;
				this.determinePlayerScroll();
			}
		}

		// if(this.state.playerInfo !== prevState.playerInfo) {}
	}

	determinePlayerScroll() {
		if (this.drawRef.current && this.drawRef.current !== null) {
			let el = this.drawRef.current;
			let elOffset = el.offsetTop;
			let offset = 106; //height of the header
			window.scroll({
				top: elOffset - offset,
				behavior: 'smooth',
			});
		}
		this.state.updateFound(true);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//logger.log('[DrawsMatchBox] componentWillReceiveProps nextProps:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		// logger.log('[DrawsMatchBox] componentWillReceiveProps state:%o',this.state);
	}

	onHover(pid) {
		if (!pid) {
			pid = '';
		}
		this.props.onHovered(pid);
	}

	getSlamtrackerButton(match_id, matchData) {
		return (
			<SlamtrackerButton
				match_id={match_id}
				slamtrackerActive={isSlamTrackerActive(matchData)}
				statusCode={matchData.statusCode}
				styleClass={'green'}
			/>
		);
	}

	getPlayerLink(id, name) {
		if (id) {
			return <WimLink to={`${this.profileLinks}${id}.html`}>{name}</WimLink>;
		} else {
			return name;
		}
	}

	checkEventStatus() {
		if (
			(this.scoreData.statusCode !== null && this.scoreData.statusCode == 'E') ||
			this.scoreData.statusCode == 'F' ||
			this.scoreData.statusCode == 'G'
		) {
			return (
				<div className="event-notes">
					<span className="update">{this.scoreData.status}</span>
				</div>
			);
		}
	}

	showMenu = (id, name) => {
		this.setPlayerInfo(id, name);
		if (!this.mobileMatch) {
			this.setState(
				{
					show: true,
				},
				() => {
					/** add a little delay so it doesn't immediately call this.closeMenu when showMenu is clicked */
					setTimeout(() => {
						document.addEventListener('click', this.closeMenu);
					}, "800");
					doMeasurement('Draws', 'playerNameMenu', ['open'], [{ player_id: id }, { state: 'open' }]);
				}
			);
		} else {
			/** for mobile view, directly open Path to the Final */
			this.showPathToTheFinal(id, this.props?.attributes?.data?.eventCode);
			// doMeasurement('Draws', 'playerMenuItem', [], [{ player_id: id }, { item: 'Path to the Finals' }]);
		}
		logger.log('[DrawsMatchBox] showMenu - showMenu:%o,', this);
	};

	closeMenu = id => {
		logger.log('[DrawsMatchBox] closeMenu - closeMenu:%o, e:%o', this);
		this.setState(
			{
				show: false,
			},
			() => {
				document.removeEventListener('click', this.closeMenu);
				doMeasurement('Draws', 'playerNameMenu', ['close'], [{ player_id: id }, { state: 'close' }]);
			}
		);
	};

	onOpenSlamtracker = (id, match_id, defaultTab = 'overview') => {
		if (match_id) {
			logger.log(
				'[DrawsMatchBox] onOpenSlamtracker - id:%o, match_id:%o, defaultTab:%o',
				match_id,
				match_id,
				defaultTab
			);

			doMeasurement(
				'Draws',
				'playerMenuItem',
				[],
				[{ player_id: id }, { match_id: match_id }, { item: 'Likelihood to Win' }]
			);

			this.props.showSlamtracker(match_id, defaultTab);
		}
	};

	showPathToTheFinal = (id, event = this.props?.attributes?.data?.eventCode) => {
		if (id) {
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (document.documentElement || document.body.parentNode || document.body).scrollTop;

			doMeasurement('Draws', 'Path To The Final', [], [{ player_id: id }]);
			this.props.showPathToTheFinal(id, event, scrollTop);
		}
	};

	setPlayerInfo = (id, name) => {
		logger.log('[DrawsMatchBox] setPlayerInfo - id:%o, name:%o', id, name);

		let playerInfo = {};

		if (id) {
			playerInfo.id = id;
		} else {
			playerInfo.id = '';
		}
		if (name) {
			playerInfo.name = name;
		} else {
			playerInfo.name = '';
		}

		logger.log('[DrawsMatchBox] setPlayerInfo - playerInfo:%o, this:%o', playerInfo, this);

		this.setState(prevState => {
			return {
				...prevState,
				playerInfo: playerInfo,
			};
		});
	};

	onNameClickHandler = (playerId, playerName) => {
		// logger.log('[DrawsMatchBox] onNameClick - playerId:%o, playerName:%o', playerId, playerName);

		let url;

		if (this.props.showPlayerMenu == false) {
			url = `${this.profileLinks}${playerId}.html`;
			return (window.location.href = url);
		} else {
			this.showMenu(playerId, playerName);
		}
	};

	getPlayerClasses = (base, playerId) => {
		let classes = base;
		if (window.webview) {
			logger.log('[DrawsMatchBox] getPlayerClass - playerId:%o,  favs:%o', playerId, this.props?.webViewFavs);
			if (this.props?.webViewFavs) {
				classes += this.props?.webViewFavs.indexOf(playerId) !== -1 ? ' favorite' : '';
			}
		} else {
			classes +=
				this.state.favourites.show && this.state.favourites.players.indexOf(playerId) !== -1 ? ' favorite' : '';
		}

		classes += this.state.searchedPlayer === playerId ? ' searchedPlayer' : '';

		return classes;
	};

	measureSlamtracker = (match_id, matchData) => {
		// logger.log('[DrawsMatchBox] measureSlamtracker - match_id:%o,', match_id);
		if (isSlamTrackerActive(matchData)) {
			doMeasurement('Draws', 'Slamtracker click', 'click', [{ match_id: match_id }]);
		}
	};

	renderRegularMatchBox() {
		let t1_higlight_condition =
			this.state.highlightPid !== '' &&
			(this.state.highlightPid === this.scoreData.team1.idA ||
				this.state.highlightPid === this.scoreData.team1.idB) &&
			(this.scoreData.team1.idA !== '' ||
				this.scoreData.team1.idB !== '' ||
				this.scoreData.team1.idA !== null ||
				this.scoreData.team1.idB !== null);

		let t2_higlight_condition =
			this.state.highlightPid !== '' &&
			(this.state.highlightPid === this.scoreData.team2.idA ||
				this.state.highlightPid === this.scoreData.team2.idB) &&
			(this.scoreData.team2.idA !== '' ||
				this.scoreData.team2.idB !== '' ||
				this.scoreData.team2.idA !== null ||
				this.scoreData.team2.idB !== null);

		let hilightClass_t1 = t1_higlight_condition ? 'highlight' : '';
		let hilightClass_t2 = t2_higlight_condition ? 'highlight' : '';

		let allowSlamtrackerLink = this.scoreData.courtId && this.scoreData.team1.idA && this.scoreData.team2.idA;

		let active = this.scoreData.team1.idA && this.scoreData.team2.idA;
		let isSlamtrackerActive = active && this.scoreData.courtId;

		//logger.log('[DrawsMatchBox] renderRegularMatchBox - state:%o', this.state);

		let matchStatusAriaLabel = '';
		if (this.scoreData?.status === 'Completed') {
			let winnerIndex = `team${this.scoreData?.winner}`;
			let defeatedIndex = winnerIndex === 'team2' ? 'team1' : 'team2';
			matchStatusAriaLabel = `${this.scoreData[winnerIndex]?.displayNameA} ${this.scoreData[winnerIndex]
				?.displayNameB || ''} defeated ${this.scoreData[defeatedIndex]?.displayNameA} ${this.scoreData[
				defeatedIndex
			]?.displayNameB || ''} ${this.scoreData[winnerIndex]?.totalSetsWon} sets to ${
				this.scoreData[defeatedIndex]?.totalSetsWon
			}`;
		} else {
			matchStatusAriaLabel = `${this.scoreData?.team1?.displayNameA} ${this.scoreData?.team1?.displayNameB ||
				''} plays ${this.scoreData?.team2?.displayNameA} ${this.scoreData?.team2?.displayNameB || ''}`;
		}

		return (
			<>
				<div
					className={`match-box match-box-draws ${this.boxStyle}`}
					data-dna="match-box-draws-page"
					data-match={this.scoreData.match_id}>
					<div className="match-status" tabIndex={0} aria-label={`match ${this.props.matchNumber}`}>
						<div className="green-border" />
						<div
							className={`team-info team-one ${
								this.scoreData.team1.displayNameB != null ? 'doubles' : 'singles'
							} ${hilightClass_t1}`}
							onMouseEnter={() => this.onHover(this.scoreData.team1.idA)}
							onMouseLeave={() => this.onHover('')}>
							<div className="team-name">
								<div className={`${this.getPlayerClasses('member-one', this.scoreData.team1.idA)}`}>
									<div className="flag">
										{this.flagImagePathSmall && this.scoreData.team1.nationA ? (
											<img
												src={this.flagImagePathSmall.replace(
													'<code>',
													this.scoreData.team1.nationA
												)}
											/>
										) : null}
									</div>
									<div className={`name`} id={`${this.scoreData.team1.idA}`}>
										<ButtonLinkText
											className=""
											name={this.scoreData.team1.displayNameA}
											ariaLabel={`${matchStatusAriaLabel}. ${this.scoreData.team1.displayNameA} bio`}
											onClick={() =>
												this.onNameClickHandler(
													this.scoreData.team1.idA,
													this.scoreData.team1.displayNameA
												)
											}>
											{this.scoreData.team1.displayNameA}
										</ButtonLinkText>
									</div>
									{this.scoreData.team1.entryStatus && (
										<div className="seed">{this.scoreData.team1.entryStatus}</div>
									)}
									{this.scoreData.team1.seed && (
										<div className={`seed ${this.scoreData.team1.seed ? '' : 'hide'}`}>
											{this.scoreData.team1.seed}
										</div>
									)}
								</div>
								{/* <div className="member-two" onClick={(e) => {this.onLinkClick(e,`${this.profileLinks}${this.scoreData.team1.idB}.html`)}}> */}
								<div className={`${this.getPlayerClasses('member-two', this.scoreData.team1.idB)}`}>
									<div className="flag">
										{this.flagImagePathSmall && this.scoreData.team1.nationB ? (
											<img
												alt={this.scoreData.team1.nationB}
												src={this.flagImagePathSmall.replace(
													'<code>',
													this.scoreData.team1.nationB
												)}
											/>
										) : null}
									</div>
									<div className={`name`} id={`${this.scoreData.team1.idB}`}>
										<ButtonLinkText
											className=""
											name={this.scoreData.team1.displayNameB}
											ariaLabel={`${this.scoreData.team1.displayNameB} bio`}
											onClick={() =>
												this.onNameClickHandler(
													this.scoreData.team1.idB,
													this.scoreData.team1.displayNameB
												)
											}>
											{this.scoreData.team1.displayNameB}
										</ButtonLinkText>
									</div>
									{this.scoreData.team1.entryStatus && (
										<div className="seed">{this.scoreData.team1.entryStatus}</div>
									)}
									{this.scoreData.team1.seed && (
										<div className={`seed ${this.scoreData.team1.seed ? '' : 'hide'}`}>
											{this.scoreData.team1.seed}
										</div>
									)}
								</div>
							</div>
							<div className={this.iconClasses_t1}>
								<i className="wim-icon-check" />
								<i className="wim-icon-ballSolid" />
							</div>
							<div className="match-results">
								<div className="results-wrapper">
									{/* <div className={this.iconClasses_t1}>
									<i className="wim-icon-check" />
									<i className="wim-icon-ballSolid" />
								</div> */}
									{this.scoreData.scores.sets.map((d1, i) => {
										let setno = parseInt(i, 10) + 1;
										return (
											<div key={i} className={`set set${setno}`}>
												<span className="scores">
													{d1[0].scoreDisplay}
													<span className="tiebreak">
														{d1[0].tiebreakDisplay ? d1[0].tiebreakDisplay : ''}
													</span>
												</span>
											</div>
										);
									})}
									{this.emptySets.map((set, i) => {
										return (
											<div key={i} className={`set set${set}`}>
												<span className="scores">
													<span className="tiebreak" />
												</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>
						<div className="results-info">
							<div className="results-info-pts">PTS</div>
							<div className="results-info-set">1</div>
							<div className="results-info-set">2</div>
							<div className="results-info-set">3</div>
							<div className="results-info-set">4</div>
							<div className="results-info-set">5</div>
						</div>
						<div
							className={`team-info team-two ${
								this.scoreData.team2.displayNameB != null ? 'doubles' : 'singles'
							} ${hilightClass_t2}`}
							onMouseEnter={() => this.onHover(this.scoreData.team2.idA)}
							onMouseLeave={() => this.onHover('')}>
							<div className="team-name">
								<div className={`${this.getPlayerClasses('member-one', this.scoreData.team2.idA)}`}>
									<div className="flag">
										{this.flagImagePathSmall && this.scoreData.team2.nationA ? (
											<img
												alt={this.scoreData.team2.nationA}
												src={this.flagImagePathSmall.replace(
													'<code>',
													this.scoreData.team2.nationA
												)}
											/>
										) : null}
									</div>
									<div className={`name`} id={`${this.scoreData.team2.idA}`}>
										<ButtonLinkText
											className=""
											name={this.scoreData.team2.displayNameA}
											ariaLabel={`${this.scoreData.team2.displayNameA} bio`}
											onClick={() =>
												this.onNameClickHandler(
													this.scoreData.team2.idA,
													this.scoreData.team2.displayNameA
												)
											}>
											{this.scoreData.team2.displayNameA}
										</ButtonLinkText>
									</div>
									{this.scoreData.team2.entryStatus && (
										<div className="seed">{this.scoreData.team2.entryStatus}</div>
									)}
									{this.scoreData.team2.seed && (
										<div className={`seed ${this.scoreData.team2.seed ? '' : 'hide'}`}>
											{this.scoreData.team2.seed}
										</div>
									)}
								</div>
								<div className={`${this.getPlayerClasses('member-two', this.scoreData.team2.idB)}`}>
									<div className="flag">
										{this.flagImagePathSmall && this.scoreData.team2.nationB ? (
											<img
												alt={this.scoreData.team2.nationB}
												src={this.flagImagePathSmall.replace(
													'<code>',
													this.scoreData.team2.nationB
												)}
											/>
										) : null}
									</div>
									<div className={`name`} id={`${this.scoreData.team2.idB}`}>
										<ButtonLinkText
											className=""
											name={this.scoreData.team2.displayNameB}
											ariaLabel={`${this.scoreData.team2.displayNameB} bio`}
											onClick={() =>
												this.onNameClickHandler(
													this.scoreData.team2.idB,
													this.scoreData.team2.displayNameB
												)
											}>
											{this.scoreData.team2.displayNameB}
										</ButtonLinkText>
									</div>
									{this.scoreData.team2.entryStatus && (
										<div className="seed">{this.scoreData.team2.entryStatus}</div>
									)}
									{this.scoreData.team2.seed && (
										<div className={`seed ${this.scoreData.team2.seed ? '' : 'hide'}`}>
											{this.scoreData.team2.seed}
										</div>
									)}
								</div>
							</div>
							<div className={this.iconClasses_t2}>
								<i className="wim-icon-check" />
								<i className="wim-icon-ballSolid" />
							</div>
							<div className="match-results">
								<div className="results-wrapper">
									{/* <div className={this.iconClasses_t2}>
									<i className="wim-icon-check" />
									<i className="wim-icon-ballSolid" />
								</div> */}
									{this.scoreData.scores.sets.map((d1, i) => {
										let setno = parseInt(i, 10) + 1;
										return (
											<div key={i} className={`set set${setno}`}>
												<span className="scores">
													{d1[1].scoreDisplay}
													<span className="tiebreak">
														{d1[1].tiebreakDisplay ? d1[1].tiebreakDisplay : ''}
													</span>
												</span>
											</div>
										);
									})}
									{this.emptySets.map((set, i) => {
										return (
											<div key={i} className={`set set${set}`}>
												<span className="scores">
													<span className="tiebreak" />
												</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>
						{this.checkEventStatus()}
					</div>

					{this.showLinks ? (
						<div
							className="match-box-buttons"
							onClick={() => {
								this.measureSlamtracker(this.scoreData.match_id, this.scoreData);
							}}>
							<Button className="compact watch-and-read" style={{ width: '100%' }}>
								Watch &amp; Read
							</Button>
							{/* <a className="watch-and-read">Watch &amp; Read</a> */}
							{/* {this.getSlamTrackerLinkBox(allowSlamtrackerLink)} */}
							{this.getSlamtrackerButton(this.scoreData.match_id, this.scoreData)}
						</div>
					) : null}
				</div>
				{this.state?.show && (
					<DrawsMenu
						openMenu={this.showMenu}
						closeMenu={this.closeMenu}
						playerInfo={this.state?.playerInfo}
						show={this.state.show}
						profileLinks={this.profileLinks}
						eventCode={this.scoreData.eventCode}
						match_id={this.scoreData.match_id}
						openSlamtracker={this.onOpenSlamtracker}
						isSlamtrackerActive={isSlamtrackerActive}
						showPathToTheFinal={playerId => {
							this.showPathToTheFinal(playerId, this.props?.attributes?.data?.eventCode);
						}}
					/>
				)}
			</>
		);
	}

	renderWinnerMatchBox() {
		let winner_higlight_condition =
			this.state.highlightPid !== '' &&
			(this.state.highlightPid === this.scoreData[this.winnerTeam].idA ||
				this.state.highlightPid === this.scoreData[this.winnerTeam].idB) &&
			(this.scoreData[this.winnerTeam].idA !== '' ||
				this.scoreData[this.winnerTeam].idB !== '' ||
				this.scoreData[this.winnerTeam].idA !== null ||
				this.scoreData[this.winnerTeam].idB !== null);

		let hilightClass_winner = winner_higlight_condition ? 'highlight' : '';

		return (
			<div
				className={`match-box match-box-draws winner ${this.boxStyle}`}
				data-match={this.scoreData.id}
				data-dna="match-box-draws-winner">
				<div className="match-status" tabIndex={0} aria-label={`match ${this.props.matchNumber}`}>
					<div className="green-border" />
					<div
						className={`team-info team-one ${
							this.scoreData[this.winnerTeam].displayNameB !== null ? 'doubles' : 'singles'
						} ${hilightClass_winner}`}
						onMouseEnter={() => this.onHover(this.scoreData[this.winnerTeam].idA)}
						onMouseLeave={() => this.onHover('')}>
						<div className="team-name">
							<div className={`member-one`}>
								<div className="flag">
									<img
										alt={this.scoreData[this.winnerTeam].nationA}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData[this.winnerTeam].nationA
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<WimLink to={`${this.profileLinks}${this.scoreData[this.winnerTeam].idA}.html`}>
										{this.scoreData[this.winnerTeam].displayNameA}
									</WimLink>
								</div>
								{this.scoreData[this.winnerTeam].entryStatus && (
									<div className="seed">{this.scoreData[this.winnerTeam].entryStatus}</div>
								)}
								{this.scoreData[this.winnerTeam].seed && (
									<div className="seed">{this.scoreData[this.winnerTeam].seed}</div>
								)}
							</div>
							<div className="member-two">
								<div className="flag">
									<img
										alt={this.scoreData[this.winnerTeam].nationB}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData[this.winnerTeam].nationB
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<WimLink to={`${this.profileLinks}${this.scoreData[this.winnerTeam].idB}.html`}>
										{this.scoreData[this.winnerTeam].displayNameB}
									</WimLink>
								</div>
								{this.scoreData[this.winnerTeam].entryStatus && (
									<div className="seed">{this.scoreData[this.winnerTeam].entryStatus}</div>
								)}
								{this.scoreData[this.winnerTeam].seed && (
									<div className="seed">{this.scoreData[this.winnerTeam].seed}</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		//logger.log('[DrawsMatchBox] render this:%o', this);
		this.emptySets.reverse();

		if (this.scoreData.roundCode === 'W' || this.scoreData.roundCode === 'WQ') {
			return (
				<div ref={this.drawRef} data-round={this.state.selectedRound}>
					{this.renderWinnerMatchBox()}
				</div>
			);
		} else if (this.scoreData.roundCode === 'C') {
			return (
				<div ref={this.drawRef} data-round={this.state.selectedRound}>
					{this.renderRegularMatchBox()}
					{this.renderWinnerMatchBox()}
				</div>
			);
		} else {
			return (
				<div ref={this.drawRef} data-round={this.state.selectedRound}>
					{this.renderRegularMatchBox()}
				</div>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawsMatchBox);

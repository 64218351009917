import React, { Component } from 'react';
import op from 'object-path';
import ContentLink from 'appdir/components/cms/ContentLink';

const categories = {
	'Getting Here': {
		order: 1,
	},
	'Food and Drink': {
		order: 2,
	},
	'Things to Do': {
		order: 3,
	},
	'Essential Information': {
		order: 4,
	},
	'Useful Information': {
		order: 5,
	},
	'Policies and Procedures': {
		order: 6,
	},
	'About Us': {
		order: 7,
	},
};

class CategoryTiles extends Component {
	constructor(props) {
		super(props);

		logger.log('[CategoryTiles] constructor - props:%o', props);

		this.state = {
			...props,
			data: props.data,
		};
	}

	componentDidMount() {
		logger.log('[CategoryTiles] componentDidMount');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		logger.log('[CategoryTiles] componentDidUpdate');
	}

	shouldComponentUpdate() {
		if (this.state.data) {
			return true;
		}
		return false;
	}

	groupItems(data) {
		if (data) {
			let category = '';
			let grouped = [];

			for (let i = 0; i < data.length; i++) {
				let itemCategory = op.get(data[i], 'metadata.category', null);
				let groupCat = grouped.find(group => group.id == itemCategory);

				//logger.log('[CategoryTiles] itemCategory:%o groupCat:%o', itemCategory, groupCat);
				if (!groupCat) {
					grouped.push({
						id: itemCategory,
						items: [data[i]],
					});
				} else {
					groupCat.items.push(data[i]);
				}
			}

			//sort groups by designated sort order
			grouped.sort(function(a, b) {
				if (op.get(categories, `${a.id}.order`, 100) > op.get(categories, `${b.id}.order`, 100)) {
					return 1;
				} else if (op.get(categories, `${a.id}.order`, 100) < op.get(categories, `${b.id}.order`, 100)) {
					return -1;
				} else {
					return 0;
				}
			});

			//sort items in groups alphabetically
			for (let i = 0; i < grouped.length; i++) {
				grouped[i].items.sort(function(a, b) {
					if (a.title > b.ititle) {
						return 1;
					} else if (a.title < b.title) {
						return -1;
					} else {
						return 0;
					}
				});
			}

			return grouped;
		}

		return [];
	}

	getContent(group) {
		if (group.items.length > 0) {
			return (
				<section id={group.id} className="column-layout" key={group.id}>
					<a name={group.id} />
					<div className="letter-section-header section-header four-col">
						<div className="header-title">{group.id}</div>
					</div>
					{group.items.map((item, index) => {
						//logger.log('[CategoryTiles] getContent - %o', this.getDataObject(item));
						return <ContentLink data={this.getDataObject(item)} key={index} />;
					})}
				</section>
			);
		} else {
			return (
				<section id={group.id} className="column-layout" key={group.id}>
					<a name={group.id} />
				</section>
			);
		}
	}

	getDataObject(item) {
		let data = {
			style: 'one-col',
			title: item.title,
			text: item.title,
			images: {
				image: {
					url: item.images[0].medium,
				},
			},
			url: item.url,
		};

		return data;
	}

	render() {
		logger.log('[CategoryTiles] render - state:%o', this.state);

		let content = this.groupItems(this.state.data.content);

		return (
			<div className="content-list">
				{content.map((group, index) => {
					//logger.log('[SearchPage] render - item:%o', item);
					return this.getContent(group);
				})}
			</div>
		);
	}
}

export default CategoryTiles;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { relatedContentVideo } from 'appdir/components/data/Schema';

//const schema = require('appdir/components/data/Schema/index.js');
const v = new Validator();

/**
 * Generic function to request json data
 *  resolves if response is 200
 *  rejects if there is an error loading
 * @param {String} path
 */
export const getJson = path => {
	//let hdr = restHeaders();
	//logger.log('[Services] getJson - path:%o', path);
	//return axios.get(path, { headers: hdr }).then(({ data }) => data);

	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				//logger.log('[Services] getJson - response.data:%o', response.data);
				return response.data;
			}
		})
		.catch(error => {
			if (error.response && error.response.status == 404) {
				logger.log('[Services] getJson - error1:%o', error.response);
				throw error.response;
			} else {
				logger.log('[Services] getJson - error2:%o', error);
				throw error;
			}
		});
};

/**
 * Generic function to call hosted serverless functions
 *  tried 1st geo, then the 2nd if the first fails
 * @param {*} hosts
 * @param {*} name
 * @param {*} method
 * @param {*} data
 * @param {*} hdr
 * @returns
 */
export const callFunction = (hosts, name, method, data, hdr) => {
	logger.log(
		'[Services] callFunction - hosts:%o name:%o method:%o data:%o headers:%o',
		hosts,
		name,
		method,
		data,
		hdr
	);

	/**
	// select random inital geo
	let host = '';
	hosts = Array.isArray(hosts) ? hosts : [hosts];

	if (hosts.length > 0) {
		let div = 1/hosts.length;
		let rand = Math.random();
		let which = rand / div;
		let indx = Math.floor(which);
		host = hosts[indx];
		//logger.log('[Services] callFunction - length:%o rand:%o which:%o index:%o', hosts.length, rand, which, indx);
	}
	else {
		host = hosts[0];
	}
	 */
	let host = hosts[0] + name;
	logger.log('[Services] callFunction - host:%o', host);

	return axios
		.request({ method: method, url: host, data: data, headers: hdr })
		.then(response => {
			if (response.status === 200 && response?.data?.status == 'success') {
				return response.data;
			} else {
				throw new Error('bad response');
			}
		})
		.catch(error => {
			host = hosts[1] + name;
			return axios
				.request({ method: method, url: host, data: data, headers: hdr })
				.then(response => {
					//logger.log('[Services] callFunction - response:%o', response);
					if (response.status === 200 && response?.data?.status == 'success') {
						return response.data;
					} else {
						throw new Error('bad response');
					}
				})
				.catch(error => {
					if (error.response) {
						throw error.response;
					} else {
						throw error;
					}
				});
		});
};

/**
 * General function to get one or more json data file
 *   (seemingly only used on PDE at moment)
 * @param {*} path
 * @param {*} method
 * @param {*} jwt_token
 * @param {*} nullError
 * @param {*} params
 * @param {*} data
 * @returns
 */

export const getContent = (path, method, jwt_token, nullError, params, data = null) => {
	let hdr = restHeaders();
	logger.log('[Services] fetchContent - path:%o method:%o', path, method);
	if (Array.isArray(path)) {
		return axios
			.all(path.map(d => getSingleContent(d, method, jwt_token, nullError, params, data)))
			.then(
				axios.spread((...responses) => {
					return responses;
				})
			)
			.catch(err => {
				return err;
			});
	} else {
		return getSingleContent(path, method, jwt_token, nullError, params, data);
	}
};

export const getSingleContent = (path, method, jwt_token, nullError, params, data) => {
	let hdr = restHeaders();
	hdr['Authorization'] = `Bearer  ${jwt_token}`;
	logger.log('[Services] fetchContent - path:%o method:%o', path, method);
	let requestParams =
		method === 'get'
			? {
					method: method,
					url: path,
					params,
					headers: hdr,
			  }
			: {
					method: method,
					url: path,
					params,
					headers: hdr,
					data: { ...data },
			  };
	return axios
		.request({ ...requestParams })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			//logger.log('[ContentPageSecure] fetchContent - error:%o', error);
			if (!nullError) {
				if (error.response) {
					//logger.log('[ContentPageSecure] fetchContent - error1:%o', error.response);
					throw error.response;
				} else {
					//logger.log('[ContentPageSecure] fetchContent - error2:%o resp:%o', error, error.response);
					throw error;
				}
			} else {
				return null;
			}
		});
};

/**
 * Used to fetch CMS content
 *   supports fetching multiple paths (bit not sure where this is used)
 * @param {*} path
 * @param {*} method
 * @param {*} jwt_token
 * @param {*} nullError
 * @returns
 */
export const fetchContent = (path, method, jwt_token, nullError) => {
	let hdr = restHeaders();
	logger.log('[Services] fetchContent - path:%o method:%o', path, method);
	if (Array.isArray(path)) {
		return axios
			.all(path.map(d => fetchSingle(d, method, jwt_token, nullError)))
			.then(
				axios.spread((...responses) => {
					return responses;
				})
			)
			.catch(err => {
				return err;
			});
	} else {
		return fetchSingle(path, method, jwt_token, nullError);
	}
};

export const fetchSingle = (path, method, jwt_token, nullError) => {
	let hdr = restHeaders();
	logger.log('[Services] fetchContent - path:%o method:%o', path, method);
	return axios
		.request({
			method: method,
			url: path,
			data: {
				jwtToken: jwt_token,
			},
			headers: hdr,
		})
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			//logger.log('[ContentPageSecure] fetchContent - error:%o', error);
			if (!nullError) {
				if (error.response) {
					//logger.log('[ContentPageSecure] fetchContent - error1:%o', error.response);
					throw error.response;
				} else {
					//logger.log('[ContentPageSecure] fetchContent - error2:%o resp:%o', error, error.response);
					throw error;
				}
			} else {
				return null;
			}
		});
};

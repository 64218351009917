/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Time from 'appdir/components/common-ui/Time';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Visualise
 * -----------------------------------------------------------------------------
 */
const Visualise = props => {
	logger.log('[Visualise] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	//logger.log('[Visualise] - attr:%o', attributes);

	let image = attributes['image'];
	//logger.log('[Visualise] link:%o', link);

	if (attributes['link']) {
		return (
			<div className={`photo ${attributes['style']}`}>
				<WimLink to={attributes['link']}>
					<img src={image['url']} alt={image['title']} />
					<div className="photo-info">
						<div className="photo-title">{image['caption']}</div>
					</div>
				</WimLink>
			</div>
		);
	} else {
		return (
			<div className={`photo ${attributes['style']}`}>
				<img src={image['url']} alt={image['title']} />
				<div className="photo-info">
					<div className="photo-title">{image['caption']}</div>
				</div>
			</div>
		);
	}
};

export default Visualise;

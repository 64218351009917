import React, { Component } from 'react';
import Button from 'appdir/components/common-ui/Button';
import ExitQueueConfirmation from 'appdir/components/webviews/TicketResaleWebview/ExitQueueConfirmation.js'
import { handleExitResaleQueueEndpoint } from 'appdir/components/webviews/TicketResaleWebview/ticketResaleEndpoints';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import WebviewResaleQRcode from 'appdir/components/webviews/TicketResaleWebview/WebviewResaleQRcode.js';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getErrorText } from 'appdir/components/general/Util/Error';
import RefreshResaleQueue from 'appdir/components/webviews/TicketResaleWebview/RefreshResaleQueue.js';
import { measureInAppContext } from 'appdir/components/general/Analytics';

class WebviewResaleStatusView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showExitConfirmation: false,
			viewQRcode: false,
			windowHeight: window.innerHeight,
            ...props
        }
		this.updateWindowHeight = this.updateWindowHeight.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateWindowHeight);
		this.setState({ refreshTimeStamp: moment() })

		measureInAppContext({
			pageTitle:"Tickets",
			action: "Ticket Resale Queue",
			context: [{
				section: "Ticket Resale Queue"
			}],
			metricType: "state",
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowHeight);
	}

	updateWindowHeight() {
		this.setState({ windowHeight: window.innerHeight });
	}

	handleViewQRcode() {
		this.setState({ viewQRcode: true })
		measureInAppContext({
			pageTitle:"Ticket Resale",
			action: "QR Code",
			context: [{
                "section": "Ticket Resale",
            }],
			metricType: "state",
		});
	}

	render() {
        const { topTxt, exitQueueTxt, queueStatus, queueObj, configQueues } = this.props;
		const { showExitConfirmation, viewQRcode, windowHeight, refreshTimeStamp } = this.state;
		
		// check timestamp since socialQ doesn't always update the last_updated_time properly
		let lastUpdated = refreshTimeStamp?.tz('Europe/London')?.format("HH:mm");

		if (showExitConfirmation) {
			return (
				<ExitQueueConfirmation
					closeModal={() => this.setState({ showExitConfirmation: false})}
					getJWT={() => this.props.getJWT()}
					showModal={(data) => this.props.showModal(data)}
					queueAPIs={this.props.queueAPIs}
					queueObj={this.props.queueObj}
					exitQueueResponse={(status, resp) => this.props.exitQueueResponse(status, resp)}
					pageTitle="Resale Status Page"
				/>
			)
		}

		let court = configQueues.filter((queue) => queue?.id == queueObj?.queue_id)[0]?.name || '';

		if (viewQRcode) {
			return (
				<div className="resale-modal-overlay">
					<div
						className='qr-code-modal-content'
					>
						<div className="resale-modal-close-btn">
							<CloseBtn closeFn={() => this.setState({ viewQRcode: false })} />
						</div>
						<div className="ticket-action-modal-title">QR CODE</div>
						<div className="ticket-action-modal-divider-container">
							<div className="ticket-action-modal-title-divider" />
						</div>
						<div className="webview-ticket-queue-qr-code">
							<WebviewResaleQRcode booking_id={queueObj?.booking_id}/>
						</div>
					</div>
				</div>
			)
		}
		let queuePosition = queueObj?.position_in_queue_visitors || queueObj?.position_in_queue_visitors == 0  ? queueObj?.position_in_queue_visitors + 1 : queueObj?.position_in_queue_visitors;
		return (
			<div className="webview-ticket-queue" >
					{queueStatus !== '' ? 
						<div className='webview-ticket-queue-closed-cntr'>
							<div className="webview-ticket-queue-court">QUEUE {queueStatus?.toUpperCase()}</div>
							<div 
								className="webview-ticket-queue-position-header-closed"
								aria-label={`The queue for ${court} is now ${queueStatus}. Please rejoin another queue.`}
								tabIndex={0}
							>
									The queue for {court} is now {queueStatus}. Please rejoin another queue.
							</div>
						</div>
						:
						<div>
							<div className='ticket-resale-checkin-txt' tabIndex={0} aria-label={topTxt}>{topTxt}</div>
							<div className="webview-ticket-queue-court" tabIndex={0} aria-label={court}>{court}</div>
							<div className="webview-ticket-queue-position-header">Your queue position:</div>
							<div className="webview-ticket-queue-position-number">{queuePosition}</div>
							<div className="webview-ticket-queue-multiple-btn-cntr">
								<div className='webview-ticket-queue-multiple-btn'>
									<RefreshResaleQueue
										getResaleQueueConfig={(updateModal) => this.props.getResaleQueueConfig(updateModal) }
										getJWT={() => this.props.getJWT()}
										showModal={(data) => this.props.showModal(data)}
										queueAPIs={this.props.queueAPIs}
										updateQueueStatus={(res) => {
											// set new timestamp since socialQ doesn't always update the last_updated_time properly
											this.setState({ refreshTimeStamp: moment() })
											this.props.updateQueueStatus(res)
										}}
									/>
								</div>
								<div className='webview-ticket-queue-multiple-btn'>
									<Button
										role="button"
										aria-label="VIEW QR CODE"
										onClick={() => this.handleViewQRcode()}
									>VIEW QR CODE</Button>
								</div>
							</div>
							<div className="webview-ticket-queue-last-updated" tabIndex={0} aria-label={`Last Updated: ${lastUpdated}`}>Last Updated: {lastUpdated} </div>
						</div>
					}
                
				<div className="webview-virtual-queue-barclays">
					<div className='webview-virtual-queue-barclays-text'>{this.props.configText?.barclays_text}</div>
					<img 
						src={'https://assets.wimbledon.com/images/queue/Wim_Barclays.png'}
					/>
				</div>
				<div className={windowHeight < 638 ? "webview-ticket-queue-exit-queue-cntr-relative" : "webview-ticket-queue-exit-queue-cntr"}>
					{queueStatus == 'closed' ? null : <div className="webview-ticket-queue-exit-queue-txt">{exitQueueTxt}</div>}
					{queueStatus == 'closed' ?
						<Button
							role="button"
							aria-label="REJOIN ANOTHER QUEUE"
							onClick={() => {
								this.props.getJWT().then(token => {
									this.props.showModal({
										showModal: true,
										showCloseBtn: false,
										type: 'updating',
										title: 'Exiting Queue',
										message: 'Please do not close the window while submitting.',
										useTimer: false,
									});
						
									let path = this.props.queueAPIs?.resaleUpdateQueue;
									handleExitResaleQueueEndpoint(token.jwt.id_token, path, queueObj?.booking_id).then((response) => {
										logger.log('[WebviewResaleStatusView] handleExitResaleQueueEndpoint response %o', response);
										this.props.showModal({
										    showModal: false,
										    showCloseBtn: true,
										    type: 'success',
										    title: 'Success',
										    message: 'You have exited the queue',
										    useTimer: false,
										});
										this.props.exitQueueResponse('success', response)
									}).catch(error => {
										logger.error('[WebviewResaleStatusView] handleExitResaleQueueEndpoint - error:%o', error);
										this.props.showModal({
											showModal: true,
											showCloseBtn: true,
											type: 'error',
											title: 'Error Exiting Queue',
											message: getErrorText(error?.code || error?.data?.response?.error?.code, "Resale Status Page"),
											useTimer: false,
										});
									});
								});
							}}
						>REJOIN ANOTHER QUEUE</Button> 
					:
						<Button
							role="button"
							aria-label="EXIT QUEUE"
							onClick={() => {
								logger.log('exit queue button clicked. call endpoint');
								this.setState({ showExitConfirmation: true })
							}}
						>EXIT QUEUE</Button>}
				</div>

			</div>
		);
	}
}

export default WebviewResaleStatusView;

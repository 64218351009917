/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import op from 'object-path';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Timeline from './timeline';
import TimelineMobile from './timeline_mobile';
import EventSelect from './EventSelect';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import DrawsLists from 'appdir/components/scoring/DrawsLists';
import WimLink from 'appdir/components/general/WimLink';
import Template from 'appdir/components/Template';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: CountryStatisticsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['DrawsArchiveYear'],
		windowSize: state['PageHeader'].windowSize,
		drawsArchiveHistoryYearsList: op.get(state['Config'].cmsData, 'drawsArchiveHistoryYearsList', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.DrawsArchiveYear.mount()),
	setYear: year => dispatch(deps.actions.DrawsArchiveYear.setYear(year)),
	filter: settings => dispatch(deps.actions.DrawsArchiveYear.filter(settings)),
});

const mobileMatch = window.matchMedia('(max-width: 767px)');
const tabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
const desktopMatch = window.matchMedia('(min-width: 1024px)');

class DrawsArchiveYear extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		this.firstLoad = true;
		this.loadedJson = '';
		this.historyYearsListLoaded = false;
		this.yearsListLoading = false;
		this.dataYearPath = '';

		this.formattedData;
		this.challengeRoundExists = false;
		this.totalRoundsNum = '';

		// used to get the round_name when round_name is null in the feed
		// W and WQ are made up to use for winners data
		this._roundNameLookUp = {
			'1': 'Round 1',
			'2': 'Round 2',
			'3': 'Round 3',
			'4': 'Round 4',
			Q: 'Quarter-final',
			S: 'Semi-final',
			F: 'Final',
			C: 'Challenge Match',
			W: 'Winner',
			WQ: 'Qualifiers',
		};

		this._windowSizeLookUp = {
			undefined: 0,
			mobile: 1,
			tablet: 2,
			desktop: 3,
		};

		logger.log('[DrawsArchiveYear] constructor - state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[DrawsArchiveYear] componentWillReceiveProps - nextProps:%o, state:%o', nextProps, this.state);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				windowSize: nextProps.windowSize,
			};
		});
	}

	componentDidUpdate(prevState) {
		logger.log('[DrawsArchiveYear] componentDidUpdate - state:%o', this.state);

		if (!this.historyYearsListLoaded && !this.state.historyYears && this.state.drawsArchiveHistoryYearsList) {
			deps.services.DrawsArchiveYear.fetch(this.state.drawsArchiveHistoryYearsList).then(result => {
				logger.log('[DrawsArchiveYear] componentDidUpdate historyYears - result %o', result);
				this.setState({
					historyYears: result,
				});
				this.historyYearsListLoaded = true;
			});
		}

		//  this is for when arrow nav is clicked to update the view to show new selected rounds
		if (
			this.state.filters.firstVisibleRound !== prevState.filters.firstVisibleRound &&
			(!this.firstLoad ||
				this.state.filters.year === prevState.filters.year ||
				this.state.filters.eventId === prevState.filters.eventId)
		) {
			this.setState({
				groupedMatches: null,
			});

			this.setColumnData(false, this.state.filters.firstVisibleRound);
			// logger.log('[DrawsArchiveYear] componentDidUpdate, firstVisibleRound Updates- this.state %o, state.firstVisibleRound:%o, prevState.firstVisibleRound:%o', this.state, this.state.filters.firstVisibleRound, prevState.filters.firstVisibleRound);
		}

		let dataPath = this.state.allEventsCode;

		if (!this.state.eventsAll && dataPath && !this.yearsListLoading) {
			this.yearsListLoading = true;
			deps.services.DrawsArchiveYear.fetch(dataPath)
				.then(result => {
					logger.log('[DrawsArchiveYear] componentDidUpdate - result %o', result);
					if (result) {
						this.setState({
							eventsAll: result,
						});
					} else {
						this.setState({
							eventsAll: {},
						});
					}
				})
				.catch(error => {
					logger.log('[DrawsArchiveYear] componentDidUpdate - getYears error %o', error);
				});
		}

		let dataYearPath = '';

		if (this.state.filters && this.state.filters.eventId && this.state.filters.year && this.state.yearData) {
			dataYearPath = this.state.yearData
				.replace('<eventId>', this.state.filters.eventId)
				.replace('<year>', this.state.filters.year);
		}

		// if(this.state.eventsAll) {
		// when eventId or year is changed, update eventsYear data

		//logger.log('[DrawsArchiveYear] componentDidUpdate - this.dataYearPath:%o dataTearPath:%o', this.dataYearPath, dataYearPath);
		if (dataYearPath && this.firstLoad && this.state.eventsAll) {
			this.getYearData(dataYearPath);
			this.dataYearPath = dataYearPath;
			this.firstLoad = false;
		} else if (!this.firstLoad && dataYearPath != this.dataYearPath) {
			this.dataYearPath = dataYearPath;
			logger.log('[DrawsArchiveYear] componentDidUpdate - getYears state:%o prevState:%o', this.state, prevState);
			this.getYearData(dataYearPath, prevState);
		}
		// }

		// window on resize
		if (
			this.state.filters.firstVisibleRound !== undefined &&
			this.state.windowSize !== prevState.windowSize &&
			this.state.filters.firstVisibleRound === prevState.filters.firstVisibleRound
		) {
			let diff = this._windowSizeLookUp[prevState.windowSize] - this._windowSizeLookUp[this.state.windowSize];
			this.onWindowSizeChange(diff);
		}
	}

	getYearData(dataYearPath, prevState) {
		// clear out the data till new data is available to show spinner
		this.props.filter({
			open: '',
			firstVisibleRound: null,
		});

		this.setState({
			groupedMatches: null,
			eventsYear: null,
		});

		this.formattedData = null;
		this.totalRoundsNum = null;

		deps.services.DrawsArchiveYear.fetch(dataYearPath)
			.then(result => {
				//logger.log('[DrawsArchiveYear] getYearData - result %o, dataYearPath:%o', result, dataYearPath);
				this.setState({
					eventsYear: result,
					eventsDropdownByYear: null,
				});

				// pass prevState to compare if year vaue is changed in setDropdownVals()
				if (prevState) {
					this.setDropdownVals(prevState);
				} else {
					this.setDropdownVals();
				}

				this.isChallengeRoundExists(result);
				this.formatData(this.groupByRound('round', result));

				logger.log('[DrawsArchiveYear] getYearData - this.state %o', this.state);
			})
			.catch(error => {
				logger.log('[DrawsArchiveYear] getYearData - error %o, dataYearPath:%o', error, dataYearPath);
			});
	}

	setDropdownVals(prevState) {
		let newDropdownData;

		if (this.state.eventsAll) {
			// find the array index based on the selected year
			let index = this.state.eventsAll.map(o => o.year.toString()).indexOf(this.state.filters.year.toString());

			newDropdownData = this.state.eventsAll[index].events;

			this.setState({
				eventsDropdownByYear: newDropdownData,
			});

			// when year in state is changed, check if the eventId in state exists in the new year data
			// if it doesn't exist, set the first eventId in state
			if (prevState) {
				if (
					this.state.filters.year !== prevState.filters.year &&
					!find(newDropdownData, { eventId: this.state.filters.eventId })
				) {
					this.props.filter({
						eventId: newDropdownData[0].eventId,
					});
				}
			}

			logger.log(
				'[DrawsArchiveYear] setDropdownVals - this.state %o, newDropdownData',
				this.state,
				newDropdownData
			);
		}
	}

	isChallengeRoundExists(resultData) {
		this.challengeRoundExists = false;

		// check if Challenge round exists so it can be moved to the last object in groupedMatches
		if (resultData[resultData.length - 1].round === 'C') {
			logger.info('[DrawsArchiveYear] isChallengeRoundExists - resultData:%o', resultData);
			this.challengeRoundExists = true;
		}
		// logger.info('[DrawsArchiveYear] isChallengeRoundExists - this.challengeRoundExists:%o', this.challengeRoundExists);
	}

	// set winner's data and update round name
	setWinnerData(groupedElements) {
		// get the last round
		let lastRoundIndex = this.challengeRoundExists ? this.totalRoundsNum - 1 : this.totalRoundsNum;
		let lastRoundMatch = groupedElements[lastRoundIndex];
		logger.info('[DrawsArchiveYear] setWinnerData - lastRound:%o', lastRoundMatch);

		// replace round code to W or WQ and apply the new round name
		const qualifyingCodes = ['QD', 'QS', 'BQ', 'GQ', 'RD', 'RS', 'NL', 'NM', 'NE', 'ND', 'NX'];
		if (indexOf(qualifyingCodes, lastRoundMatch[0].event) === -1) {
			lastRoundMatch[0].round = 'W';
		} else {
			lastRoundMatch.map(item => {
				item.round = 'WQ';
			});
		}

		// update round name to be Winner or Qualifier
		lastRoundMatch[0].round_name = this._roundNameLookUp[lastRoundMatch[0].round];

		// logger.info('[DrawsArchiveYear] setWinnerData - lastRoundMatch:%o, groupedElements%o', lastRoundMatch, groupedElements);

		return lastRoundMatch;
	}

	// grouping eventsYear data by round
	groupByRound(propertyName, resultsData) {
		let groupedElements = {};
		let letterRound = [];
		let usedObjNum = '';
		let challengeRoundData;

		resultsData.map(item => {
			// e.g.) item = {id: "0", event:"RD", year: "2017", crt: "", round: "1", round_name: "Round 1", team1: {}, team2: {} }
			let value = item[propertyName];
			let objNum;

			// if the 'round' value is not number,
			// convert lettered Round, Q, S, F, C non numeric value to increment number so we can construct HTML using loop & set panel nums etc
			if (isNaN(value)) {
				// check if the lettered round is in the array
				// if not there, get the num based on how many keys exists in groupedElements
				// that will be used as an index number
				if (indexOf(letterRound, value) === -1) {
					letterRound.push(value);
					objNum = Object.keys(groupedElements).length;
					value = parseInt(objNum) + 1;
					usedObjNum = value;
				} else {
					value = usedObjNum;
				}
			}

			// if the groupElements index num is not defined, create one
			// if not just add item to the existing index
			let group = groupedElements[value];
			if (group === undefined) {
				group = [item];
				groupedElements[value] = group;
			} else {
				group.push(item);
			}
		});

		// set column numbers
		this.totalRoundsNum = Object.keys(groupedElements).length;

		// deep copy groupedElements and create winnerData obj so original data won't get mutated
		let copy = JSON.parse(JSON.stringify(groupedElements));
		let winnerData = this.setWinnerData(copy);

		// if challenge round exists, store challenge round data as challengeRoundData
		if (this.challengeRoundExists) {
			challengeRoundData = groupedElements[this.totalRoundsNum];
		}

		// insert winnerData to the oriinal groupedElements
		let lastRoundIndex = this.challengeRoundExists ? this.totalRoundsNum - 1 : this.totalRoundsNum;

		// add the winnerData to the gropedElements
		groupedElements[lastRoundIndex + 1] = winnerData;

		if (this.challengeRoundExists) {
			// add Challenged Round data to gropedElements
			groupedElements[lastRoundIndex + 2] = challengeRoundData;
		}

		// reset column numbers
		if (this.state.windowSize === 'mobile' && this.challengeRoundExists) {
			// hallenge match column is hidden on global view
			// adjust the totalRoundsNums accordingly
			this.totalRoundsNum = Object.keys(groupedElements).length - 1;
		} else {
			this.totalRoundsNum = Object.keys(groupedElements).length;
		}

		logger.log(
			'[DrawsArchiveYear] groupByRound - groupedElements%o, totalRoundsNum%o, winnerData%o',
			groupedElements,
			this.totalRoundsNum,
			winnerData
		);

		return groupedElements;
	}

	formatData(draws) {
		logger.log('[DrawsArchiveYear] formatData - draws%o', draws);
		let allGroupedMatches = {};

		allGroupedMatches = Object.keys(draws).map((keyName, keyIndex) => {
			// get roundGroup data
			let roundData = draws[keyName];
			// logger.log('[DrawsArchiveYear] formatData - roundData%o', roundData);

			return roundData.map(
				item =>
					// {
					//     columnA : {
					//         title: item.round_name,
					//         matches: [
					({
						match_id: item.id,
						eventCode: item.event,
						roundCode: item.round,
						roundName:
							item.round_name == null || item.round_name == ''
								? this._roundNameLookUp[item.round]
								: item.round_name,
						year: item.year,
						team1: {
							displayNameA: item.team1.playerA_tv_name,
							idA: item.team1.playerA_id,
							nationA: item.team1.playerA_nat,
							displayNameB: item.team1.playerB_tv_name == '' ? null : item.team1.playerB_tv_name,
							idB: item.team1.playerB_id,
							nationB: item.team1.playerB_nat,
							seed: item.team1.seed,
							won: item.team1.won,
						},
						team2: {
							displayNameA: item.team2.playerA_tv_name,
							idA: item.team2.playerA_id,
							nationA: item.team2.playerA_nat,
							displayNameB: item.team2.playerB_tv_name == '' ? null : item.team2.playerB_tv_name,
							idB: item.team2.playerB_id,
							nationB: item.team2.playerB_nat,
							seed: item.team2.seed,
							won: item.team2.won,
						},
						scores: {
							sets: [
								[
									{
										scoreDisplay: item.team1.s1,
										tiebreakDisplay: item.team1.t1,
									},
									{
										scoreDisplay: item.team2.s1,
										tiebreakDisplay: item.team2.t1,
									},
								],
								[
									{
										scoreDisplay: item.team1.s2,
										tiebreakDisplay: item.team1.t2,
									},
									{
										scoreDisplay: item.team2.s2,
										tiebreakDisplay: item.team2.t2,
									},
								],
								[
									{
										scoreDisplay: item.team1.s3,
										tiebreakDisplay: item.team1.t3,
									},
									{
										scoreDisplay: item.team2.s3,
										tiebreakDisplay: item.team2.t3,
									},
								],
								[
									{
										scoreDisplay: item.team1.s4,
										tiebreakDisplay: item.team1.t4,
									},
									{
										scoreDisplay: item.team2.s4,
										tiebreakDisplay: item.team2.t4,
									},
								],
								[
									{
										scoreDisplay: item.team1.s5,
										tiebreakDisplay: item.team1.t5,
									},
									{
										scoreDisplay: item.team2.s5,
										tiebreakDisplay: item.team2.t5,
									},
								],
							],
						},
					})
				//         ]
				//     }
				// }
			);
		});

		this.formattedData = allGroupedMatches;

		// pass true to set initial firstVisibleRound state to be totalRoundNum
		this.setColumnData(true);
		// logger.log('[DrawsArchiveYear] formatData - allGroupedMatches%o', allGroupedMatches);
	}

	setView() {
		let visibleRoundNum;

		if (!this.state.filters.firstVisibleRound) {
			visibleRoundNum = this.totalRoundsNum;
		} else {
			visibleRoundNum = this.state.filters.firstVisibleRound;
		}

		if (this.state.windowSize === 'mobile') {
			this.props.filter({
				firstVisibleRound: visibleRoundNum,
			});
		} else if (this.state.windowSize === 'tablet') {
			this.props.filter({
				firstVisibleRound: visibleRoundNum > 1 ? visibleRoundNum - 1 : visibleRoundNum,
			});
		} else {
			this.props.filter({
				firstVisibleRound: visibleRoundNum > 2 ? visibleRoundNum - 2 : 1,
			});
		}
	}

	// when windowSize changes (set redux state in PageHeader), update firstVisibleRound
	// to get new set of column data
	onWindowSizeChange(n) {
		let newVisibleRoundNum;

		newVisibleRoundNum = this.state.filters.firstVisibleRound + n;

		if (newVisibleRoundNum > this.totalRoundsNum) {
			newVisibleRoundNum = this.totalRoundsNum;
		}

		if (newVisibleRoundNum < 1) {
			newVisibleRoundNum = 1;
		}

		this.props.filter({
			firstVisibleRound: newVisibleRoundNum,
		});

		logger.log(
			'[DrawsArchiveYear] onWindowSizeChange - this.state %o, newVisibleRoundNum %o',
			this.state,
			newVisibleRoundNum
		);
	}

	/**
	 * 1. groupByRound()  - create objects based on rounds
	 * 2. formatData()    - re-map the draws archive data (returned by groupByRound)
	 *                      to match main draws data to share DrawsLists component
	 * 3. setColumnData() - generate three columns data to pass to DrawsLists
	 */
	setColumnData(init, newVisibleRound) {
		if (this.formattedData) {
			let columnDrawsData = this.formattedData;

			if (init) {
				// initial load, set firstVisibleRound based on total round numbers defined in grouopByRound()
				this.setView();
			}

			if (this.state.filters.firstVisibleRound) {
				let visibleRound = newVisibleRound ? newVisibleRound : this.state.filters.firstVisibleRound;

				let colA = visibleRound - 1;
				let colB = colA + 1;
				let colC = colB + 1;

				let groupedMatches = {
					columnA: {
						title: columnDrawsData[colA][0].roundName,
						matches: columnDrawsData[colA],
					},
					columnB: {
						title: columnDrawsData[colB] ? columnDrawsData[colB][0].roundName : '',
						matches: columnDrawsData[colB] ? columnDrawsData[colB] : '',
					},
					columnC: {
						title: columnDrawsData[colC] ? columnDrawsData[colC][0].roundName : '',
						matches: columnDrawsData[colC] ? columnDrawsData[colC] : '',
					},
				};

				this.setState({
					groupedMatches: groupedMatches,
				});

				logger.log('[DrawsArchiveYear] formatData - groupedMatches%o', this.state.groupedMatches);
			}
		}
	}

	renderMatches() {
		return (
			// "flagImagePathLarge": this.state.data.flagImagePathLarge,
			// "playerProfileImagePath": this.state.data.playerProfileImagePath

			<div className="drawsList archive">
				<DrawsLists
					attributes={{
						data: this.state.groupedMatches,
						drawsListStyle: '',
						matchBoxStyle: '',
						showSlamTrackerLinks: false,
						profileLinks: 'archive',
						archiveYear: this.state.filters.year,
						flagImagePathSmall: this.state.MatchBoxConfig.flagImagePathSmall,
						playerProfileImagePath: this.state.MatchBoxConfig.playerProfileImagePath,
					}}
					filters={this.state.filters}
					showPlayerMenu={false}
				/>
			</div>
		);
	}

	renderEventsDropdown() {
		return (
			<div>
				<EventSelect
					year={this.state.filters.year}
					events={this.state.eventsDropdownByYear}
					filters={this.state.filters}
				/>
			</div>
		);
	}

	renderNewsArchiveLink() {
		let historyYearsData = op.get(this.state, 'historyYears', []);
		// logger.log('[DrawsArchiveYear] renderNewsArchiveLink - historyYearsData%o', historyYearsData);

		return (
			<div className="newsArchiveLink">
				{historyYearsData.length > 0 && historyYearsData.includes(this.state.filters.year.toString()) ? (
					<WimLink to={`/en_GB/draws_archive/history/${this.state.filters.year}.html`}>History</WimLink>
				) : null}
			</div>
		);
	}

	renderPDFAndNewsArchiveLinks() {
		return (
			<>
				<div className="archivePdf">
					<WimLink
						to={`${this.state.archiveDrawsAssetsPath}pdfs/draws/${this.state.filters.year}_${this.state.filters.eventId}_A4.pdf`}
						external={true}
						title="archive draws PDF"
						className="download">
						<i className="wim-icon-pdf" />
					</WimLink>
				</div>

				{this.renderNewsArchiveLink()}
			</>
		);
	}

	onClickArrow(n) {
		let newFirstVisibleRound = this.state.filters.firstVisibleRound + n;

		this.props.filter({
			firstVisibleRound: newFirstVisibleRound,
		});

		if (newFirstVisibleRound < 1) {
			newFirstVisibleRound = 1;
		}
		if (newFirstVisibleRound > this.totalRoundsNum) {
			if (this.state.windowSize === 'mobile') {
				newFirstVisibleRound = this.totalRoundsNum;
			} else if (this.state.windowSize === 'tablet') {
				newFirstVisibleRound = this.totalRoundsNum - 1;
			} else {
				newFirstVisibleRound = this.totalRoundsNum - 2;
			}
		}

		MeasurementUtils.dispatchMeasurementCall('drawsArchiveEventsPageRoundSelector', {
			round: newFirstVisibleRound,
		});
	}

	renderArrowsNav() {
		let hideClass_left;
		let hideClass_right;

		if (this.state.windowSize === 'mobile') {
			hideClass_right = this.state.filters.firstVisibleRound >= this.totalRoundsNum ? 'hide' : '';
		} else if (this.state.windowSize === 'tablet') {
			hideClass_right = this.state.filters.firstVisibleRound + 1 >= this.totalRoundsNum ? 'hide' : '';
		} else {
			hideClass_right = this.state.filters.firstVisibleRound + 2 >= this.totalRoundsNum ? 'hide' : '';
		}

		hideClass_left = this.state.filters.firstVisibleRound == 1 ? 'hide' : '';

		return (
			<div>
				<div
					className={`draws-arrow-left ${hideClass_left}`}
					onClick={() => {
						this.onClickArrow(-1);
					}}>
					<i className="wim-icon-left-arrow" />
				</div>

				<div
					className={`draws-arrow-right ${hideClass_right}`}
					onClick={() => {
						this.onClickArrow(1);
					}}>
					<i className="wim-icon-right-arrow" />
				</div>
			</div>
		);
	}

	// return Events Dropdown and MatchBoxes
	renderMainContents() {
		if (this.state.groupedMatches) {
			return (
				<div>
					{this.renderEventsDropdown()}

					{this.renderArrowsNav()}

					{this.renderPDFAndNewsArchiveLinks()}

					<div className="column-layout scoresgrid">{this.renderMatches()}</div>
				</div>
			);
		} else {
			return (
				<div>
					{this.renderEventsDropdown()}
					<div className="column-layout">
						<LoadingIndicator />
					</div>
				</div>
			);
		}
	}

	render() {
		if (this.state.eventsDropdownByYear) {
			logger.log(
				'[DrawsArchiveYear] render - state:%o, this.state.eventsDropdownByYear.length%o',
				this.state,
				this.state.eventsDropdownByYear.length
			);
		}

		let header_propsData = {
			headerType: 'drawsArchive',
			title: 'Draws Archive',
			imageSrc: '/assets/images/headers/drawsArchive_year.jpg',
			metaTitle: 'Draws Archive',
			metaDescription: 'Draws Archive for The Championships, Wimbledon.',
			metaDate: '',
			metaPlayers: '',
		};

		if (
			this.state.eventsAll &&
			this.state.eventsYear &&
			op.get(this.state, 'filters.year', false) &&
			this.state.eventsDropdownByYear
		) {
			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="drawsArchiveHdrContent dualDropdown">
						<Timeline year={this.state.filters.year} eventsAll={this.state.eventsAll} />
						<ArchiveMenu location={this.state.location.pathname} />
						<div className="mobile-timeline">
							<TimelineMobile year={this.state.filters.year} eventsAll={this.state.eventsAll} />
						</div>
					</div>
					<div className="content-main">
						{this.renderMainContents()}

						<div className="bottomLinks">{this.renderPDFAndNewsArchiveLinks()}</div>
					</div>

					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsArchiveYear);

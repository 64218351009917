/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import WimLink from 'appdir/components/general/WimLink';
import { connect } from 'react-redux';
import deps from 'dependencies';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import { Link } from 'react-router-dom';
import { Formik, FormikProps, Form, Field, ErrorMessage } from 'formik';
import { FormInput, FormEmail, FormSelect, FormTextarea, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import { countryLookup } from 'appdir/components/general/Util';
import { values } from 'appdir/main';
import * as yup from 'yup';
import Collapsible from 'react-collapsible';
import Button from 'appdir/components/common-ui/Button';
import { osName, osVersion, browserName, fullBrowserVersion, getUA } from 'react-device-detect';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { measureInWeb } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: Contact Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['ContactPage'],
		windowSize: state['PageHeader'].windowSize,
		...state['Gigya'],
		...props,
	};
	//return Object.assign({}, state['ContactPage'], props);
};
const countries = countryLookup;
const mobileMatch = window.matchMedia('(max-width: 767px)');
const tabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
const desktopMatch = window.matchMedia('(min-width: 1024px)');

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContactPage.mount()),
	contactPageSubmit: (v, f) => dispatch(deps.actions.ContactPage.contactPageSubmit(v, f)),
	newsletterSignUpSubmit: v => dispatch(deps.actions.ContactPage.newsletterSignUpSubmit(v)),
});

// const countries = countryLookup;
class ContactPage extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.countries = countryLookup;
		this.initialValues = {};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevState) {
		logger.log('[ContactPage] componentDidUpdate %o', this.state);
	}

	getTopic(val) {
		let value = null;
		if (this.state.topics) {
			this.state.topics.forEach(topic => {
				if (topic.value == val) {
					value = topic.label;
				}
			});
		}
		return value;
	}

	handleSubmit(form_values, actions) {

		measureInWeb({
			action: "Feedback",
			context: [{ action: "Submit" }],
		});


		actions.setSubmitting(false);

		form_values.eventid = values.eventId;
		form_values.os = osName + ' ' + osVersion + '?';
		form_values.apptype = 'Web';
		form_values.appVersion = window.version + ' - ' + window.commit;
		form_values.device = browserName + ' ' + fullBrowserVersion;
		form_values.userAgent = getUA;
		form_values.topic_label = this.getTopic(form_values.topic);

		logger.log('[ContactPage] onSubmit - values:%o', form_values);
		if (form_values.emailSignUp) {
			let newsletterValues = form_values;
			newsletterValues.emailAddress = form_values.email;
			this.props.newsletterSignUpSubmit(newsletterValues);
		}
		this.props.contactPageSubmit(form_values, () => {
			this.props.history.push('/' + this.props.match.params.lang + '/contact/ThankYou.html');
		});
		//logger.log('[ContactPage] onSubmit post - state:%o', this.state);
	}

	render() {
		logger.log('[ContactPage] render - state:%o', this.state);
		let header_propsData = {
			headerType: 'contact',
			title: 'Contact The AELTC',
			shortTitle: 'Contact The AELTC',
			metaTitle: 'Contact The AELTC',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		if (mobileMatch.matches == true) {
			header_propsData = {
				headerType: 'contact',
				title: 'Contact',
				shortTitle: 'Contact',
				metaTitle: 'Contact',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
			};
		}

		this.initialValues = {
			salutation: '',
			firstName: '',
			surname: '',
			email: '',
			dateOfBirth: '',
			postCode: '',
			country: '',
			topic: '',
			subject: '',
			comment: '',
			agreement: false,
		};

		//checking to see if user is logged in
		//to prepopulate contact form data
		if (this.state.loggedIn) {
			const { firstName, lastName, email } = this.state.currentUser.profile;
			const { title } = this.state.currentUser.data.profile;
			this.initialValues['salutation'] = title;
			this.initialValues['firstName'] = firstName;
			this.initialValues['surname'] = lastName;
			this.initialValues['email'] = email;
		}

		let ContactPageSchema = yup.object().shape({
			firstName: yup.string().required('Please supply a First Name.'),
			surname: yup.string().required('Please supply a Surname.'),
			email: yup
				.string()
				.email('Please supply a valid Email Address.')
				.required('Email Address is required.'),
			topic: yup.string().required('Please select a Topic.'),
			comment: yup.string().required('Please supply content for your Message.'),
			agreement: yup
				.bool()
				.required('Please comfirm you have read and understood how the All England Club will use your data.'),
		});
		if (this.state) {
			return (
				<Template className="contactpage">
					<div className="contact-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main column-layout contact-us">
						<div className="contact-header-left-align">
							If your query relates to ticketing, please ensure you have checked our{' '}
							<strong>
								<WimLink to="https://help.wimbledon.com">help centre</WimLink>
							</strong>{' '}
							before contacting us.
							<br />
							<br />
							This contact form is to support your enquiries related to the AELTC, to The Championships
							and to our year-round operations - including the Wimbledon Lawn Tennis Museum, the Wimbledon
							Foundation, the Community Tennis Centre at Raynes Park, and the Wimbledon Qualifying and
							Community Sports Centre at Roehampton.
						</div>

						<div className="contact-form">
							<Formik
								enableReinitialize
								initialValues={this.initialValues}
								onSubmit={this.handleSubmit}
								validationSchema={ContactPageSchema}>
								{formProps => {
									return this.renderForm(formProps);
								}}
							</Formik>
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="contactpage">
					<div className="contact-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}

	renderForm(formProps) {
		logger.log('[ContactPage] This is the formprops %o', formProps);
		logger.log('[ContactPage] state:%o', this.state);

		let isChecked = formProps.values.agreement;

		//logger.log('[ContactPage] isChecked:%o', formProps.values.agreement);

		return (
			<Form onSubmit={formProps.handleSubmit}>
				<div className="name-bar column-layout">
					<div className="field two-col">
						<FormInput
							label="First Name"
							name="firstName"
							placeholder="First Name"
							type="text"
							showerror={'true'}
							value={formProps.values.firstName || ''}
							required
						/>
					</div>
					<div className="field two-col">
						<FormInput
							label="Surname"
							name="surname"
							placeholder="Surname"
							type="text"
							showerror={'true'}
							value={formProps.values.surname || ''}
							required
						/>
					</div>
				</div>

				<div className="email-bar">
					<div className="field two-col">
						<FormEmail
							label="Email"
							name="email"
							placeholder="Email"
							type="email"
							value={formProps.values.email || ''}
							required
						/>
					</div>
					<div className="fields two-col">
						<FormInput
							label="Telephone Number"
							name="telephoneNumber"
							placeholder="Telephone Number"
							type="tel"
							value={formProps.values.telephoneNumber || ''}
						/>
					</div>
				</div>
				{/* <div className="bd-adr-bar column-layout">
					 <div className="one-third">
						 <Field label="Date of Birth *" name="dateOfBirth" placeholder="Date of Birth" component={this.renderDOB} value={formProps.values.dateOfBirth || ''} />
					 </div>
					 <div className="one-third">
						 <Field label="Zip/Postal Code *" name="postCode" placeholder="Postal Code" component={this.renderTextField} value={formProps.values.postCode || ''} />
					 </div>
					 <div className="one-third">
						 <Field label="Country *" name="country" component={this.renderCountryField} value={formProps.values.country || ''} />
					 </div>
				 </div> */}
				<div className="topic-subj-bar">
					<div className="field two-col">
						<FormSelect
							label="Topic"
							name="topic"
							value={formProps.values.topic || ''}
							showerror={'true'}
							required
							disabled={false}>
							<option key="topicKey" value="">
								-- Please Select A Topic --
							</option>
							{/* <option value="32">Accessibility</option>
							 <option value="35">Corporate Hospitality</option>
							 <option value="37">Employment</option>
							 <option value="30">Feedback</option>
							 <option value="34">Foundation &amp; Charity</option>
							 <option value="36">History &amp; Research</option>
							 <option value="43">Lost and Found</option>
							 <option value="31">Media</option>
							 <option value="38">Museum, Tours &amp; Learning</option>
							 <option value="48">Password reset / Login issues</option>
							 <option value="49">Registered Debenture Holders</option>
							 <option value="44">Security</option>
							 <option value="39">Ticketing</option>
							 <option value="40">Transport</option>
							 <option value="41">Visiting The Championships</option>
							 <option value="42">Web / Mobile Technical</option>
							 <option value="45">Wimbledon Shop</option>
							 <option value="47">myWIMBLEDON</option>
							 <option value="46">Other</option> */}
							{this.state.topics
								? this.state.topics.map(topic => {
										return (
											<option key={`${topic.value}`} value={`${topic.value}`}>
												{topic.label}
											</option>
										);
								  })
								: null}
						</FormSelect>
						{formProps.values.topic == 'ticketing' && (
							<p className="ticketing-msg">
								Please ensure you have checked our{' '}
								<WimLink to="https://help.wimbledon.com">help centre</WimLink> for the answer to your
								query as this will be the quickest way to access information about ticketing at The
								Championships.
							</p>
						)}
					</div>
				</div>

				<div className="date-of-visit-bar">
					<div className="field two-col">
						{/* <Field label="Date of Birth *" name="dateOfBirth" placeholder="Date of Birth" component={this.renderDOB} value={formProps.values.dateOfBirth || ''} /> */}
						<FormInput
							label="Date of Visit"
							name="dateOfVisit"
							placeholder=""
							type="date"
							value={formProps.values.dateOfVisit || ''}
						/>
					</div>
				</div>

				<div className="message-bar">
					<div className="field four-col">
						{/* <Field label="Message*" name="comment" component={this.renderMessageField} value={formProps.values.comment || ''} /> */}
						<FormTextarea
							label="Message"
							name="comment"
							type="textarea"
							rows="10"
							value={formProps.values.comment || ''}
							required
						/>
					</div>
				</div>

				<div className="form-opt-in">
					{/* <Field component="input" type="checkbox" name="agreement" id="agreement" value={formProps.values.agreement || false} />
					 <label htmlFor="agreement" className="wimCheck" /> */}
					<FormCheckBox name="agreement" id="agreement" />
					{/* value={formProps.values.agreement || false} */}
					<div className="opt-in-text">
						{' '}
						Please tick here to confirm you have read and understood how the All England Club will use your
						data: <WimLink to="/en_GB/about_wimbledon/privacy_policy.html">AELTC Privacy Policy</WimLink>
					</div>
				</div>

				<div className="contact-form-submit">
					{/* <div className="contact-disclaimer">
						 We will only use your data in line with our <WimLink to="/en_GB/about_wimbledon/privacy_policy.html">Privacy Policy</WimLink>, which you can read <WimLink to="/en_GB/about_wimbledon/privacy_policy.html">here</WimLink>.
					 </div> */}
					<Button
						className={`wide button solid${
							formProps.isSubmitting || (isChecked !== 'true' && !isChecked) || formProps.submitCount > 0
								? ' disabled'
								: ''
						}`}
						onClick={() =>
							!formProps.isSubmitting && (isChecked == 'true' || isChecked) && formProps.submitCount === 0
								? formProps.submitForm()
								: null
						}>
						Submit
					</Button>
				</div>
			</Form>
		);
	}

	// renderTitleField({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) {
	// 	return (
	// 		<FormSelect
	// 			label={label}
	// 			name={name}
	// 			disabled={false}
	// 			value={value}
	// 			required
	// 		>
	// 			<option value="">-- Please Select a Title --</option>
	// 			<option value="Mr">Mr</option>
	// 			<option value="Mrs">Mrs</option>
	// 			<option value="Miss">Miss</option>
	// 			<option value="Ms">Ms</option>
	// 			<option value="Dr">Dr</option>
	// 			<option value="Prof">Prof</option>
	// 			<option value="Rev">Rev</option>
	// 			<option value="Sir">Sir</option>
	// 			<option value="Dame">Dame</option>
	// 			<option value="Baroness">Baroness</option>
	// 			<option value="Lady">Lady</option>
	// 			<option value="Lord">Lord</option>
	// 			<option value="Rt Hon">Rt Hon</option>
	// 		</FormSelect>
	// 	);
	// }

	// renderTextField({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) {
	// 	return (
	// 		<FormInput
	// 			label={label}
	// 			name={name}
	// 			type="text"
	// 			required
	// 		/>
	// 		// <label>
	// 		// 	{label}
	// 		// 	<br />
	// 		// 	<input name={name} type="text" maxLength="100" {...props} onChange={onChange} onBlur={onBlur} />
	// 		// 	<ErrorMessage name={name} render={msg => <span className="form-error">{msg}</span>} />
	// 		// </label>
	// 	);
	// }

	renderDOB({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) {
		return (
			<label className="DOB">
				<div className="tooltip">
					We require a date of birth for all accounts to promote child safety in accordance with applicable
					laws and to provide you with the most relevant experience. Additionally, depending on your region, a
					parent or guardian's permission may be required to register.
				</div>
				Date of Birth
				<br />
				<input name={name} type="date" max="2021-07-11" {...props} onChange={onChange} onBlur={onBlur} />
				<ErrorMessage name={name} render={msg => <span className="form-error">{msg}</span>} />
			</label>
		);
	}

	// renderMessageField({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) {
	// 	return (
	// 		<label>
	// 			Message*
	// 			<br />
	// 			<textarea name={name} rows="10" onChange={onChange} onBlur={onBlur} />
	// 			<ErrorMessage name={name} render={msg => <span className="form-error">{msg}</span>} />
	// 		</label>
	// 	);
	// }

	renderCountryField({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) {
		return (
			<label>
				{label}
				<select name={name} disabled={false} onChange={onChange} onBlur={onBlur}>
					<option key="countryKey" value="">
						-- Select a Country --
					</option>
					{Object.keys(countries).map((d, i) => {
						return (
							<option key={i} value={d}>
								{countries[d]}
							</option>
						);
					})}
				</select>
				<i className="wim-icon-down-arrow" />
				<ErrorMessage name={name} render={msg => <span className="form-error">{msg}</span>} />
			</label>
		);
	}

	// renderTopicField({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) {
	// 	return (
	// 		<label>
	// 			Topic*
	// 			<select name={name} disabled={false} onChange={onChange} onBlur={onBlur}>
	// 				<option value="">-- Please Select A Topic --</option>
	// 				<option value="32">Accessibility</option>
	// 				<option value="35">Corporate Hospitality</option>
	// 				<option value="37">Employment</option>
	// 				<option value="30">Feedback</option>
	// 				<option value="34">Foundation &amp; Charity</option>
	// 				<option value="36">History &amp; Research</option>
	// 				<option value="43">Lost and Found</option>
	// 				<option value="31">Media</option>
	// 				<option value="38">Museum, Tours &amp; Learning</option>
	// 				<option value="48">Password reset / Login issues</option>
	// 				<option value="44">Security</option>
	// 				<option value="39">Ticketing</option>
	// 				<option value="40">Transport</option>
	// 				<option value="41">Visiting The Championships</option>
	// 				<option value="42">Web / Mobile Technical</option>
	// 				<option value="45">Wimbledon Shop</option>
	// 				<option value="47">myWIMBLEDON</option>
	// 				<option value="46">Other</option>
	// 			</select>
	// 			<i className="wim-icon-down-arrow" />
	// 			<ErrorMessage name={name} render={msg => <span className="form-error">{msg}</span>} />
	// 		</label>
	// 	);
	// }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);

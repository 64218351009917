/***
 **  Draws Archive Player Profile Draws Lists Component
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import ArchiveMatchBox from './archive_draws';

const mapStateToProps = (state, props) => {
	return {
		...state['DrawsLists'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class DrawsLists extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};

		logger.log('[DrawsLists] constructor props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[DrawsLists] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	render() {
		let drawsData = this.state.attributes.data;
		let flagConfig = this.state.attributes.flagImagePathSmall;
		let playerProfileImagePath = this.state.attributes.playerProfileImagePath;
		let count = 0;

		return (
			<div className="roundGroup summaryTabs">
				{drawsData.map((match, index) => {
					logger.log('[ArchiveMatchBox] renderMatches filter map match:%o', match);
					let sMatch = match.matches;
					return (
						<div className="yearWrapper">
							<h4>{match.year}</h4>
							<div key={`${match.year}+ ${index} + ${count++}`} className="drawGroup">
								{sMatch.map((item, j) => {
									return (
										<ArchiveMatchBox
											key={item.id + j}
											attributes={{
												data: item,
												tableHeader: item.round_name,
												layoutStyle: 'summaryTabs',
												flagConfig,
												playerProfileImagePath,
											}}
										/>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsLists);

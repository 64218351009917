/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import PageHeader from 'appdir/components/general/PageHeader';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import IFrameComponent from 'appdir/components/common-ui/IFrameComponent';
import MeasurementUtils from 'appdir/lib/analytics';
import Helmet from 'react-helmet';

/**
 * -----------------------------------------------------------------------------
 * React Component: FramePages
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	title: state.FramePages.title || '',
	src: state.FramePages.src || '',
	height: state.FramePages.height || '',
	social: state.FramePages.social || {},
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.FramePages.mount()),
});

class FramePages extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		//logger.info('[FramePage] componentDidMount - state:%o', this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevProps, prevState) {}

	render() {
		//logger.info('[FramePage] render - state:%o', this.state);
		const { title, src, shortTitle, height, social } = this.state;

		let header_propsData = {
			headerType: 'generic',
			title: title,
			shortTitle: shortTitle,
			metaTitle: title,
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		//add data for Helmet
		header_propsData = {
			...header_propsData,
			type: 'O',
			canonicalLink: values.canonicalHost + window.location.pathname,
			metaDescription: social.description,
			shareImage: social.image,
		};

		return (
			<Template className="iframe-component">
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />

				<div className="content-main">
					<IFrameComponent src={src} height={height} />
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FramePages);

import React from 'react';

const Menus = () => {
	return (
		<div className="menus two-col margin">
			<p className="menu-text">MENU</p>
			<br />
			<p className="menu-text">
				<a href="https://www.wimbledon.com/pdf/Club_Room_Breakfast_Menu.pdf" target="_blank">
					Breakfast Menu (pdf)
				</a>
			</p>
			<p className="menu-text">
				<a href="https://www.wimbledon.com/pdf/Member_Dinner_Menu.pdf" target="_blank">
					Dinner Menu (pdf)
				</a>
			</p>
			<p className="menu-text">
				<a href="https://www.wimbledon.com/pdf/Member_All_Day_Menu.pdf" target="_blank">
					Lunch/All Day Menu (pdf)
				</a>
			</p>
		</div>
	);
};

export default Menus;

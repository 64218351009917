import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.GALLERY_SELECTIMAGE:
			newState = Object.assign({}, state, { firstImage: action.data.imgURL, currImageIndex: action.data.index });
			// newState["firstImage"] = action.data;
			return newState;
			break;
		case deps.actionTypes.GALLERY_UPDATESLIDEROFFSET:
			newState = Object.assign({}, state, { sliderOffset: action.data.sliderOffset });
			return newState;
			break;
		default:
			return state;
	}
};

import React, { Component, Fragment } from 'react';
import { RoleRegistrationInviteContext } from '../context';
// import op from 'object-path';
// import isEmpty from 'lodash/isEmpty';
// import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormInput, FormCheckBox } from 'appdir/components/common-ui/FormFields';
// import SectionDivider from 'appdir/components/cms/SectionDivider';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

class Media extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			confirmed: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.initialValues = {
			internetPolicy: false,
			accreditPolicy: false,
			confirm: false,
		};

		logger.log('[RoleRegistrationInvite - Media] constructor - state:%o context:%o', this.state, this.context);
	}

	componentDidMount() {
		logger.log(
			'[RoleRegistrationInvite - Media] componentDidMount - state:%o context:%o',
			this.state,
			this.context
		);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Role Registration Invite Media',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[RoleRegistrationInvite - Media] componentDidUpdate - state:%o context:%o',
			this.state,
			this.context
		);
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(policies) {
		let schema = {};
		if (policies != '') {
			policies?.forEach(p => {
				schema[p.name] = policySchemas[p.name];
			});
			schema['confirm'] = policySchemas['confirm'];
			return yup.object(schema);
		} else {
			schema['confirm'] = policySchemas['confirm'];
			return yup.object(schema);
		}
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		// logger.log('[RoleRegistrationInvite - Media] handleSubmit - state:%o', values);
		this.addRole();
	}

	addRole() {
		if (this.context.addRole) {
			logger.log('[RoleRegistrationInvite - Media] addRole');
			this.context.addRole();
		}
	}

	onConfirm() {
		this.setState({ confirmed: true });
	}

	renderConfirmText() {
		return (
			<Fragment>
				<div className="two-col margin question">
					<div>Would you like to add Media access?</div>
					<div className="highlight">
						To <MyWimbledonTitle short="true" /> account:
					</div>
					<div className="email">
						<strong>{this.context.userEmail}</strong>
					</div>
				</div>
			</Fragment>
		);
	}

	renderSubmitForm() {
		let policies = segmentPolicies[this.context.segmentId];
		let validationSchema = this.getSchema(policies);

		return (
			<Fragment>
				{policies && (
					<div className="two-col margin">
						Before we provide you with access to the Wimbledon Media Website, please acknowledge that you
						accept the terms and policies below (with the exception of those superseded by any pre-existing
						contractual agreement).
					</div>
				)}
				<div className="spacer" />
				<Formik
					initialValues={this.initialValues}
					validationSchema={validationSchema}
					onSubmit={this.handleSubmit}
					enableReinitialize={true}>
					{formProps => {
						let allowSubmit = formProps.values.confirm;
						//formProps.values.mediaPolicy && policyLink != '' && formProps.values.confirm;
						policies?.forEach(p => {
							allowSubmit = formProps.values[p.name] && allowSubmit;
						});

						return (
							<Fragment>
								<div className="two-col margin">
									{policies &&
										policies.map(p => {
											return (
												<Fragment>
													<FormCheckBox name={p.name} id={p.name}>
														Accept{' '}
														<WimLink to={p.link} external={true}>
															{p.text}
														</WimLink>
													</FormCheckBox>
													<div className="spacer" />
												</Fragment>
											);
										})}

									<FormCheckBox name="confirm" id="confirm">
										I confirm I want to add Media access for <MyWimbledonTitle short="true" />{' '}
										account <strong>{this.context.userEmail}</strong>.
									</FormCheckBox>
								</div>

								<div className="four-col body">
									<Button
										className={`btn solid ${!allowSubmit ? `disabled` : ``}`}
										onClick={() => {
											formProps.submitForm();
										}}>
										CONFIRM ACCESS
									</Button>
								</div>
							</Fragment>
						);
					}}
				</Formik>
			</Fragment>
		);
	}

	render() {
		logger.log('[RoleRegistrationInvite - Media] render - state:%o status:%o', this.state, this.state.status);
		logger.log('[RoleRegistrationInvite - Media] render - context:%o', this.context);

		return (
			<Fragment>
				{this.context.complete ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION SUCCESSFUL</h4>
						</div>
						<div className="two-col">
							<p>
								We are delighted to welcome you to the official Wimbledon Media Website, which is
								available to you here on Wimbledon.com.{' '}
							</p>
							<p>
								The media website will provide you with information and resources to support your
								coverage of The Championships, tailored according to your role during the event.
							</p>
						</div>
						<div className="two-col margin">
							<h4>SIGN UP FOR MEDIA INFORMATION</h4>
						</div>
						<div className="two-col">
							<p>
								myWIMBLEDON is our way of enabling our guests to personalise their digital Wimbledon
								experience.
							</p>
							<p>
								When you log in to your profile, please go to the ‘Consents and Preferences’ section to
								select which media information you would like to receive via email.
							</p>
							<p>
								If you have any questions, please email{' '}
								<a href="mailto:communications@aeltc.com">communications@aeltc.com</a> or{' '}
								<a href="mailto:wbs@aeltc.com">wbs@aeltc.com</a>.
							</p>
						</div>
						<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
						<div className="two-col margin">
							Your profile has been updated. Please visit myWimbledon to explore and manage benefits.
						</div>
						<div className="two-col margin center">
							<Button className={`btn solid mywim`} to="/en_GB/mywimbledon/">
								<i className="wim-icon-myw my" aria-label="my wimbledon" />
								WIMBLEDON
							</Button>
						</div>
					</Fragment>
				) : this.context.status == 'completed' ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">Registration links may only be used once.</div>
						<div className="two-col body">
							Please <WimLink to="/en_GB/contact/index.html">contact us</WimLink> for additional
							questions.
						</div>
					</Fragment>
				) : !this.context.isValid ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">
							Unfortunately your registration code is invalid or has expired.
							<br />
							<br />
							Please try again or contact your AELTC representative to request a new link.
						</div>
					</Fragment>
				) : (
					<Fragment>
						<div className="two-col margin">
							<h4>MEDIA WEBSITE REGISTRATION</h4>
						</div>
						{this.context.status == 'active' ? (
							<Fragment>
								{this.renderConfirmText()}
								<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
								{this.renderSubmitForm()}
							</Fragment>
						) : null}
					</Fragment>
				)}
			</Fragment>
		);
	}
}
Media.contextType = RoleRegistrationInviteContext;
export default Media;

const segmentPolicies = {
	1000251: [
		{
			name: 'accreditPolicy',
			text:
				'AELTC Terms and Conditions of Accreditation (now incorporating the Media Terms and Conditions of Accreditation)',
			link: 'https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html',
		},
	],
	1000252: [
		{
			name: 'accreditPolicy',
			text:
				'AELTC Terms and Conditions of Accreditation (now incorporating the Media Terms and Conditions of Accreditation)',
			link: 'https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html',
		},
	],
	1000253: [
		{
			name: 'accreditPolicy',
			text:
				'AELTC Terms and Conditions of Accreditation (now incorporating the Media Terms and Conditions of Accreditation)',
			link: 'https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html',
		},
	],
	1000254: [
		{
			name: 'accreditPolicy',
			text:
				'AELTC Terms and Conditions of Accreditation (now incorporating the Media Terms and Conditions of Accreditation)',
			link: 'https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html',
		},
	],
	1000255: [
		{
			name: 'accreditPolicy',
			text:
				'AELTC Terms and Conditions of Accreditation (now incorporating the Media Terms and Conditions of Accreditation)',
			link: 'https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html',
		},
	],
	1000256: [
		{
			name: 'accreditPolicy',
			text:
				'AELTC Terms and Conditions of Accreditation (now incorporating the Media Terms and Conditions of Accreditation)',
			link: 'https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html',
		},
	],
	1000257: [
		{
			name: 'accreditPolicy',
			text:
				'AELTC Terms and Conditions of Accreditation (now incorporating the Media Terms and Conditions of Accreditation)',
			link: 'https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html',
		},
	],
};

const policySchemas = {
	internetPolicy: yup
		.boolean()
		.required('Please read and accept the Internet and Social Media Policy.')
		.oneOf([true], 'Please read and accept the Internet and Social Media Policy.'),
	accreditPolicy: yup
		.boolean()
		.required('Please read and accept the AELTC Terms and Conditions of Accreditation.')
		.oneOf([true], 'Please read and accept the AELTC Terms and Conditions of Accreditation.'),
	confirm: yup
		.boolean()
		.required('Please confirm.')
		.oneOf([true], 'Please confirm.'),
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	// logger.log('[PlayerPageWebview] reducers - action:%o', action);
	switch (action.type) {
		// case deps.actionTypes.PLAYERPAGEWEBVIEW_LOAD: {
		// 	newState = Object.assign({}, state, { status: 'loaded' });
		// 	logger.log('[PlayerPageWebview] loaded');
		// 	return newState;
		// 	break;
		// }
		case deps.actionTypes.PLAYERPAGEWEBVIEW_LOADING: {
			newState = { ...state, PlayerPageConfig: { pathObj: action.data }, status: 'loading' };
			// logger.log('[PlayerPageWebview] loading', newState);
			return newState;
			break;
		}

		default:
			return state;
	}
};

import React from 'react';
import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import LoginWrapper from './LoginWrapper';
import NotFound from './NotFound';
import useMemberPage from './hooks/useMemberPage';
import { useMemberContext } from './context';

const Member = () => {
	const { Element, headerProps } = useMemberPage();
	const { currentUser, roleValidated, loggedIn, gigyaLoaded, disableSection } = useMemberContext();

	return (
		<Template>
			{headerProps && <Header attributes={headerProps} />}

			{headerProps && <PageHeader attributes={headerProps} />}
			<div className="column-layout content-main">
				{!currentUser ? (
					<LoginWrapper />
				) : !gigyaLoaded ? (
					<LoadingIndicator />
				) : !roleValidated || disableSection ? (
					<NotFound />
				) : loggedIn && roleValidated ? (
					<Element />
				) : (
					<LoadingIndicator />
				)}
			</div>
			<Footer />
		</Template>
	);
};

export default Member;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: PullQuote
 * -----------------------------------------------------------------------------
 */

class PullQuote extends Component {
	constructor(props) {
		super(props);
		//logger.log('[PullQuote] - props:%o', props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		//logger.log('[PullQuote] - state:%o', this.state.data);

		return (
			<div className={`${this.state.data.style} pull-quote`}>
				<i className="wim-icon-quotation-marks" />
				<div className="h3 quote-text">{this.state.data.text}</div>
				<div className="h5 green quote-author">{this.state.data.author}</div>
			</div>
		);
	}
}

export default PullQuote;

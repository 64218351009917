/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import AtoZNav from 'appdir/components/common-ui/AtoZNav';
import PlayerList from './PlayersList';
import FeaturedPlayers from './FeaturedPlayers';
import ScrollHandler from 'appdir/components/general/ScrollHandler';
import MyPlayers from 'appdir/components/common-ui/MyPlayers/';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayersIndex
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => ({
	PlayersIndex: state.PlayersIndex,
	FeaturedPlayers: state.Config.featuredPlayers,
	showFavourites: state.Config?.homepage?.favoritesCarousel,
	currentUser: state.Gigya.currentUser,
	windowSize: state.PageHeader.windowSize,
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayersIndex.mount()),
});

const letterList = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

class PlayersIndex extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };

		this.loadedJson = '';
	}

	componentWillUnmount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevState) {
		//analytics for tracking letter navigation change
		let currHashLetter = this.state.location.hash;
		if (currHashLetter !== prevState.location.hash) {
			let letter = currHashLetter.replace('#', '');

			MeasurementUtils.dispatchMeasurementCall('playerProfilesSelectLetter', { letter });
		}

		if (!this.loadedJson && this.state.PlayersIndex.profilePath) {
			this.loadedJson = this.state.PlayersIndex.profilePath;
			deps.services.PlayersIndex.fetch(this.state.PlayersIndex.profilePath)
				.then(result => {
					logger.log('[PlayersIndex] componentDidUpdate result:%o', result);
					this.setState({
						players: result.players,
					});
				})
				.catch(error => {
					logger.log('[PlayersIndex] componentDidUpdate error:%o', error);
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}
	}

	getNavSettings() {
		let navSettings;
		let size, offset;
		//pass in the top fixed position of the nav when the page scrolls
		if (this.props.windowSize === 'mobile'
		) { 
			size = 73;
			offset = -100;
		}
		else {
			size = 106;
			offset = -240;
		}
		navSettings = { 
			navTop: size,
			offset: offset
		};

		return navSettings;
	}

	renderPlayerList(letterList) {
		const playerLists = letterList.map((letter, index) => {
			return (
				<div ref={letter} key={letter} id={letter} className="letter-section">
					<a name={letter} />
					<div className="column">
						<div className="header-type-title">Gentlemen's</div>
						<div className="letter-header">{letter}</div>
						<PlayerList players={this.state.players} letter={letter} gender="M" />
					</div>
					<div className="column">
						<div className="header-type-title">Ladies'</div>
						<div className="letter-header">{letter}</div>
						<PlayerList players={this.state.players} letter={letter} gender="F" />
					</div>
				</div>
			);
		});

		return playerLists;
	}

	allowedToViewPlayerFav() {
		let currentUser = op.get(this.state, 'currentUser', null);
		let showFav = this.state.showFavourites;
		// let currentUser = op.get(this.state, 'Gigya.currentUser', null);
		if (showFav) {
			if (currentUser !== null && !isEmpty(currentUser)) {
				let playerCreds = currentUser.roles.find(ele => ele.key === 'player');
				if (playerCreds) {
					return false;
				}
			}

			return true;
		}

		return false;
	}

	getFeaturedPlayers() {
		let flagImagePathLarge = '';
		let playerProfileImagePath = '';

		if (this.state.PlayersIndex && Object.keys(this.state.PlayersIndex).length > 0) {
			playerProfileImagePath = this.state.PlayersIndex.playerProfileImagePath;
			flagImagePathLarge = this.state.PlayersIndex.flagImagePathLarge;
		}

		return (
			<div id="featuredPlayers">
				{this.state.FeaturedPlayers.map(featuredPlayer => (
					<FeaturedPlayers
						key={featuredPlayer}
						players={this.state.players}
						featuredPlayer={featuredPlayer}
						config={{ flagImagePathLarge, playerProfileImagePath }}
					/>
				))}
			</div>
		);
	}

	render() {
		logger.log('[PlayersIndex] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'players',
			metaTitle: 'Players',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scroll: false,
		};
		let playerData = [];

		if (this.state.players && this.state.players.length > 0) {
			return (
				<Template className="playerIndex">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<ScrollHandler hasData={true} filter={'none'} />
					<div className="content-main">
						<noindex>
							{/* <div id="featuredPlayers"> */}
							{this.allowedToViewPlayerFav() ? (
								<MyPlayers />
							) : this.state.FeaturedPlayers ? (
								this.getFeaturedPlayers()
							) : null}
							{/* </div> */}
							<div className="content-filters-scroll" />
							<div className="content-filters playersIndex" id="content-filters">
								<AtoZNav settings={this.getNavSettings()} />
							</div>
							<div id="playersList">{this.renderPlayerList(letterList)}</div>
						</noindex>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="playerIndex">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<ScrollHandler hasData={false} filter={'none'} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayersIndex);

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.VIDEOPLAYER_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[VideoPlayer]  deps.actionTypes.VIDEOPLAYER_MOUNT - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.VIDEOPLAYER_PLAY:
			let players = {
				...state.players,
				...action.data,
			};
			//logger.log('[VideoPlayer]  deps.actionTypes.VIDEOPLAYER_PLAY - players:%o', players);

			newState = {
				...state,
				players: players,
			};
			//logger.log('[VideoPlayer]  deps.actionTypes.VIDEOPLAYER_PLAY - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.VIDEOPLAYER_FULLSCREEN:
			newState = {
				...state,
				fullscreenVideo: action.data,
			};
			//logger.log('[VideoPlayer]  deps.actionTypes.VIDEOPLAYER_FULLSCREEN - newState:%o', newState);
			return newState;
			break;

		case deps.actionTypes.VIDEOPLAYER_USER:
			newState = {
				...state,
				user: action.data,
			};
			//logger.log('[VideoPlayer]  deps.actionTypes.VIDEOPLAYER_FULLSCREEN - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

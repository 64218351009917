import React, { useRef, useState, useEffect } from 'react'; 
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { getQuerystringValues, showSummaryFirst, fetch } from 'appdir/components/general/Util';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Template from 'appdir/components/Template';
import AIPlayerCard from 'appdir/components/pages/AICatchUp/elements/AIPlayerCard';
import AIDaySummary from 'appdir/components/pages/AICatchUp/elements/AIDaySummary';
import IBMwatsonx from 'appdir/components/pages/AICatchUp/elements/IBMwatsonx';
import GenericCarousel from 'appdir/components/common-ui/GenericCarousel';
import WimLink from 'appdir/components/general/WimLink';
import { measureInAppContext } from 'appdir/components/general/Analytics';

const AICatchUpFullWebview = props => {
    const playerGen = useSelector(state => state['Tournament']?.data?.playerGen);
	const daySummaryGen = useSelector(state => state['Tournament']?.data?.daySummaryGen);
    const enabled = useSelector(state => state['Config']?.enabled); 
	const matchHighlights = useSelector(state => state['Config']?.relatedContent?.matchHighlights);
    const stubPages = useSelector(state => state['Config']?.stubPages?.aiCatchUp);
	const configText = useSelector(state => state['Config']?.text?.aiCatchUp);

    const dispatch = useDispatch();

    const [status, setStatus] = useState(null);
    const [allContent, setAllContent] = useState([]); // consolidate all the content
    const [activeSlides, setActiveSlides] = useState({active: null, totalVisibleSlides: 1});
    const [showSummaryFirstLoc, setShowSummaryFirstLoc] = useState();
    const [highlightsIds, setHighlightsIds] = useState([]); // store post match ids if there is AI Highlights for the match

    const mobileMatch = window.matchMedia('(max-width: 767px)').matches;
    
    /** webview Favs 
    *  store initial favorites players list that
    *  apps send
    */
   let search = window.location.search.replace(/^\?/, '');
   let parsedQs = getQuerystringValues(search);
   const debug = parsedQs.debug;

   const [appFavs, setAppFavs] = useState(window.location.search ? parsedQs.filterPlayers?.split(',') : []);
   const [selectedPlayer, setSelectedPlayer] = useState(window.location.search ? parsedQs.id?.split(',') : []);

   const sendPageView = useRef(true);

    //player data
    const playerGenData = playerGen?.data?.data;
    const daySummaryGenData = daySummaryGen?.data?.data;

    //get selected player's index from querystring
	const selPlayerIndex = (allcards) => {
		return findIndex(allcards, p => {
			//logger.log('[AICatchUpFullWebview] selPlayerIndex allcards:%o', allcards);
			if(selectedPlayer !== undefined){
				//logger.log('[AICatchUpFullWebview] selPlayerIndex selectedPlayer:%o id:%o day:%o', selectedPlayer[0], p.id, p.day);
				if (p?.id === selectedPlayer[0] || String(p?.day) === selectedPlayer[0]) {
					return true;
				}	
				else {
					return false;
				}
			} 
			else {
				return false;
			}
		});
	}

    /**
     * 
     * @returns index number
     * 
     *  day summary id value is integer on apps
     *  while "summary" on www
     *  
     *  apps are passing the URL as below, id is a day number or a player Id
     *  /webview/en_GB/catchup/full?filterPlayers=&id=10&edgeCntry=GB
     */
   const getInitSlide = (allcards) => {
		let foundIndex = selPlayerIndex(allcards);
		logger.log('[AICatchUpFullWebview] getInitSlide allcards:%o', allcards);
		logger.log('[AICatchUpFullWebview] getInitSlide selectedPlayer:%o foundIndex:%o', selectedPlayer, foundIndex);
		
		let newIndex = foundIndex > 0 ? foundIndex : 0;
		logger.log('[AICatchUpFullWebview] getInitSlide newIndex:%o', newIndex);

        return newIndex;
    };

    const getActiveSlidePlayerID = () => {
        // logger.log('[AICatchUpFullWebview] getActiveSlidePlayerID selectedCard:%o, selectedPlayer:%o', allContent?.[activeSlides?.active], selectedPlayer);
        let selectedSlide = allContent?.[activeSlides?.active];
        let type = selectedSlide?.day ? 'summary' : 'player';
        let id = '';
        let name = '';
        if(type == 'summary') {
            id = selectedSlide?.day;
            name = `summaryDay${selectedSlide?.day}`;
        } else {
            id = selectedSlide?.id;
            name = `${selectedSlide?.firstName} ${selectedSlide?.lastName}`;
        }
       return {id, name};
    }

    useEffect(() => {
        if(!isEmpty(enabled) && enabled) {
            if(window.webview && appFavs?.length > 0) {
                dispatch(deps.actions.Tournament.getPlayerGen({playerIds: appFavs}));
            } else {
                dispatch(deps.actions.Tournament.getPlayerGen());
            }
            dispatch(deps.actions.Tournament.getDaySummaryGen());
        }
        setShowSummaryFirstLoc(showSummaryFirst());
    }, [enabled, showSummaryFirstLoc]);

    /**
     *  
     *  For some reason, react-slick treates AIDaySummary and AIPlayerCard
     *  as two different children and cause isseus with intersecting in GenericCarousel
     *  Consolidate the content here into one child
     */
    useEffect(() => {
        let arry = [];
        let matchIdsArry = [];
        let foundMatchIds = [];

        if(daySummaryGen?.status && playerGen?.status && 
            (playerGen?.status == "loaded" && playerGenData) || (daySummaryGen?.status == "loaded" && daySummaryGenData)) {

            if (playerGenData?.length > 0) {
                arry.push(...playerGenData);
                
                playerGenData.map((player, index) => {
                    /** store all the post match IDs to check if any of them have a higlight video */
                    if(player.type == "post") {
                        matchIdsArry.push(player.matchId);
                    }
                });

                /** 
                 * pass all the match ids to fetch 
                 * related content all once 
                 * and save the cmsId with the match id
                 * so the button click plays the highlight video
                 */
                if(matchIdsArry?.length > 0) {
                    let tags = '';
                    matchIdsArry.map((id, index) => {
                        index == 0 ? (
                            tags += `tags=${id}`
                        )
                        : (
                            tags += `&tags=${id}`
                        )
                    })
                    let url = matchHighlights.replace('<matchTags>', tags);
                    logger.log('[AICatchUp] matchIds useEffect - url:%o', url); 
        
                    /** fetch related content and sore match id and video id */
                    fetch(url)
                    .then(result => {
                        logger.log('[AICatchUp] fetch rc result:%o', result);

                        result?.content?.map((item, index) => {
                            foundMatchIds.push({
                                    matchId: item?.metadata?.matchId,
                                    vidId: item?.cmsId
                                });
                        })

                        setHighlightsIds(foundMatchIds);
                    })
                    .catch(error => {
                        logger.error('[AICatchUpFullWebview] fetch rc error:%o', error);
        
                        setHighlightsIds([]);
                    });
                }
            }

            /** add day summary data as the last obj in arry */
            if(daySummaryGenData && Object.keys(daySummaryGenData).length > 0) {
				 logger.log('[AICatchUpFullWebview] daySummaryGenData:%o showSummaryFirstLoc:%o', daySummaryGenData, showSummaryFirstLoc);
                if(playerGenData && showSummaryFirstLoc) {
                    arry.unshift(daySummaryGen?.data?.data);
                } else{
                    arry.push(daySummaryGen?.data?.data);
                }
            };

			/** update activeSlides number after checking queryString */
            setActiveSlides({...activeSlides, active: getInitSlide(arry)});

            setAllContent(arry);
        }
    }, [playerGen, daySummaryGen]);


    /** 
     * update activeSlides.active number 
     * when allContent state is upated 
     * */
    // useEffect(() => {
    //     if(allContent?.length > 0) {
    //         setActiveSlides({...activeSlides, active: getInitSlide()});
    //         // logger.log('[AICatchUpFullWebview] allContent:%o, activeSlides:%o', allContent?.length, activeSlides);
    //     }
    // }, [allContent])

    useEffect(() => {
        let id = getActiveSlidePlayerID()?.id;

        if(activeSlides?.active > -1 && (id == 0 || id > -1 || id) && sendPageView.current) {
            sendPageView.current = false;
            measureInAppContext({
                pageTitle:"Catch Me Up",
                action: "Card",
                args: [activeSlides?.active + 1],
                context:  [
                            { section: "Catch Me Up" }, 
                            {cmu_playerID: getActiveSlidePlayerID()?.id }, 
                            {cmu_player:getActiveSlidePlayerID()?.name}
                        ],
                metricType: 'state'
            });
        }
    }, [activeSlides, sendPageView]);

    /**
     * 
     * @param {*} matchId 
     * @returns cmsId from related content
     * 
     *  find the same match id obj from highlightsIds
     *  and get the video id
     */
    const getHighlightVidId = (matchId) => {
        let vidId;

        let match = highlightsIds.filter(item => item.matchId == matchId);
        if(match?.length > 0) {
           vidId = match[0].vidId;
        }

        return vidId;

    }

    //total of all contents
    let totalCount = allContent?.length ? allContent.length : '';
    let sliderOverride = {
        arrows: false,
        initialSlide: activeSlides?.active 
    }
    if (mobileMatch) {
        sliderOverride = {
            arrows: false,
            centerMode: true,
            centerPadding: "20px",
            initialSlide: activeSlides?.active 
        };

    } 

    const metricsData = {
        pageTitle: "Catch Me Up", // required for webview, don't need it for www
        arrowMetrics: {
        },
        swipeMetrics: {
            // contextData: [{cmu_playerID: getActiveSlidePlayerID()?.id }, {cmu_player:getActiveSlidePlayerID()?.name}],
        }
    };
    
    // logger.log('[AICatchUpFullWebview] allContent:%o, activeSlides:%o', allContent, activeSlides);
    logger.log('[AICatchUpFullWebview] playerGen:%o, highlightsIds:%o', playerGen, highlightsIds);
    return (
        <Template className={`webview ${debug ? 'debug' : ''}`}>
            <div className="content-main ai-catchup fullview">
            {stubPages?.stub === "pre" ? (
                    <p className="nodata">
                        {stubPages?.text}
                    </p>
                ) : (
                    <>
                    {allContent?.length > 1 && activeSlides?.active > -1 ?(
                        <>
                            <GenericCarousel 
                                className={"ai-card-carousel"} 
                                sliderSettingsOverride={sliderOverride}
                                slideWidth={ 320 }
                                metricsData={metricsData}
                                afterChangeCallback={(index, visibleSlides) => {
                                    setActiveSlides(
                                        {
                                            active: index, 
                                            totalVisibleSlides: visibleSlides
                                        }
                                    )
                                }}
                                onInitCallback = {(visibleSlides) => {
                                    setActiveSlides(
                                        {
                                            active: activeSlides?.active, 
                                            totalVisibleSlides: visibleSlides
                                        }
                                    )
                                }}
                            >
                                {
                                    allContent.map((item, index) => {
                                        return item?.id
                                            ? (
                                                <>
                                                    <div className="cardCount"><span>{index+1}</span> of {totalCount}</div>
                                                    <div key={`gen-carousel-${index}${item.id}`}>
                                                        <div className="ai-catchup-cards">
                                                            <AIPlayerCard 
                                                                data={{...item, cardIndex:index, highlightsVidId: getHighlightVidId(item.matchId)}} 
                                                                cardType="fullWebview" 
                                                                key={`aiPC${index}${item.id}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div key={"gen-carousel-summary"}>
                                                <div className="cardCount"><span>{index+1}</span> of {totalCount}</div>
                                                    <div className="ai-catchup-summary">
                                                <AIDaySummary 
                                                    data={item} 
                                                    cardType="fullWebview"
                                                    key={`aiPlayer${item?.day}summary`}
                                                />
                                                </div>
                                            </div>
                                            )
                                    })
                                }
                            </GenericCarousel>

                            <IBMwatsonx styleClasses={props?.styleClasses ?? 'webview ai-subtext'} />
                        </>
                    ): allContent?.length == 0 ? (
                        <div className="column-layout">
                            <p className="nodata">
                                {configText?.nodata}
                            </p>
                        </div>
                    ) : (
                        <LoadingIndicator type="white" />
                    )}
                    </>
                )
             }
            </div>
        </Template>
    );
}

export default AICatchUpFullWebview;


/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import axios from 'axios';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';

// components
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: HillVoteWebview
 * this is the pop-up radio page that uses webview so side panel won't load
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['HillVoteWebview'],
		scoresDataPath: op.get(state['Config'].scoringData, 'liveScore.path', null),
		playerProfileImagePath: op.get(state['Config'].otherData, 'playerProfileImagePath', null),
		flagImagePathSmall: op.get(state['Config'].otherData, 'flagImagePathSmall', null),
		hillVotePath: op.get(state['Config'].otherData, 'hillvote', null),
		stubs: state['Config'].stubPages,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.HillVoteWebview.mount()),
	sendVote: data => dispatch(deps.actions.HillVoteWebview.sendVote(data)),
});

class HillVoteWebview extends Component {
	constructor(props) {
		super(props);

		// logger.log('[HillVoteWebview] constructor - props:%o', props);
		this.state = {
			...props,
		};

		this.scoresDataTimer = null;
		this.scoresDataLoaded = false;

		this.voteDataTimer = null;
	}

	componentDidMount() {
		// logger.log('[HillVoteWebview] componentDidMount - state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[HillVoteWebview] componentDidUpdate - state:%o, isBrowser:%o, isMobile:%o, isTablet:%o',
			this.state,
			isBrowser,
			isMobile,
			isTablet
		);

		if (
			!this.scoresDataLoaded &&
			this.state.scoresDataPath &&
			this.state.stubs &&
			this.state.stubs.scores.stub !== 'stub'
		) {
			this.scoresDataLoaded = true;
			this.getScoresData();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[HillVoteWebview] componentWillReceiveProps - prev:%o', this.state);
		// logger.log('[HillVoteWebview] componentWillReceiveProps - next:%o', nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentWillUnmount() {
		if (this.activeDataTimer) {
			clearInterval(this.scoresDataTimer);
		}
	}

	getScoresData() {
		let matchIdsArray = [];

		deps.services.HillVoteWebview.fetch(this.state.scoresDataPath)
			.then(result => {
				// gather all the match ids in scores.json
				result.matches.map(match => {
					let isInprogress = !match.team1.won && !match.team2.won ? true : false;

					if (isInprogress) {
						matchIdsArray.push(match.match_id);
					}
				});

				logger.log(
					'[HillVoteWebview] getScoresData - this.state:%o, result:%o, matchIdsArray:%o',
					this.state,
					result,
					matchIdsArray
				);

				this.setState({
					scoresData: result,
					matchIds: matchIdsArray,
					scoresStatus: 'loaded',
				});

				// if there are match_id, POST to endpoint to get percentage
				if (matchIdsArray.length > 0) {
					this.getVoteData(matchIdsArray);
				}

				if (!this.scoresDataTimer) {
					logger.log(
						'[HillVoteWebview] getScoresData - scoresDataTimer:%o, this.state:%o',
						this.scoresDataTimer,
						this.state
					);
					this.scoresDataTimer = setInterval(() => {
						this.getScoresData();
					}, this.state.refreshRate);
				}
			})
			.catch(error => {
				logger.error('[HillVoteWebview] componentDidUpdate error:%o', error);
				this.scoresDataLoaded = false;

				this.setState({
					scoresStatus: 'error',
				});
			});
	}

	// when scores are pulled, get the voted results
	getVoteData(ids) {
		let request = axios({
			method: 'post',
			url: this.state.hillVotePath,
			data: {
				ids: ids,
			},
			headers: { 'Content-type': 'application/json' },
		})
			.then(res => {
				this.setState({
					voteData: res,
				});

				logger.log('[HillVoteWebview] getVoteData success fetching data: %o', res);
			})
			.catch(error => {
				logger.error('[HillVoteWebview] getVoteData error fetching vote data: %o', error);
			});
	}

	// onClick to vote and get results
	sendVote = id => {
		let request = axios({
			method: 'post',
			url: this.state.hillVotePath,
			data: {
				id: id,
				ids: this.state.matchIds,
			},
			headers: { 'Content-type': 'application/json' },
		})
			.then(res => {
				this.props.sendVote({
					voted: id,
				});

				// just voted match ID isn't in the res, fetch it again to get the updated result
				this.getVoteData(this.state.matchIds);

				logger.log('[HillVoteWebview] sendVote success fetching data: %o', res);
			})
			.catch(error => {
				logger.error('[HillVoteWebview] sendVote error fetching vote data: %o', error);
			});

		if (!isBrowser) {
			measureInAppContext({
				pageTitle: 'Hill Vote',
				args: id,
			});
		}
	};

	getVoteBoxHtml(match) {
		let isInprogress = !match.team1.won && !match.team2.won ? true : false;

		let t1_playerA = match.team1.displayNameA == null ? '' : match.team1.displayNameA;
		let t1_playerB = match.team1.displayNameB == null ? '' : match.team1.displayNameB;
		let t2_playerA = match.team2.displayNameA == null ? '' : match.team2.displayNameA;
		let t2_playerB = match.team2.displayNameB == null ? '' : match.team2.displayNameB;

		let t1_playerA_image =
			match.team1.idA == null ? null : this.state.playerProfileImagePath.replace('<playerid>', match.team1.idA);
		let t1_playerB_image =
			match.team1.idB == null ? null : this.state.playerProfileImagePath.replace('<playerid>', match.team1.idB);
		let t2_playerA_image =
			match.team2.idA == null ? null : this.state.playerProfileImagePath.replace('<playerid>', match.team2.idA);
		let t2_playerB_image =
			match.team2.idB == null ? null : this.state.playerProfileImagePath.replace('<playerid>', match.team2.idB);

		let t1_playerA_flag =
			match.team1.nationA == null ? null : this.state.flagImagePathSmall.replace('<code>', match.team1.nationA);
		let t1_playerB_flag =
			match.team1.nationB == null ? null : this.state.flagImagePathSmall.replace('<code>', match.team1.nationB);
		let t2_playerA_flag =
			match.team2.nationA == null ? null : this.state.flagImagePathSmall.replace('<code>', match.team2.nationA);
		let t2_playerB_flag =
			match.team2.nationB == null ? null : this.state.flagImagePathSmall.replace('<code>', match.team2.nationB);
		let voted = op.get(this.state, 'voted', []);
		let results = op.get(this.state, 'voteData.data.results', []);
		let index = null;
		let percentageWidth = '0%';
		let percentage = '0%';

		if (results.length > 0) {
			index = results.findIndex(result => result.matchId == match.match_id);
			// logger.log('[HillVoteWebview] getVoteBoxHtml - index:%o, match:%o, results:%o', index, match, results);

			if (index !== null && index > -1) {
				percentageWidth = results[index].percentage;
				percentage = results[index].percentage;

				// if vote total is more than 0 and percentage is 0%, display <1% to indicate vote is there
				if (parseInt(results[index].total) > 0 && percentage === '0%') {
					percentage = '<1%';
				}
			}
		}

		if (isInprogress) {
			return (
				<div className="vote-box one-col" key={match.match_id}>
					<h4>{match.courtName}</h4>
					<div className="team-info">
						<div className="team1">
							<div className="playerA">
								{t1_playerA_image !== null ? (
									<img className="player-image regular" src={t1_playerA_image} />
								) : null}

								{t1_playerA_flag !== null ? <img className="flag" src={t1_playerA_flag} /> : null}

								<span className="name">{t1_playerA}</span>
							</div>
							<div className="playerB">
								{t1_playerB_image !== null ? (
									<img className="player-image regular" src={t1_playerB_image} />
								) : null}

								{t1_playerB_flag !== null ? <img className="flag" src={t1_playerB_flag} /> : null}
								<span className="name">{t1_playerB}</span>
							</div>
						</div>
						<div className="vote-info">
							<p className="match-name">
								{match.shortEventName} <span>{match.roundName}</span>
							</p>
							<p className="vote-status">
								{voted.includes(match.match_id) ? (
									<>
										<i className="wim-icon-check" /> Thanks for your vote!
									</>
								) : (
									<i
										className="wim-icon-thumb-up-blank"
										onClick={() => this.sendVote(match.match_id)}
									/>
								)}
							</p>
						</div>
						<div className="team2">
							<div className="playerA">
								{t2_playerA_image !== null ? (
									<img className="player-image regular" src={t2_playerA_image} />
								) : null}

								{t2_playerA_flag !== null ? <img className="flag" src={t2_playerA_flag} /> : null}
								<span className="name">{t2_playerA}</span>
							</div>
							<div className="playerB">
								{t2_playerB_image !== null ? (
									<img className="player-image regular" src={t2_playerB_image} />
								) : null}

								{t2_playerB_flag !== null ? <img className="flag" src={t2_playerB_flag} /> : null}
								<span className="name">{t2_playerB}</span>
							</div>
						</div>
					</div>
					<div className="vote-results">
						<div className="meter">
							<span style={{ width: percentageWidth }}></span>
						</div>
						<span className="result">{percentage}</span>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		logger.log('[HillVoteWebview] render - state:%o', this.state);

		let matches = op.get(this.state.scoresData, 'matches', []);
		let scoresStatus = op.get(this.state, 'scoresStatus', null);
		let inProgressMatchIds = op.get(this.state, 'matchIds', []);

		if (this.state.stubs && this.state.stubs.scores.stub === 'stub') {
			return (
				<section className="wrapper webview">
					<div className="content-main">
						<div className="column-layout hillvote">
							<p className="message">{this.state.stubs.scores.nomatches}</p>
						</div>
					</div>
				</section>
			);
		} else if (
			this.state.hillVotePath &&
			this.state.playerProfileImagePath &&
			this.state.flagImagePathSmall &&
			matches.length > 0 &&
			inProgressMatchIds.length > 0 &&
			scoresStatus &&
			scoresStatus === 'loaded'
		) {
			return (
				<section id="hillvote" className="wrapper webview">
					<div className="content-main">
						<p className="intro">Vote for the match to be played on the Hill Screen.</p>
						<div className="column-layout hillvote">
							<ErrorBoundary message="Voting is unavailable at this time.">
								{matches.map(match => this.getVoteBoxHtml(match))}
							</ErrorBoundary>

							{/* <div className="vote-box">
								<h4>Centre Court -- !Mockup - Not from the feed!</h4>
								<div className="team-info">
									<div className="team1">
										<div className="playerA">
											<img className="player-image regular" src="//images.wimbledon.com/square/atpn409.jpg" />
											<img className="flag" src="/assets/images/flags/USA_sm.gif" />
											<span className="name">A. Murray</span>
										</div>
										<div className="playerB">
											<img className="player-image regular" src="//images.wimbledon.com/square/atpn409.jpg" />
											<img className="flag" src="/assets/images/flags/USA_sm.gif" />
											<span className="name">A. Murray</span>
										</div>
									</div>
									<div className="vote-info">
										<p className="match-name">Gentlemen's Singles <span>Final</span></p>
										<p className="vote-status"><i className="wim-icon-check" />Thanks for your vote!</p>
										<p className="vote-status"><i className="wim-icon-thumb-up-blank" /></p>
									</div>
									<div className="team2">
										<div className="playerA">
											<img className="player-image regular" src="//images.wimbledon.com/square/atpn409.jpg" />
											<img className="flag" src="/assets/images/flags/USA_sm.gif" />
											<span className="name">A. Murray</span>
										</div>
										<div className="playerB">
											<img className="player-image regular" src="//images.wimbledon.com/square/atpn409.jpg" />
											<img className="flag" src="/assets/images/flags/USA_sm.gif" />
											<span className="name">A. Murray</span>
										</div>
									</div>
								</div>
								<div className="vote-results">
									<div className="meter">
										<span style={{width: '55%'}}></span>
									</div>
									<span className="result">55%</span>
								</div>
							</div> */}
						</div>
					</div>
				</section>
			);
		} else if (
			(matches.length === 0 || inProgressMatchIds.length == 0) &&
			scoresStatus &&
			scoresStatus === 'loaded' &&
			this.state.stubs
		) {
			return (
				<section className="wrapper webview">
					<div className="content-main">
						<div className="column-layout hillvote">
							<p className="message">{this.state.stubs.scores.nomatches}</p>
						</div>
					</div>
				</section>
			);
		} else {
			return (
				<section className="wrapper webview">
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HillVoteWebview);

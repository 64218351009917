import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'appdir/components/common-ui/Button';
import SearchBox from './elements/SearchBox';
import op from 'object-path';
import deps from 'dependencies';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import ServiceItem from 'appdir/components/common-ui/ServiceItem';
import { getRoleByName } from 'appdir/components/general/Util/Role';

const roleIDNameMap = {
	1000020: 'Debentures',
	1000230: 'Amex',
	1000250: 'Media',
	1000050: 'Player',
	1000060: 'Ballots',
	1000090: 'Tickets',
};

const roleServices = {
	1000050: ['matchDashboard'],
};

const mapRoles = data => {
	let shadowRoles = {};
	for (let i = 0; i < data.length; i++) {
		let currentRoleData = data[i];
		let roleName = roleIDNameMap[currentRoleData.roleId];
		shadowRoles[roleName] = currentRoleData;
	}
	return shadowRoles;
};

const MyWimSupport = props => {
	const Config = useSelector(state => state['Config']);
	const Gigya = useSelector(state => state['Gigya']);
	let firstLoad = true;
	const [state, setState] = useState({
		players: null,
		roleData: mapRoles(props.data.access),
		setPlayer: null,
		persistedPlayer: null,
		loading: false,
		setPlayerManual: null,
	});
	const dispatch = useDispatch();

	const renderPlayer = () => {
		let shadowRole = op.get(Gigya.currentUser.data, 'supportRole.shadow', false);
		logger.log('[MyWimSupport] renderPlayer - shadowRole:%o ', shadowRole);
		let playerCreds;
		if (shadowRole) {
			for (let i = 0; i < shadowRole['access'].length; i++) {
				let currRole = shadowRole['access'][i];
				let roleKey = roleTemplates[currRole.roleId]['key'];
				playerCreds = Gigya.currentUser.roles.find(ele => ele.key === roleKey);
			}
		}
		logger.log('[MyWimSupport] renderPlayer - state.persistedPlayer:%o ', state.persistedPlayer);
		return state.persistedPlayer !== null || (shadowRole && playerCreds && playerCreds.linkId) ? (
			<div className="player-searched">
				<div className="player-searched-container">
					<div className="player-text">
						<span className="shadowing-bold">{'Shadowing:'}</span>{' '}
						{`${state.persistedPlayer || state.setPlayer || playerCreds.linkId}`}
					</div>
					<Button className="solid" onClick={unsetPlayer}>
						Remove
					</Button>
				</div>
			</div>
		) : (
			<>
				<div className="player-search">
					<SearchBox
						staticData={state.players}
						onEnter={data => {
							onEnter(data);
						}}
					/>
					<Button
						className={`solid ${state.setPlayer == null ? 'disabled' : ''}`}
						onClick={state.setPlayer !== null ? submitFn : () => null}>
						Add
					</Button>
				</div>
				<div className="manual-search">
					<div className="manual-search-input-container">
						<input placeholder="Enter Player Ref ID Manually" onChange={handleManualChange} />
					</div>
					<Button
						className={`solid ${!enableManualSubmit() ? 'disabled' : ''}`}
						onClick={enableManualSubmit() ? submitManualFn : () => null}>
						Add
					</Button>
				</div>
			</>
		);
	};

	const roleTemplates = {
		1000050: {
			fn: renderPlayer,
			data: 'players',
			key: 'player',
			setStateVar: 'persistedPlayer',
			gigyaDataPath: ['contactDetails.firstName', 'contactDetails.lastName'],
		},
	};

	const enableManualSubmit = () => {
		return state.setPlayerManual !== null && state.setPlayerManual.length > 0;
	};

	const handleManualChange = evt => {
		setState({
			...state,
			setPlayerManual: evt.target.value,
		});
	};
	const submitFn = () => {
		let setRoleAPIPath = Config.myWimbledon.api.setShadowRole;
		setState({ ...state, loading: true });
		dispatch(deps.actions.Gigya.getJWT()).then(token => {
			axios({
				method: 'post',
				url: setRoleAPIPath,
				data: {
					jwtToken: token.jwt.id_token,
					shadowRole: { roleId: 1000050, linkId: state.setPlayerId, approved: true },
				},
			})
				.then(resp => {
					setState({
						...state,
						loading: false,
						persistedPlayer: state.setPlayer,
					});
					dispatch(deps.actions.Gigya.getAccountInfo({ src: 'wim_shadow' }));
				})
				.catch(err => {
					dispatch(
						deps.actions.ConfirmationModal.toggleModal({
							showModal: true,
							showCloseBtn: true,
							type: 'fail',
							title: 'USER SHADOW FAILURE',
							message: 'There was an error with your request. Please try again.',
							useTimer: true,
						})
					);
				});
		});
	};

	const submitManualFn = () => {
		if (state.setPlayerManual !== null && state.setPlayerManual.length > 0) {
			let { setPlayerManual } = state;
			let setRoleAPIPath = Config.myWimbledon.api.setShadowRole;
			setState({ ...state, loading: true });
			dispatch(deps.actions.Gigya.getJWT()).then(token => {
				axios({
					method: 'post',
					url: setRoleAPIPath,
					data: {
						jwtToken: token.jwt.id_token,
						shadowRole: { roleId: 1000050, linkId: setPlayerManual, approved: true },
					},
				})
					.then(resp => {
						setState({
							...state,
							loading: false,
							persistedPlayer: setPlayerManual,
						});
						dispatch(deps.actions.Gigya.getAccountInfo({ src: 'wim_shadow' }));
					})
					.catch(err => {
						dispatch(
							deps.actions.ConfirmationModal.toggleModal({
								showModal: true,
								showCloseBtn: true,
								type: 'fail',
								title: 'USER SHADOW FAILURE',
								message: 'There was an error with your request. Please try again.',
								useTimer: true,
							})
						);
					});
			});
		}
	};

	const onEnter = data => {
		if (data !== '') {
			setState({
				...state,
				setPlayer: `${data.first_name} ${data.last_name}`,
				setPlayerId: data.player_id,
			});
		} else if (data == '') {
			setState({
				...state,
				setPlayer: null,
				setPlayerId: null,
			});
		}
	};

	const unsetPlayer = () => {
		let removeRoleAPIPath = Config.myWimbledon.api.removeShadowRole;
		setState({
			...state,
			loading: true,
		});

		let playerRole = getRoleByName(op.get(Gigya, 'currentUser.roles', null), 'player');
		dispatch(deps.actions.MyWimbledon.removeShadowRole(playerRole)).then(resp => {
			setState({
				...state,
				setPlayer: null,
				player_id: null,
				persistedPlayer: null,
				setPlayerManual: null,
				loading: false,
			});
			dispatch(deps.actions.Gigya.getAccountInfo({ src: 'wim_shadow' }));
		});
	};

	const findRolePlayer = () => {
		let displayState = {};
		let shadowRole = op.get(Gigya.currentUser.data, 'supportRole.shadow', false);
		let haveRoleData = false;

		if (shadowRole) {
			for (let i = 0; i < shadowRole['access'].length; i++) {
				let currRole = shadowRole['access'][i];
				let roleKey = roleTemplates[currRole.roleId]['key'];
				const playerCreds = Gigya.currentUser.roles.find(ele => ele.key === roleKey);
				logger.log('[MyWimSupport] renderPlayer - playerCreds:%o, roleKey:%o ', playerCreds, roleKey);
				if (playerCreds) {
					// firstLoad = false;
					haveRoleData = true;
					let roleTemplateInfo = roleTemplates[currRole.roleId];
					//get role info
					if (Array.isArray(roleTemplateInfo['gigyaDataPath'])) {
						let displayInfo = roleTemplateInfo['gigyaDataPath']
							.map((d1, j) => {
								return op.get(playerCreds, d1, false);
							})
							.filter(d => d != false)
							.join(' ');

						if (displayInfo !== '') {
							displayState[roleTemplateInfo['setStateVar']] = displayInfo;
						}
					} else {
						let displayInfo = op.get(playerCreds, roleTemplateInfo['gigyaDataPath'], false);
						displayState[roleTemplateInfo['setStateVar']] = displayInfo;
					}
				}
			}
		}

		return { displayState, haveRoleData };
	};

	/**
	 * check if user role has access to a service
	 */
	const checkServiceAccess = type => {
		let accessType = props.roles.filter(role => {
			//logger.log('[MyWimSupport] checkServiceAccess - role:%o ', role, role[1].access);
			let hasAccess = role[1].access.filter(access => {
				return roleServices[access.roleId].includes(type);
			});
			return hasAccess.length > 0;
		});
		return accessType.length > 0;
	};

	useEffect(() => {
		//map the Gigya persisted data to shadow page
		let { displayState, haveRoleData } = findRolePlayer();
		if (Object.keys(displayState).length > 0 && haveRoleData) {
			setState({
				...state,
				...displayState,
				loading: false,
			});
		}
	}, [Gigya['currentUser']]);

	useEffect(() => {
		logger.log('[MyWimSupport] useEffect - The config for pde:%o', Config['pde']['api']);
		logger.log('[MyWimSupport] useEffect - state:%o', state);
		//detect if player role
		if (Config['pde']['api']['playerLookup'] && state['players'] === null) {
			axios.get(Config['pde']['api']['playerLookup']).then(resp => {
				const { data } = resp;
				setState({
					...state,
					...data,
				});
			});
		}
	}, [Config]);

	//page load use effec
	useEffect(() => {
		let { haveRoleData } = findRolePlayer();
		logger.log('[MyWimSupport] useEffect - haveRoleData:%o', haveRoleData);
		if (!haveRoleData && firstLoad && Gigya['currentUser']) {
			firstLoad = false;
			setState({
				...state,
				loading: false,
			});
		}
	}, [state['loading']]);

	logger.log('[MyWimSupport] state:%o', state);

	return (
		<div className="user-shadow-container">
			<div className="roles-container">
				{state.loading ? (
					<LoadingIndicator />
				) : (
					<>
						<div className="column-layout no-padding left right myw-icons">
							<div className="four-col service-section-title">Services</div>
							{checkServiceAccess('matchDashboard') && (
								<ServiceItem
									title={'Match Dashboard'}
									link={'/s/player/info/match-stats/pdedash.html'}
									src={`${op.get(props, 'iconPath', '')}/info.svg`}
									key={'matchDashboard'}
								/>
							)}
						</div>
						<div className="greenstripe-container service">
							<div className="greenStripe"></div>
						</div>
						{props.data.access.map((d, i) => {
							let requiredData = roleTemplates[d.roleId]['data'];
							return (
								<div className="column-layout no-padding left right myw-icons">
									<div className="four-col service-section-title">
										Shadow {roleIDNameMap[d.roleId]}
									</div>
									{state[requiredData] !== null && (
										<div>{roleTemplates[d.roleId].fn(state[requiredData])}</div>
									)}
								</div>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
};

export default MyWimSupport;

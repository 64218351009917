import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { FormCheckBox } from 'appdir/components/common-ui/FormFields';
import * as yup from 'yup';

import op from 'object-path';
import Button from 'appdir/components/common-ui/Button';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

const mapStateToProps = (state, props) => {
	return {
		gigya: state['Gigya'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	setAccountInfo: (source, data, modal) => dispatch(deps.actions.Gigya.setAccountInfoProm(source, data, modal)),
});

class RetailCompetition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.handleSubmit = this.handleSubmit.bind(this);

		this.initialValues = {
			termsAndCondsCheckbox: false,
			checkbox2: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[RegisterButton-RetailCompetition] componentWillReceiveProps - nextProps:%o', nextProps);
		if (this.state.isMerch !== nextProps?.gigya?.user?.subscriptions?.merchandise?.email?.isSubscribed) {
			this.setState({ isMerch: nextProps?.gigya?.user?.subscriptions?.merchandise?.email?.isSubscribed });
		}
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps.state,
			};
		});
	}

	handleSubmit(event) {
		let update = {};
		let entitlements = this.props.gigya?.user?.preferences?.CommunicationPreferences?.entitlements || [];

		if (this.state.isMerch) {
			op.set(update, 'subscriptions.merchandise.email.isSubscribed', true);
			// get entitlements length to make sure at least one exists if any subscriptions are selected yes
			if (!entitlements || !entitlements.length) {
				// set default communication preferences if none exist and subscriptions selected yes
				entitlements.push('email', 'phone', 'sms');
				op.set(update, 'preferences.CommunicationPreferences.entitlements', entitlements);
				op.set(update, 'preferences.CommunicationPreferences.isConsentGranted', true);
			}
		}

		op.set(update, 'subscriptions.competition.email.isSubscribed', true);
		op.set(update, 'subscriptions.competition.email.tags', ['Retail 2023']);
		MeasurementUtils.dispatchMeasurementCall('competitionSubscribeSuccess', {
			wimCollectionOpt: this.state.isMerch,
		});

		this.props.setAccountInfo('retail_competition', update, true);
	}

	clickLogin(e) {
		e.preventDefault();
		this.props.openScreen({
			screen: 'login_screen',
			redirect: false,
			showSuccess: false,
			cid: 'debenture_prospect',
		});
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {
			termsAndCondsCheckbox: yup
				.boolean()
				.required('Please read and accept the Terms and Conditions.')
				.oneOf([true], 'Please read and accept the Terms and Conditions.'),
		};

		return yup.object(schema);
	}

	getCompleteForm() {
		let validationSchema = this.getSchema();

		return (
			<Formik
				initialValues={this.initialValues}
				validationSchema={validationSchema}
				onSubmit={this.handleSubmit}
				enableReinitialize={true}>
				{formProps => {
					let allowSubmit = formProps.values.checkbox2;
					return (
						<Fragment>
							<div className="retail-competition-checkbox-container">
								<FormCheckBox
									name="checkbox2"
									id="checkbox2"
									checked={this.state.isMerch}
									onChange={() => this.setState({ isMerch: !this.state.isMerch })}>
									I would like to hear more about The Wimbledon Collection including new releases and
									exclusive product and discounts on Official Wimbledon Merchandise.
								</FormCheckBox>
								<FormCheckBox name="termsAndCondsCheckbox" id="termsAndCondsCheckbox">
									I have read, understood and agree to the{' '}
									<WimLink
										to={'/pdf/Retail_Competition_Terms_and_Conditions_2023.pdf'}
										external={true}>
										Terms and Conditions{' '}
									</WimLink>
									and would like to be entered into the Wimbledon Shopping Experience Competition.
								</FormCheckBox>
							</div>
							<div>
								<Button
									className={`btn solid ${!allowSubmit ? `disabled` : ``}`}
									onClick={formProps.submitForm}>
									Enter Now
								</Button>
							</div>
						</Fragment>
					);
				}}
			</Formik>
		);
	}

	render() {
		logger.log('[RegisterButton-RetailCompetition] render - state:%o', this.state);

		let subscribed = op.get(this.state, 'user.subscriptions.competition.email.isSubscribed', false);

		return (
			<div className={`${this.state.style} center`}>
				{!this.state.loggedIn && !this.state.gigyaLoaded ? (
					<LoadingIndicator />
				) : !this.state.loggedIn && this.state.gigyaLoaded ? (
					<h3>
						Please{' '}
						<a href="" onClick={e => this.clickLogin(e)}>
							Log in or Create a <MyWimbledonTitle /> account
						</a>{' '}
						to continue with entry into the 2024 Wimbledon Finals Weekend Shopping Experience Competition.
					</h3>
				) : !subscribed ? (
					this.getCompleteForm()
				) : (
					<Fragment>
						<h3 className="center">
							Thank you for entering the Wimbledon Shopping Experience Competition. We will be in touch
							with you on or before Monday 31st August 2023.
						</h3>
					</Fragment>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RetailCompetition);

import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { Formik, Form } from 'formik';
import { FormInput, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import * as yup from 'yup';

import axios from 'axios';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import Button from 'appdir/components/common-ui/Button';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import WimRedirect from 'appdir/components/general/WimRedirect';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch, props) => ({
	setAccountInfo: (source, data, modal) => dispatch(deps.actions.Gigya.setAccountInfoProm(source, data, modal)),
});

class PicnicRegistration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};

		this.handleSubmit = this.handleSubmit.bind(this);

		this.initialValues = {
			checkbox1: false,
		};
		//logger.log('[RegisterButton-PicnicRegistration] constructor - state:%o context:%o', this.state, this.context);
	}
	componentDidMount() {
		//logger.log('[RegisterButton-Picnicegistration] componentDidMount - state:%o context:%o', this.state, this.context);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log("[RegisterButton-PicnicRegistration] componentDidUpdate - state:%o", this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[RegisterButton-PicnicRegistration] componentWillReceiveProps - nextProps:%o', nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps.state,
			};
		});
	}

	handleSubmit() {
		let update = {};
		op.set(update, 'subscriptions.picnicRegistration.email.isSubscribed', true);
		this.props
			.setAccountInfo('picnic_register', update, true)
			.then(response => {
				MeasurementUtils.dispatchMeasurementCall('picnicSubscribeSuccess', {});
			})
			.catch(error => {});
	}

	clickLogin(e) {
		e.preventDefault();
		this.props.openScreen({
			screen: 'login_screen',
			redirect: false,
			showSuccess: false,
			cid: 'picnic_register',
		});
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema(content) {
		let schema = {};

		return yup.object(schema);
	}

	getCompleteForm() {
		let validationSchema = this.getSchema();

		return (
			<Formik
				initialValues={this.initialValues}
				validationSchema={validationSchema}
				onSubmit={this.handleSubmit}
				enableReinitialize={true}>
				{formProps => {
					let allowSubmit = formProps.values.checkbox1;
					return (
						<Fragment>
							<div>
								<Button className="btn solid" onClick={formProps.submitForm}>
									REGISTER YOUR INTEREST
								</Button>
							</div>
						</Fragment>
					);
				}}
			</Formik>
		);
	}

	render() {
		logger.log('[RegisterButton-PicnicRegistration] render - state:%o', this.state);

		let subscribed = op.get(this.state, 'user.subscriptions.picnicRegistration.email.isSubscribed', false);

		return (
			<div className={`${this.state.style} center`}>
				{!this.state.loggedIn && !this.state.gigyaLoaded ? (
					<LoadingIndicator />
				) : !this.state.loggedIn && this.state.gigyaLoaded ? (
					<h3>
						Please{' '}
						<a href="" onClick={e => this.clickLogin(e)}>
							login to / join <MyWimbledonTitle />
						</a>{' '}
						to register your interest.
					</h3>
				) : !subscribed ? (
					this.getCompleteForm()
				) : (
					<Fragment>
						<h3 className="center">
							Thank you for registering, we will be in touch once picnics are available to pre-order.
						</h3>
						{/** 
							<WimRedirect to={completePath} />
						*/}
					</Fragment>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PicnicRegistration);

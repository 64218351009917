import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import TennisScoringDataWrapper from './TennisScoringDataWrapper';
import { updatePointHistory } from './PointHistory';
import op from 'object-path';

const mapStateToProps = (state, props) => {
	return {
		...state['ScoreManager'],
		Config: state['Config'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	setMatches: matchData => dispatch(deps.actions.ScoreManager.setMatches(matchData)),
	setCurrentMatch: matchData => dispatch(deps.actions.ScoreManager.setCurrentMatch(matchData)),
	setLiveStatMatch: matchData => dispatch(deps.actions.ScoreManager.setLiveStatMatch(matchData)),
	setStatMatchStats: statData => dispatch(deps.actions.ScoreManager.setStatMatchStats(statData)),
	setStatMatchHistory: historyData => dispatch(deps.actions.ScoreManager.setStatMatchHistory(historyData)),
	clearStatMatch: () => dispatch(deps.actions.ScoreManager.clearStatMatch()),
	setScoringStatus: scoringStatus => dispatch(deps.actions.Controller.setScoringStatus(scoringStatus)),

	mount: () => dispatch(deps.actions.ScoreManager.mount()),
});

class ScoreManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			connectionReady: false,
		};
		this.started = false;
	}

	componentWillUnmount() {
		// logger.log('[ScoreManager] - componentWillUnmount');
		this.removeLocalStorage();
	}

	componentDidMount() {
		// logger.log('[ScoreManager] - componentDidMount');
		this.props.mount();

		this.removeLocalStorage();

		window.addEventListener('ixEventsScoring', e => {
			if (e.detail) {
				if (e.detail.type == 'addMatchData') {
					// logger.log('[ScoreManager] received event: %o', e.detail)
					if (this.state.statMatch.match_id != e.detail.matchId) {
						this.state.clearStatMatch();
					}
					this.state.setCurrentMatch(e.detail);
				}
			}
		});
	}

	removeLocalStorage() {
		if (window.localStorage) {
			window.localStorage.removeItem('ixScoringMatches');
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	shouldComponentUpdate() {
		if (this.state.connectionReady === true) {
			return false;
		} else {
			// logger.log('[ScoreManager] shouldComponentUpdate - yes it should');
			return true;
		}
	}

	componentDidUpdate() {
		if (this.state.connectionReady && !this.started) {
			this.started = true;
			this.props.setScoringStatus({ loaded: true });
		}
	}

	setConnectionStatus = isReady => {
		// logger.log('[ScoreManager] setConnectionStatus - isReady:%o', isReady);
		// if (!this.state.connectionReady) {
		// 	this.setState({
		// 		connectionReady: isReady,
		// 	});
		// }

		if (!isReady) {
			this.props.setMatches(null);
		}

		this.setState({
			connectionReady: isReady,
		});
	};

	processScoreUpdate(scoreData) {
		//logger.log("[ScoreManager] - processScoreUpdate: scoreData:%o", scoreData);
		let tempMatch;
		let _this = this;

		if (this.state.currentMatch) {
			tempMatch = scoreData.find(function(match) {
				return match.match_id == _this.state.currentMatch.matchId;
			});
			if (typeof tempMatch !== 'undefined') {
				this.props.setLiveStatMatch(tempMatch);
			}
		}

		this.props.setMatches(scoreData);
	}

	processPointHistoryUpdate(newHistory) {
		/** only process new points if they have the same match id a the current statMatch */
		if (this.state.statMatch && newHistory[0].MatchID == this.state.statMatch.match_id) {
			let updatedHistoryData = updatePointHistory(this.state.statMatch.pointHistory, newHistory);
			this.props.setStatMatchHistory(updatedHistoryData);
		}
	}

	render() {
		// logger.log('[ScoreManager] - render: props:%o state:%o', this.props, this.state);

		return (
			<>
				{((window.webview && (this.state.status?.slamtracker === true || this.state.status?.mip === true)) ||
					!window.webview) &&
				!this.started &&
				this.props.Config?.status == 'loaded' ? (
					<TennisScoringDataWrapper
						tournamentYear={this.props.Config.scoring.year}
						balance={parseInt(this.props.Config.scoring.pubsubBalance, 10)}
						mqttBalance={parseInt(this.props.Config.scoring.messagesightBalance, 10)}
						messageSightHostname={this.props.Config.scoring.messagesightHost}
						edgeConnectHostname={this.props.Config.scoring.edgeConnectHost}
						tokenCreator={this.props.Config.scoring.edgeConnectTokenGenerator}
						mqttPort={this.props.Config.scoring.messagesightSSLPort}
						mqttEvent={this.props.Config.scoring.messagePath}
						androidMode={this.props.Config.scoring.android_mode}
						scoresPollingFile={this.props.Config.scoringData.liveScore.path}
						statsFile={this.props.Config.scoringData.matchStat.path}
						cFile={this.props.Config.scoringData.matchHistory.path}
						uFile={this.props.Config.scoringData.matchHistoryUpdate.path}
						pollingFrequency={this.props.Config.scoring.pollingFrequency}
						retryAttempts={this.props.Config.scoring.pubsubRetries}
						connectionReady={isReady => {
							this.setConnectionStatus(isReady);
						}}
						scoresUpdated={scoreData => {
							// logger.log('[ScoreManager] Scores Data Updated! %o', scoreData);
							this.processScoreUpdate(scoreData);
						}}
						statsUpdated={statData => {
							// logger.log('[ScoreManager] Stats Data Updated! %o', statData);
							this.props.setStatMatchStats(statData);
						}}
						historyUpdated={historyData => {
							logger.log('[ScoreManager] Match History Data Updated! %o', historyData);
							let newHistory;

							if (this.state.statMatch.pointHistory.length > 0) {
								newHistory = this.state.statMatch.pointHistory.filter(point => {
									let isUpdatedPoint = false;

									for (var i = 0; i < historyData.length; i++) {
										if (point.PointNumber === historyData[i].PointNumber) {
											isUpdatedPoint = true;
										}
									}

									if (!isUpdatedPoint) {
										return point;
									} else {
										return null;
									}
								});

								newHistory = newHistory.concat(historyData);
							} else {
								newHistory = historyData;
							}

							this.processPointHistoryUpdate(newHistory);
						}}
					/>
				) : null}
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreManager);

import InsightsIndex from './index';
import deps from 'dependencies';

export default [
	{
		//path: ['/:lang/matchinsights', '/:lang/matchinsights/', '/:lang/matchinsights/index.html', '/:lang/matchinsights/:matchType/day:tournDay.html'],
		path: ['/:lang/matchinsights23/index.html'],
		exact: true,
		component: InsightsIndex,
		load: params => deps.actions.InsightsIndex.mount(params),
	},
];

import React, { Component } from 'react';
import Measure from 'react-measure';
import { logger } from '../logger';

import Cea608Parser from './Cea608Parser';
import CaptionScreen from './CaptionScreen';

import captionStyles from '../sass/captions.scss'


const initialProps = {
	
};

class Captions extends Component {
	
	constructor(props) {
		super(props);
		
		this.measureComponent;
		this.amp = props.amp;
		this.curMode;
		
		this.colWidth = 2.380952; //for 42 column 16:9 display
        //    this.colWidth = 3.125; //for 32 column 4:3 display (but if src is 608 only?)
        //this.rowHeight = 6.666667;  //for 15 rows
		this.numRows = 15;
		this.captionRange = 1;

		this.handleFragmentParsing = this.handleFragmentParsing.bind(this);
		this.onTimeUpdate = this.onTimeUpdate.bind(this);
		this.captionHandler = this.captionHandler.bind(this);
		this.commandHandler = this.commandHandler.bind(this);
		this.handleTransitionComplete = this.handleTransitionComplete.bind(this);
		this.onSeeking = this.onSeeking.bind(this);

		this.cea608Parser = Cea608Parser({
			field: 1, 
			out1: {'updateData': this.captionHandler, 'newCommand': this.commandHandler}, 
			out2: null
		});

		// initialize rows array
		let rows = this.resetCaptions(true);

		this.addAmpListeners();

		this.state = {
			...this.props,
			...initialProps.length,
			rows: rows,
			rollup: false
		}

		logger.log('Captions: constructor - state:%o', this.state);
	}
	
	componentDidMount() {
		logger.log('Captions: componentDidMount');
		
	}

	componentWillUnmount() {
		if (this.amp && this.amp.hls){
			//logger.log('Captions: addAmpListeners - this.amp:%o', this.amp);	
			this.amp.hls.removeEventListener('hlsFragParsingUserdata', this.handleFragmentParsing);
			this.amp.removeEventListener('timeupdate', this.onTimeUpdate);
			this.amp.removeEventListener('seeking', this.onSeeking);
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if (prevProps.mediaId != this.props.mediaId) {
			//logger.log('Captions: componentDidUpdate - this.props:%o', this.props);	
			this.amp = this.props.amp;
			this.addAmpListeners();

			let rows = this.resetCaptions(true);
			

			this.setState({
				rows: rows,
				rollup: false
			})
		}
	}

	addAmpListeners() {
		if (this.amp && this.amp.hls){
			//logger.log('Captions: addAmpListeners - this.amp:%o', this.amp);	
			this.amp.hls.addEventListener('hlsFragParsingUserdata', this.handleFragmentParsing);
			this.amp.addEventListener('timeupdate', this.onTimeUpdate);
			this.amp.addEventListener('seeking', this.onSeeking);
		}
	}

	/**
	 * reset the caption display
	 * prepares for new caption stream for diff video or after seek
	 * @param {Boolean} clear 
	 */
	resetCaptions(clear) {
		let rows = [];
		for (let i=0; i<this.numRows; i++) {
			let char = String.fromCharCode(65 + i);
			rows.push({
				id: char,
				position: i,
				text: ''
			});
		}

		if (clear) {
			this.messages = [];
		}

		this.lastCaptionIdx = -1;
		this.rollUpRows = null;
		this.playTime = 0;
		this.baseRow = - 1;
		this.cea608Parser.reset();

		return rows;
	}

	/**
	 * clear the caption display
	 */
	clearCaptions() {
		let rows = [];
		for (let i=0; i<this.numRows; i++) {
			let char = String.fromCharCode(65 + i);
			rows.push({
				id: char,
				position: i,
				text: ''
			});
		}

		this.setState({
			rows: rows
		})
	}

	/**
	 * get data from fragment and add byte data to message array at proper time
	 * @param {Event} event 
	 */
	handleFragmentParsing(event) {
		logger.info('Captions: handleFragmentParsing - type:%o event:%o', event.type, event);

		for (let i = 0; i < event.data.samples.length; i++) {
			let ccdatas = this.extractCea608Data(event.data.samples[i].bytes);
			if (ccdatas.length > 0)
				this.messages.push({'time': event.data.samples[i].pts, 'data':ccdatas})
		}
	}

	/**
	 * extra cea-608 data bytes from a bytearray in fragment data
	 * @param {ByteArray} byteArray 
	 */
	extractCea608Data(byteArray) {
		let count = byteArray[0] & 31;
		let position = 2;
		let tmpByte, ccbyte1, ccbyte2, ccValid, ccType;
		let actualCCBytes = [];

		for (let j = 0; j < count; j++) {
			tmpByte = byteArray[position++];
			ccbyte1 = 0x7F & byteArray[position++];
			ccbyte2 = 0x7F & byteArray[position++];
			ccValid = (4 & tmpByte) === 0 ? false : true;
			ccType = 3 & tmpByte;

			if (ccbyte1 === 0 && ccbyte2 === 0) {
				continue;
			}

			if (ccValid) {
				if (ccType === 0) // || ccType === 1
				{
					actualCCBytes.push(ccbyte1);
					actualCCBytes.push(ccbyte2);
				}
			}
		}
		return actualCCBytes;
	}

	/**
	 * use time updates to determine which caption messages to display
	 * helps align caption to video so don't play captions as arrive in segment
	 * @param {Object} data 
	 */
	onTimeUpdate(data) {
		//logger.log('Captions: onTimeUpdate - data:%o', data);

		let current = [];

		this.playTime = this.amp.getCurrentTime();
		
		// Used to determine buffered data in order to reset captions if seek outside the buffer
		//logger.info('updateTime - playTime:%o bufStart:%o bufEnd:%o', playTime, data.buffered[0].start, data.buffered[0].end);
		// if (data.buffered[0]) {
		// 	bufferStart = data.buffered[0].start;
		// 	bufferEnd = data.buffered[0].end;
		// }
		
		let ftime = (this.messages && this.messages[0]) ? this.messages[0].time : 'na';
		//logger.info('Captions: onTimeUpdate - lastCaptionIdx:%o time:%o ftime:%o len:%o', this.lastCaptionIdx, this.playTime, ftime, this.messages.length);

		//find the first index if it has been reset
		//  stop processing if not set
		if (this.lastCaptionIdx == -1) {
			for (let k = 0; k < this.messages.length; k++) {
				if (this.playTime <= this.messages[k].time) {
					this.lastCaptionIdx = k;
					//logger.info('updateTime - lastIndx:%o msgLength:%o', lastCaptionIdx, messages.length);
					break;
				}
			}
		}

		if (this.lastCaptionIdx < 0)
			return;

		//loop from end of captions list to last captured time
		//  find all messages less than the current play time and copy to current array
		//  save index of last captured time
		for (let i = this.messages.length - 1; i > this.lastCaptionIdx; i--) {
			if (this.playTime >= this.messages[i].time) {
				current = this.messages.slice(this.lastCaptionIdx, i + 1);
				this.lastCaptionIdx = i+1;
				//logger.info('Captions: onTimeUpdate - time:%o i:%o cur:%o', this.playTime, i, current);
				break;
			}
		}

		//if captions enabled, process all current captions within 1 second of current time
		// prevents older captions that are in initial messages list
		if (this.props.active && current.length) {
			//logger.info('updateTime - curLength:%o', current.length);
			for (let j = 0; j < current.length; j++) {
				//logger.info('updateTime - playTime:%o cur:%o', playTime, current[j].time);
				if (this.playTime >= current[j].time - this.captionRange) 
					this.cea608Parser.addData(current[j].time, current[j].data);
			}
		}
	}

	/**
	 * reset captions when video is seeking
	 */
	onSeeking() {
		let rows = this.resetCaptions();

		this.setState({
			rows: rows
		})
	}

	/**
	 * process caption commands coming form parser
	 * @param {Number} time 
	 * @param {Cea608Screen} screen 
	 */
	captionHandler(time, screen) {
		let rows = screen.getTextAndFormat();
		//logger.info('Captions: captionHandler - rows:%o currRow:%o rollupRoll:%o', rows, screen.currRow(), screen.rollUpRow());
		//logger.info('Captions: captionHandler - currRow:%o rollupRoll:%o text:%o', screen.currRow(), screen.rollUpRow(), screen.getDisplayText(true));

		if (screen.rollUpRow() != this.rollUpRows || screen.currRow() != this.baseRow) {
			this.rollUpRows = screen.rollUpRow();
			this.baseRow = screen.currRow();
			//logger.info('Captions: captionHandler - base:%o rollUp:%o', this.baseRow, this.rollUpRows);
			//this.setClipArea(this.baseRow, this.rollUpRows);
		}

		for (let i=0; i < rows.length; i++) {
			//logger.info('Captions: captionHandler - i:%o mode:%o row:%o txt:%o', i, this.curMode, rows[i], rows[i].getTextString());

			if (rows[i].getTextString() != '') {
				let idx = this.state.rollup ? (i+1) : i;
				this.setCaptionState(idx, rows[i].getTextString());
			}
			else if (this.curMode == 'MODE_POP-ON'){
				//$(captionRows[i].elem).find('.captionText').html('');
			}
		}
		
	}

	/**
	 * process cea608 command comeing from the parser
	 * @param {Number} time 
	 * @param {Object} data 
	 */
	commandHandler(time, data) {
		//logger.info('Captions: commandHandler - play:%o time:%o cmd:%o', this.playTime, time, data);
		
		if (data.cmd == 'mode') {
			this.curMode = data.value;
			//logger.info('Captions: commandHandler - %o - *********************************', data.value);
		}

		if (data.cmd == 'rollup') {
			//logger.info('Captions: commandHandler - play:%o time:%o cmd:%o', this.playTime, time, data.cmd);
			
			if (!this.state.rollup){
				//logger.info('Captions: commandHandler - rollup - *********************************');
				this.setState({
					rollup: true
				})
			}
			
		}

		else if (data.cmd == 'erase') {
			//logger.info('Captions: commandHandler - %o - *********************************', data.value);
			this.clearCaptions();
		}

		else {
			//logger.info('Captions: commandHandler - %o - *********************************', data.value);
		}
	}

	/**
	 * return the style object for the clip area
	 */
	getClipAreaStyle() {
		let top = (this.baseRow - this.rollUpRows + 1) * this.rowHeight + 'px';
		let height = this.rollUpRows * this.rowHeight + 'px';
		//height = this.windowHeight + 'px';

		return {
			top: top,
			height: height
		}
	}

	/**
	 * set the current caption text into rows for rendering
	 * define the left percentage offset (can be px at some pt)
	 * @param {int} idx 
	 * @param {String} text 
	 */
	setCaptionState(idx, text) {
		let rowDisp = [...this.state.rows];
		let elementPos = 0;

		rowDisp[idx]['text'] = text;

		var leading = text.search(/\S/);
		rowDisp[idx]['left'] = ((leading * this.colWidth) + '%');

		//logger.info('Captions: setCaptionState - i:%o row:%o ', idx, text);

		//logger.info('Captions: setCaptionState - i:%o elementPos:%o row:%o ', idx, elementPos, rowDisp);
		this.setState({
			rows: rowDisp
		});
	}

	/**
	 * After CSS rollup transition, move first row to last and update state
	 */
	handleTransitionComplete() {
		if (this.state.rollup){
			let rowDisp = [...this.state.rows];
			let firstPos = rowDisp[rowDisp.length - 1]['position'].valueOf();

			let firstRow = rowDisp.shift();
			firstRow['text'] = '';
			rowDisp.push(firstRow);

			//logger.info('Captions: handleTransitionComplete - firstPos:%o rowDisp:%o', rowDisp);
			this.setState({
				rollup: false,
				rows: rowDisp
			});
		}
	}

	/**
	 * reset size properties on Measure object resize event
	 * @param {Object} contentRect 
	 */
	onResize(contentRect) {
		//logger.info('Captions: onResize - contentRect:%o', contentRect.bounds);
		this.windowHeight = contentRect.bounds.height;
		this.rowHeight = this.windowHeight / this.numRows;
		//logger.log('Captions: onResize - rowHeight:%o', this.rowHeight);
	}

	
	render() {	
		//logger.log('Captions: render - state:%o', this.state);
		//style={this.windowStyle}

		if (this.props.active) {

		return (
			<Measure
				bounds
				measure={this}
				onResize={(contentRect) => {this.onResize(contentRect)}}
				ref={(component) => {
					this.measureComponent = component
				}}
			>
			{({ measureRef }) =>

				<div className={captionStyles.overlayCaptions} ref={measureRef} >
					<CaptionScreen 
						baseRow={this.baseRow}
						rollUpRows={this.rollUpRows}
						rowHeight={this.rowHeight}
						windowHeight={this.windowHeight}
						rollup={this.state.rollup}
						rows={this.state.rows}
						onTransitionFinish={() => this.handleTransitionComplete()}
					></CaptionScreen>
				</div>

			}
			</Measure>
		)
		}
		else {
			return null;
		}
	}
}

export default (Captions);


import PDEMatchStats from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/s/player/info/match-stats/:yearId/:matchId.html'],
		exact: true,
		component: PDEMatchStats,
		// load: params => deps.actions.PDEMatchStats.mount(params),
	},
];

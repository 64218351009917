import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetchSOMETHING = () => {
	let hdr = restHeaders();
	return axios.get(`${restAPI}/hello`, { pageHeaders: hdr }).then(({ data }) => data);
};

export default {
	fetchSOMETHING,
};

import React from 'react';
import StatusDevice from './StatusDevice';
const SummaryBox = ({ courts, other }) => {
	return (
		<div className="summary-box">
			<div className="summary-box__court-names">
				{courts &&
					Object.keys(courts)?.map(courtKey => (
						<React.Fragment key={courts[courtKey]?.name}>
							<StatusDevice court={courts[courtKey]} courtKey={courtKey} type="summary-box" />
						</React.Fragment>
					))}
			</div>
			<p className="summary-box__description">{other?.courtAvailabilityMemberNotes}</p>
			<div className="summary-box__published-section">
				<p className="summary-box__published-date">Published: {other?.lastupdate || 'Not available'}</p>
			</div>
		</div>
	);
};

export default SummaryBox;

import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import Headshot from '../../common-ui/Headshot';
import { getQuerystringValues } from 'appdir/components/general/Util';
import WimLink from 'appdir/components/general/WimLink';
import { measureInAppContext } from 'appdir/components/general/Analytics';

// stylesheet: _player-general-info-webview.scss
class PlayerGeneralInfoWebview extends Component {
	constructor(props) {
		super(props);
		this.parsedQs = getQuerystringValues();
		this.state = { appFavs: this.parsedQs?.filterPlayers?.split(',') || [] };
		logger.log('this.parsedQs', this.state?.appFavs);
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	componentDidUpdate(prevState) {
		logger.log('[PlayerGeneralInfoWebview] - state: %o, props: %o', this.state, this.props);
	}

	isWebviewFav = id => {
		logger.log('start of isWebviewFav', this.state.appFavs);
		// let appFavs = this.parsedQs;
		if (this.state.appFavs.length > 0) {
			logger.log(
				'[PlayerProfile] - isWebviewFav - favs:%o, %o:%o',
				this.state.appFavs,
				this.state.appFavs.indexOf(id) !== -1
			);
			return this.state.appFavs.indexOf(id) !== -1;
		} else {
			return false;
		}
	};

	updateFavs = id => {
		// let appFavs = this.parsedQs;
		let playerFavs = Object.assign([], this.state.appFavs);
		let shouldAdd = true;
		let contextKey = 'favorite_add';

		if (this.isWebviewFav(id)) {
			// remove fav
			playerFavs = this.state.appFavs.filter(fav => fav !== id);
			shouldAdd = false;
			contextKey = 'favorite_remove';
		} else {
			// add fav
			playerFavs.push(id);
		}

		measureInAppContext({
			pageTitle: 'Players:Player Profiles',
			action: 'Favorite',
			args: shouldAdd ? 'Add' : 'Remove',
			context: [{ sectionFavorited: 'Player Profiles' }, { [contextKey]: id }],
			metricType: 'action',
		});

		/** update favorites list for webview */
		this.setState({ appFavs: playerFavs });
		logger.log('[PlayerGeneralInfoWebview] - updateFavs - favs:%o', this.state.appFavs);
	};

	render() {
		let playerInfo = this.props.data.playerObj;
		let { flagImagePathLarge = '' } = this.props.data.playerConfig.pathObj;

		return (
			<div className="player-profile-info-webview">
				<div className="general-info-item">
					<div className="player-profile-header">
						<div className="player-profile-left">
							<div className="flag-player-name">
								{playerInfo.nation.code !== 'IOA' && (
									<div className="player-flag ">
										<img
											src={
												'code' in playerInfo.nation ? (
													flagImagePathLarge.replace('<code>', playerInfo.nation.code)
												) : (
													<Skeleton />
												)
											}
										/>
									</div>
								)}
								<div className="player-name ">
									<span className="first-name">{playerInfo.first_name}</span>
									<span className="last-name">{playerInfo.last_name}</span>
								</div>
							</div>
							<div className="singles-ranking">
								{playerInfo.rank.current_singles ? (
									<div className="ranking">
										<div className="value">{playerInfo.rank.current_singles}</div>
										<div className="field">
											Singles
											<br />
											Ranking
										</div>
									</div>
								) : null}
							</div>
						</div>

						<div className="player-headshot">
							<Headshot type="full" id={playerInfo.id} />
							<span className="player-profile--hide-point"></span>
						</div>
						<div className="player-profile-right">
							<div className="fav-star-player-profile">
								{/* <FavouriteStar
									id={playerInfo.id}
									type="players"
									unFavColor="green"
									favColor="yellow"
									alwaysShow={true}
									favourite_list={{ players: this.parsedQs }}
								/> */}
								<WimLink
									to={
										this.isWebviewFav(playerInfo.id)
											? `/removePlayerFav/${playerInfo.id}.html`
											: `/addPlayerFav/${playerInfo.id}.html`
									}
									external={true}>
									<span
										tabIndex="0"
										onClick={() => this.updateFavs(playerInfo.id)}
										className={`favorite ${
											this.isWebviewFav(playerInfo.id) ? 'favorited' : 'favorite'
										}`}></span>
								</WimLink>
							</div>

							<div className="doubles-ranking">
								{playerInfo.rank.current_doubles ? (
									<div className="ranking">
										<div className="value">{playerInfo.rank.current_doubles}</div>
										<div className="field">
											Doubles
											<br />
											Ranking
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default PlayerGeneralInfoWebview;

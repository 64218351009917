import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';

export default class CaptionSelect extends Component {
    constructor(props) {
		super(props);
        // this.state = {
        //     selected: -1
        // }
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, idx){
        logger.log("[CaptionSelect] idx:%o, this.props:%o", idx, this.props);
        

        // this.setState({selected: idx});

        this.props.switchCaption(idx);
    }

    handleClose(){
        this.props.closeHandler(false);
    }

    render() {
        let {styles, isOpen, selected, captionArr} = this.props;
        // let {selected} = this.state;

        logger.log('CaptionSelect: tracks:%o', captionArr);
        /* reusing styles from the audio track selection */

		let highlightColor = {color:this.props.colors.highlightColor}

        return (
            isOpen ?
            <div className={styles.audioTracks}>
                <div className={styles.inner}>
                    <div className={styles.audioTrackHeader}>
                        <div>Caption Selection</div>
                        <div className={styles.audioTracksClose}>
                            <button 
                                className={`${styles.icon} ${styles.closex}`} 
                                style={{}} 
                                onClick={() => this.handleClose()} 
                                // data-state={data_state}
                                title="Close">
                            </button>
                        </div>
                    </div>
                    <div className={styles.trackList}>
                        <ul>
                            <li 
                                data-state={-1 == selected ? `selected` : ``} 
                                style={-1 == selected ? highlightColor : {}}
                                aria-selected={-1 == selected ? 'true' : 'false'}
                                onClick={(e) => this.handleClick(e, -1)}
                                onKeyDown={(e) => {e.key === 'Enter' || e.key === ' ' ? this.handleClick(e, -1) : null}}
                                tabIndex={0}
                                aria-label={"off"}
                                role={"option"}
                            >
                            Off    
                            </li>
                            {captionArr.map((i, idx) => {
                                return (
                                    <li 
                                        data-state={idx == selected ? `selected` : ``} 
										style={idx == selected ? highlightColor : {}}
                                        aria-selected={idx == selected ? 'true' : 'false'}
                                        onClick={(e) => this.handleClick(e, idx)}
                                        onKeyDown={(e) => {e.key === 'Enter' || e.key === ' ' ? this.handleClick(e, idx) : null}}
                                        tabIndex={0}
                                        aria-label={i.label}
                                        role={"option"}
                                        key={`captiontrack${idx}`}
                                    >
                                        {`${i.label}`}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div> : null
        );
    }
}
import classNames from 'classnames';
import React, { Component } from 'react';
import { handleReturnTicketToSender } from 'appdir/components/pages/Tickets/ReusableTicketActionFuncs';
import CustomActionConfirmation from 'appdir/components/pages/Tickets/elements/TicketActionViews/CustomActionConfirmation';
import { measureInAppContext } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';

class ReturnToSender extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
		};
		this.onHandleTicketAction = this.onHandleTicketAction.bind(this);
	}

	componentDidMount() {
		measureInAppContext({
			pageTitle: 'Tickets',
			action: 'Return to Sender',
			args: [],
			metricType: 'state',
		});
	}

	onHandleTicketAction(which, error, msg, msg2) {
		// logger.log('[ReturnToSender] - onHandleTicketAction - which:%o, error:%o, msg:%o, msg2:%o', which, error, msg, msg2)
		const { fullTicketValue } = this.props;
		let ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];

		this.setState({ email: ticketValue[0]?.source?.transfer?.fromEmail || '' });

		if (error) {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Return Ticket Sumbit',
					args: 'Failure',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall(`Return Ticket Submit Failure`);
			}

			let message1, message2;
			let message1Default = 'There was an error processing your request. Please try again.';
			let message2Default = '';

			const errCode = error?.data?.response?.failure[0] ? error.data.response.failure[0].errorCode : '';

			/** if there is no error code, use default messages */
			if (!errCode) {
				if (error?.msg) {
					message1 = error?.msg;
					message2 = message2Default;
				} else {
					message1 = message1Default;
					message2 = message2Default;
				}
			} else {
				message1 = message1Default;
				message2 = error?.data?.response?.failure[0]?.errorMessage
					? error?.data?.response?.failure[0]?.errorMessage
					: message2Default;
			}

			this.props.showModal({
				showModal: false,
				showCloseBtn: false,
				type: 'error',
				title: `${which} Error`,
				message: `${message1} ${message2}`,
				useTimer: false,
			});

			// show confirmation table
			this.setState({ showConfirmationModal: true, isError: `${message1} ${message2}` });
		} else {
			if (window.webview) {
				measureInAppContext({
					pageTitle: 'Tickets',
					action: 'Return Ticket Sumbit',
					args: 'Successful',
					metricType: 'action',
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall(`Return Ticket Submit Successful`);
			}

			if (this.props.onSetResponse) this.props.onSetResponse(true);
			if (this.props.onSetActionSubmitted) this.props.onSetActionSubmitted();

			// // refresh the list
			// this.props.onRefreshTicketData();

			setTimeout(() => {
				this.props.showModal({
					showModal: false, // false because we have custom modal to show, but still need to update the status type to success otherwise the updating modal will continue showing
					showCloseBtn: false,
					type: 'success',
					title: `${which} Success`,
					message: `${msg || ''} ${msg2 || ''}`,
					useTimer: false,
				});

				// show confirmation table
				this.setState({ showConfirmationModal: true, isError: false });
			}, 1000);
		}
	}

	render() {
		const { closeModal, fullTicketValue, callTicketActions, ticketStubToRender } = this.props;

		let ticketValue = fullTicketValue.length > 0 ? fullTicketValue : [fullTicketValue];
		const spectatorId = ticketValue[0]?.spectatorId || '';

		let transferredSourceEmail = ticketValue[0]?.source?.transfer?.fromEmail || '';

		return (
			<div>
				{!this.state.showConfirmationModal ? (
					<div className="ticket-action-modal-info-txt-container">
						<div
							className="ticket-action-modal-information-text"
							tabIndex={0}
							aria-label={`Are you sure you want to return the ticket to ${transferredSourceEmail}`}>
							<span>
								Are you sure you want to return the ticket to <b>{transferredSourceEmail}</b>?
							</span>
						</div>
						<div className="ticket-action-modal-ticket-stub ticket-action-modal-section">
							{ticketStubToRender}
						</div>
						<div className="ticket-stub-actions-btns-cntr">
							<button
								role="button"
								className={classNames('ticket-stub-actions-value', 'ticket-action-modal-button-green')}
								onClick={() => {
									this.props.showModal({
										showModal: true,
										showCloseBtn: false,
										type: 'updating',
										title: 'Return in Progress',
										message: `Please wait while the ticket is being returned to: ${transferredSourceEmail.bold()}`,
										useTimer: false,
									});
									handleReturnTicketToSender(
										spectatorId,
										transferredSourceEmail,
										ticketValue,
										callTicketActions,
										this.onHandleTicketAction,
										`The ticket transfer has been returned to ${transferredSourceEmail.bold()}`
									);
								}}
								type="submit">
								Return
							</button>
							<button role="button" className="ticket-stub-actions-value" onClick={() => closeModal()}>
								Cancel
							</button>
						</div>
					</div>
				) : (
					<CustomActionConfirmation
						fullTicketValue={ticketValue}
						recipientEmail={transferredSourceEmail}
						closeModal={closeModal}
						ticketData={this.props.ticketData}
						onRenderMultipleTicketStubs={(data, error, email) =>
							this.props.onRenderMultipleTicketStubs(data, error, email)
						}
						onRenderSingleTicketStub={(data, error, email) =>
							this.props.onRenderSingleTicketStub(data, error, email)
						}
						headerTxt={
							this.state.isError ? (
								<div>
									{fullTicketValue.length > 1 ? 'Your ticket(s) were not returned to sender' : null}
								</div>
							) : this.props.isWebView ? (
								'This ticket has been returned to the sender. It may take a few minutes to appear in their app.'
							) : (
								'The ticket has been returned to the sender. The ticket status may take a few minutes to update.'
							)
						}
						detailsTxt={
							this.state.isError && (!fullTicketValue.length || fullTicketValue.length === 1) ? (
								<div style={{ color: 'black', textAlign: 'center' }}>
									Your ticket was not returned to <b>{transferredSourceEmail || this.state.email}</b>.
									<br></br>
									{this.state.isError}
								</div>
							) : (
								<div className="ticket-action-modal-email-label">
									Recipient email address:
									<span className="ticket-action-modal-email">
										{transferredSourceEmail || this.state.email}
									</span>
								</div>
							)
						}
						actionType={'Return to Sender'}
						customActionUpdateTxt="Returned ticket"
						error={this.state.isError}
						isWebView={this.props.isWebView}
						helpCenterUrl={this.props.helpCenterUrl}
					/>
				)}
			</div>
		);
	}
}

export default ReturnToSender;

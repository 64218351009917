/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import { handleExitVirtualQueueEndpoint } from 'appdir/components/webviews/VirtualQueueWebview/virtualQueueEndpoints.js';
import { measureInAppContext } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ExitVirtualQueueConfirmation
 * -----------------------------------------------------------------------------
 */

const ExitVirtualQueueConfirmation = props => {
	return (
        <div className="resale-modal-overlay">
            <div
                className='resale-modal-content'
            >
                 <div 
                    className="resale-modal-close-btn"
                    onClick={() => {
						measureInAppContext({
							pageTitle:"Virtual Queue",
							action: "Close",
							args: [],
							context:  [{ section: "Virtual Queue" }]
						});
                    }}>
                    <CloseBtn closeFn={props.closeModal} />
                </div>
                <div className="ticket-action-modal-title">EXIT QUEUE</div>
                <div className="ticket-action-modal-divider-container">
                    <div className="ticket-action-modal-title-divider" />
                </div>
                <div className="ticket-action-modal-information-text">
                    <span
                        tabIndex={0}
                        aria-label={'Are you sure you want to exit the queue? Doing so will mean you lose access to queue information and communications.'}>
                        Are you sure you want to exit the queue? Doing so will mean you lose access to queue information and communications.
                    </span>
                </div>
                <div className="ticket-stub-actions-btns-cntr">
                    <button
                        role="button"
                        aria-label='Yes exit the queue'
                        className='ticket-stub-actions-value ticket-action-modal-button-green'
                        onClick={() => {
                            props.closeModal();
                            props.getJWT().then(token => {

                                measureInAppContext({
                                    pageTitle:"Virtual Queue",
                                    action: "Exit Queue",
                                    args: [],
                                    context:  [{ section: "Virtual Queue" }]
                                });
                                
                                props.showModal({
                                    showModal: true,
                                    showCloseBtn: false,
                                    type: 'updating',
                                    title: 'Exiting Virtual Queue',
                                    message: 'Please do not close the window while submitting.',
                                    useTimer: false,
                                });
                    
                                let path = props.queueAPIs?.virtualExitQueue;
                                handleExitVirtualQueueEndpoint(token.jwt.id_token, path, props.booking_id, props.queue_id).then((response) => {
                                    logger.log('[ExitVirtualQueueConfirmation] handleExitVirtualQueueEndpoint response %o', response);
                                    props.exitQueueResponse('success', response)
                                    props.showModal({
                                        showModal: false,
                                        showCloseBtn: true,
                                        type: 'success',
                                        title: 'Success',
                                        message: 'You have exited the queue',
                                        useTimer: false,
                                    });
                                }).catch(error => {
                                    logger.error('[ExitVirtualQueueConfirmation] handleExitVirtualQueueEndpoint - error:%o', error);
                                    props.showModal({
                                        showModal: true,
                                        showCloseBtn: true,
                                        type: 'error',
                                        title: 'Error Exiting Queue',
                                        message: error?.message || 'An error has occured. Please try again',
                                        useTimer: false,
                                    });
                                    props.exitQueueResponse('error', error)
                                });
                            });
                        }}
                        type="submit">
                        Yes
                    </button>
                    <button 
                        aria-label="no do not exit the queue" 
                        role="button" 
                        className="ticket-stub-actions-value" 
                        onClick={() => {
                            measureInAppContext({
                                pageTitle:"Virtual Queue",
                                action: "Close",
                                args: [],
                                context:  [{ section: "Virtual Queue" }]
                            });
                            props.closeModal();
                        }}>
                        No
                    </button>
                </div>
            </div>
        </div>
    )
};

export default ExitVirtualQueueConfirmation;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	//logger.log('[AICatchUpFullWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.AICATCHUPFULLWEBVIEW_LOAD:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[AICatchUpFullWebview] reducers AICATCHUPFULLWEBVIEW_LOAD - newState:%o',newState);
			return newState;

		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import axios from 'axios';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PlayerImage from 'appdir/components/content/PlayerImage';
import Button from 'appdir/components/common-ui/Button';
import FavourtingPlayersList from './FavourtingPlayersList';
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';
import { values } from 'appdir/main';
import { Link } from 'react-router-dom';

/**
 * -----------------------------------------------------------------------------
 * React Component: Favourting
 * -----------------------------------------------------------------------------
 */

const mobileMatch = window.matchMedia('(max-width: 767px)').matches;

const letterList = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];
class Favourting extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
			genderTab: 'All',
			subCategoryTab: 'A-Z',
			playersList: [],
			searchedValue: [],
			searchValueInput: '',
			filteredData: '',
		};
		this.loadedJson = false;
		this.onSearch = this.onSearch.bind(this);
	}

	componentDidMount() {
		logger.log('[Favourting] componentDidMount - props:%o loadedJson', this.props, this.loadedJson);

		// add event listener for page refresh so that user wont be shown favourting again if they refresh page
		window.addEventListener('beforeunload', this.handleBeforeUnload);

		MeasurementUtils.dispatchMeasurementCall('pageView', {
			pageTitle: 'onboardingFavorites',
		});

		if (!this.loadedJson && this.props.configVal?.status === 'loaded' && !this.state.playersList.length) {
			this.loadPlayerData();
		}
	}

	componentDidUpdate() {
		logger.log('[Favourting] componentDidUpdate - props:%o loadedJson', this.props, this.loadedJson);

		if (!this.loadedJson && this.props.configVal?.status === 'loaded' && !this.state.playersList.length) {
			this.loadPlayerData();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.favourites?.players?.length !== nextProps.favourites?.players?.length) {
			// update filtered favourites
			let favouritesFiltered = this.state.playersList.filter(player => {
				return nextProps.favourites.players.indexOf(player.id) !== -1;
			});
			this.setState({ favouritesFiltered });
		}
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
	}

	handleBeforeUnload = () => {
		if (this.props.onUnmount) {
			this.props.onUnmount();
		}
	};

	onSearch(searchValue) {
		logger.log('[Favourting] onSearch - value', searchValue);
		this.setState({ searchedValue: [searchValue] });
	}

	sortCountryList(countryList, obj) {
		const hiddenCountry = values?.countryHide;

		// Players with no country, or country code of 'IOA' should display under a blank country header and sort to the bottom of the country list
		let ioaCountries = [];
		let otherCountries = [];
		let emptyCountry = '' || null;

		countryList.map(country => {
			if (country === '') {
				emptyCountry = country;
			} else if (hiddenCountry.includes(obj[country])) {
				ioaCountries.push(country);
			} else {
				otherCountries.push(country);
			}
		});

		// ioaCountries.sort();
		otherCountries.sort();

		// let sortedCountryList = otherCountries.concat(ioaCountries, [emptyCountry]);
		let sortedCountryList = otherCountries;
		return sortedCountryList;
	}

	loadPlayerData() {
		logger.log('[Favourting] loadPlayerData - props:%o', this.props);

		axios({
			method: 'get',
			url: this.props.playersPath,
		})
			.then(result => {
				logger.log('[Favourting] loadPlayerData result:%o', result);
				this.loadedJson = true;

				// get all countries
				let countries = [];
				let countryObj = {};

				let seedValuesExists = false;
				let res = result?.data?.players;
				result?.data?.players.map(player => {
					// check if false so only set once. need to check if seedValue exists, otherwise will show ranking tab
					if (!seedValuesExists && player.seedValue) seedValuesExists = true;

					// create country obj for alphabtical list scroll navigation in countries tab
					if (countries.indexOf(player.country_long) === -1) {
						countryObj[player.country_long] = player.country;
						countries.push(player.country_long);
					}
				});

				// sort countries, move country code == IOA to end
				countries = this.sortCountryList(countries, countryObj);
				let favouritesFiltered,
					ladiesFiltered,
					gentlemenFiltered = res;

				// filter main tabs (Ladies, Gents, Favourites) upfront to save load time - rather than doing so when tab changes. also decreases size that has to be filtered when subtabs (seeds/ranking, countries, a-z) are selected
				// filter favourites
				favouritesFiltered = result?.data?.players.filter(player => {
					return this.props.favourites.players.indexOf(player.id) !== -1;
				});

				// // filter ladies
				ladiesFiltered = result?.data?.players.filter(player => {
					return player.gender === 'F';
				});

				// // filter gentlemen
				gentlemenFiltered = result?.data?.players.filter(player => {
					return player.gender === 'M';
				});

				this.setState({
					playersList: result?.data?.players,
					error: false,
					countries: countries,
					countryObj: countryObj,
					seedValuesExists,
					favouritesFiltered,
					ladiesFiltered,
					gentlemenFiltered,
				});
			})
			.catch(error => {
				logger.error('[Favourting] loadPlayerData error:%o', error);
				this.loadedJson = true;
				this.setState({ playersList: [], error: error });
			});
	}

	onSortAndFilter(playersList, tab, genderSort) {
		if (tab === 'Seeds') {
			return playersList
				.filter(player => {
					return player.seedValue && player.seedValue > 0;
				})
				.sort((a, b) => {
					if (genderSort) {
						let genderOrder = { M: 0, F: 1 };
						if (a.seedValue !== b.seedValue) {
							return a.seedValue - b.seedValue; // Sort by seedValue in ascending order
						} else {
							return genderOrder[a.gender] - genderOrder[b.gender]; // Sort by gender (M before F)
						}
					} else {
						return a.seedValue - b.seedValue;
					}
				});
		} else if (tab === 'Ranking') {
			return playersList
				.filter(player => {
					return player.singles_rank && player.singles_rank > 0;
				})
				.sort((a, b) => {
					if (genderSort) {
						let genderOrder = { M: 0, F: 1 };
						if (a.singles_rank !== b.singles_rank) {
							return a.singles_rank - b.singles_rank; // Sort by seedValue in ascending order
						} else {
							return genderOrder[a.gender] - genderOrder[b.gender]; // Sort by gender (M before F)
						}
					} else {
						return a.singles_rank - b.singles_rank;
					}
				});
		} else if (tab === 'Countries') {
			return playersList;
		} else if (tab === 'A-Z') {
			// just sort (if All && A-Z selected)
			return playersList.sort((a, b) => {
				if (a.last_name < b.last_name) {
					return -1;
				}
				if (a.last_name > b.last_name) {
					return 1;
				}
				return 0;
			});
		}
	}

	onTabSelected(gender, subCategory) {
		// scroll to top of list
		if (document.getElementById('favourting-players-list'))
			document.getElementById('favourting-players-list').scrollTop = 0;
		let genderTab = gender || this.state.genderTab;
		let subCategoryTab = subCategory || this.state.subCategoryTab;

		// sort players list by tab selected
		let playersList;
		let listToReturn;

		switch (genderTab) {
			case 'All':
				playersList = this.state.playersList;
				listToReturn = this.onSortAndFilter(playersList, subCategoryTab, true);
				break;
			case 'Ladies':
				playersList = this.state.ladiesFiltered;
				listToReturn = this.onSortAndFilter(playersList, subCategoryTab);
				break;
			case 'Gentlemen':
				playersList = this.state.gentlemenFiltered;
				listToReturn = this.onSortAndFilter(playersList, subCategoryTab);
				break;
			case 'favourites':
				playersList = this.state.favouritesFiltered;
				listToReturn = this.onSortAndFilter(playersList, subCategoryTab, true);
				break;
			default:
				listToReturn = this.state.sortedPlayerList || this.state.playersList;
				break;
		}

		if (subCategory) {
			this.setState({
				subCategoryTab: subCategoryTab,
				sortedPlayerList: listToReturn,
				filteredData: '',
				searchValueInput: '',
			}); // reset search bar on tab change with filteredData: '' & searchValueInput: ''
		} else if (gender) {
			this.setState({
				genderTab: genderTab,
				sortedPlayerList: listToReturn,
				filteredData: '',
				searchValueInput: '',
			});
		} else {
			this.setState({ sortedPlayerList: listToReturn, filteredData: '', searchValueInput: '' });
		}
	}

	scrollToListLetter(letter, isCountry) {
		let id_value;
		if (isCountry) {
			// find first country with that letter
			let firstCountryIndex = this.state.countries.findIndex(country => country?.charAt(0) === letter);
			id_value = this.state.countries[firstCountryIndex];
		} else {
			id_value = letter;
		}

		let scrollTopOffset = 0;
		if (mobileMatch) {
			scrollTopOffset = this.props.isPromo
				? this.state.genderTab === 'favourites' && this.state.subCategoryTab === 'A-Z'
					? 350
					: 300
				: this.state.genderTab === 'favourites' && this.state.subCategoryTab === 'A-Z'
				? 258
				: 212;
		} else {
			scrollTopOffset = this.props.isPromo
				? this.state.genderTab === 'favourites' && this.state.subCategoryTab === 'A-Z'
					? 230
					: 188
				: this.state.genderTab === 'favourites' && this.state.subCategoryTab === 'A-Z'
				? 196
				: 146;
		}

		if (document.getElementById(`favourting-letter-section${id_value}`)) {
			const el = document.getElementById(`favourting-letter-section${id_value}`);
			var topPos = el.offsetTop - scrollTopOffset; // subtract px so it's in view
			document.getElementById(`favourting-players-list${this.props.isPromo}`).scrollTop = topPos;
		}
	}

	onClearAllFavourites() {
		logger.log('clearing all players');
		let updatedFav = {};
		let favArray = {
			...this.props.favourites,
		};

		favArray.players = [];
		updatedFav = favArray;
		this.props.clearPlayers({ favourites: { ...updatedFav } });

		this.setState({ favouritesFiltered: [] });
	}

	onSearchInputChange(event) {
		let newValue = event.target.value;

		if (this.state.genderTab !== 'All' || this.state.subCategoryTab !== 'A-Z') {
			this.setState({
				genderTab: 'All',
				subCategoryTab: 'A-Z',
				searchValueInput: newValue,
			});
		} else {
			this.setState({
				searchValueInput: newValue,
			});
		}

		let value = newValue;
		let playerList = this.state.playersList;
		// logger.log('[Favourting] onSearchInputChange - playerList:%o', playerList);
		let playerSearchData = [];

		playerList.map((p, i) => {
			playerSearchData.push({
				first_name: p.first_name,
				last_name: p.last_name,
				country: p.country,
				country_long: p.country_long,
				gender: p.gender,
				seedValue: p.seedValue,
				singles_rank: p.singles_rank,
				id: p.id,
			});
		});

		let filterData = playerSearchData.filter(d => {
			return (
				d.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				d.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
			);
		});

		this.setState({ filteredData: filterData });
	}

	render() {
		const hiddenCountry = values?.countryHide;

		if (this.state.error) {
			return (
				<div className="favouriting-error-container">
					<div className="two-col margin favourting-comp">There was an error loading the players.</div>
				</div>
			);
		} else {
			let subCategoryTabArr = this.state.seedValuesExists
				? ['Seeds', 'Countries', 'A-Z']
				: ['Ranking', 'Countries', 'A-Z'];

			// filteredData = updated data when user types in search bar, sortedPlayerList = sorted based on tabs selected, playersList = full list
			let playerListArr;
			if (this.state.filteredData === '' && !this.state.sortedPlayerList) {
				switch (this.state.genderTab) {
					case 'Ladies':
						playerListArr = this.state.ladiesFiltered;
						break;
					case 'Gentlemen':
						playerListArr = this.state.gentlemenFiltered;
						break;
					case 'favourites':
						playerListArr = this.state.favouritesFiltered;
						break;
					case 'All':
					default:
						playerListArr = this.state.playersList;
						break;
				}
			} else {
				playerListArr = this.state.filteredData === '' ? this.state.sortedPlayerList : this.state.filteredData;
			}

			const showLoader =
				!this.loadedJson &&
				!this.state.error &&
				!this.state.playersList.length &&
				!this.state.ladiesFiltered &&
				!this.state.gentlemenFiltered &&
				!this.state.favouritesFiltered;

			return (
				<div className={`two-col margin favourting-comp ${showLoader ? 'favourting-comp-loader' : ''}`}>
					<span className="favourting-comp-text" style={{ display: this.props.isPromo ? 'auto' : 'none' }}>
						Choose your favourite players to follow their journey and receive player alerts.
					</span>
					{showLoader ? (
						<div style={{ background: 'white' }}>
							<LoadingIndicator />
						</div>
					) : (
						<div>
							<div className="favourting-star-container">
								{/* search box input */}
								<div className="search-box search-box-container oop">
									<div className="search-wrapper">
										<i className="wim-icon-search" />
										{this.state.searchValueInput && this.state.searchValueInput.length > 0 && (
											<i
												className="wim-icon-close"
												onClick={() =>
													this.setState({ searchValueInput: '', filteredData: '' })
												}
											/>
										)}
										<input
											type="text"
											value={this.state.searchValueInput}
											onChange={this.onSearchInputChange.bind(this)}
											placeholder="Search for a Player"
										/>

										{this.state.value &&
											this.state.value.length > 0 &&
											this.state.noSuggestions &&
											this.state.noSuggFocus &&
											!this.state.found && (
												<div className="autosuggest-not-found">Player not found</div>
											)}
									</div>
								</div>
							</div>

							{/* tab navigation (all, ladies, gentlemen) */}
							<div className="page-nav-container-fav">
								<div className={`nav-container favourting-nav`} style={{ borderBottom: 'none' }}>
									<ul className="favourting-page-nav-list" role="list">
										{['All', 'Ladies', 'Gentlemen'].map((d, i) => {
											return (
												<React.Fragment key={i}>
													<li
														className={
															'page-nav-items' +
															(d === this.state.genderTab ? ' active' : '')
														}
														onClick={() => this.onTabSelected(d)}
														alt={d}
														tabIndex={0}
														role="listitem">
														{d}
													</li>
													<li className="divider"></li>
												</React.Fragment>
											);
										})}
										<li
											className={
												'page-nav-items' +
												('favourites' === this.state.genderTab ? ' active' : '')
											}
											onClick={() => this.onTabSelected('favourites')}
											alt="favourites star item"
											tabIndex={0}
											role="listitem">
											<div>
												<span className="favorites-toggle" />
											</div>
										</li>
									</ul>
								</div>
							</div>

							{/* filter navigation (seeds, countries, A-Z) */}
							<div className="favourting-tab-container" style={{}}>
								{subCategoryTabArr.map((tab, index) => {
									return (
										<span
											key={index}
											onClick={() => this.onTabSelected(null, tab)}
											className={`generic-button_wrapper favourting-tab ${
												this.state.subCategoryTab === tab ? 'favourting-tab-clicked' : ''
											}`}>
											{tab}
										</span>
									);
								})}
							</div>
							<div className="favourting-clear-cntr">
								{this.state.genderTab === 'favourites' && this.state.favouritesFiltered?.length > 0 ? (
									<Button
										className="clear-all-favourites-btn"
										onClick={() => this.onClearAllFavourites()}>
										clear all favourites
									</Button>
								) : null}
							</div>
							{/* player list */}
							<div
								className={`favourting-players-list ${
									this.state.genderTab === 'favourites'
										? 'favourting-players-list-height-large'
										: 'favourting-players-list-height-small'
								} ${
									this.state.subCategoryTab === 'A-Z' || this.state.subCategoryTab === 'Countries'
										? 'favourting-players-list-hide-scroll'
										: ''
								}`}
								id={`favourting-players-list${this.props.isPromo}`}>
								{this.state.filteredData !== '' && !this.state.filteredData.length ? (
									<span style={{ width: '100%', paddingTop: '16px' }}>No players were found</span>
								) : null}
								{this.state.subCategoryTab === 'A-Z' &&
								(this.state.filteredData === '' || this.state.filteredData.length) ? ( // only show if there are players found
									this.state.genderTab === 'favourites' &&
									(!this.state.favouritesFiltered || this.state.favouritesFiltered?.length === 0) ? (
										<div className="no-favourites-txt">
											Add your favourite players to follow by clicking on the star next to the
											player
										</div>
									) : (
										<div className="favourting-players-list-cntr">
											<ul
												className={`alpha_sidebar ${
													this.state.genderTab === 'favourites'
														? 'favourting-players-list-height-large'
														: 'favourting-players-list-height-small'
												}`}>
												{letterList.map((letter, index) => {
													const isDisabled =
														!playerListArr?.some(
															player => player?.last_name?.charAt(0) === letter
														) && this.state.subCategoryTab === 'A-Z';
													return (
														<li key={index}>
															<button
																disabled={isDisabled}
																onClick={() => this.scrollToListLetter(letter)}
																className={
																	isDisabled ? 'alphabet-list-button-disabled' : ''
																}>
																{letter}
															</button>
														</li>
													);
												})}
											</ul>
											{letterList.map(letter => (
												<div
													id={`favourting-letter-section${letter}`}
													className="letter-section">
													<FavourtingPlayersList
														players={playerListArr}
														letter={letter}
														playerProfileImagePath={this.props.playerProfileImagePath}
														flagImagePathSmall={this.props.flagImagePathSmall}
														subCategoryTab={this.state.subCategoryTab}
														filter={this.state.genderTab}
														favouritedPlayers={this.props.favourites.players}
														filterVal="gender"
														pageNav={this.props.pageNav}
													/>
												</div>
											))}
										</div>
									)
								) : this.state.subCategoryTab === 'Countries' ? (
									this.state.genderTab === 'favourites' &&
									(!this.state.favouritesFiltered || this.state.favouritesFiltered?.length === 0) ? (
										<div className="no-favourites-txt">
											Add your favourite players to follow by clicking on the star next to the
											player
										</div>
									) : (
										<div className="favourting-players-list-cntr">
											<ul
												className={`alpha_sidebar ${
													this.state.genderTab === 'favourites'
														? 'favourting-players-list-height-large'
														: 'favourting-players-list-height-small'
												}`}>
												{letterList.map((letter, index) => {
													const isDisabled =
														!this.state.countries?.some(
															country => country?.charAt(0) === letter
														) && this.state.subCategoryTab === 'Countries';
													return (
														<li key={index}>
															<button
																id={`alphabet-button-letter${index}`}
																className={
																	isDisabled ? 'alphabet-list-button-disabled' : ''
																}
																disabled={isDisabled}
																onClick={() => this.scrollToListLetter(letter, true)}>
																{letter}
															</button>
														</li>
													);
												})}
											</ul>
											{this.state.countries?.map(country => (
												<div
													ref={country}
													id={`favourting-letter-section${country}`}
													className="country-section">
													<div className="column">
														<FavourtingPlayersList
															players={playerListArr}
															country={country}
															playerProfileImagePath={this.props.playerProfileImagePath}
															flagImagePathSmall={this.props.flagImagePathSmall}
															subCategoryTab={this.state.subCategoryTab}
															filter={this.state.genderTab}
															favouritedPlayers={this.props.favourites.players}
															filterVal="country"
															countryObj={this.state.countryObj}
															pageNav={this.props.pageNav}
														/>
													</div>
												</div>
											))}
										</div>
									)
								) : (
									<ul role="list" className="favourites-tab-ul">
										{this.state.genderTab === 'favourites' &&
										(!this.state.favouritesFiltered ||
											this.state.favouritesFiltered?.length === 0) ? (
											<div className="no-favourites-txt">
												Add your favourite players to follow by clicking on the star next to the
												player
											</div>
										) : (
											playerListArr.map(player => {
												return this.props.pageNav ? (
													<li className="favourting-list-item">
														<Link to={`/en_GB/players/overview/${player.id}.html`}>
															<PlayerImage
																attributes={{
																	'player-image': this.props.playerProfileImagePath.replace(
																		'<playerid>',
																		player.id
																	),
																	style: 'regular',
																}}
															/>
															<div
																className="country-flag"
																style={{
																	width:
																		this.props.flagImagePathSmall &&
																		!hiddenCountry?.includes(player?.country)
																			? 'auto'
																			: '45px',
																	padding:
																		this.props.flagImagePathSmall &&
																		!hiddenCountry?.includes(player?.country)
																			? 'auto'
																			: '0px',
																}}>
																<img
																	alt={player?.country}
																	src={`${this.props.flagImagePathSmall?.replace(
																		'<code>',
																		player?.country
																	)}`}
																	loading="lazy"
																/>
															</div>
															<span>
																{player.last_name}, {player.first_name}{' '}
																{this.state.subCategoryTab === 'Seeds' && player.seed
																	? `[${player?.seedValue}]`
																	: this.state.subCategoryTab === 'Ranking'
																	? `(${player.singles_rank})`
																	: ''}
															</span>
														</Link>
														<FavouriteStar
															id={player.id}
															type="players"
															unFavColor="black"
															favColor="green"
															alwaysShow={true}
														/>
													</li>
												) : (
													<li className="favourting-list-item">
														<PlayerImage
															attributes={{
																'player-image': this.props.playerProfileImagePath.replace(
																	'<playerid>',
																	player.id
																),
																style: 'regular',
															}}
														/>
														<div
															className="country-flag"
															style={{
																width:
																	this.props.flagImagePathSmall &&
																	!hiddenCountry?.includes(player?.country)
																		? 'auto'
																		: '45px',
																padding:
																	this.props.flagImagePathSmall &&
																	!hiddenCountry?.includes(player?.country)
																		? 'auto'
																		: '0px',
															}}>
															<img
																alt={player?.country}
																src={`${this.props.flagImagePathSmall?.replace(
																	'<code>',
																	player?.country
																)}`}
																loading="lazy"
															/>
														</div>
														<span>
															{player.last_name}, {player.first_name}{' '}
															{this.state.subCategoryTab === 'Seeds' && player.seed
																? `[${player?.seedValue}]`
																: this.state.subCategoryTab === 'Ranking'
																? `(${player.singles_rank})`
																: ''}
														</span>
														<FavouriteStar
															id={player.id}
															type="players"
															unFavColor="black"
															favColor="green"
															alwaysShow={true}
														/>
													</li>
												);
											})
										)}
									</ul>
								)}
							</div>
						</div>
					)}
				</div>
			);
		}
	}
}

const mapStateToProps = (state, props) => ({
	playersPath: op.get(state['Config'].scoringData, 'players', null),
	flagImagePathSmall: op.get(state['Config'].otherData, 'flagImagePathSmall', null),
	playerProfileImagePath: op.get(state['Config'].otherData, 'playerProfileImagePath', null),
	favourites: state['Controller']['favourites'],
	configVal: state['Config'],
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	clearPlayers: data => dispatch(deps.actions.Controller.clearPlayers(data)),
});

// export default Favourting;
export default connect(mapStateToProps, mapDispatchToProps)(Favourting);

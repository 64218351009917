/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetch } from 'appdir/components/general/Util';

import InsightsIndex from '../../pages/InsightsIndex';

const MatchInsightsIndexWebview = props => {
	const [currentDayWebview, setState] = useState();
	const activeDataPath = useSelector(state => state['Config']['otherData']?.activeData?.path);

	useEffect(() => {
		if (activeDataPath) {
			fetch(activeDataPath).then(resp => {
				setState(resp?.currentDay);
			});
		}
	}, [activeDataPath]);

	let data = {
		...props,
		currentDayWebview,
	};
	logger.log('[MatchInsightsIndexWebview] render - state:%o, props:%o, newdata:%o', currentDayWebview, props, data);

	return <InsightsIndex {...data} />;
};

export default MatchInsightsIndexWebview;

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import fetchJsonp from 'fetch-jsonp';
import { getJson } from 'appdir/components/general/Util/Services';

const fetchGeoJsonp = path => {
	// logger.log('[Sidepanel] path:%o', path);
	return fetchJsonp(path)
		.then(function(response) {
			return response.json();
		})
		.then(function(json) {
			//logger.log('[Sidepanel] - done:%o', json)
			return json;
		})
		.catch(function(ex) {
			//logger.warn('[Sidepanel] - fail')
			throw new Error();
		});
};

const fetchGeoJs = path => {
	return fetchJsonp(path[0], { jsonpCallbackFunction: path[1] })
		.then(function(response) {
			//logger.log('[Sidepanel] service.fetchGeojs - cb:%o json:%o ok:%o', path[1], response, response.ok)
			return response.json();
		})
		.then(function(json) {
			//logger.log('[Sidepanel] service.fetchGeoJs - done:%o', json)
			return json;
		})
		.catch(function(ex) {
			//logger.warn('[Sidepanel] service.fetchGeoJs - fail')
			throw new Error();
		});
};

const fetchGeoJson = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			logger.warn('[Sidepanel] fetchGeoJson - %o', error.message);
			throw new Error(error.message);
		});
};

const fetch = path => {
	return new Promise((resolve, reject) => {
		getJson(path)
			.then(result => {
				resolve(result);
			})
			.catch(error => {
				reject(error);
			});
	});
};

export default {
	fetchGeoJson,
	fetchGeoJsonp,
	fetchGeoJs,
	fetch,
};

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const getPlayerInfo = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

export default {
	getPlayerInfo,
};

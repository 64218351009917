import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import { getPlatformImageSize } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import EventsHelmet from 'appdir/components/general/EventsHelmet';
import Carousel from 'appdir/components/common-ui/Carousel';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import parse from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * React Component: Gallery Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['GalleryPage'],
		prevLocation: state['Router']['prevLocation'],
		platform: state['Controller']['platform'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.GalleryPage.mount()),
	hidePanel: showFlag => dispatch(deps.actions.Sidepanel.hidePanel(showFlag)),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
	unmount: () => dispatch(deps.actions.GalleryPage.unmount()),
});

class GalleryPage extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props, currentIndex: 0, prevURL: false };
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	componentWillUnmount() {
		this.props.hidePanel(true);
		this.props.unmount();
	}

	componentDidMount() {}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.galleryAPIPath && !this.state.galleryData) {
			let date = this.state.match.params.date;
			let galleryId =
				this.state.match.params.id || this.state.match.params.photoid || this.state.match.params.playerid;
			let galleryPath = this.state.galleryAPIPath
				.replace('<date>', date)
				.replace('<id>', galleryId)
				.replace('<playerId>', galleryId);
			deps.services.GalleryPage.fetchImages(galleryPath).then(data => {
				logger.info('[GalleryPage] URL used', galleryPath);
				logger.info('[GalleryPage] retrieved data', data);
				data = data ? data : {};
				if (!this.state.match.params.playerid) {
					this.setViewedContent(parseInt(data.displayDate), data.contentId);
				}

				this.setState(prevState => {
					return { ...prevState, galleryData: data, prevURL: this.state.prevLocation !== '' ? true : false };
				});

				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					content: data,
				});
			});
		}
	}

	constructItems() {
		let imageSize = getPlatformImageSize(this.state.platform);
		let itemContent = [];
		if (this.state.galleryData) {
			if (this.state.galleryData.content) {
				itemContent = this.state.galleryData.content.map((d, i) => {
					return {
						src: d.images ? d.images[0][imageSize] : d.image,
						title: d.title,
						credit: d.images[0]['credit'],
						description: d.description,
						id: d.contentId,
						thumb: d.images ? d.images[0]['small'] : d.thumb,
					};
				});
			} else {
				let galleryData = this.state.galleryData;
				itemContent.push({
					src: galleryData.images[0][imageSize],
					title: galleryData.title,
					credit: galleryData.images[0]['credit'],
					description: galleryData.description,
					id: galleryData.contentId,
					thumb: galleryData.images[0]['small'],
				});
			}
		}

		return itemContent.map(d => {
			return { renderItem: this.renderPlayItems.bind(this, d), thumbnail: d.thumb };
		});
	}

	_onSlide(index) {
		this.setState(prevState => {
			return { ...prevState, currentIndex: index };
		});

		let props = {
			prop16: this.state.galleryData.content[index].title,
			linkTrackVars: 'prop4,prop35,prop16',
		};

		MeasurementUtils.dispatchMeasurementCall('galleryNavigate', { content: { title: document.title } });
	}

	goBack() {
		this.props.history.goBack();
	}

	renderPlayItems(data) {
		return (
			<div className="slider-content">
				<div className="slider-title">
					{/*ReactHtmlParser*/ parse(data.title)}{' '}
					<span className="gallery-credit">{/*ReactHtmlParser*/ parse(data.credit)}</span>
				</div>
				<img src={data.src} />
			</div>
		);
	}

	setViewedContent(timestamp, contentId) {
		logger.log('[GalleryPage] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	render() {
		logger.log('[GalleryPage] render - state:%o ', this.state);
		let contentImageURL;
		//let header_propsData;
		let pageTitle, pageDescription, pageDate, contentItems, formattedShareUrl;

		if (this.state.galleryData) {
			if (Object.keys(this.state.galleryData).length > 0) {
				contentItems = this.constructItems();
				if (this.state.galleryData.content) {
					if (this.state.galleryData.content.length > 0) {
						contentImageURL = this.state.galleryData.content[0]['images'][0][
							getPlatformImageSize(this.state.platform)
						];
						formattedShareUrl =
							this.state.galleryData.content[this.state.currentIndex].shareUrl !== ''
								? this.state.galleryData.content[this.state.currentIndex].shareUrl
								: this.state.galleryData.content[this.state.currentIndex].images[0].share;
						pageTitle = this.state.galleryData.title;
						pageDescription = this.state.galleryData.title;
						//not sure why using first image display date here but it seems wrong, switch to use gallery displaydate
						//pageDate = this.state.galleryData.content[0].displayDate;
						pageDate = this.state.galleryData.displayDate;
					}
				} else {
					contentImageURL = this.state.galleryData.images[0][getPlatformImageSize(this.state.platform)];
					formattedShareUrl = this.state.galleryData.shareUrl;
					pageTitle = this.state.galleryData.title;
					pageDescription = this.state.galleryData.title;
					pageDate = this.state.galleryData.displayDate;
				}
				if (contentImageURL) {
					contentImageURL =
						contentImageURL.indexOf('http') !== -1 || contentImageURL.indexOf('www.wimbledon.com') !== -1
							? contentImageURL
							: `${window.location.protocol}//${window.location.hostname}${contentImageURL}`;
					formattedShareUrl =
						formattedShareUrl.indexOf('http') !== -1 ||
						formattedShareUrl.indexOf('www.wimbledon.com') !== -1
							? formattedShareUrl
							: `${window.location.protocol}//${window.location.hostname}${formattedShareUrl}`;
				}
				logger.log('[GalleryPage] render - formattedShareUrl:%o ', formattedShareUrl);
			}
		}

		let evHelmetAttr = {
			pageTitle: pageTitle + ' - ' + values.pageTitle,
			description: pageDescription,
			keywords: '',
			canonical: values.canonicalHost + window.location.pathname,
			amp: undefined,
			type: 'P',
			contentTitle: pageTitle,
			contentDate: pageDate,
			contentPlayers: undefined,
			measureTitle: 'Detail',
			shareImage: contentImageURL ? contentImageURL : undefined,
			link: formattedShareUrl,
		};

		return this.state.galleryData && Object.keys(this.state.galleryData).length > 0 ? (
			<span>
				<EventsHelmet attributes={evHelmetAttr} shareHost={window.location.origin} />

				<div className={`Gallery-page`}>
					{this.state.galleryData.content ? (
						this.state.galleryData.content.length > 0 ? (
							this.state.galleryData.content[this.state.currentIndex].title !== '' ? (
								<div className="slider-title-mobile">
									{decodeURIComponent(this.state.galleryData.content[this.state.currentIndex].title)}
									<span className="gallery-credit-mobile">
										{this.state.galleryData.content[this.state.currentIndex].images[0].credit}
									</span>
								</div>
							) : null
						) : null
					) : this.state.galleryData.title !== '' ? (
						<div className="slider-title-mobile">
							{this.state.galleryData.title}
							<span className="gallery-credit-mobile">{this.state.galleryData['images'][0].credit}</span>
						</div>
					) : null}
					{!this.state.prevURL ? (
						<WimLink to={`/`}>
							<i className="wim-icon-close" />
						</WimLink>
					) : (
						<i
							onClick={() => {
								this.goBack();
							}}
							className="wim-icon-close"
						/>
					)}
					{this.state.galleryData.content ? (
						this.state.galleryData.content.length > 0 ? (
							<Carousel
								key={'Gallery-page-Gallery'}
								attributes={{
									items: contentItems,
									showNav: true,
									showFullscreenButton: false,
									showThumbnails: true,
									showPlayButton: false,
									customNav: false,
									onSlide: this._onSlide.bind(this),
								}}
							/>
						) : (
							<div className="no-content-gallery">No content is available</div>
						)
					) : (
						<Carousel
							key={'Gallery-page-Gallery'}
							attributes={{
								items: contentItems,
								showNav: true,
								showFullscreenButton: false,
								showThumbnails: true,
								showPlayButton: false,
								customNav: false,
								onSlide: this._onSlide.bind(this),
							}}
						/>
					)}

					{this.state.galleryData.content ? (
						this.state.galleryData.content.length > 0 ? (
							<ShareMenu
								type="gallery"
								onClick={this.onShareClick}
								url={formattedShareUrl}
								color={'white'}
							/>
						) : null
					) : (
						<ShareMenu
							type="gallery"
							onClick={this.onShareClick}
							url={this.state.galleryData.shareUrl}
							color={'white'}
						/>
					)}
				</div>
			</span>
		) : (
			<span>
				<div className={`Gallery-page`}>
					<WimLink to={`/`}>
						<i className="wim-icon-close" />
					</WimLink>
					<div className="no-content-gallery">No content is available</div>
				</div>
			</span>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryPage);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Time from 'appdir/components/common-ui/Time';
import MIInfoIcon from './MIInfoIcon';

/**
 * -----------------------------------------------------------------------------
 * React Component: Section Header for Match Insights
 *
 *  props = {
 *             title,
 *             style, ---------- additional className
 *             onInfoClickFn, -- callback method to open a modal
 *             infoIcon, ------ boolean show/hide (i)
 *             lastUpdate, ----- time
 * 			   lastUpdatePos --- 'right' to align last update text
 * 			   onTitleClickFn -- callback method to toggle
 *           }
 * -----------------------------------------------------------------------------
 */
const MISectionHeader = props => {

	let klass = "mi-section-title ";
	klass += props?.onTitleClickFn ? "close " : "";

	return (
		<div className={`mi-section-header ${props?.style ? props.style : ''}`} ref={props?.refProp}>
			{
				props.title && !props.hideTitle ? (
					<h4 className={klass} onClick={props?.onTitleClickFn ? () => props.onTitleClickFn() : null}>{props.title}</h4>
				) : null
			}
			
			{props.infoIcon ? <MIInfoIcon onInfoClickFn={props.onInfoClickFn} /> : null}
			<div className={`mi-last-updated ${props.lastUpdatePos ? props.lastUpdatePos : ''}`}>
				{props.lastUpdate && props.lastUpdate != '' ? (
					<>
						{/* Last Update: <Time epoch_ms={props.lastUpdate} format="ddd hh:mm z" options="upper" /> */}
						Updated: <Time epoch_ms={props.lastUpdate} format="D MMM HH:mm z" options="upper" />
					</>
				) : null}
			</div>
		</div>
	);
};

export default MISectionHeader;

import DrawsArchiveEvents from './index';
import deps from 'dependencies';

export default {
	path: '/:lang/draws_archive/events.html',
	exact: true,
	component: DrawsArchiveEvents,
	section: 'Draws',
	load: params => deps.actions.DrawsArchiveEvents.mount(params),
};

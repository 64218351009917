import deps from 'dependencies';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[RoleRegistrationInvite] action.mount - params:%o store:%o', params, Config);

			let roles = op.get(Config, 'myWimbledon.rolesComplete', {});
			let inviteRoles = {};
			roles.forEach(role => {
				if (role.invite) {
					inviteRoles[`${role.key}`] = role.id;
					if (role.key == 'player_delegate') {
						inviteRoles[`delegate`] = role.id;
					}
				}
			});
			logger.log('[RoleRegistrationInvite] action.mount - config2 roles:%o', inviteRoles);

			let data = {
				registration: {
					urlrole: params.role,
					guid: params.guid,
					roles: inviteRoles,
				},
			};
			logger.log('[RoleRegistrationInvite] action.mount - data:%o', data);

			dispatch({
				type: deps.actionTypes.ROLEREGISTRATIONINVITE_MOUNT,
				data: data,
			});
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.ROLEREGISTRATIONINVITE_UNMOUNT });
	},
};

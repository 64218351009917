import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';

class StorytellerPromo extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

    handleBeforeUnload = () => {
        // dont show again if user views then refreshes page
		if (this.props.onUnmount) {
			this.props.onUnmount();
		}
	};

	render() {
		return (
            <div className="promos-image-container">
                storyteller
                {/* <img src={`${catchMeUpImagePath}${imgPathToUse[currentIndex]}`} alt="Catch Me Up Promo" className="carousel-image" /> */}
            </div>
        );
	}
}

export default StorytellerPromo;

module.exports = {
	version: '2.3.7',
	template: req => {
		let webviewReg = /\/webview\//;
		if (webviewReg.test(req.originalUrl)) {
			//console.log('feo.js - test:%o req:%o orig:%o', webviewReg.test(req.originalUrl), req.url, req.originalUrl);
			return `<!DOCTYPE html>
				<html>
					<head>
						<meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0" />
						<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
						${req.styles}
						<script src="/assets/static/analytics/webview.js"></script>
					</head>
					<body class="webview">
						<Component type="data/Config"></Component>
						<Component type="general/Controller"></Component>
						<Component type="general/ScoreManager"></Component>
						<div id="router"></div>
						
						<script>
							//src: feo.js
							window.version = "local";
							window.commit = "local";
							window.ssr = false;
							window.restAPI = '/api';
							window.parseAppId = '${parseAppId}';
							window.webview = true;
							window.webviewPlatform  = navigator.userAgent.indexOf('IBM_Events_Android_APK') > -1 ? 'android' : 'ios';
						</script>
						${req.scripts}
						
					</body>
				</html>`;
		} else {
			return `<!DOCTYPE html>
				<html>
					<head>
						<meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0" />
						<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
						<link rel="apple-touch-icon-precomposed" sizes="57x57" href="/assets/images/misc/apple-touch-icon-57x57.png">
						<link rel="apple-touch-icon-precomposed" sizes="114x114" href="/assets/images/misc/apple-touch-icon-114x114.png">
						<link rel="apple-touch-icon-precomposed" sizes="72x72" href="/assets/images/misc/apple-touch-icon-72x72.png">
						<link rel="apple-touch-icon-precomposed" sizes="144x144" href="/assets/images/misc/apple-touch-icon-144x144.png">
						<link rel="apple-touch-icon-precomposed" sizes="60x60" href="/assets/images/misc/apple-touch-icon-60x60.png">
						<link rel="apple-touch-icon-precomposed" sizes="120x120" href="/assets/images/misc/apple-touch-icon-120x120.png">
						<link rel="apple-touch-icon-precomposed" sizes="76x76" href="/assets/images/misc/apple-touch-icon-76x76.png">
						<link rel="apple-touch-icon-precomposed" sizes="152x152" href="/assets/images/misc/apple-touch-icon-152x152.png">
						<link rel="icon" type="image/png" href="/assets/images/misc/favicon-196x196.png" sizes="196x196">
						<link rel="icon" type="image/png" href="/assets/images/misc/favicon-96x96.png" sizes="96x96">
						<link rel="icon" type="image/png" href="/assets/images/misc/favicon-32x32.png" sizes="32x32">
						<link rel="icon" type="image/png" href="/assets/images/misc/favicon-16x16.png" sizes="16x16">
						<link rel="icon" type="image/png" href="/assets/images/misc/favicon-128.png" sizes="128x128">
						<meta name="application-name" content="&nbsp;">
						<meta name="msapplication-TileColor" content="#FFFFFF">
						<meta name="msapplication-TileImage" content="/assets/images/misc/mstile-144x144.png">
						<meta name="msapplication-square70x70logo" content="/assets/images/misc/mstile-70x70.png">
						<meta name="msapplication-square150x150logo" content="/assets/images/misc/mstile-150x150.png">
						<meta name="msapplication-wide310x150logo" content="/assets/images/misc/mstile-310x150.png">
						<meta name="msapplication-square310x310logo" content="/assets/images/misc/mstile-310x310.png">
						<link rel="icon" type="image/icon" href="/favicon.ico">
						${req.styles}
						<script src="//assets.adobedtm.com/launch-EN8426273fcfbe4df48d3bfc11581a5d75-development.min.js" async></script>
						<script src="/en_GB/static/cookie/checkCookie.js"></script>
					</head>
					<body class="local-screens">
						<div class="site-wrap">
							<Component type="DevTools"></Component>
							<Component type="data/Config"></Component>
							<Component type="data/ActiveData"></Component>
							<Component type="data/Tournament"></Component>
							<Component type="general/Controller"></Component>
							<Component type="general/PrivacyBanner"></Component>
							<Component type="general/Gigya"></Component>
							<Component type="general/ScoreManager"></Component>
							<Component type="general/ConfirmationModal"></Component>
							<div id="router"></div>
							<Component type="general/Sidepanel"></Component>
							<Component type="general/SlamtrackerPanel"></Component>
						</div>

						<script>
							//src: feo.js
							window.version = "local";
							window.commit = "local";
							window.ssr = false;
							window.restAPI = '/api';
							window.parseAppId = '${parseAppId}';
						</script>
						${req.scripts}
						
					</body>
				</html>`;
		}
	},
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.HEADER_MOUNT:
			newState = {
				...state,
				title: '',
				...action.data,
			};
			// logger.log('[Header] HEADER_MOUNT - state:%o',newState)
			return newState;
			break;

		case deps.actionTypes.VIDEOPLAYER_FULLWINDOW:
			let type = action.data.type;
			let className = action.data.className;

			newState = {
				...state,
				fullwindowVideo: className,
				type,
			};
			//logger.log('[HEADER]deps.actionTypes.VIDEOPLAYER_FULLWINDOW - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

/* stage item components */
import DepthOfReturn from 'appdir/components/pages/Slamtracker/elements/stage/DepthOfReturn';
import ServePlacement from 'appdir/components/pages/Slamtracker/elements/stage/ServePlacement';
import ServeSpeed from 'appdir/components/pages/Slamtracker/elements/stage/ServeSpeed';

const StageItems = props => {
	const slamtrackerData = useSelector(state => state?.['Config']?.slamtracker ?? {});
  const otherData = useSelector(state => state?.['Config']?.otherData ?? {});
	const dispatch = useDispatch();

	let { data } = props;
	// let { gameObj } = data;
	// let { pointObj } = data;
	let { statMatch } = data;
	let { pointsById } = data;

	let point = data.pointObj;
	let pointNumber = point.PointNumber;
	let setNo = point.SetNo;
	let gameNo = point.GameNo;
	let pointHistoryArray = data.pointHistoryArray;
	let team1 = data.team1;
	let team2 = data.team2;
	let isMatchComplete = data.isMatchComplete;

	// logger.log('[StageItems] props:%o, props:%o', props);

	const renderPointScore = pointObj => {
		return (
			<div className="pointScore point-info-item">
				<div>
					{pointObj.P1Score == '0' && pointObj.P2Score == '0'
						? 'FINISH'
						: pointObj.PointServer == '1' || pointObj.PointServer == '3'
							? `${pointObj.P1Score}-${pointObj.P2Score}`
							: pointObj.PointServer == '2' || pointObj.PointServer == '4'
								? `${pointObj.P1Score}-${pointObj.P2Score}`
								: ''}
				</div>
			</div>
		);
	};

	const renderPointSentence = pointObj => {
		return (
			<div className="pointSentence point-info-item">
				<div>{pointObj.Sentence} </div>
			</div>
		);
	};

  const renderStageItem = (data) => {
    switch (data.stageItem) {
      case 'ret':
        return (
          <ErrorBoundary>
            <DepthOfReturn data={data} stage_location="slamtracker" />
          </ErrorBoundary>
        );
        
      case 'trk':
        return (
          <ErrorBoundary>
            <ServePlacement data={data} stage_location="slamtracker" />
          </ErrorBoundary>
        );

      case 'asp':
        return (
          <ErrorBoundary>
            <ServeSpeed data={data} stage_location="slamtracker" />
          </ErrorBoundary>
        );

      default:
        return null;
    }
  }

	const getStageItemData = (pointObj) => {    
		if (pointObj) {
				let stage_item_data = '';
				let currentPoint = pointsById[pointNumber];
				let stageItemList = slamtrackerData?.stageItems;
				if (stageItemList.includes(pointObj.Stage)) {
					switch (pointObj.Stage) {
						// Depth of Return
						case 'ret':
							stage_item_data = {
								selector: 'stage-' + pointNumber,
								animate: true,
								set: currentPoint.SetNo,
								match: statMatch.match_id,
								point: pointNumber,
								pointHistory: pointsById,
                stageItem: pointObj.Stage,
								team: {
									number: currentPoint.PointWinner,
									name: statMatch[`team${parseInt(currentPoint.PointWinner)}`].displayNameA,
									tourId: statMatch[`team${parseInt(currentPoint.PointWinner)}`].idA,
								},
							};
							break;

						// Serve Placement
						case 'trk':
							stage_item_data = {
								selector: 'stage-' + pointNumber,
								animate: true,
								set: currentPoint.SetNo,
								match: statMatch.match_id,
								point: pointNumber,
								pointData: currentPoint,
								pointHistory: pointsById,
                stageItem: pointObj.Stage,
								team: {
									number: currentPoint.PointWinner,
									name: statMatch[`team${parseInt(currentPoint.PointWinner)}`].displayNameA,
									tourId: statMatch[`team${parseInt(currentPoint.PointWinner)}`].idA,
								},
							};
							break;

						// Serve Speed
						case 'asp':
							stage_item_data = {
								selector: '#stage-' + pointNumber,
								animate: true,
								set: currentPoint.SetNo,
								match: statMatch.match_id,
								point: pointNumber,
								doubles: statMatch.team1.idB == null ? false : true,
								pointData: currentPoint,
								stageItem: pointObj.Stage,
								pointHistory: pointsById,
                currentServer: statMatch.server,

								image_url: otherData.playerProfileImagePath,
								teams: [
									{
										players: [
											{
												name: statMatch.team1.displayNameA,
												tourId: statMatch.team1.idA,
											},
											{
												name: statMatch.team1.displayNameB,
												tourId: statMatch.team1.idB,
											},
										],
									},
									{
										players: [
											{
												name: statMatch.team2.displayNameA,
												tourId: statMatch.team2.idA,
											},
											{
												name: statMatch.team2.displayNameB,
												tourId: statMatch.team2.idB,
											},
										],
									},
								],
							};
							break;

						default:
							break;
					}
				} 

        if (stage_item_data != '') {
          return renderStageItem(stage_item_data);
        }else{
          stage_item_data = 'stage data is not available'
        }

		} else {
			return null;
		}
	};
  
	if (data) {
		return (
			<div className="stage-container">
        <div className={`point-commentary ${!point.GameWinner || !point.MatchWinner ? 'in-progress' : 'finished'}`}>
				  {renderPointScore(point)}
          {renderPointSentence(point)}
        </div>
        <div className=''></div>
        {getStageItemData(point)}
			</div>
		);
	} else {
		return null;
	}
};

export default StageItems;

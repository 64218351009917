import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export const getRoundList = data => {
	let rounds = [];

	for (var i = 0; i < data.length; i++) {
		rounds.push(data[i].roundCode);
	}

	return rounds;
};

export const sortMatchData = matches => {
	matches = matches.sort((a, b) => {
		if (a.available > b.available) {
			return 1;
		} else if (a.available < b.available) {
			return -1;
		} else {
			return 0;
		}
	});

	let sortedMatchData = {
		tmp_matches_r1: [],
		tmp_matches_r2: [],
		tmp_matches_r3: [],
		tmp_matches_r4: [],
		tmp_matches_rQ: [],
		tmp_matches_rS: [],
		tmp_matches_rF: [],
	};

	for (var i = 0; i < matches.length; i++) {
		let arr = eval(`sortedMatchData.tmp_matches_r${matches[i].round}`);
		arr.push(matches[i]);
	}

	// logger.log('sortedMatchData:%o', sortedMatchData);

	return sortedMatchData;
};

export const isWithinHours = (date, diff) => {
	// logger.log('isWithinLast24Hours date:%o', date);
	let isNewMatch = false;

	let start = moment(date).tz('Europe/London');
	let end = moment().tz('Europe/London');
	let difference = end.diff(start, 'hours');

	if (difference >= 0 && difference < diff) {
		isNewMatch = true;
	}

	logger.log(
		'[isWithinHours] date:%o, start:%o, end:%o, difference:%o',
		date,
		start.toString(),
		end.toString(),
		difference
	);

	return isNewMatch;
};

import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: data => (dispatch, getState, store) => {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Wimbledon Channel Live Radio',
		});
		dispatch({ type: deps.actionTypes.LIVERADIO_MOUNT });

		logger.log('[LiveRadio] action.mount - params: %o', data);

		let params = data;

		//logger.log('[RadioIndex] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {};

			dispatch({ type: deps.actionTypes.LIVERADIO_LOAD, data: data });
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVERADIO_UNMOUNT });
	},
};

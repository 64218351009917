/**
 DrawsArchiveYear Event Dropdown
 Created based on selected year
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import SelectMenu from 'appdir/components/common-ui/SelectMenu';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.DrawsArchiveYear.filter(settings)),
});

class EventSelect extends Component {
	constructor(props) {
		super(props);

		let eventsDropdownVals = props.events.map(item => ({ name: item.eventName, value: item.eventId }));

		this.state = {
			...props,
			eventsDropdownVals: eventsDropdownVals,
		};

		this.setEvent = this.setEvent.bind(this);
		this.onOpen = this.onOpen.bind(this);

		logger.log('[EventSelect] constructor props:%o state:%o', props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[EventSelect] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);

		let eventsDropdownVals = nextProps.events.map(item => ({ name: item.eventName, value: item.eventId }));

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				eventsDropdownVals: eventsDropdownVals,
			};
		});

		//logger.log('[EventSelect] componentWillReceiveProps - status:%o state:%o next:%o', nextProps.status, this.state.year, nextProps.year);
	}

	setEvent(event, name) {
		logger.log('[DrawsArchiveYear-EventSelect] setEvent:%o', event);

		MeasurementUtils.dispatchMeasurementCall('drawsArchiveYearPageEventSelect', {
			event,
			name,
		});

		this.props.filter({
			eventId: event,
			open: '',
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	render() {
		logger.log('[DrawsArchiveYear-EventSelect] render - state: %o', this.state);
		if (this.state.eventsAll !== '') {
			let eventsData = {
				options: [],
			};

			// add available events to base archive menu options
			this.state.eventsDropdownVals.forEach(item => {
				eventsData.options.push({ name: item.name, value: item.value });
			});

			return (
				<div className="content-filters">
					<div className="news-landing-time-selector">
						<SelectMenu
							name="events"
							attributes={eventsData}
							selected={this.state.filters.eventId}
							show={this.state.filters.open == 'events'}
							onSelected={this.setEvent}
							onOpen={this.onOpen}
						/>
					</div>
				</div>
			);
		} else {
			return <div />;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSelect);

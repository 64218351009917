import React, { Component } from 'react';
import WebViewTicketStubActions from './WebViewTicketStubActions';
import classNames from 'classnames';
import { FILTER_RECEIVED, FILTER_NORMAL } from 'appdir/components/pages/Tickets/Data';
import SingleTicketStub from '../../pages/Tickets/elements/TicketActionViews/SingleTicketStub';
import { courtImageObj } from 'appdir/components/pages/Tickets/elements/consts.js'


class WebViewTicketStub extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
		};
	}

	onTicketStubClick(id) {
		const { currentlySelected, setTicketActionsSelected } = this.props;
		const ticketSelectedVal = id === currentlySelected ? '' : id;

		setTicketActionsSelected(ticketSelectedVal);
	}

	onFormatTypeValue(value) {
		let val = value.split('-');
		return (
			<div>
				<span className="web-ticket-stub-memb-guest">{val[0]}</span>
				<span className="web-ticket-stub-memb-guest msg">{val[1]}</span>
			</div>
		);
	}

	onRenderSingleTicketStub(err) {
		let ticket = this.props.fullTicketValue;
		let imageObjVal;
		if (ticket?.source?.details?.main) {
			imageObjVal = ticket.source.details.main.find(({ key }) => key.toLowerCase() === 'court');
		}

		return (
			<SingleTicketStub
				middleContents={{
					court: ticket.detailedInformation.court,
					type: ticket.typeLabel,
					gangway: ticket.detailedInformation.gangway ? `Gangway ${ticket.detailedInformation.gangway}` : '',
					row: ticket.detailedInformation.row ? `Row ${ticket.detailedInformation.row}` : '',
					seat: ticket.detailedInformation.seat ? `Seat ${ticket.detailedInformation.seat}` : '',
				}}
				imageToRender={
					courtImageObj[imageObjVal ? imageObjVal.value.toLowerCase() : ''] ||
					'https://assets.wimbledon.com/images/ticket/Grounds_328x440.jpg'
				} // have a default image in case no match
				id={ticket.externalId}
				error={err}
				date={ticket?.day !== 'UGP' ? ticket.date : ''}
				isWebview
			/>
		);
	}

	render() {
		const {
			actions,
			ticketActionsSelected,
			id,
			fullTicketValue,
			ticketModalOpen,
			index,
			rightContents,
			middleContents,
			imageToRender,
		} = this.props;

		const { court, gangway, row, seat, status } = middleContents;

		let toDisableSwap = fullTicketValue.disableSwap;

		// if modal is open, minimize zIndex so ticket stubs dont show, otherwise zindex is normal
		const zIndexToRender = ticketModalOpen ? '-10' : '1';

		const ticketStubToRender = clickEnabled => (
			<div
				className={
					clickEnabled && !this.props.disabled
						? 'ticket-stub-container'
						: this.props.disabled
						? 'ticket-stub-container-disabled ticket-stub-container'
						: 'ticket-stub-container-small'
				}
				tabIndex={0}
				alt={`ticket stub, court ${court}, row ${row}, seat ${seat}`}
				onClick={() => (clickEnabled && !this.props.disabled ? this.onTicketStubClick(id) : false)}
				style={{
					cursor: clickEnabled && !this.props.disabled ? 'pointer' : 'not-allowed',
					zIndex: zIndexToRender,
				}}>
				<img
					src="/assets/images/tickets/webviewTicketBorderShape.png"
					aria-hidden={true}
					className="single-ticket-desktop-border-shape"
				/>
				<div
					className={
						clickEnabled
							? classNames('webview-ticket-stub-shape-img-border', 'ticket-stub-shape-container')
							: classNames('ticket-stub-shape-container-small', 'ticket-stub-shape-img-border-small')
					}>
					<div
						className={clickEnabled ? 'ticket-stub-image-container' : 'ticket-stub-image-container-small'}
						tabIndex={-1}
						aria-hidden>
						<img className="ticket-stub-image" src={imageToRender} />
					</div>
					{fullTicketValue.testTicket ? <div className="test-ticket-label-webview">test ticket</div> : null}
					<div
						className={
							clickEnabled ? 'ticket-stub-details-container' : 'ticket-stub-details-container-small'
						}>
						{court ? (
							<div
								className={
									clickEnabled ? 'ticket-stub-details-title' : 'ticket-stub-details-title-small'
								}>
								{court}
							</div>
						) : null}
						{fullTicketValue.label ? (
							<div
								className={
									clickEnabled ? 'ticket-stub-details-type' : 'ticket-stub-details-type-small'
								}>
								{fullTicketValue.message ? (
									<div>
										<span className="web-ticket-stub-memb-guest">{fullTicketValue.label}</span>
										<span className="web-ticket-stub-memb-guest msg">{fullTicketValue.message}</span>
									</div>
								) : fullTicketValue.label}
							</div>
						) : null}
						{gangway || row || seat ? (
							<div
								className={
									clickEnabled ? 'ticket-stub-details-text' : 'ticket-stub-details-text-small'
								}>
								{gangway !== '' ? <span className="ticket-stub-inner-details">{gangway}</span> : null}
								{row !== '' ? <span className="ticket-stub-inner-details">{row}</span> : null}
								{seat !== '' ? <span className="ticket-stub-inner-details">{seat}</span> : null}
							</div>
						) : null}
						{status.value ? (
							<div
								style={{ textTransform: 'none' }}
								className={
									clickEnabled ? 'ticket-stub-details-text' : 'ticket-stub-details-text-small'
								}>
								{`${status.value ? status.value : ''} ${status.detail ? status.detail : ''}`}
							</div>
						) : null}
					</div>
					{clickEnabled && !this.props.disabled ? (
						<div className="ticket-stub-right-side-parent-cntr">
							<div className={'ticket-stub-right-side-container-outer'}>
								<div className="ticket-stub-right-side-container-inner ticket-stub-right-side-container-rotate">
									{rightContents}
								</div>
							</div>
						</div>
					) : null}
				</div>
				{this.props.debugView && clickEnabled ? (
					<div className="ticketview-ticketstub-details-id">
						<div>
							<strong>SpecId:</strong> {fullTicketValue.spectatorId}
						</div>
						<div>
							<strong>ID:</strong> {fullTicketValue.source.id}
						</div>
						<div>
							<strong>CATEGORY:</strong> {fullTicketValue.detailedInformation.category}{' '}
						</div>
						<div>
							<strong>ACTIONS:</strong> {fullTicketValue.actions.join(', ')}
						</div>
					</div>
				) : null}
			</div>
		);

		const containerZindexToRender = ticketModalOpen && ticketActionsSelected !== id ? '-10' : '0';
		return (
			<div
				className="ticket-webview-stub-parent-container"
				style={{ zIndex: containerZindexToRender }}
				key={`${id}${index}-ticket-stub-parent-container`}>
				{ticketStubToRender(true)}
				{ticketActionsSelected === id ? (
					<div
						key={`${id}${index}`}
						className={
							ticketModalOpen
								? 'ticket-stub-actions-component-container-modal-open'
								: 'ticket-stub-actions-component-container'
						}>
						<WebViewTicketStubActions
							actionsList={
								[FILTER_NORMAL, FILTER_RECEIVED].indexOf(fullTicketValue.status.filtervalue) > -1
									? ['show full ticket'].concat(actions)
									: actions
							}
							// fullActionsList={['show full ticket', 'transfer', 'recall', 'return', 'cancel']}
							fullActionsList={['show full ticket', 'transfer', 'recall', 'return', 'swap']}
							fullTicketValue={fullTicketValue}
							onRenderTicketStub={err => this.onRenderSingleTicketStub(err)}
							onActionClicked={() => this.onTicketStubClick(id)}
							onUpdateModalStatus={val => this.props.setTicketModalOpen(val)}
							onRefreshData={() => this.props.onRefreshData()}
							ticketData={this.props.ticketData}
							onSoftRefreshData={() => this.props.onSoftRefreshData()}
							setShowTicketSwap={val => this.props.setShowTicketSwap(val)}
							setTicketValueData={(ticketData, ticketStub) =>
								this.props.setTicketValueData(ticketData, ticketStub)
							}
							toDisableSwap={toDisableSwap}
						/>
					</div>
				) : null}
			</div>
		);
	}
}

export default WebViewTicketStub;

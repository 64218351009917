/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import axios from 'axios';
import { values } from 'appdir/main';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: EventStats
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['HeadToHead'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.HeadToHead.mount()),
});

class HeadToHead extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.data,
			selectedIndex: 0,
		};
		this.fetchHeadTohead = true;
		let playerImagePath;
	}

	componentDidMount() {
		this.props.mount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.info('[HeadToHead] componentWillReceiveProps - props:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate() {
		if (this.state.headToHeadConfigData) {
			this.playerImagePath = this.state.headToHeadConfigData.pathObj.playerImagePath;

			if (this.fetchHeadTohead) {
				this.fetchHeadTohead = false;
				let headToHeadPathArry = [
					axios.get(this.state.headToHeadConfigData.pathObj.eventStats.replace('<id>', this.state.player1)),
					axios.get(this.state.headToHeadConfigData.pathObj.eventStats.replace('<id>', this.state.player2)),
					axios.get(this.state.headToHeadConfigData.pathObj.playerInfo.replace('<id>', this.state.player1)),
					axios.get(this.state.headToHeadConfigData.pathObj.playerInfo.replace('<id>', this.state.player2)),
					axios.get(this.state.headToHeadConfigData.pathObj.players),
				];
				deps.services.HeadToHead.getHeadToHeadData(headToHeadPathArry)
					.then(
						axios.spread((player1Stats, player2Stats, player1Info, player2Info, playersDetails) => {
							this.setState(prevState => {
								return {
									...prevState,
									player1Stats: player1Stats.data,
									player2Stats: player2Stats.data,
									player1Info: player1Info.data,
									player2Info: player2Info.data,
									playersDetails: playersDetails.data,
								};
							});
						})
					)
					.catch(error => {
						logger.error('[HeadToHead] getHeadtoHeadData error:%o', error);
					});
			}
		}
	}

	setSelected(increment) {
		let newIndex = this.state.selectedIndex + increment;
		if (newIndex >= 0 && newIndex <= 6) {
			this.setState(prevState => {
				return {
					...prevState,
					selectedIndex: newIndex,
				};
			});
		}
	}

	render() {
		logger.log('[HeadToHead] render - state:%o', this.state);

		//return null;

		let tableDataLength = 0;
		let tableDataArray = [];

		let headerNew = [];
		let secondaryHeader = [];
		let header = [];

		let playerImagePath1;
		let playerImagePath2;
		let player1FlagPath;
		let player2FlagPath;

		if (this.playerImagePath && this.state.headToHeadConfigData) {
			//headerNew = this.state.headToHeadConfigData.headToHead;
			//header = Object.keys(headerNew);
			//secondaryHeader = Object.keys(headerNew).map(d => headerNew[d]);

			forEach(this.state.headToHeadConfigData.rounds, function(rnd, key) {
				header.push(rnd.roundNameShort);
			});

			forEach(this.state.headToHeadConfigData.rounds, function(rnd, key) {
				secondaryHeader.push(rnd.roundName);
			});

			//logger.log('[HeadToHead] render - header:%o secHeader:%o', header, secondaryHeader);
		}

		if (this.state.player1Stats != undefined || this.state.player2Stats != undefined) {
			tableDataLength =
				(this.state.player1Stats.player_stats.length + this.state.player2Stats.player_stats.length) / 2;

			for (let i = 0; i < tableDataLength - 1; i++) {
				tableDataArray.push(i);
			}

			playerImagePath1 = this.state.headToHeadConfigData.pathObj.playerProfileImagePath.replace(
				'<playerid>',
				this.state.player1
			);
			playerImagePath2 = this.state.headToHeadConfigData.pathObj.playerProfileImagePath.replace(
				'<playerid>',
				this.state.player2
			);
			player1FlagPath = this.state.headToHeadConfigData.pathObj.flagImagePathLarge.replace(
				'<code>',
				this.state.player1Info.nation.code
			);
			player2FlagPath = this.state.headToHeadConfigData.pathObj.flagImagePathLarge.replace(
				'<code>',
				this.state.player2Info.nation.code
			);
		}

		if (this.state.playersDetails) {
			let player1Match = this.state.playersDetails.players.find(item => item.id === this.state.player1);
			let player2Match = this.state.playersDetails.players.find(item => item.id === this.state.player2);
			this.player1CourtTime = player1Match ? player1Match.timeOnCourt : null;
			this.player2CourtTime = player2Match ? player2Match.timeOnCourt : null;
		}

		return playerImagePath1 && playerImagePath2 ? (
			<div className={`head-to-head ${this.state.style}`}>
				<div className="matchup-info">
					<div className="player-one">
						<span>
							<img className="player-image " src={`${playerImagePath1}`} alt="player image" />
						</span>
						<div className="player-one-info">
							<hr />
							<div className="player-name-flag">
								<div className="player-flag">
									<img alt={this.state.player1Info.nation.code} src={player1FlagPath} />
								</div>
								<div className="player-name">
									<WimLink to={`/en_GB/players/overview/${this.state.player1Info.id}.html`}>
										{this.state.player1Stats.first_name} {this.state.player1Stats.last_name}
									</WimLink>
								</div>
							</div>
							<div className="player-rank">
								<div className="player-rank-num">
									<div>{this.state.player1Info.rank.current_singles}</div>
								</div>
								Singles Rank
							</div>
							{/* <div className="player-rank">
								<div className="player-rank-num">
									<div>{this.state.player1Info.rank.current_doubles}</div>
								</div>
								Doubles Rank
							</div> */}
						</div>
					</div>
					<div className="player-two">
						<span>
							<img className="player-image " src={`${playerImagePath2}`} alt="player image" />
						</span>
						<div className="player-two-info">
							<hr />
							<div className="player-name-flag">
								<div className="player-flag">
									<img alt={this.state.player2Info.nation.code} src={player2FlagPath} />
								</div>
								<div className="player-name">
									<WimLink to={`/en_GB/players/overview/${this.state.player2Info.id}.html`}>
										{this.state.player2Stats.first_name} {this.state.player2Stats.last_name}
									</WimLink>
								</div>
							</div>
							<div className="player-rank">
								<div className="player-rank-num">
									<div>{this.state.player2Info.rank.current_singles}</div>
								</div>
								Singles Rank
							</div>
							{/* <div className="player-rank">
								<div className="player-rank-num">
									<div>{this.state.player2Info.rank.current_doubles}</div>
								</div>
								Doubles Rank
							</div> */}
						</div>
					</div>
				</div>
				<div className="matchup-event-stats">
					<div className="table-title">
						<a
							onClick={() => {
								this.setSelected(-1);
							}}>
							<i className="wim-icon-left-arrow left-icon" />
						</a>
						{header.map((d, i) => {
							return (
								<div
									key={i}
									className={`left-nav-dot ${this.state['selectedIndex'] == i ? 'selected' : ''}`}>
									●
								</div>
							);
						})}
						<a
							onClick={() => {
								this.setSelected(1);
							}}>
							<i className="wim-icon-right-arrow right-icon" />
						</a>
					</div>
					<div className="datatable">
						<div className="title">
							Event Statistics
							{/* <div className="stat-round">{secondaryHeader[this.state.selectedIndex]}</div> */}
						</div>
						<div className="headers player-names">
							<div className="cell first-col" />

							<div className="cell">
								<div className="player-header">
									<span className="full-name">
										{this.state.player1Stats.first_name} {this.state.player1Stats.last_name}
									</span>
									<span className="abbrev-name">
										{this.state.player1Stats.first_name.substring(0, 1)}{' '}
										{this.state.player1Stats.last_name.substring(0, 1)}
									</span>
								</div>
							</div>

							<div className="cell">
								<div className="player-header">
									<span className="full-name">
										{this.state.player2Stats.first_name} {this.state.player2Stats.last_name}
									</span>
									<span className="abbrev-name">
										{this.state.player2Stats.first_name.substring(0, 1)}{' '}
										{this.state.player2Stats.last_name.substring(0, 1)}
									</span>
								</div>
							</div>
						</div>
						{/* <div className="row">
							<div className="cell first-col">Head to Head</div>
							<div className="cell">?</div>
							<div className="cell">?</div>
						</div> */}
						<div className="row">
							<div className="cell first-col">Best Wimbledon Performance</div>
							<div className="cell">
								{this.state.player1Info.event.best_singles
									? this.state.player1Info.event.best_singles
									: ''}
							</div>
							<div className="cell">
								{this.state.player2Info.event.best_singles
									? this.state.player2Info.event.best_singles
									: ''}
							</div>
						</div>
						<div className="row">
							<div className="cell first-col">Total Match Wins</div>
							<div className="cell">
								{this.state.player1Info.results.year.matches_won
									? this.state.player1Info.results.year.matches_won
									: ''}
							</div>
							<div className="cell">
								{this.state.player2Info.results.year.matches_won
									? this.state.player2Info.results.year.matches_won
									: ''}
							</div>
						</div>
						<div className="row">
							<div className="cell first-col">Total Match Losses</div>
							<div className="cell">
								{this.state.player1Info.results.year.matches_lost
									? this.state.player1Info.results.year.matches_lost
									: ''}
							</div>
							<div className="cell">
								{this.state.player2Info.results.year.matches_lost
									? this.state.player2Info.results.year.matches_lost
									: ''}
							</div>
						</div>
						<div className="row">
							<div className="cell first-col">Total Time on Court</div>
							<div className="cell ">{this.player1CourtTime ? this.player1CourtTime : ''}</div>
							<div className="cell">{this.player2CourtTime ? this.player2CourtTime : ''}</div>
						</div>
					</div>
				</div>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadToHead);

import React, { Component } from 'react';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import { TicketsContext } from '../context';
import uniq from 'lodash/uniq';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import DatePicker from 'react-datepicker';
import MeasurementUtils from 'appdir/lib/analytics';

const ticket_action_labels = {
	transfer: { label: 'Transfer A Ticket', value: 'transfer', order: 2 },
	share: { label: 'Share A Ticket', value: 'share', order: 3 },
	assign: { label: 'Assign But Keep A Ticket', value: 'assign', order: 4 },
	unassign: { label: 'Unassign A Ticket', value: 'unassign', order: 5 },
	recall: { label: 'Recall A Ticket', value: 'recall', order: 6 },
	cancel: { label: 'Cancel A Ticket', value: 'cancel', order: 8 },
	return: { label: 'Return A Ticket', value: 'return', order: 7 },
	swap: { label: 'Swap A Ticket', value: 'swap', order: 9 }, // TODO: check what order this should be
};

class TicketFilters extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: '',
		};

		this.setAction = this.setAction.bind(this);
		this.setDate = this.setDate.bind(this);
		this.setCourt = this.setCourt.bind(this);
		this.setStatus = this.setStatus.bind(this);
		this.setType = this.setType.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.setModalClosed = this.setModalClosed.bind(this);

		logger.log('[TicketFilters] constructor props:%o state:%o', props, this.state);
	}

	handleClick = e => {
		// logger.log('[TicketFilters] handleClick e.target:%o', e.target);
		// logger.log('[TicketFilters] handleClick this.actionsNode:%o', this.actionsNode);
		if (
			this.actionsNode &&
			!this.actionsNode.contains(e.target) &&
			this.dateNode &&
			!this.dateNode.contains(e.target) &&
			this.courtNode &&
			!this.courtNode.contains(e.target) &&
			this.statusNode &&
			!this.statusNode.contains(e.target) &&
			this.typeNode &&
			!this.typeNode.contains(e.target)
		) {
			this.setState({
				open: '',
			});
		}
	};

	getActionInfo() {
		return (
			<>
				<div className="title">Ticket Options</div>
				<div className="options-text">
					You have the following options to
					<br />
					choose from when managing your tickets
				</div>
				<div className="subtitle">Share A Ticket</div>
				You may share a Standard or Member ticket with another person. You will need to know their myWimbledon
				ID which is the email address they signed up with.
				<div className="subtitle">Transfer A Ticket</div>
				You may transfer a Debenture ticket to another person. You will need to know their myWimbledon ID which
				is the email address they signed up with.
				<div className="subtitle">Recall A Ticket</div>
				You may recall a ticket that you shared with another person.
				<div className="subtitle">Assign But Keep A Ticket</div>
				You may assign a person’s name to a ticket if they do not have a myWimbledon ID and/or a mobile device.
				They must attend with you on the day.
				<div className="subtitle">Unassign A Ticket</div>
				You may unassign a person’s name from a ticket.
				<div className="subtitle">Cancel A Ticket</div>
				You may cancel a ticket and request a refund.
				<div className="subtitle">Return a Ticket</div>
				You may return your ticket back to the sender if you are unable to attend.
			</>
		);
	}

	setAction(which) {
		//logger.log('[TicketFilters] setAccount:%o', which);

		let filterval = which !== 'all' ? which : '';

		this.setState({
			action: filterval,
			open: '',
		});

		if (this.props.onChange) {
			this.props.onChange('action', filterval);
		}
	}

	setDate(dateVal) {
		//logger.log('[TicketFilters] setDate:%o', date);

		MeasurementUtils.dispatchMeasurementCall('Day Picker', { pageTitle: 'Tickets', dateSelected: dateVal });

		let date = dateVal ? moment(dateVal).format('ddd, DD MMM YYYY') : '';

		let filterval = date !== 'all' ? date : '';

		this.setState({
			date: filterval,
			open: '',
		});

		if (this.props.onChange) {
			this.props.onChange('date', filterval);
		}
	}

	setCourt(court) {
		//logger.log('[TicketFilters] setCourt:%o', court);

		let filterval = court !== 'all' ? court : '';

		this.setState({
			court: filterval,
			open: '',
		});

		if (this.props.onChange) {
			this.props.onChange('court', filterval);
		}
	}

	setStatus(status) {
		//logger.log('[TicketFilters] setStatus:%o', status);

		let filterval =
			status !== 'all'
				? status === 'Active'
					? 'Normal'
					: status // if status set is 'Active', switch to 'Normal' (i.e., what real status is) -- part of #2370
				: '';

		this.setState({
			status: filterval,
			open: '',
		});

		if (this.props.onChange) {
			this.props.onChange('status', filterval);
		}
	}

	setType(type) {
		//logger.log('[TicketFilters] setType:%o', type);

		let filterval = type !== 'all' ? type : '';

		this.setState({
			type: filterval,
			open: '',
		});

		if (this.props.onChange) {
			this.props.onChange('type', filterval);
		}
	}

	setModalClosed() {
		this.props.onModalClose();
	}

	onOpen(which) {
		logger.log('[TicketFilters] onOpen:%o', which);

		// if (which == this.state.open) {
		// 	this.setState({
		// 		open: '',
		// 	});
		// } else {
		this.setState({
			open: which,
		});
		// }
	}

	getCourtName(crt) {
		let courtObj =
			this.context.orderedCourts.filter(c => {
				return c.code == crt;
			})[0] || false;
		let court = op.get(courtObj, 'courtName', false) ? op.get(courtObj, 'courtName') : '';

		return court;
	}

	getDaysBetweenDates(startDate, endDate) {
		// var now = startDate.clone(), dates = [];
		let dates = [];
		let now = moment(startDate).clone();

		while (now.isSameOrBefore(moment(endDate))) {
			dates.push(now.format('ddd, DD MMM YYYY'));
			now.add(1, 'days');
		}
		return dates;
	}

	render() {
		logger.log('[TicketFilters] render - context:%o', this.context);

		let actionItems = [];
		let actionOptions = {
			options: [{ name: 'View All Tickets', value: 'all', selected: true }],
		};
		let dateItems = [];
		let dateOptions = {
			options: [{ name: 'Choose date', value: 'all', selected: true }],
		};
		let courtItems = [];
		let courtOptions = {
			options: [{ name: 'All', value: 'all', selected: true }],
		};
		let statusItems = [];
		let statusOptions = {
			options: [{ name: 'All', value: 'all', selected: true }],
		};
		let typeItems = [];
		let typeOptions = {
			options: [{ name: 'All', value: 'all', selected: true }],
		};

		let selectedAction = op.get(this.context, 'filters.action', null);

		// if user has ticket data, loop through and get all values for dropdowns
		if (this.context.ticketData.length > 0) {
			// add available options
			this.context.ticketData.forEach(item => {
				item?.actions?.forEach(action => {
					if (!selectedAction || item.actions.includes(selectedAction)) {
						actionItems.push(action);
					}
				});

				if (op.get(item.source.event, 'startTime', null)) {
					if (!selectedAction || item.actions.includes(selectedAction)) {
						dateItems.push(
							moment(item.source.event.startTime)
								.tz('Europe/London')
								.format('ddd, DD MMM YYYY')
						);
					}
				}

				if (op.get(item, 'source.details.main', null)) {
					if (!selectedAction || item.actions.includes(selectedAction)) {
						courtItems.push(
							item.source.details.main
								.filter((d, i) => {
									return d.key == 'Court';
								})
								.map((d, i) => {
									return d.value;
								})[0] || ''
						);
					}
				}

				if (op.get(item, 'status.filtervalue', null)) {
					if (!selectedAction || item.actions.includes(selectedAction)) {
						// if status is 'Normal', switch to 'Active' -- part of #2370
						let filterValueToPush =
							item.status.filtervalue === 'Normal' ? 'Active' : item.status.filtervalue;
						statusItems.push(filterValueToPush);
					}
				}

				if (op.get(item, 'typeLabel', null)) {
					if (!selectedAction || item.actions.includes(selectedAction)) {
						typeItems.push(item.typeLabel);
					}
				}
			});

			logger.log('[TicketFilters] render - typeItems:%o', typeItems);

			// de-dupe items in dropdowns
			actionItems = uniq(actionItems);
			dateItems = uniq(dateItems);
			courtItems = uniq(courtItems).filter((c, i) => {
				return c != '';
			});
			statusItems = uniq(statusItems);
			typeItems = uniq(typeItems);

			let tempActions = [];
			actionItems.forEach(item => {
				if (item.indexOf('none') == -1 && item.indexOf('disallowed') == -1) {
					tempActions.push(ticket_action_labels[item]);
				}
			});

			tempActions.sort((a, b) => a.order - b.order);

			// logger.log('[TicketFilters] render - tempActions:%o', tempActions);

			tempActions.forEach(item => {
				actionOptions.options.push({ name: item.label, value: item.value });
			});

			// logger.log('[TicketFilters] render - actionOptions:%o', actionOptions);

			dateItems.forEach(date => {
				dateOptions.options.push({ name: date, value: date });
			});

			// sort by secod char (i.e., compare n-court number, "r" in grounds, etc.)
			courtItems = courtItems.sort((a, b) => {
				let prestigeA = this.context.orderedCourts.filter((d, i) => {
					return d.code == a; // grab prestige
				});
				let prestigeB = this.context.orderedCourts.filter((d, i) => {
					return d.code == b; // grab prestige
				});
				let toReturn = prestigeA[0] && prestigeB[0] ? prestigeA[0].prestige - prestigeB[0].prestige : '';
				return toReturn;
			});

			courtItems.forEach(court => {
				let courtName = this.getCourtName(court);
				courtOptions.options.push({ name: courtName, value: court });
			});

			statusItems.forEach(status => {
				statusOptions.options.push({ name: status, value: status });
			});

			typeItems.forEach(type => {
				typeOptions.options.push({ name: type, value: type });
			});

			// logger.log('[TicketFilters] render - dateOptions:%o', dateOptions);
			// logger.log('[TicketFilters] render - courtOptions:%o', courtOptions);
			// logger.log('[TicketFilters] render - statusOptions:%o', statusOptions);
			logger.log('[TicketFilters] render - typeOptions:%o', typeOptions);

			const allDatesInRange = this.getDaysBetweenDates(dateItems[0], dateItems[dateItems.length - 1]);
			// compare dates in allDatesInRange and dateItems to disable dates that are not included (disallow user from selecting in cal)
			let datesToDisable = allDatesInRange.filter(x => !dateItems.includes(x));

			const { ticketManagementChecked } = this.props;

			return (
				<>
					<div className="ticket-mgmt-container">
						<div style={{ display: ticketManagementChecked ? 'block' : 'none' }}>
							<div
								alt="ticket actions section for ticket management list view"
								className="ticket-mgmt-title"
								tabIndex={0}>
								ticket actions
							</div>
							<div
								className="ticket-mgmt-tab"
								tabIndex={0}
								alt="filter group. select an option to filter ticket management"
								aria-label="filter group. select an option to filter ticket management">
								<button
									role="button"
									alt={`filter on "view all" action. ${
										this.props.ticketActionTabSelected === 'viewAll'
											? 'currently selected'
											: 'not currently selected'
									}`}
									aria-label={`filter on view all. ${
										this.props.ticketActionTabSelected === 'viewAll'
											? 'currently selected'
											: 'not currently selected'
									}`}
									className={`ticket-mgmt-tablinks ${
										this.props.ticketActionTabSelected === 'viewAll'
											? 'ticket-mgmt-tab-selected'
											: ''
									}`}
									onClick={() => {
										this.props.onTicketActionTabSelected('viewAll');
									}}>
									View All
								</button>
								<button
									role="button"
									alt={`filter on "transfer" action. ${
										this.props.ticketActionTabSelected === 'transfer'
											? 'currently selected'
											: 'not currently selected'
									}`}
									aria-label={`filter on transfer. ${
										this.props.ticketActionTabSelected === 'transfer'
											? 'currently selected'
											: 'not currently selected'
									}`}
									id="ticket-mgmt-tab-transfer"
									className={`ticket-mgmt-tablinks ${
										this.props.ticketActionTabSelected === 'transfer'
											? 'ticket-mgmt-tab-selected'
											: ''
									}`}
									onClick={() => {
										this.props.onTicketActionTabSelected('transfer');
									}}>
									Transfer
								</button>
								<button
									role="button"
									alt={`filter on "cancel transfer" action. ${
										this.props.ticketActionTabSelected === 'recall'
											? 'currently selected'
											: 'not currently selected'
									}`}
									aria-label={`filter on cancel transfer. ${
										this.props.ticketActionTabSelected === 'recall'
											? 'currently selected'
											: 'not currently selected'
									}`}
									className={`ticket-mgmt-tablinks ${
										this.props.ticketActionTabSelected === 'recall'
											? 'ticket-mgmt-tab-selected'
											: ''
									}`}
									onClick={() => {
										this.props.onTicketActionTabSelected('recall');
									}}>
									Cancel Transfer
								</button>
								<button
									role="button"
									alt={`filter on "return to sender" action. ${
										this.props.ticketActionTabSelected === 'returnToSender'
											? 'currently selected'
											: 'not currently selected'
									}`}
									aria-label={`filter on return to sender. ${
										this.props.ticketActionTabSelected === 'returnToSender'
											? 'currently selected'
											: 'not currently selected'
									}`}
									className={`ticket-mgmt-tablinks ${
										this.props.ticketActionTabSelected === 'returnToSender'
											? 'ticket-mgmt-tab-selected'
											: ''
									}`}
									onClick={() => {
										this.props.onTicketActionTabSelected('returnToSender');
									}}>
									Return To Sender
								</button>
							</div>
						</div>

						{/* <div className="tix-faq-link">
							<a href={this.props.faqsUrl}>
								<i className="wim-icon-info info tix-faq-info" />
								TICKETING FAQs
							</a>
						</div> */}
						<button
							className={'ticket-reset-btn-mrgn ticket-reset-button'}
							role="button"
							style={{
								display: !ticketManagementChecked ? 'block' : 'none', // don't need the extra margin so changing display not visibilty
								visibility: this.props.ticketLength > 10 ? 'visible' : 'hidden', // need the margin so changing visibility not display
								height: this.props.ticketLength > 10 ? 'auto' : '0px', // need the margin so changing visibility not display
							}}
							onClick={() => this.props.onReset()}>
							Reset Filters
						</button>
					</div>
					<div
						className="four-col filter-row"
						style={{ display: !ticketManagementChecked && this.props.ticketLength > 10 ? 'flex' : 'none' }}>
						<div className="ticketview-filters">
							<div className="date-filter" ref={node => (this.dateNode = node)}>
								View by date
								<div className="scores-select-menu">
									<DatePicker
										selected={
											this.context && this.context.filters && this.context.filters.date
												? new Date(this.context.filters.date)
												: ''
										}
										onChange={this.setDate}
										dateFormat="dd MMM yyyy"
										placeholderText="Choose date"
										minDate={dateItems && dateItems.length ? new Date(dateItems[0]) : null}
										maxDate={
											dateItems && dateItems.length
												? new Date(dateItems[dateItems.length - 1])
												: null
										}
										excludeDates={datesToDisable.map(val => new Date(val))}
										showDisabledMonthNavigation
										className="date-filter-picker"
										isClearable={this.context && this.context.filters && this.context.filters.date}
									/>
								</div>
							</div>
							{/* <div className="date-filter" ref={node => (this.dateNode = node)}>
								View by date
								<div className="scores-select-menu">
									<SelectMenu
										name="viewbydate"
										attributes={dateOptions}
										selected={op.get(this.context, 'filters.date', 'all')}
										show={this.state.open == 'date'}
										onSelected={this.setDate}
										onOpen={() => {
											this.onOpen('date');
										}}
									/>
								</div>
							</div> */}
						</div>

						<div className="ticketview-filters">
							<div className="court-filter" ref={node => (this.courtNode = node)}>
								View by court
								<div className="scores-select-menu">
									<SelectMenu
										name="viewbycourt"
										attributes={courtOptions}
										selected={op.get(this.context, 'filters.court', 'all')}
										show={this.state.open == 'court'}
										onSelected={val => this.setCourt(val)}
										filterName="court"
										onOpen={val => {
											this.onOpen(val);
										}}
									/>
								</div>
							</div>
						</div>

						<div className="ticketview-filters">
							<div className="status-filter" ref={node => (this.statusNode = node)}>
								View by status
								<div className="scores-select-menu">
									<SelectMenu
										name="viewbystatus"
										attributes={statusOptions}
										selected={
											op.get(this.context, 'filters.status', 'all') === 'Normal'
												? 'Active'
												: op.get(this.context, 'filters.status', 'all')
										}
										show={this.state.open == 'status'}
										onSelected={this.setStatus}
										filterName="status"
										onOpen={val => {
											this.onOpen(val);
										}}
									/>
								</div>
							</div>
						</div>

						<div className="ticketview-filters">
							<div className="type-filter" ref={node => (this.typeNode = node)}>
								View by type
								<div className="scores-select-menu">
									<SelectMenu
										name="viewbytype"
										attributes={typeOptions}
										selected={op.get(this.context, 'filters.type', 'all')}
										show={this.state.open == 'type'}
										onSelected={this.setType}
										filterName="type"
										onOpen={val => {
											this.onOpen(val);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return null;
		}
	}
}

TicketFilters.contextType = TicketsContext;
export default TicketFilters;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getMenus } from 'appdir/components/general/Util';
import WimLink from 'appdir/components/general/WimLink';
import MeasurementUtils from 'appdir/lib/analytics';
import Helmet from 'react-helmet';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

/**
 * -----------------------------------------------------------------------------
 * React Component: SiteMap
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SiteMap'],
		menus: state['Config']['menus'],
		...props,
		...state['Gigya'],
		liveIndicators: state['ActiveData']['liveIndicators'],
		version: state.Config.myWimbledon?.version,
		shelfIcons: state.Config.myWIcons,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SiteMap.mount()),
});

class SiteMap extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		this.props.mount();

		if (this.state.notFound) {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'Not Found',
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'Sitemap',
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		const roles = this.state.currentUser.roles
			?.filter(role => role.landingPage)
			.map(role => ({
				...role,
				name: this.props.shelfIcons[role.keySegment]?.find(role => role.show.includes('landing')).title,
			}))
			.sort((a, b) => this.props.shelfIcons?.titles[a.key].sort - this.props.shelfIcons?.titles[b.key].sort);
		logger.log('[SiteMap] render - state:%o', this.state);
		let menus = getMenus(this);
		let classes = 'sitemap';
		if (this.props.class) {
			classes = classes.concat(' ' + this.props.class);
		}

		if (this.state.menus && this.state.myWim) {
			return (
				<div className={classes}>
					{this.state.notFound ? (
						<Helmet>
							<meta name="prerender-status-code" content="404" />
							<meta name="robots" content="noindex" />
						</Helmet>
					) : null}
					<noindex>
						<ul role="list">
							{menus.map(menu => {
								if (menu.title === 'myWimbledon' && !this.state.myWim.active) {
									return null;
								} else if ('link' in menu) {
									return (
										<li key={menu.title}>
											<WimLink to={menu.link}>{menu.title}</WimLink>
											<ul role="list">
												{menu.title === 'myWimbledon' && (
													<>
														<li key="profile-preferences">
															<WimLink to={'/en_GB/mywimbledon/index.html'}>
																Profile & Preferences
															</WimLink>
														</li>
														{roles?.map(role => (
															<li key={role.landingPage}>
																<WimLink to={role.landingPage}>{role.name}</WimLink>
															</li>
														))}
														<li key="about-myW">
															<WimLink to={'/en_GB/aboutmywimbledon/index.html'}>
																About <MyWimbledonTitle />
															</WimLink>
														</li>
													</>
												)}
											</ul>
										</li>
									);
								}

								if ('links' in menu) {
									return (
										<li key={menu.title}>
											{menu.title}
											<ul role="list">
												{menu.links.map(link => (
													<li key={link.title}>
														<WimLink to={link.link}>{link.title}</WimLink>
													</li>
												))}
											</ul>
										</li>
									);
								}
							})}
						</ul>
					</noindex>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMap);

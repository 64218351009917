/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
const op = require('object-path');
import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';

/** MI Specific components */
import MatchInsightsContent from './MatchInsightsContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsight
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	unmount: () => dispatch(deps.actions.MatchInsights.unmount()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

class MatchInsights extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		// logger.log('[MatchInsights] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidMount() {}

	componentWillUnmount() {
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[MatchInsights] componentDidUpdate  - this.state:%o', this.state);
	}

	render() {
		logger.log('[MatchInsights] render - props:%o, this.state:%o', this.props, this.state);
		let header_propsData = {
			headerType: 'match-insights',
			title: values.matchInsightsTitle,
			shortTitle: values.matchInsightsTitle,
			metaTitle: values.matchInsightsTitle,
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		return (
			<Template className="scorespage" id="match-insights">
				<div className="scores-background" />
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />
				<MatchInsightsContent matchId={this.props.match.params.matchId} isWebview={false} />
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsights);

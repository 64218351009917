/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import SocialEmbed from 'appdir/components/content/SocialEmbed';

const TwitterList = props => {
	//logger.log('[ContentLink] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	//logger.log('[ContentLink] - attr:%o', attributes);

	if (attributes) {
		return (
			<div className={`${attributes['style']}`}>
				<SocialEmbed data={{ type: 'timeline', screenName: attributes['listId'], height: 600 }} />
			</div>
		);
	} else {
		return null;
	}
};

export default TwitterList;

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	switch (action.type) {
		case deps.actionTypes.COUNTRYPLAYERPAGE_MOUNT:
			newState = { ...state, path: action.data };
			return newState;
			break;
		case deps.actionTypes.COUNTRYPLAYERPAGE_FILTER:
			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
			};
			return newState;
			break;
		case deps.actionTypes.COUNTRYPLAYERPAGE_LOADING:
			newState = Object.assign({}, state, { status: 'loading' });
			return newState;
			break;
		case deps.actionTypes.COUNTRYPLAYERPAGE_LOAD_COMPLETE:
			logger.log('[COUNTRYSTATISTICSPAGE] COUNTRYSTATISTICSPAGE_LOAD_COMPLETE - result:%o', action.data);
			newState = Object.assign({}, state, { data: action.data, status: 'loaded' });
			return newState;
			break;

		case deps.actionTypes.COUNTRYPLAYERPAGE_LOAD_ERROR:
			newState = Object.assign({}, state, { error: action.err, status: 'error' });
			return newState;
			break;

		default:
			return state;
	}
};

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[VisitWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.VISITWEBVIEW_LOAD:
			logger.log('[VisitWebview] reducers - action:%o', action);

			newState = {
				...state,
				status: 'load',
			};

			logger.log('[VisitWebview] reducers VISITWEBVIEW_LOAD - newState:%o', newState);
			return newState;
		//break;

		default:
			return state;
	}
};

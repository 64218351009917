import assignIn from 'lodash/assignIn';
import { countries } from 'appdir/components/general/Gigya/translations/countries';
import { birthYear } from 'appdir/components/general/Gigya/translations/birthYear';
import { birthMonth } from 'appdir/components/general/Gigya/translations/birthMonth';
import { birthDay } from 'appdir/components/general/Gigya/translations/birthDay';
import { title } from 'appdir/components/general/Gigya/translations/title';
import { registrationLogin } from 'appdir/components/general/Gigya/translations/registrationLogin';
import { profileUpdate } from 'appdir/components/general/Gigya/translations/profileUpdate';
import { consent } from 'appdir/components/general/Gigya/translations/consent';

export const screenset = {
	translations: assignIn(registrationLogin, profileUpdate, countries, birthYear, birthMonth, birthDay, title, consent),
};

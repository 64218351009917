import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const playerImagePath = Config.otherData.playerImagePath;
			const flagImagePathLarge = Config.otherData.flagImagePathLarge;
			const flagImagePathSmall = Config.otherData.flagImagePathSmall;
			const pathObj = { playerImagePath, flagImagePathLarge, flagImagePathSmall };
			dispatch({
				type: deps.actionTypes.DRAW_MOUNTED,
				data: pathObj,
			});
		});
	},
};

import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';


export default class Fullscreen extends Component {
	
	constructor(props) {
		super(props);

		this.styles = props.styles;

		this.state = {
			...this.props
		}

		this.amp = props.amp;
		
		this.onClick = this.onClick.bind(this);

		//logger.log('Fullscreen: constructor - state:%o', this.state);

		// document.addEventListener('webkitfullscreenchange', function(e){
		// 	logger.info('onFullscreenChange - e:%o', e);   
		// });
	}

	componentWillReceiveProps(nextProps) {
		//logger.log('componentWillReceiveProps - nextProps:%o', nextProps);

		if (this.props.isFullscreen !== nextProps.isFullscreen) {
			// Fullscreen status has changed.
			this.setState({
				isFullscreen: nextProps.isFullscreen
			})
        }
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//logger.log('Fullscreen: componentDidUpdate - amp:%o', this.props.amp.multitonKey);

		if (this.props.amp.multitonKey != prevProps.amp.multitonKey) {
			this.amp = this.props.amp;
			//this.addAmpListeners();
			//this.amp.addEventListener('fullscreenchange', this.onFullscreenChange);

			//this.amp.mediaElement.addEventListener('webkitendfullscreen', console.log);
		}
	}

	onFullscreenChange() {
		// this.setState ({
		// 	fullscreen: (data.value == "full-screen")
		// })
		logger.log('Fullscreen: onFullscreenChange - data:%o', );
	}

	onClick() {
		let useNative = akamai.amp.Utils.isIPhone();
		// || akamai.amp.Utils.isAndroid()
		logger.log('Fullscreen: onClick - amp:%o native:%o', this.amp, useNative);

		if (useNative) {
			if (!this.state.isFullscreen) {
				this.amp.enterFullScreen();
				this.props.onClick('native_fullscreen');
			}
			else {
				this.amp.exitFullScreen();
			}
		}
		else if (this.props.onClick) {
			this.props.onClick(this.state.isFullscreen ? 'fullscreen_close' : 'fullscreen_open');
		}
	}
	
	render() {
		//logger.log('Fullscreen: render - state:%o', this.state);
		let inline = {};
		let data_state = '';
		

		if (this.state.isFullscreen) {
			data_state = 'fullscreen';
		}
		else {
			data_state = 'normal';
		}

		return (
			<button 
				className={`${this.styles.icon} ${this.styles.fullscreen}`} 
				style={inline} 
				onClick={() => {this.onClick()}} 
				data-state={data_state}
				title="Fullscreen">
			</button>
		)
	}
	
}

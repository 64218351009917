/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SinglesChallengeSummary from './SinglesChallengeSummary';
import StubBox from 'appdir/components/common-ui/StubBox';
import MSingles from './MSingles';
import WSingles from './WSingles';

/**
 * -----------------------------------------------------------------------------
 * React Component: SiteMapPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		PlayerChallengePage: state.PlayerChallengePage,
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerChallengePage.mount()),
});

class PlayerChallengePage extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props };
		//this.state = Object.assign({}, this.props);
		this.loadedJson = '';
	}

	componentWillUnmount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[PlayerChallenge] componentWillReceiveProps - next:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	componentDidUpdate(prevState) {
		if (!this.loadedJson && this.state.PlayerChallengePage.challengePath) {
			this.loadedJson = this.state.PlayerChallengePage.challengePath;
			deps.services.PlayerChallengePage.fetch(this.state.PlayerChallengePage.challengePath)
				.then(result => {
					this.setState({
						events: result.events,
					});
				})
				.catch(error => {
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}
	}

	render() {
		logger.log('[PlayerChallenge] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'scores',
			title: 'Player Challenge',
			shortTitle: 'Player Challenge',
			scroll: false,
			metaTitle: 'Player Challenge',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		if (!this.state.stubs) {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
		if (this.state.stubs.challenge.stub != 'stub') {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />

					<div className="player-challenge content-main column-layout">
						<SinglesChallengeSummary events={this.state.events} />
						<MSingles events={this.state.events} />
						<WSingles events={this.state.events} />
						<p className="center">NOTES:</p>
						<ul role="list">
							<li>
								A challenge of a line call or overrule by a player/team shall be allowed only on either
								a point-ending shot or when a player/team stops playing the point during a rally
								(returns are permitted but then the player/team must immediately stop).
							</li>
							<li>
								The Chair Umpire may only refuse the Electronic Review if he/she believes that the
								challenge was not made in a timely manner. Otherwise, the Chair Umpire will use the
								Electronic Review when there is a player/team challenge. The result of the Electronic
								Review is final and unappealable.
							</li>
							<li>
								The Chair Umpire may request an Electronic Review when the Line Umpire was unsighted on
								a point-ending shot and he/she is unable to make a decision.
							</li>
							<li>
								If the Electronic Review is unavailable for any reason, then the original line call or
								overrule will stand.
							</li>
						</ul>
						<p className="center">
							<img alt="player challenge" src="/assets/images/player-challenge/player_challenge.png" />
						</p>
						<p>
							<strong>Player Challenge System</strong>
						</p>
						<p>
							Players have unlimited opportunity to challenge, but once three incorrect challenges are
							made in a set, they cannot challenge again until the next set. If the set goes to a tie
							break, players are given additional opportunities to challenge.
						</p>

						<p>
							<strong>How the On-Court Challenge System Works:</strong>
						</p>

						<ul role="list">
							<li>
								Each player/team is allowed a maximum of three incorrect challenges in a normal set
								after which they are not permitted to challenge again in that set.
							</li>
							<li>
								If a set goes to a tiebreak, this limit of incorrect challenges is increased from three
								to four for the set.
							</li>
							<li>Challenges may not be carried over from one set to another.</li>
						</ul>

						<p>
							<strong>
								In the Advantage set - 5th set for gentlemen's singles/doubles and 3rd set for all other
								events (except Seniors’ events)
							</strong>
						</p>

						<ul role="list">
							<li>
								Each player/team is allowed a maximum of three incorrect challenges in a normal set
								after which they are not permitted to challenge again in that set.
							</li>
							<li>
								However, if the match is tied at six games all, the counter is reset with both
								players/teams again having a limit of up to three incorrect challenges in the next 12
								games
							</li>
							<li>
								Challenges may not be carried over and the process of resetting is repeated after every
								12 games.
							</li>
						</ul>

						<p>
							<strong>Match Tie-Break (Seniors Events):</strong>
						</p>

						<ul role="list">
							<li>
								During a Match Tie Break, the Match Tie Break is considered a new set and each
								player/team will again have the opportunity to make up to three incorrect challenges.
							</li>
						</ul>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="scorespage">
					<div className="scores-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="content-main">
						<StubBox attributes={{ message: this.state.stubs.challenge.text, style: 'centerV' }} />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerChallengePage);

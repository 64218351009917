import React, { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import parse from 'html-react-parser';

const StaticSmartSearch = props => {
	const [state, setState] = useState({
		//    showSmartSearch: props.data.showResults,
		//    highlightedResult: -1,
		...props.data,
	});

	useEffect(() => {
		setState({
			...state,
			value: props.data.value,
			prePopData: props.data.prePopData,
			// showSmartSearch: props.data.showResults
		});
		// return () => {
		//     window.removeEventListener("keydown", keyDown);
		//     window.removeEventListener("click", clickHandler);
		// };
	}, [props.data]);

	//    window.addEventListener('keydown', throttle(keyDown.bind(this), 500), false);
	//    window.addEventListener('click', throttle(clickHandler.bind(this), 0), false);

	function clickHandler(e) {
		let activeEl = document.activeElement;
		let bodyEl = document.getElementsByTagName('body')[0];
		let searchResultEl = document.getElementById('wim-search-results-id');
		if (activeEl != bodyEl && activeEl != searchResultEl) {
			// this.props.showResults(false);
			setState({
				...state,
				showSmartSearch: false,
			});
		}
	}

	function highlightText(searchTerm, text) {
		let searchTermLower = searchTerm;
		let searchRegex = new RegExp(searchTermLower, 'gi');
		if (text) {
			return `<span>${text.replace(searchRegex, str => `<span class='bold-text'>${str}</span>`)}</span>`;
		}
		return null;
	}

	function updateHighlightedResult(update) {
		// if (this.state.searchResults) {
		// 	if (this.state.searchResults.response.docs.length > 0) {
		// 		if (this.setBlur) {
		// 			document.getElementById('wimbledon-search-text').blur();
		// 			document.getElementById('wim-search-results-id').focus();
		// 			this.setBlur = false;
		// 		}
		let resultLength = state.prePopData.length - 1;
		let updateIndex = state.highlightedResult + update;
		if (updateIndex <= resultLength && updateIndex >= 0) {
			setState({ ...state, highlightedResult: updateIndex });
		}
		// }
		// }
	}

	// function keyDown(e) {
	// 	let keyPressValue = e.which;
	// 	let searchResultEl = document.getElementById('wim-search-results-id');
	// 	let searchInputEl = document.getElementById('wimbledon-search-text');
	// 	let activeEl = document.activeElement;
	// 	switch (keyPressValue) {
	// 		case 38:
	// 			if (activeEl == searchResultEl || activeEl == searchInputEl) {
	// 				e.preventDefault();
	// 				updateHighlightedResult(-1);
	// 			}
	// 			break;
	// 		case 40:
	// 			if (activeEl == searchResultEl || activeEl == searchInputEl) {
	// 				e.preventDefault();
	// 				updateHighlightedResult(1);
	// 			}
	// 			break;
	// 		case 13:
	// 			if (activeEl == searchResultEl) {
	// 				e.preventDefault();
	// 				setState({ nav: true });
	// 			} else {
	// 				showResults(false);
	// 				setState({ showSmartSearch: false });
	// 			}
	// 			break;
	// 		default:
	// 			return false;
	//         }
	// }
	return (
		<div tabIndex="1" id="wim-search-results-id" className="wim-search-results">
			{state.prePopData.map((d, i) => {
				return (
					<div
						className={`result-article-title ${state.highlightedResult === i ? 'highlight' : ''}`}
						onClick={() => {
							state.submitFn(d);
						}}
						key={`${d.id}-${i}`}>
						{/*ReactHtmlParser*/ parse(highlightText(state.value, `${d.firstName} ${d.lastName}`))}
					</div>
				);
			})}
		</div>
	);
};

export default StaticSmartSearch;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { values } from 'appdir/main';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ArchiveMenu from 'appdir/components/drawsArchive/DrawsArchiveMenu';
import PlayerArchiveInfo from './PlayerArchiveInfo';
import PlayerProfessionalInfo from './PlayerProfessionalInfo';
import PlayerExtraInfo from './PlayerExtraInfo';
import Tabs from './Tabs';
import RelatedContent from 'appdir/components/cms/RelatedContent';
import PrevNextBack from 'appdir/components/common-ui/PrevNextBack';
import MeasurementUtils from 'appdir/lib/analytics';
import Template from 'appdir/components/Template';

const mapStateToProps = (state, props) => {
	return {
		...state['PlayerProfile'],
		drawsArchiveProfileExtraDataPath: op.get(state['Config'].cmsData, 'drawsArchiveProfileExtraData', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerProfile.mount()),
});

class PlayerProfile extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props, playerDetailData: null };
		this.fetchedData = false;
		logger.log('[PlayerProfile] constructor - state:%o', this.state);
	}

	componentWillUnmount() {
		logger.log('[PlayerProfile] componentWillUnmount');
		this.setState({
			playerDetailData: null,
		});
		this.fetchedData = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});

		logger.log('[PlayerProfile] componentWillReceiveProps - state:%o, nextProps:%o', this.state, nextProps);
	}

	componentDidMount() {
		logger.log('[PlayerProfile] componentDidMount - state:%o', this.state);
		if (!this.state.playerDetailData && !this.fetchedData && this.state.playerInfoPath) {
			this.fetchedData = true;
			this.fetchPlayerData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.playerDetailData && !this.fetchedData && this.state.playerInfoPath) {
			this.fetchedData = true;
			this.fetchPlayerData();
		}
		logger.log('[PlayerProfile] componentDidUpdate - state:%o', this.state);
	}

	fetchPlayerData() {
		let dataPath = this.state.playerInfoPath.replace('<id>', this.state.match.params.player);
		deps.services.PlayerProfile.fetch(dataPath).then(result => {
			this.setState({
				playerDetailData: result,
			});
			this.fetchPlayerExtraData();
			this.fetchPlayerList();
			this.loadTourData(result.tourid);
		});
	}

	fetchPlayerList() {
		logger.log('[PlayerProfile] fetchPlayerList:%o', this.state.playersListPath);

		deps.services.PlayerProfile.fetch(this.state.playersListPath).then(result => {
			this.setState({
				playersList: result.players,
			});
		});
	}

	fetchPlayerExtraData() {
		let dataPath = this.state.drawsArchiveProfileExtraDataPath.replace(
			'<playerId>',
			this.state.match.params.player
		);
		deps.services.PlayerProfile.fetch(dataPath)
			.then(result => {
				this.setState({
					playerExtraData: result,
				});
			})
			.catch(error => {
				logger.error('[PlayerProfile] fetchPlayerExtraData error:%o', error);
			});
	}

	getRelatedContentData(tourid) {
		let archiveRelatedContentURL = this.state.relatedContentPath.replace(
			'<playerId>',
			this.state.playerDetailData.id
		);
		// logger.log('[PlayerProfile] getRelatedContentURL:%o', archiveRelatedContentURL);

		deps.services.PlayerProfile.fetch(archiveRelatedContentURL).then(result => {
			let archiveContentCount = parseInt(result.count);

			if (archiveContentCount < 100) {
				// pull just to make 100 total related content to display
				let reminder = 100 - archiveContentCount;
				let tourRelatedContentURL = this.state.relatedContentPath.replace(
					'<playerId>',
					tourid + '&count=' + reminder
				);

				deps.services.PlayerProfile.fetch(tourRelatedContentURL)
					.then(tourResult => {
						// combine Archive related content (pull first) and tour related content and sort by date (new to old)
						let combinedContent = result.content
							.concat(tourResult.content)
							.sort((a, b) => b.displayDate - a.displayDate);
						let newResult = {
							...result,
							count: parseInt(result.count) + parseInt(tourResult.count),
							content: combinedContent,
						};

						logger.log(
							'[PlayerProfile] getRelatedContentData - result:%o, tourResult:%o, newResult:%o',
							result,
							tourResult,
							newResult
						);

						this.setState({
							relatedContent: newResult,
						});
					})
					.catch(error => {
						//logger.log('[PlayerProfile] getRelatedContentData - error ulling tourRelatedContent');
						this.setState({
							relatedContent: result,
						});
					});
			} else {
				this.setState({
					relatedContent: result,
				});
			}
		});
	}

	loadTourData(tourid) {
		logger.log('[PlayerProfile] loadTourData - tourid:%o status:%o', tourid, this.state.status);

		if (this.state.playerTourInfoPath && tourid) {
			let dataPath = this.state.playerTourInfoPath.replace('<id>', tourid);

			deps.services.PlayerProfile.fetch(dataPath)
				.then(result => {
					//logger.log('[PlayerProfile] loadTourData - result:%o', result);

					MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
						pageTitle: 'Archive Player Page',
						player: result,
					});

					this.setState({
						playerTourData: result,
					});
					this.getRelatedContentData(tourid);
				})
				.catch(error => {
					//logger.log('[PlayerProfile] loadTourData - error');
					this.setState({
						playerTourData: null,
					});
					this.getRelatedContentData(tourid);
				});
		} else {
			this.setState({
				playerTourData: null,
			});
		}
	}

	clearPlayer() {
		this.setState({
			playerDetailData: null,
		});
		this.fetchedData = true;
		this.fetchPlayerData();
	}

	render() {
		logger.log('[PlayerProfile] render - state:%o', this.state);
		let relatedContentURL;
		let header_propsData = {
			headerType: 'drawsArchive',
			title: 'Draws Archive',
			imageSrc: '/assets/images/headers/drawsArchive_year.jpg',
			metaTitle: 'Draws Archive',
			metaDescription: 'Player profile archive information for The Championships, Wimbledon.',
			metaDate: '',
			metaPlayers: '',
		};
		let playerDetailData, flagImagePathLarge, playerProfileImagePath, flagImagePathSmall;

		if (this.state.playerDetailData) {
			if (this.state.playerDetailData.id !== this.props.match.params.player) {
				this.clearPlayer();
			}
			playerDetailData = this.state.playerDetailData;
			flagImagePathLarge = this.state.flagImagePathLarge;
			flagImagePathSmall = this.state.flagImagePathSmall;
			playerProfileImagePath = this.state.playerProfileImagePath;
		}

		let backData = { category: '', history: this.props.history };

		if (this.state.playerDetailData) {
			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="drawsArchiveHdrContent">
						<ArchiveMenu location={this.state.location.pathname} />
					</div>
					<div className="content-main drawsarchive_player">
						<div className="column-layout player-profile">
							<div className="two-col component player-info">
								<PlayerArchiveInfo player={this.state} />

								{this.state.playerTourData ? (
									<PlayerProfessionalInfo player={this.state} />
								) : (
									<div> </div>
								)}
								{this.state.playerExtraData ? (
									<PlayerExtraInfo data={this.state.playerExtraData} />
								) : (
									<div> </div>
								)}
							</div>
							<div className="two-col statsInfo">
								<Tabs
									selected="-1"
									data={{
										...playerDetailData,
										playerProfileImagePath,
										flagImagePathLarge,
										flagImagePathSmall,
									}}
								/>
							</div>
						</div>
						{this.state.relatedContent ? (
							<div className="featuredtable column-layout da-related-content">
								<div className="player-score-table four-col inline">
									<div className="more-content">Related Content</div>
									<div className="greenStripe" />
									<div className={`four-col related-content-container`}>
										<RelatedContent data={this.state} />
									</div>
								</div>
							</div>
						) : (
							<div />
						)}
						<div>
							<PrevNextBack attributes={backData} />
						</div>
					</div>
					<Footer />
				</Template>
			);
		} else {
			return (
				<Template className="drawsarchive">
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					<div className="drawsArchiveHdrContent">
						<ArchiveMenu location={this.state.location.pathname} />
					</div>
					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile);

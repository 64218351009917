/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: MITeaser for Upset Alert and Likelihood to Win button
 *
 *  props = {
 *             isUpset --- boolean
 *			   direction --- "left" or "right"
 *           }
 *
 * -----------------------------------------------------------------------------
 */
const MITeaser = props => {
	logger.log('[MITeaser] props:%o', props);

	let { isUpset, direction } = props;
	let upset = isUpset === true ? `upset` : `no-upset`;
	direction = direction == 'left' || direction == 'right' ? direction : 'right'; //testing purposes

	if (isUpset === true || isUpset === false) {
		return (
			<div className="MITeaser">
				<div className={`mi-alert ${upset}`}>
					<div className="watson-fader-container">
						<div className={`fader`} />
					</div>
					<h4 className={`watson-logo ${upset}`}></h4>
				</div>

				<div className="likelihood-win-btn">
					<div className="fader-container">
						<div className={`fader ${direction} ${upset}`} />
					</div>
					<div className={`btn ${direction}`}>
						<div className={`btn-txt ${upset}`}>Likelihood to Win</div>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default MITeaser;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { FormCheckBox } from 'appdir/components/common-ui/FormFields';
import WimLink from 'appdir/components/general/WimLink';
import Button from 'appdir/components/common-ui/Button';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const Declaration = (props) => {
 * -----------------------------------------------------------------------------
 */

const Declaration = props => {
	// console.log('[Declaration] data:%o', props.data);

	return (
		<div className="column-layout">
			<div className="four-col page-title">
				<div className="title-header">DECLARATION</div>
			</div>

			<div className="two-col margin-col clear-two clear-four">
				Please note that as part of applying for accreditation, each applicant and (where applicable) their
				appointed representative must submit certain personal information required by the AELTC to process
				applications and issue accreditation passes. For further information on the personal information
				collected and processed by the AELTC, please refer to the{' '}
				<WimLink
					to="https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html"
					external={true}>
					Data Privacy Notice annexed to the Accreditation Terms and Conditions
				</WimLink>
				.
			</div>

			<div className="two-col margin-col clear-two clear-four">
				I confirm that I have read and agree to the{' '}
				<WimLink
					to="https://www.wimbledon.com/en_GB/about_wimbledon/accreditation_terms_and_conditions.html"
					external={true}>
					Accreditation Terms and Conditions (and Data Privacy Notice annexed thereto)
				</WimLink>{' '}
				and that I will distribute these to all applicants on whose behalf I will be submitting applications for
				accreditation. I further confirm that, in accordance with the Data Privacy Notice, I am duly authorised
				by all applicants on whose behalf I will be submitting applications to disclose all required personal
				data of each applicant to the AELTC for the purposes of accreditation.
			</div>

			<div className="two-col margin-col clear-two clear-four">
				I also acknowledge that any personal data that I provide will be entered into the central Wimbledon
				Accreditation System (WACS) in order to complete the accreditation process.
			</div>

			<div className="two-col margin-col clear-two clear-four fullwidth">
				<FormCheckBox name="acceptDeclaration" id="acceptDeclaration">
					I have read and accept the terms of the Declaration
				</FormCheckBox>
			</div>
			<div className="two-col margin-col clear-two clear-four no-margin right">
				<span className="hospitality-btns no-margin right left right-align">
					<Button
						className={`media-btn solid${props.formProps.isSubmitting ? ' disabled' : ''}`}
						onClick={props.formProps.submitForm}>
						Submit >
					</Button>
				</span>
			</div>
		</div>
	);
};

export default Declaration;

import React, { Component, Fragment } from 'react';
import { RoleRegistrationInviteContext } from '../context';
// import op from 'object-path';
// import isEmpty from 'lodash/isEmpty';
// import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FormInput, FormCheckBox } from 'appdir/components/common-ui/FormFields';
// import SectionDivider from 'appdir/components/cms/SectionDivider';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import SectionDivider from 'appdir/components/cms/SectionDivider';
import MyWimbledonTitle from 'appdir/components/common-ui/MyWimbledonTitle';

class PlayerDelegate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			confirmed: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.initialValues = {
			confirm: false,
		};

		logger.log(
			'[RoleRegistrationInvite - PlayerDelegate] constructor - state:%o context:%o',
			this.state,
			this.context
		);
	}

	componentDidMount() {
		logger.log(
			'[RoleRegistrationInvite - PlayerDelegate] componentDidMount - state:%o context:%o',
			this.state,
			this.context
		);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Role Registration Invite PlayerDelegate',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log(
			'[RoleRegistrationInvite - PlayerDelegate] componentDidUpdate - state:%o context:%o',
			this.state,
			this.context
		);
	}

	/**
	 * define the yup schema for form validation
	 * @param {*} content
	 */
	getSchema() {
		let schema = {
			confirm: yup
				.boolean()
				.required('Please confirm.')
				.oneOf([true], 'Please confirm.'),
		};

		return yup.object(schema);
	}

	/**
	 * form submit handle, calls addRole in index to submit to orch
	 * @param {*} values
	 * @param {*} actions
	 */
	handleSubmit(values, actions) {
		// logger.log('[RoleRegistrationInvite - PlayerDelegate] handleSubmit - state:%o', values);
		this.addRole();
	}

	addRole() {
		if (this.context.addRole) {
			logger.log('[RoleRegistrationInvite - PlayerDelegate] addRole');
			this.context.addRole();
		}
	}

	onConfirm() {
		this.setState({ confirmed: true });
	}

	renderConfirmText() {
		return (
			<Fragment>
				<div className="two-col margin question">
					<div>Would you like to add Player Delegate access?</div>
					<div className="highlight">
						To <MyWimbledonTitle short="true" /> account:
					</div>
					<div className="email">
						<strong>{this.context.userEmail}</strong>
					</div>
				</div>
			</Fragment>
		);
	}

	renderSubmitForm(policyLink) {
		let validationSchema = this.getSchema();
		return (
			<Fragment>
				{policyLink && <div className="two-col margin">Acknowledge below...</div>}
				<Formik
					initialValues={this.initialValues}
					validationSchema={validationSchema}
					onSubmit={this.handleSubmit}
					enableReinitialize={true}>
					{formProps => {
						let allowSubmit = formProps.values.confirm;
						return (
							<Fragment>
								<div className="two-col margin">
									<FormCheckBox name="confirm" id="confirm">
										I confirm I want to add Player Delegate access for{' '}
										<MyWimbledonTitle short="true" /> account{' '}
										<strong>{this.context.userEmail}</strong>.
									</FormCheckBox>
								</div>

								<div className="four-col body">
									<Button
										className={`btn solid ${!allowSubmit ? `disabled` : ``}`}
										onClick={formProps.submitForm}>
										CONFIRM ACCESS
									</Button>
								</div>
							</Fragment>
						);
					}}
				</Formik>
			</Fragment>
		);
	}

	render() {
		logger.log(
			'[RoleRegistrationInvite - PlayerDelegate] render - state:%o status:%o',
			this.state,
			this.state.status
		);
		//logger.log('[RoleRegistrationInvite - PlayerDelegate] render - context:%o', this.context);
		let policyLink = null;

		let hasPlayerRole = this.context?.userRoles.filter(d => d.roleId == 1000050).length > 0;

		return (
			<Fragment>
				{hasPlayerRole ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">
							Your account is already associated with a player and can not be registered as a delegate.
						</div>
						<div className="two-col body">
							Please contact your AELTC representative for additional questions.
						</div>
					</Fragment>
				) : this.context.complete ? (
					<Fragment>
						<div className="two-col margin">
							<h4>YOUR REGISTRATION HAS BEEN SUCCESSFUL</h4>
						</div>
						<div className="two-col margin center">
							Your player is now required to confirm access, once this has been completed you will receive
							an email confirmation with instructions on how to access this account.
						</div>
					</Fragment>
				) : this.context.status == 'unmatched' ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col margin">
							Sorry – it looks like you are logged in to a different account. Please{' '}
							<WimLink to="/en_GB/mywimbledon/profile.html">update your account</WimLink>, or create a new
							one, with your delegate email address.
						</div>
					</Fragment>
				) : this.context.status == 'complete' ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">Registration links may only be used once.</div>
						<div className="two-col body">
							Please <WimLink to="/en_GB/contact/index.html">contact us</WimLink> for additional
							questions.
						</div>
					</Fragment>
				) : !this.context.isValid ? (
					<Fragment>
						<div className="two-col margin">
							<h4>REGISTRATION UNSUCCESSFUL</h4>
						</div>
						<div className="two-col body">
							Unfortunately your registration code is invalid or has expired.
							<br />
							<br />
							Please try again or contact your AELTC representative to request a new link.
						</div>
					</Fragment>
				) : (
					<Fragment>
						<div className="two-col margin">
							<h4>PLAYER DELEGATE REGISTRATION</h4>
						</div>
						{this.context.status == 'active' ? (
							<Fragment>
								{this.renderConfirmText()}
								<SectionDivider data={{ style: 'four-col', dividerStyle: 'light' }} />
								{this.renderSubmitForm(policyLink)}
							</Fragment>
						) : null}
					</Fragment>
				)}
			</Fragment>
		);
	}
}
PlayerDelegate.contextType = RoleRegistrationInviteContext;
export default PlayerDelegate;

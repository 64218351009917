import deps from 'dependencies';

export default {
    mount: () => (dispatch, getState, store) => {
        //logger.log('[WeatherStatus] action.mount - data:%o');
        return deps.services.Config.ensureConfigurationLoaded(
            dispatch,
            store,
        ).then(() => {
            dispatch(deps.actions.WeatherData.check());
        });
    },
};

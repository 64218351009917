/**
 Slamtracker Initial State
*/

export default {
	slamtrackerUnit: 'metric',
	slamtrackerStatsLayout: 'expanded',
	filters: {
		open: '',
	},
};

import React from 'react';
import Debenture from 'appdir/components/cms/Debenture';
import RoleIcons from 'appdir/components/cms/RoleIcons';
import DebenturePrivate from 'appdir/components/cms/DebenturePrivate';
import RegisterButton from 'appdir/components/cms/RegisterButton';
import RestaurantBooking from 'appdir/components/cms/RestaurantBooking';

const RoleIconsSample = ({ currentUser }) => {
	logger.log('[CMS] currentUser:%o', currentUser);

	return (
		<div className="content-main column-layout news article ">
			<div className="four-col">
				<div>Debenture Component</div>
				<Debenture />
				<div>Debenture Private Component</div>
				<DebenturePrivate />
				--------------
				<br />
				<br />
				<br />
				<div>Role Icons Component - Debenture MyW</div>
				<RoleIcons role="debenture" tag="myw" />
				<br />
				<div>Role Icons Component - Ballot MyW</div>
				<RoleIcons role="ballot" tag="myw" />
				<br />
				--------------
				<br />
				<br />
				<br />
				<div>Role Icons Component - Debenture Private</div>
				<RoleIcons role="debenture" tag="private" />
			</div>
		</div>
	);
};

export default RoleIconsSample;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Carousel from 'appdir/components/common-ui/Carousel';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import op from 'object-path';
import deps from 'dependencies';
import axios from 'axios';
import PlayerInnovation from './PlayerInnovation';
import PlayerImage from 'appdir/components/content/PlayerImage';
import MeasurementUtils from 'appdir/lib/analytics';
import WimLink from 'appdir/components/general/WimLink';
import NewsTile from 'appdir/components/content/NewsTile';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import shuffle from 'lodash/shuffle';

/**
 * -----------------------------------------------------------------------------
 * React Component: Home
 * -----------------------------------------------------------------------------
 */

const mobileMatch = window.matchMedia('(max-width: 510px)');
const tabletMatch = window.matchMedia('(max-width: 768px)');
const desktopSidePanelMatch = window.matchMedia('(max-width: 1600px)');

const mapStateToProps = (state, props) => {
	return {
		Gigya: state['Gigya'],
		PageHeader: state['PageHeader'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		matchInsightsMatches: state['CommonData']['matchInsightsMatches'],
		powerIndex: state['CommonData']['powerIndex'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	openScreen: data => dispatch(deps.actions.Gigya.openScreen(data)),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});
class MyPlayersWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props.attributes,
			...this.props,
			mobile: mobileMatch.matches ? true : false,
			loading: false,
		};
		this.fetchData = true;
		this.firstLoad = true;
		this.favChange = false;
		this.tabChanged = false;
		this.favChangeData = {};
		this.currBreakpoint = null; //possible values (desktop, tablet, mobile)
		this.changedBreakpoint = false;
		this.mounted = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps.attributes,
				...nextProps,
			};
		});
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidMount() {
		//set initial breakpoint
		this.detectBreakpoint();
		this.mounted = true;
		// window.addEventListener('resize', throttle(this.onResize.bind(this), 0), false);
		let powerIndex = op.get(this.props, 'powerIndex.status', 'expired');
		let matchInsightsMatches = op.get(this.props, 'matchInsightsMatches.status', 'expired');
		if (this.gotUpdatedSharedData() && matchInsightsMatches === 'loaded' && powerIndex === 'loaded') {
			if (this.state.tab == 'favourites') {
				this.getPlayerStatusData();
			} else if (this.state.tab == 'picked') {
				this.getRecommendedData();
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//update breakpoint
		if (this.mounted) {
			this.detectBreakpoint();

			//check for tab change
			if (prevState.tab !== this.state.tab) {
				this.tabChanged = true;
			}

			//check for favourite change
			const homepageFavoritesPrev = prevState.homepageFavorites.join('-');
			const homepageFavorites = this.state.homepageFavorites.join('-');
			let powerIndex = op.get(this.props, 'powerIndex.status', 'expired');
			let matchInsightsMatches = op.get(this.props, 'matchInsightsMatches.status', 'expired');

			//set change flag for favorites to true and pesist changes in instance vars
			if (homepageFavoritesPrev !== homepageFavorites) {
				this.favChange = true;
				this.favChangeData = {
					prev: prevState.homepageFavorites,
					curr: this.state.homepageFavorites,
				};
			}

			//check if tab change is highlights
			if (this.tabChanged && this.state.tab === 'highlights') {
				this.tabChanged = false;
				this.getHighlights();
			}

			if (this.gotUpdatedSharedData()) {
				if (this.firstLoad && powerIndex === 'loaded' && matchInsightsMatches === 'loaded') {
					this.firstLoad = false;
					if (this.state.tab == 'favourites') {
						this.getPlayerStatusData();
					} else if (this.state.tab == 'picked') {
						this.getRecommendedData();
					}
				}

				if (this.favChange && powerIndex === 'loaded' && matchInsightsMatches === 'loaded') {
					this.favChange = false;
					this.fetchData = true;
					if (this.fetchData) {
						this.fetchData = false;
						this.updatePlayersStatusData([...this.favChangeData.prev]);
					}
				}

				if (this.tabChanged && powerIndex === 'loaded' && matchInsightsMatches === 'loaded') {
					this.fetchData = true;
					this.firstLoad = false;
					this.tabChanged = false;
					if (this.fetchData) {
						this.fetchData = false;
						if (this.state.tab == 'favourites') {
							this.getPlayerStatusData();
						} else if (this.state.tab == 'picked') {
							this.getRecommendedData();
						}
					}
				}
			}
		}
	}

	detectBreakpoint() {
		let newBreakpoint = null;
		if (mobileMatch.matches) {
			newBreakpoint = 'mobile';
		} else if (tabletMatch.matches) {
			newBreakpoint = 'tablet';
		} else if (desktopSidePanelMatch.matches) {
			newBreakpoint = 'desktopSidePanel';
		} else {
			newBreakpoint = 'desktop';
		}

		if (this.currBreakpoint !== newBreakpoint) {
			this.changedBreakpoint = true;
			this.currBreakpoint = newBreakpoint;
		}
	}

	async updatePlayersStatusData(prevHomePageFav) {
		let ids = {};

		await prevHomePageFav.forEach(d => {
			ids[d] = {
				status: 'old',
				count: 1,
			};
		});

		await this.state.homepageFavorites.forEach(d => {
			if (ids[d]) {
				ids[d]['count'] = ids[d]['count'] + 1;
			} else {
				ids[d] = {
					status: 'new',
					count: 1,
				};
			}
		});

		//get ids of players that need to be removed
		const removeIds = Object.keys(ids).filter(d => ids[d]['count'] < 2 && ids[d]['status'] == 'old');
		const addIds = Object.keys(ids).filter(d => ids[d]['status'] == 'new');

		//get ids of players that need to be added
		let result = [];
		let updatedData = [];
		if (addIds.length > 0) {
			const favPath = this.state.apiPath.path;
			const matchStatusPath = this.state.matchInsightsMatchesPath.path;
			const powerRankingsPath = this.state.powerRankingsPath.path;
			let homePageFavPromises = addIds.map(d =>
				deps.services.HomePage.getHomePageFavorites(favPath.replace('<playerId>', d))
			);

			//get the homepage favorites
			result = await axios.all(homePageFavPromises);
			result = result.filter(d => d !== null);
			let preMatchInsights = this.props.matchInsightsMatches['result'];
			let powerRankings = this.props.powerIndex['result'];
			//put flag in the data to suggest whether match insights are available or not
			result = result.map(d => {
				return {
					...d,
					preMatchInsight: this.hasPrematchInsights(d, preMatchInsights),
					powerRanking: this.getPowerRanking(d, powerRankings),
				};
			});
		}

		updatedData = [...this.state.data];

		if (removeIds.length > 0) {
			updatedData = [...updatedData].filter(d => !removeIds.includes(d.playerID));
		}

		let finalUpdatedData = updatedData.concat(result);

		//get the power index rankings
		this.setState({
			data: finalUpdatedData,
		});
	}

	async gotUpdatedSharedData() {
		let matchInsightStatus = await this.props.checkExpired(this.props.sharedDataConfig['matchInsightsMatches']);
		let wpiIndex = await this.props.checkExpired(this.props.sharedDataConfig['powerIndex']);

		if (matchInsightStatus.status == 'expired') {
			this.props.update(this.props.sharedDataConfig['matchInsightsMatches']);
		}

		if (wpiIndex.status == 'expired') {
			this.props.update(this.props.sharedDataConfig['powerIndex']);
		}

		if (wpiIndex.status == 'loaded' && matchInsightStatus.status == 'loaded') {
			return true;
		}

		return false;
	}

	async getPlayerStatusData() {
		if (!this.firstLoad) {
			this.setState({
				loading: true,
			});
		}
		const favPath = this.state.apiPath.path;
		const matchStatusPath = this.state.matchInsightsMatchesPath.path;
		const powerRankingsPath = this.state.powerRankingsPath.path;
		let homePageFavPromises = this.state.homepageFavorites.map(d =>
			deps.services.HomePage.getHomePageFavorites(favPath.replace('<playerId>', d))
		);

		//get the homepage favorites
		let result = await axios.all(homePageFavPromises);
		result = result.filter(d => d !== null);
		let preMatchInsights = this.props.matchInsightsMatches['result'];
		let powerRankings = this.props.powerIndex['result'];
		//put flag in the data to suggest whether match insights are available or not
		result = result.map(d => {
			return {
				...d,
				preMatchInsight: this.hasPrematchInsights(d, preMatchInsights),
				powerRanking: this.getPowerRanking(d, powerRankings),
			};
		});

		//get the power index rankings
		this.setState({
			data: result,
			preMatchInsights,
			powerRankings,
			loading: false,
		});
	}

	async getHighlights() {
		if (!this.firstLoad) {
			this.setState({
				loading: true,
			});
		}

		try {
			const playerParams = this.state.homepageFavorites.join('&tags=');
			// const ai_highlights_resp = await axios.get("http://dev.wimbledon.com:8080/en_GB/pde/feeds/highlights.json");
			const url = this.props.highlightPath.replace('<playerTags>', playerParams);
			// const url = "http://dev.wimbledon.com:8080/en_GB/pde/feeds/highlights.json";
			const ai_highlights_resp = await axios.get(url);
			let ai_highlights = ai_highlights_resp['data'];
			this.setState({
				highlightData: ai_highlights,
				loading: false,
			});
		} catch (err) {
			console.error({ err });
		}
	}

	getPowerRanking(playerStatusData, powerRankings) {
		if (powerRankings !== null) {
			let gender = playerStatusData.playerID.indexOf('wta') !== -1 ? 'wta' : 'atp';
			const { playerID } = playerStatusData;
			let playerStatusRankingData = powerRankings[gender].filter(d => d.playerid == playerID);
			if (playerStatusRankingData.length > 0)
				return {
					wpi_rank: playerStatusRankingData[0]['wpi_rank'],
					wpi_rank_change: playerStatusRankingData[0]['wpi_rank_change'],
				};
		}
		return null;
	}

	hasPrematchInsights(playerStatusData, preMatchInsightsData) {
		let preMatchInsightsMatches = op.get(preMatchInsightsData, 'matches', []);
		if (playerStatusData.displayMatch || preMatchInsightsMatches.length > 0) {
			const { displayMatch } = playerStatusData;
			if (preMatchInsightsMatches.indexOf(displayMatch) !== -1) {
				return true;
			}
		}
		return false;
	}

	apiCall(path) {
		return axios
			.get(path)
			.then(resp => resp)
			.catch(err => null);
	}

	getRecommendedData() {
		if (!this.firstLoad) {
			this.setState({
				loading: true,
			});
		}
		const request2 = axios.get(this.state.recommendedPlayersPath);
		const request1 = axios.get(this.state.recommendedPlayersRankingsPath);
		axios.all([request1, request2]).then(
			axios.spread(async (res1, res2) => {
				// logger.log('[RecommendedPlayer] getRecommendedData - data1:%o, data2:%o', res1.data, res2.data);
				let powerIndexPlayers = res1.data;
				let relatedPlayers = res2.data;
				let allFavourites = op.get(this.state, 'homepageFavorites', []);
				let favourites = allFavourites;

				//step 1 - filter power index players, remove favs from list and pick 1 of each type
				let idxNoFav = reject(powerIndexPlayers, v => includes(allFavourites, v.playerid)); //filter out favs
				let idxShuffle = shuffle(idxNoFav); //randomize array
				let idxUniq = uniqBy(idxShuffle, 'reason'); //select only 1 of each type
				let idxFin = idxUniq.map(item => {
					return { playerid: item.playerid, reason: 'power_index' };
				}); //make an array with only ids
				// logger.log('[RecommendedPlayer] getRecommendedData - idxFin', idxFin);

				//step 2 - filter editors picks and remove favs and power index players
				let picsNoFav = allFavourites
					? this.state.editorsPicks.filter(v => !allFavourites.includes(v))
					: this.state.editorsPicks;
				let filteredPicks = picsNoFav.filter(v => !idxFin.includes(v));
				filteredPicks = filteredPicks.map(i => {
					return { playerid: i, reason: 'editor_picks' };
				});

				//step 3 - get related favs
				favourites = shuffle(favourites); //shuffle the favs
				favourites = favourites.slice(0, 5); //only need 5 max
				let allRec = favourites.map(i => {
					if (relatedPlayers[i]) {
						return relatedPlayers[i].map(i => {
							return i.playerid;
						});
					} else {
						return null;
					}
				}); //get all the related players
				allRec = [].concat.apply([], allRec); //flatten the array
				allRec = uniq(allRec); //uniques only
				allRec = allRec.filter(v => !allFavourites.includes(v)); //remove favourites
				allRec = allRec.filter(v => !idxFin.includes(v)); //remove anyone from step 1
				allRec = allRec.filter(v => !filteredPicks.includes(v)); //remove anyone from step 2
				allRec = shuffle(allRec); //shuffle it
				allRec = allRec.slice(0, 4); //only need 4
				allRec = allRec.map(i => {
					return { playerid: i, reason: 'recommended' };
				});
				// logger.log('[RecommendedPlayer] getRecommendedData - allRec', allRec);

				//step 4 - put all the id's into one array and shuffle again
				let recPlayersOut = allRec.concat(idxFin).concat(filteredPicks);
				recPlayersOut = shuffle(recPlayersOut);

				logger.log('[RecommendedPlayer] getRecommendedData - recPlayersOut', recPlayersOut);
				//get the player favourites data
				const favPath = this.state.apiPath.path;
				const matchStatusPath = this.state.matchInsightsMatchesPath.path;
				const powerRankingsPath = this.state.powerRankingsPath.path;
				let playerIds = recPlayersOut.map(d => d.playerid);
				let recPlayersPromises = playerIds.map(d =>
					deps.services.HomePage.getHomePageFavorites(favPath.replace('<playerId>', d))
				);

				//get the homepage favorites
				let result = await axios.all(recPlayersPromises);
				result = result.filter(d => d !== null);
				let preMatchInsights = await this.apiCall(matchStatusPath);
				preMatchInsights = preMatchInsights.data || [];
				let powerRankings = await this.apiCall(powerRankingsPath);
				// let powerRankings = this.getPlayerIndex();
				powerRankings = powerRankings.data || null;
				//put flag in the data to suggest whether match insights are available or not
				result = result.map(d => {
					return {
						...d,
						preMatchInsight: this.hasPrematchInsights(d, preMatchInsights),
						powerRanking: this.getPowerRanking(d, powerRankings),
						reason: this.getReason(d, recPlayersOut),
					};
				});

				this.setState({ data: result, loading: false });
			})
		);
	}

	getReason(data, reccPlayers) {
		const recPlayer = reccPlayers.filter(d => d.playerid == data.playerID);
		if (recPlayer.length > 0) {
			return recPlayer[0]['reason'];
		}
		return null;
	}

	renderPlayerFavoriteSlide(data) {
		if (data) {
			return (
				<div className="homepage-favorite-container">
					{data
						.filter(d1 => d1.playerID)
						.map((d, i) => {
							return (
								<PlayerInnovation
									data={d}
									imgConfig={this.state.config.playerImg}
									key={i}
									showStar={this.state.tab == 'picked' ? true : false}
									tab={this.state.tab}
								/>
							);
						})}
				</div>
			);
		} else {
			return null;
		}
	}

	renderDefaultSlide() {
		// if (!this.state.mobile) {
		if (!mobileMatch.matches) {
			return (
				<div className="homepage-favorite-container default">
					<WimLink to={'/en_GB/players/index.html'} title="players link to choose your favorite player">
						<div className="fav-player-container">
							<PlayerImage
								attributes={{ 'player-image': this.props.config.playerDefaultImg, style: 'small' }}
							/>
							<div
								tabIndex={0}
								aria-label="Replace me with a player of your choice"
								alt="Replace me with a player of your choice"
								className="player-info">
								Replace me with a player of your choice
							</div>
						</div>
					</WimLink>
					<WimLink to={'/en_GB/players/index.html'} title="players link to choose your favorite player">
						<div className="fav-player-container">
							<PlayerImage
								attributes={{ 'player-image': this.props.config.playerDefaultImg, style: 'small' }}
							/>
							<div
								tabIndex={0}
								aria-label="Replace me with a player of your choice"
								alt="Replace me with a player of your choice"
								className="player-info">
								Replace me with a player of your choice
							</div>
						</div>
					</WimLink>
					<WimLink to={'/en_GB/players/index.html'} title="players link to choose your favorite player">
						<div className="fav-player-container">
							<PlayerImage
								attributes={{ 'player-image': this.props.config.playerDefaultImg, style: 'small' }}
							/>
							<div
								tabIndex={0}
								aria-label="Replace me with a player of your choice"
								alt="Replace me with a player of your choice"
								className="player-info">
								Replace me with a player of your choice
							</div>
						</div>
					</WimLink>
				</div>
			);
		} else {
			return (
				<div className="homepage-favorite-container default mobile">
					<WimLink to={'en_GB/players/index.html'}>
						<div className="fav-player-container">
							<PlayerImage
								attributes={{ 'player-image': this.props.config.playerDefaultImg, style: 'small' }}
							/>
							<div className="player-info">Replace me with a player of your choice</div>
						</div>
					</WimLink>
				</div>
			);
		}
	}

	constructFavoritesArry(data) {
		let playerFavoriteArry = data.slice();
		let restructuredPlayerFavArry = [];

		if (tabletMatch.matches) {
			restructuredPlayerFavArry = playerFavoriteArry.map(d => [d]);
		} else if (desktopSidePanelMatch.matches) {
			while (playerFavoriteArry.length !== 0) {
				restructuredPlayerFavArry.push(playerFavoriteArry.splice(0, 2));
			}
		} else {
			while (playerFavoriteArry.length !== 0) {
				restructuredPlayerFavArry.push(playerFavoriteArry.splice(0, 3));
			}
		}

		return restructuredPlayerFavArry;
	}

	constructHighlightsArry(data) {
		let arry = data.slice();
		let restructuredPlayerFavArry = [];

		if (tabletMatch.matches) {
			while (arry.length !== 0) {
				restructuredPlayerFavArry.push(arry.splice(0, 1));
			}
		} else if (desktopSidePanelMatch.matches) {
			while (arry.length !== 0) {
				restructuredPlayerFavArry.push(arry.splice(0, 3));
			}
		} else {
			while (arry.length !== 0) {
				restructuredPlayerFavArry.push(arry.splice(0, 4));
			}
		}

		return restructuredPlayerFavArry;
	}

	renderHighlightsSlide(data) {
		if (data) {
			return (
				<div className="column-layout highlights-container">
					{data.map((d, i) => {
						d.images = d.images[0] ? d.images[0] : d.images;
						return <NewsTile attributes={{ ...d, 'col-style': 'one-col' }} key={d.cmsId} />;
					})}
				</div>
			);
		} else {
			return null;
		}
	}

	slideDirection(direction) {
		let directionMeasured = direction == 'left' ? 'Previous' : 'Next';
		MeasurementUtils.dispatchMeasurementCall('homeFavoritesSlide', { direction: directionMeasured });
	}

	myWimLogin() {
		this.props.openScreen({ screen: 'login_screen', cid: 'login_myplayers' });
	}

	handleClick(link) {
		if (link == 'login') {
			this.props.openScreen({ screen: 'login_screen', cid: 'login_subnav' });
		} else if (link == 'register') {
			this.props.openScreen({ screen: 'register_screen', cid: 'register_subnav' });
		}

		this.state.onClick(link);
	}

	constructItems(data) {
		//filter old data
		data = data.filter(d => d.playerID);
		if (!this.state.showDefault && data.length > 0) {
			let dataArray = this.constructFavoritesArry(data);
			return dataArray.map(d => {
				return { renderItem: this.renderPlayerFavoriteSlide.bind(this, d) };
			});
		} else {
			return [{ renderItem: this.renderDefaultSlide.bind(this) }];
		}
	}

	constructRelatedContent(data) {
		// data = data.filter(d => d.playerID);
		data = op.get(data, 'content', []);
		if (!this.state.showDefault && data.length > 0) {
			let dataArray = this.constructHighlightsArry(data);
			return dataArray.map(d => {
				return { renderItem: this.renderHighlightsSlide.bind(this, d) };
			});
		} else {
			return [{ renderItem: this.renderDefaultSlide.bind(this) }];
		}
	}

	renderNav(direction, clickFn) {
		return (
			<div
				onClick={() => {
					clickFn(direction);
					this.slideDirection(direction);
				}}
				className={`arrow-wrapper-${direction}`}>
				<i className={`wim-icon-${direction}-arrow`} />
			</div>
		);
	}

	render() {
		//logger.log('[HomepageFavorites] render - state:%o', this.state);
		// let dataArray = this.constructFavoritesArry(this.state.data);
		let contentItems = null;
		if (this.state.tab == 'highlights') {
			contentItems = this.constructRelatedContent(op.get(this.state, 'highlightData', []));
		} else {
			contentItems = this.constructItems(op.get(this.state, 'data', []));
		}

		let navJSON = {
			leftNav: this.renderNav.bind(this),
			rightNav: this.renderNav.bind(this),
		};
		if (this.state.loading) {
			return (
				<div className="loading">
					<LoadingIndicator />
				</div>
			);
		} else {
			if (contentItems.length > 0 && this.state.data) {
				if (this.state.tab == 'favourites' || this.state.tab == 'picked') {
					return (
						<React.Fragment>
							<div style={{ position: 'relative' }}>
								<Carousel
									key={'favorites-carousel'}
									attributes={{
										class: 'favourites',
										items: contentItems,
										showNav: false,
										showFullscreenButton: false,
										showThumbnails: false,
										showPlayButton: false,
										customNav: true,
										customNavHTML: navJSON,
									}}
								/>
							</div>
						</React.Fragment>
					);
				} else {
					return this.state.Gigya.loggedIn && this.state.highlightData ? (
						//check if there is data
						this.state.highlightData.content.length > 0 ? (
							<React.Fragment>
								<div style={{ position: 'relative' }}>
									<Carousel
										key={'highlights'}
										attributes={{
											items: contentItems,
											showNav: false,
											showFullscreenButton: false,
											showThumbnails: false,
											showPlayButton: false,
											customNav: true,
											customNavHTML: navJSON,
											class: 'highlight',
										}}
									/>
								</div>
							</React.Fragment>
						) : (
							<>
								<div className="column-layout highlight-content-placeholder">
									<div className="four-col no-content-text">No Favourite Content Available</div>
								</div>
							</>
						)
					) : (
						<>
							<div className="column-layout highlight-content-placeholder">
								<div className="four-col no-content-text">
									To view Your Highlights,{' '}
									<span
										className="login-text"
										onClick={() => {
											this.handleClick('login');
										}}>
										log in
									</span>
									/
									<span className="login-text" onClick={() => this.handleClick('register')}>
										join
									</span>
									<span className="myWimbledon">
										<i className="wim-icon-myw my" aria-label="my wimbledon"></i>
										<span className="wimbledon">Wimbledon</span>
									</span>
								</div>
							</div>
						</>
					);
				}
			} else {
				return null;
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPlayersWrapper);

import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[HillVoteWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.HILLVOTEWEBVIEW_LOAD:
			logger.log('[HillVoteWebview] reducers - action:%o',action);

			newState = {
				...state,
				status: 'load',
				...action.data
			};
			// logger.log('[HillVoteWebview] reducers HILLVOTEWEBVIEW_LOAD - newState:%o',newState);
			return newState;
		break;
		case deps.actionTypes.HILLVOTEWEBVIEW_VOTE:
			// logger.log('[HillVoteWebview] reducers - HILLVOTEWEBVIEW_VOTE action:%o',action);
			newState = {
				...state,
				voted: [...state.voted, action.data.voted],
			};
			// logger.log('[HillVoteWebview] deps.actionTypes.HILLVOTEWEBVIEW_VOTE - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};

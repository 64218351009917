import React, { Component } from 'react';
import GenericError from './GenericError';

class ErrorBoundary2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}
	componentDidCatch(error, info) {
		this.setState({ hasError: true }, () => {
			this.props.callback ? this.props.callback(error, info) : null;
		});
	}
	render() {
		if (this.state.hasError) {
			return <GenericError message={this.props.message} klass={this.props.klass ? this.props.klass : ''} />;
		} else {
			return this.props.children;
		}
	}
}
export default ErrorBoundary2;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: KeyStat
 * -----------------------------------------------------------------------------
 */
class KeyStat extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		//logger.log('[KeyStat] - attributes:%o', this.state.data);

		return (
			<div className={`${this.state.data.style} key-stat`}>
				<div className="big-stat">{this.state.data.stat}</div>
				<div className="stat-text">{this.state.data.summary}</div>
			</div>
		);
	}
}

export default KeyStat;

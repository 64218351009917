import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: () => (dispatch, getState, store) => {
		//logger.log('[PlayerChallenge] action.mount - ensureConfig');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Player Challenge',
		});

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[PlayerChallenge] action.mount - dispatch - config:%o', Config);

			let challengePath = Config.scoringData.challengeSummary;
			dispatch({
				type: deps.actionTypes.PLAYERCHALLENGE_MOUNT,
				data: { challengePath },
			});
		});
	},
};

/**
 * Imports
 */
import React, { Component } from 'react';
import MatchBox from 'appdir/components/scoring/MatchBox';
import { connect } from 'react-redux';
import deps from 'dependencies';

/**
 *
 * Class Component: Facts
 *
 */

const mapStateToProps = (state, props) => ({
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Draw.mount()),
});

class Draw extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data.data };
	}

	componentDidMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	componentDidUpdate(prevProps, prevState) {}

	formatMatchData(match) {
		return {
			scores: match.scores,
			round: match.roundName,
			team1: match.team1,
			team2: match.team2,
			eventName: match.eventName,
			winner: match.team1.won == 'true' ? '1' : '2',
		};
	}

	render() {
		let wordNumberMap = {
			1: 'one',
			2: 'two',
			3: 'three',
			4: 'four',
			5: 'five',
		};
		return (
			<div className="column-layout">
				<div className="draws four-col">
					{this.state.rounds.map((d, i) => {
						let tableHeader = `${d.roundName} ${d.courtName}`;
						return (
							<div className={`column-${wordNumberMap[i + 1]}`}>
								<div className="column-title">{d.roundName}</div>
								{Array.isArray(d.matches) ? (
									d.matches.map((d1, j) => {
										let matchData = this.formatMatchData(d1);
										return (
											<MatchBox
												key={d1.match_id}
												attributes={{
													data: matchData,
													tableHeader: tableHeader,
													event: matchData.eventName,
													showLinks: true,
													type: 'draw',
												}}
											/>
										);
									})
								) : (
									<MatchBox
										key={d.matches.match_id}
										attributes={{
											data: this.formatMatchData(d.matches),
											tableHeader: tableHeader,
											event: d.matches.eventName,
											showLinks: true,
											type: 'draw',
										}}
									/>
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Draw);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

import replace from 'lodash/replace';
import { getQuerystringValues } from 'appdir/components/general/Util';

import Template from 'appdir/components/Template';
import Header from 'appdir/components/general/Header';
import MainNav from 'appdir/components/general/MainNav';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import SearchBox from './SearchBox';
import SearchResults from './SearchResults';
import CategoryTiles from 'appdir/components/common-ui/CategoryTiles';
import WimLink from 'appdir/components/general/WimLink';
import ScrollHandler from 'appdir/components/general/ScrollHandler';
import MeasurementUtils from 'appdir/lib/analytics';
import RoleDisplay from './elements/RoleDisplay';

/**
 * -----------------------------------------------------------------------------
 * React Component: SearchPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		loggedIn: state['Gigya']['loggedIn'],
		config: state['Config'],
		...state['SearchPage'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SearchPage.mount()),
	unmount: () => dispatch(deps.actions.SearchPage.unmount()),
	getRoleSearch: path => dispatch(deps.actions.SearchPage.getRoleSearch(path)),
});

class SearchPage extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props, { loadMore: [] });

		this.onEnter = this.onEnter.bind(this);

		logger.log('[SearchPage] constructor - props:%o', this.props);
	}

	componentWillUnmount() {
		logger.log('[SearchPage] componentWillUnmount - state:%o', this.state);
		this.props.unmount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[SearchPage] componentWillReceiveProps - props:%o', nextProps);

		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps, { searchResults: prevState.searchResults });
		});
	}

	componentDidUpdate() {
		logger.log('[SearchPage] componentDidUpdate - data:%o path:%o', this.state.contentData, this.state.contentPath);

		if (!this.state.contentData && this.state.contentPath) {
			deps.services.SearchPage.fetch(this.state.contentPath)
				.then(result => {
					logger.log('[SearchPage] componentDidUpdate - result:%o', result);
					this.setState({
						contentData: result,
					});
				})
				.catch(error => {
					logger.error('[SearchPage] componentDidUpdate - error: %o', error);
				});
		}

		if (!this.state.faqData) {
			deps.services.SearchPage.fetch(this.state.faqPath).then(result => {
				this.setState({
					faqData: result,
				});
			});
		}
	}

	/**
	 * Return the search query url
	 * - provides full or quick search (for SmartSearch)
	 * @param {String} which
	 * @returns
	 */
	getSearchTemplate(which, roles = false) {
		let searchConfig = 'solrSearch';
		let parsedQs = getQuerystringValues();
		if (parsedQs.search && !roles) {
			searchConfig = parsedQs.search;
		}
		//logger.log('[SearchPage] getSearchTemplate - config:%s', searchConfig);
		let solrSearch = !roles
			? op.get(this.state, `config.${searchConfig}`, {})
			: op.get(this.state, 'config.solrSearchRole', {});
		//logger.log('[SearchPage] getSearchTemplate - solrSearch:%o', solrSearch);

		if (!solrSearch['queryTerms']) return '';

		let query = solrSearch['queryTerms'].join(' OR ');

		logger.log('[SearchPage] getSearchTemplate - roles:%o searchConfig:%o query:%o', roles, searchConfig, query);

		let searchUrl = `${solrSearch.service}${query}`;

		let sort = op.get(solrSearch, 'sort', false);
		let rows = op.get(solrSearch, 'rows', false);
		let type = op.get(solrSearch, 'type', false);
		let wt = op.get(solrSearch, 'wt', false);
		let fl = op.get(solrSearch, 'fl', false);
		let bf = op.get(solrSearch, 'bf', false);
		let queryParams = op.get(solrSearch, 'queryParams', false);

		if (queryParams) {
			searchUrl += '&' + queryParams.join('&');
		}
		if (wt) {
			searchUrl += `&wt=${wt}`;
		}
		if (fl) {
			searchUrl += `&fl=${fl}`;
		}
		if (type) {
			searchUrl += `&defType=${type}`;
		}
		if (bf) {
			searchUrl += `&bf=${bf}`;
		}
		if (sort) {
			searchUrl += `&sort=${sort}`;
		}

		if (which == 'full') {
			if (rows) {
				searchUrl += `&rows=${rows}&omitHeader=true`;
			}
			return searchUrl;
		} else {
			searchUrl += `&rows=10&omitHeader=true`;
			return searchUrl;
		}
	}

	/**
	 * Returns the search url, replacing the SEARCH_TEXT after call getSearchTemplate
	 *  - ability to log test url
	 * @param {*} term
	 * @param {*} start
	 * @param {*} cnt
	 */
	getSearchUrl(term, start, cnt) {
		term = term.trim();
		if (term.includes(' ')) {
			term = `"${term}"`;
		} else {
			term = `${term}`;
		}

		let url = replace(this.getSearchTemplate('full'), new RegExp('<SEARCH_TEXT>', 'g'), term);

		let urlRoles = replace(this.getSearchTemplate('full', true), new RegExp('<SEARCH_TEXT>', 'g'), term);

		logger.log('[SearchPage] getSearchUrlRoles - queryPath:%s', urlRoles);
		logger.log('[SearchPage] getSearchUrl - queryPath:%s', url);

		this.getResults(url);
		this.getResultsRole(urlRoles);
	}

	getResults(dataPath) {
		deps.services.SearchPage.fetchSearchResults(dataPath).then(result => {
			logger.log('[SearchPage] getResults - data:%o', result);
			this.setState({
				searchResults: result,
			});

			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'Search Results',
			});
		});
	}

	getResultsRole(dataPath) {
		if (this.state.loggedIn) {
			this.props.getRoleSearch(dataPath);
		}
	}

	getContent() {
		logger.log('[SearchPage] getContent - state:%o', this.state);

		if (this.state.searchResults && this.state.searchResults.response.numFound > 0) {
			return (
				<SearchResults
					data={this.state.searchResults}
					isRoleData={this.state.loggedIn && this.state.roleResults}
					allRoleDataTile="All Search Results"
				/>
			);
		} else if (this.state.searchResults) {
			return (
				<div className="column-layout">
					<div className="no-results">No Results Found</div>
				</div>
			);
		} else if (this.state.contentData) {
			return (
				<span>
					<div className="content-filters-scroll" />
					<CategoryTiles data={this.state.contentData} />
				</span>
			);
		}
	}

	getRoleContent() {
		if (this.state.loggedIn && this.state.roleResults) {
			return (
				<RoleDisplay
					results={this.state.roleResults}
					loadMore={this.state.loadMore}
					setLoadMore={value => this.setState({ loadMore: value })}
				/>
			);
		} else {
			return null;
		}
	}

	getFaqs() {
		if (this.state.faqData) {
			let cnt = 0;

			return (
				<div className="category-question-list">
					<div className="category-list">
						<div className="category-list-title">{this.state.faqData.categoryTitle}</div>
						{this.state.faqData.content.map((val, key) => {
							return (
								<div className="category-list-question" key={cnt++}>
									<WimLink to={val.url} style="white">
										{val.title}
									</WimLink>
								</div>
							);
						})}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	onEnter(value) {
		logger.log('[SearchPage] onEnter - value:%o', value);
		if (value != '') {
			this.getSearchUrl(value, 0);
			this.setState({ loadMore: [], roleResults: null }); // reset roleResults on search

			//record search term
			MeasurementUtils.dispatchMeasurementCall('search_term', value);
		} else {
			this.setState({
				searchResults: null,
				roleResults: null,
				loadMore: [],
			});
		}
	}

	getNavSettings() {
		let navSettings;
		//pass in the top fixed position of the nav when the page scrolls
		navSettings = { navTop: 190 };

		return navSettings;
	}

	render() {
		logger.log('[SearchPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'search',
			//"titleElem": ".search--header",
			minHeight: 190,
			metaTitle: 'Search',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scroll: true,
			hideElems: ['.category-question-list'],
		};

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />
				<ScrollHandler hasData={this.state.contentData ? true : false} filter="none" />
				<noindex>
					<div className={`search--header-wrapper ${this.state.showSearch ? 'show-results' : ''}`}>
						<div className="search--header">
							{this.state.config?.solrSearch ? (
								<SearchBox queryPath={this.getSearchTemplate('quick')} onEnter={this.onEnter} />
							) : null}

							{this.getFaqs()}
						</div>
					</div>
					<div className="content-main content-main-role-results search">{this.getRoleContent()}</div>
					<div className="content-main search atozcontent">{this.getContent()}</div>
				</noindex>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// import { isMobileSafari } from 'react-device-detect';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: SmartbannerHelmet
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Header'],
		...props,
		smartbanner: state['Config']['smartbanner'],
		config: state['Config'],
	};
};

class SmartbannerHelmet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
		this.dispatchHideSmartbanner = this.dispatchHideSmartbanner.bind(this);
		this.onSmartbannerDisplay = this.onSmartbannerDisplay.bind(this);
        this.dispatchClickoutSmartbanner = this.dispatchClickoutSmartbanner.bind(this);
	}

	componentDidMount() {
		document.addEventListener('smartbanner.clickout', this.dispatchClickoutSmartbanner); // Dispatched when smartbanner is clicked to navigate to app store
		document.addEventListener('smartbanner.exit', this.dispatchHideSmartbanner); // Dispatched when smartbanner is closed
		document.addEventListener('smartbanner.view', this.onSmartbannerDisplay); // Dispatched when smartbanner is added to display
	}

	componentWillUnmount() {
		this.dispatchClickoutSmartbanner();
	}

	dispatchHideSmartbanner(event) {
		logger.log('[SmartbannerHelmet] - dispatchHideSmartbanner() hide smartbanner');
        let wrapElem = document.querySelector('.site-wrap');

        if (wrapElem?.classList.contains('smartbannerHelmet')) {
            wrapElem?.classList?.remove('smartbannerHelmet')
            if (!event) {
				// remove extra space 
				document?.getElementsByClassName("page-header")?.classList?.remove("backgroundSpace");
			}
        }

	}

    dispatchClickoutSmartbanner() {
        let banner = document?.querySelector('.js_smartbanner');
		if (banner) {
			document?.querySelector('body')?.removeChild(banner);
		}
        this.dispatchHideSmartbanner();

    }

	onSmartbannerDisplay() {
		logger.log('[SmartbannerHelmet] - onSmartbannerDisplay()');

        let wrapElem = document.querySelector('.site-wrap');

		// check to see if config contains pathname - show if so
		let toShowSmartbanner = this.props.config?.enabled?.showSmartbanner;
		let toRender = this.props.smartbanner?.includes(window.location.pathname) && toShowSmartbanner && (document.getElementsByClassName('.promo-feature-overlay')?.length == 0);
		if (toRender) {
            wrapElem?.classList?.add('smartbannerHelmet');
		} else if (document.querySelector('.js_smartbanner')) {
			this.dispatchClickoutSmartbanner();
		}

	}

	render() {
		let toShowSmartbanner = this.props.config?.enabled?.showSmartbanner;
		let toRender = this.props.smartbanner?.includes(window.location.pathname) && toShowSmartbanner && (document.getElementsByClassName('.promo-feature-overlay')?.length == 0);
		document.querySelector('.backgroundSpace')?.classList?.add('hiddenSmartbannerBGSpace')

        if (toRender) {
			return (
				<Helmet>
					<meta name="smartbanner:close-label" content="Close" />
					<meta
						name="apple-itunes-app"
						content="app-id=319284643, app-argument=https://apps.apple.com/us/app/wimbledon-2023/id319284643l"
					/>
					<meta name="smartbanner:title" content="Wimbledon Championships" />
					<meta name="smartbanner:author" content="Open in the Wimbledon 2024 app" />

					{/* NOTE: for iOS, no longer need since we are using native banner, but keeping commented in case we want to use smartbanner in the future */}
					<meta name="smartbanner:icon-apple" content="/assets/images/misc/favicon-196x196.png" sizes="196x196"/>
					<meta name="smartbanner:icon-apple" content="/assets/images/misc/favicon-96x96.png" sizes="96x96"/>
					<meta name="smartbanner:icon-apple" content="/assets/images/misc/favicon-32x32.png" sizes="32x32"/>
					<meta name="smartbanner:icon-apple" content="/assets/images/misc/favicon-16x16.png" sizes="16x16"/>
					<meta name="smartbanner:icon-apple" content="/assets/images/misc/favicon-128.png" sizes="128x128"/>

					<meta
						name="smartbanner:icon-google"
						content="assets/images/misc/favicon-196x196.png"
						sizes="196x196"
					/>
					<meta
						name="smartbanner:icon-google"
						content="assets/images/misc/favicon-96x96.png"
						sizes="96x96"
					/>
					<meta
						name="smartbanner:icon-google"
						content="assets/images/misc/favicon-32x32.png"
						sizes="32x32"
					/>
					<meta
						name="smartbanner:icon-google"
						content="assets/images/misc/favicon-16x16.png"
						sizes="16x16"
					/>
					<meta
						name="smartbanner:icon-google"
						content="assets/images/misc/favicon-128.png"
						sizes="128x128"
					/>
					<meta name="smartbanner:button" content="OPEN" />
					{/* NOTE: for iOS, no longer need since we are using native banner, but keeping commented in case we want to use smartbanner in the future */}
					<meta name="smartbanner:button-url-apple" content="https://apps.apple.com/us/app/wimbledon-2023/id319284643"/>
					<meta
						name="smartbanner:button-url-google"
						content="https://play.google.com/store/apps/details?id=com.ibm.events.android.wimbledon&pcampaignid=web_share"
					/>
					<meta name="smartbanner:enabled-platforms" content="android,ios" />

					<meta name="smartbanner:exclude-user-agent-regex" content="^.*(Version).*Safari"/>

					<link rel="stylesheet" href="/assets/smartbanner/smartbanner.css" />
					<script src="/assets/smartbanner/smartbanner.js" />
				</Helmet>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps)(SmartbannerHelmet);

import React from 'react';
import { Formik, Form } from 'formik';
import { FormInput, FormEmail, FormCheckBox, FormSelect } from 'appdir/components/common-ui/FormFields';

const FormFields = ({ data }) => {
	return (
		<>
			<Formik
				initialValues={{
					name: '',
					email: '',
					acceptedTerms: false, // added for our checkbox
				}}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						alert(JSON.stringify(values, null, 2));
						setSubmitting(false);
					}, 400);
				}}>
				<Form>
					<FormInput label="Name" name="name" type="text" placeholder="Jane Doe" />
					<FormEmail label="Email" name="email" type="email" placeholder="" />
					<FormCheckBox name="acceptedTerms">I accept the terms and conditions</FormCheckBox>
					<FormSelect label="Job Type" name="jobType">
						<option value="">Select a job type</option>
						<option value="designer">Designer</option>
						<option value="development">Developer</option>
						<option value="product">Product Manager</option>
						<option value="other">Other</option>
					</FormSelect>
				</Form>
			</Formik>
		</>
	);
};

export default FormFields;
